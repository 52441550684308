import React from 'react'
import { DDITextField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { maxLength } from 'ddiForm/validations'
import { twoColFieldWrapperStyle } from '../../../utils'

import '../../../styles/form-styles.scss'

const Contact = props => {
  return (
    <DDICardWrapper title="Contact" contentStyle={{ padding: 10 }}>
      <div className="vendor-master-form-outer-wrapper">
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="contactName"
            label="Name"
            wrapperStyle={twoColFieldWrapperStyle}
            validate={maxLength(30)}
          />
          <DDITextField
            propertyName="phone"
            label="Phone Number"
            mask="phoneNumber"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
      </div>
    </DDICardWrapper>
  )
}

export default Contact
