import React, { Fragment } from 'react'
import { DDIButton } from 'ddiForm/wrapped'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'
import DeleteGridCell from 'pages/CustomerMaster/components/DeleteGridCell'
import {
  formatDateFields,
  formatDollarFields,
  getIn,
  makeRowDataUnique,
  dateFilterComparator
} from 'utils'
import { darkGrey, errorRed } from 'theme/colors'
import { Icon } from '@material-ui/core'
import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'
import { handleEditableGridAPIData } from 'components/EditableGrid/utils'

export const commonFormatGridCells = (
  array,
  dollarFields = ['balance', 'dollarLimit', 'sales', 'discount', 'balanceDue'],
  dateFields = ['effectiveDate', 'expirationDate'],
  percentageFields = ['lastGPPercent'],
  commaFormattedFields = ['age'],
  roundedPercentageFields = [
    'thisYearSalesVariancePercent',
    'lastYearSalesVariancePercent',
    'twoYearAgoSalesVariancePercent'
  ],
  calcPercentageFields = [
    'thisYearGPPercent',
    'lastYearGPPercent',
    'twoYearAgoGPPercent',
    'threeYearAgoGPPercent'
  ]
) => {
  const ret = array.map((col, i) => {
    col = {
      ...col
    }

    /* format the date fields */
    if (dateFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        cellClass: 'text-center align-center',
        headerClass: 'text-center',
        valueFormatter: params => formatDateFields(params),
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        }
      }
    }

    /* parse the dollar fields */
    if (dollarFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        cellClass: 'align-right',
        headerClass: 'align-right',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        cellStyle(params) {
          if (params.value && params.value < 0) {
            return { color: '#ff0000' }
          }
          return null
        },
        valueFormatter: params => {
          const hasFourDecimals = [
            'lastNetPrice',
            'lastUnitCost',
            'netPrice',
            'unitCost'
          ]
          if (hasFourDecimals.includes(col.field)) {
            return formatDollarFields(params, 4, 4)
          }

          return formatDollarFields(params, 2, 2)
        }
      }
    }

    if (percentageFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        cellStyle(params) {
          if (params.value && params.value < 0) {
            return { color: '#ff0000' }
          }
          return null
        },
        valueFormatter: params =>
          params.value || params.value === 0 ? `${params.value}%` : params.value
      }
    }

    if (calcPercentageFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        cellClass: 'align-right',
        headerClass: 'align-right',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        cellStyle(params) {
          if (params.value && params.value * 100 < 0) {
            return { color: '#ff0000' }
          }
          return null
        },
        valueFormatter: params =>
          params.value || params.value === 0
            ? `${(params.value * 100).toFixed(2)}%`
            : params.value
      }
    }

    if (roundedPercentageFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        cellClass: 'align-right',
        headerClass: 'align-right',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        cellStyle(params) {
          if (params.value && params.value * 100 < 0) {
            return { color: '#ff0000' }
          }
          return null
        },
        valueFormatter: params =>
          params.value || params.value === 0
            ? `${Math.round(params.value * 100)}%`
            : params.value
      }
    }

    if (commaFormattedFields.indexOf(col.field) !== -1) {
      col = {
        ...col,
        cellClass: 'align-right',
        headerClass: 'align-right',
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        cellStyle(params) {
          if (params.value && params.value < 0) {
            return { color: '#ff0000' }
          }

          if (col.field === 'age' && params.value === 0) {
            return { display: 'none' }
          }

          return null
        },
        valueFormatter: params => {
          if (params.value === 0) {
            return 0
          }

          return params.value && Number.isInteger(params.value)
            ? params.value.toLocaleString()
            : params.value
        }
      }
    }

    /* recur if there is a parent header */
    if (col.children) {
      col = {
        ...col,
        children: commonFormatGridCells(
          col.children,
          dollarFields,
          dateFields,
          percentageFields
        )
      }
    }

    return col
  })

  return ret
}

export const labelIcon = (style = {}) => (
  <Icon
    style={{
      marginRight: 5,
      verticalAlign: 'middle',
      fontSize: 16,
      color: '#517b9c',
      ...style
    }}
  >
    info
  </Icon>
)

export const setGridHeight = (
  array = [],
  additionalHeight = 0,
  headerHeight = 32
) => array.length * 28 + (headerHeight + array.length) + additionalHeight

export const getCurrentYear = (asString = false) => {
  const date = new Date()

  if (asString) {
    return date.getFullYear().toString()
  }

  return date.getFullYear()
}

export const setExpandableGridRowHeight = (
  params,
  key = 'payments',
  headerHeight = 0
) => {
  if (
    params.node &&
    params.node.detail &&
    params.data[key] &&
    params.data[key].length
  ) {
    return setGridHeight(params.data[key], 3, headerHeight)
  }

  return 28
}

export const redrawExpandableGridRows = params => {
  setTimeout(() => {
    params.api.redrawRows()
  }, 0)
}

export const expandableGridBaseConfig = {
  master: {
    animateRows: true,
    enableSorting: true,
    masterDetail: true,
    suppressPropertyNamesCheck: true,
    width: '100%'
  },
  detail: {
    animateRows: true,
    enableColResize: false,
    enableSorting: true,
    headerHeight: 0,
    suppressPropertyNamesCheck: true,
    suppressHorizontalScroll: true
  }
}

export const addDisputedNoteTooltip = data =>
  data.map((col, i) => {
    col = {
      ...col
    }

    if (!col.cellRendererFramework) {
      col = {
        ...col,
        cellRendererFramework: GridLinkCell,
        cellRendererParams: {
          tooltip: 'tooltip'
        }
      }
    }

    return col
  })

export const includeDeleteGridCell = (
  colDefs = [],
  propertyName,
  form,
  field = 'delete',
  width = 60
) => [
  ...colDefs,
  {
    cellRendererFramework: DeleteGridCell,
    field,
    form,
    propertyName,
    headerName: '',
    suppressNavigable: true,
    type: 'deleteGridRow',
    width,
    maxWidth: width
  }
]

export const getCellColor = value =>
  value && value?.replace && Number(value.replace(/,/gi, '')) < 0
    ? errorRed
    : darkGrey

export const objectHasProperty = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop)

export const mapActivitiesResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (next === 'activities') {
        acc[next] = response[next].activities
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  return res
}

export const mapDataResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  // console.log(JSON.stringify(response))
  // debugger

  const nested = [
    ...tabIds,
    ...groupNames,
    'activities',
    'afflink',
    'consignment',
    'creditReferences',
    'johnstone',
    'partNumbers',
    'poSpecs',
    'priceContracts',
    'sources',
    'taxMatrix',
    'webOptions',
    'customFields'
  ]

  const requireRowIdsForGridEditing = [
    'consignmentManagerItems',
    'creditReferenceManagerItems',
    'partNumbers',
    'poNumbers',
    'poPatterns',
    'priceContracts',
    'sources',
    'vendorIDs',
    'miscChargePrograms'
  ]

  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (
        nested.includes(next) &&
        response[next] !== null &&
        typeof response[next] === 'object' &&
        Object.keys(response[next]).length
      ) {
        for (const prop in response[next]) {
          if (prop === 'customFieldsData') {
            acc = mapCustomFieldsData(acc, response[next][prop])
          }

          acc[prop] = requireRowIdsForGridEditing.includes(prop)
            ? handleEditableGridAPIData(formState, response, next, prop)
            : response[next][prop]
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  // console.log(res)
  // debugger

  return res
}

export const isTabHidden = form => {
  const {
    values: { preNewMode, newMasterRecord, templateKey },
    newEntity
  } = form

  if (preNewMode || newMasterRecord || templateKey || newEntity) {
    return true
  }

  return false
}
