import { getIn, setIn } from 'utils'
import { Map, fromJS } from 'immutable'
import * as CONSTANTS from './constants'

/*
  only thing we need to keep track of in Redux is the list of printers,
  which changes when the user toggles either "All Printers" or "Session Default"
*/
const printDocumentModalBehaviors = {
  [CONSTANTS.GET_ALL_PRINTERS.SUCCESS]: (state, { payload: { printers } }) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal.printers', fromJS(printers))
    return result
  },
  [CONSTANTS.SET_SESSION_DEFAULT.SUCCESS]: (
    state,
    { payload: { printers } }
  ) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal.printers', fromJS(printers))
    return result
  },
  [CONSTANTS.CANCEL_PRINT_DOCUMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal', fromJS({}))
    return result
  },
  [CONSTANTS.VIEW_DOCUMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal', fromJS({}))
    return result
  },
  [CONSTANTS.PRINT_DOCUMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal', fromJS({}))
    return result
  },
  [CONSTANTS.SAVE_DOCUMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'meta.printDocumentModal', fromJS({}))
    return result
  }
}

export default printDocumentModalBehaviors
