import React from 'react'
import PropTypes from 'prop-types'
import { GridField } from 'ddiForm'
import { formatDateFields } from 'utils'
import { headerStyle } from 'pages/ProductSerialNumberInquiry/utils'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { DynamicGridDataTooltip } from 'hoc'

export const getRowNodeId = data => {
  const { dataId, productId, transactionId } = data
  return `${dataId} - ${productId} - ${transactionId}`
}

const dynamicColDefs = form => {
  const colDefs = [
    {
      field: 'dataId',
      headerName: 'Serial No'
    },
    {
      cellRendererFramework: DynamicGridDataTooltip({
        dataField: 'notes'
      })(NotesCheckbox),
      cellRendererParams: {
        form,
        propertyName: 'completeSerialHistory'
      },
      headerClass: 'text-center',
      headerName: 'Notes',
      field: 'notes'
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      linkTo: 'productMasterScreen',
      field: 'productId',
      headerName: 'Product No'
    },
    {
      field: 'productDescription',
      headerName: 'Description'
    },
    {
      field: 'transactionTypeDescription',
      headerName: 'Transaction Type'
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'transactionId',
      headerName: 'Transaction No'
    },
    {
      field: 'transactionDescription',
      headerName: 'Transaction Description'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'transactionDate',
      headerClassName: 'text-center',
      headerName: 'Transaction Date',
      valueFormatter: formatDateFields
    }
  ]

  return colDefs
}

const CompleteSerialHistory = ({ form, height }) => (
  <div className="product-serial-number-inquiry-layout-flex">
    <div className="flex-wrapper">
      <GridField
        propertyName="completeSerialHistory"
        title="Complete Serial History"
        headerStyle={headerStyle}
        columnDefs={dynamicColDefs(form)}
        getRowNodeId={getRowNodeId}
        height={height}
        rowSelection="single"
      />
    </div>
  </div>
)

CompleteSerialHistory.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default CompleteSerialHistory
