import React, { useState } from 'react'
// import PropTypes from 'prop-types'
// import memoize from 'memoize-one'
import { AppBar, Typography, Tabs, Tab } from '@material-ui/core'

import Payments from './components/PaymentsGrid'
import VoidedPayments from './components/VoidedPaymentsGrid'

const TabContainer = props => (
  <Typography component="div">{props.children}</Typography>
)


export const AmountPaidGrid = ({ form, dispatch }) => {

  const [currentTab, setCurrentTab] = useState('Payments')

  const handleTabChange = (_, value) => setCurrentTab(value)

  return (
    <>
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Tabs onChange={handleTabChange} value={currentTab}>
          <Tab value="Payments" label="Payments" />
          <Tab value="Voided Payments" label="Voided Payments" />
        </Tabs>
      </AppBar>
      <TabContainer>
        {currentTab === 'Payments' && (
          <Payments form={form} dispatch={dispatch} />
        )}
        {currentTab === 'Voided Payments' && (
          <VoidedPayments form={form} />
        )}
      </TabContainer>
    </>
  )
}

export default AmountPaidGrid