import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { emptyList, getIn } from 'utils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
// import { DDITextField } from 'ddiForm/wrapped'

import { addItemGroupCell } from '../../actions'

const mapState = (state, ownProps) => {
  const { form } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const groups = getIn(formState, 'values.groups.itemGroups') || emptyList
  const groupOptions = getIn(formState, 'values.groups.itemGroupOptions') || emptyList
  const canAddGroups = getIn(formState, 'values.canAddGroups') || false


  return {
    groups,
    groupOptions,
    canAddGroups
  }
}

export const AddItemGroupCell = ({
  api,
  data: {
    lineNumber
  },
  form,
  value = '',
  colDef,
  groups,
  groupOptions,
  canAddGroups,
  dispatch,
  rowIndex,
  ...rest
}) => {

  const [val, setVal] = useState({ dataId: ''})
  const [textFieldVal, setTextFieldVal] = useState([])
  const [groupsList, setGroupsList] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (value || value === '') {
      setVal({ dataId: value })
    }
  }, [value])

  useEffect(() => {
    if (groups.toJS) {
      setGroupsList(groups.toJS())
    }
  }, [groups])

  useEffect(() => {
    if (groupOptions.toJS) {
      setOptions(groupOptions.toJS())
    }
  }, [groupOptions])

  const onKeyDown = ({ keyCode}) => {
    const TAB = 9

    if (keyCode === TAB) {
      dispatch(addItemGroupCell.try(form, {
        gridApi: api,
        value: textFieldVal,
        lineNumber,
        ...rest
      }))

      api.stopEditing()
    }

  }

  const onChange = (e, { dataId }) => {
    if (dataId) {
      setTextFieldVal(dataId)
    } else {
      setTextFieldVal('')
    }
  }

  const onTextFieldChange = e => {
    setTextFieldVal(e.target.value)
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: '100%'
      }}>
      <Autocomplete
        id="add-item-group"
        options={canAddGroups ? groupsList : options}
        getOptionLabel={(option) => option.dataId}
        // style={{ width: 300 }}
        freeSolo={canAddGroups}
        forcePopupIcon={!!groupsList.length}
        onKeyDown={onKeyDown}
        value={val}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            value={textFieldVal}
            onChange={onTextFieldChange}
          />)}
      />
    </div>
  )
}

export default connect(mapState)(AddItemGroupCell)