import React from 'react'
import { DDITextField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import StateSelectField from 'components/StateSelectField'
import { twoColFieldWrapperStyle } from '../../../utils'
import '../../../styles/form-styles.scss'

const OrderAddress = props => {
  return (
    <DDICardWrapper title="Order Address" contentStyle={{ padding: 10 }}>
      <div className="vendor-master-form-outer-wrapper">
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="orderName"
            label="Name"
            wrapperStyle={twoColFieldWrapperStyle}
          />
          <DDITextField
            propertyName="orderAddressLine1"
            label="Address"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="orderAddressLine2"
            label="Address Line 2"
            wrapperStyle={twoColFieldWrapperStyle}
          />
          <DDITextField
            propertyName="orderCity"
            label="City"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
        <div
          className="vendor-master-form-row-wrapper"
          style={{ alignItems: 'baseline' }}
        >
          <StateSelectField
            propertyName="orderState"
            margin="dense"
            style={twoColFieldWrapperStyle}
            fullWidth
          />
          <DDITextField
            propertyName="orderZipcode"
            label="Zip Code"
            placeholder="Zip Code"
            mask="zipCode"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
      </div>
    </DDICardWrapper>
  )
}

export default OrderAddress
