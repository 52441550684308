import React, { useEffect, useState } from 'react'
import Grid from 'grid'
import Measure from 'react-measure'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { DDIToggle, DDISelectField } from 'ddiForm/wrapped'
import { createFooterTotals, postProcessPopup } from 'hoc/withRowTotals/utils'
import {
  emptyList,
  formatDate,
  formatNumber,
  getIn,
  plainDeepEqual
} from 'utils'

import {
  getCellStyle,
  getDetailColumnField,
  setGridHeight,
  sizeDetailColumns,
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/VendorMaster/utils'

import DollarGridCell from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/DollarGridCell'
import InvoiceLinkCell from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/InvoiceLinkCell'

/* local imports */
import AwaitingBackdrop from './AwaitingBackdrop'
import InvoiceButtons from './InvoiceButtons'
import RemitVendorTooltip from './grid/RemitVendorTooltip'

import '../styles/ledger-styles.scss'
import { getAttachments } from '../../../../../components/MasterScreen/Attachments/actions'

export const setExpandableGridRowHeight = params => {
  if (params?.node?.detail && params?.data?.payments) {
    return setGridHeight(params.data.payments, 3, 0)
  }

  return 28
}

export const getPinnedRowData = memoize(grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      acc.grossAmount += next.grossAmount
      acc.discount += next.discount
      acc.balanceDue += next.balanceDue
      return acc
    },
    { dataId: 'Totals', balanceDue: 0.0, discount: 0.0, grossAmount: 0.0 }
  )

  return [result]
}, plainDeepEqual)

let gridApi = null
let columnApi = null
let _isMounted = false

const InvoicesMain = ({
  dataId,
  dimensions,
  form,
  gridMaxHeight,
  height,
  isEditing,
  invoicesList,
  invoiceTypeOptionsList,
  defaultInvoiceType,
  invoiceType,
  includeRemitToInvoices,
  defaultInvoiceYear,
  invoiceYear,
  yearOptionsList,
  fetchingEntity,
  dispatch
}) => {
  const [inputDimensions, setDimensions] = useState({
    width: -1,
    height: -1
  })

  const invoices = invoicesList?.toJS ? invoicesList.toJS() : []
  const yearOptions = yearOptionsList?.toJS ? yearOptionsList.toJS() : []
  const invoiceTypeOptions = invoiceTypeOptionsList?.toJS
    ? invoiceTypeOptionsList.toJS()
    : []

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const getColumnDefs = memoize(type => {
    let colDefs = [
      {
        colId: 'dataId',
        field: 'dataId',
        headerName: 'Document',
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        minWidth: 125
      },
      {
        // field: 'dataId',
        colId: 'dataId_50',
        headerName: '',
        cellRendererFramework: InvoiceLinkCell,
        type: 'invoices',
        minWidth: 50,
        width: 50,
        maxWidth: 50
      },
      {
        colId: 'branchId',
        field: 'branchId',
        headerName: 'Br/Bnk',
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip
      },
      {
        colId: 'statusDescription',
        field: 'statusDescription',
        headerName: 'Status',
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip
      },
      {
        colId: 'invoiceDate',
        field: 'invoiceDate',
        headerName: 'Date',
        headerClass: 'text-center',
        cellClass: 'text-center',
        valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        ...dateFilterGridConfigs
      },
      {
        colId: 'pOorReferenceNumber',
        field: 'pOorReferenceNumber',
        headerName: 'P/O Ref #',
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip
      },
      {
        colId: 'dueDate',
        field: 'dueDate',
        headerName: 'Due Date',
        headerClass: 'text-center',
        cellClass: 'text-center',
        valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip
      },
      {
        colId: 'grossAmount',
        field: 'grossAmount',
        headerName: 'Amount',
        headerClass: 'align-right',
        cellClass: 'align-right',
        valueFormatter: params => formatNumber(params.value),
        minWidth: 150,
        cellStyle: getCellStyle,
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        ...numberFilterGridConfigs
      },
      {
        colId: 'discount',
        field: 'discount',
        headerName: 'Discount',
        headerClass: 'align-right',
        cellClass: 'align-right',
        valueFormatter: params => formatNumber(params.value),
        minWidth: 150,
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        ...numberFilterGridConfigs
      },
      {
        colId: 'discountDate',
        field: 'discountDate',
        headerName: 'Disc Date',
        headerClass: 'text-center',
        cellClass: 'text-center',
        valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        ...dateFilterGridConfigs
      },
      {
        colId: 'balanceDue',
        field: 'balanceDue',
        headerName: 'Balance',
        headerClass: 'align-right',
        cellClass: 'align-right',
        valueFormatter: params => formatNumber(params.value),
        minWidth: 150,
        cellStyle: getCellStyle,
        tooltipField: 'remitToVendorDetails',
        tooltipComponent: RemitVendorTooltip,
        ...numberFilterGridConfigs
      }
    ]

    if (type === 'P') {
      colDefs = [
        {
          cellClass: 'cell-value-hidden expander-cell',
          cellRenderer: 'agGroupCellRenderer',
          colId: 'dataId_35',
          field: 'dataId',
          headerName: '',
          filter: false,
          minWidth: 35,
          maxWidth: 35,
          width: 35
        },
        ...colDefs
      ]
    }

    return colDefs
  })

  const columnDefs = getColumnDefs(invoiceType)

  const detailColumnDefs = columnDefs.reduce((acc, next, idx) => {
    const field = getDetailColumnField(next.field)
    if (idx > 0) {
      let additionalParams =
        next.colId === 'dataId'
          ? {
              headerClass: 'align-right',
              cellClass: 'align-right'
            }
          : {}

      if (next?.colId === 'dataId_50') {
        additionalParams = {
          cellRendererFramework: DollarGridCell
        }
      }

      const { tooltipField, tooltipComponent, ...other } = next
      /* no tooltips for child rows */
      const colDef = {
        ...other,
        colId: field,
        field,
        ...additionalParams
      }

      acc = acc.concat(colDef)
    }

    return acc
  }, [])

  const isRowMaster = row => {
    return !!(
      row?.payments &&
      Array.isArray(row.payments) &&
      row?.payments?.length
    )
  }

  const getContextMenuItems = params => {
    const {
      node: {
        data: { dataId, uniqueKey, id }
      }
    } = params

    const items = [
      {
        name: 'Attachments',
        action: () => {
          dispatch(
            getAttachments.try(form, {
              uniqueKey,
              targetType: 'ledgerInvoice',
              modalTitle: `Attachments for Inv - ${dataId}`,
              invoiceType,
              invoiceYear,
              includeRemitToInvoices
            })
          )
        }
      },
      'rows',
      'exportTo',
      'filter',
      'layout'
    ]

    return items
  }

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  const onCompleteGridResizeActions = () => {
    setTimeout(() => {
      sizeDetailColumns(columnApi, gridApi, _isMounted)
    }, 0)
  }

  const redrawFooter = ({ api }) =>
    createFooterTotals(api, getPinnedRowData, _isMounted, false)

  return (
    <div style={{ width: '100%' }}>
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="vendor-ledger-inputs-wrapper">
            <div className="vendor-ledger-inputs">
              <div style={{ marginRight: 15 }}>
                <DDISelectField
                  label="Type"
                  propertyName="invoices.invoiceType"
                  values={invoiceTypeOptions}
                  initialValue={defaultInvoiceType}
                  preventAutoDisable
                  disabled={!dataId}
                />
              </div>
              {invoiceType === 'P' ? (
                <div style={{ marginRight: 15 }}>
                  <DDISelectField
                    label="Year"
                    propertyName="invoices.invoiceYear"
                    values={yearOptions}
                    initialValue={defaultInvoiceYear}
                    preventAutoDisable
                    disabled={!dataId}
                  />
                </div>
              ) : null}
            </div>
            <DDIToggle
              propertyName="invoices.includRemitToInvoices"
              label="Include Remit To Invoices"
              preventAutoDisable
            />
            <div className="button-set-wrapper">
              <InvoiceButtons form={form} dataId={dataId} />
            </div>
          </div>
        )}
      </Measure>
      <div
        className="vendor-ledger-grid-wrapper"
        style={{ position: 'relative' }}
      >
        <AwaitingBackdrop fetchingEntity={fetchingEntity} />
        <Grid
          title="Invoices"
          reactUi={false}
          headerStyle={headerStyle}
          height={gridMaxHeight - inputDimensions?.height}
          columnDefs={columnDefs}
          rowData={invoices}
          getRowNodeId={data => data.dataId}
          suppressRowClickSelection
          applyColumnDefOrder
          rowSelection="multiple"
          key={`${dataId}-${invoiceType}-${invoiceYear}`}
          masterDetail
          getContextMenuItems={getContextMenuItems}
          onGridReady={onGridReady}
          getRowHeight={setExpandableGridRowHeight}
          isRowMaster={isRowMaster}
          onColumnResized={onCompleteGridResizeActions}
          onRowGroupOpened={onCompleteGridResizeActions}
          detailCellRendererParams={{
            detailGridOptions: {
              suppressAutoSize: true,
              animateRows: true,
              enableColResize: false,
              // headerHeight: 0,
              suppressHorizontalScroll: true,
              getRowNodeId: data => data.dataId,
              columnDefs: detailColumnDefs,
              immutableData: false
            },
            getDetailRowData: params => {
              debugger
              const payments =
                params?.data?.payments && Array.isArray(params?.data?.payments)
                  ? params?.data?.payments
                  : []

              params.successCallback(payments)
              setTimeout(() => {
                sizeDetailColumns(columnApi, gridApi, _isMounted)
              }, 0)
            },
            template:
              '<div class="vl-outer-detail-wrapper outer-detail-wrapper" style="padding: 0; box-sizing: border-box;">' +
              '  <div ref="eDetailGrid" style="height: 100%;"></div>' +
              '</div>'
          }}
          postProcessPopup={postProcessPopup}
          onFilterChanged={redrawFooter}
          onRowDataChanged={redrawFooter}
          onRowDataUpdated={redrawFooter}
        />
      </div>
    </div>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  isEditing: form => form.get('isEditing') || false,
  invoicesList: form => getIn(form, 'values.invoices.invoices') || emptyList,
  invoiceTypeOptionsList: form =>
    getIn(form, 'meta.ledgerMeta.invoiceTypeOptions') || emptyList,
  yearOptionsList: form =>
    getIn(form, 'meta.ledgerMeta.yearOptions') || emptyList,
  defaultInvoiceType: form => getIn(form, 'values.invoices.invoiceType') || 'I',
  defaultInvoiceYear: form => getIn(form, 'meta.ledgerMeta.defaultYear') || '',
  invoiceType: form => getIn(form, 'fields.invoices.invoiceType.value') || '',
  invoiceYear: form => getIn(form, 'fields.invoices.invoiceYear.value') || '',
  includeRemitToInvoices: form =>
    getIn(form, 'fields.invoices.includeRemitToInvoices.value') || false,
  fetchingEntity: form => form.get('fetchingEntity') || false
})(InvoicesMain)
