import React from 'react'
import { isEqual } from 'lodash'
import masterStyles from 'theme/masterStyles'
import { layoutFlex } from 'utils'
import Quote from './components/Quote'
import CustomerInfo from './components/CustomerInfo'
import Recurring from './components/Recurring'
import SalesOrderInfo from './components/SalesOrderInfo'
import TopTabs from './components/TopTabs'
import BottomTabs from './components/BottomTabs'

export const Header = React.memo(({ form, dispatch }) => {
  return (
    <div style={layoutFlex('120rem')}>
      <div style={masterStyles.twoColFlex.wrapper}>
        <div style={masterStyles.twoColFlex.left}>
          <div style={{ marginBottom: 10 }}>
            <CustomerInfo form={form} />
          </div>
          <SalesOrderInfo />
        </div>
        <div style={masterStyles.twoColFlex.right}>
          {form !== 'invoiceInquiry' ? (
            <Quote form={form} dispatch={dispatch} />
          ) : null}
          {form !== 'invoiceInquiry' ? (
            <Recurring form={form} dispatch={dispatch} />
          ) : null}
          <TopTabs form={form} dispatch={dispatch} />
          <BottomTabs form={form} dispatch={dispatch} />
        </div>
      </div>
    </div>
  )
}, isEqual)

export default Header
