/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const readVendorMaster = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/vendormaster/read`, options)
}

export const saveVendorAgingView = ({ dataId, view }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      view
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/vendormaster/saveagingview`, options)
}

export const getVendorInvoiceReport = ({
  dataId,
  invoiceType,
  invoiceYear,
  includeRemitToInvoices
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      invoiceType,
      invoiceYear,
      includeRemitToInvoices
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/vendormaster/report/invoices`,
    options
  )
}

export const getVendorPaymentHistoryReport = ({
  dataId,
  paymentHistoryYear
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      paymentHistoryYear
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/vendormaster/report/paymenthistory`,
    options
  )
}

export const vendorMasterPropertyChange = ({
  dataId,
  templateKey,
  groupNames,
  properties,
  ...rest
}) => {
  const apiParams = templateKey
    ? {
        templateKey,
        groupNames,
        properties,
        ...rest
      }
    : {
        dataId,
        groupNames,
        properties,
        ...rest
      }

  const options = createApiOptions({
    method: 'post',
    body: apiParams
  })

  const apiRoute = templateKey
    ? `${BASE_INFORM_API_URL}/vendormaster/template/propertychange`
    : `${BASE_INFORM_API_URL}/vendormaster/propertychange`
  return callFetch(apiRoute, options)
}

export const deleteVendor = ({ dataId, templateKey, ...rest }) => {
  const apiParams = templateKey
    ? {
        templateKey
      }
    : {
        dataId
      }

  const options = createApiOptions({
    method: 'post',
    body: apiParams
  })

  const apiRoute = templateKey
    ? `${BASE_INFORM_API_URL}/vendormaster/template/delete`
    : `${BASE_INFORM_API_URL}/vendormaster/delete`

  return callFetch(apiRoute, options)
}

export const createVendor = ({ dataId, autoGenerateId = false, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: autoGenerateId
      ? { ...rest }
      : {
          dataId,
          ...rest
        }
  })
  return callFetch(`${BASE_INFORM_API_URL}/vendormaster/create`, options)
}
