import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { GridField, withContext } from 'ddiForm'
import {
  dateFilterComparator,
  formatDateFields,
  formatNumber,
  layoutFlex,
  emptyList,
  getValue
} from 'utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { headerStyle } from 'pages/ProductMaster/utils'

import './styles/master-style.scss'

export const getRowNodeId = data => {
  const { dataId, po } = data
  const compId = `${dataId} - ${po}`
  return compId
}

export const dynamicColumnDefs = memoize(({ form, type }) => {
  const colDefs = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: params => ({
        dataId: params.data.po,
        form
      }),
      field: 'po',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'P/O',
      linkTo: type
    },
    {
      field: 'vendorName',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Vendor Name'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'orderedDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label',
      headerName: 'Ordered',
      valueFormatter: formatDateFields
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'price',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label align-right',
      headerName: 'Price',
      valueFormatter: ({ value }) => formatNumber(value, '$0,0.0000')
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'discountPercent',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Discount %',
      valueFormatter: ({ value }) => formatNumber(value, '0.00')
    },
    {
      cellClass: 'text-center',
      field: 'expectedDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerName: 'Expected',
      headerClass: 'text-center'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'receivedDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label text-center',
      headerName: 'Received',
      valueFormatter: formatDateFields
    }
  ]
  return colDefs
})

export const Vendors = ({ form, height, vendors }) => {
  const [gridApi, setGridApi] = useState(null)

  useEffect(() => {
    if (gridApi) {
      gridApi.setFilterModel(null)
    }
  }, [vendors])

  function onGridReady({ api }) {
    setGridApi(api)
  }

  return (
    <div style={layoutFlex('120rem')}>
      <div className="product-analysis-flex-wrapper-1">
        <div style={{ height: '95%' }}>
          <GridField
            propertyName="productVendors"
            title="Recent Vendor Information"
            getRowNodeId={getRowNodeId}
            headerStyle={headerStyle}
            onGridReady={onGridReady}
            columnDefs={dynamicColumnDefs({
              form,
              type: 'purchaseOrder'
            })}
            rowSelection="single"
            width="100%"
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

Vendors.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export const contextObj = {
  vendors: form => getValue(form, 'productVendors', emptyList)
}

export default withContext(contextObj)(Vendors)
