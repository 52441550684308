import { call, fork, put, select, take } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'

import { getIn } from 'utils'

import { addModal, removeModal } from 'modals/actions'

import RecurringLineItemsScheduleModal from '../components/RecurringLineItemsScheduleModal'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { changeGridItem as changeGridItemAPI } from '../api'

export function* displayRecurringLineItemsScheduleModal(
  form,
  data = {},
  lineNumber,
  isEditing
) {
  const modalOpts = {
    component: RecurringLineItemsScheduleModal,
    options: {
      actions: false,
      title: 'Line Item Schedule',
      data: {
        form,
        lineNumber,
        isEditing,
        ...data
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 750
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* handleLineItemScheduleInteraction(
  form,
  thunk,
  lineNumber,
  action,
  apiParams = {},
  modalId = null
) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  yield put(actions.handleRecurringOrderInteraction.request(form))

  const apiPostParams = apiParams?.properties?.loadSchedule
    ? {
        guid,
        gridName: 'lineitemschedule',
        properties: {
          loadSchedule: null
        }
      }
    : {
        lineNumber,
        guid,
        gridName: 'lineitemschedule',
        action,
        groupNames,
        ...apiParams
      }

  const { response, error } = yield call(changeGridItemAPI, apiPostParams)

  if (response) {
    yield put({
      type: CONSTANTS.HANDLE_RECURRING_ORDER_INTERACTION.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        action,
        activeLineNumber: apiParams?.lineNumber
      }
    })

    if (action === 'initialize') {
      yield fork(
        displayRecurringLineItemsScheduleModal,
        form,
        response,
        lineNumber,
        isEditing
      )
    }

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put({
      type: CONSTANTS.HANDLE_RECURRING_ORDER_INTERACTION.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error,
        action,
        activeLineNumber: apiParams?.lineNumber
      }
    })
    yield fork(displayValidationErrors, error)
  }
}

export function* handleRecurringOrderInteractionListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { apiParams = {}, modalId = null, action, lineNumber }
    } = yield take(CONSTANTS.HANDLE_RECURRING_ORDER_INTERACTION.TRY)

    if (form === formListener) {
      yield fork(
        handleLineItemScheduleInteraction,
        form,
        thunk,
        lineNumber,
        action,
        apiParams,
        modalId
      )
    }
  }
}

export default function* recurringOrderInteractionListener(form) {
  yield fork(handleRecurringOrderInteractionListener, form)
}
