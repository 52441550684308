/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getRgb } from 'utils'

class ColorDataCell extends Component {
  static propTypes = {
    data: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number
    ])
  }

  static defaultProps = {
    data: null,
    value: null
  }

  getColorFormatting = (data, value) => {
    if (data && typeof data === 'object' && data.color) {
      return Object.keys(data.color).length ? getRgb(data.color) : '#444'
    }

    if (value && typeof value === 'object' && value.color) {
      return Object.keys(value.color).length ? getRgb(value.color) : '#444'
    }

    return '#444'
  }

  displayCellValue = (valueFormatted, value) => {
    if (valueFormatted) {
      return valueFormatted
    }

    if ((value || value === 0) && typeof value !== 'object') {
      return value
    }
    return ''
  }

  render() {
    const { data, value, valueFormatted } = this.props
    return (
      <div>
        <span style={{ color: this.getColorFormatting(data, value) }}>
          {this.displayCellValue(valueFormatted, value)}
        </span>
      </div>
    )
  }
}

export default ColorDataCell
