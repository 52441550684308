import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import { formatDateFields } from 'utils'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import {
  headerStyle,
  isDisabled,
  disabledWhenNoWarehouse,
  isWarehouseBinEnabled
} from 'pages/ProductSerialNumberInquiry/utils'
import { DynamicGridDataTooltip } from 'hoc'
import DatepickerGridCell from 'components/EditableGrid/components/DatepickerGridCell'
import DeleteSerialNumberRowCell from 'pages/ProductSerialNumberInquiry/components/DeleteSerialNumberRowCell'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell/'
import moment from 'moment'
import withDimensions from 'hoc/withDimensions'
import { withContext } from 'ddiForm'

export const getRowNodeId = data => {
  const { dataId, dateEntered } = data
  return `${dataId} - ${dateEntered}`
}

export const dynamicColumnDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'warehouse',
      onChange: onCellChange,
      propertyName
    }
    const textEditor = {
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: params
    }
    const colDefs = [
      {
        ...textEditor,
        field: 'dataId',
        headerName: 'Serial Number'
      },
      {
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'notes',
          title: 'Notes'
        })(NotesCheckbox),
        cellRendererParams: {
          ...params,
          propertyName: 'currentInventory',
          disabled: isDisabled
        },
        field: 'notes',
        headerClass: 'text-center',
        headerName: 'Notes'
      },
      {
        field: 'dateEntered',
        headerName: 'Date',
        cellRendererFramework: DatepickerGridCell,
        cellRendererParams: {
          ...params,
          disabledFn: data => {
            if (!isEditing) {
              return true
            }
            return !data.dataId
          }
        },
        headerClass: 'text-center',
        cellStyle: { textAlign: 'center' },
        valueFormatter: formatDateFields,
        maxWidth: 150
      },
      {
        field: 'warehouseId',
        headerName: 'Warehouse',
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Warehouse',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          onChange: 'setIndexSearchValueAndDescription',
          requiredFn: disabledWhenNoWarehouse,
          disabledFn: isDisabled,
          linkTo: 'warehouseMaster',
          meta: {
            allowInstantSearch: true,
            minimumKeywordLength: 2,
            allowSearchAll: true
          }
        }
      },
      {
        field: 'description',
        headerName: 'Description'
      },
      {
        field: 'binLocation',
        headerName: 'Bin Location'
      },
      {
        cellRendererFramework: DeleteSerialNumberRowCell,
        cellRendererParams: {
          ...params,
          disabled: isWarehouseBinEnabled
        },
        headerName: 'Delete?',
        hide: !isEditing
      }
    ]
    return colDefs
  }
)

const gridApi = null
const columnApi = null
const _isMounted = false

export const CurrentInventory = ({ form, height, isEditing }) => {
  return (
    <div className="product-serial-number-inquiry-layout-flex">
      <div className="flex-wrapper">
        <EditableGrid
          propertyName="currentInventory"
          title="Current Inventory"
          addButtonText="Add Serial Number"
          showAddButtonOnlyIfEditing
          columnDefs={dynamicColumnDefs}
          form={form}
          isEditing={isEditing}
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          // gridHeight={600}
          height={height}
          fullWidth
          width="100%"
          emptyRow={{
            dataId: '',
            dateEntered: moment().format(),
            notes: null,
            hasNotes: false,
            newData: true,
            isNew: true
          }}
          requiredCols={['dataId', 'warehouseId']}
          allowInsertRow
        />
      </div>
    </div>
  )
}

CurrentInventory.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditing: PropTypes.bool
}

CurrentInventory.defaultProps = {
  isEditing: false
}

const CurrentInventoryWithContext = withContext({
  isEditing: form => form.get('isEditing') || false
})(CurrentInventory)

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
})(CurrentInventoryWithContext)
