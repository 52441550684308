import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
// import { plainDeepEqual } from 'utils'
import { Checkbox, GridField, Field } from 'ddiForm'
import { DDITextField } from 'ddiForm/wrapped'
// import DDICardWrapper from 'components/DDICardWrapper'
import { Paper } from '@material-ui/core'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { headerStyle } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

import './styles/assembly.scss'

export const getRowNodeId = data => {
  const { dataId, description } = data

  return `${dataId} - ${description}`
}

const dynamicColumnDefs = memoize(({ form, type }) => {
  const defaultDef = {
    filter: false,
    suppressMenu: true
  }
  const colDef = [
    {
      ...defaultDef,
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'dataId',
      headerName: 'Product',
      linkTo: type,
      groupNames: ['analysis', 'assembly']
    },
    {
      ...defaultDef,
      field: 'description',
      headerName: 'Description'
    },
    {
      ...defaultDef,
      field: 'uomId',
      headerName: 'UOM'
    },
    {
      ...defaultDef,
      cellStyle: params => ({
        color: params?.value < 0 ? '#d9534f' : 'auto',
        textAlign: 'right'
      }),
      field: 'quantity',
      headerClass: 'numeric-value-label',
      headerName: 'Quantity'
    },
    {
      ...defaultDef,
      cellStyle: params => ({
        color: params?.value < 0 ? '#d9534f' : 'auto',
        textAlign: 'right'
      }),
      field: 'onHand',
      headerClass: 'numeric-value-label',
      headerName: 'On Hand'
    },
    {
      ...defaultDef,
      cellStyle: params => ({
        color: params?.value < 0 ? '#d9534f' : 'auto',
        textAlign: 'right'
      }),
      field: 'committed',
      headerClass: 'numeric-value-label',
      headerName: 'Committed'
    },
    {
      ...defaultDef,
      cellStyle: params => ({
        color: params?.value < 0 ? '#d9534f' : 'auto',
        textAlign: 'right'
      }),
      field: 'onOrder',
      headerClass: 'numeric-value-label',
      headerName: 'On Order'
    },
    {
      ...defaultDef,
      cellStyle: params => ({
        color: params?.value < 0 ? '#d9534f' : 'auto',
        textAlign: 'right'
      }),
      field: 'available',
      headerClass: 'numeric-value-label',
      headerName: 'Available'
    }
  ]

  return colDef
})

const Assembly = ({ form, height }) => (
  <div className="product-analysis-assembly-layout-flex">
    <div className="flex-wrapper-1">
      <Paper
        style={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          margin: '0 0 10px 0',
          maxWidth: '100%',
          padding: '10px 20px',
          width: '100%'
        }}
      >
        <Field
          propertyName="assembly.kitProduction"
          label="Kit Production"
          component={Checkbox}
        />
        <Field
          propertyName="assembly.printOnSalesOrder"
          label="Print On Sales Order"
          component={Checkbox}
        />
        <DDITextField
          label="Rollup Options"
          propertyName="assembly.rollupOption"
        />
      </Paper>
      <GridField
        propertyName="assembly.components"
        title="Assemblies"
        getRowNodeId={getRowNodeId}
        headerStyle={headerStyle}
        columnDefs={dynamicColumnDefs({
          form,
          type: 'productMaster'
        })}
        height={height - 79}
        rowSelection="single"
        width="100%"
        fullWidth
      />
    </div>
  </div>
)

Assembly.propTypes = {
  dispatch: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired
}

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  Assembly
)
