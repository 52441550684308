import {
  take,
  call,
  select,
  put,
  spawn,
  fork,
  cancel
} from 'redux-saga/effects'
import { getIn, toCamelCase } from 'utils'

import { addModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { setField } from 'ddiForm/actions'
import { SET_FIELD } from 'ddiForm/constants'

import * as api from './api'
import * as actions from './actions'
import * as CONSTANTS from './constants'
import MergeModal from './index'

const checkIsRequesting = form => {
  const { fields } = form
  const isRequesting = getIn(fields, 'mergeModal.isRequesting')
  return isRequesting
}

export function* launchMergeModalProcess(
  form,
  data,
  searchType,
  title = 'Change Number',
  mergeModalComponent = MergeModal
) {
  // debugger
  // const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  // const customerID = getIn(formState, 'values.dataId')

  let actionButtons = [
    {
      primary: true,
      title: 'OK',
      async clickEvent(args, formState, cb) {
        try {
          await this.props.dispatch(actions.merge(form, { cb }))
        } finally {
          console.log('complete')
        }
      },
      disabled: f => checkIsRequesting(f)
    },
    {
      primary: true,
      title: 'Cancel',
      disabled: f => checkIsRequesting(f)
    }
  ]

  actionButtons = data.dataId
    ? [
        {
          primary: true,
          title: 'Delete',
          async clickEvent(args, formState, cb) {
            console.log(this, args, formState)
            try {
              await this.props.dispatch(
                actions.deletePendingMerge(form, { cb })
              )
            } finally {
              console.log('pending merge deleted')
              // cb()
            }
          },
          disabled: f => checkIsRequesting(f)
        },
        ...actionButtons
      ]
    : actionButtons

  const modalOpts = {
    component: mergeModalComponent,
    options: {
      // actions: [{ primary: true, title: 'Exit' }]
      data: {
        actions: actionButtons,
        form,
        searchType
      },
      width: 600,
      maxHeight: '100%',
      title
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal.payload.id
}

export function* openMergeModalProcess(
  form,
  searchType,
  title,
  mergeModalComponent
) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

  yield put({
    type: CONSTANTS.OPEN_MERGE_MODAL.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.openMergeModal, { dataId, form })

  console.log(response)

  if (response) {
    yield put({
      type: CONSTANTS.OPEN_MERGE_MODAL.SUCCESS,
      payload: response,
      meta: { form }
    })
    yield call(
      launchMergeModalProcess,
      form,
      response,
      searchType,
      title,
      mergeModalComponent
    )
  } else {
    yield put({
      type: CONSTANTS.OPEN_MERGE_MODAL.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* openMergeModalListener(
  formListener,
  searchType,
  title,
  mergeModalComponent
) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.OPEN_MERGE_MODAL.TRY)
    if (form === formListener) {
      yield call(
        openMergeModalProcess,
        form,
        searchType,
        title,
        mergeModalComponent
      )
    }
  }
}

export function* mergeProcess(form, cb, getAdditionalApiParams) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId = getIn(formState, 'fields.mergeModal.dataId.value')
  const toId = getIn(formState, 'fields.mergeModal.toId.value')
  const mergeOvernight =
    getIn(formState, 'fields.mergeModal.mergeOvernight.value') === 'true' ||
    false

  let additionalApiParams = {}
  if (getAdditionalApiParams && typeof getAdditionalApiParams === 'function') {
    additionalApiParams = yield call(getAdditionalApiParams, form)
  }

  if (!toId) {
    yield call(warningModal, 'To ID is required', 'Attention!')
    return
  }

  yield put({
    type: CONSTANTS.MERGE.REQUEST,
    meta: { form, apiRequest: true }
  })

  // debugger

  const { response, error } = yield call(api.merge, {
    ...additionalApiParams,
    dataId,
    toId,
    mergeOvernight,
    form
  })

  if (response) {
    if (cb) {
      cb()
    }

    yield put({
      type: CONSTANTS.MERGE.SUCCESS,
      payload: response,
      meta: { form }
    })

    if (!mergeOvernight) {
      yield put(resetMasterFields(form))
      yield put(setField(form, 'dataId', toId, false, true))
    }
  } else {
    yield put({
      type: CONSTANTS.MERGE.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* mergeListener(formListener, getAdditionalApiParams) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.MERGE.TRY)
    if (form === formListener) {
      yield call(mergeProcess, form, cb, getAdditionalApiParams)
    }
  }
}

export function* deletePendingMergeProcess(form, cb) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

  yield call(
    confirmationModal,
    'Are you sure you want to remove the pending merge?',
    'Delete?'
  )

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put({
      type: CONSTANTS.DELETE_PENDING_MERGE.REQUEST,
      meta: { form, apiRequest: true }
    })

    const { response, error } = yield call(api.deletePendingMerge, {
      dataId,
      form
    })

    if (response) {
      if (cb) {
        cb()
      }
      yield put({
        type: CONSTANTS.DELETE_PENDING_MERGE.SUCCESS,
        payload: response,
        meta: { form }
      })
    } else {
      yield put({
        type: CONSTANTS.DELETE_PENDING_MERGE.FAILURE,
        payload: error,
        meta: { form }
      })
    }
  } else if (cb) {
    cb()
  }
}

export function* deletePendingMergeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.DELETE_PENDING_MERGE.TRY)
    if (form === formListener) {
      yield call(deletePendingMergeProcess, form, cb)
    }
  }
}

export function* clearToMergeFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(CLEAR_SEARCH)

    if (form === formListener && propertyName === 'mergeModal.toId') {
      yield put(actions.clearUOMFields(form))
    }
  }
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, results }
    } = yield take(SET_FIELD)

    if (form === formListener && propertyName === 'mergeModal.toId') {
      if (results && results.description) {
        yield put(
          setField(form, 'mergeModal.toIdDescription', results.description)
        )
      } else {
        yield put(setField(form, 'mergeModal.toIdDescription', ''))
      }
    }
  }
}

export default function* mergeModalSagas(form, getAdditionalApiParams) {
  yield fork(mergeListener, form, getAdditionalApiParams)
  yield fork(deletePendingMergeListener, form)
  yield fork(clearToMergeFieldListener, form)
  yield fork(setFieldListener, form)
}
