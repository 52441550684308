import React, { useState, useEffect } from 'react'
import memoize from 'memoize-one'
import {
  MenuItem,
  InputLabel,
  // FormHelperText,
  FormControl,
  Select
} from '@material-ui/core'
import {
  clearPaymentTransactionData,
  initializePayment
} from 'pages/SalesOrder/actions'
import { getMeta } from 'utils'
import { debounce } from 'lodash'
import { PaymentButton, paymentModuleStyle } from '../../../utils'

const OfflineCreditCardPayment = ({
  dispatch,
  form,
  amountTendered,
  isEditing,
  isMobile = false,
  infoMessage = 'Please select a card type and click "OK"'
}) => {
  const [cardType, setCardType] = useState('')

  const handleSelectChange = e => setCardType(e.target.value)

  useEffect(() => {
    /* functional component substitute for componentWillUnmount */
    return () => {
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  const submitPayment = debounce(e => {
    dispatch(
      initializePayment.try(form, {
        option: 'offline',
        amountTendered,
        cardType
      })
    )
  }, 500)

  const buttonIsDisabled = memoize((payment, ccType, editing) => {
    if (!payment || !ccType || !editing) {
      return true
    }

    return false
  })

  const moduleStyle = isMobile
    ? {
        ...paymentModuleStyle,
        padding: '10px 10px 15px 10px'
      }
    : paymentModuleStyle

  return (
    <div>
      <p style={{ fontSize: 12, margin: '10px 0', textAlign: 'center' }}>
        {infoMessage}
      </p>

      <div style={moduleStyle}>
        <div style={{ width: 200 }}>
          <FormControl>
            <InputLabel id="cc-card-type">Card Type</InputLabel>
            <Select
              labelId="cc-card-type-label"
              id="cc-card-type"
              value={cardType}
              onChange={handleSelectChange}
              disabled={!isEditing}
            >
              <MenuItem value="" />
              <MenuItem value="M">MasterCard</MenuItem>
              <MenuItem value="V">Visa</MenuItem>
              <MenuItem value="A">Amex</MenuItem>
              <MenuItem value="D">Discover</MenuItem>
            </Select>
          </FormControl>
        </div>

        <PaymentButton
          variant="contained"
          onClick={submitPayment}
          disabled={buttonIsDisabled(amountTendered, cardType, isEditing)}
        >
          OK
        </PaymentButton>
      </div>
    </div>
  )
}

export const contextObj = {
  creditCardOptions: form => getMeta(form, 'creditCardOptions', [])
}

export default OfflineCreditCardPayment
