/* eslint jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { removeModal } from 'modals/actions'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Paper,
  Switch,
  Icon,
  InputAdornment
  // TextField
} from '@material-ui/core'
import NumberSpinner from 'ddiForm/NumberSpinner'

import { getIn } from 'utils'
import * as actions from '../actions'
import PriceCalculator from '../components/PriceCalculator'
// import { editablePriceSelector, errorMessageSelector } from '../selectors'

export const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    data: { form }
  } = ownProps

  return {
    calculateOrderPadPrice: ({ cost, gpPercent }) =>
      dispatch(
        actions.calculateOrderPadPriceAsync.request({
          cost,
          gpPercent
        })
      ),
    removeParentModal: ({ id }) => dispatch(removeModal(form, { id })),
    saveAdjustedPriceAsync: ({
      applyToAllItems,
      lineItems,
      effectiveDate,
      adjustedPrice,
      percentIncrease,
      gridCallbackFn,
      cb
    }) =>
      dispatch(
        actions.saveAdjustedPriceAsync.try(form, {
          applyToAllItems,
          lineItems,
          effectiveDate,
          adjustedPrice,
          percentIncrease,
          gridCallbackFn,
          cb
        })
      ),
    setEditablePrice: ({ price, updateField }) =>
      dispatch(actions.setEditablePrice(form, { price, updateField })),
    showErrorMessage: ({ message }) =>
      dispatch(actions.showErrorMessage(form, { message }))
  }
}

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  let adjustedPriceChange = getIn(formState, 'values.adjustedPriceChange')
  adjustedPriceChange =
    adjustedPriceChange && adjustedPriceChange.toJS
      ? adjustedPriceChange.toJS()
      : {}

  return {
    adjustedPriceChange,
    editablePrice: getIn(formState, 'fields.editablePrice.value') || '',
    errorMessage: getIn(formState, 'fields.editablePrice.errorMessage') || ''
  }
}

export class AdjustedPriceChangeModal extends Component {
  static propTypes = {
    adjustedPrice: PropTypes.number,
    data: PropTypes.object.isRequired
  }

  static defaultProps = { adjustedPrice: 0 }

  constructor(props) {
    super(props)

    const { adjustedPriceChange } = props

    this.state = {
      adjPriceEffectiveDate: adjustedPriceChange.adjPriceEffectiveDate,
      applyToAllItems: false,
      clearAdjustedPrices: false,
      gpPercent: '',
      pctIncrease: 0,
      popoverIsOpen: false,
      /* selectedLineItemData does in fact get used (in the saga) */
      selectedLineItemData: {
        dataId: adjustedPriceChange.dataId,
        uomId: adjustedPriceChange.uomId
      }
    }
  }

  onToggleChange = name => event => {
    if (name === 'adjPriceEffectiveDate') {
      this.setState({
        adjPriceEffectiveDate: new Date(),
        [name]: event.target.checked
      })
    } else {
      this.setState({
        [name]: event.target.checked
      })
    }
  }

  onDatepickerChange = date => {
    this.setState({ adjPriceEffectiveDate: date })
  }

  onChange = id => event => {
    const { value } = event.target
    this.setState({ [id]: value })
  }

  onNumberPctIncreaseSpinnerChangeCallback = val =>
    this.setState({ pctIncrease: val })

  render() {
    const {
      data: { form }
    } = this.props

    const {
      adjustedBy,
      adjustedPrice,
      canSeeCostAndGP,
      currentPrice,
      dataId,
      initials,
      matrixCost,
      serverDate,
      uomId
    } = this.props.adjustedPriceChange

    const {
      adjPriceEffectiveDate,
      applyToAllItems,
      clearAdjustedPrices,
      pctIncrease,
      popoverIsOpen
    } = this.state

    const pctIncreaseIsDisabled =
      applyToAllItems === false || clearAdjustedPrices === true || false

    return (
      <div>
        <Row style={{ marginBottom: 15 }}>
          <Col lg={3} md={3} sm={3} xs={3}>
            <p
              style={{
                fontSize: 12,
                marginBottom: 0,
                marginTop: 7
              }}
            >
              Eff Date:
            </p>
          </Col>
          <Col lg={9} md={9} sm={9} xs={9}>
            <div style={{ width: 150 }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disabled={clearAdjustedPrices === true}
                  id="adjPriceEffectiveDate"
                  format="MM/DD/YYYY"
                  onChange={this.onDatepickerChange}
                  value={
                    clearAdjustedPrices === true
                      ? null
                      : new Date(adjPriceEffectiveDate)
                  }
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Icon
                            style={{
                              fontSize: 18,
                              position: 'relative',
                              left: 8
                            }}
                          >
                            event
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Col>
        </Row>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>By:</TableCell>
              <TableCell>
                <strong>{adjustedBy}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product:</TableCell>
              <TableCell>
                <strong>{dataId}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Current Price:</TableCell>
              <TableCell>
                <strong>{currentPrice}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Row style={{ marginBottom: 25, marginTop: 15 }}>
          <Col lg={3} md={3} sm={3} xs={3}>
            <p
              style={{
                fontSize: 12,
                marginBottom: 0,
                marginTop: 7
              }}
            >
              Adj. Price:
            </p>
          </Col>
          <Col lg={9} md={9} sm={9} xs={9}>
            <PriceCalculator
              initialPrice={adjustedPrice}
              clearEditablePrice={applyToAllItems === true}
              matrixCost={matrixCost}
              showPriceCalculator={canSeeCostAndGP}
              textFieldDisabled={applyToAllItems === true}
              ref={el => (this.priceCalculator = el)}
              updateField="adjustedPrice"
              form={form}
            />
          </Col>
        </Row>
        <Paper
          style={{
            background: '#fff',
            marginTop: 15,
            padding: '0 20px 15px 20px'
          }}
        >
          <Row>
            <Col lg={12}>
              <h4 style={{ fontSize: 14, margin: '15px 0 5px 0' }}>
                Show All With:
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={applyToAllItems}
                    onChange={this.onToggleChange('applyToAllItems')}
                    value="applyToAllItems"
                  />
                }
                label="Apply To All Items"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={clearAdjustedPrices}
                    onChange={this.onToggleChange('clearAdjustedPrices')}
                    value="clearAdjustedPrices"
                  />
                }
                label="Clear Adjusted Prices"
                disabled={applyToAllItems === false || false}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 15, marginTop: 15 }}>
            <Col lg={4} md={4} sm={4} xs={4}>
              <p
                style={{
                  fontSize: 12,
                  marginBottom: 0,
                  marginTop: 7
                }}
              >
                Pct. Increase:
              </p>
            </Col>
            <Col lg={8} md={8} sm={8} xs={8}>
              <NumberSpinner
                disabled={pctIncreaseIsDisabled}
                initialValue={pctIncrease}
                isField={false}
                ref={el => (this.numberPctIncreseSpinner = el)}
                updateValueCallback={
                  this.onNumberPctIncreaseSpinnerChangeCallback
                }
                step={1.0}
                min={0.0}
                max={100}
                inputProps={{
                  decimalScale: 4,
                  fontSize: 13,
                  fixedDecimalScale: true
                }}
              />
            </Col>
          </Row>
          {this.props.errorMessage ? (
            <Row>
              <Col lg={12} sm={12}>
                <div className="text-center">
                  <div
                    className="alert alert-warning"
                    style={{ padding: 5, margin: '0 10px 15px 10px' }}
                  >
                    <Icon style={{ verticalAlign: 'middle' }}>warning</Icon>
                    <span style={{ fontSize: 12 }}>
                      {this.props.errorMessage}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          ) : null}
        </Paper>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(AdjustedPriceChangeModal)
