/* eslint prefer-destructuring: 0, func-names: 0, prefer-template: 0 */
function LineItemsGridCustomTooltip() {}

function nl2br(str) {
  if (typeof str === 'undefined' || typeof str !== 'string' || str === null) {
    return ''
  }
  const breakTag = '<br>'
  return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

function getTooltipHeader(colId) {
  if (!colId) {
    return ''
  }

  if (colId === 'netPrice') {
    return '<strong style="margin-bottom: 3px;">Customer Product Sales History</strong>'
  }

  if (colId === 'quantityOrdered') {
    return '<strong style="margin-bottom: 3px;">Price Breaks</strong>'
  }

  return ''
}

LineItemsGridCustomTooltip.prototype.init = function(params) {
  this.eGui = document.createElement('div')
  const eGui = this.eGui
  const { colDef, value } = params
  const { colId = '' } = colDef
  const tooltipHeader = getTooltipHeader(colId)

  eGui.classList.add('custom-tooltip')
  eGui.classList.add('ag-tooltip')
  eGui.style.minWidth = colId && colId === 'netPrice' ? '175px' : '125px'
  eGui.innerHTML = tooltipHeader
    ? `<div><div>${tooltipHeader}</div><div style="white-space: pre; font-family: monospace;">${value}</div></div>`
    : `<div style="white-space: pre; font-family: monospace;">${value}</div>`
}

LineItemsGridCustomTooltip.prototype.getGui = function() {
  return this.eGui
}

export default LineItemsGridCustomTooltip
