import React from 'react'
import { Field, Label, withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  DDIDatePicker,
  DDIIndexSearch,
  DDISelectField,
  DDITextField,
  DDIToggle
} from 'ddiForm/wrapped'
import { getField, getValue, emptyList, getMeta } from 'utils'
import FieldsWrapper from 'pages/SalesOrder/components/FieldsWrapper'
import { validateSearchField } from 'pages/SalesOrder/utils'

// import { getAddressLink } from 'pages/ContactMaster/utils'

import '../../../styles/master-style.scss'

const metaData = {
  allowInvalidValues: true,
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: false,
  fieldName: 'dataId',
  hasFilters: false,
  hideToggle: true,
  minimumKeywordLength: 0,
  openText: null,
  searchType: 'customer'
}

export const SalesOrderInfo = ({
  defaultPoType,
  poTypeOptionsList,
  procurementOptionsList,
  procurementTypeDescription,
  customerId,
  isEditing,
  hasRecord,
  quote,
  form
}) => {
  const procurementOptions = procurementOptionsList?.toJS
    ? procurementOptionsList.toJS()
    : [
        { dataId: 'N', description: 'No Link' },
        { dataId: 'R', description: 'Requesting P/O' },
        { dataId: 'D', description: 'Drop Ship P/O' },
        { dataId: 'S', description: 'Special P/O' }
      ]
  const disabled = !(hasRecord && isEditing)
  const invoiceDateDisabled = quote ? true : disabled

  return (
    <div className="sales-order-info-wrapper">
      <DDICardWrapper
        title="Sales Order Info"
        contentStyle={{
          padding: '0 10px'
        }}
      >
        <div className="centered-wrapper" style={{ margin: '10px 0' }}>
          <div className="date-picker-wrapper">
            {/* LEFT SIDE */}
            <div className="two-col-flex-left">
              <DDIDatePicker
                propertyName="orderDate"
                views={['date']}
                label="Order Date"
                preventAutoDisable
                validate={[validateSearchField]}
                disabled={disabled}
              />
            </div>
            {/* END LEFT SIDE */}

            {/* RIGHT SIDE */}
            <div className="two-col-flex-right">
              <DDIDatePicker
                propertyName="invoiceDate"
                views={['date']}
                label="Invoice Date"
                preventAutoDisable
                disabled={invoiceDateDisabled}
              />
            </div>
            {/* END RIGHT SIDE */}
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Job ID</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="jobId"
              meta={{
                ...metaData,
                searchType: 'job',
                allowSearchAll: true
              }}
              preventAutoDisable
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="jobDescription" component={Label} />
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Salesperson</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="salesmanId"
              metaKey="Salesman"
              preventAutoDisable
              validate={[validateSearchField]}
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="salesmanDescription" component={Label} />
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Terms</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="termsId"
              metaKey="Terms"
              preventAutoDisable
              validate={[validateSearchField]}
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="termsDescription" component={Label} />
          </div>
        </div>
        <div className="search-row-wrapper" style={{ paddingTop: 10 }}>
          <div className="search-row-label">Tax Code</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="taxId"
              metaKey="Tax Code"
              preventAutoDisable
              validate={[validateSearchField]}
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="taxDescription" component={Label} />
          </div>
        </div>
        <div style={{ paddingLeft: '275px' }}>
          <DDIToggle
            propertyName="taxOverride"
            label="Tax Override"
            preventAutoDisable
            disabled={disabled}
          />
        </div>
        <div className="search-row-wrapper">
          <div className="search-row-label">Exempt Number</div>
          <div className="search-row-input">
            <DDITextField
              propertyName="taxExemptNumber"
              preventAutoDisable
              disabled={disabled}
              // label="Exempt Number"
              // style={{
              //   // margin: '0 0 15px 10px'
              // }}
            />
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Order Type</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="orderTypeId"
              metaKey="Order Type"
              preventAutoDisable
              validate={[validateSearchField]}
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="orderTypeDescription" component={Label} />
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Price Contract</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="priceContractId"
              metaKey="Price Contract"
              preventAutoDisable
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="priceContractDescription" component={Label} />
          </div>
        </div>

        <div className="search-row-wrapper">
          <div className="search-row-label">Locker Recipient</div>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="lockerRecipient"
              metaKey="Ordered By"
              preventAutoDisable
              disabled={disabled}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="lockerRecipientName" component={Label} />
          </div>
        </div>

        <div
          className="three-col-flex-wrapper"
          style={{ alignItems: 'center' }}
        >
          <div className="three-col-flex-left">
            <div
              className="max-width-field-left"
              style={{
                padding:
                  form === 'invoiceInquiry' ? '10px 0 0 0' : '10px 0 15px 0'
              }}
            >
              {form === 'invoiceInquiry' ? (
                <div style={{ display: 'flex', fontSize: '1rem' }}>
                  <strong style={{ marginRight: 5 }}>P/O Type:</strong>
                  <p>{procurementTypeDescription}</p>
                </div>
              ) : (
                <DDISelectField
                  propertyName="procurementType"
                  label="P/O Type"
                  initialValue={defaultPoType}
                  values={procurementOptions}
                  disabled={disabled}
                  preventAutoDisable
                />
              )}
            </div>
          </div>

          <div className="three-col-flex-center">
            <div className="toggle-wrapper">
              <DDIToggle
                propertyName="blanketOrder"
                label="Blanket Order"
                preventAutoDisable
                disabled={disabled}
              />
            </div>
          </div>

          <div className="three-col-flex-right">
            <div className="toggle-wrapper">
              <DDIToggle
                propertyName="shipComplete"
                label="Ship Complete"
                preventAutoDisable
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </DDICardWrapper>
    </div>
  )
}

export const contextObj = {
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  defaultPoType: form => getValue(form, 'defaultPoType', 'N'),
  customerId: form => getField(form, 'customerId', ''),
  quote: form => getField(form, 'quote', false),
  procurementOptionsList: form =>
    getMeta(form, 'procurementOptions', emptyList),
  procurementTypeDescription: form =>
    getValue(form, 'procurementTypeDescription', '')
}

export default React.memo(withContext(contextObj)(SalesOrderInfo))
