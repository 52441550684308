import { extractIndexSearchFieldsFromMeta } from 'ddiForm/utils'
import { getIn, setIn, deleteIn, fromJS } from 'utils'
import memoize from 'memoize-one'

import * as CONSTANTS from './constants'

export default memoize((isModalInterface = false) => {
  const valuesKey = isModalInterface ? 'values.corporateFieldUpdates' : 'values'
  const fieldsKey = isModalInterface ? 'fields.corporateFieldUpdates' : 'fields'
  const metaKey = isModalInterface ? 'meta.corporateFieldUpdates' : 'meta'

  return {
    [CONSTANTS.GET_CORPORATE_FIELD_UPDATES_META.SUCCESS]: (
      state,
      { payload }
    ) => {
      let result = state
      result = setIn(
        result,
        metaKey,
        fromJS(extractIndexSearchFieldsFromMeta(payload))
      )

      return result
    },
    [CONSTANTS.READ_CORPORATE_FIELD_UPDATES_DATA.REQUEST]: (state, action) => {
      let result = state
      result = setIn(result, `${valuesKey}.fields`, fromJS([]))
      result = deleteIn(result, `${valuesKey}.templateKey`)
      result = deleteIn(result, `${valuesKey}.description`)
      result = setIn(result, `${valuesKey}.hasRecord`, false)
      return result
    },
    [CONSTANTS.READ_CORPORATE_FIELD_UPDATES_DATA.SUCCESS]: (
      state,
      { payload }
    ) => {
      let result = state
      for (const prop in payload) {
        result = setIn(result, `${valuesKey}.${prop}`, fromJS(payload[prop]))

        if (prop === 'dataId') {
          result = setIn(result, `${fieldsKey}.dataId.value`, payload[prop])
        }
      }

      result = setIn(result, `${valuesKey}.hasRecord`, true)
      return result
    },
    [CONSTANTS.READ_CORPORATE_FIELD_UPDATES_DATA.FAILURE]: (state, action) => {
      let result = state

      result = setIn(result, `${fieldsKey}.dataId.value`, '')
      result = setIn(result, `${valuesKey}.dataId`, '')

      result = setIn(result, `${valuesKey}.fields`, fromJS([]))
      result = deleteIn(result, `${valuesKey}.templateKey`)
      result = deleteIn(result, `${valuesKey}.description`)
      result = setIn(result, `${valuesKey}.hasRecord`, false)
      result = setIn(result, `${valuesKey}.isLocked`, false)

      return result
    },
    [CONSTANTS.EDIT_CORPORATE_FIELD_UPDATES_DATA.SUCCESS]: (
      state,
      { payload }
    ) => {
      let result = state
      for (const prop in payload) {
        result = setIn(result, `${valuesKey}.${prop}`, fromJS(payload[prop]))
      }

      if (!isModalInterface) {
        /* set the isEditing flag for the ddiForm routines */
        result = setIn(result, 'isEditing', true)
      }

      return result
    },
    [CONSTANTS.CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA.REQUEST]: (
      state,
      action
    ) => {
      let result = state
      result = setIn(result, `${valuesKey}.hasRecord`, false)
      return result
    },
    [CONSTANTS.CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA.SUCCESS]: (
      state,
      { payload }
    ) => {
      const {
        clearGridOnSuccess = false,
        retainData = false,
        record = {}
      } = payload
      let result = state

      if (clearGridOnSuccess) {
        result = setIn(result, `${valuesKey}.fields`, fromJS([]))
        result = setIn(result, `${valuesKey}.isLocked`, false)
        result = setIn(result, `${valuesKey}.hasRecord`, false)
        result = deleteIn(result, `${valuesKey}.templateKey`)
        result = deleteIn(result, `${valuesKey}.description`)
      } else if (retainData) {
        for (const prop in record) {
          result = setIn(result, `${valuesKey}.${prop}`, fromJS(record[prop]))
        }
        result = setIn(result, `${valuesKey}.isLocked`, false)
        result = setIn(result, `${valuesKey}.hasRecord`, true)
      } else if (isModalInterface) {
        result = deleteIn(result, 'values.corporateFieldUpdates')
        result = deleteIn(result, 'fields.corporateFieldUpdates')
      }

      if (!isModalInterface) {
        /* set the isEditing flag for the ddiForm routines */
        result = setIn(result, 'isEditing', false)
      }

      return result
    },
    [CONSTANTS.CLEAR_VALUES]: (state, action) => {
      let result = state
      /* for modal only */
      result = deleteIn(result, 'values.corporateFieldUpdates')
      result = deleteIn(result, 'fields.corporateFieldUpdates')
      return result
    },
    [CONSTANTS.SET_ROW_DATA]: (state, { payload: { rowData } }) => {
      let result = state
      result = setIn(result, `${valuesKey}.fields`, fromJS(rowData))

      if (Array.isArray(rowData) && !rowData.length) {
        result = deleteIn(result, `${valuesKey}.templateKey`)
        result = deleteIn(result, `${valuesKey}.description`)
        result = setIn(result, `${valuesKey}.hasRecord`, false)
      }
      return result
    },
    [CONSTANTS.SAVE_CORPORATE_FIELD_UPDATES_DATA.SUCCESS]: (
      state,
      { payload }
    ) => {
      let result = state
      const isModal = payload?.isModal || false

      if (isModal) {
        result = deleteIn(result, 'values.corporateFieldUpdates')
        result = deleteIn(result, 'fields.corporateFieldUpdates')
      } else {
        for (const prop in payload) {
          result = setIn(result, `${valuesKey}.${prop}`, fromJS(payload[prop]))

          if (prop === 'dataId') {
            result = setIn(result, `${fieldsKey}.dataId.value`, payload[prop])
          }
        }

        result = setIn(result, `${valuesKey}.hasRecord`, true)
        result = setIn(result, 'isEditing', false)
      }

      return result
    }
  }
})
