import React from 'react'
import { withContext } from 'ddiForm'
import { DDITextField, DDISelectField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { maxValue } from 'ddiForm/validations'
import { getIn, emptyList } from 'utils'

const fieldWrapperStyle = {
  marginBottom: 15,
  width: '100%'
}

const FreightTerms = ({ freightTermOptionsList }) => {
  const freightTermOptions = freightTermOptionsList?.toJS
    ? freightTermOptionsList.toJS()
    : []

  return (
    <DDICardWrapper title="Freight Terms" contentStyle={{ padding: 10 }}>
      <div style={{ width: '100%', marginBottom: 15 }}>
        <DDISelectField
          propertyName="freightTerms"
          fullWidth
          values={freightTermOptions}
          label="Freight Terms"
        />
      </div>
      <DDITextField
        propertyName="minimumOrderAmount"
        label="Order Dollars"
        mask="numeric"
        style={{ maxWidth: '100%', width: '100%' }}
        wrapperStyle={fieldWrapperStyle}
        validate={maxValue(999999999.99)}
        inputProps={{
          max: 999999999.99,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: '$',
          range: [0, 999999999.99]
        }}
      />
      <DDITextField
        propertyName="minimumFreightAmount"
        label="Freight Dollars"
        mask="numeric"
        style={{ maxWidth: '100%', width: '100%' }}
        wrapperStyle={fieldWrapperStyle}
        validate={maxValue(999999999.99)}
        inputProps={{
          max: 999999999.99,
          decimalScale: 2,
          fixedDecimalScale: true,
          prefix: '$',
          range: [0, 999999999.99]
        }}
      />

      <DDITextField
        propertyName="minimumOrderWeight"
        label="Weight"
        mask="numeric"
        style={{ maxWidth: '100%', width: '100%' }}
        wrapperStyle={fieldWrapperStyle}
        inputProps={{
          integerLimit: 8,
          style: { textAlign: 'right' },
          range: [0, 99999999]
        }}
        validate={[maxValue(99999999)]}
      />
      <DDITextField
        propertyName="minimumOrderQuantity"
        label="Quantity"
        mask="numeric"
        style={{ maxWidth: '100%', width: '100%' }}
        wrapperStyle={fieldWrapperStyle}
        inputProps={{
          integerLimit: 9,
          style: { textAlign: 'right' },
          range: [0, 999999999]
        }}
        validate={[maxValue(999999999)]}
      />
      <DDITextField
        propertyName="minimumOrderVolume"
        label="Volume"
        mask="numeric"
        style={{ maxWidth: '100%', width: '100%' }}
        wrapperStyle={fieldWrapperStyle}
        inputProps={{
          integerLimit: 8,
          style: { textAlign: 'right' },
          range: [0, 99999999]
        }}
        validate={[maxValue(99999999)]}
      />
    </DDICardWrapper>
  )
}

export default withContext({
  freightTermOptionsList: form =>
    getIn(form, 'meta.freightTermOptions') || emptyList
})(FreightTerms)
