/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { BASE_INFORM_URL } from 'services/constants'
import { DDIIndexSearch, DDISelectField, DDIToggle } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  emptyList,
  getField,
  formatDollarFields,
  formatDateFields,
  getValue,
  formatNumber
} from 'utils'
import Grid from 'grid'
import ImageCellHeader from 'pages/SalesOrder/components/ImageCellHeader'
import { debounce } from 'lodash'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'

import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
// import TextFieldEditorCell from 'pages/SalesOrder/components/ManualGrid/TextFieldEditorCell'

import {
  changeRecalculatePrices,
  setSelectedSequenceNumber
} from 'pages/SalesOrder/actions'

import DropdownArrow from 'pages/SalesOrder/components/ManualGrid/DropdownArrow'

import PriceCalculatorCellWrapper from './components/PriceCalculatorCellWrapper'
import AmountCell from './components/AmountCell'
import RollupsCellRenderer from './components/RollupsCellRenderer'

import './styles/master-style.scss'

// Price/Cost Column Defs
export const dynamicColumnDefs = memoize(({ form }) => {
  const colDef = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellStyle: params =>
        params.data.dataId === 'FUTURE' ? { fontWeight: 'bold' } : null,
      field: 'description',
      form,
      headerName: 'Column',
      filter: false,
      suppressMenu: true
    },
    {
      cellClass: 'align-right',
      cellStyle: params => {
        if (params.data.dataId === 'FUTURE' && params.value < 0) {
          return {
            fontWeight: 'bold',
            color: 'red'
          }
        }
        if (params.value < 0) {
          return {
            fontWeight: 'normal',
            color: 'red'
          }
        }
        if (params.data.dataId === 'FUTURE') {
          return {
            fontWeight: 'bold'
          }
        }

        return null
      },
      field: 'factoredAmount',
      headerName: 'Amount',
      headerClass: 'align-right',
      filter: false,
      suppressMenu: true,
      valueFormatter: params => formatDollarFields(params, 4)
    },
    {
      cellStyle: params =>
        params.data.dataId === 'FUTURE' ? { fontWeight: 'bold' } : null,
      cellClass: 'text-center align-center',
      field: 'changedDate',
      headerName: 'Changed',
      headerClass: 'text-center align-center',
      filter: false,
      suppressMenu: true,
      valueFormatter: params => formatDateFields(params)
    }
  ]
  return colDef
})

export const getColDefs = memoize(
  ({ form, dispatch, priceOptions = [], operatorOptions = [] }) => {
    const editorParams = {
      disabled: false,
      form,
      isEditing: true,
      dispatch
    }

    const colDefs = [
      {
        field: 'dataId',
        headerName: 'Product'
      },
      {
        field: 'description',
        headerName: 'Description'
      },
      {
        field: 'basePriceId',
        headerName: 'C/L',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: params => {
          const values = priceOptions.map(x => x.dataId)

          return {
            values,
            formatValue: value => {
              const row = priceOptions.find(x => x.dataId === value)
              return row?.description ? row.description : value
            }
          }
        },
        cellRendererFramework: RollupsCellRenderer,
        cellRendererParams: {
          isRequired: true
        },
        onCellValueChanged: params => {
          dispatch(
            changeRecalculatePrices.try(form, {
              propertyName: 'basePriceId',
              value: params.newValue
            })
          )
        },
        editable: true,
        valueFormatter: params => {
          const row = priceOptions.find(x => x.dataId === params.value)
          return row?.description ? row.description : params.value
        }
      },
      {
        field: 'operatorType',
        headerName: '+/-',
        headerClass: 'text-center',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: params => {
          const values = operatorOptions.map(x => x.dataId)
          return {
            values
          }
        },
        onCellValueChanged: params => {
          dispatch(
            changeRecalculatePrices.try(form, {
              propertyName: 'operatorType',
              value: params.newValue
            })
          )
        },
        cellRendererFramework: RollupsCellRenderer,
        cellRendererParams: {
          isRequired: true
        },
        editable: true,
        width: 50
      },
      {
        field: 'amount',
        colId: 'amount',
        headerName: 'Amount',
        headerClass: 'align-right',
        cellStyle: { 'text-align': 'right' },
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          propertyName: 'amount',
          allowNegative: true,
          noDecimalLimit: true,
          maxLength: 10,
          textAlign: 'right',
          notifyExitTextCell: true,
          formatter: 'number',
          thousandSeparator: '',
          decimalScale: 2,
          fixedDecimalScale: true,
          isRequired: true
        },
        cellRendererFramework: RollupsCellRenderer,
        cellRendererParams: {
          isRequired: true,
          textAlign: 'right'
        },
        valueFormatter: params => formatNumber(params.value, '0,0.00'),
        editable: true
      },
      {
        field: '',
        headerComponent: 'imageCellHeader',
        headerComponentParams: {
          src: `${BASE_INFORM_URL}/resources/company_misc_charge_32.png`,
          title: 'Pricing'
        },
        cellEditor: 'pricingCalculatorCell',
        cellEditorParams: {
          disabled: false,
          form,
          propertyName: 'pricingCalculator',
          isEditing: true
        },
        cellRendererFramework: DropdownArrow,
        minWidth: 50,
        maxWidth: 50,
        editable: true
      }
    ]

    return colDefs
  }
)

const getRowNodeId = data => data.dataId

const frameworkComponents = {
  imageCellHeader: ImageCellHeader,
  pricingCalculatorCell: PriceCalculatorCellWrapper,
  textFieldEditor: AmountCell
}

export const RecalculatePricesModal = ({
  canChangeIncludeOverrides,
  canChangeCustomerCategory,
  dispatch,
  data: { priceOptions = [], recalculateOptions = [], operatorOptions },
  form,
  rollups = []
}) => {
  const [selected, setSelected] = useState([])

  const popupParent = document.querySelector('body')

  const onGridReady = params => {
    params.api.forEachNode(node => {
      if (node.data.sequenceNumber === 1) {
        node.setSelected(true)
        setSelected(node.data.prices)
      }
    })
  }
  const onSelectionChanged = debounce(params => {
    if (params.api && rollups?.toJS().length) {
      const selectedNode = params.api.getSelectedRows()
      setSelected(selectedNode[0].prices)
      dispatch(
        setSelectedSequenceNumber(form, {
          sequenceNumber: selectedNode[0].sequenceNumber
        })
      )
    } else {
      setSelected([])
    }
  }, 500)

  const tabToNextCell = params => {
    const { backwards } = params
    const { nextCellPosition, previousCellPosition } = params

    // change selected row to next position
    if (
      nextCellPosition &&
      nextCellPosition.rowIndex !== previousCellPosition.rowIndex
    ) {
      const node = params.api.current.getDisplayedRowAtIndex(
        nextCellPosition.rowIndex
      )
      if (node && !node.isSelected()) {
        node.setSelected(true)
      }
    }

    if (
      nextCellPosition &&
      nextCellPosition.column &&
      nextCellPosition.column.colDef &&
      nextCellPosition.column.colDef.editable
    ) {
      const idx = nextCellPosition.rowIndex
      const key = nextCellPosition.column.colId

      if (params.api && params.api.current) {
        setTimeout(() => {
          params.api.current.ensureColumnVisible(key)
          params.api.current.setFocusedCell(idx, key)
          params.api.current.startEditingCell({
            rowIndex: idx,
            colKey: key
          })
        }, 0)
      }
    }

    // if (
    //   (!nextCellPosition && !backwards) ||
    //   (nextCellPosition && !nextCellPosition.column)
    // ) {
    //   dispatch(addBlankRow(form, { propertyName: 'groups.itemGroups' }))
    // }

    return nextCellPosition
  }

  return (
    <div className="recalc-prices-outer-wrapper">
      <div className="left-wrapper">
        <div className="header-wrapper">
          <div className="flex-wrapper">
            <div className="search-row-wrapper">
              <label className="search-row-label">Recalculate For:</label>
              <div className="search-row-input">
                <DDISelectField
                  propertyName="recalculateType"
                  values={recalculateOptions}
                  margin="none"
                  selectValue="dataId"
                  selectDescription="description"
                  preventAutoDisable
                  fullWidth
                  displayEmpty
                />
              </div>
            </div>
          </div>
          <div className="flex-wrapper">
            {canChangeCustomerCategory ? (
              <div className="search-row-wrapper">
                <label className="search-row-label">Category:</label>
                <div className="search-row-input">
                  <DDIIndexSearch
                    propertyName="customerCategoryId"
                    disableLastSearchButton
                    preventAutoDisable
                    fullWidth
                    displayDescription
                    meta={{
                      // allowInvalidValues: true,
                      allowInstantSearch: true,
                      allowNavigation: false,
                      hasFilters: false,
                      hideToggle: true,
                      minimumKeywordLength: 0,
                      openText: null,
                      allowSearchAll: true,
                      searchType: 'CustCategory'
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex-wrapper">
            <DDIToggle
              propertyName="includeOverrides"
              label="Include Overrides"
              preventAutoDisable
              disabled={!canChangeIncludeOverrides}
            />
          </div>
        </div>

        <div className="grid-wrapper">
          <ManualGrid
            columnDefs={getColDefs({
              form,
              dispatch,
              priceOptions,
              operatorOptions
            })}
            getRowNodeId={getRowNodeId}
            frameworkComponents={frameworkComponents}
            propertyName="rollups"
            rowSelection="single"
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            dispatch={dispatch}
            form={form}
            immutableData
            suppressMovableColumns
            skipValidationOnUnmount
            suppressRowClickSelection={false}
            singleClickEdit
            height={350}
            isEditing
            popupParent={popupParent}
            reactUi={false}
            // tabToNextCell={tabToNextCell}
          />
        </div>
      </div>

      <div className="right-wrapper">
        <DDICardWrapper
          contentStyle={{ minHeight: 350, padding: 0 }}
          title="Price/Cost Columns"
        >
          <Grid
            getRowNodeId={getRowNodeId}
            columnDefs={dynamicColumnDefs({ form })}
            rowData={selected || []}
            width="100%"
            fullWidth
          />
        </DDICardWrapper>
      </div>
    </div>
  )
}

RecalculatePricesModal.props = {
  form: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired
}

const contextObj = {
  canChangeIncludeOverrides: form =>
    getValue(form, 'canChangeIncludeOverrides', false),
  canChangeCustomerCategory: form =>
    getValue(form, 'canChangeCustomerCategory', false),
  rollups: form => getField(form, 'rollups', emptyList, true)
}

export default withContext(contextObj)(RecalculatePricesModal)
