/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const searchReceiptNumbers = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/fastreceipt/search`, options)
}
