import React, { Component } from 'react'
import invariant from 'invariant'
import ddiForm from 'ddiForm/ddiForm'
import { connect } from 'react-redux'
import { deepEqual, is } from 'utils'
import withTooltip from 'hoc/withTooltip'
import { bindBehaviors } from './utils'
import SearchBase from './SearchBase'
import DefaultContextMenu from './SearchBase/components/DefaultContextMenu'

const defaultState = {
  isDirty: false,
  value: '',
  isFocused: false,
  isSet: false,
  mouseover: false,
  lastSearch: '',
  description: ''
}

export default (
  opts = {
    name: '',
    popoverStyle: { width: 500 },
    behaviors: {},
    tooltip: null,
    form: null,
    ContextMenu: DefaultContextMenu,
    defaultState,
    isSet: false
  }
) => {
  invariant(opts.name, 'Options must include a name for the search')
  let C = class extends Component {
    static displayName = opts.name

    _isMounted = false

    constructor(...args) {
      super(...args)
      this.state = opts.defaultState || defaultState
      bindBehaviors(this, opts.behaviors)
      if (this.initialize) {
        this.initialize()
        // if (this.props.propertyName === 'writerId') debugger
      } else {
        this.state.value = this.props.value || this.props.initialValue || ''
      }

      this.state.isSet = !!this.state.value
      if (opts.form && typeof opts.form === 'object') {
        invariant(
          typeof opts.form.component === 'function',
          'Options must include a name for the search'
        )
        this.component = opts.form.component

        this.options = opts.form.options ? { ...opts.form.options } : {}
        const childForm = this.options.form
        let form = this.form || ''
        form = `${form}${childForm ? `.${childForm}` : ''}`
        this.options.form = form
        this.options.asModal = true
        this.form = form
        if (this.props.propertyName === 'writerId') debugger
        this.DropDownComponent = ddiForm(this.options)(this.component)
        this.ddiForm = true
      } else if (this.props.form && typeof this.props.form === 'string') {
        this.form = this.props.form
        this.ddiForm = true
      }
      if (opts.tooltip) {
        this.withTooltip = withTooltip({ ...opts.tooltip })
      }
      if (opts.DropDownComponent && !this.DropDownComponent) {
        this.DropDownComponent = opts.DropDownComponent
      }
      if (opts.toggleMap) {
        // console.log(opts.toggleMap)
      }
      this.meta = opts.meta || this.props.meta
    }

    componentDidMount() {
      this._isMounted = true
      if (this.props.searchRef) {
        if (typeof this.props.searchRef === 'function') {
          this.props.searchRef(this)
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        prevProps.metaKey &&
        this.props.metaKey &&
        prevProps.metaKey !== this.props.metaKey
      ) {
        this.resetState()
      } else if (
        !this.state.isDirty &&
        !deepEqual(this.props.value, this.state.value) &&
        this.props.value !== undefined
      ) {
        // debugger
        // if (this.props.propertyName === 'orderedById') {
        //   debugger
        // }
        this.setValue(this.props.value)
      }
      /*
        this is causing issues with loading meta information
        both in the dataId search of Product Master & in the
        DocumentSearch fields in Product Master > Web Options
        adding these first two conditions fixes that
        SVE 6/4/2019
      */
      if (
        this.props.meta &&
        Object.keys(this.props.meta).length &&
        !deepEqual(this.props.meta, this.meta)
      ) {
        // this.meta = this.props.meta
        // debugger
        opts.meta = this.props.meta
      }

      if (
        is.string(this.props.description) &&
        this.props.displayDescription &&
        // this.props.description !== prevProps.description &&
        this.props.description !== this.state.description
      ) {
        /*
          ^^there are cases where due to a previous render,
            the description props may line up, so this condition needs
            to be removed from the comparision in order to always
            work -- SVE 2/11/2020
        */
        // if (this.props.propertyName === 'orderedById') {
        //   debugger
        // }
        this.setDescription(this.props.description)
      }
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    setValue = value =>
      this._isMounted &&
      this.setState(
        {
          value,
          isSet: !!value
        },
        () => {
          if (this.props.propertyName === 'shipViaId')
            console.log('setvalue', this.state)
        }
      )

    setDescription = (description = '') =>
      this._isMounted && this.setState({ description })

    resetState = () =>
      this._isMounted &&
      this.setState(opts.defaultState || defaultState, () =>
        console.log('reset state')
      )

    bindInputRef = c => {
      this.textField = c
      if (this.props.inputRef) {
        this.props.inputRef(c)
      }
    }

    render() {
      // console.log(this.props.propertyName, this.DropDownComponent)
      // if (this.props.propertyName === 'shipToId' && this.props.value) debugger
      // if (this.props.propertyName === 'fastProduct.primaryVendorId')
      // console.log(this.props.propertyName, value, isSet).....................................
      // console.log(
      //   this.props.value,
      //   this.props,
      //   this.state.value,
      //   this.state.isSet
      // )
      // console.log(this)
      return (
        <SearchBase
          {...this.props}
          disabled={
            Reflect.has(this.state, 'disabled')
              ? this.state.disabled
              : this.props.disabled
          }
          TextInput={opts.TextInput}
          meta={this.props.meta || opts.meta}
          dropDownProps={this.state.dropDownProps}
          isFocused={this.state.isFocused}
          isOpen={this.state.isOpen}
          isSet={this.state.isSet}
          mouseover={this.state.mouseover}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onRequestClose={this.onRequestClose}
          value={this.getDisplayValue()}
          onKeyDown={this.onKeyDown}
          onSearchClick={this.onSearchClick}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          findPrev={this.findPrev}
          findNext={this.findNext}
          asModal={!!this.ddiForm}
          disableAutoFocus={opts.disableAutoFocus}
          disableEnforceFocus={opts.disableEnforceFocus}
          disableRestoreFocus={
            Reflect.has(this.state, 'disableRestoreFocus')
              ? this.state.disableRestoreFocus
              : opts.disableRestoreFocus
          }
          popoverStyle={
            this.state.popoverStyle ||
            this.props.popoverStyle ||
            opts.popoverStyle
          }
          dropDownStyle={opts.dropDownStyle}
          DropDownComponent={this.DropDownComponent}
          customContextMenuItems={
            this.props.customContextMenuItems || opts.customContextMenuItems
          }
          contextMenuFn={this.contextMenuFn || opts.contextMenuFn}
          bindInputRef={this.bindInputRef}
          ContextMenu={opts.ContextMenu}
          lastSearch={this.state.lastSearch}
          redoSearch={this.redoSearch}
          form={this.form}
          onCloseFiltersGrid={this.onCloseFiltersGrid}
          popoverAction={this.popoverAction}
          inputValue={this.state.inputValue}
          handleFileUpload={this.handleFileUpload}
          handleFileDownload={this.handleFileDownload}
          showQuickEntityClick={this.showQuickEntityClick}
          handleBarcodeScanner={this.handleBarcodeScanner}
          inputProps={this.props.inputProps || this.state.inputProps}
          // onOkClick={this.onOkClick}
        />
      )
    }
  }

  if (opts.tooltip) {
    C = withTooltip({ ...opts.tooltip })(C)
  }
  return connect(
    null,
    null,
    null,
    { forwardRef: true }
  )(C)
}
