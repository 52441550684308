import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const warrantyTagSOETransaction = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/salesorder/warrantytag`, options)
}

export default warrantyTagSOETransaction
