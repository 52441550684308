/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { List } from 'immutable'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'

import EditableGrid from 'components/EditableGrid'
import SelectGridCell from 'components/EditableGrid/components/SelectGridCell'
import DynamicSelect from 'components/EditableGrid/components/DynamicSelect'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'

import { Paper } from '@material-ui/core'
import ButtonPopover from 'components/ButtonPopover'
import DDICardWrapper from 'components/DDICardWrapper'
import { uomGridDNDUpdate } from 'pages/ProductMaster/actions'
import { setGridHeight } from 'pages/ProductMaster/utils'
import { DDISelectField } from 'ddiForm/wrapped'
import { required } from 'ddiForm/validations'

import {
  getField,
  getIn,
  getMeta,
  emptyList,
  plainDeepEqual,
  isSecureFieldDisabled
} from 'utils'

import './styles/css-mod-ignore.scss'

export class UomSetup extends Component {
  static propTypes = {
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    unitOfMeasures: PropTypes.array.isRequired,
    uoMs: PropTypes.array.isRequired
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !plainDeepEqual(prevProps.unitOfMeasures, this.props.unitOfMeasures) &&
      this.gridApi
    ) {
      const unitOfMeasures = this?.props?.unitOfMeasures?.toJS
        ? this.props.unitOfMeasures.toJS()
        : []
      this.gridApi.setRowData(unitOfMeasures)
      this.gridApi.clearFocusedCell()
    }
  }

  onGridReady = params => {
    let { unitOfMeasures } = this.props
    unitOfMeasures = unitOfMeasures?.toJS ? unitOfMeasures.toJS() : []
    this.gridApi = params.api
    this.columnGridApi = params.columnApi

    this.gridApi.setRowData(unitOfMeasures)
  }

  getColumnDefs = props => {
    const { hasRecord, isEditing, uoMs } = this.props

    const params = {
      form: props.form,
      propertyName: props.propertyName,
      disabledFn: data => {
        if (hasRecord && isEditing) {
          return false
        }

        return true
      }
    }

    let colDefs = [
      {
        field: '',
        rowDrag: this.props.isEditing,
        minWidth: 35,
        width: 35
      },
      {
        field: 'dataId',
        headerName: 'UOM',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          selectDescription: 'dataId',
          values: uoMs.toJS()
        }
      },
      {
        field: 'priorPackQuantity',
        headerName: 'Quantity',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          decimalLimit: 0,
          fixedDecimalLimit: true,
          formatter: 'number',
          textAlign: 'right'
        }
      },
      {
        field: 'baseUOMId',
        headerName: 'Of UOM',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params
        }
      },
      {
        field: 'priceUOMId',
        headerName: 'Price UOM',
        cellRendererFramework: DynamicSelect,
        cellRendererParams: {
          ...params,
          isRequired: true,
          valueField: 'dataId'
        }
      },
      {
        field: 'markup',
        headerName: 'Markup %',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          noDecimalLimit: true,
          formatter: 'number'
        }
      },
      {
        field: 'upc',
        headerName: 'UPC',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params
        }
      },
      {
        field: 'disableSales',
        headerName: 'Disable Sales',
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          ignoreTabLogic: true
        },
        editable: false
      }
    ]

    if (props.isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'delete',
          headerName: '',
          cellRendererFramework: DeleteGridRowCell,
          cellRendererParams: {
            ...params
          },
          editable: false,
          width: 60,
          maxWidth: 60
        }
      ]
    }

    return colDefs
  }

  setGridHeight = memoize(unitOfMeasures => {
    const height = setGridHeight(unitOfMeasures)
    return height < 200 ? 200 : height
  })

  onRowDragEnd = params => {
    if (params?.type && params.type === 'rowDragEnd') {
      const nodes = this.gridApi.getRenderedNodes()
      const updatedRowData = nodes.reduce((acc, next) => {
        acc = acc.concat(next.data)
        return acc
      }, [])

      this.props.dispatch(
        uomGridDNDUpdate(this.props.form, { rowData: updatedRowData })
      )
    }
  }

  render() {
    const { form, hasRecord, isEditing, uoMs, securedFields } = this.props
    let { unitOfMeasures } = this.props

    unitOfMeasures = unitOfMeasures?.toJS ? unitOfMeasures.toJS() : []
    const selectedUOMs = unitOfMeasures.reduce((acc, next) => {
      acc = acc.concat(next.dataId)
      return acc
    }, [])

    const uoms = uoMs.reduce((acc, next) => {
      if (selectedUOMs.includes(next.get('dataId'))) {
        acc = acc.concat(next.toJS())
      }
      return acc
    }, [])

    return (
      <ButtonPopover
        popoverTitle="Setup"
        disabled={!hasRecord}
        popoverMinWidth={800}
      >
        <DDICardWrapper
          title="Unit of Measure Setup"
          contentStyle={{ minHeight: 300, padding: 0 }}
          style={{ marginBottom: 0 }}
          secondaryTitle="Warning: Changes to Unit of Measure Setup will produce undesirable results"
          secondaryTitleStyle={{
            color: '#d9534f',
            fontSize: 11
          }}
        >
          <div style={{ paddingBottom: 50, width: 800 }}>
            <EditableGrid
              addButtonText="Add Unit Of Measure"
              emptyRow={{
                dataId: null,
                description: '',
                priorPackQuantity: 1,
                baseUOMId: '',
                priceUOMId: '',
                markup: '',
                upc: '',
                disableSales: false
              }}
              gridWrapperStyle={{
                flex: '1 1',
                maxWidth: '100%',
                width: '100%'
              }}
              requiredCols={['dataId']}
              propertyName="unitOfMeasures"
              form={form}
              columnDefs={this.getColumnDefs}
              isEditing={isEditing}
              height={this.setGridHeight(unitOfMeasures)}
              width="100%"
              fullWidth
              rowSelection="multiple"
              suppressRowClickSelection={false}
              onGridReady={this.onGridReady}
              animateRows
              rowDragManaged
              onRowDragEnd={this.onRowDragEnd}
              forceFocusOnUpdate
            />
          </div>
          <Paper
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              margin: 10,
              padding: '0 10px 10px 10px'
            }}
          >
            <DDISelectField
              propertyName="uomForDisplayId"
              values={uoms}
              margin="dense"
              selectValue="dataId"
              selectDescription="dataId"
              label="Sales Default"
              validate={[required]}
            />
            <DDISelectField
              propertyName="uomForPriceId"
              values={uoms}
              margin="dense"
              selectValue="dataId"
              selectDescription="dataId"
              label="Price Default"
              validate={[required]}
              preventAutoDisable
              disabled={isSecureFieldDisabled(
                'uomForPriceId',
                isEditing,
                securedFields
              )}
            />

            <DDISelectField
              propertyName="uomForPurchasingId"
              values={uoms}
              margin="dense"
              selectValue="dataId"
              selectDescription="dataId"
              label="Purchase Default"
              validate={[required]}
            />
          </Paper>
        </DDICardWrapper>
      </ButtonPopover>
    )
  }
}

export const contextObj = {
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  unitOfMeasures: form => getField(form, 'unitOfMeasures', emptyList, true),
  uoMs: form => getMeta(form, 'uoMs', emptyList),
  securedFields: form => getIn(form, 'meta.securedFields') || List()
}

export default withContext(contextObj)(UomSetup)
