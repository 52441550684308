import { call, fork, put, select, take } from 'redux-saga/effects'
import { getIn } from 'utils'
import { getFormSelector, getMapResponse } from 'ddiForm/utils'
import { setField } from 'ddiForm/actions'
import { SET_FIELD } from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { api } from 'services'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import { warningModal } from 'modals/sagas'
import { getErrorMessages } from 'pages/ProductMaster/utils'
import { clearLayoutScreenData } from 'pages/Layout/actions'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'

export function* updatePricingTabListener(formListener) {
  while (true) {
    const action = yield take(SET_FIELD)
    const {
      meta: { form },
      payload
    } = action

    if (
      form === formListener &&
      (payload.propertyName === 'pricing.customerId' ||
        payload.propertyName === 'pricing.shipToId')
    ) {
      yield fork(updatePricingProcess, form, payload)
    }
  }
}

export function* updatePricingProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const productDataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''

  const pricingCustomerId =
    getIn(formState, 'fields.pricing.customerId.value') || ''
  const pricingShipToId =
    getIn(formState, 'fields.pricing.shipToId.value') || ''

  const pricingShowGP = getIn(formState, 'fields.showGP.value')
    ? getIn(formState, 'fields.showGP.value')
    : false

  const defaultParams = {
    dataId: productDataId,
    groupNames: ['pricing'],
    pricingCustomerId,
    pricingShipToId,
    pricingShowGP,
    uomId,
    warehouseId
  }

  let apiParams
  if (payload?.propertyName.includes('shipToId')) {
    apiParams = {
      ...defaultParams,
      pricingCustomerId: payload?.results?.parentId
    }
  } else {
    apiParams = { ...defaultParams }
  }

  if (!productDataId) return

  yield put(actions.getTabData.request(form))

  const { response, error } = yield call(api.getProduct, apiParams)

  if (response) {
    yield put(
      getEntityAsync.success(
        {
          pricing: response?.pricing ? response.pricing : {}
        },
        form
      )
    )
  } else {
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export function* showPriceGpListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SHOW_PRICE_GP.TRY)

    if (form === formListener) {
      yield fork(showPriceGpProcess, form)
    }
  }
}

export function* showPriceGpProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const pricingCustomerId =
    getIn(formState, 'fields.pricing.customerId.value') || ''
  const pricingShipToId =
    getIn(formState, 'fields.pricing.shipToId.value') || ''

  if (!dataId) return

  // yield put(actions.getTabData.request(form))
  yield put(getEntityAsync.request(form))

  const { response, error } = yield call(api.getProduct, {
    dataId,
    groupNames: ['pricing'],
    pricingShowGP: true,
    uomId,
    warehouseId,
    pricingCustomerId,
    pricingShipToId
  })

  if (response) {
    const mapResponse = yield getMapResponse({ formState, tabIds: ['pricing'] })
    const R = mapResponse({
      response,
      tabIds: ['pricing'],
      formState,
      groupNames: ['pricing']
    })
    yield put(getEntityAsync.success(R, form))
  } else {
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export function* hideShowPriceGP(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SET_SHOW_PRICE_GP)

    if (form === formListener) {
      yield put(actions.hideShowPriceGP(form))
    }
  }
}

export function* loadCustomerRecordProcess(form) {
  const customer = yield select(state =>
    getIn(state, `layout.screens.${form}.data.customer`)
  )

  if (customer) {
    yield put(setField(form, 'pricing.customerId', customer))
    yield put(clearLayoutScreenData(form))
  }
}

export function* loadCustomerRecordListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LOAD_CUSTOMER_RECORD)
    const {
      meta: { form }
    } = action

    yield fork(loadCustomerRecordProcess, form)
  }
}

export function* showGPListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(SET_FIELD)

    if (form === formListener && propertyName === 'showGP') {
      const action = value
        ? actions.showPriceGp.try(form)
        : actions.hideShowPriceGP(form)
      yield put(action)
    }
  }
}

export function* customerIdListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH)

    if (form === formListener && propertyName === 'pricing.customerId') {
      yield put(actions.clearCustomerPricingFields(form))
    }
  }
}

export default function* pricingSagas(form) {
  yield fork(updatePricingTabListener, form)
  yield fork(showPriceGpListener, form)
  yield fork(hideShowPriceGP, form)
  yield fork(loadCustomerRecordListener, form)
  yield fork(showGPListener, form)
  yield fork(customerIdListener, form)
}
