import behaviors from './behaviors'
import createSearch from '../createSearch'
import DropDownComponent from './SerialSearchDropdown'

const SerialNumberSearch = createSearch({
  name: 'SerialNumberSearch',
  behaviors,
  popoverStyle: { width: 500 },
  DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowNavigation: false,
    allowSearchAll: true,
    allowSpecialProducts: false,
    hasFilters: false,
    hideToggle: true,
    isSecure: false,
    minimumKeywordLength: 2,
    searchType: 'A',
    transactionType: 'S'
  }
})

export default SerialNumberSearch
