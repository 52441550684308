import { createAction } from 'utils'
import * as CONSTANTS from './constants'

/* labels & Criteria Selection */
export const getLabelPrintingCriteria = form =>
  createAction(CONSTANTS.GET_LABEL_PRINTING_CRITERIA.TRY, {}, form)

export const toggleCriteriaSelection = (form, { dataId }) =>
  createAction(CONSTANTS.TOGGLE_CRITERIA_SELECTION, { dataId }, form)

/*
  I do not think we need this function anymore,
  (toggleEqualToCriteria) but want to make sure before removing it
*/
export const toggleEqualToCriteria = (
  form,
  { criteriaName, gridPath, selectedRows, rowIndex }
) =>
  createAction(
    CONSTANTS.TOGGLE_EQUAL_TO_CRITERIA,
    { criteriaName, gridPath, selectedRows, rowIndex },
    form
  )

export const resetCriteriaSelection = (form, { type }) =>
  createAction(CONSTANTS.RESET_CRITERIA_SELECTION, { type }, form)

export const clearCriteriaSelection = form =>
  createAction(CONSTANTS.CLEAR_CRITERIA_SELECTION, {}, form)

export const launchFieldSelectionModal = (form, { parentModalCb, type }) =>
  createAction(
    CONSTANTS.LAUNCH_FIELD_SELECTION_MODAL,
    { parentModalCb, type },
    form
  )

export const getLabelPrintingDefaults = (form, { parentModalCb }) =>
  createAction(
    CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.TRY,
    { parentModalCb },
    form
  )

export const runPrintLabels = form =>
  createAction(CONSTANTS.RUN_PRINT_LABELS.TRY, {}, form)

export const exitPrintLabels = (form, { updateDefaults }) =>
  createAction(CONSTANTS.EXIT_PRINT_LABELS.TRY, { updateDefaults }, form)

export const launchLabelParamsModal = (form, { parentModalCb }) =>
  createAction(CONSTANTS.LAUNCH_LABEL_PARAMS_MODAL, { parentModalCb }, form)

/* report criteria */
export const getReportCriteria = form =>
  createAction(CONSTANTS.GET_REPORT_CRITERIA.TRY, {}, form)

export const setReportCriteria = (form, { parentModalCb }) =>
  createAction(CONSTANTS.SET_REPORT_CRITERIA.TRY, { parentModalCb }, form)

export const cancelReport = form =>
  createAction(CONSTANTS.CANCEL_REPORT.TRY, {}, form)

export const updatePrintOrder = (form, { printOrder }) =>
  createAction(CONSTANTS.UPDATE_PRINT_ORDER, { printOrder }, form)

export const resetFieldSelection = form =>
  createAction(CONSTANTS.RESET_FIELD_SELECTION, {}, form)

export const deletePrintOrderItem = (form, { dataId, rowIndex }) =>
  createAction(CONSTANTS.DELETE_PRINT_ORDER_ITEM, { dataId, rowIndex }, form)

export const closeParentModal = (form, { modalId }) =>
  createAction(CONSTANTS.CLOSE_PARENT_MODAL, { modalId }, form)

export const viewReport = (form, { dataId, type }) =>
  createAction(CONSTANTS.VIEW_REPORT.TRY, { dataId, type }, form)

export const clearSelectionCriteriaFields = form =>
  createAction(CONSTANTS.CLEAR_SELECTION_CRITERIA_FIELDS, {}, form)

export const deleteOnlyGridRow = (form, { propertyName, rowIndex }) =>
  createAction(CONSTANTS.DELETE_ONLY_GRID_ROW, { propertyName, rowIndex }, form)

export const setInitialRowData = (form, { propertyName, rowData }) =>
  createAction(CONSTANTS.SET_INITIAL_ROW_DATA, { propertyName, rowData }, form)
