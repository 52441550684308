import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import shortid from 'shortid'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { getCellColor } from '../../../utils'

const VendorSummaryGrid = ({
  contentStyle = { padding: 0 },
  title = '',
  rowData = [],
  style = { marginBottom: 10 }
}) => {
  return (
    <DDICardWrapper title={title} style={style} contentStyle={contentStyle}>
      <Table size="small">
        <TableBody>
          {rowData.map((x, i) => (
            <TableRow key={shortid.generate()}>
              <TableCell>{x.key}</TableCell>
              <TableCell
                style={{ color: getCellColor(x.value), fontWeight: 700 }}
                align="right"
              >
                {x.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </DDICardWrapper>
  )
}

export default VendorSummaryGrid
