/* eslint import/prefer-default-export: 0 */
import React from 'react'
import memoize from 'memoize-one'
import { Map, Set } from 'immutable'
import { Icon } from '@material-ui/core'
import {
  dateFilterComparator,
  fromJS,
  getIn,
  setIn,
  formatNumber,
  plainDeepEqual
} from 'utils'
import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'
import { handleEditableGridAPIData } from 'components/EditableGrid/utils'
import { errorRed, darkGrey } from 'theme/colors'

const convertNullDescriptionToEmptyString = (data, prop) => {
  if (prop && prop.match(/Description/) && data === null) {
    return ''
  }

  return data
}

export const mapGetRecordResponse = ({
  response,
  tabNames = [],
  formState,
  groupNames = []
}) => {
  let res = response
  // const tabIds = tabNames
  const nested = [...tabNames, ...groupNames]
  const retainNestedStructure = [
    'ledger',
    'invoices',
    'paymentHistory',
    'transactions',
    'orders',
    'johnstone'
  ]
  const requireRowIdsForGridEditing = [
    'taxableStates',
    'expenseGLIds',
    'sourceCodes',
    'productPatterns',
    'serialNumberPatterns'
  ]
  // console.log('nested', nested)
  // debugger

  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (nested.includes(next) && !retainNestedStructure.includes(next)) {
        if (
          response[next] !== null &&
          typeof response[next] === 'object' &&
          Object.keys(response[next]).length
        ) {
          for (const prop in response[next]) {
            if (prop === 'customFieldsData') {
              acc = mapCustomFieldsData(acc, response[next][prop])
            }

            acc[prop] = requireRowIdsForGridEditing.includes(prop)
              ? handleEditableGridAPIData(formState, response, next, prop)
              : convertNullDescriptionToEmptyString(response[next][prop], prop)
          }
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})
    // debugger

    res = tempResponse
  }

  return res
}

export const twoColFieldWrapperStyle = {
  flexBasis: '50%',
  margin: 15,
  position: 'relative'
}

export const states = [
  {
    description: 'Alabama',
    dataId: 'AL'
  },
  {
    description: 'Alaska',
    dataId: 'AK'
  },
  {
    description: 'Arizona',
    dataId: 'AZ'
  },
  {
    description: 'Arkansas',
    dataId: 'AR'
  },
  {
    description: 'California',
    dataId: 'CA'
  },
  {
    description: 'Colorado',
    dataId: 'CO'
  },
  {
    description: 'Connecticut',
    dataId: 'CT'
  },
  {
    description: 'Delaware',
    dataId: 'DE'
  },
  {
    description: 'District Of Columbia',
    dataId: 'DC'
  },
  {
    description: 'Florida',
    dataId: 'FL'
  },
  {
    description: 'Georgia',
    dataId: 'GA'
  },
  {
    description: 'Hawaii',
    dataId: 'HI'
  },
  {
    description: 'Idaho',
    dataId: 'ID'
  },
  {
    description: 'Illinois',
    dataId: 'IL'
  },
  {
    description: 'Indiana',
    dataId: 'IN'
  },
  {
    description: 'Iowa',
    dataId: 'IA'
  },
  {
    description: 'Kansas',
    dataId: 'KS'
  },
  {
    description: 'Kentucky',
    dataId: 'KY'
  },
  {
    description: 'Louisiana',
    dataId: 'LA'
  },
  {
    description: 'Maine',
    dataId: 'ME'
  },
  {
    description: 'Maryland',
    dataId: 'MD'
  },
  {
    description: 'Massachusetts',
    dataId: 'MA'
  },
  {
    description: 'Michigan',
    dataId: 'MI'
  },
  {
    description: 'Minnesota',
    dataId: 'MN'
  },
  {
    description: 'Mississippi',
    dataId: 'MS'
  },
  {
    description: 'Missouri',
    dataId: 'MO'
  },
  {
    description: 'Montana',
    dataId: 'MT'
  },
  {
    description: 'Nebraska',
    dataId: 'NE'
  },
  {
    description: 'Nevada',
    dataId: 'NV'
  },
  {
    description: 'New Hampshire',
    dataId: 'NH'
  },
  {
    description: 'New Jersey',
    dataId: 'NJ'
  },
  {
    description: 'New Mexico',
    dataId: 'NM'
  },
  {
    description: 'New York',
    dataId: 'NY'
  },
  {
    description: 'North Carolina',
    dataId: 'NC'
  },
  {
    description: 'North Dakota',
    dataId: 'ND'
  },
  {
    description: 'Ohio',
    dataId: 'OH'
  },
  {
    description: 'Oklahoma',
    dataId: 'OK'
  },
  {
    description: 'Oregon',
    dataId: 'OR'
  },
  {
    description: 'Palau',
    dataId: 'PW'
  },
  {
    description: 'Pennsylvania',
    dataId: 'PA'
  },
  {
    description: 'Rhode Island',
    dataId: 'RI'
  },
  {
    description: 'South Carolina',
    dataId: 'SC'
  },
  {
    description: 'South Dakota',
    dataId: 'SD'
  },
  {
    description: 'Tennessee',
    dataId: 'TN'
  },
  {
    description: 'Texas',
    dataId: 'TX'
  },
  {
    description: 'Utah',
    dataId: 'UT'
  },
  {
    description: 'Vermont',
    dataId: 'VT'
  },
  {
    description: 'Virginia',
    dataId: 'VA'
  },
  {
    description: 'Washington',
    dataId: 'WA'
  },
  {
    description: 'West Virginia',
    dataId: 'WV'
  },
  {
    description: 'Wisconsin',
    dataId: 'WI'
  },
  {
    description: 'Wyoming',
    dataId: 'WY'
  }
]

export const statesAndProvinces = [
  ...states,
  {
    description: 'Alberta',
    dataId: 'AB'
  },
  {
    description: 'British Columbia',
    dataId: 'BC'
  },
  {
    description: 'Manitoba',
    dataId: 'MB'
  },
  {
    description: 'New Brunswick',
    dataId: 'NB'
  },
  {
    description: 'Newfoundland and Labrador',
    dataId: 'NL'
  },
  {
    description: 'Northwest Territories',
    dataId: 'NT'
  },
  {
    description: 'Nova Scotia',
    dataId: 'NS'
  },
  {
    description: 'Nunavut',
    dataId: 'NU'
  },
  {
    description: 'Ontario',
    dataId: 'ON'
  },
  {
    description: 'Prince Edward Island',
    dataId: 'PE'
  },
  {
    description: 'Quebec',
    dataId: 'QC'
  },
  {
    description: 'Saskatchewan',
    dataId: 'SK'
  },
  {
    description: 'Yukon Territory',
    dataId: 'YT'
  }
]

export const getCellColor = value => {
  if (!value) {
    return darkGrey
  }

  return value && value?.replace && Number(value.replace(/,/gi, '')) < 0
    ? errorRed
    : darkGrey
}

export const AmountPinnedRowRenderer = ({ value = '', colDef, ...other }) => {
  return <div>{value || value === 0 ? formatNumber(value) : value}</div>
}

export const setGridHeight = (
  array = [],
  additionalHeight = 0,
  headerHeight = 32
) => array.length * 28 + (headerHeight + array.length) + additionalHeight

export const getDetailColumnField = memoize(field => {
  const mapping = {
    balanceDue: 'balance',
    branchId: 'bankGLId',
    date: 'checkDate',
    discount: 'discountTaken',
    grossAmount: 'payAmount',
    invoiceDate: 'checkDate'
  }

  if (mapping[field]) {
    return mapping[field]
  }

  return field
})

export const sizeDetailColumns = (
  columnApi,
  gridApi,
  _isMounted,
  getDetailColumnFieldMapper = getDetailColumnField
) => {
  if (columnApi && gridApi && _isMounted) {
    const parentColumnState = columnApi.getColumnState()
    gridApi.forEachDetailGridInfo(info => {
      const detailColumnState = info.columnApi.getColumnState()
      info.api.sizeColumnsToFit()
      if (detailColumnState.length) {
        info.api.setHeaderHeight(0)
        for (let i = 0; i < detailColumnState.length; i++) {
          const { colId } = detailColumnState[i]
          const correctIndex = parentColumnState.findIndex(
            x => getDetailColumnFieldMapper(x.colId) === colId
          )

          if (colId === 'dataId') {
            const width = parentColumnState.reduce((acc, next, idx) => {
              if (idx < 2) {
                acc += next?.width ? next.width : 0
              }
              return acc
            }, 0)
            info.columnApi.setColumnWidth(colId, width)
          } else if (
            correctIndex > 0 &&
            parentColumnState?.[correctIndex]?.width
          ) {
            info.columnApi.setColumnWidth(
              colId,
              parentColumnState[correctIndex].width
            )
          }
        }
      }
    })
  }
}

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const getCellStyle = params => {
  if (params?.value < 0) {
    return { color: errorRed }
  }

  return null
}

export const mapActivitiesResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (next === 'activities' && response?.[next]?.activities) {
        acc[next] = response?.[next]?.activities
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }
  // debugger

  return res
}

export const isTabHidden = form => {
  const {
    values: { newMasterRecord, templateKey, isNew },
    preNewMode,
    newEntity
  } = form

  if (
    preNewMode ||
    newMasterRecord ||
    templateKey ||
    newEntity ||
    isNew ||
    form?.values?.preNewMode
  ) {
    return true
  }

  return false
}

export const resetLedgerInputs = result => {
  const defaultYear = getIn(result, 'meta.ledgerMeta.defaultYear')

  if (getIn(result, 'fields.invoices.invoiceType')) {
    result = setIn(result, 'fields.invoices.invoiceType.value', 'I')
    result = setIn(result, 'fields.invoices.invoiceYear.value', defaultYear)
  }

  if (getIn(result, 'fields.paymentHistory.paymentHistoryYear')) {
    result = setIn(
      result,
      'fields.paymentHistory.paymentHistoryYear.value',
      defaultYear
    )
  }

  if (getIn(result, 'fields.transactions.transactionFromDate')) {
    result = setIn(
      result,
      'fields.transactions.transactionFromDate.value',
      getIn(result, 'meta.ledgerMeta.defaultTransactionDate')
    )
  }

  if (getIn(result, 'fields.orders.poStatus')) {
    result = setIn(result, 'fields.orders.poStatus.value', 'O')
  }

  return result
}

export const validateGridRowImmutable = (rowsToTest, data) =>
  rowsToTest.reduce((a, n) => {
    if (Map.isMap(data) && !data.get(n)) {
      a += 1
    }
    return a
  }, 0)

export const updateEditedFields = (state, propertyName) => {
  let editedFields = getIn(state, 'editedFields') || Set()
  if (editedFields && !propertyName.match(/selectionCriteria/gi)) {
    editedFields = editedFields.add(propertyName)
    state = setIn(state, 'editedFields', editedFields)
  }

  return state
}

export const getOptionsArray = memoize(
  immutableList => (immutableList?.toJS ? immutableList.toJS() : []),
  plainDeepEqual
)

export const removeTrackedTabsFromState = state => {
  const selectedPrimaryTab = getIn(state, 'masterOptions.selectedPrimaryTab')
  const selectedSecondaryTab = getIn(
    state,
    'masterOptions.selectedSecondaryTab'
  )
  let trackedTabs = Set()

  if (selectedPrimaryTab) {
    trackedTabs = trackedTabs.add(selectedPrimaryTab)
  }

  if (selectedSecondaryTab) {
    trackedTabs = trackedTabs.add(selectedSecondaryTab)
  }

  state = setIn(state, 'masterOptions.trackedTabs', trackedTabs)
  return state
}

export const clearIsPendingFlags = state => {
  let fields = getIn(state, 'fields')
  /* for old EditableGrids */
  fields = fields.map(x => {
    if (x.has('isPending')) {
      x = x.set('isPending', false)
    }
    return x
  })

  state = setIn(state, 'fields', fields)

  return state
}

export const transformDataBeforeSave = (payload, formState) => {
  const { properties = {}, ...rest } = payload
  const hasCustomFields = Object.keys(properties).some(x =>
    x.includes('customFields')
  )

  const isDropShipTab =
    payload?.groupNames &&
    Array.isArray(payload.groupNames) &&
    payload.groupNames.includes('dropShipCharges')

  // debugger
  let res
  if (properties && Object.keys(properties).length && hasCustomFields) {
    res = {
      ...rest,
      groupNames: isDropShipTab
        ? [...payload.groupNames, 'dropShip']
        : [...payload.groupNames],
      properties: {
        ...properties,
        customFieldsData: Object.keys(properties).reduce((acc, next) => {
          if (next.includes('customFields')) {
            const dataId =
              getIn(formState, `fields.${next}.meta.dataId`) || next
            acc = [
              ...acc,
              {
                dataId,
                value: properties[next]
              }
            ]
          }
          return acc
        }, [])
      }
    }
  } else if (isDropShipTab) {
    res = {
      ...payload,
      groupNames: [...payload.groupNames, 'dropShip']
    }
  } else {
    res = { ...payload }
  }

  return res
}

export const setupGrid = (
  result,
  propertyName,
  emptyRow = {
    rowId: 'blankrow',
    dataId: null
  }
) => {
  result = setIn(result, `fields.${propertyName}.grid`, true)
  result = setIn(result, `fields.${propertyName}.rowData`, fromJS([]))
  result = setIn(result, `fields.${propertyName}.emptyRow`, fromJS(emptyRow))

  return result
}

export const setUpScreenGrids = result => {
  result = setupGrid(result, 'productPatterns', {
    rowId: 'blankrow',
    dataId: null
  })

  result = setupGrid(result, 'serialNumberPatterns', {
    rowId: 'blankrow',
    dataId: null
  })

  result = setupGrid(result, 'taxableStates', {
    rowId: 'blankrow',
    dataId: null,
    tax: ''
  })

  result = setupGrid(result, 'sourceCodes', {
    dataId: null,
    description: ''
  })

  result = setupGrid(result, 'expenseGLIds', {
    glId: null,
    description: ''
  })

  return result
}

export const templateLabel = description => (
  <div
    className="ddi-data-label"
    style={{
      background: '#eaeef0',
      borderRadius: 4,
      color: '#517b9c',
      display: 'inline-block',
      fontSize: 12,
      lineHeight: '16px',
      maxWidth: '100%',
      overflow: 'hidden',
      padding: 5,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: 'auto'
    }}
  >
    <Icon
      style={{
        color: '#517b9c',
        fontSize: 16,
        marginRight: 5,
        verticalAlign: 'middle'
      }}
    >
      info
    </Icon>
    {description}
  </div>
)

export const dateFilterGridConfigs = {
  filter: 'agDateColumnFilter',
  filterParams: {
    clearButton: true,
    comparator: dateFilterComparator,
    inRangeInclusive: true,
    suppressAndOrCondition: true,
    browserDatePicker: true
  }
}

export const numberFilterGridConfigs = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    clearButton: true,
    inRangeInclusive: true,
    suppressAndOrCondition: true
  }
}

export const addLedgerInvoiceAttachmentParams = ({
  apiParams,
  additionalAttachmentsData
}) => {
  const {
    modalData: {
      invoiceYear,
      targetType,
      invoiceType,
      includeRemitToInvoices,
      uniqueKey
    }
  } = additionalAttachmentsData
  return {
    ...apiParams,
    targetType,
    invoiceYear,
    invoiceType,
    includeRemitToInvoices,
    uniqueKey
  }
}

export const addLedgerPaymentHistoryAttachmentParams = ({
  apiParams,
  additionalAttachmentsData
}) => {
  const {
    modalData: { paymentHistoryYear, targetType, uniqueKey }
  } = additionalAttachmentsData
  return {
    ...apiParams,
    targetType,
    paymentHistoryYear,
    uniqueKey
  }
}

export const addLedgerOrderAttachmentParams = ({
  apiParams,
  additionalAttachmentsData
}) => {
  const {
    modalData: { poStatus, targetType, uniqueKey }
  } = additionalAttachmentsData
  return {
    ...apiParams,
    targetType,
    poStatus,
    uniqueKey
  }
}
