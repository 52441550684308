import { actionChannel, take, call, select, fork } from 'redux-saga/effects'
import { getIn, toJS } from 'utils'
import * as EVENT_MODAL_CONSTANTS from 'modals/EventModal/constants'
import { getFormSelector } from 'ddiForm/utils'

import eventModalSagas from 'modals/EventModal/sagas'
import { addNewActivity } from 'modals/EventModal/actions'
import { listActivitiesProcess } from 'components/MasterScreen/Activities/sagas'

export function* getListActivitiesApiParams(form, isSummary = false) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const guid = getIn(formState, 'guid')

  return {
    form,
    dataId,
    guid,
    // parentId,
    // parentType,
    groupNames: ['activities'],
    summary: isSummary
  }
}

export function* refreshActivitiesListener(formListener) {
  const channel = yield actionChannel([
    EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS,
    EVENT_MODAL_CONSTANTS.CLOSE_ACTIVITY_MODAL,
    EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS
  ])

  while (true) {
    const action = yield take(channel)
    if (action.meta && action.meta.form && action.meta.form === formListener) {
      const activitiesApiParams = yield call(
        getListActivitiesApiParams,
        formListener
      )
      yield fork(listActivitiesProcess, activitiesApiParams)
    }
  }
}

export default function* activitiesSagas(form) {
  yield fork(eventModalSagas, form)
  yield fork(refreshActivitiesListener, form)
}
