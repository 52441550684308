import { createAction, createAsyncFormAction } from 'utils'

import * as CONSTANTS from './constants'

export const print = createAsyncFormAction(CONSTANTS.PRINT)

export const propertyChange = createAsyncFormAction(CONSTANTS.PROPERTY_CHANGE)

export const deleteWarrantyTag = createAsyncFormAction(
  CONSTANTS.DELETE_WARRANTY_TAG
)

export const saveWarrantyTag = createAsyncFormAction(
  CONSTANTS.SAVE_WARRANTY_TAG
)

export const originalInvoiceSearch = createAsyncFormAction(
  CONSTANTS.ORIGINAL_INVOICE_SEARCH
)
