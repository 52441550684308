/* eslint import/prefer-default-export: 0 */
import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { getIn } from 'utils'
import { noop } from 'lodash'
import * as CONSTANTS from 'pages/ProductMaster/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { UNLOCK_TEMPLATE } from 'components/MasterScreen/Template/constants'
import {
  getFormSelector,
  getSelectedTabsFromState,
  getMapResponse
  // mapResponse
} from 'ddiForm/utils'
import { api } from 'services'
import editableGridSagas from 'components/EditableGrid/sagas'
import selectionCriteriaModalSagas from 'components/SelectionCriteriaModal/sagas'
import {
  DELETE_GRID_ROW,
  UPDATE_GRID_CELL_DATA,
  CLEAR_GRID_ROW,
  ON_PRIMARY_GRID_DATA_VALIDATED
} from 'components/EditableGrid/constants'
import { warningModal } from 'modals/sagas'
import { getEntityAsync, resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { setField } from 'ddiForm/actions'
import { getRecord, searchProcess } from 'ddiForm/MasterScreen/sagas'
import { displayValidationErrors } from 'ddiForm/sagas'

import MergeProductModal from 'pages/ProductMaster/modals/MergeProductModal'
import mergeModalSagas, {
  openMergeModalListener
} from 'modals/MergeModal/sagas'

import { addModal } from 'modals/actions'
import BinSearchModal from 'pages/ProductMaster/tabs/Setup/components/modals/BinSearchModal'
import {
  mapAnalysisTabNames,
  getInventoryNames
} from 'pages/ProductMaster/utils'

import { validateAssemblies } from './assembliesSagas'
import * as actions from '../actions'

export function* getTabData(form, tabs, inventoryTab = null, cb = noop) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const showAllAudits = getIn(formState, 'fields.showAllAudits.value') || false
  const auditBinShowAll =
    getIn(formState, 'fields.auditBinShowAll.value') || false

  const selectedInventoryTabFromState =
    getIn(formState, 'values.selectedInventoryTab') || null
  const selectedInventoryTab = inventoryTab || selectedInventoryTabFromState

  const inventoryData = getIn(formState, 'meta.inventoryNames')
  const inventoryNames = getInventoryNames(inventoryData, selectedInventoryTab)

  /*
    generally speaking, we need to update the setup tabs
    if we are switching the warehouse around in Analysis
  */
  if (!dataId) return

  const defaultParams = {
    dataId,
    groupNames: tabs,
    warehouseId,
    uomId,
    showAll: showAllAudits,
    auditBinShowAll
  }

  let apiParams

  if (tabs.includes('pricing')) {
    const pricingShowGP = getIn(formState, 'fields.showGP.value') || false
    const pricingCustomerId =
      getIn(formState, 'fields.pricing.customerId.value') || ''
    const pricingShipToId =
      getIn(formState, 'fields.pricing.shipToId.value') || ''

    apiParams = {
      ...defaultParams,
      pricingShowGP,
      pricingCustomerId,
      pricingShipToId
    }
  } else if (tabs.includes('inventory')) {
    apiParams = {
      ...defaultParams,
      inventoryNames
    }

    if (
      !inventoryTab &&
      selectedInventoryTabFromState &&
      selectedInventoryTabFromState === 'Johnstone'
    ) {
      /* this condition should only be reached when the user changes the warehouse or UOM while looking at a record */
      apiParams = {
        ...apiParams,
        groupNames: [...tabs, 'johnstoneNational']
      }
    }
  } else {
    apiParams = { ...defaultParams }
  }

  yield put(getEntityAsync.request(form))

  const { response, error } = yield call(api.getProduct, apiParams)

  if (response) {
    const tabIds = tabs.filter(x => x !== 'johnstoneNational')
    const mapResponse = yield getMapResponse({ formState, tabIds })
    const R = mapResponse({
      response,
      tabIds,
      formState,
      groupNames: tabIds
    })
    yield put(getEntityAsync.success(R, form))

    if (cb && typeof cb === 'function' && inventoryTab) {
      cb(inventoryTab)
      yield put(actions.setSelectedInventoryTab(form, { tab: inventoryTab }))
    }
  } else {
    yield put(actions.getTabData.failure(error, form))
    /* 
      we don't want to use getEntityAysnc.failure because that 
      wipes out the record (which doesn't make a lot of sense). Also note
      that this failure action handles displaying the error
      modal if necessary (in the case of a 496 error) -- SVE 2/21/2022
    */
  }
}

export function* mainScreenGridChangeListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(UPDATE_GRID_CELL_DATA)

    const formState = yield select(getFormSelector(form))
    let rollUpGrid = getIn(formState, 'fields.rollUps.rowData')
    rollUpGrid = rollUpGrid && rollUpGrid.toJS ? rollUpGrid.toJS() : []

    if (
      form === formListener &&
      payload.field === 'basePriceId' &&
      payload.propertyName === 'rollUps'
    ) {
      if (
        rollUpGrid[payload.rowIndex] &&
        !rollUpGrid[payload.rowIndex].operatorType
      ) {
        yield put({
          type: UPDATE_GRID_CELL_DATA,
          meta: { form },
          payload: {
            ...payload,
            field: 'operatorType',
            value: payload.value.charAt(0) === 'L' ? '-' : '+'
          }
        })
      }

      if (
        rollUpGrid[payload.rowIndex] &&
        !rollUpGrid[payload.rowIndex].valueType
      ) {
        yield put({
          type: UPDATE_GRID_CELL_DATA,
          meta: { form },
          payload: {
            ...payload,
            field: 'valueType',
            value: '%'
          }
        })
      }
    }

    if (
      form === formListener &&
      payload.field === 'dataId' &&
      payload.propertyName === 'unitOfMeasures'
    ) {
      let uomGrid = getIn(formState, 'fields.unitOfMeasures.rowData')
      uomGrid = uomGrid && uomGrid.toJS ? uomGrid.toJS() : []
      const dataIds = uomGrid.reduce((acc, next) => {
        if (next.dataId === payload.value) {
          acc = acc.concat(next.dataId)
        }
        return acc
      }, [])

      /* no repeats */
      if (dataIds.length > 1) {
        yield call(
          warningModal,
          `${payload.value} already exists`,
          'Attention!'
        )
        yield put({
          type: UPDATE_GRID_CELL_DATA,
          meta: { form },
          payload: {
            ...payload,
            value: ''
          }
        })
      } else {
        yield put(actions.setUOMDefaults(form, { rowIndex: payload.rowIndex }))
      }
    }

    if (
      form === formListener &&
      payload.field === 'fromWarehouseId' &&
      payload.propertyName === 'replenishments'
    ) {
      yield fork(validateWarehouseReplenishments, form, payload)
    }
  }
}

export function* validateWarehouseReplenishments(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { rowIndex } = payload
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const templateKey = getIn(formState, 'values.templateKey')

  let replenishments = getIn(formState, 'fields.replenishments.rowData')
  replenishments =
    replenishments && replenishments.toJS ? replenishments.toJS() : []

  yield put(actions.validateWarehouseReplenishments.request(form))

  const apiParams = templateKey
    ? {
        templateKey,
        warehouse: replenishments[rowIndex]
      }
    : {
        dataId,
        warehouse: replenishments[rowIndex]
      }

  const { response, error } = yield call(
    api.validateWarehouseReplenishments,
    apiParams
  )

  if (response) {
    yield put(
      actions.validateWarehouseReplenishments.success(
        {
          ...response,
          rowIndex
        },
        form
      )
    )
  } else {
    yield put(
      actions.validateWarehouseReplenishments.failure(
        {
          ...error,
          rowIndex
        },
        form
      )
    )
  }
}

export function* calculateRollupPriceProcess(
  form,
  rollupId,
  rowIndex,
  value,
  route
) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const templateKey = getIn(formState, 'values.templateKey')

  yield put({
    type: CONSTANTS.CALCULATE_ROLLUP_PRICE.REQUEST,
    meta: { form, apiRequest: true }
  })

  const apiParams =
    route === 'chaindiscount'
      ? {
          dataId,
          templateKey,
          rollupId,
          chainDiscount: value,
          warehouseId,
          route
        }
      : {
          dataId,
          rollupId,
          templateKey,
          value,
          warehouseId,
          route
        }

  const { response, error } = yield call(api.calculateRollupPrice, apiParams)

  if (response) {
    yield put({
      type: CONSTANTS.CALCULATE_ROLLUP_PRICE.SUCCESS,
      payload: {
        ...response,
        rowIndex
      },
      meta: { form }
    })
    yield fork(changeRollupProcess, form, rowIndex)
  } else {
    yield put({
      type: CONSTANTS.CALCULATE_ROLLUP_PRICE.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* calculateRollupPriceListener(formListener) {
  while (true) {
    const {
      payload: { rollupId, route, rowIndex, value },
      meta: { form }
    } = yield take(CONSTANTS.CALCULATE_ROLLUP_PRICE.TRY)

    if (form === formListener) {
      yield fork(
        calculateRollupPriceProcess,
        form,
        rollupId,
        rowIndex,
        value,
        route
      )
    }
  }
}

export function* updatePricesProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const templateKey = getIn(formState, 'values.templateKey')

  yield put({
    type: CONSTANTS.UPDATE_PRICES.REQUEST,
    meta: { apiRequest: true, form }
  })

  const { response, error } = yield call(api.updatePrices, {
    dataId,
    templateKey,
    warehouseId
  })

  if (response) {
    yield put({
      type: CONSTANTS.UPDATE_PRICES.SUCCESS,
      payload: response,
      meta: { form }
    })

    /*
      ensure that the system knows it needs to fetch Cost/Price data again
      the next time the user visits Product Analysis
    */
    yield put(
      actions.removeTrackedTabs(form, {
        tabs: ['analysis', 'overview']
      })
    )
  } else {
    yield put({
      type: CONSTANTS.UPDATE_PRICES.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* updatePricesListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.UPDATE_PRICES.TRY)

    if (form === formListener) {
      yield fork(updatePricesProcess, form)
    }
  }
}

export function* changeRollupProcess(form, rowIndex, isRowDeletion = false) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const templateKey = getIn(formState, 'values.templateKey')

  const rollUps = getIn(formState, 'fields.rollUps.rowData')
  let rollUp = rollUps.get(rowIndex)
  rollUp = rollUp && rollUp.toJS ? rollUp.toJS() : {}

  // debugger
  if (
    (Object.keys(rollUp).length &&
      (rollUp.operatorType &&
        rollUp.valueType &&
        rollUp.basePriceId &&
        rollUp.amount)) ||
    (Object.keys(rollUp).length && isRowDeletion)
  ) {
    // debugger
    yield put(actions.changeRollUp.request(form))

    const { response, error } = yield call(api.changeRollUp, {
      dataId,
      templateKey,
      warehouseId,
      rollUp
    })

    if (response) {
      yield put(actions.changeRollUp.success(response, form))
    } else {
      yield put(actions.changeRollUp.failure(error, form))
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* clearGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex }
    } = yield take(CLEAR_GRID_ROW)

    if (form === formListener && propertyName === 'rollUps') {
      yield fork(changeRollupProcess, form, rowIndex, true)
    }
  }
}

export function* changePriceOrCostProcess(form, rowIndex, value) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const templateKey = getIn(formState, 'values.templateKey')

  const prices = getIn(formState, 'fields.prices.rowData')
  const priceId = prices.get(rowIndex).get('dataId')

  yield put({
    type: CONSTANTS.CHANGE_PRICE_OR_COST.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.changePriceOrCost, {
    dataId,
    templateKey,
    warehouseId,
    priceId,
    priceAmount: value
  })

  if (response) {
    yield put({
      type: CONSTANTS.CHANGE_PRICE_OR_COST.SUCCESS,
      payload: response,
      meta: { form }
    })

    /*
      ensure that the system knows it needs to fetch Cost/Price data again
      the next time the user visits Product Analysis
    */
    yield put(
      actions.removeTrackedTabs(form, {
        tabs: ['analysis', 'overview']
      })
    )
  } else {
    yield put({
      type: CONSTANTS.CHANGE_PRICE_OR_COST.FAILURE,
      payload: error,
      meta: { form }
    })

    if (error.message && typeof error.message === 'string') {
      yield fork(warningModal, error.message, 'Error!')
    }
  }
}

export function* gridCellChangeListener(formListener) {
  while (true) {
    const {
      payload: { field, propertyName, rowIndex, value, newData },
      meta: { form },
      type
    } = yield take([
      UPDATE_GRID_CELL_DATA,
      ON_PRIMARY_GRID_DATA_VALIDATED,
      CONSTANTS.SET_ROLLUP_AMOUNT
    ])

    if (form === formListener) {
      if (propertyName === 'prices' && field === 'amount') {
        yield fork(changePriceOrCostProcess, form, rowIndex, value)
      }

      if (propertyName === 'rollUps' || type === CONSTANTS.SET_ROLLUP_AMOUNT) {
        yield fork(changeRollupProcess, form, rowIndex)
      }

      if (propertyName === 'vendors') {
        if (field === 'isPrimary') {
          yield put(actions.setPrimaryVendor(form, { rowIndex }))
        } else {
          /* save vendors data any time a cell is changed */
          /* OR wait for the setPrimaryVendor action to complete^^ */
          yield fork(saveVendorsDataProcess, form)
        }
      }

      if (
        propertyName === 'components' &&
        type === ON_PRIMARY_GRID_DATA_VALIDATED
      ) {
        yield fork(validateAssemblies, form, rowIndex, propertyName, newData)
      }

      /* de-select other Bin rows */
      if (
        propertyName &&
        propertyName.match(/bins/gi) &&
        field === 'isPrimary' &&
        value
      ) {
        yield put(
          actions.setPrimaryBinCheckbox(form, { propertyName, rowIndex })
        )
      }

      /*
        if we have just selected a dataId in the Bins grid,
        we need to validate that its selected as primary if there are no others
      */
      if (
        propertyName &&
        propertyName.match(/bins/gi) &&
        type === ON_PRIMARY_GRID_DATA_VALIDATED
      ) {
        yield put(actions.validateBins(form, { propertyName }))
      }
    }
  }
}

export function* setPrimaryVendorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SET_PRIMARY_VENDOR)

    if (form === formListener) {
      yield fork(saveVendorsDataProcess, form)
    }
  }
}

export function* saveVendorsDataProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  let vendors = getIn(formState, 'fields.vendors.rowData')
  vendors = vendors && vendors.toJS ? vendors.toJS() : []

  /* saveVendorsData API needs to have this cloudNew flag set or not */
  vendors = vendors.reduce(
    (acc, next) => {
      if (next.cloudNew) {
        acc.data = acc.data.concat(next)
      } else {
        acc.data = acc.data.concat({
          ...next,
          cloudNew: false
        })
      }

      if (next.isPrimary) {
        acc.isPrimary = acc.isPrimary.concat(next.dataId)
      }

      return acc
    },
    { data: [], isPrimary: [] }
  )

  if (!vendors.isPrimary.length && vendors.data.length === 1) {
    vendors.data[0].isPrimary = true
  }

  // debugger
  yield put(actions.saveVendorsData.request(form))

  const { response, error } = yield call(api.saveVendorsData, {
    dataId,
    newWarehouseId: warehouseId,
    vendors: vendors.data,
    warehouseId
  })

  if (response) {
    yield put(actions.saveVendorsData.success(response, { form }))
  } else {
    yield put(actions.saveVendorsData.failure(error, { form }))

    // show validation errors
    yield fork(displayValidationErrors, error)
  }
}

export function* readDataHelperListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload,
      type
    } = yield take([
      MASTER_CONSTANTS.GET_ENTITY.SUCCESS,
      MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
      DDICONSTANTS.SAVE.SUCCESS,
      CONSTANTS.CREATE_NEW_PRODUCT.SUCCESS,
      UNLOCK_TEMPLATE.SUCCESS
    ])

    if (form === formListener) {
      const allowRevert =
        type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS ||
        type === DDICONSTANTS.SAVE.SUCCESS ||
        type === UNLOCK_TEMPLATE.SUCCESS ||
        false

      const formState = yield select(getFormSelector(form))
      // debugger

      /* NOTE: these two helpers NEED to be here */
      /*
        mapResponse gets too convoluted for this
        was much faster and more reliable to adjust
        existing routines 7/24/19 SVE
      */
      if (payload.bins && payload.bins !== null) {
        // debugger
        const binData = payload.bins
        yield put(
          actions.handleBinData(form, {
            binData,
            allowRevert
          })
        )
      }

      // debugger
      if (payload.analysis && payload.analysis.productBins) {
        // debugger
        yield put(
          actions.handleAnalysisBinData(form, {
            binData: payload.analysis.productBins,
            allowRevert
          })
        )
      }

      if (payload.productBins) {
        // debugger
        yield put(
          actions.handleAnalysisBinData(form, {
            binData: payload.productBins,
            allowRevert
          })
        )
      }

      if (
        payload.analysis ||
        payload.inventory ||
        payload.pricing ||
        payload.attachments ||
        payload.audit
      ) {
        // debugger
        yield put(actions.removeTrackedTabs(form, { tabs: ['setup', 'main'] }))
      }

      if (
        type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS ||
        type === UNLOCK_TEMPLATE.SUCCESS
      ) {
        const preNewMode = getIn(formState, 'preNewMode')
          ? getIn(formState, 'preNewMode')
          : false

        const newMode = getIn(formState, 'newMode')
          ? getIn(formState, 'newMode')
          : false

        if (newMode || preNewMode) {
          yield put(resetMasterFields(form))
          yield put(
            actions.setNewMode(form, {
              newMode: false,
              preNewMode: false,
              clearDataId: true
            })
          )
        }
      }
    }
  }
}

export function* fieldUpdate(form, propertyName, value, apiMethod) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const templateKey = getIn(formState, 'values.templateKey')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')

  yield put(actions.fieldUpdate.request(form))

  const apiParams = templateKey
    ? {
        dataId,
        templateKey,
        warehouseId,
        [propertyName]: value
      }
    : {
        dataId,
        warehouseId,
        [propertyName]: value
      }

  const { response, error } = yield call(api[apiMethod], apiParams)

  if (response) {
    yield put(
      actions.fieldUpdate.success(
        {
          ...response,
          propertyName
        },
        form
      )
    )

    if (
      propertyName === 'mfgNumber' &&
      response.duplicates &&
      response.duplicates.length &&
      response.duplicates[0]
    ) {
      const message = response.duplicates.reduce((acc, next) => {
        acc = acc.concat(`Product: ${next}\n`)
        return acc
      }, `Products which have manufacturer ${value} assigned\n\n`)
      yield call(
        warningModal,
        message,
        'Manufacturer Number Product Duplicates'
      )
    }
  } else {
    const { status, message, title } = error
    if (status === 496) {
      yield call(warningModal, message, title)
    }
    yield put(actions.fieldUpdate.failure(error, form))
  }
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, value },
      meta: { form },
      type
    } = yield take([DDICONSTANTS.BLUR, DDICONSTANTS.SET_FIELD])

    if (form === formListener) {
      if (propertyName === 'productLineId') {
        yield fork(fieldUpdate, form, propertyName, value, 'productLineUpdate')
      }

      if (propertyName === 'priceGroupId') {
        yield fork(fieldUpdate, form, propertyName, value, 'priceGroupUpdate')
      }

      if (propertyName === 'statusType') {
        yield fork(fieldUpdate, form, propertyName, value, 'statusTypeUpdate')
      }

      if (propertyName === 'mfgNumber') {
        yield fork(fieldUpdate, form, propertyName, value, 'mfgNumberUpdate')
      }

      if (propertyName === 'isKitProduction') {
        yield fork(validateIsKitProductionChangeProcess, form)

        if (value) {
          yield put(setField(form, 'assemblyPrintOnSalesOrder', false))
          yield put(setField(form, 'assemblyRollupType', 'N'))
        } else {
          yield put(setField(form, 'disassembleOnReceipt', false))
        }
      }

      if (propertyName === 'cycleCount') {
        yield fork(fieldUpdate, form, propertyName, value, 'cycleCountUpdate')
      }

      if (
        propertyName === 'isSerialNumberRequired' ||
        propertyName === 'useLots'
      ) {
        if (value) {
          const changeField =
            propertyName === 'isSerialNumberRequired'
              ? 'useLots'
              : 'isSerialNumberRequired'
          yield put(setField(form, changeField, false))
          // debugger

          if (changeField === 'isSerialNumberRequired') {
            yield put(setField(form, 'autoGenerateSerialNumbers', false))
          }
        } else if (propertyName === 'isSerialNumberRequired') {
          yield put(setField(form, 'autoGenerateSerialNumbers', false))
        }
      }

      /* Assemblies tab */
      if (propertyName === 'autoGenerateSerialNumbers' && value === true) {
        yield put(setField(form, 'isSerialNumberRequired', true))
        yield put(setField(form, 'useLots', false))
      }

      /* Web Options tab */
      if (propertyName === 'disableUPSShipping' && value === true) {
        yield put(setField(form, 'upsFlatRate', null))
      }

      /* new propertyChange API integrations */
      const propertyChangeFields = [
        'buyLineId',
        'weight',
        'volume',
        'boxQuantity',
        'msdSheetIDCode',
        'imageFileName',
        'imageURL',
        'documentSpecsFilePath',
        'documentSpecsURL',
        'assemblyPrintOnSalesOrder',
        'assemblyRollupType',
        'msdSheetFilePath',
        'msdSheetURL'
      ]

      // const dynamicPropertyChangeFields = [
      //   'substitute',
      // ]

      if (
        (propertyName && propertyChangeFields.includes(propertyName)) ||
        (propertyName && propertyName.match(/substitute/))
      ) {
        // debugger
        yield fork(onPropertyChangeProcess, form, propertyName, value)
      }
    }
  }
}

export function* onPropertyChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const groupNames = getSelectedTabsFromState(formState)
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const templateKey =
    getIn(formState, 'values.templateKey') || getIn(formState, 'templateKey')

  // debugger
  if (!propertyName) {
    return
  }

  function getEffectedTabs(prop) {
    let tabs = []
    const overviewFields = [
      'msdSheetFilePath',
      'msdSheetURL',
      'warehouseProcurements',
      'weight',
      'volume',
      'buyLineId',
      'boxQuantity',
      'imageFileName',
      'imageURL',
      'documentSpecsFilePath',
      'documentSpecsURL'
    ]
    const assembliesFields = [
      'assemblyRollupType',
      'components',
      'assemblyPrintOnSalesOrder',
      'assemblyRollupType'
    ]

    if (prop.match(/substitute/)) {
      tabs = ['productSubstitutes']
    } else if (prop.match(/bins/)) {
      tabs = ['productBins']
    } else if (overviewFields.includes(prop)) {
      tabs = ['analysis', 'overview']
    } else if (assembliesFields.includes(prop)) {
      tabs = ['assembly']
    }

    return tabs
  }

  function trimIncompleteGridRows(grid) {
    const ret = grid.reduce((acc, next) => {
      if (next.dataId) {
        acc = acc.concat(next)
      }
      return acc
    }, [])

    return ret
  }

  let params
  if (propertyName.match(/bins/)) {
    const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    params = {
      dataId,
      warehouseId,
      properties: {
        bins: [
          {
            dataId: warehouseId,
            bins: rowData && rowData.toJS ? rowData.toJS() : []
          }
        ]
      }
    }
  } else if (propertyName === 'components') {
    const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    params = {
      dataId,
      properties: {
        components:
          rowData && rowData.toJS ? trimIncompleteGridRows(rowData.toJS()) : []
      }
    }
  } else if (propertyName === 'warehouseProcurements') {
    const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    params = {
      dataId,
      properties: {
        warehouseProcurements:
          rowData && rowData.toJS
            ? rowData.toJS().reduce((acc, next) => {
                /* if the user has de-selected the forecastId, we need to uncheck forecastFreeze */
                acc = acc.concat({
                  ...next,
                  forecastFreeze: !next?.forecastId
                    ? false
                    : next.forecastFreeze
                })
                return acc
              }, [])
            : []
      }
    }
  } else if (propertyName === 'substitutes') {
    const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    params = {
      dataId,
      properties: {
        substitutes: rowData && rowData.toJS ? rowData.toJS() : []
      }
    }
  } else {
    params = {
      dataId,
      properties: {
        [propertyName]: value
      }
    }
  }

  params = {
    ...params,
    groupNames
  }

  // debugger
  if (templateKey) {
    params = {
      ...params,
      templateKey
    }
  }

  // yield put(getEntityAsync.request(form))
  yield put(
    actions.removeTrackedTabs(form, { tabs: getEffectedTabs(propertyName) })
  )
  // debugger

  yield put(actions.onPropertyChange.request(form))
  const { response, error } = yield call(api.onPropertyChange, params)

  if (response) {
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })
    const res = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    // console.log('propertyChangeResponse', response)
    yield put({
      meta: { form },
      payload: { ...res },
      type: MASTER_CONSTANTS.GET_ENTITY.SUCCESS
    })
    yield put(actions.onPropertyChange.success(response, form))
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* editableGridChangeListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, field, rowIndex, value },
      meta: { form },
      type
    } = yield take([
      DELETE_GRID_ROW,
      UPDATE_GRID_CELL_DATA,
      ON_PRIMARY_GRID_DATA_VALIDATED,
      CONSTANTS.VALIDATE_BINS,
      CONSTANTS.SET_PRIMARY_BIN_CHECKBOX,
      CONSTANTS.SET_PRIMARY_BIN
    ])

    const formState = yield select(getFormSelector(form))
    const selectedPrimaryTab = getIn(
      formState,
      'masterOptions.selectedPrimaryTab'
    )

    if (form === formListener) {
      if (
        selectedPrimaryTab === 'setup' &&
        propertyName &&
        propertyName.match(/bins/) &&
        (type === CONSTANTS.VALIDATE_BINS ||
          type === CONSTANTS.SET_PRIMARY_BIN_CHECKBOX)
      ) {
        yield fork(onPropertyChangeProcess, form, propertyName, null)
      }

      /* user has edited a bin using the "Set Primary Bin" feature */
      if (type === CONSTANTS.SET_PRIMARY_BIN) {
        const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
        yield fork(onPropertyChangeProcess, form, `bins.${warehouseId}`, null)
      }

      if (propertyName && propertyName === 'components') {
        yield fork(onPropertyChangeProcess, form, propertyName, null)
      }

      if (propertyName && propertyName === 'warehouseProcurements') {
        /* forecast freeze changes */
        if (field === 'forecastId' && value && type === UPDATE_GRID_CELL_DATA) {
          yield put(actions.enableForecastFreeze(form, { rowIndex }))
          yield delay(1000)
        }

        yield fork(onPropertyChangeProcess, form, propertyName, null)
      }

      if (
        (type === ON_PRIMARY_GRID_DATA_VALIDATED || type === DELETE_GRID_ROW) &&
        propertyName === 'substitutes'
      ) {
        yield fork(onPropertyChangeProcess, form, propertyName, null)
      }
    }
  }
}

export function* apiTriggerFieldListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, value, name, description },
      meta: { form },
      type,
      payload
    } = yield take([
      DDICONSTANTS.SET_FIELD,
      INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS,
      INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
    ])
    /* important that we listen for find_next & find_prev here -- SVE 2/21/2022 */

    if (form === formListener) {
      const formState = yield select(getFormSelector(form))
      const selectedPrimaryTab = getIn(
        formState,
        'masterOptions.selectedPrimaryTab'
      )
      const selectedSecondaryTab = getIn(
        formState,
        'masterOptions.selectedSecondaryTab'
      )

      const analysisTabNameToCall = mapAnalysisTabNames(selectedSecondaryTab)

      if (
        propertyName === 'dataId' &&
        (type === INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS ||
          type === INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS)
      ) {
        /* 
          prev/next buttons are a little different b/c we need to ensure that we reset state before
          the setField action is called by the component, triggering the searchProcess in ddiForm.
          Not doing this here will cause many errors
        */
        yield put(resetMasterFields(form))
      }

      if (
        (propertyName === 'selectedWarehouseId' ||
          propertyName === 'selectedUOMId') &&
        type === DDICONSTANTS.SET_FIELD
      ) {
        /* if we are on setup, get the data for whatever screen we are on */
        if (selectedPrimaryTab === 'setup') {
          yield fork(getTabData, form, [
            selectedPrimaryTab,
            selectedSecondaryTab
          ])
        } else if (
          selectedPrimaryTab === 'inventory' ||
          selectedPrimaryTab === 'pricing'
        ) {
          yield fork(getTabData, form, [selectedPrimaryTab])
        } else if (selectedPrimaryTab === 'analysis') {
          /*
            update these analysis tabs immediately if user
            changes either warehouse OR uom
          */
          if (
            selectedSecondaryTab === 'demand' ||
            selectedSecondaryTab === 'bins' ||
            selectedSecondaryTab === 'overview'
          ) {
            yield fork(getTabData, form, [analysisTabNameToCall])
          }

          /*
            only update these analysis screens immediately
            if the user is on that screen and has changed the
            warehouseId
          */
          if (
            propertyName === 'selectedWarehouseId' &&
            (selectedSecondaryTab === 'assembly' ||
              selectedSecondaryTab === 'transactions' ||
              selectedSecondaryTab === 'workOrders' ||
              selectedSecondaryTab === 'serialNumbers' ||
              selectedSecondaryTab === 'lots' ||
              selectedSecondaryTab === 'substitutes')
          ) {
            yield fork(getTabData, form, [analysisTabNameToCall])
          }
        } else {
          yield fork(getTabData, form, ['setup', 'main'])
        }
      }

      /* lesser update for purchaseHistoryLink change */
      if (propertyName === 'purchaseHistoryLinkId') {
        const id = value || name || payload?.exactMatchResults?.name

        yield fork(
          fieldUpdate,
          form,
          'purchaseHistoryLinkId',
          id,
          'purchaseHistoryLinkUpdate'
        )
      }

      if (
        propertyName === 'auditBinShowAll' &&
        type === DDICONSTANTS.SET_FIELD
      ) {
        yield call(getRecord, {
          form,
          newRecord: false,
          tabIds: ['productBins'],
          dataId: getIn(formState, 'fields.dataId.value'),
          groupNames: ['productBins']
        })
      }
    }

    /*
      need to hit the propertyChange API from now on
      whenever one of the Substitute fields is paged through
    */
    if (
      form === formListener &&
      propertyName &&
      propertyName.match(/substitute/) &&
      name &&
      type === DDICONSTANTS.SET_FIELD
    ) {
      yield fork(onPropertyChangeProcess, form, propertyName, name)

      /* update the substitute field description */
      if (description) {
        yield put(
          actions.saveIndexSearchDescription(form, {
            label: propertyName.replace('Id', 'Description'),
            value: description
          })
        )
      }
    }
  }
}

export function* deleteGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(DELETE_GRID_ROW)

    /*
      this double-checks to see if a user has deleted the UOM row
      where values were already set
    */
    if (formListener === form && propertyName === 'unitOfMeasures') {
      yield put(actions.validateUOMs(form))
    }

    if (formListener === form && propertyName === 'vendors') {
      yield fork(saveVendorsDataProcess, form)
    }

    if (formListener === form && propertyName && propertyName.match(/bins/gi)) {
      yield put(actions.validateBins(form, { propertyName }))
    }
  }
}

export function* validateIsKitProductionChangeProcess(form) {
  const formState = yield select(getFormSelector(form))

  const dataId = getIn(formState, 'fields.dataId.value')
  const isKitProduction = getIn(formState, 'fields.isKitProduction.value')

  const components = getIn(formState, 'fields.components.rowData')
    ? getIn(formState, 'fields.components.rowData').toJS()
    : []

  const componentIds =
    components.length > 0
      ? components.reduce((acc, next) => {
          if (next?.dataId) {
            acc = acc.concat(next.dataId)
          }
          return acc
        }, [])
      : []

  if (!dataId) return

  // if (!componentIds?.length) {
  //   yield call(
  //     warningModal,
  //     'Please chanage',
  //     'No assemblies loaded'
  //   )
  // }

  yield put(actions.validateIsKitProduction.request(form))

  const { response, error } = yield call(api.validateIsKitProductionChange, {
    componentIds,
    dataId,
    isKitProduction
  })

  if (response) {
    yield put(actions.validateIsKitProduction.success(response, form))
  } else {
    yield put(actions.validateIsKitProduction.failure(error, form))

    /* revert to the previous isKitProduction value on API failure */
    yield put(
      setField(
        form,
        'isKitProduction',
        !getIn(formState, 'fields.isKitProduction.value')
      )
    )
  }
}

export function* getAdditionalMergeProductApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''

  return {
    warehouseId,
    uomId
  }
}

export function* launchBinSearchModalProcess(form) {
  const modalOpts = {
    component: BinSearchModal,
    options: {
      title: 'Set Primary Bin',
      data: {
        actions: [
          {
            primary: true,
            title: 'OK',
            disabled: f => {
              const { fields } = f
              const primaryBin = getIn(fields, 'primaryBin.value')
              if (primaryBin) {
                return false
              }

              return true
            },
            async clickEvent(args, formState, cb) {
              try {
                if (this.props.setPrimaryBin) {
                  await this.props.setPrimaryBin(form)
                }
              } finally {
                cb()
              }
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, formState, cb) {
              try {
                if (this.props.cancelSetPrimaryBin) {
                  await this.props.cancelSetPrimaryBin(form)
                }
              } finally {
                cb()
              }
            }
          }
        ],
        form
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* launchBinSearchModalListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_BIN_SEARCH_MODAL)

    if (form === formListener) {
      yield fork(launchBinSearchModalProcess, form)
    }
  }
}

export function* showCannotChangePrimaryBinModalListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SHOW_CANNOT_CHANGE_PRIMARY_BIN_MODAL)

    if (form === formListener) {
      yield call(
        warningModal,
        'Cannot change primary bin because it has quantity on hand',
        'Cannot Change Primary Bin'
      )
    }
  }
}

export function* getInventoryDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { tab, cb }
    } = yield take(CONSTANTS.GET_INVENTORY_DATA.TRY)

    if (form === formListener) {
      if (tab === 'Johnstone') {
        yield fork(
          getTabData,
          form,
          ['inventory', 'johnstoneNational'],
          tab,
          cb
        )
      } else {
        yield fork(getTabData, form, ['inventory'], tab, cb)
      }
    }
  }
}

export default function* mainSagas(form) {
  // yield fork(entitySuccessListener, form)
  yield fork(editableGridSagas, form, ['substitutes', 'secondaryPriceGroups'])
  yield fork(selectionCriteriaModalSagas, form)
  yield fork(mainScreenGridChangeListener, form)
  yield fork(calculateRollupPriceListener, form)
  yield fork(updatePricesListener, form)
  yield fork(gridCellChangeListener, form)
  yield fork(readDataHelperListener, form)
  yield fork(setFieldListener, form)
  yield fork(apiTriggerFieldListener, form)
  yield fork(deleteGridRowListener, form)

  yield fork(mergeModalSagas, form, getAdditionalMergeProductApiParams)
  yield fork(
    openMergeModalListener,
    form,
    'product',
    'Change Product Number',
    MergeProductModal
  )
  yield fork(launchBinSearchModalListener, form)
  yield fork(setPrimaryVendorListener, form)
  yield fork(showCannotChangePrimaryBinModalListener, form)
  yield fork(editableGridChangeListener, form)
  yield fork(clearGridRowListener, form)
  yield fork(getInventoryDataListener, form)
  // yield fork(updateBinsListener, form)
}
