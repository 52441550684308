import { createAsyncRequestTypes } from 'utils'
// import { BASE_INFORM_API_URL } from 'services/constants'

const CONTACT = '@@CONTACT@@/'

export const contact = 'contact'

export const GET_CONTACT = createAsyncRequestTypes(`${CONTACT}GET_CONTACT`)

export const ADD_CONTACT = createAsyncRequestTypes(`${CONTACT}ADD_CONTACT`)

/* delete a contact */
export const DELETE_CONTACT = createAsyncRequestTypes(
  `${CONTACT}/DELETE_CONTACT`
)

/* list activities */
export const LIST_ACTIVITIES = createAsyncRequestTypes(
  `${CONTACT}LIST_ACTIVITIES`
)
/* end activity features */

// Attachments

export const LIST_ATTACHMENTS = createAsyncRequestTypes(
  `${CONTACT}LIST_ATTACHMENTS`
)

export const ADD_NEW_DOCUMENT = createAsyncRequestTypes(
  `${CONTACT}/ADD_NEW_DOCUMENT`
)

/* list audits */
export const LIST_AUDITS = createAsyncRequestTypes(`${CONTACT}LIST_AUDITS`)

// get analysis
export const GET_ANALYSIS = createAsyncRequestTypes(`${CONTACT}GET_ANALYSIS`)

// get payment profiles
export const GET_PAYMENT_PROFILES = createAsyncRequestTypes(
  `${CONTACT}/GET_PAYMENT_PROFILES`
)

export const GET_PAYMENT_PROFILES_URI = createAsyncRequestTypes(
  `${CONTACT}/GET_PAYMENT_PROFILES_URI`
)

// set selected payment profile
export const SET_SELECTED_PAYMENT = `${CONTACT}/SET_SELECTED_PAYMENT`
export const CLEAR_SELECTED_PAYMENT = `${CONTACT}/CLEAR_SELECTED_PAYMENT`

// add credit card
export const ADD_PAYMENT_SOURCE = createAsyncRequestTypes(
  `${CONTACT}/ADD_PAYMENT_SOURCE`
)

// remove payment source
export const REMOVE_PAYMENT_SOURCE = createAsyncRequestTypes(
  `${CONTACT}/REMOVE_PAYMENT_SOURCE`
)

export const HANDLE_COPY_PARENT = createAsyncRequestTypes(
  `${CONTACT}/HANDLE_COPY_PARENT`
)

export const SET_SHIPTO_ID = `${CONTACT}/SET_SHIPTO_ID`

export const SET_PASSWORD_FIELD = createAsyncRequestTypes(
  `${CONTACT}/SET_PASSWORD_FIELD`
)

export const SET_PRIMARY_TAB = `${CONTACT}/SET_PRIMARY_TAB`

export const SAVE_CONTACT = createAsyncRequestTypes(`${CONTACT}/SAVE_CONTACT`)

export const UNLOCK_CONTACT_RECORD = createAsyncRequestTypes(
  `${CONTACT}/UNLOCK_CONTACT_RECORD`
)

export const SET_DEFAULT_CHART_VIEW = createAsyncRequestTypes(
  `${CONTACT}/SET_DEFAULT_CHART_VIEW`
)

export const OPEN_SCREEN = createAsyncRequestTypes(`${CONTACT}OPEN_SCREEN`)

export const UPDATE_PHOTO = `${CONTACT}/UPDATE_PHOTO`

export const LOCK_CONTACT_FOR_EDIT = createAsyncRequestTypes(
  `${CONTACT}LOCK_CONTACT_FOR_EDIT`
)

export const CANCEL_CONTACT_EDIT = createAsyncRequestTypes(
  `${CONTACT}CANCE_CONTACT_EDIT`
)

export const SET_PARENT = `${CONTACT}SET_PARENT`

export const REFRESH_AUDITS = `${CONTACT}REFRESH_AUDITS`