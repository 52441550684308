import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

/* get the audits: moved to common component */
// export const listAudits = createAsyncFormAction(
//   CONSTANTS.LIST_AUDITS,
//   CONSTANTS.customerShipTo
// )

export const setParentId = ({ parentId }) =>
  createAction(CONSTANTS.SET_PARENT_ID, { parentId }, CONSTANTS.customerShipTo)

export const cancelEdit = ({ modalId }) =>
  createAction(CONSTANTS.CANCEL_EDIT.TRY, { modalId }, CONSTANTS.customerShipTo)

export const createShipTo = ({ id }) =>
  createAction(CONSTANTS.CREATE_SHIP_TO.TRY, { id }, CONSTANTS.customerShipTo)

/* this can be removed */
// export const generateShipToId = () =>
//   createAction(CONSTANTS.GENERATE_SHIP_TO_ID.TRY, {}, CONSTANTS.customerShipTo)

export const saveShipTo = () =>
  createAction(CONSTANTS.SAVE_SHIP_TO.TRY, {}, CONSTANTS.customerShipTo)

export const openGoogleMaps = ({ type }) =>
  createAction(CONSTANTS.OPEN_GOOGLE_MAPS, { type }, CONSTANTS.customerShipTo)

export const updateUOMData = ({ rowIndex, propertyName, field, value }) =>
  createAction(
    CONSTANTS.UPDATE_UOM_DATA,
    { rowIndex, propertyName, field, value },
    CONSTANTS.customerShipTo
  )

export const getProductUOM = createAsyncFormAction(
  CONSTANTS.GET_PRODUCT_UOM,
  CONSTANTS.customerShipTo
)

export const deleteShipTo = createAsyncFormAction(
  CONSTANTS.DELETE_SHIP_TO,
  CONSTANTS.customerShipTo
)

export const saveTempId = ({ value }) =>
  createAction(CONSTANTS.SAVE_TEMP_ID, { value }, CONSTANTS.customerShipTo)

export const saveModalId = ({ modalId }) =>
  createAction(CONSTANTS.SAVE_MODAL_ID, { modalId }, CONSTANTS.customerShipTo)

export const saveIndexSearchDescription = ({ label, value }) =>
  createAction(
    CONSTANTS.SAVE_INDEX_SEARCH_DESCRIPTION,
    { label, value },
    CONSTANTS.customerShipTo
  )
