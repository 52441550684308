import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { Form } from 'mobile/pages/FastCustomer/components/Form'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)

  const autoGenerateId =
    getIn(formState, 'fields.fastCustomer.autoGenerateId.value') || false
  const isLocked = getIn(formState, 'values.fastCustomer.isLocked') || false
  const description =
    getIn(formState, 'fields.fastCustomer.description.value') || ''
  const addressLine1 =
    getIn(formState, 'fields.fastCustomer.addressLine1.value') || ''

  const salesmanDescription =
    getIn(formState, 'fields.fastCustomer.salesmanId.description') ||
    getIn(formState, 'fields.fastCustomer.salesmanDescription.value') ||
    ''
  const arTermsDescription =
    getIn(formState, 'fields.fastCustomer.arTermsId.description') ||
    getIn(formState, 'fields.fastCustomer.arTermsDescription.value') ||
    ''
  const categoryDescription =
    getIn(formState, 'fields.fastCustomer.categoryId.description') ||
    getIn(formState, 'fields.fastCustomer.categoryDescription.value') ||
    ''
  const taxDescription =
    getIn(formState, 'fields.fastCustomer.taxId.description') ||
    getIn(formState, 'fields.fastCustomer.taxDescription.value') ||
    ''
  const branchDescription =
    getIn(formState, 'fields.fastCustomer.branchId.description') ||
    getIn(formState, 'fields.fastCustomer.branchDescription.value') ||
    ''
  const isEditing = getIn(formState, 'isEditing') || false
  const dataId = getIn(formState, 'fields.fastCustomer.dataId.value') || ''
  const idMaxLength = getIn(formState, 'values.fastCustomer.idMaxLength') || 7

  const country = getIn(formState, 'fields.fastCustomer.country.value') || ''

  return {
    addressLine1,
    autoGenerateId,
    isLocked,
    description,
    salesmanDescription,
    arTermsDescription,
    categoryDescription,
    taxDescription,
    branchDescription,
    isEditing,
    dataId,
    idMaxLength,
    country
  }
}

export class FastCustomerModal extends Component {
  componentDidMount() {
    console.log('mounted')
  }

  render() {
    const {
      autoGenerateId,
      // data: { custCategory = {} },
      isEditing,
      isLocked,
      salesmanDescription,
      arTermsDescription,
      categoryDescription,
      taxDescription,
      branchDescription,
      dataId,
      idMaxLength,
      country
    } = this.props

    return (
      <Form
        autoGenerateId={autoGenerateId}
        dataId={dataId}
        isLocked={isLocked}
        isEditing={isEditing}
        categoryDescription={categoryDescription}
        salesmanDescription={salesmanDescription}
        arTermsDescription={arTermsDescription}
        branchDescription={branchDescription}
        taxDescription={taxDescription}
        idMaxLength={idMaxLength}
        country={country}
        isSalesOrderModule
      />
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(FastCustomerModal)
