import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { launchNotesModal as launchNotesModalAction } from 'modals/NotesModal/actions'
import { tryChangeFormTab as tryChangeFormTabAction } from 'ddiForm/actions'
import { connect } from 'react-redux'
import { getIn, plainDeepEqual } from 'utils'

const mapStateToProps = (state, ownProps) => {
  const formState = getIn(state, 'ddiForm.customerMaster')

  return {
    internalNotes: getIn(formState, 'fields.internalNotes.value') || ''
  }
}

class ReferenceGridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    internalNotes: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
    // value: PropTypes.string.isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  openContactMaster = () => {
    const {
      value: { name, recordName, parent }
    } = this.props
    this.props.dispatch(
      openScreenAction({
        dataId: name,
        parentId: parent.recordName,
        parentType: 'C',
        recordName,
        recordType: 'Contacts',
        name: 'contactMaster',
        image: 'contact_master_16.png',
        title: 'Contact Master'
      })
    )
  }

  launchInternalNotesModal = () =>
    this.props.dispatch(launchNotesModalAction('customerMaster'))

  createLinkText = value => {
    // console.log('REFERENCE_GRID_CELL', this, this.props)
    if (!value) {
      return null
    }
    if (!value.parent) {
      return (
        <span>
          <a onClick={this.dispatchOpenScreen}>
            {value.displayName}: {value.description}
          </a>
        </span>
      )
    }
    return (
      <span>
        <a onClick={this.dispatchOpenScreen}>
          {value.displayName}: {value.description}
        </a>{' '}
        from{' '}
        <a onClick={this.dispatchParentLinkAction}>
          {value.parent.description}
        </a>
      </span>
    )
  }

  dispatchParentLinkAction = () => {
    const {
      value: {
        parent: { displayName }
      }
    } = this.props

    const actions = {
      Customer: this.openCustomerLedger
    }

    if (displayName && actions[displayName]) {
      return actions[displayName]()
    }

    return false
  }

  dispatchOpenScreen = () => {
    const {
      value: { displayName, name }
    } = this.props

    const actions = {
      Contact: this.openContactMaster,
      Customer: this.openCustomerLedger
    }

    if (displayName && actions[displayName]) {
      return actions[displayName]()
    }

    return false
  }

  openCustomerLedger = () => {
    const form = this?.props?.colDef?.cellRendererParams?.form
    if (form === 'customerMaster') {
      const { internalNotes } = this.props
      if (internalNotes) {
        this.launchInternalNotesModal()
      }

      debugger
      this.props.dispatch(tryChangeFormTabAction(form, 'ledger'))
    } else {
      const dataId = this?.props?.value?.parent?.recordName
      if (dataId) {
        this.props.dispatch(
          openScreenAction({
            image: 'accounting_accounts_receivable_customer_ledger_16.png',
            name: 'customerLedger',
            title: 'Customer Ledger',
            activeKey: 'ledger',
            route: 'customermaster',
            groupNames: ['summary', 'invoices'],
            dataId
          })
        )
      }
    }
  }

  render() {
    const {
      data: { color },
      value,
      valueFormatted
    } = this.props

    return (
      <div
        style={{
          display: 'block',
          width: '100%',
          height: '100%'
        }}
      >
        {this.createLinkText(value)}
      </div>
    )
  }
}
// export default ReferenceGridCell
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ReferenceGridCell)
