import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import Grid from 'grid'
import moment from 'moment'
import { emptyList, getIn, getValue } from 'utils'

import { LayoutFlex, FlexDiv } from 'pages/ContactMaster/styles/masterStyle'
import { setSelectedPayment } from 'pages/ContactMaster/actions'
import AddCardGridCell from 'pages/ContactMaster/components/AddCardGridCell'
import DeleteGridCell from 'pages/ContactMaster/components/DeleteGridCell'
import { headerStyle } from 'pages/ContactMaster/utils'
import withDimensions from 'hoc/withDimensions'

// export const getRowNodeId = data => `${data.accountType}-${data.branchId}`
export const getRowNodeId = data => data.rowId

export const getSelectedPaymentRowNodeId = data => data.dataId

export const columnDefs = [
  { field: 'branchId', headerName: 'Branch' },
  { field: 'account', headerName: 'Account' }
]

export const columnDefsEditingState = ({ dispatch, form }) => [
  ...columnDefs,
  {
    cellRendererFramework: AddCardGridCell,
    cellRendererParams: { form, dispatch }
  }
]

export const selectedPaymentColumnDefs = [
  { field: 'cardType', headerName: 'Type' },
  { field: 'cardNumber', headerName: 'Number' },
  {
    cellClass: 'text-center align-center',
    field: 'expirationDate',
    headerName: 'Exp Date',
    width: 100,
    valueFormatter: params =>
      params.value ? moment(new Date(params.value)).format('M/YY') : null
  },
  { field: 'name', headerName: 'Name' },
  { field: 'address', headerName: 'Address' },
  { field: 'zipCode', headerName: 'Zip Code' }
]

export const selectedPaymentColumnDefsEdit = props => [
  ...selectedPaymentColumnDefs,
  {
    cellRendererFramework: DeleteGridCell,
    cellRendererParams: {
      form: props.form
    },
    type: 'payment'
  }
]

export const Vault = ({
  canSaveCreditCardInfo,
  dispatch,
  form,
  height,
  isEditing,
  recordName,
  selectedPaymentAccount,
  selectedPaymentInfos
}) => {

  const [gridApi, setGridApi] = useState(null)
  const [accountTitle, setAccountTitle] = useState('')

  useEffect(() => {
    if (gridApi) {
      gridApi.deselectAll()
    }
  }, [gridApi, recordName])


  useEffect(() => {
    const title = selectedPaymentAccount
      ? `Payment Info For Account "${selectedPaymentAccount}"`
      : 'Payment Info'

    setAccountTitle(title)
  }, [selectedPaymentAccount])

  function onGridReady({ api }) {
    setGridApi(api)
  }

  function onRowSelected(params) {
    const { data, selected } = params.node
    if (data && selected) {
      dispatch(setSelectedPayment(data, params))
    }
  }

  return (
    <LayoutFlex>
      <FlexDiv>
        <GridField
          columnDefs={
            isEditing && canSaveCreditCardInfo
              ? columnDefsEditingState({ dispatch, form })
              : columnDefs
          }
          enableSorting
          propertyName="paymentProfiles"
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          height={height}
          onRowSelected={onRowSelected}
          onGridReady={onGridReady}
          rowSelection="single"
          title="Accounts"
        />
      </FlexDiv>
      <FlexDiv>
        <Grid
          columnDefs={
            isEditing
              ? selectedPaymentColumnDefsEdit({ form, dispatch })
              : selectedPaymentColumnDefs
          }
          enableSorting
          rowData={selectedPaymentInfos.toJS()}
          getRowNodeId={getSelectedPaymentRowNodeId}
          headerStyle={headerStyle}
          height={height}
          rowSelection="single"
          title={accountTitle}
          overlayNoRowsTemplate={
            '<span style="padding: 10px; background: lightgoldenrodyellow;">Choose an account on the left to see payment info</span>'            }
        />
      </FlexDiv>
    </LayoutFlex>
  )
}

Vault.propTypes = {
  canSaveCreditCardInfo: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
  recordName: PropTypes.string.isRequired,
  selectedPaymentAccount: PropTypes.string.isRequired,
  selectedPaymentInfos: PropTypes.array.isRequired

}

export const contextObj = {
  canSaveCreditCardInfo: form => getValue(form, 'canSaveCreditCardInfo', false),
  isEditing: form => getIn(form, 'isEditing') || false,
  // paymentProfiles: form => getValue(form, 'paymentProfiles', emptyList),
  recordName: form => getValue(form, 'recordName', null),
  selectedPaymentAccount: form => getValue(form, 'selectedPaymentAccount', ''),
  selectedPaymentInfos: form => getValue(form, 'selectedPaymentInfos', emptyList)
}

const ConnectedVault = withContext(contextObj)(Vault)

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
})(ConnectedVault)