/* eslint quote-props: 0, jsx-a11y/label-has-for: 0, react/no-multi-comp: 0, react/sort-comp: 0, sort-keys: 0 */
import React from 'react'
import { Icon } from '@material-ui/core'
import { ddiForm } from 'ddiForm'
import TruckRoutes from 'components/MasterScreen/TruckRoutes'
import Audit from 'components/MasterScreen/Audit'
import { mapAuditResponse } from 'components/MasterScreen/Audit/utils'

import CustomFields from 'components/MasterScreen/CustomFields'
import {
  mapCustomFieldsResponse,
  transformCustomFieldsData,
  checkIfCustomFieldsHidden
} from 'components/MasterScreen/CustomFields/utils'

import RightFooterButtons from './components/RightFooterButtons'
import SearchArea from './components/SearchArea'

/* components */
import Information from './tabs/Setup/Information'
import Credit from './tabs/Setup/Credit'
import VendorIds from './tabs/Setup/VendorIds'
import MonthlyBudget from './tabs/Setup/MonthlyBudget'
import StockLevels from './tabs/Setup/StockLevels'

/* common components */

import * as actions from './actions'
import sagas, { saveArgumentsSaga } from './sagas'
import behaviors from './behaviors'
import { getCustomerShipTo } from './api'
import { mapDataResponse } from './utils'

const tabIconStyle = {
  fontSize: 18,
  marginBottom: 0,
  marginRight: 5,
  verticalAlign: 'middle'
}

export default ddiForm({
  masterOptions: {
    headerComponent: SearchArea,
    hideReportButton: true,
    footer: {
      // left: () => <div />,
      right: RightFooterButtons
    },
    saveArgumentsSaga,
    getRecordArgumentsSaga: saveArgumentsSaga,
    transformDataBeforeSave: transformCustomFieldsData,
    mapResponse({ response, tabIds = [], formState, groupNames = []}) {
      return mapDataResponse({ response, tabIds, formState, groupNames })
    },
    tabs: [
      {
        icon: <Icon style={tabIconStyle}>settings_applications</Icon>,
        title: 'Setup',
        access: 'setup',
        callAlways: true,
        tabs: [
          {
            component: Information,
            title: 'Information',
            access: 'information'
          },
          {
            component: Credit,
            title: 'Credit',
            access: 'credit'
          },
          {
            component: VendorIds,
            title: 'Vendor Ids',
            access: 'vendorIds'
          },
          {
            component: TruckRoutes,
            title: 'Truck Routes',
            access: 'truckRoutes'
          },
          {
            component: MonthlyBudget,
            title: 'Monthly Budget',
            access: 'monthlyBudget'
          },
          {
            component: StockLevels,
            title: 'Stock Levels',
            access: 'stockLevels'
          },
          {
            component: CustomFields,
            title: 'Custom Fields',
            access: 'customfields',
            mapResponse: mapCustomFieldsResponse,
            isHidden: checkIfCustomFieldsHidden,
            callAlways: true
          },
        ]
      },
      {
        component: Audit,
        icon: <Icon style={tabIconStyle}>search</Icon>,
        title: 'Audit',
        access: 'audit',
        callAlways: true,
        // access: wrapAuditProcess
        mapResponse: mapAuditResponse
      }
    ]
  },
  actions: {
    createShipTo: actions.createShipTo,
    deleteShipTo: actions.deleteShipTo.try,
    saveShipTo: actions.saveShipTo
  },
  behaviors,
  sagas: {
    onInit: sagas
  },
  getEntityApi: getCustomerShipTo,
  form: 'customerShipTo',
  title: 'Customer Ship To'
})()
