import { makeRowDataUnique } from 'utils'

export const getRowNodeId = data => data.dataId

export const mapAuditResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {

  const nested = [...tabIds, ...groupNames]
  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (
        nested.includes(next) &&
        response[next] !== null &&
        typeof response[next] === 'object' &&
        Object.keys(response[next]).length
      ) {
        for (const prop in response[next]) {
          const value = prop === 'audits' ?
            makeRowDataUnique(response[next][prop], 'uniqueKey') : response[next][prop]

          acc[prop] = value
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  return res

}