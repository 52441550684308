import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, emptyList } from 'utils'
import shortid from 'shortid'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

const PatternsTooltip = ({ patternKeyMap }) => {
  const patternKeys = patternKeyMap?.toJS ? patternKeyMap.toJS() : []

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Code</TableCell>
          <TableCell>Definition</TableCell>
          <TableCell>Example</TableCell>
          <TableCell>Pattern</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {patternKeys.map((key, i) => (
          <TableRow key={shortid.generate()}>
            <TableCell>{key.code}</TableCell>
            <TableCell>{key.definition}</TableCell>
            <TableCell>{key.example}</TableCell>
            <TableCell>{key.matchingPattern}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default PatternsTooltip
