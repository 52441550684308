export default {
  colors: ['#517b9c', '#444444', '#1abcf7', '#a1a1a1', '#005596', '#434343'],
  credits: { enabled: false },
  lang: { thousandsSep: ',' },
  legend: { font: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif' },
  plotOptions: {
    areaspline: { fillOpacity: 0.5 },
    pie: {
      dataLabels: {
        style: {
          fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
          fontSize: '13px',
          fontWeight: 500
        }
      }
    }
  },
  subtitle: { text: '' },
  title: {
    style: {
      color: '#444444',
      fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif'
    },
    text: '',
  },
  xAxis: {
    labels: { fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif' },
    title: { style: { fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif' } }
  },
  yAxis: {
    labels: {
      style: {
        color: '#444',
        fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '11px'
      }
    },
    title: {
      style: {
        color: '#444',
        fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '14px'
      },
      text: ''
    }
  }
}
