/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
// import { connect } from 'react-redux'
import { withTooltip } from 'hoc'
// import hoistStatics from 'hoist-non-react-statics'
import BaseTooltipTemplate from 'components/BaseTooltipTemplate'
import * as eventModalActions from 'modals/EventModal/actions'
import {
  getIndexSearchTooltip,
  getPurchaseOrderTooltip,
  getSalesOrderTooltip,
  getWarrantyClaimTooltip
} from './actions'

/* for usage example, see SalesOrderFieldWithTooltip and PurchaseOrderFieldWithTooltip */
/* in app\pages\Dashboard\components\Calendar\components\EventModal\index.js */

const getNetworkRequestPromise = type => {
  const actions = {
    salesOrder: getSalesOrderTooltip,
    purchaseOrder: getPurchaseOrderTooltip,
    indexSearch: getIndexSearchTooltip,
    calendarItem: eventModalActions.getActivityTooltip,
    customerLedgerCell: getIndexSearchTooltip,
    warrantyClaim: getWarrantyClaimTooltip
  }

  return actions[type]
}

export default ({
  anchorToGridCellText = false,
  keyToActionMap = {},
  component = BaseTooltipTemplate,
  indexSearchType = '',
  inputComponent = false,
  networkRequestPromise,
  position = 'bottom',
  sensitivity = 10,
  style = { width: 400 },
  timeout = 1000,
  type = 'salesOrder',
  recordNameKey = 'dataId'
}) => WrappedComponent => {
  class TooltipField extends Component {
    _isMounted = false

    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const FormField = withTooltip({
    anchorToGridCellText,
    component,
    inputComponent,
    keyToActionMap,
    networkRequestPromise: getNetworkRequestPromise(type),
    popperOptions: {
      modifiers: {
        // offset: {
        //   enabled: true,
        //   offset: '0, 25'
        // },
        // preventOverflow: {
        //   boundariesElement: 'window'
        // }
      }
    },
    position,
    sensitivity,
    style,
    timeout,
    requestArgs() {
      // console.log('requestArgs', this, this.props)

      if (type === 'calendarItem') {
        return { dataId: this.props.event.dataId, form: 'calendar' }
      }

      if (
        (indexSearchType === 'Bins' || this.props.indexSearchType === 'Bins') &&
        this.props.warehouseId &&
        this.props[recordNameKey]
      ) {
        return {
          indexSearchType: this.props.indexSearchType
            ? this.props.indexSearchType
            : indexSearchType,
          recordName: `${this.props.warehouseId}|${this.props[recordNameKey]}`
        }
      }

      return {
        indexSearchType: this.props.indexSearchType
          ? this.props.indexSearchType
          : indexSearchType,
        recordName: this.props[recordNameKey]
      }
    }
  })(TooltipField)

  return FormField
}
