import { startCase } from 'lodash'
import BooleanCellRenderer from './BooleanCellRenderer'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

const getColumnDefs = (
  serialNumberColumn,
  searchType,
  visibilityParams = {}
) => {
  let colDefs = [
    serialNumberColumn,
    {
      editable: false,
      field: 'description',
      headerName: 'Description'
    }
  ]

  if (searchType === 'S' || searchType === 'R') {
    colDefs = [
      serialNumberColumn,
      {
        cellRendererFramework: BooleanCellRenderer,
        field: 'hasNotes',
        headerName: 'Notes',
        hide: !visibilityParams?.showNotes
      },
      {
        field: 'productId',
        headerName: 'Product No',
        hide: !visibilityParams?.displayProduct
      },
      {
        field: 'customerName'
      },
      {
        field: 'orderNumber',
        headerName: 'Order No'
      },
      {
        field: 'orderDate',
        ...centerAlignParams
      },
      {
        field: 'shipDate',
        ...centerAlignParams
      },
      {
        field: 'invoiceId',
        headerName: 'Invoice No'
      },
      {
        field: 'invoiceDate',
        ...centerAlignParams
      },
      {
        field: 'customerPo',
        headerName: 'Customer P/O'
      },
      {
        field: 'branchId',
        headerName: 'Branch'
      },
      {
        field: 'salesmanId',
        headerName: 'Salesman'
      },
      {
        field: 'writer'
      },
      {
        field: 'shipViaId',
        headerName: 'Ship Via'
      },
      {
        field: 'repairProductNumber'
      },
      {
        field: 'repairManufacturer'
      },
      {
        field: 'repairModel'
      }
    ]
  }

  if (searchType === 'A') {
    colDefs = [
      serialNumberColumn,
      {
        field: 'warehouseId',
        headerName: 'Warehouse',
        hide: !visibilityParams?.showWarehouse
      },
      {
        cellRendererFramework: BooleanCellRenderer,
        field: 'hasNotes',
        headerName: 'Notes',
        hide: !visibilityParams?.showNotes
      },
      {
        field: 'dateEntered',
        hide: !visibilityParams?.showDate,
        ...centerAlignParams
      },
      {
        field: 'binLocation',
        hide: !visibilityParams?.showBinLocation
      }
    ]
  }

  if (searchType === 'U') {
    colDefs = [
      serialNumberColumn,
      {
        cellRendererFramework: BooleanCellRenderer,
        field: 'hasNotes',
        headerName: 'Notes',
        hide: !visibilityParams?.showNotes
      },
      {
        field: 'workOrderId',
        headerName: 'Work Order'
      },
      {
        field: 'usedDate',
        ...centerAlignParams
      },
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'productDescription',
        headerName: 'Description'
      }
    ]
  }

  if (searchType === 'P') {
    colDefs = [
      serialNumberColumn,
      {
        cellRendererFramework: BooleanCellRenderer,
        field: 'hasNotes',
        headerName: 'Notes',
        hide: !visibilityParams?.showNotes
      },
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'poNumber',
        headerName: 'P/O Number'
      },
      {
        field: 'poDate',
        headerName: 'Date',
        ...centerAlignParams
      },
      {
        field: 'buyer'
      },
      {
        field: 'receivingDate',
        headerName: 'Rcvd-Date',
        ...centerAlignParams
      },
      {
        field: 'receivedBy',
        headerName: 'Rcvd-By'
      }
    ]
  }

  return colDefs.reduce((acc, next) => {
    acc =
      next === 'name'
        ? acc.concat({
            ...next,
            minWidth: 125
          })
        : acc.concat({
            ...next,
            id: next?.field,
            headerName: next?.headerName
              ? next.headerName
              : next?.field
              ? startCase(next.field)
              : '',
            editable: false,
            minWidth: 125
          })

    return acc
  }, [])
}

export default getColumnDefs
