// import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell/'

export default TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'Vendor',
  // keyToActionMap: {
  //   m: () =>
  //     openScreenAction({
  //       name: 'productMaster',
  //       title: 'Product Master',
  //       image: 'product_master_16.png'
  //     })
  // },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(IndexSearchCell)
