import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { Label, withContext } from 'ddiForm'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import { openScreen } from 'pages/Main/actions'
import { getField, getIn } from 'utils'

import '../styles/SearchArea.scss'

export const SearchArea = ({
  dataId,
  description,
  dispatch,
  hasRecord,
  tab
}) => {
  const [isCurrentInventory, setIsCurrentInventory] = useState(false)

  useEffect(() => {
    if (tab) {
      setIsCurrentInventory(tab === 'currentInventory')
    }
  }, [tab])

  function openProductMasterScreen() {
    dispatch(
      openScreen({
        name: 'productMaster',
        title: 'Product Master',
        dataId
      })
    )
  }

  function getCustomContextMenuItems() {
    const items = [
      {
        text: 'Open Product Master',
        icon: 'open_in_new',
        iconStyle: {
          color: '#444',
          fontSize: 16,
          verticalAlign: 'middle'
        },
        onClick: openProductMasterScreen,
        enabled: hasRecord
      }
    ]

    return items
  }

  return (
    <div className="psni-search-area-outer-wrapper">
      <div className="inner-wrapper">
        <div className="search-wrapper">
          <div className="search-label">Product: </div>
          <div style={{ minWidth: 150 }}>
            <DDIIndexSearch
              propertyName="productId"
              disableLastSearchButton
              autoFocus
              fullWidth
              metaKey="searchFields[0]"
              leader
              // inputRef={c => (this.search = c)}
              parentType="all"
              preventAutoDisable
              customContextMenuItems={getCustomContextMenuItems}
            />
          </div>
          {hasRecord ? (
            <div className="margin-left">
              <Label
                propertyName="description"
                labelStyle={{ width: '100%' }}
                value={description}
              />
            </div>
          ) : null}
        </div>
      </div>

      {!isCurrentInventory && (
        <div className="inner-wrapper">
          <div className="search-wrapper">
            <div className="search-label">Serial #:</div>
            <div style={{ minWidth: 150 }}>
              <DDITextField
                propertyName="selectedSerialNumber"
                preventAutoDisable
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export const contextObj = {
  dataId: form => getField(form, 'dataId', ''),
  description: form => getField(form, 'description', ''),
  tab: form =>
    getIn(form, 'masterOptions.selectedPrimaryTab') || 'currentInventory'
}

export default withContext(contextObj)(SearchArea)
