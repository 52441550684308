import {
  delay,
  take,
  call,
  select,
  put,
  spawn,
  fork,
  cancel
} from 'redux-saga/effects'

import { dataURItoBlob, getIn, toCamelCase } from 'utils'
import {
  CANCELED,
  CONFIRMED
  // REMOVE_MODAL,
  // HIDE_MODAL,
  // CLEAR_MODALS
} from 'modals/constants'
import { addModal } from 'modals/actions'
import { api } from 'services'

import { ADD_BLANK_ROW } from 'ddiForm/constants'
import {
  DELETE_GRID_ROW,
  ON_PRIMARY_GRID_DATA_VALIDATED
} from 'components/EditableGrid/constants'
import { validateGridData } from 'components/EditableGrid/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { setSelectionCriteria as setOrderFormSelectionCriteria } from 'pages/CustomerOrderForm/actions'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import * as CONSTANTS from './constants'
import * as actions from './actions'
import SelectionCriteriaModal from '.'
import PrintLabelsModal from './components/PrintLabelsModal'
import FieldSelectionModal from './components/FieldSelectionModal'
import LabelParamsModal from './components/LabelParamsModal'

/* print modal */

let selectionCriteriaModalId
let printLabelsModalId
let reportModalId
let fieldSelectionModalId
let labelParamsModalId

const noCriteriaSelectionProcess = [
  'customercategory',
  'arterms',
  'customersource',
  'customerterritory',
  'customerTax',
  'customerJob',
  'truckMaster',
  'vendorCategory',
  'vendorSource',
  'vendorTerms',
  'productTaxGroup',
  'majorGroup',
  'majorBuyLine',
  'priceGroup',
  'buyLine',
  'productLine',
  'cycleCountGroup',
  'unitOfMeasure',
  'division',
  'warehouseBuyZone',
  'productWebCategory',
  'orderType',
  'salespersonMaster',
  'inventoryAdjustmentReason',
  'salesOrderSpecialInstructions',
  'purchaseOrderSpecialInstructions',
  'optionsAndAccessoriesMaster',
  'dotHazardousMaster',
  'miscellaneousCharge',
  'miscChargeProgram',
  'branchMaster',
  'shipVia'
]

export const validateDataIds = items =>
  items.reduce((a, n) => {
    /* these FUCKING empty dataIds from the API */
    if (n.dataId || (n.description && n.description === 'Active')) {
      a = a.concat(n)
    }
    return a
  }, [])

export function* launchPrintLabelsModalProcess(form) {
  const modalOpts = {
    component: PrintLabelsModal,
    options: {
      data: {
        actions: [
          {
            primary: true,
            title: 'Print',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.dispatch(actions.runPrintLabels(form))
              } finally {
                console.log('Print interface shown')
                // cb()
              }
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.dispatch(
                  actions.launchLabelParamsModal(form, { parentModalCb: cb })
                )
              } finally {
                console.log('exited')
                // cb()
              }
            }
          }
        ],
        criteriaSelection: 'Labels',
        form
      },
      maxHeight: '100%',
      width: 600,
      title: 'Print Labels'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  printLabelsModalId = modal.payload.id
  return printLabelsModalId
}

export function* launchSelectionCriteriaModalProcess(
  form,
  type,
  stopContinuation = false,
  customAction = null
) {
  const actionButtons = [
    {
      title: 'Reset',
      async clickEvent(args, formState, cb) {
        this.props.dispatch(actions.resetCriteriaSelection(form, { type }))
      }
    },
    {
      primary: true,
      title: 'OK',
      async clickEvent(args, formState, cb) {
        if (type === 'Labels') {
          try {
            await this.props.dispatch(
              actions.getLabelPrintingDefaults(form, { parentModalCb: cb })
            )
          } finally {
            console.log('crazy interface')
            // cb()
          }
        } else {
          this.props.dispatch(
            actions.launchFieldSelectionModal(form, { parentModalCb: cb, type })
          )
          // cb()
        }
      }
    },
    {
      primary: true,
      title: 'Cancel',
      async clickEvent(args, formState, cb) {
        try {
          // await this.props.dispatch(actions.resetCriteriaSelection(form, { type }))
          await this.props.dispatch(actions.clearSelectionCriteriaFields(form))
        } finally {
          cb()
        }
      }
    }
  ]

  if (stopContinuation && customAction && typeof customAction === 'function') {
    actionButtons[1] = {
      primary: true,
      title: 'OK',
      async clickEvent(args, formState, cb) {
        try {
          await this.props.dispatch(
            customAction(form, {
              type,
              cb
            })
          )
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  const getCriteriaSelectionTitle = (formName, formType) => {
    const titles = {
      customerDuetoBuyReport: {
        Customer: 'Customer Selection Criteria - CUSTOMER DUE TO BUY REPORT',
        Product: 'Product Selection Criteria - CUSTOMER DUE TO BUY REPORT'
      },
      customerMaster: {
        Labels: 'Customer Selection Criteria - CUSTOMER LABELS',
        Report: 'Customer Selection Criteria - CUSTOMER FILE LISTING'
      },
      customerOrderForm: {
        Customer: 'Customer Selection Criteria - CUSTOMER ORDER REPORT',
        Product: 'Product Selection Criteria - CUSTOMER ORDER REPORT'
      },
      productMaster: {
        Report: 'Product Selection Criteria - PRODUCT FILE LISTING'
      }
    }

    if (titles[formName] && titles[formName][formType]) {
      return titles[formName][formType]
    }

    return 'Criteria Selection'
  }

  const modalOpts = {
    component: SelectionCriteriaModal,
    options: {
      data: {
        actions: actionButtons,
        type,
        form
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: '90%',
      title: getCriteriaSelectionTitle(form, type)
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  selectionCriteriaModalId = modal.payload.id
  return selectionCriteriaModalId
}

export function* getLabelPrintingCriteriaProcess(form) {
  yield put({
    type: CONSTANTS.GET_LABEL_PRINTING_CRITERIA.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getLabelPrintingCriteria, {
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.GET_LABEL_PRINTING_CRITERIA.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(launchSelectionCriteriaModalProcess, form, 'Labels')
  } else {
    yield put({
      type: CONSTANTS.GET_LABEL_PRINTING_CRITERIA.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* getLabelPrintingCriteriaListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_LABEL_PRINTING_CRITERIA.TRY)
    if (form === formListener) {
      yield fork(getLabelPrintingCriteriaProcess, form)
    }
  }
}

export function* getReportCriteriaProcess(form) {
  // debugger
  yield put({
    type: CONSTANTS.GET_REPORT_CRITERIA.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getReportCriteria, {
    getSelectionCriteria: true,
    getFieldSelection: true,
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.GET_REPORT_CRITERIA.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(launchSelectionCriteriaModalProcess, form, 'Report')
  } else {
    yield put({
      type: CONSTANTS.GET_REPORT_CRITERIA.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* getReportCriteriaListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_REPORT_CRITERIA.TRY)
    if (form === formListener) {
      if (noCriteriaSelectionProcess.includes(form)) {
        yield fork(getNoCriteriaReportProcess, form)
      } else {
        yield fork(getReportCriteriaProcess, form)
      }
    }
  }
}

export function* getLabelPrintingDefaultsProcess(form, parentModalCb) {
  const selectionCriteria = yield call(
    getSelectionCriteriaParams,
    form,
    'Labels'
  )

  if (selectionCriteria.errors) {
    yield call(
      warningModal,
      selectionCriteria.errors.reduce((acc, next) => {
        acc = acc.concat(`${next}\n`)
        return acc
      }, ''),
      'Missing Additional Criteria Data!'
    )
    return
  }

  if (parentModalCb) {
    parentModalCb()
  }

  yield put({
    type: CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getLabelPrintingDefaults, {
    selectionCriteria,
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(launchPrintLabelsModalProcess, form)
  } else {
    yield put({
      type: CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* getLabelPrintingDefaultsListener(formListener) {
  while (true) {
    const {
      payload: { parentModalCb },
      meta: { form }
    } = yield take(CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.TRY)

    if (form === formListener) {
      yield fork(getLabelPrintingDefaultsProcess, form, parentModalCb)
    }
  }
}

export function* runPrintLabelProcess(form) {
  const printLabelFields = yield select(state =>
    getIn(state, `ddiForm.${form}.fields.selectionCriteria.Labels.printLabels`)
  )

  const numberOfLabelsAcross = getIn(
    printLabelFields,
    'numberOfLabelsAcross.value'
  )
  const numberOfLinesToSkip = getIn(
    printLabelFields,
    'numberOfLinesToSkip.value'
  )
  const lengthOfEachLabel = getIn(
    printLabelFields,
    'lengthOfEachLabelChar.value'
  )
  const numberOfSpacesBetweenLabels = getIn(
    printLabelFields,
    'numberOfSpacesBetweenLabels.value'
  )
  const numberOfLabelsToSample = getIn(
    printLabelFields,
    'numberOfLabelsToSample.value'
  )

  yield put({
    type: CONSTANTS.RUN_PRINT_LABELS.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.runPrintLabels, {
    numberOfLabelsAcross,
    numberOfLinesToSkip,
    lengthOfEachLabel,
    numberOfSpacesBetweenLabels,
    numberOfLabelsToSample,
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.RUN_PRINT_LABELS.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      criteriaSelection: 'Labels',
      form
    })
  } else {
    yield put({
      type: CONSTANTS.RUN_PRINT_LABELS.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* runPrintLabelListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.RUN_PRINT_LABELS.TRY)
    if (form === formListener) {
      yield fork(runPrintLabelProcess, form)
    }
  }
}

export function* exitPrintLabelsProcess(form, updateDefaults) {
  yield put({
    type: CONSTANTS.EXIT_PRINT_LABELS.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.exitPrintLabels, {
    routeName: form,
    updateDefaults
  })

  if (response) {
    yield put({
      type: CONSTANTS.EXIT_PRINT_LABELS.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.EXIT_PRINT_LABELS.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* exitPrintLabelsListener(formListener) {
  while (true) {
    const {
      payload: { updateDefaults },
      meta: { form }
    } = yield take(CONSTANTS.EXIT_PRINT_LABELS.TRY)
    if (form === formListener) {
      yield fork(exitPrintLabelsProcess, form, updateDefaults)
    }
  }
}

export function* launchLabelParamsProcess(form, parentModalCb) {
  const modalOpts = {
    component: LabelParamsModal,
    options: {
      data: {
        actions: [
          {
            primary: true,
            title: 'Yes',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.dispatch(
                  actions.exitPrintLabels(form, {
                    updateDefaults: true,
                    routeName: form
                  })
                )
              } finally {
                cb()
                parentModalCb()
              }
            }
          },
          {
            primary: true,
            title: 'No',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.dispatch(
                  actions.exitPrintLabels(form, {
                    updateDefaults: false,
                    routeName: form
                  })
                )
              } finally {
                cb()
                parentModalCb()
              }
            }
          }
        ],
        criteriaSelection: 'Labels',
        form
      },
      width: 400,
      message: 'Do you wish to update the default settings?',
      title: 'Label Parameters Changed'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  labelParamsModalId = modal.payload.id
  return labelParamsModalId
}

export function* launchLabelParamsModalListener(formListener) {
  while (true) {
    const {
      payload: { parentModalCb },
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_LABEL_PARAMS_MODAL)

    if (form === formListener) {
      yield fork(launchLabelParamsProcess, form, parentModalCb)
    }
  }
}

export function* getSelectionCriteriaParams(form, type, errorCheck = false) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  let equalToCriterias = getIn(
    formState,
    'values.selectionCriteria.equalToCriterias'
  )
  const additionalCriteriaKey = `fields.selectionCriteria.${type}.grids.additionalCriteria.rowData`
  const additionalCriteria = getIn(formState, additionalCriteriaKey)
    ? getIn(formState, additionalCriteriaKey).toJS()
    : []
  // debugger

  let additionalCriteriaResults = { data: [], errors: [] }
  if (additionalCriteria.length === 1) {
    additionalCriteriaResults = additionalCriteria.reduce(
      (acc, next, idx) => {
        const isValid = Boolean(
          next.dataId && next.critOperator && next.critValue
        )
        // debugger
        acc.data = acc.data.concat({
          dataId: isValid ? next.dataId : '',
          CritOperator: isValid ? next.critOperator : '',
          CritValue: isValid ? next.critValue : '',
          CritConnector: next.critConnector
        })

        if (!isValid && next.dataId) {
          acc.errors = acc.errors.concat(`Missing data in line ${idx + 1}`)
        }

        return acc
      },
      { data: [], errors: [] }
    )
    // debugger

    if (
      !additionalCriteriaResults.errors.length &&
      additionalCriteriaResults.data[0] &&
      (!additionalCriteriaResults.data[0].dataId ||
        !additionalCriteriaResults.data[0].CritOperator ||
        !additionalCriteriaResults.data[0].CritValue)
    ) {
      // debugger
      /* this condition means that a user has left a blank row hanging out with only a 'field' selected */
      yield put(
        actions.deleteOnlyGridRow(form, {
          propertyName: `selectionCriteria.${type}.grids.additionalCriteria`,
          rowIndex: 0
        })
      )

      additionalCriteriaResults = {
        data: [],
        errors: []
      }
    }
  } else {
    additionalCriteriaResults = additionalCriteria.reduce(
      (acc, next, idx) => {
        const isValid = Boolean(
          next.dataId && next.critOperator && next.critValue
        )
        const connectorIsValid = !!(
          (additionalCriteria.length === idx + 1 && !next.critConnector) ||
          next.critConnector
        ) // eslint-disable-line

        acc.data = acc.data.concat({
          dataId: isValid ? next.dataId : '',
          CritOperator: isValid ? next.critOperator : '',
          CritValue: isValid ? next.critValue : '',
          CritConnector: next.critConnector
        })

        if (!isValid) {
          acc.errors = acc.errors.concat(`Missing data in line ${idx + 1}`)
        }

        if (!connectorIsValid) {
          acc.errors = acc.errors.concat(`Missing connector in line ${idx + 1}`)
        }

        return acc
      },
      { data: [], errors: [] }
    )
  }

  if (additionalCriteriaResults.errors.length) {
    return {
      errors: additionalCriteriaResults.errors
    }
  }

  if (errorCheck) {
    return false
  }

  /*
  let printOrder = getIn(formState, 'values.selectionCriteria.fieldSelection.printOrder')
  */

  const sortByField = getIn(
    formState,
    `fields.selectionCriteria.${type}.inputs.sortByField.value`
  )
  const sortDirectionType = getIn(
    formState,
    `fields.selectionCriteria.${type}.inputs.sortDirectionType.value`
  )

  equalToCriterias = equalToCriterias
    ? equalToCriterias.toJS().reduce((acc, next) => {
        let items = getIn(
          formState,
          `fields.selectionCriteria.${type}.grids.${toCamelCase(
            next.description
          )}.rowData`
        )

        items = items && items.toJS ? items.toJS() : []

        acc = acc.concat({
          dataId: next.dataId,
          enabled: next.enabled,
          equalToType: getIn(
            formState,
            `fields.selectionCriteria.${type}.inputs.${toCamelCase(
              next.description
            )}.value`
          ),
          items: validateDataIds(items)
        })
        return acc
      }, [])
    : []

  // console.log(equalToCriterias)
  // debugger

  /*
  printOrder = printOrder
    ? printOrder.toJS().reduce((acc, next) => {
      acc = acc.concat(next.dataId)
      return acc
    }, [])
    : []
  */

  return {
    sortByField,
    sortDirectionType,
    additionalCriteria: additionalCriteriaResults.data.reduce((acc, next) => {
      /* failsafe */
      if (next.dataId) {
        acc = acc.concat(next)
      }
      return acc
    }, []),
    equalToCriterias
  }
}

export function* setReportCriteriaProcess(form, parentModalCb) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  let printOrder = getIn(
    formState,
    'values.selectionCriteria.fieldSelection.printOrder'
  )

  printOrder =
    printOrder && printOrder?.toJS
      ? printOrder.toJS().reduce(
          (acc, next) => {
            acc.data = acc.data.concat(next.dataId)
            acc.length += next.masterFileReportLength
            return acc
          },
          { data: [], length: 0 }
        )
      : []
  // debugger

  if (printOrder.length > 132) {
    yield call(
      confirmationModal,
      'Would you like to continue anyway?',
      'Over Max Print Length'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      if (parentModalCb) {
        parentModalCb()
      }
    } else {
      return
    }
  } else if (parentModalCb) {
    // eslint-disable-line
    parentModalCb()
  }
  // debugger

  const selectionCriteria = yield call(
    getSelectionCriteriaParams,
    form,
    'Report'
  )

  yield put({
    type: CONSTANTS.SET_REPORT_CRITERIA.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.setReportCriteria, {
    selectionCriteria: noCriteriaSelectionProcess.includes(form)
      ? null
      : selectionCriteria,
    printOrder:
      printOrder.data && printOrder.data.length ? printOrder.data : [],
    runReport: true,
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.SET_REPORT_CRITERIA.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      criteriaSelection: 'Report',
      form
    })
  } else {
    yield put({
      type: CONSTANTS.SET_REPORT_CRITERIA.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* setReportCriteriaListener(formListener) {
  while (true) {
    const {
      payload: { parentModalCb },
      meta: { form }
    } = yield take(CONSTANTS.SET_REPORT_CRITERIA.TRY)
    if (form === formListener) {
      yield fork(setReportCriteriaProcess, form, parentModalCb)
    }
  }
}

export function* cancelReportProcess(form) {
  yield put({
    type: CONSTANTS.CANCEL_REPORT.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.cancelReport, {
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.CANCEL_REPORT.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.CANCEL_REPORT.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* cancelReportListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_REPORT.TRY)

    if (form === formListener) {
      yield fork(cancelReportProcess, form)
    }
  }
}

export function* launchFieldSelectionModalProcess(form, parentModalCb, type) {
  const additionalCriteriaSelectionErrorCheck = yield call(
    getSelectionCriteriaParams,
    form,
    'Report',
    true
  )

  if (
    additionalCriteriaSelectionErrorCheck.errors &&
    additionalCriteriaSelectionErrorCheck.errors.length
  ) {
    yield call(
      warningModal,
      additionalCriteriaSelectionErrorCheck.errors.reduce((acc, next) => {
        acc = acc.concat(`${next}\n`)
        return acc
      }, ''),
      'Missing Additional Criteria Data!'
    )
    return
  }

  /* get and ensure that we have at least one enabled tile */
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  let equalToCriterias = getIn(
    formState,
    'values.selectionCriteria.equalToCriterias'
  )
  equalToCriterias =
    equalToCriterias && equalToCriterias.toJS ? equalToCriterias.toJS() : []

  const numEnabledTiles = equalToCriterias.reduce((acc, next) => {
    let items = getIn(
      formState,
      `fields.selectionCriteria.${type}.grids.${toCamelCase(
        next.description
      )}.rowData`
    )

    items = items && items.toJS ? items.toJS() : []
    items = validateDataIds(items)

    if (next.enabled && items.length) {
      acc = acc.concat(next.dataId)
    }
    return acc
  }, [])

  let additionalCriteria = getIn(
    formState,
    `fields.selectionCriteria.${type}.grids.additionalCriteria.rowData`
  )
  additionalCriteria =
    additionalCriteria && additionalCriteria.toJS
      ? additionalCriteria.toJS()
      : []

  const sortByField = getIn(
    formState,
    'fields.selectionCriteria.Report.inputs.sortByField.value'
  )
  const sortDirectionType = getIn(
    formState,
    'fields.selectionCriteria.Report.inputs.sortDirectionType.value'
  )

  if (!sortByField || !sortDirectionType) {
    yield fork(
      warningModal,
      'Please ensure that you have selected both "Sort By" options!',
      'Attention!'
    )
    return
  }

  // console.log('additionalCriteria', additionalCriteria)
  // debugger

  if (!numEnabledTiles.length && !additionalCriteria.length) {
    const titleMapper = {
      productMaster: 'Products',
      customerMaster: 'Customers'
    }

    const confirmationMessage = titleMapper[form]
      ? `Would you like to select all ${titleMapper[form]}?`
      : 'Would you like to select all?'

    yield call(confirmationModal, confirmationMessage, 'No Selection Specified')
    // debugger

    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }
  }

  /* we have passed the check, take down the modal */
  if (parentModalCb) {
    parentModalCb()
  }

  const modalOpts = {
    component: FieldSelectionModal,
    options: {
      data: {
        actions: [
          {
            title: 'Reset',
            async clickEvent(args, fs, cb) {
              this.resetInterface()
              // try {
              //   await this.props.resetFieldSelection(form)
              // } finally {
              //   console.log('field selection reset')
              // }
            }
          },
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.setReportCriteria(form, { parentModalCb: cb })
              } finally {
                // cb()
              }
            },
            disabled: f => {
              const { values } = f
              const printOrder = getIn(
                values,
                'selectionCriteria.fieldSelection.printOrder'
              )
              console.log('shouldDisable', printOrder)
              if (!printOrder.length) {
                return true
              }

              return false
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.cancelReport(form)
              } finally {
                cb()
              }
            }
          }
        ],
        form
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 800,
      title: 'Field Selection'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  fieldSelectionModalId = modal.payload.id
  // return fieldSelectionModalId
}

export function* launchFieldSelectionModalListener(formListener) {
  while (true) {
    const {
      payload: { parentModalCb, type },
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_FIELD_SELECTION_MODAL)

    if (form === formListener) {
      yield fork(launchFieldSelectionModalProcess, form, parentModalCb, type)
    }
  }
}

export function* addNewRowOnValidationListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, newData },
      meta: { form }
    } = yield take(ON_PRIMARY_GRID_DATA_VALIDATED)
    if (form === formListener && propertyName.match(/selectionCriteria/gi)) {
      yield put(validateGridData(form, { propertyName }))
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const isPending = getIn(formState, `fields.${propertyName}.isPending`)
      const { name, recordName } = newData
      const id = name || recordName

      if (!isPending && id) {
        yield put({
          type: ADD_BLANK_ROW,
          payload: { propertyName },
          meta: { form, reducer: 'Grid' }
        })
      }
    }
  }
}

export function* deleteGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex }
    } = yield take(DELETE_GRID_ROW)

    if (
      form === formListener &&
      rowIndex === 0 &&
      (propertyName === 'selectionCriteria.Labels.grids.additionalCriteria' ||
        propertyName === 'selectionCriteria.Report.grids.additionalCriteria')
    ) {
      yield put(actions.deleteOnlyGridRow(form, { propertyName, rowIndex }))
    }
  }
}

export function* getNoCriteriaReportProcess(form) {
  yield put({
    type: CONSTANTS.GET_REPORT_CRITERIA.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getReportCriteria, {
    getSelectionCriteria: false,
    getFieldSelection: true,
    routeName: form
  })

  if (response) {
    yield put({
      type: CONSTANTS.GET_REPORT_CRITERIA.SUCCESS,
      payload: response,
      meta: { form }
    })

    yield fork(launchNoCriteriaReportModalProcess, response, form)
  } else {
    yield put({
      type: CONSTANTS.GET_REPORT_CRITERIA.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* launchNoCriteriaReportModalProcess(response, form) {
  const modalOpts = {
    component: FieldSelectionModal,
    options: {
      data: {
        actions: [
          {
            title: 'Reset',
            async clickEvent(args, fs, cb) {
              this.resetInterface()
              // try {
              //   await this.props.resetFieldSelection(form)
              // } finally {
              //   console.log('field selection reset')
              // }
            }
          },
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.setReportCriteria(form, { parentModalCb: cb })
              } finally {
                // cb()
              }
            },
            disabled: f => {
              const { values } = f
              const printOrder = getIn(
                values,
                'selectionCriteria.fieldSelection.printOrder'
              )

              if (!printOrder.length) {
                return true
              }

              return false
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.cancelReport(form)
              } finally {
                cb()
              }
            }
          }
        ],
        form
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 800,
      title: 'Field Selection'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  fieldSelectionModalId = modal.payload.id

  return fieldSelectionModalId
}

export default function* selectionCriteriaModalSagas(form) {
  yield fork(getLabelPrintingCriteriaListener, form)
  yield fork(getReportCriteriaListener, form)
  yield fork(getLabelPrintingDefaultsListener, form)
  yield fork(runPrintLabelListener, form)
  yield fork(launchFieldSelectionModalListener, form)
  yield fork(setReportCriteriaListener, form)
  yield fork(cancelReportListener, form)
  yield fork(exitPrintLabelsListener, form)
  yield fork(launchLabelParamsModalListener, form)
  yield fork(addNewRowOnValidationListener, form)
  yield fork(deleteGridRowListener, form)
}
