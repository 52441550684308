import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, is } from 'utils'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce, noop } from 'lodash'
import { removeModal } from 'modals/actions'
import { errorRed } from 'theme/colors'
import { handleRecurringOrderInteraction } from '../../../actions'

const marginRight = { marginRight: 5 }

const ActionButtons = ({
  balance,
  lineNumber,
  form,
  modalId,
  isEditable,
  onClickLoadScheduleButton = noop
}) => {
  const dispatch = useDispatch()

  const cancelRecurring = debounce(e => {
    dispatch(
      handleRecurringOrderInteraction.try(form, {
        lineNumber,
        apiParams: {
          action: 'cancel'
        },
        modalId
      })
    )
  }, 300)

  const commitRecurring = debounce(e => {
    dispatch(
      handleRecurringOrderInteraction.try(form, {
        lineNumber,
        apiParams: {
          action: 'ok'
        },
        modalId
      })
    )
  }, 300)

  return (
    <div
      className="sales-order-entry-modal-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0 0 0'
      }}
    >
      <p
        style={{
          alignItems: 'center',
          display: 'flex',
          fontSize: '1em',
          margin: 0
        }}
      >
        <span style={{ marginRight: 5 }}>Balance:</span>
        <span style={{ color: balance && balance < 0 ? errorRed : '#000' }}>
          {balance}
        </span>
      </p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          padding: '10px 0 0 0'
        }}
      >
        <Button
          onClick={onClickLoadScheduleButton}
          variant="contained"
          style={marginRight}
          tabIndex={-1}
        >
          Load Schedule
        </Button>
        <Button
          onClick={commitRecurring}
          variant="contained"
          style={marginRight}
          tabIndex={-1}
          disabled={!isEditable}
        >
          OK
        </Button>
        <Button onClick={cancelRecurring} variant="contained" tabIndex={-1}>
          Exit
        </Button>
      </div>
    </div>
  )
}

export default ActionButtons
