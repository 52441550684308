/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const deleteCategory = createAsyncFormAction(CONSTANTS.DELETE_CATEGORY)

export const addCategory = (form, { data }) =>
  createAction(CONSTANTS.ADD_CATEGORY, { data }, form)

export const setSelectedCategory = (form, { selectedId }) =>
  createAction(CONSTANTS.SET_SELECTED_CATEGORY, { selectedId }, form)

export const dragCategory = (form, { draggedItem, dropTarget }) =>
  createAction(CONSTANTS.DRAG_CATEGORY, { draggedItem, dropTarget }, form)
