import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { DDIButton } from 'ddiForm/wrapped'
// import { is } from 'utils'
import { debounce } from 'lodash'
import * as actions from 'pages/SalesOrder/actions'

export const CustomerPartNumberModalActions = ({
  dispatch,
  form,
  modal: {
    id = '',
    options: {
      data: { dataId, rowIndex, gridApi, uniqueKey, childLineNumber }
    }
  },
  ...props
}) => {
  const handleSave = debounce(e => {
    dispatch(
      actions.savePartNumber.try(form, {
        dataId,
        modalId: id,
        rowIndex,
        gridApi,
        uniqueKey,
        childLineNumber
      })
    )
  }, 500)

  const handleDelete = debounce(e => {
    dispatch(
      actions.deletePartNumber.try(form, {
        dataId,
        uniqueKey,
        modalId: id,
        childLineNumber
      })
    )
  }, 500)

  const handleExit = debounce(e => {
    dispatch(
      actions.cancelEditPartNumber.try(form, {
        dataId,
        uniqueKey,
        modalId: id,
        childLineNumber
      })
    )
  }, 500)

  return (
    <>
      <DDIButton
        onClick={handleSave}
        variant="contained"
        label="Save"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleDelete}
        variant="contained"
        label="Delete"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleExit}
        variant="contained"
        label="Exit"
        preventAutoDisable
      />
    </>
  )
}

export default connect()(CustomerPartNumberModalActions)
