import React, { useEffect, useState, useRef } from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  Input
} from '@material-ui/core'
import { BASE_INFORM_URL } from 'services/constants'
import {
  storeUIFeatureState,
  launchDepositInvoiceEntryModal as launchDepositInvoiceEntryModalAction
} from 'pages/SalesOrder/actions'
import { NumberFormatter } from 'pages/SalesOrder/tabs/Detail/components/Pricing/components/NumberField'
import { emptyList, getIn, getValue, getField, plainDeepEqual } from 'utils'
import { DDIToggle } from 'ddiForm/wrapped'
import { debounce } from 'lodash'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import usePrevious from 'hooks/usePrevious'
import CashPayment from './components/CashPayment'
import CheckPayment from './components/CheckPayment'
import CreditCardPayment from './components/CreditCardPayment'

import './styles/master-style.scss'

export const isBillingOptionDisabled = memoize((isEditing, payments = []) => {
  if (!isEditing || payments.length) {
    return true
  }

  return false
}, plainDeepEqual)

export const isCheckPaymentDisabled = memoize((canPayByChecks, isEditing) => {
  if (canPayByChecks && isEditing) {
    return false
  }

  return true
})

const Payments = ({
  // amountTendered,
  balanceLeft,
  canPostToAR,
  ccPaymentOption,
  form,
  dispatch,
  height,
  cardTypes = ['mc', 'visa', 'amex', 'discover'],
  pos,
  shippableTotal,
  paymentAttemptSuccess,
  dataId,
  isEditing,
  paymentsList,
  canCreateDeposits,
  canPayByChecks,
  paymentOption
}) => {
  const payments = paymentsList?.toJS ? paymentsList.toJS() : []
  const prevPayments = usePrevious(payments)

  const textField = React.createRef()

  const [state, setState] = useState({
    paymentOption: pos ? 'cash' : 'bill',
    amountTendered: pos ? balanceLeft : shippableTotal
  })

  useEffect(() => {
    if (!pos && !state.paymentOption && canPostToAR) {
      setState({
        ...state,
        paymentOption: 'bill',
        amountTendered: shippableTotal
      })
    }
  }, [pos])

  useEffect(() => {
    /*
      ONLY update the amountTendered after a successful payment.
      This flag is set in Redux on initializePayment.success
      -- SVE 1/16/20
    */
    if (paymentAttemptSuccess || !plainDeepEqual(payments, prevPayments)) {
      setState({
        ...state,
        amountTendered: pos ? balanceLeft : shippableTotal
      })
    }
  }, [balanceLeft, paymentsList])

  useEffect(() => {
    /*
      make an update to the amountTendered field if the shippableTotal changes.
      This can occur when changing Ship Via after a failed payment attempt,
      and the order total updates due to a Miscellaneous Charge (so in this
      case, we want to ignore the disposition of the paymentAttemptSuccess flag
      -- SVE 1/20/2020
    */
    setState({
      ...state,
      amountTendered: pos ? balanceLeft : shippableTotal
    })
  }, [shippableTotal])

  useEffect(() => {
    /*
      if the orderId changes, update the balanceLeft
      or shippableTotal depending on the customer type
    */
    setState({
      ...state,
      amountTendered: pos ? balanceLeft : shippableTotal
    })
  }, [dataId])

  useEffect(() => {
    if (state.paymentOption && state.paymentOption !== paymentOption) {
      dispatch(
        storeUIFeatureState(form, {
          feature: 'paymentOption',
          featureState: state.paymentOption
        })
      )
    }
  }, [paymentOption, state.paymentOption])

  const onRadioButtonChange = e => {
    const { value } = e.target

    if (value) {
      const isBill = value === 'bill'

      setState({
        ...state,
        paymentOption: value,
        amountTendered: isBill ? balanceLeft : state.amountTendered
      })
    }
  }

  const getPaymentOptionStyle = memoize((opt, val) => {
    if (opt === val) {
      return { border: '2px solid #517b9c', margin: 0, zIndex: 2 }
    }

    return {
      borderBottom: '1px solid #d9dcde',
      borderTop: '1px solid #d9dcde',
      margin: '-1px 0 0 0',
      zIndex: 1
    }
  })

  const getCreditCardLabel = memoize(cards => {
    return (
      <div className="credit-wrapper">
        <span>Credit Card</span>
        <div>
          {cards.includes('visa') ? (
            <span className="card-span">
              <img
                src={`${BASE_INFORM_URL}/resources/cc-icons/visa.png`}
                alt="Visa"
              />
            </span>
          ) : null}
          {cards.includes('mc') ? (
            <span className="card-span">
              <img
                src={`${BASE_INFORM_URL}/resources/cc-icons/mc.png`}
                alt="MC"
              />
            </span>
          ) : null}
          {cards.includes('amex') ? (
            <span className="card-span">
              <img
                src={`${BASE_INFORM_URL}/resources/cc-icons/amex.png`}
                alt="Amex"
              />
            </span>
          ) : null}
          {cards.includes('discover') ? (
            <span className="card-span">
              <img
                src={`${BASE_INFORM_URL}/resources/cc-icons/discover.png`}
                alt="Discover"
              />
            </span>
          ) : null}
        </div>
      </div>
    )
  })

  const onNumberFormatterChange = values => {
    const v = values.floatValue

    setState({
      ...state,
      amountTendered: v
    })
  }

  const onBlur = e => {
    dispatch(
      storeUIFeatureState(form, {
        feature: 'tendered',
        featureState: state.amountTendered
      })
    )
  }

  const handleInputFocus = e => {
    if (textField) {
      textField.current.select()
    }
  }

  const launchDepositInvoiceEntryModal = debounce(e => {
    dispatch(launchDepositInvoiceEntryModalAction(form))
  }, 500)

  return (
    <div className="sales-order-payments-wrapper">
      <Paper className="paper-wrapper" square>
        <h2 className="title-text">Payments</h2>
        <div className="payment-wrapper">
          <h3 className="payment-amount">Payment Amount</h3>
          <div className="width-100">
            <Input
              value={state.amountTendered}
              fullWidth
              inputComponent={NumberFormatter}
              onChange={onNumberFormatterChange}
              onFocus={handleInputFocus}
              onBlur={onBlur}
              inputRef={textField}
              inputProps={{
                allowNegative: true,
                decimalScale: 2,
                noDecimalLimit: false,
                decimalSeparator: '.',
                fontSize: 14,
                fixedDecimalScale: true,
                inputRef: textField,
                maxLength: 999,
                paddingRight: 5,
                textAlign: 'right',
                thousandSeparator: '',
                prefix: '$',
                suffix: ''
              }}
              disabled={!isEditing || ccPaymentOption === 'linked'}
            />
          </div>
        </div>
        <h4 className="select-payment-type">Select Payment Type</h4>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="payment-button-set"
            name="payment-button-set"
            value={state.paymentOption}
            onClick={onRadioButtonChange}
          >
            <FormControlLabel
              value="cc"
              control={<Radio />}
              label={getCreditCardLabel(cardTypes)}
              style={getPaymentOptionStyle(state.paymentOption, 'cc')}
              disabled={!isEditing}
            />
            <FormControlLabel
              value="cash"
              control={<Radio />}
              label="Cash"
              style={getPaymentOptionStyle(state.paymentOption, 'cash')}
              disabled={!isEditing}
            />
            <FormControlLabel
              value="check"
              control={<Radio />}
              label="Check"
              style={getPaymentOptionStyle(state.paymentOption, 'check')}
              disabled={isCheckPaymentDisabled(canPayByChecks, isEditing)}
            />
            <FormControlLabel
              value="bill"
              control={<Radio />}
              label="Bill To Customer"
              style={getPaymentOptionStyle(state.paymentOption, 'bill')}
              // disabled={!canPostToAR}
            />
          </RadioGroup>
        </FormControl>
        <div style={{ padding: 10 }}>
          {state.paymentOption === 'bill' && !pos && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
            >
              <div style={{ marginRight: 15 }}>
                <AssociatedFieldsWrapper>
                  <DDIToggle
                    preventAutoDisable
                    propertyName="allowShipmentsWOReceivedDeposits"
                    label="Allow S/O Shipment Without Received Deposit"
                  />
                </AssociatedFieldsWrapper>
              </div>
              <Button
                disabled={!canCreateDeposits}
                onClick={launchDepositInvoiceEntryModal}
                variant="contained"
              >
                Create Deposit Invoice
              </Button>
            </div>
          )}
          {state.paymentOption === 'cc' && (
            <CreditCardPayment
              form={form}
              dispatch={dispatch}
              amountTendered={state.amountTendered}
              isEditing={isEditing}
            />
          )}
          {state.paymentOption === 'cash' && (
            <CashPayment
              form={form}
              dispatch={dispatch}
              amountTendered={state.amountTendered}
              isEditing={isEditing}
            />
          )}
          {state.paymentOption === 'check' && (
            <CheckPayment
              form={form}
              dispatch={dispatch}
              amountTendered={state.amountTendered}
              balanceLeft={balanceLeft}
              isEditing={isEditing}
            />
          )}
        </div>
      </Paper>
    </div>
  )
}

export default withContext({
  paymentOption: form => getIn(form, 'ui.paymentOption') || '',
  ccPaymentOption: form => getIn(form, 'ui.ccPaymentOption') || '',
  canPostToAR: form => getValue(form, 'canPostToAR', true),
  balanceLeft: form => getValue(form, 'balanceLeft', 0),
  pos: form => getValue(form, 'pos', false),
  shippableTotal: form => getValue(form, 'shippableTotal', 0),
  paymentAttemptSuccess: form => form.get('paymentAttemptSuccess') || false,
  dataId: form => getField(form, 'dataId', ''),
  isEditing: form => form.get('isEditing') || false,
  paymentsList: form => getValue(form, 'payments', emptyList),
  canCreateDeposits: form => getValue(form, 'canCreateDeposits', false),
  canPayByChecks: form => getValue(form, 'canPayByChecks', false)
})(Payments)
