import React from 'react'
import { useDispatch } from 'react-redux'
import { Icon, IconButton } from '@material-ui/core'
import { debounce } from 'lodash'
import { toggleCheckboxCell } from 'pages/VendorMaster/actions'

const CheckboxCell = ({ value, node, colDef, data = {} }) => {
  const dispatch = useDispatch()
  const form = colDef?.cellRendererParams?.form || null
  const field = colDef?.field || ''
  const propertyName = colDef?.cellRendererParams?.propertyName || null
  const rowId = data?.rowId
  const { rowIndex } = node
  const isEditing = colDef?.cellRendererParams?.isEditing || false

  const toggleCheckBox = debounce(e => {
    if (field && propertyName) {
      dispatch(
        toggleCheckboxCell(form, {
          propertyName,
          rowIndex,
          field,
          value: !value
        })
      )
    }
  }, 300)

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <IconButton disabled={!isEditing} onClick={toggleCheckBox} tabIndex={-1}>
        <Icon
          style={{
            color: !isEditing ? '#999' : '#517b9c',
            display: 'inline-block',
            fontSize: 16
          }}
        >
          {value ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
      </IconButton>
    </div>
  )
}

export default React.memo(CheckboxCell)
