import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getAnalysisMeta = createAsyncFormAction(
  CONSTANTS.GET_ANALYSIS_META
)

export const getAnalysisRead = createAsyncFormAction(
  CONSTANTS.GET_ANALYSIS_READ
)

export const updateTransactions = createAsyncFormAction(
  CONSTANTS.UPDATE_TRANSACTIONS
)

export const getDocSpec = createAsyncFormAction(CONSTANTS.GET_DOCSPEC)

export const getPurchaseHistory = createAsyncFormAction(
  CONSTANTS.GET_PURCHASE_HISTORY
)

export const getSDS = createAsyncFormAction(CONSTANTS.GET_SDS)

export const openReportViewer = createAsyncFormAction(
  CONSTANTS.OPEN_REPORT_VIEWER
)

export const openPurchaseHistory = createAsyncFormAction(
  CONSTANTS.OPEN_PURCHASE_HISTORY
)

export const getDemand = createAsyncFormAction(CONSTANTS.GET_DEMAND)

export const updateDemand = createAsyncFormAction(CONSTANTS.UPDATE_DEMAND)

export const clearUpdateDemandModal = (form, propertyName) =>
  createAction(CONSTANTS.CLEAR_UPDATE_DEMAND_MODAL, { propertyName }, form)

export const getBins = createAsyncFormAction(CONSTANTS.GET_BINS)

// export const getCellTooltip = createAsyncFormAction(
//   CONSTANTS.GET_CELL_TOOLTIP
// )

export const openFifoCost = createAsyncFormAction(CONSTANTS.OPEN_FIFO_COST)

export const getTabData = createAsyncFormAction(CONSTANTS.GET_TAB_DATA)

// export const setDefaultGridSelection = (form, { dataId, propertyName }) =>
//   createAction(
//     CONSTANTS.SET_DEFAULT_GRID_SELECTION,
//     { dataId, propertyName },
//     form
//   )

export const calculateRollupPrice = (
  form,
  { rollupId, rowIndex, value, route }
) =>
  createAction(
    CONSTANTS.CALCULATE_ROLLUP_PRICE.TRY,
    { rollupId, rowIndex, value, route },
    form
  )

export const setRollupAmount = (form, { rowIndex, value }) =>
  createAction(CONSTANTS.SET_ROLLUP_AMOUNT, { rowIndex, value }, form)

export const showPriceGp = createAsyncFormAction(CONSTANTS.SHOW_PRICE_GP)

export const hideShowPriceGP = form =>
  createAction(CONSTANTS.SET_SHOW_PRICE_GP, {}, form)

export const uomGridDNDUpdate = (form, { rowData }) =>
  createAction(CONSTANTS.UOM_GRID_DND_UPDATE, { rowData }, form)

export const updatePrices = createAsyncFormAction(CONSTANTS.UPDATE_PRICES)

export const saveIndexSearchDescription = (form, { label, value }) =>
  createAction(CONSTANTS.SAVE_INDEX_SEARCH_DESCRIPTION, { label, value }, form)

export const enableForecastFreeze = (form, { rowIndex }) =>
  createAction(CONSTANTS.ENABLE_FORECAST_FREEZE, { rowIndex }, form)

export const readDataHelper = (form, { data }) =>
  createAction(CONSTANTS.READ_DATA_HELPER, { data }, form)

export const handleBinData = (form, { binData, allowRevert }) =>
  createAction(CONSTANTS.HANDLE_BIN_DATA, { binData, allowRevert }, form)

export const enableBinsGrid = form =>
  createAction(CONSTANTS.ENABLE_BINS_GRID, {}, form)

export const handleAnalysisBinData = (form, { binData, allowRevert }) =>
  createAction(
    CONSTANTS.HANDLE_ANALYSIS_BIN_DATA,
    { binData, allowRevert },
    form
  )

export const fieldUpdate = createAsyncFormAction(CONSTANTS.FIELD_UPDATE)

export const changeRollUp = createAsyncFormAction(CONSTANTS.CHANGE_ROLLUP)

export const clearRollUpCalculationError = form =>
  createAction(CONSTANTS.CLEAR_ROLLUP_CALCULATION_ERROR, {}, form)

export const saveProductAssemblyNotes = createAsyncFormAction(
  CONSTANTS.SAVE_PRODUCT_ASSEMBLY_NOTES
)

export const openProductScreenFromModal = (form, { dataId, ...rest }) =>
  createAction(
    CONSTANTS.OPEN_PRODUCT_SCREEN_FROM_MODAL,
    { ...rest, dataId },
    form
  )

export const validateUOMs = form =>
  createAction(CONSTANTS.VALIDATE_UOMS, {}, form)

export const setPrimaryVendor = (form, { rowIndex }) =>
  createAction(CONSTANTS.SET_PRIMARY_VENDOR, { rowIndex }, form)

export const setSelectedGroup = (form, data) =>
  createAction(CONSTANTS.SET_SELECTED_GROUP, data, form)

export const addNewGroup = form =>
  createAction(CONSTANTS.ADD_NEW_GROUP, {}, form)

export const saveProduct = createAsyncFormAction(CONSTANTS.SAVE_PRODUCT)

export const openWebDescriptionEditor = form =>
  createAction(CONSTANTS.OPEN_WEB_DESCRIPTION_EDITOR, {}, form)

export const toggleCheckbox = (form, data) =>
  createAction(CONSTANTS.TOGGLE_CHECK_BOX, data, form)

export const deleteProduct = createAsyncFormAction(CONSTANTS.DELETE_PRODUCT)

export const toggleAllFields = createAsyncFormAction(
  CONSTANTS.TOGGLE_ALL_FIELDS
)

export const createNewProduct = createAsyncFormAction(
  CONSTANTS.CREATE_NEW_PRODUCT
)

export const setNewMode = (form, val) =>
  createAction(CONSTANTS.SET_NEW_MODE, val, form)

export const getProduct = createAsyncFormAction(CONSTANTS.GET_PRODUCT)

export const cancelCreate = createAsyncFormAction(CONSTANTS.CANCEL_CREATE)

export const setPropertyValue = (form, data) =>
  createAction(CONSTANTS.SET_PROPERTY_VALUE, data, form)

export const setPropertyDescription = (form, { propertyName, description }) =>
  createAction(
    CONSTANTS.SET_PROPERTY_DESCRIPTION,
    { propertyName, description },
    form
  )

export const getProductUOM = createAsyncFormAction(CONSTANTS.GET_PRODUCT_UOM)

export const openCopyProductModal = createAsyncFormAction(
  CONSTANTS.OPEN_COPY_PRODUCT_MODAL
)

export const updateCopyFromSearchDescriptions = createAsyncFormAction(
  CONSTANTS.UPDATE_COPY_FROM_SEARCH_DESCRIPTIONS
)

export const setCopyFrom = createAsyncFormAction(CONSTANTS.SET_COPY_FROM)

export const cancelCopyFrom = form =>
  createAction(CONSTANTS.CANCEL_COPY_FROM, {}, form)

export const launchBinSearchModal = form =>
  createAction(CONSTANTS.LAUNCH_BIN_SEARCH_MODAL, {}, form)

export const setPrimaryBinCheckbox = (form, { propertyName, rowIndex }) =>
  createAction(
    CONSTANTS.SET_PRIMARY_BIN_CHECKBOX,
    { propertyName, rowIndex },
    form
  )

export const setPrimaryBin = form =>
  createAction(CONSTANTS.SET_PRIMARY_BIN, {}, form)

export const cancelSetPrimaryBin = form =>
  createAction(CONSTANTS.CANCEL_SET_PRIMARY_BIN, {}, form)

export const saveVendorsData = createAsyncFormAction(
  CONSTANTS.SAVE_VENDORS_DATA
)

export const setUOMDefaults = (form, { rowIndex }) =>
  createAction(CONSTANTS.SET_UOM_DEFAULTS, { rowIndex }, form)

export const launchAnalysisBinsEditor = form =>
  createAction(CONSTANTS.LAUNCH_ANALYSIS_BINS_EDITOR, {}, form)

export const showCannotChangePrimaryBinModal = form =>
  createAction(CONSTANTS.SHOW_CANNOT_CHANGE_PRIMARY_BIN_MODAL, {}, form)

export const validateBins = (form, { propertyName }) =>
  createAction(CONSTANTS.VALIDATE_BINS, { propertyName }, form)

/* for Inventory Bin Assignment */
export const lockInventoryBinAssignment = createAsyncFormAction(
  CONSTANTS.LOCK_INVENTORY_BIN_ASSIGNMENT
)
export const unlockInventoryBinAssignment = createAsyncFormAction(
  CONSTANTS.UNLOCK_INVENTORY_BIN_ASSIGNMENT
)

export const saveInventoryBinAssignment = createAsyncFormAction(
  CONSTANTS.SAVE_INVENTORY_BIN_ASSIGNMENT
)

export const changeInventoryBinAssignment = createAsyncFormAction(
  CONSTANTS.CHANGE_INVENTORY_BIN_ASSIGNMENT
)

export const setPrimaryAssignmentBin = (form, { rowIndex }) =>
  createAction(CONSTANTS.SET_PRIMARY_ASSIGNMENT_BIN, { rowIndex }, form)

export const updateAnalysisBins = createAsyncFormAction(
  CONSTANTS.UPDATE_ANALYSIS_BINS
)

export const cancelUpdateAnalysisBins = (form, { bins }) =>
  createAction(CONSTANTS.CANCEL_UPDATE_ANALYSIS_BINS, { bins }, form)

export const loadCustomerRecord = form =>
  createAction(CONSTANTS.LOAD_CUSTOMER_RECORD, {}, form)

export const validateIsKitProduction = createAsyncFormAction(
  CONSTANTS.VALIDATE_IS_KIT_PRODUCTION
)

export const getUOMForProductGrid = createAsyncFormAction(
  CONSTANTS.GET_UOM_FOR_PRODUCT_GRID
)

export const launchAssemblyNotesModal = (form, { propertyName, rowIndex }) =>
  createAction(CONSTANTS.LAUNCH_NOTES_MODAL, { propertyName, rowIndex }, form)

export const clearCustomerPricingFields = form =>
  createAction(CONSTANTS.CLEAR_CUSTOMER_PRICING_FIELDS, {}, form)

export const clearVendorsIsPendingFlag = form =>
  createAction(CONSTANTS.CLEAR_VENDORS_IS_PENDING_FLAG, {}, form)

export const validateWarehouseReplenishments = createAsyncFormAction(
  CONSTANTS.VALIDATE_WAREHOUSE_REPLENISHMENTS
)

export const exitInventoryBinAssignmentEditor = form =>
  createAction(CONSTANTS.EXIT_INVENTORY_BIN_ASSIGNMENT_EDITOR, {}, form)

export const getProductFields = createAsyncFormAction(
  CONSTANTS.GET_PRODUCT_FIELDS
)

export const removeTrackedTabs = (form, { tabs = [] }) =>
  createAction(CONSTANTS.REMOVE_TRACKED_TABS, { tabs }, form)

export const updateEditedFields = (form, { field }) =>
  createAction(CONSTANTS.UPDATE_EDITED_FIELDS, { field }, form)

export const cancelPreNewMode = form =>
  createAction(CONSTANTS.CANCEL_PRE_NEW_MODE, {}, form)

export const setAutoAddToSO = (form, { rowIds = [] }) =>
  createAction(CONSTANTS.SET_AUTO_ADD_TO_SO, { rowIds }, form)

export const onPropertyChange = createAsyncFormAction(
  CONSTANTS.ON_PROPERTY_CHANGE
)

export const getInventoryData = createAsyncFormAction(
  CONSTANTS.GET_INVENTORY_DATA
)

export const setSelectedInventoryTab = (form, { tab }) =>
  createAction(CONSTANTS.SET_SELECTED_INVENTORY_TAB, { tab }, form)

export const exitScreenFromModal = (form, { modalId }) =>
  createAction(CONSTANTS.EXIT_SCREEN_FROM_MODAL, { modalId }, form)
