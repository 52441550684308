import React from 'react'
import { getValue } from 'utils'
import { withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import {
  alignRight,
  cellLabelStyle,
  rightSidebarStyle
} from 'pages/SalesOrder/tabs/Shipments/utils'

const Picked = ({
  defaultExpanded = false,
  isExpandable = false,
  style = {
    height: 'calc(100% - 10px)',
    margin: 5,
    width: 'calc(100% - 10px)'
  },
  pallets,
  pieces,
  weight
}) => (
  <DDICardWrapper
    title="Picked"
    style={style}
    contentStyle={{ padding: 0 }}
    defaultExpanded={defaultExpanded}
    isExpandable={isExpandable}
  >
    <Table>
      <TableBody>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Pieces</TableCell>
          <TableCell style={alignRight}>{pieces}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Weight</TableCell>
          <TableCell style={alignRight}>{weight}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Pallets</TableCell>
          <TableCell style={alignRight}>{pallets}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </DDICardWrapper>
)

export default withContext({
  pallets: form => getValue(form, 'pallets', ''),
  pieces: form => getValue(form, 'pieces', ''),
  weight: form => getValue(form, 'weight', '')
})(Picked)
