import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  delay
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, is } from 'utils'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'

import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, confirm, canceled, removeModal } from 'modals/actions'
import { CANCELED, CONFIRMED } from 'modals/constants'

import SerialNumberEditorModal from 'pages/SalesOrder/components/SerialNumberEditorModal'
import {
  MODIFY_LINE_ITEM_SERIAL_NUMBER,
  HANDLE_SERIAL_NUMBER_REVIEW_CHANGE
} from 'pages/SalesOrder/constants'
import {
  modifyLineItemSerialNumber,
  onPropertyChange,
  destroySerialNumberFields,
  handleSerialNumberReviewChange
} from 'pages/SalesOrder/actions'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { validateSalesOrderProcess } from '.'
import { handleSerialNumberChange as handleSerialNumberChangeAPI } from '../api'
// import {
//   changeGridItem as changeGridItemAPI,
//   validateSalesOrder as validateSalesOrderAPI
// } from '../api'

export function* openSerialNumberEditor(
  form,
  data = {},
  initialRequestParams = null
) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  yield put(destroySerialNumberFields(form))

  const modalOpts = {
    component: SerialNumberEditorModal,
    options: {
      actions: false,
      title: 'Serial Number Review',
      data: {
        form,
        initialRequestParams,
        isMobile,
        lineHeaderDisplay: data?.lineHeaderDisplay,
        ...data?.data
      },
      fullScreen: true,
      marginTop: '0px',
      maxHeight: '95%',
      width: '95%',
      maxWidth: '95%'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* modifyLineItemSerialNumberProcess(
  form,
  apiParams = {},
  rowId,
  thunk
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const groupNames = ['header', 'detail']

  yield put(modifyLineItemSerialNumber.request(form))

  debugger
  const { response, error } = yield call(handleSerialNumberChangeAPI, {
    guid,
    groupNames,
    gridName: 'lineItems',
    ...apiParams
  })

  if (response) {
    yield put({
      type: MODIFY_LINE_ITEM_SERIAL_NUMBER.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        activeLineNumber: apiParams?.lineNumber,
        activeRowId: rowId
      }
    })

    yield put(
      onPropertyChange.success(
        {
          propertyChanged: 'bins',
          ...response
        },
        {
          form
        }
      )
    )
  } else {
    debugger
    yield put({
      type: MODIFY_LINE_ITEM_SERIAL_NUMBER.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error,
        propertyChanged: 'bins'
      }
    })
  }
}

export function* modifyLineItemSerialNumberListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { apiParams = {}, rowId }
    } = yield take(MODIFY_LINE_ITEM_SERIAL_NUMBER.TRY)

    if (form === formListener) {
      debugger
      yield fork(
        modifyLineItemSerialNumberProcess,
        form,
        apiParams,
        rowId,
        thunk
      )
    }
  }
}

export function* handleSerialNumberReviewChangeProcess(
  form,
  thunk,
  apiParams = {},
  modalId
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(handleSerialNumberReviewChange.request(form))

  // debugger
  const { response, error } = yield call(handleSerialNumberChangeAPI, {
    dataId,
    guid,
    ...apiParams
  })

  if (response) {
    yield put({
      type: HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        apiParams
      }
    })

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put({
      type: HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error
      }
    })
  }
}

export function* confirmSerialNumberReviewSaveAttempt(
  form,
  apiParams,
  modalId,
  thunk
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')

  const isIncomplete =
    getIn(formState, 'values.serialNumberIsIncomplete') || false

  if (isIncomplete) {
    yield call(confirmationModal, 'Continue anyway?', 'Incomplete data')

    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }
  }

  yield put(handleSerialNumberReviewChange.request(form))

  const { response, error } = yield call(handleSerialNumberChangeAPI, {
    dataId,
    guid,
    ...apiParams
  })

  if (response) {
    yield put({
      type: HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        apiParams
      }
    })

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put({
      type: HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error
      }
    })
  }
}

export function* handleSerialNumberReviewChangeListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { apiParams, modalId }
    } = yield take(HANDLE_SERIAL_NUMBER_REVIEW_CHANGE.TRY)

    if (form === formListener) {
      if (apiParams?.action && apiParams?.action === 'commit') {
        yield fork(
          confirmSerialNumberReviewSaveAttempt,
          form,
          apiParams,
          modalId,
          thunk
        )
      } else {
        yield fork(
          handleSerialNumberReviewChangeProcess,
          form,
          thunk,
          apiParams,
          modalId
        )
      }
    }
  }
}

export function* openSerialNumberEditorProcess(form) {
  const formState = yield select(getFormSelector(form))

  yield put(actions.openSerialNumberEditor.request(form))

  const { response, error } = yield call(handleSerialNumberChangeAPI, {
    action: 'open'
  })

  if (response) {
    debugger
    yield put(actions.openSerialNumberEditor.success(response, form))
    yield fork(openSerialNumberEditor, form, response)
  } else {
    yield put(actions.openSerialNumberEditor.failure(error, form))
  }
}

export function* openSerialNumbersEditorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.OPEN_SERIAL_NUMBER_EDITOR.TRY)

    if (form === formListener) {
      yield fork(openSerialNumberEditorProcess, form)
    }
  }
}

export default function* serialNumberSagas(form) {
  yield fork(modifyLineItemSerialNumberListener, form)
  yield fork(handleSerialNumberReviewChangeListener, form)
  yield fork(openSerialNumbersEditorListener, form)
}
