import React from 'react'
import memoize from 'memoize-one'
import { withStyles } from '@material-ui/core/styles'
import {
  ExpansionPanel as MuiExpansionPanel,
  ExpansionPanelDetails as MuiExpansionPanelDetails,
  ExpansionPanelSummary as MuiExpansionPanelSummary,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { fromJS, getIn, setIn, getRgb, is, plainDeepEqual } from 'utils'

export const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiExpansionPanel)

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 30,
    padding: '0 14px',
    '&$expanded': {
      minHeight: 30
    }
  },
  content: {
    margin: '5px 0',
    '&$expanded': {
      margin: '5px 0'
    }
  },
  expandIcon: {
    padding: 0
  },
  expanded: {}
})(MuiExpansionPanelSummary)

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 10
  }
}))(MuiExpansionPanelDetails)

export const getClientHeight = () =>
  window?.innerHeight ||
  document?.documentElement?.clientHeight ||
  document?.body?.clientHeight

export function getMobileGridHeight(gridApi) {
  let height = 440

  if (gridApi && gridApi.getDisplayedRowCount) {
    const numRows = gridApi.getDisplayedRowCount()
    if (is.number(numRows)) {
      const calculatedHeight = numRows * 28 + 70
      height = calculatedHeight >= 440 ? calculatedHeight : 440
      return height
    }

    return height
  }

  return height
}

export const makeRadioButtons = (data, name = 'groupBy') => {
  if (data && Array.isArray(data) && data.length) {
    return data.map((filter, idx) => (
      <FormControlLabel
        control={
          <Radio
            style={{
              color: filter.color,
              height: 24,
              marginRight: 5,
              width: 24
            }}
          />
        }
        style={{ paddingRight: 15, minWidth: 90 }}
        value={filter.dataId}
        label={
          name === 'filterBy' ? (
            <span style={{ color: getRgb(filter.color) }}>
              {filter.description}
            </span>
          ) : (
            filter.description
          )
        }
      />
    ))
  }

  return []
}

export const styles = {
  flexLabels: {
    label: {
      flex: 1,
      order: 1,
      minWidth: 60,
      textAlign: 'right',
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: 0,
      marginRight: 10
    },
    input: { flex: 9, order: 2 },
    description: { order: 3 },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 15
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    margin: '0 10px 0 0',
    textAlign: 'right',
    width: 100
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0'
  }
}

export const storeInquiryParams = state => {
  state = setIn(
    state,
    'values.previous.customer',
    fromJS({
      value: getIn(state, 'fields.customer.value'),
      description: getIn(state, 'fields.customer.description')
    })
  )

  state = setIn(state, 'values.customer', getIn(state, 'fields.customer.value'))

  state = setIn(
    state,
    'values.previous.shipTo',
    fromJS({
      value: getIn(state, 'fields.shipTo.value'),
      description: getIn(state, 'fields.shipTo.description')
    })
  )

  state = setIn(state, 'values.shipTo', getIn(state, 'fields.shipTo.value'))

  state = setIn(
    state,
    'values.previous.cutOffDate',
    fromJS({
      value: getIn(state, `fields.cutOffDate.value`),
      description: null
    })
  )

  return state
}
