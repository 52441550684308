import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IconButton, Icon, Paper } from '@material-ui/core'
import { getIn, toCamelCase } from 'utils'
import { DDISelectField } from 'ddiForm/wrapped'
import { toggleCriteriaSelection } from '../actions'
import SelectionGrid from './SelectionGrid'

const mapDispatchToProps = dispatch => ({
  toggleCriteriaSelection: (form, { dataId }) =>
    dispatch(toggleCriteriaSelection(form, { dataId }))
})

// const mapStateToProps = (state, ownProps) => {
//   const equalToCriterias = getIn(state, 'ddiForm.customerMaster.values.selectionCriteria.equalToCriterias')
//   return {
//     instanceData: equalToCriterias ? equalToCriterias.toJS() : []
//   }
// }

class SelectionCard extends Component {
  static propTypes = {
    cardData: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    toggleCriteriaSelection: PropTypes.func.isRequired
  }

  toggleCriteriaSelection = () => {
    const {
      cardData: { dataId },
      form,
      toggleCriteriaSelection
    } = this.props
    toggleCriteriaSelection(form, { dataId })
  }

  render() {
    const { id, cardData, type, form } = this.props
    // console.log('cardData', cardData)
    return (
      <Paper className="ddi-card clear" style={{ height: 250, minWidth: 310 }}>
        <div className="ddi-card-heading" style={{ position: 'relative' }}>
          <h2>{cardData.description}</h2>
          <div style={{ position: 'absolute', right: 5, top: 2 }}>
            <IconButton
              onClick={this.toggleCriteriaSelection}
              style={{
                height: 24,
                padding: 5,
                width: 24
              }}
            >
              <Icon style={{ color: '#444', fontSize: 16 }}>close</Icon>
            </IconButton>
          </div>
        </div>
        <div className="ddi-card-content clear" style={{ padding: 0 }}>
          <div style={{ padding: 5 }}>
            <DDISelectField
              preventAutoDisable
              propertyName={`selectionCriteria.${type}.inputs.${toCamelCase(
                cardData.description
              )}`}
              fullWidth
              values={cardData.equalToOptions}
              // initialValue={cardData.defaultEqualToType}
              margin="dense"
              selectValue="dataId"
              selectDescription="description"
            />
          </div>
          <div style={{ maxWidth: '100%', width: '100%' }}>
            <SelectionGrid
              cardData={cardData}
              dataId={cardData.dataId}
              form={form}
              initialRowData={cardData.items}
              type={type}
              // toggleEqualToCriteria={toggleEqualToCriteria}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SelectionCard)
