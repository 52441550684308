/* eslint react/sort-comp: 0 */
import React from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { Popover } from '@material-ui/core'
import { fromJS, getIn, plainDeepEqual } from 'utils'
import { isEqual, once } from 'lodash'
import { DDILotSearch, DDISerialNumberSearch } from 'ddiForm/wrapped'

import SerialNumberGrid from './components/SerialNumberGrid'

export const getSerialNumberData = memoize(
  (additionalDataMap = fromJS({}), rowId) =>
    additionalDataMap
      ?.get(rowId)
      ?.get('bins')
      ?.toJS() || [{ dataId: null, lots: null, serialNumbers: [] }],
  plainDeepEqual
)

export const getSerialNumberEditorData = memoize(
  (additionalDataMap = fromJS({}), rowId) =>
    additionalDataMap
      ?.get(rowId)
      ?.get('serialNumberEditorData')
      ?.toJS() || {
      excludedSerialNumbers: [],
      includedSerialNumbers: [],
      serialNumberSearchType: 'A',
      transactionType: 'S'
    },
  plainDeepEqual
)

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const additionalDataMap = getIn(formState, 'additionalDataMap') || fromJS({})
  const isEditing = getIn(formState, 'isEditing') || false
  const formModals = getIn(formState, 'modals')
  let modals = getIn(state, 'modals')
  modals = modals.merge(formModals).toList()

  return {
    additionalDataMap,
    isEditing,
    activeModals: Boolean(modals.size)
  }
}

class SerialNumberDropdown extends React.Component {
  static defaultProps = {
    style: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%'
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      open: true,
      inquiryMode: props.hasRecord && !props.isEditing
    }
    this.tabCount = 0
  }

  componentDidMount() {
    if (this.state.inquiryMode) {
      window.addEventListener('keydown', this.onKeyup)
    } else {
      window.addEventListener('keydown', this.onKeyupCount)
    }
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
    clearTimeout(this.timer)
    if (this.state.inquiryMode) {
      window.removeEventListener('keydown', this.onKeyup)
    } else {
      window.removeEventListener('keydown', this.onKeyupCount)
    }
  }

  onKeyup = event => {
    if (event.key === 'Tab') {
      this.tabToNext(this.props.api, true, event.shiftKey)
    }
  }

  onKeyupCount = event => {
    if (event.key === 'Tab' && !this.props.activeModals) {
      this.tabCount += 1
      this.timer = setTimeout(() => (this.tabCount = 0), 500)
      if (this.tabCount >= 2) {
        if (this._mounted) {
          this.tabToNext(this.props.api, true, event.shiftKey)
        }
      }
    } else {
      this.tabCount = 0
    }
  }

  edited = false

  setEdited = once(() => {
    this.edited = true
  })

  getValue() {
    return this.props.value
  }

  tabToNext = (api, wasBlurred, shiftKey) => {
    const { props } = this
    // console.log(wasBlurred)
    if (typeof wasBlurred !== 'boolean') {
      wasBlurred = false
    }

    this.setState({ open: false }, () => {
      api.stopEditing()
      // if (wasBlurred && props.data.promptForPrice && api.startEditingCell) {
      if (wasBlurred) {
        setTimeout(
          () => api[shiftKey ? 'tabToPreviousCell' : 'tabToNextCell'](),
          0
        )
      }
      // }
    })
  }

  handleClose = async (wasBlurred, shiftKey) => {
    const { dispatch, form, api } = this.props

    // if (this.edited) {
    //   await dispatch(confirmProvisionalChanges.try(form))
    // }
    api.stopEditing(true)
    this.tabToNext(api, wasBlurred, shiftKey)
  }

  render() {
    const { additionalDataMap } = this.props
    const serialNumberData = getSerialNumberData(
      additionalDataMap,
      this.props.data.rowId
    )

    const serialNumberEditorData = getSerialNumberEditorData(
      additionalDataMap,
      this.props.data.rowId
    )

    const {
      excludedSerialNumbers = [],
      includedSerialNumbers = [],
      serialNumberSearchType = 'A',
      transactionType = 'S'
    } = serialNumberEditorData

    const lotSearchType = this?.props?.data?.lotSearchType || '\u0000'

    const usesLotsEditor = this?.props?.data?.useLotsResolved || false

    const lotsEditorMeta = usesLotsEditor
      ? {
          suppressCreateNewLotInSearch:
            this?.props?.data?.suppressCreateNewLotInSearch || false,
          allowInvalidLots: this?.props?.data?.allowInvalidLots || false,
          searchType: lotSearchType,
          availableLotsOnly: this?.props?.data?.availableLotsOnly || false
        }
      : {}

    return (
      <Popover
        open={this.state.open}
        anchorEl={this.props.eGridCell}
        onClose={this.handleClose}
      >
        <div style={this.props.style}>
          <SerialNumberGrid
            dataId={this.props.data.dataId}
            warehouseId={this.props.data.warehouseIdResolved}
            handleClose={this.handleClose}
            dispatch={this.props.dispatch}
            form={this.props.form}
            lineNumber={this.props.data.lineNumber}
            rowId={this.props.data.rowId}
            edited={this.edited}
            setEdited={this.setEdited}
            inquiryMode={this.state.inquiryMode}
            serialNumberData={serialNumberData}
            excludedSerialNumbers={excludedSerialNumbers}
            includedSerialNumbers={includedSerialNumbers}
            serialNumberSearchType={serialNumberSearchType}
            transactionType={transactionType}
            SearchComponent={
              usesLotsEditor ? DDILotSearch : DDISerialNumberSearch
            }
            quantity={this.props.data.quantity}
            usesLotsEditor={usesLotsEditor}
            lotsEditorMeta={lotsEditorMeta}
          />
        </div>
      </Popover>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SerialNumberDropdown)
// export default QuantityCellWrapper
