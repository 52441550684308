import React from 'react'
import {
  // Table as MuiTable,
  // TableBody as MuiTable,
  TableCell as MuiTableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const rightSidebarStyle = {
  height: 'calc(100% - 10px)',
  margin: 5,
  width: 'calc(100% - 10px)',
}

export const alignRight = { textAlign: 'right' }
export const cellLabelStyle = { fontWeight: 500 }

export const TableCell = withStyles({
  root: {
    padding: '2px 10px'
  }
})(MuiTableCell)

export const TableRow = withStyles({
  root: {
    '&:nth-child(odd)': {
      background: '#fff'
    }
  }
})(MuiTableRow)

