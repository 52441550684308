import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import Icon from '@material-ui/core/Icon'

export default function Alert(props) {
  const [open, setOpen] = React.useState(true)
  return (
    <Collapse in={open}>
      <MuiAlert
        elevation={6}
        variant="filled"
        style={{ width: 360, marginTop: 5 }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false)
            }}
          >
            <Icon fontSize="inherit">close</Icon>
          </IconButton>
        }
        sx={{ mb: 2 }}
        {...props}
      />
    </Collapse>
  )
}
