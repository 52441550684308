/* eslint import/prefer-default-export: 0 */

import React from 'react'
import {
  Paper,
  CircularProgress,
  LinearProgress,
  FormControlLabel
} from '@material-ui/core'
import Radio from '@material-ui/core/Radio'

export const makeRadioButtons = data =>
  data.map((item, idx) => (
    <FormControlLabel
      control={
        <Radio
          style={{
            height: 24,
            marginRight: 5,
            width: 24
          }}
        />
      }
      style={{ paddingRight: 15, minWidth: 90 }}
      value={item.dataId}
      label={item.description}
    />
  ))

export const processOptions = [
  { dataId: 'false', description: 'Immediately' },
  { dataId: 'true', description: 'Overnight' }
]
