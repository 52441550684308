import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getField, getIn, getValue, emptyList, deepEqual } from 'utils'
import { is } from 'immutable'

import * as actions from 'pages/SalesOrder/actions'

export const RecalculateModalActions = ({
  dispatch,
  form,
  modal,
  guid,
  rollups,
  ...props
}) => {
  const [initialRow, setInitialRow] = useState(emptyList)
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    setInitialRow(rollups)
  }, [])

  useEffect(() => {
    const isTheSame = is(initialRow, rollups)
    setHasChanged(!isTheSame)
  }, [initialRow, rollups])

  const exitModal = () => {
    const { id } = modal
    dispatch(actions.closeRecalculatePrices.try(form, { guid, modalId: id }))
  }

  const handleRepeatAll = () =>
    dispatch(
      actions.repeatAllRecalculate.try(form, { propertyName: 'repeatall' })
    )

  const handleClearAll = () =>
    dispatch(
      actions.clearAllRecalculate.try(form, { propertyName: 'clearall' })
    )

  const handleRecalculate = () =>
    dispatch(actions.recalculatePrices.try(form, { guid, modalId: modal.id }))

  return (
    <>
      <div style={{ marginRight: 'auto' }}>
        <DDIButton
          onClick={handleRepeatAll}
          variant="contained"
          label="Repeat All"
          preventAutoDisable
          style={{
            marginRight: '5px'
          }}
          disabled={!hasChanged}
        />
        <DDIButton
          onClick={handleClearAll}
          variant="contained"
          label="Clear All"
          preventAutoDisable
          style={{
            marginRight: '5px'
          }}
          disabled={!rollups.toJS().length}
        />
      </div>
      <DDIButton
        onClick={handleRecalculate}
        variant="contained"
        label="Recalculate"
        preventAutoDisable
      />
      <DDIButton
        onClick={exitModal}
        variant="contained"
        label="Exit"
        preventAutoDisable
      />
    </>
  )
}

export const contextObj = {
  guid: form => getIn(form, 'guid') || null,
  rollups: form => getField(form, 'rollups', emptyList, true)
}

export default withContext(contextObj)(RecalculateModalActions)
