/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useRef } from 'react'
import { Popover, Button, Icon, IconButton, Paper } from '@material-ui/core'
import NumberField from './NumberField'

const PricingCalculator = ({
  form,
  dispatch,
  lineNumber,
  disabled,
  isComponent = false,
  data = {}
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [clearValueOnOpen, clearNumberFieldValue] = React.useState(false)
  const node = useRef()

  const checkForClickAway = e => {
    if (node.current.contains(e.target)) {
      return
    }

    setAnchorEl(null)
    clearNumberFieldValue(true)
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', checkForClickAway)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', checkForClickAway)
    }
  })

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    clearNumberFieldValue(false)
  }

  const open = Boolean(anchorEl)

  const flexStyle = { display: 'flex', padding: 5 }
  const labelStyle = { fontSize: 12, fontWeight: 400, margin: 0, width: 100 }

  return (
    <div style={{ minWidth: 25, position: 'relative' }}>
      <IconButton
        onClick={handleClick}
        disabled={disabled}
        style={{
          background: '#e9e9e9',
          padding: 3,
          position: 'absolute',
          top: -10,
          left: -1,
          zIndex: 1
        }}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Paper
        ref={node}
        style={{
          display: open ? 'block' : 'none',
          padding: 15,
          width: 300,
          position: 'absolute',
          top: 10,
          left: 0,
          zIndex: 2
        }}
      >
        <div style={flexStyle}>
          <label style={labelStyle}>Enter Desired Gross Profit</label>
          <div style={{ width: 150 }}>
            <NumberField
              propertyName="grossProfitPercent"
              form={form}
              dispatch={dispatch}
              fixedDecimalScale
              decimalScale={4}
              lineNumber={lineNumber}
              clearValueOnOpen={clearValueOnOpen}
              value=""
              defaultValue=""
              isComponent={isComponent}
              data={data}
            />
          </div>
        </div>
        <div style={flexStyle}>
          <label style={labelStyle}>Enter Desired Multiplier</label>
          <div style={{ width: 150 }}>
            <NumberField
              propertyName="multiplier"
              form={form}
              dispatch={dispatch}
              fixedDecimalScale
              decimalScale={4}
              lineNumber={lineNumber}
              clearValueOnOpen={clearValueOnOpen}
              value=""
              defaultValue=""
              isComponent={isComponent}
              data={data}
            />
          </div>
        </div>
        <div style={flexStyle}>
          <label style={labelStyle}>Enter CHAIN DISCOUNT</label>
          <div style={{ width: 150 }}>
            <NumberField
              propertyName="discountPercentChainDiscount"
              form={form}
              dispatch={dispatch}
              fixedDecimalScale
              decimalScale={4}
              lineNumber={lineNumber}
              allowSpecialCharacters
              clearValueOnOpen={clearValueOnOpen}
              value=""
              defaultValue=""
              isComponent={isComponent}
              data={data}
            />
          </div>
        </div>
        <p style={{ fontSize: 11, margin: '10px 0 0 5px' }}>
          <em>Seperated by slashes, Example 50/10/2-5</em>
        </p>
      </Paper>
    </div>
  )
}

export default PricingCalculator
