import React, { memo } from 'react'
import { withContext } from 'ddiForm'
import { getValue } from 'utils'
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell
} from '@material-ui/core'

const bold = { fontWeight: 'bold' }
const bgWhite = { background: '#fff' }

const WeightVolume = ({
  orderVolume,
  orderWeight,
  shippableVolume,
  shippableWeight
}) => (
  <Paper style={{ maxWidth: 400 }} square>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell>Order</TableCell>
          <TableCell>Shippable</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={bgWhite}>
          <TableCell>Weight</TableCell>
          <TableCell style={bold}>{orderWeight}</TableCell>
          <TableCell style={bold}>{shippableWeight}</TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Volume</TableCell>
          <TableCell style={bold}>{orderVolume}</TableCell>
          <TableCell style={bold}>{shippableVolume}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
)

const contextObj = {
  orderVolume: form => getValue(form, 'orderVolume', 0),
  orderWeight: form => getValue(form, 'orderWeight', 0),
  shippableVolume: form => getValue(form, 'shippableVolume', 0),
  shippableWeight: form => getValue(form, 'shippableWeight', 0)
}

/* should work as is SVE 9/25/20 */
export default memo(withContext(contextObj)(WeightVolume))
