import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import { withContext } from 'ddiForm'
import { fromJS, getIn, getValue, emptyList } from 'utils'
import { Link } from '@material-ui/core'

const CustomerInfo = props => {
  let { customerAddress } = props
  customerAddress =
    customerAddress && customerAddress?.toJS
      ? customerAddress.toJS()
      : {
          address1: '',
          address2: '',
          address3: '',
          cityStateZip: '',
          country: '',
          description: ''
        }

  const {
    address1,
    address2,
    address3,
    cityStateZip,
    country,
    description
  } = customerAddress

  const googleMapsLink = `https://www.google.com/maps?q=${address1}+${address2}+${cityStateZip}+${country}`.replace(
    ' ',
    '+'
  )
  return (
    <DDICardWrapper
      title="Customer"
      contentStyle={{ padding: 20, maxWidth: '500px' }}
    >
      <div>{description}</div>
      <Link
        style={{
          fontSize: '1.2rem'
        }}
        target="_blank"
        href={googleMapsLink}
        rel="noreferrer"
      >
        {address1}
      </Link>

      <div>{address2}</div>
      <div>{address3}</div>
      <div>{cityStateZip}</div>
      <div>{country}</div>
    </DDICardWrapper>
  )
}

export default withContext({
  customerAddress: form =>
    getValue(
      form,
      'customerAddress',
      fromJS({
        address1: '',
        address2: '',
        address3: '',
        cityStateZip: '',
        country: '',
        description: ''
      })
    )
})(CustomerInfo)
