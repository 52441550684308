import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { DDITextField } from 'ddiForm/wrapped'
import { setField } from 'ddiForm/actions'

const NewTemplateField = ({
  form,
  propertyName,
  templates = [],
  templateId
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    /* clear it out on load, if a template is selected */
    if (templates?.find(x => x?.dataId === templateId)) {
      dispatch(setField(form, propertyName, ''))
    }
  }, [])

  return (
    <DDITextField
      propertyName={propertyName}
      style={{ width: '100%' }}
      preventAutoDisable
      disableDoubleClickUnlock
    />
  )
}

export default NewTemplateField
