import memoize from 'memoize-one'
import { addScreenInModal } from 'ddiForm/actions'

import { invoiceInquiryInModal } from 'pages/InvoiceInquiry'
import { readInvoiceInquiry } from 'pages/InvoiceInquiry/api'
import { onOpenInvoiceInquiryInModal } from 'pages/InvoiceInquiry/sagas'

const addInvoiceInquiryInModal = memoize((form, dataId) =>
  addScreenInModal(form, {
    screen: invoiceInquiryInModal(`${form}.invoiceInquiry`),
    formName: `${form}.invoiceInquiry`,
    apiMethod: readInvoiceInquiry,
    apiArgs: {
      groupNames: ['header', 'detail'],
      dataId
    },
    onApiSuccess: onOpenInvoiceInquiryInModal,
    onApiError: error => console.log('fail', error),
    modalOptions: {
      hideActions: true,
      title: 'Invoice Inquiry',
      maxSize: true,
      modalOverrideClass: 'invoice-inquiry-in-modal'
    }
  })
)

export default addInvoiceInquiryInModal
