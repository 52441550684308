import React from 'react'
import { Set } from 'immutable'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { SET_FIELD } from 'ddiForm/constants'
import { getIn } from 'utils'
// import { clearOrder } from 'pages/SalesOrder/actions'

const LeftFooterButtons = ({
  form,
  lastInvoiceId,
  previousInvoices,
  dataId
}) => {
  const dispatch = useDispatch()
  const previousInvoiceId = dataId
    ? Array.from(previousInvoices)?.[1]
    : Array.from(previousInvoices)?.[0]

  const getLastInvoice = debounce(e => {
    if (previousInvoiceId !== dataId) {
      dispatch(resetMasterFields(form))
      dispatch({
        meta: { form, leader: true },
        payload: {
          deleteMeta: false,
          propertyName: 'dataId',
          value: previousInvoiceId
        },
        type: SET_FIELD
      })
    }
  }, 500)

  return (
    <>
      {previousInvoices?.size &&
      previousInvoiceId &&
      previousInvoiceId !== dataId ? (
        <DDIButton
          variant="contained"
          label="Last"
          preventAutoDisable
          onClick={getLastInvoice}
          style={{ marginRight: 5 }}
          tabIndex={0}
        />
      ) : null}
    </>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  lastInvoiceId: form => getIn(form, 'lastInvoiceId') || '',
  previousInvoices: form => getIn(form, 'previousInvoices') || Set()
})(LeftFooterButtons)
