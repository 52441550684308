import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, fromJS } from 'utils'
import { DDIDatePicker, DDISelectField, DDITextField } from 'ddiForm/wrapped'
import { required } from 'ddiForm/validations'
import ActionButtons from './components/ActionButtons'
import '../../styles/modal-flex-button-styles.scss'

const formatEmptySelect = (options = []) =>
  options?.reduce((acc, next) => {
    acc = acc.concat({
      ...next,
      dataId: next?.dataId ? next.dataId : 'none',
      description: next?.description ? next.description : 'None'
    })
    return acc
  }, []) || []

const EndUserTrackedFields = props => {
  const {
    data: {
      form,
      lineNumber,
      rebateInfo: {
        description,
        address1,
        city,
        state,
        postalCode,
        installDate,
        phoneNumber,
        constructionType,
        fuelType,
        ahriNumber,
        customerConfirmationCode,
        validationMessages = []
      }
    },
    descriptionFieldValue,
    id,
    isEditing
  } = props

  let { rebateConstructionOptions, rebateFuelOptions, rebateInfoFields } = props

  rebateConstructionOptions = rebateConstructionOptions?.toJS
    ? formatEmptySelect(rebateConstructionOptions.toJS())
    : []
  rebateFuelOptions = rebateFuelOptions?.toJS
    ? formatEmptySelect(rebateFuelOptions.toJS())
    : []
  rebateInfoFields = rebateInfoFields?.toJS ? rebateInfoFields.toJS() : {}

  const errorIconStyle = {
    bottom: 2,
    color: '#d9534f',
    height: 20,
    position: 'absolute',
    right: 0,
    width: 20,
    fontSize: 16,
    zIndex: 1
  }

  const requiredFieldValidation = {
    disabled: !descriptionFieldValue,
    validate: descriptionFieldValue ? [required] : null,
    errorIconStyle
  }

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div className="sales-order-entry-modal-primary-interface-wrapper">
        <div className="sales-order-entry-modal-form">
          <div className="form-row">
            <DDITextField
              propertyName="rebateInfo.description"
              label="Name"
              initialValue={description}
            />
            <DDITextField
              propertyName="rebateInfo.address1"
              label="Address"
              initialValue={address1}
              {...requiredFieldValidation}
            />
          </div>
          <div className="form-row">
            <DDITextField
              propertyName="rebateInfo.city"
              label="City"
              initialValue={city}
              {...requiredFieldValidation}
            />
            <DDITextField
              propertyName="rebateInfo.state"
              label="State"
              initialValue={state}
              wrapperStyle={{ maxWidth: 150, position: 'relative' }}
              {...requiredFieldValidation}
            />
            <DDITextField
              propertyName="rebateInfo.postalCode"
              label="Zip Code"
              initialValue={postalCode}
              {...requiredFieldValidation}
            />
          </div>
          <div className="form-row">
            <DDIDatePicker
              propertyName="rebateInfo.installDate"
              label="Date"
              views={['date']}
              initialValue={installDate}
              {...requiredFieldValidation}
              errorIconStyle={{
                ...errorIconStyle,
                right: 30
              }}
            />
            <DDITextField
              propertyName="rebateInfo.phoneNumber"
              label="Phone"
              mask="phoneNumber"
              initialValue={phoneNumber}
              {...requiredFieldValidation}
            />
          </div>
          <div className="form-row">
            <DDISelectField
              propertyName="rebateInfo.constructionType"
              fullWidth
              values={rebateConstructionOptions}
              label="Construction Type"
              initialValue={constructionType}
              disabled={!descriptionFieldValue}
              displayEmpty
            />
            <DDISelectField
              propertyName="rebateInfo.fuelType"
              fullWidth
              values={rebateFuelOptions}
              label="Fuel"
              initialValue={fuelType}
              disabled={!descriptionFieldValue}
              displayEmpty
            />
          </div>
          <div className="form-row">
            <DDITextField
              propertyName="rebateInfo.ahriNumber"
              label="AHRI Number"
              initialValue={ahriNumber}
              disabled={!descriptionFieldValue}
            />
            <DDITextField
              propertyName="rebateInfo.customerConfirmationCode"
              label="Customer Confirmation Code"
              initialValue={customerConfirmationCode}
              disabled={!descriptionFieldValue}
            />
          </div>
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        lineNumber={lineNumber}
        description={descriptionFieldValue}
        rebateInfoFields={rebateInfoFields}
        isEditing={isEditing}
      />
    </div>
  )
}

export default withContext({
  descriptionFieldValue: form =>
    getIn(form, 'fields.rebateInfo.description.value') || '',
  rebateInfoFields: form => getIn(form, 'fields.rebateInfo') || fromJS({}),
  rebateConstructionOptions: form =>
    getIn(form, 'meta.rebateConstructionOptions') || fromJS([]),
  rebateFuelOptions: form =>
    getIn(form, 'meta.rebateFuelOptions') || fromJS([]),
  isEditing: form => getIn(form, 'isEditing') || false
})(EndUserTrackedFields)
