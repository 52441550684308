import React, { useState } from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { formatNumber, getValue, getField } from 'utils'

import {
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody
} from '@material-ui/core'

import FreightFields from './components/FreightFields'
import PopoverEditor from './components/PopoverEditor'
import MiscChargeGrid from '../PaymentMethods/components/MiscChargeGrid'

const cellStyle = { textAlign: 'right' }
const totalsCellStyle = {
  background: '#444',
  color: '#fff',
  textAlign: 'right'
}

const OrderTotals = ({
  form,
  dispatch,
  openTotal,
  openSubTotal,
  orderTaxAmount,
  miscellaneousChargeTotal,
  shippableTotal,
  shippableSubtotal,
  shippableTaxAmount,
  freight,

  shippedFreight,
  shippedMiscCharge,
  shippedSubTotal,
  shippedTax,
  shippedTotal,

  backOrderAmount,

  orderSubTotal,
  orderFreight,
  orderMiscCharge,
  orderTax,
  orderTotal,
  isEditing
}) => (
  <Paper style={{ maxWidth: 650 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell style={cellStyle}>Open</TableCell>
          <TableCell style={cellStyle}>Shippable</TableCell>
          <TableCell style={cellStyle}>Back Ordered</TableCell>
          <TableCell style={cellStyle}>Shipped</TableCell>
          <TableCell style={cellStyle}>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow style={{ background: '#fff' }}>
          <TableCell>Subtotal:</TableCell>
          <TableCell style={cellStyle}>{formatNumber(openSubTotal)}</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippableSubtotal)}
          </TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(backOrderAmount)}
          </TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippedSubTotal)}
          </TableCell>
          <TableCell style={cellStyle}>{formatNumber(orderSubTotal)}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell>Freight</TableCell>
          <TableCell style={cellStyle}>
            <PopoverEditor
              displayValue={formatNumber(freight)}
              popoverContent={<FreightFields />}
              isEditing={isEditing}
            />
          </TableCell>
          <TableCell style={cellStyle}>{formatNumber(freight)}</TableCell>
          <TableCell style={cellStyle}>&nbsp;</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippedFreight)}
          </TableCell>
          <TableCell style={cellStyle}>{formatNumber(orderFreight)}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell>Misc. Charge</TableCell>
          <TableCell style={cellStyle}>
            <PopoverEditor
              displayValue={formatNumber(miscellaneousChargeTotal)}
              popoverContent={
                <MiscChargeGrid form={form} dispatch={dispatch} />
              }
              popoverContentStyle={{ minWidth: 450, padding: 10 }}
              isEditing={isEditing}
            />
          </TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(miscellaneousChargeTotal)}
          </TableCell>
          <TableCell style={cellStyle}>&nbsp;</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippedMiscCharge)}
          </TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(orderMiscCharge)}
          </TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell>Tax:</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(orderTaxAmount)}
          </TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippableTaxAmount)}
          </TableCell>
          <TableCell style={cellStyle}>&nbsp;</TableCell>
          <TableCell style={cellStyle}>{formatNumber(shippedTax)}</TableCell>
          <TableCell style={cellStyle}>{formatNumber(orderTax)}</TableCell>
        </TableRow>

        <TableRow style={{ background: '#444' }}>
          <TableCell style={{ ...totalsCellStyle, textAlign: 'left' }}>
            Total:
          </TableCell>
          <TableCell style={totalsCellStyle}>
            {formatNumber(openTotal)}
          </TableCell>
          <TableCell style={totalsCellStyle}>
            {formatNumber(shippableTotal)}
          </TableCell>
          <TableCell style={totalsCellStyle}>
            {formatNumber(backOrderAmount)}
          </TableCell>
          <TableCell style={totalsCellStyle}>
            {formatNumber(shippedTotal)}
          </TableCell>
          <TableCell style={totalsCellStyle}>
            {formatNumber(orderTotal)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Paper>
)

/* fixed 9/25/2020 SVE */
export default withContext({
  freight: form => getValue(form, 'freight', 0),
  openTotal: form => getValue(form, 'openTotal', 0),
  openSubTotal: form => getValue(form, 'openSubTotal', 0),
  orderTaxAmount: form => getValue(form, 'orderTaxAmount', 0),
  miscellaneousChargeTotal: form =>
    getValue(form, 'miscellaneousChargeTotal', 0),
  shippableTotal: form => getValue(form, 'shippableTotal', 0),
  shippableSubtotal: form => getValue(form, 'shippableSubtotal', 0),
  shippableTaxAmount: form => getValue(form, 'shippableTaxAmount', 0),

  shippableVolume: form => getValue(form, 'shippableVolume', 0),
  shippableWeight: form => getValue(form, 'shippableWeight', 0),

  shippedFreight: form => getValue(form, 'shippedFreight', 0),
  shippedMiscCharge: form => getValue(form, 'shippedMiscCharge', 0),
  shippedSubTotal: form => getValue(form, 'shippedSubTotal', 0),
  shippedTax: form => getValue(form, 'shippedTax', 0),
  shippedTotal: form => getValue(form, 'shippedTotal', 0),
  backOrderAmount: form => getValue(form, 'backOrderAmount', 0),

  orderSubTotal: form => getValue(form, 'orderSubTotal', 0),
  orderFreight: form => getValue(form, 'orderFreight', 0),
  orderMiscCharge: form => getValue(form, 'orderMiscCharge', 0),
  orderTax: form => getValue(form, 'orderTax', 0),
  orderTotal: form => getValue(form, 'orderTotal', 0),
  isEditing: form => form.get('isEditing') || false
})(OrderTotals)
