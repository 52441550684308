/* eslint react/prefer-stateless-function: 0, react/no-did-mount-set-state: 0, react/jsx-fragments: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDISelectField } from 'ddiForm/wrapped'
import {
  isForeignCountry,
  layoutFlex,
  getField,
  getValue,
  getMeta,
  getIn,
  empty,
  emptyList
} from 'utils'
import masterStyles from 'theme/masterStyles'
import { Button, IconButton, Icon } from '@material-ui/core'
import { openGoogleMaps } from 'pages/CustomerShipTo/actions'

import DDICardWrapper from 'components/DDICardWrapper'

import NameAndAddress from './components/NameAndAddress'
import ShippingAndInstructions from './components/ShippingAndInstructions'
import ShipToDefaults from './components/ShipToDefaults'
import Settings from './components/Settings'
import ContactInfo from './components/ContactInfo'

export const pageStyles = {
  ...masterStyles,
  searchRow: {
    ...masterStyles.searchRow,
    description: {
      ...masterStyles.searchRow.description,
      width: '46%'
    },
    label: {
      ...masterStyles.searchRow.label,
      width: '20%'
    },
    wrapper: {
      ...masterStyles.searchRow.wrapper,
      marginBottom: 10
    }
  }
}

export class Information extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      country: props.country,
      disableInputMask: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.country !== prevState.country) {
      return {
        country: nextProps.country,
        disableInputMask: isForeignCountry(
          nextProps.country,
          nextProps.isCanadianCompany
        )
      }
    }

    return null
  }

  componentDidMount() {
    const { country, isCanadianCompany } = this.props
    this.setState({
      disableInputMask: isForeignCountry(country, isCanadianCompany)
    })
  }

  openGoogleMaps = type => this.props.dispatch(openGoogleMaps({ type }))

  render() {
    // debugger
    const { disableInputMask } = this.state
    const {
      addressLine1,
      dataId,
      parentMap,
      customerId,
      isEditing,
      statusOptionsList
    } = this.props

    const layoutStyle = {
      display: 'flex',
      flex: '0.1 1 120rem',
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxWidth: '100%',
      margin: '0 auto',
      maxHeight: 400
    }

    const parent = parentMap?.toJS ? parentMap.toJS() : {}
    const statusOptions = statusOptionsList?.toJS
      ? statusOptionsList.toJS()
      : []

    const hasParentObject =
      (parent &&
        typeof parent === 'object' &&
        parent !== null &&
        Object.keys(parent).length) ||
      false

    return (
      <div className="layout-flex" style={layoutStyle}>
        <div
          className="two-col-flex-wrapper"
          style={pageStyles.twoColFlex.wrapper}
        >
          <div style={pageStyles.twoColFlex.left}>
            <NameAndAddress
              addressLine1={addressLine1}
              disableInputMask={disableInputMask}
              pageStyles={pageStyles}
              isEditing={isEditing}
              openGoogleMaps={this.openGoogleMaps}
            />
            <ShipToDefaults pageStyles={pageStyles} />
            <Settings pageStyles={pageStyles} />
          </div>

          <div style={pageStyles.twoColFlex.right}>
            <DDICardWrapper
              title={`Customer ${customerId}`}
              contentStyle={{ minHeight: 95, padding: 10 }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                {hasParentObject ? (
                  <div>
                    <ul
                      style={{
                        fontSize: '0.857em',
                        listStyleType: 'none',
                        paddingLeft: 0
                      }}
                    >
                      <li>
                        <strong>{parent.description}</strong>
                      </li>
                      <li>
                        <a onClick={() => this.openGoogleMaps('parent')}>
                          {parent.address1}
                        </a>
                      </li>
                      {parent.address2 ? <li>{parent.address2}</li> : null}
                      <li>{parent.cityStateZip}</li>
                      <li>Phone: {parent.phone}</li>
                    </ul>
                  </div>
                ) : null}
                <div style={{ width: 150 }}>
                  <DDISelectField
                    label="Status"
                    propertyName="statusId"
                    values={statusOptions}
                    selectValue="dataId"
                    selectDescription="description"
                    fullWidth
                  />
                </div>
              </div>
              {hasParentObject ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    onClick={() => this.openGoogleMaps('parent')}
                    color="secondary"
                    size="small"
                    variant="text"
                  >
                    <Icon style={{ fontSize: 16, marginRight: 5 }}>
                      open_in_new
                    </Icon>
                    View on Google Maps
                  </Button>
                </div>
              ) : null}
              {/* {hasParentObject ? (
                <div>
                  <ul
                    style={{
                      fontSize: '0.857em',
                      listStyleType: 'none',
                      paddingLeft: 0
                    }}
                  >
                    <li>
                      <strong>{parent.description}</strong>
                    </li>
                    <li>
                      <a onClick={() => this.openGoogleMaps('parent')}>
                        {parent.address1}
                      </a>
                    </li>
                    {parent.address2 ? <li>{parent.address2}</li> : null}
                    <li>{parent.cityStateZip}</li>
                    <li>Phone: {parent.phone}</li>
                  </ul>
                </div>
              ) : null} */}
            </DDICardWrapper>
            <ContactInfo
              dataId={dataId}
              pageStyles={pageStyles}
              disableInputMask={disableInputMask}
              customerId={customerId}
            />
            <ShippingAndInstructions pageStyles={pageStyles} />
          </div>
        </div>
      </div>
    )
  }
}

export default withContext({
  addressLine1: form => getField(form, 'addressLine1', ''),
  country: form => getField(form, 'country', ''),
  parentMap: form => getValue(form, 'parent', empty),
  customerId: form => getValue(form, 'customerId', ''),
  dataId: form => getValue(form, 'dataId', ''),
  isCanadianCompany: form => getMeta(form, 'isCanadianCompany', false),
  statusOptionsList: form => getMeta(form, 'statusOptions', emptyList)
})(Information)
