import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getIn, getField, getValue } from 'utils'
import { confirm } from 'modals/actions'

import * as actions from '../actions'

const marginRight = {
  marginRight: 5
}

// const mapState = (state, ownProps) => {

// }

export const NotesModalActions = ({ dispatch, form, isLocked, modal }) => {
  const {
    options: { newData = false, rowIndex, propertyName }
  } = modal

  function handleEdit() {
    if (modal && modal.options) {
      dispatch(
        actions.lockSerialForEdit.try(form, {
          propertyName,
          rowIndex
        })
      )
    }
  }

  function handleExit() {
    if (isLocked) {
      // logic to unlock serial and then close modal
      dispatch(
        actions.unlockSerial.try(form, {
          propertyName,
          rowIndex,
          modalId: modal.id
        })
      )
    } else {
      dispatch(confirm(form, modal.id))
    }
  }

  function handleSave() {
    dispatch(
      actions.saveNotes.try(form, {
        propertyName,
        rowIndex,
        modalId: modal.id,
        newData
      })
    )
  }

  function handleDelete() {
    dispatch(
      actions.deleteNotes.try(form, {
        propertyName,
        rowIndex,
        modalId: modal.id
      })
    )
  }

  return (
    <>
      {(isLocked || newData) && (
        <DDIButton
          onClick={handleSave}
          variant="contained"
          label="Save"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      )}
      {(isLocked || newData) && (
        <DDIButton
          onClick={handleDelete}
          variant="contained"
          label="Delete"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      )}
      {!isLocked && !newData && (
        <DDIButton
          onClick={handleEdit}
          variant="contained"
          label="Edit"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      )}
      <DDIButton
        onClick={handleExit}
        variant="contained"
        label="Exit"
        tabIndex={0}
        preventAutoDisable
        style={marginRight}
      />
    </>
  )
}

NotesModalActions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired
}

export const contextObj = {
  isLocked: (state, { modal: { options } }) => {
    debugger
    const { propertyName, rowIndex } = options

    const isLocked = getIn(
      state,
      `fields.${propertyName}.rowData[${rowIndex}].isLocked`
    )

    return isLocked
  }
}

export default withContext(contextObj)(NotesModalActions)
