import {
  actionChannel,
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects'
import { getIn, dataURItoBlob } from 'utils'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { getFormSelector, getMapResponse } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { api } from 'services'
import FifoCostModalGrid from 'pages/ProductMaster/components/FifoCostModalGrid'
import PurchaseHistoryModalGrid from 'pages/ProductMaster/components/PurchaseHistoryModalGrid'
import ReportViewer from 'components/ReportViewer'
import { addModal } from 'modals/actions'
import { REMOVE_MODAL } from 'modals/constants'
import { warningModal } from 'modals/sagas'
import { getErrorMessages } from 'pages/ProductMaster/utils'
import Bins from 'pages/ProductMaster/tabs/Setup/Bins'
import FileSaver from 'file-saver'
import * as masterActions from 'ddiForm/MasterScreen/actions'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'

export function* getAnalysisRead(form, tabToOpen) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''

  // const selectedPrimaryTab = getIn(
  //   formState,
  //   'masterOptions.selectedPrimaryTab'
  // )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  // const year = getIn(formState, 'fields.selectedYear.value')
  const year = getIn(formState, 'fields.selectedYear.value') || ''
  // debugger

  if (!dataId) return

  yield put(actions.getAnalysisRead.request(form))

  const groupNames =
    tabToOpen === 'transactions'
      ? ['analysis', 'transactions']
      : tabToOpen
      ? [tabToOpen]
      : [selectedSecondaryTab]

  const { response, error } = yield call(api.getAnalysisRead, {
    dataId,
    groupNames,
    warehouseId,
    uomId,
    year
  })

  if (response) {
    const mapResponse = yield getMapResponse({ formState })
    const res = mapResponse({
      response,
      tabIds: ['analysis', tabToOpen],
      formState,
      groupNames: [tabToOpen]
    })
    // debugger
    yield put(masterActions.getEntityAsync.success(res, form))
  } else {
    yield put(actions.getAnalysisRead.failure(error, form))
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export function* updateTransactionsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.UPDATE_TRANSACTIONS.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(getAnalysisRead, form, 'transactions')
    }
  }
}

export function* viewDocumentListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_REPORT_VIEWER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(viewDocumentProcess, form, payload)
    }
  }
}

export function* viewDocumentProcess(form, payload) {
  if (payload && payload.document) {
    const modalOpts = {
      component: ReportViewer,
      options: {
        data: {
          actions: [{ primary: true, title: 'Exit' }],
          pdfData: payload.document
        },
        fullScreen: true,
        title: '',
        marginTop: '0px',
        maxHeight: '95%',
        maxWidth: '95%',
        width: '95%'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  }
}

export function* openPurchaseHistoryListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_PURCHASE_HISTORY.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openPurchaseHistoryProcess, form, payload)
    }
  }
}

export function* openPurchaseHistoryProcess(form, payload) {
  const { dispatch, purchaseHistoryLinks } = payload

  if (purchaseHistoryLinks) {
    const modalOpts = {
      component: PurchaseHistoryModalGrid,
      options: {
        data: {
          actions: [{ primary: true, title: 'Exit' }],
          dispatch,
          form,
          links: purchaseHistoryLinks
        },
        padding: '5px',
        title: 'Purchase History Links'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  }
}

export function* openFifoCostListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_FIFO_COST.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openFifoCostProcess, form, payload)
    }
  }
}

export function* openFifoCostProcess(form, payload) {
  const { dispatch } = payload

  const formState = yield select(getFormSelector(form))
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const dataId = getIn(formState, 'fields.dataId.value')
  const selectedWarehouseId = getIn(
    formState,
    'fields.selectedWarehouseId.value'
  )

  if (!dataId) return

  yield put(actions.openFifoCost.request(form))

  const { response, error } = yield call(api.getFifoCost, {
    dataId,
    warehouseId: selectedWarehouseId
  })

  if (response) {
    const modalOpts = {
      component: FifoCostModalGrid,
      options: {
        data: {
          actions: [{ primary: true, title: 'Close' }],
          dispatch,
          form,
          rowData: response,
          isMobile
        },
        disableEnforceFocus: true,
        padding: '5px',
        title: 'FIFO Cost Layer Detail'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    yield put(actions.openFifoCost.success(response, { form }))
  } else {
    const { status, validationErrors } = error
    yield put(actions.openFifoCost.failure(form))
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export function* launchAnalysisBinsEditor(form) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const description =
    getIn(formState, 'fields.description.value') ||
    getIn(formState, 'values.description')

  const modalOpts = {
    component: Bins,
    options: {
      form,
      data: {
        actions: [
          {
            primary: true,
            title: 'Update',
            async clickEvent(args, fs, cb) {
              console.log(args, fs, this)

              try {
                await this.props.updateAnalysisBins(form, { cb })
              } finally {
                console.log('OK this is fixed now too')
                // if (cb) {
                //   cb()
                // }
              }
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.cancelUpdateAnalysisBins(form, { bins: [] })
              } finally {
                cb()
              }
            }

            // async clickEvent(args, fs, cb) {
            //   console.log(this)
            //   try {
            //     this.context._ddiForm.dispatch(
            //       actions.cancelUpdateAnalysisBins(form, { bins: [] })
            //     )
            //   } finally {
            //     cb()
            //   }
            // }
          }
        ],
        isModal: true,
        form
      },
      title: `Bins Update - ${dataId} "${description}"`
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* getBinsData(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const gridSet = getIn(formState, `fields.bins.${warehouseId}.grid`)
  const groupNames = ['setup', 'bins']

  /*
    this case means that the user has not been to
    Product Master > Setup > Bins, so we need to hit the API
  */
  if (!gridSet) {
    const { response, error } = yield call(api.getProduct, {
      dataId,
      groupNames,
      warehouseId,
      uomId
    })
    // debugger

    if (response && response.setup && response.setup.bins) {
      const binData = response.setup.bins
      yield put(
        actions.handleBinData(form, {
          binData,
          allowRevert: true
        })
      )
      // yield put(actions.launchAnalysisBinsEditor(form))
      yield fork(launchAnalysisBinsEditor, form)
    }
  } else {
    yield fork(launchAnalysisBinsEditor, form)
  }
}

export function* launchAnalysisBinsEditorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_ANALYSIS_BINS_EDITOR)

    if (form === formListener) {
      yield fork(getBinsData, form)
    }
  }
}

export function* updateAnalysisBinsProcess(form, cb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value')
  const uomId = getIn(formState, 'fields.selectedUOMId.value')
  let bins = getIn(formState, `fields.bins.${warehouseId}.rowData`)
  bins = bins && bins.toJS ? bins.toJS() : []

  yield put(actions.updateAnalysisBins.request(form))

  const { response, error } = yield call(api.updateAnalysisBins, {
    bins,
    dataId,
    warehouseId,
    uomId
  })

  if (response) {
    yield put(
      actions.updateAnalysisBins.success(
        {
          bins: response
        },
        form
      )
    )

    if (cb) {
      cb()
    }
  } else {
    yield put(actions.updateAnalysisBins.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* updateAnalysisBinsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { bins, cb }
    } = yield take(CONSTANTS.UPDATE_ANALYSIS_BINS.TRY)

    if (form === formListener) {
      yield fork(updateAnalysisBinsProcess, form, cb)
    }
  }
}

export function* getDocSpecListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_DOCSPEC.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(getDocSpecProcess, form)
    }
  }
}

export function* getDocSpecProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!dataId) return

  yield put(actions.getDocSpec.request(form))
  const { response, error } = yield call(api.getDocSpec, { dataId })

  if (response) {
    yield put(actions.getDocSpec.success(response, form))
    yield fork(handleLinks, form, response, dataId)
  } else {
    yield put(actions.getDocSpec.failure(error, form))
  }
}

export function* getPurchaseHistoryListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_PURCHASE_HISTORY.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(getPurchaseHistoryProcess, form)
    }
  }
}

export function* getPurchaseHistoryProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const { response, error } = yield call(api.getPurchaseHistory, { dataId })

  if (response) {
    // open modal
    // debugger

    const modalOpts = {
      component: PurchaseHistoryModalGrid,
      options: {
        data: {
          actions: [{ primary: true, title: 'Exit' }],
          form,
          links: response,
          isMobile
        },
        padding: '5px',
        title: 'Purchase History Links'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    return modal.payload.id
  }

  return null
}

export function* getSDSListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_SDS.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(getSDSProcess, form)
    }
  }
}

export function* getSDSProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!dataId) return
  yield put(actions.getSDS.request(form))

  const { response, error } = yield call(api.getSds, { dataId })

  if (response) {
    yield put(actions.getSDS.success(response, form))
    yield fork(handleLinks, form, response, dataId)
  } else {
    yield put(actions.getSDS.failure(error, form))
  }
}

export function* handleLinks(form, response, dataId) {
  const extension = response.extension ? response.extension.toLowerCase() : ''
  const { filename, base64String } = response
  const imageTypes = ['bmp', 'jpg', 'jpeg', 'png', 'gif']
  const base64EncodedFileTypes = ['rtf', 'xls', 'xlsx', 'docx', 'doc', 'csv']

  const mimeTypes = {
    bmp: 'image/bmp',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    rtf: 'application/rtf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    docx:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    csv: 'text/csv',
    txt: 'text/plain'
  }

  const mimeType = extension && mimeTypes[extension] ? mimeTypes[extension] : ''

  if (base64String && extension === 'pdf') {
    yield fork(viewDocumentProcess, form, { document: base64String })
  }

  if (
    (base64String && imageTypes.includes(extension)) ||
    (base64String && base64EncodedFileTypes.includes(extension))
  ) {
    FileSaver.saveAs(
      dataURItoBlob(base64String, { type: mimeType }),
      `${filename}.${extension}`
    )
  }

  if (response.text) {
    const text = new Blob([response.text], {
      type: 'text/plain;charset=utf-8'
    })
    FileSaver.saveAs(text, `${filename}.${extension}`)
  }

  if (response.url) {
    window.open(response.url, 'external')
  }
}

export function* searchInUpdateDemandModalListener(formListener) {
  const channel = yield actionChannel([
    INDEX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS,
    INDEX_SEARCH_CONSTANTS.FIND_NEXT.SUCCESS,
    INDEX_SEARCH_CONSTANTS.FIND_PREV.SUCCESS
  ])

  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (payload.propertyName === 'updateDemand.dataId') {
        yield fork(updateDemandProductSearchProcess, form, payload)
      }

      if (payload.propertyName === 'updateDemand.selectedWarehouseId') {
        yield fork(updateDemandWarehouseProcess, form, payload)
      }
    }
  }
}

export function* updateDemandProductSearchProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const warehouseId = getIn(
    formState,
    'values.updateDemand.selectedWarehouseId'
  )

  const { exactMatchResults, name } = payload
  const dataId = exactMatchResults ? exactMatchResults.dataId : name

  yield put(actions.updateDemand.request(form))

  const { response, error } = yield call(api.getDemand, {
    dataId,
    warehouseId
  })

  if (response) {
    yield put(actions.updateDemand.success(response, form))
  } else {
    yield put(actions.updateDemand.failure(error))
  }
}

export function* updateDemandWarehouseProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  // const dataId = getIn(formState, 'values.updateDemand.dataId')
  const dataId = getIn(formState, 'fields.updateDemand.dataId.value')
    ? getIn(formState, 'fields.updateDemand.dataId.value')
    : ''

  if (!dataId) return

  const { exactMatchResults, name } = payload
  const warehouseId = exactMatchResults ? exactMatchResults.dataId : name

  yield put(actions.updateDemand.request(form))

  const { response, error } = yield call(api.getDemand, {
    dataId,
    warehouseId
  })

  if (response) {
    yield put(actions.updateDemand.success(response, form))
  } else {
    yield put(actions.updateDemand.failure(error))
  }
}

export function* updateDemandListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.UPDATE_DEMAND.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(updateDemandProcess, form)
    }
  }
}

export function* updateDemandProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.updateDemand.dataId.value')
  const demand = getIn(formState, 'fields.updateDemand.demand.rowData').toJS()
    ? getIn(formState, 'fields.updateDemand.demand.rowData').toJS()
    : []
  const warehouseId = getIn(
    formState,
    'values.updateDemand.selectedWarehouseId'
  )
    ? getIn(formState, 'values.updateDemand.selectedWarehouseId')
    : ''
  const currentProduct = getIn(formState, 'fields.dataId.value')

  if (!dataId) return

  yield put(actions.updateDemand.request(form))

  const { response, error } = yield call(api.updateDemand, {
    dataId,
    demand,
    warehouseId
  })

  if (response) {
    yield put(actions.updateDemand.success(response, form))

    if (currentProduct === response.dataId) {
      yield fork(getAnalysisRead, form)
    }
  } else {
    yield put(actions.updateDemand.failure(error))
  }
}

export function* clearSearchListener(formListener) {
  while (true) {
    const action = yield take([
      INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH,
      REMOVE_MODAL
    ])
    const {
      meta: { form },
      payload: { propertyName }
    } = action

    if (
      form === formListener &&
      (propertyName === 'updateDemand.dataId' ||
        propertyName === 'updateDemand.selectedWarehouseId')
    ) {
      yield put(actions.clearUpdateDemandModal(form, propertyName))
    }
  }
}

export default function* analysisSagas(form) {
  yield fork(updateTransactionsListener, form)
  yield fork(viewDocumentListener, form)
  yield fork(openPurchaseHistoryListener, form)
  yield fork(openFifoCostListener, form)
  yield fork(launchAnalysisBinsEditorListener, form)
  yield fork(getDocSpecListener, form)
  yield fork(getPurchaseHistoryListener, form)
  yield fork(getSDSListener, form)
  yield fork(searchInUpdateDemandModalListener, form)
  yield fork(updateDemandListener, form)
  yield fork(clearSearchListener, form)
  yield fork(updateAnalysisBinsListener, form)
}
