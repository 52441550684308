import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

// export const getCustomerLedgerMeta = ({ dataId, credentials }) => {
//   const options = createApiOptions({
//     body: { dataId, credentials },
//     method: 'post'
//   })

//   return callFetch(`${BASE_INFORM_API_URL}/customerledger/meta`, options)
// }
/* ^^ DEPRECATED ^^ */

export const getCustomerLedgerMeta = ({ dataId, credentials }) => {
  const options = createApiOptions({
    body: { dataId, credentials, activeKey: 'ledger' },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/meta`, options)
}

export const readCustomerLedger = ({
  dataId,
  invoiceType,
  invoiceYear,
  orderType,
  paymentHistoryYear,
  shipToId,
  salesHistoryYear,
  transactionsFromDate,
  groupNames,
  ...rest
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      groupNames,
      invoiceType,
      invoiceYear,
      orderType,
      paymentHistoryYear,
      salesHistoryYear,
      shipToId,
      transactionsFromDate,
      ...rest
    },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/read`, options)
}

/* START NEW print/report methods */
export const getInvoiceReport = ({
  dataId,
  shipToId,
  invoiceType,
  invoiceYear,
  invoices = null,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, shipToId, invoiceType, invoiceYear, invoices, credentials }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/report/invoices`,
    options
  )
}

export const getPaymentHistoryReport = ({
  dataId,
  shipToId,
  paymentHistoryYear,
  credentials
}) => {
  const options = createApiOptions({
    method: 'post',
    body: { dataId, shipToId, paymentHistoryYear, credentials }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/report/paymenthistory`,
    options
  )
}

export const printInvoices = ({ dataId, invoices, credentials }) => {
  const options = createApiOptions({
    body: { dataId, invoices, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/print/invoices`,
    options
  )
}

export const printInvoicesMobile = ({ dataId, invoices, credentials }) => {
  const options = createApiOptions({
    body: { dataId, invoices, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customerinvoice/print/invoices`,
    options
  )
}

export const getSubAccountLedgerReport = ({
  dataId,
  shipToId,
  credentials
}) => {
  const options = createApiOptions({
    body: { dataId, shipToId, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/report/subaccountledger`,
    options
  )
}

export const getSalesHistoryReport = ({
  dataId,
  salesHistoryYear,
  shipToId,
  printMostRecentSalesOnly,
  printPrices,
  printHistoryGrossProfit,
  printHiddenProducts,
  productLineId,
  vendorId,
  sortField = 'productId',
  credentials
}) => {
  const options = createApiOptions({
    body: {
      dataId,
      salesHistoryYear,
      shipToId,
      printMostRecentSalesOnly,
      printPrices,
      printHistoryGrossProfit,
      printHiddenProducts,
      productLineId,
      vendorId,
      sortField,
      credentials
    },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/report/saleshistory`,
    options
  )
}

export const printOrders = ({
  dataIds,
  status,
  printType = '',
  credentials
}) => {
  const options = createApiOptions({
    body: { dataIds, status, printType, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/print/orders`,
    options
  )
}

export const printCreditRefLetter = ({ dataId, credentials }) => {
  const options = createApiOptions({
    body: { dataId, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/print/creditrefletter`,
    options
  )
}

/* END NEW print/report methods */

export const openARNotes = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/arnotes`, options)
}

export const saveAgingView = ({ view, credentials }) => {
  const options = createApiOptions({
    body: { view, credentials },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customermaster/saveagingview`,
    options
  )
}

export const getNextARNote = ({ dataId, credentials }) => {
  const options = createApiOptions({
    body: { dataId, credentials },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/customermaster/nextarnote`, options)
}
