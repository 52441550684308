/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import memoize from 'memoize-one'
import { getIn, fromJS } from 'utils'
import { withContext } from 'ddiForm'
import { addScreenInModal } from 'ddiForm/actions'

import { Icon } from '@material-ui/core'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import 'pages/ProductMaster/styles/master-style.scss'

import { onOpenVendorMasterInModal } from 'pages/VendorMaster/sagas/crudSagas'
import { vendorMasterInModal } from 'pages/VendorMaster'
import { readVendorMaster } from 'pages/VendorMaster/api'

const addVendorMasterInModal = memoize((form, dataId) =>
  addScreenInModal(form, {
    screen: vendorMasterInModal(`${form}.vendorMaster`),
    formName: `${form}.vendorMaster`,
    apiMethod: readVendorMaster,
    apiArgs: {
      groupNames: ['setup', 'main'],
      dataId
      // activeKey: 'ledger',
      // image: 'accounting_accounts_payable_vendor_ledger_16.png',
      // route: 'vendormaster',
      // title: 'Vendor Ledger'
    },
    onApiSuccess: onOpenVendorMasterInModal,
    onApiError: error => console.log('fail', error),
    modalOptions: {
      title: 'Vendor Master',
      hideActions: true,
      maxSize: true,
      modalOverrideClass: 'vendor-master-in-modal'
    }
  })
)

const Label = memoize(value => {
  return value ? (
    <div
      className="ddi-data-label"
      style={{
        background: '#eaeef0',
        borderRadius: 4,
        color: '#517b9c',
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}
    >
      <Icon
        style={{
          color: '#517b9c',
          fontSize: 16,
          marginRight: 5,
          verticalAlign: 'middle'
        }}
      >
        info
      </Icon>
      {value}
    </div>
  ) : null
})

const PatternTypeModal = props => {
  const { form, patternType, referenceId, referenceDescription } = props
  let { productLineSearchMeta, vendorSearchMeta } = props
  productLineSearchMeta = productLineSearchMeta?.toJS
    ? productLineSearchMeta.toJS()
    : {}
  vendorSearchMeta = vendorSearchMeta?.toJS ? vendorSearchMeta.toJS() : {}

  // const getPatternTooltip = memoize(
  //   p =>
  //     p.reduce((acc, next) => {
  //       acc = acc.concat(`${next}\n`)
  //       return acc
  //     }, 'Allowed patterns include:\n'),
  //   plainDeepEqual
  // )

  return (
    <div className="product-master-pattern-type-modal-wrapper">
      <div className="search-row-wrapper">
        <label className="search-row-label">
          {patternType === 'P' ? 'Product Line:' : 'Vendor:'}
        </label>
        <div className="search-row-input">
          <DDIIndexSearch
            propertyName="referenceId"
            meta={
              patternType === 'P' ? productLineSearchMeta : vendorSearchMeta
            }
            preventAutoDisable
            customScreenOpenAction={
              patternType === 'V' && referenceId
                ? addVendorMasterInModal(form, referenceId)
                : null
            }
          />
        </div>
        <div className="search-row-description">
          {Label(referenceDescription)}
        </div>
      </div>
      <div className="search-row-wrapper">
        <label className="search-row-label">Product ID:</label>
        <div className="search-row-input">
          <DDITextField propertyName="patternSetDataId" preventAutoDisable />
        </div>
        <div className="search-row-description" />
      </div>
    </div>
  )
}

export default withContext({
  patternType: form => getIn(form, 'meta.patternType') || '',
  productLineSearchMeta: form =>
    getIn(form, 'meta.productLineId') || fromJS({}),
  referenceId: form => getIn(form, 'fields.referenceId.value') || '',
  referenceDescription: form => getIn(form, 'values.referenceDescription'),
  vendorSearchMeta: form =>
    getIn(form, 'meta.consignmentVendorId') || fromJS({})
})(PatternTypeModal)
