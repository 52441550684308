import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { getIn, toCamelCase } from 'utils'
import { find } from 'lodash'
import { toggleCriteriaSelection } from '../actions'

const mapDispatchToProps = dispatch => ({
  toggleCriteriaSelection: (form, { dataId }) =>
    dispatch(toggleCriteriaSelection(form, { dataId }))
})

const mapStateToProps = (state, ownProps) => {
  const { dataId, form } = ownProps
  const path = `ddiForm.${form}.values.selectionCriteria.equalToCriterias`
  const equalToCriterias = getIn(state, path) ? getIn(state, path).toJS() : []

  return {
    item: find(equalToCriterias, { dataId })
  }
}

class SelectionCheckbox extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    toggleCriteriaSelection: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      checked: props.item.enabled
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.item.enabled !== prevState.checked) {
      return { checked: nextProps.item.enabled }
    }

    return null
  }

  onChange = (_, isInputChecked) => {
    // console.log('onChange', this.props, isInputChecked)
    const { dataId, form } = this.props
    this.setState({ checked: isInputChecked }, () => {
      this.props.toggleCriteriaSelection(form, {
        dataId
      })
    })
  }

  render() {
    const { checked } = this.state
    const { item } = this.props

    return (
      <FormControlLabel
        label={item.description}
        style={{
          marginLeft: 0,
          marginRight: 0,
          minWidth: 150
        }}
        control={
          <Checkbox
            preventAutoDisable
            checked={checked}
            onChange={this.onChange}
            value={toCamelCase(item.description)}
            style={{
              height: 24,
              width: 24
            }}
          />
        }
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SelectionCheckbox)
