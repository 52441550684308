/* eslint react/sort-comp: 0, react/no-find-dom-node: 0, consistent-return: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIn, noop, plainDeepEqual, toCamelCase } from 'utils'
import { TextField } from '@material-ui/core'
import BinSearch from 'components/Search/BinSearch'
import * as actions from 'components/Search/BinSearch/actions'
import shortid from 'shortid'
import { updateGridCellData } from 'components/EditableGrid/utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'

export const generateScreenParams = obj =>
  Object.keys(obj).reduce((acc, next) => {
    if (next === 'fullDescription') {
      acc.name = toCamelCase(obj[next])
      acc.title = obj[next]
    }

    if (next === 'imageFileName') {
      acc.image = obj[next]
    }

    if (next === 'dataId') {
      acc.dataId = obj[next]
    }

    if (next === 'parentId') {
      acc.parentId = obj[next]
    }

    return acc
  }, {})

export const mapStateToProps = (state, ownProps) => {
  const {
    colDef: {
      cellRendererParams: { linkTo, form, additionalOpenScreenParams = [] }
    },
    value,
    propertyName
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  let focusedCell = getIn(formState, `fields.${propertyName}.focusedCell`)
  focusedCell = focusedCell ? focusedCell.toJS() : {}

  if (linkTo) {
    let openScreenParams = getIn(state, `auth.screensMap.${linkTo}`)
    openScreenParams = openScreenParams ? openScreenParams.toJS() : {}

    /* for opening a screen or record with a dataId */
    if (form && additionalOpenScreenParams) {
      if (additionalOpenScreenParams.indexOf('dataId') !== -1) {
        openScreenParams = {
          ...openScreenParams,
          dataId: value
        }
      }

      if (additionalOpenScreenParams.indexOf('parentId') !== -1) {
        openScreenParams = {
          ...openScreenParams,
          parentId:
            getIn(state, `ddiForm.${form}.fields.dataId.value`) ||
            getIn(state, `ddiForm.${form}.values.dataId`)
        }
      }
    }

    return {
      focusedCell,
      openScreenParams: generateScreenParams(openScreenParams)
    }
  }

  return {
    focusedCell
  }
}

export class BinSearchGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    exactMatchSearch: PropTypes.func,
    form: PropTypes.string.isRequired,
    indexSearchType: PropTypes.string,
    openScreenParams: PropTypes.object,
    meta: PropTypes.object,
    partialMatchSearch: PropTypes.func,
    propertyName: PropTypes.string.isRequired
  }

  static defaultProps = {
    exactMatchSearch: noop,
    indexSearchType: 'product',
    meta: {
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowSearchAll: false
    },
    partialMatchSearch: noop
  }

  constructor(...args) {
    super(...args)
    this.state = {
      isEditing: false,
      value: this.props.value,
      rowIndex: this.props.rowIndex,
      textFieldVal: this.props.value || ''
    }

    this.updateGridCellData = updateGridCellData.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value }
    }

    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      prevState.isEditing === true
    ) {
      return { isEditing: false }
    }

    /* ^^related to the above, now that we've received a new value,
         we can strip it out because we've previously marked isEditing === false
         AFTER getting the new value prop back after being handled by the saga
         or reducer (as described above)
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      prevState.isEditing === false
    ) {
      return { textFieldVal: nextProps.value }
    }

    return null
  }

  componentDidMount() {
    this._isMounted = true

    if (this.textField && findDOMNode(this.textField) !== null) {
      findDOMNode(this.textField).focus()
      findDOMNode(this.textField).addEventListener('keydown', this.onKeyDown)
    }

    setTimeout(() => {
      this.checkFocusOnInput()
    }, 0)
  }

  componentWillUnmount() {
    this._isMounted = false
    if (this.textField && findDOMNode(this.textField) !== null) {
      findDOMNode(this.textField).removeEventListener('keydown', this.onKeyDown)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      // !plainDeepEqual(prevProps.rowData, this.props.rowData) ||
      !plainDeepEqual(prevProps.focusedCell, this.props.focusedCell)
    ) {
      setTimeout(() => {
        this.checkFocusOnInput()
      }, 0)
    }
  }

  checkFocusOnInput = () => {
    if (!this.props.api || !this._isMounted) return

    const focusedCell = this.props.api.getFocusedCell()
    const {
      colDef: { field },
      data: { rowId = null }
    } = this.props
    // debugger
    const model = this.props.api.getModel()
    if (!model) {
      return
    }

    const sortedRowData = model.rowsToDisplay.reduce((acc, next) => {
      acc = acc.concat(next.data)
      return acc
    }, [])
    const sortedRowIndex = sortedRowData.findIndex(x => x.rowId === rowId)

    if (!focusedCell || sortedRowIndex < 0) {
      return
    }

    if (
      focusedCell &&
      focusedCell.column &&
      focusedCell.column.colDef &&
      focusedCell.column.colDef.field &&
      focusedCell.column.colDef.field === field &&
      focusedCell.rowIndex === sortedRowIndex
    ) {
      // debugger
      if (findDOMNode(this.field) && findDOMNode(this.field) !== null) {
        findDOMNode(this.field).focus()
      }

      if (findDOMNode(this.textField) && findDOMNode(this.textField) !== null) {
        findDOMNode(this.textField).focus()
      }
    }
  }

  setField = (val, _, __, ___) => {
    const {
      colDef: {
        cellRendererParams: { onChange }
      },
      data,
      rowIndex
    } = this.props

    this.setState({ value: val, textFieldVal: val }, () => {
      // debugger
      if (this.props.onChange) {
        // this.updateGridCellData()
        // this.props.onChange(this.state.rowIndex, {
        //   dataId: val,
        //   recordName: val,
        //   id: val,
        //   description: null
        // })
        this.props.onChange(
          {
            ...data,
            rowIndex
          },
          {
            dataId: val,
            recordName: val,
            id: val,
            description: null
          }
        )
      }
    })
  }

  exactMatch = args =>
    this.props.dispatch(actions.exactMatchSearch(null, { ...args }))

  partialMatch = args =>
    this.props.dispatch(actions.partialMatchSearch(null, { ...args }))

  inputRef = c => (this.field = c)

  onChange = event => {
    this.setState({ isEditing: true, textFieldVal: event.target.value })
  }

  /* for editing and validating text field input only */
  onBlur = event =>
    this.props.onChange(this.state.rowIndex, {
      recordName: this.state.textFieldVal
    })

  isInputDisabled = () => {
    const {
      colDef: {
        cellRendererParams: { disabled }
      }
    } = this.props
    if (disabled === false || !this.props.colDef.cellRendererParams.disabled) {
      return false
    }

    return true
  }

  onKeyDown = event => {
    if (this.isLeftOrRight(event)) {
      event.stopPropagation()
      return false
    }
  }

  isLeftOrRight = event => [37, 39].indexOf(event.keyCode) > -1

  openScreen = () => {
    const { value } = this.state
    const {
      data,
      colDef: {
        cellRendererParams: { linkTo }
      },
      openScreenParams
    } = this.props

    if (Object.keys(openScreenParams).length) {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          dataId: value
        })
      )
    }
  }

  render() {
    const {
      colDef: {
        cellRendererParams: {
          allowMultipleSearches,
          productId,
          warehouseId,
          uom,
          linkTo
        }
      },
      indexSearchType,
      meta
    } = this.props

    return this.state.value ? (
      <div
        style={{
          color: this.isInputDisabled() ? '#999' : '#444',
          paddingLeft: 10,
          paddingRight: 10
        }}
      >
        {linkTo ? (
          <a onClick={this.openScreen}>{this.state.value}</a>
        ) : (
          this.state.value
        )}
      </div>
    ) : (
      <BinSearch
        // propertyName=
        // {...this.props}
        dispatch={this.props.dispatch}
        productId={productId}
        warehouseId={warehouseId}
        uom={uom}
        isFromBin={false}
        setField={this.setField}
        value={this.state.value}
        inputRef={this.inputRef}
        // partialMatchSearch={this.partialMatch}
        // exactMatchSearch={this.exactMatchSearch}
        // disabled={this.isInputDisabled()}
        // ref={el => (this.indexSearchCell = el)}
        // openScreenParams={this.props.openScreenParams}
        // indexSearchType="BinSearch"
        // partialMatchSearch={this.partialMatch}
        // exactMatchSearch={this.exactMatch}
        // // onChange={this.onChange}
        // value={this.state.textFieldVal}
        // propertyName={this.props.propertyName}
        // setField={this.setField}
        meta={{
          allowInstantSearch: true,
          allowSearchAll: true,
          minimumKeywordLength: 2
        }}
        // inputRef={this.inputRef}
        // minWidth="100%"
        // style={{
        //   minWidth: '100%'
        // }}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(BinSearchGridCell)
