/* eslint no-unreachable: 0, max-len: 0 */
import {
  all,
  delay,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'
import { getIn, fromJS } from 'utils'
import { api } from 'services'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'
import { addModal, removeModal } from 'modals/actions'
import { setField } from 'ddiForm/actions'
import { BLUR, SET_FIELD, EXIT, DESTROY } from 'ddiForm/constants'
import { displayValidationErrors } from 'ddiForm/sagas'
import { getFormSelector } from 'ddiForm/utils'
// import { getEntityAsync, resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { ADD } from 'pages/Layout/constants'
import { clearLayoutScreenData } from 'pages/Layout/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
// import shortid from 'shortid'
import {
  EXACT_MATCH_SEARCH,
  CLEAR_SEARCH,
  BLUR as INDEX_SEARCH_BLUR
} from 'components/Search/IndexSearch/constants'
import { clearSearch } from 'components/Search/IndexSearch/actions'

/* PDF viewer */
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'

/* new Customer Stock Minimum routines */
import customerStockMinimumsSagas from 'pages/InvoiceInquiry/sagas/customerStockMinimumsSagas'

/* access override modal for PDF viewer */
// import AccessOverride from 'auth/modals/AccessOverride'

import {
  CALCULATE_ORDER_PAD_PRICE,
  CALL_ACCESS_OVERRIDE_MODAL,
  DELETE_CUSTOMER_STOCK_MINIMUMS,
  GET_FILTERED_GRID_MODEL,
  GET_GRID_CELL_TOOLTIP,
  EXECUTE_INQUIRY,
  OPEN_SCREEN,
  LOAD_CUSTOMER_RECORD,
  RUN_CUSTOMER_ORDER_PAD_REPORT,
  SAVE_ADJUSTED_PRICE,
  SAVE_PRICE_DETAIL,
  SHOW_ADJUSTED_PRICE,
  SAVE_CUSTOMER_STOCK_MINIMUMS,
  SHOW_CUSTOMER_STOCK_MINIMUMS,
  SHOW_REPORT_OPTIONS_MODAL,
  SHOW_PRICE_DETAIL,
  SHOW_WARNING_MODAL,
  UPDATE_LINE_ITEM_QUANTITY,
  VIEW_CUSTOMER_ORDER_PAD_REPORT,
  SET_QUOTED_PRICE,
  OPEN_SALES_ORDER_SCREEN,
  ADD_TO_SALES_ORDER,
  COMPILE_DATA_FOR_SALES_ORDER,
  REVERT_SEARCH_CONTROL_SELECTION
} from './constants'

import * as actions from './actions'
import PriceDetailModal from './modals/PriceDetail'
import AdjustedPriceChangeModal from './modals/AdjustedPriceChange'
// import CustomerStockMinimumsModal from './modals/CustomerStockMinimums'
import ReportOptionsModal from './modals/ReportOptions'

// let accessOverrideModalId
let priceDetailModalId
let reportModalId
// let stockMinimumModalId
let adjustedPriceModalId

export function* onOpenProcess(form) {
  yield putResolve({
    meta: { form },
    type: OPEN_SCREEN
  })
}

export function* executeInquiryListener(formListener) {
  let task
  while (true) {
    const action = yield take(EXECUTE_INQUIRY.TRY)
    const {
      meta: { form },
      payload: { propertyName }
    } = action
    if (task) yield cancel(task)

    if (form === formListener) {
      task = yield fork(executeInquiryProcess, form, propertyName)
    }
  }
}

export function* executeInquiryProcess(
  form,
  propertyName,
  isGridEditedCheck = false
) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  // debugger
  const customerId = getIn(formState, 'fields.customer.value')
  const shipToId = getIn(formState, 'fields.shipTo.value')
  const cutOffDate = getIn(formState, 'fields.cutOffDate.value')
    ? getIn(formState, 'fields.cutOffDate.value')
    : null
  const gridEdited = getIn(formState, 'gridEdited')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  if (gridEdited) {
    yield call(
      confirmationModal,
      'Are you sure you want to lose your changes?',
      'Customer Order Pad'
    )

    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      const propsToRevert = ['customer', 'shipTo', 'cutOffDate']
      if (propsToRevert.includes(propertyName)) {
        const prevValue =
          getIn(formState, `values.previous.${propertyName}.value`) || ''

        const prevDescription =
          getIn(formState, `values.previous.${propertyName}.description`) || ''

        yield put({
          type: REVERT_SEARCH_CONTROL_SELECTION,
          meta:
            propertyName === 'customer'
              ? {
                  form,
                  customer: prevValue,
                  description: prevDescription,
                  mobile: isMobile
                }
              : {
                  form
                },
          payload: {
            propertyName,
            value: prevValue,
            description: prevDescription
          }
        })
      }
      // debugger
      return
    }

    if (action.type === CONFIRMED) {
      yield put(
        actions.clearInquiryResults(form, { propertyChanged: propertyName })
      )

      if (!isMobile) {
        return
      }
    }
  }

  if (!customerId) {
    yield fork(warningModal, 'Customer is required', 'Attention!', [
      {
        title: 'OK',
        primary: true,
        clickEvent(args, fs, cb) {
          /* prob not the prettiest solution, but it works */
          cb()
          const input = document.getElementById('customer-search-field')
          if (input) {
            // setTimeout(() => {
            //   input.focus()
            // }, 500)
          }
        }
      }
    ])

    if (isMobile) {
      yield put(
        actions.clearInquiryResults(form, { propertyChanged: propertyName })
      )
    }
    return
  }

  if (propertyName === 'shipTo' && !shipToId && !customerId) {
    // debugger
    yield put(
      actions.clearInquiryResults(form, { propertyChanged: propertyName })
    )
    return
  }
  yield put({ type: EXECUTE_INQUIRY.REQUEST, meta: { form, apiRequest: true } })

  /* can be changed by clearInquiryResults */
  const updatedFormState = yield select(state =>
    getIn(state, `ddiForm.${form}`)
  )
  const { response, error } = yield call(api.orderPadInquiry, {
    customerId: getIn(updatedFormState, 'fields.customer.value'),
    cutOffDate,
    shipToId: getIn(updatedFormState, 'fields.shipTo.value')
  })

  if (response) {
    yield put({
      meta: { form },
      payload: {
        ...response,
        customerId: getIn(updatedFormState, 'fields.customer.value'),
        customerDescription: getIn(
          updatedFormState,
          'fields.customer.description'
        )
      },
      type: EXECUTE_INQUIRY.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: { ...error, propertyName },
      type: EXECUTE_INQUIRY.FAILURE
    })
    if (error?.status !== 404 && error?.message) {
      yield fork(warningModal, error.message, 'Alert!')
    }
  }
}

export function* showPriceDetailProcess(form, UOMId, dataId, gridCallbackFn) {
  // debugger
  yield put({
    type: SHOW_PRICE_DETAIL.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getPriceDetail, {
    UOMId,
    dataId
  })

  if (response) {
    yield put({
      type: SHOW_PRICE_DETAIL.SUCCESS,
      meta: { form },
      payload: response
    })
    yield fork(showPriceDetailModal, response, form, gridCallbackFn)
  } else {
    yield put({
      type: SHOW_PRICE_DETAIL.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* showPriceDetailListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { UOMId, dataId, gridCallbackFn }
    } = yield take(SHOW_PRICE_DETAIL.TRY)

    // debugger
    if (form === formListener) {
      yield fork(showPriceDetailProcess, form, UOMId, dataId, gridCallbackFn)
    }
  }
}

export function* showPriceDetailModal(args, form, gridCallbackFn) {
  const baseActions = [
    {
      title: 'Save',
      primary: true,
      async clickEvent(btnArgs, formState, cb) {
        const { isHidden, isMarginPriceDisabled } = this.state
        try {
          await this.props.savePriceDetailAsync({
            UOMId: this.props.data.responseData.uomId,
            dataId: this.props.data.responseData.dataId,
            isHidden,
            isMarginPriceDisabled,
            cb
          })
        } finally {
          if (cb && typeof cb === 'function') {
            cb()
          }
        }
      },
      disabled: formState => {
        const {
          fields,
          values: {
            priceDetail: { canSave }
          }
        } = formState
        console.log('canSave', canSave)
        return !canSave
      }
    },
    {
      primary: true,
      title: 'Exit',
      async clickEvent(btnArgs, formState, cb) {
        try {
          await this.props.setEditablePrice({ price: '', updateField: '' })
        } finally {
          cb()
        }
      }
    }
  ]
  const actionButtons = args.canAdjustPrice
    ? [
        {
          title: 'Adjusted Price',
          async clickEvent(btnArgs, formState, cb) {
            try {
              await this.props.showAdjustedPrice({
                dataId: this.props.data.responseData.dataId,
                UOMId: this.props.data.responseData.uomId,
                gridCallbackFn
              })
            } finally {
              console.log('finally')
            }
          }
        },
        ...baseActions
      ]
    : baseActions

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const options = {
    component: PriceDetailModal,
    options: {
      // draggable: true,
      data: {
        form,
        actions: actionButtons,
        responseData: { ...args }
      },
      maxHeight: isMobile ? '100%' : 800,
      marginTop: isMobile ? 25 : null,
      title: `Order Pad Detail - ${args.dataId}`,
      width: 600
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  priceDetailModalId = modal.payload.id
  return priceDetailModalId
}

export function* savePriceDetailListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(SAVE_PRICE_DETAIL.TRY)

    if (form === formListener) {
      yield fork(savePriceDetailProcess, form, payload)
    }
  }
}

export function* savePriceDetailProcess(
  form,
  { dataId, isHidden, isMarginPriceDisabled, UOMId, cb }
) {
  const { response, error } = yield call(api.savePriceDetail, {
    dataId,
    isHidden,
    isMarginPriceDisabled,
    UOMId
  })

  if (response) {
    yield put(actions.savePriceDetailAsync.success(response, form))

    // debugger
    if (cb) {
      cb()
    }
  } else {
    yield put(actions.savePriceDetailAsync.failure(error, form))
  }
}

export function* showAdjustedPriceProcess(form, dataId, UOMId, gridCallbackFn) {
  yield put({
    type: SHOW_ADJUSTED_PRICE.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.getAdjustedPrice, {
    UOMId,
    dataId
  })

  if (response) {
    yield put({
      type: SHOW_ADJUSTED_PRICE.SUCCESS,
      meta: { form },
      payload: response
    })

    yield fork(
      showAdjustedPriceModal,
      {
        ...response,
        gridCallbackFn
      },
      form
    )
  } else {
    yield put({
      type: SHOW_ADJUSTED_PRICE.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* showAdjustedPriceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, gridCallbackFn, UOMId }
    } = yield take(SHOW_ADJUSTED_PRICE.TRY)

    // debugger
    if (form === formListener) {
      yield fork(showAdjustedPriceProcess, form, dataId, UOMId, gridCallbackFn)
    }
  }
}

export function* showAdjustedPriceModal(args, form) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const options = {
    component: AdjustedPriceChangeModal,
    options: {
      // draggable: true,
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'OK',
            disabled: f => f.isPosting || false,
            async clickEvent(btnArgs, formState, cb) {
              const {
                adjPriceEffectiveDate,
                applyToAllItems,
                clearAdjustedPrices,
                selectedLineItemData,
                pctIncrease
              } = this.state

              const adjustedPrice = getIn(
                formState,
                'fields.editablePrice.value'
              )

              const { gridCallbackFn } = args
              // debugger

              try {
                await this.props.saveAdjustedPriceAsync({
                  applyToAllItems,
                  lineItems: [selectedLineItemData],
                  effectiveDate: clearAdjustedPrices
                    ? null
                    : adjPriceEffectiveDate,
                  adjustedPrice: clearAdjustedPrices ? null : adjustedPrice,
                  percentIncrease: clearAdjustedPrices ? null : pctIncrease,
                  gridCallbackFn,
                  cb
                })
              } finally {
                // debugger
                if (cb && typeof cb === 'function') {
                  cb()
                }

                // if (priceDetailModalId) {
                //   debugger
                //   this.props.removeParentModal({ id: priceDetailModalId })
                // }
              }
            }
          },
          {
            primary: true,
            title: 'Exit',
            disabled: f => f.isPosting || false,
            async clickEvent(btnArgs, formState, cb) {
              try {
                await this.props.setEditablePrice({
                  price: '',
                  updateField: ''
                })
              } finally {
                cb()
                // if (priceDetailModalId) {
                //   this.props.removeParentModal({ id: priceDetailModalId })
                // }
              }
            }
          }
        ],
        responseData: { ...args }
      },
      maxHeight: isMobile ? '100%' : 800,
      marginTop: isMobile ? 25 : null,
      title: `Adjusted Price Change - ${args.dataId}`,
      width: 400
    }
  }
  const modal = yield call(addModal, form, options)
  yield put(modal)
  adjustedPriceModalId = modal.payload.id
  return adjustedPriceModalId
}

export function* saveAdjustedPriceProcess(
  {
    applyToAllItems,
    lineItems,
    effectiveDate,
    adjustedPrice,
    percentIncrease,
    gridCallbackFn,
    cb
  },
  form
) {
  // debugger
  const grid = gridCallbackFn()
  // debugger

  const filteredRows = grid.length
    ? grid.reduce((acc, next) => {
        acc = acc.concat({
          dataId: next.dataId,
          uomId: next.uomId
        })
        return acc
      }, [])
    : []

  const effectedLineItems = applyToAllItems ? filteredRows : lineItems

  const { response, error } = yield call(api.saveAdjustedPrice, {
    adjustedPrice: applyToAllItems ? null : adjustedPrice,
    effectiveDate,
    lineItems: effectedLineItems,
    percentIncrease
  })

  if (response) {
    yield put(actions.saveAdjustedPriceAsync.success(response, form))

    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.saveAdjustedPriceAsync.failure(error, form))
  }
}

export function* saveAdjustedPriceListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(SAVE_ADJUSTED_PRICE.TRY)

    // debugger
    if (form === formListener) {
      // debugger
      yield fork(saveAdjustedPriceProcess, payload, form)
    }
  }
}

export function* showReportOptionsModalListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(SHOW_REPORT_OPTIONS_MODAL)

    if (form === formListener) {
      // debugger
      yield fork(showReportOptionsModalProcess, form)
    }
  }
}

export function* showReportOptionsModalProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  const printOptions = getIn(formState, 'meta.printOptions')
    ? getIn(formState, 'meta.printOptions').toJS()
    : []
  const reportTypeOptions = getIn(formState, 'meta.reportTypeOptions')
    ? getIn(formState, 'meta.reportTypeOptions').toJS()
    : []
  const defaultCutOffDate = getIn(formState, 'meta.defaultCutOffDate')

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const options = {
    component: ReportOptionsModal,
    options: {
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'Run',
            clickEvent(args, formState, cb) {
              const {
                defaultCutOffDate,
                printOption,
                printShipToDetail,
                reportType,
                saveLineSeqNo
              } = this.state
              this.props.dispatch(
                actions.runCustomerOrderPadReportAysnc.try(form, {
                  defaultCutOffDate,
                  printOption,
                  printShipToDetail,
                  reportType,
                  saveLineSeqNo,
                  cb
                })
              )
            }
          },
          { primary: true, title: 'Exit' }
        ],
        responseData: {
          defaultCutOffDate,
          printOptions,
          reportTypeOptions
        }
      },
      maxHeight: isMobile ? '100%' : 800,
      marginTop: isMobile ? 25 : null,
      title: 'Customer Order Pad Report',
      width: 400
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  return modal.payload.id
}

export function* lineItemEntryErrorMessage() {
  while (true) {
    const { payload } = yield take(UPDATE_LINE_ITEM_QUANTITY)
    if (payload.showErrorModal === true) {
      yield call(warningModal, 'Number is out of range', 'Attention!')
    }
  }
}

export function* runCustomerOrderPadReportProcess(
  {
    defaultCutOffDate,
    printOption,
    printShipToDetail,
    reportType,
    saveLineSeqNo,
    cb
  },
  form
) {
  const formState = yield select(state =>
    getIn(state, `ddiForm.${form}.fields`)
  )

  const customerId = getIn(formState, 'customer.value')
  const shipToId = getIn(formState, 'shipTo.value')
  const filterById = getIn(formState, 'filterBy.value')
  const groupById = getIn(formState, 'groupBy.value')

  const { response, error } = yield call(api.runCustomerOrderPadReport, {
    customerId,
    cutOffDate: defaultCutOffDate,
    filterById,
    groupById,
    printOption,
    printShipToDetail,
    reportType,
    saveLineSeqNo,
    shipToId
  })

  if (response) {
    yield put(actions.runCustomerOrderPadReportAysnc.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, response, cb)
  } else {
    yield put(actions.runCustomerOrderPadReportAysnc.failure(error, form))
  }
}

export function* runCustomerOrderPadReportListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(RUN_CUSTOMER_ORDER_PAD_REPORT.TRY)

    if (form === formListener) {
      yield fork(runCustomerOrderPadReportProcess, payload, form)
    }
  }
}

export function* calculateOrderPadProcess(payload, form) {
  const { cost, gpPercent, updateField } = payload

  // const formState = yield select(state =>
  //   getIn(state, `ddiForm.${form}.fields`)
  // )

  const { response, error } = yield call(api.calculateOrderPadPrice, {
    cost,
    gpPercent
  })

  if (response) {
    yield put(
      actions.calculateOrderPadPriceAsync.success(
        {
          ...response,
          updateField
        },
        form
      )
    )
  } else {
    yield put(actions.calculateOrderPadPriceAsync.failure(error, form))
  }
}

export function* calculateOrderPriceListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CALCULATE_ORDER_PAD_PRICE.TRY)

    if (form === formListener) {
      // debugger
      yield fork(calculateOrderPadProcess, payload, form)
    }
  }
}

export function* showWarningModalProcess(form, customerInput) {
  // debugger
  yield call(
    confirmationModal,
    'Are you sure you want to lose your changes?',
    'Customer Order Pad'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    // console.log(customerInput)
    // debugger
    if (customerInput?.blur) {
      yield take(REMOVE_MODAL)
      // debugger
      customerInput.blur()
    }

    return
  }
  if (action.type === CONFIRMED) {
    yield put(actions.removeGridEditedFlag(form))
  }
}

export function* showWarningModalListener(form) {
  while (true) {
    const {
      payload: { customerInput }
    } = yield take(SHOW_WARNING_MODAL)
    yield fork(showWarningModalProcess, form, customerInput)
  }
}

export function* getGridCellTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_GRID_CELL_TOOLTIP.REQUEST)
    const {
      payload: { recordName, indexSearchType },
      meta: { thunk }
    } = action
    if (task) yield cancel(task)
    task = yield fork(getGridCellTooltipProcess, {
      indexSearchType,
      recordName,
      thunk
    })
  }
}

export function* getGridCellTooltipProcess({
  indexSearchType,
  recordName,
  thunk
}) {
  const { response, error } = yield call(api.getIndexSearchTooltip, {
    indexSearchType,
    recordName
  })

  if (response) {
    yield putResolve({
      meta: { thunk },
      payload: { ...response },
      type: GET_GRID_CELL_TOOLTIP.SUCCESS
    })
  } else {
    yield putResolve({
      error: true,
      meta: { thunk },
      payload: { ...error },
      type: GET_GRID_CELL_TOOLTIP.FAILURE
    })
  }
}

export function getLoadData(payload, screenLayoutData, field) {
  const value =
    payload && payload.data && payload.data[field]
      ? payload.data[field]
      : getIn(screenLayoutData, `data.${field}`)

  if (value) {
    return value
  }

  return null
}

export function* loadCustomerRecordProcess(form, payload) {
  const screenLayoutData = yield select(state =>
    getIn(state, `layout.screens.${form}`)
  )
  const customer = getLoadData(payload, screenLayoutData, 'customer')
  const customerDescription = getLoadData(
    payload,
    screenLayoutData,
    'customerDescription'
  )

  const shipTo = getLoadData(payload, screenLayoutData, 'shipTo')
  const shipToDescription = getLoadData(
    payload,
    screenLayoutData,
    'shipToDescription'
  )

  if (customer) {
    yield put({
      type: SET_FIELD,
      meta: {
        form
      },
      payload: {
        propertyName: 'customer',
        value: customer,
        results: { description: customerDescription }
      }
    })

    if (shipTo) {
      yield put({
        type: SET_FIELD,
        meta: {
          form
        },
        payload: {
          propertyName: 'shipTo',
          value: shipTo,
          results: { description: shipToDescription }
        }
      })
    }

    yield put(clearLayoutScreenData(form))
    yield fork(executeInquiryProcess, form, null)
  }
}

export function* loadCustomerRecordListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(LOAD_CUSTOMER_RECORD)

    if (form === formListener) {
      yield fork(loadCustomerRecordProcess, form, payload)
    }
  }
}

export function* onAddListener(formListener) {
  while (true) {
    const action = yield take(ADD)
    const {
      payload: { name, data }
    } = action

    // debugger

    if (
      name === formListener &&
      data.propertyName === 'inquire' &&
      data.customer
    ) {
      // yield put(setField(name, 'customer', data.customer))
      yield put({
        type: SET_FIELD,
        meta: {
          form: name
        },
        payload: {
          propertyName: 'customer',
          value: data.customer,
          results: { description: data?.customerDesciption }
        }
      })

      yield fork(executeInquiryProcess, name, null)
    }

    /*
      this logic handles initiating an external customer load,
      clearing the fields and loading a new record/search inquiry
    */
    if (name === formListener && data.customer && !data.propertyName) {
      yield put({
        type: SET_FIELD,
        meta: {
          form: name
        },
        payload: {
          propertyName: 'customer',
          value: data.customer,
          results: { description: data?.customerDesciption }
        }
      })

      yield fork(executeInquiryProcess, name, null)
    }
  }
}

let isRunning = false
export function* dispatchExecuteInquiryProcess(
  form,
  propertyName,
  value,
  type
) {
  const formState = yield select(getFormSelector(form))
  const gridEdited = getIn(formState, 'gridEdited')
  const prevValue = getIn(formState, `fields.${propertyName}.prevValue`) || ''
  const storedValue =
    getIn(formState, `values.previous.${propertyName}.value`) || ''
  const modals = yield select(state => getIn(state, 'modals')) || fromJS([])

  if (
    type === INDEX_SEARCH_BLUR &&
    !gridEdited &&
    prevValue &&
    value !== prevValue
  ) {
    yield delay(1000)
    yield put(
      actions.clearInquiryResults(form, { propertyChanged: propertyName })
    )
  }

  if (
    gridEdited &&
    storedValue !== value &&
    (type === SET_FIELD ||
      type === BLUR ||
      (type === INDEX_SEARCH_BLUR && !value)) &&
    !modals.size
  ) {
    yield delay(1000)

    if (!isRunning) {
      isRunning = true
      yield call(executeInquiryProcess, form, propertyName, true)
    }
    isRunning = false
  }
}

export function* formStateChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, results, value },
      type
    } = yield take([SET_FIELD, BLUR, CLEAR_SEARCH, INDEX_SEARCH_BLUR])

    const requiresFieldChangeCheck = ['customer', 'cutOffDate', 'shipTo']
    if (
      form === formListener &&
      requiresFieldChangeCheck.includes(propertyName)
    ) {
      if (propertyName === 'shipTo' && results?.parentId) {
        const formState = yield select(getFormSelector(form))
        const customerId = getIn(formState, 'fields.customer.value') || ''
        if (!customerId) {
          yield putResolve({
            type: SET_FIELD,
            meta: {
              form
            },
            payload: {
              propertyName: 'customer',
              preventClearShipTo: true,
              value: results?.parentId,
              results: {
                description: results?.parentDescription
              }
            }
          })
        } else {
          yield fork(
            dispatchExecuteInquiryProcess,
            form,
            propertyName,
            value,
            type
          )
        }
      } else {
        yield fork(
          dispatchExecuteInquiryProcess,
          form,
          propertyName,
          value,
          type
        )
      }
    }
  }
}

export function* setQuotedPriceProcess(form, dataId, uomId, quotedPrice) {
  yield put(actions.setQuotedPrice.request(form))

  const { response, error } = yield call(api.savePriceQuote, {
    dataId,
    uomId,
    quotedPrice
  })

  if (response) {
    yield put(actions.setQuotedPrice.success(response, form))
  } else {
    // debugger
    yield put(actions.setQuotedPrice.failure(error, form))

    if (error?.modelState?.['model.QuotedPrice']?.[0]) {
      // debugger
      yield fork(
        warningModal,
        `${error?.modelState?.['model.QuotedPrice']?.[0]}`,
        'Attention!'
      )
    }
  }
}

export function* setQuotedPriceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, uomId, quotedPrice }
    } = yield take(SET_QUOTED_PRICE.TRY)

    if (form === formListener) {
      yield fork(setQuotedPriceProcess, form, dataId, uomId, quotedPrice)
    }
  }
}

export function* openSalesOrderScreenProcess(form, isQuote, modalId) {
  const formState = yield select(getFormSelector(form))
  const customerId = getIn(formState, 'fields.customer.value')
  const shipToId = getIn(formState, 'fields.shipTo.value')

  const bulkProperties = yield call(getLineItemQuantities, form)

  yield put(
    openScreenAction({
      image: 'sales_order_entry_16.png',
      title: 'Sales Order',
      activeKey: '',
      route: 'salesorder',
      screenOpenData: {
        bulkProperties,
        customerId,
        shipToId,
        isQuote
      }
    })
  )

  if (modalId) {
    yield put(removeModal(form.split('.')[0], modalId))
  } else {
    /*
      exit alone is not enough:
      object does not get removed from ddiForm,
      so the user can't reopen the screen -- SVE 12/19/19
    */
    yield put({
      type: EXIT,
      meta: { form },
      payload: {}
    })

    yield put({
      type: DESTROY,
      meta: { form },
      payload: {}
    })
  }
}

export function* openSalesOrderScreenListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { isQuote, modalId }
    } = yield take(OPEN_SALES_ORDER_SCREEN)

    if (form === formListener) {
      yield fork(openSalesOrderScreenProcess, form, isQuote, modalId)
    }
  }
}

export function* getLineItemQuantities(form) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'fields.inquiryResults.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  const bulkProperties =
    lineItems && lineItems.length
      ? lineItems.reduce((acc, next) => {
          if (next.quantity) {
            acc = acc.concat({
              dataId: next.dataId,
              quantityOrdered: next.quantity,
              uomId: next?.uomId,
              netPrice: next?.currentPrice?.value
            })
          }
          return acc
        }, [])
      : []

  return bulkProperties
}

export function* compileDataForSalesOrderProcess(
  form,
  modalId,
  lineItemsGridApi
) {
  const formState = yield select(getFormSelector(form))
  const shipToId = getIn(formState, 'fields.shipTo.value')
  const bulkProperties = yield call(getLineItemQuantities, form)

  const salesOrderFormName = form.split('.')[0]

  if (modalId) {
    yield put(removeModal(form.split('.')[0], modalId))
  }

  yield put(
    actions.addToSalesOrder(salesOrderFormName, {
      shipToId,
      bulkProperties,
      lineItemsGridApi
    })
  )
}

export function* compileDataForSalesOrderListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId, lineItemsGridApi }
    } = yield take(COMPILE_DATA_FOR_SALES_ORDER)

    if (form === formListener) {
      // debugger
      yield fork(
        compileDataForSalesOrderProcess,
        form,
        modalId,
        lineItemsGridApi
      )
    }
  }
}

export function* exactMatchSearchSuccessListener(formListener) {
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.SUCCESS)
    const {
      meta: { form },
      payload: { propertyName, exactMatchResults }
    } = action

    if (form === formListener && propertyName) {
      if (propertyName === 'shipTo' && exactMatchResults) {
        // debugger
        const formState = yield select(getFormSelector(form))
        const customerId = getIn(formState, 'fields.customer.value') || null

        if (!customerId) {
          const { response, error } = yield call(api.exactMatchSearch, {
            indexSearchType: 'customer',
            keyword: exactMatchResults?.parentId
          })

          if (response) {
            yield put({
              type: SET_FIELD,
              meta: {
                form
              },
              payload: {
                propertyName: 'customer',
                value: exactMatchResults?.parentId,

                results: {
                  description: response?.exactMatchResults?.description
                }
              }
            })
          }
        }
      }
    }
  }
}

export function* clearSearchFieldsProcess(
  form,
  actionType,
  propertyName,
  value
) {
  if (actionType === CLEAR_SEARCH) {
    if (propertyName === 'customer') {
      // dispatch clearSearch for ship to
      yield put(clearSearch(form, { propertyName: 'shipTo' }))
    }
  }

  if (actionType === BLUR) {
    if (propertyName === 'customer' && !value) {
      yield put(clearSearch(form, { propertyName: 'shipTo' }))
    }
  }
}

export function* clearSearchListener(formListener) {
  while (true) {
    const action = yield take([CLEAR_SEARCH, BLUR])

    const {
      meta: { form },
      payload: { propertyName, value },
      type
    } = action

    if (form === formListener) {
      yield fork(clearSearchFieldsProcess, form, type, propertyName, value)
    }
  }
}

export default function* customerOrderPadSagas(form) {
  yield all([
    fork(onOpenProcess, form),
    fork(lineItemEntryErrorMessage),
    fork(executeInquiryListener, form),
    fork(showPriceDetailListener, form),
    fork(savePriceDetailListener, form),
    fork(showAdjustedPriceListener, form),
    fork(showReportOptionsModalListener, form),
    fork(runCustomerOrderPadReportListener, form),
    fork(calculateOrderPriceListener, form),
    fork(saveAdjustedPriceListener, form),
    fork(showWarningModalListener, form),
    fork(getGridCellTooltipListener),
    fork(loadCustomerRecordListener, form),
    fork(onAddListener, form),
    fork(formStateChangeListener, form),
    fork(setQuotedPriceListener, form),
    fork(openSalesOrderScreenListener, form),
    fork(compileDataForSalesOrderListener, form),
    // fork(exactMatchSearchSuccessListener, form),
    fork(clearSearchListener, form),
    fork(customerStockMinimumsSagas, form)
  ])
}
