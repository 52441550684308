/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import withRowTotals from 'hoc/withRowTotals'
import {
  dateFilterComparator,
  formatDateFields,
  getValue,
  emptyList
} from 'utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { addNumberFormat } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

import './styles/master-style.scss'

const getBottomData = (lots = []) => {
  const defaults = {
    warehouse: 'Total: ',
    onST: 0,
    onSO: 0,
    onHand: 0,
    available: 0
  }

  if (!lots || !Array.isArray(lots)) {
    return [defaults]
  }

  const totals = lots.reduce((acc, next) => {
    acc.onST += next?.onST
    acc.onSO += next?.onSO
    acc.onHand += next?.onHand
    acc.available += next?.available
    return acc
  }, defaults)

  return [totals]
}

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: getBottomData
})(Grid)

export const getRowNodeId = data => {
  const { warehouse, lotNo, lotDate } = data
  const compId = `${warehouse}|${lotNo}|${lotDate}`
  return compId
}

const dynamicColumnDefs = memoize(({ type }) => {
  const colDef = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      field: 'lotNo',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      linkTo: type,
      headerName: 'Lot No.'
    },
    {
      field: 'warehouse',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Warehouse'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'lotDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label',
      headerName: 'Lot Date',
      valueFormatter: formatDateFields
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'mfgDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label',
      headerName: 'Mfg Date',
      valueFormatter: formatDateFields
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'expDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label',
      headerName: 'Exp Date',
      valueFormatter: formatDateFields
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'onSO',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'On S/O',
      valueFormatter: ({ value }) => addNumberFormat(value)
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'onST',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'On S/T',
      valueFormatter: ({ value }) => addNumberFormat(value)
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'onHand',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'On Hand',
      valueFormatter: ({ value }) => addNumberFormat(value)
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'available',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Available',
      valueFormatter: ({ value }) => addNumberFormat(value)
    }
  ]
  return colDef
})

export class Lots extends Component {
  static propTypes = {
    canUpdateLots: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    lots: PropTypes.array.isRequired,
    lotsUOM: PropTypes.string.isRequired,
    shelfLife: PropTypes.number.isRequired
  }

  render() {
    const { canUpdateLots, height, lots, lotsUOM, shelfLife } = this.props
    return (
      <div className="product-analysis-layout-flex-column">
        <div className="product-analysis-flex-wrapper-1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              padding: 10
            }}
          >
            <div className="product-analysis-flex-wrapper-1">
              Quantites are based on:{' '}
              <span style={{ fontWeight: 'bold' }}>{lotsUOM}</span>
            </div>

            <div className="product-analysis-flex-wrapper-1">
              Shelf Life (days): <strong>{shelfLife}</strong>
            </div>
          </div>
          <div className="product-analysis-flex-wrapper-1">
            <div style={{ height: canUpdateLots ? '70%' : '73%' }}>
              <GridWithRowTotals
                // propertyName="lots"
                title="Lots"
                addButtonText="Update Lots"
                showAddButtonAlways={canUpdateLots}
                addButtonStyleParams={{
                  showIcon: false
                }}
                getRowNodeId={getRowNodeId}
                headerStyle={{
                  background: '#e1e3e4',
                  color: '#444',
                  fontSize: 13,
                  fontWeight: 400,
                  lineHeight: '17px',
                  margin: 0,
                  padding: '5px 0',
                  textAlign: 'center',
                  width: '100%'
                }}
                columnDefs={dynamicColumnDefs({
                  type: 'lot'
                })}
                gridHeight={height}
                rowData={lots.toJS()}
                rowSelection="single"
                enableSorting
                width="100%"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  canUpdateLots: form => getValue(form, 'lots.canUpdateLots', false),
  lots: form => getValue(form, 'lots.lots', emptyList),
  lotsUOM: form => getValue(form, 'lots.lotsUOM', ''),
  shelfLife: form => getValue(form, 'lots.shelfLife', '')
}

const lotsWithContext = withContext(contextObj)(Lots)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  lotsWithContext
)
