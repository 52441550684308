import { call, fork, put, select, take } from 'redux-saga/effects'
import { addScreenInModal, setField } from 'ddiForm/actions'
import { lockForEditAsync } from 'ddiForm/MasterScreen/actions'
import { GET_ENTITY } from 'ddiForm/MasterScreen/constants'
import { getIn } from 'utils'
import { clearLayoutScreenData } from 'pages/Layout/actions'
import { OPEN_SCREEN, SET_SELECTED_ROW_INDEX } from 'ddiForm/constants'
import { ADD_TO_SALES_ORDER } from 'pages/CustomerOrderPad/constants'
import { getFormSelector, getMapResponse } from 'ddiForm/utils'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import * as actions from 'pages/SalesOrder/actions'
import { api } from 'services'
import { OrderPadInModal } from 'pages/CustomerOrderPad'
import { confirmationModal, warningModal } from 'modals/sagas'

import {
  duplicateProductsRoutine,
  handleAdditionalProductRoutines
} from './commonSagas'

export function* openScreenWithDataProcess(form) {
  let screenOpenData = yield select(state =>
    getIn(state, `layout.screens.${form}.data.screenOpenData`)
  )
  screenOpenData =
    screenOpenData && screenOpenData.toJS ? screenOpenData.toJS() : {}

  if (screenOpenData.customerId) {
    const {
      payload: { gridApi },
      meta
    } = yield take(CONSTANTS.ON_LINE_ITEMS_GRID_READY)

    /* wait until here to get the formState */
    const formState = yield select(getFormSelector(form))
    let lineItems = getIn(formState, 'fields.lineItems.rowData')
    lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

    if (
      form === meta.form &&
      lineItems &&
      lineItems.length &&
      !lineItems[0].dataId
    ) {
      yield put(setField(form, 'customerId', screenOpenData.customerId))
      yield take(CONSTANTS.SET_SALES_ORDER_INITIALIZED)

      if (screenOpenData.bulkProperties && screenOpenData.bulkProperties) {
        yield put(actions.lineItemsBulkAdd.request(form))

        const { response, error } = yield call(api.massChange, {
          guid: getIn(formState, 'guid'),
          action: 'lineitembulkadd',
          groupNames: ['header', 'detail'],
          bulkProperties: screenOpenData.bulkProperties
        })

        if (response) {
          yield put(actions.lineItemsBulkAdd.success(response, form))
          const shipToId = screenOpenData?.shipToId || ''
          yield fork(
            handleAdditionalProductRoutines,
            form,
            lineItems,
            response.record,
            gridApi,
            shipToId
          )

          if (Object.hasOwnProperty.call(screenOpenData, 'isQuote')) {
            yield put(setField(form, 'quote', screenOpenData.isQuote))
          }

          /* clear the screen data from Redux */
          yield put(clearLayoutScreenData(form))
        } else {
          yield put(actions.lineItemsBulkAdd.failure(error, form))
          yield put(clearLayoutScreenData(form))
        }
      } else if (
        !screenOpenData.bulkProperties &&
        Object.hasOwnProperty.call(screenOpenData, 'isQuote')
      ) {
        /* 
          coming from Customer Master, there are no 'bulkProperties' but its possible
          that we do need to open it as a quote (or set quote to true in cases where the 
          screen is set up to open as a Quote with a company flag -- SVE 2/24/2021
        */
        yield put(setField(form, 'quote', screenOpenData.isQuote))

        /* clear the screen data from Redux */
        yield put(clearLayoutScreenData(form))
      }
    }
  } else if (screenOpenData?.creditInvoice) {
    const onGridReady = yield take(CONSTANTS.ON_LINE_ITEMS_GRID_READY)
    if (onGridReady?.meta?.form === form && onGridReady?.payload?.gridApi) {
      yield fork(
        handleCreditInvoice,
        form,
        screenOpenData.creditInvoice,
        onGridReady.payload.gridApi
      )
    }
  } else if (screenOpenData?.initEditMode && screenOpenData?.dataId) {
    const gridReady = yield take(CONSTANTS.ON_LINE_ITEMS_GRID_READY)
    if (gridReady?.meta?.form) {
      yield put(setField(form, 'dataId', screenOpenData.dataId))
      const onSuccessLoaded = yield take(CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS)
      if (onSuccessLoaded?.meta?.form && onSuccessLoaded?.meta?.form === form) {
        yield put(lockForEditAsync.try(form))
        yield put(clearLayoutScreenData(form))
      }
    }
  } else {
    yield put(clearLayoutScreenData(form))
  }
}

export function* handleCreditInvoice(form, dataId, gridApi) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  if (!dataId || !guid) {
    return
  }

  yield put(actions.creditInvoice.request(form))

  const { response, error } = yield call(api.creditInvoice, {
    dataId,
    guid,
    groupNames
  })

  if (response) {
    yield put(actions.creditInvoice.success({ ...response }, form))

    /* handle any messages if applicable */
    if (
      response?.messages &&
      Array.isArray(response.messages) &&
      response?.messages?.length
    ) {
      const messageData = response.messages.reduce(
        (acc, next) => {
          acc.message = acc.message.concat(`${next.message}\n`)
          acc.title = next.modalTitle ? next.modalTitle : 'Warning'
          return acc
        },
        { message: '', title: '' }
      )

      if (messageData?.message && messageData?.title) {
        yield call(warningModal, messageData.message, messageData.modalTitle)
      }
    }

    if (gridApi && gridApi?.forEachNode) {
      gridApi.forEachNode(node => {
        if (node?.data?.lineNumber && node?.data?.lineNumber === 1) {
          node.setSelected(true)
        } else {
          node.setSelected(false)
        }
      })
    }

    /* clear the screen data from Redux */
    yield put(clearLayoutScreenData(form))
  } else {
    yield put(actions.creditInvoice.failure(error, form))

    /* clear the screen data from Redux */
    yield put(clearLayoutScreenData(form))
  }
}

export function* openScreenWithDataListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(OPEN_SCREEN.SUCCESS)

    if (form === formListener) {
      yield fork(openScreenWithDataProcess, form)
    }
  }
}

/* the response routine should get merged with openScreenWithDataProcess */
export function* addToSalesOrderProcess(
  form,
  bulkProperties,
  shipToId,
  gridApi
) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'fields.lineItems.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  const { duplicatesProcessed, processedPostData } = yield call(
    duplicateProductsRoutine,
    form,
    lineItems,
    bulkProperties
  )

  yield put(actions.lineItemsBulkAdd.request(form))

  const { response, error } = yield call(api.massChange, {
    guid: getIn(formState, 'guid'),
    action: 'lineitembulkadd',
    groupNames: ['header', 'detail'],
    bulkProperties: duplicatesProcessed ? processedPostData : bulkProperties
  })

  if (response) {
    yield put(actions.lineItemsBulkAdd.success(response, form))
    yield fork(
      handleAdditionalProductRoutines,
      form,
      lineItems,
      response.record,
      gridApi,
      shipToId
    )
  } else {
    yield put(actions.lineItemsBulkAdd.failure(error, form))
  }
}

export function* addToSalesOrderListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { bulkProperties, shipToId, lineItemsGridApi }
    } = yield take(ADD_TO_SALES_ORDER)

    if (form === formListener) {
      yield fork(
        addToSalesOrderProcess,
        form,
        bulkProperties,
        shipToId,
        lineItemsGridApi
      )
    }
  }
}

export function* openCustomerOrderPadProcess(form, gridApi) {
  const formState = yield select(getFormSelector(form))
  const shipToDescription = getIn(formState, 'values.shipToDescription') || ''
  const customerName = getIn(formState, 'values.customerName') || ''
  const customerId = getIn(formState, 'fields.customerId.value')
  const shipToId = getIn(formState, 'fields.shipToId.value')

  yield put(
    addScreenInModal(form, {
      screen: OrderPadInModal(form),
      apiMethod: api.getMeta,
      apiArgs: {
        name: 'customerOrderPad'
      },
      modalOptions: {
        maxSize: true,
        hideActions: true,
        title: 'Customer Order Pad',
        data: {
          lineItemsGridApi: gridApi,
          customer: customerId,
          customerDescription: customerName,
          shipTo: shipToId,
          shipToDescription
        }
      }
    })
  )
}

export function* openCustomerOrderPadListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { gridApi }
    } = yield take(CONSTANTS.OPEN_CUSTOMER_ORDER_PAD_IN_MODAL)

    if (form === formListener) {
      yield fork(openCustomerOrderPadProcess, form, gridApi)
    }
  }
}

export default function* externalHandlerSagas(form) {
  yield fork(openScreenWithDataListener, form)
  yield fork(addToSalesOrderListener, form)
  yield fork(openCustomerOrderPadListener, form)
}
