import React from 'react'
import { withContext } from 'ddiForm'
import {
  Table,
  TableBody,
  TableHead,
  TableRow
  // TableCell
} from '@material-ui/core'

import { getMeta } from 'utils'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'
import NumberField from './NumberField'
import PricingCalculator from './PricingCalculator'
import { TableCell } from './utils'

const PricingTable = ({
  canSeeCost,
  allowCostOverride,
  priceChange,
  form,
  dispatch,
  useMultiplierForDiscount,
  isPostingProvisionalData,
  isEditing,
  handleClose,
  lineNumber,
  setEdited,
  isMobile = false,
  isComponent = false,
  data = {}
}) => {
  const {
    defaultPriceUOMId,
    discountPercent,
    netCost,
    netPrice,
    unitPrice,
    grossProfitPercent,
    uomId,
    multiplier,
    markupPercent
  } = priceChange
  // const { allowCostOverride, canSeeCost, lineNumber } = data
  const disabled = !isEditing
  const onKeyDown = propertyName => async event => {
    event.persist()
    //
    if (
      (!event.shiftKey && propertyName === 'unitPrice') ||
      (event.shiftKey && propertyName === 'grossProfitPercent')
    ) {
      return
    }
    if (event.key === 'Tab') {
      if (
        propertyName === 'grossProfitPercent' &&
        event?.target?.value &&
        grossProfitPercent !== Number(event?.target?.value)
      ) {
        /* 
          Marc -- I added this action because I noticed that 
          the confirmProvisionalChangesProcess was happening BEFORE
          the changeProvisionalLineItemProcess in NumberField on tabbing out of the
          G/P cell, so it was causing an issue. This SHOULD settle it
          here -- SVE 2/1/21
        */
        await dispatch(
          changeProvisionalLineItem.try(form, {
            lineNumber,
            propertyName,
            value: event.target.value,
            isComponent,
            data
          })
        )
      }

      handleClose(true, event.shiftKey, propertyName)
    }
  }

  const onChange = prop => val => {
    const m = {
      unitPrice,
      multiplier,
      discountPercent,
      netPrice
    }
    //
    const oldValue = m[prop]
    if (val !== oldValue) {
      setEdited()
    }
  }
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ textAlign: 'right' }}>Unit Price</TableCell>
            <TableCell style={{ textAlign: 'center' }}>UOM</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Disc Pct</TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              <i
                className="fa fa-calculator"
                style={{ color: '#444', fontSize: 14 }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'right' }}>Net Price</TableCell>
            <TableCell style={{ textAlign: 'center' }}>UOM</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Net Cost</TableCell>
            <TableCell style={{ textAlign: 'right' }}>G/P%</TableCell>
            <TableCell style={{ textAlign: 'right' }}>Mark Up %</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ background: '#fff' }}>
            <TableCell>
              <NumberField
                propertyName="unitPrice"
                value={unitPrice}
                form={form}
                dispatch={dispatch}
                fixedDecimalScale
                decimalScale={4}
                lineNumber={lineNumber}
                isPostingProvisionalData={isPostingProvisionalData}
                autoFocus={!isMobile}
                disabled={disabled}
                onChange={onChange('unitPrice')}
                onKeyDown={onKeyDown('unitPrice')}
                textAlign="right"
                isMobile={isMobile}
                isComponent={isComponent}
                data={data}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>
              {defaultPriceUOMId}
            </TableCell>
            <TableCell>
              {useMultiplierForDiscount ? (
                <NumberField
                  propertyName="multiplier"
                  value={multiplier}
                  form={form}
                  dispatch={dispatch}
                  fixedDecimalScale
                  decimalScale={2}
                  lineNumber={lineNumber}
                  isPostingProvisionalData={isPostingProvisionalData}
                  disabled={disabled}
                  onChange={onChange('multiplier')}
                  textAlign="right"
                  isMobile={isMobile}
                  isComponent={isComponent}
                  data={data}
                />
              ) : (
                <NumberField
                  propertyName="discountPercent"
                  value={discountPercent}
                  form={form}
                  dispatch={dispatch}
                  fixedDecimalScale
                  decimalScale={2}
                  lineNumber={lineNumber}
                  isPostingProvisionalData={isPostingProvisionalData}
                  disabled={disabled}
                  onChange={onChange('discountPercent')}
                  textAlign="right"
                  isMobile={isMobile}
                  isComponent={isComponent}
                  data={data}
                />
              )}
            </TableCell>
            <TableCell>
              <PricingCalculator
                dispatch={dispatch}
                form={form}
                lineNumber={lineNumber}
                isPostingProvisionalData={isPostingProvisionalData}
                disabled={disabled}
                isComponent={isComponent}
                data={data}
              />
            </TableCell>
            <TableCell>
              <NumberField
                propertyName="netPrice"
                value={netPrice}
                form={form}
                dispatch={dispatch}
                fixedDecimalScale
                decimalScale={4}
                lineNumber={lineNumber}
                isPostingProvisionalData={isPostingProvisionalData}
                disabled={disabled}
                onKeyDown={!canSeeCost ? onKeyDown('netPrice') : undefined}
                onChange={onChange('netPrice')}
                textAlign="right"
                isMobile={isMobile}
                isComponent={isComponent}
                data={data}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'center' }}>{uomId}</TableCell>
            <TableCell>
              {canSeeCost ? (
                <NumberField
                  propertyName="netCost"
                  value={netCost}
                  form={form}
                  dispatch={dispatch}
                  fixedDecimalScale
                  decimalScale={4}
                  lineNumber={lineNumber}
                  isPostingProvisionalData={isPostingProvisionalData}
                  disabled={!allowCostOverride || disabled}
                  onChange={onChange('netCost')}
                  textAlign="right"
                  isMobile={isMobile}
                  isComponent={isComponent}
                  data={data}
                />
              ) : (
                <span style={{ display: 'inline-block', minWidth: 50 }}>
                  &nbsp;
                </span>
              )}
            </TableCell>
            <TableCell>
              {canSeeCost ? (
                <NumberField
                  propertyName="grossProfitPercent"
                  value={grossProfitPercent}
                  form={form}
                  dispatch={dispatch}
                  fixedDecimalScale
                  decimalScale={2}
                  lineNumber={lineNumber}
                  isPostingProvisionalData={isPostingProvisionalData}
                  disabled={disabled}
                  onKeyDown={onKeyDown('grossProfitPercent')}
                  onChange={onChange('grossProfitPercent')}
                  textAlign="right"
                  isMobile={isMobile}
                  isComponent={isComponent}
                  data={data}
                />
              ) : (
                <span style={{ display: 'inline-block', minWidth: 50 }}>
                  &nbsp;
                </span>
              )}
            </TableCell>
            <TableCell>
              {canSeeCost ? (
                <NumberField
                  propertyName="markupPercent"
                  value={markupPercent}
                  form={form}
                  dispatch={dispatch}
                  fixedDecimalScale
                  decimalScale={2}
                  lineNumber={lineNumber}
                  isPostingProvisionalData={isPostingProvisionalData}
                  disabled={disabled}
                  onKeyDown={onKeyDown('markupPercent')}
                  onChange={onChange('markupPercent')}
                  textAlign="right"
                  isMobile={isMobile}
                  isComponent={isComponent}
                  data={data}
                />
              ) : (
                <span style={{ display: 'inline-block', minWidth: 50 }}>
                  &nbsp;
                </span>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

// const contextObj = {
//   useMultiplierForDiscount: form =>
//     form.meta && form.meta.useMultiplierForDiscount
//       ? form.meta.useMultiplierForDiscount
//       : false,
//   isPostingProvisionalData: form => form.isPostingProvisionalData || false,
//   isEditing: form => form.isEditing || false
// }

/* fixed SVE 9/24/2020 */
const contextObj = {
  useMultiplierForDiscount: form =>
    getMeta(form, 'useMultiplierForDiscount', false),
  isEditing: form => form.get('isEditing') || false
}

export default withContext(contextObj)(PricingTable)
