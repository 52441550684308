import React from 'react'
import { withContext } from 'ddiForm'
import { getIn } from 'utils'
import Contact from './components/Contact'
import RemitTo from './components/RemitTo'
import OrderAddress from './components/OrderAddress'
import AdditionalInformation from './components/AdditionalInformation'
import InternalInformation from './components/InternalInformation'
import '../../styles/layout.scss'

const Main = ({ isEditing, isNew, description, ...other }) => {
  return (
    <div className="vendor-master-main-flex-column-wrapper two-column-wrapper">
      <div>
        <RemitTo
          isEditing={isEditing}
          isNew={isNew}
          description={description}
        />
        <OrderAddress />
      </div>
      <div>
        <Contact />
        <AdditionalInformation />
        <InternalInformation />
      </div>
    </div>
  )
}

export default withContext({
  description: form => getIn(form, 'fields.description.value') || '',
  isNew: form => getIn(form, 'values.isNew') || false,
  isEditing: form => form.get('isEditing') || false
})(Main)
