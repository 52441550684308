import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const runReportExplorer = ({ dataId, signal }) => {
  const options = createApiOptions({
    signal,
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/reportexplorer/run`, options)
}

export const reportExplorerSendDocumentRead = () => {
  const options = createApiOptions({
    method: 'post'
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/reportexplorer/senddocument/read`,
    options
  )
}

export const reportExplorerSendDocumentSend = ({
  sendTo,
  sendType,
  fromCompany,
  fromName,
  subject,
  content,
  dataId
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      sendTo,
      sendType,
      fromCompany,
      fromName,
      subject,
      content,
      dataId
    }
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/reportexplorer/senddocument/send`,
    options
  )
}
export const cancelReportExplorer = ({ guid }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/reportexplorer/cancel`, options)
}

export const closeExplorerScreen = () => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid: null
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/reportexplorer/close`, options)
}
export const closeExplorerSendDocumentWithoutSending = () => {
  const options = createApiOptions({
    method: 'post',
    body: {
      guid: null
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/reportexplorer/senddocument/cancel`,
    options
  )
}
