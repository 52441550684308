import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const CUSTOMER_MASTER = '@@CUSTOMER_MASTER@@/'

export const customerMaster = 'customerMaster'
export const GET_CUSTOMER = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_CUSTOMER`
)

export const GET_GRID_CELL_TOOLTIP = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}GET_GRID_CELL_TOOLTIP`
)

/* START internal notes modal constants */
/* deprecated: moved to common compoonent */
// export const LAUNCH_NOTES_MODAL = `${CUSTOMER_MASTER}LAUNCH_NOTES_MODAL`

// export const LOCK_INTERNAL_NOTES = createAsyncRequestTypes(
//   `${CUSTOMER_MASTER}LOCK_INTERNAL_NOTES`
// )

// export const UNLOCK_INTERNAL_NOTES = createAsyncRequestTypes(
//   `${CUSTOMER_MASTER}UNLOCK_INTERNAL_NOTES`
// )

// export const SAVE_INTERNAL_NOTES = createAsyncRequestTypes(
//   `${CUSTOMER_MASTER}SAVE_INTERNAL_NOTES`
// )

// export const DELETE_INTERNAL_NOTES = createAsyncRequestTypes(
//   `${CUSTOMER_MASTER}DELETE_INTERNAL_NOTES`
// )
/* END internal notes constants */

/* DEPRECATED: list Ship Tos */
// export const LIST_SHIP_TOS = createAsyncRequestTypes(
//   `${CUSTOMER_MASTER}LIST_SHIP_TOS`
// )

export const DELETE_SHIP_TO = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}DELETE_SHIP_TO`
)

export const SHOW_SHIP_TO_WARNING_MODAL = `${CUSTOMER_MASTER}SHOW_SHIP_TO_WARNING_MODAL`

/* start TAX MATRIX constants */
export const OPEN_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}OPEN_TAX_MATRIX`
)

export const DELETE_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}DELETE_TAX_MATRIX`
)

export const LOCK_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}LOCK_TAX_MATRIX`
)

export const UNLOCK_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}UNLOCK_TAX_MATRIX`
)

export const CLOSE_TAX_MATRIX_MODAL = `${CUSTOMER_MASTER}/CLOSE_TAX_MATRIX_MODAL`
export const INIT_NEW_TAX_MATRIX = `${CUSTOMER_MASTER}/INIT_NEW_TAX_MATRIX`

export const SAVE_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}SAVE_TAX_MATRIX`
)

export const CREATE_TAX_MATRIX = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}CREATE_TAX_MATRIX`
)

export const SET_TAX_MATRIX_PROPERTY_DESCRIPTION = `${CUSTOMER_MASTER}/SET_TAX_MATRIX_PROPERTY_DESCRIPTION`
/* end TAX MATRIX constants */

/* lock and delete a contact */
export const LOCK_CONTACT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}/LOCK_CONTACT`
)
export const DELETE_CONTACT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}/DELETE_CONTACT`
)

/* get sales order tooltip */
export const GET_SALES_ORDER_TOOLTIP = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}/GET_SALES_ORDER_TOOLTIP`
)
export const GET_SALES_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/summary`

/* handle add grid row unique cases */
export const ON_AFTER_ADD_OR_DELETE_GRID_ROW = `${CUSTOMER_MASTER}ON_AFTER_ADD_OR_DELETE_GRID_ROW`

export const SET_DEFAULT_GRID_SELECTION = `${CUSTOMER_MASTER}SET_DEFAULT_GRID_SELECTION`

export const CLOSE_CUSTOMER_MASTER = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}CLOSE_CUSTOMER_MASTER`
)

export const DELETE_CUSTOMER_RECORD = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}DELETE_CUSTOMER_RECORD`
)

/* START create Customer constants */
export const CREATE_CUSTOMER_RECORD = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}CREATE_CUSTOMER_RECORD`
)

export const INVOKE_PRE_NEW_MODE = `${CUSTOMER_MASTER}INVOKE_PRE_NEW_MODE`
export const CANCEL_PRE_NEW_MODE = `${CUSTOMER_MASTER}CANCEL_PRE_NEW_MODE`
export const CANCEL_EDIT_NEW_CUSTOMER_RECORD = `${CUSTOMER_MASTER}CANCEL_EDIT_NEW_CUSTOMER_RECORD`

export const SAVE_TEMP_ID = `${CUSTOMER_MASTER}SAVE_TEMP_ID`
export const FLAG_NEW_CUSTOMER_RECORD = `${CUSTOMER_MASTER}FLAG_NEW_CUSTOMER_RECORD`

/* END create Customer constants */

export const LIST_CONTACTS = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}LIST_CONTACTS`
)

export const SAVE_INDEX_SEARCH_DESCRIPTION = `${CUSTOMER_MASTER}SAVE_INDEX_SEARCH_DESCRIPTION`

export const OPEN_CONTACT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}OPEN_CONTACT`
)

export const CHANGE_PO_DOLLAR_LIMIT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}CHANGE_PO_DOLLAR_LIMIT`
)

export const DELETE_PO_DOLLAR_LIMIT = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}DELETE_PO_DOLLAR_LIMIT`
)

export const PROPERTY_CHANGE = createAsyncRequestTypes(
  `${CUSTOMER_MASTER}PROPERTY_CHANGE`
)

export const OPEN_PRODUCT_MASTER = `${CUSTOMER_MASTER}OPEN_PRODUCT_MASTER`

export const OPEN_SCREEN_FROM_CUSTOMER_MASTER = `${CUSTOMER_MASTER}OPEN_SCREEN_FROM_CUSTOMER_MASTER`

export const SHOW_WARNING_MODAL = `${CUSTOMER_MASTER}SHOW_WARNING_MODAL`
export const UPDATE_AS = createAsyncRequestTypes(`${CUSTOMER_MASTER}UPDATE_AS`)
