import ReferenceGridCell from 'pages/CustomerMaster/components/ReferenceGridCell'
import moment from 'moment'
import { commonFormatGridCells } from 'pages/CustomerMaster/utils'

import LaunchEventModalCell from './components/LaunchEventModalCell'
import ActivityCompletedCell from './components/ActivityCompletedCell'
import DeleteGridCell from './components/DeleteGridCell'

const columnDefs = props => {
  let colDefs = [
    {
      cellClass: 'cell-value-hidden',
      cellRendererFramework: DeleteGridCell,
      field: 'dataId',
      form: props.form,
      headerName: '',
      filter: false,
      suppressMenu: true,
      width: 50,
      type: 'activity'
    },
    {
      cellRendererFramework: LaunchEventModalCell,
      form: props.form,
      field: 'subject',
      headerName: 'Subject'
    },
    { field: 'type', headerName: 'Type', width: 100 },
    {
      field: 'notes',
      headerName: 'Notes',
      tooltip: params => {
        if (params && params.value && params.value.length >= 30) {
          return params.value
        }
        return null
      }
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 100,
      cellClass: 'text-center align-center',
      valueFormatter: params => {
        if (params.data) {
          return params.value
            ? moment(new Date(params.value)).format('M/D/YY h:mm A')
            : params.value
        }
        return null
      }
    },
    {
      cellRendererFramework: ReferenceGridCell,
      cellRendererParams: {
        form: props.form
      },
      field: 'reference',
      headerName: 'Reference'
    },
    { field: 'reminder', headerName: 'Reminder', width: 150 },
    {
      cellClass: 'text-center',
      cellRendererFramework: ActivityCompletedCell,
      field: 'completed',
      form: props.form,
      headerClass: 'centered-label',
      headerName: 'Completed',
      width: 100
    },
    {
      field: 'enteredBy',
      headerName: 'Entered By',
      tooltipField: 'enteredByName',
      width: 100
    }
  ]

  colDefs = colDefs.reduce((acc, next) => {
    if (next.field === 'reference') {
      if (props.form === 'customerMaster') {
        acc = acc.concat(next)
      }
    } else {
      acc = acc.concat(next)
    }
    return acc
  }, [])

  // console.log(colDefs)

  return commonFormatGridCells(colDefs)
}

export default columnDefs
