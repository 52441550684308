import { Iterable, fromJS as _fromJS } from 'immutable'

/**
 * converts plain JS objects and arrays to Map or List,
 * leveraging Immutable's internal fromJS method
 * @param {object|array} jsValue - object or array to convert to List or Map
 * @returns {Map|List} as a Immutable style map or a list
 */
const fromJS = jsValue =>
  _fromJS(jsValue, (key, value) =>
    Iterable.isIndexed(value) ? value.toList() : value.toMap()
  )

export default fromJS
