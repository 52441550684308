import React from 'react'
import TooltipField from 'hoc/TooltipField'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { informBlue } from 'theme/colors'

const CellRenderer = ({ value }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        maxHeight: 30,
        display: 'flex',
        color: informBlue,
        fontWeight: 400
      }}
    >
      {value}
    </div>
  )
}

const PropsProxy = props => <CellRenderer {...props} />

const SalesOrderTooltipCell = TooltipField({
  anchorToGridCellText: true,
  keyToActionMap: {
    m: () =>
      openScreenAction({
        name: 'salesorder',
        title: 'Sales Order',
        image: 'sales_order_entry_16.png',
        route: 'salesorder',
        groupNames: ['header', 'detail'],
        newInstance: true
      })
  },
  indexSearchType: 'salesOrder',
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'salesOrder'
})(PropsProxy)

export default SalesOrderTooltipCell
