import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const getRouteForModalForms = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return form
  }

  return formParts[1]
}

const getRoute = form =>
  form && form.match(/salesOrder-/) ? 'salesOrder' : getRouteForModalForms(form)

/* start internal notes API methods */
export const lockDDISysInternalNotes = ({
  dataId,
  form,
  recordName,
  guid = null,
  templateKey = ''
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid
      }
    : { dataId, recordName, templateKey }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)
  const apiRoute = `${BASE_INFORM_API_URL}/${route}/notes/editlock`

  return callFetch(apiRoute, options)
}

export const unlockDDISysInternalNotes = ({
  dataId,
  form,
  recordName,
  guid = null,
  templateKey = ''
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid
      }
    : { dataId, recordName, templateKey }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)
  const apiRoute = `${BASE_INFORM_API_URL}/${route}/notes/canceledit`

  return callFetch(apiRoute, options)
}

export const saveDDISysInternalNotes = ({
  dataId,
  form,
  recordName,
  note,
  retainLock = false,
  guid = null,
  templateKey = ''
}) => {
  const apiOptions = guid
    ? {
        dataId,
        notesName: 'internalNotes',
        guid,
        note,
        retainLock
      }
    : { dataId, recordName, note, retainLock, templateKey }

  const options = createApiOptions({
    body: apiOptions,
    method: 'post'
  })

  const route = getRoute(form)
  const apiRoute = `${BASE_INFORM_API_URL}/${route}/notes/save`

  return callFetch(apiRoute, options)
}
/* end internal notes API methods */
