import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

import { setSelectedMultiRetainTaxable } from 'pages/SalesOrder/actions'

export const RetainPrompt = ({
  data: {
    form,
    lineItems = []
  }
}) => {
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()

  const onRowSelection = (e, lineNumber = null) => {
    let nSelected

    if (e.target.checked) {
      nSelected = selected.concat([lineNumber])
      setSelected(nSelected)
    } else {
      nSelected = selected.filter(x => x !== lineNumber)
      setSelected(nSelected)
    }

    dispatch(setSelectedMultiRetainTaxable(form, nSelected))
  }

  const renderDataTable = () => {
    if (lineItems.length) {
      return lineItems.map((row, i) => {

        return (
          <TableRow
            key={`${row.dataId} - ${row.lineNumber}`}
            role="checkbox"
            selected={selected.includes(row.lineNumber)}
            onClick={e => onRowSelection(e, row.lineNumber)}
          >
            <TableCell style={{ padding: 5, maxWidth: 25, width: 25 }}>
              <Checkbox
                style={{ height: 24, width: 24 }}
              />
            </TableCell>
            <TableCell style={{ textAlign: 'left' }}>
            {row.lineNumber}
          </TableCell>
          <TableCell style={{ textAlign: 'left' }}>
            {row.dataId}
          </TableCell>

          </TableRow>
        )
      })
    }

    return []
  }

  return (
    <div>
      <div>Do you wish to retain this taxable status for this customer?</div>
      <Table>
        <TableHead style={{ background: '#444', color: '#fff' }}>
          <TableCell style={{ width: 35 }}>&nbsp;</TableCell>
          <TableCell style={{ width: 35, textAlign: 'left', color: '#fff'}}>Ln</TableCell>
          <TableCell
            style={{
              color: '#fff',
              textAlign: 'left',
              width: 50
            }}
          >
            Product Number
          </TableCell>
        </TableHead>

        <TableBody>
          {renderDataTable()}
        </TableBody>
      </Table>
    </div>
  )
}

export default RetainPrompt