import { createAsyncRequestTypes } from 'utils'

export const TEMPLATE = '@@TEMPLATE@@/'

export const READ_TEMPLATE = createAsyncRequestTypes(`${TEMPLATE}READ_TEMPLATE`)

export const LOCK_TEMPLATE = createAsyncRequestTypes(`${TEMPLATE}LOCK_TEMPLATE`)

export const UNLOCK_TEMPLATE = createAsyncRequestTypes(
  `${TEMPLATE}UNLOCK_TEMPLATE`
)

export const PRE_UNLOCK_TEMPLATE = `${TEMPLATE}PRE_UNLOCK_TEMPLATE`

export const DELETE_TEMPLATE = createAsyncRequestTypes(
  `${TEMPLATE}DELETE_TEMPLATE`
)

export const SAVE_TEMPLATE = createAsyncRequestTypes(`${TEMPLATE}SAVE_TEMPLATE`)

export const EXIT_TEMPLATE = `${TEMPLATE}EXIT_TEMPLATE`
