/* eslint react/no-unused-state: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { required, maxLength } from 'ddiForm/validations'
import { Tooltip, CircularProgress, IconButton, Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { debounce } from 'lodash'
import { cancelCardReaderPayment } from 'pages/SalesOrder/actions'
import { DDITextField } from 'ddiForm/wrapped'

import '../styles/modal.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const overpaymentResponsePosting =
    getIn(formState, 'overpaymentResponsePosting') || false
  const manualIdPosting = getIn(formState, 'manualIdPosting') || false
  const cardProcessorInitialized =
    getIn(formState, 'cardProcessorInitialized') || false

  return {
    overpaymentResponsePosting,
    manualIdPosting,
    cardProcessorInitialized
  }
}

class SalesOrderNumberEntryModal extends Component {
  static propTypes = {
    appendToFinanceCharge: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    notesModalEnabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      value: ''
    }
  }

  // componentDidMount() {
  //   console.log(this)
  // }

  onChange = e => this.setState({ value: e.target.value, isDirty: true })

  onBlur = e => {
    if (this.state.isDirty) {
      const value = e.target.value
      this.setState({ isDirty: false }, () => this.props.onBlur(value))
    }
  }

  cancelCardReaderPayment = debounce(e => {
    const {
      data: { form },
      dispatch
    } = this.props

    dispatch(cancelCardReaderPayment.try(form))
  }, 300)

  render() {
    const {
      data: { option },
      overpaymentResponsePosting,
      manualIdPosting,
      cardProcessorInitialized
    } = this.props
    // const { value } = this.state

    return (
      <div style={{ width: '100%' }}>
        {option === 'processorPayment' &&
        cardProcessorInitialized &&
        manualIdPosting ? (
          <div
            style={{
              alignItems: 'center',
              background: 'rgba(26,188,247, 0.05)',
              borderRadius: 4,
              border: '1px solid #1abcf7',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
              padding: 5
            }}
          >
            <CircularProgress
              color="secondary"
              size={15}
              thickness={7}
              style={{ marginRight: 5 }}
            />
            <span style={{ color: '#1abcf7' }}>
              Please go ahead and swipe your card... this modal will close
              automatically after payment
            </span>
            <Tooltip title="Cancel Transaction">
              <IconButton onClick={this.cancelCardReaderPayment}>
                <Icon style={{ color: '#d9534f' }}>cancel</Icon>
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
        <Tooltip
          title="Enter desired Sales Order number and hit 'Tab' to accept"
          PopperProps={{
            style: {
              marginTop: -20
            }
          }}
          TransitionProps={{
            style: {
              fontSize: '11px'
            }
          }}
          placement="bottom-start"
        >
          <div className="manual-dataid-field-wrapper">
            <DDITextField
              propertyName="manualDataId"
              disabled={false}
              preventAutoDisable
              validate={[maxLength(8)]}
              label="Sales Order"
              inputProps={{
                maxLength: 8
              }}
            />
          </div>
        </Tooltip>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SalesOrderNumberEntryModal)
