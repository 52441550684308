/* eslint react/jsx-no-duplicate-props: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useState } from 'react'
import { withContext } from 'ddiForm'
import { handleCheckoutContact } from 'pages/SalesOrder/utils'
import {
  clearPaymentTransactionData,
  readCardTerminalStatus,
  initializePayment,
  cancelCardReaderPayment
} from 'pages/SalesOrder/actions'

import DDICardWrapper from 'components/DDICardWrapper'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'

import shortid from 'shortid'
import { debounce } from 'lodash'
import {
  Icon,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import { errorRed, successGreen } from 'theme/colors'
import { getField, getValue, getIn, empty } from 'utils'
import {
  PaymentButton,
  PaymentCancellationButton,
  paymentContactMeta
} from '../../../utils'

const tableCellStyle = {
  fontSize: 12,
  lineHeight: '15px',
  padding: '5px 10px',
  textAlign: 'right'
}
const leftTableCellStyle = {
  fontSize: 12,
  lineHeight: '15px',
  padding: '5px 10px'
}

const progressStyle = {
  alignItems: 'center',
  background: 'rgba(26,188,247, 0.05)',
  borderRadius: 4,
  border: '1px solid #1abcf7',
  display: 'flex',
  justifyContent: 'center',
  margin: '10px 0',
  padding: 5
}

const ProcessorPayment = ({
  form,
  dispatch,
  amountTendered,
  isEditing,
  cardTerminalStatusMap,
  isPosting,
  cardProcessorInitialized,
  saveCCInfo,
  customerId,
  checkoutContactDescription,
  paymentAttemptSuccess,
  isMobile = false,
  hidePaymentMessage = false,
  checkoutContact,
  orderedById,
  orderedByName
}) => {
  const cardTerminalStatus = cardTerminalStatusMap?.toJS
    ? cardTerminalStatusMap.toJS()
    : {}

  const [state, setState] = useState({
    addToVault: false,
    contactInputKey: shortid.generate()
  })

  useEffect(() => {
    /* functional component substitute for mount & componentWillUnmount */
    /* mount */
    dispatch(readCardTerminalStatus.try(form))
    handleCheckoutContact(
      dispatch,
      form,
      checkoutContact,
      checkoutContactDescription,
      orderedById,
      orderedByName
    )

    return () => {
      /* unmount */
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  useEffect(() => {
    if (paymentAttemptSuccess && !isPosting) {
      setState({
        ...state,
        addToVault: false,
        contactInputKey: shortid.generate()
      })
    }
  }, [paymentAttemptSuccess])

  const tryProcessCardReaderPayment = debounce(e => {
    dispatch(
      initializePayment.try(form, {
        amountTendered,
        option: 'processorPayment',
        addToVault: state.addToVault
      })
    )
  }, 300)

  const tryCancelCardReaderPayment = debounce(e => {
    dispatch(cancelCardReaderPayment.try(form))
  }, 300)

  const onToggle = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  if (
    cardTerminalStatus &&
    typeof cardTerminalStatus === 'object' &&
    Object.keys(cardTerminalStatus)?.length &&
    cardTerminalStatus?.offline &&
    cardTerminalStatus?.message
  ) {
    return (
      <div
        className="alert alert-warning"
        style={{
          ...progressStyle,
          background: '#fcf8e3',
          border: '1px solid #faebcc',
          margin: '10px 0'
        }}
      >
        <Icon size="small" style={{ marginRight: 5 }}>
          warning
        </Icon>
        <span>{cardTerminalStatus.message}</span>
      </div>
    )
  }

  if (
    cardTerminalStatus &&
    typeof cardTerminalStatus === 'object' &&
    Object.keys(cardTerminalStatus)?.length &&
    cardTerminalStatus?.status
  ) {
    return (
      <div style={{ paddingTop: 10 }}>
        {!hidePaymentMessage ? (
          <p
            style={{ margin: '0 0 10px 0', fontSize: 12, textAlign: 'center' }}
          >
            Please confirm payment amount and click &lsquo;Process&rsquo;
          </p>
        ) : null}
        <div>
          <DDICardWrapper
            title="Terminal Information"
            style={{ marginBottom: 0 }}
            contentStyle={{ padding: 0 }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={leftTableCellStyle}>Status</TableCell>
                  <TableCell
                    style={{
                      ...tableCellStyle,
                      color:
                        cardTerminalStatus.status === 'Online'
                          ? successGreen
                          : errorRed
                    }}
                  >
                    <div
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <span>{cardTerminalStatus?.status}</span>
                      {cardTerminalStatus.status === 'Online' ? (
                        <Icon
                          style={{
                            fontSize: 16,
                            color: successGreen,
                            marginLeft: 5
                          }}
                        >
                          check_circle
                        </Icon>
                      ) : (
                        <Icon
                          style={{
                            fontSize: 16,
                            color: errorRed,
                            marginLeft: 5
                          }}
                        >
                          warning
                        </Icon>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={leftTableCellStyle}>Screen</TableCell>
                  <TableCell style={tableCellStyle}>
                    {cardTerminalStatus?.screen}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={leftTableCellStyle}>Message</TableCell>
                  <TableCell style={tableCellStyle}>
                    {isPosting ? (
                      <div
                        style={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <span style={{ color: '#1abcf7' }}>Ready</span>
                        <Icon
                          style={{
                            fontSize: 16,
                            color: '#1abcf7',
                            marginLeft: 5
                          }}
                        >
                          payment
                        </Icon>
                      </div>
                    ) : (
                      <span>{cardTerminalStatus.message}</span>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DDICardWrapper>
        </div>
        {!isMobile ? (
          <div
            style={{ alignItems: 'flex-end', display: 'flex', width: '100%' }}
          >
            {saveCCInfo ? (
              <div
                style={{
                  margin: '0px 10px 5px 10px',
                  flexGrow: 1,
                  maxWidth: 190
                }}
              >
                <label
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '0.85em',
                    margin: '10px 0 5px 0',
                    transform: 'translate(0, 7px) scale(0.75)',
                    transformOrigin: 'top left'
                  }}
                >
                  Contact
                </label>
                <DDIIndexSearch
                  key={state.contactInputKey}
                  propertyName="checkoutContact"
                  parentId={customerId}
                  fullWidth
                  parentType="Customer"
                  popoverStyle={{
                    width: 750
                  }}
                  meta={paymentContactMeta}
                  descriptionKey="contact"
                  displayDescription
                  description={checkoutContactDescription}
                  inputProps={{
                    showQuickEntityButton: true
                  }}
                />
              </div>
            ) : null}
            <div
              style={{
                margin: '0px 10px 5px 10px',
                flexGrow: 1,
                maxWidth: 190
              }}
            >
              <AssociatedFieldsWrapper>
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.addToVault}
                      onChange={onToggle('addToVault')}
                      value="addToVault"
                    />
                  }
                  label="Add To Vault"
                />
              </AssociatedFieldsWrapper>
            </div>
          </div>
        ) : null}

        {isPosting && cardProcessorInitialized ? (
          <div style={{ ...progressStyle, margin: '10px 0 0 0' }}>
            <CircularProgress
              color="secondary"
              size={15}
              thickness={7}
              style={{ marginRight: 5 }}
            />
            <span style={{ color: '#1abcf7' }}>
              Please go ahead and swipe your card...
            </span>
          </div>
        ) : null}
        {amountTendered === 0 ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 10,
              width: '100%'
            }}
          >
            <div
              className="alert alert-warning"
              style={{
                backgroundColor: '#fcf8e3',
                borderColor: '#faebcc',
                padding: 5,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Icon size="small" style={{ marginRight: 5 }}>
                warning
              </Icon>
              <span>Payment Amount cannot be 0.00</span>
            </div>
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '0 5px 5px 5px'
          }}
        >
          <PaymentButton
            variant="contained"
            onClick={tryProcessCardReaderPayment}
            disabled={
              cardTerminalStatus.status !== 'Online' ||
              isPosting ||
              amountTendered === 0
            }
            style={{ margin: 10 }}
          >
            <Icon style={{ marginRight: 5 }}>
              {isPosting ? 'sync' : 'lock'}
            </Icon>{' '}
            {isPosting ? 'Processing' : 'Process'}
          </PaymentButton>
          <PaymentCancellationButton
            onClick={tryCancelCardReaderPayment}
            variant="contained"
            style={{ margin: 10 }}
            disabled={!cardProcessorInitialized}
          >
            <Icon style={{ marginRight: 5 }}>cancel</Icon> Cancel
          </PaymentCancellationButton>
        </div>
      </div>
    )
  }

  return (
    <div style={{ ...progressStyle, margin: '10px 0' }}>
      <CircularProgress
        color="secondary"
        size={15}
        thickness={7}
        style={{ marginRight: 5 }}
      />
      <span style={{ color: '#1abcf7' }}>Waiting for terminal...</span>
    </div>
  )
}

export default withContext({
  cardTerminalStatusMap: form => getIn(form, 'cardTerminalStatus') || empty,
  isPosting: form => form.get('isPosting') || false,
  cardProcessorInitialized: form =>
    form.get('cardProcessorInitialized') || false,
  saveCCInfo: form => getValue(form, 'saveCCInfo', false),
  customerId: form => getField(form, 'customerId', ''),
  checkoutContactDescription: form =>
    getField(form, 'checkoutContactDescription', '') ||
    getValue(form, 'checkoutContactDescription', '') ||
    '',
  paymentAttemptSuccess: form => form.get('paymentAttemptSuccess') || false,
  checkoutContact: form => getValue(form, 'checkoutContact', ''),
  orderedById: form => getField(form, 'orderedById', ''),
  orderedByName: form => getValue(form, 'orderedByName', '')
})(ProcessorPayment)
