import React from 'react'
import memoize from 'memoize-one'
import { ddiForm } from 'ddiForm'
import { Icon } from '@material-ui/core'
import {
  getReportCriteria,
  getLabelPrintingCriteria
} from 'components/SelectionCriteriaModal/actions'

import MeasuredModal from 'components/MasterScreen/MeasuredModal'
import Audit from 'components/MasterScreen/Audit'
import { mapAuditResponse } from 'components/MasterScreen/Audit/utils'
import Activities from 'components/MasterScreen/Activities'
import Attachments from 'components/MasterScreen/Attachments'
import Notes from 'components/MasterScreen/Notes'

import Contacts from 'pages/CustomerMaster/tabs/Contacts'

/* Custom Fields stuff */
import CustomFields from 'components/MasterScreen/CustomFields'
import {
  mapCustomFieldsResponse,
  checkIfCustomFieldsHidden
} from 'components/MasterScreen/CustomFields/utils'

/* template stuff */
import {
  lockTemplate,
  unlockTemplate,
  deleteTemplate,
  exitTemplate
} from 'components/MasterScreen/Template/actions'

/* Setup screens */
import Main from './tabs/Setup/Main'
import General from './tabs/Setup/General'
import Purchasing from './tabs/Setup/Purchasing'
import DropShipCharges from './tabs/Setup/DropShipCharges'
import Consignment from './tabs/Setup/Consignment'
import Warranty from './tabs/Setup/Warranty'
import Sources from './tabs/Setup/Sources'
import Patterns from './tabs/Setup/Patterns'
import JohnstoneSetup from './tabs/Setup/JohnstoneSetup'

/* Ledger screens */
import Invoices from './tabs/Ledger/Invoices'
import PaymentHistory from './tabs/Ledger/PaymentHistory'
import Transactions from './tabs/Ledger/Transactions'
import Orders from './tabs/Ledger/Orders'

/* main screens */
import Johnstone from './tabs/Johnstone'

import SearchArea from './components/SearchArea'
import RightFooterButtons from './components/RightFooterButtons'
import LeftFooterButtons from './components/LeftFooterButtons'
import vendorMasterSagas, {
  getGroupNamesSaga,
  cancelEditGroupNamesSaga
} from './sagas'
import {
  createVendor,
  deleteVendor,
  togglePreNewMode,
  tryTogglePreNewMode
} from './actions'
import behaviors from './behaviors'
import {
  isTabHidden,
  mapGetRecordResponse,
  mapActivitiesResponse,
  transformDataBeforeSave
} from './utils'
import { readVendorMaster } from './api'
import './styles/css-mod-ignore.scss'

const tabIconStyle = {
  fontSize: 18,
  marginBottom: 0,
  marginRight: 5,
  verticalAlign: 'middle'
}

const options = {
  actions: {
    createVendor: createVendor.try,
    deleteVendor: deleteVendor.try,
    getReport: getReportCriteria,
    getLabels: getLabelPrintingCriteria,
    togglePreNewMode,
    tryTogglePreNewMode,
    deleteTemplate,
    lockTemplate,
    unlockTemplate,
    exitTemplate
  },
  apiAlias: 'vendormaster',
  form: 'vendorMaster',
  title: 'Vendor Master',
  sagas: {
    onInit: vendorMasterSagas
  },
  masterOptions: {
    getRecordArgumentsSaga: getGroupNamesSaga,
    cancelEditGroupNamesSaga,
    transformDataBeforeSave,
    footer: {
      right: RightFooterButtons,
      left: LeftFooterButtons
    },
    headerComponent: SearchArea,
    tabs: [
      {
        icon: <Icon style={tabIconStyle}>settings_applications</Icon>,
        title: 'Setup',
        access: 'setup',
        tabs: [
          {
            callAlways: true,
            component: Main,
            title: 'Main',
            access: 'main'
          },
          {
            component: General,
            title: 'General',
            access: 'general'
          },
          {
            component: DropShipCharges,
            title: 'Drop Ship Charges',
            access: 'dropShip'
          },
          {
            component: Purchasing,
            title: 'Purchasing',
            access: 'purchasing'
            // isHidden: formState => !formState?.meta?.afflinkMember
          },
          {
            component: Notes,
            title: 'Notes',
            access: 'notes'
          },
          {
            component: Consignment,
            title: 'Consignment / MFG Rep',
            access: 'consignment'
          },
          {
            component: Warranty,
            title: 'Warranty',
            access: 'warranty'
          },
          {
            component: Sources,
            title: 'Sources',
            access: 'sources'
          },
          {
            component: Patterns,
            title: 'Patterns',
            access: 'patterns'
          },
          {
            component: CustomFields,
            title: 'Custom Fields',
            access: 'customfields',
            mapResponse: mapCustomFieldsResponse,
            isHidden: checkIfCustomFieldsHidden
          },
          {
            component: JohnstoneSetup,
            title: 'Johnstone',
            access: 'johnstoneSetup',
            isHidden: formState => !formState?.meta?.isJohnstoneCustomer
          }
        ]
      },
      {
        icon: <Icon style={tabIconStyle}>receipt</Icon>,
        title: 'Ledger',
        access: 'ledger',
        tabs: [
          {
            component: Invoices,
            title: 'Invoices',
            access: 'invoices'
          },
          {
            component: PaymentHistory,
            title: 'Payment History',
            access: 'paymentHistory'
          },
          {
            component: Transactions,
            title: 'Transactions',
            access: 'transactions'
          },
          {
            component: Orders,
            title: 'Orders',
            access: 'orders'
          }
        ],
        isHidden: form => isTabHidden(form)
      },
      {
        component: Activities,
        icon: <Icon style={tabIconStyle}>event</Icon>,
        title: 'Activities',
        access: 'activities',
        mapResponse: mapActivitiesResponse,
        isHidden: form => isTabHidden(form)
      },
      {
        component: Contacts,
        icon: <Icon style={tabIconStyle}>person</Icon>,
        title: 'Contacts',
        access: 'contacts',
        isHidden: form => isTabHidden(form),
        mapResponse: mapGetRecordResponse
      },
      {
        component: Johnstone,
        title: 'Johnstone',
        isHidden: formState => !formState?.values?.isCorporateVendor,
        access: 'johnstone'
      },
      {
        access: 'attachments',
        component: Attachments,
        icon: <Icon style={tabIconStyle}>attachment</Icon>,
        title: 'Attachments',
        mapResponse: mapGetRecordResponse
      },
      {
        icon: <Icon style={tabIconStyle}>search</Icon>,
        component: Audit,
        title: 'Audit',
        access: 'audit',
        mapResponse: mapAuditResponse,
        isHidden: form => isTabHidden(form)
      }
    ],
    mapResponse: mapGetRecordResponse
  },
  behaviors,
  getEntityApi: readVendorMaster,
  updateTitleMethod: ({ dataId, description }) =>
    `Vendor Master${dataId ? ` - ${dataId}` : ''}`
}

export const vendorMasterInModal = memoize(form =>
  ddiForm({
    ...options,
    form
  })(MeasuredModal)
)

export default ddiForm(options)()
