import React, { useState } from 'react'
import Grid from 'grid'
import { useDispatch } from 'react-redux'
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  LinearProgress
} from '@material-ui/core'
import MobileGrid from '../../grid'
import { partialMatchSearch } from './actions'

export default props => {
  const { apiParams = {}, rowData = [], returnTransactionId } = props
  const dispatch = useDispatch()
  const [gridRows, setRowData] = useState(rowData)
  const [value, setValue] = useState(returnTransactionId)
  const [isFetching, setIsFetching] = useState(false)

  const handleChange = async e => {
    const val = e.target.value
    let result

    setValue(val)

    try {
      setIsFetching(true)
      result = await dispatch(
        partialMatchSearch(props.form, {
          ...apiParams,
          returnTransactionId: val === 'all' ? null : val
        })
      )
    } catch (err) {
      setIsFetching(false)
    } finally {
      setIsFetching(false)
      if (result?.grid?.rowData && Array.isArray(result.grid.rowData)) {
        setRowData(result.grid.rowData)
      }
    }
  }

  return (
    <div
      style={{
        height: props?.height ? props?.height + 40 || 400 : 400,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: 5,
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '0.9em'
        }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ paddingLeft: 10 }}>
            <strong>Warehouse:</strong>
          </div>
          <div style={{ paddingLeft: 10 }}>
            {props.warehouseIdAndDescription}
          </div>
        </div>
        {returnTransactionId ? (
          <div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="invoice-selection"
                name="invoice-selection"
                value={value}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={returnTransactionId}
                  control={<Radio style={{ padding: 5 }} />}
                  label={`Invoice #${returnTransactionId}`}
                  style={{ marginBottom: 0 }}
                />
                <FormControlLabel
                  value="all"
                  control={<Radio style={{ padding: 5 }} />}
                  label="All"
                  style={{ marginBottom: 0 }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        ) : null}
      </div>
      <div>
        {isFetching ? <LinearProgress mode="indeterminate" /> : null}
        <MobileGrid
          {...props}
          columnDefs={props.columnDefs}
          meta={props.meta}
          rowData={gridRows}
          suppressCellSelection
        />
      </div>
    </div>
  )
}
