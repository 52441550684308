import { createAsyncRequestTypes } from 'utils'

const MERGE_MODAL = '@@MERGE_MODAL@@'

export const OPEN_MERGE_MODAL = createAsyncRequestTypes(
  `${MERGE_MODAL}OPEN_MERGE_MODAL`
)

export const MERGE = createAsyncRequestTypes(`${MERGE_MODAL}MERGE`)

export const DELETE_PENDING_MERGE = createAsyncRequestTypes(
  `${MERGE_MODAL}DELETE_PENDING_MERGE`
)

export const CLEAR_UOM_FIELDS = `${MERGE_MODAL}CLEAR_UOM_FIELDS`
