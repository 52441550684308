import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton, Button } from '@material-ui/core'
import FadeInAndOut from 'components/animation/FadeInAndOut'

import { noop } from 'utils'
// import styles from './styles/pagination.scss'
import './styles/pagination.scss'

class Pagination extends Component {
  static defaultProps = {
    onTab: noop,
    pageCount: 0,
    pageNumber: 0,
    paginationCb: noop,
    recordCount: 0
  }

  static propTypes = {
    // onTab: PropTypes.func,
    pageCount: PropTypes.number,
    pageNumber: PropTypes.number,
    paginationCb: PropTypes.func,
    recordCount: PropTypes.number
  }

  componentDidMount() {
    this._isMounted = true
    document.addEventListener('keydown', this.onKeydown)
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener('keydown', this.onKeydown)
  }

  onKeydown = event => {
    if (event?.altKey) {
      event.preventDefault()
      event.stopPropagation()
      this?.props?.gridRef?.current?.api.clearFocusedCell()
    }

    if (
      this._isMounted &&
      this?.props?.gridRef?.current?.api &&
      this?.props?.showCart &&
      this?.props?.saveCart &&
      event?.altKey &&
      (event?.keyCode === 83 || event?.key === 's' || event?.code === 'KeyS')
    ) {
      event.preventDefault()
      const nodes = this.props.gridRef.current.api.getRenderedNodes() || []
      const cartItems =
        nodes?.reduce((acc, next) => {
          if (next?.data?.cart) {
            acc = acc.concat(next.data)
          }
          return acc
        }, []) || []

      if (cartItems?.length) {
        this.props.saveCart()
      }
    }
  }

  onBtnNextClick = event => {
    this.props.paginationCb(this.props.pageNumber + 1)
  }

  onBtnPrevClick = event => {
    this.props.paginationCb(this.props.pageNumber - 1)
  }

  toggleFilter = e => {
    this.props.toggleFilter(!this.props.isFiltered)
  }

  render() {
    const {
      recordCount,
      pageNumber,
      pageCount,
      toggles,
      toggleFilter,
      showCart
      // isFiltered
    } = this.props

    let btn
    const nextDisabled = pageNumber === pageCount
    const prevDisabled = pageNumber <= 1
    if (toggleFilter) {
      // debugger
      const toggle = !!this.props.isFiltered
      const label = toggle ? toggles.toggled : toggles.default
      btn = (
        <div style={{ width: 250, display: 'flex' }}>
          <div style={{ padding: '4px' }}>
            <Button onClick={this.toggleFilter} variant="contained">
              {label}
            </Button>
          </div>
          {toggle && (
            <FadeInAndOut interval={2000}>
              <div
                style={{
                  backgroundColor: 'red',
                  borderRadius: '4px',
                  padding: '8px',
                  color: 'white',
                  marginLeft: '5px',
                  fontWeight: 500,
                  fontFamily: 'Roboto, sans-seriff'
                }}
              >
                {toggles.default}
              </div>
            </FadeInAndOut>
          )}
        </div>
      )
    }
    // console.log(toggles, toggleFilter, isFiltered)
    return (
      <div className="search-pagination">
        <div className="search-pagination-controls-inner-wrapper">
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div
              className="search-pagination-controls"
              style={{ marginRight: 15, width: 200 }}
            >
              <div className="arrow">
                <IconButton
                  disabled={prevDisabled}
                  onClick={this.onBtnPrevClick}
                  style={{
                    height: 16,
                    padding: 0,
                    width: 16,
                    backgroundColor: prevDisabled ? '#bdbdbd' : '#517b9c',
                    cursor: prevDisabled ? 'default' : 'pointer'
                  }}
                >
                  <Icon
                    style={{
                      color: '#fff',
                      height: 16,
                      // marginTop: -2,
                      width: 16,
                      fontSize: 16
                    }}
                  >
                    keyboard_arrow_left
                  </Icon>
                </IconButton>
              </div>
              <div>
                <p>
                  {pageNumber} of {pageCount} pages
                </p>
              </div>
              <div className="arrow">
                <IconButton
                  disabled={nextDisabled}
                  onClick={this.onBtnNextClick}
                  style={{
                    height: 16,
                    padding: 0,
                    width: 16,
                    backgroundColor: nextDisabled ? '#bdbdbd' : '#517b9c',
                    cursor: nextDisabled ? 'default' : 'pointer'
                  }}
                >
                  <Icon
                    style={{
                      color: '#fff',
                      height: 16,
                      // marginTop: -2,
                      width: 16,
                      fontSize: 16
                    }}
                  >
                    keyboard_arrow_right
                  </Icon>
                </IconButton>
              </div>
            </div>
            {toggleFilter && btn}

            <div
              style={{
                marginRight: 15,
                textAlign: 'right',
                width: showCart ? 350 : 150
              }}
            >
              {showCart ? (
                <Button
                  raised
                  onClick={this.props.saveCart}
                  variant="contained"
                  style={{ marginRight: 40 }}
                >
                  Save Cart
                </Button>
              ) : null}

              <p>{recordCount} Results</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// export default CSSModules(Pagination, styles)
export default Pagination
