import { createAsyncFormAction, createAsyncAction, createAction } from 'utils'

import * as CONSTANTS from './constants'

export const executeInquiry = createAsyncFormAction(CONSTANTS.EXECUTE_INQUIRY)

export const showPriceDetail = (form, { dataId, UOMId, gridCallbackFn }) =>
  createAction(
    CONSTANTS.SHOW_PRICE_DETAIL.TRY,
    { dataId, UOMId, gridCallbackFn },
    form
  )

export const savePriceDetailAsync = createAsyncFormAction(
  CONSTANTS.SAVE_PRICE_DETAIL
)

export const saveCustomerStockMinimumsAsync = createAsyncFormAction(
  CONSTANTS.SAVE_CUSTOMER_STOCK_MINIMUMS
)

export const deleteCustomerStockMinimumsAsync = createAsyncFormAction(
  CONSTANTS.DELETE_CUSTOMER_STOCK_MINIMUMS
)

export const showCustomerStockMinimums = (
  form,
  { dataId, UOMId, customerId, shipToId }
) =>
  createAction(
    CONSTANTS.SHOW_CUSTOMER_STOCK_MINIMUMS,
    {
      dataId,
      UOMId,
      customerId,
      shipToId
    },
    form
  )

export const showAdjustedPrice = (form, { dataId, UOMId, gridCallbackFn }) =>
  createAction(
    CONSTANTS.SHOW_ADJUSTED_PRICE.TRY,
    { dataId, UOMId, gridCallbackFn },
    form
  )

export const showReportOptionsModal = form =>
  createAction(CONSTANTS.SHOW_REPORT_OPTIONS_MODAL, {}, form)

export const updateLineItemQuantity = (
  form,
  { recordId, quantity, showErrorModal }
) =>
  createAction(
    CONSTANTS.UPDATE_LINE_ITEM_QUANTITY,
    {
      recordId,
      quantity,
      showErrorModal
    },
    form
  )

export const runCustomerOrderPadReportAysnc = createAsyncFormAction(
  CONSTANTS.RUN_CUSTOMER_ORDER_PAD_REPORT
)

// export const viewCustomerOrderPadReportAysnc = createAsyncAction(
//   CONSTANTS.VIEW_CUSTOMER_ORDER_PAD_REPORT,
//   CONSTANTS.customerOrderPad
// )

export const calculateOrderPadPriceAsync = createAsyncFormAction(
  CONSTANTS.CALCULATE_ORDER_PAD_PRICE
)

export const saveAdjustedPriceAsync = createAsyncFormAction(
  CONSTANTS.SAVE_ADJUSTED_PRICE
)

export const setEditablePrice = (form, { price, updateField }) =>
  createAction(CONSTANTS.SET_EDITABLE_PRICE, { price, updateField }, form)

export const setFilteredGridModelId = (form, { id }) =>
  createAction(CONSTANTS.SET_FILTERED_GRID_MODEL_ID, { id }, form)

export const getFilteredGridModel = (form, { id, grid }) =>
  createAction(CONSTANTS.GET_FILTERED_GRID_MODEL, { id, grid }, form)

export const showWarningModal = (form, { customerInput }) =>
  createAction(CONSTANTS.SHOW_WARNING_MODAL, { customerInput }, form)

export const removeGridEditedFlag = form =>
  createAction(CONSTANTS.REMOVE_GRID_EDITED_FLAG, {}, form)

export const setPriceDetailSavePermissions = (form, { canSave }) =>
  createAction(CONSTANTS.SET_PRICE_DETAIL_SAVE_PERMISSIONS, { canSave }, form)

export const showErrorMessage = (form, { message }) =>
  createAction(CONSTANTS.SHOW_ERROR_MESSAGE, { message }, form)

export const dismissAPCErrorMessage = form =>
  createAction(CONSTANTS.DISMISS_APC_ERROR_MESSAGE, {}, form)

export const updateLineItemPrice = (form, { dataId, uomId, price }) =>
  createAction(
    CONSTANTS.UPDATE_LINE_ITEM_PRICE,
    {
      dataId,
      price,
      uomId
    },
    form
  )

/* access override for report mdal */
export const callAccessOverrideModal = form =>
  createAction(CONSTANTS.CALL_ACCESS_OVERRIDE_MODAL, {}, form)

export const getGridCellTooltip = ({ recordName, indexSearchType }) => {
  const action = createAction(
    CONSTANTS.GET_GRID_CELL_TOOLTIP.REQUEST,
    { recordName, indexSearchType },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

export const loadCustomerRecord = (form, { data }) =>
  createAction(CONSTANTS.LOAD_CUSTOMER_RECORD, { data }, form)

export const setInitialEditablePrice = (form, { price, updateField }) =>
  createAction(
    CONSTANTS.SET_INITIAL_EDITABLE_PRICE,
    { price, updateField },
    form
  )

export const setQuotedPrice = createAsyncFormAction(CONSTANTS.SET_QUOTED_PRICE)

export const openSalesOrderScreen = (form, { isQuote, modalId }) =>
  createAction(CONSTANTS.OPEN_SALES_ORDER_SCREEN, { isQuote, modalId }, form)

export const compileDataForSalesOrder = (form, { modalId, lineItemsGridApi }) =>
  createAction(
    CONSTANTS.COMPILE_DATA_FOR_SALES_ORDER,
    { modalId, lineItemsGridApi },
    form
  )

export const addToSalesOrder = (
  form,
  { bulkProperties, shipToId, lineItemsGridApi }
) =>
  createAction(
    CONSTANTS.ADD_TO_SALES_ORDER,
    { bulkProperties, shipToId, lineItemsGridApi },
    form
  )

export const clearInquiryResults = (form, { propertyChanged = '' }) =>
  createAction(CONSTANTS.CLEAR_INQUIRY_RESULTS, { propertyChanged }, form)

export const revertSearchControlSelection = (
  form,
  { propertyName, value, description }
) =>
  createAction(
    CONSTANTS.REVERT_SEARCH_CONTROL_SELECTION,
    { propertyName, value, description },
    form
  )

export const clearIsEditingFlag = form =>
  createAction(CONSTANTS.CLEAR_IS_EDITING_FLAG, {}, form)
