import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import { formatDateFields, getField } from 'utils'
import memoize from 'memoize-one'
import { headerStyle } from 'pages/ProductSerialNumberInquiry/utils'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { DynamicGridDataTooltip } from 'hoc'
import SerialNumberGridCell from 'pages/ProductSerialNumberInquiry/components/SerialNumberGridCell'

import { setSelectedSerialNumber } from '../actions'

export const getRowNodeId = data => {
  const { dataId, stockTransferId, transferDate } = data
  return `${dataId} - ${stockTransferId} - ${transferDate}`
}

const dynamicColDefs = memoize(form => {
  const colDefs = [
    {
      cellRendererFramework: SerialNumberGridCell,
      cellRendererParams: {
        form
      },
      field: 'dataId',
      headerName: 'Serial No'
    },
    {
      cellRendererFramework: DynamicGridDataTooltip({
        dataField: 'notes'
      })(NotesCheckbox),
      cellRendererParams: {
        form,
        propertyName: 'transferHistory'
      },
      headerClass: 'text-center',
      headerName: 'Notes',
      field: 'notes'
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'stockTransferId',
      headerName: 'Transfer No'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'transferDate',
      headerClassName: 'text-center',
      headerName: 'Transfer Date',
      valueFormatter: formatDateFields
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'fromWhseId',
      headerName: 'From Whse'
    },
    {
      field: 'fromWhseName',
      headerName: 'From Whse Name'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'receivedDate',
      headerClass: 'text-center',
      headerName: 'Received Date',
      valueFormatter: formatDateFields
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'toWhseId',
      headerName: 'To Whse'
    },
    {
      field: 'toWhseName',
      headerName: 'To Whse Name'
    }
  ]

  return colDefs
})

export class TransferHistory extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    selectedSerialNumber: PropTypes.string
  }

  static defaultProps = {
    selectedSerialNumber: ''
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSerialNumber } = this.props
    if (prevProps.selectedSerialNumber !== selectedSerialNumber) {
      if (this.gridApi) {
        this.gridApi.onFilterChanged()
      }
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  onRowDoubleClicked = params => {
    const { dispatch, form } = this.props
    const value = params.data && params.data.dataId ? params.data.dataId : ''

    dispatch(setSelectedSerialNumber(form, { value }))
  }

  doesExternalFilterPass = node => {
    const { selectedSerialNumber } = this.props
    const { dataId } = node.data

    return selectedSerialNumber === dataId
  }

  isExternalFilterPresent = () => {
    const { selectedSerialNumber } = this.props

    return selectedSerialNumber !== ''
  }

  render() {
    const { form, height } = this.props

    return (
      <div className="product-serial-number-inquiry-layout-flex">
        <div className="flex-wrapper">
          <GridField
            propertyName="transferHistory"
            title="Transfer History"
            headerStyle={headerStyle}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            onGridReady={this.onGridReady}
            columnDefs={dynamicColDefs(form)}
            getRowNodeId={getRowNodeId}
            height={height}
            rowSelection="single"
            onRowDoubleClicked={this.onRowDoubleClicked}
          />
        </div>
      </div>
    )
  }
}

export const contextObj = {
  selectedSerialNumber: form => getField(form, 'selectedSerialNumber', '')
}

export default withContext(contextObj)(TransferHistory)
