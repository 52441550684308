// with
import { setIn, getIn, is } from 'utils'

export const boolFlip = bool => propertyName => (state, action) =>
  setIn(state, propertyName, is.bool(bool) ? bool : !getIn(state, propertyName))

export const withHasRecord = (bool = true) => (state, _) =>
  boolFlip(bool)('hasRecord')(state, _)

export const withToggleFetch = bool => (state, _) =>
  boolFlip(bool)('fetchingEntity')(state, _)

export const id = me => me
