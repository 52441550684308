import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen as openScreenAction } from 'pages/Main/actions'
// import PurchaseOrderFieldWithTooltip from 'modals/EventModal/components/PurchaseOrderFieldWithTooltip'
import HoverIntent from 'react-hoverintent'
import { Tooltip } from 'react-tippy'
import { connect } from 'react-redux'

// import { withContext } from 'ddiForm'
// import { getFieldOrValue } from 'pages/ProductMaster/utils'
import { getIn } from 'utils'
import { openFifoCost, openProductScreenFromModal } from '../actions'

const mapStateToProps = (state, ownProps) => {
  const formState = getIn(state, 'ddiForm.productMaster')

  return {
    uomId:
      getIn(formState, 'fields.selectedUOMId.value') ||
      getIn(formState, 'values.selectedUOMId') ||
      '',
    warehouseId:
      getIn(formState, 'fields.selectedWarehouseId.value') ||
      getIn(formState, 'values.selectedWarehouseId') ||
      ''
  }
}

export class OpenScreenLinkCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    warehouseId: PropTypes.string.isRequired,
    uomId: PropTypes.string.isRequired,
    hasLink: PropTypes.bool
  }

  static defaultProps = {
    hasLink: false
  }

  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      hoverIntended: false
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getLink = () => {
    const {
      data,
      hasLink,
      value,
      colDef: { linkTo }
    } = this.props

    if (!data.hasLink && !hasLink && value !== 'FIFO Cost') {
      return <div style={{ padding: 0 }}>{value}</div>
    }

    if (value === 'FIFO Cost' || linkTo || hasLink) {
      return (
        <a onClick={this.openScreen} style={{ padding: 0 }}>
          {value}
        </a>
      )
    }

    return <div style={{ padding: 0 }}>{value}</div>
  }

  hoverIntended = () => {
    if (!this._isMounted) {
      return
    }

    this.setState({ hoverIntended: true })
  }

  hoverNotIntended = () => {
    if (!this._isMounted) {
      return
    }

    this.setState({ hoverIntended: false })
  }

  openFifoCost = () => {
    const {
      dispatch,
      colDef: { form }
    } = this.props

    dispatch(openFifoCost.try(form, { dispatch }))
  }

  openScreen = () => {
    const {
      data: { dataId, productId, controller = '' },
      customerId,
      dispatch,
      form,
      value,
      warehouseId,
      uomId,
      serialProduct,
      colDef: { groupNames, linkTo, startTab }
    } = this.props

    // debugger
    if (value === 'FIFO Cost' && !linkTo) {
      this.openFifoCost()
    } else if (linkTo === 'purchaseOrder') {
      dispatch(
        openScreenAction({
          name: 'purchaseOrder',
          title: 'Purchase Order',
          dataId
        })
      )
    } else if (linkTo === 'stockTransfer') {
      dispatch(
        openScreenAction({
          name: 'stockTransfer',
          title: 'Stock Transfer Entry'
        })
      )
    } else if (
      (linkTo === 'invoice' && controller === 'InvoiceInquiry') ||
      linkTo === 'invoiceInquiry'
    ) {
      // debugger
      dispatch(
        openScreenAction({
          image: 'sales_invoicing_invoice_inquiry_16.png',
          route: 'invoiceinquiry',
          title: 'Invoice Inquiry',
          groupNames: ['header', 'detail'],
          dataId: value
        })
      )
    } else if (linkTo === 'salesOrder') {
      // debugger
      dispatch(
        openScreenAction({
          image: 'sales_order_entry_32.png',
          imageFileName16: 'sales_order_entry_16.png',
          description: 'Sales Order',
          name: 'salesOrder',
          route: 'salesorder',
          title: 'Sales Order',
          dataId: value,
          newInstance: true,
          read: true
        })
      )
    } else if (linkTo === 'productMaster') {
      dispatch(
        openProductScreenFromModal(form, {
          name: 'productMaster',
          title: 'Product Master',
          dataId: dataId || productId,
          warehouseId,
          uomId,
          groupNames,
          startTab,
          image: 'product_master_16.png'
        })
      )
    } else if (linkTo === 'productMasterScreen') {
      dispatch(
        openScreenAction({
          name: 'productMaster',
          title: 'Product Master',
          dataId: productId,
          warehouseId,
          uomId,
          groupNames,
          startTab,
          image: 'product_master_16.png'
        })
      )
    } else if (linkTo === 'serialNumber') {
      dispatch(
        openScreenAction({
          route: 'productserialnumberinquiry',
          title: 'Product Serial Number Inquiry',
          productId: serialProduct,
          groupNames: ['currentInventory', true]
        })
      )
    } else if (linkTo === 'workOrder') {
      dispatch(
        openScreenAction({
          name: 'wordOrder',
          title: 'Work Order'
        })
      )
    } else if (linkTo === 'warehouse' || linkTo === 'warehouseMaster') {
      // debugger
      dispatch(
        openScreenAction({
          activeKey: null,
          image: 'company_warehouse_16.png',
          route: 'warehousemaster',
          title: 'Warehouse Master',
          dataId: value,
          groupNames: ['general']
        })
      )
    } else if (linkTo === 'stockReceipts' || controller === 'StockReceipts') {
      // debugger
      dispatch(
        openScreenAction({
          activeKey: null,
          image: 'purchasing_stock_receipts_16.png',
          route: '',
          title: 'Stock Receipts',
          groupNames: ['header', 'receiving'],
          screenOpenData: {
            dataId: value
          }
        })
      )
    } else if (linkTo === 'lot') {
      dispatch(
        openScreenAction({
          name: 'lot',
          title: 'Lot'
        })
      )
    } else if (linkTo === 'customerMaster') {
      dispatch(
        openScreenAction({
          activeKey: 'setup',
          image: 'customer_master_16.png',
          route: 'customermaster',
          title: 'Customer Master',
          dataId: customerId,
          groupNames: ['setup', 'main']
        })
      )
    } else if (linkTo === 'customerLedger') {
      dispatch(
        openScreenAction({
          dataId: value,
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          name: 'customerLedger',
          title: 'Customer Ledger',
          activeKey: 'ledger',
          route: 'customermaster',
          groupNames: ['summary', 'invoices']
        })
      )
    } else if (linkTo === 'container') {
      dispatch(
        openScreenAction({
          name: 'containerManagement',
          title: 'Container'
        })
      )
    }
  }

  tooltipMarkup = null

  generateTooltip = () => {
    const {
      data: { tooltip }
    } = this.props

    if (tooltip) {
      const markup = (
        <div
          className="inform-tooltip"
          style={{ borderRadius: 4, padding: 10 }}
        >
          <span style={{ color: '#fff', fontSize: 11, margin: 0 }}>
            {tooltip}
          </span>
        </div>
      )

      this.tooltipMarkup = markup

      return this.tooltipMarkup
    }

    return this.tooltipMarkup
  }

  render() {
    return (
      <Tooltip
        open={this.state.hoverIntended}
        // ref={el => (this.tooltip = el)}
        html={<div>{this.generateTooltip()}</div>}
        unmountHTMLWhenHide
        position="bottom"
        popperOptions={{
          modifiers: { preventOverflow: { boundariesElement: 'window' } }
        }}
      >
        <div
          style={{
            display: 'block',
            width: '100%',
            height: '100%'
          }}
        >
          <HoverIntent
            onMouseOver={this.hoverIntended}
            onMouseOut={this.hoverNotIntended}
            sensitivity={20}
            interval={500}
          >
            <div
              style={{
                display: 'block',
                width: '100%',
                height: '100%'
              }}
            >
              <div>{this.getLink()}</div>
            </div>
          </HoverIntent>
        </div>
      </Tooltip>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(OpenScreenLinkCell)
