/* eslint import/prefer-default-export: 0 */
import { call, fork, put, take } from 'redux-saga/effects'
import * as CONSTANTS from 'pages/ProductMaster/constants'
import { addModal } from 'modals/actions'
import WebDescriptionEditor from 'pages/ProductMaster/tabs/Setup/components/modals/WebDescriptionEditor'
import { setField } from 'ddiForm/actions'

export function* openWebDescriptionEditorProcess(form) {
  const options = {
    component: WebDescriptionEditor,
    options: {
      title: 'Edit as HTML',
      maxHeight: 800,
      maxWidth: 800,
      data: {
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, formState, cb) {
              const { webDescription } = this.state
              try {
                await this.props.dispatch(
                  setField(form, 'webDescription', webDescription)
                )
              } finally {
                cb()
              }
            }
          },
          {
            primary: true,
            title: 'Exit'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
}

export function* openWebDescriptionEditorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.OPEN_WEB_DESCRIPTION_EDITOR)

    if (form === formListener) {
      yield call(openWebDescriptionEditorProcess, form)
    }
  }
}

export default function* webOptionsSagas(form) {
  yield fork(openWebDescriptionEditorListener, form)
}
