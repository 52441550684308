/* eslint import/prefer-default-export: 0 */
import { getIn, setIn, toCamelCase } from 'utils'
import { fromJS } from 'immutable'

export const setFastCustomerFields = (result, data = {}) => {
  const skipProps = [
    'validationMessages',
    'idMaxLength',
    'autoGenerateId',
    'isLocked'
  ]
  const descriptionMap = {
    arTermsId: 'arTermsDescription',
    branchId: 'branchDescription',
    categoryId: 'categoryDescription',
    salesmanId: 'salesmanDescription',
    taxId: 'taxDescription'
  }

  for (const prop in data) {
    if (!skipProps.includes(prop)) {
      let val = data[prop]
      val = prop && prop.match(/Description/) && val === null ? '' : val

      result = setIn(result, `fields.${prop}.value`, fromJS(val))
      result = setIn(result, `fields.${prop}.errorMessage`, '')
      result = setIn(result, `values.${prop}`, fromJS(val))

      if (descriptionMap[prop]) {
        const descriptionKey = descriptionMap[prop]
        let description = data[descriptionKey]
        description = description === null ? '' : description
        result = setIn(
          result,
          `fields.${prop}.description`,
          fromJS(description)
        )
      }
    } else {
      result = setIn(result, `values.${prop}`, fromJS(data[prop]))
    }

    if (
      prop === 'validationMessages' &&
      Array.isArray(data[prop]) &&
      data[prop].length
    ) {
      for (let i = 0; i < data[prop].length; i++) {
        const key = toCamelCase(data[prop][i].key)
        const errorMessage = data[prop][i].value
        result = setIn(result, `fields.${key}.errorMessage`, errorMessage)
      }
    }
  }

  return result
}

export const validateFastCustomerField = (val, formState, field) => {
  if (field && field.errorMessage) {
    return field.errorMessage
  }

  return null
}

export const indexSearchFields = {
  customer: {
    fieldName: 'Customer',
    searchType: 'Customer',
    hideToggle: false,
    hasFilters: true,
    allowSearchAll: false,
    allowInstantSearch: true,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Master',
    isSecure: false
  },
  shipToId: {
    fieldName: 'Ship To Id',
    searchType: 'ShipTo',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: false,
    allowInstantSearch: false,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Ship To Master',
    isSecure: false
  },
  branch: {
    fieldName: 'Branch',
    searchType: 'Branch',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Branch Master',
    isSecure: false
  },
  salesman: {
    fieldName: 'Salesman',
    searchType: 'Salesman',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Salesman Master',
    isSecure: false
  },
  shipVia: {
    fieldName: 'Ship Via',
    searchType: 'ShipVia',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: null,
    isSecure: false
  },
  custCategory: {
    fieldName: 'Category',
    searchType: 'CustCategory',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Category Master',
    isSecure: false
  },
  territory: {
    fieldName: 'Territory',
    searchType: 'Territory',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: null,
    isSecure: false
  },
  orderType: {
    fieldName: 'Order Type',
    searchType: 'OrderType',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Order Type Master',
    isSecure: false
  },
  parentPricing: {
    fieldName: 'Parent Pricing',
    searchType: 'Customer',
    hideToggle: false,
    hasFilters: true,
    allowSearchAll: false,
    allowInstantSearch: true,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Master',
    isSecure: false
  },
  parentCustomerPart: {
    fieldName: 'Parent Cust Part#',
    searchType: 'Customer',
    hideToggle: false,
    hasFilters: true,
    allowSearchAll: false,
    allowInstantSearch: true,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Master',
    isSecure: false
  },
  shipTo: {
    fieldName: 'Ship To',
    searchType: 'ShipTo',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: false,
    allowInstantSearch: false,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Ship To Master',
    isSecure: false
  },
  miscCharge: {
    fieldName: 'Miscellaneous Charge Programs',
    searchType: 'MiscChargeProgram',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: null,
    isSecure: false
  },
  terms: {
    fieldName: 'Terms',
    searchType: 'ARTerms',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open A/R Terms Master',
    isSecure: false
  },
  parentAR: {
    fieldName: 'Parent A/R#',
    searchType: 'Customer',
    hideToggle: false,
    hasFilters: true,
    allowSearchAll: false,
    allowInstantSearch: true,
    minimumKeywordLength: 2,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Master',
    isSecure: false
  },
  tax: {
    fieldName: 'Tax',
    searchType: 'Tax',
    hideToggle: true,
    hasFilters: false,
    allowSearchAll: true,
    allowInstantSearch: true,
    minimumKeywordLength: 0,
    allowNavigation: false,
    allowSpecialProducts: false,
    openText: 'Open Customer Tax',
    isSecure: false
  }
}

export const dropdownColumnFormatter = (col, i) => {
  col = {
    ...col
  }

  const width75Fields = ['category', 'salesman', 'terms', 'tax']
  const min125WidthFields = ['branch']
  const max125WidthFields = ['terms', 'tax']

  if (col.field && width75Fields.includes(col.field)) {
    col = {
      ...col,
      width: 75,
      minWidth: 75
    }
  }

  if (col.field && max125WidthFields.includes(col.field)) {
    col = {
      ...col,
      maxWidth: 125
    }
  }

  if (col.field && min125WidthFields.includes(col.field)) {
    col = {
      ...col,
      width: 75,
      minWidth: 125
    }
  }

  return col
}

export const categoryDropdownColumnFormatter = (col, i) => {
  col = {
    ...col
  }

  const width75Fields = ['category']
  const descriptionFields = ['description']

  if (col.field && width75Fields.includes(col.field)) {
    col = {
      ...col,
      width: 75,
      maxWidth: 125
    }
  }

  if (col.field && descriptionFields.includes(col.field)) {
    col = {
      ...col,
      width: 100,
      minWidth: 100,
      maxWidth: 600
    }
  }

  return col
}
