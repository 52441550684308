/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import {
  getRowNodeId,
  headerStyle,
  setGridHeight
} from 'pages/ProductMaster/utils'
import {
  launchBinSearchModal,
  unlockInventoryBinAssignment,
  lockInventoryBinAssignment,
  showCannotChangePrimaryBinModal,
  updateAnalysisBins,
  cancelUpdateAnalysisBins
} from 'pages/ProductMaster/actions'
import { addBlankRow } from 'ddiForm/actions'

import EditableGrid from 'components/EditableGrid'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import BinTooltipSearchCell from 'pages/ProductMaster/tabs/Setup/components/grids/BinTooltipSearchCell'
import { getIn, plainDeepEqual } from 'utils'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

const mapStateToProps = (state, ownProps) => {
  console.log('mapState', ownProps)
  const form =
    ownProps && ownProps.data && ownProps.data.form
      ? ownProps.data.form
      : ownProps.form
      ? ownProps.form
      : 'productMaster'
  // const { data: { form = 'productMaster' } } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const productId = getIn(formState, 'fields.dataId.value') || ''
  const uom = getIn(formState, 'fields.selectedUOMId.value') || ''
  const isWarehouseMaintainingBinQuantity =
    getIn(formState, 'values.isWarehouseMaintainingBinQuantity') || false
  const isEditing = getIn(formState, 'isEditing') || false
  const cannotChangePrimaryBin =
    getIn(formState, `fields.bins.${warehouseId}.cannotChangePrimaryBin`) ||
    false
  const rowData = getIn(formState, `fields.bins.${warehouseId}.rowData`)

  return {
    warehouseId,
    productId,
    uom,
    isWarehouseMaintainingBinQuantity,
    isEditing,
    cannotChangePrimaryBin,
    rowData: rowData && rowData.toJS ? rowData.toJS() : []
  }
}

const mapDispatchToProps = dispatch => ({
  addBlankRow: (form, { propertyName }) =>
    dispatch(addBlankRow(form, { propertyName })),
  launchBinSearchModal: form => dispatch(launchBinSearchModal(form)),
  lockInventoryBinAssignment: form =>
    dispatch(lockInventoryBinAssignment.try(form)),
  showCannotChangePrimaryBinModal: form =>
    dispatch(showCannotChangePrimaryBinModal(form)),
  updateAnalysisBins: (form, { cb }) =>
    dispatch(updateAnalysisBins.try(form, { cb })),
  cancelUpdateAnalysisBins: (form, { bins }) =>
    dispatch(cancelUpdateAnalysisBins(form, { bins })),
  unlockInventoryBinAssignment: (form, { cb, closeModal }) =>
    dispatch(unlockInventoryBinAssignment.try(form, { cb, closeModal }))
})

export class Bins extends Component {
  static propTypes = {
    cannotChangePrimaryBin: PropTypes.bool.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    isEditing: PropTypes.bool.isRequired,
    isWarehouseMaintainingBinQuantity: PropTypes.bool.isRequired,
    productId: PropTypes.string.isRequired,
    uom: PropTypes.string.isRequired,
    warehouseId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      test: 'data',
      rowData: props.rowData
    }
  }

  componentDidMount() {
    const { rowData } = this.props
    console.log('cdm', rowData)
    this.rowData = rowData
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!plainDeepEqual(nextProps.rowData, prevState.rowData)) {
      return {
        rowData: nextProps.rowData
      }
    }

    return null
  }

  getColumnDefs = props => {
    // console.log('getColumnDefs', props, this.props)
    const params = {
      disabled: !props.isEditing,
      form: props.form,
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    const { isEditing, productId, uom, warehouseId } = this.props
    const gridIsEditable =
      this.props.data && this.props.data.isModal ? true : isEditing

    const colDef = [
      {
        // cellRendererFramework: CheckboxCell,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          disabledFn: data => {
            if (this.props.data && this.props.data.isModal && data.isPrimary) {
              return true
            }

            if (this.props.data && this.props.data.isModal) {
              return false
            }

            if (
              (this.props.rowData && this.props.rowData.length <= 1) ||
              !this.props.isEditing
            ) {
              return true
            }

            if (this.props.cannotChangePrimaryBin) {
              return true
            }

            return false
          }
        },
        field: 'isPrimary',
        headerName: 'Primary',
        minWidth: 100,
        maxWidth: 100
      },
      {
        field: 'dataId',
        headerName: 'Bin Location',
        cellRendererFramework: BinTooltipSearchCell,
        cellRendererParams: {
          ...params,
          linkTo: 'warehouseBinLocations',
          productId,
          warehouseId,
          uom
        },
        minWidth: 200
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params,
          disabledFn: data => {
            if (this.props.isWarehouseMaintainingBinQuantity) {
              return true
            }

            return false
          }
        },
        headerName: '',
        hide: !gridIsEditable,
        minWidth: 50,
        maxWidth: 50
      }
    ]

    return colDef
  }

  addBlankRow = () => {
    const {
      cannotChangePrimaryBin,
      isWarehouseMaintainingBinQuantity,
      warehouseId
    } = this.props

    const form = 'productMaster'

    if (isWarehouseMaintainingBinQuantity) {
      if (cannotChangePrimaryBin) {
        this.props.showCannotChangePrimaryBinModal(form)
      } else {
        this.props.launchBinSearchModal(form)
      }
    } else {
      this.props.addBlankRow(form, { propertyName: `bins.${warehouseId}` })
    }
  }

  launchInventoryBinAssignmentEditor = () => {
    const form = 'productMaster'
    this.props.lockInventoryBinAssignment(form)
  }

  getContextMenuItems = params => {
    const form = 'productMaster'
    const { defaultItems = [] } = params
    const {
      cannotChangePrimaryBin,
      isEditing,
      isWarehouseMaintainingBinQuantity
    } = this.props

    if (this.props.data && this.props.data.isModal) {
      return [...defaultItems]
    }

    if (isWarehouseMaintainingBinQuantity && !isEditing) {
      return [
        {
          name: 'Bin Assignment',
          action: () => this.props.lockInventoryBinAssignment(form)
        },
        ...defaultItems
      ]
    }

    return [...defaultItems]
  }

  render() {
    const {
      rowData,
      height,
      warehouseId,
      isEditing,
      isWarehouseMaintainingBinQuantity
    } = this.props
    const form = 'productMaster'
    const gridHeight =
      this.props.data && this.props.data.isModal
        ? setGridHeight(rowData, 66)
        : height
    const wrapperStyle =
      this.props.data && this.props.data.isModal
        ? { paddingBottom: 35, width: '100%' }
        : { flex: '1 1', width: '50%' }

    const addButtonText = isWarehouseMaintainingBinQuantity
      ? 'Set Primary Bin'
      : 'Add Bin Location'

    const gridIsEditable =
      this.props.data && this.props.data.isModal ? true : isEditing

    return (
      <div className="layout-flex-centered">
        <div style={wrapperStyle}>
          <EditableGrid
            addButtonText={addButtonText}
            showAddButtonAlways={gridIsEditable}
            form={form}
            propertyName={`bins.${warehouseId}`}
            title="Bins"
            addBlankRow={this.addBlankRow}
            getRowNodeId={getRowNodeId}
            headerStyle={headerStyle}
            columnDefs={this.getColumnDefs}
            requiredCols={['dataId']}
            isEditing={gridIsEditable}
            height={gridHeight}
            rowSelection="single"
            width="100%"
            fullWidth
            gridWrapperStyle={{ flex: '1 1', maxWidth: '100%', width: '100%' }}
            getContextMenuItems={this.getContextMenuItems}
            focusCellOnNewRow="dataId"
            emptyRow={{
              isPrimary: false,
              dataId: null
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(Bins))
