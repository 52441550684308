import React from 'react'
import { Button, IconButton, Icon } from '@material-ui/core'

import { productSubstitute } from 'pages/SalesOrder/actions'

const SelectSubstituteBtn = ({
  dispatch,
  form,
  gridApi,
  value,
  data: { canSelect },
  isEditing,
  isMobile
}) => {
  const selectSubstitute = event =>
    dispatch(productSubstitute.try(form, { dataId: value, gridApi, isMobile }))

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <IconButton
        disabled={!canSelect || !isEditing}
        onClick={selectSubstitute}
      >
        <Icon
          style={{ color: canSelect ? '#517b9c' : '#999' }}
          fontSize="large"
        >
          swap_horizontal_circle
        </Icon>
      </IconButton>
    </div>
  )
}

export default SelectSubstituteBtn
