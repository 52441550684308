import {
  call,
  delay,
  fork,
  put,
  putResolve,
  select,
  take
} from 'redux-saga/effects'
import * as DDICONSTANTS from 'ddiForm/constants'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, is, fromJS } from 'utils'
import { api } from 'services'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import * as actions from 'pages/SalesOrder/actions'

import { updateLineItemProcess } from 'pages/SalesOrder/sagas/detailSagas'
import { propertyChangeProcess } from 'pages/SalesOrder/sagas/searchAreaSagas'
import CommentsEditor from 'pages/SalesOrder/tabs/Order/components/LineItemsSection/components/TransfersGrid/components/CommentsEditor'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CONFIRMED, CANCELED } from 'modals/constants'
import { addModal } from 'modals/actions'
import { destroyProductDetailFields } from 'mobile/pages/SalesOrder/actions'
import {
  mapLinePropertyChangeResponse,
  getDuplicateProductMessage,
  getSelectedRowLineNumberFromFormState
} from '../utils'

export function* changeGridItemProcess(
  form,
  gridName,
  key,
  value,
  action,
  gridApi,
  groupNames = ['header', 'detail'],
  isConfirmedProcess = false,
  mobileLineNumber = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const lineNumber =
    mobileLineNumber || getSelectedRowLineNumberFromFormState(formState)
  const childLineNumber = getIn(formState, 'fields.lineItems.childIndex')

  yield put(actions[action].request(form))

  const apiParams =
    key === 'substitute'
      ? {
          lineNumber,
          guid,
          gridName,
          properties: {
            substitute: {
              id: value,
              delete: isConfirmedProcess ? true : ''
            }
          },
          groupNames
        }
      : {
          lineNumber,
          guid,
          gridName,
          properties: {
            [key]: value
          },
          groupNames
        }
  if (childLineNumber != null) {
    apiParams.childLineNumber = childLineNumber
  }
  const { response, error } = yield call(api.changeGridItem, apiParams)

  if (response) {
    yield put(actions[action].success(response, form))

    if (gridApi && gridApi.forEachNode && action === 'productSubstitute') {
      gridApi.forEachNode(node => {
        if (node.data.lineNumber === lineNumber) {
          if (!node.isSelected()) node.setSelected(true)
        }
      })
    }
  } else if (error.status !== 496) {
    yield put(actions[action].failure(error, form))
  } else if (error.status === 496 && error.propertyToFillOnOk === 'delete') {
    const { modalTitle, message } = error
    yield call(confirmationModal, message, modalTitle)

    const modalAction = yield take([CONFIRMED, CANCELED])

    if (modalAction.type === CONFIRMED) {
      yield fork(
        changeGridItemProcess,
        form,
        gridName,
        key,
        value,
        action,
        gridApi,
        groupNames,
        true
      )
    } else {
      yield put(actions[action].failure({}, form))
    }
  } else {
    yield put(actions[action].failure(error, form))
  }
}

export function* handleMobileProductSubstitute(form, dataId) {
  const formState = yield select(getFormSelector(form))
  yield call(
    confirmationModal,
    `Are you sure you want to substitute this product for ${dataId}?`,
    'Product Substitute'
  )
  const modalAction = yield take([CONFIRMED, CANCELED])

  if (modalAction.type === CONFIRMED) {
    const mobileLineNumber = getSelectedRowLineNumberFromFormState(formState)
    yield put(destroyProductDetailFields(form))
    yield delay(100)
    yield fork(
      changeGridItemProcess,
      form,
      'lineItems',
      'substitute',
      dataId,
      'productSubstitute',
      null,
      ['header', 'detail'],
      false,
      mobileLineNumber
    )
  }
}

export function* productSubstituteListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataId, gridApi, isMobile }
    } = yield take(CONSTANTS.PRODUCT_SUBSTITUTE.TRY)

    if (form === formListener) {
      if (dataId) {
        if (isMobile) {
          yield fork(handleMobileProductSubstitute, form, dataId)
        } else {
          yield fork(
            changeGridItemProcess,
            form,
            'lineItems',
            'substitute',
            dataId,
            'productSubstitute',
            gridApi
          )
        }
      }
    }
  }
}

export function* checkRetainTaxableModal(form) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'fields.lineItems.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []
  const lineNumber = getSelectedRowLineNumberFromFormState(formState)
  const row = lineItems.find(x => x.lineNumber === lineNumber)

  if (row && row.shouldPromptForRetainTaxable) {
    yield call(
      confirmationModal,
      'Do you wish to retain this taxable status for this customer?',
      'Retain Taxable?'
    )

    const modalAction = yield take([CONFIRMED, CANCELED])

    if (modalAction.type === CONFIRMED) {
      yield fork(propertyChangeProcess, form, 'retaintaxable', null)
    }
  }
}

export function* triggerUpdateLineItemListener(formListener) {
  /* 
    trigger an update to a line item from the sidebar,
    e.g. change to promise date or assemblyRollupType -- SVE 11/27/2019
  */
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.TRIGGER_UPDATE_LINE_ITEM)

    if (form === formListener) {
      yield fork(updateLineItemProcess, form, payload)
    }
  }
}

export function* triggerPropertyChangeProcessListener(formListener) {
  /* 
    only used in applyPromiseDateAll in DetailsTab component.
    -- SVE 1/6/21
  */
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(CONSTANTS.TRIGGER_PROPERTY_CHANGE_PROCESS)

    if (form === formListener) {
      yield fork(propertyChangeProcess, form, propertyName, value)
    }
  }
}

export function* updateGridItemSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyChanged, fieldChanged }
    } = yield take(CONSTANTS.UPDATE_GRID_ITEM.SUCCESS)

    if (
      form === formListener &&
      propertyChanged &&
      propertyChanged === 'lineItems' &&
      fieldChanged &&
      fieldChanged === 'taxable'
    ) {
      yield fork(checkRetainTaxableModal, form)
    }
  }
}

export function* handleProcurementGridUpdateProcess(
  form,
  field,
  value,
  rowIndex,
  gridName = 'linkedTransfers',
  isRowDeletionHandler = false,
  gridApi = null,
  cb = null,
  isCommentDeletionAttempt = false
) {
  const formState = yield select(getFormSelector(form))
  const lineItems = getIn(formState, 'fields.lineItems.rowData')
  const selectedRowIndex = getIn(formState, 'fields.lineItems.selectedRowIndex')
  const row = lineItems.get(selectedRowIndex)
  const rowKey = gridName === 'linkedTransfers' ? 'fromWarehouseId' : 'dataId'
  const grid = row.get(gridName) || fromJS([{ [rowKey]: null }])
  // debugger

  let lineNumber = null

  if (is.number(selectedRowIndex) && lineItems.size) {
    lineNumber = lineItems.get(selectedRowIndex).get('lineNumber')
  }

  if (!lineNumber) {
    return
  }

  if (isCommentDeletionAttempt) {
    yield call(
      confirmationModal,
      'Are you sure you wish to delete this note?',
      'Notes'
    )
    const modalAction = yield take([CONFIRMED, CANCELED])

    if (modalAction.type === CANCELED) {
      return
    }
  }

  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  if (
    field === rowKey &&
    grid.findIndex(x => x.get(rowKey) === value) >= 0 &&
    grid.findIndex(x => x.get(rowKey) === value) !== rowIndex
  ) {
    yield fork(warningModal, `${value} already exists`, 'Attention!')
    return
  }

  yield put(actions.handleProcurementGridUpdate.request(form))

  /* 
    note: this API is a bit weird, 
    hence the bizarre logic here -- SVE 1/6/2021
  */
  const properties =
    field === rowKey
      ? { [field]: { key: null, value } }
      : isRowDeletionHandler
      ? {
          delete: {
            key:
              grid &&
              grid.get &&
              grid.get(rowIndex) &&
              grid.get(rowIndex).get(rowKey),
            value: null
          }
        }
      : {
          [field]: {
            key:
              grid &&
              grid.get &&
              grid.get(rowIndex) &&
              grid.get(rowIndex).get(rowKey),
            value
          }
        }

  const { response, error } = yield call(api.changeGridItem, {
    properties,
    lineNumber,
    guid,
    groupNames,
    gridName
  })

  if (response) {
    yield put(actions.handleProcurementGridUpdate.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }

    if (gridApi && gridApi.setFocusedCell) {
      setTimeout(() => {
        if (
          (gridName === 'shippingWhses' && field === 'dataId') ||
          (gridName === 'linkedTransfers' && field === 'fromWarehouseId')
        ) {
          gridApi.setFocusedCell(rowIndex, 'quantity')
        }
      }, 0)
    }
  } else {
    yield put(actions.handleProcurementGridUpdate.failure(error, form))
  }
}

export function* handleProcurementGridUpdateListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: {
        field,
        value,
        rowIndex,
        gridName,
        isRowDeletionHandler = false,
        gridApi = null,
        cb = null,
        isCommentDeletionAttempt = false
      }
    } = yield take(CONSTANTS.HANDLE_PROCUREMENT_GRID_UPDATE.TRY)

    if (form === formListener) {
      yield fork(
        handleProcurementGridUpdateProcess,
        form,
        field,
        value,
        rowIndex,
        gridName,
        isRowDeletionHandler,
        gridApi,
        cb,
        isCommentDeletionAttempt
      )
    }
  }
}

export function* handleOpenTransfersCommentEditorProcess(form, payload) {
  const { rowIndex, comments } = payload

  const modalOpts = {
    component: CommentsEditor,
    options: {
      data: {
        form,
        rowIndex,
        comments,
        actions: [
          {
            title: 'Save',
            primary: true,
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.handleProcurementGridUpdate.try(form, {
                    field: 'comments',
                    value: this.state.value,
                    rowIndex,
                    gridName: 'linkedTransfers',
                    cb
                  })
                )
              } catch (e) {
                console.log(e)
              } finally {
                if (cb && typeof cb === 'function') {
                  cb()
                }
              }
            }
          },
          {
            title: 'Delete',
            primary: true,
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.handleProcurementGridUpdate.try(form, {
                    field: 'comments',
                    value: '',
                    rowIndex,
                    gridName: 'linkedTransfers',
                    cb,
                    isCommentDeletionAttempt: true
                  })
                )
                if (cb && typeof cb === 'function') {
                  cb()
                }
              } catch (e) {
                console.log(e)
              } finally {
                if (cb && typeof cb === 'function') {
                  cb()
                }
              }
            }
          },
          {
            title: 'Cancel',
            primary: true
          }
        ]
      },
      width: 600,
      title: 'Stock Transfer Comments'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* handleOpenTransfersCommentEditorListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.HANDLE_OPEN_TRANSFERS_COMMENT_EDITOR)

    if (form === formListener) {
      yield fork(handleOpenTransfersCommentEditorProcess, form, payload)
    }
  }
}

export default function* infoPanelSagas(form) {
  yield fork(productSubstituteListener, form)
  yield fork(triggerUpdateLineItemListener, form)
  yield fork(triggerPropertyChangeProcessListener, form)
  yield fork(updateGridItemSuccessListener, form)
  yield fork(handleProcurementGridUpdateListener, form)
  yield fork(handleOpenTransfersCommentEditorListener, form)
}
