import React, { Component } from 'react'
import { TextField } from '@material-ui/core'
import { connect } from 'react-redux'

export class NotesModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      note: this.props.data.note ? this.props.data.note : ''
    }
  }

  onChange = event => {
    const val = event.target.value

    this.setState({
      note: val
    })
  }

  render() {
    // const { notesModalEnabled } = this.props.data
    const { note } = this.state

    return (
      <TextField
        name="internalNotesModal"
        value={note}
        multiline
        rows={5}
        onChange={this.onChange}
        variant="outlined"
      />
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(NotesModal)
