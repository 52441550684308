import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import Grid from 'grid'
import { AppBar, Typography, Tabs, Tab } from '@material-ui/core'
import {
  formatDollarFields,
  noDataMessage,
  numToDollar,
  plainDeepEqual
} from 'utils'

/* charts stuff */
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts' // eslint-disable-line
import highcharts3D from 'highcharts/highcharts-3d' // eslint-disable-line
import highchartsTheme from 'theme/highchartsTheme'

ReactHighcharts.Highcharts.setOptions(highchartsTheme)
highcharts3D(ReactHighcharts.Highcharts)

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

const getGridHeight = (array = []) => array.length * 28 + (32 + array.length)

const formatSummaryGrids = (col, i) => {
  col = {
    ...col
  }

  if (col.field === 'amount') {
    col = {
      ...col,
      valueFormatter: params => formatDollarFields(params)
    }
  }

  return col
}

class SummaryGrid extends Component {
  static propTypes = {
    defaultTab: PropTypes.string,
    columnDefs: PropTypes.array.isRequired,
    getRowNodeId: PropTypes.func.isRequired,
    gridClass: PropTypes.string,
    hasChart: PropTypes.bool,
    rowData: PropTypes.array,
    setAgingSummaryDefaultView: PropTypes.func,
    summaryExpanded: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string.isRequired
  }

  static defaultProps = {
    defaultTab: 'Data',
    gridClass: 'ag-grid-ledger-summary',
    hasChart: false,
    rowData: [],
    setAgingSummaryDefaultView: () => {},
    style: { flex: 1, marginRight: 10 }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // debugger
    if (nextProps.defaultTab !== prevState.tab && nextProps.hasChart) {
      return {
        tab: nextProps.defaultTab
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: props.defaultTab || 'Data'
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.tab !== this.state.tab && nextProps.hasChart) {
      return true
    }

    if (plainDeepEqual(nextProps.rowData, this.props.rowData)) {
      return false
    }

    return true
  }

  handleTabChange = (event, value) => {
    this.props.saveAgingView({ agingView: value })
    // this.props.setAgingSummaryDefaultView({ agingSummaryDefaultView: value })
  }

  pieChartConfig = () => {
    const { rowData } = this.props

    const pieData = rowData
      ? rowData.reduce((acc, next) => {
          if (next.amount) {
            acc = acc.concat({
              name: next.description,
              y: Math.abs(Number(next.amount)),
              negative: Number(next.amount) < 0
            })
          }
          return acc
        }, [])
      : []

    const config = {
      chart: {
        height: 200,
        options3d: {
          alpha: 45,
          beta: 0,
          enabled: true
        },
        spacingTop: -20,
        type: 'pie',
        width: 200
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false,
        symbolRadius: 2
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          depth: 35,
          showInLegend: true
        }
      },
      series: [{ data: pieData }],
      tooltip: {
        enabled: true,
        formatter() {
          const isNegativeNumber = this.point.negative
          return `<strong style="margin-right: 3px; display: inline-block;">${
            this.key
          }:</strong> <span style="color: ${
            isNegativeNumber ? '#d9534f' : '#444'
          }">${isNegativeNumber ? '-' : ''}${numToDollar(this.y)}</span>`
        }
      }
    }
    return config
  }

  dataIsParsable = rowData => {
    const total = rowData.reduce((acc, next) => {
      acc += next.amount
      return acc
    }, 0)

    return total > 0 || false
  }

  render() {
    const { tab } = this.state
    const {
      columnDefs,
      gridClass,
      hasChart,
      rowData,
      getRowNodeId,
      style,
      title
    } = this.props

    const renderedGrid =
      rowData && rowData.length ? (
        <div className={gridClass}>
          <Grid
            hideHeader
            getRowNodeId={getRowNodeId}
            rowData={rowData}
            columnDefs={columnDefs.map(formatSummaryGrids)}
            height={getGridHeight(rowData)}
            width="100%"
          />
        </div>
      ) : (
        noDataMessage()
      )

    return hasChart ? (
      <DDICardWrapper
        title={title}
        style={style}
        contentStyle={{ minHeight: 200, padding: 0 }}
      >
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs
            id="cl-summary-tabs"
            onChange={this.handleTabChange}
            value={tab}
          >
            <Tab value="Data" label="Grid" />
            <Tab value="Chart" label="Chart" />
          </Tabs>
        </AppBar>
        <TabContainer>
          {tab === 'Data' && renderedGrid}
          {tab === 'Chart' &&
            (rowData && rowData.length && this.dataIsParsable(rowData) ? (
              <div style={{ margin: '0 auto', width: 200 }}>
                <ReactHighcharts
                  config={this.pieChartConfig()}
                  ref={c => (this.chart = c)}
                />
              </div>
            ) : (
              noDataMessage()
            ))}
        </TabContainer>
      </DDICardWrapper>
    ) : (
      <DDICardWrapper
        title={title}
        style={style}
        contentStyle={{ minHeight: 200, padding: 0 }}
      >
        {renderedGrid}
      </DDICardWrapper>
    )
  }
}

export default SummaryGrid
