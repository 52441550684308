import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setSelectedSerialNumber } from 'pages/ProductSerialNumberInquiry/actions'

export const SerialNumberGridCell = ({ dispatch, form, value}) => {
  function setFilter() {
    dispatch(setSelectedSerialNumber(form, { value }))
  }

  return (
    <a
      onClick={setFilter}
    >
      {value}
    </a>
  )
}

SerialNumberGridCell.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default connect(
  null,
  null,
  null,
  { withContext: true }
)(SerialNumberGridCell)
