import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, Label } from 'ddiForm'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import { getIn } from 'utils'
import { required } from 'ddiForm/validations'
// import { validateSearchField } from 'pages/SalesOrder/utils'

import '../styles/search-area.scss'

const mapStateToProps = (state, ownProps) => {
  // const { form } = ownProps.data

  const defaultUserId = getIn(state, 'auth.dataId') || ''

  return {
    defaultUserId
  }
}

class VoidSalesOrderModal extends Component {
  componentDidMount() {
    console.log('void')
  }

  render() {
    const { defaultUserId } = this.props

    return (
      <div className="void-sales-order-wrapper">
        <div className="index-search-row-wrapper">
          <label className="void-sales-order-label">User:</label>
          <div className="search-row-input">
            <DDIIndexSearch
              propertyName="userId"
              metaKey="Writer"
              preventAutoDisable
              forwardRef
              inputRef={el => (this.userId = el)}
              autoFocus
              initialValue={defaultUserId}
              validate={[required]}
            />
          </div>
          <div className="search-row-description">
            <Field propertyName="userDescription" component={Label} />
          </div>
        </div>

        <div className="index-search-row-wrapper">
          <label className="void-sales-order-label">Reason:</label>
          <div className="reason-input-wrapper">
            <DDITextField
              propertyName="reason"
              fullWidth
              validate={[required]}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(VoidSalesOrderModal)
