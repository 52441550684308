import React, { memo } from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { formatNumber } from 'utils'
import { generateCellStyle } from 'pages/SalesOrder/utils'

import '../styles/bottom-tabs.scss'

export const PODollarLimit = memo(({ poSales, poBalance, poLimit }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className="bold-title-cell">Limit</TableCell>

          <TableCell style={generateCellStyle(poLimit)}>
            {formatNumber(poLimit)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="bold-title-cell">Sales</TableCell>

          <TableCell style={generateCellStyle(poSales)}>
            {formatNumber(poSales)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="bold-title-cell">Balance</TableCell>

          <TableCell style={generateCellStyle(poBalance)}>
            {formatNumber(poBalance)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
})

export default PODollarLimit
