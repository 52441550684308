import { List } from 'immutable'

const isSecureFieldDisabled = (
  propertyName,
  isEditing = false,
  securedFields = List()
) => {
  if (!isEditing) {
    return true
  }

  if (
    List?.isList(securedFields) &&
    securedFields?.find(x => x?.get('propertyName') === propertyName)
  ) {
    console.log('isSecureFieldDisabled', propertyName)
    return true
  }

  return false
}

export default isSecureFieldDisabled
