/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import EditableGrid from 'components/EditableGrid'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell/'
import IndexSearchCellWithTooltip from 'components/MasterScreen/IndexSearchCell/IndexSearchCellWithTooltip'

import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import { getIn, plainDeepEqual, toCamelCase } from 'utils'
import { addBlankRow as addBlankRowAction } from 'ddiForm/actions'

import DeleteGridCell from './DeleteGridCell'

// import { debounce } from 'lodash'
import {
  toggleEqualToCriteria as toggleEqualToCriteriaAction,
  setInitialRowData as setInitialRowDataAction
} from '../actions'

const mapStateToProps = (state, ownProps) => {
  const { cardData, form, type, initialRowData } = ownProps
  const formFields = getIn(state, `ddiForm.${form}.fields`)
  const propertyName = `selectionCriteria.${type}.grids.${toCamelCase(
    cardData.description
  )}`
  const initialRowDataSet =
    getIn(formFields, `${propertyName}.initialRowDataSet`) || false

  return {
    initialRowDataSet
  }
}

const mapDispatchToProps = dispatch => ({
  addBlankRow: (form, { propertyName }) =>
    dispatch(addBlankRowAction(form, { propertyName })),
  setInitialRowData: (form, { propertyName, rowData }) => {
    dispatch(setInitialRowDataAction(form, { propertyName, rowData }))
  },
  toggleEqualToCriteria: (
    form,
    { criteriaName, gridPath, selectedRows, rowIndex }
  ) =>
    dispatch(
      toggleEqualToCriteriaAction(form, {
        criteriaName,
        gridPath,
        selectedRows,
        rowIndex
      })
    )
})

class SelectionGrid extends Component {
  static propTypes = {
    cardData: PropTypes.object.isRequired,
    columnDefs: PropTypes.array,
    dataId: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.number,
    initialRowDataSet: PropTypes.bool.isRequired,
    rowData: PropTypes.array.isRequired,
    rowSelection: PropTypes.string,
    toggleEqualToCriteria: PropTypes.func.isRequired
  }

  static defaultProps = {
    columnDefs: [
      {
        cellClass: 'cell-value-hidden',
        checkboxSelection: true,
        field: 'isSelected',
        headerCheckboxSelection: false,
        headerName: '',
        width: 80
      },
      {
        field: 'description',
        headerName: ''
      }
    ],
    height: 180,
    rowSelection: 'multiple'
  }

  constructor(props) {
    super(props)

    this.state = {
      rowData: props.rowData
    }

    // this.onRowSelected = debounce(this.onRowSelected, 500)
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (!plainDeepEqual(prevProps.rowData, this.props.rowData)) {
  //     if (this.grid && this.gridApi) {
  //       this.checkSelectedGridItems(this.gridApi)
  //     }
  //   }
  // }

  componentDidMount() {
    this._isMounted = true
    // console.log('cdm', this.props, this)
    const {
      cardData,
      form,
      type,
      initialRowData,
      initialRowDataSet
    } = this.props

    if (initialRowData && !initialRowDataSet) {
      this.props.setInitialRowData(form, {
        propertyName: `selectionCriteria.${type}.grids.${toCamelCase(
          cardData.description
        )}`,
        rowData: initialRowData
      })
    }

    if (
      cardData &&
      cardData.description &&
      cardData.allowNewItems &&
      cardData.items.length === 0
    ) {
      setTimeout(() => {
        if (this._isMounted) {
          this.props.addBlankRow(form, {
            propertyName: `selectionCriteria.${type}.grids.${toCamelCase(
              cardData.description
            )}`
          })
        }
      }, 0)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
    // this.checkSelectedGridItems(api)
  }

  checkSelectedGridItems = api =>
    api.forEachNode(node => {
      if (node.data.isSelected) {
        node.setSelected(true)
      }
    })

  /* pretty sure this whole function can just go away now */
  onRowSelected = ({ node }) => {
    const { rowIndex } = node
    const {
      cardData: { dataId, items, description, multiSelect },
      form,
      toggleEqualToCriteria,
      type
    } = this.props

    if (this.gridApi) {
      const selectedRows = this.getSelectedRows(this.gridApi)
      const preSelectedRows = items.reduce((acc, next) => {
        if (next.isSelected) {
          acc = acc.concat(next.dataId)
        }
        return acc
      }, [])
      /* make sure we only fire off this action on an actual user interaction */
      /* not when ag-grid fires off its stupid callback to check the defaults */
      /* only do it for STATUS */
      if (multiSelect) {
        toggleEqualToCriteria(form, {
          gridPath: `fields.selectionCriteria.${type}.grids.${toCamelCase(
            description
          )}.rowData`,
          criteriaName: dataId,
          selectedRows,
          rowIndex
        })
      }
    }
  }

  getSelectedRows = gridApi => {
    let selectedRows = gridApi.getSelectedRows()
    selectedRows = selectedRows.length
      ? selectedRows.reduce((acc, next) => {
          acc = acc.concat(next.dataId)
          return acc
        }, [])
      : []

    return selectedRows
  }

  columnDefs = props => {
    const {
      cardData: { allowNewItems, description, searchType },
      form,
      type
    } = this.props

    const params = {
      form,
      indexSearchType: searchType,
      onChange: props.onCellChange,
      meta: this.getMeta(),
      propertyName: props.propertyName
    }

    const columnDefs = allowNewItems
      ? [
          {
            cellRendererFramework:
              searchType === 'None'
                ? IndexSearchCell
                : IndexSearchCellWithTooltip,
            cellRendererParams: params,
            cellStyle: {
              paddingLeft: 10,
              paddingRight: 0
            },
            editable: false,
            field: 'dataId',
            headerName: description,
            sortable: false
          },
          {
            cellRendererFramework: DeleteGridCell,
            // colsRequiredForDeletion: ['dataId'],
            field: 'description',
            form,
            propertyName: `selectionCriteria.${type}.grids.${toCamelCase(
              description
            )}`,
            headerName: '',
            criteriaSelectionType: type,
            type: 'deleteGridRow',
            width: 50,
            suppressNavigable: true
          }
        ]
      : [
          {
            cellClass: 'cell-value-hidden',
            field: 'isSelected',
            cellRendererFramework: ToggleCheckboxCell,
            cellRendererParams: {
              ...params,
              isDisabled: false
            },
            headerName: '',
            width: 50
          },
          {
            field: 'description',
            headerName: description
          }
        ]

    return columnDefs
  }

  getMeta = () => {
    const { cardData } = this.props

    return {
      allowInstantSearch: cardData.allowInstantSearch || false,
      allowInvalidValues: cardData.allowInvalidValues || false,
      allowNavigation: cardData.allowNavigation || false,
      allowSearchAll: cardData.allowSearchAll || false,
      hasFilters: cardData.hasFilters || false,
      hideToggle: cardData.hideToggle || false,
      minimumKeywordLength:
        cardData.minimumKeywordLength === 0 ? 1 : cardData.minimumKeywordLength
    }
  }

  render() {
    const {
      cardData: { allowNewItems, dataId, description, items, searchType },
      form,
      columnDefs,
      height,
      rowSelection,
      type,
      rowData
    } = this.props
    // const { rowData } = this.state
    return (
      <EditableGrid
        actOnCellChange
        addButtonText={`Add ${description}`}
        addButtonStyleParams={{
          variant: 'text'
        }}
        showAddButtonAlways={allowNewItems}
        form={form}
        propertyName={`selectionCriteria.${type}.grids.${toCamelCase(
          description
        )}`}
        getRowNodeId={data => data.dataId}
        height={182}
        columnDefs={this.columnDefs}
        emptyRow={{
          dataId: '',
          description: ''
        }}
        indexSearchType={searchType}
        onGridReady={this.onGridReady}
        // onRowSelected={this.onRowSelected}
        // onSelectionChanged={this.onSelectionChanged}
        // rowSelection={dataId === 'STATUS' ? 'multiple' : ''}
        ref={el => (this.grid = el)}
        gridWrapperStyle={{ flex: '1 1', overflow: 'visible' }}
        requiredCols={['dataId']}
        allowFocusWithEditModeOff
      />
    )
  }
}

// export default SelectionGrid
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(SelectionGrid)
