import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import IconTooltip from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/hoc/IconTooltip'
import { getIn, plainDeepEqual } from 'utils'

class DollarGridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!plainDeepEqual(nextProps.data, this.props.data) || nextProps.value !== this.props.value) {
      return true
    }

    return false
  }

  render() {
    return (
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
        <Icon style={{ color: '#444', display: 'inline-block', fontSize: 16 }}>
          payment
        </Icon>
      </div>
    )
  }
}

export default IconTooltip({ tooltipContent: 'Payment' })(DollarGridCell)

