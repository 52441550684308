import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const launchSelectionCriteria = (form, { entity }) =>
  createAction(CONSTANTS.LAUNCH_SELECTION_CRITERIA.TRY, { entity }, form)

export const setSelectionCriteria = (form, { type, cb }) =>
  createAction(CONSTANTS.SET_SELECTION_CRITERIA.TRY, { type, cb }, form)

export const clearSelectionCriteria = (form, { entity, clearInput = false }) =>
  createAction(
    CONSTANTS.CLEAR_SELECTION_CRITERIA.TRY,
    { entity, clearInput },
    form
  )

export const runOrderFormReport = createAsyncFormAction(
  CONSTANTS.RUN_ORDER_FORM_REPORT
)

export const clearFieldAndDescription = (form, { field }) =>
  createAction(CONSTANTS.CLEAR_FIELD_AND_DESCRIPTION, { field }, form)

// (form, { entity }) =>
//   createAction(CONSTANTS.RUN_SELECTION_CRITERIA.TRY, { entity }, form)
