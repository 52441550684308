import React from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { getIn } from 'utils'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { removeModal } from 'modals/actions'
import { getChangeInvoiceMeta, handleVoidProcess } from '../actions'
import { getChildAndParentForm } from '../utils'

const RightFooterButtons = ({
  canChange,
  canCredit,
  canVoid,
  form,
  orderId,
  dataId,
  id
}) => {
  const dispatch = useDispatch()

  const initSalesOrderCredit = debounce(e => {
    dispatch(
      openScreenAction({
        image: 'sales_order_entry_16.png',
        title: 'Sales Order',
        activeKey: '',
        route: 'salesorder',
        screenOpenData: {
          creditInvoice: dataId
        }
      })
    )
  }, 500)

  const initVoidProcess = debounce(e => {
    dispatch(
      handleVoidProcess.try(form, {
        modalId: id || null
      })
    )
  }, 500)

  const initChangeInvoiceProcess = debounce(e => {
    dispatch(getChangeInvoiceMeta.try(form))
  }, 500)

  const exitModal = e => {
    const { parentForm } = getChildAndParentForm(form)

    dispatch(removeModal(parentForm, id))
  }

  return (
    <>
      {canChange ? (
        <DDIButton
          variant="contained"
          label="Change"
          preventAutoDisable
          onClick={initChangeInvoiceProcess}
          style={{ marginRight: 5 }}
          tabIndex={0}
        />
      ) : null}
      {canCredit && !id ? (
        <DDIButton
          variant="contained"
          label="Credit"
          preventAutoDisable
          onClick={initSalesOrderCredit}
          style={{ marginRight: 5 }}
          tabIndex={0}
        />
      ) : null}
      {canVoid ? (
        <DDIButton
          variant="contained"
          label="Void"
          preventAutoDisable
          onClick={initVoidProcess}
          style={{ marginRight: 5 }}
          tabIndex={0}
        />
      ) : null}
      {id ? (
        <DDIButton
          variant="contained"
          label="Exit"
          onClick={exitModal}
          preventAutoDisable
          tabIndex={0}
        />
      ) : (
        <DDIButton
          variant="contained"
          label="Exit"
          actionName="exit"
          preventAutoDisable
          tabIndex={0}
        />
      )}
    </>
  )
}

export default withContext({
  canChange: form => getIn(form, 'values.canChange') || false,
  canCredit: form => getIn(form, 'values.canCredit') || false,
  canVoid: form => getIn(form, 'values.canVoid') || false,
  orderId: form => getIn(form, 'values.orderId') || '',
  dataId: form => getIn(form, 'values.dataId') || ''
})(RightFooterButtons)
