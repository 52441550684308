/* eslint react/sort-comp: 0, max-classes-per-file: 0 */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import shortid from 'shortid'
import { Set } from 'immutable'
import { debounce, isEqual, transform, isObject } from 'lodash'
import { BASE_INFORM_URL } from 'services/constants'
import 'react-reflex/styles.css'
import { withContext } from 'ddiForm'
import {
  addBlankRow,
  exit,
  showQuickEntity,
  setSelectedRowIndex,
  clearChildIndex
} from 'ddiForm/actions'
import { Icon, IconButton } from '@material-ui/core'
import withDimensions from 'hoc/withDimensions'
import { getFormSelector } from 'ddiForm/utils'
import {
  getIn,
  formatDollarFields,
  formatDateFields,
  formatNumber,
  plainDeepEqual,
  noop,
  is,
  emptyList,
  empty,
  makeRowDataUnique,
  fromJS
} from 'utils'
import * as localService from 'services/local'
import { setGridColsInLocal } from 'grid/utils'

import {
  storeUIFeatureState,
  openComments,
  copyProducts,
  launchItemGroupsModal,
  openCustomerOrderPadInModal,
  getReturnModalData,
  triggerReturnProductValidation,
  launchRepairItemModalInterface,
  launchCSVUploadInterface,
  launchCustomerPartNumberModal,
  launchAddItemGroupModal,
  initializeMultiSelect,
  openPromiseDateModal,
  launchRecalculateCostsModal,
  launchRecalculatePricesModal,
  saveLayoutForUserAuthentication,
  flagActiveGridLayout,
  openInvoiceHistoryModal,
  openCutLengthModal,
  getSOESalesmenCommissions,
  handleBackorderCommitmentSchedule,
  handleRebateInfoInteraction,
  handleRecurringOrderInteraction,
  openSpawnedOrdersModal,
  handleSwapCommittedQuantitiesInteraction,
  readLineItem,
  addComponentsRow,
  setSelectedSequenceNumber
} from 'pages/SalesOrder/actions'

import { openScreen as openScreenAction } from 'pages/Main/actions'
import {
  handleOpenGridInterfaceFeature,
  readCustomerStockMinimums
} from 'pages/InvoiceInquiry/actions'
import { show as showSnackbar } from 'snackbar/actions'
import SuccessAlert from 'pages/SalesOrder/components/SuccessAlert'
import ImageGridCell from 'pages/SalesOrder/components/ImageGridCell'

import PricingCell from 'pages/SalesOrder/tabs/Detail/components/PricingCellWrapper'
import RemoveLineItemCell from 'pages/SalesOrder/tabs/Detail/components/RemoveLineItemCell'
import TextFieldEditorCell from '../../components/ManualGrid/TextFieldEditorCell'
import IndexSearchEditor from '../../components/ManualGrid/IndexSearchEditor'
import ImageCellHeader from '../../components/ImageCellHeader'
import QuantityCell from '../../components/ManualGrid/QuantityCellWrapper'
import QuantityCellRenderer from '../../components/ManualGrid/QuantityCellRenderer'

/* NOTE: frameworkComponents are managed in the GridPanel component */
import StatusGridCell from '../../components/ManualGrid/StatusGridCell'
import GroupRowInnerRenderer from '../../components/ManualGrid/GroupRowInnerRenderer'
import IndexSearchRenderer from '../../components/ManualGrid/IndexSearchRenderer'
import ToggleCheckboxCell from '../../components/ManualGrid/ToggleCheckboxCell'
import headerNameMap from './headerNameMap'
import OrderInfo from './components/OrderInfo'
import LineItemsSection from './components/LineItemsSection'
import MultiLineTextRendererCell from '../../components/MultiLineTextRendererCell'
import DropdownArrow from '../../components/ManualGrid/DropdownArrow'
import './styles/css-mod-ignore.scss'

import {
  shouldPromptAdvancedCell,
  isGridLocalStorageRegistered,
  tryParseJSON,
  sortHiddenCols
} from '../../utils'
import AddItemGroupCell from '../../components/ItemGroups/AddItemGroupCell'
import AutoQuotesInput from './components/AutoQuotesInput'
import ComponentsGrid from './components/LineItemsSection/components/ComponentsGrid'
import getComponentsColumnDefinitions from './components/LineItemsSection/components/ComponentsGrid/getComponentsColumnDefinitions'

export const setGridHeight = memoize(
  (array = [], additionalHeight = 0, headerHeight = 32) =>
    array.length * 28 + (headerHeight + array.length) + additionalHeight
)

export const setExpandableGridRowHeight = memoize(
  (params, key = 'notes', headerHeight = 0) => {
    if (
      params.node &&
      params.node.detail &&
      params.data[key] &&
      params.data[key].length
    ) {
      return setGridHeight(params.data[key], 3, headerHeight)
    }

    return 28
  }
)

const editableOnlyWithProductSelected = params => {
  const { data: { dataId } = {} } = params
  console.log(params)
  return !!dataId
}

const editableOnlyWithQuantityAndSerialFlag = params => {
  const {
    data: { quantity, isSerialNumberRequiredResolved, useLotsResolved } = {}
  } = params
  console.log(params)
  return quantity && (isSerialNumberRequiredResolved || useLotsResolved)
}

// const canChangeQuantity = inquiryMode => params =>
//   !inquiryMode && params.data.canChangeQuantity && params.data.dataId

const greater = (a, b) => {
  if (a >= b) {
    return [a, 0]
  }
  return [b, 1]
}

const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })

const headerValueGetter = mapper => params => {
  return mapper[params.colDef.field]
    ? params.location !== 'toolPanel'
      ? mapper[params.colDef.field][0]
      : mapper[params.colDef.field][1] || mapper[params.colDef.field][0]
    : params.colDef.headerName || ''
}

// const suppressTabWhileEditing = params => {
//   const TAB = 9
//   const { value } = params.event.target
//   const { editing } = params
//   const { keyCode } = params.event
//   // if (keyCode === TAB)
//   let ret = false
//   if (keyCode === TAB && editing && !value) {
//     params.api.stopEditing(true)
//     ret = false
//   }
//   if (keyCode === TAB && editing && value) {
//     // params.api.stopEditing(true)
//     ret = true
//   }
//   return ret
// }

const setSelectedNodeFromIndex = (api, index) => {
  const node = api.getDisplayedRowAtIndex(index)
  if (node && !node.isSelected()) {
    // debuggers
    node.setSelected(true)
  }
}

function selectFirstNode(cb = noop) {
  const { hasItemGroups, isEditing } = this.props
  const targetIndex = hasItemGroups && !isEditing ? 1 : 0
  this.gridApi.deselectAll()
  const node = this.gridApi.getDisplayedRowAtIndex(targetIndex)
  // debugger
  if (node && !node.isSelected()) {
    setTimeout(() => {
      // debugger
      node.setSelected(true)
      cb()
    }, 0)
  }
}

const shouldHideToggle = memoize((searchFields = []) => {
  if (searchFields && Array.isArray(searchFields)) {
    const searchField = searchFields.find(x => x.searchType === 'Product')
    return searchField?.hideToggle ? searchField.hideToggle : false
  }
  return false
}, plainDeepEqual)

let autoQuotesInput

const StatusGridCellProxy = props => {
  if (props.comments && Array.isArray(props.comments)) {
    return (
      <div
        style={{
          background: '#fff',
          paddingLeft: 10,
          width: '100%',
          height: props.comments.length * 29
        }}
      >
        {props.comments
          .reduce((acc, next) => {
            if (
              next?.dataId === 'Return Invoice' &&
              props?.data?.returnInvoiceId
            ) {
              acc = acc.concat({
                ...next,
                returnInvoiceId: props?.data?.returnInvoiceId
              })
            } else if (next?.dataId === 'Warranty Tag') {
              acc = acc.concat({
                ...next,
                guid: props?.data?.guid || null,
                lineNumber: props?.data?.lineNumber,
                warrantyTagId: props?.data?.warrantyTagId
              })
            } else if (next?.dataId === 'Bins Comment') {
              acc = acc.concat(
                ...next.text.split('\r\n').reduce((a, n) => {
                  if (n) {
                    a = a.concat({
                      text: n,
                      color: next?.color ? next.color : { r: 0, g: 128, b: 0 },
                      dataId: shortid.generate()
                    })
                  }
                  return a
                }, [])
              )
            } else {
              acc = acc.concat(next)
            }

            return acc
          }, [])
          .map((x, i) => (
            <div
              style={{
                height: 28,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #e9e9e9'
              }}
            >
              <StatusGridCell
                key={x?.dataId}
                {...x}
                {...props}
                value={x?.text}
                data={{ ...x }}
              />
            </div>
          ))}
      </div>
    )
  }

  return ''
}

class Order extends Component {
  constructor(props) {
    super(props)
    const { form, userId } = this.props

    const gridLayoutTypeKey =
      form === 'invoiceInquiry'
        ? 'InvoiceInquiryGridLayoutType'
        : 'SOEGridLayoutType'

    const gridLayoutType =
      localService.get(`${gridLayoutTypeKey}-${userId}`) || 'company'

    this.state = {
      interfaceDimensions: {
        width: -1,
        height: -1
      },
      lineItemsSidebarDimensions: {
        width: -1,
        height: -1
      },
      expanded: props.orderAccordion || 'detail',
      layoutState: this.getLayoutState(),
      popupParent: document.querySelector('body'),
      gridLayoutKey: shortid.generate(),
      gridLayoutType
    }

    this.expandGridRows = debounce(this.expandGridRows, 500)
    this.expandGridRow = debounce(this.expandGridRow, 100)

    this.emitter = this.props.emitter
  }

  wasTouched = false

  componentDidMount() {
    this.sub = [
      this.emitter.addListener('touched', this.searchAreaTouched),
      this.emitter.addListener('getScreenRef', this.getScreenRef)
    ]
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = event => {
    if (event.shiftKey || event.ctrlKey) {
      // setShiftKeyPressed(true);
      this.shiftOrControl = true
    } else {
      this.shiftOrControl = false
    }
  }

  handleKeyUp = event => {
    this.shiftOrControl = false
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      form,
      customerId,
      dispatch,
      lineItemsList,
      isEditing,
      selectedRowIndex,
      hasItemGroups
    } = this.props

    if (this.gridApi) {
      if (!prevProps.lineItemsList.size && !this.props.lineItemsList.size) {
        selectFirstNode.call(this)
      } else if (
        ((this.props.hasRecord && !prevProps.hasRecord) ||
          (!this.props.hasRecord && prevProps.hasRecord)) &&
        lineItemsList.size
      ) {
        selectFirstNode.call(this)
      } else if (
        lineItemsList.size < prevProps.lineItemsList.size &&
        is.number(selectedRowIndex) &&
        dispatch &&
        isEditing &&
        form
      ) {
        /*
          IMPORTANT: if we have reached this condition, this means that a row
          in an order has been deleted and we need to update the selectedRowIndex
          AND possibly get additional data if we are looking at an already saved
          order -- SVE 9/30/2020
        */
        setSelectedNodeFromIndex(this.gridApi, selectedRowIndex)
        debugger
        dispatch(
          setSelectedRowIndex(form, {
            propertyName: 'lineItems',
            rowIndex: selectedRowIndex,
            gridApi: this.gridApi
          })
        )
      } else if (
        (isEditing && !prevProps.isEditing) ||
        (!isEditing && prevProps.isEditing)
      ) {
        /*
          if the user goes into or cancels out of Edit mode, the grid must focus
          on the first line because that is the only line item that has sidebar data
          after those actions -- SVE 5/7/2020
        */
        selectFirstNode.call(this)

        // debugger
        setTimeout(() => {
          if (this?.gridApi?.forEachNode) {
            this.gridApi.forEachNode(node => {
              node.setExpanded(false)
            })
          }
        }, 100)
      }

      if (
        this?.props?.customerName &&
        prevProps?.customerName !== this?.props?.customerName &&
        this?.props?.customerIdIsSet &&
        lineItemsList?.size
      ) {
        const hasReturns = lineItemsList.some(x => x.get('quantityOrdered') < 0)
        if (hasReturns) {
          this.props.dispatch(
            triggerReturnProductValidation(this.props.form, {
              gridApi: this.gridApi
            })
          )
        }
      }

      if (!prevProps.hasItemGroups && hasItemGroups && this.gridApi) {
        /* focus on line number 1 for any newly loaded item groups */
        setTimeout(() => {
          this.gridApi.deselectAll()
          this.gridApi.forEachNode(node => {
            if (node?.data?.lineNumber && node.data.lineNumber === 1) {
              // debugger
              node.setSelected(true)
            }
          })
        }, 0)
      }
    }

    if (
      prevState?.gridLayoutKey &&
      this?.state?.gridLayoutKey &&
      prevState?.gridLayoutKey !== this?.state?.gridLayoutKey &&
      this?.props?.dispatch
    ) {
      this.props.dispatch(
        showSnackbar({
          message: {
            message: 'Layout Updated',
            type: 'info',
            timer: 5000,
            id: this.state.gridLayoutKey,
            component: SuccessAlert(this.state.gridLayoutKey)
          }
        })
      )
    }
  }

  componentWillUnmount() {
    this.sub.forEach(x => x.remove())
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  handleAccordionToggle = panel => (event, isExpanded) => {
    /* automatically open Line Items grid when Order Info is collapsed */
    const expanded =
      panel === 'header' && !isExpanded ? 'detail' : isExpanded ? panel : false
    this.setState({ expanded }, () => {
      this.props.dispatch(
        storeUIFeatureState(this.props.form, {
          feature: 'orderAccordion',
          featureState: this.state.expanded
        })
      )
    })
  }

  onChildRowSelected = (...params) => console.log(params)

  getLayoutState = () => {
    const { userId } = this.props

    let layout = localStorage.getItem(`salesOrderEntryFlexLayout-${userId}`)
    layout = layout === 'undefined' || !layout ? null : JSON.parse(layout)

    if (layout && Object.keys(layout).length) {
      return layout
    }

    return {
      main: [75, 25],
      sidebar: [50, 50]
    }
  }

  onToggleInterface = (e, type) => {
    const { userId } = this.props

    this.setState(
      prevState => {
        return {
          layoutState:
            type === 'expand'
              ? {
                  ...prevState.layoutState,
                  main: [100, 0]
                }
              : {
                  ...prevState.layoutState,
                  main: [0, 100]
                }
        }
      },
      () => {
        localService.set(
          `salesOrderEntryFlexLayout-${userId}`,
          this.state.layoutState
        )

        if (type === 'expand') {
          this.expandGridRows()
        }
      }
    )
  }

  onRowSelected = (params, isMounted) => {
    console.log(params, isMounted)
    if (isMounted && params?.node?.detailNode) {
      // params.node.detailNode.setSelected(false)
      params.api.forEachDetailGridInfo(info => {
        info.api.deselectAll()
      })
    }
  }

  onResizeLineItemDetailGrid = contentRect =>
    this.setState({ lineItemsSidebarDimensions: contentRect.bounds })

  onResizeInterface = contentRect =>
    this.setState({ interfaceDimensions: contentRect.bounds })

  expandGridRows = () => {
    const { interfaceDimensions, lineItemsSidebarDimensions } = this.state
    const { expandComments } = this.props
    const sidebarWidthPct = this.getSidebarWidth(
      interfaceDimensions,
      lineItemsSidebarDimensions
    )

    if (expandComments && this.gridApi) {
      if (sidebarWidthPct < 0.02) {
        this.gridApi.forEachNode(node => {
          if (node.data.comments && node.data.comments.length) {
            node.setExpanded(true)
          }
        })
      } else {
        this.gridApi.forEachNode(node => {
          node.setExpanded(false)
        })
      }
    }
  }

  expandGridRow = rowIndex => {
    const { interfaceDimensions, lineItemsSidebarDimensions } = this.state
    const { expandComments } = this.props
    const sidebarWidthPct = this.getSidebarWidth(
      interfaceDimensions,
      lineItemsSidebarDimensions
    )

    if (expandComments && this.gridApi) {
      if (sidebarWidthPct < 0.02) {
        this.gridApi.forEachNode(node => {
          if (node.data.comments && node.data.comments.length) {
            if (node.rowIndex === rowIndex) {
              node.setExpanded(true)
            }
          }
        })
      }
    }
  }

  getSidebarWidth = memoize(
    (interfaceDimensions, lineItemsSidebarDimensions) => {
      const sidebarWidthPct =
        lineItemsSidebarDimensions.width / interfaceDimensions.width

      return sidebarWidthPct
    }
  )

  getToggleNavIcons = memoize((uiCompacted, popupEditorOpen) => {
    const toggleNavStyle = uiCompacted
      ? {
          position: 'absolute',
          top: 8,
          right: 2,
          zIndex: 998,
          display: popupEditorOpen ? 'none' : 'block'
        }
      : {
          position: 'absolute',
          top: 3,
          right: 2,
          zIndex: 998,
          display: popupEditorOpen ? 'none' : 'block'
        }
    const toggleNavIconStyle = { color: '#444', fontSize: 16 }

    return (
      <div className="toggle-nav-icons" style={toggleNavStyle}>
        <IconButton
          onClick={e => this.onToggleInterface(e, 'collapse')}
          size="small"
        >
          <Icon style={toggleNavIconStyle}>keyboard_arrow_left</Icon>
        </IconButton>
        <IconButton
          onClick={e => this.onToggleInterface(e, 'expand')}
          size="small"
        >
          <Icon style={toggleNavIconStyle}>keyboard_arrow_right</Icon>
        </IconButton>
      </div>
    )
  })

  detailCellRendererParams = prms => {
    console.log('detailCellRendererParams', prms?.data?.components)
    if (!prms?.data?.components?.length) {
      /* there are only comments to display */
      return {
        refreshStrategy: 'everything',
        detailGridOptions: {
          rowSelection: 'multiple',

          suppressRowClickSelection: true,
          suppressHorizontalScroll: true,
          width: '100%',
          columnDefs: [
            {
              cellRendererFramework: StatusGridCell,
              cellRendererParams: {
                dispatch: this.props.dispatch,
                form: this.props.form
              },
              field: 'text',
              headerName: ''
            }
          ],
          getRowNodeId: data => data.rowId,
          onFirstDataRendered: params => params.api.sizeColumnsToFit()
        },
        getDetailRowData: params => {
          // let rowData
          // if (params.data.comments)
          let detailRowComments =
            params?.data?.comments && Array.isArray(params.data.comments)
              ? params.data.comments.reduce((acc, next) => {
                  if (
                    next?.dataId === 'Return Invoice' &&
                    params?.data?.returnInvoiceId
                  ) {
                    acc = acc.concat({
                      ...next,
                      returnInvoiceId: params?.data?.returnInvoiceId
                    })
                  } else if (next?.dataId === 'Warranty Tag') {
                    acc = acc.concat({
                      ...next,
                      guid: this?.props?.guid || null,
                      lineNumber: params?.data?.lineNumber,
                      warrantyTagId: params?.data?.warrantyTagId
                    })
                  } else if (next?.dataId === 'Bins Comment') {
                    acc = acc.concat(
                      ...next.text.split('\r\n').reduce((a, n) => {
                        if (n) {
                          a = a.concat({
                            text: n,
                            color: next?.color
                              ? next.color
                              : { r: 0, g: 128, b: 0 },
                            dataId: shortid.generate()
                          })
                        }
                        return a
                      }, [])
                    )
                  } else {
                    acc = acc.concat(next)
                  }

                  return acc
                }, [])
              : []

          detailRowComments = makeRowDataUnique(detailRowComments, 'rowId')
          params.successCallback(detailRowComments)
          // params?.api.forEachDetailGridInfo(detailGridInfo => {
          const detailColumnState = params?.node?.detailGridInfo?.columnApi?.getColumnState()
          if (detailColumnState?.length) {
            params.node.detailGridInfo.api.setHeaderHeight(0)
          }
          // })
        }
      }
    }

    const { hasRecord, isEditing, form, customerId } = this.props
    const rowId = prms?.data?.rowId
    const parentLineNumber = prms?.data?.lineNumber
    let comments = prms?.data?.comments

    const propertyName = `lineItemComponents.${rowId}`

    const hideToggle = true
    const showCart = false
    const inquiryMode = Boolean(hasRecord && !isEditing)
    const editableIfCanChangeQuantityNoInquiryMode = p => {
      return p.data && p.data.canChangeQuantity && !inquiryMode
    }

    const editorParams = {
      disabled: inquiryMode,
      focusedCell: 'quantityExtended',
      form,
      propertyName,
      onChange: this.props.onCellChange,
      hasRecord,
      isEditing
    }

    const textEditor = {
      cellEditor: 'textFieldEditor',
      cellEditorParams: editorParams
    }

    const frameworkComponents = {
      indexSearchEditor: IndexSearchEditor,
      textFieldEditor: TextFieldEditorCell,
      removeLineItemCell: RemoveLineItemCell,
      imageCellHeader: ImageCellHeader,
      quantityCellEditor: QuantityCell,
      quantityCellRenderer: QuantityCellRenderer,
      pricingCellEditor: PricingCell
    }

    const onCellValueChanged = params => {
      if (params.newValue !== params.oldValue) {
        this.props.dispatch({
          type: 'CELL_CHANGED',
          payload: {
            ...params,
            propertyName
          },
          meta: { form: this.props.form }
        })
      }
    }

    const onCellFocused = params => {
      const { api } = params

      const initEditingOnFocus = ['pricing', 'quantityChange']

      if (
        params?.column?.colId &&
        initEditingOnFocus.includes(params.column.colId)
      ) {
        api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params?.column?.colId
        })
      }
    }

    const columnDefs = getComponentsColumnDefinitions({
      propertyName,
      form,
      hideToggle,
      showCart,
      inquiryMode,
      editorParams,
      textEditor,
      editableIfCanChangeQuantityNoInquiryMode,
      dispatch: this.props.dipsatch,
      customerId,
      isEditing
    })

    const ret = {
      refreshStrategy: 'rows',
      detailGridOptions: {
        rowSelection: 'multiple',
        autoSize: false,
        applyColumnDefOrder: true,
        suppressHorizontalScroll: false,
        frameworkComponents,
        onCellValueChanged,
        onCellFocused,
        onRowSelected: params => {
          const {
            data: { uniqueKey },
            node: { selected, setSelected /* id */ }
          } = params

          if (!selected && !this.shiftOrControl) {
            this.props.dispatch(
              clearChildIndex(form, {
                propertyName: 'lineItems',
                // rowIndex: selectedRowIndex,
                rowIndex: true,
                gridApi: this.gridApi
              })
            )
            return
          }
          this.gridApi?.forEachDetailGridInfo((info, num) => {
            // check the info
            const id = info.id.split('detail_')[1]
            console.log(info, id, rowId)
            if (id !== rowId) {
              info.api.forEachNode(n => n.setSelected(false, false, true))
            }
            // info.api.deselectAll()
          })
          this.gridApi?.deselectAll()
          if (this.shiftOrControl) return
          // this.gridApi?.deselectAll()
          setTimeout(() => {
            params.node.setSelected(true, true, true)
            console.log(this.props)
            debugger
            this.props.dispatch(
              setSelectedRowIndex(form, {
                propertyName: 'lineItems',
                // rowIndex: selectedRowIndex,
                rowIndex: prms.data.lineNumber,
                childIndex: params.data.lineNumber,
                gridApi: this.gridApi
              })
            )
          }, 0)
          // if (!selected) return
          // this?.gridApi?.forEachNode(node => {
          //   // test
          //   if (node.id !== id && node.isSelected()) {
          //     node.setSelected(false)
          //   } else if (node.id === id) {
          //     node.setSelected(true)
          //   }
          // })
          // setTimeout(() => this.gridApi?.deselectAll(), 0)
        },
        onColumnVisible: params => {
          const displayedColumns = params.columnApi.getAllDisplayedColumns()
          if (displayedColumns) {
            const visibleColumns = displayedColumns.reduce((acc, next) => {
              if (next?.colId) {
                acc = acc.concat(next.colId)
              }
              return acc
            }, [])
            localService.set('componentsGrid', JSON.stringify(visibleColumns))
          }

          setTimeout(() => {
            if (params?.api) {
              params.api.sizeColumnsToFit()
            }
          }, 0)
        },
        columnDefs,
        defaultColDef: {
          suppressMovable: false,
          lockPinned: true,
          resizable: true
        },
        sideBar: {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressSideButtons: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true
              }
            }
          ]
        },
        immutableData: true,
        getRowNodeId: data => data.uniqueKey,
        onFirstDataRendered: params => {
          setTimeout(() => {
            if (params?.api) {
              params.api.sizeColumnsToFit()
            }
          }, 0)
        },
        rememberGroupStateWhenNewData: true,
        onGridReady: params => {
          const { api } = params
          // console.log('onComponentsGridReady', params)

          setTimeout(() => {
            api.forEachNode(node => {
              console.log(node?.data)
              if (
                (node?.data?.rowId === 'blankrow' || !node?.data?.rowId) &&
                !node?.data?.dataId
              ) {
                // debugger
                if (node && api) {
                  api.deselectAll()
                  // debugger
                  node.setSelected(true)
                  api.startEditingCell({
                    rowIndex: node.rowIndex,
                    colKey: 'dataId'
                  })
                }
              }
            })
          }, 100)
        },
        onRowDataUpdated: p => {
          const { lineItemsList } = this.props
          const lineItems = lineItemsList?.toJS ? lineItemsList.toJS() : []
          const lineItemComments =
            lineItems?.find(x => x?.rowId === rowId)?.comments || null
          // const comments = prms?.data?.comments
          const { api } = p
          const hasBlankRow = prms?.data?.components?.some(
            item => item.rowId === 'blankrow'
          )
          // api.resetRowHeights()
          if (api && isEditing && hasBlankRow) {
            setTimeout(() => {
              api.forEachNode(node => {
                console.log(node?.data)
                if (
                  (node?.data?.rowId === 'blankrow' || !node?.data?.rowId) &&
                  !node?.data?.dataId
                ) {
                  if (node && api) {
                    api.deselectAll()
                    // debugger
                    node.setSelected(true)
                    api.startEditingCell({
                      rowIndex: node.rowIndex,
                      colKey: 'dataId'
                    })
                  }
                }
              })
            }, 100)
          }

          if (lineItemComments && Array.isArray(lineItemComments)) {
            comments = lineItemComments

            const target = document.getElementById(
              `soe-comments-display-${this?.props?.guid}-${rowId}`
            )

            setTimeout(() => {
              if (target) {
                ReactDOM.render(
                  React.createElement(StatusGridCellProxy, {
                    data: {
                      ...prms.data,
                      comments: lineItemComments,
                      guid: this.props.guid
                    },
                    comments: lineItemComments,
                    colDef: {
                      cellRendererParams: {
                        dispatch: this.props.dispatch,
                        form
                      }
                    }
                  }),
                  target
                )

                if (this.gridApi) {
                  this.gridApi.resetRowHeights()
                }

                const detailsGridWrapper = document.getElementById(
                  `soe-details-grid-display-${this?.props?.guid}-${rowId}`
                )

                if (detailsGridWrapper) {
                  const calculatedHeight = `calc(100% - ${lineItemComments.length *
                    29}px)`
                  detailsGridWrapper.style.height = calculatedHeight
                }
              }
            }, 0)
          }
        },
        singleClickEdit: true,
        // rowSelection: 'single',
        popupParent: document.querySelector('body'),
        getContextMenuItems: params => {
          const { defaultItems, api, node } = params
          const {
            customerId,
            customerIdDescription,
            customerName,
            dataId,
            dispatch,
            form,
            hasRecord,
            isEditing,
            lineItemsList,
            availableLineItems
          } = this.props

          let ret = [
            ...defaultItems,
            {
              name: 'Recalculate Costs',
              disabled: !isEditing,
              action: () =>
                dispatch(
                  launchRecalculateCostsModal(form, {
                    gridApi: params?.api
                  })
                )
            },
            {
              name: 'Customer Stock Minimums',
              disabled: !(node?.data?.dataId && node?.data?.uomId),
              action: () => {
                if (node?.data?.dataId && node?.data?.uomId) {
                  dispatch(
                    readCustomerStockMinimums.try(form, {
                      dataId: node.data.dataId,
                      uomId: node.data.uomId
                    })
                  )
                }
              }
            },
            // {
            //   name: 'Recalculate Prices',
            //   action: () =>
            //     dispatch(
            //       launchRecalculatePricesModal.try(form, {
            //         dataId: node.data.dataId
            //       })
            //     )
            // },
            {
              name: 'Customer Part Number',
              action: prms => {
                const { rowIndex } = this.focusOnCorrectLineNumber(params, api)

                const args = {
                  dataId,
                  customerIdDescription: customerName,
                  productId: node?.data?.dataId,
                  productDescription: node?.data?.description,
                  partNumber: node?.data?.customerPartNumber,
                  rowIndex,
                  gridApi: params?.api,
                  uniqueKey: node?.data?.uniqueKey
                  // parentLineNumber: node?.data?.parentLineNumber,
                  // lineNumber: node?.data?.lineNumber
                }
                if (node?.data?.parentLineNumber) {
                  args.childLineNumber = node?.data?.lineNumber
                  args.uniqueKey = this.props?.lineItemsList
                    ?.find(
                      x => x.get('lineNumber') === node.data.parentLineNumber
                    )
                    ?.get('uniqueKey')
                }
                if (is.number(rowIndex)) {
                  dispatch(launchCustomerPartNumberModal.try(form, args))
                }
              },
              disabled: !customerId
            },
            {
              name: 'Detail',
              disabled: !isEditing,
              subMenu: [
                {
                  name: 'Procurement',
                  subMenu: [
                    {
                      name: 'No Link',
                      action: () => {
                        // debugger
                        this.handleMultiSelect(
                          'ProcurementType',
                          'N',
                          params?.api,
                          true
                        )
                      }
                    },
                    {
                      name: 'Requesting P/O',
                      action: () =>
                        this.handleMultiSelect(
                          'ProcurementType',
                          'R',
                          params?.api,
                          true
                        )
                    },
                    {
                      name: 'Drop Ship P/O',
                      action: () =>
                        this.handleMultiSelect(
                          'ProcurementType',
                          'D',
                          params?.api,
                          true
                        )
                    },
                    {
                      name: 'Special P/O',
                      action: () =>
                        this.handleMultiSelect(
                          'ProcurementType',
                          'S',
                          params?.api,
                          true
                        )
                    },
                    {
                      name: 'Transfer Requested',
                      action: () =>
                        this.handleMultiSelect(
                          'ProcurementType',
                          'T',
                          params?.api,
                          true
                        )
                    },
                    {
                      name: 'Work Order',
                      action: () =>
                        this.handleMultiSelect(
                          'ProcurementType',
                          'W',
                          params?.api,
                          true
                        )
                    }
                  ]
                }
              ]
            },

            {
              name: 'External Comments',
              action: () => {
                dispatch(
                  openComments.try(form, {
                    propertyName: 'External',
                    uniqueKey: node.data.uniqueKey
                  })
                )
              },
              disabled: !isEditing
            },
            {
              name: 'Internal Comments',
              action: () => {
                dispatch(
                  openComments.try(form, {
                    propertyName: 'Internal',
                    uniqueKey: node.data.uniqueKey
                  })
                )
              },
              disabled: !isEditing
            }
          ]

          if (node?.data?.jsMenuVisible) {
            const {
              jsCatalogPage,
              jsMemberPortalURL,
              jsWarrantyPageURL
            } = node.data
            ret = ret.concat([
              {
                name: 'Johnstone',
                subMenu: [
                  {
                    name: 'Catalog Page',
                    action: () => {
                      window.open(jsCatalogPage, '_blank')
                    },
                    disabled: !node?.data?.jsCatalogPageEnabled
                  },
                  {
                    name: 'Member Portal',
                    action: () => {
                      window.open(jsMemberPortalURL, '_blank')
                    },
                    disabled: !node?.data?.jsMemberPortalEnabled
                  },
                  {
                    name: 'Warranty Sheet',
                    action: () => {
                      window.open(jsWarrantyPageURL, '_blank')
                    },
                    disabled: !node?.data?.jsWarrantySheetEnabled
                  }
                ],
                disabled: !node?.data?.jsMenuEnabled
              }
            ])
          }
          if (node?.data?.substitutes) {
            ret = ret.concat([
              {
                name: 'Substitutes',
                action: () => {
                  if (api) {
                    const { lineNumber } = this.focusOnCorrectLineNumber(
                      params,
                      api
                    )
                    if (lineNumber) {
                      this.openSubstituteInterface()
                    }
                  }
                }
              }
            ])
          }

          ret = ret.concat(
            {
              name: 'Procurement',
              action: () => {
                if (api) {
                  const { lineNumber } = this.focusOnCorrectLineNumber(
                    params,
                    api
                  )
                  if (lineNumber) {
                    this.openProcurementInterface()
                  }
                }
              }
            },

            {
              disabled: !node?.data?.canSwapQuantities,
              name: 'Swap Committed Quantities',
              action: () => {
                if (node?.data?.lineNumber && node?.data?.uniqueKey) {
                  dispatch(
                    handleSwapCommittedQuantitiesInteraction.try(form, {
                      apiParams: {
                        action: 'initialize',
                        childLineNumber: node.data.lineNumber,
                        lineNumber: prms?.data?.lineNumber,
                        activeLineNumber: prms?.data?.lineNumber,
                        activeLineNumbers: node?.data?.uniqueKey
                          ? [node.data.uniqueKey]
                          : [],
                        additionalData: ['components']
                      }
                    })
                  )
                }
              }
            },
            {
              name: 'View Open Orders',
              action: () => {
                const viewOpenOrdersParams = {
                  customer: this.props.customerId,
                  product: params.node.data.dataId,
                  status: 'O',
                  name: 'salesOrderInquiry',
                  title: 'Sales Order Inquiry'
                }

                if (this.props.salesOrderInquiryScreenOpen) {
                  /* we need to close the screen first */
                  dispatch(exit('salesOrderInquiry'))
                  dispatch(openScreenAction(viewOpenOrdersParams))
                } else {
                  dispatch(openScreenAction(viewOpenOrdersParams))
                }
              },
              disabled: !params.node.data.dataId
            },
            {
              name: 'View Quotes',
              action: () => {
                const viewOpenOrdersParams = {
                  customer: this.props.customerId,
                  product: params.node.data.dataId,
                  status: 'Q',
                  name: 'salesOrderInquiry',
                  title: 'Sales Order Inquiry'
                }

                if (this.props.salesOrderInquiryScreenOpen) {
                  /* we need to close the screen first */
                  dispatch(exit('salesOrderInquiry'))
                  dispatch(openScreenAction(viewOpenOrdersParams))
                } else {
                  dispatch(openScreenAction(viewOpenOrdersParams))
                }
              },
              disabled: !params.node.data.dataId
            }
          )
          ret = ret
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .concat({
              name: 'Add Row',
              disabled: !isEditing,
              action: () => {
                if (this?.props?.dispatch && parentLineNumber && rowId) {
                  this.props.dispatch(
                    addComponentsRow(this.props.form, {
                      parentLineNumber,
                      rowId,
                      gridApi: api,
                      componentsGridExpanded: true
                    })
                  )
                }
              }
            })
          return ret
        },
        tabToNextCell: params => {
          const { api, backwards } = params
          const { nextCellPosition } = params
          const colIdsToTrack = ['quantityChange', 'pricing', 'bins']

          if (this.props.modals) {
            return null
          }
          // deal with reverse direction as well... shift -T
          const nextDisplayedCol = backwards
            ? 'getDisplayedColBefore'
            : 'getDisplayedColAfter'

          if (!inquiryMode && !hasRecord) {
            return null
          }

          if (
            !backwards &&
            !inquiryMode &&
            (!nextCellPosition ||
              (nextCellPosition && !nextCellPosition.column))
          ) {
            const count = api.getDisplayedRowCount()
            if (params?.previousCellPosition?.rowIndex === count - 1) {
              this.props.dispatch(
                addComponentsRow(this.props.form, {
                  parentLineNumber: prms?.data?.lineNumber,
                  rowId,
                  gridApi: api,
                  componentsGridExpanded: true
                })
              )
              setTimeout(() => {
                if (this.gridApi) {
                  this.gridApi.resetRowHeights()
                }
              }, 1)
            }
            return null
          }

          return nextCellPosition
            ? nextCellPosition.column
              ? nextCellPosition
              : null
            : null
        }
      },
      getDetailRowData: params => {
        // let rowData
        let focusedCell = params?.node?.detailGridInfo?.api?.getFocusedCell()

        const components =
          params?.data?.components && Array.isArray(params.data.components)
            ? params.data.components.reduce((acc, next) => {
                acc = acc.concat({
                  ...next,
                  rowId: next.uniqueKey,
                  parentLineNumber: params.data.lineNumber
                })

                return acc
              }, [])
            : []

        // const components = params?.data?.components || []
        params.successCallback(components)
        setTimeout(() => {
          const colId = focusedCell?.column?.colId

          if (params?.node?.detailGridInfo?.api) {
            params.node.detailGridInfo.api.forEachNode(node => {
              if (
                (node?.data?.rowId === 'blankrow' || !node?.data?.rowId) &&
                !node?.data?.dataId
              ) {
                if (node) {
                  focusedCell = null
                  params.node.detailGridInfo.api.deselectAll()
                  // debugger
                  node.setSelected(true)
                  params.node.detailGridInfo.api.startEditingCell({
                    rowIndex: node.rowIndex,
                    colKey: 'dataId'
                  })
                }
              } else if (
                node &&
                focusedCell &&
                node?.rowIndex === focusedCell?.rowIndex
              ) {
                const focusedRowId = node?.data?.rowId
                const value = components?.find(
                  x => x?.rowId === focusedRowId
                )?.[colId]
                node.setDataValue(colId, value)
              }
            })

            const complexEditors = ['pricing', 'quantityChange']
            if (focusedCell && colId && !complexEditors?.includes(colId)) {
              params?.node?.detailGridInfo?.api?.setFocusedCell(
                focusedCell.rowIndex,
                colId
              )

              params?.node?.detailGridInfo?.api?.startEditingCell({
                rowIndex: focusedCell.rowIndex,
                colKey: colId
              })
            } else if (
              focusedCell &&
              colId &&
              !complexEditors?.includes(colId)
            ) {
              params?.node?.detailGridInfo?.api.tabToNextCell()
            }
          }
        }, 1)
      },
      template: params => {
        return `<div style="height: 100%; width: 100%; box-sizing: border-box;">
          <div id="soe-comments-display-${this?.props?.guid}-${rowId}" style="max-width: 100%"></div>
          <div id="soe-details-grid-display-${this?.props?.guid}-${rowId}" style="padding: 0; box-sizing: border-box; width: 100%; max-width: 100%;">
            <div ref="eDetailGrid" style="height: 100%;"></div>
          </div>
        </div>`
      }
    }
    console.log(ret)

    return ret
  }

  getColumnDefs = memoize(
    ({
      customerId,
      hasRecord,
      isEditing,
      focusedCell,
      form,
      onCellChange,
      showCart,
      hideToggle,
      gridLayoutType,
      allowWarehousePick,
      gridLayoutKey // needed to re-run this memoized function
    } = {}) => {
      /*
      gridLayoutKey is in fact an important value even though eslint
      thinks its not because it causes this function to re-run
      when the user selects 'Reset to Default' or 'Save for User' layout
      in the context menu -- SVE 1/11/21
    */

      const {
        disableAdvancedParsing,
        gridLayoutsMap,
        userId,
        activeGridLayoutsSet
      } = this.props
      const inquiryMode = Boolean(hasRecord && !isEditing)
      const checkBoxDisabled = p => !inquiryMode && !p.data.dataId
      const editableIfCanChangeQuantityNoInquiryMode = p => {
        return p.data && p.data.canChangeQuantity && !inquiryMode
      }
      // const hasDataId = p => !!p.data.dataId

      const editorParams = {
        disabled: inquiryMode,
        focusedCell,
        form,
        propertyName: 'lineItems',
        onChange: onCellChange,
        hasRecord,
        isEditing
      }

      const textEditor = {
        cellEditor: 'textFieldEditor',
        cellEditorParams: editorParams
      }

      let colDefs = [
        {
          field: 'rowId',
          colId: 'rowId',
          headerName: 'Comments',
          headerClass: 'hide-header-text',
          cellRenderer: 'agGroupCellRenderer',
          maxWidth: 50,
          valueGetter: () => '',
          pinned: 'left',
          lockPinned: true,
          suppressMovable: true
        },
        {
          field: 'lineNumber',
          colId: 'lineNumber',
          headerName: 'lineNumber',
          filter: false,
          cellStyle: { 'text-align': 'left' },
          minWidth: 50,
          maxWidth: 100,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          headerValueGetter: () => 'Ln'
        },
        {
          cellRenderer: IndexSearchRenderer,
          cellEditor: 'indexSearchEditor',
          cellClass: 'line-items-product',
          cellEditorParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            form: this.props.form,
            propertyName: 'lineItems',
            hideToggle,
            tabStop: true,
            disableAdvancedParsing,
            showCart,
            customerId
          },
          cellRendererParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            form: this.props.form,
            dispatch: this.props.dispatch
          },
          field: 'dataId',
          colId: 'dataId',
          headerName: 'Product Number',
          minWidth: 150,
          maxWidth: 200,
          width: 150,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          editable: p => !inquiryMode && !p.data.dataId
        },
        {
          field: 'description',
          colId: 'description',
          headerName: 'Product Description',
          minWidth: 150,
          maxWidth: 200,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          tabStop: true,
          editable: ({ data }) => data?.dataId === 'SP'
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right', color: '#000' }
          },
          ...textEditor,
          cellEditorParams: {
            /* 
              note this is using a string text editor now to allow 
              characters like 'B' and 'C' -- SVE 12/17/2021
            */
            ...textEditor.cellEditorParams,
            textAlign: 'right',
            notifyExitTextCell: true,
            propertyName: 'lineItems',
            tabStop: true,
            onBlur: (e, data, isCancelAfterEndFired) => {
              if (
                this.gridApi &&
                data?.dataId &&
                !this.props.modals &&
                !isCancelAfterEndFired
              ) {
                this.gridApi.stopEditing()
              }
            }
          },
          field: 'quantityOrdered',
          colId: 'quantityOrdered',
          tooltipComponent: 'customTooltip',
          tooltipField: 'quantityOrderedTooltip',
          headerName: 'Quant Ordered',
          minWidth: 100,
          maxWidth: 200,
          width: 125,
          suppressMenu: true,
          editable: !inquiryMode,
          pinned: 'left',
          lockPinned: true
        },
        {
          field: 'uomId',
          colId: 'uomId',
          headerName: 'Quant UM',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorParams: params => {
            let { uoMs: values } = params.data
            values = values.map(x => x.dataId)
            return {
              values,
              formatValue: value => {
                console.log(value)
                const row = params.data.uoMs.find(x => x.dataId === value)
                return `${value} - ${row.quantityFactor || ''}`
              }
            }
          },
          cellStyle: {
            paddingLeft: 0,
            paddingRight: 0
          },
          minWidth: 100,
          width: 125,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          editable: p => !inquiryMode && p.data.dataId,
          maxWidth: 100
        },
        {
          field: 'preferredBins',
          colId: 'preferredBins',
          headerName: 'Preferred Bins',
          cellRendererFramework: MultiLineTextRendererCell
          // autoHeight: true
        },
        {
          field: 'scannedBins',
          colId: 'scannedBins',
          headerName: 'Scanned Bins',
          cellRendererFramework: MultiLineTextRendererCell
          // autoHeight: true
        },
        {
          field: 'warehousePicked',
          colId: 'warehousePicked',
          headerClass: 'text-center',
          headerName: 'WH Pick',
          minWidth: 50,
          pinned: 'left',
          lockPinned: true,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: props => {
              return (
                checkBoxDisabled(props) || !allowWarehousePick || !isEditing
              )
            }
          },
          maxWidth: 150
        },
        {
          cellRenderer: 'removeLineItemCell',
          field:
            'canDelete' /* discovered this needs to be here in this minor version of ag-grid or the cell renderer data will not update disabled state -- SVE 3/5/2021 */,
          colId: 'removeLineItem',
          cellRendererParams: {
            ...editorParams,
            propertyName: 'lineItems'
          },
          maxWidth: 35,
          minWidth: 35,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          width: 35,
          suppressMenu: true,
          headerName: 'Remove Line Item',
          headerClass: 'hide-header-text',
          pinned: 'left',
          lockPinned: true
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          headerClass: 'align-right',
          field: 'quantity',
          colId: 'quantity',
          headerName: 'Quant To Ship',
          minWidth: 100,
          width: 150,
          editable: editableIfCanChangeQuantityNoInquiryMode,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName: 'lineItems',
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          }
        },
        {
          field: 'bins',
          colId: 'bins',
          headerName: 'Bins',
          headerClass: 'hide-header-text',
          headerComponent: 'imageCellHeader',
          headerComponentParams: {
            src: `${BASE_INFORM_URL}/resources/inventory_print_product_labels_32.png`,
            title: 'Bins/Serial Numbers/Lots'
          },
          maxWidth: 50,
          minWidth: 50,
          cellEditor: 'serialNumberEditor',
          cellEditorParams: { ...editorParams, tabStop: true },
          cellRendererFramework: DropdownArrow,
          editable: editableOnlyWithQuantityAndSerialFlag
        },
        {
          field: 'quantityBO',
          colId: 'quantityBO',
          headerName: 'Quant B/O',
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          minWidth: 100,
          width: 125,
          editable: editableIfCanChangeQuantityNoInquiryMode,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName: 'lineItems',
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          }
        },
        {
          headerComponent: 'imageCellHeader',
          headerComponentParams: {
            src: `${BASE_INFORM_URL}/resources/product_master_32.png`,
            title: 'Check Stock'
          },
          headerName: 'Quantity Dropdown',
          headerClass: 'hide-header-text',
          minWidth: 50,
          maxWidth: 50,
          field: 'quantityChange',
          colId: 'quantityChange',
          cellEditor: 'quantityCellEditor',
          cellEditorParams: { ...editorParams, tabStop: true },
          cellRendererFramework: DropdownArrow,
          editable: editableOnlyWithProductSelected
        },
        {
          field: 'quantityStaged',
          colId: 'quantityStaged',
          headerName: 'Quant Staged',
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          minWidth: 100,
          width: 150,
          hide: true
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          field: 'netPrice',
          colId: 'netPrice',
          headerClass: 'align-right',
          headerName: 'Net Price',
          headerTooltip: 'Net Price',
          tooltipComponent: 'customTooltip',
          tooltipField: 'netPriceTooltip',
          minWidth: 100,
          width: 125,
          cellEditor: 'textFieldEditor',
          editable: isEditing,
          cellEditorParams: {
            ...editorParams,
            textAlign: 'right',
            formatter: 'number',
            propertyName: 'lineItems',
            fixedDecimalScale: true,
            decimalScale: 4
          },
          valueFormatter: ({ value }) => formatNumber(value, '0.0000')
        },
        {
          headerComponent: 'imageCellHeader',
          headerComponentParams: {
            src: `${BASE_INFORM_URL}/resources/company_misc_charge_32.png`,
            title: 'Price Calculator'
          },
          headerName: 'Pricing Dropdown',
          headerClass: 'hide-header-text',
          minWidth: 50,
          maxWidth: 50,
          field: 'pricing',
          colId: 'pricing',
          cellEditor: 'pricingCellEditor',
          cellEditorParams: {
            ...editorParams,
            gridApi: this.gridApi,
            tabStop: true
          },
          cellStyle: prms => {
            if (prms && prms.api) {
              const selected = prms.api.getSelectedRows()
              if (
                selected &&
                selected.length &&
                selected[0].data &&
                Number.isFinite(selected[0].data.lineNumber) &&
                this.props.lineNumber &&
                parseFloat(this.props.lineNumber) ===
                  parseFloat(selected[0].data.lineNumber)
              ) {
                return {
                  backgroundColor: '#d5edd5'
                }
              }

              return null
            }
            return null
          },
          cellRendererFramework: DropdownArrow,
          editable: editableOnlyWithProductSelected,
          suppressKeyboardEvent: params => {
            const { value } = params.event.target
            const { editing } = params
            const { keyCode } = params.event
            if (keyCode === this.TAB && editing) {
              return true
            }
            return false
          }
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          field: 'priceExtension',
          colId: 'priceExtension',
          headerClass: 'align-right',
          headerName: 'Price Extension',
          headerTooltip: 'Price Extension',
          minWidth: 100,
          width: 150,
          valueFormatter: formatDollarFields,
          cellEditor: 'textFieldEditor',
          editable: isEditing,
          cellEditorParams: {
            ...editorParams,
            textAlign: 'right',
            formatter: 'number',
            propertyName: 'lineItems',
            fixedDecimalScale: true,
            decimalScale: 4
          }
        },
        {
          field: 'quantityShipped',
          colId: 'quantityShipped',
          headerName: 'Quant Shipped',
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          minWidth: 100,
          width: 150,
          hide: true,
          editable: isEditing
        },
        {
          field: 'mfgNumber',
          colId: 'mfgNumber',
          headerName: 'MFG Number',
          minWidth: 150,
          hide: true
        },

        {
          field: 'quantityScannedDisplay',
          colId: 'quantityScannedDisplay',
          headerName: 'Quant Scanned',
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          field: 'rebateCost',
          colId: 'rebateCost',
          headerClass: 'align-right',
          headerName: 'Rebate Cost',
          minWidth: 150,
          hide: true
        },
        {
          field: 'shippablePriceExtensionResolved',
          colId: 'shippablePriceExtensionResolved',
          headerName: 'Shippable Extension',
          minWidth: 150,
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          field: 'costExtension',
          colId: 'costExtension',
          headerClass: 'align-right',
          headerName: 'Cost Extension',
          minWidth: 150,
          hide: true,
          valueFormatter: formatDollarFields
        },
        {
          field: 'shippableCostExtensionResolved',
          colId: 'shippableCostExtensionResolved',
          headerName: 'Shippable Cost Extension',
          minWidth: 150,
          hide: true
        },
        {
          field: 'priceMethodResolved',
          colId: 'priceMethodResolved',
          headerName: 'Price Formula',
          minWidth: 100,
          hide: true
        },
        {
          field: 'costMethodResolved',
          colId: 'costMethodResolved',
          headerName: 'Cost Formula',
          minWidth: 100,
          hide: true
        },
        {
          field: 'rebateCostMethod',
          colId: 'rebateCostMethod',
          headerName: 'Rebate Cost Formula',
          minWidth: 100,
          hide: true
        },
        {
          field: 'grossProfitDollars',
          colId: 'grossProfitDollars',
          headerName: 'G/P $',
          minWidth: 100,
          cellStyle: { textAlign: 'right' },
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          field: 'taxable',
          colId: 'taxable',
          headerName: 'Taxable',
          headerClass: 'text-center',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },

        {
          field: 'priceOverride',
          colId: 'priceOverride',
          headerName: 'Pr Ovr',
          headerClass: 'text-center',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'costOverride',
          colId: 'costOverride',
          headerClass: 'text-center',
          headerName: 'Cost Override',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'customerPartNumber',
          colId: 'customerPartNumber',
          headerName: 'Customer Part #',
          minWidth: 100,
          hide: true
        },
        {
          field: 'markupPercent',
          colId: 'markupPercent',
          cellClass: 'align-right',
          headerClass: 'align-right',
          headerName: 'Mark Up %',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'warehouseIdResolved',
          colId: 'warehouseIdResolved',
          headerName: 'Whse',
          minWidth: 100,
          hide: true
        },
        {
          field: 'warrantyTagId',
          colId: 'warrantyTagId',
          headerName: 'Tag',
          minWidth: 100,
          hide: true
        },
        {
          field: 'projectLine',
          colId: 'projectLine',
          headerName: 'Project Line #',
          minWidth: 100,
          hide: true,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName: 'lineItems',
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          },
          editable: editableIfCanChangeQuantityNoInquiryMode
        },
        {
          field: 'priceCode',
          colId: 'priceCode',
          headerName: 'Price Code',
          minWidth: 100,
          hide: true
        },
        {
          field: 'expectedPODeliveryDateResolved',
          cellClass: 'text-center',
          colId: 'expectedPODeliveryDateResolved',
          headerName: 'P/O Expd Dt',
          minWidth: 100,
          hide: true
        },
        {
          field: 'promisedDate',
          cellClass: 'text-center',
          colId: 'promisedDate',
          headerName: 'Promised Date',
          minWidth: 100,
          valueFormatter: formatDateFields,
          hide: true
        },
        {
          field: 'coOpPercent',
          colId: 'coOpPercent',
          headerName: 'Co-op Percent',
          minWidth: 100,
          cellStyle: { textAlign: 'right' },
          valueFormatter: ({ value }) => formatNumber(value, '0.00'),
          hide: true
        },
        {
          field: 'repair',
          colId: 'repair',
          headerName: 'RI',
          headerClass: 'text-center',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'updateDemand',
          colId: 'updateDemand',
          headerName: 'UD',
          headerClass: 'text-center',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'commitInventory',
          colId: 'commitInventory',
          headerClass: 'text-center',
          headerName: 'Commit Inventory',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'commitBOQuantity',
          colId: 'commitBOQuantity',
          headerClass: 'text-center',
          headerName: 'Commit B/O',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          cellStyle: { textAlign: 'right' },
          field: 'quantityBOComm',
          colId: 'quantityBOComm',
          headerClass: 'align-right',
          headerName: 'Quant B/O Committed',
          minWidth: 100,
          width: 125
        },
        {
          cellStyle: { textAlign: 'right' },
          field: 'quantityBOUncomm',
          colId: 'quantityBOUncomm',
          headerClass: 'align-right',
          headerName: 'Quant B/O Uncommitted',
          minWidth: 100,
          width: 125
        },
        {
          cellRendererFramework: ImageGridCell,
          field: 'image',
          colId: 'image',
          headerName: 'Image',
          minWidth: 100,
          hide: true
        },
        {
          field: 'purchase',
          colId: 'purchase',
          headerClass: 'text-center',
          headerName: 'Purch',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'procurementType',
          colId: 'procurementType',
          headerName: 'P/O Type',
          minWidth: 100,
          hide: true
        },
        {
          field: 'redAlert',
          colId: 'redAlert',
          headerClass: 'text-center',
          headerName: 'Red Alert',
          minWidth: 100,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            isDisabled: checkBoxDisabled
          },
          hide: true
        },
        {
          field: 'purchaseOrderId',
          colId: 'purchaseOrderId',
          headerName: 'Linked P/O',
          minWidth: 100,
          hide: true
        },
        {
          field: 'poVendorId',
          colId: 'poVendorId',
          headerName: 'P/O Vendor',
          minWidth: 150,
          hide: true
        },
        {
          field: 'transferId',
          colId: 'transferId',
          headerName: 'Linked Transfer',
          minWidth: 150,
          hide: true
        },
        {
          field: 'warehouseId',
          colId: 'warehouseId',
          headerName: 'Selling Whse',
          minWidth: 100,
          hide: true
        },
        {
          cellStyle: { textAlign: 'right' },
          field: 'transferQuantity',
          colId: 'transferQuantity',
          headerClass: 'align-right',
          headerName: 'Trans Quant',
          minWidth: 100,
          hide: true
        },
        {
          field: 'transferFromWhseId',
          colId: 'transferFromWhseId',
          headerName: 'Trans Fr Whse',
          minWidth: 100,
          hide: true
        },
        {
          field: 'transferComments',
          colId: 'transferComments',
          headerName: 'Trans Commts',
          minWidth: 100,
          hide: true
        },
        {
          cellRendererFramework: AddItemGroupCell,
          cellRendererParams: {
            ...editorParams
          },
          field: 'itemGroupId',
          colId: 'itemGroupId',
          headerName: 'Item Group',
          minWidth: 100,
          hide: true
        },
        {
          field: 'spawnedQuantityShipped',
          colId: 'spawnedQuantityShipped',
          headerClass: 'align-right',
          headerName: 'Spawned TotQtyShpd',
          minWidth: 150,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'quantityUnScheduled',
          colId: 'quantityUnScheduled',
          headerClass: 'align-right',
          headerName: 'Quant UnSched',
          minWidth: 100,
          width: 125,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'hazMatIdResolved',
          colId: 'hazMatIdResolved',
          headerName: 'Haz Mat',
          minWidth: 100,
          hide: true
        },
        {
          field: 'rebateGrossProfitDollars',
          colId: 'rebateGrossProfitDollars',
          headerName: 'Rebate G/P $',
          minWidth: 100,
          cellStyle: { textAlign: 'right' },
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          field: 'quantityOpen',
          colId: 'quantityOpen',
          headerName: 'Quant Open',
          minWidth: 100,
          width: 125,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'quantityOpenDisplay',
          colId: 'quantityOpenDisplay',
          headerName: 'Quant Open Display',
          minWidth: 100,
          width: 125,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'priceUOMId',
          colId: 'priceUOMId',
          headerName: 'Dft Pr UOM',
          minWidth: 100,
          hide: true
        },
        {
          cellStyle: { textAlign: 'right' },
          field: 'discountPercent',
          colId: 'discountPercent',
          headerClass: 'align-right',
          headerName: 'Discount Percent',
          minWidth: 100,
          valueFormatter: ({ value }) => formatNumber(value, '0.00'),
          hide: true
        },
        {
          field: 'multiplier',
          colId: 'multiplier',
          headerName: 'Mult',
          minWidth: 100,
          hide: true
        },
        {
          field: 'unitPrice',
          colId: 'unitPrice',
          headerName: 'Unit Price',
          minWidth: 100,
          hide: true
        },
        {
          cellClass: 'align-right',
          field: 'unitCost',
          colId: 'unitCost',
          headerClass: 'align-right',
          headerName: 'Unit Cost',
          minWidth: 100,
          hide: true
        },
        {
          cellClass: 'align-right',
          field: 'netCost',
          colId: 'netCost',
          headerClass: 'align-right',
          headerName: 'Net Cost',
          minWidth: 100,
          width: 125,
          hide: true
        },

        {
          field: 'grossProfitPercent',
          colId: 'grossProfitPercent',
          headerName: 'Gross Profit Percent',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'unitAltCost',
          colId: 'unitAltCost',
          headerName: 'Unit Alt Cost',
          minWidth: 100,
          hide: true
        },
        {
          field: 'netAltCost',
          colId: 'netAltCost',
          headerName: 'Net Alt Cost',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'altCostExtension',
          colId: 'altCostExtension',
          headerName: 'Alt Cost Ext',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'altGPPercent',
          colId: 'altGPPercent',
          headerName: 'Alt Gross Profit Percent',
          minWidth: 100,
          width: 125,
          valueFormatter: ({ value }) => formatNumber(value, '0.00'),
          hide: true
        },
        {
          field: 'altGPDollars',
          colId: 'altGPDollars',
          headerName: 'Alt G/P Dollars',
          minWidth: 100,
          width: 125,
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          cellClass: 'align-right',
          field: 'unitAverageCost',
          colId: 'unitAverageCost',
          headerClass: 'align-right',
          headerName: 'Unit Avg Cost',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'netAverageCost',
          colId: 'netAverageCost',
          headerClass: 'align-right',
          headerName: 'Net Avg Cost',
          minWidth: 100,
          width: 125,
          cellStyle: { textAlign: 'right' },
          valueFormatter: formatDollarFields,
          hide: true
        },
        {
          cellClass: 'alig-right',
          field: 'averageCostExtension',
          colId: 'averageCostExtension',
          headerClass: 'align-right',
          headerName: 'Avg Cost Extension',
          minWidth: 100,
          width: 125,
          hide: true
        },
        {
          field: 'averageGPPercent',
          colId: 'averageGPPercent',
          headerName: 'Avg Gross Profit Percent',
          minWidth: 100,
          width: 125,
          valueFormatter: ({ value }) => formatNumber(value, '0.00'),
          hide: true
        },
        {
          field: 'averageGPDollars',
          colId: 'averageGPDollars',
          headerClass: 'align-right',
          headerName: 'Avg G/P Dollars',
          minWidth: 100,
          width: 125,
          valueFormatter: formatDollarFields,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'primaryBinResolved',
          colId: 'primaryBinResolved',
          headerName: 'Primary Bin',
          minWidth: 100,
          hide: true
        },
        {
          field: 'quantityAvailableResolved',
          colId: 'quantityAvailableResolved',
          headerClass: 'align-right',
          headerName: 'Available',
          minWidth: 100,
          width: 125,
          cellStyle: { textAlign: 'right' },
          hide: true
        },
        {
          field: 'statusDescription',
          colId: 'statusDescription',
          headerName: 'Status',
          minWidth: 100,
          hide: true
        }
      ]

      const localStorageGridKey =
        form === 'invoiceInquiry' ? 'invoiceInquiry' : 'salesOrder'

      const layoutKey =
        form === 'invoiceInquiry'
          ? 'InvoiceInquiryLineItems'
          : 'SalesOrderEntryLineItems'

      const gridLayouts = gridLayoutsMap?.toJS ? gridLayoutsMap.toJS() : []
      let selectedLayoutFromMeta =
        gridLayoutType === 'company'
          ? gridLayouts.find(x => x.layoutKey === layoutKey)?.company
          : gridLayouts.find(x => x.layoutKey === layoutKey)?.default

      if (gridLayoutType === 'company' && selectedLayoutFromMeta === null) {
        selectedLayoutFromMeta = gridLayouts.find(
          x => x.layoutKey === layoutKey
        )?.default
      }

      const selectedColsFromMeta =
        selectedLayoutFromMeta?.bands?.[0]?.columns || []

      const aliases = {
        delete: 'removeLineItem',
        quantityDropDownButton: 'quantityChange',
        priceDropDownButton: 'pricing'
      }

      const hiddenUserCols = tryParseJSON(localService.get(`grids-${userId}`))
      const activeGridLayouts =
        activeGridLayoutsSet && activeGridLayoutsSet?.toJS
          ? activeGridLayoutsSet.toJS()
          : []

      const lineItemsGridActive = activeGridLayouts.includes('lineItems')

      if (
        (gridLayoutType === 'default' && !lineItemsGridActive) ||
        (gridLayoutType === 'company' && !lineItemsGridActive)
      ) {
        if (selectedColsFromMeta && selectedColsFromMeta.length) {
          colDefs = colDefs.reduce((acc, next) => {
            if (
              next.colId === 'rowId' ||
              selectedColsFromMeta.find(
                x => x.key === next.colId || x.key === next.field
              ) ||
              selectedColsFromMeta.find(
                x =>
                  aliases[x.key] &&
                  (aliases[x.key] === next.colId ||
                    aliases[x.key] === next.field)
              )
            ) {
              const pinned =
                next.colId === 'rowId' ||
                selectedColsFromMeta.find(
                  x => x.key === next.colId || x.key === next.field
                )?.value?.fixed ||
                selectedColsFromMeta.find(
                  x =>
                    aliases[x.key] &&
                    (aliases[x.key] === next.colId ||
                      aliases[x.key] === next.field)
                )?.value?.fixed ||
                false

              const displayedColumnConfig = pinned
                ? {
                    ...next,
                    pinned: 'left',
                    lockPinned: true,
                    hide: false,
                    resizable: true
                  }
                : {
                    ...next,
                    resizable: true,
                    hide: false
                  }

              acc = acc.concat(displayedColumnConfig)
            } else {
              acc = acc.concat({
                ...next,
                hide: true
              })
            }
            return acc
          }, [])

          const sortedKeyed = selectedColsFromMeta.reduce(
            (acc, next) => {
              const key =
                next?.key && aliases[next.key] ? aliases[next.key] : next.key
              acc[key] = next.value.position + 1
              return acc
            },
            { rowId: 0 }
          )

          colDefs = sortHiddenCols(colDefs).sort((a, b) => {
            if (sortedKeyed[a.colId] > sortedKeyed[b.colId]) {
              return 1
            }

            if (sortedKeyed[a.colId] < sortedKeyed[b.colId]) {
              return -1
            }

            return 0
          })
        }
      } else if (gridLayoutType === 'user' || lineItemsGridActive) {
        if (
          hiddenUserCols &&
          hiddenUserCols?.[localStorageGridKey]?.lineItems
        ) {
          colDefs = colDefs.reduce((acc, next) => {
            acc = acc.concat({
              ...next,
              hide:
                hiddenUserCols[localStorageGridKey].lineItems.includes(
                  next.colId
                ) ||
                hiddenUserCols[localStorageGridKey].lineItems.includes(
                  next.field
                ) ||
                false,
              resizable: true
            })
            return acc
          }, [])
        }

        if (this?.columnApi?.getColumnState) {
          const ordered = this.columnApi.getColumnState()
          if (ordered && Array.isArray(ordered)) {
            localService.set(
              `gridOrdered-${userId}-${localStorageGridKey}`,
              ordered.reduce((acc, next, idx) => {
                acc[next.colId] = idx
                return acc
              }, {})
            )
          }
        }

        const userOrdered =
          localService.get(`gridOrdered-${userId}-${localStorageGridKey}`) || {}
        if (
          userOrdered &&
          typeof userOrdered === 'object' &&
          userOrdered !== null &&
          Object.keys(userOrdered).length
        ) {
          colDefs = colDefs.sort((a, b) => {
            if (userOrdered[a.colId] > userOrdered[b.colId]) {
              return 1
            }

            if (userOrdered[a.colId] < userOrdered[b.colId]) {
              return -1
            }

            return 0
          })
        }
      }

      /* super important -- SVE 1/11/21 */
      const gridRegisteredInLocal = isGridLocalStorageRegistered(
        localService.get(`grids-${userId}`),
        localStorageGridKey
      )
      // debugger
      if (colDefs && colDefs?.length && gridRegisteredInLocal) {
        /* this will error if the grid hasn't been registered in storage already */
        setGridColsInLocal(
          {
            ...this.props,
            propertyName: 'lineItems'
          },
          colDefs.reduce((acc, next) => {
            const id = next.colId || next.field
            if (next.hide && id) {
              acc = acc.concat(id)
            }
            return acc
          }, [])
        )
      }

      return colDefs
    },
    isEqual
  )

  emptyRow = {
    rowId: 'blankrow',
    lineNumber: '1',
    dataId: null,
    description: '',
    quantityOrdered: null,
    uomId: 'EA',
    netPrice: 0,
    comments: [],
    data: [],
    itemGroupId: null,
    itemGroupDescription: ''
  }

  onCellValueChanged = params => {
    //
    const {
      allowDuplicates,
      dispatch,
      form,
      suppressDuplicateProductMessageInSalesOrder
    } = this.props
    //
    if (
      params.colDef.field !== 'inventory' &&
      params.newValue != null
      //  &&
      // !isEqual(params.newValue, params.oldValue)
    ) {
      dispatch({
        type: 'CELL_CHANGED',
        payload: {
          ...params,
          allowDuplicates: true,
          suppressDuplicateProductMessageInSalesOrder,
          propertyName: 'lineItems'
        },
        meta: { form }
      })
    }
  }

  KEY_UP = 38

  KEY_DOWN = 40

  TAB = 9

  /* headerNames are different in InvoiceInquiry -- SVE 2/11/2021 */
  getDefaultColDef = {
    headerValueGetter: headerValueGetter(
      this.props.form === 'invoiceInquiry'
        ? {
            ...headerNameMap,
            quantityShipped: ['Tot-Ord Shipped', 'Tot-Ord Shipped']
          }
        : headerNameMap
    ),
    suppressMovable: false,
    lockPinned: true
  }

  onKeyUpListener = p => {
    console.log(p)
  }

  tabToNextCell = params => {
    const { api, backwards } = params
    const colIdsToTrack = ['quantityChange', 'pricing', 'bins']
    let { nextCellPosition } = params

    if (this.props.modals) {
      return null
    }
    // deal with reverse direction as well... shift -T
    const nextDisplayedCol = backwards
      ? 'getDisplayedColBefore'
      : 'getDisplayedColAfter'

    const inquiryMode = this.props.hasRecord && !this.props.isEditing

    if (!inquiryMode && !this.props.hasRecord) {
      return null
    }

    while (nextCellPosition) {
      if (
        nextCellPosition &&
        nextCellPosition.column &&
        nextCellPosition.column.colDef.cellEditorParams &&
        nextCellPosition.column.colDef.cellEditorParams.tabStop
      ) {
        // check if it has the corresponding flag and what not. either break or continue
        if (colIdsToTrack.includes(nextCellPosition.column.colDef.colId)) {
          if (
            shouldPromptAdvancedCell(
              api,
              nextCellPosition.column,
              nextCellPosition.rowIndex,
              colIdsToTrack
            )
          ) {
            break
          }
        } else {
          break
        }
      }

      if (!nextCellPosition.column && api) {
        if (!backwards) {
          // get rowcount and see if there's one to go to
          const count = api.getDisplayedRowCount()
          if (nextCellPosition.rowIndex + 1 < count) {
            // eslint-disable-next-line prefer-destructuring
            nextCellPosition.column = api?.columnController?.getAllGridColumns()?.[2]
            nextCellPosition.rowIndex += 1
          }
        } else if (backwards && nextCellPosition.rowIndex - 1 >= 0) {
          const gridCols = api?.columnController?.getAllGridColumns()
          nextCellPosition.column = gridCols.find(x => x.colId === 'pricing')
          nextCellPosition.rowIndex -= 1
        }
        setSelectedNodeFromIndex(api, nextCellPosition.rowIndex)
        // const node = this.gridApi.getDisplayedRowAtIndex(
        //   nextCellPosition.rowIndex
        // )
        // if (node && !node.isSelected()) {
        //   node.setSelected(true)
        // }
        break
      }

      nextCellPosition = {
        column: nextCellPosition?.column?.columnApi?.[nextDisplayedCol](
          nextCellPosition.column
        ),
        rowIndex: nextCellPosition.rowIndex
      }
    }
    // end while loop.. we should have the nextCellPos, and need to try to edit if possible.

    if (
      !backwards &&
      !inquiryMode &&
      (!nextCellPosition || (nextCellPosition && !nextCellPosition.column))
    ) {
      // since line items grid is now multi select, deselect current selected row before adding new one
      // LL 9/4/20
      if (api) {
        api.deselectAll()
      }

      this.props.dispatch(
        addBlankRow(this.props.form, { propertyName: 'lineItems' })
      )

      return null
    }

    if (
      nextCellPosition &&
      nextCellPosition.column &&
      nextCellPosition.column.colDef &&
      nextCellPosition.column.colDef.cellEditorParams &&
      nextCellPosition.column.colDef.cellEditorParams.tabStop
    ) {
      const idx = nextCellPosition.rowIndex
      const key = nextCellPosition.column.colId
      if (
        colIdsToTrack.includes(key) &&
        !shouldPromptAdvancedCell(
          api,
          nextCellPosition.column,
          nextCellPosition.rowIndex,
          colIdsToTrack
        )
      ) {
        return null
      }
      setTimeout(() => {
        if (api) {
          api.ensureColumnVisible(key)
          api.setFocusedCell(idx, key)
          api.startEditingCell({
            rowIndex: idx,
            colKey: key
          })
        }
      }, 0)

      return null
    }

    return nextCellPosition
      ? nextCellPosition.column
        ? nextCellPosition
        : null
      : null
  }

  getScreenRef = (...args) => {
    console.log(args, 'getScreenRef')
  }

  onDragEnd = (sizes, pane) => {
    const { userId } = this.props
    this.setState(
      prevState => {
        return {
          layoutState: {
            ...prevState.layoutState,
            [pane]: sizes
          }
        }
      },
      () => {
        localService.set(
          `salesOrderEntryFlexLayout-${userId}`,
          this.state.layoutState
        )
      }
    )
  }

  handleMultiSelect = (propertyId, value, gridApi, child = false) =>
    this.props.dispatch(
      initializeMultiSelect.try(this.props.form, {
        propertyId,
        value,
        gridApi,
        child
      })
    )

  getHeight = node => {
    if (node.detail) {
      const comments = node?.data?.comments?.length || 0
      const components = node?.data?.components?.length || 0
      const additionalHeight = components ? 75 : 0
      const height = comments * 30 + components * 30 + additionalHeight
      console.log('getHeight', height)
      return height
    }
    if (node?.data?.scannedBins || node?.data?.preferredBins) {
      const scannedBins = node.data.scannedBins ?? ''
      const preferredBins = node.data.preferredBins ?? ''
      const regexp = /<br\s*[\/]?>/g
      const allScanned = [...scannedBins.matchAll(regexp)].length
      const allPrefferred = [...preferredBins.matchAll(regexp)].length
      const [larger, idx] = greater(allScanned, allPrefferred)
      // console.log(this.api)
      const isVis = this.columnApi
        .getColumn(idx === 0 ? 'scannedBins' : 'preferredBins')
        .isVisible()
      return isVis ? larger * 30 : 30
    }

    return 30
  }

  getRowHeight = params => this.getHeight(params.node)

  isRowMaster = item => {
    if (
      (item?.comments &&
        Array.isArray(item.comments) &&
        item?.comments?.length) ||
      (item?.components &&
        Array.isArray(item.components) &&
        item?.components?.length)
    ) {
      return true
    }

    return false
  }

  openSubstituteInterface = () => {
    const { dispatch, form } = this.props

    dispatch(
      storeUIFeatureState(form, {
        feature: 'lineItemsDetailTab',
        featureState: 'Substitutes'
      })
    )
  }

  openProcurementInterface = () => {
    const { dispatch, form } = this.props

    dispatch(
      storeUIFeatureState(form, {
        feature: 'expandedDetailsAccordion',
        featureState: 'procurement'
      })
    )

    dispatch(
      storeUIFeatureState(form, {
        feature: 'lineItemsDetailTab',
        featureState: 'Details'
      })
    )
  }

  toggleCommentsColumn = () => {
    if (!this.columnApi) {
      return
    }

    const commentsShowing = this?.columnApi?.getAllDisplayedColumns
      ? Boolean(
          this.columnApi.getAllDisplayedColumns().find(x => x.colId === 'rowId')
        )
      : false

    this.columnApi.setColumnVisible('rowId', !commentsShowing)
  }

  removeActiveGridLayout = () => {
    const { activeGridLayoutsSet, dispatch, form } = this.props
    const activeGridLayouts =
      activeGridLayoutsSet && activeGridLayoutsSet?.toJS
        ? activeGridLayoutsSet.toJS()
        : []

    if (activeGridLayouts.includes('lineItems')) {
      dispatch(
        flagActiveGridLayout(form, {
          gridName: 'lineItems',
          action: 'remove'
        })
      )
    }
  }

  resetToDefault = () => {
    const { form, userId } = this.props
    this.removeActiveGridLayout()

    this.setState(
      {
        gridLayoutKey: shortid.generate(),
        gridLayoutType: 'default'
      },
      () => {
        const gridLayoutTypeKey =
          form === 'invoiceInquiry'
            ? 'InvoiceInquiryGridLayoutType'
            : 'SOEGridLayoutType'

        localService.set(
          `${gridLayoutTypeKey}-${userId}`,
          this.state.gridLayoutType
        )
      }
    )
  }

  setToCompanyLayout = () => {
    const { form, userId } = this.props

    this.removeActiveGridLayout()
    this.setState(
      {
        gridLayoutKey: shortid.generate(),
        gridLayoutType: 'company'
      },
      () => {
        const gridLayoutTypeKey =
          form === 'invoiceInquiry'
            ? 'InvoiceInquiryGridLayoutType'
            : 'SOEGridLayoutType'

        localService.set(
          `${gridLayoutTypeKey}-${userId}`,
          this.state.gridLayoutType
        )
      }
    )
  }

  saveLayoutForUser = debounce(() => {
    const { form, dispatch } = this.props

    if (form === 'invoiceInquiry') {
      this.saveLayoutForUserSuccessCb()
    } else {
      dispatch(
        saveLayoutForUserAuthentication.try(form, {
          successCb: this.saveLayoutForUserSuccessCb
        })
      )
    }
  }, 300)

  saveLayoutForUserSuccessCb = () => {
    const { form, userId } = this.props

    this.removeActiveGridLayout()
    this.setState(
      {
        gridLayoutType: 'user',
        gridLayoutKey: shortid.generate()
      },
      () => {
        const gridLayoutTypeKey =
          form === 'invoiceInquiry'
            ? 'InvoiceInquiryGridLayoutType'
            : 'SOEGridLayoutType'

        localService.set(
          `${gridLayoutTypeKey}-${userId}`,
          this.state.gridLayoutType
        )
      }
    )
  }

  onColumnVisibleCb = () => {
    const { dispatch, form, activeGridLayoutsSet } = this.props
    const activeGridLayouts =
      activeGridLayoutsSet && activeGridLayoutsSet?.toJS
        ? activeGridLayoutsSet.toJS()
        : []

    if (!activeGridLayouts.includes('lineItems')) {
      dispatch(
        flagActiveGridLayout(form, {
          gridName: 'lineItems',
          action: 'add'
        })
      )
    }
  }

  onDragStoppedCb = () => {
    const { form, userId } = this.props
    const localStorageGridKey =
      form === 'invoiceInquiry' ? 'invoiceInquiry' : 'salesOrder'

    if (this?.columnApi?.getColumnState) {
      const ordered = this.columnApi.getColumnState()
      if (ordered && Array.isArray(ordered)) {
        localService.set(
          `gridOrdered-${userId}-${localStorageGridKey}`,
          ordered.reduce((acc, next, idx) => {
            acc[next.colId] = idx
            return acc
          }, {})
        )
      }
    }

    this.onColumnVisibleCb()
  }

  focusOnCorrectLineNumber = (params, api) => {
    /* 
      NOTE: this function is for setting / selecting the correct lineNumber
      and rowIndex when using contextMenu features. The reason this needed
      to be added is because when you are dealing with orders that have
      Item Groups, both node.rowIndex and node.childIndex are INCORRECT
      because ag-grid counts the group bands into those indexes. For example,
      if you pull up order (or Invoice #) 3020154 and right click on 
      item Z95-342 'Box Cutter' (Line Number 6 in the 'Options' group), 
      ag-grid is going to give you node.childIndex 1 and node.rowIndex 8 
      when the rowIndex we actually want is 5 for APIs and lookups in sagas
      -- SVE 2/22/21
    */
    const { lineItemsList } = this.props
    const lineNumber = params?.node?.data?.lineNumber

    if (!lineNumber) {
      return {
        lineNumber: null,
        rowIndex: 0
      }
    }

    const rowIndex = lineItemsList.findIndex(
      x => x.get('lineNumber') === lineNumber
    )

    api.forEachNode((nde, idx) => {
      if (nde?.data?.lineNumber && nde?.data?.lineNumber === lineNumber) {
        // debugger
        nde.setSelected(true)
      } else {
        nde.setSelected(false)
      }
    })

    return { lineNumber, rowIndex }
  }

  getContextMenuItems = params => {
    const {
      customerId,
      customerIdDescription,
      customerName,
      dataId,
      dispatch,
      form,
      hasRecord,
      isEditing,
      lineItemsList,
      availableLineItems
    } = this.props
    const { defaultItems, node, columnApi, api } = params
    const isSideBarVisible = api.isSideBarVisible()
    const commentsShowing =
      columnApi.getAllDisplayedColumns().find(x => x.colId === 'rowId') || null

    const hasLineItems =
      lineItemsList?.size &&
      lineItemsList?.get &&
      lineItemsList.get(0).get('rowId') !== 'blankrow'

    const inquiryMode = Boolean(hasRecord && !isEditing)

    let ret = [
      {
        name: 'Layout',
        subMenu: [
          {
            name: 'Set To Company',
            action: this.setToCompanyLayout
          },
          {
            name: 'Reset To Default',
            action: this.resetToDefault
          },
          {
            name: `${isSideBarVisible ? 'Hide' : 'Show'} Column Chooser`,
            action: () => {
              api.openToolPanel('columns')
              api.setSideBarVisible(!isSideBarVisible)
            }
          },
          {
            name: `${commentsShowing ? 'Hide' : 'Show'} Comments Column`,
            action: this.toggleCommentsColumn
          },
          {
            name: 'Save For User',
            action: this.saveLayoutForUser
          }
        ].sort((a, b) => {
          /* alphabetize ('Hide...' can change to 'Show...') */
          if (!a?.name || !b?.name) {
            return 0
          }

          if (a.name < b.name) {
            return -1
          }

          if (a.name > b.name) {
            return 1
          }

          return 0
        })
      }
    ]

    if (isEditing && hasLineItems) {
      ret = ret.concat([
        {
          name: 'Backorder',
          action: () => {
            const { lineNumber } = this.focusOnCorrectLineNumber(params, api)

            if (dispatch && api && lineNumber) {
              dispatch(
                handleOpenGridInterfaceFeature(form, {
                  colId: 'quantityChange',
                  gridApi: api,
                  lineNumber
                })
              )
            }
          }
        },
        {
          name: 'Customer Part Number',
          action: () => {
            const { rowIndex } = this.focusOnCorrectLineNumber(params, api)

            if (is.number(rowIndex)) {
              dispatch(
                launchCustomerPartNumberModal.try(form, {
                  dataId,
                  customerIdDescription: customerName,
                  productId: node?.data?.dataId,
                  productDescription: node?.data?.description,
                  partNumber: node?.data?.customerPartNumber,
                  rowIndex,
                  gridApi: params?.api,
                  uniqueKey: node?.data?.uniqueKey
                })
              )
            }
          },
          disabled: !customerId
        },
        {
          name: 'Detail',
          subMenu: [
            {
              name: 'Add To Item Group (Ctrl + G)',
              action: () =>
                dispatch(
                  launchAddItemGroupModal.try(form, {
                    dataId: node?.data?.dataId,
                    lineNumber: node?.data?.lineNumber,
                    gridApi: params?.api
                  })
                )
            },
            {
              name: 'Commissionable',
              subMenu: [
                {
                  name: 'Yes',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'commissionableType',
                        value: 'Y',
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'No',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'commissionableType',
                        value: 'N',
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'Split',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'commissionableType',
                        value: 'S',
                        gridApi: params?.api
                      })
                    )
                }
              ]
            },
            {
              name: 'Commit BackOrder Quantities',
              subMenu: [
                {
                  name: 'Yes',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'commitBOQuantity',
                        value: true,
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'No',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'commitBOQuantity',
                        value: false,
                        gridApi: params?.api
                      })
                    )
                }
              ]
            },
            {
              name: 'Procurement',
              subMenu: [
                {
                  name: 'No Link',
                  action: () => {
                    this.handleMultiSelect('ProcurementType', 'N', params?.api)
                  }
                },
                {
                  name: 'Requesting P/O',
                  action: () =>
                    this.handleMultiSelect('ProcurementType', 'R', params?.api)
                },
                {
                  name: 'Drop Ship P/O',
                  action: () =>
                    this.handleMultiSelect('ProcurementType', 'D', params?.api)
                },
                {
                  name: 'Special P/O',
                  action: () =>
                    this.handleMultiSelect('ProcurementType', 'S', params?.api)
                },
                {
                  name: 'Transfer Requested',
                  action: () =>
                    this.handleMultiSelect('ProcurementType', 'T', params?.api)
                },
                {
                  name: 'Work Order',
                  action: () =>
                    this.handleMultiSelect('ProcurementType', 'W', params?.api)
                }
              ]
            },
            {
              name: 'Quantity',
              subMenu: [
                {
                  name: 'Ship Available',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'shipType',
                        value: '',
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'Ship All',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'shipType',
                        value: 'A',
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'Backorder All',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'shipType',
                        value: 'B',
                        gridApi: params?.api
                      })
                    )
                }
              ]
            },
            {
              name: 'Taxable',
              subMenu: [
                {
                  name: 'Yes',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'taxable',
                        value: true,
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'No',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'taxable',
                        value: false,
                        gridApi: params?.api
                      })
                    )
                }
              ]
            },
            {
              name: 'Update Demand',
              subMenu: [
                {
                  name: 'Yes',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'updatedemand',
                        value: true,
                        gridApi: params?.api
                      })
                    )
                },
                {
                  name: 'No',
                  action: () =>
                    dispatch(
                      initializeMultiSelect.try(form, {
                        propertyId: 'updatedemand',
                        value: false,
                        gridApi: params?.api
                      })
                    )
                }
              ]
            },
            {
              name: 'Reprice',
              action: () =>
                dispatch(
                  initializeMultiSelect.try(form, {
                    propertyId: 'reprice',
                    value: null,
                    gridApi: params?.api
                  })
                )
            },
            {
              name: 'Set Promise Date',
              action: () =>
                dispatch(
                  openPromiseDateModal.try(form, {
                    promisedDate: node?.data?.promisedDate,
                    gridApi: params?.api
                  })
                )
            },
            {
              name: 'Cancel Scan',
              action: () =>
                dispatch(
                  initializeMultiSelect.try(form, {
                    propertyId: 'cancelscan',
                    value: null,
                    gridApi: params?.api
                  })
                )
            }
          ]
        },
        {
          name: 'Recalculate Costs',
          disabled: !isEditing,
          action: () =>
            dispatch(
              launchRecalculateCostsModal(form, {
                gridApi: params?.api
              })
            )
        },
        {
          name: 'Recalculate Prices',
          action: () =>
            dispatch(
              launchRecalculatePricesModal.try(form, {
                dataId
              })
            )
        },
        {
          disabled: !node?.data?.canSwapQuantities,
          name: 'Swap Committed Quantities',
          action: () => {
            if (node?.data?.lineNumber) {
              dispatch(
                handleSwapCommittedQuantitiesInteraction.try(form, {
                  apiParams: {
                    action: 'initialize',
                    lineNumber: node.data.lineNumber
                  }
                })
              )
            }
          }
        }
      ])
    }

    if (node?.data?.dataId && form !== 'invoiceInquiry') {
      ret = ret.concat([
        {
          name: 'End User Tracked Fields',
          action: () => {
            if (
              node?.data?.lineNumber &&
              node?.data?.rebate &&
              node?.data?.rebateInfo
            ) {
              dispatch(
                handleRebateInfoInteraction.try(form, {
                  action: 'launch',
                  lineNumber: node.data.lineNumber,
                  rebateInfo: node.data.rebateInfo
                })
              )
            }
          },
          disabled: !node?.data?.rebate
        },
        {
          name: 'External Comments',
          action: () => {
            dispatch(
              openComments.try(form, {
                propertyName: 'External',
                uniqueKey: node.data.uniqueKey
              })
            )
          },
          disabled: !isEditing
        },
        {
          name: 'Internal Comments',
          action: () => {
            dispatch(
              openComments.try(form, {
                propertyName: 'Internal',
                uniqueKey: node.data.uniqueKey
              })
            )
          },
          disabled: !isEditing
        }
      ])

      if (node?.data?.canUnCommitBOQty) {
        ret = ret.concat({
          name: 'Backorder Commitment Schedule',
          action: () => {
            if (node?.data?.lineNumber && node?.data?.dataId) {
              dispatch(
                handleBackorderCommitmentSchedule.try(form, {
                  apiParams: {
                    lineNumber: node.data.lineNumber,
                    action: 'initialize'
                  },
                  rowId: node?.data?.rowId
                })
              )
            }
          }
        })
      }

      if (node?.data?.isLineItemScheduled) {
        ret = ret.concat({
          name: 'Line Item Schedule',
          action: () => {
            if (node?.data?.lineNumber) {
              dispatch(
                handleRecurringOrderInteraction.try(form, {
                  action: 'initialize',
                  lineNumber: node.data.lineNumber
                })
              )
            }
          }
        })
      }
    }

    if (node?.data?.cutLengthVisible) {
      ret = ret.concat([
        {
          name: 'Cut Length',
          action: () => {
            if (params?.node?.data?.lineNumber) {
              dispatch(
                openCutLengthModal(form, {
                  lineNumber: params.node.data.lineNumber
                })
              )
            }
          },
          disabled: !isEditing
        }
      ])
    }
    if (node?.data?.substitutes) {
      ret = ret.concat([
        {
          name: 'Substitutes',
          action: () => {
            if (api) {
              const { lineNumber } = this.focusOnCorrectLineNumber(params, api)
              if (lineNumber) {
                this.openSubstituteInterface()
              }
            }
          }
        }
      ])
    }
    if (node?.data?.jsMenuVisible) {
      const { jsCatalogPage, jsMemberPortalURL, jsWarrantyPageURL } = node.data
      ret = ret.concat([
        {
          name: 'Johnstone',
          subMenu: [
            {
              name: 'Catalog Page',
              action: () => {
                window.open(jsCatalogPage, '_blank')
              },
              disabled: !node?.data?.jsCatalogPageEnabled
            },
            {
              name: 'Member Portal',
              action: () => {
                window.open(jsMemberPortalURL, '_blank')
              },
              disabled: !node?.data?.jsMemberPortalEnabled
            },
            {
              name: 'Warranty Sheet',
              action: () => {
                window.open(jsWarrantyPageURL, '_blank')
              },
              disabled: !node?.data?.jsWarrantySheetEnabled
            }
          ],
          disabled: !node?.data?.jsMenuEnabled
        }
      ])
    }

    if (node && node.data && node.data.dataId) {
      ret = ret.concat([
        {
          name: 'View Open Orders',
          action: () => {
            const viewOpenOrdersParams = {
              customer: this.props.customerId,
              product: params.node.data.dataId,
              status: 'O',
              name: 'salesOrderInquiry',
              title: 'Sales Order Inquiry'
            }

            if (this.props.salesOrderInquiryScreenOpen) {
              /* we need to close the screen first */
              dispatch(exit('salesOrderInquiry'))
              dispatch(openScreenAction(viewOpenOrdersParams))
            } else {
              dispatch(openScreenAction(viewOpenOrdersParams))
            }
          },
          disabled: !params.node.data.dataId
        },
        {
          name: 'View Quotes',
          action: () => {
            const viewOpenOrdersParams = {
              customer: this.props.customerId,
              product: params.node.data.dataId,
              status: 'Q',
              name: 'salesOrderInquiry',
              title: 'Sales Order Inquiry'
            }

            if (this.props.salesOrderInquiryScreenOpen) {
              /* we need to close the screen first */
              dispatch(exit('salesOrderInquiry'))
              dispatch(openScreenAction(viewOpenOrdersParams))
            } else {
              dispatch(openScreenAction(viewOpenOrdersParams))
            }
          },
          disabled: !params.node.data.dataId
        },
        {
          name: 'Procurement',
          action: () => {
            if (api) {
              const { lineNumber } = this.focusOnCorrectLineNumber(params, api)
              if (lineNumber) {
                this.openProcurementInterface()
              }
            }
          }
        },
        {
          name: 'Pricing',
          action: () => {
            this.gridApi.setFocusedCell(params.node.rowIndex, 'pricing')
            this.gridApi.startEditingCell({
              rowIndex: params.node.rowIndex,
              colKey: 'pricing'
            })
          },
          disabled: !params.node.data.dataId
        },
        {
          name: 'Invoice History',
          action: () => {
            if (params?.node?.data?.lineNumber) {
              dispatch(
                openInvoiceHistoryModal.try(form, {
                  lineNumber: params.node.data.lineNumber
                })
              )
            }
          },
          disabled: !params.node.data.invoiceHistoryEnabled
        },
        {
          name: 'View Spawned Orders',
          action: () => {
            if (params?.node?.data?.lineNumber) {
              dispatch(
                openSpawnedOrdersModal.try(form, {
                  lineNumber: params.node.data.lineNumber,
                  spawnedOrders: params.node.data.spawnedOrders
                })
              )
            }
          },
          disabled: !params.node.data.hasSpawnedOrders
        }
      ])
    }

    ret = ret.concat({
      name: 'Repair Item',
      action: () => {
        if (api) {
          const { rowIndex } = this.focusOnCorrectLineNumber(params, api)

          if (is.number(rowIndex)) {
            dispatch(
              launchRepairItemModalInterface(form, {
                rowIndex,
                data: node.data,
                gridApi: api
              })
            )
          }
        }
      }
    })

    if (isEditing || !dataId) {
      ret = ret.concat({
        name: 'Import Products From CSV',
        action: () =>
          dispatch(launchCSVUploadInterface(form, { gridApi: params?.api }))
      })
    }

    if (isEditing || !dataId) {
      ret = ret.concat([
        {
          name: 'Customer Order Pad',
          action: () =>
            dispatch(
              openCustomerOrderPadInModal(form, {
                gridApi: this.gridApi ? this.gridApi : params?.api
              })
            )
        },
        {
          name: 'Copy Products From Order',
          action: () => this.copyProducts()
        }
      ])
    }

    // if (
    //   node &&
    //   node.data &&
    //   node.data.substitutes &&
    //   node.data.substitutes.length
    // ) {
    //   ret = ret.concat([
    //     {
    //       name: 'Subtitutes',
    //       action: () => {
    //         this.focusOnCorrectLineNumber(params, api)
    //         dispatch(
    //           storeUIFeatureState(form, {
    //             feature: 'lineItemsDetailTab',
    //             featureState: 'Substitutes'
    //           })
    //         )
    //       }
    //     }
    //   ])
    // }

    // if editing and there are line items
    if (isEditing && hasLineItems) {
      ret = ret.concat([
        {
          name: 'Item Groups',
          action: () => dispatch(launchItemGroupsModal.try(form, { dispatch })),
          disabled: !availableLineItems
        },
        {
          name: 'Return Invoices',
          action: () => {
            const { rowIndex, lineNumber } = this.focusOnCorrectLineNumber(
              params,
              api
            )
            if (is.number(rowIndex) && lineNumber) {
              dispatch(
                getReturnModalData(form, {
                  rowIndex,
                  gridApi: params?.api,
                  isRequest: true,
                  launchModal: false,
                  lineNumber,
                  type: 'invoicehistory'
                })
              )
            }
          },
          disabled:
            params.node.data.quantityOrdered > 0 ||
            !params.node.data.canChange ||
            params.node.isSpecialProduct
        },
        {
          name: 'Return Authorization Code',
          action: () => {
            const { rowIndex, lineNumber } = this.focusOnCorrectLineNumber(
              params,
              api
            )
            if (is.number(rowIndex) && lineNumber) {
              dispatch(
                getReturnModalData(form, {
                  rowIndex,
                  gridApi: params?.api,
                  isRequest: true,
                  launchModal: false,
                  lineNumber,
                  type: 'returnauthorization'
                })
              )
            }
          },
          disabled:
            params.node.data.quantityOrdered > 0 ||
            !params.node.data.promptForReturnToAuthorization ||
            !params.node.data.canChange ||
            params.node.isSpecialProduct
        }
      ])
    }

    if (
      (customerId && node && node?.data) ||
      (form === 'invoiceInquiry' && node && node?.data)
    ) {
      ret = ret.concat([
        {
          name: 'Customer Sales History',
          action: () =>
            dispatch(
              openScreenAction({
                initialData: {
                  customerId,
                  customerIdDescription: customerName,
                  salesHistoryYear: 'All',
                  productId: node.data.dataId ?? '',
                  productIdDescription: node.data.description ?? ''
                },
                title: 'Customer Sales History',
                route: 'customersaleshistory',
                image: 'sales_customer_sales_history_16.png'
              })
            )
        }
      ])
    }

    if (form === 'invoiceInquiry' && dataId) {
      ret = ret.concat([
        // {
        //   name: 'Backorder',
        //   action: () => {
        //     const { lineNumber } = this.focusOnCorrectLineNumber(params, api)

        //     if (dispatch && api && lineNumber) {
        //       dispatch(
        //         handleOpenGridInterfaceFeature(form, {
        //           colId: 'quantityChange',
        //           gridApi: api,
        //           lineNumber
        //         })
        //       )
        //     }
        //   }
        // },
        {
          name: 'Pricing',
          action: () => {
            const { lineNumber } = this.focusOnCorrectLineNumber(params, api)

            if (dispatch && api && lineNumber) {
              dispatch(
                handleOpenGridInterfaceFeature(form, {
                  colId: 'pricing',
                  gridApi: api,
                  lineNumber
                })
              )
            }
          }
        }
      ])
    }

    if (customerId && node?.data?.dataId && node?.data?.uomId) {
      ret = ret.concat({
        name: 'Customer Stock Minimums',
        action: () => {
          if (node?.data?.dataId && node?.data?.uomId) {
            dispatch(
              readCustomerStockMinimums.try(form, {
                dataId: node.data.dataId,
                uomId: node.data.uomId
              })
            )
          }
        }
      })
    }

    if (hasLineItems && form !== 'invoiceInquiry') {
      // getSOESalesmenCommissions
      ret = ret.concat({
        name: 'Salesperson Commissions',
        action: () => {
          if (dispatch) {
            dispatch(getSOESalesmenCommissions.try(form))
          }
        }
      })
    }

    if (!inquiryMode && form !== 'invoiceInquiry') {
      ret = ret.concat({
        name: 'Import From AutoQuotes',
        action: () => {
          if (this?.autoQuotesInput?.openFileBrowser) {
            this.autoQuotesInput.openFileBrowser()
          }
        }
      })
    }

    /* alphabetize this list */
    // ret = ret.sort((a, b) => {
    //   if (!a?.name || !b?.name) {
    //     return 0
    //   }

    //   if (a.name < b.name) {
    //     return -1
    //   }

    //   if (a.name > b.name) {
    //     return 1
    //   }

    //   return 0
    // })
    ret = ret.sort((a, b) => a?.name?.localeCompare(b?.name))
    return ret.concat(defaultItems)
  }

  onGridReady = params => {
    console.log('onGridReady', params)
    this.gridApi = params.api
    this.columnApi = params.columnApi
    this.setState({ gridReadied: false })
    // this.gridApi.forEachNode(node => {
    //   debugger
    //   if (node.data.rowId === 'blankrow') {
    //     if (!node.isSelected()) node.setSelected(true)
    //     if (!this.wasTouched) {
    //       this.gridApi.startEditingCell({
    //         rowIndex: node.rowIndex,
    //         colKey: 'dataId'
    //       })
    //     }
    //   }

    //   /*
    //     Marc - this condition is specifically set up to
    //     focus on the first row of the grid if the user leaves the screen
    //     or tab and comes back, restoring the focus to the first row -- SVE 5/7/2020
    //   */
    //   if (node.childIndex === 0 && node.data.dataId) {
    //     if (!node.isSelected()) {
    //       node.setSelected(true)
    //     }
    //   }
    // })
  }

  navigateToNextCell = params => {
    const KEY_LEFT = 37
    const KEY_UP = 38
    const KEY_RIGHT = 39
    const KEY_DOWN = 40
    const previousCell = params.previousCellPosition
    const suggestedNextCell = params.nextCellPosition
    let nextRowIndex
    let renderedRowCount
    if (!this.props.hasRecord) return null

    // const inquiryMode = this.props.hasRecord && !this.props.isEditing
    // // if (!inquiryMode) {
    // //   return null
    // // }
    switch (params.key) {
      case KEY_DOWN:
        nextRowIndex = previousCell.rowIndex + 1
        if (nextRowIndex < 0) {
          return null
        }
        setSelectedNodeFromIndex(this.gridApi, nextRowIndex)
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          floating: previousCell.floating
        }

      case KEY_UP:
        nextRowIndex = previousCell.rowIndex - 1
        renderedRowCount = this.gridApi.getModel().getRowCount()
        if (nextRowIndex >= renderedRowCount) {
          return null
        }
        setSelectedNodeFromIndex(this.gridApi, nextRowIndex)
        return {
          rowIndex: nextRowIndex,
          column: previousCell.column,
          floating: previousCell.floating
        }

      case KEY_LEFT:
      case KEY_RIGHT:
        return suggestedNextCell
      default:
        // eslint-disable-next-line no-throw-literal
        throw 'this will never happen, navigation is always one of the 4 keys above'
    }
  }

  copyProducts = debounce(() => {
    const { dispatch, form } = this.props
    dispatch(
      copyProducts.try(form, { gridApi: this.gridApi ? this.gridApi : null })
    )
  }, 100)

  openFastProductModal = debounce(() => {
    const { dispatch, form } = this.props
    dispatch(
      showQuickEntity(form, {
        propertyName: 'fastProduct',
        gridApi: this.gridApi ? this.gridApi : null
      })
    )
  }, 100)

  onCellDoubleClicked = debounce(
    params => {
      //
      if (
        this.props.hasRecord &&
        !this.props.isEditing &&
        this.props._ddiForm &&
        this.props._ddiForm.lockForEdit &&
        !params.column.colId.includes('lineNumber')
      ) {
        this.props._ddiForm.lockForEdit()
      }
    },
    5000,
    { leading: true }
  )

  getRowNodeId = data => data.rowId

  setLayoutState = tab => {
    this.setState(
      {
        layoutState: {
          main: [75, 25],
          sidebar: [50, 50]
        }
      },
      () => {
        localService.set(
          `salesOrderEntryFlexLayout-${this.props.userId}`,
          this.state.layoutState
        )
        this.props.dispatch(
          storeUIFeatureState(this.props.form, {
            feature: 'lineItemsDetailTab',
            featureState: tab
          })
        )
      }
    )
  }

  searchAreaTouched = params => {
    this.wasTouched = true
    this.sub.forEach(x => x.remove())
  }

  defaultGroupSortComparator = (nodeA, nodeB) => {
    if (nodeA.key < nodeB.key) {
      return -1
    }
    if (nodeA.key > nodeB.key) {
      return 1
    }
    return 0
  }

  onRowDataUpdated = (...rest) => {
    if (!this.state.gridReadied) {
      let { lineItemsList } = this.props
      lineItemsList = lineItemsList?.toJS ? lineItemsList.toJS() : []
      this.gridApi?.forEachNode(node => {
        // debugger
        if (node?.data?.rowId === 'blankrow') {
          if (!node.isSelected()) node.setSelected(true)
          if (!this.wasTouched) {
            this.gridApi.startEditingCell({
              rowIndex: node.rowIndex,
              colKey: 'dataId'
            })
          }
        }

        if (
          node?.data?.lineNumber &&
          node?.data?.lineNumber === 1 &&
          node?.data?.dataId &&
          !lineItemsList.some(x => x.rowId === 'blankrow')
        ) {
          if (!node.isSelected()) {
            // debugger
            node.setSelected(true)
          }
        }
      })
      this.setState({ gridReadied: true })
    } else if (this?.props?.isEditing) {
      this.gridApi.forEachNode(node => {
        if (
          node.rowIndex === this.props.selectedRowIndex &&
          !Number(this.props.childIndex)
        ) {
          // debugger
          if (!node.isSelected()) node.setSelected(true)
        }
      })
    }
  }

  onRowGroupOpened = params => {
    const { form, dispatch } = this.props
    const hasOnlyBlankComponentRow =
      params?.node?.data?.components?.length === 1 &&
      params?.node?.data?.components?.find(x => x.rowId === 'blankrow')

    if (params.expanded && !hasOnlyBlankComponentRow) {
      debugger
      dispatch(
        readLineItem.try(form, {
          additionalDataType: ['components'],
          rowIndex: params.rowIndex,
          gridApi: params.api
        })
      )
    }
  }

  onCellFocused = params => {
    /* 
      stop editing the detail grid if 
      the user goes to edit the parent grid while focused
    */
    if (is.number(params.rowIndex) && params.column) {
      params.api.forEachDetailGridInfo(info => {
        if (info?.api) {
          info.api.stopEditing(true)
        }
      })
    }
  }

  onDisplayedColumnsChanged = ({ api }) => {
    api?.resetRowHeights()
  }

  render() {
    const {
      customerId,
      dataId,
      focusedCell,
      onCellChange,
      propertyName,
      hasRecord,
      height,
      image,
      isEditing,
      form,
      dispatch,
      popupEditorOpen,
      showCart,
      searchFieldsList,
      userId,
      allowWarehousePick
    } = this.props
    // debugger
    const searchFields = searchFieldsList?.toJS ? searchFieldsList.toJS() : []
    const hideToggle = shouldHideToggle(searchFields)

    const {
      expanded,
      interfaceDimensions,
      lineItemsSidebarDimensions,
      layoutState,
      popupParent,
      gridLayoutKey,
      gridLayoutType
    } = this.state

    const contentHeight = height - 80
    const rightColHeight = contentHeight - 27
    const sidebarWidthPct = this.getSidebarWidth(
      interfaceDimensions,
      lineItemsSidebarDimensions
    )

    const uiCompacted = sidebarWidthPct >= 0.9

    const columnDefs = this.getColumnDefs({
      customerId,
      hasRecord,
      isEditing,
      focusedCell,
      form,
      onCellChange,
      propertyName,
      showCart,
      hideToggle,
      gridLayoutType,
      gridLayoutKey,
      allowWarehousePick
    })

    return (
      <div
        className="soe-line-items-order-interface"
        style={{ padding: '0 10px', width: '100%', maxWidth: '100%' }}
      >
        <OrderInfo
          expanded={expanded === 'header'}
          handleAccordionToggle={this.handleAccordionToggle}
          dispatch={dispatch}
          form={form}
        />
        <LineItemsSection
          columnDefs={columnDefs}
          onRowSelected={this.onRowSelected}
          contentHeight={contentHeight}
          copyProducts={this.copyProducts}
          dataId={dataId}
          defaultColDef={this.getDefaultColDef}
          detailCellRendererParams={this.detailCellRendererParams}
          dispatch={dispatch}
          emptyRow={this.emptyRow}
          expanded={expanded === 'detail'}
          form={form}
          getContextMenuItems={this.getContextMenuItems}
          getRowHeight={this.getRowHeight}
          getRowNodeId={this.getRowNodeId}
          getToggleNavIcons={this.getToggleNavIcons}
          handleAccordionToggle={this.handleAccordionToggle}
          height={height}
          image={image}
          isEditing={isEditing}
          isRowMaster={this.isRowMaster}
          // onChildRowSelected={this.onChildRowSelected}
          layoutState={layoutState}
          lineItemsSidebarDimensions={lineItemsSidebarDimensions}
          masterDetail
          navigateToNextCell={this.navigateToNextCell}
          onCellDoubleClicked={this.onCellDoubleClicked}
          onCellFocused={this.onCellFocused}
          onCellValueChanged={this.onCellValueChanged}
          onResizeLineItemDetailGrid={this.onResizeLineItemDetailGrid}
          onGridReady={this.onGridReady}
          onDragEnd={this.onDragEnd}
          onResizeInterface={this.onResizeInterface}
          onToggleInterface={this.onToggleInterface}
          openFastProductModal={this.openFastProductModal}
          popupEditorOpen={popupEditorOpen}
          rightColHeight={rightColHeight}
          tabToNextCell={this.tabToNextCell}
          uiCompacted={uiCompacted}
          setLayoutState={this.setLayoutState}
          gridApi={this.gridApi || noop}
          groupUseEntireRow
          groupRowInnerRendererFramework={GroupRowInnerRenderer}
          defaultGroupSortComparator={this.defaultGroupSortComparator}
          popupParent={popupParent}
          customerId={this.props.customerId}
          gridLayoutKey={gridLayoutKey}
          userId={userId}
          onColumnVisibleCb={this.onColumnVisibleCb}
          onDragStoppedCb={this.onDragStoppedCb}
          onRowDataChanged={this.onRowDataChanged}
          onRowDataUpdated={this.onRowDataUpdated}
          onDisplayedColumnsChanged={this.onDisplayedColumnsChanged}
          isAddingBulkLineItems={this.props.isAddingBulkLineItems}
          applyColumnDefOrder
          onRowGroupOpened={this.onRowGroupOpened}
          rowSelection="multiple"
        />
        <AutoQuotesInput
          form={form}
          dispatch={dispatch}
          ref={el => (this.autoQuotesInput = el)}
          gridApi={this.gridApi}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formState = getFormSelector(props.form)(state)
  const formModals = getIn(formState, 'modals')?.size || 0
  const disableAdvancedParsing = getIn(formState, 'meta.disableAdvancedParsing')
  const showCart = getIn(formState, 'meta.showCart')
  const mainModals = state.get('modals')?.size || 0

  const salesOrderInquiryScreenOpen = getIn(state, 'ddiForm.salesOrderInquiry')

  const ret = {
    modals: formModals + mainModals,
    userId: getIn(state, 'auth.dataId'),
    disableAdvancedParsing,
    showCart,
    salesOrderInquiryScreenOpen: Boolean(salesOrderInquiryScreenOpen),
    isEditing: getIn(formState, 'isEditing') || false,
    dataId: getIn(formState, 'fields.dataId.value') || null,
    hasRecord: getIn(formState, 'hasRecord') || false,
    suppressDuplicateProductMessageInSalesOrder:
      getIn(formState, 'meta.suppressDuplicateProductMessageInSalesOrder') ||
      '',
    expandComments: getIn(formState, 'values.expandComments') || false,
    lineItemsList: getIn(formState, 'fields.lineItems.rowData') || emptyList,
    orderAccordion: getIn(formState, 'ui.orderAccordion') || 'detail',
    lineItemsDetailTab:
      getIn(formState, 'ui.lineItemsDetailTab') || 'Inventory',
    searchFieldsList: getIn(formState, 'meta.searchFields') || emptyList,
    popupEditorOpen: getIn(formState, 'popupEditorOpen') || false,
    availableLineItems:
      getIn(formState, 'values.groups.availableLineItems') || null,
    customerId:
      getIn(formState, 'fields.customerId.value') ||
      getIn(formState, 'values.customerId') ||
      '',
    customerIdIsSet: getIn(formState, 'fields.customerId.isSet') || false,
    customerName: getIn(formState, 'values.customerName') || '',
    hasItemGroups: getIn(formState, 'values.hasItemGroups') || false,
    selectedRowIndex: getIn(formState, 'fields.lineItems.selectedRowIndex'),
    childIndex: getIn(formState, 'fields.lineItems.childIndex'),
    gridLayoutsMap: getIn(formState, 'meta.gridLayouts') || empty,
    activeGridLayoutsSet: getIn(formState, 'activeGridLayouts') || Set(),
    isAddingBulkLineItems: getIn(formState, 'isAddingBulkLineItems') || false,
    allowWarehousePick: getIn(formState, 'meta.allowWarehousePick'),
    guid: getIn(formState, 'guid'),
    // additionalDataMap: getIn(formState, 'additionalDataMap') || fromJS({}),
    lineItemComponents:
      getIn(formState, 'fields.lineItemComponents') || fromJS({})
    // initialGridLayoutType:
    //   getIn(formState, 'fields.lineItems.gridLayoutType') || 'user'
  }

  return ret
}

const ConnectedOrder = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(withDimensions()(Order))

export default ConnectedOrder // connectedOrder
