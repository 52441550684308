/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DDIIndexSearch, DDISelectField } from 'ddiForm/wrapped'
import { Label, withContext } from 'ddiForm'
import { shouldUpdate } from 'hoc'
import memoize from 'memoize-one'
import { getRgb, getMeta, getField, getValue, emptyList, empty } from 'utils'
import { readTemplate } from 'components/MasterScreen/Template/actions'
import { openMergeModal } from 'modals/MergeModal/actions'

import NewProductField from './NewProductField'
import {
  createNewProduct,
  setPropertyValue,
  openCopyProductModal
} from '../actions'
import '../styles/SearchArea.scss'

const styles = {
  ddiLabelIconStyle: {
    marginRight: 5,
    verticalAlign: 'middle',
    fontSize: 16
  },
  ddiLabelStyle: {
    background: '#eaeef0',
    borderRadius: 4,
    color: '#517b9c',
    display: 'inline-block',
    fontSize: 12,
    lineHeight: '16px',
    padding: 5
  },
  inputText: { fontSize: 12 },
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    minWidth: 60,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    margin: '0 5px 0 0'
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0'
  }
}

const whitelist = [
  'fields',
  'hasRecord',
  'isEditing',
  'values',
  'preNewMode',
  'newMode',
  'statusDescription',
  'statusType',
  'productStatusOptions'
]

export class SearchArea extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    defaultTemplates: PropTypes.array.isRequired,
    selectedWarehouseId: PropTypes.string.isRequired,
    description: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    newMode: PropTypes.bool,
    preNewMode: PropTypes.bool,
    productStatusOptions: PropTypes.array.isRequired,
    selectedWarehouseStatusColor: PropTypes.string.isRequired,
    statusDescription: PropTypes.string.isRequired,
    statusType: PropTypes.string.isRequired,
    unitOfMeasures: PropTypes.array.isRequired
    // uoMs: PropTypes.array.isRequired
  }

  static defaultProps = {
    description: '',
    newMode: false,
    preNewMode: false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.newProductField) {
      this.newProductField.focus()
    }
  }

  getStatusOptionText = () => {
    let status = 'Active'

    const { statusType, statusDescription } = this.props
    let { productStatusOptions } = this.props
    productStatusOptions = productStatusOptions.toJS
      ? productStatusOptions.toJS()
      : []

    if (statusDescription) {
      return statusDescription
    }

    if (statusType && productStatusOptions && productStatusOptions.size) {
      const selectedOpt = productStatusOptions.find(
        x => x.get('dataId') === statusType
      )
      // debugger
      status = selectedOpt ? selectedOpt.get('description') : 'Active'
      return status
    }

    return status
  }

  getNewProduct = () => {
    const { dispatch, form } = this.props

    dispatch(createNewProduct.try(form))
  }

  openCopy = () => {
    const { dispatch, form } = this.props
    dispatch(openCopyProductModal.try(form))
  }

  getCustomContextMenuItems = () => {
    const { preNewMode } = this.props
    const items = [
      {
        text: 'Next Sequential Number',
        onClick: this.getNewProduct,
        enabled: preNewMode
      }
    ]

    return items
  }

  getProductTemplate = templateKey => {
    const { dispatch, form } = this.props
    dispatch(readTemplate(form, { templateKey }))
  }

  getBaseContextMenuItems = memoize(
    ({
      dataId,
      defaultTemplates,
      newMode,
      hasRecord,
      preNewMode,
      isEditing,
      templateKey
    }) => {
      console.log(this.props)
      // debugger
      let items = hasRecord
        ? emptyList
        : defaultTemplates.reduce((acc, next) => {
            acc = acc.concat({
              text: next.get('description'),
              icon: 'library_books',
              iconStyle: {
                color: '#444',
                fontSize: 16,
                verticalAlign: 'middle'
              },
              enabled: dataId ? false : true, // eslint-disable-line
              onClick: () => this.getProductTemplate(next.get('dataId')),
              style: { color: '#444' }
            })
            return acc
          }, [])

      items = [...items]
      if (hasRecord && isEditing && !templateKey && (!newMode || !preNewMode)) {
        items.push({
          text: 'Change Number',
          icon: 'merge_type',
          iconStyle: {
            color: '#444',
            fontSize: 16,
            verticalAlign: 'middle'
          },
          onClick: this.openMergeModal,
          enabled: !newMode,
          // enabled: isEditing, // eslint-disable-line
          style: { color: '#444' }
        })
      }

      items.push({
        text: 'Copy From',
        onClick: this.openCopy,
        enabled: newMode
      })

      return items
    }
  )

  openMergeModal = () => {
    const { dispatch, form } = this.props
    dispatch(openMergeModal(form))
  }

  setPropertyField = params => {
    const { dispatch, form } = this.props
    dispatch(setPropertyValue(form, { ...params }))
  }

  render() {
    const {
      defaultSelectedWarehouseId,
      description,
      isEditing,
      hasRecord,
      newMode,
      preNewMode,
      selectedWarehouseStatusColor,
      unitOfMeasures
    } = this.props
    // console.log('searchAreaRender', this.props)

    return (
      <div
        style={{
          alignItems: 'center',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '5px 0'
        }}
      >
        <div
          className="product-master-search-row-wrapper"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: '5px 10px',
            width: '100%'
          }}
        >
          <div
            className="search-row-inner-wrapper"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flex: 2
              }}
            >
              <div style={styles.label}>Product: </div>
              <div style={{ width: 200 }}>
                {preNewMode ? (
                  <NewProductField
                    propertyName="dataId"
                    preventAutoDisable
                    // disabled={newMode}
                    setPropertyField={this.setPropertyField}
                    getNewProduct={this.getNewProduct}
                    inputRef={c => (this.newProductField = c)}
                    customContextMenuItems={this.getCustomContextMenuItems(
                      this.props
                    )}
                    placeholder="Enter ID and hit the tab key"
                  />
                ) : (
                  <DDIIndexSearch
                    propertyName="dataId"
                    disableLastSearchButton
                    autoFocus
                    fullWidth
                    metaKey="dataId"
                    leader
                    inputRef={c => (this.search = c)}
                    parentType="all"
                    preventAutoDisable
                    disabled={isEditing || newMode}
                    popoverStyle={{
                      width: 750
                    }}
                    customContextMenuItems={this.getBaseContextMenuItems(
                      this.props
                    )}
                  />
                )}
              </div>
              {hasRecord ? (
                <div style={{ marginLeft: 15 }}>
                  <Label
                    propertyName="description"
                    labelStyle={{ width: '100%' }}
                    value={description}
                    // initialValue="A-1 Interstate Mechanical"
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div
            className="search-row-inner-wrapper"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            {hasRecord ? (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: 2,
                  justifyContent: 'center'
                }}
              >
                <div style={{ width: 150 }}>
                  <div
                    style={{
                      background: selectedWarehouseStatusColor,
                      borderRadius: 4,
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 27,
                      fontSize: 12,
                      flexDirection: 'row',
                      width: '100%'
                    }}
                  >
                    {this.getStatusOptionText()}
                  </div>
                </div>
              </div>
            ) : null}
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flex: 2
              }}
            >
              <div style={styles.label}>Warehouse: </div>
              <div style={{ width: 100 }}>
                <DDIIndexSearch
                  propertyName="selectedWarehouseId"
                  fullWidth
                  metaKey="selectedWarehouseId"
                  preventAutoDisable
                  indexSearchType="warehouse"
                  initialValue={defaultSelectedWarehouseId}
                  disabled={newMode || preNewMode}
                />
              </div>
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                flex: 2
              }}
            >
              <div style={styles.label}>UOM: </div>
              <div style={{ width: 100 }}>
                <DDISelectField
                  preventAutoDisable
                  fullWidth
                  propertyName="selectedUOMId"
                  selectValue="dataId"
                  selectDescription="priorPackQuantityAndDataId"
                  values={unitOfMeasures.toJS()}
                  disabled={newMode || preNewMode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const SearchAreaUpdate = shouldUpdate({ whitelist })(SearchArea)

export const contextObj = {
  defaultSelectedWarehouseId: formState =>
    getMeta(formState, 'defaultSelectedWarehouseId', ''),
  defaultTemplates: formState =>
    getMeta(formState, 'defaultTemplates', emptyList),
  description: formState => getValue(formState, 'description', ''),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  newMode: form => form.get('newMode') || false,
  preNewMode: form => form.get('preNewMode') || false,
  productStatusOptions: form =>
    getMeta(form, 'productStatusOptions', emptyList),
  selectedWarehouseStatusColor: form => {
    let value = getValue(form, 'selectedWarehouseStatusColor', empty)

    value = value.toJS ? value.toJS() : value

    if (value) {
      return getRgb(value, 0)
    }
    return '#444'
  },
  statusDescription: form =>
    getValue(form, 'getWarehouseStatusDescription', ''),
  statusType: form => getField(form, 'statusType', ''),
  templateKey: formState => getValue(formState, 'templateKey', ''),
  unitOfMeasures: form => getValue(form, 'unitOfMeasures', emptyList)
}

export default withContext(contextObj)(SearchAreaUpdate)
