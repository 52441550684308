import React from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { FormControlLabel } from '@material-ui/core'
import { DDIButton, DDIToggle } from 'ddiForm/wrapped'
import * as actions from 'pages/SalesOrder/actions'
import { is, getField, getIn } from 'utils'

export const ItemGroupsActions = ({
  dispatch,
  form,
  modal,
  guid,
  printItemGroupDetails
}) => {
  function exitModal() {
    const { id } = modal
    dispatch(actions.cancelItemGroups.try(form, { guid, modalId: id }))
  }

  function handleSave() {
    const { id } = modal
    dispatch(actions.saveItemGroups.try(form, { guid, modalId: id }))
  }

  function handleSort(e) {
    dispatch(
      actions.itemGroupsPropertyChange.try(form, { propertyName: 'sortOrders' })
    )
  }

  return (
    <>
      <div style={{ marginRight: 'auto' }}>
        <DDIButton
          onClick={handleSort}
          variant="contained"
          label="Sort Groups"
          preventAutoDisable
        />
        <FormControlLabel
          control={<DDIToggle propertyName="groups.printItemGroupDetails" />}
          label="Print Group Details"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <DDIToggle
              propertyName="groups.printItemGroupPrices"
              disabled={!printItemGroupDetails}
            />
          }
          label="Print Prices"
          labelPlacement="start"
        />
      </div>
      <DDIButton
        onClick={handleSave}
        variant="contained"
        label="OK"
        preventAutoDisable
      />
      <DDIButton
        onClick={exitModal}
        variant="contained"
        label="Cancel"
        preventAutoDisable
      />
    </>
  )
}

// export const contextObj = {
//   printItemGroupDetails: form => form.fields && form.fields.groups &&
//     form.fields.groups.printItemGroupDetails &&
//     is.bool(form.fields.groups.printItemGroupDetails.value) ?
//       form.fields.groups.printItemGroupDetails.value : false
// }

/* fixed 9/23/2020 SVE */
export const contextObj = {
  guid: form => getIn(form, 'guid') || null,
  printItemGroupDetails: form =>
    getField(form, 'groups.printItemGroupDetails', false)
}

export default withContext(contextObj)(ItemGroupsActions)
