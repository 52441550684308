import React, { memo, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
// import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
// import { is, noop, plainDeepEqual } from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { getAddressLink, Link } from 'pages/ContactMaster/utils'

import {
  ListInfo,
  RightColumn,
  TwoColFlexWrapper,
  TwoColFlexLeft
} from 'pages/ContactMaster/styles/masterStyle'


export const ParentInfo = memo(({
  canCopy,
  dataId,
  dispatch,
  form,
  handleCopy,
  isEditing,
  parent,
  parentInfo,
  style,
  newMode
 }) => {

  const [parentTitle, setParentTitle] = useState('')
  const [address, setAddress] = useState('')
  const [cityStateZip, setCityStateZip] = useState('')
  const [phone, setPhone] = useState('')

  useEffect(() => {
    let title = ''
    const id = parent?.parentId || ''

    if (parent?.parentType) {
      title = parent.parentType === 'C' ? `Customer ${id}` : `Vendor ${id}`
    }
    setParentTitle(title)
  }, [parent])

  useEffect(() => {
    if (parent?.address) {
      setAddress(parent.address)
    } else {
      setAddress({})
    }
  }, [parent])

  useEffect(() => {
    if (address && Object.keys(address).length) {
      const val = parent?.address.city && parent?.address.state ?
        `${address.city}, ${address.state} ${address.zip}` :
        `${address.city} ${address.state} ${address.ip}`
      setCityStateZip(val)
    } else {
      setCityStateZip('')
    }
  }, [address])

  useEffect(() => {
    const val = parent.phone ? `Phone: ${parent.phone}` : parent.phone
    setPhone(val)
  }, [parent.phone])

  function getAddress() {
    if (parent) {
      const { address1, address2, } = address
      return getAddressLink(address1, address2, cityStateZip)
    }
    return ''
  }

  function openParentScreen(event) {
    event.preventDefault()
    const { parentId, parentType } = parent

    if (parentType === 'C') {
      dispatch(
        openScreenAction({
          dataId: parentId,
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          name: 'customerLedger',
          title: `Customer - ${parentId} "${address?.description || ''}"`,
          activeKey: 'ledger',
          route: 'customermaster',
          groupNames: ['summary', 'invoices']
        })
      )
    }
  }

  return (
    <DDICardWrapper title={parentTitle} contentStyle={{ padding: '10px 5px' }} style={style}>
      <TwoColFlexWrapper>
        <TwoColFlexLeft>
          <ListInfo>
            <li>
              <a onClick={openParentScreen}>{address?.description || ''}</a>
            </li>
            <li>{getAddress()}</li>
            <li>{address?.address2 || ''}</li>
            <li>{address?.address3 || ''}</li>
            <li>{cityStateZip}</li>
            <li>{phone}</li>
            <li>
              {parent?.fax ?
                (<div>
                  <span>Fax:</span>
                  <Link
                    dataId={dataId}
                    dispatch={dispatch}
                    form={form}
                    parentId={parent.parentId}
                    parentType={parent.parentType}
                    sendType='Fax'
                    value={parent.fax}
                    newMode={newMode}
                  />
                </div>)
                : null
              }
            </li>
            <li>
              {parent?.email ?
                (<div>
                  <span>Email:</span>
                  <Link
                    dataId={dataId}
                    dispatch={dispatch}
                    form={form}
                    parentId={parent.parentId}
                    parentType={parent.parentType}
                    sendType='Email'
                    value={parent.email}
                    newMode={newMode}
                  />
                </div>)
                : null
              }
            </li>
          </ListInfo>
        </TwoColFlexLeft>
        {isEditing && canCopy && (
          <RightColumn>
            <DDIButton
              variant="contained"
              label="Copy"
              onClick={handleCopy}
              key="copy-parent"
              preventAutoDisable
            />
          </RightColumn>
        )}

      </TwoColFlexWrapper>
    </DDICardWrapper>
  )
})

export default ParentInfo
