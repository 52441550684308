import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
// import memoize from 'memoize-one'
import Grid from 'grid'
import DDICardWrapper from 'components/DDICardWrapper'
import { withContext } from 'ddiForm'
import { emptyList, getField, getValue } from 'utils'
// import { formatDateFields } from 'utils'
import { Icon, IconButton } from '@material-ui/core'
import { setGridHeight } from 'pages/SalesOrder/utils'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'

import {
  initPrintRoutine as initPrintRoutineAction,
  sendSalesOrderDocument as sendSalesOrderDocumentAction,
  storeUIFeatureState as storeUIFeatureStateAction,
  sendLinkedSalesOrderDocument as sendLinkedSalesOrderDocumentAction
} from 'pages/SalesOrder/actions'
import CellRendererSelector from './CellRendererSelector'

import '../styles/linked-orders.scss'

// dataId: "2020794-01"
// details: null
// displayNameResolved: "Spawned B/O"
// statusDescription: null
// statusResolved: "O,B,P"
const getDataIds = rowData =>
  rowData.reduce((acc, next) => {
    acc = acc.concat(next.dataId)
    return acc
  }, [])
const edgeCases = [
  'Special P/O',
  'Drop Ship P/O',
  'Stock Transfer',
  'Work Order'
]
export const LinkedOrders = ({ linkedOrdersList, form, dispatch, dataId }) => {
  const linkedOrders = linkedOrdersList?.toJS ? linkedOrdersList.toJS() : []
  const [selectedLinkedOrderIndex, setSelectedLinkedOrderIndex] = useState(null)

  useEffect(() => {
    setSelectedLinkedOrderIndex(null)
  }, [dataId])

  const getRowNodeId = data => data.dataId

  const columnDefs = [
    {
      field: 'displayNameResolved',
      headerName: 'Type',
      filter: false,
      suppressMenu: true
    },
    {
      field: 'dataId',
      headerName: 'Order',
      filter: false,
      suppressMenu: true,
      // cellRendererFramework: cellRendererSelector(TooltipField, {
      //   anchorToGridCellText: true,
      //   indexSearchType: 'salesOrder',
      //   position: 'right-end',
      //   recordNameKey: 'value',
      //   sensitivity: 100,
      //   type: 'salesOrder'
      // })(IndexSearchCell)),
      cellRendererFramework: CellRendererSelector(
        IndexSearchCell,
        {
          anchorToGridCellText: true,
          indexSearchType: 'salesOrder',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'salesOrder'
        },
        props => !edgeCases.includes(props.data?.displayNameResolved),
        // return true
        { tooltipField: true }
      ),
      cellRendererParams: {
        form,
        linkTo: 'salesOrder',
        groupNames: ['header', 'detail']
      }
    },
    {
      field: 'details',
      headerName: 'Details',
      // valueFormatter: formatDateFields,
      filter: false,
      suppressMenu: true
    },
    {
      field: 'statusResolved',
      tooltipField: 'statusDescription',
      headerName: 'Status',
      filter: false,
      suppressMenu: true,
      cellStyle: params => ({ color: '#563d7c' })
    }
  ]

  const printLinkedOrder = event =>
    dispatch(
      initPrintRoutineAction.try(form, {
        type: 'printLinkedOrder',
        selectedLinkedOrderIndex
      })
    )

  const sendLinkedOrder = event =>
    dispatch(
      sendLinkedSalesOrderDocumentAction.try(form, { selectedLinkedOrderIndex })
    )

  const topRightOptions = () => {
    const disabled = selectedLinkedOrderIndex === null
    const iconStyle = { marginRight: 10, padding: 0 }

    return (
      <div>
        <IconButton
          disabled={disabled}
          onClick={printLinkedOrder}
          size="medium"
          style={iconStyle}
        >
          <Icon>print</Icon>
        </IconButton>
        <IconButton
          disabled={disabled}
          onClick={sendLinkedOrder}
          size="medium"
          style={iconStyle}
        >
          <Icon>email</Icon>
        </IconButton>
        <IconButton disabled size="medium" style={iconStyle}>
          <Icon>edit</Icon>
        </IconButton>
      </div>
    )
  }

  const onRowSelected = params => {
    if (params.node.selected) {
      setSelectedLinkedOrderIndex(params.rowIndex)
    }
  }

  return (
    <div className="linked-orders-outer-wrapper">
      <DDICardWrapper
        title="Linked Orders"
        secondaryTitle={topRightOptions()}
        secondaryTitleStyle={{
          float: 'right',
          marginTop: -3
        }}
        contentStyle={{ padding: 0 }}
      >
        <Grid
          getRowNodeId={getRowNodeId}
          rowData={Array.isArray(linkedOrders) ? linkedOrders : []}
          columnDefs={columnDefs}
          fullWidth
          height={setGridHeight(linkedOrders, 1)}
          // height={300}
          width="100%"
          style={{ width: '100%' }}
          rowSelection="single"
          onRowSelected={onRowSelected}
          suppressHorizontalScroll
        />
      </DDICardWrapper>
    </div>
  )
}

// export default withContext({
//   dataId: form => getField(form, 'dataId', ''),
//   linkedOrders: form => getValue(form, 'linkedOrders', [])
// })(LinkedOrders)

/* fixed 9/25/2020 SVE */
export default withContext({
  dataId: form => getField(form, 'dataId', ''),
  linkedOrdersList: form => getValue(form, 'linkedOrders', emptyList)
})(LinkedOrders)
