/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0, react/sort-comp: 0 */
import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import { withContext, Label } from 'ddiForm'
import { useDispatch } from 'react-redux'
import { getIn } from 'utils'
import { DDITextField, DDISerialNumberSearch } from 'ddiForm/wrapped'
import { Icon, Grid } from '@material-ui/core'

import TooltipField from 'hoc/TooltipField'
import { openProductAnalysisScreen } from 'pages/StockReceipts/components/StageSalesOrdersModal/components/OpenProductAnalysisCell'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import LabeledField from './LabeledField'
import ValidatedField from './ValidatedField'

const ValueLink = ({
  isModal = false,
  form,
  route = '',
  value,
  screenOpenArgs
}) => {
  const buttonLinkStyle = {
    color: '#517b9c',
    fontWeight: 500,
    margin:
      '-3px 0 0 0' /* have to do this because of other bad CSS on this screen */,
    padding: 0,
    outline: 0,
    border: 0
  }

  const dispatch = useDispatch()

  const openScreenLink = e => {
    if (dispatch && value) {
      if (isModal && form && route === 'productmaster') {
        dispatch(openProductAnalysisScreen(form, value))
      } else {
        dispatch(
          openScreenAction({
            ...screenOpenArgs,
            dataId: value
          })
        )
      }
    }
  }

  return (
    <button type="button" onClick={openScreenLink} style={buttonLinkStyle}>
      {value}
    </button>
  )
}

const SalesOrderTooltipField = TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'salesOrder',
  keyToActionMap: {
    m: () =>
      openScreenAction({
        name: 'salesorder',
        title: 'Sales Order',
        image: 'sales_order_entry_16.png',
        route: 'salesorder',
        groupNames: ['header', 'detail'],
        newInstance: true
      })
  },
  position: 'right-end',
  recordNameKey: 'salesOrderId',
  sensitivity: 100,
  type: 'salesOrder'
})(ValueLink)

const ProductTooltipCell = TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'Product',
  keyToActionMap: {
    m: () =>
      openScreenAction({
        route: 'productmaster',
        title: 'Product Analysis',
        activeKey: 'analysis',
        image: 'inventory_product_analysis_16.png',
        groupNames: ['analysis', 'overview']
      })
  },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(ValueLink)

const SaleInformation = props => {
  const {
    form,
    isEditing,
    productId,
    salesOrderId,
    warehouseId,
    invoiceIdForSerialNumber,
    returnInvoiceId
  } = props
  const isModal =
    (props?.data?.parentForm && props?.data?.parentForm?.match(/salesOrder/)) ||
    false

  return (
    <DDICardWrapper title="Sale Information" contentStyle={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={4}>
            <label style={{ float: 'right', marginTop: '3px' }}>
              Return S/O #:
            </label>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ display: 'flex', alignItems: 'top', marginTop: 3 }}
          >
            <SalesOrderTooltipField
              propertyName="salesOrderId"
              salesOrderId={salesOrderId}
              disabled
              value={salesOrderId}
              screenOpenArgs={{
                image: 'sales_order_entry_32.png',
                imageFileName16: 'sales_order_entry_16.png',
                description: 'Sales Order',
                name: 'salesOrder',
                route: 'salesorder',
                title: 'Sales Order',
                newInstance: true,
                read: true
              }}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1} xs={6}>
          <LabeledField label="Orig Invoice #">
            <ValidatedField>
              <DDITextField
                propertyName="returnInvoiceId"
                disabled={!isEditing}
              />
            </ValidatedField>
          </LabeledField>
        </Grid>

        <Grid item container spacing={1} xs={6}>
          <LabeledField label="Writer">
            <DDITextField propertyName="writer" disabled />
          </LabeledField>
        </Grid>
        <Grid item xs={6} />
        <Grid item container spacing={1} xs={6}>
          <Grid item xs={4}>
            <label style={{ float: 'right', marginTop: '3px' }}>Product:</label>
          </Grid>
          <Grid item xs={8} style={{ display: 'flex', alignItems: 'center' }}>
            <ProductTooltipCell
              value={productId}
              isModal={isModal}
              form={form}
              route="productmaster"
              screenOpenArgs={{
                route: 'productmaster',
                title: 'Product Analysis',
                activeKey: 'analysis',
                image: 'inventory_product_analysis_16.png',
                groupNames: ['analysis', 'overview']
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Label propertyName="productDescription" />
        </Grid>
        <Grid item container spacing={1} xs={6}>
          <LabeledField label="MFG #">
            <ValidatedField>
              <DDITextField propertyName="mfgNumber" disabled={!isEditing} />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid item container spacing={1} xs={6}>
          <LabeledField label="Serial #">
            <ValidatedField>
              <DDISerialNumberSearch
                propertyName="failedSerialNumber"
                meta={{
                  allowInstantSearch: false,
                  allowInvalidValues: true,
                  allowNavigation: false,
                  allowSearchAll: true,
                  allowSpecialProducts: false,
                  fieldName: 'failedSerialNumber',
                  hasFilters: false,
                  hideToggle: true,
                  isSecure: false,
                  minimumKeywordLength: 2,
                  patternValidation: 'S',
                  searchType: 'S'
                }}
                wrapperStyle={{ margin: 0, width: '100%' }}
                preventAutoDisable
                disabled={!isEditing}
                productId={productId}
                warehouseId={warehouseId}
                returnTransactionId={returnInvoiceId}
                anotherValue="Steve Van Etten"
              />
            </ValidatedField>
          </LabeledField>
        </Grid>
      </Grid>
    </DDICardWrapper>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  productId: form => getIn(form, 'values.productId') || '',
  salesOrderId: form => getIn(form, 'values.salesOrderId') || '',
  warehouseId: form => getIn(form, 'values.warehouseId') || '',
  returnInvoiceId: form => getIn(form, 'values.returnInvoiceId') || '',
  invoiceIdForSerialNumber: form =>
    getIn(form, 'values.invoiceIdForSerialNumber') || null
})(SaleInformation)

// <Grid item xs={8}>
// <div className="search-row" style={{ marginBottom: 5 }}>
//   <label>Serial #:</label>
//   <div className="input-wrapper">
//     <DDIIndexSearch
//       propertyName="jmImportSalesOrderWriterId"
//       metaKey="jmImportSalesOrderWriterId"
//       searchType="SystemUsers"
//       wrapperStyle={{ margin: 0, width: '100%' }}
//       preventAutoDisable
//       disabled={!isEditing}
//     />
//   </div>
//   <div className="description-overflow">
//     <Label propertyName="jmImportSalesOrderWriterDescription" />
//   </div>
// </div>
// </Grid>
