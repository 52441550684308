import { deleteIn, setIn, getIn, fromJS } from 'utils'
import { Set } from 'immutable'
import { compose } from 'redux'
import { SET_FIELD } from 'ddiForm/constants'
import { updateValuesAndFields } from 'ddiForm/utils'
import { boolFlip, withHasRecord } from 'ddiForm/reducerEnhancers'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import {
  LOCK_FOR_EDIT,
  RESET_MASTER_FIELDS,
  GET_ENTITY
} from 'ddiForm/MasterScreen/constants'

import selectionCriteriaBehaviors from 'components/SelectionCriteriaModal/reducer'
import standardSimpleFormBehaviors from 'components/MasterScreen/StandardSimpleForm/reducer'

import {
  CLEAR_IS_EDITING_FLAG as SOE_CLEAR_IS_EDITING_FLAG,
  GET_PREVIOUS_END_USER_INFO as SOE_GET_PREVIOUS_END_USER_INFO
  // CANCEL_EDIT_WARRANTY_TAG as SOE_CANCEL_EDIT_WARRANTY_TAG,
  // SAVE_WARRANTY_TAG as SOE_SAVE_WARRANTY_TAG
} from 'pages/SalesOrder/common/WarrantyTag/constants'
import * as CONSTANTS from './constants'
import { updateEditedFields } from '../BranchMaster/utils'

const resetWarrantyTagScreen = (state, action) => {
  state = masterScreenBehaviors[RESET_MASTER_FIELDS](state, action)

  state = setIn(state, 'fields.dataId.value', '')
  state = setIn(state, 'values.dataId', '')

  state = setIn(state, 'editedFields', Set())
  state = setIn(state, 'isEditing', false)
  state = setIn(state, 'hasRecord', false)

  return state
}

export const withNewMode = (bool = true) => (state, _) =>
  boolFlip(bool)('newMode')(state, _)

export default {
  [CONSTANTS.PROPERTY_CHANGE.SUCCESS]: compose(
    withHasRecord(true),
    (state, { payload }) => {
      let result = state

      if (payload) {
        result = updateValuesAndFields(result, payload)
      }

      return result
    }
  ),
  [CONSTANTS.PROPERTY_CHANGE.FAILURE]: compose(
    withHasRecord(true),
    (state, { payload }) => {
      let result = state

      if (payload) {
        result = updateValuesAndFields(result, payload)
        // result = setIn(
        //   result,
        //   'values.validationErrors',
        //   fromJS(payload.validationErrors)
        // )
      }

      return result
    }
  ),
  [SET_FIELD]: (state, { payload: { propertyName, value, results } }) => {
    let result = state

    if (propertyName === 'dataId') {
      result = setIn(result, 'editedFields', Set())
    }

    const searchFields = ['vendorId']

    const propertyNameinSearchFields = searchFields.includes(propertyName)

    if (propertyNameinSearchFields) {
      const descriptionKey = propertyName.replace('Id', 'Description')
      result = updateEditedFields(result, descriptionKey)
      if (value && results?.description) {
        result = setIn(result, `values.${descriptionKey}`, results.description)
      } else {
        result = setIn(result, `values.${descriptionKey}`, '')
      }
    }

    return result
  },

  [CONSTANTS.ORIGINAL_INVOICE_SEARCH.FAILURE]: state => {
    let result = state
    result = setIn(result, 'fields.returnInvoiceId.value', '')
    result = setIn(result, 'values.returnInvoiceId', '')

    return result
  },

  // [CONSTANTS.SAVE_WARRANTY_TAG.SUCCESS]: (state, { payload }) => {
  //   let result = state

  //   if (payload) {
  //     result = updateValuesAndFields(result, payload)
  //     result = setIn(result, 'isEditing', false)
  //   }

  //   result = setIn(result, 'values.validationErrors', fromJS([]))
  //   return result
  // },

  [CONSTANTS.DELETE_WARRANTY_TAG]: (state, action) => {
    let result = state
    result = resetWarrantyTagScreen(result, action)
    return result
  },
  ...masterScreenBehaviors,
  ...standardSimpleFormBehaviors,
  ...selectionCriteriaBehaviors,
  [LOCK_FOR_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[LOCK_FOR_EDIT.SUCCESS](result, action)

    if (
      action?.payload?.validationErrors &&
      Array.isArray(action.payload.validationErrors)
    ) {
      result = setIn(
        result,
        'values.validationErrors',
        fromJS(action.payload.validationErrors)
      )
    }
    debugger

    const isEditing = action?.payload?.isLocked || false
    if (isEditing !== getIn(result, 'isEditing')) {
      debugger
      result = setIn(result, 'isEditing', isEditing)
    }

    return result
  },
  [CONSTANTS.DELETE_WARRANTY_TAG.SUCCESS]: (state, action) => {
    let result = state
    result = resetWarrantyTagScreen(result, action)
    return result
  },
  [GET_ENTITY.SUCCESS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[GET_ENTITY.SUCCESS](result, action)

    const isEditing = action?.payload?.isLocked || false
    // console.log('isEditing', isEditing)
    // console.log(result)
    // debugger

    result = setIn(result, 'isEditing', isEditing)

    return result
  },
  [SOE_CLEAR_IS_EDITING_FLAG]: (state, action) => {
    let result = state
    debugger
    result = setIn(result, 'isEditing', false)
    return result
  },
  [SOE_GET_PREVIOUS_END_USER_INFO.SUCCESS]: (state, { payload }) => {
    let result = state

    debugger
    if (payload) {
      result = updateValuesAndFields(result, payload)
    }

    return result
  }
}
