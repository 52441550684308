// import React from 'react'
import {
  actionChannel,
  take,
  call,
  select,
  put,
  putResolve,
  fork
} from 'redux-saga/effects'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as EVENT_MODAL_CONSTANTS from 'modals/EventModal/constants'
import { UPDATE_GRID_CELL_DATA } from 'components/EditableGrid/constants'
import {
  updateGridCellData,
  additionalValidationSuccess,
  validateGridData
} from 'components/EditableGrid/actions'
import { addModal } from 'modals/actions'
import {
  changeDisplayTabs,
  enterNewMode,
  changeFormTab,
  setField,
  quickCancelEdit
} from 'ddiForm/actions'
import {
  getFormSelector,
  getSelectedTabsFromState,
  getMapResponse
} from 'ddiForm/utils'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { displayValidationErrors } from 'ddiForm/sagas'
import { getRecord } from 'ddiForm/MasterScreen/sagas'
import { tryGetModule } from 'pages/Layout/sagas'
import { add } from 'pages/Layout/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { fromJS, getIn } from 'utils'
import {
  defaultGetListActivitiesApiParams,
  listActivitiesProcess
} from 'components/MasterScreen/Activities/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { updatePhoto } from 'pages/ContactMaster/actions'

import { api } from 'services'

import eventModalSagas from 'modals/EventModal/sagas'
import notesModalSagas from 'modals/NotesModal/sagas'
import templateSagas from 'components/MasterScreen/Template/sagas'
import * as templateApi from 'components/MasterScreen/Template/api'

import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'

import mergeModalSagas, {
  openMergeModalListener
} from 'modals/MergeModal/sagas'

import taxMatrixModalSagas from 'pages/CustomerMaster/modals/TaxMatrixModal/sagas'
import selectionCriteriaModalSagas from 'components/SelectionCriteriaModal/sagas'
import editableGridSagas from 'components/EditableGrid/sagas'
import customerLedgerSagas, {
  getNextARNote
} from 'pages/CustomerMaster/tabs/Ledger/lib/sagas'

import { additionalAuditsDataFlagListener } from 'components/MasterScreen/Audit/sagas'
import { SAVE_CONTACT } from 'pages/ContactMaster/constants'
import UpdateAsModal from './modals/SaveAsModal'
import * as actions from './actions'
import * as CONSTANTS from './constants'

export function* deleteContactProcess(payload, form) {
  const { dataId, parentId, parentType } = payload

  yield put(actions.deleteContactAsync.request(form))

  const { response, error } = yield call(api.deleteContact, {
    dataId,
    groupNames: ['setup'],
    parentId,
    parentType
  })

  if (response) {
    yield put(
      actions.deleteContactAsync.success({ ...response, ...payload }, form)
    )
  } else {
    yield put(actions.deleteContactAsync.failure(error, form))
  }
}

export function* deleteContactModalConfirmationProcess(
  form,
  dataId,
  parentId,
  parentType,
  modalTitleSuffix
) {
  yield call(
    confirmationModal,
    `Are you sure you want to delete contact ${modalTitleSuffix}?`
  )
  const { type } = yield take([CONFIRMED, CANCELED])
  if (type === CONFIRMED) {
    /* must lock the contact record first with new API */
    const { response, error } = yield call(api.lockContact, {
      dataId,
      parentId,
      parentType
    })

    if (response) {
      yield fork(deleteContactProcess, { dataId, parentId, parentType }, form)
    }
  }
}

export function* deleteContactListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_CONTACT.TRY)
    const {
      meta: { form },
      payload: { dataId, modalTitleSuffix, parentId, parentType }
    } = action

    if (form === formListener) {
      yield fork(
        deleteContactModalConfirmationProcess,
        form,
        dataId,
        parentId,
        parentType,
        modalTitleSuffix
      )
    }
  }
}

export function* refreshActivitiesListener(formListener) {
  const channel = yield actionChannel([
    EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS,
    EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS
  ])

  while (true) {
    const action = yield take(channel)
    /* second case is when somebody added an event via the Dashboard calendar */
    if (
      (action.meta && action.meta.form && action.meta.form === formListener) ||
      (action.meta && action.meta.form && action.meta.form === 'calendar')
    ) {
      const activitiesApiParams = yield call(
        defaultGetListActivitiesApiParams,
        formListener
      )
      yield fork(listActivitiesProcess, activitiesApiParams)
    }

    /*
      refresh the ARNote any time
      there is a change in Activities
    */
    yield fork(getNextARNote, formListener)
  }
}

const patternMatchTest = (arr, validMatch = /^\d+[A|X|N]$/gi) =>
  arr.reduce((acc, next) => {
    if (!next.match(validMatch)) {
      acc += 1
    }
    return acc
  }, 0)

export function* patternMatchingProcess(payload, form) {
  const { value, rowIndex, propertyName, field } = payload

  if (propertyName === 'poPatterns') {
    if (!value) {
      return
    }

    const valueParts = value.split('"-"')
    const splitString = value.match(/.{1,2}/g)
    let isInvalid = false

    if (valueParts.length > 1) {
      isInvalid = patternMatchTest(valueParts) !== 0
    } else {
      isInvalid = patternMatchTest(splitString) !== 0
    }

    if (isInvalid) {
      yield call(warningModal, 'Not a valid matching pattern!', 'Attention!')
      // yield call(delay, 100)
      yield put(
        updateGridCellData(form, {
          rowIndex,
          propertyName,
          field,
          value: ''
        })
      )
      yield put(
        validateGridData(form, {
          propertyName
        })
      )
    } else {
      yield put(
        additionalValidationSuccess(form, {
          rowIndex,
          propertyName,
          validated: true
        })
      )
    }
  }

  if (propertyName === 'poNumbers' && field === 'dataId' && value !== '') {
    const formState = yield select(state => getIn(state, `ddiForm.${form}`))
    const poPatterns = getIn(formState, 'fields.poPatterns.rowData')
      ? getIn(formState, 'fields.poPatterns.rowData').toJS()
      : []

    const getRegExString = (
      arr,
      charMap = {
        A: 'a-zA-Z',
        N: '0-9',
        X: 'a-zA-Z0-9'
      }
    ) =>
      arr.reduce((ac, nxt) => {
        const numeric = nxt.replace(/[a-zA-Z]/i, '')
        const alpha = nxt.replace(/[0-9]/i, '')

        if (charMap[alpha]) {
          ac = ac.concat(`[${charMap[alpha]}]{${numeric}}-`)
        }
        return ac
      }, '')

    const isValid = poPatterns.reduce((acc, next) => {
      if (next.dataId) {
        const valueParts = next.dataId.split('"-"')
        const splitString = next.dataId.match(/.{1,2}/g)
        if (valueParts.length > 1) {
          /* generate the pattern and remove the last dash */
          const regexResult = getRegExString(valueParts)
          const pattern = regexResult.slice(0, -1)
          const isMatch = value.match(new RegExp(`^${pattern}$`, 'gi'))

          if (isMatch && isMatch.length === 1) {
            acc += 1
          }
        } else {
          /* generate the pattern and remove the last dash */
          const regexResult = getRegExString(splitString)
          const pattern = regexResult.slice(0, -1)
          const isMatch = value.match(new RegExp(`^${pattern}$`, 'gi'))

          if (isMatch && isMatch.length === 1) {
            acc += 1
          }
        }
      }
      return acc
    }, 0)

    const hasValidPattern = poPatterns.reduce((acc, next) => {
      if (next.dataId) {
        acc += 1
      }
      return acc
    }, 0)

    if (isValid === 0 && poPatterns.length && hasValidPattern > 0) {
      yield call(warningModal, 'Invalid P.O. Number!', 'Attention!')
      // yield call(delay, 100)
      yield put(
        updateGridCellData(form, {
          rowIndex,
          propertyName,
          field,
          value: ''
        })
      )
    }
  }
}

export function* patternMatchingListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(UPDATE_GRID_CELL_DATA)

    if (
      (form === formListener && payload.propertyName === 'poPatterns') ||
      payload.propertyName === 'poNumbers'
    ) {
      yield fork(patternMatchingProcess, payload, form)
    }
  }
}

export function* onAddContactProcess(response, meta, form) {
  // console.log(response, meta, form)
  yield put(enterNewMode(form))
  yield putResolve(
    masterActions.getEntityAsync.success(
      {
        ...response,
        parent: {
          ...response.parent,
          address:
            response.parent && response.parent.address
              ? response.parent.address
              : {
                  address1: null,
                  address2: null,
                  address3: null,
                  city: null,
                  description: null,
                  state: null,
                  zip: null
                }
        }
      },
      {
        form,
        thunk: meta.thunk
      }
    ) // TODO GENERICS... args of cb succ/fail takes async method etc..
  )
}

export function* onAddError(err) {
  yield call(warningModal, err.message, err.modalTitle)
}

/*
  ensure that we are showing all of the tabs if the dataId gets cleared out
  this happens when the user has selected a template, but then goes and removes
  the template ID from the main dataId indexSearch field
*/
export function* unsetTemplateListener(formListener) {
  const channel = yield actionChannel(DDICONSTANTS.SET_FIELD)
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(channel)

    if (formListener === form && propertyName === 'dataId') {
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const templateKey = getIn(formState, 'values.templateKey')
      const preNewMode = getIn(formState, 'values.preNewMode')
      const newEntity = getIn(formState, 'newEntity')
      // debugger

      if (
        (value === '' && newEntity) ||
        (value === '' && templateKey) ||
        (value && preNewMode)
      ) {
        // debugger
        yield put(changeDisplayTabs(form))
      }
    }
  }
}

export function* deleteCustomerProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const dataId = getIn(formState, 'fields.dataId.value')
  const description = getIn(formState, 'fields.description.value')

  const warningMessage = `Are you sure you want to delete Customer - ${dataId}: "${description}"`

  yield call(confirmationModal, warningMessage, 'Delete?')

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put({
      type: CONSTANTS.DELETE_CUSTOMER_RECORD.REQUEST,
      meta: { form, apiRequest: true }
    })

    const { response, error } = yield call(api.deleteCustomerRecord, {
      dataId
    })

    if (response) {
      yield put({
        type: CONSTANTS.DELETE_CUSTOMER_RECORD.SUCCESS,
        payload: response,
        meta: { form }
      })
      yield put({
        type: MASTER_CONSTANTS.RESET_MASTER_FIELDS,
        meta: { form }
      })
    } else {
      yield put({
        type: CONSTANTS.DELETE_CUSTOMER_RECORD.FAILURE,
        payload: error,
        meta: { form }
      })
    }
  }
}

export function* deleteCustomerRecordListener(form) {
  while (true) {
    yield take(CONSTANTS.DELETE_CUSTOMER_RECORD.TRY)
    yield call(deleteCustomerProcess, form)
  }
}

export function* createCustomerRecordProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const autoGenerateId = getIn(formState, 'meta.autoGenerateId') || false
  const hasRecord = getIn(formState, 'hasRecord') || false
  const isEditing = getIn(formState, 'isEditing') || false
  const templateKey = getIn(formState, 'values.templateKey') || ''
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const isNew = getIn(formState, 'values.isNew') || false
  // debugger

  if (hasRecord && isEditing && dataId && !isNew) {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Create New?'
    )
    const action = yield take([CONFIRMED, CANCELED])

    /* unlock, but not reload the record */
    if (action.type === CONFIRMED) {
      /* AND, what are we unlocking? A template? Or a standard record? */
      if (templateKey) {
        yield call(templateApi.unlockTemplate, { templateKey, form })
      } else {
        yield call(api.cancelEdit, {
          recordName: dataId,
          form: 'customerMaster'
        })
      }

      // debugger
      yield fork(dispatchRecordCreationProcess, autoGenerateId, formState, form)
    }
  } else {
    /* in the case that the user is adding multiple NEW customer records in a row */
    if (isNew) {
      // debugger
      yield call(api.cancelEdit, { recordName: dataId, form: 'customerMaster' })
    }

    // debugger
    yield fork(dispatchRecordCreationProcess, autoGenerateId, formState, form)
  }
}

export function* dispatchRecordCreationProcess(
  autoGenerateId,
  formState,
  form
) {
  yield put({
    type: MASTER_CONSTANTS.RESET_MASTER_FIELDS,
    meta: { form }
  })

  if (autoGenerateId) {
    // debugger
    yield fork(postToCustomerCreateApi, form, '')
  } else {
    // debugger
    yield fork(
      manualCreateCustomerRecordProcess,
      form,
      getIn(formState, 'values.tempId')
    )
  }
}

export function* manualCreateCustomerRecordProcess(form, dataId) {
  if (dataId) {
    yield fork(postToCustomerCreateApi, form, dataId)
  } else {
    yield put(actions.invokePreNewMode(form))
    yield fork(displayLimitedTabs, form)
  }
}

export function* cancelPreNewModeListener(formListener) {
  while (true) {
    const {
      payload: { displayAllTabs },
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_PRE_NEW_MODE)

    if (form === formListener && displayAllTabs) {
      // debugger
      yield put(changeDisplayTabs(form))
    }
  }
}

export function* cleanupAfterNewCancellation(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const isNew = getIn(formState, 'values.isNew')
  // debugger

  /*
    if we are cancelling the editing of a newly added Customer,
    we need to snap the tabs back to their original configuaration
    and reset all fields
  */
  if (isNew) {
    yield put(changeDisplayTabs(form))

    /* clear the dataId */
    yield put({
      meta: { form },
      type: DDICONSTANTS.SET_FIELD,
      payload: { propertyName: 'dataId', value: '' }
    })

    /* AND reset the fields here */
    yield put({
      type: MASTER_CONSTANTS.RESET_MASTER_FIELDS,
      meta: { form }
    })
  }
}

export function* cancelEditSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS)

    if (form === formListener) {
      yield fork(cleanupAfterNewCancellation, form)
    }
  }
}

export function* displayLimitedTabs(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  if (selectedPrimaryTab !== 'setup' || selectedSecondaryTab !== 'main') {
    yield put(changeFormTab(form, 'setup', 'main'))
  }
}

export function* postToCustomerCreateApi(form, dataId) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  // const groupNames = getSelectedTabsFromState(formState)
  let allTabs = getIn(formState, 'masterOptions.tabs') || fromJS([])
  allTabs = allTabs?.toJS ? allTabs.toJS() : []

  const skipTabs = [
    'creditreferences',
    'pricecontracts',
    'pospecs',
    'partnumbers',
    'taxmatrix'
  ]
  /* including routes in the API request causes errors in the Truck Routes component */

  const groupNames = allTabs
    ?.find(x => x.title === 'Setup')
    ?.tabs?.reduce(
      (acc, next) => {
        if (next.access && !skipTabs.includes(next.access)) {
          acc = acc.concat(next.access)
        }

        return acc
      },
      ['setup']
    ) || ['setup', 'main']
  // console.log('groupNames', groupNames)

  yield put({
    meta: { form, apiRequest: true },
    type: CONSTANTS.CREATE_CUSTOMER_RECORD.REQUEST
  })

  const { response, error } = yield call(api.createCustomerRecord, {
    dataId,
    groupNames
  })

  const mapResponse = getMapResponse({ formState, tabIds: groupNames })

  if (response) {
    yield put({
      type: MASTER_CONSTANTS.GET_ENTITY.SUCCESS,
      payload: {
        ...mapResponse({ response, groupNames, formState, tabIds: groupNames }),
        preNewMode: false
      },
      meta: { form }
    })

    /*
      if we have a new customer record,
      we need to flag it to prevent the 'Delete' button
      from showing up, and we need to display base tabs
    */
    if (response.isNew) {
      yield put({
        type: CONSTANTS.FLAG_NEW_CUSTOMER_RECORD,
        meta: { form }
      })
      yield fork(displayLimitedTabs, form)
    } else {
      yield put(changeDisplayTabs(form))
    }
  } else {
    yield put({
      type: MASTER_CONSTANTS.GET_ENTITY.FAILURE,
      payload: error,
      meta: { form }
    })

    /* system handles this error in general */
    if (error?.status !== 496) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* createCustomerListener(formListener) {
  const channel = yield actionChannel(CONSTANTS.CREATE_CUSTOMER_RECORD.TRY)
  while (true) {
    const {
      meta: { form }
    } = yield take(channel)
    // debugger
    if (formListener === form) {
      yield fork(createCustomerRecordProcess, form)
    }
  }
}

export function* createCustomerOnTabListener(formListener) {
  while (true) {
    const {
      payload: { post },
      meta: { form }
    } = yield take(CONSTANTS.SAVE_TEMP_ID)
    if (form === formListener && post) {
      yield put({
        type: CONSTANTS.CREATE_CUSTOMER_RECORD.TRY,
        meta: { form }
      })
    }
  }
}

export function* deleteShipToProcess(dataId, description, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const parentId = getIn(formState, 'fields.dataId.value')

  yield call(
    confirmationModal,
    `Are you sure you want to delete Ship To ${dataId} - ${description}?`
  )
  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CONFIRMED) {
    yield fork(deleteShipToConfirmedProcess, form, dataId, parentId)
  }
}

export function* deleteShipToConfirmedProcess(form, dataId, parentId) {
  yield put({
    type: CONSTANTS.DELETE_SHIP_TO.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.deleteShipTo, {
    dataId,
    parentId
  })

  if (response) {
    yield put({
      type: CONSTANTS.DELETE_SHIP_TO.SUCCESS,
      payload: {
        ...response,
        dataId
      },
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.DELETE_SHIP_TO.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* deleteShipToListener(formListener) {
  while (true) {
    const {
      payload: { dataId, description },
      meta: { form }
    } = yield take(CONSTANTS.DELETE_SHIP_TO.TRY)

    // debugger
    if (form === formListener) {
      yield fork(deleteShipToProcess, dataId, description, form)
    }
  }
}

export function* showShipToWarningModalListener(formListener) {
  while (true) {
    const {
      payload: { dataId, description }
    } = yield take(CONSTANTS.SHOW_SHIP_TO_WARNING_MODAL)
    yield call(
      warningModal,
      `Ship To ${dataId} "${description}" is reserved for ${description}`,
      'System Ship To'
    )
  }
}

export function* onSave(formState) {
  // debugger
  yield console.log(formState)
}

export const listContactsProcess = function* proc(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  // yield put(actions.listContacts.request(form))
  yield put(masterActions.getEntityAsync.request(form))
  // const { response, error } = yield call(api.listContacts, { dataId })
  const { response, error } = yield call(api.getCustomer, {
    dataId,
    groupNames: ['contacts']
  })

  if (response) {
    yield put(
      masterActions.getEntityAsync.success(
        {
          ...response,
          contacts: response?.contacts?.contacts
        },
        form
      )
    )
  } else {
    yield put(masterActions.getEntityAsync.failure(form, error))
  }
}

export function* listContactsProcessProxy(form, payload) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (
    selectedPrimaryTab === 'contacts' &&
    payload?.parent?.parentType &&
    payload?.parent?.parentType === 'C'
  ) {
    yield fork(listContactsProcess, form)
  }
}

export function* refreshContactsListener(formListener) {
  while (true) {
    const action = yield take([DDICONSTANTS.SAVE.SUCCESS, SAVE_CONTACT.SUCCESS])

    const {
      meta: { form },
      payload,
      type
    } = action

    if (form && form.includes(formListener) && type === SAVE_CONTACT.SUCCESS) {
      yield fork(listContactsProcess, formListener)
    }

    if (form === 'contact') {
      yield fork(listContactsProcessProxy, formListener, payload)
    }
  }
}

export function* openContactListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_CONTACT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openContactProcess, form, payload)
    }
  }
}

export function* openContactProcess(form, payload) {
  const { dataId, description, parentId, parentType } = payload

  if (!dataId || !parentId) return

  yield put(actions.openContact.request(form))

  const { response, error } = yield call(api.inquireContact, {
    dataId,
    parentId,
    parentType
  })

  if (response?.success) {
    yield put(actions.openContact.success(response, form))
    const layout = yield select(state => getIn(state, 'layout'))

    if (getIn(layout, 'openScreens.contact')) {
      // PROLLY not best method --LL 2/20/2020
      // DEFINITELY NOT!!! --MS 2/28/2020
      yield put(updatePhoto('contact', { base64: null }))
    }

    yield put(
      openScreenAction({
        dataId,
        groupNames: ['setup'],
        parentId,
        parentType,
        image: 'default-inform-icon-16.png',
        name: 'contact master',
        recordType: 'Contacts',
        title: `Contact - ${dataId} - "${description}"`
      })
    )
  } else {
    yield put(actions.openContact.failure(error))
    yield fork(listContactsProcess, form)
  }
}

export function* changePODollarLimitProcess(form, index, field, poNumber) {
  // debugger
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.changePODollarLimit.request(form))

  const { response, error } = yield call(api.changePODollarLimit, {
    dataId,
    index,
    poNumber
  })

  if (response) {
    yield put(
      actions.changePODollarLimit.success(
        {
          ...response,
          field,
          rowIndex: index
        },
        form
      )
    )
    // debugger
  } else {
    yield put(
      actions.changePODollarLimit.failure(
        {
          ...error,
          rowIndex: index
        },
        form
      )
    )
    // debugger
  }
}

export function* changePODollarLimitListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex, propertyName, field, value }
    } = yield take(UPDATE_GRID_CELL_DATA)

    if (form === formListener) {
      if (propertyName === 'poNumbers' && field === 'dataId') {
        yield fork(changePODollarLimitProcess, form, rowIndex, field, value)
      }
    }
  }
}

export function* deletePODollarLimitProcess(form, index) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.deletePODollarLimit.request(form))

  const { response, error } = yield call(api.deletePODollarLimit, {
    dataId,
    index
  })

  if (response) {
    yield put(
      actions.deletePODollarLimit.success(
        {
          ...response,
          rowIndex: index
        },
        form
      )
    )
  } else {
    yield put(actions.deletePODollarLimit.failure(error, form))
  }
}

export function* deletePODollarLimitListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex }
    } = yield take(CONSTANTS.DELETE_PO_DOLLAR_LIMIT.TRY)

    if (form === formListener) {
      yield fork(deletePODollarLimitProcess, form, rowIndex)
    }
  }
}

export function* propertyChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const groupNames = getSelectedTabsFromState(formState)
  const templateKey =
    getIn(formState, 'values.templateKey') || getIn(formState, 'templateKey')
  // debugger

  // debugger
  const apiParams = templateKey
    ? {
        // dataId,
        templateKey,
        properties: {
          [propertyName]: value
        },
        groupNames
      }
    : {
        dataId,
        properties: {
          [propertyName]: value
        },
        groupNames
      }

  const { response, error } = yield call(
    api.customerMasterPropertyChange,
    apiParams
  )

  if (response) {
    yield put(actions.propertyChange.success(response, form))
  } else {
    yield put(actions.propertyChange.failure(error, form))
  }
}

export function* handleGetCustomerByShipToIdProcess(
  form,
  customerId,
  customerDescription
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''

  if (!dataId) {
    yield put(
      setField(form, 'dataId', customerId, false, true, {
        description: customerDescription
      })
    )
  }
}

export function* propertyChangeTriggerListener(formListener) {
  const fieldsToUpdate = ['arContactName', 'arPhone', 'salesmanID', 'arTermsId']
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value, results },
      type
    } = yield take([DDICONSTANTS.SET_FIELD, DDICONSTANTS.BLUR])

    if (form === formListener) {
      // debugger
      if (fieldsToUpdate.includes(propertyName)) {
        yield fork(propertyChangeProcess, form, propertyName, value)
      }

      if (
        propertyName === 'shipToId' &&
        type === DDICONSTANTS.SET_FIELD &&
        results?.parentId &&
        results?.parentDescription
      ) {
        yield fork(
          handleGetCustomerByShipToIdProcess,
          form,
          results.parentId,
          results.parentDescription
        )
      }
    }
  }
}

export function* openScreenFromCustomerMasterProcess(form, payload) {
  const formState = yield select(getFormSelector(payload.name))
  const screen = yield call(tryGetModule, payload.title)

  if (formState) {
    yield putResolve(
      add({
        component: screen.default ? screen.default : screen,
        data: { ...payload },
        allowMultiple:
          screen.options && screen.options.allowMultiple
            ? !!screen.options.allowMultiple
            : false,
        ...payload
      })
    )
  } else {
    yield put(openScreenAction(payload))
  }
}

export function* openScreenFromCustomerMasterListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.OPEN_SCREEN_FROM_CUSTOMER_MASTER)

    if (form === formListener) {
      yield fork(openScreenFromCustomerMasterProcess, form, payload)
    }
  }
}

export function* showWarningModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { message }
    } = yield take(CONSTANTS.SHOW_WARNING_MODAL)

    if (form === formListener && message) {
      yield fork(warningModal, message, 'Attention!')
    }
  }
}

export function* getRecordArgumentsSaga(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const templateKey = getIn(formState, 'values.templateKey')
  const showAllAudits = getIn(formState, 'fields.showAllAudits.value') || false

  return templateKey
    ? {
        templateKey,
        form
      }
    : {
        dataId,
        showAll: showAllAudits
      }
}

export function* handleGetEntityFailureProcess(form, message, status) {
  yield call(warningModal, message, 'Attention!')

  if (
    message === 'This customer does not pertain to your salesperson' ||
    status === 403
  ) {
    yield put(
      setField(form, 'dataId', '', false, true, {
        description: ''
      })
    )
    yield put({
      type: MASTER_CONSTANTS.RESET_MASTER_FIELDS,
      meta: { form }
    })
  }
}

export function* getEntityFailureListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { status, message }
    } = yield take(MASTER_CONSTANTS.GET_ENTITY.FAILURE)

    if (form === formListener && status && status === 403 && message) {
      yield fork(handleGetEntityFailureProcess, form, message, status)
    }
  }
}

export function* saveFailureProcess(action) {
  yield console.log(action)
  if (action.payload.message === 'Must use Save As') {
    const options = {
      component: UpdateAsModal,
      props: {
        title: 'Update As',
        width: 400
      }
    }
    const modal = yield call(addModal, 'customerMaster', options)
    yield put(modal)
  }
}

export function* saveFailureListener(formListener) {
  while (true) {
    const action = yield take(DDICONSTANTS.SAVE.FAILURE)
    if (action.meta.form === formListener) {
      yield fork(saveFailureProcess, action)
    }
  }
}

export function* saveAsProcess({
  meta: { form, thunk },
  payload: { customerName }
}) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  // yield put(actions.deletePODollarLimit.request(form))

  // yield console.log(action)
  const { response, error } = yield call(api.updateCustomerAs, {
    fromDataId: dataId,
    toDataId: customerName
  })

  console.log(response, error)
  if (response) {
    // yield put(
    //   actions.saveAs.success(
    //     {
    //       ...response,
    //       rowIndex: index
    //     },
    //     form
    //   )
    // )
    // yield fork(getRecord, { form, newRecord: customerName })
    yield put({
      meta: { form, thunk },
      type: DDICONSTANTS.SET_FIELD,
      payload: { propertyName: 'dataId', value: customerName }
    })
    yield put(quickCancelEdit(form))
  } else {
    yield put(actions.updateAs.failure(error, { form, thunk }))
    yield fork(displayValidationErrors, error)
    // yield call(
    //   warningModal,
    //   error.validationErrors.reduce((acc, next) => {
    //     acc = acc.concat(next.message)
    //     return acc
    //   }, ''),
    //   'Attention'
    // )
  }
}

export function* saveAsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.UPDATE_AS.REQUEST)
    if (action.meta.form === formListener) {
      yield fork(saveAsProcess, action)
    }
  }
}

export default function* sagas(form) {
  /* for the activities */
  yield fork(eventModalSagas, form)
  yield fork(refreshActivitiesListener, form)

  yield fork(taxMatrixModalSagas, form)
  yield fork(deleteContactListener, form)

  yield fork(customerLedgerSagas, form)
  yield fork(notesModalSagas, form)
  yield fork(templateSagas, form)

  /*
    these sagas, imported from the common component
    selectionCriteriaModalSagas
  */
  yield fork(selectionCriteriaModalSagas, form)
  yield fork(editableGridSagas, form, ['priceContracts', 'sources'])

  yield fork(mergeModalSagas, form)
  yield fork(openMergeModalListener, form, 'customer', 'Change Customer Number')

  // yield fork(patternMatchingListener, form)
  yield fork(unsetTemplateListener, form)
  yield fork(deleteCustomerRecordListener, form)
  yield fork(deleteShipToListener, form)
  yield fork(showShipToWarningModalListener, form)

  /* create customer stuff */
  yield fork(createCustomerListener, form)
  yield fork(createCustomerOnTabListener, form)
  yield fork(cancelPreNewModeListener, form)
  yield fork(cancelEditSuccessListener, form)

  /* "show all audits" listener */
  yield fork(additionalAuditsDataFlagListener, form)
  yield fork(refreshContactsListener, form)

  yield fork(openContactListener, form)
  yield fork(changePODollarLimitListener, form)
  yield fork(deletePODollarLimitListener, form)
  yield fork(propertyChangeTriggerListener, form)

  yield fork(openScreenFromCustomerMasterListener, form)
  yield fork(showWarningModalListener, form)
  yield fork(attachmentsSagas, form)
  yield fork(getEntityFailureListener, form)
  yield fork(saveFailureListener, form)
  yield fork(saveAsListener, form)
}
