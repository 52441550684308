import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

export const PurchaseOrderTooltipCell = props => (<GridLinkCell {...props} />)

export default TooltipField({
  anchorToGridCellText: true,
  // indexSearchType: 'salesOrder',
  keyToActionMap: {
    m: () => openScreenAction({
      name: 'purchaseOrder',
      title: 'Purchase Order',
      image: 'purchasing_purchase_order_16.png',
    }),
    1: () => openScreenAction({
      image: 'vendor_master_16.png',
      name: 'vendorMaster',
      title: 'Vendor Master'
    })
  },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'purchaseOrder'
})(PurchaseOrderTooltipCell)
