import React, { useEffect, useRef } from 'react'
import { DDITextField, DDISelectField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import StateSelectField from 'components/StateSelectField'
import { required, maxLength } from 'ddiForm/validations'
import usePrevious from 'hooks/usePrevious'
import { is } from 'utils'

import { statesAndProvinces, twoColFieldWrapperStyle } from '../../../utils'
import '../../../styles/form-styles.scss'

const RemitTo = props => {
  const textRef = useRef(null)

  const { description, isEditing, isNew } = props
  const wasEditing = usePrevious(isEditing)
  const wasNew = usePrevious(isNew)

  useEffect(() => {
    if (
      isEditing &&
      isNew &&
      !description &&
      is.bool(isNew) &&
      is.bool(wasNew) &&
      isNew !== wasNew
    ) {
      console.log('textRef', textRef)
      if (textRef?.current) {
        textRef.current.focus()
      }
    }
  }, [isNew, wasNew])

  return (
    <DDICardWrapper title="Remit To Address" contentStyle={{ padding: 10 }}>
      <div className="vendor-master-form-outer-wrapper">
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="description"
            label="Name"
            wrapperStyle={twoColFieldWrapperStyle}
            validate={[required, maxLength(30)]}
            inputRef={textRef}
          />
          <DDITextField
            propertyName="remitToAddressLine1"
            label="Address"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="remitToAddressLine2"
            label="Address Line 2"
            wrapperStyle={twoColFieldWrapperStyle}
          />
          <DDITextField
            propertyName="remitToCity"
            label="City"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
        <div
          className="vendor-master-form-row-wrapper"
          style={{ alignItems: 'baseline' }}
        >
          <StateSelectField
            propertyName="remitToState"
            margin="dense"
            style={twoColFieldWrapperStyle}
            fullWidth
          />
          <DDITextField
            propertyName="remitToZipcode"
            label="Zip Code"
            placeholder="Zip Code"
            mask="zipCode"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
      </div>
    </DDICardWrapper>
  )
}

export default RemitTo
