import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { DDITextField } from 'ddiForm/wrapped'
import { Label, Field } from 'ddiForm'
import { TextField } from '@material-ui/core'

import '../styles/customer-part-number.scss'

export const CustomerPartNumberModal = ({
  data: {
    dataId,
    customerIdDescription,
    form,
    partNumber,
    productId,
    productDescription
  }
}) => {
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef?.current) {
      textRef.current.focus()
    }
  }, [])

  return (
    <div className="customer-part-number-outer-wrapper">
      <div className="field-wrapper">
        <label className="label">Customer: </label>
        <TextField value={dataId} disabled />
      </div>
      <div className="field-wrapper">
        <label className="label">Product: </label>
        <TextField value={productId} disabled />
      </div>
      <div className="field-wrapper">
        <label className="label">Part Number: </label>
        <DDITextField
          propertyName="selectedCustomerPartNumber"
          fullWidth
          preventAutoDisable
          initialValue={partNumber}
          inputRef={textRef}
        />
      </div>
    </div>
  )
}

export default CustomerPartNumberModal
