import { call, fork, put, select, take } from 'redux-saga/effects'
import { SAVE_CONTACT } from 'pages/ContactMaster/constants'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { SAVE } from 'ddiForm/constants'
import {
  deleteContactListener,
  openContactListener
} from 'pages/CustomerMaster/sagas'
import { api } from 'services'

export const listContactsProcess = function* proc(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(masterActions.getEntityAsync.request(form))
  const { response, error } = yield call(api.readVendorMaster, {
    dataId,
    groupNames: ['contacts']
  })

  if (response) {
    yield put(
      masterActions.getEntityAsync.success(
        {
          ...response,
          contacts: response?.contacts?.contacts
        },
        form
      )
    )
  } else {
    yield put(masterActions.getEntityAsync.failure(form, error))
  }
}

export function* listContactsProcessProxy(form, payload) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (
    selectedPrimaryTab === 'contacts' &&
    payload?.parent?.parentType &&
    payload?.parent?.parentType === 'V'
  ) {
    yield fork(listContactsProcess, form)
  }
}

export function* refreshContactsListener(formListener) {
  while (true) {
    const action = yield take([SAVE_CONTACT.SUCCESS, SAVE.SUCCESS])
    const {
      meta: { form },
      payload,
      type
    } = action

    if (form && form.includes(formListener) && type === SAVE_CONTACT.SUCCESS) {
      yield fork(listContactsProcess, formListener)
    }

    if (form === 'contact') {
      yield fork(listContactsProcessProxy, formListener, payload)
    }
  }
}

export default function* contactManagementSagas(form) {
  yield fork(deleteContactListener, form)
  yield fork(openContactListener, form)
  yield fork(refreshContactsListener, form)
}
