/* eslint react/no-unused-state: 0, react/no-did-mount-set-state: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DDISelectField } from 'ddiForm/wrapped'
import PropTypes from 'prop-types'
import { getIn, toCamelCase } from 'utils'
import { difference, find } from 'lodash'
import FormGroup from '@material-ui/core/FormGroup'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import { Responsive, WidthProvider } from 'react-grid-layout'
import AdditionalCriteriaGrid from './components/AdditionalCriteriaGrid'

import SelectionCheckbox from './components/SelectionCheckbox'
import SelectionCard from './components/SelectionCard'

import 'react-grid-layout/css/styles.css'
import memoize from 'memoize-one'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const draggableCancel =
  '.ddi-expansion-panel-summary-content, .ag-row, .ag-theme-balham'
const getActiveDataIds = data =>
  data.reduce((acc, next) => {
    if (next.enabled) {
      acc = acc.concat(next.dataId)
    }
    return acc
  }, [])

const getCurrentBreakpoint = width => {
  let breakpoint = 'lg'
  if (width === 0) {
    breakpoint = 'xxs'
  } else if (width <= 480) {
    breakpoint = 'xs'
  } else if (width <= 768) {
    breakpoint = 'sm'
  } else if (width <= 996) {
    breakpoint = 'md'
  }

  return breakpoint
}

const getX = (index, cols) => (index * 2) % (cols || 12)

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps', ownProps)
  const {
    data: { form, type }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}.values.selectionCriteria`)
  const fields = getIn(state, `ddiForm.${form}.fields.selectionCriteria`)

  return {
    additionalCriteria: getIn(fields, `grids.${type}.additionalCriteria`)
      ? getIn(fields, `grids.${type}.additionalCriteria`).toJS()
      : [],
    dictionaries: getIn(formState, 'dictionaries')
      ? getIn(formState, 'dictionaries').toJS()
      : [],
    equalToCriterias: getIn(formState, 'equalToCriterias')
      ? getIn(formState, 'equalToCriterias').toJS()
      : [],
    form,
    sortDirectionOptions: getIn(formState, 'sortDirectionOptions')
      ? getIn(formState, 'sortDirectionOptions').toJS()
      : []
  }
}

class SelectionCriteriaModal extends Component {
  static propTypes = {
    additionalCriteria: PropTypes.array.isRequired,
    cols: PropTypes.object,
    data: PropTypes.object.isRequired,
    dictionaries: PropTypes.array.isRequired,
    equalToCriterias: PropTypes.array.isRequired,
    form: PropTypes.string.isRequired,
    sortDirectionOptions: PropTypes.array.isRequired
  }

  static defaultProps = {
    cols: { lg: 8, md: 8, sm: 6, xs: 4, xxs: 2 }
  }

  constructor(props) {
    super(props)
    const criteriaData = props.equalToCriterias

    const activeIds = getActiveDataIds(criteriaData)
    const rglWidth =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) *
        0.9 -
      40
    const breakpoint = getCurrentBreakpoint(rglWidth)

    const layouts = activeIds
      ? activeIds.reduce(
        (acc, next, idx) => {
          const data = {
            i: next,
            // x: (idx * 2) % (props.cols[breakpoint] || 12),
            x: getX(idx, props.cols[breakpoint]),
            y: Infinity,
            w: 2,
            h: 2
          }

          acc.lg = acc.lg.concat(data)
          acc.md = acc.md.concat(data)
          acc.sm = acc.sm.concat(data)
          acc.xs = acc.xs.concat(data)
          acc.xxs = acc.xxs.concat(data)

          return acc
        },
        { lg: [], md: [], sm: [], xs: [], xxs: [] }
      )
      : []

    this.state = {
      activeIds,
      criteriaData,
      layouts
    }

    // this.createGrid = memoize(this.createGrid)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const activeIds = getActiveDataIds(nextProps.equalToCriterias)

    /* we've added an item */
    if (activeIds.length > prevState.activeIds.length) {
      const addedItemId = difference(activeIds, prevState.activeIds)[0]
      return {
        activeIds,
        layouts: Object.keys(prevState.layouts).reduce((acc, next, idx) => {
          acc[next] = [
            ...prevState.layouts[next],
            {
              i: addedItemId,
              // x: (prevState.layouts[next].length * 2) % (nextProps.cols[prevState.breakpoint] || 12),
              x: getX(
                prevState.layouts[next].length,
                nextProps.cols[prevState.breakpoint]
              ),
              y: Infinity,
              w: 2,
              h: 2
            }
          ]
          return acc
        }, {})
      }
    }

    /* removing an item */
    if (activeIds.length < prevState.activeIds.length) {
      const removedItemId = difference(prevState.activeIds, activeIds)[0]
      return {
        activeIds,
        layouts: Object.keys(prevState.layouts).reduce((acc, next) => {
          acc[next] = prevState.layouts[next].reduce(
            (a, n, idx, arr) => {
              if (n.i !== removedItemId) {
                a.data = a.data.concat({
                  ...n,
                  // x: (a.index * 2) % (nextProps.cols[prevState.breakpoint] || 12)
                  x: getX(a.index, nextProps.cols[prevState.breakpoint])
                })
                a.index += 1
              }
              return a
            },
            { data: [], index: 0 }
          ).data
          return acc
        }, {})
      }
    }

    return null
  }

  componentDidMount() {
    if (this.rgl) {
      const {width} = this.rgl.state
      this.setState({
        breakpoint: getCurrentBreakpoint(width)
      })
    }
  }

  onBreakpointChange = (breakpoint, cols) => this.setState({ breakpoint })

  onLayoutChange = (layout, layouts) =>
    this.setState(
      { layouts }
      // () => localStorage.setItem('selectionGridLayouts', JSON.stringify(layouts))
    )

  createGrid = memoize(id => {
    const {
      data: { type },
      equalToCriterias,
      form
    } = this.props
    // const { layouts } = this.state
    const cardData = find(equalToCriterias, { dataId: id })

    if (cardData) {
      return (
        <div key={id}>
          <SelectionCard cardData={cardData} id={id} form={form} type={type} />
        </div>
      )
    }

    return null
  })

  getAdditionalCriteriaGridHeight = () => {
    const { additionalCriteria } = this.props
    const height = additionalCriteria.length * 28 + 32 + 50
    return height
  }

  render() {
    const { activeIds, layouts } = this.state
    const {
      // additionalCriteria,
      cols,
      // criteriaSelection,
      dictionaries,
      equalToCriterias,
      form,
      sortDirectionOptions,
      data: { type }
    } = this.props
    return (
      <div>
        <DDIExpansionPanel
          defaultExpanded
          expansionPanelContentStyle={{ padding: '10px 15px', width: '100%' }}
          expansionPanelDetailsStyle={{
            padding: 0,
            width: '100%'
          }}
          title="Criteria Options"
        >
          <FormGroup row>
            {equalToCriterias.map((item, idx) => (
              <SelectionCheckbox
                dataId={item.dataId}
                key={toCamelCase(item.description)}
                form={form}
              />
            ))}
          </FormGroup>
        </DDIExpansionPanel>
        <DDIExpansionPanel
          defaultExpanded={false}
          expansionPanelContentStyle={{ padding: '0 0 24px 0', width: '100%' }}
          expansionPanelDetailsStyle={{
            padding: 0,
            width: '100%'
          }}
          title="Additional Criteria"
          style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
        >
          <AdditionalCriteriaGrid
            type={type}
            form={form}
            dictionaries={dictionaries}
          />
        </DDIExpansionPanel>
        <div style={{ minHeight: 270 }}>
          <ResponsiveReactGridLayout
            className="layout"
            cols={cols}
            draggableCancel={draggableCancel}
            onBreakpointChange={this.onBreakpointChange}
            onDragStop={this.onDragStop}
            onLayoutChange={this.onLayoutChange}
            // layout={layout}
            layouts={layouts}
            // width="100%"
            isResizable={false}
            rowHeight={120}
            ref={el => (this.rgl = el)}
          >
            {activeIds.map((id, idx) => this.createGrid(id))}
          </ResponsiveReactGridLayout>
        </div>
        <div
          style={{
            alignItems: 'center',
            background: '#f7f7f7',
            borderRadius: 4,
            display: 'inline-flex',
            margin: '10px 10px 0 10px',
            padding: 10,
            width: 'calc(100% - 20px)'
          }}
        >
          <label style={{ fontWeight: 400, marginBottom: 0, marginRight: 10 }}>
            Sort By:
          </label>
          <div style={{ width: 150, marginRight: 15 }}>
            <DDISelectField
              preventAutoDisable
              propertyName={`selectionCriteria.${type}.inputs.sortByField`}
              fullWidth
              values={dictionaries}
              margin="dense"
              selectValue="dataId"
              selectDescription="description"
            />
          </div>
          <div style={{ width: 150, marginRight: 15 }}>
            <DDISelectField
              preventAutoDisable
              propertyName={`selectionCriteria.${type}.inputs.sortDirectionType`}
              fullWidth
              values={sortDirectionOptions}
              margin="dense"
              selectValue="dataId"
              selectDescription="description"
            />
          </div>
        </div>
      </div>
    )
  }
}

/* have to use connect here so we have access to dispatch in the saga for button click events */
// export default withContext({
//   additionalCriteria: form => form.values.selectionCriteria.additionalCriteria,
//   dictionaries: form => form.values.selectionCriteria.dictionaries,
//   equalToCriterias: form => form.values.selectionCriteria.equalToCriterias,
//   sortDirectionOptions: form => form.values.selectionCriteria.sortDirectionOptions
// })(SelectionCriteriaModal)

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(SelectionCriteriaModal)
