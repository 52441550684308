import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import { createApiOptions } from 'utils'
import {
  PURCHASE_ORDER_EXACT_MATCH_URL,
  PURCHASE_ORDER_INQUIRY_URL,
  GET_PURCHASE_ORDER_TOOLTIP_URL
} from './constants'

export const purchaseOrderExactMatch = ({ keyword }) => {
  const options = createApiOptions({
    body: { description: keyword },
    method: 'post'
  })
  // console.log(options)
  return callFetch(PURCHASE_ORDER_EXACT_MATCH_URL, options)
}

export const purchaseOrderInquiry = args => {
  const options = createApiOptions({
    method: 'post',
    body: { ...args }
  })
  return callFetch(PURCHASE_ORDER_INQUIRY_URL, options)
}

export const getPurchaseOrderTooltip = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      recordName: dataId
    }
  })
  return callFetch(GET_PURCHASE_ORDER_TOOLTIP_URL, options)
}

export const printSelectedPurchaseOrders = ({ dataIds = [], status }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataIds,
      status
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/purchaseorderinquiry/print`, options)
}
