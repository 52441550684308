/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { layoutFlex, getIn, getValue, formatNumber, is } from 'utils'
import { DDIToggle, DDITextField, DDIButton } from 'ddiForm/wrapped'
import { Button } from '@material-ui/core'
import {
  billForBackorder,
  createInvoice,
  deleteInvoice,
  handleShipperHQInteraction
} from 'pages/SalesOrder/actions'

import CheckoutTabs from './components/CheckoutTabs'
import PrintOptions from './components/PrintOptions'
import LinkedOrders from './components/LinkedOrders'
import './styles/invoicing.scss'

export const Invoicing = ({
  canBillBackOrders,
  canChangeBatch,
  canCreateInvoice,
  canDeleteInvoice,
  canSuspend,
  dispatch,
  form,
  height,
  isSuspended,
  minimumDeposit,
  paid,
  pos,
  suspendReason,
  openTotal,
  isEditing,
  showAllowanceLabel,
  allowanceAmount,
  netOrderTotal
}) => {
  const handleCreateInvoice = () => dispatch(createInvoice.try(form))

  const handleDeleteInvoice = () => dispatch(deleteInvoice.try(form))

  const handleBillBackOrders = () => dispatch(billForBackorder.try(form))

  const getShipperHQData = debounce(
    e =>
      dispatch(
        handleShipperHQInteraction.try(form, {
          apiParams: {
            action: 'initialize'
          }
        })
      ),
    300
  )

  return (
    <div className="invoicing-tab-outer-wrapper">
      <div
        id="invoicing-tab"
        style={layoutFlex('120rem', 'row', 'wrap', 'center')}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            paddingBottom: 10,
            paddingLeft: 20,
            width: '100%'
          }}
        >
          {pos && is.number(minimumDeposit) && (
            <div className="flex-wrapper">
              <p className="minimum-deposit">
                Minimum Deposit:{' '}
                <span style={{ color: '#d9534f' }}>
                  {formatNumber(minimumDeposit)}
                </span>
              </p>
            </div>
          )}
          <div className="flex-wrapper">
            <p className="order-total">
              Order Total: {formatNumber(openTotal)}
            </p>
          </div>
          {showAllowanceLabel ? (
            <>
              <div className="flex-wrapper">
                <p className="allowance-label">
                  Allowance:{' '}
                  <span style={{ color: '#517b9c' }}>
                    {formatNumber(allowanceAmount)}
                  </span>
                </p>
              </div>
              <div className="flex-wrapper">
                <p className="allowance-label">
                  Net Due:{' '}
                  <span style={{ color: '#517b9c' }}>
                    {formatNumber(netOrderTotal)}
                  </span>
                </p>
              </div>
            </>
          ) : null}
          {canSuspend && (
            <div title={suspendReason}>
              <DDIToggle
                label="Suspend"
                propertyName="isSuspended"
                labelStyle={{
                  marginBottom: 0
                }}
                className="toggle-on-error-color"
                // disabled={paid > 0}
                disabled={!canSuspend}
              />
            </div>
          )}

          <div className="batch-wrapper">
            <label className="batch-label">Batch:</label>
            <DDITextField
              propertyName="batch"
              // label="Batch"
              preventAutoDisable
              disabled={!canChangeBatch}
            />
          </div>

          <div className="invoice-wrapper">
            {!isEditing ? (
              <div className="flex-wrapper">
                <DDIButton
                  label="Quote Freight / Handling"
                  onClick={getShipperHQData}
                  preventAutoDisable
                  variant="contained"
                />
              </div>
            ) : null}

            {canCreateInvoice && (
              <div className="flex-wrapper">
                <DDIButton
                  label="Create Invoice"
                  onClick={handleCreateInvoice}
                  preventAutoDisable
                  variant="contained"
                />
              </div>
            )}

            {canDeleteInvoice && (
              <div className="flex-wrapper">
                <DDIButton
                  label="Delete Invoice"
                  onClick={handleDeleteInvoice}
                  preventAutoDisable
                  variant="contained"
                />
              </div>
            )}

            {canBillBackOrders && (
              <div className="flex-wrapper">
                <DDIButton
                  label="Bill for Backorder"
                  onClick={handleBillBackOrders}
                  preventAutoDisable
                  variant="contained"
                />
              </div>
            )}
          </div>
        </div>
        <div className="sales-order-checkout-main-wrapper">
          <CheckoutTabs form={form} dispatch={dispatch} height={height} />
          <PrintOptions form={form} dispatch={dispatch} height={height} />
        </div>

        <div className="linked-orders">
          <LinkedOrders form={form} dispatch={dispatch} />
        </div>
      </div>
    </div>
  )
}

/* should be OK as is -- SVE 9/24/2020 */
const contextObj = {
  canBillBackOrders: form => getValue(form, 'canBillBackOrders', false),
  canChangeBatch: form => getValue(form, 'canChangeBatch', false),
  canCreateInvoice: form => getValue(form, 'canCreateInvoice', false),
  canDeleteInvoice: form => getValue(form, 'canDeleteInvoice', false),
  canSuspend: form => getValue(form, 'canSuspend', false),
  isEditing: form => form.get('isEditing') || false,
  isSuspended: form => getValue(form, 'isSuspended', false),
  openTotal: form => getValue(form, 'openTotal', 0),
  paid: form => getValue(form, 'paid', 0),
  pos: form => getValue(form, 'pos', false),
  suspendReason: form => getValue(form, 'suspendReason'),
  minimumDeposit: form => getValue(form, 'minimumDeposit', null),
  showAllowanceLabel: form => getIn(form, 'values.showAllowanceLabel') || false,
  allowanceAmount: form => getIn(form, 'values.allowanceAmount') || 0,
  netOrderTotal: form => getIn(form, 'values.netOrderTotal') || 0
}

export default withContext(contextObj)(Invoicing)
