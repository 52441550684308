import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from 'pages/SalesOrder/utils'
import Measure from 'react-measure'
import { Typography } from '@material-ui/core'
import Split from 'react-split'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { isEqual } from 'lodash'
import InfoPanel from './InfoPanel'
import GridPanel from './GridPanel'
// check this
export const LineItemsSection = props => {
  const {
    columnDefs,
    commentChecked,
    contentHeight,
    copyProducts,
    customerId,
    dataId,
    defaultColDef,
    detailCellRendererParams,
    dispatch,
    emptyRow,
    expanded,
    form,
    getContextMenuItems,
    getRowHeight,
    getRowNodeId,
    getToggleNavIcons,
    handleAccordionToggle,
    // hasDocumentSpec,
    // hasSDS,
    height,
    image,
    isEditing,
    isRowMaster,
    layoutState,
    lineItemsSidebarDimensions,
    navigateToNextCell,
    onCellDoubleClicked,
    onCellFocused,
    onCellValueChanged,
    onGridReady,
    onSelectionChanged,
    onRowSelected,
    onDragEnd,
    onResizeInterface,
    onResizeLineItemDetailGrid,
    onRowDataUpdated,
    onToggleInterface,
    openFastProductModal,
    // optionsAccessories,
    popupEditorOpen,
    rightColHeight,
    setLayoutState,
    // tab,
    tabToNextCell,
    uiCompacted,
    gridApi,
    groupRowInnerRendererFramework,
    defaultGroupSortComparator,
    popupParent,
    gridLayoutKey,
    userId,
    onColumnVisibleCb,
    onDragStoppedCb,
    isAddingBulkLineItems,
    applyColumnDefOrder = true,
    onRowGroupOpened,
    onDisplayedColumnsChanged
  } = props

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleAccordionToggle('detail')}
      defaultExpanded
      square
      forwardRef
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography style={{ color: '#444', fontSize: '0.85em' }}>
          Line Items
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography component="div" style={{ width: '100%' }}>
          <Measure bounds onResize={onResizeInterface}>
            {({ measureRef }) => (
              <div
                ref={measureRef}
                style={{
                  height: contentHeight,
                  position: 'relative',
                  width: '100%'
                }}
              >
                {uiCompacted
                  ? getToggleNavIcons(uiCompacted, popupEditorOpen)
                  : null}
                <Split
                  className="split-flex split-flex-horizontal"
                  sizes={layoutState.main || [75, 25]}
                  minSize={0}
                  onDragEnd={sizes => onDragEnd(sizes, 'main')}
                >
                  <GridPanel
                    columnDefs={columnDefs}
                    contentHeight={contentHeight}
                    copyProducts={copyProducts}
                    dataId={dataId}
                    defaultColDef={defaultColDef}
                    detailCellRendererParams={detailCellRendererParams}
                    dispatch={dispatch}
                    emptyRow={emptyRow}
                    form={form}
                    getContextMenuItems={getContextMenuItems}
                    getRowHeight={getRowHeight}
                    getRowNodeId={getRowNodeId}
                    getToggleNavIcons={getToggleNavIcons}
                    height={height}
                    isEditing={isEditing}
                    isRowMaster={isRowMaster}
                    navigateToNextCell={navigateToNextCell}
                    onCellDoubleClicked={onCellDoubleClicked}
                    onCellFocused={onCellFocused}
                    onCellValueChanged={onCellValueChanged}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    openFastProductModal={openFastProductModal}
                    popupEditorOpen={popupEditorOpen}
                    tabToNextCell={tabToNextCell}
                    uiCompacted={uiCompacted}
                    onRowDataUpdated={onRowDataUpdated}
                    groupUseEntireRow
                    groupRowInnerRendererFramework={
                      groupRowInnerRendererFramework
                    }
                    onRowSelected={onRowSelected}
                    defaultGroupSortComparator={defaultGroupSortComparator}
                    commentChecked={commentChecked}
                    popupParent={popupParent}
                    gridLayoutKey={gridLayoutKey}
                    onColumnVisibleCb={onColumnVisibleCb}
                    onDragStoppedCb={onDragStoppedCb}
                    isAddingBulkLineItems={isAddingBulkLineItems}
                    applyColumnDefOrder={applyColumnDefOrder}
                    onRowGroupOpened={onRowGroupOpened}
                    onDisplayedColumnsChanged={onDisplayedColumnsChanged}
                  />
                  <InfoPanel
                    dispatch={dispatch}
                    form={form}
                    height={height}
                    layoutState={layoutState}
                    lineItemsSidebarDimensions={lineItemsSidebarDimensions}
                    onDragEnd={onDragEnd}
                    onResizeLineItemDetailGrid={onResizeLineItemDetailGrid}
                    onToggleInterface={onToggleInterface}
                    rightColHeight={rightColHeight}
                    setLayoutState={setLayoutState}
                    gridApi={gridApi}
                    userId={userId}
                    // tab={tab}
                  />
                </Split>
              </div>
            )}
          </Measure>
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default React.memo(LineItemsSection, isEqual)
