import React from 'react'
import PropTypes from 'prop-types'

export const ImageCellHeader = ({ src, title}) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      width: '100%'
    }}
  >
    <img
      src={src}
      alt="tooltip"
      style={{ height: 20 }}
      title={title}
    />
  </div>
)

ImageCellHeader.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}


export default ImageCellHeader