import { take, call, fork, put, select } from 'redux-saga/effects'
import { api } from 'services'
import * as CONSTANTS from 'pages/ProductMaster/constants'
import * as actions from 'pages/ProductMaster/actions'
import { getIn } from 'utils'
import {
  DELETE_GRID_ROW,
  ON_PRIMARY_GRID_DATA_VALIDATED,
  SET_FOCUSED_CELL
} from 'components/EditableGrid/constants'
import { ADD_BLANK_ROW } from 'ddiForm/constants'
import { getFormSelector } from 'ddiForm/utils'

export function* addGroupSearchSuccessListener(formListener) {
  while (true) {
    const action = yield take(ON_PRIMARY_GRID_DATA_VALIDATED)
    const {
      meta: { form },
      payload
    } = action

    if (payload.propertyName === 'accessoryGroups') {
      yield fork(addGroupSearchProcess, form, payload)
    }
  }
}

export function* addGroupSearchProcess(form, payload) {
  const {
    newData: { dataId },
    propertyName,
    rowIndex
  } = payload

  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.dataId.value')

  const { response, error } = yield call(api.getGroups, {
    dataId: productId,
    groupId: dataId
  })

  if (response) {
    // if there is response, add group to accessoryGroups
    const params = {
      data: {
        ...response
      },
      propertyName,
      rowIndex
    }
    yield put({
      type: CONSTANTS.UPDATE_ROW_DATA,
      payload: params,
      meta: { form }
    })

    // set selected group

    yield put({
      type: CONSTANTS.SET_SELECTED_GROUP,
      payload: { dataId: response.dataId, accessories: response.accessories },
      meta: { form }
    })
  }
}

// export function* addNewGroupListener(formListener) {
//   while (true) {
//     const action = yield take(CONSTANTS.ADD_NEW_GROUP)
//     const {
//       meta: { form }
//     } = action

//     if (form === formListener) {
//       yield fork(addNewGroupProcess, form)
//     }
//   }
// }

// export function* addNewGroupProcess(form) {
//   // open group master modal
//   // ** for future **
//   const modalOpts = {
//     component: NotesModal,
//     options: {
//       data: {
//         actions: [
//           { primary: true, title: 'Exit' }
//         ],
//         note: 'Test',
//         notesModalEnabled: true
//       },
//       title: 'Test'
//     }
//   }

//   const modal = yield call(addModal, form, modalOpts)
//   yield put(modal)
// }

export function* addGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(ADD_BLANK_ROW)

    if (form === formListener && propertyName === 'accessoryGroups') {
      yield put(actions.setSelectedGroup(form, []))
    }
  }
}

export function* deleteGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex }
    } = yield take(DELETE_GRID_ROW)

    if (form === formListener && propertyName === 'accessoryGroups') {
      const formState = yield select(getFormSelector(form))
      let accessoryGroups = getIn(formState, 'fields.accessoryGroups.rowData')
      accessoryGroups =
        accessoryGroups && accessoryGroups.toJS ? accessoryGroups.toJS() : []

      if (accessoryGroups.length) {
        // console.log(accessoryGroups)
        const group = accessoryGroups.length === 1 ? 0 : rowIndex

        if (
          accessoryGroups[group] &&
          accessoryGroups[group].dataId &&
          accessoryGroups[group].accessories
        ) {
          yield put({
            type: CONSTANTS.SET_SELECTED_GROUP,
            payload: {
              dataId: accessoryGroups[group].dataId,
              accessories: accessoryGroups[group].accessories
            },
            meta: { form }
          })
        }
      } else {
        yield put(actions.setSelectedGroup(form, []))
      }
    }
  }
}

export default function* sagas(form) {
  yield fork(addGroupSearchSuccessListener, form)
  yield fork(addGridRowListener, form)
  yield fork(deleteGridRowListener, form)
}
