import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext, GridField } from 'ddiForm'
import {
  dateFilterComparator,
  formatDateFields,
  plainDeepEqual,
  getField,
  emptyList
} from 'utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { headerStyle, setGridHeight } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

import master from './styles/master-style.scss'
import './styles/gridStyle.css'

export const getRowNodeId = data => {
  const { workOrder, kitProduct, productDescription } = data
  return `${workOrder} - ${kitProduct} - ${productDescription}`
}

const dynamicColumnDefs = memoize(({ type }) => {
  const colDef = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      field: 'workOrder',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Work Order',
      linkTo: type
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'enteredDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label text-center',
      headerName: 'Date',
      valueFormatter: formatDateFields
    },
    {
      field: 'branch.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Branch',
      tooltipField: 'branch.tooltip'
    },
    {
      field: 'warehouse.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Wh',
      tooltipField: 'warehouse.tooltip'
    },
    {
      field: 'writer.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Wr',
      tooltipField: 'writer.tooltip'
    },
    {
      cellStyle: ({ value }) =>
        value < 0
          ? { color: 'red', textAlign: 'right' }
          : { textAlign: 'right' },
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      valueGetter: params => params.data.quantity,
      headerClass: 'numeric-value-label',
      headerName: 'Qty'
    },
    {
      field: 'uom',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'UOM'
    },
    {
      field: 'kitProduct',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Kit Product'
    },
    {
      field: 'productDescription',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Description'
    },
    {
      cellStyle: { color: '#c140f7' },
      field: 'status.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Status',
      tooltipField: 'status.tooltip'
    }
  ]
  return colDef
})

export class WorkOrders extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    workOrders: PropTypes.array.isRequired
  }

  componentDidUpdate(prevProps) {
    if (
      this.gridApi &&
      !plainDeepEqual(this.props.workOrders, prevProps.workOrders)
    ) {
      this.gridApi.setFilterModel(null)
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  render() {
    const { height, workOrders } = this.props
    return (
      <div className="product-analysis-layout-flex-column">
        <div style={{ flex: '1 1', overflow: 'hidden' }}>
          <div style={{ height: height * 0.95 }}>
            <GridField
              propertyName="workOrders"
              title="Work Orders"
              getRowNodeId={getRowNodeId}
              headerStyle={headerStyle}
              columnDefs={dynamicColumnDefs({
                type: 'workOrder'
              })}
              gridHeight={setGridHeight(workOrders)}
              onGridReady={this.onGridReady}
              // rowData={workOrders}
              rowSelection="single"
              enableSorting
              width="100%"
              fullWidth
            />
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  workOrders: form => getField(form, 'workOrders', emptyList, true)
  // workOrders: form => {
  //   if (form.fields && form.fields.workOrders && form.fields.workOrders.rowData) {
  //     return form.fields.workOrders.rowData
  //   }

  //   if (form.values && form.values.workOrders) {
  //     return form.values.workOrders
  //   }

  //   return []
  // }
  // workOrders: form => form.values.workOrders ? form.values.workOrders : []
}

const workOrdersWithContext = withContext(contextObj)(WorkOrders)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  workOrdersWithContext
)
