/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { debounce } from 'lodash'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { connect } from 'react-redux'
import DDICardWrapper from 'components/DDICardWrapper'
import { getIn } from 'utils'
import '../styles/css-mod-ignore.scss'
import {
  cancelReport,
  resetFieldSelection,
  setReportCriteria,
  updatePrintOrder
} from 'components/SelectionCriteriaModal/actions'
import DraggableField from './components/DraggableField'

// function immutableSplice(arr, start, deleteCount, ...items) {
//   return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)]
// }

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const fieldSelection = getIn(
    state,
    `ddiForm.${form}.values.selectionCriteria.fieldSelection`
  )

  return {
    fields: getIn(fieldSelection, 'fields')
      ? getIn(fieldSelection, 'fields').toJS()
      : [],
    printOrder: getIn(fieldSelection, 'printOrder')
      ? getIn(fieldSelection, 'printOrder').toJS()
      : []
  }
}

const mapDispatchToProps = dispatch => ({
  cancelReport: form => dispatch(cancelReport(form)),
  resetFieldSelection: form => dispatch(resetFieldSelection(form)),
  setReportCriteria: (form, { parentModalCb }) =>
    dispatch(setReportCriteria(form, { parentModalCb })),
  updatePrintOrder: (form, { printOrder }) =>
    dispatch(updatePrintOrder(form, { printOrder }))
})

class FieldSelectionModal extends Component {
  static propTypes = {
    cancelReport: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    fields: PropTypes.array.isRequired,
    printOrder: PropTypes.array.isRequired,
    resetFieldSelection: PropTypes.func.isRequired,
    setReportCriteria: PropTypes.func.isRequired,
    updatePrintOrder: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    const initiallySelectedPrintOrderIds = props.printOrder.reduce(
      (acc, next) => {
        acc = acc.concat(next.dataId)
        return acc
      },
      []
    )

    this.state = {
      fields: props.fields.filter(
        x => !initiallySelectedPrintOrderIds.includes(x.dataId)
      ),
      printOrder: props.printOrder,
      draggingTaskId: null,
      selectedDraggableItems: []
    }
  }

  savePrintOrderToStore = (printOrder = []) => {
    const {
      data: { form }
    } = this.props

    this.props.updatePrintOrder(form, {
      printOrder
    })
  }

  onDragStart = start => {
    const { draggableId } = start

    this.setState({
      draggingTaskId: draggableId
    })
  }

  onDragEnd = result => {
    const { selectedDraggableItems } = this.state
    const { destination, draggableId, source, type } = result
    const isSingleDrag = selectedDraggableItems.length <= 1
    // debugger

    if (!destination || result.reason === 'CANCEL') {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (destination.droppableId === 'printOrder') {
      if (isSingleDrag) {
        this.setState(
          prevState => {
            const sourceItems =
              source.droppableId === 'fields'
                ? prevState.fields.slice()
                : prevState.printOrder.slice()

            const targetItems =
              source.droppableId !== destination.droppableId
                ? prevState.printOrder.slice()
                : sourceItems

            const [deletedItem] = sourceItems.splice(source.index, 1)
            targetItems.splice(destination.index, 0, deletedItem)

            return {
              printOrder: targetItems,
              fields:
                source.droppableId === 'fields'
                  ? prevState.fields.filter(x => x.dataId !== draggableId)
                  : prevState.fields,
              selectedDraggableItems: []
            }
          },
          () => {
            this.savePrintOrderToStore(this.state.printOrder)
          }
        )
      } else {
        this.setState(
          prevState => {
            const { fields, printOrder } = prevState

            if (source.droppableId === destination.droppableId) {
              const updatedPrintOrder = printOrder.reduce((acc, next) => {
                if (
                  selectedDraggableItems.includes(next.dataId) ||
                  draggableId === next.dataId
                ) {
                  acc = acc.concat(next)
                }
                return acc
              }, [])
              // debugger

              const restSelectedPrintOrder = printOrder.reduce(
                (acc, next, idx) => {
                  if (
                    !selectedDraggableItems.includes(next.dataId) &&
                    draggableId !== next.dataId &&
                    idx >= destination.index
                  ) {
                    acc = acc.concat(next)
                  }

                  return acc
                },
                []
              )

              return {
                printOrder:
                  destination.index === 0
                    ? [...updatedPrintOrder, ...restSelectedPrintOrder]
                    : [
                        ...printOrder.slice(0, destination.index),
                        ...updatedPrintOrder,
                        ...restSelectedPrintOrder
                      ],
                selectedDraggableItems: []
              }
            }

            if (source.droppableId === 'fields') {
              // debugger
              const selectedFields = fields.reduce((acc, next) => {
                if (
                  next.dataId === draggableId ||
                  selectedDraggableItems.includes(next.dataId)
                ) {
                  acc = acc.concat(next)
                }
                return acc
              }, [])
              // debugger

              return {
                printOrder:
                  destination.index === 0
                    ? [...selectedFields, ...printOrder]
                    : [
                        ...printOrder.slice(0, destination.index),
                        ...selectedFields,
                        ...printOrder.slice(
                          destination.index,
                          printOrder.length
                        )
                      ],
                fields: fields.reduce((acc, next) => {
                  if (
                    next.dataId !== draggableId &&
                    !selectedDraggableItems.includes(next.dataId)
                  ) {
                    acc = acc.concat(next)
                  }
                  return acc
                }, []),
                selectedDraggableItems: []
              }
            }

            return null
          },
          () => {
            this.savePrintOrderToStore(this.state.printOrder)
          }
        )
      }
    } else if (
      destination.droppableId === 'fields' &&
      source.droppableId === 'printOrder'
    ) {
      // debugger
      this.setState(
        prevState => {
          const { printOrder, fields } = prevState
          const updatedPrintOrder = printOrder.reduce((acc, next) => {
            if (
              !selectedDraggableItems.includes(next.dataId) &&
              draggableId !== next.dataId
            ) {
              acc = acc.concat(next)
            }
            return acc
          }, [])

          const updatedFields = [
            ...fields,
            ...printOrder.reduce((acc, next) => {
              if (
                selectedDraggableItems.includes(next.dataId) ||
                draggableId === next.dataId
              ) {
                acc = acc.concat(next)
              }

              return acc
            }, [])
          ]
          // debugger

          return {
            printOrder: updatedPrintOrder,
            fields: updatedFields.sort((a, b) => {
              if (a.description > b.description) {
                return 1
              }

              if (a.description < b.description) {
                return -1
              }

              return 0
            }),
            selectedDraggableItems: []
          }
        },
        () => {
          this.savePrintOrderToStore(this.state.printOrder)
        }
      )
    }
  }

  unselectAll = () => {
    this.setState({
      selectedDraggableItems: []
    })
  }

  toggleSelection = dataId => {
    this.setState(prevState => {
      const { selectedDraggableItems } = prevState
      const wasSelected = selectedDraggableItems.includes(dataId)

      if (!wasSelected || selectedDraggableItems.length > 1) {
        return {
          selectedDraggableItems: [dataId]
        }
      }

      return {
        selectedDraggableItems: []
      }
    })
  }

  toggleSelectionInGroup = (dataId, type) => {
    this.setState(prevState => {
      const { selectedDraggableItems } = prevState
      let isSameGroup = true

      for (let i = 0; i < selectedDraggableItems.length; i++) {
        const id = selectedDraggableItems[i]
        if (!prevState[type].find(x => x.dataId === id)) {
          isSameGroup = false
          break
        }
      }

      if (!selectedDraggableItems.includes(dataId) && isSameGroup) {
        return {
          selectedDraggableItems: [...selectedDraggableItems, dataId]
        }
      }

      return {
        selectedDraggableItems: selectedDraggableItems.filter(x => x !== dataId)
      }
    })
  }

  deletePrintOrderItem = debounce(dataId => {
    this.setState(
      prevState => {
        const { printOrder, fields } = prevState
        const deletedItem = printOrder.find(x => x.dataId === dataId)

        if (!deletedItem) {
          return null
        }

        const updatedFields = [...fields, deletedItem]
        // debugger

        return {
          printOrder: printOrder.filter(x => x.dataId !== dataId),
          fields: updatedFields.sort((a, b) => {
            if (a.description > b.description) {
              return 1
            }

            if (a.description < b.description) {
              return -1
            }

            return 0
          }),
          selectedDraggableItems: []
        }
      },
      () => {
        this.savePrintOrderToStore(this.state.printOrder)
      }
    )
  }, 300)

  resetInterface = debounce(() => {
    this.setState(
      prevState => {
        const { printOrder, fields } = prevState

        const updatedFields = [...fields, ...printOrder]
        // debugger

        return {
          printOrder: [],
          fields: updatedFields.sort((a, b) => {
            if (a.description > b.description) {
              return 1
            }

            if (a.description < b.description) {
              return -1
            }

            return 0
          }),
          selectedDraggableItems: []
        }
      },
      () => {
        this.savePrintOrderToStore(this.state.printOrder)
      }
    )
  }, 300)

  getDropZoneStyle = memoize(isDraggingOver => ({
    height: 600,
    overflow: 'auto',
    background: isDraggingOver ? '#c1d5e5' : '#fff',
    padding: 10
  }))

  render() {
    const {
      draggingTaskId,
      fields,
      printOrder,
      selectedDraggableItems
    } = this.state

    const maxPrintLength = printOrder.reduce((acc, next) => {
      acc += next.masterFileReportLength
      return acc
    }, 0)

    const ddiCardStyle = {
      minWidth: 320,
      width: 'auto',
      float: 'none',
      clear: 'none'
    }

    return (
      <div style={{ minHeight: 600 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
            fontSize: '0.9em',
            padding: '0 35px'
          }}
        >
          <span>Drag &amp; drop fields to add to print order</span>
          <span>Max Printable Report Length: 132</span>
        </div>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            <DDICardWrapper
              title="Available Fields"
              contentStyle={{ padding: 0 }}
              style={ddiCardStyle}
            >
              <Droppable droppableId="fields">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    style={this.getDropZoneStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <ul className="draggable-field-selection-list plain">
                      {fields.map((field, i) => (
                        <DraggableField
                          field={field}
                          index={i}
                          draggingTaskId={draggingTaskId}
                          toggleSelection={this.toggleSelection}
                          toggleSelectionInGroup={this.toggleSelectionInGroup}
                          selectedDraggableItems={selectedDraggableItems}
                          type="fields"
                        />
                      ))}
                    </ul>
                  </div>
                )}
              </Droppable>
            </DDICardWrapper>
            <DDICardWrapper
              title="Print Order (Length)"
              secondaryTitle={`Total Print Length: ${maxPrintLength}`}
              secondaryTitleStyle={{
                fontWeight: 500,
                float: 'right',
                marginRight: 10
              }}
              contentStyle={{ padding: 0 }}
              style={ddiCardStyle}
            >
              <Droppable droppableId="printOrder">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    style={this.getDropZoneStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <ul className="draggable-field-selection-list plain">
                      {printOrder.map((field, i) => (
                        <DraggableField
                          field={field}
                          index={i}
                          draggingTaskId={draggingTaskId}
                          toggleSelection={this.toggleSelection}
                          toggleSelectionInGroup={this.toggleSelectionInGroup}
                          deletePrintOrderItem={this.deletePrintOrderItem}
                          selectedDraggableItems={selectedDraggableItems}
                          type="printOrder"
                        />
                      ))}
                    </ul>
                  </div>
                )}
              </Droppable>
            </DDICardWrapper>
          </div>
        </DragDropContext>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(FieldSelectionModal)
