import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { formatNumber } from 'utils'
import { generateCellStyle } from 'pages/SalesOrder/utils'

import '../styles/bottom-tabs.scss'

export const ShipToDollarLimit = ({
  currentSJPeriod,
  shipToBalance,
  shipToLimit,
  shipToSales
}) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className="bold-title-cell">Limit</TableCell>

          <TableCell style={generateCellStyle(shipToLimit)}>
            {formatNumber(shipToLimit)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="bold-title-cell">Sales</TableCell>

          <TableCell style={generateCellStyle(shipToSales)}>
            {formatNumber(shipToSales)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="bold-title-cell">Balance</TableCell>

          <TableCell style={generateCellStyle(shipToBalance)}>
            {formatNumber(shipToBalance)}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className="bold-title-cell">Current SJ Period</TableCell>

          <TableCell style={{ fontWeight: 'bold' }}>
            {currentSJPeriod}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ShipToDollarLimit
