import { toJSON, createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { isInteger } from 'lodash'
import moment from 'moment'
import { TRUCK_MANIFEST_URL } from 'services/constants'

export const truckManifestSearch = ({
  branch,
  shipDate,
  transferWarehouseId
}) => {
  const options = createApiOptions({
    body: { branchId: branch, shipDate, transferWarehouseId },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}search`, options)
}

export const addOrders = ({
  branch,
  position,
  truckId,
  orderIds,
  shipDate,
  selectedBoCycle,
  transferWarehouseId
}) => {
  if (isInteger(position)) {
    position += 1
  }
  const options = createApiOptions({
    body: {
      branchId: branch,
      orderIds,
      position,
      shipDate: moment(shipDate).format('MM/DD/YYYY'),
      truckId,
      transferWarehouseId,
      selectedBoCycle
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}add`, options)
}

export const removeOrders = ({
  branch,
  orderIds,
  shipDate,
  transferWarehouseId
}) => {
  const options = createApiOptions({
    body: {
      branchId: branch,
      orderIds,
      shipDate: moment(shipDate).format('MM/DD/YYYY'),
      transferWarehouseId
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}remove`, options)
}

export const toggleFc = ({ branchId, fc, orderId, shipDate }) => {
  const options = createApiOptions({
    body: {
      branchId,
      orderId,
      fc,
      shipDate: moment(shipDate).format('MM/DD/YYYY')
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}fc`, options)
}

export const truckManifestSelectOrder = ({ orderId }) => {
  const options = createApiOptions({
    body: { orderId },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}select`, options)
}

export const truckManifestSync = ({
  branchId,
  shipDate,
  syncEnable,
  truckId
}) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      syncEnable,
      truckId
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}sync`, options)
}
export const truckManifestSyncAll = ({ branchId, shipDate, syncEnable }) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      syncEnable
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}syncall`, options)
}

export const sendToWMS = ({ sendToWMS: sendToMWSArg, truckId, shipDate }) => {
  const options = createApiOptions({
    body: {
      sendToWMS: sendToMWSArg,
      truckId,
      shipDate
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}wms`, options)
}

export const openNotes = ({
  branchId,
  shipDate,
  truckId,
  transferWarehouseId
}) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      truckId,
      transferWarehouseId
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}notesopen`, options)
}

export const saveNotes = ({
  branchId,
  comment1,
  comment2,
  shipDate,
  truckId
}) => {
  const options = createApiOptions({
    body: {
      branchId,
      comment1,
      comment2,
      shipDate,
      truckId
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}notessave`, options)
}

export const consolidateFc = ({ branchId, shipDate, truckId, type }) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      truckId,
      type
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}consolidatefc`, options)
}

export const shipConfirmTruck = ({ branchId, shipDate, truckId }) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      truckId
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}shipconfirm`, options)
}

export const printTruckManifest = ({
  branchId,
  shipDate,
  truckIds,
  manifestReportType,
  pickSheetReportType,
  printSalesOrders,
  printPickSheet,
  printManifest,
  printType,
  credentials
}) => {
  const options = createApiOptions({
    body: {
      branchId,
      shipDate,
      truckIds,
      manifestReportType,
      pickSheetReportType,
      printSalesOrders,
      printPickSheet,
      printManifest,
      printType,
      credentials
    },
    method: 'post'
  })
  return callFetch(`${TRUCK_MANIFEST_URL}print`, options)
}

export const getPrintModalMessage = ({ dataId }) => {
  const options = createApiOptions({
    body: {
      dataId
    },
    method: 'post'
  })

  return callFetch(`${TRUCK_MANIFEST_URL}getmessage`, options)
}

export const clearPickSheet = ({ dataId }) => {
  const options = createApiOptions({
    body: {
      dataId
    },
    method: 'post'
  })

  return callFetch(`${TRUCK_MANIFEST_URL}clearpicksheet`, options)
}
