import React from 'react'
import { Icon, Tooltip } from '@material-ui/core'

const IndicatorColumn = ({ data, value }) => {
  const { documentType = '' } = data

  if (documentType === 'Payment' || documentType === 'Invoice') {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Tooltip title={documentType}>
          <Icon
            style={{ color: '#444', display: 'inline-block', fontSize: 16 }}
          >
            {documentType === 'Invoice' ? 'receipt' : 'payment'}
          </Icon>
        </Tooltip>
      </div>
    )
  }

  return ''
}

export default IndicatorColumn
