import React from 'react'
import shortid from 'shortid'
import { noop } from 'lodash'
import { Icon, IconButton, TableRow, TableCell } from '@material-ui/core'

import { DDILotSearch } from 'ddiForm/wrapped'
import { mediumGrey, errorRed } from 'theme/colors'
import QuantityEditor from './QuantityEditor'

const LotsGrid = ({
  rowId,
  dataId,
  lotsEditorMeta = {},
  lots = [],
  inquiryMode = false,
  isPosting = false,
  warehouseId,
  onChangeQuantity = noop,
  onSetField = noop,
  removeSerialNumberRow = noop
}) => {
  return (
    <>
      {lots.map((item, i) => {
        return (
          <TableRow
            key={`lineItemSerialNumber-${dataId}-${rowId}-${shortid.generate()}`}
          >
            <TableCell>
              <div style={{ width: 125 }}>
                <DDILotSearch
                  propertyName={`serialNumbers.${rowId}.${i}`}
                  meta={{
                    allowInstantSearch: false,
                    allowInvalidValues: true,
                    allowNavigation: false,
                    allowSearchAll: true,
                    allowSpecialProducts: false,
                    fieldName: `serialNumbers.${rowId}.${i}`,
                    hasFilters: false,
                    hideToggle: true,
                    isSecure: false,
                    minimumKeywordLength: 2,
                    ...lotsEditorMeta
                  }}
                  wrapperStyle={{ margin: 0, width: '100%' }}
                  preventAutoDisable
                  disabled={
                    inquiryMode || (item.dataId && item.dataId !== 'blankrow')
                  }
                  productId={dataId}
                  warehouseId={warehouseId}
                  returnTransactionId={null}
                  onSetField={onSetField}
                  initialValue={
                    item?.dataId && item?.dataId !== 'blankrow'
                      ? item.dataId
                      : null
                  }
                  value={
                    item?.dataId && item?.dataId !== 'blankrow'
                      ? item.dataId
                      : null
                  }
                  // value={item.dataId !== 'blankrow' ? item.dataId : null}
                />
              </div>
            </TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.manufacturedDate}</TableCell>
            <TableCell>{item.expirationDate}</TableCell>
            <TableCell align="right">
              {item.factoredAvailableQuantity}
            </TableCell>
            <TableCell align="right">
              {item.dataId && item?.dataId !== 'blankrow' ? (
                <div style={{ width: 75 }}>
                  <QuantityEditor
                    lotId={item.dataId}
                    value={item.factoredQuantity}
                    onChangeQuantity={onChangeQuantity}
                    noDecimalLimit
                  />
                </div>
              ) : (
                <div style={{ width: 75 }}>{item?.factoredQuantity}</div>
              )}
            </TableCell>
            <TableCell style={{ paddingRight: 0 }}>
              <div
                style={{
                  width: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <IconButton
                  disabled={inquiryMode}
                  style={{ color: inquiryMode ? mediumGrey : errorRed }}
                  size="small"
                  onClick={e => removeSerialNumberRow(e, item.dataId, i)}
                >
                  <Icon size="small">cancel</Icon>
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        )
      })}
    </>
  )
}

export default LotsGrid
