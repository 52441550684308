import React, { Component, memo, useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { DDISelectField, DDIToggle } from 'ddiForm/wrapped'

import { withContext } from 'ddiForm'
import { setField } from 'ddiForm/actions'
import DDICardWrapper from 'components/DDICardWrapper'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { emptyList, getIn, getValue, getField, is } from 'utils'

import {
  initPrintRoutine as initPrintRoutineAction,
  sendSalesOrderDocument as sendSalesOrderDocumentAction,
  storeUIFeatureState as storeUIFeatureStateAction
} from 'pages/SalesOrder/actions'

/* get the stylized, compacted expansion panels from utils */
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from 'pages/SalesOrder/utils'

import { Button, Fab, Icon, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const marginFive = { margin: 5 }

const PrintOptions = ({
  canPrint,
  canPrintOrderRecap,
  canPrintWarehousePick,
  canPrintWorksheet,
  canSend,
  allowSend,
  dataId,
  documentName,
  invoicePrintOptionsEnabled,
  invoicePrintOptionsVisible,
  invoicePrintOptionsList,
  isEditing,
  isSendRequired,
  layoutId,
  layoutOptionsList,
  layoutOptionsEnabled,
  printItemPrices,
  printOption,
  printListPrices,
  printTotalsOnly,
  printBackOrderItems,
  suppressCompanyHeader,
  form,
  dispatch,
  height,
  printOptionsExpansionPanel,
  quote,
  quotePrintGP,
  quotePrintListAndDiscount,
  quotePrintQuoteDescription,
  quoteSuppressExtensions,
  quoteSuppressProductNumber,
  quoteSuppressTaxes,
  quoteSuppressTotals,
  quoteSuppressUnitPrices,
  printPanelOpen,
  invoicePrintType,
  isMobile = false
}) => {
  const layoutOptions = layoutOptionsList?.toJS ? layoutOptionsList.toJS() : []
  const invoicePrintOptions = invoicePrintOptionsList?.toJS
    ? invoicePrintOptionsList.toJS()
    : []

  let sendButton = null
  let sideEffectActions = null

  const [expanded, setExpanded] = useState(printOptionsExpansionPanel)
  const [focusHasBeenSet, isFocusSet] = useState(false)
  const [printOptsPanelClosed, togglePrintOptsPanel] = useState(printPanelOpen)

  const handleChange = panel => (event, isExpanded) => {
    const expansionState = isExpanded ? panel : false
    setExpanded(expansionState)
    dispatch(
      storeUIFeatureStateAction(form, {
        feature: 'printOptionsExpansionPanel',
        featureState: expansionState
      })
    )
  }

  const initPrintRoutine = debounce(
    type => dispatch(initPrintRoutineAction.try(form, { type })),
    500
  )

  const initSendDocument = debounce(
    () => dispatch(sendSalesOrderDocumentAction.try(form)),
    500
  )

  useEffect(() => {
    if (!layoutId) {
      dispatch(setField(form, 'layoutId', 'Default'))
    }

    if (!printOption) {
      dispatch(setField(form, 'printOption', 'P'))
    }

    if (form === 'invoiceInquiry' && !invoicePrintType) {
      dispatch(setField(form, 'invoicePrintType', 'N'))
    }

    if (isSendRequired && sendButton && sendButton.focus && !focusHasBeenSet) {
      sendButton.focus()
      isFocusSet(true)
    }
  }, [focusHasBeenSet, isSendRequired, layoutId, printOption, invoicePrintType])

  const onTogglePrintPanel = e => {
    const isOpen = !printOptsPanelClosed

    togglePrintOptsPanel(isOpen)

    dispatch(
      storeUIFeatureStateAction(form, {
        feature: 'printPanelOpen',
        featureState: isOpen
      })
    )
  }

  return (
    <div
      className="sales-order-print-opts-wrapper"
      style={{
        maxWidth: 330,
        marginLeft: printOptsPanelClosed || isMobile ? 0 : 10
      }}
    >
      <div style={{ position: 'relative' }}>
        <DDICardWrapper
          title="Print Options"
          contentStyle={{ padding: 0 }}
          style={{ display: printOptsPanelClosed ? 'none' : 'block' }}
        >
          <div style={{ display: 'flex', padding: 5 }}>
            <div style={{ margin: 5, width: 150 }}>
              <DDISelectField
                propertyName="layoutId"
                label="Layout"
                // disabled={layoutOptionsEnabled}
                preventAutoDisable
                values={layoutOptions}
                fullWidth
                initialValue="Default"
                selectValue="description"
                selectDescription="description"
              />
            </div>
            <div style={{ margin: 5, width: 150 }}>
              {invoicePrintOptionsVisible && (
                <DDISelectField
                  propertyName="invoicePrintType"
                  label="Invoice"
                  preventAutoDisable
                  disabled={!invoicePrintOptionsEnabled}
                  initialValue="P"
                  values={invoicePrintOptions}
                  fullWidth
                />
              )}
            </div>
          </div>
          {layoutId === 'Default' ? (
            <ExpansionPanel
              expanded={expanded === 'opts'}
              onChange={handleChange('opts')}
              square
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography style={{ color: '#444', fontSize: '0.85em' }}>
                  Additional Options
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography component="div" style={{ width: '100%' }}>
                  {quote ? (
                    <>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print Gross Profit"
                          propertyName="quotePrintGP"
                          initialValue={quotePrintGP}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Unit Prices"
                          propertyName="quoteSuppressUnitPrices"
                          initialValue={quoteSuppressUnitPrices}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print List & Discount"
                          propertyName="quotePrintListAndDiscount"
                          initialValue={quotePrintListAndDiscount}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Product Number"
                          propertyName="quoteSuppressProductNumber"
                          initialValue={quoteSuppressProductNumber}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Extensions"
                          propertyName="quoteSuppressExtensions"
                          initialValue={quoteSuppressExtensions}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Totals"
                          propertyName="quoteSuppressTotals"
                          initialValue={quoteSuppressTotals}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print Quote Description"
                          propertyName="quotePrintQuoteDescription"
                          initialValue={quotePrintQuoteDescription}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Taxes"
                          propertyName="quoteSuppressTaxes"
                          initialValue={quoteSuppressTaxes}
                          disabled={isEditing}
                          preventAutoDisable
                        />
                      </AssociatedFieldsWrapper>
                    </>
                  ) : (
                    <>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print Item Prices"
                          propertyName="printItemPrices"
                          initialValue={printItemPrices}
                          disabled={!isEditing}
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print List Prices"
                          propertyName="printListPrices"
                          initialValue={printListPrices}
                          disabled={!isEditing}
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print Totals Only"
                          propertyName="printTotalsOnly"
                          initialValue={printTotalsOnly}
                          disabled={!isEditing}
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Print B/O Items"
                          propertyName="printBackOrderItems"
                          initialValue={printBackOrderItems}
                          disabled={!isEditing}
                        />
                      </AssociatedFieldsWrapper>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          label="Suppress Company Header"
                          propertyName="suppressCompanyHeader"
                          initialValue={suppressCompanyHeader}
                          disabled={!isEditing}
                        />
                      </AssociatedFieldsWrapper>
                    </>
                  )}
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : null}
          <div
            style={{
              display: 'flex',
              flexFlow: 'wrap',
              maxWidth: 330,
              padding: 5
            }}
          >
            {dataId && canPrint ? (
              <div style={marginFive}>
                <Button
                  onClick={e => initPrintRoutine('printSalesOrder')}
                  disabled={isEditing}
                  variant="contained"
                  style={{ width: 150 }}
                >
                  {documentName}
                </Button>
              </div>
            ) : null}
            {dataId && canPrintWorksheet ? (
              <div style={marginFive}>
                <Button
                  onClick={e => initPrintRoutine('printWorksheet')}
                  disabled={isEditing}
                  variant="contained"
                  style={{ width: 150 }}
                >
                  Worksheet
                </Button>
              </div>
            ) : null}
            {dataId && canPrintWarehousePick ? (
              <div style={marginFive}>
                <Button
                  onClick={e => initPrintRoutine('printWarehousePick')}
                  disabled={isEditing}
                  variant="contained"
                  style={{ width: 150 }}
                >
                  Warehouse Pick
                </Button>
              </div>
            ) : null}
            {dataId && form === 'invoiceInquiry' ? (
              <div style={marginFive}>
                <Button
                  onClick={e => initPrintRoutine('printProofOfDelivery')}
                  disabled={isEditing}
                  variant="contained"
                  style={{ fontSize: '0.8em', minHeight: 26, width: 150 }}
                >
                  Proof of Delivery
                </Button>
              </div>
            ) : null}
            {dataId && canPrintOrderRecap ? (
              <div style={marginFive}>
                <Button
                  onClick={e => initPrintRoutine('printOrderRecap')}
                  disabled={isEditing}
                  variant="contained"
                  style={{ width: 150 }}
                >
                  Order Recap
                </Button>
              </div>
            ) : null}
            {dataId && canSend ? (
              <div style={marginFive}>
                <Button
                  onClick={initSendDocument}
                  disabled={
                    isEditing || (!allowSend && form === 'invoiceInquiry')
                  }
                  variant="contained"
                  style={{ width: 150 }}
                  ref={el => (sendButton = el)}
                  action={actions => (sideEffectActions = actions)}
                  onFocus={() => {
                    if (sideEffectActions && isSendRequired) {
                      sideEffectActions.focusVisible()
                    }
                  }}
                >
                  Send
                </Button>
              </div>
            ) : null}
          </div>
        </DDICardWrapper>
        {!isMobile ? (
          <Fab
            style={{
              position: 'absolute',
              right: 5,
              top: 5,
              width: 30,
              height: 30,
              minHeight: 30
            }}
            onClick={onTogglePrintPanel}
            size="small"
          >
            <Icon fontSize="small" style={{ color: '#444', fontSize: 20 }}>
              {printOptsPanelClosed ? 'print' : 'print_disabled'}
            </Icon>
          </Fab>
        ) : null}
      </div>
    </div>
  )
}

const contextObj = {
  canPrint: form => getValue(form, 'canPrint', false),
  canPrintOrderRecap: form => getValue(form, 'canPrintOrderRecap', false),
  canPrintWarehousePick: form => getValue(form, 'canPrintWarehousePick', false),
  canPrintWorksheet: form => getValue(form, 'canPrintWorksheet', false),
  canSend: form => getValue(form, 'canSend', false),
  allowSend: form => getValue(form, 'allowSend', false),
  dataId: form => getField(form, 'dataId', ''),
  documentName: form => getValue(form, 'documentName', 'Sales Order'),
  isEditing: form => form.get('isEditing') || false,
  isSendRequired: form => getValue(form, 'isSendRequired', false),
  layoutId: form => getField(form, 'layoutId', ''),
  layoutOptionsList: form => getIn(form, 'values.layoutOptions') || emptyList,
  layoutOptionsEnabled: form => getValue(form, 'layoutOptionsEnabled', false),
  printOption: form => getField(form, 'printOption', ''),
  printItemPrices: form => getValue(form, 'printItemPrices', false),
  printListPrices: form => getValue(form, 'printListPrices', false),
  printTotalsOnly: form => getValue(form, 'printTotalsOnly', false),
  printBackOrderItems: form => getValue(form, 'printBackOrderItems', false),
  suppressCompanyHeader: form => getValue(form, 'suppressCompanyHeader', false),
  printOptionsExpansionPanel: form =>
    getIn(form, 'ui.printOptionsExpansionPanel') || false,
  // quote: form => getValue(form, 'quote', false),
  quote: form => {
    if (is.bool(getIn(form, 'fields.quote.value'))) {
      return getIn(form, 'fields.quote.value')
    }

    if (is.bool(getIn(form, 'values.quote'))) {
      return getIn(form, 'values.quote')
    }

    return false
  },
  quotePrintGP: form => getValue(form, 'quotePrintGP', false),
  quotePrintListAndDiscount: form =>
    getValue(form, 'quotePrintListAndDiscount', false),
  quotePrintQuoteDescription: form =>
    getValue(form, 'quotePrintQuoteDescription', false),
  quoteSuppressExtensions: form =>
    getValue(form, 'quoteSuppressExtensions', false),
  quoteSuppressProductNumber: form =>
    getValue(form, 'quoteSuppressProductNumber', false),
  quoteSuppressTaxes: form => getValue(form, 'quoteSuppressTaxes', false),
  quoteSuppressTotals: form => getValue(form, 'quoteSuppressTotals', false),
  quoteSuppressUnitPrices: form =>
    getValue(form, 'quoteSuppressUnitPrices', false),
  printPanelOpen: form => getIn(form, 'ui.printPanelOpen') || false,
  invoicePrintOptionsList: form =>
    getValue(form, 'invoicePrintOptions', emptyList),
  invoicePrintOptionsEnabled: form =>
    getValue(form, 'invoicePrintOptionsEnabled', false),
  invoicePrintOptionsVisible: form =>
    getValue(form, 'invoicePrintOptionsVisible', false),
  invoicePrintType: form => getField(form, 'invoicePrintType', '')
}

/* fixed 9/25/2020 SVE */
export default withContext(contextObj)(PrintOptions)
