import { getIn, setIn } from 'utils'
import { fromJS, Map, Set } from 'immutable'
import shortid from 'shortid'
import * as CONSTANTS from './constants'

const notesModalBehaviors = {
  [CONSTANTS.LOCK_INTERNAL_NOTES.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'notesModalEnabled', true)
    return result
  },
  [CONSTANTS.SAVE_INTERNAL_NOTES.SUCCESS]: (
    state,
    { payload: { note }, meta }
  ) => {
    let result = state
    result = setIn(result, 'notesModalEnabled', false)
    result = setIn(result, 'fields.internalNotes.value', note)
    result = setIn(result, 'values.internalNotes', note)

    let editedFields = getIn(result, 'editedFields')
    if (editedFields) {
      editedFields = editedFields.remove('internalNotes')
      result = setIn(result, 'editedFields', editedFields)
    }

    return result
  },
  [CONSTANTS.UNLOCK_INTERNAL_NOTES.SUCCESS]: (state, action) => {
    let result = state
    const internalNotesPrevValue =
      getIn(result, 'fields.internalNotes.prevValue') || ''

    const value = getIn(result, 'fields.internalNotes.value')

    if (internalNotesPrevValue && internalNotesPrevValue !== value) {
      result = setIn(
        result,
        'fields.internalNotes.value',
        internalNotesPrevValue
      )
      result = setIn(result, 'values.internalNotes', internalNotesPrevValue)
    }

    let editedFields = getIn(result, 'editedFields')
    if (editedFields) {
      editedFields = editedFields.remove('internalNotes')
      result = setIn(result, 'editedFields', editedFields)
    }

    result = setIn(result, 'notesModalEnabled', false)
    return result
  },
  [CONSTANTS.SAVE_INTERNAL_NOTES.FAILURE]: (state, action) => {
    let result = state
    const internalNotes = getIn(result, 'fields.internalNotes.prevValue')
    result = setIn(result, 'fields.internalNotes.value', internalNotes)

    let editedFields = getIn(result, 'editedFields')
    if (editedFields) {
      editedFields = editedFields.remove('internalNotes')
      result = setIn(result, 'editedFields', editedFields)
    }

    return result
  }
}

export default notesModalBehaviors
