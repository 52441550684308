/* eslint react/jsx-no-duplicate-props: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useState } from 'react'
import { withContext } from 'ddiForm'
import { fromJS } from 'immutable'
import { debounce } from 'lodash'
import memoize from 'memoize-one'
import {
  Select,
  TextField,
  MenuItem,
  Icon,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import { ZipCodeTextField } from 'components/masks'
import { handleCheckoutContact } from 'pages/SalesOrder/utils'
import {
  clearPaymentTransactionData,
  initializePayment,
  notifyCreditCardError,
  getCayanKey,
  storeUIFeatureState
} from 'pages/SalesOrder/actions'
import { getValue, getField } from 'utils'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import shortid from 'shortid'

import CVVPopover from './CVVPopover'
import {
  PaymentButton,
  PaymentCancellationButton,
  paymentContactMeta
} from '../../../utils'
import './styles/css-mod-ignore.scss'

// const inputWrapperStyle = { margin: '5px 10px', flexGrow: 1, maxWidth: 190 }
// const cayanKey = 'JCX34FC3KHWFEMHQ'

// const cayanKey = 'JCX34FC3KHWFEMHQ'

const createDateOptions = memoize((year = new Date().getFullYear()) => {
  const jsx = []
  for (let i = 0; i < 10; i++) {
    const currentYear = i > 0 ? year + i : year
    const value = currentYear.toString().substr(-2)
    jsx.push({ dataId: value, description: currentYear })
  }

  return jsx
})

function formatCardNumber(val) {
  const value = val.replace(/\D/g, '')
  let formattedValue

  if (/^3[47]\d{0,13}$/.test(value)) {
    formattedValue = value
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
      .trim()
  } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
    formattedValue = value
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
      .trim()
  } else if (
    /^\d{0,16}$/.test(value) ||
    /^\d{0,17}$/.test(value) ||
    /^\d{0,18}$/.test(value) ||
    /^\d{0,19}$/.test(value)
  ) {
    formattedValue = value
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{4})/, '$1 $2 ')
      .replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
      .trim()
  }

  return formattedValue
}

function getCreditCardType(cardNumber) {
  let cardType = ''

  // const discoverRegex = new RegExp('(?:6(?:011|5[0-9]{2})(?:[0-9]{12}))')
  // const visaRegex = new RegExp('(?:4[0-9]{12})(?:[0-9]{3})?')
  // const amexRegex = new RegExp('(?:3[47][0-9]{13})')
  // const mcRegex = new RegExp(
  //   '(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})'
  // )
  const discoverRegex = new RegExp('^6(?:011|5[0-9]{2})[0-9]{3,}')
  const visaRegex = new RegExp('^4[0-9]{6,}$')
  const amexRegex = new RegExp('^3[47][0-9]{5,}$')
  const mcRegex = new RegExp(
    '^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$'
  )

  if (cardNumber) {
    if (cardNumber.match(discoverRegex)) {
      cardType = 'discover'
    } else if (cardNumber.match(visaRegex)) {
      cardType = 'visa'
    } else if (cardNumber.match(amexRegex)) {
      cardType = 'amex'
    } else if (cardNumber.match(mcRegex)) {
      cardType = 'mc'
    } else {
      cardType = ''
    }
  }

  // console.log('getCreditCardType', cardNumber, cardType)

  return cardType
}

const isSubmitButtonDisabled = memoize(
  (
    isEditing,
    isPosting,
    cardholder,
    cardnumber,
    cardnumberFormatted,
    expirationmonth,
    expirationyear,
    streetaddress,
    zipcode,
    cvv,
    amountTendered,
    cayanKey
  ) => {
    if (!isEditing || isPosting || !cayanKey) {
      return true
    }

    if (
      (amountTendered || amountTendered === 0) &&
      amountTendered >= 0 &&
      (!cardholder ||
        !cardnumber ||
        !cardnumberFormatted ||
        !expirationmonth ||
        !expirationyear ||
        !streetaddress ||
        !zipcode ||
        !cvv)
    ) {
      return true
    }

    return false
  }
)

let _isMounted = false

const KeyedEntry = ({
  form,
  guid,
  dispatch,
  amountTendered,
  isPosting,
  balanceLeft,
  // paymentTransactionToken,
  paymentTransactionTokenMap,
  isEditing,
  customerId,
  checkoutContact,
  checkoutContactDescription,
  paymentAttemptSuccess,
  saveCCInfo,
  orderedById,
  orderedByName,
  inputWrapperStyle = { margin: '5px 10px', flexGrow: 1, maxWidth: 190 },
  inputRowWrapperStyle = {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  isMobile = false
}) => {
  const ccNumberInput = React.createRef()
  const currentYear = new Date().getFullYear()
  const dateOptions = createDateOptions(currentYear)

  const [state, setState] = useState({
    addToVault: false,
    token: '',
    cardholder: '',
    cardnumber: '',
    cardnumberFormatted: '',
    expirationmonth: '',
    expirationyear: '',
    streetaddress: '',
    zipcode: '',
    cvv: '',
    contactInputKey: shortid.generate(),
    cardType: ''
  })

  const [cayanKey, setCayanKey] = useState(null)

  const clearTransactionData = () => {
    setState({
      ...state,
      addToVault: false,
      token: '',
      cardholder: '',
      cardnumber: '',
      cardnumberFormatted: '',
      expirationmonth: '',
      expirationyear: '',
      streetaddress: '',
      zipcode: '',
      cvv: '',
      contactInputKey: shortid.generate(),
      cardType: ''
    })

    dispatch(clearPaymentTransactionData(form))
  }

  useEffect(() => {
    _isMounted = true
    /* functional component substitute for componentWillUnmount */
    // console.log('mount')
    handleCheckoutContact(
      dispatch,
      form,
      checkoutContact,
      checkoutContactDescription,
      orderedById,
      orderedByName
    )

    return () => {
      // console.log('unmount')
      _isMounted = false
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  useEffect(() => {
    async function getKey() {
      try {
        const { webKey } = await dispatch(getCayanKey.request(form, { guid }))
        setCayanKey(webKey)
      } catch (e) {
        console.log('webKeyError', e)
      }
    }

    /* 
      without ensuring the component is mounted, firing off 
      this async function can trigger a memory leak warning 
      in the console -- SVE 10/12/21
    */
    if (!cayanKey && _isMounted) {
      getKey()
    }
  }, [cayanKey])

  useEffect(() => {
    // const paymentTransactionToken = paymentTransactionTokenMap?.toJS ? paymentTransactionTokenMap.toJS() : { token: '', success: false }
    /* 
      this indicates that a transaction has been successfully posted,
      so we can clear out all the fields
    */
    if (
      paymentAttemptSuccess &&
      !isPosting &&
      paymentTransactionTokenMap &&
      paymentTransactionTokenMap?.get &&
      paymentTransactionTokenMap.get('token') === state.token &&
      paymentTransactionTokenMap.get('success')
    ) {
      /* test and remove debugger later */
      clearTransactionData()
    }
  }, [balanceLeft, paymentAttemptSuccess])

  const handleTextFieldChange = name => event => {
    const val = event.target.value
    setState({
      ...state,
      [name]:
        name === 'cvv' && val && typeof val === 'string'
          ? val.replace(/\D/g, '')
          : val
    })
  }

  const handleCCNumberChange = name => event => {
    event.persist()

    const val = event.target.value
    const formattedValue = formatCardNumber(val)

    setState({
      ...state,
      [name]: formattedValue,
      cardnumber: val ? val.replace(/\D/g, '') : ''
    })
  }

  const onSelectChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  const successCallback = params => {
    const { token } = params
    // console.log('successCallback', params)
    /* token, created, expires */
    //
    setState({
      ...state,
      token
    })

    dispatch(
      initializePayment.try(form, {
        option: 'keyedEntry',
        token,
        amountTendered,
        addToVault: state.addToVault
      })
    )
  }

  const errorCallback = error => {
    // console.log('errorCallback', error)
    setState({ ...state, token: '' })

    if (error && Array.isArray(error)) {
      const message = error.reduce((acc, next) => {
        acc = acc.concat(`Please check your ${next.reason.toUpperCase()}\r\n`)
        return acc
      }, '')

      dispatch(notifyCreditCardError(form, { message }))
    } else {
      dispatch(notifyCreditCardError(form))
    }
  }

  const onSubmitPayment = debounce(async e => {
    /* CayanCheckoutPlus is global var */
    if (isMobile) {
      dispatch(
        storeUIFeatureState(form, {
          feature: 'paymentOption',
          featureState: 'cc'
        })
      )
    }

    try {
      window.CayanCheckoutPlus.setWebApiKey(cayanKey)
      window.CayanCheckoutPlus.createPaymentToken({
        success: successCallback,
        error: errorCallback
      })
    } catch (err) {
      console.log('onSubmitPaymentError', err)
    }
  }, 300)

  const onCancelPayment = e => clearTransactionData()

  const onToggle = name => event => {
    setState({ ...state, [name]: event.target.checked })
  }

  const setCardType = () => {
    setState({
      ...state,
      cardType:
        state.cardnumber && typeof state.cardnumber === 'string'
          ? getCreditCardType(state.cardnumber)
          : ''
    })
  }

  return (
    <div className="keyed-entry-payment-module">
      <div style={inputRowWrapperStyle}>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <TextField
            className={
              state.cardType
                ? `cc-num-input cc-num-input-${state.cardType}`
                : 'cc-num-input'
            }
            label="Card Number"
            disabled={!isEditing}
            value={state.cardnumberFormatted}
            onChange={handleCCNumberChange('cardnumberFormatted')}
            inputProps={{
              maxLength: 22
            }}
            inputRef={ccNumberInput}
            onBlur={setCardType}
          />
          <div style={{ display: 'none' }}>
            <input
              type="text"
              data-cayan="cardnumber"
              value={state.cardnumber}
            />
          </div>
        </div>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <div
            className="keyed-entry-input-inner-wrapper-twocol"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div
              style={{
                width: 'calc(50% - 10px)',
                maxWidth: 87,
                marginLeft: 5,
                marginRight: 10
              }}
            >
              <FormControl>
                <InputLabel>Month</InputLabel>
                <Select
                  className="keyed-entry-input-inner-wrapper-twocol-select"
                  value={state.expirationmonth}
                  onChange={onSelectChange('expirationmonth')}
                  inputProps={{ 'data-cayan': 'expirationmonth' }}
                  style={{ maxWidth: 87 }}
                  disabled={!isEditing}
                >
                  <MenuItem value="01">January</MenuItem>
                  <MenuItem value="02">February</MenuItem>
                  <MenuItem value="03">March</MenuItem>
                  <MenuItem value="04">April</MenuItem>
                  <MenuItem value="05">May</MenuItem>
                  <MenuItem value="06">June</MenuItem>
                  <MenuItem value="07">July</MenuItem>
                  <MenuItem value="08">August</MenuItem>
                  <MenuItem value="09">September</MenuItem>
                  <MenuItem value="10">October</MenuItem>
                  <MenuItem value="11">November</MenuItem>
                  <MenuItem value="12">December</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div style={{ width: '50%', maxWidth: 87 }}>
              <FormControl>
                <InputLabel>Exp. Year</InputLabel>
                <Select
                  className="keyed-entry-input-inner-wrapper-twocol-select"
                  value={state.expirationyear}
                  onChange={onSelectChange('expirationyear')}
                  inputProps={{ 'data-cayan': 'expirationyear' }}
                  style={{ maxWidth: 87 }}
                  disabled={!isEditing}
                >
                  {dateOptions.map((x, i) => (
                    <MenuItem value={x?.dataId}>{x?.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
      <div style={inputRowWrapperStyle}>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <TextField
            value={state.cardholder}
            label="Cardholder Name"
            onChange={handleTextFieldChange('cardholder')}
            inputProps={{
              'data-cayan': 'cardholder'
            }}
            disabled={!isEditing}
          />
        </div>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <div style={{ position: 'relative' }}>
            <TextField
              label="CVV"
              value={state.cvv}
              onChange={handleTextFieldChange('cvv')}
              inputProps={{
                'data-cayan': 'cvv',
                maxLength: 6
              }}
              disabled={!isEditing}
            />
            <div style={{ position: 'absolute', bottom: 5, right: 0 }}>
              <CVVPopover />
            </div>
          </div>
        </div>
      </div>

      <div style={inputRowWrapperStyle}>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <TextField
            value={state.streetaddress}
            label="Street Address"
            onChange={handleTextFieldChange('streetaddress')}
            inputProps={{
              'data-cayan': 'streetaddress'
            }}
            disabled={!isEditing}
          />
        </div>
        <div className="keyed-entry-input-wrapper" style={inputWrapperStyle}>
          <TextField
            label="Postal Code"
            InputProps={{
              inputComponent: ZipCodeTextField,
              onChange: handleTextFieldChange('zipcode'),
              value: state.zipcode,
              inputProps: {
                id: 'cayan-zipcode-input',
                'data-cayan': 'zipcode'
              }
            }}
            disabled={!isEditing}
          />
        </div>
      </div>

      {!isMobile ? (
        <div style={{ alignItems: 'flex-end', display: 'flex' }}>
          {saveCCInfo ? (
            <div style={{ ...inputWrapperStyle, margin: '0 10px' }}>
              <label
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: '0.85em',
                  margin: '10px 0 5px 0',
                  transform: 'translate(0, 7px) scale(0.75)',
                  transformOrigin: 'top left'
                }}
              >
                Contact
              </label>
              <DDIIndexSearch
                key={state.contactInputKey}
                propertyName="checkoutContact"
                parentId={customerId}
                fullWidth
                parentType="Customer"
                popoverStyle={{
                  width: 750
                }}
                meta={paymentContactMeta}
                descriptionKey="contact"
                displayDescription
                description={checkoutContactDescription}
                inputProps={{
                  showQuickEntityButton: true
                }}
              />
            </div>
          ) : null}
          <div style={{ ...inputWrapperStyle, margin: '0 10px' }}>
            <AssociatedFieldsWrapper>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.addToVault}
                    onChange={onToggle('addToVault')}
                    value="addToVault"
                  />
                }
                label="Add To Vault"
              />
            </AssociatedFieldsWrapper>
          </div>
        </div>
      ) : null}
      {!cayanKey ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
            width: '100%'
          }}
        >
          <div
            className="alert alert-warning"
            style={{
              backgroundColor: '#fcf8e3',
              borderColor: '#faebcc',
              padding: 5,
              margin: '10px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Icon size="small" style={{ marginRight: 5 }}>
              warning
            </Icon>
            <span>
              There is no Cayan account associated with this customer or
              selected branch
            </span>
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          padding: '10px 5px 5px 5px'
        }}
      >
        <PaymentButton
          onClick={onSubmitPayment}
          variant="contained"
          // disabled={isPosting || !isEditing}
          disabled={isSubmitButtonDisabled(
            isEditing,
            isPosting,
            state.cardholder,
            state.cardnumber,
            state.cardnumberFormatted,
            state.expirationmonth,
            state.expirationyear,
            state.streetaddress,
            state.zipcode,
            state.cvv,
            amountTendered,
            cayanKey
          )}
          style={{ margin: 10 }}
        >
          <Icon style={{ marginRight: 5 }}>lock</Icon> Submit
        </PaymentButton>
        <PaymentCancellationButton
          onClick={onCancelPayment}
          variant="contained"
          disabled={isPosting || !isEditing}
          style={{ margin: 10 }}
        >
          <Icon style={{ marginRight: 5 }}>cancel</Icon>
          {form === 'fastReceipt' ? 'Clear' : 'Cancel'}
        </PaymentCancellationButton>
      </div>
    </div>
  )
}

export default withContext({
  checkoutContact: form => getValue(form, 'checkoutContact', ''),
  checkoutContactDescription: form =>
    getField(form, 'checkoutContactDescription', '') ||
    getValue(form, 'checkoutContactDescription', '') ||
    '',
  customerId: form => getField(form, 'customerId', ''),
  balanceLeft: form => getValue(form, 'balanceLeft', 0),
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  paymentTransactionTokenMap: form =>
    form.get('paymentTransactionToken') ||
    fromJS({ token: '', success: false }),
  paymentAttemptSuccess: form => form.get('paymentAttemptSuccess') || false,
  saveCCInfo: form => getValue(form, 'saveCCInfo', false),
  orderedById: form => getField(form, 'orderedById', ''),
  orderedByName: form => getValue(form, 'orderedByName', ''),
  guid: form => form.get('guid')
})(KeyedEntry)
