import { fork, put, select, take } from 'redux-saga/effects'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { CANCEL_EDIT } from 'ddiForm/MasterScreen/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { getFormSelector } from 'ddiForm/utils'
import { getIn } from 'utils'

import * as actions from './actions'
import * as CONSTANTS from './constants'

export function* handlePreNewModeCheckProcess(form) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  if (isEditing) {
    yield put({
      type: CANCEL_EDIT.TRY,
      meta: { form }
    })

    const result = yield take([CANCEL_EDIT.SUCCESS, CANCEL_EDIT.FAILURE])

    if (
      result?.meta?.form &&
      result?.meta?.form === form &&
      result?.type === CANCEL_EDIT.SUCCESS
    ) {
      yield put(resetMasterFields(form))
      yield put(actions.togglePreNewMode(form))
    }
  } else {
    yield put(actions.togglePreNewMode(form))
  }
}

export function* tryTogglePreNewModeListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.TRY_TOGGLE_PRE_NEW_MODE)

    if (form === formListener) {
      yield fork(handlePreNewModeCheckProcess, form)
    }
  }
}

export function* changeRecordSelectionListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(INDEX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS)

    if (form === formListener && propertyName === 'dataId') {
      yield put(resetMasterFields(form, ['dataId']))
    }
  }
}
