import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Switch } from '@material-ui/core'
import { formatDate, formatNumber } from 'utils'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'

const flexWrapperStyle = {
  display: 'flex',
  fontSize: 12,
  justifyContent: 'space-between',
  padding: 5
}

const PricingToggleInterface = ({
  form,
  dispatch,
  lineNumber,
  propertyName,
  label,
  isChecked,
  unitPrice,
  netPrice,
  date,
  additionalData,
  additionalDataKey,
  wrapperStyle,
  disabled,
  hasAdditionalData
}) => {
  const [state, setState] = useState({
    [propertyName]: isChecked
  })

  useEffect(() => {
    if (isChecked !== state[propertyName]) {
      setState({
        ...state,
        [propertyName]: isChecked
      })
    }
  })

  const handleChange = name => event => {
    event.persist()
    // console.log(event.target.checked, state)
    setState({ [propertyName]: event.target.checked })
    dispatch(
      changeProvisionalLineItem.try(form, {
        propertyName,
        lineNumber,
        value: event.target.checked
      })
    )
  }

  return (
    <div style={wrapperStyle}>
      <FormControlLabel
        control={
          <Switch
            checked={state[propertyName]}
            onChange={handleChange(propertyName)}
            disabled={disabled}
            value={propertyName}
          />
        }
        label={label}
      />
      <div style={flexWrapperStyle}>
        <div style={{ minWidth: 75 }}>Unit</div>
        <div>
          <strong>{formatNumber(unitPrice, '0,0.0000')}</strong>
        </div>
      </div>
      <div style={flexWrapperStyle}>
        <div style={{ minWidth: 75 }}>Net</div>
        <div>
          <strong>{formatNumber(netPrice, '0,0.0000')}</strong>
        </div>
      </div>
      {hasAdditionalData ? (
        <>
          <div style={flexWrapperStyle}>
            <div style={{ minWidth: 75 }}>{additionalDataKey}</div>
            <div>
              <strong>{additionalData}</strong>
            </div>
          </div>
          <div style={flexWrapperStyle}>
            <div style={{ minWidth: 75 }}>Date</div>
            <div>
              <strong>{date ? formatDate(date, 'MM/DD/YY') : null}</strong>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

PricingToggleInterface.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasAdditionalData: PropTypes.bool.isRequired,
  lineNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  propertyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  unitPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  netPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  date: PropTypes.string,
  additionalData: PropTypes.string,
  additionalDataKey: PropTypes.string,
  wrapperStyle: PropTypes.object
}

PricingToggleInterface.defaultProps = {
  date: '',
  disabled: false,
  additionalData: '',
  additionalDataKey: '',
  hasAdditionalData: false,
  wrapperStyle: {
    background: '#f4f7f9',
    borderRadius: 4,
    border: '1px solid #517b9c',
    marginRight: 5,
    padding: 5
  }
}

export default PricingToggleInterface
