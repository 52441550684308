import React, { memo, useState } from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import {
  AppBar,
  Collapse,
  Icon,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Paper,
  Tooltip
} from '@material-ui/core'

import { DDISelectField, DDITextField } from 'ddiForm/wrapped'

import { withStyles } from '@material-ui/core/styles'
import {
  emptyList,
  formatNumber,
  generateTabs,
  getMeta,
  getValue,
  getField,
  plainDeepEqual
} from 'utils'

import MiscChargeGrid from './MiscChargeGrid'
import AmountPaidGrid from './AmountPaid'
import FreightFields from '../../OrderTotals/components/FreightFields'
import ItemizedSubTotalTable from '../../OrderTotals/components/ItemizedSubTotalTable'
import ItemizedTotalTable from '../../OrderTotals/components/ItemizedTotalTable'

export const ListItem = withStyles({
  root: {
    borderBottom: '1px solid #c9c9c9',
    paddingBottom: 5,
    paddingTop: 5
  }
})(MuiListItem)

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #dadde9'
  }
}))(Tooltip)

export const ListItemText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0,
    marginTop: 0
  },
  primary: {
    color: '#444',
    fontSize: '0.9em',
    fontWeight: 500
  },
  secondary: {
    color: '#444',
    fontSize: '0.9em',
    fontWeight: 500
  },
  multiline: {
    marginBottom: 0,
    marginTop: 0
  }
})(MuiListItemText)

const frieghtHeadingStyle = {
  color: '#517b9c',
  fontSize: '0.9em',
  margin: '0 0 10px 0'
}

const getAmountPaidLabel = memoize((payments = []) => {
  let label = 'Amount Paid'

  if (!payments.length) {
    return label
  }

  if (payments.length > 1) {
    label = `Amount Paid: (${payments.length} Payments)`
    return label
  }

  if (
    payments.length === 1 &&
    payments[0] &&
    payments[0].paymentTypeDescription
  ) {
    label = `Amount Paid: (${payments[0].paymentTypeDescription})`
    return label
  }

  return label
}, plainDeepEqual)

const InvoiceSummary = ({
  freight,
  miscellaneousChargeTotal,
  form,
  height,
  dispatch,
  shippableTotal,
  shippableSubtotal,
  shippableTaxAmount,
  paid,
  balanceLeft,
  change,
  paymentsList,
  isMobile = false
}) => {
  const payments = paymentsList?.toJS ? paymentsList.toJS() : []
  const [freightToggleOpen, setOpen] = useState(false)
  const [miscChargeToggleOpen, setMiscChargeToggleOpen] = useState(false)
  const [amountPaidToggle, setAmountPaidToggle] = useState(false)

  function handleFreightToggle() {
    setOpen(!freightToggleOpen)
  }

  function handleMiscChargeToggle() {
    setMiscChargeToggleOpen(!miscChargeToggleOpen)
  }

  function handleAmountPaidToggle(e) {
    setAmountPaidToggle(!amountPaidToggle)
  }

  const wrapperStyle = isMobile
    ? { flexGrow: 1, paddingTop: 10 }
    : { maxWidth: 700, minWidth: 400, flexGrow: 1, paddingTop: 10 }

  return (
    <Paper style={wrapperStyle} square>
      <h2
        style={{
          color: '#517b9c',
          fontSize: '1.2em',
          margin: '0 10px 10px 10px',
          textAlign: 'center'
        }}
      >
        Invoice Summary
      </h2>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem>
          <ListItemText
            primary="Subtotal"
            secondary={
              <HtmlTooltip title={<ItemizedSubTotalTable />} placement="right">
                <span>{formatNumber(shippableSubtotal)}</span>
              </HtmlTooltip>
            }
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>
        <ListItem button onClick={handleFreightToggle}>
          {freightToggleOpen ? (
            <Icon>keyboard_arrow_down</Icon>
          ) : (
            <Icon>keyboard_arrow_right</Icon>
          )}
          <ListItemText primary="Freight" secondary={formatNumber(freight)} />
        </ListItem>
        <Collapse
          in={freightToggleOpen}
          timeout="auto"
          unmountOnExit
          style={{ borderBottom: '1px solid #c9c9c9' }}
        >
          <div style={{ margin: 10 }}>
            <FreightFields />
          </div>
        </Collapse>

        <ListItem button onClick={handleMiscChargeToggle}>
          {miscChargeToggleOpen ? (
            <Icon>keyboard_arrow_down</Icon>
          ) : (
            <Icon>keyboard_arrow_right</Icon>
          )}
          <ListItemText
            primary="Miscellaneous Charge"
            secondary={formatNumber(miscellaneousChargeTotal)}
          />
        </ListItem>
        <Collapse
          in={miscChargeToggleOpen}
          timeout="auto"
          unmountOnExit
          style={{ borderBottom: '1px solid #c9c9c9' }}
        >
          <div style={{ margin: 10 }}>
            <MiscChargeGrid
              form={form}
              height={height}
              dispatch={dispatch}
              isMobile={isMobile}
            />
          </div>
        </Collapse>

        <ListItem>
          <ListItemText
            primary="Tax"
            secondary={formatNumber(shippableTaxAmount)}
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>
        <ListItem style={{ background: '#e1e3e4' }}>
          <ListItemText
            primary="TOTALS"
            secondary={
              <HtmlTooltip title={<ItemizedTotalTable />} placement="right">
                <span>{formatNumber(shippableTotal)}</span>
              </HtmlTooltip>
            }
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>

        <ListItem button onClick={handleAmountPaidToggle}>
          <Icon>
            {amountPaidToggle ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
          </Icon>
          <ListItemText
            primary={getAmountPaidLabel(payments)}
            secondary={formatNumber(paid)}
          />
        </ListItem>
        <Collapse
          in={amountPaidToggle}
          timeout="auto"
          unmountOnExit
          style={{ borderBottom: '1px solid #c9c9c9' }}
        >
          <div style={{ margin: 10 }}>
            <AmountPaidGrid form={form} height={height} dispatch={dispatch} />
          </div>
        </Collapse>
        <ListItem>
          <ListItemText
            primary="Balance"
            secondary={formatNumber(balanceLeft)}
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
            secondaryTypographyProps={{
              style: {
                color: balanceLeft < 0 ? '#d9534f' : 'auto'
              }
            }}
          />
        </ListItem>
        {change ? (
          <ListItem>
            <ListItemText
              primary="Change Due"
              secondary={formatNumber(change)}
              primaryTypographyProps={{ style: { marginLeft: 18 } }}
            />
          </ListItem>
        ) : null}
      </List>
    </Paper>
  )
}

/* fixed SVE 9/25/2020 */
const contextObj = {
  freight: form => getValue(form, 'freight', 0),
  freightAllowed: form => getValue(form, 'freightAllowed', false),
  miscellaneousChargeTotal: form =>
    getValue(form, 'miscellaneousChargeTotal', 0),
  shippableTotal: form => getValue(form, 'shippableTotal', 0),
  shippableSubtotal: form => getValue(form, 'shippableSubtotal', 0),
  shippableTaxAmount: form => getValue(form, 'shippableTaxAmount', 0),
  paid: form => getValue(form, 'paid', 0),
  balanceLeft: form => getValue(form, 'balanceLeft', 0),
  change: form => getValue(form, 'change', 0),
  paymentsList: form => getValue(form, 'payments', emptyList)
}

export default memo(withContext(contextObj)(InvoiceSummary))
