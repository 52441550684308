import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'division'

export const readDivision = args => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/read`, options)
}

export const updateDivision = ({ dataId, properties }) => {
  const options = createApiOptions({
    body: { dataId, properties },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/update`, options)
}

export const deleteDivision = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/delete`, options)
}

export const createDivision = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/create`, options)
}
