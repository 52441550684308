import React from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { emptyList, getValue, plainDeepEqual, toCamelCase } from 'utils'

import { required } from 'ddiForm/validations'

const fieldIsRequired = memoize((validationErrors = [], property) => {
  return (
    (validationErrors &&
      Array.isArray(validationErrors) &&
      validationErrors?.length &&
      validationErrors?.find(
        x =>
          x?.property === property ||
          (x?.property && toCamelCase(x?.property) === property)
      )) ||
    false
  )
}, plainDeepEqual)

const ValidatedField = props => {
  const { children } = props
  let { validationErrors } = props
  validationErrors = validationErrors?.toJS ? validationErrors.toJS() : []
  const { propertyName, wrapperStyle = {} } = children.props
  return React.cloneElement(children, {
    wrapperStyle: { ...wrapperStyle, position: 'relative' },
    validate: fieldIsRequired(validationErrors, propertyName)
      ? [required]
      : null
  })
}

export default withContext({
  validationErrors: form => getValue(form, 'validationErrors', emptyList)
})(ValidatedField)
