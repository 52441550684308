/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const getCorporateFieldUpdatesMeta = ({ route }) => {
  const options = createApiOptions({
    method: 'post',
    body: {}
  })
  return callFetch(
    `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/meta`,
    options
  )
}

export const readCorporateFieldUpdatesData = ({
  dataId,
  route,
  templateKey
}) => {
  const options = createApiOptions({
    method: 'post',
    body: templateKey
      ? {
          templateKey
        }
      : {
          dataId
        }
  })

  return templateKey
    ? callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/template/read`,
        options
      )
    : callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/read`,
        options
      )
}

export const editCorporateFieldUpdatesData = ({
  dataId,
  route,
  templateKey
}) => {
  const options = createApiOptions({
    method: 'post',
    body: templateKey
      ? {
          templateKey
        }
      : {
          dataId
        }
  })
  return templateKey
    ? callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/template/editlock`,
        options
      )
    : callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/editlock`,
        options
      )
}

export const cancelEditCorporateFieldUpdatesData = ({
  dataId,
  route,
  templateKey
}) => {
  const options = createApiOptions({
    method: 'post',
    body: templateKey
      ? {
          templateKey
        }
      : {
          dataId
        }
  })
  return templateKey
    ? callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/template/canceledit`,
        options
      )
    : callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/canceledit`,
        options
      )
}

export const saveCoprorateFieldUpdatesData = ({
  dataId,
  properties,
  route,
  templateKey
}) => {
  const options = createApiOptions({
    method: 'post',
    body: templateKey
      ? {
          templateKey,
          properties
        }
      : {
          dataId,
          properties
        }
  })

  return templateKey
    ? callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/template/update`,
        options
      )
    : callFetch(
        `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/update`,
        options
      )
}

export const closeCorporateFieldUpdatesModal = ({ route }) => {
  const options = createApiOptions({
    method: 'post',
    body: {}
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/corporatefieldupdates/${route}/close`,
    options
  )
}
