import { getIn, is } from 'utils'

export const mapCustomFieldsData = (response, customFields = []) => {
  const res = response

  if (is.array(customFields) && customFields.length) {

      for(let i = 0; i < customFields.length; i++) {
        const d = customFields[i]
        res[`customFields-${d.fieldNumber}`] = d.value
      }
  }

  return res
}

export const mapCustomFieldsResponse = ({ response, tabIdss = [], formState, groupNames }) => {

  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (next === 'customFields') {
        for(const prop in response[next]) {
          if (prop === 'customFieldsData') {
            acc = mapCustomFieldsData(acc, response[next][prop])
          }

          acc[prop] = response[next][prop]
        }
      } else {
        acc[next] = response[next]
      }

      return acc
    }, {})

    res = tempResponse
  }

  return res

}

export const transformCustomFieldsData = (payload, formState) => {

  const { properties = {}, ...rest } = payload
  const hasCustomFields = Object.keys(properties).some(x => x.includes('customFields'))

  let res
  if (properties && Object.keys(properties).length && hasCustomFields) {

    res = {
      ...rest,
      properties: {
        ...properties,
        customFieldsData: Object.keys(properties).reduce((acc, next) => {
          if (next.includes('customFields')) {
            const dataId = getIn(formState, `fields.${next}.meta.dataId`) || next
            acc = [
              ...acc,
              {
                dataId,
                value: properties[next]
              }
            ]
          }
          return acc
        }, [])
      }
    }
  } else {
    res = { ...payload }
  }


  return res
}

export const checkIfCustomFieldsHidden = form => {
  const formState = form.toJS ? form.toJS() : form

  return !formState?.meta?.customFields?.hasCustomFields
}
