/* eslint jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { is } from 'utils'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import NumberSpinner from 'ddiForm/NumberSpinner'

export class CustomerStockMinimumsModal extends Component {
  static propTypes = { data: PropTypes.object.isRequired }

  constructor(props) {
    super(props)

    this.state = {
      maximumQuantity: props.data.responseData.maximumQuantity
        ? props.data.responseData.maximumQuantity
        : null,
      minimumQuantity: props.data.responseData.minimumQuantity
        ? props.data.responseData.minimumQuantity
        : null
    }
  }

  componentDidMount() {
    if (this.minimumQuantity && this.minimumQuantity.focus) {
      this.minimumQuantity.focus()
    }
  }

  onChange = id => event => {
    this.setState({ [id]: event.target.value, isDirty: true })
  }

  // validateInput = event => {
  //   console.log('validateInput', event)
  //   return (event.charCode === 8 || event.charCode === 0 || event.charCode === 13) ? null : event.charCode >= 48 && event.charCode <= 57
  // }

  onInputFocus = (e, type) => {
    if (this[type] && this[type].select) {
      this[type].select()
    }
  }

  updateValue = (input, value) => this.setState({ [input]: value })

  render() {
    const {
      dataId,
      uomId,
      customer,
      shipTo,
      product,
      uom
    } = this.props.data.responseData

    const { minimumQuantity, maximumQuantity } = this.state

    const tableCellStyle = { fontSize: '1.2rem' }

    return (
      <div>
        {/* {JSON.stringify(this.props.data.responseData)} */}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Customer:</TableCell>
              <TableCell>
                <strong>{customer}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ship To:</TableCell>
              <TableCell>
                <strong>{shipTo}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Product:</TableCell>
              <TableCell>
                <strong>{product}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>UOM:</TableCell>
              <TableCell>
                <strong>{uom}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div
          className="quantity-spinner-wrapper"
          style={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '15px 0'
          }}
        >
          <NumberSpinner
            isField={false}
            initialValue={minimumQuantity}
            value={minimumQuantity}
            step={1.0}
            min={0.0}
            updateValueCallback={val =>
              this.updateValue('minimumQuantity', val)
            }
            label="Minimum Quantity:"
            inputProps={{
              noDecimalLimit: true
            }}
            errorText={
              is.number(maximumQuantity) && minimumQuantity > maximumQuantity
                ? 'Minimum Quantity cannot be greater than Maxium Quantity'
                : ''
            }
          />
          <NumberSpinner
            isField={false}
            initialValue={maximumQuantity}
            value={maximumQuantity}
            step={1.0}
            min={0.0}
            updateValueCallback={val =>
              this.updateValue('maximumQuantity', val)
            }
            label="Maximum Quantity:"
            inputProps={{
              noDecimalLimit: true
            }}
            errorText={
              is.number(maximumQuantity) && minimumQuantity > maximumQuantity
                ? 'Maximum Quantity must be greater than Minimum Quantity'
                : ''
            }
          />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(CustomerStockMinimumsModal)
