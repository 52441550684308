/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIn, empty, emptyList } from 'utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { Paper } from '@material-ui/core'
import {
  DDIDatePicker,
  DDIIndexSearch,
  DDITextField,
  DDISelectField,
  DDIToggle
} from 'ddiForm/wrapped'
import { maxValue } from 'ddiForm/validations'

import './styles/change-invoice-modal.scss'

class ChangeInvoiceModal extends Component {
  render() {
    const {
      arPeriodsList,
      canChangePeriod,
      orderTypeId,
      orderTypeDescription,
      jobId,
      jobDescription,
      salesmanId,
      salesmanDescription,
      searchMetaMap
    } = this.props

    const arPeriods = arPeriodsList?.toJS ? arPeriodsList.toJS() : []
    const searchMeta = searchMetaMap?.toJS
      ? searchMetaMap.toJS()
      : {
          salesmanId,
          orderTypeId,
          jobId
        }

    return (
      <div className="change-invoice-modal-outer-wrapper">
        <Paper square style={{ padding: '10px 0', marginBottom: 20 }}>
          <div className="change-invoice-modal-inner-wrapper">
            <div className="column left-column">
              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Invoice Date</span>
                <div className="input">
                  <DDIDatePicker
                    propertyName="changeInvoice.invoiceDate"
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Due Date</span>
                <div className="input">
                  <DDIDatePicker
                    propertyName="changeInvoice.dueDate"
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">
                  Discount Amount
                </span>
                <div className="input">
                  <DDITextField
                    propertyName="changeInvoice.discountAmount"
                    mask="numeric"
                    validate={maxValue(999999999.99)}
                    inputProps={{
                      max: 999999999.99,
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      prefix: '$'
                    }}
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Customer PO</span>
                <div className="input">
                  <DDITextField
                    propertyName="changeInvoice.poNumber"
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Job ID</span>
                <div className="input">
                  <DDIIndexSearch
                    propertyName="changeInvoice.jobId"
                    meta={searchMeta?.jobId ? searchMeta.jobId : {}}
                    displayDescription
                    description={jobDescription}
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <AssociatedFieldsWrapper
                style={{ width: 260, float: 'none', clear: 'none' }}
              >
                <DDIToggle
                  propertyName="changeInvoice.suppressFromStatements"
                  label="Suppress From Statements"
                  preventAutoDisable
                  disableDoubleClickUnlock
                />
              </AssociatedFieldsWrapper>
            </div>
            <div className="column">
              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Ship Date</span>
                <div className="input">
                  <DDIDatePicker
                    propertyName="changeInvoice.shipDate"
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">
                  Discount Date
                </span>
                <div className="input">
                  <DDIDatePicker
                    propertyName="changeInvoice.discountDate"
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Freight Cost</span>
                <div className="input">
                  <DDITextField
                    propertyName="changeInvoice.freightCost"
                    mask="numeric"
                    validate={maxValue(999999999.99)}
                    inputProps={{
                      max: 999999999.99,
                      decimalScale: 2,
                      fixedDecimalScale: true,
                      prefix: '$'
                    }}
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Order Type</span>
                <div className="input">
                  <DDIIndexSearch
                    propertyName="changeInvoice.orderTypeId"
                    meta={searchMeta?.orderTypeId ? searchMeta.orderTypeId : {}}
                    displayDescription
                    description={orderTypeDescription}
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">Salesman</span>
                <div className="input">
                  <DDIIndexSearch
                    propertyName="changeInvoice.salesmanId"
                    meta={searchMeta?.salesmanId ? searchMeta.salesmanId : {}}
                    displayDescription
                    description={salesmanDescription}
                    preventAutoDisable
                    disableDoubleClickUnlock
                  />
                </div>
              </div>

              <div className="input-wrapper">
                <span className="change-invoice-modal-label">
                  Post to Period
                </span>
                <div className="input">
                  <DDISelectField
                    propertyName="changeInvoice.sjPeriod"
                    values={arPeriods}
                    selectValue="dataId"
                    selectDescription="description"
                    preventAutoDisable
                    disabled={!canChangePeriod}
                    disableDoubleClickUnlock
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <div className="change-invoice-message-wrapper">
          <DDITextField
            label="Invoice Message"
            propertyName="changeInvoice.invoiceMessage"
            multiline
            preventAutoDisable
            variant="outlined"
            rows={4}
            disableDoubleClickUnlock
          />
        </div>
      </div>
    )
  }
}

const mapState = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const orderTypeId = getIn(formState, 'fields.changeInvoice.orderTypeId.value')
  const orderTypeDescription =
    getIn(formState, 'fields.changeInvoice.orderTypeId.description') || ''

  const jobId = getIn(formState, 'fields.changeInvoice.jobId.value')
  const jobDescription =
    getIn(formState, 'fields.changeInvoice.jobId.description') || ''

  const salesmanId = getIn(formState, 'fields.changeInvoice.salesmanId.value')
  const salesmanDescription =
    getIn(formState, 'fields.changeInvoice.salesmanId.description') || ''

  const searchMetaMap =
    getIn(formState, 'meta.changeInvoiceFields.searchFields') || empty
  const arPeriodsList =
    getIn(formState, 'values.changeInvoice.arPeriods') || emptyList

  // const orderTypeDescription = getIn(formState, 'values.changeInvoice.orderTypeDescription')
  const canChangePeriod =
    getIn(formState, 'values.changeInvoice.canChangePeriod') || false

  return {
    orderTypeId,
    orderTypeDescription,
    jobId,
    jobDescription,
    salesmanId,
    salesmanDescription,
    searchMetaMap,
    arPeriodsList,
    canChangePeriod
  }
}

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(ChangeInvoiceModal)
