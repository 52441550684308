import React from 'react'

import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell
} from '@material-ui/core'

const headerCellStyle = {
  background: 'rgb(245, 247, 247)',
  color: 'rgba(0, 0, 0, 0.54)'
}

const BackorderDataTable = ({
  quantityBO,
  quantityBOComm,
  quantityBOUncomm
}) => {
  return (
    <div style={{ border: '1px solid #c9c9c9' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={headerCellStyle} align="right">
              Quant B/O
            </TableCell>
            <TableCell style={headerCellStyle} align="right">
              Quant B/O Comm
            </TableCell>
            <TableCell style={headerCellStyle} align="right">
              Quant B/O Uncomm
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{ backgroundColor: '#fff' }}>
            <TableCell align="right">{quantityBO}</TableCell>
            <TableCell align="right">{quantityBOComm}</TableCell>
            <TableCell align="right">{quantityBOUncomm}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default BackorderDataTable
