import React from 'react'
import memoize from 'memoize-one'
// import PropTypes from 'prop-types'
import Grid from 'grid'
// import { GridField } from 'ddiForm'
import { dateFilterComparator, formatDateFields, formatNumber } from 'utils'
import { headerStyle, setGridHeight } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

import WarehouseTooltipCell from './WarehouseTooltipCell'

const getRowNodeId = data => {
  const { whse, type, reference } = data
  return `${whse} - ${type} - ${reference}`
}

export const dynamicColumnDefs = memoize((isMobile = false) => {
  const colDefs = [
    {
      cellRendererFramework: isMobile ? null : WarehouseTooltipCell,
      field: 'whse',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Whse',
      linkTo: 'warehouse'
    },
    {
      field: 'type',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Type'
    },
    {
      field: 'reference',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Reference'
    },
    {
      cellClass: 'text-center align-center',
      field: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'text-center',
      headerName: 'Date',
      valueFormatter: params => formatDateFields(params)
    },
    {
      cellStyle: { textAlign: 'right' },
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Quantity',
      valueGetter: ({ data }) => data.quantity
    },
    {
      field: 'uom.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      tooltipField: 'uom.tooltip',
      headerName: 'UOM'
    },
    {
      cellStyle: params =>
        params.value < 0
          ? { color: 'red', textAlign: 'right' }
          : { textAlign: 'right' },
      field: 'cost',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Cost',
      valueFormatter: ({ value }) => formatNumber(value, '$0,0.0000')
    },
    {
      cellStyle: params =>
        params.value < 0
          ? { color: 'red', textAlign: 'right' }
          : { textAlign: 'right' },
      field: 'extCost',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Ext Cost',
      valueFormatter: ({ value }) => formatNumber(value, '$0,0.0000')
      // width: 120,
      // resizable: true
    }
  ]

  return colDefs
})

export const FifoCostModalGrid = props => {
  const {
    data: { isMobile, rowData }
  } = props

  function postProcessPopup(params) {
    // NOTE: to show the filter menu over the modal - LL
    if (params.type !== 'columnMenu') {
      return
    }
    const { ePopup } = params
    ePopup.style.zIndex = 1300
  }

  return (
    <div
      style={{
        display: 'flex',
        // flex: '0.1 1 120rem',
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        // margin: '0 auto',
        // maxWidth: '100%'
        width: '100%'
      }}
    >
      <div style={{ flex: '1' }}>
        <Grid
          rowData={rowData}
          getRowNodeId={getRowNodeId}
          title="Cost Layers"
          height={setGridHeight(rowData, 85)}
          headerStyle={headerStyle}
          columnDefs={dynamicColumnDefs(isMobile)}
          autoSize
          postProcessPopup={postProcessPopup}
          rowSelection="multiple"
          enableSorting
          preventAutoDisable
          fullWidth
          width="100%"
          defaultColDef={{
            resizable: true,
            width: 50
          }}
          suppressHorizontalScroll={false}
        />
      </div>
    </div>
  )
}

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
})(FifoCostModalGrid)
