import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactHighcharts from 'react-highcharts'
import {
  formatNumber,
  getContainerDimensions,
  numToDollar,
  strToUpperCase,
  toCamelCase
} from 'utils'
import Highcharts from 'highcharts' // eslint-disable-line
import highcharts3D from 'highcharts/highcharts-3d' // eslint-disable-line
import exporting from 'highcharts/modules/exporting' // eslint-disable-line
import moment from 'moment'
import highchartsTheme from '../../theme/highchartsTheme'

// const ReactHighcharts = RHC.withHighcharts(Highcharts)
exporting(ReactHighcharts.Highcharts)
ReactHighcharts.Highcharts.setOptions(highchartsTheme)
highcharts3D(ReactHighcharts.Highcharts)

/* context menu */
import shortid from 'shortid'
import { Icon } from '@material-ui/core'
// import { Menu, MenuItem, MenuTrigger } from 'react-Menu'

import {
  Menu,
  Item,
  IconFont,
  Separator,
  MenuProvider
} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'

function getChartData(chartSeries) {
  const dataPoint = Array.isArray(chartSeries) ? chartSeries[0] : {}
  const dataPointKeys = Object.keys(dataPoint)

  const accumulator = dataPointKeys.reduce((acc, next) => {
    acc[next] = []
    return acc
  }, {})

  const chartData = chartSeries.reduce((acc, next) => {
    for (const [k, v] of Object.entries(next)) {
      acc[k] = acc[k].concat(v)
    }
    return acc
  }, accumulator)

  let series = []
  for (const [k, v] of Object.entries(chartData)) {
    series = series.concat({
      data: v,
      name: strToUpperCase(k).replace(/_/gi, '')
    })
  }

  const chartCategories = series.slice(0, 1)
  const seriesData = series.slice(1, series.length)
  const minMax = seriesData.reduce(
    (acc, next) => {
      /* get the max and min numbers */
      acc.max =
        Math.max.apply(null, next.data) > acc.max
          ? Math.max.apply(null, next.data)
          : acc.max
      acc.min =
        Math.min.apply(null, next.data) < acc.min
          ? Math.min.apply(null, next.data)
          : acc.min
      return acc
    },
    { max: 0, min: 0 }
  )

  return {
    chartCategories,
    minMax,
    seriesData
  }
}

const percentageBasedCharts = [
  'Profit Dollars Trend',
  'Gross Margin Trend',
  'Fill Rate to Date'
]

/* Each of these 4 charts neeed special data manipulation */
const columnLineCharts = {
  'Gross Margin Trend': { displayName: 'Invoice Count', key: 'InvoicesNotRaw' },
  'Profit Dollars Trend': {
    displayName: 'Invoice Count',
    key: 'InvoicesNotRaw'
  },
  'Sales Dollars Trend': {
    displayName: 'Invoice Count',
    key: 'InvoicesNotRaw'
  },
  Turns: { displayName: 'Yearly Average', key: 'Yearly Average' }
}

const generateColumnLineChartData = (data = [], lineKey = '', chart) => {
  const clChartData = data.reduce((acc, next) => {
    if (next.name === lineKey) {
      acc = acc.concat({
        ...next,
        data: next.data.reduce(
          (a, b) => (a = a.concat(parseFloat(b.toFixed(2), 10))),
          []
        ),
        name: columnLineCharts[chart].displayName,
        type: 'spline'
      })
    } else {
      acc = acc.concat({
        ...next,
        data: next.data.reduce(
          (a, b) => (a = a.concat(parseFloat(b.toFixed(2), 10))),
          []
        ),
        type: 'column',
        yAxis: chart === 'Turns' ? 0 : 1
      })
    }
    return acc
  }, [])

  return clChartData
}

class HighChart extends PureComponent {
  static propTypes = {
    chartCategories: PropTypes.array,
    chartHeight: PropTypes.number,
    chartSeries: PropTypes.array,
    chartTitleText: PropTypes.string,
    chartTooltipValuePrefix: PropTypes.string,
    chartType: PropTypes.string,
    chartWidth: PropTypes.number,
    dataLabelsEnabled: PropTypes.bool,
    isDashboardChart: PropTypes.bool,
    labelsEnabled: PropTypes.bool,
    legendEnabled: PropTypes.bool,
    markerEnabled: PropTypes.bool,
    pieChartSecondaryLabel: PropTypes.string,
    showExportButton: PropTypes.bool,
    tooltipEnabled: PropTypes.bool
  }

  static defaultProps = {
    chartCategories: [],
    chartHeight: 200,
    chartSeries: [],
    chartTitleText: '',
    chartTooltipValuePrefix: '$',
    chartType: '',
    chartWidth: 200,
    dataLabelsEnabled: true,
    isDashboardChart: false,
    labelsEnabled: true,
    legendEnabled: true,
    markerEnabled: true,
    pieChartSecondaryLabel: 'Stock Value:',
    showExportButton: false,
    tooltipEnabled: true
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   /* only update the chart if the actual chart data has been updated */
  //   if (plainDeepEqual(nextProps.chartSeries, this.props.chartSeries)) {
  //     return false
  //   }
  //   return true
  // }

  // constructor(props) {
  //   super(props)
  //   console.log('HighCharts', this.props)
  // }

  /* start chart configurations: add new chart types here */
  pieChartConfig = () => {
    const {
      chartSeries,
      chartHeight,
      chartWidth,
      dataLabelsEnabled,
      pieChartSecondaryLabel,
      showExportButton,
      tooltipEnabled
    } = this.props

    let pieData = []
    for (const [k, v] of Object.entries(chartSeries)) {
      /* we need to account for some randomly shaped pie data */
      pieData = pieData.concat({
        name: v.rank ? v.rank : v.type ? v.type : `Category ${k}`, // eslint-disable-line
        y: v.value
      })
    }

    const config = {
      chart: {
        height: chartHeight,
        options3d: {
          alpha: 45,
          beta: 0,
          enabled: true
        },
        spacingTop: -20,
        type: 'pie',
        width: chartWidth
      },
      exporting: {
        buttons: { contextButton: { enabled: showExportButton } },
        enabled: true
      },
      legend: {
        enabled: true,
        symbolRadius: 2
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: dataLabelsEnabled,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          depth: 35,
          showInLegend: true
        }
      },
      series: [
        { data: pieData }
      ],
      tooltip: {
        enabled: tooltipEnabled,
        shared: true,
        formatter() {
          return `<strong>${this.key}:</strong> ${this.percentage.toFixed(
            1
          )}% <strong>${pieChartSecondaryLabel}</strong> ${numToDollar(this.y)}`
        },
        pointFormat: '<b>{point.percentage:.1f}%</b>',
        useHTML: true
      }
    }
    return config
  }

  // GOOD CODE RIGH CHEA
  lineChartConfig = () => {
    const {
      chartHeight,
      chartSeries,
      chartTitleText,
      chartWidth,
      legendEnabled,
      labelsEnabled,
      markerEnabled,
      showExportButton,
      tooltipEnabled
    } = this.props
    const chartData = getChartData(chartSeries)

    const config = {
      chart: {
        height: chartHeight,
        width: chartWidth
      },
      exporting: {
        buttons: { contextButton: { enabled: showExportButton } },
        enabled: true
      },
      legend: { enabled: legendEnabled },
      plotOptions: { series: { marker: { enabled: markerEnabled } } },
      series: chartData.seriesData,
      tooltip: {
        enabled: tooltipEnabled,
        formatter() {
          return `${numToDollar(this.y)} in ${this.x}`
        },
        valuePrefix: '$'
      },
      xAxis: {
        categories: chartData.chartCategories[0].data.map((item, i) => {
          if (item.match(/00:00:00/)) {
            return moment(new Date(item)).format('M/D/YY')
          }
          return item
        }),
        labels: { enabled: labelsEnabled },
        tickLength: labelsEnabled ? 10 : 0
      },
      yAxis: {
        labels: {
          enabled: labelsEnabled,
          formatter() {
            return numToDollar(this.value)
          }
        },
        max:
          chartData.minMax.max === 0
            ? 1
            : Math.round(chartData.minMax.max * 1.1),
        min: chartData.minMax.min,
        plotLines: [
          {
            color: '#808080',
            value: 0,
            width: 1
          }
        ]
      }
    }
    return config
  }

  areaSplineChartConfig = () => {
    const {
      noDataMessage,
      chartHeight,
      chartSeries,
      chartTitleText,
      chartTooltipValuePrefix,
      chartWidth,
      showExportButton
    } = this.props

    const chartData = getChartData(chartSeries)

    /* Sales/Inventory chart has API data that is provided, but not rendered */
    const formattedChartSeries =
      chartTitleText === 'Sales/Inventory' || chartTitleText === 'Sales/AR'
        ? chartData.seriesData.reduce((acc, next) => {
          if (next.name !== 'SI Ratio' && next.name !== 'AR Ratio') {
            acc = acc.concat({
              data: next.data,
              name: next.name
            })
          }
          return acc
        }, [])
        : chartData.seriesData

    const config = {
      chart: {
        height: chartHeight,
        type: 'areaspline',
        width: chartWidth
      },
      exporting: {
        buttons: { contextButton: { enabled: showExportButton } },
        enabled: true
      },
      series: formattedChartSeries,
      tooltip: {
        shared: true,
        valuePrefix: chartTooltipValuePrefix
      },
      xAxis: {
        categories: chartData.chartCategories[0].data,
        max: chartData.chartCategories[0].data.length - 1.5,
        min: 0.5
      },
      yAxis: {
        labels: {
          formatter() {
            return formatNumber(this.value)
          }
        }
      }
    }

    return config
  }

  stackedLineChartConfig = () => ({
    chart: {
      height: this.props.chartHeight,
      type: 'area',
      width: this.props.chartWidth
    },
    exporting: {
      buttons: { contextButton: { enabled: this.props.showExportButton } },
      enabled: true
    },
    plotOptions: {
      area: {
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineColor: '#666666',
          lineWidth: 1
        },
        stacking: 'normal'
      }
    },
    series: this.props.chartSeries,
    title: { text: this.props.chartTitleText },
    tooltip: {
      valuePrefix: '$',
      valueSuffix: 'million'
    },
    xAxis: {
      categories: this.props.chartCategories,
      max: 10.5,
      min: 0.5
    },
    yAxis: {
      plotLines: [
        {
          color: '#808080',
          value: 0,
          width: 1
        }
      ],
      title: { text: 'Profit' }
    }
  })

  columnLineChartConfig = () => {
    const {
 chartSeries, chartTitleText, showExportButton
} = this.props
    const chartData = getChartData(chartSeries)

    const formattedChartSeries =
      Object.keys(columnLineCharts).indexOf(chartTitleText) !== -1
        ? generateColumnLineChartData(
          chartData.seriesData,
          columnLineCharts[chartTitleText].key,
          chartTitleText
        )
        : chartData.seriesData

    const config = {
      chart: {
        height: this.props.chartHeight,
        // options3d: {
        //   alpha: 15,
        //   beta: 15,
        //   depth: 30,
        //   enabled: true,
        //   viewDistance: 50
        // },
        width: this.props.chartWidth,
        zoomType: 'xy'
      },
      exporting: {
        buttons: { contextButton: { enabled: showExportButton } },
        enabled: true
      },
      series: formattedChartSeries,
      tooltip: { shared: true },
      xAxis: [
        {
          categories: chartData.chartCategories[0].data,
          crosshair: true
        }
      ],
      yAxis:
        chartTitleText === 'Turns'
          ? [
            { labels: { format: '{value}' } }
          ]
          : [
            {
              labels: {
                formatter() {
                  return formatNumber(this.value, '0,0')
                }
              }
            },
            {
              labels: {
                formatter() {
                  if (chartTitleText === 'Gross Margin Trend') {
                    return `${this.value}%`
                  }
                  return formatNumber(this.value)
                }
              },
              opposite: true
            }
          ]
    }
    return config
  }

  columnChartConfig = () => {
    const {
      chartHeight,
      chartSeries,
      chartTitleText,
      chartWidth,
      showExportButton,
      disableLegend = false
    } = this.props
    const chartData = getChartData(chartSeries)
    const chartMax =
      chartData.minMax.max === 0 ? 1 : Math.round(chartData.minMax.max * 1.1)
    const isPctChart = percentageBasedCharts.indexOf(chartTitleText) !== -1

    /* something changed in the "Fill Rate to Date" data */
    const formattedChartData =
      chartTitleText === 'Fill Rate to Date'
        ? chartSeries.reduce(
          (acc, next) => {
            const key = next['time Period']
            const val = next['fill Rate'] ? next['fill Rate'] * 100 : 0
            acc.data.push([key, val])
            return acc
          },
          { name: 'Fill Rate to Date', data: [] }
        )
        : chartData.seriesData

    return {
      chart: {
        height: chartHeight,
        // options3d: {
        //   alpha: 15,
        //   beta: 15,
        //   depth: 50,
        //   enabled: true
        // },
        spacingLeft: 0,
        type: 'column',
        width: chartWidth
      },
      exporting: {
        buttons: { contextButton: { enabled: showExportButton } },
        enabled: true
      },
      legend: { enabled: !disableLegend },
      plotOptions: { column: { depth: 25 } },
      // series: chartData.seriesData,
      series:
        chartTitleText === 'Fill Rate to Date'
          ? [formattedChartData]
          : formattedChartData,
      tooltip: {
        // pointFormat: isPctChart ?
        // (<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{parseFloat(point.y, 10).toFixed()}</b><br/>) :
        // (<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>),
        valuePrefix: isPctChart ? '' : '$',
        valueSuffix: isPctChart ? '%' : '',
        formatter() {
          console.log('formatter', this)
          return isPctChart
            ? `<span style="color: ${this.color}">\u25CF</span> <strong>${
              this.key
            }:</strong> ${parseFloat(this.y, 10).toFixed(2)}%`
            : `<span style="color: ${this.color}">\u25CF</span> <strong>${
              this.key
            }:</strong> ${numToDollar(this.y)}`
        }
      },
      xAxis: { categories: chartData.chartCategories[0].data },
      yAxis: {
        labels: {
          formatter() {
            return isPctChart ? `${this.value}%` : numToDollar(this.value, 'en-US', 'USD', 0, 0)
          }
        },
        max: isPctChart ? 100 : chartMax,
        min: chartData.minMax.min
      }
    }
  }

  /* end chart configurations */

  createChartConfig = chartType => {
    /* overrides where API is sending incorrectly named chart types */
    const { chartTitleText } = this.props
    if (
      chartTitleText === 'Inventory Trend' ||
      chartTitleText === 'Sales/Profit to Date' ||
      chartTitleText === "This Week's Sales"
    ) {
      chartType = 'linechart'
    }
    if (chartTitleText === 'Sales/Inventory' || chartTitleText === 'Sales/AR') {
      chartType = 'areaspline'
    }
    if (Object.keys(columnLineCharts).indexOf(chartTitleText) !== -1) {
      chartType = 'columnLine'
    }

    const configs = {
      areaspline: () => this.areaSplineChartConfig(),
      columnLine: () => this.columnLineChartConfig(),
      columnchart: () => this.columnChartConfig(),
      default: () => null,
      linechart: () => this.lineChartConfig(),
      piechart: () => this.pieChartConfig(),
      stackedLine: () => this.stackedLineChartConfig()
    }
    return (configs[chartType] || configs.default)()
  }

  MenuAction = (targetNode, ref, data) => this.props.MenuAction()

  render() {
    const {
      noDataMessage,
      chartType,
      isDashboardChart,
      chartTitleText
    } = this.props
    const config = noDataMessage ? null : this.createChartConfig(chartType)
    const MenuId = toCamelCase(chartTitleText)

    if (config === null) {
      return <p>There is no data</p>
    }

    return isDashboardChart ? (
      <span>
        <MenuProvider id={MenuId}>
          <div>
            <ReactHighcharts config={config} ref={c => (this.chart = c)} />
          </div>
        </MenuProvider>
        <Menu ref={el => (this.Menu = el)} id={MenuId}>
          <Item
            onClick={(targetNode, ref, data) =>
              this.MenuAction(targetNode, ref, data)
            }
          >
            <IconFont className="material-icons" style={{ color: '#517b9c' }}>
              &#xE89E;
            </IconFont>
            View Details
          </Item>
        </Menu>
      </span>
    ) : (
      <ReactHighcharts config={config} ref={c => (this.chart = c)} />
    )
  }
}

export default HighChart
