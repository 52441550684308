import { getIn, mapColumnHeaders } from 'utils'
import {
  showQuickEntity,
  findPrev as findPrevAction,
  findNext as findNextAction
} from 'ddiForm/actions'
import TextFieldEditorCell from 'pages/SalesOrder/components/ManualGrid/TextFieldEditorCell'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { isThisISOWeek } from 'date-fns'
import { CLOSE_SEARCH, CLOSE_FILTERS_GRID } from './constants'
import {
  blur,
  resetFilters as resetFiltersAction,
  toggleFilter as toggleFilterAction,
  clearSearch,
  foundInvalidValues,
  clearForm
} from './actions'

import PasswordProtected from '../SearchBase/components/PasswordProtected'
import GridCell from './GridCell'
import baseBehaviors from '../baseBehaviors'

const KEY_UP = 38
const KEY_DOWN = 40
const KEY_LEFT = 37
const KEY_RIGHT = 39
const ENTER = 13
const HOME = 36
const END = 35

const regex = /[^a-zA-Z0-9-.]+/g

const trimNonWord = (str = '') => str.replace(regex, '')

const toLower = (str = '') => str.toLowerCase()

function tabToNext(event, prev) {
  event.preventDefault()
  event.stopPropagation()
  this.props.api.stopEditing()
  this.props.api[prev ? 'tabToPreviousCell' : 'tabToNextCell']()
}

function handleSearch(val) {
  const {
    exactMatchSearch,
    indexSearchType,
    propertyName,
    moreInfo,
    includeParent,
    parentId
  } = this.props
  if (this._isMounted) {
    this.setState(
      {
        isOpen: false,
        value: val,
        lastSearch: val,
        tab: true
      },
      async () => {
        try {
          const p = await exactMatchSearch({
            indexSearchType: indexSearchType || this.props.meta.searchType,
            keyword: val,
            propertyName,
            moreInfo,
            includeParent,
            parentId,
            allowInvalidValues:
              this.props.meta && this.props.meta.allowInvalidValues,
            parentType: this.props.parentType ? this.props.parentType : '',
            allowSpecialProducts: this.props.allowSpecialProducts,
            returnInactiveWarehouses:
              this?.props?.meta?.returnInactiveWarehouses || false,
            returnCustomerConsignmentWarehouses:
              this?.props?.meta?.returnCustomerConsignmentWarehouses || false,
            exactMatchOnly: this?.props?.meta?.exactMatchOnly || false
          })

          if (p.exactMatchResults) {
            if (this._isMounted) {
              /* RED ALERT: p.exactMatchResults.name disappeared today -- SVE 4/23/20 */
              const value =
                p.exactMatchResults.name || p.exactMatchResults.dataId
              this.setState(
                {
                  // value: p.exactMatchResults.name,
                  value,
                  description: p.exactMatchResults.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(
                    // p.exactMatchResults.name,
                    value,
                    true,
                    p.exactMatchResults
                  )
                  this.blur(this.state.value)
                }
              )
            }
          } else if (p.partialMatchResults) {
            // this.setPartialMatchResults(p.partialMatchResults)
            // debugger
            const {
              columnHeaders: columnDefs,
              filtered,
              pageCount,
              pageNumber,
              recordCount,
              results: rowData
            } = p.partialMatchResults
            await this.setPartialMatchResults({
              grid: {
                columnDefs: p.partialMatchResults.columnHeaders.map(
                  mapColumnHeaders
                ),
                meta: { filtered, pageCount, pageNumber, recordCount },
                rowData: rowData || []
              }
            })
          } else if (this._isMounted) {
            // debugger
            this.setState({
              value: '',
              isSet: false,
              isDirty: false,
              isOpen: false,
              tab: false
            })
          }
        } catch (e) {
          // debugger
          console.log(this, e)
          // debugger
          if (this.props.meta.allowInvalidValues) {
            this.handleInvalidValues({ ...e, value: val })
          } else if (this._isMounted) {
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      }
    )
  }
}

function setNavigationalSearch(response) {
  const newState = {
    description: response.description,
    value: response.name,
    isDirty: false,
    isSearching: false,
    isSet: true
  }
  if (this._isMounted) {
    this.setState(newState, () => {
      this.setField(response.name)
    })
  }
}
const filterMap = {
  customer: {
    default: 'Prospects',
    toggled: 'Customer'
  },
  product: {
    default: 'Non-stock',
    toggled: 'Stock'
  },
  contacts: {
    default: 'Prospects',
    toggled: 'All Contacts'
  }
  // shipTo: {
  //   default: 'Prospects',
  //   toggled: 'All Ship Tos'
  // }
}
function toggleFilterFn(val = true) {
  this.props.dispatch(
    toggleFilterAction(this.form, { propertyName: this.props.propertyName })
  )
  // const bool = true
  this.partialMatchSearch(this.state.value, null, null, val)
}

const selectNodeByIndex = (api, idx = 0) => {
  const node = api.getDisplayedRowAtIndex(idx)
  if (node && !node.isSelected()) {
    node.setSelected(true)
  }
}

export default {
  ...baseBehaviors,
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },

  clearSearch() {
    if (this.cartMap) {
      this.cartMap = null
    }
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },

  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut: () => {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {},
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },

  dispatchSaveCart() {
    if (this?.cartMap && Reflect?.ownKeys(this.cartMap)?.length) {
      this.props.dispatch({
        type: 'SAVE_CART',
        payload: { cartMap: this.cartMap, api: this.props.api },
        meta: { form: this.props.form, thunk: true }
      })
    }
  },

  async findNext() {
    const { propertyName, recordName, indexSearchType, value } = this.props
    try {
      const a = await this.props.dispatch(
        findNextAction(this.form, {
          indexSearchType: indexSearchType || this.props.meta.searchType,
          recordName: recordName || value,
          propertyName
        })
      )
      setNavigationalSearch.call(this, a)
    } catch (e) {
      // debugger
    }
  },

  async findPrev() {
    const { propertyName, recordName, indexSearchType, value } = this.props
    try {
      const a = await this.props.dispatch(
        findPrevAction(this.form, {
          indexSearchType: indexSearchType || this.props.meta.searchType,
          recordName: recordName || value,
          propertyName
        })
      )
      setNavigationalSearch.call(this, a)
    } catch (e) {
      // debugger
    }
  },

  getButtonStyle() {
    let { buttonStyle } = this.props
    if (this.props.disabled) {
      buttonStyle = {
        ...buttonStyle,
        background: '#000',
        opacity: 0.12
      }
    }
    return buttonStyle
  },
  // FIX THIS
  // getDisplayValue() {
  //   if (this.props.displayDescription && !this.state.isFocused) {
  //     /* if the description has been overwitten by a prop (e.g. API update), overwrite the description state SVE -- 10/16/19 */
  //     // i have to look into this further. low level user access override, writer ?
  //     if (
  //       Object.hasOwnProperty.call(this.props, 'description') &&
  //       this.props.description !== this.state.description
  //     ) {
  //       if (this._isMounted) {
  //         // this.setState({
  //         //   description: this.props.description
  //         // })
  //       }
  //     }

  //     return this.state.description || ''
  //   }
  //   return this.state.value || ''
  // },

  getGridColumnDefs(defs) {
    const { isInquiryScreen, showCart } = this.props
    let colDefs = isInquiryScreen
      ? defs.map(x => ({
          ...x,
          menuTabs: [],
          filter: false
        }))
      : defs

    if (colDefs[0]) {
      colDefs[0] = {
        ...colDefs[0],
        cellRendererFramework: GridCell,
        cellRendererParams: {
          onCellSelected: this.onRowClicked,
          withTooltip: this.withTooltip,
          indexSearchType:
            this.props.indexSearchType || this.props.meta.searchType,
          propertyName: this.props.propertyName
        }
      }
    }
    if (showCart) {
      colDefs = [
        {
          field: 'cart',
          colId: 'cart',
          headerName: 'Cart',
          cellEditorFramework: TextFieldEditorCell,
          editable: true,
          singleClickEdit: true,
          onCellValueChanged: this.onCellValueChanged,
          cellStyle: { 'text-align': 'right', height: 24 },
          cellEditorParams: {
            allowNegative: false,
            propertyName: this.props.propertyName,
            decimalScale: 0,
            fixedDecimalScale: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            form: this.props.form,
            formatter: 'number',
            thousandSeparator: '',
            saveCart: this.saveCart,
            onCellKeyDown(event) {
              if (event.persist) event.persist()
              const { keyCode, altKey } = event
              if (this._isMounted) {
                if (keyCode === 13) {
                  event.preventDefault()
                  event.stopPropagation()
                } else if (event.keyCode === 40) {
                  // down
                  tabToNext.call(this, event)
                } else if (event.keyCode === 38) {
                  tabToNext.call(this, event, true)
                } else if (event.code === 'Tab') {
                  tabToNext.call(this, event)
                } else if (altKey && keyCode === 83) {
                  event.preventDefault()
                  event.stopPropagation()
                  if (this.props.saveCart) {
                    this.props.saveCart()
                  }
                } else {
                  this.setEdited()
                }
              }
            }
          }
        },
        ...colDefs
      ]
    }
    // console.log(colDefs, this, this.props)
    return colDefs
  },

  getRowNodeId(data = {}) {
    return data.recordName
  },

  getSearchFilters() {
    const { getSearchFilters, indexSearchType, propertyName } = this.props

    getSearchFilters({
      indexSearchType: indexSearchType || this.props.meta.searchType,
      propertyName
    })
  },

  async handleInvalidValues(e) {
    if (this._isMounted) {
      await this.setState({
        modalDisplayed: true,
        isSet: false
      })
    }
    // debugger
    try {
      await this.props.dispatch(
        foundInvalidValues(this.form, {
          propertyName: this.props.propertyName,
          ...e
        })
      )
      // debugger
      if (this._isMounted) {
        await this.setState({
          value: e.value,
          isSet: true,
          isDirty: false,
          modalDisplayed: false
        })
      }
      // debugger
      if (this._isMounted) {
        this.setField(e.value)
      }
      //
    } catch (e2) {
      // debugger
      console.log(e, e2)
      if (this._isMounted) {
        await this.setState({ modalDisplayed: false })
        if (this._isMounted) {
          this.partialMatchSearch(e.value)
        }
      }
    }
  },

  initialize() {
    if (this.props.onKeyDown) {
      this.onKeyDown = this.props.onKeyDown.bind(this)
      this.state = { ...this.state, modalDisplayed: false }
    }
    this.state.value = this.props.value || this.props.initialValue || ''
    if (this.props.indexSearchType === 'product') {
      this.state.popoverStyle = { width: 900 }
    }

    const fieldsRequirePassword = [
      'writerId',
      'pickedBy',
      'packedBy',
      'receivedBy'
    ]
    if (
      this.props.propertyName &&
      fieldsRequirePassword.includes(this.props.propertyName) &&
      this.props.requirePassword
    ) {
      this.DropDownComponent = PasswordProtected
      // this.state.popoverStyle = { width: 360 }
    }
    // this.state.popoverStyle = { width: 10000 }
  },

  navigateToNextCell(params) {
    const previousCell = params.previousCellPosition
    let suggestedNextCell = params.nextCellPosition
    let pageCount
    let pageNumber
    const mapping = {
      [KEY_DOWN]: () => {
        this.api.forEachNode(node => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true)
          }
        })

        if (suggestedNextCell) {
          // eslint-disable-next-line prefer-destructuring
          suggestedNextCell.column = this.api.columnController.getAllDisplayedColumns()[0]
          return suggestedNextCell
        }
        // paginate..
        if (
          this.state.dropDownProps &&
          this.state.dropDownProps.rowData &&
          this.state.dropDownProps.meta
        ) {
          ;({ pageCount, pageNumber } = this.state.dropDownProps.meta)
          if (pageNumber < pageCount) {
            this.paginationCb(pageNumber + 1, () => {
              if (this.api) {
                const node = this.api.getDisplayedRowAtIndex(0)
                if (node) {
                  setTimeout(() => {
                    const v = this.api.columnController.getAllDisplayedColumns()[0]
                    node.setSelected(true)
                    this.api.setFocusedCell(node.rowIndex, v.colId)
                  }, 0)
                }
              }
            })
          }
        }
        return null
      },
      [KEY_UP]: () => {
        // set selected cell on current cell - 1
        if (suggestedNextCell) {
          this.api.forEachNode(node => {
            if (previousCell.rowIndex - 1 === node.rowIndex) {
              node.setSelected(true)
            }
          })
          // eslint-disable-next-line prefer-destructuring
          suggestedNextCell.column = this.api.columnController.getAllDisplayedColumns()[0]
          return suggestedNextCell
        }
        if (
          this.state.dropDownProps &&
          this.state.dropDownProps.rowData &&
          this.state.dropDownProps.meta
        ) {
          ;({ pageCount, pageNumber } = this.state.dropDownProps.meta)
          if (pageNumber - 1 >= 1) {
            // debugger
            this.paginationCb(pageNumber - 1, () => {
              if (this.api) {
                const node = this.api.getDisplayedRowAtIndex(
                  this.api.getLastDisplayedRow()
                )
                if (node) {
                  setTimeout(() => {
                    const v = this.api.columnController.getAllDisplayedColumns()[0]
                    node.setSelected(true)
                    this.api.setFocusedCell(node.rowIndex, v.colId)
                  }, 0)
                }
              }
            })
          } else {
            // close the dropdown if you are on the first row and there's no previous page...
            this.onRequestClose()
          }
        }
        return null
      },

      [KEY_LEFT]: () => {
        // debugger
        const { nextCellPosition, previousCellPosition } = params
        this.api.forEachNode(node => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true)
          }
        })

        suggestedNextCell = nextCellPosition
          ? { ...nextCellPosition }
          : { ...previousCellPosition }
        // eslint-disable-next-line prefer-destructuring
        suggestedNextCell.column = this.api.columnController.getAllDisplayedColumns()[0]
        if (suggestedNextCell.rowIndex - 1 >= 0) {
          suggestedNextCell.rowIndex -= 1
          return suggestedNextCell
        }
        return null
      },
      [KEY_RIGHT]: () => {
        const { nextCellPosition, previousCellPosition } = params
        this.api.forEachNode(node => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true)
          }
        })

        suggestedNextCell = nextCellPosition
          ? { ...nextCellPosition }
          : { ...previousCellPosition }
        const rowCount = this.api.getDisplayedRowCount()
        // eslint-disable-next-line prefer-destructuring
        suggestedNextCell.column = this.api.columnController.getAllDisplayedColumns()[0]
        if (suggestedNextCell.rowIndex + 1 < rowCount) {
          suggestedNextCell.rowIndex += 1
          return suggestedNextCell
        }
        return null
      }
    }
    const fn = mapping[params.key]
    return fn ? fn() : null
  },

  async onBlur(event) {
    // debugger
    if (event && event.persist) event.persist()
    if (this.state.isOpen || this.props.isFetching) return
    if (this._isMounted) {
      await this.setState({ isFocused: false })
    }
    if (!this.state.isSet && this.state.isDirty && !this.state.modalDisplayed) {
      if (this._isMounted) {
        this.setState(
          {
            value: '',
            description: '',
            isDirty: false
          },
          () => this.blur(this.state.value)
        )
      }
    } else {
      this.blur(this.state.value)
    }
  },
  onFocus(event) {
    baseBehaviors.onFocus.call(this, event)

    const fieldsRequirePassword = [
      'writerId',
      'pickedBy',
      'packedBy',
      'receivedBy'
    ]
    if (
      this.props.propertyName &&
      fieldsRequirePassword.includes(this.props.propertyName) &&
      this.props.requirePassword
    ) {
      this.setState({
        isOpen: true,
        isFocused: false,
        dropDownProps: {
          onOkClick: this.onOkClick,
          onCancelClick: () => this.setState({ isOpen: false })
        },
        popoverStyle: { width: 360 },
        disableRestoreFocus: true
        // PaperProps: { style: { width: 360 } }
      })
    }
  },
  onCellEditingStopped(p) {
    this.api.removeEventListener('cellValueChanged', this.onCellEditingStopped)

    this.onCellValueChanged(p)
    this.dispatchSaveCart()
    this.onRequestClose()
  },
  onCellKeyDown(params) {
    const {
      event: { keyCode }
    } = params

    switch (keyCode) {
      case ENTER: {
        this.onRowClicked(params)
        break
      }
      case HOME: {
        selectNodeByIndex(this.api, 0)
        break
      }
      case END: {
        const idx = this.api.getLastDisplayedRow()
        selectNodeByIndex(this.api, idx)
        break
      }
      default: {
        //
      }
    }
  },
  onCellValueChanged(params) {
    if (params.newValue != null && params.newValue !== '') {
      this.cartMap = this.cartMap || {}
      this.cartMap[params.data.dataId] = params.newValue
    }
  },
  onCloseFiltersGrid() {
    if (this._isMounted) {
      this.setState({ mouseover: false }, () => {
        if (this.props.getFormState) {
          this.props.dispatch({
            type: CLOSE_FILTERS_GRID,
            payload: { propertyName: this.props.propertyName },
            meta: { form: this.form, reducer: 'IndexSearch' }
          })
        }
      })
    }
  },
  async onChange(event) {
    if (event && event.persist) event.persist()
    const v = event.target.value
    console.log(v)

    const allowInstantSearch =
      this.props.allowInstantSearch || this.props?.meta?.allowInstantSearch
    const minimumKeywordLength =
      typeof this.props.minimumKeywordLength === 'number'
        ? this.props.minimumKeywordLength
        : typeof this.props.meta?.minimumKeywordLength === 'number'
        ? this.props?.meta?.minimumKeywordLength
        : 2

    let emptySetFieldCalled = false
    if (this._isMounted) {
      if (!this.state.isDirty && this.state.isSet) {
        this.clearSearch()
        this.setField('', true)
        emptySetFieldCalled = true
      }
      await this.setState({
        isDirty: true,
        value: event.target.value,
        // lastSearch: event.target.value,
        isSet: false
      })
    }

    if (this.props.leader && this.props.hasRecord) {
      const f = await this.props.dispatch(clearForm(this.form))
      // this.props.dispatch(clearForm(this.form))
    }
    const { disableAdvancedParsing } = this.props
    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    console.log(v)
    if (
      v.includes(',') &&
      disableAdvancedParsing != null &&
      !disableAdvancedParsing
    ) {
      if (!this.advancedParsingActive) {
        this.advancedParsingActive = true
      }
      const valuesFromInput = v.split(',').map(trimNonWord)
      const valuesLength = valuesFromInput.length
      let quant
      let prod
      let price

      if (valuesLength === 2) {
        ;[quant, prod] = valuesFromInput
        this.advancedParsingArguments = [quant, prod]
        // <quant> <product>
      } else if (valuesLength === 3) {
        // <quant> <prod> <price>
        ;[quant, prod, price] = valuesFromInput
        this.advancedParsingArguments = [quant, prod, price]
      }
    }
    // const

    const callPartial = () => {
      this.timeout = setTimeout(() => {
        this.partialMatchSearch(v)
      }, this.props.timer || 750)
      // }, this.props.timer || 500)
    }

    if (allowInstantSearch && v.length >= minimumKeywordLength) {
      // debugger
      if (this._isMounted) {
        if (this.cartMap) {
          this.cartMap = null
        }
        this.setState(
          {
            lastSearch: event.target.value
            //  value: event.target.value
          },
          callPartial
          // () => callPartial()
        )
      }
    } else if (v.length === 0 && !emptySetFieldCalled) {
      this.setField('', true)
      emptySetFieldCalled = true
    }
    // if (v.length === 0) {
    //   this.clearSearch()
    //   this.setField('', true)
    // } else if (allowInstantSearch && v.length >= minimumKeywordLength) {
    //   // debugger
    //   if (this._isMounted) {
    //     if (this.cartMap) {
    //       this.cartMap = null
    //     }
    //     this.setState(
    //       {
    //         lastSearch: event.target.value
    //         //  value: event.target.value
    //       },
    //       callPartial
    //       // () => callPartial()
    //     )
    //   }
    // }
  },

  onGridReady(params) {
    this.api = params.api
    console.log(this)
    const firstNode = this.api.getDisplayedRowAtIndex(0)
    if (firstNode) {
      firstNode.setSelected(true)
    }
  },

  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    const { keyCode } = event
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }
    const gridAvail = this.api && this.state.isOpen && this.state.dropDownProps
    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        return
      }
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.state.isSet /* || this.state.value != null */) {
        return
      }
      event.preventDefault()

      handleSearch.call(this, val)
    }
    if (keyCode === KEY_DOWN && gridAvail) {
      event.preventDefault()
      this.api.ensureIndexVisible(0)
      const rowCount = this.api.getDisplayedRowCount()
      if (rowCount > 2) {
        const firstCol = this.api.columnController.getAllDisplayedColumns()[0]

        const node = this.api.getDisplayedRowAtIndex(1)
        if (node) {
          node.setSelected(true)
          this.api.ensureColumnVisible(firstCol)

          // sets focus into the first grid cell
          this.api.setFocusedCell(1, firstCol)
          this.api.ensureIndexVisible(0)
        }
      }
      // // might need to change this when we do saveCart feature
    } else if (keyCode === ENTER && gridAvail) {
      event.preventDefault()
      const node = this.api.getDisplayedRowAtIndex(0)

      this.onRowClicked({
        node,
        api: this.api,
        columnApi: this.api.columnController,
        data: node.data,
        rowIndex: node.rowIndex
      })
    }
  },

  onOkClick({ pin, password, username }) {
    // console.log(...rest)
    // debugger
    this.setField({ pin, password, username })
    this.setState({
      isOpen: false
    })
  },

  onMouseLeave(event) {
    if (this._isMounted) {
      this.setState({ mouseover: false })
    }
  },

  onMouseOver(event) {
    if (this._isMounted) {
      this.setState({ mouseover: true })
    }
  },

  onRequestClose() {
    // console.log('request close', this.state, this.props)
    if (this.cartMap) {
      this.cartMap = null
    }
    if (this._isMounted) {
      this.setState(
        prevState => ({
          isOpen: false,
          mouseover: false,
          searchClicked: prevState.searchClicked ? false : undefined
        }),
        () => {
          if (this.props.getFormState) {
            this.props.dispatch({
              type: CLOSE_SEARCH,
              payload: { propertyName: this.props.propertyName },
              meta: { form: this.form, reducer: 'IndexSearch' }
            })
          }
          if (this.props.onRequestClose) {
            this.props.onRequestClose()
          }
          // console.log(this.state)
        }
      )
    }
  },

  async onRowClicked(event) {
    const {
      // grid,
      descriptionKey = 'description',
      propertyName,
      indexSearchType,
      moreInfo,
      includeParent,
      exactMatchSearch
      // grid
    } = this.props

    const keyword = event.data.recordName

    const displayValue = event.data.parentId
      ? event.data.dataId
      : event.data.recordName

    const description = (descriptionKey && event.data[descriptionKey]) || ''

    const newState = {
      description,
      value: displayValue,
      isDirty: false,
      isSearching: false
    }
    let result

    const row = event.data

    try {
      result = await exactMatchSearch({
        indexSearchType: row.recordType, // indexSearchType || this.props.meta.searchType,
        keyword,
        propertyName,
        moreInfo,
        includeParent,
        isRecordName: true,
        exactMatchOnly: this?.props?.meta?.exactMatchOnly || false
      })
      if (!this.isDDIFormField) {
        newState.value = result.exactMatchResults.name
      }
      newState.isSet = true
      if (this._isMounted) {
        this.setState(newState, () => {
          this.setField(displayValue, true, result.exactMatchResults)
          this.blur(this.state.value)
          this.onRequestClose()
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },

  // /116|159|147|159|131|45|33/
  async paginationCb(pageNumber, cb) {
    const {
      partialMatchSearch,
      propertyName,
      indexSearchType,
      parentType,
      parentId
    } = this.props
    // debugger
    try {
      await this.partialMatchSearch(
        null,
        null,
        {
          partialMatchSearch,
          indexSearchType: indexSearchType || this.props.meta.searchType,
          pageNumber,
          propertyName,
          parentType,
          parentId,
          keyword: this.state.searchClicked ? '' : this.state.value || ''
        },
        this.state.dropDownProps && this.state.dropDownProps.isFiltered,
        cb
      )
    } catch (e) {
      //
    }
  },

  async partialMatchSearch(value, bool, args, isFiltered = false, cb) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    const {
      partialMatchSearch,
      propertyName,
      indexSearchType,
      parentId,
      parentType,
      leader,
      pageNumber
    } = args || this.props
    if (args) {
      value = args.keyword
    }
    if (
      !this.props.disableAdvancedParsing &&
      this.advancedParsingArguments &&
      this.advancedParsingArguments.length
    ) {
      // debugger
      // oValue = value
      this.advancedParsingArguments[3] = value
      ;[, value] = this.advancedParsingArguments
      // debugger
    }

    if (this.state.isSearching) return
    let results
    if (this._isMounted) {
      // debugger
      this.setState(
        {
          lastSearch:
            this.advancedParsingArguments &&
            this.advancedParsingArguments.length
              ? this.advancedParsingArguments[3]
              : value,
          isSearching: true,
          value:
            this.advancedParsingArguments &&
            this.advancedParsingArguments.length
              ? this.advancedParsingArguments[3]
              : value
        },
        async () => {
          try {
            results = await partialMatchSearch({
              indexSearchType: indexSearchType || this.props.meta.searchType,
              propertyName,
              keyword: bool ? '' : value,
              searchAll,
              parentId,
              parentType,
              isFiltered,
              leader,
              pageNumber,
              allowSpecialProducts: this.props.allowSpecialProducts,
              returnInactiveWarehouses:
                this?.props?.meta?.returnInactiveWarehouses || false,
              returnCustomerConsignmentWarehouses:
                this?.props?.meta?.returnCustomerConsignmentWarehouses || false
            })
            await this.setPartialMatchResults(results, isFiltered, cb)
          } finally {
            if (this._isMounted) {
              this.setState({ isSearching: false })
            }
          }
        }
      )
    }
  },

  redoSearch() {
    // debugger
    if (this._isMounted) {
      this.setState(
        { isSet: false, value: this.state.lastSearch, isDirty: true },
        () => this.partialMatchSearch(this.state.lastSearch)
      )
    }
  },
  saveCart(e) {
    if (this.props.onSaveCart) {
      this.props.onSaveCart()
    }
    const editingCells = this.api.getEditingCells()
    if (!editingCells.length) {
      this.dispatchSaveCart()
      // this.props.dispatch({
      //   type: 'SAVE_CART',
      //   payload: this.cartMap,
      //   meta: { form: this.props.form }
      // })
      this.onRequestClose()
      return
    }

    this.api.addEventListener('cellValueChanged', this.onCellEditingStopped)
    this.api.stopEditing()
  },
  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  },
  setField(val, clear, results) {
    const { clearFieldOnSet, leader, setField, onSetField } = this.props
    // debugger
    const args = this.advancedParsingArguments
    if (this.props.node && args && args.length) {
      const { node } = this.props
      node.advancedParsingArguments = this.advancedParsingArguments
    }
    this.advancedParsingArguments = null
    // debugger
    setField(val, clear, leader, results, args)

    if (onSetField) {
      onSetField(val, clear, leader, results)
    }
    if (clearFieldOnSet) {
      setField('', clear)
    }
  },
  async setPartialMatchResults(results, isFiltered = false, cb = () => {}) {
    // debugger
    const { hideToggle } = this.props.meta
    // debugger
    const toggles =
      !hideToggle &&
      filterMap[
        this.props.indexSearchType ||
          this.props?.meta?.searchType?.toLowerCase()
      ]
    let toggleFilter
    if (toggles) {
      toggleFilter = toggleFilterFn.bind(this)
      // toggles: filterMap[]
    }

    // debugger
    let {
      grid: { rowData, columnDefs, meta },
      ...rest
    } = results

    if (this.cartMap && Reflect.ownKeys(this.cartMap).length) {
      // debugger
      rowData = rowData.map(row => {
        if (this.cartMap[row.recordName]) {
          row = {
            ...row,
            cart: this.cartMap[row.recordName]
          }
        }
        return row
      })
    }
    if (this._isMounted) {
      await this.setState(
        {
          dropDownProps: {
            ...rest,
            rowData,
            meta,
            columnDefs: this.getGridColumnDefs(columnDefs),
            // onRowDoubleClicked: this.onRowClicked,
            navigateToNextCell: this.navigateToNextCell,
            getRowNodeId: this.getRowNodeId,
            paginationCb: this.paginationCb,
            tabToNextCell: this.tabToNextCell,
            // height: this.getGridHeight(),
            toggles,
            toggleFilter,
            isFiltered: results?.filtered,
            onGridReady: this.onGridReady,
            rowSelection: 'single',
            domLayout: 'autoHeight',
            rowHeight: 24,
            suppressCellSelection: true,
            onCellKeyDown: this.onCellKeyDown,
            showCart: this.props.showCart,
            saveCart: this.saveCart
          },
          isOpen: true
        },
        () => cb()
      )
    }
  },
  setDisplayValue(val) {
    if (this._isMounted) {
      this.setState({ description: val })
    }
  },
  showQuickEntityClick() {
    this.props.dispatch(
      showQuickEntity(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  tabToNextCell(params) {
    const previousCell = params.previousCellPosition
    const lastRowIndex = previousCell.rowIndex
    let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1
    const renderedRowCount = this.api.getDisplayedRowCount()

    if (nextRowIndex < 0) {
      nextRowIndex = 0
    } else if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1
    }
    const result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      floating: previousCell.floating
    }

    this.api.forEachNode(node => {
      if (result.rowIndex === node.rowIndex) {
        node.setSelected(true)
        /* this was busted, fixed while working on 10480 */
        const colId = this?.api?.columnController?.getAllDisplayedColumns()?.[0]
          ?.colId
        if (colId) {
          this.api.setFocusedCell(result.rowIndex, colId)
        }
      }
    })
    return result
  }
}
