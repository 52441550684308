/* eslint react/sort-comp: 0, react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, Paper } from '@material-ui/core'
import { getIn } from 'utils'
import { setField } from 'ddiForm/actions'

const mapStateToProps = (state, ownProps) => {
  const {
    colDef: {
      cellRendererParams: { form, propertyName }
    }
  } = ownProps
  return {
    fieldValue:
      getIn(state, `ddiForm.${form}.fields.${propertyName}.value`) || ''
  }
}

class WebCatCheckbox extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    openScreenParams: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isChecked: props.data.recordName === props.fieldValue || false,
      fieldValue: props.fieldValue
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.fieldValue !== prevState.fieldValue) {
      return {
        isChecked: nextProps.fieldValue === nextProps.data.recordName || false,
        fieldValue: nextProps.fieldValue
      }
    }

    return null
  }

  selectRecord = () => {
    const {
      colDef: {
        cellRendererParams: { form, propertyName, closePopoverCallbackFn }
      },
      data: { recordName }
    } = this.props
    const value = this.state.isChecked ? '' : recordName
    // console.log('value', value)
    this.setState(
      {
        isChecked: Boolean(value)
      },
      () => {
        setTimeout(() => {
          this.props.dispatch(setField(form, propertyName, value))
          if (
            closePopoverCallbackFn &&
            typeof closePopoverCallbackFn === 'function'
          ) {
            closePopoverCallbackFn()
          }
        }, 100)
      }
    )
  }

  render() {
    const {
      colDef: {
        cellRendererParams: { form }
      },
      data: { recordName }
    } = this.props

    const { isChecked } = this.state

    if (form === 'productWebCategory') {
      return <div />
    }

    return (
      <div
        style={{
          display: 'block',
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <Icon
            onClick={this.selectRecord}
            style={{ color: '#999', display: 'inline-block', fontSize: 16 }}
          >
            {isChecked ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(WebCatCheckbox)
