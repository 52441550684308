// import 'babel-polyfill'
import '@babel/polyfill'

/* eslint-disable import/no-unresolved, import/extensions, import/no-webpack-loader-syntax */
import 'file-loader?name=[name].[ext]!./favicon.ico'
/* eslint-enable import/no-unresolved, import/extensions */

// Import all the third party stuff
// import injectTapEventPlugin from 'react-tap-event-plugin'
import React, { useState, useEffect, StrictMode, Fragment } from 'react'

import { render } from 'react-dom'
import { Provider, connect } from 'react-redux'
import ScrollToTop from 'components/ScrollToTop'
import { ConnectedRouter } from 'connected-react-router/immutable'
import Snackbar from 'snackbar'
import { fromJS } from 'immutable'
import createHistory from 'history/createHashHistory'
import FontFaceObserver from 'fontfaceobserver'
// import { useScroll } from 'react-router-scroll'
import 'sanitize.css/sanitize.css'
// import MainForm from 'pages/Main'
// import Login from 'pages/Login'
import MuiModal from 'modals/MuiModal'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {
  isMobile,
  isTablet,
  isMobileOnly,
  isIOS,
  isMobileSafari,
  isAndroid,
  mobileVendor,
  mobileModel,
  isIOS13
} from 'react-device-detect'
import { getAsyncInjectors } from 'utils/asyncInjectors'
import { checkTokenExpiration } from 'auth/actions'
import informTheme from 'theme/informTheme'

import { TOGGLE_MOBILE } from 'mobile/constants'
import rootSaga from './sagas'
import configureStore from './store'
// import createRoutes from './routes'
// import createRoutes from './routes'
import Routes from './routes'

// agGrid.LicenseManager.setLicenseKey(
//   'CompanyName=DDI System,LicensedApplication=Inform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-011518,ExpiryDate=30_October_2021_[v2]_MTYzNTU0ODQwMDAwMA==ae1d0f935266239d58d6d88a61a12507'
// )

const appHistory = createHistory()
const robotoObserver = new FontFaceObserver('Roboto', {})
robotoObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded')
  },
  () => {
    document.body.classList.remove('fontLoaded')
  }
)

const mobileArgs = {
  mobile: {
    isMobile,
    isTablet,
    isMobileOnly,
    isIOS,
    isAndroid,
    mobileVendor,
    mobileModel,
    isMobileSafari,
    isIOS13
  }
}
const initialState = fromJS(mobileArgs)
const store = configureStore(initialState, appHistory)
// debugger
// Sync history and store, as the connected-react-router reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state

// Inject root sagas..
const { injectReducer } = getAsyncInjectors(store)
store.runSaga(rootSaga, injectReducer)

// Set up the router, wrapping all Routes in the App component

// Needed for onTouchTap
// Can go away when react 1.0 release
// Check this repo:
// https://github.com/zilverline/react-tap-event-plugin
// injectTapEventPlugin()
function useWindowSize(dispatch) {
  const isDevClient =
    typeof window === 'object' && process.env.NODE_ENV === 'development'
  const toggle = bool =>
    dispatch({
      type: TOGGLE_MOBILE,
      payload: {
        isMobile: bool,
        isTablet,
        isMobileOnly,
        isIOS,
        isAndroid,
        mobileVendor,
        mobileModel,
        isMobileSafari,
        isIOS13
      }
    })
  function getSize() {
    return {
      width: isDevClient ? window.innerWidth : undefined,
      height: isDevClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)
  const [thisIsMobile, setIsMobile] = useState(null)

  useEffect(() => {
    if (!isDevClient) {
      return false
    }
    if (thisIsMobile === null) {
      const size = getSize()
      if (size.width <= 1024) {
        setIsMobile(true)
        toggle(true)
      } else {
        setIsMobile(false)
        toggle(false)
      }
    }
    function handleResize() {
      const size = getSize()
      if (!thisIsMobile && size.width <= 1024) {
        setIsMobile(true)
        toggle(true)
      }
      if (thisIsMobile && size.width >= 1024) {
        setIsMobile(false)
        toggle(false)
      }
      setWindowSize(size)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [thisIsMobile]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

const ResizeWrapper = ({ dispatch, children }) => {
  useWindowSize(dispatch)
  return children
}
const ConnectedResize = connect()(ResizeWrapper)

store.dispatch(checkTokenExpiration())

render(
  <MuiThemeProvider theme={informTheme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <ConnectedResize>
          <ConnectedRouter history={appHistory} noInitialPop>
            <Routes />

            <MuiModal />
            <Snackbar />
          </ConnectedRouter>
        </ConnectedResize>
      </Provider>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root')
)

if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install() // eslint-disable-line
}
