import React from 'react'
import { withContext } from 'ddiForm'
import { getIn } from 'utils'

const getDisplayedValue = props => WrappedComponent => {
  const { propertyName, lineItems, hasHeaders = false } = props

  const ValueDisplay = ({ value }) => {
    let displayValue = value
    if (value && Array.isArray(lineItems) && lineItems?.length) {
      const index = hasHeaders ? 1 : 0
      displayValue = lineItems?.[index]?.[value] || ''
    }

    return <WrappedComponent {...props} value={displayValue} />
  }

  const ConnectedValueDisplay = withContext({
    value: form =>
      getIn(form, `fields.productImport.${propertyName}.value`) || ''
  })(ValueDisplay)

  return <ConnectedValueDisplay />
}

export default getDisplayedValue
