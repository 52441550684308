import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import Grid from 'grid'
import { formatNumber } from 'utils'
import moment from 'moment'
import TooltipField from 'hoc/TooltipField'
import addInvoiceInquiryInModal from '../common/addInvoiceInquiryInModal'

const InvoiceRenderer = ({ value, form }) => {
  const [isOpeningScreen, setIsOpening] = useState(false)

  const buttonLinkStyle = {
    color: isOpeningScreen ? '#777' : '#517b9c',
    fontWeight: 500,
    margin: 0,
    padding: 0,
    textDecoration: 'underline'
  }

  const dispatch = useDispatch()

  const handleClick = debounce(async event => {
    if (isOpeningScreen) {
      return
    }
    try {
      setIsOpening(true)
      await dispatch(addInvoiceInquiryInModal(form, value))
    } catch (e) {
      console.log(e)
    } finally {
      setIsOpening(false)
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <button
        onClick={handleClick}
        style={buttonLinkStyle}
        type="button"
        disabled={isOpeningScreen}
      >
        {value}
      </button>
    </div>
  )
}

const ProductInvoiceHistoryModal = ({ data: { rowData = [], form } }) => {
  const columnDefs = [
    {
      colId: 'dataId',
      cellRendererFramework: TooltipField({
        anchorToGridCellText: true,
        indexSearchType: 'invoiceOrder',
        position: 'right-end',
        recordNameKey: 'value',
        sensitivity: 100,
        type: 'indexSearch'
      })(InvoiceRenderer),
      cellRendererParams: { form },
      field: 'dataId',
      headerName: 'Invoice',
      editable: false,
      sortable: false,
      filterable: false
    },
    {
      colId: 'invoiceDate',
      field: 'invoiceDate',
      headerName: 'Date',
      cellClass: 'align-center center-align text-center',
      headerClass: 'align-center center-align text-center',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => {
        return moment(params.value).format('M/D/YY')
      }
    },
    {
      colId: 'quantityShipped',
      field: 'quantityShipped',
      headerName: 'Qty',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false
    },
    {
      colId: 'total',
      field: 'total',
      headerName: 'Total',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => formatNumber(params.value)
    }
  ]

  return (
    <Grid
      height={350}
      rowData={rowData && Array.isArray(rowData) ? rowData : []}
      getRowNodeId={data => data.dataId}
      columnDefs={columnDefs}
    />
  )
}

export default ProductInvoiceHistoryModal
