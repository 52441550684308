import {
  call,
  delay,
  fork,
  put,
  putResolve,
  select,
  take
} from 'redux-saga/effects'
import * as DDICONSTANTS from 'ddiForm/constants'
import { displayValidationErrors } from 'ddiForm/sagas'
import { getFormSelector } from 'ddiForm/utils'
import { setSelectedRowIndex, setField } from 'ddiForm/actions'
import { addModal, confirm } from 'modals/actions'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { getIn, is, noop, fromJS } from 'utils'
import { clearGridRow } from 'components/EditableGrid/actions'
import { handleLinks } from 'pages/ProductMaster/sagas/analysisSagas'
import { api } from 'services'
import CommentsModalContent from 'pages/SalesOrder/components/CommentsModalContent'
import CommentsModalActions from 'pages/SalesOrder/components/CommentsModalActions'
import UploadCSVModal from 'pages/SalesOrder/tabs/Order/components/LineItemsSection/components/UploadCSVModal'
import CustomerPartNumberModal from 'pages/SalesOrder/components/CustomerPartNumberModal'
import CustomerPartNumberModalActions from 'pages/SalesOrder/components/CustomerPartNumberModalActions'
import PromiseDateModal from 'pages/SalesOrder/components/PromiseDateModal'
import MultiSelectRetainTaxablePromptModal from 'pages/SalesOrder/components/MultiSelectRetainTaxablePromptModal'
import productImportSagas from 'components/ProductImport/sagas'

import { readAdditionalInvoiceData as readAdditionalInvoiceDataAPI } from 'pages/InvoiceInquiry/api'
import { getLineItemImages } from 'mobile/pages/SalesOrder/actions'
import * as actions from '../actions'
import * as CONSTANTS from '../constants'
import { quantityToShipProcess } from './gridSagas'
import { confirmReturnCancellationModal } from './returnOrderSagas'

import {
  handleNewlyAddedProductNotes,
  triggerBoxQuantityPrompts,
  duplicateProductsRoutine,
  readLineItemDataProcess,
  handleRepairItemModalProcess
} from './commonSagas'

import {
  mapLinePropertyChangeResponse,
  getSelectedRowLineNumberFromFormState,
  getInventoryNames
} from '../utils'

const provisionalLineItem = 'provisionallineitem'

export function* addRowButtonListener(formListener) {
  while (true) {
    const action = yield take(DDICONSTANTS.ADD_BLANK_ROW)
    const {
      meta: { form },
      payload: { propertyName }
    } = action

    if (form === formListener) {
      yield put(actions.setProductDataDefaults(form, { propertyName }))
    }
  }
}

export function* getProductDetails({
  form,
  dataId,
  propertyName,
  api: gridApi,
  rowIndex,
  payload,
  column,
  data,
  ...rest
}) {
  // debugger
  yield put(actions.getProductDetails.request(form, { data }))

  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  // /* header and detail tabs are combined now */
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = ['header', 'detail']

  if (
    payload.node &&
    payload.node.advancedParsingArguments &&
    payload.node.advancedParsingArguments.length
  ) {
    rest.quantityOrdered = payload.node.advancedParsingArguments[0] // eslint-disable-line
    if (payload.node.advancedParsingArguments[2]) {
      rest.netPrice = payload.node.advancedParsingArguments[2] // eslint-disable-line
    }
  }

  let apiParams = {
    properties: { dataId, ...rest },
    lineNumber: rowIndex + 1,
    guid,
    groupNames,
    gridName: propertyName
  }

  if (propertyName && propertyName.match(/lineItemComponents/)) {
    apiParams = {
      ...apiParams,
      gridName: 'lineItems',
      lineNumber: payload?.data?.parentLineNumber,
      childLineNumber: payload?.data?.lineNumber,
      additionalData: ['components']
    }
  }

  const { response, error } = yield call(api.changeGridItem, apiParams)

  if (response) {
    const res = response
    yield putResolve({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: {
        form,
        addedNewLineItem: true,
        addedLineNumber: rowIndex + 1,
        searchFieldInteraction: false, // this is not a search field interaction !important SVE 10/11/19
        apiParams
      },
      payload: {
        ...res,
        propertyChanged: propertyName
      }
    })
    yield put(
      actions.getProductDetails.success(
        {
          ...res,
          propertyChanged: propertyName
        },
        form
      )
    )

    let rowIdentifier
    if (res?.record?.detail?.lineItems?.length) {
      rowIdentifier =
        propertyName && propertyName?.match(/lineItemComponents/)
          ? payload?.data?.lineNumber
          : res?.record?.detail?.lineItems?.[rowIndex]?.lineNumber

      if (rowIdentifier && !isMobile && gridApi && gridApi?.forEachNode) {
        yield delay(100)
        gridApi.resetRowHeights()
        gridApi.forEachNode(node => {
          if (
            node?.data?.lineNumber &&
            node.data.lineNumber === rowIdentifier
          ) {
            if (!node.isSelected()) {
              // debugger
              node.setSelected(true)
            }
          }
        })
      }

      /*
        Marc FYI, open the Options tab here. This way it only happens once, and this does not interfere
        with the grid tabbing interactions -- SVE 12/6/2019
      */
      if (
        propertyName === 'lineItems' &&
        res?.record?.detail?.lineItems?.[rowIndex]?.optionsAndAccessories
          ?.length &&
        !isMobile
      ) {
        yield put(
          actions.storeUIFeatureState(form, {
            feature: 'lineItemsDetailTab',
            featureState: 'Options'
          })
        )
      }
    }

    setTimeout(() => {
      if (!isMobile) {
        if (propertyName === 'lineItems') {
          if (dataId === 'SP') {
            gridApi.startEditingCell({
              rowIndex,
              colKey: 'description'
            })
          } else {
            gridApi.startEditingCell({
              rowIndex,
              colKey: 'quantityOrdered'
            })
          }
        } else if (propertyName && propertyName?.match(/lineItemComponents/)) {
          gridApi.startEditingCell({
            rowIndex,
            colKey: 'quantityExtended'
          })
        }
      }
    }, 100)
  } else {
    const { status, message } = error
    yield put(actions.getProductDetails.failure(error, form))
    if (status === 496) {
      yield put(
        clearGridRow(form, {
          propertyName,
          rowIndex,
          skipFields: ['rowId', 'lineNumber', 'uniqueKey']
        })
      )

      if (propertyName && propertyName.match(/lineItemComponents/)) {
        yield put(
          actions.clearComponentsRow(form, {
            parentLineNumber: payload?.data?.parentLineNumber,
            childLineNumber: payload?.data?.lineNumber
          })
        )
        yield delay(100)
        const updatedFormState = yield select(getFormSelector(form))

        setTimeout(() => {
          if (gridApi) {
            let components = getIn(
              updatedFormState,
              `fields.${propertyName}.rowData`
            )
            components = components?.toJS ? components.toJS() : []
            gridApi.setRowData(components)
          }
        }, 1)
      }
    }
  }
}

export function* onCellChangedListener(formListener) {
  while (true) {
    const action = yield take('CELL_CHANGED_COMPLETE')
    const {
      payload: {
        propertyName,
        api,
        column,
        newValue,
        oldValue,
        node: { childIndex: rowIndex },
        data: { quantityOrdered }
      },
      meta: { form }
    } = action

    if (
      form === formListener &&
      (propertyName === 'lineItems' ||
        (propertyName &&
          typeof propertyName === 'string' &&
          propertyName.match(/lineItemComponents/))) &&
      newValue != null &&
      newValue !== oldValue
    ) {
      if (column.colId === 'dataId') {
        if (quantityOrdered) {
          yield fork(getProductDetails, {
            form,
            dataId: newValue,
            quantityOrdered,
            propertyName,
            api,
            rowIndex,
            payload: action.payload,
            column
          })
        } else {
          yield fork(getProductDetails, {
            form,
            dataId: newValue,
            propertyName,
            api,
            rowIndex,
            payload: action.payload,
            column
          })
        }
      } else if (action.payload.data.dataId) {
        yield fork(updateLineItemProcess, form, action.payload)
      }
    }
  }
}

export function* removeLineItemProcess(
  form,
  lineNumber,
  rowId,
  isConfirmedDeletion = false,
  data = {}
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  yield put(actions.onPropertyChange.request(form))

  let apiParams = {
    lineNumber,
    gridName: 'lineItems',
    groupNames,
    guid,
    properties: { delete: isConfirmedDeletion ? true : '' }
  }

  if (data?.parentLineNumber) {
    apiParams = {
      ...apiParams,
      lineNumber: data?.parentLineNumber,
      childLineNumber: data?.lineNumber,
      activeLineNumber: data?.parentLineNumber,
      activeLineNumbers: [data?.uniqueKey],
      additionalData: ['components']
    }
    debugger
  }

  const { response, error } = yield call(api.changeGridItem, apiParams)

  if (response) {
    /*
      if there is only 1 line item, for some reason,
      the entire 'detail' object comes back as null
      so we need to fake it in the reducer like so.
      not sure if this is the ideal approach or not
      -- SVE 1/2/20
    */

    yield put(
      actions.onPropertyChange.success(
        {
          ...response,
          record: {
            ...response.record,
            detail: response.record.detail
              ? {
                  ...response.record.detail,
                  lineItems: response.record.detail.lineItems
                }
              : {
                  lineItems: []
                }
          },
          propertyChanged: 'lineItems'
        },
        form
      )
    )

    /* remove the additionalDataMap stuff */
    yield put(actions.removeAdditionalDataMapRow(form, { rowId }))
  } else if (error.status !== 496) {
    yield put(actions.onPropertyChange.failure(error, form))
  } else if (error.status === 496 && error.propertyToFillOnOk === 'delete') {
    const { modalTitle, message } = error
    yield call(confirmationModal, message, modalTitle)

    const action = yield take([CONFIRMED, CANCELED])

    if (action.type === CONFIRMED) {
      yield fork(removeLineItemProcess, form, lineNumber, rowId, true)
    } else {
      yield put(actions.onPropertyChange.failure({}, form))
    }
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* removeLineItemListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.REMOVE_LINE_ITEM.TRY)
    const {
      meta: { form },
      payload: { lineNumber, rowId, data }
    } = action

    if (formListener === form && lineNumber) {
      yield fork(removeLineItemProcess, form, lineNumber, rowId, false, data)
    }
  }
}

export function* updateLineItemListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.UPDATE_LINE_ITEM.TRY)

    if (form === formListener) {
      yield fork(updateLineItemProcess, form, payload)
    }
  }
}

export function* updateLineItemProcess(form, payload) {
  const {
    propertyName,
    data,
    value,
    colDef: { field },
    additionalProperties = {},
    cb = noop
  } = payload

  const formState = yield select(getFormSelector(form))

  const { lineNumber } = data

  const guid = getIn(formState, 'guid')

  const childLineNumber = getIn(formState, 'fields.lineItems.childIndex')
  const groupNames = ['header', 'detail']

  const resolvedValue = value

  const properties = {
    [field]: resolvedValue,
    ...additionalProperties
  }

  yield put(actions.updateGridItem.request(form))

  let apiParams =
    field === 'warehouseId'
      ? {
          properties,
          lineNumber,
          gridName: propertyName,
          guid,
          groupNames,
          additionalData: ['lineitemoptionsaccessories', 'lineitemsubstitutes'],
          activeLineNumber: lineNumber
        }
      : {
          properties,
          lineNumber,
          gridName: propertyName,
          guid,
          groupNames
        }

  if (propertyName && propertyName.match(/lineItemComponents/)) {
    apiParams = {
      ...apiParams,
      gridName: 'lineItems',
      lineNumber: data?.parentLineNumber,
      activeLineNumber: data?.parentLineNumber,
      activeLineNumbers: [data?.uniqueKey],
      childLineNumber: data?.lineNumber,
      additionalData: ['components']
    }
  }

  if (propertyName === 'lineItems' && data?.components) {
    apiParams = {
      ...apiParams,
      activeLineNumber: lineNumber,
      additionalData: ['components']
    }
  }
  if (childLineNumber) {
    apiParams.childLineNumber = childLineNumber
  }
  const { response, error } = yield call(api.changeGridItem, apiParams)
  debugger

  if (response) {
    if (
      response.messages &&
      Array.isArray(response.messages) &&
      response?.messages?.[0]?.propertyToFillOnOk &&
      response?.messages?.[0]?.propertyToFillOnOk === 'PurchaseOrderId'
    ) {
      yield call(
        confirmationModal,
        `${response.messages[0].message}. Continue?`,
        response.messages[0].modalTitle,
        { form },
        { title: 'Yes' },
        { title: 'No' }
      )

      const action = yield take([CONFIRMED, CANCELED])
      if (action.type === CANCELED) {
        yield fork(updateLineItemProcess, form, {
          ...payload,
          value: ''
        })

        return
      }
    }

    const res = mapLinePropertyChangeResponse(response, groupNames)
    /*
      cb && changedValuePosted added to accommodate return workflows,
      which happen via a series of (potentially) stacked
      modals -- SVE 1/10/2020
    */
    yield putResolve(
      actions.updateGridItem.success(
        {
          ...res,
          propertyChanged: propertyName,
          lineNumber,
          fieldChanged: field,
          changedValuePosted: resolvedValue
        },
        form
      )
    )

    if (cb && typeof cb === 'function') {
      cb()
    }

    if (field === 'cancelreturn') {
      yield put(actions.clearReturnModalData(form, { lineNumber }))
    }

    if (field === 'quantityOrdered' && resolvedValue && resolvedValue > 1) {
      const recurringIntervalType =
        getIn(formState, 'fields.recurringIntervalType.value') ||
        getIn(formState, 'values.recurringIntervalType') ||
        ''
      // const data = record?.detail?.lineItems?.find(x => x.lineNumber === lineNumber)
      if (recurringIntervalType === 'L') {
        yield put(
          actions.handleRecurringOrderInteraction.try(form, {
            action: 'initialize',
            lineNumber
          })
        )
      }
    }

    // if (
    //   propertyName &&
    //   propertyName.match(/lineItemComponents/) &&
    //   payload?.api
    // ) {
    //   const components =
    //     response?.record?.detail?.lineItems
    //       ?.find(x => x.lineNumber === data?.parentLineNumber)
    //       ?.components?.reduce((acc, next) => {
    //         const { priceChange, ...rest } = next
    //         acc = acc.concat({
    //           ...rest,
    //           rowId: next.uniqueKey ? next.uniqueKey : 'blankrow',
    //           parentLineNumber: data?.parentLineNumber
    //         })

    //         return acc
    //       }, []) || null

    //   if (components && Array.isArray(components)) {
    //     payload.api.setRowData(components)
    //     payload.api.refreshCells({ force: true })
    //   }
    // }
  } else {
    yield put(actions.updateGridItem.failure(error, form))

    if (field === 'cancelreturn' && cb) {
      yield fork(confirmReturnCancellationModal, form, cb, data)
    }
  }
}

export function* setWarehouseListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SET_WAREHOUSE.TRY)
    const {
      meta: { form, thunk },
      payload
    } = action

    if (
      form === formListener &&
      payload &&
      payload.propertyName &&
      payload.propertyName === 'warehouse'
    ) {
      yield fork(setWarehouseOnLineItemProcess, form, thunk, payload)
    }
  }
}

export function* setWarehouseOnLineItemProcess(form, thunk, payload) {
  const { warehouseId, propertyName, gridId, isSelected } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const lineNumber = getSelectedRowLineNumberFromFormState(formState)
  const groupNames = ['header', 'detail']

  const properties = {
    warehouseId
  }

  yield put(actions.setWarehouse.request(form))

  // NOTE: gridName for warehouse not yet implemented, use lineItems -LL 10/3/19
  const { response, error } = yield call(api.changeGridItem, {
    lineNumber,
    properties,
    guid,
    groupNames,
    gridName: 'lineItems',
    inventoryNames: [gridId]
  })

  if (response) {
    const res = mapLinePropertyChangeResponse(response, groupNames)
    /*
      commented this out with the selected variable because
      nothing done with it, so it seems like a totally
      extraneous computation -- SVE 2/2/21
    */
    yield put(
      actions.onPropertyChange.success(
        {
          ...res,
          propertyChanged: propertyName
        },
        form
      )
    )

    yield put({
      type: CONSTANTS.SET_WAREHOUSE.SUCCESS,
      meta: { form, thunk },
      payload
    })
  } else {
    yield put(actions.setWarehouse.failure(error, form))
  }
}

export function* getSalesOrderDocumentProcess(
  form,
  payload,
  actionName = 'getSDS'
) {
  const { dataId } = payload

  const apiMethod = actionName === 'getSDS' ? 'getSds' : actionName
  /*
    correct this little discrepancy in the action
    and API names (which are getting pulled in from
    Product Master) here -- SVE 2/2/2021
  */

  if (!dataId || !actions[actionName] || !api[apiMethod]) return

  yield put(actions[actionName].request(form))

  const { response, error } = yield call(api[apiMethod], { dataId })

  if (response) {
    yield put(actions[actionName].success(response, form))
    yield fork(handleLinks, form, response, dataId)
  } else {
    yield put(actions[actionName].failure(error, form))
  }
}

export function* getSDSListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_SDS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(getSalesOrderDocumentProcess, form, payload, 'getSDS')
    }
  }
}

export function* getDocSpecListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_DOC_SPEC.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(getSalesOrderDocumentProcess, form, payload, 'getDocSpec')
    }
  }
}

export function* openCommentsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_COMMENTS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openCommentsProcess, form, payload)
    }
  }
}

export function* openCommentsProcess(form, payload) {
  const { propertyName, uniqueKey } = payload

  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const commentName = `${propertyName.toLowerCase()}Comment`

  yield put(actions.openComments.request(form))
  const { response, error } = yield call(api.getComment, {
    uniqueKey,
    commentName,
    guid
  })

  if (response) {
    yield put(actions.openComments.success(response, form))

    const modalOpts = {
      component: CommentsModalContent,
      options: {
        actions: CommentsModalActions,
        data: {
          ...response,
          commentName,
          form,
          uniqueKey
        },
        title: `${propertyName} Comments`
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  } else {
    yield put(actions.openComments.failure(error, form))
  }
}

export function* setCommentListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SET_COMMENT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(setCommentProcess, form, payload)
    }
  }
}

export function* setCommentProcess(form, payload, deleteComment = false) {
  const { uniqueKey, commentName, storePermanently, modalId } = payload

  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const comment = getIn(formState, 'fields.comment.value') || ''

  yield put(actions.setComment.request(form))

  const { response, error } = yield call(api.setComment, {
    uniqueKey,
    guid,
    comment: deleteComment ? null : comment,
    commentName,
    storePermanently
  })

  if (response) {
    yield put(actions.setComment.success({ ...response, uniqueKey }, form))
    yield put(confirm(form, modalId))
  } else {
    yield put(actions.setComment.failure(error, form))
  }
}

export function* deleteCommentListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_COMMENT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      /*
        note: deleteCommentProcess was removed and easily merged
        with setCommentProcess. No reason to have 2 sagas that do
        99% the same exact thing -- SVE 2/2/21
      */
      yield fork(setCommentProcess, form, payload, true)
    }
  }
}

export function* addOptionsProcess(
  form,
  selectedOptions,
  selectedOptionsMap,
  expandLineItemsInterfaceCb = noop,
  gridApi,
  thunk,
  action = 'lineitembulkadd'
) {
  const formState = yield select(getFormSelector(form))
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const groupNames = ['header', 'detail', 'final']
  const guid = getIn(formState, 'guid')
  let lineItems = getIn(formState, 'fields.lineItems.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  let data
  if (selectedOptions) {
    data = selectedOptions
  } else {
    data = Reflect.ownKeys(selectedOptionsMap).reduce(
      (acc, next) =>
        acc.concat({
          dataId: next,
          quantityOrdered: selectedOptionsMap[next]
        }),
      []
    )
  }

  const { duplicatesProcessed, processedPostData } = yield call(
    duplicateProductsRoutine,
    form,
    lineItems,
    data
  )

  const bulkProperties = duplicatesProcessed ? processedPostData : data

  if (!bulkProperties.length) {
    return
  }

  yield put(actions.addOptions.request(form))

  const { response, error } = yield call(api.massChange, {
    guid,
    action,
    groupNames,
    bulkProperties
  })

  if (response) {
    // yield put(actions.addOptions.success(response, form))
    yield put({
      type: CONSTANTS.ADD_OPTIONS.SUCCESS,
      meta: { form, thunk },
      payload: response
    })

    /* handle product notes modal(s) */
    yield fork(handleNewlyAddedProductNotes, form, lineItems, response.record)

    /* listen and wait for completion of the product notes loop */
    yield take(CONSTANTS.NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED)

    /* trigger the box quantity prompts */
    if (gridApi && !isMobile) {
      /* moved to a shared routine in externalHandlerSagas -- SVE 12/17/19 */
      yield delay(1000)
      yield fork(triggerBoxQuantityPrompts, form, gridApi)
    }

    if (
      isMobile &&
      response?.record?.detail?.lineItems &&
      Array.isArray(response?.record?.detail?.lineItems)
    ) {
      const newlyAdded = response.record.detail.lineItems.reduce(
        (acc, next) => {
          if (bulkProperties.find(x => x.dataId === next.dataId)) {
            acc = acc.concat({
              ...next,
              rowId: next?.uniqueKey
            })
          }
          return acc
        },
        []
      )

      /* we need to get the new images here after Save Cart or Options & Accessories -- SVE 1/27/2021 */
      yield put(
        getLineItemImages.try(form, {
          rowData: newlyAdded
        })
      )
    }

    if (
      expandLineItemsInterfaceCb &&
      typeof expandLineItemsInterfaceCb === 'function' &&
      !isMobile
    ) {
      expandLineItemsInterfaceCb(null, 'expand')
    }
  } else {
    // yield put(actions.addOptions.failure(error, form))
    yield put({
      type: CONSTANTS.ADD_OPTIONS.FAILURE,
      meta: { form, thunk },
      payload: error
    })

    if (error?.message) {
      yield fork(warningModal, error.message, 'Error!')
    }
  }
}

export function* saveCartListener(formListener) {
  while (true) {
    const action = yield take('SAVE_CART')
    if (action.meta.form === formListener) {
      yield fork(
        addOptionsProcess,
        action.meta.form,
        null,
        action.payload.cartMap,
        null,
        action.payload.api,
        action?.meta?.thunk
      )
    }
  }
}

export function* addOptionsListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { selectedOptions, expandLineItemsInterfaceCb, gridApi }
    } = yield take(CONSTANTS.ADD_OPTIONS.TRY)
    if (form === formListener) {
      yield fork(
        addOptionsProcess,
        form,
        selectedOptions,
        null,
        expandLineItemsInterfaceCb,
        gridApi,
        thunk
      )
    }
  }
}

export function* addBulkLineItemsListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { bulkProperties, gridApi, action }
    } = yield take(CONSTANTS.ADD_BULK_LINE_ITEMS)

    if (form === formListener) {
      yield fork(
        addOptionsProcess,
        form,
        bulkProperties,
        null,
        null,
        gridApi,
        thunk,
        action
      )
    }
  }
}

export function* clearRebateListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CLEAR_REBATE.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(clearRebateProcess, form, payload)
    }
  }
}

export function* clearRebateProcess(form, payload) {
  const { lineNumber } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['order', 'detail', 'final']
  /* is this right? I don't think there is an 'order' group -- SVE 2/3/21 */

  yield put(actions.clearRebate.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    lineNumber,
    guid,
    gridName: provisionalLineItem,
    properties: {
      clearRebate: null
    },
    groupNames
  })

  if (response) {
    yield put(
      actions.clearRebate.success(
        {
          lineNumber,
          ...response
        },
        form
      )
    )
  } else {
    yield put(actions.clearRebate.failure(error, form))
  }
}

export function* changeProvisionalLineItemListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.TRY)
    const {
      type,
      meta: { form },
      meta,
      payload
    } = action

    if (form === formListener) {
      if (
        payload.propertyName === 'quantity' ||
        payload.propertyName === 'quantityBO'
      ) {
        try {
          yield call(quantityToShipProcess, {
            type,
            meta,
            payload: {
              value: payload.value,
              data: payload.data,
              nextInput: payload.nextInput,
              field: payload.propertyName
            }
          })
          yield fork(
            changeProvisionalLineItemProcess,
            form,
            payload,
            meta,
            true
          )
        } catch (e) {
          // throw new Error(e)
          yield put({
            type: CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.FAILURE,
            error: true,
            meta
          })
        }
      } else {
        /* ALL OF THE PRICING INPUTS AND OTHERWISE!!!! --- SVE 11/6/19 */
        // debugger
        try {
          yield fork(
            changeProvisionalLineItemProcess,
            form,
            payload,
            meta,
            true
          )
        } catch (e) {
          // throw new Error(e)
          yield put({
            type: CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.FAILURE,
            error: true,
            meta
          })
        }
      }
    }
  }
}

// export function* initializeMultiSelectChangeProcess(form, payload) {
//   const { propertyId, value, gridApi, child } = payload
//   const formState = yield select(getFormSelector(form))
//   const guid = getIn(formState, 'guid') || null

//   if (guid && gridApi) {
//     const activeLineNumbers = gridApi.getSelectedRows().reduce((acc, next) => {
//       acc = acc.concat(child ? next.uniqueKey : next.lineNumber)

//       return acc
//     }, [])
//     yield put(actions.initializeMultiSelect.request(form))
//     const apiArgs = {
//       guid,
//       // activeLineNumbers,
//       action: 'multiline',
//       properties: {
//         [propertyId]: value
//       }
//     }
//     if (child) {
//       apiArgs.childLineNumber = activeLineNumbers
//     } else {
//       apiArgs.activeLineNumbers = activeLineNumbers
//     }

//     const { response, error } = yield call(api.massChange, apiArgs)

//     if (response) {
//       yield put(actions.initializeMultiSelect.success(response, form))
//       /* set the focus on the first line number in the array, otherwise the details tab can get out of whack -- SVE 3/16/2021 */
//       if (
//         gridApi &&
//         gridApi?.forEachNode &&
//         activeLineNumbers &&
//         Array.isArray(activeLineNumbers) &&
//         activeLineNumbers.length &&
//         activeLineNumbers[0] &&
//         response?.detail?.lineItems &&
//         Array.isArray(response.detail.lineItems) &&
//         response.detail.lineItems.length
//       ) {
//         const selectedRowIndex = response.detail.lineItems.findIndex(
//           x => x.lineNumber === activeLineNumbers[0]
//         )
//         if (is.number) {
//           gridApi.forEachNode(node => {
//             if (
//               node?.data?.lineNumber &&
//               node?.data?.lineNumber === activeLineNumbers[0]
//             ) {
//               node.setSelected(true)
//             } else {
//               node.setSelected(false)
//             }
//           })

//           yield put(
//             setSelectedRowIndex(form, {
//               propertyName: 'lineItems',
//               rowIndex: selectedRowIndex,
//               gridApi
//             })
//           )
//         }
//       }

//       if (propertyId === 'taxable' && response?.detail?.lineItems?.length) {
//         yield fork(
//           validateRetainTaxable,
//           form,
//           response.detail.lineItems,
//           activeLineNumbers
//         )
//       }
//     } else {
//       yield put(actions.initializeMultiSelect.failure(error, form))
//     }
//   }
// }

export function* changeProvisionalLineItemProcess(form, payload, meta, bool) {
  const {
    propertyName,
    lineNumber,
    value = 0,
    isComponent = false,
    data = {}
  } = payload
  // debugger
  const formState = yield select(getFormSelector(form))
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const guid = getIn(formState, 'guid')
  const groupNames = ['detail']
  const val = value === '' ? 0 : value

  if (!bool) {
    yield put(actions.changeProvisionalLineItem.request(form))
  } else if (isMobile && bool) {
    /*
      not sure why we would not want to send the request
      and trigger the isFetching flag. But OK, we are gonna
      do it on mobile for an improved UI -- SVE 9/15/20
    */
    yield put(actions.changeProvisionalLineItem.request(form))
  }

  let apiParams = {
    gridName: provisionalLineItem,
    guid,
    lineNumber,
    properties: {
      [propertyName]: val
    },
    groupNames
  }

  if (data?.parentLineNumber) {
    apiParams = {
      ...apiParams,
      lineNumber: data?.parentLineNumber,
      childLineNumber: data?.lineNumber,
      activeLineNumber: data?.parentLineNumber,
      activeLineNumbers: [data?.uniqueKey],
      additionalData: ['components']
    }
    // debugger
  }

  const { response, error } = yield call(api.changeGridItem, apiParams)
  debugger
  if (response) {
    yield put(
      actions.changeProvisionalLineItem.success(
        {
          lineNumber: apiParams?.lineNumber || lineNumber,
          parentLineNumber: data?.parentLineNumber,
          propertyName,
          ...response
        },
        meta || form
      )
    )
  } else {
    yield put(actions.changeProvisionalLineItem.failure(error, meta || form))
  }
}

export function* confirmProvisionalChangesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CONFIRM_PROVISIONAL_CHANGES.TRY)
    const {
      payload,
      meta: { form },
      meta
    } = action

    if (form === formListener) {
      // debugger
      yield fork(confirmProvisionalChangesProcess, form, meta, payload)
    }
  }
}

export function* confirmProvisionalChangesProcess(form, meta, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const lineNumber = payload?.lineNumber
    ? payload.lineNumber
    : getSelectedRowLineNumberFromFormState(formState)
  const groupNames = ['detail']
  const { data } = payload

  yield put(actions.confirmProvisionalChanges.request(form))

  let apiParams = {
    lineNumber,
    guid,
    gridName: provisionalLineItem,
    properties: {
      confirmProvisionalChanges: null
    },
    groupNames
  }

  if (data?.parentLineNumber) {
    apiParams = {
      ...apiParams,
      lineNumber: data?.parentLineNumber,
      childLineNumber: data?.lineNumber,
      activeLineNumber: data?.parentLineNumber,
      activeLineNumbers: [data?.uniqueKey],
      additionalData: ['components']
    }
    // debugger
  }

  const { response, error } = yield call(api.changeGridItem, apiParams)
  debugger
  if (response) {
    yield put(
      actions.onPropertyChange.success(
        {
          propertyChanged: 'lineItems',
          lineNumber,
          ...response
        },
        {
          form,
          thunk: meta.thunk
        }
      )
    )
  } else {
    yield put(actions.confirmProvisionalChanges.failure(error, form))
  }
}

export function* cancelProvisionalChangesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_PROVISIONAL_CHANGES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(cancelProvisionalChangesProcess, form, payload)
    }
  }
}

export function* cancelProvisionalChangesProcess(form, payload) {
  const { data } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const lineNumber = payload?.lineNumber
    ? payload.lineNumber
    : getSelectedRowLineNumberFromFormState(formState)
  const groupNames = ['order', 'detail', 'final']

  const rebateCostCleared = getIn(formState, 'rebateCostCleared')

  yield put(actions.cancelProvisionalChanges.request(form))

  let apiParams = {
    lineNumber,
    guid,
    gridName: provisionalLineItem,
    properties: {
      cancelProvisionalChanges: ''
    },
    groupNames
  }
  if (rebateCostCleared) {
    apiParams.properties = {
      cancelProvisionalChanges: 0
    }
  }

  if (data?.parentLineNumber) {
    apiParams = {
      ...apiParams,
      lineNumber: data?.parentLineNumber,
      childLineNumber: data?.lineNumber,
      activeLineNumber: data?.parentLineNumber,
      activeLineNumbers: [data?.uniqueKey],
      additionalData: ['components']
    }
    debugger
  }

  const { response, error } = yield call(api.changeGridItem, apiParams)

  if (response) {
    yield put(
      actions.onPropertyChange.success(
        {
          propertyChanged: 'lineItems',
          lineNumber,
          ...response
        },
        form
      )
    )
  } else {
    yield put(actions.cancelProvisionalChanges.failure(error, form))
  }
}

export function* openPreviousOrder(form, dataId) {
  yield put(actions.clearOrder(form))
  yield put(setField(form, 'dataId', dataId))
}

export function* openPreviousOrderListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { dataId }
    } = yield take(CONSTANTS.OPEN_PREVIOUS_ORDER)

    if (form === formListener) {
      yield fork(openPreviousOrder, form, dataId)
    }
  }
}

export function* setSelectedRowIndexListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex, gridApi, childIndex }
    } = yield take(DDICONSTANTS.SET_SELECTED_ROW_INDEX)
    // debugger
    if (
      form === formListener &&
      propertyName === 'lineItems' &&
      is.number(rowIndex) &&
      rowIndex >= 0
    ) {
      yield fork(
        readLineItemDataProcess,
        form,
        rowIndex,
        gridApi,
        true,
        null,
        childIndex
      )
    }
  }
}

export function* saveRepairItemDataProcess(form, payload) {
  const { lineNumber, data, cb } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  yield put(actions.saveRepairItemData.request(form))

  // debugger
  const { response, error } = yield call(api.changeGridItem, {
    lineNumber,
    gridName: 'lineItems',
    groupNames,
    guid,
    properties: {
      repairInfo: {
        ...data
      }
    }
  })

  if (response) {
    yield put(actions.saveRepairItemData.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.saveRepairItemData.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* saveRepairItemDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.SAVE_REPAIR_ITEM_DATA.TRY)

    if (form === formListener) {
      yield fork(saveRepairItemDataProcess, form, payload)
    }
  }
}

export function* launchRepairItemModalInterfaceProcess(
  form,
  rowIndex,
  data,
  gridApi
) {
  let repairItemData = data

  if (repairItemData.hasAdditionalData) {
    yield call(readLineItemDataProcess, form, rowIndex, gridApi, false)
    const formState = yield select(getFormSelector(form))
    const lineItems = getIn(formState, 'fields.lineItems.rowData')
    repairItemData = lineItems.get(rowIndex)
    repairItemData =
      repairItemData && repairItemData.toJS ? repairItemData.toJS() : {}
  }

  if (repairItemData.lineNumber) {
    yield fork(
      handleRepairItemModalProcess,
      form,
      repairItemData.lineNumber,
      repairItemData
    )
  }
}

export function* launchRepairItemModalInterfaceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex, data, gridApi }
    } = yield take(CONSTANTS.LAUNCH_REPAIR_ITEM_MODAL_INTERFACE)

    if (form === formListener) {
      // debugger
      yield fork(
        launchRepairItemModalInterfaceProcess,
        form,
        rowIndex,
        data,
        gridApi
      )
    }
  }
}

export function* launchCSVUploadInterfaceProcess(form, gridApi) {
  const modalOpts = {
    component: UploadCSVModal,
    options: {
      width: 300,
      maxHeight: '100%',
      title: 'Upload CSV',
      data: {
        form,
        actions: [
          {
            title: 'OK',
            primary: true,
            async clickEvent(args, fs, cb) {
              const { bulkProperties, fileName } = this.state
              if (
                !this.props.dispatch ||
                !fileName ||
                !bulkProperties?.length
              ) {
                this.setState({
                  errorMessage: 'Please upload a valid CSV file'
                })
                return
              }

              if (cb && typeof cb === 'function') {
                cb()
              }

              try {
                await this.props.dispatch(
                  actions.addBulkLineItems(form, {
                    bulkProperties,
                    gridApi
                  })
                )
              } finally {
                console.log('done')
                // if (cb && typeof cb === 'function') {
                //   cb()
                // }
              }
            }
          },
          {
            title: 'Cancel',
            primary: true
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* launchCSVUploadInterfaceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { gridApi }
    } = yield take(CONSTANTS.LAUNCH_CSV_UPLOAD_INTERFACE)

    if (form === formListener) {
      yield fork(launchCSVUploadInterfaceProcess, form, gridApi)
    }
  }
}

export function* launchCustomerPartNumberModalListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_CUSTOMER_PART_NUMBER_MODAL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(launchCustomerPartNumberModalProcess, form, payload)
    }
  }
}

export function* launchCustomerPartNumberModalProcess(form, payload) {
  /* this one needs fixing, its using the uniqueKey that no longer exists -- SVE 2/3/2021 */
  const { uniqueKey } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.launchCustomerPartNumberModal.request(form))
  const args = {
    action: 'editlock',
    guid,
    uniqueKey
  }
  if (payload.childLineNumber) {
    args.childLineNumber = payload.childLineNumber
  }
  const { response, error } = yield call(api.partNumberPropertyChange, args)

  if (response) {
    yield put(actions.launchCustomerPartNumberModal.success(response, form))
    const modalOpts = {
      component: CustomerPartNumberModal,
      options: {
        width: 450,
        actions: CustomerPartNumberModalActions,
        data: {
          form,
          ...payload,
          ...response
        },
        padding: '5px',
        title: 'Part Number'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    // return modal.id
  } else {
    yield put(actions.launchCustomerPartNumberModal.failure(error, form))
  }

  return null
}

export function* cancelEditCustomerPartNumberListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_EDIT_PART_NUMBER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(cancelEditCustomerPartNumberProcess, form, payload)
    }
  }
}

export function* cancelEditCustomerPartNumberProcess(form, payload) {
  /* same as above, check this out with the uniqueKey */
  const { modalId, uniqueKey } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.cancelEditPartNumber.request(form))
  const args = {
    action: 'canceledit',
    guid,
    uniqueKey
  }
  if (payload.childLineNumber) {
    args.childLineNumber = payload.childLineNumber
  }
  const { response, error } = yield call(api.partNumberPropertyChange, args)

  if (response) {
    yield put(actions.cancelEditPartNumber.success(response, form))
    // close modal
    yield put(confirm(form, modalId))
  } else {
    yield put(actions.cancelEditPartNumber.failure(error, form))
  }
}

export function* deletePartNumberListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_PART_NUMBER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(deletePartNumberProcess, form, payload)
    }
  }
}

export function* deletePartNumberProcess(form, payload) {
  const { uniqueKey, modalId } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.deletePartNumber.request(form))
  const args = {
    action: 'delete',
    uniqueKey,
    guid
  }
  if (payload.childLineNumber) {
    args.childLineNumber = payload.childLineNumber
  }
  const { response, error } = yield call(api.partNumberPropertyChange, args)

  if (response) {
    yield put(actions.deletePartNumber.success(response, form))

    if (modalId) {
      yield put(confirm(form, modalId))
    }
  } else {
    const { status, validationErrors = [] } = error
    yield put(actions.deletePartNumber.failure(error, form))

    if (status === 498) {
      const errorMessage = validationErrors[0]?.message
      yield call(warningModal, errorMessage, 'Attention!')
    }
  }
}

export function* saveCustomerPartNumberListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_PART_NUMBER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(saveCustomerPartNumberProcess, form, payload)
    }
  }
}

export function* saveCustomerPartNumberProcess(form, payload) {
  const { modalId, uniqueKey } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const newPartNumber =
    getIn(formState, 'fields.selectedCustomerPartNumber.value') || ''

  yield put(actions.savePartNumber.request(form))
  const args = {
    action: 'save',
    guid,
    uniqueKey,
    partNumber: newPartNumber
  }
  if (payload.childLineNumber) {
    args.childLineNumber = payload.childLineNumber
  }
  const { response, error } = yield call(api.partNumberPropertyChange, args)

  if (response) {
    yield put(actions.savePartNumber.success(response, form))

    if (modalId) {
      yield put(confirm(form, modalId))
    }
  } else {
    yield put(actions.savePartNumber.failure(error, form))
  }
}

export function* initializeMultiSelectChangeListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.INITIALIZE_MULTI_SELECT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(initializeMultiSelectChangeProcess, form, payload)
    }
  }
}

export function* initializeMultiSelectChangeProcess(form, payload) {
  const { propertyId, value, gridApi, child } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid') || null

  if (guid && gridApi) {
    const activeLineNumbers = gridApi.getSelectedRows().reduce((acc, next) => {
      acc = acc.concat(child ? next.uniqueKey : next.lineNumber)

      return acc
    }, [])
    yield put(actions.initializeMultiSelect.request(form))

    const { response, error } = yield call(api.massChange, {
      guid,
      activeLineNumbers,
      action: 'multiline',
      properties: {
        [propertyId]: value
      }
    })

    if (response) {
      yield put(actions.initializeMultiSelect.success(response, form))
      /* set the focus on the first line number in the array, otherwise the details tab can get out of whack -- SVE 3/16/2021 */
      if (
        gridApi &&
        gridApi?.forEachNode &&
        activeLineNumbers &&
        Array.isArray(activeLineNumbers) &&
        activeLineNumbers.length &&
        activeLineNumbers[0] &&
        response?.detail?.lineItems &&
        Array.isArray(response.detail.lineItems) &&
        response.detail.lineItems.length
      ) {
        const selectedRowIndex = response.detail.lineItems.findIndex(
          x => x.lineNumber === activeLineNumbers[0]
        )
        if (is.number) {
          gridApi.forEachNode(node => {
            if (
              node?.data?.lineNumber &&
              node?.data?.lineNumber === activeLineNumbers[0]
            ) {
              debugger
              node.setSelected(true)
            } else {
              debugger
              node.setSelected(false)
            }
          })

          yield put(
            setSelectedRowIndex(form, {
              propertyName: 'lineItems',
              rowIndex: selectedRowIndex,
              gridApi
            })
          )
        }
      }

      if (propertyId === 'taxable' && response?.detail?.lineItems?.length) {
        yield fork(
          validateRetainTaxable,
          form,
          response.detail.lineItems,
          activeLineNumbers
        )
      }
    } else {
      yield put(actions.initializeMultiSelect.failure(error, form))
    }
  }
}

export function* validateRetainTaxable(
  form,
  lineItems,
  activeLineNumbers = []
) {
  // const formState = yield select(getFormSelector(form))
  debugger
  const lineItemsWithPrompt = lineItems?.filter(
    x =>
      activeLineNumbers.includes(x.lineNumber) && x.shouldPromptForRetainTaxable
  )

  if (lineItemsWithPrompt.length) {
    const modalOpts = {
      component: MultiSelectRetainTaxablePromptModal,
      options: {
        width: 550,
        data: {
          actions: [
            {
              primary: true,
              title: 'Yes'
            },
            { secondary: true, cancel: true, title: 'No' }
          ],
          lineItems: lineItemsWithPrompt,
          form
        },
        padding: '5px',
        title: 'Retain Taxable'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)

    const action = yield take([CONFIRMED, CANCELED])

    if (action.type === CONFIRMED) {
      const formState = yield select(getFormSelector(form))
      const guid = getIn(formState, 'guid')

      /* 
        Lyndon: aren't we passing this into the funtion?
        this seems like an error / bad code to me
        -- SVE 2/17/2021
      */

      const activeLineNumbers = getIn(
        formState,
        'ui.selectedMultiSelectRetainTaxable'
      )?.toJS
        ? getIn(formState, 'ui.selectedMultiSelectRetainTaxable').toJS()
        : []

      debugger
      const { response, error } = yield call(api.massChange, {
        guid,
        groupNames: ['header', 'detail'],
        activeLineNumbers,
        action: 'multiline',
        properties: {
          retaintaxable: null
        }
      })

      if (response) {
        yield put(actions.initializeMultiSelect.success(response, form))
      } else {
        yield put(actions.initializeMultiSelect.failure(error, form))
      }
    } else {
      // clear our selected if answer is 'No'
      yield put(actions.setSelectedMultiRetainTaxable(form, []))
    }

    return modal.id
  }

  return null
}

export function* openPromiseDateModalListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_PROMISE_DATE_MODAL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openPromiseDateModalProcess, form, payload)
    }
  }
}

export function* openPromiseDateModalProcess(form, payload) {
  const modalOpts = {
    component: PromiseDateModal,
    options: {
      width: 450,
      data: {
        actions: [
          { primary: true, title: 'Ok' },
          { secondary: true, title: 'Cancel', cancel: true }
        ],
        form,
        ...payload
      },
      padding: '5px',
      title: 'Set Promise Date'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  const { type } = yield take([CONFIRMED, CANCELED])

  if (type === CONFIRMED) {
    yield fork(executeMultiSelectPromisedDateProcess, form, payload)
  } else {
    yield put(actions.setPromiseDate.failure({}, form))
  }

  // return modal.id
}

export function* executeMultiSelectPromisedDateProcess(form, payload) {
  const { gridApi } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const promisedDate =
    getIn(formState, 'fields.multiSelectPromisedDate.value') || null
  const groupNames = ['header', 'detail']

  if (guid && gridApi) {
    const activeLineNumbers = gridApi.getSelectedRows().reduce((acc, next) => {
      acc = acc.concat(next.lineNumber)

      return acc
    }, [])

    yield put(actions.setPromiseDate.request(form))

    const { response, error } = yield call(api.massChange, {
      guid,
      activeLineNumbers,
      action: 'multiline',
      properties: { promisedDate },
      groupNames
    })

    if (response) {
      // yield put(actions.initializeMultiSelect.success(response, form))
      yield put(actions.setPromiseDate.success(response, form))
    } else {
      yield put(actions.setPromiseDate.failure(error, form))
    }
  }

  return null
}

export function* getWarehouseInventoryProcess(form, thunk, gridId, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid')
  const isFetchingAdditionalData =
    getIn(formState, 'isFetchingAdditionalData') || false
  const isPosting = getIn(formState, 'isPosting') || false

  if (isPosting) {
    /* make sure this process completes AND does not make 2 API calls */
    yield take(CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS)
    yield fork(getWarehouseInventoryProcess, form, thunk, gridId, lineNumber)
    return
  }

  if (!lineNumber || !gridId) {
    return
  }

  if (isFetchingAdditionalData) {
    yield take(CONSTANTS.READ_LINE_ITEM.SUCCESS)
    const updatedFormState = yield select(getFormSelector(form))
    let additionalDataMap =
      getIn(updatedFormState, 'additionalDataMap') || fromJS({})
    additionalDataMap = additionalDataMap?.toJS ? additionalDataMap.toJS() : {}
    const lineItems =
      getIn(updatedFormState, 'fields.lineItems.rowData') || fromJS([])
    const rowIndex = lineItems.findIndex(
      x => x.get('lineNumber') === lineNumber
    )
    if (is.number(rowIndex)) {
      const rowId = lineItems.get(rowIndex).get('rowId')
      const hasData =
        additionalDataMap?.[rowId]?.inventory?.companies?.find(
          x => x?.dataId === gridId
        )?.data?.length || false
      if (hasData) {
        return
      }
    } else {
      yield fork(getWarehouseInventoryProcess, form, thunk, gridId, lineNumber)
      return
    }
  }

  const apiParams = guid
    ? {
        guid,
        dataId,
        lineNumber,
        inventoryNames: [gridId]
      }
    : {
        dataId,
        lineNumber,
        inventoryNames: [gridId]
      }

  yield put(actions.getWarehouseInventory.request(form))
  const apiMethod =
    form && form?.match(/invoiceInquiry/)
      ? readAdditionalInvoiceDataAPI
      : api.readLineItem
  debugger
  const { response, error } = yield call(apiMethod, apiParams)

  if (response) {
    // debugger
    yield put({
      type: CONSTANTS.GET_WAREHOUSE_INVENTORY.SUCCESS,
      meta: { form, thunk },
      payload: response
    })

    const exception =
      response?.inventory?.companies?.find(x => x?.dataId === gridId)
        ?.exception || null
    if (exception && exception?.message && exception?.modalTitle) {
      /* exception occurs at the line item level */
      yield fork(warningModal, exception.message, exception.modalTitle)
    }
  } else {
    yield put({
      type: CONSTANTS.GET_WAREHOUSE_INVENTORY.FAILURE,
      meta: { form, thunk },
      payload: error
    })
  }
}

export function* getWarehouseInventoryListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { gridId, lineNumber }
    } = yield take(CONSTANTS.GET_WAREHOUSE_INVENTORY.TRY)

    if (form === formListener) {
      yield delay(100)
      yield fork(getWarehouseInventoryProcess, form, thunk, gridId, lineNumber)
    }
  }
}

export function* readLineItemListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { additionalDataType, rowIndex, gridApi }
    } = yield take(CONSTANTS.READ_LINE_ITEM.TRY)

    if (form === formListener) {
      yield fork(
        readLineItemDataProcess,
        form,
        rowIndex,
        gridApi,
        true,
        additionalDataType
      )
    }
  }
}

export default function* detailSagas(form) {
  yield fork(addRowButtonListener, form)
  yield fork(onCellChangedListener, form)
  yield fork(removeLineItemListener, form)
  yield fork(updateLineItemListener, form)
  yield fork(setWarehouseListener, form)
  yield fork(getSDSListener, form)
  yield fork(getDocSpecListener, form)
  yield fork(openCommentsListener, form)
  yield fork(setCommentListener, form)
  yield fork(deleteCommentListener, form)
  yield fork(addOptionsListener, form)
  yield fork(clearRebateListener, form)
  yield fork(changeProvisionalLineItemListener, form)
  yield fork(confirmProvisionalChangesListener, form)
  yield fork(cancelProvisionalChangesListener, form)
  yield fork(openPreviousOrderListener, form)
  yield fork(setSelectedRowIndexListener, form)
  yield fork(saveCartListener, form)
  yield fork(saveRepairItemDataListener, form)
  yield fork(launchRepairItemModalInterfaceListener, form)
  yield fork(launchCSVUploadInterfaceListener, form)
  yield fork(addBulkLineItemsListener, form)
  yield fork(launchCustomerPartNumberModalListener, form)
  yield fork(cancelEditCustomerPartNumberListener, form)
  yield fork(deletePartNumberListener, form)
  yield fork(saveCustomerPartNumberListener, form)
  yield fork(initializeMultiSelectChangeListener, form)
  yield fork(openPromiseDateModalListener, form)
  yield fork(getWarehouseInventoryListener, form)
  yield fork(productImportSagas, form)
  yield fork(readLineItemListener, form)
}
