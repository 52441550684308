import {
  take,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'
import { startCase } from 'lodash'

import { api } from 'services'
import { warningModal, confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED, HIDE_MODAL } from 'modals/constants'

import { formatDateFields, is } from 'utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH,
  FOUND_INVALID_VALUES
} from './constants'

import getColumnDefs from './getColumnDefs'
import GridCell from './GridCell'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* exactMatchProcess({
  form,
  keyword,
  customerId,
  propertyName,
  warehouseId,
  productId,
  uomId,
  searchType = 'A',
  transactionType = 'S',
  transactionId = '',
  returnTransactionId = '',
  sourceBinId = '',
  salesHistoryCustomerId = '',
  salesHistoryInvoiceId = '',
  allowInvalidValues = false,
  includedSerialNumbers = '',
  excludedSerialNumbers = '',
  thunk,
  ...rest
}) {
  const params = {
    keyword,
    customerId,
    warehouseId,
    productId,
    searchType,
    transactionType,
    transactionId,
    returnTransactionId,
    sourceBinId,
    salesHistoryCustomerId,
    salesHistoryInvoiceId,
    uomId,
    allowInvalidValues,
    includedSerialNumbers,
    excludedSerialNumbers
  }
  // debugger

  const { response, error } = yield call(
    api.serialNumberExactMatchSearch,
    params
  )

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  form,
  thunk,
  keyword,
  onCellSelected,
  customerId,
  productId,
  propertyName,
  uomId,
  warehouseId,
  transactionId = '',
  returnTransactionId = '',
  sourceBinId = '',
  salesHistoryCustomerId = '',
  salesHistoryInvoiceId = '',
  searchType = 'A',
  transactionType = 'S',
  allowInvalidValues = false,
  includedSerialNumbers,
  excludedSerialNumbers,
  ...rest
}) {
  const params = {
    keyword,
    customerId,
    productId,
    propertyName,
    uomId,
    warehouseId,
    transactionId,
    returnTransactionId,
    sourceBinId,
    salesHistoryCustomerId,
    salesHistoryInvoiceId,
    searchType,
    transactionType,
    allowInvalidValues,
    includedSerialNumbers,
    excludedSerialNumbers
  }
  // debugger

  const { response, error } = yield call(
    api.serialNumberPartialMatchSearch,
    params
  )

  if (response) {
    const serialNumberColumn = {
      cellRendererFramework: GridCell,
      cellRendererParams: { onCellSelected },
      field: 'name',
      headerName: 'Serial Number'
    }

    const visibilityParams = Object.keys(response).reduce((acc, next) => {
      if (is.bool(response?.[next]) && next !== 'matchFound') {
        acc[next] = response[next]
      }
      return acc
    }, {})
    // debugger

    const columnDefs = getColumnDefs(
      serialNumberColumn,
      searchType,
      visibilityParams
    )

    const rowData = response.results || []
    const grid = {
      meta: {},
      rowData,
      columnDefs
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName,
        warehouseIdAndDescription:
          response?.warehouseIdAndDescription || warehouseId
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)

    const {
      payload: {
        keyword,
        customerId,
        productId,
        propertyName,
        uomId,
        warehouseId,
        transactionId,
        returnTransactionId,
        sourceBinId,
        salesHistoryCustomerId,
        salesHistoryInvoiceId,
        searchType,
        transactionType,
        allowInvalidValues,
        includedSerialNumbers,
        excludedSerialNumbers,
        onCellSelected
      },
      meta: { form, thunk }
    } = action
    // debugger

    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      thunk,
      customerId,
      keyword,
      productId,
      propertyName,
      uomId,
      warehouseId,
      transactionId,
      returnTransactionId,
      sourceBinId,
      salesHistoryCustomerId,
      salesHistoryInvoiceId,
      searchType,
      transactionType,
      allowInvalidValues,
      includedSerialNumbers,
      excludedSerialNumbers,
      onCellSelected
    })
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
}) {
  const { response, error } = yield call(api[apiName], {
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

export function* foundInvalidValuesListener() {
  while (true) {
    const action = yield take(FOUND_INVALID_VALUES)
    const {
      payload: {
        value,
        propertyName,
        message = 'Serial number not found. Accept?',
        modalTitle = 'Not Found'
      }
    } = action
    const { meta } = action
    yield call(
      confirmationModal,
      message,
      modalTitle,
      {},
      { title: 'YES' },
      { title: 'NO' }
    )
    const confirmed = yield take([CONFIRMED, CANCELED])
    if (confirmed.type === CONFIRMED) {
      yield put({
        type: `${FOUND_INVALID_VALUES}_SUCCESS`,
        payload: { value },
        meta
      })
    } else {
      yield put({
        type: `${FOUND_INVALID_VALUES}_REJECTED`,
        payload: { propertyName, value },
        meta,
        error: true
      })
    }
  }
}

export default function* serialNumberSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(getTooltipListener)
  yield fork(foundInvalidValuesListener)
}
