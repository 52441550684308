import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InputAdornment,
  Icon,
  IconButton,
  Input,
  TextField
} from '@material-ui/core'
import {
  getErrorIcon,
} from 'components/EditableGrid/utils'


export class RollupsCellRenderer extends Component {

  _isMounted = false

  static defaultProps = {
    disabled: false,
    isRequired: false
  }

  constructor(props) {
    super(props)
    // console.log(props)
    const {
      value,

    } = this.props

    const state = {
      textFieldVal: value || value === 0 ? value : ''
    }

    this.state = state
  }

  componentDidMount() {
    this._isMounted = true

    // findDOMNode(this.textField).addEventListener('keydown', this.onKeyDown)
  }

  showRequiredIcon = () => {
    const {
      value,
      valueFormatted,
      errorMessage,
      isRequired,
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }

    return null
  }



  render() {
    const {
      colDef: {
        field,
      },
      form,
      rowIndex,
      textAlign,
      value,
      valueFormatted
    } = this.props

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
      >
        {this.showRequiredIcon()}
        <TextField
          onBlur={this.onBlur}
          value={valueFormatted || value }
          id={`text-field-${form}-${rowIndex}`}
          fullWidth
          style={{
            height: '100%',
            width: '100%'
          }}
          inputProps={{
            style: {
              height: '100%',
              // padding: 0,
              width: '100%',
              textAlign: textAlign || 'left'
            }
          }}
          inputRef={el => (this.textField = el)}
          forwardRef
        />
      </div>
    )
  }
}

export default RollupsCellRenderer
