import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Grid from 'grid'
import { formatNumber, noop } from 'utils'
import { isEqual } from 'lodash'
import { withContext } from 'ddiForm'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'

const getRowNodeId = data => data.dataId

const CategoryPricingTab = ({
  dispatch,
  isEditing,
  form,
  handleTabChange = noop,
  priceChange,
  lineNumber
}) => {
  function onRowSelected(params) {
    if (isEditing && params && params.data && !!params.data.dataId) {
      dispatch(
        changeProvisionalLineItem.try(form, {
          propertyName: 'setCategoryPrice',
          lineNumber,
          value: params.data.dataId
        })
      )
      handleTabChange(null, 'Price')
    }
  }

  const columnDefs = [
    {
      checkboxSelection: isEditing,
      width: 50,
      suppressMenu: true
    },
    {
      field: 'dataId',
      headerName: 'Category',
      suppressMenu: true
    },
    {
      field: 'description',
      headerName: 'Description',
      suppressMenu: true
    },
    {
      field: 'formula',
      headerName: 'Formula',
      suppressMenu: true
    },
    {
      cellClass: 'align-right',
      field: 'price',
      headerClass: 'align-right',
      headerName: 'Price',
      valueFormatter: params => formatNumber(params.value, '0.00000'),
      suppressMenu: true
    }
  ]

  return (
    <Grid
      getRowNodeId={getRowNodeId}
      columnDefs={columnDefs}
      height={360}
      onRowSelected={onRowSelected}
      rowData={
        priceChange && priceChange.categoryPrices
          ? priceChange.categoryPrices
          : []
      }
    />
  )
}

CategoryPricingTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  priceChange: PropTypes.object.isRequired
}

const contextObj = {
  isEditing: form => form.get('isEditing') || false
}

/* fixed 9/24/2020 SVE */
export default memo(withContext(contextObj)(CategoryPricingTab), isEqual)
