import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { formatNumber } from 'utils'
import { generateCellStyle } from 'pages/SalesOrder/utils'
import { isEqual } from 'lodash'

import '../styles/pricing-details.scss'

const paragraphStyle = {
  textAlign: 'center',
  fontSize: '1em',
  lineHeight: '18px',
  margin: '10px 0 0 0'
}

export const PricingDetails = memo(({ priceChange = {}, canSeeCost }) => {
  // const [canSeeCost, setCanSeeCost] = useState(false)

  // useEffect(() => {

  //   if (canSeeCost) {
  //     setCanSeeCost(canSeeCost)
  //   }

  // }, [canSeeCost])

  return (
    <div
      className="pricing-details-outer-wrapper"
      style={{ height: '100%', maxHeight: '100%' }}
    >
      <div className="pricing-details-flex-wrapper">
        <DDICardWrapper
          title="Pricing"
          style={{ margin: '5px 5px 0 5px' }}
          contentStyle={{ padding: 10 }}
        >
          <div className="pricing-details-outer-wrapper">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Net Price:</TableCell>
                  <TableCell style={{ fontWeight: 500 }} align="right">
                    {formatNumber(priceChange.netPrice, '0,0.0000')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Extension:</TableCell>
                  <TableCell style={{ fontWeight: 500 }} align="right">
                    {formatNumber(priceChange.priceExtension, '0,0.00')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <p className="pricing-details-formula-paragraph">
              Formula
              <br />
              <span className="pricing-details-bold-text">
                {priceChange.priceMethodResolved}
              </span>
            </p>
          </div>
        </DDICardWrapper>

        <DDICardWrapper
          title="Costing"
          style={{ margin: '5px 5px 0 5px' }}
          contentStyle={{ padding: 10 }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Net Cost</TableCell>
                <TableCell style={{ fontWeight: 500 }} align="right">
                  {canSeeCost &&
                    formatNumber(priceChange.netCost, '0,0,0,0.0000')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Extension</TableCell>
                <TableCell style={{ fontWeight: 500 }} align="right">
                  {canSeeCost &&
                    formatNumber(priceChange.costExtension, '0,0,0,0.00')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <p className="pricing-details-formula-paragraph">
            Formula
            <br />
            {canSeeCost && (
              <span className="pricing-details-bold-text">
                {priceChange.costMethodResolved}
              </span>
            )}
          </p>
        </DDICardWrapper>
      </div>

      <div className="pricing-details-flex-wrapper">
        <DDICardWrapper
          title="Gross Profit"
          style={{ margin: '0 5px 5px 5px' }}
          contentStyle={{ padding: 10 }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Percent</TableCell>

                {canSeeCost && (
                  <TableCell
                    align="right"
                    style={generateCellStyle(priceChange.grossProfitPercent)}
                  >
                    {formatNumber(priceChange.grossProfitPercent, '0.00')}
                  </TableCell>
                )}
              </TableRow>

              <TableRow>
                <TableCell>Dollars</TableCell>

                {canSeeCost && (
                  <TableCell
                    align="right"
                    style={generateCellStyle(
                      priceChange.grossProfitDollarsEach
                    )}
                  >
                    {formatNumber(
                      priceChange.grossProfitDollarsEach,
                      '$0,0.00'
                    )}
                  </TableCell>
                )}
              </TableRow>

              <TableRow>
                <TableCell>Total</TableCell>

                {canSeeCost && (
                  <TableCell
                    align="right"
                    style={generateCellStyle(priceChange.grossProfitDollars)}
                  >
                    {formatNumber(priceChange.grossProfitDollars, '$0,0.00')}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </DDICardWrapper>
      </div>
    </div>
  )
}, isEqual)

PricingDetails.propTypes = {
  canSeeCost: PropTypes.bool.isRequired,
  priceChange: PropTypes.object.isRequired
}

export default PricingDetails
