/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import {
  DDITextField,
  DDIToggle,
  DDINumberSpinner,
  DDIIndexSearch,
  DDISelectField
} from 'ddiForm/wrapped'
import { maxLength } from 'ddiForm/validations'

import {
  AppBar,
  Paper,
  Tabs,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DDICardWrapper from 'components/DDICardWrapper'
import masterStyles from 'theme/masterStyles'
import {
  setGridHeight,
  numberFilterGridConfigs,
  dateFilterGridConfigs
} from 'pages/ProductMaster/utils'
import {
  generateTabs,
  layoutFlex,
  getValue,
  getMeta,
  emptyList,
  getField,
  plainDeepEqual
} from 'utils'
import { createTextArea } from 'pages/ProductMaster/tabs/Setup/Main'

/* Editable Grid Stuffs */
import EditableGrid from 'components/EditableGrid'
import ClearGridRowCell from 'components/EditableGrid/components/ClearGridRowCell'
import SelectGridCell from 'components/EditableGrid/components/SelectGridCell'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import DatepickerGridCell from 'components/EditableGrid/components/DatepickerGridCell'

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

const ContainedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1
  }
})(Backdrop)

const numberFields = [
  {
    propertyName: 'weight',
    label: 'Weight'
  },
  {
    propertyName: 'volume',
    label: 'Volume'
  },
  {
    propertyName: 'length',
    label: 'Length'
  },
  {
    propertyName: 'width',
    label: 'Width'
  },
  {
    propertyName: 'height',
    label: 'Height'
  },
  {
    propertyName: 'boxQuantity',
    label: 'Box Quantity'
  }
]

const numberFieldsRight = [
  {
    propertyName: 'cartonQuantity',
    label: 'Carton Quantity'
  },
  {
    propertyName: 'palletQuantity',
    label: 'Pallet Quantity'
  },
  {
    propertyName: 'palletLayer',
    label: 'Pallet Layer'
  },
  {
    propertyName: 'minStockQuantity',
    label: 'Min Stock Quanitity'
  },
  {
    propertyName: 'maxStockQuantity',
    label: 'Max Stock Quanitity'
  }
]

const generateNumberFields = memoize((propertyName, label) => (
  <DDITextField
    propertyName={`${propertyName}`}
    id={propertyName}
    label={label}
    mask="numeric"
    inputProps={{
      noDecimalLimit: true
    }}
    fullWidth
    wrapperStyle={{ position: 'relative', marginBottom: 10 }}
  />
))

export class Purchasing extends Component {
  static propTypes = {
    forecastFormulaOptions: PropTypes.array.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    palletSizeOptions: PropTypes.array.isRequired,
    statusType: PropTypes.string.isRequired,
    stockOptions: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: 'External'
    }

    this.numberFields = numberFields.map((item, idx) =>
      generateNumberFields(item.propertyName, item.label)
    )

    this.numberFieldsRight = numberFieldsRight.map((item, idx) =>
      generateNumberFields(item.propertyName, item.label)
    )

    this.externalPurchaseOrderComments = createTextArea(
      'externalPurchaseOrderComments'
    )
    this.internalPurchaseOrderComments = createTextArea(
      'internalPurchaseOrderComments'
    )
  }

  handleTabChange = (event, value) => {
    if (typeof value === 'string') this.setState({ tab: value })
  }

  getColumnDefs = memoize(props => {
    const {
      forecastFormulaOptions,
      stockOptions,
      isEditing,
      hasRecord,
      statusType
    } = this.props

    const params = {
      form: props.form,
      propertyName: props.propertyName,
      disabledFn: data => {
        if (
          (statusType === '' || statusType === 'N') &&
          hasRecord &&
          isEditing
        ) {
          return false
        }

        return true
      }
    }

    let colDefs = [
      {
        field: 'description',
        headerName: 'Warehouse',
        editable: false,
        cellStyle: { color: isEditing ? '#444' : '#777' },
        suppressNavigable: true
      },
      {
        field: 'stockType',
        headerName: 'Stock?',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          values: stockOptions?.toJS ? stockOptions.toJS() : []
        }
      },
      {
        field: 'minQuantity',
        headerName: 'Min Qty',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          noDecimalLimit: true,
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'maxQuantity',
        headerName: 'Max Qty',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          noDecimalLimit: true,
          textAlign: 'right',
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'minMaxExpirationDate',
        headerName: 'Min/Max Exp Date',
        cellRendererFramework: DatepickerGridCell,
        cellRendererParams: {
          ...params
        },
        ...dateFilterGridConfigs
      },
      {
        field: 'forecastId',
        headerName: 'Forecast Formula',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          values: forecastFormulaOptions?.toJS
            ? forecastFormulaOptions.toJS()
            : []
        }
      },
      {
        field: 'forecastFreeze',
        headerName: 'Freeze Forecast?',
        headerClass: 'text-center',
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          toggleAllowedKey: 'canChangeForecastFreeze'
        }
      },
      {
        field: 'leadDays',
        headerName: 'Lead Days',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          formatter: 'number',
          range: [0, 999999999.99]
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'safetyStockPercentage',
        headerName: 'Safety Stock %',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          formatter: 'number',
          decimalScale: 2,
          textAlign: 'right',
          fixedDecimalScale: true,
          range: [0, 99999999.99]
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'transferPackageQuantity',
        headerName: 'Transfer Pkg Qty',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          formatter: 'number',
          textAlign: 'right',
          range: [0, 999999999.99]
        },
        ...numberFilterGridConfigs
      }
    ]

    if (props.isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'delete',
          headerName: '',
          cellRendererFramework: ClearGridRowCell,
          cellRendererParams: {
            ...params,
            defaultResets: {
              forecastFreeze: false,
              stockType: 'D'
            },
            skipFields: ['dataId', 'description']
          },
          editable: false,
          width: 50,
          maxWidth: 50
        }
      ]
    }

    return colDefs
  }, plainDeepEqual)

  setGridHeight = memoize(arr => {
    const height = setGridHeight(arr) + 30
    return height < 200 ? 200 : height
  })

  render() {
    const {
      isEditing,
      isPosting,
      warehouseProcurements,
      palletSizeOptions,
      purchasingUOM,
      shipperIntegrationOriginCodeMaxLength
    } = this.props

    const { tab } = this.state
    const labelStyle = {
      color: '#444',
      fontSize: 12,
      fontWeight: 'normal',
      marginBottom: 0,
      marginRight: 10
    }

    return (
      <div className="product-master-main-wrapper" style={layoutFlex('120rem')}>
        <div style={{ width: '100%' }}>
          <div style={{ padding: '0 10px 10px 10px', width: '100%' }}>
            <Paper
              style={{
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                margin: 0,
                maxWidth: '100%',
                padding: '10px 20px',
                width: '100%'
              }}
            >
              <DDIToggle
                propertyName="isSeasonal"
                label="Seasonal"
                labelPlacement="start"
                wrapperStyle={{ clear: 'none', position: 'static' }}
              />
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <label htmlFor="shelfLife" style={labelStyle}>
                  Shelf Life (days):
                </label>
                <DDINumberSpinner
                  propertyName="shelfLife"
                  type="number"
                  wrapperStyle={{ marginLeft: 10 }}
                  step={0.5}
                  inputProps={{
                    noDecimalLimit: true
                  }}
                  validate={[maxLength(10)]}
                />
              </div>

              <div style={{ alignItems: 'center', display: 'flex' }}>
                <label htmlFor="purchaseHistoryLinkId" style={labelStyle}>
                  Purchase History Link:
                </label>

                <DDIIndexSearch
                  propertyName="purchaseHistoryLinkId"
                  id="purchaseHistoryLinkId"
                  searchType="product"
                  metaKey="purchaseHistoryLinkId"
                  fullWidth
                  minWidth="100%"
                />
              </div>

              <div style={{ alignItems: 'center', display: 'flex' }}>
                <label
                  htmlFor="shipperIntegrationOriginCode"
                  style={labelStyle}
                >
                  Shipper Integration Origin Code:
                </label>

                <DDITextField
                  propertyName="shipperIntegrationOriginCode"
                  validate={maxLength(shipperIntegrationOriginCodeMaxLength)}
                  fullWidth
                  style={{ width: 350 }}
                />
              </div>
            </Paper>
          </div>
          <div
            className="two-col-flex-wrapper-break-700"
            style={{
              ...masterStyles.twoColFlex.wrapper,
              justifyContent: 'space-between',
              padding: '0 10px'
            }}
          >
            <DDICardWrapper
              title={`Based On Purchasing Unit Of Measure ${purchasingUOM}`}
              contentStyle={{ padding: 10 }}
              style={{
                ...masterStyles.twoColFlex.left,
                flexBasis: '50%',
                flexGrow: 0
              }}
            >
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    minWidth: 150,
                    padding: '0 10px 10px 10px',
                    width: '50%'
                  }}
                >
                  {this.numberFields}
                  <DDISelectField
                    fullWidth
                    propertyName="palletSize"
                    selectValue="dataId"
                    selectDescription="description"
                    label="Pallet Size"
                    values={
                      palletSizeOptions?.toJS ? palletSizeOptions.toJS() : []
                    }
                  />
                </div>
                <div
                  style={{
                    minWidth: 150,
                    padding: '0 10px 10px 10px',
                    width: '50%'
                  }}
                >
                  {this.numberFieldsRight}
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '25px 0 10px 0'
                    }}
                  >
                    <label htmlFor="minPOOrderQuantity" style={labelStyle}>
                      Min P/O Order Quantity:
                    </label>
                    <DDINumberSpinner
                      propertyName="minPOOrderQuantity"
                      type="number"
                      wrapperStyle={{ marginLeft: 10 }}
                      validate={[maxLength(10)]}
                    />
                  </div>
                  <DDITextField
                    propertyName="innerDimension"
                    // id={propertyName}
                    label="Inner Dimension"
                    mask="numeric"
                    inputProps={{
                      noDecimalLimit: true
                    }}
                    fullWidth
                    wrapperStyle={{ position: 'relative', marginBottom: 10 }}
                  />
                  <DDITextField
                    propertyName="outerDimension"
                    // id={propertyName}
                    label="Outer Dimension"
                    mask="numeric"
                    inputProps={{
                      noDecimalLimit: true
                    }}
                    fullWidth
                    wrapperStyle={{ position: 'relative', marginBottom: 0 }}
                  />
                </div>
              </div>
            </DDICardWrapper>
            <DDICardWrapper
              title="P/O Comments"
              contentStyle={{ padding: 10 }}
              style={{
                ...masterStyles.twoColFlex.right,
                flexBasis: '49%',
                flexGrow: 0
              }}
            >
              <div className="tabs-wrapper" style={{ marginBottom: 15 }}>
                <AppBar position="static" style={{ boxShadow: 'none' }}>
                  <Tabs value={tab} onChange={this.handleTabChange} scrollable>
                    {generateTabs(['External', 'Internal'])}
                  </Tabs>
                </AppBar>
                <TabContainer>
                  <div style={{ marginTop: 10 }}>
                    {tab === 'External' && this.externalPurchaseOrderComments}
                    {tab === 'Internal' && this.internalPurchaseOrderComments}
                  </div>
                </TabContainer>
              </div>
            </DDICardWrapper>
          </div>
          <div
            style={{
              padding: '0 10px 10px 10px',
              width: '100%',
              position: 'relative'
            }}
          >
            <ContainedBackdrop
              open={isPosting}
              style={{ width: '100%', height: '100%' }}
            >
              <CircularProgress size={50} thickness={10} />
            </ContainedBackdrop>
            <EditableGrid
              gridWrapperStyle={{
                flex: '1 1',
                maxWidth: '100%',
                width: '100%'
              }}
              requiredCols={['dataId', 'description']}
              // getRowNodeId={data => data.dataId}
              propertyName="warehouseProcurements"
              form={this.props.form}
              columnDefs={this.getColumnDefs}
              isEditing={isEditing}
              height={this.setGridHeight(
                warehouseProcurements?.toJS ? warehouseProcurements.toJS() : []
              )}
              width="100%"
              fullWidth
              skipValidationOnUnmount
              rowSelection="multiple"
              suppressRowClickSelection={false}
              showAddButtonOnlyIfEditing={false}
              forceFocusOnUpdate
            />
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  forecastFormulaOptions: form =>
    getMeta(form, 'forecastFormulaOptions', emptyList),
  palletSizeOptions: form => getMeta(form, 'getPalletSizes', emptyList),
  statusType: form => getField(form, 'statusType', ''),
  stockOptions: form => getMeta(form, 'stockOptions', emptyList),
  warehouseProcurements: form =>
    getField(form, 'warehouseProcurements', emptyList, true),
  purchasingUOM: form => getValue(form, 'purchasingUOM', ''),
  shipperIntegrationOriginCodeMaxLength: form =>
    getValue(form, 'shipperIntegrationOriginCodeMaxLength', 30)
}

export default withContext(contextObj)(Purchasing)
