import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getValue, getIn } from 'utils'
import { exitScreenFromModal } from '../actions'

const marginRight = { marginRight: 5 }

export const RightFooterButton = ({
  form,
  hasRecord,
  id,
  isEditing,
  isSerialNumberRequired
}) => {
  const dispatch = useDispatch()
  const exitModal = e => dispatch(exitScreenFromModal(form, { modalId: id }))
  const enabled = isEditing && hasRecord
  return (
    <>
      {!isEditing && hasRecord && isSerialNumberRequired && (
        <DDIButton
          actionName="lockForEdit"
          variant="contained"
          label="Edit"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      )}
      (
      <DDIButton
        actionName="saveProduct"
        variant="contained"
        label="Save"
        tabIndex={0}
        style={marginRight}
        disabled={!enabled}
      />
      <DDIButton
        variant="contained"
        label="Cancel"
        actionName="cancelProductEdit"
        preventAutoDisable
        tabIndex={0}
        autoFocus
        style={marginRight}
        disabled={!enabled}
      />
      )
      {id ? (
        <DDIButton
          variant="contained"
          label="Exit"
          onClick={exitModal}
          preventAutoDisable
          tabIndex={0}
        />
      ) : (
        <DDIButton
          actionName="exit"
          variant="contained"
          label="Exit"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      )}
    </>
  )
}

RightFooterButton.propTypes = {
  hasRecord: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool
}

RightFooterButton.defaultProps = {
  isEditing: false
}

export const contextObj = {
  hasRecord: form => getIn(form, 'hasRecord') || false,
  isEditing: form => getIn(form, 'isEditing') || false,
  isSerialNumberRequired: form =>
    getValue(form, 'isSerialNumberRequired', false)
}

export default withContext(contextObj)(RightFooterButton)
