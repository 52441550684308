/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import {
  // emptyList,
  getField,
  getIn,
  getValue,
  layoutFlex
} from 'utils'
import memoize from 'memoize-one'

import {
  addNewActivity as addNewActivityAction,
  lockActivity as lockActivityAction
} from 'modals/EventModal/actions'

import { tryChangeFormTab } from 'ddiForm/actions'
import withDimensions from 'hoc/withDimensions'
import ErrorBoundary from 'components/ErrorBoundary'

import columnDefs from './columnDefs'
import './styles/css-mod-ignore.scss'

const getRowNodeId = data => data.dataId

class Activities extends Component {
  static propTypes = {
    // activities: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dataId,
      dispatch,
      isNew,
      isLocked,
      form,
      selectedPrimaryTab
    } = this.props

    if (
      form &&
      form.match(/salesOrder/) &&
      isNew &&
      isLocked &&
      prevProps.isLocked !== isLocked &&
      !dataId &&
      selectedPrimaryTab === 'activities'
    ) {
      dispatch(tryChangeFormTab(form, 'order'))
    }
  }

  /* componentDidUpdate removed 6/24/19 SVE */
  /* this was a relic, no longer needed */

  onGridReady = params => {
    this.gridApi = params.api
  }

  onRowDoubleClicked = params => {
    const { form } = this.props
    if (params.node.data) {
      const { dataId } = params.node.data
      this.props.dispatch(lockActivityAction(form, { dataId }))
    }
  }

  emptyRow = {
    rowId: 'blankrow',
    dataId: null,
    subject: '',
    type: '',
    notes: ''
  }

  addActivity = () => {
    const { dispatch, form } = this.props

    if (form && dispatch) {
      dispatch(
        addNewActivityAction(form, {
          selectedStartDate: new Date()
        })
      )
    }
  }

  showAddButtonIfHasRecord = memoize(dataId => {
    const { form } = this.props
    if (form.match(/salesOrder/)) {
      return Boolean(dataId)
    }

    if (form === 'vendorMaster' || (form && form?.match(/vendorMaster/))) {
      return false
    }

    return true
  })

  render() {
    const { dataId, height } = this.props
    return (
      <ErrorBoundary>
        <div
          className="activities-grid-wrapper"
          style={layoutFlex('120rem', 'column', 'wrap')}
        >
          <div>
            <GridField
              propertyName="activities"
              title="Activities"
              addButtonText="Add Activity"
              showAddButtonIfHasRecord={this.showAddButtonIfHasRecord(dataId)}
              addBlankRow={this.addActivity}
              headerStyle={{
                background: '#e1e3e4',
                color: '#444',
                fontSize: 13,
                fontWeight: 400,
                lineHeight: '17px',
                margin: 0,
                padding: '5px 0',
                textAlign: 'center',
                width: '100%'
              }}
              emptyRow={this.emptyRow}
              getRowNodeId={getRowNodeId}
              columnDefs={columnDefs({
                form: this.props.form
              })}
              enableSorting
              width="100%"
              height={height}
              ref={el => (this.activitiesGrid = el)}
              onGridReady={this.onGridReady}
              onRowSelected={this.onRowSelected}
              onRowDoubleClicked={this.onRowDoubleClicked}
              rowSelection="multiple"
              suppressRowClickSelection
            />
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

const contextObj = {
  dataId: form => getField(form, 'dataId', ''),
  // activities: form => getValue(form, 'activities', emptyList),
  isNew: form => getValue(form, 'isNew', false),
  isLocked: form => getValue(form, 'isLocked', false),
  selectedPrimaryTab: form =>
    getIn(form, 'masterOptions.selectedPrimaryTab') || '',
  customerId: form => getField(form, 'customerId', '')
}

const activitiesWithContext = withContext(contextObj)(Activities)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  activitiesWithContext
)
