import {
  call,
  take,
  fork,
  put,
  putResolve,
  select,
  delay,
  actionChannel
} from 'redux-saga/effects'
import { buffers } from 'redux-saga'

import * as CONSTANTS from './constants'
import { remove } from './actions'

export function* autoRemoveSnack(action) {
  const {
    payload: {
      message: { timer = 5000, id, type }
    }
  } = action
  yield delay(timer)

  yield put(remove({ id, type }))
}

export function* showSnackListener() {
  const channel = yield actionChannel(CONSTANTS.SHOW, buffers.expanding(30))
  while (true) {
    const action = yield take(channel)
    if (!action?.payload?.message?.persist) {
      yield fork(autoRemoveSnack, action)
    }
  }
}

export default function* snackBarListeners() {
  yield fork(showSnackListener)
  // yield fork(modalConfirmationListener)
}
