import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'

import DDICardWrapper from 'components/DDICardWrapper'
import { withContext, Field, Label } from 'ddiForm'
import { getIn } from 'utils'
import {
  DDISelectField,
  DDITextField,
  DDIIndexSearch,
  DDIDatePicker
} from 'ddiForm/wrapped'
import { Icon, Grid, Button } from '@material-ui/core'
import { getPreviousEndUserInfo as getPreviousEndUserInfoAction } from 'pages/SalesOrder/common/WarrantyTag/actions'
import LabeledField from './LabeledField'
import ValidatedField from './ValidatedField'
import { getChildAndParentForm } from '../sagas'

let buttonActive = false

const EndUserInfo = props => {
  const { form, hasPreviousEndUserInfo, isEditing } = props
  const dispatch = useDispatch()

  const getPreviousEndUserInfo = debounce(e => {
    const { parentForm, childForm } = getChildAndParentForm(form)
    if (
      parentForm &&
      typeof parentForm === 'string' &&
      parentForm?.match(/salesOrder/)
    ) {
      dispatch(
        getPreviousEndUserInfoAction.try(parentForm, {
          lineNumber: props?.data?.lineNumber
        })
      )
    } else {
      dispatch(getPreviousEndUserInfoAction.try(form))
    }
  }, 300)

  useEffect(() => {
    if (hasPreviousEndUserInfo) {
      buttonActive = true
    }
  }, [hasPreviousEndUserInfo])

  return (
    <DDICardWrapper title="End User Info" contentStyle={{ padding: 20 }}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={1}>
          <LabeledField labelWidth={2} fieldWidth={10} label="Name">
            <ValidatedField>
              <DDITextField propertyName="endUserName" disabled={!isEditing} />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <LabeledField labelWidth={2} fieldWidth={10} label="Address">
            <ValidatedField>
              <DDITextField
                propertyName="endUserAddress"
                disabled={!isEditing}
              />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <LabeledField labelWidth={2} fieldWidth={10} label="Line 2">
            <ValidatedField>
              <DDITextField
                propertyName="endUserAddressLine2"
                disabled={!isEditing}
              />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <LabeledField labelWidth={2} fieldWidth={3} label="City">
            <ValidatedField>
              <DDITextField propertyName="endUserCity" disabled={!isEditing} />
            </ValidatedField>
          </LabeledField>
          <LabeledField labelWidth={1} fieldWidth={1} label="State">
            <ValidatedField>
              <DDITextField
                propertyName="endUserState"
                disabled={!isEditing}
                inputProps={{ style: { minWidth: '0 !important' } }}
              />
            </ValidatedField>
          </LabeledField>
          <LabeledField labelWidth={2} fieldWidth={3} label="Zip Code">
            <ValidatedField>
              <DDITextField propertyName="endUserZip" disabled={!isEditing} />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid container item xs={6} spacing={1}>
          <LabeledField label="Phone">
            <ValidatedField>
              <DDITextField propertyName="endUserPhone" disabled={!isEditing} />
            </ValidatedField>
          </LabeledField>
        </Grid>
        <Grid item xs={6}>
          <Button
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
            variant="contained"
            color="primary"
            disabled={!(isEditing && buttonActive)}
            tabIndex={-1}
            onClick={getPreviousEndUserInfo}
          >
            Previous End User
          </Button>
        </Grid>
      </Grid>
    </DDICardWrapper>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  hasPreviousEndUserInfo: form =>
    getIn(form, 'values.hasPreviousEndUserInfo') || false
})(EndUserInfo)
