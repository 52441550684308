/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { Label, Field, withContext } from 'ddiForm'
import {
  DDINumberSpinner,
  DDITextField,
  DDIToggle,
  DDIIndexSearch,
  DDISelectField
} from 'ddiForm/wrapped'
import { maxValue } from 'ddiForm/validations'
import DDICardWrapper from 'components/DDICardWrapper'
import { getIn, emptyList } from 'utils'
import { getOptionsArray } from 'pages/VendorMaster/utils'
import '../../styles/consignment.scss'

const Consignment = ({ consignmentChargeOptionsList }) => {
  const consignmentChargeOptions = getOptionsArray(consignmentChargeOptionsList)

  return (
    <div className="vendor-master-consignment-wrapper">
      <DDICardWrapper title="Consignment">
        <div className="interface-label-wrapper">
          <label>Amount</label>
        </div>
        <div className="row-wrapper">
          <div className="row">
            <label className="consignment-label">Incoming Charge:</label>
            <DDISelectField
              propertyName="consignmentInChargeType"
              fullWidth
              values={consignmentChargeOptions}
            />
            <DDITextField
              propertyName="consignmentInChargeAmount"
              mask="numeric"
              style={{ maxWidth: '100%', width: '100%' }}
              validate={maxValue(999999999.99)}
              inputProps={{
                max: 999999999.99,
                decimalScale: 4,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
          </div>
          <div className="row">
            <label className="consignment-label">Outgoing Charge:</label>
            <DDISelectField
              propertyName="consignmentOutChargeType"
              fullWidth
              values={consignmentChargeOptions}
            />
            <DDITextField
              propertyName="consignmentOutChargeAmount"
              mask="numeric"
              style={{ maxWidth: '100%', width: '100%' }}
              validate={maxValue(999999999.99)}
              inputProps={{
                max: 999999999.99,
                decimalScale: 4,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
          </div>
          <div className="row">
            <label className="consignment-label">Storage Charge:</label>
            <DDISelectField
              propertyName="consignmentStorageChargeType"
              fullWidth
              values={consignmentChargeOptions}
            />
            <DDITextField
              propertyName="consignmentStorageChargeAmount"
              mask="numeric"
              style={{ maxWidth: '100%', width: '100%' }}
              validate={maxValue(999999999.99)}
              inputProps={{
                max: 999999999.99,
                decimalScale: 4,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
          </div>
          <div className="row">
            <label className="consignment-label">Admin Charge:</label>
            <div>&nbsp;</div>
            <DDITextField
              propertyName="consignmentAdminChargeAmount"
              mask="numeric"
              style={{ maxWidth: '100%', width: '100%' }}
              validate={maxValue(999999999.99)}
              inputProps={{
                max: 999999999.99,
                decimalScale: 4,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
          </div>
        </div>
      </DDICardWrapper>
      <DDICardWrapper title="Manufacturer Rep">
        <div className="row-wrapper">
          <div className="row">
            <label className="consignment-label">Rep. Comm %</label>
            <DDITextField
              propertyName="manufacturingRepCommissionPercentage"
              mask="numeric"
              style={{ maxWidth: '100%', width: '100%' }}
              validate={maxValue(999999999.99)}
              inputProps={{
                max: 999999999.99,
                decimalScale: 4,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
            <div>&nbsp;</div>
          </div>
          <div className="row">
            <label className="consignment-label">Rep. Product #</label>
            <div>
              <DDIIndexSearch
                propertyName="manufacturingRepProductNumber"
                metaKey="manufacturingRepProductNumber"
                searchType="CurrencyCode"
                wrapperStyle={{ margin: 0, width: '100%' }}
              />
            </div>
            <div className="description-overflow">
              <Label propertyName="manufacturingRepProductNumberDescription" />
            </div>
          </div>
        </div>
      </DDICardWrapper>
    </div>
  )
}

export default withContext({
  consignmentChargeOptionsList: form =>
    getIn(form, 'meta.consignmentChargeOptions') || emptyList
})(Consignment)
