/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const productImport = ({ route = 'salesOrder', ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      ...rest
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/${route}/import`, options)
}
