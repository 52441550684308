/* eslint react/sort-comp: 0 */
import React, { Component, memo } from 'react'
import { IconButton, Icon } from '@material-ui/core'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { emptyList, getIn, is, plainDeepEqual } from 'utils'
import Grid from 'grid'
import withRowTotals from 'hoc/withRowTotals'
import {
  addProcurementGridRow,
  handleProcurementGridUpdate,
  cleanUpProcurementGridRow
} from 'pages/SalesOrder/actions'

import TooltipField from 'hoc/TooltipField'
import { getSidebarRowData } from 'pages/SalesOrder/utils'

import TextFieldGridCell from './TransfersGrid/components/TextFieldGridCell'
import IndexSearchCell from './TransfersGrid/components/IndexSearchCell'

const DeleteWarehouseGridRow = ({
  value,
  node,
  colDef,
  data = {},
  ...other
}) => {
  const { rowIndex } = node
  const disabled = colDef?.cellRendererParams?.disabled || !data.dataId || false
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = colDef?.cellRendererParams?.dispatch || null

  const deleteWarehouse = e => {
    if (form && dispatch) {
      dispatch(
        handleProcurementGridUpdate.try(form, {
          isRowDeletionHandler: true,
          field: '',
          value: '',
          rowIndex,
          gridName: 'shippingWhses'
        })
      )
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <IconButton disabled={disabled} onClick={deleteWarehouse} tabIndex={-1}>
        <Icon
          style={{
            color: disabled ? '#999' : '#d9534f',
            display: 'inline-block',
            fontSize: 16
          }}
        >
          cancel
        </Icon>
      </IconButton>
    </div>
  )
}

const setPinnedRowData = grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      if (next.quantity) {
        acc.quantity += next.quantity
      }
      return acc
    },
    { dataId: null, description: 'Total:', quantity: 0, delete: null }
  )

  return [result]
}

const pinnedRowRenderer = ({ value = '', colDef, ...other }) => {
  const { field } = colDef
  return (
    <div style={field === 'quantity' ? { textAlign: 'right' } : null}>
      {value}
    </div>
  )
}

const emptyGridRow = {
  dataId: '',
  description: '',
  quantity: null
}

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: setPinnedRowData
})(Grid)

class ShippingWarehousesGrid extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isEditing: PropTypes.bool.isRequired,
    shippingWhsesList: PropTypes.object.isRequired
  }

  static defaultProps = {
    isMobile: false
  }

  componentDidMount() {
    const { shippingWhsesList } = this.props

    if (!shippingWhsesList.size) {
      this.addBlankRow()
    }
  }

  componentWillUnmount() {
    const { dispatch, form, selectedRowIndex } = this.props
    if (is.number(selectedRowIndex)) {
      dispatch(
        cleanUpProcurementGridRow(form, {
          gridName: 'shippingWhses',
          selectedRowIndex,
          rowKey: 'dataId'
        })
      )
    }
  }

  getColumnDefs = memoize(
    ({
      isEditing,
      form,
      isMobile,
      isPosting,
      dispatch,
      canEnterMultipleShippingWhses
    }) => {
      //
      const params = {
        disabled: !isEditing || !canEnterMultipleShippingWhses || isPosting,
        form,
        isEditing,
        gridName: 'shippingWhses'
      }

      const textEditor = {
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: params
      }

      const colDefs = [
        {
          cellRendererFramework: isMobile
            ? IndexSearchCell
            : TooltipField({
                anchorToGridCellText: true,
                indexSearchType: 'warehouse',
                position: 'right-end',
                recordNameKey: 'value',
                sensitivity: 100,
                type: 'indexSearch'
              })(IndexSearchCell),
          cellRendererParams: {
            ...params,
            indexSearchType: 'warehouse',
            isMobile,
            meta: {
              allowInstantSearch: true,
              minimumKeywordLength: 2,
              allowSearchAll: true
            }
          },
          field: 'dataId',
          headerName: 'Shipping Whs',
          minWidth: isMobile ? 125 : 150,
          maxWidth: 150,
          cellStyle: isMobile ? { paddingLeft: 0, paddingRight: 0 } : null,
          pinnedRowCellRenderer: 'customPinnedRowRenderer'
        },
        {
          field: 'description',
          headerName: 'Description',
          cellStyle: { color: isEditing ? '#444' : '#999' },
          suppressNavigable: true,
          minWidth: isMobile ? 75 : 100,
          maxWidth: 150,
          pinnedRowCellRenderer: 'customPinnedRowRenderer'
        },
        {
          cellRendererFramework: TextFieldGridCell,
          cellRendererParams: {
            ...params,
            formatter: 'number',
            noDecimalLimit: true,
            textAlign: 'right',
            isRequired: true,
            errorMessage: 'Qty is Required'
          },
          field: 'quantity',
          headerName: 'Qty',
          minWidth: 100,
          maxWidth: 150,
          pinnedRowCellRenderer: 'customPinnedRowRenderer'
        },
        {
          cellRendererFramework: DeleteWarehouseGridRow,
          cellRendererParams: {
            ...params,
            dispatch
          },
          hide: !isEditing,
          width: 50,
          maxWidth: 50,
          pinnedRowCellRenderer: 'customPinnedRowRenderer'
        }
      ]

      return colDefs
    }
  )

  addBlankRow = () => {
    const { dispatch, form, propertyName } = this.props
    dispatch(
      addProcurementGridRow(form, {
        gridName: 'shippingWhses',
        emptyGridRow,
        rowKey: 'dataId'
      })
    )
  }

  getGridHeight = memoize((rowData = []) => {
    let height = 150
    if (!rowData.length) {
      return height
    }

    height = rowData.length * 28 + 132
    return height
  }, plainDeepEqual)

  render() {
    const {
      form,
      dispatch,
      isEditing,
      isMobile = false,
      isPosting,
      shippingWhsesList,
      canEnterMultipleShippingWhses
    } = this.props
    const rowData = shippingWhsesList?.toJS ? shippingWhsesList.toJS() : []
    const pinnedRowData = setPinnedRowData(rowData)

    return (
      <div style={{ paddingBottom: 35 }}>
        <GridWithRowTotals
          addBlankRow={this.addBlankRow}
          addButtonText="Add Shipping Whse"
          form={form}
          getRowNodeId={data => data.dataId}
          rowData={rowData}
          height={this.getGridHeight(rowData)}
          columnDefs={this.getColumnDefs({
            form,
            isMobile,
            isEditing,
            isPosting,
            dispatch,
            canEnterMultipleShippingWhses
          })}
          frameworkComponents={{
            customPinnedRowRenderer: pinnedRowRenderer
          }}
          showAddButtonAlways
          reactUi={false}
        />
      </div>
    )
  }
}

const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  shippingWhsesList: form =>
    getSidebarRowData(form, 'shippingWhses', emptyList),
  canEnterMultipleShippingWhses: form =>
    getSidebarRowData(form, 'canEnterMultipleShippingWhses', false),
  selectedRowIndex: form => getIn(form, 'fields.lineItems.selectedRowIndex')
}

/* fixed 9/25/2020 SVE */
export default memo(withContext(contextObj)(ShippingWarehousesGrid))
