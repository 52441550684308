import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon, IconButton } from '@material-ui/core'
import { launchAssemblyNotesModal } from 'pages/ProductMaster/actions'
import { is } from 'utils'

export const NotesCheckbox = props => {
  const [hasNotes, setHasNotes] = useState(false)

  useEffect(() => {
    setHasNotes(props.data.hasNotes)
  }, [props.data.hasNotes])

  function toggleCheckbox() {
    const {
      dispatch,
      form,
      rowIndex,
      propertyName
    } = props

    dispatch(
      launchAssemblyNotesModal(form, { rowIndex, propertyName })
    )
  }

  function checkDisabled() {
    const { data, disabled } = props
    return is.fn(disabled) ? disabled(data) : disabled
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <IconButton
        onClick={toggleCheckbox}
        disabled={checkDisabled()}
      >
        <Icon style={{ fontSize: 16 }}>
          {hasNotes ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
      </IconButton>
    </div>
  )
}

NotesCheckbox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  propertyName: PropTypes.string
}

NotesCheckbox.defaultProps = {
  propertyName: 'components'
}


export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(NotesCheckbox)
