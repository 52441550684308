import React from 'react'
import { formatDate, getValue } from 'utils'
import { withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'

const paragraphStyle = {
  color: '#444',
  fontSize: 12,
  lineHeight: '16px',
  margin: '0 0 5px 0'
}

const Signature = ({ imageUrl, signedBy, signedByDate }) => (
  <DDICardWrapper
    title="Signature"
    style={{ margin: 5, maxWidth: 360 }}
    contentStyle={{ padding: 15 }}
  >
    {imageUrl ? (
      <div style={{ border: '1px solid #d9dcde', margin: '0 auto 15px auto' }}>
        <img
          src={imageUrl}
          alt="signature"
          style={{ maxHeight: 150, maxWidth: '100%' }}
          className="center-block"
        />
      </div>
    ) : (
      <p style={{ border: '1px solid #d9dcde', borderRadius: 4, padding: 20 }}>
        No Signature attached.
      </p>
    )}
    {signedBy ? (
      <p style={paragraphStyle}>
        Signed By: <strong>{signedBy}</strong>
      </p>
    ) : null}
    {signedByDate ? (
      <p style={paragraphStyle}>
        Date: <strong>{formatDate(signedByDate, 'MM/DD/YYYY')}</strong>
      </p>
    ) : null}
    {signedByDate ? (
      <p style={paragraphStyle}>
        Time: <strong>{formatDate(signedByDate, 'hh:mm a')}</strong>
      </p>
    ) : null}
  </DDICardWrapper>
)

/* should be fine as-is SVE 9/25/2020 */
export default withContext({
  signedBy: form => getValue(form, 'signature.signedBy', ''),
  signedByDate: form => getValue(form, 'signature.signedDate', null),
  imageUrl: form => getValue(form, 'signature.signature', '')
})(Signature)
