/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const RECEIPT_NUMBER_SEARCH = '@@RECEIPT_NUMBER_SEARCH@@'

export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${RECEIPT_NUMBER_SEARCH}EXECUTE_INQUIRY`
)

export const OPEN_DROPDOWN = createAsyncRequestTypes(
  `${RECEIPT_NUMBER_SEARCH}OPEN_DROPDOWN`
)

export const CLEAR_INQUIRY_RESULTS = `${RECEIPT_NUMBER_SEARCH}CLEAR_INQUIRY_RESULTS`

export const EXACT_MATCH = createAsyncRequestTypes(
  `${RECEIPT_NUMBER_SEARCH}EXACT_MATCH`
)
