import React, { Component, useState } from 'react'
import { TextField } from '@material-ui/core'
import { connect } from 'react-redux'
// import { getIn } from 'utils'
// import { saveNotes } from 'pages/ProductMaster/actions'

// export const NotesModal = ({ data, dispatch }) => {

//   const [value, setValue] = useState(data.note)

//   function onChange(e) {
//     const val = e.target.value

//     setValue(val)
//   }

//   return (
//     <TextField
//       name="internalNotesModal"
//       // disabled={!notesModalEnabled}
//       value={value}
//       multiline
//       rows={5}
//       onChange={onChange}
//     />
//   )
// }

export class NotesModal extends Component {

  constructor(props) {
    super(props)

    this.state = {
      value: this.props.data.note ? this.props.data.note : ''
    }
  }

  onChange = event => {
    const val = event.target.value

    this.setState({
      value: val
    })
  }

  render() {
    // const { notesModalEnabled } = this.props.data
    const { value } = this.state

    return (
      <TextField
        name="internalNotesModal"
        // disabled={!notesModalEnabled}
        value={value}
        multiline
        rows={5}
        onChange={this.onChange}
        variant="outlined"
      />
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(NotesModal)
