import React from 'react'
import { connect } from 'react-redux'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { confirm } from 'modals/actions'

const generateOpenScreenParams = dataId => ({
  activeKey: 'analysis',
  image: 'inventory_product_analysis_16.png',
  route: 'productmaster',
  title: 'Product Analysis',
  groupNames: ['analysis', 'overview'],
  dataId
})

const DuplicateManufacturingNumberModal = ({ data = {}, dispatch, id }) => {
  const openProductAnalysis = dataId => {
    dispatch(openScreenAction(generateOpenScreenParams(dataId)))

    if (data && data.form) {
      dispatch(confirm(data.form, id))
    }
  }

  return (
    <div>
      <p style={{ fontSize: 13, margin: '0 0 5px 0' }}>{data.message}:</p>
      {data.isMobile ? (
        <ul>
          {data.dataIds.map(x => (
            <li style={{ fontSize: 12 }}>{x}</li>
          ))}
        </ul>
      ) : (
        <ul>
          {data.dataIds.map(x => (
            <li style={{ fontSize: 12 }}>
              <a onClick={e => openProductAnalysis(x)}>{x}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(DuplicateManufacturingNumberModal)
