/* eslint jsx-a11y/label-has-for: 0, react/sort-comp: 0, react/static-property-placement: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

// Require new editor files.
// import 'froala-editor/js/froala_editor.pkgd.min.js'
// import 'froala-editor/css/froala_style.min.css'
// import 'froala-editor/css/froala_editor.pkgd.min.css'
// import 'font-awesome/css/font-awesome.css'
// import FroalaEditor from 'react-froala-wysiwyg'

export const mapStateToProps = (state, ownProps) => {
  const webDescription =
    getIn(state, 'ddiForm.productMaster.fields.webDescription.value') ||
    getIn(state, 'ddiForm.productMaster.values.webDescription')

  return { webDescription }
}

export class WebDescriptionEditor extends Component {
  static propTypes = {
    webDescription: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      webDescription: props.webDescription ? `<div>${props.webDescription}</div>` : ''
    }
  }

  onModelChange = content => {
    this.setState({
      webDescription: content
    })
  }

  render() {
    // const { webDescription } = this.props
    const { webDescription } = this.state
    const toolbarButtons = [
      'bold',
      'italic',
      'paragraphFormat',
      'formatOL',
      'formatUL',
      'insertLink',
      'insertImage',
      'insertVideo'
    ]

    return (
      <div style={{ minHeight: 300 }}>
        <ReactQuill
          defaultValue={webDescription}
          model={webDescription}
          onChange={this.onModelChange}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(WebDescriptionEditor)
