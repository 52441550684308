import React from 'react'
import { useDispatch } from 'react-redux'
import { launchNotesModal } from 'modals/NotesModal/actions'
import { getVendorInvoiceReport } from 'pages/VendorMaster/actions'
import { Button } from '@material-ui/core'

const InvoiceButtons = ({ dataId, form }) => {
  const dispatch = useDispatch()

  const openInternalNotes = e => dispatch(launchNotesModal(form))

  const getInvoiceReport = e => {
    dispatch(getVendorInvoiceReport.try(form))
  }

  return (
    <>
      <Button
        size="small"
        variant="contained"
        disabled={!dataId}
        onClick={openInternalNotes}
        style={{ marginRight: 10 }}
      >
        Internal Notes
      </Button>
      <Button
        size="small"
        variant="contained"
        disabled={!dataId}
        onClick={getInvoiceReport}
      >
        Report
      </Button>
    </>
  )
}

export default InvoiceButtons
