import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const getRoute = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return form
  }

  return formParts[1]
}

export const handleAttachments = ({ form, ...args }) => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${
      form === 'reportExplorer' ? `${form}/senddocument` : getRoute(form)
    }/attachments`,
    options
  )
}

export const deleteAttachment = ({ form, ...args }) => {
  const options = createApiOptions({
    body: args,
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${
      form === 'reportExplorer' ? `${form}/senddocument` : getRoute(form)
    }/attachments`,
    options
  )
}
