import React, { useEffect, useState, useRef } from 'react'
import { withContext } from 'ddiForm'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  Input
} from '@material-ui/core'

import { getIn, getValue } from 'utils'
import {
  storeUIFeatureState,
  selectLinkedRefund
} from 'pages/SalesOrder/actions'
import { PaymentButton, paymentModuleStyle, headerFourStyle } from '../../utils'
import OfflineCreditCardPayment from './components/OfflineCreditCardPayment'
import KeyedEntry from './components/KeyedEntry'
import VaultPayment from './components/VaultPayment'
import LinkedRefund from './components/LinkedRefund'
import ProcessorPayment from './components/ProcessorPayment'

const CreditCardPayment = ({
  form,
  dispatch,
  amountTendered,
  isEditing,
  ccPaymentOption,
  hasAccount,
  hasReturnInvoice
}) => {
  function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const [state, setState] = useState({
    ccPaymentOption: hasAccount ? ccPaymentOption : 'offline'
  })

  const prevCCPaymentOption = usePrevious(ccPaymentOption)

  useEffect(() => {
    /*
      allows programmatic switch of options
      (see usage in standardPaymentProcess/checkoutSagas)
    */
    if (
      prevCCPaymentOption !== ccPaymentOption &&
      ccPaymentOption !== state.ccPaymentOption
    ) {
      setState({
        ...state,
        ccPaymentOption
      })
    }
  }, [prevCCPaymentOption, ccPaymentOption])

  const [showLinked, setShowLinked] = useState(false)

  useEffect(() => {
    setShowLinked(hasAccount && hasReturnInvoice && amountTendered < 0)
  }, [hasAccount, hasReturnInvoice, amountTendered])

  const setPaymentOption = value => {
    setState({
      ...state,
      ccPaymentOption: value
    })

    dispatch(
      storeUIFeatureState(form, {
        feature: 'ccPaymentOption',
        featureState: value
      })
    )
  }

  useEffect(() => {
    if (!hasAccount) {
      setPaymentOption('offline')
    }
  }, [hasAccount])

  const onRadioButtonChange = e => {
    const {
      target: { value }
    } = e

    setPaymentOption(value)

    if (value === 'linked') {
      dispatch(selectLinkedRefund.try(form, { amountTendered }))
    }
  }

  return (
    <div>
      <h4 style={headerFourStyle}>Enter Payment Information</h4>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="cc-opt-button-set"
          name="cc-opt-button-set"
          value={state.ccPaymentOption}
          onChange={onRadioButtonChange}
          style={{
            background: '#f4f7f9',
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 20,
            justifyContent: 'center'
          }}
          row
        >
          {hasAccount ? (
            <>
              <FormControlLabel
                value="keyed"
                control={<Radio />}
                label="Keyed Entry"
                style={{ marginBottom: 0 }}
                disabled={!isEditing}
              />
              <FormControlLabel
                value="vault"
                control={<Radio />}
                label="Vault"
                style={{ marginBottom: 0 }}
                disabled={!isEditing}
              />
              <FormControlLabel
                value="processor"
                control={<Radio />}
                label="Use Processor"
                style={{ marginBottom: 0 }}
                disabled={!isEditing}
              />
            </>
          ) : null}
          <FormControlLabel
            value="offline"
            control={<Radio />}
            label="Offline"
            style={{ marginBottom: 0 }}
            disabled={!isEditing}
          />
          {showLinked && (
            <FormControlLabel
              value="linked"
              control={<Radio />}
              label="Linked Refund"
              style={{ marginBottom: 0 }}
              disabled={!isEditing}
            />
          )}
        </RadioGroup>
      </FormControl>
      {state.ccPaymentOption === 'vault' && (
        <VaultPayment
          form={form}
          dispatch={dispatch}
          amountTendered={amountTendered}
        />
      )}
      {state.ccPaymentOption === 'keyed' && (
        <KeyedEntry
          form={form}
          dispatch={dispatch}
          amountTendered={amountTendered}
        />
      )}
      {state.ccPaymentOption === 'offline' && (
        <OfflineCreditCardPayment
          form={form}
          dispatch={dispatch}
          amountTendered={amountTendered}
          isEditing={isEditing}
        />
      )}
      {state.ccPaymentOption === 'linked' && (
        <LinkedRefund
          amountTendered={amountTendered}
          form={form}
          isEditing={isEditing}
        />
      )}
      {state.ccPaymentOption === 'processor' && (
        <ProcessorPayment
          dispatch={dispatch}
          amountTendered={amountTendered}
          form={form}
          isEditing={isEditing}
        />
      )}
    </div>
  )
}

/* valid as is SVE 9/25/2020 */
export default withContext({
  ccPaymentOption: form => getIn(form, 'ui.ccPaymentOption') || 'keyed',
  hasAccount: form => getValue(form, 'hasAccount', false),
  hasReturnInvoice: form => getValue(form, 'hasReturnInvoice', false)
})(CreditCardPayment)
