import { createSelector } from 'reselect'
import { getIn, getRgb } from 'utils'

export const ddiFormSelector = state => state.get('ddiForm')
export const orderPadSelector = createSelector(ddiFormSelector, ddiForm => ddiForm.get('customerOrderPad'))
export const errorMessageSelector = createSelector(orderPadSelector, orderPad => getIn(orderPad, 'errorMessage'))
export const editablePriceSelector = createSelector(orderPadSelector, orderPad => getIn(orderPad, 'fields.editablePrice.value'))
export const editablePriceErrorMessageSelector = createSelector(orderPadSelector, orderPad => getIn(orderPad, 'fields.editablePrice.errorMessage'))
export const editablePriceUpdateFieldSelector = createSelector(orderPadSelector, orderPad => getIn(orderPad, 'fields.editablePrice.updateField'))
export const priceDetailSavePermissionsSelector = createSelector(orderPadSelector, orderPad => getIn(orderPad, 'fields.priceDetailSavePermissions.value'))
