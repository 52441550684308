import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { openScreen } from 'pages/Main/actions'
import { debounce } from 'lodash'
import { getField, getValue } from 'utils'
import { Set } from 'immutable'
import { openPreviousOrder as openPreviousOrderAction } from 'pages/SalesOrder/actions'

export const LeftFooterButtons = ({
  canShipConfirm,
  dataId,
  dispatch,
  form,
  hasRecord,
  isEditing,
  quote,
  status,
  isCurrentlyOnCreditHold,
  previousOrdersSet
}) => {
  const previousOrders = previousOrdersSet?.toJS ? previousOrdersSet.toJS() : []

  function openShipConfirm(e) {
    dispatch(
      openScreen({
        screenOpenData: {
          dataId
        },
        image: 'warehouse_shipping_confirmation_32.png',
        imageFileName16: 'warehouse_shipping_confirmation_16.png',
        route: 'shipconfirmation',
        title: 'Shipping Confirmation',
        name: 'shipConfirmation'
      })
    )
  }

  const openPreviousOrder = debounce(e => {
    dispatch(
      openPreviousOrderAction(form, {
        dataId: previousOrders[0]
      })
    )
  }, 200)

  return (
    <>
      {!isEditing && previousOrders.length && previousOrders[0] !== dataId ? (
        <DDIButton
          variant="contained"
          label="Last"
          actionName="exit"
          preventAutoDisable
          style={{ marginRight: 5 }}
          onClick={openPreviousOrder}
        />
      ) : null}
      {!hasRecord && (
        <DDIButton
          variant="contained"
          label="Credit Invoice"
          actionName="exit"
          preventAutoDisable
          style={{ marginRight: 5 }}
        />
      )}
      {dataId && !isEditing && (
        <DDIButton
          variant="contained"
          label={quote ? 'Copy Quote' : 'Copy Order'}
          actionName="launchCopyOrder"
          preventAutoDisable
          style={{ marginRight: 5 }}
        />
      )}
      {dataId && !isEditing && quote && (
        <DDIButton
          variant="contained"
          label="Create Order"
          actionName="convertQuoteToOrder"
          preventAutoDisable
          style={{ marginRight: 5 }}
        />
      )}
      {dataId && !isEditing && canShipConfirm && (
        <DDIButton
          variant="contained"
          label="Ship Confirm"
          actionName="exit"
          preventAutoDisable
          style={{ marginRight: 5 }}
          onClick={openShipConfirm}
        />
      )}
      {dataId && isCurrentlyOnCreditHold && (
        <DDIButton
          variant="contained"
          label="Release From Hold"
          actionName="releaseCreditHold"
          preventAutoDisable
          style={{ marginRight: 5 }}
        />
      )}
    </>
  )
}

// export const contextObj = {
//   canShipConfirm: form => form.values && form.values.canShipConfirm ?
//     form.values.canShipConfirm : false,
//   dataId: form => form.fields && form.fields.dataId && form.fields.dataId.value ?
//     form.fields.dataId.value : null,
//   quote: form => form.values && form.values.quote ?
//     form.values.quote : false,
//   status: form => form.values && form.values.status ?
//     form.values.status : '',
//   isCurrentlyOnCreditHold: form => form.values && form.values.isCurrentlyOnCreditHold ?
//     form.values.isCurrentlyOnCreditHold : false,
//   previousOrders: form => form.previousOrders || []
// }

/* FIXED SVE 9/23/2020 */
export const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  hasRecord: form => form.get('hasRecord') || false,
  canShipConfirm: form => getValue(form, 'canShipConfirm', false),
  dataId: form => getField(form, 'dataId', null),
  quote: form => getValue(form, 'quote', false),
  status: form => getValue(form, 'status', ''),
  isCurrentlyOnCreditHold: form =>
    getValue(form, 'isCurrentlyOnCreditHold', false),
  previousOrdersSet: form => form.get('previousOrders') || Set()
}

export default memo(withContext(contextObj)(LeftFooterButtons))
