import React from 'react'
import { withContext } from 'ddiForm'
import { emptyList, getField, getMeta } from 'utils'
import { Paper } from '@material-ui/core'
import { DDISelectField, DDITextField } from 'ddiForm/wrapped'

const frieghtHeadingStyle = {
  color: '#517b9c',
  fontSize: '0.9em',
  margin: '0 0 10px 0'
}

const FreightFields = ({
  form,
  dispatch,
  freightTermsType,
  freightTermOptionsList,
  isEditing
}) => {
  const freightTermOptions = freightTermOptionsList?.toJS
    ? freightTermOptionsList.toJS()
    : []

  return (
    <Paper
      square
      style={{ display: 'flex', padding: 10, justifyContent: 'space-around' }}
    >
      <div style={{ minWidth: 150, marginRight: 10 }}>
        <h3 style={frieghtHeadingStyle}>Freight Cost</h3>
        <DDISelectField
          propertyName="freightTermsType"
          label="Freight Terms:"
          initialValue={freightTermsType}
          values={freightTermOptions}
          style={{ marginRight: 15 }}
          minWidth={150}
          fullWidth
          preventAutoDisable
          disabled={!isEditing}
        />
        <DDITextField
          propertyName="freightCost"
          label="Cost"
          mask="numeric"
          inputProps={{
            decimalScale: 2,
            fixedDecimalScale: true
          }}
          minWidth={150}
          fullWidth
          includeDollarSign
          preventAutoDisable
          disabled={!isEditing}
        />
      </div>
      <div>
        <h3 style={frieghtHeadingStyle}>Freight Charges</h3>
        <DDITextField
          propertyName="freight"
          label="Freight Charge"
          mask="numeric"
          inputProps={{
            decimalScale: 2,
            fixedDecimalScale: true
          }}
          minWidth={150}
          fullWidth
          includeDollarSign
          preventAutoDisable
          disabled={
            (freightTermsType !== 'B' && freightTermsType !== 'C') || !isEditing
          }
        />
      </div>
    </Paper>
  )
}

/* fixed 9/25/2020 SVE */
export default withContext({
  freightTermsType: form => getField(form, 'freightTermsType', ''),
  freightTermOptionsList: form =>
    getMeta(form, 'freightTermOptions', emptyList),
  isEditing: form => form.get('isEditing') || false
})(FreightFields)
