import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { REMOVE_MODAL, CONFIRMED, CANCELED } from 'modals/constants'
import { api } from 'services'
import { SET_FIELD } from 'ddiForm/constants'
import { updateFormTitle } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, noop } from 'utils'
import Dialog from 'modals/Dialog'
import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, removeModal } from 'modals/actions'
import { getErrorMessages } from 'pages/ProductMaster/utils'

import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import * as actions from 'pages/SalesOrder/actions'
import {
  getDuplicateProductMessage,
  getGroupNames
} from 'pages/SalesOrder/utils'

import {
  triggerBoxQuantityPrompts,
  displayDuplicateProductsModal,
  handleNewlyAddedProductNotes
} from './commonSagas'

import CopyProductsModal from '../components/CopyProductsModal'

export function* launchCopyProductsModal(form, action, gridApi = null) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const configOptions = isMobile
    ? {
        fullScreen: true,
        marginTop: '0px',
        maxHeight: '95%',
        width: '95%',
        maxWidth: '95%'
      }
    : {
        maxHeight: '100%',
        width: 800
      }

  const modalOpts = {
    component: CopyProductsModal,
    options: {
      ...configOptions,
      title: 'Copy Products From Order',
      data: {
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              const postData = this.getPostData()
              try {
                await this.props.dispatch(
                  actions.executeCopyOrder.try(form, {
                    postData,
                    cb,
                    type: action,
                    gridApi
                  })
                )
              } finally {
                if (cb) {
                  cb()
                }
              }
            },
            disabled: formState => {
              const { fields, values } = formState
              const orderId = getIn(fields, 'copyProducts.orderId.value')

              return !orderId
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              try {
                cb()
                await this.props.dispatch(actions.cancelCopyProducts.try(form))
              } finally {
                if (cb) {
                  cb()
                }
              }
            }
          }
        ],
        form
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* launchCopyOrderProcess(form, action = 'copyOrder', gridApi) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const isQuoteConverted = getIn(formState, 'values.isQuoteConverted')

  const guid = getIn(formState, 'guid')

  if (action === 'convertQuoteToOrder' && isQuoteConverted) {
    yield call(
      confirmationModal,
      'Quote has already been converted to an order. Continue?',
      'Quote Converted'
    )
    const modalAction = yield take([CONFIRMED, CANCELED])

    if (modalAction.type === CANCELED) {
      return
    }
  }

  yield put(actions[action].request(form))

  const apiParams =
    action === 'copyOrder' || action === 'convertQuoteToOrder'
      ? {
          action: action === 'copyOrder' ? 'opencopyorder' : 'opencreateorder',
          guid,
          properties: {
            orderId: dataId
          }
        }
      : {
          action: 'opencopyproduct',
          guid
        }

  const { response, error } = yield call(api.massChange, apiParams)

  if (response) {
    yield put(actions[action].success(response, form))

    // debugger
    if (response.messages && response.messages.length) {
      for (let i = 0; i < response.messages.length; i++) {
        const msg = response.messages[i]
        yield call(warningModal, msg.message, msg.modalTitle)
        yield take(REMOVE_MODAL)
      }
    }

    yield fork(launchCopyProductsModal, form, action, gridApi)
  } else {
    yield put(actions[action].failure(error, form))
  }
}

export function* copyOrderListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_COPY_ORDER)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(launchCopyOrderProcess, form, 'copyOrder')
    }
  }
}

export function* changeCopyOrderOption(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.changeCopyOrderOption.request(form))

  const { response, error } = yield call(api.massChange, {
    action: 'changecopyoptions',
    guid,
    properties: {
      [propertyName]: value
    }
  })

  if (response) {
    yield put(actions.changeCopyOrderOption.success(response, form))
  } else {
    yield put(
      actions.changeCopyOrderOption.failure(
        {
          ...error,
          propertyName
        },
        form
      )
    )
  }
}

export function* copyProductsSettingChangeListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, value },
      meta: { form }
    } = yield take(SET_FIELD)
    // debugger

    if (form === formListener && propertyName.match(/copyProducts/)) {
      const propName = propertyName.replace('copyProducts.', '')
      const mayRequireAccessCheck = ['price', 'costOverrides', 'taxable']
      if (mayRequireAccessCheck.includes(propName)) {
        yield fork(changeCopyOrderOption, form, propName, value)
      }

      if (propertyName === 'copyProducts.orderId' && !value) {
        /* 
          something weird is going on with SO Inquiry dropdown in Copy Products modal,
          so this offers a little bulletproofing -- SVE 10/7/2020
        */
        yield put(actions.clearCopyOrderData(form))
      }
    }
  }
}

export function* onOrderIdBlurListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(INDEX_SEARCH_CONSTANTS.BLUR)

    const formState = yield select(getFormSelector(form))
    const orderOptionIsPosting = getIn(formState, 'orderOptionIsPosting')

    if (
      form &&
      form.replace('.salesOrderInquiry', '') === formListener &&
      propertyName === 'copyProducts.orderId' &&
      value &&
      !orderOptionIsPosting
    ) {
      const propName = propertyName.replace('copyProducts.', '')
      yield fork(changeCopyOrderOption, formListener, propName, value)
    }
  }
}

export function* launchCopyProductsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { gridApi }
    } = yield take(CONSTANTS.COPY_PRODUCTS.TRY)

    if (form === formListener) {
      yield fork(launchCopyOrderProcess, form, 'copyProducts', gridApi)
    }
  }
}

export function* executeCopyProcess(form, postData, cb = noop, type, gridApi) {
  const formState = yield select(getFormSelector(form))
  const isQuote = getIn(formState, 'fields.quote.value') || false
  const groupNames = ['header', 'detail', 'final']
  /* 
    groupNames must be hard-coded to include ONLY header, detail, final
    because if the 'Copy Products' feature is attempted from the Activities 
    (or the Shipments) screen, the massChange API will fail due to reference 
    errors. The groupNames here CANNOT be dynamic -- we need all of this 
    information for copying an order. Noticed this when testing the APIs today -- SVE 4/29/2020
  */

  const suppressDuplicateProductMessageInSalesOrder = getIn(
    formState,
    'meta.suppressDuplicateProductMessageInSalesOrder'
  )

  const numSelected = postData.reduce((acc, next) => {
    if (next.isSelected) {
      acc += 1
    }
    return acc
  }, 0)

  if (type === 'copyProducts' && numSelected === 0) {
    yield call(
      warningModal,
      'Select the products that you want to copy.',
      'No Products Selected'
    )
    return
  }

  const guid = getIn(formState, 'guid')
  const postDataFields = [
    'orderId',
    'price',
    'costOverrides',
    'externalNotes',
    'internalNotes',
    'repairInfo',
    'itemProcurementTypes',
    'taxable'
  ]
  const apiParams = {}
  // let copyLineItems = getIn(formState, 'fields.copyProducts.lineItems.rowData')
  // copyLineItems = copyLineItems && copyLineItems.toJS ? copyLineItems.toJS() :[]

  let lineItems = getIn(formState, 'fields.lineItems.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  for (let i = 0; i < postDataFields.length; i++) {
    const field = postDataFields[i]
    apiParams[field] =
      getIn(formState, `fields.copyProducts.${field}.value`) || false
  }

  if (cb && typeof cb === 'function') {
    cb()
  }

  /*
    this duplicate products routine differs from the rest,
    which were consolidated into duplicateProductsRoutine
    in commonSagas -- SVE 12/20/2019
  */
  let action = {}
  let rowsToPost = {}
  let applyForAll = false
  if (
    (!suppressDuplicateProductMessageInSalesOrder ||
      (suppressDuplicateProductMessageInSalesOrder === 'O' && isQuote) ||
      (suppressDuplicateProductMessageInSalesOrder === 'Q' && !isQuote)) &&
    type === 'copyProducts'
  ) {
    for (let i = 0; i < postData.length; i++) {
      const selectedRow = postData[i]
      const isDuplicate =
        selectedRow.isSelected &&
        lineItems.find(x => x.dataId === selectedRow.dataId)

      if (isDuplicate) {
        const duplicateMessage = getDuplicateProductMessage(isDuplicate)

        if (!applyForAll) {
          yield call(
            displayDuplicateProductsModal,
            form,
            duplicateMessage,
            'Duplicate Product'
          )
          // action = yield take([CONFIRMED, CANCELED])
          action = yield take([
            CONSTANTS.ACCEPT_DUPLICATE_PRODUCT,
            CONSTANTS.REJECT_DUPLICATE_PRODUCT
          ])

          applyForAll = action.payload.applyForAll
          yield put(removeModal(form, action.payload.modalId))

          action = {
            type: action.type
          }
        }

        if (action.type === CONSTANTS.ACCEPT_DUPLICATE_PRODUCT) {
          rowsToPost[selectedRow.lineNumber] = true
        } else {
          rowsToPost[selectedRow.lineNumber] = false
        }
      } else {
        rowsToPost[selectedRow.lineNumber] = selectedRow.isSelected
      }
    }
  } else {
    rowsToPost = postData.reduce((acc, next) => {
      acc[next.lineNumber] = next.isSelected
      return acc
    }, {})
  }

  // console.log('rowsToPost', rowsToPost, apiParams)
  // debugger
  yield put(actions.executeCopyOrder.request(form))

  const { response, error } = yield call(api.massChange, {
    guid,
    action: 'executecopy',
    groupNames,
    properties: {
      ...apiParams,
      lineItems: rowsToPost
    }
  })

  if (response) {
    if (response.messages && response.messages.length) {
      for (let i = 0; i < response.messages.length; i++) {
        const msg = response.messages[i]
        const title =
          msg.modalTitle === 'Error Message' ? 'Attention!' : msg.modalTitle
        yield call(warningModal, msg.message, title)
        yield take(REMOVE_MODAL)
      }
    }

    yield put(
      actions.executeCopyOrder.success(
        {
          ...response,
          copyOrderType: type
        },
        form
      )
    )

    if (gridApi) {
      yield delay(1000)
      yield fork(triggerBoxQuantityPrompts, form, gridApi, true)

      /* wait for the Box Quanity routine to complete */
      yield take(CONSTANTS.NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED)

      setTimeout(() => {
        gridApi.forEachNode(node => {
          if (node?.data?.lineNumber === 1) {
            node.setSelected(true)
          } else {
            node.setSelected(false)
          }
        })
      }, 0)
    }

    /* show the product notes */
    yield fork(handleNewlyAddedProductNotes, form, lineItems, response)
    yield put(updateFormTitle(form, 'Sales Order'))
  } else {
    yield put(actions.executeCopyOrder.failure(error, form))
  }
}

export function* executeCopyListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb, postData, type, gridApi }
    } = yield take(CONSTANTS.EXECUTE_COPY_ORDER.TRY)

    if (form === formListener) {
      if (type === 'copyOrder') {
        yield put(actions.clearOrder(form))
      }
      yield fork(executeCopyProcess, form, postData, cb, type, gridApi)
    }
  }
}

export function* cancelCopyProducts(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.cancelCopyProducts.request(form))

  const { response, error } = yield call(api.massChange, {
    action: 'cancelcopy',
    guid
  })

  if (response) {
    yield put(actions.cancelCopyProducts.success(response, form))
  } else {
    yield put(actions.cancelCopyProducts.failure(error, form))
  }
}

export function* cancelCopyProductsListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_COPY_PRODUCTS.TRY)

    if (form === formListener) {
      yield fork(cancelCopyProducts, form)
    }
  }
}

export function* convertQuoteToOrderListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CONVERT_QUOTE_TO_ORDER.TRY)

    if (form === formListener) {
      yield fork(launchCopyOrderProcess, form, 'convertQuoteToOrder')
    }
  }
}

export default function* copyOrderSagas(form) {
  yield fork(copyOrderListener, form)
  yield fork(copyProductsSettingChangeListener, form)
  yield fork(launchCopyProductsListener, form)
  yield fork(executeCopyListener, form)
  yield fork(cancelCopyProductsListener, form)
  yield fork(onOrderIdBlurListener, form)
  yield fork(convertQuoteToOrderListener, form)
}
