import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

export const CustomerTooltipCell = props => (
  <GridLinkCell
    {...props}
    value={props.data.customerId}
    valueFormatted={props.value}
    />
  )

export default TooltipField({
  indexSearchType: 'customer',
  // keyToActionMap: {
  //   m: () => openScreenAction({
  //     name: 'purchaseOrder',
  //     title: 'Purchase Order',
  //     image: 'purchasing_purchase_order_16.png',
  //   }),
  // },
  position: 'right-end',
  recordNameKey: 'customerId',
  sensitivity: 100,
  type: 'indexSearch'
})(CustomerTooltipCell)
