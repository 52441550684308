import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import HoverIntent from 'react-hoverintent'
import { Paper } from '@material-ui/core'
import { Tooltip } from 'react-tippy'
import moment from 'moment'

export default ({ tooltipContent, tooltipField }) => WrappedComponent => {
  class IconTooltip extends Component {
    constructor(props) {
      super(props)
      this.state = {
        hoverIntended: false
      }

      this.tooltipMarkup = null
    }

    hoverIntended = () => this.setState({ hoverIntended: true })

    hoverNotIntended = () => this.setState({ hoverIntended: false })

    getTooltipValue = value => {
      if (value === true) {
        return <span>Yes</span>
      }
      if (value === false) {
        return <span>No</span>
      }
      return <span>{value}</span>
    }

    displayTooltipValue = tooltipField => {
      if (tooltipField.match(/Date/gi)) {
        return moment(new Date(this.props.data[tooltipField])).format(
          'M/D/YY h:mm A'
        )
      }

      return this.props.data[tooltipField]
    }

    generateTooltip = () => {
      if (this.tooltipMarkup === null && this.props.data[tooltipField]) {
        const markup = (
          <div className="inform-tooltip" style={{ borderRadius: 4 }}>
            <div style={{ padding: 10 }}>
              {this.props.data[tooltipField] ? (
                <div>
                  <p style={{ color: '#fff', fontSize: 11, margin: 0 }}>
                    {this.displayTooltipValue(tooltipField)}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        )

        this.tooltipMarkup = markup
        // this.setState({ tooltip: markup })

        return this.tooltipMarkup
      }

      if (this.props.data[tooltipField] || this.tooltipMarkup) {
        return this.tooltipMarkup
      }

      return null
    }

    getTooltip = () => {
      if (tooltipContent) {
        return (
          <div
            className="inform-tooltip"
            style={{ borderRadius: 4, padding: 10 }}
          >
            <p style={{ color: '#fff', fontSize: 11, margin: 0 }}>
              {tooltipContent}
            </p>
          </div>
        )
      }

      if (tooltipField) {
        return this.generateTooltip()
      }

      return null
    }

    render() {
      return (
        <Tooltip
          open={this.state.hoverIntended}
          ref={el => (this.tooltip = el)}
          html={<div>{this.getTooltip()}</div>}
          unmountHTMLWhenHide
          position="top"
          popperOptions={{
            modifiers: { preventOverflow: { boundariesElement: 'window' } }
          }}
        >
          <HoverIntent
            onMouseOver={this.hoverIntended}
            onMouseOut={this.hoverNotIntended}
            sensitivity={20}
            interval={500}
          >
            <div
              style={{
                display: 'block',
                width: '100%',
                height: '100%'
              }}
            >
              <WrappedComponent {...this.props} />
            </div>
          </HoverIntent>
        </Tooltip>
      )
    }
  }

  return IconTooltip
}
