import behaviors from './behaviors'
import createSearch from '../createSearch'
import DropDownComponent from './BinSearch'

const BinSearch = createSearch({
  name: 'BinSearch',
  behaviors,
  popoverStyle: { width: 500 },
  DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowSearchAll: true,
    minimumKeywordLength: 2
  }
})

export default BinSearch
