import React from 'react'
import { errorRed, mediumGrey } from 'theme/colors'
import { noop } from 'lodash'

import { Icon, IconButton } from '@material-ui/core'

const DeleteButton = props => {
  const { colDef, data } = props
  const disabled = !data?.canDelete
  const onClickDeleteButton =
    colDef?.cellRendererParams?.onClickDeleteButton || noop

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <IconButton
        disabled={disabled}
        style={{ color: disabled ? mediumGrey : errorRed, padding: 5 }}
        size="small"
        onClick={e => onClickDeleteButton(e, data)}
      >
        <Icon size="small">cancel</Icon>
      </IconButton>
    </div>
  )
}

export default DeleteButton
