import React, { memo, useState, useEffect } from 'react'
import { getRgb, getIn, empty, is } from 'utils'
import shortid from 'shortid'
import { isEqual } from 'lodash'
import { withContext } from 'ddiForm'
import { Fab, Icon, Paper } from '@material-ui/core'
import { getSDS, getDocSpec } from 'pages/SalesOrder/actions'
import { BASE_INFORM_URL } from 'services/constants'
import {
  getAdditionalDataMapContent,
  getAdditionalDataFromContext,
  getAdditionalDataMapContentFromImmutable,
  renderStatusIcon,
  getSelectedRowIndex,
  getChildIndex
} from 'pages/SalesOrder/utils'

import '../styles/product-info.scss'

const buttonLinkStyle = {
  boxShadow: 'none',
  color: '#517b9c',
  height: 24,
  minHeight: 24,
  width: '100%'
}
const buttonIconLinkStyle = { color: '#517b9c', fontSize: 16, marginRight: 3 }

export const ProductDetailsInfo = ({
  childIndex,
  dispatch,
  image,
  form,
  selectedRowIndex,
  rowDataMap
}) => {
  const rowData = rowDataMap?.toJS ? rowDataMap.toJS() : []
  let selectedRow = {
    dataId: '',
    hasDocumentSpec: '',
    hasSDS: false,
    comments: []
  }
  if (childIndex) {
    const temp = rowData && rowData[selectedRowIndex]
    console.log(temp)
    if (temp) {
      // eslint-disable-next-line prefer-destructuring
      selectedRow = temp?.components?.find(
        x => x.lineNumber === childIndex
      ) || {
        dataId: '',
        hasDocumentSpec: '',
        hasSDS: false,
        comments: []
      }
    }
  } else {
    selectedRow =
      rowData && rowData[selectedRowIndex]
        ? rowData[selectedRowIndex]
        : { dataId: '', hasDocumentSpec: '', hasSDS: false, comments: [] }
  }
  const { dataId, hasDocumentSpec, hasSDS, comments = [] } = selectedRow

  const [imageSrc, setImageSrc] = useState(null)

  useEffect(() => {
    const tempImage = image?.toJS ? image.toJS() : image || {}
    if (
      !dataId &&
      (!tempImage || (tempImage && !tempImage.base64String && !tempImage.url))
    ) {
      setImageSrc(null)
    } else if (dataId && tempImage.base64String) {
      setImageSrc(tempImage.base64String)
    } else if (dataId && tempImage.url) {
      setImageSrc(tempImage.url)
    } else {
      setImageSrc(`${BASE_INFORM_URL}/resources/no_image.jpg`)
    }
  }, [dataId, image, imageSrc])

  function renderInfos() {
    if (comments && comments.length) {
      const listItems = comments
        .reduce((acc, next) => {
          if (next?.dataId === 'Bins Comment') {
            acc = acc.concat(
              ...next.text.split('\r\n').reduce((a, n) => {
                if (n) {
                  a = a.concat({
                    text: n,
                    color: next?.color ? next.color : { r: 0, g: 128, b: 0 },
                    dataId: shortid.generate()
                  })
                }
                return a
              }, [])
            )
          } else {
            acc = acc.concat(next)
          }
          return acc
        }, [])
        .map((comment, i) => (
          <li
            key={`${comment.dataId}-${shortid.generate()}`}
            style={{
              alignItems: 'center',
              color: getRgb(comment.color),
              display: 'flex',
              marginBottom: 3
            }}
          >
            <Icon style={{ fontSize: 16, marginRight: 10 }}>
              {renderStatusIcon(getRgb(comment.color))}
            </Icon>
            <span style={{ fontSize: 12 }}>{comment.text}</span>
          </li>
        ))

      return (
        <ul
          style={{
            listStyleType: 'none',
            margin: '10px 0 10px 0',
            paddingLeft: 0
          }}
        >
          {listItems}
        </ul>
      )
    }

    return null
  }

  function getSDSReport(e) {
    dispatch(getSDS.try(form, { dataId }))
  }

  function getDocSpecReport(e) {
    dispatch(getDocSpec.try(form, { dataId }))
  }

  return (
    <Paper square>
      <div className="product-details-outer-wrapper">
        <div className="image-field">
          <div className="image-wrapper">
            {imageSrc && (
              <img src={imageSrc} alt="text" className="product-image" />
            )}
          </div>
        </div>

        <div className="info-field">
          <div className="status-list">{renderInfos()}</div>
          <div className="fab-buttons-wrapper">
            {hasDocumentSpec && (
              <span style={{ marginBottom: 10, marginRight: 10 }}>
                <Fab
                  variant="extended"
                  size="small"
                  target="_blank"
                  onClick={getDocSpecReport}
                  style={buttonLinkStyle}
                >
                  <Icon style={buttonIconLinkStyle}>open_in_new</Icon>
                  Document/Specs
                </Fab>
              </span>
            )}
            {hasSDS && (
              <span style={{ marginBottom: 10 }}>
                <Fab
                  variant="extended"
                  size="small"
                  style={buttonLinkStyle}
                  onClick={getSDSReport}
                >
                  <Icon style={buttonIconLinkStyle}>open_in_browser</Icon>
                  SDS
                </Fab>
              </span>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}

// dataId,
// hasDocumentSpec = true,
// hasSDS = true,
// image,
// comments = []
// export default memo(
//   withContext({
//     selectedRow: form => {
//       const lineItems = form.fields && form.fields.lineItems
//       const rowData = lineItems && lineItems.rowData ? lineItems.rowData : []
//       const index = lineItems && lineItems.selectedRowIndex
//       if (index === null || index === undefined) return {}
//       const lineItem = rowData[index]
//       return lineItem || {}
//     },
//     image: form => getAdditionalDataMapContent(form, 'image')
//   })(ProductDetailsInfo),
//   isEqual
// )

/* fixed 9/23/2020 SVE */
/* 
  note, this one required lots of refactor,
  just due to the nature of the changes
*/
export default withContext({
  rowDataMap: form => getIn(form, 'fields.lineItems.rowData') || empty,
  additionalDataMapObj: form => getIn(form, 'additionalDataMap') || empty,
  selectedRowIndex: getSelectedRowIndex,
  childIndex: getChildIndex,
  image: form => getAdditionalDataFromContext(form, 'image', {})
})(ProductDetailsInfo)
