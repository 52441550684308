/* eslint react/prefer-stateless-function: 0, jsx-a11y/no-static-element-interactions: 0 */
import React, { Component } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DeleteButton from './DeleteButton'

const primaryButton = 0

const keyCodes = {
  enter: 13,
  escape: 27,
  arrowDown: 40,
  arrowUp: 38,
  tab: 9
}

export default class DraggableField extends Component {
  onKeyDown = (event, provided, snapshot) => {
    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== keyCodes.enter) {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event)
  }

  onClick = event => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== primaryButton) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event)
  }

  wasToggleInSelectionGroupKeyUsed = event => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  performAction = event => {
    const { field, toggleSelection, toggleSelectionInGroup, type } = this.props

    if (event.shiftKey) {
      toggleSelectionInGroup(field.dataId, type)
      return
    }

    toggleSelection(field.dataId, type)
  }

  render() {
    const {
      field,
      index,
      type,
      selectedDraggableItems,
      deletePrintOrderItem
    } = this.props

    const isSelected = selectedDraggableItems.includes(field.dataId)

    return (
      <li key={field.dataId}>
        <Draggable draggableId={field.dataId} index={index}>
          {(provided, snapshot) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              onClick={this.onClick}
              onKeyDown={e => this.onKeyDown(e, provided, snapshot)}
              isDragging={snapshot.isDragging}
              className={
                isSelected || snapshot.isDragging
                  ? 'draggable-field-selection-list-selected'
                  : 'draggable-field-selection-list-plain'
              }
            >
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{field.description}</span>
                  {type === 'printOrder' ? (
                    <span
                      style={{ marginLeft: 5, fontWeight: 500 }}
                    >{`(${field.masterFileReportLength})`}</span>
                  ) : null}
                </span>
                {type === 'printOrder' &&
                deletePrintOrderItem &&
                typeof deletePrintOrderItem === 'function' &&
                !snapshot.isDragging ? (
                  <DeleteButton
                    dataId={field.dataId}
                    deletePrintOrderItem={deletePrintOrderItem}
                  />
                ) : null}
              </span>
              {snapshot.isDragging &&
              selectedDraggableItems?.length > 1 &&
              isSelected ? (
                <span className="num-drags">
                  {selectedDraggableItems.length}
                </span>
              ) : null}
            </div>
          )}
        </Draggable>
      </li>
    )
  }
}
