import { getIn, setIn } from 'utils'
import { fromJS, Set } from 'immutable'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import auditBehaviors from 'components/MasterScreen/Audit/reducer'
import truckRoutesBehaviors from 'components/MasterScreen/TruckRoutes/reducer'
import * as DDI_MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import { getSelectedTabsFromState } from 'ddiForm/utils'

import * as CONSTANTS from './constants'

export const clearIsPendingFlags = state => {
  let fields = getIn(state, 'fields')
  /* for old EditableGrids */
  fields = fields.map(x => {
    if (x.has('isPending')) {
      x = x.set('isPending', false)
    }
    return x
  })

  state = setIn(state, 'fields', fields)

  return state
}

export default {
  [CONSTANTS.SET_PARENT_ID]: (state, { payload: { parentId } }) => {
    let result = state

    /* fix that fetchingEntity bug */
    result = setIn(result, 'fetchingEntity', false)

    const parent = getIn(result, 'values.parent')
      ? getIn(result, 'values.parent').toJS()
      : {}
    result = setIn(
      result,
      'values.parent',
      fromJS({
        ...parent,
        parentId,
        parentType: 'C'
      })
    )
    return result
  },
  [CONSTANTS.CREATE_SHIP_TO.SUCCESS]: (state, { payload }) => {
    let result = state
    if (payload) {
      result = setIn(result, 'isEditing', payload.isNew)
      result = setIn(result, 'hasRecord', true)
    }
    return result
  },
  [CONSTANTS.CREATE_SHIP_TO.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'hasRecord', false)
    return result
  },
  [CONSTANTS.SAVE_INDEX_SEARCH_DESCRIPTION]: (
    state,
    { payload: { label, value } }
  ) => {
    let result = state
    result = setIn(result, `fields.${label}.value`, value)
    result = setIn(result, `values.${label}`, value)

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.add(label)
    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [CONSTANTS.GET_PRODUCT_UOM.SUCCESS]: (
    state,
    { payload: { uoms, dataId, rowIndex, propertyName } }
  ) => {
    let result = state

    const meta = getIn(result, `fields.${propertyName}.meta`)
      ? getIn(result, `fields.${propertyName}.meta`).toJS()
      : {}

    const rowData = getIn(
      result,
      'fields.customerStockLevels.stockMinimums.rowData'
    )
      ? getIn(result, 'fields.customerStockLevels.stockMinimums.rowData').toJS()
      : []

    if (rowData.length && rowData[rowIndex]) {
      rowData[rowIndex] = {
        ...rowData[rowIndex],
        uomId: uoms[0].dataId,
        uoms: uoms.reduce((acc, next, idx) => {
          if (idx === 0) {
            acc = acc.concat({
              ...next,
              isSelected: true
            })
          } else {
            acc = acc.concat({
              ...next,
              isSelected: false
            })
          }
          return acc
        }, [])
      }
    }

    result = setIn(
      result,
      'fields.customerStockLevels.stockMinimums.rowData',
      fromJS(rowData)
    )
    result = setIn(result, `fields.${propertyName}.meta`, fromJS(meta))

    return result
  },
  [CONSTANTS.UPDATE_UOM_DATA]: (
    state,
    { payload: { rowIndex, propertyName, field, value } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    // const valueKey = `fields.${propertyName}.value`
    const rowData = getIn(result, rowDataKey)
      ? getIn(result, rowDataKey).toJS()
      : []

    /* custom update of uoms array required for Customer Ship Tos > Stock Levels > UOM */
    if (
      // propertyName === 'customerStockLevels.stockMinimums' &&
      field === 'uomId' &&
      rowData[rowIndex].uoms &&
      rowData[rowIndex].uoms.length
    ) {
      rowData[rowIndex].uoms = rowData[rowIndex].uoms.reduce((acc, next) => {
        if (next.dataId === value) {
          acc = acc.concat({
            ...next,
            isSelected: true
          })
        } else {
          acc = acc.concat({
            ...next,
            isSelected: false
          })
        }
        return acc
      }, [])
    }

    result = setIn(result, rowDataKey, fromJS(rowData))
    // result = setIn(result, valueKey, fromJS(rowData))
    return result
  },
  [CONSTANTS.SAVE_MODAL_ID]: (state, { payload: { modalId } }) => {
    let result = state
    result = setIn(result, 'modalId', modalId)
    return result
  },
  [CONSTANTS.SAVE_SHIP_TO.SUCCESS]: (state, { payload, meta }) => {
    let result = state
    result = setIn(result, 'fields', fromJS({}))
    result = setIn(result, 'meta', fromJS({}))
    result = setIn(result, 'values', fromJS({}))
    result = setIn(result, 'callLocation', '')
    result = setIn(result, 'modalId', '')
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'hasRecord', false)
    return result
  },
  [CONSTANTS.SAVE_SHIP_TO.FAILURE]: (state, { payload, meta }) => {
    const result = state
    // result = setIn(result, 'isEditing', false)
    return result
  },
  [CONSTANTS.DELETE_SHIP_TO.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields', fromJS({}))
    result = setIn(result, 'meta', fromJS({}))
    result = setIn(result, 'values', fromJS({}))
    result = setIn(result, 'callLocation', '')
    result = setIn(result, 'modalId', '')
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'hasRecord', false)
    return result
  },
  [DDI_MASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS](
      result,
      action
    )
    result = clearIsPendingFlags(result)

    return result
  },
  [DDI_MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS](
      result,
      action
    )

    result = clearIsPendingFlags(result)
    return result
  },
  [CONSTANTS.SAVE_TEMP_ID]: (state, { payload: { value } }) => {
    let result = state
    result = setIn(result, 'values.dataId', value)
    result = setIn(result, 'fields.dataId.value', value)
    return result
  },
  ...editableGridBehaviors,
  ...auditBehaviors,
  ...truckRoutesBehaviors
}
