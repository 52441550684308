import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { GridField, withContext } from 'ddiForm'
import withRowTotals from 'hoc/withRowTotals'
import {
  dateFilterComparator,
  formatDateFields,
  layoutFlex,
  plainDeepEqual,
  getField,
  emptyList
} from 'utils'
import {
  expandableGridBaseConfig,
  getValueCellStyle,
  headerStyle,
  setGridHeight
} from 'pages/ProductMaster/utils'
import ContainerTooltipCell from 'pages/ProductMaster/components/ContainerTooltipCell'
import PurchaseOrderTooltipCell from 'pages/ProductMaster/components/PurchaseOrderTooltipCell'
import withDimensions from 'hoc/withDimensions'

import './styles/master-style.scss'

export const getRowNodeId = data => data.order

export const dynamicColumnDefs = memoize(({ dispatch, form, type }) => {
  const colDef = [
    {
      cellRendererSelector: ({ value }) => {
        const masterComp = {
          component: 'agGroupCellRenderer',
          params: { value: '' }
        }
        return value && value.length ? masterComp : null
      },
      field: 'containers',
      headerName: '',
      width: 100
    },
    {
      cellRendererFramework: PurchaseOrderTooltipCell,
      dispatch,
      field: 'order',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      form,
      headerName: 'Order #',
      linkTo: type
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'orderDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label',
      headerName: 'Order Date',
      valueFormatter: formatDateFields
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'estimatedDeliveryDate',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerClass: 'centered-label',
      headerName: 'Est. Dlv. Date'
    },
    {
      field: 'vendor',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Vendor'
    },
    {
      field: 'reference',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Reference'
    },
    {
      field: 'buyer.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Buyer',
      tooltipField: 'buyer.tooltip'
    },
    {
      field: 'shipVia.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      tooltipField: 'shipVia.tooltip',
      headerName: 'Ship Via'
    },
    {
      field: 'branch.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Branch',
      tooltipField: 'branch.tooltip'
    },
    {
      field: 'warehouse.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Whse',
      tooltipField: 'warehouse.tooltip'
    },
    {
      cellStyle: { color: 'purple' },
      field: 'status.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Status',
      tooltipField: 'status.tooltip'
    },
    {
      cellStyle: getValueCellStyle,
      field: 'quantityOrdered',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Qty Ord'
    },
    {
      field: 'uom',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'UOM',
      tooltipField: 'uom'
    },
    {
      cellStyle: getValueCellStyle,
      field: 'quantityReceived',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Qty Rcv'
    },
    {
      cellStyle: getValueCellStyle,
      field: 'balanceDue',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Bal Due'
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'containerQuantity',
      filter: 'agNumberColumnFilter',
      filterParams: {
        clearButton: true,
        inRangeInclusive: true,
        suppressAndOrCondition: true
      },
      headerClass: 'numeric-value-label',
      headerName: 'Cont Qty'
    }
  ]

  return colDef
})

const dynamicDetailColumnDefs = ({ dispatch, form }) => {
  const colDef = [
    {
      cellRendererFramework: ContainerTooltipCell,
      cellRendererParams: {
        dispatch
      },
      field: 'dataId',
      form,
      headerName: 'Container',
      linkTo: 'container'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'dueDate',
      headerClass: 'centered-label',
      headerName: 'Due Date',
      valueFormatter: params => formatDateFields(params)
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'quantity',
      headerClass: 'numeric-value-label',
      headerName: 'Cont Qty'
    }
  ]
  return colDef
}

const getBottomData = (purchaseOrders = []) => {
  const defaults = {
    vendor: 'Totals:',
    quantityOrdered: 0,
    quantityReceived: 0,
    balanceDue: 0
  }

  if (!purchaseOrders || !Array.isArray(purchaseOrders)) {
    return [defaults]
  }

  const totals = purchaseOrders.reduce((acc, next) => {
    acc.quantityOrdered += next?.quantityOrdered
    acc.quantityReceived += next?.quantityReceived
    acc.balanceDue += next?.balanceDue
    return acc
  }, defaults)

  return [totals]
}

const GridFieldWithRowTotals = withRowTotals({
  setPinnedRowDataFn: getBottomData
})(GridField)

export class PurchaseOrders extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    purchaseOrders: PropTypes.array.isRequired
  }

  componentDidUpdate(prevProps) {
    if (
      this.gridApi &&
      !plainDeepEqual(this.props.purchaseOrders, prevProps.purchaseOrders)
    ) {
      const filter = this.gridApi.getFilterModel()
      if (Object.keys(filter).length) {
        this.gridApi.setFilterModel(null)
      }
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
    this.checkSelectedGridItems(api)
    // this.gridApi.setFilterModel(null)
  }

  getRowHeight = params => {
    if (params.node && params.node.detail) {
      return setGridHeight(params.data.containers, 5)
    }
    return params.node.rowHeight ? params.node.rowHeight : 28
  }

  checkSelectedGridItems = api =>
    api.forEachNode(node => {
      if (node.data.isSelected) {
        node.setSelected(true)
      }
    })

  render() {
    const { dispatch, form, height } = this.props

    return (
      <>
        <div style={layoutFlex('120rem')}>
          <div className="product-analysis-overflow-wrapper">
            <div style={{ height: height * 0.95 }}>
              <GridFieldWithRowTotals
                enableSorting
                propertyName="purchaseOrders"
                title="Purchase Orders"
                getRowNodeId={getRowNodeId}
                headerStyle={headerStyle}
                masterDetail
                reactUi={false}
                detailCellRendererParams={{
                  detailGridOptions: {
                    ...expandableGridBaseConfig.detail,
                    getRowNodeId: data => data.dataId,
                    columnDefs: dynamicDetailColumnDefs({
                      dispatch,
                      form
                    }),
                    onGridReady: this.onGridReady
                  },
                  getDetailRowData: params => {
                    const containers = params.data.containers
                      ? params.data.containers
                      : []
                    params.successCallback(containers)
                  }
                }}
                columnDefs={dynamicColumnDefs({
                  dispatch,
                  form,
                  type: 'purchaseOrder'
                })}
                getRowHeight={this.getRowHeight}
                onGridReady={this.onGridReady}
                rowSelection="single"
                width="100%"
                fullWidth
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const contextObj = {
  purchaseOrders: form => getField(form, 'purchaseOrders', emptyList, true) // getRowDataFromStore(form, 'purchaseOrders')
  // purchaseOrders: form =>
  //   form.values.purchaseOrders ? form.values.purchaseOrders : []
}

const purchaseOrderWithContext = withContext(contextObj)(PurchaseOrders)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  purchaseOrderWithContext
)
