import { getIn, setIn } from 'utils'
import { fromJS } from 'immutable'
import * as DDI_MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import { removeTrackedTabsFromState } from 'pages/ProductMaster/utils'
import * as CONSTANTS from './constants'

const templateBehaviors = {
  [CONSTANTS.LOCK_TEMPLATE.REQUEST]: state => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.LOCK_FOR_EDIT.REQUEST](
      result
    )
    return result
  },
  [CONSTANTS.LOCK_TEMPLATE.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS](
      result,
      action
    )
    return result
  },
  [CONSTANTS.PRE_UNLOCK_TEMPLATE]: state => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.PRE_CANCEL_EDIT](result)
    return result
  },
  [CONSTANTS.UNLOCK_TEMPLATE.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDI_MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS](
      result,
      action
    )
    result = removeTrackedTabsFromState(result)
    return result
  },
  [CONSTANTS.LOCK_TEMPLATE.FAILURE]: state => {
    let result = state
    result = setIn(result, 'isEditing', false)
    return result
  },
  [CONSTANTS.DELETE_TEMPLATE.SUCCESS]: (
    state,
    { payload: { templateKey } }
  ) => {
    let result = state

    /* calling reset master fields does not clear the dataId field */
    result = setIn(
      result,
      'fields.dataId',
      fromJS({
        description: '',
        prevValue: getIn(result, 'fields.dataId.value'),
        recordName: '',
        value: ''
      })
    )

    result = setIn(result, 'displayName', '')

    return result
  },
  [CONSTANTS.READ_TEMPLATE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'fetchingEntity', true) // maybe useCase for withFetching
    return result
  },
  [CONSTANTS.READ_TEMPLATE.SUCCESS]: (state, { payload }) => {
    let result = state
    // this is handled ALREADY in ANOTHER REDUCER...
    // THIS SHOULD BE HANDLED HERE for consistency
    if (payload.templateKey) {
      result = setIn(result, 'templateKey', payload.templateKey)
    }
    return result
  }
}

export default templateBehaviors
