/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import Grid from 'grid'
import {
  formatDateFields,
  formatDollarFields,
  getIn,
  // fromJS,
  plainDeepEqual
} from 'utils'
import { Button, Icon } from '@material-ui/core'
import { successGreen, errorRed } from 'theme/colors'
import { debounce, isEqual } from 'lodash'
import {
  // clearReturnModalData,
  getReturnModalData,
  storeReturnModalData
} from 'pages/SalesOrder/actions'
import TooltipField from 'hoc/TooltipField'

const getSelectedRowDataIndex = (rowData = [], selectedRows = []) =>
  rowData.findIndex(x => x.dataId === selectedRows[0].dataId)

const TaxableCell = ({ data }) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    }}
  >
    {data.lineTaxable ? (
      <Icon style={{ color: successGreen }}>check_circle_outline</Icon>
    ) : (
      <Icon style={{ color: errorRed }}>highlight_off</Icon>
    )}
  </div>
)

const InvoiceCell = ({ data, value }) => <div>{value}</div>

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form, rowId }
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const lineItems = getIn(formState, 'fields.lineItems.rowData')
  // const rowIndex = lineItems.findIndex(x => x.get('rowId') === uniqueKey)

  const additionalDataMap = getIn(formState, 'additionalDataMap')

  const invoiceHistory =
    getIn(additionalDataMap, `${rowId}.invoiceHistory`) || null

  return {
    lineItems,
    invoiceHistory
  }
}

export class InvoiceHistoryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addButtonText: 'Show All',
      selectedInvoice: null,
      rowData:
        props.data && props.data.invoiceHistory ? props.data.invoiceHistory : []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.invoiceHistory &&
      nextProps.invoiceHistory.toJS &&
      !plainDeepEqual(nextProps.invoiceHistory.toJS(), prevState.rowData)
    ) {
      return {
        addButtonText: 'First 50',
        rowData: nextProps.invoiceHistory.toJS()
      }
    }

    return null
  }

  componentDidMount() {
    this.initializeGrid()
  }

  initializeGrid = () => {
    setTimeout(() => {
      if (this.gridApi) {
        this.gridApi.forEachNode(node => {
          if (node && node.data && node.data.isSelected) {
            node.setSelected(true)
          }
        })
      }
    }, 0)
  }

  onGridReady = params => {
    if (params && params.api) {
      this.gridApi = params.api
    }
  }

  getGridHeight = memoize(data => {
    let height = 100
    if (data && data.length) {
      height = data.length * 29 + 33
    }

    return height
  }, isEqual)

  getAllInvoiceHistory = debounce(e => {
    const {
      dispatch,
      data: { form, lineNumber },
      lineItems
    } = this.props

    const lineItemRowData = lineItems && lineItems.toJS ? lineItems.toJS() : []

    const rowIndex = lineItemRowData.findIndex(x => x.lineNumber === lineNumber)

    if (this.state.addButtonText === 'Show All') {
      dispatch(
        getReturnModalData(form, {
          rowIndex,
          gridApi: null,
          isRequest: true,
          launchModal: false,
          lineNumber,
          type: 'invoicehistoryall'
        })
      )
    } else {
      this.setState(prevState => {
        return {
          addButtonText: 'Show All',
          rowData: prevState.rowData.slice(0, 50)
        }
      })
    }
  }, 500)

  getColumnDefs = memoize(form => {
    const columnDefs = [
      {
        field: 'dataId',
        headerName: 'Invoice',
        filter: false,
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'invoiceOrder',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(InvoiceCell)
      },
      {
        field: 'invoiceDate',
        headerName: 'Date',
        headerClass: 'text-center',
        cellClass: 'text-center',
        valueFormatter: formatDateFields,
        tooltipField: 'getPreviousReturnedInfo',
        filter: false
      },
      {
        field: 'uomId',
        headerName: 'UOM',
        filter: false
      },
      {
        field: 'lineTaxable',
        headerName: 'Tax?',
        headerClass: 'text-center',
        cellRendererFramework: TaxableCell,
        filter: false
      },
      {
        field: 'quantity',
        headerName: 'Qty Sold',
        headerClass: 'align-right',
        cellClass: 'align-right',
        filter: false
      },
      {
        field: 'quantityAvailableForReturn',
        headerName: 'Avail For Return',
        headerClass: 'align-right',
        cellClass: 'align-right',
        filter: false
      },
      {
        field: 'netPrice',
        headerName: 'Net Price',
        headerClass: 'align-right',
        cellClass: 'align-right',
        valueFormatter: formatDollarFields,
        filter: false
      }
    ]

    return columnDefs
  })

  onRowSelected = params => {
    const { rowData } = this.state
    const {
      data: { lineNumber, form },
      dispatch
    } = this.props

    // console.log(getSelectedRowDataIndex(rowData, params.api.getSelectedRows()))
    // console.log(params.api.getSelectedRows())
    //

    dispatch(
      storeReturnModalData(form, {
        lineNumber,
        selectedInvoiceHistory: getSelectedRowDataIndex(
          rowData,
          params.api.getSelectedRows()
        )
      })
    )
  }

  isButtonDisabled = memoize(rowData => {
    if (!rowData.length) {
      return true
    }

    return false
  }, plainDeepEqual)

  render() {
    const { addButtonText, rowData } = this.state
    const {
      data: { form }
    } = this.props

    return (
      <div>
        <Grid
          // columnDefs={columnDefs}
          columnDefs={this.getColumnDefs(form)}
          rowData={rowData}
          getRowNodeId={data => data.dataId}
          height={this.getGridHeight(rowData)}
          suppressHorizontalScroll
          rowSelection="single"
          onGridReady={this.onGridReady}
          onRowSelected={this.onRowSelected}
        />
        <Button
          variant="contained"
          onClick={this.getAllInvoiceHistory}
          disabled={this.isButtonDisabled(rowData)}
        >
          {addButtonText}
        </Button>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(InvoiceHistoryModal)
