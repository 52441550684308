import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@material-ui/core'
import { isDisabled, updateGridCellData } from 'components/EditableGrid/utils'

class ToggleCheckboxCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn, tooltip }
      }
    } = props

    const tooltipDisplay =
      tooltip && props.data[tooltip] ? props.data[tooltip] : ''

    this.state = {
      tooltipDisplay: tooltipDisplay || '',
      value: props.value || false
    }

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  toggleCheckbox = () => {
    const {
      colDef: {
        cellRendererParams: { toggleAllowedKey }
      },
      data
    } = this.props

    if (!toggleAllowedKey || (toggleAllowedKey && data[toggleAllowedKey])) {
      this.setState(
        {
          value: !this.state.value
        },
        () => this.updateGridCellData()
      )
    }
  }

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { isDisabled, disabledFn, formatter }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    if (isDisabled) {
      return isDisabled
    }

    return false
  }

  render() {
    const {
      colDef: {
        cellRendererParams: { tooltip }
      }
    } = this.props

    const { tooltipDisplay, value } = this.state

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%'
        }}
        title={tooltipDisplay}
      >
        <IconButton
          onClick={this.toggleCheckbox}
          disabled={this.isFieldDisabled()}
        >
          <Icon style={{ fontSize: 16 }}>
            {value ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
        </IconButton>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ToggleCheckboxCell)
