/* eslint import/prefer-default-export: 0 */
import { call, select, put, fork } from 'redux-saga/effects'
import { getIn, is } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { api } from 'services'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import { listActivities } from './actions'

export function* listActivitiesProcess(apiParams) {
  const { form } = apiParams

  if (!apiParams.dataId || !form) {
    return
  }

  yield put(listActivities.request(form))

  // const { response, error } = yield call(api.listActivities, apiParams)
  const { response, error } = yield call(api.read, {
    name: form && form.match(/salesOrder/) ? 'salesOrder' : form,
    ...apiParams
  })

  if (response) {
    const activities = response?.activities?.activities
      ? response.activities.activities
      : Array.isArray(response?.activities)
      ? response.activites
      : []
    yield put(listActivities.success({ ...response, activities }, form))
    // yield put(
    //   getEntityAsync.success(
    //     {
    //       activities: response,
    //       groupNames: ['Activities']
    //     },
    //     form
    //   )
    // )
  } else {
    yield put(getEntityAsync.failure(error, form))
  }
}
export function* defaultGetListActivitiesApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const guid = getIn(formState, 'guid')

  return {
    form,
    guid,
    dataId,
    groupNames: ['activities']
  }
}

export function* wrapActivitiesDataAccess(form, getListActivitiesApiParams) {
  getListActivitiesApiParams =
    getListActivitiesApiParams || defaultGetListActivitiesApiParams
  const activitiesApiParams = yield call(getListActivitiesApiParams, form)
  yield call(listActivitiesProcess, activitiesApiParams)
}
