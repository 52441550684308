/* eslint react/prefer-stateless-function: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import { Paper, CircularProgress, Icon } from '@material-ui/core'
import { getIn } from 'utils'

import { Field, Label } from 'ddiForm'
import RenderRadioGroup from 'components/RenderRadioGroup'
import { makeRadioButtons } from './utils'

const processOptions = [
  { dataId: 'false', description: 'Immediately' },
  { dataId: 'true', description: 'Overnight' }
]

const mapStateToProps = (state, ownProps) => {
  const { data: { form } } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  return {
    toIdDescription: getIn(formState, 'values.mergeModal.toIdDescription') || '',
    isRequesting: getIn(formState, 'fields.mergeModal.isRequesting') || false,
    requestComplete: getIn(formState, 'fields.mergeModal.requestComplete')
  }
}

class MergeModal extends Component {
  static propTypes = {
    // customerIndexSearchMeta: PropTypes.object.isRequired,
    toIdDescription: PropTypes.string.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    requestComplete: PropTypes.bool.isRequired
  }

  render() {
    const {
      data: { searchType },
      isRequesting,
      requestComplete,
      toIdDescription
    } = this.props

    if (isRequesting) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{ marginRight: 15 }}>
            <CircularProgress thickness={7} />
          </div>
          <p style={{ fontSize: 14, marginBottom: 0, textAlign: 'center' }}>
            Please wait. Your merge request is processing...
          </p>
        </div>
      )
    }

    if (requestComplete) {
      return (
        <p style={{ fontSize: 14, marginBottom: 0, textAlign: 'center' }}>
          Process completed...
        </p>
      )
    }

    return (
      <div>
        <Paper
          style={{
            background: '#f5f7f7',
            marginBottom: 10,
            padding: '15px 15px 0 15px'
          }}
        >
          <p style={{ margin: 0 }}>Process</p>
          <Field
            component={RenderRadioGroup}
            propertyName="mergeModal.mergeOvernight"
            // defaultSelected
            // initialValue
            renderAsRow
          >
            {makeRadioButtons(processOptions)}
          </Field>
        </Paper>
        <Paper style={{ background: '#f5f7f7', marginBottom: 10, padding: 15 }}>
          <p style={{ marginBottom: 0 }}>From</p>
          <div
            style={{ alignItems: 'center', display: 'flex', margin: '15px 0' }}
          >
            <label
              style={{
                color: '#444',
                order: 1,
                marginBottom: 0,
                marginRight: 10
              }}
            >
              Number:
            </label>
            <div
              style={{ marginRight: 15, maxWidth: 200, order: 2, width: 200 }}
            >
              <DDIIndexSearch
                propertyName="mergeModal.dataId"
                disabled
                searchType={searchType}
                fullWidth
              />
            </div>
            <div
              style={{
                flex: 1,
                order: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Label propertyName="mergeModal.dataId.description" />
            </div>
          </div>
        </Paper>
        <Paper style={{ background: '#f5f7f7', marginBottom: 10, padding: 15 }}>
          <p style={{ marginBottom: 0 }}>To</p>
          <div
            style={{ alignItems: 'center', display: 'flex', margin: '15px 0' }}
          >
            <label
              style={{
                color: '#444',
                order: 1,
                marginBottom: 0,
                marginRight: 10
              }}
            >
              Number:
            </label>
            <div
              style={{ marginRight: 15, maxWidth: 200, order: 2, width: 200 }}
            >
              <DDIIndexSearch
                preventAutoDisable
                propertyName="mergeModal.toId"
                searchType={searchType}
                indexSearchType={searchType}
                meta={{
                  allowInvalidValues: true,
                  allowInstantSearch: true,
                  allowNavigation: false,
                  allowSearchAll: false,
                  fieldName: 'mergeModal.toId',
                  hasFilters: false,
                  hideToggle: true,
                  minimumKeywordLength: 0,
                  openText: null,
                  searchType
                }}
                fullWidth
                // disableBaseOnTabFunctionality
                // onKeyDown={e => alert('fook')}
              />
            </div>
            <div
              style={{
                flex: 1,
                order: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {toIdDescription ? (
                <div
                  className="ddi-data-label"
                  style={{
                    background: '#eaeef0',
                    borderRadius: 4,
                    color: '#517b9c',
                    display: 'inline-block',
                    fontSize: 12,
                    lineHeight: '16px',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    padding: 5,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: 'auto'
                  }}
                >
                    <Icon
                      style={{
                        color: '#517b9c',
                        fontSize: 16,
                        marginRight: 5,
                        verticalAlign: 'middle',
                      }}
                    >
                      info
                    </Icon>
                  {toIdDescription}
                </div>
              ) : null}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(MergeModal)
