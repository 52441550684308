import React from 'react'
import { withContext } from 'ddiForm'
import PropTypes from 'prop-types'
import { DDISelectField } from 'ddiForm/wrapped'
import { getMeta, emptyList } from 'utils'

import {
  LayoutFlex,
  SelectFieldFlexWrapper,
  SelectFieldFlexCentered
} from 'pages/ContactMaster/styles/masterStyle'

export const Johnstone = ({ catalogCodeOptions, catalogCodeStatusOptions}) => (
  <LayoutFlex flexDirection='column'>
    <SelectFieldFlexWrapper>
      <SelectFieldFlexCentered>
        <DDISelectField
          propertyName="catalogCode"
          label="Catalog Code"
          values={catalogCodeOptions.toJS()}
        />

        <DDISelectField
          propertyName="catalogCodeStatus"
          label="Status"
          values={catalogCodeStatusOptions.toJS()}
        />
      </SelectFieldFlexCentered>
    </SelectFieldFlexWrapper>
  </LayoutFlex>
)

Johnstone.propTypes = {
  catalogCodeOptions: PropTypes.array.isRequired,
  catalogCodeStatusOptions: PropTypes.array.isRequired
}

export const contextObj = {
  catalogCodeOptions: form => getMeta(form, 'catalogCodeOptions', emptyList),
  catalogCodeStatusOptions: form => getMeta(form, 'catalogCodeStatusOptions', emptyList)
}

export default withContext(contextObj)(Johnstone)
