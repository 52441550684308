import React from 'react'
import { List } from 'immutable'
import { withContext } from 'ddiForm'
import { DDISelectField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  getIn,
  emptyList,
  fromJS,
  formatDate,
  isSecureFieldDisabled
} from 'utils'

import '../../../styles/form-styles.scss'

const statusOptions = [
  { dataId: 'H', description: 'Inactive/Hold' },
  { dataId: 'T', description: 'Active/Trade' }
]

const InternalInformation = ({
  initialStatusValue,
  enteredBy,
  enteredDate,
  vendorStatusesList,
  isEditing,
  securedFields
}) => {
  const vendorStatuses = vendorStatusesList?.fromJS
    ? vendorStatusesList.toJS()
    : statusOptions

  return (
    <DDICardWrapper title="Internal Information" contentStyle={{ padding: 10 }}>
      <div className="vendor-master-form-outer-wrapper">
        <div className="vendor-master-form-row-wrapper">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              flexBasis: '50%'
            }}
          >
            <span
              style={{
                fontSize: '0.9em',
                marginLeft: 15,
                marginRight: 15,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span style={{ marginRight: enteredBy ? 10 : 0 }}>
                Created By:
              </span>
              {enteredBy ? (
                <strong style={{ marginRight: 10 }}>{enteredBy}</strong>
              ) : null}
              {enteredDate ? (
                <strong style={{ marginRight: 10 }}>
                  {formatDate(enteredDate, 'MM/DD/YYYY')}
                </strong>
              ) : null}
            </span>
          </div>
          <div style={{ flexBasis: '50%', padding: 15 }}>
            <DDISelectField
              propertyName="status"
              fullWidth
              values={vendorStatuses}
              label="Status"
              initialValue={initialStatusValue}
              preventAutoDisable
              disabled={isSecureFieldDisabled(
                'status',
                isEditing,
                securedFields
              )}
            />
          </div>
        </div>
      </div>
    </DDICardWrapper>
  )
}

export default withContext({
  initialStatusValue: form => getIn(form, 'values.status.value') || emptyList,
  isEditing: form => form.get('isEditing') || false,
  enteredBy: form => getIn(form, 'values.enteredBy') || '',
  enteredDate: form => getIn(form, 'values.enteredDate') || null,
  vendorStatusesList: form =>
    getIn(form, 'meta.vendorStatuses') || fromJS(statusOptions),
  securedFields: form => getIn(form, 'meta.securedFields') || List()
})(InternalInformation)
