import React from 'react'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import { getIn, emptyList, formatNumber } from 'utils'
import { getGridHeight } from 'pages/InvoiceInquiry/utils'
import { Icon } from '@material-ui/core'
import { successGreen, errorRed } from 'theme/colors'

const TaxableCell = ({ value }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <Icon style={{ color: value ? successGreen : errorRed }}>
        {value ? 'check_circle' : 'block'}
      </Icon>
    </div>
  )
}

const columnDefs = [
  {
    field: 'dataId',
    headerName: 'Charge',
    minWidth: 75
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 100
  },
  {
    cellClass: 'align-right right-align',
    field: 'amount',
    headerName: 'Amount',
    minWidth: 100,
    valueFormatter: params => formatNumber(params.value)
  },
  {
    cellRendererFramework: TaxableCell,
    field: 'taxable',
    headerClass: 'text-center',
    headerName: 'Tx?',
    suppressMenu: true,
    minWidth: 80,
    maxWidth: 100
  }
]

const MiscChargesGrid = ({ miscChargesList }) => {
  const miscCharges = miscChargesList?.toJS ? miscChargesList.toJS() : []

  return (
    <Grid
      rowData={miscCharges}
      columnDefs={columnDefs}
      height={getGridHeight(miscCharges)}
      getRowNodeId={data => data.dataId}
    />
  )
}

export default withContext({
  miscChargesList: form =>
    getIn(form, 'values.miscellaneousCharges') || emptyList
})(MiscChargesGrid)
