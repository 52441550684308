import {
  all,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { getIn } from 'utils'

import * as CONSTANTS from './constants'
import * as api from './api'

export function* receiptNumberExactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.EXACT_MATCH.REQUEST)
    const {
      payload: { keyword, propertyName, subProperty },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)

    const { response, error } = yield call(api.searchReceiptNumbers, {
      dataId: keyword
    })

    // debugger
    if (response) {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...response,
          recordName: response?.result?.recordName,
          propertyName,
          subProperty
        },
        type: CONSTANTS.EXACT_MATCH.SUCCESS
      })
    } else {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...error,
          propertyName,
          subProperty
        },
        type: CONSTANTS.EXACT_MATCH.FAILURE
        // error: true
      })
    }
  }
}

export function* executeInquiryListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.EXECUTE_INQUIRY.TRY)
    const {
      meta: { form },
      payload: { propertyName }
    } = action
    // debugger
    // console.log('executeInquiryListener', action)
    if (task) yield cancel(task)
    const formParts = form.split('.')
    const formName = formParts[0]
    const childForm = formParts[1]
    task = yield call(executeInquiryProcess, formName, childForm, propertyName)
  }
}

export function* executeInquiryProcess(form, childForm, propertyName) {
  const formState = yield select(getFormSelector(`${form}.${childForm}`))
  const startDate = getIn(formState, 'fields.startDate.value')
  const endDate = getIn(formState, 'fields.endDate.value')

  yield put({
    type: CONSTANTS.EXECUTE_INQUIRY.REQUEST,
    meta: { form, childForm, apiRequest: true }
  })
  // debugger

  const { response, error } = yield call(api.searchReceiptNumbers, {
    startDate,
    endDate
  })

  if (response) {
    yield put({
      meta: { form, childForm },
      payload: { ...response },
      type: CONSTANTS.EXECUTE_INQUIRY.SUCCESS
    })
  } else {
    yield put({
      meta: { form, childForm },
      payload: { ...error, propertyName },
      type: CONSTANTS.EXECUTE_INQUIRY.FAILURE
    })
  }
}

// export function* dropDownOpenListener() {
//   while (true) {
//     const action = yield take(CONSTANTS.OPEN_DROPDOWN.REQUEST)
//     const { meta } = action
//     // console.log('dropDownListener', meta)
//     yield putResolve({
//       meta: {
//         form: meta.form,
//         thunk: meta.thunk
//       },
//       type: CONSTANTS.OPEN_DROPDOWN.SUCCESS
//     })
//   }
// }

export default function* receiptNumberInquirySagas(form) {
  yield fork(executeInquiryListener)
  yield fork(receiptNumberExactMatchSearchListener)
}
