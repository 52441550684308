import { call, fork, select } from 'redux-saga/effects'
import { getIn } from 'utils'
import { getFormSelector, getSelectedTabsFromState } from 'ddiForm/utils'
import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'
import notesModalSagas from 'modals/NotesModal/sagas'
import selectionCriteriaModalSagas from 'components/SelectionCriteriaModal/sagas'
import editableGridSagas from 'components/EditableGrid/sagas'
import corporateFieldUpdatesSagas from 'components/MasterScreen/CorporateFieldUpdates/sagas'
import { additionalAuditsDataFlagListener } from 'components/MasterScreen/Audit/sagas'

/* local imports */
import contactManagementSagas from './contactManagementSagas'
import ledgerSagas, { getLedgerGroupNames } from './ledgerSagas'
import activitiesSagas from './activitiesSagas'
import crudSagas from './crudSagas'

export function* cancelEditGroupNamesSaga(form) {
  const formState = yield select(getFormSelector(form))
  const groupNames = getSelectedTabsFromState(formState) || []

  return groupNames.includes('dropShipCharges')
    ? [...groupNames, 'dropShip']
    : [...groupNames]
}

export function* getGroupNamesSaga(form, rest = {}) {
  const { groupNames = [] } = rest
  const formState = yield select(getFormSelector(form))
  const templateKey = getIn(formState, 'values.templateKey')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (selectedPrimaryTab === 'ledger' || groupNames.includes('ledger')) {
    let apiParams = yield call(getLedgerGroupNames, form, groupNames)

    if (templateKey) {
      apiParams = {
        ...apiParams,
        form,
        templateKey
      }
    }

    return apiParams
  }

  return templateKey
    ? {
        groupNames: [...groupNames],
        form,
        templateKey
      }
    : {
        groupNames: groupNames.includes('dropShipCharges')
          ? [...groupNames, 'dropShip']
          : [...groupNames]
      }
}

export default function* vendorMasterSagas(form) {
  yield fork(ledgerSagas, form)
  yield fork(activitiesSagas, form)
  yield fork(attachmentsSagas, form)
  yield fork(notesModalSagas, form)
  yield fork(selectionCriteriaModalSagas, form)
  yield fork(additionalAuditsDataFlagListener, form)
  yield fork(contactManagementSagas, form)
  yield fork(editableGridSagas, form, ['expenseGLIds', 'sourceCodes'])
  yield fork(corporateFieldUpdatesSagas, form, true)
  yield fork(crudSagas, form)
}
