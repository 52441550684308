import React, { useState, useEffect, createRef, useRef } from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Switch from '@material-ui/core/Switch'
import { makeStyles } from '@material-ui/core/styles'
import { removeModal } from 'modals/actions'
import { isMobileSelector } from 'mobile/selectors'
import { recalculateCosts } from '../actions'

const useStyles = mobile =>
  makeStyles(theme => {
    // debugger
    return {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // width: 500
      },
      card: {
        // width: 500
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120
      },
      selectEmpty: {
        marginTop: theme.spacing(2)
      },
      cardMarginTop: {
        // width: 400,
        marginTop: 20
      },
      inputStyle: {
        width: mobile ? '2.7rem !important' : '4rem !important',
        height: mobile ? '3rem' : '4rem',
        margin: mobile ? '0 0.25rem' : '0 1rem',
        fontSize: mobile ? '1.5rem' : '3rem',
        borderRadius: 4,
        border: '1px solid rgba(0,0,0,0.3)'
      },
      error: {
        border: '1px solid red !important'
      },
      iconStyle: {
        color: 'green',
        fontSize: 48,
        animationDuration: '2s',
        animationName: 'fadeIn'
      },
      iconContainerStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
      }
    }
  })
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
export function RecalculateCostsModal(props) {
  const { form } = props
  const switchRef = useRef()
  console.log(props)
  const [open, setOpen] = useState(true)
  const [success, setSuccess] = useState(false)

  const [apiActive, setApiActive] = useState(false)
  const [isError, setError] = useState(false)

  const handleClose = bool => {
    // setOpen(false)
    props.dispatch(removeModal(null, props.id))

    setOpen(false)
  }
  const recalculate = async () => {
    try {
      setApiActive(true)
      let selectedRows = props.gridApi.getSelectedRows().map(x => x.uniqueKey)
      props.gridApi.forEachDetailGridInfo(info => {
        const selected = info.api.getSelectedRows().map(x => x.uniqueKey)

        if (selected.length) {
          selectedRows = selectedRows.concat(selected)
        }
      })
      await props.dispatch(
        recalculateCosts.request(form, {
          overrides: switchRef.current.checked,
          selectedRows
        })
      )
      console.log(switchRef.current.checked)
      setSuccess(true)
      await delay(1000)
      setOpen(false)
      handleClose()
    } catch (ex) {
      console.log(ex)

      setError(true)
      setApiActive(false)
      // setOtp('')
      if (ex?.error === 'invalid_grant') {
        handleClose()
      }
    }
  }
  const classes = useStyles(props.mobile)()

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          {props.title || 'One Time Password'}
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div style={{ margin: '25px auto', minWidth: 280 }}>
              <div>
                <FormControlLabel
                  control={<Switch inputRef={switchRef} />}
                  label="Include Overrides"
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={recalculate}
            color="primary"
            disabled={apiActive}
          >
            Recalculate
          </Button>
          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            color="primary"
            disabled={apiActive}
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
const mapState = state => ({
  mobile: isMobileSelector(state)
})
export default connect(mapState)(RecalculateCostsModal)
