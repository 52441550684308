import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import memoize from 'memoize-one'
import useStateWithCallback from 'hooks/useStateWithCallback'
import { DDISerialNumberSearch } from 'ddiForm/wrapped'
import { destroyField } from 'ddiForm/actions'
import { errorRed, mediumGrey } from 'theme/colors'
import { plainDeepEqual } from 'utils'
import shortid from 'shortid'
import { debounce } from 'lodash'

import {
  Button,
  Icon,
  IconButton,
  Paper,
  Table,
  TableRow,
  TableFooter,
  TableBody,
  TableHead,
  TableCell
} from '@material-ui/core'

import {
  modifyLineItemSerialNumber,
  destroySerialNumberFields,
  handleSerialNumberReviewChange
} from 'pages/SalesOrder/actions'

import LotsGrid from './LotsGrid'

const getSerialNumberData = memoize(
  (serialNumberData, usesLotsEditor = false, isSerialNumberReview = false) => {
    const key =
      usesLotsEditor && !isSerialNumberReview ? 'lots' : 'serialNumbers'
    // debugger
    return serialNumberData?.[0]?.[key] &&
      Array.isArray(serialNumberData?.[0]?.[key])
      ? serialNumberData[0][key].reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            dataId: next.dataId,
            serialRowId: shortid.generate()
          })
          return acc
        }, [])
      : [{ dataId: 'blankrow', serialRowId: shortid.generate() }]
  },
  plainDeepEqual
)

const generateSerialNumberBinData = (result, usesLotsEditor) => {
  const key = usesLotsEditor ? 'lotId' : 'serialNumberId'
  // debugger
  return [
    {
      serialNumbers:
        result?.data?.detail?.reduce((acc, next) => {
          if (next?.[key]) {
            acc = acc.concat({
              ...next,
              dataId: next[key]
            })
          }
          return acc
        }, []) || []
    }
  ]
}

const SerialNumberGrid = ({
  dataId,
  rowId,
  inquiryMode,
  lineNumber,
  warehouseId,
  form,
  serialNumberData,
  excludedSerialNumbers = [],
  includedSerialNumbers = [],
  serialNumberSearchType = 'A',
  transactionType = 'S',
  isSerialNumberReview = false,
  SearchComponent = DDISerialNumberSearch,
  quantity,
  usesLotsEditor = false,
  lotsEditorMeta = {}
}) => {
  // console.log('serialNumberDropdown_grid', excludedSerialNumbers)
  // debugger
  const dispatch = useDispatch()
  const rowData = getSerialNumberData(serialNumberData, usesLotsEditor)
  const [serialNumbers, manageSerials] = useStateWithCallback(rowData)

  const addSerialNumberRow = debounce(e => {
    if (
      inquiryMode ||
      (serialNumbers?.length &&
        serialNumbers?.length === quantity &&
        !usesLotsEditor)
    ) {
      return
    }

    if (
      serialNumbers &&
      Array.isArray(serialNumbers) &&
      serialNumbers?.every(x => x?.dataId) &&
      serialNumbers?.every(x => x?.dataId !== 'blankrow')
    ) {
      manageSerials([
        ...serialNumbers,
        { dataId: 'blankrow', serialRowId: shortid.generate() }
      ])
    } else if (!serialNumbers?.length || !serialNumbers) {
      manageSerials([{ dataId: 'blankrow', serialRowId: shortid.generate() }])
    }
  }, 500)

  useEffect(() => {
    /* try to add a row if there are none */
    addSerialNumberRow()
    return () => {
      /* destroy these temporary fields */
      dispatch(destroySerialNumberFields(form))
    }
  }, [])

  const onChangeQuantity = async (value, lotId, setIsPosting) => {
    let result

    // if (!value) {
    //   return
    // }

    const properties = {
      lots: {
        binId: null,
        lotId,
        action: 'Q',
        quantity: value
      }
    }
    // debugger

    try {
      result = await dispatch(
        isSerialNumberReview
          ? handleSerialNumberReviewChange.try(form, {
              rowId,
              lineNumber,
              apiParams: {
                action: 'quantity',
                binUId: rowId,
                changeId: lotId,
                quantity: value
              }
            })
          : modifyLineItemSerialNumber.try(form, {
              rowId,
              apiParams: {
                lineNumber,
                properties
              }
            })
      )
    } catch (error) {
      console.log(error)
    } finally {
      if (result?.data || result?.record) {
        // debugger

        // dispatch(destroySerialNumberFields(form))
        const serials = isSerialNumberReview
          ? generateSerialNumberBinData(result, usesLotsEditor)
          : result?.record?.detail?.lineItems?.find(
              x => x?.lineNumber === lineNumber
            )?.bins

        // debugger
        const key = 'lots'
        const hasReachedLimit =
          serials?.[0]?.[key]?.length === quantity && !usesLotsEditor

        if (hasReachedLimit) {
          manageSerials(
            getSerialNumberData(serials, usesLotsEditor, isSerialNumberReview)
          )
        } else {
          // debugger
          manageSerials([
            ...getSerialNumberData(
              serials,
              usesLotsEditor,
              isSerialNumberReview
            ),
            { dataId: 'blankrow', serialRowId: shortid.generate() }
          ])
        }
        setIsPosting(false)
      } else {
        // debugger
        // dispatch(destroySerialNumberFields(form))
        manageSerials(
          serialNumbers.reduce((acc, next) => {
            acc = acc.concat({
              ...next,
              serialRowId:
                next?.dataId === 'blankrow'
                  ? shortid.generate()
                  : next?.serialRowId
            })
            return acc
          }, [])
        )
        setIsPosting(false)
      }
    }
  }

  const onSetField = async (value, isNew = false) => {
    debugger
    let result

    if (!value) {
      return
    }

    const properties = usesLotsEditor
      ? {
          lots: {
            binId: null,
            lotId: value,
            action: isNew ? 'N' : 'A',
            quantity: null
          }
        }
      : {
          serialNumbers: {
            binId: null,
            serialNumber: value,
            action: 'A'
          }
        }

    try {
      result = await dispatch(
        isSerialNumberReview
          ? handleSerialNumberReviewChange.try(form, {
              rowId,
              lineNumber,
              apiParams: {
                action: isNew ? 'create' : 'add',
                binUId: rowId,
                changeId: value
              }
            })
          : modifyLineItemSerialNumber.try(form, {
              rowId,
              apiParams: {
                lineNumber,
                properties
              }
            })
      )
    } catch (error) {
      console.log(error)
    } finally {
      if (result?.data || result?.record) {
        debugger

        dispatch(destroySerialNumberFields(form))
        const serials = isSerialNumberReview
          ? generateSerialNumberBinData(result, usesLotsEditor)
          : result?.record?.detail?.lineItems?.find(
              x => x?.lineNumber === lineNumber
            )?.bins

        // debugger
        const key = usesLotsEditor ? 'lots' : 'serialNumbers'
        const hasReachedLimit =
          serials?.[0]?.[key]?.length === quantity && !usesLotsEditor

        if (hasReachedLimit) {
          manageSerials(
            getSerialNumberData(serials, usesLotsEditor, isSerialNumberReview)
          )
        } else {
          // debugger
          manageSerials([
            ...getSerialNumberData(
              serials,
              usesLotsEditor,
              isSerialNumberReview
            ),
            { dataId: 'blankrow', serialRowId: shortid.generate() }
          ])
        }
      } else {
        // debugger
        dispatch(destroySerialNumberFields(form))
        manageSerials(
          serialNumbers.reduce((acc, next) => {
            acc = acc.concat({
              ...next,
              serialRowId:
                next?.dataId === 'blankrow'
                  ? shortid.generate()
                  : next?.serialRowId
            })
            return acc
          }, [])
        )
      }
    }
  }

  const removeSerialNumberRow = debounce(async (e, id, index) => {
    debugger
    if (id === 'blankrow') {
      manageSerials(serialNumbers.filter((serial, i) => i !== index), () =>
        dispatch(destroyField(form, `serialNumbers.${rowId}-${index}`))
      )
    } else {
      let result
      const hasBlankRow = serialNumbers.find(x => x.dataId === 'blankrow')
      // const hasReachedLimit =
      //   serialNumbers?.length && serialNumbers?.length === quantity
      const apiParams = usesLotsEditor
        ? {
            lineNumber,
            properties: {
              lots: {
                binId: null,
                lotId: id,
                action: 'R'
              }
            }
          }
        : {
            lineNumber,
            properties: {
              serialNumbers: {
                binId: null,
                serialNumber: id,
                action: 'R'
              }
            }
          }

      try {
        result = await dispatch(
          isSerialNumberReview
            ? handleSerialNumberReviewChange.try(form, {
                rowId,
                lineNumber,
                apiParams: {
                  action: 'remove',
                  binUId: rowId,
                  changeId: id
                }
              })
            : modifyLineItemSerialNumber.try(form, {
                rowId,
                apiParams
              })
        )
      } catch (error) {
        console.log(error)
      } finally {
        dispatch(destroySerialNumberFields(form))
        const serials = isSerialNumberReview
          ? generateSerialNumberBinData(result, usesLotsEditor)
          : result?.record?.detail?.lineItems?.find(
              x => x?.lineNumber === lineNumber
            )?.bins

        manageSerials(
          hasBlankRow
            ? [
                ...getSerialNumberData(
                  serials,
                  usesLotsEditor,
                  isSerialNumberReview
                ),
                { dataId: 'blankrow', serialRowId: shortid.generate() }
              ]
            : getSerialNumberData(serials, usesLotsEditor, isSerialNumberReview)
        )
      }
    }
  }, 500)

  return (
    <div style={{ maxWidth: '100%', width: '100%' }}>
      <Table size="small">
        <TableHead>
          {usesLotsEditor ? (
            <TableRow>
              <TableCell>
                <div style={{ width: 125 }}>Lot No.</div>
              </TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Mfg Date</TableCell>
              <TableCell>Exp Date</TableCell>
              <TableCell align="right">Avail</TableCell>
              <TableCell align="right">
                <div style={{ width: 75 }}>Qty.</div>
              </TableCell>
              <TableCell
                align="center"
                style={{ paddingRight: 0, textAlign: 'center' }}
              >
                <div style={{ textAlign: 'center', width: 50 }}>X</div>
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell
                colspan={2}
                style={{
                  background: 'rgb(245, 247, 247)',
                  color: 'rgba(0, 0, 0, 0.54)',
                  textAlign: 'center'
                }}
              >
                Serial Numbers
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
                  {usesLotsEditor ? (
                      <LotsGrid
                          lots={serialNumbers}
                          dataId={dataId}
                          rowId={rowId}
                          lotsEditorMeta={lotsEditorMeta}
                          warehouseId={warehouseId}
                          onSetField={onSetField}
                          onChangeQuantity={onChangeQuantity}
                          removeSerialNumberRow={removeSerialNumberRow}
                          inquiryMode={inquiryMode}
            />
          ) : (
            serialNumbers.map((item, i) => {
              return (
                <TableRow
                  key={`lineItemSerialNumber-${dataId}-${rowId}-${shortid.generate()}`}
                >
                  <TableCell>
                    <SearchComponent
                      propertyName={`serialNumbers.${rowId}.${i}`}
                      meta={{
                        allowInstantSearch: false,
                        allowInvalidValues: true,
                        allowNavigation: false,
                        allowSearchAll: true,
                        allowSpecialProducts: false,
                        fieldName: `serialNumbers.${rowId}.${i}`,
                        hasFilters: false,
                        hideToggle: true,
                        isSecure: false,
                        minimumKeywordLength: 2,
                        patternValidation: 'S',
                        searchType: serialNumberSearchType,
                        excludedSerialNumbers:
                          excludedSerialNumbers ||
                          serialNumbers?.reduce((acc, next) => {
                            if (next?.dataId && next?.dataId !== 'blankrow') {
                              acc = acc.concat(next.dataId)
                            }
                            return acc
                          }, []) ||
                          [],
                        includedSerialNumbers,
                        transactionType
                      }}
                      wrapperStyle={{ margin: 0, width: '100%' }}
                      preventAutoDisable
                      disabled={
                        inquiryMode ||
                        (item.dataId && item.dataId !== 'blankrow')
                      }
                      productId={dataId}
                      warehouseId={warehouseId}
                      returnTransactionId={null}
                      onSetField={onSetField}
                      initialValue={
                        item?.dataId && item?.dataId !== 'blankrow'
                          ? item.dataId
                          : null
                      }
                      value={
                        item?.dataId && item?.dataId !== 'blankrow'
                          ? item.dataId
                          : null
                      }
                    />
                  </TableCell>
                  <TableCell style={{ width: 50 }}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <IconButton
                        disabled={inquiryMode}
                        style={{ color: inquiryMode ? mediumGrey : errorRed }}
                        size="small"
                        onClick={e => removeSerialNumberRow(e, item.dataId, i)}
                      >
                        <Icon size="small">cancel</Icon>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              )
            })
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              colspan={usesLotsEditor ? 7 : 2}
              style={{
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.9rem',
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              Total:{' '}
              {serialNumbers?.reduce((acc, next) => {
                if (next?.dataId && next?.dataId !== 'blankrow') {
                  usesLotsEditor ? (acc += next.factoredQuantity) : (acc += 1)
                }

                return acc
              }, 0) || 0}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <div
        style={{
          display: inquiryMode ? 'none' : 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          padding: 10,
          background: '#e9e9e9'
        }}
      >
        <Button
          variant="contained"
          onClick={addSerialNumberRow}
          size="small"
          color="secondary"
          tabIndex={-1}
          disabled={
            serialNumbers?.length &&
            serialNumbers?.length === quantity &&
            !usesLotsEditor
          }
        >
          <Icon style={{ fontSize: 16, marginRight: 5 }}>add_circle</Icon>
          <span>{usesLotsEditor ? 'Add Lot' : 'Add Serial Number'}</span>
        </Button>
      </div>
    </div>
  )
}

export default SerialNumberGrid
