/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from 'grid'
import DDICardWrapper from 'components/DDICardWrapper'
import { isEqual } from 'lodash'
import { DDISalesOrderSearch, DDIToggle } from 'ddiForm/wrapped'

import { getIn, plainDeepEqual, toJS } from 'utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const orderId = getIn(formState, 'fields.copyProducts.orderId.value') || ''
  const orderOptionIsPosting = getIn(formState, 'orderOptionIsPosting') || false
  const rowData = getIn(formState, 'fields.copyProducts.lineItems.rowData')

  return {
    orderId,
    orderOptionIsPosting,
    rowData
  }
}

export const columnDefs = [
  {
    field: 'isSelected',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 50,
    maxWidth: 50,
    width: 50,
    suppressMenu: true
  },
  {
    field: 'dataId',
    headerName: 'Product Number',
    minWidth: 100,
    maxWidth: 100,
    width: 100
  },
  {
    field: 'description',
    headerName: 'Description'
  },
  {
    field: 'quantityOrdered',
    headerName: 'Quant Ordered',
    headerStyle: { textAlign: 'right' },
    cellStyle: { textAlign: 'right' }
  },
  {
    field: 'uomId',
    headerName: 'Quant UM'
  }
]

const getRowNodeId = data => `${data.dataId}-${data.lineNumber}`

// const getDataIds = data => data.reduce((acc, next) => {
//   acc = acc.concat(next.dataId)
//   return acc
// }, [])

const labelStyle = {
  color: '#444',
  fontSize: 12,
  lineHeight: '16px',
  margin: '0 10px 0 0'
}

export class CopyProductsModal extends Component {
  static propTypes = {
    orderId: PropTypes.string.isRequired,
    rowData: PropTypes.object.isRequired
    // data: PropTypes.object.isRequired
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !plainDeepEqual(prevProps.rowData, this.props.rowData) &&
      this.gridApi
    ) {
      setTimeout(() => {
        this.gridApi.forEachNode(node => node.setSelected(true))
      }, 0)
    }
  }

  // onRowSelected = params => {
  //   const dataIds = getDataIds(params.api.getSelectedRows())
  // }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  getGridHeight = memoize(data => {
    let height = 200
    if (data && data.length) {
      height = data.length * 29 + 32
    }

    return height
  }, isEqual)

  onGridReady = params => {
    if (params && params.api) {
      this.gridApi = params.api

      this.gridApi.forEachNode(node => node.setSelected(true))
    }
  }

  getPostData = () => {
    /* this function is called in copyOrderSagas in the OK clickEvent */
    let postData = null

    if (this.gridApi) {
      const clientSideRowModel = this.gridApi.getModel()
      postData = clientSideRowModel.rowsToDisplay.reduce((acc, next) => {
        acc = acc.concat({
          ...next.data,
          isSelected: next.selected
        })
        return acc
      }, [])
    }
    // console.log('selectedRows', postData)
    //

    return postData
  }

  render() {
    const { orderId, rowData } = this.props
    const convertedRowData = toJS(rowData)

    const searchDisabled = Boolean(
      orderId && Array.isArray(convertedRowData) && convertedRowData.length
    )

    // /* disabled is not getting to SearchBase from DDISalesOrderSearch */
    // console.log('searchDisabled', searchDisabled)

    /* do not remove these classNames, they are used in mobile -- SVE 12/1/2020 */
    return (
      <div
        className="soe-copy-products-wrapper"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        <div
          className="soe-copy-products-left"
          style={{ paddingRight: 15, width: '30%' }}
        >
          <div
            style={{ alignItems: 'center', display: 'flex', margin: '15px 0' }}
          >
            <label htmlFor="copyProductsOrderId" style={labelStyle}>
              Order:
            </label>
            <div style={{ width: 150 }}>
              <DDISalesOrderSearch
                propertyName="copyProducts.orderId"
                preventAutoDisable
                disabled={searchDisabled}
              />
            </div>
          </div>

          <DDICardWrapper
            title="Options"
            contentStyle={{ padding: '5px 10px 10px 10px' }}
          >
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.price"
                label="Prices"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.costOverrides"
                label="Cost Overrides"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.externalNotes"
                label="External Notes"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.internalNotes"
                label="Internal Notes"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.repairInfo"
                label="Repair"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.taxable"
                label="Taxable"
              />
            </AssociatedFieldsWrapper>
            <AssociatedFieldsWrapper>
              <DDIToggle
                preventAutoDisable
                propertyName="copyProducts.itemProcurementTypes"
                label="Item Procurement Types"
              />
            </AssociatedFieldsWrapper>
          </DDICardWrapper>
        </div>
        <div className="soe-copy-products-right" style={{ width: '70%' }}>
          <DDICardWrapper title="Line Items" contentStyle={{ padding: 0 }}>
            <Grid
              rowData={convertedRowData}
              columnDefs={columnDefs}
              getRowNodeId={getRowNodeId}
              rowSelection="multiple"
              suppressRowClickSelection
              // onRowSelected={this.onRowSelected}
              onGridReady={this.onGridReady}
              height={350}
              suppressHorizontalScroll
            />
          </DDICardWrapper>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(CopyProductsModal)
