/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getCorporateFieldUpdatesMeta = createAsyncFormAction(
  CONSTANTS.GET_CORPORATE_FIELD_UPDATES_META
)

export const readCorporateFieldUpdatesData = createAsyncFormAction(
  CONSTANTS.READ_CORPORATE_FIELD_UPDATES_DATA
)

export const saveCoprorateFieldUpdatesData = createAsyncFormAction(
  CONSTANTS.SAVE_CORPORATE_FIELD_UPDATES_DATA
)

export const editCorporateFieldUpdatesData = createAsyncFormAction(
  CONSTANTS.EDIT_CORPORATE_FIELD_UPDATES_DATA
)

export const cancelEditCorporateFieldUpdatesData = createAsyncFormAction(
  CONSTANTS.CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA
)

export const setRowData = (form, { rowData = [] }) =>
  createAction(CONSTANTS.SET_ROW_DATA, { rowData }, form)

export const destroyModal = (form, { modalId }) =>
  createAction(CONSTANTS.DESTROY_MODAL, { modalId }, form)

export const clearValues = form =>
  createAction(CONSTANTS.CLEAR_VALUES, {}, form)
