import React from 'react'
import { withContext } from 'ddiForm'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { removeModal } from 'modals/actions'
import { getIn } from 'utils'
import { createNewProduct, cancelPreNewMode } from '../actions'

const PatternTypeModalActions = ({ patternSetDataId, form, modal }) => {
  const dispatch = useDispatch()

  const tryCreateNewProduct = e => {
    dispatch(
      createNewProduct.try(form, {
        modalId: modal?.id
      })
    )
  }

  const closeModal = e => {
    dispatch(cancelPreNewMode(form))
    if (modal?.id) {
      dispatch(removeModal(form, modal.id))
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end'
      }}
    >
      <Button
        // disabled={!patternSetDataId}
        onClick={tryCreateNewProduct}
        variant="contained"
        style={{ marginRight: 5 }}
      >
        OK
      </Button>
      <Button onClick={closeModal} variant="contained">
        Cancel
      </Button>
    </div>
  )
}

export default withContext({
  patternSetDataId: form => getIn(form, 'fields.patternSetDataId.value') || ''
})(PatternTypeModalActions)
