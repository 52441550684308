/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { Col, Row } from 'react-bootstrap'
import {
  Button,
  IconButton,
  Popover,
  Icon,
  Input,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import NumberSpinner from 'ddiForm/NumberSpinner'
import { getIn } from 'utils'

import * as actions from '../actions'
import {
  editablePriceSelector,
  editablePriceErrorMessageSelector
} from '../selectors'

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    ...other
  } = props

  /*
    had to change this component to receive
    decimal props like this
  */
  const decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  return (
    <NumberFormat
      {...other}
      {...decimalFormatting}
      disabled={disabled}
      id={id}
      getInputRef={inputRef}
      forwardRef
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      prefix={prefix}
      suffix={suffix}
      style={{
        color: value < 0 ? '#d9534f' : disabled ? '#999' : '#000',
        fontSize: fontSize || '1em',
        height: '100%',
        paddingLeft: 0,
        paddingRight,
        // paddingLeft: textAlign === 'left' ? 10 : 0,
        // paddingRight: textAlign === 'right' ? 0 : 0,
        textAlign,
        width: '100%'
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      maxLength={maxLength}
    />
  )
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { form } = ownProps
  return {
    calculateOrderPadPrice: ({ cost, gpPercent, updateField }) =>
      dispatch(
        actions.calculateOrderPadPriceAsync.try(form, {
          cost,
          gpPercent,
          updateField
        })
      ),
    dismissAPCErrorMessage: () =>
      dispatch(actions.dismissAPCErrorMessage(form)),
    setEditablePrice: ({ price, updateField }) =>
      dispatch(
        actions.setEditablePrice(form, { price, updateField })
      ),
    setInitialEditablePrice: ({ price, updateField }) =>
      dispatch(
        actions.setInitialEditablePrice(form, { price, updateField })
      )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  // editablePrice: editablePriceSelector(state) || 0,
  // errorMessage: editablePriceErrorMessageSelector(state) || ''
  const formState = getIn(state, `ddiForm.${form}`)
  return {
    editablePrice: getIn(formState, 'fields.editablePrice.value') || 0,
    errorMessage: getIn(formState, 'fields.editablePrice.errorMessage') || '',
    updateFieldSet: getIn(formState, 'fields.editablePrice.updateField') ? true : false // eslint-disable-line
  }
}

export class PriceCalculator extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    editablePrice: PropTypes.number,
    initialPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    matrixCost: PropTypes.number,
    clearEditablePrice: PropTypes.bool,
    showPriceCalculator: PropTypes.bool,
    textFieldDisabled: PropTypes.bool,
    updateField: PropTypes.string,
    setInitialEditablePrice: PropTypes.func.isRequired
  }

  static defaultProps = {
    clearEditablePrice: false,
    showPriceCalculator: true,
    textFieldDisabled: false
  }

  constructor(props) {
    super(props)
    this.state = {
      clearEditablePrice: props.clearEditablePrice,
      editablePrice: props.initialPrice,
      gpPercent: 0.0,
      isEditing: false,
      popoverIsOpen: false
    }
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this.popoverAnchor)
  }

  // componentDidMount() {
  //   this.anchorEl = findDOMNode(this.popoverAnchor)
  //   const { updateField, initialPrice, updateFieldSet } = this.props
  //   if (!updateFieldSet) {
  //     this.props.setInitialEditablePrice({
  //       price: initialPrice,
  //       updateField
  //     })
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.editablePrice || nextProps.editablePrice === 0) {
      if (prevState.editablePrice !== nextProps.editablePrice && !prevState.isEditing) {
        // debugger
        return {
          editablePrice: nextProps.editablePrice,
          isEditing: false
        }
      }
    }

    if (
      nextProps.clearEditablePrice === true &&
      prevState.clearEditablePrice !== nextProps.clearEditablePrice
    ) {
      return {
        clearEditablePrice: nextProps.clearEditablePrice,
        editablePrice: '',
        popoverIsOpen: false
      }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.clearEditablePrice === true &&
      prevProps.clearEditablePrice === false
    ) {
      this.props.setEditablePrice({ price: '', updateField: '' })
    }
  }

  openPopover = event => {
    this.setState({
      anchorEl: event.currentTarget,
      popoverIsOpen: true
    })
  }

  closePopover = () => {
    this.setState({ popoverIsOpen: false })
  }

  onChange = id => event => {
    const {value} = event.target
    this.setState({ [id]: value, isEditing: true })
  }

  onNumberFormatterChange = values => {
    this.setState({
      editablePrice: values.floatValue,
      isEditing: true
    }, () => {
      setTimeout(() => {
        this.props.setEditablePrice({
          price: values.floatValue,
          updateField: this.props.updateField
        })
      }, 0)
    })
  }

  onBlur = () => this.setState({ isEditing: false  })
  // onBlur = () => {
    // const price = this.state.editablePrice
    // this.setState({ isEditing: false  }, () => {
    //   this.props.setEditablePrice({
    //     price,
    //     updateField: this.props.updateField
    //   })
    // })
  // }

  onNumberSpinnerChangeCallback = value => this.setState({ isEditing: false })

  calculateOrderPadPrice = () => {
    const { calculateOrderPadPrice, matrixCost, updateField } = this.props
    this.setState({ isEditing: false }, () => {
      calculateOrderPadPrice({
        cost: matrixCost,
        gpPercent: this.numberSpinner.value,
        updateField
      })
    })
  }

  dismissErrorMessage = () => {
    const { dismissAPCErrorMessage } = this.props
    dismissAPCErrorMessage()
  }

  render() {
    const { editablePrice, gpPercent, popoverIsOpen } = this.state
    const {
      errorMessage,
      matrixCost,
      showPriceCalculator,
      textFieldDisabled
    } = this.props

    return (
      <div style={{ position: 'relative', width: 150 }}>
        <div
          style={{
            left: 0,
            position: 'absolute',
            top: 0,
            zIndex: 1
          }}
        >
          <Input
            fullWidth
            disabled={textFieldDisabled}
            value={editablePrice}
            inputComponent={NumberFormatter}
            onChange={this.onNumberFormatterChange}
            onBlur={this.onBlur}
            inputProps={{
              allowNegative: true,
              decimalScale: 4,
              noDecimalLimit: false,
              decimalSeparator: '.',
              fontSize: '1em',
              fixedDecimalScale: true,
              inputRef: el => (this.popoverAnchor = el),
              maxLength: 999,
              textAlign: 'left',
              thousandSeparator: '',
              prefix: ''
            }}
          />
        </div>
        {showPriceCalculator && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 2
            }}
          >
            <IconButton
              disabled={textFieldDisabled}
              onClick={this.openPopover}
              style={{ width: 24, height: 24, padding: '2px 5px' }}
            >
              <Icon>keyboard_arrow_down</Icon>
            </IconButton>
            <Popover
              open={popoverIsOpen}
              anchorEl={this.anchorEl}
              onClose={this.closePopover}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              PaperProps={{
                style: {
                  width: 300
                }
              }}
            >
              <div style={{ padding: 20, width: 300 }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell style={{ width: 75 }}>Cost:</TableCell>
                      <TableCell>
                        <strong>
                          {matrixCost ? matrixCost.toFixed(4) : matrixCost}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div style={{ alignItems: 'center', display: 'flex', padding: '15px 10px' }}>
                  <span style={{ fontSize: 12, width: 75 }}>
                    GP Pct:
                  </span>
                  <NumberSpinner
                    initialValue={gpPercent}
                    isField={false}
                    ref={el => this.numberSpinner = el}
                    // updateValueCallback={this.onNumberSpinnerChangeCallback}
                    step={1.00}
                    min={0.00}
                    max={100}
                    inputProps={{
                      decimalScale: 4,
                      fontSize: 13,
                      fixedDecimalScale: true,
                      // noDecimalLimit: true
                    }}
                  />
                </div>
              </div>
              {errorMessage ? (
                <div className="text-center">
                  <div
                    className="alert alert-warning"
                    style={{ padding: 5, margin: '0 10px 15px 10px' }}
                  >
                    <Icon style={{ verticalAlign: 'middle' }}>warning</Icon>
                    <span style={{ fontSize: 12 }}>
                      {errorMessage}{' '}
                      <a onClick={this.dismissErrorMessage}>Dismiss</a>
                    </span>
                  </div>
                </div>
              ) : null}
              <div className="text-center" style={{ marginBottom: 15 }}>
                <Button variant="contained" onClick={this.calculateOrderPadPrice}>
                  OK
                </Button>
              </div>
            </Popover>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PriceCalculator)
