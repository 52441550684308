import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DDITextField, DDIIndexSearch, DDIToggle } from 'ddiForm/wrapped'
import { Paper, FormControlLabel, Switch as Toggle } from '@material-ui/core'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { required, maxLength } from 'ddiForm/validations'
import { setField } from 'ddiForm/actions'
import { getIn, isUsaCanadaOrBlank, toJS } from 'utils'

import '../styles/shipto-modal.scss'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const changeShipTo = getIn(formState, 'values.changeShipTo') || {}
  const shipToAddress = getIn(formState, 'values.shipToAddress') || {}

  return {
    changeShipTo,
    shipToAddress
  }
}

export const ChangeShipToModal = ({
  data: { shipToAddress, form },
  changeShipTo,
  dispatch
}) => {
  changeShipTo = toJS(changeShipTo)

  const nameField = useRef(null)

  const manualSetSave = e => {
    const { checked } = e.target

    dispatch(setField(form, 'changeShipTo.save', checked))
  }
  const [isUsaOrCanada, setIsUsaOrCanada] = useState(false)
  useEffect(() => {
    setIsUsaOrCanada(
      isUsaCanadaOrBlank(changeShipTo?.country || shipToAddress?.country)
    )
  }, [changeShipTo?.country, shipToAddress])
  // useEffect(() => {}, [])
  useEffect(() => {
    if (nameField?.current) {
      setTimeout(() => {
        nameField?.current?.focus()
        nameField?.current?.select()
      }, 1)
    }
  }, [nameField.current])

  console.log(shipToAddress, changeShipTo)
  return (
    <div className="shipto-modal-outer-wrapper">
      <Paper style={{ padding: '25px' }} elevation={4}>
        <h2 className="heading">Ship To Information</h2>
        <div className="division">
          <div className="two-col-flex-wrapper">
            <div className="two-col-flex-left">
              <DDITextField
                label="Name"
                propertyName="changeShipTo.description"
                initialValue={shipToAddress.description ?? ''}
                fullWidth
                inputRef={c => (nameField.current = c)}
                validate={[required, maxLength(30)]}
                InputProps={{
                  inputProps: {
                    maxLength: 30
                  }
                }}
              />
            </div>

            <div className="two-col-flex-right">
              <DDITextField
                label="Address"
                propertyName="changeShipTo.address1"
                initialValue={shipToAddress.address1 ?? ''}
                fullWidth
              />
            </div>
          </div>

          <div className="two-col-flex-wrapper">
            <div className="two-col-flex-left">
              <DDITextField
                label="Line 2"
                propertyName="changeShipTo.address2"
                initialValue={shipToAddress.address2 ?? ''}
              />
            </div>
            <div className="two-col-flex-right">
              <DDITextField
                label="Line 3"
                propertyName="changeShipTo.address3"
                initialValue={shipToAddress.address3 ?? ''}
              />
            </div>
          </div>

          <div className="two-col-flex-wrapper">
            <div className="two-col-flex-left">
              <DDITextField
                label="City"
                propertyName="changeShipTo.city"
                initialValue={shipToAddress.city ?? ''}
                fullWidth
                preventAutoDisable
                validate={maxLength(isUsaOrCanada ? 20 : 30)}
                inputProps={{
                  maxLength: isUsaOrCanada ? 20 : 30
                }}
              />
            </div>

            <div className="two-col-flex-right two-col-flex-multi">
              <div className="two-col-flex-wrapper">
                <div className="two-col-flex-left">
                  <DDITextField
                    label="State"
                    propertyName="changeShipTo.state"
                    initialValue={shipToAddress.state ?? ''}
                    fullWidth
                    preventAutoDisable
                  />
                </div>
                <div className="two-col-flex-right">
                  <DDITextField
                    label="Zip Code"
                    propertyName="changeShipTo.postalCode"
                    initialValue={shipToAddress.postalCode ?? ''}
                    fullWidth
                    preventAutoDisable
                    // mask="zipCode"
                    // inputProps={{
                    //   guide: false
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="two-col-flex-wrapper">
            <div className="two-col-flex-left">
              <DDITextField
                label="Country"
                propertyName="changeShipTo.country"
                initialValue={shipToAddress.country ?? ''}
                fullWidth
              />
            </div>
            <div className="two-col-flex-right">
              <DDITextField
                label="Attention"
                propertyName="changeShipTo.attention"
                initialValue={shipToAddress.attention ?? ''}
                fullWidth
              />
            </div>
          </div>

          <div className="utility-block-wrapper">
            {shipToAddress && shipToAddress.save && shipToAddress.showTax && (
              <div className="search-row-wrapper">
                <div className="search-row-label">Tax Code</div>
                <div className="search-row-input">
                  <DDIIndexSearch
                    propertyName="changeShipTo.taxId"
                    initialValue={shipToAddress.taxId}
                    metaKey="Tax Code"
                    preventAutoDisable
                  />
                </div>
              </div>
            )}

            {shipToAddress && shipToAddress.canChangeSave && (
              <div className="flex-wrapper">
                <AssociatedFieldsWrapper
                  style={{ float: 'none', clear: 'none' }}
                >
                  <FormControlLabel
                    control={
                      <DDIToggle
                        propertyName="changeShipTo.save"
                        onChange={manualSetSave}
                        value={shipToAddress.save}
                        preventAutoDisable
                      />
                    }
                    label="Save Manual Ship To Info"
                    labelPlacement="start"
                    style={{
                      margin: '5px 0px',
                      fontSize: '1rem',
                      fontWeight: 400
                    }}
                  />
                </AssociatedFieldsWrapper>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ChangeShipToModal)
