import { all, take, call, delay, select, put, fork } from 'redux-saga/effects'
import { getIn } from 'utils'
import { ADD_BLANK_ROW } from 'ddiForm/constants'
import { destroy } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'
import { addModal } from 'modals/actions'
// import PartNumbersNotesEditorCell from 'components/EditableGrid/components/PartNumbersNotesEditorCell'
import PartNumbersNotesEditor from './components/PartNumbersNotesEditor'

import * as CONSTANTS from './constants'
import * as actions from './actions'

export const getRowDataIdentifier = propertyName => {
  let rowDataIdentifier = 'dataId'

  const identifierMap = {
    expenseGLIds: 'glId',
    glDistribution: 'glId'
  }

  if (identifierMap[propertyName]) {
    rowDataIdentifier = identifierMap[propertyName]
  }

  return rowDataIdentifier
}

export function* duplicatePrimaryGridDataCheckProcess(payload, form) {
  // const { rowIndex, newData, propertyName, allowDuplicates, allowDuplicatesRequiresModal, entityType } = payload
  const {
    rowId,
    newData,
    propertyName,
    allowDuplicates,
    allowDuplicatesRequiresModal,
    entityType,
    gridApi
  } = payload

  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    ? getIn(formState, `fields.${propertyName}.rowData`).toJS()
    : []

  /* get the rowIndex by pulling it from Redux by EditableGrid's rowId */
  const rowIndex = rowData.findIndex(x => x.rowId === rowId)
  const { name, recordName } = newData
  const id = name || recordName
  const rowDataIdentifier = getRowDataIdentifier(propertyName)
  const isDuplicate = rowData.find(
    (x, idx) => x[rowDataIdentifier] === id && idx !== rowIndex
  )
  // debugger

  /* if we have a duplicate record, throw a modal and clear out the row */
  if (isDuplicate && id) {
    if (allowDuplicates) {
      if (
        allowDuplicatesRequiresModal === 'O' ||
        allowDuplicatesRequiresModal === 'B'
      ) {
        /* this means we are just gonna allow duplicates without any modal interaction */
        yield fork(dispatchValidatedGridData, form, {
          rowIndex,
          newData,
          propertyName,
          gridApi
        })
      } else {
        /* otherwise we need a confirmation modal here */
        const duplicateMessageHeader = entityType
          ? `Duplicate ${entityType}`
          : 'Duplicate Item'
        const duplicateMessage =
          isDuplicate[rowDataIdentifier] &&
          isDuplicate.description &&
          isDuplicate.lineNumber
            ? `${isDuplicate[rowDataIdentifier]} ${isDuplicate.description} already found on line ${isDuplicate.lineNumber}. Continue?`
            : 'This item already exists. Continue?'

        yield call(confirmationModal, duplicateMessage, duplicateMessageHeader)
        const action = yield take([CONFIRMED, CANCELED])

        if (action.type === CONFIRMED) {
          yield fork(dispatchValidatedGridData, form, {
            rowIndex,
            newData,
            propertyName,
            gridApi
          })
        } else {
          yield fork(stripInvalidGridData, form, {
            rowIndex,
            newData,
            propertyName
          })
        }
        /* end confirmation modal logic */
      }
    } else {
      yield call(warningModal, `${id} already exists`, 'Attention!')
      yield fork(stripInvalidGridData, form, {
        rowIndex,
        newData,
        propertyName
      })
    }
  } else {
    yield fork(dispatchValidatedGridData, form, {
      rowIndex,
      newData,
      propertyName,
      gridApi
    })
  }
}

export function* stripInvalidGridData(
  form,
  { rowIndex, newData, propertyName }
) {
  // debugger
  yield put(
    actions.onPrimaryGridDataValidated(form, {
      rowIndex,
      newData: Object.keys(newData).reduce((acc, next) => {
        acc[next] = ''
        return acc
      }, {}),
      propertyName
    })
  )
}

export function* dispatchValidatedGridData(
  form,
  { rowIndex, newData, propertyName, gridApi }
) {
  //  debugger
  yield put(
    actions.onPrimaryGridDataValidated(form, {
      rowIndex,
      newData,
      propertyName,
      gridApi
    })
  )
  // yield put(actions.validateGridData(form, { propertyName }))

  if (gridApi) {
    gridApi.tabToNextCell()
  }
}

export function* validateGridOnLastCellTab(formListener) {
  while (true) {
    const {
      payload: {
        propertyName,
        lastCell,
        requiresAdditionalValidation,
        rowIndex,
        validated
      },
      meta: { form }
    } = yield take([
      CONSTANTS.UPDATE_GRID_CELL_DATA,
      CONSTANTS.ADDITIONAL_VALIDATION_SUCCESS
    ])

    const formState = yield select(state => getIn(state, `ddiForm.${form}`))
    const rowData = getIn(formState, `fields.${propertyName}.rowData`)
    // debugger

    if (formListener === form) {
      if (
        rowData &&
        rowData.size &&
        rowData.size === rowIndex + 1 &&
        ((lastCell && !requiresAdditionalValidation) || validated)
      ) {
        yield put(actions.validateGridData(form, { propertyName }))
        const isPending = getIn(formState, `fields.${propertyName}.isPending`)
        const emptyRow =
          getIn(formState, `fields.${propertyName}.emptyRow`) || null
        /* not all editableGrids add rows */

        if (!isPending && emptyRow) {
          yield put({
            type: ADD_BLANK_ROW,
            payload: { propertyName },
            meta: { form, reducer: 'Grid' }
          })
        }
      }
    }
  }
}

export function* duplicatePrimaryGridDataCheckListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.ON_PRIMARY_GRID_DATA_POSTED)
    if (
      form === formListener &&
      payload &&
      payload.newData &&
      (payload.newData.recordName || payload.newData.id)
    ) {
      // yield call(duplicatePrimaryGridDataCheckProcess, payload, form)
      yield fork(duplicatePrimaryGridDataCheckProcess, payload, form)
    }
  }
}

/*
  needed to add this for Product Master > Replenishments
  where the index search field is repeatedly editable
*/
export function* clearSearchListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(CLEAR_SEARCH)

    if (
      form === formListener &&
      propertyName.match(/editableGridIndexSearchField/gi)
    ) {
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const grid = propertyName.replace('.editableGridIndexSearchField', '')
      let focusedCell = getIn(formState, `fields.${grid}.focusedCell`)
      focusedCell = focusedCell && focusedCell.toJS ? focusedCell.toJS() : []
      if (focusedCell.field) {
        yield put(
          actions.updateGridCellData(form, {
            rowIndex: focusedCell.rowIndex,
            field: focusedCell.field,
            propertyName: grid,
            value: ''
          })
        )
      }
    }
  }
}

export function* requestInsertGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex }
    } = yield take(CONSTANTS.REQUEST_INSERT_GRID_ROW)

    if (form === formListener) {
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const rowData = getIn(formState, `fields.${propertyName}.rowData`)
      // console.log(rowData)

      /* validate the grid, this will prune any empty rows */
      yield put(
        actions.validateGridData(form, {
          propertyName,
          preInsertRow: true,
          rowIndex
        })
      )
    }
  }
}

export function* validateGridDataListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, rowIndex, preInsertRow },
      meta: { form }
    } = yield take(CONSTANTS.VALIDATE_GRID_DATA)

    if (form === formListener && preInsertRow) {
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const isPending = getIn(formState, `fields.${propertyName}.isPending`)

      if (!isPending) {
        yield put(actions.insertGridRow(form, { propertyName, rowIndex }))
      }
    }
  }
}

export function* addBlankRowListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(ADD_BLANK_ROW)
    // debugger

    yield fork(setBlankRowIdProcess, form, formListener, propertyName)
  }
}

export function* setBlankRowIdProcess(form, formListener, propertyName) {
  if (
    form === formListener &&
    propertyName &&
    propertyName !== 'lineItems' &&
    !propertyName?.match(/lineItemComponents/)
  ) {
    // debugger
    yield put(actions.setBlankRowId(form, { propertyName }))
  }
}

export function* openPartsNumberEditorProcess(form, payload) {
  const {
    field,
    note,
    rowId,
    rowIndex,
    propertyName,
    description,
    dataId,
    isEditing
  } = payload

  const titles = {
    internalComment: 'Internal Comments',
    externalComment: 'External Comments',
    woNotes: 'Part Number'
  }

  const modalOpts = {
    component: PartNumbersNotesEditor,
    options: {
      width: 400,
      title:
        titles[field] && dataId && description
          ? `${titles[field]}: ${dataId} - "${description}"`
          : `Comments: ${dataId} - "${description}"`,
      data: {
        note,
        isEditing,
        actions: [
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.updateGridCellData(form, {
                    rowId,
                    rowIndex,
                    propertyName,
                    field,
                    value: this.state.value
                  })
                )
              } finally {
                if (cb) {
                  cb()
                }
              }
            },
            disabled: formState => !formState.isEditing
          },
          {
            primary: true,
            title: 'Delete',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.updateGridCellData(form, {
                    rowId,
                    rowIndex,
                    propertyName,
                    field,
                    value: ''
                  })
                )
              } finally {
                if (cb) {
                  cb()
                }
              }
            },
            disabled: formState => !formState.isEditing
          },
          {
            primary: true,
            title: 'Exit'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.id
}

export function* openPartsNumberEditorListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.OPEN_PART_NUMBERS_NOTES_EDITOR)

    if (form === formListener) {
      yield fork(openPartsNumberEditorProcess, form, payload)
    }
  }
}

export function* onPrimaryGridCellValidationHandler(
  formListener,
  autoAddRow = []
) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, newData },
      type
    } = yield take([CONSTANTS.ON_PRIMARY_GRID_DATA_VALIDATED])
    // const autoAddRow = ['priceContracts', 'sources']
    // debugger

    if (form === formListener) {
      const formState = yield select(state => getIn(state, `ddiForm.${form}`))
      const isPending = getIn(formState, `fields.${propertyName}.isPending`)
      const emptyRow =
        getIn(formState, `fields.${propertyName}.emptyRow`) || null

      if (
        autoAddRow.includes(propertyName) &&
        type === CONSTANTS.ON_PRIMARY_GRID_DATA_VALIDATED
      ) {
        const { name, recordName } = newData
        const id = name || recordName
        // debugger
        if (!isPending && id && emptyRow) {
          yield delay(500)
          yield put({
            type: ADD_BLANK_ROW,
            payload: { propertyName },
            meta: { form, reducer: 'Grid' }
          })
        }
      }
    }
  }
}

export function* ensureFormExistsOnValidateGridData(form) {
  const formState = yield select(getFormSelector(form))

  /* 
    need this to prevent an error when exiting a modal 
    with an editable grid being actively used on a form
  */
  if (!formState || !formState?.size) {
    yield put(destroy(form))
  }
}

export function* validateGridRowListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.VALIDATE_GRID_DATA)

    if (form === formListener) {
      yield fork(ensureFormExistsOnValidateGridData, form)
    }
  }
}

export default function* editableGridSagas(form, autoAddRow = []) {
  yield all([
    fork(duplicatePrimaryGridDataCheckListener, form),
    fork(validateGridOnLastCellTab, form),
    fork(requestInsertGridRowListener, form),
    fork(validateGridDataListener, form),
    fork(addBlankRowListener, form),
    fork(openPartsNumberEditorListener, form),
    fork(onPrimaryGridCellValidationHandler, form, autoAddRow),
    fork(validateGridRowListener, form)
  ])
}
