import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { BLUR, SET_FIELD } from 'ddiForm/constants'
import { displayValidationErrors } from 'ddiForm/sagas'
import { fromJS, getIn, toCamelCase } from 'utils'
import { addModal, removeModal } from 'modals/actions'
import { warningModal, confirmationModal } from 'modals/sagas'
import { CONFIRMED, CANCELED, REMOVE_MODAL } from 'modals/constants'
import { show } from 'snackbar/actions'
import shortid from 'shortid'

import {
  NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED,
  NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED,
  ON_PROPERTY_CHANGE
} from 'pages/SalesOrder/constants'
import {
  handleNewlyAddedProductNotes,
  triggerBoxQuantityPrompts
} from 'pages/SalesOrder/sagas/commonSagas'
import { readSalesOrderProcess } from 'pages/SalesOrder/sagas/searchAreaSagas'
import * as CONSTANTS from './constants'
import * as actions from './actions'
import { productImport as productImportAPI } from './api'
import { createImportPostData, mappedColumnDefaults } from './utils'
import ProductImportModal from '.'
import ExceptionsReport from './components/ExceptionsReport'
import ImportSuccessAlert from './components/ImportSuccessAlert'

const getRoute = form => (form?.match(/salesOrder-/) ? 'salesOrder' : form)

export function* launchProductImportModal(form, response, lineItemsGridApi) {
  const { productLineId, productLineDescription } = response
  yield put(
    actions.setRowData(form, {
      rowData: [],
      clearData: true,
      includesMeta: true,
      productLineId,
      productLineDescription
    })
  )

  const modalOpts = {
    component: ProductImportModal,
    options: {
      actions: false,
      title: 'Sales Order Line Item Import',
      data: {
        form,
        lineItemsGridApi
      },
      fullScreen: true,
      marginTop: '0px',
      maxHeight: '95%',
      width: '95%',
      maxWidth: '95%'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* getInterfaceMetaData(form, lineItemsGridApi) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.openProductImportEditor.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'meta',
    guid,
    route
  })

  if (response) {
    yield put(actions.openProductImportEditor.success(response, form))
    yield fork(launchProductImportModal, form, response, lineItemsGridApi)
  } else {
    yield put(actions.openProductImportEditor.failure(error, form))
  }
}

export function* openProductImportEditorListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineItemsGridApi }
    } = yield take(CONSTANTS.OPEN_PRODUCT_IMPORT_EDITOR.TRY)

    if (form === formListener) {
      yield fork(getInterfaceMetaData, form, lineItemsGridApi)
    }
  }
}

export function* onPropertyChangeProcess(
  form,
  properties = {},
  isCellValueChange = false
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.onProductImportPropertyChange.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'propertyChange',
    guid,
    route,
    properties
  })

  if (response) {
    const importDataChanged =
      Object?.keys(properties)?.includes('importData') ||
      Object?.keys(properties)?.includes('templateId') ||
      Object?.keys(properties)?.includes('hasHeaders') ||
      false
    yield put(
      actions.onProductImportPropertyChange.success(
        {
          ...response,
          importDataChanged,
          isCellValueChange
        },
        form
      )
    )
  } else {
    yield put(actions.onProductImportPropertyChange.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* onPropertyChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { properties }
    } = yield take(CONSTANTS.ON_PRODUCT_IMPORT_PROPERTY_CHANGE.TRY)

    if (form === formListener) {
      yield fork(onPropertyChangeProcess, form, properties)
    }
  }
}

export function* handleSetMappedColumn(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  let sortOrder =
    getIn(formState, 'values.productImport.sortOrder') || fromJS([])
  let fieldMappings =
    getIn(formState, 'values.productImport.fieldMappings') || fromJS([])
  fieldMappings = fieldMappings?.toJS ? fieldMappings.toJS() : []
  sortOrder = sortOrder?.toJS ? sortOrder.toJS() : []
  const index = fieldMappings.findIndex(
    x => x?.description && toCamelCase(x.description) === propertyName
  )

  if (index < 0) {
    return
  }

  const properties = {
    fieldMappings: {
      [index]:
        value && sortOrder && Array.isArray(sortOrder)
          ? sortOrder.indexOf(value)
          : null
    }
  }
  // debugger

  yield fork(onPropertyChangeProcess, form, properties)
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(SET_FIELD)

    if (form === formListener && propertyName?.match(/productImport\./)) {
      const key = propertyName.split('.').pop()
      const properties = {
        [key]: value
      }

      if (
        mappedColumnDefaults.find(
          x => x.propertyName && toCamelCase(x.propertyName) === key
        )
      ) {
        yield fork(handleSetMappedColumn, form, key, value)
      } else {
        yield fork(onPropertyChangeProcess, form, properties)
      }
    }
  }
}

export function* onNewTemplateNamedListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(BLUR)

    if (form === formListener && propertyName === 'productImport.templateId') {
      const key = propertyName.split('.').pop()
      const properties = {
        [key]: value
      }

      yield fork(onPropertyChangeProcess, form, properties)
    }
  }
}

export function* analyzeProductImportDataProcess(form, modalId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.analyzeProductImportData.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'analyze',
    guid,
    route
  })

  if (response) {
    yield put(actions.analyzeProductImportData.success(response, form))
  } else {
    yield put(actions.analyzeProductImportData.failure(error, form))
    yield fork(handleAnalyseProductImportDataOutput, form, error, modalId)
  }
}

export function* handleAnalyseProductImportDataOutput(
  form,
  apiResponseData,
  modalId
) {
  console.log(handleAnalyseProductImportDataOutput, apiResponseData)
  const message = apiResponseData?.data?.message?.message
    ? apiResponseData.data.message.message
    : 'There were some errors'
  const modalTitle = apiResponseData?.data?.message?.modalTitle
    ? apiResponseData.data.message.modalTitle
    : 'Product Import Analysis'
  const responses = apiResponseData?.data?.message?.responses?.length || 1

  if (responses === 2) {
    yield call(confirmationModal, message, modalTitle)
    const action = yield take([CONFIRMED, CANCELED])
    if (
      action.type === CONFIRMED &&
      apiResponseData?.data?.report &&
      Array.isArray(apiResponseData?.data?.report) &&
      apiResponseData?.data.report.length
    ) {
      yield call(
        displayAnalysisExceptionsReportProcess,
        form,
        apiResponseData.data.report
      )
      yield fork(analyzeProductImportDataProcess, form, modalId)
    } else {
      yield fork(analyzeProductImportDataProcess, form, modalId)
    }
  } else {
    yield call(warningModal, message, modalTitle)
    yield take(REMOVE_MODAL)
    yield fork(analyzeProductImportDataProcess, form, modalId)
  }
}

export function* displayAnalysisExceptionsReportProcess(form, report = []) {
  if (!Array.isArray(report) || !report || !report.length) {
    return false
  }

  const modalOpts = {
    component: ExceptionsReport,
    options: {
      title: 'Sales Order Line Item Import - Exceptions Report',
      data: {
        form,
        report
      },
      maxHeight: 800,
      width: 600,
      maxWidth: 600
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield take(act => {
    return act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
  })

  return modal.payload.id
}

export function* analyzeProductImportDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(CONSTANTS.ANALYZE_PRODUCT_IMPORT_DATA.TRY)

    if (form === formListener) {
      yield fork(analyzeProductImportDataProcess, form, modalId)
    }
  }
}

export function* importProductDataProcess(
  form,
  modalId,
  lineItemsGridApi,
  gridHandlingData = {},
  importLineItems = null,
  retryImport = null
) {
  debugger
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.importProductData.request(form))

  const route = getRoute(form)
  debugger

  const apiParams = {
    action: 'import',
    guid,
    importLineItems,
    retryImport,
    route
  }
  const { response, error } = yield call(productImportAPI, apiParams)

  if (response) {
    if (modalId && response?.success) {
      yield put(removeModal(form, modalId))
    }

    yield put(actions.importProductData.success(response, form))
    // console.log('gridHandlingData', gridHandlingData, lineItemsGridApi)
    if (route === 'salesOrder' && response?.success) {
      yield fork(
        onAfterImportDataSuccess,
        form,
        gridHandlingData,
        lineItemsGridApi
      )
    }
    // debugger
  } else {
    yield put(actions.importProductData.failure(error, form))
    if (error?.status === 499) {
      debugger
      if (
        error?.message === 'Import Results Successful' &&
        form?.match(/salesOrder-/)
      ) {
        yield fork(onImportResultsSuccess, form, modalId, lineItemsGridApi)
      } else if (error?.data?.report && error?.data?.message) {
        debugger
        yield call(
          confirmationModal,
          error?.data?.message?.message,
          error?.data?.message?.modalTitle
        )
        const modalInteraction = yield take([CONFIRMED, CANCELED])
        debugger

        if (modalInteraction.type === CONFIRMED) {
          yield call(
            displayAnalysisExceptionsReportProcess,
            form,
            error.data.report
          )
          debugger
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            error?.data?.propertyToFillOnOk === 'ImportLineItems'
              ? true
              : importLineItems,
            error?.data?.propertyToFillOnOk === 'RetryImport'
              ? true
              : retryImport
          )
        } else {
          debugger
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            error?.data?.propertyToFillOnCancel === 'ImportLineItems'
              ? false
              : importLineItems,
            error?.data?.propertyToFillOnCancel === 'RetryImport'
              ? false
              : retryImport
          )
        }
      } else if (error?.data?.propertyToFillOnCancel === 'ImportLineItems') {
        debugger
        yield call(
          confirmationModal,
          error?.data?.message,
          error?.data?.modalTitle
        )
        const modalInteraction = yield take([CONFIRMED, CANCELED])
        if (modalInteraction.type === CONFIRMED) {
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            true
          )
        } else {
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            false
          )
        }
      } else if (error?.data?.propertyToFillOnCancel === 'RetryImport') {
        debugger
        yield call(
          confirmationModal,
          error?.data?.message,
          error?.data?.modalTitle
        )
        const modalInteraction = yield take([CONFIRMED, CANCELED])
        if (modalInteraction.type === CONFIRMED) {
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            importLineItems,
            true
          )
        } else {
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            importLineItems,
            false
          )
        }
      } else {
        console.log('error', error)
        debugger
        if (error?.data?.responses?.length === 1) {
          yield call(
            warningModal,
            error?.data?.message,
            error?.data?.modalTitle
          )
          yield take(REMOVE_MODAL)
          yield fork(
            importProductDataProcess,
            form,
            modalId,
            lineItemsGridApi,
            gridHandlingData,
            importLineItems,
            retryImport
          )
        }
        /* 
          something happens here with Test CSV Data - Sheet1 (1).csv 
          and a new template        
        */
      }
    }
    // debugger
  }
}

export function* onAfterImportDataSuccess(
  form,
  gridHandlingData,
  lineItemsGridApi
) {
  const { lineItems = [], payload } = gridHandlingData
  /* handle product notes modal(s) */
  yield fork(handleNewlyAddedProductNotes, form, lineItems, payload.record)

  /* listen and wait for completion of the product notes loop */
  yield take(NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED)

  /* trigger the box quantity prompts */
  if (lineItemsGridApi) {
    yield delay(100)
    yield fork(triggerBoxQuantityPrompts, form, lineItemsGridApi, true)
    yield take(NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED)

    setTimeout(() => {
      lineItemsGridApi.deselectAll()
      lineItemsGridApi.forEachNode(node => {
        if (node?.data?.lineNumber && node?.data?.lineNumber === 1) {
          node.setSelected(true)
        } else {
          node.setSelected(false)
        }
      })
    }, 0)
  }

  yield fork(
    showPaymentResponseMessage,
    'Line Item Import Successful',
    'success'
  )
}

export function* showPaymentResponseMessage(message, type, timer = 10000) {
  if (message && type) {
    const messageId = shortid.generate()
    yield put(
      show({
        message: {
          message,
          type,
          timer,
          id: messageId,
          component: ImportSuccessAlert(messageId)
        }
      })
    )
  }
}

export function* onImportResultsSuccess(form, modalId, lineItemsGridApi) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  let lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  yield fork(readSalesOrderProcess, { value: dataId, form })
  const { payload } = yield take(ON_PROPERTY_CHANGE.SUCCESS)

  const gridHandlingData = {
    payload,
    lineItems
  }

  yield fork(
    importProductDataProcess,
    form,
    modalId,
    lineItemsGridApi,
    gridHandlingData
  )
}

export function* importProductDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId, lineItemsGridApi }
    } = yield take(CONSTANTS.IMPORT_PRODUCT_DATA.TRY)

    if (form === formListener) {
      yield fork(importProductDataProcess, form, modalId, lineItemsGridApi)
    }
  }
}

export function* clearMappingsProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.clearMappings.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'propertychange',
    guid,
    route,
    properties: {
      clearMappings: true
    }
  })

  if (response) {
    yield put(actions.clearMappings.success(response, form))
  } else {
    yield put(actions.clearMappings.failure(error, form))
  }
}

export function* clearMappingsListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CLEAR_MAPPINGS.TRY)

    if (form === formListener) {
      yield fork(clearMappingsProcess, form)
    }
  }
}

export function* exitImportScreenProcess(form, modalId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.exitImportScreen.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'close',
    guid,
    route
  })

  if (response) {
    yield put(actions.exitImportScreen.success(response, form))
    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put(actions.exitImportScreen.failure(error, form))
  }
}

export function* exitImportScreenListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(CONSTANTS.EXIT_IMPORT_SCREEN.TRY)

    if (form === formListener) {
      yield fork(exitImportScreenProcess, form, modalId)
    }
  }
}

export function* handleNotifyGridDataChanged(form) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'values.productImport.rowData')
  lineItems = lineItems?.toJS ? lineItems.toJS() : []
  const importData = createImportPostData(lineItems)

  const properties = {
    importData: Buffer.from(importData).toString('base64')
  }

  yield fork(onPropertyChangeProcess, form, properties, true)
}

export function* onCellValueChangedListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.ON_CELL_VALUE_CHANGED)

    if (form === formListener) {
      yield fork(handleNotifyGridDataChanged, form)
    }
  }
}

export function* deleteTemplateProcess(form, templateId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield call(
    confirmationModal,
    'Are you sure you want to delete this template?',
    'Delete Template?'
  )

  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CANCELED) {
    return
  }

  yield put(actions.deleteTemplate.request(form))

  const route = getRoute(form)
  const { response, error } = yield call(productImportAPI, {
    action: 'propertychange',
    guid,
    route,
    properties: {
      deleteTemplate: templateId
    }
  })

  if (response) {
    yield put(
      actions.deleteTemplate.success(
        {
          ...response,
          templateId
        },
        form
      )
    )
  } else {
    yield put(actions.deleteTemplate.failure(error, form))
  }
}

export function* deleteTemplateListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { templateId }
    } = yield take(CONSTANTS.DELETE_TEMPLATE.TRY)

    if (form === formListener && templateId) {
      yield fork(deleteTemplateProcess, form, templateId)
    }
  }
}

export default function* productImportSagas(form) {
  yield fork(openProductImportEditorListener, form)
  yield fork(onPropertyChangeListener, form)
  yield fork(setFieldListener, form)
  yield fork(analyzeProductImportDataListener, form)
  yield fork(importProductDataListener, form)
  yield fork(clearMappingsListener, form)
  yield fork(exitImportScreenListener, form)
  yield fork(onCellValueChangedListener, form)
  yield fork(onNewTemplateNamedListener, form)
  yield fork(deleteTemplateListener, form)
}
