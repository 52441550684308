import React, { useEffect, useState } from 'react'
import { withContext } from 'ddiForm'
import memoize from 'memoize-one'
import {
  Button,
  FormControlLabel,
  Icon,
  Input,
  Paper,
  Switch,
  Typography,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  // DatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { isValidDate } from 'ddiForm/utils'
import { setField } from 'ddiForm/actions'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  getSidebarRowData,
  usePrevious,
  getChildIndex,
  getSelectedRowIndex
} from 'pages/SalesOrder/utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
// import { DDIIndexSearch } from 'ddiForm/wrapped'
import { DDIToggle } from 'ddiForm/wrapped'
import IndexSearch from 'components/Search/IndexSearch'
import * as actions from 'components/Search/IndexSearch/actions'

import IndexSearchMobile from 'mobile/components/IndexSearchMobile'
import DDIPurchaseOrderSearchMobile from 'mobile/ddiForm/wrapped/DDIPurchaseOrderSearchMobile'
import DDIPurchaseOrderSearch from 'ddiForm/wrapped/DDIPurchaseOrderSearch'
import LineItemSwitch from 'pages/SalesOrder/tabs/Detail/components/Pricing/components/LineItemSwitch'
import { NumberFormatter } from 'pages/SalesOrder/tabs/Detail/components/Pricing/components/NumberField'

import * as mobileSearchActions from 'mobile/components/IndexSearchMobile/actions'

import { getValue, getMeta, getIn, empty, emptyList, is } from 'utils'
import {
  triggerPropertyChangeProcess,
  triggerUpdateLineItem,
  storeUIFeatureState
} from 'pages/SalesOrder/actions'
import moment from 'moment'
import ShippingWarehousesGrid from './ShippingWarehousesGrid'
import TransfersGrid from './TransfersGrid'

const infoStyle = { color: '#444', fontSize: '0.85em' }
const formatDate = (date, format = 'MM/DD/YYYY') =>
  isValidDate(date) ? new Date(moment(date).format(format)) : null

const isDisabled = memoize((isEditing, canChange, dataId) => {
  if (!isEditing || !canChange || !dataId) {
    return true
  }

  return false
})

const mobileFieldIsDisabled = memoize((isEditing, isPostingGridUpdate) => {
  if (!isEditing || isPostingGridUpdate) {
    return true
  }

  return false
})

const handleSearchDescriptions = (field, description) => {
  if (
    [field] &&
    [field]?.wrappedComponent &&
    [field]?.wrappedComponent?.state &&
    [field]?.wrappedComponent?.state?.description !== description &&
    [field]?.wrappedComponent?.setDescription
  ) {
    ;[field].wrappedComponent.setDescription(
      description === null ? '' : description
    )
  }
}

/* not provided by API */
const vendorMetaData = {
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: false,
  allowSpecialProducts: false,
  fieldName: 'poVendorId',
  hasFilters: false,
  hideToggle: true,
  isSecure: false,
  minimumKeywordLength: 2,
  openText: 'Open Vendor Master',
  searchType: 'Vendor'
}

const detailsInputLabelStyle = { fontSize: 12, width: 100 }
const procurementModuleHeaderStyle = {
  color: '#517b9c',
  fontSize: 12,
  margin: '0 0 15px 0',
  textAlign: 'center',
  textTransform: 'uppercase'
}

const standardProcurementModuleFlexStyle = {
  alignItems: 'center',
  display: 'flex',
  marginBottom: 15
}

const inputWidth120Style = { width: 120 }

let shipAsItemIndexSearchField = null
let warehouseIndexSearchField = null
let vendorIndexSearchField = null

const DetailsTab = props => {
  const {
    form,
    childIndex,
    height,
    dispatch,
    taxable,
    repair,
    cancelScan,
    canCancelScan,
    updateDemand,
    commissionableType,
    commissionableTypeDescription,
    commissionableOptionsList,
    projectLine,
    shipAsProductId,
    useProjectLineNumbers,
    isCanadianCompanyOrHasHawaiianTax,
    canChange,
    isEditing,
    shipItemAsDisplay,
    dataId,
    rowId,
    isPostingGridUpdate,
    shipAsProductDescription,
    selectedLineItemMap,
    selectedRowIndex,
    expandedDetailsAccordion,
    procurementOptionsList,
    procurementType,
    procurementTypeDescription,
    promisedDate,
    isPosting,
    warehouseId,
    warehouseSearchMetaMap,
    warehouseDescription,
    purchaseOrderId,
    poVendorId,
    poVendorDescription,
    canChangeProcurementType,
    canChangePO,
    canChangeVendor,
    moreShippingWhses,
    canChangeMoreShippingWhses,
    workOrderQuantity,
    workOrderComments,
    canChangeWorkOrder,
    allowWorkOrder,
    workOrderId,
    allowTransfer,
    isMobile = false,
    salesOrderId
  } = props
  console.log(procurementTypeDescription)
  const selectedLineItem = selectedLineItemMap?.toJS
    ? selectedLineItemMap.toJS()
    : {}

  const commissionableOptions = commissionableOptionsList?.toJS
    ? commissionableOptionsList.toJS()
    : []
  const procurementOptions = procurementOptionsList?.toJS
    ? procurementOptionsList.toJS()
    : []

  const warehouseSearchMeta = warehouseSearchMetaMap?.toJS
    ? warehouseSearchMetaMap.toJS()
    : {}

  const fieldDisabled = isDisabled(isEditing, canChange, dataId)

  /* default settings */
  const [state, setState] = useState({
    promisedDate,
    expanded: expandedDetailsAccordion,
    taxable,
    repair,
    cancelScan,
    canCancelScan,
    updateDemand,
    commissionableType,
    commissionableTypeDescription,
    projectLine,
    shipAsProductId,
    procurementType,
    procurementTypeDescription,
    warehouseId,
    purchaseOrderId,
    poVendorId,
    moreShippingWhses,
    workOrderQuantity,
    workOrderComments
  })

  const setIndexSearchFieldDescriptions = () => {
    const fieldsToUpdate = [
      {
        field: shipAsItemIndexSearchField,
        description: shipAsProductDescription
      },
      { field: warehouseIndexSearchField, description: warehouseDescription },
      { field: vendorIndexSearchField, description: poVendorDescription }
    ]

    for (let i = 0; i < fieldsToUpdate.length; i++) {
      const input = fieldsToUpdate[i]
      handleSearchDescriptions(input.field, input.description)
    }
  }

  const updateDetailsTabState = (isLineItemChange = false) => {
    setState({
      ...state,
      expanded: isLineItemChange && isMobile ? 'overrides' : state.expanded,
      promisedDate: promisedDate || null,
      taxable,
      repair,
      cancelScan,
      canCancelScan,
      updateDemand,
      commissionableType,
      commissionableTypeDescription,
      projectLine,
      shipAsProductId,
      procurementType,
      procurementTypeDescription,
      warehouseId,
      purchaseOrderId,
      poVendorId,
      moreShippingWhses,
      workOrderQuantity,
      workOrderComments
    })

    /* update the store with the correct expanded state */
    // if (isLineItemChange && !isMobile) {
    //   dispatch(
    //     storeUIFeatureState(form, {
    //       feature: 'expandedDetailsAccordion',
    //       featureState: 'overrides'
    //     })
    //   )
    // }

    if (procurementType === 'S' || procurementType === 'D') {
      dispatch(
        setField(form, 'productDetails.purchaseOrderId', purchaseOrderId)
      )
    }

    setIndexSearchFieldDescriptions()
  }

  const wasPostingGridUpdate = usePrevious(isPostingGridUpdate)
  const wasPosting = usePrevious(isPosting)
  const wasEditing = usePrevious(isEditing)
  const prevSalesOrderId = usePrevious(salesOrderId)

  const prevSelectedRowIndex = usePrevious(selectedRowIndex)
  const prevChildIndex = usePrevious(childIndex)
  useEffect(() => {
    if (
      (wasPostingGridUpdate !== isPostingGridUpdate && !isPostingGridUpdate) ||
      (wasPosting !== isPosting && !isPosting) ||
      wasEditing !== isEditing
    ) {
      /* update state on any API data being returned here */
      updateDetailsTabState(false)
    }
  }, [
    wasPostingGridUpdate,
    isPostingGridUpdate,
    wasPosting,
    isPosting,
    isEditing,
    wasEditing
  ])

  useEffect(() => {
    if (
      ((prevSelectedRowIndex || prevSelectedRowIndex === 0) &&
        selectedRowIndex !== prevSelectedRowIndex) ||
      (prevChildIndex !== null && childIndex !== prevChildIndex)
    ) {
      /* 
        update state AND ensure the details tab
        is in focus on changing line items
      */
      updateDetailsTabState(true)
    }
  }, [selectedRowIndex, prevSelectedRowIndex])

  useEffect(() => {
    if (expandedDetailsAccordion !== state.expanded && !isMobile) {
      setState({
        ...state,
        expanded: expandedDetailsAccordion
      })
    }
  }, [expandedDetailsAccordion])

  useEffect(() => {
    if (prevSalesOrderId && !salesOrderId) {
      /* reset on when a new sales order is created */
      updateDetailsTabState(false)
    }
  }, [salesOrderId, prevSalesOrderId])

  const onDatepickerChange = date => {
    setState({ ...state, promisedDate: date })

    if (isValidDate(date)) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: formatDate(date),
          colDef: {
            field: 'promisedDate'
          }
        })
      )
    }
  }

  const onClearDatepicker = date => {
    if (!date) {
      setState({ ...state, promisedDate: date })
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: date,
          colDef: {
            field: 'promisedDate'
          }
        })
      )
    }
  }

  const onDatepickerBlur = event => {
    const storedDate = selectedLineItem.promisedDate || null

    /* user has cleared out a previously valid date */
    if (!state.promisedDate && state.promisedDate !== storedDate) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: formatDate(state.promisedDate),
          colDef: {
            field: 'promisedDate'
          }
        })
      )
    }
  }

  const applyPromiseDateAll = event => {
    dispatch(
      triggerPropertyChangeProcess(form, {
        propertyName: 'promisedDate',
        value: formatDate(state.promisedDate)
      })
    )
  }

  const handleExpansionPanelChange = panel => (event, isExpanded) => {
    // let expanded = isExpanded ? panel : false
    const expanded = isExpanded
      ? panel
      : panel === 'overrides'
      ? 'procurement'
      : 'overrides'
    setState({ ...state, expanded })

    if (!isMobile) {
      dispatch(
        storeUIFeatureState(form, {
          feature: 'expandedDetailsAccordion',
          featureState: expanded
        })
      )
    }
  }

  const handleToggleChange = name => event => {
    setState({ ...state, [name]: event.target.checked })

    dispatch(
      triggerUpdateLineItem(form, {
        propertyName: 'lineItems',
        data: selectedLineItem,
        value: event.target.checked,
        colDef: {
          field: name
        }
      })
    )
  }

  const handleSelectFieldChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    })

    dispatch(
      triggerUpdateLineItem(form, {
        propertyName: 'lineItems',
        data: selectedLineItem,
        value: event.target.value,
        colDef: {
          field: name
        }
      })
    )
  }

  const onIndexSearchChange = (event, field) => {
    setState({
      ...state,
      [field]: event.target.value
    })
  }

  const onPurchaseOrderSetField = (val, clear, leader, results) => {
    setState({
      ...state,
      purchaseOrderId: val
    })

    if (val !== purchaseOrderId) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: val,
          colDef: {
            field: 'purchaseOrderId'
          }
        })
      )
    }
  }

  const setIndexSearchField = (val, _, __, result, field) => {
    setState({
      ...state,
      [field]: val
    })

    dispatch(
      triggerUpdateLineItem(form, {
        propertyName: 'lineItems',
        data: selectedLineItem,
        value: val,
        colDef: {
          field
        }
      })
    )
  }

  const exactMatch = args =>
    dispatch(actions.exactMatchSearch(null, { ...args }))

  const partialMatch = args =>
    dispatch(actions.partialMatchSearch(null, { ...args }))

  // const searchComponent = isMobile ? IndexSearchMobile : IndexSearch
  const productSearchMeta = {
    allowInvalidValues: true,
    allowInstantSearch: true,
    allowNavigation: true,
    allowSearchAll: false,
    hasFilters: true,
    hideToggle: true,
    minimumKeywordLength: 1,
    openText: null,
    searchType: 'Product',
    form
  }

  const handleTextFieldChange = name => event => {
    setState({
      ...state,
      [name]: event.target.value
    })
  }

  const onTextFieldBlur = (field, event) => {
    if (state[field] !== props[field]) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: state[field],
          colDef: {
            field
          }
        })
      )
    }
  }

  const onNumberFormatterChange = (field, values) => {
    const v = values.floatValue

    setState({
      ...state,
      [field]: v
    })
  }

  const onNumberFormatterBlur = (e, field) => {
    // console.log('onNumberFormatterBlur', field, state[field], props[field])
    if (state[field] !== props[field]) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: state[field],
          colDef: {
            field
          }
        })
      )
    }
  }
  console.log(procurementType, state.procurementType)

  return (
    <div style={{ height, overflow: isMobile ? 'auto' : 'hidden' }}>
      <ExpansionPanel
        expanded={state.expanded === 'procurement'}
        onChange={handleExpansionPanelChange('procurement')}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={infoStyle}>Procurement</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <div style={{ marginBottom: 10 }}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  padding: '10px 0 20px 5'
                }}
              >
                <span style={detailsInputLabelStyle}>Shipping W/H:</span>
                <div style={{ width: 115, marginRight: 15 }}>
                  {isMobile ? (
                    <IndexSearchMobile
                      // _ddiForm={{ form }}
                      form={form}
                      meta={warehouseSearchMeta}
                      value={warehouseId}
                      onChange={e => onIndexSearchChange(e, 'warehouseId')}
                      setField={(val, _, __, result) =>
                        setIndexSearchField(val, _, __, result, 'warehouseId')
                      }
                      partialMatchSearch={partialMatch}
                      exactMatchSearch={exactMatch}
                      disabled={
                        !selectedLineItem.canChangeWarehouse ||
                        fieldDisabled ||
                        moreShippingWhses ||
                        childIndex != null
                      }
                      displayDescription
                      description={warehouseDescription}
                      ref={el => (warehouseIndexSearchField = el)}
                    />
                  ) : (
                    <IndexSearch
                      // _ddiForm={{ form }}
                      form={form}
                      meta={warehouseSearchMeta}
                      value={warehouseId}
                      onChange={e => onIndexSearchChange(e, 'warehouseId')}
                      setField={(val, _, __, result) =>
                        setIndexSearchField(val, _, __, result, 'warehouseId')
                      }
                      partialMatchSearch={partialMatch}
                      exactMatchSearch={exactMatch}
                      disabled={
                        !selectedLineItem.canChangeWarehouse ||
                        fieldDisabled ||
                        moreShippingWhses ||
                        childIndex != null
                      }
                      displayDescription
                      description={warehouseDescription}
                      ref={el => (warehouseIndexSearchField = el)}
                    />
                  )}
                </div>
                <LineItemSwitch
                  field="moreShippingWhses"
                  label="More"
                  data={selectedLineItem}
                  form={form}
                  dispatch={dispatch}
                  isPostingGridUpdate={isPostingGridUpdate}
                  value={moreShippingWhses}
                  disabled={!canChangeMoreShippingWhses}
                  isDetailTabSwitch
                  isMobile
                />
              </div>
              <div style={{ paddingLeft: 5 }}>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <span style={detailsInputLabelStyle}>Promise Date:</span>
                  <div style={{ marginRight: 15, width: 115 }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        disabled={fieldDisabled}
                        clearable
                        id="promisedDate"
                        format="MM/DD/YYYY"
                        onChange={onDatepickerChange}
                        onAccept={onClearDatepicker}
                        value={promisedDate}
                        fullWidth
                        onBlur={onDatepickerBlur}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Button
                    variant="contained"
                    sizeSmall
                    onClick={applyPromiseDateAll}
                    disabled={fieldDisabled}
                  >
                    All
                  </Button>
                </div>
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  padding: '20px 0 10px 5px'
                }}
              >
                <span style={detailsInputLabelStyle}>P/O Type:</span>
                {form === 'invoiceInquiry' ? (
                  <span style={{ display: 'block', margin: '10px 0' }}>
                    {procurementTypeDescription}
                  </span>
                ) : (
                  <div style={{ width: 200 }}>
                    <Select
                      fullWidth
                      value={procurementType}
                      onChange={handleSelectFieldChange('procurementType')}
                      inputProps={{
                        name: 'procurementType',
                        id: 'procurement-details'
                      }}
                      disabled={!canChangeProcurementType}
                    >
                      {procurementOptions.map((item, idx) => (
                        <MenuItem
                          value={item.dataId}
                          key={`procurement-${item.dataId}`}
                        >
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            </div>
            {procurementType === 'S' || procurementType === 'D' ? (
              <Paper className="mobile-stretch-sm" style={{ padding: 10 }}>
                <h4 style={procurementModuleHeaderStyle}>Purchase</h4>
                <div style={standardProcurementModuleFlexStyle}>
                  <span style={detailsInputLabelStyle}>P/O Number:</span>
                  <div style={inputWidth120Style}>
                    {isMobile ? (
                      <DDIPurchaseOrderSearchMobile
                        propertyName="productDetails.purchaseOrderId"
                        dataId={purchaseOrderId}
                        onSetField={onPurchaseOrderSetField}
                        disabled={!canChangePO}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                      />
                    ) : (
                      <DDIPurchaseOrderSearch
                        propertyName="productDetails.purchaseOrderId"
                        dataId={purchaseOrderId}
                        onSetField={onPurchaseOrderSetField}
                        disabled={!canChangePO}
                      />
                    )}
                  </div>
                </div>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <span style={detailsInputLabelStyle}>Vendor:</span>
                  <div style={inputWidth120Style}>
                    {isMobile ? (
                      <IndexSearchMobile
                        // _ddiForm={{ form }}
                        form={form}
                        meta={vendorMetaData}
                        value={poVendorId}
                        onChange={e => onIndexSearchChange(e, 'poVendorId')}
                        setField={(val, _, __, result) =>
                          setIndexSearchField(val, _, __, result, 'poVendorId')
                        }
                        partialMatchSearch={partialMatch}
                        exactMatchSearch={exactMatch}
                        disabled={!canChangeVendor}
                        displayDescription
                        description={poVendorDescription}
                        ref={el => (vendorIndexSearchField = el)}
                        disableSearchAll
                      />
                    ) : (
                      <IndexSearch
                        // _ddiForm={{ form }}
                        form={form}
                        meta={vendorMetaData}
                        value={poVendorId}
                        onChange={e => onIndexSearchChange(e, 'poVendorId')}
                        setField={(val, _, __, result) =>
                          setIndexSearchField(val, _, __, result, 'poVendorId')
                        }
                        partialMatchSearch={partialMatch}
                        exactMatchSearch={exactMatch}
                        disabled={!canChangeVendor}
                        displayDescription
                        description={poVendorDescription}
                        ref={el => (vendorIndexSearchField = el)}
                      />
                    )}
                  </div>
                </div>
              </Paper>
            ) : null}
            {procurementType === 'W' && allowWorkOrder ? (
              <Paper className="mobile-stretch-sm" style={{ padding: 10 }}>
                <h4 style={procurementModuleHeaderStyle}>Work Order:</h4>
                <div style={standardProcurementModuleFlexStyle}>
                  <span style={detailsInputLabelStyle}>Work Order ID:</span>
                  <div>{workOrderId}</div>
                </div>
                <div style={standardProcurementModuleFlexStyle}>
                  <span style={detailsInputLabelStyle}>Quantity:</span>
                  <div style={inputWidth120Style}>
                    <Input
                      value={workOrderQuantity}
                      fullWidth
                      inputComponent={NumberFormatter}
                      onChange={values =>
                        onNumberFormatterChange('workOrderQuantity', values)
                      }
                      onBlur={e =>
                        onNumberFormatterBlur(e, 'workOrderQuantity')
                      }
                      inputProps={{
                        allowNegative: true,
                        noDecimalLimit: true,
                        decimalSeparator: '.',
                        fontSize: 14,
                        maxLength: 999,
                        paddingLeft: 5,
                        textAlign: 'left',
                        thousandSeparator: '',
                        suffix: ''
                      }}
                      disabled={!canChangeWorkOrder}
                      endAdornment={
                        !workOrderQuantity ? (
                          <Icon
                            title="This field is required"
                            fontSize="small"
                            style={{ color: '#d9534f' }}
                          >
                            error
                          </Icon>
                        ) : null
                      }
                    />
                  </div>
                </div>
                <div
                  style={{
                    ...standardProcurementModuleFlexStyle,
                    alignItems: 'baseline'
                  }}
                >
                  <span style={detailsInputLabelStyle}>Comments:</span>
                  <div style={{ minWidth: 120, flexGrow: 1 }}>
                    <TextField
                      id="work-order-comments"
                      value={workOrderComments}
                      onChange={handleTextFieldChange('workOrderComments')}
                      onBlur={e => onTextFieldBlur('workOrderComments', e)}
                      disabled={!canChangeWorkOrder}
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  </div>
                </div>
              </Paper>
            ) : null}

            {procurementType === 'N' && moreShippingWhses ? (
              <div style={{ width: 400 }}>
                <ShippingWarehousesGrid
                  dispatch={dispatch}
                  form={form}
                  isMobile={isMobile}
                  propertyName="productDetails.shippingWhses"
                  selectedRowIndex={selectedRowIndex}
                />
              </div>
            ) : null}

            {procurementType === 'T' && allowTransfer ? (
              <TransfersGrid form={form} isMobile={isMobile} />
            ) : null}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={state.expanded === 'overrides'}
        onChange={handleExpansionPanelChange('overrides')}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={infoStyle}>Overrides</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            {isMobile ? (
              <div style={{ display: 'flex' }}>
                <AssociatedFieldsWrapper
                  style={{ margin: 5, maxWidth: 'calc(50% - 10px)' }}
                >
                  <DDIToggle
                    propertyName="productDetails.redAlert"
                    label="Red Alert"
                    preventAutoDisable
                    disabled={fieldDisabled}
                  />
                </AssociatedFieldsWrapper>

                <AssociatedFieldsWrapper
                  style={{ margin: 5, maxWidth: 'calc(50% - 10px)' }}
                >
                  <DDIToggle
                    propertyName="productDetails.warehousePicked"
                    label="Warehouse Pick"
                    preventAutoDisable
                    disabled={fieldDisabled}
                  />
                </AssociatedFieldsWrapper>
              </div>
            ) : null}
            <div style={{ display: 'flex' }}>
              {!isCanadianCompanyOrHasHawaiianTax ? (
                <AssociatedFieldsWrapper style={{ margin: 5 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={taxable}
                        onChange={handleToggleChange('taxable')}
                        value="taxable"
                        disabled={fieldDisabled}
                      />
                    }
                    label="Taxable"
                  />
                </AssociatedFieldsWrapper>
              ) : null}
              <AssociatedFieldsWrapper style={{ margin: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={repair}
                      onChange={handleToggleChange('repair')}
                      value="repair"
                      disabled={fieldDisabled}
                    />
                  }
                  label="Repair Item"
                />
              </AssociatedFieldsWrapper>
            </div>
            <div style={{ display: 'flex' }}>
              <AssociatedFieldsWrapper style={{ margin: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={updateDemand}
                      onChange={handleToggleChange('updateDemand')}
                      value="updateDemand"
                      disabled={fieldDisabled}
                    />
                  }
                  label="Update Demand"
                />
              </AssociatedFieldsWrapper>
              <AssociatedFieldsWrapper style={{ margin: 5 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={cancelScan}
                      onChange={handleToggleChange('cancelScan')}
                      value="cancelScan"
                      disabled={!canCancelScan}
                    />
                  }
                  label="Cancel Scan"
                />
              </AssociatedFieldsWrapper>
            </div>
            <div
              className="product-details-tab-override-fields"
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ width: 150, margin: 10 }}>
                <p style={{ fontSize: 11, margin: '0 0 3px 0' }}>
                  {shipItemAsDisplay}
                </p>
                {isMobile ? (
                  <IndexSearchMobile
                    // _ddiForm={{ form }}
                    form={form}
                    meta={{
                      ...productSearchMeta,
                      allowNavigation: false
                    }}
                    value={shipAsProductId}
                    onChange={e => onIndexSearchChange(e, 'shipAsProductId')}
                    setField={(val, _, __, result) =>
                      setIndexSearchField(val, _, __, result, 'shipAsProductId')
                    }
                    partialMatchSearch={partialMatch}
                    exactMatchSearch={exactMatch}
                    disabled={fieldDisabled}
                    displayDescription
                    description={shipAsProductDescription}
                    ref={el => (shipAsItemIndexSearchField = el)}
                  />
                ) : (
                  <IndexSearch
                    // _ddiForm={{ form }}
                    form={form}
                    meta={productSearchMeta}
                    value={shipAsProductId}
                    onChange={e => onIndexSearchChange(e, 'shipAsProductId')}
                    setField={(val, _, __, result) =>
                      setIndexSearchField(val, _, __, result, 'shipAsProductId')
                    }
                    partialMatchSearch={partialMatch}
                    exactMatchSearch={exactMatch}
                    disabled={fieldDisabled}
                    displayDescription
                    description={shipAsProductDescription}
                    ref={el => (shipAsItemIndexSearchField = el)}
                  />
                )}
              </div>
              <div style={{ width: 100, margin: 10 }}>
                <p style={{ fontSize: 11, margin: '0 0 3px 0' }}>
                  Commissionable
                </p>
                {form === 'invoiceInquiry' ? (
                  <span style={{ display: 'block', margin: '10px 0' }}>
                    {commissionableTypeDescription}
                  </span>
                ) : (
                  <Select
                    fullWidth
                    value={commissionableType}
                    onChange={handleSelectFieldChange('commissionableType')}
                    inputProps={{
                      name: 'commissionableType',
                      id: 'commissionable-details'
                    }}
                    disabled={fieldDisabled}
                  >
                    {commissionableOptions.map((item, idx) => (
                      <MenuItem
                        value={item.dataId}
                        key={`commissionable-${item.dataId}`}
                      >
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </div>
              {useProjectLineNumbers ? (
                <div style={{ width: 150, margin: 10 }}>
                  <p style={{ fontSize: 11, margin: '0 0 3px 0' }}>
                    Project Line
                  </p>
                  <TextField
                    id="project-line"
                    value={projectLine}
                    onChange={handleTextFieldChange('projectLine')}
                    onBlur={e => onTextFieldBlur('projectLine', e)}
                    inputProps={{
                      style: {
                        height: 26,
                        paddingBottom: 0,
                        paddingTop: 0
                      }
                    }}
                    disabled={fieldDisabled}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

/* fixed 9/25/2020 SVE */
export default withContext({
  selectedRowIndex: getSelectedRowIndex,
  childIndex: getChildIndex,
  selectedLineItemMap: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')
    const lineItems = getIn(form, 'fields.lineItems.rowData') || emptyList

    if (is.number(selectedRowIndex) && lineItems && lineItems?.get) {
      return lineItems.get(selectedRowIndex)
    }

    return empty
  },
  commissionableOptionsList: form =>
    getMeta(form, 'commissionableOptions', emptyList),
  isCanadianCompanyOrHasHawaiianTax: form =>
    getMeta(form, 'isCanadianCompanyOrHasHawaiianTax', false),
  commissionableType: form => getSidebarRowData(form, 'commissionableType', ''),
  commissionableTypeDescription: form =>
    getSidebarRowData(form, 'commissionableTypeDescription', ''),
  taxable: form => getSidebarRowData(form, 'taxable', false),
  repair: form => getSidebarRowData(form, 'repair', false),
  updateDemand: form => getSidebarRowData(form, 'updateDemand', false),
  canCancelScan: form => getSidebarRowData(form, 'canCancelScan', false),
  cancelScan: form => getSidebarRowData(form, 'cancelScan', false),
  projectLine: form => getSidebarRowData(form, 'projectLine', ''),
  promisedDate: form => getSidebarRowData(form, 'promisedDate', null),
  shipAsProductId: form => getSidebarRowData(form, 'shipAsProductId', ''),
  shipAsProductDescription: form =>
    getSidebarRowData(form, 'shipAsProductDescription', ''),
  useProjectLineNumbers: form => getMeta(form, 'useProjectLineNumbers', false),
  canChange: form => getSidebarRowData(form, 'canChange', false),
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  shipItemAsDisplay: form =>
    getMeta(form, 'shipItemAsDisplay', 'Print As Item'),
  dataId: form => getSidebarRowData(form, 'dataId', ''),
  rowId: form => getSidebarRowData(form, 'rowId', ''),
  isPostingGridUpdate: form => form.get('isPostingGridUpdate') || false,
  expandedDetailsAccordion: form =>
    getIn(form, 'ui.expandedDetailsAccordion') || 'overrides',
  procurementOptionsList: form =>
    getMeta(form, 'lineItemProcurementOptions', emptyList),
  procurementType: form => getSidebarRowData(form, 'procurementType', ''),
  procurementTypeDescription: form =>
    getSidebarRowData(form, 'procurementTypeDescription', ''),
  warehouseId: form => getSidebarRowData(form, 'warehouseId', ''),
  warehouseDescription: form =>
    getSidebarRowData(form, 'warehouseDescription', ''),
  warehouseSearchMetaMap: form => getMeta(form, 'Whse', empty),
  purchaseOrderId: form => getSidebarRowData(form, 'purchaseOrderId', ''),
  poVendorId: form => getSidebarRowData(form, 'poVendorId', ''),
  poVendorDescription: form =>
    getSidebarRowData(form, 'poVendorDescription', ''),
  canChangeProcurementType: form =>
    getSidebarRowData(form, 'canChangeProcurementType', false),
  canChangePO: form => getSidebarRowData(form, 'canChangePO', false),
  canChangeVendor: form => getSidebarRowData(form, 'canChangeVendor', false),
  moreShippingWhses: form =>
    getSidebarRowData(form, 'moreShippingWhses', false),
  canChangeMoreShippingWhses: form =>
    getSidebarRowData(form, 'canChangeMoreShippingWhses', false),
  workOrderQuantity: form => getSidebarRowData(form, 'workOrderQuantity', null),
  workOrderComments: form => getSidebarRowData(form, 'workOrderComments', ''),
  canChangeWorkOrder: form =>
    getSidebarRowData(form, 'canChangeWorkOrder', false),
  allowWorkOrder: form => getSidebarRowData(form, 'allowWorkOrder', false),
  workOrderId: form => getSidebarRowData(form, 'workOrderId', ''),
  allowTransfer: form => getSidebarRowData(form, 'allowTransfer', false),
  salesOrderId: form => getIn(form, 'fields.dataId.value') || ''
})(DetailsTab)
