import {
  take,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'
import { startCase } from 'lodash'

import { api } from 'services'
import { warningModal, confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED, HIDE_MODAL } from 'modals/constants'

import { formatDateFields, is } from 'utils'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH,
  FOUND_INVALID_VALUES
} from './constants'

import getColumnDefs from './getColumnDefs'
import GridCell from './GridCell'

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* exactMatchProcess({
  form,
  warehouseId,
  transactionType,
  transactionId,
  productId,
  returnTransactionId,
  searchType = '\u0000',
  sourceBinId,
  keyword,
  suppressCreateNewLotInSearch,
  transactionLotQuantity,
  transactionUomId,
  availableOnly = true,
  expiredOnly,
  includeSystemBins,
  returnCustomerNumber,
  allowInvalidValues,
  propertyName,
  thunk,
  ...rest
}) {
  const params = {
    warehouseId,
    transactionType,
    transactionId,
    productId,
    returnTransactionId,
    searchType,
    sourceBinId,
    keyword,
    suppressCreateNewLotInSearch,
    transactionLotQuantity,
    transactionUomId,
    availableOnly,
    expiredOnly,
    includeSystemBins,
    returnCustomerNumber,
    allowInvalidValues
  }
  // debugger

  const { response, error } = yield call(api.lotExactMatchSearch, params)

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  form,
  thunk,
  onCellSelected,
  propretyName,
  warehouseId,
  transactionType,
  transactionId,
  productId,
  returnTransactionId,
  searchType = '\u0000',
  sourceBinId,
  keyword,
  suppressCreateNewLotInSearch,
  transactionLotQuantity,
  transactionUomId,
  availableOnly = true,
  expiredOnly,
  includeSystemBins,
  returnCustomerNumber,
  allowInvalidValues,
  propertyName,
  ...rest
}) {
  const params = {
    warehouseId,
    transactionType,
    transactionId,
    productId,
    returnTransactionId,
    searchType,
    sourceBinId,
    keyword,
    suppressCreateNewLotInSearch,
    transactionLotQuantity,
    transactionUomId,
    availableOnly,
    expiredOnly,
    includeSystemBins,
    returnCustomerNumber,
    allowInvalidValues,
    propertyName
  }
  // debugger

  const { response, error } = yield call(api.lotPartialMatchSearch, params)

  if (response) {
    const lotNumberColumn = {
      cellRendererFramework: GridCell,
      cellRendererParams: { onCellSelected },
      field: 'name',
      headerName: 'Lot No'
    }

    const visibilityParams = Object.keys(response).reduce((acc, next) => {
      if (is.bool(response?.[next]) && next !== 'matchFound') {
        acc[next] = response[next]
      }
      return acc
    }, {})
    // debugger

    const columnDefs = getColumnDefs(
      lotNumberColumn,
      searchType,
      visibilityParams
    )

    const rowData = response.results || []
    const grid = {
      meta: {},
      rowData,
      columnDefs
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName,
        warehouseIdAndDescription:
          response?.warehouseIdAndDescription || warehouseId
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)

    const {
      payload: {
        propertyName,
        onCellSelected,
        warehouseId,
        transactionType,
        transactionId,
        productId,
        returnTransactionId,
        searchType,
        sourceBinId,
        keyword,
        suppressCreateNewLotInSearch,
        transactionLotQuantity,
        transactionUomId,
        availableOnly,
        expiredOnly,
        includeSystemBins,
        returnCustomerNumber,
        allowInvalidValues
      },
      meta: { form, thunk }
    } = action
    // debugger

    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)
    task = yield call(partialMatchProcess, {
      form,
      thunk,
      propertyName,
      warehouseId,
      transactionType,
      transactionId,
      productId,
      returnTransactionId,
      searchType,
      sourceBinId,
      keyword,
      suppressCreateNewLotInSearch,
      transactionLotQuantity,
      transactionUomId,
      availableOnly,
      expiredOnly,
      includeSystemBins,
      returnCustomerNumber,
      allowInvalidValues,
      onCellSelected
    })
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  ...rest
}) {
  const { response, error } = yield call(api[apiName], {
    propertyName,
    ...rest
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: { ...response, propertyName },
      type: GET_TOOLTIP.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: { ...error, propertyName },
      error: true,
      type: GET_TOOLTIP.FAILURE
    })
  }
}

export function* foundInvalidValuesListener() {
  while (true) {
    const action = yield take(FOUND_INVALID_VALUES)
    const {
      payload: {
        value,
        propertyName,
        message = 'Invalid Lot Number - Create?',
        modalTitle = 'Not Found'
      }
    } = action
    const { meta } = action
    yield call(
      confirmationModal,
      message,
      modalTitle,
      {},
      { title: 'YES' },
      { title: 'NO' }
    )
    const confirmed = yield take([CONFIRMED, CANCELED])
    if (confirmed.type === CONFIRMED) {
      yield put({
        type: `${FOUND_INVALID_VALUES}_SUCCESS`,
        payload: { value },
        meta
      })
    } else {
      yield put({
        type: `${FOUND_INVALID_VALUES}_REJECTED`,
        payload: { propertyName, value },
        meta,
        error: true
      })
    }
  }
}

export default function* lotSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(getTooltipListener)
  yield fork(foundInvalidValuesListener)
}
