/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, react/no-find-dom-node: 0, react/no-did-mount-set-state: 0, consistent-return: 0 */
import React, { Component } from 'react'
// import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'
// import { EventEmitter } from 'fbemitter'
// import { eventChannel } from 'redux-saga'
import { getIn, toNumeral } from 'utils'
// import { delay, throttle, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import {
  InputAdornment,
  Icon,
  IconButton,
  Input,
  TextField
} from '@material-ui/core'
import {
  getErrorIcon,
  // isDisabled,
  // notifyExitTextCellFn
  // updateGridCellData
} from 'components/EditableGrid/utils'
import { debounce, once } from 'lodash'

// import { validateGridData } from '../actions'

const nums = [37, 39]

const keyMap = {
  ALT_S: 'alt'
}

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    allowLeadingZeros = true,
    handlers,
    saveCart,
    ...other
  } = props

  /*
    had to change this component to receive
    decimal props like this
  */
  const decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  const Wrapper = saveCart ? HotKeys : React.Fragment

  return (
    <Wrapper keyMap={keyMap} handlers={handlers}>
      <NumberFormat
        {...other}
        {...decimalFormatting}
        // disabled={disabled}
        id={id}
        getInputRef={inputRef}
        forwardRef
        value={value}
        onValueChange={onChange}
        onBlur={onBlur}
        prefix={prefix}
        suffix={suffix}
        style={{
          color: value < 0 ? '#d9534f' : disabled ? '#999' : '#000',
          fontSize: fontSize || '1em',
          height: '100%',
          paddingLeft: 0,
          paddingRight,
          // paddingLeft: textAlign === 'left' ? 10 : 0,
          // paddingRight: textAlign === 'right' ? 0 : 0,
          textAlign,
          width: '100%'
        }}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        maxLength={maxLength}
      />
    </Wrapper>
  )
}

export class AmountCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired
  }

  _isMounted = false

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    // console.log(props)
    let {
      value,
      colDef: {
        cellEditorParams: { formatter }
      }
    } = this.props
    if (props.charPress) {
      value = formatter === 'number' ? Number(props.charPress) : props.charPress
    }
    const state = {
      isEditing: false,
      focusSet: false,
      lastCellTabbed: false,
      rowIndex: props.rowIndex,
      textFieldVal: value || value === 0 ? value : ''
    }
    if (formatter === 'number' && value === 0) {
      state.textFieldVal = 0
    }

    this.state = state
    // this.emitter = new EventEmitter()
    // this.emitter.addListener('Tab', this.test)
  }

  componentDidMount() {
    this._isMounted = true

    // findDOMNode(this.textField).addEventListener('keydown', this.onKeyDown)
  }

  // componentWillUnmount() {
  //   this._isMounted = false
  //   findDOMNode(this.textField).removeEventListener('keydown', this.onKeyDown)
  // }

  afterGuiAttached() {
    const input = this.textField // props.eGridCell
    if (input) {
      input.addEventListener('keydown', e => {
        this.onKeyDown(e)
      })
    }
    setTimeout(() => {
      if (input) {
        input.focus()
        if (!this.props.charPress) {
          input.select()
        }
      }
    }, 0)
  }

  componentDidUpdate() {
    const input = this.textField
    if (!this.edited && input) {
      input.focus()
      input.select()
    }
  }

  componentWillUnmount() {
    //
    const input = this.textField // props.eGridCell
    this._isMounted = false
    if (input) {
      input.removeEventListener('keydown', this.onKeyDown)
    }
  }

  edited = false

  setEdited = once(() => (this.edited = true))

  onChange = event => {
    if (this._isMounted) {
      this.setState({
        focusSet: false,
        isEditing: true,
        lastCellTabbed: false,
        textFieldVal: event.target.value || ''
      })
    }
  }

  onBlur = async event => {
    event.preventDefault()
    event.stopPropagation()

    const value = event?.target?.value
    const { api } = this.props
    const { isEditing } = this.state

    // if (
    //   (value || value === 0) &&
    //   value !== this.props.value &&
    //   isEditing
    // ) {
    //   this.setState({ isEditing: false })
    //   api.stopEditing()
    //   try {
    //     // debugger
    //     const newValue = await this.props.dispatch({
    //       type: 'TRY_CELL_CHANGED_REQUEST',
    //       meta: { form: this.props.form, thunk: true, reducer: 'Grid' },
    //       payload: {
    //         value,
    //         propertyName: this.props.propertyName,
    //         field: this.props.column.colDef.field,
    //         rowIndex: this.props.node.childIndex,
    //         data: this.props.data,
    //         node: this.props.node,
    //         gridApi: this.props.api
    //       }
    //     })

    //     if (newValue !== null) {
    //       this.props.node.setDataValue(this.props.column.colId, newValue)
    //     }
    //   } catch (e) {
    //     return true
    //   }
    // }
    this.setState({ isEditing: false })
    api.stopEditing()
  }

  blurField = e => {
    if (this.textField && this?.textField?.blur) {
      this.textField.blur()
      // debugger
    }
  }

  onKeyDown = event => {
    const { api, dispatch, form, onCellKeyDown, data } = this.props
    const { isEditing } = this.state
    if (onCellKeyDown) {
      onCellKeyDown.call(this, event)
    } else {
      const { keyCode } = event
      if (this._isMounted) {
        if (keyCode === 13) {
          event.preventDefault()
          event.stopPropagation()
        } else if (event.code === 'Tab') {
          event.preventDefault()
          event.stopPropagation()

          if (isEditing) {
            dispatch({
              type: 'RECALCULATE_PRICES_GRID_TAB_ATTEMPT',
              payload: {
                ...this.props,
                shiftKey: event.shiftKey
              },
              meta: {
                form
              }
            })
          } else if (!isEditing && data.dataId) {
            api[event.shiftKey ? 'tabToPreviousCell' : 'tabToNextCell']()
          }

          api.stopEditing()
        } else {
          this.setEdited()
        }
      }
    }
  }

  isCancelAfterEnd = async p => {
    if (this.state.textFieldVal != null && this.state.isEditing) {
      try {
        const newValue = await this.props.dispatch({
          type: 'TRY_CELL_CHANGED_REQUEST',
          meta: { form: this.props.form, thunk: true, reducer: 'Grid' },
          payload: {
            value: this.state.textFieldVal,
            propertyName: this.props.propertyName,
            field: this.props.column.colDef.field,
            rowIndex: this.props.node.childIndex,
            data: this.props.data,
            node: this.props.node,
            gridApi: this.props.api
          }
        })

        if (newValue !== null) {
          this.props.node.setDataValue(this.props.column.colId, newValue)
        }
        return false
      } catch (e) {
        return true
      }
    }
  }

  onValueChange = values => {
    const textFieldVal =
      typeof values.floatValue === 'number' ? values.floatValue : ''
    if (this._isMounted) {
      this.setState({
        focusSet: false,
        lastCellTabbed: false,
        isEditing: true,
        textFieldVal
      })
    }
  }

  showRequiredIcon = () => {
    const {
      data,
      value,
      valueFormatted,
      colDef: {
        cellEditorParams: {
          errorMessage,
          isRequired,
          requiredFn,
          requiredIf,
          requiredLessThan
        },
        field
      },
      rowData,
      rowIndex
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)
    //
    // prob makes sense to send all the props into the fn...
    // also prob makes BETTER sense to not have 3 named ways of required
    // you could easily find the type and use it.. this way you can have
    // multiple avenues to pass in and all can be evaluated..
    // this is called polymorphism

    if (requiredFn && typeof requiredFn === 'function') {
      return requiredFn(data, this.props) ? errorIcon : null
    }

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }
    // why? just use a fn...
    if (requiredIf) {
      // console.log('requiredIf', rowData[rowIndex][field])
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredIf] &&
        !rowData[rowIndex][field]
      ) {
        return errorIcon
      }
    }

    if (requiredLessThan) {
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredLessThan]
      ) {
        if (
          toNumeral(rowData[rowIndex][field]) >
          toNumeral(rowData[rowIndex][requiredLessThan])
        ) {
          return errorIcon
        }
      }
    }

    return null
  }

  isLeftOrRight = event => nums.includes(event.keyCode)

  getValue() {
    return this.state.textFieldVal
  }

  onFocus = () => {
    if (this.textField) {
      this.textField.select()
    }
  }

  render() {
    const {
      colDef: {
        field,
        cellEditorParams: {
          allowNegative,
          formatter,
          decimalScale,
          decimalSeperator,
          dollarField,
          fixedDecimalScale,
          thousandSeparator,
          textAlign,
          noDecimalLimit,
          maxLength,
          fontSize
        }
      },
      form,
      rowIndex,
      isMobile
    } = this.props

    const { isEditing } = this.state

    if (formatter) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            width: '100%'
          }}
        >
          {this.showRequiredIcon()}
          <Input
            disableUnderline
            fullWidth
            id={`text-field-${form}-${field}-${rowIndex}`}
            value={this.state.textFieldVal}
            // disabled={this.isFieldDisabled()}
            inputComponent={NumberFormatter}
            onChange={this.onValueChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            style={{
              height: '100%',
              width: '100%'
            }}
            inputRef={el => (this.textField = el)}
            // inputRef={el => (this.textField = el)}
            inputProps={{
              allowNegative,
              decimalScale: decimalScale || 0,
              noDecimalLimit: noDecimalLimit || false,
              decimalSeparator: decimalSeperator || '.',
              fontSize: fontSize || '1em',
              fixedDecimalScale: fixedDecimalScale || false,
              inputRef: el => (this.textField = el),
              maxLength,
              textAlign: textAlign || 'left',
              thousandSeparator,
              prefix: dollarField ? '$' : ''
            }}
            endAdornment={
              isMobile && this.state.textFieldVal && isEditing ? (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    style={{ padding: 5 }}
                  >
                    <Icon style={{ color: '#389123' }}>done</Icon>
                  </IconButton>
                </InputAdornment>
              ) : null
            }

          />
        </div>
      )
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
      >
        {this.showRequiredIcon()}
        <TextField
          InputProps={{
            disableUnderline: true,
            style: { height: '100%', padding: 0, width: '100%' },
            endAdornment:
              isMobile && this.state.textFieldVal && isEditing ? (
                <InputAdornment position="end">
                  <IconButton
                    // onClick={this.blurField}
                    size="small"
                    style={{ padding: 5 }}
                  >
                    <Icon style={{ color: '#389123' }}>done</Icon>
                  </IconButton>
                </InputAdornment>
              ) : null
          }}
          inputProps={{
            style: {
              height: '100%',
              padding: 0,
              width: '100%',
              textAlign: textAlign || 'left'
            }
          }}
          onChange={this.onChange}
          onFocus={this.onFocus}
          // disabled={this.isFieldDisabled()}
          onBlur={this.onBlur}
          value={this.state.textFieldVal}
          id={`text-field-${form}-${field}-${rowIndex}`}
          fullWidth
          style={{ height: '100%', width: '100%' }}
          inputRef={el => (this.textField = el)}
          forwardRef
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formModals = getIn(state, `ddiForm.${props.form}.modals`).size
  const mainModals = state.get('modals').size
  const ret = {
    modals: formModals + mainModals
  }
  return ret
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AmountCell)
