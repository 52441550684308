import React, { useEffect } from 'react'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import { getIn, getValue, emptyList, formatDateFields } from 'utils'
import { setGridHeightImmutable } from 'pages/SalesOrder/utils'

import ClearStatusCell from './ClearStatusCell'

const getRowNodeId = data => data.dataId

const dynamicColDefs = ({ isEditing, dispatch, form, isMobile }) => {
  const columnDefs = [
    {
      field: 'description',
      headerName: '',
      filter: false,
      minWidth: isMobile ? 50 : 120
    },
    {
      field: 'date',
      headerName: 'Date',
      valueFormatter: params => formatDateFields(params, 'M/D/YY h:mm A'),
      minWidth: isMobile ? 50 : 120,
      suppressMenu: true,
      filter: false
    },
    {
      field: 'userId',
      headerName: 'By',
      suppressMenu: true,
      filter: false
    },
    {
      cellRendererFramework: ClearStatusCell,
      cellRendererParams: {
        form,
        dispatch
      },
      field: 'canDelete',
      headerName: '',
      hide: !isEditing
    }
  ]

  return columnDefs
}

let gridApi = null
export const StatusGrid = ({
  dispatch,
  floorLocation,
  form,
  isEditing,
  isMobile = false,
  isActiveMobileTab = false,
  shipmentStatuses
}) => {
  const gridHeight = setGridHeightImmutable(shipmentStatuses, 28)

  function getBottomData() {
    return [
      {
        description: 'Floor Location:',
        date: null,
        userId: floorLocation
      }
    ]
  }

  useEffect(() => {
    if (isMobile && gridApi && isActiveMobileTab) {
      gridApi.sizeColumnsToFit()
    }
  }, [isActiveMobileTab])

  const onGridReady = params => {
    if (params && params.api) {
      gridApi = params.api
      params.api.sizeColumnsToFit()
    }
  }

  return (
    <Grid
      getRowNodeId={getRowNodeId}
      columnDefs={dynamicColDefs({
        dispatch,
        form,
        isEditing,
        isMobile
      })}
      rowData={
        shipmentStatuses && shipmentStatuses?.toJS
          ? shipmentStatuses.toJS()
          : []
      }
      height={gridHeight < 300 ? 300 : gridHeight}
      fullWidth
      pinnedBottomRowData={getBottomData()}
      onGridReady={onGridReady}
    />
  )
}

export const contextObj = {
  floorLocation: form => getValue(form, 'floorLocation', ''),
  isEditing: form => form.get('isEditing') || false,
  shipmentStatuses: form => getIn(form, 'values.shipmentStatuses') || emptyList
}

export default withContext(contextObj)(StatusGrid)
