import React from 'react'
import PropTypes from 'prop-types'
import { Label, withContext } from 'ddiForm'
import { Paper, MenuItem } from '@material-ui/core'
import DDITextField from 'ddiForm/wrapped/DDITextField'
import SelectField from 'ddiForm/SelectField'
import DDITabTextField from 'ddiForm/wrapped/DDITabTextField'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import { DDIDatePicker } from 'ddiForm/wrapped'
import { getIn } from 'utils'
// 2021111A
const Analysis = ({ asModal, selectInitialValue, statusOptions, styles }) => (
  <Paper style={{ padding: '10px 20px 15px 20px' }}>
    <div className="po-inquiry-fields">
      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="startDate" style={styles.label}>
            Start Date:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIDatePicker
            propertyName="startDate"
            hintText="Start Date"
            dialogContainerStyle={{ zIndex: 5000 }}
            inputStyle={{ marginTop: 5 }}
            wrapperStyle={null}
            initialValue={null}
          />
        </div>
      </div>
      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="endDate" style={styles.label}>
            End Date:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIDatePicker
            propertyName="endDate"
            hintText="End Date"
            dialogContainerStyle={{ zIndex: 5000 }}
            inputStyle={{ marginTop: 5 }}
            wrapperStyle={null}
            initialValue={null}
          />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="branch" style={styles.label}>
            Branch:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIIndexSearch
            propertyName="branchId"
            metaKey="Branch"
            asModal={asModal}
            moreInfo
            openScreenData={{
              name: 'branchMaster',
              image: 'branch_master_16.png',
              title: 'Branch Master'
            }}
            openScreenMenuText="Open Branch Master"
            minWidth="100%"
          />
        </div>
        <div className="description" style={styles.flexInput.description}>
          <Label propertyName="branchDescription" />
        </div>
      </div>

      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="shipVia" style={styles.label}>
            Ship Via:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIIndexSearch
            propertyName="shipViaId"
            metaKey="ShipVia"
            asModal={asModal}
            moreInfo
            minWidth="100%"
          />
        </div>
        <div className="description" style={styles.flexInput.description}>
          <Label propertyName="shipViaDescription" />
        </div>
      </div>
      <div className="input-flex-row" style={styles.flexInput.wrapper}>
        <div className="label" style={styles.flexInput.label}>
          <label htmlFor="writer" style={styles.label}>
            Writer:
          </label>
        </div>
        <div className="input" style={styles.flexInput.input}>
          <DDIIndexSearch
            propertyName="writerId"
            metaKey="Writer"
            asModal={asModal}
            moreInfo
            minWidth="100%"
          />
        </div>
        <div className="description" style={styles.flexInput.description}>
          <Label propertyName="writerDescription" />
        </div>
      </div>
    </div>
  </Paper>
)

export default withContext({
  selectInitialValue: formState =>
    getIn(formState, 'meta.statusOptions[0].dataId'),
  statusOptions: formState => {
    const productId = getIn(formState, 'fields.product.value')
    const productIdIsSet = getIn(formState, 'fields.product.isSet')
    const statusOptions = getIn(formState, 'meta.statusOptions')
    const productStatusOptions = getIn(formState, 'meta.productStatusOptions')
    return productId && productIdIsSet ? productStatusOptions : statusOptions
  }
})(Analysis)
