import React from 'react'
import Grid from 'grid'
import { BASE_INFORM_URL } from 'services/constants'
import { ButtonBase } from '@material-ui/core'

export default props => (
  console.log(props),
  (
    <div
      style={{
        height: 400,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        style={{ display: 'flex', flex: 0.1, padding: 5, alignItems: 'center' }}
      >
        <div style={{ flex: 0.1, paddingLeft: 10 }}>
          <strong>Warehouse:</strong>
        </div>
        <div style={{ flex: 0.7, paddingLeft: 10 }}>
          {props.warehouseIdAndDescription}
        </div>
        <div style={{ flex: 0.1, alignItems: 'flex-end' }}>
          {props.showButton && (
            <ButtonBase
              disabled
              onClick={() => {
                alert('inventory allocation modal')
                // openScreen(item.fullDescription, item.imageFilename16)
              }}
            >
              <img
                src={`${BASE_INFORM_URL}/resources/inventory_bin_allocation_16.png`}
                alt="inventory bin allocation"
                className="center-block"
              />
            </ButtonBase>
          )}
        </div>
      </div>
      <div style={{ flex: 0.9 }}>
        <Grid
          // height={this.getGridHeight()}
          height={350}
          columnDefs={props.columnDefs}
          meta={props.meta}
          rowData={props.rowData}
          suppressCellSelection
          // onRowDoubleClicked={this.onRowClicked}
          // getRowNodeId={this.getRowNodeId}
          // paginationCb={this.paginationCb}
          // toggleFilter={this.toggleFilter}
          // toggles={this.toggles}
          // isFiltered={props.isFiltered}
          // autoSize
        />
      </div>
    </div>
  )
)
