import { createAsyncRequestTypes } from 'utils'
import { SALES_ORDER } from '../../constants'

export const SAVE_WARRANTY_TAG = createAsyncRequestTypes(
  `${SALES_ORDER}SAVE_WARRANTY_TAG`
)

export const CANCEL_EDIT_WARRANTY_TAG = createAsyncRequestTypes(
  `${SALES_ORDER}CANCEL_EDIT_WARRANTY_TAG`
)

export const EDIT_WARRANTY_TAG = createAsyncRequestTypes(
  `${SALES_ORDER}EDIT_WARRANTY_TAG`
)

export const DELETE_WARRANTY_TAG = createAsyncRequestTypes(
  `${SALES_ORDER}DELETE_WARRANTY_TAG`
)

export const CLEAR_IS_EDITING_FLAG = `${SALES_ORDER}CLEAR_IS_EDITING_FLAG`

export const GET_PREVIOUS_END_USER_INFO = createAsyncRequestTypes(
  `${SALES_ORDER}GET_PREVIOUS_END_USER_INFO`
)
