import React, { Component } from 'react'
import { Icon, IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { errorRed } from 'theme/colors'
import { getIn } from 'utils'

import { deleteCategory } from '../actions'

class DeleteButton extends Component {
  tryDeleteCategory = e => {
    const {
      dispatch,
      isEditing,
      value,
      data,
      colDef,
      api,
      node,
      ...rest
    } = this.props
    const form = colDef?.cellRendererParams?.form

    e.preventDefault()
    if (form && isEditing) {
      dispatch(deleteCategory.try(form, { data, gridApi: api, node }))
    }
  }

  render() {
    const { isEditing, data } = this.props
    return (
      <div
        key={data?.dataId}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <IconButton
          size="small"
          onClick={this.tryDeleteCategory}
          disabled={!isEditing}
        >
          <Icon
            style={{ color: isEditing && data?.dataId ? errorRed : '#777' }}
          >
            cancel
          </Icon>
        </IconButton>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const form = ownProps?.colDef?.cellRendererParams?.form || ''
  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false

  return { form, isEditing }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DeleteButton)
