import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { Field, Label } from 'ddiForm'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import { DDICardWrapper } from 'components'

import '../styles/master-style.scss'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const formData = getIn(state, `ddiForm.${form}`)

  return {
    description: getIn(formData, 'values.fromIdDescription') || '',
    displayUom: getIn(formData, 'values.displayUOM') || '',
    priceUom: getIn(formData, 'values.priceUOM') || ''
  }
}

export class CopyProductModal extends Component {
  // componentDidMount() {
  //   if (this.field) this.field.focus()
  // }

  render() {
    const { description, displayUom, priceUom } = this.props

    return (
      <DDICardWrapper title="From Product">
        <div className="two-col-flex-wrapper">
          <div className="search-wrapper" style={{ marginBottom: 15 }}>
            <label
              className="search-label"
              style={{ minWidth: 0, textAlign: 'left' }}
            >
              Number:
            </label>
            <div style={{ marginRight: 10 }}>
              <DDIIndexSearch
                propertyName="fromId"
                disableLastSearchButton
                meta={{
                  allowInstantSearch: true,
                  minimumKeywordLength: 2,
                  searchType: 'product'
                }}
                inputProps={{
                  autoFocus: true
                }}
                inputRef={c => (this.field = c)}
                preventAutoDisable
              />
            </div>
            <Field
              component={Label}
              propertyName="fromIdDescription"
              labelStyle={{ width: '100%' }}
            />
          </div>
        </div>
        <div className="two-col-flex-wrapper">
          <div className="two-col-flex-left" style={{ paddingLeft: 0 }}>
            <div>
              Display UOM: <strong>{displayUom}</strong>
            </div>
          </div>
          <div className="two-col-flex-right">
            <div>
              Price UOM: <strong>{priceUom}</strong>
            </div>
          </div>
        </div>
      </DDICardWrapper>
    )
  }
}

CopyProductModal.propTypes = {
  displayUom: PropTypes.string.isRequired,
  priceUom: PropTypes.string.isRequired
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(CopyProductModal)
