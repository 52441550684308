import { createAsyncRequestTypes } from 'utils'

export const PRODUCT_IMPORT = '@@PRODUCT_IMPORT@@'

export const CLEAR_INTERFACE = `${PRODUCT_IMPORT}CLEAR_INTERFACE`

export const OPEN_PRODUCT_IMPORT_EDITOR = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}OPEN_PRODUCT_IMPORT_EDITOR`
)

export const SET_ROW_DATA = `${PRODUCT_IMPORT}SET_ROW_DATA`

export const ON_CELL_VALUE_CHANGED = `${PRODUCT_IMPORT}ON_CELL_VALUE_CHANGED`

export const ON_PRODUCT_IMPORT_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}ON_PRODUCT_IMPORT_PROPERTY_CHANGE`
)

export const ANALYZE_PRODUCT_IMPORT_DATA = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}ANALYZE_PRODUCT_IMPORT_DATA`
)

export const CLEAR_MAPPINGS = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}CLEAR_MAPPINGS`
)

export const IMPORT_PRODUCT_DATA = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}IMPORT_PRODUCT_DATA`
)

export const EXIT_IMPORT_SCREEN = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}EXIT_IMPORT_SCREEN`
)

export const DELETE_TEMPLATE = createAsyncRequestTypes(
  `${PRODUCT_IMPORT}DELETE_TEMPLATE`
)
