import React from 'react'
import PropTypes from 'prop-types'

export const MultiLineTextRendererCell = ({ value = '' }) => {

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <p dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  )
}

MultiLineTextRendererCell.propTypes = {
  value: PropTypes.string.isRequired
}

export default MultiLineTextRendererCell
