import { call, fork, cancel, put, select, take } from 'redux-saga/effects'
import * as DDICONSTANTS from 'ddiForm/constants'
import {
  ON_PRIMARY_GRID_DATA_VALIDATED,
  UPDATE_GRID_CELL_DATA,
  DELETE_GRID_ROW
} from 'components/EditableGrid/constants'
import { addModal, removeModal } from 'modals/actions'
import {
  updateGridCellData,
  validateGridData
} from 'components/EditableGrid/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, parseNumber } from 'utils'
import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import {
  cancelEditAfterClear,
  removeGridItemProcess
} from 'pages/SalesOrder/sagas/commonSagas'

import {
  handlePrintJobAfterSave,
  displaySafetyDataSheetsGridModal,
  displaySpawnedBoMessage
} from 'pages/ShippingConfirmation/sagas'
import { api } from 'services'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import {
  PRINT_DOCUMENT_ROUTINE_COMPLETED,
  CLOSE_REPORT_VIEWER
} from 'components/PrintDocumentModal/constants'
import { printDocumentRoutineCompleted } from 'components/PrintDocumentModal/actions'

import { warningModal, confirmationModal } from 'modals/sagas'
import { REMOVE_MODAL, CANCELED, CONFIRMED } from 'modals/constants'
import SendDocumentModal from 'components/SendDocument'
import { getRecord } from 'ddiForm/MasterScreen/sagas'
import { sendInvoice as sendInvoiceAPI } from 'pages/InvoiceInquiry/api'
import ProductInvoiceHistoryModal from '../components/ProductInvoiceHistoryModal'

export function* miscChargeGridUpdateProcess(form, field, value, rowIndex) {
  if ((field === 'dataId' && !value) || field === 'description') {
    return
  }
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['final']

  yield put(actions.updateGridItem.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    properties: { [field]: field === 'amount' ? parseNumber(value) : value },
    lineNumber: rowIndex + 1,
    guid,
    groupNames,
    gridName: 'miscellaneousCharges'
  })

  if (response) {
    yield put(actions.updateGridItem.success(response, form))
  } else {
    const miscCharges = getIn(formState, 'fields.miscellaneousCharges.rowData')
    const row = miscCharges.get(rowIndex)
    if (row && row.get && row.get('rowId')) {
      const rowId = row.get('rowId')
      yield put(
        updateGridCellData(form, {
          rowId,
          rowIndex,
          propertyName: 'miscellaneousCharges',
          field,
          value: ''
        })
      )

      if (field === 'dataId') {
        /* need to manually clear the description as well */
        yield put(
          updateGridCellData(form, {
            rowId,
            rowIndex,
            propertyName: 'miscellaneousCharges',
            field: 'description',
            value: ''
          })
        )
      }
    }

    yield put(actions.updateGridItem.failure(error, form))
  }
}

export function* onPrimraryGridDataValidatedListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex, newData, propertyName }
    } = yield take(ON_PRIMARY_GRID_DATA_VALIDATED)

    if (form === formListener && newData && newData.dataId) {
      if (propertyName === 'miscellaneousCharges') {
        yield fork(
          miscChargeGridUpdateProcess,
          form,
          'dataId',
          newData.dataId,
          rowIndex
        )
      }
    }
  }
}

export function* onUpdateGridCellDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, field, value, rowIndex, rowId }
    } = yield take(UPDATE_GRID_CELL_DATA)

    if (form === formListener) {
      if (propertyName === 'miscellaneousCharges') {
        yield fork(miscChargeGridUpdateProcess, form, field, value, rowIndex)
      }
    }
  }
}

// export function* miscChargeDeleteGridRowProcess(form, propertyName, rowIndex) {
//   const formState = yield select(getFormSelector(form))
//   const guid = getIn(formState, 'guid')
//   const groupNames = ['final']

//   yield put(actions.removeGridItem.request(form))

//   const { response, error } = yield call(api.changeGridItem, {
//     lineNumber: rowIndex + 1,
//     gridName: propertyName,
//     groupNames,
//     guid,
//     properties: { delete: '' }
//   })

//   if (response) {
//     yield put(actions.removeGridItem.success(response, form))
//     yield put(validateGridData(form, { propertyName }))
//   } else {
//     yield put(actions.removeGridItem.failure(error, form))
//   }
// }

// export function* deleteEditableGridRowListener(formListener) {
//   while (true) {
//     const {
//       meta: { form },
//       payload: { propertyName, rowIndex, rowId }
//     } = yield take(DELETE_GRID_ROW)

//     if (form === formListener) {
//       if (propertyName === 'miscellaneousCharges') {
//         yield fork(miscChargeDeleteGridRowProcess, form, propertyName, rowIndex)
//       }
//     }
//   }
// }

export function* getQuotePrintParams(form) {
  const formState = yield select(getFormSelector(form))
  const quoteFields = [
    'quotePrintGP',
    'quoteSuppressUnitPrices',
    'quotePrintListAndDiscount',
    'quoteSuppressProductNumber',
    'quoteSuppressExtensions',
    'quoteSuppressTotals',
    'quotePrintQuoteDescription',
    'quoteSuppressTaxes'
  ]

  const data = {}
  for (let i = 0; i < quoteFields.length; i++) {
    const field = quoteFields[i]
    data[field] = getIn(formState, `fields.${field}.value`)
  }

  return data
}

export function* printProcess(form, type, selectedLinkedOrderIndex = null) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')
  const layoutId = getIn(formState, 'fields.layoutId.value')
  const quote = getIn(formState, 'values.quote')
  const afterPrintingInSOSetFormForNew = getIn(
    formState,
    'meta.afterPrintingInSOSetFormForNew'
  )
  const isNew = getIn(formState, 'values.isNew') || false
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const groupNames = ['final', 'header', 'detail']

  const paramsMap = {
    printOrderRecap: {
      guid,
      dataId,
      groupNames
    },
    printWorksheet: {
      guid,
      dataId,
      groupNames
    },
    printWarehousePick: {
      guid,
      dataId,
      groupNames
    },
    printSalesOrder: {
      guid,
      dataId,
      layoutId: layoutId === 'Default' ? '' : layoutId
    },
    printLinkedOrder: {
      guid,
      dataId,
      index: selectedLinkedOrderIndex,
      action: type ? type.toLowerCase() : ''
    }
  }

  let postData = paramsMap[type]

  if (quote) {
    const quoteData = yield call(getQuotePrintParams, form)

    postData = {
      ...postData,
      ...quoteData
    }
  }

  yield put(actions.initPrintRoutine.request(form))
  const apiMethod = type === 'printLinkedOrder' ? 'printSalesOrder' : type

  const { response, error } = yield call(api[apiMethod], postData)
  // const { response, error } = yield call(api[type], paramsMap[type])

  if (response) {
    yield put(actions.initPrintRoutine.success(response, form))

    /* variable to be overwritten by actual action */
    let printCompletionAction = { payload: { isCancellation: false } }
    if (Array.isArray(response)) {
      for (let i = 0; i < response.length; i++) {
        yield fork(showPrintDocumentModalProcess, form, {
          ...response[i],
          form
        })
        // debugger

        printCompletionAction = yield take([
          PRINT_DOCUMENT_ROUTINE_COMPLETED,
          CLOSE_REPORT_VIEWER
        ])
      }
    } else {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response,
        form
      })

      printCompletionAction = yield take([
        PRINT_DOCUMENT_ROUTINE_COMPLETED,
        CLOSE_REPORT_VIEWER
      ])
    }

    const isCancellationAction =
      printCompletionAction?.payload?.isCancellation || false

    if (
      afterPrintingInSOSetFormForNew &&
      type === 'printSalesOrder' &&
      !isCancellationAction &&
      !isMobile
    ) {
      /* respond to flag that clears a Sales Order */
      yield put(actions.clearOrder(form))
      yield call(cancelEditAfterClear, form)
    } else if (
      (type === 'printSalesOrder' || type === 'printLinkedOrder') &&
      !isCancellationAction &&
      !isMobile
    ) {
      /*
        unfortunately, we have to get the whole record here
        there should be a thumbs down emoji for that -- SVE 10/25/2019
      */
      yield fork(getRecord, {
        form,
        newRecord: isNew,
        tabIds: ['final'],
        dataId,
        guid,
        groupNames: ['final']
      })
    }
  } else {
    yield put(actions.initPrintRoutine.failure(error, form))
  }
}

export function* closeSendDocumentListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take('CLOSE_SEND_DOCUMENT')

    if (form === formListener) {
      yield put(printDocumentRoutineCompleted(form))
    }
  }
}

export function* validatePrintProcess(form, type) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')
  const layoutId = getIn(formState, 'fields.layoutId.value')
  const quote = getIn(formState, 'values.quote')
  const groupNames = ['final', 'header', 'detail']

  yield put(actions.validatePrintDocument.request(form))

  let postData = {
    guid,
    dataId,
    layoutId: layoutId === 'Default' ? '' : layoutId
  }

  if (quote) {
    const quoteData = yield call(getQuotePrintParams, form)

    postData = {
      ...postData,
      ...quoteData
    }
  }

  const { response, error } = yield call(api.validatePrintDocument, postData)

  if (response) {
    yield put(actions.validatePrintDocument.success(response, form))

    /*
      show any warnings from the validation
      prior to initiating the print proc
    */
    if (response && response.messages) {
      for (let i = 0; i < response.messages.length; i++) {
        const msg = response.messages[i]
        if (msg.type === 'Warning' || msg.type === 'Error') {
          yield call(warningModal, msg.message, msg.modalTitle)
          yield take(REMOVE_MODAL)
        }
      }
    }

    if (response.valid) {
      yield fork(printProcess, form, type)
    }
  } else {
    yield put(actions.validatePrintDocument.failure(error, form))
  }
}

export function* initializePrintRoutine(form, type, selectedLinkedOrderIndex) {
  if (type === 'printSalesOrder') {
    yield fork(validatePrintProcess, form, type)
  } else {
    yield fork(printProcess, form, type, selectedLinkedOrderIndex)
  }
}

export function* initPrintRoutineListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { type, selectedLinkedOrderIndex }
    } = yield take(CONSTANTS.INIT_PRINT_ROUTINE.TRY)

    if (form === formListener) {
      yield fork(initializePrintRoutine, form, type, selectedLinkedOrderIndex)
    }
  }
}

export function* sendSalesOrderDocumentProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')
  const layoutId = getIn(formState, 'fields.layoutId.value')
  const isQuote = getIn(formState, 'values.quote') || false

  yield put(actions.sendSalesOrderDocument.request(form))

  let params =
    form === 'invoiceInquiry'
      ? {
          dataId
        }
      : {
          guid,
          dataId,
          layoutId: layoutId === 'Default' ? '' : layoutId
        }

  if (isQuote) {
    const quoteParams = yield call(getQuotePrintParams, form)

    params = {
      ...params,
      ...quoteParams
    }
  }

  const apiMethod =
    form === 'invoiceInquiry' ? sendInvoiceAPI : api.sendSalesOrderDocument
  const { response, error } = yield call(apiMethod, params)

  if (response) {
    yield put(actions.sendSalesOrderDocument.success(response, form))
    const modalOpts = {
      component: SendDocumentModal,
      props: {
        ...response,
        dataId: response.dataId,
        form
      }
    }
    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  } else {
    yield put(actions.sendSalesOrderDocument.failure(error, form))
  }
}

export function* sendSalesOrderDocumentListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SEND_SALES_ORDER_DOCUMENT.TRY)

    if (form === formListener) {
      yield fork(sendSalesOrderDocumentProcess, form)
    }
  }
}

export function* sendLinkedSalesOrderDocumentProcess(
  form,
  selectedLinkedOrderIndex
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')
  let linkedOrders = getIn(formState, 'values.linkedOrders')
  linkedOrders = linkedOrders && linkedOrders.toJS ? linkedOrders.toJS() : []

  // if (!linkedOrders[selectedLinkedOrderIndex]) {
  //   return
  // }

  /* 
    might want to add a request action here to trigger the prog bar,
    but not too familiar with this function, maybe reason we skipped it here.
    Also not sure about why the linkedOrders data is retreived but not used. 
    Weird but don't want to change it without knowing why -- SVE 1/7/21
  */

  const { response, error } = yield call(api.sendSalesOrderDocument, {
    action: 'sendlinkedorder',
    dataId,
    index: selectedLinkedOrderIndex,
    guid
  })

  if (response) {
    yield put(actions.sendLinkedSalesOrderDocument.success(response, form))
    const modalOpts = {
      component: SendDocumentModal,
      props: {
        ...response,
        dataId: response.dataId,
        form
      }
    }
    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  } else {
    yield put(actions.sendLinkedSalesOrderDocument.failure(error, form))
  }
}

export function* sendLinkedSalesOrderDocumentListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { selectedLinkedOrderIndex }
    } = yield take(CONSTANTS.SEND_LINKED_SALES_ORDER_DOCUMENT.TRY)

    if (form === formListener) {
      yield fork(
        sendLinkedSalesOrderDocumentProcess,
        form,
        selectedLinkedOrderIndex
      )
    }
  }
}

export function* saveSignature(
  { payload: { signature, signer: signedBy, modal, id }, meta },
  formActions
) {
  const formState = yield select(getFormSelector(meta.form))
  const guid = getIn(formState, 'guid')
  formActions = formActions || actions
  let ignoreCall = false
  if (signature == null) {
    signature = ''
    signedBy = ''
    const title = 'Clear?'
    const message = 'Do you wish to save a cleared signature?'
    yield call(confirmationModal, message, title)
    const act = yield take([CONFIRMED, CANCELED])
    if (act.type === CANCELED) {
      ignoreCall = true
      yield put(
        actions.onPropertyChange.failure(
          {},
          {
            form: meta.form,
            thunk: meta.thunk
          }
        )
      )
    }
  }
  const args = {
    properties: {
      signature,
      signedBy
    },
    guid,
    groupNames: ['signature']
  }
  if (meta.form === 'shippingConfirmation') {
    args.dataId = getIn(formState, 'fields.dataId.value')
    args.properties.signatureImage = args.properties.signature
    args.action = ''
    delete args.properties.signature
    delete args.guid
  }
  if (!ignoreCall) {
    debugger
    const apiMethod =
      api[
        meta.form === 'shippingConfirmation'
          ? modal
            ? 'saveShippingConfirmation'
            : 'shipConfPropertyChange'
          : 'propertyChange'
      ]
    if (modal && meta.form === 'shippingConfirmation') {
      args.action = ''
      args.requestReply = false
    }
    const { response, error } = yield call(apiMethod, args)

    if (response) {
      if (modal && meta.form === 'shippingConfirmation') {
        ///
        yield put(removeModal(meta.form, id))

        if (response.printJobs) {
          yield call(handlePrintJobAfterSave, meta.form, response.printJobs)
        }

        if (response.sdSheets) {
          yield call(
            displaySafetyDataSheetsGridModal,
            meta.form,
            response.sdSheets
          )
        }

        if (response.spawnedBoMessage) {
          yield call(
            displaySpawnedBoMessage,
            meta.form,
            response.spawnedBoMessage
          )
        }
        yield put(
          formActions.saveShippingConfirmation.success(response, meta.form)
        )
      } else {
        yield put(
          formActions.onPropertyChange.success(response, {
            form: meta.form,
            thunk: meta.thunk
          })
        )
      }
    } else {
      yield put(
        formActions.onPropertyChange.failure(error, {
          form: meta.form,
          thunk: meta.thunk
        })
      )
    }
  }
}

export function* saveSignatureListener(formListener, formActions) {
  while (true) {
    let task
    const action = yield take('TRY_SAVE_SIGNATURE')

    if (action.meta.form === formListener) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(saveSignature, action, formActions)
    }
  }
}

export function* removeGridItemListener(formListener) {
  /* services the misc charges and shipments grid ONLY */
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, gridName, key, groupNames, isEditableGrid = false }
    } = yield take(CONSTANTS.REMOVE_GRID_ITEM.TRY)

    if (form === formListener) {
      yield fork(
        removeGridItemProcess,
        form,
        gridName,
        lineNumber,
        key,
        groupNames,
        isEditableGrid
      )
    }
  }
}

export function* openInvoiceHistoryModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber }
    } = yield take(CONSTANTS.OPEN_INVOICE_HISTORY_MODAL.TRY)

    if (form === formListener) {
      yield fork(getInvoiceHistoryModalData, form, lineNumber)
    }
  }
}

export function* getInvoiceHistoryModalData(form, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const guid = getIn(formState, 'guid')

  yield put(actions.openInvoiceHistoryModal.request(form))
  debugger
  const { response, error } = yield call(api.readLineItem, {
    guid,
    dataId,
    lineNumber,
    action: 'productinvoicehistory'
  })

  if (response) {
    yield put(actions.openInvoiceHistoryModal.success(response, form))
    yield fork(launchInvoiceHistoryModal, form, response)
  } else {
    yield put(actions.openInvoiceHistoryModal.failure(error, form))
  }
}

export function* launchInvoiceHistoryModal(form, data) {
  const modalOpts = {
    component: ProductInvoiceHistoryModal,
    options: {
      maxHeight: '100%',
      title: 'S/O Product Invoice History',
      data: {
        form,
        rowData: data,
        actions: [
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              cb()
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export default function* invoicingSagas(form) {
  yield fork(onPrimraryGridDataValidatedListener, form)
  yield fork(onUpdateGridCellDataListener, form)
  yield fork(initPrintRoutineListener, form)
  yield fork(sendSalesOrderDocumentListener, form)
  yield fork(sendLinkedSalesOrderDocumentListener, form)
  yield fork(saveSignatureListener, form)
  yield fork(removeGridItemListener, form)
  yield fork(openInvoiceHistoryModalListener, form)
}
