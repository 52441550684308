/* eslint import/prefer-default-export: 0 */
import memoize from 'memoize-one'
import { plainDeepEqual } from 'utils'

export const getGridHeight = memoize(data => {
  let height = 200
  if (data && data.length) {
    height = data.length * 30 + 32 + 56
  }

  return height
}, plainDeepEqual)

export const addMinWidth = col => {
  col = {
    ...col,
    minWidth: 100
  }

  return col
}
