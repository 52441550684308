import React, { memo } from 'react'
import { Paper } from '@material-ui/core'
import { errorRed } from 'theme/colors'

const ProfitCard = ({
  headline = '',
  primaryData = '',
  primaryDataSubtitle = 'Cost',
  secondaryData = '',
  secondaryDataSubtitle = '',
  isPrimaryNegative = false,
  isSecondaryNegative = false
}) => (
  <Paper style={{ minWidth: 150, margin: 5, padding: 10 }} square>
    <div style={{ paddingBottom: 10, marginBottom: 10, borderBottom: '1px solid #c9c9c9' }}>
      <h2 style={{ color: '#517b9c', fontSize: 18, textAlign: 'center', margin: '0 0 15px 0' }}>{headline}</h2>
      <h3
        style={{
          fontSize: 24,
          fontWeight: 500,
          marginBottom: 0,
          marginTop: 0,
          color: isPrimaryNegative ? errorRed : '#000'
        }}>
          {primaryData}
      </h3>
      <span style={{ fontSize: 12 }}>{primaryDataSubtitle}</span>
    </div>
    <h3
      style={{
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 0,
        marginTop: 0,
        color: isSecondaryNegative ? errorRed : '#000'
      }}>
        {secondaryData}
      </h3>
    <span style={{ fontSize: 12 }}>{secondaryDataSubtitle}</span>
  </Paper>
)

export default ProfitCard

