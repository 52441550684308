/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const readInvoiceInquiry = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/invoiceinquiry/read`, options)
}

export const readAdditionalInvoiceData = ({ dataId, lineNumber, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      lineNumber,
      ...rest
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/invoiceinquiry/readlineitem`,
    options
  )
}

export const printInvoice = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/invoiceinquiry/print`, options)
}

export const printOrderRecap = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/invoiceinquiry/printorderrecap`,
    options
  )
}

export const printProofOfDelivery = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/invoiceinquiry/printproofofdelivery`,
    options
  )
}

export const sendInvoice = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/invoiceinquiry/send`, options)
}

export const voidInvoice = ({ dataId, groupNames }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      groupNames
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/invoiceinquiry/void`, options)
}

export const getChangeInvoiceMeta = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/changeinvoice/meta`, options)
}

export const lockInvoiceRecord = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/changeinvoice/editlock`, options)
}

export const unlockInvoiceRecord = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/changeinvoice/canceledit`, options)
}

export const saveInvoiceRecord = ({ dataId, properties }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      properties
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/changeinvoice/update`, options)
}

export const handleSuppressFromStatements = ({
  dataId,
  suppressFromStatements
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      suppressFromStatements
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/changeinvoice/suppressfromstatements`,
    options
  )
}

export const getSalesmenCommissions = ({ dataId, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      ...rest
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/changeinvoice/commissions`, options)
}

export const changeCommissionValue = ({
  dataId,
  lineNumber,
  properties,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      lineNumber,
      properties,
      ...rest
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/changeinvoice/commissions/changegriditem`,
    options
  )
}

export const changeCommissionPropChange = ({ dataId, properties, ...rest }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      properties,
      ...rest
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/changeinvoice/commissions/propertychange`,
    options
  )
}

/* start new Customer Stock Minimum APIs */
export const readCustomerStockMinimums = ({
  dataId,
  customerId,
  shipToId,
  uomId,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      customerId,
      shipToId,
      uomId,
      ...rest
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/customerstockminimums/read`, options)
}

export const saveCustomerStockMinimums = ({
  dataId,
  customerId,
  shipToId,
  uomId,
  minimumQuantity,
  maximumQuantity,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      customerId,
      shipToId,
      uomId,
      minimumQuantity,
      maximumQuantity,
      ...rest
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/customerstockminimums/save`, options)
}

export const deleteCustomerStockMinimums = ({
  dataId,
  customerId,
  shipToId,
  uomId,
  ...rest
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId,
      customerId,
      shipToId,
      uomId,
      ...rest
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customerstockminimums/delete`,
    options
  )
}

export const exitCustomerStockMinimumsInterface = () => {
  const options = createApiOptions({
    method: 'post',
    body: {}
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/customerstockminimums/close`,
    options
  )
}

/* end new Customer Stock Minimum APIs */

export const handleSerialNumberChange = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/changeinvoice/serialnumbers`,
    options
  )
}
