/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { DDIIndexSearch, DDISelectField } from 'ddiForm/wrapped'
import { Icon, IconButton } from '@material-ui/core'
import { errorRed, informBlue } from 'theme/colors'
import { deleteTemplate as deleteTemplateAction } from '../actions'
import NewTemplateField from './NewTemplateField'

const templateActionButtonStyle = {
  padding: 5,
  position: 'absolute',
  right: -35,
  bottom: 2
}

const ConfigurationFields = ({ form, templates, templateId }) => {
  const dispatch = useDispatch()
  const [showDropdown, toggleDropdown] = useState(true)
  const existingTemplateSelected =
    templates?.find(x => x?.dataId === templateId) || false

  const deleteTemplate = debounce(e => {
    dispatch(deleteTemplateAction.try(form, { templateId }))
  }, 300)

  return (
    <>
      <div className="input-row">
        <label className="label">Template:</label>
        <div className="input-wrapper" style={{ position: 'relative' }}>
          {showDropdown ? (
            <DDISelectField
              propertyName="productImport.templateId"
              values={templates}
              style={{ width: '100%' }}
              preventAutoDisable
              disableDoubleClickUnlock
              selectValue="dataId"
              selectDescription="dataId"
            />
          ) : (
            <NewTemplateField
              form={form}
              propertyName="productImport.templateId"
              templates={templates}
              templateId={templateId}
            />
          )}
          {existingTemplateSelected ? (
            <IconButton
              onClick={deleteTemplate}
              size="large"
              style={templateActionButtonStyle}
            >
              <Icon size="large" style={{ color: errorRed }}>
                cancel
              </Icon>
            </IconButton>
          ) : (
            <IconButton
              onClick={e => toggleDropdown(!showDropdown)}
              size="large"
              style={templateActionButtonStyle}
            >
              <Icon size="large" style={{ color: informBlue }}>
                {showDropdown ? 'add_circle_outline' : 'swap_horizontal_circle'}
              </Icon>
            </IconButton>
          )}
        </div>
      </div>
      <div className="input-row">
        <label className="label">Product Line:</label>
        <div className="input-wrapper">
          <DDIIndexSearch
            propertyName="productImport.productLineId"
            wrapperStyle={{ width: '100%', position: 'relative' }}
            preventAutoDisable
            disableDoubleClickUnlock
            meta={{
              allowInstantSearch: true,
              allowNavigation: false,
              allowSearchAll: true,
              allowSpecialProducts: false,
              fieldName: 'productImport.productLineId',
              groupName: null,
              hasFilters: false,
              hideToggle: true,
              isSecure: false,
              minimumKeywordLength: 0,
              openText: 'Open Product Line Master',
              returnCustomerConsignmentWarehouses: false,
              returnInactiveWarehouses: false,
              searchType: 'ProductLine'
            }}
            displayDescription
          />
        </div>
      </div>
      <div className="input-row">
        <label className="label">Vendor</label>
        <div className="input-wrapper">
          <DDIIndexSearch
            propertyName="productImport.vendorId"
            wrapperStyle={{ width: '100%', position: 'relative' }}
            preventAutoDisable
            disableDoubleClickUnlock
            meta={{
              allowInstantSearch: true,
              hasFilters: false,
              minimumKeywordLength: 2,
              isSecure: false,
              searchType: 'Vendor',
              fieldName: 'productImport.vendorId',
              groupName: null,
              returnInactiveWarehouses: false,
              allowSearchAll: false,
              allowSpecialProducts: false,
              hideToggle: true,
              openText: 'Open Vendor Master',
              allowNavigation: false,
              returnCustomerConsignmentWarehouses: false
            }}
            displayDescription
          />
        </div>
      </div>
    </>
  )
}

export default ConfigurationFields
