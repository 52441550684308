import { call, fork, take, select, put } from 'redux-saga/effects'
import { api } from 'services'
import { addModal, confirm as confirmModalAction } from 'modals/actions'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import ItemGroupsWrapperMobile from 'pages/SalesOrder/components/ItemGroups/mobile/ItemGroupsWrapperMobile'
import ItemGroups from 'pages/SalesOrder/components/ItemGroups/ItemGroupsWrapper'
import ItemGroupsActions from 'pages/SalesOrder/components/ItemGroups/ItemGroupsActions'
import ItemGroupsActionsMobile from 'pages/SalesOrder/components/ItemGroups/ItemGroupsActionsMobile'
import { SET_FIELD } from 'ddiForm/constants'
import { setSelectedRowIndex } from 'ddiForm/actions'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'

import AddItemGroupModal from '../components/ItemGroups/AddItemGroupModal'
import { mapLinePropertyChangeResponse } from '../utils'

export function* launchAddItemGroupListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_ADD_ITEM_GROUP_MODAL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(launchAddItemGroupModalProcess, form, payload)
    }
  }
}

export function* launchAddItemGroupModalProcess(form, payload) {
  const modalOpts = {
    component: AddItemGroupModal,
    options: {
      width: 450,
      data: {
        actions: [
          {
            clickEvent: {
              saga: addItemGroupToLineItemProcess,
              args: { form, ...payload }
            },
            primary: true,
            title: 'Ok'
          },
          { secondary: true, cancel: true, title: 'Cancel' }
        ],
        form,
        ...payload
      },
      padding: '5px',
      title: 'Add To Group'
    }
  }
  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal.id
}

export function* addItemGroupToLineItemProcess(params) {
  const { form, gridApi } = params
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const itemGroupId = getIn(formState, 'fields.addedItemGroupId.value') || ''
  const groupNames = ['header', 'detail']

  if (guid && gridApi) {
    const activeLineNumbers = gridApi.getSelectedRows().reduce((acc, next) => {
      acc = acc.concat(next.lineNumber)

      return acc
    }, [])

    yield put(actions.updateGridItem.request(form))

    const { response, error } = yield call(api.massChange, {
      guid,
      activeLineNumbers,
      action: 'multiline',
      properties: { itemGroupId },
      groupNames
    })

    if (response) {
      const res = mapLinePropertyChangeResponse(
        { record: response },
        groupNames
      )
      yield put(
        actions.updateGridItem.success(
          {
            ...res
          },
          form
        )
      )
    } else {
      yield put(actions.updateGridItem.failure(error, form))
    }
  }
}

export function* addItemGroupCellListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.ADD_ITEM_GROUP_CELL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(addItemGroupCellProcess, form, payload)
    }
  }
}

export function* addItemGroupCellProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  const { lineNumber, value = '' } = payload

  if (guid) {
    yield put(actions.addItemGroupCell.request(form))

    const params = {
      lineNumber,
      guid,
      gridName: 'lineItems',
      groupNames,
      properties: { itemGroupId: value }
    }

    const { response, error } = yield call(api.changeGridItem, params)

    if (response) {
      const res = mapLinePropertyChangeResponse(response, groupNames)
      yield put(
        actions.updateGridItem.success(
          {
            ...res,
            lineNumber
          },
          form
        )
      )

      // if (gridApi) {
      //   // debugger
      //   gridApi[shiftKey ? 'tabToPreviousCell' : 'tabToNextCell']()
      // }
    } else {
      yield put(actions.updateGridItem.failure(error, form))
    }
  }
}

export function* itemGroupsTabListener(formListener) {
  while (true) {
    const action = yield take('ITEM_GROUPS_GRID_TAB_ATTEMPT')
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(itemGroupsTabProcess, action)
    }
  }
}

export function* itemGroupsTabProcess(action) {
  yield take([
    CONSTANTS.CHANGE_ITEM_GROUPS.SUCCESS,
    CONSTANTS.CHANGE_ITEM_GROUPS.FAILURE
  ])

  if (action.payload.api) {
    action.payload.api[
      action.payload.shiftKey ? 'tabToPreviousCell' : 'tabToNextCell'
    ]()
  }
}

export function* itemGroupsPropertyChangeListener(formListener) {
  const itemGroupsProperties = [
    'groups.printItemGroupDetails',
    'groups.printItemGroupPrices',
    'sortOrders'
  ]
  while (true) {
    const action = yield take([
      SET_FIELD,
      CONSTANTS.ITEM_GROUPS_PROPERTY_CHANGE.TRY
    ])
    const {
      meta: { form },
      payload: { propertyName, value }
    } = action

    if (form === formListener && itemGroupsProperties.includes(propertyName)) {
      yield fork(itemGroupsPropertyChangeProcess, form, { propertyName, value })
    }
  }
}

export function* itemGroupsPropertyChangeProcess(form, payload) {
  const { value = '', propertyName } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const selectedItemGroupIndex =
    getIn(formState, 'fields.groups.itemGroups.selectedRowIndex') || 0
  const selectedItemGroupDataId =
    getIn(
      formState,
      `fields.groups.itemGroups.rowData[${selectedItemGroupIndex}].dataId`
    ) || null
  const isSort = propertyName === 'sortOrders'

  let properties
  if (isSort) {
    properties = { sort: '' }
  } else {
    const prop = propertyName.split('.')[1]
    properties = {
      [prop]: value
    }
  }

  yield put(actions.itemGroupsPropertyChange.request(form))

  const { response, error } = yield call(api.itemGroupsPropertyChange, {
    guid,
    properties
  })

  if (response) {
    yield put(
      actions.itemGroupsPropertyChange.success({ groups: response }, form)
    )

    if (isSort) {
      const newIndex = response.itemGroups.findIndex(
        x => x.dataId === selectedItemGroupDataId
      )

      if (selectedItemGroupIndex !== newIndex) {
        yield put(
          setSelectedRowIndex(form, {
            propertyName: 'groups.itemGroups',
            rowIndex: newIndex
          })
        )
      }
    }
  } else {
    yield put(actions.itemGroupsPropertyChange.failure(error, form))
  }
}

export function* moveProductToDifferentGroupListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.MOVE_PRODUCT_TO_DIFFERENT_GROUP.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(moveProductToDifferentGroupProcess, form, payload)
    }
  }
}

export function* moveProductToDifferentGroupProcess(form, payload) {
  const { itemGroupId = null, lineNumbers = [] } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.moveProductToDifferentGroup.request(form))

  const { response, error } = yield call(api.changeItemGroup, {
    guid,
    gridName: 'lineItems',
    lineNumbers,
    properties: {
      itemGroupId
    }
  })

  if (response) {
    yield put(actions.changeItemGroups.success({ groups: response }, form))
  } else {
    yield put(actions.moveProductToDifferentGroup.failure(error, form))
  }
}

export function* itemGroupsCellChangedListener(formListener) {
  while (true) {
    const action = yield take('ITEM_GROUPS_CELL_CHANGED')
    const {
      meta: { form },
      payload
    } = action

    if (
      form === formListener &&
      payload &&
      payload.propertyName &&
      payload.propertyName.includes('groups')
    ) {
      if (payload.field === 'dataId') {
        yield fork(addItemGroupProcess, form, payload)
      } else {
        yield fork(changeItemGroupsGridProcess, form, payload)
      }
    }
  }
}

export function* launchItemGroupsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_ITEM_GROUPS_MODAL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(launchItemGroupsProcess, form, payload)
    }
  }
}

export function* launchItemGroupsProcess(form, payload) {
  const { dispatch } = payload
  const formState = yield select(getFormSelector(form))
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const availableLineItems =
    getIn(formState, 'values.groups.availableLineItems').toJS() || []
  const itemGroups = getIn(formState, 'values.groups.itemGroups').toJS() || []

  const modalOpts = isMobile
    ? {
        // component: ItemGroups,
        component: ItemGroupsWrapperMobile,
        options: {
          marginTop: '0px',
          maxHeight: '95%',
          maxWidth: '95%',
          width: '95%',
          fullScreen: true,
          title: 'Item Groups',
          actions: ItemGroupsActionsMobile,
          data: {
            form,
            dispatch
          }
        }
      }
    : {
        component: ItemGroups,
        options: {
          maxWidth: '100%',
          width: '100%',
          maxHeight: '100%',
          marginTop:
            '0px' /* note that 0 does not work because the script regards that as false */,
          title: 'Item Groups',
          actions: ItemGroupsActions,
          data: {
            form,
            dispatch
          }
        }
      }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield put(
    actions.launchItemGroupsModal.success(
      { availableLineItems, itemGroups },
      form
    )
  )
  return modal.id
}

export function* cancelItemGroupsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_ITEM_GROUPS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(cancelItemGroupProcess, form, payload)
    }
  }
}

export function* cancelItemGroupProcess(form, payload) {
  const { guid, modalId } = payload

  yield put(actions.cancelItemGroups.request(form))
  const { response, error } = yield call(api.cancelItemGroups, { guid })

  if (response) {
    yield put(actions.cancelItemGroups.success(response, form))
    yield put(confirmModalAction(form, modalId))
  } else {
    yield put(actions.cancelItemGroups.failure(error, form))
  }

}

export function* changeItemGroupsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CHANGE_ITEM_GROUPS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(changeItemGroupProcess, form, payload)
    }
  }
}

export function* changeItemGroupProcess(form, payload) {
  const { type } = payload
  let { lineNumbers } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const availableLineItems =
    getIn(formState, 'fields.groups.availableLineItems.rowData') &&
    getIn(formState, 'fields.groups.availableLineItems.rowData').toJS
      ? getIn(formState, 'fields.groups.availableLineItems.rowData').toJS()
      : []

  const selectedItemGroupRowIndex =
    getIn(formState, 'fields.groups.itemGroups.selectedRowIndex') || 0
  const itemGroup =
    getIn(
      formState,
      `fields.groups.itemGroups.rowData[${selectedItemGroupRowIndex}]`
    ).toJS() || null

  lineNumbers =
    lineNumbers === 'all'
      ? availableLineItems.reduce((acc, next) => {
          acc = acc.concat([next.lineNumber])
          return acc
        }, [])
      : lineNumbers

  const itemGroupId = itemGroup && itemGroup.dataId ? itemGroup.dataId : ''

  yield put(actions.changeItemGroups.request(form))

  const { response, error } = yield call(api.changeItemGroup, {
    guid,
    gridName: 'lineItems',
    lineNumbers,
    properties: {
      itemGroupId: type !== 'remove' ? itemGroupId : ''
    }
  })

  if (response) {
    yield put(actions.changeItemGroups.success({ groups: response }, form))
  } else {
    yield put(actions.changeItemGroups.failure(error, form))
  }
}

export function* saveItemGroupsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_ITEM_GROUPS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(saveItemGroupsProcess, form, payload)
    }
  }
}

export function* saveItemGroupsProcess(form, payload) {
  const { guid, modalId } = payload

  yield put(actions.saveItemGroups.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    properties: {
      groups: ''
    },
    groupNames: ['detail']
  })

  if (response) {
    yield put(actions.saveItemGroups.success(response, form))
    yield put(confirmModalAction(form, modalId))
  } else {
    yield put(actions.saveItemGroups.failure(error, form))
  }
}

export function* deleteItemGroupListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_ITEM_GROUP.TRY)
    const {
      meta: { form },
      payload: { lineNumber }
    } = action

    if (form === formListener) {
      yield fork(deleteItemGroupProcess, form, lineNumber)
    }
  }
}

export function* deleteItemGroupProcess(form, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.deleteItemGroup.request(form))

  const { response, error } = yield call(api.changeItemGroup, {
    guid,
    action: 'delete',
    gridName: 'itemGroups',
    lineNumber
  })

  if (response) {
    yield put(actions.changeItemGroups.success({ groups: response }, form))
    yield put(actions.deleteItemGroup.success(response, form))
  } else {
    yield put(actions.changeItemGroups.failure(error, form))
  }
}
export function* addItemGroupProcess(form, payload) {
  const {
    value,
    field,
    data: { lineNumber = 0 },
    gridApi,
    node
    // rowIndex
  } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  // const lineNumber = rowIndex + 1

  const properties = {
    [field]: value
  }

  yield put(actions.changeItemGroups.request(form))

  const { response, error } = yield call(api.changeItemGroup, {
    gridName: 'itemGroups',
    guid,
    lineNumber,
    properties
  })

  if (response) {
    yield put(
      actions.changeItemGroups.success({ groups: response, api: gridApi }, form)
    )

    if (gridApi) {
      gridApi.forEachNode(node => {
        if (node && node.data && node.data.lineNumber === lineNumber) {
          if (!node.isSelected()) node.setSelected(true)
        }
      })
    }
  } else {
    yield put(
      actions.changeItemGroups.failure({ ...error, api: gridApi }, form)
    )
  }
}

export function* changeItemGroupsGridProcess(form, payload) {
  const {
    value,
    field,
    data: { lineNumber = 0 },
    gridApi
  } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.changeItemGroups.request(form))

  const { response, error } = yield call(api.changeItemGroup, {
    gridName: 'itemGroups',
    guid,
    lineNumber,
    properties: {
      [field]: value
    }
  })

  if (response) {
    yield put(
      actions.changeItemGroups.success({ groups: response, api: gridApi }, form)
    )
  } else {
    yield put(
      actions.changeItemGroups.failure({ ...error, api: gridApi }, form)
    )
  }
}

export default function* itemGroupSagas(form) {
  yield fork(launchAddItemGroupListener, form)
  yield fork(addItemGroupCellListener, form)
  yield fork(itemGroupsTabListener, form)
  yield fork(launchItemGroupsListener, form)
  yield fork(changeItemGroupsListener, form)
  yield fork(cancelItemGroupsListener, form)
  yield fork(deleteItemGroupListener, form)
  yield fork(saveItemGroupsListener, form)
  yield fork(itemGroupsPropertyChangeListener, form)
  yield fork(moveProductToDifferentGroupListener, form)
  yield fork(itemGroupsCellChangedListener, form)
}
