import React, { useState } from 'react'
import { Popover, IconButton, Icon } from '@material-ui/core'
import { BASE_INFORM_URL } from 'services/constants'

const CVVPopover = ({
  displayValue,
  popoverContent,
  popoverContentStyle = { minWidth: 300, padding: 20 },
  isEditing = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <IconButton onClick={handlePopoverOpen} size="small">
        <Icon fontSize="small">{open ? 'close' : 'help'}</Icon>
      </IconButton>
      <Popover open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
        <div style={{ display: 'flex', width: 540, padding: 20 }}>
          <div style={{ marginRight: 15, width: 200 }}>
            <p style={{ margin: 0, fontSize: '1em' }}>
              The CVV Number (&lsquo;Card Verification Value&rsquo;) on your
              credit card or debit card is a 3 digit number on VISA
              <sup>&reg;</sup>, MasterCard<sup>&reg;</sup> and Discover
              <sup>&reg;</sup> branded credit and debit cards. On your American
              Express<sup>&reg;</sup> branded credit or debit card it is a 4
              digit numeric code.
            </p>
          </div>
          <div style={{ width: 300 }}>
            <img
              src={`${BASE_INFORM_URL}/resources/cc-icons/cvv-info.png`}
              alt="CVV Card"
              style={{ maxWidth: '100%' }}
            />
          </div>
        </div>
      </Popover>
    </>
  )
}

export default CVVPopover
