import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { useDispatch } from 'react-redux'
import { removeModal } from 'modals/actions'
import { informBlue } from 'theme/colors'

const BackorderSpawnedMessage = ({ id, data }) => {
  const dispatch = useDispatch()
  const form = data?.form
  const dataId = data?.dataId
  const message = data?.message

  const openSalesOrderScreen = e => {
    dispatch(
      openScreenAction({
        dataId,
        description: 'Sales Order',
        image: 'sales_order_entry_32.png',
        imageFileName16: 'sales_order_entry_16.png',
        name: 'salesOrder',
        newInstance: true,
        read: true,
        route: 'salesorder',
        title: 'Sales Order'
      })
    )

    dispatch(removeModal(form, id))
  }

  return (
    <p style={{ fontSize: '1em' }}>
      <button
        type="button"
        onClick={openSalesOrderScreen}
        style={{ color: informBlue }}
      >
        {message}
      </button>
    </p>
  )
}

export default BackorderSpawnedMessage
