import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'grid'
import { withContext } from 'ddiForm'
import { headerStyle, setGridHeight } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'
import { getIn, getValue, emptyList } from 'utils'
import DistributorsTable from './components/DistributorsTable'

import './styles/master-style.scss'

const getRowNodeId = ({ dataId, description, number }) => {
  const compId = `${dataId}|${description}|${number}`
  return compId
}

const storesCol = [
  {
    field: 'number',
    headerName: 'Number',
    cellClass: 'align-right',
    headerClass: 'align-right',
    maxWidth: 100
  },
  { field: 'description', headerName: 'Location' },
  {
    field: 'quantityAvailable',
    headerName: 'Quantity',
    cellClass: 'align-right',
    headerClass: 'align-right',
    maxWidth: 150
  },
  {
    field: 'rank',
    headerName: 'Rank',
    cellClass: 'text-center',
    headerClass: 'text-center',
    maxWidth: 80
  },
  {
    field: 'dataId',
    headerName: 'Zone',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const JohnstoneNational = ({
  distributors,
  distributorsDisplay,
  height,
  stores
}) => {
  let rowData = stores && stores?.toJS ? stores.toJS() : []
  rowData = Array.isArray(rowData) ? rowData : []

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className="clear">
        <DistributorsTable
          data={distributors.toJS ? distributors.toJS() : []}
          title={distributorsDisplay}
        />
      </div>
      <div className="clear">
        <Grid
          getRowNodeId={getRowNodeId}
          columnDefs={storesCol}
          rowData={rowData}
          title="Stores"
          headerStyle={headerStyle}
          enableSorting
          height={height ? height - 120 : setGridHeight(rowData)}
        />
      </div>
    </div>
  )
}

JohnstoneNational.propTypes = {
  distributors: PropTypes.array.isRequired,
  distributorsDisplay: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  stores: PropTypes.array.isRequired
}

export const contextObj = {
  distributors: form =>
    getValue(form, 'johnstoneNational.distributors', emptyList),
  distributorsDisplay: form =>
    getValue(
      form,
      'johnstoneNational.distributorsDisplay',
      'Distribution Centers'
    ),
  stores: form => getIn(form, 'values.johnstoneNational.stores') || emptyList
}

export default withContext(contextObj)(JohnstoneNational)
