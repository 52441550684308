import { createAsyncRequestTypes } from 'utils'

const WARRANTY_TAG = '@@WARRANTY_TAG@@/'

export const PRINT = createAsyncRequestTypes(`${WARRANTY_TAG}PRINT`)

export const PROPERTY_CHANGE = createAsyncRequestTypes(
  `${WARRANTY_TAG}PROPERTY_CHANGE`
)

export const DELETE_WARRANTY_TAG = createAsyncRequestTypes(
  `${WARRANTY_TAG}DELETE_WARRANTY_TAG`
)

export const SAVE_WARRANTY_TAG = createAsyncRequestTypes(
  `${WARRANTY_TAG}SAVE_WARRANTY_TAG`
)

export const ORIGINAL_INVOICE_SEARCH = createAsyncRequestTypes(
  `${WARRANTY_TAG}ORIGINAL_INVOICE_SEARCH`
)
