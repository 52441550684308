import React from 'react'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getIn, getValue } from 'utils'

const marginRight = { marginRight: 5 }

export const RightFooterButtons = ({
  form,
  hasRecord = false,
  isEditing = false,
  isNew = false,
  newMode = false,
  canEdit = false,
  canDelete = false
}) => {
  return (
    <>
      {hasRecord && canEdit && !isEditing && (
        <DDIButton
          variant="contained"
          label="Edit"
          actionName="lockForEdit"
          preventAutoDisable
          tabIndex={0}
          autoFocus
          style={marginRight}
        />
      )}
      {isEditing && canDelete && (
        <DDIButton
          variant="contained"
          label="Save"
          actionName="saveWarrantyTag"
          preventAutoDisable
          tabIndex={0}
          autoFocus
          style={marginRight}
        />
      )}
      {isEditing && !isNew && (
        <DDIButton
          variant="contained"
          label="Delete"
          actionName="deleteWarrantyTag"
          preventAutoDisable
          tabIndex={0}
          autoFocus
          style={marginRight}
        />
      )}
      {isEditing && (
        <DDIButton
          variant="contained"
          label="Cancel"
          actionName="cancelEdit"
          preventAutoDisable
          tabIndex={0}
          autoFocus
          style={marginRight}
        />
      )}
      <DDIButton
        variant="contained"
        label="Exit"
        actionName="exit"
        preventAutoDisable
        tabIndex={0}
        autoFocus
      />
    </>
  )
}

export default withContext({
  hasRecord: form => getIn(form, 'hasRecord'),
  isEditing: form => getIn(form, 'isEditing'),
  newMode: form => getIn(form, 'newMode'),
  isNew: form => getValue(form, 'isNew', false),
  canEdit: form => getValue(form, 'canEdit', false),
  canDelete: form => getValue(form, 'canDelete', false)
})(RightFooterButtons)
