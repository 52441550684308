/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import FieldsWrapper from 'pages/SalesOrder/components/FieldsWrapper'
import { withContext } from 'ddiForm'
import { getField, getIn, getMeta, is } from 'utils'
import { DDIDatePicker, DDIToggle, DDINumberSpinner } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'
import { debounce } from 'lodash'
import { setField } from 'ddiForm/actions'

import '../styles/quote.scss'

export const Quote = ({
  allowQuoteCreateContract,
  canChangeQuote,
  form,
  dispatch,
  isQuote,
  quote,
  addToPipeline,
  quoteExpansionPanel,
  isNew,
  isEditing
}) => {
  const onToggle = panelState => {
    dispatch(
      storeUIFeatureState(form, {
        feature: 'quoteExpansionPanel',
        featureState: panelState
      })
    )
  }

  const updateProbPercent = debounce(value => {
    dispatch(setField(form, 'quoteProbabilityPercent', value))
  }, 1000)

  return (
    <DDICardWrapper
      title="Quote"
      defaultExpanded={quoteExpansionPanel}
      isExpandable
      expansionDetailsStyle={{
        padding: 10,
        width: '100%'
      }}
      expansionPanelStyle={{
        background: '#f5f7f7'
      }}
      onExpandedCb={onToggle}
    >
      <div className="quote-module">
        <div className="padding-10">
          <DDIToggle
            propertyName="quote"
            label="Quote"
            preventAutoDisable
            disabled={!canChangeQuote}
          />
        </div>
        {quote || isQuote ? (
          <>
            <div className="top-row">
              <DDIToggle
                propertyName="quoteMarginPriceUpdate"
                label="Margin Price Update"
                preventAutoDisable
                disabled={!isEditing}
              />
              <DDIToggle
                propertyName="quoteSuppressFromWeb"
                label="Suppress From Web"
                preventAutoDisable
                disabled={!isEditing}
              />
              <DDIDatePicker
                propertyName="expirationDate"
                label="Expiration Date"
                views={['date']}
                preventAutoDisable
                disabled={!isEditing}
              />
            </div>
            <FieldsWrapper style={{ padding: 0 }}>
              <div className="bottom-row">
                <div
                  // className={{ borderRight: addToPipeline ? 'colored-border' : 'no-border' }}
                  className="colored-border"
                >
                  <DDIToggle
                    propertyName="quoteAddToPipeline"
                    label="Add To Pipeline"
                    preventAutoDisable
                    disabled={!isEditing}
                  />
                </div>
                {addToPipeline ? (
                  <>
                    <div className="colored-border">
                      <div className="center-flex-wrapper">
                        <label className="label-style">Probability %</label>
                        <DDINumberSpinner
                          propertyName="quoteProbabilityPercent"
                          step={1.0}
                          min={0.0}
                          max={100}
                          isField={false}
                          updateValueCallback={updateProbPercent}
                          inputProps={{
                            decimalScale: 2,
                            fontSize: 13,
                            fixedDecimalScale: true,
                            suffix: '%'
                          }}
                          wrapperStyle={{ margin: 0 }}
                          preventAutoDisable
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                    <div className="padding-10">
                      <DDIDatePicker
                        propertyName="quoteExpectedCloseDate"
                        label="Expected Close Date"
                        views={['date']}
                        preventAutoDisable
                        disabled={!isEditing}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </FieldsWrapper>

            {allowQuoteCreateContract && (
              <div className="create-contract-row">
                <DDIToggle
                  propertyName="quoteCreateContract"
                  label="Price Contract"
                  preventAutoDisable
                  disabled={!isEditing}
                />
              </div>
            )}
          </>
        ) : null}
      </div>
    </DDICardWrapper>
  )
}

Quote.propTypes = {
  addToPipeline: PropTypes.bool.isRequired,
  quote: PropTypes.bool.isRequired
}

export const contextObj = {
  addToPipeline: form => getField(form, 'quoteAddToPipeline', false),
  allowQuoteCreateContract: form =>
    getMeta(form, 'allowQuoteCreateContract', false),
  canChangeQuote: form =>
    is.bool(getIn(form, 'values.canChangeQuote'))
      ? getIn(form, 'values.canChangeQuote')
      : true,
  isQuote: form => getIn(form, 'values.isQuote') || false,
  quote: form => getField(form, 'quote', false),
  quoteExpansionPanel: form => getIn(form, 'ui.quoteExpansionPanel') || false,
  isNew: form =>
    is.bool(getIn(form, 'values.isNew')) ? getIn(form, 'values.isNew') : true,
  isEditing: form => form.get('isEditing') || false
}
export default withContext(contextObj)(Quote)
