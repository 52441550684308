import React from 'react'
import { Icon, IconButton } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { is } from 'utils'
import { deleteGridRow as deleteGridRowAction } from 'components/EditableGrid/actions'

const DeleteGridCell = ({ value, node, colDef, data = {}, ...other }) => {
  const dispatch = useDispatch()
  const form = colDef?.cellRendererParams?.form || null
  const propertyName = colDef?.cellRendererParams?.propertyName || null
  const rowId = data?.rowId
  const { rowIndex } = node
  const isEditing = colDef?.cellRendererParams?.isEditing || false

  const deleteGridRow = e => {
    if (
      rowId &&
      rowId !== 'blankrow' &&
      form &&
      propertyName &&
      is.number(rowIndex) &&
      rowId
    ) {
      dispatch(
        deleteGridRowAction(form, {
          propertyName,
          rowId,
          rowIndex
        })
      )
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <IconButton disabled={!isEditing} onClick={deleteGridRow} tabIndex={-1}>
        <Icon
          style={{
            color: !isEditing ? '#999' : '#d9534f',
            display: 'inline-block',
            fontSize: 16
          }}
        >
          cancel
        </Icon>
      </IconButton>
    </div>
  )
}

export default DeleteGridCell
