import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { getIn, is } from 'utils'
import { setField } from 'ddiForm/actions'
import { SET_FIELD } from 'ddiForm/constants'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { addModal, removeModal } from 'modals/actions'

import { openScreen as openScreenAction } from 'pages/Main/actions'
import ChangeInvoiceModal from 'pages/InvoiceInquiry/components/ChangeInvoiceModal/'
import SalesmenCommissionsModal from 'pages/InvoiceInquiry/components/SalesmenCommissionsModal'
import * as api from 'pages/InvoiceInquiry/api'
import * as CONSTANTS from 'pages/InvoiceInquiry/constants'
import * as actions from 'pages/InvoiceInquiry/actions'

export function* getChangeInvoiceMetaProcess(form) {
  const formState = yield select(getFormSelector(form))
  const searchMeta =
    getIn(formState, 'meta.changeInvoiceFields.searchFields') || null

  if (searchMeta) {
    yield fork(openChangeInvoiceModalProcess, form)
    return
  }

  yield put(actions.getChangeInvoiceMeta.request(form))

  const { response, error } = yield call(api.getChangeInvoiceMeta)

  if (response) {
    yield put(actions.getChangeInvoiceMeta.success(response, form))
    yield fork(openChangeInvoiceModalProcess, form)
  } else {
    yield put(actions.getChangeInvoiceMeta.failure(error, form))
  }
}

export function* openChangeInvoiceModalProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  // debugger

  yield put(actions.lockInvoiceRecord.request(form))

  const { response, error } = yield call(api.lockInvoiceRecord, {
    dataId
  })

  if (response) {
    yield put(actions.lockInvoiceRecord.success(response, form))
    yield fork(openChangeInvoiceModalHandler, form)
  } else {
    yield put(actions.lockInvoiceRecord.failure(error, form))
  }
}

export function* unlockInvoiceRecordProcess(form, cb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  // debugger

  yield put(actions.unlockInvoiceRecord.request(form))

  const { response, error } = yield call(api.unlockInvoiceRecord, {
    dataId
  })

  if (response) {
    yield put(actions.unlockInvoiceRecord.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.unlockInvoiceRecord.failure(error, form))
  }
}

export function* unlockInvoiceRecordListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.UNLOCK_INVOICE_RECORD.TRY)

    if (form === formListener) {
      yield fork(unlockInvoiceRecordProcess, form, cb)
    }
  }
}

export function* displaySalesmenCommissionsModal(
  form,
  response,
  parentModalCb = null
) {
  const modalOpts = {
    component: SalesmenCommissionsModal,
    options: {
      width: 1024,
      maxHeight: 1200,
      title: 'Salesmen Commissions',
      data: {
        form,
        response,
        parentModalCb,
        actions: [
          {
            primary: true,
            title: 'OK'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* openChangeInvoiceModalHandler(form) {
  // const formState = yield select(getFormSelector(form))
  // const dataId = getIn(formState, 'fields.dataId.value')

  const modalOpts = {
    component: ChangeInvoiceModal,
    options: {
      width: 800,
      maxHeight: 1200,
      title: 'Change Invoice',
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'SerialNumbers',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.openSerialNumberEditor.try(form, {
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            },
            buttonStyle: { marginRight: 0 },
            disabled: f => {
              const { values } = f
              const canChangeSerialNumbers =
                getIn(values, 'changeInvoice.canChangeSerialNumbers') || false

              return !canChangeSerialNumbers
            }
          },
          {
            primary: true,
            title: 'Salesmen Commissions',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.getSalesmenCommissions.try(form, {
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            },
            buttonStyle: { marginRight: 'auto' }
          },
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.saveInvoiceRecord.try(form, {
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.unlockInvoiceRecord.try(form, {
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* getChangeInvoiceMetaListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_CHANGE_INVOICE_META.TRY)

    if (form === formListener) {
      yield fork(getChangeInvoiceMetaProcess, form)
    }
  }
}

export function* saveInvoiceRecordProcess(form, cb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  let editedFields = getIn(formState, 'editedFields')
  editedFields = editedFields?.toJS ? editedFields.toJS() : []
  // debugger

  const properties = editedFields.reduce((acc, next) => {
    /* 
      changeInvoice.commissionIgnoreMinimums has its own prop change,
      so we explicity do not want to send this value -- SVE 10/5/21
    */
    if (
      next.match(/changeInvoice\./) &&
      next !== 'changeInvoice.commissionIgnoreMinimums'
    ) {
      const key = next.replace('changeInvoice.', '')
      acc[key] = getIn(formState, `fields.${next}.value`)
    }
    return acc
  }, {})

  yield put(actions.saveInvoiceRecord.request(form))

  const { response, error } = yield call(api.saveInvoiceRecord, {
    dataId,
    properties
  })

  if (response) {
    yield put(actions.saveInvoiceRecord.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.saveInvoiceRecord.failure(error, form))
    if (
      error?.status &&
      error.status === 498 &&
      error?.validationErrors &&
      Array.isArray(error.validationErrors)
    ) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* saveInvoiceRecordListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.SAVE_INVOICE_RECORD.TRY)

    if (form === formListener) {
      yield fork(saveInvoiceRecordProcess, form, cb)
    }
  }
}

export function* handleSuppressFromStatementsProcess(form, value) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.handleSuppressFromStatements.request(form))

  const { response, error } = yield call(api.handleSuppressFromStatements, {
    dataId,
    suppressFromStatements: value
  })

  if (response) {
    yield put(actions.handleSuppressFromStatements.success(response, form))
  } else {
    yield put(actions.handleSuppressFromStatements.failure(error, form))
    yield put(setField(form, 'changeInvoice.suppressFromStatements', false))
  }
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(SET_FIELD)

    if (form === formListener) {
      if (propertyName === 'changeInvoice.suppressFromStatements' && value) {
        yield fork(handleSuppressFromStatementsProcess, form, value)
      }

      if (propertyName === 'changeInvoice.commissionIgnoreMinimums') {
        yield fork(changeCommissionPropChangeProcess, form, propertyName, value)
      }
    }
  }
}

export function* changeCommissionPropChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.changeCommissionProperty.request(form))

  if (!propertyName) {
    return
  }

  const propName = propertyName.replace('changeInvoice.', '')
  // debugger

  const { response, error } = yield call(api.changeCommissionPropChange, {
    dataId,
    properties: {
      [propName]: value
    }
  })

  if (response) {
    yield put(actions.changeCommissionProperty.success(response, form))
  } else {
    yield put(actions.changeCommissionProperty.failure(error, form))
  }
}

export function* getSalemenCommissionsProcess(form, parentModalCb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.getSalesmenCommissions.request(form))

  const { response, error } = yield call(api.getSalesmenCommissions, {
    dataId
  })

  if (response) {
    yield put(actions.getSalesmenCommissions.success(response, form))
    yield fork(displaySalesmenCommissionsModal, form, response, parentModalCb)
  } else {
    yield put(actions.getSalesmenCommissions.failure(error, form))
  }
}

export function* getSalesmenCommissionsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.GET_SALESMEN_COMMISSIONS.TRY)

    if (form === formListener) {
      yield fork(getSalemenCommissionsProcess, form, cb)
    }
  }
}

export function* changeCommissionValueProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  const { field, value, lineNumber, gridApi } = payload

  yield put(actions.changeCommissionValue.request(form))
  // debugger

  const { response, error } = yield call(api.changeCommissionValue, {
    dataId,
    lineNumber,
    properties: {
      [field]: value
    }
  })

  if (response) {
    yield put(actions.changeCommissionValue.success(response, form))
    yield fork(tryBeginEditingFocusedCell, gridApi)
  } else {
    yield put(actions.changeCommissionValue.failure(error, form))
  }
}

export function* tryBeginEditingFocusedCell(gridApi = null) {
  if (gridApi && gridApi?.getFocusedCell) {
    const focusedCell = gridApi.getFocusedCell()
    const rowIndex = focusedCell?.rowIndex
    const colId = focusedCell?.column?.colId || ''
    if (is.number(rowIndex)) {
      yield delay(100)
      gridApi.startEditingCell({
        rowIndex,
        colKey: colId
      })
    }
  }
}

export function* changeCommissionValueListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.CHANGE_COMMISSION_VALUE.TRY)

    if (form === formListener) {
      yield fork(changeCommissionValueProcess, form, payload)
    }
  }
}

export function* handleOpenProductFromSalemenCommissionsInterfaceProcess(
  form,
  payload
) {
  const { dataId, modalId, parentModalCb } = payload

  yield put(removeModal(form, modalId))
  yield put(
    actions.unlockInvoiceRecord.try(form, {
      cb: parentModalCb
    })
  )

  yield take(CONSTANTS.UNLOCK_INVOICE_RECORD.SUCCESS)
  yield put(
    openScreenAction({
      activeKey: 'analysis',
      image: 'inventory_product_analysis_16.png',
      route: 'productmaster',
      title: 'Product Analysis',
      groupNames: ['analysis', 'overview'],
      dataId
    })
  )
}

export function* handleOpenProductFromSalemenCommissionsInterfaceListener(
  formListener
) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(
      CONSTANTS.HANDLE_OPEN_PRODDUCT_FROM_SALESMEN_COMMISSIONS_INTERFACE
    )

    if (form === formListener) {
      yield fork(
        handleOpenProductFromSalemenCommissionsInterfaceProcess,
        form,
        payload
      )
    }
  }
}

export default function* changeInvoiceSagas(form) {
  yield fork(getChangeInvoiceMetaListener, form)
  yield fork(unlockInvoiceRecordListener, form)
  yield fork(saveInvoiceRecordListener, form)
  yield fork(setFieldListener, form)
  yield fork(getSalesmenCommissionsListener, form)
  yield fork(changeCommissionValueListener, form)
  yield fork(handleOpenProductFromSalemenCommissionsInterfaceListener, form)
}
