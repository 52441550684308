/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component, Fragment } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import masterStyles from 'theme/masterStyles'
import { Icon } from '@material-ui/core'
import {
  DDIIndexSearch,
  DDIToggle,
  DDITextField,
  DDISelectField
} from 'ddiForm/wrapped'
// import { Label } from 'ddiForm'
import { getIn } from 'utils'
import { maxLength, maxValue } from 'ddiForm/validations'

const Label = memoize(value => {
  return value ? (
    <div
      className="ddi-data-label"
      style={{
        background: '#eaeef0',
        borderRadius: 4,
        color: '#517b9c',
        display: 'inline-block',
        fontSize: 12,
        lineHeight: '16px',
        maxWidth: '100%',
        overflow: 'hidden',
        padding: 5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
      }}
    >
      <Icon
        style={{
          color: '#517b9c',
          fontSize: 16,
          marginRight: 5,
          verticalAlign: 'middle'
        }}
      >
         info
      </Icon>
      {value}
    </div>
  ) : null
})

const mapStateToProps = (state, ownProps) => {
  const formState = getIn(state, 'ddiForm.customerMaster')

  return {
    dataId: getIn(formState, 'fields.taxMatrix.dataId.value') || '',
    isEditing: getIn(formState, 'isEditing') || false,
    isLocked: getIn(formState, 'values.taxMatrix.isLocked') || false,
    shipToDesc: getIn(formState, 'fields.taxMatrix.shipToID.description') || '',
    productIDDesc: getIn(formState, 'fields.taxMatrix.productID.description') || '',
    productLineIDDesc: getIn(formState, 'fields.taxMatrix.productLineID.description') || '',
    productTaxPriceGroupDesc: getIn(formState, 'fields.taxMatrix.productTaxPriceGroup.description') || '',
    priceGroupIDDesc: getIn(formState, 'fields.taxMatrix.priceGroupID.description') || '',
    taxColumns: getIn(formState, 'meta.taxColumns') || []
  }
}

class TaxMatrixModal extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    isLocked: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      configsDisabled: true,
      dataId: props.dataId,
      fieldsDisabled: props.dataId ? true : false, // eslint-disable-line
      isLocked: props.isLocked
    }
  }

  componentDidMount() {
    this.getDisabledFields()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.dataId !== prevState.dataId ||
      nextProps.isLocked !== prevState.isLocked
    ) {
      const interactionUpdate = nextProps.dataId && nextProps.isLocked
      return {
        dataId: nextProps.dataId,
        isLocked: nextProps.isLocked,
        configsDisabled: !interactionUpdate,
        fieldsDisabled: interactionUpdate
      }
    }

    return null
  }

  getDisabledFields = (props = this.props) => {
    const { dataId, isLocked } = props
    if (dataId && isLocked) {
      this.setState({
        configsDisabled: false,
        fieldsDisabled: true
      })
    }
  }

  render() {
    const { configsDisabled, fieldsDisabled } = this.state
    const {
      data: { customerID },
      shipToDesc,
      productIDDesc,
      productLineIDDesc,
      productTaxPriceGroupDesc,
      priceGroupIDDesc,
      taxColumns
    } = this.props

    const taxColumnOptions = taxColumns?.toJS ? taxColumns.toJS().filter(x => x.dataId) : []

    const searchRowStyle = {
      ...masterStyles.searchRow.wrapper,
      marginBottom: 10
    }
    return (
      <div>
        <div style={searchRowStyle}>
          <div style={masterStyles.searchRow.label}>
            <label htmlFor="taxMatrixShipTo" style={{ marginBottom: 0 }}>
              Ship To:
            </label>
          </div>
          <div style={masterStyles.searchRow.input}>
            <DDIIndexSearch
              propertyName="taxMatrix.shipToID"
              id="taxMatrixShipToID"
              searchType="shipTo"
              meta={{
                allowInstantSearch: false,
                allowSearchAll: true,
                hasFilters: false,
                searchType: 'shipTo'
              }}
              parentId={customerID}
              fullWidth
              minWidth="100%"
              includeParent
              disabled={fieldsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={masterStyles.searchRow.description}>
            {Label(shipToDesc)}
          </div>
        </div>

        <div style={searchRowStyle}>
          <div style={masterStyles.searchRow.label}>
            <label htmlFor="taxMatrixProductID" style={{ marginBottom: 0 }}>
              Product:
            </label>
          </div>
          <div style={masterStyles.searchRow.input}>
            <DDIIndexSearch
              propertyName="taxMatrix.productID"
              id="taxMatrixProductID"
              searchType="product"
              meta={{
                allowInstantSearch: true,
                allowSearchAll: false,
                hasFilters: false,
                minimumKeywordLength: 2,
                searchType: 'product'
              }}
              fullWidth
              minWidth="100%"
              includeParent
              disabled={fieldsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={masterStyles.searchRow.description}>
            {Label(productIDDesc)}
          </div>
        </div>

        <div style={searchRowStyle}>
          <div style={masterStyles.searchRow.label}>
            <label htmlFor="taxMatrixProductLineID" style={{ marginBottom: 0 }}>
              Product Line:
            </label>
          </div>
          <div style={masterStyles.searchRow.input}>
            <DDIIndexSearch
              propertyName="taxMatrix.productLineID"
              id="taxMatrixProductLineID"
              searchType="productLine"
              meta={{
                allowInstantSearch: true,
                allowSearchAll: true,
                hasFilters: false,
                minimumKeywordLength: 2,
                searchType: 'productLine'
              }}
              fullWidth
              minWidth="100%"
              includeParent
              disabled={fieldsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={masterStyles.searchRow.description}>
            {Label(productLineIDDesc)}
          </div>
        </div>

        <div style={searchRowStyle}>
          <div style={masterStyles.searchRow.label}>
            <label htmlFor="taxMatrixPriceGroupID" style={{ marginBottom: 0 }}>
              Price Group:
            </label>
          </div>
          <div style={masterStyles.searchRow.input}>
            <DDIIndexSearch
              propertyName="taxMatrix.priceGroupID"
              id="taxMatrixPriceGroupID"
              searchType="priceGroup"
              meta={{
                allowInstantSearch: true,
                allowSearchAll: true,
                hasFilters: false,
                minimumKeywordLength: 2,
                searchType: 'priceGroup'
              }}
              fullWidth
              minWidth="100%"
              includeParent
              disabled={fieldsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={masterStyles.searchRow.description}>
            {Label(priceGroupIDDesc)}
          </div>
        </div>

        <div style={searchRowStyle}>
          <div style={masterStyles.searchRow.label}>
            <label
              htmlFor="taxMatrixProductTaxPriceGroup"
              style={{ marginBottom: 0 }}
            >
              Tax Group:
            </label>
          </div>
          <div style={masterStyles.searchRow.input}>
            <DDIIndexSearch
              propertyName="taxMatrix.productTaxPriceGroup"
              id="taxMatrixProductTaxPriceGroup"
              searchType="productTaxGroup"
              meta={{
                allowInstantSearch: true,
                allowSearchAll: true,
                hasFilters: false,
                minimumKeywordLength: 2,
                searchType: 'productTaxGroup'
              }}
              fullWidth
              minWidth="100%"
              includeParent
              disabled={fieldsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={masterStyles.searchRow.description}>
            {Label(productTaxPriceGroupDesc)}
          </div>
        </div>

        <div
          style={{
            alignItems: 'center',
            background: '#f9f9f9',
            borderRadius: 4,
            boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.35)',
            display: 'flex',
            justifyContent: 'center',
            padding: 10,
            margin: '15px 10px 10px 10px'
          }}
        >
          <div style={{ flex: 1, maxWidth: 150, order: 1 }}>
            <DDIToggle
              propertyName="taxMatrix.taxable"
              label="Taxable"
              disabled={configsDisabled}
              preventAutoDisable
            />
          </div>
          <div
            style={{
              flex: 1,
              marginRight: 15,
              maxWidth: 150,
              order: 2,
              position: 'relative',
              top: 1
            }}
          >
            <DDISelectField
              propertyName="taxMatrix.taxColumn"
              fullWidth
              selectValue="dataId"
              selectDescription="description"
              label="Tax Column"
              values={[
                { dataId: 'All', description: 'All' },
                ...taxColumnOptions
              ]}
              disabled={configsDisabled}
              preventAutoDisable
            />
          </div>
          <div style={{ flex: 1, maxWidth: 150, order: 3 }}>
            <DDITextField
              propertyName="taxMatrix.additionalTaxPercent"
              label="Additional Tax %"
              placeholder="Additional Tax %"
              fullWidth
              style={{ maxWidth: '100%' }}
              disabled={configsDisabled}
              preventAutoDisable
              mask="numeric"
              validate={maxValue(999999999.99)}
              inputProps={{
                decimalScale: 2,
                fixedDecimalScale: true,
                range: [0, 999999999.99]
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TaxMatrixModal)
