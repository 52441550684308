import React from 'react'
import PropTypes from 'prop-types'
import { DDIToggle } from 'ddiForm/wrapped'

export const CustomToggleField = props => {
  if (props.value) {
    props = { ...props, initialValue: props.value }
  }
  return <DDIToggle {...props} />
}

CustomToggleField.propTypes = {
  propertyName: PropTypes.string.isRequired
}

export default CustomToggleField
