import {
  // actionChannel,
  call,
  fork,
  put,
  putResolve,
  select,
  take
} from 'redux-saga/effects'
import memoize from 'memoize-one'
import { api } from 'services'
import * as CONSTANTS from 'pages/ProductMaster/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as MAIN_CONSTANTS from 'pages/Main/constants'
import { fromJS, getIn, plainDeepEqual, trimNonWord, is } from 'utils'
import * as actions from 'pages/ProductMaster/actions'
import {
  changeFormTab,
  tryChangeFormTab,
  updateFormTitle,
  setField
} from 'ddiForm/actions'
import { addModal, hideModal, removeModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import {
  getErrorMessages,
  mapAnalysisTabNames,
  getInventoryNames
} from 'pages/ProductMaster/utils'
import {
  CLEAR_SEARCH,
  EXACT_MATCH_SEARCH
} from 'components/Search/IndexSearch/constants'
import * as BIN_SEARCH_CONSTANTS from 'components/Search/BinSearch/constants'

import {
  getFormSelector,
  getSelectedTabsFromState,
  getMapResponse
} from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import CopyProductModal from 'pages/ProductMaster/components/CopyProductModal'
import UpdateDemandModal from 'pages/ProductMaster/components/UpdateDemandModal'
import notesModalSagas from 'modals/NotesModal/sagas'
import templateSagas from 'components/MasterScreen/Template/sagas'
import corporateFieldUpdatesSagas from 'components/MasterScreen/CorporateFieldUpdates/sagas'

import {
  additionalAuditsDataFlagListener,
  wrapAuditDataAccess
} from 'components/MasterScreen/Audit/sagas'
import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import { ADD } from 'pages/Layout/constants'

import PatternTypeModal from '../components/PatternTypeModal'
import PatternTypeModalActions from '../components/PatternTypeModalActions'
import analysisSagas from './analysisSagas'
import mainSagas, { getTabData } from './main'
// import inventoryPricingSagas from './inventoryPricingSagas'
import optionsAndAccessories from './optionsAndAccessoriesSagas'
import pricingSagas from './pricingSagas'
import assembliesSagas from './assembliesSagas'
import webOptionsSagas from './webOptionsSagas'
import binEditorSagas from './binEditorSagas'

export function* saveArgumentsSaga(form, rest = {}) {
  const { groupNames = [] } = rest
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const templateKey = getIn(formState, 'values.templateKey')
  const showAllAudits = getIn(formState, 'fields.showAllAudits.value') || false
  const auditBinShowAll =
    getIn(formState, 'fields.auditBinShowAll.value') || false
  const inventoryNames = getInventoryNames(
    getIn(formState, 'meta.inventoryNames')
  )
  // const selectedInventoryTabFromState =
  //   getIn(formState, 'values.selectedInventoryTab') || null

  return templateKey
    ? {
        templateKey,
        uomId,
        warehouseId,
        form
      }
    : {
        dataId,
        uomId,
        warehouseId,
        showAll: showAllAudits,
        auditBinShowAll,
        inventoryNames
      }
}

export function* onSaveProductProcess(formState, form = 'productMaster') {
  const dataId = getIn(formState, 'fields.dataId.value')
  const templateKey = getIn(formState, 'values.templateKey')
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''
  const pricingShowGP = getIn(formState, 'values.showGP')
  const groupNames = getSelectedTabsFromState(formState)
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )
  const analysisTabNameToCall = mapAnalysisTabNames(selectedSecondaryTab)
  const editedFields = getIn(formState, 'editedFields')
  const newMode = getIn(formState, 'newMode') || false

  let params
  /*
    note that these fields are all edited via external API
  */
  const skipEditedFields = [
    'productLineId',
    'priceGroupId',
    'statusType',
    'mfgNumber',
    'prices',
    'primaryBin',
    'vendors',
    'purchaseHistoryLinkId',
    'binEditorModal.binAssignments'
  ]

  const notesFields = ['internalNotes', 'webDescription']

  /* need to remove partially filled rowData rows here */
  if (editedFields && Array.isArray(editedFields) && editedFields.length) {
    params = editedFields.reduce((acc, next) => {
      if (!skipEditedFields.includes(next) && !next.match(/bins/gi)) {
        const field = getIn(formState, `fields.${next}`)
        const grid = getIn(formState, `fields.${next}.grid`)
        const isPending = getIn(formState, `fields.${next}.isPending`) || false
        let value = getIn(field, 'rowData') || getIn(field, 'value')

        /* add a return to "Notes" fields for Inform2 */
        if (notesFields.includes(next) && value && typeof value === 'string') {
          value = value.replace(/\n/g, '\r\n')
        }

        /* remove an incomplete row from the rowData here */
        if (grid && isPending && Array.isArray(value)) {
          value = value.slice(0, -1)
        }

        acc[next] = value
      }
      return acc
    }, {})
  }

  /* build the bin data here */
  let bins = []

  // debugger
  if (editedFields.filter(x => x.match(/bins/gi)).length) {
    const binFields = getIn(formState, 'fields.bins')
    // console.log(binFields)
    // debugger
    for (const prop in binFields) {
      if (binFields[prop].rowData && binFields[prop].grid) {
        bins = bins.concat({
          dataId: binFields[prop].warehouseId || warehouseId,
          cannotChangePrimaryBin:
            binFields[prop].cannotChangePrimaryBin || false,
          bins: binFields[prop].rowData.map(
            ({ rowId, lineNumber, ...rest }) => ({ ...rest })
          )
        })
      }
    }
  }

  // debugger

  if (bins.length) {
    params = {
      ...params,
      bins
    }
  }

  // debugger

  const tabIds =
    selectedPrimaryTab === 'analysis'
      ? [selectedPrimaryTab, analysisTabNameToCall]
      : groupNames.map(trimNonWord)

  params = templateKey
    ? {
        dataId,
        templateKey,
        form,
        groupNames: tabIds,
        // groupNames: groupNames.map(trimNonWord),
        pricingShowGP,
        uomId,
        warehouseId,
        properties: { ...params }
      }
    : {
        dataId,
        form,
        groupNames: tabIds,
        // groupNames: groupNames.map(trimNonWord),
        pricingShowGP,
        uomId,
        warehouseId,
        properties: { ...params }
      }

  const transformDataMethod = getIn(
    formState,
    'masterOptions.transformDataBeforeSave'
  )

  if (transformDataMethod && is.fn(transformDataMethod)) {
    params = transformDataMethod(params, formState)
  }
  debugger
  if (!dataId) return

  yield put(actions.saveProduct.request())

  const saveMethod = templateKey ? api.saveTemplate : api.save

  /* 
    overwrite the form name in case we are editing in a modal
    and the form name is like invoiceInquiry.productMaster
  */
  debugger
  const { response, error } = yield call(saveMethod, {
    ...params,
    form: getIn(formState, 'apiAlias') || form
  })

  if (response) {
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })
    const res = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    const title = `Product - ${res.dataId} - ${res.description}`
    yield put(updateFormTitle(form, title))

    if (newMode) {
      yield put(
        actions.setNewMode(form, {
          newMode: !newMode,
          preNewMode: false,
          clearTrackedTabs: true
        })
      )
    }
    yield put({
      meta: { form },
      payload: { ...res },
      type: DDICONSTANTS.SAVE.SUCCESS
    })
  } else {
    yield put({
      meta: { form },
      payload: error,
      type: DDICONSTANTS.SAVE.FAILURE
    })

    // show validation errors
    yield fork(displayValidationErrors, error)
  }
}

export function* deleteProductListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_PRODUCT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(onDeleteProductProcess, form)
    }
  }
}

export function* onDeleteProductProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId =
    getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')
  const description =
    getIn(formState, 'fields.description.value') ||
    getIn(formState, 'values.description')
  // const title = getIn(formState, 'title')

  yield put(actions.deleteProduct.request(form))

  yield call(
    confirmationModal,
    `Are you sure you want to delete Product ${dataId} - "${description}"?`,
    'Delete?'
  )

  const { type } = yield take([CONFIRMED, CANCELED])

  if (type === CONFIRMED) {
    yield put(actions.deleteProduct.request(form))

    const { response, error } = yield call(api.deleteProduct, { dataId })

    if (response) {
      yield put(actions.deleteProduct.success(response, form))
    } else {
      const { status, validationErrors } = error
      yield put(actions.deleteProduct.failure(form))
      if (status && status === 498) {
        const errorMessages = getErrorMessages(validationErrors)
        yield call(warningModal, errorMessages, 'Attention!')
      }
    }
  }
}

export function* toggleAllFieldsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.TOGGLE_ALL_FIELDS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield put(actions.toggleAllFields.success(payload, form))
    }
  }
}

export function* lockForEditListener(formListener) {
  while (true) {
    const action = yield take(MASTER_CONSTANTS.LOCK_FOR_EDIT.TRY)
    const {
      meta: { form },
      payload
    } = action

    /*
      NOTE: isLaunchingBinEditor is a flag for
      allowing bin editing via modal interface
      in Product Analysis > Bins
    */
    debugger
    if (form === formListener && !payload.isLaunchingBinEditor) {
      yield fork(lockForEditProcess, form)
    }
  }
}

export function* lockForEditProcess(form) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (selectedPrimaryTab !== 'setup') {
    yield put(tryChangeFormTab(form, 'setup'))
  }
}

export function* updateBinSearchLabelListener(formListener) {
  while (true) {
    const {
      payload: { propertyName, result, matchFound },
      meta: { form },
      type
    } = yield take([
      BIN_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS,
      BIN_SEARCH_CONSTANTS.CLEAR_SEARCH
    ])
    // debugger

    if (
      form === formListener &&
      propertyName === 'primaryBin' &&
      matchFound &&
      result
    ) {
      yield put(
        actions.setPropertyDescription(form, {
          propertyName,
          description: result.description
        })
      )
    }

    if (
      form === formListener &&
      propertyName === 'primaryBin' &&
      type === BIN_SEARCH_CONSTANTS.CLEAR_SEARCH
    ) {
      yield put(
        actions.setPropertyDescription(form, {
          propertyName,
          description: ''
        })
      )
    }
  }
}

export function* updateIndexSearchLabelListener(formListener) {
  while (true) {
    const {
      payload: { exactMatchResults, propertyName, value },
      meta: { form }
    } = yield take([
      EXACT_MATCH_SEARCH.SUCCESS,
      DDICONSTANTS.SET_FIELD,
      CLEAR_SEARCH
    ])

    if (
      form === formListener &&
      propertyName &&
      exactMatchResults &&
      exactMatchResults.description
    ) {
      if (propertyName === 'fromId') {
        const { dataId, description } = exactMatchResults

        yield put(actions.updateCopyFromSearchDescriptions.request(form))
        const { response, error } = yield call(
          api.getCopyFromSearchDescription,
          { dataId }
        )
        if (response) {
          // update copy product search results
          yield put(
            actions.updateCopyFromSearchDescriptions.success(
              { ...response, description },
              form
            )
          )
        }
      }

      if (propertyName === 'mergeModal.toId') {
        yield fork(getProductUOMProcess, form, exactMatchResults.dataId)
      }

      if (propertyName === 'dataId') {
        /* analysis doesn't always have this data right away */
        yield put(setField(form, 'description', exactMatchResults.description))
      }
    }
  }
}

export function* getProductUOMProcess(form, dataId) {
  yield put(actions.getProductUOM.request(form))

  const { response, error } = yield call(api.getProductUOMData, {
    dataId
  })

  if (response) {
    yield put(actions.getProductUOM.success({ uoms: response }, { form }))
  } else {
    yield put(actions.getProductUOM.failure(error, { form }))
  }
}

export function* createNewProductListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CREATE_NEW_PRODUCT.TRY)
    const {
      meta: { form },
      payload: { modalId = null }
    } = action

    if (form === formListener) {
      yield fork(createNewProductProcess, form, modalId)
    }
  }
}

export function* handleCreateNewCancellation(
  form,
  newMode = false,
  preNewMode = false
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''

  yield put(masterActions.cancelEditAsync.try(form, { recordName: dataId }))

  const cancellationAction = yield take([
    MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
    MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
  ])

  if (cancellationAction.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS) {
    yield fork(createNewProductProcess, form)
  }
}

export function* createNewProductProcess(form, modalId) {
  /* make sure the user is on Setup > Main */
  yield put(changeFormTab(form, 'setup', 'main'))

  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const newMode = getIn(formState, 'newMode') || false
  const preNewMode = getIn(formState, 'preNewMode') || false
  const hasRecord = getIn(formState, 'hasRecord') || false
  const isEditing = getIn(formState, 'isEditing') || false
  const patternType = getIn(formState, 'meta.patternType') || ''
  let allTabs = getIn(formState, 'masterOptions.tabs') || fromJS([])
  allTabs = allTabs?.toJS ? allTabs.toJS() : []

  const tabIds = allTabs
    ?.find(x => x.title === 'Setup')
    ?.tabs?.reduce(
      (acc, next) => {
        if (next.access) {
          acc = acc.concat(next.access)
        }

        return acc
      },
      ['setup']
    ) || ['setup', 'main']

  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  // debugger
  if (isEditing && dataId) {
    yield fork(handleCreateNewCancellation, form, newMode, preNewMode)
    return
  }

  if (hasRecord) {
    // debugger
    yield putResolve(masterActions.resetMasterFields(form))
  }

  if (preNewMode) {
    // debugger
    yield put(actions.createNewProduct.request(form))

    const apiParams = patternType
      ? {
          dataId: getIn(formState, 'fields.patternSetDataId.value') || '',
          referenceId: getIn(formState, 'fields.referenceId.value') || '',
          groupNames: tabIds
        }
      : {
          dataId,
          groupNames: tabIds
        }
    // debugger

    const { response, error } = yield call(api.createNewProduct, apiParams)

    if (response) {
      const mapResponse = getMapResponse({ formState, groupNames: tabIds })
      debugger
      // const res = mapResponse(response, [], formState, groupNames)
      const res = mapResponse({
        response: { groupNames: tabIds, ...response },
        tabIds,
        formState,
        groupNames: tabIds
      })

      const title = res.isNew
        ? `New Product - ${res.dataId}`
        : `Product - ${res.dataId} - ${res.description}`
      yield put(updateFormTitle(form, title))

      debugger
      yield put(actions.createNewProduct.success(res, form))
      yield put(masterActions.getEntityAsync.success(res, form))
      if (modalId) {
        yield put(removeModal(form, modalId))
      }
    } else {
      const { status, validationErrors, message } = error

      yield put(actions.createNewProduct.failure(form))

      if (status) {
        if (status === 496) {
          yield call(warningModal, message, 'Attention!')
        } else if (status === 498) {
          const errorMessages = getErrorMessages(validationErrors)
          yield call(warningModal, errorMessages, 'Attention!')
        }
      }
    }
  } else {
    // debugger
    yield put(updateFormTitle(form, 'New Product'))
    yield put(setField(form, 'dataId', ''))
    yield put(actions.setNewMode(form, { newMode, preNewMode: true }))

    if (patternType && (patternType === 'P' || patternType === 'V')) {
      yield call(openPatternTypeModalProcess, form)
    }
  }
}

export function* openPatternTypeModalProcess(form) {
  const modalOpts = {
    component: PatternTypeModal,
    options: {
      actions: PatternTypeModalActions,
      title: 'Create New Product',
      width: 500,
      maxHeight: '100%',
      disableEnforceFocus: true,
      data: {
        form
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal?.payload?.id
}

export function* openCopyProductModalListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_COPY_PRODUCT_MODAL.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(openCopyProductModalProcess, form)
    }
  }
}

export function* openCopyProductModalProcess(form) {
  const modalOpts = {
    component: CopyProductModal,
    options: {
      data: {
        actions: [
          {
            primary: true,
            title: 'Ok',
            async clickEvent(args, formState, cb) {
              const { id } = args
              try {
                this.props.dispatch(actions.setCopyFrom.try(form))
                await this.props.dispatch(hideModal(form, id))
              } finally {
                cb()
              }
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, formState, cb) {
              const { id } = args
              try {
                this.props.dispatch(actions.cancelCopyFrom(form))
                await this.props.dispatch(hideModal(form, id))
              } finally {
                cb()
              }
            }
          }
        ],
        form
      },
      title: 'Copy Product'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* setCopyFromListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SET_COPY_FROM.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(setCopyFromProcess, form)
    }
  }
}

export function* setCopyFromProcess(form) {
  const formState = yield select(getFormSelector(form))
  const fromId = getIn(formState, 'fields.fromId.value')
  const dataId = getIn(formState, 'fields.dataId.value')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  const groupNames = [selectedPrimaryTab, selectedSecondaryTab]

  yield put(actions.setCopyFrom.request(form))
  yield put(masterActions.resetMasterFields(form))

  const { response, error } = yield call(api.setCopyFrom, {
    dataId,
    fromId,
    groupNames
  })

  if (response) {
    // update for mapResponse logic.
    const mapResponse = getMapResponse({ formState, groupNames })
    const res = mapResponse({ response, groupNames, formState })
    yield put(actions.setCopyFrom.success(res, form))
  } else {
    const { status, validationErrors } = error

    yield put(actions.setCopyFrom.failure(form))

    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export function* templateApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const uomId = getIn(formState, 'fields.selectedUOMId.value') || ''
  const warehouseId = getIn(formState, 'fields.selectedWarehouseId.value') || ''

  return {
    uomId,
    warehouseId
  }
}

export function* openProductScreenFromModaListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_PRODUCT_SCREEN_FROM_MODAL)
    const {
      meta: { form },
      payload: { dataId, warehouseId, groupNames, uomId, startTab }
    } = action

    if (!dataId) return

    if (form === formListener) {
      yield putResolve(hideModal(form))

      /* clear out the master fields, but keep the warehouseId */
      yield put(masterActions.resetMasterFields(form))

      // screen is already open, no need to initialize,
      // call read
      const { response, error } = yield call(api.getProduct, {
        dataId,
        groupNames,
        warehouseId,
        uomId
      })

      if (response) {
        /* this needs a mapResponse update SVE 7/31/19 SVE */
        debugger
        yield put(masterActions.getEntityAsync.success(response, form))
        if (startTab === 'analysis') {
          const formState = yield select(getFormSelector(form))
          const selectedSecondaryTab = getIn(
            formState,
            'masterOptions.selectedSecondaryTab'
          )
          const analysisTabNameToCall = mapAnalysisTabNames(
            selectedSecondaryTab
          )
          yield fork(getTabData, form, [analysisTabNameToCall])
        }
      } else {
        yield put(masterActions.getEntityAsync.failure(error))
      }
    }
  }
}

export function* openScreenListener(formListener) {
  while (true) {
    const action = yield take(MAIN_CONSTANTS.OPEN_SCREEN)
    const { payload } = action

    if (payload && payload.name && payload.name === formListener) {
      yield fork(openScreenProcess, payload)
    }
  }
}

export function* openScreenProcess(payload) {
  const {
    customer,
    name: form,
    startTab,
    dataId = null,
    groupNames = []
  } = payload
  const formState = yield select(getFormSelector(form))
  const currentProduct = getIn(formState, 'values.dataId') || null
  const warehouseId = getIn(formState, 'values.warehouseId') || ''
  const uomId = getIn(formState, 'values.uomId') || ''

  // For now, only use case is opening to Pricing with specific id
  if (startTab && startTab.primary && startTab.primary === 'pricing') {
    yield put(masterActions.resetMasterFields(form))
    yield put(tryChangeFormTab(form, startTab.primary))
    if (customer) {
      yield put(setField(form, 'pricing.customerId', customer))
    }
  }

  if (startTab?.primary === 'analysis') {
    if (currentProduct !== dataId) {
      yield put(masterActions.resetMasterFields(form))

      const { response, error } = yield call(api.getProduct, {
        dataId,
        groupNames,
        warehouseId,
        uomId
      })

      if (response) {
        yield put(masterActions.getEntityAsync.success(response, form))
      } else {
        yield put(masterActions.getEntityAsync.failure(error))
      }
    }
    yield put(tryChangeFormTab(form, startTab.primary, startTab.secondary))
  }
}

export function* onAddListener(formListener) {
  while (true) {
    const action = yield take(ADD)
    const { payload } = action

    if (
      payload.name === formListener &&
      payload.data.propertyName === 'inquire'
    ) {
      yield putResolve(tryChangeFormTab(formListener, 'pricing'))
    }
  }
}

export function* openUpdateDemandListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_DEMAND.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(openUpdateDemandModalProcess, form)

      // if (propertyName === 'jsDetail') {
      //   yield fork(openCorporateFieldUpdatesModal, form)
      // }
    }
  }
}

export function* openUpdateDemandModalProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const warehouseId = getIn(formState, 'values.selectedWarehouseId')

  yield put(actions.getDemand.request(form))
  const { response, error } = yield call(api.openDemand, {
    dataId,
    warehouseId
  })

  if (response) {
    yield putResolve(actions.getDemand.success(response, form))
    const modalOpts = {
      component: UpdateDemandModal,
      options: {
        data: {
          actions: [
            {
              primary: true,
              title: 'Update',
              async clickEvent(args, fs, cb) {
                try {
                  if (this.props.updateDemand) {
                    await this.props.updateDemand(form)
                  }
                } finally {
                  /* never makes it here */
                  cb()
                }
              }
            },
            { primary: true, title: 'Exit', propertyName: 'exitUpdateDemand' }
          ],
          form,
          ...response
        },
        fullScreen: true,
        title: 'Product Demand Update',
        marginTop: '0px',
        maxHeight: '70%',
        maxWidth: '65%',
        width: '65%'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  } else {
    yield put(actions.getDemand.falure(error))
  }
}

export function* auditAccessCheckProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  debugger
  if (dataId) {
    const { response, error } = yield call(api.getTabAccess, {
      form,
      groupName: 'audit'
    })
    debugger

    if (response && response.success) {
      debugger
      yield fork(wrapAuditDataAccess, form)
    } else {
      debugger
      throw new Error(error)
    }
  } else {
    try {
      debugger
      yield fork(wrapAuditDataAccess, form)
    } catch (e) {
      debugger
      throw new Error(e)
    }
  }
}

export const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  if (formParts?.[2] && formParts?.[1]) {
    return {
      childForm: formParts[2],
      parentForm: `${formParts[0]}.${formParts[1]}`
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}

// export function* exitScreenFromModalProcess(form, modalId) {
//   const { childForm, parentForm } = getChildAndParentForm(form)
//   const formState = yield select(getFormSelector(form))
//   const isEditing = getIn(formState, 'isEditing') || false

//   if (isEditing) {
//     yield put({
//       type: MASTER_CONSTANTS.CANCEL_EDIT.TRY,
//       meta: { form }
//     })

//     const result = yield take([
//       MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS,
//       MASTER_CONSTANTS.CANCEL_EDIT.FAILURE
//     ])

//     if (
//       result?.meta?.form &&
//       result?.meta?.form === form &&
//       result?.type === MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS
//     ) {
//       yield put(removeModal(parentForm, modalId))
//     }
//   } else {
//     yield put(removeModal(parentForm, modalId))
//   }
// }

// export function* exitScreenFromModalListener(formListener) {
//   while (true) {
//     const {
//       meta: { form },
//       payload: { modalId }
//     } = yield take(CONSTANTS.EXIT_SCREEN_FROM_MODAL)

//     if (form === formListener) {
//       yield fork(exitScreenFromModalProcess, form, modalId)
//     }
//   }
// }

export default function* sagas(form) {
  yield fork(analysisSagas, form)
  yield fork(mainSagas, form)
  yield fork(optionsAndAccessories, form)
  yield fork(pricingSagas, form)
  yield fork(assembliesSagas, form)
  yield fork(webOptionsSagas, form)
  yield fork(deleteProductListener, form)
  yield fork(toggleAllFieldsListener, form)
  yield fork(lockForEditListener, form)
  yield fork(updateBinSearchLabelListener, form)
  yield fork(updateIndexSearchLabelListener, form)
  yield fork(createNewProductListener, form)
  yield fork(notesModalSagas, form)
  yield fork(openCopyProductModalListener, form)
  yield fork(setCopyFromListener, form)
  yield fork(templateSagas, form, templateApiParams)
  // yield fork(dataIdChangeListener, form)
  yield fork(additionalAuditsDataFlagListener, form)
  yield fork(openProductScreenFromModaListener, form)
  yield fork(binEditorSagas, form)
  yield fork(openScreenListener, form)
  yield fork(onAddListener, form)
  yield fork(openUpdateDemandListener, form)
  yield fork(attachmentsSagas, form)
  yield fork(corporateFieldUpdatesSagas, form, true)
  // yield fork(exitScreenFromModalListener, form)
}
