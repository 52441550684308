import {
  take,
  select,
  call,
  put,
  cancel,
  fork,
  actionChannel
} from 'redux-saga/effects'

import { api } from 'services'
import { getIn, dataURItoBlob } from 'utils'

import FileSaver from 'file-saver'

import { getFormSelector } from 'ddiForm/utils'
import { setField } from 'ddiForm/actions'
import GridCell from './GridCell'
import {
  EXACT_MATCH_SEARCH,
  GET_TOOLTIP,
  PARTIAL_MATCH_SEARCH,
  HANDLE_FILE_UPLOAD,
  HANDLE_FILE_DOWNLOAD
} from './constants'

const getRoute = form => (form.includes('.') ? form.split('.').pop() : form)

export function* exactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)

    // const formState = yield select(getFormSelector(form))

    task = yield call(exactMatchProcess, {
      form,
      ...payload,
      thunk
    })
  }
}

export function* getApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const templateKey = getIn(formState, 'values.templateKey')
  const parentId = getIn(formState, 'values.parent.parentId') || null
  const parentType = getIn(formState, 'values.parent.parentType') || null

  return { dataId, templateKey, parentId, parentType }
}

export function* exactMatchProcess({
  form,
  keyword,
  propertyName,
  documentType,
  ignoreGetApiParams,
  thunk,
  ...rest
}) {
  const formName = getRoute(form)

  let params
  if (!ignoreGetApiParams) {
    const { dataId, templateKey, parentId, parentType } = yield call(
      getApiParams,
      form
    )
    params = templateKey
      ? {
          keyword,
          form: formName,
          templateKey,
          documentType,
          parentId,
          parentType
        }
      : {
          keyword,
          form: formName,
          dataId,
          documentType,
          parentId,
          parentType
        }
  } else {
    params = {
      ...rest,
      form: formName,
      keyword,
      propertyName,
      documentType,
      thunk
    }
  }

  const { response, error } = yield call(api.documentExactMatchSearch, params)
  // on error?
  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
      },
      type: EXACT_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchProcess({
  dataId,
  form,
  keyword,
  propertyName,
  onCellSelected,
  thunk,
  documentType,
  parentType,
  parentId,
  templateKey
}) {
  const params = {
    dataId,
    form: getRoute(form),
    keyword,
    documentType,
    parentType,
    parentId,
    templateKey
  }
  // debugger
  const { response, error } = yield call(api.documentPartialMatchSearch, params)

  if (response) {
    const columnDefs = [
      {
        cellRendererFramework: GridCell,
        cellRendererParams: { onCellSelected },

        field: 'dataId',
        headerName: 'File Name'
      },
      {
        editable: false,
        field: 'typeFriendly',
        headerName: 'Type'
      },
      {
        editable: false,
        field: 'sizeFriendly',
        headerName: 'Size'
      },
      {
        editable: false,
        field: 'dateCreated',
        headerName: 'Created On'
      },
      {
        editable: false,
        field: 'dateModified',
        headerName: 'Modified On'
      }
    ]
    const rowData = response.results || []
    const grid = {
      meta: {},
      rowData,
      columnDefs
    }
    yield put({
      // meta: { form, propertyName },
      meta: { form, thunk },
      payload: {
        grid,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName
        // searchAll
      },
      type: PARTIAL_MATCH_SEARCH.FAILURE,
      error: true
    })
  }
}

export function* partialMatchSearchListener() {
  const channel = yield actionChannel(PARTIAL_MATCH_SEARCH.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    let apiParams
    const {
      payload: {
        keyword,
        propertyName,
        onCellSelected,
        documentType,
        ignoreGetApiParams,
        ...rest
      },
      meta: { form, thunk }
    } = action
    // debugger
    if (!ignoreGetApiParams) {
      const { dataId, templateKey, parentId, parentType } = yield call(
        getApiParams,
        form
      )
      apiParams = templateKey
        ? {
            templateKey,
            form,
            keyword,
            propertyName,
            onCellSelected,
            thunk,
            documentType,
            parentId,
            parentType
          }
        : {
            dataId,
            form,
            keyword,
            propertyName,
            onCellSelected,
            thunk,
            documentType,
            parentId,
            parentType
          }
    } else {
      apiParams = {
        keyword,
        propertyName,
        onCellSelected,
        documentType,
        form,
        thunk,
        ...rest
      }
    }

    // debugger

    if (task) yield cancel(task)
    // console.log(keyword.length, disablePropChange, !disablePropChange || !keyword.length > 0)

    task = yield call(partialMatchProcess, apiParams)
  }
}

export function* getTooltipListener() {
  let task
  while (true) {
    const action = yield take(GET_TOOLTIP.REQUEST)
    const {
      payload,
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    task = yield call(getTooltipProcess, {
      form,
      ...payload,
      thunk
    })
  }
}
export function* getTooltipProcess({
  form,
  thunk,
  propertyName,
  apiName = 'getIndexSearchTooltip',
  documentType,
  recordName,
  ...rest
  // indexSearchType,
  // propertyName,
  // recordName,
}) {
  const route = getRoute(form)

  if (documentType === 'image') {
    const { response, error } = yield call(api.getImageTooltip, {
      form: route,
      fileName: recordName,
      propertyName,
      ...rest
    })

    if (response) {
      yield put({
        meta: { form, thunk },
        payload: {
          summary: {
            title: {
              id: recordName,
              description: recordName
            },
            body: {
              image: response.content,
              imageURL: null
            },
            footer: {
              actions: []
            }
          },
          propertyName
        },
        type: GET_TOOLTIP.SUCCESS
      })
    } else {
      yield put({
        meta: { form, thunk },
        payload: { ...error, propertyName },
        error: true,
        type: GET_TOOLTIP.FAILURE
      })
    }
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        summary: {
          title: {
            id: recordName,
            description: recordName
          },
          body: {
            image: null,
            imageURL: null
          },
          footer: {
            actions: []
          }
        },
        propertyName
      },
      type: GET_TOOLTIP.SUCCESS
    })
  }
}

export function* handleFileUploadProcess(form, payload) {
  const { dataId, templateKey, parentId, parentType } = yield call(
    getApiParams,
    form
  )

  const isContact = form.includes('contact')

  const { content, propertyName, fileName, documentType, base64 } = payload

  const apiParams = templateKey
    ? {
        templateKey,
        fileName,
        content,
        form: isContact ? 'contact' : form,
        documentType,
        parentId,
        parentType
      }
    : {
        dataId,
        fileName,
        content,
        form: isContact ? 'contact' : form,
        documentType,
        parentId,
        parentType
      }

  const { response, error } = yield call(api.documentUpload, apiParams)

  if (response) {
    yield put({
      type: HANDLE_FILE_UPLOAD.SUCCESS,
      payload: {
        ...response,
        propertyName,
        base64
      },
      meta: { form }
    })

    /* routes don't match up here for webCategory */
    const formName = form === 'webCategory' ? 'productWebCategory' : form
    yield put(setField(formName, propertyName, fileName))
  } else {
    yield put({
      type: HANDLE_FILE_UPLOAD.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* handleFileUploadListener() {
  const channel = yield actionChannel(HANDLE_FILE_UPLOAD.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload
    } = action

    if (task) {
      yield cancel(task)
    }

    task = yield call(handleFileUploadProcess, form, payload)
  }
}

export function* handleFileDownloadProcess(form, payload) {
  const { propertyName, fileName, documentType } = payload

  const { response, error } = yield call(api.documentDownload, {
    fileName,
    form,
    documentType
  })

  const mimeTypes = {
    bmp: 'image/bmp',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    rtf: 'application/rtf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    docx:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    doc: 'application/msword',
    csv: 'text/csv',
    txt: 'text/plain'
  }

  if (response) {
    yield put({
      type: HANDLE_FILE_DOWNLOAD.SUCCESS,
      payload: {
        ...response,
        propertyName
      },
      meta: { form }
    })

    const extension = response.fileType
    const mimeType =
      extension && mimeTypes[extension] ? mimeTypes[extension] : ''
    FileSaver.saveAs(
      dataURItoBlob(response.content, { type: mimeType }),
      response.fileName
    )
  } else {
    yield put({
      type: HANDLE_FILE_DOWNLOAD.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* handleFileDownloadListener() {
  const channel = yield actionChannel(HANDLE_FILE_DOWNLOAD.REQUEST)
  let task
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload
    } = action

    if (task) {
      yield cancel(task)
    }

    task = yield call(handleFileDownloadProcess, form, payload)
  }
}

export default function* documentSearchSagas() {
  yield fork(partialMatchSearchListener)
  yield fork(exactMatchSearchListener)
  yield fork(handleFileUploadListener)
  yield fork(handleFileDownloadListener)
  yield fork(getTooltipListener)
}
