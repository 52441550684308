import { createAsyncRequestTypes } from 'utils'

export const MOBILE_PRODUCT_ANALYSIS = '@@MOBILE_PRODUCT_ANALYSIS/'

export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${MOBILE_PRODUCT_ANALYSIS}EXECUTE_INQUIRY`
)

export const CLEAR_PRODUCT_DATA = `${MOBILE_PRODUCT_ANALYSIS}CLEAR_PRODUCT_DATA`

export const SET_ACTIVE_TAB = `${MOBILE_PRODUCT_ANALYSIS}SET_ACTIVE_TAB`

export const HANDLE_PURCHASE_HISTORY_LINK = `${MOBILE_PRODUCT_ANALYSIS}HANDLE_PURCHASE_HISTORY_LINK`
