// @flow
// @flow
import React, { Component, createRef } from 'react'
import Measure from 'react-measure'
// import Measure from 'react-measure'
import shouldUpdate from 'hoc/shouldUpdate'
import { connect } from 'react-redux'
import invariant from 'invariant'
import { debounce, isEqual, transform, isObject } from 'lodash'
import { deepEqual, getIn, withinRange } from 'utils'
import { contentAreaSelector } from './selectors'
import { trimmedCameled } from '../utils'

const whitelist = ['tab', 'tabComponent', 'isEditing', 'searchAreaTouched']

const mapStateToProps = (state, ownProps) => {
  // contentAreaSelector(state, ownProps.form)
  const { form } = ownProps
  const masterOptions = getIn(state, `ddiForm.${form}.masterOptions`)
  const selectedPrimaryTab = getIn(masterOptions, 'selectedPrimaryTab')
  const selectedSecondaryTab = getIn(masterOptions, 'selectedSecondaryTab')
  const tabComponents = getIn(masterOptions, 'tabComponents')
  const tab = `${selectedPrimaryTab}${
    selectedSecondaryTab ? `-${trimmedCameled(selectedSecondaryTab)}` : ''
  }`
  // const tabComponent = getIn(tabComponents, tab)
  // const tabComponent = getIn(getIn(tabComponents, tab), 'component')
  let tabComponent = getIn(tabComponents, tab)
  tabComponent = getIn(tabComponent, 'component')
  tabComponent =
    tabComponent && tabComponent.toJS ? tabComponent.toJS() : tabComponent
  return {
    tabComponent,
    tab
  }
}
const style = {
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
}

const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })
class ContentArea extends Component {
  constructor(...args) {
    super(...args)
    console.log('contentArea rendered mounted', this.props)
    // invariant(
    //   this.props.tab && this.props.tabComponent,
    //   'Must provide tab and tabComponent'
    // )
    this.ref = createRef()
    this.resize = debounce(this.onResize, 500)
  }

  // ref = null
  state = {
    dimensions: {
      width: -1,
      height: -1
    }
    // TabComponent: this.props.tabComponent
  }

  // shouldComponentUpdate(nextProps, nextState) {

  //   const ret = (
  //     this.props.children ||
  //     nextProps.children ||
  //     Object.keys(nextProps).some(
  //       prop =>
  //         whitelist.includes(prop) &&
  //         !deepEqual(this.props[prop], nextProps[prop])
  //     ) ||
  //     Object.keys(nextState.dimensions).some(
  //       key =>
  //         !withinRange(
  //           this.state.dimensions[key],
  //           nextState.dimensions[key],
  //           50
  //         )
  //     )
  //   )
  //   console.log('rendered contentArea should render', ret)
  //   return ret
  // }
  shouldComponentUpdate(nextProps, nextState) {
    const ret =
      this.props.children ||
      nextProps.children ||
      Object.keys(nextProps).some(
        prop =>
          whitelist.includes(prop) &&
          !deepEqual(this.props[prop], nextProps[prop])
      )
    const ret2 = Object.keys(nextState.dimensions).some(
      key =>
        !withinRange(this.state.dimensions[key], nextState.dimensions[key], 50)
    )
    console.log('rendered contentArea should render', ret)
    return ret || ret2
  }

  componentDidUpdate(prevProps) {
    console.log('rendered ContentArea', difference(prevProps, this.props))
  }

  onResize = contentRect => this.setState({ dimensions: contentRect.bounds })

  dispatch = (...args) => this.props.dispatch(...args)
  // componentDidUpdate(prevProps, prevState) {
  //   Object.keys(this.props).forEach(prop => {
  //     if (!deepEqual(this.props[prop], prevProps[prop])) {
  //       console.info(
  //         'contentarea',
  //         prop,
  //         'changed',
  //         prevProps[prop],
  //         '==>',
  //         this.props[prop]
  //       )
  //     }
  //   })
  //   Object.keys(this.state).forEach(prop => {
  //     if (!deepEqual(this.state[prop], prevState[prop])) {
  //       console.info(
  //         'contentarea',
  //         prop,
  //         'state changed',
  //         prevState[prop],
  //         '==>',
  //         this.state[prop]
  //       )
  //     }
  //   })
  // }

  render() {
    const TabComponent = this.props.tabComponent

    const {
      dimensions: { height }
      // TabComponent
    } = this.state

    return (
      <div className="ddiform-content-area" style={style}>
        {TabComponent ? (
          <TabComponent
            height={height}
            isEditing={this.props.isEditing}
            dispatch={this.dispatch}
            form={this.props.form}
            emitter={this.props.emitter}
          />
        ) : null}
      </div>
    )
    // return (
    //   <Measure bounds onResize={this.onResize}>
    //     {({ measureRef }) => (
    //       <div ref={measureRef} style={style}>
    //         {TabComponent ? (
    //           <TabComponent
    //             height={height}
    //             isEditing={this.props.isEditing}
    //             dispatch={this.dispatch}
    //             form={this.props.form}
    //             emitter={this.props.emitter}
    //           />
    //         ) : null}
    //       </div>
    //     )}
    //   </Measure>
    // )
  }
}
// function shouldComponentUpdateWhitelist(nextProps, nextState) {
//   return (
//     this.props.children ||
//     nextProps.children ||
//     Object.keys(nextProps).some(
//       prop =>
//         (whitelist.includes(prop) &&
//           !deepEqual(this.props[prop], nextProps[prop])) ||
//         !deepEqual(this.state, nextState)
//     )
//   )
// }
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ContentArea)
// // const Connected = connect(mapStateToProps, null, null, { forwardRef: true })(ContentArea)
// export default connect(
//   mapStateToProps,
//   null,
//   null,
//   { forwardRef: true }
// )(
//   withContext({
//     isEditing: form => {
//       console.log(form)
//       return form.isEditing
//     }
//   })(ContentArea)
// )
