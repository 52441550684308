import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  delay
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { fromJS, getIn, is } from 'utils'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { displayValidationErrors } from 'ddiForm/sagas'

import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, confirm, canceled, removeModal } from 'modals/actions'
import { CANCELED, CONFIRMED } from 'modals/constants'

import CustomAssemblyDescriptionModal from '../components/CustomAssembly/DescriptionModal'
import CustomAssemblySaveAsModal from '../components/CustomAssembly/SaveAsModal'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { validateSalesOrderProcess } from '.'
import {
  changeGridItem as changeGridItemAPI,
  validateSalesOrder as validateSalesOrderAPI
} from '../api'

export function* openCustomAssemblyDescriptionEditor(
  form,
  data = {},
  lineNumber
) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  lineItems = lineItems?.toJS ? lineItems.toJS() : []
  const description = lineItems.find(x => x.lineNumber === lineNumber)
    ?.description

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const modalOpts = {
    component: CustomAssemblyDescriptionModal,
    options: {
      actions: false,
      title: 'Custom Assembly Description',
      data: {
        form,
        lineNumber,
        isMobile,
        description,
        ...data
      },
      marginTop: '0px',
      maxHeight: '95%',
      width: 400
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* openCustomAssemblySaveAsEditor(form, data = {}, lineNumber) {
  // const formState = yield select(getFormSelector(form))

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const modalOpts = {
    component: CustomAssemblySaveAsModal,
    options: {
      actions: false,
      title: 'Save As',
      data: {
        form,
        lineNumber,
        isMobile,
        ...data
      },
      marginTop: '0px',
      maxHeight: '95%',
      width: 400
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* createCustomAssemblyProcess(form, payload) {
  const {
    action,
    lineNumber,
    properties = {},
    modalId,
    additionalApiParams = {}
  } = payload
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid')

  yield put(actions.createCustomAssembly.request(form))

  const apiParams =
    action === 'initialize'
      ? {
          lineNumber,
          gridName: 'lineItems',
          guid,
          properties: {
            createcustomassemblyproduct: null
          }
        }
      : {
          lineNumber,
          gridName: 'lineItems',
          guid,
          properties,
          ...additionalApiParams
        }

  const { response, error } = yield call(changeGridItemAPI, apiParams)

  if (response) {
    debugger
    yield put(actions.createCustomAssembly.success(response, form))
    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    debugger
    yield put(actions.createCustomAssembly.failure(error, form))

    if (
      error?.status === 499 &&
      error?.data?.modalTitle === 'Custom Assembly Description'
    ) {
      yield fork(
        openCustomAssemblyDescriptionEditor,
        form,
        error.data,
        lineNumber
      )
    } else if (error?.status === 499 && error?.message === 'Save As Meta') {
      if (modalId) {
        yield put(removeModal(form, modalId))
      }
      //   error.data = {
      //     "displayName": "Save Product As",
      //     "maxLength": 20,
      //     "isNewIdAutoGenerated": true
      // }
      yield fork(openCustomAssemblySaveAsEditor, form, error.data, lineNumber)
    } else if (error?.status === 498 && error.validationErrors) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* createCustomAssemblyListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.CREATE_CUSTOM_ASSEMBLY.TRY)

    if (form === formListener) {
      yield fork(createCustomAssemblyProcess, form, payload)
    }
  }
}

export default function* customAssemblySagas(form) {
  yield fork(createCustomAssemblyListener, form)
}
