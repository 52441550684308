import React, { useState } from 'react'
import { Popover, IconButton, Icon } from '@material-ui/core'

const PopoverEditor = ({
  displayValue,
  popoverContent,
  popoverContentStyle = { minWidth: 300, padding: 20 },
  isEditing = false
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const displayIcon = isEditing ? 'edit' : 'visibility'

  return (
    <div style={{ minHeight: 10, position: 'relative', width: '100%' }}>
      <span>{displayValue}</span>
      <span style={{ position: 'absolute', top: -3, right: -22 }}>
        <IconButton onClick={handlePopoverOpen} size="small">
          <Icon fontSize="small">{open ? 'close' : displayIcon}</Icon>
        </IconButton>
        <Popover open={open} anchorEl={anchorEl} onClose={handlePopoverClose}>
          <div style={popoverContentStyle}>{popoverContent}</div>
        </Popover>
      </span>
    </div>
  )
}

export default PopoverEditor
