import { getIn, setIn } from 'utils'
import { Map, fromJS } from 'immutable'
import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
import shortid from 'shortid'
import * as CONSTANTS from './constants'

const mergeModalBehaviors = {
  [CONSTANTS.OPEN_MERGE_MODAL.SUCCESS]: (state, { payload }) => {
    let result = state
    const dataId =
      getIn(result, 'fields.dataId.value') || getIn(result, 'values.dataId')
    const description =
      getIn(result, 'fields.description.value') ||
      getIn(result, 'values.description')

    result = setIn(result, 'fields.mergeModal.isRequesting', false)
    result = setIn(result, 'fields.mergeModal.requestComplete', false)

    result = setIn(
      result,
      'fields.mergeModal.mergeOvernight.value',
      payload.dataId ? 'true' : 'false'
    )

    result = setIn(result, 'fields.mergeModal.dataId.value', dataId)
    result = setIn(result, 'fields.mergeModal.dataId.description', description)

    result = setIn(
      result,
      'fields.mergeModal.toId.value',
      payload.dataId ? payload.dataId : ''
    )

    result = setIn(
      result,
      'values.mergeModal.toIdDescription',
      payload.description ? payload.description : ''
    )

    result = setIn(
      result,
      'fields.mergeModal.toId.displayUOM',
      payload.displayUOM ? payload.displayUOM : ''
    )

    result = setIn(
      result,
      'fields.mergeModal.toId.priceUOM',
      payload.priceUOM ? payload.priceUOM : ''
    )

    return result
  },
  [CONSTANTS.MERGE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.mergeModal.isRequesting', true)
    result = setIn(result, 'fields.mergeModal.requestComplete', false)
    return result
  },
  [CONSTANTS.MERGE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(
      result,
      'fields.mergeModal',
      fromJS({
        isRequesting: false,
        requestComplete: true
      })
    )
    result = setIn(result, 'fields.mergeModal.toId.value', '')
    result = setIn(result, 'values.mergeModal.toIdDescription', '')
    result = setIn(result, 'fields.mergeModal.toId.displayUOM', '')
    result = setIn(result, 'fields.mergeModal.toId.priceUOM', '')
    return result
  },
  [CONSTANTS.DELETE_PENDING_MERGE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(
      result,
      'fields.mergeModal',
      fromJS({
        isRequesting: false,
        requestComplete: true
      })
    )
    result = setIn(result, 'fields.mergeModal.toId.value', '')
    result = setIn(result, 'values.mergeModal.toIdDescription', '')
    result = setIn(result, 'fields.mergeModal.toId.displayUOM', '')
    result = setIn(result, 'fields.mergeModal.toId.priceUOM', '')
    return result
  },
  [CONSTANTS.CLEAR_UOM_FIELDS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.mergeModal.toId.displayUOM', '')
    result = setIn(result, 'fields.mergeModal.toId.priceUOM', '')
    return result
  }
}

export default mergeModalBehaviors
