import {
  RESET_MASTER_FIELDS,
  CANCEL_EDIT,
  LOCK_FOR_EDIT,
  GET_ENTITY
} from 'ddiForm/MasterScreen/constants'

import { REGISTER_FIELD, SET_FIELD, BLUR, SAVE } from 'ddiForm/constants'
import { behaviors as ddiFormBehaviors } from 'ddiForm/reducer'
import { Set } from 'immutable'
import shortid from 'shortid'
import {
  fromJS,
  is,
  setIn,
  makeRowDataUnique,
  getIn,
  toCamelCase,
  deleteIn
} from 'utils'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import auditBehaviors from 'components/MasterScreen/Audit/reducer'
import selectionCriteriaBehaviors from 'components/SelectionCriteriaModal/reducer'
import mergeModalBehaviors from 'modals/MergeModal/reducer'
import notesModalBehaviors from 'modals/NotesModal/reducer'
import templateBehaviors from 'components/MasterScreen/Template/reducer'
import attachmentsBehaviors from 'components/MasterScreen/Attachments/reducer'
import corporateFieldUpdatesBehaviors from 'components/MasterScreen/CorporateFieldUpdates/behaviors'

import { withToggleFetch, withHasRecord, id } from 'ddiForm/reducerEnhancers'
import { compose } from 'redux'
import { mapValuesOverFields } from 'ddiForm/utils'
import {
  updateEditedFields,
  ensureGridInitialization,
  resetMasterFieldsHelper,
  setSelectedOptionGroup,
  handleAnalyisUpdateHelper,
  setUpPrimaryGrids,
  padString,
  removeTrackedTabsFromState,
  resetPatternTypeModal
} from 'pages/ProductMaster/utils'

import * as CONSTANTS from './constants'

export const manageBinApiData = (result, propData, selectedWarehouseId) => {
  let rowData = makeRowDataUnique(propData.bin.data, 'rowId')
  const masterBinsRowData = getIn(
    result,
    `fields.bins.${selectedWarehouseId}.rowData`
  )
    ? getIn(result, `fields.bins.${selectedWarehouseId}.rowData`).toJS()
    : []
  const editedFields = getIn(result, 'editedFields')
    ? getIn(result, 'editedFields').toJS()
    : []

  /*
    if we have edited the bin data for this warehouse
    in Product Master, we need to merge that data
  */

  if (editedFields.includes(`bins.${selectedWarehouseId}`)) {
    rowData = masterBinsRowData.reduce((acc, next) => {
      const apiDataRow = propData.bin.data.find(x => x.dataId === next.dataId)
      if (next.dataId) {
        acc = acc.concat({
          ...next,
          date: apiDataRow && apiDataRow.date ? apiDataRow.date : null,
          isSelected: next.isSelected || false,
          quantity: apiDataRow && apiDataRow.quantity ? apiDataRow.quantity : 0
        })
      }
      return acc
    }, [])
  } else {
    result = setIn(
      result,
      `fields.bins.${selectedWarehouseId}.rowData`,
      fromJS(rowData)
    )
    result = setIn(
      result,
      `fields.bins.${selectedWarehouseId}.dataId`,
      selectedWarehouseId
    )
    result = setIn(result, `fields.bins.${selectedWarehouseId}.grid`, true)
    result = setIn(
      result,
      `fields.bins.${selectedWarehouseId}.emptyRow`,
      fromJS({
        isPrimary: false,
        dataId: null
      })
    )

    result = setIn(
      result,
      `values.bins.${selectedWarehouseId}`,
      fromJS(rowData)
    )
  }

  if (propData.audit) {
    result = setIn(
      result,
      'values.audit',
      fromJS(makeRowDataUnique(propData.audit, 'rowId'))
    )
  }

  /* this means we have not registered this bin grid */
  /* if we have, that means we skip this routine */
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.rowData`,
    fromJS(rowData)
  )
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.dataId`,
    selectedWarehouseId
  )
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.grid`,
    true
  )
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.colDefs`,
    fromJS(propData.bin.meta.columns)
  )
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.emptyRow`,
    fromJS({
      isPrimary: false,
      dataId: null
    })
  )

  result = setIn(
    result,
    `values.analysisBins.${selectedWarehouseId}`,
    fromJS(rowData)
  )

  result = setIn(
    result,
    'fields.analysisBins.additionalDataAvailable',
    propData.additionalDataAvailable
  )
  result = setIn(
    result,
    `fields.analysisBins.${selectedWarehouseId}.canUpdateBins`,
    propData.canUpdateBins
  )

  result = deleteIn(result, 'fields.analysisBins.value')
  result = deleteIn(result, 'fields.analysisBins.bins')
  result = deleteIn(result, 'values.analysisBins.bins')

  return result
}

export const mapOverFields = (state, data, propName = '') => {
  /* should probably check if this is a grid, don't save 'value', save rowData */
  /* this is an array of all the grids I encountered in Product Analysis */
  const grids = [
    'components',
    'allWarehouses',
    'allWarehouses48MonthDemand',
    'currentWarehouse',
    'currentWarehouse48MonthDemand',
    'vendors',
    'allWarehouseTransactions',
    'currentWarehouseTransactions',
    'salesOrders',
    'purchaseOrders',
    'transfers',
    'workOrders',
    'serialNumbers',
    'lots',
    'substitutes'
  ]
  let result = state

  if (
    propName === 'currentWarehouseTransactions' ||
    propName === 'allWarehouseTransactions'
  ) {
    // NOTE: some data have similar values, therefore need to make id - LL
    const newData = makeRowDataUnique(data)
    result = setIn(result, `fields.${propName}.grid`, true)
    result = setIn(result, `fields.${propName}.rowData`, fromJS(newData))
    result = setIn(result, `values.${propName}`, fromJS(newData))
    return result
  }

  if (propName === 'components') {
    result = setIn(result, `values.${propName}`, fromJS(data))
    result = setIn(result, `fields.${propName}.rowData`, fromJS(data))
    return result
  }

  if (is.array(data)) {
    /*
      Look out for priceCost data,
      which can be edited from Setup > Main
      and may be set (and edited) there first
    */

    if (propName === 'priceCost') {
      let priceCost = getIn(result, 'fields.prices.rowData')
      priceCost = priceCost && priceCost.toJS ? priceCost.toJS() : []
      if (!priceCost.length) {
        result = setIn(result, 'values.prices', fromJS(data))
      }
    } else if (propName === 'vendors') {
      result = setIn(result, 'fields.analysisVendors.grid', true)
      result = setIn(result, 'fields.analysisVendors.rowData', fromJS(data))
      result = setIn(result, 'values.analysisVendors', fromJS(data))
    } else {
      result = setIn(result, `values.${propName}`, fromJS(data))
      result = setIn(result, `fields.${propName}.value`, fromJS(data))
      result = setIn(result, `fields.${propName}.rowData`, fromJS(data))

      /* this is important, or rowData will not get cleared when the dataId changes with resetMasterFields */
      if (grids.includes(propName)) {
        result = setIn(result, `fields.${propName}.grid`, true)
      }
    }

    return result
  }
  for (const prop in data) {
    const propData = data[prop]

    if (prop === 'data' || prop === 'selectedWarehouseStatusColor') {
      result = setIn(result, `fields.${propName}.rowData`, fromJS(propData))
      result = setIn(result, `fields.${propName}.value`, fromJS(propData))
      result = setIn(result, `values.${propName}`, fromJS(propData))

      if (grids.includes(propName)) {
        result = setIn(result, `fields.${propName}.grid`, true)
      }
    } else if (!is.null(data[prop]) && is.array(propData)) {
      result = mapOverFields(result, propData, prop)
    } else if (
      (prop === 'salesOrders' || prop === 'serialNumbers') &&
      !is.null(propData)
    ) {
      result = mapOverFields(result, propData, prop)
    } else if (prop === 'inventory') {
      result = setIn(result, 'values.inventoryGrid', fromJS(propData))
    } else if (prop === 'bins' && !is.null(propData)) {
      /* update and track bins */
      const selectedWarehouseId =
        getIn(result, 'fields.selectedWarehouseId.value') ||
        data.selectedWarehouseId

      /* update the Analysis Bins on read */
      result = manageBinApiData(result, propData, selectedWarehouseId)
    } else if (prop === 'specifications') {
      /* data in the specifications object is shared with
        Product Master/Purchasing, so we need to get these
        values from fields if these fields have already been
        registered and potentially edited
      */

      const volume = getIn(result, 'fields.volume.value')
      const weight = getIn(result, 'fields.weight.value')
      const boxQuantity = getIn(result, 'fields.boxQuantity.value')

      result = setIn(result, 'values.volume', volume || propData.volume)
      result = setIn(
        result,
        'values.boxQuantity',
        boxQuantity || propData.boxQuantity
      )
      result = setIn(result, 'values.weight', weight || propData.weight)

      result = setIn(result, 'values.leadDays', propData.leadDays)
      result = setIn(result, 'values.sds', propData.sds)

      const editedFields = getIn(result, 'editedFields')
        ? getIn(result, 'editedFields').toJS()
        : []

      /*
        cycle count is updated by API call per warehouse,
        value is always valid
      */
      result = setIn(result, 'values.cycleCount', propData.cycleCount)
      result = setIn(result, 'fields.cycleCount.value', propData.cycleCount)
    } else if (prop === 'groups') {
      /* same issue as with specifications above^^ */
      for (const key in propData) {
        if (!getIn(result, `fields[${key}].value`)) {
          const properyName = key.slice(0, -2)
          result = setIn(
            result,
            `values.${properyName}`,
            propData[key] ? propData[key] : ''
          )
        }
      }
    } else if (
      !is.null(propData) &&
      !is.array(propData) &&
      typeof propData === 'object'
    ) {
      result = mapOverFields(result, data[prop])
    } else if (prop !== 'bins' && prop !== 'bin') {
      /*
        don't handle the Bins this way^^ we have already covered them
      */

      if (Array.isArray(propData)) {
        /* rowData is everywhere in Redux without checking this */
        result = setIn(result, `fields.${prop}.rowData`, fromJS(propData))
        result = setIn(result, `values.${prop}`, fromJS(propData))
      } else {
        result = setIn(result, `fields.${prop}.value`, fromJS(propData))
        result = setIn(result, `values.${prop}`, fromJS(propData))
      }
    }
  }

  return result
}

export const updatePricesAndRollUps = (state, prices, rollUps) => {
  const priceData = getIn(state, 'fields.prices.rowData').mergeDeep(
    fromJS(prices)
  )
  // const rollUpData = getIn(state, 'fields.rollUps.rowData').mergeDeep(fromJS(rollUps))

  /* update the Prices */
  state = setIn(state, 'values.prices', fromJS(priceData))
  state = setIn(state, 'fields.prices.rowData', fromJS(priceData))

  /*
    update the Roll-Up data, but don't mess with any of valueTypes or operatorTypes
    because the API does not account for that
  */
  if (rollUps && rollUps.length) {
    let rollUpRowData = getIn(state, 'fields.rollUps.rowData')

    /* first make sure that there is rollUpData to work with */
    if (rollUpRowData) {
      for (let i = 0; i < rollUps.length; i++) {
        const rowIndex = i

        rollUpRowData = rollUpRowData.update(rowIndex, data =>
          data.set('amount', rollUps[rowIndex].amount)
        )

        rollUpRowData = rollUpRowData.update(rowIndex, data =>
          data.set('sequenceNumber', rollUps[rowIndex].sequenceNumber)
        )

        rollUpRowData = rollUpRowData.update(rowIndex, data =>
          data.set('resultAmount', rollUps[rowIndex].resultAmount)
        )
      }

      state = setIn(state, 'fields.rollUps.rowData', fromJS(rollUpRowData))
      state = setIn(state, 'values.rollUps', fromJS(rollUpRowData))
    } else {
      /* this means this is the first time getting rollUp data */
      state = setIn(
        state,
        'fields.rollUps.rowData',
        fromJS(makeRowDataUnique(rollUps, 'rowId'))
      )
      state = setIn(
        state,
        'values.rollUps',
        fromJS(makeRowDataUnique(rollUps, 'rowId'))
      )
    }
  }

  return state
}

export const clearIsPendingFlags = state => {
  let fields = getIn(state, 'fields')
  /* for old EditableGrids */
  fields = fields.map(x => {
    if (x.has('isPending')) {
      x = x.set('isPending', false)
    }
    return x
  })

  state = setIn(state, 'fields', fields)

  return state
}

export default {
  [CONSTANTS.GET_ANALYSIS_META.REQUEST]: state =>
    compose(
      withToggleFetch(true),
      id
    )(state),
  [CONSTANTS.GET_ANALYSIS_META.SUCCESS]: compose(
    withToggleFetch(false),
    (state, { payload }) => {
      let result = state
      for (const prop in payload) {
        result = setIn(result, `meta.analysis.${prop}`, fromJS(payload[prop]))
      }
      let meta = getIn(result, 'meta')
      if (!getIn(meta, 'defaultSelectedWarehouseId')) {
        meta = setIn(
          meta,
          'defaultSelectedWarehouseId',
          payload.selectedWarehouseId
        )
      }
      if (payload.searchFields && is.array(payload.searchFields)) {
        payload.searchFields.forEach(x => {
          if (!getIn(meta, x.fieldName)) {
            meta = setIn(meta, x.fieldName, fromJS(x))
          }
        })
      }
      result = setIn(result, 'meta', meta)

      return result
    }
  ),
  [CONSTANTS.GET_ANALYSIS_META.FAILURE]: state => withToggleFetch(false)(state),
  [CONSTANTS.GET_ANALYSIS_READ.REQUEST]: compose(
    withToggleFetch(true),
    (state, action) => {
      let result = state
      result = setIn(result, 'fetchingAnalysisData', true)
      return result
    }
  ),
  [CONSTANTS.GET_ANALYSIS_READ.FAILURE]: compose(
    withToggleFetch(false),
    (state, action) => {
      let result = state
      result = setIn(result, 'fetchingAnalysisData', false)
      return result
    }
  ),
  [CONSTANTS.GET_TAB_DATA.REQUEST]: state =>
    compose(
      withToggleFetch(true),
      id
    )(state),
  [CONSTANTS.GET_TAB_DATA.SUCCESS]: compose(
    withToggleFetch(false),
    (state, { payload }) => {
      let result = state
      result = mapOverFields(result, payload)
      return result
    }
  ),
  [CONSTANTS.GET_TAB_DATA.FAILURE]: state =>
    compose(
      withToggleFetch(false),
      id
    )(state),
  [CONSTANTS.GET_BINS.SUCCESS]: compose(
    withToggleFetch(false),
    (state, { payload }) => {
      let result = state

      /* 
        don't believe this reducer method is being used, 
        but I hesitate to remove it -- SVE 6/23/2021
      */
      debugger
      for (const prop in payload) {
        if (prop === 'bins') {
          const { data } = payload[prop].bin
          const uniqueRow = makeRowDataUnique(data, 'rowId')
          result = setIn(
            result,
            'fields.additionalDataAvailable.value',
            payload[prop].additionalDataAvailable
          )
          result = setIn(
            result,
            'fields.canUpdateBins.value',
            payload[prop].canUpdateBins
          )
          result = setIn(result, 'fields.bin.value', fromJS(uniqueRow))
          result = setIn(result, 'fields.bin.rowData', fromJS(uniqueRow))
        } else {
          result = setIn(result, `values.${prop}`, fromJS(payload[prop]))
          result = setIn(result, `fields.${prop}.value`, fromJS(payload[prop]))
          result = setIn(
            result,
            `fields.${prop}.rowData`,
            fromJS(payload[prop])
          )
        }
      }
      return result
    }
  ),
  [CONSTANTS.GET_DOCSPEC.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'values.docspec', fromJS(payload))
    return result
  },
  [CONSTANTS.GET_PURCHASE_HISTORY.SUCCESS]: (state, { payload }) => {
    let result = state
    const { links } = payload
    if (links) {
      result = setIn(result, 'values.purchaseHistoryLinks', fromJS(links))
    }
    return result
  },
  [CONSTANTS.GET_SDS.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'values.sdsLink', fromJS(payload))
    return result
  },
  [CONSTANTS.CALCULATE_ROLLUP_PRICE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'rollUpCalculationError', false)
    result = setIn(result, 'isPostingRollupData', true)
    return result
  },
  [CONSTANTS.CALCULATE_ROLLUP_PRICE.SUCCESS]: (
    state,
    { payload: { rowIndex, rollUps, prices } }
  ) => {
    let result = state
    let rollUpRowData = getIn(result, 'fields.rollUps.rowData')

    rollUpRowData = rollUpRowData.update(rowIndex, data =>
      data.set('amount', rollUps[rowIndex].amount)
    )

    rollUpRowData = rollUpRowData.update(rowIndex, data =>
      data.set('sequenceNumber', rollUps[rowIndex].sequenceNumber)
    )

    rollUpRowData = rollUpRowData.update(rowIndex, data =>
      data.set('resultAmount', rollUps[rowIndex].resultAmount)
    )

    result = setIn(result, 'fields.rollUps.rowData', rollUpRowData)
    result = setIn(result, 'values.rollUps', rollUpRowData)
    result = setIn(result, 'values.prices', fromJS(prices))
    result = setIn(result, 'rollUpCalculationError', false)
    result = setIn(result, 'isPostingRollupData', false)
    return result
  },
  [CONSTANTS.CALCULATE_ROLLUP_PRICE.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'rollUpCalculationError', true)
    result = setIn(result, 'isPostingRollupData', false)
    return result
  },
  [CONSTANTS.CLEAR_ROLLUP_CALCULATION_ERROR]: (state, action) => {
    let result = state
    result = setIn(result, 'rollUpCalculationError', false)
    return result
  },
  [CONSTANTS.SET_ROLLUP_AMOUNT]: (state, { payload: { rowIndex, value } }) => {
    let result = state
    let rollUpRowData = getIn(result, 'fields.rollUps.rowData')

    rollUpRowData = rollUpRowData.update(rowIndex, data =>
      data.set('amount', value)
    )

    result = setIn(result, 'fields.rollUps.rowData', rollUpRowData)
    result = setIn(result, 'values.rollUps', rollUpRowData)
    return result
  },
  [RESET_MASTER_FIELDS]: (state, action) => {
    let result = state

    /* rollUps && rollUps.rowData was not getting cleared out there */
    /*
      as well as some other grids and data that we need
      to handle separately outside of the base DDIForm flow
    */
    result = masterScreenBehaviors[RESET_MASTER_FIELDS](result)
    result = resetMasterFieldsHelper(result)
    result = setIn(result, 'masterOptions.trackedTabs', Set())
    // result = setIn(result, 'fields.bins.bins', fromJS(gridReducer()))
    return result
  },
  [CONSTANTS.UOM_GRID_DND_UPDATE]: (state, { payload: { rowData } }) => {
    let result = state

    if (rowData && rowData.length) {
      result = setIn(result, 'fields.unitOfMeasures.rowData', fromJS(rowData))
      result = updateEditedFields(result, 'unitOfMeasures')
    }

    return result
  },
  [CONSTANTS.SET_UOM_DEFAULTS]: (state, { payload: { rowIndex } }) => {
    let result = state

    const prevRowIndex = rowIndex - 1
    let rowData = getIn(result, 'fields.unitOfMeasures.rowData')
    const baseUOMId = rowData.get(prevRowIndex).get('dataId')
    const dataId = rowData.get(rowIndex).get('dataId')

    rowData = rowData.update(rowIndex, data => data.set('priceUOMId', dataId))
    rowData = rowData.update(rowIndex, data => data.set('priorPackQuantity', 1))
    rowData = rowData.update(rowIndex, data =>
      data.set('priorPackQuantityAndDataId', `${dataId}         1`)
    )

    if (rowIndex === 0) {
      rowData = rowData.update(rowIndex, data => data.set('baseUOMId', dataId))

      result = setIn(result, 'fields.uomForDisplayId.value', '')
      result = setIn(result, 'fields.uomForPriceId.value', '')
      result = setIn(result, 'fields.uomForPurchasingId.value', '')
      result = setIn(result, 'values.uomForDisplayId', '')
      result = setIn(result, 'values.uomForPriceId', '')
      result = setIn(result, 'values.uomForPurchasingId', '')
    } else {
      rowData = rowData.update(rowIndex, data =>
        data.set('baseUOMId', baseUOMId)
      )
    }

    result = setIn(result, 'fields.unitOfMeasures.rowData', rowData)
    result = setIn(result, 'values.unitOfMeasures', rowData)

    return result
  },
  [CONSTANTS.UPDATE_PRICES.SUCCESS]: (
    state,
    { payload: { rollUps, prices } }
  ) => {
    let result = state

    result = updatePricesAndRollUps(result, prices, rollUps)
    return result
  },
  [CONSTANTS.CHANGE_ROLLUP.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingRollupData', true)
    return result
  },
  [CONSTANTS.CHANGE_ROLLUP.SUCCESS]: (
    state,
    { payload: { rollUps, prices } }
  ) => {
    let result = state
    result = updatePricesAndRollUps(result, prices, rollUps)
    result = setIn(result, 'isPostingRollupData', false)
    return result
  },
  [CONSTANTS.CHANGE_ROLLUP.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingRollupData', false)
    return result
  },
  [CONSTANTS.SAVE_INDEX_SEARCH_DESCRIPTION]: (
    state,
    { payload: { label, value } }
  ) => {
    let result = state
    result = setIn(result, `fields.${label}.value`, value)
    result = setIn(result, `values.${label}`, value)

    /*
      update the edited fields so they are
      not overwritten by API calls
    */
    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.add(label)
    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [CONSTANTS.ENABLE_FORECAST_FREEZE]: (state, { payload: { rowIndex } }) => {
    let result = state

    let warehouseProcurements = getIn(
      result,
      'fields.warehouseProcurements.rowData'
    )

    if (warehouseProcurements && warehouseProcurements.size) {
      warehouseProcurements = warehouseProcurements.update(rowIndex, data =>
        data.set('forecastFreeze', true)
      )

      warehouseProcurements = warehouseProcurements.update(rowIndex, data =>
        data.set('canChangeForecastFreeze', true)
      )

      result = setIn(
        result,
        'fields.warehouseProcurements.rowData',
        warehouseProcurements
      )
      result = setIn(
        result,
        'fields.warehouseProcurements.value',
        warehouseProcurements
      )
    }

    return result
  },
  [CONSTANTS.CHANGE_PRICE_OR_COST.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingPriceOrCost', true)
    return result
  },
  [CONSTANTS.CHANGE_PRICE_OR_COST.SUCCESS]: (
    state,
    { payload: { prices, rollUps } }
  ) => {
    let result = state
    const priceData = getIn(result, 'fields.prices.rowData').mergeDeep(
      fromJS(prices)
    )
    const rollUpData = getIn(result, 'fields.rollUps.rowData').mergeDeep(
      fromJS(rollUps)
    )

    result = setIn(result, 'fields.prices.rowData', fromJS(priceData))
    result = setIn(result, 'values.prices', fromJS(priceData))

    result = setIn(result, 'fields.rollUps.rowData', fromJS(rollUpData))
    result = setIn(result, 'values.rollUps', fromJS(rollUpData))

    result = setIn(result, 'isPostingPriceOrCost', false)
    return result
  },
  [CONSTANTS.CHANGE_PRICE_OR_COST.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPostingPriceOrCost', false)
    return result
  },
  [CONSTANTS.READ_DATA_HELPER]: (state, { payload: { data } }) => {
    let result = state
    const grids = ['warehouseProcurements', 'vendors']
    // debugger
    for (const key in data) {
      if (typeof data[key] === 'object' && Object.keys(data[key]).length) {
        for (const prop in data[key]) {
          if (
            getIn(result, `fields.${key}.${prop}.grid`) ||
            grids.includes(prop)
          ) {
            result = setIn(
              result,
              `fields.${key}.${prop}.rowData`,
              fromJS(data[key][prop])
            )
          } else {
            result = setIn(
              result,
              `fields.${key}.${prop}.value`,
              data[key][prop]
            )
          }
        }
      } else if (data[key] && Array.isArray(data[key])) {
        result = setIn(result, `fields.${key}.rowData`, data[key])
        result = setIn(result, `values.${key}`, data[key])
      } else {
        result = setIn(result, `fields.${key}.value`, data[key])
        result = setIn(result, `values.${key}`, data[key])
      }
    }

    return result
  },
  [CONSTANTS.HANDLE_BIN_DATA]: (
    state,
    {
      payload: {
        allowRevert,
        binData: { warehouseId, bins, cannotChangePrimaryBin }
      }
    }
  ) => {
    let result = state
    const binData = makeRowDataUnique(bins, 'rowId')
    // debugger

    result = setIn(result, `values.bins.${warehouseId}.bins`, fromJS(binData))
    result = setIn(
      result,
      `values.bins.${warehouseId}.cannotChangePrimaryBin`,
      cannotChangePrimaryBin
    )
    result = setIn(
      result,
      `values.bins.${warehouseId}.warehouseId`,
      warehouseId
    )

    // if (!getIn(result, `fields.bins.${warehouseId}`) || allowRevert) {
    result = setIn(
      result,
      `fields.bins.${warehouseId}.rowData`,
      fromJS(binData)
    )
    result = setIn(
      result,
      `fields.bins.${warehouseId}.cannotChangePrimaryBin`,
      cannotChangePrimaryBin
    )
    result = setIn(
      result,
      `fields.bins.${warehouseId}.warehouseId`,
      warehouseId
    )
    result = setIn(result, `fields.bins.${warehouseId}.grid`, true)
    result = setIn(result, `fields.bins.${warehouseId}.isPending`, false)
    result = setIn(
      result,
      `fields.bins.${warehouseId}.emptyRow`,
      fromJS({
        isPrimary: false,
        dataId: null
      })
    )
    // }

    result = deleteIn(result, 'fields.bins.value')
    result = deleteIn(result, 'fields.bins.bins')
    return result
  },
  [CONSTANTS.HANDLE_ANALYSIS_BIN_DATA]: (
    state,
    {
      payload: {
        allowRevert,
        binData: { audit, additionalDataAvailable, bin, canUpdateBins }
      }
    }
  ) => {
    let result = state
    const selectedWarehouseId = getIn(
      result,
      'fields.selectedWarehouseId.value'
    )
    const rowData = makeRowDataUnique(bin.data, 'rowId')

    // debugger
    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.rowData`,
      fromJS(rowData)
    )

    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.dataId`,
      selectedWarehouseId
    )

    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.grid`,
      true
    )

    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.colDefs`,
      fromJS(bin.meta.columns)
    )

    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.emptyRow`,
      fromJS({
        isPrimary: false,
        dataId: null
      })
    )

    result = setIn(
      result,
      `values.analysisBins.${selectedWarehouseId}`,
      fromJS(rowData)
    )

    result = setIn(
      result,
      'fields.analysisBins.additionalDataAvailable',
      bin.additionalDataAvailable
    )

    result = setIn(
      result,
      `fields.analysisBins.${selectedWarehouseId}.canUpdateBins`,
      canUpdateBins
    )

    /* update the nested audits as well */
    if (audit) {
      result = setIn(
        result,
        'values.audit',
        fromJS(makeRowDataUnique(audit, 'rowId'))
      )
    }

    result = deleteIn(result, 'fields.analysisBins.value')
    result = deleteIn(result, 'fields.analysisBins.bins')
    result = deleteIn(result, 'values.analysisBins.bins')

    return result
  },
  [CONSTANTS.SET_PRIMARY_BIN]: (state, action) => {
    let result = state
    const selectedWarehouseId = getIn(
      result,
      'fields.selectedWarehouseId.value'
    )
    const primaryBinId = getIn(result, 'fields.primaryBin.value')
    const primaryBinDescription = getIn(result, 'fields.primaryBin.description')
    let editedFields = getIn(result, 'editedFields') || Set()

    let bins = getIn(result, `fields.bins.${selectedWarehouseId}.rowData`)

    if (bins.size) {
      bins = bins.update(0, data => data.set('isPrimary', true))
      bins = bins.update(0, data => data.set('dataId', primaryBinId))
      bins = bins.update(0, data =>
        data.set('description', primaryBinDescription)
      )
    } else {
      bins = fromJS([
        {
          isPrimary: true,
          dataId: primaryBinId,
          description: primaryBinDescription
        }
      ])
    }

    result = setIn(result, `fields.bins.${selectedWarehouseId}.rowData`, bins)
    result = setIn(result, `values.bins.${selectedWarehouseId}.bins`, bins)
    result = setIn(result, 'fields.primaryBin.value', '')
    result = setIn(result, 'fields.primaryBin.description', '')
    result = setIn(result, 'values.primaryBin')

    editedFields = editedFields.add(`bins.${selectedWarehouseId}`)
    editedFields = editedFields.remove('primaryBin')
    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [CONSTANTS.CANCEL_SET_PRIMARY_BIN]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.primaryBin.value', '')
    result = setIn(result, 'fields.primaryBin.description', '')
    return result
  },
  [CONSTANTS.SET_PRIMARY_BIN_CHECKBOX]: (state, { payload: { rowIndex } }) => {
    let result = state

    const selectedWarehouseId = getIn(
      result,
      'fields.selectedWarehouseId.value'
    )

    let bins = getIn(result, `fields.bins.${selectedWarehouseId}.rowData`)

    if (bins && bins.size) {
      for (let i = 0; i < bins.size; i++) {
        if (i !== rowIndex) {
          bins = bins.update(i, data => data.set('isPrimary', false))
        }
      }
    }

    result = setIn(result, `fields.bins.${selectedWarehouseId}.rowData`, bins)
    result = setIn(result, `values.bins.${selectedWarehouseId}`, bins)

    return result
  },
  [CONSTANTS.FIELD_UPDATE.SUCCESS]: (
    state,
    {
      payload: {
        rollUps,
        prices,
        canChangeToConsumable,
        getWarehouseStatusDescription,
        isActiveOrNonStock,
        selectedWarehouseStatusColor,
        duplicates,
        purchasingHistoryLinkId,
        propertyName
      }
    }
  ) => {
    let result = state

    /*
      this means that either the product line or price group
      was changed and then we hit the update API
    */
    if (
      rollUps &&
      prices &&
      (propertyName === 'productLineId' || propertyName === 'priceGroupId')
    ) {
      result = updatePricesAndRollUps(result, prices, rollUps)
    }

    /* this means that the product 'status' has changed */
    if (propertyName === 'statusType') {
      result = setIn(
        result,
        'values.canChangeToConsumable',
        canChangeToConsumable
      )
      result = setIn(
        result,
        'values.getWarehouseStatusDescription',
        getWarehouseStatusDescription
      )
      result = setIn(result, 'values.isActiveOrNonStock', isActiveOrNonStock)

      result = setIn(
        result,
        'values.selectedWarehouseStatusColor',
        selectedWarehouseStatusColor
      )

      result = setIn(result, 'fields.statusType.errorMessage', null)
    }

    if (
      duplicates &&
      duplicates.length &&
      duplicates[0] &&
      propertyName === 'mfgNumber'
    ) {
      result = setIn(
        result,
        'fields.mfgNumber.value',
        getIn(result, 'fields.mfgNumber.prevValue')
      )
    }

    /*
      if the selected Purchase History Link is invalid,
      purchasingHistoryLinkId comes back as null
    */

    if (propertyName === 'purchaseHistoryLinkId' && !purchasingHistoryLinkId) {
      result = setIn(result, 'fields.purchaseHistoryLinkId.value', '')
      result = setIn(result, 'values.purchaseHistoryLinkId', '')
    }

    return result
  },
  [CONSTANTS.FIELD_UPDATE.FAILURE]: (
    state,
    { payload: { propertyChanges, validationErrors } }
  ) => {
    let result = state

    const propChanges = Object.keys(propertyChanges)

    if (propChanges.length) {
      for (let i = 0; i < propChanges.length; i++) {
        result = setIn(
          result,
          `values.${propChanges[i]}`,
          propertyChanges[propChanges[i]]
        )
      }
    }

    if (validationErrors && validationErrors.length) {
      for (let i = 0; i < validationErrors.length; i++) {
        result = setIn(
          result,
          `fields.${toCamelCase(validationErrors[i].property)}.errorMessage`,
          validationErrors[i].message
        )
      }
    }

    return result
  },
  [CONSTANTS.VALIDATE_UOMS]: (state, action) => {
    let result = state
    const uomFields = ['uomForDisplayId', 'uomForPriceId', 'uomForPurchasingId']
    let unitOfMeasures = getIn(result, 'fields.unitOfMeasures.rowData')
    unitOfMeasures =
      unitOfMeasures && unitOfMeasures.toJS ? unitOfMeasures.toJS() : []

    const uoms = unitOfMeasures.reduce((acc, next) => {
      acc = acc.concat(next.dataId)
      return acc
    }, [])

    for (let i = 0; i < uomFields.length; i++) {
      const value = getIn(result, `fields.${uomFields[i]}.value`)

      if (!uoms.includes(value)) {
        result = setIn(result, `fields.${uomFields[i]}.value`, '')
        result = setIn(result, `fields.${uomFields[i]}.prevValue`, value)
        result = setIn(result, `values.${uomFields[i]}`, '')
      }
    }

    return result
  },
  [CONSTANTS.SET_PRIMARY_VENDOR]: (state, { payload: { rowIndex } }) => {
    let result = state

    let vendors = getIn(result, 'fields.vendors.rowData')

    if (vendors && vendors.size) {
      for (let i = 0; i < vendors.size; i++) {
        if (i !== rowIndex) {
          vendors = vendors.update(i, data => data.set('isPrimary', false))
        }
      }

      result = setIn(result, 'fields.vendors.rowData', fromJS(vendors))
      result = setIn(result, 'values.vendors', fromJS(vendors))
    }

    return result
  },
  [CONSTANTS.SAVE_PRODUCT_ASSEMBLY_NOTES.SUCCESS]: (
    state,
    { payload: { note, propertyName, rowIndex } }
  ) => {
    let result = state
    result = setIn(
      result,
      `fields.${propertyName}.rowData[${rowIndex}].notes`,
      note
    )

    if (note !== '') {
      result = setIn(
        result,
        `fields.${propertyName}.rowData[${rowIndex}].hasNotes`,
        true
      )
    } else {
      result = setIn(
        result,
        `fields.${propertyName}.rowData[${rowIndex}].hasNotes`,
        false
      )
    }

    if (propertyName) {
      result = updateEditedFields(result, propertyName)
    }
    return result
  },
  [CONSTANTS.SET_SELECTED_GROUP]: (
    state,
    { payload: { dataId, accessories } }
  ) => {
    let result = state

    result = setIn(result, 'values.selectedGroup.title', dataId)
    result = setIn(
      result,
      'values.selectedGroup.accessories',
      fromJS(accessories)
    )

    return result
  },
  [CONSTANTS.TOGGLE_CHECK_BOX]: (
    state,
    { payload: { field, propertyName, rowIndex } }
  ) => {
    let result = state
    if (propertyName) {
      result = updateEditedFields(result, propertyName)
    }

    const currentVal = getIn(
      result,
      `fields.${propertyName}.rowData[${rowIndex}].${field}`
    )

    result = setIn(
      result,
      `fields.${propertyName}.rowData[${rowIndex}].${field}`,
      !currentVal
    )
    return result
  },
  [CONSTANTS.DELETE_PRODUCT.SUCCESS]: state => {
    let result = state
    result = setIn(result, 'fields.dataId.value', null)
    result = masterScreenBehaviors[RESET_MASTER_FIELDS](result)
    return result
  },
  [CONSTANTS.TOGGLE_ALL_FIELDS.SUCCESS]: (
    state,
    { payload: { field, propertyName, value } }
  ) => {
    let result = state
    const rowData = getIn(result, `fields.${propertyName}.rowData`)
      .toJS()
      .reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          [field]: value
        })
        return acc
      }, [])
    result = setIn(result, `fields.${propertyName}.rowData`, fromJS(rowData))

    if (propertyName) {
      result = updateEditedFields(result, propertyName)
    }

    return result
  },
  [CONSTANTS.SET_SHOW_PRICE_GP]: state => {
    let result = state
    result = setIn(result, 'values.showGP', false)
    return result
  },
  [CONSTANTS.CREATE_NEW_PRODUCT.SUCCESS]: (state, { payload }) => {
    let result = state
    const values = payload
    const { isNew, groupNames } = values
    let trackedTabs = getIn(result, 'masterOptions.trackedTabs')
      ? getIn(result, 'masterOptions.trackedTabs')
      : Set([])

    groupNames.forEach(name => {
      trackedTabs = trackedTabs.add(toCamelCase(name))
    })
    result = setIn(result, 'masterOptions.trackedTabs', trackedTabs)

    if (isNew) {
      result = setIn(result, 'newMode', isNew)
      result = setIn(result, 'isEditing', true)
    }

    result = setIn(result, 'preNewMode', false)
    result = setIn(result, 'hasRecord', true)

    const skipFields = [
      'prices',
      'rollUps',
      'bins',
      'groupNames',
      'keywords',
      'vendors',
      'substitutes',
      'unitOfMeasures'
    ]

    for (const prop in payload) {
      // debugger
      if (!skipFields.includes(prop)) {
        if (payload[prop] && Array.isArray(payload[prop])) {
          const rowData = makeRowDataUnique(payload[prop], 'rowId')
          result = setIn(result, `fields.${prop}.rowData`, fromJS(rowData))
          result = setIn(result, `fields.${prop}.grid`, true)
          result = setIn(result, `values.${prop}`, fromJS(rowData))
        } else {
          // result = setIn(result, `fields.${prop}.value`, payload[prop])
          result = setIn(result, `values.${prop}`, payload[prop])
        }
      }
    }

    /* some custom stuff required here */
    result = setUpPrimaryGrids(result, payload)

    const keywords =
      payload?.keywords && Array.isArray(payload.keywords)
        ? payload.keywords
        : []

    result = ensureGridInitialization(
      result,
      'keywords',
      makeRowDataUnique(keywords, 'rowId'),
      {
        description: ''
      }
    )

    /* substitutes is null from the API */
    result = ensureGridInitialization(result, 'substitutes', [], {
      dataId: null,
      description: ''
    })

    /* now update any more fields that do not require rowId, emptyRow etc. */
    const fields = mapValuesOverFields(result, getIn(result, 'values'))
    result = setIn(result, 'fields', fields)

    result = resetPatternTypeModal(result)
    return result
  },
  [CONSTANTS.SET_NEW_MODE]: (
    state,
    {
      payload: {
        newMode,
        preNewMode,
        clearDataId = false,
        clearTrackedTabs = false
      }
    }
  ) => {
    let result = state
    result = setIn(result, 'newMode', newMode)
    result = setIn(result, 'preNewMode', preNewMode)

    if (clearDataId) {
      result = setIn(result, 'fields.dataId.value', '')
      result = setIn(result, 'values.dataId', '')
    }

    if (clearTrackedTabs) {
      /* save will end up clearing some data inadvertently */
      result = setIn(result, 'masterOptions.trackedTabs', Set())
    }

    // debugger
    return result
  },
  [CONSTANTS.SET_PROPERTY_VALUE]: (
    state,
    { payload: { propertyName, val } }
  ) => {
    let result = state
    result = setIn(result, `fields.${propertyName}.value`, val)
    return result
  },
  [CONSTANTS.SET_PROPERTY_DESCRIPTION]: (
    state,
    { payload: { propertyName, description } }
  ) => {
    let result = state
    result = setIn(result, `fields.${propertyName}.description`, description)
    return result
  },
  [CONSTANTS.GET_PRODUCT_UOM.SUCCESS]: (state, { payload: { uoms } }) => {
    let result = state
    /* this is for Merge Modal */
    if (uoms && uoms.length && uoms[0] && uoms[0].description) {
      result = setIn(
        result,
        'fields.mergeModal.toId.displayUOM',
        uoms[0].description
      )
      result = setIn(
        result,
        'fields.mergeModal.toId.priceUOM',
        uoms[0].description
      )
    }
    return result
  },
  [CONSTANTS.LAUNCH_ANALYSIS_BINS_EDITOR]: (state, action) => {
    let result = state
    const warehouseId = getIn(result, 'fields.selectedWarehouseId.value')
    const uneditedBins =
      getIn(result, `fields.bins.${warehouseId}.rowData`) ||
      getIn(result, `fields.analysisBins.${warehouseId}.rowData`) ||
      fromJS([])
    // console.log(uneditedBins.toJS())
    // debugger
    result = setIn(result, 'values.uneditedBins', uneditedBins)
    return result
  },
  [CONSTANTS.CANCEL_UPDATE_ANALYSIS_BINS]: (state, action) => {
    let result = state
    const warehouseId = getIn(result, 'fields.selectedWarehouseId.value')
    const bins = getIn(result, 'values.uneditedBins')
    // console.log(bins.toJS())
    // debugger
    if (bins && bins?.toJS) {
      const binData = makeRowDataUnique(bins.toJS(), 'rowId')
      result = setIn(
        result,
        `fields.bins.${warehouseId}.rowData`,
        fromJS(binData)
      )
      result = setIn(result, `values.bins.${warehouseId}`, fromJS(binData))
    }

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.remove(`bins.${warehouseId}`)
    result = setIn(result, 'editedFields', editedFields)

    result = deleteIn(result, 'values.uneditedBins')
    return result
  },
  [CONSTANTS.UPDATE_ANALYSIS_BINS.FAILURE]: (state, action) => {
    let result = state
    const warehouseId = getIn(result, 'fields.selectedWarehouseId.value')
    let bins = getIn(result, 'values.uneditedBins')
    bins = bins?.toJS ? bins.toJS() : []
    bins = fromJS(makeRowDataUnique(bins, 'rowId'))

    result = setIn(result, `fields.bins.${warehouseId}.rowData`, bins)
    result = setIn(result, `values.bins.${warehouseId}`, bins)

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.remove(`bins.${warehouseId}`)
    result = setIn(result, 'editedFields', editedFields)

    result = deleteIn(result, 'values.uneditedBins')
    return result
  },
  [CONSTANTS.UPDATE_ANALYSIS_BINS.SUCCESS]: (state, { payload: { bins } }) => {
    let result = state
    const warehouseId = getIn(result, 'fields.selectedWarehouseId.value')
    const binsRowData = fromJS(makeRowDataUnique(bins, 'rowId'))

    /* update the bins displayed in Product Analysis */
    result = setIn(
      result,
      `fields.analysisBins.${warehouseId}.rowData`,
      binsRowData
    )
    result = setIn(result, `values.analysisBins.${warehouseId}`, binsRowData)

    /* also update the bins displayed in Product Master */
    result = setIn(result, `fields.bins.${warehouseId}.rowData`, binsRowData)
    result = setIn(result, `values.bins.${warehouseId}`, binsRowData)

    return result
  },
  [CANCEL_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state

    result = setIn(
      result,
      'values.getWarehouseStatusDescription',
      payload.getWarehouseStatusDescription
    )

    result = setIn(
      result,
      'values.selectedWarehouseStatusColor',
      fromJS(payload.selectedWarehouseStatusColor)
    )

    result = setIn(
      result,
      'values.canChangeToConsumable',
      payload.canChangeToConsumable
    )

    result = setIn(result, 'values.selectedGroup', fromJS({}))
    result = setIn(result, 'fields.statusType.errorMessage', '')

    /* set the selected accessoryGroup if user goes in or out of edit mode */
    if (payload.accessoryGroups && payload.accessoryGroups.length) {
      result = setSelectedOptionGroup(result, payload.accessoryGroups)
    }

    result = masterScreenBehaviors[CANCEL_EDIT.SUCCESS](result, { payload })

    /* 
      prices (and other grids) do not update correctly on cancelEdit 
      due to their custom nature and placement in the API results
    */
    result = setUpPrimaryGrids(result, payload, true)
    result = clearIsPendingFlags(result)
    result = handleAnalyisUpdateHelper(result, payload)
    result = removeTrackedTabsFromState(result)
    /* 
      important: empty out the tracked tabs sans whatever tabs we are looking at here,
      otherwise we can make changes to one tab, move away from the tab, and cancel in 
      another location. This will result in failure to download the original data when
      returning to that tab -- SVE 8/4/21
    */
    return result
  },
  [LOCK_FOR_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[LOCK_FOR_EDIT.SUCCESS](result, action)

    result = clearIsPendingFlags(result)
    return result
  },
  [GET_ENTITY.SUCCESS]: (state, { payload }) => {
    let result = state
    const { isNew } = payload

    const newPayload = {
      ...payload,
      groupNames:
        payload.groupNames &&
        Array.isArray(payload.groupNames) &&
        payload.groupNames.length
          ? payload.groupNames
          : ['setup', 'main'],
      newMode: (isNew && payload.dataId) || false
    }

    if (
      Object.hasOwnProperty.call(newPayload, 'pricing') &&
      newPayload.pricing === null
    ) {
      delete newPayload.pricing
    }

    /* 
      remove warehouseProcurements from editedFields before running
      GET_ENTITY.SUCCESS
    */
    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.remove('warehouseProcurements')
    result = setIn(result, 'editedFields', editedFields)

    result = masterScreenBehaviors[GET_ENTITY.SUCCESS](result, {
      payload: newPayload
    })

    /* prices and rollUps need to be manually updated here 
       and keyed with rowId etc.
    */
    result = setUpPrimaryGrids(result, payload)

    /* bins.value appears for some reason now */
    /* we need to kill that off or it will effect the save routine */
    result = deleteIn(result, 'bins.value')

    if (payload?.setup?.uomForDisplayId) {
      result = setIn(
        result,
        'values.uomForDisplayId',
        fromJS(payload.setup.uomForDisplayId)
      )
    }

    if (payload?.setup?.uomForPriceId) {
      result = setIn(
        result,
        'values.uomForPriceId',
        fromJS(payload.setup.uomForPriceId)
      )
    }

    if (payload?.setup?.uomForPurchasingId) {
      result = setIn(
        result,
        'values.uomForPurchasingId',
        fromJS(payload.setup.uomForPurchasingId)
      )
    }

    /* handle warehouseId toggle (payload changes shape on changing tab vs changing warehouse) */
    result = handleAnalyisUpdateHelper(result, payload)
    result = setIn(result, 'fetchingAnalysisData', false)

    if (
      payload?.pricing &&
      typeof payload.pricing === 'object' &&
      payload.pricing !== null
    ) {
      for (const prop in payload.pricing) {
        result = setIn(result, `values.${prop}`, fromJS(payload.pricing[prop]))
        result = setIn(
          result,
          `values.pricing.${prop}`,
          fromJS(payload.pricing[prop])
        )
        const fieldsOrLabels = [
          'customerId',
          'customerDescription',
          'shipToId',
          'shipToDescription'
        ]
        if (fieldsOrLabels.includes(prop)) {
          result = setIn(
            result,
            `fields.pricing.${prop}.value`,
            fromJS(payload.pricing[prop])
          )
        }
      }
    }

    return result
  },
  [CONSTANTS.UPDATE_COPY_FROM_SEARCH_DESCRIPTIONS.SUCCESS]: (
    state,
    { payload }
  ) => {
    let result = state
    const { description, displayUOM, priceUOM } = payload
    result = setIn(result, 'values.displayUOM', displayUOM)
    result = setIn(result, 'values.priceUOM', priceUOM)
    result = setIn(result, 'fields.fromIdDescription.value', description)
    result = setIn(result, 'values.fromIdDescription', description)

    return result
  },
  [CONSTANTS.CANCEL_COPY_FROM]: state => {
    let result = state
    result = setIn(result, 'fields.fromId.value', null)
    result = setIn(result, 'values.displayUOM', null)
    result = setIn(result, 'values.priceUOM', null)
    result = setIn(result, 'fields.fromIdDescription.value', null)
    result = setIn(result, 'values.fromIdDescription', null)
    return result
  },
  [CONSTANTS.SET_COPY_FROM.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'isEditing', true)
    result = masterScreenBehaviors[GET_ENTITY.SUCCESS](result, { payload })
    return result
  },
  [CONSTANTS.UPDATE_ROW_DATA]: (state, { payload }) => {
    let result = state
    const { data, propertyName, rowIndex } = payload

    if (propertyName) {
      result = setIn(
        result,
        `fields.${propertyName}.rowData[${rowIndex}]`,
        fromJS(data)
      )
    }
    return result
  },
  [CONSTANTS.VALIDATE_BINS]: (state, { payload: { propertyName } }) => {
    let result = state

    let rowData = getIn(result, `fields.${propertyName}.rowData`)
    let hasSelectedBin = false

    if (rowData.size) {
      for (let i = 0; i < rowData.size; i++) {
        const isPrimary = rowData.get(i).get('isPrimary')
        if (isPrimary) {
          hasSelectedBin = true
          break
        }
      }

      if (!hasSelectedBin) {
        rowData = rowData.update(0, data => data.set('isPrimary', true))
        result = setIn(result, `fields.${propertyName}.rowData`, rowData)
        result = setIn(result, `values.${propertyName}`, rowData)
      }
    }

    return result
  },
  [CONSTANTS.GET_DEMAND.SUCCESS]: (state, { payload }) => {
    let result = state
    const {
      dataId,
      demand,
      uom,
      productDescription,
      selectedWarehouseId,
      warehouseDescription
    } = payload
    result = setIn(result, 'values.updateDemand', fromJS(payload))
    result = setIn(result, 'fields.updateDemand.dataId.value', dataId)
    result = setIn(result, 'fields.updateDemand.demand.rowData', fromJS(demand))
    result = setIn(result, 'fields.updateDemand.demand.grid', true)
    result = setIn(result, 'fields.updateDemand.uom.value', uom)
    result = setIn(
      result,
      'fields.updateDemand.warehouseDescription.value',
      warehouseDescription
    )
    result = setIn(
      result,
      'fields.updateDemand.productDescription.value',
      productDescription
    )
    result = setIn(
      result,
      'fields.updateDemand.selectedWarehouseId.value',
      selectedWarehouseId
    )
    return result
  },
  [CONSTANTS.UPDATE_DEMAND.SUCCESS]: (state, { payload }) => {
    let result = state
    const { demand, productDescription, uom, warehouseDescription } = payload

    result = deleteIn(result, 'fields.updateDemand')

    result = setIn(state, 'fields.updateDemand.demand.grid', true)
    result = setIn(state, 'fields.updateDemand.demand.value', fromJS(demand))
    result = setIn(state, 'fields.updateDemand.demand.rowData', fromJS(demand))
    result = setIn(result, 'fields.updateDemand.uom.value', uom)
    result = setIn(
      result,
      'fields.updateDemand.productDescription.value',
      productDescription
    )
    result = setIn(
      result,
      'fields.updateDemand.warehouseDescription.value',
      warehouseDescription
    )
    return result
  },
  [CONSTANTS.CLEAR_UPDATE_DEMAND_MODAL]: (state, { payload }) => {
    let result = state
    const { propertyName } = payload

    result = setIn(result, 'fields.updateDemand.demand.rowData', fromJS([]))

    if (propertyName === 'updateDemand.dataId') {
      result = setIn(result, 'fields.updateDemand.uom.value', '')
      result = setIn(result, 'fields.updateDemand.productDescription.value', '')
    } else {
      result = setIn(
        result,
        'fields.updateDemand.selectedWarehouseId.value',
        ''
      )
      result = setIn(
        result,
        'fields.updateDemand.warehouseDescription.value',
        ''
      )
    }
    return result
  },
  [CONSTANTS.LOCK_INVENTORY_BIN_ASSIGNMENT.SUCCESS]: (
    state,
    { payload: { items, dataId, warehouseId } }
  ) => {
    let result = state

    const description =
      getIn(result, 'fields.description.value') ||
      getIn(result, 'values.description')

    const hasProductDescription = getIn(
      result,
      'fields.binEditorModal.productDescription.value'
    )

    /* only update the description if this is a first-time lock */
    if (!hasProductDescription) {
      result = setIn(
        result,
        'fields.binEditorModal.productDescription.value',
        description
      )
      result = setIn(
        result,
        'fields.binEditorModal.warehouseDescription.value',
        warehouseId
      )
    }

    result = setIn(
      result,
      'fields.binEditorModal.binAssignments.rowData',
      fromJS(items)
    )
    result = setIn(result, 'fields.binEditorModal.binAssignments.grid', true)
    result = setIn(
      result,
      'values.binEditorModal.binAssignments',
      fromJS(items)
    )

    result = setIn(result, 'fields.binEditorModal.productId.value', dataId)
    result = setIn(
      result,
      'fields.binEditorModal.warehouseId.value',
      warehouseId
    )
    result = setIn(result, 'values.binEditorModal.locked', true)

    return result
  },
  [CONSTANTS.UNLOCK_INVENTORY_BIN_ASSIGNMENT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.binEditorModal.productId.value', '')
    result = setIn(result, 'fields.binEditorModal.productDescription.value', '')

    result = setIn(result, 'fields.binEditorModal.warehouseId.value', '')
    result = setIn(
      result,
      'fields.binEditorModal.warehouseDescription.value',
      ''
    )
    result = setIn(
      result,
      'fields.binEditorModal.binAssignments.rowData',
      fromJS([])
    )
    result = setIn(result, 'values.binEditorModal.locked', false)

    return result
  },
  [CONSTANTS.CHANGE_INVENTORY_BIN_ASSIGNMENT.SUCCESS]: (
    state,
    { payload: { items } }
  ) => {
    let result = state

    result = setIn(
      result,
      'fields.binEditorModal.binAssignments.rowData',
      fromJS(items)
    )
    result = setIn(result, 'fields.binEditorModal.binAssignments.grid', true)
    result = setIn(
      result,
      'values.binEditorModal.binAssignments',
      fromJS(items)
    )

    return result
  },
  [CONSTANTS.SAVE_INVENTORY_BIN_ASSIGNMENT.SUCCESS]: (
    state,
    { payload: { productId, warehouseId, rowData } }
  ) => {
    let result = state
    const dataId = getIn(result, 'fields.dataId.value')
    const selectedWarehouseId = getIn(
      result,
      'fields.selectedWarehouseId.value'
    )

    /* clear the fields in use in the modal & the grid */
    result = setIn(result, 'fields.binEditorModal.warehouseId.value', '')
    result = setIn(
      result,
      'fields.binEditorModal.warehouseDescription.value',
      ''
    )
    result = setIn(result, 'fields.binEditorModal.productId.value', '')
    result = setIn(result, 'fields.binEditorModal.productDescription.value', '')
    result = setIn(
      result,
      'fields.binEditorModal.binAssignments.rowData',
      fromJS([])
    )

    /* only make the other state updates if the user hasn't changed stuff */
    if (dataId === productId && warehouseId === selectedWarehouseId) {
      /* update the Product Master Bin data */
      result = setIn(
        result,
        `fields.bins.${warehouseId}.rowData`,
        fromJS(rowData)
      )
      result = setIn(result, `values.bins.${warehouseId}`, fromJS(rowData))

      /* check and if necessary, update the Analysis Bins data */
      let analysisBins = getIn(
        result,
        `fields.analysisBins.${warehouseId}.rowData`
      )
      analysisBins =
        analysisBins && analysisBins.toJS ? analysisBins.toJS() : []

      if (analysisBins.length) {
        const analsysisBinsRowData = rowData.reduce((acc, next) => {
          const data = analysisBins.find(x => x.dataId === next.dataId)
          acc = acc.concat({
            ...next,
            date: data && data.date ? data.date : null,
            isSelected: next.isSelected || false,
            quantity: data && data.quantity ? data.quantity : 0,
            rowId: data.rowId ? data.rowId : shortid.generate()
          })
          return acc
        }, [])

        result = setIn(
          result,
          `fields.analysisBins.${warehouseId}.rowData`,
          fromJS(analsysisBinsRowData)
        )
      }
    }

    /* we are keeping this modal open now */
    result = setIn(result, 'values.binEditorModal.locked', false)

    return result
  },
  [CONSTANTS.SET_PRIMARY_ASSIGNMENT_BIN]: (
    state,
    { payload: { rowIndex } }
  ) => {
    let result = state

    let bins = getIn(result, 'fields.binEditorModal.binAssignments.rowData')

    if (bins && bins.size) {
      for (let i = 0; i < bins.size; i++) {
        if (i !== rowIndex) {
          bins = bins.update(i, data => data.set('isFromBinPrimary', false))
        } else {
          bins = bins.update(i, data => data.set('isFromBinPrimary', true))
        }
      }
    }

    result = setIn(result, 'fields.binEditorModal.binAssignments.rowData', bins)
    result = setIn(result, 'values.binEditorModal.binAssignments', bins)

    return result
  },
  [CONSTANTS.CLEAR_CUSTOMER_PRICING_FIELDS]: (state, action) => {
    let result = state
    result = setIn(result, 'values.pricing.lastSaleDate', '')
    result = setIn(result, 'values.pricing.lastSalePrice', '')
    result = setIn(result, 'values.pricing.netPrice', '')
    result = setIn(result, 'values.pricing.priceFormula', '')
    result = setIn(result, 'values.pricing.quotedDateAndBy', '')
    result = setIn(result, 'values.pricing.quotedPrice', '')
    result = setIn(
      result,
      'values.pricing.customerCategoryIDAndDescription',
      ''
    )
    result = setIn(result, 'values.pricing.gpPercent', '')

    result = setIn(result, 'values.pricing.shipToId', '')
    result = setIn(result, 'values.pricing.shipToIdDescription', '')

    result = setIn(result, 'fields.pricing.shipToId.value', '')
    result = setIn(result, 'fields.pricing.shipToIdDescription.value', '')

    return result
  },
  [CONSTANTS.CLEAR_VENDORS_IS_PENDING_FLAG]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.vendors.isPending', false)
    return result
  },
  [CONSTANTS.VALIDATE_WAREHOUSE_REPLENISHMENTS.SUCCESS]: (
    state,
    { payload: { rowIndex } }
  ) => {
    let result = state
    let replenishments = getIn(result, 'fields.replenishments.rowData')

    if (replenishments.get(rowIndex).get('fromWarehouseIdErrorMessage')) {
      replenishments = replenishments.update(rowIndex, data =>
        data.set('fromWarehouseIdErrorMessage', '')
      )

      result = setIn(result, 'fields.replenishments.rowData', replenishments)
      result = setIn(result, 'values.replenishments', replenishments)
    }

    return result
  },
  [CONSTANTS.VALIDATE_WAREHOUSE_REPLENISHMENTS.FAILURE]: (
    state,
    { payload: { validationErrors, rowIndex } }
  ) => {
    let result = state

    let replenishments = getIn(result, 'fields.replenishments.rowData')

    if (validationErrors) {
      const errorMessage = validationErrors.reduce((acc, next) => {
        if (next.property === 'fromWarehouseId') {
          acc = acc.concat(next.message)
        }
        return acc
      }, '')

      /*
        not ideal, but we have to handle this based on what the API provides
      */
      if (errorMessage) {
        if (errorMessage.match(/physical warehouse/gi)) {
          replenishments = replenishments.update(rowIndex, data =>
            data.set('fromWarehouseId', '')
          )
        } else {
          replenishments = replenishments.update(rowIndex, data =>
            data.set('fromWarehouseIdErrorMessage', errorMessage)
          )
        }
        result = setIn(result, 'fields.replenishments.rowData', replenishments)
        result = setIn(result, 'values.replenishments', replenishments)
      }
    }

    return result
  },
  [CONSTANTS.REMOVE_TRACKED_TABS]: (state, { payload: { tabs = [] } }) => {
    let result = state
    let trackedTabs = getIn(result, 'masterOptions.trackedTabs')

    for (let i = 0; i < tabs.length; i++) {
      trackedTabs = trackedTabs.remove(tabs[i])
    }

    result = setIn(result, 'masterOptions.trackedTabs', trackedTabs)

    return result
  },
  [CONSTANTS.UPDATE_EDITED_FIELDS]: (state, { payload: { field } }) => {
    let result = state

    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.add(field)
    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [REGISTER_FIELD]: (state, { payload: { propertyName } }) => {
    let result = state

    /*
      this is a fix for something unaccounted for in REGISTER_FIELD...
      when the screen is opened with a dataId, the field is not yet
      registered, so the data is only stored in 'values', not as the
      value of the field itself. Noticed that while opening Product
      Master from Sales Order -- SVE 11/21/2019
    */
    const complexFields = ['bins', 'prices', 'rollUps', 'vendors']
    const value = getIn(state, `values.setup.${propertyName}`)
    if (
      !complexFields.includes(propertyName) &&
      value &&
      typeof value !== 'object'
    ) {
      result = setIn(result, `fields.${propertyName}.value`, fromJS(value))
    }

    return result
  },
  [CONSTANTS.SAVE_VENDORS_DATA.SUCCESS]: (
    state,
    { payload: { vendors = [] } }
  ) => {
    let result = state

    const vendorsData = makeRowDataUnique(vendors, 'rowId')
    result = setIn(result, 'fields.vendors.rowData', fromJS(vendorsData))
    result = setIn(result, 'values.vendors', fromJS(vendorsData))

    return result
  },
  [CONSTANTS.CANCEL_PRE_NEW_MODE]: (state, action) => {
    let result = state
    result = resetMasterFieldsHelper(result)

    result = setIn(result, 'fields.dataId.value', '')
    result = setIn(result, 'values.dataId', '')
    result = setIn(result, 'newMode', false)
    result = setIn(result, 'preNewMode', false)
    result = resetPatternTypeModal(result)

    return result
  },
  [CONSTANTS.SET_AUTO_ADD_TO_SO]: (state, { payload: { rowIds = [] } }) => {
    let result = state

    let accessoryGroups = getIn(result, 'fields.accessoryGroups.rowData')
    accessoryGroups =
      accessoryGroups && accessoryGroups.toJS ? accessoryGroups.toJS() : []
    accessoryGroups = accessoryGroups.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        autoAddToSO: Boolean(rowIds.includes(next.rowId))
      })
      return acc
    }, [])

    result = setIn(
      result,
      'fields.accessoryGroups.rowData',
      fromJS(accessoryGroups)
    )
    result = setIn(result, 'values.accessoryGroups', fromJS(accessoryGroups))
    let editedFields = getIn(result, 'editedFields') || Set()
    editedFields = editedFields.add('accessoryGroups')
    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [BLUR]: (state, { payload: { propertyName, value } }) => {
    let result = state

    if (propertyName === 'sortSequenceNumber' && value) {
      /* pad the sort sequence number with 000s */
      const strLength = value?.toString()?.length || 0
      if (strLength < 10 && value?.toString) {
        const newValue = padString(value.toString(), 10, '0')
        result = setIn(result, `fields.${propertyName}.value`, newValue)
        result = setIn(result, `values.${propertyName}`, newValue)
      }
    }

    return result
  },
  [SET_FIELD]: (state, { payload: { propertyName, value, results } }) => {
    let result = state

    if (
      propertyName === 'selectedWarehouseId' ||
      propertyName === 'selectedUOMId'
    ) {
      /*
        if the user has changed either the warhouseId OR the uomId,
        we need to let the system know that we need a fresh data fetch
      */
      result = removeTrackedTabsFromState(result)
    }

    if (propertyName === 'referenceId') {
      if (value && results?.description) {
        result = setIn(
          result,
          'values.referenceDescription',
          results.description
        )
      } else {
        result = setIn(result, 'values.referenceDescription', '')
      }
    }

    /* start update index search labels */
    const searchInputs = [
      'productLineId',
      'priceGroupId',
      'buyLineId',
      'consignmentVendorId',
      'hazMatId',
      'cycleCountGroupId',
      'productLabelId',
      'taxGroup',
      'jsWebNode'
    ]

    if (searchInputs.includes(propertyName)) {
      const label = `${
        propertyName && propertyName?.replace
          ? propertyName.replace('Id', '')
          : ''
      }Description`
      if (value && results?.description) {
        result = setIn(result, `fields.${label}.value`, results.description)
        result = setIn(result, `values.${label}`, results.description)
      } else {
        result = setIn(result, `fields.${label}.value`, '')
        result = setIn(result, `values.${label}`, '')
      }

      let editedFields = getIn(result, 'editedFields') || Set()
      editedFields = editedFields.add(label)
      result = setIn(result, 'editedFields', editedFields)
    }

    const pricingSearchInputs = ['pricing.customerId', 'pricing.shipToId']
    if (pricingSearchInputs.includes(propertyName)) {
      const label =
        propertyName === 'pricing.customerId'
          ? 'pricing.customerDescription'
          : 'pricing.shipToDescription'
      if (value && results?.description) {
        result = setIn(result, `fields.${label}.value`, results.description)
        result = setIn(result, `values.${label}`, results.description)
      } else {
        result = setIn(result, `fields.${label}.value`, '')
        result = setIn(result, `values.${label}`, '')
      }

      let editedFields = getIn(result, 'editedFields') || Set()
      editedFields = editedFields.add(label)
      result = setIn(result, 'editedFields', editedFields)
    }
    /* end update index search labels */

    return result
  },
  [SAVE.SUCCESS]: (state, { payload }) => {
    let result = state

    // debugger
    result = ddiFormBehaviors[SAVE.SUCCESS](result, {
      payload
    })

    /* save will end up clearing some data inadvertently */
    result = removeTrackedTabsFromState(result)
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.ON_PROPERTY_CHANGE.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SET_SELECTED_INVENTORY_TAB]: (state, { payload: { tab } }) => {
    let result = state
    result = setIn(result, 'values.selectedInventoryTab', tab)
    return result
  },
  ...auditBehaviors,
  ...editableGridBehaviors,
  ...selectionCriteriaBehaviors,
  ...mergeModalBehaviors,
  ...notesModalBehaviors,
  ...templateBehaviors,
  ...attachmentsBehaviors,
  ...corporateFieldUpdatesBehaviors(true)
}
