import React, { useEffect, useState } from 'react'
import { withContext, GridField } from 'ddiForm'
import { getIn, emptyList, is } from 'utils'
import shortid from 'shortid'
import withDimensions from 'hoc/withDimensions'
import ErrorBoundary from 'components/ErrorBoundary'

import usePrevious from 'hooks/usePrevious'
import { headerStyle } from 'pages/VendorMaster/utils'

import CheckboxCell from './components/grids/components/CheckboxCell'
import CheckboxHeaderCell from './components/grids/components/CheckboxHeaderCell'

const Warranty = ({
  form,
  height,
  warrantyFailCodes,
  warrantyRequirements,
  isEditing,
  ...rest
}) => {
  const prevIsEditing = usePrevious(isEditing)
  const [requirementsGridKey, resetRequirementsGridKey] = useState(
    shortid.generate()
  )
  const [failureGridKey, resetFailureGridKey] = useState(shortid.generate())

  useEffect(() => {
    /* annoyingly, gridField does not update on isEditing flip */

    if (
      is.bool(isEditing) &&
      is.bool(prevIsEditing) &&
      isEditing !== prevIsEditing
    ) {
      resetRequirementsGridKey(shortid.generate())
      resetFailureGridKey(shortid.generate())
    }
  }, [isEditing, prevIsEditing])

  const requirementsGridColumnDefs = [
    {
      colId: 'description',
      field: 'description',
      headerName: 'Required'
    },
    {
      colId: 'partRequired',
      field: 'partRequired',
      headerName: 'Part',
      headerClass: 'text-center align-center',
      headerComponentFramework: CheckboxHeaderCell,
      headerComponentParams: {
        propertyName: 'warrantyRequirements',
        form,
        isEditing,
        rowData:
          warrantyRequirements && warrantyRequirements?.toJS
            ? warrantyRequirements.toJS()
            : []
      },
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        propertyName: 'warrantyRequirements',
        form,
        isEditing
      },
      maxWidth: 150
    },
    {
      colId: 'equipmentRequired',
      field: 'equipmentRequired',
      headerName: 'Equipment',
      headerClass: 'text-center align-center',
      headerComponentFramework: CheckboxHeaderCell,
      headerComponentParams: {
        propertyName: 'warrantyRequirements',
        form,
        isEditing,
        rowData:
          warrantyRequirements && warrantyRequirements?.toJS
            ? warrantyRequirements.toJS()
            : []
      },
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        propertyName: 'warrantyRequirements',
        form,
        isEditing
      },
      maxWidth: 150
    }
  ]

  const failureReasonsGridColumnDefs = [
    {
      colId: 'isSelected',
      field: 'isSelected',
      headerName: 'Valid',
      headerClass: 'text-center align-center',
      cellRendererFramework: CheckboxCell,
      cellRendererParams: {
        propertyName: 'warrantyFailCodes',
        form,
        isEditing
      },
      headerComponentFramework: CheckboxHeaderCell,
      headerComponentParams: {
        propertyName: 'warrantyFailCodes',
        form,
        isEditing,
        rowData:
          warrantyFailCodes && warrantyFailCodes?.toJS
            ? warrantyFailCodes.toJS()
            : []
      },
      maxWidth: 75
    },
    {
      colId: 'dataId',
      field: 'dataId',
      headerName: 'Code'
    },
    {
      colId: 'description',
      field: 'description',
      headerName: 'Reason'
    }
  ]

  return (
    <div className="vendor-grid-layout-twocol">
      <div className="wide-col">
        <ErrorBoundary>
          <GridField
            title="Requirements"
            headerStyle={headerStyle}
            form={form}
            height={height}
            propertyName="warrantyRequirements"
            getRowNodeId={data => data.description}
            columnDefs={requirementsGridColumnDefs}
            key={requirementsGridKey}
          />
        </ErrorBoundary>
      </div>
      <div className="slim-col">
        <ErrorBoundary>
          <GridField
            title="Failure Reasons"
            headerStyle={headerStyle}
            form={form}
            height={height}
            propertyName="warrantyFailCodes"
            getRowNodeId={data => data.dataId}
            columnDefs={failureReasonsGridColumnDefs}
            key={failureGridKey}
          />
        </ErrorBoundary>
      </div>
    </div>
  )
}

const WarrantyWithContext = withContext({
  isEditing: form => form.get('isEditing') || false,
  warrantyFailCodes: form =>
    getIn(form, 'fields.warrantyFailCodes.rowData') || emptyList,
  warrantyRequirements: form =>
    getIn(form, 'fields.warrantyRequirements.rowData') || emptyList
})(Warranty)

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%',
  minHeight: 400
})(WarrantyWithContext)
