import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { emptyList, getIn, layoutFlex } from 'utils'
import { debounce } from 'lodash'
import Grid from 'grid'
import shortid from 'shortid'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core'
import { headerStyle } from 'pages/VendorMaster/utils'
import { getCorporateFieldUpdatesMeta } from 'components/MasterScreen/CorporateFieldUpdates/actions'

import '../../styles/johnstone.scss'

const DocumentLinkCell = ({ value, colDef, rowIndex, data, ...rest }) => {
  const url = data?.url || ''

  const openDocument = debounce(e => {
    if (url) {
      window.open(url, '_blank', 'toolbar=0,location=0,menubar=0')
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <button
        size="small"
        style={{
          color: '#517b9c',
          minHeight: 26,
          padding: 5,
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%'
        }}
        onClick={openDocument}
        type="button"
      >
        {value}
      </button>
    </div>
  )
}

const columnDefs = [
  {
    field: 'description',
    headerName: 'description',
    cellRendererFramework: DocumentLinkCell
  }
]

const Johnstone = ({
  billTo,
  buyerCode,
  corporateVendorNumber,
  dataId,
  dropShipMultiplier,
  form,
  johnstoneFreightTerms,
  jsLinksList,
  lastUpdateDate,
  lastPriceChangeDate,
  lastFuturePriceChangeDate,
  lastFuturePriceChangeComments,
  paymentTerms
}) => {
  const dispatch = useDispatch()
  const tableData = [
    { key: 'Corporate Number', value: corporateVendorNumber },
    { key: 'Buyer Code', value: buyerCode },
    { key: 'Payment Terms', value: paymentTerms },
    { key: 'Freight Terms', value: johnstoneFreightTerms },
    { key: 'Bill To', value: billTo },
    { key: 'Direct Ship Multiplier', value: dropShipMultiplier },
    { key: 'Last Update Date', value: lastUpdateDate },
    { key: 'Last Price Change Date', value: lastPriceChangeDate },
    { key: 'Last Future Price Change Date', value: lastFuturePriceChangeDate },
    {
      key: 'Last Future Price Change Comments',
      value: lastFuturePriceChangeComments
    }
  ]

  const rowData = jsLinksList?.toJS ? jsLinksList.toJS() : []

  const openCorporateFieldsUpdateModal = e =>
    dispatch(
      getCorporateFieldUpdatesMeta.try(form, {
        openModalOnComplete: true
      })
    )

  return (
    <div style={layoutFlex()}>
      <div className="johnstone-twocol-wrapper">
        <div className="johnstone-twocol">
          <div className="left">
            <Paper>
              <Table size="small">
                <TableBody>
                  {tableData.map((x, i) => (
                    <TableRow
                      key={shortid.generate()}
                      style={{ background: '#fff' }}
                    >
                      <TableCell>{x.key}</TableCell>
                      <TableCell style={{ fontWeight: 500 }}>
                        {x.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <div
              style={{
                display: 'flex',
                padding: '10px 20px 20px',
                justifyContent: 'center'
              }}
            >
              <Button
                variant="contained"
                disabled={!dataId}
                onClick={openCorporateFieldsUpdateModal}
              >
                Corporate Field Updates
              </Button>
            </div>
          </div>
          <div className="right">
            <div style={{ width: 300 }}>
              <Grid
                title="Web Links"
                headerStyle={headerStyle}
                hideHeader
                columnDefs={columnDefs}
                rowData={rowData}
                getRowNodeId={data => `${data.description}-${data.url}`}
                height={400}
                reactUi={false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  billTo: form => getIn(form, 'values.johnstone.billTo') || '',
  buyerCode: form => getIn(form, 'values.johnstone.buyerCode') || '',
  corporateVendorNumber: form =>
    getIn(form, 'values.johnstone.corporateVendorNumber') || '',
  dropShipMultiplier: form =>
    getIn(form, 'values.johnstone.dropShipMultiplier') || '',
  johnstoneFreightTerms: form =>
    getIn(form, 'values.johnstone.johnstoneFreightTerms') || '',
  jsLinksList: form => getIn(form, 'values.johnstone.jsLinks') || emptyList,
  lastUpdateDate: form => getIn(form, 'values.johnstone.lastUpdateDate') || '',
  lastPriceChangeDate: form =>
    getIn(form, 'values.johnstone.lastPriceChangeDate') || '',
  lastFuturePriceChangeDate: form =>
    getIn(form, 'values.johnstone.lastFuturePriceChangeDate') || '',
  lastFuturePriceChangeComments: form =>
    getIn(form, 'values.johnstone.lastFuturePriceChangeComments') || '',
  paymentTerms: form => getIn(form, 'values.johnstone.paymentTerms') || ''
})(Johnstone)
