import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import {
  dateFilterComparator,
  formatDateFields,
  is,
  layoutFlex,
  plainDeepEqual,
  getValue,
  emptyList,
  formatNumber
} from 'utils'
import withRowTotals from 'hoc/withRowTotals'
import BranchTooltipCell from 'pages/ProductMaster/components/BranchTooltipCell'
import CustomerTooltipCell from 'pages/ProductMaster/components/CustomerTooltipCell'
// import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import SalesOrderTooltipCell from 'pages/ProductMaster/components/SalesOrderTooltipCell'
import SalesmanTooltipCell from 'pages/ProductMaster/components/SalesmanTooltipCell'
import WarehouseTooltipCell from 'pages/ProductMaster/components/WarehouseTooltipCell'
import WriterTooltipCell from 'pages/ProductMaster/components/WriterTooltipCell'
import {
  expandableGridBaseConfig,
  headerStyle,
  setGridHeight,
  sizeDetailColumns
} from 'pages/ProductMaster/utils'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import withDimensions from 'hoc/withDimensions'
import { openScreen } from 'mobile/actions'

// import LineItemsComponent from './components/LineItemsComponent'

import './styles/master-style.scss'

const MobileSalesOrderCell = props => {
  // console.log('MobileSalesOrderCell', props)
  const dispatch = props?.colDef?.cellRendererParams?.dispatch || null
  const form = props?.colDef?.cellRendererParams?.form || ''
  const buttonLinkStyle = {
    color: '#517b9c',
    fontWeight: 500,
    margin: 0,
    padding: 0
  }

  const openSalesOrder = e => {
    if (dispatch && form && props.value) {
      dispatch(
        openScreen({
          link: '/salesorder',
          dataId: props.value,
          readSalesOrder: true
        })
      )
    }
  }

  return (
    <button type="button" onClick={openSalesOrder} style={buttonLinkStyle}>
      {props.value}
    </button>
  )
}

export const getRowNodeId = data => {
  const { order, customer, customerId } = data
  const comp = `${order}|${customer}|${customerId}`
  return comp
}

export const dynamicColumnDefs = memoize(
  ({ dispatch, form, type, columns = [], isMobile = false }) => {
    const hasScanned = columns.some(x => x.propertyName === 'scanned')

    const orderColParams = isMobile
      ? {
          cellRendererFramework: MobileSalesOrderCell,
          cellRendererParams: {
            dispatch,
            form
          }
        }
      : {
          cellRendererFramework: SalesOrderTooltipCell
        }

    const colDef = [
      {
        cellRendererSelector: params => {
          const masterComp = {
            component: 'agGroupCellRenderer',
            params: { value: '' }
          }
          return params.value && params.value.length > 1 ? masterComp : null
        },
        field: 'lineItems',
        headerName: '',
        valueFormatter: params => '',
        width: 100
      },
      {
        field: 'order',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        form,
        headerName: 'Order',
        linkTo: type,
        width: 250,
        ...orderColParams
      },
      {
        cellStyle: { textAlign: 'center' },
        field: 'orderDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        },
        headerClass: 'centered-label',
        headerName: 'Order Date',
        valueFormatter: formatDateFields,
        width: 250
      },
      {
        // cellRendererFramework: OpenScreenLinkCell,
        cellRendererFramework: isMobile ? null : CustomerTooltipCell,
        cellRendererParams: params => ({
          customerId: params.data.customerId
        }),
        field: 'customer',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        form,
        headerName: 'Customer',
        linkTo: 'customerLedger',
        width: 250
      },
      {
        // cellRender
        field: 'customerPO',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Customer P/O'
      },
      {
        cellStyle: { textAlign: 'center' },
        field: 'promiseDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        },
        headerClass: 'centered-label',
        headerName: 'Prom Dt',
        valueFormatter: formatDateFields
      },
      {
        cellRendererFramework: isMobile ? null : SalesmanTooltipCell,
        field: 'salesman',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Slsprsn'
      },
      {
        cellRendererFramework: isMobile ? null : WriterTooltipCell,
        field: 'writer',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Writer'
      },
      {
        cellRendererFramework: isMobile ? null : BranchTooltipCell,
        field: 'branch',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Branch'
      },
      {
        cellRendererFramework: isMobile ? null : WarehouseTooltipCell,
        field: 'warehouse',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Warehouse'
      },
      {
        cellStyle: { color: 'purple' },
        field: 'status.value',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Status',
        tooltipField: 'status.tooltip'
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'numeric-value-label align-right',
        headerName: 'Open',
        valueGetter: ({ data }) => data.lineItems[0].open,
        valueFormatter: params => formatNumber(params.value, '0,0')
      },
      {
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'UOM',
        valueGetter: ({ data }) => data.lineItems[0].uom
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'numeric-value-label align-right',
        headerName: 'To Ship',
        valueGetter: ({ data }) => data.lineItems[0].toShip
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'numeric-value-label align-right',
        headerName: 'Staged',
        valueGetter: ({ data }) => data.lineItems[0].staged,
        valueFormatter: params => formatNumber(params.value, '0,0')
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'numeric-value-label align-right',
        headerName: 'B/O',
        valueGetter: ({ data }) => data.lineItems[0].bo,
        valueFormatter: params => formatNumber(params.value, '0,0')
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        disable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'align-right',
        headerName: 'Cmtd',
        lineField: 'cmtd',
        valueGetter: ({ data }) => data.lineItems[0].cmtd
      },
      {
        cellStyle: params => ({
          color: params?.value < 0 ? '#d9534f' : 'auto',
          textAlign: 'right'
        }),
        filter: 'agNumberColumnFilter',
        filterParams: {
          clearButton: true,
          inRangeInclusive: true,
          suppressAndOrCondition: true
        },
        headerClass: 'numeric-value-label align-right',
        headerName: 'Scanned',
        hide: !hasScanned,
        valueGetter: ({ data }) => data.lineItems[0].scanned
      }
    ]
    return colDef
  }
)

const detailColumnDefs = [
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  { field: '' },
  {
    cellClass: 'cell-value-hidden cell-value-hidden-standalone',
    cellStyle: { textAlign: 'right' },
    field: 'open',
    headerClass: 'numeric-value-label align-right',
    headerName: 'Open'
  },
  {
    field: 'uom',
    headerName: 'UOM'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'toShip',
    headerClass: 'numeric-value-label align-right',
    headerName: 'To Ship'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'staged',
    headerClass: 'numeric-value-label align-right',
    headerName: 'Staged'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'bo',
    headerClass: 'numeric-value-label align-right',
    headerName: 'B/O'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'scanned',
    headerClass: 'numeric-value-label align-right',
    headerName: 'Scanned'
  },
  {
    // cellRendererFramework: CheckboxCell,
    cellRendererFramework: ToggleCheckboxCell,
    cellRendererParams: {
      isDisabled: true
    },
    field: 'committed',
    headerName: 'Committed'
  }
]

const reduceLineItems = items => {
  const result = is.array(items)
    ? items.reduce(
        (acc, next) => {
          acc.open += next.open
          acc.staged += next.staged
          acc.bo += next.bo
          return acc
        },
        {
          open: 0,
          staged: 0,
          bo: 0
        }
      )
    : null

  return result
}

const getPinnedRowData = (salesOrders = []) => {
  const defaults = {
    salesman: '',
    lineItems: [
      {
        open: 0,
        staged: 0,
        bo: 0
      }
    ]
  }

  if (salesOrders.length) {
    const salesTotal = salesOrders.reduce((acc, next) => {
      if (next.lineItems && next.lineItems.length > 1) {
        const reducedItems = reduceLineItems(next.lineItems)
        acc.lineItems[0].open += reducedItems?.open
        acc.lineItems[0].staged += reducedItems?.staged
        acc.lineItems[0].bo += reducedItems?.bo
      } else {
        acc.lineItems[0].open += next.lineItems?.[0]?.open
        acc.lineItems[0].staged += next.lineItems?.[0]?.staged
        acc.lineItems[0].bo += next.lineItems?.[0]?.bo
      }
      return acc
    }, defaults)

    return [salesTotal]
  }

  return [defaults]
}

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: getPinnedRowData
})(Grid)

export class SalesOrders extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    // height: PropTypes.number.isRequired,
    isWarehouseMaintainingBinQuantity: PropTypes.bool.isRequired,
    salesOrders: PropTypes.array
  }

  static defaultProps = {
    salesOrders: []
  }

  constructor(props) {
    super(props)

    this.sizeDetailColumns = sizeDetailColumns.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (
      this.gridApi &&
      !plainDeepEqual(prevProps.salesOrders, this.props.salesOrders)
    ) {
      const filter = this.gridApi.getFilterModel()
      if (Object.keys(filter).length) {
        this.gridApi.setFilterModel(null)
      }
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
    this.checkSelectedGridItems(api)
  }

  getRowHeight = params => {
    if (params.node && params.node.rowPinned) {
      return 28
    }
    if (params.node && params.node.detail) {
      return setGridHeight(params.data.lineItems) - 28
    }
    return params.node.rowHeight
  }

  checkSelectedGridItems = api =>
    api.forEachNode(node => {
      if (node.data.isSelected) {
        node.setSelected(true)
      }
    })

  render() {
    const {
      dispatch,
      form,
      isMobile = false
      // height,
      // isWarehouseMaintainingBinQuantity,
    } = this.props

    let { salesOrders, columns } = this.props

    salesOrders = salesOrders?.toJS ? salesOrders.toJS() : []
    columns = columns?.toJS ? columns.toJS() : []

    let columnDefs = dynamicColumnDefs({
      dispatch,
      form,
      type: 'salesOrder',
      columns,
      isMobile
      // isWarehouseMaintainingBinQuantity
    })

    columnDefs = isMobile
      ? columnDefs.map((col, i) => {
          if (i === 0) {
            col = {
              ...col,
              minWidth: 50
            }
          } else {
            col = {
              ...col,
              minWidth: 150
            }
          }

          return col
        })
      : columnDefs

    return (
      <>
        <div style={isMobile ? null : layoutFlex('120rem')}>
          <div className="product-analysis-flex-wrapper-1">
            <div style={{ height: isMobile ? 'auto' : '95%' }}>
              <GridWithRowTotals
                propertyName="salesOrders"
                title="Sales Orders"
                getRowNodeId={getRowNodeId}
                headerStyle={
                  isMobile ? { height: 0, margin: 0, padding: 0 } : headerStyle
                }
                reactUi={false}
                columnDefs={columnDefs}
                masterDetail
                detailCellRendererParams={{
                  detailGridOptions: {
                    ...expandableGridBaseConfig.detail,
                    getRowNodeId: data => data.open,
                    columnDefs: detailColumnDefs,
                    onGridReady: this.onGridReady
                  },
                  getDetailRowData: params => {
                    const lineItems =
                      params.data.lineItems && params.data.lineItems.length > 1
                        ? params.data.lineItems.slice(1)
                        : []

                    // debugger
                    this.sizeDetailColumns()
                    params.successCallback(lineItems)
                  }
                  // template:
                  //   '<div style="padding: 0px; box-sizing: border-box;">' +
                  //   '  <div ref="eDetailGrid" style="height: 100%;"></div>' +
                  //   '</div>'
                }}
                gridHeight={setGridHeight(salesOrders)}
                getRowHeight={this.getRowHeight}
                onGridReady={this.onGridReady}
                rowData={salesOrders}
                immutableData
                rowSelection="single"
                width="100%"
                fullWidth
                ref={el => (this.grid = el)}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const contextObj = {
  salesOrders: form => getValue(form, 'salesOrders.data', emptyList),

  columns: form => getValue(form, 'salesOrders.meta.columns', emptyList)
}

export const salesOrderWithContext = withContext(contextObj)(SalesOrders)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  salesOrderWithContext
)
