/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'utils'

const mapStateToProps = (state, ownProps) => {
  const formState = getIn(state, 'ddiForm.customerShipTo')

  return {
    description: getIn(formState, 'fields.description.value'),
    dataId: getIn(formState, 'fields.dataId.value'),
    preNewMode: getIn(formState, 'values.preNewMode'),
    newRecordCreated: getIn(formState, 'values.newRecordCreated')
  }
}

class ShipToTitleBar extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    newRecordCreated: PropTypes.bool.isRequired,
    preNewMode: PropTypes.bool.isRequired
  }

  render() {
    const { dataId, description, newRecordCreated, preNewMode } = this.props
    const title =
      preNewMode || newRecordCreated
        ? `New Ship To: ${dataId}`
        : `Ship To - ${dataId}: ${description}`
    return <span>{title}</span>
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(ShipToTitleBar)
