import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from 'pages/SalesOrder/utils'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Typography } from '@material-ui/core'
import { isEqual } from 'lodash'
import Header from '../../../Header'

const infoStyle = { color: '#444', fontSize: '0.85em' }
const width = { width: '100%' }
export default React.memo(props => {
  const { expanded, handleAccordionToggle, form, dispatch } = props
  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleAccordionToggle('header')}
      square
      // ref={el => (this.headerPanel = el)}
      forwardRef
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography style={infoStyle}>Order Info</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography component="div" style={width}>
          <Header form={form} dispatch={dispatch} />
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}, isEqual)
