import { createAction, createAsyncFormAction } from 'utils'

import * as CONSTANTS from './constants'

export const deleteWarrantyTag = createAsyncFormAction(
  CONSTANTS.DELETE_WARRANTY_TAG
)

export const cancelEditWarrantyTag = createAsyncFormAction(
  CONSTANTS.CANCEL_EDIT_WARRANTY_TAG
)

export const editWarrantyTag = createAsyncFormAction(
  CONSTANTS.EDIT_WARRANTY_TAG
)

export const saveWarrantyTag = createAsyncFormAction(
  CONSTANTS.SAVE_WARRANTY_TAG
)

export const clearIsEditingFlag = form =>
  createAction(CONSTANTS.CLEAR_IS_EDITING_FLAG, {}, form)

export const getPreviousEndUserInfo = createAsyncFormAction(
  CONSTANTS.GET_PREVIOUS_END_USER_INFO
)
