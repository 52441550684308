import { createAsyncRequestTypes } from 'utils'

const SERIAL_NUMBER_INQUIRY = '@@SERIAL_NUMBER_INQUIRY/'

export const UPDATE_DESCRIPTION = `${SERIAL_NUMBER_INQUIRY}UPDATE_DESCRIPTION`

export const SAVE_NOTES = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}SAVE_NOTES`
)

export const LOCK_SERIAL_FOR_EDIT = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}LOCK_SERIAL_FOR_EDIT`
)

export const UNLOCK_SERIAL = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}UNLOCK_SERIAL`
)

export const DELETE_NOTES = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}DELETE_NOTES`
)

export const SAVE_PRODUCT = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}SAVE_PRODUCT`
)

export const DELETE_SERIAL_NUMBER = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}DELETE_SERIAL_NUMBER`
)

export const CANCEL_PRODUCT_EDIT = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}CANCEL_PRODUCT_EDIT`
)

export const SET_FILTER = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}SET_FILTER`
)

export const SET_SELECTED_SERIAL_NUMBER = `${SERIAL_NUMBER_INQUIRY}SET_SELECTED_SERIAL_NUMBER`

export const CLEAR_COMPLETE_SERIAL_HISTORY = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}GET_COMPLETE_SERIAL_HISTORY`
)

export const CLEAR_FIELDS = `${SERIAL_NUMBER_INQUIRY}CLEAR_FIELDS`

export const TOGGLE_ADD_MODE = `${SERIAL_NUMBER_INQUIRY}TOGGLE_ADD_MODE`

export const ADD_SERIAL_NUMBER = createAsyncRequestTypes(
  `${SERIAL_NUMBER_INQUIRY}ADD_SERIAL_NUMBER`
)

export const CLEAR_CELL = `${SERIAL_NUMBER_INQUIRY}CLEAR_CELL`

export const EXIT_SCREEN_FROM_MODAL = `${SERIAL_NUMBER_INQUIRY}EXIT_SCREEN_FROM_MODAL`
