import { api } from 'services'
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import { groupNamesMap } from './utils'

export const getProductInSerialNumberInquiry = ({ dataId, serialNumberId, groupNames }) => {

  const params = {
    name: 'productSerialNumberInquiry',
    productId: dataId,
    serialNumberId: serialNumberId || '',
    groupNames: groupNamesMap[groupNames[0]] ? [groupNamesMap[groupNames[0]]] : groupNames
  }

  return api.read(params)
}

export const lockProductForEdit = ({ dataId, ...rest }) => api.lockForEdit({
  productId: dataId
})

export const editNotes = ({ groupName, ...rest }) => {
  const options = createApiOptions({
    body: { groupName, ...rest },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productserialnumberinquiry/notes/editlock`, options)
}

export const unlockNotes = ({ groupName, ...rest }) => {
  const options = createApiOptions({
    body: { groupName, ...rest },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productserialnumberinquiry/notes/canceledit`, options)
}

export const updateNotes = params => {
  const options = createApiOptions({
    body: { ...params },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productserialnumberinquiry/notes/save`, options)
}

export const removeSerialNumber = ({ productId, serialNumberId }) => {
  const options = createApiOptions({
    body: { productId, serialNumberId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productserialnumberinquiry/currentinventory/remove`, options)
}

export const addSerialNumber = ({ productId, serialNumberId, properties, ...rest }) => {
  const options = createApiOptions({
    body: { productId, serialNumberId, properties, ...rest },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/productserialnumberinquiry/currentinventory/add`, options)
}