/* eslint react/prefer-stateless-function: 0, jsx-a11y/label-has-for: 0  */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import { Paper, CircularProgress } from '@material-ui/core'
import { getIn } from 'utils'
import { makeRadioButtons, processOptions } from 'modals/MergeModal/utils'

import { Field, Label } from 'ddiForm'
import { required } from 'ddiForm/validations'
import RenderRadioGroup from 'components/RenderRadioGroup'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const uomForDisplayId =
    getIn(formState, 'fields.uomForDisplayId.value') ||
    getIn(formState, 'values.uomForDisplayId') ||
    ''
  const uomForPriceId =
    getIn(formState, 'fields.uomForPriceId.value') ||
    getIn(formState, 'values.uomForPriceId') ||
    ''
  let uoms = getIn(formState, 'meta.uoMs')
  uoms = uoms && uoms.toJS ? uoms.toJS() : []

  const uomForDisplay = uoms.find(x => x.dataId === uomForDisplayId)
  const uomForPrice = uoms.find(x => x.dataId === uomForPriceId)

  return {
    isRequesting: getIn(formState, 'fields.mergeModal.isRequesting') || false,
    requestComplete: getIn(formState, 'fields.mergeModal.requestComplete'),
    toProductDisplayUOM:
      getIn(formState, 'fields.mergeModal.toId.displayUOM') || '',
    toProductPriceUOM:
      getIn(formState, 'fields.mergeModal.toId.priceUOM') || '',
    uomForDisplayDescription:
      uomForDisplay && typeof uomForDisplay === 'object'
        ? uomForDisplay.description
        : '',
    uomForPriceDescription:
      uomForPrice && typeof uomForPrice === 'object'
        ? uomForPrice.description
        : ''
  }
}

export class MergeModal extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isRequesting: PropTypes.bool.isRequired,
    requestComplete: PropTypes.bool.isRequired,
    toProductDisplayUOM: PropTypes.string.isRequired,
    toProductPriceUOM: PropTypes.string.isRequired,
    uomForDisplayDescription: PropTypes.string.isRequired,
    uomForPriceDescription: PropTypes.string.isRequired
  }

  render() {
    const {
      data: { searchType },
      toProductDisplayUOM,
      toProductPriceUOM,
      uomForDisplayDescription,
      uomForPriceDescription,
      isRequesting,
      requestComplete
    } = this.props
    console.log(this)
    if (isRequesting) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div style={{ marginRight: 15 }}>
            <CircularProgress thickness={7} />
          </div>
          <p style={{ fontSize: 14, marginBottom: 0, textAlign: 'center' }}>
            Please wait. Your merge request is processing...
          </p>
        </div>
      )
    }

    if (requestComplete) {
      return (
        <p style={{ fontSize: 14, marginBottom: 0, textAlign: 'center' }}>
          Process completed...
        </p>
      )
    }

    return (
      <div>
        <Paper
          style={{
            background: '#f5f7f7',
            marginBottom: 10,
            padding: '15px 15px 0 15px'
          }}
        >
          <p style={{ margin: 0 }}>Process</p>
          <Field
            component={RenderRadioGroup}
            propertyName="mergeModal.mergeOvernight"
            renderAsRow
          >
            {makeRadioButtons(processOptions)}
          </Field>
        </Paper>
        <Paper style={{ background: '#f5f7f7', marginBottom: 10, padding: 15 }}>
          <p style={{ marginBottom: 0 }}>From</p>
          <div
            style={{ alignItems: 'center', display: 'flex', margin: '15px 0' }}
          >
            <label
              style={{
                color: '#444',
                order: 1,
                marginBottom: 0,
                marginRight: 10
              }}
            >
              Number:
            </label>
            <div
              style={{ marginRight: 15, maxWidth: 200, order: 2, width: 200 }}
            >
              <DDIIndexSearch
                propertyName="mergeModal.dataId"
                disabled
                searchType={searchType}
                fullWidth
              />
            </div>
            <div
              style={{
                flex: 1,
                order: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Label propertyName="mergeModal.dataId.description" />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%'
            }}
          >
            <p style={{ marginBottom: 0 }}>
              <span style={{ display: 'inline-block', marginRight: 10 }}>
                Display UOM:
              </span>
              <strong>{uomForDisplayDescription}</strong>
            </p>
            <span style={{ margin: '0 15px' }}>|</span>
            <p style={{ marginBottom: 0 }}>
              <span style={{ display: 'inline-block', marginRight: 10 }}>
                Price UOM:
              </span>
              <strong>{uomForPriceDescription}</strong>
            </p>
          </div>
        </Paper>
        <Paper style={{ background: '#f5f7f7', marginBottom: 10, padding: 15 }}>
          <p style={{ marginBottom: 0 }}>To</p>
          <div
            style={{ alignItems: 'center', display: 'flex', margin: '15px 0' }}
          >
            <label
              style={{
                color: '#444',
                order: 1,
                marginBottom: 0,
                marginRight: 10
              }}
            >
              Number:
            </label>
            <div
              style={{ marginRight: 15, maxWidth: 200, order: 2, width: 200 }}
            >
              <DDIIndexSearch
                preventAutoDisable
                propertyName="mergeModal.toId"
                searchType={searchType}
                indexSearchType={searchType}
                meta={{
                  allowInvalidValues: true,
                  allowInstantSearch: true,
                  allowNavigation: false,
                  allowSearchAll: false,
                  fieldName: 'mergeModal.toId',
                  hasFilters: true,
                  hideToggle: true,
                  openText: null,
                  searchType
                }}
                validate={[required]}
              />
            </div>
            <div
              style={{
                flex: 1,
                order: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Label propertyName="mergeModal.toId.description" />
            </div>
          </div>
          {toProductDisplayUOM && toProductPriceUOM ? (
            <div
              style={{
                display: 'flex',
                width: '100%'
              }}
            >
              <p style={{ marginBottom: 0 }}>
                <span style={{ display: 'inline-block', marginRight: 10 }}>
                  Display UOM:
                </span>
                <strong>{toProductDisplayUOM}</strong>
              </p>
              <span style={{ margin: '0 15px' }}>|</span>
              <p style={{ marginBottom: 0 }}>
                <span style={{ display: 'inline-block', marginRight: 10 }}>
                  Price UOM:
                </span>
                <strong>{toProductPriceUOM}</strong>
              </p>
            </div>
          ) : null}
        </Paper>
        <div style={{ padding: 10 }}>
          <p>
            Product merge is designed to merge products with identical Units of
            Measure and Costs. Merging products with different Units of Measure
            and/or Costs is not recommended and could significantly change your
            inventory value.
          </p>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(MergeModal)
