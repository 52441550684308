import React, { useEffect, useRef } from 'react'
import memoize from 'memoize-one'
import { ddiForm } from 'ddiForm'
import { Grid, Paper } from '@material-ui/core'
import { lockForEditAsync, cancelEditAsync } from 'ddiForm/MasterScreen/actions'
import { save } from 'ddiForm/actions'

import ComponentStyles from 'theme/ComponentStyles'
import CustomerInfo from './components/CustomerInfo'
import TagInfo from './components/TagInfo'
import SaleInformation from './components/SaleInformation'
import EndUserInfo from './components/EndUserInfo'
import RightFooterButtons from './components/RightFooterButtons'
import sagas from './sagas'
import behaviors from './behaviors'
import TagEntry from './components/TagEntry'
import { readWarrantyTag } from './api'
import { print, deleteWarrantyTag } from './actions'
import LeftFooterButtons from './components/LeftFooterButtons'
import './styles/css-mod-ignore.scss'

export const WarrantyTag = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column nowrap',
        height: '100%',
        position: 'sticky',
        width: '100%'
      }}
    >
      <div
        style={{
          flexGrow: 1,
          minHeight: '2em',
          overflow: 'auto',
          justifyContent: 'center',
          display: 'flex',
          padding: '0.5rem 0'
        }}
      >
        <Grid
          className="warranty-tag-container"
          style={{ margin: 0 }}
          spacing={2}
          container
        >
          <Grid className="warranty-tag-column" item xs={5}>
            <CustomerInfo />
            <TagInfo />
          </Grid>
          <Grid className="warranty-tag-column" item xs={7}>
            <TagEntry {...props} />
            <SaleInformation {...props} />
            <EndUserInfo {...props} />
          </Grid>
        </Grid>
      </div>
      <div
        className="warranty-tag-footer-outer-wrapper"
        style={{ flexShrink: 0 }}
      >
        <div
          className="warranty-tag-footer-wrapper"
          style={ComponentStyles.screenLayout.footerWrapper}
        >
          <Paper
            className="action-buttons clear"
            style={ComponentStyles.footer.containerStandalone}
          >
            <LeftFooterButtons />

            <div style={{ alignItems: 'flex-end', display: 'flex' }}>
              <RightFooterButtons />
            </div>
          </Paper>
        </div>
      </div>
    </div>
  )
}

const options = {
  form: 'warrantyTag',
  apiAlias: 'warrantytag',
  title: 'Warranty Tag',
  actions: {
    lockForEdit: lockForEditAsync.try,
    cancelEdit: cancelEditAsync.try,
    print: print.try,
    deleteWarrantyTag: deleteWarrantyTag.try,
    saveWarrantyTag: save
  },
  behaviors,
  getEntityApi: readWarrantyTag,
  sagas: { onInit: sagas }
}

export const warrantyTagInModal = memoize(form =>
  ddiForm({
    ...options,
    form
  })(WarrantyTag)
)

export default ddiForm(options)(WarrantyTag)
