/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, react/no-find-dom-node: 0, react/no-did-mount-set-state: 0, consistent-return: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ADD_BLANK_ROW } from 'ddiForm/constants'
import { getIn, plainDeepEqual, toNumeral } from 'utils'
// import { delay, throttle, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import { InputAdornment, Icon, Input, TextField } from '@material-ui/core'
import {
  getErrorIcon,
  isDisabled,
  notifyExitTextCellFn,
  updateGridCellData
} from 'components/EditableGrid/utils'
import { debounce } from 'lodash'

import { validateGridData } from '../actions'

const nums = [37, 39]

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    range,
    formatDuringEditOnly,
    isEditingField,
    ...other
  } = props
  /*
    had to change this component to receive
    decimal props like this
  */
  let decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  if (formatDuringEditOnly && !isEditingField) {
    decimalFormatting = {}
  }

  let isAllowed = {}

  if (range && Array.isArray(range)) {
    isAllowed = {
      isAllowed: values => {
        let { floatValue } = values
        floatValue = floatValue ?? 0

        return range[2]
          ? floatValue > range[0] && floatValue < range[1]
          : floatValue >= range[0] && floatValue <= range[1]
      }
    }
  }

  return (
    <NumberFormat
      {...other}
      {...decimalFormatting}
      {...isAllowed}
      disabled={disabled}
      id={id}
      getInputRef={inputRef}
      forwardRef
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      prefix={prefix}
      suffix={suffix}
      style={{
        color: value < 0 ? '#d9534f' : disabled ? '#666' : '#000',
        fontSize: fontSize || '1em',
        height: '100%',
        paddingLeft: 0,
        paddingRight,
        // paddingLeft: textAlign === 'left' ? 10 : 0,
        // paddingRight: textAlign === 'right' ? 0 : 0,
        textAlign,
        width: '100%'
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      maxLength={maxLength}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    rowIndex,
    colDef: {
      cellRendererParams: { type, propertyName }
    }
  } = ownProps
  const rowData = getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`)
  const emptyRow =
    getIn(state, `ddiForm.${form}.fields.${propertyName}.emptyRow`) || null
  const focusedCell = getIn(
    state,
    `ddiForm.${form}.fields.${propertyName}.focusedCell`
  )

  return {
    focusedCell: focusedCell ? focusedCell.toJS() : {},
    rowData: rowData && rowData.toJS ? rowData.toJS() : [],
    emptyRow
  }
}

class TextFieldGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    focusedCell: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired,
    variant: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number
  }

  static defaultProps = {
    disabled: false,
    variant: 'standard',
    multiline: false,
    rows: 4
  }

  _isMounted = false

  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      focusSet: false,
      lastCellTabbed: false,
      rowIndex: props.rowIndex,
      textFieldVal: props.value || props.value === 0 ? props.value : ''
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.notifyExitTextCellFn = notifyExitTextCellFn.bind(this)
    this.isDisabled = isDisabled.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef.cellRendererParams.requiredFn = this.props.colDef.cellRendererParams.requiredFn.bind(
        this
      )
    }

    this.checkFocusOnInput = debounce(this.checkFocusOnInput, 100)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    /* some tricky logic in here */

    /*
      this means that the user has entered invalid data (for whatever reason)
      (see POPatterns or Credit References for example) and a saga OR a
      reducer method has stripped the value onBlur
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      (prevState.isEditing === true || prevState.lastCellTabbed === true)
    ) {
      return { isEditing: false, lastCellTabbed: false }
    }

    /* ^^related to the above, now that we've received a new value,
         we can strip it out because we've previously marked isEditing === false
         AFTER getting the new value prop back after being handled by the saga
         or reducer (as described above)
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      (prevState.isEditing === false || prevState.lastCellTabbed === false)
    ) {
      return { textFieldVal: nextProps.value }
    }

    return null
  }

  componentDidMount() {
    this._isMounted = true
    const {
      value,
      colDef: {
        cellRendererParams: { formatter }
      }
    } = this.props

    if (formatter === 'number' && value === 0) {
      this.setState({
        textFieldVal: 0
      })
    }

    findDOMNode(this.textField).addEventListener('keydown', this.onKeyDown)

    setTimeout(() => {
      this.checkFocusOnInput()
    }, 0)
  }

  componentWillUnmount() {
    this._isMounted = false
    findDOMNode(this.textField).removeEventListener('keydown', this.onKeyDown)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!plainDeepEqual(prevProps.focusedCell, this.props.focusedCell)) {
      setTimeout(() => {
        this.checkFocusOnInput()
      }, 0)
    }
  }

  onChange = event => {
    if (!this._isMounted) {
      return
    }

    this.setState({
      focusSet: false,
      isEditing: true,
      lastCellTabbed: false,
      textFieldVal: event.target.value
    })
  }

  onBlur = event => {
    event.persist()
    const {
      colDef: {
        cellRendererParams: { notifyExitTextCell = false }
      }
    } = this.props

    if (this.state.focusSet === false && this.state.lastCellTabbed === false) {
      const {
        colDef: { field, requiresAdditionalValidation },
        value
      } = this.props

      // debugger
      /* extra condition prevents unnecessary updates on null v '' */
      if (
        this.state.textFieldVal !== value &&
        !(!this.state.textFieldVal && !value)
      ) {
        // debugger
        this.updateGridCellData(
          'textFieldVal',
          false,
          requiresAdditionalValidation
        )
      }
    }

    if (this._isMounted) {
      this.setState({
        isEditing: false
      })
    }
  }

  onValueChange = values => {
    if (!this._isMounted) {
      return
    }
    // console.log('onValueChange', values)
    // debugger
    this.setState({
      focusSet: false,
      lastCellTabbed: false,
      isEditing: true,
      textFieldVal: values.floatValue
    })
  }

  showRequiredIcon = () => {
    const {
      data,
      value,
      valueFormatted,
      colDef: {
        cellRendererParams: {
          errorMessage,
          isRequired,
          requiredFn,
          requiredIf,
          requiredLessThan
        },
        field
      },
      rowData,
      rowIndex
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)
    // debugger
    // prob makes sense to send all the props into the fn...
    // also prob makes BETTER sense to not have 3 named ways of required
    // you could easily find the type and use it.. this way you can have
    // multiple avenues to pass in and all can be evaluated..
    // this is called polymorphism

    if (requiredFn && typeof requiredFn === 'function') {
      const error = requiredFn(data, this.props)
      return error
        ? typeof error === 'string'
          ? getErrorIcon(error)
          : errorIcon
        : null
    }

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }
    // why? just use a fn...
    if (requiredIf) {
      // console.log('requiredIf', rowData[rowIndex][field])
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredIf] &&
        !rowData[rowIndex][field]
      ) {
        return errorIcon
      }
    }

    if (requiredLessThan) {
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredLessThan]
      ) {
        if (
          toNumeral(rowData[rowIndex][field]) >
          toNumeral(rowData[rowIndex][requiredLessThan])
        ) {
          return errorIcon
        }
      }
    }

    return null
  }

  checkFocusOnInput = () => {
    if (!this.props.api || !this._isMounted) return
    const focusedCell = this.props.api.getFocusedCell()
    const {
      colDef: { field },
      data: { rowId = null }
    } = this.props

    const model = this.props.api.getModel()
    if (!model) {
      return
    }

    const sortedRowData = model.rowsToDisplay.reduce((acc, next) => {
      acc = acc.concat(next.data)
      return acc
    }, [])
    const sortedRowIndex = sortedRowData.findIndex(x => x.rowId === rowId)

    if (!focusedCell || sortedRowIndex < 0) {
      return
    }

    if (
      focusedCell?.column?.colDef?.field &&
      focusedCell?.column?.colDef?.field === field &&
      focusedCell?.rowIndex === sortedRowIndex
    ) {
      // console.log(focusedCell)
      if (findDOMNode(this.textField) && findDOMNode(this.textField) !== null) {
        this.setState({ focusSet: true }, () => {
          findDOMNode(this.textField).focus()
        })
      }
    }
  }

  onKeyDown = event => {
    if (this.isLeftOrRight(event) || !this._isMounted) {
      event.stopPropagation()
      return false
    }

    const {
      api,
      colDef: { field, requiresAdditionalValidation },
      data,
      form,
      propertyName,
      value,
      emptyRow,
      rowIndex
    } = this.props

    if (event.key === 'Tab') {
      // const lastRow = rowData[rowData.length - 1]
      const lastDisplayedRow = api.getLastDisplayedRow()
      const displayedRowCount = api.getDisplayedRowCount()
      const allColumns = api.getColumnDefs()
      const lastCell =
        allColumns
          .filter(
            x =>
              x?.field &&
              !x?.field?.match(/delete/) &&
              !x?.cellRendererParams?.ignoreTabLogic &&
              !x?.suppressNavigable
          )
          ?.pop()?.field === field || false
      // debugger

      if (lastCell) {
        this.setState(
          {
            lastCellTabbed: true
          },
          () => {
            if (
              this.state.textFieldVal !== value &&
              !(!this.state.textFieldVal && !value)
            ) {
              this.updateGridCellData(
                'textFieldVal',
                true,
                requiresAdditionalValidation
              )

              if (api && this._isMounted) {
                api.tabToNextCell()
              }
            } else if (rowIndex + 1 === displayedRowCount && emptyRow) {
              this.props.dispatch({
                type: ADD_BLANK_ROW,
                payload: { propertyName },
                meta: { form, reducer: 'Grid' }
              })
            }
          }
        )
      } else if (api && this._isMounted) {
        api.tabToNextCell()
      }
    }
  }

  isLeftOrRight = event => nums.includes(event.keyCode)

  isFieldDisabled = () => {
    const {
      // value,
      colDef: {
        cellRendererParams: { disabledFn }
      },
      data
    } = this.props

    // this.checkFocusOnInput()
    if (disabledFn) {
      return disabledFn(data)
    }

    return this.isDisabled()
  }

  render() {
    const {
      colDef: {
        field,
        cellRendererParams: {
          allowNegative,
          formatter,
          decimalScale,
          decimalSeperator,
          dollarField,
          fixedDecimalScale,
          thousandSeparator,
          textAlign,
          noDecimalLimit,
          maxLength,
          fontSize,
          inputProps = {},
          range,
          suffix,
          formatDuringEditOnly = false
        }
      },
      form,
      rowIndex
    } = this.props

    if (formatter) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            width: '100%'
          }}
          title={this.state.textFieldVal}
        >
          {this.showRequiredIcon()}
          <Input
            disableUnderline
            fullWidth
            id={`text-field-${form}-${field}-${rowIndex}`}
            value={this.state.textFieldVal}
            disabled={this.isFieldDisabled()}
            inputComponent={NumberFormatter}
            onChange={this.onValueChange}
            onBlur={this.onBlur}
            style={{
              height: '100%',
              width: '100%'
            }}
            inputRef={el => (this.textField = el)}
            inputProps={{
              allowNegative,
              decimalScale: decimalScale || 0,
              noDecimalLimit: noDecimalLimit || false,
              decimalSeparator: decimalSeperator || '.',
              fontSize: fontSize || '1em',
              fixedDecimalScale: fixedDecimalScale || false,
              inputRef: el => (this.textField = el),
              maxLength,
              textAlign: textAlign || dollarField ? 'right' : 'left',
              thousandSeparator,
              prefix: dollarField ? '$' : '',
              suffix: suffix || '',
              range,
              formatDuringEditOnly,
              isEditingField: this.state.isEditing
            }}
            // startAdornment={
            //   dollarField ? (
            //     <InputAdornment position="start">$</InputAdornment>
            //   ) : null
            // }
          />
        </div>
      )
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
        title={this.state.textFieldVal}
      >
        {this.showRequiredIcon()}
        <TextField
          InputProps={{
            disableUnderline: true,
            style: { height: '100%', padding: 0, width: '100%' }
          }}
          inputProps={{
            style: {
              height: '100%',
              padding: this.props.multiline ? 15 : 0,
              width: '100%',
              textAlign: textAlign || 'left'
            },
            ...inputProps
          }}
          onChange={this.onChange}
          disabled={this.isFieldDisabled()}
          onBlur={this.onBlur}
          value={this.state.textFieldVal}
          id={`text-field-${form}-${field}-${rowIndex}`}
          fullWidth
          style={{ height: '100%', width: '100%' }}
          inputRef={el => (this.textField = el)}
          forwardRef
          variant={this.props.variant}
          multiline={this.props.multiline}
          rows={this.props.rows}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TextFieldGridCell)
