import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import Measure from 'react-measure'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { Button } from '@material-ui/core'
import { DDISelectField } from 'ddiForm/wrapped'
import { createFooterTotals, postProcessPopup } from 'hoc/withRowTotals/utils'
import {
  emptyList,
  formatDate,
  formatNumber,
  getIn,
  plainDeepEqual
} from 'utils'

import {
  getCellStyle,
  setGridHeight,
  sizeDetailColumns,
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/VendorMaster/utils'

import { getPaymentHistoryReport as getPaymentHistoryReportAction } from 'pages/VendorMaster/actions'
import DollarGridCell from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/DollarGridCell'
import InvoiceLinkCell from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/InvoiceLinkCell'
import AwaitingBackdrop from './AwaitingBackdrop'

import '../styles/ledger-styles.scss'
import { getAttachments } from '../../../../../components/MasterScreen/Attachments/actions'

export const getDetailColumnField = memoize(field => {
  const mapping = {
    amount: 'balanceDue',
    bankGLId: 'branchId',
    discountTaken: 'discount',
    number: 'dataId',
    postingDate: 'invoiceDate',
    reference: 'pOorReferenceNumber'
  }

  if (mapping[field]) {
    return mapping[field]
  }

  return field
})

export const setExpandableGridRowHeight = params => {
  if (params?.node?.detail && params?.data?.invoices) {
    return setGridHeight(params.data.invoices, 3, 0)
  }

  return 28
}

export const getPinnedRowData = memoize(grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      acc.grossAmount += next.grossAmount
      acc.discountTaken += next.discountTaken
      acc.amount += next.amount
      return acc
    },
    { dataId: 'Totals', amount: 0.0, discountTaken: 0.0, grossAmount: 0.0 }
  )

  return [result]
}, plainDeepEqual)

let gridApi = null
let columnApi = null
let _isMounted = false

const PaymentHistoryMain = ({
  dataId,
  dimensions,
  form,
  gridMaxHeight,
  height,
  isEditing,
  paymentHistoryYear,
  paymentsList,
  defaultYear,
  yearOptionsList,
  fetchingEntity
}) => {
  const dispatch = useDispatch()

  const [inputDimensions, setDimensions] = useState({
    width: -1,
    height: -1
  })

  const paymentHistory = paymentsList?.toJS ? paymentsList.toJS() : []
  const yearOptions = yearOptionsList?.toJS ? yearOptionsList.toJS() : []

  const getContextMenuItems = params => {
    const {
      node: {
        data: { dataId, uniqueKey, id }
      }
    } = params

    const items = [
      {
        name: 'Attachments',
        action: () => {
          dispatch(
            getAttachments.try(form, {
              uniqueKey,
              targetType: 'ledgerPaymentHistory',
              modalTitle: `Attachments for Inv - ${dataId}`,
              paymentHistoryYear
            })
          )
        }
      },
      'rows',
      'exportTo',
      'filter',
      'layout'
    ]

    return items
  }

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const columnDefs = [
    {
      cellClass: 'cell-value-hidden expander-cell',
      cellRenderer: 'agGroupCellRenderer',
      colId: 'dataId_35',
      field: 'number',
      headerName: '',
      filter: false,
      minWidth: 35,
      maxWidth: 35,
      width: 35
    },
    {
      colId: 'number',
      field: 'number',
      headerName: 'Document'
    },
    {
      // field: 'dataId',
      colId: 'dataId_50',
      headerName: '',
      // cellRendererFramework: InvoiceLinkCell,
      cellRendererFramework: DollarGridCell,
      type: 'invoices',
      minWidth: 50,
      width: 50,
      maxWidth: 50
    },
    {
      colId: 'bankGLId',
      field: 'bankGLId',
      headerName: 'Br/Bnk'
    },
    {
      colId: 'statusDescription',
      field: 'statusDescription',
      headerName: 'Status'
    },
    {
      colId: 'postingDate',
      field: 'postingDate',
      headerName: 'Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
      ...dateFilterGridConfigs
    },
    {
      colId: 'reference',
      field: 'reference',
      headerName: 'P/O Ref #'
    },
    {
      colId: 'dueDate',
      field: 'dueDate',
      headerName: 'Due Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
      ...dateFilterGridConfigs
    },
    {
      colId: 'grossAmount',
      field: 'grossAmount',
      headerName: 'Amount',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle,
      ...numberFilterGridConfigs
    },
    {
      colId: 'discountTaken',
      field: 'discountTaken',
      headerName: 'Disc',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      ...numberFilterGridConfigs
    },
    {
      colId: 'discountDate',
      field: 'discountDate',
      headerName: 'Disc Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
      ...dateFilterGridConfigs
    },
    {
      colId: 'amount',
      field: 'amount',
      headerName: 'Net',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle,
      ...numberFilterGridConfigs
    }
  ]

  const detailColumnDefs = columnDefs.reduce((acc, next, idx) => {
    const field = getDetailColumnField(next.field)
    if (idx > 0) {
      let additionalParams =
        next.colId === 'number'
          ? {
              headerClass: 'align-right',
              cellClass: 'align-right'
            }
          : {}

      if (next?.colId === 'dataId_50') {
        additionalParams = {
          cellRendererFramework: InvoiceLinkCell
        }
      }

      const colDef = {
        ...next,
        colId: field,
        field,
        ...additionalParams
      }

      acc = acc.concat(colDef)
    }

    return acc
  }, [])

  const isRowMaster = row => {
    return !!(
      row?.invoices &&
      Array.isArray(row.invoices) &&
      row?.invoices?.length
    )
  }

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  const onCompleteGridResizeActions = () => {
    setTimeout(() => {
      sizeDetailColumns(columnApi, gridApi, _isMounted, getDetailColumnField)
    }, 0)
  }

  const getPaymentHistoryReport = e =>
    dispatch(getPaymentHistoryReportAction.try(form))

  const getGridTitle = memoize((year, options) => {
    let title = 'Payment History'
    if (options && Array.isArray(options) && options?.length) {
      const description =
        options.find(x => x.dataId === year)?.description || ''
      title = description ? `${description} Payment History` : title
    }

    return title
  }, plainDeepEqual)

  const redrawFooter = ({ api }) =>
    createFooterTotals(api, getPinnedRowData, _isMounted, false)

  return (
    <div style={{ width: '100%' }}>
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="vendor-ledger-inputs-wrapper">
            <div className="vendor-ledger-inputs">
              <DDISelectField
                label="Year"
                propertyName="paymentHistory.paymentHistoryYear"
                values={yearOptions}
                initialValue={defaultYear}
                preventAutoDisable
              />
            </div>
            <div className="button-set-wrapper">
              <Button
                size="small"
                variant="contained"
                disabled={!dataId}
                onClick={getPaymentHistoryReport}
              >
                Report
              </Button>
            </div>
          </div>
        )}
      </Measure>
      <div
        className="vendor-ledger-grid-wrapper"
        style={{ position: 'relative' }}
      >
        <AwaitingBackdrop fetchingEntity={fetchingEntity} />
        <Grid
          title={getGridTitle(paymentHistoryYear, yearOptions)}
          headerStyle={headerStyle}
          height={gridMaxHeight - inputDimensions?.height}
          columnDefs={columnDefs}
          rowData={paymentHistory}
          getRowNodeId={data => data.number}
          pinnedBottomRowData={getPinnedRowData(paymentHistory)}
          suppressRowClickSelection
          applyColumnDefOrder
          key={`${dataId}-${paymentHistoryYear}`}
          masterDetail
          getContextMenuItems={getContextMenuItems}
          onGridReady={onGridReady}
          getRowHeight={setExpandableGridRowHeight}
          isRowMaster={isRowMaster}
          onColumnResized={onCompleteGridResizeActions}
          onRowGroupOpened={onCompleteGridResizeActions}
          reactUi={false}
          detailCellRendererParams={{
            detailGridOptions: {
              suppressAutoSize: true,
              animateRows: true,
              enableColResize: false,
              // headerHeight: 0,
              suppressHorizontalScroll: true,
              getRowNodeId: data => data.dataId,
              columnDefs: detailColumnDefs,
              getContextMenuItems
            },
            getDetailRowData: params => {
              const invoiceData =
                params?.data?.invoices && Array.isArray(params?.data?.invoices)
                  ? params?.data?.invoices
                  : []

              params.successCallback(invoiceData)
              setTimeout(() => {
                sizeDetailColumns(
                  columnApi,
                  gridApi,
                  _isMounted,
                  getDetailColumnField
                )
              }, 0)
            },
            template:
              '<div class="vl-outer-detail-wrapper outer-detail-wrapper" style="padding: 0; box-sizing: border-box;">' +
              '  <div ref="eDetailGrid" style="height: 100%;"></div>' +
              '</div>'
          }}
          postProcessPopup={postProcessPopup}
          onFilterChanged={redrawFooter}
          onRowDataChanged={redrawFooter}
          onRowDataUpdated={redrawFooter}
        />
      </div>
    </div>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  isEditing: form => form.get('isEditing') || false,
  paymentHistoryYear: form =>
    getIn(form, 'fields.paymentHistory.paymentHistoryYear.value') || '',
  paymentsList: form =>
    getIn(form, 'values.paymentHistory.payments') || emptyList,
  yearOptionsList: form =>
    getIn(form, 'meta.ledgerMeta.yearOptions') || emptyList,
  defaultYear: form => getIn(form, 'meta.ledgerMeta.defaultYear') || '',
  fetchingEntity: form => form.get('fetchingEntity') || false
})(PaymentHistoryMain)
