import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

export const WarehouseTooltipCell = props => (<GridLinkCell {...props} />)

export default TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'warehouse',
  keyToActionMap: {
    m: () => openScreenAction({
      name: 'warehouse',
      title: 'Warehouse',
      // image: '',
    }),
    g: params => {
      const { url = '' } = params
      window.open(url, '_blank')
    }
  },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(WarehouseTooltipCell)
