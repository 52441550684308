/* eslint react/prefer-stateless-function: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { getMeta, getIn, empty } from 'utils'
import EditableGrid from 'components/EditableGrid'
import ProductTooltipWithIndexSearchCell from 'pages/CustomerMaster/components/grid/ProductTooltipWithIndexSearchCell'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import UOMGridCell from 'components/EditableGrid/components/UOMGridCell'
import DynamicDropdown from 'components/EditableGrid/components/DynamicDropdown'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import withDimensions from 'hoc/withDimensions'

const columnDefs = props => {
  const params = {
    disabled: !props.isEditing,
    focusedCell: props.focusedCell,
    form: props.form,
    // metaData: props.metaData,
    indexSearchType: 'product',
    onChange: props.onCellChange,
    propertyName: props.propertyName
  }

  const textEditor = {
    cellRendererFramework: TextFieldGridCell,
    cellRendererParams: params
  }

  const colDefs = [
    {
      cellRendererFramework: ProductTooltipWithIndexSearchCell,
      cellRendererParams: {
        ...params,
        meta: {
          allowInstantSearch: true,
          allowSearchAll: false,
          hasFilters: true,
          minimumKeywordLength: 2
        }
      },
      field: 'dataId',
      headerName: 'Product',
      width: 200
    },
    {
      cellStyle: {
        color: props.isEditing ? '#444' : '#999'
      },
      editable: false,
      field: 'description',
      headerName: 'Description',
      suppressNavigable: true
    },
    {
      cellRendererFramework: UOMGridCell,
      cellRendererParams: {
        ...params,
        meta: props.meta
      },
      cellStyle: {
        paddingLeft: 0,
        paddingRight: 0
      },
      field: 'uomId',
      headerName: 'UOM'
    },
    {
      ...textEditor,
      cellRendererParams: {
        ...textEditor.cellRendererParams,
        requiredLessThan: 'maximumQuantity',
        errorMessage:
          'Minimum quantity cannot be greater than maximum quantity',
        noDecimalLimit: true,
        formatter: 'number',
        textAlign: 'right',
        allowNegative: false
      },
      editable: false,
      field: 'minimumQuantity',
      headerName: 'Minimum Qty',
      headerClass: 'align-right',
      width: 120
    },
    {
      ...textEditor,
      cellRendererParams: {
        ...textEditor.cellRendererParams,
        requiredIf: 'location',
        errorMessage: 'Maximum quantity is required',
        noDecimalLimit: true,
        formatter: 'number',
        textAlign: 'right',
        allowNegative: false
      },
      editable: false,
      field: 'maximumQuantity',
      headerName: 'Maximum Qty',
      headerClass: 'align-right',
      width: 120
    },
    {
      cellRendererFramework: DynamicDropdown,
      cellRendererParams: {
        ...params,
        valueField: 'location'
      },
      cellStyle: {
        paddingLeft: 0,
        paddingRight: 0
      },
      editable: false,
      field: 'location',
      headerName: 'Location'
    },
    {
      cellRendererFramework: DeleteGridRowCell,
      cellRendererParams: {
        ...params
      },
      hide: !props.isEditing,
      maxWidth: 50,
      width: 50
    }
  ]

  // if (props.isEditing) {
  //   colDefs = includeDeleteGridCell(colDefs, props.propertyName, props.form)
  // }

  return colDefs
}

const StockLevels = ({ height, metaMap, isEditing }) => {
  const meta = metaMap?.toJS ? metaMap.toJS() : {}

  return (
    <div style={{ width: '100%', paddingBottom: isEditing ? 30 : 0 }}>
      <EditableGrid
        addButtonText="Add Stock Level"
        columnDefs={columnDefs}
        emptyRow={{
          dataId: null,
          description: '',
          uomId: '',
          minimumQuantity: '',
          maximumQuantity: '',
          location: ''
        }}
        getRowNodeId={data => data.dataId}
        height={400}
        form="customerShipTo"
        meta={meta}
        focusCell="uomId"
        isEditing={isEditing}
        propertyName="customerStockLevels.stockMinimums"
        title={
          <span style={{ display: 'block', width: '100%' }}>
            <span
              style={{
                display: 'block',
                float: 'left',
                paddingLeft: 10,
                textAlign: 'left'
              }}
            >
              Stock Levels
            </span>
            <span
              style={{
                display: 'block',
                fontSize: 11,
                fontStyle: 'italic',
                paddingRight: 10,
                textAlign: 'right'
              }}
            >
              To use automated managed inventory functionality, a maximum qty
              value is required
            </span>
          </span>
        }
        requiredCols={['dataId', 'description']}
      />
    </div>
  )
}

StockLevels.propTypes = {
  height: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired
}

// const stockLevelsWithContext = withContext({
//   isEditing: form => form.isEditing || false,
//   meta: form =>
//     form.fields &&
//     form.fields.customerStockLevels &&
//     form.fields.customerStockLevels.stockMinimums &&
//     form.fields.customerStockLevels.stockMinimums.meta
//       ? form.fields.customerStockLevels.stockMinimums.meta
//       : {}
// })(StockLevels)
const stockLevelsWithContext = withContext({
  isEditing: form => form.get('isEditing') || false,
  metaMap: form =>
    getIn(form, 'fields.customerStockLevels.stockMinimums.meta') || empty
})(StockLevels)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  stockLevelsWithContext
)
