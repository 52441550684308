/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { Col, Row } from 'react-bootstrap'
import {
  Button,
  FormControl,
  IconButton,
  Popover,
  Icon,
  Input,
  InputLabel,
  TextField
} from '@material-ui/core'
// import { NumericTextField } from 'components/masks'
import { NumberFormatter } from 'components/EditableGrid/components/TextFieldGridCell'
import { getErrorIcon } from 'components/EditableGrid/utils'
import { toLower } from 'lodash'
import { getIn } from 'utils'
import {
  calculateRollupPrice,
  clearRollUpCalculationError,
  setRollupAmount
} from 'pages/ProductMaster/actions'

const mapStateToProps = (state, ownProps) => ({
  rollUpCalculationError:
    getIn(state, 'ddiForm.productMaster.rollUpCalculationError') || false
})

class RollupAmountCalculator extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    rollUpCalculationError: PropTypes.bool.isRequired,
    rowIndex: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      amount: props.value ? props.value : null,
      isEditing: false,
      popoverIsOpen: false,
      grossProfit: null,
      multiplier: null,
      chainDiscount: '',
      rollUpCalculationError: props.rollUpCalculationError || false
    }

    const {
      colDef: {
        cellRendererParams: { disabledFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.amount && !prevState.isEditing) {
      return { amount: nextProps.value }
    }

    if (
      // nextProps.rollUpCalculationError === true &&
      nextProps.rollUpCalculationError !== prevState.rollUpCalculationError
    ) {
      return {
        grossProfit:
          nextProps.rollUpCalculationError === true
            ? null
            : prevState.grossProfit,
        multiplier:
          nextProps.rollUpCalculationError === true
            ? null
            : prevState.multiplier,
        chainDiscount:
          nextProps.rollUpCalculationError === true
            ? ''
            : prevState.chainDiscount,
        rollUpCalculationError: nextProps.rollUpCalculationError
      }
    }

    return null
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this.popoverAnchor)
  }

  openPopover = event => {
    this.setState({
      anchorEl: event.currentTarget,
      popoverIsOpen: true
    })
  }

  closePopover = () => {
    const { form } = this.props

    this.setState({ popoverIsOpen: false }, () =>
      this.props.dispatch(clearRollUpCalculationError(form))
    )
  }

  onChange = id => event => {
    const { value } = event.target
    this.setState({ [id]: value, isEditing: true, isDirty: true })
  }

  onValueChange = (values, inputId) =>
    this.setState({
      [inputId]: values.floatValue || ''
    })

  renderNumberInput = memoize((id, editable, label = '', annotation = '') => (
    <div>
      <FormControl>
        {label ? (
          <label
            htmlFor={id}
            style={{ color: '#517b9c', margin: 0, fontSize: 11 }}
          >
            {label}
          </label>
        ) : null}
        {id === 'chainDiscount' ? (
          <TextField
            id={id}
            value={this.state[id]}
            fullWidth
            inputRef={c => (this.popoverAnchor = c)}
            onFocus={this.onFocus}
            onChange={this.onChange(id)}
            onBlur={this.onBlur}
            disabled={!editable}
          />
        ) : (
          <TextField
            id={id}
            value={this.state[id]}
            fullWidth
            inputRef={c => (this.popoverAnchor = c)}
            InputProps={{
              inputComponent: NumberFormatter
            }}
            onFocus={this.onFocus}
            onChange={values => this.onValueChange(values, id)}
            onBlur={this.onBlur}
            inputProps={{
              decimalScale: id === 'grossProfit' ? 2 : 4,
              decimalSeparator: '.',
              fixedDecimalScale: id === 'amount' || false,
              paddingRight: id === 'amount' ? 30 : 0,
              textAlign: 'right',
              thousandSeparator: ','
            }}
            disabled={!editable}
          />
        )}
      </FormControl>
      {annotation ? (
        <span
          style={{ fontSize: 10, fontStyle: 'italic', textAlign: 'center' }}
        >
          {annotation}
        </span>
      ) : null}
    </div>
  ))

  onBlur = event => {
    const input = event.target.id
    const value = this.state[input]
    const {
      form,
      rowIndex,
      data: { dataId }
    } = this.props
    // debugger
    // console.log('Blur', event, value, this.props)
    this.setState(
      {
        isEditing: false
      },
      () => {
        if (value && input !== 'amount') {
          this.props.dispatch(
            calculateRollupPrice(form, {
              rollupId: dataId,
              rowIndex,
              value,
              route: toLower(input)
            })
          )
        } else if (input === 'amount') {
          this.props.dispatch(setRollupAmount(form, { rowIndex, value }))
        }
      }
    )
  }

  onFocus = () => this.setState({ isEditing: true })

  isFieldDisabled = () => {
    const {
      colDef: {
        cellRendererParams: { disabledFn }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return false
  }

  showRequiredIcon = () => {
    const {
      data: { basePriceId, amount }
    } = this.props

    if (basePriceId && !amount) {
      return getErrorIcon()
    }

    return null
  }

  render() {
    const isFieldDisabled = this.isFieldDisabled()
    const {
      colDef: {
        cellRendererParams: { hasRecord, isEditing }
      }
    } = this.props
    const editable = hasRecord && isEditing
    const { popoverIsOpen } = this.state

    return (
      <div style={{ height: '100%', position: 'relative', width: 150 }}>
        <div
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            zIndex: 1
          }}
        >
          <div
            style={{ alignItems: 'center', display: 'flex', height: '100%' }}
          >
            {this.showRequiredIcon()}
            {this.renderNumberInput('amount', editable)}
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 2
          }}
        >
          <IconButton
            disabled={!editable}
            onClick={this.openPopover}
            style={{ width: 24, height: 24, padding: '2px 5px' }}
          >
            <Icon>keyboard_arrow_down</Icon>
          </IconButton>
          <Popover
            open={popoverIsOpen}
            anchorEl={this.anchorEl}
            onClose={this.closePopover}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            PaperProps={{
              style: {
                width: 300
              }
            }}
          >
            <div style={{ padding: 20, width: 300 }}>
              <div style={{ marginBottom: 15 }}>
                {this.renderNumberInput(
                  'grossProfit',
                  editable,
                  'Enter Desired Gross Profit'
                )}
              </div>
              <div style={{ marginBottom: 15 }}>
                {this.renderNumberInput(
                  'multiplier',
                  editable,
                  'Enter Desired Multiplier'
                )}
              </div>
              <div style={{ marginBottom: 15 }}>
                {this.renderNumberInput(
                  'chainDiscount',
                  editable,
                  'Enter CHAIN DISCOUNT',
                  'Separate by slashes, Example: 50/10/2-5'
                )}
              </div>
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(RollupAmountCalculator)
