import { ddiForm } from 'ddiForm'
import memoize from 'memoize-one'
import MeasuredModal from 'components/MasterScreen/MeasuredModal'
import { getProductInSerialNumberInquiry } from './api'
import behaviors from './behaviors'
import sagas, {
  saveArgumentsSaga,
  saveProcess,
  getEntityProcess
} from './sagas'
import * as actions from './actions'

import RightFooterButton from './components/RightFooterButton'
import SearchArea from './components/SearchArea'

import CompleteSerialHistory from './tabs/CompleteSerialHistory'
import CurrentInventory from './tabs/CurrentInventory'
import PurchaseOrderHistory from './tabs/PurchaseOrderHistory'
import SalesHistory from './tabs/SalesHistory'
import TransferHistory from './tabs/TransferHistory'
import UsedForWorkOrder from './tabs/UsedForWorkOrder'
import WorkOrder from './tabs/WorkOrder'
import './styles/master-style.scss'

const options = {
  apiAlias: 'productSerialNumberInquiry',
  form: 'productSerialNumberInquiry',
  title: 'Serial Number Inquiry',
  masterOptions: {
    headerComponent: SearchArea,
    hideReportButton: true,
    footer: {
      right: RightFooterButton
    },
    saveArgumentsSaga,
    tabs: [
      {
        component: CurrentInventory,
        title: 'Current Inventory',
        // access: 'currentInventory',
        access: x => getEntityProcess(x, 'currentInventory'),
        callAlways: true
      },
      {
        component: SalesHistory,
        title: 'Sales History',
        access: x => getEntityProcess(x, 'salesHistory'),
        callAlways: true,
        isHidden: formState => formState.isEditing
      },
      {
        component: PurchaseOrderHistory,
        title: 'Purchase Order History',
        access: x => getEntityProcess(x, 'poHistory'),
        callAlways: true,
        isHidden: formState => formState.isEditing
      },
      {
        component: TransferHistory,
        title: 'Transfer History',
        access: x => getEntityProcess(x, 'transferHistory'),
        callAlways: true,
        isHidden: formState => formState.isEditing
      },
      {
        component: WorkOrder,
        title: 'Work Order',
        access: x => getEntityProcess(x, 'workOrder'),
        // callAlways: true,
        isHidden: formState => formState.isEditing
      },
      {
        component: UsedForWorkOrder,
        title: 'Used For Work Order',
        access: x => getEntityProcess(x, 'usedForWorkOrder'),
        callAlways: true,
        isHidden: formState => formState.isEditing
      },
      {
        component: CompleteSerialHistory,
        title: 'Complete Serial History',
        access: x => getEntityProcess(x, 'completeSerialHistory'),
        callAlways: true,
        isHidden: formState => formState.isEditing
      }
    ]
  },
  getEntityApi: getProductInSerialNumberInquiry,
  actions: {
    cancelProductEdit: actions.cancelProductEdit.try,
    saveProduct: actions.saveProduct.try
  },
  behaviors,
  sagas: { onInit: sagas, onSave: saveProcess }
}

export const ProductSerialNumberInquiryInModal = memoize(form =>
  ddiForm({
    ...options,
    form
  })(MeasuredModal)
)

export default ddiForm(options)()
