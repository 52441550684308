/* eslint func-names: 0 */
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { debounce } from 'lodash'

const createTrackingURL = (vendor, val) => {
  if (vendor && vendor.match(/ups\.com/gi)) {
    return `https://www.ups.com/track?loc=en_US&tracknum=${val}&requester=WT/`
  }

  if (vendor && vendor.match(/fedex\.com/gi)) {
    return `https://www.fedex.com/fedextrack/?action=track&tracknumbers=${val}&locale=en_US&cntry_code=us`
  }

  return vendor
}

function TrackingNumberCellRenderer() {}

// gets called once before the renderer is used
TrackingNumberCellRenderer.prototype.init = function(params) {
  // console.log(params)
  // create the cell
  this.eGui = document.createElement('div')
  this.eGui.innerHTML = `<span class="btn-tracking-link-wrapper"><button class="btn-tracking-link" style="padding: 0;"><span class="tracking-number-link-value"></span></button></span>`
  // get references to the elements we want

  this.eButton = this.eGui.querySelector('.btn-tracking-link')
  this.eButton.style.textDecoration = 'underline'
  this.eButton.style.color = '#0000ff'
  this.eValue = this.eGui.querySelector('.tracking-number-link-value')

  // set value into cell
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value

  // add event listener to button
  this.eventListener = function() {
    // dispatch
    const {
      colDef: {
        cellRendererParams: { carrierUrl }
      },
      value
    } = params

    if (carrierUrl && value) {
      window.open(createTrackingURL(carrierUrl, value), '_blank')
    }
  }
  this.eventListener = debounce(this.eventListener, 2000, {
    leading: true,
    trailing: false
  })

  this.eButton.addEventListener('click', this.eventListener)
}

// gets called once when grid ready to insert the element
TrackingNumberCellRenderer.prototype.getGui = function() {
  return this.eGui
}

// gets called whenever the user gets the cell to refresh
TrackingNumberCellRenderer.prototype.refresh = function(params) {
  // set value into cell again
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value
  // return true to tell the grid we refreshed successfully
  return true
}

// gets called when the cell is removed from the grid
TrackingNumberCellRenderer.prototype.destroy = function() {
  this.eButton.removeEventListener('click', this.eventListener)
}

export default TrackingNumberCellRenderer
