import { startCase } from 'lodash'
import BooleanCellRenderer from './BooleanCellRenderer'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

const getColumnDefs = (lotNumberColumn, searchType, visibilityParams = {}) => {
  let colDefs = [
    lotNumberColumn,
    {
      editable: false,
      field: 'description',
      headerName: 'Description'
    }
  ]

  if (searchType === '\u0000') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'warehouseIdAndDescription',
        headerName: 'Warehouse',
        hide: !visibilityParams?.showWarehouse,
        ...centerAlignParams
      },
      {
        field: 'lotDate',
        ...centerAlignParams
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'onOpenSalesOrderQuantity',
        headerName: 'On S/O',
        ...rightAlignParams
      },
      {
        field: 'onOpenStockTransferQuantity',
        headerName: 'On S/T',
        ...rightAlignParams
      },
      {
        field: 'factoredQuantityOnHand',
        headerName: 'On Hand',
        ...rightAlignParams
      },
      {
        field: 'factoredQuantityAvailable',
        headerName: 'Available',
        ...rightAlignParams
      }
    ]
  }

  if (searchType === 'A') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'inventoryAdjustmentId',
        headerName: 'Adjustment'
      },
      {
        field: 'adjustmentDate',
        headerName: 'Adjusted Date',
        ...centerAlignParams
      },
      {
        field: 'warehouseId',
        headerName: 'Whse'
      },
      {
        field: 'description',
        headerName: 'Type'
      },
      {
        field: 'adjustmentQuantity',
        headerName: 'Adjusted Lot Qty',
        ...rightAlignParams
      },
      {
        field: 'enteredBy',
        headerName: 'Entered By'
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'uomId',
        headerName: 'UOM'
      },
      {
        field: 'quantityFactor',
        headerName: 'Qty Factor',
        ...rightAlignParams
      }
    ]
  }

  if (searchType === 'W') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'description',
        headerName: 'Lot Description'
      },
      {
        field: 'workOrderId',
        headerName: 'W/O Note'
      },
      {
        field: 'workOrderEntryDate',
        headerName: 'W/O Date',
        ...centerAlignParams
      },
      {
        field: 'workOrderCompletedDate',
        headerName: 'W/O Completed Date',
        ...centerAlignParams
      },
      {
        field: 'warehouse',
        headerName: 'Warehouse'
      },
      {
        field: 'typeDescription',
        headerName: 'Type'
      },
      {
        field: 'parentProductId',
        headerName: 'Parent Product'
      },
      {
        field: 'lotQuantity',
        headerName: 'Lot Qty',
        ...rightAlignParams
      },
      {
        field: 'enteredBy',
        headerName: 'Entered By'
      },
      {
        field: 'completedBy',
        headerName: 'Completed By'
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'uomId',
        headerName: 'UOM'
      },
      {
        field: 'quantityFactor',
        headerName: 'Qty Factor',
        ...rightAlignParams
      }
    ]
  }

  if (searchType === 'L') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No',
        hide: !visibilityParams?.showProduct
      },
      {
        field: 'lotDate',
        headerName: 'Lot Date',
        ...centerAlignParams
      },
      {
        field: 'manufacturedDate',
        headerName: 'Mfg Date',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        headerName: 'Exp Date',
        ...centerAlignParams
      }
    ]
  }

  if (searchType === 'P') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'purchaseOrderId',
        headerName: 'P/O Number'
      },
      {
        field: 'purchaseOrderDate',
        headerName: 'Date',
        ...centerAlignParams
      },

      {
        field: 'vendorId',
        headerName: 'Vendor Name'
      },
      {
        field: 'buyerId',
        headerName: 'Buyer'
      },
      {
        field: 'receivingDate',
        headerName: 'Rcvd Date',
        ...centerAlignParams
      },
      {
        field: 'receivedBy',
        headerName: 'received By'
      },
      {
        field: 'quantityReceived',
        headerName: 'Qty Received',
        ...rightAlignParams
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'receivingId',
        headerName: 'Rec Num'
      },
      {
        field: 'uomId',
        headerName: 'UOM'
      },
      {
        field: 'quantityFactor',
        headerName: 'Qty Factor',
        ...rightAlignParams
      }
    ]
  }

  if (searchType === 'S') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'description',
        headerName: 'Description'
      },
      {
        field: 'customerId',
        headerName: 'Customer Number'
      },
      {
        field: 'customerName',
        headerName: 'Customer Name'
      },
      {
        field: 'salesOrderId',
        headerName: 'Order Number'
      },
      {
        field: 'salesOrderDate',
        headerName: 'Order Date',
        ...centerAlignParams
      },
      {
        field: 'shipDate',
        headerName: 'Ship Date',
        ...centerAlignParams
      },

      {
        field: 'invoiceId',
        headerName: 'Invoice No'
      },
      {
        field: 'invoiceDate',
        headerName: 'Invoice Date',
        ...centerAlignParams
      },
      {
        field: 'customerPoNumber',
        headerName: 'Customer P/O'
      },
      {
        field: 'branchId',
        headerName: 'Branch'
      },
      {
        field: 'salesmanId',
        headerName: 'Salesman'
      },
      {
        field: 'writer'
      },
      {
        field: 'shipViaId',
        headerName: 'Ship Via'
      },
      {
        field: 'shipViaDescription',
        headerName: 'Ship Via Desc'
      },
      {
        field: 'quantityShipped',
        headerName: 'Qty Shipped',
        ...rightAlignParams
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'uomId',
        headerName: 'UOM'
      },
      {
        field: 'quantityFactor',
        headerName: 'Qty Factor',
        ...rightAlignParams
      }
    ]
  }

  if (searchType === 'T') {
    colDefs = [
      lotNumberColumn,
      {
        field: 'productId',
        headerName: 'Product No'
      },
      {
        field: 'transferId',
        headerName: 'Trans Item Num'
      },
      {
        field: 'transferDate',
        headerName: 'Trans Date',
        ...centerAlignParams
      },
      {
        field: 'fromWarehouseId',
        headerName: 'From Whse'
      },
      {
        field: 'toWarehouseId',
        headerName: 'To Whse'
      },
      {
        field: 'transferQuantity',
        headerName: 'Trans Qty',
        ...rightAlignParams
      },
      {
        field: 'transferQuantityBalance',
        headerName: 'Trans Qty Bal',
        ...rightAlignParams
      },
      {
        field: 'receivedDate',
        headerName: 'Received Date',
        ...centerAlignParams
      },
      {
        field: 'quantityReceived',
        headerName: 'Received Qty',
        ...rightAlignParams
      },
      {
        field: 'receivedById',
        headerName: 'Received By'
      },
      {
        field: 'manufacturedDate',
        ...centerAlignParams
      },
      {
        field: 'expirationDate',
        ...centerAlignParams
      },
      {
        field: 'uomId',
        headerName: 'UOM'
      },
      {
        field: 'quantityFactor',
        headerName: 'Qty Factor',
        ...rightAlignParams
      }
    ]
  }

  return colDefs.reduce((acc, next) => {
    acc =
      next === 'name'
        ? acc.concat({
            ...next,
            minWidth: 125
          })
        : acc.concat({
            ...next,
            id: next?.field,
            headerName: next?.headerName
              ? next.headerName
              : next?.field
              ? startCase(next.field)
              : '',
            editable: false,
            minWidth: 125
          })

    return acc
  }, [])
}

export default getColumnDefs
