/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Popover } from '@material-ui/core'

class ButtonPopover extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    popoverMinWidth: PropTypes.number,
    popoverTitle: PropTypes.string
  }

  static defaultProps = {
    disabled: false,
    popoverMinWidth: 400,
    popoverTitle: 'Open Sesame'
  }

  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null
    }
  }

  handleClick = event => {
    // debugger
    this.setState({
      anchorEl: event.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  render() {
    const { disabled, popoverMinWidth, popoverTitle } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <div>
        <Button
          aria-owns={open ? 'simple-popper' : undefined}
          aria-haspopup="true"
          disabled={disabled}
          variant="contained"
          onClick={this.handleClick}
        >
          {popoverTitle}
        </Button>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div style={{ minWidth: popoverMinWidth }}>{this.props.children}</div>
        </Popover>
      </div>
    )
  }
}

export default ButtonPopover
