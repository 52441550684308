import React, { Component } from 'react'
import {
  Input,
  InputAdornment,
  InputLabel,
  ButtonBase,
  Icon,
  IconButton,
  FormControl
} from '@material-ui/core'

import MDIIcon from '@mdi/react'
import { mdiBarcodeScan } from '@mdi/js'
import shouldUpdate from 'hoc/shouldUpdate'
import { debounce } from 'lodash'
import { noop } from 'utils'
import BarcodeScanner from './BarcodeScanner'

const whitelist = [
  'value',
  'mouseover',
  'disabled',
  'meta',
  'isSet',
  'disableSearchAll'
]

const toDebounce = [
  'onSearchClick',
  'redoSearch',
  'findPrev',
  'findNext',
  'showQuickEntityClick'
]

export class TextInput extends Component {
  static defaultProps = {
    navigationArrowStyles: {
      iconStyle: { fontSize: 16, padding: 0 },
      style: {
        // float: 'right',
        height: 20,
        marginTop: 4,
        padding: 0,
        width: 20
      }
    },
    navWrapper: {
      // float: 'right',
      height: 20,
      marginTop: 4,
      padding: 0,
      width: 40
    },
    iconStyle: {
      //       /* start INFORM custom colors */
      // export const ddiBlue = '#006699'
      // export const informBlue = '#517b9c'
      color: '#006699',
      fill: '#006699',
      fontSize: 16,
      height: 16,
      width: 20,
      outline: 'none'
    },
    scanIconStyle: {
      //       /* start INFORM custom colors */
      // export const ddiBlue = '#006699'
      // export const informBlue = '#517b9c'
      color: 'white',
      fill: '#006699',
      fontSize: 16,
      height: 16,
      width: 20,
      outline: 'none'
    },
    onSearchClick: noop,
    getSearchFilters: noop,
    showQuickEntityClick: noop,
    redoSearch: noop,
    findPrev: noop,
    findNext: noop
  }

  constructor(...args) {
    super(...args)
    toDebounce.forEach(prop => {
      Object.assign(this, {
        [prop]: debounce((...args) => this.props[prop](...args), 1000, {
          leading: true
        })
      })
    })
    this.state = {
      showScanner: false
    }
  }

  componentDidMount() {
    if (this.props.autoFocus && this.textInput) {
      setTimeout(() => this.textInput && this.textInput.focus(), 1)
    }
  }

  onFocus = e => {
    e.persist()
    if (this.textInput) this.textInput.select()
    if (this.props.onFocus) {
      this.props.onFocus(e)
    }
  }

  _getSearchFilters = () => {
    const { propertyName } = this.props
    const indexSearchType =
      this.props.indexSearchType ||
      (this.props.meta && this.props.meta.searchType)
    this.props.getSearchFilters({ indexSearchType, propertyName })
  }

  inputRef = c => {
    this.textInput = c
    if (this.props.inputRef) {
      this.props.inputRef(c)
    }
  }

  // onSearchClick = () => this.props.onSearchClick()
  handleBarcodeScanner = () => {
    // debugger
  }

  onUpdate = text => {
    this.setState({ showScanner: false }, () =>
      this.props.handleBarcodeScanner(text)
    )
  }

  showBarcodeScanner = () => this.setState({ showScanner: true })

  closeScan = () => this.setState({ showScanner: false })

  render() {
    const {
      autoFocus,
      inputRef,
      label,
      placeholder,
      id,
      disabled,
      lastSearch,
      value,
      onChange,
      onDoubleClick,
      onFocus,
      onKeyDown,
      onBlur,
      meta = {},
      buttonStyle,
      onSearchClick,
      iconStyle,
      scanIconStyle,
      // disableLastSearchButton,
      mouseover,
      navigationArrowStyles,
      redoSearch,
      isSet,
      findNext,
      findPrev,
      getSearchFilters,
      filtersButtonRef,
      disableSearchAll,
      disableRefresh
    } = this.props
    console.log(this.props, this)

    const isProduct = !!(
      this.props?.meta?.searchType === 'Product' ||
      this.props?.meta?.searchType === 'product' ||
      this.props?.indexSearchType === 'product' ||
      this.props?.indexSearchType === 'Product'
    )
    return (
      <>
        {this.state.showScanner ? (
          <BarcodeScanner closeScan={this.closeScan} onUpdate={this.onUpdate} />
        ) : null}
        <FormControl>
          {label ? <InputLabel color="primary">{label}</InputLabel> : null}
          <Input
            autoFocus={autoFocus}
            inputRef={this.inputRef}
            fullWidth={this.props.fullWidth || true}
            label={label}
            placeholder={placeholder}
            id={id}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onDoubleClick={onDoubleClick}
            onFocus={this.onFocus}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            endAdornment={
              <InputAdornment position="end">
                <div
                  className="utility-buttons"
                  style={{
                    height: 26,
                    zIndex: 2,
                    display: 'flex'
                  }}
                >
                  {meta.allowNavigation &&
                  !disabled &&
                  mouseover &&
                  isSet &&
                  value ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <IconButton
                        className="hvr-bounce-in"
                        style={navigationArrowStyles.style}
                        onClick={findPrev}
                        tabIndex={-1}
                        onDoubleClick={noop}
                      >
                        <Icon
                          // color={informBlue}
                          tabIndex={-1}
                          style={navigationArrowStyles.iconStyle}
                        >
                          keyboard_arrow_left
                        </Icon>
                      </IconButton>
                      <IconButton
                        className="hvr-bounce-in"
                        style={navigationArrowStyles.style}
                        onClick={findNext}
                        tabIndex={-1}
                        onDoubleClick={noop}
                      >
                        <Icon
                          // color={informBlue}
                          tabIndex={-1}
                          style={navigationArrowStyles.iconStyle}
                        >
                          keyboard_arrow_right
                        </Icon>
                      </IconButton>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 20,
                        marginTop: 4,
                        padding: 0,
                        width: 40
                        // border: '2px solid red'
                      }}
                    />
                  )}

                  {!disableRefresh &&
                  meta.allowInstantSearch &&
                  lastSearch &&
                  mouseover &&
                  !disabled ? (
                    <IconButton
                      className="hvr-bounce-in"
                      style={navigationArrowStyles.style}
                      onClick={this.props.redoSearch}
                      tabIndex={-1}
                      onDoubleClick={noop}
                    >
                      <Icon tabIndex={-1} style={{ padding: 0, fontSize: 16 }}>
                        refresh
                      </Icon>
                    </IconButton>
                  ) : (
                    <div
                      style={{
                        height: 20,
                        marginTop: 4,
                        padding: 0,
                        width: 20
                        // border: '2px solid blue'
                      }}
                    />
                  )}
                  {!this.props.disableSearchAll ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius:
                          meta.showQuickEntityButton ||
                          this.props.showQuickEntityButton
                            ? undefined
                            : '0px 5px 5px 0px'
                      }}
                    >
                      {!disableSearchAll && (!value || isSet) ? (
                        <ButtonBase
                          id={`${id}-search-all-trigger`}
                          disabled={disabled}
                          style={{
                            ...buttonStyle,
                            // color: 'red',
                            height: 26,
                            borderRadius:
                              meta.showQuickEntityButton ||
                              this.props.showQuickEntityButton
                                ? undefined
                                : '0px 5px 5px 0px'
                          }}
                          onClick={this.onSearchClick}
                          tabIndex={-1}
                          onDoubleClick={noop}
                        >
                          <Icon
                            tabIndex={-1}
                            style={{
                              ...iconStyle,
                              color: disabled ? '#777' : '#006699',
                              fill: disabled ? '#777' : '#006699'
                            }}
                          >
                            search
                          </Icon>
                        </ButtonBase>
                      ) : null}
                    </div>
                  ) : null}
                  {(meta.showQuickEntityButton ||
                    this.props.showQuickEntityButton) && (
                    <div
                      style={{
                        background: 'rgb(81, 123, 156)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '0px 5px 5px 0px',
                        borderLeft: '1px solid #cc'
                      }}
                    >
                      <ButtonBase
                        id={`${id}-search-all-trigger`}
                        disabled={disabled}
                        style={{
                          ...buttonStyle,
                          height: 26,
                          borderRadius: '0px 5px 5px 0px',
                          background: disabled ? '#ccc' : undefined,
                          borderLeft:
                            meta.allowSearchAll || this.props.allowSearchAll
                              ? '1px solid #ccc'
                              : undefined
                        }}
                        onClick={this.showQuickEntityClick}
                        tabIndex={-1}
                        onDoubleClick={noop}
                      >
                        <Icon tabIndex={-1} style={iconStyle}>
                          add
                        </Icon>
                      </ButtonBase>
                    </div>
                  )}

                  {isProduct && (
                    <div
                      style={{
                        background: 'rgb(81, 123, 156)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '0px 5px 5px 0px',
                        borderLeft: '1px solid #cc'
                      }}
                    >
                      <ButtonBase
                        id={`${id}-scan`}
                        disabled={disabled}
                        style={{
                          ...buttonStyle,
                          height: 26,
                          borderRadius: '0px 5px 5px 0px',
                          padding: '0 5px',
                          background: disabled ? '#ccc' : undefined,
                          borderLeft:
                            meta.allowSearchAll || this.props.allowSearchAll
                              ? '1px solid #ccc'
                              : undefined
                        }}
                        onClick={this.showBarcodeScanner}
                        tabIndex={-1}
                        onDoubleClick={noop}
                      >
                        <MDIIcon
                          tabIndex={-1}
                          style={scanIconStyle}
                          path={mdiBarcodeScan}
                        />
                      </ButtonBase>
                    </div>
                  )}
                </div>
              </InputAdornment>
            }
            inputProps={{
              // style: {
              //   height: 26,
              //   paddingBottom: 0,
              //   paddingTop: 0
              // },
              'data-lpignore': true
            }}
          />
        </FormControl>
      </>
    )
  }
}
export default shouldUpdate({ whitelist })(TextInput)
