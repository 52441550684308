import {
  call,
  take,
  select,
  put,
  fork,
  actionChannel,
  putResolve
} from 'redux-saga/effects'

import { buffers } from 'redux-saga'

import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { updateFormTitle } from 'ddiForm/actions'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import * as DDICONSTANTS from 'ddiForm/constants'
import { api } from 'services'
import { is, getIn, toUpper, toLower, toCamelCase, trimNonWord } from 'utils'
import { showInternalNotesModal } from 'modals/NotesModal/sagas'
import { getGroupNames as getSalesOrderGroupNames } from 'pages/SalesOrder/utils'
import { readTemplate } from 'components/MasterScreen/Template/api'

import * as MASTER_CONSTANTS from './constants'
import {
  getFormSelector,
  getSelectedTabsFromState,
  // mapResponse as defaultMapResponse,
  getMapResponse,
  trimmedCameled
} from '../utils'

// console.log(MASTER_CONSTANTS)
// export function* emptySetFieldListener(form) {
//   const channel = yield actionChannel(
//     DDICONSTANTS.SET_FIELD,
//     buffers.expanding(30)
//   )
//   while (true) {
//     const action = yield take(channel)
//     if (
//       action.meta.form === form &&
//       action.meta.leader &&
//       !action.payload.value
//     ) {
//       if (yield select
//       yield put(masterActions.resetMasterFields(action.meta.form))
//       // title here?
//     }
//   }
// }

export function* clearFormListener(form) {
  const channel = yield actionChannel(INDEX_SEARCH_CONSTANTS.CLEAR_FORM)
  while (true) {
    const action = yield take(channel)
    if (action.meta.form === form) {
      yield putResolve(masterActions.resetMasterFields(action.meta.form))
      yield put({
        type: 'FORM_CLEARED',
        payload: {},
        meta: { form, thunk: action.meta.thunk }
      })
    }
  }
}

// export function* leaderListener(form) {
//   const channel = yield actionChannel(
//     INDEX_SEARCH_CONSTANTS.PARTIAL_MATCH_SEARCH.REQUEST
//   )
//   while (true) {
//     const action = yield take(channel)
//     const hasRecord = yield select(state =>
//       getIn(state, `ddiForm.${form}.hasRecord`)
//     )
//     if (hasRecord) {
//       if (action.meta.form === form && action.payload.leader) {
//         debugger
//         yield put(masterActions.resetMasterFields(action.meta.form))
//       }
//     }
//   }
// }
export function* handleEntityResponse(form, response) {
  const updateTitleMethod = yield select(state =>
    getIn(state, `ddiForm.${form}.updateTitleMethod`)
  )
  if (updateTitleMethod) {
    const title = yield call(updateTitleMethod, response)

    yield put(updateFormTitle(form, title))
  }
  const formState = yield select(getFormSelector(form))
  // console.log(formState.toJS(), response)
  if (
    response.internalNotes &&
    response.showInternalNotes !== false &&
    !getIn(formState, 'notesDisplayed')
  ) {
    // debugger
    yield fork(showInternalNotesModal, response.internalNotes, form, response)
    //
  }
}

export function* lockForEdit({ form, dataId, recordName, ...rest }) {
  yield put(masterActions.lockForEditAsync.request(form))
  const formState = yield select(getFormSelector(form))
  const apiAlias = getIn(formState, 'apiAlias')
  let groupNames = getSelectedTabsFromState(formState)
  if (form.includes('salesOrder-')) {
    // when editing inside  Shipments tab, do not need the other groupNames,
    // but requires 'signature'
    // - LL 10/31/19
    groupNames = getSalesOrderGroupNames(formState)
  }

  if (form === 'salesOrderMobile') {
    /* saveArgumentSaga is not getting picked up in mobile -- SVE 7/30/20 */
    groupNames = ['header', 'detail', 'final', 'signature']
  }

  const isTemplate = getIn(formState, 'values.isTemplate')
  const args = {
    recordName: dataId || recordName,
    form: isTemplate
      ? apiAlias
        ? `${apiAlias}/template`
        : `${form}/template` || form
      : apiAlias || form,
    ...rest,
    groupNames: groupNames.map(trimNonWord)
  }
  if (isTemplate) {
    const templateKey = getIn(formState, 'meta.defaultTemplates[0].dataId')
    args.templateKey = templateKey
  }
  const R = yield call(api.lockForEdit, args)

  // update for mapResponse logic.
  if (R.response) {
    // const mapResponse = yield getMapResponse({ formState })
    // const response = mapResponse(R.response, groupNames, formState)
    const mapResponse = yield getMapResponse({
      formState,
      tabIds: groupNames.map(trimNonWord)
    })
    const response = mapResponse({
      response: R.response,
      formState,
      groupNames: groupNames.map(trimNonWord)
    })

    yield put(
      masterActions.lockForEditAsync.success(response, { form }) // TODO GENERICS... args of cb succ/fail takes async method etc..
    )
    // todo update title
  } else {
    yield put(masterActions.lockForEditAsync.failure(R.error, { form }))
  }
}

export function* getTabAccess({ form, groupName }) {
  const formState = yield select(getFormSelector(form))
  const apiAlias = getIn(formState, 'apiAlias')
  const { response, error } = yield call(api.getTabAccess, {
    groupName,
    form: apiAlias || form
  })
  return { response, error }
}

export function* searchProcess(form, tabs, newRecord, id = 'dataId', value) {
  const formState = yield select(getFormSelector(form))
  // debugger
  tabs = tabs || getSelectedTabsFromState(formState)
  // const originalTabs = tabs
  tabs = tabs
    .map(trimNonWord)
    .map(toCamelCase)
    .filter(x => !!x)
  let access
  if (newRecord || tabs) {
    const dataId = value || getIn(formState, `fields.${id}.value`)
    const tabComponents = getIn(formState, 'masterOptions.tabComponents')
    const masterTabs = getIn(formState, 'masterOptions.tabs')
    // check if the tab is high level?
    if (tabs.length === 1) {
      const v = masterTabs.find(x => toCamelCase(getIn(x, 'title')) === tabs[0])
      access = getIn(v, 'access')
      if (v && getIn(v, 'tabs')) {
        access = getIn(v, 'tabs[0].access')
        if (access && access !== tabs[0]) {
          tabs[1] = access
        }
      }
    } else {
      const tabId = tabs[1] ? `${tabs[0]}-${tabs[1]}` : tabs[0]
      const tab = getIn(tabComponents, tabId)
      access = getIn(tab, 'access')
    }
    if (access && is.fn(access)) {
      // assumption is made here that this acess function will deal with getting some data..
      try {
        yield call(access, form)
        return true
      } catch (e) {
        return false
      }
    } else {
      try {
        yield call(getRecord, {
          form,
          newRecord,
          tabIds: tabs,
          dataId,
          groupNames: [...new Set([...tabs, access])]
        })
        return true
      } catch (e) {
        // if (e && e.message === 'canceled') throw e
        return false
      }
    }
  }
  return true
}

export function* accessProcess(form, groupName) {
  yield put({
    type: 'TAB_ACCESS_REQUEST',
    payload: { groupName },
    meta: {
      form
    }
  })
  const { response, error } = yield call(api.getTabAccess, { form, groupName })
  if (response) {
    yield put({
      type: 'TAB_ACCESS_SUCCESS',
      payload: { groupName },
      meta: {
        form
      }
    })
  } else {
    yield put({
      type: 'TAB_ACCESS_FAILURE',
      payload: { groupName },
      error: true,
      meta: {
        form
      }
    })
    throw new Error('access check canceled')
  }
}

export function* getRecord({ form, newRecord, tabIds, ...rest }) {
  const formState = yield select(getFormSelector(form))
  const apiMethod = yield getIn(formState, 'getEntityApi')
  if (!apiMethod) {
    throw new Error('require api access method.')
  }
  // debugger
  yield put(
    masterActions.getEntityAsync.request(form) // TODO GENERICS... args of cb succ/fail takes async method etc..
  )

  let args = {}

  const getRecordArgumentsSaga = getIn(
    formState,
    'masterOptions.getRecordArgumentsSaga'
  )
  if (getRecordArgumentsSaga && is.fn(getRecordArgumentsSaga)) {
    args = yield call(getRecordArgumentsSaga, form, { ...rest })
  }

  const apiMethodToUse = args && args.templateKey ? readTemplate : apiMethod

  // debugger
  const R = yield call(apiMethodToUse, {
    ...rest,
    ...args
  })

  if (R.response) {
    if (
      tabIds.length > 1 &&
      rest &&
      rest.groupNames &&
      rest.groupNames.length
    ) {
      if (rest.groupNames[0] !== tabIds[1]) {
        tabIds[1] = rest.groupNames[0]
      }
    }
    const mapResponse = yield getMapResponse({ formState, tabIds })
    const response = mapResponse({
      response: R.response,
      tabIds,
      formState,
      groupNames: rest.groupNames
    })
    // debugger

    yield put(
      masterActions.getEntityAsync.success(response, form) // TODO GENERICS... args of cb succ/fail takes async method etc..
    )
    yield fork(handleEntityResponse, form, response)
  } else {
    console.log(R)
    debugger
    if (R.error === 'canceled') {
      yield put(masterActions.getEntityAsync.failure(R.error, form))
      throw new Error('canceled')
    } else {
      yield put(masterActions.getEntityAsync.failure(R.error, form))
    }
  }
}

export function* searchListener(form) {
  const channel = yield actionChannel(DDICONSTANTS.SET_FIELD)
  while (true) {
    const action = yield take(channel)
    if (action.meta.form === form && action.meta.leader) {
      const dataId = action.payload.value
      if (dataId) {
        // debugger
        yield fork(searchProcess, form, null, true, null, dataId)
      }
    }
  }
}

export function* onDoubleClick(form) {
  const channel = yield actionChannel([
    MASTER_CONSTANTS.ON_DOUBLE_CLICK,
    MASTER_CONSTANTS.LOCK_FOR_EDIT.TRY
  ])
  while (true) {
    const action = yield take(channel)
    const {
      payload: { propertyName }
    } = action

    if (form === action.meta.form) {
      const formState = yield select(getFormSelector(form))
      const field = yield select(state =>
        getIn(formState, `fields.${propertyName}`)
      )
      const isLeader = getIn(field, 'leader')
      const hasRecord = getIn(formState, 'hasRecord')
      const isEditing = getIn(formState, 'isEditing')

      if (hasRecord && !isEditing) {
        // alert('let get it!')

        if (
          (action.type === MASTER_CONSTANTS.ON_DOUBLE_CLICK && !isLeader) ||
          action.type === MASTER_CONSTANTS.LOCK_FOR_EDIT.TRY
        ) {
          let args = {}

          const saveArgumentsSaga = getIn(
            formState,
            'masterOptions.saveArgumentsSaga'
          )
          if (saveArgumentsSaga && is.fn(saveArgumentsSaga)) {
            args = yield call(saveArgumentsSaga, form)
          }
          args.dataId =
            getIn(formState, 'values.dataId') ||
            getIn(formState, 'fields.dataId.value')

          // yield fork(lockForEdit, { form, dataId, parentId, parentType })
          // debugger
          yield fork(lockForEdit, { form, ...args })
        }
      }
    }
  }
}

export function* getParentRecordInfo(form) {
  const dataId = yield select(state =>
    getIn(state, `ddiForm.${form}.values.dataId`)
  )
  let parentId
  let parentType
  const parent = yield select(state =>
    getIn(state, `ddiForm.${form}.values.parent`)
  )
  if (parent) {
    parentId = getIn(parent, 'parentId')

    parentType = getIn(parent, 'parentType')
  }

  return {
    dataId,
    parentId,
    parentType
  }
}

export function* cancelEditProcess(form, action) {
  // debugger
  // const isEditing = yield select(state =>
  //   getIn(state, `ddiForm.${form}.isEditing`)
  // )
  let formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing')
  const masterOptions = getIn(formState, 'masterOptions')
  const hideCancelDialogSaga = getIn(masterOptions, 'hideCancelDialogSaga')
  let hideCancelDialog = false

  if (hideCancelDialogSaga) {
    hideCancelDialog = yield call(hideCancelDialogSaga, form)
    if (!is.bool(hideCancelDialog)) {
      hideCancelDialog = false
    }
  }

  let modalAction
  if (isEditing) {
    if (!hideCancelDialog) {
      yield call(
        confirmationModal,
        'All changes will be lost. Continue?',
        'Cancel?'
      )

      modalAction = yield take([CONFIRMED, CANCELED])
    } else {
      modalAction = {
        type: CONFIRMED
      }
    }
    if (modalAction.type === CONFIRMED) {
      yield put(masterActions.preCancelEdit(form))

      let args = {}

      formState = yield select(getFormSelector(form))
      const noAPIForCancelConfirm = getIn(formState, 'noAPIForCancelConfirm')

      let groupNames = getSelectedTabsFromState(formState).map(trimNonWord)
      const cancelEditGroupNamesSaga = getIn(
        formState,
        'masterOptions.cancelEditGroupNamesSaga'
      )
      if (cancelEditGroupNamesSaga) {
        // // debugger
        let tempGroupNames = yield call(cancelEditGroupNamesSaga, form)
        if (!is.array(tempGroupNames)) {
          tempGroupNames = []
        }
        groupNames = new Set([...groupNames, ...tempGroupNames])
        groupNames = Array.from(groupNames)
      }

      if (form === 'salesOrderMobile') {
        /* saveArgumentSaga is not getting picked up in mobile -- SVE 7/30/20 */
        groupNames = ['header', 'detail', 'final', 'signature']
      }

      if (!noAPIForCancelConfirm) {
        const apiAlias = yield select(state =>
          getIn(state, `ddiForm.${form}.apiAlias`)
        )

        const saveArgumentsSaga = getIn(
          formState,
          'masterOptions.saveArgumentsSaga'
        )
        if (saveArgumentsSaga && is.fn(saveArgumentsSaga)) {
          args = yield call(saveArgumentsSaga, form)
        }

        args.dataId =
          getIn(formState, 'values.dataId') ||
          getIn(formState, 'fields.dataId.value')

        const R = yield call(api.cancelEdit, {
          recordName: args.dataId || args.recordName,
          ...args,
          form: apiAlias || form,
          groupNames
        })

        if (R.response) {
          const mapResponse = yield getMapResponse({
            formState,
            tabIds: groupNames
          })

          const response = mapResponse({
            response: R.response.record ? R.response.record : {},
            tabIds: groupNames,
            groupNames,
            formState
          })

          yield put(
            masterActions.cancelEditAsync.success(response, {
              form,
              thunk: action.meta.thunk
            }) // TODO GENERICS... args of cb succ/fail takes async method etc..
          )
        } else {
          yield put(
            masterActions.cancelEditAsync.failure(R.error, {
              form,
              thunk: action.meta.thunk
            })
          )
        }
      } else {
        yield put(
          masterActions.cancelEditAsync.success(
            {},
            {
              form,
              thunk: action.meta.thunk
            }
          ) // TODO GENERICS... args of cb succ/fail takes async method etc..
        )
      }
    } else {
      yield put(
        masterActions.cancelEditAsync.failure(
          {},
          {
            form,
            thunk: action.meta.thunk
          }
        )
      )
    }
  }
}

export function* cancelEditListener(form) {
  const channel = yield actionChannel(MASTER_CONSTANTS.CANCEL_EDIT.TRY)
  // let action = yield take()
  while (true) {
    const action = yield take(channel)
    // debugger
    if (form === action.meta.form) {
      yield fork(cancelEditProcess, form, action)
    }
  }
}

export default function* masterScreenSagas(form) {
  // // debugger
  // yield fork(emptySetFieldListener, form)
  yield fork(cancelEditListener, form)
  yield fork(onDoubleClick, form)
  // yield fork(leaderListener, form)
  yield fork(searchListener, form)
  yield fork(clearFormListener, form)
  // yield fork(entitySuccessListener, form)
}
