/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const INVOICE_INQUIRY = '@@INVOICE_INQUIRY@@'
export const CUSTOMER_STOCK_MINIMUMS = '@@CUSTOMER_STOCK_MINIMUMS@@'

export const GET_ADDITIONAL_DATA = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}GET_ADDITIONAL_DATA`
)

export const SET_NEXT_TAB = `${INVOICE_INQUIRY}SET_NEXT_TAB`

export const CLEAR_ADDITIONAL_DATA = `${INVOICE_INQUIRY}CLEAR_ADDITIONAL_DATA`

export const HANDLE_OPEN_GRID_INTERFACE_FEATURE = `${INVOICE_INQUIRY}HANDLE_OPEN_GRID_INTERFACE_FEATURE`

export const HANDLE_VOID_PROCESS = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}HANDLE_VOID_PROCESS`
)

export const GET_CHANGE_INVOICE_META = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}GET_CHANGE_INVOICE_META`
)

export const LOCK_INVOICE_RECORD = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}LOCK_INVOICE_RECORD`
)

export const UNLOCK_INVOICE_RECORD = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}UNLOCK_INVOICE_RECORD`
)

export const SAVE_INVOICE_RECORD = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}SAVE_INVOICE_RECORD`
)

export const HANDLE_SUPPRESS_FROM_STATEMENTS = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}HANDLE_SUPPRESS_FROM_STATEMENTS`
)

export const GET_SALESMEN_COMMISSIONS = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}GET_SALESMEN_COMMISSIONS`
)

export const CHANGE_COMMISSION_VALUE = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}CHANGE_COMMISSION_VALUE`
)

export const CHANGE_COMMISSION_PROPERTY = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}CHANGE_COMMISSION_PROPERTY`
)

export const HANDLE_OPEN_PRODDUCT_FROM_SALESMEN_COMMISSIONS_INTERFACE = `${INVOICE_INQUIRY}HANDLE_OPEN_PRODDUCT_FROM_SALESMEN_COMMISSIONS_INTERFACE`

/* start NEW Customer Stock Miniums constants */
export const READ_CUSTOMER_STOCK_MINIMUMS = createAsyncRequestTypes(
  `${CUSTOMER_STOCK_MINIMUMS}READ_CUSTOMER_STOCK_MINIMUMS`
)

export const SAVE_CUSTOMER_STOCK_MINIMUMS = createAsyncRequestTypes(
  `${CUSTOMER_STOCK_MINIMUMS}SAVE_CUSTOMER_STOCK_MINIMUMS`
)

export const DELETE_CUSTOMER_STOCK_MINIMUMS = createAsyncRequestTypes(
  `${CUSTOMER_STOCK_MINIMUMS}DELETE_CUSTOMER_STOCK_MINIMUMS`
)

export const EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE = createAsyncRequestTypes(
  `${CUSTOMER_STOCK_MINIMUMS}EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE`
)
/* end NEW Customer Stock Miniums constants */

export const OPEN_SERIAL_NUMBER_EDITOR = createAsyncRequestTypes(
  `${INVOICE_INQUIRY}OPEN_SERIAL_NUMBER_EDITOR`
)
