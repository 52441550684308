import { call, fork, put, select, take } from 'redux-saga/effects'

import { api } from 'services'
import { addModal, confirm } from 'modals/actions'
import { warningModal, confirmationModal } from 'modals/sagas'
import { CONFIRMED, CANCELED } from 'modals/constants'
import { UNLOCK_CONTACT_RECORD } from 'pages/ContactMaster/constants'

import FastProductEntryModal from 'pages/SalesOrder/components/FastProductEntryModal/'
import FastProductEntryModalHeader from 'pages/SalesOrder/components/FastProductEntryModal/components/FastProductEntryModalHeader'
import SaveProductAsModal from 'pages/SalesOrder/components/FastProductEntryModal/components/SaveProductAsModal'
import PatternTypeModal from 'pages/SalesOrder/components/FastProductEntryModal/components/PatternTypeModal'
import DuplicateManufacturingNumberModal from 'pages/SalesOrder/components/FastProductEntryModal/components/DuplicateManufacturingNumberModal'

import * as CONSTANTS from 'pages/SalesOrder/constants'
import * as actions from 'pages/SalesOrder/actions'
import { displayValidationErrors } from 'ddiForm/sagas'
import { SHOW_QUICK_ENTITY, SET_FIELD, BLUR } from 'ddiForm/constants'
import {
  FIND_NEXT,
  FIND_PREV,
  BLUR as INDEX_SEARCH_BLUR
} from 'components/Search/IndexSearch/constants'
import * as BIN_SEARCH_CONSTANTS from 'components/Search/BinSearch/constants'
import { addScreenInModal, setField } from 'ddiForm/actions'
import { onAddContactProcess, onAddError } from 'pages/CustomerMaster/sagas'
import FastShipToModal from 'pages/SalesOrder/components/FastShipToModal'
import FastShipToModalMobile from 'mobile/pages/SalesOrder/components/FastShipToModalMobile'
import { OrderOpts } from 'pages/ContactMaster'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, noop, is, toCamelCase, toLower } from 'utils'
import moment from 'moment'
import FastCustomerModal from 'pages/SalesOrder/components/FastCustomerModal'
import FastCustomerModalMobile from 'mobile/pages/SalesOrder/components/FastCustomerModalMobile'
import FastShipToModalTitle from 'pages/SalesOrder/components/FastShipToModalTitle'

import { getGroupNames } from '../utils'
import { getErrorMessages } from '../../ContactMaster/utils'

export function* getFastProductMeta(form, gridApi) {
  yield put(actions.getFastProductMeta.request(form))

  const { response, error } = yield call(api.getFastProductMeta, {
    newInstance: true
  })

  if (response) {
    yield put(actions.getFastProductMeta.success(response, form))
    yield fork(getFastProductDefaults, form, gridApi)
  } else {
    yield put(actions.getFastProductMeta.failure(error, form))
  }
}

export function* getFastProductDefaults(form, gridApi) {
  const formState = yield select(getFormSelector(form))
  const warehouseId = getIn(formState, 'fields.warehouseId.value') || ''

  yield put(actions.getFastProductDefaults.request(form))

  const { response, error } = yield call(api.getFastProductDefaults, {
    warehouseId
  })

  if (response) {
    yield put(actions.getFastProductDefaults.success(response, form))
    yield fork(launchFastProductModal, form, gridApi)
  } else {
    yield put(actions.getFastProductDefaults.failure(error, form))
  }
}

export function* launchFastProductModal(form, gridApi) {
  const modalOpts = {
    component: FastProductEntryModal,
    options: {
      marginTop: 25,
      maxHeight: '100%',
      width: 600,
      headingStyle: { padding: '16px 24px 0 24px' },
      // title: 'Fast Product Entry',
      title: FastProductEntryModalHeader,
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              // console.log(this)
              //
              // this.props.dispatch(actions.saveFastProduct.try(form, { cb }))
              try {
                // await this.props.dispatch(actions.validateFastProduct.try(form, { cb, dispatch: this.props.dispatch }))
                await this.props.dispatch(
                  actions.launchFastProductSaveInterface(form, {
                    cb,
                    dispatch: this.props.dispatch,
                    gridApi
                  })
                )
              } finally {
                console.log('routine done')
                // cb()
              }
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              try {
                cb()
                await this.props.dispatch(
                  actions.cancelFastProductEntry.try(form)
                )
              } finally {
                console.log('cancel done')
                //
                if (cb) {
                  cb()
                }
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* showQuickEntityListener(formListener) {
  while (true) {
    const action = yield take(SHOW_QUICK_ENTITY)
    const {
      meta: { form },
      payload: { propertyName, gridApi }
    } = action

    if (form === formListener && propertyName) {
      if (propertyName === 'shipToId') {
        yield fork(openFastShipToProcess, form)
      }

      if (
        propertyName === 'orderedById' ||
        propertyName === 'checkoutContact'
      ) {
        yield fork(openContactScreen, form, propertyName)
      }

      if (propertyName === 'fastProduct') {
        yield fork(getFastProductMeta, form, gridApi)
      }

      if (propertyName === 'customerId') {
        yield fork(openFastCustomerProcess, form)
      }
    }
  }
}

export function* openFastShipToProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.launchManualShipTo.request(form))

  const { response, error } = yield call(api.lockForEdit, {
    form: 'salesorder',
    action: 'manualshipto',
    guid
  })

  if (response) {
    yield put(actions.launchManualShipTo.success(response, form))
    yield fork(launchManualShipToModal, form)
  } else {
    yield put(actions.launchManualShipTo.failure(error, form))
  }
}

export function* launchManualShipToModal(form) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const modalStyleOptions = isMobile
    ? {
        fullScreen: true,
        marginTop: '0px',
        maxHeight: '95%',
        width: '95%',
        maxWidth: '95%'
      }
    : {
        maxWidth: 500,
        maxHeight: 600
      }

  const modalOpts = {
    component: isMobile ? FastShipToModalMobile : FastShipToModal,
    options: {
      title: FastShipToModalTitle,
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              try {
                cb()
                await this.props.dispatch(actions.saveManualShipTo.try(form))
              } finally {
                if (cb) {
                  cb()
                }
              }
            }
          },
          { primary: true, title: 'Exit' }
        ]
      },
      ...modalStyleOptions
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* openContactScreen(form, propertyName) {
  // NOTE:  saga to save contact and set orderedById to newly created contact
  // is in ContactMaster/sagas -> saveContactFromModal
  // - LL 11/14/2019
  yield put(actions.setContactReferenceField(form, { propertyName }))

  yield put(
    addScreenInModal(form, {
      screen: OrderOpts(form),
      apiMethod: api.createContact,
      formName: `${form}.contact`,
      apiArgs: {
        groupNames: ['setup'],
        parentType: 'C',
        parentId: forms => getIn(forms, 'fields.customerId.value')
      },
      onApiSuccess: onAddContactProcess,
      onApiError: onAddError,
      modalOptions: {
        maxSize: true,
        hideActions: true,
        title: 'New Contact'
      }
    })
  )
}

export function* updateFastProductIndexSearchLabel(
  form,
  propertyName,
  results = null
) {
  const label = propertyName
    ? propertyName.replace('Id', '').concat('Description')
    : ''

  if (results && results.description) {
    yield put(setField(form, label, results.description))
  } else {
    const timeStamp = moment(new Date()).format('M/D/YY h:mm A')
    const labelName =
      propertyName === 'fastProduct.listPrice'
        ? 'fastProduct.priceChangedDate'
        : 'fastProduct.costChangedDate'
    yield put(setField(form, labelName, timeStamp))
  }
}

export function* fastProductPropertyChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const warehouseId = getIn(formState, 'fields.warehouseId.value') || ''
  const guid = getIn(formState, 'guid')

  const propName = propertyName.replace('fastProduct.', '')

  yield put(actions.fastProductPropertyChange.request(form))

  const { response, error } = yield call(api.fastProductPropertyChange, {
    guid,
    warehouseId,
    properties: {
      [propName]: propName === 'statusType' && value === 'A' ? '' : value
    }
  })

  if (response) {
    yield put(actions.fastProductPropertyChange.success(response, form))

    /* show the duplicate MFG Number modal */
    if (
      response.messages &&
      response.messages.length &&
      response.mfgNumberProductDuplicates &&
      response.mfgNumberProductDuplicates.length
    ) {
      yield fork(
        displayDuplicateManufacturingNumberModal,
        form,
        response.messages,
        response.mfgNumberProductDuplicates
      )
    }
  } else {
    /* revert the value on a 497 cancellation */
    yield put(
      actions.fastProductPropertyChange.failure(
        {
          ...error,
          propertyName: propName
        },
        form
      )
    )
  }
}

export function* displayDuplicateManufacturingNumberModal(
  form,
  messages,
  duplicates
) {
  const message = messages[0]
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const modalOpts = {
    component: DuplicateManufacturingNumberModal,
    options: {
      title: message.modalTitle
        ? message.modalTitle
        : 'Manufacture Number Product Duplicates',
      width: 400,
      data: {
        form,
        isMobile,
        message: message.message
          ? message.message
          : 'Products with a duplicate manufacturing number',
        dataIds: duplicates,
        actions: [{ primary: true, title: 'OK' }]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* validateFastProductId(form, propertyName) {
  const formState = yield select(getFormSelector(form))
  const value = getIn(formState, `fields.${propertyName}.value`)
  const errorMessage = getIn(formState, `fields.${propertyName}.errorMessage`)
  const warnCommas = getIn(formState, 'values.fastProduct.warnCommas')

  if (warnCommas && value && value.match(/,/)) {
    yield put(
      actions.setFieldErrorMessage(form, {
        propertyName,
        errorMessage: 'Fast Product contains commas. Continue?'
      })
    )
  } else if (errorMessage) {
    yield put(
      actions.setFieldErrorMessage(form, {
        propertyName,
        errorMessage: ''
      })
    )
  }
}

export function* fastProductInputChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, result, results, value, description },
      type
    } = yield take([
      BLUR,
      SET_FIELD,
      BIN_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS,
      FIND_PREV.SUCCESS,
      FIND_NEXT.SUCCESS,
      INDEX_SEARCH_BLUR
    ])

    const requiresPropertyChangeCall = [
      'fastProduct.statusType',
      'fastProduct.mfgNumber',
      'fastProduct.productLineId',
      'fastProduct.primaryVendorId',
      'fastProduct.uomForDisplayId'
    ]

    if (
      form === formListener &&
      propertyName &&
      propertyName.match(/fastProduct/)
    ) {
      if (results && results.description && type === SET_FIELD) {
        yield fork(
          updateFastProductIndexSearchLabel,
          form,
          propertyName,
          results
        )

        if (propertyName === 'fastProduct.fromId') {
          yield fork(
            fastProductCopyFromInteraction,
            form,
            'getFastProductCopyFromDescriptions'
          )
        }
      }

      const label = propertyName
        ? propertyName.replace('Id', '').concat('Description')
        : ''
      if (
        propertyName &&
        propertyName.match(/fastProduct/) &&
        (type === SET_FIELD || type === BLUR || type === INDEX_SEARCH_BLUR) &&
        !value
      ) {
        yield put(setField(form, label, ''))
      }

      if (
        propertyName &&
        propertyName.match(/fastProduct/) &&
        (type === FIND_PREV.SUCCESS || type === FIND_NEXT.SUCCESS) &&
        description
      ) {
        yield put(setField(form, label, description))

        if (propertyName === 'fastProduct.fromId') {
          yield fork(
            fastProductCopyFromInteraction,
            form,
            'getFastProductCopyFromDescriptions'
          )
        }
      }

      if (
        (propertyName === 'fastProduct.cost' ||
          propertyName === 'fastProduct.listPrice') &&
        type === BLUR
      ) {
        yield fork(updateFastProductIndexSearchLabel, form, propertyName)
      }

      if (
        result &&
        result.description &&
        propertyName === 'fastProduct.binLocation' &&
        type === BIN_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS
      ) {
        yield fork(
          updateFastProductIndexSearchLabel,
          form,
          propertyName,
          result
        )
      }

      if (propertyName === 'fastProduct.dataId' && type === BLUR) {
        yield fork(validateFastProductId, form, propertyName)
      }

      if (requiresPropertyChangeCall.includes(propertyName)) {
        yield fork(fastProductPropertyChangeProcess, form, propertyName, value)
      }
    }
  }
}

export function* saveManualShipToListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_MANUAL_SHIP_TO.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(saveManualShipToProcess, form)
    }
  }
}

export function* saveManualShipToProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const manualShipToObj =
    getIn(formState, 'fields.manualShipTo') &&
    getIn(formState, 'fields.manualShipTo').toJS
      ? getIn(formState, 'fields.manualShipTo').toJS()
      : {}

  const shipToProps = {}
  if (manualShipToObj && Object.keys(manualShipToObj).length) {
    for (const prop in manualShipToObj) {
      const value = getIn(formState, `fields.manualShipTo.${prop}.value`)
      shipToProps[prop] = value
    }
  }

  yield put(actions.saveManualShipTo.request(form))

  const { response, error } = yield call(api.propertyChange, {
    properties: {
      manualshipto: { ...shipToProps }
    },
    guid,
    groupNames: ['header']
  })

  if (response) {
    yield put(actions.onPropertyChange.success(response, form))
  } else {
    yield put(actions.saveManualShipTo.failure(error, form))
  }
}

export function* openFastCustomerProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  yield put(actions.launchFastCustomer.request(form))

  const { response, error } = yield call(api.lockForEdit, {
    form: 'salesorder',
    action: 'fastcustomer',
    guid
  })

  if (response) {
    yield put(actions.launchFastCustomer.success(response, form))

    let custCategory

    if (
      response?.searchFields &&
      is.array(response.searchFields) &&
      response.searchFields.length
    ) {
      custCategory = response.searchFields.find(
        x => x.searchType === 'CustCategory'
      )
    }

    const modalStyleOptions = isMobile
      ? {
          fullScreen: true,
          marginTop: '0px',
          maxHeight: '95%',
          width: '95%',
          maxWidth: '95%'
        }
      : {
          maxWidth: 400,
          maxHeight: '100%',
          marginTop: '25px'
        }

    const modalOpts = {
      component: isMobile ? FastCustomerModalMobile : FastCustomerModal,
      options: {
        title: 'Fast Customer Entry',
        ...modalStyleOptions,
        data: {
          custCategory,
          form,
          actions: [
            {
              primary: true,
              title: 'Save',
              async clickEvent(args, fs, cb) {
                try {
                  // cb()
                  const { id } = args
                  await this.props.dispatch(
                    actions.saveFastCustomer.try(form, { modalId: id })
                  )
                  if (cb) {
                    cb()
                  }
                } finally {
                  // if (cb) {
                  //   cb()
                  // }
                }
              }
            },
            {
              primary: true,
              title: 'Exit',
              async clickEvent(args, fs, cb) {
                try {
                  cb()
                  await this.props.dispatch(actions.exitFastCustomer.try(form))
                } finally {
                  if (cb) {
                    cb()
                  }
                }
              }
            }
          ]
        }
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)

    // return modal.payload.id
  } else {
    yield put(actions.launchFastCustomer.failure(error, form))
  }
}

export function* launchCreateFastProductIdModal(
  form,
  parentModalCb,
  dispatch,
  gridApi = noop,
  component = SaveProductAsModal,
  title = 'Save Product As'
) {
  // const saveProductModalOpts = {
  //   component: SaveProductAsModal,
  //   options: {
  //     width: 400,
  //     title: 'Save Product As',
  //   }
  // }

  const modalOpts = {
    component,
    options: {
      width: 400,
      title,
      data: {
        dispatch,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              dispatch(
                actions.saveFastProduct.try(form, {
                  cb,
                  parentModalCb,
                  gridApi
                })
              )
            },
            disabled: formState => formState.isPostingFastProductData || false
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              dispatch(actions.clearInvalidFastProductId(form))
              if (cb) {
                cb()
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* saveFastProductProcess(form, cb, parentModalCb, gridApi) {
  const formState = yield select(getFormSelector(form))
  const warehouseId = getIn(formState, 'fields.warehouseId.value') || ''
  const isCanadianCompanyOrHasHawaiianTax =
    getIn(formState, 'meta.fastProduct.isCanadianCompanyOrHasHawaiianTax') ||
    false
  let fastProductFields = getIn(formState, 'fields.fastProduct')
  fastProductFields =
    fastProductFields && fastProductFields.toJS ? fastProductFields.toJS() : {}

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const postData = {}
  for (const prop in fastProductFields) {
    if (!prop.match(/Description/)) {
      if (prop === 'statusType') {
        const value = fastProductFields[prop].value
          ? fastProductFields[prop].value
          : ''
        postData[prop] = value === 'A' ? '' : value
      } else {
        postData[prop] =
          fastProductFields[prop].value ||
          is.bool(fastProductFields[prop].value)
            ? fastProductFields[prop].value
            : ''
      }
    }
  }

  if (isCanadianCompanyOrHasHawaiianTax) {
    delete postData?.isTaxable
  } else {
    delete postData?.taxableType
  }

  const apiParams = {
    dataId: getIn(formState, 'fields.fastProduct.dataId.value') || '',
    warehouseId,
    properties: {
      ...postData
    }
  }

  yield put(actions.saveFastProduct.request(form))
  const { response, error } = yield call(api.saveFastProduct, apiParams)

  if (response) {
    if (cb && typeof cb === 'function') {
      cb()
    }

    if (parentModalCb && typeof parentModalCb === 'function') {
      parentModalCb()
    }

    yield put(actions.saveFastProduct.success(response, form))

    if (!isMobile) {
      yield fork(addFastProductToLineItemsGrid, form, response.dataId, gridApi)
    } else if (form === 'salesOrderMobile') {
      yield put({
        type: 'TRY_CELL_CHANGED_REQUEST',
        meta: { form, thunk: true },
        payload: {
          value: response.dataId,
          propertyName: 'lineItems',
          field: 'dataId',
          advancedParsingArguments: null
        }
      })
    }
  } else {
    yield put(actions.saveFastProduct.failure(error, form))
    if (
      error.status === 498 &&
      error.validationErrors &&
      error.validationErrors.length
    ) {
      const err = error.validationErrors[0]
      yield call(warningModal, err.message, 'Invalid Pattern!')
    }
  }
}

export function* addFastProductToLineItemsGrid(form, dataId, gridApi = noop) {
  const formState = yield select(getFormSelector(form))
  const rowData = getIn(formState, 'fields.lineItems.rowData')
  const rowIndex = rowData && rowData.size ? rowData.size - 1 : 0
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']

  const hasBlankRow = rowData.get(rowIndex).get('rowId') === 'blankrow' || false
  const lineNumber = hasBlankRow
    ? rowData.get(rowIndex).get('lineNumber')
    : rowData.size + 1

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    properties: { dataId },
    lineNumber,
    guid,
    groupNames,
    gridName: 'lineItems'
  })

  if (response) {
    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: {
        form,
        addedNewLineItem: true,
        addedLineNumber: Number(lineNumber),
        searchFieldInteraction: false
      },
      payload: {
        ...response,
        propertyChanged: 'lineItems'
      }
    })

    if (gridApi && gridApi.forEachNode) {
      gridApi.forEachNode(node => {
        if (node.data.lineNumber === Number(lineNumber)) {
          node.setSelected(true)
        }
      })
    }
  } else {
    yield put(actions.getProductDetails.failure(error, form))
  }
}

export function* saveFastProductListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb, parentModalCb, gridApi }
    } = yield take(CONSTANTS.SAVE_FAST_PRODUCT.TRY)

    if (form === formListener) {
      yield fork(saveFastProductProcess, form, cb, parentModalCb, gridApi)
    }
  }
}

export function* cancelFastProductEntryProcess(form) {
  yield put(actions.cancelFastProductEntry.request(form))

  const { response, error } = yield call(api.cancelFastProductEntry)

  if (response) {
    yield put(actions.cancelFastProductEntry.success(response, form))
  } else {
    yield put(actions.cancelFastProductEntry.failure(error, form))
  }
}

export function* cancelFastProductEntryListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_FAST_PRODUCT_ENTRY.TRY)

    if (form === formListener) {
      yield fork(cancelFastProductEntryProcess, form)
    }
  }
}

/* pretty sure this is a remnant that can be deleted */
export function* setFieldListener(formListener) {
  while (true) {
    const action = yield take(SET_FIELD)
    const {
      meta: { form },
      payload: { propertyName }
    } = action

    if (form === formListener) {
      if (propertyName && propertyName === 'prospect') {
        // set up saga to call api
        // to set prospect
      }
    }
  }
}

export const getSaveFastProductWarningMessage = (
  productDescription,
  productLineId
) => {
  /* only 2 required fields, simple validation */
  if (!productDescription && !productLineId) {
    return 'Description is required.\nProduct Line is required.'
  }

  if (!productDescription) {
    return 'Description is required.'
  }

  if (!productLineId) {
    return 'Product Line is required.'
  }

  return ''
}

export function* launchFastProductSaveInterface(form, cb, dispatch, gridApi) {
  const formState = yield select(getFormSelector(form))
  const productDescription = getIn(
    formState,
    'fields.fastProduct.description.value'
  )
  const productLineId = getIn(
    formState,
    'fields.fastProduct.productLineId.value'
  )
  const patternType = getIn(formState, 'values.fastProduct.patternType')

  if (!productDescription || !productLineId) {
    const message = getSaveFastProductWarningMessage(
      productDescription,
      productLineId
    )
    yield call(warningModal, message, 'Attention!')
    return
  }

  if (patternType === 'S') {
    yield fork(launchCreateFastProductIdModal, form, cb, dispatch, gridApi)
  } else {
    yield fork(
      launchCreateFastProductIdModal,
      form,
      cb,
      dispatch,
      gridApi,
      PatternTypeModal,
      'Create New Product'
    )
  }
}

export function* launchFastProductSaveInterfaceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb, dispatch, gridApi }
    } = yield take(CONSTANTS.LAUNCH_FAST_PRODUCT_SAVE_INTERFACE)

    if (form === formListener) {
      yield fork(launchFastProductSaveInterface, form, cb, dispatch, gridApi)
    }
  }
}

export function* fastProductCopyFromInteraction(form, action) {
  const formState = yield select(getFormSelector(form))
  const key = getIn(formState, 'meta.fastProduct.key')
  const fromId = getIn(formState, 'fields.fastProduct.fromId.value')
  const warehouseId = getIn(formState, 'fields.fastProduct.warehouseId.value')

  const apiParamsMap = {
    getFastProductCopyFromMeta: {
      dataId: key
    },
    fastProductCopyFromCancel: {
      dataId: key
    },
    getFastProductCopyFromDescriptions: {
      dataId: fromId
    },
    fastProductCopyFromExecute: {
      dataId: key,
      fromId,
      warehouseId
    }
  }

  if (!apiParamsMap[action]) {
    return
  }

  yield put(actions[action].request(form))

  const { response, error } = yield call(api[action], apiParamsMap[action])

  if (response) {
    yield put(actions[action].success(response, form))
    if (
      action === 'fastProductCopyFromExecute' &&
      Object.hasOwnProperty.call(response, 'statusTypeCopy')
    ) {
      /*
        need to hit the propertyChange API for
        possible statusType 497 -- SVE 12/3/2019
      */
      yield fork(
        fastProductPropertyChangeProcess,
        form,
        'statusType',
        response.statusTypeCopy
      )
    }
  } else {
    yield put(actions[action].failure(error, form))
  }
}

export function* fastProductCopyFromInteractionListener(formListener) {
  while (true) {
    const {
      meta: { form },
      type
    } = yield take([
      CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_META.TRY,
      CONSTANTS.FAST_PRODUCT_COPY_FROM_CANCEL.TRY,
      CONSTANTS.FAST_PRODUCT_COPY_FROM_EXECUTE.TRY
    ])

    if (form === formListener && type) {
      if (type === CONSTANTS.GET_FAST_PRODUCT_COPY_FROM_META.TRY) {
        yield fork(
          fastProductCopyFromInteraction,
          form,
          'getFastProductCopyFromMeta'
        )
      }

      if (type === CONSTANTS.FAST_PRODUCT_COPY_FROM_CANCEL.TRY) {
        yield fork(
          fastProductCopyFromInteraction,
          form,
          'fastProductCopyFromCancel'
        )
      }

      if (type === CONSTANTS.FAST_PRODUCT_COPY_FROM_EXECUTE.TRY) {
        yield fork(
          fastProductCopyFromInteraction,
          form,
          'fastProductCopyFromExecute'
        )
      }
    }
  }
}

export function* fastCustomerPropertyChangeListener(formListener) {
  while (true) {
    const action = yield take([BLUR, SET_FIELD])
    const {
      meta: { form },
      payload: { propertyName, disablePropertyChange }
    } = action

    if (
      form === formListener &&
      propertyName.includes('fastCustomer') &&
      !disablePropertyChange
    ) {
      if (propertyName.includes('isProspect')) {
        yield fork(prospectChangeProcess, form)
      } else {
        yield fork(fastCustomerPropertyChangeProcess, form, propertyName)
      }
    }
  }
}

export function* onFastCustomerIndexSearchBlurListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(INDEX_SEARCH_BLUR)

    if (
      form === formListener &&
      propertyName &&
      propertyName.match(/fastCustomer\./) &&
      !value
    ) {
      yield fork(fastCustomerPropertyChangeProcess, form, propertyName)
    }
  }
}

// Might need to refactor later to do all the property change in save
// -LL 10/19/19
// 7055 - for docs
export function* fastCustomerPropertyChangeProcess(form, propertyName) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const value = getIn(formState, `fields.${propertyName}.value`)
  const isProspect = getIn(formState, 'fields.fastCustomer.isProspect.value')
  const prop = propertyName.split('.').pop()

  const properties = {}

  if (prop === 'dataId') {
    properties.isProspect = isProspect
  }

  yield put(actions.fastCustomerPropertyChange.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    action: 'fastcustomer',
    properties: {
      [prop]: value,
      ...properties
    }
  })

  if (response) {
    yield put(actions.launchFastCustomer.success(response, form))
  } else {
    yield put(actions.fastCustomerPropertyChange.failure(error, form))
    // yield fork(displayValidationErrors, error)
    if (error?.validationErrors && Array.isArray(error.validationErrors)) {
      const errorProps = error.validationErrors.reduce((acc, next) => {
        if (next?.property) {
          acc = acc.concat(toCamelCase(next.property))
          acc = acc.concat(toLower(toCamelCase(next.property)))
        }
        return acc
      }, [])

      if (errorProps?.includes(prop)) {
        // yield put(
        //   setField(form, propertyName, '', null, null, null, null, true)
        // )
      }
    }
  }
}

export function* prospectChangeProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isProspect =
    getIn(formState, 'fields.fastCustomer.isProspect.value') || false

  if (isProspect) {
    yield call(
      confirmationModal,
      'Do you want to change template to Prospect?  Any changes will be lost.',
      'Warning'
    )

    const action = yield take([CONFIRMED, CANCELED])

    if (action.type === CONFIRMED) {
      yield put(actions.launchFastCustomer.request(form))

      const { response, error } = yield call(api.propertyChange, {
        guid,
        action: 'fastcustomer',
        properties: {
          isProspect
        }
      })

      if (response) {
        yield put(actions.launchFastCustomer.success(response, form))
      } else {
        yield put(actions.launchFastCustomer.failure(error, form))
      }
    } else {
      // reset isProspect field to false
      yield put(setField(form, 'fastCustomer.isProspect', !isProspect))
    }
  }
}

export function* exitFastCustomerListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.EXIT_FAST_CUSTOMER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(exitFastCustomerProcess, form)
    }
  }
}

export function* exitFastCustomerProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.exitFastCustomer.request(form))

  const { response, error } = yield call(api.cancelEdit, {
    form: 'salesOrder',
    action: 'fastcustomer',
    guid
  })

  if (response) {
    yield put(actions.exitFastCustomer.success(response, form))
  } else {
    yield put(actions.exitFastCustomer.failure(response, form))
  }
}

export function* saveFastCustomerListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_FAST_CUSTOMER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(saveFastCustomerProcess, form, payload)
    }
  }
}

export function* saveFastCustomerProcess(form, payload) {
  const { modalId } = payload
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  yield put(actions.saveFastCustomer.request(form))

  const { response, error } = yield call(api.saveSalesOrder, {
    action: 'fastcustomer',
    guid,
    groupNames
  })

  if (response) {
    yield put(confirm(form, modalId))
    yield put(actions.onPropertyChange.success(response, form))
  } else {
    debugger
    const { status, message, validationErrors } = error
    if (status === 404) {
      yield call(warningModal, message, 'Attention!')
    } else if (status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
    yield put(actions.saveFastCustomer.failure(error, form))
  }
}

export function* exitFastContactListener(formListener) {
  while (true) {
    const action = yield take(UNLOCK_CONTACT_RECORD.TRY)
    const {
      meta: { form }
    } = action

    if (form.includes(formListener)) {
      // clear fields
      const newForm = form.split('.')[0]
      yield put(actions.clearFastContact(newForm))
    }
  }
}

export function* changeFastProductVendorProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  // const warehouseId = getIn(formState, 'fields.warehouseId.value') || ''

  const { response, error } = yield call(api.fastProductPropertyChange, {
    guid,
    properties: {
      canChangeVendor: null
    }
  })

  if (response) {
    yield put(actions.changeFastProductVendor.success(response, form))
    debugger
  } else {
    yield put(actions.changeFastProductVendor.failure(error, form))
  }
}

export function* changeFastProductVendorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CHANGE_FAST_PRODUCT_VENDOR.TRY)

    if (form === formListener) {
      yield fork(changeFastProductVendorProcess, form)
    }
  }
}

export default function* fastEntitySagas(form) {
  yield fork(fastProductInputChangeListener, form)
  yield fork(showQuickEntityListener, form)
  yield fork(saveManualShipToListener, form)
  yield fork(saveFastProductListener, form)
  yield fork(cancelFastProductEntryListener, form)
  yield fork(launchFastProductSaveInterfaceListener, form)
  yield fork(fastProductCopyFromInteractionListener, form)
  yield fork(fastCustomerPropertyChangeListener, form)
  yield fork(onFastCustomerIndexSearchBlurListener, form)
  yield fork(exitFastCustomerListener, form)
  yield fork(saveFastCustomerListener, form)
  yield fork(exitFastContactListener, form)
  yield fork(changeFastProductVendorListener, form)
}
