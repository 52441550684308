import React from 'react'

const GreyRow = props => (
  <div
    style={{
      display: 'flex',
      padding: '0.3rem',
      background: 'rgba(219, 229, 236, 0.3)',
      borderRadius: 3,
      border: '1px solid rgba(219, 229, 236, 0.5)',
      alignItems: 'center',
      ...props.style
    }}
  >
    {props.children}
  </div>
)

export default GreyRow
