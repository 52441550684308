import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import { DDITextField } from 'ddiForm/wrapped'

// import TextArea from '../../components/TextArea'

const Notes = props => {

  return (
    <div style={{ height: props.height ? props.height : 'auto', display: 'flex', flex: 1, justifyContent: 'center' }}>
      <DDICardWrapper
        style={{ flex: 1, maxWidth: 800, overflowX: 'hidden', overflowY: 'scroll' }}
        title="Notes"
        contentStyle={{ padding: '1rem', minHeight: 300 }}
      >
        <DDITextField
          fullWidth
          propertyName="internalNotes"
          name="internalNotes"
          id="internalNotesTF"
          multiline
          minWidth="100%"
          variant="outlined"
        />
      </DDICardWrapper>
    </div>
  )
}

Notes.defaultProps = {
  style: {
    resize: 'none',
    border: '1px solid #ccc',
    borderRadius: 5,
    padding: '.5rem',
    flex: 1
  },
  wrapperStyle: { display: 'flex', flex: 1 }
}

export default Notes
