import React from 'react'
import PropTypes from 'prop-types'
import { DDITextField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { maxValue } from 'ddiForm/validations'

const ShippingAndInstructions = ({ pageStyles }) => (
  <div>
    <DDICardWrapper title="Shipping Information" contentStyle={{ padding: 10 }}>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="upsAccountNumber"
            placeholder="UPS Account #"
            margin="dense"
            label="UPS Account #"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="fedExAccountNumber"
            placeholder="FedEx Account #"
            margin="dense"
            label="FedEx Account #"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="ediNumber"
            placeholder="EDI Number"
            margin="dense"
            label="EDI Number"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="ediDept"
            placeholder="EDI Dept"
            margin="dense"
            label="EDI Dept"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="keywords"
            placeholder="Keyword"
            margin="dense"
            label="Keyword"
            fullWidth
            style={{ width: '100%' }}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="coOpPercent"
            id="coOpPercent"
            label="Co-Op Percent"
            mask="numeric"
            inputProps={{
              decimalLimit: 2,
              fixedDecimalScale: true,
              range: [0, 100]
            }}
            fullWidth
          />
        </div>
      </div>
      <div style={pageStyles.twoColFlex.wrapper}>
        <div style={pageStyles.twoColFlex.left}>
          <DDITextField
            propertyName="maxEcommOrderSize"
            id="maxEcommOrderSize"
            label="Maximum eCommerce Order Size"
            mask="numeric"
            fullWidth
            inputProps={{
              decimalScale: 2,
              fixedDecimalScale: true,
              prefix: '$',
              textAlign: 'left',
              range: [0, 999999999.99]
            }}
            validate={[maxValue(999999999.99)]}
          />
        </div>
        <div style={pageStyles.twoColFlex.right}>
          <DDITextField
            propertyName="maxEcommAnnualSales"
            id="maxEcommAnnualSales"
            label="Maximum eCommerce Annual Sales"
            mask="numeric"
            fullWidth
            inputProps={{
              range: [0, 999999999.99],
              decimalScale: 2,
              fixedDecimalScale: true,
              prefix: '$',
              textAlign: 'left'
            }}
            validate={[maxValue(999999999.99)]}
          />
        </div>
      </div>
    </DDICardWrapper>
    <DDICardWrapper title="Special Instructions" contentStyle={{ padding: 10 }}>
      <DDITextField
        fullWidth
        propertyName="specialInstruction"
        id="specialInstruction"
        placeholder="Enter Special Instructions..."
        multiline
        minWidth="100%"
        rows={10}
        style={{ width: '100%' }}
      />
    </DDICardWrapper>
  </div>
)

ShippingAndInstructions.propTypes = {
  pageStyles: PropTypes.object.isRequired
}

export default ShippingAndInstructions
