import React from 'react'
import PropTypes from 'prop-types'
// import DDICardWrapper from 'components/DDICardWrapper'
// import { CardMedia } from '@material-ui/core'

const Image = ({ isEditing, src, style }) => (
  <>
    <div style={style}>
      <img src={src} style={{ maxWidth: '100%', maxHeight: 250 }} alt="text" />
    </div>
  </>
)

Image.propTypes = {
  isEditing: PropTypes.bool,
  src: PropTypes.string.isRequired,
  style: PropTypes.object
}

Image.defaultProps = {
  isEditing: false,
  style: {
    margin: 0,
    maxHeight: 250
  }
}

export default Image
