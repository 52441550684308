import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import ConnectedField from './ConnectedField'
import { withDDIForm } from './DDIFormContext'

const specialFields = ['SalesOrderSearch']

class Field extends PureComponent {
  ref = React.createRef()

  static propTypes = {
    // autoFocus: PropTypes.bool,
    disableRegister: PropTypes.bool,
    enablePropChange: PropTypes.bool,
    id: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    // required: PropTypes.bool,
    searchType: PropTypes.string,
    type: PropTypes.string
  }

  static defaultProps = {
    disableRegister: false,
    id: null,
    searchType: null,
    type: null,
    autoFocus: false,
    enablePropChange: false,
    ConnectedField
    // required: false
  }

  constructor(...args) {
    //eslint-disable-line
    super(...args)
    this.id = this.props.id || uuid.v1()
    // deal with invariants here...
    // invariant(this.context.formState && this.context.propertyChange, 'This Field must be used inside of a ddiForm connected container.')
  }

  componentDidMount() {
    const {
      disableRegister,
      propertyName,
      component: { ddiType },
      initialValue,
      meta,
      leader,
      validate,
      registerField,
      index,
      valueKey,
      parentArray
    } = this.props

    if (!disableRegister && propertyName && registerField) {
      registerField({
        propertyName,
        searchType: this.props.searchType,
        ddiType,
        meta,
        leader,
        initialValue,
        validate,
        type: this.props.type,
        index,
        valueKey,
        parentArray
      })
    }
  }

  render() {
    const { propertyName } = this.props
    // console.log('rendered FIELD', propertyName)
    return (
      <this.props.ConnectedField
        {...this.props}
        propertyName={propertyName}
        id={this.id}
        ref={this.ref}
      />
    )
  }
}

export default withDDIForm(Field)
