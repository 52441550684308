import { call, fork, put, select, take } from 'redux-saga/effects'
import { getRecord } from 'ddiForm/MasterScreen/sagas'
import { fromJS, getIn, is } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import {
  getTabIds,
  getChildAndParentForm,
  mapGetRecordResponse
} from 'pages/InvoiceInquiry/utils'
import * as DDI_FORM_CONSTANTS from 'ddiForm/constants'
import { launchCustomerShipToListener } from 'pages/SalesOrder/sagas/headerSagas'
import { sendSalesOrderDocumentListener } from 'pages/SalesOrder/sagas/invoicingSagas'
import { handleRepairItemModalProcess } from 'pages/SalesOrder/sagas/commonSagas'
import { LAUNCH_REPAIR_ITEM_MODAL_INTERFACE } from 'pages/SalesOrder/constants'
import { getInventoryNames } from 'pages/SalesOrder/utils'
import { getWarehouseInventoryListener } from 'pages/SalesOrder/sagas/detailSagas'
import activitiesSagas from 'pages/SalesOrder/sagas/activitiesSagas'
import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'
import * as actions from '../actions'
import * as CONSTANTS from '../constants'
import { readAdditionalInvoiceData } from '../api'
import { initPrintRoutineListener } from './printSagas'
import secondaryHandlers from './secondaryHandlers'
import changeInvoiceSagas from './changeInvoiceSagas'
import customerStockMinimumsSagas from './customerStockMinimumsSagas'
import serialNumberSagas from './serialNumberSagas'

export function* getRecordArgumentsSaga(form, rest = {}) {
  const formState = yield select(getFormSelector(form))
  const { groupNames = [] } = rest

  return {
    groupNames: getTabIds(groupNames)
  }
}

export function* getDataByTab(form, groupNames = ['header', 'detail']) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''

  const params = {
    form,
    tabIds: groupNames,
    dataId,
    groupNames
  }

  yield fork(getRecord, params)
}

export function* getAdditionalDataProcess(form, rowIndex, gridApi) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  const selectedRowIndex =
    getIn(formState, 'fields.lineItems.selectedRowIndex') || null
  const isPosting = getIn(formState, 'isPosting') || false
  const row = lineItems?.get ? lineItems.get(rowIndex) : fromJS({})
  const lineNumber = row && row?.get ? row.get('lineNumber') : null
  const selectedWarehousePanel = getIn(formState, 'ui.warehousePanel') || null

  if (!lineNumber || (isPosting && rowIndex === selectedRowIndex)) {
    return
  }

  const additionalDataMap = getIn(formState, 'additionalDataMap') || fromJS({})

  if (!additionalDataMap.get(lineNumber.toString())) {
    yield put(actions.getAdditionalData.request(form))

    const { response, error } = yield call(readAdditionalInvoiceData, {
      dataId,
      lineNumber,
      inventoryNames: getInventoryNames(
        getIn(formState, 'meta.inventoryNames'),
        selectedWarehousePanel
      )
    })

    if (response) {
      yield put(
        actions.getAdditionalData.success(
          {
            ...response,
            rowId: response.lineNumber.toString()
          },
          form
        )
      )
    } else {
      yield put(actions.getAdditionalData.failure(error, form))
    }
  }
}

export function* setSelectedRowIndexListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, rowIndex, gridApi }
    } = yield take(DDI_FORM_CONSTANTS.SET_SELECTED_ROW_INDEX)

    if (form === formListener && propertyName === 'lineItems') {
      yield fork(getAdditionalDataProcess, form, rowIndex, gridApi)
    }
  }
}

export function* launchRepairItemModalInterfaceProcess(
  form,
  rowIndex,
  gridApi
) {
  yield call(getAdditionalDataProcess, form, rowIndex, gridApi)
  const formState = yield select(getFormSelector(form))
  const lineItems = getIn(formState, 'fields.lineItems.rowData')
  if (!lineItems?.get) {
    return
  }

  let repairItemData = lineItems.get(rowIndex)
  repairItemData =
    repairItemData && repairItemData.toJS ? repairItemData.toJS() : {}

  if (repairItemData.lineNumber) {
    yield fork(
      handleRepairItemModalProcess,
      form,
      repairItemData.lineNumber,
      repairItemData
    )
  }
}

export function* launchRepairItemModalInterfaceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex, gridApi }
    } = yield take(LAUNCH_REPAIR_ITEM_MODAL_INTERFACE)

    if (form === formListener) {
      // debugger
      yield fork(launchRepairItemModalInterfaceProcess, form, rowIndex, gridApi)
    }
  }
}

export function* handleOpenGridInterfaceFeatureProcess(
  form,
  colId,
  lineNumber,
  gridApi
) {
  const formState = yield select(getFormSelector(form))
  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  let rowIndex = lineItems?.findIndex
    ? lineItems.findIndex(x => x.get('lineNumber') === lineNumber)
    : null

  if (!is.number(rowIndex)) {
    return
  }

  yield call(getAdditionalDataProcess, form, rowIndex, gridApi)

  /*  this should already be set, but leave this here in place as a failsafe.  -- SVE 2/22/2021 */
  gridApi.forEachNode((node, i) => {
    if (node?.data?.lineNumber && node?.data?.lineNumber === lineNumber) {
      node.setSelected(true)
      rowIndex = node.rowIndex
    } else {
      node.setSelected(false)
    }
  })

  gridApi.setFocusedCell(rowIndex, colId)
  gridApi.startEditingCell({
    rowIndex,
    colKey: colId
  })
}

export function* handleOpenGridInterfaceFeatureListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { gridApi, lineNumber, colId }
    } = yield take(CONSTANTS.HANDLE_OPEN_GRID_INTERFACE_FEATURE)

    if (form === formListener) {
      yield fork(
        handleOpenGridInterfaceFeatureProcess,
        form,
        colId,
        lineNumber,
        gridApi
      )
    }
  }
}

export function* onOpenInvoiceInquiryInModal(response, meta, form) {
  const { childForm, parentForm } = getChildAndParentForm(form)
  const formState = yield select(getFormSelector(form))
  const tabIds = ['header', 'detail']

  const mappedResponse = mapGetRecordResponse({
    response,
    tabIds,
    formState,
    groupNames: tabIds
  })

  yield put(
    getEntityAsync.success(mappedResponse, {
      form,
      thunk: meta.thunk
    })
  )

  // yield spawn(switchToLedgerTabInModal, form, parentForm)
  // if (response?.internalNotes) {
  //   yield fork(showInternalNotesModal, response.internalNotes, form, response)
  // }
}

export default function* invoiceInquirySagas(form) {
  yield fork(setSelectedRowIndexListener, form)
  yield fork(launchCustomerShipToListener, form)
  yield fork(initPrintRoutineListener, form)
  yield fork(sendSalesOrderDocumentListener, form)
  yield fork(activitiesSagas, form)
  yield fork(attachmentsSagas, form)
  yield fork(launchRepairItemModalInterfaceListener, form)
  yield fork(handleOpenGridInterfaceFeatureListener, form)
  yield fork(secondaryHandlers, form)
  yield fork(changeInvoiceSagas, form)
  yield fork(customerStockMinimumsSagas, form)
  yield fork(getWarehouseInventoryListener, form)
  yield fork(serialNumberSagas, form)
}
