import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getShippingConfirmation = createAsyncFormAction(
  CONSTANTS.GET_SHIPPING_CONFIRMATION
)

export const deleteShippingConfirmation = createAsyncFormAction(
  CONSTANTS.DELETE_SHIPPING_CONFIRMATION
)

export const saveShippingConfirmation = createAsyncFormAction(
  CONSTANTS.SAVE_SHIPPING_CONFIRMATION
)

export const cancelShippingConfirmation = createAsyncFormAction(
  CONSTANTS.CANCEL_SHIPPING_CONFIRMATION
)
export const initiateCardTerminalSignature = createAsyncFormAction(
  CONSTANTS.INITIATE_CARD_TERMINAL_SIGNATURE
)
export const pollCardTerminalSignature = createAsyncFormAction(
  CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE
)

export const onPropertyChange = createAsyncFormAction(
  CONSTANTS.ON_PROPERTY_CHANGE
)

export const printShippingConfirmation = createAsyncFormAction(
  CONSTANTS.PRINT_SHIPPING_CONFIRMATION
)

export const viewSDSheet = createAsyncFormAction(CONSTANTS.VIEW_SD_SHEET)

export const updateInternalNotes = createAsyncFormAction(
  CONSTANTS.UPDATE_INTERNAL_NOTES
)

export const revertToLockedDataId = (form, { dataId }) =>
  createAction(CONSTANTS.REVERT_TO_LOCKED_DATA_ID, { dataId }, form)

export const getPreviousOrder = form =>
  createAction(CONSTANTS.GET_PREVIOUS_ORDER, {}, form)

export const removePreviousOrder = (form, { dataId }) =>
  createAction(CONSTANTS.REMOVE_PREVIOUS_ORDER, { dataId }, form)

export const addBlankTrackingRow = form =>
  createAction(CONSTANTS.ADD_BLANK_TRACKING_ROW, {}, form)

export const removeBlankTrackingRow = form =>
  createAction(CONSTANTS.REMOVE_BLANK_TRACKING_ROW, {}, form)
