import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import { styles } from 'pages/ProductMaster/utils'
import { withContext, Label, Field } from 'ddiForm'

const JohnstoneWebNode = props => {
  const { isEditing } = props
  return (
    <div
      style={{
        height: props.height ? props.height : 'auto',
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
      }}
    >
      <DDICardWrapper
        style={{
          flex: 1,
          maxWidth: 800,
          height: 200
        }}
        title="Johnstone"
        contentStyle={{ padding: '1rem', minHeight: 100, paddingTop: '50px' }}
      >
        <div style={styles.searchRow.wrapper}>
          <label htmlFor="jsWebNode" style={styles.searchRow.label}>
            JS Web Node
          </label>
          <div style={styles.searchRow.input}>
            <DDIIndexSearch
              propertyName="jsWebNode"
              id="jsWebNode"
              metaKey="jsWebNode"
              fullWidth
              minWidth="100%"
              preventAutoDisable
              disabled={!isEditing}
            />
          </div>
          <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
            <Field
              component={Label}
              propertyName="jsWebNodeDescription"
              labelStyle={{ width: '100%' }}
            />
          </div>
        </div>
      </DDICardWrapper>
    </div>
  )
}

export const contextObj = {
  isEditing: form => form.get('isEditing') || false
}

export default withContext(contextObj)(JohnstoneWebNode)
