import { call, fork, put, select, take } from 'redux-saga/effects'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { addModal } from 'modals/actions'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import CustomerStockMinimumsModal from 'pages/CustomerOrderPad/modals/CustomerStockMinimums'

import {
  READ_CUSTOMER_STOCK_MINIMUMS,
  SAVE_CUSTOMER_STOCK_MINIMUMS,
  DELETE_CUSTOMER_STOCK_MINIMUMS,
  EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE
} from '../constants'

import {
  readCustomerStockMinimums,
  saveCustomerStockMinimums,
  deleteCustomerStockMinimums,
  exitCustomerStockMinimumsInterface
} from '../actions'

import * as api from '../api'

const actionsMap = {
  readCustomerStockMinimums,
  saveCustomerStockMinimums,
  deleteCustomerStockMinimums,
  exitCustomerStockMinimumsInterface
}

export function* getBaseApiParams(form) {
  const formState = yield select(getFormSelector(form))

  if (!form || !formState) {
    return {}
  }

  if (form === 'invoiceInquiry') {
    return {
      customerId: getIn(formState, 'values.customerId'),
      shipToId: getIn(formState, 'values.shipToId')
    }
  }

  if (form.match(/salesOrder-/)) {
    return {
      customerId:
        getIn(formState, 'fields.customerId.value') ||
        getIn(formState, 'values.customerId'),
      shipToId:
        getIn(formState, 'fields.shipToId.value') ||
        getIn(formState, 'values.shipToId')
    }
  }

  return {
    customerId: getIn(formState, 'fields.customer.value'),
    shipToId: getIn(formState, 'fields.shipTo.value')
  }
}

export function* displayCustomerStockMinimumsModal(
  form,
  response,
  baseApiParams = {}
) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const options = {
    component: CustomerStockMinimumsModal,
    options: {
      data: {
        form,
        actions: [
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              const { minimumQuantity, maximumQuantity } = this.state
              try {
                await this.props.dispatch(
                  saveCustomerStockMinimums.try(form, {
                    ...baseApiParams,
                    dataId: response?.dataId,
                    uomId: response?.uomId,
                    maximumQuantity,
                    minimumQuantity,
                    cb
                  })
                )
              } finally {
                console.log('ok')
                debugger
              }
            }
          },
          {
            primary: true,
            title: 'Delete',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  deleteCustomerStockMinimums.try(form, {
                    ...baseApiParams,
                    product: response?.product,
                    dataId: response?.dataId,
                    uomId: response?.uomId,
                    cb
                  })
                )
              } finally {
                console.log('ok deleted')
                debugger
                // cb()
              }
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  exitCustomerStockMinimumsInterface.try(form, { cb })
                )
              } catch (e) {
                console.log(e)
              } finally {
                console.log('finally')
                debugger
              }
            }
          }
        ],
        responseData: { ...response }
      },
      maxHeight: isMobile ? '100%' : 800,
      marginTop: isMobile ? 25 : null,
      title: 'Customer Stock Minimums',
      width: 600
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
}

export function* readCustomerStockMinimumsProcess(form, payload) {
  // const formState = yield select(getFormSelector(form))
  const baseApiParams = yield call(getBaseApiParams, form)

  const { dataId, uomId } = payload

  const { response, error } = yield call(api.readCustomerStockMinimums, {
    ...baseApiParams,
    dataId,
    uomId
  })

  if (response) {
    yield put(readCustomerStockMinimums.success(response, form))
    yield fork(displayCustomerStockMinimumsModal, form, response, baseApiParams)
  } else {
    yield put(readCustomerStockMinimums.failure(error, form))
  }
}

export function* readCustomerStockMinimumsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(READ_CUSTOMER_STOCK_MINIMUMS.TRY)

    if (form === formListener) {
      yield fork(readCustomerStockMinimumsProcess, form, payload)
    }
  }
}

export function* handleModalApiTransactionProcess(
  form,
  payload,
  type = 'saveCustomerStockMinimums'
) {
  const { cb, product = '', ...apiParams } = payload
  const action = actionsMap[type]

  if (!action) {
    return
  }

  if (type === 'deleteCustomerStockMinimums') {
    yield call(
      confirmationModal,
      `Are you sure you want to delete Stock Level - ${apiParams?.dataId}: "${product}"?`,
      'Delete?'
    )

    const modalAction = yield take([CONFIRMED, CANCELED])
    if (modalAction.type === CANCELED) {
      return
    }
  }

  yield put(action.request(form))

  const { response, error } = yield call(api[type], apiParams)

  if (response) {
    yield put(action.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    if (error.status === 498) {
      yield fork(displayValidationErrors, error)
    }

    yield put(action.failure(error, form))
  }
}

const getActionName = type => {
  if (type === SAVE_CUSTOMER_STOCK_MINIMUMS.TRY) {
    return 'saveCustomerStockMinimums'
  }

  if (type === DELETE_CUSTOMER_STOCK_MINIMUMS.TRY) {
    return 'deleteCustomerStockMinimums'
  }

  if (type === EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE.TRY) {
    return 'exitCustomerStockMinimumsInterface'
  }

  return null
}

export function* handleModalApiTransactionListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload,
      type
    } = yield take([
      SAVE_CUSTOMER_STOCK_MINIMUMS.TRY,
      DELETE_CUSTOMER_STOCK_MINIMUMS.TRY,
      EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE.TRY
    ])

    const actionName = getActionName(type)

    if (form === formListener && actionName) {
      yield fork(handleModalApiTransactionProcess, form, payload, actionName)
    }
  }
}

export default function* customerStockMinimumsSagas(form) {
  yield fork(readCustomerStockMinimumsListener, form)
  yield fork(handleModalApiTransactionListener, form)
}
