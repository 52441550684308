/* eslint react/sort-comp: 0, no-lonely-if: 0 */
import React, { useEffect, useState } from 'react'
import { withContext } from 'ddiForm'
import { empty, emptyList, getIn, getMeta, getValue, is } from 'utils'
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper
} from '@material-ui/core'

import {
  triggerUpdateLineItem,
  addComponentsRow,
  createCustomAssembly
} from 'pages/SalesOrder/actions'

import { errorRed } from 'theme/colors'

let _isMounted = false

const AssembliesGrid = ({
  form,
  height,
  childIndex,
  dispatch,
  rowData,
  selectedRowIndex,
  assemblyRollupType,
  assemblyRollupOptionsList,
  layoutState,
  isPostingGridUpdate,
  selectedLineItemMap,
  gridApi,
  rowId,
  lineNumber,
  dataId,
  lineItemDataId
}) => {
  const assemblyRollupOptions = assemblyRollupOptionsList?.toJS
    ? assemblyRollupOptionsList.toJS()
    : []

  const [rollupType, setRollupType] = useState(assemblyRollupType)
  const [isPosting, setIsPosting] = useState(false)

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    setRollupType(assemblyRollupType)
  }, [selectedRowIndex, childIndex])

  useEffect(() => {
    if (!isPostingGridUpdate && isPosting) {
      setRollupType(assemblyRollupType)
      setIsPosting(false)
    }
  }, [isPostingGridUpdate])

  const handleChange = event => {
    const selectedLineItem = selectedLineItemMap?.toJS
      ? selectedLineItemMap.toJS()
      : {}
    setRollupType(event.target.value)

    if (selectedLineItem?.dataId) {
      setIsPosting(true)
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data: selectedLineItem,
          value: event.target.value,
          colDef: {
            field: 'assemblyRollupType'
          }
        })
      )
    }
  }

  const addRowToComponentsGrid = e => {
    dispatch(
      addComponentsRow(form, {
        parentLineNumber: lineNumber,
        rowId,
        gridApi
      })
    )

    setTimeout(() => {
      if (gridApi && _isMounted) {
        gridApi.forEachNode(node => {
          if (node?.data?.lineNumber === lineNumber) {
            if (!node?.expanded) {
              node.setExpanded(true)
            }
          }
        })

        gridApi.resetRowHeights()
      }
    }, 100)
  }

  const initCreateCustomAssembly = e =>
    dispatch(
      createCustomAssembly.try(form, {
        lineNumber,
        action: 'initialize'
      })
    )

  console.log(assemblyRollupOptions)

  if (!lineItemDataId) {
    return (
      <div style={{ height, width: '100%' }}>
        <p
          style={{
            margin: 15,
            color: errorRed,
            textAlign: 'center',
            fontWeight: 500
          }}
        >
          assembly edits not allowed
        </p>
      </div>
    )
  }
  if (rollupType === undefined) {
    debugger
  }
  return (
    <div style={{ height, width: '100%' }}>
      {!childIndex && (
        <div
          style={{
            padding: 5,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <Button
            onClick={addRowToComponentsGrid}
            variant="contained"
            style={{ margin: 5, minWidth: 225 }}
          >
            Add Component
          </Button>
          <Button
            onClick={initCreateCustomAssembly}
            variant="contained"
            style={{ margin: 5, minWidth: 225 }}
          >
            Create Custom Assembly
          </Button>
        </div>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Paper
          style={{
            padding: 15,
            width: 200,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <FormControl>
            <InputLabel id="assembly-rollup-type-label">Rollup</InputLabel>
            <Select
              labelId="assembly-rollup-type-label"
              id="assembly-rollup-type"
              value={rollupType}
              onChange={handleChange}
            >
              {assemblyRollupOptions.map((item, i) => (
                <MenuItem value={item.dataId} key={item.dataId}>
                  {item.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      </div>
    </div>
  )
}

/* fixed 9/25/2020 SVE */
export default withContext({
  assemblyRollupType: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')
    const childIndex = getIn(form, 'fields.lineItems.childIndex')
    const lineItems = getIn(form, 'fields.lineItems.rowData') || emptyList
    let assemblyRollUpType = null
    if (is.number(childIndex) && lineItems && lineItems?.get) {
      const temp = lineItems
        .get(selectedRowIndex)
        ?.get('components')
        ?.get(childIndex)
      debugger
      assemblyRollUpType = temp?.get('assemblyRollupType')
      // lineItems?.get(selectedRowIndex)?.get('assemblyRollupType') || null
      return assemblyRollUpType
    }
    if (is.number(selectedRowIndex) && lineItems && lineItems?.get) {
      assemblyRollUpType = lineItems
        ?.get(selectedRowIndex)
        ?.get('assemblyRollupType')
      return assemblyRollUpType
    }

    return assemblyRollUpType || ''
  },
  dataId: form => getValue(form, 'dataId', ''),
  assemblyRollupOptionsList: form =>
    getMeta(form, 'assemblyRollupOptions', emptyList),
  childIndex: form => {
    const childIndex = getIn(form, 'fields.lineItems.childIndex')

    if (is.number(childIndex)) {
      return childIndex
    }

    return null
  },
  selectedRowIndex: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')

    if (is.number(selectedRowIndex)) {
      return selectedRowIndex
    }

    return null
  },
  selectedLineItemMap: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')
    const lineItems = getIn(form, 'fields.lineItems.rowData') || emptyList

    if (is.number(selectedRowIndex) && lineItems?.size) {
      return lineItems.get(selectedRowIndex)
    }

    return empty
  },
  isPostingGridUpdate: form => form.get('isPostingGridUpdate') || false
})(AssembliesGrid)
