import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { TextField, FormControlLabel, Switch, Icon } from '@material-ui/core'
import RepairSerialNumberSearch from 'components/Search/RepairSerialNumberSearch'
import RepairSerialNumberSearchMobile from 'mobile/components/RepairSerialNumberSearchMobile'

// import SerialNumberSearch from 'components/Search/SerialNumberSearch'
// import SerialNumberSearchMobile from 'mobile/components/SerialNumberSearchMobile'

class RepairItemModal extends Component {
  constructor(props) {
    super(props)

    console.log('repairItemModal', props)

    this.state = {
      hasRepairPrinter: props?.data?.repairInfo?.hasRepairPrinter || false,
      printRepair: props?.data?.repairInfo?.printRepair || false,
      repairManufacturer: props?.data?.repairInfo?.repairManufacturer || '',
      repair: props?.data?.repairInfo?.repair || '',
      repairModel: props?.data?.repairInfo?.repairModel || '',
      repairProduct: props?.data?.repairInfo?.repairProduct || '',
      repairSerialNumber: props?.data?.repairInfo?.repairSerialNumber || '',
      comments: props?.data?.repairInfo?.comments || '',
      // isEditing: props?.data?.isEditing || false,
      form: props?.data?.form || '',
      productId: props?.data?.productId || '',
      uomId: props?.data?.uomId || '',
      isMobile: props?.data?.isMobile || false
    }
  }

  onToggleChange = (e, field) => this.setState({ [field]: e.target.checked })

  onTextFieldChange = (e, field) => this.setState({ [field]: e.target.value })

  setSerialNumberSearchField = (value, _, __, ___) => {
    this.setState({
      repairSerialNumber: value
    })
  }

  onSerialNumberSearchMobileBlur = value =>
    this.setState({
      repairSerialNumber: value
    })

  render() {
    const {
      comments,
      hasRepairPrinter,
      printRepair,
      repair,
      repairManufacturer,
      repairModel,
      repairProduct,
      repairSerialNumber,
      form,
      productId,
      uomId,
      isMobile
    } = this.state

    const { customerId, isEditing, warehouseId } = this.props

    return (
      <div className="repair-item-modal-wrapper" style={{ width: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: 15
          }}
        >
          <FormControlLabel
            label="Repair Item"
            labelPlacement="start"
            control={
              <Switch
                checked={repair}
                onChange={e => this.onToggleChange(e, 'repair')}
                disabled={!isEditing}
              />
            }
          />

          <FormControlLabel
            label="Print"
            labelPlacement="start"
            control={
              <Switch
                checked={printRepair}
                onChange={e => this.onToggleChange(e, 'printRepair')}
                disabled={!isEditing}
              />
            }
          />
        </div>
        {!hasRepairPrinter ? (
          <div
            className="alert alert-warning"
            style={{
              backgroundColor: '#fcf8e3',
              borderColor: '#faebcc',
              padding: 5,
              margin: '5px 5px 15px 5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Icon size="small" style={{ marginRight: 5 }}>
              warning
            </Icon>
            <span>
              Please set up a service tag label printer for this branch to print
            </span>
          </div>
        ) : null}
        <div>
          <div
            style={{
              alignItems: 'flex-end',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <div
              className="repair-item-modal-serial-number-search-wrapper"
              style={{
                padding: '0 15px 10px 15px',
                flexBasis: '50%'
              }}
            >
              {isMobile ? (
                <RepairSerialNumberSearchMobile
                  label="Serial Number"
                  dispatch={this.props.dispatch}
                  form={form}
                  customerId={customerId}
                  productId={productId}
                  warehouseId={warehouseId}
                  uomId={uomId}
                  value={repairSerialNumber}
                  setField={this.setSerialNumberSearchField}
                  disabled={!isEditing}
                  meta={{
                    allowInstantSearch: true,
                    allowSearchAll: true,
                    minimumKeywordLength: 1,
                    allowNavigation: false,
                    allowSpecialProducts: false,
                    hasFilters: false,
                    hideToggle: true
                  }}
                  allowInvalidValueOnBlur
                  onBlur={this.onSerialNumberSearchMobileBlur}
                  allowInvalidExactMatchSearch
                  width="100%"
                />
              ) : (
                <div style={{ width: '100%' }}>
                  <span style={{ fontSize: 10, color: '#444', margin: 0 }}>
                    Serial Number
                  </span>
                  <RepairSerialNumberSearch
                    label="Serial Number"
                    dispatch={this.props.dispatch}
                    form={form}
                    customerId={customerId}
                    productId={productId}
                    warehouseId={warehouseId}
                    uomId={uomId}
                    value={repairSerialNumber}
                    setField={this.setSerialNumberSearchField}
                    disabled={!isEditing}
                    meta={{
                      allowInstantSearch: true,
                      allowSearchAll: true,
                      minimumKeywordLength: 1,
                      allowNavigation: false,
                      allowSpecialProducts: false,
                      hasFilters: false,
                      hideToggle: true
                    }}
                    allowInvalidExactMatchSearch
                    width="100%"
                  />
                </div>
              )}
            </div>
            <div style={{ padding: '0 15px 10px 15px', flexBasis: '50%' }}>
              <TextField
                value={repairProduct}
                onChange={e => this.onTextFieldChange(e, 'repairProduct')}
                fullWidth
                label="Item"
                disabled={!isEditing}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <TextField
              value={repairManufacturer}
              onChange={e => this.onTextFieldChange(e, 'repairManufacturer')}
              fullWidth
              label="Manufacturer"
              style={{ margin: '0 15px 10px 15px' }}
              disabled={!isEditing}
            />
            <TextField
              value={repairModel}
              onChange={e => this.onTextFieldChange(e, 'repairModel')}
              fullWidth
              label="Model"
              style={{ margin: '0 15px' }}
              disabled={!isEditing}
            />
          </div>
          <div style={{ padding: '40px 15px 20px 15px' }}>
            <TextField
              value={comments}
              onChange={e => this.onTextFieldChange(e, 'comments')}
              fullWidth
              label="Comments"
              multiline
              rows="8"
              variant="outlined"
              disabled={!isEditing}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapState = (state, ownProps) => {
  // console.log('mapState', ownProps)
  const {
    data: { form }
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing')
  const warehouseId = getIn(formState, 'fields.warehouseId.value')
  const customerId = getIn(formState, 'fields.customerId.value')

  return {
    isEditing,
    customerId,
    warehouseId
  }
}

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(RepairItemModal)
