import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { getIn, plainDeepEqual } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import IconTooltip from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/hoc/IconTooltip'

// const mapDispatchToProps = dispatch => ({
//   deleteActivityAsync: ({ activeDataSet, dataId }) => dispatch(deleteActivityAsync.request('eventModal', { activeDataSet, dataId })),
//   deleteContactAsync: ({ parentType, dataId, parentId, recordName, modalTitleSuffix }) => dispatch(deleteContactAsync.request('customerMaster', { parentType, dataId, parentId, recordName, modalTitleSuffix }))
// })

// const mapStateToProps = state => {
//   const activities = getIn(state, 'ddiForm.customerMaster.fields.activities.value')
//   return {
//     activities: activities ? activities.toJS() : []
//   }
// }

class InvoiceLinkCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    // deleteActivityAsync: PropTypes.func.isRequired,
    // deleteContactAsync: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  dispatchClickAction = () => {
    const {
      colDef: { type }
    } = this.props
    if (type === 'paymentHistory') {
      this.openInvoiceInquiry()
    }
  }

  openInvoiceInquiry = () => {
    const {
      data: { dataId }
    } = this.props

    const openScreenParams = {
      dataId,
      name: 'invoiceInquiry',
      image: 'sales_invoicing_invoice_inquiry_16.png',
      title: 'Invoice Inquiry',
      route: 'invoiceinquiry',
      groupNames: ['header', 'detail']
    }

    this.props.dispatch(openScreenAction(openScreenParams))
  }

  render() {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{ color: '#444', display: 'inline-block', fontSize: 16 }}
          onClick={this.dispatchClickAction}
        >
          receipt
        </Icon>
      </div>
    )
  }
}

const composedLinkCell = IconTooltip({ tooltipContent: 'Invoice' })(
  InvoiceLinkCell
)

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(composedLinkCell)
