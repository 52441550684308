import React from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { FormControlLabel } from '@material-ui/core'
import { DDIButton, DDIToggle } from 'ddiForm/wrapped'
import * as actions from 'pages/SalesOrder/actions'
import {  getField } from 'utils'

export const ItemGroupsActionsMobile = ({
  dispatch,
  form,
  modal,
  guid,
  printItemGroupDetails
}) => {

  function exitModal() {
    const { id } = modal
    dispatch(actions.cancelItemGroups.try(form, { guid, modalId: id }))
  }

  function handleSave() {
    const { id } = modal
    dispatch(actions.saveItemGroups.try(form, { guid, modalId: id }))
  }

  function handleSort(e) {
    dispatch(
      actions.itemGroupsPropertyChange.try(form, { propertyName: 'sortOrders' })
    )
  }

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '0 15px 10px 15px'
        }}
      >
        <DDIButton
          onClick={handleSort}
          variant="contained"
          label="Sort Groups"
          preventAutoDisable
        />
        <FormControlLabel
          control={<DDIToggle propertyName="groups.printItemGroupDetails" />}
          label="Print Group Details"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <DDIToggle
              propertyName="groups.printItemGroupPrices"
              disabled={!printItemGroupDetails}
            />
          }
          label="Print Prices"
          labelPlacement="start"
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%'
        }}
      >
        <DDIButton
          onClick={handleSave}
          variant="contained"
          label="OK"
          preventAutoDisable
          style={{ marginRight: 15 }}
        />
        <DDIButton
          onClick={exitModal}
          variant="contained"
          label="Cancel"
          preventAutoDisable
        />
      </div>
    </div>
  )
}

// export const contextObj = {
//   printItemGroupDetails: form =>
//     form.fields &&
//     form.fields.groups &&
//     form.fields.groups.printItemGroupDetails &&
//     is.bool(form.fields.groups.printItemGroupDetails.value)
//       ? form.fields.groups.printItemGroupDetails.value
//       : false
// }

/* fixed 9/23/2020 SVE */
export const contextObj = {
  printItemGroupDetails: form =>
    getField(form, 'groups.printItemGroupDetails', false)
}

export default withContext(contextObj)(ItemGroupsActionsMobile)
