/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const executeInquiry = createAsyncFormAction(CONSTANTS.EXECUTE_INQUIRY)

export const clearProductData = form =>
  createAction(CONSTANTS.CLEAR_PRODUCT_DATA, {}, form)

export const setActiveTab = (form, { activeTab, hasData = false }) =>
  createAction(CONSTANTS.SET_ACTIVE_TAB, { activeTab, hasData }, form)

export const handlePurchaseHistoryLink = (
  form,
  { dataId, description, modalId }
) =>
  createAction(
    CONSTANTS.HANDLE_PURCHASE_HISTORY_LINK,
    { dataId, description, modalId },
    form
  )
