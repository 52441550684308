import React from 'react'
import { Icon, IconButton } from '@material-ui/core'

const DeleteButton = ({ dataId, deletePrintOrderItem }) => {
  const onClick = e => {
    e.stopPropagation()

    if (
      deletePrintOrderItem &&
      typeof deletePrintOrderItem === 'function' &&
      dataId
    ) {
      deletePrintOrderItem(dataId)
    }
  }

  return (
    <IconButton onClick={onClick} size="small" style={{ padding: 0 }}>
      <Icon style={{ color: '#d9534f' }}>cancel</Icon>
    </IconButton>
  )
}

export default DeleteButton
