/* eslint brace-style: 0 */
import { api /* local */ } from 'services'
import {
  take,
  fork,
  call,
  delay,
  select,
  cancel,
  put,
  putResolve
} from 'redux-saga/effects'
import moment from 'moment'
import { authIdSelector } from 'auth/selectors'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import { getIn, timestampFormatter } from 'utils'
import * as MAIN_CONSTANTS from 'pages/Main/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import { removeModal } from 'modals/actions'
import { blur, clearSearch } from 'mobile/components/IndexSearchMobile/actions'

import * as IDX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import * as SO_CONSTANTS from 'pages/SalesOrderInquiry/constants'

import * as CONSTANTS from './constants'
import arInvoiceExactMatch from './api'

import {
  addEventType,
  createEventType,
  deleteEventType,
  getEventTypes,
  getServerTime,
  lockEventType,
  unlockEventType,
  saveEventType,
  validateEventType
} from './actions'

import addOrEditActivityTask from './addOrEditActivityTask'

let tasks
let inModalTasks
// let modalId

const getReferenceType = callLocation => {
  const referenceTypes = {
    calendar: null,
    contact: 'Contacts',
    customerMaster: 'Customer',
    invoiceInquiry: 'InvoiceOrder',
    customerJob: 'Job'
  }

  if (referenceTypes[callLocation]) {
    return referenceTypes[callLocation]
  }

  // debugger
  if (
    (callLocation && callLocation.match(/salesOrder/)) ||
    (callLocation && callLocation === 'opportunityPipeline')
  ) {
    return 'SalesOrderHeader'
  }

  return null
}

// export const isFormValid = props => {
//   const eventType = props.fields.eventType.value
//   const subject = props.fields.subject.value
//   const startDate = props.fields.startDate.value
//   const endDate =
//     props.fields.endDate && props.fields.endDate.value
//       ? props.fields.endDate.value
//       : null
//   const referenceType = props.fields.referenceType.value
//   const referenceId =
//     props.fields.referenceId && props.fields.referenceId.value
//       ? props.fields.referenceId.value
//       : null
//   const timeSelectionsValid = moment(endDate) >= moment(startDate)
//   if (
//     (eventType && startDate && endDate && subject && timeSelectionsValid) ||
//     (eventType && eventType === 'T' && startDate && subject) ||
//     (eventType &&
//       startDate &&
//       endDate &&
//       subject &&
//       referenceType &&
//       referenceId &&
//       timeSelectionsValid) ||
//     (eventType &&
//       eventType === 'T' &&
//       startDate &&
//       referenceType &&
//       referenceId &&
//       subject)
//   ) {
//     return true
//   }
//   return false
// }

export function* getCustomFieldsProcess(form) {
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const meta = getIn(formState, 'meta')
  let fields = getIn(formState, 'fields')
  fields = fields && fields.toJS ? fields.toJS() : {}

  let customFields = getIn(meta, 'customFields.customFieldsData')
  customFields = customFields && customFields.toJS ? customFields.toJS() : []
  customFields = customFields.reduce((acc, next) => {
    const isBoolean = next.fieldType === 'CBX'
    const data = isBoolean
      ? {
          dataId: next.dataId,
          value: Boolean(
            getIn(fields, `customFields-${next.fieldNumber}.value`)
          )
        }
      : {
          dataId: next.dataId,
          value: getIn(fields, `customFields-${next.fieldNumber}.value`)
        }
    acc = acc.concat(data)
    return acc
  }, [])

  return customFields
}

export function* saveActivityProcess(form, thunk) {
  yield put({
    type: CONSTANTS.SAVE_ACTIVITY.REQUEST,
    meta: { form, apiRequest: true }
  })

  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  // const callLocation = getIn(formState, 'callLocation')

  const dataId = getIn(formState, 'dataId') ? getIn(formState, 'dataId') : null
  const eventTypeId = getIn(formState, 'fields.eventType.value')
  const taskTypeId = getIn(formState, 'fields.taskType.value')
  const subject = getIn(formState, 'fields.subject.value')

  const startDate = getIn(formState, 'fields.startDate.value')
  const endDate = getIn(formState, 'fields.endDate.value')

  const location = getIn(formState, 'fields.location.value')
  const notes = getIn(formState, 'fields.notes.value')
  const users =
    getIn(formState, 'fields.users.value') || getIn(formState, 'userId')
  const reminderType = getIn(formState, 'fields.reminderIntervalType.value')
  const reminderValue = getIn(formState, 'fields.reminderValue.value')
  const referenceType = getIn(formState, 'fields.referenceType.value')
  let referenceId = null

  /* Contact reference in this form has an idiotic case to accommodate */
  if (referenceType === 'CO') {
    referenceId = getIn(
      formState,
      'fields.referenceId.referenceData.recordName'
    )
  } else {
    referenceId = getIn(formState, 'fields.referenceId.value')
  }

  const completed = getIn(formState, 'fields.completed.value')
    ? getIn(formState, 'fields.completed.value')
    : false

  const customFields = yield call(getCustomFieldsProcess, form)

  const referenceTypeMap = {
    CO: 'Contacts',
    SO: 'SalesOrderHeader',
    IN: 'InvoiceOrder',
    PO: 'PurchaseOrder',
    JB: 'Job',
    C: 'Customer',
    CN: 'Container'
  }

  const apiParams =
    customFields && customFields.length
      ? {
          dataId,
          eventTypeId,
          taskTypeId,
          subject,
          startDate: `${timestampFormatter(startDate)} ${timestampFormatter(
            startDate,
            'hh:mm A'
          )}`,
          endDate: `${timestampFormatter(endDate)} ${timestampFormatter(
            endDate,
            'hh:mm A'
          )}`,
          location,
          notes,
          users,
          reminderType,
          reminderValue,
          referenceType: referenceType ? referenceTypeMap[referenceType] : '',
          referenceId,
          completed,
          customFields
        }
      : {
          dataId,
          eventTypeId,
          taskTypeId,
          subject,
          startDate: `${timestampFormatter(startDate)} ${timestampFormatter(
            startDate,
            'hh:mm A'
          )}`,
          endDate: `${timestampFormatter(endDate)} ${timestampFormatter(
            endDate,
            'hh:mm A'
          )}`,
          location,
          notes,
          users,
          reminderType,
          reminderValue,
          referenceType: referenceType ? referenceTypeMap[referenceType] : '',
          referenceId,
          completed
        }

  const { response, error } = yield call(api.saveActivity, apiParams)

  if (response) {
    yield put({
      type: CONSTANTS.SAVE_ACTIVITY.SUCCESS,
      meta: { form, thunk },
      payload: response
    })
    // yield put({
    //   type: REMOVE_MODAL,
    //   payload: { id: modalId }
    // })
  } else {
    yield put({
      type: CONSTANTS.SAVE_ACTIVITY.FAILURE,
      meta: { form, thunk },
      payload: error,
      error: true
    })

    if (error && error.validationErrors) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* displayValidationErrors(error) {
  let errorMessageString
  if (error?.validationErrors && Array.isArray(error.validationErrors)) {
    errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.message}\n`)
      return acc
    }, '')
  } else if (error?.message) {
    errorMessageString = error.message
  }
  if (errorMessageString) yield call(warningModal, errorMessageString, 'Error!')
}

export function* saveActivityListener(formListener) {
  let task
  while (true) {
    const {
      meta: { form, thunk }
    } = yield take(CONSTANTS.SAVE_ACTIVITY.TRY)
    if (form === formListener) {
      if (task) {
        yield cancel(task)
      }
      task = yield call(saveActivityProcess, form, thunk)
    }
  }
}

export function* lockActivityTask(dataId, form = 'calendar') {
  yield put({
    type: CONSTANTS.LOCK_ACTIVITY.REQUEST,
    meta: { form, apiRequest: true }
  })

  const callLocation = form
  const ret = yield call(api.getActivityMeta)
  const { response, error } = yield call(api.lockActivity, { dataId })
  const userId = yield select(authIdSelector)
  // debugger
  if (response) {
    const responseData = {
      ...response,
      meta: {
        ...ret.response
      },
      callLocation,
      dataId,
      form,
      userId
    }
    yield call(addOrEditActivityTask, responseData)
  } else if (error.status === 496) {
    // eslint-disable-line
    // yield call(warningModal, error.message, error.modalTitle)
    yield put({
      type: CONSTANTS.LOCK_ACTIVITY.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* unlockActivityTask(dataId, form) {
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))

  const { response, error } = yield call(api.unlockActivity, { dataId })
  if (response) {
    yield put({
      type: CONSTANTS.UNLOCK_ACTIVITY.SUCCESS,
      meta: { form },
      payload: response
    })
    // yield put({
    //   type: REMOVE_MODAL,
    //   payload: { id: modalId }
    // })
  } else {
    yield put({
      type: CONSTANTS.UNLOCK_ACTIVITY.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* unlockActivityListener(formListener) {
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.UNLOCK_ACTIVITY.TRY)
    if (form === formListener) {
      yield fork(unlockActivityTask, dataId, form)
    }
  }
}

export function* getEventTypesTask(payload) {
  const { indexSearchType, keyword, pageNumber } = payload
  const { response, error } = yield call(api.getEventTypes, {
    indexSearchType,
    keyword,
    pageNumber
  })
  if (response) {
    yield put(getEventTypes.success(response))
  } else {
    yield put(getEventTypes.failure(error))
  }
}

export function* getEventTypesListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.GET_EVENT_TYPES.REQUEST)
    yield fork(getEventTypesTask, payload)
  }
}

export function* validateEventTypeTask(payload) {
  const { dataId } = payload
  const { response, error } = yield call(api.validateEventType, {
    dataId
  })
  if (response) {
    yield put(validateEventType.success(response))
  } else {
    yield put(validateEventType.failure(error))
  }
}

export function* validateEventTypeListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.VALIDATE_EVENT_TYPE.REQUEST)
    yield fork(validateEventTypeTask, payload)
  }
}

export function* createEventTypeTask(payload) {
  const { dataId } = payload

  const { response, error } = yield call(api.createEventType, {
    dataId
  })

  // debugger
  if (response) {
    yield put(createEventType.success(response))
  } else {
    yield put(createEventType.failure(error))
  }
}

export function* createEventTypeListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.CREATE_EVENT_TYPE.REQUEST)
    yield fork(createEventTypeTask, payload)
  }
}

export function* saveEventTypeTask(payload) {
  const { dataId, color } = payload
  const { response, error } = yield call(api.saveEventType, {
    dataId,
    color
  })
  if (response) {
    yield put(saveEventType.success(response))
  } else {
    yield put(saveEventType.failure(error))
  }
}

export function* saveEventTypeListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.SAVE_EVENT_TYPE.REQUEST)
    yield fork(saveEventTypeTask, payload)
  }
}

export function* deleteEventTypeTask(payload) {
  const { dataId } = payload
  const { response, error } = yield call(api.deleteEventType, {
    dataId
  })
  if (response) {
    yield put(deleteEventType.success({ dataId }))
  } else {
    yield put(deleteEventType.failure(error))
  }
}

export function* deleteEventTypeListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.DELETE_EVENT_TYPE.REQUEST)
    // debugger
    yield call(
      confirmationModal,
      `Are you sure you want to delete Event Type "${payload.dataId}"?`,
      'Really?',
      { modalOverrideClass: 'delete-event-confirmation-modal' }
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      yield fork(deleteEventTypeTask, payload)
    }
  }
}

export function* lockEventTypeTask(payload) {
  const { dataId } = payload
  const { response, error } = yield call(api.lockEventType, { dataId })
  if (response) {
    yield put(lockEventType.success(response))
  } else {
    yield put(lockEventType.failure(error))
  }
}

export function* lockEventTypeListener(formListener) {
  let task
  while (true) {
    const { payload } = yield take(CONSTANTS.LOCK_EVENT_TYPE.REQUEST)
    // // debugger
    if (task) {
      yield cancel(task)
    }
    task = yield fork(lockEventTypeTask, payload)
  }
}

export function* unlockEventTypeTask(payload) {
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const { dataId, editorOpen, isEditing = false } = payload

  // debugger
  if (isEditing) {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Create New?'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }
  }

  const { response, error } = yield call(api.unlockEventType, {
    dataId: dataId || getIn(formState, 'fields.newEventType.value')
  })

  if (response) {
    yield put(unlockEventType.success({ ...response, editorOpen, isEditing }))
  } else {
    yield put(unlockEventType.failure(error))
  }
}

export function* unlockEventTypeListener() {
  while (true) {
    const { payload, meta } = yield take(CONSTANTS.UNLOCK_EVENT_TYPE.REQUEST)

    // debugger
    yield fork(unlockEventTypeTask, payload)
  }
}

export function* getServerTimeTask() {
  const { response, error } = yield call(api.getServerTime)
  let endDate = response
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const startDate = getIn(formState, 'fields.startDate.value')
  if (moment(endDate) < moment(startDate)) {
    endDate = moment(startDate).add(1, 'hour')
  }

  if (response) {
    yield put(getServerTime.success({ endDate }))
  } else {
    yield put(getServerTime.failure(error))
  }
}

export function* getServerTimeListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.GET_SERVER_TIME.REQUEST)
    yield fork(getServerTimeTask)
  }
}

export function* handleTaskCompletedProcess(form, value) {
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const completedPrevValue = getIn(formState, 'fields.completed.prevValue')

  if (completedPrevValue !== value) {
    yield fork(getServerTimeTask)
  }
}

export function* resetSalesOrderMobileSearch(
  form,
  propertyName = 'referenceId'
) {
  /*
    this was a temp function and is no longer necessary
  */
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const referenceId = getIn(formState, 'fields.referenceId.value')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  if (isMobile && !referenceId) {
    yield delay(100)
    yield put(
      clearSearch(form, {
        propertyName
      })
    )

    yield put(
      blur(form, {
        propertyName
      })
    )
  }
}

export function* setFieldListener() {
  while (true) {
    const {
      payload: { propertyName, value },
      meta: { form }
    } = yield take(DDICONSTANTS.SET_FIELD)

    if (
      propertyName === 'completed' &&
      value === true &&
      form === 'eventModal'
    ) {
      yield fork(handleTaskCompletedProcess)
    }

    // if (propertyName === 'referenceType' && value === 'SO' && form === 'eventModal') {
    //   yield fork(resetSalesOrderMobileSearch, form, 'referenceId')
    // }
  }
}

export function* addEventTypeTask(payload) {
  const { credentials } = payload
  const { response, error } = yield call(api.addEventType, {
    credentials
  })
  if (response) {
    yield put(addEventType.success(response))
  } else {
    yield put(addEventType.failure(error))
  }
}

export function* addEventTypeListener(formListener) {
  while (true) {
    const { payload } = yield take(CONSTANTS.ADD_EVENT_TYPE.REQUEST)
    yield fork(addEventTypeTask, payload)
  }
}

export function* deleteActivityProcess(payload, form) {
  // const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  // const callLocation = getIn(formState, 'callLocation')

  const { dataId, credentials, removeMe, cb, modalId } = payload
  const params = removeMe
    ? {
        dataId,
        removeMe,
        credentials
      }
    : {
        dataId,
        credentials
      }

  const { response, error } = yield call(api.deleteActivity, params)

  if (response) {
    // debugger
    yield put({
      type: CONSTANTS.DELETE_ACTIVITY.SUCCESS,
      meta: { form },
      payload: {
        ...response,
        dataId
      }
    })

    if (cb) {
      cb()
    }

    if (modalId && removeMe) {
      yield put(removeModal(null, modalId))
    }
  }
  //  else if (error.status === 497) {
  //   // eslint-disable-line
  //   yield call(showCalendarAccessOverrideModal, error, payload, deleteMode, cb)
  // }
  else {
    yield put({
      type: CONSTANTS.DELETE_ACTIVITY.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* deleteActivityListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(CONSTANTS.DELETE_ACTIVITY.TRY)

    if (form === formListener) {
      yield fork(handleDeleteProcess, form, payload)
    }
  }
}

export function* handleDeleteProcess(form, payload) {
  const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))
  const users = getIn(formState, 'fields.users.value')
  const userId = getIn(formState, 'userId')
  // console.log('USERS', users, userId, users === userId)
  // debugger

  if (payload.credentials) {
    yield fork(deleteActivityProcess, payload, form)
    return
  }

  if (payload.removeMe && users !== userId) {
    yield fork(deleteActivityProcess, payload, form)
    return
  }

  const warningMessage = `Are you sure you want to delete ${payload.type} - "${payload.subject}"?`

  yield call(confirmationModal, warningMessage, 'Delete?')
  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CONFIRMED) {
    yield fork(deleteActivityProcess, payload, form)
  }
}

export function* arInvoiceExactMatchSearchListener() {
  let task
  while (true) {
    const action = yield take(CONSTANTS.AR_INVOICE_EXACT_MATCH.REQUEST)
    const {
      payload: { keyword, propertyName, subProperty },
      meta: { form, thunk }
    } = action
    if (task) yield cancel(task)
    const { response, error } = yield call(arInvoiceExactMatch, {
      keyword
    })

    // console.log('RESPONSE', response, error)
    if (response) {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...response,
          propertyName,
          subProperty
        },
        type: CONSTANTS.AR_INVOICE_EXACT_MATCH.SUCCESS
      })
    } else {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...error,
          propertyName,
          subProperty
        },
        type: CONSTANTS.AR_INVOICE_EXACT_MATCH.FAILURE
        // error: true
      })
    }
  }
}
// export function* soSearchExactMatchListener() {
//   let task
//   while(true) {

//   }
// }

export function* onTooltipKeyPressListener() {
  while (true) {
    const { payload } = yield take(CONSTANTS.ON_TOOLTIP_KEYPRESS)
    const formState = yield select(state => getIn(state, 'ddiForm.eventModal'))

    const dataId = getIn(formState, 'dataId')

    /* first unlock the record if needed */
    if (dataId) {
      yield put({
        type: CONSTANTS.UNLOCK_ACTIVITY.REQUEST,
        payload: { dataId }
      })
    }

    // yield put({
    //   type: REMOVE_MODAL,
    //   payload: { id: modalId }
    // })

    yield put({
      type: MAIN_CONSTANTS.OPEN_SCREEN,
      payload: { ...payload.openArgs }
    })
  }
}

export function* lockActivityListener(formListener) {
  let task
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.LOCK_ACTIVITY.TRY)

    if (form === formListener) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(lockActivityTask, dataId, form)
    }
  }
}

export function* addNewActivityProcess(
  form,
  selectedStartDate,
  referenceData = {}
) {
  // debugger
  yield put({
    type: CONSTANTS.ADD_NEW_ACTIVITY.REQUEST,
    meta: { form, apiRequest: true }
  })

  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  let referenceId = null
  if (form !== 'calendar') {
    if (form === 'contact') {
      referenceId = getIn(formState, 'values.recordName')
    } else {
      referenceId =
        getIn(formState, 'fields.dataId.value') ||
        getIn(formState, 'values.dataId')
    }
  }

  const userId = yield select(authIdSelector)
  const ret = yield call(api.getActivityMeta)
  const apiParams =
    referenceData &&
    typeof referenceData === 'object' &&
    referenceData !== null &&
    Object.keys(referenceData).length
      ? {
          ...referenceData
        }
      : {
          referenceId,
          referenceType: getReferenceType(form)
        }

  let { response, error } = yield call(api.addNewActivity, apiParams)

  if (response) {
    response = {
      ...response,
      meta: {
        ...ret.response
      }
    }

    const payload = {
      ...response,
      userId,
      isNotesContact:
        form === 'contact' ? getIn(formState, 'values.isNotesContact') : false,
      selectedStartDate: selectedStartDate || new Date(),
      callLocation: form
    }

    yield call(addOrEditActivityTask, payload)
    // debugger
    yield put({
      type: CONSTANTS.ADD_NEW_ACTIVITY.SUCCESS,
      meta: { form },
      payload
    })
  } else {
    yield put({
      type: CONSTANTS.ADD_NEW_ACTIVITY.FAILURE,
      meta: { form },
      payload: error
    })
  }
}

export function* addNewActivityListener(formListener) {
  let task
  while (true) {
    const {
      meta: { form },
      payload: { selectedStartDate, referenceData = {} }
    } = yield take(CONSTANTS.ADD_NEW_ACTIVITY.TRY)
    // // debugger

    if (form === formListener) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(
        addNewActivityProcess,
        form,
        selectedStartDate,
        referenceData
      )
    }
  }
}

export function* getActivityTooltipProcess({ dataId, thunk }) {
  const { response, error } = yield call(api.getActivityTooltip, {
    dataId
  })

  if (response) {
    yield putResolve({
      meta: { thunk },
      payload: { ...response },
      type: CONSTANTS.GET_ACTIVITY_TOOLTIP.SUCCESS
    })
  } else {
    yield putResolve({
      error: true,
      meta: { thunk },
      payload: { ...error },
      type: CONSTANTS.GET_ACTIVITY_TOOLTIP.FAILURE
    })
  }
}

export function* getActivityTooltipListener(formListener) {
  let task
  while (true) {
    const action = yield take(CONSTANTS.GET_ACTIVITY_TOOLTIP.REQUEST)
    const {
      payload: { dataId },
      meta: { form, thunk }
    } = action
    if (form === formListener) {
      if (task) yield cancel(task)
      task = yield call(getActivityTooltipProcess, {
        dataId,
        thunk
      })
    }
  }
}

export function* indexSearchListener() {
  while (true) {
    const {
      meta: { form },
      payload: { keyword, propertyName }
    } = yield take(IDX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.REQUEST)

    if (propertyName === 'newEventType' && keyword) {
      const action = yield take(IDX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS)

      if (action?.payload && !action?.payload?.exactMatchSearchResults) {
        yield put(createEventType.request('eventModal', { dataId: keyword }))
      }
    }
  }
}

export function* salesOrderSearchFailureListener() {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(SO_CONSTANTS.EXACT_MATCH.FAILURE)

    if (
      propertyName &&
      propertyName === 'referenceId' &&
      form &&
      form === 'eventModal.salesOrderInquiry'
    ) {
      yield put({
        meta: { form: 'eventModal' },
        payload: {
          propertyName
        },
        type: SO_CONSTANTS.EXACT_MATCH.FAILURE
      })
    }
  }
}

export default function* eventModalSagas(formListener) {
  yield fork(getActivityTooltipListener, formListener)
  yield fork(unlockActivityListener, formListener)
  yield fork(saveActivityListener, formListener)
  yield fork(lockActivityListener, formListener)
  yield fork(addNewActivityListener, formListener)
  yield fork(deleteActivityListener, formListener)

  yield fork(validateEventTypeListener)
  yield fork(addEventTypeListener)
  yield fork(createEventTypeListener)
  yield fork(getEventTypesListener)
  yield fork(saveEventTypeListener)
  yield fork(deleteEventTypeListener)
  yield fork(unlockEventTypeListener)
  yield fork(lockEventTypeListener)
  yield fork(onTooltipKeyPressListener)
  yield fork(setFieldListener)
  yield fork(arInvoiceExactMatchSearchListener)
  yield fork(indexSearchListener)
  yield fork(salesOrderSearchFailureListener)
}
