/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import { DatePicker, Field, NumberSpinner, withContext } from 'ddiForm'

import { DDINumberSpinner } from 'ddiForm/wrapped'

import DDIExpansionPanel from 'components/DDIExpansionPanel'
import DDICardWrapper from 'components/DDICardWrapper'
import { Grid as BootstrapGrid, Row, Col } from 'react-bootstrap'
import RenderRadioGroup from 'components/RenderRadioGroup'
import { makeRadioButtons } from 'pages/CustomerOrderPad/utils'
import { emptyList, getIn, getMeta, getField, getValue } from 'utils'

const formFieldWrapperStyle = {
  clear: 'both',
  float: 'left',
  minHeight: 65,
  padding: 10,
  width: '100%'
}

const FilterBy = ({ filterBy, filterByOptionsList, defaultCutOffDate }) => {
  const filterByOptions =
    filterByOptionsList && filterByOptionsList.toJS
      ? filterByOptionsList.toJS()
      : []

  // console.log('FILTER_BY', filterBy, filterByOptions, defaultCutOffDate)

  return (
    <DDIExpansionPanel
      title="Filter By"
      expansionPanelContentStyle={{ width: '100%' }}
    >
      <Row>
        <Col lg={5} md={6} sm={6} xs={12}>
          <Field
            component={RenderRadioGroup}
            propertyName="filterBy"
            defaultSelected="A"
            initialValue="A"
            renderAsRow
          >
            {makeRadioButtons(filterByOptions, 'filterBy')}
          </Field>
        </Col>
        <Col lg={7} md={6} sm={6} xs={12}>
          <Row>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="col-md-up-pad-right-only"
            >
              <DDICardWrapper
                title="Hits"
                contentStyle={formFieldWrapperStyle}
                headlineClassName="ddi-card-heading ddi-card-heading-secondary"
                style={{ marginTop: 10 }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Field
                    component={NumberSpinner}
                    disabled={filterBy !== 'D'}
                    propertyName="hits"
                    type="number"
                    fullWidth
                    wrapperStyle={{}}
                  />
                </div>
              </DDICardWrapper>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="col-md-up-pad-right-only"
            >
              <div>
                <DDICardWrapper
                  title="History"
                  contentStyle={formFieldWrapperStyle}
                  headlineClassName="ddi-card-heading ddi-card-heading-secondary"
                  style={{ marginTop: 10 }}
                >
                  <label
                    htmlFor="cutOffDate"
                    style={{
                      color: '#999',
                      fontSize: 10,
                      margin: 0
                    }}
                  >
                    Cut-Off Date
                  </label>
                  <Field
                    component={DatePicker}
                    propertyName="cutOffDate"
                    initialValue={new Date(defaultCutOffDate)}
                    views={['date']}
                  />
                </DDICardWrapper>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </DDIExpansionPanel>
  )
}

// export default withContext({
//   defaultCutOffDate: form => form?.meta?.defaultCutOffDate || new Date(),
//   filterBy: form => form?.fields?.filterBy?.value || '',
//   filterByOptions: form => form?.meta?.filterByOptions || []
// })(FilterBy)
export default withContext({
  defaultCutOffDate: form => getMeta(form, 'defaultCutOffDate', new Date()),
  filterBy: form => getValue(form, 'filterBy', ''),
  filterByOptionsList: form => getMeta(form, 'filterByOptions', emptyList)
})(FilterBy)
