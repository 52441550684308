import React, { useEffect } from 'react'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'
import ImageCellHeader from 'pages/SalesOrder/components/ImageCellHeader'
import RemoveLineItemCell from 'pages/SalesOrder/tabs/Detail/components/RemoveLineItemCell'
import QuantityCell from 'pages/SalesOrder//components/ManualGrid/QuantityCellWrapper'
import QuantityCellRenderer from 'pages/SalesOrder/components/ManualGrid/QuantityCellRenderer'
import TextFieldEditorCell from 'pages/SalesOrder/components/ManualGrid/TextFieldEditorCell'
import IndexSearchEditor from 'pages/SalesOrder/components/ManualGrid/IndexSearchEditor'
import ToggleCheckboxCell from 'pages/SalesOrder/components/ManualGrid/ToggleCheckboxCell'
import DropdownArrow from 'pages/SalesOrder//components/ManualGrid/DropdownArrow'
import SerialNumberDropdown from 'pages/SalesOrder/tabs/Order/components/LineItemsSection/components/SerialNumberDropdown'

import PricingCell from 'pages/SalesOrder/tabs/Detail/components/PricingCellWrapper'
import { Icon, IconButton, Tooltip } from '@material-ui/core'
import { debounce } from 'lodash'
import { addBlankRow } from 'ddiForm/actions'
import usePrevious from 'hooks/usePrevious'
import AwaitingBackdrop from 'components/AwaitingBackdrop'

/* open Order Pad */
import {
  openCustomerOrderPadInModal as openCustomerOrderPadInModalAction,
  launchAddItemGroupModal,
  launchRecalculatePricesModal
} from 'pages/SalesOrder/actions'

import { openProductImportEditor } from 'components/ProductImport/actions'

import LineItemsGridCustomTooltip from '../LineItemsGridCustomTooltip'
import ComponentsGrid from './components/ComponentsGrid'

const frameWorkComponents = {
  indexSearchEditor: IndexSearchEditor,
  textFieldEditor: TextFieldEditorCell,
  removeLineItemCell: RemoveLineItemCell,
  imageCellHeader: ImageCellHeader,
  quantityCellEditor: QuantityCell,
  quantityCellRenderer: QuantityCellRenderer,
  checkBoxCellEditor: ToggleCheckboxCell,
  pricingCellEditor: PricingCell,
  serialNumberEditor: SerialNumberDropdown,
  dropdownArrow: DropdownArrow,
  detailCellRenderer: ComponentsGrid
}

const boxShadow =
  'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'

let gridRef = null

export default React.memo(props => {
  const {
    columnDefs,
    contentHeight,
    copyProducts,
    dataId,
    defaultColDef,
    detailCellRendererParams,
    dispatch,
    emptyRow,
    form,
    getContextMenuItems,
    getRowHeight,
    getRowNodeId,
    getToggleNavIcons,
    gridApi,
    height,
    isEditing,
    isRowMaster,
    masterDetail,
    navigateToNextCell,
    onCellDoubleClicked,
    onCellFocused,
    onCellValueChanged,
    onGridReady,
    onRowSelected,
    onSelectionChanged,
    openFastProductModal,
    popupEditorOpen,
    tabToNextCell,
    uiCompacted,
    onRowDataUpdated,
    groupRowInnerRendererFramework,
    defaultGroupSortComparator,
    popupParent,
    gridLayoutKey,
    onColumnVisibleCb,
    onDragStoppedCb,
    isAddingBulkLineItems,
    applyColumnDefOrder,
    onRowGroupOpened,
    onDisplayedColumnsChanged
  } = props

  const prevGridLayoutKey = usePrevious(gridLayoutKey)

  useEffect(() => {
    if (
      gridLayoutKey !== prevGridLayoutKey &&
      columnDefs &&
      Array.isArray(columnDefs) &&
      gridRef?.columnApi
    ) {
      /* this routine is very important -- SVE 1/11/21 */
      const layoutRules = columnDefs.reduce(
        (acc, next) => {
          if (next.hide) {
            acc.hide = acc.hide.concat(next.colId ? next.colId : next.field)
          } else {
            acc.show = acc.show.concat(next.colId ? next.colId : next.field)
          }
          return acc
        },
        { show: [], hide: [] }
      )

      gridRef.columnApi.setColumnsVisible(layoutRules.show, true)
      gridRef.columnApi.setColumnsVisible(layoutRules.hide, false)
    }
  }, [prevGridLayoutKey, gridLayoutKey])

  const openCustomerOrderPadInModal = debounce(event => {
    dispatch(
      openCustomerOrderPadInModalAction(form, {
        gridApi: gridRef && gridRef.api ? gridRef.api : null
      })
    )
  }, 500)

  const openRecalculatePricesModal = debounce(e => {
    dispatch(launchRecalculatePricesModal.try(form))
  }, 500)

  const onCellKeyDown = params => {
    const { node, event, api } = params
    if (event?.ctrlKey && event?.key === 'g' && isEditing) {
      const {
        data: { dataId = '', lineNumber = 0, itemGroupId }
      } = node
      // open add item group modal
      dispatch(
        launchAddItemGroupModal.try(form, {
          dataId,
          lineNumber,
          gridApi: api,
          value: itemGroupId
        })
      )
    }
  }

  const addBlankRowProp = params => {
    if (gridRef?.api) {
      gridRef.api.deselectAll()
    }
    dispatch(addBlankRow(form, { propertyName: 'lineItems' }))
  }

  const openProductImportModal = debounce(e => {
    dispatch(
      openProductImportEditor.try(form, {
        lineItemsGridApi: gridRef && gridRef.api ? gridRef.api : null
      })
    )
  })

  return (
    <div
      className="pane-content"
      style={{
        overflow: uiCompacted ? 'hidden' : 'visible',
        width: '100%'
      }}
    >
      <div
        style={{
          minHeight: contentHeight,
          position: 'relative',
          width: '100%'
        }}
      >
        <AwaitingBackdrop
          isAwaiting={isAddingBulkLineItems}
          message="Processing Request..."
        />
        {uiCompacted ? null : getToggleNavIcons(uiCompacted, popupEditorOpen)}
        <div
          id="line-items-grid"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            minHeight: contentHeight,
            width: '100%',
            zIndex: 1
          }}
        >
          <ManualGrid
            addButtonText="Add Line Item"
            addBlankRow={addBlankRowProp}
            allowContextMenuWithControlKey
            allowDuplicates
            allowFieldChooser
            autoSize
            onCellKeyDown={onCellKeyDown}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            immutableData
            dispatch={dispatch}
            displayEmptyRowWhileEmpty
            emptyRow={emptyRow}
            focusCell="quantityOrdered"
            form={form}
            frameworkComponents={frameWorkComponents}
            components={{
              customTooltip: LineItemsGridCustomTooltip
            }}
            getContextMenuItems={getContextMenuItems}
            getRowHeight={getRowHeight}
            getRowNodeId={getRowNodeId}
            gridRef={el => (gridRef = el)}
            height={height}
            isRowMaster={isRowMaster}
            masterDetail
            navigateToNextCell={navigateToNextCell}
            onCellDoubleClicked={onCellDoubleClicked}
            onCellFocused={onCellFocused}
            onCellValueChanged={onCellValueChanged}
            onGridReady={onGridReady}
            onRowDataUpdated={onRowDataUpdated}
            onSelectionChanged={onSelectionChanged}
            propertyName="lineItems"
            // rowSelection="single"
            showAddButtonIfNoRecordOrEditing={!(form === 'invoiceInquiry')}
            suppressColumnVirtualisation
            suppressRowClickSelection={false}
            skipValidationOnUnmount
            singleClickEdit
            tabToNextCell={tabToNextCell}
            title="Line Items"
            groupUseEntireRow
            groupRowInnerRendererFramework={groupRowInnerRendererFramework}
            defaultGroupSortComparator={defaultGroupSortComparator}
            popupParent={popupParent}
            key={gridLayoutKey}
            onColumnVisibleCb={onColumnVisibleCb}
            onDragStoppedCb={onDragStoppedCb}
            applyColumnDefOrder={applyColumnDefOrder}
            detailCellRendererParams={detailCellRendererParams}
            onRowGroupOpened={onRowGroupOpened}
            onRowSelected={onRowSelected}
            onDisplayedColumnsChanged={onDisplayedColumnsChanged}
            rowSelection="multiple"
            // keepDetailRows
            // rememberGroupStateWhenNewData
            // detailRowAutoHeight
          />
        </div>
        {(isEditing || !dataId) && form !== 'invoiceInquiry' ? (
          <div
            id="grid-action-buttons"
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              right: '50%',
              zIndex: 2
            }}
          >
            <Tooltip title="Copy Products" placement="top">
              <IconButton
                onClick={copyProducts}
                size="small"
                style={{
                  background: '#e9e9e9',
                  boxShadow,
                  marginRight: 10
                }}
                tabIndex={-1}
              >
                <Icon style={{ fontSize: 16 }}>file_copy</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Fast Product" placement="top">
              <IconButton
                onClick={openFastProductModal}
                size="small"
                style={{
                  background: '#e9e9e9',
                  boxShadow,
                  display: 'block',
                  marginRight: 10
                }}
                tabIndex={-1}
              >
                <Icon style={{ fontSize: 16 }}>add_circle</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Customer Order Pad" placement="top">
              <IconButton
                onClick={openCustomerOrderPadInModal}
                size="small"
                style={{
                  background: '#e9e9e9',
                  boxShadow,
                  display: 'block',
                  marginRight: 10
                }}
                tabIndex={-1}
              >
                <Icon style={{ fontSize: 16 }}>receipt</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Recalculate" placement="top">
              <IconButton
                onClick={openRecalculatePricesModal}
                size="small"
                style={{
                  background: '#e9e9e9',
                  boxShadow,
                  display: 'block',
                  marginRight: 10
                }}
                tabIndex={-1}
              >
                <Icon style={{ fontSize: 16 }}>calculate</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Import" placement="top">
              <IconButton
                onClick={openProductImportModal}
                size="small"
                style={{
                  background: '#e9e9e9',
                  boxShadow,
                  display: 'block',
                  marginRight: 10
                }}
                tabIndex={-1}
              >
                <Icon style={{ fontSize: 16 }}>upload</Icon>
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
      </div>
    </div>
  )
})
