import React, { useState } from 'react'
import { withContext } from 'ddiForm'
import { getMeta } from 'utils'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'
import { FormControlLabel, Switch } from '@material-ui/core'

const StandaloneToggleSet = ({
  form,
  dispatch,
  priceChange,
  lineNumber,
  showOneTimePrice,
  showDisableMarginPrice,
  isEditing
}) => {
  const {
    disableMarginPricing,
    enableDisableMarginPriceCheckbox,
    oneTimePrice
  } = priceChange

  const [state, setState] = useState({
    disableMarginPricing,
    oneTimePrice
  })

  const handleChange = name => event => {
    event.persist()
    console.log(event.target.checked, state)
    setState({
      ...state,
      [name]: event.target.checked
    })

    dispatch(
      changeProvisionalLineItem.try(form, {
        propertyName: name,
        lineNumber,
        value: event.target.checked
      })
    )
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        margin: '10px auto',
        justifyContent: 'space-evenly'
      }}
    >
      {showOneTimePrice ? (
        <FormControlLabel
          control={
            <Switch
              checked={state.oneTimePrice}
              onChange={handleChange('oneTimePrice')}
              value="oneTimePrice"
              disabled={!isEditing}
            />
          }
          label="One Time Price"
        />
      ) : null}
      {showDisableMarginPrice ? (
        <FormControlLabel
          control={
            <Switch
              checked={state.disableMarginPricing}
              onChange={handleChange('disableMarginPricing')}
              disabled={!enableDisableMarginPriceCheckbox}
              value="disableMarginPricing"
            />
          }
          label="Enable Margin Pricing"
        />
      ) : null}
    </div>
  )
}

// const contextObj = {
//   showDisableMarginPrice: form =>
//     form.meta && form.meta.showDisableMarginPrice
//       ? form.meta.showDisableMarginPrice
//       : false,
//   showOneTimePrice: form =>
//     form.meta && form.meta.showOneTimePrice ? form.meta.showOneTimePrice : false
// }

/* fixed SVE 9/24/2020 */
const contextObj = {
  showDisableMarginPrice: form =>
    getMeta(form, 'showDisableMarginPrice', false),
  showOneTimePrice: form => getMeta(form, 'showOneTimePrice', false),
  isEditing: form => form.get('isEditing') || false
}

export default withContext(contextObj)(StandaloneToggleSet)
