import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'automaticbackorderrelease'

export const getBranchDefaults = (branchId) => {
    const options = createApiOptions({
        body: { branchId},
        method: 'post'
    })

    return callFetch(`${BASE_INFORM_API_URL}/${screen}/branchdefaults`, options)
}

export const releaseAutomaticBackorders = (properties) => {
    const options = createApiOptions({
        body: {...properties},
        method: 'post'
    })

    return callFetch(`${BASE_INFORM_API_URL}/${screen}/release`, options)
}


export const closeAutomaticBackorderRelease = () => {
    const options = createApiOptions({
        body: {},
        method: 'post'
    })

    return callFetch(`${BASE_INFORM_API_URL}/${screen}/close`, options)
}
