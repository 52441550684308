import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, Label } from 'ddiForm'
import { DDITextField } from 'ddiForm/wrapped'
import DDIIndexSearch from 'mobile/ddiForm/wrapped/ddiIndexSearchMobile'

import { getIn } from 'utils'
import { required } from 'ddiForm/validations'

const mapStateToProps = (state, ownProps) => {
  // const { form } = ownProps.data

  const defaultUserId = getIn(state, 'auth.dataId') || ''

  return {
    defaultUserId
  }
}

class VoidSalesOrderModal extends Component {
  componentDidMount() {
    console.log('void')
  }

  render() {
    const { defaultUserId } = this.props
    // const isMobile = this?.props?.data?.isMobile || false
    // console.log('_VOID', isMobile, this.props)

    return (
      <div className="void-sales-order-mobile-modal">
        <DDIIndexSearch
          propertyName="userId"
          meta={{
            fieldName: 'Writer',
            searchType: 'SystemUsers',
            hideToggle: true,
            hasFilters: false,
            allowSearchAll: false,
            allowInstantSearch: true,
            minimumKeywordLength: 0,
            allowNavigation: false,
            allowSpecialProducts: false,
            openText: null,
            isSecure: true
          }}
          preventAutoDisable
          forwardRef
          inputRef={el => (this.userId = el)}
          autoFocus
          initialValue={defaultUserId}
          validate={[required]}
        />
        <DDITextField
          label="Reason"
          propertyName="reason"
          fullWidth
          validate={[required]}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(VoidSalesOrderModal)
