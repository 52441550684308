import {
  // actionChannel,
  call,
  fork,
  put,
  select,
  take
} from 'redux-saga/effects'

import { api } from 'services'
import * as CONSTANTS from 'pages/ProductMaster/constants'
// import * as DDICONSTANTS from 'ddiForm/constants'
// import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { getIn } from 'utils'
import * as actions from 'pages/ProductMaster/actions'
import {
  getFormSelector
  // getSelectedTabsFromState,
  // mapResponse
} from 'ddiForm/utils'
import { addModal } from 'modals/actions'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import BinEditorModal from 'pages/ProductMaster/tabs/Analysis/components/BinEditorModal'

import { setField } from 'ddiForm/actions'
import {
  DELETE_GRID_ROW,
  UPDATE_GRID_CELL_DATA
} from 'components/EditableGrid/constants'

import {
  CLEAR_SEARCH,
  EXACT_MATCH_SEARCH,
  FIND_NEXT,
  FIND_PREV
} from 'components/Search/IndexSearch/constants'

export function* openBinAssignmentModal(form) {
  const buttonIsDisabled = formState => {
    const { fields, values } = formState
    const productId = getIn(fields, 'binEditorModal.productId.value')
    const warehouseId = getIn(fields, 'binEditorModal.warehouseId.value')
    const recordLocked = getIn(values, 'binEditorModal.locked')

    if (productId && warehouseId && recordLocked) {
      return false
    }

    return true
  }

  const modalOpts = {
    component: BinEditorModal,
    options: {
      form,
      data: {
        actions: [
          {
            title: 'Save',
            primary: true,
            async clickEvent(args, formState, cb) {
              try {
                await this.props.saveInventoryBinAssignment(form, { cb })
              } finally {
                console.log('SAVE is done')
                // debugger
                // cb()
              }
            },
            disabled: formState => buttonIsDisabled(formState)
          },
          {
            title: 'Cancel',
            primary: true,
            disabled: formState => buttonIsDisabled(formState),
            async clickEvent(args, formState, cb) {
              const { fields, values } = formState
              const productId = getIn(fields, 'binEditorModal.productId.value')
              const warehouseId = getIn(
                fields,
                'binEditorModal.warehouseId.value'
              )

              const recordLocked = getIn(values, 'binEditorModal.locked')

              if (productId && warehouseId && recordLocked) {
                try {
                  await this.props.unlockInventoryBinAssignment(form, {
                    cb,
                    closeModal: false
                  })
                } finally {
                  console.log('CANCEL is done')
                  // debugger
                  // cb()
                }
              } else {
                cb()
              }
            }
          },
          {
            title: 'Exit',
            primary: true,
            async clickEvent(args, formState, cb) {
              const { fields, values } = formState
              const productId = getIn(fields, 'binEditorModal.productId.value')
              const warehouseId = getIn(
                fields,
                'binEditorModal.warehouseId.value'
              )

              const recordLocked = getIn(values, 'binEditorModal.locked')

              if (productId && warehouseId && recordLocked) {
                try {
                  // this.context._ddiForm.dispatch(
                  //   actions.unlockInventoryBinAssignment.try(form, {
                  //     cb,
                  //     closeModal: true
                  //   })
                  // )
                  await this.props.unlockInventoryBinAssignment(form, {
                    cb,
                    closeModal: true
                  })
                } finally {
                  console.log('CANCEL is done')
                  // debugger
                  // cb()
                }
              } else {
                cb()
              }
            }
          }
        ],
        form
      },
      title: 'Inventory Bin Assignment'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  return modal.payload.id
}

export function* lockInventoryBinAssignmentProcess(
  form,
  dataId,
  warehouseId,
  modalExists = false
) {
  debugger
  yield put(actions.lockInventoryBinAssignment.request(form))

  const { response, error } = yield call(api.lockInventoryBinAssignment, {
    productId: dataId,
    warehouseId
  })

  if (response) {
    yield put(
      actions.lockInventoryBinAssignment.success(
        {
          ...response,
          dataId,
          warehouseId
        },
        form
      )
    )

    if (!modalExists) {
      yield fork(openBinAssignmentModal, form)
    }
  } else {
    yield put(actions.lockInventoryBinAssignment.failure(error, form))
    if (error.validationErrors && error.validationErrors.length) {
      for (let i = 0; i < error.validationErrors.length; i++) {
        if (error.validationErrors[i].property === 'WarehouseID') {
          yield put(setField(form, 'binEditorModal.warehouseId', ''))
          yield put(setField(form, 'binEditorModal.warehouseDescription', ''))
        }
      }
    }
  }
}

export function* lockInventoryBinAssignmentListener(formListener) {
  /* this is only called from right clicking on the grid */
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LOCK_INVENTORY_BIN_ASSIGNMENT.TRY)
    debugger

    if (formListener === form) {
      const formState = yield select(getFormSelector(form))
      const dataId =
        getIn(formState, 'fields.dataId.value') ||
        getIn(formState, 'values.dataId') ||
        ''
      const warehouseId =
        getIn(formState, 'fields.selectedWarehouseId.value') ||
        getIn(formState, 'values.selectedWarehouseId') ||
        ''
      yield fork(
        lockInventoryBinAssignmentProcess,
        form,
        dataId,
        warehouseId,
        false
      )
      // yield fork(openBinAssignmentModal, form)
    }
  }
}

export function* unlockInventoryBinAssignmentProcess(form, cb, closeModal) {
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.binEditorModal.productId.value')
  const warehouseId = getIn(
    formState,
    'fields.binEditorModal.warehouseId.value'
  )

  yield call(confirmationModal, 'All changes will be lost. Continue?', 'Close?')

  const confirmationModalAction = yield take([CONFIRMED, CANCELED])

  if (confirmationModalAction.type === CONFIRMED) {
    yield put(actions.lockInventoryBinAssignment.request(form))

    const { response, error } = yield call(api.unlockInventoryBinAssignment, {
      productId
    })

    if (response) {
      yield put(actions.unlockInventoryBinAssignment.success(response, form))
      if (cb && typeof cb === 'function' && closeModal) {
        cb()
      }
    }
  }
}

export function* unlockInventoryBinAssignmentListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb, closeModal }
    } = yield take(CONSTANTS.UNLOCK_INVENTORY_BIN_ASSIGNMENT.TRY)

    if (formListener === form) {
      yield fork(unlockInventoryBinAssignmentProcess, form, cb, closeModal)
    }
  }
}

export function* saveInventoryBinAssignmentProcess(form, cb) {
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.binEditorModal.productId.value')
  const warehouseId = getIn(
    formState,
    'fields.binEditorModal.warehouseId.value'
  )

  let items = getIn(formState, 'fields.binEditorModal.binAssignments.rowData')
  items = items && items.toJS ? items.toJS() : []

  const { response, error } = yield call(api.saveInventoryBinAssignment, {
    productId,
    warehouseId,
    items
  })

  // debugger

  if (response) {
    yield put(
      actions.saveInventoryBinAssignment.success(
        {
          productId,
          warehouseId,
          rowData: response
        },
        form
      )
    )

    // if (cb) {
    //   cb()
    // }
  } else {
    yield put(actions.saveInventoryBinAssignment.failure(error, form))
  }
}

export function* saveInventoryBinAssignmentListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { cb }
    } = yield take(CONSTANTS.SAVE_INVENTORY_BIN_ASSIGNMENT.TRY)

    if (formListener === form) {
      yield fork(saveInventoryBinAssignmentProcess, form, cb)
    }
  }
}

export function* changeInventoryBinAssignmentProcess(form) {
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.binEditorModal.productId.value')
  const warehouseId = getIn(
    formState,
    'fields.binEditorModal.warehouseId.value'
  )
  let items = getIn(formState, 'fields.binEditorModal.binAssignments.rowData')
  items = items && items.toJS ? items.toJS() : []

  const { response, error } = yield call(api.changeInventoryBinAssignment, {
    productId,
    warehouseId,
    items
  })

  if (response) {
    yield put(actions.changeInventoryBinAssignment.success(response, form))
  } else {
    yield put(actions.changeInventoryBinAssignment.failure(error, form))
  }
}

export function* changeInventoryBinAssignmentListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName },
      type
    } = yield take([DELETE_GRID_ROW, CONSTANTS.SET_PRIMARY_ASSIGNMENT_BIN])

    if (
      (form === formListener &&
        propertyName === 'binEditorModal.binAssignments') ||
      (form === formListener && type === CONSTANTS.SET_PRIMARY_ASSIGNMENT_BIN)
    ) {
      yield fork(changeInventoryBinAssignmentProcess, form)
    }
  }
}

export function* setPrimaryAssignmentBinListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { field, propertyName, rowIndex }
    } = yield take([UPDATE_GRID_CELL_DATA])

    if (
      form === formListener &&
      propertyName === 'binEditorModal.binAssignments' &&
      field === 'isFromBinPrimary'
    ) {
      // debugger
      yield put(actions.setPrimaryAssignmentBin(form, { rowIndex }))
    }
  }
}

export function* variableChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, description, exactMatchResults },
      type
    } = yield take([
      CLEAR_SEARCH,
      FIND_NEXT.SUCCESS,
      FIND_PREV.SUCCESS,
      EXACT_MATCH_SEARCH.SUCCESS
    ])
    // debugger

    /* all this block does is handle setting the description fields */
    if (
      formListener === form &&
      (propertyName === 'binEditorModal.productId' ||
        propertyName === 'binEditorModal.warehouseId')
    ) {
      // yield fork(destroyInitialBinAssignmentRecord, form, propertyName)
      const prop = propertyName.slice(0, -2)
      const label = `${prop}Description`
      // debugger

      if (type === CLEAR_SEARCH) {
        yield put(
          actions.setPropertyValue(form, {
            propertyName: label,
            val: ''
          })
        )
      }

      if (
        type === FIND_NEXT.SUCCESS ||
        (type === FIND_PREV.SUCCESS && description)
      ) {
        yield put(
          actions.setPropertyValue(form, {
            propertyName: label,
            val: description
          })
        )
      }

      if (
        type === EXACT_MATCH_SEARCH.SUCCESS &&
        exactMatchResults &&
        exactMatchResults.description
      ) {
        yield put(
          actions.setPropertyValue(form, {
            propertyName: label,
            val: exactMatchResults.description
          })
        )
      }

      /* finally if we have both of those variables set... */
      const formState = yield select(getFormSelector(form))
      const dataId = getIn(formState, 'fields.binEditorModal.productId.value')
      const warehouseId = getIn(
        formState,
        'fields.binEditorModal.warehouseId.value'
      )
      const recordLocked = getIn(formState, 'values.binEditorModal.locked')

      // debugger

      if (dataId && warehouseId && !recordLocked) {
        debugger
        /* might be an error here */
        yield fork(
          lockInventoryBinAssignmentProcess,
          form,
          dataId,
          warehouseId,
          true
        )
      }
    }
  }
}

export default function* binEditorSagas(form) {
  yield fork(lockInventoryBinAssignmentListener, form)
  yield fork(unlockInventoryBinAssignmentListener, form)
  yield fork(saveInventoryBinAssignmentListener, form)
  yield fork(changeInventoryBinAssignmentListener, form)
  yield fork(setPrimaryAssignmentBinListener, form)
  yield fork(variableChangeListener, form)
}
