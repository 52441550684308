import React from 'react'
import { List } from 'immutable'
import { withContext } from 'ddiForm'
import { headerStyle } from 'pages/VendorMaster/utils'
import { emptyList, getIn, fromJS, isSecureFieldDisabled } from 'utils'
import EditableGrid from 'components/EditableGrid'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'

const propertyName = 'expenseGLIds'

const GLDistributionGrid = ({
  form,
  isEditing,
  liabilityGLIdMetaMap,
  securedFields
}) => {
  const liabilityGLIdMeta = liabilityGLIdMetaMap?.toJS
    ? liabilityGLIdMetaMap.toJS()
    : {}

  const getColumnDefs = props => {
    const params = {
      disabled: !props.isEditing,
      focusedCell: props.focusedCell,
      form: props.form,
      indexSearchType: 'GLAccount',
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    const columnDefs = [
      {
        colId: 'glId',
        field: 'glId',
        headerName: 'G/L Account',
        cellRendererFramework: IndexSearchCell,
        cellRendererParams: {
          ...params,
          allowMultipleSearches: true,
          meta: {
            ...liabilityGLIdMeta,
            exactMatchOnly: true
          },
          isEditing: props.isEditing,
          disabled: isSecureFieldDisabled(
            'expenseGLIds',
            props.isEditing,
            securedFields
          ),
          indexSearchType: 'GLAccount',
          propertyName: props.propertyName,
          requiredFn: data => !data.glId
        }
      },
      {
        colId: 'description',
        field: 'description',
        headerName: 'Description'
      },
      {
        colId: 'delete',
        field: '',
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params,
          propertyName,
          form,
          isEditing: props.isEditing,
          disabledFn: data =>
            isSecureFieldDisabled(
              'expenseGLIds',
              props.isEditing,
              securedFields
            )
        },
        editable: false,
        suppressNavigable: true,
        maxWidth: 40
      }
    ]

    return columnDefs
  }

  return (
    <div style={{ paddingBottom: 20, width: '100%' }}>
      <EditableGrid
        title="Expense G/L"
        headerStyle={headerStyle}
        form={form}
        height={250}
        propertyName="expenseGLIds"
        getRowNodeId={data => data.rowId}
        columnDefs={getColumnDefs}
        emptyRow={{
          glId: null,
          description: ''
        }}
        addButtonText="Add G/L Distribution"
        showAddButtonOnlyIfEditing
        isEditing={isEditing}
        requiredCols={['glId']}
      />
    </div>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  liabilityGLIdMetaMap: form =>
    getIn(form, 'meta.liabilityGLId') ||
    fromJS({
      allowInstantSearch: true,
      allowNavigation: false,
      allowSearchAll: true,
      allowSpecialProducts: false,
      fieldName: 'liabilityGLId',
      hasFilters: false,
      hideToggle: true,
      isSecure: false,
      minimumKeywordLength: 0,
      openText: 'Open G/L Account Master',
      searchType: 'GLAccount'
    }),
  securedFields: form => getIn(form, 'meta.securedFields') || List()
})(GLDistributionGrid)
