import React, { useEffect } from 'react'
import { withContext } from 'ddiForm'
import { useDispatch } from 'react-redux'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import SummaryGrid from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/SummaryGrid'
import { emptyList, getIn, is } from 'utils'

import usePrevious from 'hooks/usePrevious'
import useStateWithCallback from 'hooks/useStateWithCallback'
import {
  saveAgingView as saveAgingViewAction,
  setSummaryExpansionPanelState as setSummaryExpansionPanelStateAction
} from 'pages/VendorMaster/actions'
import VendorSummaryGrid from './VendorSummaryGrid'
import '../styles/summary.scss'

const Summary = ({
  agingList,
  agingTab,
  contactNameAndPhone,
  form,
  lastCheckAmount,
  lastCheckDate,
  lastCheckNumber,
  lyrPayments,
  lyrPurchases,
  openAPBalance,
  openPOBalance,
  ytdPayments,
  ytdPurchases,
  defaultExpanded
}) => {
  const dispatch = useDispatch()
  const agingData = agingList?.toJS ? agingList.toJS() : []
  const [activeSummaryTab, setActiveSummaryTab] = useStateWithCallback(agingTab)
  const prevAgingTab = usePrevious(agingTab)

  useEffect(() => {
    if (prevAgingTab && agingTab && prevAgingTab !== agingTab) {
      setActiveSummaryTab(agingTab)
    }
  }, [agingTab, prevAgingTab])

  const saveAgingView = ({ agingView }) =>
    setActiveSummaryTab(agingView, () => {
      dispatch(
        saveAgingViewAction.try(form, {
          view: agingView
        })
      )
    })

  const setExpansionPanelState = ({ expanded }) =>
    dispatch(setSummaryExpansionPanelStateAction(form, { expanded }))

  return (
    <DDIExpansionPanel
      title="Summary"
      expansionPanelContentStyle={{
        padding: 0,
        width: '100%'
      }}
      changeCallback={setExpansionPanelState}
      defaultExpanded={defaultExpanded}
    >
      <>
        <p className="vendor-ledger-summary-contact-info">
          Contact: <strong>{contactNameAndPhone}</strong>
        </p>
        <div className="vendor-ledger-summary-wrapper">
          <div className="module">
            <VendorSummaryGrid
              title="Balance"
              rowData={[
                { key: 'Open A/P', value: openAPBalance },
                { key: 'Open P/O', value: openPOBalance }
              ]}
            />
            <VendorSummaryGrid
              title="Last"
              rowData={[
                { key: 'Check', value: lastCheckNumber },
                { key: 'Amount', value: lastCheckAmount },
                { key: 'Date', value: lastCheckDate }
              ]}
              contentStyle={{ minHeight: 110, padding: 0 }}
            />
          </div>
          <div className="module">
            <VendorSummaryGrid
              title="Purhcases"
              rowData={[
                { key: 'Year To Date', value: ytdPurchases },
                { key: 'Last Year', value: lyrPurchases }
              ]}
            />
            <VendorSummaryGrid
              title="Payments"
              rowData={[
                { key: 'Year To Date', value: ytdPayments },
                { key: 'Last Year', value: lyrPayments }
              ]}
              contentStyle={{ minHeight: 110, padding: 0 }}
            />
          </div>
          <div className="module">
            <SummaryGrid
              rowData={agingData}
              columnDefs={[
                {
                  field: 'description',
                  headerName: 'Description'
                },
                {
                  field: 'amount',
                  headerName: 'Anount',
                  cellClass: 'align-right',
                  cellStyle: params => ({
                    color: params?.value < 0 ? '#d9534f' : 'auto',
                    textAlign: 'right'
                  }),
                  headerClass: 'align-right'
                },
                {
                  field: 'percent',
                  headerName: 'Percent',
                  cellClass: 'align-right',
                  headerClass: 'align-right'
                }
              ]}
              defaultTab={activeSummaryTab}
              getRowNodeId={data => data.description}
              saveAgingView={saveAgingView}
              hasChart
              style={{
                overflow: 'hidden'
              }}
              title="Aging"
            />
          </div>
        </div>
      </>
    </DDIExpansionPanel>
  )
}

export default withContext({
  agingList: form => getIn(form, 'values.summary.aging') || emptyList,
  agingTab: form => getIn(form, 'values.summary.agingView') || 'Data',
  contactNameAndPhone: form =>
    getIn(form, 'values.summary.contactNameAndPhone') || '',
  lastCheckAmount: form => getIn(form, 'values.summary.lastCheckAmount') || '',
  lastCheckDate: form => getIn(form, 'values.summary.lastCheckDate') || '',
  lastCheckNumber: form => getIn(form, 'values.summary.lastCheckNumber') || '',
  lyrPayments: form => getIn(form, 'values.summary.lyrPayments') || '',
  lyrPurchases: form => getIn(form, 'values.summary.lyrPurchases') || '',
  openAPBalance: form => getIn(form, 'values.summary.openAPBalance') || '',
  openPOBalance: form => getIn(form, 'values.summary.openPOBalance') || '',
  ytdPayments: form => getIn(form, 'values.summary.ytdPayments') || '',
  ytdPurchases: form => getIn(form, 'values.summary.ytdPurchases') || '',
  defaultExpanded: form =>
    is.bool(getIn(form, 'meta.ledgerMeta.summaryExpanded'))
      ? getIn(form, 'meta.ledgerMeta.summaryExpanded')
      : true
})(Summary)
