import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'

const SuccessAlert = id => props => {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      style={{ width: 360, marginTop: 5 }}
      {...props}
    >
      <>{props.children}</>
    </MuiAlert>
  )
}

export default SuccessAlert
