import { call, fork, put, select, take } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { lockForEditAsync } from 'ddiForm/MasterScreen/actions'
import { fromJS, getIn, noop, is } from 'utils'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal } from 'modals/sagas'
import { removeModal } from 'modals/actions'

import * as WARRANTY_TAG_CONSTANTS from 'pages/SalesOrder/common/WarrantyTag/constants'
import * as warrantyTagActions from 'pages/SalesOrder/common/WarrantyTag/actions'
import warrantyTagSOETransaction from 'pages/SalesOrder/common/WarrantyTag/warrantyTagSOETransaction'

import { readLineItemDataProcess } from './commonSagas'

export function* saveWarrantyTagProcess(form, lineNumber, modalId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(warrantyTagActions.saveWarrantyTag.request(form))

  const { response, error } = yield call(warrantyTagSOETransaction, {
    lineNumber,
    guid,
    action: 'update'
  })

  if (response) {
    /* very important: knock out the isEdited flag to ensure ddiForm crushes the form */
    yield put(warrantyTagActions.clearIsEditingFlag(`${form}.warrantyTag`))
    yield put(
      warrantyTagActions.saveWarrantyTag.success(
        {
          ...response,
          lineNumber
        },
        form
      )
    )

    // debugger
    if (modalId) {
      yield put(removeModal(form, modalId))
    }

    const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
    const rowIndex = lineItems?.findIndex(
      x => x.get('lineNumber') === lineNumber
    )

    if (is.number(rowIndex)) {
      /* just refresh the data so we get new comments with the updated link to the Warranty Tag modal */
      yield fork(readLineItemDataProcess, form, rowIndex, noop, true)
    }
  } else {
    yield put(warrantyTagActions.saveWarrantyTag.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* saveWarrantyTagListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, modalId }
    } = yield take(WARRANTY_TAG_CONSTANTS.SAVE_WARRANTY_TAG.TRY)

    if (form === formListener) {
      yield fork(saveWarrantyTagProcess, form, lineNumber, modalId)
    }
  }
}

export function* cancelEditWarrantyTagProcess(
  form,
  lineNumber,
  isNew,
  modalId,
  isInitialInteraction
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield call(
    confirmationModal,
    'All changes will be lost. Continue?',
    'Cancel?'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return
  }

  yield put(warrantyTagActions.cancelEditWarrantyTag.request(form))

  const { response, error } = yield call(warrantyTagSOETransaction, {
    lineNumber,
    guid,
    action: isInitialInteraction ? 'cancelnew' : 'cancel'
  })

  if (response) {
    /* very important: knock out the isEdited flag to ensure ddiForm crushes the form */
    yield put(warrantyTagActions.clearIsEditingFlag(`${form}.warrantyTag`))
    yield put(
      warrantyTagActions.cancelEditWarrantyTag.success(
        {
          ...response,
          lineNumber
        },
        form
      )
    )

    if (modalId) {
      yield put(removeModal(form, modalId))
    }

    if (isInitialInteraction) {
      yield fork(onAfterCancelOrDeleteNewTag, form, lineNumber)
    }
  } else {
    yield put(warrantyTagActions.cancelEditWarrantyTag.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* onAfterCancelOrDeleteNewTag(form, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  const rowIndex = lineItems?.findIndex(x => x.get('lineNumber') === lineNumber)

  /* this function call brings back the Return Auth modal */
  if (is.number(rowIndex)) {
    yield fork(
      readLineItemDataProcess,
      form,
      rowIndex,
      noop,
      true,
      'returnauthorization'
    )
  }
}

export function* cancelEditWarrantyTagListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, isNew, modalId, isInitialInteraction }
    } = yield take(WARRANTY_TAG_CONSTANTS.CANCEL_EDIT_WARRANTY_TAG.TRY)

    if (form === formListener) {
      yield fork(
        cancelEditWarrantyTagProcess,
        form,
        lineNumber,
        isNew,
        modalId,
        isInitialInteraction
      )
    }
  }
}

export function* deleteWarrantyTagProcess(
  form,
  lineNumber,
  warrantyTagId,
  modalId
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isEditing = getIn(formState, 'isEditing')
  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  const rowIndex = lineItems?.findIndex(x => x.get('lineNumber') === lineNumber)

  const confirmationMessage = warrantyTagId
    ? `Are you sure you want to delete Warranty Tag ${warrantyTagId}?`
    : 'Are you sure you want to delete this tag? Continue?'

  yield call(confirmationModal, confirmationMessage, 'Delete?')

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return
  }

  yield put(warrantyTagActions.deleteWarrantyTag.request(form))

  const { response, error } = yield call(warrantyTagSOETransaction, {
    lineNumber,
    guid,
    action: 'delete'
  })

  if (response) {
    /* very important: knock out the isEdited flag to ensure ddiForm crushes the form */
    yield put(warrantyTagActions.clearIsEditingFlag(`${form}.warrantyTag`))
    yield put(
      warrantyTagActions.deleteWarrantyTag.success(
        {
          ...response,
          lineNumber
        },
        form
      )
    )

    // debugger
    if (modalId) {
      yield put(removeModal(form, modalId))
    }

    if (isEditing) {
      // debugger
      yield fork(onAfterCancelOrDeleteNewTag, form, lineNumber)
    } else if (is.number(rowIndex)) {
      /* just refresh the data so we get new comments with the Warranty Tag modal */
      yield fork(readLineItemDataProcess, form, rowIndex, noop, true)
    }
  } else {
    yield put(warrantyTagActions.deleteWarrantyTag.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* deleteWarrantyTagListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, modalId, warrantyTagId }
    } = yield take(WARRANTY_TAG_CONSTANTS.DELETE_WARRANTY_TAG.TRY)

    if (form === formListener) {
      yield fork(
        deleteWarrantyTagProcess,
        form,
        lineNumber,
        warrantyTagId,
        modalId
      )
    }
  }
}

export function* editWarrantyTagProcess(form, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(warrantyTagActions.editWarrantyTag.request(form))

  const { response, error } = yield call(warrantyTagSOETransaction, {
    lineNumber,
    guid,
    action: 'edit'
  })

  if (response) {
    yield put(
      warrantyTagActions.editWarrantyTag.success(
        {
          ...response,
          lineNumber
        },
        form
      )
    )

    yield put(lockForEditAsync.success(response, `${form}.warrantyTag`))
  } else {
    yield put(warrantyTagActions.editWarrantyTag.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* editWarrantyTagListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber }
    } = yield take(WARRANTY_TAG_CONSTANTS.EDIT_WARRANTY_TAG.TRY)

    if (form === formListener) {
      yield fork(editWarrantyTagProcess, form, lineNumber)
    }
  }
}

export function* getPreviousEndUserInfoProcess(form, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  if (!lineNumber) {
    return
  }

  yield put(warrantyTagActions.getPreviousEndUserInfo.request(form))

  const { response, error } = yield call(warrantyTagSOETransaction, {
    action: 'previousenduser',
    guid,
    lineNumber
  })

  if (response) {
    yield put(warrantyTagActions.getPreviousEndUserInfo.success(response, form))

    yield put(
      warrantyTagActions.getPreviousEndUserInfo.success(
        response,
        `${form}.warrantyTag`
      )
    )
  } else {
    yield put(warrantyTagActions.getPreviousEndUserInfo.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* getPreviousEndUserInfoListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber }
    } = yield take(WARRANTY_TAG_CONSTANTS.GET_PREVIOUS_END_USER_INFO.TRY)

    if (form === formListener) {
      yield fork(getPreviousEndUserInfoProcess, form, lineNumber)
    }
  }
}

export default function* warrantyTagSagas(form) {
  yield fork(saveWarrantyTagListener, form)
  yield fork(cancelEditWarrantyTagListener, form)
  yield fork(deleteWarrantyTagListener, form)
  yield fork(editWarrantyTagListener, form)
  yield fork(getPreviousEndUserInfoListener, form)
}
