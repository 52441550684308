/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { Paper, Button, Icon, IconButton } from '@material-ui/core'
import { withContext, Field } from 'ddiForm'
import { DDITextField, DDIToggle, DDIDocumentSearch } from 'ddiForm/wrapped'

import DDICardWrapper from 'components/DDICardWrapper'
import masterStyles from 'theme/masterStyles'
import {
  layoutFlex,
  getField,
  getMeta,
  emptyList,
  getValue,
  getIn
} from 'utils'
import {
  getRowNodeId,
  isValidURL,
  setGridHeight
} from 'pages/ProductMaster/utils'
import { openWebDescriptionEditor } from 'pages/ProductMaster/actions'
import { MasterDetailGridDropdown, SearchDropdown } from 'components'
/* Editable Grid Stuffs */
import EditableGrid from 'components/EditableGrid'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
// import DocumentSearch from 'components/Search/DocumentSearch'

const eCommerceBranchOptionsColDefs = [
  { field: 'dataId', headerName: 'Code' },
  { field: 'description', headerName: 'Description' }
]

export class WebOptions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    eCommerceBranchOptions: PropTypes.array.isRequired,
    form: PropTypes.string.isRequired,
    getAllCategories: PropTypes.array.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    publishBranches: PropTypes.string.isRequired,
    webCategoryRecordNames: PropTypes.string.isRequired,
    webDocuments: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      pictures: []
    }
  }

  getColumnDefs = props => {
    const params = {
      disabled: !props.isEditing,
      focusedCell: props.focusedCell,
      form: props.form,
      indexSearchType: 'vendor',
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    let colDefs = [
      {
        field: 'dataId',
        headerName: 'Document',
        minWidth: 150,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          isRequired: true
        }
      },
      {
        field: 'path',
        headerName: 'Path',
        minWidth: 150,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          isRequired: true
        }
      },
      {
        field: 'isURL',
        headerName: 'is URL',
        minWidth: 80,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params
        },
        cellClass: 'text-center align-center',
        headerClass: 'text-center align-center',
        width: 80,
        maxWidth: 80
      }
    ]

    if (props.isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'delete',
          headerName: '',
          cellRendererFramework: DeleteGridRowCell,
          cellRendererParams: {
            ...params
          },
          editable: false,
          width: 50,
          maxWidth: 50
        }
      ]
    }

    return colDefs
  }

  launchWebDescriptionEditor = () => {
    const { dispatch, form } = this.props
    // console.log('launchWebOpts', this.props)
    dispatch(openWebDescriptionEditor(form))
  }

  onDrop = (pictureFiles, pictureDataURLs, inputId) => {
    console.log('onDrop', pictureFiles, pictureDataURLs, inputId)

    this.setState({
      [inputId]: pictureFiles.name,
      [`${inputId}Preview`]:
        pictureDataURLs && Array.isArray(pictureDataURLs)
          ? pictureDataURLs[0]
          : ''
    })
  }

  getExternalWindowLink = memoize((url, isEditing) => {
    if (url && isValidURL(url) && !isEditing) {
      return (
        <IconButton
          style={{
            bottom: 0,
            padding: 5,
            position: 'absolute',
            right: -25
          }}
          onClick={() => window.open(url, 'external')}
        >
          <Icon style={{ fontSize: 16 }}>open_in_new</Icon>
        </IconButton>
      )
    }

    return null
  })

  // setPublishBranches = values => {
  //   console.log('setPublishBranches', values)
  // }

  render() {
    const {
      disableUPSShipping,
      eCommerceBranchOptions,
      publishBranches,
      hasRecord,
      isEditing,
      webDocuments,
      webCategoryRecordNames,
      getAllCategories,
      imageURL,
      documentSpecsURL,
      msdSheetURL,
      dataId
    } = this.props
    const { imagePreview, image } = this.state

    const editButtonDisabled = !(hasRecord && isEditing)

    const docSearchLabelStyle = {
      color: isEditing ? '#444' : '#777',
      fontSize: 12,
      fontWeight: 400,
      minWidth: 150,
      margin: '0 10px 0 0',
      textAlign: 'right'
    }

    const docSearchWrapperStyle = {
      alignItems: 'center',
      display: 'flex',
      margin: '10px 0'
    }

    return (
      <div style={layoutFlex('100rem')}>
        <div style={masterStyles.twoColFlex.wrapper}>
          <div style={masterStyles.twoColFlex.left}>
            <Paper
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-around',
                margin: '0 0 10px 0',
                maxWidth: '100%',
                width: '100%'
              }}
            >
              <DDIToggle
                propertyName="webPublishFlag"
                label="Web Publish"
                labelPlacement="start"
                wrapperStyle={{ clear: 'none', position: 'static' }}
              />
              <DDIToggle
                propertyName="caProposition65"
                label="CA Proposition 65"
                labelPlacement="start"
                wrapperStyle={{ clear: 'none', position: 'static' }}
              />
            </Paper>

            <DDICardWrapper
              title="Branch & Category Options"
              contentStyle={{ padding: 10 }}
            >
              <Field
                component={SearchDropdown}
                propertyName="publishBranches"
                floatingLabelText="Publish By Branch"
                identifier="dataId"
                initialValue={publishBranches}
                rowData={eCommerceBranchOptions.toJS()}
                gridOptions={{
                  columnDefs: eCommerceBranchOptionsColDefs,
                  className: 'ag-theme-balham secondary-grid',
                  enableSorting: true,
                  getRowNodeId: data => data.dataId,
                  height: 200
                }}
              />
              <MasterDetailGridDropdown
                label="Web Category"
                propertyName="webCategoryRecordNames"
                form={this.props.form}
                hasRecord={hasRecord}
                isEditing={isEditing}
                rowData={getAllCategories.toJS()}
                value={webCategoryRecordNames}
                // expandOnLoad
              />
              <DDITextField
                label="Max Web Order Qty"
                mask="numeric"
                propertyName="maxWebOrderQuantity"
                inputProps={{
                  decimalScale: 2,
                  fixedDecimalScale: true
                }}
              />
            </DDICardWrapper>

            <DDICardWrapper title="Linked Files" contentStyle={{ padding: 10 }}>
              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>Image:</label>
                <DDIDocumentSearch
                  propertyName="imageFileName"
                  form={this.props.form}
                  documentType="image"
                  width={250}
                  minWidth={250}
                  meta={{
                    allowDownload: true,
                    allowInstantSearch: true,
                    allowSearchAll: true,
                    hasUpload: true,
                    minimumKeywordLength: 2
                  }}
                />
              </div>
              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>Image URL:</label>
                <div style={{ minWidth: 250, position: 'relative' }}>
                  <DDITextField propertyName="imageURL" />
                  {this.getExternalWindowLink(imageURL, isEditing)}
                </div>
              </div>

              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>Document/Spec:</label>
                <DDIDocumentSearch
                  propertyName="documentSpecsFilePath"
                  form={this.props.form}
                  documentType="documentspecs"
                  width={250}
                  minWidth={250}
                  meta={{
                    allowDownload: true,
                    allowInstantSearch: true,
                    allowSearchAll: true,
                    hasUpload: true,
                    minimumKeywordLength: 2
                  }}
                />
              </div>

              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>Document / Spec URL:</label>
                <div style={{ minWidth: 250, position: 'relative' }}>
                  <DDITextField propertyName="documentSpecsURL" />
                  {this.getExternalWindowLink(documentSpecsURL, isEditing)}
                </div>
              </div>

              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>S.D. Sheet:</label>
                <DDIDocumentSearch
                  propertyName="msdSheetFilePath"
                  form={this.props.form}
                  documentType="msds"
                  width={250}
                  minWidth={250}
                  meta={{
                    allowDownload: true,
                    allowInstantSearch: true,
                    allowSearchAll: true,
                    hasUpload: true,
                    minimumKeywordLength: 2
                  }}
                />
              </div>

              <div style={docSearchWrapperStyle}>
                <label style={docSearchLabelStyle}>S.D. Sheet URL:</label>
                <div style={{ minWidth: 250, position: 'relative' }}>
                  <DDITextField propertyName="msdSheetURL" />
                  {this.getExternalWindowLink(msdSheetURL, isEditing)}
                </div>
              </div>
            </DDICardWrapper>

            <DDICardWrapper title="UPS" contentStyle={{ padding: 10 }}>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <DDIToggle
                  propertyName="disableUPSShipping"
                  label="Disable UPS Shipping"
                  labelPlacement="start"
                  wrapperStyle={{ clear: 'none', position: 'static' }}
                />

                <DDITextField
                  disabled={disableUPSShipping}
                  label="Flat Rate"
                  propertyName="upsFlatRate"
                  mask="numeric"
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true

                    // allowDecimal: true,
                    // decimalLimit: 2,
                    // requireDecimal: true
                  }}
                />
              </div>
            </DDICardWrapper>
          </div>
          <div style={masterStyles.twoColFlex.right}>
            <DDICardWrapper
              title="Web Description"
              contentStyle={{ padding: 10 }}
            >
              <div style={{ marginBottom: 15 }}>
                <DDITextField
                  fullWidth
                  propertyName="webDescription"
                  name="webDescription"
                  id="webDescription"
                  multiline
                  minWidth="100%"
                  rows={4}
                  style={{ width: '100%' }}
                  variant="outlined"
                />
              </div>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="contained"
                  onClick={this.launchWebDescriptionEditor}
                  disabled={editButtonDisabled}
                >
                  Edit as HTML
                </Button>
              </div>
            </DDICardWrapper>
            <DDICardWrapper
              title="Web Documents"
              contentStyle={{ minHeight: 150, padding: 0 }}
            >
              {webDocuments && webDocuments?.toJS ? (
                <EditableGrid
                  addButtonText="Add Web Document"
                  gridWrapperStyle={{
                    flex: '1 1',
                    overflow: 'visible',
                    maxWidth: '100%',
                    width: '100%'
                  }}
                  emptyRow={{
                    dataId: null,
                    path: '',
                    isURL: false
                  }}
                  requiredCols={['dataId', 'path']}
                  getRowNodeId={getRowNodeId}
                  propertyName="webDocuments"
                  form={this.props.form}
                  columnDefs={this.getColumnDefs}
                  height={setGridHeight(webDocuments.toJS(), 35)}
                  isEditing={isEditing}
                  width="100%"
                  suppressHorizontalScroll
                />
              ) : null}
            </DDICardWrapper>
          </div>
        </div>
      </div>
    )
  }
}

export const getWebDocuments = form => {
  if (getIn(form, 'fields.webDocuments.rowData')) {
    return getIn(form, 'fields.webDocuments.rowData')
  }

  if (getIn(form, 'values.webDocuments')) {
    return getIn(form, 'values.webDocuments')
  }

  return emptyList
}

export const getWebCategoryRecordNames = form => {
  if (getIn(form, 'fields.webCategoryRecordNames.value')) {
    return getIn(form, 'fields.webCategoryRecordNames.value')
  }
  if (getIn(form, 'values.webCategoryRecordNames')) {
    return getIn(form, 'values.webCategoryRecordNames')
  }
  if (getIn(form, 'values.setup.webCategoryRecordNames')) {
    return getIn(form, 'values.setup.webCategoryRecordNames')
  }
  return ''
}

export const contextObj = {
  dataId: form => getField(form, 'dataId', ''),
  imageURL: form => getField(form, 'imageURL', ''),
  documentSpecsURL: form => getField(form, 'documentSpecsURL', ''),
  msdSheetURL: form => getField(form, 'msdSheetURL', ''),
  disableUPSShipping: form => getField(form, 'disableUPSShipping', false),
  eCommerceBranchOptions: form =>
    getMeta(form, 'eCommerceBranchOptions', emptyList),
  getAllCategories: form => getMeta(form, 'getAllCategories', emptyList),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  publishBranches: form => getValue(form, 'publishBranches', ''),
  webCategoryRecordNames: form => getWebCategoryRecordNames(form),
  webDocuments: form => getWebDocuments(form)
}

export default withContext(contextObj)(WebOptions)
