import { call, fork, put, putResolve, select, take } from 'redux-saga/effects'
import { Set } from 'immutable'
import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import { addModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { REMOVE_MODAL, CONFIRMED, CANCELED } from 'modals/constants'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, is } from 'utils'
import InvoiceHistoryModal from 'pages/SalesOrder/components/InvoiceHistoryModal'
import ReturnAuthorizationModal from 'pages/SalesOrder/components/ReturnAuthorizationModal'
import addWarrantyTagInModal from 'pages/SalesOrder/common/addWarrantyTagInModal'
import {
  readLineItemDataProcess,
  triggerReturnProductValidationRoutine
} from './commonSagas'

const getSelectedRowDataIndex = (rowData = [], selectedRows = []) =>
  rowData.findIndex(x => x.dataId === selectedRows[0].dataId)

export function* returnProductProcess(form, meta, value, data, node) {
  const prevValue = data.quantityOrdered

  yield put({
    meta,
    type: 'TRY_CELL_CHANGED_SUCCESS',
    payload: value
  })

  const action = yield take([
    CONSTANTS.UPDATE_GRID_ITEM.SUCCESS,
    CONSTANTS.UPDATE_GRID_ITEM.FAILURE
  ])

  if (action.type === CONSTANTS.UPDATE_GRID_ITEM.SUCCESS) {
    if (action?.payload?.record?.detail?.lineItems) {
      const row = action.payload.record.detail.lineItems.find(
        x => x.lineNumber === data.lineNumber
      )
      if (row.invoiceHistory || row.returnAuthorization) {
        const formState = yield select(getFormSelector(form))
        const lineItems = getIn(formState, 'fields.lineItems.rowData')
        const item = lineItems.filter(x => {
          return x.get('lineNumber') === row.lineNumber
        })

        row.rowId = item && item.first().get('rowId')
        const { uniqueKey, ...rest } = row
        yield fork(handleReturnProductModals, form, rest)
      }
    }
  } else if (node) {
    /* mainly for Access Override cancellation */
    const res = yield putResolve({
      meta: { form, thunk: true, reducer: 'Grid' },
      payload: {
        data,
        field: 'quantityOrdered',
        propertyName: 'lineItems',
        rowIndex: node.childIndex,
        value: prevValue,
        gridApi: node.gridApi,
        skipBoxQuantityRoutine: true
      },
      type: 'TRY_CELL_CHANGED_REQUEST'
    })

    node.setDataValue('quantityOrdered', res)
  }
}

export function* handleReturnProductModals(form, data) {
  if (data.invoiceHistory) {
    yield fork(launchInvoiceHistoryModal, form, data)
    return
  }

  if (data.returnAuthorization) {
    yield fork(launchReturnAuthorizationModal, form, data)
  }
}

export function* returnModalTitle(form, lineNumber, prefix) {
  const formState = yield select(getFormSelector(form))
  const lineItems = getIn(formState, 'fields.lineItems.rowData')
  const rowIndex =
    lineNumber && lineItems && lineItems.findIndex
      ? lineItems.findIndex(x => x.get('lineNumber') === lineNumber)
      : 0
  const dataId =
    lineItems && lineItems.get && lineItems.get(rowIndex)
      ? lineItems.get(rowIndex).get('dataId')
      : ''
  // const description = lineItems && lineItems.get && lineItems.get(rowIndex) ? lineItems.get(rowIndex).get('description') : ''

  if (dataId) {
    return `${prefix} - Product ID: ${dataId}`
  }

  return `${prefix}`
}

export function* launchInvoiceHistoryModal(form, data) {
  const modalTitle = yield call(
    returnModalTitle,
    form,
    data.lineNumber,
    'Product & Customer Invoice History'
  )

  const modalOpts = {
    component: InvoiceHistoryModal,
    options: {
      maxHeight: '100%',
      // title: 'Product & Customer Invoice History',
      title: modalTitle,
      data: {
        form,
        ...data,
        actions: [
          {
            primary: true,
            title: 'Select',
            async clickEvent(args, fs, cb) {
              const value = getSelectedRowDataIndex(
                this.state.rowData,
                this.gridApi.getSelectedRows()
              )

              if (value > -1) {
                try {
                  await this.props.dispatch(
                    actions.triggerUpdateLineItem(form, {
                      propertyName: 'lineItems',
                      data,
                      colDef: {
                        field: 'returnInvoiceId'
                      },
                      value
                    })
                  )
                } catch (e) {
                  console.log(e)
                } finally {
                  this.props.dispatch(
                    actions.clearReturnModalData(form, {
                      lineNumber: data.lineNumber
                    })
                  )
                  if (cb && typeof cb === 'function') {
                    cb()
                  }
                }
              }
            },
            disabled: formState => {
              // console.log('buttonIsDisabled', this, formState)
              const { fields, values } = formState
              if (
                values.selectedInvoiceHistory ||
                values.selectedInvoiceHistory === 0
              ) {
                return false
              }

              return true
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.triggerUpdateLineItem(form, {
                    propertyName: 'lineItems',
                    data,
                    colDef: {
                      field: 'cancelreturn'
                    },
                    value: null,
                    cb
                  })
                )
              } catch (e) {
                console.log(e)
              } finally {
                console.log('cannot do this here b/c of cancellation')
                /* 
                this action had to be moved to the success condition
                of updateLineItemProcess for cancelreturn
                due to Access Override -- SVE 1/28/2020
              */

                //
                // this.props.dispatch(
                //   actions.clearReturnModalData(form, {
                //     lineNumber: data.lineNumber
                //   })
                // )
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  /*
    wait for the interaction to complete 
  */
  const action = yield take(CONSTANTS.UPDATE_GRID_ITEM.SUCCESS)

  if (
    action?.payload?.propertyChanged &&
    action?.payload?.propertyChanged === 'lineItems' &&
    action?.payload?.fieldChanged &&
    data?.returnAuthorization &&
    (action?.payload?.fieldChanged === 'returnInvoiceId' ||
      (action?.payload?.fieldChanged === 'cancelreturn' &&
        !action?.payload?.changedValuePosted))
  ) {
    yield fork(launchReturnAuthorizationModal, form, data)
  }

  return modal.payload.id
}

export function* launchReturnAuthorizationModal(form, data) {
  // const formState = yield select(getFormSelector(form))
  const modalTitle = yield call(
    returnModalTitle,
    form,
    data.lineNumber,
    'Enter Return Action'
  )

  const modalOpts = {
    component: ReturnAuthorizationModal,
    options: {
      maxHeight: '100%',
      width: 500,
      title: modalTitle,
      // title: 'Enter Return Action',
      data: {
        form,
        ...data,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              const rgaType = this?.state?.rgaType
              const originalRgaType = this?.props?.data?.returnAuthorization
                ?.rgaType

              if (rgaType && this?.state) {
                const additionalProperties = this?.state
                  ?.allowReturnToShowroomDisplay
                  ? {
                      applyRestockingCharge: this.state.applyRestockingCharge,
                      returnToShowroomDisplay: this.state
                        .returnToShowroomDisplay
                    }
                  : {
                      applyRestockingCharge: this.state.applyRestockingCharge
                    }

                try {
                  await this.props.dispatch(
                    actions.triggerUpdateLineItem(form, {
                      propertyName: 'lineItems',
                      data,
                      colDef: {
                        field: 'rgaType'
                      },
                      value: rgaType,
                      additionalProperties
                    })
                  )
                  cb()
                } catch (e) {
                  if (cb && typeof cb === 'function') {
                    cb()
                  }
                } finally {
                  const isChange = rgaType !== originalRgaType
                  // debugger
                  this.props.dispatch(
                    actions.returnAuthModalClosed(form, {
                      data,
                      rgaType,
                      isChange
                    })
                  )
                  // debugger
                }
              }
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* launchWarrantyTagModalProcess(
  form,
  rgaType,
  data,
  isInitialInteraction
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const { lineNumber } = data

  yield put(
    addWarrantyTagInModal(form, lineNumber, guid, null, isInitialInteraction)
  )
}

export function* returnAuthModalClosedListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rgaType, data, isChange }
    } = yield take(CONSTANTS.RETURN_AUTH_MODAL_CLOSED)

    if (form === formListener && rgaType && data) {
      if (rgaType === 'W' && isChange) {
        yield fork(launchWarrantyTagModalProcess, form, rgaType, data, true)
      }
    }
  }
}

export function* confirmReturnCancellationModal(form, parentModalCb, rowData) {
  // const formState = yield select(getFormSelector(form))
  // console.log(form)

  yield call(
    confirmationModal,
    'OK to re-enter or Cancel to cancel return',
    'Return Invoice Required'
  )

  const modalAction = yield take([CONFIRMED, CANCELED])

  if (
    modalAction.type === CANCELED &&
    parentModalCb &&
    typeof parentModalCb === 'function'
  ) {
    parentModalCb()

    yield put(
      actions.triggerUpdateLineItem(form, {
        propertyName: 'lineItems',
        data: rowData,
        colDef: {
          field: 'cancelreturn'
        },
        value: true
      })
    )
  }
}

export function* getReturnModalDataListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { rowIndex, gridApi, isRequest, launchModal, type, data = {} }
    } = yield take(CONSTANTS.GET_RETURN_MODAL_DATA)

    if (form === formListener) {
      if (isRequest) {
        yield fork(readLineItemDataProcess, form, rowIndex, gridApi, true, type)
      } else if (Object.keys(data).length && launchModal) {
        if (type === 'returnauthorization') {
          yield fork(launchReturnAuthorizationModal, form, data)
        }

        if (type === 'invoicehistory') {
          yield fork(launchInvoiceHistoryModal, form, data)
        }

        /* 
          handle both the modals -- this happens after 
          changing the customerId in the search area -- SVE 1/14/2020 
        */
        if (type === 'returninvoiceauthorization') {
          yield fork(handleReturnProductModals, form, data)
        }
      }
    }
  }
}

export function* triggerReturnProductValidationListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { gridApi }
    } = yield take(CONSTANTS.TRIGGER_RETURN_PRODUCT_VALIDATION)
    /* 
      note that this action is kicked off when the customer changes
      and there are lineItems with quantityOrdered less than 1. See
      the dispatch of the triggerReturnProductValidation action in
      tabs/Order/index.js -- just figured I would spell out what this
      is for. -- SVE 1/18/2020 
    */

    if (form === formListener) {
      /* 
        one of these actions ensures that any of the modals
        that come along with changing the customer have been
        dismissed -- SVE - 1/14/2020
      */
      const modalRoutineCompleted = yield take([
        CONSTANTS.MODAL_ROUTINE_COMPLETED,
        CONSTANTS.SET_SALES_ORDER_INITIALIZED
      ])

      if (modalRoutineCompleted.meta.form === formListener && gridApi) {
        yield fork(triggerReturnProductValidationRoutine, form, gridApi, true)
      }
    }
  }
}

export default function* returnOrderSagas(form) {
  yield fork(getReturnModalDataListener, form)
  yield fork(triggerReturnProductValidationListener, form)
  yield fork(returnAuthModalClosedListener, form)
}
