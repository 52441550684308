/* eslint react/sort-comp: 0, react/no-unused-state: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Input,
  InputAdornment,
  ButtonBase,
  Icon,
  IconButton
} from '@material-ui/core'

class UploadCSVModal extends Component {
  static propTypes = {
    allowedFileTypes: PropTypes.array
  }

  static defaultProps = {
    allowedFileTypes: ['csv']
  }

  constructor(props) {
    super(props)

    this.state = {
      bulkProperties: [],
      fileName: ''
    }
  }

  onOpenFileBrowserHandler = e => (e.target.value = null)

  openFileBrowser = e => {
    this.inputEl.click()
  }

  handleFileUploadProcess = files => {
    const { allowedFileTypes } = this.props

    for (let i = 0; i < 1; i++) {
      const file = files[i]
      const fileName = file.name
      const extension = file.name.split('.').pop()

      if (!allowedFileTypes.includes(extension)) {
        this.setState({
          bulkProperties: [],
          fileName: '',
          errorMessage: 'Incorrect File Type'
        })

        break
      }

      const reader = new FileReader()

      reader.onload = e => {
        const csv = reader.result
        const lines = csv.split('\n')

        const bulkProperties = lines.reduce((acc, next) => {
          const data = next.split(',')
          if (
            data &&
            data.length === 3 &&
            !Number.isNaN(Number(data[0])) &&
            !Number.isNaN(Number(data[2]))
          ) {
            acc = acc.concat({
              dataId: data[1],
              quantityOrdered: Number(data[0]),
              netPrice: Number(data[2])
            })
          }

          return acc
        }, [])

        if (bulkProperties.length) {
          this.setState({ bulkProperties, fileName, errorMessage: '' })
        } else {
          this.setState({
            bulkProperties: [],
            fileName: '',
            errorMessage: 'Please upload a valid CSV file'
          })
        }
      }

      reader.readAsText(file)
    }
  }

  // readFile = file =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader()
  //     resolve({ data: reader.readAsText(file) })
  //   })

  onSelectFileUpload = e => {
    const { files } = e.target
    this.handleFileUploadProcess(files)
  }

  handleDrop = e => {
    e.preventDefault()
    const files = []

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile()
          const { type } = e.dataTransfer.items[i]
          files.push(file)
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        files.push(e.dataTransfer.files[i])
      }
    }

    /* only allow the user to drag and drop one file at a time */
    if (files.length && files.length === 1) {
      this.handleFileUploadProcess(files)
    }
  }

  onDragOver = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  onDragEnter = e => {
    e.stopPropagation()
  }

  render() {
    const { errorMessage, fileName } = this.state

    return (
      <div style={{ width: '100%' }}>
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <div
            onDrop={this.handleDrop}
            onDragOver={this.onDragOver}
            onDragEnter={this.onDragEnter}
          >
            <Input
              startAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={this.openFileBrowser} tabIndex={-1}>
                    <Icon>folder</Icon>
                  </IconButton>
                </InputAdornment>
              }
              ref={el => (this.inputEl = el)}
              placeholder="Choose any CSV file"
              onChange={this.handleFileSelect}
              value={fileName}
            />
          </div>
          <div style={{ display: 'none' }}>
            <input
              type="file"
              ref={el => (this.inputEl = el)}
              onChange={this.onSelectFileUpload}
              onClick={this.onOpenFileBrowserHandler}
            />
          </div>
        </div>
        <p
          style={{
            margin: 5,
            fontSize: 11,
            fontStyle: 'italic',
            textAlign: 'center'
          }}
        >
          Drag and drop or upload your CSV file here
        </p>

        {errorMessage ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <div
              className="alert alert-warning"
              style={{
                backgroundColor: '#fcf8e3',
                borderColor: '#faebcc',
                padding: 5,
                margin: '10px 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon size="small" style={{ marginRight: 5 }}>
                warning
              </Icon>
              <span>{errorMessage}</span>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(UploadCSVModal)
