const masterStyles = {
  searchRow: {
    description: {
      flex: 1,
      order: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '33%'
    },
    input: { flex: 1, marginRight: 10, maxWidth: 150, order: 2, width: '33%' },
    label: {
      flex: 'none',
      fontSize: '1rem',
      fontWeight: 400,
      marginBottom: 0,
      marginRight: 10,
      order: 1,
      textAlign: 'right',
      width: '33%'
    },
    toggle: { flex: 1, order: 3, width: '33%' },
    wrapper: { alignItems: 'center', display: 'flex', width: '100%' }
  },
  twoColToggleFlex: {
    wrapper: { alignItems: 'center', display: 'flex', flexWrap: 'wrap' },
    left: { flex: 1, margin: '0 5px 5px 0', order: 1 },
    right: { flex: 1, margin: '0 0 5px 5px', order: 2 }
  },
  threeColFlex: {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
      padding: '0 5px',
      width: '100%'
    },
    left: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 1 },
    center: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 2 },
    right: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 3 }
  },
  twoColFlex: {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
      padding: '0 5px',
      width: '100%'
    },
    left: {
      flexBasis: '50%',
      flexGrow: 1,
      maxWidth: '100%',
      padding: '0 5px',
      order: 1
    },
    right: {
      flexBasis: '50%',
      flexGrow: 1,
      maxWidth: '100%',
      padding: '0 5px',
      order: 2
    }
  },
  twoColInputsFlex: {
    wrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: '100%',
      padding: 0,
      width: '100%'
    },
    left: {
      flexBasis: '50%',
      flexGrow: 1,
      maxWidth: '100%',
      padding: '0 10px',
      order: 1
    },
    right: {
      flexBasis: '50%',
      flexGrow: 1,
      maxWidth: '100%',
      padding: '0 10px',
      order: 2
    }
  }
}

export default masterStyles
