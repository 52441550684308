import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, Label } from 'ddiForm'
import { connect } from 'react-redux'
import {
  DDITextField,
  DDIIndexSearch,
  DDIToggle,
  DDISelectField
} from 'ddiForm/wrapped'
import { Paper, FormControlLabel } from '@material-ui/core'
import { required, maxLength } from 'ddiForm/validations'
import { getIn, isUsaCanadaOrBlank } from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import listOfStates from 'pages/CustomerMaster/data'
import { validateSearchField } from 'pages/SalesOrder/utils'

import '../styles/fast-customer-modal.scss'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const autoGenerateId =
    getIn(state, `ddiForm.${form}.fields.autoGenerateId.value`) || false
  const isLocked =
    getIn(state, `ddiForm.${form}.values.fastCustomer.isLocked`) || false
  const description = getIn(
    state,
    `ddiForm.${form}.fields.fastCustomer.description.value`
  )
  const addressLine1 =
    getIn(state, `ddiForm.${form}.fields.fastCustomer.addressLine1.value`) || ''
  const country =
    getIn(state, `ddiForm.${form}.fields.fastCustomer.country.value`) || ''
  // determines whether the record is locked and editable

  return {
    addressLine1,
    autoGenerateId,
    country,
    isLocked,
    description
  }
}

export class FastCustomerModal extends Component {
  componentDidMount() {
    if (this.numberField) {
      this.numberField.focus()
    }
  }

  render() {
    const {
      autoGenerateId,
      data: { custCategory = {} },
      isLocked,
      country
    } = this.props

    return (
      <div className="fast-customer-modal-wrapper">
        <div className="two-col-flex-wrapper">
          <div className="two-col-flex-left">
            <DDITextField
              label="Customer Number"
              preventAutoDisable
              InputProps={{
                inputProps: {
                  maxLength: 7
                }
              }}
              inputRef={c => (this.numberField = c)}
              propertyName="fastCustomer.dataId"
              disabled={!!autoGenerateId || isLocked}
            />
          </div>

          <div className="two-col-flex-right">
            <div className="associated-fields-wrapper">
              <DDIToggle
                propertyName="fastCustomer.isProspect"
                label="Prospect"
                preventAutoDisable
              />
            </div>
          </div>
        </div>

        <Paper
          style={{
            margin: '10px 0',
            padding: 10
          }}
        >
          <h2 className="heading">Customer Address & Contact Info</h2>
          <div className="division">
            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="fastCustomer.description"
                  label="Name"
                  disabled={!isLocked}
                  preventAutoDisable
                  validate={[required]}
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="fastCustomer.addressLine1"
                  label="Address"
                  disabled={!isLocked}
                  preventAutoDisable
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="fastCustomer.addressLine2"
                  label="Address Line 2"
                  disabled={!isLocked}
                  preventAutoDisable
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="fastCustomer.city"
                  label="City"
                  disabled={!isLocked}
                  preventAutoDisable
                  inputProps={{
                    maxLength: isUsaCanadaOrBlank(country) ? 20 : 30
                  }}
                  validate={maxLength(isUsaCanadaOrBlank(country) ? 20 : 30)}
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <div
                  className="two-col-flex-wrapper"
                  style={{ marginBottom: 0 }}
                >
                  <div
                    className="two-col-flex-left"
                    style={{ marginLeft: '-10px' }}
                  >
                    <DDISelectField
                      propertyName="fastCustomer.state"
                      label="State"
                      fullWidth
                      values={listOfStates}
                      selectValue="value"
                      selectDescription="primaryText"
                      disabled={!isLocked}
                      preventAutoDisable
                    />
                  </div>

                  <div
                    className="two-col-flex-right"
                    style={{ marginRight: '-10px' }}
                  >
                    <DDITextField
                      propertyName="fastCustomer.zipcode"
                      label="Zip Code"
                      mask="zipCode"
                      inputProps={{
                        guide: false
                      }}
                      // disableMask={isCanadianCompany || !isUsa}
                      disabled={!isLocked}
                      preventAutoDisable
                    />
                  </div>
                </div>
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="fastCustomer.country"
                  label="Country"
                  disabled={!isLocked}
                  preventAutoDisable
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="fastCustomer.arcontactName"
                  label="Contact"
                  disabled={!isLocked}
                  preventAutoDisable
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="fastCustomer.email"
                  label="Email"
                  disabled={!isLocked}
                  preventAutoDisable
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="fastCustomer.arphone"
                  label="Phone"
                  disabled={!isLocked}
                  mask="phoneNumber"
                  preventAutoDisable
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="fastCustomer.fax"
                  label="Fax"
                  disabled={!isLocked}
                  fullWidth
                  mask="phoneNumber"
                  preventAutoDisable
                />
              </div>
            </div>
          </div>
        </Paper>

        <Paper
          style={{
            margin: '10px 0',
            padding: 10
          }}
        >
          <h2 className="heading">General Configuration</h2>

          <div className="search-row-wrapper">
            <div className="search-row-label">Salesman</div>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastCustomer.salesmanId"
                metaKey="Salesman"
                disabled={!isLocked}
                preventAutoDisable
                validate={[validateSearchField]}
              />
            </div>
            <div className="search-row-description">
              <Field
                propertyName="fastCustomer.salesmanDescription"
                component={Label}
              />
            </div>
          </div>

          <div className="search-row-wrapper">
            <div className="search-row-label">Terms</div>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastCustomer.arTermsId"
                metaKey="Terms"
                disabled={!isLocked}
                preventAutoDisable
                validate={[validateSearchField]}
              />
            </div>
            <div className="search-row-description">
              <Field
                propertyName="fastCustomer.arTermsDescription"
                component={Label}
              />
            </div>
          </div>

          <div className="search-row-wrapper">
            <div className="search-row-label">Category</div>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastCustomer.categoryId"
                metaKey="Category"
                disabled={!isLocked}
                preventAutoDisable
                meta={custCategory}
                validate={[validateSearchField]}
              />
            </div>
            <div className="search-row-description">
              <Field
                propertyName="fastCustomer.categoryDescription"
                component={Label}
              />
            </div>
          </div>

          <div className="search-row-wrapper">
            <div className="search-row-label">Tax</div>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastCustomer.taxId"
                metaKey="Tax Code"
                disabled={!isLocked}
                preventAutoDisable
                validate={[validateSearchField]}
              />
            </div>
            <div className="search-row-description">
              <Field
                propertyName="fastCustomer.taxDescription"
                component={Label}
              />
            </div>
          </div>

          <div className="search-row-wrapper">
            <div className="search-row-label">Sort Code</div>
            <div className="search-row-input">
              <DDITextField
                propertyName="fastCustomer.sortCode"
                disabled={!isLocked}
                preventAutoDisable
              />
            </div>
          </div>

          <div className="search-row-wrapper">
            <div className="search-row-label">Branch</div>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastCustomer.branchId"
                metaKey="Branch"
                disabled={!isLocked}
                preventAutoDisable
                validate={[validateSearchField]}
              />
            </div>
            <div className="search-row-description">
              <Field
                propertyName="fastCustomer.branchDescription"
                component={Label}
              />
            </div>
          </div>
        </Paper>

        <h2 className="footer-heading">Internal Notes</h2>
        <DDITextField
          propertyName="fastCustomer.internalNotes"
          multiline
          rows={5}
          disabled={!isLocked}
          variant="outlined"
          preventAutoDisable
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(FastCustomerModal)
