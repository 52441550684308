import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { headerStyle } from 'pages/ProductMaster/utils'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import { withContext } from 'ddiForm'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

export const getRowNodeId = data => data.description

export const colDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'product',
      onChange: onCellChange,
      propertyName
    }

    const textEditor = {
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: params
    }

    const colDef = [
      {
        ...textEditor,
        field: 'description',
        headerName: 'Keyword'
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        hide: !isEditing,
        maxWidth: 50,
        width: 50
      }
    ]

    return colDef
  }
)

const Keywords = ({ form, isEditing, height }) => (
  <EditableGrid
    actOnCellChange
    propertyName="keywords"
    addButtonText="Add Keyword"
    emptyRow={{
      description: ''
    }}
    focusCell="description"
    form={form}
    title="Keywords"
    headerStyle={headerStyle}
    isEditing={isEditing}
    columnDefs={colDefs}
    getRowNodeId={getRowNodeId}
    requiredCols={['description']}
    enableSorting
    height={height}
    allowInsertRow
    addBlankRowOnLoad
  />
)

Keywords.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditing: PropTypes.bool.isRequired
}

const keywordsWithContext = withContext({
  isEditing: form => form.get('isEditing')
})(Keywords)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  keywordsWithContext
)
