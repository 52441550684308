const headerNameMap = {
  altCostExtension: ['Alt Cost Extension', 'Alt Cost Ext'],
  altGPDollars: ['Alt G/P Dollars', 'Alt G/P $'],
  altGPPercent: ['Alt Gross Profit Percent', 'Alt G/P %'],
  averageCostExtension: ['Avg Cost Extension', 'Avg Cost Ext'],
  averageGPDollars: ['Avg G/P Dollars', 'Avg G/P $'],
  averageGPPercent: ['Avg Gross Profit Percent', 'Avg GP %'],
  commitInventory: ['Commit Inventory', 'Commit Inv'],
  coOpPercent: ['Co-op Percent', 'Co-op %'],
  costExtension: ['Cost Extension', 'Cost Ext'],
  costOverride: ['Cost Override', 'Cost Ovrd'],
  discountPercent: ['Discount Percent', 'Discount %'],
  grossProfitDollars: ['Gross Profit Dollars', 'G/P $'],
  grossProfitPercent: ['Gross Profit Percent', 'G/P %'],
  hazMatIdResolved: ['Haz Mat', 'Hazmat'],
  lineNumber: ['Ln', 'Line Number'],
  purchaseOrderId: ['Linked P/O', 'Linked PO'],
  transferId: ['Linked Transfer', 'Linked Xfer'],
  mfgNumber: ['Manufacturer Number', 'MPN'],
  priceExtension: ['Price Extension', 'Price Ext'],
  priceUOMId: ['Price Unit of Measure', 'Price UoM'],
  priceOverride: ['Price Override', 'Price Ovrd'],
  description: ['Product Description', 'Description'],
  statusDescription: ['Product Status', 'Status'],
  projectLine: ['Project Line Number', 'Project Line'],
  quantityBO: ['Quant B/O', 'Qty BO'],
  quantityOpen: ['Quantity Open', 'Qty Open'],
  quantityOrdered: ['Quant\nOrdered', 'Qty Ordered'],
  quantityScannedDisplay: ['Quantity Scanned', 'Qty Scanned'],
  quantityShipped: ['Quantity Shipped', 'Qty Shipped'],
  quantityStaged: ['Quantity Staged', 'Qty Staged'],
  quantity: ['Quantity to Ship', 'Qty to Ship'],
  uomId: ['Quant UM', 'UoM'],
  quantityUnScheduled: ['Quantity Unscheduled', 'Qty Unscheduled'],
  rebateCostMethod: ['Rebact Cost Formula', 'Rebate Form'],
  warehouseId: ['Selling Warehouse', 'Selling Whse'],
  shippableCostExtensionResolved: ['Shippable Cost Extension', 'Shippable Ext'],
  shippablePriceExtensionResolved: ['Shippable Extension', 'Shippable Cost'],
  spawnedQuantityShipped: ['Spawned Total Quantity Shipped', 'Spawned Qty'],
  transferComments: ['Transfer Comments', 'Xfer Comments'],
  transferFromWhseId: ['Transfer from Warehouse', 'Xfer from Whse'],
  transferQuantity: ['Transfer Quantity', 'Xfer Qty'],
  warehouseIdResolved: ['Warehouse', 'Whse'],
  warehousePicked: ['Warehouse\nPick', 'Whse Pick'],
  warrantyTagId: ['Warranty Tag', 'Warranty'],
  removeLineItem: ['Delete', 'Delete']
}

export default headerNameMap
