import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'

const AwaitingBackdrop = ({ fetchingEntity }) => (
  <Backdrop open={fetchingEntity} style={{ position: 'absolute', zIndex: 99 }}>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <CircularProgress size={50} thickness={10} style={{ color: '#fff' }} />
      <div style={{ marginLeft: 15, color: '#fff' }}>
        <div style={{ fontSize: '1em' }}>Please wait...</div>
      </div>
    </div>
  </Backdrop>
)

export default AwaitingBackdrop
