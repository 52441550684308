import behaviors from './behaviors'
import createSearch from '../createSearch'
import DropDownComponent from './LotSearchDropdown'

const LotSearch = createSearch({
  name: 'LotSearch',
  behaviors,
  popoverStyle: { width: 500 },
  DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowNavigation: false,
    allowSearchAll: true,
    allowSpecialProducts: false,
    hasFilters: false,
    hideToggle: true,
    isSecure: false,
    minimumKeywordLength: 2,
    searchType: '\u0000',
    transactionType: 'S'
  }
})

export default LotSearch
