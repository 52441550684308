import React from 'react'
import { ddiForm } from 'ddiForm'
import { Icon } from '@material-ui/core'
import memoize from 'memoize-one'
import MeasuredModal from 'components/MasterScreen/MeasuredModal'
import { getValue } from 'utils'
import Audit from 'components/MasterScreen/Audit'
import CustomFields from 'components/MasterScreen/CustomFields'
import {
  checkIfCustomFieldsHidden,
  transformCustomFieldsData
} from 'components/MasterScreen/CustomFields/utils'

import { wrapAuditDataAccess } from 'components/MasterScreen/Audit/sagas'
import { mapAuditResponse } from 'components/MasterScreen/Audit/utils'
import Activities from 'components/MasterScreen/Activities'
import Attachments from 'components/MasterScreen/Attachments'
import SearchArea from './components/SearchArea'
import LeftFooterButtons from './components/LeftFooterButtons'
import RightFooterButtons from './components/RightFooterButtons'
import Order from './tabs/Order'
// import Header from './tabs/Header'
// import Final from './tabs/Final'
import Invoicing from './tabs/Invoicing'
import Shipments from './tabs/Shipments'
import behaviors from './behaviors'
import './styles/css-mod-ignore.scss'
import salesOrderSagas, {
  getRecordArgumentsSaga,
  getSalesOrderTabProcess,
  saveArgumentsSaga,
  // saveSalesOrderProcess,
  cancelEditGroupNamesSaga
  // getRecordProcess
} from './sagas'
import {
  cancelSalesOrderEdit,
  cancelNewSalesOrderEdit,
  closeSalesOrder,
  launchCopyOrder,
  saveSalesOrder,
  clearOrder,
  createNewSalesOrder,
  // voidSalesOrder,
  convertQuoteToOrder,
  releaseCreditHold
} from './actions'
import { readSalesOrder } from './api'
import { mapGetRecordResponse, mapGetInvoicingTabResponse } from './utils'

const tabIconStyle = {
  fontSize: 18,
  marginBottom: 0,
  marginRight: 5,
  verticalAlign: 'middle'
}
const options = {
  actions: {
    cancelSalesOrderEdit: cancelSalesOrderEdit.try,
    closeSalesOrder: closeSalesOrder.try,
    convertQuoteToOrder: convertQuoteToOrder.try,
    launchCopyOrder,
    saveSalesOrder: saveSalesOrder.try,
    clearOrder,
    createNewSalesOrder: createNewSalesOrder.try,
    cancelNewSalesOrderEdit: cancelNewSalesOrderEdit.try,
    // voidSalesOrder: voidSalesOrder.try,
    releaseCreditHold: releaseCreditHold.try
  },
  behaviors,
  form: 'salesOrder',
  title: 'Sales Order',
  allowMultiple: true,
  sagas: {
    onInit: salesOrderSagas
    // onExit: closeSalesOrderProcess
    // onSave: saveSalesOrderProcess
  },
  getEntityApi: readSalesOrder,
  masterOptions: {
    headerComponent: SearchArea,
    hideReportButton: true,
    footer: {
      left: LeftFooterButtons,
      right: RightFooterButtons
    },
    saveArgumentsSaga,
    getRecordArgumentsSaga,
    cancelEditGroupNamesSaga,
    tabs: [
      {
        icon: <Icon style={tabIconStyle}>list</Icon>,
        component: Order,
        title: 'Order',
        access: form => getSalesOrderTabProcess(form, ['header', 'detail']),
        mapResponse: mapGetRecordResponse
      },
      {
        icon: <Icon style={tabIconStyle}>payment</Icon>,
        component: Invoicing,
        title: 'Checkout',
        access: form => getSalesOrderTabProcess(form, ['final', 'signature']),
        mapResponse: mapGetInvoicingTabResponse
      },
      {
        icon: <Icon style={tabIconStyle}>local_shipping</Icon>,
        component: Shipments,
        title: 'Shipments',
        access: form =>
          getSalesOrderTabProcess(form, ['shipments', 'signature']),
        mapResponse: mapGetRecordResponse,
        // isHidden: formState => getValue(formState, 'isNew', false) && getValue(formState, 'isLocked', false)
        isHidden: formState => getValue(formState, 'isNew', false)
      },
      {
        component: Activities,
        icon: <Icon style={tabIconStyle}>event</Icon>,
        title: 'Activities',
        access: form => getSalesOrderTabProcess(form, ['activities']),
        mapResponse: mapGetRecordResponse,
        // isHidden: formState => getValue(formState, 'isNew', false) && getValue(formState, 'isLocked', false)
        isHidden: formState => getValue(formState, 'isNew', false)
      },
      {
        access: form => getSalesOrderTabProcess(form, ['attachments']),
        component: Attachments,
        icon: <Icon style={tabIconStyle}>attachment</Icon>,
        title: 'Attachments',
        isHidden: formState => getValue(formState, 'isNew', false)
      },
      {
        component: Audit,
        icon: <Icon style={tabIconStyle}>search</Icon>,
        title: 'Audit',
        // access: wrapAuditDataAccess,
        access: 'audit',
        callAlways: true,
        // mapResponse: mapGetRecordResponse,
        mapResponse: mapAuditResponse,
        isHidden: formState => getValue(formState, 'isNew', false)
      },
      {
        component: CustomFields,
        title: 'Custom Fields',
        access: 'customfields',
        callAlways: true,
        isHidden: checkIfCustomFieldsHidden
      }
    ]
  },
  updateTitleMethod: ({ dataId }) =>
    `Sales Order${dataId ? ` - ${dataId}` : ''}`
}
export const salesOrderInModal = form => {
  return ddiForm({
    ...options,
    form
  })(MeasuredModal)
}

export default ddiForm(options)()
