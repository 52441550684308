import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { GridField } from 'ddiForm'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { headerStyle } from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

import master from './styles/master-style.scss'
import './styles/gridStyle.css'

export const getRowNodeId = ({ productId }) => productId

const dynamicColumnDefs = memoize(({ form, type }) => {
  const defaultDef = {
    filterParams: {
      clearButton: true,
      inRangeInclusive: true,
      suppressAndOrCondition: true
    }
  }
  const colDef = [
    {
      ...defaultDef,
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form
      },
      field: 'productId',
      filter: 'agTextColumnFilter',
      groupNames: ['analysis', 'substitutes'],
      headerName: 'Product',
      linkTo: type
    },
    {
      ...defaultDef,
      field: 'description',
      filter: 'agTextColumnFilter',
      headerName: 'Description'
    },
    {
      ...defaultDef,
      field: 'uom',
      filter: 'agTextColumnFilter',
      headerName: 'UOM'
    },
    {
      ...defaultDef,
      headerClass: 'numeric-value-label',
      cellStyle: { textAlign: 'right' },
      field: 'onHand',
      filter: 'agNumberColumnFilter',
      headerName: 'On Hand'
    },
    {
      ...defaultDef,
      headerClass: 'numeric-value-label',
      cellStyle: { textAlign: 'right' },
      field: 'committed',
      filter: 'agNumberColumnFilter',
      headerName: 'Committed'
    },
    {
      ...defaultDef,
      headerClass: 'numeric-value-label',
      cellStyle: { textAlign: 'right' },
      field: 'onOrder',
      filter: 'agNumberColumnFilter',
      headerName: 'On Order'
    },
    {
      ...defaultDef,
      headerClass: 'numeric-value-label',
      cellStyle: { textAlign: 'right' },
      field: 'available',
      filter: 'agNumberColumnFilter',
      headerName: 'Available'
    }
  ]
  return colDef
})

const Substitutes = ({ form, height }) => (
  <div className="product-analysis-layout-flex-column">
    <div className="product-analysis-overflow-wrapper">
      <div style={{ height: height * 0.95 }}>
        <GridField
          propertyName="productSubstitutes"
          title="Substitutes"
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          columnDefs={dynamicColumnDefs({
            form,
            type: 'productMaster'
          })}
          gridHeight={height - 78}
          rowSelection="single"
          enableSorting
          width="100%"
          fullWidth
        />
      </div>
    </div>
  </div>
)

Substitutes.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired
}

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  Substitutes
)
