import {
  deleteIn,
  setIn,
  fromJS,
  // getIn
} from 'utils'
import { compose } from 'redux'
import { withToggleFetch } from 'ddiForm/reducerEnhancers'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import { FIND_NEXT, FIND_PREV } from 'components/Search/IndexSearch/constants'
import { clearSelectedSerial } from './utils'
// import * as indexSearchReducer from 'components/Search/IndexSearch/reducer'

import * as CONSTANTS from './constants'

export default {
  [CONSTANTS.UPDATE_DESCRIPTION]: (state, { payload }) => {
    let result = state
    const { description } = payload

    result = setIn(result, 'fields.description.value', description)
    result = setIn(result, 'values.description', description)

    return result
  },
  [CONSTANTS.LOCK_SERIAL_FOR_EDIT.SUCCESS]: (state, { payload }) => {
    let result = state
    const { propertyName, rowIndex } = payload

    result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].isLocked`, true)
    

    return result
  },
  [CONSTANTS.UNLOCK_SERIAL.SUCCESS]: (state, { payload }) => {
    let result = state
    const { propertyName, rowIndex } = payload

    result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].isLocked`, false)

    return result
  },
  [CONSTANTS.SAVE_NOTES.SUCCESS]: (state, { payload }) => {
    let result = state
    const { propertyName, rowIndex, note, serialNumberId } = payload

    result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].notes`, note)
    result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].isLocked`, false)

    if (note) {
      result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].hasNotes`, true)
    } else {
      result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].hasNotes`, false)
      result = setIn(result, `fields.serialNote.${serialNumberId}.value`, note)
    }

    return result
  },
  [CONSTANTS.DELETE_SERIAL_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state
    const { rowIndex } = payload

    result = deleteIn(result, `fields.currentInventory.rowData[${rowIndex}]`)

    return result
  },
  [CONSTANTS.SET_SELECTED_SERIAL_NUMBER]: (state, { payload }) => {
    let result = state
    const { value } = payload
    result = setIn(result, 'fields.selectedSerialNumber.value', value)
    return result
  },
  [CONSTANTS.SAVE_PRODUCT.SUCCESS]: state => {
    let result = state

    result = setIn(result, 'isEditing', false)

    return result
  },
  [CONSTANTS.CLEAR_COMPLETE_SERIAL_HISTORY.SUCCESS]: compose(
    withToggleFetch(false),
    state => {
      let result = state

      result = setIn(result, 'fields.completeSerialHistory.rowData', fromJS([]))

      return result
    }
  ),
  [CONSTANTS.CLEAR_FIELDS]: (state, { payload }) => {
    let result = state
    const { prop } = payload

    result = setIn(result, `fields.${prop}.rowData`, fromJS([]))

    return result
  },
  [CONSTANTS.TOGGLE_ADD_MODE]: (state, { payload }) => {
    let result = state
    const { addMode } = payload

    result = setIn(result, 'addMode', addMode)

    return result
  },
  [CONSTANTS.ADD_SERIAL_NUMBER.SUCCESS]: (state, { payload }) => {
    let result = state
    const { rowIndex } = payload

    result = deleteIn(result, `fields.currentInventory.rowData[${rowIndex}].newData`)
    result = deleteIn(result, `values.currentInventory[${rowIndex}].newData`)

    result = deleteIn(result, `fields.currentInventory.rowData[${rowIndex}].isNew`)
    result = deleteIn(result, `values.currentInventory[${rowIndex}].isNew`)

    return result
  },
  [CONSTANTS.ADD_SERIAL_NUMBER.FAILURE]: (state, { payload }) => {
    let result = state
    const { rowIndex } = payload

    result = setIn(result, `fields.currentInventory.rowData[${rowIndex}].isNew`, false)
    result = setIn(result, `values.currentInventory[${rowIndex}].isNew`, false)

    return result
  },
  [CONSTANTS.CLEAR_CELL]: (state, { payload }) => {
    let result = state
    const { rowIndex, propertyName, field } = payload

    result = setIn(result, `fields.${propertyName}.rowData[${rowIndex}].${field}`, '')
    result = setIn(result, `values.${propertyName}[${rowIndex}].${field}`, '')

    return result
  },
  [FIND_NEXT.SUCCESS]: compose(
    clearSelectedSerial(),
    (state, { payload }) => {
      let result = state

      result = setIn(result, 'values.dataId', payload.name)
      return result
    },
  ),
  [FIND_PREV.SUCCESS]: compose(
    clearSelectedSerial(),
    (state, { payload }) => {
      let result = state

      result = setIn(result, 'values.dataId', payload.name)
      return result
    },
  ),
  ...editableGridBehaviors
}
