import React, { useState, useEffect, memo } from 'react'
import {
  AppBar,
  Button,
  Icon,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  Toolbar,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { MobileAppBar, TabPanel } from 'mobile/utils'
import { generateTabs } from 'pages/SalesOrder/utils'
import { noop, getIn, toJS, fromJS } from 'utils'

import CategoryPricing from './CategoryPricingTab'
import CompetitvePricing from './CompetitivePricingTab'
import Contracts from './ContractsTab'
import RebateCost from './RebateCost'
import PricingDetails from './PricingDetails'
import Pricing from './Pricing'

import '../styles/pricing-cell.scss'
import { openRebateCostTab } from '../../../actions'

export const MobileTab = withStyles({
  textColorInherit: {
    backgroundColor: '#517b9c',
    color: 'rgba(255, 255, 255, 0.60)'
  },
  root: {
    textTransform: 'uppercase',
    minWidth: 100,
    maxWidth: 100,
    width: 100,
    '@media (min-width: 600px)': {
      minWidth: 100,
      maxWidth: 100
    }
  }
})(Tab)

const TabContainer = ({ children }) => (
  <Typography component="div">{children}</Typography>
)

const useStyles = makeStyles(theme => ({
  root: {
    width: 600,
    height: 440,
    overflow: 'auto',
    position: 'relative'
  },
  paper: {
    width: '100%'
    // overflowX: 'hidden'
  },
  table: {
    width: 320
  },
  textField: { minWidth: 63, width: 63 }
}))

const PricingCell = ({
  allowCostOverride,
  dataId,
  dispatch,
  form,
  handleClose,
  showCompetitivePricesInSO,
  showContractPrices,
  gridApi,
  setCancelled = noop,
  onCancelButtonClicked,
  // priceWarningMessage,
  canChange,
  priceChange,
  lineNumber,
  canSeeCost,
  canSeeRebateCost,
  setEdited,
  isMobile = false,
  isPostingProvisionalData,
  isEditing,
  hasBeenEdited = false,
  isComponent = false,
  node
}) => {
  const classes = useStyles()

  const [tab, setTab] = useState('Price')

  function handleTabChange(e, val) {
    if (val) {
      if (val === 'Rebate Cost') {
        dispatch(openRebateCostTab.try(form, { dataId, lineNumber, setTab }))
      } else {
        setTab(val)
      }
    }
  }
  priceChange = toJS(priceChange) || {}
  // if (!priceChange) {
  // }

  const { priceWarningMessage = '' } = priceChange

  return (
    <div className="pricing-cell-wrapper">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {isMobile ? (
            <MobileAppBar position="static">
              <Toolbar style={{ minHeight: 35, padding: 0 }}>
                {!hasBeenEdited ? (
                  <div style={{ margin: '0 10px' }}>
                    <IconButton onClick={onCancelButtonClicked} size="small">
                      <Icon style={{ color: '#fff' }}>close</Icon>
                    </IconButton>
                  </div>
                ) : null}
                <Tabs value={tab} onChange={handleTabChange} scrollable>
                  <MobileTab value="Price" label="Price" key="Price" />
                  <MobileTab value="Detail" label="Detail" key="Detail" />
                  <MobileTab
                    value="Rebate Cost"
                    label="Rebate Cost"
                    key="Rebate Cost"
                  />
                  {showCompetitivePricesInSO ? (
                    <MobileTab
                      value="Competitive Pricing"
                      label="Competitive Pricing"
                      key="Competitive Pricing"
                    />
                  ) : (
                    <MobileTab
                      value="Category Pricing"
                      label="Category Pricing"
                      key="Category Pricing"
                    />
                  )}
                  {showContractPrices ? (
                    <MobileTab
                      value="Contracts"
                      label="Contracts"
                      key="Contracts"
                    />
                  ) : null}
                </Tabs>
              </Toolbar>
            </MobileAppBar>
          ) : (
            <AppBar position="static" style={{ boxShadow: 'none' }}>
              <Toolbar
                style={{
                  justifyContent: 'space-between',
                  minHeight: 35,
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                <Tabs value={tab} onChange={handleTabChange} scrollable>
                  {generateTabs([
                    'Price',
                    'Detail',
                    'Rebate Cost',
                    showCompetitivePricesInSO
                      ? 'Competitive Pricing'
                      : 'Category Pricing',
                    showContractPrices ? 'Contracts' : null
                  ])}
                </Tabs>
              </Toolbar>
            </AppBar>
          )}
          {/* {isPostingProvisionalData ? (
            <LinearProgress mode="indeterminate" />
          ) : null} */}
          <TabContainer>
            {isMobile ? (
              <div
                style={{
                  padding: '10px 10px 0 10px',
                  textAlign: hasBeenEdited ? 'left' : 'center',
                  width: '100%'
                }}
              >
                {priceWarningMessage ? (
                  <span
                    style={{
                      color: '#d9534f',
                      display: 'inline-block',
                      margin: hasBeenEdited ? '5px 15px 0 5px' : '5px 0 0 5px'
                    }}
                  >
                    {`${priceWarningMessage}${hasBeenEdited ? ':' : ''}`}
                  </span>
                ) : null}
                {hasBeenEdited ? (
                  <>
                    <Button
                      size="small"
                      startIcon={
                        <Icon style={{ color: '#389123' }}>check_circle</Icon>
                      }
                      onClick={handleClose}
                      style={{
                        borderColor: '#389123',
                        marginRight: 10,
                        color: '#389123'
                      }}
                      variant="outlined"
                    >
                      Confirm
                    </Button>
                    <Button
                      size="small"
                      onClick={onCancelButtonClicked}
                      startIcon={
                        <Icon style={{ color: '#d9534f' }}>cancel</Icon>
                      }
                      style={{ borderColor: '#d9534f', color: '#d9534f' }}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </>
                ) : null}
              </div>
            ) : null}
            {tab === 'Price' && (
              <Pricing
                dispatch={dispatch}
                form={form}
                handleClose={handleClose}
                priceChange={priceChange}
                lineNumber={lineNumber}
                allowCostOverride={allowCostOverride}
                canSeeCost={canSeeCost}
                setEdited={setEdited}
                isMobile={isMobile}
                isComponent={isComponent}
                data={node?.data}
                // data={data}
              />
            )}
            {tab === 'Detail' && (
              <PricingDetails
                priceChange={priceChange}
                canSeeCost={canSeeCost}
              />
            )}
            {tab === 'Rebate Cost' && (
              <RebateCost
                dispatch={dispatch}
                form={form}
                handleTabChange={handleTabChange}
                priceChange={priceChange}
                isPostingProvisionalData={isPostingProvisionalData}
                canSeeCost={canSeeCost}
                canChange={canChange}
                canSeeRebateCost={canSeeRebateCost}
                setEdited={setEdited}
                lineNumber={lineNumber}
                isEditing={isEditing}
              />
            )}
            {tab === 'Category Pricing' && (
              <CategoryPricing
                dispatch={dispatch}
                form={form}
                handleTabChange={handleTabChange}
                priceChange={priceChange}
                lineNumber={lineNumber}
              />
            )}
            {tab === 'Competitive Pricing' && (
              <CompetitvePricing
                dispatch={dispatch}
                form={form}
                handleTabChange={handleTabChange}
                priceChange={priceChange}
                lineNumber={lineNumber}
              />
            )}
            {tab === 'Contracts' && (
              <Contracts
                dispatch={dispatch}
                form={form}
                handleTabChange={handleTabChange}
                priceChange={priceChange}
                canSeeCost={canSeeCost}
                canSeeRebateCost={canSeeRebateCost}
                lineNumber={lineNumber}
              />
            )}
          </TabContainer>
        </Paper>

        <>
          {priceWarningMessage && !isMobile ? (
            <div style={{ position: 'absolute', bottom: 10, left: 10 }}>
              <span
                style={{
                  color: '#d9534f',
                  display: 'inline-block',
                  margin: '5px 0 0 5px'
                }}
              >
                {priceWarningMessage}
              </span>
            </div>
          ) : null}
          {!isMobile && isEditing ? (
            <Button
              onClick={onCancelButtonClicked}
              style={{ position: 'absolute', bottom: 10, right: 10 }}
              variant="contained"
            >
              Cancel
            </Button>
          ) : null}
        </>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { form, rowId, isComponent } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  // const priceChange =
  //   isComponent && ownProps?.node?.data?.priceChange
  //     ? fromJS(ownProps.node.data.priceChange)
  //     : getIn(formState, `additionalDataMap.${rowId}.priceChange`)
  const priceChange = getIn(formState, `additionalDataMap.${rowId}.priceChange`)
  const isEditing = getIn(formState, 'isEditing') || false
  const isPostingProvisionalData =
    getIn(formState, 'isPostingProvisionalData') || false
  //
  return {
    isEditing,
    isPostingProvisionalData,
    priceChange
  }
}
export default connect(mapStateToProps)(PricingCell)
