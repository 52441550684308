import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import shortid from 'shortid'
import SerialNumberGrid from 'pages/SalesOrder/tabs/Order/components/LineItemsSection/components/SerialNumberDropdown/components/SerialNumberGrid'
import OpenProductAnalysisInSecondaryModalCell from 'components/grid/OpenProductAnalysisInSecondaryModalCell'
import DDISerialNumberSearchMobile from 'mobile/ddiForm/wrapped/DDISerialNumberSearchMobile'
import { DDILotSearch, DDISerialNumberSearch } from 'ddiForm/wrapped'

import ActionButtons from './components/ActionButtons'
import { handleSerialNumberReviewChange } from '../../actions'

import './styles/serial-number-editor.scss'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

const SerialNumberEditorModal = props => {
  const dispatch = useDispatch()

  const {
    data: {
      form,
      lineHeaderDisplay,
      summary = { bins: [] },
      initialRequestParams,
      isMobile
    },
    id
  } = props

  const {
    bins = [],
    showReferenceId,
    showParent,
    showBinLocation,
    hideUom,
    showWarehouse
  } = summary

  /* default settings */
  const [state, setState] = useState({
    detail: props?.data?.detail || [],
    selectedBinId: props?.data?.selectedBinId,
    serialNumberSearchType: props?.data?.serialNumberSearchType,
    lineItemId: props?.data?.lineItemId,
    warehouseId: props?.data?.warehouseId,
    transactionId: props?.data?.transactionId,
    returnTransactionId: props?.data?.returnTransactionId,
    transactionType: props?.data?.transactionType,
    excludedSerialNumbers: props?.data?.excludedSerialNumbers,
    includedSerialNumbers: props?.data?.includedSerialNumbers,
    sourceBinId: props?.data?.sourceBinId,
    allowInvalidSerialNumbers: props?.data?.allowInvalidSerialNumbers,
    salesHistoryCustomerId: props?.data?.salesHistoryCustomerId,
    salesHistoryInvoiceId: props?.data?.salesHistoryInvoiceId,
    canEnterNewItems: props?.data?.canEnterNewItems,
    isIncomplete: props?.data?.isIncomplete,
    serialNumberGridKey: shortid.generate(),
    quantity:
      bins?.find(x => x.uid === props?.data?.selectedBinId)?.factoredQuantity ||
      null,

    /* lot specific data */
    allowInvalidLots: props?.data?.allowInvalidLots || false,
    lotSearchType: props?.data?.lotSearchType,
    returnCustomerId: props?.data?.returnCustomerId,
    suppressCreateNewLotInSearch:
      props?.data?.suppressCreateNewLotInSearch || false,
    transactionUomId: props?.data?.transactionUomId,
    useAvailableLotsOnly: props?.data?.useAvailableLotsOnly || false
  })

  const columnDefs = [
    {
      field: 'lineNumber',
      colId: 'lineNumber',
      headerName: lineHeaderDisplay,
      maxWidth: 100
    },
    {
      field: 'productId',
      colId: 'productId',
      headerName: 'Product',
      cellRendererFramework: OpenProductAnalysisInSecondaryModalCell,
      cellRendererParams: {
        form
      }
    },
    {
      field: 'productDescription',
      colId: 'productDescription',
      headerName: 'Description'
    },
    {
      field: 'uomId',
      colId: 'uomId',
      headerName: 'UOM',
      tooltipField: 'uomTooltip',
      hide: hideUom
    },
    {
      field: 'factoredQuantity',
      colId: 'factoredQuantity',
      headerName: 'Qty',
      ...rightAlignParams
    }
  ]

  const onRowSelected = async params => {
    const { node, api, columnApi, data } = params
    const { factoredQuantity } = data

    if (node?.selected && data?.uid !== state?.selectedBinId) {
      console.log('onRowSelected', params)

      let result
      try {
        result = await dispatch(
          handleSerialNumberReviewChange.try(form, {
            apiParams: {
              action: 'read',
              BinUId: data?.uid,
              cancel: false,
              changeId: null
            }
          })
        )
      } catch (error) {
        console.log(error)
      } finally {
        console.log('onRowSelected', result)
        // debugger
        setState({
          ...state,
          ...result?.data,
          quantity: factoredQuantity,
          serialNumberGridKey: shortid.generate()
        })
      }
    } else {
      setState({
        ...state,
        quantity: factoredQuantity
      })
    }
  }

  const onGridReady = params => {
    const { api } = params

    api.forEachNode(node => {
      if (node?.data?.lineNumber === '1' || node?.data?.lineNumber === 1) {
        node.setSelected(true)
      }
    })
  }

  const serialNumberData =
    state?.detail && Array.isArray(state.detail)
      ? state.detail.reduce((acc, next) => {
          if (next?.serialNumberId) {
            acc = acc.concat({
              dataId: next.serialNumberId
            })
          }
          return acc
        }, [])
      : []

  const lotsEditorData =
    state?.detail && Array.isArray(state.detail)
      ? state.detail.reduce((acc, next) => {
          if (next?.lotId) {
            acc = acc.concat({
              ...next,
              dataId: next.lotId
            })
          }
          return acc
        }, [])
      : []

  const selectedRowData = bins.find(x => x.uid === state?.selectedBinId)
  const { lineNumber, productId } = selectedRowData
  const optionalGridParams = isMobile ? { height: 300 } : {}

  const {
    lotSearchType,
    suppressCreateNewLotInSearch,
    allowInvalidLots,
    availableLotsOnly
  } = state
  const usesLotsEditor = Boolean(lotSearchType)
  const lotsEditorMeta = usesLotsEditor
    ? {
        suppressCreateNewLotInSearch,
        allowInvalidLots,
        searchType: lotSearchType,
        availableLotsOnly
      }
    : {}

  return (
    <div
      className="serial-number-editor-fullscreen-flex-modal-wrapper"
      style={{ maxWidth: '100%', width: '100%' }}
    >
      <div
        className="serial-number-editor-interface-wrapper"
        style={{ maxWidth: '100%', width: '100%' }}
      >
        <div
          style={{
            width: '100%',
            display: isMobile ? 'block' : 'flex',
            maxWidth: '100%'
          }}
        >
          <div
            style={{
              width: isMobile ? '100%' : usesLotsEditor ? '50%' : '65%'
            }}
          >
            <Grid
              headerName="Line Items"
              columnDefs={columnDefs}
              rowData={bins}
              onGridReady={onGridReady}
              getRowNodeById={data => data.uid}
              onRowSelected={onRowSelected}
              rowSelection="single"
              {...optionalGridParams}
            />
          </div>
          <div
            style={{
              width: isMobile ? '100%' : usesLotsEditor ? '50%' : '35%'
            }}
          >
            <SerialNumberGrid
              dataId={productId}
              warehouseId={state.warehouseId}
              dispatch={dispatch}
              form={form}
              lineNumber={lineNumber}
              rowId={state.selectedBinId}
              serialNumberData={
                usesLotsEditor
                  ? [{ lots: lotsEditorData }]
                  : [
                      {
                        serialNumbers: serialNumberData
                      }
                    ]
              }
              excludedSerialNumbers={state.excludedSerialNumbers}
              includedSerialNumbers={state.includedSerialNumbers}
              serialNumberSearchType={state.serialNumberSearchType}
              transactionType={state.transactionType}
              key={state.serialNumberGridKey}
              inquiryMode={false}
              SearchComponent={
                isMobile
                  ? DDISerialNumberSearchMobile
                  : usesLotsEditor
                  ? DDILotSearch
                  : DDISerialNumberSearch
              }
              quantity={state?.quantity}
              isSerialNumberReview
              usesLotsEditor={usesLotsEditor}
              lotsEditorMeta={lotsEditorMeta}
            />
          </div>
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        initialRequestParams={initialRequestParams}
      />
    </div>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false
})(SerialNumberEditorModal)
