import { setIn, getIn } from 'utils'
import { fromJS } from 'immutable'
import { withToggleFetch, id, withHasRecord } from 'ddiForm/reducerEnhancers'
import auditBehaviors from 'components/MasterScreen/Audit/reducer'
import activityBehaviors from 'components/MasterScreen/Activities/reducer'
import attachmentsBehaviors from 'components/MasterScreen/Attachments/reducer'

import { toggleFetch } from 'ddiForm/utils'
import { compose } from 'redux'
import * as DDI_MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import behaviors from 'ddiForm/MasterScreen/behaviors'
import shortId from 'shortid'
import { CLEAR_SEARCH } from 'components/search/DocumentSearch/constants'
import * as CONSTANTS from './constants'

export default {
  [CONSTANTS.UNLOCK_CONTACT_RECORD.TRY]: state => {
    let result = state
    result = setIn(result, 'isEditing', false)
    return result
  },
  [CONSTANTS.GET_ANALYSIS.REQUEST]: state =>
    compose(
      withToggleFetch(true),
      id
    )(state),
  [CONSTANTS.GET_ANALYSIS.SUCCESS]: compose(
    withHasRecord(true),
    withToggleFetch(false),
    (state, { payload }) => {
      let result = state
      if (!payload.analysis) {
        return result
      }
      for (const prop in payload.analysis) {
        if (prop === 'aging' || prop === 'customerRankings') {
          result = setIn(
            result,
            `fields.customerLedger.summary.${prop}`,
            fromJS(payload.analysis[prop])
          )
          result = setIn(
            result,
            `values.customerLedger.summary.${prop}`,
            fromJS(payload.analysis[prop])
          )
        } else {
          result = setIn(
            result,
            `fields.${prop}.value`,
            fromJS(payload.analysis[prop])
          )
          result = setIn(
            result,
            `values.${prop}`,
            fromJS(payload.analysis[prop])
          )
        }
      }
      if (payload && payload.activities) {
        result = setIn(
          result,
          'values.activities',
          payload.activities.activities
        )
      }
      result = setIn(result, 'values.selectedPaymentInfos', fromJS([]))
      result = setIn(result, 'values.selectedPaymentAccount', '')
      result = setIn(result, 'values.dataId', payload.dataId)
      result = setIn(result, 'fields.dataId.value', payload.dataId)
      result = setIn(result, 'values.parent', fromJS(payload.parent))
      result = setIn(result, 'values.description', payload.description)
      return result
    }
  ),
  // [CONSTANTS.GET_ANALYSIS.FAILURE]: state => compose(withHasRecord(false), withToggleFetch(false))(state),
  [CONSTANTS.GET_ANALYSIS.FAILURE]: state =>
    compose(withToggleFetch(false))(state),
  [CONSTANTS.GET_PAYMENT_PROFILES.SUCCESS]: (state, { payload }) => {
    let result = state
    const gatewayAccounts = payload?.vault
      ? payload.vault.gatewayAccounts
      : payload?.gatewayAccounts
    if (gatewayAccounts.length) {
      const paymentProfiles = gatewayAccounts.reduce((acc, x, i) => {
        let oldRow = gatewayAccounts[i]
        if (oldRow && !oldRow.rowId) {
          oldRow.rowId = shortId.generate()
        }

        oldRow = { ...oldRow, ...x }

        acc = acc.concat(oldRow)
        return acc
      }, [])

      result = setIn(
        result,
        'fields.paymentProfiles.value',
        fromJS(paymentProfiles)
      )
      result = setIn(
        result,
        'fields.paymentProfiles.rowData',
        fromJS(paymentProfiles)
      )

      result = setIn(result, 'values.paymentProfiles', fromJS(paymentProfiles))
    }
    return toggleFetch(result, false)
  },
  [CONSTANTS.REMOVE_PAYMENT_SOURCE.SUCCESS]: (
    state,
    { payload: { gatewayAccounts } }
  ) => {
    let result = state
    result = setIn(
      result,
      'values.selectedPaymentInfos',
      fromJS(gatewayAccounts[0].paymentInfo)
    )
    result = setIn(
      result,
      'fields.selectedPaymentInfos.value',
      fromJS(gatewayAccounts[0].paymentInfo)
    )
    result = setIn(
      result,
      'fields.selectedPaymentInfos.rowData',
      fromJS(gatewayAccounts[0].paymentInfo)
    )
    return result
  },
  // [CONSTANTS.ADD_PAYMENT_SOURCE]: (state, { payload: { dataId } }) => {
  //   const result = state
  //   const paymentProfiles = getIn(result, 'fields.selectedPayment.value')

  //   return result
  // },
  [CONSTANTS.SET_SELECTED_PAYMENT]: (state, { payload }) => {
    let result = state
    // result = setIn(
    //   result,
    //   'fields.selectedPaymentInfos.rowData',
    //   fromJS(payload.paymentInfo)
    // )
    result = setIn(
      result,
      'fields.selectedPaymentAccount.value',
      fromJS(payload.account)
    )
    result = setIn(
      result,
      'values.selectedPaymentInfos',
      fromJS(payload.paymentInfo)
    )
    result = setIn(
      result,
      'values.selectedPaymentAccount',
      fromJS(payload.account)
    )
    return result
  },
  [CONSTANTS.CLEAR_SELECTED_PAYMENT]: (state, { payload: { paymentInfo } }) => {
    let result = state
    result = setIn(
      result,
      'fields.selectedPaymentInfos.value',
      fromJS(paymentInfo)
    )
    result = setIn(
      result,
      'fields.selectedPaymentInfos.rowData',
      fromJS(paymentInfo)
    )
    return result
  },
  [CONSTANTS.HANDLE_COPY_PARENT.SUCCESS]: (state, { payload: { parent } }) => {
    let result = state
    const {
      address: { address1, address2, city, zip, country },
      fax,
      phone
    } = parent

    let editedFields = getIn(result, 'editedFields')

    result = setIn(result, 'fields.addressLine1.value', address1)
    editedFields = editedFields.add('addressLine1')
    result = setIn(result, 'fields.addressLine2.value', address2)
    editedFields = editedFields.add('addressLine2')
    result = setIn(result, 'fields.city.value', city)
    editedFields = editedFields.add('city')
    result = setIn(result, 'fields.zipCode.value', zip)
    editedFields = editedFields.add('zipCode')
    result = setIn(result, 'fields.country.value', country)
    editedFields = editedFields.add('country')
    result = setIn(result, 'fields.state.value', parent.address.state)
    editedFields = editedFields.add('state')
    result = setIn(result, 'fields.phone1.value', phone)
    editedFields = editedFields.add('phone1')
    result = setIn(result, 'fields.fax.value', fax)
    editedFields = editedFields.add('fax')

    result = setIn(result, 'editedFields', editedFields)

    return result
  },
  [CONSTANTS.SET_SHIPTO_ID]: (state, { payload: { shipToIds } }) => {
    let result = state
    result = setIn(state, 'values.shipToIds', fromJS(shipToIds))
    return result
  },
  // [RESET_MASTER_FIELDS]: state => {
  //   let result = state

  //   const paymentProfiles = getIn(result, 'fields.paymentProfiles.rowData')
  //     ? getIn(result, 'fields.paymentProfiles.rowData').toJS()
  //     : []

  //   // const selectedPaymentInfos = getIn(
  //   //   result,
  //   //   'fields.selectedPaymentInfos.rowData'
  //   // )
  //   //   ? getIn(result, 'fields.selectedPaymentInfos.rowData').toJS()
  //   //   : []

  //   if (paymentProfiles.length) {
  //     result = setIn(result, 'fields.paymentProfiles.rowData', fromJS([]))
  //   }

  //   // if (selectedPaymentInfos.length) {
  //   //   result = setIn(result, 'fields.selectedPaymentInfos.rowData', fromJS([]))
  //   // }

  //   // result = setIn(result, 'fields.dataId.value', '')
  //   result = setIn(result, 'values.selectedPaymentInfos', fromJS([]))

  //   result = behaviors[RESET_MASTER_FIELDS](result)

  //   return result
  // },
  [CONSTANTS.SET_PRIMARY_TAB]: (
    state,
    { payload: { selectedPrimaryTab, selectedSecondaryTab } }
  ) => {
    let result = state

    result = setIn(
      result,
      'masterOptions.selectedPrimaryTab',
      fromJS(selectedPrimaryTab)
    )
    result = setIn(
      result,
      'masterOptions.selectedSecondaryTab',
      fromJS(selectedSecondaryTab)
    )
    return result
  },
  [CONSTANTS.SET_DEFAULT_CHART_VIEW.SUCCESS]: (
    state,
    { payload: { view } }
  ) => {
    let result = state
    result = setIn(result, 'values.agingView', view)
    return result
  },
  [CONSTANTS.UPDATE_PHOTO]: (state, { payload: { base64 } }) => {
    let result = state
    result = setIn(result, 'values.imageBase64String', base64)
    return result
  },
  [CONSTANTS.DELETE_CONTACT.SUCCESS]: state => {
    let result = state
    result = setIn(result, 'fields.dataId.value', '')
    return result
  },
  [CONSTANTS.SET_PARENT]: (state, { payload }) => {
    let result = state
    const { parentId, parentType } = payload

    result = setIn(result, 'values.parent.parentId', parentId)
    result = setIn(result, 'values.parent.parentType', parentType)
    return result
  },
  [CONSTANTS.REFRESH_AUDITS]: (state, { payload }) => {
    let result = state

    result = behaviors[DDI_MASTER_CONSTANTS.GET_ENTITY.SUCCESS](result, {
      payload
    })

    return result
  },
  [CLEAR_SEARCH]: state => {
    let result = state
    result = setIn(result, 'values.imageBase64String', '')
    return result
  },
  ...auditBehaviors,
  ...activityBehaviors,
  ...attachmentsBehaviors
}
