import React from 'react'
import { connect } from 'react-redux'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { openProductAnalysisScreen } from 'pages/StockReceipts/components/StageSalesOrdersModal/components/OpenProductAnalysisCell'
import { debounce } from 'lodash'

function IndexSearchRenderer() {}

// gets called once before the renderer is used
IndexSearchRenderer.prototype.init = function(params) {
  // console.log(params)
  // create the cell
  this.eGui = document.createElement('div')
  this.eGui.innerHTML = `<span class="my-css-class"><button class="btn-simple"><span class="my-value"></span></button></span>`
  // get references to the elements we want

  this.eButton = this.eGui.querySelector('.btn-simple')
  this.eButton.style.textDecoration = 'underline'
  this.eValue = this.eGui.querySelector('.my-value')

  // set value into cell
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value

  // add event listener to button
  this.eventListener = function() {
    if (
      params?.form &&
      params?.form?.split('.')?.[1] &&
      params?.value &&
      params?.dispatch
    ) {
      params.dispatch(openProductAnalysisScreen(params.form, params.value))
    } else {
      params.dispatch(
        openScreenAction({
          dataId: params.value,
          route: 'productmaster',
          title: 'Product Analysis',
          activeKey: 'analysis',
          image: 'inventory_product_analysis_16.png',
          groupNames: ['analysis', 'overview']
        })
      )
    }
  }
  this.eventListener = debounce(this.eventListener, 2000, {
    leading: true,
    trailing: false
  })
  this.eButton.addEventListener('click', this.eventListener)
}

// gets called once when grid ready to insert the element
IndexSearchRenderer.prototype.getGui = function() {
  return this.eGui
}

// gets called whenever the user gets the cell to refresh
IndexSearchRenderer.prototype.refresh = function(params) {
  // set value into cell again
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value
  // return true to tell the grid we refreshed successfully
  return true
}

// gets called when the cell is removed from the grid
IndexSearchRenderer.prototype.destroy = function() {
  // do cleanup, remove event listener from button
  this.eButton.removeEventListener('click', this.eventListener)
}

export default IndexSearchRenderer
