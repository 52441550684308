import React from 'react'
import { component, options } from 'pages/SalesOrderInquiry'
import TooltipTemplate from 'components/BaseTooltipTemplate'
import behaviors from './behaviors'
// import TooltipTemplate from './TooltipTemplate'
// import ContextMenu from './ContextMenu'
// import customContextMenuItems from './CustomContextMenuItems'
// import keyToActionMap from './keyToActionMap'
import { getTooltip } from '../IndexSearch/actions'
import createSearch from '../createSearch'

const SalesOrderSearch = createSearch({
  // customContextMenuItems,
  name: 'SalesOrderSearch',
  type: 'SalesOrderSearch',
  behaviors,
  form: {
    component,
    options
  },
  popoverStyle: {
    maxWidth: '100%',
    width: 1300
  },
  tooltip: {
    component: TooltipTemplate,
    // keyToActionMap,
    networkRequestPromise: getTooltip,
    style: { width: 400 },
    requestArgs() {
      console.log('whatThis', this)
      return {
        apiName: 'salesOrderTooltip',
        propertyName: this.props.propertyName,
        dataId: this.props.recordName || this.props.value
      }
    }
  }
})

export default SalesOrderSearch
