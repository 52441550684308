import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getCustomer = createAsyncFormAction(
  CONSTANTS.GET_CUSTOMER,
  CONSTANTS.customerMaster
)

export const getGridCellTooltip = ({ recordName, indexSearchType }) => {
  const action = createAction(
    CONSTANTS.GET_GRID_CELL_TOOLTIP.REQUEST,
    { recordName, indexSearchType },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

/* DEPRECATED: get the Ship Tos */
// export const listShipTos = createAsyncFormAction(
//   CONSTANTS.LIST_SHIP_TOS,
//   CONSTANTS.customerMaster
// )

export const deleteShipTo = ({ dataId, description }) =>
  createAction(
    CONSTANTS.DELETE_SHIP_TO.TRY,
    { dataId, description },
    CONSTANTS.customerMaster
  )

export const showShipToWarningModal = ({ dataId, description }) =>
  createAction(
    CONSTANTS.SHOW_SHIP_TO_WARNING_MODAL,
    { dataId, description },
    CONSTANTS.customerMaster
  )

/* start tax matrix actions */
export const openTaxMatrix = (form, { dataId }) =>
  createAction(CONSTANTS.OPEN_TAX_MATRIX.TRY, { dataId }, form)

export const deleteTaxMatrix = (form, { dataId, modalId, unlocked }) =>
  createAction(
    CONSTANTS.DELETE_TAX_MATRIX.TRY,
    { dataId, modalId, unlocked },
    form
  )

export const lockTaxMatrix = (form, { dataId }) =>
  createAction(CONSTANTS.LOCK_TAX_MATRIX.TRY, { dataId }, form)

export const unlockTaxMatrix = (form, { dataId }) =>
  createAction(CONSTANTS.UNLOCK_TAX_MATRIX.TRY, { dataId }, form)

export const saveTaxMatrix = (form, { dataId }) =>
  createAction(CONSTANTS.SAVE_TAX_MATRIX.TRY, { dataId }, form)

export const closeTaxMatrixModal = form =>
  createAction(CONSTANTS.CLOSE_TAX_MATRIX_MODAL, {}, form)

export const createTaxMatrix = createAsyncFormAction(
  CONSTANTS.CREATE_TAX_MATRIX
)

export const setTaxMatrixPropertyDescription = (
  form,
  { propertyName, description }
) =>
  createAction(
    CONSTANTS.SET_TAX_MATRIX_PROPERTY_DESCRIPTION,
    { propertyName, description },
    form
  )

export const openProductMaster = (form, { dataId, screenOpenArgs = {} }) =>
  createAction(CONSTANTS.OPEN_PRODUCT_MASTER, { dataId, screenOpenArgs }, form)

/* end tax matrix actions */

/* lock & delete a contact */
export const lockContactAsync = createAsyncFormAction(CONSTANTS.LOCK_CONTACT)
export const deleteContactAsync = createAsyncFormAction(
  CONSTANTS.DELETE_CONTACT
)

/* more kinds of tooltips */
export const getSalesOrderTooltip = ({ recordName }) => {
  const action = createAction(
    CONSTANTS.GET_SALES_ORDER_TOOLTIP.REQUEST,
    { recordName },
    null,
    false,
    { thunk: true, apiRequest: true }
  )
  return action
}

/* handle special grid add row cases */
export const onAfterAddOrDeleteGridRow = (form, { propertyName }) =>
  createAction(
    CONSTANTS.ON_AFTER_ADD_OR_DELETE_GRID_ROW,
    { propertyName },
    form
  )

export const setDefaultGridSelection = (form, { dataId, propertyName }) =>
  createAction(
    CONSTANTS.SET_DEFAULT_GRID_SELECTION,
    { dataId, propertyName },
    form
  )

/* close customer: unlocks all objects related to the customer record */
export const closeCustomerMaster = form =>
  createAction(CONSTANTS.CLOSE_CUSTOMER_MASTER.TRY, {}, form)

export const deleteCustomerRecord = (form, { dataId }) =>
  createAction(CONSTANTS.DELETE_CUSTOMER_RECORD.TRY, { dataId }, form)

export const createCustomerRecord = form =>
  createAction(CONSTANTS.CREATE_CUSTOMER_RECORD.TRY, {}, form)

export const invokePreNewMode = form =>
  createAction(CONSTANTS.INVOKE_PRE_NEW_MODE, {}, form)

export const cancelPreNewMode = ({
  displayAllTabs = true,
  dataIdSet = false
}) =>
  createAction(
    CONSTANTS.CANCEL_PRE_NEW_MODE,
    { displayAllTabs, dataIdSet },
    CONSTANTS.customerMaster
  )

export const cancelEditNewCustomerRecord = () =>
  createAction(
    CONSTANTS.CANCEL_EDIT_NEW_CUSTOMER_RECORD,
    {},
    CONSTANTS.customerMaster
  )

export const saveTempId = ({ value, post }) =>
  createAction(
    CONSTANTS.SAVE_TEMP_ID,
    { value, post },
    CONSTANTS.customerMaster
  )

export const listContacts = createAsyncFormAction(CONSTANTS.LIST_CONTACTS)

export const saveIndexSearchDescription = ({ label, value }) =>
  createAction(
    CONSTANTS.SAVE_INDEX_SEARCH_DESCRIPTION,
    { label, value },
    CONSTANTS.customerMaster
  )

export const openContact = createAsyncFormAction(CONSTANTS.OPEN_CONTACT)

export const changePODollarLimit = createAsyncFormAction(
  CONSTANTS.CHANGE_PO_DOLLAR_LIMIT
)

export const deletePODollarLimit = createAsyncFormAction(
  CONSTANTS.DELETE_PO_DOLLAR_LIMIT
)

export const propertyChange = createAsyncFormAction(CONSTANTS.PROPERTY_CHANGE)

export const openScreenFromCustomerMaster = ({ ...args }) =>
  createAction(
    CONSTANTS.OPEN_SCREEN_FROM_CUSTOMER_MASTER,
    { ...args },
    CONSTANTS.customerMaster
  )

export const showWarningModal = (form, { message }) =>
  createAction(CONSTANTS.SHOW_WARNING_MODAL, { message }, form)
export const updateAs = createAsyncFormAction(CONSTANTS.UPDATE_AS)
