import React from 'react'
import { withContext } from 'ddiForm'
import { emptyList, getIn } from 'utils'
import { useDispatch } from 'react-redux'

import { Button } from '@material-ui/core'
import {
  editCorporateFieldUpdatesData,
  cancelEditCorporateFieldUpdatesData,
  destroyModal,
  saveCoprorateFieldUpdatesData,
  readCorporateFieldUpdatesData
} from '../actions'

const marginRight = { marginRight: 5 }

const ActionButtons = props => {
  const { dataId, isLocked, modal, form, templatesList } = props
  const templates = templatesList?.toJS ? templatesList.toJS() : []
  const id = modal?.id
  const dispatch = useDispatch()

  const saveRecord = e =>
    dispatch(saveCoprorateFieldUpdatesData.try(form, { modalId: id }))

  const closeModal = e => dispatch(destroyModal(form, { modalId: id }))

  const editRecord = e => dispatch(editCorporateFieldUpdatesData.try(form))

  const cancelAndExitModal = e =>
    dispatch(
      cancelEditCorporateFieldUpdatesData.try(form, {
        modalId: id
      })
    )

  const cancelEdit = e =>
    dispatch(
      cancelEditCorporateFieldUpdatesData.try(form, {
        retainData: true
      })
    )

  const getTemplateData = e => {
    const templateKey = templates?.[0]?.dataId

    if (templateKey) {
      dispatch(
        readCorporateFieldUpdatesData.try(form, {
          templateKey
        })
      )
    }
  }

  if (isLocked) {
    return (
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <Button variant="contained" onClick={saveRecord} style={marginRight}>
          Save
        </Button>
        <Button variant="contained" onClick={cancelEdit} style={marginRight}>
          Cancel
        </Button>
        <Button variant="contained" onClick={cancelAndExitModal}>
          Exit
        </Button>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <div>
        <Button variant="contained" onClick={getTemplateData}>
          Default
        </Button>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={editRecord}
          style={marginRight}
          disabled={!dataId}
        >
          Edit
        </Button>
        <Button variant="contained" onClick={closeModal}>
          Exit
        </Button>
      </div>
    </div>
  )
}

export default withContext({
  dataId: form =>
    getIn(form, 'fields.corporateFieldUpdates.dataId.value') || '',
  isLocked: form =>
    getIn(form, 'values.corporateFieldUpdates.isLocked') || false,
  templatesList: form =>
    getIn(form, 'meta.corporateFieldUpdates.defaultTemplates') || emptyList
})(ActionButtons)
