import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { noop } from 'lodash'
import { Icon, IconButton, TableCell, TableRow } from '@material-ui/core'
import { DDISelectField } from 'ddiForm/wrapped'
import { setField } from 'ddiForm/actions'
import { errorRed, mediumGrey } from 'theme/colors'
import { is } from 'utils'
import usePrevious from 'hooks/usePrevious'
import getDisplayedValue from './getDisplayedValue'

const SelectValueDisplay = ({
  value,
  hasHeaders,
  hadHeaders,
  clearRow,
  ...rest
}) => {
  // console.log('SelectValueDisplay', hasHeaders, hadHeaders, value, clearRow)

  // useEffect(() => {
  //   if (
  //     value &&
  //     is.bool(hasHeaders) &&
  //     is.bool(hadHeaders) &&
  //     hasHeaders !== hadHeaders &&
  //     clearRow &&
  //     typeof clearRow === 'function'
  //   ) {
  //     clearRow()
  //   }
  // }, [hasHeaders, hadHeaders])

  return <div>{value}</div>
}

const ClearRowButton = ({
  clearRow = noop,
  disabled = false,
  propertyName,
  form
}) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={e => clearRow(e)}
      style={{ color: disabled ? mediumGrey : errorRed }}
      size="small"
    >
      <Icon size="small">cancel</Icon>
    </IconButton>
  )
}

const MappedColumnRow = ({
  hasHeaders,
  form,
  item,
  lineItems = [],
  selectValues = []
}) => {
  // console.log('MappedColumnRow', inputRef)
  const dispatch = useDispatch()
  const hadHeaders = usePrevious(hasHeaders)

  const clearRow = e =>
    dispatch(setField(form, `productImport.${item.propertyName}`, ''))

  // useEffect(() => {
  //   if (
  //     is.bool(hasHeaders) &&
  //     is.bool(hadHeaders) &&
  //     hasHeaders !== hadHeaders
  //   ) {
  //     clearRow()
  //   }
  // }, [hasHeaders, hadHeaders])

  return (
    <TableRow key={item.fieldName}>
      <TableCell style={{ width: 175 }}>{item.fieldName}</TableCell>
      <TableCell
        style={{
          // padding: '5px 24px 5px 5px',
          width: 150
        }}
      >
        <DDISelectField
          propertyName={`productImport.${item.propertyName}`}
          style={{
            fontSize: 12,
            fontWeight: 400,
            width: '100%'
          }}
          autoWidth
          values={selectValues}
          selectValue="dataId"
          selectDescription="description"
          preventAutoDisable
          disabled={!selectValues?.length}
        />
      </TableCell>
      <TableCell>
        {getDisplayedValue({
          propertyName: item.propertyName,
          lineItems,
          selectValues,
          hasHeaders,
          hadHeaders,
          clearRow
        })(SelectValueDisplay)}
      </TableCell>
      <TableCell style={{ width: 35 }}>
        <ClearRowButton
          propertyName={item.propertyName}
          form={form}
          clearRow={clearRow}
          disabled={!selectValues?.length || !lineItems?.length || false}
        />
      </TableCell>
    </TableRow>
  )
}

export default MappedColumnRow
