import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Table,
  FormGroup,
  FormControlLabel,
  Button,
  Paper,
  Switch,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from 'pages/CustomerOrderPad/utils'

import moment from 'moment'
import { getIn, numToDollar } from 'utils'
// import DDIExpansionPanel from 'components/DDIExpansionPanel'
import PriceCalculator from '../components/PriceCalculator'
import * as actions from '../actions'
// import {
//   priceDetailSavePermissionsSelector,
//   editablePriceSelector,
//   editablePriceUpdateFieldSelector
// } from '../selectors'

const infoStyle = { color: '#444', fontSize: '0.85em' }

export const mapDispatchToProps = (dispatch, ownProps) => {
  const { data: { form } } = ownProps
  // console.log('mapDispatch', form)
  // debugger

  return {
    showAdjustedPrice: ({ dataId, UOMId, gridCallbackFn }) =>
      dispatch(actions.showAdjustedPrice(form, { dataId, UOMId, gridCallbackFn })),
    savePriceDetailAsync: ({ dataId, UOMId, isHidden, isMarginPriceDisabled, cb }) =>
      dispatch(
        actions.savePriceDetailAsync.try(form, {
          dataId,
          UOMId,
          isHidden,
          isMarginPriceDisabled,
          cb
        })
      ),
    setEditablePrice: ({ price, updateField }) =>
      dispatch(
        actions.setEditablePrice(form, { price, updateField })
      ),
    setPriceDetailSavePermissions: ({ canSave }) =>
      dispatch(
        actions.setPriceDetailSavePermissions(form, { canSave })
      ),
    updateLineItemPrice: ({ dataId, uomId, price }) =>
      dispatch(
        actions.updateLineItemPrice(form, {
          dataId,
          uomId,
          price
        })
      ),
    setQuotedPrice: ({ dataId, uomId, quotedPrice }) => {
      dispatch(
        actions.setQuotedPrice.try(form, {
          dataId,
          uomId,
          quotedPrice
        })
      )
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { data: { form } } = ownProps
  // console.log('mapState', form)
  // debugger

  const formState = getIn(state, `ddiForm.${form}`)
  let priceDetail = getIn(formState, 'values.priceDetail')
  priceDetail = priceDetail && priceDetail.toJS ? priceDetail.toJS() : {}

  return {
    priceDetail,
    quotedPrice: priceDetail.quotedPrice || 0,
    priceDetailSavePermissionsSelector: getIn(formState, 'fields.priceDetailSavePermissions.value') || true,
    editablePrice: getIn(formState, 'fields.editablePrice.value') || '',
    editablePriceUpdateField: getIn(formState, 'fields.editablePrice.updateField') || ''
  }
}

export class PriceDetailModal extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    editablePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    editablePriceUpdateField: PropTypes.string.isRequired,
    setEditablePrice: PropTypes.func.isRequired,
    updateLineItemPrice: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    const { priceDetail } = props

    this.state = {
      expanded: false,
      adjustedPrice: priceDetail.adjustedPrice
        ? parseFloat(priceDetail.adjustedPrice).toFixed(4)
        : '',
      canSave: priceDetail.canSave || false,
      isDueToBuy: priceDetail.isDueToBuy || false,
      isHidden: priceDetail.isHidden || false,
      isMarginPriceDisabled: priceDetail.isMarginPriceDisabled || false,
      quotedPrice: priceDetail.quotedPrice || 0
    }
  }


  // componentDidMount() {
  //   this.props.setPriceDetailSavePermissions({
  //     canSave: this.props.data.responseData.canSave
  //   })
  // }

  checkExpansionPanelState = ({ expanded }) => {
    // debugger
    if (expanded === false) {
      this.priceCalculator.setState({ gpPercent: 0.0 }, () => {
        this.revertPriceQuote()
      })
    }
  }

  acceptQuote = () => {
    const { editablePriceUpdateField, priceDetail } = this.props

    // debugger
    this.setState({
      expanded: false,
      [editablePriceUpdateField]: parseFloat(this.priceCalculator.state.editablePrice).toFixed(4)
    }, () => {
      const { dataId, uomId } = priceDetail

      this.priceCalculator.setState({ gpPercent: 0.0 })
      this.props.setQuotedPrice({
        dataId,
        uomId,
        // quotedPrice: editablePrice
        quotedPrice: this.priceCalculator.state.editablePrice
      })

    })
  }

  cancelQuote = () => {
    const { quotedPrice, editablePriceUpdateField, setEditablePrice } = this.props
    this.setState({ expanded: false }, () => {
      this.revertPriceQuote()
    })
  }

  revertPriceQuote = () => {
    const { editablePriceUpdateField, setEditablePrice, quotedPrice } = this.props
    this.priceCalculator.setState({ editablePrice: quotedPrice, gpPercent: 0.0 }, () => {
      setEditablePrice({
        price: quotedPrice,
        updateField: editablePriceUpdateField
      })
    })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleExpansionPanelChange = panel => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false }, () => {
      if (!this.state.expanded) {
        this.revertPriceQuote()
      }
    })
  }

  render() {
    // console.log('PRICE_DETAIL', this.props)
    const {
      data: { form }
    } = this.props

    const {
      adjPriceEffectiveDate,
      adjustedBy,
      adjustedPrice,
      avgPurchaseDays,
      canAdjustPrice,
      canChangeQuote,
      canSave,
      canSeeCostAndGP,
      dataId,
      daysSinceLastPurch,
      historyDate,
      historyPrice,
      historyQuantity,
      isDisableMarginPriceEnabled,
      isDisableMarginPriceVisible,
      isHideItemEnabled,
      marginPrice,
      matrixCost,
      numberOfPurchases,
      quoteNo,
      quotedBy,
      quotedDate,
      quotedPrice,
      uomId
    } = this.props.priceDetail

    const {
      isDueToBuy,
      isHidden,
      isMarginPriceDisabled,
      expanded
    } = this.state
    const tableCellStyle = { fontSize: '1.2rem' }
    // console.log('priceDetailRenderProps', this.props.data.responseData, quotedPrice)

    return (
      <div>
        <Paper
          style={{
            background: '#f5f7f7',
            marginBottom: 20,
            padding: '0 15px'
          }}
        >
          <Row>
            <Col lg={6} md={6} sm={6} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isHideItemEnabled}
                    checked={isHidden}
                    onChange={this.handleChange('isHidden')}
                    value="isHidden"
                  />
                }
                label="Hide This Item From Order Pad History"
              />
            </Col>
            {isDisableMarginPriceVisible && (
              <Col lg={6} md={6} sm={6} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMarginPriceDisabled}
                      disabled={!isDisableMarginPriceEnabled}
                      onChange={this.handleChange('isMarginPriceDisabled')}
                      value="isMarginPriceDisabled"
                    />
                  }
                  label="Disable Margin Price"
                />
              </Col>
            )}
          </Row>
        </Paper>
        <h4 style={{ fontSize: 14, margin: '0 0 5px 0' }}>
          Prices for UOM: {uomId}
        </h4>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                History:{' '}
                <strong>
                  {historyPrice
                    ? numToDollar(historyPrice, 'en-US', 'USD', 4, 10)
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                Date:{' '}
                <strong>
                  {historyDate
                    ? moment(new Date(historyDate)).format('M/D/YYYY')
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                Quantity: <strong>{historyQuantity}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Quoted:{' '}
                <strong>
                  {(quotedPrice || quotedPrice === 0)
                    ? numToDollar(quotedPrice, 'en-US', 'USD', 4, 10)
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                Date:{' '}
                <strong>
                  {quotedDate
                    ? moment(new Date(quotedDate)).format('M/D/YYYY')
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <span style={{ display: 'inline-block', marginRight: 3 }}>
                    Initials:
                  </span>{' '}
                  <strong>{quotedBy}</strong>
                </div>
              </TableCell>
            </TableRow>
            {canChangeQuote ? (
              <TableRow style={{ background: '#c4c4c4' }}>
                <TableCell colspan={3}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                    <div
                      className="change-quote-wrapper"
                      style={{
                        overflow: 'visible',
                        width: 200
                      }}
                    >
                      <ExpansionPanel
                        expanded={expanded === 'quotedPricePanel'}
                        onChange={this.handleExpansionPanelChange('quotedPricePanel')}
                      >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography style={infoStyle}>{`Change Quote: ${quoteNo}`}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                          <div style={{ display: 'block' }}>
                            <div style={{ minHeight: 40 }}>
                              <div style={{ margin: '0 auto', width: 150 }}>
                                <PriceCalculator
                                  ref={el => (this.priceCalculator = el)}
                                  initialPrice={quotedPrice}
                                  matrixCost={matrixCost}
                                  showPriceCalculator={canSeeCostAndGP}
                                  updateField="quotedPrice"
                                  form={form}
                                />
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                variant="contained"
                                size="small"
                                onClick={this.acceptQuote}
                                style={{ marginRight: 10 }}
                              >
                                OK
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                onClick={this.cancelQuote}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell>
                Adjusted:{' '}
                <strong>
                  {adjustedPrice
                    ? numToDollar(adjustedPrice, 'en-US', 'USD', 4, 10)
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                Date:{' '}
                <strong>
                  {adjPriceEffectiveDate
                    ? moment(new Date(adjPriceEffectiveDate)).format('M/D/YYYY')
                    : ''}
                </strong>
              </TableCell>
              <TableCell>
                Initials: <strong>{adjustedBy}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colspan={3}>
                Margin:{' '}
                <strong>
                  {marginPrice
                    ? numToDollar(marginPrice, 'en-US', 'USD', 4, 10)
                    : ''}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div>
          <h4 style={{ fontSize: 14, margin: '20px 0 5px 0' }}>
            Average Purchase Days
          </h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Days Since Last Purchase</TableCell>
                <TableCell>
                  <strong>{daysSinceLastPurch}</strong>
                </TableCell>
                <TableCell>24 Month Number of Purchases:</TableCell>
                <TableCell>
                  <strong>{numberOfPurchases}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <Paper
          style={{
            background: '#f5f7f7',
            marginTop: 15,
            padding: '0 15px'
          }}
        >
          <Row>
            <Col lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled
                    checked={isDueToBuy}
                    onChange={this.handleChange('isDueToBuy')}
                    value="isDueToBuy"
                  />
                }
                label="Due To Buy"
              />
            </Col>
          </Row>
        </Paper>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PriceDetailModal)
