import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import shortid from 'shortid'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import useStateWithCallback from 'hooks/useStateWithCallback'
import { makeRowDataUnique, numToDollar, plainDeepEqual } from 'utils'
import {
  Button,
  Icon,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'

import ActionButtons from './components/ActionButtons'
import { handleShipperHQInteraction } from '../../actions'

import '../../styles/modal-flex-button-styles.scss'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

let gridApi = null
let columnApi = null
let _isMounted = false

const makeShippingMethodsUnique = vendors =>
  vendors && Array.isArray(vendors)
    ? vendors.reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          shippingMethods: next.shippingMethods.reduce((a, n) => {
            a = a.concat({
              ...n,
              parentDataId: next.dataId,
              rowId: shortid.generate()
            })
            return a
          }, [])
        })

        return acc
      }, [])
    : []

const ShipperHQModal = props => {
  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const dispatch = useDispatch()

  const {
    data: { form, total, isEditing, vendors = [] },
    id
  } = props

  const [state, setState] = useStateWithCallback({
    total: Number(total),
    vendors:
      vendors && Array.isArray(vendors)
        ? makeShippingMethodsUnique(vendors)
        : []
  })

  const updateStateAfterApiTransaction = result => {
    setState(
      {
        ...state,
        ...result,
        vendors:
          result?.vendors && Array.isArray(result?.vendors)
            ? makeShippingMethodsUnique(result.vendors)
            : state.vendors || []
      },
      () => {
        if (gridApi && _isMounted) {
          setTimeout(() => {
            gridApi.forEachNode(node => node.setExpanded(true))
          }, 1)
        }
      }
    )
  }

  const columnDefs = [
    {
      field: 'dataId',
      colId: 'dataId',
      headerName: '',
      headerClass: 'hide-header-text',
      cellRenderer: 'agGroupCellRenderer',
      maxWidth: 50,
      valueGetter: () => '',
      pinned: 'left',
      lockPinned: true,
      suppressMovable: true
    },
    {
      field: 'description',
      colId: 'description',
      headerName: 'Qty',
      cellStyle: { fontWeight: 'bold' }
    }
  ]

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi

    params.api.forEachNode(node => node.setExpanded(true))
  }

  // const handleRowSelection = async (
  //   parentDataId,
  //   selectedRowId,
  //   isSelected
  // ) => {
  //   let result

  //   const shippingMethods = state.vendors.reduce((acc, next) => {
  //     if (next.dataId === parentDataId) {
  //       acc = acc.concat(
  //         ...next.shippingMethods.reduce((a, n) => {
  //           const { rowId, parentDataId, ...rest } = n
  //           a = a.concat({
  //             ...rest,
  //             isSelected: selectedRowId === rowId && isSelected
  //           })
  //           return a
  //         }, [])
  //       )
  //     }
  //     return acc
  //   }, [])

  //   const previousShippingMethods = state.vendors
  //     .find(x => x.dataId === parentDataId)
  //     .shippingMethods.reduce((acc, next) => {
  //       const { rowId, parentDataId, ...rest } = next
  //       acc = acc.concat({
  //         ...rest
  //       })
  //       return acc
  //     }, [])

  //   // if (plainDeepEqual(shippingMethods, previousShippingMethods)) {
  //   //   debugger
  //   //   return
  //   // }

  //   try {
  //     result = await dispatch(
  //       handleShipperHQInteraction.try(form, {
  //         apiParams: {
  //           action: 'select',
  //           vendors: [{ dataId: parentDataId, shippingMethods }]
  //         }
  //       })
  //     )
  //   } catch (error) {
  //     console.log(error)
  //   } finally {
  //     console.log(result)
  //     // updateStateAfterApiTransaction(result, true)
  //     setState({
  //       ...state,
  //       total: result.total
  //     })
  //   }
  // }

  const updateTotal = () => {
    const selectedRows = []
    gridApi.forEachDetailGridInfo(info => {
      const selected = info.api.getSelectedNodes()
      if (selected?.length === 1) {
        selectedRows.push(selected?.[0].data)
      }
    })

    const newTotal = selectedRows.reduce((acc, next) => {
      acc += next.charge
      return acc
    }, 0)

    setState({
      ...state,
      total: newTotal.toFixed(2)
    })
  }

  const detailCellRendererParams = {
    refreshStrategy: 'rows',
    detailGridOptions: {
      suppressRowClickSelection: true,
      suppressHorizontalScroll: true,
      width: '100%',
      rowSelection: 'single',
      columnDefs: [
        {
          field: 'isSelected',
          colId: 'isSelected',
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: false,
          minWidth: 50,
          maxWidth: 50,
          width: 50,
          suppressMenu: true
        },
        {
          field: 'carrierId',
          colId: 'carrierId'
        },
        {
          field: 'carrierType',
          colId: 'carrierType'
        },
        {
          field: 'charge',
          colId: 'charge'
        }
      ],
      getRowNodeId: data => data.rowId,
      onFirstDataRendered: params => params.api.sizeColumnsToFit(),
      onGridReady: params => {
        const { api } = params
        api.forEachNode(node => {
          if (node?.data?.isSelected) {
            node.setSelected(true)
          }
        })
      },
      onRowSelected: params => {
        // const { node, api, data } = params
        // const selectedRows = params.api.getSelectedRows()
        updateTotal()
      }
    },
    getDetailRowData: params => {
      // let rowData
      // if (params.data.comments)
      const details =
        params?.data?.shippingMethods &&
        Array.isArray(params.data.shippingMethods)
          ? params.data.shippingMethods.reduce((acc, next) => {
              acc = acc.concat({
                ...next,
                parentDataId: params?.data?.dataId
              })

              return acc
            }, [])
          : []

      // details = makeRowDataUnique(details, 'rowId')
      params.successCallback(details)
      // params.node.gridApi.forEachDetailGridInfo(detailGridInfo => {
    }
  }

  const onSave = async e => {
    const selectedRows = []
    gridApi.forEachDetailGridInfo(info => {
      const selected = info.api.getSelectedNodes()
      if (selected?.length === 1) {
        selectedRows.push(selected?.[0].data)
      }
    })

    if (selectedRows.length === state.vendors.length) {
      const vendorSelections = state.vendors.reduce((acc, next) => {
        acc = acc.concat({
          dataId: next.dataId,
          shippingMethods: next.shippingMethods.reduce((a, n) => {
            const selectedRecord = selectedRows?.find(
              x => x.parentDataId === next.dataId
            )
            const { parentDataId, rowId, ...rest } = n
            a = a.concat({
              ...rest,
              isSelected: rowId === selectedRecord?.rowId
            })

            return a
          }, [])
        })

        return acc
      }, [])

      let result
      try {
        await dispatch(
          handleShipperHQInteraction.try(form, {
            apiParams: {
              action: 'select',
              vendors: vendorSelections
            }
          })
        )
      } catch (error) {
        console.log(error)
      } finally {
        console.log(result)
        dispatch(
          handleShipperHQInteraction.try(form, {
            apiParams: {
              action: 'save'
            },
            modalId: id
          })
        )
      }

      // setTimeout(() => {
      //   if (gridApi && _isMounted) {
      //     gridApi.forEachNode(node => node.setExpanded(true))
      //     gridApi.forEachDetailGridInfo(info => {
      //       info.api.forEachNode(node => {
      //         if (
      //           node?.data &&
      //           selectedVendors.find(
      //             x =>
      //               x?.carrierId === node?.data?.carrierId &&
      //               x?.carrierType === node?.data?.carrierType
      //           )
      //         ) {
      //           node.setSelected(true)
      //         } else {
      //           node.setSelected(false)
      //         }
      //       })
      //     })
      //   }
      // }, 0)
      // console.log('vendors', selectedVendors)
      // debugger

      // await dispatch(
      //   handleShipperHQInteraction.try(form, {
      //     apiParams: {
      //       action: 'select',
      //       vendors: [{ dataId: parentDataId, shippingMethods }]
      //     }
      //   })
      // )
    } else {
      dispatch(
        handleShipperHQInteraction.try(form, {
          displayModalError: true
        })
      )
    }
  }

  const selectLowestRate = async e => {
    let result
    try {
      result = await dispatch(
        handleShipperHQInteraction.try(form, {
          apiParams: {
            action: 'selectlowestrate'
          }
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      const selectedVendors = result.vendors.reduce((acc, next) => {
        acc = acc.concat(
          ...next.shippingMethods.reduce((a, n) => {
            if (n?.isSelected) {
              a = a.concat(n)
            }
            return a
          }, [])
        )
        return acc
      }, [])

      setTimeout(() => {
        if (gridApi && _isMounted) {
          gridApi.forEachNode(node => node.setExpanded(true))
          gridApi.forEachDetailGridInfo(info => {
            info.api.forEachNode(node => {
              if (
                node?.data &&
                selectedVendors.find(
                  x =>
                    x?.carrierId === node?.data?.carrierId &&
                    x?.carrierType === node?.data?.carrierType
                )
              ) {
                node.setSelected(true)
              } else {
                node.setSelected(false)
              }
            })
          })
        }
      }, 0)
      console.log('vendors', selectedVendors)
    }
  }

  const getHeight = node => {
    if (node.detail) {
      const shippingMethods = node?.data?.shippingMethods?.length || 0
      const height = shippingMethods * 30 + 35
      return height
    }

    return 30
  }

  const getRowHeight = params => getHeight(params.node)

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div
        className="sales-order-entry-modal-primary-interface-wrapper"
        style={{ paddingBottom: 30 }}
      >
        <div style={{ width: '100%' }}>
          <Grid
            headerName="Quote Freight"
            columnDefs={columnDefs}
            rowData={state.vendors}
            getRowNodeById={data => data.dataId}
            height={250}
            hideHeader
            onGridReady={onGridReady}
            getRowHeight={getRowHeight}
            masterDetail
            detailCellRendererParams={detailCellRendererParams}
            keepDetailRows
          />
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        onClickSelectLowestRate={selectLowestRate}
        onClickSaveButton={onSave}
        isEditing={isEditing}
        total={state.total}
      />
    </div>
  )
}

export default ShipperHQModal
