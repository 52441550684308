import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { TextField, Tooltip } from '@material-ui/core'
import useStateWithCallback from 'hooks/useStateWithCallback'
import { createVendor, savePreNewModeValue } from '../actions'

let tabKeyFired

const PreNewTextField = ({
  form,
  maxLength = 5,
  wrapperStyle = { width: 175 }
}) => {
  const dispatch = useDispatch()
  const [value, setValue] = useStateWithCallback('')
  const [tooltipOpen, toggleTooltipOpen] = useStateWithCallback(true)

  const handleClose = () => toggleTooltipOpen(false)

  const handleOpen = () => toggleTooltipOpen(true)

  useEffect(() => {
    /* reset on mount and unmount */
    tabKeyFired = false

    return () => {
      tabKeyFired = false
    }
  }, [])

  const saveValueToRedux = debounce(() => {
    if (!tabKeyFired) {
      dispatch(savePreNewModeValue(form, { value }))
    }
  }, 300)

  const handleChange = event =>
    setValue(event.target.value, () => {
      saveValueToRedux(value)
    })

  const onKeyDown = event => {
    if (event.key === 'Tab' && value) {
      tabKeyFired = true
      dispatch(savePreNewModeValue(form, { value }))
      dispatch(createVendor.try(form, { value, tabKeyFired }))
    }
  }

  const onBlur = event => {
    dispatch(savePreNewModeValue(form, { value }))
  }

  return (
    <div style={wrapperStyle}>
      <Tooltip
        title="Enter ID and hit the tab key"
        open={tooltipOpen}
        onClose={handleOpen}
        onOpen={handleClose}
        placement="top"
        enterDelay={3000}
      >
        <TextField
          autoFocus
          fullWidth
          onChange={handleChange}
          value={value}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          placeholder="Enter ID and hit the tab key"
          inputProps={{
            maxLength
          }}
        />
      </Tooltip>
    </div>
  )
}

export default PreNewTextField
