import { deleteIn, is, setIn, getIn, fromJS, makeRowDataUnique } from 'utils'
import shortid from 'shortid'
import { OPEN_SCREEN, SET_FIELD, SAVE } from 'ddiForm/constants'
import { List, Set } from 'immutable'
import activityBehaviors from 'components/MasterScreen/Activities/reducer'
import attachmentsBehaviors from 'components/MasterScreen/Attachments/reducer'
import selectionCriteriaBehaviors from 'components/SelectionCriteriaModal/reducer'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import auditBehaviors from 'components/MasterScreen/Audit/reducer'
import corporateFieldUpdatesBehaviors from 'components/MasterScreen/CorporateFieldUpdates/behaviors'
import templateBehaviors from 'components/MasterScreen/Template/reducer'
import notesModalBehaviors from 'modals/NotesModal/reducer'

import * as DDIMASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import { behaviors as ddiFormBehaviors } from 'ddiForm/reducer'

/* Contact-related */
import { HANDLE_COPY_PARENT } from 'pages/ContactMaster/constants'
import { DELETE_CONTACT, LIST_CONTACTS } from 'pages/CustomerMaster/constants'

/* local imports */
import * as CONSTANTS from './constants'
import {
  resetLedgerInputs,
  validateGridRowImmutable,
  updateEditedFields,
  removeTrackedTabsFromState,
  clearIsPendingFlags,
  setUpScreenGrids
} from './utils'

const resetPreNewMode = result => {
  if (getIn(result, 'preNewMode') || getIn(result, 'values.preNewText')) {
    result = deleteIn(result, 'preNewMode')
    result = deleteIn(result, 'values.preNewText')
  }

  return result
}

const handleStatusObject = (result, payload = {}) => {
  const editedFields = getIn(result, 'editedFields') || Set()

  /* status is an object here, have to deal with this -- SVE 8/25/21 */
  if (payload?.status && !editedFields.has('status')) {
    const status = payload?.status?.value
    result = setIn(result, 'fields.status.value', status)
  }

  if (payload?.status?.description) {
    const statusDescription = payload?.status?.description
    result = setIn(result, 'values.statusDescription', statusDescription)
  }

  if (payload?.status?.color) {
    const statusColor = payload?.status?.color
    result = setIn(result, 'values.statusColor', fromJS(statusColor))
  }

  return result
}

export default {
  [OPEN_SCREEN.SUCCESS]: (state, action) => {
    let result = state

    /* ddiForm does not always handle these correctly, depending on new mode etc. */
    result = setUpScreenGrids(result)
    return result
  },
  [DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS](
      result,
      action
    )

    result = handleStatusObject(result, action?.payload)

    // /* not sure why the buyersSelected prop doesn't work */
    // const editedFields = getIn(result, 'editedFields') || Set()
    // if (
    //   action?.payload &&
    //   Object?.hasOwnProperty?.call(action.payload, 'buyersSelected') &&
    //   !editedFields?.has('buyersSelected')
    // ) {
    //   result = setIn(
    //     result,
    //     'fields.selectedSalespeople.value',
    //     action.payload.buyersSelected
    //   )

    //   result = setIn(
    //     result,
    //     'values.selectedSalespeople',
    //     action.payload.buyersSelected
    //   )
    // }

    return result
  },
  [SET_FIELD]: (state, { payload: { propertyName, value, results } }) => {
    let result = state
    if (propertyName === 'dataId') {
      result = setIn(result, 'notesDisplayed', false)
      result = deleteIn(result, 'values.templateKey')

      /* 
        if the user moves around prior to setting the vendor ID,
        we need to remove the trackedTabs or ddiForm will not
        fetch that data on tab change -- SVE 9/2/9021
      */
      result = setIn(result, 'masterOptions.trackedTabs', Set())
      result = setIn(result, 'editedFields', Set())
    }

    const searchFields = [
      'categoryId',
      'apTermsId',
      'currencyId',
      'remitToVendorId',
      'liabilityGLId',
      'manufacturingRepProductNumber',
      'shipViaId'
    ]

    if (searchFields.includes(propertyName)) {
      const descriptionKey = propertyName.replace('Id', 'Description')
      result = updateEditedFields(result, descriptionKey)
      if (value && results?.description) {
        result = setIn(result, `values.${descriptionKey}`, results.description)
      } else {
        result = setIn(result, `values.${descriptionKey}`, '')
      }
    }

    return result
  },
  [CONSTANTS.SAVE_AGING_VIEW.SUCCESS]: (state, { payload: { view } }) => {
    let result = state

    if (view) {
      result = setIn(result, 'values.summary.agingView', view)
    }

    return result
  },
  [CONSTANTS.SET_SUMMARY_EXPANSION_PANEL_STATE]: (
    state,
    { payload: { expanded } }
  ) => {
    let result = state
    result = setIn(result, 'meta.ledgerMeta.summaryExpanded', expanded)
    return result
  },
  [CONSTANTS.TOGGLE_PRE_NEW_MODE]: (state, action) => {
    let result = state

    if (getIn(result, 'preNewMode')) {
      result = setIn(result, 'preNewMode', false)
      result = deleteIn(result, 'values.preNewText')
    } else {
      result = setIn(result, 'preNewMode', true)
    }

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    result = setIn(result, 'fields.dataId.value', '')
    result = setIn(result, 'values.dataId', '')
    result = setIn(result, 'editedFields', Set())

    return result
  },
  [CONSTANTS.SAVE_PRE_NEW_MODE_VALUE]: (state, { payload: { value } }) => {
    let result = state
    result = setIn(result, 'values.preNewText', value)

    return result
  },
  [DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    // debugger
    result = resetLedgerInputs(result)
    result = setIn(result, 'editedFields', Set())
    return result
  },
  [DELETE_CONTACT.SUCCESS]: (state, { payload: { dataId }, meta }) => {
    let result = state
    let contacts = getIn(result, 'fields.contacts.rowData')
    if (contacts.size) {
      const rowIndex = contacts.findIndex(x => x.get('dataId') === dataId)
      if (rowIndex > -1) {
        contacts = contacts.delete(rowIndex)
      }
    }

    result = setIn(result, 'values.contacts', contacts)
    result = setIn(result, 'fields.contacts.rowData', contacts)
    return result
  },
  [HANDLE_COPY_PARENT.SUCCESS]: (state, { payload: { parent } }) => {
    let result = state
    const {
      address: { address1, address2, city, zip, country },
      fax,
      phone
    } = parent

    let editedFields = getIn(result, 'contact.editedFields') || Set()

    result = setIn(result, 'contact.fields.addressLine1.value', address1)
    editedFields = editedFields.add('addressLine1')
    result = setIn(result, 'contact.fields.addressLine2.value', address2)
    editedFields = editedFields.add('addressLine2')
    result = setIn(result, 'contact.fields.city.value', city)
    editedFields = editedFields.add('city')
    result = setIn(result, 'contact.fields.zipCode.value', zip)
    editedFields = editedFields.add('zipCode')
    result = setIn(result, 'contact.fields.country.value', country)
    editedFields = editedFields.add('country')
    result = setIn(result, 'contact.fields.state.value', parent?.address?.state)
    editedFields = editedFields.add('state')
    result = setIn(result, 'contact.fields.phone1.value', phone)
    editedFields = editedFields.add('phone1')
    result = setIn(result, 'contact.fields.fax.value', fax)
    editedFields = editedFields.add('fax')

    result = setIn(result, 'contact.editedFields', editedFields)

    return result
  },
  [LIST_CONTACTS.SUCCESS]: (state, { payload: { contacts } }) => {
    let result = state

    if (contacts) {
      result = setIn(result, 'values.contacts', fromJS(contacts))
      result = setIn(result, 'fields.contacts.value', fromJS(contacts))
      result = setIn(result, 'fields.contacts.rowData', fromJS(contacts))
    }
    return result
  },
  [CONSTANTS.REMOVE_EMPTY_GRID_ROW]: (
    state,
    { payload: { propertyName, requiredCols } }
  ) => {
    let result = state
    let rowData = getIn(result, `fields.${propertyName}.rowData`)
    rowData = rowData?.toJS ? rowData.toJS() : []

    rowData = rowData.reduce((acc, next) => {
      if (next?.rowId !== 'blankrow') {
        acc = acc.concat(next)
      }

      return acc
    }, [])

    rowData = fromJS(rowData)

    result = setIn(result, `fields.${propertyName}.rowData`, rowData)
    result = setIn(result, `fields.${propertyName}.isPending`, false)
    result = setIn(result, `values.${propertyName}`, rowData)

    return result
  },
  [CONSTANTS.HANDLE_ROW_DATA_UPDATE]: (
    state,
    {
      payload: {
        addNewRowOnChange = false,
        propertyName,
        emptyRow = null,
        field,
        value,
        rowId,
        rowIndex,
        requiredCols = []
      }
    }
  ) => {
    let result = state
    if (value === undefined) {
      return result
    }

    let rowData = getIn(result, `fields.${propertyName}.rowData`)
    rowData = rowData.update(rowIndex, data => data.set(field, value))

    if (rowData?.get(rowIndex)?.get('rowId') === 'blankrow') {
      rowData = rowData.update(rowIndex, data =>
        data.set('rowId', shortid.generate())
      )
    }

    if (
      rowData &&
      rowData.size &&
      requiredCols &&
      requiredCols.length &&
      List.isList(rowData)
    ) {
      /* OK we pretty much just need to validate every row from now on */
      const missingDataCountTotal = rowData.reduce((acc, next) => {
        const missingDataCount = validateGridRowImmutable(requiredCols, next)
        if (missingDataCount > 0) {
          acc += 1
        }
        return acc
      }, 0)

      // result = setIn(result, rowDataKey, newRowData)

      const isPending = !(missingDataCountTotal === 0)
      result = setIn(result, `fields.${propertyName}.isPending`, isPending)

      if (!isPending && addNewRowOnChange && emptyRow) {
        rowData = rowData.push(fromJS(emptyRow))
        result = setIn(result, `fields.${propertyName}.isPending`, true)
      }
    }

    result = setIn(result, `fields.${propertyName}.rowData`, rowData)
    result = setIn(result, `values.${propertyName}`, rowData)
    result = updateEditedFields(result, propertyName)

    return result
  },
  [CONSTANTS.TOGGLE_CHECKBOX_CELL]: (
    state,
    {
      payload: {
        propertyName,
        rowIndex,
        field,
        value,
        values,
        isHeaderToggle,
        selectAll
      }
    }
  ) => {
    let result = state
    let rowData = getIn(result, `fields.${propertyName}.rowData`)

    if (is.number(rowIndex) && field) {
      rowData = rowData.update(rowIndex, data => data.set(field, value))
    }

    if (isHeaderToggle && field && List.isList(rowData)) {
      rowData = rowData.reduce((acc, next) => {
        acc = acc.push(next.set(field, selectAll))
        return acc
      }, List())
    }

    result = setIn(result, `fields.${propertyName}.rowData`, rowData)
    result = setIn(result, `values.${propertyName}`, rowData)
    result = updateEditedFields(result, propertyName)

    return result
  },
  [DDIMASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS](
      result,
      action
    )

    result = clearIsPendingFlags(result)
    result = handleStatusObject(result, action?.payload)
    return result
  },
  [DDIMASTER_CONSTANTS.CANCEL_EDIT.SUCCESS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.CANCEL_EDIT.SUCCESS](
      result,
      action
    )

    result = removeTrackedTabsFromState(result)
    result = clearIsPendingFlags(result)

    /* if cancelling a new vendor */
    if (getIn(result, 'values.isNew')) {
      result = setIn(result, 'values.isNew', false)
      result = setIn(result, 'fields.dataId.value', '')
      result = setIn(result, 'values.dataId', '')

      result = resetLedgerInputs(result)
      result = setIn(result, 'masterOptions.trackedTabs', Set())
      result = setIn(result, 'editedFields', Set())
      result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
        result,
        action
      )
    }

    result = resetPreNewMode(result)
    result = handleStatusObject(result, action?.payload)
    return result
  },
  [SAVE.SUCCESS]: (state, action) => {
    let result = state

    result = ddiFormBehaviors[SAVE.SUCCESS](result, action)

    /* try this workflow again later on */
    if (action?.payload?.internalNotes) {
      result = setIn(result, 'notesDisplayed', true)
    }

    if (
      action?.payload?.dropShip?.taxableStates &&
      Array.isArray(action.payload.dropShip.taxableStates)
    ) {
      /* this data does not get properly parsed on save, not sure why */
      const taxableStates = fromJS(
        makeRowDataUnique(action.payload.dropShip.taxableStates, 'rowId')
      )
      result = setIn(result, 'fields.taxableStates.rowData', taxableStates)
      result = setIn(result, 'values.taxableStates', taxableStates)
    }

    result = clearIsPendingFlags(result)
    result = resetPreNewMode(result)
    result = handleStatusObject(result, action?.payload)
    return result
  },
  [CONSTANTS.PROPERTY_CHANGE.FAILURE]: (
    state,
    { payload: { propertyChanged, rowIndex } }
  ) => {
    let result = state

    if (is.number(rowIndex) && propertyChanged) {
      let rowData = getIn(result, `fields.${propertyChanged}.rowData`)
      rowData = rowData.delete(rowIndex)
      result = setIn(result, `fields.${propertyChanged}.rowData`, rowData)
      result = setIn(result, `values.${propertyChanged}`, rowData)

      if (rowData?.find && rowData.find(x => x.get('rowId') === 'blankrow')) {
        result = setIn(result, `fields.${propertyChanged}.isPending`, true)
      }
    }

    return result
  },
  [CONSTANTS.DELETE_VENDOR.SUCCESS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    result = setIn(result, 'fields.dataId.value', '')
    result = setIn(result, 'values.dataId', '')

    result = resetLedgerInputs(result)
    result = setIn(result, 'masterOptions.trackedTabs', Set())
    result = resetPreNewMode(result)
    result = setIn(result, 'editedFields', Set())
    return result
  },
  [CONSTANTS.CREATE_VENDOR.SUCCESS]: (state, action) => {
    let result = state

    result = setIn(result, 'isEditing', true)
    result = resetPreNewMode(result)
    result = handleStatusObject(result, action?.payload)

    /* clear out the tracked tabs so */
    result = removeTrackedTabsFromState(result)

    /* just in case, dump any templateKey */
    result = deleteIn(result, 'values.templateKey')
    return result
  },
  [CONSTANTS.CREATE_VENDOR.FAILURE]: (state, action) => {
    let result = state
    result = resetPreNewMode(result)
    result = setIn(result, 'editedFields', Set())
    return result
  },
  ...activityBehaviors,
  ...selectionCriteriaBehaviors,
  ...editableGridBehaviors,
  ...auditBehaviors,
  ...notesModalBehaviors,
  ...attachmentsBehaviors,
  ...templateBehaviors,
  ...corporateFieldUpdatesBehaviors(true)
}
