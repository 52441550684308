import React, { Component } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext, GridField } from 'ddiForm'
import Grid from 'grid'
import { BASE_INFORM_URL } from 'services/constants'
import { DDIToggle } from 'ddiForm/wrapped'
import {
  formatDateFields,
  formatDollarFields,
  fromJS,
  requestProcessingMessage,
  getField,
  getValue,
  emptyList
} from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  CardMedia,
  AppBar,
  Fab,
  Icon,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core'
import PivotTable from 'components/PivotTable'
import ReactHighcharts from 'react-highcharts'
import highchartsTheme from 'theme/highchartsTheme'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import * as actions from 'pages/ProductMaster/actions'
import { headerStyle, setGridHeight } from 'pages/ProductMaster/utils'
import { errorRed } from 'theme/colors'

import './styles/overview.scss'

const buttonLinkStyle = {
  boxShadow: 'none',
  color: '#517b9c',
  height: 24,
  minHeight: 24,
  width: '100%'
}

const buttonIconLinkStyle = { color: '#517b9c', fontSize: 16, marginRight: 3 }

const getRowNodeId = data => data.dataId

ReactHighcharts.Highcharts.setOptions(highchartsTheme)

export const dynamicColumnDefs = memoize(({ form }) => {
  const colDef = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellStyle: params =>
        params.data.dataId === 'FUTURE' ? { fontWeight: 'bold' } : null,
      field: 'description',
      form,
      headerName: 'Column',
      filter: false,
      suppressMenu: true
    },
    {
      cellClass: 'align-right',
      cellStyle: params => {
        if (params.data.dataId === 'FUTURE' && params.value < 0) {
          return {
            fontWeight: 'bold',
            color: 'red'
          }
        }
        if (params.value < 0) {
          return {
            fontWeight: 'normal',
            color: 'red'
          }
        }
        if (params.data.dataId === 'FUTURE') {
          return {
            fontWeight: 'bold'
          }
        }

        return null
      },
      field: 'factoredAmount',
      headerName: 'Amount',
      headerClass: 'align-right',
      filter: false,
      suppressMenu: true,
      valueFormatter: params => formatDollarFields(params, 4)
    },
    {
      cellStyle: params =>
        params.data.dataId === 'FUTURE' ? { fontWeight: 'bold' } : null,
      cellClass: 'text-center align-center',
      field: 'changedDate',
      headerName: 'Changed',
      headerClass: 'text-center align-center',
      filter: false,
      suppressMenu: true,
      valueFormatter: params => formatDateFields(params)
    }
  ]
  return colDef
})

// const inventoryCols = [
//   {
//     field: 'columnHeader',
//     pivot: true
//   },
//   {
//     field: 'onHand',
//     headerName: 'On Hand'
//   },
//   {
//     cellRendererFramework: DescriptionTooltipCell,
//     field: 'picked.value',
//     headerName: 'Picked'
//   },
//   {
//     cellStyle: params => (params.value < 0 ? { color: 'red' } : null),
//     field: 'onPurchaseOrder',
//     headerName: 'On P/O',
//     aggFunc: val => val
//   },
//   {
//     field: 'staged',
//     headerName: 'Staged',
//     aggFunc: val => val
//   },
//   {
//     field: 'onStockTransferOutAndPicked',
//     headerName: 'Trans Out/Picked',
//     aggFunc: val => val
//   },
//   {
//     field: 'onStockTransferInAndPicked',
//     headerName: 'Trans In/Picked',
//     aggFunc: val => val
//   },
//   {
//     field: 'onReturnGoods',
//     headerName: 'S/O Returns',
//     aggFunc: val => val
//   },
//   {
//     field: 'onWorkOrderKit',
//     headerName: 'On W/O Kit',
//     aggFunc: val => val
//   },
//   {
//     field: 'onWorkOrderComponent',
//     headerName: 'On W/O Comp',
//     aggFunc: val => val
//   },
//   {
//     field: 'rank',
//     headerName: 'Rank'
//     // pivot: true
//   },
//   {
//     field: 'quarterlyProjectedUsage',
//     headerName: 'Qrtly Proj Usage',
//     aggFunc: val => val
//   },
//   {
//     field: 'weeksAvailable',
//     headerName: 'Weeks Available',
//     aggFunc: val => val
//   },
//   {
//     field: 'reOrderPoint',
//     headerName: 'Reorder Point',
//     aggFunc: val => val
//   },
//   {
//     field: 'stockValue',
//     headerName: 'Stock Value'
//   },
//   {
//     field: 'primaryBin',
//     headerName: 'Primary Bin',
//     aggFunc: val => val
//   }
// ]
// const inventoryCols = [
//   {
//     field: 'columnHeader',
//     pivot: true,
//   },
//   {
//     field: 'onHand',
//     headerName: 'On Hand',
//   },
//   {
//     cellRendererFramework: DescriptionTooltipCell,
//     field: 'picked.value',
//     headerName: 'Picked',
//   },
//   {
//     cellStyle: params => (params.value < 0 ? { color: 'red' } : null),
//     field: 'onPurchaseOrder',
//     headerName: 'On P/O',
//     pivot: true,
//   },
//   {
//     field: 'staged',
//     headerName: 'Staged',
//     pivot: true,
//   },
//   {
//     field: 'onStockTransferOutAndPicked',
//     headerName: 'Trans Out/Picked',
//     pivot: true,
//   },
//   {
//     field: 'onStockTransferInAndPicked',
//     headerName: 'Trans In/Picked',
//     pivot: true,
//   },
//   {
//     field: 'onReturnGoods',
//     headerName: 'S/O Returns',
//     pivot: true,
//   },
//   {
//     field: 'onWorkOrderKit',
//     headerName: 'On W/O Kit',
//     pivot: true,
//   },
//   {
//     field: 'onWorkOrderComponent',
//     headerName: 'On W/O Comp',
//     pivot: true,
//   },
//   {
//     field: 'rank',
//     headerName: 'Rank',
//     pivot: true
//   },
//   {
//     field: 'quarterlyProjectedUsage',
//     headerName: 'Qrtly Proj Usage',
//     pivot: true,
//   },
//   {
//     field: 'weeksAvailable',
//     headerName: 'Weeks Available',
//     pivot: true,
//   },
//   {
//     field: 'reOrderPoint',
//     headerName: 'Reorder Point',
//     pivot: true,
//   },
//   {
//     field: 'stockValue',
//     headerName: 'Stock Value',
//   },
//   {
//     field: 'primaryBin',
//     headerName: 'Primary Bin',
//     pivot: true,
//   }
// ]
export class Overview extends Component {
  static propTypes = {
    allWarehouses48MonthDemand: PropTypes.array.isRequired,
    base64String: PropTypes.string,
    boxQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    costPriceRowData: PropTypes.array.isRequired,
    currentWarehouse48MonthDemand: PropTypes.array.isRequired,
    // dataId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    // groups: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    imageUrl: PropTypes.string,
    leadDays: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    sds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    volume: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }

  static defaultProps = {
    base64String: '',
    buyLine: '',
    imageUrl: '',
    priceGroup: '',
    primaryVendor: '',
    productLine: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: 'Current'
    }
  }

  handleTabChange = (e, val) => this.setState({ tab: val })

  handleOpenReport = e => {
    const { dispatch, form } = this.props
    e.preventDefault()

    // if (sdsLink && sdsLink.base64String) {
    //   dispatch(
    //     actions.openReportViewer.try(form, { document: sdsLink.base64String })
    //   )
    // }
    dispatch(actions.getSDS.try(form))
  }

  handleOpenDocSpec = e => {
    const { dispatch, form } = this.props
    e.preventDefault()

    dispatch(actions.getDocSpec.try(form))
  }

  handleOpenPurchaseHistory = e => {
    const { dispatch, form } = this.props
    e.preventDefault()

    dispatch(actions.getPurchaseHistory.try(form))
  }

  barChartConfig = () => {
    const {
      allWarehouses48MonthDemand,
      currentWarehouse48MonthDemand
    } = this.props
    const { tab } = this.state

    const allChartData = allWarehouses48MonthDemand.reduce((acc, next) => {
      acc = acc.concat({
        name: next.get('months'),
        y: next.get('demand')
      })
      return acc
    }, [])

    const currentChartData = currentWarehouse48MonthDemand.reduce(
      (acc, next) => {
        acc = acc.concat({
          name: next.get('months'),
          y: next.get('demand')
        })
        return acc
      },
      []
    )

    const chartConfig = {
      chart: {
        type: 'column'
      },
      title: {
        text: '48 Month Demand'
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          depth: 25,
          pointPadding: 0
        }
      },
      series: [
        {
          colorByPoint: true,
          // colors: ['#8bbc21', '#1aadce', '#ffff07', '#fa8072'],
          data: tab === 'Current' ? currentChartData : allChartData
        }
      ],
      tooltip: { enabled: false },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        min: 0,
        title: {
          style: { 'font-size': 12 },
          text: 'Units'
        }
      }
    }

    return chartConfig
  }

  render() {
    const { tab } = this.state
    const {
      base64String,
      boxQuantity,
      costPriceRowData,
      fetchingAnalysisData,
      form,
      hasDocumentSpec,
      hasPurchaseHistoryLinks,
      hasSDS,
      height,
      imageUrl,
      imageExceptionMessage,
      isEditing,
      leadDays,
      sds,
      sdsLink,
      volume,
      weight,
      buyLine,
      priceGroup,
      primaryVendor,
      productLine
    } = this.props

    const imageSrc =
      base64String || imageUrl
        ? base64String || imageUrl
        : `${BASE_INFORM_URL}/resources/white/no_image.png`

    return (
      <div className="product-analysis-overview-layout-flex">
        <div className="three-col-flex-wrapper">
          <div className="three-col-flex">
            <DDICardWrapper
              title="Inventory"
              style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '30em'
              }}
              contentStyle={{ padding: 0 }}
            >
              {fetchingAnalysisData ? (
                requestProcessingMessage('Request Processing...', 450)
              ) : (
                <PivotTable
                  cellStyle={{ textAlign: 'right' }}
                  data={this.props.inventoryGrid.get('data').toJS()}
                  meta={this.props.inventoryGrid.get('meta').toJS()}
                  rowFormattedToDollar={['Stock Value', 'Whse Cst']}
                  headerStyle={{
                    padding: '10px 5px',
                    fontSize: 13,
                    lineHeight: '13px',
                    textAlign: 'right'
                  }}
                />
              )}
            </DDICardWrapper>
          </div>

          <div className="three-col-flex">
            <DDICardWrapper title="Groups" contentStyle={{ padding: 20 }}>
              {fetchingAnalysisData ? (
                requestProcessingMessage('Request Processing...', 120)
              ) : (
                <div className="group-data-wrapper">
                  <div className="two-col-flex-wrapper">
                    <div className="two-col-flex">Product Line:</div>
                    <div className="two-col-flex">
                      <div className="overflow-text">
                        <div title={productLine} className="strong-text">
                          {productLine}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two-col-flex-wrapper">
                    <div className="two-col-flex">Price Group:</div>
                    <div className="two-col-flex">
                      <div className="overflow-text">
                        <div title={priceGroup} className="strong-text">
                          {priceGroup}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two-col-flex-wrapper">
                    <div className="two-col-flex">Buy Line:</div>
                    <div className="two-col-flex">
                      <div className="overflow-text">
                        <div title={buyLine} className="strong-text">
                          {buyLine}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="two-col-flex-wrapper">
                    <div className="two-col-flex">Primary Vendor:</div>
                    <div className="two-col-flex">
                      <div className="overflow-text">
                        <div title={primaryVendor} className="strong-text">
                          {primaryVendor}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </DDICardWrapper>
            <DDICardWrapper
              title="Specifications"
              contentStyle={{ padding: 20 }}
            >
              {fetchingAnalysisData ? (
                requestProcessingMessage('Request Processing...', 140)
              ) : (
                <div className="specifications-data-wrapper">
                  <div className="two-col-flex-wrapper">
                    <div className="two-col-flex">
                      <div className="two-col-flex-wrapper">
                        <div className="two-col-flex">Lead Days:</div>
                        <div className="two-col-flex">
                          <span className="strong-text">{leadDays}</span>
                        </div>
                      </div>
                      <div className="two-col-flex-wrapper">
                        <div className="two-col-flex">Weight:</div>
                        <div className="two-col-flex">
                          <span className="strong-text">{weight}</span>
                        </div>
                      </div>
                      <div className="two-col-flex-wrapper">
                        <div className="two-col-flex">Volume:</div>
                        <div className="two-col-flex">
                          <span className="strong-text">{volume}</span>
                        </div>
                      </div>
                      <div className="two-col-flex-wrapper">
                        <div className="two-col-flex">Box Quantity: </div>
                        <div className="two-col-flex">
                          <span className="strong-text">{boxQuantity}</span>
                        </div>
                      </div>
                      <div className="two-col-flex-wrapper">
                        <div className="two-col-flex">SDS:</div>
                        <div className="two-col-flex">
                          <span className="strong-text">{sds}</span>
                        </div>
                      </div>
                    </div>
                    <div className="two-col-flex">
                      <DDIToggle
                        preventAutoDisable
                        disabled={isEditing}
                        // propertyName="overview.specifications.cycleCount"
                        propertyName="cycleCount"
                        label="Cycle Count"
                        wrapperStyle={{
                          clear: 'both',
                          float: 'right',
                          position: 'relative'
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </DDICardWrapper>
            <DDICardWrapper
              contentStyle={{ minHeight: 350, padding: 0 }}
              title="Price/Cost Columns"
            >
              {costPriceRowData &&
              costPriceRowData.size &&
              !fetchingAnalysisData ? (
                <Grid
                  // propertyName="prices"
                  getRowNodeId={getRowNodeId}
                  headerStyle={headerStyle}
                  columnDefs={dynamicColumnDefs({ form })}
                  rowData={costPriceRowData.toJS()}
                  height={setGridHeight(costPriceRowData.toJS())}
                  rowSelection="single"
                  width="100%"
                  fullWidth
                />
              ) : fetchingAnalysisData ? (
                requestProcessingMessage('Request Processing...', 380)
              ) : null}
            </DDICardWrapper>
          </div>

          <div className="three-col-flex">
            <DDICardWrapper
              title="Image"
              contentStyle={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: 10
              }}
            >
              {fetchingAnalysisData ? (
                requestProcessingMessage('Request Processing...', 250)
              ) : (
                <CardMedia
                  src={imageSrc}
                  className="image-style"
                  style={{ width: 425 }}
                >
                  <img
                    src={imageSrc}
                    style={{ maxWidth: '100%' }}
                    alt="text"
                    className="center-block"
                  />
                </CardMedia>
              )}
              {imageExceptionMessage ? (
                <p style={{ color: errorRed, textAlign: 'center' }}>
                  {imageExceptionMessage}
                </p>
              ) : null}
            </DDICardWrapper>
            {fetchingAnalysisData ? null : (
              <div className="clear">
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-around'
                  }}
                >
                  <span
                    style={{
                      margin: hasDocumentSpec ? '0 0 10px 0' : 0,
                      display: hasDocumentSpec ? 'block' : 'none'
                    }}
                  >
                    <Fab
                      // href={docspec.url}
                      variant="extended"
                      size="small"
                      target="_blank"
                      style={buttonLinkStyle}
                      onClick={this.handleOpenDocSpec}
                    >
                      <Icon style={buttonIconLinkStyle}>open_in_new</Icon>
                      Document/Specs
                    </Fab>
                  </span>
                  <span
                    style={{
                      margin: hasSDS ? '0 0 10px 0' : 0,
                      display: hasSDS ? 'block' : 'none'
                    }}
                  >
                    {hasSDS ? (
                      <Fab
                        onClick={this.handleOpenReport}
                        variant="extended"
                        size="small"
                        style={buttonLinkStyle}
                      >
                        <Icon style={buttonIconLinkStyle}>open_in_browser</Icon>
                        SDS
                      </Fab>
                    ) : (
                      <Fab
                        href={sdsLink && sdsLink.url ? sdsLink.url : ''}
                        variant="extended"
                        size="small"
                        target="_blank"
                        style={buttonLinkStyle}
                      >
                        <Icon style={buttonIconLinkStyle}>open_in_new</Icon>
                        SDS
                      </Fab>
                    )}
                  </span>
                  <span
                    style={{
                      margin: hasPurchaseHistoryLinks ? '0 0 10px 0' : 0,
                      display: hasPurchaseHistoryLinks ? 'block' : 'none'
                    }}
                  >
                    <Fab
                      onClick={this.handleOpenPurchaseHistory}
                      variant="extended"
                      size="small"
                      style={buttonLinkStyle}
                    >
                      <Icon style={buttonIconLinkStyle}>open_in_browser</Icon>
                      Purchase History Links
                    </Fab>
                  </span>
                </div>
              </div>
            )}
            {fetchingAnalysisData ? null : (
              <div>
                <AppBar position="static">
                  <Tabs onChange={this.handleTabChange} value={tab}>
                    <Tab value="Current" label="Current Warehouse" />
                    <Tab value="All" label="All Warehouses" />
                  </Tabs>
                </AppBar>
                <Paper>
                  <ReactHighcharts config={this.barChartConfig()} />
                </Paper>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  allWarehouses48MonthDemand: form =>
    getValue(form, 'overview.allWarehouses48MonthDemand', emptyList),
  base64String: form => getValue(form, 'overview.image.base64String', ''),
  boxQuantity: form =>
    getValue(form, 'overview.specifications.boxQuantity', ''),
  currentWarehouse48MonthDemand: form =>
    getValue(form, 'overview.currentWarehouse48MonthDemand', emptyList),
  dataId: form => getField(form, 'dataId', ''),
  fetchingAnalysisData: form => form.get('fetchingAnalysisData') || false,
  hasDocumentSpec: form => getValue(form, 'overview.hasDocumentSpec', false),
  hasPurchaseHistoryLinks: form =>
    getValue(form, 'overview.hasPurchaseHistoryLinks', false),
  hasRecord: form => form.get('hasRecord') || false,
  hasSDS: form => getValue(form, 'overview.hasSDS', false),
  leadDays: form => getValue(form, 'overview.specifications.leadDays', ''),
  sds: form => getValue(form, 'overview.specifications.sds', ''),
  sdsLink: form => getValue(form, 'sdsLink', null),
  weight: form => getValue(form, 'overview.specifications.weight', ''),
  volume: form => getValue(form, 'overview.specifications.volume', ''),
  inventoryGrid: form =>
    getValue(
      form,
      'overview.inventory',
      fromJS({ data: [], meta: { columns: [] } })
    ),
  imageUrl: form => getValue(form, 'overview.image.url', ''),
  imageExceptionMessage: form =>
    getValue(form, 'overview.image.exceptionMessage', ''),
  costPriceRowData: form => getValue(form, 'overview.priceCost', emptyList),
  priceGroup: form => getValue(form, 'overview.groups.priceGroupId', ''),
  buyLine: form => getValue(form, 'overview.groups.buyLineId', ''),
  productLine: form => getValue(form, 'overview.groups.productLineId', ''),
  primaryVendor: form => getValue(form, 'overview.groups.primaryVendorId', '')
}

export default withContext(contextObj)(Overview)
