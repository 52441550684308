import React from 'react'
import memoize from 'memoize-one'
import {
  getIn,
  setIn,
  formatNumber,
  is,
  toCamelCase,
  makeRowDataUnique,
  plainDeepEqual,
  fromJS,
  dateFilterComparator
} from 'utils'
import { Set } from 'immutable'
import masterStyles from 'theme/masterStyles'
import { Field, Label } from 'ddiForm'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'
import { handleEditableGridAPIData } from 'components/EditableGrid/utils'

export const addNumberFormat = value =>
  !is.integer(value) ? formatNumber(value, '0,0.0') : formatNumber(value, '0,0')

export function sizeDetailColumns(headerHeight = 0) {
  if (this.grid) {
    // const grid = this.grid.connectedGrid && this.grid.connectedGrid.current
    const grid =
      this.grid.connectedGrid && this.grid.connectedGrid.current
        ? this.grid.connectedGrid.current
        : this.grid
    if (grid) {
      const parentColumnState = grid.columnApi.getColumnState()
      grid.api.forEachDetailGridInfo(detailGridInfo => {
        const detailColumnState = detailGridInfo.columnApi.getColumnState()
        if (detailColumnState.length) {
          detailGridInfo.api.setHeaderHeight(headerHeight)
          for (let i = 0; i < detailColumnState.length; i++) {
            if (parentColumnState[i] && parentColumnState[i].width) {
              detailGridInfo.columnApi.setColumnWidth(
                detailColumnState[i].colId,
                parentColumnState[i].width
              )
            }
          }
        }
      })
    }
  }
}

export const expandableGridBaseConfig = {
  master: {
    animateRows: true,
    enableSorting: true,
    masterDetail: true
  },
  detail: {
    animateRows: true,
    enableColResize: false,
    enableSorting: true,
    suppressHorizontalScroll: true
  }
}

export const setGridHeight = (
  array = [],
  additionalHeight = 0,
  headerHeight = 32
) => array.length * 28 + (headerHeight + array.length) + additionalHeight

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const getFormField = (form, field) => {
  if (form.fields && form.fields[field] && form.fields[field].value) {
    return form.fields[field].value
  }

  return ''
}

export const getRowNodeId = data => data.rowId

export const updateEditedFields = (state, propertyName) => {
  let editedFields = getIn(state, 'editedFields') || Set()
  if (editedFields) {
    editedFields = editedFields.add(propertyName)
    state = setIn(state, 'editedFields', editedFields)
  }

  return state
}

export const getErrorMessages = errors =>
  errors.reduce((acc, next) => {
    if (next.message) {
      acc = acc.concat(`${next.message} \n`)
    }
    return acc
  }, '')

export const styles = {
  ...masterStyles,
  ddiLabelIconStyle: {
    marginRight: 5,
    verticalAlign: 'middle',
    fontSize: 16
  },
  inputText: { fontSize: 12 },
  label: {
    fontSize: 12,
    fontWeight: 500,
    margin: 0
  },
  labelColumn: { textAlign: 'right' },
  rowStyle: {
    alignItems: 'center',
    display: 'flex',
    margin: '10px 0'
  },
  paddedCol: { padding: '0 10' },
  searchRow: {
    ...masterStyles.searchRow,
    wrapper: {
      ...masterStyles.searchRow.wrapper,
      marginBottom: 15
    }
  },
  twoColInputsFlex: {
    ...masterStyles.twoColInputsFlex,
    wrapper: {
      ...masterStyles.twoColInputsFlex.wrapper,
      alignItems: 'center',
      marginBottom: 15
    }
  }
}

export const createSearchInputRow = (
  key,
  label,
  description,
  styleObj,
  validations = []
) => (
  <div style={styleObj.searchRow.wrapper} key={key}>
    <label htmlFor={key} style={styleObj.searchRow.label}>
      {label}
    </label>
    <div style={styleObj.searchRow.input}>
      <DDIIndexSearch
        propertyName={key}
        id={key}
        metaKey={key}
        fullWidth
        minWidth="100%"
        validate={validations}
      />
    </div>
    <div style={{ ...styleObj.searchRow.description, maxWidth: 200 }}>
      <Field
        component={Label}
        propertyName={description}
        labelStyle={{ width: '100%' }}
      />
    </div>
  </div>
)

export const vendorsEmptyRow = {
  isPrimary: false,
  dataId: null,
  description: '',
  partNumber: '',
  partNumberDescription: '',
  upc: '',
  boxQuantity: null,
  lastPONumber: '',
  lastPOQuantity: null,
  lastPOCost: null,
  lastPODate: null,
  nextPOCost: null,
  disableImport: false,
  disableForecast: false,
  cloudNew: true
}

export const padString = (s, width, char) => {
  if (typeof s !== 'string' || !s) {
    return s
  }

  if (s.length === width) {
    return s
  }
  if (s.length < width) {
    return (new Array(width).join(char) + s).slice(-width)
  }
  if (s.length > width) {
    return s.substring(0, width)
  }

  return s
}

export const isValidURL = value => {
  const regEx = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/
  const res = value ? value.match(regEx) : null
  if (res === null) return false
  return true
}

export const getTooltip = params => {
  if (params && params.data && params.data.tooltip) {
    return params.data.tooltip
  }
  return null
}

export const getValueCellStyle = params => {
  if (params && params.value && params.value < 0) {
    return {
      color: 'red',
      textAlign: 'right'
    }
  }

  return { textAlign: 'right' }
}

export const mapBinsResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  // NEEDS update for groupNames?
  if (response) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      acc[next.toLowerCase()] = response[next]
      return acc
    }, {})
    const tabComponents = getIn(formState, 'masterOptions.tabComponents')
    const secondaryTab = getIn(tabComponents, `${tabIds[0]}-${tabIds[1]}`)
    if (secondaryTab && !getIn(secondaryTab, 'access')) {
      tabIds = tabIds.slice(0, 1)
    }
    tabIds.forEach(tab => {
      // const t = toCamelCase(tab)
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse[t]
      delete tempResponse[t]
      if (selectedTabData) {
        response = {
          ...response,
          ...selectedTabData
        }
      }
    })
  }
  response.groupNames = tabIds
  return response
}

export const mapAnalysisResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  if (response) {
    const tab = tabIds.length ? tabIds[1] : ''

    if (tab === 'overview') {
      const { cycleCount } = response.analysis.overview.specifications

      response = {
        ...response,
        ...response.analysis,
        cycleCount
      }
    } else if (tab === 'transactions') {
      const allWarehouseTransactions = makeRowDataUnique(
        response.analysis.transactions.allWarehouseTransactions,
        'dataId'
      )
      const currentWarehouseTransactions = makeRowDataUnique(
        response.analysis.transactions.currentWarehouseTransactions,
        'dataId'
      )

      response = {
        ...response,
        ...response.analysis[tab],
        allWarehouseTransactions,
        currentWarehouseTransactions
      }
      // debugger
    } else {
      response = {
        ...response,
        ...response.analysis
      }
    }
  }

  return response
}

export const mapAnalysisResponseWithData = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  if (response) {
    const tab = tabIds.length ? tabIds[1] : ''

    response = {
      ...response,
      ...response.analysis
    }
  }

  return response
}

export const mapAnalysisTabNames = (tabName = '') => {
  const tabs = {
    bins: 'productBins',
    substitutes: 'productSubstitutes',
    vendors: 'productVendors'
  }

  if (tabs[tabName]) {
    return tabs[tabName]
  }

  return tabName
}

export const mapDataResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  const nested = [...tabIds, ...groupNames]
  const requireRowIdsForGridEditing = [
    'components',
    'keywords',
    'accessoryGroups',
    'getPartNumberManagerItems',
    'warehouseProcurements',
    'replenishments',
    'secondaryPriceGroups',
    'substitutes',
    'vendors',
    'webDocuments',
    'rollUps',
    'unitOfMeasures'
  ]

  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (
        nested.includes(next) &&
        response[next] !== null &&
        typeof response[next] === 'object' &&
        Object.keys(response[next]).length
      ) {
        for (const prop in response[next]) {
          if (prop === 'customFieldsData') {
            acc = mapCustomFieldsData(acc, response[next][prop])
          }

          acc[prop] = requireRowIdsForGridEditing.includes(prop)
            ? handleEditableGridAPIData(formState, response, next, prop)
            : response[next][prop]
        }
        // debugger
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  // console.log(res)
  // debugger

  return res
}

export const ensureGridInitialization = (
  state,
  propertyName,
  rowData = [],
  emptyRow = {}
) => {
  state = setIn(state, `fields.${propertyName}.grid`, true)

  if (rowData && Array.isArray(rowData)) {
    state = setIn(state, `fields.${propertyName}.rowData`, fromJS(rowData))
    state = setIn(state, `values.${propertyName}`, fromJS(rowData))
  }

  if (
    emptyRow &&
    typeof emptyRow === 'object' &&
    emptyRow !== null &&
    Object.keys(emptyRow).length
  ) {
    state = setIn(state, `fields.${propertyName}.emptyRow`, fromJS(emptyRow))
  }

  return state
}

export const validateAndConvertUrl = (url = '') => {
  if (url.indexOf('http') !== 0 && url.indexOf('www.') !== 0) {
    return `http://www.${url}`
  }

  if (url.indexOf('http') !== 0) {
    return `http://${url}`
  }

  return url
}

export const resetMasterFieldsHelper = result => {
  result = setIn(result, 'fields.prices', fromJS({}))
  result = setIn(result, 'fields.purchasing', fromJS({}))
  result = setIn(result, 'fields.vendors', fromJS({}))
  result = setIn(result, 'fields.bins', fromJS({}))
  result = setIn(result, 'fields.analysisBins', fromJS({}))
  // result = setIn(result, 'fields.dataId.value', null)

  /* clear out these Analysis fields too */
  /* these are for charts */
  result = setIn(result, 'fields.allWarehouses48MonthDemand', fromJS({}))
  result = setIn(result, 'fields.currentWarehouse48MonthDemand', fromJS({}))
  result = setIn(result, 'fields.analysisVendors', fromJS({}))
  result = setIn(result, 'hasAnalysisData', false)

  /* clear the edited fields too */
  result = setIn(result, 'editedFields', Set())

  return result
}

export const setSelectedOptionGroup = (result, accessoryGroups = []) => {
  if (accessoryGroups[0].accessories) {
    result = setIn(
      result,
      'values.selectedGroup.title',
      accessoryGroups[0].dataId
    )
    result = setIn(
      result,
      'values.selectedGroup.accessories',
      fromJS(accessoryGroups[0].accessories)
    )
  }

  return result
}

export const updateAnalysisAssembly = (state, data) => {
  state = setIn(
    state,
    'fields.assembly.components.rowData',
    fromJS(data.components)
  )
  state = setIn(state, 'values.assembly.components', fromJS(data.components))

  state = setIn(
    state,
    'fields.assembly.printOnSalesOrder.value',
    data.printOnSalesOrder
  )
  state = setIn(
    state,
    'values.assembly.printOnSalesOrder',
    data.printOnSalesOrder
  )

  state = setIn(state, 'fields.assembly.rollupOption.value', data.rollupOption)
  state = setIn(state, 'values.assembly.rollupOption', data.rollupOption)

  state = setIn(
    state,
    'fields.assembly.kitProduction.value',
    data.kitProduction
  )
  state = setIn(state, 'values.assembly.kitProduction', data.kitProduction)

  return state
}

export const updateAnalysisDemand = (state, data) => {
  for (const prop in data) {
    state = setIn(state, `values.demand.${prop}`, fromJS(data[prop]))
  }

  return state
}

export const updateAnalysisSerialNumbers = (state, data) => {
  state = setIn(
    state,
    'values.serialNumbers.canUpdateSerialNumbers',
    data.canUpdateSerialNumbers
  )
  state = setIn(state, 'values.serialNumbers.data', fromJS(data.data))
  state = setIn(
    state,
    'values.serialNumbers.meta.columns',
    fromJS(data.meta.columns)
  )

  return state
}

export const updateAnalysisOverview = (state, data) => {
  const priceData = makeRowDataUnique(data.priceCost, 'rowId')
  /* Cost/Price data */
  state = setIn(state, 'fields.prices.rowData', fromJS(priceData))
  state = setIn(state, 'values.prices', fromJS(priceData))
  state = setIn(state, 'values.overview.priceCost', fromJS(priceData))
  state = setIn(state, 'fields.prices.grid', true)

  state = setIn(state, 'values.overview.inventory', fromJS(data.inventory))
  state = setIn(
    state,
    'values.overview.allWarehouses48MonthDemand',
    fromJS(data.allWarehouses48MonthDemand)
  )
  state = setIn(
    state,
    'values.overview.currentWarehouse48MonthDemand',
    fromJS(data.currentWarehouse48MonthDemand)
  )
  state = setIn(state, 'values.overview.hasDocumentSpec', data.hasDocumentSpec)
  state = setIn(
    state,
    'values.overview.hasPurchaseHistoryLinks',
    data.hasPurchaseHistoryLinks
  )

  /* Groups */
  if (data?.groups) {
    for (const prop in data.groups) {
      state = setIn(state, `values.overview.groups.${prop}`, data.groups[prop])
    }
  }

  if (data?.specifications) {
    for (const prop in data.specifications) {
      state = setIn(
        state,
        `values.overview.specifications.${prop}`,
        data.specifications[prop]
      )
    }
  }

  /* Cycle Count */
  state = setIn(
    state,
    'fields.cycleCount.value',
    data.specifications.cycleCount
  )
  state = setIn(state, 'values.cycleCount', data.specifications.cycleCount)

  /* Image Data */
  state = setIn(
    state,
    'values.overview.image.base64String',
    data.image.base64String
  )
  state = setIn(state, 'values.overview.image.url', data.image.url)

  if (data?.image?.exceptionMessage) {
    state = setIn(
      state,
      'values.overview.image.exceptionMessage',
      data.image.exceptionMessage
    )
  }

  return state
}

export const updateAnalysisLots = (state, data) => {
  for (const prop in data) {
    state = setIn(state, `values.lots.${prop}`, fromJS(data[prop]))
  }

  return state
}

export const handleAnalyisUpdateHelper = (state, payload) => {
  /* Analysis > Overview */
  if (payload?.overview || payload?.analysis?.overview) {
    const overview = payload.overview
      ? payload.overview
      : payload.analysis.overview
    state = updateAnalysisOverview(state, overview)
  }

  /* Analysis Assembly */
  if (payload?.assembly || payload?.analysis?.assembly) {
    const assemblyData = payload.assembly
      ? payload.assembly
      : payload.analysis.assembly
    state = updateAnalysisAssembly(state, assemblyData)
  }

  /* Demand */
  if (payload?.demand || payload?.analysis?.demand) {
    const demandData = payload.demand ? payload.demand : payload.analysis.demand
    state = updateAnalysisDemand(state, demandData)
  }

  /* Serial Numbers */
  if (payload?.serialNumbers || payload?.analysis?.serialNumbers) {
    const serialNumbers = payload.serialNumbers
      ? payload.serialNumbers
      : payload.analysis.serialNumbers
    state = updateAnalysisSerialNumbers(state, serialNumbers)
  }

  /* Substitutes */
  if (payload?.productSubstitutes || payload?.analysis?.productSubstitutes) {
    const productSubstitutes = payload?.productSubstitutes
      ? payload.productSubstitutes
      : payload.analysis.productSubstitutes
    state = setIn(
      state,
      'fields.productSubstitutes.rowData',
      fromJS(productSubstitutes)
    )
    state = setIn(
      state,
      'values.productSubstitutes',
      fromJS(productSubstitutes)
    )
  }

  /* Work Orders */
  if (payload?.workOrders || payload?.analysis?.workOrders) {
    const workOrders = payload?.workOrders
      ? payload.workOrders
      : payload.analysis.workOrders
    state = setIn(state, 'fields.workOrders.rowData', fromJS(workOrders))
    state = setIn(state, 'values.workOrders', fromJS(workOrders))
  }

  /* Lots */
  if (payload?.lots || payload?.analysis?.lots) {
    const lots = payload.lots ? payload.lots : payload.analysis.lots
    state = updateAnalysisLots(state, lots)
  }

  /* Transactions */
  if (
    (payload?.analysis?.transactions?.allWarehouseTransactions &&
      payload?.analysis?.transactions?.currentWarehouseTransactions) ||
    (payload?.transactions?.allWarehouseTransactions &&
      payload?.transactions?.currentWarehouseTransactions)
  ) {
    const transactions =
      payload?.analysis?.transactions?.allWarehouseTransactions &&
      payload?.analysis?.transactions?.currentWarehouseTransactions
        ? payload.analysis.transactions
        : payload.transactions

    state = setIn(
      state,
      'values.transactions.allWarehouseTransactions',
      fromJS(makeRowDataUnique(transactions.allWarehouseTransactions, 'dataId'))
    )
    state = setIn(
      state,
      'values.transactions.currentWarehouseTransactions',
      fromJS(
        makeRowDataUnique(transactions.currentWarehouseTransactions, 'dataId')
      )
    )

    if (transactions?.selectedYear) {
      state = setIn(
        state,
        'values.transactions.selectedYear',
        transactions.selectedYear
      )

      state = setIn(
        state,
        'fields.selectedYear.value',
        transactions.selectedYear
      )
    }
  }

  return state
}

export const setUpPrimaryGrids = (
  state,
  payload,
  isCancellationAction = false
) => {
  const editedFields = getIn(state, 'editedFields') || Set()

  if (payload.prices && Array.isArray(payload.prices)) {
    const prices = makeRowDataUnique(payload.prices, 'rowId')
    state = ensureGridInitialization(state, 'prices', prices)
  }

  if (payload.rollUps && Array.isArray(payload.rollUps)) {
    const rollUps = makeRowDataUnique(payload.rollUps, 'rowId')
    state = ensureGridInitialization(state, 'rollUps', rollUps)
  }

  /* same for vendors */
  if (payload.vendors && Array.isArray(payload.vendors)) {
    const vendors = makeRowDataUnique(payload.vendors, 'rowId')
    state = ensureGridInitialization(state, 'vendors', vendors, vendorsEmptyRow)
  }

  if (
    payload.unitOfMeasures &&
    Array.isArray(payload.unitOfMeasures) &&
    (!editedFields.has('unitOfMeasures') || isCancellationAction)
  ) {
    const unitOfMeasures = makeRowDataUnique(payload.unitOfMeasures, 'rowId')
    state = ensureGridInitialization(state, 'unitOfMeasures', unitOfMeasures, {
      dataId: null,
      description: '',
      priorPackQuantity: 1,
      baseUOMId: '',
      priceUOMId: '',
      markup: '',
      upc: '',
      disableSales: false
    })
  }

  if (
    payload.webDocuments &&
    Array.isArray(payload.webDocuments) &&
    (!editedFields.has('webDocuments') || isCancellationAction)
  ) {
    const webDocuments = makeRowDataUnique(payload.webDocuments, 'rowId')
    state = ensureGridInitialization(state, 'webDocuments', webDocuments, {
      dataId: null,
      path: '',
      isURL: false
    })
  }

  return state
}

export const removeTrackedTabsFromState = state => {
  const selectedPrimaryTab = getIn(state, 'masterOptions.selectedPrimaryTab')
  const selectedSecondaryTab = getIn(
    state,
    'masterOptions.selectedSecondaryTab'
  )
  let trackedTabs = Set()

  if (selectedPrimaryTab) {
    trackedTabs = trackedTabs.add(selectedPrimaryTab)
  }

  if (selectedSecondaryTab) {
    trackedTabs = trackedTabs.add(selectedSecondaryTab)
  }

  state = setIn(state, 'masterOptions.trackedTabs', trackedTabs)
  return state
}

export const resetPatternTypeModal = state => {
  state = setIn(state, 'fields.referenceId.value', '')
  state = setIn(state, 'values.referenceId', '')
  state = setIn(state, 'values.referenceDescription', '')
  state = setIn(state, 'fields.referenceId.description', '')
  state = setIn(state, 'fields.patternSetDataId.value', '')
  state = setIn(state, 'values.patternSetDataId', '')
  return state
}

export const getInventoryNames = memoize((inventoryNames, tab = null) => {
  const data =
    inventoryNames && inventoryNames?.toJS ? inventoryNames.toJS() : null
  let tabs = ['all']
  if (data && Array.isArray(data)) {
    tabs = data.reduce((acc, next, idx) => {
      if (idx === 0 && next?.dataId) {
        acc = acc.concat(next.dataId)
      }
      return acc
    }, [])
  }

  if (tab) {
    tabs = [...Set(tabs).toJS(), tab]
  }

  return tabs
}, plainDeepEqual)

export const getInventoryTabId = memoize((inventoryNames, tab) => {
  const inventoryTabId = tab
    ? inventoryNames?.find(x => x?.get('description') === tab)?.get('dataId')
    : null

  return inventoryTabId
}, plainDeepEqual)

export const dateFilterGridConfigs = {
  filter: 'agDateColumnFilter',
  filterParams: {
    clearButton: true,
    comparator: dateFilterComparator,
    inRangeInclusive: true,
    suppressAndOrCondition: true,
    browserDatePicker: true
  }
}

export const numberFilterGridConfigs = {
  filter: 'agNumberColumnFilter',
  filterParams: {
    clearButton: true,
    inRangeInclusive: true,
    suppressAndOrCondition: true
  }
}
