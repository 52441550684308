import { call, fork, put, select, take, delay } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { getIn, is } from 'utils'
import { addModal, removeModal } from 'modals/actions'
import BackorderCommitmentScheduleModal from '../components/BackorderCommitmentScheduleModal'
import SwapCommittedQuantitiesModal from '../components/SwapCommittedQuantitiesModal'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { changeGridItem as changeGridItemAPI } from '../api'

export function* displayBackorderCommitmentsModal(form, data = {}, lineNumber) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  const modalOpts = {
    component: BackorderCommitmentScheduleModal,
    options: {
      actions: false,
      title: 'Backorder Commitment Schedule',
      data: {
        form,
        lineNumber,
        isEditing,
        ...data
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 500
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* handleBackorderCommitmentProcess(
  form,
  thunk,
  apiParams = {},
  modalId = null,
  gridApi = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const groupNames = ['header', 'detail']

  yield put(actions.handleBackorderCommitmentSchedule.request(form))
  // debugger

  const { response, error } = yield call(changeGridItemAPI, {
    guid,
    groupNames,
    gridName: 'bocommitmentlineitem',
    ...apiParams
  })

  if (response) {
    yield put({
      type: CONSTANTS.HANDLE_BACKORDER_COMMITMENT_SCHEDULE.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        action: apiParams?.action,
        activeLineNumber: apiParams?.lineNumber
      }
    })

    if (apiParams?.action === 'initialize') {
      yield fork(
        displayBackorderCommitmentsModal,
        form,
        response,
        apiParams?.lineNumber
      )
    }

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    debugger
    yield put({
      type: CONSTANTS.HANDLE_BACKORDER_COMMITMENT_SCHEDULE.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error,
        propertyChanged: 'backorderCommitment'
      }
    })

    if (error.status !== 496) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* tryBeginEditingFocusedCell(gridApi = null) {
  if (gridApi && gridApi?.getFocusedCell) {
    const focusedCell = gridApi.getFocusedCell()
    const rowIndex = focusedCell?.rowIndex
    const colId = focusedCell?.column?.colId || ''
    if (is.number(rowIndex)) {
      yield delay(100)
      gridApi.startEditingCell({
        rowIndex,
        colKey: colId
      })
    }
  }
}

export function* handleBackorderCommitmentScheduleListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { apiParams = {}, modalId = null, gridApi = null }
    } = yield take(CONSTANTS.HANDLE_BACKORDER_COMMITMENT_SCHEDULE.TRY)

    if (form === formListener) {
      // debugger
      yield fork(
        handleBackorderCommitmentProcess,
        form,
        thunk,
        apiParams,
        modalId,
        gridApi
      )
    }
  }
}

export function* displaySwapCommittedQuantitiesModal(
  form,
  data = {},
  lineNumber
) {
  const modalOpts = {
    component: SwapCommittedQuantitiesModal,
    options: {
      actions: false,
      modalOverrideClass: 'swap-committed-quantities-modal',
      title: 'Swap Committed Quantities',
      data: {
        form,
        lineNumber,
        ...data
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 940
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* handleSwapCommittedQuantitiesProcess(
  form,
  thunk,
  apiParams = {},
  modalId = null,
  gridApi = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const groupNames = ['header', 'detail']

  yield put(actions.handleSwapCommittedQuantitiesInteraction.request(form))
  // debugger

  const { response, error } = yield call(changeGridItemAPI, {
    guid,
    groupNames,
    gridName: 'swappableorders',
    ...apiParams
  })

  if (response) {
    yield put({
      type: CONSTANTS.HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        action: apiParams?.action,
        activeLineNumber: apiParams?.lineNumber
      }
    })

    if (apiParams?.action === 'initialize') {
      yield fork(
        displaySwapCommittedQuantitiesModal,
        form,
        response,
        apiParams?.lineNumber
      )
    }

    if (gridApi) {
      yield fork(tryBeginEditingFocusedCell, gridApi)
    }

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put({
      type: CONSTANTS.HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error,
        propertyChanged: 'swappedQuantities'
      }
    })

    if (error.status !== 496) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* handleSwapCommittedQuantitiesInteractionListener(
  formListener
) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { apiParams = {}, modalId = null, gridApi = null }
    } = yield take(CONSTANTS.HANDLE_SWAP_COMMITTED_QUANTITIES_INTERACTION.TRY)

    if (form === formListener) {
      // debugger
      yield fork(
        handleSwapCommittedQuantitiesProcess,
        form,
        thunk,
        apiParams,
        modalId,
        gridApi
      )
    }
  }
}

export default function* backorderCommitmentSagas(form) {
  yield fork(handleBackorderCommitmentScheduleListener, form)
  yield fork(handleSwapCommittedQuantitiesInteractionListener, form)
}
