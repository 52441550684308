import React from 'react'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import { emailAddress } from 'ddiForm/validations'
import { DDITextField } from 'ddiForm/wrapped'

const ContactInfo = ({ dataId, disableInputMask, pageStyles, customerId }) => (
  <DDICardWrapper title="Contact Information" contentStyle={{ padding: 10 }}>
    <div style={pageStyles.twoColFlex.wrapper}>
      <div style={pageStyles.twoColFlex.left}>
        <DDITextField
          propertyName="contactName"
          placeholder="Contact"
          margin="dense"
          label="Contact"
          fullWidth
          style={{ width: '100%' }}
        />
      </div>
      <div style={pageStyles.twoColFlex.right}>
        <DDITextField
          propertyName="email"
          placeholder="Email"
          margin="dense"
          label="Email"
          fullWidth
          style={{ width: '100%' }}
          mask="email"
          validate={emailAddress}
          linkToSendDocument
          sendDocumentArgs={{
            dataId,
            parentId: customerId,
            sendType: 'Email',
            parentType: 'C',
            childType: 'shipTo'
          }}
        />
      </div>
    </div>
    <div style={pageStyles.twoColFlex.wrapper}>
      <div style={pageStyles.twoColFlex.left}>
        <DDITextField
          propertyName="phone"
          placeholder="Phone"
          margin="dense"
          label="Phone"
          fullWidth
          style={{ width: '100%' }}
          disableMask={disableInputMask}
          mask="phoneNumber"
        />
      </div>
      <div style={pageStyles.twoColFlex.right}>
        <DDITextField
          propertyName="fax"
          placeholder="Fax"
          margin="dense"
          label="Fax"
          fullWidth
          style={{ width: '100%' }}
          disableMask={disableInputMask}
          mask="phoneNumber"
        />
      </div>
    </div>
  </DDICardWrapper>
)

ContactInfo.propTypes = {
  disableInputMask: PropTypes.bool.isRequired,
  pageStyles: PropTypes.object.isRequired
}

export default ContactInfo
