import React, { memo, useState } from 'react'
import { withContext } from 'ddiForm'
import { formatNumber, getValue, getMeta } from 'utils'
import { Paper } from '@material-ui/core'
import HighChart from 'components/charts/HighCharts'
import ProfitCard from './ProfitCard'

const GrossProfit = ({
  grossProfitAmount,
  grossProfitPercent,
  totalCost,
  totalMerchandise,
  totalAltCost,
  altCostGPPercent
}) => {
  const chartSeries = [
    {
      value: grossProfitAmount,
      type: 'Profit'
    },
    {
      value: totalCost,
      type: 'Cost'
    }
  ]

  return (
    <div style={{ display: 'flex' }}>
      <div>
        {grossProfitAmount || totalCost ? (
          <HighChart
            chartType="piechart"
            chartSeries={chartSeries}
            chartTitleText=""
            chartCategories={[]}
            dataLabelsEnabled={false}
            pieChartSecondaryLabel="Amount"
          />
        ) : (
          <div style={{ padding: 10 }}>
            <p style={{ fontSize: 12 }}>No chart data</p>
          </div>
        )}
      </div>
      <ProfitCard
        headline="Gross Profit"
        primaryData={
          grossProfitPercent || grossProfitPercent === 0
            ? `${grossProfitPercent}%`
            : ''
        }
        isPrimaryNegative={grossProfitPercent < 0}
        primaryDataSubtitle="Percent"
        secondaryData={formatNumber(grossProfitAmount)}
        isSecondaryNegative={grossProfitAmount < 0}
        secondaryDataSubtitle="Dollars"
      />
      <ProfitCard
        headline="Total"
        primaryData={formatNumber(totalCost)}
        isPrimaryNegative={totalCost < 0}
        primaryDataSubtitle="Cost"
        secondaryData={formatNumber(totalMerchandise)}
        isSecondaryNegative={totalMerchandise < 0}
        secondaryDataSubtitle="Merchandise Amount"
      />
      <ProfitCard
        headline="Alt Cost"
        primaryData={formatNumber(totalAltCost)}
        primaryDataSubtitle="Cost"
        secondaryData={
          altCostGPPercent || altCostGPPercent === 0
            ? `${altCostGPPercent}%`
            : ''
        }
        isPrimaryNegative={totalAltCost < 0}
        isSecondaryNegative={altCostGPPercent < 0}
        secondaryDataSubtitle="G/P Percent"
      />
    </div>
  )
}

/* should be OK just as is 9/25/2020 SVE */
const contextObj = {
  grossProfitAmount: form => getValue(form, 'grossProfitAmount', 0),
  grossProfitPercent: form => getValue(form, 'grossProfitPercent', 0),
  totalCost: form => getValue(form, 'totalCost', 0),
  totalMerchandise: form => getValue(form, 'totalMerchandise', 0),
  totalAltCost: form => getValue(form, 'totalAltCost', 0),
  altCostGPPercent: form => getValue(form, 'altCostGPPercent', 0)
}

export default memo(withContext(contextObj)(GrossProfit))
