/* eslint react/sort-comp: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import { withContext } from 'ddiForm'
import { debounce } from 'lodash'
import {
  DDITextField,
  DDIDatePicker,
  DDIIndexSearch,
  DDISalesOrderSearch,
  // DDISelectField
  DDIToggle
} from 'ddiForm/wrapped'
import {
  setSearchAreaExpansionPanelState,
  initializeDefaultToQuote,
  initializeFormTitle,
  cancelEditAfterClear
} from 'pages/SalesOrder/actions'
// import { tryChangeFormTab, changeFormTab } from 'ddiForm/actions'
import {
  getMeta,
  getField,
  getIn,
  getRgb,
  getValue,
  // plainDeepEqual,
  is,
  empty,
  fromJS
} from 'utils'
import { Button, Icon, IconButton, Popover } from '@material-ui/core'
import {
  getGoogleMapsURL,
  renderShipToAddress,
  validateSearchField
} from 'pages/SalesOrder/utils'
import {
  openScreen as openScreenAction
  // linkToScreen
} from 'pages/Main/actions'
import { exit } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import addShipToInModal from 'components/addShipToInModal'

import '../styles/search-area.scss'

// const styles = {
//   indexSearchRow: {
//     label: { fontSize: 12, fontWeight: 400, marginRight: 10, minWidth: 100, textAlign: 'right' },
//     inputWrapper: { width: 200 },
//     wrapper: { alignItems: 'center', display: 'flex', marginBottom: 15 }
//   }
// }

const fakeMetaData = {
  allowInvalidValues: true,
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: false,
  hasFilters: false,
  hideToggle: true,
  minimumKeywordLength: 0,
  openText: null
}

export class SearchArea extends Component {
  static propTypes = {
    customerId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      popoverIsOpen: false
    }
  }

  componentDidMount() {
    const { dispatch, form, openSOInQuoteMode, isQuote, dataId } = this.props
    // only fire this if the default value of quote is true
    // conflicts with starting quote from any screen
    // -- LL 2/20/2020
    if (openSOInQuoteMode) {
      dispatch(initializeDefaultToQuote(form, { value: openSOInQuoteMode }))
    }

    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     !plainDeepEqual(
  //       prevProps.validationMessages,
  //       this.props.validationMessages
  //     )
  //   ) {
  //     if (
  //       this.props.validationMessages &&
  //       this.props.validationMessages.length
  //     ) {
  //       const inputToFocus = this.props.validationMessages[0].key
  //       if (this[inputToFocus] && this[inputToFocus].focus) {
  //         /* programatically focusing on index search fields causes an error */
  //         /* need to work out a couple edge-case errors here before implementing this */
  //         // if (inputToFocus === 'shipDate' || inputToFocus === 'poNumber') {
  //         //   this[inputToFocus].focus()
  //         // }
  //       }
  //     }
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.modals && !this?.props?.modals) {
      /* 
        handle resetting focus on empty index 
        searches after modal interactions 
        -- SVE 4/4/2022
      */
      setTimeout(() => {
        const activeElement = document?.activeElement
        const focusedInputId = activeElement?.id
        const searchInputs = [
          'customerId',
          'shipToId',
          'orderedById',
          'shipViaId',
          'branchId',
          'warehouseId'
        ]

        if (focusedInputId && this._isMounted) {
          let emptySearchInput = false
          for (let i = 0; i < searchInputs?.length; i++) {
            const searchInput = searchInputs[i]
            if (
              activeElement === this?.[searchInput] &&
              !this?.props?.[searchInput]
            ) {
              emptySearchInput = searchInput
              break
            }
          }

          if (
            emptySearchInput &&
            this?.[emptySearchInput]?.blur &&
            this?.[emptySearchInput]?.focus
          ) {
            this?.[emptySearchInput]?.blur()
            this?.[emptySearchInput]?.focus()
          }
        }
      }, 1)
    }
  }

  setSearchAreaExpansionPanelState = ({ expanded }) => {
    const { form, dispatch } = this.props

    dispatch(setSearchAreaExpansionPanelState(form, { expanded }))
  }

  handleTouchTap = event => {
    event.preventDefault()

    this.setState({
      anchorEl: event.currentTarget,
      popoverIsOpen: true
    })
  }

  handleRequestClose = event => {
    event.preventDefault()
    this.setState({ popoverIsOpen: false })
  }

  openSalesOrderInquiry = (e, type) => {
    e.preventDefault()
    const { customerId, dispatch, salesOrderInquiryScreenOpen } = this.props

    const screenData =
      type === 'backOrders'
        ? {
            customer: customerId,
            status: 'B',
            name: 'salesOrderInquiry',
            title: 'Sales Order Inquiry'
          }
        : {
            customer: customerId,
            name: 'salesOrderInquiry',
            title: 'Sales Order Inquiry'
          }

    this.setState({ popoverIsOpen: false }, () => {
      if (salesOrderInquiryScreenOpen) {
        dispatch(exit('salesOrderInquiry'))
      }
      dispatch(openScreenAction(screenData))
    })
  }

  onSalesOrderChange = () => {
    const { dispatch, form } = this.props
    if (this.props.isEditing) {
      this.props.cancelEdit()
    } else if (this.props.hasRecord) {
      this.props.clearOrder()
      dispatch(cancelEditAfterClear.try(form))
    }
  }

  onSalesOrderFocus = e => {
    if (this.props.isEditing && this.props.dataId) {
      if (this?.dataId?.blur) {
        this.dataId.blur()
      }
      this.props.cancelEdit()
    }
  }

  onInvoiceClick = debounce(() => {
    const screenData = {
      dataId: this.props.lastInvoiceId,
      name: 'invoiceInquiry',
      image: 'sales_invoicing_invoice_inquiry_16.png',
      route: 'invoiceinquiry',
      title: 'Invoice Inquiry',
      groupNames: ['header', 'detail']
    }

    this.props.dispatch(openScreenAction(screenData))
  }, 500)

  getLastInvoiceLink = () => {
    const { isInBilling, lastInvoiceId } = this.props

    if (isInBilling) {
      return <span style={{ color: '#444' }}>{lastInvoiceId}</span>
    }

    return <a onClick={this.onInvoiceClick}>{lastInvoiceId}</a>
  }

  getCustomContextMenuItems = type => {
    // const { orderedById, orderedByName } = this.props

    const screensMap = {
      contact: [
        {
          text: 'Open Contact Master',
          enabled: !!this.props.orderedById,
          // disable: true,
          onClick: this.openContactMaster
        }
      ],
      customer: [
        {
          text: 'Open Customer Master',
          enabled: !!this.props.customerId,
          disable: true,
          onClick: this.openCustomerMaster
        }
      ],
      shipTo: [
        {
          text: 'Open Ship To Master',
          enabled: !!this.props.shipToId,
          onClick: this.openShipToMaster
        }
      ]
    }

    let items

    if (type) {
      items = screensMap[type]
    }

    return items
  }

  openShipToMaster = params => {
    this.props.dispatch(
      addShipToInModal(
        {
          dataId: this.props.shipToId,
          name: 'customerShipTo',
          title: 'Customer Ship To',
          parentId: this.props.customerId,
          groupNames: ['setup']
        },
        this.props.form
      )
    )
  }

  openContactMaster = () => {
    const { dispatch, customerId, orderedById, orderedByName } = this.props

    if (!customerId && !orderedById) {
      return
    }

    dispatch(
      openScreenAction({
        image: 'default-inform-icon-16.png',
        dataId: orderedById,
        name: 'contact master',
        title: `Contact - ${orderedById} "${orderedByName}"`,
        parentType: 'C',
        parentId: customerId,
        groupNames: ['setup']
      })
    )
  }

  openCustomerMaster = () => {
    const { dispatch, customerId, customerName } = this.props

    if (!customerId) {
      return
    }

    dispatch(
      openScreenAction({
        dataId: customerId,
        image: 'accounting_accounts_receivable_customer_ledger_16.png',
        route: 'customermaster',
        activeKey: 'ledger',
        title: `Customer - ${customerId} "${customerName}"`,
        groupNames: ['invoices', 'summary']
      })
    )
  }

  render() {
    const {
      canAddContact,
      canCreateFastCustomer,
      customerName,
      branchDescription,
      searchAreaExpanded,
      canChangeQuote,
      customerId,
      customerStatus,
      customerStatusColorMap,
      currentStatusColorMap,
      currentStatusDescriptionResolved,
      dataId,
      lastInvoiceId,
      requirePasswordForWriter,
      shipDate,
      shipToEmail,
      shipToFax,
      shipToDescription,
      shipToPhone,
      shipToAddressMap,
      shipViaDescription,
      warehouseDescription,
      writerDescription,
      shipToMetaMap,
      orderedByMetaMap,
      openOrdersExist,
      backOrdersExist,
      hasRecord,
      isEditing,
      validPatterns,
      status,
      statusTooltip,
      orderedByName,
      touchSearchArea,
      openSOInQuoteMode,
      customerMetaMap
    } = this.props
    //
    let { lineItems } = this.props
    lineItems = lineItems?.toJS ? lineItems.toJS() : []
    const hasProductsLoadedInEditMode =
      lineItems?.some(x => x?.dataId && x?.rowId !== 'blankrow') && isEditing

    const { anchorEl, popoverIsOpen } = this.state

    /* updated logic here to account for new & existing orders -- SVE 9/26/2019 */
    const hasOrderWithoutEdit = !dataId
      ? !customerId && !dataId
      : !!(!isEditing && dataId)
    // const customerIdDisabled = (dataId && isEditing) || false
    // console.log('searchArea', this.props, hasOrderWithoutEdit)

    const allowSearchAll = !!customerId && hasRecord

    const currentStatusColor = currentStatusColorMap?.toJS
      ? getRgb(currentStatusColorMap.toJS(), 0)
      : '#444'
    const customerStatusColor = customerStatusColorMap?.toJS
      ? getRgb(customerStatusColorMap.toJS(), 0)
      : '#444'

    const shipToAddress = shipToAddressMap?.toJS
      ? shipToAddressMap.toJS()
      : { address1: '', address2: '', cityStateZip: '' }
    const { address1 = '', address2 = '', cityStateZip = '' } = shipToAddress

    const shipToMeta = shipToMetaMap?.toJS ? shipToMetaMap?.toJS() : {}
    const orderedByMeta = orderedByMetaMap?.toJS ? orderedByMetaMap?.toJS() : {}
    const customerMeta = customerMetaMap?.toJS ? customerMetaMap?.toJS() : {}

    return (
      <div className="sales-order-search-area-wrapper">
        <DDIExpansionPanel
          defaultExpanded={searchAreaExpanded}
          title="Sales Order Entry"
          titleIcon="shopping_cart"
          expansionPanelContentStyle={{
            padding: 0,
            width: '100%'
          }}
          changeCallback={this.setSearchAreaExpansionPanelState}
          style={{ border: 0 }}
        >
          <div className="flex-wrapper">
            <div className="inner-flex-wrapper">
              <div className="left-searches">
                {customerStatus ? (
                  <>
                    <IconButton
                      onClick={this.handleTouchTap}
                      style={{
                        background: '#e9e9e9',
                        position: 'absolute',
                        left: 0,
                        top: -2,
                        padding: 6
                      }}
                    >
                      <Icon>info</Icon>
                    </IconButton>
                    <Popover
                      open={popoverIsOpen}
                      anchorEl={anchorEl}
                      onClose={this.handleRequestClose}
                      className="sales-order-entry-customer-popover"
                    >
                      <div className="popover-wrapper">
                        <h2 className="popover-title">{customerName}</h2>
                        {address1 ? (
                          <IconButton
                            style={{ marginRight: 10, padding: 6 }}
                            onClick={e =>
                              window.open(
                                getGoogleMapsURL(
                                  address1,
                                  address2,
                                  cityStateZip
                                ),
                                '_blank'
                              )
                            }
                          >
                            <Icon>location_on</Icon>
                          </IconButton>
                        ) : null}
                        <div className="width-150">
                          <div
                            style={{
                              background: customerStatusColor,
                              borderRadius: 4,
                              color: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: 27,
                              fontSize: 12,
                              flexDirection: 'row',
                              width: '100%'
                            }}
                          >
                            {customerStatus}
                          </div>
                        </div>
                      </div>
                      <div className="padding-20">
                        {renderShipToAddress(
                          shipToAddress,
                          shipToPhone,
                          customerId,
                          shipToEmail,
                          shipToFax
                        )}
                      </div>
                      <div
                        style={{
                          background: '#f5f7f7',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          padding: '10px 0'
                        }}
                      >
                        {openOrdersExist ? (
                          <Button
                            onClick={e =>
                              this.openSalesOrderInquiry(e, 'openOrders')
                            }
                            style={{
                              color: '#517b9c',
                              fontSize: 12,
                              textTransform: 'none'
                            }}
                          >
                            Open Orders
                            <Icon
                              style={{
                                color: '#517b9c',
                                fontSize: 16,
                                marginLeft: 5
                              }}
                            >
                              open_in_new
                            </Icon>
                          </Button>
                        ) : null}
                        {backOrdersExist ? (
                          <Button
                            onClick={e =>
                              this.openSalesOrderInquiry(e, 'backOrders')
                            }
                            style={{
                              color: '#517b9c',
                              fontSize: 12,
                              textTransform: 'none'
                            }}
                          >
                            Backorders
                            <Icon
                              style={{
                                color: '#517b9c',
                                fontSize: 16,
                                marginLeft: 5
                              }}
                            >
                              open_in_new
                            </Icon>
                          </Button>
                        ) : null}
                      </div>
                    </Popover>
                  </>
                ) : null}
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Customer:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="customerId"
                      disableLastSearchButton
                      fullWidth
                      // metaKey="Customer"
                      meta={{
                        ...customerMeta,
                        searchType: 'customer'
                      }}
                      // leader: not using this for Sales Order
                      inputRef={c => (this.customerId = c)}
                      preventAutoDisable
                      popoverStyle={{
                        width: 750
                      }}
                      displayDescription
                      description={customerName}
                      moreInfo
                      forwardRef // remember this is REQUIRED if you want to use a ref on it.
                      validate={[validateSearchField]}
                      // disabled={hasOrderWithoutEdit}
                      disabled={Boolean(dataId) || false}
                      onFocus={touchSearchArea}
                      inputProps={{
                        showQuickEntityButton: canCreateFastCustomer
                      }}
                      customContextMenuItems={this.getCustomContextMenuItems(
                        'customer'
                      )}
                    />
                  </div>
                </div>
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Ship To:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="shipToId"
                      disableLastSearchButton
                      fullWidth
                      preventAutoDisable
                      popoverStyle={{
                        width: 750
                      }}
                      parentId={customerId}
                      displayDescription
                      description={shipToDescription}
                      descriptionKey="shipto Name"
                      inputRef={el => (this.shipToId = el)}
                      validate={[validateSearchField]}
                      meta={{
                        ...shipToMeta,
                        allowSearchAll
                      }}
                      disabled={dataId ? !isEditing : !!dataId}
                      onFocus={touchSearchArea}
                      inputProps={{
                        showQuickEntityButton:
                          hasRecord && isEditing && !!customerId
                      }}
                      customContextMenuItems={this.getCustomContextMenuItems(
                        'shipTo'
                      )}
                    />
                  </div>
                </div>
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Ordered By:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="orderedById"
                      disableLastSearchButton
                      fullWidth
                      preventAutoDisable
                      parentId={customerId}
                      parentType="customer"
                      popoverStyle={{
                        width: 750
                      }}
                      validate={[validateSearchField]}
                      meta={
                        customerId
                          ? {
                              ...orderedByMeta,
                              allowSearchAll
                            }
                          : {
                              allowInstantSearch: true,
                              hasFilters: true,
                              minimumKeywordLength: 2,
                              searchType: 'contacts'
                            }
                      }
                      metaKey="Contact"
                      displayDescription
                      description={orderedByName}
                      descriptionKey="contact"
                      disabled={dataId ? !isEditing : !!dataId}
                      inputRef={el => (this.orderedById = el)}
                      onFocus={touchSearchArea}
                      inputProps={{
                        showQuickEntityButton: canAddContact
                      }}
                      customContextMenuItems={this.getCustomContextMenuItems(
                        'contact'
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="middle-searches search-col-style">
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Writer:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="writerId"
                      metaKey="Writer"
                      // disabled={!customerId}
                      preventAutoDisable
                      forwardRef
                      inputRef={el => (this.writerId = el)}
                      validate={[validateSearchField]}
                      displayDescription
                      description={writerDescription}
                      disabled={
                        hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                      }
                      requirePassword={requirePasswordForWriter}
                    />
                  </div>
                </div>

                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Ship Via:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="shipViaId"
                      disableLastSearchButton
                      fullWidth
                      descriptionKey="description"
                      meta={{
                        ...fakeMetaData,
                        searchType: 'shipVia',
                        allowSearchAll: true,
                        allowInvalidValues: false
                      }}
                      preventAutoDisable
                      popoverStyle={{
                        width: 750
                      }}
                      // disabled={!customerId}
                      displayDescription
                      description={shipViaDescription}
                      inputRef={el => (this.shipViaId = el)}
                      validate={[validateSearchField]}
                      disabled={
                        hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                      }
                    />
                  </div>
                </div>
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Ship Date:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIDatePicker
                      propertyName="shipDate"
                      preventAutoDisable
                      views={['date']}
                      fullWidth
                      // disabled={!customerId}
                      validate={[validateSearchField]}
                      value={shipDate}
                      inputRef={el => (this.shipDate = el)}
                      forwardRef
                      disabled={
                        hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                      }
                    />
                    {/*
                      NOTE: I don't see shipDateIsRequired in the API data at all
                      that ternary messes up the validation otherwise
                    */}
                  </div>
                </div>
              </div>
              <div className="right-searches search-col-style">
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Order:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDISalesOrderSearch
                      propertyName="dataId"
                      preventAutoDisable
                      onChange={this.onSalesOrderChange}
                      onFocus={this.onSalesOrderFocus}
                      disableDoubleClickUnlock
                      inputRef={el => (this.dataId = el)}
                    />
                  </div>
                </div>

                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Branch:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="branchId"
                      metaKey="Branch"
                      displayDescription
                      description={branchDescription}
                      descriptionKey="name"
                      // disabled={!customerId}
                      preventAutoDisable
                      validate={[validateSearchField]}
                      inputRef={el => (this.branchId = el)}
                      forwardRef
                      disabled={
                        hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                      }
                    />
                  </div>
                </div>

                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Warehouse:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIIndexSearch
                      propertyName="warehouseId"
                      displayDescription
                      description={warehouseDescription}
                      descriptionKey="location"
                      metaKey="Whse"
                      // disabled={!customerId}
                      preventAutoDisable
                      validate={[validateSearchField]}
                      inputRef={el => (this.warehouseId = el)}
                      forwardRef
                      disabled={
                        hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="right-searches search-col-style">
                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">
                    Customer P/O:
                  </label>
                  <div className="index-search-row-input-wrapper">
                    <div title={validPatterns}>
                      <DDITextField
                        propertyName="poNumber"
                        fullWidth
                        // disabled={!customerId}
                        validate={[validateSearchField]}
                        preventAutoDisable
                        inputRef={el => (this.poNumber = el)}
                        forwardRef
                        disabled={
                          hasOrderWithoutEdit && !hasProductsLoadedInEditMode
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="index-search-row-wrapper">
                  <label className="index-search-row-label">Quote:</label>
                  <div className="index-search-row-input-wrapper">
                    <DDIToggle
                      propertyName="quote"
                      preventAutoDisable
                      // disabled={!canChangeQuote && hasRecord}
                      disabled={!canChangeQuote}
                      // initialValue={openSOInQuoteMode}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="status-col-wrapper">
              <div className="status-col">
                {customerId && (
                  <div
                    className="overflow-status"
                    title={currentStatusDescriptionResolved}
                  >
                    <div
                      style={{
                        background: currentStatusColor,
                        borderRadius: 4,
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: 10,
                        minHeight: 27,
                        fontSize: 12,
                        flexDirection: 'row',
                        padding: 5,
                        width: '100%'
                      }}
                    >
                      <span className="ellipsis-span">
                        {currentStatusDescriptionResolved}
                      </span>
                    </div>
                  </div>
                )}
                {status && (
                  <div style={{ width: 150 }} title={statusTooltip}>
                    <div
                      style={{
                        background: '#f4f7f9',
                        borderRadius: 4,
                        color: '#444',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 10,
                        minHeight: 27,
                        fontSize: 12,
                        flexDirection: 'row',
                        padding: 5,
                        width: '100%'
                      }}
                    >
                      <span style={{ display: 'inline-block', marginRight: 5 }}>
                        Status:
                      </span>
                      <span style={{ color: currentStatusColor || '#444' }}>
                        {status}
                      </span>
                    </div>
                  </div>
                )}
                {lastInvoiceId && (
                  <div style={{ width: 150 }}>
                    <div
                      style={{
                        background: '#f4f7f9',
                        borderRadius: 4,
                        color: '#444',
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 10,
                        minHeight: 27,
                        fontSize: 12,
                        flexDirection: 'row',
                        padding: 5,
                        width: '100%'
                      }}
                    >
                      <span style={{ display: 'inline-block', marginRight: 5 }}>
                        Last Invoice:
                      </span>
                      {this.getLastInvoiceLink()}
                      {/*  <span style={{ color: '#444' }}>{lastInvoiceId}</span> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DDIExpansionPanel>
      </div>
    )
  }
}

/* FIXED SVE 9/23/2020 */
export const contextObj = {
  canAddContact: form => getValue(form, 'canAddContact', false),
  canCreateFastCustomer: form => getMeta(form, 'canCreateFastCustomer', false),
  customerName: form => getValue(form, 'customerName', ''),
  branchDescription: form => getValue(form, 'branchDescription', ''),
  branchId: form => getField(form, 'branchId', ''),
  currentStatusColorMap: form => getValue(form, 'currentStatusColor', empty),
  currentStatusDescriptionResolved: form =>
    getValue(form, 'currentStatusDescriptionResolved', ''),
  customerStatus: form => getValue(form, 'customerStatus', ''),
  customerStatusColorMap: form => getValue(form, 'customerStatusColor', empty),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  isQuote: form => getValue(form, 'isQuote', false),
  searchAreaExpanded: form => form.get('searchAreaExpanded') || true,
  customerId: form => getField(form, 'customerId', ''),
  customerPORequired: form => getMeta(form, 'customerPORequired', false),
  lastInvoiceId: form => getValue(form, 'lastInvoiceId', ''),
  shipToAddressMap: form => getValue(form, 'shipToAddress', empty),
  shipToEmail: form => getValue(form, 'shipToEmail', ''),
  shipToFax: form => getValue(form, 'shipToFax', ''),
  shipToId: form => getValue(form, 'shipToId', ''),
  shipDate: form => getField(form, 'shipDate', ''),
  shipDateIsRequired: form => getValue(form, 'shipDateIsRequired', false),
  shipToDescription: form => getValue(form, 'shipToDescription', ''),
  shipToPhone: form => getValue(form, 'shipToPhone', ''),
  shipViaDescription: form => getValue(form, 'shipViaDescription', ''),
  warehouseDescription: form => getValue(form, 'warehouseDescription', ''),
  writerDescription: form => getValue(form, 'writerDescription', ''),

  validationMessagesMap: form => getValue(form, 'validationMessages', empty),
  shipToMetaMap: form => getMeta(form, 'Ship To', empty),
  orderedByMetaMap: form => getMeta(form, 'Ordered By', empty),
  customerMetaMap: form => getMeta(form, 'Customer', empty),

  openOrdersExist: form => getValue(form, 'openOrdersExist', false),
  backOrdersExist: form => getValue(form, 'backOrdersExist', false),
  validPatterns: form => getValue(form, 'validPatterns', ''),
  dataId: form => getField(form, 'dataId', ''),
  status: form => getValue(form, 'status', ''),
  statusTooltip: form => getValue(form, 'statusTooltip', ''),
  orderedById: form => getValue(form, 'orderedById', ''),
  orderedByName: form => getValue(form, 'orderedByName', ''),

  canChangeQuote: form =>
    is.bool(getIn(form, 'values.canChangeQuote'))
      ? getIn(form, 'values.canChangeQuote')
      : true,
  isNew: form =>
    is.bool(getIn(form, 'values.isNew')) ? getIn(form, 'values.isNew') : true,
  isInBilling: form => getValue(form, 'isInBilling', false),
  lineItems: form => getIn(form, 'fields.lineItems.rowData') || fromJS([]),
  shipViaId: form => getField(form, 'shipViaId', ''),
  warehouseId: form => getField(form, 'warehouseId', '')
}

const search = withContext(contextObj)(SearchArea)

export const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getFormSelector(form)(state)
  const formModals = getIn(formState, 'modals')?.size || 0
  const mainModals = state.get('modals')?.size || 0

  const openSOInQuoteMode =
    getIn(state, 'auth.branch.openSOInQuoteMode') || false

  const salesOrderInquiryScreenOpen = getIn(state, 'ddiForm.salesOrderInquiry')

  return {
    openSOInQuoteMode,
    modals: formModals + mainModals,
    salesOrderInquiryScreenOpen: Boolean(salesOrderInquiryScreenOpen),
    requirePasswordForWriter: getIn(state, 'auth.requirePasswordForWriter')
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(search)
