import React from 'react'
import { IconButton, Icon } from '@material-ui/core'
import { removeGridItem } from 'pages/SalesOrder/actions'

const DeleteTrackingCell = props => {
  const tryDeleteRow = () => {
    const {
      dispatch,
      form,
      rowIndex,
      data,
      column: {
        colDef: { cellRendererParams }
      }
    } = props
    const groupNames = ['shipments']

    if (data) {
      dispatch(
        removeGridItem.try(form, {
          gridName: 'shipmentTrackedPackages',
          lineNumber: rowIndex + 1,
          key: 'delete',
          groupNames
        })
      )
    } else {
      cellRendererParams.dispatch(
        removeGridItem.try(cellRendererParams.form, {
          gridName: 'shipmentTrackedPackages',
          lineNumber: 0,
          key: 'clearall',
          groupNames
        })
      )
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <IconButton onClick={tryDeleteRow}>
        <Icon style={{ color: '#d9534f', fontSize: 16 }}>cancel</Icon>
      </IconButton>
    </div>
  )
}

export default DeleteTrackingCell
