import React from 'react'
import { formatDate, getValue } from 'utils'
import { withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

import {
  alignRight,
  cellLabelStyle,
  rightSidebarStyle
} from 'pages/SalesOrder/tabs/Shipments/utils'

const Scheduled = ({
  additionalDisplayComponent = null,
  defaultExpanded = false,
  isExpandable = false,
  isMobile = false,
  style = {
    height: 'calc(100% - 10px)',
    margin: 5,
    width: 'calc(100% - 10px)'
  },
  title = 'Scheduled',
  truckId,
  truckName,
  date,
  stop
}) => (
  <DDICardWrapper
    title={title}
    style={style}
    contentStyle={{ padding: 0 }}
    defaultExpanded={defaultExpanded}
    isExpandable={isExpandable}
  >
    <Table>
      <TableBody>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Truck</TableCell>
          <TableCell style={alignRight}>{truckId}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Truck Name</TableCell>
          <TableCell style={alignRight}>{truckName}</TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Date</TableCell>
          <TableCell style={alignRight}>
            {date ? formatDate(date, 'MM/DD/YYYY') : null}
          </TableCell>
        </TableRow>
        <TableRow style={{ background: '#fff' }}>
          <TableCell style={cellLabelStyle}>Stop #</TableCell>
          <TableCell style={alignRight}>{stop}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    {additionalDisplayComponent}
  </DDICardWrapper>
)

/* should be fine as-is SVE 9/25/2020 */
export default withContext({
  truckId: form => getValue(form, 'truckId', ''),
  truckName: form => getValue(form, 'truckName', ''),
  date: form => getValue(form, 'shipmentDate', null),
  stop: form => getValue(form, 'stopNumber', '')
})(Scheduled)
