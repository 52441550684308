import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const getRoute = routeName => {
  const formParts = routeName?.split('.') || []

  if (!formParts?.[1]) {
    return routeName === 'productWebCategory' ? 'webCategory' : routeName
  }

  return formParts[1]
}

/* Labels feature */
export const getLabelPrintingCriteria = ({ routeName }) => {
  const options = createApiOptions({
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/printlabel/getcriteria`,
    options
  )
}

export const getLabelPrintingDefaults = ({ selectionCriteria, routeName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      selectionCriteria
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/printlabel/getdefaults`,
    options
  )
}

export const getReportCriteria = ({
  getSelectionCriteria = true,
  getFieldSelection = true,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      getSelectionCriteria,
      getFieldSelection
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/report/get`,
    options
  )
}

export const setReportCriteria = ({
  selectionCriteria,
  runReport = true,
  printOrder,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      selectionCriteria,
      runReport,
      printOrder
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/report/set`,
    options
  )
}

export const cancelReport = ({ routeName }) => {
  const options = createApiOptions({
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/report/cancel`,
    options
  )
}

export const runPrintLabels = ({
  numberOfLabelsAcross,
  numberOfLinesToSkip,
  lengthOfEachLabel,
  numberOfSpacesBetweenLabels,
  numberOfLabelsToSample,
  routeName
}) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      numberOfLabelsAcross,
      numberOfLinesToSkip,
      lengthOfEachLabel,
      numberOfSpacesBetweenLabels,
      numberOfLabelsToSample
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/printlabel/run`,
    options
  )
}

export const exitPrintLabels = ({ updateDefaults = false, routeName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      updateDefaults
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${getRoute(routeName)}/printlabel/exit`,
    options
  )
}

/* end Label printing api methods */

/* VIEW THE REPORT */
export const viewSelectionCriteriaReport = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/printdocument/view`, options)
}
