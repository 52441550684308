import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const INDEX_SEARCH = '@@RepairSerialNumberSearch/'
export const POPULATE_FIELD = `${INDEX_SEARCH}POPULATE_FIELD`
export const BLUR = `${INDEX_SEARCH}BLUR`
export const FOCUS = `${INDEX_SEARCH}FOCUS`
export const CLOSE_SEARCH = `${INDEX_SEARCH}CLOSE_SEARCH`
export const EXACT_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}EXACT_MATCH_SEARCH`
)
export const PARTIAL_MATCH_SEARCH = createAsyncRequestTypes(
  `${INDEX_SEARCH}PARTIAL_MATCH_SEARCH`
)
export const FIND_NEXT = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_NEXT`)
export const FIND_PREV = createAsyncRequestTypes(`${INDEX_SEARCH}FIND_PREV`)
export const TOGGLE_FILTER = `${INDEX_SEARCH}TOGGLE_FILTER`
export const GET_TOOLTIP = createAsyncRequestTypes(`${INDEX_SEARCH}GET_TOOLTIP`)
export const GET_SEARCH_FILTERS = createAsyncRequestTypes(
  `${INDEX_SEARCH}GET_SEARCH_FILTERS`
)

export const REPAIR_SERIAL_NUMBER_PARTIAL_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/repair/serialnumber/partialmatch`
export const REPAIR_SERIAL_NUMBER_EXACT_MATCH_SEARCH_URL = `${BASE_INFORM_API_URL}/search/repair/serialnumber/exactmatch`

export const OPEN_FILTERS_GRID = `${INDEX_SEARCH}OPEN_FILTERS_GRID`
export const CLOSE_FILTERS_GRID = `${INDEX_SEARCH}CLOSE_FILTERS_GRID`
export const CLEAR_FILTER_FIELD = `${INDEX_SEARCH}CLEAR_FILTER_FIELD`
export const SET_TEXT_FILTER_FIELD = `${INDEX_SEARCH}SET_TEXT_FILTER_FIELD`
export const RESET_FILTERS = `${INDEX_SEARCH}RESET_FILTERS`
export const CLEAR_SEARCH = `${INDEX_SEARCH}CLEAR_SEARCH`
