/* eslint react/sort-comp: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from 'grid'
import memoize from 'memoize-one'
import { Label } from 'ddiForm'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import { getIn, fromJS, plainDeepEqual } from 'utils'
import { debounce } from 'lodash'
import { setRowData, cancelEditCorporateFieldUpdatesData } from '../actions'
import './styles/css-mod-ignore.scss'

const removeIsSelectedKey = rowData =>
  rowData.reduce((acc, next) => {
    const { isSelected, ...rest } = next
    acc = acc.concat({ ...rest })
    return acc
  }, [])

const getColumnDefs = memoize(isLocked => [
  {
    suppressMenu: true,
    suppressNavigable: true,
    resizable: false,
    lockPosition: true,
    field: 'isSelected',
    colId: 'isSelected',
    headerName: 'Exclude',
    cellClass: 'cell-value-hidden',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    width: 100,
    minWidth: 100,
    maxWidth: 100
  },
  {
    field: 'description',
    headerName: 'Description'
  }
])

export const mapState = (state, ownProps) => {
  const form = ownProps?.data?.form || ownProps?.form || null
  const formState = getIn(state, `ddiForm.${form}`)
  const isModal = ownProps?.data?.isModal || false
  const valuesKey = isModal ? 'values.corporateFieldUpdates' : 'values'
  const dataIdKey = isModal
    ? 'fields.corporateFieldUpdates.dataId.value'
    : 'fields.dataId.value'
  const fields = getIn(formState, `${valuesKey}.fields`) || fromJS([])
  const isLocked = getIn(formState, `${valuesKey}.isLocked`) || false
  const hasRecord = getIn(formState, `${valuesKey}.hasRecord`) || false
  const dataId = getIn(formState, dataIdKey)
  const description = getIn(formState, `${valuesKey}.description`) || ''

  return {
    dataId,
    description,
    fields,
    hasRecord,
    isLocked
  }
}

class GridInterface extends Component {
  constructor(props) {
    super(props)

    console.log(this.props)
    const { hasRecord } = this.props
    let { fields } = this.props
    fields = fields?.toJS ? fields.toJS() : []

    this.state = {
      hasRecord,
      data: fields || []
    }

    this.gridApi = null
    this.columnApi = null
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      (nextProps?.fields?.toJS &&
        !plainDeepEqual(
          removeIsSelectedKey(nextProps.fields.toJS()),
          removeIsSelectedKey(prevState.data)
        )) ||
      nextProps.hasRecord !== prevState.hasRecord
    ) {
      return {
        hasRecord: nextProps.hasRecord,
        data: nextProps.fields.toJS()
      }
    }

    return null
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasRecord && this.state.hasRecord !== prevState.hasRecord) {
      if (this._isMounted && this.gridApi) {
        this.gridApi.refreshCells({ force: true, suppressFlash: true })
        this.gridApi.forEachNode(node => {
          if (node?.data?.isSelected) {
            node.setSelected(true)
          } else {
            node.setSelected(false)
          }
        })
      }
    }
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.columnApi = params.columnApi

    this.gridApi.forEachNode(node => {
      if (node?.data?.isSelected) {
        node.setSelected(true)
      }
    })
  }

  onRowSelected = debounce(params => {
    const isModal = this?.props?.data?.isModal || false
    const form = isModal ? this?.props?.data?.form : this?.props?.form
    let { fields } = this.props
    fields = fields?.toJS ? fields.toJS() : []

    if (this._isMounted) {
      this.setState(
        prevState => {
          const selectedRows = params.api
            .getSelectedRows()
            .reduce((acc, next) => {
              acc = acc.concat(next.dataId)
              return acc
            }, [])

          return {
            data: prevState.data.reduce((acc, next) => {
              acc = acc.concat({
                ...next,
                isSelected: selectedRows.includes(next.dataId)
              })

              return acc
            }, [])
          }
        },
        () => {
          if (!plainDeepEqual(fields, this.state.data)) {
            this.props.dispatch(setRowData(form, { rowData: this.state.data }))
          }
        }
      )
    }
  }, 500)

  onIndexSearchFocus = e => {
    const isModal = this?.props?.data?.isModal || false
    const form = isModal ? this?.props?.data?.form : this?.props?.form

    const { isLocked } = this.props
    if (isLocked) {
      if (
        this?.indexSearch?.blur &&
        typeof this.indexSearch.blur === 'function'
      ) {
        this.indexSearch.blur()
      }
      this.props.dispatch(
        cancelEditCorporateFieldUpdatesData.try(form, {
          clearGridOnSuccess: true
        })
      )
    }
  }

  render() {
    const isModal = this?.props?.data?.isModal || false
    const form = isModal ? this?.props?.data?.form : this?.props?.form

    const { isLocked, description } = this.props

    const gridWrapperClassName = isLocked
      ? 'allow-grid-pointer-events corporate-field-updates-grid-wrapper'
      : 'disable-grid-pointer-events corporate-field-updates-grid-wrapper'

    return (
      <div style={{ width: '100%' }}>
        <div
          className="corporate-field-updates-search-interface-wrapper"
          style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
        >
          <label
            style={{ margin: '0 10px 0 0', fontSize: '0.9em', fontWeight: 500 }}
          >
            {form && form.match(/vendor/gi) ? 'Vendor:' : 'Product:'}
          </label>
          <DDIIndexSearch
            propertyName={isModal ? 'corporateFieldUpdates.dataId' : 'dataId'}
            metaKey="dataId"
            searchType={form && form.match(/vendor/gi) ? 'Vendor' : 'Product'}
            preventAutoDisable
            wrapperStyle={{ width: 175 }}
            autoFocus
            disabled={isLocked}
            onFocus={this.onIndexSearchFocus}
            inputRef={el => (this.indexSearch = el)}
            disableDoubleClickUnlock
          />
          {description && (
            <div style={{ marginLeft: 15 }}>
              <Label
                propertyName={
                  isModal ? 'corporateFieldUpdates.description' : 'description'
                }
                labelStyle={{ width: '100%' }}
                value={description}
              />
            </div>
          )}
        </div>
        <div className={gridWrapperClassName} style={{ width: '100%' }}>
          <Grid
            columnDefs={getColumnDefs(isLocked)}
            rowSelection="multiple"
            getRowNodeId={data => data.dataId}
            rowData={this.state.data || []}
            onGridReady={this.onGridReady}
            onRowSelected={this.onRowSelected}
            height={600}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(GridInterface)
