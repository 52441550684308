import React, { useState, useEffect } from 'react'
import { DDIButton } from 'ddiForm/wrapped'
import { confirm, canceled } from 'modals/actions'
import { Switch as Toggle, FormControlLabel } from '@material-ui/core'

export const RetainCustomerModalActions = ({ dispatch, form, modal }) => {

  const [retainCustomer, setRetainCustomer] = useState(false)
  const [modalId, setModalId] = useState('')

  useEffect(() => {
    if (modal && modal.id) {
      setModalId(modal.id)
    }
  }, [modal])

  function handleCancel() {
    dispatch(canceled(form, modalId))
  }

  function handleConfirm() {
    dispatch(confirm(form, modalId, { retainCustomer }))
  }

  function handleToggle(e) {
    const checked = e.target.checked
    setRetainCustomer(checked)
  }

  function getToggle() {
    return (
      <Toggle
        checked={retainCustomer}
        id={modal.id}
        onChange={handleToggle}
      />
    )
  }


  return (
    <>
      <FormControlLabel
        control={getToggle()}
        label="Retain Customer Information"
        labelPlacement="start"
        style={{ marginRight: 'auto' }}
      />
      <DDIButton
        onClick={handleConfirm}
        confirm
        variant="contained"
        label="OK"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleCancel}
        variant="contained"
        label="Cancel"
        preventAutoDisable
      />
    </>
  )
}


export default RetainCustomerModalActions
