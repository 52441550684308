import React, { Component } from 'react'
import { withContext } from 'ddiForm'
import PropTypes from 'prop-types'
import { plainDeepEqual, emptyList, getMeta, getValue } from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import { DDITextField, DDIToggle, DDISearchDropdown } from 'ddiForm/wrapped'

import { setShipToId } from 'pages/ContactMaster/actions'

import {
  LayoutFlex,
  TwoColInputsFlexWrapper,
  TwoColInputsFlexLeft,
  TwoColInputsFlexRight
} from 'pages/ContactMaster/styles/masterStyle'

// import * as style from 'pages/ContactMaster/styles/masterStyle'
import WebField from './components/WebField'

export const getRowNodeId = data => data.dataId

const columnDefs = [
  { field: 'dataId', headerName: 'Code' },
  { field: 'description', headerName: 'Description' },
  { field: 'addressLine1', headerName: 'AddressLine1' },
  { field: 'addressLine2', headerName: 'AddressLine2' },
  { field: 'city', headerName: 'City' },
  { field: 'state', headerName: 'State' }
]

// const searchWidth = window ? window.innerWidth * 0.9 : 300
const searchWidth = 900

export class Permissions extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    hasEcommerceOrAPI: PropTypes.bool.isRequired,
    shipToIds: PropTypes.string.isRequired,
    shipToOptions: PropTypes.object.isRequired,
  }

  shouldComponentUpdate(nextProps) {
    if (plainDeepEqual(nextProps, this.props)) {
      return false
    }
    return true
  }

  render() {
    const {
      form,
      hasEcommerceOrAPI,
      shipToIds,
      shipToOptions
    } = this.props


    return (
      <LayoutFlex>
        <DDICardWrapper title="">
          <TwoColInputsFlexWrapper>
            <TwoColInputsFlexLeft>
              <DDITextField
                label="Product Certification Codes"
                placeholder="Separate by a comma..."
                propertyName="certificationCodes"
                fullWidth
              />
            </TwoColInputsFlexLeft>
            <TwoColInputsFlexRight>
              <DDIToggle
                propertyName="authorizedBuyer"
                label="Authorized Buyer"
              />
            </TwoColInputsFlexRight>
          </TwoColInputsFlexWrapper>
          <TwoColInputsFlexWrapper>
            <TwoColInputsFlexLeft>
              <DDISearchDropdown
                floatingLabelText="Ship Tos:"
                identifier="dataId"
                propertyName="shipToIds"
                placeholder="All Ship Tos"
                initialValue={shipToIds}
                gridOptions={{
                  columnDefs,
                  enableSorting: true,
                  getRowNodeId,
                  // width: '50%'
                }}
                ref={el => (this.shipToDropdown = el)}
                rowData={shipToOptions.toJS()}
                width={searchWidth}
                inputLabelProps={{
                  shrink: true
                }}
              />
            </TwoColInputsFlexLeft>
          </TwoColInputsFlexWrapper>
        </DDICardWrapper>
        {hasEcommerceOrAPI ? <WebField form={form} /> : null}
      </LayoutFlex>
    )
  }
}

export const contextObj = {
  hasEcommerceOrAPI: form => getMeta(form, 'hasEcommerceOrAPI', false),
  shipToIds: form => getValue(form, 'shipToIds', ''),
  shipToOptions: form => getValue(form, 'setup.shipToOptions', emptyList)
}

export default withContext(contextObj)(Permissions)
