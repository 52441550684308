import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getValue } from 'utils'

const marginRight = { marginRight: 5 }

export const LeftFooterButtons = ({ getEcommerceURL }) => {
  function openWebPreview() {
    window.open(getEcommerceURL, '_blank')
  }

  return (
    <>
      {getEcommerceURL ? (
        <DDIButton
          variant="contained"
          label="Web Preview"
          key="web-preview"
          onClick={openWebPreview}
          preventAutoDisable
          disabled={!getEcommerceURL}
          style={marginRight}
        />
      ) : null}
    </>
  )
}

LeftFooterButtons.propTypes = {
  getEcommerceURL: PropTypes.string.isRequired
}

export const contextObj = {
  getEcommerceURL: form => getValue(form, 'eCommerceURL', '')
}

export default withContext(contextObj)(LeftFooterButtons)
