import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import {
  clearPaymentTransactionData,
  initializePayment
} from 'pages/SalesOrder/actions'
import { noop } from 'utils'
import { debounce } from 'lodash'

import {
  PaymentButton,
  paymentModuleStyle,
  headerFourStyle,
  buttonIsDisabled
} from '../utils'

const CashPayment = ({
  form,
  dispatch,
  amountTendered,
  clearAmountTendered,
  isEditing,
  isMobile = false
}) => {
  useEffect(() => {
    /* functional component substitute for componentWillUnmount */
    return () => {
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  const submitCashPayment = debounce(e => {
    dispatch(
      initializePayment.try(form, {
        option: 'cash',
        amountTendered
      })
    )
  }, 500)

  const moduleStyle = isMobile
    ? {
        ...paymentModuleStyle,
        padding: '10px 10px 15px 10px'
      }
    : paymentModuleStyle

  return (
    <div>
      <h4 style={headerFourStyle}>Enter Payment Information</h4>
      <div style={moduleStyle}>
        <span style={{ fontSize: 11, margin: '0 10px' }}>
          Please confirm Payment Amount, then click &lsquo;OK&rsquo;
        </span>
        <PaymentButton
          variant="contained"
          onClick={submitCashPayment}
          disabled={buttonIsDisabled(amountTendered, isEditing)}
        >
          OK
        </PaymentButton>
      </div>
    </div>
  )
}

export default CashPayment
