import React from 'react'
import memoize from 'memoize-one'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const headerFourStyle = { color: '#517b9c', fontSize: 10, margin: '10px 0', textAlign: 'center', textTransform: 'uppercase' }
export const paymentModuleStyle = { alignItems: 'center', background: '#f4f7f9', borderRadius: 4, display: 'flex', justifyContent: 'space-between', padding: 5 }

export const PaymentButton = withStyles({
  root: {
    backgroundColor: '#389123'
  }
})(Button)

export const PaymentCancellationButton = withStyles({
  root: {
    backgroundColor: '#d9534f'
  }
})(Button)

export const buttonIsDisabled = memoize((payment, editing) => {
  if (!payment || !editing) {
    return true
  }

  return false
})

export const paymentContactMeta = {
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: true,
  allowSpecialProducts: false,
  hasFilters: false,
  hideToggle: true,
  isSecure: false,
  minimumKeywordLength: 2,
  openText: 'Open Contact Master',
  parentType: 'All',
  searchType: 'Contacts'
}