/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0, react/prefer-stateless-function: 0, no-useless-escape: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Label, Field } from 'ddiForm'
import {
  DDIIndexSearch,
  DDITextField,
  DDISelectField,
  DDIToggle
} from 'ddiForm/wrapped'

import { Button, Paper, Typography } from '@material-ui/core'

import { getIn, toJS, fromJS } from 'utils'
import BinSearch from 'components/Search/BinSearch'
import { setField } from 'ddiForm/actions'
import { required } from 'ddiForm/validations'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from 'pages/SalesOrder/utils'
import { changeFastProductVendor } from 'pages/SalesOrder/actions'

import '../../styles/master-style.scss'

const infoStyle = { color: '#444', fontSize: '0.85em' }
const headingStyle = {
  color: '#517b9c',
  fontSize: 14,
  lineHeight: '18px',
  margin: '0 0 15px 0'
}
const divisionStyle = {
  borderBottom: '1px solid #cecece',
  marginBottom: 15,
  paddingBottom: 15
}

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const meta = getIn(formState, 'meta.fastProduct')
  const searchFields = getIn(meta, 'searchFields')
  const statusOptions = getIn(meta, 'statusOptions')

  return {
    canChangeVendor: getIn(meta, 'canChangeVendor') || false,
    searchFields,
    statusOptions,
    isSingleInstance: getIn(meta, 'isSingleInstance') || '',
    maxInstances: getIn(meta, 'maxInstances') || '',
    uomForDisplayId:
      getIn(formState, 'fields.fastProduct.uomForDisplayId.value') || '',
    uomForPriceId:
      getIn(formState, 'fields.fastProduct.uomForPriceId.value') || '',
    uomForPurchasingId:
      getIn(formState, 'fields.fastProduct.uomForPurchasingId.value') || '',
    warehouseId: getIn(formState, 'fields.warehouseId.value') || '',
    isCanadianCompanyOrHasHawaiianTax:
      getIn(formState, 'meta.fastProduct.isCanadianCompanyOrHasHawaiianTax') ||
      false,
    taxableOptions:
      getIn(formState, 'meta.fastProduct.taxableOptions') || fromJS([])
  }
}

class FastProductEntryModal extends Component {
  setBinField = (value, _, __, ___) => {
    const {
      data: { form },
      dispatch
    } = this.props
    dispatch(setField(form, 'fastProduct.binLocation', value))
  }

  changeVendor = e => {
    const {
      data: { form },
      dispatch
    } = this.props

    dispatch(changeFastProductVendor.try(form))
  }

  render() {
    const {
      canChangeVendor,
      data: { form },
      searchFields,
      statusOptions,
      uomForDisplayId,
      uomForPriceId,
      uomForPurchasingId,
      warehouseId,
      isCanadianCompanyOrHasHawaiianTax,
      taxableOptions
    } = this.props

    const {
      UOM = {},
      Bins = {},
      BuyLine = {},
      DOTCode = {},
      PriceGroup = {},
      Vendor = {},
      ProductLine = {}
    } = toJS(searchFields)

    return (
      <div className="fast-product-entry-modal">
        <Paper style={{ margin: '10px 0', padding: '10px 10px 20px 10px' }}>
          <h2 style={headingStyle}>Product Configuration</h2>
          <div style={divisionStyle}>
            <div className="search-row-wrapper">
              <label className="search-row-label">Product Line</label>
              <div className="search-row-input">
                <DDIIndexSearch
                  propertyName="fastProduct.productLineId"
                  meta={ProductLine}
                  preventAutoDisable
                  validate={[required]}
                />
              </div>
              <div className="search-row-description">
                <Field
                  propertyName="fastProduct.productLineDescription"
                  component={Label}
                />
              </div>
            </div>
            <div className="search-row-wrapper">
              <label className="search-row-label">Price Group</label>
              <div className="search-row-input">
                <DDIIndexSearch
                  propertyName="fastProduct.priceGroupId"
                  meta={PriceGroup}
                  preventAutoDisable
                />
              </div>
              <div className="search-row-description">
                <Field
                  propertyName="fastProduct.priceGroupDescription"
                  component={Label}
                />
              </div>
            </div>
            <div className="search-row-wrapper">
              <label className="search-row-label">Buy Line</label>
              <div className="search-row-input">
                <DDIIndexSearch
                  propertyName="fastProduct.buylineId"
                  meta={BuyLine}
                  preventAutoDisable
                />
              </div>
              <div className="search-row-description">
                <Field
                  propertyName="fastProduct.buylineDescription"
                  component={Label}
                />
              </div>
            </div>
            {canChangeVendor ? (
              <div className="search-row-wrapper">
                <label className="search-row-label">Vendor</label>
                <div className="search-row-input">
                  <DDIIndexSearch
                    propertyName="fastProduct.primaryVendorId"
                    meta={Vendor}
                    preventAutoDisable
                  />
                </div>
                <div className="search-row-description">
                  <Field
                    propertyName="fastProduct.primaryVendorDescription"
                    component={Label}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '15px 10px 0 10px'
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={this.changeVendor}
                >
                  Change Vendor
                </Button>
              </div>
            )}
          </div>
          <div style={divisionStyle}>
            <div className="search-row-wrapper">
              <label className="search-row-label">Sell / Qty UOM</label>
              <div className="search-row-input">
                <DDIIndexSearch
                  propertyName="fastProduct.uomForDisplayId"
                  meta={UOM}
                  preventAutoDisable
                />
              </div>
              <div
                className="search-row-description"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Field
                  propertyName="fastProduct.uomForDisplayDescription"
                  component={Label}
                />
                <span
                  style={{
                    display: 'inline-block',
                    fontSize: 11,
                    marginLeft: 10
                  }}
                >
                  <strong style={{ color: '#517b9c', fontWeight: 500 }}>
                    Price UOM:
                  </strong>{' '}
                  {uomForPriceId}
                  <br />
                  <strong style={{ color: '#517b9c', fontWeight: 500 }}>
                    Purchase UOM:
                  </strong>{' '}
                  {uomForPurchasingId}
                </span>
              </div>
            </div>

            <div className="search-row-wrapper">
              <label className="search-row-label">MFG Number</label>
              <div className="search-row-input">
                <DDITextField
                  propertyName="fastProduct.mfgNumber"
                  preventAutoDisable
                  // fullWidth
                />
              </div>
            </div>

            <div className="search-row-wrapper">
              <label className="search-row-label">Cost</label>
              <div className="search-row-input">
                <DDITextField
                  propertyName="fastProduct.cost"
                  mask="numeric"
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true
                  }}
                  preventAutoDisable
                  style={{ width: 150 }}
                />
              </div>
              <div className="search-row-description">
                <Field
                  propertyName="fastProduct.costChangedDate"
                  component={Label}
                />
              </div>
            </div>

            <div className="search-row-wrapper">
              <label className="search-row-label">List Price</label>
              <div className="search-row-input">
                <DDITextField
                  propertyName="fastProduct.listPrice"
                  mask="numeric"
                  inputProps={{
                    decimalScale: 2,
                    fixedDecimalScale: true
                  }}
                  preventAutoDisable
                  style={{ width: 150 }}
                />
              </div>
              <div className="search-row-description">
                <Field
                  propertyName="fastProduct.priceChangedDate"
                  component={Label}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <DDISelectField
              propertyName="fastProduct.statusType"
              label="Status"
              values={toJS(statusOptions)}
              preventAutoDisable
              style={{ width: 150 }}
            />
            <div
              style={{
                background: '#f4f7f9',
                borderRadius: 4,
                paddingLeft: 15,
                width: 150
              }}
            >
              <DDIToggle
                propertyName="fastProduct.isSerialNumberRequired"
                label="S/N Required"
                preventAutoDisable
              />
            </div>
            {isCanadianCompanyOrHasHawaiianTax ? (
              <DDISelectField
                propertyName="fastProduct.taxableType"
                label="Taxable"
                values={toJS(taxableOptions)}
                preventAutoDisable
                style={{ width: 150 }}
              />
            ) : (
              <div
                style={{
                  background: '#f4f7f9',
                  borderRadius: 4,
                  paddingLeft: 15,
                  width: 150
                }}
              >
                <DDIToggle
                  propertyName="fastProduct.isTaxable"
                  label="Taxable"
                  preventAutoDisable
                />
              </div>
            )}
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 10
            }}
          >
            <DDITextField
              propertyName="fastProduct.weight"
              label="Weight"
              mask="numeric"
              inputProps={{
                noDecimalLimit: true
              }}
              preventAutoDisable
              style={{ width: 150 }}
            />
            <DDITextField
              propertyName="fastProduct.volume"
              label="Volume"
              mask="numeric"
              inputProps={{
                noDecimalLimit: true
              }}
              preventAutoDisable
              style={{ width: 150 }}
            />
            <DDITextField
              propertyName="fastProduct.keyword"
              label="Keyword"
              preventAutoDisable
              style={{ width: 150 }}
            />
          </div>
        </Paper>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={infoStyle}>Additional Fields</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ borderBottom: '1px solid #cecece' }}>
            <Typography component="div" style={{ width: '100%' }}>
              <div className="search-row-wrapper">
                <label className="search-row-label">Haz Mat</label>
                <div className="search-row-input">
                  <DDIIndexSearch
                    propertyName="fastProduct.hazMatId"
                    meta={DOTCode}
                    preventAutoDisable
                  />
                </div>
                <div className="search-row-description">
                  <Field
                    propertyName="fastProduct.hazMatDescription"
                    component={Label}
                  />
                </div>
              </div>
              <div className="search-row-wrapper">
                <label className="search-row-label">Bin Location</label>
                <div className="search-row-input">
                  <BinSearch
                    form={form}
                    dispatch={this.props.dispatch}
                    propertyName="fastProduct.binLocation"
                    meta={Bins}
                    warehouseId={warehouseId}
                    setField={this.setBinField}
                    preventAutoDisable
                  />
                </div>
                <div className="search-row-description">
                  <Field
                    propertyName="fastProduct.binLocationDescription"
                    component={Label}
                  />
                </div>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div style={{ marginTop: 15 }}>
          <h2 style={{ ...headingStyle, marginBottom: 5 }}>Internal Notes</h2>
          <DDITextField
            propertyName="fastProduct.internalNotes"
            fullWidth
            rows={4}
            multiline
            variant="outlined"
            preventAutoDisable
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(FastProductEntryModal)
