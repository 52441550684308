/* eslint quote-props: 0, jsx-a11y/label-has-for: 0, react/no-multi-comp: 0, react/sort-comp: 0, sort-keys: 0, jsx-a11y/label-has-associated-control: 0, no-else-return: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import { Grid as BootstrapGrid, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import ComponentStyles from 'theme/ComponentStyles'
import Measure from 'react-measure'

import { Button, Paper } from '@material-ui/core'
import { ddiForm } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { removeModal } from 'modals/actions'
import { destroy, exit } from 'ddiForm/actions'

import { getIn, plainDeepEqual } from 'utils'
import * as actions from './actions'
import sagas from './sagas'
import behaviors from './behaviors'
import { getClientHeight } from './utils'
import FilterBy from './components/desktop/FilterBy'
import GroupBy from './components/desktop/GroupBy'
import SearchFields from './components/desktop/SearchFields'
import InquiryResults from './components/desktop/InquiryResults'

import './styles/css-mod-ignore.scss'

export class CustomerOrderPad extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired
    // meta: PropTypes.object,
    // showAdjustedPrice: PropTypes.func.isRequired,
    // showCustomerStockMinimums: PropTypes.func.isRequired,
    // showPriceDetail: PropTypes.func.isRequired,
    // updateLineItemQuantity: PropTypes.func.isRequired
  }

  // static defaultProps = {
  //   meta: {}
  // }

  static ddiType = 'customerOrderPad'

  constructor(props) {
    super(props)
    this.state = {
      filterInterfaceDimensions: {
        height: -1,
        width: -1
      },
      isModal: Boolean(this.props.id)
    }
  }

  componentDidMount() {
    /* data is from when opening this guy in a modal */
    const { dispatch, form, data = null } = this.props
    dispatch(actions.loadCustomerRecord(form, { data }))
  }

  createSalesOrder = (e, isQuote = false) => {
    const { dispatch, form, id } = this.props
    dispatch(actions.openSalesOrderScreen(form, { isQuote, modalId: id }))
  }

  createSalesOrderIsDisabled = memoize(data => {
    const hasQuantity =
      data && data.length
        ? data.reduce((acc, next) => {
            if (next.quantity) {
              acc = acc.concat(next.dataId)
            }
            return acc
          }, [])
        : []

    return !hasQuantity.length
  }, plainDeepEqual)

  closeScreen = () => {
    const { dispatch, form, id } = this.props
    // debugger

    if (id) {
      dispatch(removeModal(form.split('.')[0], id))
    } else {

      dispatch(exit(form))
    }
  }

  addToSalesOrder = e => {
    const {
      dispatch,
      form,
      id,
      data: { lineItemsGridApi }
    } = this.props

    dispatch(
      actions.compileDataForSalesOrder(form, {
        modalId: id,
        lineItemsGridApi
      })
    )
  }

  render() {
    const { screenLayout } = ComponentStyles
    const fields = this?.props?.fields?.toJS ? this.props.fields.toJS() : {}
    const rowData = fields?.inquiryResults?.rowData?.length
      ? fields.inquiryResults.rowData
      : []

    return (
      <div
        id="cop-container"
        className={
          this.state.isModal
            ? 'cop-container cop-container-is-modal'
            : 'cop-container'
        }
        style={{
          ...screenLayout.container,
          display: this.state.isModal ? 'block' : 'flex',
          minHeight: this.state.isModal ? getClientHeight() - 100 : 400
        }}
        ref={el => (this.container = el)}
      >
        <div
          className="cop-content-wrapper"
          style={{
            ...screenLayout.contentWrapper,
            overflow: 'hidden',
            padding: 10
          }}
        >
          <Measure
            bounds
            onResize={contentRect =>
              this.setState({ filterInterfaceDimensions: contentRect.bounds })
            }
          >
            {({ measureRef }) => (
              <div ref={measureRef} className="clear">
                <DDICardWrapper
                  title="Customer Order Pad"
                  contentStyle={{ padding: 10 }}
                >
                  <BootstrapGrid
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      width: '100%'
                    }}
                  >
                    <Row>
                      <Col lg={7} md={7} sm={6} xs={12}>
                        <SearchFields form={this.props.form} />
                      </Col>
                      <Col
                        lg={5}
                        md={5}
                        sm={6}
                        xs={12}
                        className="col-md-up-pad-right-only"
                      >
                        <FilterBy />
                        <GroupBy />
                      </Col>
                    </Row>
                  </BootstrapGrid>
                </DDICardWrapper>
              </div>
            )}
          </Measure>
          <InquiryResults
            isModal={this.state.isModal}
            filterInterfaceDimensions={this.state.filterInterfaceDimensions}
            form={this.props.form}
          />
        </div>
        <div className="cop-footer-wrapper" style={screenLayout.footerWrapper}>
          <Paper
            className="action-buttons clear"
            style={ComponentStyles.footer.containerStandalone}
          >
            <div style={{ float: 'left' }}>
              <DDIButton
                variant="contained"
                actionName="showReportOptionsModal"
                label="Report"
                disabled={(field, formState) => {
                  const inquiryRowData = getIn(
                    formState,
                    'fields.inquiryResults.rowData'
                  )
                  if (!inquiryRowData) {
                    return true
                  }
                  return false
                }}
                style={{ ...ComponentStyles.footer.button, float: 'left' }}
              />
            </div>
            <div style={{ float: 'right' }}>
              {this.state.isModal ? (
                <Button
                  variant="contained"
                  style={ComponentStyles.footer.button}
                  onClick={this.addToSalesOrder}
                  size="large"
                  disabled={this.createSalesOrderIsDisabled(rowData)}
                >
                  Add To Order
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    style={ComponentStyles.footer.button}
                    onClick={e => this.createSalesOrder(e, true)}
                    size="large"
                    disabled={this.createSalesOrderIsDisabled(rowData)}
                  >
                    Create Quote
                  </Button>
                  <Button
                    variant="contained"
                    style={ComponentStyles.footer.button}
                    onClick={e => this.createSalesOrder(e, false)}
                    size="large"
                    disabled={this.createSalesOrderIsDisabled(rowData)}
                  >
                    Create Order
                  </Button>
                </>
              )}
              <DDIButton
                component={Button}
                label="Search"
                actionName="executeInquiry"
                bindTo="onClick"
                variant="contained"
                style={ComponentStyles.footer.button}
              />
              <Button
                variant="contained"
                onClick={this.closeScreen}
                size="large"
              >
                Exit
              </Button>
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

const contextObj = {
  fields: form => (form.fields ? form.fields : {})
}

// const customerOrderPad = connect(mapStateToProps, null, null)(CustomerOrderPad)

const defaultArgs = {
  withFields: true,
  actions: {
    executeInquiry: actions.executeInquiry.try,
    showAdjustedPrice: actions.showAdjustedPrice,
    showCustomerStockMinimums: actions.showCustomerStockMinimums,
    showPriceDetail: actions.showPriceDetail,
    showReportOptionsModal: actions.showReportOptionsModal,
    showWarningModal: actions.showWarningModal,
    updateLineItemQuantity: actions.updateLineItemQuantity
  },
  behaviors,
  sagas: { onInit: sagas },
  form: 'customerOrderPad',
  title: 'Customer Order Pad',
  noAPIForCancelConfirm: true
}

const Form = ddiForm(defaultArgs)(CustomerOrderPad)

export const OrderPadInModal = form =>
  ddiForm({
    ...defaultArgs,
    form: `${form}.customerOrderPad`
  })(CustomerOrderPad)

export default Form
