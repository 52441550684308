/* eslint react/no-multi-comp: 0, react/prefer-stateless-function: 0, jsx-a11y/label-has-for: 0, max-classes-per-file: 0, jsx-a11y/label-has-associated-control: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import shortid from 'shortid'
import { withContext, Checkbox, Field } from 'ddiForm'
import { DDISelectField, DDINumberSpinner } from 'ddiForm/wrapped'
import EditableGrid from 'components/EditableGrid'
import { Paper } from '@material-ui/core'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import { deepEqual, getValue, getMeta, emptyList, getField } from 'utils'
import { getRowNodeId, headerStyle, numberFilterGridConfigs } from 'pages/ProductMaster/utils'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import DisplayOnlyCell from 'components/EditableGrid/components/DisplayOnlyCell'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import TooltipField from 'hoc/TooltipField'
import DynamicGridDataTooltip from 'hoc/DynamicGridDataTooltip'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

export const dynamicColDefs = memoize(
  ({
    disassembleOnReceipt,
    isEditing,
    focusedCell,
    form,
    isKitProduction,
    onCellChange,
    propertyName
  }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'product',
      onChange: onCellChange,
      propertyName
    }

    const textEditor = {
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: params
    }

    const colDef = [
      {
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Product',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          linkTo: 'productMaster'
        },
        pinnedRowCellRenderer: 'customPinnedRowRenderer',
        field: 'dataId',
        headerName: 'Product',
        linkTo: 'productMaster',
        groupNames: ['setup', 'assemblies']
      },
      {
        field: 'description',
        headerName: 'Description',
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'description'
        })(DisplayOnlyCell),
        cellStyle: { color: isEditing ? '#444' : '#777' },
        suppressNavigable: true
      },
      {
        field: 'uomId',
        headerName: 'UOM',
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'notes',
          title: 'Notes'
        })(DisplayOnlyCell),
        cellStyle: { color: isEditing ? '#444' : '#777' },
        suppressNavigable: true
      },
      {
        ...textEditor,
        cellClass: 'align-right',
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'notes',
          title: 'Notes'
        })(TextFieldGridCell),
        cellRendererParams: {
          ...textEditor.cellRendererParams,
          noDecimalLimit: true,
          textAlign: 'right',
          formatter: 'number'
        },
        field: 'quantity',
        headerName: 'Quantity',
        headerClass: 'align-right',
        ...numberFilterGridConfigs
      },
      {
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'notes',
          title: 'Notes'
        })(NotesCheckbox),
        cellRendererParams: {
          form,
          disabled: !isEditing
        },
        headerName: 'Notes',
        headerClass: 'text-center',
        field: 'hasNotes',
        hide: !isKitProduction,
        width: 80
      },
      {
        ...textEditor,
        cellRendererFramework: DynamicGridDataTooltip({
          dataField: 'notes',
          title: 'Notes'
        })(TextFieldGridCell),
        cellRendererParams: {
          ...textEditor.cellRendererParams,
          noDecimalLimit: true,
          textAlign: 'right',
          formatter: 'number'
        },
        field: 'disassemblePercent',
        headerName: 'Disassemble %',
        headerClass: 'align-right',
        hide: !disassembleOnReceipt,
        ...numberFilterGridConfigs
      },
      {
        field: 'delete',
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        headerName: '',
        hide: !isEditing,
        maxWidth: 50,
        width: 50
      }
    ]
    return colDef
  }
)

class CustomPinnedRowRenderer extends Component {
  render() {
    return <span style={this.props.style}>{this.props.value}</span>
  }
}

export class Assemblies extends Component {
  static propTypes = {
    componentTypeOptions: PropTypes.array.isRequired,
    disassembleOnReceipt: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isKitProduction: PropTypes.bool.isRequired,
    rollupOptions: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.gridKey = shortid.generate()
  }

  componentDidMount() {
    /* 
      without this, the core system Grid does not get the correct hide 
      flags on the immediate load -- SVE 10/20/2021
    */
    this.gridKey = shortid.generate()
  }

  shouldComponentUpdate(nextProps) {
    if (!deepEqual(nextProps, this.props)) {
      return true
    }
    return false
  }

  getPinnedData = rowData => {
    let ret = { disassemblePercent: null }
    if (rowData && rowData.length) {
      ret = rowData.reduce(
        (acc, next) => {
          acc.disassemblePercent += next.disassemblePercent
          return acc
        },
        { disassemblePercent: 0 }
      )
    }

    return [ret]
  }

  render() {
    const {
      componentTypeOptions,
      disassembleOnReceipt,
      form,
      height,
      rollupMarkupPercent,
      rollupOptions,
      isEditing,
      isKitProduction
    } = this.props

    return (
      <div
        className="product-analysis-flex-wrapper-1"
        style={{ margin: '0 auto', maxWidth: '90%', width: '90%' }}
      >
        <Paper
          className="product-analysis-two-col-flex-wrapper"
          style={{ margin: '0 0 10px 0', padding: '10px 20px' }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              margin: 0,
              maxWidth: '100%',
              width: '100%'
            }}
          >
            <Field
              propertyName="isKitProduction"
              label="Kit Production"
              component={Checkbox}
              wrapperStyle={{ margin: '0 1%', minWidth: '23%' }}
            />
            <Field
              propertyName="disassembleOnReceipt"
              label="Disassemble on Receipt"
              component={Checkbox}
              disabled={!isKitProduction}
              wrapperStyle={{ margin: '0 1%', minWidth: '23%' }}
            />
            <div style={{ margin: '-16px 1% 0 1%', minWidth: '23%' }}>
              <DDISelectField
                label="Component Type:"
                values={
                  componentTypeOptions?.toJS ? componentTypeOptions.toJS() : []
                }
                propertyName="componentType"
              />
            </div>
            <div style={{ margin: '-16px 1% 0 1%', minWidth: '23%' }}>
              <DDISelectField
                values={rollupOptions?.toJS ? rollupOptions.toJS() : []}
                label="Rollup Options:"
                propertyName="assemblyRollupType"
                // disabled={isKitProduction}
              />
            </div>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              margin: 0,
              maxWidth: '100%',
              width: '100%'
            }}
          >
            <Field
              propertyName="assemblyPrintOnSalesOrder"
              label="Print On Sales Order"
              component={Checkbox}
              disabled={isKitProduction}
              wrapperStyle={{ margin: '0 1%', minWidth: '23%' }}
            />
            <Field
              propertyName="autoGenerateSerialNumbers"
              label="Auto Generate Serial Numbers"
              component={Checkbox}
              wrapperStyle={{ margin: '0 1%', minWidth: '23%' }}
            />
            <Field
              propertyName="componentTypeCalc"
              label="Component Type Calculation"
              component={Checkbox}
              wrapperStyle={{ margin: '0 1%', minWidth: '23%' }}
            />
            {/* <DDITextField
              propertyName="rollupMarkupPercent"
              label="Rollup Markup %:"
              // type="number"
              mask="numeric"
              inputProps={{
                allowDecimal: true,
                // includeThousandsSeparator: false,
                max: 100,
                min: 0
              }}
              wrapperStyle={{ margin: '-16px 1% 0 1%', minWidth: '23%' }}
            /> */}
            <div
              style={{ alignItems: 'center', display: 'flex', minWidth: '23%' }}
            >
              <label
                style={{
                  color: isEditing ? '#444' : '#777',
                  fontWeight: 400,
                  fontSize: 13,
                  marginBottom: 0,
                  marginRight: 10
                }}
              >
                Rollup Markup %:
              </label>
              <DDINumberSpinner
                propertyName="rollupMarkupPercent"
                type="number"
                step={0.01}
                max={99.99}
                // initialValue={rollupMarkupPercent}
                inputProps={{
                  decimalScale: 2,
                  fontSize: 13,
                  fixedDecimalScale: true,
                  suffix: '%',
                  range: [0, 100]
                }}
                wrapperStyle={{ margin: 0 }}
              />
            </div>
          </div>
        </Paper>
        {/* END TOP ROW */}

        {/* START GRID */}
        <div className="product-analysis-flex-wrapper-1">
          <EditableGrid
            actOnCellChange
            propertyName="components"
            addButtonText="Add Product Assembly"
            title="Assemblies"
            form={form}
            emptyRow={{
              dataId: null,
              description: '',
              uomId: '',
              quantity: ''
            }}
            getRowNodeId={getRowNodeId}
            headerStyle={headerStyle}
            isEditing={isEditing}
            columnDefs={dynamicColDefs}
            requiredCols={['dataId']}
            colParams={{
              disassembleOnReceipt,
              isKitProduction
            }}
            height={height - 120}
            pinnedData={this.getPinnedData}
            frameworkComponents={{
              customPinnedRowRenderer: CustomPinnedRowRenderer
            }}
            gridWrapperStyle={{ flex: '1 1', maxWidth: '100%', width: '100%' }}
            focusCell="quantity"
            key={this.gridKey}
          />
        </div>
        {/* END GRID */}
      </div>
    )
  }
}

export const contextObj = {
  componentTypeOptions: form =>
    getMeta(form, 'componentTypeOptions', emptyList),
  disassembleOnReceipt: form => getField(form, 'disassembleOnReceipt', false),
  isKitProduction: form => getField(form, 'isKitProduction', false),
  rollupOptions: form => getMeta(form, 'rollupOptions', emptyList),
  isEditing: form => form.get('isEditing') || false,
  rollupMarkupPercent: form => getValue(form, 'rollupMarkupPercent', 0)
}

const assembliesWithContext = withContext(contextObj)(Assemblies)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  assembliesWithContext
)
