/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import NumberSpinner from 'ddiForm/NumberSpinner'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {
  Backdrop,
  CircularProgress,
  Icon,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import { NumberFormatter } from 'pages/SalesOrder/tabs/Detail/components/Pricing/components/NumberField'
import { formatNumber, getIn } from 'utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { debounce } from 'lodash'

const labelStyle = { fontSize: 12, fontWeight: 400, margin: 0, width: 75 }
const fieldStyle = {
  alignItems: 'center',
  display: 'flex',
  margin: '15px 0'
}
const totalStyle = {
  border: '1px solid #517b9c',
  borderRadius: 4,
  background: '#f4f7f9',
  fontWeight: 500,
  padding: '5px 10px',
  margin: 0,
  textAlign: 'center'
}
const alertStyle = {
  alignItems: 'center',
  backgroundColor: '#fcf8e3',
  borderColor: '#faebcc',
  color: '#d9534f',
  display: 'flex',
  fontSize: 11,
  lineHeight: '15px',
  margin: '5px 0',
  padding: 5,
  width: '100%'
}
const alertIconStyle = { color: '#d9534f', fontSize: 14, marginRight: 5 }

const mapStateToProps = (state, ownProps) => {
  const form = ownProps?.data?.form
  const formState = getIn(state, `ddiForm.${form}`)
  const isSaving = getIn(formState, 'isSaving') || false
  const isPosting = getIn(formState, 'isPosting') || false

  return {
    isSaving,
    isPosting
  }
}

class DepositInvoiceEntryModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // openTotal: props.data && props.data.openTotal ? props.data.openTotal : 0,
      allowShipmentsWOReceivedDeposits: false,
      date: new Date(),
      errorMessage: '',
      amount: '',
      percent: 0,
      calculationPercent: null,
      message: ''
    }
  }

  onNumberSpinnerChange = val => {
    const calcPct = this.state.calculationPercent
      ? this.state.calculationPercent
      : val
    const openTotal =
      this.props.data && this.props.data.openTotal
        ? this.props.data.openTotal
        : 0
    const amount = openTotal * (calcPct / 100)
    // console.log('onNumberSpinnerChange', val, amount)
    this.setState({
      amount,
      percent: val,
      calculationPercent: null,
      message: val ? `${val}% Deposit` : ''
    })
  }

  onDatepickerChange = date => this.setState({ date })

  onTextFieldChange = e => this.setState({ message: e.target.value })

  onNumberFormatterChange = values => {
    const openTotal =
      this.props.data && this.props.data.openTotal
        ? this.props.data.openTotal
        : 0
    const v = values.floatValue
    const percent = (v / openTotal) * 100
    console.log(values, percent)
    //

    this.setState({ amount: v })
  }

  onNumberFormatterBlur = e => {
    const { amount } = this.state
    const openTotal =
      this.props.data && this.props.data.openTotal
        ? this.props.data.openTotal
        : 0
    const percent = amount ? (amount / openTotal) * 100 : 0
    // console.log('onNumberFormatterBlur', percent, amount, openTotal)
    //

    if (amount > openTotal) {
      this.setState({
        amount: openTotal,
        calculationPercent: 100,
        percent: 100
      })
    } else {
      this.setState({
        calculationPercent: percent,
        percent
      })
    }
  }

  onToggleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  clearErrorMessage = e => this.setState({ errorMessage: '' })

  render() {
    const openTotal =
      this.props.data && this.props.data.openTotal
        ? this.props.data.openTotal
        : 0

    const {
      date,
      amount,
      percent,
      message,
      errorMessage,
      allowShipmentsWOReceivedDeposits
    } = this.state

    const isMobile = this?.props?.data?.isMobile || false
    const { isSaving, isPosting } = this.props

    return (
      <div style={{ width: '100%' }}>
        {isMobile && (isSaving || isPosting) ? (
          <Backdrop open style={{ zIndex: 2000 }}>
            <CircularProgress size={50} thickness={10} />
          </Backdrop>
        ) : null}
        <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
          <div
            style={{
              width: isMobile ? '100%' : 225,
              marginRight: isMobile ? 0 : 30
            }}
          >
            <p style={totalStyle}>
              <span style={{ marginRight: 5 }}>Open Total:</span>{' '}
              {`${formatNumber(openTotal)}`}
            </p>
            <div style={fieldStyle}>
              <label style={labelStyle}>Amount:</label>
              <div style={{ width: 150 }}>
                <Input
                  value={amount}
                  fullWidth
                  inputComponent={NumberFormatter}
                  onChange={this.onNumberFormatterChange}
                  onBlur={this.onNumberFormatterBlur}
                  inputProps={{
                    allowNegative: true,
                    decimalScale: 2,
                    noDecimalLimit: false,
                    decimalSeparator: '.',
                    fontSize: 14,
                    fixedDecimalScale: true,
                    // inputRef: textField,
                    maxLength: 999,
                    paddingRight: 5,
                    textAlign: 'right',
                    thousandSeparator: '',
                    prefix: '$',
                    suffix: ''
                  }}
                />
              </div>
            </div>
            <div style={fieldStyle}>
              <label style={labelStyle}>Percent:</label>
              <div
                style={{
                  width: 150,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <NumberSpinner
                  value={percent}
                  isField={false}
                  ref={el => (this.numberSpinner = el)}
                  updateValueCallback={this.onNumberSpinnerChange}
                  step={1.0}
                  min={0.0}
                  max={100}
                  inputProps={{
                    decimalScale: 2,
                    fontSize: 13,
                    fixedDecimalScale: true
                  }}
                />
              </div>
            </div>

            <div style={fieldStyle}>
              <label style={labelStyle}>Date:</label>
              <div style={{ width: 150 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    id="date"
                    format="MM/DD/YYYY"
                    onChange={this.onDatepickerChange}
                    value={date}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Icon
                              style={{
                                fontSize: 18,
                                position: 'relative',
                                left: 8
                              }}
                            >
                              event
                            </Icon>
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div
            className="deposit-invoice-entry-modal-textfield-wrapper"
            style={isMobile ? { width: '100%' } : { minWidth: 295 }}
          >
            <TextField
              value={message}
              onChange={this.onTextFieldChange}
              placeholder="Enter Message"
              multiline
              rows="8"
              variant="outlined"
              fullWidth
              label="Message"
            />
          </div>
        </div>
        {errorMessage ? (
          <div className="alert alert-warning" style={alertStyle}>
            <Icon size="small" style={alertIconStyle}>
              warning
            </Icon>{' '}
            <span>{errorMessage}</span>
            <span>
              <IconButton onClick={this.clearErrorMessage} size="small">
                <Icon size="small" fontSize="small" style={{ color: '#999' }}>
                  highlight_off
                </Icon>
              </IconButton>
            </span>
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DepositInvoiceEntryModal)
