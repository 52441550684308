/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { getIn, getRgb, plainDeepEqual } from 'utils'
import { debounce } from 'lodash'

import ActivityTooltipTemplate from 'pages/Dashboard/components/Calendar/components/ActivityTooltipTemplate'
import {
  launchNotesModal as launchNotesModalAction
} from 'modals/NotesModal/actions'

import {
  getActivityTooltip as getActivityTooltipAction,
  lockActivity as lockActivityAction
} from 'modals/EventModal/actions'

import { withTooltip } from 'hoc'

import { connect } from 'react-redux'

const mapStateToProps = state => ({
  eventModal: getIn(state, 'ddiForm.eventModal') || {}
})

class LaunchEventModalCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
    // value: PropTypes.string.isRequired
  }

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     numClicks: 0
  //   }
  // }

  launchEventModal = debounce(event => {
    const {
      data: { dataId },
      eventModal,
      colDef: { form }
    } = this.props

    if (dataId && !Object.keys(eventModal).length) {
      this.props.dispatch(lockActivityAction(form, { dataId }))
    }
  }, 300)

  render() {
    const {
      data: { color },
      value,
      valueFormatted
    } = this.props
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
      >
        <div style={{ marginRight: 5 }}>
          <svg height="20" width="20">
            <circle
              cx="10"
              cy="10"
              r="6"
              fill={color ? getRgb(color) : 'rgb(81,123,156)'}
            />
          </svg>
        </div>
        <a onClick={this.launchEventModal}>{value || valueFormatted}</a>
      </div>
    )
  }
}

const composed = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(LaunchEventModalCell)

export default withTooltip({
  anchorToGridCellText: true,
  cacheData: false,
  component: ActivityTooltipTemplate,
  keyToActionMap: {
    m: args => lockActivityAction(args.form, { dataId: args.dataId }),
    1: args => launchNotesModalAction(args.form)
  },
  networkRequestPromise: getActivityTooltipAction,
  popperOptions: {
    modifiers: { preventOverflow: { boundariesElement: 'window' } }
  },
  position: 'right-end',
  sensitivity: 100,
  style: { width: 400 },
  timeout: 1000,
  inputComponent: false,
  requestArgs() {
    const {
      colDef: { form }
    } = this.props
    return { dataId: this.props.data.dataId, form }
  }
})(composed)
