import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { emptyList, is, getIn, plainDeepEqual, usePrevious } from 'utils'
import { Icon, IconButton } from '@material-ui/core'
import memoize from 'memoize-one'
import Grid from 'grid'
import withRowTotals from 'hoc/withRowTotals'
import { getSidebarRowData } from 'pages/SalesOrder/utils'
import {
  addProcurementGridRow,
  cleanUpProcurementGridRow,
  handleProcurementGridUpdate,
  handleOpenTransfersCommentEditor
} from 'pages/SalesOrder/actions'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from './components/IndexSearchCell'
import TextFieldGridCell from './components/TextFieldGridCell'

// comments: "This is a stock transfer"
// dataId: ""
// fromWarehouseId: "02"
// hasComments: true
// isEditable: false
// quantity: 1
// shipViaId: ""
// status: ""
// statusDescription: null

const CommentsEditorCell = ({ value, data, colDef, node }) => {
  const { rowIndex } = node
  const disabled = !data.fromWarehouseId
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = colDef?.cellRendererParams?.dispatch || null

  const icon = value ? 'check_circle' : 'block'

  const handleOpenComments = e => {
    if (form && dispatch) {
      dispatch(
        handleOpenTransfersCommentEditor(form, {
          comments: data?.comments,
          rowIndex
        })
      )
    }
  }

  const color = disabled
    ? '#999'
    : icon === 'check_circle'
    ? '#5cb85c'
    : '#d9534f'

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <IconButton disabled={disabled} onClick={handleOpenComments}>
        <Icon
          style={{
            color,
            fontSize: 14,
            verticalAlign: 'middle'
          }}
        >
          {icon}
        </Icon>
      </IconButton>
    </div>
  )
}

const DeleteTransferGridCell = ({
  value,
  node,
  colDef,
  data = {},
  ...other
}) => {
  const { rowIndex } = node
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = colDef?.cellRendererParams?.dispatch || null
  const isPosting = colDef?.cellRendererParams?.isPosting || false
  const disabled = !data.fromWarehouseId || isPosting

  const deleteWarehouse = e => {
    if (form && dispatch && !isPosting) {
      dispatch(
        handleProcurementGridUpdate.try(form, {
          isRowDeletionHandler: true,
          field: '',
          value: '',
          rowIndex,
          gridName: 'linkedTransfers'
        })
      )
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <IconButton disabled={disabled} onClick={deleteWarehouse} tabIndex={-1}>
        <Icon
          style={{
            color: disabled ? '#999' : '#d9534f',
            display: 'inline-block',
            fontSize: 16
          }}
        >
          cancel
        </Icon>
      </IconButton>
    </div>
  )
}

const setPinnedRowData = grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      if (next.quantity) {
        acc.quantity += next.quantity
      }
      return acc
    },
    {
      dataId: null,
      fromWarehouseId: 'Total:',
      quantity: 0,
      shipViaId: null,
      delete: null
    }
  )

  return [result]
}

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: setPinnedRowData
})(Grid)

const pinnedRowRenderer = ({ value = '', colDef, ...other }) => {
  const { field } = colDef
  return (
    <div style={field === 'quantity' ? { textAlign: 'right' } : null}>
      {value}
    </div>
  )
}

const getColumnDefs = memoize(
  ({
    form,
    isMobile,
    dispatch,
    isEditing,
    isPosting,
    hideLinkedTransferShipVia,
    canChangeTransfer
  }) => {
    const params = {
      form,
      isEditing,
      isPosting,
      allowMultipleSearches: true,
      gridName: 'linkedTransfers',
      disabled: !canChangeTransfer
    }

    let colDefs = [
      {
        colId: 'dataId',
        field: 'dataId',
        headerName: 'Transfer',
        minWidth: 100
      },
      {
        colId: 'fromWarehouseId',
        field: 'fromWarehouseId',
        headerName: 'From Whse',
        cellRendererFramework: isMobile
          ? IndexSearchCell
          : TooltipField({
              anchorToGridCellText: true,
              indexSearchType: 'warehouse',
              position: 'right-end',
              recordNameKey: 'value',
              sensitivity: 100,
              type: 'indexSearch'
            })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          indexSearchType: 'warehouse',
          isMobile,
          meta: {
            allowInstantSearch: true,
            minimumKeywordLength: 2,
            allowSearchAll: true
          }
        },
        cellStyle: isMobile ? { paddingLeft: 0, paddingRight: 0 } : null,
        minWidth: 150,
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      },
      {
        colId: 'quantity',
        field: 'quantity',
        headerName: 'Qty',
        minWidth: 100,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          formatter: 'number',
          noDecimalLimit: true,
          isRequired: true,
          disabledFn: data => !data.fromWarehouseId
        },
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      },
      {
        colId: 'shipViaId',
        field: 'shipViaId',
        headerName: 'Ship Via',
        cellStyle: isMobile ? { paddingLeft: 0, paddingRight: 0 } : null,
        cellRendererFramework: isMobile
          ? IndexSearchCell
          : TooltipField({
              anchorToGridCellText: true,
              indexSearchType: 'shipVia',
              position: 'right-end',
              recordNameKey: 'value',
              sensitivity: 100,
              type: 'indexSearch'
            })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          indexSearchType: 'shipVia',
          isMobile,
          meta: {
            allowInstantSearch: true,
            minimumKeywordLength: 2,
            allowSearchAll: true
          },
          disabledFn: data => !data.fromWarehouseId
        },
        minWidth: 150,
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      },
      {
        colId: 'hasComments',
        field: 'hasComments',
        headerName: 'Cmmts',
        headerStyle: { textAlign: 'center' },
        cellRendererFramework: CommentsEditorCell,
        cellRendererParams: {
          ...params,
          dispatch,
          isMobile
        },
        minWidth: 90,
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      },
      {
        colId: 'delete',
        cellRendererFramework: DeleteTransferGridCell,
        cellRendererParams: {
          ...params,
          isMobile,
          dispatch
        },
        minWidth: 50,
        maxWidth: 50,
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      }
    ]

    if (hideLinkedTransferShipVia) {
      colDefs = colDefs.reduce((acc, next) => {
        if (next.colId !== 'shipViaId') {
          acc = acc.concat(next)
        }

        return acc
      }, [])
    }

    return colDefs
  }
)

const getGridHeight = memoize((rowData = []) => {
  let height = 150
  if (!rowData.length) {
    return height
  }

  height = rowData.length * 28 + 132
  return height
}, plainDeepEqual)

const emptyGridRow = {
  comments: '',
  dataId: '',
  fromWarehouseId: '',
  hasComments: false,
  isEditable: true,
  quantity: null,
  shipViaId: '',
  status: '',
  statusDescription: null
}

const TransfersGrid = ({
  form,
  rowDataList,
  isMobile = false,
  isPosting,
  selectedRowIndex,
  hideLinkedTransferShipVia,
  canChangeTransfer
}) => {
  const rowData = rowDataList?.toJS ? rowDataList.toJS() : []
  const dispatch = useDispatch()
  let gridApi = null

  const addBlankRow = () => {
    dispatch(
      addProcurementGridRow(form, {
        gridName: 'linkedTransfers',
        emptyGridRow,
        rowKey: 'fromWarehouseId'
      })
    )
  }

  useEffect(() => {
    if (!rowData.length) {
      addBlankRow()
    }

    return () => {
      if (is.number(selectedRowIndex)) {
        dispatch(
          cleanUpProcurementGridRow(form, {
            gridName: 'linkedTransfers',
            selectedRowIndex,
            rowKey: 'fromWarehouseId'
          })
        )
      }
    }
  }, [])

  const onGridReady = params => {
    gridApi = params.api
  }

  return (
    <div style={{ paddingBottom: 40, width: 485 }}>
      <GridWithRowTotals
        addBlankRow={addBlankRow}
        addButtonText="Add Stock Transfers"
        onGridReady={onGridReady}
        getRowNodeId={data => data.fromWarehouseId}
        height={getGridHeight(rowData)}
        form={form}
        rowData={rowData}
        columnDefs={getColumnDefs({
          form,
          isMobile,
          isPosting,
          hideLinkedTransferShipVia,
          canChangeTransfer,
          dispatch
        })}
        frameworkComponents={{
          customPinnedRowRenderer: pinnedRowRenderer
        }}
        showAddButtonAlways
        reactUi={false}
      />
    </div>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  rowDataList: form => getSidebarRowData(form, 'linkedTransfers', emptyList),
  selectedRowIndex: form => getIn(form, 'fields.lineItems.selectedRowIndex'),
  hideLinkedTransferShipVia: form =>
    getSidebarRowData(form, 'hideLinkedTransferShipVia', false),
  canChangeTransfer: form => getSidebarRowData(form, 'canChangeTransfer', false)
})(TransfersGrid)
