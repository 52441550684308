import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { clearTruckRoute } from './actions'
import { connect } from 'react-redux'
import { getIn, plainDeepEqual } from 'utils'

const mapDispatchToProps = dispatch => ({
  clearTruckRoute: (rowIndex, form) => dispatch(clearTruckRoute(rowIndex, form))
})

class ClearTruckRoutesGridCell extends Component {
  static propTypes = {
    clearTruckRoute: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  clearTruckRoute = () => {
    // console.log('clearTruckRoute', this.props)
    // debugger
    const {
      clearTruckRoute,
      rowIndex,
      colDef: { form }
    } = this.props
    clearTruckRoute(rowIndex, form)
  }

  render() {
    const {
      colDef: { type }
    } = this.props

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{ color: '#d9534f', display: 'inline-block', fontSize: 16 }}
          onClick={this.clearTruckRoute}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ClearTruckRoutesGridCell)
