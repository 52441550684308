/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import {
  DDIDatePicker,
  DDIIndexSearch,
  DDISelectField,
  DDINumberSpinner,
  DDITextField,
  DDIToggle
} from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import {
  createSearchInputRow,
  setGridHeight,
  styles
} from 'pages/ProductMaster/utils'

import {
  emailAddress,
  exactLength,
  required,
  maxLength,
  minLength
} from 'ddiForm/validations'

// import { InputAdornment, TextField } from '@material-ui/core'
import { getIn, getMeta, emptyList, isSecureFieldDisabled } from 'utils'
import '../../styles/master-style.scss'

const searchInputs = [
  {
    key: 'taxGroup',
    label: 'Product Tax Group',
    description: 'taxGroupDescription'
  },
  {
    key: 'cycleCountGroupId',
    label: 'Cycle Count Group Code',
    description: 'cycleCountGroupDescription'
  },
  {
    key: 'productLabelId',
    label: 'Product Label Code',
    description: 'productLabelDescription'
  }
]

const searchInputsJSX = searchInputs.map((item, idx) =>
  createSearchInputRow(item.key, item.label, item.description, styles)
)
export const General = props => {
  const {
    commissionableOptions,
    isEditing,
    securedFields,
    warrantyTypeOptions,
    isCanadianCompanyOrHasHawaiianTax,
    taxableOptions
  } = props

  return (
    <div className="layout-flex">
      <div className="flex-wrapper-1">
        <div className="three-col-flex-wrapper">
          <div className="three-col-flex">
            <DDICardWrapper
              title="General Settings"
              contentStyle={{ padding: 10 }}
            >
              <div style={{ marginBottom: 30 }}>
                <div style={styles.twoColInputsFlex.wrapper}>
                  <div style={styles.twoColInputsFlex.left}>
                    <DDITextField
                      propertyName="sortSequenceNumber"
                      label="Sort Sequence Number:"
                      mask="numeric"
                      inputProps={{
                        allowLeadingZeros: true,
                        thousandSeparator: false,
                        maxLength: 10
                      }}
                      validate={[exactLength(10)]}
                    />
                  </div>
                  <div style={styles.twoColInputsFlex.left}>
                    <DDITextField
                      propertyName="catalogPageNumber"
                      label="Catalog Page Number:"
                    />
                  </div>
                </div>
                <div style={styles.twoColInputsFlex.wrapper}>
                  <div
                    style={{
                      ...styles.twoColInputsFlex.left,
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <label
                        style={{
                          color: isEditing ? '#444' : '#777',
                          fontWeight: 400,
                          fontSize: 12,
                          marginBottom: 0,
                          marginRight: 10,
                          lineHeight: '16px'
                        }}
                      >
                        A/R Invoice Discount %:
                      </label>
                      <DDINumberSpinner
                        propertyName="arInvoiceDiscountPercent"
                        type="number"
                        step={0.01}
                        max={99.99}
                        inputProps={{
                          decimalScale: 2,
                          fontSize: 13,
                          fixedDecimalScale: true,
                          suffix: '%'
                        }}
                        wrapperStyle={{ margin: 0 }}
                      />
                    </div>
                  </div>
                  <div style={styles.twoColInputsFlex.right}>
                    <DDITextField
                      propertyName="mfgClassCode"
                      label="MFG Class Code:"
                    />
                  </div>
                </div>
                <div style={styles.twoColInputsFlex.wrapper}>
                  <div
                    style={{
                      ...styles.twoColInputsFlex.left,
                      alignItems: 'center'
                    }}
                  >
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                      <label
                        style={{
                          color: isEditing ? '#444' : '#777',
                          fontWeight: 400,
                          fontSize: 12,
                          marginBottom: 0,
                          marginRight: 10,
                          lineHeight: '16px'
                        }}
                      >
                        Ship Qty Tolerance %:
                      </label>
                      <DDINumberSpinner
                        propertyName="shipTolerancePercent"
                        type="number"
                        step={0.01}
                        max={99.99}
                        inputProps={{
                          decimalScale: 2,
                          fontSize: 13,
                          fixedDecimalScale: true,
                          suffix: '%'
                        }}
                        wrapperStyle={{ margin: 0 }}
                      />
                    </div>
                  </div>
                  <div style={styles.twoColInputsFlex.right}>
                    <DDITextField
                      propertyName="catalogSection"
                      label="Catalog Section:"
                    />
                  </div>
                </div>
                <div style={styles.twoColInputsFlex.wrapper}>
                  <div style={styles.twoColInputsFlex.left}>
                    <DDITextField
                      propertyName="pricingServiceVelocity"
                      label="Pricing Service Velocity:"
                    />
                  </div>
                  <div style={styles.twoColInputsFlex.right}>
                    <DDITextField
                      propertyName="pricingServiceTemplate"
                      label="Pricing Service Template:"
                    />
                  </div>
                </div>
                <div style={styles.twoColInputsFlex.wrapper}>
                  <div
                    style={{ ...styles.twoColInputsFlex.left, maxWidth: '50%' }}
                  >
                    <DDITextField
                      propertyName="freightClass"
                      label="Freight Class:"
                    />
                  </div>
                </div>
              </div>
              {searchInputsJSX}
            </DDICardWrapper>
          </div>
          <div className="three-col-flex">
            <DDICardWrapper
              title="Safety Data Sheet"
              contentStyle={{ padding: 10 }}
            >
              <div style={styles.twoColInputsFlex.wrapper}>
                <div style={styles.twoColInputsFlex.left}>
                  <DDITextField
                    propertyName="msdSheetIDCode"
                    label="ID Code:"
                  />
                </div>
                <div style={styles.twoColInputsFlex.right}>
                  <DDIDatePicker
                    propertyName="msdSheetDateChanges"
                    label="Date Changes:"
                    views={['date']}
                  />
                </div>
              </div>
            </DDICardWrapper>
            <DDICardWrapper
              title="Warranty Info"
              contentStyle={{ padding: 10 }}
            >
              <DDIToggle propertyName="isWarranty" label="Warranty" />
              <div style={styles.twoColInputsFlex.wrapper}>
                <div style={styles.twoColInputsFlex.left}>
                  <DDISelectField
                    values={warrantyTypeOptions.toJS()}
                    label="Type"
                    propertyName="warrantyType"
                  />
                </div>
                <div style={styles.twoColInputsFlex.right}>
                  <DDITextField propertyName="warrantyMonths" label="Months:" />
                </div>
              </div>
            </DDICardWrapper>
            <DDICardWrapper
              title="Certification Code"
              contentStyle={{ padding: 10 }}
            >
              <DDITextField
                propertyName="certificationCode"
                label="Cert Code:"
              />
            </DDICardWrapper>
          </div>
          <div className="three-col-flex">
            <DDICardWrapper
              title="Configuration"
              contentStyle={{ padding: 10 }}
            >
              <div style={{ marginBottom: 10 }}>
                <DDISelectField
                  fullWidth
                  label="Commissionable:"
                  propertyName="commissionableType"
                  values={commissionableOptions.toJS()}
                />
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  {isCanadianCompanyOrHasHawaiianTax ? (
                    <DDISelectField
                      propertyName="taxableType"
                      label="Taxable"
                      values={
                        taxableOptions && taxableOptions?.toJS
                          ? taxableOptions.toJS()
                          : []
                      }
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <AssociatedFieldsWrapper>
                      <DDIToggle propertyName="isTaxable" label="Taxable" />
                    </AssociatedFieldsWrapper>
                  )}
                </div>
                <div style={styles.twoColToggleFlex.right}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isFreightTaxable"
                      label="Tax Feight"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isSerialNumberRequired"
                      label="Serial Number Required"
                    />
                  </AssociatedFieldsWrapper>
                </div>
                <div style={styles.twoColToggleFlex.right}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle propertyName="useLots" label="Lot Required" />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="vendorReturnable"
                      label="Vendor Returnable"
                    />
                  </AssociatedFieldsWrapper>
                </div>
                <div style={styles.twoColToggleFlex.right}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isWarehousePick"
                      label="Warehouse Pick"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isShowroomDisplay"
                      label="Showroom Display"
                    />
                  </AssociatedFieldsWrapper>
                </div>
                <div style={styles.twoColToggleFlex.right}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle propertyName="isRepairItem" label="Repair" />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isMarginPriceUpdateDisabled"
                      label="Disable Margin Price"
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'isMarginPriceUpdateDisabled',
                        isEditing,
                        securedFields
                      )}
                    />
                  </AssociatedFieldsWrapper>
                </div>
                <div style={styles.twoColToggleFlex.right}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isCoopEligable"
                      label="Co-op Eligible"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="isSOCostChangeDisabled"
                      label="Disable S/O Cost Chg"
                    />
                  </AssociatedFieldsWrapper>
                </div>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="rebateInfoRequiredInSO"
                      label="Rebate Info Required in S/O"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={styles.twoColToggleFlex.wrapper}>
                <div style={styles.twoColToggleFlex.left}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="soShipAllBypassAvail"
                      label="S/O Ship All Ignore Available"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
            </DDICardWrapper>
          </div>
        </div>
      </div>
    </div>
  )
}

General.propTypes = {
  commissionableOptions: PropTypes.array.isRequired,
  isEditing: PropTypes.bool.isRequired,
  warrantyTypeOptions: PropTypes.array.isRequired
}

export const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  commissionableOptions: form =>
    getMeta(form, 'commissionableOptions', emptyList),
  warrantyTypeOptions: form => getMeta(form, 'warrantyTypeOptions', emptyList),
  securedFields: form => getIn(form, 'meta.securedFields') || List(),
  isCanadianCompanyOrHasHawaiianTax: form =>
    getIn(form, 'meta.isCanadianCompanyOrHasHawaiianTax') || false,
  taxableOptions: form => getIn(form, 'meta.taxableOptions') || emptyList
}

export default withContext(contextObj)(General)
