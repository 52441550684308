import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import memoize from 'memoize-one'
import { GridField, withContext } from 'ddiForm'
import {
  dateFilterComparator,
  formatDateFields,
  plainDeepEqual,
  // getField,
  getValue,
  emptyList,
  mapColumnHeaders
} from 'utils'
import {
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/ProductMaster/utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import withDimensions from 'hoc/withDimensions'
import Grid from 'grid'

import './styles/master-style.scss'

const dateFields = ['expectedDate', 'transferDate']

const objFields = [
  // 'uOM',
  'toWarehouse',
  'fromWarehouse',
  'writer'
]

const numericFields = ['quantityDue', 'quantityReceived', 'quantityTransferred']

export const getRowNodeId = ({ transferNumber }) => transferNumber

export const formatColumns = (col, type, isMobile = false) => {
  if (col.field === 'transferNumber') {
    col = {
      ...col,
      cellRendererFramework: isMobile ? null : OpenScreenLinkCell,
      cellRendererParams: {
        hasLink: true
      },
      field: 'transferNumber',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      linkTo: type
    }
  }

  if (dateFields.includes(col.field)) {
    col = {
      ...col,
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      valueFormatter: formatDateFields
    }
  }

  if (col.field === 'uOM') {
    col = {
      ...col,
      field: 'uom.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'UOM',
      tooltipField: 'uom.tooltip'
    }
  }

  if (objFields.includes(col.field)) {
    col = {
      ...col,
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      valueFormatter: params => params.value?.value
    }
  }

  if (col.field === 'status') {
    col = {
      ...col,
      cellStyle: { color: '#c140f7' },
      // field: 'status.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Status',
      tooltipField: 'status.tooltip',
      valueFormatter: params => {
        const { value = '' } = params.value

        return value
      }
    }
  }

  if (col?.field && numericFields.includes(col.field)) {
    col = {
      ...col,
      ...numberFilterGridConfigs
    }
  }

  return col
}

export class Transfers extends Component {
  shouldComponentUpdate(nextProps) {
    if (
      this.gridApi &&
      !plainDeepEqual(nextProps.transfers, this.props.transfers)
    ) {
      this.gridApi.setFilterModal(null)
    }
    return true
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  render() {
    const { height, isMobile = false } = this.props
    let { transfers, columns } = this.props

    transfers = transfers.toJS ? transfers.toJS() : transfers
    columns = columns.toJS ? columns.toJS() : columns

    let columnDefs = columns
      ? columns
          .map(mapColumnHeaders)
          .map(col => formatColumns(col, 'stockTransfer', isMobile))
      : []

    columnDefs = isMobile
      ? columnDefs.map((col, i) => {
          col = {
            ...col,
            minWidth: 150
          }

          return col
        })
      : columnDefs

    return (
      <div className="product-analysis-layout-flex-column">
        <div className="product-analysis-overflow-wrapper">
          <div style={{ height: isMobile ? height : height * 0.95 }}>
            <Grid
              title="Stock Transfers"
              getRowNodeId={getRowNodeId}
              headerStyle={
                isMobile ? { height: 0, margin: 0, padding: 0 } : headerStyle
              }
              columnDefs={columnDefs}
              gridHeight={isMobile ? height : height * 0.95}
              rowData={transfers}
              rowSelection="single"
              enableSorting
              width="100%"
              fullWidth
            />
          </div>
        </div>
      </div>
    )
  }
}

Transfers.propTypes = {
  height: PropTypes.number.isRequired,
  transfers: PropTypes.array.isRequired
}

export const contextObj = {
  transfers: form => getValue(form, 'transfers.data', emptyList),
  columns: form => getValue(form, 'transfers.meta.columns', emptyList)
}

export const transfersWithContext = withContext(contextObj)(Transfers)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  transfersWithContext
)
