/* eslint react/sort-comp: 0, react/jsx-fragments: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { removeModal } from 'modals/actions'
import * as actions from 'pages/CustomerMaster/actions'

const mapStateToProps = state => ({
  dataId:
    getIn(state, 'ddiForm.customerMaster.fields.taxMatrix.dataId.value') || '',
  isLocked:
    getIn(state, 'ddiForm.customerMaster.values.taxMatrix.isLocked') || false,
  isNew: getIn(state, 'ddiForm.customerMaster.values.taxMatrix.isNew') || false,
  matrixSet: getIn(state, 'ddiForm.customerMaster.taxMatrix.matrixSet') || false
})

const mapDispatchToProps = dispatch => ({
  closeTaxMatrixModal: () =>
    dispatch(actions.closeTaxMatrixModal('customerMaster')),
  lockTaxMatrix: ({ dataId }) =>
    dispatch(actions.lockTaxMatrix('customerMaster', { dataId })),
  saveTaxMatrix: ({ dataId }) =>
    dispatch(actions.saveTaxMatrix('customerMaster', { dataId })),
  unlockTaxMatrix: ({ dataId }) =>
    dispatch(actions.unlockTaxMatrix('customerMaster', { dataId }))
})

class TaxMatrixModalActions extends Component {
  static propTypes = {
    dataId: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    lockTaxMatrix: PropTypes.func.isRequired,
    modalEnabled: PropTypes.bool.isRequired,
    saveTaxMatrix: PropTypes.func.isRequired,
    unlockTaxMatrix: PropTypes.func.isRequired
  }

  lockTaxMatrix = () => {
    const { dataId, lockTaxMatrix } = this.props
    if (dataId) {
      lockTaxMatrix({ dataId })
    }
  }

  unlockTaxMatrix = () => {
    const { dataId, unlockTaxMatrix } = this.props
    if (dataId) {
      unlockTaxMatrix({ dataId })
      this.removeModal()
    }
  }

  closeModal = () => {
    const { closeTaxMatrixModal, dataId, isLocked } = this.props
    if (dataId && isLocked) {
      this.unlockTaxMatrix()
    } else {
      closeTaxMatrixModal() // notify the reducer that the modal is closing
      this.removeModal()
    }
  }

  removeModal = () => {
    this.props.dispatch(removeModal(this.props.form, this.props.modal.id))
  }

  saveTaxMatrix = () => {
    const { dataId, saveTaxMatrix } = this.props
    saveTaxMatrix({ dataId })
    if (dataId) {
      this.removeModal()
    }
  }

  deleteTaxMatrix = () => {
    const { dataId } = this.props

    if (dataId) {
      this.props.dispatch(
        actions.deleteTaxMatrix('customerMaster', {
          dataId,
          modalId: this.props.modal.id
        })
      )
    }
  }

  getModalButtons = () => {
    const { dataId, isLocked, isNew } = this.props
    // debugger
    let buttons
    if (!dataId) {
      buttons = (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={this.saveTaxMatrix}
            style={{ marginRight: 5 }}
          >
            OK
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.closeModal}
            style={{ marginRight: 5 }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={this.closeModal}>
            Exit
          </Button>
        </Fragment>
      )
    } else {
      buttons = isLocked ? (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={this.saveTaxMatrix}
            style={{ marginRight: 5 }}
          >
            Save
          </Button>
          {!isNew ? (
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 5 }}
              onClick={this.deleteTaxMatrix}
            >
              Delete
            </Button>
          ) : null}
          <Button variant="contained" color="primary" onClick={this.closeModal}>
            Exit
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            variant="contained"
            color="primary"
            onClick={this.lockTaxMatrix}
            style={{ marginRight: 5 }}
          >
            Edit
          </Button>
          <Button variant="contained" color="primary" onClick={this.closeModal}>
            Exit
          </Button>
        </Fragment>
      )
    }

    return buttons
  }

  render() {
    return this.getModalButtons()
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(TaxMatrixModalActions)
