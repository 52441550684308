import { call, delay, fork, put, select, take, spawn } from 'redux-saga/effects'
import { fromJS, getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import {
  cancelEditAsync,
  getEntityAsync,
  resetMasterFields
} from 'ddiForm/MasterScreen/actions'
import { CANCEL_EDIT } from 'ddiForm/MasterScreen/constants'
import { changeFormTab, tryChangeFormTab } from 'ddiForm/actions'

import { ON_PRIMARY_GRID_DATA_VALIDATED } from 'components/EditableGrid/constants'
import templateSagas, {
  unlockTemplateProcess
} from 'components/MasterScreen/Template/sagas'
import { UNLOCK_TEMPLATE } from 'components/MasterScreen/Template/constants'
import { showInternalNotesModal } from 'modals/NotesModal/sagas'
import { confirmationModal, warningModal } from 'modals/sagas'
import { removeModal } from 'modals/actions'
import { ADD_MODAL, CANCELED, CONFIRMED } from 'modals/constants'

import { api } from 'services'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { mapGetRecordResponse } from '../utils'

const requiresPropertyChange = [
  'expenseGLIds',
  'productPatterns',
  'serialNumberPatterns'
]

export function* propertyChangeProcess(
  form,
  propertyName,
  propertyChangeData,
  rowIndex
) {
  const formState = yield select(getFormSelector(form))
  const selectedSecondaryTab =
    getIn(formState, 'masterOptions.selectedSecondaryTab') || ''
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const templateKey = getIn(formState, 'values.templateKey') || ''
  const gridIdentifier = propertyName === 'expenseGLIds' ? 'glId' : 'dataId'
  const isEditing = getIn(formState, 'isEditing') || false
  let rowData = getIn(formState, `fields.${propertyName}.rowData`) || fromJS([])
  rowData = rowData?.toJS ? rowData.toJS() : []

  if (!dataId || !rowData?.length || !isEditing) {
    return
  }

  const rowId = propertyChangeData?.moreInfo?.rowId

  yield put(actions.vendorMasterPropertyChange.request(form))

  const baseApiParams = {
    groupNames: [selectedSecondaryTab],
    properties: {
      [propertyName]: rowData.reduce((acc, next) => {
        if (next?.[gridIdentifier]) {
          acc = acc.concat({
            [gridIdentifier]: next[gridIdentifier]
          })
        }
        return acc
      }, [])
    }
  }

  const apiParams = templateKey
    ? { templateKey, ...baseApiParams }
    : { dataId, ...baseApiParams }

  const { response, error } = yield call(
    api.vendorMasterPropertyChange,
    apiParams
  )

  if (response) {
    yield put(
      actions.vendorMasterPropertyChange.success(
        {
          ...response,
          propertyChanged: propertyName,
          rowIndex
        },
        form
      )
    )
    // debugger
  } else {
    yield put(
      actions.vendorMasterPropertyChange.failure(
        {
          ...error,
          propertyChanged: propertyName,
          rowIndex
        },
        form
      )
    )
    yield fork(displayValidationErrors, error)
    // debugger
  }
}

export function* displayValidationErrors(error) {
  if (
    error &&
    error.validationErrors &&
    Array.isArray(error.validationErrors)
  ) {
    const errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.message}\n`)
      return acc
    }, '')
    yield call(warningModal, errorMessageString, 'Attention!')
  }
}

export function* propertyChangeTriggerListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, newData = {}, rowIndex },
      type
    } = yield take([
      ON_PRIMARY_GRID_DATA_VALIDATED,
      CONSTANTS.HANDLE_ROW_DATA_UPDATE
    ])

    if (
      form === formListener &&
      requiresPropertyChange.includes(propertyName)
    ) {
      // debugger
      yield fork(propertyChangeProcess, form, propertyName, newData, rowIndex)
    }
  }
}

export function* deleteVendorProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const templateKey = getIn(formState, 'values.templateKey') || ''
  const description =
    getIn(formState, 'fields.description.value') ||
    getIn(formState, 'values.description') ||
    ''

  yield call(
    confirmationModal,
    `Are you sure you want to delete Vendor - ${dataId}: "${description}"?`,
    'Void?'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return
  }

  yield put(actions.deleteVendor.request(form))

  const apiParams = templateKey ? { templateKey } : { dataId }
  const { response, error } = yield call(api.deleteVendor, apiParams)

  if (response) {
    yield put(actions.deleteVendor.success(response, form))
  } else {
    yield put(actions.deleteVendor.failure(error, form))
  }
}

export function* deleteVendorListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.DELETE_VENDOR.TRY)

    if (form === formListener) {
      yield fork(deleteVendorProcess, form)
    }
  }
}

export function* createVendorProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'values.preNewText')
  const templateKey = getIn(formState, 'values.templateKey') || ''
  const isEditing = getIn(formState, 'isEditing') || false
  const autoGenerateId = getIn(formState, 'meta.autoGenerateId') || false
  let allTabs = getIn(formState, 'masterOptions.tabs') || fromJS([])
  allTabs = allTabs?.toJS ? allTabs.toJS() : []

  const tabIds = allTabs
    ?.find(x => x.title === 'Setup')
    ?.tabs?.reduce((acc, next) => {
      if (next.access) {
        acc = acc.concat(next.access)
      }

      return acc
    }, []) || ['setup', 'main']

  if (isEditing) {
    if (templateKey) {
      yield call(
        confirmationModal,
        'All changes will be lost. Continue?',
        'Cancel?'
      )

      const templateAction = yield take([CONFIRMED, CANCELED])
      if (templateAction.type === CONFIRMED) {
        yield call(unlockTemplateProcess, form)
        yield take([UNLOCK_TEMPLATE.SUCCESS, UNLOCK_TEMPLATE.FAILURE])
        // debugger
      } else {
        return
      }
    } else {
      yield put(cancelEditAsync.try(form))
      const cancellationAction = yield take([
        CANCEL_EDIT.SUCCESS,
        CANCEL_EDIT.FAILURE
      ])
      // debugger
      if (cancellationAction.type === CANCEL_EDIT.FAILURE) {
        return
      }
    }
  }

  if (!dataId && !autoGenerateId) {
    return
  }

  yield put(changeFormTab(form, 'setup', 'main'))
  yield put(resetMasterFields(form, ['dataId']))
  yield put(actions.createVendor.request(form))

  const apiParams = autoGenerateId
    ? { autoGenerateId, groupNames: tabIds }
    : { dataId, groupNames: tabIds }

  const { response, error } = yield call(api.createVendor, apiParams)

  if (response) {
    const mappedResponse = mapGetRecordResponse({
      response,
      tabIds,
      formState,
      groupNames: tabIds
    })

    yield put(actions.createVendor.success(mappedResponse, form))
    yield put(getEntityAsync.success(mappedResponse, form))
  } else {
    yield put(actions.createVendor.failure(error, form))
  }
}

export function* createVendorListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { tabKeyFired }
    } = yield take(CONSTANTS.CREATE_VENDOR.TRY)

    if (form === formListener) {
      yield delay(1000)
      yield fork(createVendorProcess, form)
    }
  }
}

export function* handlePreNewModeCheckProcess(form) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  if (isEditing) {
    yield put({
      type: CANCEL_EDIT.TRY,
      meta: { form }
    })

    const result = yield take([CANCEL_EDIT.SUCCESS, CANCEL_EDIT.FAILURE])

    if (
      result?.meta?.form &&
      result?.meta?.form === form &&
      result?.type === CANCEL_EDIT.SUCCESS
    ) {
      yield put(changeFormTab(form, 'setup', 'main'))
      yield put(resetMasterFields(form))
      yield put(actions.togglePreNewMode(form))
    }
  } else {
    yield put(changeFormTab(form, 'setup', 'main'))
    yield put(resetMasterFields(form))
    yield put(actions.togglePreNewMode(form))
  }
}

export function* tryTogglePreNewModeListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.TRY_TOGGLE_PRE_NEW_MODE)

    if (form === formListener) {
      yield fork(handlePreNewModeCheckProcess, form)
    }
  }
}

const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}

export function* onOpenVendorMasterInModal(response, meta, form) {
  const { childForm, parentForm } = getChildAndParentForm(form)
  const formState = yield select(getFormSelector(form))
  const tabIds = ['setup', 'main']

  const mappedResponse = mapGetRecordResponse({
    response,
    tabIds,
    formState,
    groupNames: tabIds
  })

  yield put(
    getEntityAsync.success(mappedResponse, {
      form,
      thunk: meta.thunk
    })
  )

  yield spawn(switchToLedgerTabInModal, form, parentForm)
  // if (response?.internalNotes) {
  //   yield fork(showInternalNotesModal, response.internalNotes, form, response)
  // }
}

export function* switchToLedgerTabInModal(form, parentForm) {
  const modalAction = yield take(ADD_MODAL)
  if (
    form &&
    form.match(/vendorMaster/) &&
    modalAction?.meta?.form &&
    modalAction.meta.form === parentForm
  ) {
    yield put(tryChangeFormTab(form, 'ledger'))
  }
}

// export function* exitScreenFromModalProcess(form, modalId) {
//   const { parentForm } = getChildAndParentForm(form)
//   const formState = yield select(getFormSelector(form))
//   const isEditing = getIn(formState, 'isEditing') || false

//   if (isEditing) {
//     yield put({
//       type: CANCEL_EDIT.TRY,
//       meta: { form }
//     })

//     const result = yield take([CANCEL_EDIT.SUCCESS, CANCEL_EDIT.FAILURE])

//     if (
//       result?.meta?.form &&
//       result?.meta?.form === form &&
//       result?.type === CANCEL_EDIT.SUCCESS
//     ) {
//       yield put(removeModal(parentForm, modalId))
//     }
//   } else {
//     yield put(removeModal(parentForm, modalId))
//   }
// }

// export function* exitScreenFromModalListener(formListener) {
//   while (true) {
//     const {
//       meta: { form },
//       payload: { modalId }
//     } = yield take(CONSTANTS.EXIT_SCREEN_FROM_MODAL)

//     if (form === formListener) {
//       yield fork(exitScreenFromModalProcess, form, modalId)
//     }
//   }
// }

export default function* crudSagas(form) {
  yield fork(propertyChangeTriggerListener, form)
  yield fork(deleteVendorListener, form)
  yield fork(createVendorListener, form)
  yield fork(tryTogglePreNewModeListener, form)
  yield fork(templateSagas, form)
  // yield fork(exitScreenFromModalListener, form)
}
