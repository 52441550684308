import React from 'react'
import { DDIButton } from 'ddiForm/wrapped'
import { confirm, canceled, removeModal } from 'modals/actions'

export const ConfirmCancelDuplicateAttachmentsActions = ({
  dispatch,
  form,
  modal
}) => {
  const modalId = modal?.id

  function handleCancel() {
    dispatch(canceled(form, modalId))
  }

  function handleConfirm() {
    dispatch(confirm(form, modalId))
  }

  function handleRemoveModal() {
    dispatch(removeModal(form, modalId))
  }

  return (
    <>
      <DDIButton
        onClick={handleConfirm}
        confirm
        variant="contained"
        label="YES"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleCancel}
        variant="contained"
        label="NO"
        preventAutoDisable
      />
      <DDIButton
        onClick={handleRemoveModal}
        variant="contained"
        label="CANCEL"
        preventAutoDisable
      />
    </>
  )
}

export default ConfirmCancelDuplicateAttachmentsActions
