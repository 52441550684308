/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { noop } from 'utils'
import { Tooltip } from 'react-tippy'
import HoverIntent from 'react-hoverintent'
import { createShipTo, saveTempId } from 'pages/CustomerShipTo/actions'

const mapDispatchToProps = dispatch => ({
  createShipTo: ({ id }) => dispatch(createShipTo({ id })),
  saveTempId: ({ value }) => dispatch(saveTempId({ value }))
})

export class PreNewTextField extends Component {
  static defaultProps = {
    disabled: false,
    onTab: noop
  }

  static propTypes = {
    createShipTo: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onTab: PropTypes.func,
    saveTempId: PropTypes.func.isRequired
  }

  static ddiType = 'PreNewTextField'

  constructor(props) {
    super(props)
    this.state = {
      hoverIntended: true,
      nextIdRetrievedFromDb: false,
      value: props.value || ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value &&
      nextProps.value !== prevState.value &&
      prevState.nextIdRetrievedFromDb === false &&
      (prevState.value === undefined || prevState.value === '')
    ) {
      return {
        nextIdRetrievedFromDb: true,
        value: nextProps.value
      }
    }

    return null
  }

  // componentDidMount() {
  //   this.setState({ hoverIntended: true }) // eslint-disable-line
  // }

  onKeyDown = event => {
    event.persist()
    if (event.key === 'Tab') {
      event.preventDefault()
      if (this.state.value) {
        this.props.createShipTo({ id: this.state.value })
      }
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  hoverIntended = () =>
    this.setState({ hoverIntended: !this.state.hoverIntended })

  hoverNotIntended = () =>
    this.setState({ hoverIntended: !this.state.hoverIntended })

  onBlur = () => {
    if (this.state.value) {
      this.props.saveTempId({ value: this.state.value })
    }
  }

  render() {
    const { value } = this.state
    const { disabled } = this.props
    return (
      <Tooltip
        open={this.state.hoverIntended}
        ref={el => (this.tooltip = el)}
        html={
          <div
            className="inform-tooltip"
            style={{ padding: 10, borderRadius: 4 }}
          >
            <p style={{ color: '#fff', fontSize: 12 }}>
              Enter Id to create new record<br />Tab to accept
            </p>
          </div>
        }
        unmountHTMLWhenHide
        position="top"
        popperOptions={{
          modifiers: { preventOverflow: { boundariesElement: 'window' } }
        }}
      >
        <HoverIntent
          onMouseOver={this.hoverIntended}
          onMouseOut={this.hoverNotIntended}
          sensitivity={20}
          interval={0}
        >
          <div style={{ height: 26, margin: 0 }}>
            <TextField
              preventAutoDisable
              disabled={disabled}
              fullWidth
              onKeyDown={this.onKeyDown}
              value={value}
              onChange={this.handleChange}
              onBlur={this.onBlur}
              placeholder="Enter ID and hit the tab key"
            />
          </div>
        </HoverIntent>
      </Tooltip>
    )
  }
}

export default connect(
  null, // mapStateToProps,
  mapDispatchToProps
)(PreNewTextField)
