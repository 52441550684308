/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const createRecord = createAsyncFormAction(CONSTANTS.CREATE_RECORD)

export const deleteRecord = createAsyncFormAction(CONSTANTS.DELETE_RECORD)

export const tryTogglePreNewMode = form =>
  createAction(CONSTANTS.TRY_TOGGLE_PRE_NEW_MODE, {}, form)

export const togglePreNewMode = form =>
  createAction(CONSTANTS.TOGGLE_PRE_NEW_MODE, {}, form)

export const savePreNewModeValue = (form, { value }) =>
  createAction(CONSTANTS.SAVE_PRE_NEW_MODE_VALUE, { value }, form)

export const getVendorInvoiceReport = createAsyncFormAction(
  CONSTANTS.GET_VENDOR_INVOICE_REPORT
)

export const lockVendorNotes = createAsyncFormAction(
  CONSTANTS.LOCK_VENDOR_NOTES
)

export const unlockVendorNotes = createAsyncFormAction(
  CONSTANTS.UNLOCK_VENDOR_NOTES
)

export const saveVendorNotes = createAsyncFormAction(
  CONSTANTS.SAVE_VENDOR_NOTES
)

export const getPaymentHistoryReport = createAsyncFormAction(
  CONSTANTS.GET_PAYMENT_HISTORY_REPORT
)

export const saveAgingView = createAsyncFormAction(CONSTANTS.SAVE_AGING_VIEW)

export const setSummaryExpansionPanelState = (form, { expanded }) =>
  createAction(CONSTANTS.SET_SUMMARY_EXPANSION_PANEL_STATE, { expanded }, form)

export const removeEmptyGridRow = (form, { propertyName, requiredCols = [] }) =>
  createAction(
    CONSTANTS.REMOVE_EMPTY_GRID_ROW,
    { propertyName, requiredCols },
    form
  )

export const handleRowDataUpdate = (
  form,
  {
    addNewRowOnChange = false,
    propertyName,
    emptyRow,
    field,
    value,
    rowId,
    rowIndex,
    requiredCols = []
  }
) =>
  createAction(
    CONSTANTS.HANDLE_ROW_DATA_UPDATE,
    {
      addNewRowOnChange,
      propertyName,
      emptyRow,
      field,
      value,
      rowId,
      rowIndex,
      requiredCols
    },
    form
  )

export const toggleCheckboxCell = (
  form,
  {
    propertyName,
    rowIndex,
    field,
    value,
    values = [],
    isHeaderToggle = false,
    selectAll = false
  }
) =>
  createAction(
    CONSTANTS.TOGGLE_CHECKBOX_CELL,
    {
      propertyName,
      rowIndex,
      field,
      value,
      values,
      isHeaderToggle,
      selectAll
    },
    form
  )

export const vendorMasterPropertyChange = createAsyncFormAction(
  CONSTANTS.PROPERTY_CHANGE
)

export const deleteVendor = createAsyncFormAction(CONSTANTS.DELETE_VENDOR)

export const createVendor = createAsyncFormAction(CONSTANTS.CREATE_VENDOR)

export const exitScreenFromModal = (form, { modalId }) =>
  createAction(CONSTANTS.EXIT_SCREEN_FROM_MODAL, { modalId }, form)
