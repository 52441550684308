/* eslint react/sort-comp: 0, no-lonely-if: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import Grid from 'grid'
import { withContext } from 'ddiForm'
import { getIn, is, formatDollarFields, plainDeepEqual, noop } from 'utils'
import { debounce } from 'lodash'
import { addOptions } from 'pages/SalesOrder/actions'
import { destroyProductDetailFields } from 'mobile/pages/SalesOrder/actions'
import { shouldUpdate } from 'hoc'
import OptionsDataIdCellRenderer from 'pages/SalesOrder/tabs/Detail/components/OptionsDataIdCellRenderer'
import ErrorBoundary from 'components/ErrorBoundary'

export const setGridHeight = memoize(
  (array = [], additionalHeight = 0, headerHeight = 32) =>
    array.length * 30 + (headerHeight + array.length) + additionalHeight
)

export const setExpandableGridRowHeight = memoize(
  (params, key = 'notes', headerHeight = 0) => {
    if (
      params.node &&
      params.node.detail &&
      params.data[key] &&
      params.data[key].length
    ) {
      return setGridHeight(params.data[key], 2, headerHeight)
    }

    return 30
  }
)

const columnDefs = [
  {
    field: 'dataId',
    headerName: '',
    cellRenderer: 'agGroupCellRenderer',
    cellClass: 'cell-value-hidden expander-cell',
    filter: false,
    maxWidth: 35,
    minWidth: 35,
    width: 35,
    suppressMovable: true,
    suppressMenu: true,
    suppressToolPanel: true
  },
  {
    field: 'isSelected',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    minWidth: 50,
    maxWidth: 50,
    width: 50,
    suppressMenu: true
  },
  {
    field: 'dataId',
    headerName: 'Option',
    minWidth: 100,
    maxWidth: 100,
    width: 100
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 150
  }
]

const getRowNodeId = data => `${data.dataId}-${data.sortIndex}`

const getRowHeight = params => setExpandableGridRowHeight(params, 'accessories')

const getDataIds = rowData =>
  rowData.reduce((acc, next) => {
    acc = acc.concat(next.dataId)
    return acc
  }, [])

class OptionsAndAccessoriesGrid extends Component {
  static propTypes = {
    form: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
    isEditing: PropTypes.bool.isRequired,
    expandLineItemsInterfaceCb: PropTypes.func,
    gridApi: PropTypes.func,
    rowData: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired
  }

  static defaultProps = {
    isMobile: false,
    expandLineItemsInterfaceCb: noop,
    gridApi: noop
  }

  componentDidMount() {
    this._isMounted = true
    this.initializeGrid()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  /* before withContext update 9/24/20 */
  // componentDidUpdate(prevProps, prevState) {
  //   if (
  //     (prevProps.lineNumber !== this.props.lineNumber && this.gridApi) ||
  //     (!plainDeepEqual(prevProps.rowData, this.props.rowData) && this.gridApi)
  //   ) {
  //     this.initializeGrid()
  //   }
  // }
  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.selectedRowIndex !== this.props.selectedRowIndex &&
        this.gridApi) ||
      (!plainDeepEqual(prevProps.rowData, this.props.rowData) && this.gridApi)
    ) {
      this.initializeGrid()
    }
  }

  initializeGrid = () => {
    setTimeout(() => {
      if (this.gridApi && this._isMounted) {
        this.gridApi.forEachNode(node => {
          node.setExpanded(true)
        })
      }
    }, 0)
  }

  onGridReady = params => {
    if (params && params.api) {
      this.gridApi = params.api
    }
  }

  onRowSelected = params => {
    if (params.node.selected) {
      if (params.node.detailNode && params.node.detailNode.detailGridInfo) {
        params.node.detailNode.detailGridInfo.api.forEachNode(node => {
          if (!node.isSelected()) {
            node.setSelected(true)
          }
        })
      }
    } else {
      if (params.node.detailNode && params.node.detailNode.detailGridInfo) {
        params.node.detailNode.detailGridInfo.api.forEachNode(node =>
          node.setSelected(false)
        )
      }
    }
  }

  onChildRowSelected = params => {
    const {
      data: { dataId, parentId }
    } = params
    const isSelected = params.node.selected

    try {
      const selectedChildNodes = params.node.parent.gridApi.getSelectedNodes()
      const allChildren = params.node.parent.allLeafChildren

      const parentIsSelected =
        selectedChildNodes.length === allChildren.length
          ? true
          : selectedChildNodes.length
          ? 'indeterminate'
          : false

      if (parentIsSelected !== 'indeterminate') {
        this.gridApi.forEachNode(node => {
          if (node.data.dataId === parentId) {
            node.setSelected(parentIsSelected)
          }
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  addOptions = debounce(() => {
    const {
      dispatch,
      form,
      expandLineItemsInterfaceCb,
      isEditing,
      gridApi,
      modalId,
      isMobile
    } = this.props

    if (!this._isMounted) {
      return
    }
    // const selectedRows = getDataIds(this.gridApi.getSelectedRows())

    let selectedOptions = []

    /* the options have to be open in order for this routine to work */
    this.gridApi.forEachNode(node => {
      node.setExpanded(true)
      node.detailNode.detailGridInfo.api.forEachNode(item => {
        if (item.isSelected()) {
          selectedOptions.push(item.data)
        }
      })
    })

    selectedOptions = selectedOptions.reduce((acc, next) => {
      acc = acc.concat({
        dataId: next.dataId,
        quantityOrdered: next.quantity,
        uomId: next.uomId
      })
      return acc
    }, [])

    if (isEditing && selectedOptions.length) {
      if (isMobile) {
        // dispatch(removeModal(form, modalId))
        dispatch(destroyProductDetailFields(form))
      }

      dispatch(
        addOptions.try(form, {
          // dataIds: selectedRows,
          selectedOptions,
          expandLineItemsInterfaceCb,
          gridApi
        })
      )
    }
  }, 300)

  render() {
    const { form, dispatch, isMobile, height, rowData } = this.props

    const childDataIdColumnDef = isMobile
      ? {
          field: 'dataId',
          minWidth: 100,
          maxWidth: 100,
          width: 100
        }
      : {
          cellRenderer: OptionsDataIdCellRenderer,
          cellRendererParams: {
            dispatch,
            form
          },
          field: 'dataId',
          minWidth: 100,
          maxWidth: 100,
          width: 100
        }

    return (
      <ErrorBoundary>
        <div
          className="opts-accessories-grid-wrapper"
          style={{ width: '100%' }}
        >
          <Grid
            form={form}
            addButtonText={isMobile ? 'Add Options' : 'Add'}
            addBlankRow={this.addOptions}
            showAddButtonAlways={form !== 'invoiceInquiry'}
            id="optionsAndAccessories"
            onGridReady={this.onGridReady}
            rowData={rowData}
            columnDefs={columnDefs}
            height={height}
            getRowHeight={getRowHeight}
            masterDetail
            detailCellRendererParams={{
              detailGridOptions: {
                columnDefs: [
                  {
                    field: 'emptyPlaceholder',
                    headerName: '',
                    cellClass: 'cell-value-hidden',
                    filter: false,
                    maxWidth: 35,
                    minWidth: 35,
                    width: 35,
                    suppressMovable: true,
                    suppressMenu: true,
                    suppressToolPanel: true
                  },
                  {
                    field: 'isSelected',
                    headerName: '',
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    minWidth: 50,
                    maxWidth: 50,
                    width: 50,
                    suppressMenu: true
                  },
                  childDataIdColumnDef,
                  {
                    field: 'description',
                    minWidth: 150,
                    maxWidth: 150,
                    width: 150
                  },
                  {
                    field: 'quantityWithUOM',
                    minWidth: 75,
                    maxWidth: 75,
                    width: 75
                  },
                  {
                    cellClass: 'align-right right-align',
                    field: 'price',
                    minWidth: 100,
                    maxWidth: 100,
                    width: 100,
                    valueFormatter: formatDollarFields
                  }
                ],
                rowSelection: 'multiple',
                suppressHorizontalScroll: true,
                suppressRowClickSelection: true,
                width: '100%',
                onRowSelected: this.onChildRowSelected
              },
              getDetailRowData: params => {
                const parentId = params.data.dataId
                const accessories = params?.data?.accessories?.length
                  ? params.data.accessories.reduce((acc, next) => {
                      acc = acc.concat({
                        ...next,
                        parentId
                      })
                      return acc
                    }, [])
                  : []
                params.successCallback(accessories)

                const detailColumnState = params.node.detailGridInfo.columnApi.getColumnState()
                if (detailColumnState.length) {
                  params.node.detailGridInfo.api.setHeaderHeight(0)
                }

                if (params.node.parent.selected) {
                  params.node.detailGridInfo.api.forEachNode(node => {
                    if (!node.isSelected()) node.setSelected(true)
                  })
                } else {
                  params.node.detailGridInfo.api.forEachNode(node => {
                    if (!node.isSelected()) node.setSelected(false)
                  })
                }
              }
            }}
            getRowNodeId={getRowNodeId}
            style={{ width: '100%' }}
            onRowSelected={this.onRowSelected}
            rowSelection="multiple"
            width="100%"
            suppressRowClickSelection
            reactUi={false}
          />
        </div>
      </ErrorBoundary>
    )
  }
}

const wrappedUpdate = shouldUpdate({
  whitelist: ['height', 'lineNumber', 'rowData']
})(OptionsAndAccessoriesGrid)

export const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  selectedRowIndex: form => {
    const selectedRowIndex = getIn(form, 'fields.lineItems.selectedRowIndex')

    if (is.number(selectedRowIndex)) {
      return selectedRowIndex
    }

    return null
  }
}

/* fixed 9/24/2020 SVE */
export default withContext(contextObj)(wrappedUpdate)
