/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useRef, useState } from 'react'
import { withContext } from 'ddiForm'
import { DDITextField, DDISelectField, DDIToggle } from 'ddiForm/wrapped'
import { Button, Icon } from '@material-ui/core'
import DDIIndexSearch from 'mobile/ddiForm/wrapped/ddiIndexSearchMobile'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import {
  getField,
  getIn,
  getValue,
  isForeignCountry,
  isUsaCanadaOrBlank
} from 'utils'
import usePrevious from 'hooks/usePrevious'

import { indexSearchFields, validateFastCustomerField } from '../utils'
import { states } from '../../Contacts/utils'
import '../../FastProduct/styles/css-mod-ignore.scss'

export const Form = ({
  autoGenerateId = false,
  dataId,
  isEditing,
  categoryDescription,
  salesmanDescription,
  arTermsDescription,
  branchDescription,
  taxDescription,
  idMaxLength,
  isSalesOrderModule = false,
  isLocked = true,
  country
}) => {
  const dataIdInput = React.createRef()
  const prevDataId = usePrevious(dataId)
  const prevCountry = usePrevious(country)
  const [hasDataId, flagDataIdInput] = useState(Boolean(dataId))
  const [disableInputMask, setMaskDisabledState] = useState(
    isForeignCountry(country)
  )

  useEffect(() => {
    if (dataId !== prevDataId && hasDataId !== Boolean(dataId)) {
      flagDataIdInput(Boolean(dataId))
    }
  }, [dataId, prevDataId])

  useEffect(() => {
    if (
      typeof country === 'string' &&
      typeof prevCountry === 'string' &&
      country !== prevCountry
    ) {
      setMaskDisabledState(isForeignCountry(country))
    }
  }, [country, prevCountry])

  const onDataIdEdit = e => {
    if (e.target.value) {
      flagDataIdInput(true)
    } else {
      flagDataIdInput(false)
    }
  }

  return (
    <>
      <div style={{ margin: 10 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            padding: '10px 20px 20px 20px'
          }}
        >
          <DDITextField
            propertyName={isSalesOrderModule ? 'fastCustomer.dataId' : 'dataId'}
            label="Customer ID"
            placeholder="Enter desired Customer ID"
            preventAutoDisable
            disabled={!!autoGenerateId || isLocked}
            wrapperStyle={{ width: 200, marginRight: 25 }}
            fullWidth
            inputRef={dataIdInput}
            onChangeCb={onDataIdEdit}
            inputProps={{
              maxLength: idMaxLength
            }}
          />
          <Button
            variant="contained"
            startIcon={<Icon fontSize="small">add_circle</Icon>}
            size="small"
            disabled={!hasDataId || (isLocked && hasDataId)}
          >
            Create Customer
          </Button>
        </div>
        <DDIExpansionPanel
          title="Name &amp; Address"
          titleIcon="business"
          // style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
          expansionPanelContentStyle={{ padding: '10px 0', width: '100%' }}
          expansionPanelDetailsStyle={{ padding: 0, width: '100%' }}
        >
          <div className="mobile-form-wrapper">
            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule
                      ? 'fastCustomer.description'
                      : 'description'
                  }
                  label="Name"
                  disabled={!isLocked}
                  validate={[validateFastCustomerField]}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <AssociatedFieldsWrapper>
                  <DDIToggle
                    propertyName={
                      isSalesOrderModule
                        ? 'fastCustomer.isProspect'
                        : 'isProspect'
                    }
                    label="Prospect"
                  />
                </AssociatedFieldsWrapper>
              </div>
            </div>
            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule
                      ? 'fastCustomer.addressLine1'
                      : 'addressLine1'
                  }
                  label="Address"
                  disabled={!isLocked}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule
                      ? 'fastCustomer.addressLine2'
                      : 'addressLine2'
                  }
                  label="Address Line 2"
                  disabled={!isLocked}
                />
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.city' : 'city'
                  }
                  label="City"
                  inputProps={{
                    maxLength: isUsaCanadaOrBlank(country) ? 20 : 30
                  }}
                  disabled={!isLocked}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <div style={{ display: 'flex' }}>
                  <DDISelectField
                    propertyName={
                      isSalesOrderModule ? 'fastCustomer.state' : 'state'
                    }
                    label="State"
                    disabled={!isLocked}
                    values={states}
                    style={{ width: 150, marginRight: 15 }}
                  />

                  <DDITextField
                    propertyName={
                      isSalesOrderModule ? 'fastCustomer.zipcode' : 'zipcode'
                    }
                    mask="zipCode"
                    label="Zip Code"
                    disableMask={disableInputMask}
                    disabled={!isLocked}
                  />
                </div>
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.country' : 'country'
                  }
                  label="Country"
                  disabled={!isLocked}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.arContact' : 'arContact'
                  }
                  label="Contact"
                  disabled={!isLocked}
                />
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.arPhone' : 'arPhone'
                  }
                  label="Phone Number"
                  mask="phoneNumber"
                  disableMask={disableInputMask}
                  disabled={!isLocked}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={isSalesOrderModule ? 'fastCustomer.fax' : 'fax'}
                  label="Fax Number"
                  mask="phoneNumber"
                  disableMask={disableInputMask}
                  disabled={!isLocked}
                />
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.email' : 'email'
                  }
                  label="Email"
                  mask="email"
                  disabled={!isLocked}
                />
              </div>
              <div className="mobile-form-input-wrapper" />
            </div>
          </div>
        </DDIExpansionPanel>
        <DDIExpansionPanel
          title="General Configuration"
          titleIcon="settings_applications"
          // style={{ borderBottom: '1px solid rgba(0, 0, 0, .125)' }}
          expansionPanelContentStyle={{ padding: '10px 0', width: '100%' }}
          expansionPanelDetailsStyle={{ padding: 0, width: '100%' }}
        >
          <div className="mobile-form-wrapper">
            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDIIndexSearch
                  propertyName={
                    isSalesOrderModule
                      ? 'fastCustomer.salesmanId'
                      : 'salesmanId'
                  }
                  meta={indexSearchFields.salesman}
                  disabled={!isLocked}
                  label="Salesperson"
                  displayDescription
                  descriptionKey="name"
                  description={salesmanDescription}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDIIndexSearch
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.arTermsId' : 'arTermsId'
                  }
                  meta={indexSearchFields.terms}
                  disabled={!isLocked}
                  label="Terms"
                  displayDescription
                  descriptionKey="a/R Terms Description"
                  description={arTermsDescription}
                />
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDIIndexSearch
                  propertyName={
                    isSalesOrderModule
                      ? 'fastCustomer.categoryId'
                      : 'categoryId'
                  }
                  meta={indexSearchFields.custCategory}
                  disabled={!isLocked}
                  label="Category"
                  displayDescription
                  description={categoryDescription}
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDIIndexSearch
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.taxId' : 'taxId'
                  }
                  meta={indexSearchFields.tax}
                  disabled={!isLocked}
                  label="Tax"
                  displayDescription
                  descriptionKey="jurisdiction"
                  description={taxDescription}
                />
              </div>
            </div>

            <div className="mobile-form-row-wrapper">
              <div className="mobile-form-input-wrapper">
                <DDITextField
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.sortCode' : 'sortCode'
                  }
                  disabled={!isLocked}
                  label="Sort Code"
                />
              </div>
              <div className="mobile-form-input-wrapper">
                <DDIIndexSearch
                  propertyName={
                    isSalesOrderModule ? 'fastCustomer.branchId' : 'branchId'
                  }
                  meta={indexSearchFields.branch}
                  disabled={!isLocked}
                  label="Branch"
                  displayDescription
                  descriptionKey="name"
                  description={branchDescription}
                />
              </div>
            </div>
          </div>
        </DDIExpansionPanel>
      </div>
    </>
  )
}

/* fixed 9/28/2020 SVE */
export default withContext({
  autoGenerateId: form => getIn(form, 'values.autoGenerateId') || false,
  dataId: form => getField(form, 'dataId', ''),
  isEditing: form => form.get('isEditing') || false,
  categoryDescription: form => getValue(form, 'categoryDescription', ''),
  salesmanDescription: form => getValue(form, 'salesmanDescription', ''),
  arTermsDescription: form => getValue(form, 'arTermsDescription', ''),
  branchDescription: form => getValue(form, 'branchDescription', ''),
  taxDescription: form => getValue(form, 'taxDescription', ''),
  idMaxLength: form => getValue(form, 'idMaxLength', 7),
  isLocked: form => getIn(form, 'values.isLocked') || false,
  country: form => getValue(form, 'country', '')
})(Form)
