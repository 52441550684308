import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import {
  Select,
  MenuItem
} from '@material-ui/core'


export class SelectFieldEditorCell extends Component {

  constructor(props) {
    super(props)

    const state = {
      isEditing: false,
      value: props.value || ''
    }

    this.state = state
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentDidUpdate() {
    if (this.selectField) {
      const { api, column, rowIndex } = this.props
      setTimeout(() => {
        api.setFocusedCell(rowIndex, column.colId)
        api.startEditingCell({
          rowIndex,
          colKey: column.colId
        })
      }, 0)
    }
  }

  onChange = event => {
    const { dispatch, form } = this.props

    if (this._isMounted) {
      this.setState({
        value: event.target.value || ''
      }, () => {
        dispatch({
          meta: { form },
          type: 'ITEM_GROUPS_CELL_CHANGED',
          payload: {
            propertyName: 'groups.itemGroups',
            field: 'dataId',
            gridApi: this.props.api,
            data: this.props.data,
            value: this.state.value
          }
        })
      })
    }
  }

  isCancelAfterEnd = () => {
    return true
  }

  render() {
    return (
      <>
        <Select
          onChange={this.onChange}
          value={this.state.value}
          autoWidth
          forwardRef
          inputRef={el => this.selectField = el}
          style={{ width: '100%' }}
        >
          {this.props.values.map((x, i) => (
            <MenuItem value={x} key={`${x} - ${i}`}>{x}</MenuItem>
          ))}
        </Select>
      </>
    )
  }

}

export default SelectFieldEditorCell