/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, react/no-find-dom-node: 0, react/no-did-mount-set-state: 0, consistent-return: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { is, getIn, plainDeepEqual, toNumeral } from 'utils'
// import { delay, throttle, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import { Input, TextField } from '@material-ui/core'
import { getErrorIcon, isDisabled } from 'components/EditableGrid/utils'
import { debounce } from 'lodash'
import { handleProcurementGridUpdate } from 'pages/SalesOrder/actions'

const nums = [37, 39]

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    range,
    ...other
  } = props
  /*
    had to change this component to receive
    decimal props like this
  */
  const decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  let isAllowed = {}

  if (range && Array.isArray(range)) {
    isAllowed = {
      isAllowed: values => {
        let { floatValue } = values
        floatValue = floatValue ?? 0

        return range[2]
          ? floatValue > range[0] && floatValue < range[1]
          : floatValue >= range[0] && floatValue <= range[1]
      }
    }
  }

  return (
    <NumberFormat
      {...other}
      {...decimalFormatting}
      {...isAllowed}
      disabled={disabled}
      id={id}
      getInputRef={inputRef}
      forwardRef
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      prefix={prefix}
      suffix={suffix}
      style={{
        color: value < 0 ? '#d9534f' : disabled ? '#666' : '#000',
        fontSize: fontSize || '1em',
        height: '100%',
        paddingLeft: 0,
        paddingRight,
        // paddingLeft: textAlign === 'left' ? 10 : 0,
        // paddingRight: textAlign === 'right' ? 0 : 0,
        textAlign,
        width: '100%'
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      maxLength={maxLength}
    />
  )
}

class TextFieldGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired,
    propertyName: PropTypes.string.isRequired
  }

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      focusSet: false,
      lastCellTabbed: false,
      rowIndex: props.rowIndex,
      textFieldVal: props.value || props.value === 0 ? props.value : ''
    }

    this.isDisabled = isDisabled.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef.cellRendererParams.requiredFn = this.props.colDef.cellRendererParams.requiredFn.bind(
        this
      )
    }

    this.checkFocusOnInput = debounce(this.checkFocusOnInput, 500)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    /* some tricky logic in here */

    /*
      this means that the user has entered invalid data (for whatever reason)
      (see POPatterns or Credit References for example) and a saga OR a
      reducer method has stripped the value onBlur
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      (prevState.isEditing === true || prevState.lastCellTabbed === true)
    ) {
      return { isEditing: false, lastCellTabbed: false }
    }

    /* ^^related to the above, now that we've received a new value,
         we can strip it out because we've previously marked isEditing === false
         AFTER getting the new value prop back after being handled by the saga
         or reducer (as described above)
    */
    if (
      prevState.textFieldVal !== '' &&
      nextProps.value === '' &&
      nextProps.value !== prevState.textFieldVal &&
      (prevState.isEditing === false || prevState.lastCellTabbed === false)
    ) {
      return { textFieldVal: nextProps.value }
    }

    return null
  }

  componentDidMount() {
    const {
      value,
      colDef: {
        cellRendererParams: { formatter }
      }
    } = this.props

    if (formatter === 'number' && value === 0) {
      this.setState({
        textFieldVal: 0
      })
    }

    findDOMNode(this.textField).addEventListener('keydown', this.onKeyDown)

    setTimeout(() => {
      this.checkFocusOnInput()
    }, 0)
  }

  componentWillUnmount() {
    findDOMNode(this.textField).removeEventListener('keydown', this.onKeyDown)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data &&
      prevProps.data &&
      !plainDeepEqual(prevProps.data, this.props.data)
    ) {
      setTimeout(() => {
        this.checkFocusOnInput()
      }, 0)
    }
  }

  onChange = event => {
    this.setState({
      focusSet: false,
      isEditing: true,
      lastCellTabbed: false,
      textFieldVal: event.target.value
    })
  }

  onBlur = event => {
    event.persist()
    const {
      colDef: {
        cellRendererParams: { form, gridName, formatter = '' }
      }
    } = this.props

    if (this.state.focusSet === false && this.state.lastCellTabbed === false) {
      const {
        colDef: { field },
        value
      } = this.props

      if (
        (this.state.textFieldVal !== value &&
          !(!this.state.textFieldVal && !value)) ||
        (formatter && is.number(this.state.textFieldVal) && value === null)
      ) {
        this.props.dispatch(
          handleProcurementGridUpdate.try(form, {
            field,
            value: this.state.textFieldVal,
            rowIndex: this?.props?.rowIndex,
            gridName
          })
        )
      }
    }
  }

  onValueChange = values => {
    this.setState({
      focusSet: false,
      lastCellTabbed: false,
      isEditing: true,
      textFieldVal: values.floatValue
    })
  }

  showRequiredIcon = () => {
    const {
      data,
      value,
      valueFormatted,
      colDef: {
        cellRendererParams: { errorMessage, isRequired, requiredFn },
        field
      },
      rowIndex
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)
    // debugger
    // prob makes sense to send all the props into the fn...
    // also prob makes BETTER sense to not have 3 named ways of required
    // you could easily find the type and use it.. this way you can have
    // multiple avenues to pass in and all can be evaluated..
    // this is called polymorphism

    if (requiredFn && typeof requiredFn === 'function') {
      const error = requiredFn(data, this.props)
      return error
        ? typeof error === 'string'
          ? getErrorIcon(error)
          : errorIcon
        : null
    }

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }

    return null
  }

  onKeyDown = event => {
    if (this.isLeftOrRight(event)) {
      event.stopPropagation()
      return false
    }
  }

  isLeftOrRight = event => nums.includes(event.keyCode)

  isFieldDisabled = () => {
    const {
      // value,
      colDef: {
        cellRendererParams: { disabledFn }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return this.isDisabled()
  }

  checkFocusOnInput = () => {
    if (!this.props.api) return
    const focusedCell = this.props.api.getFocusedCell()
    const {
      colDef: { field },
      rowIndex
    } = this.props

    if (!focusedCell || rowIndex < 0) {
      return
    }

    if (
      focusedCell?.column?.colDef?.field &&
      focusedCell.column.colDef.field === field &&
      focusedCell.rowIndex === rowIndex
    ) {
      // console.log(focusedCell)
      if (findDOMNode(this.textField) && findDOMNode(this.textField) !== null) {
        this.setState({ focusSet: true }, () => {
          findDOMNode(this.textField).focus()
        })
      }
    }
  }

  render() {
    const {
      colDef: {
        field,
        cellRendererParams: {
          allowNegative,
          formatter,
          decimalScale,
          decimalSeperator,
          dollarField,
          fixedDecimalScale,
          thousandSeparator,
          textAlign,
          noDecimalLimit,
          maxLength,
          fontSize,
          inputProps = {},
          range
        }
      },
      form,
      rowIndex
    } = this.props

    if (formatter) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            width: '100%'
          }}
          title={this.state.textFieldVal}
        >
          {this.showRequiredIcon()}
          <Input
            disableUnderline
            fullWidth
            id={`text-field-${form}-${field}-${rowIndex}`}
            value={this.state.textFieldVal}
            disabled={this.isFieldDisabled()}
            inputComponent={NumberFormatter}
            onChange={this.onValueChange}
            onBlur={this.onBlur}
            style={{
              height: '100%',
              width: '100%'
            }}
            inputRef={el => (this.textField = el)}
            inputProps={{
              allowNegative,
              decimalScale: decimalScale || 0,
              noDecimalLimit: noDecimalLimit || false,
              decimalSeparator: decimalSeperator || '.',
              fontSize: fontSize || '1em',
              fixedDecimalScale: fixedDecimalScale || false,
              inputRef: el => (this.textField = el),
              maxLength,
              textAlign: textAlign || dollarField ? 'right' : 'left',
              thousandSeparator,
              prefix: dollarField ? '$' : '',
              range
            }}
          />
        </div>
      )
    }

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
        title={this.state.textFieldVal}
      >
        {this.showRequiredIcon()}
        <TextField
          InputProps={{
            disableUnderline: true,
            style: { height: '100%', padding: 0, width: '100%' }
          }}
          inputProps={{
            style: {
              height: '100%',
              padding: 0,
              width: '100%',
              textAlign: textAlign || 'left'
            },
            ...inputProps
          }}
          onChange={this.onChange}
          disabled={this.isFieldDisabled()}
          onBlur={this.onBlur}
          value={this.state.textFieldVal}
          id={`text-field-${form}-${field}-${rowIndex}`}
          fullWidth
          style={{ height: '100%', width: '100%' }}
          inputRef={el => (this.textField = el)}
          forwardRef
        />
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(TextFieldGridCell)
