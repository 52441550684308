import React from 'react'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  // Paper,
  Table,
  TableCell,
  // TableHead,
  TableRow,
  TableBody
} from '@material-ui/core'
import { numToDollar } from 'utils'

import '../styles/void-cayan-modal.scss'

export const VoidCayanPaymentModal = ({
  data: {
    amountDisplay,
    invoiceIds,
    transactionId,
    voidCardNumber,
    voidCardHolder,
    voidCardType,
    voidExpDate,
    voidTotalAmount
  }
}) => {
  return (
    <div className="void-cayan-modal">
      <div style={{ maxWidth: 250 }}>
        <p className="amount">Amount: {numToDollar(amountDisplay)}</p>
      </div>
      <div style={{ maxWidth: 250 }}>
        <p className="invoice">Invoice: {invoiceIds}</p>
      </div>

      <DDICardWrapper title="Original Payment">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Transaction #:</TableCell>
              <TableCell>{transactionId}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Card Number:</TableCell>
              <TableCell>{voidCardNumber}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Card Holder:</TableCell>
              <TableCell>{voidCardHolder}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Card Type:</TableCell>
              <TableCell>{voidCardType}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Exp. Date:</TableCell>
              <TableCell>{voidExpDate}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Total Amount:</TableCell>
              <TableCell>{numToDollar(voidTotalAmount)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DDICardWrapper>
    </div>
  )
}

export default VoidCayanPaymentModal
