import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'

class CommentsEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.data && props.data.comments ? props.data.comments : ''
    }
  }

  onChange = e => this.setState({ value: e.target.value, isDirty: true })

  onBlur = e => {
    if (this.state.isDirty) {
      this.setState({ isDirty: false, value: e.target.value })
    }
  }

  render() {
    const {
      data: { isEditing }
    } = this.props
    const { value } = this.state

    return (
      <div className="transfers-grid-editor-wrapper" style={{ width: '100%' }}>
        <TextField
          preventAutoDisable
          value={value}
          onChange={this.onChange}
          fullWidth
          multiline
          minWidth="100%"
          variant="outlined"
          style={{ width: '100%' }}
          placeholder="Enter a note"
          label="Comment"
        />
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(CommentsEditor)
