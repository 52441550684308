import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  empty,
  emptyList,
  plainDeepEqual,
  getIn,
  getValue,
  formatNumber
} from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import Grid from 'grid'
import { withContext } from 'ddiForm'
import { DDITextField } from 'ddiForm/wrapped'
import columnDefs from 'pages/ContactMaster/components/columnDefs'
import Image from 'pages/ContactMaster/components/Image'
import ParentInfo from 'pages/ContactMaster/components/ParentInfo'
import {
  getAddressLink,
  getImageSource,
  headerStyle,
  setGridHeight,
  Link
} from 'pages/ContactMaster/utils'
import Measure from 'react-measure'
import { BASE_INFORM_URL } from 'services/constants'

import { LayoutFlex, ListInfo } from 'pages/ContactMaster/styles/masterStyle'

import './styles/gridStyle.css'

import DescriptionTooltipCell from './components/DescriptionTooltipCell'
import SummaryGrid from './components/SummaryGrid'

export const getRowNodeId = data => data.dataId

export class Analysis extends Component {
  static propTypes = {
    activities: PropTypes.array.isRequired,
    aging: PropTypes.array.isRequired,
    agingView: PropTypes.string,
    contactInfo: PropTypes.object,
    customerRankings: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired,
    imageBase64String: PropTypes.string.isRequired,
    parentInfo: PropTypes.object.isRequired,
    parentTitle: PropTypes.string.isRequired
  }

  static defaultProps = {
    agingView: 'Chart',
    contactInfo: {
      address1: '',
      address2: '',
      address3: '',
      cityStateZip: '',
      email: '',
      fax: '',
      phone1: '',
      phone2: ''
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (plainDeepEqual(nextProps, this.props)) {
      return false
    }
    return true
  }

  getAddress = () => {
    let { contactInfo } = this.props
    contactInfo = contactInfo.toJS ? contactInfo.toJS() : contactInfo

    if (contactInfo) {
      const { address1, address2, cityStateZip } = contactInfo
      return getAddressLink(address1, address2, cityStateZip)
    }
    return ''
  }

  getGridMaxHeight = (dimensions, height) => {
    console.log('dimensions', dimensions, height)
    if (dimensions && dimensions.height) {
      const calculatedHeight = height - dimensions.height

      if (dimensions && dimensions.width && dimensions.width <= 700) {
        return 400
      }

      if (calculatedHeight <= 0) {
        return 100
      }

      return calculatedHeight
    }

    return 100
  }

  render() {
    let {
      activities,
      aging,
      contactInfo,
      customerRankings,
      parent,
      parentInfo
    } = this.props
    const {
      agingView,
      // contactInfo: {
      //   address2,
      //   cityStateZip,
      //   email,
      //   fax,
      //   phone1,
      //   phone2,
      //   phoneExtension
      // },
      dataId,
      dispatch,
      form,
      // height,
      imageBase64String,
      parentTitle
    } = this.props

    activities = activities && activities.toJS ? activities.toJS() : []
    aging = aging.toJS()
    contactInfo = contactInfo.toJS()
    customerRankings = customerRankings.toJS()
    parent = parent.toJS()
    parentInfo = parentInfo.toJS()

    const flexWrapStretchStyle = {
      display: 'flex',
      justifyContent: 'stretch',
      maxWidth: '100%',
      width: '100%'
    }

    return (
      <LayoutFlex>
        <Measure
          bounds
          onResize={contentRect =>
            this.setState({ dimensions: contentRect.bounds }, () =>
              console.log('render', this.state.dimensions)
            )
          }
        >
          {({ measureRef }) => (
            <div ref={measureRef} style={{ maxWidth: '100%', width: '100%' }}>
              <div
                className="contact-flex-wrapper"
                style={{ display: 'flex', width: '100%', maxWidth: '100%' }}
              >
                <div
                  className="contact-left-col"
                  style={{ flex: 1, maxWidth: '66%', flexBasis: '66%' }}
                >
                  <div
                    className="contact-inner-flexwrap"
                    style={flexWrapStretchStyle}
                  >
                    <DDICardWrapper
                      title="Contact"
                      contentStyle={{ padding: 10 }}
                      style={{ marginRight: 10 }}
                    >
                      <ListInfo>
                        <li>{this.getAddress()}</li>
                        <li>{contactInfo?.address2}</li>
                        <li>{contactInfo?.cityStateZip}</li>
                        <li>
                          {contactInfo?.phone1
                            ? `Phone: ${contactInfo?.phone1}`
                            : ''}{' '}
                          {contactInfo?.phoneExtension
                            ? `Ext: ${contactInfo?.phoneExtension}`
                            : ''}
                        </li>
                        <li>
                          {contactInfo?.phone2
                            ? `(A): ${contactInfo?.phone2}`
                            : ''}
                        </li>
                        <li>
                          {contactInfo?.fax ? (
                            <div>
                              <span>Fax:</span>
                              <Link
                                dataId={dataId}
                                dispatch={dispatch}
                                form={form}
                                parentId={parent?.parentId}
                                parentType={parent?.parentType}
                                sendType="Fax"
                                value={contactInfo?.fax}
                              />
                            </div>
                          ) : null}
                        </li>
                        <li>
                          {contactInfo?.email ? (
                            <div>
                              <span>Email:</span>
                              <Link
                                dataId={dataId}
                                dispatch={dispatch}
                                form={form}
                                parentId={parent?.parentId}
                                parentType={parent?.parentType}
                                sendType="Email"
                                value={contactInfo?.email}
                              />
                            </div>
                          ) : null}
                        </li>
                      </ListInfo>
                    </DDICardWrapper>
                    <ParentInfo
                      dataId={dataId}
                      dispatch={dispatch}
                      form={form}
                      parent={parent}
                      parentInfo={parentInfo}
                      parentTitle={parentTitle}
                      style={{ marginRight: 10 }}
                    />
                  </div>
                  <div
                    className="contact-inner-flexwrap"
                    style={flexWrapStretchStyle}
                  >
                    <SummaryGrid
                      columnDefs={[
                        {
                          field: 'description',
                          headerName: 'Description',
                          cellRendererFramework: DescriptionTooltipCell,
                          cellStyle: params =>
                            params.value === 'Rank'
                              ? { fontWeight: 'bold' }
                              : null
                        },
                        {
                          field: 'rank',
                          headerName: 'Rank',
                          cellStyle: params =>
                            params.node.firstChild
                              ? { fontWeight: 'bold' }
                              : null
                        },
                        {
                          field: 'points',
                          headerName: 'Points',
                          cellStyle: params =>
                            params.node.firstChild
                              ? { fontWeight: 'bold', textAlign: 'right' }
                              : { textAlign: 'right' },
                          headerClass: 'numeric-value-label',
                          valueFormatter: params =>
                            formatNumber(params.value, '0,0')
                        }
                      ]}
                      rowData={customerRankings}
                      title="12-Month Rank"
                      // style={{ flex: 1 }}
                      gridOptions={{
                        getRowStyle: params =>
                          params.node.rowIndex === 0
                            ? { fontWeight: 'bold' }
                            : {}
                      }}
                      ref={el => (this.rankingGrid = el)}
                      form={form}
                      dispatch={dispatch}
                      style={{
                        marginRight: 10,
                        maxWidth: '50%',
                        overflow: 'hidden'
                      }}
                      gridInnerWrapperStyle={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        width: '100%',
                        height: 200
                      }}
                    />
                    <SummaryGrid
                      columnDefs={[
                        { field: 'description', headerName: 'Description' },
                        {
                          cellStyle: params =>
                            params.value < 0
                              ? { color: 'red', textAlign: 'right' }
                              : { textAlign: 'right' },
                          field: 'amount',
                          headerName: 'Amount'
                        },
                        {
                          cellStyle: { textAlign: 'right' },
                          field: 'percent',
                          headerClass: 'numeric-value-label',
                          headerName: 'Percent'
                        }
                      ]}
                      defaultTab={agingView}
                      dispatch={dispatch}
                      form={form}
                      rowData={aging}
                      hasChart
                      title="Aging"
                      style={{
                        marginRight: 10,
                        maxWidth: '50%',
                        overflow: 'hidden'
                      }}
                      gridInnerWrapperStyle={{
                        maxWidth: '100%',
                        overflow: 'hidden',
                        width: '100%',
                        height: 200
                      }}
                      // style={{ flex: 1, marginRight: 10 }}
                    />
                  </div>
                </div>
                <div className="contact-right-col" style={{ flex: 1 }}>
                  <DDICardWrapper
                    title="Image"
                    contentStyle={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      padding: '0.5em'
                    }}
                  >
                    <Image src={imageBase64String} />
                  </DDICardWrapper>
                  <DDICardWrapper
                    title="Comments"
                    contentStyle={{ padding: 10 }}
                  >
                    <DDITextField
                      propertyName="comments"
                      fullWidth
                      multiline
                      rows={10}
                      // variant="outlined"
                      // minWidth="100%"
                    />
                  </DDICardWrapper>
                </div>
              </div>
            </div>
          )}
        </Measure>

        <div style={flexWrapStretchStyle}>
          <div style={{ width: '100%', height: 200 }}>
            <Grid
              getRowNodeId={getRowNodeId}
              columnDefs={columnDefs({
                form
              })}
              enableSorting
              width="100%"
              headerStyle={headerStyle}
              height={setGridHeight(activities, 65)}
              rowSelection="multiple"
              rowData={activities}
              suppressRowClickSelection
              title="Recent Activities"
            />
          </div>
        </div>
      </LayoutFlex>
    )
  }
}

export const contextObj = {
  activities: form => getValue(form, 'activities', emptyList),
  aging: form => getValue(form, 'customerLedger.summary.aging', emptyList),
  agingView: form => getValue(form, 'agingView', 'Chart'),
  contactInfo: form => getValue(form, 'contactInfo', empty),
  customerRankings: form =>
    getValue(form, 'customerLedger.summary.customerRankings', emptyList),
  dataId: form => getValue(form, 'dataId', null),
  imageBase64String: getImageSource,
  parent: form => getValue(form, 'parent', empty),
  parentInfo: form => getValue(form, 'parentInfo', empty),
  parentTitle: form => getValue(form, 'parentTitle', '')
}

export default withContext(contextObj)(Analysis)
