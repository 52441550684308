import React from 'react'
import { noop } from 'utils'


export const SendDocumentGrid = props => {

  const onClick = () => {
    const {
      data: {
        dataId,
        parentId,
        parentType = 'C',
        customerId,
        contactName
      },
      colDef: {
        dispatch = noop,
        form,
        sendType = 'Email',
        callLocation = 'contacts'
      }
    } = props

    dispatch({
      type: 'SEND_DOCUMENT_TRY',
      meta: { form },
      payload: {
        parentId: callLocation === 'contacts' ? parentId: customerId,
        parentType,
        sendType,
        childType: callLocation === 'shipTo' ? callLocation : null,
        dataId
      }
    })
  }

  return (
    <a onClick={onClick} style={{ fontWeight: 'bold' }}>
      {props.value}
    </a>
  )
}

export default SendDocumentGrid