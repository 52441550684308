/* eslint react/prefer-stateless-function: 0 */
import React from 'react'
import { Icon, IconButton } from '@material-ui/core'

export default class extends React.Component {
  static defaultProps = {
    identifier: 'dataId'
  }

  render() {
    return this.props.data ? (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <IconButton
          disabled={!this.props.data[this.props.identifier]}
          tabIndex={-1}
        >
          <Icon style={{ fontSize: 16 }}>keyboard_arrow_down</Icon>
        </IconButton>
      </div>
    ) : null
  }
}
