import { setIn } from 'utils'
import { fromJS } from 'immutable'
import * as CONSTANTS from './constants'
import { targetTypes } from './utils'

const attachmentsBehaviors = {
  [CONSTANTS.UPLOAD_ATTACHMENTS.SUCCESS]: (
    state,
    { payload: { attachments, targetType } }
  ) => {
    let result = state

    if (!targetType || (targetType && !targetTypes.includes(targetType))) {
      const rowData = fromJS(attachments)
      result = setIn(result, 'fields.attachments.rowData', rowData)
      result = setIn(result, 'values.attachments', rowData)
    }

    return result
  },
  [CONSTANTS.DELETE_ATTACHMENT.SUCCESS]: (
    state,
    { payload: { attachments, targetType } }
  ) => {
    let result = state

    if (!targetType || (targetType && !targetTypes.includes(targetType))) {
      const rowData = fromJS(attachments)
      result = setIn(result, 'fields.attachments.rowData', rowData)
      result = setIn(result, 'values.attachments', rowData)
    }

    return result
  }
}

export default attachmentsBehaviors
