import React from 'react'
import { withContext } from 'ddiForm'
import { Paper, Table, TableRow, TableBody, TableCell } from '@material-ui/core'

import { getValue, formatNumber } from 'utils'

const Freight = ({ freight, freightTermsDescription }) => {
  return (
    <Paper square>
      <Table>
        <TableBody>
          <TableRow style={{ background: '#fff' }}>
            <TableCell>Terms:</TableCell>
            <TableCell align="right">{freightTermsDescription}</TableCell>
          </TableRow>
          <TableRow style={{ background: '#fff' }}>
            <TableCell>Freight:</TableCell>
            <TableCell align="right">{formatNumber(freight)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withContext({
  freight: form => getValue(form, 'freight', 0),
  freightTermsDescription: form => getValue(form, 'freightTermsDescription', '')
})(Freight)
