import React from 'react'
// import Field from 'ddiForm/Field'
import createSearch from 'components/Search/createSearch'
import customBehaviors from 'components/Search/IndexSearch/behaviors'
import { openScreen } from 'pages/Main/actions'
// import BaseTooltipTemplate from 'components/BaseTooltipTemplate'
import IconTooltip from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/hoc/IconTooltip'

export const behaviors = {
  ...customBehaviors,
  clearSearch() {
    // console.log('clear')
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value

    if (event.key === 'Tab') {
      // dispatch create action
      const { getNewProduct } = this.props
      if (getNewProduct && val) {
        getNewProduct()
      }
    }
  },
  async onChange(event) {
    this.timeout = this.timeout || 0
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    event.persist()
    const val = event.target.value
    const setVal = () => {
      this.timeout = setTimeout(
        () => this.setField(val),
        this.props.timer || 100
      )
    }
    await this.setState({
      isDirty: true,
      value: val,
      isSet: false
    })
    setVal(val)
  },
  async onBlur(event) {
    // override onBlur,
    // keep value even when field is out of focus
    if (event) event.persist()
    this.blur(this.state.value)
  },
  setField(val) {
    const { setPropertyField, propertyName } = this.props
    if (setPropertyField) {
      setPropertyField({ propertyName, val })
    }
  }
}

const typesMap = {
  product: {
    name: 'Product',
    route: 'productMaster',
    title: 'Product Master'
  }
}

export const options = {
  name: 'NewProduct',
  behaviors,
  defaultState: {
    isDirty: false,
    value: '',
    isFocused: false,
    isSet: true,
    mouseover: false,
    lastSearch: '',
    description: '',
  },
  customContextMenuItems() {
    if (!this) return []
    const searchType = String(
      this.props.indexSearchType || this.props.meta.searchType
    ).toLowerCase()

    const record = typesMap[searchType]
    // if (this.props.value && !record && searchType) {
    //   // debugger
    // }
    return !record
      ? []
      : [
        {
          text: `Open ${record.name} Master`,
          icon: 'open_in_new',
          iconStyle: {
            color: '#444',
            fontSize: 16,
            verticalAlign: 'middle'
          },
          onClick: () => {
            this.props.dispatch(
              openScreen({
                name: record.route,
                title: record.title || record.name,
                dataId: this.props.value
              })
            )
          },
          enabled: !!this.props.value
        }
      ]
  }
}


const NewProduct = createSearch(options)

export default IconTooltip({
  tooltipContent: 'Enter id to create new record.  Press tab to accept.'
})(NewProduct)
