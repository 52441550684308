import React, { useEffect, useState } from 'react'
import memoize from 'memoize-one'
import DDICardWrapper from 'components/DDICardWrapper'
import { withContext, Field, Label } from 'ddiForm'
import { getIn, getMeta, emptyList, getValue, plainDeepEqual } from 'utils'
import { Grid } from '@material-ui/core'
import {
  DDISelectField,
  DDITextField,
  DDIIndexSearch,
  DDIDatePicker,
  DDISerialNumberSearch
} from 'ddiForm/wrapped'
import { required } from 'ddiForm/validations'
import LabeledField from './LabeledField'
import ValidatedField from './ValidatedField'

const TagInfo = props => {
  const {
    isEditing,
    warrantyTypeOptions,
    failReasons,
    productId,
    warehouseId,
    invoiceIdForSerialNumber
  } = props

  return (
    <DDICardWrapper
      contentStyle={{ padding: 20, paddingRight: 40, maxWidth: '500px' }}
    >
      <Grid container spacing={1}>
        <LabeledField label="Type">
          <ValidatedField>
            <DDISelectField
              propertyName="warrantyType"
              preventAutoDisable
              values={
                warrantyTypeOptions?.toJS ? warrantyTypeOptions.toJS() : []
              }
              fullWidth
              selectValue="dataId"
              selectDescription="description"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Vendor">
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <ValidatedField>
                <DDIIndexSearch
                  propertyName="vendorId"
                  metaKey="vendorId"
                  searchType="Vendor"
                  wrapperStyle={{ margin: 0, position: 'relative' }}
                  preventAutoDisable
                  disabled={!isEditing}
                />
              </ValidatedField>
            </Grid>
            <Grid item xs={7}>
              <Label propertyName="vendorDescription" />
            </Grid>
          </Grid>
        </LabeledField>
        <LabeledField label="Replace MFG #">
          <ValidatedField>
            <DDITextField
              propertyName="replacementMFGNumber"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Replace Model #">
          <ValidatedField>
            <DDITextField
              propertyName="replacementModelNumber"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Replace Serial #">
          <ValidatedField>
            <DDISerialNumberSearch
              propertyName="replacementSerialNumber"
              meta={{
                allowInstantSearch: false,
                allowInvalidValues: true,
                allowNavigation: false,
                allowSearchAll: true,
                allowSpecialProducts: false,
                fieldName: 'replacementSerialNumber',
                hasFilters: false,
                hideToggle: true,
                isSecure: false,
                minimumKeywordLength: 2,
                patternValidation: 'S',
                searchType: 'A'
              }}
              wrapperStyle={{ margin: 0, width: '100%' }}
              preventAutoDisable
              disabled={!isEditing}
              productId={productId}
              warehouseId={warehouseId}
              returnTransactionId={invoiceIdForSerialNumber}
              anotherValue="Steve Van Etten"
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Unit Model #">
          <ValidatedField>
            <DDITextField
              propertyName="unitModelNumber"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Unit Serial #">
          <ValidatedField>
            <DDITextField
              propertyName="unitSerialNumber"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Install Date">
          <ValidatedField>
            <DDIDatePicker
              propertyName="installDate"
              preventAutoDisable
              views={['date']}
              fullWidth
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Failure Date">
          <ValidatedField>
            <DDIDatePicker
              propertyName="failureDate"
              preventAutoDisable
              views={['date']}
              fullWidth
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Fail Reason">
          <ValidatedField>
            <DDISelectField
              preventAutoDisable
              values={failReasons?.toJS ? failReasons.toJS() : []}
              propertyName="failReasonCode"
              fullWidth
              selectValue="dataId"
              selectDescription="description"
              disabled={!isEditing}
            />
          </ValidatedField>
        </LabeledField>
        <LabeledField label="Comments">
          <ValidatedField>
            <DDITextField
              propertyName="comments"
              multiline
              rows={4}
              disabled={!isEditing}
              variant="outlined"
            />
          </ValidatedField>
        </LabeledField>
      </Grid>
    </DDICardWrapper>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  warrantyTypeOptions: form => getMeta(form, 'warrantyTypeOptions'),
  failReasons: form => getValue(form, 'failReasons', emptyList),
  validationErrors: form => getValue(form, 'validationErrors', emptyList),
  warehouseId: form => getIn(form, 'values.warehouseId') || '',
  productId: form => getIn(form, 'values.productId') || '',
  invoiceIdForSerialNumber: form =>
    getIn(form, 'values.invoiceIdForSerialNumber') || null
})(TagInfo)
