import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { addBlankRow } from 'ddiForm/actions'
import shortid from 'shortid'
import { emptyList, getIn, fromJS, is } from 'utils'
import usePrevious from 'hooks/usePrevious'
import {
  getAdditionalDataFromContext,
  shouldPromptAdvancedCell
} from 'pages/SalesOrder/utils'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'

let _isMounted = false

const ComponentsGridWithContext = props => {
  const dispatch = useDispatch()
  let { additionalDataMap, lineItemComponents } = props
  const {
    columnDefs,
    parentLineNumber,
    rowId,
    // dispatch,
    frameworkComponents,
    isEditing,
    hasRecord
    // components = []
  } = props
  // const { additionalDataMap, lineItemComponents } = props
  additionalDataMap = additionalDataMap?.toJS ? additionalDataMap.toJS() : {}
  lineItemComponents = lineItemComponents?.toJS ? lineItemComponents.toJS() : []
  const components =
    lineItemComponents?.rowId?.rowData &&
    Array.isArray(lineItemComponents.rowId.rowData)
      ? lineItemComponents?.rowId?.rowData
      : additionalDataMap?.[rowId]?.components || []
  const propertyName = `lineItemComponents.${rowId}`
  const rowData = components?.length ? components : []

  // const prevIsEditing = usePrevious(isEditing)
  // const [gridKey, setGridKey] = useState(shortid.generate())

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const onCellValueChanged = params => {
    //
    if (
      params.colDef.field !== 'inventory' &&
      params.newValue != null
      //  &&
      // !isEqual(params.newValue, params.oldValue)
    ) {
      dispatch({
        type: 'CELL_CHANGED',
        payload: {
          ...params,
          propertyName
        },
        meta: { form: props.form }
      })
    }
  }

  const onRowDataUpdated = params => {
    // console.log('onRowDataUpdated', params)
    if (params?.api && _isMounted) {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      }, 0)
    }
  }

  const setSelectedNodeFromIndex = (api, index) => {
    const node = api.getDisplayedRowAtIndex(index)
    if (node && !node.isSelected()) {
      debugger
      node.setSelected(true)
    }
  }

  const tabToNextCell = params => {
    const { api, backwards } = params
    const colIdsToTrack = ['quantityChange', 'pricing', 'bins']
    let { nextCellPosition } = params

    if (props.modals) {
      return null
    }
    // deal with reverse direction as well... shift -T
    const nextDisplayedCol = backwards
      ? 'getDisplayedColBefore'
      : 'getDisplayedColAfter'

    const inquiryMode = hasRecord && !isEditing

    if (!inquiryMode && !hasRecord) {
      return null
    }

    while (nextCellPosition) {
      if (
        nextCellPosition &&
        nextCellPosition.column &&
        nextCellPosition.column.colDef.cellEditorParams &&
        nextCellPosition.column.colDef.cellEditorParams.tabStop
      ) {
        // check if it has the corresponding flag and what not. either break or continue

        if (colIdsToTrack.includes(nextCellPosition.column.colDef.colId)) {
          if (
            shouldPromptAdvancedCell(
              api,
              nextCellPosition.column,
              nextCellPosition.rowIndex,
              colIdsToTrack
            )
          ) {
            break
          }
        } else {
          break
        }
      }

      if (!nextCellPosition.column && api) {
        if (!backwards) {
          // get rowcount and see if there's one to go to
          const count = api.getDisplayedRowCount()
          if (nextCellPosition.rowIndex + 1 < count) {
            // eslint-disable-next-line prefer-destructuring
            nextCellPosition.column = api?.columnController?.getAllGridColumns()?.[2]
            nextCellPosition.rowIndex += 1
          }
        } else if (backwards && nextCellPosition.rowIndex - 1 >= 0) {
          const gridCols = api?.columnController?.getAllGridColumns()
          nextCellPosition.column = gridCols.find(x => x.colId === 'pricing')
          nextCellPosition.rowIndex -= 1
        }
        setSelectedNodeFromIndex(api, nextCellPosition.rowIndex)
        // const node = this.gridApi.getDisplayedRowAtIndex(
        //   nextCellPosition.rowIndex
        // )
        // if (node && !node.isSelected()) {
        //   node.setSelected(true)
        // }
        break
      }

      nextCellPosition = {
        column: nextCellPosition?.column?.columnApi?.[nextDisplayedCol](
          nextCellPosition.column
        ),
        rowIndex: nextCellPosition.rowIndex
      }
    }
    // end while loop.. we should have the nextCellPos, and need to try to edit if possible.

    if (
      !backwards &&
      !inquiryMode &&
      (!nextCellPosition || (nextCellPosition && !nextCellPosition.column)) &&
      _isMounted
    ) {
      dispatch(addBlankRow(props.form, { propertyName }))

      return null
    }

    if (
      nextCellPosition &&
      nextCellPosition.column &&
      nextCellPosition.column.colDef &&
      nextCellPosition.column.colDef.cellEditorParams &&
      nextCellPosition.column.colDef.cellEditorParams.tabStop
    ) {
      const idx = nextCellPosition.rowIndex
      const key = nextCellPosition.column.colId
      if (
        colIdsToTrack.includes(key) &&
        !shouldPromptAdvancedCell(
          nextCellPosition.column,
          nextCellPosition.rowIndex,
          colIdsToTrack
        )
      ) {
        return null
      }
      setTimeout(() => {
        if (api) {
          api.ensureColumnVisible(key)
          api.setFocusedCell(idx, key)
          api.startEditingCell({
            rowIndex: idx,
            colKey: key
          })
        }
      }, 0)

      return null
    }

    return nextCellPosition
      ? nextCellPosition.column
        ? nextCellPosition
        : null
      : null
  }

  const onRowSelected = params => {
    console.log('onRowSelected', params)
  }

  const emptyRow = {
    rowId: 'blankrow',
    lineNumber: '1',
    dataId: null,
    description: '',
    quantityExtended: null,
    quantity: null,
    parentLineNumber
  }

  const onGridReady = params => {
    const detailId = `componentsDetailGrid-${rowId}`

    // Create Grid Info object
    const detailGridInfo = {
      id: detailId,
      api: params.api,
      columnApi: params.columnApi
    }

    props.masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
  }

  return (
    <>
      {rowData?.length && rowId !== 'blankrow' ? (
        <ManualGrid
          // onCellKeyDown={onCellKeyDown}
          isSecondaryManualGrid
          columnDefs={columnDefs}
          rowData={rowData}
          immutableData
          dispatch={dispatch}
          form={props.form}
          frameworkComponents={frameworkComponents}
          getRowNodeId={x => x.rowId}
          height={400}
          onCellValueChanged={onCellValueChanged}
          propertyName={propertyName}
          showAddButtonIfNoRecordOrEditing={false}
          // suppressColumnVirtualisation
          suppressRowClickSelection={false}
          skipValidationOnUnmount
          singleClickEdit
          title="Components"
          tabToNextCell={tabToNextCell}
          applyColumnDefOrder
          onRowDataUpdated={onRowDataUpdated}
          onRowSelected={onRowSelected}
          rowSelection="single"
          emptyRow={emptyRow}
          focusCell="quantityExtended"
          allowFieldChooser
          autoSize
          // suppressChangeDetection
          onGridReady={onGridReady}
        />
      ) : null}
    </>
  )
}

const componentsGridWithContext = withContext({
  additionalDataMap: form => getIn(form, 'additionalDataMap') || fromJS({}),
  lineItemComponents: form =>
    getIn(form, 'fields.lineItemComponents') || fromJS({})
})(ComponentsGridWithContext)

export default React.memo(componentsGridWithContext)
