import { BASE_INFORM_API_URL } from 'services/constants'
import { createAsyncRequestTypes } from 'utils'

export const PURCHASE_ORDER_SEARCH = '@@PURCHASE_ORDER_SEARCH/'

export const FIND = createAsyncRequestTypes(`${PURCHASE_ORDER_SEARCH}FIND`)
export const EXACT_MATCH = createAsyncRequestTypes(
  `${PURCHASE_ORDER_SEARCH}EXACT_MATCH`
)
export const EXECUTE_INQUIRY = createAsyncRequestTypes(
  `${PURCHASE_ORDER_SEARCH}EXECUTE_INQUIRY`
)

export const OPEN_DROPDOWN = createAsyncRequestTypes(
  `${PURCHASE_ORDER_SEARCH}OPEN_DROPDOWN`
)
export const OPEN_SCREEN = `${PURCHASE_ORDER_SEARCH}OPEN_SCREEN`
export const RESET = `${PURCHASE_ORDER_SEARCH}RESET`

export const PURCHASE_ORDER_EXACT_MATCH_URL = `${BASE_INFORM_API_URL}/purchaseorderinquiry/exactmatch`
export const PURCHASE_ORDER_INQUIRY_URL = `${BASE_INFORM_API_URL}/purchaseorderinquiry/search`
export const ROW_SELECTED = `${PURCHASE_ORDER_SEARCH}ROW_SELECTED`
export const FLAGGED = `${PURCHASE_ORDER_SEARCH}/FLAGGED`
export const GET_PURCHASE_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/purchaseorderinquiry/summary`

export const CLEAR_INQUIRY_RESULTS = `${PURCHASE_ORDER_SEARCH}CLEAR_INQUIRY_RESULTS`

export const PRINT_PURCHASE_ORDERS = createAsyncRequestTypes(
  `${PURCHASE_ORDER_SEARCH}PRINT_PURCHASE_ORDERS`
)
