// import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import NumberFormat from 'react-number-format'
import React from 'react'
// import MaskedInput from 'react-text-mask'

const getValue = event => {
    let value =
        event.target.value || event.target.value === 0 ? event.target.value : ''
    value = value ? Number(value.toString().replace(/\$|,/g, '')) : ''
    return value
}

export default function IntegerTextMask(props) {
    const {
        inputRef,
        onChange,
        onBlur,
        noDecimalLimit,
        decimalScale,
        fixedDecimalScale,
        textAlign = 'right',
        style = {},
        ...rest
    } = props

    const decimalFormatting = noDecimalLimit
        ? {}
        : {
            decimalScale,
            fixedDecimalScale
        }

    let isAllowed = {}
    if (props.id === 'financeChargePercent') {
        console.log(props)
    }
    if (props.range && Array.isArray(props.range)) {
        const { range } = props
        isAllowed = {
            isAllowed: values => {
                let { floatValue } = values
                floatValue = floatValue ?? 0
                return range[2]
                    ? floatValue > range[0] && floatValue < range[1]
                    : floatValue >= range[0] && floatValue <= range[1]
            }
        }
    }
    return (
        <NumberFormat
            {...rest}
            {...decimalFormatting}
            {...isAllowed}
            getInputRef={inputRef}
            style={{ ...style, textAlign }}
            onChange={e => {
                e.persist()
                // console.log('onChangeHandler', e)
                const value = getValue(e)

                onChange({
                    target: {
                        value
                    }
                })
            }}
            onBlur={e => {
                // console.log(e.target.value)
                // const value = e.target.value || e.target.value === 0 ? e.target.value : ''
                const value = getValue(e)
                // debugger
                onBlur({
                    target: {
                        value
                    }
                })
            }}
        />
    )
}
IntegerTextMask.defaultProps = {
    thousandSeparator: true,
    decimalSeparator: '.',
    decimalScale: 0,
    fixedDecimalScale: false,
    noDecimalLimit: false,
    allowNegative: false,
}
// NumericTextMask.defaultProps = {
//   prefix: '', // prefix (string): what to display before the amount. Defaults to '$'.
//   suffix: '', // suffix (string): what to display after the amount. Defaults to empty string.
//   // includeThousandsSeparator: true, // includeThousandsSeparator (boolean): whether or not to separate thousands. Defaults to to true.
//   // thousandsSeparatorSymbol: ',', // thousandsSeparatorSymbol (string): character with which to separate thousands. Default to ','.
//   allowDecimal: false, // allowDecimal (boolean): whether or not to allow the user to enter a fraction with the amount. Default to false.
//   decimalSymbol: '.', // decimalSymbol (string): character that will act as a decimal point. Defaults to '.'
//   decimalLimit: 2, // decimalLimit (number): how many digits to allow after the decimal. Defaults to 2
//   integerLimit: null, // integerLimit (number): limit the length of the integer number. Defaults to null for unlimited
//   requireDecimal: false, // requireDecimal (boolean): whether or not to always include a decimal point and placeholder for decimal digits after the integer. Defaults to false.
//   allowNegative: false, // allowNegative (boolean): whether or not to allow negative numbers. Defaults to false
//   allowLeadingZeroes: false // allowLeadingZeroes (boolean): whether or not to allow leading zeroes. Defaults to false
// }

// export default function NumericTextMask(props) {
//   const { ...other } = props
//   const mask = createNumberMask({
//     prefix: '', // props.prefix,
//     allowDecimal: props.allowDecimal,
//     decimalLimit: props.decimalLimit,
//     suffix: '',
//     includeThousandsSeparator: props.includeThousandsSeparator,
//     thousandsSeparatorSymbol: props.thousandsSeparatorSymbol,
//     decimalSymbol: props.decimalSymbol,
//     integerLimit: props.integerLimit,
//     requireDecimal: props.requireDecimal,
//     allowNegative: props.allowNegative,
//     allowLeadingZeroes: props.allowLeadingZeroes
//   })
//   const onBlur = e => {
//     let value = e.target.value
//     value = value.replace(/[,]/g, '')

//     /*
//       the requireDecimal prop of
//       number formatter is not working correctly
//       this will help with that
//     */
//     // if ((value || value === 0) && props.decimalLimit && props.requireDecimal) {
//     //   value = parseFloat(value).toFixed(props.decimalLimit)
//     // }

//     const target = {
//       target: {
//         value
//       }
//     }
//     props.onBlur(target)
//   }
//   return (
//     <MaskedInput
//       {...other}
//       mask={mask}
//       placeholderChar={'\u2000'}
//       showMask={!!props.value}
//       onBlur={onBlur}
//     />
//   )
// }

// NumericTextMask.defaultProps = {
//   prefix: '', // prefix (string): what to display before the amount. Defaults to '$'.
//   suffix: '', // suffix (string): what to display after the amount. Defaults to empty string.
//   includeThousandsSeparator: true, // includeThousandsSeparator (boolean): whether or not to separate thousands. Defaults to to true.
//   thousandsSeparatorSymbol: ',', // thousandsSeparatorSymbol (string): character with which to separate thousands. Default to ','.
//   allowDecimal: false, // allowDecimal (boolean): whether or not to allow the user to enter a fraction with the amount. Default to false.
//   decimalSymbol: '.', // decimalSymbol (string): character that will act as a decimal point. Defaults to '.'
//   decimalLimit: 2, // decimalLimit (number): how many digits to allow after the decimal. Defaults to 2
//   integerLimit: null, // integerLimit (number): limit the length of the integer number. Defaults to null for unlimited
//   requireDecimal: false, // requireDecimal (boolean): whether or not to always include a decimal point and placeholder for decimal digits after the integer. Defaults to false.
//   allowNegative: false, // allowNegative (boolean): whether or not to allow negative numbers. Defaults to false
//   allowLeadingZeroes: false // allowLeadingZeroes (boolean): whether or not to allow leading zeroes. Defaults to false
// }
