import React from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { Paper, Tooltip, Fab, Icon } from '@material-ui/core'
import { getIn, emptyList, is } from 'utils'

import './styles/item-groups.scss'

export const MoveProductButtons = ({
  availableLineItemsList,
  addProductsToGroup,
  addAllToGroup,
  selectedLineNumbersList,
  selectedItemGroup
}) => {
  const availableLineItems = availableLineItemsList?.toJS
    ? availableLineItemsList.toJS()
    : []
  const selectedLineNumbers = selectedLineNumbersList?.toJS
    ? selectedLineNumbersList.toJS()
    : []

  const handleAdd = () =>
    addProductsToGroup({
      lineNumbers: selectedLineNumbers,
      selectedItemGroup
    })

  return (
    <div className="item-groups-three-col-center" style={{ maxWidth: 80 }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          padding: '0 10px'
        }}
      >
        <Paper
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Tooltip title="Transfer to Group" placement="top">
            <Fab
              onClick={handleAdd}
              disabled={
                !selectedLineNumbers.length || !is.number(selectedItemGroup)
              }
              style={{ margin: '10px 10px 0 10px' }}
              size="small"
              color="primary"
            >
              <Icon style={{ transform: 'rotate(160deg' }}>reply</Icon>
            </Fab>
          </Tooltip>
          <Tooltip title="Transfer All" placement="top">
            <Fab
              disabled={
                !availableLineItems.length || !is.number(selectedItemGroup)
              }
              style={{ margin: 10 }}
              size="small"
              color="primary"
              onClick={addAllToGroup}
            >
              <Icon style={{ transform: 'rotate(160deg' }}>reply_all</Icon>
            </Fab>
          </Tooltip>
        </Paper>
      </div>
    </div>
  )
}

// export const contextObj = {
//   availableLineItems: form => form.fields && form.fields.groups &&
//     form.fields.groups.availableLineItems &&
//     form.fields.groups.availableLineItems.rowData ?
//       form.fields.groups.availableLineItems.rowData : [],
//   selectedLineNumbers: form => form.fields && form.fields.groups &&
//     form.fields.groups.availableLineItems &&
//     form.fields.groups.availableLineItems.selectedLineNumbers ?
//       form.fields.groups.availableLineItems.selectedLineNumbers : [],
//   selectedItemGroup: form => form.fields && form.fields.groups && form.fields.groups.itemGroups &&
//     is.number(form.fields.groups.itemGroups.selectedRowIndex) ?
//       form.fields.groups.itemGroups.selectedRowIndex : null
// }

/* fixed 9/23/2020 SVE */
export const contextObj = {
  availableLineItemsList: form =>
    getIn(form, 'fields.groups.availableLineItems.rowData') || emptyList,
  selectedLineNumbersList: form =>
    getIn(form, 'fields.groups.availableLineItems.selectedLineNumbers') ||
    emptyList,
  selectedItemGroup: form => {
    const selectedItemGroup = getIn(
      form,
      'fields.groups.itemGroups.selectedRowIndex'
    )

    if (is.number(selectedItemGroup)) {
      return selectedItemGroup
    }

    return null
  }
}

export default withContext(contextObj)(MoveProductButtons)
