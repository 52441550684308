import { createAsyncRequestTypes } from 'utils'

/*
  note the naming convention,
  as the constants, actions and sagas
  for this screen are shared
  with CustomerDueToBuy
*/
const CUSTOMER_REPORT_FORM = '@@CUSTOMER_REPORT_FORM@@/'

export const LAUNCH_SELECTION_CRITERIA = createAsyncRequestTypes(
  `${CUSTOMER_REPORT_FORM}LAUNCH_SELECTION_CRITERIA`
)

export const SET_SELECTION_CRITERIA = createAsyncRequestTypes(
  `${CUSTOMER_REPORT_FORM}SET_SELECTION_CRITERIA`
)

export const CLEAR_SELECTION_CRITERIA = createAsyncRequestTypes(
  `${CUSTOMER_REPORT_FORM}CLEAR_SELECTION_CRITERIA`
)

export const RUN_ORDER_FORM_REPORT = createAsyncRequestTypes(
  `${CUSTOMER_REPORT_FORM}RUN_ORDER_FORM_REPORT`
)

export const CLEAR_FIELD_AND_DESCRIPTION = `${CUSTOMER_REPORT_FORM}CLEAR_FIELD_AND_DESCRIPTION`

export const SET_INITIAL_CRITERIA = createAsyncRequestTypes(
  `${CUSTOMER_REPORT_FORM}SET_INITIAL_CRITERIA`
)