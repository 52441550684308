/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useState } from 'react'
import { Map } from 'immutable'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { plainDeepEqual, getValue, getMeta, emptyList } from 'utils'
import { Label, Field, withContext } from 'ddiForm'

import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'

import '../../../styles/master-style.scss'
// import { connect } from 'connect'

const PatternTypeModal = ({ patternsList, patternType, searchFieldsMap }) => {
  const patterns = patternsList && patternsList?.toJS ? patternsList.toJS() : []
  const searchFields = searchFieldsMap?.toJS
    ? searchFieldsMap.toJS()
    : {
        Vendor: {},
        ProductLine: {}
      }

  const { Vendor = {}, ProductLine = {} } = searchFields

  const getPatternTooltip = memoize(
    p =>
      p.reduce((acc, next) => {
        acc = acc.concat(`${next}\n`)
        return acc
      }, 'Allowed patterns include:\n'),
    plainDeepEqual
  )

  return (
    <div className="pattern-type-modal-wrapper">
      <div className="search-row-wrapper">
        <label className="search-row-label">
          {patternType === 'P' ? 'Product Line:' : 'Vendor'}
        </label>
        <div className="search-row-input">
          {patternType === 'P' ? (
            <DDIIndexSearch
              propertyName="fastProduct.productLineId"
              meta={ProductLine}
              preventAutoDisable
            />
          ) : (
            <DDIIndexSearch
              propertyName="fastProduct.primaryVendorId"
              meta={Vendor}
              preventAutoDisable
            />
          )}
        </div>
        <div className="search-row-description">
          <Field
            propertyName={
              patternType === 'P'
                ? 'fastProduct.productLineDescription'
                : 'fastProduct.primaryVendorDescription'
            }
            component={Label}
          />
        </div>
      </div>
      <div className="search-row-wrapper">
        <label className="search-row-label">Product:</label>
        <div
          className="search-row-input"
          title={patterns && patterns.length ? getPatternTooltip(patterns) : ''}
        >
          <DDITextField propertyName="fastProduct.dataId" preventAutoDisable />
        </div>
        <div className="search-row-description" />
      </div>
    </div>
  )
}

PatternTypeModal.propTypes = {
  patterns: PropTypes.array.isRequired,
  patternType: PropTypes.string.isRequired,
  searchFields: PropTypes.object.isRequired
}

// export default withContext({
//   patterns: form => form.values && form.values.fastProduct && form.values.fastProduct.patterns ? form.values.fastProduct.patterns : [],
//   patternType: form => form.values && form.values.fastProduct && form.values.fastProduct.patternType ? form.values.fastProduct.patternType : 'P',
//   searchFields: form => form.meta && form.meta.fastProduct && form.meta.fastProduct.searchFields ? form.meta.fastProduct.searchFields : { Vendor: {}, ProductLine: {} }
// })(PatternTypeModal)

/* FIXED SVE 9/23/2020 */
export default withContext({
  patternsList: form => getValue(form, 'fastProduct.patterns', emptyList),
  patternType: form => getValue(form, 'fastProduct.patternType', 'P'),
  searchFieldsMap: form =>
    getMeta(
      form,
      'fastProduct.searchFields',
      Map({ Vendor: {}, ProductLine: {} })
    )
})(PatternTypeModal)
