import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
// import { openScreen as openScreenAction } from 'pages/Main/actions'
import { openScreen } from 'pages/ContactMaster/actions'
import { Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import { getIn, getValue } from 'utils'
import { BASE_INFORM_URL } from 'services/constants'
import { addNewActivity as addNewActivityAction } from 'modals/EventModal/actions'

const marginRight = { marginRight: 5 }

const buttonStyle = {
  backgroundPosition: 'center',
  // backgroundSize: '40%',
  marginRight: 5,
  minHeight: 18,
  minWidth: 50
}

const TooltipWrapper = styled.div`
  margin-top: 2px;
`

export class LeftFooterButtons extends Component {
  static propTypes = {
    canAddActivity: PropTypes.bool.isRequired,
    canCheckStock: PropTypes.bool.isRequired,
    canCreateNewPriceQuote: PropTypes.bool.isRequired,
    canCreateNewSalesOrder: PropTypes.bool.isRequired,
    canInquireOrder: PropTypes.bool.isRequired,
    canViewCustomerPivotReport: PropTypes.bool.isRequired,
    canViewOrderpad: PropTypes.bool.isRequired,
    canViewPricing: PropTypes.bool.isRequired,
    canViewSalesOpportunity: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    isCustomerContact: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    newMode: PropTypes.bool.isRequired,
    parentDescription: PropTypes.string.isRequired,
    parentId: PropTypes.string.isRequired
  }

  addNewActivity = () => {
    const { dispatch, form } = this.props

    dispatch(addNewActivityAction(form, { selectedStartDate: new Date() }))
  }

  openScreen = (e, screenData) => {
    e.preventDefault()
    const { dispatch, form } = this.props
    // this.props.dispatch(openScreenAction(screenData))
    dispatch(openScreen.try(form, screenData))
  }

  openNewOrder = () => {
    const { dataId, dispatch, form, parentId } = this.props

    dispatch(
      openScreen.try(form, {
        customerId: parentId,
        orderedById: dataId,
        name: 'salesorder',
        image: 'sales_order_entry_16.png',
        title: 'Sales Order',
        guid: null,
        route: 'salesorder'
        // newInstance: true
      })
    )
  }

  openNewQuote = () => {
    const { dataId, dispatch, form, parentId } = this.props

    dispatch(
      openScreen.try(form, {
        customerId: parentId,
        orderedById: dataId,
        name: 'salesorder',
        title: 'Sales Order',
        guid: null,
        route: 'salesorder',
        quote: true
      })
    )
  }

  render() {
    const {
      canAddActivity,
      canCheckStock,
      canCreateNewPriceQuote,
      canCreateNewSalesOrder,
      canInquireOrder,
      canViewOrderpad,
      canViewPricing,
      canViewSalesOpportunity,
      isCustomerContact,
      isEditing,
      newMode,
      parentId,
      parentDescription
    } = this.props

    return (
      <>
        {!isEditing && !newMode && canCreateNewSalesOrder && (
          <DDIButton
            variant="contained"
            label="New Order"
            onClick={this.openNewOrder}
            key="new-order"
            preventAutoDisable
            style={marginRight}
            disabled={newMode}
          />
        )}
        {!isEditing && !newMode && canCreateNewPriceQuote && (
          <DDIButton
            variant="contained"
            label="New Quote"
            onClick={this.openNewQuote}
            key="new-quote"
            preventAutoDisable
            style={marginRight}
            disabled={newMode}
          />
        )}

        {(canCheckStock || !isCustomerContact || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Check Stock"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                bindTo="onClick"
                key="product-master"
                preventAutoDisable
                disabled={newMode}
                style={buttonStyle}
                onClick={e =>
                  this.openScreen(e, {
                    name: 'productMaster',
                    title: 'Product Master',
                    startTab: {
                      primary: 'analysis',
                      secondary: 'overview'
                    }
                  })
                }
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/product_master_32.png`}
                  alt="Check Stock"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}

        {(canViewPricing || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Pricing"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                // actionName="exit"
                bindTo="onClick"
                key="product-master"
                preventAutoDisable
                disabled={newMode}
                onClick={e =>
                  this.openScreen(e, {
                    customer: parentId,
                    name: 'productMaster',
                    title: 'Product Master',
                    startTab: {
                      primary: 'pricing'
                    }
                  })
                }
                // style={getButtonStyle('productMaster')}
                style={buttonStyle}
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/product_price_grp_32.png`}
                  alt="Pricing"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}

        {(canInquireOrder || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Order Inquiry"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                actionName="exit"
                key="sales-order"
                onClick={e =>
                  this.openScreen(e, {
                    customer: parentId,
                    name: 'salesOrderInquiry',
                    title: 'Sales Order Inquiry'
                  })
                }
                preventAutoDisable
                disabled={newMode}
                // style={getButtonStyle('salesOrder')}
                style={buttonStyle}
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/sales_order_inquiry_32.png`}
                  alt="Sales Order Inquiry"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}
        {(canViewSalesOpportunity || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Sales Opportunity"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                // actionName="exit"
                onClick={e =>
                  this.openScreen(e, {
                    // customer: parentId,
                    // customerDescription: parentDescription,
                    name: 'productOpportunities',
                    route: 'shiptoopportunities',
                    title: 'Product Opportunities',
                    customerId: parentId,
                    searchBy: 'DueToBuy'
                  })
                }
                key="sales-opportunity"
                preventAutoDisable
                disabled={newMode}
                style={buttonStyle}
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/crm_sales_opportunities_32.png`}
                  alt="Add Activity"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}

        {(canAddActivity || !isCustomerContact || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Add Activity"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                actionName="exit"
                onClick={this.addNewActivity}
                key="new-activity"
                preventAutoDisable
                disabled={newMode}
                // style={getButtonStyle('activity')}
                style={buttonStyle}
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/activity_32.png`}
                  alt="Add Activity"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}

        {(canViewOrderpad || newMode) && (
          <Tooltip
            PopperProps={{ style: { pointerEvents: 'none' } }}
            // disableHoverListener
            title="Customer Order Pad"
          >
            <TooltipWrapper>
              <DDIButton
                variant="contained"
                actionName="exit"
                onClick={e =>
                  this.openScreen(e, {
                    customer: parentId,
                    customerDescription: parentDescription,
                    name: 'customerOrderPad',
                    title: 'Customer Order Pad'
                    // dataId: { customerId: parentId }
                  })
                }
                key="order-pad"
                preventAutoDisable
                disabled={newMode}
                // style={getButtonStyle('orderPad')}
                style={buttonStyle}
              >
                <img
                  src={`${BASE_INFORM_URL}/resources/white/sales_customer_order_pad_32.png`}
                  alt="Customer Order Pad"
                  style={{
                    height: 18
                  }}
                />
              </DDIButton>
            </TooltipWrapper>
          </Tooltip>
        )}
      </>
    )
  }
}

export const contextObj = {
  canAddActivity: form => getValue(form, 'canAddActivity', false),
  canCheckStock: form => getValue(form, 'canCheckStock', false),
  canCreateNewPriceQuote: form =>
    getValue(form, 'canCreateNewPriceQuote', false),
  canCreateNewSalesOrder: form =>
    getValue(form, 'canCreateNewSalesOrder', false),
  canInquireOrder: form => getValue(form, 'canInquireOrder', false),
  canViewCustomerPivotReport: form =>
    getValue(form, 'canViewCustomerPivotReport', false),
  canViewOrderpad: form => getValue(form, 'canViewOrderpad', false),
  canViewPricing: form => getValue(form, 'canViewPricing', false),
  canViewSalesOpportunity: form =>
    getValue(form, 'canViewSalesOpportunity', false),
  dataId: form => getValue(form, 'dataId', null),
  isCustomerContact: form => getValue(form, 'isCustomerContact', false),
  isEditing: form => getIn(form, 'isEditing') || false,
  newMode: form => getIn(form, 'newMode') || false,
  parentDescription: form =>
    getIn(form, 'values.parent.address.description') || '',
  parentId: form => getValue(form, 'parent.parentId', '')
}

export default withContext(contextObj)(LeftFooterButtons)
