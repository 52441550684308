import React, { memo } from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { formatNumber } from 'utils'
import { generateCellStyle } from 'pages/SalesOrder/utils'

import '../styles/bottom-tabs.scss'

export const ARInfo = memo(
  ({
    arBalance,
    orderBalance,
    creditLimitBalance,
    currentAging,
    thru30Aging,
    thru60AndOverAging,
    customerIsOverMaxDaysAllowed
  }) => {
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className="bold-title-cell">A/R Balance</TableCell>

            <TableCell style={generateCellStyle(arBalance)}>
              {formatNumber(arBalance)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bold-title-cell">Order Balance</TableCell>

            <TableCell style={generateCellStyle(orderBalance)}>
              {formatNumber(orderBalance)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bold-title-cell">
              Credit Limit Balance
            </TableCell>

            <TableCell style={generateCellStyle(creditLimitBalance)}>
              {formatNumber(creditLimitBalance)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bold-title-cell">Current</TableCell>

            <TableCell
              style={generateCellStyle(
                currentAging,
                customerIsOverMaxDaysAllowed
              )}
            >
              {formatNumber(currentAging)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bold-title-cell">1-30</TableCell>

            <TableCell
              style={generateCellStyle(
                thru30Aging,
                customerIsOverMaxDaysAllowed
              )}
            >
              {formatNumber(thru30Aging)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className="bold-title-cell">31-60 & Over</TableCell>

            <TableCell
              style={generateCellStyle(
                thru60AndOverAging,
                customerIsOverMaxDaysAllowed
              )}
            >
              {formatNumber(thru60AndOverAging)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
)

export default ARInfo
