import { getIn, setIn } from 'utils'
import { fromJS } from 'immutable'
import * as CONSTANTS from './constants'

const updateEditedFields = (state, propertyName) => {
  let editedFields = getIn(state, 'editedFields')
  if (editedFields) {
    editedFields = editedFields.add(propertyName)
    state = setIn(state, 'editedFields', editedFields)
  }

  return state
}

const truckRoutesBehaviors = {
  [CONSTANTS.TRUCK_ROUTES_CHANGE]: (
    state,
    { payload: { rowIndex, newData } }
  ) => {
    let result = state
    let routes = getIn(state, 'fields.routes').toJS()
    const values = routes.rowData || routes.value
    const route = values[rowIndex]
    const { description, recordName } = newData
    route.shipViaId = recordName
    route.description = description
    values[rowIndex] = route
    routes = setIn(fromJS(routes), 'rowData', fromJS(values))
    // routes = setIn(fromJS(routes), 'value', fromJS(values))
    result = updateEditedFields(result, 'routes')
    result = setIn(result, 'fields.routes', fromJS(routes))
    result = setIn(result, 'values.routes', fromJS(values))
    return result
  },
  [CONSTANTS.STOP_NUMBER_CHANGE]: (
    state,
    { payload: { rowIndex, newValue } }
  ) => {
    let result = state
    let routes = getIn(state, 'fields.routes').toJS()
    const values = routes.rowData || routes.value
    const route = values[rowIndex]
    route.stopNumber = newValue
    values[rowIndex] = route
    routes = setIn(fromJS(routes), 'rowData', fromJS(values))
    // routes = setIn(fromJS(routes), 'value', fromJS(values))
    result = updateEditedFields(result, 'routes')
    result = setIn(result, 'fields.routes', fromJS(routes))
    result = setIn(result, 'values.routes', fromJS(values))
    return result
  },
  [CONSTANTS.CLEAR_TRUCK_ROUTE]: (state, { payload: { rowIndex } }) => {
    let result = state
    let routes = getIn(state, 'fields.routes').toJS()
    
    const { rowData } = routes
    rowData[rowIndex] = {
      ...rowData[rowIndex],
      stopNumber: '',
      shipViaId: '',
      description: ''
    }

    routes = setIn(fromJS(routes), 'rowData', fromJS(rowData))
    // routes = setIn(fromJS(routes), 'value', fromJS(rowData))
    result = updateEditedFields(result, 'routes')
    result = setIn(result, 'fields.routes', fromJS(routes))
    result = setIn(result, 'values.routes', fromJS(rowData))

    return result
  }
}

export default truckRoutesBehaviors
