import { call, fork, put, select, take } from 'redux-saga/effects'

import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import addShipToInModal from 'components/addShipToInModal'
import { addModal, confirm } from 'modals/actions'
import { getFormSelector } from 'ddiForm/utils'
import { setField } from 'ddiForm/actions'
import { api } from 'services'
import { getIn, isUsaCanadaOrBlank } from 'utils'

import ChangeShipToModal from 'pages/SalesOrder/components/ChangeShipToModal'
import { getGroupNames } from '../utils'

export function* launchCustomerShipToProcess(form) {
  const addShipToAction = addShipToInModal(
    {
      dataId: f => {
        if (getIn(f, 'fields.shipToId.value')) {
          return getIn(f, 'fields.shipToId.value')
        }

        return getIn(f, 'values.shipToId')
      },
      parentId: f => {
        if (getIn(f, 'fields.customerId.value')) {
          return getIn(f, 'fields.customerId.value')
        }

        return getIn(f, 'values.customerId')
      },
      name: 'customerShipTo',
      groupNames: ['setup'],
      title: 'Customer Ship To'
    },
    form
  )

  yield put(addShipToAction)
}

export function* launchCustomerShipToListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_CUSTOMER_SHIP_TO)

    if (form === formListener) {
      yield fork(launchCustomerShipToProcess, form)
    }
  }
}

export function* editShipToListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.EDIT_SHIP_TO.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(editShipToProcess, form)
    }
  }
}

export function* editShipToProcess(form) {
  const formState = yield select(getFormSelector(form))
  const shipToAddress =
    getIn(formState, 'values.shipToAddress') &&
    getIn(formState, 'values.shipToAddress').toJS()
      ? getIn(formState, 'values.shipToAddress').toJS()
      : {}
  const isSave = shipToAddress?.save

  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const modalStyleOptions = isMobile
    ? {
        fullScreen: true,
        marginTop: '0px',
        maxHeight: '95%',
        width: '95%',
        maxWidth: '95%'
      }
    : {
        maxWidth: 500,
        maxHeight: 800
      }
  const countriesList = ['usa', 'ca', 'canada']
  const modalOpts = {
    component: ChangeShipToModal,
    options: {
      title: 'Change Ship To',
      data: {
        form,
        shipToAddress,
        actions: [
          {
            clickEvent: {
              saga: saveEditedShipTo,
              args: { form }
            },
            primary: true,
            disabled: ({ fields }) => {
              // console.log(...args)
              const { changeShipTo, shipToAddress } = fields
              console.log(changeShipTo, shipToAddress)

              if (!changeShipTo) {
                return true
              }
              const maxLen = isUsaCanadaOrBlank(changeShipTo?.country?.value)
                ? 20
                : 30

              return changeShipTo?.city?.value?.length >= maxLen
            },
            title: 'OK'
          },
          {
            clickEvent: {
              action: actions.editShipTo.success,
              args: { form }
            },
            secondary: true,
            title: 'Cancel'
          }
        ]
      },
      ...modalStyleOptions
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  // set save manual ship to
  yield put(setField(form, 'changeShipTo.save', isSave))

  return modal.payload.id
}

export function* saveEditedShipTo(args) {
  const { form, modalSaga } = args
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  const shipToObj =
    getIn(formState, 'fields.changeShipTo') &&
    getIn(formState, 'fields.changeShipTo').toJS
      ? getIn(formState, 'fields.changeShipTo').toJS()
      : {}

  const shipToAddress = {}
  if (shipToObj && Object.keys(shipToObj).length) {
    for (const prop in shipToObj) {
      const val = shipToObj[prop].value
      shipToAddress[prop] = val
    }
  }

  yield put(actions.editShipTo.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    groupNames,
    properties: {
      shipToAddress
    }
  })

  if (response) {
    yield put(actions.onPropertyChange.success(response, form))
    yield put(actions.editShipTo.success(response, form))
  } else {
    yield put(actions.editShipTo.failure(error, form))
    if (modalSaga) {
      throw new Error()
    }
  }
}

export default function* headerSagas(form) {
  yield fork(launchCustomerShipToListener, form)
  yield fork(editShipToListener, form)
}
