/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { connect } from 'react-redux'

import {
  otherMessagesSelector,
  addedOtherSelector,
  removedOtherSelector
} from '../selectors'

const style = { width: 360 }

export const mapStateToProps = state => ({
  messages: otherMessagesSelector(state),
  added: addedOtherSelector(state),
  removed: removedOtherSelector(state)
})

export const OtherMessages = props => {
  const { messages, added, removed } = props
  const [snacks, createSnacks] = useState([])
  const [trackedAdd, setAdded] = useState(null)
  const [trackedRemoved, setRemoved] = useState(null)
  const [trackedMessages, setTracked] = useState(messages)

  useEffect(() => {
    let msg
    if (!messages.size && snacks.length) {
      createSnacks([])
      setAdded(null)
      setRemoved(null)
      setTracked(messages)
      return
    }
    if (added != null && trackedAdd !== added) {
      setAdded(added)
      msg = messages.find(x => x.get('id') === added)
      if (msg) {
        const Comp = msg.get('component')
        createSnacks(
          snacks.concat(
            <Comp severity={msg.get('type')} key={msg.get('id')}>
              {msg.get('message')}
            </Comp>
          )
        )
      }
    }

    if (removed != null && trackedRemoved !== removed) {
      setRemoved(removed)
      msg = trackedMessages.find(x => x.get('id') === removed)
      if (msg) {
        createSnacks(
          trackedMessages
            .filter(x => x.get('id') != msg.get('id'))
            .map(x => {
              const Comp = x.get('component')
              return (
                <Comp severity={x.get('type')} key={x.get('id')}>
                  {x.get('message')}
                </Comp>
              )
            })
            .toJS()
        )
      }
    }

    setTracked(messages)
  }, [messages, added, removed])

  return (
    <Snackbar
      open={!!snacks.length}
      anchorOrigin={props.anchorOrigin}
      style={style}
    >
      <div>{snacks}</div>
    </Snackbar>
  )
}

export default connect(mapStateToProps)(OtherMessages)
