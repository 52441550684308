import React, { Component, Fragment } from 'react'
import memoize from 'memoize-one'
import shortid from 'shortid'
import { getRgb, is } from 'utils'

const numericFields = ['bo', 'ordered', 'toShip']

export default class BaseTooltipTemplate extends Component {
  getImage = ({ image, imageURL }) => {
    if (!image && !imageURL) {
      return null
    }
    const src = image ? `data:image/jpeg;base64, ${image}` : imageURL
    return <img src={src} height={200} width={200} alt="tooltip" />
  }
  getStatus = status => {
    if (!status) return null
    const { color, description, value } = status
    return (
      <Fragment>
        {description && (
          <div
            style={{
              backgroundColor: getRgb(color),
              borderRadius: 4,
              color: 'white',
              fontSize: 12,
              lineHeight: '16px',
              marginLeft: 10,
              padding: 5,
              textTransform: 'uppercase'
            }}
          >
            {description}
          </div>
        )}
        {value && <div style={{ color: '#fff' }}>{value}</div>}
      </Fragment>
    )
  }

  renderComments = comments => {
    const listItemStyle = {
      color: '#fff',
      fontSize: 12,
      lineHeight: '16px',
      marginBottom: 0
    }
    return (
      <ul
        style={{
          listStyleType: 'none',
          marginLeft: 0,
          paddingLeft: 0
        }}
      >
        {comments.map((item, index) => {
          if (index === 0) {
            return (
              <li
                key={shortid.generate()}
                style={{ ...listItemStyle, marginTop: 10}}
              >
                <strong>{item}</strong>
              </li>
            )
          }
          return (
            <li key={shortid.generate()} style={listItemStyle}>{item}</li>
          )
        })}
      </ul>
    )
  }

  render() {
    const { content } = this.props
    if (!content) return null
    let {
      summary: {
        body,
        footer: { actions },
        title: { description, status }
      }
    } = content

    body = body || {}
    const { comments = [], info = [] } = body
    return (
      <div className="inform-tooltip" style={{ width: 400 }}>
        <div
          className="tooltip-heading"
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <h2
            style={{
              color: '#fff',
              fontSize: 12,
              fontWeight: 500,
              lineHeight: '14px'
            }}
          >
            {description}
          </h2>
          {this.getStatus(status)}
        </div>
        <div className="tooltip-content">
          <div>
            {info.map((x, i) => (
              <div style={{ color: 'white', fontSize: 11 }} key={x}>
                {x}
              </div>
            ))}
            {body && body.comments ? this.renderComments(comments) : null}
          </div>
          {/*  <div style={{ marginTop: 10 }}>
            {actions.map((x, i) => (
              <div style={{ marginBottom: 5, color: '#444' }} key={x.id}>
                <span
                  style={{
                    background: '#fff',
                    borderRadius: 4,
                    display: 'inline-block',
                    fontSize: 10,
                    lineHeight: '10px',
                    marginRight: 3,
                    padding: 2,
                    minWidth: 12
                  }}
                >
                  {x.id}
                </span>
                <span
                  style={{
                    display: 'inline-block',
                    fontSize: 11,
                    color: '#fff'
                  }}
                >
                  {x.description}
                </span>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    )
  }
}
