import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

/* start Read/Lock/Unlock template actions */
export const readTemplate = (form, { templateKey }) =>
  createAction(CONSTANTS.READ_TEMPLATE.TRY, { templateKey }, form)

export const lockTemplate = form =>
  createAction(CONSTANTS.LOCK_TEMPLATE.TRY, {}, form)

export const unlockTemplate = form =>
  createAction(CONSTANTS.UNLOCK_TEMPLATE.TRY, {}, form)

export const deleteTemplate = (form, { templateKey }) =>
  createAction(CONSTANTS.DELETE_TEMPLATE.TRY, { templateKey }, form)

export const saveTemplate = form =>
  createAction(CONSTANTS.SAVE_TEMPLATE.TRY, {}, form)

export const exitTemplate = form =>
  createAction(CONSTANTS.EXIT_TEMPLATE, {}, form)

/* end Read/Lock/Unlock template actions */
