import React from 'react'
import { Icon } from '@material-ui/core'

const MiscChargeCalculatorHeader = () => (
  <div style={{ alignItems: 'center', display: 'flex', width: '100%' }}>
    <span style={{ marginRight: 15 }}>Amount</span>
    <i className="fa fa-calculator" style={{ color: '#444', fontSize: 14 }} />
  </div>
)

export default MiscChargeCalculatorHeader
