import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import { is, makeRowDataUnique } from 'utils'
import { getColumnDescription, sortData } from '../utils'
import { onCellValueChanged } from '../actions'

let _isMounted = false

const LineItemsGrid = props => {
  useEffect(() => {
    _isMounted = true

    return () => {
      _isMounted = false
    }
  }, [])

  const { form, hasHeaders = false, lineItems, sortOrder } = props
  const dispatch = useDispatch()

  function onLineItemDataChanged(params) {
    if (
      ((params.newValue || params.newValue === 0) &&
        Number(params.newValue) === Number(params.oldValue)) ||
      params.newValue === params.oldValue
    ) {
      return
    }

    const field = params?.colDef?.field
    const { newValue } = params
    const rowIndex = params?.node?.rowIndex

    // console.log('onLineItemDataChanged', field, newValue, rowIndex)
    if (field && is.number(rowIndex)) {
      dispatch(
        onCellValueChanged(form, {
          field,
          value: newValue,
          rowIndex,
          hasHeaders
        })
      )
    }
  }

  let rowData = lineItems
  const columnDefs =
    lineItems?.[0] && Object?.keys(lineItems[0])?.length
      ? sortData(
          Object.keys(lineItems[0]).reduce((acc, next) => {
            acc = acc.concat({
              headerName: getColumnDescription(hasHeaders, lineItems, next),
              field: next,
              colId: next,
              minWidth: 200,
              editable: true,
              sortable: false,
              suppressMovable: true,
              onCellValueChanged: onLineItemDataChanged
            })
            return acc
          }, []),
          sortOrder
        )
      : []

  if (hasHeaders && Array.isArray(rowData) && rowData?.length) {
    rowData = rowData.slice(1, rowData.length)
  }

  const onCellFocused = params => {
    const { api, rowIndex } = params
    const colId = params?.column?.colId
    // console.log('onCellFocused', colId, rowIndex)
    if (api && colId && is.number(rowIndex) && _isMounted) {
      setTimeout(() => {
        api.startEditingCell({
          rowIndex,
          colKey: colId
        })
      }, 0)
    }
  }

  return (
    <Grid
      height={400}
      columnDefs={columnDefs}
      rowData={makeRowDataUnique(rowData, 'rowId')}
      getRowNodeId={data => data.rowId}
      singleClickEdit
      onCellFocused={onCellFocused}
    />
  )
}

export default LineItemsGrid
