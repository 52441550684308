import React, { useState } from 'react'
import { InputAdornment, IconButton, Icon, TextField } from '@material-ui/core'
import { errorRed } from 'theme/colors'
import ActionButtons from './components/ActionButtons'

import '../../../styles/modal-flex-button-styles.scss'

const CustomAssemblyDescriptionModal = props => {
  const {
    data: { form, lineNumber, description },
    id
  } = props

  const [assemblyDescription, setDescription] = useState(description)

  console.log('CustomAssemblyDescriptionModal', props)
  const onChange = e => {
    const { value } = e.target
    setDescription(value)
  }

  const clearDescription = e => setDescription('')

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div className="sales-order-entry-modal-primary-interface-wrapper">
        <div className="multiline-textarea-wrapper" atyle={{ width: '100%' }}>
          <span
            style={{
              fontSize: '0.875em',
              margin: '0 0 5px 3px',
              display: 'inline-block'
            }}
          >
            Modify or Accept Product Description
          </span>
          <TextField
            value={assemblyDescription}
            onChange={onChange}
            multiline
            variant="outlined"
            rows={4}
            fullWidth
            InputProps={
              !assemblyDescription
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton style={{ padding: 5 }}>
                          <Icon style={{ color: errorRed, fontSize: 13 }}>
                            error_outline
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                : null
            }
          />
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        lineNumber={lineNumber}
        description={assemblyDescription}
        onClickClearButton={clearDescription}
      />
    </div>
  )
}

export default CustomAssemblyDescriptionModal
