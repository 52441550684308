import memoize from 'memoize-one'
import { fromJS, plainDeepEqual, setIn, toCamelCase } from 'utils'

export const getGroupNames = selectedTab => {
  if (selectedTab === 'order') {
    return ['header', 'detail']
  }

  if (selectedTab === 'invoicing') {
    return ['final']
  }

  return [selectedTab]
}

export const getTabIds = (groupNames = []) => {
  if (groupNames.includes('order')) {
    return ['header', 'detail']
  }

  if (groupNames.includes('invoicing')) {
    return ['final']
  }

  return groupNames
}

export const mapGetRecordResponse = ({
  response,
  tabNames = [],
  formState,
  groupNames = []
}) => {
  let res = response
  let tabIds = tabNames
  tabIds = getTabIds(groupNames)

  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      acc[next.toLowerCase()] =
        next === 'activities' && response[next] === null
          ? { activities: [] }
          : response[next]
      return acc
    }, {})

    tabIds.forEach(tab => {
      const t = toCamelCase(tab).toLowerCase()
      const selectedTabData = tempResponse.record
        ? tempResponse.record[t]
        : tempResponse[t]
      if (tempResponse.record) {
        delete tempResponse.record[t]
      }

      const data = {}
      for (const prop in selectedTabData) {
        data[prop] =
          prop === 'lineItems' &&
          selectedTabData[prop] &&
          Array.isArray(selectedTabData[prop])
            ? selectedTabData[prop].reduce((a, n) => {
                a = a.concat({
                  ...n,
                  rowId: n.lineNumber.toString()
                })
                return a
              }, [])
            : selectedTabData[prop]
      }
      res[t] = data

      if (selectedTabData) {
        res = {
          ...res,
          ...data
        }
      }
    })
  }

  return res
}

export const getScreenOpeningParams = (dataId, screen) => {
  if (screen === 'customerLedger') {
    return {
      image: 'accounting_accounts_receivable_customer_ledger_16.png',
      name: 'customerLedger',
      title: 'Customer Ledger',
      activeKey: 'ledger',
      route: 'customermaster',
      groupNames: ['summary', 'invoices'],
      dataId
    }
  }

  if (screen === 'salesOrder') {
    return {
      dataId,
      description: 'Sales Order',
      image: 'sales_order_entry_32.png',
      imageFileName16: 'sales_order_entry_16.png',
      name: 'salesOrder',
      newInstance: true,
      read: true,
      route: 'salesorder',
      title: 'Sales Order'
    }
  }

  if (screen === 'shipVia') {
    return {
      dataId,
      name: 'shipViaMaster',
      title: 'Ship-via Master'
    }
  }

  if (screen === 'branch') {
    return {
      dataId,
      name: 'branchMaster',
      title: 'Branch Master'
    }
  }

  return {}
}

export const getGridHeight = memoize((rowData = [], additionalHeight = 0) => {
  const calculatedHeight =
    rowData && Array.isArray(rowData)
      ? rowData.length * 29 + (32 + additionalHeight)
      : 150

  if (calculatedHeight < 150) {
    return 150
  }

  return calculatedHeight
}, plainDeepEqual)

export const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}

export const handleSalesmanCommissionData = (
  state,
  { payload: { commissionIgnoreMinimums, lineItems } }
) => {
  let result = state

  if (lineItems && Array.isArray(lineItems)) {
    result = setIn(
      result,
      'values.changeInvoice.salesmenCommissions',
      fromJS(lineItems)
    )
  }

  result = setIn(
    result,
    'fields.changeInvoice.commissionIgnoreMinimums.value',
    fromJS(commissionIgnoreMinimums)
  )
  result = setIn(
    result,
    'values.changeInvoice.commissionIgnoreMinimums',
    fromJS(commissionIgnoreMinimums)
  )
  return result
}
