import React from 'react'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

export const WriterTooltipCell = props => (<GridLinkCell {...props} />)

export default TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'systemUsers',
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(WriterTooltipCell)
