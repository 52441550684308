import React from 'react'
import { getIn, emptyList } from 'utils'
import { withContext } from 'ddiForm'
import { changeItemGroups } from 'pages/SalesOrder/actions'

import AvailableProducts from '../AvailableProducts'
import ItemGroups from '../ItemGroups'
import ProductsInGroupGridMobile from './ProductsInGroupGridMobile'
import MoveProductButtonsMobile from './MoveProductButtonsMobile'

import { getGridHeight } from './utils'

export const ItemGroupsWrapperMobile = ({ availableLineItemsList, data }) => {
  const availableLineItems = availableLineItemsList?.toJS
    ? availableLineItemsList.toJS()
    : []

  function addProductsToGroup(params) {
    const { form, dispatch } = data
    dispatch(
      changeItemGroups.try(form, {
        type: 'add',
        lineNumbers: params.lineNumbers
      })
    )
  }

  function addAllToGroup() {
    const { form, dispatch } = data
    dispatch(
      changeItemGroups.try(form, {
        type: 'add',
        lineNumbers: 'all'
      })
    )
  }

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <AvailableProducts
          dispatch={data.dispatch}
          form={data.form}
          height={getGridHeight(availableLineItems)}
          isMobile
        />
        <MoveProductButtonsMobile
          addProductsToGroup={addProductsToGroup}
          addAllToGroup={addAllToGroup}
        />
      </div>

      <div style={{ marginBottom: 30, paddingBottom: 30 }}>
        <ItemGroups form={data.form} isMobile />
      </div>

      <div style={{ marginBottom: 30 }}>
        <ProductsInGroupGridMobile form={data.form} isMobile />
      </div>
    </div>
  )
}

/* fixed 9/23/2020 SVE */
export default withContext({
  availableLineItemsList: form =>
    getIn(form, 'fields.groups.availableLineItems.rowData') || emptyList
})(ItemGroupsWrapperMobile)
