import React, { createRef } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Grid, Tooltip, Typography } from '@material-ui/core'
import DDICardWrapper from 'components/DDICardWrapper'
import { getIn } from 'utils'
import { DDITextField } from 'ddiForm/wrapped'
import { withContext } from 'ddiForm'
import TooltipField from 'hoc/TooltipField'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'

import LabeledField from './LabeledField'

const WarrantyClaimWithTooltipField = TooltipField({
  anchorToGridCellText: false,
  indexSearchType: 'warrantyClaim',
  keyToActionMap: {},
  position: 'right-end',
  recordNameKey: 'fetchClaimTooltipId',
  sensitivity: 100,
  type: 'warrantyClaim'
})(DDITextField)

const TagEntry = ({
  statusValue,
  statusTooltip,
  claimId,
  dataId,
  hasRecord,
  form,
  isEditing,
  id = ''
}) => {
  const inputRef = createRef()
  const dispatch = useDispatch()
  const inModal = Boolean(id)

  const onChangeCb = debounce(e => {
    if (hasRecord && dataId && dataId !== e?.target?.value) {
      dispatch(resetMasterFields(form, ['dataId']))
    }
  }, 300)

  return (
    <DDICardWrapper contentStyle={{ padding: 20 }}>
      <Grid container spacing={1} style={{ width: '100%' }}>
        <LabeledField labelWidth={1} fieldWidth={3} label="Tag">
          <DDITextField
            propertyName="dataId"
            preventAutoDisable
            disabled={isEditing || inModal}
            inputRef={inputRef}
            onChangeCb={onChangeCb}
            disableDoubleClickUnlock={inModal}
          />
        </LabeledField>
        <LabeledField labelWidth={2} fieldWidth={2} label="Claim">
          <WarrantyClaimWithTooltipField
            propertyName="claimId"
            fetchClaimTooltipId={claimId ? dataId : null}
            disabled
          />
        </LabeledField>

        <LabeledField labelWidth={2} fieldWidth={2} label="Status">
          <Tooltip title={statusTooltip}>
            <Typography style={{ lineHeight: '1.8' }}>{statusValue}</Typography>
          </Tooltip>
        </LabeledField>
      </Grid>
    </DDICardWrapper>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  claimId: form => getIn(form, 'values.claimId') || '',
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  statusValue: form => getIn(form, 'values.status.value') || '',
  statusTooltip: form => getIn(form, 'values.status.tooltip') || ''
})(TagEntry)
