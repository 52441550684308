import React, {
  Component,
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react'

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

const CustomEditorComponent = forwardRef((props, ref) => {
  const refDatePicker = useRef()
  console.log(refDatePicker)
  const [date, setDate] = useState(
    props.value ? moment(props.value, 'DD MM YYYY').toDate() : null
  )
  const [editing, setEditing] = useState(true)
  const [open, setOpen] = useState(true)
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing()
    }
  }, [editing])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return date ? moment(date).format('MM/DD/YYYY') : null
      }
    }
  })

  const onChange = selectedDate => {
    setDate(selectedDate)
    setEditing(false)
    setOpen(false)
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          ref={refDatePicker}
          portalId="root"
          popperClassName="ag-custom-component-popup"
          // selected={date}
          clearable
          value={date}
          format="MM/DD/YYYY"
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
})

export default CustomEditorComponent
