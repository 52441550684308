import React from 'react'
import PropTypes from 'prop-types'
import { Label, Field, withContext } from 'ddiForm'
import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'
import { CircularProgress } from '@material-ui/core'
import { getRgb, getValue } from 'utils'

import PreNewTextField from './PreNewTextField'

const styles = {
  label: {
    fontSize: '1rem',
    fontWeight: 500,
    minWidth: 60,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    margin: '0 5px 0 0'
  }
}

const SearchArea = ({
  dataId,
  description,
  hasRecord,
  isEditing,
  preNewMode,
  status
}) => (
  <div
    style={{
      alignItems: 'center',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      minHeight: 45,
      padding: 10
    }}
  >
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flex: 2
      }}
    >
      <label htmlFor="dataId" style={styles.label}>
        Ship To:
      </label>
      {dataId ? (
        <div>
          {preNewMode ? (
            <Field
              preventAutoDisable
              propertyName="dataId"
              component={PreNewTextField}
              wrapperStyle={{ width: 175 }}
            />
          ) : (
            <DDIIndexSearch
              propertyName="dataId"
              searchType="shipTo"
              disableLastSearchButton
              componentWrapperStyleOverrides={{ marginTop: 0 }}
              fullWidth
              metaKey="Ship To Id"
              leader
              placeholder={dataId}
              disabled
              wrapperStyle={{ width: 175 }}
            />
          )}
        </div>
      ) : (
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <CircularProgress size={20} thickness={7} />
          <span
            style={{
              display: 'inline-block',
              fontSize: 12,
              fontStyle: 'italic',
              marginLeft: 5
            }}
          >
            Retreiving next available ID...
          </span>
        </div>
      )}
      {hasRecord ? (
        <div style={{ marginLeft: 15, marginRight: 15 }}>
          <Label
            propertyName="description"
            labelStyle={{ width: '100%' }}
            value={description}
          />
        </div>
      ) : null}
    </div>

    {status?.value ? (
      <div
        style={{
          background: getRgb(status.color),
          borderRadius: 5,
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 27,
          fontSize: 12,
          flex: '0.3 2',
          width: '100%'
        }}
      >
        {status.value}
      </div>
    ) : null}
  </div>
)

SearchArea.propTypes = {
  dataId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasRecord: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  preNewMode: PropTypes.bool.isRequired
}

// export default withContext({
//   dataId: form => (form.values && form.values.dataId ? form.values.dataId : ''),
//   description: form =>
//     form.fields && form.fields.description && form.fields.description.value
//       ? form.fields.description.value
//       : '',
//   preNewMode: form =>
//     form.values && form.values.preNewMode ? form.values.preNewMode : false,
//   status: form => form?.values?.status || null
// })(SearchArea)
export default withContext({
  dataId: form => getValue(form, 'dataId', ''),
  description: form => getValue(form, 'description', ''),
  preNewMode: form => getValue(form, 'preNewMode', false),
  status: form => getValue(form, 'status', null)
})(SearchArea)
