import {
  call,
  delay,
  fork,
  put,
  select,
  take,
  cancel
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { SET_FIELD } from 'ddiForm/constants'
import { setField } from 'ddiForm/actions'
import { getIn, noop, formatNumber } from 'utils'
import { api } from 'services'
import { getGroupNames } from 'pages/SalesOrder/utils'
import { getErrorMessages } from 'pages/ProductMaster/utils'
import { addModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CONFIRMED, CANCELED } from 'modals/constants'
import { show } from 'snackbar/actions'
import shortid from 'shortid'

import OverpaymentModal from 'pages/SalesOrder/tabs/Invoicing/components/PaymentMethods/components/Payments/components/OverpaymentModal'
import SalesOrderNumberEntryModal from 'pages/SalesOrder/tabs/Order/components/SalesOrderNumberEntryModal'
import DepositInvoiceEntryModal from 'pages/SalesOrder/tabs/Invoicing/components/PaymentMethods/components/Payments/components/DepositInvoiceEntryModal'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import VoidCayanPaymentModal from 'pages/SalesOrder/components/VoidCayanPaymentModal'
import PaymentAlert from 'pages/SalesOrder/tabs/Invoicing/components/PaymentMethods/components/Payments/components/CreditCardPayment/components/PaymentAlert'
import { propertyChangeProcess } from './searchAreaSagas'

import * as actions from '../actions'
import * as CONSTANTS from '../constants'

export function* initializePaymentsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.INITIALIZE_PAYMENT.TRY)
    const {
      meta: { form },
      payload: { option, cb, parentModalCb },
      payload
    } = action

    const validOptions = [
      'cash',
      'check',
      'offline',
      'keyedEntry',
      'vaultPayment',
      'processorPayment'
    ]

    // debugger
    if (form === formListener && option) {
      if (option === 'bill') {
        yield fork(billToCustomerProcess, form, payload)
      } else if (validOptions.includes(option)) {
        yield fork(standardPaymentProcess, form, payload, cb, parentModalCb)
      }
    }
  }
}

let taskId

export function* standardPaymentProcess(
  form,
  payload,
  cb = noop,
  parentModalCb = noop
) {
  //
  const {
    amountTendered,
    overpaymentResponse = null,
    option,
    checkNumber = '',
    cardType = '',
    dataId = null,
    generateId = false,
    modalOpen = false,
    token = '',
    addToVault = false,
    paymentGuid = null
  } = payload
  //

  const checkNumberIsBlank = !checkNumber

  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  // const balanceLeft = getIn(formState, 'values.balanceLeft')
  const groupNames = getGroupNames(formState)

  const contactId = getIn(formState, 'fields.checkoutContact.value')
  const orderedById = getIn(formState, 'fields.orderedById.value')
  // const orderId = getIn(formState, 'fields.dataId.value')

  /*
  note that dataId in this case is the desired orderId that
  can be created (or generated) during the offline CC payment proc
  */

  const properties = {
    cash: {
      cashPayment: {
        amountTendered,
        overpaymentResponse
      }
    },
    check: {
      checkPayment: {
        amountTendered,
        checkNumber,
        overpaymentResponse,
        checkNumberIsBlank
      }
    },
    offline: {
      creditCardPayment: {
        cardType,
        amountTendered,
        dataId,
        generateId,
        overpaymentResponse
      }
    },
    keyedEntry: {
      creditCardPayment: {
        addToVault,
        cardType: 'Process',
        generateId,
        dataId,
        token,
        amountTendered,
        forceDuplicate: true,
        contactId: addToVault ? contactId || orderedById : null,
        transactionType: 'singleusetokenpayment',
        overpaymentResponse
      }
    },
    vaultPayment: {
      creditCardPayment: {
        dataId,
        generateId,
        cardType: 'Process',
        forceDuplicate: true,
        paymentGuid,
        amountTendered,
        transactionType: 'boardedtokenpayment',
        overpaymentResponse
      }
    },
    processorPayment: {
      creditCardPayment: {
        dataId,
        generateId,
        cardType: 'Process',
        forceDuplicate: true,
        amountTendered,
        transactionType: 'readerpayment',
        contactId: addToVault ? contactId || orderedById : null,
        overpaymentResponse
      }
    }
  }

  if (!properties[option]) {
    return
  }

  yield put(actions.initializePayment.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    groupNames,
    requestReply: option === 'processorPayment',
    properties: properties[option]
  })

  // const creditCardPayments = ['keyedEntry', 'offline', 'vaultPayment', 'processorPayment']

  yield fork(
    handlePaymentProcessResponse,
    form,
    response,
    error,
    properties,
    payload,
    cb,
    parentModalCb,
    guid
  )
}

export function* cancelCardReaderPaymentProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const { response, error } = yield call(api.propertyChange, {
    guid,
    action: 'canceltransaction'
  })

  if (response) {
    yield put(actions.cancelCardReaderPayment.success(response, form))
    taskId = null
  } else {
    yield put(actions.cancelCardReaderPayment.failure(error, form))
  }
}

export function* cancelCardReaderPaymentListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_CARD_READER_PAYMENT.TRY)

    if (form === formListener) {
      yield fork(cancelCardReaderPaymentProcess, form)
    }
  }
}

export function* handlePaymentProcessResponse(
  form,
  response,
  error,
  properties,
  payload,
  cb,
  parentModalCb,
  guid
) {
  const formState = yield select(getFormSelector(form))
  const {
    amountTendered,
    overpaymentResponse = null,
    option,
    checkNumber = '',
    cardType = '',
    dataId = null,
    generateId = false,
    modalOpen = false,
    token = '',
    addToVault = false,
    paymentGuid = null
  } = payload

  const creditCardPayments = [
    'keyedEntry',
    'offline',
    'vaultPayment',
    'processorPayment'
  ]
  const checkNumberIsBlank = !checkNumber

  if (response) {
    if (response.promptForId && properties[option]) {
      if (!modalOpen && creditCardPayments.includes(option)) {
        yield fork(
          launchCreditCardPaymentOrderIdModal,
          form,
          properties[option],
          option,
          parentModalCb
        )
      }
    } else if (response.taskId) {
      /* this is for credit card processor relay ping */

      if (
        option === 'processorPayment' &&
        taskId &&
        taskId !== response.taskId
      ) {
        /* this means that promptForId or signature is in play and the reader is not ready */
        yield put(
          actions.cardProcessorInitialized(form, { initialized: false })
        )
      }

      taskId = response.taskId

      const task = yield call(api.propertyChange, {
        guid,
        taskId: response.taskId
      })

      if (task.response) {
        yield delay(2000)
        yield fork(
          handlePaymentProcessResponse,
          form,
          task.response,
          null,
          properties,
          payload,
          cb,
          parentModalCb,
          guid
        )
        if (
          option === 'processorPayment' &&
          !getIn(formState, 'cardProcessorInitialized') &&
          !task.response.promptForId
        ) {
          yield put(
            actions.cardProcessorInitialized(form, { initialized: true })
          )
        }
      } else {
        yield fork(
          handlePaymentProcessResponse,
          form,
          null,
          task.error,
          properties,
          payload,
          cb,
          parentModalCb,
          guid
        )
      }
    } else {
      //
      const successResponse =
        option === 'keyedEntry'
          ? {
              ...response,
              paymentTransactionToken: { token, success: true }
            }
          : response

      yield put(actions.initializePayment.success(successResponse, form))

      /*
        sometimes need to await completion of an API proc
        before taking down an intermediary modal (mainly offline payment)
      */
      if (cb && typeof cb === 'function') {
        cb()
      }

      /*
        this scenario comes up if the user is low-level,
        there is an overpayment AND the orderType selected
        requires manual Sales Order number entry -- SVE 1/20/2020
      */
      if (parentModalCb && typeof parentModalCb === 'function') {
        parentModalCb()
      }

      if (creditCardPayments.includes(option)) {
        /*
          if the user has entered a manual dataId as part of the
          offline credit card payment process, clear it from Redux.
        */
        yield put(actions.clearManualDataId(form, { isCheckout: true }))
      }

      const paymentSuccessMessage =
        option && creditCardPayments.includes(option)
          ? `Your payment in the amount of ${formatNumber(
              amountTendered
            )} was approved`
          : `Thank you for your payment of ${formatNumber(amountTendered)}`

      yield fork(showPaymentResponseMessage, paymentSuccessMessage, 'success')

      if (option === 'processorPayment') {
        yield put(
          actions.storeUIFeatureState(form, {
            feature: 'ccPaymentOption',
            featureState: 'keyed'
          })
        )
      }

      taskId = null
    }
  } else {
    const { status, validationErrors, message } = error

    if (status === 496) {
      // open overpayment modal
      const overpayments = {
        cash: {
          ...error,
          amountTendered,
          option
        },
        check: {
          ...error,
          amountTendered,
          option,
          checkNumber,
          checkNumberIsBlank
        },
        offline: {
          ...error,
          amountTendered,
          cardType,
          option
        },
        keyedEntry: {
          ...error,
          addToVault,
          amountTendered,
          option,
          token
        },
        vaultPayment: {
          ...error,
          amountTendered,
          option,
          paymentGuid
        },
        processorPayment: {
          ...error,
          addToVault,
          amountTendered,
          option
        }
      }

      if (overpayments[option] && error.id === 'AddPayment') {
        yield fork(
          openOverpaymentModalProcess,
          form,
          overpayments[option],
          option
        )
      } else if (message) {
        yield fork(warningModal, message, 'Attention!')

        /* reset the form buttons to allow the user to try again */
        const baseFailureMessages = [
          'Cannot process null payment',
          'Cannot process payment because no account found'
        ]
        if (baseFailureMessages.includes(message)) {
          yield put(actions.initializePayment.failure({}, form))
        }
      } else if (creditCardPayments.includes(option)) {
        /*
          496 for problems with the credit card terminal proc
          (this is rare, seems to be triggered ONLY by a timeout) -- SVE 2/13/20
        */

        const cardErrorMessage =
          option === 'processorPayment'
            ? 'There was a problem with the card processor. Please try again'
            : 'There was a problem processing your request. Please check your info and try again'

        yield fork(showPaymentResponseMessage, cardErrorMessage, 'error')
      }
    } else if (status === 498 && validationErrors) {
      const messages = getErrorMessages(validationErrors)
      yield fork(warningModal, messages, 'Attention!')
      yield put(actions.initializePayment.failure({}, form))
    } else if (error && error !== 'canceled') {
      yield fork(
        showPaymentResponseMessage,
        'There was a problem processing your request. Please check your info and try again',
        'error'
      )

      if (option === 'processorPayment') {
        /*
          cleanup and reset the interface, closing any modals
          if a processor payment fails for any reason -- SVE 2/13/20
        */
        if (cb && typeof cb === 'function') {
          cb()
        }

        if (parentModalCb && typeof parentModalCb === 'function') {
          parentModalCb()
        }

        yield put(
          actions.storeUIFeatureState(form, {
            feature: 'ccPaymentOption',
            featureState: 'keyed'
          })
        )
      }

      yield put(actions.initializePayment.failure({}, form))

      taskId = null
    }
  }
}

export function* launchCreditCardPaymentOrderIdModal(
  form,
  properties,
  option,
  parentModalCb
) {
  /*
    Note: SalesOrderNumberEntryModal is also used in the 'Save' process,
    and its working, so don't mess with it -- SVE 1/16/2020 :)
  */

  const modalOpts = {
    component: SalesOrderNumberEntryModal,
    options: {
      title: 'Choose ID',
      width: 400,
      data: {
        form,
        option,
        actions: [
          {
            primary: true,
            title: 'OK',
            async clickEvent(args, fs, cb) {
              const { fields } = fs
              const dataId =
                fields && fields.manualDataId && fields.manualDataId.value
                  ? fields.manualDataId.value
                  : ''

              if (dataId) {
                try {
                  await this.props.dispatch(
                    actions.initializePayment.try(form, {
                      ...properties.creditCardPayment,
                      option,
                      isManualIdEntry: true,
                      dataId,
                      cb,
                      modalOpen: true,
                      parentModalCb
                    })
                  )
                } catch (e) {
                  console.log(e)
                } finally {
                  console.log('offline CC by dataId done')
                }
              }
            },
            disabled: formState => {
              const { fields, manualIdPosting } = formState
              const dataId =
                fields && fields.manualDataId && fields.manualDataId.value
                  ? fields.manualDataId.value
                  : ''

              if (manualIdPosting) {
                return true
              }

              // if (dataId) {
              //   return false
              // }

              return false
            }
          },
          {
            primary: true,
            title: 'System ID',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.initializePayment.try(form, {
                    ...properties.creditCardPayment,
                    option,
                    isManualIdEntry: true,
                    generateId: true,
                    cb,
                    modalOpen: true,
                    parentModalCb
                  })
                )
              } catch (e) {
                console.log(e)
              } finally {
                console.log('systemId done')
              }
            },
            disabled: formState => {
              const { manualIdPosting } = formState

              if (manualIdPosting) {
                return true
              }

              return false
            }
          },
          {
            primary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              const { overpaymentResponsePosting } = fs
              const action = overpaymentResponsePosting
                ? actions.clearManualDataId(form, { isCheckout: true })
                : actions.initializePayment.failure({}, form)

              try {
                await this.props.dispatch(action)
              } catch (e) {
                console.log(e)
              } finally {
                cb()
              }
            },
            disabled: formState => {
              const { manualIdPosting } = formState

              if (manualIdPosting) {
                return true
              }

              return false
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.id
}

export function* billToCustomerProcess(form) {
  const formState = yield select(getFormSelector(form))
  // const dataId = getIn(formState, 'values.dataId') || null
  // const customerId = getIn(formState, 'values.customerId') || null
  const dataId =
    getIn(formState, 'fields.dataId.value') ||
    getIn(formState, 'values.dataId') ||
    null
  const customerId =
    getIn(formState, 'fields.customerId.value') ||
    getIn(formState, 'values.customerId') ||
    null

  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  yield put(actions.initializePayment.request(form))

  const { response, error } = yield call(api.propertyChange, {
    customerId,
    dataId,
    guid,
    groupNames
  })

  if (response) {
    yield put(actions.initializePayment.success(response, form))
  } else {
    yield put(actions.initializePayment.failure(error, form))
  }
}

export function* voidPaymentListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.VOID_PAYMENT.TRY)
    const {
      meta: { form },
      payload: { index, promptForVoid = false }
    } = action

    if (form === formListener) {
      if (promptForVoid) {
        yield fork(voidCayanPaymentProcess, form, index)
      } else {
        yield fork(voidPaymentProcess, form, index)
      }
    }
  }
}

export function* voidPaymentProcess(form, index) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  yield put(actions.voidPayment.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    groupNames,
    properties: {
      voidPayment: index
    }
  })

  if (response) {
    yield put(actions.voidPayment.success(response, form))
  } else {
    yield put(actions.voidPayment.failure(error, form))
  }

  return null
}

export function* voidCayanPaymentProcess(form, paymentIndex) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  yield put(actions.voidPayment.request(form))

  const action = yield call(api.readSalesOrder, {
    guid,
    action: 'voidpayment',
    paymentIndex
  })

  if (action?.response) {
    yield call(openVoidCayanPaymentModal, form, action.response)
    const act = yield take([CONFIRMED, CANCELED])

    if (act.type === CONFIRMED) {
      const { response, error } = yield call(api.propertyChange, {
        guid,
        groupNames,
        properties: {
          voidpayment: paymentIndex
        }
      })

      if (response) {
        yield put(actions.voidPayment.success(response, form))
      } else {
        yield put(actions.voidPayment.failure(error, form))
      }
    } else {
      yield put(actions.voidPayment.failure({}, form))
    }
  } else {
    const { message, status } = action.error

    if (status === 496) {
      yield call(warningModal, message, 'Attention!')
    }

    yield put(actions.voidPayment.failure({}, form))
  }

  return null
}

export function* openVoidCayanPaymentModal(form, response) {
  const modalOpts = {
    component: VoidCayanPaymentModal,
    options: {
      title: 'Void',
      width: 500,
      maxHeight: '100%',
      data: {
        form,
        ...response,
        actions: [
          {
            primary: true,
            title: 'Process'
          },
          {
            secondary: true,
            title: 'Close',
            cancel: true
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.id
}

export function* openOverpaymentModalProcess(form, payload, type = 'cash') {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const { title } = payload

  const modalOpts = {
    component: OverpaymentModal,
    options: {
      width: 450,
      title,
      data: {
        ...payload,
        form,
        guid,
        actions: [
          {
            primary: true,
            title: 'Change',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.initializePayment.try(form, {
                    ...payload,
                    overpaymentResponse: 'c',
                    parentModalCb: cb,
                    cb
                  })
                )
              } catch (e) {
                console.log(e)
              } finally {
                console.log('made some change ha')
              }
            },
            disabled: fs => {
              if (fs.overpaymentResponsePosting) {
                return true
              }

              return false
            }
          },
          {
            primary: true,
            title: 'Deposit',
            async clickEvent(args, fs, cb) {
              try {
                // cb()
                await this.props.dispatch(
                  actions.initializePayment.try(form, {
                    ...payload,
                    overpaymentResponse: 'd',
                    parentModalCb: cb,
                    cb
                  })
                )
              } catch (e) {
                console.log(e)
              } finally {
                console.log('Deposit done')
              }
            },
            disabled: fs => {
              if (fs.overpaymentResponsePosting) {
                return true
              }

              return false
            }
          },
          {
            secondary: true,
            title: 'Cancel',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.initializePayment.failure({}, form)
                )
              } catch (e) {
                console.log(e)
              } finally {
                cb()
              }
            },
            disabled: fs => {
              if (fs.overpaymentResponsePosting) {
                return true
              }

              return false
            }
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.id
}

export function* launchDepositInvoiceEntryModalProcess(form) {
  const formState = yield select(getFormSelector(form))
  const openTotal = getIn(formState, 'values.openTotal')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const guid = getIn(formState, 'guid')

  const modalOpts = {
    component: DepositInvoiceEntryModal,
    options: {
      width: 600,
      maxHeight: 600,
      title: 'Sales Order Deposit Invoice Entry',
      data: {
        openTotal,
        isMobile,
        form,
        actions: [
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              const action = fs.isEditing
                ? 'saveSalesOrder'
                : 'depositInvoiceEntry'

              if (this.state.amount && this.state.date && this.state.message) {
                this.props.dispatch(
                  actions[action].try(form, {
                    depositData: {
                      createdepositamount: this.state.amount,
                      createdepositdate: this.state.date,
                      createdepositmessage: this.state.message
                    },
                    cb
                  })
                )
              } else {
                this.setState({
                  errorMessage:
                    'There are problems with your submission. Please make adjustments and try again.'
                })
              }
              // if (cb && typeof cb === 'function') {
              //   cb()
              // }
            },
            disabled: fs => fs.isSaving || fs.isPosting || false
          },
          {
            primary: true,
            title: 'Cancel',
            disabled: fs => fs.isSaving || fs.isPosting || false
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.id
}

export function* launchDepositInvoiceEntryModalListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.LAUNCH_DEPOSIT_INVOICE_ENTRY_MODAL)

    if (form === formListener) {
      yield fork(launchDepositInvoiceEntryModalProcess, form)
    }
  }
}

export function* depositInvoiceEntryProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = isMobile
    ? ['header', 'detail', 'final', 'shipments', 'signature']
    : ['header', 'detail', 'final', 'shipments']

  yield put(actions.depositInvoiceEntry.request(form))
  // debugger

  const { response, error } = yield call(api.propertyChange, {
    action: 'createdeposit',
    dataId,
    guid,
    properties: {
      ...payload.depositData
    },
    groupNames
  })

  if (response) {
    yield put(actions.depositInvoiceEntry.success(response, form))

    if (payload.cb && typeof payload.cb === 'function') {
      payload.cb()
    }

    if (response.createDepositPrintJob) {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response.createDepositPrintJob,
        form
      })
    }
  } else {
    if (
      error &&
      error.status &&
      error.status === 498 &&
      error.validationErrors
    ) {
      const messages = getErrorMessages(error.validationErrors)
      yield fork(warningModal, messages, 'Attention!')
    }

    yield put(actions.depositInvoiceEntry.failure(error, form))
  }
}

export function* depositInvoiceEntryListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.DEPOSIT_INVOICE_ENTRY.TRY)

    if (form === formListener) {
      yield fork(depositInvoiceEntryProcess, form, payload)
    }
  }
}

export function* createInvoiceListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CREATE_INVOICE.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(createInvoiceProcess, form)
    }
  }
}

export function* deleteInvoiceListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_INVOICE.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(deleteInvoiceProcess, form)
    }
  }
}

export function* createInvoiceProcess(form) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const shippableTotal = getIn(formState, 'values.shippableTotal') || 0

  if (!shippableTotal) {
    // confirm if they want to continue
    yield call(confirmationModal, 'Create 0 balance invoice?', 'Create Invoice')

    const { type } = yield take([CONFIRMED, CANCELED])

    if (type === CANCELED) return
  }

  if (isEditing) {
    if (!dataId) {
      yield call(
        confirmationModal,
        'This request will save your order and immediately create an invoice.  Are you sure?',
        'Warning'
      )

      const { type } = yield take([CONFIRMED, CANCELED])

      if (type === CANCELED) return
    }
    // call validate
    // call update
    // call propertychange

    yield put(actions.saveSalesOrder.try(form))

    const act = yield take([
      CONSTANTS.SAVE_SALES_ORDER.SUCCESS,
      CONSTANTS.SAVE_SALES_ORDER.FAILURE,
      CONSTANTS.VALIDATE_SALES_ORDER.FAILURE
    ])
    if (act.type !== CONSTANTS.SAVE_SALES_ORDER.SUCCESS) return
  }

  yield fork(invoicePropertyChange, form, 'createinvoice')
}

export function* deleteInvoiceProcess(form) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  yield call(
    confirmationModal,
    'Are you sure you want to remove this from billing?',
    'Delete Invoice'
  )

  const { type } = yield take([CONFIRMED, CANCELED])

  if (type === CANCELED) return

  if (isEditing) {
    yield put(actions.saveSalesOrder.try(form))
    const act = yield take([
      CONSTANTS.SAVE_SALES_ORDER.SUCCESS,
      CONSTANTS.SAVE_SALES_ORDER.FAILURE,
      CONSTANTS.VALIDATE_SALES_ORDER.FAILURE
    ])
    if (act.type !== CONSTANTS.SAVE_SALES_ORDER.SUCCESS) return
  }

  yield fork(invoicePropertyChange, form, 'deleteinvoice')
}

export function* invoicePropertyChange(form, type = 'createinvoice') {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId =
    getIn(formState, 'fields.dataId.value') ||
    getIn(formState, 'values.dataId') ||
    null
  const groupNames = getGroupNames(formState)

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.propertyChange, {
    action: type,
    dataId,
    groupNames,
    guid
  })

  if (response) {
    yield put(actions.onPropertyChange.success(response, form))
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
  return null
}

export function* showPaymentResponseMessage(message, type, timer = 10000) {
  if (message && type) {
    const messageId = shortid.generate()
    yield put(
      show({
        message: {
          message,
          type,
          timer,
          id: messageId,
          component: PaymentAlert(messageId)
        }
      })
    )
  }
}

export function* notifyCreditCardErrorListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: {
        message = 'There was a problem processing your request. Please check your info and try again'
      }
    } = yield take(CONSTANTS.NOTIFY_CREDIT_CARD_ERROR)

    if (form === formListener) {
      yield fork(showPaymentResponseMessage, message, 'error')
    }
  }
}

export function* billForBackorderListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.BILL_FOR_BACKORDER.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(billForBackorderProcess, form)
    }
  }
}

export function* billForBackorderProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)
  const dataId = getIn(formState, 'values.dataId') || null

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.propertyChange, {
    action: 'billforbackorder',
    dataId,
    guid,
    groupNames
  })

  if (response) {
    yield put(actions.onPropertyChange.success(response, form))
    if (response?.invoicePrintJob) {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response.invoicePrintJob,
        form
      })
    }
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }

  return null
}

export function* getVaultCardsProcess(form, contactId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.getVaultCards.request(form))

  const { response, error } = yield call(api.readSalesOrder, {
    guid,
    action: 'readvault',
    contactId
  })

  if (response) {
    yield put(actions.getVaultCards.success(response, form))
  } else {
    yield put(actions.getVaultCards.failure(error, form))
  }
}

export function* getVaultCardsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { contactId }
    } = yield take(CONSTANTS.GET_VAULT_CARDS.TRY)

    if (form === formListener && contactId) {
      yield fork(getVaultCardsProcess, form, contactId)
    }
  }
}

export function* removeVaultCardProcess(form, paymentGuid, cb) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const checkoutContact = getIn(formState, 'fields.checkoutContact.value')

  yield call(confirmationModal, 'Are you sure?', 'Remove Card')
  const { type } = yield take([CONFIRMED, CANCELED])
  if (type === CANCELED) {
    return
  }

  const { response, error } = yield call(api.propertyChange, {
    guid,
    action: 'removevaultedcard',
    properties: {
      contactId: checkoutContact,
      paymentGuid
    }
  })

  if (response) {
    yield put(actions.removeVaultCard.success(response, form))

    if (cb && typeof cb === 'function') {
      /* remove the selectedCard from component state */
      cb()
    }
  } else {
    yield put(actions.removeVaultCard.failure(error, form))
  }
}

export function* removeVaultCardListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { paymentGuid, cb }
    } = yield take(CONSTANTS.REMOVE_VAULT_CARD.TRY)

    if (form === formListener) {
      yield fork(removeVaultCardProcess, form, paymentGuid, cb)
    }
  }
}

export function* selectLinkedRefundListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SELECT_LINKED_REFUND.TRY)
    const {
      meta: { form },
      payload: { amountTendered }
    } = action

    if (form === formListener) {
      yield fork(selectLinkedRefundProcess, form, amountTendered)
    }
  }
}

export function* selectLinkedRefundProcess(form, amountTendered) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.selectLinkedRefund.request(form))

  const { response, error } = yield call(api.readSalesOrder, {
    guid,
    action: 'linkedrefund',
    amountTendered
  })

  if (response) {
    yield put(actions.selectLinkedRefund.success(response, form))
  } else {
    // const { status, validationErrors } = error

    // if (status === 498) {
    //   const messages = getErrorMessages(validationErrors)
    //   yield fork(warningModal, messages, 'Attention!')
    // }

    yield put(actions.selectLinkedRefund.failure(error, form))
  }
}

export function* linkedRefundListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LINKED_REFUND_PROCESS.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(linkedRefundProcess, form, payload)
    }
  }
}

export function* linkedRefundProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = getGroupNames(formState)

  const {
    generateId = false,
    amountTendered,
    overpaymentResponse = null
  } = payload

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(api.propertyChange, {
    guid,
    properties: {
      creditCardPayment: {
        cardType: 'Process',
        generateId,
        transactionType: 'refundpayment',
        paymentGuid: payload.guid,
        amountTendered,
        overpaymentResponse
      }
    },
    groupNames
  })

  if (response) {
    yield put(actions.onPropertyChange.success(response, form))
  } else {
    const { status, validationErrors, data } = error

    if (status === 498) {
      const messages = getErrorMessages(validationErrors)
      yield fork(warningModal, messages, 'Attention!')
    } else if (status === 499 && data) {
      yield put(actions.linkedRefundResponse(form, data))
    }

    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* changeCheckoutContactProcess(
  form,
  propertyName,
  value,
  results = {}
) {
  const formState = yield select(getFormSelector(form))
  const saveCCInfo = getIn(formState, 'values.saveCCInfo') || false
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const paymentOption = getIn(formState, 'ui.paymentOption') // cc
  const ccPaymentOption = getIn(formState, 'ui.ccPaymentOption') // vault

  if (value) {
    if (results && results.description) {
      yield put(
        setField(form, 'checkoutContactDescription', results.description)
      )
    }

    // debugger
    if (
      selectedPrimaryTab === 'checkout' &&
      paymentOption === 'cc' &&
      ccPaymentOption === 'vault'
    ) {
      if (propertyName === 'checkoutContact') {
        // debugger
        yield put(
          actions.getVaultCards.try(form, {
            contactId: value
          })
        )
      }
    }
  } else {
    yield put(setField(form, 'checkoutContactDescription', ''))

    if (
      selectedPrimaryTab === 'checkout' &&
      paymentOption === 'cc' &&
      ccPaymentOption === 'vault'
    ) {
      yield put(actions.clearPaymentTransactionData(form))
    }
  }
}

export function* resetVaultPaymentCardsAfterPropertyChange(form, payload) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const paymentOption = getIn(formState, 'ui.paymentOption') // cc

  if (selectedPrimaryTab === 'checkout' && paymentOption === 'cc') {
    if (
      payload.record &&
      payload.record.orderedById &&
      payload.record.orderedByName
    ) {
      yield put(
        actions.setDefaultPaymentContact(form, {
          value: payload.record.orderedById,
          description: payload.record.orderedByName
        })
      )
    } else {
      yield put(
        actions.setDefaultPaymentContact(form, {
          value: '',
          description: ''
        })
      )
    }
  }
}

export function* changeCheckoutContactListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value, results },
      payload,
      type
    } = yield take([
      SET_FIELD,
      CONSTANTS.SET_DEFAULT_PAYMENT_CONTACT,
      CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS
    ])

    if (form === formListener) {
      if (
        (propertyName === 'checkoutContact' && type === SET_FIELD) ||
        type === CONSTANTS.SET_DEFAULT_PAYMENT_CONTACT
      ) {
        const prop =
          type === CONSTANTS.SET_DEFAULT_PAYMENT_CONTACT
            ? 'checkoutContact'
            : propertyName
        yield fork(changeCheckoutContactProcess, form, prop, value, results)
      }

      if (type === CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS) {
        if (
          payload.propertyChanged === 'orderedById' ||
          payload.propertyChanged === 'customerId'
        ) {
          yield fork(resetVaultPaymentCardsAfterPropertyChange, form, payload)
        }
      }
    }
  }
}

export function* readCardTerminalStatusProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.readCardTerminalStatus.request(form))

  const { response, error } = yield call(api.readSalesOrder, {
    guid,
    action: 'terminalstatus'
  })

  if (response) {
    yield put(actions.readCardTerminalStatus.success(response, form))
  } else {
    yield put(actions.readCardTerminalStatus.failure(error, form))
  }
}

export function* readCardTerminalStatusListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.READ_CARD_TERMINAL_STATUS.TRY)

    if (form === formListener) {
      yield fork(readCardTerminalStatusProcess, form)
    }
  }
}

export function* getCayanKeyProcess(form, action) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null

  const apiParams = action?.payload?.guid
    ? {
        guid: action.payload.guid,
        action: 'getwebapikey'
      }
    : {
        dataId,
        action: 'getwebapikey'
      }

  const { response, error } = yield call(api.readSalesOrder, apiParams)

  if (response) {
    yield put(actions.getCayanKey.success(response, action.meta))
  } else {
    yield put(actions.getCayanKey.failure(error, action.meta))
  }
}

export function* getCayanKeyListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_CAYAN_KEY.REQUEST)
    if (action.meta.form === formListener) {
      yield fork(getCayanKeyProcess, action.meta.form, action)
    }
  }
}

export function* initiateCardTerminalSignatureProcess(action, formActions) {
  const formState = yield select(getFormSelector(action.meta.form))
  const guid = getIn(formState, 'guid')
  formActions = formActions || actions

  const { response, error } = yield call(api.propertyChange, {
    guid,
    Action: 'terminalsignature',
    RequestReply: true,
    Properties: { requestsignature: '' }
  })

  if (response) {
    yield put(
      actions.initiateCardTerminalSignature.success(response, action.meta)
    )
  } else {
    yield put(actions.initiateCardTerminalSignature.failure(error, action.meta))
  }
}

export function* initiateCardTerminalSignatureListener(
  formListener,
  formActions = null
) {
  while (true) {
    const action = yield take(
      CONSTANTS.INITIATE_CARD_TERMINAL_SIGNATURE.REQUEST
    )

    if (action.meta.form === formListener) {
      yield fork(initiateCardTerminalSignatureProcess, action, formActions)
    }
  }
}

export function* cancelSignature(action) {
  // yield console.log(action)
  const formState = yield select(getFormSelector(action.meta.form))
  const guid = getIn(formState, 'guid')

  const { response, error } = yield call(api.propertyChange, {
    guid,
    Action: 'terminalsignature',
    Properties: { cancelsignature: action.payload.taskId }
  })
  if (response) {
    actions.pollCardTerminalSignature.success({}, action.meta)
  } else {
    yield put(actions.pollCardTerminalSignature.failure(response, action.meta))
  }
}
export function* pollCardTerminalSignatureProcess(action) {
  const timer = 1000
  while (true) {
    const formState = yield select(getFormSelector(action.meta.form))
    const guid = getIn(formState, 'guid')

    const { response, error } = yield call(api.propertyChange, {
      guid,
      Action: 'terminalsignature',
      taskId: action.payload.taskId,
      Properties: { requestsignature: '' }
    })
    // console.log(response, error)
    if (response?.signature) {
      yield put(
        actions.pollCardTerminalSignature.success(response, action.meta)
      )
      return
    }
    if (error) {
      yield put(
        actions.pollCardTerminalSignature.failure(response, action.meta)
      )
      return
    }
    const hasField = getIn(formState, 'fields.tempSignedBy')
    if (!hasField) {
      yield fork(cancelSignature, action)
      return
    }
    yield delay(timer)
  }
}

export function* pollCardTerminalSignatureListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE.REQUEST)

    if (action.meta.form === formListener) {
      yield fork(pollCardTerminalSignatureProcess, action)
    }
  }
}
export function* onChangeCheckoutTypeListener(formListener) {
    while (true) {
        const {
            meta: { form },
            payload: { feature, featureState }
        } = yield take(CONSTANTS.STORE_UI_FEATURE_STATE)

        if (form === formListener && featureState && feature === 'paymentOption') {
            console.log(featureState)
            const isPos = featureState !== 'bill'

            if (isPos) {
                yield fork(propertyChangeProcess, form, 'pos', isPos, false)
            }
        }
    }
}


export default function* checkoutSagas(form) {
  yield fork(initializePaymentsListener, form)
  yield fork(voidPaymentListener, form)
  yield fork(launchDepositInvoiceEntryModalListener, form)
  yield fork(depositInvoiceEntryListener, form)
  yield fork(createInvoiceListener, form)
  yield fork(deleteInvoiceListener, form)
  yield fork(notifyCreditCardErrorListener, form)
  yield fork(billForBackorderListener, form)
  yield fork(getVaultCardsListener, form)
  yield fork(removeVaultCardListener, form)
  yield fork(selectLinkedRefundListener, form)
  yield fork(linkedRefundListener, form)
  yield fork(changeCheckoutContactListener, form)
  yield fork(readCardTerminalStatusListener, form)
  yield fork(cancelCardReaderPaymentListener, form)
  yield fork(getCayanKeyListener, form)
  yield fork(initiateCardTerminalSignatureListener, form)
  yield fork(pollCardTerminalSignatureListener, form)
  yield fork(onChangeCheckoutTypeListener, form)
}
