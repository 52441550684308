import {
  all,
  take,
  select,
  call,
  put,
  putResolve,
  fork,
  cancel
} from 'redux-saga/effects'
import { api } from 'services'
import { getIn } from 'utils'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
// import INDEXSEARCH_CONSTANTS, { CLOSE_SEARCH } from 'components/Search/IndexSearch/constants'
import { displayValidationErrors } from 'ddiForm/sagas'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as actions from 'ddiForm/actions'
import { ADD } from 'pages/Layout/constants'
import { clearLayoutScreenData } from 'pages/Layout/actions'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import { sendDocument } from 'components/PrintDocumentModal/actions'
import { getFormSelector } from 'ddiForm/utils'

import {
  clearResults,
  reset,
  setFlagged,
  printSalesOrders,
  viewReport
} from './actions'

import {
  FIND,
  EXACT_MATCH,
  EXECUTE_INQUIRY,
  OPEN_SCREEN,
  CHECK_FOR_SEARCH_PARAMS_ON_LOAD,
  UPDATE_PARAMS_AND_EXECUTE_SALES_ORDER_INQUIRY,
  PRINT_SALES_ORDERS,
  VIEW_REPORT
} from './constants'

const propsToClearOnBlur = [
  'poNumber',
  'manualShipToInfo',
  'startDate',
  'endDate'
]

const propsToOnlyClearResults = ['startDate', 'endDate']

const rangeFilterProps = ['rangeFilters.gpPercent', 'rangeFilters.gpDollar']

let tasks
export function* executeInquiryListener() {
  let task
  while (true) {
    const action = yield take(EXECUTE_INQUIRY.TRY)
    // debugger

    const {
      meta: { form },
      payload: { propertyName }
    } = action
    if (task) yield cancel(task)

    task = yield call(executeInquiryProcess, form, propertyName)
  }
}

export function* executeInquiryProcess(form, propertyName) {
  // TODO VALIDATE START/END DATE
  const formState = yield select(state =>
    getIn(state, `ddiForm.${form}.fields`)
  )
  const status = getIn(formState, 'status.value')
  const dateType = getIn(formState, 'dateType.value')
  const startDate = getIn(formState, 'startDate.value')
  const endDate = getIn(formState, 'endDate.value')
  const branchId = getIn(formState, 'branch.value')
  const salesmanId = getIn(formState, 'salesman.value')
  const writer = getIn(formState, 'writer.value')
  const shipViaId = getIn(formState, 'shipVia.value')
  const orderTypeId = getIn(formState, 'orderType.value')
  const dataId = getIn(formState, 'orderNumber.value')
  const productId = getIn(formState, 'product.value')
  const customerId = getIn(formState, 'customer.value')
  const poNumber = getIn(formState, 'poNumber.value')
  const jobName = getIn(formState, 'jobName.value')
  const manualShipToInfo = getIn(formState, 'manualShipToInfo.value')
  const rgaCode = getIn(formState, 'rgaCode.value') || ''

  let overrideFilters = getIn(formState, 'overrideFilters')
  let rangeFilters = getIn(formState, 'rangeFilters')

  rangeFilters = rangeFilters
    ? rangeFilters.reduce((acc, next) => {
        const value = getIn(next, 'value')
        if (value) {
          acc = acc.concat({
            value,
            type: getIn(next, 'meta.filterType').toString(),
            filterType: getIn(next, 'filterTypeOption.value')
          })
        }
        return acc
      }, [])
    : []

  overrideFilters = overrideFilters
    ? overrideFilters.reduce((acc, next) => {
        // console.log(val, val.toJS())
        // return val
        const enabled = getIn(next, 'enabled.value')
        if (enabled) {
          // console.log(getIn(next, 'enabled.filterType'))
          acc = acc.concat({
            type: getIn(next, 'enabled.meta.filterType').toString(),
            enabled: true,
            filterType: getIn(next, 'filterTypeOption.value')
          })
        }
        return acc
      }, [])
    : []

  const b =
    status === 'O' &&
    !(
      branchId ||
      customerId ||
      dataId ||
      endDate ||
      jobName ||
      manualShipToInfo ||
      orderTypeId ||
      overrideFilters.length ||
      poNumber ||
      productId ||
      rangeFilters.length ||
      salesmanId ||
      shipViaId ||
      startDate ||
      rgaCode ||
      writer
    )

  if (form === 'salesOrderInquiry' || form === 'salesOrderInquiryLite') {
    // debugger
    if (b) {
      yield call(
        confirmationModal,
        'Do you wish to see all orders?',
        'No Selection Entered'
      )
      const action = yield take([CONFIRMED, CANCELED])
      if (action.type === CANCELED) {
        return
      }
    }
  }
  yield put({ type: EXECUTE_INQUIRY.REQUEST, meta: { form, apiRequest: true } })

  const { response, error } = yield call(api.salesOrderInquiry, {
    dataId,
    productId,
    status,
    dateType,
    startDate,
    endDate,
    branchId,
    salesmanId,
    writer,
    shipViaId,
    orderTypeId,
    customerId,
    poNumber,
    jobName,
    manualShipToInfo,
    overrideFilters,
    rangeFilters,
    rgaCode
  })
  // console.log(response, error)
  // debugger
  if (response) {

    yield put({
      meta: { form },
      payload: { ...response },
      type: EXECUTE_INQUIRY.SUCCESS
    })
    if (response.messages?.length) {
      for (const message of response.messages) {
        yield fork(warningModal, message.message, message.modalTitle)
      }
    }
  } else {
    yield put({
      meta: { form },
      payload: { ...error, propertyName },
      type: EXECUTE_INQUIRY.FAILURE
    })

    yield fork(displayValidationErrors, error)
  }
}

export function* salesOrderFindListener(formListener) {
  let task
  while (true) {
    const action = yield take(FIND.REQUEST)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      if (task) yield cancel(task)
      yield fork(salesOrderFindProcess, form, action)
    }
  }
}

export function* salesOrderFindProcess(form, action) {
  const {
    meta: { thunk },
    payload: { keyword = '', propertyName, subProperty }
  } = action

  // keyword (required)
  if (keyword) {
    const { response, error } = yield call(api.salesOrderFind, {
      keyword
    })

    if (response) {
      yield putResolve({
        meta: { form, thunk },
        payload: {
          ...response,
          propertyName,
          subProperty
        },
        type: FIND.SUCCESS
      })
    } else {
      yield put({
        meta: { form, thunk },
        error: true,
        payload: {
          ...error,
          propertyName,
          subProperty
        },
        type: FIND.FAILURE
      })
    }
  } else {
    yield fork(validateAndClearInquiryResultsProcess, form, propertyName)
  }
}

export function* validateAndClearInquiryResultsProcess(form, propertyName) {
  const formState = yield select(getFormSelector(form))
  const inquiryResults = getIn(
    formState,
    'fields.inquiryResults.rowData'
  )?.toJS()
    ? getIn(formState, 'fields.inquiryResults.rowData')?.toJS()
    : []

  if (inquiryResults.length) {
    if (propsToOnlyClearResults.includes(propertyName)) {
      yield put(clearResults(form))
    } else {
      yield put(reset(form))
    }
  }
}

export function* salesOrderExactMatchProcess(action) {
  const {
    payload: { keyword = '', propertyName, subProperty },
    meta: { form, thunk }
  } = action

  const { response, error } = yield call(api.salesOrderExactMatch, {
    keyword
  })

  if (response) {
    yield put({
      meta: { form, thunk },
      payload: {
        ...response,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH.SUCCESS
    })
  } else {
    yield put({
      meta: { form, thunk },
      payload: {
        ...error,
        propertyName,
        subProperty
      },
      type: EXACT_MATCH.FAILURE,
      error: true
    })

    if (error?.status !== 404 && error?.message) {
      yield fork(warningModal, error.message, 'Alert!')
    }
  }
}

// used in onAuth.js - do not remove
export function* salesOrderExactMatchListener() {
  let task
  while (true) {
    const action = yield take(EXACT_MATCH.REQUEST)
    if (task) yield cancel(task)
    yield fork(salesOrderExactMatchProcess, action)
  }
}

export function* setFieldListener(form) {
  const trackedProps = [
    'orderNumber',
    'product',
    'customer',
    'poNumber',
    'jobName',
    'manualShipToInfo'
  ]
  while (true) {
    const action = yield take(DDICONSTANTS.SET_FIELD)
    // debugger
    const {
      meta,
      payload: { propertyName, value }
    } = action
    if (meta.form === form) {
      if (trackedProps.includes(propertyName)) {
        // console.log(action)

        yield put(setFlagged(meta.form, !!value))
      }
    }
  }
}

export function* onOpenProcess(form) {
  // debugger
  const payload = { meta: {} }
  const indexSearchFields = yield select(state =>
    getIn(state, 'auth.common.salesOrderInquiryMeta.indexSearchFields')
  )
  const printTypes = yield select(state =>
    getIn(state, 'auth.common.salesOrderInquiryMeta.printTypes')
  )
  const salesmanDisabled = !indexSearchFields.find(
    x => getIn(x, 'searchType') === 'Salesman'
  )
  //         console.log(indexSearchFields)
  payload.meta.indexSearchFields = indexSearchFields.toJS()
  if (salesmanDisabled) {
    const defaultValue = yield select(state =>
      getIn(state, 'auth.salesmenDisplay')
    )
    payload.meta.indexSearchFields = payload.meta.indexSearchFields.concat({
      allowInstantSearch: true,
      allowNavigation: false,
      allowSearchAll: true,
      fieldName: 'Salesman',
      hasFilters: false,
      hideToggle: true,
      minimumKeywordLength: 0,
      openText: null,
      searchType: 'Salesman',
      disabled: true,
      defaultValue
    })
  }
  payload.meta.printTypes = printTypes.toJS()

  yield putResolve({
    type: OPEN_SCREEN,
    payload,
    meta: { form }
  })
}
// export function* onBlurListener(form) {
//   const orderProduct = ['orderNumber', 'product']
//   const custEtc = ['customer', 'poNumber', 'jobName', 'manualShipToInfo']
//   const mapper = {
//     poNumber: orderProduct,
//     manualShipToInfo: orderProduct,
//     product: ['orderNumber', ...custEtc.slice(1)],
//     orderNumber: ['product', ...custEtc],
//     customer: orderProduct,
//     jobName: orderProduct
//   }
//   while (true) {
//     const action = yield take([INDEX_SEARCH_CONSTANTS.BLUR, DDICONSTANTS.BLUR])
//     const {
//       meta,
//       payload: { isSet, propertyName }
//     } = action
//     // debugger
//     if (meta.form === form) {
//       const field = yield select(state =>
//         getIn(
//           getIn(state, 'ddiForm'),
//           `${meta.form}.fields.${action.payload.propertyName}`
//         )
//       )
//       // console.log(field.toJS())
//       // console.log(action)
//       const fields = mapper[action.payload.propertyName]
//       if (action.type === INDEX_SEARCH_CONSTANTS.BLUR) {
//         if (fields) {
//           if (isSet) {
//             yield put(actions.disableFields(meta.form, fields))
//           } else {
//             // yield put(actions.enableFields(meta.form, fields))
//           }
//         }
//       }
//       if (action.type === DDICONSTANTS.BLUR) {
//         if (propertyName === 'orderNumber') {
//           if (getIn(field, 'isSet')) {
//             yield put(actions.disableFields(meta.form, fields))
//           } else {
//             yield put(actions.enableFields(meta.form, fields))
//           }
//         } else if (
//           propertyName === 'manualShipToInfo' ||
//           propertyName === 'poNumber'
//         ) {
//           if (getIn(field, 'value')) {
//             yield put(actions.disableFields(meta.form, fields))
//           } else {
//             yield put(actions.enableFields(meta.form, fields))
//           }
//         }
//       }
//     }
//   }
// }

// export function* onBlurListener() {
//   const orderProduct = ['orderNumber', 'product']
//   const custEtc = ['customer', 'poNumber', 'jobName', 'manualShipToInfo']
//   const mapper = {
//     poNumber: orderProduct,
//     manualShipToInfo: orderProduct,
//     product: ['orderNumber', ...custEtc],
//     orderNumber: ['product', ...custEtc.slice(1)],
//     customer: orderProduct,
//     jobName: orderProduct
//   }
//   while (true) {
//     const action = yield take([INDEX_SEARCH_CONSTANTS.BLUR, DDICONSTANTS.BLUR])
//     const {
//       meta,
//       payload: { isSet, propertyName }
//     } = action
//     debugger
//     if (meta.form) {
//       if (meta.form.includes('salesOrderInquiry')) {
//         const field = yield select(state =>
//           getIn(
//             getIn(state, 'ddiForm'),
//             `${meta.form}.fields.${action.payload.propertyName}`
//           )
//         )
//         // console.log(field.toJS())
//         // console.log(action)
//         const fields = mapper[action.payload.propertyName]
//         if (action.type === INDEX_SEARCH_CONSTANTS.BLUR) {
//           if (fields) {
//             if (isSet) {
//               yield put(actions.disableFields(meta.form, fields))
//             } else {
//               // yield put(actions.enableFields(meta.form, fields))
//             }
//           }
//         }
//         if (action.type === DDICONSTANTS.BLUR) {
//           if (propertyName === 'orderNumber') {
//             if (getIn(field, 'isSet')) {
//               yield put(actions.disableFields(meta.form, fields))
//             } else {
//               yield put(actions.enableFields(meta.form, fields))
//             }
//           } else if (
//             propertyName === 'manualShipToInfo' ||
//             propertyName === 'poNumber'
//           ) {
//             if (getIn(field, 'value')) {
//               yield put(actions.disableFields(meta.form, fields))
//             } else {
//               yield put(actions.enableFields(meta.form, fields))
//             }
//           }
//         }
//       }
//     }
//   }
// }

// const fields = mapper[action.payload.propertyName]
// if (fields) {
//   const field = yield select(state =>
//     getIn(getIn(state, 'ddiForm'), `${meta.form}.fields.${action.payload.propertyName}`),
//   )
//   console.log(field)
//   if (getIn(field, 'value')) {
//     yield put(actions.disableFields(meta.form, fields))
//   } else {
//     yield put(actions.enableFields(meta.form, fields))
//   }
// }

// export function* salesOrderSearchSagas() {
//   yield [fork(setFieldListener)]
// }

// export function* dropDownOpenListener() {
//   while (true) {
//     const action = yield take(OPEN_DROPDOWN.REQUEST)
//     const { meta } = action
//     const hasAccess = yield call(checkHasAccessProcess, 'salesOrderInquiry')
//     if (hasAccess) {
//       yield putResolve({
//         meta: {
//           form: meta.form,
//           thunk: meta.thunk
//         },
//         type: OPEN_DROPDOWN.SUCCESS
//       })
//     } else {
//       // show dialog...
//       yield call(grantAccessProcess, {})
//       // yield put({
//       //   meta: {
//       //     form: meta.form,
//       //     thunk: meta.thunk
//       //   },
//       //   type: OPEN_DROPDOWN.FAILURE,
//       //   error: true
//       // })
//     }
//   }
// }

export function* getSearchInquiryParams(form, key, payload = {}) {
  const value =
    payload && payload.data && payload.data[key]
      ? payload.data[key]
      : yield select(state =>
          getIn(state, `layout.screens.${form}.data.${key}`)
        )

  if (value) {
    return value
  }

  return ''
}

export function* loadSearchParamsProcess(form, payload, isOpen = false) {
  const customer = yield call(getSearchInquiryParams, form, 'customer', payload)
  const status = yield call(getSearchInquiryParams, form, 'status', payload)
  const product = yield call(getSearchInquiryParams, form, 'product', payload)

  // debugger
  if (customer || status || product) {
    // yield put(reset(form))

    // debugger
    if (customer) {
      yield put(actions.setField(form, 'customer', customer))
    }

    if (status) {
      yield put(actions.setField(form, 'status', status))
    }

    if (product) {
      yield put(actions.setField(form, 'product', product))
    }

    yield put(clearLayoutScreenData(form))
    yield fork(executeInquiryProcess, form, null)
  }
}

export function* checkForSearchParamsOnLoadListener(formListener) {
  while (true) {
    const action = yield take(CHECK_FOR_SEARCH_PARAMS_ON_LOAD)
    const {
      meta: { form }
    } = action

    // debugger
    if (form === formListener) {
      yield fork(loadSearchParamsProcess, form)
    }
  }
}

export function* onAddListener(formListener) {
  while (true) {
    const action = yield take(ADD)
    const { payload } = action

    if (payload.name === formListener) {
      yield fork(loadSearchParamsProcess, formListener, payload)
    }
  }
}

export function* updateParamsAndExecuteSalesOrderInquiryListener(formListener) {
  while (true) {
    const {
      payload,
      meta: { form }
    } = yield take(UPDATE_PARAMS_AND_EXECUTE_SALES_ORDER_INQUIRY)

    if (form === formListener) {
      yield fork(loadSearchParamsProcess, form, payload, true)
    }
  }
}

export function* printSalesOrdersProcess(form, isDirectSend = false) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  const status = getIn(formState, 'fields.status.value') || ''
  const printType = getIn(formState, 'fields.printType.value') || 'SO'

  let selectedRows = getIn(formState, 'selectedRows')
  selectedRows = selectedRows && selectedRows.toJS ? selectedRows.toJS() : []

  if (!selectedRows.length) {
    return
  }

  yield put(printSalesOrders.request(form))
  // debugger
  const { response, error } = yield call(api.printSalesOrderDocuments, {
    dataIds: selectedRows,
    printType,
    status
  })

  if (response) {
    // debugger
    yield put(printSalesOrders.success(response, form))
    if (isDirectSend) {
      yield put(sendDocument(form, { dataId: response.dataId }))
    } else {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response,
        form
      })
    }
  } else {
    yield put(printSalesOrders.failure(error, form))
  }
}

export function* printSalesOrdersListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(PRINT_SALES_ORDERS.TRY)

    if (form === formListener) {
      yield fork(printSalesOrdersProcess, form, false)
    }
  }
}

/*
  had to disable closeSearchListener here
  because canceling the tasks here prevented Sales Order Search
  from working in an dropdown on any second usage -- SVE 4/17/2020
*/
// export function* closeSearchListener(formListener) {
//   while (true) {
//     const action = yield take(CLOSE_SEARCH)

//     if (action.meta.form === formListener && tasks) {
//       yield cancel(tasks)
//     }
//   }
// }

export function* clearOnBlurListener(formListener) {
  while (true) {
    const action = yield take(DDICONSTANTS.BLUR)
    const {
      meta: { form },
      payload: { propertyName, value }
    } = action

    if (form === formListener) {
      if (
        (!value && propsToClearOnBlur.includes(propertyName)) ||
        rangeFilterProps.includes(propertyName)
      )
        yield fork(validateAndClearInquiryResultsProcess, form, propertyName)
    }
  }
}

export function* viewReportProcess(form) {
  let dataIds

  const formState = yield select(getFormSelector(form))
  const inquiryResults = getIn(
    formState,
    'fields.inquiryResults.rowData'
  )?.toJS()
    ? getIn(formState, 'fields.inquiryResults.rowData')?.toJS()
    : []

  if (inquiryResults?.length) {
    dataIds = inquiryResults.map(order => order.dataId)
  }

  yield put(viewReport.request(form))

  const { response, error } = yield call(api.salesOrderInquiryReport, {
    dataIds
  })

  if (response) {
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(viewReport.failure(error, form))
  }
}

export function* viewReportListener(formListener) {
  while (true) {
    const action = yield take(VIEW_REPORT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(viewReportProcess, form)
    }
  }
}

export default function* salesOrderSearchSagas(form) {
  // debugger
  if (tasks) {
    yield cancel(tasks)
  }

  tasks = yield all([
    yield fork(onOpenProcess, form),
    yield fork(executeInquiryListener),
    yield fork(salesOrderFindListener, form),
    yield fork(setFieldListener, form),
    yield fork(checkForSearchParamsOnLoadListener, form),
    yield fork(onAddListener, form),
    yield fork(updateParamsAndExecuteSalesOrderInquiryListener, form),
    yield fork(printSalesOrdersListener, form),
    // yield fork(closeSearchListener, form)
    yield fork(clearOnBlurListener, form),
    yield fork(viewReportListener, form)
  ])
  // yield fork(onBlurListener, form)
}
