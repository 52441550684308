import {
  delay,
  take,
  call,
  fork,
  put,
  select,
  actionChannel
} from 'redux-saga/effects'
import { api } from 'services'
import { exit, setField } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, getField, is, fromJS, toJS } from 'utils'
import { EXACT_MATCH_SEARCH } from 'components/Search/IndexSearch/constants'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'

// import { CANCELED, CONFIRMED } from 'modals/constants'
// import { confirmationModal, warningModal } from 'modals/sagas'
import {
  getEntityAsync,
  resetMasterFields,
  lockForEditAsync
} from 'ddiForm/MasterScreen/actions'

import { BLUR, SET_FIELD } from 'ddiForm/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import { CANCEL_EDIT } from 'ddiForm/MasterScreen/constants'
import selectionCriteriaModalSagas from 'components/SelectionCriteriaModal/sagas'
import masterScreenSagas, { searchProcess } from 'ddiForm/MasterScreen/sagas'
import { tryTogglePreNewModeListener } from 'components/MasterScreen/StandardSimpleForm/sagas'
import { getErrorMessages } from 'components/SalesOrderReport/utils'
// import { data } from 'jquery' WTF is this?
import warrantyTagSOETransaction from 'pages/SalesOrder/common/WarrantyTag/warrantyTagSOETransaction'
import {
  deleteWarrantyTag,
  originalInvoiceSearch,
  printWarrantyTag,
  propertyChange,
  updateWarrantyTag
} from './api'

import * as actions from './actions'
import * as CONSTANTS from './constants'

export const isSOETransaction = form => form && form?.match(/salesOrder/)

export const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}

export function* warrantyTagEnteredListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value: dataId }
    } = yield take(BLUR)

    if (form === formListener && propertyName === 'dataId' && dataId) {
      yield fork(searchProcess, form, null, false, null, dataId)
    }
  }
}

export function* originalInvoiceSearchProcess(form, keyword) {
  yield put(actions.originalInvoiceSearch.request({ keyword }, form))

  const { response, error } = yield call(originalInvoiceSearch, keyword)

  if (response) {
    yield put(actions.originalInvoiceSearch.success({ keyword }, form))
    yield put(
      actions.propertyChange.try(
        {
          propertyName: 'returnInvoiceId',
          value: keyword
        },
        form
      )
    )
    yield fork(propertyChangeProcess, form, {
      propertyName: 'returnInvoiceId',
      value: keyword
    })
  } else {
    const { validationErrors } = error
    if (keyword && validationErrors?.[0]?.message) {
      yield call(confirmationModal, validationErrors[0].message, 'Attention')
      const action = yield take([CONFIRMED, CANCELED])
      if (action.type === CANCELED) {
        yield put(actions.originalInvoiceSearch.failure({ keyword }, form))
      } else {
        yield put(actions.originalInvoiceSearch.success({ keyword }, form))
        yield fork(propertyChangeProcess, form, {
          propertyName: 'returnInvoiceId',
          value: keyword
        })
      }
    } else if (!keyword) {
      yield put(actions.originalInvoiceSearch.success({ keyword }, form))
      yield fork(propertyChangeProcess, form, {
        propertyName: 'returnInvoiceId',
        value: keyword
      })
    }
  }
}

export function* propertyChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value },
      payload
    } = yield take([BLUR, SET_FIELD])

    if (form === formListener && propertyName !== 'dataId') {
      if (propertyName === 'returnInvoiceId') {
        debugger
        yield fork(originalInvoiceSearchProcess, form, value)
      } else {
        yield fork(propertyChangeProcess, form, payload)
      }
    }
  }
}

export function* getSalesOrderTransactionApiParams(form) {
  const { parentForm, childForm } = getChildAndParentForm(form)
  const formState = yield select(getFormSelector(parentForm))
  const lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  const selectedRowIndex = getIn(formState, 'fields.lineItems.selectedRowIndex')
  // debugger

  if (!is.number(selectedRowIndex)) {
    return {}
  }

  return {
    guid: getIn(formState, 'guid') || null,
    lineNumber: lineItems?.get(selectedRowIndex)?.get('lineNumber')
  }
}

export function* propertyChangeProcess(form, payload) {
  const { propertyName, value } = payload
  yield put(actions.propertyChange.request(form))
  const formState = yield select(getFormSelector(form))
  const dataId = getField(formState, 'dataId', null)

  let editedFields = getIn(formState, 'editedFields')
  editedFields = editedFields && editedFields?.toJS ? editedFields.toJS() : []

  let properties = {
    [propertyName]: value
  }

  const isSalesOrderTransaction = isSOETransaction(form)

  if (
    is.array(editedFields) &&
    editedFields.length &&
    !isSalesOrderTransaction
  ) {
    /* 
      not sure if this is necessary but gonna leave it 
      because not sure why its there --- SVE 5/18/2022 
    */
    properties = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      const val = getIn(field, 'rowData') || getIn(field, 'value')
      acc[next] = toJS(val)
      return acc
    }, {})
  }

  const apiRoute = isSalesOrderTransaction
    ? warrantyTagSOETransaction
    : propertyChange

  let apiParams = isSalesOrderTransaction
    ? {
        action: 'propertychange',
        properties
      }
    : {
        dataId,
        properties
      }

  if (isSalesOrderTransaction) {
    const soeTransactionParams = yield call(
      getSalesOrderTransactionApiParams,
      form
    )

    apiParams = {
      ...apiParams,
      ...soeTransactionParams
    }
  }

  const { response, error } = yield call(apiRoute, apiParams)

  if (response) {
    // debugger
    yield put(actions.propertyChange.success(response, form))
  } else {
    // debugger
    yield put(actions.propertyChange.failure(error, form))
  }
}

export function* printListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.PRINT.TRY)

    if (form === formListener) {
      yield fork(printProcess, form)
    }
  }
}

export function* printProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  const { response, error } = yield call(printWarrantyTag, {
    dataId
  })

  if (response) {
    yield put(actions.print.success(response, form))
    yield call(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
    yield put(actions.print.failure(error, form))
  }
}

export function* deleteWarrantyTagListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.DELETE_WARRANTY_TAG.TRY)

    if (form === formListener) {
      yield fork(deleteWarrantyTagProcess, form)
    }
  }
}

export function* deleteWarrantyTagProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const canDelete = getIn(formState, 'values.canDelete', false)

  if (canDelete) {
    yield call(
      confirmationModal,
      `Are you sure you want to delete Warranty Tag - ${dataId}?`,
      'Delete?'
    )

    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }

    yield put(actions.deleteWarrantyTag.request(form))

    const { response, error } = yield call(deleteWarrantyTag, {
      dataId
    })

    if (response) {
      yield put(actions.deleteWarrantyTag.success(response, form))
    } else {
      yield put(actions.deleteWarrantyTag.failure(error, form))
    }
  }
}

export function* onOpenWarrantyTagInModal(response, meta, form) {
  yield put(
    getEntityAsync.success(response, {
      form,
      thunk: meta.thunk
    })
  )
}

export default function* sagas(form) {
  yield fork(warrantyTagEnteredListener, form)
  yield fork(printListener, form)
  yield fork(masterScreenSagas, form)
  yield fork(propertyChangeListener, form)
  yield fork(deleteWarrantyTagListener, form)
}
