export default [
  {
    primaryText: 'Alabama',
    value: 'AL'
  },
  {
    primaryText: 'Alaska',
    value: 'AK'
  },
  {
    primaryText: 'American Samoa',
    value: 'AS'
  },
  {
    primaryText: 'Arizona',
    value: 'AZ'
  },
  {
    primaryText: 'Arkansas',
    value: 'AR'
  },
  {
    primaryText: 'California',
    value: 'CA'
  },
  {
    primaryText: 'Colorado',
    value: 'CO'
  },
  {
    primaryText: 'Connecticut',
    value: 'CT'
  },
  {
    primaryText: 'Delaware',
    value: 'DE'
  },
  {
    primaryText: 'District Of Columbia',
    value: 'DC'
  },
  {
    primaryText: 'Federated States Of Micronesia',
    value: 'FM'
  },
  {
    primaryText: 'Florida',
    value: 'FL'
  },
  {
    primaryText: 'Georgia',
    value: 'GA'
  },
  {
    primaryText: 'Guam',
    value: 'GU'
  },
  {
    primaryText: 'Hawaii',
    value: 'HI'
  },
  {
    primaryText: 'Idaho',
    value: 'ID'
  },
  {
    primaryText: 'Illinois',
    value: 'IL'
  },
  {
    primaryText: 'Indiana',
    value: 'IN'
  },
  {
    primaryText: 'Iowa',
    value: 'IA'
  },
  {
    primaryText: 'Kansas',
    value: 'KS'
  },
  {
    primaryText: 'Kentucky',
    value: 'KY'
  },
  {
    primaryText: 'Louisiana',
    value: 'LA'
  },
  {
    primaryText: 'Maine',
    value: 'ME'
  },
  {
    primaryText: 'Marshall Islands',
    value: 'MH'
  },
  {
    primaryText: 'Maryland',
    value: 'MD'
  },
  {
    primaryText: 'Massachusetts',
    value: 'MA'
  },
  {
    primaryText: 'Michigan',
    value: 'MI'
  },
  {
    primaryText: 'Minnesota',
    value: 'MN'
  },
  {
    primaryText: 'Mississippi',
    value: 'MS'
  },
  {
    primaryText: 'Missouri',
    value: 'MO'
  },
  {
    primaryText: 'Montana',
    value: 'MT'
  },
  {
    primaryText: 'Nebraska',
    value: 'NE'
  },
  {
    primaryText: 'Nevada',
    value: 'NV'
  },
  {
    primaryText: 'New Hampshire',
    value: 'NH'
  },
  {
    primaryText: 'New Jersey',
    value: 'NJ'
  },
  {
    primaryText: 'New Mexico',
    value: 'NM'
  },
  {
    primaryText: 'New York',
    value: 'NY'
  },
  {
    primaryText: 'North Carolina',
    value: 'NC'
  },
  {
    primaryText: 'North Dakota',
    value: 'ND'
  },
  {
    primaryText: 'Northern Mariana Islands',
    value: 'MP'
  },
  {
    primaryText: 'Ohio',
    value: 'OH'
  },
  {
    primaryText: 'Oklahoma',
    value: 'OK'
  },
  {
    primaryText: 'Oregon',
    value: 'OR'
  },
  {
    primaryText: 'Palau',
    value: 'PW'
  },
  {
    primaryText: 'Pennsylvania',
    value: 'PA'
  },
  {
    primaryText: 'Puerto Rico',
    value: 'PR'
  },
  {
    primaryText: 'Rhode Island',
    value: 'RI'
  },
  {
    primaryText: 'South Carolina',
    value: 'SC'
  },
  {
    primaryText: 'South Dakota',
    value: 'SD'
  },
  {
    primaryText: 'Tennessee',
    value: 'TN'
  },
  {
    primaryText: 'Texas',
    value: 'TX'
  },
  {
    primaryText: 'Utah',
    value: 'UT'
  },
  {
    primaryText: 'Vermont',
    value: 'VT'
  },
  {
    primaryText: 'Virgin Islands',
    value: 'VI'
  },
  {
    primaryText: 'Virginia',
    value: 'VA'
  },
  {
    primaryText: 'Washington',
    value: 'WA'
  },
  {
    primaryText: 'West Virginia',
    value: 'WV'
  },
  {
    primaryText: 'Wisconsin',
    value: 'WI'
  },
  {
    primaryText: 'Wyoming',
    value: 'WY'
  },
  {
    primaryText: 'Alberta',
    value: 'AB'
  },
  {
    primaryText: 'British Columbia',
    value: 'BC'
  },
  {
    primaryText: 'Manitoba',
    value: 'MB'
  },
  {
    primaryText: 'New Brunswick',
    value: 'NB'
  },
  {
    primaryText: 'Newfoundland and Labrador',
    value: 'NL'
  },
  {
    primaryText: 'Northwest Territories',
    value: 'NT'
  },
  {
    primaryText: 'Nova Scotia',
    value: 'NS'
  },
  {
    primaryText: 'Nunavut',
    value: 'NU'
  },
  {
    primaryText: 'Ontario',
    value: 'ON'
  },
  {
    primaryText: 'Prince Edward Island',
    value: 'PE'
  },
  {
    primaryText: 'Quebec',
    value: 'QC'
  },
  {
    primaryText: 'Saskatchewan',
    value: 'SK'
  },
  {
    primaryText: 'Yukon Territory',
    value: 'YT'
  }
]
