import React from 'react'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import DDICardWrapper from 'components/DDICardWrapper'
import { DDIToggle } from 'ddiForm/wrapped'

const JohnstoneSetup = ({ form, ...rest }) => {
  return (
    <div
      className="vendor-master-johnstone-setup-wrapper"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <DDICardWrapper title="Johnstone" style={{ width: 'auto' }}>
        <AssociatedFieldsWrapper style={{ marginTop: 0 }}>
          <DDIToggle
            propertyName="excludeFromWebOrderDiscount"
            label="Exclude From Web Order Discount"
          />
        </AssociatedFieldsWrapper>
      </DDICardWrapper>
    </div>
  )
}

export default JohnstoneSetup
