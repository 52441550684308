import React, { useEffect, useRef, useState } from 'react'
// import { DDITextField } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import { DDIButton, DDITextField, DDIIndexSearch } from 'ddiForm/wrapped'
import { Label } from 'ddiForm'

import {
  clearRebate as clearRebateAction,
  changeProvisionalLineItem
} from 'pages/SalesOrder/actions'
import { Table, TableRow, TableCell } from '@material-ui/core'
import { formatNumber } from 'utils'

import '../styles/rebate-cost.scss'
import { getIn } from 'immutable'
import NumberField from './Pricing/components/NumberField'

export const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const orderId = getIn(formState, 'fields.copyProducts.orderId.value') || ''
  const orderOptionIsPosting = getIn(formState, 'orderOptionIsPosting') || false
  const rowData = getIn(formState, 'fields.copyProducts.lineItems.rowData')

  return {
    orderId,
    orderOptionIsPosting,
    rowData
  }
}

export const RebateCost = ({
  dispatch,
  form,
  canSeeCost,
  isPostingProvisionalData,
  priceChange = {},
  canChange,
  setEdited,
  lineNumber,
  isEditing
} = {}) => {
  const {
    rebateCost,
    canSeeRebateCost,

    rebateContractId,
    rebateVendorId,
    canChangeRebateCost,
    rebateVendorDescription,
    allowRebateCost,
    rebateCostMethod,
    unitCost,
    uomId
  } = priceChange

  // debugger

  function clearRebate() {
    
    dispatch(clearRebateAction.try(form, { lineNumber }))
    // dispatch(
    //   changeProvisionalLineItem.try(form, {
    //     lineNumber,
    //     propertyName: 'cancelProvisionalChanges'
    //   })
    // )
  }

  const onChange = async (propertyName, event) => {
    await dispatch(
      changeProvisionalLineItem.try(form, {
        lineNumber,
        propertyName,
        value: event.target.value
      })
    )
    await dispatch(
      changeProvisionalLineItem.try(form, {
        lineNumber,
        propertyName: 'confirmProvisionalChanges'
      })
    )
  }

  return (
    <div className="rebate-cost-outer-wrapper">
      <div className="flex-wrapper">
        <div className="info-wrapper">
          <Table>
            <TableRow style={{ background: '#fff' }}>
              <TableCell>Unit of Measure:</TableCell>
              <TableCell style={{ fontWeight: 500 }}>{uomId}</TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
      <div
        className="flex-wrapper"
        style={{ width: '580px', marginBottom: '18px' }}
      >
        <DDICardWrapper
          title="Rebate Cost"
          contentStyle={{ padding: 10, paddingBottom: '5px' }}
        >
          <Table>
            <TableRow>
              <TableCell style={{ width: '25%' }}>Current Cost:</TableCell>
              <TableCell
                style={{ fontWeight: 500, width: '25%' }}
                align="right"
              >
                {canSeeCost && unitCost ? (
                  <span>{formatNumber(unitCost, '0,0.0000')}</span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </TableCell>
              {/* <TableCell>
                <span>&nbsp;</span>
              </TableCell>
              <TableCell>
                <span>&nbsp;</span>
              </TableCell> */}
            </TableRow>
            {canSeeRebateCost && (
              <TableRow>
                <TableCell style={{ width: '25%' }}>Rebate Cost:</TableCell>
                <TableCell
                  style={{ fontWeight: 500, width: '25%' }}
                  align="right"
                >
                  <DDITextField
                    propertyName={`rebateCostData[${lineNumber -
                      1}].rebateCost`}
                    disabled={!canChangeRebateCost}
                    // wrapperStyle={{ width: '125px' }}
                    mask="numeric"
                    onChangeCb={setEdited}
                    inputProps={{
                      textAlign: 'right',
                      decimalScale: 4,
                      fixedDecimalScale: true
                      // prefix: '$'
                    }}
                  />
                </TableCell>
              </TableRow>
            )}
            {allowRebateCost && (
              <TableRow>
                <TableCell style={{ width: '25%' }}>Rebate Contract:</TableCell>
                <TableCell style={{ fontWeight: 500, width: '25%' }}>
                  <DDITextField
                    propertyName={`rebateCostData[${lineNumber -
                      1}].rebateContractId`}
                    onChangeCb={setEdited}
                    // wrapperStyle={{ width: '200px' }}
                  />
                </TableCell>
              </TableRow>
            )}
            {allowRebateCost && (
              <TableRow>
                <TableCell style={{ width: '25%' }}>Vendor:</TableCell>
                <TableCell style={{ fontWeight: 500, display: 'flex' }}>
                  <div style={{ width: '30%' }}>
                    <DDIIndexSearch
                      // initialValue={rebateVendorId}
                      propertyName={`rebateCostData[${lineNumber -
                        1}].rebateVendorId`}
                      indexSearchType="vendor"
                      preventAutoDisable
                      allowInstantSearch
                      hasFilters={false}
                      minimumKeywordLength={2}
                      isSecure={false}
                      searchype="Vendor"
                      groupName={null}
                      allowSearchAll={false}
                      allowSpecialProducts={false}
                      disabled={!isEditing || !canChange}
                    />
                  </div>
                  <div style={{ width: '70%' }}>
                    <div
                      className="ddi-data-label"
                      style={{
                        background: '#eaeef0',
                        borderRadius: 4,
                        color: '#517b9c',
                        display: 'inline-block',
                        fontSize: 12,
                        lineHeight: '16px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        padding: 5,
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: 'auto'
                      }}
                    >
                      {rebateVendorDescription}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </Table>
          <div className="rebate-cost-method-section">
            <div className="rebate-flex">
              <div className="rebate-wrapper">
                <div className="rebate-flex">
                  Rebate Cost Method:
                  <span style={{ fontWeight: 'bold' }}>{rebateCostMethod}</span>
                </div>
              </div>
            </div>
            <div className="rebate-flex">
              <DDIButton
                onClick={clearRebate}
                variant="contained"
                label="Clear"
                disabled={!isEditing || !canChange}
              />
            </div>
          </div>
        </DDICardWrapper>
      </div>
    </div>
  )
}

export default RebateCost
