import { exit } from 'ddiForm/actions'
import { createAction, createAsyncFormAction } from 'utils'

import * as CONSTANTS from './constants'

export const exitScreen = form => exit(form)

export const updateDescription = (form, { description }) => {
  const action = createAction(
    CONSTANTS.UPDATE_DESCRIPTION,
    { description },
    form
  )
  return action
}

export const saveNotes = createAsyncFormAction(CONSTANTS.SAVE_NOTES)

export const lockSerialForEdit = createAsyncFormAction(
  CONSTANTS.LOCK_SERIAL_FOR_EDIT
)

export const unlockSerial = createAsyncFormAction(CONSTANTS.UNLOCK_SERIAL)

export const deleteNotes = createAsyncFormAction(CONSTANTS.DELETE_NOTES)

export const saveProduct = createAsyncFormAction(CONSTANTS.SAVE_PRODUCT)

export const deleteSerialNumber = createAsyncFormAction(
  CONSTANTS.DELETE_SERIAL_NUMBER
)

export const cancelProductEdit = createAsyncFormAction(
  CONSTANTS.CANCEL_PRODUCT_EDIT
)

export const setFilter = createAsyncFormAction(CONSTANTS.SET_FILTER)

export const setSelectedSerialNumber = (form, { value }) => {
  const action = createAction(
    CONSTANTS.SET_SELECTED_SERIAL_NUMBER,
    { value },
    form
  )
  return action
}

export const clearCompleteSerialHistory = createAsyncFormAction(
  CONSTANTS.CLEAR_COMPLETE_SERIAL_HISTORY
)

export const clearFields = (form, { prop }) => {
  const action = createAction(CONSTANTS.CLEAR_FIELDS, { prop }, form)
  return action
}

export const addSerialNumber = createAsyncFormAction(
  CONSTANTS.ADD_SERIAL_NUMBER
)

export const clearCell = (form, data) =>
  createAction(CONSTANTS.CLEAR_CELL, { ...data }, form)

export const exitScreenFromModal = (form, { modalId }) =>
  createAction(CONSTANTS.EXIT_SCREEN_FROM_MODAL, { modalId }, form)
