import React, { useEffect } from 'react'
import Grid from 'grid'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { debounce, isEqual } from 'lodash'
import {
  getIn,
  getValue,
  formatDateFields,
  formatDollarFields,
  formatNumber,
  is,
  numToDollar
} from 'utils'
import TooltipField from 'hoc/TooltipField'
import withRowTotals from 'hoc/withRowTotals'
import { resizeGrid } from 'pages/SalesOrder/utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import {
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core'

const TotalsPinnedRowRenderer = ({ value }) => (
  <span style={{ fontWeight: 'bold' }}>{value}</span>
)

const InvoiceCell = ({ data, value, colDef }) => {
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = colDef?.cellRendererParams?.dispatch || ''

  const openInvoiceInquiryScreen = debounce(e => {
    if (form && dispatch && value) {
      dispatch(
        openScreenAction({
          dataId: value,
          image: 'sales_invoicing_invoice_inquiry_16.png',
          route: 'invoiceinquiry',
          title: 'Invoice Inquiry',
          groupNames: ['header', 'detail']
        })
      )
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Button
        onClick={openInvoiceInquiryScreen}
        style={{ color: '#517b9c', justifyContent: 'flex-start', padding: 0 }}
        variant="text"
      >
        {value}
      </Button>
    </div>
  )
}

const getGridHeight = memoize(data => {
  let height = 200
  if (data && data.length) {
    height = data.length * 30 + 32 + 56
  }

  return height
}, isEqual)

const isObjectHasKey = (obj, key) =>
  !is.null(obj) &&
  typeof obj === 'object' &&
  Object.hasOwnProperty.call(obj, key)

const getPinnedRowData = memoize(rowData => {
  const pinnedRowData =
    rowData && Array.isArray(rowData) && rowData.length
      ? rowData.reduce(
          (acc, next) => {
            acc.merchandiseAmount += next.merchandiseAmount
            acc.freight += next.freight
            acc.miscellaneous += next.miscellaneous
            acc.depositInvoice += next.depositInvoice
            acc.tax += next.tax
            acc.invoiceTotal += next.invoiceTotal
            if (isObjectHasKey(next.payment, 'value')) {
              acc.payment += next.payment.value
            }
            return acc
          },
          {
            dataId: 'TOTALS:',
            merchandiseAmount: 0,
            freight: 0,
            miscellaneous: 0,
            depositInvoice: 0,
            tax: 0,
            invoiceTotal: 0,
            payment: 0
          }
        )
      : {}

  return [pinnedRowData]
}, isEqual)

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: getPinnedRowData
})(Grid)

let _isMounted = false

const PaymentsDeposits = ({
  dispatch,
  form,
  paymentsDeposits,
  remainingBalance,
  totalAmountApplied,
  printPanelOpen
}) => {
  let columnApi = null
  let gridApi = null

  const getColumnDefs = memoize(f => {
    return [
      {
        colId: 'dataId',
        field: 'dataId',
        headerName: 'Invoice',
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'invoiceOrder',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(InvoiceCell),
        cellRendererParams: {
          form,
          dispatch
        },
        minWidth: 100,
        pinnedRowCellRenderer: 'customPinnedRowRenderer'
      },
      {
        cellClass: 'text-center',
        colId: 'type',
        field: 'type',
        headerName: 'Type',
        headerClass: 'text-center',
        minWidth: 100
      },
      {
        cellClass: 'text-center',
        colId: 'invoiceDate',
        field: 'invoiceDate',
        headerName: 'Date',
        headerClass: 'text-center',
        valueFormatter: formatDateFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'payment',
        field: 'payment',
        headerName: 'Payment',
        headerClass: 'align-right',
        valueFormatter: params => {
          if (isObjectHasKey(params.value, 'value')) {
            return numToDollar(params.value.value)
          }

          if (is.number(params.value)) {
            return numToDollar(params.value)
          }

          return null
        },
        tooltip: params => {
          if (isObjectHasKey(params.value, 'tooltip')) {
            return params.value.tooltip
          }

          return null
        },
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'merchandiseAmount',
        field: 'merchandiseAmount',
        headerClass: 'align-right',
        headerName: 'Merchandise',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'depositInvoice',
        field: 'depositInvoice',
        headerClass: 'align-right',
        headerName: 'Deposit Invoice',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'balanceReceived',
        field: 'balanceReceived',
        headerClass: 'align-right',
        headerName: 'Balance Received',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'text-center',
        colId: 'balanceReceivedDate',
        field: 'balanceReceivedDate',
        headerClass: 'text-center',
        headerName: 'Balance Received Date',
        valueFormatter: formatDateFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'balanceApplied',
        field: 'balanceApplied',
        headerClass: 'text-center',
        headerName: 'Balance Applied',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'text-center',
        colId: 'balanceAppliedDate',
        field: 'balanceAppliedDate',
        headerClass: 'text-center',
        headerName: 'Balance Applied Date',
        valueFormatter: formatDateFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'tax',
        field: 'tax',
        headerClass: 'align-right',
        headerName: 'Tax',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'freight',
        field: 'freight',
        headerClass: 'align-right',
        headerName: 'Freight',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'miscellaneous',
        field: 'miscellaneous',
        headerClass: 'align-right',
        headerName: 'Misc. Amount',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        cellClass: 'align-right',
        colId: 'invoiceTotal',
        field: 'invoiceTotal',
        headerClass: 'align-right',
        headerName: 'Invoice Total',
        valueFormatter: formatDollarFields,
        minWidth: 100
      },
      {
        field: 'paymentMethod',
        colId: 'paymentMethod',
        headerName: 'Type',
        valueFormatter: params => {
          if (isObjectHasKey(params.value, 'value')) {
            return params.value.value
          }

          return null
        },
        tooltip: params => {
          if (isObjectHasKey(params.value, 'tooltip')) {
            return params.value.tooltip
          }

          return null
        },
        minWidth: 100
      }
    ]
  })

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    if (columnApi && _isMounted) {
      resizeGrid(columnApi)
    }
  }, [printPanelOpen])

  const onToolPanelVisibleChanged = params => {
    if (params.api && params.columnApi) {
      if (!params.api.isToolPanelShowing() && _isMounted) {
        resizeGrid(params.columnApi)
      }
    }
  }

  return (
    <div style={{ width: '100%' }}>
      {paymentsDeposits &&
      Array.isArray(paymentsDeposits) &&
      paymentsDeposits.length ? (
        <GridWithRowTotals
          propertyName="paymentsDeposits"
          form={form}
          rowData={paymentsDeposits}
          columnDefs={getColumnDefs(form)}
          getRowNodeId={data => data.dataId}
          height={getGridHeight(paymentsDeposits)}
          allowFieldChooser
          onGridReady={onGridReady}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          frameworkComponents={{
            customPinnedRowRenderer: TotalsPinnedRowRenderer
          }}
          reactUi={false}
        />
      ) : (
        <p>No data</p>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ maxWidth: 400 }}>
          <Table>
            <TableBody>
              <TableRow style={{ background: '#fff' }}>
                <TableCell>Remaining Balance</TableCell>
                <TableCell>{formatNumber(remainingBalance)}</TableCell>
              </TableRow>
              <TableRow style={{ background: '#fff' }}>
                <TableCell>Total Amount Applied:</TableCell>
                <TableCell>{formatNumber(totalAmountApplied)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default withContext({
  remainingBalance: form => getValue(form, 'remainingBalance', 0),
  totalAmountApplied: form => getValue(form, 'totalAmountApplied', 0),
  printPanelOpen: form => getIn(form, 'ui.printPanelOpen') || false
})(PaymentsDeposits)
