/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteGridRow, deleteGridRowNew } from 'components/EditableGrid/utils'
import { getIn, toJS } from 'utils'
import shouldUpdate from 'hoc/shouldUpdate'
import CancelIcon from 'components/EditableGrid/components/icons/CancelIcon'
import { removeLineItem } from 'pages/SalesOrder/actions'

const mapStateToProps = (state, ownProps) => {
  const { form, propertyName } = ownProps
  const isPending = getIn(
    state,
    `ddiForm.${form}.fields.${propertyName}.isPending`
  )

  const rowData =
    getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`) ||
    getIn(state, `ddiForm.${form}.fields.${propertyName}.value`)

  return {
    rowData
  }
}

class RemoveLineItemCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    rowData: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn, tooltip }
      }
    } = props

    const tooltipDisplay =
      tooltip && props.data[tooltip] ? props.data[tooltip] : ''

    this.state = {
      tooltipDisplay: tooltipDisplay || ''
    }

    this.deleteGridRow = deleteGridRowNew.bind(this)
    // this.clearGridRow = clearGridRow.bind(this)

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  removeLineItem = () => {
    const rowData = toJS(this.props.rowData) || []

    const {
      form,
      node: {
        data: { rowId = '' }
      }
    } = this.props
    /* 
      data.uniqueKey was not being updated by ag-grid
      but for some reason, thankfully node.data.uniqueKey
      is getting updated -- SVE 4/20/2020
    */

    const lineNumber = rowData?.find(x => x?.rowId === rowId)?.lineNumber
    debugger
    // if (lineNumber) {
    //   lineNumber = lineNumber.lineNumber
    // }
    //

    if (lineNumber) {
      this.props.dispatch(
        removeLineItem.try(form, {
          lineNumber,
          rowId,
          data: this.props.node.data
        })
      )
    }
  }

  isDisabled = () => {
    const {
      colDef: {
        cellRendererParams: { disabledFn }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return false
  }

  render() {
    const { tooltipDisplay } = this.state
    const {
      colDef: {
        cellRendererParams: { propertyName }
      },
      hasRecord,
      isEditing
    } = this.props
    // const disabled = !(hasRecord && isEditing)
    const disabled = !this?.props?.data?.canDelete || !isEditing

    return (
      <CancelIcon
        title={tooltipDisplay}
        onClick={this.removeLineItem}
        disabled={disabled}
      />
    )
  }
}

// export default connect(
//   mapStateToProps,
//   null,
//   null,
//   { forwardRef: true }
// )(
//   shouldUpdate({
//     whitelist: ['data', 'rowData', 'propertyName', 'rowIndex', 'value']
//   })(RemoveLineItemCell)
// )
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(RemoveLineItemCell)

// export default connect(
//   null,
//   null,
//   null,
//   { forwardRef: true }
// )(DeleteGridRowCell)
