import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'
import moment from 'moment'

const screen = 'pointofsale'

export const previewReport = values => {
  const options = createApiOptions({
    body: { action: 'preview', guid: 1, ...values },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`, options)
}

export const postBatches = values => {
  const options = createApiOptions({
    body: {action: 'post', guid: 1, ...values},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`,  options)
}

export const generateFinalReport = ({batchDate, batchId = ''} )=> {
  const formattedDate = moment(batchDate, 'MM/DD/YYYY').format('YYYY-MM-DDT00:00:00Z')

  const options = createApiOptions({
    body: {action: 'final', guid: 1, batchDate: formattedDate, batchId},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`,  options)
}

export const createNewBatch = ({branchId, register} ) => {
  const options = createApiOptions({
    body: {action: 'createnew', guid: 1, branchId, register},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`,  options)
}

export const fetchBatchItems = () => {
  const options = createApiOptions({
    // need to find out about guid here
    body: { action: 'read', guid: 1 },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`, options)
}

export const closePointOfSale = () => {
  const options = createApiOptions({
    body: {},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/close`, options)
}
