/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const clearInterface = form =>
  createAction(CONSTANTS.CLEAR_INTERFACE, {}, form)

export const openProductImportEditor = createAsyncFormAction(
  CONSTANTS.OPEN_PRODUCT_IMPORT_EDITOR
)

export const setRowData = (
  form,
  { rowData = [], clearData = false, includesMeta = false, ...rest }
) =>
  createAction(
    CONSTANTS.SET_ROW_DATA,
    { rowData, clearData, includesMeta, ...rest },
    form
  )

export const onCellValueChanged = (
  form,
  { field, value, rowIndex, hasHeaders = false }
) =>
  createAction(
    CONSTANTS.ON_CELL_VALUE_CHANGED,
    { field, value, rowIndex, hasHeaders },
    form
  )

export const onProductImportPropertyChange = createAsyncFormAction(
  CONSTANTS.ON_PRODUCT_IMPORT_PROPERTY_CHANGE
)

export const analyzeProductImportData = createAsyncFormAction(
  CONSTANTS.ANALYZE_PRODUCT_IMPORT_DATA
)

export const clearMappings = createAsyncFormAction(CONSTANTS.CLEAR_MAPPINGS)

export const importProductData = createAsyncFormAction(
  CONSTANTS.IMPORT_PRODUCT_DATA
)

export const exitImportScreen = createAsyncFormAction(
  CONSTANTS.EXIT_IMPORT_SCREEN
)

export const deleteTemplate = createAsyncFormAction(CONSTANTS.DELETE_TEMPLATE)
