import React, { memo } from 'react'
import AddressLink from 'components/AddressLink'
import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'
import { getIn } from 'utils'
import { BASE_INFORM_URL } from 'services/constants'

export const googleLink = 'https://www.google.com/maps/search/?api=1&query='

export const getAddressLink = (address1, address2, cityStateZip) => {
  let href
  if (address1) {
    let addressStr = `${address1} ${address2}, ${cityStateZip}`
    addressStr = addressStr.replace(',', '')
    href = `${googleLink}${encodeURI(addressStr)}`
    href = <AddressLink href={href} address={address1} />
  }
  return href
}

export const getErrorMessages = errors =>
  errors.reduce((acc, next) => {
    if (next.message) {
      acc = acc.concat(`${next.message} \n`)
    }
    return acc
  }, '')

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const setGridHeight = (
  array = [],
  additionalHeight = 0,
  headerHeight = 32
) => array.length * 28 + (headerHeight + array.length) + additionalHeight

export const Link = memo(props => {
  function onClick(e) {
    if (props?.dispatch && props?.form) {
      props.dispatch({
        type: 'SEND_DOCUMENT_TRY',
        meta: { form: props.form },
        payload: {
          dataId: !props.newMode ? props.dataId : null,
          sendType: props.sendType,
          parentId: props.parentId,
          parentType: props.parentType
        }
      })
    }
  }

  return (
    <a onClick={onClick} style={{ fontWeight: 'bold' }}>
      {props.value}
    </a>
  )
})

export const mapDataResponse = ({
  response,
  tabIds = [],
  formState,
  groupNames = []
}) => {
  const nested = [
    // ...tabIds,
    // ...groupNames,
    // 'activities',
    'customFields',
    'attachments',
    'audit'
  ]

  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (
        nested.includes(next) &&
        response[next] !== null &&
        typeof response[next] === 'object' &&
        Object.keys(response[next]).length
      ) {
        for (const prop in response[next]) {
          if (prop === 'customFieldsData') {
            acc = mapCustomFieldsData(acc, response[next][prop])
          }

          acc[prop] = response[next][prop]
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  // console.log(res)
  // debugger

  return res
}

export const getImageSource = form => {
  const imageBase64String = getIn(form, 'values.imageBase64String')

  if (
    imageBase64String &&
    typeof imageBase64String === 'string' &&
    imageBase64String.match(/data:image/)
  ) {
    return imageBase64String
  }

  if (
    imageBase64String &&
    typeof imageBase64String === 'string' &&
    (imageBase64String.charAt(0) === '/' ||
      !imageBase64String.match(/data:image/))
  ) {
    return `data:image/jpeg;base64,${imageBase64String}`
  }

  return `${BASE_INFORM_URL}/resources/white/no_image.png`
}
