/* eslint import/prefer-default-export: 0 */
import {
  call,
  fork,
  put,
  select,
  take,
  actionChannel
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, dataURItoBlob } from 'utils'
import { addModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CONFIRMED, CANCELED, REMOVE_MODAL } from 'modals/constants'
import ReportViewer from 'components/ReportViewer'
import FileSaver from 'file-saver'
import Dialog from 'modals/Dialog'
import ConfirmCancelDuplicateAttachmentsActions from './components/ConfirmCancelDuplicateAttachmentsActions'
import AttachmentsModal from './components/AttachmentsModal'

import * as CONSTANTS from './constants'
import * as actions from './actions'
import {
  mimeTypes,
  targetTypes,
  handleAdditionalAttachmentsDataCallback,
  buildLedgerApiParams
} from './utils'
import { handleAttachments } from './api'
import {
  addLedgerInvoiceAttachmentParams,
  addLedgerOrderAttachmentParams,
  addLedgerPaymentHistoryAttachmentParams
} from '../../../pages/VendorMaster/utils'

export function* addContactParams(form, params) {
  const formState = yield select(getFormSelector(form))
  const parentId = getIn(formState, 'values.parent.parentId')
  const parentType = getIn(formState, 'values.parent.parentType')

  /* 
    form can sometimes be like customerMaster.contact,
    so we need to overwite if that is the case -- SVE
  */
  return {
    ...params,
    form: 'contact',
    parentId,
    parentType
  }
}

export function* addInvoiceInquiryParams(form, params) {
  const formState = yield select(getFormSelector(form))
  const selectedAttachmentsTab =
    getIn(formState, 'ui.attachmentsTab') || 'This Invoice'

  if (selectedAttachmentsTab === 'Sales Order') {
    return {
      ...params,
      type: 'salesorder'
    }
  }

  return {
    ...params
  }
}

export function* showDuplicateAttachmentsModal(form, message) {
  const options = {
    component: Dialog,
    options: {
      actions: ConfirmCancelDuplicateAttachmentsActions,
      data: {
        message
      },
      modalOverrideClass: '',
      title: 'Confirm Overwrite?',
      type: 'confirm',
      width: 500
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  return modal.payload.id
}

export function* uploadAttachmentsProcess(form, payload) {
  const { targetType, uploadData } = payload
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const guid = getIn(formState, 'guid') || null
  let attachments = getIn(formState, 'fields.attachments.rowData')
  attachments = attachments && attachments.toJS ? attachments.toJS() : []
  // debugger
  if (
    payload?.additionalAttachmentsData?.existingRowData &&
    Array.isArray(payload?.additionalAttachmentsData?.existingRowData)
  ) {
    /* 
      this means we are evaulating comparison data from a component's internal state
      rather than data from Redux (e.g. Customer Ledger attachments & Send Document attachments)
      -- SVE 9/18/20
    */
    attachments = payload.additionalAttachmentsData.existingRowData
  }

  const filesExist = uploadData.reduce((acc, next) => {
    if (attachments.findIndex(x => x.description === next.fileName) > -1) {
      acc = acc.concat(next.fileName)
    }

    return acc
  }, [])

  let parameters = {
    attachments: uploadData
  }

  if (filesExist.length) {
    const message = filesExist.join(',\n')
    const formAlias =
      form === 'sendDocument' && payload?.additionalAttachmentsData?.parentForm
        ? payload?.additionalAttachmentsData?.parentForm
        : form

    yield call(
      showDuplicateAttachmentsModal,
      formAlias,
      `The following files already exist:\n\n${message}\n\n Do you want to replace them? If not, a number will be added to the end of the file name.`
    )
    const action = yield take([CONFIRMED, CANCELED, REMOVE_MODAL])

    if (action.type === CONFIRMED) {
      parameters = {
        attachments: uploadData.reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            overwrite: filesExist.includes(next.fileName),
            rename: false
          })
          return acc
        }, [])
      }
    } else if (action.type === CANCELED) {
      parameters = {
        attachments: uploadData.reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            overwrite: false,
            rename: filesExist.includes(next.fileName)
          })
          return acc
        }, [])
      }
    } else {
      return
    }
  }

  let apiParams = guid
    ? {
        action: 'upload',
        form: 'salesOrder',
        dataId,
        guid,
        parameters
      }
    : {
        action: 'upload',
        form,
        dataId,
        parameters
      }

  if (form === 'stockReceipts' && targetType === 'i') {
    apiParams.attachmentType = 'I'
  }

  if (form === 'vendorMaster' && targetType === 'invoice') {
    apiParams = addLedgerInvoiceAttachmentParams({
      apiParams,
      additionalAttachmentsData: payload.additionalAttachmentsData
    })
  }

  if (form === 'vendorMaster' && targetType === 'payment') {
    apiParams = addLedgerPaymentHistoryAttachmentParams({
      apiParams,
      additionalAttachmentsData: payload.additionalAttachmentsData
    })
  }

  if (form === 'vendorMaster' && targetType === 'order') {
    apiParams = addLedgerOrderAttachmentParams({
      apiParams,
      additionalAttachmentsData: payload.additionalAttachmentsData
    })
  }

  if (form === 'contact' || form.match(/\.contact/)) {
    apiParams = yield call(addContactParams, form, apiParams)
  }

  if (form === 'invoiceInquiry') {
    apiParams = yield call(addInvoiceInquiryParams, form, apiParams)
  }

  if (form === 'sendDocument' && payload?.additionalAttachmentsData) {
    apiParams = {
      ...apiParams,
      dataId: payload?.additionalAttachmentsData?.dataId,
      sendType: payload?.additionalAttachmentsData?.sendType
    }
  }

  if (form === 'customerMaster') {
    if (!targetType) {
      apiParams = {
        ...apiParams,
        targetType: 'customer'
      }
    } else if (
      targetType &&
      payload?.additionalAttachmentsData?.modalData &&
      typeof payload?.additionalAttachmentsData?.modalData === 'object'
    ) {
      apiParams = buildLedgerApiParams(
        targetType,
        apiParams,
        payload.additionalAttachmentsData.modalData
      )
    }
  }

  yield put(actions.uploadAttachments.request(form))
  const { response, error } = yield call(handleAttachments, apiParams)

  if (response) {
    yield put(
      actions.uploadAttachments.success(
        {
          ...response,
          targetType
        },
        form
      )
    )

    if (payload?.additionalAttachmentsData) {
      handleAdditionalAttachmentsDataCallback(
        payload?.additionalAttachmentsData,
        response
      )
    }
  } else {
    yield put(actions.uploadAttachments.failure(error, form))
    if (error.message) {
      yield fork(warningModal, error.message, 'Error!')
    }
  }
}

export function* uploadAttachmentsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.UPLOAD_ATTACHMENTS.TRY)
    // debugger
    if (
      form === formListener ||
      (form === 'sendDocument' &&
        (payload?.additionalAttachmentsData?.parentForm &&
          payload?.additionalAttachmentsData?.parentForm === formListener))
    ) {
      yield fork(uploadAttachmentsProcess, form, payload)
    }
  }
}

export function* deleteAttachmentProcess(
  form,
  fileNames = [],
  targetType = '',
  additionalAttachmentsData = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid') || null
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield call(
    confirmationModal,
    'Are you sure you want to remove these attachment(s)?',
    'Remove?'
  )
  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put(actions.deleteAttachment.request(form))

    let apiParams = guid
      ? {
          action: 'remove',
          form: 'salesOrder',
          dataId,
          guid,
          parameters: {
            fileNames: [...fileNames]
          }
        }
      : {
          action: 'remove',
          form,
          dataId,
          parameters: {
            fileNames: [...fileNames]
          }
        }

    if (form === 'stockReceipts' && targetType === 'i') {
      apiParams.attachmentType = 'I'
    }

    if (form === 'vendorMaster' && targetType === 'invoice') {
      apiParams = addLedgerInvoiceAttachmentParams({
        apiParams,
        additionalAttachmentsData
      })
    }

    if (form === 'vendorMaster' && targetType === 'payment') {
      apiParams = addLedgerPaymentHistoryAttachmentParams({
        apiParams,
        additionalAttachmentsData
      })
    }

    if (form === 'vendorMaster' && targetType === 'order') {
      apiParams = addLedgerOrderAttachmentParams({
        apiParams,
        additionalAttachmentsData
      })
    }

    if (form === 'contact' || form.match(/\.contact/)) {
      apiParams = yield call(addContactParams, form, apiParams)
    }

    if (form === 'invoiceInquiry') {
      apiParams = yield call(addInvoiceInquiryParams, form, apiParams)
    }

    if (form === 'sendDocument' && additionalAttachmentsData) {
      apiParams = {
        ...apiParams,
        dataId: additionalAttachmentsData?.dataId,
        sendType: additionalAttachmentsData?.sendType
      }
    }

    if (form === 'customerMaster') {
      if (!targetType) {
        apiParams = {
          ...apiParams,
          targetType: 'customer'
        }
      } else if (
        targetType &&
        additionalAttachmentsData?.modalData &&
        typeof additionalAttachmentsData?.modalData === 'object'
      ) {
        apiParams = buildLedgerApiParams(
          targetType,
          apiParams,
          additionalAttachmentsData?.modalData
        )
      }
    }

    const { response, error } = yield call(handleAttachments, apiParams)

    if (response) {
      yield put(
        actions.deleteAttachment.success(
          {
            ...response,
            targetType
          },
          form
        )
      )

      if (additionalAttachmentsData) {
        handleAdditionalAttachmentsDataCallback(
          additionalAttachmentsData,
          response
        )
      }
    } else {
      yield put(actions.deleteAttachment.failure(error, form))
    }
  }
}

export function* deleteAttachmentListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: {
        fileNames = [],
        targetType = '',
        additionalAttachmentsData = null
      }
    } = yield take(CONSTANTS.DELETE_ATTACHMENT.TRY)

    if (
      form === formListener ||
      (form === 'sendDocument' &&
        additionalAttachmentsData?.parentForm &&
        additionalAttachmentsData?.parentForm === formListener)
    ) {
      yield fork(
        deleteAttachmentProcess,
        form,
        fileNames,
        targetType,
        additionalAttachmentsData
      )
    }
  }
}

export function* handleAttachmentDownloads(form, response) {
  let extension = response.extension ? response.extension.toLowerCase() : ''
  extension = extension.replace(/^./, '')
  const { filename, base64String } = response

  const mimeType = extension && mimeTypes[extension] ? mimeTypes[extension] : ''

  if (base64String && extension === 'pdf' && mimeType) {
    yield fork(viewDocumentProcess, form, {
      document: `data:${mimeType};base64,${base64String}`
    })

    const fileBlob = dataURItoBlob(`data:${mimeType};base64,${base64String}`, {
      type: mimeType
    })

    if (fileBlob) {
      FileSaver.saveAs(fileBlob, filename)
    }
  } else if (base64String && extension && mimeType) {
    const fileBlob = dataURItoBlob(`data:${mimeType};base64,${base64String}`, {
      type: mimeType
    })

    if (fileBlob) {
      FileSaver.saveAs(fileBlob, filename)
    }
  } else {
    if (response.text) {
      const text = new Blob([response.text], {
        type: 'text/plain;charset=utf-8'
      })
      FileSaver.saveAs(text, filename)
    }

    if (response.url) {
      window.open(response.url, 'external')
    }
  }
}

export function* viewDocumentProcess(form, payload) {
  if (payload && payload.document) {
    const modalOpts = {
      component: ReportViewer,
      options: {
        data: {
          actions: [{ primary: true, title: 'Exit' }],
          pdfData: payload.document
        },
        fullScreen: true,
        title: '',
        marginTop: '0px',
        maxHeight: '95%',
        maxWidth: '95%',
        width: '95%'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
  }
}

export function* downloadAttachmentsProcess(
  form,
  fileName,
  targetType,
  additionalAttachmentsData = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid') || null
  const dataId = getIn(formState, 'fields.dataId.value') || null

  if (!fileName) {
    return
  }

  yield put(actions.downloadAttachment.request(form))

  debugger

  let apiParams = guid
    ? {
        action: 'download',
        form: 'salesOrder',
        dataId,
        guid,
        parameters: {
          fileName
        }
      }
    : {
        action: 'download',
        form: form && form.match(/salesOrder/) ? 'salesOrder' : form,
        dataId,
        parameters: {
          fileName
        }
      }

  if (form === 'stockReceipts' && targetType === 'i') {
    apiParams.attachmentType = 'I'
  }

  if (form === 'vendorMaster' && targetType === 'invoice') {
    apiParams = addLedgerInvoiceAttachmentParams({
      apiParams,
      additionalAttachmentsData
    })
  }

  if (form === 'vendorMaster' && targetType === 'payment') {
    apiParams = addLedgerPaymentHistoryAttachmentParams({
      apiParams,
      additionalAttachmentsData
    })
  }

  if (form === 'vendorMaster' && targetType === 'order') {
    apiParams = addLedgerOrderAttachmentParams({
      apiParams,
      additionalAttachmentsData
    })
  }

  if (form === 'contact' || form.match(/\.contact/)) {
    apiParams = yield call(addContactParams, form, apiParams)
  }

  if (form === 'invoiceInquiry') {
    apiParams = yield call(addInvoiceInquiryParams, form, apiParams)
  }

  if (form === 'sendDocument' && additionalAttachmentsData) {
    apiParams = {
      ...apiParams,
      dataId: additionalAttachmentsData?.dataId,
      sendType: additionalAttachmentsData?.sendType
    }
  }

  if (form === 'customerMaster') {
    if (!targetType) {
      apiParams = {
        ...apiParams,
        targetType: 'customer'
      }
    } else if (
      targetType &&
      additionalAttachmentsData?.modalData &&
      typeof additionalAttachmentsData?.modalData === 'object'
    ) {
      apiParams = buildLedgerApiParams(
        targetType,
        apiParams,
        additionalAttachmentsData?.modalData
      )
    }
  }

  const { response, error } = yield call(handleAttachments, apiParams)

  if (response) {
    yield put(actions.downloadAttachment.success(response, form))
    const formAlias =
      form === 'sendDocument' ? additionalAttachmentsData?.parentForm : form
    yield fork(handleAttachmentDownloads, formAlias, response)
  } else {
    yield put(actions.downloadAttachment.failure(error, form))
  }
}

export function* downloadAttachmentsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { fileName, targetType, additionalAttachmentsData = null }
    } = yield take(CONSTANTS.DOWNLOAD_ATTACHMENT.TRY)

    if (
      (form === formListener && fileName) ||
      (form === 'sendDocument' &&
        fileName &&
        additionalAttachmentsData?.parentForm &&
        additionalAttachmentsData?.parentForm === formListener)
    ) {
      yield fork(
        downloadAttachmentsProcess,
        form,
        fileName,
        targetType,
        additionalAttachmentsData
      )
    }
  }
}

export function* launchAttachmentsModal(
  form,
  modalData,
  modalTitle = 'Attachments'
) {
  // const { targetType, attachments } = modalData
  const modalOpts = {
    component: AttachmentsModal,
    options: {
      maxHeight: '100%',
      data: {
        actions: [{ primary: true, title: 'Exit' }],
        form,
        ...modalData
      },
      title: modalTitle
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* getAttachmentsProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const shipToId = getIn(formState, 'fields.shipToId.value')

  const ledgerFields = getIn(formState, 'fields.customerLedger')
  const invoiceType = getIn(ledgerFields, 'invoicesFilter.value')
  let invoiceYear = getIn(ledgerFields, 'invoicesYearFilter.value') || null
  invoiceYear = invoiceYear && invoiceYear === 'All' ? null : invoiceYear

  let paymentHistoryYear = getIn(ledgerFields, 'paymentHistoryFilter.value')
  paymentHistoryYear =
    paymentHistoryYear && paymentHistoryYear === 'All'
      ? null
      : paymentHistoryYear

  const orderType = getIn(ledgerFields, 'ordersFilter.value')

  const { uniqueKey, targetType, modalTitle } = payload

  debugger
  const baseApiParams = {
    form,
    dataId,
    shipToId,
    targetType,
    uniqueKey,
    action: 'get',
    parameters: null
  }

  const apiParamsMap = {
    ledgerOrder: {
      ...baseApiParams,
      targetType: 'order',
      poType: payload.poType
    },
    ledgerPaymentHistory: {
      ...baseApiParams,
      targetType: 'payment',
      paymentHistoryYear: payload.paymentHistoryYear
    },
    ledgerInvoice: {
      ...baseApiParams,
      targetType: 'invoice',
      invoiceType: payload.invoiceType,
      invoiceYear: payload.invoiceYear,
      includeRemitToInvoices: payload.includeRemitToInvoices
    },
    invoice: {
      ...baseApiParams,
      invoiceType,
      invoiceYear
    },
    payment: {
      ...baseApiParams,
      paymentHistoryYear
    },
    order: {
      ...baseApiParams,
      orderType
    },
    paidInvoice: {
      ...baseApiParams,
      invoiceType: 'P',
      invoiceYear: paymentHistoryYear,
      targetType: 'invoice'
    }
  }

  if (!apiParamsMap[targetType]) {
    return
  }

  debugger
  yield put(actions.getAttachments.request(form))

  const { response, error } = yield call(
    handleAttachments,
    apiParamsMap[targetType]
  )

  if (response) {
    const modalData = {
      ...response,
      ...apiParamsMap[targetType]
    }

    yield put(actions.getAttachments.success(response, form))
    debugger
    yield fork(launchAttachmentsModal, form, modalData, modalTitle)
  } else {
    yield put(actions.getAttachments.failure(error, form))
  }
}

export function* getAttachmentsListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.GET_ATTACHMENTS.TRY)

    if (form === formListener) {
      yield fork(getAttachmentsProcess, form, payload)
    }
  }
}

export default function*(form) {
  yield fork(uploadAttachmentsListener, form)
  yield fork(deleteAttachmentListener, form)
  yield fork(downloadAttachmentsListener, form)
  yield fork(getAttachmentsListener, form)
}
