import { boolFlip } from 'ddiForm/reducerEnhancers'
import { setIn } from 'utils'

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const groupNamesMap = {
  purchaseOrderHistory: 'poHistory',
  workOrder: 'workOrderInfo',
  usedForWorkOrder: 'usedForWorkOrderInfo'
}

export const isDisabled = data => !data.dataId

export const disabledWhenNoWarehouse = data => !data.warehouseId

export const isWarehouseBinEnabled = data => data.isWarehouseBinEnabled

export const withToggleAddMode = bool => (state, _) =>
  boolFlip(bool)('addMode')(state, _)

export const clearSelectedSerial = () => (state, _) =>
  setIn(state, 'fields.selectedSerialNumber.value', '')

// export const toggleIsEditing = bool => (state, _) =>
//   boolFlip(bool)('isEditing')(state, _)