import React from 'react'
import { component, options } from 'pages/PurchaseOrderInquiry'
import TooltipTemplate from 'components/BaseTooltipTemplate'
import behaviors from './behaviors'

// import customContextMenuItems from './CustomContextMenuItems'
import { getTooltip } from '../IndexSearch/actions'
import createSearch from '../createSearch'

const PurchaseOrderInquirySearch = createSearch({
  // customContextMenuItems,
  name: 'PurchaseOrderInquirySearch',
  behaviors,
  form: {
    component,
    options
  },
  popoverStyle: {
    maxWidth: '100%',
    width: 1300
  },
  tooltip: {
    component: TooltipTemplate,
    // keyToActionMap,
    networkRequestPromise: getTooltip,
    style: { width: 400 },
    requestArgs() {
      console.log(this)
      return {
        apiName: 'getPurchaseOrderTooltip',
        propertyName: this.props.propertyName,
        dataId: this.props.recordName || this.props.value
      }
    }
  }
})

export default PurchaseOrderInquirySearch
