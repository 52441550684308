import React, { memo, useState } from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import {
  AppBar,
  Paper,
  Tabs,
  // Tab,
  Toolbar
} from '@material-ui/core'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import { generateTabs, getIn } from 'utils'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'

import '../../../styles/master-style.scss'

export const comp = ({ dispatch, form, messagingTab }) => {
  const [tab, setTab] = useState(messagingTab)

  function handleTabChange(e, val) {
    setTab(val)
    /* store the tab state in Redux */
    dispatch(
      storeUIFeatureState(form, {
        feature: 'messagingTab',
        featureState: val
      })
    )
  }

  return (
    <div className="top-tabs-wrapper" style={{ marginBottom: 10 }}>
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            minHeight: 35,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            // variant="scrollable"
          >
            {generateTabs([
              'Special Instructions',
              'Invoice Message',
              'Internal Notes'
            ])}
          </Tabs>
        </Toolbar>
      </AppBar>

      <Paper square style={{ padding: 15 }}>
        {tab === 'Special Instructions' && (
          <>
            <div className="flex-wrapper">
              <div style={{ width: 150 }}>
                <DDIIndexSearch
                  placeholder="Search..."
                  propertyName="specialInstructionsId"
                  metaKey="Special Instructions"
                  width="100%"
                  fullWidth
                />
              </div>
            </div>

                      <div className="flex-wrapper">
                          <DDITextField
                              propertyName="specialInstructions"
                              fullWidth
                              multiline
                              rows={5}
                              variant="outlined"
              />
            </div>
          </>
        )}

        {tab === 'Invoice Message' && (
          <DDITextField
            propertyName="invoiceMessage"
            fullWidth
            multiline
            rows={5}
            variant="outlined"
          />
        )}

        {tab === 'Internal Notes' && (
          <DDITextField
            propertyName="internalNotes"
            fullWidth
            multiline
            rows={5}
            variant="outlined"
          />
        )}
      </Paper>
    </div>
  )
}

/* fixed 9/24/2020 SVE */
const contextObj = {
  messagingTab: form => getIn(form, 'ui.messagingTab') || 'Special Instructions'
}

export default memo(withContext(contextObj)(comp))
