import React, { Component } from 'react'
import { Icon, IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { getPaymentProfilesUri } from '../actions'

const StyledDiv = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`
const style = { color: '#006A4E'}

export class AddCardGridCell extends Component {
  addCard = () => {
    this.props.tryAddCard(this.props.data)
  }

  render() {
    return (
      <StyledDiv>
        <IconButton onClick={this.addCard}>
          <Icon style={style}>add_box</Icon>
        </IconButton>
      </StyledDiv>
    )
  }
}

export default connect((state, props) => {
  // console.log(state, props)
  return {
    tryAddCard: args =>
      props.dispatch(getPaymentProfilesUri.try(props.form, args))
  }
})(AddCardGridCell)
// export default AddCardGridCell
