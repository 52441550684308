import React from 'react'
import { withContext } from 'ddiForm'
import { DDITextField, DDIToggle } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { emailAddress } from 'ddiForm/validations'
import { getIn } from 'utils'
import { twoColFieldWrapperStyle } from '../../../utils'
import '../../../styles/form-styles.scss'

const AdditionalInformation = ({ dataId }) => {
  return (
    <DDICardWrapper
      title="Additional Information"
      contentStyle={{ padding: 10 }}
    >
      <div className="vendor-master-form-outer-wrapper">
        <div className="vendor-master-form-row-wrapper">
          <DDITextField
            propertyName="email"
            label="Email Address"
            mask="email"
            validate={emailAddress}
            wrapperStyle={twoColFieldWrapperStyle}
            linkToSendDocument
            sendDocumentArgs={{
              parentId: dataId,
              sendType: 'Email',
              parentType: 'V'
            }}
          />
          <DDITextField
            propertyName="websiteAddress"
            label="Website"
            mask="url"
            wrapperStyle={twoColFieldWrapperStyle}
          />
        </div>
      </div>
      <AssociatedFieldsWrapper
        style={{
          clear: 'none',
          float: 'none',
          width: '100%',
          padding: 0
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <DDITextField
            propertyName="fax"
            label="Fax Number"
            mask="phoneNumber"
            linkToSendDocument
            sendDocumentArgs={{
              parentId: dataId,
              sendType: 'Fax',
              parentType: 'V'
            }}
            wrapperStyle={{ flexBasis: '50%', padding: 15 }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexBasis: '50%'
            }}
          >
            <DDIToggle
              propertyName="faxInclude1BeforeAreaCode"
              label="Include One"
            />
            <DDIToggle propertyName="faxIncludeAreaCode" label="Area Code" />
          </div>
        </div>
      </AssociatedFieldsWrapper>
    </DDICardWrapper>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || null
})(AdditionalInformation)
