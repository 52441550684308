import { createAction, createAsyncAction } from 'utils'
import {
  OPEN_SCREEN,
  TRY_OPEN_SCREEN,
  HANDLE_ROWS_PER_PAGE_MODAL_INTERACTION
} from './constants'

export const openScreenAttempt = payload =>
  createAction(OPEN_SCREEN, payload, null, false, {
    withAccess: true,
    apiRequest: true,
    thunk: true
  })
export const openScreen = (...args) => {
  return createAction(TRY_OPEN_SCREEN, ...args)
}

export const handleRowsPerPageModalInteraction = (
  form,
  { interaction, ...rest }
) =>
  createAction(
    HANDLE_ROWS_PER_PAGE_MODAL_INTERACTION,
    { interaction, ...rest },
    form
  )
