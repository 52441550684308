import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { findDOMNode } from 'react-dom'
import shortid from 'shortid'
import {
  Icon,
  IconButton,
  MenuItem,
  Popover,
  TextField
} from '@material-ui/core'
import { getIn } from 'utils'
import { isDisabled, updateGridCellData } from '../utils'

const mapStateToProps = (state, ownProps) => {
  const { form, propertyName, valueField } = ownProps

  let rowData = getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`)
  rowData = rowData && rowData.toJS ? rowData.toJS() : []

  const items = rowData.reduce((acc, next) => {
    if (next[valueField]) {
      if (acc.findIndex(x => x.dataId === next[valueField]) === -1) {
        acc = acc.concat({
          dataId: next[valueField],
          description: next[valueField]
        })
      }
    }
    return acc
  }, [])

  return {
    menuItems: items.length
      ? items.sort((a, b) => {
          if (a.dataId < b.dataId) {
            return -1
          }
          if (a.dataId > b.dataId) {
            return 1
          }
          return 0
        })
      : [],
    rowData
  }
}

class DynamicDropdown extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
    propertyName: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    rowData: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      value: props.value || ''
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this.anchor)
  }

  onChange = event => {
    this.setState({ value: event.target.value })
  }

  onKeyDown = event => {
    if (this.state.value && event.key === 'Tab') {
      this.updateGridCellData('value', true, false)
    }
  }

  selectMenuItem = ({ value }) =>
    this.setState({ value, isOpen: false }, () => this.updateGridCellData())

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { disabledFn, formatter }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return this.isDisabled()
  }

  render() {
    const { disabled, menuItems } = this.props
    const { isOpen, value } = this.state
    const isFieldDisabled = this.isFieldDisabled()

    return (
      <div style={{ position: 'relative', height: '100%', width: '100%' }}>
        <div
          style={{ position: 'absolute', left: 0, bottom: 0, width: '100%' }}
        >
          <TextField
            disabled={isFieldDisabled}
            value={value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            fullWidth
            inputRef={c => (this.anchor = c)}
            inputProps={{
              style: {
                padding: '0 20px 5px 10px'
              }
            }}
          />
        </div>
        <IconButton
          onClick={() => this.setState({ isOpen: true })}
          style={{
            padding: 5,
            position: 'absolute',
            right: 0,
            top: -2,
            zIndex: 99
          }}
          tabIndex={-1}
          disabled={isFieldDisabled}
        >
          <Icon style={{ color: disabled ? '#999' : '#444' }}>
            arrow_drop_down
          </Icon>
        </IconButton>
        <Popover
          anchorEl={this.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          open={isOpen}
          onClose={() => this.setState({ isOpen: false })}
          style={{ width: 300 }}
        >
          {menuItems.map((item, idx) => (
            <MenuItem
              value={item.dataId}
              key={shortid.generate()}
              onClick={() => this.selectMenuItem({ value: item.dataId })}
            >
              {item.description}
            </MenuItem>
          ))}
        </Popover>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DynamicDropdown)
