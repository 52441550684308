import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { GridField, withContext } from 'ddiForm'
import { addScreenInModal } from 'ddiForm/actions'
import DeleteGridCell from 'pages/CustomerMaster/components/DeleteGridCell'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'
import { getIn, layoutFlex } from 'utils'
import { commonFormatGridCells } from 'pages/CustomerMaster/utils'
import { onAddContactProcess, onAddError } from 'pages/CustomerMaster/sagas'
import {
  CustomerAddContact,
  VendorAddContact,
  VendorMasterAddContactFromModal
} from 'pages/ContactMaster'
import { api } from 'services'
import SendDocumentGrid from 'pages/CustomerMaster/components/SendDocumentGrid'
import withDimensions from 'hoc/withDimensions'

import HighAccessCell from './components/HighAccessCell'

const dynamicColumnDefs = props => {
  const colDefs = [
    {
      cellRendererFramework: GridLinkCell,
      field: 'description',
      headerName: 'Name',
      form: props.form,
      linkTo: props.type,
      pinned: 'left',
      lockPinned: true,
      valueFormatter: params => {
        if (params.data && params.data.firstName && params.data.lastName) {
          return `${params.data.firstName} ${params.data.lastName}`
        }
        if (params.data && params.data.firstName) {
          return params.data.firstName
        }
        return null
      }
    },
    {
      field: 'jobTitle',
      headerName: 'Title',
      lockPinned: true,
      maxWidth: 200,
      tooltipField: 'jobTitle'
    },
    {
      cellClass: 'cell-value-hidden text-center',
      cellRendererFramework: HighAccessCell,
      field: 'highAccessLevelRequired',
      headerName: 'High Access',
      lockPinned: true,
      headerClass: 'text-center'
    },
    {
      cellRendererFramework: SendDocumentGrid,
      field: 'fax',
      headerName: 'Fax',
      lockPinned: true,
      sendType: 'Fax',
      form: props.form,
      dispatch: props.dispatch
    },
    { field: 'phone1', headerName: 'Phone', lockPinned: true },
    { field: 'phoneExtension', headerName: 'Ext', lockPinned: true },
    { field: 'phone2', headerName: 'Mobile', lockPinned: true },
    {
      cellRendererFramework: SendDocumentGrid,
      field: 'email',
      headerName: 'Email',
      lockPinned: true,
      sendType: 'Email',
      form: props.form,
      dispatch: props.dispatch
    },
    { field: 'defaultSendTo', headerName: 'Def Send To', lockPinned: true },
    { field: 'addressLine1', headerName: 'Address', lockPinned: true },
    { field: 'addressLine2', headerName: 'Line 2', lockPinned: true },
    { field: 'city', headerName: 'City', lockPinned: true },
    { field: 'state', headerName: 'State', lockPinned: true },
    { field: 'zipCode', headerName: 'Zip Code', lockPinned: true },
    { field: 'country', headerName: 'Country', lockPinned: true },
    {
      field: 'dataId',
      headerName: '',
      cellRendererFramework: DeleteGridCell,
      form: props.form,
      pinned: 'right',
      lockPinned: true,
      filter: false,
      width: 50,
      type: 'contact'
    }
  ]

  return commonFormatGridCells(colDefs)
}

const getRowNodeId = data => data.dataId

const emptyRow = {
  rowId: 'blankRow',
  dataId: null,
  jobTitle: ''
}

const getParentType = form => {
  if (form && form.match(/customerMaster/)) {
    return 'C'
  }

  if (form && form.match(/vendorMaster/)) {
    return 'V'
  }

  return 'C'
}

const getScreenForm = form => {
  if (form && form.match(/customerMaster/)) {
    return CustomerAddContact
  }

  if (form && form === 'productMaster.vendorMaster') {
    return VendorMasterAddContactFromModal
  }

  if (form && form.match(/vendorMaster/)) {
    return VendorAddContact
  }

  return CustomerAddContact
}

const Contacts = ({ height, isEditing, form, dispatch }) => {
  const addNewContact = debounce(() => {
    dispatch(
      addScreenInModal(form, {
        screen: getScreenForm(form),
        apiMethod: api.createContact,
        formName: `${form}.contact`,
        apiArgs: {
          groupNames: ['setup'],
          parentType: getParentType(form),
          parentId: formState => getIn(formState, 'fields.dataId.value') || ''
        },
        onApiSuccess: onAddContactProcess,
        onApiError: onAddError,
        modalOptions: {
          maxSize: true,
          hideActions: true,
          title: 'New Contact'
        }
      })
    )
  }, 300)

  return (
    <div
      className="contacts-grid-wrapper"
      style={layoutFlex('120rem', 'column', 'wrap')}
    >
      <div>
        <GridField
          autoSize
          propertyName="contacts"
          getRowNodeId={getRowNodeId}
          columnDefs={dynamicColumnDefs({
            dispatch,
            form,
            type: 'contacts'
          })}
          emptyRow={emptyRow}
          enableSorting
          width="100%"
          height={height}
          // ref={el => (this.grid = el)}
          title="Contacts"
          headerStyle={{
            background: '#e1e3e4',
            color: '#444',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '17px',
            margin: 0,
            padding: '5px 0',
            textAlign: 'center',
            width: '100%'
          }}
          addButtonText="Add Contact"
          addBlankRow={addNewContact}
          showAddButtonIfHasRecord={form !== 'productMaster.vendorMaster'}
        />
      </div>
    </div>
  )
}

Contacts.propTypes = {
  height: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired
}

const contextObj = {
  isEditing: form => form.get('isEditing') || false
}

const connectedContacts = withContext(contextObj)(Contacts)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  connectedContacts
)
