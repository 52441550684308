import { createAsyncRequestTypes } from 'utils'
// import { BASE_INFORM_API_URL } from 'services/constants'

export const productMaster = 'productMaster'
export const PRODUCT_MASTER = '@@PRODUCT_MASTER@@/'

export const GET_ANALYSIS_META = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_ANALYSIS_META`
)

export const GET_ANALYSIS_READ = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_ANALYSIS_READ`
)

export const UPDATE_TRANSACTIONS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/UPDATE_TRANSACTIONS`
)

export const GET_DOCSPEC = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_DOCSPEC`
)

export const GET_PURCHASE_HISTORY = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_PURCHASE_HISTORY`
)

export const GET_SDS = createAsyncRequestTypes(`${PRODUCT_MASTER}/GET_SDS`)

export const OPEN_REPORT_VIEWER = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/OPEN_REPORT_VIEWER`
)

export const OPEN_PURCHASE_HISTORY = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/OPEN_PURCHASE_HISTORY`
)

export const GET_BINS = createAsyncRequestTypes(`${PRODUCT_MASTER}/GET_BINS`)

export const OPEN_FIFO_COST = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_FIFO_COST`
)

export const GET_TAB_DATA = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/GET_TAB_DATA`
)

export const CALCULATE_ROLLUP_PRICE = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/CALCULATE_ROLLUP_PRICE`
)

export const SET_ROLLUP_AMOUNT = `${PRODUCT_MASTER}/SET_ROLLUP_AMOUNT`

export const SHOW_PRICE_GP = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/SHOW_PRICING_GP`
)

export const SET_SHOW_PRICE_GP = `${PRODUCT_MASTER}/SET_SHOW_PRICE_GP`

export const UOM_GRID_DND_UPDATE = `${PRODUCT_MASTER}/UOM_GRID_DND_UPDATE`

export const UPDATE_PRICES = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/UPDATE_PRICES`
)

export const SAVE_INDEX_SEARCH_DESCRIPTION = `${PRODUCT_MASTER}SAVE_INDEX_SEARCH_DESCRIPTION`
export const ENABLE_FORECAST_FREEZE = `${PRODUCT_MASTER}ENABLE_FORECAST_FREEZE`

export const READ_DATA_HELPER = `${PRODUCT_MASTER}READ_DATA_HELPER`
export const HANDLE_BIN_DATA = `${PRODUCT_MASTER}HANDLE_BIN_DATA`
export const ENABLE_BINS_GRID = `${PRODUCT_MASTER}ENABLE_BINS_GRID`
export const HANDLE_ANALYSIS_BIN_DATA = `${PRODUCT_MASTER}HANDLE_ANALYSIS_BIN_DATA`

export const CHANGE_PRICE_OR_COST = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/CHANGE_PRICE_OR_COST`
)

export const FIELD_UPDATE = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/FIELD_UPDATE`
)

export const CHANGE_ROLLUP = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/CHANGE_ROLLUP`
)

export const CLEAR_ROLLUP_CALCULATION_ERROR = `${PRODUCT_MASTER}CLEAR_ROLLUP_CALCULATION_ERROR`

export const SAVE_PRODUCT_ASSEMBLY_NOTES = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/SAVE_PRODUCT_ASSEMBLY_NOTES`
)

export const OPEN_PRODUCT_SCREEN = `${PRODUCT_MASTER}/OPEN_PRODUCT_SCREEN`
export const OPEN_PRODUCT_SCREEN_FROM_MODAL = `${PRODUCT_MASTER}/OPEN_PRODUCT_SCREEN_FROM_MODAL`
export const VALIDATE_UOMS = `${PRODUCT_MASTER}VALIDATE_UOMS`

export const SET_PRIMARY_VENDOR = `${PRODUCT_MASTER}SET_PRIMARY_VENDOR`

export const SET_SELECTED_GROUP = `${PRODUCT_MASTER}/SET_SELECTED_GROUP`

export const ADD_NEW_GROUP = `${PRODUCT_MASTER}/ADD_NEW_GROUP`

export const SAVE_PRODUCT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}/SAVE_PRODUCT`
)

export const OPEN_WEB_DESCRIPTION_EDITOR = `${PRODUCT_MASTER}OPEN_WEB_DESCRIPTION_EDITOR`

export const TOGGLE_CHECK_BOX = `${PRODUCT_MASTER}/TOGGLE_CHECK_BOX`

export const DELETE_PRODUCT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}DELETE_PRODUCT`
)

export const TOGGLE_ALL_FIELDS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}TOGGLE_ALL_FIELDS`
)

export const CREATE_NEW_PRODUCT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}CREATE_NEW_PRODUCT`
)

export const SET_NEW_MODE = `${PRODUCT_MASTER}SET_NEW_MODE`

export const GET_PRODUCT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}GET_PRODUCT`
)

export const CANCEL_CREATE = createAsyncRequestTypes(
  `${PRODUCT_MASTER}CANCEL_CREATE`
)

export const SET_PROPERTY_VALUE = `${PRODUCT_MASTER}SET_PROPERTY_VALUE`
export const SET_PROPERTY_DESCRIPTION = `${PRODUCT_MASTER}SET_PROPERTY_DESCRIPTION`

export const GET_PRODUCT_UOM = createAsyncRequestTypes(
  `${PRODUCT_MASTER}GET_PRODUCT_UOM`
)

export const OPEN_COPY_PRODUCT_MODAL = createAsyncRequestTypes(
  `${PRODUCT_MASTER}OPEN_COPY_PRODUCT_MODAL`
)

export const UPDATE_COPY_FROM_SEARCH_DESCRIPTIONS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}UPDATE_COPY_FROM_SEARCH_DESCRIPTIONS`
)

export const SET_COPY_FROM = createAsyncRequestTypes(
  `${PRODUCT_MASTER}SET_COPY_FROM`
)

export const CANCEL_COPY_FROM = `${PRODUCT_MASTER}CANCEL_COPY_FROM`

export const LAUNCH_BIN_SEARCH_MODAL = `${PRODUCT_MASTER}LAUNCH_BIN_SEARCH_MODAL`
export const SET_PRIMARY_BIN = `${PRODUCT_MASTER}SET_PRIMARY_BIN`
export const CANCEL_SET_PRIMARY_BIN = `${PRODUCT_MASTER}CANCEL_SET_PRIMARY_BIN`
export const SET_PRIMARY_BIN_CHECKBOX = `${PRODUCT_MASTER}SET_PRIMARY_BIN_CHECKBOX`

export const SAVE_VENDORS_DATA = createAsyncRequestTypes(
  `${PRODUCT_MASTER}SAVE_VENDORS_DATA`
)

export const SET_UOM_DEFAULTS = `${PRODUCT_MASTER}SET_UOM_DEFAULTS`
export const LAUNCH_ANALYSIS_BINS_EDITOR = `${PRODUCT_MASTER}LAUNCH_ANALYSIS_BINS_EDITOR`
export const SHOW_CANNOT_CHANGE_PRIMARY_BIN_MODAL = `${PRODUCT_MASTER}SHOW_CANNOT_CHANGE_PRIMARY_BIN_MODAL`
export const UPDATE_ROW_DATA = `${PRODUCT_MASTER}/UPDATE_ROW_DATA`

export const VALIDATE_BINS = `${PRODUCT_MASTER}VALIDATE_BINS`

export const GET_DEMAND = createAsyncRequestTypes(`${PRODUCT_MASTER}GET_DEMAND`)

export const UPDATE_DEMAND = createAsyncRequestTypes(
  `${PRODUCT_MASTER}UPDATE_DEMAND`
)

export const CLEAR_UPDATE_DEMAND_MODAL = `${PRODUCT_MASTER}CLEAR_UPDATE_DEMAND_MODAL`

/* for Inventory Bin Assignment Modal */
export const LOCK_INVENTORY_BIN_ASSIGNMENT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}LOCK_INVENTORY_BIN_ASSIGNMENT`
)

export const UNLOCK_INVENTORY_BIN_ASSIGNMENT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}UNLOCK_INVENTORY_BIN_ASSIGNMENT`
)

export const SAVE_INVENTORY_BIN_ASSIGNMENT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}SAVE_INVENTORY_BIN_ASSIGNMENT`
)

export const CHANGE_INVENTORY_BIN_ASSIGNMENT = createAsyncRequestTypes(
  `${PRODUCT_MASTER}CHANGE_INVENTORY_BIN_ASSIGNMENT`
)

export const SET_PRIMARY_ASSIGNMENT_BIN = `${PRODUCT_MASTER}SET_PRIMARY_ASSIGNMENT_BIN`

export const UPDATE_ANALYSIS_BINS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}UPDATE_ANALYSIS_BINS`
)

export const CANCEL_UPDATE_ANALYSIS_BINS = `${PRODUCT_MASTER}CANCEL_UPDATE_ANALYSIS_BINS`

export const LOAD_CUSTOMER_RECORD = `${PRODUCT_MASTER}LOAD_CUSTOMER_RECORD`

export const VALIDATE_IS_KIT_PRODUCTION = createAsyncRequestTypes(
  `${PRODUCT_MASTER}VALIDATE_IS_KIT_PRODUCTION`
)

export const GET_UOM_FOR_PRODUCT_GRID = createAsyncRequestTypes(
  `${PRODUCT_MASTER}GET_UOM_FOR_PRODUCT_GRID`
)

export const LAUNCH_NOTES_MODAL = `${PRODUCT_MASTER}LAUNCH_NOTES_MODAL`

export const CLEAR_CUSTOMER_PRICING_FIELDS = `${PRODUCT_MASTER}CLEAR_CUSTOMER_PRICING_FIELDS`

export const CLEAR_VENDORS_IS_PENDING_FLAG = `${PRODUCT_MASTER}CLEAR_VENDORS_IS_PENDING_FLAG`

export const VALIDATE_WAREHOUSE_REPLENISHMENTS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}VALIDATE_WAREHOUSE_REPLENISHMENTS`
)

export const EXIT_INVENTORY_BIN_ASSIGNMENT_EDITOR = `${PRODUCT_MASTER}EXIT_INVENTORY_BIN_ASSIGNMENT_EDITOR`

export const GET_PRODUCT_FIELDS = createAsyncRequestTypes(
  `${PRODUCT_MASTER}GET_PRODUCT_FIELDS`
)

export const REMOVE_TRACKED_TABS = `${PRODUCT_MASTER}REMOVE_TRACKED_TABS`

export const UPDATE_EDITED_FIELDS = `${PRODUCT_MASTER}UPDATE_EDITED_FIELDS`

export const CANCEL_PRE_NEW_MODE = `${PRODUCT_MASTER}CANCEL_PRE_NEW_MODE`

export const SET_AUTO_ADD_TO_SO = `${PRODUCT_MASTER}SET_AUTO_ADD_TO_SO`

export const ON_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${PRODUCT_MASTER}ON_PROPERTY_CHANGE`
)

export const GET_INVENTORY_DATA = createAsyncRequestTypes(
  `${PRODUCT_MASTER}GET_INVENTORY_DATA`
)

export const SET_SELECTED_INVENTORY_TAB = `${PRODUCT_MASTER}SET_SELECTED_INVENTORY_TAB`

export const EXIT_SCREEN_FROM_MODAL = `${PRODUCT_MASTER}EXIT_SCREEN_FROM_MODAL`
