import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'inventoryAdjustmentReport'

export const runInventoryAdjustmentReport = ({ properties }) => {
    const options = createApiOptions({
        body: { ...properties },
        method: 'post'
    })

    return callFetch(`${BASE_INFORM_API_URL}/${screen}/run`, options)
}

export const closeInventoryAdjustmentReport = ({ dataId }) => {
    const options = createApiOptions({
        body: {},
        method: 'post'
    })

    return callFetch(`${BASE_INFORM_API_URL}/${screen}/close`, options)
}
