import memoize from 'memoize-one'
import IndexSearchRenderer from 'pages/SalesOrder/components/ManualGrid/IndexSearchRenderer'
import ToggleCheckboxCell from 'pages/SalesOrder/components/ManualGrid/ToggleCheckboxCell'
import DropdownArrow from 'pages/SalesOrder/components/ManualGrid/DropdownArrow'
import { BASE_INFORM_URL } from 'services/constants'
import * as localService from 'services/local'

import {
  is,
  plainDeepEqual,
  formatDollarFields,
  formatNumber,
  noop
} from 'utils'

const editableOnlyWithProductSelected = params => {
  const { data: { dataId } = {} } = params
  return !!dataId
}

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

const cellStyle = params => {
  if (is.number(params.value) && params.value < 0) {
    return { color: '#d9534f', 'text-align': 'right' }
  }

  return { 'text-align': 'right', color: '#000' }
}

export default memoize(
  ({
    propertyName,
    form,
    hideToggle,
    showCart = false,
    inquiryMode,
    editorParams = {},
    textEditor = {},
    editableIfCanChangeQuantityNoInquiryMode,
    dispatch,
    customerId,
    isEditing = false
  }) => {
    const checkBoxDisabled = p => !inquiryMode && !p.data.dataId
    const standardEditableCellParams = {
      cellEditor: 'textFieldEditor',
      editable: isEditing,
      cellEditorParams: {
        ...editorParams,
        textAlign: 'right',
        formatter: 'number',
        propertyName,
        fixedDecimalScale: true,
        decimalScale: 4
      }
    }

    let columnDefs = [
      {
        field: 'lineNumber',
        colId: 'lineNumber',
        headerName: 'lineNumber',
        filter: false,
        cellStyle: { 'text-align': 'left' },
        minWidth: 50,
        maxWidth: 100,
        pinned: 'left',
        lockPinned: true,
        suppressMenu: true,
        headerValueGetter: () => 'Ln',
        initialHide: true
      },
      {
        cellRenderer: IndexSearchRenderer,
        cellEditor: 'indexSearchEditor',
        cellClass: 'line-items-product',
        cellEditorParams: {
          ...editorParams,
          linkTo: 'productMaster',
          additionalOpenScreenParams: ['productAnalysis'],
          dispatch,
          form,
          propertyName,
          hideToggle,
          tabStop: true,
          disableAdvancedParsing: true,
          showCart,
          customerId
        },
        cellRendererParams: {
          ...editorParams,
          linkTo: 'productMaster',
          additionalOpenScreenParams: ['productAnalysis'],
          form,
          dispatch
        },
        field: 'dataId',
        colId: 'dataId',
        headerName: 'Component Number',
        minWidth: 150,
        maxWidth: 200,
        pinned: 'left',
        lockPinned: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        editable: p => !inquiryMode && !p.data.dataId
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Component Description',
        minWidth: 150,
        maxWidth: 200,
        pinned: 'left',
        lockPinned: true,
        suppressMenu: true,
        tabStop: true,
        editable: false
      },
      {
        cellStyle,
        ...textEditor,
        cellEditorParams: {
          /* 
        note this is using a string text editor now to allow 
        characters like 'B' and 'C' -- SVE 12/17/2021
      */
          ...textEditor.cellEditorParams,
          textAlign: 'right',
          notifyExitTextCell: true,
          propertyName,
          tabStop: true
          // onBlur: (e, data, isCancelAfterEndFired) => {
          //   if (
          //     this.gridApi &&
          //     data?.dataId &&
          //     !this.props.modals &&
          //     !isCancelAfterEndFired
          //   ) {
          //     this.gridApi.stopEditing()
          //   }
          // }
        },
        field: 'quantityExtended',
        colId: 'quantityExtended',
        tooltipComponent: 'customTooltip',
        headerName: 'Quant Per',
        minWidth: 100,
        maxWidth: 200,
        suppressMenu: true,
        editable: !inquiryMode,
        pinned: 'left',
        lockPinned: true
      },
      {
        field: 'uomId',
        colId: 'uomId',
        headerName: 'Quant UM',
        pinned: 'left',
        lockPinned: true,
        suppressNavigable: true
      },
      {
        cellRenderer: 'removeLineItemCell',
        field:
          'canDelete' /* discovered this needs to be here in this minor version of ag-grid or the cell renderer data will not update disabled state -- SVE 3/5/2021 */,
        colId: 'removeLineItem',
        cellRendererParams: {
          ...editorParams,
          propertyName
        },
        maxWidth: 35,
        minWidth: 35,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        width: 35,
        suppressMenu: true,
        headerName: 'Remove Line Item',
        headerClass: 'hide-header-text',
        pinned: 'left',
        lockPinned: true,
        suppressNavigable: true
      },
      {
        cellStyle,
        headerClass: 'align-right',
        field: 'quantity',
        colId: 'quantity',
        headerName: 'Quant To Ship',
        minWidth: 100,
        editable: false,
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          allowNegative: false,
          propertyName,
          noDecimalLimit: true,
          maxLength: 10,
          textAlign: 'right',
          notifyExitTextCell: true,
          formatter: 'number',
          thousandSeparator: ''
        }
      },
      {
        field: 'quantityOrdered',
        colId: 'quantityOrdered',
        headerName: 'Quant Ordered',
        editable: isEditing,
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          textAlign: 'right',
          formatter: 'number',
          propertyName,
          noDecimalLimit: true
        },
        tooltipField: 'quantityOrderedTooltip',
        pinned: 'left',
        lockPinned: true,
        initialHide: true,
        ...rightAlignParams
      },
      {
        headerComponent: 'imageCellHeader',
        headerComponentParams: {
          src: `${BASE_INFORM_URL}/resources/product_master_32.png`,
          title: 'Check Stock'
        },
        headerName: 'Quantity Dropdown',
        headerClass: 'hide-header-text',
        minWidth: 50,
        maxWidth: 50,
        field: 'quantityChange',
        colId: 'quantityChange',
        cellEditor: 'quantityCellEditor',
        cellEditorParams: { ...editorParams, tabStop: true },
        cellRendererFramework: DropdownArrow,
        editable: editableOnlyWithProductSelected,
        initialHide: true
      },
      {
        field: 'purchase',
        colId: 'purchase',
        headerClass: 'text-center',
        headerName: 'Purch',
        minWidth: 50,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          isDisabled: props => {
            return checkBoxDisabled(props) || !isEditing
          }
        },
        maxWidth: 150,
        initialHide: true
      },
      {
        field: 'quantityBO',
        colId: 'quantityBO',
        headerName: 'Quant B/O',
        cellStyle,
        minWidth: 100,
        editable: false,
        ...standardEditableCellParams,
        initialHide: true
      },
      {
        field: 'quantityOpen',
        colId: 'quantityOpen',
        headerName: 'Quant Open',
        minWidth: 100,
        cellStyle: { textAlign: 'right' },
        initialHide: true
      },
      {
        field: 'quantityStaged',
        colId: 'quantityStaged',
        headerName: 'Quant Staged',
        cellStyle,
        minWidth: 100,
        initialHide: true
      },
      {
        field: 'quantityScannedDisplay',
        colId: 'quantityScannedDisplay',
        headerName: 'Quant Scanned',
        initialHide: true,
        ...rightAlignParams
        // hide: true
      },
      {
        cellClass: 'align-right',
        field: 'netCost',
        colId: 'netCost',
        headerClass: 'align-right',
        headerName: 'Net Cost',
        minWidth: 100,
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '0.0000')
      },
      {
        cellStyle,
        field: 'netPrice',
        colId: 'netPrice',
        headerClass: 'align-right',
        headerName: 'Net Price',
        headerTooltip: 'Net Price',
        tooltipComponent: 'customTooltip',
        tooltipField: 'netPriceTooltip',
        minWidth: 100,
        valueFormatter: ({ value }) => formatNumber(value, '0.0000'),
        initialHide: true,
        ...standardEditableCellParams
      },
      {
        headerComponent: 'imageCellHeader',
        headerComponentParams: {
          src: `${BASE_INFORM_URL}/resources/company_misc_charge_32.png`,
          title: 'Price Calculator'
        },
        headerName: 'Pricing Dropdown',
        headerClass: 'hide-header-text',
        minWidth: 50,
        maxWidth: 50,
        field: 'pricing',
        colId: 'pricing',
        cellEditor: 'pricingCellEditor',
        cellEditorParams: params => {
          return {
            ...editorParams,
            gridApi: params.api,
            isComponent: true,
            tabStop: true
          }
        },
        cellStyle: prms => {
          if (prms && prms.api) {
            const selected = prms.api.getSelectedRows()
            if (
              selected &&
              selected.length &&
              selected[0].data &&
              Number.isFinite(selected[0].data.lineNumber) &&
              this.props.lineNumber &&
              parseFloat(this.props.lineNumber) ===
                parseFloat(selected[0].data.lineNumber)
            ) {
              return {
                backgroundColor: '#d5edd5'
              }
            }

            return null
          }
          return null
        },
        cellRendererFramework: DropdownArrow,
        editable: editableOnlyWithProductSelected,
        suppressKeyboardEvent: params => {
          // const { value } = params.event.target
          const { editing } = params
          const { keyCode } = params.event
          if (keyCode === 9 && editing) {
            return true
          }
          return false
        },
        initialHide: true
      },

      {
        cellClass: 'align-right',
        field: 'unitAltCost',
        colId: 'unitAltCost',
        headerClass: 'align-right',
        headerName: 'Unit Cost',
        minWidth: 100,
        valueFormatter: ({ value }) => formatNumber(value, '0.0000'),
        initialHide: true,
        ...standardEditableCellParams
      },
      {
        cellClass: 'align-right',
        field: 'unitPrice',
        colId: 'unitPrice',
        headerClass: 'align-right',
        headerName: 'Unit Price',
        minWidth: 100,
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '0.0000'),
        ...standardEditableCellParams
      },
      {
        cellStyle,
        field: 'priceExtension',
        colId: 'priceExtension',
        headerClass: 'align-right',
        headerName: 'Price Extension',
        headerTooltip: 'Price Extension',
        initialHide: true,
        minWidth: 100,
        valueFormatter: formatDollarFields,
        supressNavigable: true
      },
      {
        field: 'grossProfitPercent',
        colId: 'grossProfitPercent',
        headerName: 'G/P %',
        editable: isEditing,
        valueFormatter: ({ value }) => formatNumber(value, '0.00'),
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          textAlign: 'right',
          formatter: 'number',
          propertyName,
          fixedDecimalScale: true,
          decimalScale: 2
        },
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'markupPercent',
        colId: 'markupPercent',
        headerName: 'Markup %',
        editable: isEditing,
        valueFormatter: ({ value }) => formatNumber(value, '0.00'),
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          textAlign: 'right',
          formatter: 'number',
          propertyName,
          fixedDecimalScale: true,
          decimalScale: 2
        },
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'grossProfitDollars',
        colId: 'grossProfitDollars',
        headerName: 'G/P $',
        valueFormatter: ({ value }) => formatNumber(value, '0.00'),
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'discountPercent',
        colId: 'discountPercent',
        headerName: 'Disc Pct',
        editable: isEditing,
        valueFormatter: ({ value }) => formatNumber(value, '0.00'),
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          textAlign: 'right',
          formatter: 'number',
          propertyName,
          fixedDecimalScale: true,
          decimalScale: 2
        },
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'multiplier',
        colId: 'multiplier',
        headerName: 'Mult',
        editable: isEditing,
        cellEditor: 'textFieldEditor',
        cellEditorParams: {
          ...editorParams,
          textAlign: 'right',
          formatter: 'number',
          propertyName,
          noDecimalLimit: true
        },
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'quantityShipped',
        colId: 'quantityShipped',
        headerName: 'Quant Shipped',
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'costExtension',
        colId: 'costExtension',
        headerName: 'Cost Extension',
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '$0.00'),
        ...rightAlignParams
      },
      {
        field: 'quantityAvailableResolved',
        colId: 'quantityAvailableResolved',
        headerName: 'Avail',
        initialHide: true,
        ...rightAlignParams
      },

      {
        field: 'shippablePriceExtensionResolved',
        colId: 'shippablePriceExtensionResolved',
        headerName: 'Shippable Extension',
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '$0.00'),
        ...rightAlignParams
      },
      {
        field: 'shippableCostExtensionResolved',
        colId: 'shippableCostExtensionResolved',
        headerName: 'Shippable Cost Extension',
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '$0.00'),
        ...rightAlignParams
      },
      {
        field: 'rank',
        colId: 'rank',
        headerName: 'Rank',
        initialHide: true
      },
      {
        field: 'leadDays',
        colId: 'leadDays',
        headerName: 'Lead Days',
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'transfers',
        colId: 'transfers',
        headerName: 'Transfers',
        initialHide: true
      },
      {
        field: 'scannedBins',
        colId: 'scannedBins',
        headerName: 'Scanned Bins',
        initialHide: true
      },

      {
        field: 'preferredBins',
        colId: 'preferredBins',
        headerName: 'Preferred Bins',
        initialHide: true
      },
      {
        field: 'priceMethodResolved',
        colId: 'priceMethodResolved',
        headerName: 'Price Formula',
        initialHide: true
      },
      {
        field: 'costMethodResolved',
        colId: 'costMethodResolved',
        headerName: 'Cost Formula',
        initialHide: true
      },
      {
        cellClass: 'align-right',
        field: 'rebateCost',
        colId: 'rebateCost',
        headerClass: 'align-right',
        headerName: 'Rebate Cost',
        minWidth: 100,
        initialHide: true,
        valueFormatter: ({ value }) => formatNumber(value, '0.0000'),
        ...standardEditableCellParams
      },
      {
        field: 'rebateCostMethod',
        colId: 'rebateCostMethod',
        headerName: 'Rebate Cost Formula',
        initialHide: true
      },
      {
        field: 'rebateGrossProfitDollars',
        colId: 'rebateGrossProfitDollars',
        headerName: 'Rebate G/P $',
        cellStyle,
        valueFormatter: ({ value }) => formatNumber(value, '0.00'),
        initialHide: true,
        ...rightAlignParams
      },
      {
        field: 'costOverride',
        colId: 'costOverride',
        headerClass: 'text-center',
        headerName: 'Cst Ovr',
        minWidth: 50,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          isDisabled: true
        },
        maxWidth: 150,
        initialHide: true
      },
      {
        field: 'priceOverride',
        colId: 'priceOverride',
        headerClass: 'text-center',
        headerName: 'Pr Ovr',
        minWidth: 50,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          isDisabled: true
        },
        maxWidth: 150,
        initialHide: true
      }
    ]

    let visibleColumns = localService.get('componentsGrid')
    visibleColumns = JSON.parse(visibleColumns)

    columnDefs = columnDefs.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        initialHide: next.hasOwnProperty('initialHide')
          ? !visibleColumns?.includes(next.colId)
          : false,
        minWidth: next?.minWidth ? next.minWidth : 125,
        isComponent: true
      })

      return acc
    }, [])

    console.log('getComponentsColumnDefinitions', columnDefs)
    return columnDefs
  },
  plainDeepEqual
)
