import React from 'react'
import { ddiForm } from 'ddiForm'
import { Icon } from '@material-ui/core'
import MeasuredModal from 'components/MasterScreen/MeasuredModal'

import Activities from 'components/MasterScreen/Activities'
import Attachments from 'components/MasterScreen/Attachments'
import Shipments from 'pages/SalesOrder/tabs/Shipments'

// import Order from 'pages/SalesOrder/tabs/Order'
import memoize from 'memoize-one'

import { readInvoiceInquiry } from './api'
import invoiceInquirySagas, {
  getRecordArgumentsSaga,
  getDataByTab
} from './sagas'
import behaviors from './behaviors'
import SearchArea from './components/SearchArea'
import LeftFooterButtons from './components/LeftFooterButtons'
import RightFooterButtons from './components/RightFooterButtons'
import Order from './tabs/Order'
import Invoicing from './tabs/Invoicing'
import { mapGetRecordResponse } from './utils'
import './styles/css-mod-ignore.scss'

const tabIconStyle = {
  fontSize: 18,
  marginBottom: 0,
  marginRight: 5,
  verticalAlign: 'middle'
}

const options = {
  form: 'invoiceInquiry',
  title: 'Invoice Inquiry',
  sagas: {
    onInit: invoiceInquirySagas
  },
  masterOptions: {
    getRecordArgumentsSaga,
    headerComponent: SearchArea,
    hideReportButton: true,
    disableDoubleClickUnlock: true,
    footer: {
      left: LeftFooterButtons,
      right: RightFooterButtons
    },
    tabs: [
      {
        icon: <Icon style={tabIconStyle}>list</Icon>,
        component: Order,
        title: 'Order',
        access: 'detail',
        mapResponse: mapGetRecordResponse
      },
      {
        icon: <Icon style={tabIconStyle}>payment</Icon>,
        component: Invoicing,
        title: 'Invoicing',
        access: 'final',
        mapResponse: mapGetRecordResponse
      },
      {
        icon: <Icon style={tabIconStyle}>local_shipping</Icon>,
        component: Shipments,
        title: 'Shipments',
        access: 'shipments',
        mapResponse: mapGetRecordResponse
      },
      {
        component: Activities,
        icon: <Icon style={tabIconStyle}>event</Icon>,
        title: 'Activities',
        access: 'activities',
        mapResponse: mapGetRecordResponse
      },
      {
        access: 'attachments',
        component: Attachments,
        icon: <Icon style={tabIconStyle}>attachment</Icon>,
        title: 'Attachments',
        mapResponse: mapGetRecordResponse
      }
    ]
  },
  behaviors,
  getEntityApi: readInvoiceInquiry,
  updateTitleMethod: ({ dataId }) =>
    `Invoice Inquiry${dataId ? ` - ${dataId}` : ''}`
}

export const invoiceInquiryInModal = memoize(form =>
  ddiForm({
    ...options,
    form
  })(MeasuredModal)
)

export default ddiForm(options)()
