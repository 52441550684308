import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import shortid from 'shortid'
import Grid from 'grid'
import { GridField, withContext } from 'ddiForm'
import ErrorBoundary from 'components/ErrorBoundary'
import {
  handleRowDataUpdate,
  removeEmptyGridRow
} from 'pages/VendorMaster/actions'
import { headerStyle, states as statesData } from 'pages/VendorMaster/utils'

import usePrevious from 'hooks/usePrevious'
import {
  emptyList,
  fromJS,
  is,
  getIn,
  formatNumber,
  plainDeepEqual
} from 'utils'
import DecimalCellEditor from './components/DecimalCellEditor'
import DeleteGridCell from './components/DeleteGridCell'

let gridApi = null
let columnApi = null
let _isMounted = false

const requiredCols = ['dataId', 'tax']
const emptyRow = {
  rowId: 'blankrow',
  dataId: null,
  tax: ''
}

const DropShipChargesGrid = ({ form, isEditing, rowDataList, statesList }) => {
  const propertyName = 'taxableStates'
  const dispatch = useDispatch()
  const [gridKey, resetGridKey] = useState(shortid.generate())
  const states = statesList?.toJS ? statesList.toJS() : []

  const rowData = rowDataList?.toJS ? rowDataList.toJS() : []
  const prevRowData = usePrevious(rowData)
  const prevIsEditing = usePrevious(isEditing)

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
      dispatch(removeEmptyGridRow(form, { propertyName }))
    }
  }, [])

  useEffect(() => {
    if (
      is.bool(isEditing) &&
      is.bool(prevIsEditing) &&
      isEditing !== prevIsEditing
    ) {
      resetGridKey(shortid.generate())
    }
  }, [isEditing, prevIsEditing])

  useEffect(() => {
    if (
      rowData &&
      prevRowData &&
      !plainDeepEqual(rowData, prevRowData) &&
      gridApi
    ) {
      gridApi.setRowData(rowData)
      gridApi.refreshCells({
        force: true,
        suppressFlash: true
      })
    }
  }, [rowData, prevRowData])

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  const onCellValueChanged = params => {
    if (
      ((params.newValue || params.newValue === 0) &&
        Number(params.newValue) === Number(params.oldValue)) ||
      params.newValue === params.oldValue ||
      !_isMounted
    ) {
      return
    }

    // console.log('onCellValueChanged', params)
    dispatch(
      handleRowDataUpdate(form, {
        propertyName,
        rowIndex: params.node.rowIndex,
        rowId: params?.data?.rowId,
        field: params?.colDef?.field,
        value: params.newValue,
        requiredCols,
        emptyRow,
        addNewRowOnChange: params?.colDef?.field === 'tax'
      })
    )

    params.api.tabToNextCell()
  }

  const onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column && params.column.colId
      })
    }
  }

  const columnDefs = [
    {
      colId: 'dataId',
      field: 'dataId',
      headerName: 'State',
      editable: params => {
        if (!isEditing) {
          return false
        }

        return true
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => {
        /* 
          states becomes params.data.whatever? 
          see salesmen commission modal in Invoice Inquiry
        */
        if (states && Array.isArray(states)) {
          const values = states.map(x => x.dataId)
          return {
            values,
            formatValue: value => {
              const row = states.find(x => x.dataId === value)

              return row?.description ? row.description : value
            }
          }
        }

        return { values: [] }
      },
      sortable: false,
      valueFormatter: params => {
        if (states && Array.isArray(states)) {
          const row = states.find(x => x.dataId === params.value)
          return row?.description ? row.description : params.value
        }

        return params.value
      },
      onCellValueChanged,
      minWidth: 125,
      onCellFocused
    },
    {
      colId: 'tax',
      field: 'tax',
      cellClass: 'align-right',
      headerName: 'Tax %',
      headerClass: 'align-right',
      editable: params => {
        if (!params?.data?.dataId) {
          return false
        }

        if (!isEditing) {
          return false
        }

        return true
      },
      cellEditor: 'decimalCellEditor',
      onCellValueChanged,
      onCellFocused,
      valueFormatter: params => formatNumber(params.value, '0.00')
    },
    {
      colId: 'delete',
      field: '',
      cellRendererFramework: DeleteGridCell,
      cellRendererParams: {
        propertyName,
        form,
        isEditing
      },
      editable: false,
      suppressNavigable: true,
      maxWidth: 40
    }
  ]

  return (
    <ErrorBoundary>
      <GridField
        title="States"
        headerStyle={headerStyle}
        form={form}
        height={400}
        propertyName={propertyName}
        onGridReady={onGridReady}
        getRowNodeId={data => data.rowId}
        columnDefs={columnDefs}
        emptyRow={emptyRow}
        components={{
          decimalCellEditor: DecimalCellEditor
        }}
        addButtonText="Add State"
        singleClickEdit
        showAddButtonOnlyIfEditing
        key={gridKey}
        reactUi={false}
      />
    </ErrorBoundary>
  )
}

export default withContext({
  rowDataList: form => getIn(form, 'fields.taxableStates.rowData') || emptyList,
  statesList: form => getIn(form, 'meta.states') || fromJS(statesData),
  isEditing: form => form.get('isEditing') || false
})(DropShipChargesGrid)
