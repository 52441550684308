import React from 'react'
import { getValue, emptyList, layoutFlex } from 'utils'
import { Label, withContext } from 'ddiForm'
import { DDISelectField } from 'ddiForm/wrapped'
import { InputLabel } from '@material-ui/core'
import DDICardWrapper from 'components/DDICardWrapper'

import Signature from './components/Signature'
import Picked from './components/Picked'
import Scheduled from './components/Scheduled'
import TrackingInformation from './components/TrackingInformation'
import StatusGrid from './components/StatusGrid'

import './styles/shipments.scss'

const Shipments = ({
  form,
  dispatch,
  backOrderCycle,
  releaseCycle,
  shipmentCyclesList
}) => {
  const shipmentCycles = shipmentCyclesList?.toJS
    ? shipmentCyclesList.toJS()
    : []

  return (
    <div style={layoutFlex('90rem')}>
      <div className="shipments-outer-wrapper">
        <div className="cycle-wrapper">
          <div className="left-cycle-field">
            {form === 'invoiceInquiry' ? (
              <div className="label-wrapper">
                <span className="label-bold">Cycle:</span>
                {backOrderCycle}
              </div>
            ) : (
              <>
                <InputLabel shrink>Cycle</InputLabel>
                <DDISelectField
                  fullWidth
                  propertyName="activeShipmentCycle"
                  selectValue="dataId"
                  selectDescription="dataId"
                  values={shipmentCycles}
                  preventAutoDisable
                />
              </>
            )}
          </div>
          <div className="right-cycle-field">
            <div className="label-wrapper">
              <span className="label-bold">Rel:</span>
              {releaseCycle}
            </div>
          </div>
        </div>

        <div className="shipments-inner-wrapper">
          <div className="status-grid">
            <DDICardWrapper
              title="Status"
              style={{ margin: 5, width: 'calc(100% - 10px)' }}
              contentStyle={{
                padding: 0
              }}
            >
              <StatusGrid form={form} />
            </DDICardWrapper>
          </div>
          <Signature form={form} dispatch={dispatch} />
          <div className="shipments-right-col">
            <Picked />
            <Scheduled />
          </div>
        </div>
        <TrackingInformation form={form} dispatch={dispatch} />
      </div>
    </div>
  )
}

export const contextObj = {
  backOrderCycle: form => getValue(form, 'backOrderCycle', ''),
  shipmentCyclesList: form => getValue(form, 'shipmentCycles', emptyList),
  releaseCycle: form => getValue(form, 'releaseCycle', '')
}

export default withContext(contextObj)(Shipments)
