import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from 'grid'
import memoize from 'memoize-one'
import {
  formatDollarFields,
  getIn,
  makeRowDataUnique,
  plainDeepEqual
} from 'utils'


const getRowNodeId = data => data.rowId

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  const voidedPayments = getIn(formState, `values.voidedPayments`) || []

  return {
    voidedPayments
  }
}

const columnDefs = [
  {
    field: 'paymentTypeDescription',
    headerName: 'Type',
    suppressFilter: true,
    suppressMenu: true
  },
  {
    cellStyle: { textAlign: 'right' },
    headerClass: 'align-right',
    field: 'amount',
    headerName: 'Amount',
    valueFormatter: formatDollarFields,
    suppressFilter: true,
    suppressMenu: true
  },
  {
    field: 'referenceResolved',
    headerName: 'Reference',
    suppressFilter: true,
    suppressMenu: true
  },
]

const getRowData = memoize((rowData = []) => makeRowDataUnique(rowData, 'rowId'), plainDeepEqual)

const VoidedPaymentsGrid = ({ voidedPayments }) => {

  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (voidedPayments.toJS) {
      setRowData(voidedPayments.toJS())
    } else {
      setRowData([])
    }

  }, [voidedPayments])

  const onGridReady = params => {
    if (params && params.api) {
      params.api.sizeColumnsToFit()
    }
  }


  return (
    <>
      <Grid
        getRowNodeId={getRowNodeId}
        columnDefs={columnDefs}
        rowData={getRowData(rowData)}
        onGridReady={onGridReady}
        height={150}
        suppressHorizontalScroll
      />
    </>
  )
}


export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(VoidedPaymentsGrid)