import React, { Component } from 'react'
import Grid from 'grid'

class DropdownGrid extends Component {
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.isOpen && nextProps.value !== this.props.value) {
  //     return false
  //   }

  //   return true
  // }

  render() {
    const {
      gridOptions = {},
      gridRef,
      rowSelection = 'multiple',
      onRowSelected,
      onSelectionChanged,
      rowData = [],
      suppressRowClickSelection = true,
      gridHeight,
      postProcessPopup
    } = this.props

    return (
      <div
        style={{
          maxHeight: 400,
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        <Grid
          className={gridOptions.className}
          columnDefs={gridOptions.columnDefs}
          ref={gridRef}
          rowSelection={rowSelection}
          {...gridOptions}
          onRowSelected={onRowSelected}
          onSelectionChanged={onSelectionChanged}
          rowData={rowData}
          suppressRowClickSelection={suppressRowClickSelection}
          height={gridHeight}
          postProcessPopup={postProcessPopup}
          popupParent={document.querySelector('#search-dropdown-inner-wrapper')}
          reactUi
        />
      </div>
    )
  }
}

export default DropdownGrid
