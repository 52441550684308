import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell/'
import BinSearchGridCell from 'pages/ProductMaster/components/BinSearchGridCell'

export default TooltipField({
  anchorToGridCellText: true,
  indexSearchType: 'Bins',
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(BinSearchGridCell)
