import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import PricingTable from './components/PricingTable'
import PricingToggleInterface from './components/PricingToggleInterface'
import StandaloneToggleSet from './components/StandaloneToggleSet'

const Pricing = ({
  allowCostOverride,
  canSeeCost,
  data = {},
  form,
  dispatch,
  setEdited,
  handleClose,
  priceChange = {},
  lineNumber,
  isMobile = false,
  isComponent = false
}) => {
  const {
    enableCurrentPriceCheckbox,
    currentNetPrice,
    currentUnitPrice,
    useCurrentPrice,
    enableLastPriceCheckbox,
    lastNetPrice,
    lastUnitPrice,
    useLastPrice,
    enableQuotedPriceCheckbox,
    quotedNetPrice,
    quotedUnitPrice,
    useQuotedPrice,
    enableMarginPriceCheckbox,
    marginNetPrice,
    marginUnitPrice,
    useMarginPrice,
    // lineNumber,
    quotedDate,
    quotedBy,
    lastQuantity,
    lastDate
  } = priceChange

  const toggleSwitchDisabledOnInvoiceInquiry = form === 'invoiceInquiry'
  return (
    <div style={{ padding: 10 }}>
      <Paper square>
        <PricingTable
          form={form}
          dispatch={dispatch}
          handleClose={handleClose}
          canSeeCost={canSeeCost}
          allowCostOverride={allowCostOverride}
          priceChange={priceChange}
          lineNumber={lineNumber}
          setEdited={setEdited}
          isMobile={isMobile}
          data={data}
          isComponent={isComponent}
        />
      </Paper>
      <StandaloneToggleSet
        form={form}
        dispatch={dispatch}
        lineNumber={lineNumber}
        priceChange={priceChange}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between'
        }}
      >
        <PricingToggleInterface
          propertyName="useCurrentPrice"
          disabled={
            !enableCurrentPriceCheckbox || toggleSwitchDisabledOnInvoiceInquiry
          }
          netPrice={currentNetPrice}
          unitPrice={currentUnitPrice}
          isChecked={useCurrentPrice}
          lineNumber={lineNumber}
          dispatch={dispatch}
          form={form}
          label="Current Price"
        />

        <PricingToggleInterface
          propertyName="useLastPrice"
          disabled={
            !enableLastPriceCheckbox || toggleSwitchDisabledOnInvoiceInquiry
          }
          netPrice={lastNetPrice}
          unitPrice={lastUnitPrice}
          isChecked={useLastPrice}
          lineNumber={lineNumber}
          dispatch={dispatch}
          form={form}
          date={lastDate}
          additionalData={lastQuantity}
          additionalDataKey="Qty"
          label="Last Price"
          hasAdditionalData
        />

        <PricingToggleInterface
          propertyName="useQuotedPrice"
          disabled={
            !enableQuotedPriceCheckbox || toggleSwitchDisabledOnInvoiceInquiry
          }
          netPrice={quotedNetPrice}
          unitPrice={quotedUnitPrice}
          isChecked={useQuotedPrice}
          lineNumber={lineNumber}
          dispatch={dispatch}
          form={form}
          date={quotedDate}
          additionalData={quotedBy}
          additionalDataKey="By"
          label="Quoted Price"
          hasAdditionalData
        />

        <PricingToggleInterface
          propertyName="useMarginPrice"
          disabled={
            !enableMarginPriceCheckbox || toggleSwitchDisabledOnInvoiceInquiry
          }
          netPrice={marginNetPrice}
          unitPrice={marginUnitPrice}
          isChecked={useMarginPrice}
          lineNumber={lineNumber}
          dispatch={dispatch}
          form={form}
          label="Margin Price"
        />
      </div>
    </div>
  )
}

Pricing.propTypes = {
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default Pricing
