import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, is } from 'utils'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce, noop } from 'lodash'
import { removeModal } from 'modals/actions'
import { createCustomAssembly } from '../../../../actions'

const marginRight = { marginRight: 5 }

const ActionButtons = ({
  description,
  form,
  modalId,
  lineNumber,
  onClickClearButton
}) => {
  const dispatch = useDispatch()

  const save = debounce(e => {
    dispatch(
      createCustomAssembly.try(form, {
        properties: {
          createcustomassemblyproduct: { description }
        },
        action: 'save',
        lineNumber,
        modalId
      })
    )
  }, 300)

  const cancel = debounce(e => {
    dispatch(
      createCustomAssembly.try(form, {
        properties: {
          createcustomassemblyproduct: { cancel: true }
        },
        action: 'cancel',
        lineNumber,
        modalId
      })
    )
  }, 300)

  return (
    <div
      className="sales-order-entry-modal-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 0 0 0'
      }}
    >
      <Button
        onClick={save}
        variant="contained"
        style={marginRight}
        tabIndex={-1}
        disabled={!description}
      >
        OK
      </Button>
      <Button
        onClick={onClickClearButton}
        variant="contained"
        style={marginRight}
        tabIndex={-1}
      >
        Clear
      </Button>
      <Button onClick={cancel} variant="contained" tabIndex={-1}>
        Exit
      </Button>
    </div>
  )
}

export default ActionButtons
