import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import React from 'react'
import MaskedInput from 'react-text-mask'

export default function PercentageTextField(props) {
  const {
    inputRef,
    allowDecimal,
    allowLeadingZeroes,
    allowNegative,
    // requireDecimal,
    decimalLimit,
    suffix,
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    decimalSymbol,
    integerLimit,
    requireDecimal,
    textAlign = 'right',
    style = {},
    ...other
  } = props

  const mask = createNumberMask({
    prefix: '', // props.prefix,
    allowDecimal,
    decimalLimit,
    suffix,
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    decimalSymbol,
    integerLimit,
    requireDecimal,
    allowNegative,
    allowLeadingZeroes
  })
  const onBlur = e => {
    let { value } = e.target
    value = value.replace(
      new RegExp(`[${props.thousandsSeparatorSymbol} | ${props.suffix}]`, 'g'),
      ''
    )

    const target = {
      target: {
        value
      }
    }
    props.onBlur(target)
  }
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      style={{ ...style, textAlign }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={!!props.value}
      onBlur={onBlur}
    />
  )
}

PercentageTextField.defaultProps = {
  // prefix: '', // prefix (string): what to display before the amount. Defaults to '$'.
  suffix: '%', // suffix (string): what to display after the amount. Defaults to empty string.
  includeThousandsSeparator: true, // includeThousandsSeparator (boolean): whether or not to separate thousands. Defaults to to true.
  thousandsSeparatorSymbol: ',', // thousandsSeparatorSymbol (string): character with which to separate thousands. Default to ','.
  allowDecimal: true, // allowDecimal (boolean): whether or not to allow the user to enter a fraction with the amount. Default to false.
  decimalSymbol: '.', // decimalSymbol (string): character that will act as a decimal point. Defaults to '.'
  decimalLimit: 2, // decimalLimit (number): how many digits to allow after the decimal. Defaults to 2
  integerLimit: 9, // integerLimit (number): limit the length of the integer number. Defaults to null for unlimited
  requireDecimal: false, // requireDecimal (boolean): whether or not to always include a decimal point and placeholder for decimal digits after the integer. Defaults to false.
  allowNegative: false, // allowNegative (boolean): whether or not to allow negative numbers. Defaults to false
  allowLeadingZeroes: false // allowLeadingZeroes (boolean): whether or not to allow leading zeroes. Defaults to false
}
