/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { noop, getIn } from 'utils'
import IndexSearch from 'components/Search/IndexSearch'
import * as actions from 'components/Search/IndexSearch/actions'

const mapStateToProps = (state, ownProps) => ({
  isEditing: getIn(state, `ddiForm.${ownProps.form}.isEditing`)
})
class IndexSearchCell extends Component {
  static defaultProps = {
    exactMatchSearch: noop,
    partialMatchSearch: noop
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.value !== prevState.value)
  // }
  constructor(...args) {
    super(...args)
    // console.log('index search cell, ', ...args)
    this.state = {
      value: this.props.value,
      rowIndex: this.props.rowIndex
    }
  }

  setField = (val, _, __, result) => {
    if (!result) return
    this.setState({ value: val }, () => {
      if (this.props.onChange) {
        console.log(this)
        this.props.onChange(this.state.rowIndex, result)
      }
    })
  }

  exactMatch = args =>
    this.props.dispatch(actions.exactMatchSearch(null, { ...args }))

  partialMatch = args =>
    this.props.dispatch(actions.partialMatchSearch(null, { ...args }))

  inputRef = c => (this.field = c)

  render() {
    if (this.props.rowIndex === 0) console.log(this.props, this.state)
    const { dispatch } = this.props
    return this.state.value ? (
      this.state.value
    ) : (
      <IndexSearch
        indexSearchType="shipVia"
        partialMatchSearch={this.partialMatch}
        exactMatchSearch={this.exactMatch}
        // onChange={this.onChange}
        setField={this.setField}
        meta={{
          allowInstantSearch: true,
          minimumKeywordLength: 2,
          allowSearchAll: true
        }}
        disabled={!this.props.isEditing}
        inputRef={this.inputRef}
      />
    )
  }
}

export default connect(mapStateToProps)(IndexSearchCell)
