import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { plainDeepEqual } from 'utils'
import { deletePrintOrderItem as deletePrintOrderItemAction } from 'components/SelectionCriteriaModal/actions'
import { deleteGridRow } from 'components/EditableGrid/utils'

class DeleteGridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    // deletePrintOrderItem: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    this.deleteGridRow = deleteGridRow.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.rowIndex !== this.props.rowIndex ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  dispatchDeleteProcess = () => {
    const {
      colDef: { type }
    } = this.props

    const methods = {
      deleteGridRow: this.deleteGridRow,
      deletePrintOrderItem: this.deletePrintOrderItem
    }

    if (type && methods[type]) {
      return methods[type]()
    }

    return this.deleteGridRow()
  }

  deletePrintOrderItem = () => {
    const {
      colDef: { form },
      data: { dataId },
      rowIndex
    } = this.props

    // console.log('rowIndex', rowIndex)
    // debugger

    this.props.dispatch(deletePrintOrderItemAction(form, { dataId, rowIndex }))
  }

  render() {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{
            color: '#d9534f',
            cursor: 'auto',
            display: 'inline-block',
            fontSize: 16
          }}
          onClick={this.dispatchDeleteProcess}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(DeleteGridCell)
