/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ClearGridRowCell from 'components/EditableGrid/components/ClearGridRowCell'

import SelectGridCell from 'components/EditableGrid/components/SelectGridCell'
import ButtonPopover from 'components/ButtonPopover'
import { updatePrices } from 'pages/ProductMaster/actions'
import { setGridHeight } from 'pages/ProductMaster/utils'
import { Button, Paper, Backdrop, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { getMeta, getValue, emptyList, getField } from 'utils'
import RollupAmountCalculator from './components/RollupAmountCalculator'

const ContainedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1
  }
})(Backdrop)

export class RollupSchedule extends Component {
  static propTypes = {
    basePriceOptions: PropTypes.array.isRequired,
    canSeeCostAndGrossProfit: PropTypes.bool.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    operatorOptions: PropTypes.array.isRequired,
    rollUpRowData: PropTypes.array.isRequired,
    valueOptions: PropTypes.array.isRequired
  }

  getColumnDefs = props => {
    const {
      isEditing,
      hasRecord,
      basePriceOptions,
      valueOptions,
      operatorOptions
    } = this.props
    const params = {
      hasRecord,
      isEditing,
      form: props.form,
      propertyName: props.propertyName,
      disabledFn: data => {
        if (hasRecord && isEditing) {
          return false
        }

        return true
      }
    }

    let colDefs = [
      {
        field: 'sequenceNumber',
        headerName: 'Seq #'
      },
      {
        field: 'description',
        headerName: 'Column'
      },
      {
        field: 'basePriceId',
        headerName: 'Base Column',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          values: basePriceOptions.toJS(),
          valuesFilterFn: (values, data) =>
            values && Array.isArray(values)
              ? values.filter(x => x.dataId !== data.dataId)
              : basePriceOptions.toJS(),
          requiredFn: data => {
            const { basePriceId, amount } = data

            if (amount && !basePriceId) {
              return true
            }

            return false
          }
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      {
        field: 'operatorType',
        headerName: '+/-',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          values: operatorOptions.toJS(),
          requiredFn: data => {
            const { operatorType, amount } = data

            if (amount && !operatorType) {
              return true
            }

            return false
          }
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      {
        field: 'valueType',
        headerName: '$/%',
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          values: valueOptions.toJS(),
          requiredFn: data => {
            const { valueType, amount } = data

            if (amount && !valueType) {
              return true
            }

            return false
          }
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        }
      },
      {
        field: 'amount',
        headerName: 'Amount',
        minWidth: 150,
        cellRendererFramework: RollupAmountCalculator,
        cellRendererParams: params,
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        },
        suppressKeyboardEvent: prms => {
          // const { value } = params.event.target
          // const { editing } = params
          const { keyCode } = prms.event
          const arrowKeys = [37, 39]
          if (arrowKeys.includes(keyCode)) {
            return true
          }
          return false
        }
      },
      {
        field: 'resultAmount',
        headerClass: 'align-right',
        headerName: 'Result',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          decimalScale: 4,
          dollarField: true,
          fixedDecimalScale: true,
          formatter: 'number',
          textAlign: 'right'
        }
      }
    ]

    if (isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'dataId',
          headerName: '',
          cellRendererFramework: ClearGridRowCell,
          cellRendererParams: {
            ...params,
            skipFields: ['dataId', 'description']
          },
          editable: false,
          width: 50,
          maxWidth: 50
        }
      ]
    }

    return colDefs
  }

  setGridHeight = memoize(arr => setGridHeight(arr))

  updatePrices = () => {
    const { dispatch } = this.props
    dispatch(updatePrices.try('productMaster', {}))
  }

  rollUpScheduleDisabled = memoize((canSeeCostAndGrossProfit, hasRecord) => {
    if (!hasRecord || !canSeeCostAndGrossProfit) {
      return true
    }

    return false
  })

  render() {
    const {
      canSeeCostAndGrossProfit,
      hasRecord,
      isEditing,
      rollUpRowData,
      isPostingRollupData
    } = this.props

    return (
      <ButtonPopover
        disabled={this.rollUpScheduleDisabled(
          canSeeCostAndGrossProfit,
          hasRecord
        )}
        popoverTitle="Roll-Up Schedule"
        popoverMinWidth={800}
      >
        <DDICardWrapper
          title="Roll-Up Schedule"
          style={{ marginBottom: 0 }}
          contentStyle={{ padding: 0 }}
        >
          {rollUpRowData && rollUpRowData.size ? (
            <div style={{ width: 800, maxWidth: 800, position: 'relative' }}>
              <ContainedBackdrop
                open={isPostingRollupData}
                style={{ width: '100%', height: '100%' }}
              >
                <CircularProgress size={50} thickness={10} />
              </ContainedBackdrop>
              <EditableGrid
                // getRowNodeId={data => data.dataId}
                requiredFields={[
                  'basePriceId',
                  'operatorType',
                  'resultAmount',
                  'valueType'
                ]}
                propertyName="rollUps"
                form={this.props.form}
                columnDefs={this.getColumnDefs}
                ref={el => (this.grid = el)}
                height={this.setGridHeight(rollUpRowData.toJS())}
                rowSelection="single"
                width="100%"
                fullWidth
                skipValidationOnUnmount
                gridWrapperStyle={{
                  flex: '1 1',
                  maxWidth: '100%',
                  overflow: 'hidden',
                  width: '100%'
                }}
                showAddButtonOnlyIfEditing={false}
              />
            </div>
          ) : null}
          <Paper
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              padding: 10,
              margin: 10
            }}
          >
            <Button
              onClick={this.updatePrices}
              variant="contained"
              disabled={!isEditing}
            >
              Update Prices
            </Button>
          </Paper>
        </DDICardWrapper>
      </ButtonPopover>
    )
  }
}

export const contextObj = {
  basePriceOptions: form => getMeta(form, 'basePriceOptions', emptyList),
  canSeeCostAndGrossProfit: form =>
    getMeta(form, 'canSeeCostAndGrossProfit', false),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  isPostingRollupData: form => form.get('isPostingRollupData') || false,
  operatorOptions: form => getMeta(form, 'operatorOptions', emptyList),
  rollUpRowData: form => getField(form, 'rollUps', emptyList, true),
  valueOptions: form => getMeta(form, 'valueOptions', emptyList)
}

export default withContext(contextObj)(RollupSchedule)
