/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getAdditionalData = createAsyncFormAction(
  CONSTANTS.GET_ADDITIONAL_DATA
)

export const setNextTab = (form, { tab }) =>
  createAction(CONSTANTS.SET_NEXT_TAB, { tab }, form)

export const clearAdditionalData = form =>
  createAction(CONSTANTS.CLEAR_ADDITIONAL_DATA, {}, form)

export const handleOpenGridInterfaceFeature = (
  form,
  { gridApi, lineNumber, colId }
) =>
  createAction(
    CONSTANTS.HANDLE_OPEN_GRID_INTERFACE_FEATURE,
    { gridApi, lineNumber, colId },
    form
  )

export const handleVoidProcess = createAsyncFormAction(
  CONSTANTS.HANDLE_VOID_PROCESS
)

export const getChangeInvoiceMeta = createAsyncFormAction(
  CONSTANTS.GET_CHANGE_INVOICE_META
)

export const lockInvoiceRecord = createAsyncFormAction(
  CONSTANTS.LOCK_INVOICE_RECORD
)

export const unlockInvoiceRecord = createAsyncFormAction(
  CONSTANTS.UNLOCK_INVOICE_RECORD
)

export const saveInvoiceRecord = createAsyncFormAction(
  CONSTANTS.SAVE_INVOICE_RECORD
)

export const handleSuppressFromStatements = createAsyncFormAction(
  CONSTANTS.HANDLE_SUPPRESS_FROM_STATEMENTS
)

export const getSalesmenCommissions = createAsyncFormAction(
  CONSTANTS.GET_SALESMEN_COMMISSIONS
)

export const changeCommissionValue = createAsyncFormAction(
  CONSTANTS.CHANGE_COMMISSION_VALUE
)

export const changeCommissionProperty = createAsyncFormAction(
  CONSTANTS.CHANGE_COMMISSION_PROPERTY
)

export const handleOpenProductFromSalesmenCommissionsInterface = (
  form,
  { dataId, modalId, parentModalCb }
) =>
  createAction(
    CONSTANTS.HANDLE_OPEN_PRODDUCT_FROM_SALESMEN_COMMISSIONS_INTERFACE,
    { dataId, modalId, parentModalCb },
    form
  )

/* start NEW Customer Stock Minimums actions */
export const readCustomerStockMinimums = createAsyncFormAction(
  CONSTANTS.READ_CUSTOMER_STOCK_MINIMUMS
)

export const saveCustomerStockMinimums = createAsyncFormAction(
  CONSTANTS.SAVE_CUSTOMER_STOCK_MINIMUMS
)

export const deleteCustomerStockMinimums = createAsyncFormAction(
  CONSTANTS.DELETE_CUSTOMER_STOCK_MINIMUMS
)

export const exitCustomerStockMinimumsInterface = createAsyncFormAction(
  CONSTANTS.EXIT_CUSTOMER_STOCK_MINIMUMS_INTERFACE
)
/* end NEW Customer Stock Minimums actions */

export const openSerialNumberEditor = createAsyncFormAction(
  CONSTANTS.OPEN_SERIAL_NUMBER_EDITOR
)
