import { select } from 'redux-saga/effects'
import { getIn } from 'utils'

export default function* saveArgumentsSaga(form) {
  let parentId
  let parentType
  const parent = yield select(state =>
    getIn(state, `ddiForm.${form}.values.parent`)
  )
  if (parent) {
    parentId = getIn(parent, 'parentId')
    parentType = getIn(parent, 'parentType')
  }
  return {
    // dataId,
    parentId,
    parentType
  }
}
