import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext, Field, Label } from 'ddiForm'
import { DDIIndexSearch, DDIToggle } from 'ddiForm/wrapped'
import {
  formatDate,
  // noop,
  requestProcessingMessage,
  getValue,
  formatNumber,
  getField,
  getMeta,
  emptyList
} from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import { Paper, Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import masterStyles from 'theme/masterStyles'
// import DDIIndexSearch from 'ddiForm/wrapped/DDIIndexSearch'

import {
  showPriceGp,
  hideShowPriceGP,
  loadCustomerRecord
} from 'pages/ProductMaster/actions'

import withDimensions from 'hoc/withDimensions'

import { PriceBreaksGrid, PricingGrid } from './components'

import './styles/master-style.scss'

export class Pricing extends Component {
  static propTypes = {
    canSeeCostAndGrossProfit: PropTypes.bool.isRequired,
    categoryPrices: PropTypes.array.isRequired,
    competitivePrices: PropTypes.array.isRequired,
    customerCategoryIDAndDescription: PropTypes.string.isRequired,
    customerId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    fetchingEntity: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    gpPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    l1Price: PropTypes.string,
    l1PriceDate: PropTypes.string.isRequired,
    l1PriceLabel: PropTypes.object.isRequired,
    lastSaleDate: PropTypes.string.isRequired,
    lastSalePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    levels: PropTypes.array.isRequired,
    netPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    priceFormula: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    quotedDateAndBy: PropTypes.string.isRequired,
    quotedPrice: PropTypes.string.isRequired,
    showCompetitivePricesInSO: PropTypes.bool.isRequired,
    showGP: PropTypes.bool.isRequired
  }

  static defaultProps = {
    gpPercent: '',
    l1Price: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      checked: false
    }
  }

  componentDidMount() {
    const { dispatch, form } = this.props

    dispatch(loadCustomerRecord(form))
  }

  handleToggleChange = event => {
    const { dispatch, form } = this.props
    const { checked } = event.target
    this.setState({ checked }, () => {
      this.state.checked
        ? dispatch(showPriceGp.try(form))
        : dispatch(hideShowPriceGP(form))
    })
  }

  render() {
    const {
      canSeeCostAndGrossProfit,
      categoryPrices,
      competitivePrices,
      customerCategoryIDAndDescription,
      customerId,
      dataId,
      fetchingEntity,
      form,
      gpPercent,
      height,
      l1Price,
      l1PriceDate,
      l1PriceLabel,
      lastSaleDate,
      lastSalePrice,
      levels,
      netPrice,
      priceFormula,
      quotedDateAndBy,
      quotedPrice,
      showCompetitivePricesInSO,
      showGP
    } = this.props

    const formattedDate = l1PriceDate
      ? formatDate(l1PriceDate, 'MM/DD/YYYY')
      : null

    const l1PriceFormatted =
      l1Price && typeof l1Price === 'number'
        ? l1Price.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            minimumFractionDigits: 4
          })
        : l1Price

    const pricingGridHeight = height ? height - 394 : 300

    return (
      <div className="product-analysis-pricing-layout-flex">
        <Paper className="three-col-flex-wrapper" style={{ marginBottom: 10 }}>
          <span>
            {l1PriceLabel} <strong>{l1PriceFormatted}</strong>
          </span>
          <span>
            As of: <strong>{formattedDate}</strong>
          </span>
          <DDIToggle
            propertyName="showGP"
            label="Show G/P"
            initialValue={showGP}
            preventAutoDisable
            leader
            disableDoubleClickUnlock
          />
        </Paper>

        <DDICardWrapper
          title="Customer Pricing"
          style={{ textAlign: 'center' }}
          contentStyle={{ padding: '1rem' }}
        >
          <div className="two-col-flex-wrapper">
            {/* LEFT START */}
            <div className="two-col-flex-left">
              <div className="search-wrapper">
                <label htmlFor="customerId" className="search-label">
                  Customer:
                </label>
                <div style={{ marginRight: 10, width: 150 }}>
                  <DDIIndexSearch
                    propertyName="pricing.customerId"
                    disableDoubleClickUnlock
                    indexSearchType="customer"
                    disableLastSearchButton
                    fullWidth
                    meta={{
                      allowInstantSearch: true,
                      hasFilters: true,
                      minimumKeywordLength: 2
                    }}
                    metaKey="Customer"
                    preventAutoDisable
                    disabled={!dataId}
                    popoverStyle={{
                      width: 500
                    }}
                    initialValue={customerId}
                  />
                </div>
                <Field
                  component={Label}
                  // propertyName="customerDescription"
                  propertyName="pricing.customerDescription"
                  labelStyle={{ width: '100%' }}
                />
              </div>
              <div className="two-col-category-wrapper">
                {fetchingEntity ? (
                  requestProcessingMessage('Request Processing...', 200)
                ) : (
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ width: 100 }}>Category:</TableCell>
                        <TableCell style={{ width: 180 }}>
                          <strong>{customerCategoryIDAndDescription}</strong>
                        </TableCell>
                        <TableCell style={{ width: 65 }} />
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: 100 }}>Net Price:</TableCell>
                        <TableCell style={{ width: 180 }}>
                          <strong>{formatNumber(netPrice, '0,0.0000')}</strong>
                        </TableCell>
                        <TableCell style={{ width: 65 }}>
                          <span style={{ display: showGP ? 'block' : 'none' }}>
                            G/P %:
                          </span>
                        </TableCell>
                        <TableCell>
                          <strong
                            style={{ display: showGP ? 'block' : 'none' }}
                          >
                            {gpPercent}
                          </strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: 100 }}>
                          Last Price:
                        </TableCell>
                        <TableCell style={{ width: 180 }}>
                          <strong>
                            {formatNumber(lastSalePrice, '0,0.0000')}
                          </strong>
                        </TableCell>
                        <TableCell style={{ width: 65 }}>Date:</TableCell>
                        <TableCell>
                          <strong>
                            {formatDate(lastSaleDate, 'MM/DD/YYYY')}
                          </strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: 100 }}>
                          Quoted Price:
                        </TableCell>
                        <TableCell style={{ width: 180 }}>
                          <strong>{quotedPrice}</strong>
                        </TableCell>
                        <TableCell style={{ width: 65 }}>Date:</TableCell>
                        <TableCell>
                          <strong>{quotedDateAndBy}</strong>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ width: 100 }}>Formula:</TableCell>
                        <TableCell style={{ width: 180 }}>
                          <strong>{priceFormula}</strong>
                        </TableCell>
                        <TableCell style={{ width: 65 }} />
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
            {/* LEFT END */}

            {/* RIGHT START */}
            <div className="two-col-flex-right">
              <div className="search-wrapper">
                <label htmlFor="shipToId" className="search-label">
                  Ship To:
                </label>
                <div style={{ marginRight: 10, width: 150 }}>
                  <DDIIndexSearch
                    propertyName="pricing.shipToId"
                    // disabled={!customerId}
                    fullWidth
                    meta={{
                      allowInstantSearch: false,
                      allowSearchAll: Boolean(customerId),
                      hasFilters: false,
                      searchType: 'shipTo'
                    }}
                    parentId={customerId}
                    preventAutoDisable
                    disabled={!dataId}
                    minWidth="100%"
                    placeholder="All Ship  Tos"
                    popoverStyle={{
                      width: 500
                    }}
                    disableDoubleClickUnlock
                  />
                </div>
                <Field
                  component={Label}
                  propertyName="pricing.shipToDescription"
                  labelStyle={{ width: '100%' }}
                />
              </div>
              <div className="flex-wrapper-1">
                <div style={{ height: '20%', width: '50%' }}>
                  <PriceBreaksGrid
                    data={levels && levels?.toJS ? levels.toJS() : []}
                  />
                </div>
              </div>
            </div>
            {/* RIGHT END */}
          </div>
        </DDICardWrapper>
        <div style={{ maxWidth: '100%', width: '100%' }}>
          <div className="flex-wrapper-1">
            <PricingGrid
              canSeeCostAndGrossProfit={canSeeCostAndGrossProfit}
              data={
                showCompetitivePricesInSO
                  ? competitivePrices.toJS()
                  : categoryPrices.toJS()
              }
              showCompetitive={showCompetitivePricesInSO}
              showGP={showGP}
              height={pricingGridHeight}
              form={form}
            />
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  dataId: form => getField(form, 'dataId', ''),
  canSeeCostAndGrossProfit: form =>
    getMeta(form, 'canSeeCostAndGrossProfit', false),
  categoryPrices: form => getValue(form, 'categoryPrices', emptyList),
  competitivePrices: form => getValue(form, 'competitivePrices', emptyList),
  customerCategoryIDAndDescription: form =>
    getValue(form, 'customerCategoryIDAndDescription', ''),
  customerId: form => getValue(form, 'customerId', ''),
  fetchingEntity: form => form.get('fetchingEntity') || false,
  gpPercent: form => getValue(form, 'gpPercent', 0),
  l1Price: form => getValue(form, 'l1Price', ''),
  l1PriceDate: form => getValue(form, 'l1PriceDate', ''),
  l1PriceLabel: form => getValue(form, 'l1PriceLabel', 'L1 List'),
  lastSaleDate: form => getValue(form, 'lastSaleDate', ''),
  lastSalePrice: form => getValue(form, 'lastSalePrice', ''),
  levels: form => getValue(form, 'levels', emptyList),
  netPrice: form => getValue(form, 'netPrice', ''),
  priceFormula: form => getValue(form, 'priceFormula', ''),
  quotedDateAndBy: form => getValue(form, 'quotedDateAndBy', ''),
  quotedPrice: form => getValue(form, 'quotedPrice', ''),
  showCompetitivePricesInSO: form =>
    getValue(form, 'showCompetitivePricesInSO', false),
  showGP: form => getValue(form, 'showGP', false)
}

const pricingWithContext = withContext(contextObj)(Pricing)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  pricingWithContext
)
