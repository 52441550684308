/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { findDOMNode } from 'react-dom'
import shortid from 'shortid'
import {
  Icon,
  IconButton,
  Select,
  MenuItem,
  Popover,
  TextField
} from '@material-ui/core'
import { getIn } from 'utils'
import { isDisabled, updateGridCellData, showRequiredIcon } from '../utils'

const mapStateToProps = (state, ownProps) => {
  const { form, propertyName, valueField } = ownProps

  let rowData = getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`)
  rowData = rowData && rowData.toJS ? rowData.toJS() : []

  const items = rowData.reduce((acc, next) => {
    if (next[valueField]) {
      if (acc.findIndex(x => x.dataId === next[valueField]) === -1) {
        acc = acc.concat({
          dataId: next[valueField],
          description: next[valueField]
        })
      }
    }
    return acc
  }, [])

  return {
    menuItems: items.length
      ? items.sort((a, b) => {
          if (a.dataId < b.dataId) {
            return -1
          }
          if (a.dataId > b.dataId) {
            return 1
          }
          return 0
        })
      : [],
    rowData
  }
}

class DynamicSelect extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    menuItems: PropTypes.array.isRequired,
    propertyName: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    rowData: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      value: props.value || ''
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)
    this.showRequiredIcon = showRequiredIcon.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef.cellRendererParams.requiredFn = this.props.colDef.cellRendererParams.requiredFn.bind(
        this
      )
    }
  }

  componentDidMount() {
    this.anchorEl = findDOMNode(this.anchor)
  }

  handleChange = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.value !== this.state.value) {
        this.updateGridCellData()
      }
    })
  }

  onKeyDown = event => {
    if (this.state.value && event.key === 'Tab') {
      this.updateGridCellData('value', true, false)
    }
  }

  selectMenuItem = ({ value }) =>
    this.setState({ value, isOpen: false }, () => this.updateGridCellData())

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { disabledFn, formatter }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return this.isDisabled()
  }

  render() {
    const { menuItems } = this.props

    return (
      <div style={{ position: 'relative' }}>
        {this.showRequiredIcon()}
        <Select
          inputProps={{
            name: shortid.generate(),
            id: shortid.generate()
          }}
          value={this.state.value}
          fullWidth
          onChange={this.handleChange}
          disabled={this.isFieldDisabled()}
        >
          {menuItems.map((item, idx) => (
            <MenuItem value={item.dataId} key={shortid.generate()}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(DynamicSelect)
