import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import Grid from 'grid'
import { formatDollarFields } from 'utils'

export const getRowNodeId = ({
  dataId = '',
  description,
  level,
  factoredPrice,
  price = ''
}) => {
  const compId = `${dataId}|${level}|${description}|${factoredPrice}|${price}`
  return compId
}

export const dynamicCategoryCols = memoize(
  ({ canSeeCostAndGrossProfit, showGP }) => {
    const colDefs = [
      {
        field: 'dataId',
        headerName: 'Category',
        filter: false,
        suppressMenu: true
      },
      {
        field: 'description',
        headerName: 'Description',
        filter: false,
        suppressMenu: true
      },
      {
        field: 'formula',
        headerName: 'Formula',
        hide: !canSeeCostAndGrossProfit,
        filter: false,
        suppressMenu: true
      },
      {
        field: 'price',
        headerName: 'Price',
        valueFormatter: params => formatDollarFields(params, 4),
        filter: false,
        suppressMenu: true,
        cellStyle: { textAlign: 'right' },
        headerClass: 'align-right'
      },
      {
        cellClass: 'align-right',
        cellStyle: params => {
          if (params.value < 0) {
            return {
              color: '#d9534f'
            }
          }

          return null
        },
        field: 'gpPercent',
        headerName: 'G/P %',
        headerClass: 'align-right',
        valueFormatter: params => {
          if (params.value || params.value === 0) {
            return `${params.value}%`
          }

          return null
        },
        hide: !showGP,
        filter: false,
        suppressMenu: true
      }
    ]
    return colDefs
  }
)

export const dynamicCompetitiveCols = memoize(
  ({ canSeeCostAndGrossProfit }) => [
    { field: 'level', headerName: 'Lvl' },
    { field: 'description', headerName: 'Method' },
    {
      field: 'factoredPrice',
      cellClass: 'align-right',
      headerClass: 'align-right',
      headerName: 'Price',
      valueFormatter: params => formatDollarFields(params, 4)
    },
    {
      field: 'formulaDisplay',
      headerName: 'Formula',
      hide: !canSeeCostAndGrossProfit
    },
    {
      field: 'cost',
      cellClass: 'align-right',
      headerClass: 'align-right',
      headerName: 'Cost',
      hide: !canSeeCostAndGrossProfit,
      valueFormatter: params => formatDollarFields(params, 4)
    }
  ]
)

const PricingGrid = ({
  canSeeCostAndGrossProfit,
  data,
  form,
  height,
  showCompetitive,
  showGP
}) => (
  <Grid
    getRowNodeId={getRowNodeId}
    rowData={data}
    columnDefs={
      showCompetitive
        ? dynamicCompetitiveCols({ canSeeCostAndGrossProfit })
        : dynamicCategoryCols({
            showGP,
            canSeeCostAndGrossProfit
          })
    }
    height={height}
    enableSorting
    width="100%"
    fullWidth
    form={form}
  />
)

PricingGrid.propTypes = {
  canSeeCostAndGrossProfit: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  height: PropTypes.number.isRequired,
  showCompetitive: PropTypes.bool.isRequired,
  showGP: PropTypes.bool.isRequired
}

export default PricingGrid
