import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import {
  getRowNodeId,
  headerStyle,
  numberFilterGridConfigs
} from 'pages/ProductMaster/utils'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import PartNumbersNotesEditorCell from 'components/EditableGrid/components/PartNumbersNotesEditorCell'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import TooltipField from 'hoc/TooltipField'
import { withContext } from 'ddiForm'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

export const dynamicColDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'customer',
      onChange: onCellChange,
      propertyName
    }

    const textEditor = {
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: params
    }

    const colDefs = [
      {
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'customer',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          linkTo: 'customerLedger'
        },
        field: 'dataId',
        headerName: 'Customer'
      },
      {
        field: 'description',
        headerName: 'Description',
        cellStyle: { color: isEditing ? '#444' : '#777' },
        suppressNavigable: true
      },
      {
        ...textEditor,
        cellRendererParams: {
          ...textEditor.cellRendererParams,
          requiredFn: data => {
            if (
              data.dataId &&
              !data.partId &&
              !data.externalComment &&
              !data.internalComment &&
              !data.woNotes
            ) {
              return true
            }

            return false
          }
        },
        editable: false,
        field: 'partId',
        headerName: 'Part Number'
      },
      {
        ...textEditor,
        cellRendererParams: {
          ...textEditor.cellRendererParams,
          allowNegative: false,
          decimalScale: 0,
          fixedDecimalScale: true,
          maxLength: 10,
          textAlign: 'right',
          formatter: 'number',
          thousandSeparator: ''
        },
        editable: false,
        field: 'quantityCommitment',
        headerName: 'Qty Commitment',
        ...numberFilterGridConfigs
      },
      {
        field: 'externalComment',
        headerName: 'Ext Comment',
        headerClass: 'text-center',
        cellRendererFramework: PartNumbersNotesEditorCell,
        cellRendererParams: {
          form,
          isEditing,
          propertyName,
          ignoreTabLogic: true
        }
      },
      {
        field: 'internalComment',
        headerName: 'Int Comment',
        headerClass: 'text-center',
        cellRendererFramework: PartNumbersNotesEditorCell,
        cellRendererParams: {
          form,
          isEditing,
          propertyName,
          ignoreTabLogic: true
        }
      },
      {
        field: 'woNotes',
        headerName: 'W/O Notes',
        headerClass: 'text-center',
        cellRendererFramework: PartNumbersNotesEditorCell,
        cellRendererParams: {
          form,
          isEditing,
          propertyName,
          ignoreTabLogic: true
        }
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        hide: !isEditing,
        suppressNavigable: true,
        maxWidth: 50,
        width: 50
      }
    ]

    return colDefs
  }
)

const PartNumbers = ({ form, height, isEditing }) => (
  <EditableGrid
    title="Part Number"
    headerStyle={headerStyle}
    propertyName="getPartNumberManagerItems"
    addButtonText="Add Part Number"
    getRowNodeId={getRowNodeId}
    form={form}
    emptyRow={{
      dataId: null,
      description: '',
      partId: null,
      quantityCommitment: ''
    }}
    focusCell="partId"
    isEditing={isEditing}
    columnDefs={dynamicColDefs}
    height={height}
  />
)

PartNumbers.propTypes = {
  form: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isEditing: PropTypes.bool.isRequired
}

const partNumbersWithContext = withContext({
  isEditing: form => form.get('isEditing') || false
})(PartNumbers)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  partNumbersWithContext
)
