import React from 'react'

const FieldsWrapper = props => (
  <div
    style={{
      display: 'flex',
      padding: 10,
      background: '#f4f7f9',
      alignItems: 'center',
      ...props.style
    }}
  >
    {props.children}
  </div>
)

export default FieldsWrapper

