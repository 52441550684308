import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { fromJS, getIn, is } from 'utils'
import ErrorBoundary from 'components/ErrorBoundary'
import {
  Typography,
  ExpansionPanelDetails as MuiExpansionPanelDetails
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/core/styles'

import { ExpansionPanel, ExpansionPanelSummary } from 'pages/SalesOrder/utils'

import { storeUIFeatureState } from 'pages/SalesOrder/actions'
import WarehouseGrid from './WarehouseGrid'
import JohnstoneNational from './JohnstoneNational'

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: 0
  }
}))(MuiExpansionPanelDetails)

const infoStyle = { color: '#444', fontSize: '0.85em' }

const DynamicWarehouses = props => {
  const dispatch = useDispatch()
  const {
    form,
    height,
    userId,
    dataId,
    lineNumber,
    rowId,
    isMobile = false,
    isJohnstoneCustomer = false,
    johnstoneInventory = {},
    selectedWarehousePanel
  } = props
  let { inventoryNames } = props
  inventoryNames = inventoryNames?.toJS ? inventoryNames.toJS() : []

  const [state, setState] = useState({
    expanded:
      isMobile && selectedWarehousePanel
        ? selectedWarehousePanel
        : inventoryNames?.[0]?.dataId || false
  })

  const handleExpansionPanelChange = panel => (event, isExpanded) => {
    const inventoryIdx = inventoryNames.findIndex(x => x.dataId === panel)
    const openByNextByDefault =
      is.number(inventoryIdx) && inventoryNames?.[inventoryIdx + 1]?.dataId
        ? inventoryNames[inventoryIdx + 1].dataId
        : false
    const expanded = isExpanded ? panel : openByNextByDefault

    setState({ ...state, expanded })

    if (expanded && panel && panel !== 'johnstone') {
      dispatch(
        storeUIFeatureState(form, {
          feature: 'warehousePanel',
          featureState: expanded
        })
      )
    }
  }

  if (!inventoryNames?.length) {
    return null
  }

  return (
    <ErrorBoundary>
      {inventoryNames.map((x, i) => (
        <ExpansionPanel
          expanded={state.expanded === x.dataId}
          onChange={handleExpansionPanelChange(x.dataId)}
          key={x.dataId}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={infoStyle}>{x.description}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ maxWidth: '100%', width: '100%' }}>
              <WarehouseGrid
                form={form}
                height={height}
                userId={userId}
                key={x.dataId}
                gridId={x.dataId}
                lineNumber={lineNumber}
                rowId={rowId}
                dataId={dataId}
                panelIsOpen={state.expanded === x.dataId}
                isMobile={isMobile}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
      {isJohnstoneCustomer && !isMobile ? (
        <ExpansionPanel
          expanded={state.expanded === 'johnstone'}
          onChange={handleExpansionPanelChange('johnstone')}
          key="johnstone"
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={infoStyle}>Johnstone</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ maxWidth: '100%', width: '100%' }}>
              <JohnstoneNational
                form={form}
                johnstoneInventory={johnstoneInventory}
              />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ) : null}
    </ErrorBoundary>
  )
}

export default withContext({
  inventoryNames: form => getIn(form, 'meta.inventoryNames') || fromJS([]),
  selectedWarehousePanel: form => getIn(form, 'ui.warehousePanel') || null
})(DynamicWarehouses)
