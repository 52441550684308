import React, { useState } from 'react'
import Grid from 'grid'
import Measure from 'react-measure'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { DDIDatePicker } from 'ddiForm/wrapped'
import { emptyList, formatDate, formatNumber, getIn } from 'utils'

import {
  getCellStyle,
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/VendorMaster/utils'

import IndicatorColumn from './grid/IndicatorColumn'

import AwaitingBackdrop from './AwaitingBackdrop'

import '../styles/ledger-styles.scss'

const TransactionsMain = ({
  dataId,
  defaultTransactionDate,
  dimensions,
  form,
  gridMaxHeight,
  height,
  isEditing,
  transactionsList,
  transactionFromDate,
  fetchingEntity
}) => {
  const [inputDimensions, setDimensions] = useState({
    width: -1,
    height: -1
  })

  const transactions = transactionsList?.toJS ? transactionsList.toJS() : []

  const columnDefs = [
    {
      field: 'documentId',
      headerName: 'Document'
    },
    {
      field: 'dcoumentId',
      headerName: '',
      cellRendererFramework: IndicatorColumn,
      minWidth: 60,
      maxWidth: 60,
      width: 60
    },
    {
      field: 'date',
      headerName: 'Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY'),
      ...dateFilterGridConfigs
    },
    {
      field: 'poOrReferenceId',
      headerName: 'PO/Ref #'
    },
    {
      field: 'amount',
      headerName: 'Amount',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle,
      ...numberFilterGridConfigs
    },
    {
      field: 'runningBalance',
      headerName: 'Balance',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle,
      ...numberFilterGridConfigs
    }
  ]

  return (
    <div style={{ width: '100%' }}>
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="vendor-ledger-inputs-wrapper">
            <div className="vendor-ledger-inputs">
              <DDIDatePicker
                propertyName="transactions.transactionFromDate"
                label="From Date:"
                initialValue={defaultTransactionDate}
                preventAutoDisable
                disabled={!dataId}
              />
            </div>
          </div>
        )}
      </Measure>
      <div
        className="vendor-ledger-grid-wrapper"
        style={{ position: 'relative' }}
      >
        <AwaitingBackdrop fetchingEntity={fetchingEntity} />
        <Grid
          title="Transactions"
          headerStyle={headerStyle}
          height={gridMaxHeight - inputDimensions?.height}
          columnDefs={columnDefs}
          rowData={transactions}
          getRowNodeId={data => data.documentId}
          suppressRowClickSelection
          applyColumnDefOrder
          key={`${dataId}-${transactionFromDate}`}
          reactUi={false}
        />
      </div>
    </div>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  isEditing: form => form.get('isEditing') || false,
  defaultTransactionDate: form =>
    getIn(form, 'meta.ledgerMeta.defaultTransactionDate') || '',
  transactionFromDate: form =>
    getIn(form, 'fields.transactions.transactionFromDate.value') || '',
  transactionsList: form =>
    getIn(form, 'values.transactions.transactions') || emptyList,
  fetchingEntity: form => form.get('fetchingEntity') || false
})(TransactionsMain)
