import React, { useEffect, useState } from 'react'
import { withContext } from 'ddiForm'
import { FormControlLabel, Switch } from '@material-ui/core'
import { updateLineItem, triggerUpdateLineItem } from 'pages/SalesOrder/actions'
import { usePrevious } from 'pages/SalesOrder/utils'

const LineItemSwitch = ({
  form,
  dispatch,
  field,
  value,
  data,
  node,
  label,
  isPostingGridUpdate,
  isDetailTabSwitch = false,
  disabled = false,
  isMobile = false
}) => {
  const lineNumber = data?.lineNumber || ''

  const [state, setState] = useState({
    [field]: value
  })

  const wasPostingGridUpdate = usePrevious(isPostingGridUpdate)
  const prevLineNumber = usePrevious(lineNumber)

  useEffect(() => {
    if (
      (wasPostingGridUpdate &&
        !isPostingGridUpdate &&
        state[field] !== value) ||
      (lineNumber !== prevLineNumber && state[field] !== value)
    ) {
      setState({
        ...state,
        [field]: value
      })
    }
  })

  const handleChange = name => event => {
    event.persist()
    console.log(event.target.checked, state, name)
    // debugger

    setState({
      ...state,
      [name]: event.target.checked
    })

    if (isDetailTabSwitch) {
      dispatch(
        triggerUpdateLineItem(form, {
          propertyName: 'lineItems',
          data,
          value: event.target.checked,
          colDef: {
            field
          }
        })
      )
    } else {
      dispatch(
        updateLineItem.try(form, {
          data: node.data,
          value: event.target.checked,
          propertyName: 'lineItems',
          colDef: {
            field
          },
          node
        })
      )
    }
  }

  return (
    <FormControlLabel
      control={
        <Switch
          checked={state[field]}
          onChange={handleChange(field)}
          value={field}
          disabled={disabled}
        />
      }
      label={label}
    />
  )
}

export default LineItemSwitch
