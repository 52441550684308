import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deepEqual, isGrid, getIn, plainDeepEqual, getRgb, fromJS } from 'utils'
import { BASE_INFORM_URL } from 'services/constants'
import * as CONSTANTS from './constants'
// import deep from 'deep-diff'

const propsToNotUpdateFor = [
  // '_ddiForm',
  'dispatch',
  'dataId',
  'blur',
  'change',
  'meta',
  'onChange',
  'onFocus',
  'focus',
  'getFormState',
  'setField'
]
// paginate: PropTypes.shape({
//   next: PropTypes.func,
//   prev: PropTypes.func,
// }),
class ConnectedStatusLabel extends Component {
  static propTypes = {
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    propertyName: PropTypes.string.isRequired
  }

  static defaultProps = {
    initialValue: null,
    forwardRef: false
  }

  render() {
    // console.log(this.props)
    const { creditStatusColor, creditStatus } = this.props
    return creditStatus && creditStatusColor ? (
      <div
        style={{
          backgroundColor: creditStatusColor,
          borderRadius: 4,
          color: 'white',
          fontSize: 12,
          lineHeight: '16px',
          marginLeft: 10,
          padding: 5,
          textTransform: 'uppercase'
        }}
      >
        {creditStatus}
      </div>
    ) : null
  }
}

const connector = connect(
  (state, ownProps) => {
    const { propertyName, getFormState } = ownProps
    const formState = getFormState(state)
    const field = getIn(formState, `fields.${propertyName}`)
    const creditStatus = getIn(field, 'creditStatus')
    const creditStatusColor = getIn(field, 'creditStatusColor')
    // if (creditStatusColor) {
    //   console.log(creditStatusColor.toJS())
    // }
    return {
      creditStatus,
      creditStatusColor: creditStatusColor
        ? getRgb(creditStatusColor.toJS())
        : null
    }
  },
  undefined,
  undefined,
  { forwardRef: true }
)
export default connector(ConnectedStatusLabel)
