import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import { formatDateFields, getField } from 'utils'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { DynamicGridDataTooltip } from 'hoc'
import memoize from 'memoize-one'
import SerialNumberGridCell from 'pages/ProductSerialNumberInquiry/components/SerialNumberGridCell'

import { setSelectedSerialNumber } from '../actions'

import { headerStyle } from '../utils'

export const getRowNodeId = data => {
  const { completedDate, dataId, productId, workOrderId } = data
  return `${dataId} - ${completedDate} - ${productId} - ${workOrderId}`
}

const dynamicColDefs = memoize(form => {
  const colDefs = [
    {
      cellRendererFramework: SerialNumberGridCell,
      cellRendererParams: {
        form
      },
      field: 'dataId',
      headerName: 'Serial No'
    },
    {
      cellRendererFramework: DynamicGridDataTooltip({
        dataField: 'notes'
      })(NotesCheckbox),
      cellRendererParams: {
        form,
        propertyName: 'workOrderInfo'
      },
      headerClass: 'text-center',
      headerName: 'Notes',
      field: 'notes'
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'workOrderId',
      headerName: 'Work Order'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'completedDate',
      headerClass: 'text-center',
      headerName: 'Completed Date',
      valueFormatter: formatDateFields
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      linkTo: 'productMasterScreen',
      groupNames: ['setup', 'main'],
      field: 'productId',
      headerName: 'Product No'
    },
    {
      field: 'productDescription',
      headerName: 'Description'
    }
  ]

  return colDefs
})

export class WorkOrder extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    selectedSerialNumber: PropTypes.string
  }

  static defaultProps = {
    selectedSerialNumber: ''
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSerialNumber } = this.props
    if (prevProps.selectedSerialNumber !== selectedSerialNumber) {
      if (this.gridApi) {
        this.gridApi.onFilterChanged()
      }
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  onRowDoubleClicked = params => {
    const { dispatch, form } = this.props
    const value = params.data && params.data.dataId ? params.data.dataId : ''

    dispatch(setSelectedSerialNumber(form, { value }))
  }

  doesExternalFilterPass = node => {
    const { selectedSerialNumber } = this.props
    const { dataId } = node.data

    return selectedSerialNumber === dataId
  }

  isExternalFilterPresent = () => {
    const { selectedSerialNumber } = this.props

    return selectedSerialNumber !== ''
  }

  render() {
    const { form, height } = this.props

    return (
      <div className="product-serial-number-inquiry-layout-flex">
        <div className="flex-wrapper">
          <GridField
            propertyName="workOrderInfo"
            title="Work Order Information"
            headerStyle={headerStyle}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            onGridReady={this.onGridReady}
            columnDefs={dynamicColDefs(form)}
            getRowNodeId={getRowNodeId}
            height={height}
            rowSelection="single"
            onRowDoubleClicked={this.onRowDoubleClicked}
          />
        </div>
      </div>
    )
  }
}

export const contextObj = {
  selectedSerialNumber: form => getField(form, 'selectedSerialNumber', '')
}

export default withContext(contextObj)(WorkOrder)
