import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import {
  deleteContact,
  lockContactForEdit,
  unlockContactRecord,
  saveContactButton
} from 'pages/ContactMaster/actions'
import { removeModal } from 'modals/actions'
import { getIn } from 'utils'

const marginRight = { marginRight: 5 }
/* eslint-disable import/prefer-default-export */

export class RightFooterButtons extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    id: PropTypes.string,
    isEditing: PropTypes.bool,
    newMode: PropTypes.bool
  }

  static defaultProps = {
    id: '',
    isEditing: false,
    newMode: false
  }

  componentDidUpdate() {
    const { hasRecord, isEditing } = this.props
    if (hasRecord && !isEditing) {
      if (this.edit) this.edit.focus()
    }
  }

  edit = null

  cancel = null

  handleDelete = () => {
    const { hasRecord, isEditing } = this.props

    if (hasRecord && isEditing) {
      this.props.dispatch(deleteContact.try('contact'))
    }
  }

  handleEdit = () => {
    const { form, hasRecord } = this.props

    if (hasRecord) {
      this.props.dispatch(lockContactForEdit.try(form))
    }
  }

  exitModal = () => {
    const { dispatch, form, id, isEditing } = this.props

    if (isEditing) {
      let newForm = form.split('.')[0]
      dispatch(unlockContactRecord.try(form))

      if (form === 'productMaster.vendorMaster.contact') {
        newForm = 'productMaster.vendorMaster'
      }

      dispatch(removeModal(newForm, id))
    }
  }

  saveContact = () => {
    const { dispatch, form, id } = this.props

    dispatch(saveContactButton(form, id))
  }

  render() {
    const { hasRecord, isEditing, newMode } = this.props

    return (
      <>
        {hasRecord && !newMode && !isEditing && (
          <DDIButton
            variant="contained"
            label="Edit"
            onClick={this.handleEdit}
            preventAutoDisable
            tabIndex={0}
            autoFocus
            buttonRef={c => (this.edit = c)}
            style={marginRight}
          />
        )}
        {hasRecord && (isEditing || newMode) && (
          <DDIButton
            onClick={this.saveContact}
            variant="contained"
            label="Save"
            tabIndex={0}
            style={marginRight}
          />
        )}
        {hasRecord && !newMode && isEditing && (
          <DDIButton
            variant="contained"
            label="Delete"
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
            onClick={this.handleDelete}
          />
        )}
        {hasRecord && !newMode && isEditing && (
          <DDIButton
            variant="contained"
            label="Cancel"
            actionName="cancelEdit"
            preventAutoDisable
            tabIndex={0}
            autoFocus
            style={marginRight}
          />
        )}

        {newMode ? (
          <DDIButton
            variant="contained"
            label="Exit"
            onClick={this.exitModal}
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
          />
        ) : (
          <DDIButton
            variant="contained"
            label="Exit"
            actionName={isEditing ? 'cancelEdit' : 'exit'}
            // newMode modal? close out...
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
          />
        )}
      </>
    )
  }
}

export const contextObj = {
  hasRecord: form => getIn(form, 'hasRecord') || false,
  isEditing: form => getIn(form, 'isEditing') || false,
  newMode: form => getIn(form, 'newMode') || false
}

export default withContext(contextObj)(RightFooterButtons)
