/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { debounce } from 'lodash'
import { Popover, Button, IconButton } from '@material-ui/core'
import { DDITextField } from 'ddiForm/wrapped'
import { setField } from 'ddiForm/actions'
import NumberSpinner from 'ddiForm/NumberSpinner'
import { formatNumber } from 'utils'
import useStateWithCallback from 'hooks/useStateWithCallback'

const Calculator = props => {
  const { dispatch, form, isEditing, openTotal, allowanceAmount } = props

  const [state, setState] = useStateWithCallback({
    anchorEl: null,
    result: allowanceAmount,
    spinnerVal: (allowanceAmount / openTotal) * 100
  })

  const onNumberSpinnerChange = debounce(newValue => {
    const result = (newValue / 100) * openTotal

    setState({
      ...state,
      spinnerVal: newValue,
      result
    })
  }, 500)

  const handleOpen = event => {
    setState({
      ...state,
      anchorEl: event.currentTarget,
      spinnerVal: (allowanceAmount / openTotal) * 100,
      result: allowanceAmount
    })
  }

  const handleClose = () => {
    setState({
      ...state,
      anchorEl: null,
      spinnerVal: (allowanceAmount / openTotal) * 100,
      result: allowanceAmount
    })
  }

  const updateInputAmount = () => {
    const { result } = state

    dispatch(setField(form, 'allowanceAmount', result))
    setState({
      ...state,
      anchorEl: null
    })
  }

  const { anchorEl, result, spinnerVal } = state

  const open = Boolean(anchorEl)
  const labelStyle = { minWidth: 50, marginRight: 10, marginBottom: 0 }
  const paragraphStyle = { marginBottom: 0 }
  const flexStyle = {
    display: 'flex',
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: '1px solid #cecece'
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <DDITextField
        propertyName="allowanceAmount"
        disabled={!isEditing}
        mask="numeric"
        inputProps={{
          max: 999999999.99,
          decimalScale: 2,
          fixedDecimalScale: true,
          range: [0, 999999999.99],
          allowNegative: false
        }}
        wrapperStyle={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          zIndex: 1,
          width: '100%',
          height: '100%'
        }}
      />
      <div style={{ position: 'absolute', right: -30, bottom: 5, zIndex: 2 }}>
        <IconButton onClick={handleOpen} size="small" disabled={!isEditing}>
          <i
            className="fa fa-calculator"
            style={{ color: '#444', fontSize: 14 }}
          />
        </IconButton>
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
          <div style={{ padding: 10 }}>
            <div style={flexStyle}>
              <label style={labelStyle}>Total:</label>
              <p style={paragraphStyle}>{formatNumber(openTotal)}</p>
            </div>
            <div style={flexStyle}>
              <label style={labelStyle}>Percent:</label>
              <NumberSpinner
                initialValue={Number.isFinite(spinnerVal) ? spinnerVal : 0}
                value={Number.isFinite(spinnerVal) ? spinnerVal : 0}
                step={1}
                max={99.99}
                inputProps={{
                  decimalScale: 2,
                  fontSize: 13,
                  fixedDecimalScale: true,
                  suffix: '%'
                }}
                wrapperStyle={{ margin: 0 }}
                isField={false}
                updateValueCallback={onNumberSpinnerChange}
              />
            </div>
            <div style={flexStyle}>
              <label style={labelStyle}>Result:</label>
              <p style={paragraphStyle}>
                {Number.isFinite(result) ? formatNumber(result) : null}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={updateInputAmount} variant="contained">
                OK
              </Button>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default Calculator
