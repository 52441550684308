/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const getShippingConfirmation = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/shipconfirmation/editlock`, options)
}

export const cancelShippingConfirmation = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/shipconfirmation/canceledit`,
    options
  )
}

export const shipConfPropertyChange = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/shipconfirmation/propertychange`,
    options
  )
}

export const deleteShippingConfirmation = ({ dataId }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      dataId
    }
  })

  return callFetch(`${BASE_INFORM_API_URL}/shipconfirmation/delete`, options)
}

export const printShippingConfirmation = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/shipconfirmation/print`, options)
}

export const saveShippingConfirmation = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(`${BASE_INFORM_API_URL}/shipconfirmation/update`, options)
}

export const updateShipConfNotes = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/shipconfirmation/notes/save`,
    options
  )
}
