import { call, fork, put, select, take } from 'redux-saga/effects'
import { SET_FIELD } from 'ddiForm/constants'
import { exit } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn } from 'utils'
import { addModal, removeModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'

import GridInterface from './components/GridInterface'
import ActionButtons from './components/ActionButtons'
import * as CONSTANTS from './constants'
import * as actions from './actions'
import * as api from './api'

export const formRouteMapper = form => {
  const routes = {
    productFieldsUpdate: 'product',
    productMaster: 'product',
    vendorFieldsUpdate: 'vendor',
    vendorMaster: 'vendor'
  }

  if (routes[form]) {
    return routes[form]
  }

  return null
}

export function* displayCorporateFieldUpdatesModalProcess(
  form,
  route,
  response
) {
  const options = {
    component: GridInterface,
    options: {
      data: {
        form,
        fields: response?.fields,
        isModal: true,
        responseData: { ...response }
      },
      actions: ActionButtons,
      maxHeight: '100%',
      marginTop: 25,
      title: response?.displayName || 'Corporate Field Updates',
      width: 600
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
}

export function* readCorporateFieldUpdatesDataProcess(
  form,
  route,
  openModalOnComplete = false,
  dataIdPropertyName = 'dataId',
  templateKey = null
) {
  // debugger
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, `fields.${dataIdPropertyName}.value`)
  const apiParams = templateKey
    ? {
        templateKey,
        route
      }
    : {
        dataId,
        route
      }

  yield put(actions.readCorporateFieldUpdatesData.request(form))

  const { response, error } = yield call(
    api.readCorporateFieldUpdatesData,
    apiParams
  )

  if (response) {
    // debugger
    yield put(
      actions.readCorporateFieldUpdatesData.success(
        {
          ...response,
          templateKey,
          isModal: openModalOnComplete
        },
        form
      )
    )

    if (openModalOnComplete) {
      yield fork(
        displayCorporateFieldUpdatesModalProcess,
        form,
        route,
        response
      )
    }
  } else {
    yield put(actions.readCorporateFieldUpdatesData.failure(error, form))
    if (error?.message) {
      yield call(warningModal, error.message, 'Error!')
    }
  }
}

export function* getCorporateFieldUpdatesMetaProcess(
  form,
  openModalOnComplete = false
) {
  yield put(actions.getCorporateFieldUpdatesMeta.request(form))
  const route = formRouteMapper(form)

  if (!route) {
    return
  }

  const { response, error } = yield call(api.getCorporateFieldUpdatesMeta, {
    route
  })

  if (response) {
    yield put(actions.getCorporateFieldUpdatesMeta.success(response, form))
    if (openModalOnComplete) {
      yield fork(
        readCorporateFieldUpdatesDataProcess,
        form,
        route,
        openModalOnComplete
      )
    }
  } else {
    yield put(actions.getCorporateFieldUpdatesMeta.failure(error, form))
  }
}

export function* getCorporateFieldUpdatesMetaListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { openModalOnComplete = false }
    } = yield take(CONSTANTS.GET_CORPORATE_FIELD_UPDATES_META.TRY)

    if (form === formListener) {
      yield fork(getCorporateFieldUpdatesMetaProcess, form, openModalOnComplete)
    }
  }
}

export function* toggleEditCorporateFieldsProcess(
  form,
  action,
  modalId,
  isModal = false,
  clearGridOnSuccess = false,
  retainData = false,
  exitScreenOnSuccess = false
) {
  const formState = yield select(getFormSelector(form))
  const dataId = isModal
    ? getIn(formState, 'fields.corporateFieldUpdates.dataId.value')
    : getIn(formState, 'fields.dataId.value')
  const templateKey = isModal
    ? getIn(formState, 'values.corporateFieldUpdates.templateKey')
    : getIn(formState, 'values.templateKey')

  if (action === 'cancelEditCorporateFieldUpdatesData') {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Close?'
    )

    const confirmationModalAction = yield take([CONFIRMED, CANCELED])
    if (confirmationModalAction.type === CANCELED) {
      return
    }
  }

  yield put(actions[action].request(form))
  const route = formRouteMapper(form)

  if (!route) {
    return
  }

  const apiParams = templateKey
    ? {
        templateKey,
        route
      }
    : {
        dataId,
        route
      }

  const { response, error } = yield call(api[action], apiParams)

  if (response) {
    const resp =
      clearGridOnSuccess || retainData
        ? {
            ...response,
            clearGridOnSuccess,
            retainData
          }
        : response

    yield put(actions[action].success(resp, form))
    if (modalId && action === 'cancelEditCorporateFieldUpdatesData') {
      yield put(removeModal(form, modalId))
    }

    if (
      exitScreenOnSuccess &&
      action === 'cancelEditCorporateFieldUpdatesData'
    ) {
      yield put(exit(form))
    }
  } else {
    yield put(actions[action].failure(error, form))
  }
}

export function* toggleEditCorporateFieldsListener(formListener, isModal) {
  while (true) {
    const {
      meta: { form },
      payload: {
        modalId = null,
        clearGridOnSuccess = false,
        retainData = false,
        exitScreenOnSuccess = false
      },
      type
    } = yield take([
      CONSTANTS.EDIT_CORPORATE_FIELD_UPDATES_DATA.TRY,
      CONSTANTS.CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA.TRY
    ])

    if (form === formListener) {
      const action =
        type === CONSTANTS.EDIT_CORPORATE_FIELD_UPDATES_DATA.TRY
          ? 'editCorporateFieldUpdatesData'
          : 'cancelEditCorporateFieldUpdatesData'

      yield fork(
        toggleEditCorporateFieldsProcess,
        form,
        action,
        modalId,
        isModal,
        clearGridOnSuccess,
        retainData,
        exitScreenOnSuccess
      )
    }
  }
}

export function* setFieldListener(formListener, isModal) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(SET_FIELD)

    const listenerKey = isModal ? 'corporateFieldUpdates.dataId' : 'dataId'
    const openModalOnComplete = !isModal && listenerKey !== 'dataId'
    if (form === formListener && propertyName === listenerKey) {
      if (value) {
        // debugger
        yield fork(
          readCorporateFieldUpdatesDataProcess,
          form,
          formRouteMapper(form),
          openModalOnComplete,
          listenerKey
        )
      } else {
        yield put(actions.setRowData(form, { rowData: [] }))
      }
    }
  }
}

export function* destroyModalProcess(form, modalId) {
  yield put(removeModal(form, modalId))
  yield put(actions.clearValues(form))
  yield call(api.closeCorporateFieldUpdatesModal, {
    route: formRouteMapper(form)
  })
}

export function* destroyModalListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(CONSTANTS.DESTROY_MODAL)

    if (form === formListener) {
      yield fork(destroyModalProcess, form, modalId)
    }
  }
}

export function* saveCorproateFieldUpdatesDataProcess(
  form,
  isModal,
  modalId = null
) {
  const formState = yield select(getFormSelector(form))
  const dataId = isModal
    ? getIn(formState, 'fields.corporateFieldUpdates.dataId.value')
    : getIn(formState, 'fields.dataId.value')
  let fields = isModal
    ? getIn(formState, 'values.corporateFieldUpdates.fields')
    : getIn(formState, 'values.fields')
  fields = fields?.toJS ? fields.toJS() : []
  const templateKey = isModal
    ? getIn(formState, 'values.corporateFieldUpdates.templateKey')
    : getIn(formState, 'values.templateKey')

  const route = formRouteMapper(form)

  if (!route) {
    return
  }

  const apiParams = templateKey
    ? {
        templateKey,
        route,
        properties: { fields }
      }
    : {
        dataId,
        route,
        properties: { fields }
      }

  const { response, error } = yield call(
    api.saveCoprorateFieldUpdatesData,
    apiParams
  )

  if (response) {
    yield put(
      actions.saveCoprorateFieldUpdatesData.success(
        {
          ...response,
          isModal
        },
        form
      )
    )

    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put(actions.saveCoprorateFieldUpdatesData.failure(error, form))
  }
}

export function* saveCorporateFieldUpdatesDataListener(formListener, isModal) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(CONSTANTS.SAVE_CORPORATE_FIELD_UPDATES_DATA.TRY)

    if (form === formListener) {
      yield fork(saveCorproateFieldUpdatesDataProcess, form, isModal, modalId)
    }
  }
}

export function* readCorporateFieldUpdatesDataListener(formListener, isModal) {
  while (true) {
    const {
      meta: { form },
      payload: { templateKey }
    } = yield take(CONSTANTS.READ_CORPORATE_FIELD_UPDATES_DATA.TRY)

    if (form === formListener && templateKey) {
      const listenerKey = isModal ? 'corporateFieldUpdates.dataId' : 'dataId'
      const openModalOnComplete = !isModal && listenerKey !== 'dataId'
      yield put(actions.setRowData(form, { rowData: [] }))
      yield fork(
        readCorporateFieldUpdatesDataProcess,
        form,
        formRouteMapper(form),
        openModalOnComplete,
        listenerKey,
        templateKey
      )
    }
  }
}

export default function* corporateFieldUpdatesSagas(form, isModal = false) {
  yield fork(getCorporateFieldUpdatesMetaListener, form)
  yield fork(toggleEditCorporateFieldsListener, form, isModal)
  yield fork(setFieldListener, form, isModal)
  yield fork(destroyModalListener, form)
  yield fork(saveCorporateFieldUpdatesDataListener, form, isModal)
  yield fork(readCorporateFieldUpdatesDataListener, form, isModal)
}
