import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { voidPayment } from 'pages/SalesOrder/actions'
import { debounce } from 'lodash'

export class VoidPaymentCell extends Component {
  voidPayment = debounce(() => {
    /* 
      NOTE: this.props.rowIndex can be wrong, 
      but this.props.node.rowIndex is correct.
      Weird -- SVE
    */
    const {
      data: { promptForVoid = false },
      dispatch,
      form,
      node: { rowIndex }
    } = this.props

    dispatch(
      voidPayment.try(form, {
        index: rowIndex,
        promptForVoid
      })
    )
  }, 300)

  render() {
    const { isEditing } = this.props

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icon
          style={{
            color: isEditing ? '#d9534f' : '#999',
            display: 'inline-block',
            fontSize: 18,
            marginLeft: -5
          }}
          onClick={this.voidPayment}
          disabled={!isEditing}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default VoidPaymentCell
