import React from 'react'
import { addScreenInModal } from 'ddiForm/actions'
import CustomerShipTo from 'pages/CustomerShipTo'
import { read } from 'services/api'
import {
  onOpenShipToProcess,
  onOpenShipToFailureProcess
} from 'pages/CustomerShipTo/sagas'
import ShipToTitleBar from 'pages/CustomerShipTo/components/ShipToTitleBar'

const addShipToInModal = (
  apiArgs = {},
  form = 'customerMaster',
  apiMethod = read
) =>
  addScreenInModal(form, {
    screen: CustomerShipTo,
    formName: 'customerShipTo',
    apiMethod,
    apiArgs,
    onApiSuccess: onOpenShipToProcess,
    onApiError: onOpenShipToFailureProcess,
    modalOptions: {
      title: ShipToTitleBar,
      hideActions: true,
      maxSize: true
    }
  })

export default addShipToInModal
