import React from 'react'
import PropTypes from 'prop-types'

const DisplayOnlyCell = ({ textAlign, value, valueFormatted }) => (
  <div style={{ textAlign, width: '100%', height: '100%' }}>
    {valueFormatted || value}
  </div>
)

DisplayOnlyCell.propTypes = {
  textAlign: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueFormatted: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

DisplayOnlyCell.defaultProps = {
  textAlign: 'left',
  value: '',
  valueFormatted: ''
}

export default DisplayOnlyCell
