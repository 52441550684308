import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import useStateWithCallback from 'hooks/useStateWithCallback'

import {
  Button,
  Icon,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core'

/* grid components */
import DecimalCellEditor from 'components/grid/DecimalCellEditor'
import DatepickerGridCell from 'components/EditableGrid/components/DatepickerGridCell'

import DeleteButton from './components/DeleteButton'
import ActionButtons from './components/ActionButtons'
import BackorderDataTable from './components/BackorderDataTable'
import { handleBackorderCommitmentSchedule } from '../../actions'

import '../../styles/modal-flex-button-styles.scss'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

let gridApi = null
let columnApi = null
let _isMounted = false

const BackorderCommitmentScheduleModal = props => {
  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const dispatch = useDispatch()

  const blankRow = {
    quantity: null,
    shipDate: null,
    commitDate: null,
    rowId: 'blankrow'
  }

  const {
    data: {
      form,
      commitBOQuantity,
      balance,
      quantityBO,
      quantityBOComm,
      quantityBOUncomm,
      canEnterSchedule,
      isEditable,
      isEditing,
      scheduledOrders = []
    },
    id
  } = props

  const [state, setState] = useStateWithCallback({
    canEnterSchedule,
    commitBOQuantity,
    balance,
    quantityBO,
    quantityBOComm,
    quantityBOUncomm,
    scheduledOrders:
      scheduledOrders && Array.isArray(scheduledOrders) ? scheduledOrders : []
  })

  const beginEditingNewRow = () => {
    setTimeout(() => {
      if (_isMounted && gridApi) {
        console.log('focusedCell', gridApi.getFocusedCell())
        gridApi.forEachNode(node => {
          if (
            (node?.data?.rowId === 'blankrow' && !node?.data?.quantity) ||
            (node?.data?.lineNumber === 0 && !node?.data?.quantity)
          ) {
            const { rowIndex } = node
            gridApi.setFocusedCell(rowIndex, 'quantity')
            gridApi.startEditingCell({
              rowIndex,
              colKey: 'quantity'
            })
          }
        })
      }
    }, 0)
  }

  const updateStateAfterApiTransaction = (
    result,
    bypassBlankRowCheck = false
  ) => {
    const hasBlankRow =
      !bypassBlankRowCheck &&
      state.scheduledOrders.find(x => x?.rowId && x?.rowId === 'blankrow')

    setState(
      {
        ...state,
        ...result,
        scheduledOrders:
          result?.scheduledOrders && Array.isArray(result?.scheduledOrders)
            ? hasBlankRow
              ? [
                  ...result.scheduledOrders,
                  {
                    lineNumber: result.scheduledOrders.length
                      ? result.scheduledOrders.length
                      : 0,
                    ...blankRow
                  }
                ]
              : result.scheduledOrders
            : state.scheduledOrders || []
      },
      () => beginEditingNewRow()
    )
  }

  const onCellValueChanged = async params => {
    if (
      ((params.newValue || params.newValue === 0) &&
        Number(params.newValue) === Number(params.oldValue)) ||
      params.newValue === params.oldValue ||
      !_isMounted
    ) {
      return
    }

    const lineNum = params?.data?.lineNumber
    const isBlankRowChange = params?.data?.rowId === 'blankrow'

    let result
    try {
      result = await dispatch(
        handleBackorderCommitmentSchedule.try(form, {
          apiParams: {
            lineNumber: lineNum,
            properties: {
              [params?.colDef?.colId]: params.newValue
            }
          },
          gridApi: params.api
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      console.log(result)
      updateStateAfterApiTransaction(result, isBlankRowChange)
    }
  }

  const onClickDeleteButton = async (e, lineItemData) => {
    if (lineItemData?.rowId && lineItemData.rowId === 'blankrow') {
      await setState({
        ...state,
        scheduledOrders: state.scheduledOrders.filter(
          x => x?.rowId !== 'blankrow'
        )
      })

      return
    }

    let result
    try {
      result = await dispatch(
        handleBackorderCommitmentSchedule.try(form, {
          apiParams: {
            lineNumber: lineItemData.lineNumber,
            properties: {
              delete: lineItemData.lineNumber
            }
          }
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      console.log(result)
      updateStateAfterApiTransaction(result)
    }
  }

  const onClickLoadScheduleButton = async e => {
    let result
    try {
      result = await dispatch(
        handleBackorderCommitmentSchedule.try(form, {
          apiParams: {
            properties: {
              loadSchedule: true
            }
          }
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      console.log(result)
      updateStateAfterApiTransaction(result, true)
    }
  }

  const onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column && params.column.colId
      })
    }
  }

  const columnDefs = [
    {
      field: 'quantity',
      colId: 'quantity',
      headerName: 'Qty',
      cellEditor: 'decimalCellEditor',
      editable: state.canEnterSchedule,
      onCellValueChanged,
      onCellFocused,
      ...rightAlignParams
    },
    {
      field: 'shipDate',
      colId: 'shipDate',
      headerName: 'Requested Ship Date',
      cellRendererFramework: DatepickerGridCell,
      cellRendererParams: {
        form,
        isDisabled: !state.canEnterSchedule
      },
      onCellValueChanged,
      editable: false,
      suppressNavigable: true
    },
    {
      field: 'commitDate',
      colId: 'commitDate',
      headerName: 'Commit Date',
      cellRendererFramework: DatepickerGridCell,
      cellRendererParams: {
        form,
        isDisabled: !state.canEnterSchedule
      },
      onCellValueChanged,
      editable: false,
      suppressNavigable: true
    },
    {
      field: 'lineNumber',
      colId: 'lineNumber',
      headerName: '',
      cellRendererFramework: DeleteButton,
      cellRendererParams: {
        form,
        onClickDeleteButton,
        isDisabled: !state.canEnterSchedule
      },
      maxWidth: 50
    }
  ]

  const onToggleSwitch = async event => {
    setState({ ...state, [event.target.name]: event.target.checked })

    let result
    try {
      result = await dispatch(
        handleBackorderCommitmentSchedule.try(form, {
          apiParams: {
            properties: {
              [event.target.name]: event.target.checked
            }
          }
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      updateStateAfterApiTransaction(result)
    }
  }

  const addBlankRow = debounce(e => {
    if (
      state.scheduledOrders &&
      Array.isArray(state.scheduledOrders) &&
      state.scheduledOrders?.every(x => x.quantity)
    ) {
      setState(
        {
          ...state,
          scheduledOrders: [
            ...state.scheduledOrders,
            {
              lineNumber: state.scheduledOrders.length
                ? state.scheduledOrders.length
                : 0,
              ...blankRow
            }
          ]
        },
        () => beginEditingNewRow()
      )
    } else if (
      state.scheduledOrders &&
      Array.isArray(state.scheduledOrders) &&
      !state.scheduledOrders.length
    ) {
      setState(
        {
          ...state,
          scheduledOrders: [
            {
              lineNumber: 0,
              ...blankRow
            }
          ]
        },
        () => beginEditingNewRow()
      )
    } else if (!state.scheduledOrders) {
      setState(
        {
          ...state,
          scheduledOrders: [
            {
              lineNumber: 0,
              ...blankRow
            }
          ]
        },
        () => beginEditingNewRow()
      )
    }
  }, 200)

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div
        className="sales-order-entry-modal-primary-interface-wrapper"
        style={{ paddingBottom: 30 }}
      >
        <div style={{ width: '100%' }}>
          <BackorderDataTable
            quantityBO={state.quantityBO}
            quantityBOComm={state.quantityBOComm}
            quantityBOUncomm={state.quantityBOUncomm}
          />
          <div style={{ padding: '0  15px' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.commitBOQuantity}
                    onChange={onToggleSwitch}
                    name="commitBOQuantity"
                    // disabled={!isEditable}
                  />
                }
                label="Commit Backorder Quantities"
              />
            </FormGroup>
          </div>
          <Grid
            headerName="Line Items"
            columnDefs={columnDefs}
            rowData={state.scheduledOrders}
            getRowNodeById={data => data.lineNumber}
            addBlankRow={addBlankRow}
            height={250}
            onGridReady={onGridReady}
            components={{
              decimalCellEditor: DecimalCellEditor
            }}
            singleClickEdit
          />
          <div style={{ display: state.canEnterSchedule ? 'block' : 'none' }}>
            <Button
              variant="contained"
              onClick={addBlankRow}
              size="small"
              color="secondary"
              tabIndex={-1}
              disabled={!state.canEnterSchedule}
            >
              <Icon style={{ fontSize: 16, marginRight: 5 }}>add_circle</Icon>
              <span>Add B/O Scheduled Order</span>
            </Button>
          </div>
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        balance={state.balance}
        onClickLoadScheduleButton={onClickLoadScheduleButton}
        isEditing={isEditing}
      />
    </div>
  )
}

export default BackorderCommitmentScheduleModal
