/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import memoize from 'memoize-one'
import EditableGrid from 'components/EditableGrid'
import { headerStyle, getRowNodeId } from 'pages/ProductMaster/utils'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import TooltipField from 'hoc/TooltipField'
import { setSelectedGroup, setAutoAddToSO } from 'pages/ProductMaster/actions'
import {
  plainDeepEqual,
  getField,
  getIn,
  getValue,
  emptyList,
  fromJS
} from 'utils'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

const getRowIds = rowData =>
  rowData.reduce((acc, next) => {
    acc = acc.concat(next.rowId)
    return acc
  }, [])

export const dynamicColumnDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'optionsAndAccessoriesGroups',
      onChange: onCellChange,
      propertyName,
      meta: {
        allowSearchAll: true
      }
    }

    // const textEditor = {
    //   cellRendererFramework: TextFieldGridCell,
    //   cellRendererParams: params
    // }

    const colDefs = [
      {
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Product',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          linkTo: 'productOptionsAndAccessories'
        },
        field: 'dataId',
        headerName: 'Group',
        maxWidth: 200
      },
      {
        editable: false,
        field: 'description',
        headerName: 'Description',
        cellStyle: {
          color: isEditing ? '#444' : '#777'
        }
      },
      {
        field: 'autoAddToSO',
        checkboxSelection: true,
        cellClass:
          'cell-value-hidden text-center center-align center-align-checkbox-cell',
        headerClass: 'text-center center-align center-align-checkbox-header',
        headerCheckboxSelection: true,
        headerName: 'Auto Add To S/O',
        editable: false,
        suppressMenu: true,
        maxWidth: 150
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        field: 'delete',
        id: 'delete',
        hide: !isEditing,
        headerName: '',
        maxWidth: 50,
        width: 50
      }
    ]

    return colDefs
  }
)

export class OptionsAndAccessories extends Component {
  static propTypes = {
    accessoryGroups: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    isEditing: PropTypes.bool.isRequired,
    selectedGroup: PropTypes.object
  }

  static defaultProps = {
    selectedGroup: {
      accessories: [],
      title: ''
    }
  }

  componentDidMount() {
    const {
      form,
      dispatch,
      selectedGroup: { accessories = [] }
    } = this.props
    let { accessoryGroups } = this.props
    accessoryGroups = accessoryGroups.toJS
      ? accessoryGroups.toJS()
      : accessoryGroups

    if (!accessories.length && accessoryGroups && accessoryGroups.length) {
      /* set a default on mount if we have none set */
      dispatch(setSelectedGroup(form, accessoryGroups[0]))
    }
  }

  // shouldComponentUpdate(nextProps) {
  //   return !deepEqual(nextProps, this.props)
  // }

  componentDidUpdate(prevProps, prevState) {
    const { dataId, accessoryGroups, isEditing } = this.props

    /*
      this means that the user has deleted a row,
      works in conjunction with deleteGridRowListener
      in optionsAndAccessoriesSagas
    */
    if (
      accessoryGroups &&
      accessoryGroups.length &&
      prevProps.accessoryGroups &&
      prevProps.accessoryGroups.length &&
      !plainDeepEqual(accessoryGroups, prevProps.accessoryGroups) &&
      this.gridApi
    ) {
      setTimeout(() => {
        this.setSelectedAccessoryGroups()
      }, 0)
    }

    if (
      accessoryGroups &&
      accessoryGroups.length &&
      prevProps.accessoryGroups &&
      prevProps.accessoryGroups.length &&
      accessoryGroups.length < prevProps.accessoryGroups.length
    ) {
      this.loadSelectedGroup()
    }
  }

  loadSelectedGroup = (rowIndex = 0) => {
    const { accessoryGroups, dispatch, form } = this.props
    // debugger
    if (accessoryGroups && accessoryGroups[rowIndex]) {
      dispatch(setSelectedGroup(form, accessoryGroups[rowIndex]))
    } else {
      /* no accessoryGroups condition */
      dispatch(setSelectedGroup(form, { dataId: null, accessories: [] }))
    }
  }

  onGridReady = params => {
    this.gridApi = params.api

    this.setSelectedAccessoryGroups()
  }

  setSelectedAccessoryGroups = () => {
    if (this.gridApi) {
      this.gridApi.forEachNode(node => {
        if (node.data.autoAddToSO) {
          node.setSelected(true)
        } else {
          node.setSelected(false)
        }
      })
    }
  }

  onRowSelected = params => {
    const { api } = params

    const selected = api.getSelectedRows()
    const selectedRowIds = getRowIds(selected)

    // console.log('onRowSelected', params, selected, selectedRowIds)
    this.dispatchRowSelections(selectedRowIds)
  }

  dispatchRowSelections = debounce((rowIds = []) => {
    const { dispatch, form } = this.props
    dispatch(setAutoAddToSO(form, { rowIds }))
  }, 500)

  onRowClicked = params => {
    const { dispatch, form } = this.props
    const { data } = params

    const isDeleteButton = Boolean(
      params?.event?.target?.className?.match(/material-icons/gi)
    )
    // console.log('onRowClicked', isDeleteButton, params)

    if (data && data.accessories && !isDeleteButton) {
      dispatch(setSelectedGroup(form, data))
    }
  }

  getGroupProductCols = memoize(isEditing => {
    const { form } = this.props
    const params = {
      disabled: !isEditing,
      form
    }

    return [
      {
        field: 'dataId',
        headerName: 'Product',
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Product',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          // linkTo: 'productMaster'
          linkTo: 'productAnalysis'
        }
      },
      {
        editable: false,
        field: 'description',
        headerName: 'Description',
        cellStyle: {
          color: isEditing ? '#444' : '#777'
        },
        suppressNavigable: true
      },
      {
        editable: false,
        field: 'uomId',
        headerName: 'UOM',
        cellStyle: {
          color: isEditing ? '#444' : '#777'
        }
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        headerClass: 'align-right',
        cellStyle: {
          color: isEditing ? '#444' : '#777'
        },
        cellClass: 'align-right'
      }
    ]
  })

  render() {
    const {
      accessoryGroups,
      form,
      height,
      isEditing,
      selectedGroup
    } = this.props
    const { title, accessories } = selectedGroup.toJS()
    /* this stuff with the style declaration is super important */
    const highlightedRow =
      title && accessoryGroups.size
        ? accessoryGroups.find(x => x.get('dataId') === title)
        : fromJS({ rowId: null })

    const highlightedRowId =
      highlightedRow?.get && highlightedRow?.get('rowId')
        ? highlightedRow.get('rowId')
        : null
    const styleDeclaration = `.accessory-groups-grid-wrapper .ag-theme-balham .ag-body-viewport .ag-row[row-id="${highlightedRowId}"] {
      background: #d5edd5 !important;
      border-color: #d5edd5 !important;
    }`
    const disabledDeclaration = `
      .accessory-groups-grid-wrapper .ag-theme-balham .ag-header-select-all,
      .accessory-groups-grid-wrapper .ag-theme-balham .ag-selection-checkbox {
        pointer-events: ${isEditing ? 'auto' : 'none'};
        opacity: ${isEditing ? '1' : '0.5'};
    }`

    return (
      <>
        <style type="text/css">
          {styleDeclaration}
          {disabledDeclaration}
        </style>
        <div className="layout-flex-column">
          {/* Add New Group in next iteration
        <div className="flex-button-wrapper">
          <DDIButton
            label="Add New Group"
            actionName="addNewGroup"
            variant="contained"
          />
        </div>
        */}
          <div className="accessory-groups-grid-wrapper overflow-wrapper">
            <EditableGrid
              propertyName="accessoryGroups"
              addButtonText="Add Group"
              title="Option & Accessory"
              headerStyle={headerStyle}
              getRowNodeId={getRowNodeId}
              emptyRow={{
                dataId: null,
                description: '',
                autoAddToSO: false
              }}
              form={form}
              focusCell="dataId"
              isEditing={isEditing}
              columnDefs={dynamicColumnDefs}
              requiredCols={['dataId']}
              rowSelection="single"
              onGridReady={this.onGridReady}
              onRowSelected={this.onRowSelected}
              onRowClicked={this.onRowClicked}
              height={height * 0.5}
              addBlankRowOnLoad
            />
          </div>

          {/* Group Products Grid */}
          <div className="overflow-wrapper">
            <div style={{ height: height * 0.5 }}>
              <Grid
                rowData={accessories}
                title={title ? `Group ${title} Products` : 'Products'}
                headerStyle={headerStyle}
                getRowNodeId={data => data.dataId}
                columnDefs={this.getGroupProductCols(isEditing)}
                height={height * 0.5}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export const contextObj = {
  dataId: form => getField(form, 'dataId', ''),
  // accessoryGroups: form => getField(form, 'accessoryGroups', emptyList, true),
  accessoryGroups: form =>
    getIn(form, 'fields.accessoryGroups.rowData') ||
    getIn(form, 'values.accessoryGroups') ||
    emptyList,
  isEditing: form => form.get('isEditing') || false,
  selectedGroup: form =>
    getValue(form, 'selectedGroup', Map({ accessories: [], title: '' }))
}

const optsAccessoriesWithContext = withContext(contextObj)(
  OptionsAndAccessories
)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  optsAccessoriesWithContext
)
