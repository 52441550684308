import React from 'react'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'

const marginRight = { marginRight: 5 }

const LeftFooterButtons = ({ isEditing, isPosting, form }) => {
  return (
    <>
      <DDIButton
        variant="contained"
        label="Labels"
        actionName="getLabels"
        preventAutoDisable
        tabIndex={0}
        disabled={isEditing || isPosting}
      />
    </>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false
})(LeftFooterButtons)
