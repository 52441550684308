import {
  actionChannel,
  call,
  fork,
  take,
  put,
  select
} from 'redux-saga/effects'
import { api } from 'services'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import {
  EXACT_MATCH_SEARCH,
  FIND_NEXT,
  FIND_PREV
} from 'components/Search/IndexSearch/constants'
import { LAUNCH_NOTES_MODAL } from 'pages/ProductMaster/constants'
import { addModal, confirm, removeModal } from 'modals/actions'
import { confirmationModal, warningModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { ADD_BLANK_ROW, BLUR, SET_FIELD } from 'ddiForm/constants'
import {
  CANCEL_EDIT,
  LOCK_FOR_EDIT,
  GET_ENTITY
} from 'ddiForm/MasterScreen/constants'
import { tryChangeFormTab, setField } from 'ddiForm/actions'
import { getErrorMessages } from 'pages/ProductMaster/utils'
// import { setFocusedCell } from 'components/EditableGrid/actions'
import { searchProcess } from 'ddiForm/MasterScreen/sagas'

import { UPDATE_GRID_CELL_DATA } from 'components/EditableGrid/constants'
import {
  preCancelEdit,
  cancelEditAsync,
  getEntityAsync,
  resetMasterFields
} from 'ddiForm/MasterScreen/actions'

import SerialNotesModal from 'pages/ProductSerialNumberInquiry/components/SerialNotesModal'

import editableGridSagas from 'components/EditableGrid/sagas'
import { TRY_OPEN_SCREEN } from 'pages/Main/constants'

import { groupNamesMap } from './utils'
import NotesModalActions from './components/NotesModalActions'

import * as actions from './actions'
import * as CONSTANTS from './constants'
import * as localApi from './api'

export function* exactMatchSearchSuccessListener(formListener) {
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.SUCCESS)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (payload && payload.exactMatchResults) {
        yield fork(updateDescriptionProcess, form, payload)
      }
    }

    if (
      payload &&
      payload.propertyName === 'currentInventory.editableGridIndexSearchField'
    ) {
      yield fork(addSerialNumberProcess, 'productSerialNumberInquiry', payload)
    }
  }
}

export function* entitySuccessListener(formListener) {
  while (true) {
    const action = yield take(GET_ENTITY.SUCCESS)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(entitySuccessProcess, form, payload)
    }
  }
}

export function* entitySuccessProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'values.productId') || null
  const description = getIn(formState, 'fields.description.value') || ''

  yield fork(checkForNullResponse, form, payload)

  if (productId && !description) {
    const { response, error } = yield call(api.exactMatchSearch, {
      indexSearchType: 'product',
      keyword: productId
    })

    if (response?.exactMatchResults) {
      yield put(
        setField(form, 'description', response.exactMatchResults.description)
      )
    }
  }
}

// NOTE: This process is needed because when response comes back with a null data, it does not update the property
// in the store to empty array/obj
// ex. searching for serial number with a sales history, and then searching for a different serial number
// that has no sales history
// Can be deleted in future - LL
export function* checkForNullResponse(form, payload) {
  const formState = yield select(getFormSelector(form))

  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  // const data = getIn(formState, `fields.${selectedPrimaryTab}.rowData`) ?
  //   getIn(formState, `fields.${selectedPrimaryTab}.rowData`) : []

  try {
    const prop = groupNamesMap[selectedPrimaryTab]
      ? groupNamesMap[selectedPrimaryTab]
      : selectedPrimaryTab
    const newData = payload[prop]

    if (newData === null) {
      yield put(actions.clearFields(form, { prop }))
    }
    yield put(actions.setSelectedSerialNumber(form, { value: '' }))
  } catch (e) {
    throw new Error(e)
  }

  return true
}

export function* updateDescriptionProcess(form, payload) {
  const { exactMatchResults } = payload

  if (exactMatchResults) {
    yield put(
      actions.updateDescription(form, {
        description: exactMatchResults.description
      })
    )
  }
}

export function* saveArgumentsSaga(form) {
  const formState = yield select(getFormSelector(form))

  const productId = getIn(formState, 'fields.productId.value')
    ? getIn(formState, 'fields.productId.value')
    : ''
  const serialNumberId = getIn(formState, 'fields.selectedSerialNumber.value')
    ? getIn(formState, 'fields.selectedSerialNumber.value')
    : ''

  return {
    productId,
    serialNumberId,
    groupNames: ['currentInventory']
  }
}

export function* launchNotesModalListener(formListener) {
  while (true) {
    const action = yield take(LAUNCH_NOTES_MODAL)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openNotesModal, form, payload)
    }
  }
}

export function* openNotesModal(form, payload) {
  const { propertyName, rowIndex } = payload

  const formState = yield select(getFormSelector(form))

  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const dataId = getIn(formState, 'fields.productId.value') || ''
  const data = getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    : {}
  const note = getIn(data, 'notes')
  const serialNumberId = getIn(data, 'dataId')
  const description = getIn(data, 'description') || dataId
  const newData = getIn(data, 'newData') ? getIn(data, 'newData') : false
  const warehouseId = getIn(data, 'warehouseId') || null

  const currentInventoryTitle = `Serial Number - ${serialNumberId} "${description}, Product - ${dataId}, Whse - ${warehouseId}" Internal Notes`
  const salesHistoryTitle = `Serial Number - ${serialNumberId} "${description}, Product - ${dataId}" Internal Notes`
  const commonTitle = `Serial Number - ${serialNumberId} "Product - ${dataId}" Internal Notes`
  const titlesDictionary = {
    currentInventory: currentInventoryTitle,
    salesHistory: salesHistoryTitle,
    purchaseOrderHistory: commonTitle,
    transferHistory: commonTitle,
    workOrder: commonTitle,
    usedForWorkOrder: commonTitle,
    completeSerialHistory: commonTitle
  }

  const modalOpts = {
    component: SerialNotesModal,
    options: {
      actions: NotesModalActions,
      form,
      propertyName,
      rowIndex,
      serialNumberId,
      newData,
      data: {
        note,
        propertyName,
        rowIndex,
        serialNumberId,
        newData
      },
      title: titlesDictionary[selectedPrimaryTab]
    }
  }
  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* lockSerialForEditListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LOCK_SERIAL_FOR_EDIT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(lockSerialForEditProcess, form, payload)
    }
  }
}

export function* lockSerialForEditProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { propertyName, rowIndex } = payload

  const serialData = getIn(
    formState,
    `fields.${propertyName}.rowData[${rowIndex}]`
  )
    ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    : {}
  const serialNumberId = getIn(serialData, 'dataId')
  const productId = getIn(formState, 'values.productId')
  const selectedPrimaryTab =
    getIn(formState, 'masterOptions.selectedPrimaryTab') || 'currentInventory'

  const groupName = groupNamesMap[selectedPrimaryTab]
    ? groupNamesMap[selectedPrimaryTab]
    : selectedPrimaryTab

  yield put(actions.lockSerialForEdit.request(form))

  const { error, response } = yield call(localApi.editNotes, {
    productId,
    serialNumberId,
    groupName
  })

  if (response) {
    yield put(
      actions.lockSerialForEdit.success({ propertyName, rowIndex }, form)
    )
  } else {
    yield put(actions.lockSerialForEdit.failure(error, form))
  }
}

export function* unlockSerialListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.UNLOCK_SERIAL.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(unlockSerialProcess, form, payload)
    }
  }
}

export function* unlockSerialProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { propertyName, rowIndex, modalId } = payload

  const serialData = getIn(
    formState,
    `fields.${propertyName}.rowData[${rowIndex}]`
  )
    ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    : {}
  const serialNumberId = getIn(serialData, 'dataId')
  const productId = getIn(formState, 'values.productId')

  yield put(actions.unlockSerial.request(form))

  const { error, response } = yield call(localApi.unlockNotes, {
    productId,
    serialNumberId,
    groupName: 'currentInventory'
  })

  if (response) {
    yield put(actions.unlockSerial.success({ propertyName, rowIndex }, form))
    yield put(confirm(form, modalId))
  } else {
    yield put(actions.unlockSerial.failure(error))
  }
}

export function* saveNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_NOTES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(saveNotesProcess, form, payload)
    }
  }
}

export function* saveNotesProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { propertyName, rowIndex, modalId, newData } = payload

  const serialData = getIn(
    formState,
    `fields.${propertyName}.rowData[${rowIndex}]`
  )
    ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    : {}
  const serialNumberId = getIn(serialData, 'dataId')
  const productId = getIn(formState, 'values.productId')
  const note =
    getIn(formState, `fields.serialNote[${serialNumberId}].value`) || ''

  if (newData) {
    yield call(lockSerialForEditProcess, form, { propertyName, rowIndex })
    // return
  }

  yield put(actions.saveNotes.request(form))

  const { error, response } = yield call(localApi.updateNotes, {
    productId,
    serialNumberId,
    note,
    retainLock: false
  })

  if (response) {
    yield put(
      actions.saveNotes.success(
        { propertyName, rowIndex, note, serialNumberId },
        form
      )
    )
    yield put(confirm(form, modalId))
  } else {
    yield put(actions.saveNotes.failure(error))
  }
}

export function* deleteNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_NOTES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(deleteNotesProcess, form, payload)
    }
  }
}

export function* deleteNotesProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { propertyName, rowIndex, modalId } = payload

  const serialData = getIn(
    formState,
    `fields.${propertyName}.rowData[${rowIndex}]`
  )
    ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
    : {}
  const serialNumberId = getIn(serialData, 'dataId')
  const productId = getIn(formState, 'values.productId')

  yield call(
    confirmationModal,
    'Do you wish to delete this note?',
    'Internal Notes'
  )

  const { type } = yield take([CONFIRMED, CANCELED])

  if (type === CONFIRMED) {
    yield put(actions.saveNotes.request(form))

    const { error, response } = yield call(localApi.updateNotes, {
      productId,
      serialNumberId,
      note: '',
      retainLock: false
    })

    if (response) {
      yield put(
        actions.saveNotes.success(
          { propertyName, rowIndex, note: '', serialNumberId },
          form
        )
      )
      yield put(confirm(form, modalId))
    } else {
      yield put(actions.saveNotes.failure(error, form))
    }
  }

  return true
}

export function* lockForEditListener(formListener) {
  while (true) {
    const action = yield take(LOCK_FOR_EDIT.SUCCESS)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield put(tryChangeFormTab(form, 'currentInventory'))
    }
  }
}

export function* onSaveListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_PRODUCT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(saveProcess, form)
    }
  }
}

export function* saveProcess(form) {
  const formState = yield select(getFormSelector(form))

  const productId = getIn(formState, 'fields.productId.value')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
    ? getIn(formState, 'masterOptions.selectedPrimaryTab')
    : 'currentInventory'
  const serialNumberId = ''
  const groupNames = [selectedPrimaryTab]
  const addMode = getIn(formState, 'addMode') || false

  if (addMode) {
    // if in addMode and there is still unsaved serial number, call add
    const serialNumbers = getIn(formState, 'fields.currentInventory.rowData')
      ? getIn(formState, 'fields.currentInventory.rowData').toJS()
      : []

    const newSerialNumber =
      serialNumbers && serialNumbers.length
        ? serialNumbers.filter(x => x.dataId && x.newData)[0]
        : null
    const rowIndex = serialNumbers.indexOf(newSerialNumber)
    const isNew = newSerialNumber ? newSerialNumber.isNew : false

    // if (!newSerialNumber || !newSerialNumber.dataId) {
    //   return
    // }
    yield put(actions.addSerialNumber.request(form))

    if (newSerialNumber) {
      const { response, error } = yield call(localApi.addSerialNumber, {
        productId,
        properties: newSerialNumber,
        isNew,
        serialNumberId: newSerialNumber.dataId
      })

      if (response) {
        yield put(
          actions.addSerialNumber.success(
            {
              rowIndex,
              serialNumberId: newSerialNumber.dataId
            },
            form
          )
        )
      } else {
        const { status, validationErrors } = error
        if (status && status === 498) {
          const errorMessages = getErrorMessages(validationErrors)
          yield call(warningModal, errorMessages, 'Attention!')
        }
        yield put(actions.addSerialNumber.failure({ ...error, rowIndex }, form))
        return
      }
    }
  }

  yield put(actions.saveProduct.request(form))

  const { response, error } = yield call(api.save, {
    form: getIn(formState, 'apiAlias') || form,
    productId,
    serialNumberId,
    groupNames
  })

  if (response) {
    yield put(actions.saveProduct.success(response, form))
    yield put(getEntityAsync.success(response, form))
  } else {
    yield put(actions.saveProduct.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* deleteSerialNumberListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.DELETE_SERIAL_NUMBER.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(deleteSerialNumberProcess, form, payload)
    }
  }
}

export function* deleteSerialNumberProcess(form, payload) {
  const { dataId, rowIndex } = payload
  const formState = yield select(getFormSelector(form))

  const productId = getIn(formState, 'fields.productId.value')
  // const serialNumberId = getIn(formState, `fields.${propertyName}.rowData[${rowIndex}].dataId`) ?
  //   getIn(formState, `fields.${propertyName}.rowData[${rowIndex}].dataId`) : ''
  const serialNumberId = dataId

  yield put(actions.deleteSerialNumber.request(form))

  const { response, error } = yield call(localApi.removeSerialNumber, {
    productId,
    serialNumberId
  })

  if (response) {
    yield put(actions.deleteSerialNumber.success({ rowIndex }, form))
  } else {
    yield put(actions.deleteSerialNumber.failure(error, form))
  }
}

export function* cancelEditListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CANCEL_PRODUCT_EDIT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(cancelEditProcess, form)
    }
  }
}

export function* cancelEditProcess(form) {
  const formState = yield select(getFormSelector(form))

  const isEditing = getIn(formState, 'isEditing')
  const productId = getIn(formState, 'fields.productId.value')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const addMode = getIn(formState, 'addMode') || false

  let modalAction
  if (isEditing) {
    yield call(
      confirmationModal,
      'All changes will be lost.  Continue?',
      'Cancel?'
    )

    modalAction = yield take([CONFIRMED, CANCELED])
  }

  if (modalAction.type === CONFIRMED) {
    yield put(preCancelEdit(form))

    /* apiAlias is for edits made in modal */
    const { response, error } = yield call(api.cancelEdit, {
      form: getIn(formState, 'apiAlias') || form,
      productId,
      groupNames: [selectedPrimaryTab]
    })

    if (response) {
      if (addMode) {
        yield put({
          meta: { form },
          payload: { addMode: !addMode },
          type: CONSTANTS.TOGGLE_ADD_MODE
        })
      }
      yield put(
        cancelEditAsync.success(response, {
          form,
          thunk: true
        })
      )
    } else {
      yield put(yield put(cancelEditAsync.failure(error)))
    }
  }
}

export function* blurListener(formListener) {
  while (true) {
    const action = yield take(BLUR)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (
        payload.propertyName &&
        payload.propertyName === 'selectedSerialNumber'
      ) {
        yield fork(getCompleteSerialHistoryProcess, form, payload)
      }
    }
  }
}

export function* getCompleteSerialHistoryProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { value } = payload

  const dataId = getIn(formState, 'fields.productId.value')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (selectedPrimaryTab !== 'completeSerialHistory') {
    return
  }

  // call read for completeSerialHistory

  yield put(getEntityAsync.request(form))

  const { response, error } = yield call(
    localApi.getProductInSerialNumberInquiry,
    {
      dataId,
      serialNumberId: value,
      groupNames: ['completeSerialHistory']
    }
  )

  if (response) {
    if (value !== '') {
      yield put(getEntityAsync.success(response, form))
    } else {
      yield put(actions.clearCompleteSerialHistory.success(response, form))
    }
  } else {
    yield put(getEntityAsync.failure(error, form))
  }
}

export function* addBlankRowListener(formListener) {
  while (true) {
    const action = yield take(ADD_BLANK_ROW)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (payload.propertyName && payload.propertyName === 'currentInventory') {
        yield fork(addNewRowProcess, form)
      }
    }
  }
}

export function* addNewRowProcess(form) {
  const formState = yield select(getFormSelector(form))

  const addMode = getIn(formState, 'addMode') || false

  if (!addMode) {
    // swtich to add mode
    yield put({
      meta: { form },
      payload: { addMode: !addMode },
      type: CONSTANTS.TOGGLE_ADD_MODE
    })
    return
  }

  const productId = getIn(formState, 'fields.productId.value')
  const serialNumbers = getIn(formState, 'fields.currentInventory.rowData')
    ? getIn(formState, 'fields.currentInventory.rowData').toJS()
    : []

  const newSerialNumber =
    serialNumbers && serialNumbers.length
      ? serialNumbers.filter(x => x.newData)[0]
      : null
  const rowIndex =
    serialNumbers && serialNumbers.length
      ? serialNumbers.indexOf(newSerialNumber)
      : null

  if (!newSerialNumber?.dataId) {
    return
  }

  yield put(actions.addSerialNumber.request(form))

  const { response, error } = yield call(localApi.addSerialNumber, {
    productId,
    properties: newSerialNumber,
    serialNumberId: newSerialNumber.dataId,
    isNew: newSerialNumber.isNew
  })

  if (response) {
    yield put(actions.addSerialNumber.success({ rowIndex }, form))
  } else {
    const { status, validationErrors } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
    yield put(
      actions.addSerialNumber.failure(
        { ...error, isNew: false, rowIndex },
        form
      )
    )
  }
}

export function* addSerialNumberListener(formListener) {
  while (true) {
    const action = yield take(UPDATE_GRID_CELL_DATA)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (
        payload.field &&
        (payload.field === 'dataId' || payload.field === 'warehouseId')
      ) {
        yield fork(addSerialNumberProcess, form, payload)
      }
    }
  }
}

export function* addSerialNumberProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const { field, propertyName, rowIndex, value } = payload

  const productId = getIn(formState, 'fields.productId.value')
  const isNew = getIn(
    formState,
    `fields.currentInventory.rowData[${rowIndex}].isNew`
  )
    ? getIn(formState, `fields.currentInventory.rowData[${rowIndex}].isNew`)
    : false

  const params = {
    dataId: value
  }

  if (isNew) {
    const { response, error } = yield call(localApi.addSerialNumber, {
      productId,
      properties: params,
      serialNumberId: value,
      isNew
    })

    // will always give error because warehouseId is not filled yet, but serialNumber is saved

    // yield put(actions.addSerialNumber.success({ rowIndex }, form))

    if (response) {
      // will never happen....
    } else {
      const { status, validationErrors } = error
      if (status === 498 && validationErrors && validationErrors.length) {
        const isDataIdError = validationErrors.some(
          x => x.property === 'dataId'
        )
        if (isDataIdError) {
          const errorMessages = getErrorMessages(validationErrors)
          yield call(warningModal, errorMessages, 'Attention!')
          // clear field
          yield put(
            actions.clearCell(form, {
              rowIndex,
              propertyName,
              field
            })
          )
        } else {
          // remove isNew flag
          yield put(
            actions.addSerialNumber.failure(
              { ...error, isNew: false, rowIndex },
              form
            )
          )
        }
      }
    }
  }

  if (field === 'warehouseId') {
    // check if warehouseId is valid
    const data = getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
      ? getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`)
      : []
    const serialNumberId = getIn(data, 'dataId') || ''
    // const isNew = getIn(data, 'isNew') || false

    const res = yield call(localApi.addSerialNumber, {
      productId,
      properties: {
        ...params,
        warehouseId: value
      },
      serialNumberId,
      isNew
    })

    if (res.response) {
      // debugger
    } else {
      const { status, validationErrors } = res.error
      if (status === 498 && validationErrors) {
        const errorMessages = getErrorMessages(validationErrors)
        yield call(warningModal, errorMessages, 'Attention!')
        yield put(
          actions.clearCell(form, {
            rowIndex,
            propertyName,
            field
          })
        )
      }
    }
  }
}

export function* navigationSearchListener(formListener) {
  const channel = yield actionChannel([FIND_NEXT.SUCCESS, FIND_PREV.SUCCESS])
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (payload && payload.propertyName === 'dataId') {
        yield fork(searchProcess, form, null, true)
      }
    }
  }
}

// export function* getSerialNumberReadProcess(form) {
//   const formState = yield select(getFormSelector(form))

//   const dataId = getIn(formState, 'fields.dataId.value') || ''
//   const selectedPrimaryTab = getIn(formState, 'masterOptions.selectedPrimaryTab') || ''
//   const selectedSecondaryTab = getIn(formState, 'masterOptions.selectedSecondaryTab') || ''

//   if (!dataId) {
//     return
//   }

//   yield call(searchProcess, form , [
//     selectedPrimaryTab,
//     selectedSecondaryTab
//   ], false)
// }

export function* getEntityProcess(form, tab) {
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.productId.value') || null
  const serialNumberId = getIn(formState, 'fields.serialNumberId.value') || ''

  if (productId) {
    yield put(getEntityAsync.request(form))

    const { response, error } = yield call(api.read, {
      name: form,
      productId,
      serialNumberId,
      groupNames: [tab]
    })

    if (response) {
      yield put(getEntityAsync.success(response, form))
    } else {
      yield put(getEntityAsync.failure(error, form))
    }
  }

  return null
}

export function* handleOpenFromExternalScreen(form, screenOpenData) {
  /*  note: this function handles opening the screen when its already open -- SVE 2/24/2022 */
  const formState = yield select(getFormSelector(form))
  const productId = getIn(formState, 'fields.productId.value')
  const isEditing = getIn(formState, 'isEditing') || false

  if (
    !screenOpenData?.productId ||
    (screenOpenData?.productId && screenOpenData.productId === productId) ||
    (screenOpenData?.route &&
      screenOpenData.route !== 'productserialnumberinquiry')
  ) {
    return
  }

  if (!isEditing) {
    /* 
      ddiForm does not handle this because this screen uses 
      productId vs dataId as its 'leader' field -- SVE 2/24/2022
    */
    yield put(resetMasterFields(form))
    yield put({
      meta: { form, leader: true },
      payload: {
        deleteMeta: false,
        propertyName: 'productId',
        value: screenOpenData.productId
      },
      type: SET_FIELD
    })
  } else {
    /* 
      ddiForm handles this correctly because it uses the correct args
      so we only need to wait on the results of this interaction
    */
    const cancellationAction = yield take([
      CANCEL_EDIT.SUCCESS,
      CANCEL_EDIT.FAILURE
    ])
    // debugger
    if (cancellationAction.type === CANCEL_EDIT.FAILURE) {
      return
    }

    if (cancellationAction?.meta?.form === form) {
      yield put(resetMasterFields(form))
      yield put({
        meta: { form, leader: true },
        payload: {
          deleteMeta: false,
          propertyName: 'productId',
          value: screenOpenData.productId
        },
        type: SET_FIELD
      })
    }
  }
}

let screenOpenData
export function* tryOpenScreenListener(formListener) {
  while (true) {
    const { payload } = yield take(TRY_OPEN_SCREEN)

    screenOpenData = payload
    if (payload?.route && payload.route === 'productserialnumberinquiry') {
      yield take('REQUEST_CANCELED')
      yield fork(handleOpenFromExternalScreen, formListener, screenOpenData)
    }
  }
}

export const getChildAndParentForm = form => {
  const formParts = form?.split('.') || []

  if (!formParts?.[1]) {
    return {
      childForm: form,
      parentForm: form
    }
  }

  if (formParts?.[2] && formParts?.[1]) {
    return {
      childForm: formParts[2],
      parentForm: `${formParts[0]}.${formParts[1]}`
    }
  }

  const childForm = formParts[1]
  const parentForm = formParts[0]

  return {
    childForm,
    parentForm
  }
}

// export function* exitScreenFromModalProcess(form, modalId) {
//   const { childForm, parentForm } = getChildAndParentForm(form)
//   const formState = yield select(getFormSelector(form))
//   const isEditing = getIn(formState, 'isEditing') || false

//   if (isEditing) {
//     yield put({
//       type: CONSTANTS.CANCEL_PRODUCT_EDIT.TRY,
//       meta: { form }
//     })

//     const result = yield take([CANCEL_EDIT.SUCCESS, CANCEL_EDIT.FAILURE])

//     if (
//       result?.meta?.form &&
//       result?.meta?.form === form &&
//       result?.type === CANCEL_EDIT.SUCCESS
//     ) {
//       yield put(removeModal(parentForm, modalId))
//     }
//   } else {
//     yield put(removeModal(parentForm, modalId))
//   }
// }

// export function* exitScreenFromModalListener(formListener) {
//   while (true) {
//     const {
//       meta: { form },
//       payload: { modalId }
//     } = yield take(CONSTANTS.EXIT_SCREEN_FROM_MODAL)

//     if (form === formListener) {
//       yield fork(exitScreenFromModalProcess, form, modalId)
//     }
//   }
// }

export default function* sagas(form) {
  yield fork(exactMatchSearchSuccessListener, form)
  yield fork(entitySuccessListener, form)
  yield fork(launchNotesModalListener, form)
  yield fork(lockSerialForEditListener, form)
  yield fork(unlockSerialListener, form)
  yield fork(saveNotesListener, form)
  yield fork(deleteNotesListener, form)
  yield fork(lockForEditListener, form)
  yield fork(onSaveListener, form)
  yield fork(deleteSerialNumberListener, form)
  yield fork(cancelEditListener, form)
  yield fork(editableGridSagas, form)
  yield fork(blurListener, form)
  yield fork(addBlankRowListener, form)
  yield fork(addSerialNumberListener, form)
  yield fork(navigationSearchListener, form)
  yield fork(tryOpenScreenListener, form)
  // yield fork(exitScreenFromModalListener, form)
}
