import { createSelector } from 'reselect'
import getIn from 'utils/getIn'

// export const tokenSelector = state => state.get('auth').get('token')
export const authSelector = state => state.get('auth')
export const isAuthenticatedSelector = createSelector(
  authSelector,
  auth => auth.get('isAuthenticated')
)
export const authIdSelector = createSelector(
  authSelector,
  auth => auth.get('dataId')
)

export const mobileTimeoutSelector = createSelector(
  authSelector,
  auth => auth.get('infomMobileUiTimeout')
)
export const tokenSelector = createSelector(
  authSelector,
  auth => auth.get('token')
)
export const refreshTokenSelector = createSelector(
  authSelector,
  auth => auth.get('refreshToken')
)
export const accessLevelSelector = createSelector(
  authSelector,
  auth => auth.get('accessLevelsDisplay')
)
export const selectedBranchIdSelector = createSelector(
  authSelector,
  auth => auth.get('selectedBranchId')
)
export const branchSelector = createSelector(
  authSelector,
  auth => auth.get('branch')
)
export const posRegisterSelector = createSelector(
  authSelector,
  auth => auth.get('posRegister')
)
export const cardTerminalSelector = createSelector(
  authSelector,
  auth => auth.get('cardTerminal')
)
export const accessPathListSelector = createSelector(
  authSelector,
  auth => auth.get('accessPathList')
)
export const ddiAccessMenuSelector = createSelector(
  authSelector,
  auth => getIn(auth, 'ddiActionMenu.items')
)
export const recentNewsSelector = createSelector(
  authSelector,
  auth => auth.get('newsfeed')
)
export const configurationSelector = createSelector(
  authSelector,
  auth => auth.get('configuration')
)
export const screensMapSelector = createSelector(
  authSelector,
  auth => auth.get('screensMap')
)

export const fastCustomerDisabledSelector = createSelector(
  authSelector,
  auth => auth.get('fastCustomerDisabled')
)

export const fastCustomerEnabledSelector = createSelector(
  authSelector,
  auth => auth.get('fastCustomerEnabled')
)

export const mailDisabledSelector = createSelector(
  authSelector,
  auth => auth.get('mailNotificationsEnabled')
)

export const salesOrderEntryEnabledSelector = createSelector(
  authSelector,
  auth => auth.get('salesOrderEntryEnabled')
)

export const branchListSelector = createSelector(
  authSelector,
  auth => getIn(auth, 'branches').toList()
)

export const userNameSelector = createSelector(
  authSelector,
  auth => auth.get('userName')
)
export const mfaEnabledSelector = createSelector(
  authSelector,
  auth => auth.get('mfaEnabled') || false
)

export const twoFactorEnabledSelector = createSelector(
  authSelector,
  auth => auth.get('twoFactorEnabled') | false
)

// export const selectedBranchIdSelector = state => state.get('auth').get('selectedBranchId')
// export const posRegisterSelector = state => state.get('auth').get('posRegister')
