/* eslint import/prefer-default-export: 0 */
import { Map, Set } from 'immutable'
import { fromJS, getIn, setIn } from 'utils'
import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'
import { handleEditableGridAPIData } from 'components/EditableGrid/utils'

const convertNullDescriptionToEmptyString = (data, prop) => {
  if (prop && prop.match(/Description/) && data === null) {
    return ''
  }

  return data
}

export const mapGetRecordResponse = ({
  response,
  tabNames = [],
  formState,
  groupNames = []
}) => {
  let res = response
  // const tabIds = tabNames
  const nested = [...tabNames, ...groupNames]

  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (nested.includes(next)) {
        if (
          response[next] !== null &&
          typeof response[next] === 'object' &&
          Object.keys(response[next]).length
        ) {
          for (const prop in response[next]) {
            acc[prop] = response[next][prop]
          }
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  return res
}

export const twoColFieldWrapperStyle = {
  flexBasis: '50%',
  margin: '5 15 5 15',
  position: 'relative'
}

export const fourColFieldWrapperStyle = {
  flexBasis: '25%',
  margin: '5 15 5 15',
  position: 'relative'
}

export const threeColFieldWrapperStyle = {
  flexBasis: 'calc(100% / 3)',
  margin: '5 15 5 15',
  position: 'relative'
}

export const singleColFieldWrapperStyle = {
  flexBasis: '100%',
  margin: '5 15 5 15',
  position: 'relative'
}

export const states = [
  {
    description: 'Alabama',
    dataId: 'AL'
  },
  {
    description: 'Alaska',
    dataId: 'AK'
  },
  {
    description: 'Arizona',
    dataId: 'AZ'
  },
  {
    description: 'Arkansas',
    dataId: 'AR'
  },
  {
    description: 'California',
    dataId: 'CA'
  },
  {
    description: 'Colorado',
    dataId: 'CO'
  },
  {
    description: 'Connecticut',
    dataId: 'CT'
  },
  {
    description: 'Delaware',
    dataId: 'DE'
  },
  {
    description: 'District Of Columbia',
    dataId: 'DC'
  },
  {
    description: 'Florida',
    dataId: 'FL'
  },
  {
    description: 'Georgia',
    dataId: 'GA'
  },
  {
    description: 'Hawaii',
    dataId: 'HI'
  },
  {
    description: 'Idaho',
    dataId: 'ID'
  },
  {
    description: 'Illinois',
    dataId: 'IL'
  },
  {
    description: 'Indiana',
    dataId: 'IN'
  },
  {
    description: 'Iowa',
    dataId: 'IA'
  },
  {
    description: 'Kansas',
    dataId: 'KS'
  },
  {
    description: 'Kentucky',
    dataId: 'KY'
  },
  {
    description: 'Louisiana',
    dataId: 'LA'
  },
  {
    description: 'Maine',
    dataId: 'ME'
  },
  {
    description: 'Maryland',
    dataId: 'MD'
  },
  {
    description: 'Massachusetts',
    dataId: 'MA'
  },
  {
    description: 'Michigan',
    dataId: 'MI'
  },
  {
    description: 'Minnesota',
    dataId: 'MN'
  },
  {
    description: 'Mississippi',
    dataId: 'MS'
  },
  {
    description: 'Missouri',
    dataId: 'MO'
  },
  {
    description: 'Montana',
    dataId: 'MT'
  },
  {
    description: 'Nebraska',
    dataId: 'NE'
  },
  {
    description: 'Nevada',
    dataId: 'NV'
  },
  {
    description: 'New Hampshire',
    dataId: 'NH'
  },
  {
    description: 'New Jersey',
    dataId: 'NJ'
  },
  {
    description: 'New Mexico',
    dataId: 'NM'
  },
  {
    description: 'New York',
    dataId: 'NY'
  },
  {
    description: 'North Carolina',
    dataId: 'NC'
  },
  {
    description: 'North Dakota',
    dataId: 'ND'
  },
  {
    description: 'Ohio',
    dataId: 'OH'
  },
  {
    description: 'Oklahoma',
    dataId: 'OK'
  },
  {
    description: 'Oregon',
    dataId: 'OR'
  },
  {
    description: 'Palau',
    dataId: 'PW'
  },
  {
    description: 'Pennsylvania',
    dataId: 'PA'
  },
  {
    description: 'Rhode Island',
    dataId: 'RI'
  },
  {
    description: 'South Carolina',
    dataId: 'SC'
  },
  {
    description: 'South Dakota',
    dataId: 'SD'
  },
  {
    description: 'Tennessee',
    dataId: 'TN'
  },
  {
    description: 'Texas',
    dataId: 'TX'
  },
  {
    description: 'Utah',
    dataId: 'UT'
  },
  {
    description: 'Vermont',
    dataId: 'VT'
  },
  {
    description: 'Virginia',
    dataId: 'VA'
  },
  {
    description: 'Washington',
    dataId: 'WA'
  },
  {
    description: 'West Virginia',
    dataId: 'WV'
  },
  {
    description: 'Wisconsin',
    dataId: 'WI'
  },
  {
    description: 'Wyoming',
    dataId: 'WY'
  }
]

export const statesAndProvinces = [
  ...states,
  {
    description: 'Alberta',
    dataId: 'AB'
  },
  {
    description: 'British Columbia',
    dataId: 'BC'
  },
  {
    description: 'Manitoba',
    dataId: 'MB'
  },
  {
    description: 'New Brunswick',
    dataId: 'NB'
  },
  {
    description: 'Newfoundland and Labrador',
    dataId: 'NL'
  },
  {
    description: 'Northwest Territories',
    dataId: 'NT'
  },
  {
    description: 'Nova Scotia',
    dataId: 'NS'
  },
  {
    description: 'Nunavut',
    dataId: 'NU'
  },
  {
    description: 'Ontario',
    dataId: 'ON'
  },
  {
    description: 'Prince Edward Island',
    dataId: 'PE'
  },
  {
    description: 'Quebec',
    dataId: 'QC'
  },
  {
    description: 'Saskatchewan',
    dataId: 'SK'
  },
  {
    description: 'Yukon Territory',
    dataId: 'YT'
  }
]

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const validateGridRowImmutable = (rowsToTest, data) =>
  rowsToTest.reduce((a, n) => {
    if (Map.isMap(data) && !data.get(n)) {
      a += 1
    }
    return a
  }, 0)

export const updateEditedFields = (state, propertyName) => {
  let editedFields = getIn(state, 'editedFields') || Set()
  if (editedFields && !propertyName.match(/selectionCriteria/gi)) {
    editedFields = editedFields.add(propertyName)
    state = setIn(state, 'editedFields', editedFields)
  }

  return state
}
