import React, { Component } from 'react'
import memoize from 'memoize-one'
import ErrorBoundary from 'components/ErrorBoundary'
import { dateFilterComparator, plainDeepEqual } from 'utils'
import { postProcessPopup, createFooterTotals } from './utils'
import './styles.scss'

const addDateFilter = memoize((colDefs = []) => {
  const columnDefs =
    colDefs?.reduce((acc, next) => {
      if (next?.field && next?.field?.match(/date/gi) && !next?.filterParams) {
        acc = acc.concat({
          ...next,
          filter: 'agDateColumnFilter',
          filterParams: {
            clearButton: true,
            comparator: dateFilterComparator,
            inRangeInclusive: true,
            suppressAndOrCondition: true,
            browserDatePicker: true
          }
        })
      } else {
        acc = acc.concat(next)
      }

      return acc
    }, []) || colDefs

  return columnDefs
}, plainDeepEqual)

const withRowTotals = (options = {}) => WrappedGridComponent => {
  const { setPinnedRowDataFn = null } = options

  class WithRowTotals extends Component {
    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    onGridReady = params => {
      const { api } = params
      const { isMobile = false } = this.props

      if (this?.props?.onGridReady) {
        this.props.onGridReady(params)
      }

      if (setPinnedRowDataFn && typeof setPinnedRowDataFn === 'function') {
        createFooterTotals(api, setPinnedRowDataFn, this._isMounted, isMobile)
      }
    }

    redrawFooter = ({ api }) => {
      const { isMobile = false } = this.props

      if (setPinnedRowDataFn && typeof setPinnedRowDataFn === 'function') {
        createFooterTotals(api, setPinnedRowDataFn, this._isMounted, isMobile)
      }
    }

    postProcessPopup = params => {
      if (this?.props?.postProcessPopup) {
        this.props.postProcessPopup(params)
      }

      postProcessPopup(params)
    }

    onFilterChanged = params => {
      if (this?.props?.onFilterChanged) {
        this.props.onFilterChanged(params)
      }

      this.redrawFooter(params)
    }

    onRowDataChanged = params => {
      if (this?.props?.onRowDataChanged) {
        this.props.onRowDataChanged(params)
      }

      this.redrawFooter(params)
    }

    onRowDataUpdated = params => {
      if (this?.props?.onRowDataUpdated) {
        this.props.onRowDataUpdated(params)
      }

      this.redrawFooter(params)
    }

    render() {
      const { forwardedRef, ...rest } = this.props

      return (
        <ErrorBoundary>
          <WrappedGridComponent
            ref={forwardedRef}
            {...rest}
            columnDefs={
              this?.props?.columnDefs && Array.isArray(this.props.columnDefs)
                ? addDateFilter(this.props.columnDefs)
                : this.props.columnDefs
            }
            postProcessPopup={this.postProcessPopup}
            onFilterChanged={this.onFilterChanged}
            onRowDataChanged={this.onRowDataChanged}
            onRowDataUpdated={this.onRowDataUpdated}
          />
        </ErrorBoundary>
      )
    }
  }

  return React.forwardRef((props, ref) => (
    <WithRowTotals {...props} forwardedRef={ref} />
  ))
}

export default withRowTotals
