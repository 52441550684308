import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { debounce } from 'lodash'
import { getValue, getField } from 'utils'
import { voidSalesOrder, exitScreenFromModal, saveSalesOrder } from '../actions'

export const RightFooterButtons = ({
  canChange,
  canVoid,
  dataId,
  dispatch,
  form,
  hasRecord,
  isEditing,
  newMode,
  preNewMode,
  templateKey,
  isSaving,
  id,
  emitter,
  ...rest
}) => {
  console.log(id, rest)
  const handleVoid = debounce(() => {
    dispatch(voidSalesOrder.try(form))
  }, 500)
  const saveSalesOrderTry = debounce(() => {
    try {
      dispatch(saveSalesOrder.try(form, { emitter }))
    } catch (e) {
      debugger
      console.log(e)
    }
  }, 500)
  const exitModal = e => dispatch(exitScreenFromModal(form, { modalId: id }))
  return (
    <>
      <DDIButton
        variant="contained"
        label="New"
        actionName="createNewSalesOrder"
        preventAutoDisable
        tabIndex={0}
        style={{ marginRight: 5 }}
      />

      {isEditing && hasRecord && (
        <DDIButton
          variant="contained"
          label="Save"
          // debounceTime={10000}
          // actionName="saveSalesOrder"
          onClick={saveSalesOrderTry}
          preventAutoDisable
          tabIndex={0}
          disabled={isSaving}
          style={{ marginRight: 5 }}
        />
      )}

      {isEditing && hasRecord && dataId && canVoid && (
        <DDIButton
          variant="contained"
          label="Void"
          // actionName="voidSalesOrder"
          onClick={handleVoid}
          preventAutoDisable
          tabIndex={0}
          style={{ marginRight: 5 }}
        />
      )}
      {isEditing && hasRecord && (
        <DDIButton
          variant="contained"
          label="Cancel"
          actionName={
            dataId ? 'cancelSalesOrderEdit' : 'cancelNewSalesOrderEdit'
          }
          preventAutoDisable
          tabIndex={0}
          style={{ marginRight: 5 }}
        />
      )}
      {canChange && !isEditing && hasRecord && (
        <DDIButton
          variant="contained"
          label="Edit"
          actionName="lockForEdit"
          preventAutoDisable
          tabIndex={0}
          style={{ marginRight: 5 }}
        />
      )}
      {id ? (
        <DDIButton
          variant="contained"
          label="Exit"
          onClick={exitModal}
          preventAutoDisable
          tabIndex={0}
        />
      ) : (
        <DDIButton
          variant="contained"
          label="Exit"
          actionName="exit"
          preventAutoDisable
          tabIndex={0}
        />
      )}
    </>
  )
}

RightFooterButtons.propTypes = {
  hasRecord: PropTypes.bool,
  isEditing: PropTypes.bool
}

RightFooterButtons.defaultProps = {
  hasRecord: false,
  isEditing: false
}

/* FIXED SVE 9/23/2020 */
export const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  hasRecord: form => form.get('hasRecord') || false,
  dataId: form => getField(form, 'dataId', null),
  canChange: form => getValue(form, 'canChange', false),
  canVoid: form => getValue(form, 'canVoid', false),
  newMasterRecord: form => getValue(form, 'newMasterRecord', false),
  isSaving: form => form.get('isSaving') || false,
  templateKey: form => getValue(form, 'templateKey', '')
}

export default withContext(contextObj)(RightFooterButtons)
