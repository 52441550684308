import React from 'react'
import {
  DDINumberSpinner,
  DDISelectField,
  DDITextField,
  DDIToggle,
  DDIIndexSearch
} from 'ddiForm/wrapped'
import { maxLength } from 'ddiForm/validations'
import { withContext, Label } from 'ddiForm'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import DDICardWrapper from 'components/DDICardWrapper'
import { emptyList, getIn } from 'utils'
import FreightTerms from './components/FreightTerms'
import { getOptionsArray } from '../../utils'
import '../../styles/general.scss'

const Purchasing = ({
  afflinkMember,
  costListOptionsList,
  ecdoxOptionsList,
  poSortOptionsList,
  shipperIntegrationOriginCodeMaxLength
}) => {
  const costListOptions = getOptionsArray(costListOptionsList)
  const poSortOptions = getOptionsArray(poSortOptionsList)
  const ecdoxOptions = getOptionsArray(ecdoxOptionsList)

  return (
    <div className="vendor-master-general-layout-flex-centered">
      <DDICardWrapper title="Purchasing" contentStyle={{ padding: 0 }}>
        <div className="inner-wrapper">
          <div className="flex-column-wrapper two-column-wrapper">
            <div className="flex-inner-column-wrapper">
              <div className="search-row">
                <label>Ship Via Code:</label>
                <div className="input-wrapper">
                  <DDIIndexSearch
                    propertyName="shipViaId"
                    metaKey="shipViaId"
                    searchType="ShipVia"
                    wrapperStyle={{ margin: 0, width: '100%' }}
                  />
                </div>
                <div className="description-overflow">
                  <Label propertyName="shipViaDescription" />
                </div>
              </div>
              <div className="two-column-wrapper">
                <div style={{ paddingLeft: 0 }}>
                  <div className="form-row-wrapper spinner-form-row-wrapper">
                    <DDINumberSpinner
                      propertyName="leadTimeDays"
                      type="number"
                      label="Lead Time Days:"
                    />
                    <DDINumberSpinner
                      propertyName="projectedTurns"
                      type="number"
                      label="Projected Terms:"
                    />
                  </div>
                  <div className="form-row-wrapper">
                    <DDITextField propertyName="ediNumber" label="EDI Number" />
                  </div>
                  <div className="form-row-wrapper">
                    <DDITextField
                      propertyName="shipperIntegrationOriginCode"
                      label="Shipper Integration Origin Code"
                      validate={maxLength(
                        shipperIntegrationOriginCodeMaxLength
                      )}
                      inputProps={{
                        maxLength: shipperIntegrationOriginCodeMaxLength
                      }}
                    />
                  </div>
                  <div className="form-row-wrapper">
                    <DDISelectField
                      propertyName="sortPurchaseOrderBy"
                      fullWidth
                      values={poSortOptions}
                      label="Sort P/O By"
                      // style={{ width: 150 }}
                    />
                  </div>
                  <div className="form-row-wrapper">
                    <DDISelectField
                      propertyName="rebateCostColumn"
                      fullWidth
                      values={costListOptions}
                      label="Rebate Cost Column"
                      // style={{ width: 150 }}
                    />
                  </div>
                </div>
                <div style={{ paddingRight: 0, paddingTop: 5 }}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="disableLeadTimeUpdate"
                      label="Disable Lead Time Update"
                    />
                  </AssociatedFieldsWrapper>
                  <AssociatedFieldsWrapper>
                    <DDIToggle propertyName="metric" label="Metric" />
                  </AssociatedFieldsWrapper>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="forecastLowPriority"
                      label="Forecast Low Priority"
                    />
                  </AssociatedFieldsWrapper>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="forecastToBoxQtyForEachWarehouse"
                      label="Forecast To Box Quantity For Each Warehouse"
                      wrapperStyle={{ padding: '10px 0' }}
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
              <div style={{ marginTop: 20, paddingLeft: 0 }}>
                <DDITextField
                  label="Special Instructions"
                  propertyName="specialPOInstructions"
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                />
              </div>
            </div>
            <div>
              {afflinkMember ? (
                <DDICardWrapper
                  title="Afflink Buying Group"
                  contentStyle={{ padding: 10 }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div style={{ flexBasis: '50%', paddingRight: 15 }}>
                      <AssociatedFieldsWrapper>
                        <DDIToggle
                          propertyName="afflinkHouseReport"
                          label="House Report"
                        />
                      </AssociatedFieldsWrapper>
                    </div>
                    <div style={{ flexBasis: '50%' }}>
                      <DDISelectField
                        propertyName="afflinkECDOX"
                        fullWidth
                        values={ecdoxOptions}
                        label="ECDOX"
                      />
                    </div>
                  </div>
                </DDICardWrapper>
              ) : null}
              <FreightTerms />
            </div>
          </div>
        </div>
      </DDICardWrapper>
    </div>
  )
}

export default withContext({
  afflinkMember: form => getIn(form, 'meta.afflinkMember') || false,
  costListOptionsList: form => getIn(form, 'meta.costListOptions') || emptyList,
  ecdoxOptionsList: form => getIn(form, 'meta.ecdoxOptions') || emptyList,
  poSortOptionsList: form => getIn(form, 'meta.poSortOptions') || emptyList,
  shipperIntegrationOriginCodeMaxLength: form =>
    getIn(form, 'values.shipperIntegrationOriginCodeMaxLength') || 30
})(Purchasing)
