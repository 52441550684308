import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from '@material-ui/core'
import SignaturePad from 'react-signature-canvas'
import { withContext } from 'ddiForm'
import { required } from 'ddiForm/validations'
import { setField, destroyField } from 'ddiForm/actions'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'
import { formatDate, getField, getMeta, getValue, getIn } from 'utils'
import {
  pollCardTerminalSignature as pollCardTerminalSignatureShipConf,
  initiateCardTerminalSignature as initiateCardTerminalSignatureShipConf
} from 'pages/ShippingConfirmation/actions'
import {
  initiateCardTerminalSignature,
  pollCardTerminalSignature
} from '../../../actions'

const paragraphStyle = {
  color: '#444',
  fontSize: 12,
  lineHeight: '16px',
  margin: '10px 0 0 12px'
}
const sigPadWrapperStyle = {
  display: 'flex',
  justifyContent: 'center'
}
const labelStyle = {
  fontSize: 12,
  marginTop: 5
}

const buttonStyle = {
  marginLeft: 5
}

export const Signature = ({
  customerId,
  cardTerminalSelected,
  form,
  dataId,
  imageUrl,
  signedBy,
  signedByDate,
  dispatch,
  isOnHoldOrSuspended,
  isEditing,
  signatureCapturePrintedNameRequired,
  isShipConf,
  modal,
  id
}) => {
  useEffect(() => {
    if (!cardTerminalSelected) {
      document.addEventListener('SignResponse', signResponse)
      return () => {
        document.removeEventListener('SignResponse', signResponse)
      }
    }
  })

  const inputRef = React.createRef()

  const canvasRef = React.createRef()
  const [isSigned, setSigned] = useState(false)
  const [imgWidth, setWidth] = useState(0)
  const [imgHeight, setHeight] = useState(0)
  const [signDisabled, setSignDisabled] = useState(null)
  const [signedByD, setSignedDate] = useState(null)
  const [signer, setSigner] = useState(signedBy)
  const [open, setOpen] = useState(false)
  const [signature, setSignature] = useState(null)
  const [editingSignature, setEditingSignature] = useState(false)
  const [image, setImage] = useState(imageUrl)
  const [cleared, setCleared] = useState(false)
  const [resigned, setResigned] = useState(false)
  const [useCanvas, setUseCanvas] = useState(false)
  const [hasNewSig, setHasNewSig] = useState(false)
  const [points, setPoints] = useState(null)
  const [canceled, setCanceled] = useState(false)

  useEffect(() => {
    //
    if (cleared && !resigned) {
      // debugger
      setImage('')
      setSignature(null)
    } else if (!signature && !resigned) {
      setImage(imageUrl)
    } else if (resigned && signature) {
      setImage(signature)
      imageUrl = null
    }
    if (!customerId) {
      setImage(null)
    }
    if (modal && !open && !canceled) {
      // setOpen(true)
      onSignClick(id)
    }
  }, [imageUrl, signature, cleared, customerId])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function setValues(objResponse, imageWidth, imageHeight) {
    // debugger
    try {
      let obj = null
      if (typeof objResponse === 'string') {
        obj = JSON.parse(objResponse)
      } else {
        obj = JSON.parse(JSON.stringify(objResponse))
      }

      // const ctx = document.getElementById('cnv').getContext('2d')
      if (!obj.isSigned) {
        setSigned(false)
        setSignDisabled(false)
      } else if (obj.isSigned && obj.errorMsg != null && obj.errorMsg !== '') {
        alert(obj.errorMsg)
        setSigned(false)
        setSignDisabled(false)
      } else if (obj.isSigned) {
        handleOpen()
        setSigned(true)
        const d = new Date()
        signedByDate = d.toISOString() // `${d.getMonth()+1}/${d.getDate()}/${d.getFullYear()}`
        setSignedDate(signedByDate)
        setSignature(obj.imageData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  function signResponse(event) {
    const str = event.target.getAttribute('msgAttribute')
    const obj = JSON.parse(str)
    setValues(obj, imgWidth, imgHeight)
  }

  const onSetField = (...args) => {
    const p = args[3]
    let description
    if (p) {
      description = p.description
      if (description) {
        setSigner(description)
        dispatch(setField(form, 'tempSignedBy', description))
      }
    }

    //
  }

  const cancelSign = () => {
    setImage(imageUrl)
    setSignDisabled(false)
    setSigned(false)
    setCleared(false)
    handleClose()
    setPoints(null)
    setCanceled(true)
    dispatch(destroyField(form, 'tempSignedBy'))
  }

  function GetDeviceStatusResponse(event) {
    const str = event.target.getAttribute('msgAttribute')
    const obj = JSON.parse(str)
    const message = {
      firstName: '',
      lastName: '',
      eMail: '',
      location: '',
      imageFormat: 1,
      imageX: imgWidth, // - 20,
      imageY: imgHeight, // - 20,
      imageTransparency: false,
      imageScaling: false,
      maxUpScalePercent: 0.0,
      rawDataFormat: 'ENC',
      minSigPoints: 25
    }

    if (obj?.deviceStatus === 1) {
      // device is online
      setUseCanvas(false)
      const messageData = JSON.stringify(message)
      const element = document.createElement('MyExtensionDataElement')
      element.setAttribute('messageAttribute', messageData)
      document.documentElement.appendChild(element)
      const evt = document.createEvent('Events')
      evt.initEvent('SignStartEvent', true, false)
      element.dispatchEvent(evt)
    }
    // do something wiht the canvas
    else if (!useCanvas) {
      setUseCanvas(true)
      handleOpen()
    }
  }

  async function onSignClick(id) {
    debugger
    if (isOnHoldOrSuspended) {
      // no signing
      return
    }
    const isInstalled = document.documentElement.getAttribute(
      'SigPlusExtLiteExtension-installed'
    )
    if (cardTerminalSelected) {
      if (!image) {
        // sign
        // debugger
        let resp = null

        handleOpen()
        try {
          if (!isShipConf) {
            resp = await dispatch(initiateCardTerminalSignature.request(form))
            resp = await dispatch(pollCardTerminalSignature.request(form, resp))
          } else {
            resp = await dispatch(
              initiateCardTerminalSignatureShipConf.request(form, { modal })
            )
            resp = await dispatch(
              pollCardTerminalSignatureShipConf.request(form, {
                ...resp,
                id,
                modal
              })
            )
            debugger
          }
          debugger
          setImage(resp.signature)
          setSignature(resp.signature)
          setSigned(true)
          const d = new Date()
          signedByDate = d.toISOString()
          setSignedDate(signedByDate)
        } catch {
          // do nothing right now..
          debugger
        }
      } else {
        setEditingSignature(true)
        setSigner(signedBy)
        handleOpen()
      }
    } else if (!image) {
      if (!isInstalled) {
        // use the canvas...
        setUseCanvas(true)
        handleOpen()
        return
      }
      // setSignDisabled(true)
      // if installed see if it is connected first

      const deviceStatus = {
        metadata: { version: 1.0, command: 'GetDeviceStatus' },
        deviceStatus: ''
      }
      // debugger
      const deviceStatusData = JSON.stringify(deviceStatus)
      const element = document.createElement(
        'MyExtensionDataElementDeviceStatus'
      )
      element.setAttribute('msgeAttributeDeviceStatus', deviceStatusData)
      document.documentElement.appendChild(element)
      const evt = document.createEvent('Events')
      evt.initEvent('GetDeviceStatusEvent', true, false)
      window.addEventListener(
        'GetDeviceStatusResponse',
        GetDeviceStatusResponse,
        false
      )
      element.dispatchEvent(evt)
    } else {
      // already have an image..
      // debugger

      setEditingSignature(true)
      setSigner(signedBy)
      handleOpen()
    }
  }

  const isSignDisabled =
    !dataId || !(isEditing && !isOnHoldOrSuspended) || signDisabled

  const title = !dataId
    ? 'Please save order before signing!'
    : isOnHoldOrSuspended
    ? 'Cannot sign on credit hold or suspended order'
    : ''

  const resign = () => {
    setCleared(false)
    setResigned(true)
    onSignClick()
  }
  const saveSignature = async () => {
    let newSig = signature
    if (points) {
      newSig = canvasRef?.current
        ?.getTrimmedCanvas()
        ?.toDataURL('image/png', 0.5)
      newSig = newSig.substring(22)
    }
    // debugger
    try {
      const f = await dispatch({
        type: 'TRY_SAVE_SIGNATURE',
        payload: {
          signature: newSig,
          signer,
          modal,
          id
        },
        meta: { form, thunk: true }
      })
      // debugger
      if (points) {
        setHasNewSig(false)
      }
      handleClose()
      if (f?.record?.signature?.signature) {
        setImage(f.record.signature.signature)
      } else if (f?.signature?.signatureImage) {
        setImage(f.signature.signatureImage)
      }
      setEditingSignature(false)
    } catch (e) {
      setImage(imageUrl)
    } finally {
      setCleared(false)
      setResigned(false)
    }
  }
  const onChangeCb = e => {
    setSigner(e.target.value)
  }

  const onBegin = (...rest) => setHasNewSig(true)
  const onEnd = () => setPoints(canvasRef?.current?.toData())
  const clearSignature = () => {
    if (hasNewSig) {
      canvasRef?.current?.clear()
      setPoints(null)
    }
    setImage(null)
    setSigner(null)
    if (!useCanvas && imageUrl) {
      setCleared(true)
    }
    dispatch(setField(form, 'tempSignedBy', ''))
  }

  // 2022618
  let isSaveDisabled
  if (editingSignature) {
    if (image) {
      isSaveDisabled = true
    }
    if (signatureCapturePrintedNameRequired && !signer && !cleared) {
      isSaveDisabled = true
    }
  } else if (useCanvas && !points) {
    isSaveDisabled = true
  }
  // else

  // if (editingSignature) {
  //   if (image) {
  //     isSaveDisabled = true
  //   }
  // } else if (useCanvas && !points) {
  //   isSaveDisabled = true
  // } else if (signatureCapturePrintedNameRequired && !signer) {
  //   isSaveDisabled = true
  // }

  // useEffect(() => {
  //   const listener = event => {
  //     if (event.code === 'Enter' || event.code === 'NumpadEnter') {
  //       event.preventDefault()
  //       // debugger
  //       if (!isSignDisabled) {
  //         //
  //         saveSignature()
  //       }
  //     }
  //   }
  //   document.addEventListener('keydown', listener)
  //   return () => {
  //     document.removeEventListener('keydown', listener)
  //   }
  // }, [])
  console.log('image - ', image)
  console.log('useCanvas', useCanvas)
  return (
    <>
      {!modal && (
        <div style={{ maxWidth: 400 }}>
          <div
            style={{
              border: '1px solid #d9dcde',
              margin: '0 auto 15px auto',
              height: 100,
              maxHeight: 100
            }}
          >
            {image ? (
              <img
                src={image}
                alt="signature"
                style={{ maxHeight: 98, maxWidth: '100%' }}
                className="center-block"
              />
            ) : null}
          </div>

          <p style={paragraphStyle}>
            Signed By: <strong>{signedBy}</strong>
          </p>

          <p style={paragraphStyle}>
            Date: <strong>{formatDate(signedByDate, 'MM/DD/YYYY')}</strong>
          </p>
          <p style={paragraphStyle}>
            Time: <strong>{formatDate(signedByDate, 'hh:mm a')}</strong>
          </p>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end'
              }}
            >
              <Tooltip title={title}>
                <span>
                  <Button
                    letiant="contained"
                    id="SignBtn"
                    onClick={onSignClick}
                    disabled={isSignDisabled}
                  >
                    Sign
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Save Signature?</DialogTitle>
        <DialogContent>
          {image || signature ? (
            <img
              src={
                editingSignature
                  ? resigned && signature
                    ? `data:image/png;base64,${signature}`
                    : image
                  : signature
                  ? `data:image/png;base64,${signature}`
                  : image
              }
              alt="signature"
              style={{ maxHeight: 100, maxWidth: '100%', height: 90 }}
              className="center-block"
            />
          ) : useCanvas ? (
            <div style={sigPadWrapperStyle}>
              <SignaturePad
                ref={canvasRef}
                canvasProps={{
                  height: 120,
                  width: 530,
                  style: { border: '1px solid #ccc' }
                }}
                onBegin={onBegin}
                onEnd={onEnd}
                clearOnResize={false}
              />
            </div>
          ) : (
            <div
              className="center-block"
              style={{ maxHeight: 100, maxWidth: '100%', height: 90 }}
            />
          )}
          <div style={{ padding: '12px 12px 0' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end'
                }}
              >
                <div style={labelStyle}>Contact:</div>
                <div>
                  <DDIIndexSearch
                    propertyName="sigContact"
                    metaKey="Contact"
                    preventAutoDisable
                    meta={{
                      allowInstantSearch: true,
                      minimumKeywordLength: 2,
                      searchType: 'contacts',
                      allowSearchAll: true
                    }}
                    clearFieldOnSet
                    onSetField={onSetField}
                    parentId={customerId}
                    parentType="Customer"
                  />
                </div>
                <div style={labelStyle}>Signed By:</div>
                <div>
                  <DDITextField
                    propertyName="tempSignedBy"
                    preventAutoDisable
                    validate={
                      signatureCapturePrintedNameRequired ? [required] : null
                    }
                    // value={signer}
                    inputRef={inputRef}
                    onChangeCb={onChangeCb}
                    initialValue={editingSignature ? signedBy : undefined}
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10
            }}
          >
            {editingSignature || (useCanvas && points) ? (
              <Button
                style={buttonStyle}
                letiant="contained"
                onClick={clearSignature}
                disabled={cleared}
              >
                Clear Signature
              </Button>
            ) : null}
            {editingSignature && cleared ? (
              <Button style={buttonStyle} letiant="contained" onClick={resign}>
                Re-sign
              </Button>
            ) : null}
            <Button
              style={buttonStyle}
              letiant="contained"
              disabled={isSaveDisabled}
              onClick={saveSignature}
            >
              Save
            </Button>
            <Button
              style={buttonStyle}
              letiant="contained"
              onClick={cancelSign}
            >
              Cancel
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const contextObj = {
  isShipConf: form => Boolean(form.get('apiAlias') === 'shipconfirmation'),
  tempSignedBy: form => getValue(form, 'tempSignedBy', ''),
  signedBy: form => getValue(form, 'signature.signedBy', ''),
  signedByDate: form => getValue(form, 'signature.signedDate', null),
  imageUrl: form => {
    const t = `signature.${
      form.get('apiAlias') === 'shipconfirmation'
        ? 'signatureImage'
        : 'signature'
    }`

    return getValue(form, t, '')
  },
  isOnHoldOrSuspended: form => getValue(form, 'isOnHoldOrSuspended', false),
  isEditing: form => form.get('isEditing') || false,
  signatureCapturePrintedNameRequired: form =>
    getMeta(form, 'signatureCapturePrintedNameRequired', false),
  dataId: form => getField(form, 'dataId', ''),
  customerId: form => {
    let ret = ''
    if (form.get('apiAlias') === 'shipconfirmation') {
      ret = getValue(form, 'customerName')
    } else {
      ret = getField(form, 'customerId')
    }
    return ret
  }
}
const mapState = state => ({
  cardTerminalSelected: !!(
    getIn(state, 'auth.cardTerminal') ||
    getIn(state, 'auth.statusBar.selectedTerminal')
  )
})
export default connect(mapState)(withContext(contextObj)(Signature))
