import React from 'react'
// import PropTypes from 'prop-types'
import { changeItemGroups } from 'pages/SalesOrder/actions'

import AvailableProducts from './AvailableProducts'
import ItemGroups from './ItemGroups'
import ProductsInGroupGrid from './ProductsInGroupGrid'
import MoveProductButtons from './MoveProductButtons'

import './styles/item-groups.scss'

export const ItemGroupsWrapper = ({ data }) => {
  function addProductsToGroup(params) {
    const { form, dispatch } = data
    dispatch(
      changeItemGroups.try(form, {
        type: 'add',
        lineNumbers: params.lineNumbers
      })
    )
  }

  function addAllToGroup() {
    const { form, dispatch } = data
    dispatch(
      changeItemGroups.try(form, {
        type: 'add',
        lineNumbers: 'all'
      })
    )
  }

  return (
    <div className="item-groups-outer-wrapper">
      <div className="item-groups-three-col-wrapper">
        {/* AVAILABLE PRODUCTS */}
        <div className="item-groups-three-col-left">
          <AvailableProducts dispatch={data.dispatch} form={data.form} />
        </div>

        {/* ARROW BUTTONS */}
        <MoveProductButtons
          addProductsToGroup={addProductsToGroup}
          addAllToGroup={addAllToGroup}
        />

        {/* ITEM GROUPS */}

        <div className="item-groups-three-col-right">
          <div className="item-groups-column-wrapper">
            <div
              className="item-groups-flex-wrapper"
              style={{ paddingBottom: 50 }}
            >
              <ItemGroups form={data.form} />
            </div>

            <div className="item-groups-flex-wrapper">
              <ProductsInGroupGrid form={data.form} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemGroupsWrapper
