import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import Grid from 'grid'
import { is, formatDate } from 'utils'
import { viewSDSheet } from '../../actions'

const DocumentLinkCell = ({ value, colDef, rowIndex, data, ...rest }) => {
  const dispatch = colDef?.cellRendererParams?.dispatch || null
  const form = colDef?.cellRendererParams?.form || ''
  const documentUrl = data?.url || null
  console.log('DocumentLinkCell', rowIndex, form, rest)

  const openDocument = debounce(e => {
    if (documentUrl) {
      window.open(documentUrl, '_blank', 'toolbar=0,location=0,menubar=0')
    } else if (is.number(rowIndex) && dispatch) {
      dispatch(
        viewSDSheet.try(form, {
          action: 'viewsds',
          pos: [rowIndex]
        })
      )
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex'
      }}
    >
      <button
        size="small"
        style={{
          color: '#517b9c',
          minHeight: 26,
          padding: 5,
          justifyContent: 'flex-start',
          width: '100%'
        }}
        onClick={openDocument}
        type="button"
      >
        {value}
      </button>
    </div>
  )
}

class SafetyDataSheetsGrid extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    console.log(this.props)

    this.state = {
      pos:
        this?.props?.data?.sdSheets && Array.isArray(this.props.data.sdSheets)
          ? this.props.data.sdSheets.reduce((acc, next, idx) => {
              if (next.isSelected) {
                acc = acc.concat(idx)
              }
              return acc
            }, [])
          : []
    }

    this.gridApi = null
    this.columnApi = null
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getColumnDefs = () => {
    const { dispatch } = this.props

    const columnDefs = [
      {
        suppressMenu: true,
        suppressNavigable: true,
        resizable: false,
        lockPosition: true,
        field: 'dataId',
        colId: 'dataId_0',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 50,
        minWidth: 50,
        maxWidth: 50
      },
      {
        colId: 'productId',
        field: 'productId',
        headerName: 'Product'
      },
      {
        colId: 'productDescription',
        field: 'productDescription',
        headerName: 'Description'
      },
      {
        field: 'dataId',
        colId: 'dataId_1',
        headerName: 'Document',
        cellRendererFramework: DocumentLinkCell,
        cellRendererParams: {
          dispatch,
          form: this?.props?.data?.form
        }
      },
      {
        cellStyle: { textAlign: 'center' },
        colId: 'lastUpdatedDate',
        field: 'lastUpdatedDate',
        headerClass: 'text-center center-align',
        headerName: 'Last Updated',
        valueFormatter: params => formatDate(params.value, 'M/D/YYYY')
      }
    ]

    return columnDefs
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.columnApi = params.columnApi

    this.gridApi.forEachNode(node => {
      if (node?.data?.isSelected) {
        node.setSelected(true)
      }
    })
  }

  onRowSelected = params => {
    console.log(params.api.getSelectedNodes(), this.state.pos)
    if (this._isMounted) {
      this.setState({
        pos: params.api.getSelectedNodes().reduce((acc, next) => {
          acc = acc.concat(next.rowIndex)
          return acc
        }, [])
      })
    }
  }

  render() {
    console.log('SafetyDataSheetsGrid', this.props)
    return (
      <div style={{ width: '100%' }}>
        <Grid
          getRowNodeId={data => `${data.dataId}-${data.productId}`}
          columnDefs={this.getColumnDefs()}
          rowData={this.props.data.sdSheets}
          onGridReady={this.onGridReady}
          onRowSelected={this.onRowSelected}
          rowSelection="multiple"
          height={400}
          suppressRowClickSelection
        />
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(SafetyDataSheetsGrid)
