import { createAsyncRequestTypes } from 'utils'
// import { PROPERTY_CHANGE } from './index'
const SHIPPING_CONFIRMATION = '@@shippingConfirmation'
// async
export const GET_SHIPPING_CONFIRMATION = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/GET_SHIPPING_CONFIRMATION`
)

export const DELETE_SHIPPING_CONFIRMATION = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/DELETE_SHIPPING_CONFIRMATION`
)

export const SAVE_SHIPPING_CONFIRMATION = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/SAVE_SHIPPING_CONFIRMATION`
)

export const CANCEL_SHIPPING_CONFIRMATION = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/CANCEL_SHIPPING_CONFIRMATION`
)

export const INITIATE_CARD_TERMINAL_SIGNATURE = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/INITIATE_CARD_TERMINAL_SIGNATURE`
)

export const ON_PROPERTY_CHANGE = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/ON_PROPERTY_CHANGE`
)

export const POLL_CARD_TERMINAL_SIGNATURE = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}POLL_CARD_TERMINAL_SIGNATURE`
)

export const PRINT_SHIPPING_CONFIRMATION = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/PRINT_SHIPPING_CONFIRMATION`
)

export const VIEW_SD_SHEET = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/VIEW_SD_SHEET`
)

export const UPDATE_INTERNAL_NOTES = createAsyncRequestTypes(
  `${SHIPPING_CONFIRMATION}/UPDATE_INTERNAL_NOTES`
)

export const REVERT_TO_LOCKED_DATA_ID = `${SHIPPING_CONFIRMATION}/REVERT_TO_LOCKED_DATA_ID`

export const GET_PREVIOUS_ORDER = `${SHIPPING_CONFIRMATION}/GET_PREVIOUS_ORDER`
export const REMOVE_PREVIOUS_ORDER = `${SHIPPING_CONFIRMATION}/REMOVE_PREVIOUS_ORDER`

export const ADD_BLANK_TRACKING_ROW = `${SHIPPING_CONFIRMATION}/ADD_BLANK_TRACKING_ROW`
export const REMOVE_BLANK_TRACKING_ROW = `${SHIPPING_CONFIRMATION}/REMOVE_BLANK_TRACKING_ROW`
