/* eslint react/sort-comp: 0, react/no-did-mount-set-state: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Select,
  MenuItem,
  Icon,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import shortid from 'shortid'
import { isDisabled, updateGridCellData } from 'components/EditableGrid/utils'
import { noop, plainDeepEqual } from 'utils'

function isValidDate(date) {
  return (
    date &&
    Object.prototype.toString.call(date) === '[object Date]' &&
    !isNaN(date)
  )
}

let isChanging = false

class DatepickerGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.initialValue ? props.initialValue : props.value,
      stringValue: props.initialValue ? props.initialValue : props.value
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value &&
      !prevState.value &&
      nextProps.value !== prevState.value &&
      !isChanging
    ) {
      return {
        value: nextProps.value,
        stringValue: nextProps.value
      }
    }

    return null
  }

  onChange = date => {
    isChanging = true
    // console.log('onChangeDate', date)
    const {
      colDef: {
        cellRendererParams: { dateFormat = 'MM/DD/YYYY' }
      }
    } = this.props

    if (date === null) {
      this.setState({ stringValue: '', value: '' }, () => {
        if (this.props.value !== this.state.value) {
          this.updateGridCellData('stringValue')
        }
        isChanging = false
      })
    } else {
      this.setState(
        { stringValue: new Date(moment(date).format(dateFormat)), value: date },
        () => {
          if (this.props.value !== this.state.value) {
            this.updateGridCellData('stringValue')
          }
          isChanging = false
        }
      )
    }
  }

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { isDisabled, disabledFn, formatter }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    if (isDisabled) {
      return isDisabled
    }

    return this.isDisabled()
  }

  render() {
    const {
      colDef: {
        cellRendererParams: {
          dateFormat = 'MM/DD/YYYY',
          mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
        }
      }
    } = this.props
    const { value } = this.state
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          clearable
          keyboard
          disabled={this.isFieldDisabled()}
          value={value}
          onChange={this.onChange}
          format={dateFormat}
          mask={mask}
          InputAdornmentProps={{
            className: 'editable-grid-datepicker'
          }}
          InputProps={{
            className: 'editable-grid-datepicker-input-wrapper',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ padding: 5 }}>
                  <Icon
                    fontSize="small"
                    // style={{
                    //   fontSize: 18
                    // }}
                  >
                    event
                  </Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
          onBlur={noop}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(DatepickerGridCell)
