import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@material-ui/core'
import { isDisabled, updateGridCellData } from 'components/EditableGrid/utils'
import { is } from 'utils'

class ToggleCheckboxCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    // this.updateGridCellData = updateGridCellData.bind(this)
    // this.isDisabled = isDisabled.bind(this)

    // const {
    //   colDef: {
    //     cellRendererParams: { disabledFn, tooltip }
    //   }
    // } = props

    // const tooltipDisplay =
    //   tooltip && props.data[tooltip] ? props.data[tooltip] : ''

    this.state = {
      // tooltipDisplay: tooltipDisplay || '',
      value: props.value || false
    }

    // if (disabledFn && typeof disabledFn === 'function') {
    //   this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
    //     this
    //   )
    // }
  }

  componentDidUpdate() {
    if (this.props.value !== this.state.value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.value })
    }
  }

  toggleCheckbox = () => {
    this.setState(
      {
        value: !this.state.value
      },
      () =>
        this.props.node.setDataValue(this.props.colDef.field, this.state.value)
    )
  }

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { isDisabled }
      },
      data
    } = this.props
    if (is.fn(isDisabled)) {
      return isDisabled(this.props)
    }
    if (is.bool(isDisabled)) {
      return isDisabled
    }
    return false
  }

  render() {
    // const {
    //   colDef: {
    //     cellRendererParams: { tooltip }
    //   }
    // } = this.props

    const { value } = this.state

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          width: '100%'
        }}
        // title={tooltipDisplay}
      >
        <IconButton
          onClick={this.toggleCheckbox}
          disabled={this.isFieldDisabled()}
          tabIndex={-1}
        >
          <Icon style={{ fontSize: 16 }}>
            {value ? 'check_box' : 'check_box_outline_blank'}
          </Icon>
        </IconButton>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ToggleCheckboxCell)
