import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { emptyList, getIn } from 'utils'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DDITextField } from 'ddiForm/wrapped'
import { setField } from 'ddiForm/actions'

const mapState = (state, ownProps) => {
  const { data: { form } } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const groups = getIn(formState, 'values.groups.itemGroups') || emptyList
  const groupOptions = getIn(formState, 'values.groups.itemGroupOptions') || emptyList
  const canAddGroups = getIn(formState, 'values.canAddGroups') || false

  return {
    groups,
    groupOptions,
    canAddGroups
  }
}

export const AddItemGroupModal = ({
  // form,
  data: {
    form,
    value
  },
  dispatch,
  groups,
  groupOptions,
  canAddGroups,
}) => {

  const [val, setVal] = useState({ dataId: '' })
  // const [textFieldVal, setValue]
  const [groupsList, setGroupsList] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (groups?.toJS) {
      setGroupsList(groups.toJS())
    }
  }, [groups])

  useEffect(() => {
    if (groupOptions?.toJS) {
      setOptions(groupOptions.toJS())
    }
  }, [groupOptions])

  useEffect(() => {
    if (value || value === '') {
      setVal({ dataId: value })
    }
  }, [value])

  const onChange = (e, v) => {
    if (v?.dataId) {
      // manually set addedItemGroupId when selecting from dropDown
      dispatch(setField(form, 'addedItemGroupId', v.dataId))
    } else {
      dispatch(setField(form, 'addedItemGroupId', null))
    }
  }

  return (
    <div>
        <Autocomplete
          id="add-item-group"
          options={canAddGroups ? groupsList : options}
          getOptionLabel={(option) => option.dataId}
          style={{ width: 300 }}
          freeSolo={canAddGroups}
          forcePopupIcon={!!groupsList.length}
          onChange={onChange}
          renderInput={(params) => (
            <DDITextField
              propertyName="addedItemGroupId"
              {...params}
            />)}
          value={val}
        />
    </div>
  )
}

export default connect(mapState)(AddItemGroupModal)