import React from 'react'
import { ddiForm } from 'ddiForm'
import { Icon } from '@material-ui/core'
import { bindSagaWithRest } from 'ddiForm/utils'
import { wrapActivitiesDataAccess } from 'components/MasterScreen/Activities/sagas'

import saveArgumentsSaga from 'sagas/customerContactSaveArgumentsSaga'
import Activities from 'components/MasterScreen/Activities'
import Attachments from 'components/MasterScreen/Attachments'
import CustomFields from 'components/MasterScreen/CustomFields'
import {
  checkIfCustomFieldsHidden,
  transformCustomFieldsData
} from 'components/MasterScreen/CustomFields/utils'

import Audit from 'components/MasterScreen/Audit'
import { mapAuditResponse } from 'components/MasterScreen/Audit/utils'

import Analysis from './tabs/Analysis'
// import Attachments from './tabs/Attachments'
import * as api from './api'

import Information from './tabs/Setup/Information'
import Permissions from './tabs/Setup/Permissions'
// import PaymentProfiles from './tabs/Setup/PaymentProfiles'
import Johnstone from './tabs/Setup/Johnstone'
import Vault from './tabs/Setup/Vault'

import LeftFooterButtons from './components/LeftFooterButtons'
import RightFooterButtons from './components/RightFooterButtons'
import SearchArea from './components/SearchArea'

import * as actions from './actions'
import sagas, {
  onSaveContactProcess,
  getAnalysisProcess,
  getListActivitiesApiParams,
  getPaymentProfilesProcess,
  getRecordArgumentsSaga
} from './sagas'
import behaviors from './behaviors'

import { mapDataResponse } from './utils'

import './styles/css-mod-ignore.scss'

export const tabIconStyle = { fontSize: 20 }

export const defaultArgs = {
  form: 'contact',
  title: 'Contact',
  masterOptions: {
    getRecordArgumentsSaga,
    hideReportButton: true,
    headerComponent: SearchArea,
    // footer: props => <div>hi</div>,
    footer: {
      left: LeftFooterButtons,
      right: /* props => <div>hi</div>// */ RightFooterButtons
    },
    saveArgumentsSaga,
    transformDataBeforeSave: transformCustomFieldsData,
    mapResponse({ response, tabIds = [], formState, groupNames = [] }) {
      return mapDataResponse({ response, tabIds, formState, groupNames })
    }
  },
  actions: {
    saveContactButton: actions.saveContactButton,
    setShipToId: actions.setShipToId,
    addCreditCard: actions.addPaymentSource.try,
    cancelContactEdit: actions.cancelContactEdit.try
    // getCustomerLabelPrintingCriteria:
    //   selectionCriteriaActions.getLabelPrintingCriteria,
    // getReport: selectionCriteriaActions.getReportCriteria
  },
  behaviors,
  sagas: { onInit: sagas, onSave: onSaveContactProcess },
  // allowMultiple: true,
  getEntityApi: api.getContact,
  updateTitleMethod: response => {
    const suffix =
      response.firstName && response.lastName
        ? `${response.firstName} ${response.lastName}`
        : response.description
    return `Contact - ${response.dataId} - "${suffix}"`
  }
}
export const defaultTabs = [
  {
    icon: <Icon style={tabIconStyle}>settings_applications</Icon>,
    tabs: [
      {
        component: Information,
        title: 'Information',
        access: 'information',
        callAlways: true
      },
      {
        component: Permissions,
        title: 'Permissions',
        access: 'permission',
        isHidden: formState => !formState.values.showPermissions,
        callAlways: true
      },
      {
        component: CustomFields,
        title: 'Custom Fields',
        access: 'customfields',
        callAlways: true,
        isHidden: checkIfCustomFieldsHidden
      },
      {
        component: Johnstone,
        title: 'Johnstone',
        access: 'johnstone',
        isHidden: formState => !formState.meta.isJohnstoneCustomer,
        callAlways: true
      },
      {
        component: Vault,
        title: 'Vault',
        isHidden: formState => !formState.values.showVault,
        access: getPaymentProfilesProcess,
        callAlways: true
      }
    ],
    title: 'Setup',
    access: 'setup',
    callAlways: true
  },
  {
    component: Analysis,
    title: 'Analysis',
    isHidden: formState => !formState.values.isCustomerContact,
    access: getAnalysisProcess,
    callAlways: true
  },
  {
    component: Activities,
    icon: <Icon style={tabIconStyle}>event</Icon>,
    title: 'Activities',
    access: bindSagaWithRest(getListActivitiesApiParams)(
      wrapActivitiesDataAccess
    ),
    callAlways: true
  },
  {
    access: 'attachments',
    component: Attachments,
    icon: <Icon style={tabIconStyle}>attachment</Icon>,
    title: 'Attachments'
  },
  {
    component: Audit,
    icon: <Icon style={tabIconStyle}>search</Icon>,
    title: 'Audit',
    access: 'audit',
    callAlways: true,
    mapResponse: mapAuditResponse
  }
]
defaultArgs.masterOptions.tabs = defaultTabs

const altOpts = {
  ...defaultArgs,
  form: 'customerMaster.contact',
  masterOptions: {
    ...defaultArgs.masterOptions,
    tabs: [defaultTabs[0], defaultTabs[3]]
  }
}

const fastReceiptDashboardOpts = {
  ...defaultArgs,
  form: 'fastReceipt.contact',
  masterOptions: {
    ...defaultArgs.masterOptions,
    tabs: [defaultTabs[0], defaultTabs[3]]
  }
}

const salesPersonDashboardOpts = {
  ...defaultArgs,
  form: 'salespersonDashboard.contact',
  masterOptions: {
    ...defaultArgs.masterOptions,
    tabs: [defaultTabs[0], defaultTabs[3]]
  }
}

const vendorMasterOpts = {
  ...defaultArgs,
  form: 'vendorMaster.contact',
  masterOptions: {
    ...defaultArgs.masterOptions,
    tabs: [defaultTabs[0], defaultTabs[3]]
  }
}

const vendorMasterInProductMasterOpts = {
  ...defaultArgs,
  form: 'productMaster.vendorMaster.contact',
  masterOptions: {
    ...defaultArgs.masterOptions,
    tabs: [defaultTabs[0], defaultTabs[3]]
  }
}

export const OrderOpts = form => {
  return ddiForm({
    ...defaultArgs,
    form: `${form}.contact`,
    masterOptions: {
      ...defaultArgs.masterOptions,
      tabs: [defaultTabs[0], defaultTabs[3]]
    }
  })()
}
// console.log(altOpts)
export const CustomerAddContact = ddiForm(altOpts)()

export const SalespersonDashboardAddContact = ddiForm(
  salesPersonDashboardOpts
)()

export const FastReceiptContactMaster = ddiForm(fastReceiptDashboardOpts)()

export const VendorAddContact = ddiForm(vendorMasterOpts)()

export const VendorMasterAddContactFromModal = ddiForm(
  vendorMasterInProductMasterOpts
)()

export default ddiForm(defaultArgs)()
