import { call, delay, fork, put, select, take } from 'redux-saga/effects'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { searchProcess } from 'ddiForm/MasterScreen/sagas'
import { tryChangeFormTab } from 'ddiForm/actions'
import { SET_FIELD, BLUR } from 'ddiForm/constants'
import { CLEAR_SEARCH } from 'components/Search/IndexSearch/constants'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'

import { api } from 'services'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'

export function* getLedgerGroupNames(form, groupNames = []) {
  const formState = yield select(getFormSelector(form))
  const baseGroupNames = getIn(formState, 'values.summary')
    ? [...groupNames]
    : [...groupNames, 'summary']

  if (groupNames.includes('transactions')) {
    return {
      transactionFromDate: getIn(
        formState,
        'fields.transactions.transactionFromDate'
      )
        ? getIn(formState, 'fields.transactions.transactionFromDate.value') ||
          ''
        : getIn(formState, 'meta.ledgerMeta.defaultTransactionDate'),
      groupNames: baseGroupNames
    }
  }

  if (groupNames.includes('paymentHistory')) {
    return {
      paymentHistoryYear: getIn(
        formState,
        'fields.paymentHistory.paymentHistoryYear'
      )
        ? getIn(formState, 'fields.paymentHistory.paymentHistoryYear.value') ||
          ''
        : getIn(formState, 'meta.ledgerMeta.defaultYear'),
      groupNames: baseGroupNames
    }
  }

  if (groupNames.includes('invoices')) {
    const invoiceType =
      getIn(formState, 'fields.invoices.invoiceType.value') || 'I'

    const invoicesGroupNames =
      invoiceType === 'P' ? [...groupNames] : [...groupNames, 'summary']
    // debugger
    return {
      invoiceType,
      invoiceYear:
        invoiceType === 'P'
          ? getIn(formState, 'fields.invoices.invoiceYear.value') || ''
          : '',
      groupNames: invoicesGroupNames
    }
  }

  if (groupNames.includes('orders')) {
    return {
      poStatus: getIn(formState, 'fields.orders.poStatus.value') || 'O',
      groupNames: baseGroupNames
    }
  }

  return {
    groupNames: [...groupNames]
  }
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName },
      type
    } = yield take([BLUR, SET_FIELD])

    if (form === formListener) {
      if (
        type === SET_FIELD &&
        propertyName &&
        (propertyName.match(/invoices\./) ||
          propertyName.match(/paymentHistory\./) ||
          propertyName.match(/transactions\./) ||
          propertyName.match(/orders\./))
      ) {
        yield delay(1000)
        yield fork(searchProcess, form, null, true)
      }

      if (
        type === BLUR &&
        propertyName === 'transactions.transactionFromDate'
      ) {
        yield delay(1000)
        yield fork(searchProcess, form, null, true)
      }
    }
  }
}

export function* getVendorInvoiceReportProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const invoiceType = getIn(formState, 'fields.invoices.invoiceType.value')
  const invoiceYear =
    getIn(formState, 'fields.invoices.invoiceYear.value') || ''
  const includeRemitToInvoices =
    getIn(formState, 'fields.includeRemitToInvoices.value') || false

  if (!dataId || !invoiceType) {
    return
  }

  yield put(actions.getVendorInvoiceReport.request(form))
  const { response, error } = yield call(api.getVendorInvoiceReport, {
    dataId,
    invoiceType,
    invoiceYear,
    includeRemitToInvoices
  })

  if (response) {
    yield put(actions.getVendorInvoiceReport.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(actions.getVendorInvoiceReport.failure(error, form))
  }
}

export function* getVendorInvoiceReportListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_VENDOR_INVOICE_REPORT.TRY)

    if (form === formListener) {
      yield fork(getVendorInvoiceReportProcess, form)
    }
  }
}

export function* getPaymentHistoryReportProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const paymentHistoryYear = getIn(
    formState,
    'fields.paymentHistory.paymentHistoryYear.value'
  )

  if (!dataId) {
    return
  }

  yield put(actions.getPaymentHistoryReport.request(form))
  const { response, error } = yield call(api.getVendorPaymentHistoryReport, {
    dataId,
    paymentHistoryYear
  })

  if (response) {
    yield put(actions.getPaymentHistoryReport.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(actions.getPaymentHistoryReport.failure(error, form))
  }
}

export function* getPaymentHistoryReportListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_PAYMENT_HISTORY_REPORT.TRY)

    if (form === formListener) {
      yield fork(getPaymentHistoryReportProcess, form)
    }
  }
}

export function* saveAgingViewProcess(form, view) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!dataId || !view) {
    return
  }

  yield put(actions.saveAgingView.request(form))
  const { response, error } = yield call(api.saveVendorAgingView, {
    dataId,
    view
  })

  if (response) {
    yield put(actions.saveAgingView.success(response, form))
  } else {
    yield put(actions.saveAgingView.failure(error, form))
  }
}

export function* saveAgingViewListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { view }
    } = yield take(CONSTANTS.SAVE_AGING_VIEW.TRY)

    if (form === formListener) {
      yield fork(saveAgingViewProcess, form, view)
    }
  }
}

export function* resetLedgerViewBeforeLoadVendorRecord(form) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  if (selectedPrimaryTab === 'ledger' && selectedSecondaryTab !== 'invoices') {
    yield put(tryChangeFormTab(form, 'ledger', 'invoices'))
  }
}

export function* clearSearchListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(CLEAR_SEARCH)

    if (form === formListener && propertyName === 'dataId') {
      yield fork(resetLedgerViewBeforeLoadVendorRecord, form)
    }
  }
}

export default function* ledgerSagas(form) {
  yield fork(setFieldListener, form)
  yield fork(getVendorInvoiceReportListener, form)
  yield fork(getPaymentHistoryReportListener, form)
  yield fork(saveAgingViewListener, form)
  yield fork(clearSearchListener, form)
}
