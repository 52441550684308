import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { getField, getValue } from 'utils'

export const FastShipToModalTitle = ({ save, shipToId }) => {
  const getTitle = () => {
    let title = 'Manual Ship to Address'
    if (save) {
      title = 'Add Ship To'
    }
    return title
  }

  return <div>{getTitle()}</div>
}

/* FIXED SVE 9/23/2020 */
export const contextObj = {
  save: form => getField(form, 'manualShipTo.save', false),
  shipToId: form => getValue(form, 'shipToId', null)
}

export default withContext(contextObj)(FastShipToModalTitle)
