import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Icon } from '@material-ui/core'
import styled from 'styled-components'

import { removePaymentSource } from 'pages/ContactMaster/actions'

const GridWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

export class DeleteGridCell extends Component {
  static propTypes = {
    // activities: PropTypes.array.isRequired,
    // colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }
  // deleteActivity = () => {
  //   const {
  //     activities,
  //     data: { dataId },
  //     dispatch
  //   } = this.props
  //   const activeDataSet = activities.find(x => x.dataId === dataId)
  //   dispatch(
  //     deleteActivityAsync.request(this.props.form, { activeDataSet, dataId })
  //   )
  // }

  deletePayment = () => {
    const {
      form,
      data: { token },
      dispatch
    } = this.props
    dispatch(removePaymentSource.try(form, { token }))
  }

  // dispatchDeleteProcess = () => {
  //   const {
  //     colDef: { type }
  //   } = this.props

  //   const methods = {
  //     // activity: this.deleteActivity,
  //     payment: this.deletePayment
  //   }

  //   if (type && methods[type]) {
  //     return methods[type]()
  //   }

  //   return false
  // }

  render() {
    return (
      <GridWrapper>
        <Icon
          color="error"
          style={{
            display: 'inline-block',
            fontSize: 18,
            marginLeft: -5
          }}
          onClick={this.deletePayment}
        >
          cancel
        </Icon>
      </GridWrapper>
    )
  }
}

export default connect()(DeleteGridCell)
