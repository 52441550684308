import React, { useState, useRef } from 'react'
import { DDITextField } from 'ddiForm/wrapped'
import { Paper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getIn } from 'utils'
import { connect } from 'react-redux'

import NumberField from './PricingCalculatorNumberField'

import '../styles/pricing-calculator.scss'

export const PriceCalculatorCell = ({
  dispatch,
  form,
  handleClose,
  setEdited
}) => {
  const [desiredgp, setDesiredGP] = useState('')
  const [desiredmultiplier, setDesiredMultiplier] = useState('')

  const gpRef = useRef()
  const mRef = useRef()

  const onKeyDown = () => async event => {
    event.persist()
    if (event.key === 'Tab') {
      if (gpRef.current && gpRef.current.blur) {
        await gpRef.current.blur()
      }
      handleClose(true, event.shiftKey)
    }
  }

  const onChange = () => setEdited()

  return (
    <div className="recalc-prices-pricing-calculator-outer-wrapper">
      <Paper className="paper" elevation={3}>
        <div className="text-row-wrapper">
          <div className="text-row-label">Enter Desired Gross Profit</div>
          <div className="text-row-input">
            <NumberField
              value={desiredgp}
              dispatch={dispatch}
              propertyName="desiredgp"
              form={form}
              autoFocus
              inputRef={gpRef}
              // nextInput={qRef}
              fixedDecimalScale
              decimalScale={2}
              onKeyDown={onKeyDown}
              onChange={onChange}
              noDecimalLimit
              textAlign="right"
              disabled={false}
              setTextField={setDesiredGP}
              clearTextField={setDesiredMultiplier}
            />
          </div>
        </div>

        <div className="text-row-wrapper">
          <div className="text-row-label">Enter Desired Multiplier</div>
          <div className="text-row-input">
            <NumberField
              value={desiredmultiplier}
              dispatch={dispatch}
              propertyName="desiredmultiplier"
              form={form}
              fixedDecimalScale
              decimalScale={2}
              inputRef={mRef}
              onKeyDown={onKeyDown}
              onChange={onChange}
              noDecimalLimit
              textAlign="right"
              disabled={false}
              setTextField={setDesiredMultiplier}
              clearTextField={setDesiredGP}
            />
          </div>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false

  return {
    isEditing
  }
}

const connected = connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(PriceCalculatorCell)
export default React.memo(connected)
