import { call, fork, take, select, put } from 'redux-saga/effects'
import { api } from 'services'
import { addModal, confirm as confirmModalAction } from 'modals/actions'
import { getIn } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { SET_FIELD } from 'ddiForm/constants'
import { getErrorMessages } from 'pages/ProductMaster/utils'
import { warningModal } from 'modals/sagas'
// import { openFifoCostProcess } from 'pages/ProductMaster/sagas/analysisSagas'
import { OPEN_FIFO_COST } from 'pages/ProductMaster/constants'
import FifoCostModalGrid from 'pages/ProductMaster/components/FifoCostModalGrid'
import RecalculateCostsModal from 'pages/SalesOrder/components/RecalculateCostsModal'
import RecalculatePricesModal from 'pages/SalesOrder/components/RecalculatePrices'
import RecalculateModalActions from 'pages/SalesOrder/components/RecalculatePrices/components/RecalculateModalActions'
import { openFifoCost } from 'pages/ProductMaster/actions'
import { mapLinePropertyChangeResponse } from '../utils'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'

const recalculateFields = [
  'customerCategoryId',
  'recalculateType',
  'includeOverrides'
]

export function* recalculateCostsProcess(form, thunk, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid') || null
  const groupNames = ['header', 'detail']
  if (guid) {
    const { response, error } = yield call(api.massChange, {
      guid,
      action: 'multiline',
      properties: { recalculatecosts: payload.overrides },
      groupNames,
      activeLineNumbers: payload.selectedRows
    })

    if (response) {
      const res = mapLinePropertyChangeResponse(
        { record: response },
        groupNames
      )
      yield put(
        actions.updateGridItem.success(
          {
            ...res
          },
          { form, thunk }
        )
      )
    } else {
      yield put(actions.updateGridItem.failure(error, { form, thunk }))
    }
  }
}

export function* launchRecalculatePricesModalProcess(form) {
  yield put(actions.launchRecalculatePricesModal.request(form))
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid') || null

  if (guid) {
    const { response, error } = yield call(api.recalculatePrices, {
      action: 'open',
      guid
    })

    if (response) {
      const modalOpts = {
        component: RecalculatePricesModal,
        options: {
          actions: RecalculateModalActions,
          data: {
            ...response,
            form
          },
          width: '1200',
          maxHeight: '100%',
          padding: '5px',
          title: 'Recalculate S/O Prices'
        }
      }

      yield put(actions.launchRecalculatePricesModal.success(response, form))

      const modal = yield call(addModal, form, modalOpts)

      yield put(modal)
    } else {
      yield put(actions.launchRecalculatePricesModal.failure(error, form))
    }
  }

  return null
}

export function* launchRecalculateCostsModalProcess(form, { gridApi }) {
  const modalOpts = {
    component: RecalculateCostsModal,
    props: {
      form,
      gridApi,

      width: '600',
      maxHeight: '30%',
      padding: '5px',
      title: 'Recalculate S/O Costs'
    }
  }

  // yield put(actions.launchRecalculatePricesModal.success(response, form))

  const modal = yield call(addModal, form, modalOpts)

  yield put(modal)
}

export function* launchRecalculatePricesModalListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_RECALCULATE_PRICES_MODAL.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(launchRecalculatePricesModalProcess, form)
    }
  }
}

export function* recalculateCostsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.RECALCULATE_COSTS.REQUEST)
    const {
      meta: { form, thunk },
      payload
    } = action

    if (form === formListener) {
      yield fork(recalculateCostsProcess, form, thunk, payload)
    }
  }
}

export function* launchRecalculateCostsModalListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LAUNCH_RECALCULATE_COSTS_MODAL)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(launchRecalculateCostsModalProcess, form, payload)
    }
  }
}

export function* recalculatePricesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.RECALCULATE_PRICES.TRY)
    const {
      meta: { form },
      payload
    } = action
    if (form === formListener) {
      yield fork(recalculatePricesProcess, form, payload)
    }
  }
}

export function* recalculatePricesProcess(form, payload) {
  const { guid, modalId } = payload

  yield put(actions.recalculatePrices.request(form))

  const { response, error } = yield call(api.recalculatePrices, {
    action: 'recalculate',
    guid,
    groupNames: ['header', 'detail']
  })

  if (response) {
    yield put(actions.recalculatePrices.success(response, form))
    yield put(confirmModalAction(form, modalId))
  } else {
    const { status, validationErrors } = error

    if (status === 498 && validationErrors) {
      const messages = getErrorMessages(validationErrors)
      yield fork(warningModal, messages, 'Attention!')
    }

    yield put(actions.recalculatePrices.failure(error, form))
  }

  return null
}

export function* closeRecalculatePricesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CLOSE_RECALCULATE_PRICES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(closeRecalculatePricesProcess, form, payload)
    }
  }
}

export function* closeRecalculatePricesProcess(form, payload) {
  const { guid, modalId } = payload
  yield put(actions.closeRecalculatePrices.request(form))

  const { response, error } = yield call(api.recalculatePrices, {
    action: 'close',
    guid
  })

  if (response) {
    yield put(actions.closeRecalculatePrices.success(response, form))
    yield put(confirmModalAction(form, modalId))
  } else {
    yield put(actions.closeRecalculatePrices.failure(error, form))
  }
}

export function* setFieldsListener(formListener) {
  while (true) {
    const action = yield take(SET_FIELD)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (recalculateFields.includes(payload?.propertyName)) {
        yield fork(changeRecalculatePricesProcess, form, payload)
      }
    }
  }
}

export function* changeRecalculatePricesProcess(form, payload) {
  const { propertyName, value = null } = payload

  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const sequenceNumber =
    getIn(formState, 'fields.rollups.selectedSequenceNumber') || null

  yield put(actions.changeRecalculatePrices.request(form))

  const properties = {
    [propertyName]: value
  }

  const { response, error } = yield call(api.recalculatePrices, {
    action: 'change',
    guid,
    sequenceNumber,
    properties
  })

  if (response) {
    yield put(
      actions.changeRecalculatePrices.success(
        { ...response, propertyName },
        form
      )
    )
  } else {
    yield put(actions.changeRecalculatePrices.failure(error, form))
  }
}

export function* changeRecalculatePricesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.CHANGE_RECALCULATE_PRICES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(changeRecalculatePricesProcess, form, payload)
    }
  }
}

export function* recalculatePricesGridTabAttemptListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.RECALCULATE_PRICES_GRID_TAB_ATTEMPT)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(recalcTabProcess, action)
    }
  }
}

export function* recalcTabProcess(action) {
  yield take([
    CONSTANTS.CHANGE_RECALCULATE_PRICES.SUCCESS,
    CONSTANTS.CHANGE_RECALCULATE_PRICES.FAILURE
  ])

  if (action.payload.api) {
    action.payload.api[
      action.payload.shiftKey ? 'tabToPreviousCell' : 'tabToNextCell'
    ]()
  }
}

export function* recalcModalActionsListener(formListener) {
  while (true) {
    const action = yield take([
      CONSTANTS.REPEAT_ALL_RECALCULATE.TRY,
      CONSTANTS.CLEAR_ALL_RECALCULATE.TRY
    ])
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(changeRecalculatePricesProcess, form, payload)
    }
  }
}

export function* openFifoCostListener(formListener) {
  while (true) {
    const action = yield take(OPEN_FIFO_COST.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openFifoCostProcess, form, payload)
    }
  }
}

export function* openFifoCostProcess(form, payload) {
  const { dispatch } = payload

  const formState = yield select(getFormSelector(form))
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  const selectedSequenceNumber =
    getIn(formState, 'fields.rollups.selectedSequenceNumber') || null
  const rollups = getIn(formState, 'fields.rollups.rowData')?.toJS
    ? getIn(formState, 'fields.rollups.rowData').toJS()
    : []

  if (!selectedSequenceNumber) return

  const { dataId, warehouseId } = rollups.find(
    x => x.sequenceNumber === selectedSequenceNumber
  )

  yield put(openFifoCost.request(form))

  const { response, error } = yield call(api.getFifoCost, {
    dataId,
    warehouseId
  })

  if (response) {
    const modalOpts = {
      component: FifoCostModalGrid,
      options: {
        data: {
          actions: [{ primary: true, title: 'Close' }],
          dispatch,
          form,
          rowData: response,
          isMobile
        },
        disableEnforceFocus: true,
        padding: '5px',
        title: 'FIFO Cost Layer Detail'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    yield put(openFifoCost.success(response, { form }))
  } else {
    const { status, validationErrors } = error
    yield put(openFifoCost.failure(form))
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }
  }
}

export default function* recalculatePricesSagas(form) {
  yield fork(launchRecalculatePricesModalListener, form)
  yield fork(launchRecalculateCostsModalListener, form)
  yield fork(closeRecalculatePricesListener, form)
  yield fork(setFieldsListener, form)
  yield fork(changeRecalculatePricesListener, form)
  yield fork(recalculatePricesListener, form)
  yield fork(recalcModalActionsListener, form)
  yield fork(openFifoCostListener, form)
  yield fork(recalculateCostsListener, form)
}
