/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  DDIDatePicker,
  DDIToggle,
  DDISelectField,
  DDINumberSpinner
} from 'ddiForm/wrapped'
import { getMeta, is, getValue, getIn, emptyList } from 'utils'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'

import '../styles/recurring.scss'

export const Recurring = ({
  canChangeRecurring,
  dispatch,
  form,
  recurring,
  recurringDays,
  recurringExpansionPanel,
  recurringIntervalOptionsList,
  recurringIntervalType,
  recurringWeeklyOptionsList
}) => {
  const recurringIntervalOptions = recurringIntervalOptionsList?.toJS
    ? recurringIntervalOptionsList.toJS()
    : []
  const recurringWeeklyOptions = recurringWeeklyOptionsList?.toJS
    ? recurringWeeklyOptionsList.toJS()
    : []

  const canSelectDayOfTheWeek =
    recurringIntervalType &&
    (recurringIntervalType === 'W' || recurringIntervalType === 'B')

  const canSelectRecurringDays =
    recurringIntervalType && recurringIntervalType === 'D'

  const onToggle = panelState => {
    dispatch(
      storeUIFeatureState(form, {
        feature: 'recurringExpansionPanel',
        featureState: panelState
      })
    )
  }

  return (
    <DDICardWrapper
      title="Recurring"
      isExpandable
      defaultExpanded={recurringExpansionPanel}
      expansionPanelStyle={{
        background: '#f5f7f7'
      }}
      onExpandedCb={onToggle}
    >
      <DDIToggle
        propertyName="recurring"
        label="Recurring"
        disabled={!canChangeRecurring}
        preventAutoDisable
      />

      {recurring && (
        <>
          <div className="recurring-module-two-col-flex-wrapper">
            {/* TOP OPTIONS */}
            <div className="two-col-flex-left">
              <DDISelectField
                propertyName="recurringIntervalType"
                label="Frquency"
                values={recurringIntervalOptions}
              />
            </div>

            <div className="two-col-flex-right">
              {canSelectDayOfTheWeek && (
                <DDISelectField
                  propertyName="recurringWeeklyType"
                  label="Weekly"
                  values={recurringWeeklyOptions}
                  preventAutoDisable
                />
              )}
            </div>

            <div className="two-col-flex-left">
              {canSelectRecurringDays && (
                <div className="center-flex-wrapper">
                  <label className="label-style">Days</label>
                  <DDINumberSpinner
                    propertyName="recurringDays"
                    type="number"
                    wrapperStyle={{ marginLeft: 10 }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="recurring-module-two-col-flex-wrapper">
            {/* BOTTOM OPTIONS */}
            <div className="two-col-flex-left">
              <DDIDatePicker
                propertyName="expirationDate"
                label="Expiration Date"
                views={['date']}
                tabIndex={-1}
                preventAutoDisable
              />
            </div>

            <div className="two-col-flex-right">
              <DDIToggle
                propertyName="recurringAutoInvoice"
                label="Auto Invoice"
              />
            </div>
          </div>
        </>
      )}
    </DDICardWrapper>
  )
}

Recurring.propTypes = {
  canChangeRecurring: PropTypes.bool.isRequired,
  recurringIntervalOptions: PropTypes.array.isRequired,
  recurringWeeklyOptions: PropTypes.array.isRequired
}

/* fixed SVE 9/24/2020 */
export const contextObj = {
  canChangeRecurring: form =>
    is.bool(getIn(form, 'values.canChangeRecurring'))
      ? getIn(form, 'values.canChangeRecurring')
      : false,
  recurring: form =>
    getIn(form, 'fields.recurring.value') ||
    getIn(form, 'values.recurring') ||
    false,
  recurringAutoInvoice: form =>
    is.bool(getIn(form, 'values.recurringAutoInvoice'))
      ? getIn(form, 'values.recurringAutoInvoice')
      : false,
  recurringDays: form => getValue(form, 'recurringDays', null),
  recurringExpansionPanel: form =>
    getIn(form, 'ui.recurringExpansionPanel') || false,
  recurringIntervalOptionsList: form =>
    getMeta(form, 'recurringIntervalOptions', emptyList),
  recurringIntervalType: form => getValue(form, 'recurringIntervalType', ''),
  recurringWeeklyOptionsList: form =>
    getMeta(form, 'recurringWeeklyOptions', emptyList),
  recurringWeeklyType: form => getValue(form, 'recurringWeeklyType', null)
}

export default withContext(contextObj)(Recurring)
