/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { List } from 'immutable'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import moment from 'moment'
import { withContext, Label, Field } from 'ddiForm'
import { DDIIndexSearch, DDISelectField, DDITextField } from 'ddiForm/wrapped'
import { maxLength, required } from 'ddiForm/validations'

import {
  AppBar,
  Backdrop,
  CircularProgress,
  Icon,
  InputLabel,
  Tabs,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import { dynamicColumnDefs } from 'pages/ProductMaster/tabs/Analysis/Overview'
import DDICardWrapper from 'components/DDICardWrapper'
import masterStyles from 'theme/masterStyles'
import {
  // createSearchInputRow,
  getRowNodeId,
  setGridHeight,
  styles
} from 'pages/ProductMaster/utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import {
  formatDateFields,
  generateTabs,
  layoutFlex,
  getField,
  getIn,
  getValue,
  getMeta,
  emptyList,
  isSecureFieldDisabled
} from 'utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'

/* Editable Grid Stuffs */
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'

import RollupSchedule from './components/RollupSchedule'
import UomSetup from './components/UomSetup'

const showErrorIcon = memoize(() => (
  <Icon
    style={{
      color: '#d9534f',
      fontSize: 16,
      height: 20,
      width: 20
    }}
  >
    error_outline
  </Icon>
))

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

const ContainedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1
  }
})(Backdrop)

export const createTextArea = (
  propertyName,
  validate = [],
  placeholder = 'Enter Message'
) => (
  <DDITextField
    fullWidth
    propertyName={propertyName}
    name={propertyName}
    id={propertyName}
    placeholder={placeholder}
    multiline
    minWidth="100%"
    rows={4}
    style={{ width: '100%' }}
    variant="outlined"
    validate={validate}
  />
)

const uomLabels = [
  { propertyName: 'uomForDisplayId', label: 'Display' },
  { propertyName: 'uomForPriceId', label: 'Price' },
  { propertyName: 'uomForPurchasingId', label: 'Purchase' }
]

const createUOMLabels = memoize((propertyName, label) => (
  <div style={{ alignItems: 'center', display: 'flex' }}>
    <span style={{ marginRight: 3 }}>{label}</span>
    <Label
      propertyName={propertyName}
      includeIcon={false}
      labelStyle={{
        background: 'none',
        color: '#444',
        fontSize: 'inherit',
        fontWeight: 'bold'
      }}
    />
  </div>
))

export const dynamicColumnDefs = ({
  dispatch,
  isEditing,
  focusedCell,
  form,
  onCellChange,
  propertyName,
  securedFields
}) => {
  const params = {
    disabled: !isEditing,
    focusedCell,
    form,
    indexSearchType: 'warehouse',
    onChange: onCellChange,
    propertyName
  }

  const colDef = [
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellStyle: prms =>
        prms.data.dataId === 'FUTURE'
          ? {
              color: isEditing ? '#444' : '#777',
              fontWeight: 'bold',
              paddingLeft: 10
            }
          : { color: isEditing ? '#444' : '#777', paddingLeft: 10 },
      dispatch,
      field: 'description',
      form,
      headerName: 'Column',
      filter: false,
      suppressMenu: true,
      suppressNavigable: true,
      sortable: false
    },
    {
      cellStyle: prms =>
        prms.data.dataId === 'FUTURE'
          ? {
              color: isEditing ? '#444' : '#777',
              fontWeight: 'bold'
            }
          : {
              color: isEditing ? '#444' : '#777'
            },
      field: 'amount',
      headerName: 'Amount',
      headerClass: 'align-right',
      filter: false,
      suppressMenu: true,
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: {
        ...params,
        decimalScale: 4,
        dollarField: true,
        fixedDecimalScale: true,
        formatter: 'number',
        disabledFn: data => {
          if (
            data.dataId === 'F' ||
            data.dataId === 'FUTURE' ||
            data.disabled
          ) {
            return true
          }

          return isSecureFieldDisabled(data.dataId, isEditing, securedFields)
        }
      },
      sortable: false
    },
    {
      cellStyle: prms =>
        prms.data.dataId === 'FUTURE'
          ? { color: isEditing ? '#444' : '#777', fontWeight: 'bold' }
          : { color: isEditing ? '#444' : '#777' },
      cellClass: 'text-center align-center',
      field: 'changedDate',
      headerName: 'Changed',
      headerClass: 'text-center align-center',
      filter: false,
      suppressMenu: true,
      suppressNavigable: true,
      valueFormatter: prms => formatDateFields(prms),
      sortable: false
    }
  ]
  return colDef
}

export class Main extends Component {
  static propTypes = {
    costPriceRowData: PropTypes.array.isRequired,
    // dispatch: PropTypes.func.isRequired,
    // form: PropTypes.string.isRequired,
    // height: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    productStatusOptions: PropTypes.array.isRequired,
    uomForDisplayId: PropTypes.string.isRequired,
    uomForPriceId: PropTypes.string.isRequired,
    uomForPurchasingId: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: 'Main'
    }

    // this.searchInputs = searchInputs.map((item, idx) =>
    //   createSearchInputRow(
    //     item.key,
    //     item.label,
    //     item.description,
    //     styles,
    //     item.validations
    //   )
    // )

    this.quoteDescription = createTextArea(
      'quoteDescription',
      [],
      'Enter Quote Description'
    )
    this.catalogDescription = createTextArea(
      'catalogDescription',
      [maxLength(30)],
      'Enter Catalog Description'
    )
    this.soCommentsDescription = createTextArea(
      'soExternalComments',
      [],
      'Enter S/O External Comments'
    )

    this.uomLabels = uomLabels.map((item, idx) =>
      createUOMLabels(item.propertyName, item.label)
    )
  }

  handleTabChange = (event, value) => {
    if (typeof value === 'string') this.setState({ tab: value })
  }

  getGridHeight = () => {
    const { costPriceRowData } = this.props
    if (costPriceRowData && costPriceRowData.size) {
      return setGridHeight(costPriceRowData.toJS())
    }

    return 0
  }

  render() {
    const {
      hasRecord,
      isEditing,
      isPostingPriceOrCost,
      costPriceRowData,
      productStatusOptions,
      statusTypeErrorMessage,
      uomForDisplayId,
      uomForPriceId,
      uomForPurchasingId,
      templateKey,
      securedFields
    } = this.props

    const { tab } = this.state
    const uomLabelStyle = {
      color: '#444',
      fontSize: 14,
      fontWeight: 'normal',
      margin: '0 5px 0 0'
    }
    // console.log('MainProps', this.props)

    return (
      <div style={layoutFlex('90rem')}>
        <div style={masterStyles.twoColFlex.wrapper}>
          <div style={masterStyles.twoColFlex.left}>
            <DDICardWrapper title="Main Info" contentStyle={{ padding: 10 }}>
              <div className="tabs-wrapper" style={{ marginBottom: 15 }}>
                <AppBar position="static" style={{ boxShadow: 'none' }}>
                  <Tabs value={tab} onChange={this.handleTabChange} scrollable>
                    {generateTabs([
                      'Main',
                      'Quote',
                      'Catalog',
                      'S/O Ext Comments'
                    ])}
                  </Tabs>
                </AppBar>
                <TabContainer>
                  <div style={{ marginTop: 10 }}>
                    {tab === 'Main' && (
                      <DDITextField
                        fullWidth
                        propertyName="description"
                        name="description"
                        id="description"
                        placeholder="Enter Product Description"
                        multiline
                        minWidth="100%"
                        rows={4}
                        style={{ width: '100%' }}
                        variant="outlined"
                        validate={templateKey ? null : [required]}
                      />
                    )}
                    {tab === 'Quote' && this.quoteDescription}
                    {tab === 'Catalog' && this.catalogDescription}
                    {tab === 'S/O Ext Comments' && this.soCommentsDescription}
                  </div>
                </TabContainer>
              </div>
              <div style={styles.searchRow.wrapper}>
                <label htmlFor="productLineId" style={styles.searchRow.label}>
                  Product Line
                </label>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="productLineId"
                    id="productLineId"
                    metaKey="productLineId"
                    fullWidth
                    minWidth="100%"
                    validate={[required]}
                    preventAutoDisable
                    disabled={isSecureFieldDisabled(
                      'productLineId',
                      isEditing,
                      securedFields
                    )}
                  />
                </div>
                <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
                  <Field
                    component={Label}
                    propertyName="productLineDescription"
                    labelStyle={{ width: '100%' }}
                  />
                </div>
              </div>
              <div style={styles.searchRow.wrapper}>
                <label htmlFor="priceGroupId" style={styles.searchRow.label}>
                  Price Group
                </label>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="priceGroupId"
                    id="priceGroupId"
                    metaKey="priceGroupId"
                    fullWidth
                    minWidth="100%"
                    preventAutoDisable
                    disabled={isSecureFieldDisabled(
                      'priceGroupId',
                      isEditing,
                      securedFields
                    )}
                  />
                </div>
                <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
                  <Field
                    component={Label}
                    propertyName="priceGroupDescription"
                    labelStyle={{ width: '100%' }}
                  />
                </div>
              </div>
              <div style={styles.searchRow.wrapper}>
                <label htmlFor="buyLineId" style={styles.searchRow.label}>
                  Buy Line
                </label>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="buyLineId"
                    id="buyLineId"
                    metaKey="buyLineId"
                    fullWidth
                    minWidth="100%"
                    preventAutoDisable
                    disabled={isSecureFieldDisabled(
                      'buyLineId',
                      isEditing,
                      securedFields
                    )}
                  />
                </div>
                <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
                  <Field
                    component={Label}
                    propertyName="buyLineDescription"
                    labelStyle={{ width: '100%' }}
                  />
                </div>
              </div>
              <div style={styles.searchRow.wrapper}>
                <label
                  htmlFor="consignmentVendorId"
                  style={styles.searchRow.label}
                >
                  Consign Vendor
                </label>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="consignmentVendorId"
                    id="consignmentVendorId"
                    metaKey="consignmentVendorId"
                    fullWidth
                    minWidth="100%"
                  />
                </div>
                <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
                  <Field
                    component={Label}
                    propertyName="consignmentVendorDescription"
                    labelStyle={{ width: '100%' }}
                  />
                </div>
              </div>
              <div style={styles.searchRow.wrapper}>
                <label htmlFor="hazMatId" style={styles.searchRow.label}>
                  Haz Mat
                </label>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="hazMatId"
                    id="hazMatId"
                    metaKey="hazMatId"
                    fullWidth
                    minWidth="100%"
                  />
                </div>
                <div style={{ ...styles.searchRow.description, maxWidth: 200 }}>
                  <Field
                    component={Label}
                    propertyName="hazMatDescription"
                    labelStyle={{ width: '100%' }}
                  />
                </div>
              </div>

              {/* {this.searchInputs} */}
              <AssociatedFieldsWrapper style={{ padding: '0 0 10px 0' }}>
                <div className="secondary-inputs">
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around'
                    }}
                  >
                    <div style={{ width: '40%' }}>
                      <DDITextField
                        propertyName="upcNumber"
                        placeholder="Enter UPC Number"
                        label="UPC Number"
                        margin="dense"
                        fullWidth
                        validate={[maxLength(30)]}
                      />
                    </div>
                    <div style={{ width: '40%' }}>
                      <DDITextField
                        propertyName="mfgNumber"
                        placeholder="Enter Mfg. Number"
                        label="MFG Number"
                        margin="dense"
                        fullWidth
                        validate={[maxLength(75)]}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-around'
                    }}
                  >
                    <div style={{ width: '40%' }}>
                      <DDITextField
                        propertyName="pricingServiceNumber"
                        placeholder="Enter Pricing Service Number"
                        label="Pricing Service #"
                        margin="dense"
                        fullWidth
                      />
                    </div>
                    <div style={{ width: '40%' }}>
                      <DDITextField
                        propertyName="sellingBoxQuantity"
                        placeholder="Enter Selling Box Quantity"
                        label="Selling Box Quantity"
                        margin="dense"
                        fullWidth
                        // validate={[maxLength(75)]}
                        mask="numeric"
                        inputProps={{
                          allowDecimal: true
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AssociatedFieldsWrapper>
            </DDICardWrapper>
            <DDICardWrapper
              title="Units of Measure"
              contentStyle={{ minHeight: 35, padding: 10 }}
            >
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <label style={uomLabelStyle}>Display:</label>{' '}
                    {hasRecord && !uomForDisplayId ? (
                      showErrorIcon()
                    ) : (
                      <strong>{uomForDisplayId}</strong>
                    )}
                  </span>
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <label style={uomLabelStyle}>Price:</label>{' '}
                    {hasRecord && !uomForPriceId ? (
                      showErrorIcon()
                    ) : (
                      <strong>{uomForPriceId}</strong>
                    )}
                  </span>
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    <label style={uomLabelStyle}>Purchase:</label>{' '}
                    {hasRecord && !uomForPurchasingId ? (
                      showErrorIcon()
                    ) : (
                      <strong>{uomForPurchasingId}</strong>
                    )}
                  </span>
                  <UomSetup />
                </div>
              </div>
            </DDICardWrapper>
          </div>
          <div style={masterStyles.twoColFlex.right}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: 300,
                maxWidth: 500
              }}
            >
              <DDICardWrapper
                title="Internal Information"
                contentStyle={{ padding: '0 10px 10px 10px' }}
              >
                <div
                  style={{
                    ...masterStyles.twoColInputsFlex.wrapper,
                    alignItems: 'center'
                  }}
                >
                  <div style={masterStyles.twoColInputsFlex.left}>
                    <div
                      style={{
                        marginBottom: 0,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span style={{ marginRight: 0 }}>Created by:</span>
                      <Label
                        propertyName="enteredBy"
                        includeIcon={false}
                        labelStyle={{
                          background: 'none',
                          color: '#444',
                          fontSize: 'inherit',
                          fontWeight: 'bold'
                        }}
                      />
                      <Label
                        propertyName="entryDate"
                        formatting={v =>
                          v ? moment(new Date(v)).format('M/D/YY') : ''
                        }
                        includeIcon={false}
                        labelStyle={{
                          background: 'none',
                          color: '#444',
                          fontSize: 'inherit',
                          fontWeight: 'bold'
                        }}
                      />
                    </div>
                  </div>
                  <div style={masterStyles.twoColInputsFlex.right}>
                    <div style={{ marginTop: 5, position: 'relative' }}>
                      <InputLabel shrink>Status</InputLabel>
                      <DDISelectField
                        fullWidth
                        propertyName="statusType"
                        selectValue="dataId"
                        selectDescription="description"
                        placeholder="Status"
                        values={productStatusOptions.toJS()}
                        displayEmpty
                      />
                      {statusTypeErrorMessage ? (
                        <div
                          title={statusTypeErrorMessage}
                          style={{
                            bottom: 0,
                            left: -20,
                            position: 'absolute'
                          }}
                        >
                          {showErrorIcon()}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </DDICardWrapper>
              <DDICardWrapper
                title="Cost/Price Columns"
                contentStyle={{ minHeight: 350, padding: 0 }}
              >
                <div style={{ position: 'relative' }}>
                  <ContainedBackdrop
                    open={isPostingPriceOrCost}
                    style={{ width: '100%', height: '100%' }}
                  >
                    <CircularProgress size={50} thickness={10} />
                  </ContainedBackdrop>
                  {costPriceRowData && costPriceRowData.size ? (
                    <EditableGrid
                      gridWrapperStyle={{ maxWidth: '100%', width: '100%' }}
                      requiredCols={['dataId']}
                      getRowNodeId={getRowNodeId}
                      propertyName="prices"
                      form={this.props.form}
                      rowData={costPriceRowData.toJS()}
                      columnDefs={dynamicColumnDefs}
                      colParams={securedFields}
                      height={setGridHeight(costPriceRowData.toJS())}
                      isEditing={isEditing}
                      width="100%"
                      maxWidth="100%"
                      fullWidth
                      skipValidationOnUnmount
                      suppressRowClickSelection
                      showAddButtonOnlyIfEditing={false}
                      forceFocusOnUpdate
                    />
                  ) : null}
                </div>
              </DDICardWrapper>
              <RollupSchedule />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  costPriceRowData: form => getField(form, 'prices', emptyList, true), // gegetRowDataFromStore(form, 'prices'),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  isPostingPriceOrCost: form => form.get('isPostingPriceOrCost') || false,
  productStatusOptions: form =>
    getMeta(form, 'productStatusOptions', emptyList),
  statusTypeErrorMessage: form => getField(form, 'statusType.errorMessage', ''),
  templateKey: form => getValue(form, 'templateKey', ''),
  uomForDisplayId: form => getValue(form, 'uomForDisplayId', ''),
  uomForPriceId: form => getValue(form, 'uomForPriceId', ''),
  uomForPurchasingId: form => getValue(form, 'uomForPurchasingId', ''),
  securedFields: form => getIn(form, 'meta.securedFields') || List()
}

export default withContext(contextObj)(Main)
