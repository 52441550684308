import React from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { Button, Icon } from '@material-ui/core'
import { getIn, emptyList, is } from 'utils'

export const MoveProductButtonsMobile = ({
  availableLineItemsList,
  addProductsToGroup,
  addAllToGroup,
  selectedLineNumbersList,
  selectedItemGroup
}) => {
  const selectedLineNumbers = selectedLineNumbersList?.toJS
    ? selectedLineNumbersList.toJS()
    : []
  const availableLineItems = availableLineItemsList?.toJS
    ? availableLineItemsList.toJS()
    : []

  const handleAdd = () => {
    const params = {
      lineNumbers: selectedLineNumbers,
      selectedItemGroup
    }
    addProductsToGroup(params)
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}
    >
      <Button
        onClick={handleAdd}
        disabled={!selectedLineNumbers.length || !is.number(selectedItemGroup)}
        style={{ margin: 5 }}
        size="small"
        color="primary"
        startIcon={<Icon style={{ transform: 'rotate(160deg' }}>reply</Icon>}
        variant="contained"
      >
        Transfer to Group
      </Button>
      <Button
        disabled={!availableLineItems.length || !is.number(selectedItemGroup)}
        style={{ margin: 5 }}
        size="small"
        color="primary"
        onClick={addAllToGroup}
        startIcon={
          <Icon style={{ transform: 'rotate(160deg' }}>reply_all</Icon>
        }
        variant="contained"
      >
        Transfer All
      </Button>
    </div>
  )
}

/* fixed 9/24/2020 SVE */
export const contextObj = {
  availableLineItemsList: form =>
    getIn(form, 'fields.groups.availableLineItems.rowData') || emptyList,
  selectedLineNumbersList: form =>
    getIn(form, 'fields.groups.availableLineItems.selectedLineNumbers') ||
    emptyList,
  selectedItemGroup: form => {
    const selectedItemGroup = getIn(
      form,
      'fields.groups.itemGroups.selectedRowIndex'
    )

    if (is.number(selectedItemGroup)) {
      return selectedItemGroup
    }
    return null
  }
}

export default withContext(contextObj)(MoveProductButtonsMobile)
