import React, { Component } from 'react'
import {
  AppBar,
  Button,
  Icon,
  Typography,
  TextField,
  Tabs,
  Tab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover
} from '@material-ui/core'

import { Col, Row } from 'react-bootstrap'

const capsLockTooltipHTML = (
  <div>
    <h3 style={{ fontSize: 14, margin: '10px 0 5px 0' }}>Caps Lock Is On</h3>
    <p>
      Having caps lock on may cause you to enter your password incorrectly. You
      should press Caps Lock to turn it off before entering your PIN or
      password.
    </p>
  </div>
)

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

export default class PasswordProtected extends Component {
  constructor(...args) {
    super(...args)
    this.state = {
      tab: 'pin',
      open: true,
      tooltipOpen: false,
      pin: '',
      password: '',
      username: ''
    }
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyUp)
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp)
  }

  containerRef = React.createRef()

  handleTabChange = (e, tab) => {
    this.setState({ tab })
  }

  onFieldChange = field => event =>
    this.setState({ [field]: event.target.value })

  onOkClick = e => {
    this.props.onOkClick(this.state)
  }

  onKeyDown = event => {
    event.persist()
    const { pin, password, username } = this.state
    if (event.keyCode === 13) {
      const okDisabled = !pin && !(password && username)
      if (!okDisabled) {
        this.onOkClick()
      }
    }
  }

  onKeyUp = e => {
    if (e && e?.getModifierState && typeof e.getModifierState === 'function') {
      this.setState({
        tooltipOpen: e.getModifierState('CapsLock')
      })
    }
  }

  render() {
    const { tab, pin, password, username, tooltipOpen } = this.state
    console.log(this.container)
    const okDisabled = !pin && !(password && username)
    return (
      <div>
        {/* <Popover
          open={this.state.open}
          anchorEl={this.props.containerRef && this.props.containerRef.current}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={this.handleClose}
          // style={{ width: 360 }}
          PaperProps={{ style: { width: 360 } }}
        > */}
        <div style={{ height: 180, padding: 5 }}>
          <AppBar position="static">
            <Tabs
              id="auth-tabs"
              onChange={this.handleTabChange}
              value={tab}
              indicatorColor="#1abcf7"
              style={{ width: 300 }}
            >
              <Tab value="pin" label="PIN" />
              <Tab value="password" label="User Name / Password" />
            </Tabs>
            {tab === 'pin' && (
              <TabContainer>
                <div
                  style={{
                    marginTop: 15,
                    minHeight: 120
                    // width: 290
                  }}
                >
                  <Tooltip
                    title={capsLockTooltipHTML}
                    open={tooltipOpen}
                    placement="top"
                  >
                    <TextField
                      autoComplete="new-password"
                      autoFocus
                      label="PIN"
                      id="pin"
                      // disabled={requesting}
                      onChange={this.onFieldChange('pin')}
                      inputRef={c => (this.pinField = c)}
                      type="password"
                      value={this.state.pin}
                      fullWidth
                      onKeyDown={this.onKeyDown}
                      // inputProps={ignoreLp}
                    />
                  </Tooltip>
                </div>
              </TabContainer>
            )}
            {tab === 'password' && (
              <TabContainer>
                <Tooltip
                  title={capsLockTooltipHTML}
                  open={tooltipOpen}
                  placement="top"
                >
                  <div
                    style={{
                      marginTop: 15,
                      minHeight: 120
                      // width: 290
                    }}
                  >
                    <TextField
                      autoFocus
                      label="User Name"
                      id="username"
                      onChange={this.onFieldChange('username')}
                      // disabled={requesting}
                      inputRef={c => (this.usernameField = c)}
                      // value={username}
                      fullWidth
                      style={{ marginBottom: 10 }}
                      // inputProps={ignoreLp}
                    />
                    <TextField
                      autoComplete="user-password"
                      id="password"
                      label="Password"
                      onChange={this.onFieldChange('password')}
                      type="password"
                      // disabled={requesting}
                      // value={password}
                      // inputProps={ignoreLp}
                      onKeyDown={this.onKeyDown}
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </TabContainer>
            )}
          </AppBar>
        </div>
        <div
          style={{
            padding: 5,
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row'
          }}
        >
          <Button
            onClick={this.onOkClick}
            disabled={okDisabled}
            variant="contained"
            buttonRef={this.buttonRef}
            style={{ marginRight: 5 }}
          >
            OK
          </Button>
          <Button
            keyboardFocused
            onClick={this.props.onCancelClick}
            variant="contained"
          >
            Cancel
          </Button>
        </div>
        {/* </Popover> */}
      </div>
    )
  }
}
