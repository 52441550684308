import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, is } from 'utils'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce } from 'lodash'
import { removeModal } from 'modals/actions'
import {
  exitImportScreen,
  importProductData as importProductDataAction,
  analyzeProductImportData,
  clearMappings as clearMappingsAction
} from '../actions'

const marginRight = { marginRight: 5 }

const ActionButtons = ({
  actionButtonsDisabled,
  form,
  lineItemsGridApi,
  modalId
}) => {
  const dispatch = useDispatch()

  const analyze = debounce(e => {
    dispatch(
      analyzeProductImportData.try(form, {
        modalId
      })
    )
  }, 300)

  const importProductData = debounce(e => {
    dispatch(
      importProductDataAction.try(form, {
        modalId,
        lineItemsGridApi
      })
    )
  }, 300)

  const clearMappings = debounce(e => {
    dispatch(
      clearMappingsAction.try(form, {
        modalId
      })
    )
  }, 300)

  // const exit = e => dispatch(removeModal(form, modalId))
  const exitModal = debounce(e => {
    dispatch(
      exitImportScreen.try(form, {
        modalId
      })
    )
  }, 300)

  return (
    <div
      className="product-import-interface-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0 0 0'
      }}
    >
      <div>
        <Button
          onClick={clearMappings}
          variant="contained"
          disabled={actionButtonsDisabled}
        >
          Clear Mappings
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          onClick={analyze}
          variant="contained"
          style={marginRight}
          disabled={actionButtonsDisabled}
        >
          Analyze
        </Button>
        <Button
          onClick={importProductData}
          variant="contained"
          style={marginRight}
          disabled={actionButtonsDisabled}
        >
          Import
        </Button>
        <Button onClick={exitModal} variant="contained">
          Exit
        </Button>
      </div>
    </div>
  )
}

export default ActionButtons
