import createSearch from 'components/Search/createSearch'
import behaviors from './behaviors'
import TextInput from './TextInput'
import DropDownComponent from './SerialSearchDropdown'

const SerialNumberSearchMobile = createSearch({
  name: 'MobileSerialNumberSearch',
  behaviors,
  popoverStyle: { width: 500 },
  DropDownComponent,
  meta: {
    allowInstantSearch: true,
    allowNavigation: false,
    allowSearchAll: true,
    allowSpecialProducts: false,
    hasFilters: false,
    hideToggle: true,
    isSecure: false,
    minimumKeywordLength: 2,
    searchType: 'A',
    transactionType: 'S'
  },
  TextInput,
  mobile: true
})

export default SerialNumberSearchMobile
