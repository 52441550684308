import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import {
  Card,
  CardContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { show } from 'snackbar/actions'
import { withContext } from 'ddiForm'
import { removeModal } from 'modals/actions'
import { getIn, noop } from 'utils'
import { addPaymentSource} from '../actions'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    width: 400
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardMarginTop: {
    width: 400,
    marginTop: 20
  }
}))

export function AddPaymentProfile(props) {
  console.log(props?.data?.webApiKey)
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const [cayanKey, setCayanKey] = useState(
    props?.data?.webApiKey || 'JCX34FC3KHWFEMHQ'
  )
  const [name, setName] = useState(false)
  const [cardNumber, setCardNumber] = useState(false)
  const [month, setMonth] = useState(false)
  const [year, setYear] = useState(false)
  const [cvv, setCvv] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const { dispatch } = props
  useEffect(() => {

    //debugger
        // eslint-disable-next-line no-undef
    CayanCheckoutPlus.setWebApiKey(cayanKey)
  }, [cayanKey])

  const handleMonth = e => setMonth(!!e.target.value)
  const handleYear = e => setYear(!!e.target.value)
  const handleName = e => setName(!!e.target.value)
  const handleCard = e => setCardNumber(!!e.target.value)
  const setCVV = e => setCvv(!!e.target.value)
  const requiredFieldsSet =
    name && cardNumber && month && year && cvv && !saveDisabled
  const handleClose = () => {
    setOpen(false)
    props.dispatch(removeModal(props.data.form, props.id))
  }
  const successCallback = async ({ token }) => {
    debugger
    try {
      await dispatch(
        addPaymentSource.try(props.data.form, {
          vaultToken: token,
          branchId: props.data.branchId
        })
      )
      await handleClose()
      dispatch(
        show({
          message: {
            message: 'Card Added!',
            type: 'success'
          }
        })
      )
    } catch (ex) {
      debugger
          handleClose()
      console.log(ex)
      dispatch(
        show({
          message: {
            message: 'Failure: Card Not Added!',
            type: 'warning'
          }
        })
      )
    }
  }

  const failureCallback = (...rest) => {
    console.log(rest)
    debugger
    dispatch(
      show({
        message: {
          message: 'Failure: Card Not Added!',
          type: 'warning'
        }
      })
    )
    setTimeout(() => setSaveDisabled(false), 1000)
  }
  const onSubmit = () => {
    setSaveDisabled(true)
    // eslint-disable-next-line no-undef
    CayanCheckoutPlus.createPaymentToken({
      success: successCallback,
      error: failureCallback
    })
  }
console.log(cayanKey)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">Board Credit Card</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <Card className={classes.card} variant="outlined">
              <CardContent>
               
                <div>
                  <TextField
                    label="Card Number"
                    onChange={handleCard}
                    inputProps={{ 'data-cayan': 'cardnumber' }}
                    className={classes.formControl}
                  />
                </div>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Exp. Month</InputLabel>
                    <Select
                      // value={month}
                      onChange={handleMonth}
                      inputProps={{ 'data-cayan': 'expirationmonth' }}
                    >
                      <MenuItem value="01">January</MenuItem>
                      <MenuItem value="02">February</MenuItem>
                      <MenuItem value="03">March</MenuItem>
                      <MenuItem value="04">April</MenuItem>
                      <MenuItem value="05">May</MenuItem>
                      <MenuItem value="06">June</MenuItem>
                      <MenuItem value="07">July</MenuItem>
                      <MenuItem value="08">August</MenuItem>
                      <MenuItem value="09">September</MenuItem>
                      <MenuItem value="10">October</MenuItem>
                      <MenuItem value="11">November</MenuItem>
                      <MenuItem value="12">December</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <InputLabel>Exp. Year</InputLabel>
                    <Select
                      // value={year}
                      onChange={handleYear}
                      inputProps={{ 'data-cayan': 'expirationyear' }}
                    >
                      <MenuItem value="21">2021</MenuItem>
                      <MenuItem value="22">2022</MenuItem>
                      <MenuItem value="23">2023</MenuItem>
                      <MenuItem value="24">2024</MenuItem>
                      <MenuItem value="25">2025</MenuItem>
                      <MenuItem value="26">2026</MenuItem>
                      <MenuItem value="27">2027</MenuItem>
                      <MenuItem value="28">2028</MenuItem>
                      <MenuItem value="29">2029</MenuItem>
                      <MenuItem value="30">2030</MenuItem>
                      <MenuItem value="31">2031</MenuItem>
                      <MenuItem value="32">2032</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    label="CVV/CVS"
                    onChange={setCVV}
                    inputProps={{ 'data-cayan': 'cvv' }}
                    className={classes.formControl}
                  />
                </div>
                <div>
                  <TextField
                    label="Card Holder Name"
                    onChange={handleName}
                    inputProps={{ 'data-cayan': 'cardholder' }}
                    className={classes.formControl}
                  />
                </div>
                <div>
                  <TextField
                    label="Address"
                    // onChange={setAddress}
                    inputProps={{ 'data-cayan': 'streetaddress' }}
                    className={classes.formControl}
                  />
                </div>
                <div>
                  <TextField
                    label="Zip Code"
                    // onChange={setZip}
                    inputProps={{ 'data-cayan': 'zipcode' }}
                    className={classes.formControl}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={!requiredFieldsSet}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withContext({
  addCreditCard: form => { console.log(form);
     return getIn(form, 'actions.addCreditCard') || noop
  }
})(AddPaymentProfile)
