import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DDITextField, DDIIndexSearch, DDIToggle } from 'ddiForm/wrapped'
import { Paper, FormControlLabel } from '@material-ui/core'
import { required, maxLength } from 'ddiForm/validations'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { getIn } from 'utils'

import '../styles/shipto-modal.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const canChangeSave =
    getIn(state, `ddiForm.${form}.values.manualShipTo.canChangeSave`) || false
  const showTax =
    getIn(state, `ddiForm.${form}.values.manualShipTo.showTax`) || false
  const save =
    getIn(state, `ddiForm.${form}.fields.manualShipTo.save.value`) || false

  return {
    canChangeSave,
    showTax,
    save
  }
}

export class FastShipToModal extends Component {
  componentDidMount() {
    if (this.nameField) {
      this.nameField.focus()
    }
  }

  render() {
    const { canChangeSave, save, showTax } = this.props

    return (
      <div className="shipto-modal-outer-wrapper">
        <Paper
          style={{
            padding: '25px'
          }}
          elevation={4}
        >
          <h2 className="heading">Ship To Information</h2>
          <div className="division">
            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="manualShipTo.description"
                  label="Name"
                  inputRef={c => (this.nameField = c)}
                  validate={[required, maxLength(30)]}
                  InputProps={{
                    inputProps: {
                      maxLength: 30
                    }
                  }}
                  fullWidth
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="manualShipTo.address1"
                  label="Address"
                  fullWidth
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="manualShipTo.address2"
                  label="Line 2"
                  fullWidth
                />
              </div>

              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="manualShipTo.address3"
                  label="Line 3"
                  fullWidth
                />
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="manualShipTo.city"
                  label="City"
                  fullWidth
                  preventAutoDisable
                  validate={maxLength(30)}
                />
              </div>

              <div className="two-col-flex-right two-col-flex-multi">
                <div className="two-col-flex-wrapper">
                  <div className="two-col-flex-left">
                    <DDITextField
                      propertyName="manualShipTo.state"
                      label="State"
                      fullWidth
                      preventAutoDisable
                    />
                  </div>
                  <div className="two-col-flex-right">
                    <DDITextField
                      propertyName="manualShipTo.postalCode"
                      label="Zip Code"
                      fullWidth
                      preventAutoDisable
                      mask="zipCode"
                      inputProps={{
                        guide: false
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="two-col-flex-wrapper">
              <div className="two-col-flex-left">
                <DDITextField
                  propertyName="manualShipTo.country"
                  label="Country"
                  fullWidth
                />
              </div>
              <div className="two-col-flex-right">
                <DDITextField
                  propertyName="manualShipTo.attention"
                  label="Attention"
                  fullWidth
                />
              </div>
            </div>

            <div className="utility-block-wrapper">
              {save && showTax && (
                <div className="search-row-wrapper">
                  <div className="search-row-label">Tax Code:</div>
                  <div className="search-row-input">
                    <DDIIndexSearch
                      propertyName="manualShipTo.taxId"
                      metaKey="Tax Code"
                      preventAutoDisable
                    />
                  </div>
                </div>
              )}

              {canChangeSave && (
                <div className="flex-wrapper">
                  <AssociatedFieldsWrapper
                    style={{ float: 'none', clear: 'none' }}
                  >
                    <FormControlLabel
                      control={
                        <DDIToggle
                          propertyName="manualShipTo.save"
                          preventAutoDisable
                        />
                      }
                      label="Save Manual Ship To Info"
                      labelPlacement="start"
                      style={{
                        margin: '5px 0px',
                        fontSize: '1rem',
                        fontWeight: 400
                      }}
                    />
                  </AssociatedFieldsWrapper>
                </div>
              )}
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(FastShipToModal)
