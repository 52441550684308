import React from 'react'
import { withContext } from 'ddiForm'
import { getValue } from 'utils'
import InvoiceSummary from './components/InvoiceSummary'
import Payments from './components/Payments'

const PaymentMethods = ({
  form,
  dispatch,
  height,
  isSuspended = false,
  canAddPayments,
  canPayByChecks
}) => (
  <div style={{ display: 'flex' }}>
    <InvoiceSummary form={form} dispatch={dispatch} height={height} />
    {canAddPayments ? (
      <Payments form={form} dispatch={dispatch} height={height} />
    ) : null}
  </div>
)

/* should be OK as-is SVE 9/25/2020 */
export default withContext({
  canAddPayments: form => getValue(form, 'canAddPayments', false)
})(PaymentMethods)
