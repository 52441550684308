import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { addBlankRow } from 'ddiForm/actions'
import {
  getIn,
  getValue,
  emptyList,
  is,
  formatDollarFields,
  formatNumber
} from 'utils'

import { Icon } from '@material-ui/core'
import TextFieldEditorCell from './GroupNameEditorCell'
import DeleteItemGroupCell from './DeleteItemGroupCell'
import SelectFieldEditorCell from './SelectFieldEditorCell'
import DateEditorCell from './DateEditor'
import { getGridHeight, addMinWidth } from './mobile/utils'

const frameworkComponents = {
  textFieldEditor: TextFieldEditorCell,
  selectFieldEditor: SelectFieldEditorCell,
  dateEditor: DateEditorCell
}

const MobileSelectionHandle = props => (
  <div
    style={{
      width: '100%',
      height: '100%',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Icon style={{ color: '#517b9c' }}>select_all</Icon>
  </div>
)

const getColumnDefs = memoize(
  ({
    hasRecord,
    isEditing,
    focusedCell,
    form,
    onCellChange,
    canAddGroups,
    dispatch,
    itemGroupOptions,
    isMobile,
    canSeeCost
  }) => {
    const editorParams = {
      disabled: false,
      focusedCell,
      form,
      propertyName: 'groups.itemGroups',
      onChange: onCellChange,
      hasRecord,
      isEditing,
      dispatch,
      isMobile
    }

    const textEditor = {
      cellEditor: 'textFieldEditor',
      cellEditorParams: editorParams
    }

    let columnDefs = [
      {
        // ...textEditor,
        cellEditor:
          itemGroupOptions &&
          is.array(itemGroupOptions) &&
          itemGroupOptions.length
            ? 'selectFieldEditor'
            : 'textFieldEditor',
        cellEditorParams: params => {
          const values =
            itemGroupOptions &&
            is.array(itemGroupOptions) &&
            itemGroupOptions.length
              ? itemGroupOptions.map(x => x.dataId)
              : []
          return {
            ...editorParams,
            values
          }
        },
        field: 'dataId',
        colId: 'dataId',
        headerName: 'Name',
        suppressMenu: true,
        editable: true,
        tabInto: true
      },
      {
        ...textEditor,
        field: 'pricePer',
        colId: 'pricePer',
        cellStyle: { 'text-align': 'right' },
        headerClass: 'align-right',
        headerName: 'Price Per',
        suppressMenu: true,
        editable: true,
        tabInto: false,
        valueFormatter: params => formatNumber(params.value, '0,0.0000')
      },
      {
        ...textEditor,
        cellEditorParams: {
          ...textEditor.cellEditorParams,
          textAlign: 'right'
        },
        cellStyle: { 'text-align': 'right' },
        field: 'quantity',
        colId: 'quantity',
        headerName: 'Qty',
        suppressMenu: true,
        editable: true,
        tabInto: true
      },
      {
        ...textEditor,
        cellEditorParams: {
          ...textEditor.cellEditorParams,
          textAlign: 'right'
        },
        cellStyle: { 'text-align': 'right' },
        field: 'priceExtension',
        colId: 'priceExtension',
        headerClass: 'align-right',
        headerName: 'Total Price',
        suppressMenu: true,
        editable: true,
        valueFormatter: formatDollarFields
      },
      {
        // ...textEditor,
        headerClass: 'align-right',
        cellStyle: { 'text-align': 'right' },
        field: 'costExtension',
        headerName: 'Total Cost',
        suppressMenu: true,
        valueFormatter: formatDollarFields,
        tabInto: false
      },
      {
        // ...textEditor,
        headerClass: 'align-right',
        cellStyle: { 'text-align': 'right' },
        field: 'promisedDate',
        headerName: 'Promised Date',
        editable: true,
        suppressMenu: true,
        cellEditor: 'dateEditor',
        colId: 'promisedDate',
        tabInto: false
      },
      {
        headerName: '',
        cellRendererFramework: DeleteItemGroupCell,
        cellRendererParams: {
          form,
          dispatch,
          type: 'itemGroups'
        },
        // keep field for remove -LL 12/4/19
        field: 'lineNumber',
        colId: 'deleteCell',
        maxWidth: 50,
        tabInto: false
      }
    ]

    if (isMobile) {
      columnDefs = [
        {
          field: '',
          headerName: '',
          cellRendererFramework: MobileSelectionHandle,
          maxWidth: 50
        },
        ...columnDefs
      ]
    }

    if (!canSeeCost) {
      columnDefs = columnDefs.reduce((acc, next) => {
        if (next.field !== 'costExtension') {
          acc = acc.concat(next)
        }

        return acc
      }, [])
    }

    return isMobile ? columnDefs.map(addMinWidth) : columnDefs
  }
)

const getRowNodeId = data => {
  const { dataId, lineNumber } = data

  return `${lineNumber} - ${dataId}`
}

export const ItemGroups = ({
  form,
  canAddGroups,
  canSeeCost,
  dispatch,
  itemGroupOptionsMap,
  itemGroupsList,
  isMobile = false
}) => {
  const itemGroups = itemGroupsList?.toJS ? itemGroupsList.toJS() : []
  const itemGroupOptions = itemGroupOptionsMap?.toJS
    ? itemGroupOptionsMap.toJS()
    : {}
  const gridApi = useRef(null)

  const emptyRow = {
    rowId: 'blankrow',
    lineNumber: '1',
    dataId: null,
    pricePer: 0,
    quantity: 0,
    priceExtension: 0,
    costExtension: 0,
    itemGroupId: null,
    itemGroupDescription: '',
    promisedDate: null
  }

  useEffect(() => {
    if (itemGroups && itemGroups.length === 1 && gridApi && gridApi.current) {
      gridApi.current.sizeColumnsToFit()
      gridApi.current.setHeaderHeight(35)
    }
  }, [itemGroupsList])

  const onGridReady = params => {
    if (params && params.api) {
      gridApi.current = params.api
      // setTimeout(() => gridApi.setHeaderHeight(35), 0)
    }
  }

  const tabToNextCell = params => {
    const { backwards } = params
    const { nextCellPosition, previousCellPosition } = params

    // change selected row to next position
    if (
      nextCellPosition &&
      nextCellPosition.rowIndex !== previousCellPosition.rowIndex
    ) {
      const node = gridApi.current.getDisplayedRowAtIndex(
        nextCellPosition.rowIndex
      )
      if (node && !node.isSelected()) {
        node.setSelected(true)
      }
    }

    if (
      nextCellPosition &&
      nextCellPosition.column &&
      nextCellPosition.column.colDef &&
      nextCellPosition.column.colDef.editable
    ) {
      const idx = nextCellPosition.rowIndex
      const key = nextCellPosition.column.colId

      if (gridApi && gridApi.current) {
        setTimeout(() => {
          gridApi.current.ensureColumnVisible(key)
          gridApi.current.setFocusedCell(idx, key)
          gridApi.current.startEditingCell({
            rowIndex: idx,
            colKey: key
          })
        }, 0)
      }
    }

    if (
      (!nextCellPosition && !backwards) ||
      (nextCellPosition && !nextCellPosition.column)
    ) {
      dispatch(addBlankRow(form, { propertyName: 'groups.itemGroups' }))
    }

    return nextCellPosition
  }

  const onCellValueChanged = params => {
    const { newValue, oldValue, colDef, data } = params

    // for now, only used for changing pre determined item groups

    if (newValue !== oldValue) {
      dispatch({
        meta: { form },
        type: 'ITEM_GROUPS_CELL_CHANGED',
        payload: {
          ...params,
          allowDuplicates: false,
          field: colDef.colId,
          propertyName: 'groups.itemGroups',
          data,
          gridApi: gridApi.current,
          value: newValue,
          rowIdentifier: 'dataId'
        }
      })
    }
  }
  const defs = getColumnDefs({
    hasRecord: true,
    isEditing: true,
    focusedCell: 'dataId',
    form,
    onCellChange: 'test',
    canAddGroups,
    dispatch,
    itemGroupOptions,
    isMobile,
    canSeeCost
  })
  return (
    <div>
      <ManualGrid
        title="Item Groups"
        addButtonText="Add Item Group"
        columnDefs={defs}
        immutableData
        emptyRow={emptyRow}
        focusCell="dataId"
        form={form}
        frameworkComponents={frameworkComponents}
        getRowNodeId={getRowNodeId}
        // displayEmptyRowWhileEmpty
        rowSelection="single"
        showAddButtonIfNoRecordOrEditing
        suppressColumnVirtualisation
        suppressMovableColumns
        skipValidationOnUnmount
        suppressRowClickSelection={false}
        singleClickEdit
        propertyName="groups.itemGroups"
        height={isMobile ? getGridHeight(itemGroups) + 133 : 350}
        tabToNextCell={tabToNextCell}
        onGridReady={onGridReady}
        onCellValueChanged={onCellValueChanged}
      />
    </div>
  )
}

ItemGroups.propTypes = {
  form: PropTypes.string.isRequired,
  canAddGroups: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  itemGroupOptions: PropTypes.array.isRequired,
  itemGroups: PropTypes.array.isRequired
}

// export const contextObjOld = {
//   canAddGroups: form =>
//     form.values && is.bool(form.values.canAddGroups)
//       ? form.values.canAddGroups
//       : false,
//   itemGroupOptions: form =>
//     form.values && form.values.itemGroupOptions
//       ? form.values.itemGroupOptions
//       : null,
//   itemGroups: form =>
//     form.fields &&
//     form.fields.groups &&
//     form.fields.groups.itemGroups &&
//     form.fields.groups.itemGroups.rowData
//       ? form.fields.groups.itemGroups.rowData
//       : []
// }

/* fixed 9/23/2020 SVE */
export const contextObj = {
  canAddGroups: form => getValue(form, 'canAddGroups', false),
  canSeeCost: form => getIn(form, 'values.groups.canSeeCost') || false,
  itemGroupOptionsMap: form => getValue(form, 'itemGroupOptions', emptyList),
  itemGroupsList: form =>
    getIn(form, 'fields.groups.itemGroups.rowData') || emptyList
}

export default withContext(contextObj)(ItemGroups)
