import React, { useState, useEffect } from 'react'
import { DDIDatePicker } from 'ddiForm/wrapped'

export const PromiseDateModal = ({
  data: {
    form,
    promisedDate = null
  },
}) => {
  const [textRef, setTextRef] = useState(null)

  useEffect(() => {
    if (textRef) {
      textRef.focus()
    }
  }, [textRef])

  const getInputRef = c => {

    setTextRef(c)
  }

  return (
    <DDIDatePicker
      propertyName="multiSelectPromisedDate"
      initialValue={promisedDate}
      views={['date']}
      inputStyle={{ marginTop: 5 }}
      inputRef={getInputRef}
    />
  )
}

export default PromiseDateModal