import { deleteIn, getIn, is, setIn, fromJS, toCamelCase } from 'utils'
import * as CONSTANTS from './constants'
import { mappedColumnDefaults } from './utils'

export default {
  [CONSTANTS.SET_ROW_DATA]: (
    state,
    {
      payload: {
        rowData = [],
        clearData = false,
        includesMeta = false,
        productLineId,
        productLineDescription
      }
    }
  ) => {
    let result = state
    if (clearData) {
      result = deleteIn(result, 'fields.productImport')
      result = deleteIn(result, 'values.productImport')
    }

    if (Array.isArray(rowData)) {
      // result = setIn(result, 'values.productImport.rowData', fromJS(rowData))
      /* fromJS and toJS jacks up the rowData order */
      result = setIn(result, 'values.productImport.rowData', fromJS(rowData))
      if (rowData.length && Object?.keys(rowData?.[0])?.length) {
        result = setIn(
          result,
          'values.productImport.sortOrder',
          fromJS(Object.keys(rowData[0]))
        )
      }

      let productImportFields = getIn(result, 'fields.productImport')
      if (!rowData.length && productImportFields && productImportFields?.toJS) {
        productImportFields = productImportFields?.toJS
          ? productImportFields.toJS()
          : {}
        productImportFields = Object.keys(productImportFields)
        // debugger
        result = setIn(result, 'values.productImport.sortOrder', fromJS([]))

        for (const prop in productImportFields) {
          // console.log(prop)
          const field = productImportFields[prop]
          result = setIn(result, `fields.productImport.${field}.value`, '')
          result = setIn(result, `values.productImport.${field}`, '')
        }
      }
    }

    if (includesMeta && productLineId && productLineDescription) {
      result = setIn(
        result,
        'fields.productImport.productLineId.value',
        productLineId
      )
      result = setIn(
        result,
        'values.productImport.productLineId',
        productLineId
      )
      result = setIn(
        result,
        'fields.productImport.productLineId.description',
        productLineDescription
      )
    }

    return result
  },
  [CONSTANTS.ON_CELL_VALUE_CHANGED]: (
    state,
    { payload: { field, value, rowIndex, hasHeaders } }
  ) => {
    let result = state
    let rowData = getIn(result, 'values.productImport.rowData')
    const targetIndex =
      hasHeaders && is.number(rowIndex) ? rowIndex + 1 : rowIndex
    rowData = rowData.update(targetIndex, data => data.set(field, value))
    result = setIn(result, 'values.productImport.rowData', rowData)
    return result
  },
  [CONSTANTS.OPEN_PRODUCT_IMPORT_EDITOR.SUCCESS]: (state, { payload }) => {
    let result = state

    for (const prop in payload) {
      result = setIn(
        result,
        `meta.productImport.${prop}`,
        fromJS(payload[prop])
      )
    }

    return result
  },
  [CONSTANTS.ON_PRODUCT_IMPORT_PROPERTY_CHANGE.SUCCESS]: (
    state,
    { payload }
  ) => {
    let result = state

    for (const prop in payload) {
      result = setIn(
        result,
        `values.productImport.${prop}`,
        fromJS(payload[prop])
      )

      if (getIn(result, `fields.productImport.${prop}`)) {
        result = setIn(
          result,
          `fields.productImport.${prop}.value`,
          fromJS(payload[prop])
        )
      }

      if (prop?.match(/Description/)) {
        const fieldDescription = prop.replace('Description', 'Id')
        if (getIn(result, `fields.productImport.${fieldDescription}`)) {
          result = setIn(
            result,
            `fields.productImport.${fieldDescription}.description`,
            fromJS(payload[prop])
          )
        }
      }

      if (
        prop === 'fieldMappings' &&
        payload[prop] &&
        Array.isArray(payload[prop]) &&
        payload?.importDataChanged &&
        !payload?.isCellValueChange
      ) {
        /* if the user has uploaded a new spreadsheet, handle the mappings */
        for (let i = 0; i < payload[prop].length; i++) {
          const mappedField = payload[prop][i]
          const { description, mappedColumnIndex } = mappedField
          const propertyName = toCamelCase(description)

          if (getIn(result, `fields.productImport.${propertyName}`)) {
            const value =
              typeof mappedColumnIndex === 'string' ? mappedColumnIndex : ''
            result = setIn(
              result,
              `fields.productImport.${propertyName}.value`,
              value
            )
            result = setIn(
              result,
              `values.productImport.${propertyName}`,
              value
            )
          }
        }
      }
    }

    return result
  },
  [CONSTANTS.CLEAR_MAPPINGS.SUCCESS]: (
    state,
    { payload: { columnList, fieldMappings } }
  ) => {
    let result = state

    result = setIn(
      result,
      'values.productImport.columnList',
      fromJS(columnList)
    )

    result = setIn(
      result,
      'values.productImport.fieldMappings',
      fromJS(fieldMappings)
    )

    for (let i = 0; i < mappedColumnDefaults.length; i++) {
      const { propertyName } = mappedColumnDefaults[i]
      if (propertyName) {
        result = setIn(result, `fields.productImport.${propertyName}.value`, '')
        result = setIn(result, `values.productImport.${propertyName}`, '')
      }
    }

    return result
  },
  [CONSTANTS.EXIT_IMPORT_SCREEN.SUCCESS]: (state, action) => {
    let result = state
    result = deleteIn(result, 'fields.productImport')
    result = deleteIn(result, 'values.productImport')
    return result
  },
  [CONSTANTS.IMPORT_PRODUCT_DATA.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'values.productImport.isImportingProducts', true)
    return result
  },
  [CONSTANTS.IMPORT_PRODUCT_DATA.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'values.productImport.isImportingProducts', false)
    return result
  },
  [CONSTANTS.IMPORT_PRODUCT_DATA.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'values.productImport.isImportingProducts', false)

    if (payload?.success) {
      result = deleteIn(result, 'fields.productImport')
      result = deleteIn(result, 'values.productImport')
      result = setIn(result, 'isEditing', true)
    }

    return result
  },
  [CONSTANTS.DELETE_TEMPLATE.SUCCESS]: (state, { payload: { templateId } }) => {
    let result = state

    result = setIn(result, 'fields.productImport.templateId.value', '')
    result = setIn(result, 'values.productImport.templateId', '')

    let templates = getIn(result, 'meta.productImport.templates') || fromJS([])
    templates = templates?.toJS ? templates.toJS() : []

    result = setIn(
      result,
      'meta.productImport.templates',
      fromJS(templates.filter(x => x?.dataId !== templateId))
    )

    return result
  }
}
