/* eslint react/sort-comp: 0, react/no-unused-state: 0, no-loop-func: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  Input,
  InputAdornment,
  Icon,
  IconButton,
  Paper
} from '@material-ui/core'

import useStateWithCallback from 'hooks/useStateWithCallback'
import usePrevious from 'hooks/usePrevious'
import { plainDeepEqual } from 'utils'
import { setRowData, onProductImportPropertyChange } from '../actions'
import { createImportPostData } from '../utils'

let inputEl

export const textToRowData = (str, delimiter = ',') => {
  const lines = str.split('\n')

  return (
    lines?.reduce((acc, next) => {
      const data = next.split(delimiter)
      if (data) {
        acc = acc.concat({ ...data })
      }

      return acc
    }, []) || null
  )
}

// function fixData(data) {
//   let o = ''
//   const w = 10240
//   let l
//   for (l = 0; l < data.byteLength / w; ++l)
//     o += String.fromCharCode.apply(
//       null,
//       new Uint8Array(data.slice(l * w, l * w + w))
//     )
//   o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))
//   return o
// }

// const useScript = url => {
//   useEffect(() => {
//     const script = document.createElement('script')

//     if (url && url?.match(/xlsx\.full\.min\.js/)) {
//       /* let's be super safe about this */
//       script.src = url
//       script.async = true
//       document.body.appendChild(script)
//     }

//     return () => {
//       document.body.removeChild(script)
//     }
//   }, [url])
// }

const FileInput = ({ allowedFileTypes = ['csv', 'xlsx', 'txt'], form }) => {
  const dispatch = useDispatch()
  // useScript('//cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.mini.min.js')

  const [state, setState] = useStateWithCallback({
    dropZoneBackground: '#fff',
    lineItemsData: [],
    fileName: '',
    extension: '',
    errorMessage: ''
  })

  const prevLineItemsData = usePrevious(state.lineItemsData)

  const handleLineItemsDataPropertyChange = lineItems => {
    const importData = createImportPostData(lineItems)
    dispatch(
      onProductImportPropertyChange.try(form, {
        properties: {
          importData: Buffer.from(importData).toString('base64')
        }
      })
    )
  }

  const onInputPaste = e => {
    if (e?.target?.id === 'product-import-input') {
      const pastedData = e.clipboardData
        .getData('text')
        .toString()
        .replace(/(\r\n|\n|\r)/gm, '\n')

      const delimiter = pastedData?.match(/\t/) ? '\t' : ','
      const lineItemsData = textToRowData(pastedData, delimiter)

      if (lineItemsData && Array.isArray(lineItemsData)) {
        setState(
          {
            ...state,
            fileName: '[FROM CLIPBOARD]',
            lineItemsData,
            extension: '',
            errorMessage: ''
          },
          () => handleLineItemsDataPropertyChange(lineItemsData)
        )
      } else {
        setState(
          {
            ...state,
            lineItemsData: [],
            extension: '',
            errorMessage: 'Something went wrong'
          },
          () => handleLineItemsDataPropertyChange([])
        )
      }
    }
  }

  useEffect(() => {
    window.addEventListener('paste', onInputPaste)

    return () => {
      window.removeEventListener('paste', onInputPaste)
    }
  }, [])

  useEffect(() => {
    if (
      Array.isArray(state.lineItemsData) &&
      Array.isArray(prevLineItemsData) &&
      !plainDeepEqual(state.lineItemsData, prevLineItemsData)
    ) {
      dispatch(setRowData(form, { rowData: state.lineItemsData }))
    }
  }, [state.lineItemsData, prevLineItemsData])

  const openFileBrowser = e => {
    inputEl.click()
  }

  const handleFileUploadProcess = files => {
    for (let i = 0; i < 1; i++) {
      const file = files[i]
      const fileName = file.name
      const extension = file.name
        .split('.')
        .pop()
        .toLowerCase()

      if (!allowedFileTypes.includes(extension)) {
        setState(
          {
            ...state,
            dropZoneBackground: '#fff',
            lineItemsData: [],
            fileName: '',
            extension: '',
            errorMessage: 'Incorrect File Type'
          },
          () => handleLineItemsDataPropertyChange([])
        )

        break
      }

      const reader = new FileReader()

      if (extension === 'csv' || extension === 'txt') {
        reader.onload = e => {
          const data = reader.result.replace(/(\r\n|\n|\r)/gm, '\n').trim()
          const delimiter = data?.match(/\t/) ? '\t' : ','
          const lineItemsData = textToRowData(data, delimiter)
          // console.log('lineItemsData', lineItemsData)
          // debugger

          if (lineItemsData.length) {
            setState(
              {
                ...state,
                dropZoneBackground: '#fff',
                lineItemsData,
                fileName,
                extension,
                errorMessage: ''
              },
              () => handleLineItemsDataPropertyChange(lineItemsData)
            )
          } else {
            setState(
              {
                ...state,
                dropZoneBackground: '#fff',
                lineItemsData: [],
                fileName: '',
                extension: '',
                errorMessage: 'Please upload a valid CSV, XLSX, or TXT file'
              },
              () => handleLineItemsDataPropertyChange([])
            )
          }
        }

        reader.readAsText(file)
      } else if (extension === 'xlsx' || extension === 'xls') {
        // const rABS =
        //   typeof FileReader !== 'undefined' &&
        //   typeof FileReader?.prototype !== 'undefined' &&
        //   typeof FileReader?.prototype?.readAsBinaryString !== 'undefined'
        // reader.readAsBinaryString(file)
        reader.onload = e => {
          if (!window?.XLSX) {
            setState(
              {
                ...state,
                dropZoneBackground: '#fff',
                lineItemsData: [],
                fileName: '',
                extension: '',
                errorMessage: 'Something went wrong'
              },
              () => handleLineItemsDataPropertyChange([])
            )

            return
          }

          const data = e.target.result
          const workbook = window.XLSX.read(data, {
            type: 'binary'
          })

          // const lineItemsData =
          //   workbook?.Sheets && workbook?.SheetNames?.[0]
          //     ? window.XLSX.utils.sheet_to_row_object_array(
          //         workbook.Sheets[workbook.SheetNames[0]]
          //       )
          //     : []
          const csvData =
            workbook?.Sheets && workbook?.SheetNames?.[0]
              ? window.XLSX.utils.sheet_to_csv(
                  workbook.Sheets[workbook.SheetNames[0]]
                )
              : ''
          const lineItemsData = textToRowData(csvData, ',')

          if (lineItemsData.length) {
            setState(
              {
                ...state,
                dropZoneBackground: '#fff',
                lineItemsData,
                fileName,
                extension,
                errorMessage: ''
              },
              () => handleLineItemsDataPropertyChange(lineItemsData)
            )
          } else {
            setState(
              {
                ...state,
                dropZoneBackground: '#fff',
                lineItemsData: [],
                fileName: '',
                extension: '',
                errorMessage: 'Please upload a valid CSV, XLSX, or TXT file'
              },
              () => handleLineItemsDataPropertyChange([])
            )
          }
        }

        reader.readAsBinaryString(file)

        // if (rABS) {
        //   reader.readAsBinaryString(file)
        // } else {
        //   reader.readAsArrayBuffer(file)
        // }
      }
    }
  }

  const onSelectFileUpload = e => {
    const { files } = e.target
    handleFileUploadProcess(files)
  }

  const handleDrop = e => {
    e.preventDefault()
    const files = []

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile()
          const { type } = e.dataTransfer.items[i]
          files.push(file)
        }
      }
    } else {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        files.push(e.dataTransfer.files[i])
      }
    }

    /* only allow the user to drag and drop one file at a time */
    if (files.length && files.length === 1) {
      handleFileUploadProcess(files)
    }
  }

  const onDragOver = e => {
    e.stopPropagation()
    e.preventDefault()

    setState({
      ...state,
      dropZoneBackground: '#f9f9f9'
    })
  }

  const onDragEnter = e => {
    e.stopPropagation()

    setState({
      ...state,
      dropZoneBackground: '#f9f9f9'
    })
  }

  const onDragLeave = e => {
    e.stopPropagation()

    setState({
      ...state,
      dropZoneBackground: '#fff'
    })
  }

  const onOpenFileBrowserHandler = e => (e.target.value = null)

  const onInputChange = e => {
    // console.log('onInputChange', e)
    if (e && e.persist) {
      e.persist()
    }

    setState(
      {
        ...state,
        lineItemsData: [],
        fileName: '',
        errorMessage: 'Please upload a valid CSV, XLSX, or TXT file'
      },
      () => handleLineItemsDataPropertyChange([])
    )
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <Paper
        onDrop={handleDrop}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        style={{
          background: state?.dropZoneBackground || '#fff',
          border:
            state?.dropZoneBackground === '#f9f9f9'
              ? '1px dotted #777'
              : 'none',
          padding: 10,
          margin: '0 15px 0 -10px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label className="label">File Name:</label>
          <div className="input-wrapper" style={{ marginRight: 10 }}>
            <Input
              startAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={openFileBrowser} tabIndex={-1}>
                    <Icon>folder</Icon>
                  </IconButton>
                </InputAdornment>
              }
              ref={el => (inputEl = el)}
              placeholder="Choose any CSV, XLSX or TXT file"
              onChange={onInputChange}
              value={state.fileName}
              inputProps={{
                id: 'product-import-input',
                style: { width: '100%' }
              }}
              fullWidth
              style={{ width: '100%' }}
            />
            <div style={{ display: 'none' }}>
              <input
                type="file"
                ref={el => (inputEl = el)}
                onChange={onSelectFileUpload}
                onClick={onOpenFileBrowserHandler}
              />
            </div>
          </div>
        </div>
        <p
          style={{
            color: '#777',
            margin: '10px 0 0 0',
            fontSize: 10,
            fontStyle: 'italic',
            textAlign: 'center'
          }}
        >
          Drag and drop or upload your valid CSV, XLSX, or TXT file here
        </p>
        {state?.errorMessage ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <div
              className="alert alert-warning"
              style={{
                backgroundColor: '#fcf8e3',
                borderColor: '#faebcc',
                padding: 5,
                margin: '5px 0 0 0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <Icon size="small" style={{ marginRight: 5 }}>
                warning
              </Icon>
              <span style={{ fontSize: 11 }}>{state.errorMessage}</span>
            </div>
          </div>
        ) : null}
      </Paper>
    </div>
  )
}

export default FileInput
