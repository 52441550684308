/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  DDIIndexSearch,
  DDINumberSpinner,
  DDISelectField,
  DDITextField
} from 'ddiForm/wrapped'
import { Field, Label, withContext } from 'ddiForm'
import { maxValue, required } from 'ddiForm/validations'
import masterStyles from 'theme/masterStyles'

import GreyRow from 'pages/CustomerMaster/components/GreyRow'
import { getIn, getValue, layoutFlex, getMeta, emptyList } from 'utils'

const styles = {
  marginBottom: { marginBottom: '0.5rem' },
  searchRow: {
    wrapper: { ...masterStyles.searchRow.wrapper },
    label: { ...masterStyles.searchRow.label, width: 250 },
    input: { ...masterStyles.searchRow.input },
    description: { ...masterStyles.searchRow.description }
  }
}

const layoutStyle = {
  display: 'flex',
  flex: '0.1 1 60rem',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '100%',
  margin: '0 auto',
  height: 400
}

const Credit = ({ canChangeCreditLimit, taxOptionsList }) => {
  const taxOptions =
    taxOptionsList && taxOptionsList.toJS ? taxOptionsList.toJS() : []
  return (
    <div style={layoutStyle}>
      <div
        style={{
          flex: '1 1',
          padding: '0 0.5rem'
        }}
      >
        <DDICardWrapper title="Credit" contentStyle={{ padding: '0.5rem' }}>
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <div
                className="cm-search-row-label"
                style={styles.searchRow.label}
              >
                Finance Charge %:
              </div>
              <div style={styles.searchRow.input}>
                <DDITextField
                  propertyName="financeChargePercent"
                  id="financeChargePercent"
                  mask="numeric"
                  style={{ maxWidth: '100%', width: '100%' }}
                  inputProps={{
                    decimalLimit: 2,
                    fixedDecimalScale: true,
                    range: [0, 999999999.99]
                  }}
                />
              </div>
            </div>
          </GreyRow>
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <div
                className="cm-search-row-label"
                style={styles.searchRow.label}
              >
                Credit Limit:
              </div>
              <div style={styles.searchRow.input}>
                <DDITextField
                  disabled={!canChangeCreditLimit}
                  propertyName="creditLimit"
                  id="creditLimit"
                  mask="currency"
                  inputProps={{
                    integerLimit: 8,
                    style: { textAlign: 'right' },
                    range: [0, 99999999]
                  }}
                  validate={[maxValue(99999999)]}
                  style={{
                    maxWidth: '100%',
                    width: '100%',
                    textAlign: 'right'
                  }}
                />
              </div>
            </div>
          </GreyRow>
        </DDICardWrapper>
        <DDICardWrapper
          title="Tax Options"
          contentStyle={{ padding: '0.5rem' }}
        >
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <div
                className="cm-search-row-label"
                style={styles.searchRow.label}
              >
                Tax:
              </div>
              <div style={styles.searchRow.input}>
                <DDIIndexSearch
                  propertyName="taxCode"
                  meta={{
                    allowInstantSearch: true,
                    allowNavigation: false,
                    allowSearchAll: true,
                    fieldName: 'Tax',
                    hasFilters: false,
                    hideToggle: true,
                    minimumKeywordLength: 0,
                    openText: 'Open Customer Tax',
                    searchType: 'Tax'
                  }}
                  // metaKey="Tax"
                  minWidth="100%"
                  width="100%"
                  style={{ maxWidth: '100%', width: '100%' }}
                />
              </div>
              <div style={styles.searchRow.description}>
                <Field component={Label} propertyName="taxCodeDescription" />
              </div>
            </div>
          </GreyRow>
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <div
                className="cm-search-row-label"
                style={styles.searchRow.label}
              >
                Tax Exempt #:
              </div>
              <div style={styles.searchRow.input}>
                <DDITextField
                  propertyName="taxExemptNumber"
                  minWidth="100%"
                  width="100%"
                  style={{ maxWidth: '100%', width: '100%' }}
                />
              </div>
            </div>
          </GreyRow>
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <label
                className="cm-search-row-label"
                style={styles.searchRow.label}
                htmlFor="taxableType"
              >
                Taxable:
              </label>
              <div style={styles.searchRow.input}>
                <DDISelectField
                  propertyName="taxableType"
                  values={taxOptions}
                  margin="dense"
                  selectValue="dataId"
                  selectDescription="description"
                />
              </div>
            </div>
          </GreyRow>
          <GreyRow style={styles.marginBottom}>
            <div style={styles.searchRow.wrapper}>
              <div
                className="cm-search-row-label"
                style={{ ...styles.searchRow.label, maxWidth: 300 }}
              >
                Branch Pick Up Tax Code Pointer:
              </div>
              <div style={styles.searchRow.input}>
                <DDINumberSpinner propertyName="taxCodePointer" max={5} />
              </div>
            </div>
          </GreyRow>
        </DDICardWrapper>
      </div>
    </div>
  )
}

Credit.propTypes = {
  canChangeCreditLimit: PropTypes.bool.isRequired,
  taxOptions: PropTypes.array.isRequired
}

// export default withContext({
//   canChangeCreditLimit: formState =>
//     formState.values && formState.values.canChangeCreditLimit
//       ? formState.values.canChangeCreditLimit
//       : false,
//   taxOptions: formState =>
//     formState.meta && formState.meta.taxOptions ? formState.meta.taxOptions : []
// })(Credit)
export default withContext({
  canChangeCreditLimit: form => getValue(form, 'canChangeCreditLimit', false),
  taxOptionsList: form => getMeta(form, 'taxOptions', emptyList)
})(Credit)
