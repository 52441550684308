import React, { Component, forwardRef } from 'react'
import { tryImportAutoQuote } from 'pages/SalesOrder/actions'

// let inputEl

class AutoQuotesInput extends Component {
  openFileBrowser = e => {
    this.inputEl.click()
  }

  handleFileUploadProcess = files => {
    const { dispatch, form, gridApi } = this.props

    for (let i = 0; i < 1; i++) {
      const file = files[i]
      const fileName = file.name
      const extension = file.name
        .split('.')
        .pop()
        .toLowerCase()

      const reader = new FileReader()

      if (extension === 'json' || extension === 'txt') {
        reader.onload = e => {
          const data = reader.result
          if (data && dispatch && form) {
            dispatch(
              tryImportAutoQuote(form, {
                content: Buffer.from(data).toString('base64'),
                fileName,
                gridApi
              })
            )
          }
        }

        reader.readAsText(file)
      }
    }
  }

  onSelectFileUpload = e => {
    const { files } = e.target
    this.handleFileUploadProcess(files)
  }

  onOpenFileBrowserHandler = e => (e.target.value = null)

  render() {
    return (
      <div style={{ display: 'none' }}>
        <input
          type="file"
          ref={el => (this.inputEl = el)}
          onChange={this.onSelectFileUpload}
          onClick={this.onOpenFileBrowserHandler}
        />
      </div>
    )
  }
}

export default AutoQuotesInput
