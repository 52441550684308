/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import {
  deleteContactAsync,
  deleteShipTo,
  deleteTaxMatrix
} from 'pages/CustomerMaster/actions'
import { connect } from 'react-redux'
import { plainDeepEqual } from 'utils'
import { deleteGridRow } from 'components/EditableGrid/utils'
import { debounce } from 'lodash'

class DeleteGridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteContactAsync: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    this.deleteGridRow = deleteGridRow.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.rowIndex !== this.props.rowIndex ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  deleteContact = () => {
    const {
      colDef: { form },
      data: { dataId, parentId, parentType, description, recordName }
    } = this.props
    if (dataId && parentId && parentType) {
      this.props.dispatch(
        deleteContactAsync.try(form, {
          dataId,
          parentId,
          parentType,
          recordName,
          modalTitleSuffix: description
        })
      )
    }
  }

  deleteShipTo = () => {
    const {
      data: { dataId, description }
    } = this.props
    if (dataId !== '00000001') {
      this.props.dispatch(deleteShipTo({ dataId, description }))
    }
  }

  deleteTaxMatrix = () => {
    const {
      data: { dataId }
    } = this.props
    this.props.dispatch(deleteTaxMatrix('customerMaster', { dataId, unlocked: true }))
  }

  dispatchDeleteProcess = debounce(e => {
    const {
      colDef: { type }
    } = this.props

    const methods = {
      contact: this.deleteContact,
      deleteGridRow: this.deleteGridRow,
      shipTo: this.deleteShipTo,
      taxMatrix: this.deleteTaxMatrix
    }

    if (type && methods[type]) {
      return methods[type]()
    }

    return false
  }, 300)

  getIconColor = () => {
    const {
      colDef: { type },
      data
    } = this.props

    if (
      type === 'shipTo' &&
      data &&
      data.dataId &&
      data.dataId === '00000001'
    ) {
      return '#cecece'
    }

    return '#d9534f'
  }

  render() {
    const {
      colDef: { type }
    } = this.props
    // const deleteFnDisabled = ['shipTo']
    // const iconColor = type === 'shipTo' && data && data.dataId && dataId ? '#d9534f' : '#cecece'

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{
            color: this.getIconColor(),
            display: 'inline-block',
            fontSize: 16
          }}
          onClick={this.dispatchDeleteProcess}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(DeleteGridCell)
