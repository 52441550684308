/* eslint jsx-a11y/label-has-for: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { Grid } from 'grid'
import masterStyles from 'theme/masterStyles'
import { layoutFlex } from 'utils'
import { numberFilterGridConfigs } from 'pages/ProductMaster/utils'

/* Editable Grid Stuffs */
import EditableGrid from 'components/EditableGrid'
import ClearGridRowCell from 'components/EditableGrid/components/ClearGridRowCell'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell/'

export class Replenishments extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      eslint: 'off'
    }
  }

  getColumnDefs = props => {
    const { _ddiForm } = this.props

    const params = {
      disabled: !props.isEditing,
      focusedCell: props.focusedCell,
      form: props.form,
      indexSearchType: 'warehouse',
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    let colDefs = [
      {
        cellStyle: { color: props.isEditing ? '#444' : '#999' },
        field: 'toWarehouseIdAndDescription',
        headerName: 'Transfer To Warehouse',
        suppressNavigable: true
      },
      {
        field: 'fromWarehouseId',
        headerName: 'From Warehouse',
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Warehouse',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          _ddiForm,
          onChange: 'updateGridCellData',
          tabToNextCellOnChange: true,
          allowMultipleSearches: true,
          meta: {
            allowInstantSearch: true,
            minimumKeywordLength: 2,
            allowSearchAll: true
          },
          // errorMessage: 'From Warehouse is required',
          getErrorMessageFn: data => {
            if (data.fromWarehouseIdErrorMessage) {
              return data.fromWarehouseIdErrorMessage
            }

            // if (data.toWarehouseId === data.fromWarehouseId) {
            //   return 'From Warehouse cannot be the same as To Warehouse'
            // }

            return 'From Warehouse is required'
          },
          requiredFn: data => {
            // if (data.toWarehouseId === data.fromWarehouseId) {
            //   return true
            // }
            if (data.fromWarehouseIdErrorMessage) {
              return true
            }

            if (
              !data.fromWarehouseId &&
              (data.minWeeks || data.maxWeeks || data.breakCase)
            ) {
              return true
            }

            return false
          }
        }
      },
      {
        field: 'minWeeks',
        headerName: 'Minimum Weeks',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          requiredLessThan: 'maxWeeks',
          errorMessage: 'Min weeks cannot be greater than max weeks',
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'maxWeeks',
        headerName: 'Maximum Weeks',
        headerClass: 'align-right',
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'breakCase',
        headerName: 'Break Case',
        headerClass: 'text-center',
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          // disabledFn: data => params.disabled
          isDisabled: params.disabled
        },
        editable: false
      }
    ]

    if (props.isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'delete',
          headerName: '',
          cellRendererFramework: ClearGridRowCell,
          cellRendererParams: {
            ...params,
            skipFields: ['toWarehouseId', 'toWarehouseIdAndDescription']
          },
          editable: false,
          width: 50,
          maxWidth: 50
        }
      ]
    }

    return colDefs
  }

  render() {
    const { height, isEditing } = this.props
    return (
      <div style={layoutFlex('100rem')}>
        <EditableGrid
          gridWrapperStyle={{ flex: '1 1', maxWidth: '100%', width: '100%' }}
          requiredFields={['toWarehouseId']}
          getRowNodeId={data => data.toWarehouseId}
          propertyName="replenishments"
          form={this.props.form}
          columnDefs={this.getColumnDefs}
          height={height}
          isEditing={isEditing}
          focusCell="minWeeks"
          width="100%"
          fullWidth
          skipValidationOnUnmount
          suppressRowClickSelection
          showAddButtonOnlyIfEditing={false}
        />
      </div>
    )
  }
}

export const contextObj = {
  isEditing: form => form.get('isEditing') || false
}

export default withContext(contextObj)(Replenishments)
