/* eslint import/prefer-default-export: 0 */
import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const readWarrantyTag = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/warrantytag/read`, options)
}

export const printWarrantyTag = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/warrantytag/print`, options)
}

export const propertyChange = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/warrantytag/propertychange`, options)
}

export const deleteWarrantyTag = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/warrantytag/delete`, options)
}

export const updateWarrantyTag = args => {
  const options = createApiOptions({
    method: 'post',
    body: args
  })
  return callFetch(`${BASE_INFORM_API_URL}/warrantytag/delete`, options)
}

export const originalInvoiceSearch = keyword => {
  const options = createApiOptions({
    method: 'post',
    body: {
      keyword,
      allowInvalidValues: true
    }
  })
  return callFetch(`${BASE_INFORM_API_URL}/search/invoice/exactmatch`, options)
}
