import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import Grid from 'grid'
import { plainDeepEqual, numToDollar, noDataMessage, formatDollarFields } from 'utils'
import { AppBar, Typography, Tabs, Tab } from '@material-ui/core'
import ReactHighcharts from 'react-highcharts'
import { setDefaultChartView } from 'pages/ContactMaster/actions'
import { setGridHeight } from 'pages/ContactMaster/utils'

export const getRowNodeId = data => {
  const { description, amount } = data
  return `${description} - ${amount}`
}

export const formatSummaryGrids = (col, i) => {
  col = {
    ...col
  }

  if (col.field === 'amount') {
    col = {
      ...col,
      valueFormatter: params => formatDollarFields(params)
    }
  }

  return col
}

export const TabContainer = props => (
  <Typography component="div">{props.children}</Typography>
)

export class SummaryGrid extends Component {
  static propTypes = {
    columnDefs: PropTypes.array.isRequired,
    defaultTab: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    gridInnerWrapperStyle: PropTypes.object,
    gridOptions: PropTypes.object,
    hasChart: PropTypes.bool,
    rowData: PropTypes.array.isRequired,
    style: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
  }

  static defaultProps = {
    defaultTab: 'Data',
    gridInnerWrapperStyle: {
      flex: '1',
      overflow: 'hidden',
      maxWidth: '100%',
      width: '100%'
    },
    gridOptions: {},
    hasChart: false
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: props.defaultTab
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.tab !== nextState.tab) {
      return true
    }
    if (plainDeepEqual(this.props, nextProps)) {
      return false
    }
    return true
  }

  handleTabChange = (e, value) => {
    const { dispatch, form } = this.props
    this.setState({ tab: value })

    if (form === 'contact') {
      dispatch(setDefaultChartView.try(form, { view: value }))
    }
  }

  pieChartConfig = () => {
    const { rowData } = this.props

    const pieData = rowData
      ? rowData.reduce((acc, next) => {
        acc = acc.concat({
          name: next.description,
          y: Number(next.amount)
        })
        return acc
      }, [])
      : []

    const config = {
      chart: {
        height: 200,
        options3d: {
          alpha: 45,
          beta: 0,
          enabled: true
        },
        spacingTop: -20,
        type: 'pie',
        width: 200
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false,
        symbolRadius: 2
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %'
          },
          depth: 35,
          showInLegend: true
        }
      },
      series: [{ data: pieData }],
      tooltip: {
        enabled: true,
        formatter() {
          return `<strong>${this.key}</strong> ${numToDollar(this.y)}`
        }
      }
    }
    return config
  }

  dataIsParsable = rowData => {
    const total = rowData.reduce((acc, next) => {
      acc += Number(next.amount)
      return acc
    }, 0)

    return total > 0 || false
  }

  render() {
    const { tab } = this.state
    const {
      columnDefs,
      rowData,
      title,
      style,
      hasChart,
      gridOptions,
      gridInnerWrapperStyle
    } = this.props

    const renderedGrid =
      rowData && rowData.length ? (
        <div style={gridInnerWrapperStyle}>
          <Grid
            hideHeader
            getRowNodeId={getRowNodeId}
            rowData={rowData}
            gridOptions={gridOptions}
            columnDefs={columnDefs.map(formatSummaryGrids)}
            height={setGridHeight(rowData)}
            width="100%"
          />
        </div>
      ) : (
        noDataMessage()
      )

    return hasChart ? (
      <DDICardWrapper
        title={title}
        style={style}
        contentStyle={{ minHeight: 200, padding: 0 }}
      >
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs
            id="cl-summary-tabs"
            onChange={this.handleTabChange}
            value={tab}
          >
            <Tab value="Data" label="Grid" />
            <Tab value="Chart" label="Chart" />
          </Tabs>
        </AppBar>
        <TabContainer>
          {tab === 'Data' && (renderedGrid) }
          {tab === 'Chart' &&
            (rowData && rowData.length && this.dataIsParsable(rowData) ? (
              <div style={{ margin: '0 auto', width: 200 }}>
                <ReactHighcharts
                  config={this.pieChartConfig()}
                  ref={c => (this.chart = c)}
                />
              </div>
            ) : (
              noDataMessage()
            ))}
        </TabContainer>
      </DDICardWrapper>
    ) : (
      <DDICardWrapper
        title={title}
        style={style}
        contentStyle={{ minHeight: '25%', padding: 0 }}
      >
        {renderedGrid}
      </DDICardWrapper>
    )
  }
}

export default SummaryGrid
