import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import IndexSearchCell from './'

export default TooltipField({
  anchorToGridCellText: true,
  // indexSearchType: props.indexSearchType,
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(IndexSearchCell)
