import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import Grid from 'grid'
import { addScreenInModal } from 'ddiForm/actions'
import { select, put } from 'redux-saga/effects'
import { getFormSelector, getMapResponse } from 'ddiForm/utils'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'

import { formatNumber } from 'utils'
import moment from 'moment'
import TooltipField from 'hoc/TooltipField'
import { mapGetRecordResponse } from '../utils'
import { salesOrderInModal } from '../index'
import { propertyChange as propertyChangeAPI, readSalesOrder } from '../api'

const SpawnedOrderRenderer = ({ value, form }) => {
  const [isOpeningScreen, setIsOpening] = useState(false)

  const buttonLinkStyle = {
    color: isOpeningScreen ? '#777' : '#517b9c',
    fontWeight: 500,
    margin: 0,
    padding: 0,
    textDecoration: 'underline'
  }

  const dispatch = useDispatch()

  function* openSOEInModalOnSuccess(response, meta, form) {
    const formState = yield select(getFormSelector(form))
    console.log(response)
    const tabIds = ['header', 'detail']
    const mappedResponse = mapGetRecordResponse({
      response,
      formState,
      tabIds,
      groupNames: tabIds
    })
    console.log(form, mappedResponse)
    debugger

    yield put(
      getEntityAsync.success(mappedResponse, {
        form,
        thunk: meta.thunk
      })
    )
  }

  const openSalesOrderInModal = debounce(e => {
    debugger
    dispatch(
      addScreenInModal(form, {
        screen: salesOrderInModal(`${form}.salesOrder`),
        route: 'salesorder',
        formName: `${form}.salesOrder`,
        image: 'sales_order_entry_16.png',
        title: 'Sales Order',
        // apiMethod: propertyChangeAPI,
        apiMethod: readSalesOrder,
        // newInstance: true,
        apiArgs: {
          dataId: value,
          route: 'salesorder',
          // newInstance: true,
          groupNames: ['header', 'detail', 'final']
        },
        onApiSuccess: openSOEInModalOnSuccess,
        onApiError: error => {
          console.log('fail', error)
          debugger
        },
        modalOptions: {
          title: 'Sales Order Entry',
          hideActions: true,
          maxSize: true,
          modalOverrideClass: 'sales-order-entry-in-modal'
        }
      })
    )
  }, 500)

  const handleClick = debounce(async event => {
    if (isOpeningScreen) {
      return
    }
    try {
      setIsOpening(true)
      await dispatch(openSalesOrderInModal(event))
    } catch (e) {
      console.log(e)
    } finally {
      setIsOpening(false)
    }
  }, 300)

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <button
        onClick={handleClick}
        style={buttonLinkStyle}
        type="button"
        disabled={isOpeningScreen}
      >
        {value}
      </button>
    </div>
  )
}

const SpawnedOrdersModal = ({ data: { rowData = [], form } }) => {
  debugger
  const columnDefs = [
    {
      colId: 'orderId',
      cellRendererFramework: TooltipField({
        anchorToGridCellText: true,
        indexSearchType: 'salesOrder',
        position: 'right-end',
        recordNameKey: 'value',
        sensitivity: 100,
        type: 'indexSearch'
      })(SpawnedOrderRenderer),
      cellRendererParams: { form },
      field: 'orderId',
      headerName: 'Spawned Order',
      editable: false,
      sortable: false,
      filterable: false
    },
    {
      colId: 'typeDescription',
      field: 'typeDescription',
      headerName: 'Type',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false
    },
    {
      colId: 'orderedDate',
      field: 'orderedDate',
      headerName: 'Ordered Date',
      cellClass: 'align-center center-align text-center',
      headerClass: 'align-center center-align text-center',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => {
        return params.value ? moment(params.value).format('M/D/YY') : null
      }
    },
    {
      colId: 'shipDate',
      field: 'shipDate',
      headerName: 'Ship Date',
      cellClass: 'align-center center-align text-center',
      headerClass: 'align-center center-align text-center',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => {
        return params.value ? moment(params.value).format('M/D/YY') : null
      }
    },
    {
      colId: 'orderedQuantity',
      field: 'orderedQuantity',
      headerName: 'Qty Spawned',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => {
        return `${params.value}`
      }
    },
    {
      colId: 'shippedQuantity',
      field: 'shippedQuantity',
      headerName: 'Qty Shipped',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false,
      valueFormatter: params => {
        return `${params.value}`
      }
    },

    {
      colId: 'status',
      field: 'status',
      headerName: 'Status',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      editable: false,
      sortable: false,
      filterable: false,
      tooltipValueGetter: params => params.value.tooltip,
      valueFormatter: params => {
        return params.value.value
      }
    }
  ]

  return (
    <Grid
      height={350}
      rowData={rowData && Array.isArray(rowData) ? rowData : []}
      getRowNodeId={data => data.dataId}
      columnDefs={columnDefs}
    />
  )
}

export default SpawnedOrdersModal
