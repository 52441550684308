/* eslint import/prefer-default-export: 0 */
import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const tryTogglePreNewMode = form =>
  createAction(CONSTANTS.TRY_TOGGLE_PRE_NEW_MODE, {}, form)

export const togglePreNewMode = form =>
  createAction(CONSTANTS.TOGGLE_PRE_NEW_MODE, {}, form)

export const savePreNewModeValue = (form, { value }) =>
  createAction(CONSTANTS.SAVE_PRE_NEW_MODE_VALUE, { value }, form)
