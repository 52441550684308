import {
  actionChannel,
  take,
  call,
  fork,
  put,
  select
} from 'redux-saga/effects'
import { getIn } from 'utils'
import * as CONSTANTS from 'pages/ProductMaster/constants'
// import { getFormSelector } from 'ddiForm/utils'
import { api } from 'services'
import { getEntityAsync, resetMasterFields } from 'ddiForm/MasterScreen/actions'
import * as actions from 'pages/ProductMaster/actions'
import { getFormSelector } from 'ddiForm/utils'
import { warningModal } from 'modals/sagas'
import { addModal, hideModal } from 'modals/actions'
import {
  clearGridRow,
  updateGridCellData
} from 'components/EditableGrid/actions'
import NotesModal from 'pages/ProductMaster/components/NotesModal'

export function* overrideOpenScreenListener(formListener) {
  const channel = yield actionChannel([CONSTANTS.OPEN_PRODUCT_SCREEN])
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(openScreenProcess, form, payload)
    }
  }
}

export function* openScreenProcess(form, payload) {
  const { dataId, groupNames, name, title, image } = payload

  if (!dataId) return

  yield put(actions.getProduct.request(form))
  const { response, error } = yield call(api.getProduct, {
    dataId,
    groupNames,
    name,
    title,
    image
  })

  if (response) {
    // reset fields and values
    yield put(resetMasterFields(form))
    yield put(
      getEntityAsync.success(
        {
          ...response,
          groupNames
        },
        form
      )
    )
  } else {
    yield put(actions.getProduct.failure(form))
  }
}

export function* saveProductAssemblyNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_PRODUCT_ASSEMBLY_NOTES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield put(actions.saveProductAssemblyNotes.success({ ...payload }, form))
    }
  }
}

export function* openModalScreenListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.LAUNCH_NOTES_MODAL)

    if (form === formListener) {
      yield fork(openNotesModal, form, payload)
    }
  }
}

export function* openNotesModal(form, payload) {
  const { propertyName, rowIndex } = payload

  const formState = yield select(getFormSelector(form))

  const data =
    getIn(formState, `fields.${propertyName}.rowData[${rowIndex}]`) || []
  const note = getIn(data, 'notes')
  const dataId = getIn(data, 'dataId')
  const description = getIn(data, 'description')
    ? getIn(data, 'description')
    : ''

  const modalOpts = {
    component: NotesModal,
    options: {
      data: {
        actions: [
          {
            // action to save notes
            primary: true,
            title: 'Save',
            // clickEvent: { action: actions.saveProductAssemblyNotes.try, dataId }
            async clickEvent(args, formState, cb) {
              const { id } = args
              // debugger
              const { value = '' } = this.state
              debugger
              try {
                this.props.dispatch(
                  actions.saveProductAssemblyNotes.try(form, {
                    dataId,
                    note: value,
                    propertyName,
                    rowIndex
                  })
                )
                await this.props.dispatch(hideModal(form, id))
              } finally {
                cb()
              }
            }
          },
          {
            // action to delete notes
            primary: true,
            title: 'Delete',
            async clickEvent(args, formState, cb) {
              const { id } = args
              try {
                this.props.dispatch(
                  actions.saveProductAssemblyNotes.try(form, {
                    dataId,
                    note: '',
                    propertyName,
                    rowIndex
                  })
                )
                await this.props.dispatch(hideModal(form, id))
              } finally {
                cb()
              }
            }
          },
          { primary: true, title: 'Exit' }
        ],
        note,
        notesModalEnabled: true
      },
      title: `Product Assembly - ${dataId} ${description}`
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
}

export function* validateAssemblies(form, rowIndex, propertyName, data) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const components = getIn(formState, 'fields.components.rowData')
  // components = components && components.toJS ? components.toJS() : []
  const componentId =
    components && components.get ? components.get(rowIndex).get('dataId') : ''

  if (componentId === dataId) {
    yield call(
      warningModal,
      'Cannot add the product as its own assembly component',
      'Attention!'
    )
    yield put(clearGridRow(form, { propertyName: 'components', rowIndex }))
  }

  if (propertyName === 'components' && data && data.recordName) {
    yield put(actions.getUOMForProductGrid.request(form))

    const { response, error } = yield call(api.getProductUOMData, {
      dataId: data.recordName
    })

    if (response) {
      yield put(actions.getUOMForProductGrid.success({ uoms: response }, form))
      if (response && response[0] && response[0].dataId) {
        yield put(
          updateGridCellData(form, {
            rowIndex,
            propertyName: 'components',
            field: 'uomId',
            value: response[0].dataId
          })
        )
      }
    } else {
      yield put(actions.getUOMForProductGrid.failure(error, form))
    }
  }
}

export default function* sagas(form) {
  yield fork(overrideOpenScreenListener, form)
  yield fork(saveProductAssemblyNotesListener, form)
  yield fork(openModalScreenListener, form)
}
