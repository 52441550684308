import React from 'react'
import { Button, Icon } from '@material-ui/core'

const OrderLink = ({ orderId, openExternalScreen, ...other }) => {
  return (
    <Button
      onClick={e => openExternalScreen(orderId, 'salesOrder')}
      startIcon={<Icon>open_in_new</Icon>}
    >
      {orderId}
    </Button>
  )
}

export default OrderLink
