/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import { Icon } from '@material-ui/core'

import { DDITextField } from 'ddiForm/wrapped'

import { getIn } from 'utils'
import { withContext } from 'ddiForm'

const alertStyle = {
  alignItems: 'center',
  backgroundColor: '#fcf8e3',
  borderColor: '#faebcc',
  color: '#d9534f',
  display: 'flex',
  fontSize: 11,
  margin: '5px 0',
  padding: 5,
  width: '100%'
}
const alertIconStyle = { color: '#d9534f', fontSize: 14, marginRight: 5 }

const SaveProductAsModal = ({ errorMessage }) => {
  return (
    <div>
      <div>
        <DDITextField
          propertyName="fastProduct.dataId"
          label="Enter Product ID"
          preventAutoDisable
        />
        <span
          style={{
            color: '#777',
            display: 'inline-block',
            fontSize: 11,
            fontStyle: 'italic',
            fontWeight: 400,
            margin: '5px 0',
            padding: 0
          }}
        >
          (blank for system generated)
        </span>
      </div>
      {errorMessage ? (
        <div className="alert alert-warning" style={alertStyle}>
          <Icon size="small" style={alertIconStyle}>
            warning
          </Icon>{' '}
          <span>{errorMessage}</span>
        </div>
      ) : null}
    </div>
  )
}

/* FIXED SVE 9/23/2020 */
export default withContext({
  errorMessage: form =>
    getIn(form, 'fields.fastProduct.dataId.errorMessage') || ''
})(SaveProductAsModal)
