import { getIn } from 'utils'
import { ButtonBase } from '@material-ui/core'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { CLOSE_SEARCH, CLOSE_FILTERS_GRID } from './constants'

import {
  blur,
  exactMatchSearch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  toggleFilter as toggleFilterAction,
  clearSearch
} from './actions'
import baseBehaviors from '../baseBehaviors'

export default {
  ...baseBehaviors,
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    // //
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  getRowNodeId(data) {
    return data.recordName
  },

  // what is this? put this somewhere else...

  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },
  // onCellSelected(event) {
  //   this.setField(event.data.dataId)
  //   this.blur(this.state.value)
  //   this.onRequestClose()
  // },
  async onCellSelected(event) {
    const { propertyName, warehouseId, productId, uom } = this.props
    try {
      const p = await this.props.dispatch(
        exactMatchSearch(this.form, {
          keyword: event.data.dataId,
          propertyName,
          warehouseId,
          productId,
          uom
        })
      )

      if (p.matchFound) {
        this.setState(
          {
            value: p.result.dataId,
            description: p.result.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false,
            tab: false
          },
          () => {
            this.setField(p.result.dataId)
            this.blur(this.state.value)
          }
        )
      } else {
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false,
          tab: false
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },
  // async onChange(event) {
  //   this.timeout = this.timeout || 0
  //   if (this.timeout) {
  //     clearTimeout(this.timeout)
  //   }
  //   event.persist()
  //   const v = event.target.value
  //   const callPartial = () => {
  //     this.timeout = setTimeout(
  //       () => this.partialMatchSearch(v),
  //       this.props.timer || 500
  //     )
  //   }

  //   const {
  //     meta: { allowInstantSearch, minimumKeywordLength }
  //   } = this

  //   await this.setState({
  //     isDirty: true,
  //     value: v,
  //     // lastSearch: event.target.value,
  //     isSet: false
  //   })
  //   if (allowInstantSearch && v.length >= (minimumKeywordLength || 2)) {
  //     this.setState(
  //       {
  //         lastSearch: event.target.value,
  //         value: event.target.value
  //       },
  //       callPartial()
  //     )
  //   } else if (v.length === 0) {
  //     this.clearSearch()
  //     this.setField('', true)
  //   }
  // },
  onCloseFiltersGrid() {
    this.setState({ mouseover: false }, () => {
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_FILTERS_GRID,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
    })
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()

      const { propertyName, warehouseId, productId, uom } = this.props

      this.setState(
        {
          isOpen: false,
          value: val,
          lastSearch: val,
          tab: true
        },
        async () => {
          try {
            const p = await this.props.dispatch(
              exactMatchSearch(this.form, {
                keyword: this.state.value,
                propertyName,
                warehouseId,
                productId,
                uom
              })
            )

            if (p.matchFound) {
              this.setState(
                {
                  value: p.result.dataId,
                  description: p.result.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(p.result.dataId)
                  this.blur(this.state.value)
                }
              )
            } else {
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false,
                tab: false
              })
            }
          } catch (e) {
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      )
    }
  },

  onMouseLeave(event) {
    this.setState({ mouseover: false })
  },

  onMouseOver(event) {
    this.setState({ mouseover: true })
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      // this.onBlur()
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_SEARCH,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
      // console.log(this.state)S
    })
  },

  onSearchClick(event) {
    this.setState(
      {
        // isOpen: true,
        // dropDownProps: {}
        isSet: false
      },
      () => this.partialMatchSearch(this.state.value, true)
    )
  },
  // onKeyPress = e => console.log(e.charCode, e.keyCode)

  partialMatchSearch(value, bool, args, isFiltered = false) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    // const { partialMatc}
    const {
      includeSystemBins,
      keyword,
      onHandOnly,
      productId,
      propertyName,
      uom,
      warehouseId
    } = args || this.props
    if (args) {
      value = args.keyword
    }
    if (this.state.isSet || this.props.isFetching) return
    let results
    //
    this.setState({ lastSearch: value, isSearching: true }, async () => {
      try {
        //
        results = await this.props.dispatch(
          partialMatchSearch(this.props.form, {
            keyword: bool ? '' : value,
            includeSystemBins,
            onHandOnly,
            productId,
            propertyName,
            uom,
            warehouseId,
            onCellSelected: this.onCellSelected
          })
        )
        //
        // //
        this.setState({
          dropDownProps: {
            ...results,
            ...results.grid,
            getRowNodeId: this.getRowNodeId,
            paginationCb: this.paginationCb,
            height: this.getGridHeight(),
            isFiltered
          },
          isOpen: true
        })
        // }
      } finally {
        this.setState({ isSearching: false })
      }
    })
  },
  redoSearch() {
    // //
    this.setState(
      { isSet: false, value: this.state.lastSearch, isDirty: true },
      () => this.partialMatchSearch(this.state.lastSearch)
    )
  },
  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  }
  // setField(val, clear, results) {
  //   const { clearFieldOnSet, leader, setField, onSetField } = this.props
  //   // //
  //   setField(val, clear, leader, results)
  //   if (onSetField) {
  //     onSetField(val, clear, leader, results)
  //   }
  //   if (clearFieldOnSet) {
  //     setField('', clear)
  //   }
  // }
}
