import { actionChannel, take, call, select, fork } from 'redux-saga/effects'
import { getIn, toJS } from 'utils'
import * as EVENT_MODAL_CONSTANTS from 'modals/EventModal/constants'
import { getFormSelector } from 'ddiForm/utils'

import eventModalSagas from 'modals/EventModal/sagas'
import { listActivitiesProcess } from 'components/MasterScreen/Activities/sagas'

export function* getListActivitiesApiParams(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  return {
    form,
    dataId,
    groupNames: ['activities']
  }
}

export function* refreshActivitiesListener(formListener) {
  const channel = yield actionChannel([
    EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS,
    EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS
  ])

  while (true) {
    const action = yield take(channel)
    if (
      (action.meta && action.meta.form && action.meta.form === formListener) ||
      (action?.meta?.form && action.meta.form === 'calendar')
    ) {
      const activitiesApiParams = yield call(
        getListActivitiesApiParams,
        formListener
      )
      yield fork(listActivitiesProcess, activitiesApiParams)
    }
  }
}

export default function* activitiesSagas(form) {
  yield fork(eventModalSagas, form)
  yield fork(refreshActivitiesListener, form)
}
