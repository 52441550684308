/* eslint consistent-return: 0 */
import {
  call,
  // fork,
  put,
  select,
  take
} from 'redux-saga/effects'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { getFormSelector } from 'ddiForm/utils'
// import { setField } from 'ddiForm/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import * as actions from 'pages/SalesOrder/actions'
import {
  isValidQuantityInput,
  extractString,
  calculateResolvedQuantity,
  roundToNextMultiple
} from 'pages/SalesOrder/utils'
// import { returnProductProcess } from 'pages/SalesOrder/sagas/returnOrderSagas'
import { getIn } from 'utils'
import { revertNumberSpinnerValue } from '../actions'

export function* quantityToShipProcessMobile(action) {
  const {
    payload: { value, data, field },
    meta,
    type
  } = action

  let bool = false
  let temp
  let tempData = data
  const isTryCellChanged = type === 'TRY_CELL_CHANGED_REQUEST'
  if (!isTryCellChanged) {
    tempData = tempData.quantityChange
  }

  if (field === 'quantity') {
    temp = value
    if (temp > data.quantityOrdered) {
      bool = true
    }
  } else {
    temp = value + data.quantity
    if (temp > data.quantityOrdered) {
      bool = true
    }
  }

  const success = put({
    meta,
    type: isTryCellChanged
      ? 'TRY_CELL_CHANGED_SUCCESS'
      : CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.REQUEST,
    payload: { value, data, field }
  })

  if (bool) {
    const failure = put({
      meta,
      error: true,
      type: isTryCellChanged
        ? 'TRY_CELL_CHANGED_FAILURE'
        : CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.FAILURE
    })
    // alert the user..
    const title = 'Over Open Quantity'
    const message =
      'Quantity ship is greater than quantity open!\nAre you sure?'
    yield call(confirmationModal, message, title)
    const act = yield take([CONFIRMED, CANCELED])

    if (act.type === CONFIRMED) {
      if (isTryCellChanged) {
        yield success
      } else {
        return true
      }
    } else if (isTryCellChanged) {
      /* 
        flips the isPostingGridUpdate flag back and forth 
        to allow the value to revert 
        SVE- 11/16/2020
      */
      yield put(
        revertNumberSpinnerValue(action.meta.form, {
          field,
          isPostingGridUpdate: true,
          value: null
        })
      )
      yield put(
        revertNumberSpinnerValue(action.meta.form, {
          field,
          isPostingGridUpdate: false,
          value: data[field]
        })
      )
    } else {
      // debugger
      /* 
        flips the isPostingGridUpdate flag back and forth 
        to allow the value to revert 
        SVE- 11/16/2020
      */
      yield put(
        revertNumberSpinnerValue(action.meta.form, {
          field,
          isPostingGridUpdate: true,
          value: null
        })
      )
      yield put(
        revertNumberSpinnerValue(action.meta.form, {
          field,
          isPostingGridUpdate: false,
          value: data[field]
        })
      )
    }
  } else if (isTryCellChanged) {
    yield success
  }
  //
}

export function* quantityValidationMobile(
  action,
  skipBoxQuantityRoutine = false
) {
  // debugger
  const {
    payload: { value, data, field, propertyName, node },
    meta: { form },
    meta
  } = action

  const { dataId, description, lineNumber, rowId } = data
  const formState = yield select(getFormSelector(form))
  let resolvedValue = value
  const isValid = isValidQuantityInput(resolvedValue)
  const boxQuantityResolved = getIn(data, 'boxQuantityResolved') || null
  const cartonQuantityResolved = getIn(data, 'cartonQuantityResolved') || null
  const roundToBoxQuantity = getIn(data, 'roundToBoxQuantity') || false

  if (value < 0) {
    /* handle returns */
    debugger
    // yield fork(returnProductProcess, form, meta, value, data, node)
    return
  }

  if (skipBoxQuantityRoutine) {
    yield put({
      meta,
      type: 'TRY_CELL_CHANGED_SUCCESS',
      payload: {
        field,
        propertyName,
        value,
        data
      }
    })

    return
  }

  if (roundToBoxQuantity) {
    const suppressedLineItems =
      getIn(formState, 'values.listOfLineItemIdsSuppressed') &&
      getIn(formState, 'values.listOfLineItemIdsSuppressed').toJS()
        ? getIn(formState, 'values.listOfLineItemIdsSuppressed').toJS()
        : []

    if (!isValid) {
      resolvedValue = ''
    }

    const k = extractString(value)
    resolvedValue = calculateResolvedQuantity(
      value,
      k,
      boxQuantityResolved,
      cartonQuantityResolved
    )

    // debugger
    if (k.toLowerCase() !== 'c' && !suppressedLineItems.includes(rowId)) {
      const isMultiple = resolvedValue % boxQuantityResolved === 0
      const roundedValue = roundToNextMultiple(
        resolvedValue,
        boxQuantityResolved
      )

      const isResolvedValue = roundedValue === resolvedValue

      if (!isMultiple && !isResolvedValue) {
        yield call(
          confirmationModal,
          `${resolvedValue} is not a box quantity multiple of ${boxQuantityResolved}. Round to ${roundedValue} ?`,
          `Warning: ${dataId} - "${description}"`,
          { form },
          { title: 'Yes' },
          { title: 'No' }
        )

        const act = yield take([CONFIRMED, CANCELED])
        // debugger
        if (act.type === CONFIRMED) {
          resolvedValue = roundedValue
          yield put({
            meta,
            type: 'TRY_CELL_CHANGED_SUCCESS',
            payload: {
              field,
              propertyName,
              value: resolvedValue,
              data
            }
          })
        } else {
          // if no, set flag to stop prompt
          yield put(actions.supressBoxQuantityPrompt(form, { rowId }))
          yield put({
            meta,
            type: 'TRY_CELL_CHANGED_SUCCESS',
            payload: {
              field,
              propertyName,
              value: resolvedValue,
              data
            }
          })
        }
      } else {
        yield put({
          meta,
          type: 'TRY_CELL_CHANGED_SUCCESS',
          payload: {
            field,
            propertyName,
            value: roundedValue,
            data
          }
        })
      }
    } else {
      yield put({
        meta,
        type: 'TRY_CELL_CHANGED_SUCCESS',
        payload: {
          field,
          propertyName,
          value,
          data
        }
      })
    }
  } else {
    yield put({
      meta,
      type: 'TRY_CELL_CHANGED_SUCCESS',
      payload: {
        field,
        propertyName,
        value,
        data
      }
    })
  }
}
