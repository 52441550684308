import React, { useEffect } from 'react'
import Grid from 'grid'
import { formatDollarFields } from 'utils'
import { resizeGrid } from 'pages/SalesOrder/utils'

let _isMounted = false

export const PriceBreaksGrid = ({
  form,
  height,
  dispatch,
  rowData,
  layoutState,
  gridApi,
  isEditing
}) => {
  let columnApi = null

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    if (layoutState && columnApi && _isMounted) {
      resizeGrid(columnApi)
    }
  }, [layoutState])

  const onGridReady = params => {
    if (params && params.columnApi) {
      columnApi = params.columnApi
    }
  }

  const getRowNodeId = data => data.dataId

  const columnDefs = [
    {
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right',
      field: 'dataId',
      headerName: 'Quantity'
    },
    {
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right',
      field: 'price',
      headerName: 'Price',
      valueFormatter: formatDollarFields
    }
  ]

  return (
    <div style={{ widht: '100%' }}>
      <Grid
        form={form}
        getRowNodeId={getRowNodeId}
        columnDefs={columnDefs}
        height={height}
        id="priceBreaks"
        rowData={rowData}
        onGridReady={onGridReady}
      />
    </div>
  )
}

export default PriceBreaksGrid
