import { call, fork, put, select, take, delay } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { displayValidationErrors } from 'ddiForm/sagas'
import { getIn, is } from 'utils'
import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, removeModal } from 'modals/actions'
import ShipperHQModal from '../components/ShipperHQModal'
import SwapCommittedQuantitiesModal from '../components/SwapCommittedQuantitiesModal'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { readSalesOrderProcess } from './searchAreaSagas'
import { shipperHQInteraction as shipperHQInteractionAPI } from '../api'

export function* displayShipperHQModal(form, data = {}) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  const modalOpts = {
    component: ShipperHQModal,
    options: {
      actions: false,
      title: 'Quote Freight',
      data: {
        form,
        isEditing,
        ...data
      },
      marginTop: '0px',
      maxHeight: '100%',
      width: 500
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* handleShipperHQProcess(
  form,
  thunk,
  apiParams = {},
  modalId = null,
  gridApi = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null

  yield put(actions.handleShipperHQInteraction.request(form))
  // debugger

  const { response, error } = yield call(shipperHQInteractionAPI, {
    guid,
    ...apiParams
  })

  if (response) {
    yield put({
      type: CONSTANTS.HANDLE_SHIPPER_HQ_INTERACTION.SUCCESS,
      meta: {
        form,
        thunk
      },
      payload: {
        ...response,
        action: apiParams?.action,
        activeLineNumber: apiParams?.lineNumber
      }
    })

    if (apiParams?.action === 'initialize') {
      yield fork(displayShipperHQModal, form, response)
    }

    if (modalId) {
      yield put(removeModal(form, modalId))
    }

    if (apiParams?.action === 'save' && dataId) {
      yield fork(readSalesOrderProcess, { value: dataId, form })
    }
  } else {
    debugger
    yield put({
      type: CONSTANTS.HANDLE_SHIPPER_HQ_INTERACTION.FAILURE,
      meta: {
        form,
        thunk
      },
      payload: {
        ...error,
        propertyChanged: 'backorderCommitment'
      }
    })

    if (error.status !== 496) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* tryBeginEditingFocusedCell(gridApi = null) {
  if (gridApi && gridApi?.getFocusedCell) {
    const focusedCell = gridApi.getFocusedCell()
    const rowIndex = focusedCell?.rowIndex
    const colId = focusedCell?.column?.colId || ''
    if (is.number(rowIndex)) {
      yield delay(100)
      gridApi.startEditingCell({
        rowIndex,
        colKey: colId
      })
    }
  }
}

export function* handleShipperHQInteractionListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: {
        apiParams = {},
        modalId = null,
        gridApi = null,
        displayModalError = false
      }
    } = yield take(CONSTANTS.HANDLE_SHIPPER_HQ_INTERACTION.TRY)

    if (form === formListener) {
      // debugger
      if (displayModalError) {
        yield fork(
          warningModal,
          'A carrier type should be selected for each vendor',
          'Attention!'
        )
      } else {
        yield fork(
          handleShipperHQProcess,
          form,
          thunk,
          apiParams,
          modalId,
          gridApi
        )
      }
    }
  }
}

export default function* shipperHQSagas(form) {
  yield fork(handleShipperHQInteractionListener, form)
}
