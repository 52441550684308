import memoize from 'memoize-one'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getIn, setIn, fromJS, plainDeepEqual } from 'utils'
import { startCase } from 'lodash'
import { BASE_INFORM_URL } from 'services/constants'

export const resetLineItemProducts = state => {
  state = setIn(state, 'fields.productDetails', fromJS({}))
  state = setIn(state, 'values.productDetails', fromJS({}))

  state = setIn(state, 'fields.lineItems.rowData', fromJS([]))
  state = setIn(state, 'values.lineItems', fromJS([]))

  state = setIn(state, 'ui.lineItemsTablePage', 0)
  state = setIn(state, 'ui.lineItemsTableRowsPerPage', 5)
  state = setIn(state, 'ui.lineItemsExpandedGroups', fromJS([]))
  state = setIn(state, 'additionalDataMap', fromJS({}))

  return state
}

export const productDetailFields = [
  'netPrice',
  'warehousePicked',
  'quantity',
  'quantityOrdered',
  'quantityOpen',
  'quantityBO',
  'quantityStaged',
  'quantityShipped',
  'productInternalNotes',
  'redAlert',
  'uomId'
]

export const metaData = {
  suppressDuplicateProductMessageInSalesOrder: '',
  showShipToDollarLimit: true,
  isCanadianCompanyOrHasHawaiianTax: false,
  requirePasswordForWriter: true,
  useMultiplierForDiscount: false,
  showOneTimePrice: true,
  showDisableMarginPrice: true,
  showCompetitivePricesInSO: false,
  showContractPrices: true,
  allowQuoteCreateContract: true,
  canCreateFastCustomer: true,
  showCart: true,
  shipItemAsDisplay: 'Print As Item',
  showShipItemAs: true,
  useProjectLineNumbers: true,
  freightTermOptions: [
    {
      dataId: 'P',
      description: 'Prepaid'
    },
    {
      dataId: 'C',
      description: 'Collect'
    },
    {
      dataId: 'B',
      description: 'Prepaid And Bill'
    },
    {
      dataId: 'T',
      description: 'Third Party'
    }
  ],
  taxableOptions: [
    {
      dataId: 'Y',
      description: 'Yes'
    },
    {
      dataId: 'N',
      description: 'No'
    }
  ],
  statusOptions: [
    {
      dataId: 'O',
      description: 'Open Order'
    },
    {
      dataId: 'B',
      description: 'Backorder'
    },
    {
      dataId: 'S',
      description: 'Ship Confirmed'
    },
    {
      dataId: 'X',
      description: 'Signed'
    },
    {
      dataId: 'T',
      description: 'Truck Scheduled'
    },
    {
      dataId: 'D',
      description: 'Drop Shipment'
    },
    {
      dataId: 'P',
      description: 'Printed'
    },
    {
      dataId: 'F',
      description: 'Sent Fax/Email'
    },
    {
      dataId: 'I',
      description: 'In Billing'
    },
    {
      dataId: 'H',
      description: 'Credit Hold'
    },
    {
      dataId: 'C',
      description: 'Completed'
    },
    {
      dataId: 'N',
      description: 'Cancelled'
    },
    {
      dataId: '$',
      description: 'P.O.S.'
    },
    {
      dataId: 'W',
      description: 'Order w/Deposit'
    },
    {
      dataId: 'Q',
      description: 'Quoted'
    },
    {
      dataId: 'V',
      description: 'Quote Converted'
    },
    {
      dataId: 'R',
      description: 'Recurring Order'
    },
    {
      dataId: 'E',
      description: 'Expired Quote'
    },
    {
      dataId: 'A',
      description: 'Assigned Pick By'
    },
    {
      dataId: 'U',
      description: 'Ready for Pickup'
    }
  ],
  assemblyRollupOptions: [
    {
      dataId: 'N',
      description: 'None'
    },
    {
      dataId: 'P',
      description: 'Price'
    },
    {
      dataId: 'C',
      description: 'Cost'
    },
    {
      dataId: 'B',
      description: 'Both'
    }
  ],
  commissionableOptions: [
    {
      dataId: 'Y',
      description: 'Yes'
    },
    {
      dataId: 'N',
      description: 'No'
    },
    {
      dataId: 'S',
      description: 'Split'
    }
  ],
  recurringIntervalOptions: [
    {
      dataId: 'W',
      description: 'Weekly'
    },
    {
      dataId: 'B',
      description: 'Bi-Weekly'
    },
    {
      dataId: 'M',
      description: 'Monthly'
    },
    {
      dataId: 'Q',
      description: 'Quarterly'
    },
    {
      dataId: 'S',
      description: 'Semi-annually'
    },
    {
      dataId: 'A',
      description: 'Annually'
    },
    {
      dataId: 'L',
      description: 'Line Item Scheduled'
    },
    {
      dataId: 'D',
      description: 'Custom Frequency'
    }
  ],
  recurringWeeklyOptions: [
    {
      dataId: '0',
      description: 'Sunday'
    },
    {
      dataId: '1',
      description: 'Monday'
    },
    {
      dataId: '2',
      description: 'Tuesday'
    },
    {
      dataId: '3',
      description: 'Wednesday'
    },
    {
      dataId: '4',
      description: 'Thursday'
    },
    {
      dataId: '5',
      description: 'Friday'
    },
    {
      dataId: '6',
      description: 'Saturday'
    }
  ],
  afterPrintingInSOSetFormForNew: true,
  disableAdvancedParsing: false,
  signatureCapturePrintedNameRequired: true,
  creditCardOptions: [
    {
      dataId: 'Process',
      description: 'Use Processor'
    },
    {
      dataId: 'A',
      description: 'American Express'
    },
    {
      dataId: 'V',
      description: 'Visa'
    },
    {
      dataId: 'M',
      description: 'Master Card'
    },
    {
      dataId: 'D',
      description: 'Discover'
    }
  ],
  useCreditCardProcessing: true,
  defaultTemplates: [],
  indexSearchFields: {
    customer: {
      fieldName: 'Customer',
      searchType: 'Customer',
      hideToggle: false,
      hasFilters: true,
      allowSearchAll: false,
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Customer Master',
      isSecure: false
    },
    shipTo: {
      fieldName: 'Ship To',
      searchType: 'ShipTo',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: false,
      allowInstantSearch: false,
      minimumKeywordLength: 2,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Ship To Master',
      isSecure: false
    },
    orderedBy: {
      fieldName: 'Ordered By',
      searchType: 'Contacts',
      parentType: 'All',
      hideToggle: false,
      hasFilters: true,
      allowSearchAll: false,
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Contact Master',
      isSecure: false
    },
    branch: {
      fieldName: 'Branch',
      searchType: 'Branch',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Branch Master',
      isSecure: false
    },
    warehouse: {
      fieldName: 'Whse',
      searchType: 'Warehouse',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Warehouse Master',
      isSecure: false
    },
    writer: {
      fieldName: 'Writer',
      searchType: 'SystemUsers',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: false,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: null,
      isSecure: true
    },
    salesman: {
      fieldName: 'Salesman',
      searchType: 'Salesman',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Salesman Master',
      isSecure: false
    },
    terms: {
      fieldName: 'Terms',
      searchType: 'ARTerms',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open A/R Terms Master',
      isSecure: false
    },
    taxCode: {
      fieldName: 'Tax Code',
      searchType: 'Tax',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Customer Tax',
      isSecure: false
    },
    orderType: {
      fieldName: 'Order Type',
      searchType: 'OrderType',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Order Type Master',
      isSecure: false
    },
    specialInstructions: {
      fieldName: 'Special Instructions',
      searchType: 'SalesOrderSpecialInstructions',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: null,
      isSecure: false
    },
    priceContract: {
      fieldName: 'Price Contract',
      searchType: 'PriceContract',
      hideToggle: true,
      hasFilters: false,
      allowSearchAll: true,
      allowInstantSearch: true,
      minimumKeywordLength: 0,
      allowNavigation: false,
      allowSpecialProducts: false,
      openText: 'Open Price Contract',
      isSecure: false
    },
    productNumber: {
      fieldName: 'Product Number',
      searchType: 'Product',
      hideToggle: false,
      hasFilters: true,
      allowSearchAll: false,
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowNavigation: false,
      allowSpecialProducts: true,
      openText: '',
      isSecure: false
    }
  },
  isSingleInstance: false,
  maxInstances: 5,
  guid: '1759b715-06eb-4271-b781-b11383c3e3a5'
}

export const fakeMetaData = {
  allowInvalidValues: true,
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: false,
  hasFilters: false,
  hideToggle: true,
  minimumKeywordLength: 0,
  openText: null
}

export const styles = {
  labelStyle: {
    color: '#517b9c',
    fontSize: 10,
    margin: 0
  },
  inputRowStyle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginBottom: 15
  },
  subtitleHeadingStyle: {
    color: '#517b9c',
    fontSize: 10,
    margin: '0 0 20px 0',
    textAlign: 'center',
    textTransform: 'uppercase'
  }
}

export const PaymentButton = withStyles({
  root: {
    backgroundColor: '#389123'
  }
})(Button)

export const PaymentCancellationButton = withStyles({
  root: {
    backgroundColor: '#d9534f'
  }
})(Button)

export const buttonIsDisabled = memoize((payment, editing) => {
  if (!payment || !editing) {
    return true
  }

  return false
})

export const paymentContactMeta = {
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: true,
  allowSpecialProducts: false,
  hasFilters: false,
  hideToggle: true,
  isSecure: false,
  minimumKeywordLength: 2,
  openText: 'Open Contact Master',
  parentType: 'All',
  searchType: 'Contacts'
}

export const updateProductDetailsFromAPI = (result, lineNumber, record) => {
  const lineItems = getIn(result, 'fields.lineItems.rowData')
  if (lineItems && record?.detail?.lineItems) {
    const data =
      record.detail.lineItems.find(x => x.lineNumber === lineNumber) || {}

    if (Object.keys(data).length) {
      for (const prop in data) {
        if (productDetailFields.includes(prop)) {
          /* set the prevVal for comparison purposes */
          result = setIn(
            result,
            `fields.productDetails.${prop}.prevValue`,
            fromJS(getIn(result, `fields.productDetails.${prop}.value`))
          )
          result = setIn(
            result,
            `fields.productDetails.${prop}.value`,
            fromJS(data[prop])
          )
          result = setIn(
            result,
            `values.productDetails.${prop}`,
            fromJS(data[prop])
          )
        }
      }
    }
  }

  return result
}

/* for formatting non-standard Index Search dropdown grids */
export const dropdownColumnFormatter = (col, i) => {
  col = {
    ...col
  }

  const width75Fields = ['whse', 'intls', 'type', 'contract', 'shipVia', 'tax']
  const descriptionFields = [
    'location',
    'system User Name',
    'order Type Description',
    'description',
    'jurisdiction'
  ]

  if (col.field && width75Fields.includes(col.field)) {
    col = {
      ...col,
      width: 75,
      maxWidth: 125
    }
  }

  if (col.field && descriptionFields.includes(col.field)) {
    col = {
      ...col,
      width: 100,
      minWidth: 100,
      maxWidth: 600
    }
  }

  return col
}

export const immutableObjToJS = (obj, defaultValue) =>
  obj?.toJS ? obj.toJS() : defaultValue || obj

export const getPageRowData = memoize(
  (rowData = [], page, rowsPerPage) =>
    rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
  plainDeepEqual
)

export const getProductImageFromDataMap = (additionalDataMap = {}, rowId) =>
  additionalDataMap &&
  typeof additionalDataMap === 'object' &&
  additionalDataMap !== null &&
  rowId &&
  additionalDataMap[rowId] &&
  additionalDataMap[rowId].image
    ? additionalDataMap[rowId].image
    : null

export const getImageUrl = memoize((image = {}) => {
  const baseString = `${BASE_INFORM_URL}/resources/no_image.jpg`
  if (!image) {
    return baseString
  }

  if (image.base64String) {
    return image.base64String
  }

  if (image.url) {
    return image.url
  }

  return baseString
}, plainDeepEqual)

export const saveSetupRecord = (result, setup) => {
  if (
    typeof setup === 'object' &&
    setup !== null &&
    Object.keys(setup).length
  ) {
    for (const prop in setup) {
      if (Array.isArray(setup[prop])) {
        result = setIn(
          result,
          `values.fastContact.${prop}`,
          fromJS(setup[prop])
        )
      } else {
        result = setIn(result, `values.fastContact.${prop}`, setup[prop])
        result = setIn(result, `fields.fastContact.${prop}.value`, setup[prop])
      }
    }
  }

  return result
}
