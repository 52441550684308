/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React, { Component, memo } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Popover, Button, Icon, IconButton } from '@material-ui/core'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import { updateGridCellData as updateGridCellDataAction } from 'components/EditableGrid/actions'
import { DDINumberSpinner } from 'ddiForm/wrapped'
import { setField } from 'ddiForm/actions'
import NumberSpinner from 'ddiForm/NumberSpinner'
import { formatNumber, getIn } from 'utils'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const openSubTotal = getIn(formState, 'values.openSubTotal')
  const isEditing = getIn(formState, 'isEditing') || false

  return {
    openSubTotal,
    isEditing
  }
}

class MiscChargeCalculator extends Component {
  constructor(props) {
    super(props)
    // console.log(props)
    this.state = {
      anchorEl: null,
      result: 0,
      spinnerVal: 0
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.miscChargeSpinner / 100 * nextProps.openSubTotal !== prevState.result) {
  //     return {
  //       result: nextProps.miscChargeSpinner / 100 * nextProps.openSubTotal
  //     }
  //   }

  //   return null
  // }

  onNumberSpinnerChange = debounce(newValue => {
    const result = (newValue / 100) * this.props.openSubTotal

    this.setState({
      spinnerVal: newValue,
      result
    })
  }, 500)

  handleOpen = event => {
    this.setState({
      anchorEl: event.currentTarget,
      spinnerVal: 0,
      result: 0
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
      spinnerVal: 0,
      result: 0
    })
  }

  updateInputAmount = () => {
    const { result } = this.state
    const {
      form,
      rowIndex,
      data: { rowId },
      propertyName,
      colDef: { field }
    } = this.props
    // const pct = spinnerVal / 100

    this.props.dispatch(
      updateGridCellDataAction(form, {
        propertyName,
        rowIndex,
        rowId,
        field,
        value: formatNumber(result, '0,0.00'),
        generateDataId: false,
        lastCell: false,
        requiresAdditionalValidation: false
      })
    )

    this.setState({
      anchorEl: null,
      spinnerVal: 0,
      result: 0
    })
  }

  render() {
    const { anchorEl, result, spinnerVal } = this.state
    const {
      isEditing,
      openSubTotal,
      data: { dataId }
    } = this.props

    const open = Boolean(anchorEl)
    const labelStyle = { minWidth: 50, marginRight: 10, marginBottom: 0 }
    const paragraphStyle = { marginBottom: 0 }
    const flexStyle = {
      display: 'flex',
      marginBottom: 10,
      paddingBottom: 10,
      borderBottom: '1px solid #cecece'
    }

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            zIndex: 1,
            width: '100$',
            height: '100%'
          }}
        >
          <TextFieldGridCell {...this.props} />
        </div>
        <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 2 }}>
          <IconButton
            onClick={this.handleOpen}
            size="small"
            disabled={!dataId || !isEditing}
          >
            <Icon>keyboard_arrow_down</Icon>
          </IconButton>
          <Popover open={open} anchorEl={anchorEl} onClose={this.handleClose}>
            <div style={{ padding: 10 }}>
              <div style={flexStyle}>
                <label style={labelStyle}>Total:</label>
                <p style={paragraphStyle}>{formatNumber(openSubTotal)}</p>
              </div>
              <div style={flexStyle}>
                <label style={labelStyle}>Percent:</label>
                <NumberSpinner
                  initialValue={0}
                  step={1}
                  max={99.99}
                  inputProps={{
                    decimalScale: 2,
                    fontSize: 13,
                    fixedDecimalScale: true,
                    suffix: '%'
                  }}
                  wrapperStyle={{ margin: 0 }}
                  isField={false}
                  updateValueCallback={this.onNumberSpinnerChange}
                />
              </div>
              <div style={flexStyle}>
                <label style={labelStyle}>Result:</label>
                <p style={paragraphStyle}>{formatNumber(result)}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={this.updateInputAmount} variant="contained">
                  OK
                </Button>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(MiscChargeCalculator)
