/* eslint react/sort-comp: 0 */
import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { getIn, emptyList, plainDeepEqual } from 'utils'
import EditableGrid from 'components/EditableGrid'
import { addEditableGridRow } from 'pages/SalesOrder/actions'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
// import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import TooltipField from 'hoc/TooltipField'
import MiscChargeCalculator from './MiscChargeCalculator'
import MiscChargeCalculatorHeader from './MiscChargeCalculatorHeader'
import DeleteGridRowCell from './DeleteGridRowCell'

class MiscChargeGrid extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    isEditing: PropTypes.bool.isRequired,
    miscellaneousCharges: PropTypes.array.isRequired
  }

  static defaultProps = {
    isMobile: false
  }

  getColumnDefs = memoize(
    ({
      isEditing,
      focusedCell,
      form,
      onCellChange,
      propertyName,
      isMobile
    }) => {
      //
      const params = {
        disabled: !isEditing,
        focusedCell,
        form,
        onChange: onCellChange,
        propertyName
      }

      const textEditor = {
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: params
      }

      const colDefs = [
        {
          cellRendererFramework: TooltipField({
            anchorToGridCellText: true,
            indexSearchType: 'miscCharge',
            position: 'right-end',
            recordNameKey: 'value',
            sensitivity: 100,
            type: 'indexSearch'
          })(IndexSearchCell),
          cellRendererParams: {
            ...params,
            indexSearchType: 'miscCharge',
            isMobile,
            meta: {
              allowInstantSearch: true,
              minimumKeywordLength: 2,
              allowSearchAll: true
            }
          },
          field: 'dataId',
          headerName: 'Charge',
          cellStyle: isMobile ? { paddingLeft: 0, paddingRight: 0 } : null,
          minWidth: isMobile ? 125 : 150
        },
        {
          field: 'description',
          headerName: 'Description',
          cellStyle: { color: isEditing ? '#444' : '#999' },
          suppressNavigable: true,
          minWidth: isMobile ? 75 : 100
        },
        {
          cellRendererFramework: MiscChargeCalculator,
          cellRendererParams: {
            ...params,
            formatter: 'number',
            fixedDecimalScale: true,
            decimalScale: 2
          },
          field: 'amount',
          headerComponentFramework: MiscChargeCalculatorHeader,
          headerName: 'Amount',
          minWidth: 100
        },
        {
          field: 'taxable',
          headerClass: 'text-center',
          headerName: 'Tx?',
          suppressMenu: true,
          minWidth: 80,
          cellRendererFramework: ToggleCheckboxCell,
          cellRendererParams: {
            ...params
          }
        },
        {
          cellRendererFramework: DeleteGridRowCell,
          cellRendererParams: {
            ...params,
            dispatch: this.props.dispatch
          },
          hide: !isEditing,
          minWidth: 60,
          maxWidth: 60
        }
      ]

      return colDefs
    }
  )

  addBlankRow = () => {
    const { dispatch, form } = this.props

    dispatch(addEditableGridRow(form, { propertyName: 'miscellaneousCharges' }))
  }

  getGridHeight = memoize((rowData = []) => {
    let height = 150
    if (!rowData.length) {
      return height
    }

    height = rowData.length * 28 + 100
    return height
  }, plainDeepEqual)

  render() {
    const { form, isEditing, miscellaneousChargesList } = this.props
    const miscellaneousCharges = miscellaneousChargesList?.toJS
      ? miscellaneousChargesList.toJS()
      : []

    return (
      <div style={{ paddingBottom: 35 }}>
        <EditableGrid
          propertyName="miscellaneousCharges"
          addBlankRow={this.addBlankRow}
          form={form}
          // height={150}
          height={this.getGridHeight(miscellaneousCharges)}
          emptyRow={{
            dataId: null,
            description: '',
            amount: 0,
            taxable: false
          }}
          colParams={{ isMobile: this.props.isMobile }}
          addButtonText="Add Misc Charge"
          focusCellOnNewRow="dataId"
          focusCell="amount"
          columnDefs={this.getColumnDefs}
          requiredCols={['dataId']}
          isEditing={isEditing}
        />
      </div>
    )
  }
}

const contextObj = {
  isEditing: form => form.get('isEditing') || false,
  miscellaneousChargesList: form =>
    getIn(form, 'fields.miscellaneousCharges.rowData') || emptyList
}

/* fixed 9/25/2020 SVE */
export default memo(withContext(contextObj)(MiscChargeGrid))
