import { take, put, fork } from 'redux-saga/effects'

export function* cellChangedProcess(action) {
  const {
    payload: {
      propertyName,
      newValue,
      oldValue,
      colDef: { field }
    },
    meta: { form }
  } = action

  yield put({
    type: 'CELL_CHANGED_COMPLETE',
    payload: action.payload,
    meta: { form, reducer: 'Grid' }
  })
}

export function* cellChangedListener(formListener) {
  while (true) {
    const action = yield take('CELL_CHANGED')
    const {
      meta: { form }
    } = action
    if (form === formListener) {
      yield fork(cellChangedProcess, action)
    }
  }
}
export default function* sagas(form) {
  yield fork(cellChangedListener, form)
}
