import React from 'react'
import PropTypes from 'prop-types'
import Grid from 'grid'
import { headerStyle } from 'pages/ProductMaster/utils'


export const getRowNodeId = data => data.id

const columnDefs = [
  { field: 'level', headerName: 'Level' },
  { field: 'price', headerName: 'Price' }
]

const PriceBreaksGrid = ({ data }) => (
  <Grid
    title="Price Breaks"
    getRowNodeId={getRowNodeId}
    headerStyle={headerStyle}
    rowData={data}
    columnDefs={columnDefs}
    width="100%"
    height={'10%'}
    fullWidth
  />
)

PriceBreaksGrid.propTypes = {
  data: PropTypes.array.isRequired
}

export default PriceBreaksGrid
