import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

export const runReport = (data, form) => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/run`, options)
}

export const readReport = (data, form) => {
  const options = createApiOptions({
    body: data,
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/read`, options)
}

export const reportPropertyChange = (data, form) => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/propertychange`, options)
}

export const closeSalesOrderReport = (data, form) => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/close`, options)
}
