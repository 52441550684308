import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Label } from 'ddiForm'
import memoize from 'memoize-one'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import {
  addNumberFormat,
  headerStyle,
  setGridHeight
} from 'pages/ProductMaster/utils'
import { formatNumber, getIn } from 'utils'
import { updateDemand as updateDemandAction } from 'pages/ProductMaster/actions'

import '../styles/master-style.scss'

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  const demand = getIn(formState, 'fields.updateDemand.demand.rowData')
  const productDescription = getIn(
    formState,
    'fields.updateDemand.productDescription.value'
  )
  const selectedWarehouseId = getIn(
    formState,
    'fields.selectedWarehouseId.value'
  )
  const uom = getIn(formState, 'fields.updateDemand.uom.value')
  const warehouseDescription = getIn(
    formState,
    'fields.updateDemand.warehouseDescription.value'
  )

  return {
    demand: demand && demand.toJS ? demand.toJS() : [],
    productDescription: productDescription || '',
    selectedWarehouseId: selectedWarehouseId || '',
    uom: uom || '',
    warehouseDescription: warehouseDescription || ''
  }
}

const mapDispatchToProps = dispatch => ({
  updateDemand: form => dispatch(updateDemandAction.try(form))
})

const numberCellStyle = {
  color: '#000',
  justifyContent: 'flex-end',
  textAlign: 'right'
}

export const getFormattedData = (data, field) => {
  const format = data.month ? '0,0.[000]' : '0,0.[0000]'
  return formatNumber(data[field], format)
}

export const dynamicColumnDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: false,
      focusedCell,
      form,
      onChange: onCellChange,
      propertyName
    }

    const textEditor = {
      cellRendererFramework: TextFieldGridCell,
      cellRendererParams: {
        ...params,
        allowNegative: true,
        noDecimalLimit: true,
        formatter: 'number',
        textAlign: 'right',
        thousandSeperator: true
      }
    }

    const colDefs = [
      {
        field: '',
        headerName: '',
        children: [
          {
            cellStyle: ({ value }) => (value < 0 ? { color: 'red' } : null),
            field: 'month',
            headerName: 'Month',
            filter: false,
            suppressMenu: true
          }
        ],
        filter: false,
        suppressMenu: true
      },
      {
        field: 'twelveMonth',
        headerClass: 'centered-label',
        headerName: '12 Month',
        width: 100,
        children: [
          {
            ...textEditor,
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twelveMonthDemand',
            headerClass: 'numeric-value-label',
            headerName: 'Demand',
            filter: false,
            suppressMenu: true,
            valueGetter: ({ data }) =>
              getFormattedData(data, 'twelveMonthDemand')
          },
          {
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twelveMonthSales',
            headerClass: 'numeric-value-label',
            headerName: 'Sales',
            filter: false,
            suppressMenu: true,
            valueFormatter: ({ value }) => addNumberFormat(value)
          },
          {
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twelveMonthHits',
            headerClass: 'numeric-value-label',
            headerName: 'Hits',
            filter: false,
            suppressMenu: true,
            valueFormatter: ({ value }) => addNumberFormat(value)
          }
        ]
      },
      {
        field: 'twentyFourMonth',
        headerClass: 'centered-label',
        headerName: '24 Month',
        children: [
          {
            ...textEditor,
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twentyFourMonthDemand',
            headerClass: 'numeric-value-label',
            headerName: 'Demand',
            filter: false,
            suppressMenu: true,
            valueGetter: ({ data }) =>
              getFormattedData(data, 'twentyFourMonthDemand')
          },
          {
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twentyFourMonthSales',
            headerClass: 'numeric-value-label',
            headerName: 'Sales',
            filter: false,
            suppressMenu: true,
            valueFormatter: ({ value }) => addNumberFormat(value)
          },
          {
            cellStyle: ({ value }) =>
              value < 0
                ? { color: 'red', textAlign: 'right' }
                : numberCellStyle,
            field: 'twentyFourMonthHits',
            headerClass: 'numeric-value-label',
            headerName: 'Hits',
            filter: false,
            suppressMenu: true,
            valueFormatter: ({ value }) => addNumberFormat(value)
          }
        ]
      }
    ]

    return colDefs
  }
)

const getBottomData = memoize(nuDemand => {
  const result = []

  const total = nuDemand.reduce(
    (acc, next) => {
      acc.twelveMonthDemand += next.twelveMonthDemand
      acc.twelveMonthSales += next.twelveMonthSales
      acc.twelveMonthHits += next.twelveMonthHits
      acc.twentyFourMonthDemand += next.twentyFourMonthDemand
      acc.twentyFourMonthSales += next.twentyFourMonthSales
      acc.twentyFourMonthHits += next.twentyFourMonthHits

      return acc
    },
    {
      twelveMonthSales: 0,
      twelveMonthDemand: 0,
      twelveMonthHits: 0,
      twentyFourMonthDemand: 0,
      twentyFourMonthSales: 0,
      twentyFourMonthHits: 0
    }
  )

  result.push(total)

  return result
})

// Keep as class component
export class UpdateDemandModal extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    demand: PropTypes.array.isRequired,
    selectedWarehouseId: PropTypes.string.isRequired,
    warehouseDescription: PropTypes.string.isRequired,
    uom: PropTypes.string.isRequired
  }

  render() {
    const {
      data,
      demand,
      productDescription,
      selectedWarehouseId,
      warehouseDescription,
      uom
    } = this.props

    // console.log('updateDemandModal', this.props)

    return (
      <>
        <div className="two-col-flex-wrapper" style={{ marginBottom: 10 }}>
          <div className="modal-search-label">Product:</div>
          <div style={{ width: 150, paddingLeft: '5px' }}>
            <DDIIndexSearch
              propertyName="updateDemand.dataId"
              disableLastSearchButton
              fullWidth
              meta={{
                allowInstantSearch: true,
                allowNavigation: true,
                allowSearchAll: false,
                hasFilters: false,
                searchType: 'Product'
              }}
              parentType="all"
              preventAutoDisable
              popoverStyle={{
                width: '175%'
              }}
              tooltip={false}
            />
          </div>
          <Label
            initialValue={productDescription}
            labelStyle={{ width: '100%' }}
          />
        </div>
        <div className="two-col-flex-wrapper" style={{ marginBottom: 10 }}>
          <div className="modal-search-label">Warehouse:</div>
          <div style={{ width: 146.8 }}>
            <DDIIndexSearch
              propertyName="updateDemand.selectedWarehouseId"
              fullWidth
              meta={{
                allowInstantSearch: true,
                allowNavigation: false,
                allowSearchAll: true,
                searchType: 'warehouse'
              }}
              initialValue={selectedWarehouseId}
              preventAutoDisable
            />
          </div>
          <Label
            initialValue={warehouseDescription}
            labelStyle={{ width: '100%' }}
          />
        </div>
        <div className="two-col-flex-wrapper">
          <div className="modal-search-label">Purchase UOM:</div>
          <span className="modal-search-description">{uom}</span>
        </div>
        <EditableGrid
          propertyName="updateDemand.demand"
          form={data.form}
          columnDefs={dynamicColumnDefs}
          title="Demand"
          headerStyle={headerStyle}
          height={setGridHeight(data.demand, 83)}
          pinnedBottomRowData={getBottomData(demand)}
        />
      </>
    )
  }
}

export const contextObj = {
  demand: form =>
    form.fields.updateDemand &&
    form.fields.updateDemand.demand &&
    form.fields.updateDemand.demand.rowData
      ? form.fields.updateDemand.demand.rowData
      : [],
  productDescription: form =>
    form.fields.updateDemand &&
    form.fields.updateDemand.productDescription &&
    form.fields.updateDemand.productDescription.value
      ? form.fields.updateDemand.productDescription.value
      : '',
  selectedWarehouseId: form =>
    form.fields.selectedWarehouseId && form.fields.selectedWarehouseId.value
      ? form.fields.selectedWarehouseId.value
      : '',
  uom: form =>
    form.fields.updateDemand &&
    form.fields.updateDemand.uom &&
    form.fields.updateDemand.uom.value
      ? form.fields.updateDemand.uom.value
      : '',
  warehouseDescription: form =>
    form.fields.updateDemand &&
    form.fields.updateDemand.warehouseDescription &&
    form.fields.updateDemand.warehouseDescription.value
      ? form.fields.updateDemand.warehouseDescription.value
      : ''
}

// export default withContext(contextObj)(UpdateDemandModal)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(UpdateDemandModal)
