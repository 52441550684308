import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import memoize from 'memoize-one'
import { fromJS, getIn, is } from 'utils'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'
import IndexSearchRenderer from 'pages/SalesOrder/components/ManualGrid/IndexSearchRenderer'
import TextFieldEditorCell from 'pages/SalesOrder/components/ManualGrid/TextFieldEditorCell'
import IndexSearchEditor from 'pages/SalesOrder/components/ManualGrid/IndexSearchEditor'
import { triggerShippingGridBulkChange } from 'pages/SalesOrder/actions'

const frameWorkComponents = {
  indexSearchEditor: IndexSearchEditor,
  textFieldEditor: TextFieldEditorCell
}

let _isMounted = false

const Shipping = props => {
  const dispatch = useDispatch()

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const getColumnDefs = memoize(
    ({ customerId, hasRecord, isEditing, focusedCell, form, onCellChange }) => {
      const hideToggle = true
      const showCart = false
      const inquiryMode = Boolean(hasRecord && !isEditing)
      const editableIfCanChangeQuantityNoInquiryMode = p => {
        return p.data && p.data.canChangeQuantity && !inquiryMode
      }

      const editorParams = {
        disabled: inquiryMode,
        focusedCell,
        form,
        propertyName: 'lineItems',
        onChange: onCellChange,
        hasRecord,
        isEditing
      }

      const colDefs = [
        {
          field: 'lineNumber',
          colId: 'lineNumber',
          headerName: 'lineNumber',
          filter: false,
          cellStyle: { 'text-align': 'left' },
          minWidth: 50,
          maxWidth: 100,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          headerValueGetter: () => 'Ln'
        },
        {
          cellRenderer: IndexSearchRenderer,
          cellEditor: 'indexSearchEditor',
          cellClass: 'line-items-product',
          cellEditorParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            form,
            propertyName: 'lineItems',
            hideToggle,
            tabStop: true,
            disableAdvancedParsing: true,
            showCart,
            customerId
          },
          cellRendererParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            form,
            dispatch
          },
          field: 'dataId',
          colId: 'dataId',
          headerName: 'Product Number',
          minWidth: 150,
          maxWidth: 200,
          width: 150,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          editable: false
        },
        {
          field: 'description',
          colId: 'description',
          headerName: 'Product Description',
          minWidth: 150,
          maxWidth: 200,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          tabStop: true,
          editable: false
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right', color: '#000' }
          },
          field: 'quantityOrdered',
          colId: 'quantityOrdered',
          tooltipComponent: 'customTooltip',
          tooltipField: 'quantityOrderedTooltip',
          headerName: 'Quant Open',
          minWidth: 100,
          maxWidth: 200,
          width: 125,
          suppressMenu: true,
          editable: false,
          pinned: 'left',
          lockPinned: true
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          headerClass: 'align-right',
          field: 'quantity',
          colId: 'quantity',
          headerName: 'Quant To Ship',
          minWidth: 100,
          width: 150,
          editable: editableIfCanChangeQuantityNoInquiryMode,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName: 'lineItems',
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          }
        },
        {
          field: 'quantityBO',
          colId: 'quantityBO',
          headerName: 'Quant B/O',
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          minWidth: 100,
          width: 125,
          editable: editableIfCanChangeQuantityNoInquiryMode,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName: 'lineItems',
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          }
        }
      ]

      return colDefs
    }
  )

  const columnDefs = getColumnDefs({
    customerId: props.customerId,
    hasRecord: props.hasRecord,
    isEditing: props.isEditing,
    focusedCell: null,
    form: props.form,
    onCellChange: props.onCellChange
  })

  const onCellValueChanged = params => {
    //
    if (
      params.colDef.field !== 'inventory' &&
      params.newValue != null
      //  &&
      // !isEqual(params.newValue, params.oldValue)
    ) {
      dispatch({
        type: 'CELL_CHANGED',
        payload: {
          ...params,
          propertyName: 'lineItems'
        },
        meta: { form: props.form }
      })
    }
  }

  const onClickShippingGridButton = debounce(
    (e, action) =>
      dispatch(
        triggerShippingGridBulkChange(props.form, {
          action
        })
      ),
    300
  )

  const onRowDataUpdated = params => {
    // console.log('onRowDataUpdated', params)
    if (params?.api && _isMounted) {
      setTimeout(() => {
        params.api.sizeColumnsToFit()
      }, 0)
    }
  }

  const hasLineItems =
    props?.lineItems?.size &&
    props?.lineItems?.get &&
    props?.lineItems?.get(0)?.get('rowId') !== 'blankrow'

  const buttonsDisabled = !(hasLineItems && props.isEditing)

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative'
      }}
    >
      <div style={{ paddingBottom: 45, width: '100%' }}>
        <ManualGrid
          // onCellKeyDown={onCellKeyDown}
          isSecondaryManualGrid
          columnDefs={columnDefs}
          immutableData
          dispatch={dispatch}
          form={props.form}
          frameworkComponents={frameWorkComponents}
          getRowNodeId={data => data.rowId}
          height={300}
          onCellValueChanged={onCellValueChanged}
          propertyName="lineItems"
          showAddButtonIfNoRecordOrEditing={false}
          suppressColumnVirtualisation
          suppressRowClickSelection
          skipValidationOnUnmount
          singleClickEdit
          title="Line Items"
          applyColumnDefOrder
          isExternalFilterPresent={() => true}
          doesExternalFilterPass={rowNode =>
            rowNode?.data?.rowId !== 'blankrow'
          }
          onRowDataUpdated={onRowDataUpdated}
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          left: 0,
          background: '#e9e9e9',
          padding: 10
        }}
      >
        <Button
          variant="contained"
          size="small"
          style={{ marginRight: 10 }}
          onClick={e => onClickShippingGridButton(e, 'shipall')}
          disabled={buttonsDisabled}
        >
          Ship All
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={e => onClickShippingGridButton(e, 'backorderall')}
          disabled={buttonsDisabled}
        >
          B/O All
        </Button>
      </div>
    </div>
  )
}

export default withContext({
  hasRecord: form => getIn(form, 'hasRecord') || false,
  isEditing: form => getIn(form, 'isEditing') || false,
  customerId: form => getIn(form, 'fields.customerId.value') || '',
  lineItems: form => getIn(form, 'fields.lineItems.rowData') || fromJS([])
})(Shipping)
