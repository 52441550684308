/* eslint react/jsx-no-duplicate-props: 0 */
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
  DDIIndexSearch,
  DDIToggle,
  DDITextField,
  DDISelectField
} from 'ddiForm/wrapped'
import { Label } from 'ddiForm'

import {
  Icon,
  InputAdornment,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel
} from '@material-ui/core'
import moment from 'moment'
import { numToDollar, getIn } from 'utils'

export const mapStateToProps = state => ({
  accessLevel: getIn(state, 'auth.accessLevelsDisplay')
})

// export const mapDispatchToProps = dispatch => ({
//   callAccessOverrideModal: () =>
//     dispatch(actions.callAccessOverrideModal('eventModal'))
// })

export class SalesHistoryReportModal extends Component {
  static propTypes = {
    accessLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    callAccessOverrideModal: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      printMostRecentSalesOnly: true,
      printPrices: true,
      printHistoryGrossProfit: false,
      printHiddenProducts: false
    }
  }

  onToggleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  onSelectChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    const {
      printMostRecentSalesOnly,
      printPrices,
      printHistoryGrossProfit,
      printHiddenProducts
    } = this.state

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'center'
          }}
        >
          <Paper style={{ marginRight: 5, padding: 20 }} elevation={4}>
            <h2
              style={{
                fontSize: 14,
                margin: '0 0 15px 0',
                textAlign: 'center'
              }}
            >
              Print
            </h2>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={printMostRecentSalesOnly}
                    onChange={this.onToggleChange('printMostRecentSalesOnly')}
                    value="printMostRecentSalesOnly"
                  />
                }
                label="Most Recent Sales Only"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={printPrices}
                    onChange={this.onToggleChange('printPrices')}
                    value="printPrices"
                  />
                }
                label="Prices"
              />
            </div>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={printHistoryGrossProfit}
                    onChange={this.onToggleChange('printHistoryGrossProfit')}
                    value="printHistoryGrossProfit"
                  />
                }
                label="History Gross Profit"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={printHiddenProducts}
                    onChange={this.onToggleChange('printHiddenProducts')}
                    value="printHiddenProducts"
                  />
                }
                label="Hidden Products"
              />
            </div>
          </Paper>
          <Paper style={{ marginLeft: 5, padding: 20 }} elevation={4}>
            <h2
              style={{
                fontSize: 14,
                margin: '0 0 15px 0',
                textAlign: 'center'
              }}
            >
              Select
            </h2>
            <h3 style={{ fontSize: 12, margin: '15px 0 0' }}>Product Line</h3>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div style={{ width: 150, marginRight: 15 }}>
                <DDIIndexSearch
                  propertyName="salesReportPrintConfig.productLineId"
                  id="salesReportProductLine"
                  searchType="productLine"
                  meta={{
                    allowInstantSearch: true,
                    allowSearchAll: true,
                    hasFilters: false,
                    minimumKeywordLength: 2,
                    searchType: 'productLine'
                  }}
                  fullWidth
                  minWidth="100%"
                  includeParent
                  preventAutoDisable
                  label="Product Line"
                />
              </div>
              <Label
                propertyName="salesReportPrintConfig.productLineId.description"
                labelStyle={{ width: '100%' }}
              />
            </div>
            <h3 style={{ fontSize: 12, margin: '15px 0 0' }}>Vendor</h3>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <div style={{ width: 150, marginRight: 15 }}>
                <DDIIndexSearch
                  propertyName="salesReportPrintConfig.vendorId"
                  id="salesReportVendor"
                  searchType="vendor"
                  meta={{
                    allowInstantSearch: true,
                    allowSearchAll: false,
                    hasFilters: false,
                    minimumKeywordLength: 2,
                    searchType: 'vendor'
                  }}
                  fullWidth
                  minWidth="100%"
                  includeParent
                  preventAutoDisable
                  label="Vendor"
                />
              </div>
              <Label
                propertyName="salesReportPrintConfig.vendorId.description"
                labelStyle={{ width: '100%' }}
              />
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(SalesHistoryReportModal)
