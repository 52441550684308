/* eslint react/sort-comp: 0, react/jsx-no-duplicate-props: 0, react/no-find-dom-node: 0, react/no-did-mount-set-state: 0, consistent-return: 0 */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { HotKeys } from 'react-hotkeys'
// import { EventEmitter } from 'fbemitter'
import { eventChannel } from 'redux-saga'
import { getIn, plainDeepEqual, toNumeral } from 'utils'
// import { delay, throttle, debounce } from 'lodash'
import NumberFormat from 'react-number-format'
import { InputAdornment, Icon, Input, TextField } from '@material-ui/core'
import {
  getErrorIcon,
  // isDisabled,
  notifyExitTextCellFn
  // updateGridCellData
} from 'components/EditableGrid/utils'
import { debounce, once } from 'lodash'

const TAB = 9
const ENTER = 13
const ARROW_RIGHT = 39
const ARROW_LEFT = 37
const ARROW_DOWN = 40
const ARROW_UP = 38

const keyMap = {
  ALT_S: 'alt'
}
const ignoreTags = ['select', 'textarea']

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    allowLeadingZeros = true,
    handlers,
    saveCart,
    ...other
  } = props

  /*
    had to change this component to receive
    decimal props like this
  */
  const decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  const Wrapper = saveCart ? HotKeys : React.Fragment
  const wrapperArgs = saveCart ? { keyMap, handlers } : {}
  console.log(decimalFormatting)
  return (
    <Wrapper {...wrapperArgs}>
      <NumberFormat
        {...other}
        {...decimalFormatting}
        // disabled={disabled}
        id={id}
        getInputRef={inputRef}
        forwardRef
        value={value}
        onValueChange={onChange}
        onBlur={onBlur}
        prefix={prefix}
        suffix={suffix}
        style={{
          color: value < 0 ? '#d9534f' : disabled ? '#999' : '#000',
          fontSize: fontSize || '1em',
          height: '100%',
          paddingLeft: 0,
          paddingRight,
          // paddingLeft: textAlign === 'left' ? 10 : 0,
          // paddingRight: textAlign === 'right' ? 0 : 0,
          textAlign,
          width: '100%'
        }}
        thousandSeparator={thousandSeparator}
        allowNegative={allowNegative}
        maxLength={maxLength}
      />
    </Wrapper>
  )
}

export class TextFieldEditorCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    form: PropTypes.string.isRequired
  }

  _isMounted = false

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props)
    // console.log(props)
    //
    let {
      value,
      colDef: {
        cellEditorParams: { formatter }
      }
    } = this.props
    // let value
    if (props.charPress) {
      value = formatter === 'number' ? Number(props.charPress) : props.charPress
      console.log(value)
    }
    const state = {
      isEditing: false,
      focusSet: false,
      lastCellTabbed: false,
      rowIndex: props.rowIndex,
      textFieldVal: value || value === 0 ? value : ''
    }
    if (formatter === 'number' && value === 0) {
      state.textFieldVal = 0
    }

    this.state = state
    // this.emitter = new EventEmitter()
    // this.emitter.addListener('Tab', this.test)
  }

  componentDidMount() {
    this._isMounted = true
    this._isCancelAfterEndEventFired = false
  }

  afterGuiAttached() {
    const input = this.textField // props.eGridCell
    //
    if (input) {
      input.addEventListener('keydown', this.onKeyDown)
    }
    setTimeout(() => {
      if (input) {
        input.focus()
        // input.select()
        if (!this.props.charPress) {
          input.select()
        } else if (input.setSelectionRange) {
          input.setSelectionRange(1, 1)
        }
      }
    }, 0)
  }

  componentDidUpdate() {
    const input = this.textField // props.eGridCell
    // if (input) {
    //   input.addEventListener('keydown', this.onKeyDown)
    // }
    if (!this.edited && input) {
      //
      input.focus()
      input.select()
    }
  }

  componentWillUnmount() {
    //
    const input = this.textField // props.eGridCell
    this._isMounted = false
    if (input) {
      input.removeEventListener('keydown', this.onKeyDown)
    }
  }

  edited = false

  setEdited = once(() => (this.edited = true))

  onChange = event => {
    if (this._isMounted) {
      // debugger
      this.setState({
        focusSet: false,
        isEditing: true,
        lastCellTabbed: false,
        textFieldVal: event?.target?.value || ''
      })
    }
  }

  dispatchTab = (shiftKey, arrowKey) => {
    this.props.dispatch({
      type: 'GRID_TAB_ATTEMPT',
      payload: {
        ...this.props,
        shiftKey,
        arrowKey
      },
      meta: {
        form: this.props.form
      }
    })
    this.props.api.stopEditing()
  }

  onKeyDown = event => {
    if (event.persist) {
      return
    }
    const { onCellKeyDown } = this.props
    if (onCellKeyDown) {
      onCellKeyDown.call(this, event)
    } else {
      const { keyCode } = event
      if (this._isMounted) {
        const {
          target: { selectionStart, selectionEnd },
          target
        } = event
        if (keyCode === ENTER) {
          event.preventDefault()
          event.stopPropagation()
        } else if (keyCode === TAB) {
          if (this.props.propertyName === 'lineItems') {
            event.preventDefault()
            event.stopPropagation()
            this.dispatchTab(event.shiftKey)
          } else if (this.props.propertyName?.match(/lineItemComponents/)) {
            event.preventDefault()
            event.stopPropagation()
            if (this.edited) {
              this.dispatchTab(event.shiftKey)
            } else {
              this.props.api.tabToNextCell()
            }
          }
        } else if (
          keyCode === ARROW_LEFT &&
          selectionStart === 0 &&
          selectionEnd === 0
        ) {
          this.dispatchTab(true, 'left')
        } else if (
          keyCode === ARROW_RIGHT &&
          selectionStart === selectionEnd &&
          target.value.length === selectionStart
        ) {
          this.dispatchTab(false, 'right')
        } else if (keyCode === ARROW_DOWN) {
          this.dispatchTab(event.shiftKey, 'down')
        } else if (keyCode === ARROW_UP) {
          this.dispatchTab(event.shiftKey, 'up')
        } else {
          this.setEdited()
        }
      }
    }
  }
  // onKeyDown = event => {
  //   if (event.persist) {
  //     event.persist()
  //   }
  //   const { onCellKeyDown } = this.props
  //   if (onCellKeyDown) {
  //     onCellKeyDown.call(this, event)
  //   } else {
  //     const { keyCode } = event
  //     if (this._isMounted) {
  //       const {
  //         target: { selectionStart, selectionEnd },
  //         target
  //       } = event
  //       if (keyCode === ENTER) {
  //         event.preventDefault()
  //         event.stopPropagation()
  //       } else if (keyCode === TAB) {
  //         event.preventDefault()
  //         event.stopPropagation()
  //         //
  //         this.dispatchTab(event.shiftKey)
  //       } else if (
  //         keyCode === ARROW_LEFT &&
  //         selectionStart === 0 &&
  //         selectionEnd === 0
  //       ) {
  //         this.dispatchTab(true, 'left')
  //       } else if (
  //         keyCode === ARROW_RIGHT &&
  //         selectionStart === selectionEnd &&
  //         target.value.length === selectionStart
  //       ) {
  //         this.dispatchTab(false, 'right')
  //       } else {
  //         this.setEdited()
  //       }
  //     }
  //   }
  // }

  isCancelAfterEnd = async p => {
    if (!this.props.modals && this.state.textFieldVal != null) {
      try {
        this._isCancelAfterEndFired = true
        const newValue = await this.props.dispatch({
          type: 'TRY_CELL_CHANGED_REQUEST',
          meta: { form: this.props.form, thunk: true, reducer: 'Grid' },
          payload: {
            value: this.state.textFieldVal,
            propertyName: this.props.propertyName,
            field: this.props.column.colDef.field,
            rowIndex: this.props.node.childIndex,
            data: this.props.data,
            node: this.props.node
          }
        })

        if (newValue != null) {
          this.props.node.setDataValue(this.props.column.colId, newValue)
        }

        return false
      } catch (e) {
        return true
      } finally {
        this._isCancelAfterEndFired = false
      }
    }
  }

  onBlur = e => {
    const { data } = this.props

    /* 
      allow for a blur callback if necessary,
      mainly for in case the box quantity has not been triggered
      by the isCancelAfterEndFired function. fixes #8571, see
      implmentation in Order/index ('quantityOrdered') -- SVE 10/5/2020
    */
    if (this.props.onBlur && typeof this.props.onBlur === 'function') {
      this.props.onBlur(e, data, this._isCancelAfterEndFired)
    }
  }

  onValueChange = values => {
    const textFieldVal =
      typeof values.floatValue === 'number' ? values.floatValue : ''
    if (this._isMounted) {
      //
      this.setState({
        focusSet: false,
        lastCellTabbed: false,
        isEditing: true,
        textFieldVal
      })
    }
  }

  showRequiredIcon = () => {
    const {
      data,
      value,
      valueFormatted,
      colDef: {
        cellEditorParams: {
          errorMessage,
          isRequired,
          requiredFn,
          requiredIf,
          requiredLessThan
        },
        field
      },
      rowData,
      rowIndex
    } = this.props

    const errorIcon = getErrorIcon(errorMessage)
    //
    // prob makes sense to send all the props into the fn...
    // also prob makes BETTER sense to not have 3 named ways of required
    // you could easily find the type and use it.. this way you can have
    // multiple avenues to pass in and all can be evaluated..
    // this is called polymorphism

    if (requiredFn && typeof requiredFn === 'function') {
      return requiredFn(data, this.props) ? errorIcon : null
    }

    if (isRequired) {
      return value || valueFormatted ? null : errorIcon
    }
    // why? just use a fn...
    if (requiredIf) {
      // console.log('requiredIf', rowData[rowIndex][field])
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredIf] &&
        !rowData[rowIndex][field]
      ) {
        return errorIcon
      }
    }

    if (requiredLessThan) {
      if (
        Array.isArray(rowData) &&
        rowData[rowIndex] &&
        rowData[rowIndex][requiredLessThan]
      ) {
        if (
          toNumeral(rowData[rowIndex][field]) >
          toNumeral(rowData[rowIndex][requiredLessThan])
        ) {
          return errorIcon
        }
      }
    }

    return null
  }

  getValue() {
    return this.state.textFieldVal
  }

  // onFocus = () => {
  //   if (this.textField) {
  //
  //     this.textField.select()
  //   }
  // }

  render() {
    const {
      colDef: {
        field,
        cellEditorParams: {
          allowNegative,
          formatter,
          decimalScale,
          decimalSeperator,
          dollarField,
          fixedDecimalScale,
          thousandSeparator,
          textAlign,
          noDecimalLimit,
          maxLength,
          fontSize
        }
      },
      form,
      rowIndex,
      saveCart
    } = this.props

    /* 
      this ternary is important, because the NumberFormatter component 
      will not work properly if you provide any decimalScale, even 0 -- SVE 8/6/2020
    */
    const formatterProps = noDecimalLimit
      ? {
          allowNegative,
          decimalSeparator: decimalSeperator || '.',
          fontSize: fontSize || '1em',
          inputRef: el => (this.textField = el),
          maxLength,
          textAlign: textAlign || dollarField ? 'right' : 'left',
          thousandSeparator,
          prefix: dollarField ? '$' : '',
          saveCart,
          handlers: this.handlers
        }
      : {
          allowNegative,
          decimalScale: decimalScale || 0,
          noDecimalLimit: noDecimalLimit || false,
          decimalSeparator: decimalSeperator || '.',
          fontSize: fontSize || '1em',
          fixedDecimalScale: fixedDecimalScale || false,
          inputRef: el => (this.textField = el),
          maxLength,
          textAlign: textAlign || dollarField ? 'right' : 'left',
          thousandSeparator,
          prefix: dollarField ? '$' : '',
          saveCart,
          handlers: this.handlers
        }
    // debugger
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          width: '100%'
        }}
      >
        {formatter ? (
          <NumberFormat
            customInput={TextField}
            {...formatterProps}
            onValueChange={this.onValueChange}
            onBlur={this.onBlur}
            value={this.state.textFieldVal}
            onKeyDown={this.onKeyDown}
            InputProps={{
              disableUnderline: true,
              style: { height: '100%', padding: 0, width: '100%' }
            }}
            inputProps={{
              style: {
                height: '100%',
                maxHeight: '35px',
                padding: 0,
                minHeight: '27px'
              }
            }}
          />
        ) : (
          <Input
            disableUnderline
            fullWidth
            forwardRef={!formatter}
            id={`text-field-${form}-${field}-${rowIndex}`}
            value={this.state.textFieldVal}
            // disabled={this.isFieldDisabled()}
            inputComponent={TextField}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            style={{
              height: '100%',
              width: '100%'
            }}
            inputRef={el => (this.textField = el)}
            inputProps={
              /* formatter
              ? {
                  allowNegative,
                  decimalScale: decimalScale || 0,
                  noDecimalLimit: noDecimalLimit || false,
                  decimalSeparator: decimalSeperator || '.',
                  fontSize: fontSize || '1em',
                  fixedDecimalScale: fixedDecimalScale || false,
                  inputRef: el => (this.textField = el),
                  maxLength,
                  textAlign: textAlign || dollarField ? 'right' : 'left',
                  thousandSeparator,
                  prefix: dollarField ? '$' : '',
                  saveCart,
                  handlers: this.handlers
                }
              : */ {
                style: {
                  height: '100%',
                  padding: 0,
                  width: '100%',
                  textAlign: textAlign || 'left'
                }
              }
            }
            /* InputProps={
            formatter
              ? {
                  disableUnderline: true,
                  style: { height: '100%', padding: 0, width: '100%' }
                }
              : undefined
          } */
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const formModals = getIn(state, `ddiForm.${props.form}.modals`).size
  const mainModals = state.get('modals').size
  const ret = {
    modals: formModals + mainModals
  }
  return ret
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(TextFieldEditorCell)
