/* eslint import/prefer-default-export: 0 */
import { getIn, setIn } from 'utils'
import { fromJS } from 'immutable'

export const states = [
  {
    description: 'Alabama',
    dataId: 'AL'
  },
  {
    description: 'Alaska',
    dataId: 'AK'
  },
  {
    description: 'American Samoa',
    dataId: 'AS'
  },
  {
    description: 'Arizona',
    dataId: 'AZ'
  },
  {
    description: 'Arkansas',
    dataId: 'AR'
  },
  {
    description: 'California',
    dataId: 'CA'
  },
  {
    description: 'Colorado',
    dataId: 'CO'
  },
  {
    description: 'Connecticut',
    dataId: 'CT'
  },
  {
    description: 'Delaware',
    dataId: 'DE'
  },
  {
    description: 'District Of Columbia',
    dataId: 'DC'
  },
  {
    description: 'Federated States Of Micronesia',
    dataId: 'FM'
  },
  {
    description: 'Florida',
    dataId: 'FL'
  },
  {
    description: 'Georgia',
    dataId: 'GA'
  },
  {
    description: 'Guam',
    dataId: 'GU'
  },
  {
    description: 'Hawaii',
    dataId: 'HI'
  },
  {
    description: 'Idaho',
    dataId: 'ID'
  },
  {
    description: 'Illinois',
    dataId: 'IL'
  },
  {
    description: 'Indiana',
    dataId: 'IN'
  },
  {
    description: 'Iowa',
    dataId: 'IA'
  },
  {
    description: 'Kansas',
    dataId: 'KS'
  },
  {
    description: 'Kentucky',
    dataId: 'KY'
  },
  {
    description: 'Louisiana',
    dataId: 'LA'
  },
  {
    description: 'Maine',
    dataId: 'ME'
  },
  {
    description: 'Marshall Islands',
    dataId: 'MH'
  },
  {
    description: 'Maryland',
    dataId: 'MD'
  },
  {
    description: 'Massachusetts',
    dataId: 'MA'
  },
  {
    description: 'Michigan',
    dataId: 'MI'
  },
  {
    description: 'Minnesota',
    dataId: 'MN'
  },
  {
    description: 'Mississippi',
    dataId: 'MS'
  },
  {
    description: 'Missouri',
    dataId: 'MO'
  },
  {
    description: 'Montana',
    dataId: 'MT'
  },
  {
    description: 'Nebraska',
    dataId: 'NE'
  },
  {
    description: 'Nevada',
    dataId: 'NV'
  },
  {
    description: 'New Hampshire',
    dataId: 'NH'
  },
  {
    description: 'New Jersey',
    dataId: 'NJ'
  },
  {
    description: 'New Mexico',
    dataId: 'NM'
  },
  {
    description: 'New York',
    dataId: 'NY'
  },
  {
    description: 'North Carolina',
    dataId: 'NC'
  },
  {
    description: 'North Dakota',
    dataId: 'ND'
  },
  {
    description: 'Northern Mariana Islands',
    dataId: 'MP'
  },
  {
    description: 'Ohio',
    dataId: 'OH'
  },
  {
    description: 'Oklahoma',
    dataId: 'OK'
  },
  {
    description: 'Oregon',
    dataId: 'OR'
  },
  {
    description: 'Palau',
    dataId: 'PW'
  },
  {
    description: 'Pennsylvania',
    dataId: 'PA'
  },
  {
    description: 'Puerto Rico',
    dataId: 'PR'
  },
  {
    description: 'Rhode Island',
    dataId: 'RI'
  },
  {
    description: 'South Carolina',
    dataId: 'SC'
  },
  {
    description: 'South Dakota',
    dataId: 'SD'
  },
  {
    description: 'Tennessee',
    dataId: 'TN'
  },
  {
    description: 'Texas',
    dataId: 'TX'
  },
  {
    description: 'Utah',
    dataId: 'UT'
  },
  {
    description: 'Vermont',
    dataId: 'VT'
  },
  {
    description: 'Virgin Islands',
    dataId: 'VI'
  },
  {
    description: 'Virginia',
    dataId: 'VA'
  },
  {
    description: 'Washington',
    dataId: 'WA'
  },
  {
    description: 'West Virginia',
    dataId: 'WV'
  },
  {
    description: 'Wisconsin',
    dataId: 'WI'
  },
  {
    description: 'Wyoming',
    dataId: 'WY'
  },
  {
    description: 'Alberta',
    dataId: 'AB'
  },
  {
    description: 'British Columbia',
    dataId: 'BC'
  },
  {
    description: 'Manitoba',
    dataId: 'MB'
  },
  {
    description: 'New Brunswick',
    dataId: 'NB'
  },
  {
    description: 'Newfoundland and Labrador',
    dataId: 'NL'
  },
  {
    description: 'Northwest Territories',
    dataId: 'NT'
  },
  {
    description: 'Nova Scotia',
    dataId: 'NS'
  },
  {
    description: 'Nunavut',
    dataId: 'NU'
  },
  {
    description: 'Ontario',
    dataId: 'ON'
  },
  {
    description: 'Prince Edward Island',
    dataId: 'PE'
  },
  {
    description: 'Quebec',
    dataId: 'QC'
  },
  {
    description: 'Saskatchewan',
    dataId: 'SK'
  },
  {
    description: 'Yukon Territory',
    dataId: 'YT'
  }
]

export const saveSetupRecord = (result, setup) => {

  if (typeof setup === 'object' && setup !== null && Object.keys(setup).length) {
    for (const prop in setup) {
      if (Array.isArray(setup[prop])) {
        result = setIn(result, `values.contact.${prop}`, fromJS(setup[prop]))
      } else {
        result = setIn(result, `values.contact.${prop}`, setup[prop])
        result = setIn(result, `fields.contact.${prop}.value`, setup[prop])
      }
    }
  }

  return result

}

export const resetContact = result => {
  let values = getIn(result, 'values.contact')
  values = values && values.toJS ? values.toJS() : {}
  const props = Object.keys(values)

  if (props.length) {
    for (let i = 0; i < props.length; i++) {
      const prop = props[i]
      result = setIn(result, `fields.contact.${prop}.value`, '')
      result = setIn(result, `values.contact.${prop}`, '')
    }
  }

  return result
}

export const getErrorMessages = errors =>
  errors.reduce((acc, next) => {
    if (next.message) {
      acc = acc.concat(`${next.message} \n`)
    }
    return acc
  }, '')