import 'isomorphic-fetch' /* this is for this function ?... yes, this is a polyfill - MS */
import {
  select,
  call,
  fork,
  actionChannel,
  take,
  put
} from 'redux-saga/effects'
import { CANCELED } from 'modals/constants'

import { TOKEN_URL } from 'services/constants'
import { TRY_REQUEST_ACCESS, SUBMIT_LOGOUT_USER } from 'auth/constants'
import { tokenSelector, refreshTokenSelector } from 'auth/selectors'
import fetchProcess from 'utils/fetchProcess'
import destroyModal from 'utils/destroyModal'
import { api } from 'services'
import noop from 'utils/noop'
import { warningModal } from 'modals/sagas'
import { addModal } from 'modals/actions'
import toJSON from 'utils/toJSON'
import PriceOverrideModal from 'modals/PriceOverrideModal'
import * as authActions from 'auth/actions'
import {
  CANCEL_PRICE_OVERRIDE,
  PRICE_OVERRIDE
} from 'pages/SalesOrder/constants'
import { showAccessOverrideModal } from './sagas'
import { accessRejected } from './actions'
/**
 * posts to a web service URL with an options config object
 * and returns either API data or error messaging
 * @param {string} route - URL to post to
 * @param {object} options - options object
 * @returns {object} returns an object with either a response or an error
 */

const cases = [TRY_REQUEST_ACCESS, CANCELED]
// export function* priceOverrideProcess(
//   form,
//   error,
//   apiMethod,
//   apiParams,
//   actionName,
//   successFn = noop,
//   failFn = noop
// ) {
//   if (
//     error?.status === 499 &&
//     error?.message === 'Price Override Reason Code Required'
//   ) {
//     // c
//     apiParams.action = actionName
//     const { response, error } = yield call(apiMethod, apiParams)
//     if (response) {
//       const modal = yield call(addModal, form, {
//         component: PriceOverrideModal,
//         props: {
//           form,
//           apiParams,
//           apiMethod,
//           successFn,
//           failFn,
//           ...response
//         }
//       })
//       yield put(modal)
//       // debugger
//       // let listeners =
//     }
//   }
// }

export function* priceOverrideProcess({
  form,
  route,
  options,
  modalId,
  // apiMethod,
  // apiParams,
  actionName,
  data
  // successFn = noop,
  // failFn = noop
}) {
  let action
  const cases = [PRICE_OVERRIDE.REQUEST, CANCEL_PRICE_OVERRIDE.TRY]
  const channel = yield actionChannel(cases)
  let body = JSON.parse(options.body)
  while (true) {
    action = yield take(channel)
    if (action.type === PRICE_OVERRIDE.REQUEST) {
      // const { pin, password, username } = action.payload
      const { meta } = action

      debugger
      body = {
        ...body,
        action: actionName,
        reasonCode: action.payload.reasonCode
      }
      if (
        Array.isArray(data) &&
        data.length &&
        data.every(x => x.group !== null)
      ) {
        const { reasonCode } = action.payload
        const reasonCodes = data?.map(x => ({
          key: x.key,
          reasonCode
        }))
        body = {
          ...body,
          reasonCodes
        }
        delete body.reasonCode
      }
      const newOptions = {
        ...options,
        body: toJSON(body)
      }
      const { response, error } = yield call(fetchProcess, route, newOptions)
      // debugger
      if (response) {
        yield fork(destroyModal, modalId, form)
        return { response }
      }
      if (error.status && error.status === 496) {
        yield fork(destroyModal, modalId, form)
        yield call(warningModal, error.message, 'Error Message')
        return {
          error: 'canceled'
        }
      }
    } else if (action.type === CANCEL_PRICE_OVERRIDE.TRY) {
      debugger
      const { response: r3, error: e3 } = yield call(api.cancelPriceOverride, {
        action: 'pricereasonoverride',
        guid: body.guid,
        lineNumber: body.lineNumber,
        childLineNumber: body.childLineNumber,
        groupNames: ['header', 'detail']
      })
      return {
        response: r3,
        error: e3
      }
    }
  }
  // c
  // apiParams.action = actionName
  // const { response, error } = yield call(apiMethod, apiParams)
  // if (response) {
  //   const modal = yield call(addModal, form, {
  //     component: PriceOverrideModal,
  //     props: {
  //       form,
  //       apiParams,
  //       apiMethod,
  //       successFn,
  //       failFn,
  //       ...response
  //     }
  //   })
  //   yield put(modal)
  //   // debugger
  //   // let listeners =
  // }
}
export const overrideProcess = function* overrideProcess({
  modalId,
  route,
  options,
  message,
  accessPath
}) {
  // console.log(message)
  let action
  const channel = yield actionChannel(cases)
  while (true) {
    action = yield take(channel)
    // good
    if (action.type === TRY_REQUEST_ACCESS) {
      const { pin, password, username } = action.payload
      const { meta } = action
      let body = JSON.parse(options.body)

      body = {
        ...body,
        credentials: {
          password,
          pin,
          username
        }
      }
      const newOptions = {
        ...options,
        body: toJSON(body)
      }
      const { response, error } = yield call(fetchProcess, route, newOptions)
      // debugger
      if (response) {
        yield fork(destroyModal, modalId)
        return { response }
      }
      if (error.status && error.status === 496) {
        yield fork(destroyModal, modalId)
        yield call(warningModal, error.message, 'Error Message')
        return {
          error: 'canceled'
        }
      }
      if (error.accessPath !== accessPath) {
        yield fork(destroyModal, modalId)
        const { response } = yield call(callFetch, route, options)
        // do something here...
        return {
          response
        }
      }
      yield put(accessRejected(meta))
    } else if (action.type === CANCELED) {
      // debugger
      return {
        error: 'canceled'
      }
    }
  }
}

export default function* callFetch(
  route,
  options,
  noToken = false,
  fetchProc = fetchProcess
) {
  if (!noToken) {
    const token = yield select(tokenSelector)
    options.headers.authorization = `Bearer ${token}`
  }

  let { response, error } = yield call(fetchProc, route, options)
  if (error) {
    // debugger
    // good

    if (error.status === 401) {
      const refreshToken = yield select(refreshTokenSelector)

      const newOptions = {
        body: `grant_type=refresh_token&client_id=informCloudWebApp&refresh_token=${refreshToken}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
        method: 'post',
        mode: 'cors'
      }
      const { response: secondResponse, error: secondError } = yield call(
        fetchProc,
        TOKEN_URL,
        newOptions
      )
      console.log(secondResponse, secondError)
      if (secondResponse) {
        yield put(authActions.token.success(secondResponse))

        const token = yield select(tokenSelector)
        options.headers.authorization = `Bearer ${token}`
        ;({ response, error } = yield call(fetchProc, route, options))
        if (response) {
          return { response }
        }
      } else {
        error = noToken ? error : secondError
      }
      // return callFetch(TOKEN_URL, options, true)
    }
    if (error.status === 497) {
      const { accessPath, requiredLevel, message } = error
      const modalId = yield call(showAccessOverrideModal, {
        accessPath,
        message,
        requiredLevel
      })
      // debugger
      const { response: resp, error: err } = yield call(overrideProcess, {
        accessPath,
        message,
        modalId,
        options,
        requiredLevel,
        route
      })
      // debugger
      return {
        error: err,
        response: resp
      }
    }
    if (
      error?.status === 499 &&
      error?.message === 'Price Override Reason Code Required'
    ) {
      debugger
      let body = JSON.parse(options.body)
      const form = `salesOrder-${body.guid}`
      let action = 'pricereasonoverride'
      if (route.includes('masschange')) {
        action = 'changecopyoptionspricereasonoverride'
      }
      body = {
        ...body,
        action
      }

      const newOptions = {
        ...options,
        body: toJSON(body)
      }
      const { response: resp, error: e2 } = yield call(
        callFetch,
        route,
        newOptions
      )
      if (resp) {
        const modal = yield call(addModal, form, {
          component: PriceOverrideModal,
          props: {
            form,
            // apiParams,
            // apiMethod,
            // successFn,
            // failFn,
            ...newOptions,
            ...resp
          }
        })
        yield put(modal)
        const { response: resp2, error: err } = yield call(
          priceOverrideProcess,
          {
            form,
            modalId: modal.payload.id,
            options,
            route,
            actionName: action,
            data: error?.data
          }
        )
        debugger
        return {
          error: err,
          response: resp2
        }
      }

      //
      // const modalId = yield call(showModal, {
      //   accessPath,
      //   message,
      //   requiredLevel
      // })
      // debugger
    }
    if (error.status === 495) {
      yield put({
        type: SUBMIT_LOGOUT_USER.SUCCESS,
        payload: { statusText: 'Your session has timed out.' }
      })
    }
    // debugger
    return {
      error
    }
  }
  // success
  // // debugger
  return { response }
}
