import React from 'react'
import { Table, TableCell, TableRow, TableBody } from '@material-ui/core'
import { withContext } from 'ddiForm'
import { formatNumber, getValue } from 'utils'

const cellStyle = { textAlign: 'right' }
const bgWhite = { background: '#fff' }

const ItemizedTotalTable = ({
  openTotal,
  shippableTotal,
  backOrderAmount,
  shippedTotal,
  orderTotal
}) => (
  <div>
    <h2
      style={{
        color: '#444',
        fontSize: 14,
        fontWeight: 500,
        margin: '10px 0 0 0',
        textAlign: 'center'
      }}
    >
      Total
    </h2>
    <Table>
      <TableBody>
        <TableRow style={bgWhite}>
          <TableCell>Open</TableCell>
          <TableCell style={cellStyle}>{formatNumber(openTotal)}</TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Shippable</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippableTotal)}
          </TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Back Ordered</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(backOrderAmount)}
          </TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Shipped</TableCell>
          <TableCell style={cellStyle}>{formatNumber(shippedTotal)}</TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Total</TableCell>
          <TableCell style={cellStyle}>{formatNumber(orderTotal)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
)

/* should be OK as-is SVE 9/25/2020 */
export default withContext({
  openTotal: form => getValue(form, 'openTotal', 0),
  shippableTotal: form => getValue(form, 'shippableTotal', 0),
  backOrderAmount: form => getValue(form, 'backOrderAmount', 0),
  shippedTotal: form => getValue(form, 'shippedTotal', 0),
  orderTotal: form => getValue(form, 'orderTotal', 0)
})(ItemizedTotalTable)
