import React from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { getIn, layoutFlex, emptyList } from 'utils'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import withDimensions from 'hoc/withDimensions'

const getRowNodeId = data => data.month

const columnDefs = props => {
  const params = {
    disabled: !props.isEditing,
    focusedCell: props.focusedCell,
    form: props.form,
    onChange: props.onCellChange,
    propertyName: props.propertyName
  }

  const textEditor = {
    cellRendererFramework: TextFieldGridCell,
    cellRendererParams: params
  }

  const colDefs = [
    { field: 'month', headerName: 'Month' },
    {
      ...textEditor,
      cellRendererParams: {
        ...textEditor.cellRendererParams,
        decimalScale: 2,
        dollarField: true,
        textAlign: 'right',
        fixedDecimalScale: true,
        formatter: 'number'
      },
      field: 'budget',
      headerName: 'Budget',
      headerClass: 'align-right'
    }
  ]

  return colDefs
}

const setPinnedRowData = grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      acc.budget += next.budget
      return acc
    },
    { month: 'TOTALS:', budget: 0 }
  )

  return [result]
}

const MonthlyBudget = ({ height, isEditing, rowDataList }) => {
  const rowData = rowDataList?.toJS ? rowDataList.toJS() : []
  const pinnedRowData = setPinnedRowData(rowData)

  return (
    <div style={layoutFlex('60rem')}>
      <div style={{ flex: '1 1', overflow: 'hidden' }}>
        <EditableGrid
          form="customerShipTo"
          propertyName="monthlyBudget"
          getRowNodeId={getRowNodeId}
          columnDefs={columnDefs}
          enableSorting
          width="100%"
          height={400}
          isEditing={isEditing}
          title="Monthly Budget"
          pinnedBottomRowData={pinnedRowData}
          requiredCols={['month', 'budget']}
          skipValidationOnUnmount
        />
      </div>
    </div>
  )
}

MonthlyBudget.propTypes = {
  height: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired
}

// const monthlyBudgetWithContext = withContext({
//   isEditing: form => form.isEditing || false,
//   pinnedRowData: formState =>
//     formState.fields &&
//     formState.fields.monthlyBudget &&
//     formState.fields.monthlyBudget.rowData
//       ? setPinnedRowData(formState.fields.monthlyBudget.rowData)
//       : []
// })(MonthlyBudget)
const monthlyBudgetWithContext = withContext({
  isEditing: form => form.get('isEditing') || false,
  rowDataList: form => getIn(form, 'fields.monthlyBudget.rowData') || emptyList
})(MonthlyBudget)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  monthlyBudgetWithContext
)
