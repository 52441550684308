/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popover } from '@material-ui/core'
import { getIn } from 'utils'
import { once } from 'lodash'
import {
  cancelProvisionalChanges,
  confirmProvisionalChanges
} from 'pages/SalesOrder/actions'
import PriceCalculatorCell from './PriceCalculatorCell'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false

  return {
    isEditing
  }
}

class PriceCalculateCellWrapper extends Component {
  static defaultProps = {
    style: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%'
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      open: true,
      inquiryMode: props.hasRecord && !props.isEditing
    }
  }

  componentDidMount() {
    if (this.state.inquiryMode) {
      window.addEventListener('keydown', this.onKeyup)
    }
  }

  componentWillUnmount() {
    if (this.state.inquiryMode) {
      window.removeEventListener('keydown', this.onKeyup)
    }
  }

  onKeyup = event => {
    if (event.key === 'Tab') {
      this.tabToNext(this.props.api, true, event.shiftKey)
    }
  }

  edited = false

  setEdited = once(() => {
    this.edited = true
  })

  getValue() {

    return this.props.value
  }

  tabToNext = (api, wasBlurred, shiftKey) => {
    const { props } = this
    // console.log(wasBlurred)
    if (typeof wasBlurred !== 'boolean') {
      wasBlurred = false
    }

    this.setState({ open: false }, () => {
      api.stopEditing()
      // if (wasBlurred && props.data.promptForPrice && api.startEditingCell) {
      if (wasBlurred) {
        setTimeout(
          () => api[shiftKey ? 'tabToPreviousCell' : 'tabToNextCell'](),
          0
        )
      }
      // }
    })
  }

  handleClose = async (wasBlurred, shiftKey) => {
    const { dispatch, form, api } = this.props

    api.stopEditing(true)
    // this.tabToNext(api, wasBlurred, shiftKey)
  }

  render() {
    return (
      <Popover
        open={this.state.open}
        anchorEl={this.props.eGridCell}
        onClose={this.handleClose}
      >
        <div style={this.props.style}>
          <PriceCalculatorCell
            handleClose={this.handleClose}
            dispatch={this.props.dispatch}
            node={this.props.node}
            form={this.props.form}
            edited={this.edited}
            setEdited={this.setEdited}
            // inquiryMode={this.state.inquiryMode}
          />
        </div>
      </Popover>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(PriceCalculateCellWrapper)
