import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

export class DistributorsTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired
  }

  getTable = memoize(
    data =>
      data.length > 1 && (
        <Table>
          <TableHead>
            <TableRow style={{ height: 'auto' }}>
              {data.map(x => (
                <TableCell
                  key={x.dataId}
                  style={{ padding: 5, fontSize: 12, textAlign: 'center' }}
                >
                  {x.dataId}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow style={{ height: 'auto' }}>
              {data.map(x => (
                <TableCell
                  key={x.dataId}
                  style={{ padding: 5, fontSize: 11, textAlign: 'center' }}
                >
                  {x.quantityAvailable}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      )
  )

  render() {
    const { data, title } = this.props

    return (
      <DDICardWrapper
        title={title}
        contentStyle={{ padding: '0 0' }}
        style={{ textAlign: 'center', margin: 0 }}
      >
        {this.getTable(data)}
      </DDICardWrapper>
    )
  }
}

export default DistributorsTable
