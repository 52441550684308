import React from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import withRowTotals from 'hoc/withRowTotals'
import { getIn, emptyList, formatNumber, plainDeepEqual } from 'utils'
import { getGridHeight } from 'pages/InvoiceInquiry/utils'
import { errorRed } from 'theme/colors'

const columnDefs = [
  {
    field: 'glId',
    headerName: 'Charge',
    minWidth: 75
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 100
  },
  {
    cellClass: 'align-right right-align',
    cellStyle: params => {
      if (params.value < 0) {
        return { color: errorRed }
      }

      return null
    },
    field: 'amount',
    headerName: 'Amount',
    minWidth: 100,
    valueFormatter: params => formatNumber(params.value)
  }
]

const getPinnedRowData = memoize((rowData = []) => {
  const pinnedRowData =
    rowData && Array.isArray(rowData) && rowData.length
      ? rowData.reduce(
          (acc, next) => {
            acc.amount += next.amount
            return acc
          },
          { glId: 'TOTALS', amount: 0 }
        )
      : { glId: 'TOTALS', amount: 0 }

  return [pinnedRowData]
}, plainDeepEqual)

const GridWithRowTotals = withRowTotals({
  setPinnedRowDataFn: getPinnedRowData
})(Grid)

const GLDistribution = ({ glDistributionList }) => {
  const rowData = glDistributionList?.toJS ? glDistributionList.toJS() : []

  return (
    <div style={{ minWidth: 600, maxWidth: 800 }}>
      <GridWithRowTotals
        rowData={rowData}
        columnDefs={columnDefs}
        height={getGridHeight(rowData, 29)}
        getRowNodeId={data => data.glId}
        reactUi={false}
      />
    </div>
  )
}

export default withContext({
  glDistributionList: form => getIn(form, 'values.glDistribution') || emptyList
})(GLDistribution)
