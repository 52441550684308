import React from 'react'
import PropTypes from 'prop-types'

import { validateAndConvertUrl } from 'pages/ProductMaster/utils'

const Links = props => {
  const {
    data: {
      description,
      url
    }
  } = props

  const openLink = () => {
    const newUrl = validateAndConvertUrl(url)

    window.open(newUrl)
  }

  return (
    <a onClick={openLink} target="_blank">{description}</a>
  )
}

Links.propTypes = {
  data: PropTypes.object.isRequired
}

export default Links
