/* eslint func-names: 0 */
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { debounce } from 'lodash'

function OptionsDataIdCellRenderer() {}

// gets called once before the renderer is used
OptionsDataIdCellRenderer.prototype.init = function(params) {
  // console.log(params)
  // create the cell
  this.eGui = document.createElement('div')
  this.eGui.innerHTML =
    `<span class="btn-product-master-link-wrapper"><button class="btn-product-master-link" style="padding: 0;"><span class="product-master-link-value"></span></button></span>`
  // get references to the elements we want

  this.eButton = this.eGui.querySelector('.btn-product-master-link')
  this.eButton.style.textDecoration = 'underline'
  this.eButton.style.color = '#0000ff'
  this.eValue = this.eGui.querySelector('.product-master-link-value')

  // set value into cell
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value

  // add event listener to button
  this.eventListener = function() {
    // dispatch
    params.dispatch(
      openScreenAction({
        dataId: params.value,
        route: 'productmaster',
        title: 'Product Analysis',
        activeKey: 'analysis',
        image: "product_master_16.png",
        groupNames: ['analysis', 'overview']
      })
    )
  }
  this.eventListener = debounce(this.eventListener, 2000, {
    'leading': true,
    'trailing': false
  })

  this.eButton.addEventListener('click', this.eventListener)
}

// gets called once when grid ready to insert the element
OptionsDataIdCellRenderer.prototype.getGui = function() {
  return this.eGui
}

// gets called whenever the user gets the cell to refresh
OptionsDataIdCellRenderer.prototype.refresh = function(params) {
  // set value into cell again
  this.eValue.innerHTML = params.valueFormatted
    ? params.valueFormatted
    : params.value
  // return true to tell the grid we refreshed successfully
  return true
}

// gets called when the cell is removed from the grid
OptionsDataIdCellRenderer.prototype.destroy = function() {
  this.eButton.removeEventListener('click', this.eventListener)
}

export default OptionsDataIdCellRenderer
