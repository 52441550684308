/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ButtonBase, Icon } from '@material-ui/core'
// import { noop } from 'utils'
// import IndexSearch from 'components/Search/IndexSearch'
// import * as actions from 'components/Search/IndexSearch/actions'

class EditRowCell extends Component {
  // constructor(...args) {
  //   super(...args)
  //   console.log('index search cell, ', ...args)
  //   this.state = {
  //     value: this.props.value,
  //     rowIndex: this.props.rowIndex
  //   }
  // }

  render() {
    return (
      <ButtonBase>
        <Icon mini>edit</Icon>
      </ButtonBase>
    )
  }
}
export default connect()(EditRowCell)
// console.log(this)
//     const { dispatch } = this.props
//     return this.state.value ? (
//       this.state.value
//     ) : (
//       <IndexSearch
//         indexSearchType="shipVia"
//         partialMatchSearch={this.partialMatch}
//         exactMatchSearch={this.exactMatch}
//         // onChange={this.onChange}
//         setField={this.setField}
//         meta={{
//           allowInstantSearch: true,
//           minimumKeywordLength: 2,
//           allowSearchAll: true
//         }}
//         inputRef={this.inputRef}
//       />
//     )
