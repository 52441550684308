import { createAction, createAsyncAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const getMeta = createAsyncFormAction(CONSTANTS.GET_META)

export const clearScreen = (form, { skipFields = [] }) =>
  createAction(CONSTANTS.CLEAR_SCREEN, { skipFields }, form)

export const closeSalesOrder = createAsyncFormAction(
  CONSTANTS.CLOSE_SALES_ORDER
)

export const viewProductDetails = (form, { data = {}, dispatch = null }) =>
  createAction(CONSTANTS.VIEW_PRODUCT_DETAILS, { data, dispatch }, form)

export const destroyProductDetailFields = form =>
  createAction(CONSTANTS.DESTROY_PRODUCT_DETAIL_FIELDS, {}, form)

export const revertNumberSpinnerValue = (
  form,
  { field, isPostingGridUpdate, value }
) =>
  createAction(
    CONSTANTS.REVERT_NUMBER_SPINNER_VALUE,
    { field, isPostingGridUpdate, value },
    form
  )

export const openCheckoutScreen = createAsyncFormAction(
  CONSTANTS.OPEN_CHECKOUT_SCREEN
)

export const setSelectedRowIndex = (form, { rowIndex }) =>
  createAction(CONSTANTS.SET_SELECTED_ROW_INDEX, { rowIndex }, form)

export const launchFastProductModal = form =>
  createAction(CONSTANTS.LAUNCH_FAST_PRODUCT_MODAL, {}, form)

export const removeLineItemAttempt = (
  form,
  { rowId, lineNumber, dataId, description }
) =>
  createAction(
    CONSTANTS.REMOVE_LINE_ITEM_ATTEMPT,
    { rowId, lineNumber, dataId, description },
    form
  )

export const fetchAdditionalData = (form, { rowIndex }) =>
  createAction(CONSTANTS.FETCH_ADDITIONAL_DATA, { rowIndex }, form)

export const onNumberSpinnerChange = (form, { data, field, value }) =>
  createAction(CONSTANTS.ON_NUMBER_SPINNER_CHANGE, { data, field, value }, form)

export const onLineItemInputChange = (form, { data, field, value }) =>
  createAction(
    CONSTANTS.ON_LINE_ITEM_INPUT_CHANGE,
    { data, field, value },
    form
  )

export const closeProductDetailsInterface = (form, { modal }) =>
  createAction(CONSTANTS.CLOSE_PRODUCT_DETAILS_INTERFACE, { modal }, form)

export const deleteLineItemFromProductDetailsInterface = (
  form,
  { rowId, lineNumber }
) =>
  createAction(
    CONSTANTS.DELETE_LINE_ITEM_FROM_PRODUCT_DETAILS_INTERFACE,
    { rowId, lineNumber },
    form
  )

export const getLineItemImages = createAsyncFormAction(
  CONSTANTS.GET_LINE_ITEM_IMAGES
)

export const toggleLineItemPanels = form =>
  createAction(CONSTANTS.TOGGLE_LINE_ITEM_PANELS, {}, form)

export const launchFastCustomerModal = form =>
  createAction(CONSTANTS.LAUNCH_FAST_CUSTOMER_MODAL, {}, form)

export const storeTableUIState = (form, { page, rowsPerPage }) =>
  createAction(CONSTANTS.STORE_TABLE_UI_STATE, { page, rowsPerPage }, form)

export const storeTableExpandedGroups = (form, { expandedGroups = [] }) =>
  createAction(CONSTANTS.STORE_TABLE_EXPANDED_GROUPS, { expandedGroups }, form)

export const togglePrintOptionsInterface = form =>
  createAction(CONSTANTS.TOGGLE_PRINT_OPTIONS_INTERFACE, {}, form)

export const launchOrderPadInModal = form =>
  createAction(CONSTANTS.LAUNCH_ORDER_PAD_IN_MODAL, {}, form)

export const addLineItemsFromOrderPad = (form, { modalId }) =>
  createAction(
    CONSTANTS.ADD_LINE_ITEMS_FROM_ORDER_PAD,
    {
      modalId
    },
    form
  )

export const getShippingData = createAsyncFormAction(
  CONSTANTS.GET_SHIPPING_DATA
)
