/* eslint react/sort-comp: 0, sort-keys: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HoverIntent from 'react-hoverintent'
import { Tooltip } from 'react-tippy'

export class DescriptionTooltipCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      hoverIntended: false,
    }

    this.tooltipMarkup = null
  }

  // generateTooltipTitle = (field) => {
  //   const fields = {
  //     appendToFinanceCharge: 'Append To Finance Charge',
  //     disputedNote: 'Disputed Note'
  //   }

  //   if (fields[field]) {
  //     return fields[field]
  //   }

  //   return null
  // }

  getDisplayValue = (value = '') => {
    const values = {
      Rank: 'Rank',
      'Recent invoice # of days': 'Recency',
      Invoices: 'Invoices',
      'Sales ($)': 'Sales',
      'Profit ($)': 'Profit',
      'Average Pay Days': 'A.P.D.',
      'Lines Shipped': 'Lines Shipped'
    }

    if (values[value]) {
      return values[value]
    }

    return ''
  }

  generateTooltip = () => {
    const { value } = this.props
    if (this.tooltipMarkup === null) {
      const markup = (
        <div className="inform-tooltip" style={{ borderRadius: 4, padding: 10 }}>
          <span style={{ color: '#fff', fontSize: 11, margin: 0 }}>{value}</span>
        </div>
      )

      this.tooltipMarkup = markup
      // this.setState({ tooltip: markup })

      return this.tooltipMarkup
    }

    return this.tooltipMarkup
  }

  hoverIntended = () => this.setState({ hoverIntended: true })

  hoverNotIntended = () => this.setState({ hoverIntended: false })

  render() {
    const {
      value,
    } = this.props
    return (
      <Tooltip
        open={this.state.hoverIntended}
        // ref={el => (this.tooltip = el)}
        html={<div>{this.generateTooltip()}</div>}
        unmountHTMLWhenHide
        position="top"
        popperOptions={{ modifiers: { preventOverflow: { boundariesElement: 'window' } } }}
      >

        <div
          style={{
            display: 'block',
            width: '100%',
            height: '100%'
          }}
        >
          <HoverIntent
            onMouseOver={this.hoverIntended}
            onMouseOut={this.hoverNotIntended}
            sensitivity={20}
            interval={500}
          >
            <div
              style={{
                display: 'block',
                width: '100%',
                height: '100%'
              }}
            >
              <span>{this.getDisplayValue(value)}</span>
            </div>
          </HoverIntent>
        </div>
      </Tooltip>
    )
  }
}

export default DescriptionTooltipCell
