/* eslint no-lonely-if: 0 */
import React, { Component } from 'react'
import $ from 'jquery'
import { debounce } from 'lodash'
import { findDOMNode } from 'react-dom'
import { connect } from 'react-redux'
import { getDisplayName } from 'utils'
import hoistStatics from 'hoist-non-react-statics'
import { Tooltip } from 'react-tippy'
import HoverIntent from 'react-hoverintent'

const defaultState = {
  hoverIntended: false,
  tooltipResponse: null,
  keysToListenFor: {}
}
export default (options = {}) => WrappedComponent => {
  options = {
    cacheData: options.cacheData || true,
    component: options.component || undefined,
    position: options.position || 'bottom',
    sensitivity: options.sensitivity || 7,
    interval: options.interval || 700,
    timeout: options.timeout || 400,
    title: options.title || null,
    networkRequestPromise: options.networkRequestPromise || Promise.resolve,
    requestArgs: options.requestArgs || {},
    style: options.style || {},
    keyToActionMap: options.keyToActionMap || {},
    inputComponent: options.inputComponent || true,
    popperOptions: options.popperOptions || {},
    anchorToGridCellText: options.anchorToGridCellText || false,
    interactiveBorder: options.interactiveBorder || 5,
    ...options
  }
  class WithTooltip extends Component {
    static displayName = `withTooltip(${getDisplayName(WrappedComponent)})`

    constructor(...args) {
      super(...args)
      this.state = {
        contextMenuOpen: false,
        ...defaultState
      }
      if (!options.component && options.title) {
        options.component = <div>{options.title}</div>
      }
      this.idCache = {}
      // this.hoverIntended = debounce(this.hoverIntended, 100)
      // this.hoverNotIntended = debounce(this.hoverNotIntended, 100)
      // throw err if neither
    }

    componentDidMount() {
      // window.addEventListener('keydown', this.onKeyDown)

      if (this.wrappedComponent && findDOMNode(this.wrappedComponent)) {
        findDOMNode(this.wrappedComponent).addEventListener(
          'contextmenu',
          this.contextMenuOpen
        )
        findDOMNode(this.wrappedComponent).addEventListener(
          'focus',
          this.contextMenuOpen
        )
        findDOMNode(this.wrappedComponent).addEventListener(
          'mouseleave',
          this.contextMenuClose
        )
      }
    }

    componentWillUnmount() {
      // window.removeEventListener('keydown', this.onKeydown)
      // window.removeEventListener('keypress', this.onKeyPress)
      if (this.wrappedComponent && findDOMNode(this.wrappedComponent)) {
        findDOMNode(this.wrappedComponent).removeEventListener(
          'contextmenu',
          this.contextMenuOpen
        )
        findDOMNode(this.wrappedComponent).removeEventListener(
          'focus',
          this.contextMenuOpen
        )
        findDOMNode(this.wrappedComponent).removeEventListener(
          'mouseleave',
          this.contextMenuClose
        )
      }
    }

    onKeyDown = e => {
      if (options.inputComponent) {
        let wrapped = this.wrappedComponent
        if (wrapped && wrapped.wrappedInstance) {
          wrapped = this.wrappedComponent.wrappedInstance
        }
        if (wrapped && wrapped.textField) {
          if (wrapped.textField.state && wrapped.textField.state.isFocused) {
            if (this.state.keysToListenFor[e.key]) {
              e.preventDefault()
              this.onKeyPress(e)
            }
          }
        }
      } else {
        if (this.state.keysToListenFor[e.key]) {
          e.preventDefault()
          this.onKeyPress(e)
        }
      }
    }

    onKeyPress = e => {
      if (options.inputComponent) {
        if (options.keyToActionMap[e.key]) {
          const form = this.props._ddiForm && this.props._ddiForm.form
          const action = options.keyToActionMap[e.key]({
            form,
            ...this.state.keysToListenFor[e.key],
            searchType: this.props.searchType
          })
          this.props.dispatch(action)
        }
      } else {
        let requestArgs
        if (options.networkRequestPromise) {
          if (typeof options.requestArgs === 'function') {
            requestArgs = options.requestArgs.call(this)
            requestArgs = requestArgs || {}
          } else {
            requestArgs = options.requestArgs || {}
          }
        }
        const form = this.props._ddiForm && this.props._ddiForm.form
        if (typeof options.keyToActionMap[e.key] === 'function') {
          const action = options.keyToActionMap[e.key]({
            ...this.state.keysToListenFor[e.key],
            form: form || requestArgs.form || '',
            dataId: requestArgs.dataId
          })
          this.props.dispatch(action)
        }
      }
    }

    contextMenuOpen = e => {
      if (this.wrappedComponent && this.wrappedComponent._isMounted) {
        this.setState({ contextMenuOpen: true, hoverIntended: false })
      }
    }

    contextMenuClose = e => {
      if (this.wrappedComponent && this.wrappedComponent._isMounted) {
        this.setState({ contextMenuOpen: false })
      }
    }

    // onChange = (e, ...args) => {
    //   if (e.persist) {
    //     e.persist()
    //   }

    //   if (this.props.onChange) {
    //     this.props.onChange(e, ...args)
    //   }
    // }
    hoverIntended = event => {
      let wrapped = this.wrappedComponent
      if (wrapped && wrapped.wrappedInstance) {
        wrapped = this.wrappedComponent.wrappedInstance
      }

      /* this is for FieldWithTooltip */
      if (wrapped && wrapped.props.component) {
        wrapped = this.wrappedComponent
      }

      // console.log(
      //   'wrappedComponent',
      //   wrapped,
      //   this.wrappedComponent.wrappedInstance
      // )
      // debugger
      if (wrapped) {
        if (
          wrapped.state &&
          Object.hasOwnProperty.call(wrapped.state, 'isSet') &&
          !wrapped.state.isSet
        ) {
          return
        }
      }

      if (this.state.contextMenuOpen) {
        return
      }
      // debugger
      if (wrapped && wrapped._isMounted) {
        if (this.props.propertyName === 'writerId' && wrapped.state.isFocused)
          return
        this.setState({ hoverIntended: true }, async () => {
          if (options.networkRequestPromise) {
            let requestArgs
            if (typeof options.requestArgs === 'function') {
              requestArgs = options.requestArgs.call(this)
              requestArgs = requestArgs || {}
            } else {
              requestArgs = options.requestArgs || {}
            }
            try {
              const id = requestArgs.dataId || requestArgs.recordName
              let tooltipResponse
              if (id) {
                if (this.idCache[id]) {
                  // return
                  tooltipResponse = this.idCache[id]
                } else {
                  if (this?.props?.form && this?.props?.dispatch) {
                    tooltipResponse = await this.props.dispatch(
                      options.networkRequestPromise(
                        this.props.form,
                        requestArgs
                      )
                    )
                  } else {
                    tooltipResponse = await this.props.dispatch(
                      options.networkRequestPromise(requestArgs)
                    )
                  }
                  if (options.cacheData) {
                    this.idCache[id] = tooltipResponse
                  }
                }
              }
              // debugger
              let keysToListenFor = {}
              if (
                tooltipResponse &&
                tooltipResponse.summary &&
                tooltipResponse.summary.footer &&
                tooltipResponse.summary.footer.actions &&
                tooltipResponse.summary.footer.actions.length
              ) {
                keysToListenFor = tooltipResponse.summary.footer.actions.reduce(
                  (acc, x) => {
                    acc[x.id.toLowerCase()] = x
                    return acc
                  },
                  {}
                )
              }
              // debugger
              if (wrapped && wrapped._isMounted) {
                this.setState({
                  keysToListenFor,
                  tooltipResponse
                })
                // this.setState(
                //   {
                //     keysToListenFor,
                //     tooltipResponse
                //   },
                //   () => {
                //     window.addEventListener('keypress', this.onKeyPress)
                //   }
                // )
              }
            } catch (e) {
              console.log(e)
            }
          }
        })
      }
    }

    hoverNotIntended = event => {
      if (this.wrappedComponent && this.wrappedComponent._isMounted) {
        this.setState({
          hoverIntended: false,
          tooltipResponse: null,
          keysToListenFor: {}
        })

        // this.setState(
        //   {
        //     hoverIntended: false,
        //     tooltipResponse: null,
        //     keysToListenFor: {}
        //     // hasData: false
        //   },
        //   () => {
        //     window.removeEventListener('keypress', this.onKeyPress)
        //   }
        // )
      }
    }

    onHidden = () => {
      $('.tippy-popper').remove()
    }

    render() {
      return (
        <HoverIntent
          sensitivity={options.sensitivity}
          interval={options.interval}
          timeout={options.timeout}
          onMouseOver={this.hoverIntended}
          onMouseOut={this.hoverNotIntended}
        >
          <span>
            <Tooltip
              // trigger="mouseenter focus"
              open={this.state.hoverIntended}
              // delay={700}
              html={
                <options.component
                  {...this.props}
                  content={this.state.tooltipResponse}
                />
              }
              interactiveBorder={options.interactiveBorder}
              unmountHTMLWhenHide
              position={options.position}
              // onShown={this.onTooltipShown}
              popperOptions={options.popperOptions}
              onHidden={this.onHidden}
              style={{
                display: options.anchorToGridCellText ? 'inline' : 'block'
              }}
            >
              <div
                style={{
                  display: options.anchorToGridCellText ? 'inline' : 'block',
                  pointer: 'none'
                }}
              >
                <div
                  style={{
                    display: options.anchorToGridCellText ? 'inline' : 'block'
                  }}
                >
                  <WrappedComponent
                    {...this.props}
                    // onChange={this.onChange}
                    ref={c => (this.wrappedComponent = c)}
                    style={{ pointer: 'none' }}
                  />
                </div>
                {/* </HoverIntent> */}
              </div>
            </Tooltip>
          </span>
        </HoverIntent>
      )
    }
  }
  const temp = hoistStatics(WithTooltip, WrappedComponent)
  return connect(
    null,
    null,
    null,
    { forwardRef: true }
  )(temp)
}
