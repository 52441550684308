/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const CORPORATE_FIELD_UPDATES = '@@CORPORATE_FIELD_UPDATES@@'

export const GET_CORPORATE_FIELD_UPDATES_META = createAsyncRequestTypes(
  `${CORPORATE_FIELD_UPDATES}GET_CORPORATE_FIELD_UPDATES_META`
)

export const READ_CORPORATE_FIELD_UPDATES_DATA = createAsyncRequestTypes(
  `${CORPORATE_FIELD_UPDATES}READ_CORPORATE_FIELD_UPDATES_DATA`
)

export const SAVE_CORPORATE_FIELD_UPDATES_DATA = createAsyncRequestTypes(
  `${CORPORATE_FIELD_UPDATES}SAVE_CORPORATE_FIELD_UPDATES_DATA`
)

export const EDIT_CORPORATE_FIELD_UPDATES_DATA = createAsyncRequestTypes(
  `${CORPORATE_FIELD_UPDATES}EDIT_CORPORATE_FIELD_UPDATES_DATA`
)

export const CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA = createAsyncRequestTypes(
  `${CORPORATE_FIELD_UPDATES}CANCEL_EDIT_CORPORATE_FIELD_UPDATES_DATA`
)

export const SET_ROW_DATA = `${CORPORATE_FIELD_UPDATES}SET_ROW_DATA`

export const DESTROY_MODAL = `${CORPORATE_FIELD_UPDATES}DESTROY_MODAL`

export const CLEAR_VALUES = `${CORPORATE_FIELD_UPDATES}CLEAR_VALUES`
