import React, { memo } from 'react'
import { withContext } from 'ddiForm'
import { formatNumber, getValue } from 'utils'
import HighChart from 'components/charts/HighCharts'
import ProfitCard from 'pages/SalesOrder/tabs/Invoicing/components/ProfitCard'

const GrossProfit = ({
  totalCost,
  merchandiseAmount,
  gpPercent,
  gpDollars
}) => {
  const chartSeries = [
    {
      value: gpDollars,
      type: 'Profit'
    },
    {
      value: totalCost,
      type: 'Cost'
    }
  ]

  return (
    <div style={{ display: 'flex' }}>
      <div>
        {gpDollars || totalCost ? (
          <HighChart
            chartType="piechart"
            chartSeries={chartSeries}
            chartTitleText=""
            chartCategories={[]}
            dataLabelsEnabled={false}
            pieChartSecondaryLabel="Amount"
          />
        ) : (
          <div style={{ padding: 10 }}>
            <p style={{ fontSize: 12 }}>No chart data</p>
          </div>
        )}
      </div>
      <ProfitCard
        headline="Gross Profit"
        primaryData={gpPercent || gpPercent === 0 ? `${gpPercent}%` : ''}
        isPrimaryNegative={gpPercent < 0}
        primaryDataSubtitle="Percent"
        secondaryData={formatNumber(gpDollars)}
        isSecondaryNegative={gpDollars < 0}
        secondaryDataSubtitle="Dollars"
      />
      <ProfitCard
        headline="Total"
        primaryData={formatNumber(totalCost)}
        isPrimaryNegative={totalCost < 0}
        primaryDataSubtitle="Cost"
        secondaryData={formatNumber(merchandiseAmount)}
        isSecondaryNegative={merchandiseAmount < 0}
        secondaryDataSubtitle="Merchandise Amount"
      />
    </div>
  )
}

const contextObj = {
  totalCost: form => getValue(form, 'totalCost', null),
  merchandiseAmount: form => getValue(form, 'merchandiseAmount', null),
  gpPercent: form => getValue(form, 'gpPercent', null),
  gpDollars: form => getValue(form, 'gpDollars', null)
}

export default memo(withContext(contextObj)(GrossProfit))
