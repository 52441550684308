import React from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ContainedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 1
  }
})(Backdrop)

const DynamicWarehousesIsFetchingIndicator = ({ fetchingEntity = false }) => (
  <ContainedBackdrop
    open={fetchingEntity}
    style={{ width: '100%', height: '100%' }}
  >
    <CircularProgress size={50} thickness={10} />
  </ContainedBackdrop>
)

export default DynamicWarehousesIsFetchingIndicator
