import moment from 'moment'
import { getIn, setIn, fromJS, numToDollar, deleteIn, is } from 'utils'
import shortid from 'shortid'
import { REQUEST_ACCESS } from 'auth/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import { CANCEL_EDIT } from 'ddiForm/MasterScreen/constants'
import * as MUI_MODAL_CONSTANTS from 'modals/constants'
import printDocumentModalBehaviors from 'components/PrintDocumentModal/reducer'
import ProductTooltipCell from 'pages/CustomerMaster/components/grid/ProductTooltipWithIndexSearchCell'

import ColorDataCell from 'grid/ColorDataCell'
import {
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'hoc/withRowTotals/utils'
import {
  READ_CUSTOMER_STOCK_MINIMUMS,
  SAVE_CUSTOMER_STOCK_MINIMUMS,
  DELETE_CUSTOMER_STOCK_MINIMUMS
} from 'pages/InvoiceInquiry/constants'
// import ProductTooltipCell from './components/ProductTooltipCell'
import * as CONSTANTS from './constants'
import { storeInquiryParams } from './utils'

const dateFields = ['priceDate']
const tooltipFields = ['description']
const dataFields = ['productLine', 'vendor']

// const hiddenDataCols = ['isDueToBuy', 'isHidden', 'isHistory', 'isQuoted', 'productLine', 'vendor', 'numberOfPurchases']
export const hiddenDataCols = [
  'isDueToBuy',
  'isHidden',
  'isHistory',
  'isQuoted',
  'productLine',
  'vendor',
  'numberOfPurchases'
]

export const setGroupedRow = (key, value) => {
  const valMap = {
    numberOfPurchases: 'T',
    productLine: 'P',
    vendor: 'V'
  }
  if (valMap[key] === value) {
    return true
  }
  return false
}

export const sortRowData = (groupBy, rowData) => {
  switch (groupBy) {
    case 'N': // product
      rowData = rowData.sort((a, b) => {
        if (a.dataId < b.dataId) {
          return -1
        }
        if (a.dataId > b.dataId) {
          return 1
        }
        return 0
      })
      break
    case 'P': // product line
      rowData = rowData
        .sort((a, b) => {
          if (a.description.value < b.description.value) {
            return -1
          }
          if (a.description.value > b.description.value) {
            return 1
          }
          return 0
        })
        .sort((a, b) => {
          if (a.dataId < b.dataId) {
            return -1
          }
          if (a.dataId > b.dataId) {
            return 1
          }
          return 0
        })
      /*
      .sort((a, b) => {
        if (a.productLine.description < b.productLine.description) { return -1 }
        if (a.productLine.description > b.productLine.description) { return 1 }
        return 0
      })
      */
      break
    case 'V': // primary vendor
      rowData = rowData.sort((a, b) => {
        const regexAlpha = /[^a-zA-Z]/g
        const regexNumeric = /[^0-9]/g
        let dataA
        let dataB
        if (!isNaN(a.dataId.charAt(0)) && !isNaN(b.dataId.charAt(0))) {
          dataA = parseInt(a.dataId.replace(regexNumeric, '').substr(0, 2), 10)
          dataB = parseInt(b.dataId.replace(regexNumeric, '').substr(0, 2), 10)
          // console.log('test', dataA, dataB)
        } else {
          dataA = a.dataId.replace(regexAlpha, '')
          dataB = b.dataId.replace(regexAlpha, '')
        }
        if (dataA < dataB) {
          return -1
        }
        if (dataA > dataB) {
          return 1
        }
        return 0
      })
      /*
      .sort((a, b) => {
        if (a.seqNo < b.seqNo) { return -1 }
        if (a.seqNo > b.seqNo) { return 1 }
        return 0
      })
      */
      /*
      .sort((a, b) => {
        if (a.vendor.description < b.vendor.description) { return -1 }
        if (a.vendor.description > b.vendor.description) { return 1 }
        return 0
      })
      */
      break
    case 'T': // 24 Month Hits
      rowData = rowData.sort((a, b) => {
        const regexAlpha = /[^a-zA-Z]/g
        const regexNumeric = /[^0-9]/g
        let dataA
        let dataB
        if (!isNaN(a.dataId.charAt(0)) && !isNaN(b.dataId.charAt(0))) {
          dataA = parseInt(a.dataId.replace(regexNumeric, '').substr(0, 2), 10)
          dataB = parseInt(b.dataId.replace(regexNumeric, '').substr(0, 2), 10)
          // console.log('test', dataA, dataB)
        } else {
          dataA = a.dataId.replace(regexAlpha, '')
          dataB = b.dataId.replace(regexAlpha, '')
        }
        if (dataA < dataB) {
          return -1
        }
        if (dataA > dataB) {
          return 1
        }
        return 0
      })
      /*
      .sort((a, b) => {
        if (a.numberOfPurchases < b.numberOfPurchases) { return -1 }
        if (a.numberOfPurchases > b.numberOfPurchases) { return 1 }
        return 0
      })
      */
      break
    case 'S': // Line Seq Num
      rowData = rowData.sort((a, b) => {
        if (a.seqNo < b.seqNo) {
          return -1
        }
        if (a.seqNo > b.seqNo) {
          return 1
        }
        return 0
      })
      break
    default:
      rowData = rowData.sort((a, b) => {
        if (a.dataId < b.dataId) {
          return -1
        }
        if (a.dataId > b.dataId) {
          return 1
        }
        return 0
      })
  }

  return rowData
}

export const mapGridHeaders = (col, i) => {
  // col = {
  //   ...col,
  //   rowGroup: false
  // }

  col = {
    ...col,
    cellRendererFramework: ColorDataCell
  }

  if (col.field === 'dataId') {
    col = {
      ...col,
      cellRendererFramework: ProductTooltipCell,
      cellRendererParams: {
        linkTo: 'productAnalysis',
        indexSearchType: 'product'
      }
    }
  }

  if (dataFields.includes(col.field)) {
    col = {
      ...col,
      valueGetter: params => {
        if (params.data) {
          return (
            params.data[col.field] &&
            `${params.data[col.field].dataId}: ${params.data[col.field].description}`
          )
        }
        return null
      }
    }
  }

  /* description is the only field left with a junky old tooltip */
  if (col.field === 'description') {
    col = {
      ...col,
      tooltipField: `${col.field}.tooltip`,
      valueGetter: params => {
        if (
          params.data &&
          params.data[col.field] &&
          params.data[col.field].value
        ) {
          return params.data[col.field].value
        }
        return null
      }
    }
  }

  /* current price has a custom tooltip */
  if (col.field === 'currentPrice') {
    col = {
      ...col,
      valueGetter: params => {
        if (
          params.data &&
          params.data[col.field] &&
          params.data[col.field].value
        ) {
          return params.data[col.field].value
        }
        return null
      }
    }
  }

  /* parse the dollar field */
  if (col.field === 'currentPrice' || col.field === 'priceDisplay') {
    col = {
      ...col,
      cellClass: 'align-right',
      valueFormatter: params =>
        numToDollar(params.value, 'en-US', 'USD', 4, 10),
      ...numberFilterGridConfigs
    }
  }

  /* format any date fields */
  if (dateFields.includes(col.field)) {
    col = {
      ...col,
      cellClass: 'text-center align-center',
      valueFormatter: params => {
        if (params.value) {
          return params.value
            ? moment(new Date(params.value)).format('M/D/YY')
            : params.value
        }
        return null
      },
      ...dateFilterGridConfigs
    }
  }

  if (col.field === 'numberOfPurchases') {
    col = {
      ...col,
      valueGetter: params => {
        if (params.data) {
          return `Hits: ${params.data.numberOfPurchases}`
        }
        return null
      },
      ...numberFilterGridConfigs
    }
  }

  if (
    col.field === 'qtyAndUOM' ||
    col.field === 'ytdQty' ||
    col.field === 'minimumQuantity'
  ) {
    col = {
      ...col,
      cellClass: 'align-right'
    }
  }

  if (col.field === 'ytdQty' || col.field === 'minimumQuantity') {
    col = {
      ...col,
      cellClass: 'align-right',
      ...numberFilterGridConfigs
    }
  }

  if (col.field === 'quantity') {
    col = {
      ...col,
      width: 125,
      cellEditor: 'numericCellEditor',
      cellClass: 'align-right right-align'
    }
  }

  return col
}

const updateStockMinimums = (result, dataId, uomId, minimumQuantity) => {
  let grid = getIn(result, 'fields.inquiryResults')
  if (grid) {
    grid = grid.toJS()
    let { rowData } = grid
    rowData = rowData.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        minimumQuantity:
          next.dataId === dataId && next.uomId === uomId
            ? minimumQuantity
            : next.minimumQuantity
      })
      return acc
    }, [])

    grid = {
      ...grid,
      rowData
    }

    result = setIn(result, 'fields.inquiryResults', fromJS(grid))
  }

  return result
}

export default {
  [REQUEST_ACCESS.SUCCESS]: (
    state,
    {
      payload: {
        name,
        response: { meta }
      }
    }
  ) => {
    let result = state
    // console.log('requestAccessSuccess', name, meta)
    result = setIn(result, 'meta', fromJS(meta))
    return result
  },
  [CONSTANTS.OPEN_SCREEN]: (state, { payload, meta: { form } }) => {
    let result = state
    const metaDefaults = {
      defaultCutOffDate: '',
      defaultGroupBy: '',
      filterByOptions: [],
      groupByOptions: [],
      reportTypeOptions: [],
      indexSearchFields: [],
      printOptions: []
    }

    // const meta = extractIndexSearchFieldsFromMeta(payload.meta)
    // result = setIn(result, 'meta', fromJS(metaDefaults))
    result = setIn(result, 'fields.hits.value', 4)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'gridEdited', false)
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'fields.inquiryResults.isFetching', true)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    return result
  },
  [CONSTANTS.EXECUTE_INQUIRY.SUCCESS]: (
    state,
    {
      payload: {
        products: { data }
      }
    }
  ) => {
    let result = state
    const columnHeaders = [
      {
        propertyName: 'seqNo',
        columnHeader: 'Line Seq',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'dataId',
        columnHeader: 'Product',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'description',
        columnHeader: 'Description',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'uomId',
        columnHeader: 'UM',
        tooltip: '',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'quantity',
        columnHeader: 'Quantity',
        tooltip: '',
        canEdit: true,
        canSort: true
      },
      {
        propertyName: 'currentPrice',
        columnHeader: 'Current Price',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'priceDisplay',
        columnHeader: 'History Price',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'qtyAndUOM',
        columnHeader: 'Qty',
        tooltip: '',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'priceDate',
        columnHeader: 'History Date',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'ytdQty',
        columnHeader: 'YTD Qty',
        tooltip: '',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'minimumQuantity',
        columnHeader: 'Stock Min',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'productLine',
        columnHeader: 'Product Line',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'vendor',
        columnHeader: 'Vendor',
        canEdit: '',
        canSort: true
      },
      {
        propertyName: 'numberOfPurchases',
        columnHeader: 'Number of Purchases',
        canEdit: '',
        canSort: true
      },

      {
        propertyName: 'isHidden',
        columnHeader: 'Is Hidden',
        canEdit: false,
        canSort: true,
        filter: 'agTextColumnFilter'
      },
      {
        propertyName: 'isDueToBuy',
        columnHeader: 'Is Due To Buy',
        canEdit: false,
        canSort: true,
        filter: 'agTextColumnFilter'
      },
      {
        propertyName: 'isQuoted',
        columnHeader: 'Quoted',
        canEdit: false,
        canSort: true,
        filter: 'agTextColumnFilter'
      },
      {
        propertyName: 'isHistory',
        columnHeader: 'Is History',
        canEdit: false,
        canSort: true,
        filter: 'agTextColumnFilter'
      }
    ]

    const groupBy = getIn(result, 'fields.groupBy.value')
    /* we need to generate unique IDs for duplicate listings */
    let rowData = data.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        recordId: `${next.dataId}-${shortid.generate()}`
      })
      return acc
    }, [])

    rowData = sortRowData(groupBy, rowData)

    const filterFields = ['isHidden', 'isDueToBuy', 'isQuoted', 'isHistory']
    const grid = {
      columnDefs: columnHeaders
        .map(col => ({
          editable: col.canEdit,
          field: col.propertyName,
          headerName: col.columnHeader,
          sortingOrder: ['asc', 'desc'],
          suppressSorting: !col.canSort,
          hide: hiddenDataCols.includes(col.propertyName),
          rowGroup: setGroupedRow(col.propertyName, groupBy),
          filter: filterFields.includes(col.propertyName)
            ? 'agTextColumnFilter'
            : ''
        }))
        .map(mapGridHeaders),
      rowData
    }

    result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    result = setIn(result, 'fields.inquiryResults.isFetching', false)
    result = setIn(result, 'gridEdited', false)
    result = setIn(result, 'fields.filteredGridModel.id', '')
    result = setIn(result, 'fields.filteredGridModel.grid', [])

    result = storeInquiryParams(result)
    return result
  },
  [DDICONSTANTS.BLUR]: (state, { payload: { propertyName, value } }) => {
    let result = state
    if (propertyName === 'cutOffDate') {
      const prevValue = getIn(result, 'fields.cutOffDate.prevValue')
      if (value !== prevValue || !value) {
        result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
      }
    }

    return result
  },
  [DDICONSTANTS.SET_FIELD]: (
    state,
    {
      payload: {
        propertyName,
        value,
        isMobile = false,
        preventClearShipTo = false,
        ...rest
      },
      meta: { loadedFromSOERecord = false }
    }
  ) => {
    let result = state
    // console.log('DDICONSTANTS.SET_FIELD', action)
    const grid = getIn(result, 'fields.inquiryResults')
    const gridEdited = getIn(result, 'gridEdited')
    const storedValue =
      getIn(result, `values.previous.${propertyName}.value`) || ''

    if ((propertyName === 'cutOffDate') & !gridEdited) {
      result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    }

    if (
      propertyName === 'customer' &&
      value !== storedValue &&
      !gridEdited &&
      !preventClearShipTo
    ) {
      result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))

      result = setIn(result, 'fields.shipTo.value', '')
      result = setIn(result, 'fields.shipTo.description', '')
      result = setIn(result, 'values.shipTo', '')
    }

    if (propertyName === 'shipTo' && value !== storedValue && !gridEdited) {
      result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    }

    if (grid && propertyName === 'groupBy') {
      let gridData = grid ? grid.toJS() : {}
      const columnDefs =
        gridData.columnDefs && gridData.columnDefs.length
          ? gridData.columnDefs.reduce((acc, next) => {
              acc = acc.concat({
                ...next,
                hide: hiddenDataCols.includes(next.field),
                rowGroup: setGroupedRow(next.field, value)
              })
              return acc
            }, [])
          : []

      const rowData =
        gridData.rowData && gridData.rowData.length
          ? sortRowData(value, gridData.rowData)
          : []

      gridData = {
        ...gridData,
        columnDefs,
        rowData
      }
      result = setIn(result, 'fields.inquiryResults', fromJS(gridData))
    }

    if (propertyName === 'groupBy' && value !== 'V') {
      result = setIn(result, 'fields.vendor.value', '')
      result = setIn(result, 'values.vendor', '')
    }

    if (propertyName === 'groupBy' && value !== 'P') {
      result = setIn(result, 'fields.productLine.value', '')
      result = setIn(result, 'values.productLine', '')
    }

    if (propertyName === 'customer' && loadedFromSOERecord) {
      result = setIn(result, 'loadedFromSOERecord', true)
    }
    // if (propertyName === 'shipTo' && value) {
    //   console.log(rest)
    //   if (rest.results.parentId) {
    //     // result = setIn(result, 'fields.customer.value', rest.results.parentId)
    //     // result = setIn(
    //     //   result,
    //     //   'fields.customer.description',
    //     //   rest.results.parentDescription || ''
    //     // )
    //     // result = setIn(result, 'values.customer', rest.results.parentId)
    //   }
    //   // debugger
    // }

    return result
  },
  [CONSTANTS.SHOW_PRICE_DETAIL]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    result = setIn(result, 'errorMessage', '')
    return result
  },
  [CONSTANTS.SAVE_PRICE_DETAIL.SUCCESS]: (state, action) => {
    // debugger
    const {
      payload: { dataId, uomId, isHidden }
    } = action

    let result = state
    let grid = getIn(result, 'fields.inquiryResults')

    if (grid) {
      grid = grid.toJS()
      let { rowData } = grid
      rowData = rowData.reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          isHidden:
            next.dataId === dataId && next.uomId === uomId
              ? isHidden
              : next.isHidden
        })
        return acc
      }, [])

      grid = {
        ...grid,
        rowData
      }

      result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    }

    /* clear out any of the PriceCalculator data */
    result = setIn(result, 'fields.editablePrice.value', '')
    result = setIn(result, 'fields.editablePrice.updateField', '')
    result = setIn(result, 'fields.editablePrice.errorMessage', '')

    return result
  },
  [READ_CUSTOMER_STOCK_MINIMUMS.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    result = setIn(result, 'errorMessage', '')
    return result
  },
  [SAVE_CUSTOMER_STOCK_MINIMUMS.SUCCESS]: (state, action) => {
    const {
      payload: { dataId, uomId, minimumQuantity }
    } = action
    let result = state
    result = updateStockMinimums(result, dataId, uomId, minimumQuantity)
    return result
  },
  [DELETE_CUSTOMER_STOCK_MINIMUMS.SUCCESS]: (state, action) => {
    const {
      payload: { dataId, uomId, minimumQuantity }
    } = action
    let result = state
    result = updateStockMinimums(result, dataId, uomId, minimumQuantity)
    return result
  },
  [CONSTANTS.UPDATE_LINE_ITEM_QUANTITY]: (
    state,
    { payload: { recordId, quantity } }
  ) => {
    let result = state
    // console.log('updateLineItemQuantity', action)
    let rowData = getIn(result, 'fields.inquiryResults.rowData')
    const rowIndex = rowData.findIndex(x => x.get('recordId') === recordId)

    if (
      is.number(rowIndex) &&
      rowIndex > -1 &&
      rowData?.update &&
      rowData?.size
    ) {
      rowData = rowData.update(rowIndex, data => data.set('quantity', quantity))
      result = setIn(result, 'fields.inquiryResults.rowData', rowData)
      result = setIn(result, 'gridEdited', true)
      result = setIn(result, 'isEditing', true)
    }

    return result
  },
  [CONSTANTS.UPDATE_LINE_ITEM_PRICE]: (
    state,
    { payload: { dataId, uomId, price } }
  ) => {
    let result = state
    // console.log('updateLineItemQuantity', action)
    let grid = getIn(result, 'fields.inquiryResults')
    if (grid) {
      grid = grid.toJS()
      let { rowData } = grid
      rowData = rowData.reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          priceDisplay:
            next.dataId === dataId && next.uomId === uomId
              ? price
              : next.priceDisplay
        })
        return acc
      }, [])

      grid = {
        ...grid,
        rowData
      }

      result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    }

    result = setIn(result, 'gridEdited', true)
    return result
  },
  [CONSTANTS.SET_EDITABLE_PRICE]: (
    state,
    { payload: { price, updateField } }
  ) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.value', price)
    result = setIn(result, 'fields.filteredGridModel.id', '')
    result = setIn(result, 'fields.filteredGridModel.grid', [])
    return result
  },
  [CONSTANTS.CALCULATE_ORDER_PAD_PRICE.SUCCESS]: (
    state,
    { payload: { price, updateField } }
  ) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.value', price)
    result = setIn(result, 'fields.editablePrice.updateField', updateField)
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    return result
  },
  [CONSTANTS.CALCULATE_ORDER_PAD_PRICE.FAILURE]: (
    state,
    { payload: { modelState } }
  ) => {
    let result = state
    result = setIn(
      result,
      'fields.editablePrice.errorMessage',
      modelState['model.GPPercent'][0]
    )
    return result
  },
  [CONSTANTS.DISMISS_APC_ERROR_MESSAGE]: (
    state,
    { payload: { modelState } }
  ) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    return result
  },
  [CONSTANTS.SHOW_ERROR_MESSAGE]: (state, { payload: { message } }) => {
    let result = state
    result = setIn(result, 'errorMessage', message)
    return result
  },
  [CONSTANTS.DELETE_CUSTOMER_STOCK_MINIMUMS.SUCCESS]: (
    state,
    { meta: { form }, payload: { dataId, uomId, minimumQuantity } }
  ) => {
    let result = state
    let grid = getIn(result, 'fields.inquiryResults')
    if (grid) {
      grid = grid.toJS()
      let { rowData } = grid
      rowData = rowData.reduce((acc, next) => {
        acc = acc.concat({
          ...next,
          minimumQuantity:
            next.dataId === dataId && next.uomId === uomId
              ? minimumQuantity
              : next.minimumQuantity
        })
        return acc
      }, [])

      grid = {
        ...grid,
        rowData
      }

      result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    }

    return result
  },
  [CONSTANTS.SHOW_ADJUSTED_PRICE]: (state, action) => {
    let result = state
    result = setIn(result, 'errorMessage', '')
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    return result
  },
  [CONSTANTS.SAVE_ADJUSTED_PRICE.TRY]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.SAVE_ADJUSTED_PRICE.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.value', '')
    result = setIn(result, 'fields.editablePrice.updateField', '')
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SAVE_ADJUSTED_PRICE.SUCCESS]: (
    state,
    { meta: { form }, payload: { lineItems } }
  ) => {
    let result = state
    let grid = getIn(result, 'fields.inquiryResults')

    if (grid && lineItems) {
      grid = grid.toJS()
      let { rowData } = grid
      for (let i = 0; i < lineItems.length; i++) {
        const lineItem = lineItems[i]
        rowData = rowData.reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            currentPrice:
              lineItem.dataId === next.dataId && lineItem.uomId === next.uomId
                ? {
                    ...next.currentPrice,
                    value: lineItem.adjustedPrice
                      ? lineItem.adjustedPrice
                      : lineItem.currentPrice.value
                  }
                : next.currentPrice
          })
          return acc
        }, [])
      }

      grid = {
        ...grid,
        rowData
      }

      result = setIn(result, 'fields.inquiryResults', fromJS(grid))
    }

    result = setIn(result, 'fields.filteredGridModel.id', '')
    result = setIn(result, 'fields.filteredGridModel.grid', [])
    result = setIn(result, 'fields.editablePrice.value', '')
    result = setIn(result, 'fields.editablePrice.updateField', '')
    result = setIn(result, 'fields.editablePrice.errorMessage', '')
    result = setIn(result, 'errorMessage', '')

    /* update the priceDetail interface */
    result = setIn(
      result,
      'values.priceDetail.adjustedPrice',
      lineItems[0].adjustedPrice
    )

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SET_FILTERED_GRID_MODEL_ID]: (state, { payload: { id } }) => {
    let result = state
    result = setIn(result, 'fields.filteredGridModel.id', id)
    result = setIn(result, 'fields.filteredGridModel.grid', [])
    return result
  },
  [CONSTANTS.GET_FILTERED_GRID_MODEL]: (state, { payload: { id, grid } }) => {
    let result = state
    result = setIn(result, 'fields.filteredGridModel.id', id)
    result = setIn(result, 'fields.filteredGridModel.grid', grid)
    return result
  },
  [CONSTANTS.REMOVE_GRID_EDITED_FLAG]: (state, action) => {
    let result = state
    result = setIn(result, 'gridEdited', false)
    return result
  },
  [MUI_MODAL_CONSTANTS.REMOVE_MODAL]: (state, { payload: { id } }) => {
    let result = state
    result = setIn(result, 'modalRemovedId', id)
    return result
  },
  [CONSTANTS.SET_PRICE_DETAIL_SAVE_PERMISSIONS]: (
    state,
    { payload: { canSave } }
  ) => {
    let result = state
    result = setIn(result, 'fields.priceDetailSavePermissions.value', canSave)
    return result
  },
  [CONSTANTS.SET_INITIAL_EDITABLE_PRICE]: (
    state,
    { payload: { price, updateField } }
  ) => {
    let result = state
    result = setIn(result, 'fields.editablePrice.value', price)
    result = setIn(result, 'fields.editablePrice.updateField', updateField)
    return result
  },
  [CONSTANTS.SET_QUOTED_PRICE.SUCCESS]: (
    state,
    { payload: { quotedPrice, priceDisplay, dataId } }
  ) => {
    let result = state

    let rowData = getIn(result, 'fields.inquiryResults.rowData')

    const rowIndex = rowData.findIndex(x => x.get('dataId') === dataId)
    rowData = rowData.update(rowIndex, data =>
      data.set('priceDisplay', priceDisplay)
    )
    result = setIn(result, 'fields.inquiryResults.rowData', rowData)
    result = setIn(result, 'fields.editablePrice.value', quotedPrice)
    result = setIn(result, 'values.priceDetail.quotedPrice', quotedPrice)
    result = setIn(result, 'gridEdited', true)

    return result
  },
  [CONSTANTS.SHOW_PRICE_DETAIL.SUCCESS]: (state, { payload }) => {
    let result = state
    // debugger

    for (const prop in payload) {
      result = setIn(result, `values.priceDetail.${prop}`, payload[prop])
      if (prop === 'quotedPrice') {
        result = setIn(result, 'fields.editablePrice.value', payload[prop])
        result = setIn(
          result,
          'fields.editablePrice.updateField',
          'quotedPrice'
        )
      }
    }

    return result
  },
  [CANCEL_EDIT.SUCCESS]: state => state.set('isEditing', false),
  [CONSTANTS.SHOW_ADJUSTED_PRICE.SUCCESS]: (state, { payload }) => {
    let result = state

    for (const prop in payload) {
      result = setIn(
        result,
        `values.adjustedPriceChange.${prop}`,
        payload[prop]
      )
      if (prop === 'adjustedPrice') {
        result = setIn(result, 'fields.editablePrice.value', payload[prop])
        result = setIn(
          result,
          'fields.editablePrice.updateField',
          'adjustedPrice'
        )
      }
    }

    return result
  },
  [CONSTANTS.CLEAR_INQUIRY_RESULTS]: (
    state,
    { payload: { propertyChanged } }
  ) => {
    let result = state

    result = setIn(result, 'gridEdited', false)
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'fields.inquiryResults.rowData', fromJS([]))
    result = deleteIn(result, 'values.previous')
    // debugger
    if (propertyChanged) {
      result = setIn(result, `values.${propertyChanged}`, '')
      // debugger
      if (propertyChanged === 'customer') {
        result = setIn(result, 'fields.shipTo.value', '')
        result = setIn(result, 'fields.shipTo.description', '')
        result = setIn(result, 'values.shipTo')
      }
      // if (propertyChanged === 'shipTo') {
      //   result = setIn(result, 'fields.customer.value', '')
      //   result = setIn(result, 'fields.customer.description', '')
      //   result = setIn(result, 'values.customer', '')
      // }
    }

    return result
  },
  [CONSTANTS.REVERT_SEARCH_CONTROL_SELECTION]: (
    state,
    {
      meta: { isMobile = false },
      payload: { propertyName, value, description }
    }
  ) => {
    let result = state
    /* 
      roll back any of the controls on cancellation of SET_FIELD,
      applies mainly for mobile -- SVE 11/19/2020
    */
    result = setIn(
      result,
      `fields.${propertyName}.prevValue`,
      getIn(result, `fields.${propertyName}.value`)
    )
    result = setIn(result, `fields.${propertyName}.value`, value)
    result = setIn(result, `values.${propertyName}`, value)

    if (propertyName === 'customer' || propertyName === 'shipTo') {
      result = setIn(result, `fields.${propertyName}.description`, description)
    }

    if (propertyName === 'customer') {
      result = setIn(
        result,
        'fields.shipTo.value',
        getIn(result, 'values.previous.shipTo.value')
      )
      result = setIn(
        result,
        'fields.shipTo.description',
        getIn(result, 'values.previous.shipTo.description')
      )
      result = setIn(
        result,
        'values.shipTo',
        getIn(result, 'values.previous.shipTo.value')
      )
    }

    if (value && description) {
      result = setIn(result, `fields.${propertyName}.isSet`, true)
    }

    return result
  },
  [CONSTANTS.CLEAR_IS_EDITING_FLAG]: (state, action) => {
    let result = state
    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'gridEdited', false)
    return result
  },
  ...printDocumentModalBehaviors
}
