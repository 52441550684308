import React, { useEffect } from 'react'
import shortid from 'shortid'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import memoize from 'memoize-one'
import {
  emptyList,
  fromJS,
  getIn,
  makeRowDataUnique,
  is,
  plainDeepEqual
} from 'utils'
import Grid from 'grid'
import ManualGrid from 'pages/SalesOrder/components/ManualGrid'
import IndexSearchRenderer from 'pages/SalesOrder/components/ManualGrid/IndexSearchRenderer'
import TextFieldEditorCell from 'pages/SalesOrder/components/ManualGrid/TextFieldEditorCell'
import IndexSearchEditor from 'pages/SalesOrder/components/ManualGrid/IndexSearchEditor'
import StatusGridCell from 'pages/SalesOrder/components/ManualGrid/StatusGridCell'

import RemoveLineItemCell from 'pages/SalesOrder/tabs/Detail/components/RemoveLineItemCell'
import ComponentsGridWithContext from './components/ComponentsGridWithContext'

const getCommentsRowData = memoize((data, guid) => {
  let comments =
    data?.comments && Array.isArray(data.comments)
      ? data.comments.reduce((acc, next) => {
          if (next?.dataId === 'Return Invoice' && data?.returnInvoiceId) {
            acc = acc.concat({
              ...next,
              returnInvoiceId: data?.returnInvoiceId
            })
          } else if (next?.dataId === 'Warranty Tag') {
            acc = acc.concat({
              ...next,
              guid,
              lineNumber: data?.lineNumber,
              warrantyTagId: data?.warrantyTagId
            })
          } else if (next?.dataId === 'Bins Comment') {
            acc = acc.concat(
              ...next.text.split('\r\n').reduce((a, n) => {
                if (n) {
                  a = a.concat({
                    text: n,
                    color: next?.color ? next.color : { r: 0, g: 128, b: 0 },
                    dataId: shortid.generate()
                  })
                }
                return a
              }, [])
            )
          } else {
            acc = acc.concat(next)
          }

          return acc
        }, [])
      : []

  comments = makeRowDataUnique(comments, 'rowId')

  return comments
}, plainDeepEqual)

const frameworkComponents = {
  indexSearchEditor: IndexSearchEditor,
  textFieldEditor: TextFieldEditorCell,
  removeLineItemCell: RemoveLineItemCell
}

let _isMounted = false

export default React.memo(props => {
  // const dispatch = useDispatch()
  const { dispatch } = props
  const rowId = props?.data?.rowId
  const propertyName = `lineItemComponents.${rowId}`

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const getColumnDefs = memoize(
    ({ customerId, hasRecord, isEditing, focusedCell, form, onCellChange }) => {
      const hideToggle = true
      const showCart = false
      const inquiryMode = Boolean(hasRecord && !isEditing)
      const editableIfCanChangeQuantityNoInquiryMode = p => {
        return p.data && p.data.canChangeQuantity && !inquiryMode
      }

      const editorParams = {
        disabled: inquiryMode,
        focusedCell,
        form,
        propertyName,
        onChange: onCellChange,
        hasRecord,
        isEditing
      }

      const textEditor = {
        cellEditor: 'textFieldEditor',
        cellEditorParams: editorParams
      }

      const colDefs = [
        {
          field: 'lineNumber',
          colId: 'lineNumber',
          headerName: 'lineNumber',
          filter: false,
          cellStyle: { 'text-align': 'left' },
          minWidth: 50,
          maxWidth: 100,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          headerValueGetter: () => 'Ln'
        },
        {
          cellRenderer: IndexSearchRenderer,
          cellEditor: 'indexSearchEditor',
          cellClass: 'line-items-product',
          cellEditorParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            dispatch: props.dispatch,
            form,
            propertyName,
            hideToggle,
            tabStop: true,
            disableAdvancedParsing: true,
            showCart,
            customerId
          },
          cellRendererParams: {
            ...editorParams,
            linkTo: 'productMaster',
            additionalOpenScreenParams: ['productAnalysis'],
            form,
            dispatch: props.dispatch
            // dispatch
          },
          field: 'dataId',
          colId: 'dataId',
          headerName: 'Component Number',
          minWidth: 150,
          maxWidth: 200,
          width: 150,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          editable: p => !inquiryMode && !p.data.dataId
        },
        {
          field: 'description',
          colId: 'description',
          headerName: 'Component Description',
          minWidth: 150,
          maxWidth: 200,
          pinned: 'left',
          lockPinned: true,
          suppressMenu: true,
          tabStop: true,
          editable: false
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right', color: '#000' }
          },
          ...textEditor,
          cellEditorParams: {
            /* 
              note this is using a string text editor now to allow 
              characters like 'B' and 'C' -- SVE 12/17/2021
            */
            ...textEditor.cellEditorParams,
            textAlign: 'right',
            notifyExitTextCell: true,
            propertyName,
            tabStop: true
            // onBlur: (e, data, isCancelAfterEndFired) => {
            //   if (
            //     this.gridApi &&
            //     data?.dataId &&
            //     !this.props.modals &&
            //     !isCancelAfterEndFired
            //   ) {
            //     this.gridApi.stopEditing()
            //   }
            // }
          },
          field: 'quantityExtended',
          colId: 'quantityExtended',
          tooltipComponent: 'customTooltip',
          tooltipField: 'quantityOrderedTooltip',
          headerName: 'Quant Per',
          minWidth: 100,
          maxWidth: 200,
          width: 125,
          suppressMenu: true,
          editable: !inquiryMode,
          pinned: 'left',
          lockPinned: true
        },
        {
          cellRenderer: 'removeLineItemCell',
          field:
            'canDelete' /* discovered this needs to be here in this minor version of ag-grid or the cell renderer data will not update disabled state -- SVE 3/5/2021 */,
          colId: 'removeLineItem',
          cellRendererParams: {
            ...editorParams,
            propertyName
          },
          maxWidth: 35,
          minWidth: 35,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          width: 35,
          suppressMenu: true,
          headerName: 'Remove Line Item',
          headerClass: 'hide-header-text',
          pinned: 'left',
          lockPinned: true
        },
        {
          field: 'uomId',
          colId: 'uomId',
          headerName: 'Quant UM'
        },
        {
          cellStyle: params => {
            if (is.number(params.value) && params.value < 0) {
              return { color: '#d9534f', 'text-align': 'right' }
            }

            return { 'text-align': 'right' }
          },
          headerClass: 'align-right',
          field: 'quantity',
          colId: 'quantity',
          headerName: 'Quant To Ship',
          minWidth: 100,
          width: 150,
          editable: editableIfCanChangeQuantityNoInquiryMode,
          cellEditor: 'textFieldEditor',
          cellEditorParams: {
            ...editorParams,
            allowNegative: false,
            propertyName,
            noDecimalLimit: true,
            maxLength: 10,
            textAlign: 'right',
            notifyExitTextCell: true,
            formatter: 'number',
            thousandSeparator: ''
          }
        }
      ]

      return colDefs
    }
  )

  const columnDefs = getColumnDefs({
    customerId: props.customerId,
    hasRecord: props.hasRecord,
    isEditing: props.isEditing,
    focusedCell: null,
    form: props.form,
    onCellChange: props.onCellChange
  })

  const commentsRowData = getCommentsRowData(props.data, props.guid)

  const onGridReady = params => {
    console.log('onGridReady', params)
    const detailId = `commentsDetailGrid-${props.data.rowId}`

    // Create Grid Info object
    const detailGridInfo = {
      id: detailId,
      api: params.api,
      columnApi: params.columnApi
    }

    props.api.addDetailGridInfo(detailId, detailGridInfo)
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        position: 'relative'
      }}
    >
      <div style={{ width: '100%' }}>
        <Grid
          rowData={commentsRowData}
          hideHeader
          suppressRowClickSelection
          suppressHorizontalScroll
          width="100%"
          columnDefs={[
            {
              cellRendererFramework: StatusGridCell,
              cellRendererParams: {
                dispatch: props.dispatch,
                form: props.form
              },
              field: 'text',
              headerName: ''
            }
          ]}
          getRowNodeId={data => data.rowId}
          onFirstDataRendered={params => params.api.sizeColumnsToFit()}
          height={commentsRowData?.length ? commentsRowData.length * 31 : 0}
          onGridReady={onGridReady}
        />
        <ComponentsGridWithContext
          form={props.form}
          // dispatch={dispatch}
          frameworkComponents={frameworkComponents}
          data={props.data}
          columnDefs={columnDefs}
          isEditing={props.isEditing}
          rowId={props?.data?.rowId}
          parentLineNumber={props?.data?.lineNumber}
          hasRecord={props.hasRecord}
          // components={props.data.components}
          additionalDataMap={props.additionalDataMap}
          lineItemComponents={props.lineItemComponents}
          masterGridApi={props.api}
        />
      </div>
    </div>
  )
})
