import React, { Component } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { GridField, withContext } from 'ddiForm'
import Grid from 'grid'
import { DDISelectField } from 'ddiForm/wrapped'
import {
  dateFilterComparator,
  formatDateFields,
  formatDollarFields,
  plainDeepEqual,
  getValue,
  emptyList,
  getField,
  getMeta
} from 'utils'
import { AppBar, Typography, Tabs, Tab } from '@material-ui/core'
import { updateTransactions } from 'pages/ProductMaster/actions'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import {
  getTooltip,
  getValueCellStyle,
  headerStyle
} from 'pages/ProductMaster/utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import './styles/master-style.scss'

export const getRowNodeId = (data, ind) => {
  // const { type, source, date, balance, quantity } = data
  // debugger
  // return `${type} - ${source} - ${date} - ${balance} - ${quantity}`
  return data.dataId
}

const TabContainer = ({ children }) => (
  <Typography component="div">{children}</Typography>
)

const dynamicCurrentColumnDefs = memoize(({ type }) => {
  const defaultDef = {
    filterParams: {
      clearButton: true,
      inRangeInclusive: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    }
  }
  const colDef = [
    {
      ...defaultDef,
      field: 'type',
      filter: 'agTextColumnFilter',
      headerName: 'Type',
      tooltip: getTooltip
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label text-center',
      headerName: 'Date',
      sortable: true,
      tooltip: getTooltip,
      valueFormatter: formatDateFields
    },
    {
      ...defaultDef,
      cellStyle: getValueCellStyle,
      field: 'quantity',
      filter: 'agNumberColumnFilter',
      headerClass: 'numeric-value-label',
      headerName: 'Quantity',
      sortable: true,
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      field: 'uom.value',
      filter: 'agTextColumnFilter',
      headerName: 'UOM',
      sortable: true,
      tooltipField: 'uom.tooltip',
      maxWidth: 150
    },
    {
      ...defaultDef,
      cellStyle: getValueCellStyle,
      field: 'balance',
      filter: 'agNumberColumnFilter',
      headerClass: 'numeric-value-label align-right',
      headerName: 'Balance',
      sortable: true,
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      field: 'source',
      filter: 'agTextColumnFilter',
      headerName: 'Source',
      sortable: true,
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      cellRendererFramework: OpenScreenLinkCell,
      field: 'reference',
      filter: 'agTextColumnFilter',
      headerName: 'Reference',
      linkTo: type,
      sortable: true
    },
    {
      ...defaultDef,
      cellStyle: { textAlign: 'right' },
      field: 'amount',
      filter: 'agNumberColumnFilter',
      headerClass: 'numeric-value-label',
      headerName: 'Amount',
      sortable: true,
      tooltip: getTooltip,
      valueFormatter: formatDollarFields
    }
  ]
  return colDef
})

const dynamicAllColumnDefs = props => {
  const defaultDef = {
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true,
      newRowsAction: 'keep'
    }
  }
  const colDef = [
    {
      ...defaultDef,
      field: 'type',
      filter: 'agTextColumnFilter',
      headerName: 'Type',
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      field: 'whse.value',
      filter: 'agTextColumnFilter',
      headerName: 'Whse',
      tooltipField: 'whse.tooltip'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'date',
      filter: 'agDateColumnFilter',
      filterParams: {
        clearButton: true,
        comparator: dateFilterComparator,
        inRangeInclusive: true,
        suppressAndOrCondition: true,
        browserDatePicker: true
      },
      headerClass: 'centered-label text-center',
      headerName: 'Date',
      tooltip: getTooltip,
      valueFormatter: formatDateFields
    },
    {
      ...defaultDef,
      cellStyle: getValueCellStyle,
      field: 'quantity',
      filter: 'agNumberColumnFilter',
      headerClass: 'numeric-value-label',
      headerName: 'Quantity',
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      field: 'uom.value',
      filter: 'agTextColumnFilter',
      tooltipField: 'uom.tooltip',
      headerName: 'UOM'
    },
    {
      ...defaultDef,
      field: 'source',
      filter: 'agTextColumnFilter',
      headerName: 'Source',
      tooltip: getTooltip
    },
    {
      ...defaultDef,
      cellRendererFramework: OpenScreenLinkCell,
      dispatch: props.dispatch,
      field: 'reference',
      filter: 'agTextColumnFilter',
      headerName: 'Reference',
      linkTo: props.type
    },
    {
      ...defaultDef,
      cellStyle: { textAlign: 'right' },
      field: 'amount',
      filter: 'agNumberColumnFilter',
      headerClass: 'numeric-value-label',
      headerName: 'Amount',
      tooltip: getTooltip,
      valueFormatter: formatDollarFields
    }
  ]
  return colDef
}

export class Transactions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    selectedYear: PropTypes.string.isRequired,
    years: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      tab: 'Current'
    }
  }

  componentDidUpdate(prevProps) {
    const {
      allWarehouseTransactions,
      currentWarehouseTransactions,
      selectedYear,
      dispatch,
      form
    } = this.props
    const prevYear = prevProps.selectedYear
    const onSameProduct =
      plainDeepEqual(
        allWarehouseTransactions,
        prevProps.allWarehouseTransactions
      ) &&
      plainDeepEqual(
        currentWarehouseTransactions,
        prevProps.currentWarehouseTransactions
      )

    if (prevYear !== '' && !plainDeepEqual(prevYear, selectedYear)) {
      dispatch(updateTransactions.try(form))
    }

    if (this.gridApi && !onSameProduct) {
      this.gridApi.setFilterModel(null)
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  getContextMenuItems = params => {
    const { dispatch } = this.props
    const {
      defaultItems,
      node: {
        data: { type }
      }
    } = params

    if (type === 'Received') {
      return [
        {
          name: 'Purchase Order',
          action: () =>
            dispatch(
              openScreenAction({
                name: 'invoice',
                title: 'Invoice'
              })
            )
        },
        ...defaultItems
      ]
    }
    return defaultItems
  }

  handleTabChange = (e, value) => {
    this.setState({ tab: value })
  }

  render() {
    const { tab } = this.state
    const {
      height,
      years,
      allWarehouseTransactions,
      currentWarehouseTransactions,
      selectedYear,
      selectedYearValue
    } = this.props

    const renderGrid =
      tab === 'Current' ? (
        <Grid
          // propertyName='currentWarehouseTransactions'
          title="Transactions"
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          onGridReady={this.onGridReady}
          defaultColDef={{
            sortable: true
          }}
          columnDefs={dynamicCurrentColumnDefs({
            dispatch: this.props.dispatch,
            type: 'invoice',
            tab
          })}
          // gridHeight={this.props.height - 78}
          getContextMenuItems={this.getContextMenuItems}
          rowSelection="single"
          rowData={currentWarehouseTransactions.toJS()}
          width="100%"
          fullWidth
        />
      ) : (
        <Grid
          // propertyName={'allWarehouseTransactions'}
          title="Transactions"
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          onGridReady={this.onGridReady}
          defaultColDef={{
            sortable: true
          }}
          columnDefs={dynamicAllColumnDefs({
            dispatch: this.props.dispatch,
            type: 'invoice',
            tab
          })}
          getContextMenuItems={this.getContextMenuItems}
          rowSelection="single"
          rowData={allWarehouseTransactions.toJS()}
          width="100%"
          fullWidth
        />
      )

    return (
      <div className="product-analysis-layout-flex-column">
        <div className="product-analysis-flex-wrapper-1">
          <div style={{ paddingBottom: 15, paddingLeft: 15 }}>
            <DDISelectField
              propertyName="selectedYear"
              values={years.toJS()}
              label="Year"
              style={{
                width: '5%'
              }}
              preventAutoDisable
              initialValue={selectedYearValue}
            />
          </div>
          <AppBar position="state">
            <Tabs onChange={this.handleTabChange} value={tab}>
              <Tab value="Current" label="Current Warehouse" />
              <Tab value="All" label="All Warehouses" />
            </Tabs>
          </AppBar>
          <TabContainer>
            <div className="product-analysis-flex-wrapper-1">
              <div style={{ height: height * 0.83 }}>{renderGrid}</div>
            </div>
          </TabContainer>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  allWarehouseTransactions: form =>
    getValue(form, 'transactions.allWarehouseTransactions', emptyList),
  currentWarehouseTransactions: form =>
    getValue(form, 'transactions.currentWarehouseTransactions', emptyList),
  selectedYearValue: form => getValue(form, 'transactions.selectedYear', ''),
  selectedYear: form => getField(form, 'selectedYear', ''),
  years: form => getMeta(form, 'years', emptyList)
}

export default withContext(contextObj)(Transactions)
