import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { debounce } from 'lodash'
import { DDIButton } from 'ddiForm/wrapped'
import { getIn } from 'utils'
import { createVendor, exitScreenFromModal } from '../actions'

const marginRight = { marginRight: 5 }

const RightFooterButtons = ({
  autoGenerateId,
  hasRecord,
  id,
  isEditing,
  isNew,
  preNewMode,
  preNewTextValue,
  form,
  templateKey
}) => {
  const dispatch = useDispatch()

  const createNewRecord = debounce(e => {
    dispatch(
      createVendor.try(form, {
        value: preNewTextValue,
        tabKeyFired: false
      })
    )
  }, 300)

  const exitModal = e => dispatch(exitScreenFromModal(form, { modalId: id }))

  return (
    <>
      {!preNewMode && !isNew ? (
        <DDIButton
          variant="contained"
          label="New"
          actionName={autoGenerateId ? 'createVendor' : 'tryTogglePreNewMode'}
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {preNewMode ? (
        <DDIButton
          variant="contained"
          label="OK"
          onClick={createNewRecord}
          disabled={!preNewTextValue}
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {hasRecord && !isEditing ? (
        <DDIButton
          variant="contained"
          label="Edit"
          // actionName="lockForEdit"
          actionName={templateKey ? 'lockTemplate' : 'lockForEdit'}
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {hasRecord && isEditing ? (
        <DDIButton
          variant="contained"
          label="Save"
          actionName="save"
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {hasRecord && isEditing && !isNew ? (
        <DDIButton
          variant="contained"
          label="Delete"
          actionName="deleteVendor"
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {(hasRecord && isEditing) || preNewMode ? (
        <DDIButton
          variant="contained"
          label="Cancel"
          actionName={
            preNewMode
              ? 'togglePreNewMode'
              : templateKey
              ? 'unlockTemplate'
              : 'cancelEdit'
          }
          preventAutoDisable
          tabIndex={0}
          style={marginRight}
        />
      ) : null}
      {id ? (
        <DDIButton
          variant="contained"
          label="Exit"
          onClick={exitModal}
          preventAutoDisable
          tabIndex={0}
        />
      ) : (
        <DDIButton
          variant="contained"
          label="Exit"
          // actionName="exitScreen"
          actionName={templateKey && isEditing ? 'exitTemplate' : 'exit'}
          preventAutoDisable
          tabIndex={0}
        />
      )}
    </>
  )
}

export default withContext({
  autoGenerateId: form => getIn(form, 'meta.autoGenerateId') || false,
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false,
  isNew: form => getIn(form, 'values.isNew') || false,
  preNewMode: form => form.get('preNewMode') || false,
  preNewTextValue: form => getIn(form, 'values.preNewText') || '',
  templateKey: form => getIn(form, 'values.templateKey') || ''
})(RightFooterButtons)
