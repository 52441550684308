// import { ColDefUtil } from 'ag-grid-community'

const menuTabs = ['filterMenuTab']
export default col => {
  const ret = {
    editable: col.canEdit,
    field: col.propertyName,
    headerName: col.columnHeader,
    menuTabs,
    // suppressSorting: !col.canSort
    sortable: !!col.canSort
    // type: col.align && col.align === 'right' ? 'numberColumn' : undefined
  }
  if (col.align) {
    // && col.align === 'right'/
    let align
    switch (col.align) {
      case 'right': {
        align = 'right'
        break
      }
      case 'left': {
        align = 'left'
        break
      }
      default: {
        align = 'center'
        break
      }
    }
    ret.cellStyle = {
      'text-align': align
    }
    ret.headerClass = `align-${align}`
    ret.cellClass = `align-${align}`
  }
  return ret
}
