/* eslint jsx-a11y/label-has-for: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import {
  dateFilterGridConfigs,
  numberFilterGridConfigs,
  vendorsEmptyRow,
  getRowNodeId
} from 'pages/ProductMaster/utils'
import { clearVendorsIsPendingFlag } from 'pages/ProductMaster/actions'
import withDimensions from 'hoc/withDimensions'
import { getMeta } from 'utils'
/* Editable Grid Stuffs */
import EditableGrid from 'components/EditableGrid'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
// import SelectGridCell from 'components/EditableGrid/components/SelectGridCell'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import DatepickerGridCell from 'components/EditableGrid/components/DatepickerGridCell'
import VendorTooltipSearchCell from './components/grids/VendorTooltipSearchCell'

export class Vendors extends Component {
  static propTypes = {
    hasRecord: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired
  }

  componentDidMount() {
    const { dispatch, form } = this.props
    dispatch(clearVendorsIsPendingFlag(form))
  }

  getColumnDefs = props => {
    const { canSeeCostAndGrossProfit } = this.props

    const params = {
      disabled: !props.isEditing,
      focusedCell: props.focusedCell,
      form: props.form,
      indexSearchType: 'vendor',
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    let colDefs = [
      {
        field: 'isPrimary',
        headerName: 'Primary',
        headerTooltip: 'Primary',
        minWidth: 100,
        pinned: 'left',
        lockPinned: true,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          disabledFn: data => {
            if (!props.isEditing) {
              return true
            }

            if (!data.dataId) {
              return true
            }

            return false
          }
        }
      },
      {
        field: 'dataId',
        headerName: 'Vendor',
        headerTooltip: 'Vendor',
        minWidth: 150,
        pinned: 'left',
        lockPinned: true,
        cellClass: 'vendor-id-cell',
        cellRendererFramework: VendorTooltipSearchCell,
        cellRendererParams: {
          ...params,
          linkTo: 'vendorMaster'
          // allowMultipleSearches: true,
          // meta: {
          //   allowInstantSearch: true,
          //   minimumKeywordLength: 2,
          //   allowSearchAll: true
          // }
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        headerTooltip: 'Description',
        tooltipField: 'description',
        minWidth: 200,
        pinned: 'left',
        lockPinned: true,
        editable: false,
        cellStyle: { color: props.isEditing ? '#444' : '#999' },
        suppressNavigable: true
      },
      {
        field: 'partNumber',
        headerName: 'Vendor Product #',
        headerTooltip: 'Vendor Product #',
        minWidth: 150,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params
        }
      },
      {
        field: 'partNumberDescription',
        headerName: 'Product Description',
        headerTooltip: 'Product Description',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          inputProps: {
            maxLength: 30
          }
        }
      },
      {
        field: 'upc',
        headerName: 'UPC Code',
        headerTooltip: 'UPC Code',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params
        }
      },
      {
        field: 'boxQuantity',
        headerClass: 'align-right',
        headerName: 'Box Quantity',
        headerTooltip: 'Box Quantity',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          formatter: 'number',
          fixedDecimalScale: true,
          decimalScale: 2
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'lastPONumber',
        headerName: 'Last P/O #',
        headerTooltip: 'Last P/O #',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params
        }
      },
      {
        field: 'lastPOQuantity',
        headerName: 'Last Quantity',
        headerTooltip: 'Last Quantity',
        headerClass: 'align-right',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          noDecimalLimit: true,
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'lastPOCost',
        headerName: 'Last Cost',
        headerClass: 'align-right',
        headerTooltip: 'Last Cost',
        hide: !canSeeCostAndGrossProfit,
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'right',
          decimalScale: 4,
          dollarField: true,
          fixedDecimalScale: true,
          formatter: 'number'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'lastPODate',
        headerName: 'Last Date',
        headerTooltip: 'Last Date',
        headerClass: 'text-center',
        minWidth: 150,
        lockPinned: true,
        cellClass: 'text-center datepicker-text-center',
        cellRendererFramework: DatepickerGridCell,
        cellRendererParams: {
          ...params,
          textAlign: 'center'
        },
        ...dateFilterGridConfigs
      },
      {
        field: 'nextPOCost',
        headerName: 'Next Cost',
        headerTooltip: 'Next Cost',
        headerClass: 'align-right',
        hide: !canSeeCostAndGrossProfit,
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          decimalScale: 4,
          dollarField: true,
          fixedDecimalScale: true,
          formatter: 'number',
          textAlign: 'right'
        },
        ...numberFilterGridConfigs
      },
      {
        field: 'disableImport',
        headerClass: 'text-center',
        headerName: 'Disable Import',
        headerTooltip: 'Disable Import',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          // disabledFn: data => !props.isEditing
          isDisabled: !props.isEditing
        }
      },
      {
        field: 'disableForecast',
        headerClass: 'text-center',
        headerName: 'Disable Forecast',
        headerTooltip: 'Disable Forecast',
        minWidth: 150,
        lockPinned: true,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          // disabledFn: data => !props.isEditing
          isDisabled: !props.isEditing
        }
      }
    ]

    if (props.isEditing) {
      colDefs = [
        ...colDefs,
        {
          field: 'delete',
          headerName: '',
          headerTooltip: 'Delete Row',
          lockPinned: true,
          cellRendererFramework: DeleteGridRowCell,
          cellRendererParams: {
            ...params
          },
          editable: false,
          width: 50,
          maxWidth: 50,
          minWidth: 50
        }
      ]
    }

    return colDefs
  }

  render() {
    // console.log('thisPropsVendors', this.props)
    const { height, isEditing, hasRecord } = this.props

    return (
      <div style={{ width: '100%' }}>
        <div
          className="product-master-vendors-grid-wrapper"
          style={{ margin: '0 10px' }}
        >
          <EditableGrid
            addButtonText="Add Vendor"
            gridWrapperStyle={{ flex: '1 1', maxWidth: '100%', width: '100%' }}
            emptyRow={vendorsEmptyRow}
            requiredCols={['dataId', 'description']}
            getRowNodeId={getRowNodeId}
            propertyName="vendors"
            form={this.props.form}
            columnDefs={this.getColumnDefs}
            height={height}
            isEditing={isEditing}
            focusCell="partNumber"
            width="100%"
            fullWidth
            suppressRowClickSelection
            focusCellOnNewRow="dataId"
            addBlankRowOnLoad
          />
        </div>
      </div>
    )
  }
}

export const contextObj = {
  canSeeCostAndGrossProfit: form =>
    getMeta(form, 'canSeeCostAndGrossProfit', false),
  hasRecord: form => form.get('hasRecord') || false,
  isEditing: form => form.get('isEditing') || false
  // vendors: form => form.fields && form.fields.vendors && form.fields.vendors.rowData ? form.fields.vendors.rowData : []
}

const vendorsWithContext = withContext(contextObj)(Vendors)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  vendorsWithContext
)
