import { call, fork, cancel, put, select, take } from 'redux-saga/effects'

import { INIT_PRINT_ROUTINE } from 'pages/SalesOrder/constants'
import { initPrintRoutine } from 'pages/SalesOrder/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, parseNumber } from 'utils'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import {
  PRINT_DOCUMENT_ROUTINE_COMPLETED,
  CLOSE_REPORT_VIEWER
} from 'components/PrintDocumentModal/constants'
import { printDocumentRoutineCompleted } from 'components/PrintDocumentModal/actions'
import * as api from 'pages/InvoiceInquiry/api'

export function* printProcess(form, type, selectedLinkedOrderIndex = null) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const layoutId = getIn(formState, 'fields.layoutId.value')
  const groupNames = ['final', 'header', 'detail']

  const paramsMap = {
    printOrderRecap: {
      dataId
    },
    printProofOfDelivery: {
      dataId
    },
    printWorksheet: {
      dataId,
      groupNames
    },
    printWarehousePick: {
      dataId,
      groupNames
    },
    printSalesOrder: {
      dataId,
      layoutId: layoutId === 'Default' ? '' : layoutId
    },
    printInvoice: {
      dataId
    },
    printLinkedOrder: {
      dataId,
      index: selectedLinkedOrderIndex,
      action: type ? type.toLowerCase() : ''
    }
  }

  /* temp till we add more print methods */
  const availableApiMethods = [
    'printSalesOrder',
    'printOrderRecap',
    'printProofOfDelivery'
  ]
  if (!type || !availableApiMethods.includes(type)) {
    return
  }

  const postData = paramsMap[type]

  yield put(initPrintRoutine.request(form))

  /* temp till we add more print methods */
  const apiMethod = type === 'printSalesOrder' ? 'printInvoice' : type

  const { response, error } = yield call(api[apiMethod], postData)
  // const { response, error } = yield call(api[type], paramsMap[type])

  if (response) {
    yield put(initPrintRoutine.success(response, form))

    /* variable to be overwritten by actual action */
    let printCompletionAction = { payload: { isCancellation: false } }
    if (Array.isArray(response)) {
      for (let i = 0; i < response.length; i++) {
        yield fork(showPrintDocumentModalProcess, form, {
          ...response[i],
          form
        })
        // debugger

        printCompletionAction = yield take([
          PRINT_DOCUMENT_ROUTINE_COMPLETED,
          CLOSE_REPORT_VIEWER
        ])
      }
    } else {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response,
        form
      })

      printCompletionAction = yield take([
        PRINT_DOCUMENT_ROUTINE_COMPLETED,
        CLOSE_REPORT_VIEWER
      ])
    }

    const isCancellationAction =
      printCompletionAction?.payload?.isCancellation || false

    console.log(isCancellationAction)
    // debugger
  } else {
    yield put(initPrintRoutine.failure(error, form))
  }
}

export function* initPrintRoutineListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { type, selectedLinkedOrderIndex = null }
    } = yield take(INIT_PRINT_ROUTINE.TRY)

    if (form === formListener) {
      yield fork(printProcess, form, type, selectedLinkedOrderIndex)
    }
  }
}
