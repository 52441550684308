/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { deleteActivity } from 'modals/EventModal/actions'
import { connect } from 'react-redux'
import { getIn, plainDeepEqual } from 'utils'

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteActivity: (form, { dataId, subject, type }) =>
    dispatch(
      deleteActivity(form, {
        dataId,
        subject,
        type
      })
    )
})

const mapStateToProps = (state, ownProps) => {
  const {
    colDef: { form }
  } = ownProps

  const activities = getIn(state, `ddiForm.${form}.fields.activities.value`)

  return {
    activities: activities && activities.toJS ? activities.toJS() : []
  }
}

class DeleteGridCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteActivity: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !plainDeepEqual(nextProps.data, this.props.data) ||
      nextProps.rowIndex !== this.props.rowIndex ||
      nextProps.value !== this.props.value
    ) {
      return true
    }

    return false
  }

  deleteActivity = () => {
    const {
      data: { dataId, subject, type },
      colDef: { form }
    } = this.props

    this.props.deleteActivity(form, { dataId, subject, type })
  }

  render() {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{
            color: '#d9534f',
            display: 'inline-block',
            fontSize: 16
          }}
          onClick={this.deleteActivity}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DeleteGridCell)
