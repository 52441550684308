/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DDIBinSearch from 'ddiForm/wrapped/DDIBinSearch'
import { Label, withContext } from 'ddiForm'
import { getIn } from 'utils'
import {
  cancelSetPrimaryBin as cancelSetPrimaryBinAction,
  setPrimaryBin as setPrimaryBinAction
} from 'pages/ProductMaster/actions'

const mapStateToProps = (state, ownProps) => {
  console.log('ownProps', ownProps)
  const {
    data: { form }
  } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  return {
    primaryBin: getIn(formState, 'fields.primaryBin.value') || '',
    productId: getIn(formState, 'fields.dataId.value') || '',
    warehouseId: getIn(formState, 'fields.selectedWarehouseId.value') || '',
    uom: getIn(formState, 'fields.selectedUOMId.value') || ''
  }

  // primaryBin: form =>
  //   form.fields && form.fields.primaryBin && form.fields.primaryBin.value
  //     ? form.fields.primaryBin.value
  //     : '',
  // productId: form => form.fields.dataId.value,
  // warehouseId: form => form.fields.selectedWarehouseId.value,
  // uom: form => form.fields.selectedUOMId.value
}

const mapDispatchToProps = dispatch => ({
  cancelSetPrimaryBin: form => dispatch(cancelSetPrimaryBinAction(form)),
  setPrimaryBin: form => dispatch(setPrimaryBinAction(form))
})

export class BinSearchModal extends Component {
  static propTypes = {
    productId: PropTypes.string.isRequired,
    uom: PropTypes.string.isRequired,
    warehouseId: PropTypes.string.isRequired
  }

  render() {
    const { uom, warehouseId, primaryBin, productId } = this.props

    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <label style={{ color: '#444', margin: '0 15px 0 0' }}>Bin:</label>
        <div style={{ marginRight: 10, maxWidth: 150 }}>
          <DDIBinSearch
            propertyName="primaryBin"
            warehouseId={warehouseId}
            productId={productId}
            isFromBin={false}
            uom={uom}
            meta={{
              allowInstantSearch: true,
              allowSearchAll: true,
              minimumKeywordLength: 2
            }}
          />
        </div>
        <Label propertyName="primaryBin.description" />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(BinSearchModal)
