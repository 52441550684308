/* eslint prefer-destructuring: 0, func-names: 0, prefer-template: 0 */
function RemitVendorTooltip() {}

function nl2br(str) {
  if (typeof str === 'undefined' || typeof str !== 'string' || str === null) {
    return ''
  }
  const breakTag = '<br>'
  return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
}

RemitVendorTooltip.prototype.init = function(params) {
  this.eGui = document.createElement('div')
  const eGui = this.eGui
  const { value } = params

  eGui.classList.add('custom-tooltip')
  eGui.classList.add('ag-tooltip')
  eGui.style.minWidth = '125px'
  if (value && typeof value === 'object' && value !== null) {
    const vendorId = value?.vendorId ? `${value.vendorId}<br />` : ''
    const description = value?.description ? `${value.description}<br />` : ''
    const address1 = value?.address1 ? `${value.address1}<br />` : ''
    const cityStateZip = value?.cityStateZip
      ? `${value.cityStateZip}<br />`
      : ''
    eGui.innerHTML = `<div>Remit To<br />${vendorId}${description}${address1}${cityStateZip}</div>`
  }
}

RemitVendorTooltip.prototype.getGui = function() {
  return this.eGui
}

export default RemitVendorTooltip
