import React from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import EditableGrid from 'components/EditableGrid'
// import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import TooltipField from 'hoc/TooltipField'
import { withContext } from 'ddiForm'
import withDimensions from 'hoc/withDimensions'

export const getRowNodeId = data => data.dataId

const getColDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      indexSearchType: 'pricegroup',
      onChange: onCellChange,
      propertyName,
      meta: {
        allowSearchAll: true,
        allowInstantSearch: true,
        minimumKeywordLength: 2
      }
    }
    const colDefs = [
      {
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'PriceGroup',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: { ...params },
        field: 'dataId',
        headerName: 'Price Group',
        fiter: 'agTextColumnFilter'
      },
      {
        field: 'description',
        headerName: 'Description',
        cellStyle: { color: isEditing ? '#444' : '#777' }
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        hide: !isEditing,
        width: 80
      }
    ]
    return colDefs
  }
)

const SecondaryPriceGroups = ({ form, height, isEditing }) => {
  return (
    <EditableGrid
      title="Secondary Price Groups For Price Matrix only"
      addButtonText="Add Price Group"
      propertyName="secondaryPriceGroups"
      columnDefs={getColDefs}
      emptyRow={{
        dataId: null,
        description: ''
      }}
      getRowNodeId={getRowNodeId}
      form={form}
      focusCellOnNewRow="dataId"
      focusCell="dataId"
      isEditing={isEditing}
      immutableData
      entityType="Product"
      height={height}
      requiredCols={['dataId']}
      addBlankRowOnLoad
    />
  )
}

const secondaryPriceGroupsWithContext = withContext({
  isEditing: form => form.get('isEditing') || false
})(SecondaryPriceGroups)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  secondaryPriceGroupsWithContext
)
