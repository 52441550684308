import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import CancelIcon from 'components/EditableGrid/components/icons/CancelIcon'
import { is } from 'utils'

import { deleteSerialNumber } from '../actions'

export const DeleteSerialNumberRowCell = ({ data, disabled, dispatch, form, rowIndex,}) => {

  function deleteRow() {
    const { dataId } = data

    if (dataId) {
      dispatch(deleteSerialNumber.try(form, { dataId, rowIndex } ))
    }
  }

  function checkIfDisabled() {
    return is.fn(disabled) ? disabled(data) : disabled
  }


  return (
    <CancelIcon
      onClick={deleteRow}
      disabled={checkIfDisabled()}
    />
  )
}

DeleteSerialNumberRowCell.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
}

export default connect(
  null,
  null,
  null,
  { forwardRef: false }
)(DeleteSerialNumberRowCell)

// export default DeleteSerialNumberRowCell
