import { createReducer, getIn, setIn, splice, timestampFormatter } from 'utils'
import { fromJS } from 'immutable'
import shortid from 'shortid'

import * as PO_CONSTANTS from 'pages/PurchaseOrderInquiry/constants'
import * as SO_CONSTANTS from 'pages/SalesOrderInquiry/constants'
import * as IDX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import * as INV_SEARCH_CONSTANTS from 'components/Search/ARInvoiceSearch/constants'
import * as DDI_FORM_CONSTANTS from 'ddiForm/constants'
import * as CONSTANTS from './constants'

export const initialState = fromJS({
  activity: {},
  colorOptions: [],
  date: new Date(),
  // eventTypeEditor: {
  //   open: false,
  //   columnHeaders: [],
  //   eventTypes: [],
  //   gridVisible: false,
  //   eventTypeLocked: false,
  //   selectedEvent: {
  //     dataId: '',
  //     color: '',
  //     isNew: false
  //   }
  // },
  eventTypeOptions: [],
  open: false,
  participants: [],
  referenceOptions: [],
  reminderIntervalOptions: [],
  taskTypeOptions: []
})

const openKey = 'open'
const activityKey = 'meta.activity'

const participantsKey = 'meta.participants'
const eventTypeOptionsKey = 'meta.eventTypeOptions'
const taskTypeOptionsKey = 'meta.taskTypeOptions'
const taskColorsKey = 'meta.taskColors'
const referenceOptionsKey = 'meta.referenceOptions'
const reminderIntervalOptionsKey = 'meta.reminderIntervalOptions'

const userIdsKey = 'meta.userIds'
const selectedEventKey = 'eventTypeEditor.selectedEvent'

const indexSearchFieldsKey = 'eventTypeEditor.meta.indexSearchFields'
const colorOptionsKey = 'eventTypeEditor.meta.colorOptions'
const eventTypeEditorOpenKey = 'eventTypeEditor.open'
const eventTypeEditorEventTypesKey = 'eventTypeEditor.eventTypes'
const eventTypeLockedKey = 'eventTypeEditor.eventTypeLocked'
const eventTypeEditorGridVisibleKey = 'eventTypeEditor.gridVisible'

export const behaviors = {
  [CONSTANTS.REGISTER_RESPONSE_DATA]: (state, { payload: { data } }) => {
    let result = state
    if (data && data.meta) {
      const { meta, participants } = data

      if (meta.customFields) {
        result = setIn(result, 'meta.customFields', fromJS(meta.customFields))
      }

      /* double check this stuff tomorrow */
      if (data?.activity?.customFields?.customFieldsData?.length) {
        for (
          let i = 0;
          i < data.activity.customFields.customFieldsData.length;
          i++
        ) {
          const customField = data.activity.customFields.customFieldsData[i]
          console.log('customField', customField.fieldNumber, customField.value)
          result = setIn(
            result,
            `fields.customFields-${customField.fieldNumber}.value`,
            fromJS(customField.value)
          )
        }
      }

      const indexSearchFields = meta.referenceOptions.reduce((acc, next) => {
        if (next.indexSearchField) {
          acc = acc.concat({ ...next.indexSearchField })
        }
        return acc
      }, [])
      result = setIn(
        result,
        'meta.indexSearchFields',
        fromJS(indexSearchFields)
      )

      for (let i = 0; i < indexSearchFields.length; i++) {
        result = setIn(
          result,
          `meta.${indexSearchFields[i].fieldName}`,
          fromJS(indexSearchFields[i])
        )
      }

      const userIds =
        participants && Array.isArray(participants)
          ? participants
              .reduce((acc, next) => {
                if (next.isSelected) {
                  acc = acc.concat(next.dataId)
                }
                return acc
              }, [])
              .join(',')
          : data.activity.users

      result = setIn(result, userIdsKey, userIds)
      result = setIn(result, eventTypeOptionsKey, meta.eventTypeOptions)
      result = setIn(result, participantsKey, meta.participants)
      result = setIn(result, referenceOptionsKey, meta.referenceOptions)
      result = setIn(result, taskTypeOptionsKey, meta.taskTypeOptions)
      result = setIn(result, taskColorsKey, meta.taskColors)
      result = setIn(
        result,
        reminderIntervalOptionsKey,
        meta.reminderIntervalOptions
      )
    }

    /* if we are creating a new event, we need to store the selectedStartDate and the user */
    if (data && data.selectedStartDate && data.userId) {
      const startDate = `${timestampFormatter(
        data.selectedStartDate
      )} ${timestampFormatter(data.meta.serverStartDate, 'hh:mm A')}`
      const endDate = `${timestampFormatter(
        data.selectedStartDate
      )} ${timestampFormatter(data.meta.serverEndDate, 'hh:mm A')}`

      result = setIn(result, 'fields.startDate.value', startDate)
      result = setIn(result, 'fields.endDate.value', endDate)
      result = data.userIds
        ? setIn(result, 'fields.users.value', data.userIds)
        : setIn(result, 'fields.users.value', data.userId)
    }

    /* if we are editing an existing calendar item, save the data */
    if (data && data.dataId) {
      const { activity, dataId } = data

      result = setIn(result, 'dataId', dataId)
      result = setIn(result, 'occurredInThePast', activity.occurredInThePast)
      result = setIn(result, 'enteredByDisplay', activity.enteredByDisplay)
      result = setIn(
        result,
        'lastUpdateddByDisplay',
        activity.lastUpdateddByDisplay
      )

      const skipFields = [
        'dataId',
        'occurredInThePast',
        'enteredByDisplay',
        'lastUpdateddByDisplay'
      ]
      for (const prop in activity) {
        if (skipFields.indexOf(prop) === -1) {
          result = setIn(result, `fields.${prop}.value`, activity[prop])

          /* if we have metadata for the referenceId display */
          /* referenceId has some special cases */
          if (prop === 'referenceId' && data.reference) {
            let label
            if (
              data.reference &&
              data.reference.parent &&
              data.reference.parent.description
            ) {
              label = `${data.reference.displayName}: ${data.reference.recordName} from ${data.reference.parent.description}`
            }

            if (data.reference && !data.reference.parent) {
              label = `${data.reference.displayName}: ${data.reference.recordName}`
            }

            result = setIn(
              result,
              'fields.referenceId.value',
              data.reference.name
            )
            result = setIn(result, `fields.${prop}.labelText`, label)
            result = setIn(
              result,
              `fields.${prop}.referenceData`,
              fromJS(data.reference)
            )
          }
        }
      }
    } else if (data && !data.dataId && data.reference) {
      // debugger
      result = setIn(result, 'fields.referenceId.value', data.reference.name)
      result = setIn(
        result,
        'fields.referenceId.referenceData',
        fromJS(data.reference)
      )
    }

    /* store the userId, because we need this for the clearForm method */
    if (data && data.userId) {
      result = setIn(result, 'userId', data.userId)
    }

    /* store the callLocation for later use */
    result = setIn(result, 'callLocation', data.callLocation)
    // result = setIn(result, 'preventAutoDisable', true) /* has no effect, even though it should */

    if (data && data.reference) {
      if (data.reference.searchType === 'Customer') {
        result = setIn(result, 'fields.referenceType.value', 'C')
      }

      if (data.reference.searchType === 'Contacts') {
        result = setIn(result, 'fields.referenceType.value', 'CO')

        /* this means that we are opening an A/R Notes modal */
        if (data.isNotesContact) {
          result = setIn(result, 'fields.eventType.value', 'T')
          result = setIn(result, 'fields.taskType.value', 'AR')
        }
      }

      if (data.reference.searchType === 'Job') {
        result = setIn(result, 'fields.referenceType.value', 'JB')
        result = setIn(result, 'fields.referenceId.value', data.reference.name)
        result = setIn(
          result,
          'fields.referenceId.referenceData',
          fromJS(data.reference)
        )
      }

      if (data.reference.searchType === 'SalesOrderHeader') {
        result = setIn(result, 'fields.referenceType.value', 'SO')
        result = setIn(result, 'fields.referenceId.value', data.reference.name)
        result = setIn(
          result,
          'fields.referenceId.referenceData',
          fromJS(data.reference)
        )
      }

      if (data.reference.searchType === 'InvoiceOrder') {
        result = setIn(result, 'fields.referenceType.value', 'IN')
        result = setIn(result, 'fields.referenceId.value', data.reference.name)
      }
    }

    /* finally, we need to set a flag that we have completed registering any populated fields */
    result = setIn(result, 'initialized', true)
    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevValue: '',
        isNew: false
      })
    )

    return result
  },
  [CONSTANTS.CLEAR_FORM]: (state, payload) => {
    let result = state
    const userId = getIn(result, 'userId')
    const fields = getIn(result, 'fields') ? getIn(result, 'fields').toJS() : {}
    // console.log('clearForm', fields, result.toJS())
    result = setIn(result, 'dataId', '')
    result = setIn(result, 'occurredInThePast', null)
    result = setIn(result, 'enteredByDisplay', null)
    result = setIn(result, 'lastUpdateddByDisplay', null)

    // const skipFields = ['dataId', 'occurredInThePast', 'enteredByDisplay', 'lastUpdateddByDisplay']
    for (const prop in fields) {
      if (prop === 'startDate' || prop === 'endDate') {
        result = setIn(result, `fields.${prop}.value`, new Date())
        result = setIn(result, `fields.${prop}.prevValue`, '')
      } else if (prop === 'users') {
        result = setIn(result, `fields.${prop}.value`, userId)
      } else if (prop === 'eventType') {
        result = setIn(result, `fields.${prop}.value`, 'E')
      } else {
        result = setIn(result, `fields.${prop}.value`, '')
        result = setIn(result, `fields.${prop}.prevValue`, '')
      }
    }

    return result
  },
  [INV_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.FAILURE]: (
    state,
    { payload: { propertyName }, meta: { form } }
  ) => {
    let result = state
    // console.log('exactMatchFail', propertyName, form)
    result = setIn(result, `fields.${propertyName}.value`, '')
    result = setIn(result, `fields.${propertyName}.prevValue`, '')
    return result
  },
  [INV_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload, meta: { form } }
  ) => {
    let result = state
    // console.log('indexSearchResults', payload)
    if (payload.propertyName === 'referenceId' && form === 'eventModal') {
      const label = `${payload.displayName}: ${payload.description} from ${payload.moreInfo.parent.description}`
      result = setIn(result, 'fields.referenceId.value', payload.recordName)
      result = setIn(result, 'fields.referenceId.labelText', label)
      result = setIn(
        result,
        'fields.referenceId.referenceData',
        fromJS(payload)
      )
    }
    return result
  },

  [PO_CONSTANTS.EXACT_MATCH.SUCCESS]: (state, { payload, meta: { form } }) => {
    let result = state
    if (payload.propertyName === 'referenceId' && form === 'eventModal') {
      const label = `Purchase Order: ${payload.recordName} from ${payload.parent.description}`
      // console.log('poExactMatch', payload.propertyName, form, label, payload)
      result = setIn(result, 'fields.referenceId.labelText', label)

      /* still getting funny results from exactMatchResults -- SVE 4/24/20 */
      // debugger
      result = setIn(
        result,
        'fields.referenceId.referenceData',
        fromJS({
          ...payload,
          searchType: 'SalesOrderHeader',
          name: payload.name ? payload.name : payload.dataId
        })
      )
    }
    // "Purchase Order 4001502 from Flanders Prescissionare"

    return result
  },
  [SO_CONSTANTS.EXACT_MATCH.SUCCESS]: (state, { payload, meta: { form } }) => {
    let result = state
    // debugger
    if (payload.propertyName === 'referenceId' && form === 'eventModal') {
      const label = `Sales Order: ${payload.recordName} from ${payload.parent.description}`
      // console.log('soExactMatch', payload.propertyName, form, label, payload)
      result = setIn(result, 'fields.referenceId.labelText', label)

      /* still getting funny results from exactMatchResults -- SVE 4/24/20 */
      // debugger
      result = setIn(
        result,
        'fields.referenceId.referenceData',
        fromJS({
          ...payload,
          searchType: 'SalesOrderHeader',
          name: payload.name ? payload.name : payload.dataId
        })
      )
    }
    return result
  },
  [SO_CONSTANTS.EXACT_MATCH.FAILURE]: (
    state,
    { payload: { propertyName }, meta: { form } }
  ) => {
    let result = state

    if (propertyName === 'referenceId' && form === 'eventModal') {
      result = setIn(result, 'fields.referenceId.value', '')
    }

    return result
  },
  [IDX_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload, meta: { form } }
  ) => {
    let result = state
    // debugger
    // console.log('indexSearchResults', payload)
    if (
      payload &&
      payload.exactMatchResults &&
      payload.propertyName === 'referenceId' &&
      form === 'eventModal'
    ) {
      const label = payload.exactMatchResults.parent
        ? `${payload.exactMatchResults.displayName}: ${payload.exactMatchResults.description} from ${payload.exactMatchResults.parent.description}`
        : `${payload.exactMatchResults.displayName}: ${payload.exactMatchResults.description}`
      result = setIn(result, 'fields.referenceId.labelText', label)
      result = setIn(
        result,
        'fields.referenceId.referenceData',
        fromJS(payload.exactMatchResults)
      )
    }

    // debugger
    if (
      payload &&
      payload.exactMatchResults &&
      payload.propertyName === 'newEventType' &&
      form === 'eventModal'
    ) {
      // debugger
      const eventTypes = getIn(result, 'eventTypeEditor.meta.eventTypes')
      if (eventTypes) {
        // eventTypes = eventTypes.toJS()
        if (payload.exactMatchResults.dataId) {
          setIn(
            result,
            'fields.newEventType.value',
            payload.exactMatchResults.dataId
          )
        }

        const selectedRecord = eventTypes.filter(
          x => x.recordName === payload.exactMatchResults.recordName
        )
        if (selectedRecord.length === 1 && selectedRecord[0].colCd) {
          result = setIn(result, 'fields.color.value', selectedRecord[0].colCd)
          result = setIn(result, 'fields.newEventType.isNew', false)
          result = setIn(result, eventTypeLockedKey, false)
        }
      }
      result = setIn(result, 'fields.newEventType.isSet', true)
    }

    // if (payload && payload.propertyName === 'newEventType' && form === 'eventModal' && !payload.exactMatchResults) {
    //   debugger
    // }

    return result
  },
  [IDX_SEARCH_CONSTANTS.BLUR]: (state, { payload }) => {
    let result = state
    const callLocation = getIn(state, 'callLocation')
    const referenceData = getIn(state, 'fields.referenceId.referenceData')
      ? getIn(state, 'fields.referenceId.referenceData').toJS()
      : {}
    if (
      payload.propertyName === 'referenceId' &&
      callLocation === 'contact' &&
      Object.keys(referenceData).length &&
      referenceData.name
    ) {
      result = setIn(result, 'fields.referenceId.value', referenceData.name)
    }

    return result
  },
  // [IDX_SEARCH_CONSTANTS.PARTIAL_MATCH_SEARCH.SUCCESS]: (state, { payload, meta: { form } }) => {
  //   let result = state
  //   if (payload && payload.partialMatchResults && payload.propertyName === 'newEventType' && form === 'eventModal') {
  //     result = setIn(result, 'eventTypeEditor.meta.eventTypes', payload.partialMatchResults.results)
  //   }
  //   return result
  // },
  [IDX_SEARCH_CONSTANTS.PARTIAL_MATCH_SEARCH.SUCCESS]: (
    state,
    { payload, meta: { form } }
  ) => {
    let result = state
    const newEventType = getIn(result, 'fields.newEventType.value')
    if (
      payload &&
      payload.grid &&
      payload.propertyName === 'newEventType' &&
      form === 'eventModal' &&
      !newEventType
    ) {
      result = setIn(
        result,
        'eventTypeEditor.meta.eventTypes',
        payload.grid.rowData
      )
    }

    const isNew = getIn(result, 'fields.newEventType.isNew')
    // const isSet = getIn(result, 'fields.newEventType.isSet')
    if (isNew) {
      result = setIn(result, 'fields.newEventType.isSet', true)
    }
    return result
  },
  [DDI_FORM_CONSTANTS.BLUR]: (
    state,
    { payload: { propertyName }, meta: { form } }
  ) => {
    let result = state
    const initialized = getIn(result, 'initialized')
    if (
      form === 'eventModal' &&
      propertyName === 'referenceType' &&
      initialized === true
    ) {
      const prev = getIn(result, 'fields.referenceType.prevValue')
      const curr = getIn(result, 'fields.referenceType.value')
      const fieldExists = getIn(result, 'fields.referenceId')
      // console.log('DDI_FORM_CONSTANTS.BLUR', `PREV: ${prev}`, `CURR: ${curr}`)
      if (prev !== curr && fieldExists) {
        result = setIn(result, 'fields.referenceId.value', '')
        result = setIn(result, 'fields.referenceId.prevValue', '')
        result = setIn(result, 'fields.referenceId.labelText', '')
        result = setIn(result, 'fields.referenceId.referenceData', fromJS({}))
      }
    }
    return result
  },
  [DDI_FORM_CONSTANTS.SET_FIELD]: (
    state,
    { payload: { propertyName, value } }
  ) => {
    let result = state
    const prevReferenceTypeVal = getIn(result, 'fields.referenceType.prevValue')
    // console.log('DDI_FORM_CONSTANTS.SET_FIELD', propertyName, value, prevReferenceTypeVal)
    if (propertyName === 'referenceType' && value !== prevReferenceTypeVal) {
      result = setIn(result, 'fields.referenceId.value', '')
      result = setIn(result, 'fields.referenceId.prevValue', '')
      result = setIn(result, 'fields.referenceId.labelText', '')
      result = setIn(result, 'fields.referenceId.referenceData', fromJS({}))
    }

    const referenceId = getIn(result, 'fields.referenceId.value')
    if (propertyName === 'referenceId' && value === '') {
      result = setIn(result, 'fields.referenceId.referenceData', fromJS({}))
    }

    const prevEventTypeVal = getIn(result, 'fields.eventType.prevValue') || ''
    if (
      propertyName === 'eventType' &&
      value &&
      value !== 'T' &&
      !getIn(result, 'fields.endDate.value') &&
      value !== prevEventTypeVal
    ) {
      result = setIn(result, 'fields.endDate.value', fromJS(new Date()))
      result = setIn(result, 'values.endDate', fromJS(new Date()))
    }

    return result
  },
  // [CONSTANTS.LOCK_ACTIVITY.REQUEST]: (state, { payload }) => {
  //   const result = state
  //   return result
  // },
  // [CONSTANTS.LOCK_ACTIVITY.SUCCESS]: (state, { payload }) => {
  //   let result = state
  //   result = setIn(result, activityKey, payload.activity)
  //   result = setIn(result, participantsKey, payload.meta.participants)
  //   result = setIn(result, eventTypeOptionsKey, payload.meta.eventTypeOptions)
  //   result = setIn(result, taskTypeOptionsKey, payload.meta.taskTypeOptions)
  //   result = setIn(result, referenceOptionsKey, payload.meta.referenceOptions)
  //   result = setIn(
  //     result,
  //     reminderIntervalOptionsKey,
  //     payload.meta.reminderIntervalOptions
  //   )
  //   result = setIn(result, openKey, true)
  //   return result
  // },
  [CONSTANTS.UNLOCK_ACTIVITY.REQUEST]: (state, { payload }) => {
    let result = state
    result = setIn(result, openKey, false)
    return result
  },
  [CONSTANTS.UNLOCK_ACTIVITY.SUCCESS]: (state, { payload }) => {
    let result = state
    /* empty out the activity from state here */
    result = setIn(result, activityKey, {})
    result = setIn(result, openKey, false)
    return result
  },
  [CONSTANTS.SAVE_ACTIVITY.REQUEST]: (state, { payload }) => {
    const result = state
    // console.log('result', result.toJS())
    return state
  },
  [CONSTANTS.SAVE_ACTIVITY.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, activityKey, {})
    result = setIn(result, 'fields', fromJS({}))
    result = setIn(result, openKey, false)
    return result
  },
  [CONSTANTS.GET_SERVER_TIME.SUCCESS]: (state, { payload }) => {
    let result = state
    const { endDate } = payload
    result = setIn(result, 'fields.endDate.value', endDate)
    return result
  },
  [CONSTANTS.ADD_EVENT_TYPE.SUCCESS]: (
    state,
    { payload: { colorOptions, searchFields } }
  ) => {
    // console.log(colorOptions, searchFields)
    // debugger
    let result = state
    result = setIn(result, indexSearchFieldsKey, searchFields)
    result = setIn(result, colorOptionsKey, colorOptions)
    result = setIn(result, eventTypeLockedKey, false)
    result = setIn(result, eventTypeEditorOpenKey, true)

    for (let i = 0; i < searchFields.length; i++) {
      result = setIn(
        result,
        `meta.${searchFields[i].fieldName}`,
        fromJS(searchFields[i])
      )
    }

    return result
  },
  [CONSTANTS.GET_EVENT_TYPES.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(
      result,
      eventTypeEditorEventTypesKey,
      payload.partialMatchResults.results
    )
    result = setIn(
      result,
      'eventTypeEditor.columnHeaders',
      payload.partialMatchResults.columnHeaders
    )
    result = setIn(result, eventTypeEditorGridVisibleKey, true)
    return result
  },
  [CONSTANTS.VALIDATE_EVENT_TYPE.SUCCESS]: (state, { payload }) => {
    let result = state
    const { color, dataId, isNew } = payload
    const selectedEvent = {
      color,
      dataId,
      isNew
    }
    result = setIn(result, selectedEventKey, selectedEvent)
    result = setIn(result, eventTypeEditorGridVisibleKey, false)
    return result
  },
  [CONSTANTS.CREATE_EVENT_TYPE.SUCCESS]: (state, { payload }) => {
    let result = state
    const { color, dataId, isNew } = payload
    // const selectedEvent = { color, dataId, isNew }
    // result = setIn(result, selectedEventKey, selectedEvent)
    // result = setIn(result, eventTypeEditorGridVisibleKey, false)
    if (isNew) {
      result = setIn(result, eventTypeLockedKey, true)
    }
    result = setIn(result, 'fields.newEventType.value', dataId)
    result = setIn(result, 'fields.newEventType.isSet', true)
    result = setIn(result, 'fields.newEventType.isNew', isNew)
    result = setIn(result, 'fields.newEventType.editorKey', shortid.generate())

    if (color) {
      result = setIn(result, 'fields.color.value', color)
    }
    return result
  },
  [CONSTANTS.SAVE_COLOR_SELECTION]: (state, { payload: { color }, meta }) => {
    let result = state
    result = setIn(result, 'fields.color.value', color)
    return result
  },
  [CONSTANTS.SAVE_EVENT_TYPE.SUCCESS]: (
    state,
    { payload: { color, dataId, isNew } }
  ) => {
    let result = state
    const eventDataId = getIn(state, 'dataId') || null
    // console.log('eventDataId', eventDataId)
    // result = setIn(result, eventTypeOptionsKey, meta.eventTypeOptions)
    let eventTypeOptions = getIn(result, eventTypeOptionsKey) || []
    const colorOptions = getIn(result, colorOptionsKey) || []
    const selectedColor = colorOptions.filter(x => x.dataId === color)[0].color
    eventTypeOptions = eventTypeOptions.find(x => x.dataId === dataId)
      ? eventTypeOptions.reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            color: next.dataId === dataId ? selectedColor : next.color
          })

          return acc
        }, [])
      : eventTypeOptions.concat({
          color: selectedColor,
          dataId,
          description: dataId
        })

    eventTypeOptions.sort((a, b) => {
      const descA = a.description.toUpperCase()
      const descB = b.description.toUpperCase()
      if (descA < descB) {
        return -1
      }
      if (descA > descB) {
        return 1
      }
      return 0
    })

    const mainListOptions = []
    const secondaryListOptions = []
    for (let i = 0; i < eventTypeOptions.length; i++) {
      if (
        eventTypeOptions[i].description === 'Event' ||
        eventTypeOptions[i].description === 'Task'
      ) {
        mainListOptions.push(eventTypeOptions[i])
      } else {
        secondaryListOptions.push(eventTypeOptions[i])
      }
    }
    const updatedEventTypeOptions = mainListOptions.concat(secondaryListOptions)

    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevVal: '',
        isNew: ''
      })
    )
    result = setIn(result, 'fields.color.value', '')
    result = setIn(result, eventTypeOptionsKey, updatedEventTypeOptions)
    result = setIn(result, eventTypeEditorOpenKey, false)

    if (!eventDataId) {
      result = setIn(result, 'fields.eventType.value', dataId)
    }

    return result
  },
  [CONSTANTS.LOCK_EVENT_TYPE.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, eventTypeLockedKey, true)
    return result
  },
  [CONSTANTS.CLEAR_EVENT_TYPE_FORM]: (state, { payload }) => {
    let result = state
    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevVal: '',
        isNew: false,
        editorKey: shortid.generate()
      })
    )

    result = setIn(result, 'fields.color.value', '')
    result = setIn(result, eventTypeLockedKey, false)
    result = setIn(result, eventTypeEditorOpenKey, true)
    return result
  },
  [CONSTANTS.CLEAR_NEW_EVENT_TYPE_POPOVER]: (state, { payload }) => {
    let result = state
    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevVal: '',
        isNew: ''
      })
    )
    result = setIn(result, 'fields.color.value', '')
    result = setIn(result, eventTypeLockedKey, false)
    result = setIn(result, eventTypeEditorOpenKey, true)
    return result
  },
  // [CONSTANTS.UNLOCK_EVENT_TYPE.REQUEST]: (state, { payload }) => {
  //   let result = state
  //   result = setIn(result, eventTypeEditorOpenKey, payload.editorOpen)
  //   return result
  // },
  [CONSTANTS.UNLOCK_EVENT_TYPE.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, eventTypeLockedKey, false)

    if (payload.editorOpen === false || payload.isEditing || !payload.record) {
      result = setIn(
        result,
        'fields.newEventType',
        fromJS({
          value: '',
          prevVal: '',
          isNew: '',
          editorKey: shortid.generate()
        })
      )
      result = setIn(result, 'fields.color.value', '')
    }

    result = setIn(result, eventTypeEditorOpenKey, payload.editorOpen)
    return result
  },
  [CONSTANTS.CLOSE_EVENT_MODAL]: (state, action) => {
    let result = state
    result = setIn(result, eventTypeEditorOpenKey, false)
    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevVal: '',
        isNew: ''
      })
    )
    result = setIn(result, 'fields.color.value', '')
    // result = setIn(result, openKey, false)
    return result
  },
  [CONSTANTS.DELETE_EVENT_TYPE.SUCCESS]: (state, { payload: { dataId } }) => {
    let result = state
    // console.log('deleteEventTypeSuccess', dataId)
    const eventTypeOptions = getIn(result, eventTypeOptionsKey) || []
    const updatedEventTypeOptions = eventTypeOptions.reduce((acc, next) => {
      if (next.dataId !== dataId) {
        acc = acc.concat(next)
      }
      return acc
    }, [])
    result = setIn(result, eventTypeOptionsKey, updatedEventTypeOptions)
    result = setIn(result, eventTypeLockedKey, false)
    result = setIn(result, eventTypeEditorOpenKey, false)
    result = setIn(
      result,
      'fields.newEventType',
      fromJS({
        value: '',
        prevVal: '',
        isNew: ''
      })
    )
    result = setIn(result, 'fields.color.value', '')
    return result
  },
  [CONSTANTS.DELETE_ACTIVITY.SUCCESS]: (state, { payload }) => {
    const result = state
    // const key = activitiesKey
    // result = setIn(result, key, fromJS(payload.activities))
    return result
  },
  [CONSTANTS.SAVE_ACCESS_MODAL_ID]: (
    state,
    { payload: { accessOverrideModalId } }
  ) => {
    let result = state
    result = setIn(result, 'accessOverrideModalId', accessOverrideModalId)
    return result
  }
}

export default createReducer(initialState, behaviors)
