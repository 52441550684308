/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const MASTER_DETAIL_DROPDOWN = '@@MASTER_DETAIL_DROPDOWN@@'

export const DELETE_CATEGORY = createAsyncRequestTypes(
  `${MASTER_DETAIL_DROPDOWN}DELETE_CATEGORY`
)

export const ADD_CATEGORY = `${MASTER_DETAIL_DROPDOWN}ADD_CATEGORY`

export const SET_SELECTED_CATEGORY = `${MASTER_DETAIL_DROPDOWN}SET_SELECTED_CATEGORY`

export const DRAG_CATEGORY = `${MASTER_DETAIL_DROPDOWN}DRAG_CATEGORY`
