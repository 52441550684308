import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@material-ui/core'
import { debounce } from 'lodash'
import { openPartNumbersNotesEditor } from '../actions'

const PartNumbersNotesEditorCell = ({ value, data, colDef, node }) => {
  const dispatch = useDispatch()
  const disabled = !data.dataId

  const launchPartNumbersNotesEditor = debounce(e => {
    if (colDef?.cellRendererParams?.form)
      dispatch(
        openPartNumbersNotesEditor(colDef?.cellRendererParams?.form, {
          field: colDef.field,
          note: value,
          rowId: data.rowId,
          dataId: data.dataId,
          description: data.description,
          rowIndex: node.childIndex,
          propertyName: colDef?.cellRendererParams?.propertyName,
          isEditing: colDef?.cellRendererParams?.isEditing
        })
      )
  }, 500)

  const icon = value ? (
    <IconButton disabled={disabled} onClick={launchPartNumbersNotesEditor}>
      <Icon
        style={{
          color: disabled ? '#999' : '#5cb85c',
          fontSize: 14,
          verticalAlign: 'middle'
        }}
      >
        check_circle
      </Icon>
    </IconButton>
  ) : (
    <IconButton disabled={disabled} onClick={launchPartNumbersNotesEditor}>
      <Icon
        style={{
          color: disabled ? '#999' : '#d9534f',
          fontSize: 14,
          verticalAlign: 'middle'
        }}
      >
        block
      </Icon>
    </IconButton>
  )

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      {icon}
    </div>
  )
}

PartNumbersNotesEditorCell.propTypes = {
  data: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired
}

export default PartNumbersNotesEditorCell
