import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { useDispatch } from 'react-redux'
import { Map } from 'immutable'
import { withContext } from 'ddiForm'
import PivotTable from 'components/PivotTable'
import withDimensions from 'hoc/withDimensions'
import usePrevious from 'hooks/usePrevious'
import {
  Paper,
  AppBar,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getInventoryData } from 'pages/ProductMaster/actions'

import { getValue, emptyList, fromJS, getIn, plainDeepEqual } from 'utils'

import '../../styles/master-style.scss'
import JohnstoneNational from './JohnstoneNational'

const styles = {
  tabStyles: { height: 30, minHeight: 30 },
  appBarStyles: {
    background: '#c1d5e5',
    boxShadow: 'none',
    height: 30,
    minHeight: 30,
    marginTop: 0
  }
}

export const SecondaryTabs = withStyles({
  root: {
    height: 30,
    minHeight: 30
  }
})(Tabs)

const getPivotTableData = memoize((companies, tabId) => {
  let data =
    companies?.find(x => x?.get('dataId') === tabId)?.get('stockInfo') ||
    fromJS({
      dataId: tabId,
      description: '',
      stockInfo: []
    })?.get('stockInfo') ||
    []

  if (data && data?.toJS) {
    data = data.toJS()
  }

  if (Array.isArray) {
    return data
  }

  return []
}, plainDeepEqual)

export const Inventory = props => {
  const {
    companies,
    dataId,
    form,
    isJohnstoneCustomer,
    meta,
    inventoryNames,
    fetchingEntity
  } = props
  const tabNames =
    inventoryNames && inventoryNames?.toJS ? inventoryNames.toJS() : []
  const initialInventoryTab = tabNames?.[0]?.dataId || ''
  const [tabId, setTabId] = useState(initialInventoryTab)
  const dispatch = useDispatch()
  const pivotTableData = getPivotTableData(companies, tabId)
  const previousDataId = usePrevious(dataId)

  useEffect(() => {
    if (
      (previousDataId && !dataId) ||
      (previousDataId && previousDataId !== dataId)
    ) {
      setTabId(initialInventoryTab)
    }
  }, [dataId, previousDataId])

  const handleTabChange = (event, tab) => {
    if (fetchingEntity) {
      return
    }

    if (tab === initialInventoryTab) {
      setTabId(tab)
    } else {
      dispatch(
        getInventoryData.try(form, {
          tab,
          cb: setTabId
        })
      )
    }
  }

  return (
    <div className="layout-flex" style={{ flexDirection: 'column' }}>
      <div style={{ width: '100%' }}>
        <AppBar position="static" style={styles.appBarStyles}>
          <SecondaryTabs
            value={tabId}
            onChange={handleTabChange}
            aria-label="Inventory Tabs"
          >
            {tabNames.map((item, i) => (
              <Tab
                label={item?.description}
                value={item?.dataId}
                key={item?.dataId}
                style={styles.tabStyles}
              />
            ))}
            {isJohnstoneCustomer ? (
              <Tab
                label="Johnstone"
                value="Johnstone"
                key="Johnstone"
                style={styles.tabStyles}
              />
            ) : null}
          </SecondaryTabs>
        </AppBar>
        {tabId === 'Johnstone' ? (
          <JohnstoneNational {...props} />
        ) : (
          <Paper style={{ width: '100%' }}>
            <PivotTable
              data={pivotTableData}
              meta={meta && meta?.toJS ? meta.toJS() : {}}
              rowFormattedToDollar={['Stock Value', 'Whse Cst']}
              highlightedColumn="All Warehouses"
              cellStyle={{
                padding: 5,
                fontSize: 11,
                lineHeight: '10px',
                textAlign: 'right'
              }}
              headerStyle={{
                padding: 5,
                fontSize: 12,
                lineHeight: '12px',
                textAlign: 'right'
              }}
            />
          </Paper>
        )}
      </div>
    </div>
  )
}

Inventory.propTypes = {
  companies: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
}

export const contextObj = {
  companies: form => getValue(form, 'companies', emptyList),
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  fetchingEntity: form => form.get('fetchingEntity') || false,
  meta: form => getValue(form, 'meta', Map({ columns: [] })),
  inventoryNames: form => getIn(form, 'meta.inventoryNames') || fromJS([]),
  isJohnstoneCustomer: form =>
    getIn(form, 'values.isJohnstoneCustomer') || false
}

const inventoryWithContext = withContext(contextObj)(Inventory)
export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  inventoryWithContext
)
