import React, { Component, Fragment, createElement, createRef } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import shouldUpdate from 'hoc/shouldUpdate'
import { deepEqual, isGrid, getIn, noop } from 'utils'
import * as CONSTANTS from './constants'
// import deep from 'deep-diff'
import {
  isDisabled,
  validateField,
  onDoubleClick,
  disableMasterFieldsIfNoRecord,
  validateComponentProp
} from './utils'
import { onDoubleClick as onDoubleClickAction } from './MasterScreen/actions'

const propsToNotUpdateFor = [
  'dispatch',
  'blur',
  'change',
  'onBlur',
  'onDoubleClick',
  'meta',
  'onChange',
  'onFocus',
  'focus',
  'getFormState',
  // 'propertyChange',
  'setField',
  'style'
]
// paginate: PropTypes.shape({
//   next: PropTypes.func,
//   prev: PropTypes.func,
// }),
class ConnectedField extends Component {
  static propTypes = {
    component: validateComponentProp,
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string,
      PropTypes.number
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    metaKey: PropTypes.string,
    propertyName: PropTypes.string.isRequired,
    forwardRef: PropTypes.bool,
    wrapperStyle: PropTypes.object,
    errorIconStyle: PropTypes.object
  }

  static defaultProps = {
    initialValue: null,
    metaKey: null,
    wrapperStyle: {
      clear: 'both',
      position: 'relative'
    },
    errorIconStyle: {
      bottom: 2,
      color: '#d9534f',
      height: 20,
      position: 'absolute',
      right: -25,
      width: 20,
      fontSize: 16,
      zIndex: 1
    }
  }

  getRenderedComponent() {
    // debugger
    return this.ref.current
  }

  ref = createRef()

  // getRenderedComponent() {
  //   return this.refs.renderedComponent // eslint-disable-line react/no-string-refs
  // }
  // componentDidUpdate() {
  //   console.log(this.ref)
  // }
  // componentDidUpdate(prevProps, prevState) {
  //   Object.keys(this.props).forEach(prop => {
  //     if (!deepEqual(this.props[prop], prevProps[prop])) {
  //       console.info(
  //         'connectedField',
  //         prop,
  //         'changed',
  //         prevProps[prop],
  //         '==>',
  //         this.props[prop]
  //       )
  //     }
  //   })
  // }

  render() {
    const {
      component,
      wrapperStyle,
      forwardRef,
      errorIconStyle,
      ...rest
    } = this.props
    const custom = {}
    if (forwardRef) {
      custom.ref = this.ref
    }
    // console.log('ConnectedField render', this.props.propertyName)
    return (
      <div style={wrapperStyle}>
        {createElement(component, { ...rest, ...custom })}
        {rest.errorText ? ( // todo: error location
          <div title={rest.errorText}>
            <Icon style={errorIconStyle}>error_outline</Icon>
          </div>
        ) : null}
      </div>
    )
  }
}

const connector = connect(
  (state, ownProps) => {
    const {
      metaKey,
      propertyName,
      getEntity,
      getFormState,
      partialMatchSearch,
      exactMatchSearch,
      findPrev,
      findNext,
      form,
      getSearchFilters,
      columnDefs,
      dispatch
    } = ownProps

    let { initialValue } = ownProps
    if (initialValue) {
      if (initialValue.type) {
        // debugger
        initialValue =
          initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
            ? initialValue.value
            : getIn(ownProps._ddiForm, initialValue.value)
      }
    }
    const formState = getFormState(state)
    const isMaster = !!getIn(formState, 'masterOptions')
    const dataId = getIn(formState, 'dataId') || ''
    const focus = ownProps.focus.bind(null, propertyName)
    const blur = ownProps.blur.bind(null, propertyName)
    const change = ownProps.change.bind(null, propertyName)

    const setField = ownProps.setField.bind(null, propertyName)

    let field = getIn(formState, `fields.${propertyName}`) // .toJS()

    field = field?.toJS ? field.toJS() : field || {}
    const isFound = getIn(formState, 'isFound') || false
    const isEditing = getIn(formState, 'isEditing') || false
    const hasRecord = getIn(formState, 'hasRecord') || false
    const isFiltered = getIn(field, 'isFiltered')
    let meta = getIn(formState, 'meta')
    if (metaKey && meta) {
      meta = getIn(meta, metaKey)
      meta = meta ? meta.toJS() : {}
    } else if (!metaKey || !meta) {
      meta = {}
    }
    meta = ownProps.meta ? ownProps.meta : meta
    meta = meta || {}

    let disabled = isDisabled(ownProps, field, formState)
    if (meta.disabled) {
      disabled = !!meta.disabled
    }

    // meta = meta ? meta.toJS() : {}
    const isEntitySuccessComplete =
      getIn(formState, 'isEntitySuccessComplete') || false
    const defaultOnChange = event => {
      event =
        typeof event === 'object' && event !== null ? event.target.value : event
      setField(event)
    }

    const onChange = ownProps.onChange || defaultOnChange
    let onTab
    if (ownProps.onTab) {
      if (typeof ownProps.onTab === 'string') {
        const act = getIn(ownProps?.actions, ownProps.onTab) || noop
        onTab = value => dispatch(act(form, ownProps.propertyName, value))
        // console.log(act, onTab)
      }
    }
    // const errorText = validateField(field, formState, ownProps)
    let errorText = validateField(field, formState, ownProps)
    errorText = errorText && ownProps.errorText ? ownProps.errorText : errorText
    const doubleClick = () => onDoubleClick(formState, ownProps)
    let props = {
      _value: ownProps.value, // save value passed in (for checkboxes)
      onBlur: blur,
      change,
      dataId,
      initialValue,
      exactMatchSearch,
      isEditing,
      isFiltered,
      findPrev,
      findNext,
      focus,
      getEntity,
      hasRecord,
      isEntitySuccessComplete,
      isFound,
      meta,
      onChange,
      partialMatchSearch,
      // propertyChange,
      setField,
      onTab,
      errorText,
      disabled,
      getSearchFilters,
      onDoubleClick: doubleClick,
      columnDefs
    }
    props = {
      ...field,
      ...props,
      disabled
    }
    if (form.toLowerCase().includes('inquiry')) {
      props.isInquiryScreen = true
    }
    if (ownProps.multiline) {
      props.inputProps = {}
    }
    if (meta.defaultValue) {
      props.value = meta.defaultValue
    }
    if (props.value === null || props.value === undefined) {
      props.value = ''
    }
    if (props.valueKey) {
      props.value = props[props.valueKey]
    }
    return props
  },
  undefined,
  undefined,
  { forwardRef: true }
)

export default connector(
  shouldUpdate({ blacklist: propsToNotUpdateFor })(ConnectedField)
)
