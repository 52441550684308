import React from 'react'
import { ddiForm } from 'ddiForm'
import { Icon } from '@material-ui/core'
import memoize from 'memoize-one'
import {
  getFormSelector,
  getSelectedTabsFromState,
  mapResponse
} from 'ddiForm/utils'
import { getReportCriteria } from 'components/SelectionCriteriaModal/actions'
import Audit from 'components/MasterScreen/Audit'
import { mapAuditResponse } from 'components/MasterScreen/Audit/utils'
import MeasuredModal from 'components/MasterScreen/MeasuredModal'
import Notes from 'components/MasterScreen/Notes'
import CustomFields from 'components/MasterScreen/CustomFields'
import Attachments from 'components/MasterScreen/Attachments'
import {
  mapCustomFieldsResponse,
  transformCustomFieldsData,
  checkIfCustomFieldsHidden
} from 'components/MasterScreen/CustomFields/utils'

import { select } from 'redux-saga/effects'

import {
  lockTemplate,
  unlockTemplate,
  saveTemplate,
  deleteTemplate,
  exitTemplate
} from 'components/MasterScreen/Template/actions'

import * as actions from './actions'

import sagas, {
  auditAccessCheckProcess,
  saveArgumentsSaga,
  onSaveProductProcess
} from './sagas'

import behaviors from './behaviors'
import * as api from './api'

import LeftFooterButtons from './components/LeftFooterButtons'
import RightFooterButtons from './components/RightFooterButtons'
import SearchArea from './components/SearchArea'

import Assembly from './tabs/Analysis/Assembly'
import Overview from './tabs/Analysis/Overview'
import Demand from './tabs/Analysis/Demand'
import Vendors from './tabs/Analysis/Vendors'
import Transactions from './tabs/Analysis/Transactions'
import SalesOrders from './tabs/Analysis/SalesOrders'
import PurchaseOrders from './tabs/Analysis/PurchaseOrders'
import Transfers from './tabs/Analysis/Transfers'
import WorkOrders from './tabs/Analysis/WorkOrders'
import SerialNumbers from './tabs/Analysis/SerialNumbers'
import Lots from './tabs/Analysis/Lots'
import Substitutes from './tabs/Analysis/Substitutes'
import AnalysisBins from './tabs/Analysis/Bins'
import JohnstoneNational from './tabs/Inventory/JohnstoneNational'

import Inventory from './tabs/Inventory'
import Johnstone from './tabs/Johnstone'
import Pricing from './tabs/Pricing'

import Assemblies from './tabs/Setup/Assemblies'
import Bins from './tabs/Setup/Bins'
import General from './tabs/Setup/General'
import Keywords from './tabs/Setup/Keywords'
import Main from './tabs/Setup/Main'
import OptionsAndAccessories from './tabs/Setup/OptionsAndAccessories'
import PartNumbers from './tabs/Setup/PartNumbers'
import Purchasing from './tabs/Setup/Purchasing'
import Replenishments from './tabs/Setup/Replenishments'
import RollUpHistory from './tabs/Setup/RollUpHistory'
import SetupSubs from './tabs/Setup/Substitutes'
import SetupVendors from './tabs/Setup/Vendors'
import WebOptions from './tabs/Setup/WebOptions'
import SecondaryPriceGroups from './tabs/Setup/SecondaryPriceGroups'

import {
  mapAnalysisResponse,
  mapAnalysisResponseWithData,
  mapBinsResponse,
  mapDataResponse
} from './utils'

import './styles/css-mod-ignore.scss'
import JohnstoneWebNode from './tabs/Setup/JohnstoneWebNode'

const tabIconStyle = {
  fontSize: 18,
  marginBottom: 0,
  marginRight: 5,
  verticalAlign: 'middle'
}

export function* cancelEditGroupNamesSaga(form) {
  const formState = yield select(getFormSelector(form))
  const groupNames = getSelectedTabsFromState(formState)
  console.log(groupNames)

  // do work
  // return array of groupNames..
  return !groupNames.includes('purchasing') ? ['purchasing'] : []
}

const options = {
  apiAlias: 'productMaster',
  form: 'productMaster',
  title: 'Product Master',
  masterOptions: {
    newApi: true,
    ignoreResetFields: ['selectedWarehouseId'],
    headerComponent: SearchArea,
    footer: {
      left: LeftFooterButtons,
      right: RightFooterButtons
    },
    saveArgumentsSaga,
    // cancelEditGroupNamesSaga,
    getRecordArgumentsSaga: saveArgumentsSaga,
    transformDataBeforeSave: transformCustomFieldsData,
    tabs: [
      {
        icon: <Icon style={tabIconStyle}>settings_applications</Icon>,
        tabs: [
          {
            component: Main,
            title: 'Main',
            access: 'main',
            callAlways: true
          },
          {
            component: General,
            title: 'General',
            access: 'general'
            // mapResponse: function foo(...rest) {
            //   console.log(...rest)
            //   debugger
            //   return mapResponse(...rest)
            // }
          },
          {
            component: Purchasing,
            title: 'Purchasing',
            access: 'purchasing'
          },
          {
            component: Notes,
            title: 'Notes',
            access: 'notes'
          },
          {
            component: OptionsAndAccessories,
            title: 'Options & Accessories',
            access: 'optionsAccessories'
          },
          {
            component: Assemblies,
            title: 'Assemblies',
            // access: form => wrapMasterTabAccess(form, 'assemblies'),
            access: 'assemblies'
          },
          {
            component: Bins,
            title: 'Bins',
            access: 'bins',
            isHidden: formState =>
              formState.values && formState.values.templateKey
          },
          {
            component: Replenishments,
            title: 'Replenishments',
            access: 'replenishments'
          },
          {
            component: SetupVendors,
            title: 'Vendors',
            access: 'vendors'
          },
          {
            component: SetupSubs,
            title: 'Substitutes',
            access: 'substitutes'
          },
          {
            component: Keywords,
            title: 'Keywords',
            access: 'keywords'
          },
          {
            component: SecondaryPriceGroups,
            title: 'Secondary Price Groups',
            access: 'secondarypricegroups'
          },
          {
            component: WebOptions,
            title: 'Web Options',
            access: 'webOptions'
          },
          {
            component: PartNumbers,
            isHidden: formState =>
              formState.preNewMode ||
              formState.newMode ||
              (formState.values && formState.values.templateKey) ||
              false,
            title: 'Part Numbers',
            access: 'partNumbers'
          },
          {
            component: RollUpHistory,
            title: 'Roll-Up History',
            access: 'rolluphistory'
          },
          {
            component: CustomFields,
            title: 'Custom Fields',
            access: 'customfields',
            mapResponse: mapCustomFieldsResponse,
            isHidden: checkIfCustomFieldsHidden,
            callAlways: true
          },
          {
            component: JohnstoneWebNode,
            title: 'Johnstone',
            access: 'johnstoneSetup',
            isHidden: formState => !formState.meta.isJohnstoneCustomer
          }
        ],
        title: 'Setup',
        access: 'setup',
        callAlways: true
      },
      {
        title: 'Analysis',
        access: 'analysis',
        icon: <Icon style={tabIconStyle}>multiline_chart</Icon>,
        tabs: [
          {
            component: Overview,
            title: 'Overview',
            callAlways: true,
            access: 'overview',
            mapResponse: mapAnalysisResponse
          },
          {
            component: Assembly,
            title: 'Assembly',
            access: 'assembly',
            callAlways: true
          },
          {
            component: Demand,
            title: 'Demand',
            access: 'demand',
            callAlways: true
          },
          {
            component: Vendors,
            title: 'Vendors',
            access: 'productVendors'
            // mapResponse: mapAnalysisResponse
          },
          {
            component: Transactions,
            title: 'Transactions',
            access: 'transactions',
            callAlways: true,
            mapResponse: mapAnalysisResponse
          },
          {
            component: SalesOrders,
            title: 'Sales Orders',
            access: 'salesOrders',
            callAlways: true,
            mapResponse: mapAnalysisResponse
          },
          {
            component: PurchaseOrders,
            title: 'Purchase Orders',
            access: 'purchaseOrders'
          },
          {
            component: Transfers,
            title: 'Transfers',
            access: 'transfers'
          },
          {
            component: WorkOrders,
            title: 'Work Orders',
            access: 'workOrders',
            callAlways: true
          },
          {
            component: SerialNumbers,
            title: 'Serial Numbers',
            access: 'serialNumbers',
            callAlways: true
          },
          {
            component: Lots,
            title: 'Lots',
            access: 'lots',
            callAlways: true
          },
          {
            component: Substitutes,
            title: 'Substitutes',
            access: 'productSubstitutes',
            callAlways: true
          },
          {
            component: AnalysisBins,
            title: 'Bins',
            access: 'productBins',
            callAlways: true
            // mapResponse: mapBinsResponse
          }
        ],
        isHidden: formState =>
          formState.preNewMode ||
          formState.newMode ||
          (formState.values && formState.values.templateKey)
      },
      // {
      //   icon: <Icon style={tabIconStyle}>assignment_turned_in</Icon>,
      //   tabs: [
      //     {
      //       component: Inventory,
      //       title: 'Inventory',
      //       access: 'inventory'
      //     },
      //     {
      //       component: JohnstoneNational,
      //       title: 'Johnstone National',
      //       isHidden: formState => {
      //         if (formState.values && !formState.values.isJohnstoneCustomer) {
      //           return true
      //         }

      //         return false
      //       },
      //       access: 'johnstoneNational'
      //     }
      //   ],
      //   title: 'Inventory',
      //   access: 'inventory',
      //   isHidden: formState =>
      //     formState.preNewMode ||
      //     formState.newMode ||
      //     (formState.values && formState.values.templateKey)
      // },
      {
        icon: <Icon style={tabIconStyle}>assignment_turned_in</Icon>,
        component: Inventory,
        title: 'Inventory',
        access: 'inventory',
        isHidden: formState =>
          formState.preNewMode ||
          formState.newMode ||
          (formState.values && formState.values.templateKey)
      },
      {
        component: Pricing,
        icon: <Icon style={tabIconStyle}>payment</Icon>,
        title: 'Pricing',
        access: 'pricing',
        isHidden: formState =>
          formState.preNewMode ||
          formState.newMode ||
          (formState.values && formState.values.templateKey)
      },
      {
        component: Johnstone,
        title: 'Johnstone',
        access: 'johnstone',
        isHidden: formState => {
          if (formState.preNewMode || formState.newMode) {
            return true
          }

          if (formState.values && formState.values.templateKey) {
            return true
          }

          if (formState.values && formState.values.isJohnstoneProduct) {
            return !formState.values.isJohnstoneProduct
          }

          return true
        }
      },
      {
        access: 'attachments',
        component: Attachments,
        icon: <Icon style={tabIconStyle}>attachment</Icon>,
        title: 'Attachments'
        // component: () => (
        //   <div style={{ padding: 20 }}>
        //     <p>Attachments coming soon</p>
        //   </div>
        // ),
        // icon: <Icon style={tabIconStyle}>attachment</Icon>,
        // title: 'Attachments'
      },
      {
        component: Audit,
        icon: <Icon style={tabIconStyle}>search</Icon>,
        title: 'Audit',
        access: 'audit',
        callAlways: true,
        mapResponse: mapAuditResponse,
        isHidden: formState =>
          formState.preNewMode ||
          formState.newMode ||
          (formState.values && formState.values.templateKey)
      }
    ],
    mapResponse({ response, tabIds = [], formState, groupNames = [] }) {
      // console.log(response, tabIds, formState, groupNames)
      // debugger
      return mapDataResponse({ response, tabIds, formState, groupNames })
    }
  },
  behaviors,
  actions: {
    getReport: getReportCriteria,
    addNewGroup: actions.addNewGroup,
    delete: actions.deleteProduct.try,
    addNew: actions.createNewProduct.try,
    cancelCreate: actions.cancelCreate.try,
    lockTemplate,
    unlockTemplate,
    deleteTemplate,
    saveTemplate,
    exitTemplate,
    cancelPreNewMode: actions.cancelPreNewMode
  },
  sagas: {
    onInit: sagas,
    onSave: onSaveProductProcess
  },
  getEntityApi: api.getProduct,
  transformMeta(meta) {
    // console.log(...args)
    return {
      ...meta,
      defaultSelectedWarehouseId: meta.selectedWarehouseId
    }
  },
  updateTitleMethod: memoize(response => {
    const { dataId, description } = response
    return dataId && description
      ? `Product - ${response.dataId} "${response.description}"`
      : 'Product Master'
  })
}

export const productMasterInModal = memoize(form =>
  ddiForm({
    ...options,
    form
  })(MeasuredModal)
)

export default ddiForm(options)()
