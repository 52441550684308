import React from 'react'
import { Button, Chip, Avatar, Icon, Popover } from '@material-ui/core'
import { informBlue } from 'theme/colors'

const PopoverInfoChip = ({
  labelName,
  id,
  description,
  action = null,
  actionText = '',
  wrapperStyle = { marginBottom: 10 },
  hasPopover = true,
  hasAvatar = true,
  avatarIcon = 'keyboard_arrow_down',
  tooltipId = null, // this value is used in a wrapper component, do not remove -- SVE
  additionalPopoverInfo = null
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    if (!hasPopover) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? `simple-popover-${id}` : undefined

  const avatar = hasAvatar ? (
    <Avatar style={{ border: 0, background: '#f5f7f7' }}>
      <Icon>{avatarIcon}</Icon>
    </Avatar>
  ) : null

  const onClickActionButton = e => {
    handleClose()
    action()
  }

  if (!id) {
    return (
      <div style={wrapperStyle}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <span style={{ marginRight: 10, width: 70, fontSize: '0.9em' }}>
            {labelName}:
          </span>
          <Chip
            label={description}
            style={{
              fontSize: '0.9em',
              width: 175,
              background: '#f5f7f7',
              justifyContent: 'flex-start'
            }}
            variant="outlined"
          />
        </div>
      </div>
    )
  }

  return (
    <div style={wrapperStyle}>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <span style={{ marginRight: 10, width: 70, fontSize: '0.9em' }}>
          {labelName}:
        </span>
        <Chip
          avatar={avatar}
          label={description}
          onClick={hasPopover ? handleClick : null}
          style={{
            fontSize: '0.9em',
            width: 175,
            background: '#f5f7f7',
            justifyContent: 'flex-start'
          }}
          variant="outlined"
        />
      </div>
      {hasPopover ? (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <div>
            <div style={{ padding: 15 }}>
              <strong>{labelName}:</strong>{' '}
              <span>
                {description} {`(${id})`}
              </span>
              {additionalPopoverInfo ? (
                <div style={{ marginTop: 15 }}>{additionalPopoverInfo}</div>
              ) : null}
            </div>
            {action && typeof action === 'function' && actionText ? (
              <Button
                style={{
                  alignItems: 'center',
                  background: '#e7e7e7',
                  color: informBlue,
                  display: 'flex',
                  textTransform: 'none',
                  width: '100%'
                }}
                onClick={onClickActionButton}
              >
                <Icon
                  fontSize="small"
                  style={{ color: informBlue, marginRight: 10 }}
                >
                  open_in_new
                </Icon>
                <span>{actionText}</span>
              </Button>
            ) : null}
          </div>
        </Popover>
      ) : null}
    </div>
  )
}

export default PopoverInfoChip
