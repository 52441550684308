import React from 'react'
import { useDispatch } from 'react-redux'
import memoize from 'memoize-one'
import { Icon, IconButton } from '@material-ui/core'
import { debounce } from 'lodash'
import { plainDeepEqual } from 'utils'
import { toggleCheckboxCell } from 'pages/VendorMaster/actions'

const getCheckboxIcon = memoize((rowData = [], field) => {
  const allSelected = rowData.every(x => x[field])
  const noneSelected = rowData.every(x => !x[field])

  if (allSelected) {
    return 'check_box'
  }

  if (noneSelected) {
    return 'check_box_outline_blank'
  }

  return 'indeterminate_check_box'
}, plainDeepEqual)

const getRowData = gridApi => {
  const rowData = []
  if (gridApi && gridApi?.forEachNode) {
    gridApi.forEachNode(node => rowData.push(node.data))
  }

  return rowData
}

const CheckboxHeaderCell = ({ value, node, column, data = {}, ...rest }) => {
  const dispatch = useDispatch()
  const form = column?.colDef?.headerComponentParams?.form || null
  const field = column?.colDef?.field || ''
  const headerName = column?.colDef?.headerName || ''
  const propertyName =
    column?.colDef?.headerComponentParams?.propertyName || null
  const isEditing = column?.colDef?.headerComponentParams?.isEditing || false
  const gridApi = column?.gridApi || null

  const rowData = getRowData(gridApi)
  const checkboxIcon = getCheckboxIcon(rowData, field)

  console.log('CheckboxHeaderCell', column, field, isEditing, rowData, rest)

  const toggleCheckBox = debounce(e => {
    if (field && propertyName) {
      dispatch(
        toggleCheckboxCell(form, {
          propertyName,
          field,
          isHeaderToggle: true,
          selectAll: checkboxIcon === 'check_box_outline_blank'
        })
      )
    }
  }, 300)

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}
    >
      <div>
        <IconButton
          disabled={!isEditing}
          onClick={toggleCheckBox}
          tabIndex={-1}
          style={{ padding: 5 }}
        >
          <Icon
            style={{
              color: !isEditing ? '#999' : '#517b9c',
              display: 'inline-block',
              fontSize: 16
            }}
          >
            {checkboxIcon}
          </Icon>
        </IconButton>
        <span>{headerName}</span>
      </div>
    </div>
  )
}

export default CheckboxHeaderCell
