import callFetch from 'access/callFetch'
import { createApiOptions } from 'utils'
import {
  GET_PURCHASE_ORDER_TOOLTIP_URL,
  GET_SALES_ORDER_TOOLTIP_URL,
  GET_WARRANTY_CLAIM_TOOLTIP_URL
} from './constants'

export const getPurchaseOrderTooltip = ({ recordName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      recordName
    }
  })
  return callFetch(GET_PURCHASE_ORDER_TOOLTIP_URL, options)
}

export const getSalesOrderTooltip = ({ recordName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      recordName
    }
  })
  return callFetch(GET_SALES_ORDER_TOOLTIP_URL, options)
}

export const getWarrantyClaimTooltip = ({ recordName }) => {
  const options = createApiOptions({
    method: 'post',
    body: {
      recordName,
      dataId: recordName
    }
  })
  return callFetch(GET_WARRANTY_CLAIM_TOOLTIP_URL, options)
}
