/* eslint max-classes-per-file: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { layoutFlex, is, getIn, plainDeepEqual } from 'utils'
import * as localService from 'services/local'

import memoize from 'memoize-one'
import { fetchGridColsFromLocal, toggleFieldInLocal } from 'grid/utils'

import { debounce, transform, isEqual, isEqualWith, isObject } from 'lodash'
import { connect } from 'react-redux'
import AddNew from 'grid/AddNew'
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css'
import { withDDIForm } from 'ddiForm/DDIFormContext'
import { addBlankRow, setSelectedRowIndex } from 'ddiForm/actions'
import { onLineItemsGridReady } from 'pages/SalesOrder/actions'
import NoItemsInCart from 'mobile/pages/SalesOrder/components/NoItemsInCart'
import WrappedGrid from './WrappedGrid'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })

const compareArrays = (a, b) => {
  const diff = difference(a, b)
  return diff && diff.length === 0
}

const customizer = (a, b) => {
  const bool = isEqualWith(a, b, compareArrays)
  return bool
}

function autoSizeAll() {
  const allColumnIds = []
  if (this.api && this._isMounted) {
    this.columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId)
    })
    setTimeout(() => this.columnApi.autoSizeColumns(allColumnIds), 1)
  }
}
function autoFit() {
  if (this.api && this._isMounted) this.api.sizeColumnsToFit()
}
const whitelist = [
  'columnDefs',
  'rowData',
  'height',
  'masterDetail',
  'isEditing'
]

export class ManualGrid extends Component {
  _isMounted = false

  static defaultProps = {
    headerStyle: {
      background: '#d1d3d4',
      color: '#444',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '17px',
      margin: 0,
      padding: '5px 0',
      textAlign: 'center',
      width: '100%'
    },
    getRowNodeId: data => {
      console.log(data, data.rowId)
      return data.rowId
    },
    gridWrapperStyle: {
      flex: '1 1',
      maxWidth: '100%',
      height: 'auto',
      width: '100%'
    },
    isSecondaryManualGrid: false,
    maxWidth: '80rem',
    sideBar: {
      hiddenByDefault: true,
      defaultToolPanel: 'columns',
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressSideButtons: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true
          }
        }
      ]
    }
  }

  constructor(...args) {
    super(...args)
    this.resize = debounce(this.resize, 200)
    Object.assign(this, {
      autoSizeAll: this.props.autoSize
        ? autoSizeAll.bind(this)
        : autoFit.bind(this)
    })
    if (this.props.allowFieldChooser) {
      const hiddenColumns = fetchGridColsFromLocal(this.props) || []
      this.hiddenColumns = hiddenColumns
    }
    this.state = {
      selectedRowIndex: this.props.selectedRowIndex,
      selectedCell: this.props.selectedCell
    }
    console.log('manual grid constructed')
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('keydown', this.handleKeyDown)
    window.addEventListener('keyup', this.handleKeyUp)
    this._isMounted = true
  }

  handleKeyDown = event => {
    if (event.shiftKey || event.ctrlKey) {
      // setShiftKeyPressed(true);
      this.shiftOrControl = true
    } else {
      this.shiftOrControl = false
    }
  }

  handleKeyUp = event => {
    this.shiftOrControl = false
  }

  // shouldComponentUpdate(nextProps) {
  //   const ret =
  //     this.props.children ||
  //     nextProps.children ||
  //     Object.keys(nextProps).some(
  //       prop =>
  //         whitelist.includes(prop) &&
  //         !isEqual(this.props[prop], nextProps[prop])
  //     )
  //   console.log('manual grid should update', ret)
  //   return ret
  // }

  shouldComponentUpdate(nextProps) {
    const ret =
      this.props.children ||
      nextProps.children ||
      Object.keys(nextProps).some(prop => {
        let r
        if (whitelist.includes(prop)) {
          if (prop !== 'columnDefs' || prop !== 'rowData') {
            // console.dir(prop)
            try {
              r = !isEqual(this.props[prop], nextProps[prop])
            } catch (e) {
              console.log(e, prop)
            }
          } else {
            r = !isEqualWith(this.props[prop], nextProps[prop], customizer)
          }
        } else {
          r = false
        }

        return r
      })
    // console.log('manual grid should update', ret)
    return ret
  }

  componentDidUpdate(prevProps) {
    // const { rowData = [], isEditing } = this.props
    // const hasBlankRow = rowData.some(item => item.rowId === 'blankrow')
    // const { rowData: oldRowData } = prevProps
    // //
    // if (this.api) {
    //   if (this.props.rowData && this.props.rowData.length) {
    //     if (this.props.hideHeader) {
    //       this.api.setHeaderHeight(0)
    //     } else {
    //       this.api.setHeaderHeight()
    //     }
    // const idx = this.props.selectedRowIndex
    // if (
    //   (this.props.selectedRowIndex != null &&
    //     this.props.selectedRowIndex !== prevProps.selectedRowIndex) ||
    //   (this.props.selectedRowIndex === 0 && prevProps.selectedRowIndex === 0)
    // ) {
    //   this.api.forEachNode(node => {
    //     if (node.childIndex === idx) {
    //       if (!node.isSelected()) {
    //         node.setSelected(true)
    //       }
    //     }
    //   })
    // }
    //     if (
    //       rowData &&
    //       oldRowData &&
    //       rowData.length > oldRowData.length &&
    //       isEditing &&
    //       hasBlankRow
    //     ) {
    //       //
    //       const lastLine = rowData[rowData.length - 1]
    //       if (lastLine.dataId === null) {
    //         this.api.forEachNode(node => {
    //           if (node.data.rowId === 'blankrow') {
    //             if (!node.isSelected()) node.setSelected(true)
    //             setTimeout(
    //               () =>
    //                 this.api.startEditingCell({
    //                   rowIndex: node.childIndex,
    //                   colKey: 'dataId'
    //                 }),
    //               1
    //             )
    //           }
    //         })
    //       }
    //     }
    //   }
    //   this.grouping()
    // }
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.resize)
    window.removeEventListener('keydown', this.handleKeyDown)
    window.removeEventListener('keyup', this.handleKeyUp)
  }

  grouping = () => {
    if (
      this.props.hasRecord &&
      this.props.rowData &&
      Array.isArray(this.props.rowData) &&
      !this.props.isEditing
    ) {
      const r = this.props.rowData.find(x => !x.itemGroupId)
      let noGroupStr
      let allUngrouped

      if (r) {
        noGroupStr = r.itemGroupDescription
      }

      if (noGroupStr) {
        allUngrouped = this.props.rowData.every(
          x =>
            x.itemGroupDescription.includes('Not Grouped') &&
            x.itemGroupDescription === noGroupStr
        )
      }

      if (r && r.rowId && r.rowId === 'blankrow') {
        allUngrouped = true
      }

      if (!allUngrouped) {
        const {
          hasRecord,
          isEditing,
          focusedCell,
          form,
          onCellChange,
          showCart
        } = this.props
        let defs = this.props.columnDefs
        defs = [
          {
            field: 'itemGroupDescription',
            colId: 'itemGroupDescription',
            headerName: 'GroupDesc',
            hide: true,
            rowGroup: !isEditing,
            cellRenderer: !isEditing ? 'agGroupCellRenderer' : undefined,
            suppressColumnsToolPanel: true,
            suppressFiltersToolPanel: true
          },
          ...defs
        ]

        this.api.setColumnDefs(defs)
        this.api.forEachNode(node => {
          if (node.group) {
            node.expanded = true
          }
        })
        this.api.onGroupExpandedOrCollapsed()
      } else {
        this.api.setColumnDefs(this.props.columnDefs)
      }
    }
  }

  onGridReady = params => {
    const { form, dispatch } = this.props
    // console.log('manual grid ready')
    this.api = params.api
    this.columnApi = params.columnApi
    // this.api.sizeColumnsToFit()
    const { rowData } = this.props
    if (rowData) {
      if (!rowData.length) {
        this.api.setHeaderHeight(0)
        this.api.showNoRowsOverlay()
        if (this.props.selectedRowIndex) {
          const node = this.api.getDisplayedRowAtIndex(
            this.props.selectedRowIndex
          )

          if (node && !node.isSelected()) {
            node.setSelected(true)
          }
        }
      } else {
        this.autoSizeAll()
      }
      // if (rowData.length && this.props.pinnedData) {
      //   this.pinnedData(this.props)
      // }
    }
    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }
    if (this.props.hideHeader) {
      this.api.setHeaderHeight(0)
    }

    // if (!this.props.isEditing) {
    //   this.api.onGroupExpandedOrCollapsed()
    // }
    if (!this.props.isSecondaryManualGrid) {
      this.grouping()
    }

    if (
      this.props.dispatch &&
      this.props.form &&
      !this.props.isSecondaryManualGrid
    ) {
      /* 
        added this action today to bolster the openScreenWithDataProcess
        routine in sagas/externalHanderSagas. after the ag-grid update
        busted that initial SET_SELECTED_ROW_INDEX action, it
        became clear that an explicit action was needed with the gridApi
        available for that routine -- SVE 2/3/2021
      */
      this.props.dispatch(
        onLineItemsGridReady(this.props.form, {
          gridApi: params.api
        })
      )
    }
  }

  onColumnVisible = params => {
    /* 
      ag-grid made changes (or perhaps fixed a bug that resulted in this change),
      but in any event, this is the fix -- SVE 2/3/2021
    */
    const { column, columns } = params

    if (column && column.colId) {
      toggleFieldInLocal(this.props, column.colId, params.visible)
    } else if (columns && columns.length) {
      columns.forEach(col => {
        if (col.colId) {
          toggleFieldInLocal(this.props, col.colId, col.visible)
        }
      })
    }

    if (
      this.props.onColumnVisibleCb &&
      typeof this.props.onColumnVisibleCb === 'function'
    ) {
      this.props.onColumnVisibleCb()
    }
  }

  onDragStopped = () => {
    if (
      this.props.onDragStoppedCb &&
      typeof this.props.onDragStoppedCb === 'function'
    ) {
      this.props.onDragStoppedCb()
    }
  }

  onRowSelected = params => {
    if (!params.node.selected) return
    const { rowData = [], onRowSelected } = this.props

    if (onRowSelected) {
      onRowSelected(params, this._isMounted)
      // return
    }
    if (!this.shiftOrControl) {
      this.gridApi?.deselectAll()
    }
    setTimeout(() => {
      const { data, node } = params

      const lineNumber = data?.lineNumber

      if (
        !lineNumber ||
        !rowData ||
        !Array.isArray(rowData) ||
        this.shiftOrControl
      ) {
        return
      }

      const actualRowIndex = rowData.findIndex(
        x => x.lineNumber === Number(lineNumber)
      )
      if (node && this._isMounted) {
        params.api.forEachDetailGridInfo(t => {
          t.api.forEachNodeAfterFilter(n => {
            if (n.isSelected()) {
              n.setSelected(false, true, true)
            }
          })
        })
      }

      // debugger
      const f = node?.isSelected()
      if (
        node &&
        //! node?.isSelected() &&
        is.number(actualRowIndex) &&
        (actualRowIndex >= 0 && this.props.selectedRowIndex !== actualRowIndex)
      ) {
        if (this._isMounted) {
          if (lineNumber != null) {
            this.props.setSelectedRowIndex(actualRowIndex, this.api)
            // if (this.props.onRowSelected) {
            //   this.props.onRowSelected(params)
            // }
          }
        }
      }
    })
  }

  // onRowSelected = params => {
  //   if (!params.node.selected) return
  //   const { rowData = [], onRowSelected } = this.props

  //   if (onRowSelected) {
  //     onRowSelected(params, this._isMounted)
  //     // return
  //   }
  //   const { data, node } = params

  //   const lineNumber = data?.lineNumber

  //   if (!lineNumber || !rowData || !Array.isArray(rowData)) {
  //     return
  //   }

  //   const actualRowIndex = rowData.findIndex(
  //     x => x.lineNumber === Number(lineNumber)
  //   )
  //   setTimeout(() => {
  //     if (node && this._isMounted) {
  //       params.api.forEachDetailGridInfo(t => {
  //         t.api.forEachNodeAfterFilter(n => {
  //           if (n.isSelected()) {
  //             n.setSelected(false, true, true)
  //           }
  //         })
  //       })
  //     }
  //   })
  //   // debugger
  //   const f = node?.isSelected()
  //   if (
  //     node &&
  //     //! node?.isSelected() &&
  //     is.number(actualRowIndex) &&
  //     (actualRowIndex >= 0 && this.props.selectedRowIndex !== actualRowIndex)
  //   ) {
  //     if (this._isMounted) {
  //       setTimeout(() => {
  //         if (lineNumber != null) {
  //           this.props.setSelectedRowIndex(actualRowIndex, this.api)
  //           // if (this.props.onRowSelected) {
  //           //   this.props.onRowSelected(params)
  //           // }
  //         }
  //       }, 0)
  //     }
  //     // }

  //     // }
  //   }
  // }

  // onRowSelected = params => {
  //   if (!params.node.selected) return
  //   const { rowData = [], onRowSelected } = this.props

  //   if (onRowSelected) {
  //     onRowSelected(params, this._isMounted)
  //     // return
  //   }
  //   const { data, node } = params

  //   const lineNumber = data?.lineNumber

  //   if (!lineNumber || !rowData || !Array.isArray(rowData)) {
  //     return
  //   }

  //   const actualRowIndex = rowData.findIndex(
  //     x => x.lineNumber === Number(lineNumber)
  //   )
  //   if (node && this._isMounted) {
  //     params.api.forEachDetailGridInfo(t => {
  //       t.api.forEachNodeAfterFilter(n => {
  //         if (n.isSelected()) {
  //           n.setSelected(false)
  //         }
  //       })
  //     })
  //   }
  //   // debugger
  //   if (
  //     node &&
  //     // !node?.isSelected() &&
  //     is.number(actualRowIndex) &&
  //     (actualRowIndex >= 0 && this.props.selectedRowIndex !== actualRowIndex)
  //   ) {
  //     if (this._isMounted) {
  //       this.setState(
  //         {
  //           selectedRowIndex: actualRowIndex
  //         },
  //         () => {
  //           setTimeout(() => {
  //             if (lineNumber != null) {
  //               this.props.setSelectedRowIndex(actualRowIndex, this.api)
  //               if (this.props.onRowSelected) {
  //                 this.props.onRowSelected(params)
  //               }
  //             }
  //           }, 0)
  //         }
  //       )
  //     }
  //   }
  // }

  resize = () => {
    if (this.api) {
      this.autoSizeAll()
      this.grouping()
    }
  }

  // columnDefs = memoize(colDefs =>
  //   colDefs.map(col => {
  //     const ret = { ...col }
  //     if (!col.headerName && !col.children) {
  //       // ret.suppressToolPanel = true
  //       ret.suppressColumnsToolPanel = true
  //       ret.suppressFiltersToolPanel = true
  //     } else if (
  //       this.props.allowFieldChooser &&
  //       this.hiddenColumns &&
  //       this.hiddenColumns.includes(col.field)
  //     ) {
  //       ret.hide = true
  //     } else if (
  //       ret.hide &&
  //       this.hiddenColumns &&
  //       col.field &&
  //       !this.hiddenColumns.includes(col.field)
  //     ) {
  //       ret.hide = false
  //     }
  //     return ret
  //   })
  // )
  columnDefs = memoize(colDefs =>
    colDefs.map(col => {
      const ret = { ...col }
      if (!col.headerName && !col.children) {
        // ret.suppressToolPanel = true
        ret.suppressColumnsToolPanel = true
        ret.suppressFiltersToolPanel = true
      }
      /* 
        the hide and show stuff is taken care of externally now 
        because the routine is different and we have API colDefs
        to handle, and this had to get adjusted further today
        I am assuming to accommodate changes in ag-grid -- SVE 2/3/21
      */
      return ret
    })
  )

  onRowDataChanged = p => console.log('rowdata changed', p)

  onRowDataUpdated = p => {
    const { rowData = [], isEditing } = this.props
    const hasBlankRow = rowData.some(item => item.rowId === 'blankrow')
    // const { rowData: oldRowData } = prevProps
    //
    if (this.api) {
      // if (this.props.rowData && this.props.rowData.length) {
      //   if (this.props.hideHeader) {
      //     this.api.setHeaderHeight(0)
      //   } else {
      //     this.api.setHeaderHeight()
      //   }
      //   const idx = this.props.selectedRowIndex

      //   if (
      //     this.props.selectedRowIndex != null &&
      //     this.props.selectedRowIndex !== prevProps.selectedRowIndex
      //   ) {
      //     this.api.forEachNode(node => {
      //       if (node.childIndex === idx) {
      //         if (!node.isSelected()) {
      //           node.setSelected(true)
      //         }
      //       }
      //     })
      //   }

      if (rowData && isEditing && hasBlankRow) {
        //
        const lastLine = rowData[rowData.length - 1]

        if (lastLine.dataId === null) {
          this.api.forEachNode(node => {
            if (
              node?.data?.rowId === 'blankrow' &&
              !node?.data?.rowRetainedAfterApiTransaction
            ) {
              if (!node.isSelected()) node.setSelected(true)

              setTimeout(
                () =>
                  this.api.startEditingCell({
                    rowIndex: node.childIndex,
                    colKey: 'dataId'
                  }),
                1
              )
            }
          })
        }
      }
    }

    if (!this?.props?.isSecondaryManualGrid) {
      this.grouping()
    }

    if (
      this?.props?.onRowDataUpdated &&
      typeof this?.props?.onRowDataUpdated === 'function'
    ) {
      this.props.onRowDataUpdated(p)
    }
  }

  //
  // }
  onCellContextMenu = ({ node }) => {
    if (this.props.hideHeader) {
      this.api.setHeaderHeight(0)
    }

    node?.setSelected(true, false)
  }

  render() {
    const {
      addButtonText,
      allowFieldChooser,
      gridWrapperStyle,
      find,
      gridRef,
      headerStyle,
      hasRecord,
      isEditing,
      maxWidth,
      showAddButtonAlways,
      showAddButtonIfHasRecord,
      showAddButtonOnlyIfEditing,
      showAddButtonIfNoRecordOrEditing,
      title,
      ...rest
    } = this.props
    let { height } = this.props
    let allowNew = false
    if (showAddButtonAlways) {
      allowNew = true
    } else if (showAddButtonOnlyIfEditing && isEditing) {
      allowNew = true
    } else if (showAddButtonIfHasRecord && hasRecord) {
      allowNew = true
    } else if (showAddButtonIfNoRecordOrEditing && (isEditing || !hasRecord)) {
      allowNew = true
    }
    let sideBar
    if (allowFieldChooser) {
      sideBar = this.props.sideBar
    }
    // think this needs a tweak if there's no column groups.
    height = height <= 0 ? 0 : height - 133
    // console.log('manual grid rendered', isEditing)
    console.log(rest.detailCellRendererParams)
    return (
      <div>
        <div style={layoutFlex(maxWidth)}>
          <div style={gridWrapperStyle}>
            <div>
              {title && <h4 style={headerStyle}>{title}</h4>}

              <div style={{ position: 'relative', height }}>
                <div
                  className="ag-theme-balham"
                  style={{
                    height,
                    width: '100%'
                  }}
                >
                  <WrappedGrid
                    {...rest}
                    onGridReady={this.onGridReady}
                    columnDefs={this.columnDefs(this.props.columnDefs)}
                    onCellContextMenu={this.onCellContextMenu}
                    gridRef={gridRef}
                    enableColResize
                    sideBar={sideBar}
                    onRowSelected={this.onRowSelected}
                    // suppressKeyboardEvent={this.suppressKeyboardEvent}
                    onColumnVisible={this.onColumnVisible}
                    // onRowDataChanged={this.onRowDataChanged}
                    onRowDataUpdated={this.onRowDataUpdated}
                    rememberGroupStateWhenNewData
                    onDragStopped={this.onDragStopped}
                    debug
                    // debug
                    // onCellValueChanged={this.onCellValueChanged}
                  />
                </div>
                {allowNew && (
                  <AddNew
                    addButtonText={addButtonText}
                    onClick={this.props.addBlankRow}
                    variant="contained"
                    size="small"
                    color="secondary"
                    showIcon
                    // showIcon={addButtonStyleParams.showIcon}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapState = (state, ownProps) => {
  const ret = { userId: getIn(state, 'auth.dataId') }

  return ret
}

const ConnectedManualGrid = connect(
  mapState,
  null,
  null,
  { forwardRef: true }
)(ManualGrid)
/* eslint-disable react/no-multi-comp */

const isEmptyCartOrder = memoize((hasRecord, isEditing, rowData = []) => {
  let isEmptyCart = false

  if (
    !isEditing &&
    hasRecord &&
    rowData &&
    Array.isArray(rowData) &&
    rowData.length === 1 &&
    rowData[0] &&
    rowData[0].rowId &&
    rowData[0].rowId === 'blankrow'
  ) {
    isEmptyCart = true
  }

  return isEmptyCart
}, plainDeepEqual)

export class GridField extends Component {
  componentDidMount() {
    // console.log(this, this.props)
    // debugger

    this.props.registerField({
      propertyName: this.props.propertyName,
      searchType: null,
      type: 'grid-new',
      ...this.props
      // blankRow
    })
  }

  render() {
    const isEmptyCart = isEmptyCartOrder(
      this.props.hasRecord,
      this.props.isEditing,
      this.props.rowData
    )

    if (isEmptyCart) {
      return (
        <NoItemsInCart
          headerText="No Line Items"
          instructionsText="Edit Order to Add Line Items"
          statusIcon="error"
          wrapperStyleOverride={{
            margin: 40
          }}
        />
      )
    }

    return <ConnectedManualGrid {...this.props} />
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log('GridField', state, ownProps)
  // debugger
  const {
    propertyName,
    dispatch,
    getFormState,
    form
    // _ddiForm: { dispatch, getFormState, form }
  } = ownProps
  const formState = getFormState(state)
  const field = getIn(formState, `fields.${propertyName}`)

  const ret = field ? { ...ownProps, ...field.toJS() } : { ...ownProps }
  ret.hasRecord = getIn(formState, 'hasRecord') || false
  ret.isEditing = getIn(formState, 'isEditing') || false

  if (ownProps.columnDefs) {
    ret.columnDefs = ownProps.columnDefs
  }

  if (!ownProps.addBlankRow && dispatch) {
    ret.addBlankRow = (e, args) => {
      dispatch(addBlankRow(form, { propertyName, ...args }))
    }
  }

  return ret
}

const mapDispatch = (dispatch, ownProps) => {
  // ({ dispatch })
  return {
    dispatch,
    setSelectedRowIndex: (rowIndex, gridApi = () => {}) =>
      dispatch(
        setSelectedRowIndex(ownProps.form, {
          propertyName: ownProps.propertyName,
          rowIndex,
          gridApi
        })
      )
  }
}
export default withDDIForm(
  connect(
    mapStateToProps,
    mapDispatch,
    null,
    { forwardRef: true }
  )(GridField)
)
