import { openScreen as openScreenAction } from 'pages/Main/actions'
import memoize from 'memoize-one'
import { setField } from 'ddiForm/actions'
import { is, plainDeepEqual } from 'utils'
import { CLOSE_SEARCH, CLOSE_FILTERS_GRID } from './constants'

import {
  blur,
  exactMatchSearch,
  partialMatchSearch,
  resetFilters as resetFiltersAction,
  clearSearch,
  foundInvalidValues
} from './actions'
import baseBehaviors from '../baseBehaviors'

export const extractApiParamsFromMetaData = memoize((meta = {}) => {
  if (
    meta &&
    typeof meta === 'object' &&
    !is.null(meta) &&
    Object.keys(meta)?.length
  ) {
    return {
      allowInvalidValues: is.bool(meta?.allowInvalidValues)
        ? meta.allowInvalidValues
        : false,
      includedSerialNumbers: meta?.includedSerialNumbers || [],
      excludedSerialNumbers: meta?.excludedSerialNumbers || [],
      transactionType: meta?.transactionType || 'S',
      searchType: meta?.searchType || 'A'
    }
  }

  return {
    allowInvalidValues: true,
    includedSerialNumbers: '',
    excludedSerialNumbers: '',
    transactionType: 'S',
    searchType: 'A'
  }
}, plainDeepEqual)

export default {
  ...baseBehaviors,
  blur(value) {
    this.props.dispatch(
      blur(this.form, {
        propertyName: this.props.propertyName,
        value,
        isSet: this.state.isSet
      })
    )
    if (this.state.isDirty && !value.length) {
      this.setField('', true)
      if (this.props.onChange) {
        this.props.onChange('')
      }
    }
  },
  clearSearch() {
    if (!this.props.getFormState) return
    this.props.dispatch(
      clearSearch(this.form, {
        propertyName: this.props.propertyName
      })
    )
  },
  contextMenuFn(e, data) {
    e.stopPropagation()
    // const target = findDOMNode(this.textField)
    const target = this.textField

    const action = {
      copy: () => {
        target.select()
        try {
          document.execCommand('copy')
        } catch (err) {
          console.log(err)
        }
      },
      cut() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      select() {
        target.select()
      },
      paste() {
        // Chrome does not allow retrieval of clipboard data
        // document.addEventListener('paste', function (evt) {
        //   const data = evt.clipboardData.getData('text')
        //   console.log('DATA', data)
        // })
        // target.focus()
        // document.execCommand('paste')
      },
      delete() {
        this.props.dispatch(
          blur(this.form, {
            propertyName: this.props.propertyName,
            value: '',
            isSet: false
          })
        )
      },
      openScreen: () => {
        const { openScreenData } = this.props
        this.props.dispatch(openScreenAction(openScreenData))
      }
    }
    return (action[data.type] || action.select)()
  },
  focusField() {
    return (
      this.textField &&
      this.setState(
        { isFocused: true },
        () => ((this.autofocused = true), this.textField.focus())
      )
    )
  },
  getRowNodeId(data) {
    return data.recordName
  },
  getGridHeight() {
    const { grid } = this.props
    return grid && grid.rowData && grid.rowData.length
      ? grid.rowData.length * 27 + 52
      : 150
  },
  async onCellSelected(event) {
    const {
      propertyName,
      customerId,
      warehouseId,
      productId,
      uomId,
      transactionId,
      returnTransactionId,
      sourceBinId,
      salesHistoryCustomerId,
      salesHistoryInvoiceId,
      meta
    } = this.props

    // console.log('onCellSelected', event)
    // debugger
    try {
      const p = await this.props.dispatch(
        exactMatchSearch(this.form, {
          keyword: event.data.dataId,
          customerId,
          propertyName,
          warehouseId,
          productId,
          uomId,
          transactionId,
          returnTransactionId,
          sourceBinId,
          salesHistoryCustomerId,
          salesHistoryInvoiceId,
          ...extractApiParamsFromMetaData(meta)
          // searchType: meta?.searchType || 'A',
          // transactionType: meta?.transactionType || 'S'
        })
      )

      if (p.matchFound) {
        // debugger
        this.setState(
          {
            value: p.result.dataId,
            description: p.result.description || '',
            isSet: true,
            isDirty: false,
            isOpen: false,
            tab: false
          },
          () => {
            this.setField(p.result.dataId)
            this.blur(this.state.value)
          }
        )
      } else {
        debugger
        this.setState({
          value: '',
          isSet: false,
          isDirty: false,
          isOpen: false,
          tab: false
        })
      }
    } catch (e) {
      this.onRequestClose()
    }
  },
  onCloseFiltersGrid() {
    this.setState({ mouseover: false }, () => {
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_FILTERS_GRID,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
    })
  },
  onKeyDown(event) {
    event.persist()
    const val = event.target.value
    /* this is for New Event Type popover */
    if (this.props.disableBaseOnTabFunctionality && this.props.onKeyDown) {
      this.props.onKeyDown(event)
      return
    }

    // check if tooltip is open and keys match etc.. great :-(
    if (event.key === 'Tab') {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      if (this.state.isSet || !this.state.value) {
        return
      }
      event.preventDefault()
      // event.preventDefault()
      // event.target.blur()

      const {
        propertyName,
        warehouseId,
        customerId,
        productId,
        uomId,
        transactionId,
        returnTransactionId,
        sourceBinId,
        salesHistoryCustomerId,
        salesHistoryInvoiceId,
        meta
      } = this.props

      this.setState(
        {
          isOpen: false,
          value: val,
          lastSearch: val,
          tab: true
        },
        async () => {
          try {
            const p = await this.props.dispatch(
              exactMatchSearch(this.form, {
                keyword: this.state.value,
                propertyName,
                customerId,
                warehouseId,
                productId,
                uomId,
                transactionId,
                returnTransactionId,
                sourceBinId,
                salesHistoryCustomerId,
                salesHistoryInvoiceId,
                ...extractApiParamsFromMetaData(meta)
                // searchType: meta?.searchType || 'A',
                // transactionType: meta?.transactionType || 'S'
              })
            )

            if (p.matchFound) {
              this.setState(
                {
                  value: p.result.dataId,
                  description: p.result.description || '',
                  isSet: true,
                  isDirty: false,
                  isOpen: false,
                  tab: false
                },
                () => {
                  this.setField(p.result.dataId)
                  this.blur(this.state.value)
                }
              )
            } else if (p?.messages && Array.isArray(p.messages)) {
              if (
                p?.messages?.[0]?.modalTitle &&
                p?.messages?.[0]?.modalTitle === 'Not Found'
              ) {
                this.handleInvalidValues({
                  ...event,
                  value: val,
                  modalTitle: p?.messages?.[0]?.modalTitle,
                  message: p?.messages?.[0]?.message
                })
              }
            } else if (this.props.allowInvalidExactMatchSearch) {
              this.setState(
                prevState => {
                  return {
                    value: prevState.value,
                    description: '',
                    isSet: true,
                    isDirty: false,
                    isOpen: false,
                    tab: false
                  }
                },
                () => {
                  debugger
                  this.setField(this.state.value)
                  this.blur(this.state.value)
                }
              )
            } else {
              debugger
              this.setState({
                value: '',
                isSet: false,
                isDirty: false,
                isOpen: false,
                tab: false
              })
            }
          } catch (e) {
            debugger
            this.setState({ isSet: false, isDirty: true, tab: false })
          }
        }
      )
    }
  },

  onMouseLeave(event) {
    this.setState({ mouseover: false })
  },

  onMouseOver(event) {
    this.setState({ mouseover: true })
  },
  onRequestClose() {
    // console.log('request close', this.state, this.props)
    this.setState({ isOpen: false, mouseover: false }, () => {
      if (this.props.getFormState) {
        this.props.dispatch({
          type: CLOSE_SEARCH,
          payload: { propertyName: this.props.propertyName },
          meta: { form: this.form }
        })
      }
      if (this.props.onRequestClose) {
        this.props.onRequestClose()
      }
    })
  },
  onSearchClick(event) {
    this.setState(
      {
        isSet: false
      },
      () => this.partialMatchSearch(this.state.value, true)
    )
  },

  partialMatchSearch(value, bool, args, isFiltered = false) {
    let searchAll = false
    if (typeof value === 'boolean') {
      searchAll = value
      value = ''
    }
    // const { partialMatc}
    const {
      keyword,
      productId,
      propertyName,
      uomId,
      warehouseId,
      customerId,
      transactionId,
      returnTransactionId,
      sourceBinId,
      salesHistoryCustomerId,
      salesHistoryInvoiceId,
      meta
    } = args || this.props

    // debugger
    if (args) {
      value = args.keyword
    }

    if (this.state.isSet || this.props.isFetching) return
    let results
    this.setState({ lastSearch: value, isSearching: true }, async () => {
      try {
        const apiParams = {
          keyword: bool ? '' : value,
          productId,
          propertyName,
          uomId,
          warehouseId,
          customerId,
          transactionId,
          returnTransactionId,
          sourceBinId,
          salesHistoryCustomerId,
          salesHistoryInvoiceId,
          ...extractApiParamsFromMetaData(meta),
          onCellSelected: this.onCellSelected
        }
        results = await this.props.dispatch(
          partialMatchSearch(this.props.form, apiParams)
        )

        // debugger

        this.setState({
          dropDownProps: {
            ...results,
            ...results.grid,
            apiParams,
            returnTransactionId,
            warehouseIdAndDescription:
              results?.warehouseIdAndDescription || warehouseId,
            getRowNodeId: this.getRowNodeId,
            paginationCb: this.paginationCb,
            height: this.getGridHeight(),
            isFiltered
          },
          isOpen: true
        })
      } finally {
        this.setState({ isSearching: false })
      }
    })
  },
  redoSearch() {
    this.setState(
      { isSet: false, value: this.state.lastSearch, isDirty: true },
      () => this.partialMatchSearch(this.state.lastSearch)
    )
  },
  resetFilters() {
    this.props.dispatch(
      resetFiltersAction(this.form, { propertyName: this.props.propertyName })
    )
  },
  setField(value, _, __, ___) {
    const { form, propertyName, dispatch } = this.props

    if (propertyName && form && dispatch) {
      dispatch(setField(form, propertyName, value))
      if (
        this?.props?.onSetField &&
        typeof this?.props?.onSetField === 'function'
      ) {
        this.props.onSetField(value)
      }
    } else if (
      propertyName &&
      form &&
      dispatch &&
      this?.props?.setField &&
      typeof this?.props?.setField === 'function'
    ) {
      this.props.setField(value)
    } else if (
      this?.props?.onSetField &&
      typeof this?.props?.onSetField === 'function'
    ) {
      this.props.onSetField(value)
    }
  },
  async handleInvalidValues(e) {
    if (this._isMounted) {
      await this.setState({
        modalDisplayed: true,
        isSet: true,
        value: e.value
      })
    }
    // debugger
    try {
      await this.props.dispatch(
        foundInvalidValues(this.form, {
          propertyName: this.props.propertyName,
          ...e
        })
      )
      // debugger
      if (this._isMounted) {
        await this.setState(
          {
            value: e.value,
            isSet: true,
            isDirty: false,
            modalDisplayed: false
          },
          () => this.setField(e.value)
        )
      }
    } catch (err) {
      if (this._isMounted) {
        await this.setState(
          {
            value: '',
            isSet: false,
            isDirty: false,
            modalDisplayed: false
          },
          () => this.setField('')
        )
      }
    }
  }
}
