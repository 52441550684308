import React from 'react'
import PropTypes from 'prop-types'
import { DDISelectField } from 'ddiForm/wrapped'

export const CustomDropdownField = ({ dropDownItems, ...props }) => {
  console.log(dropDownItems, props)
  if (props.value) {
    props = { ...props, initialValue: props.value }
  }
  return <DDISelectField {...props} values={dropDownItems} />
}

CustomDropdownField.propTypes = {
  propertyName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dropDownItems: PropTypes.array
}

CustomDropdownField.defaultProps = {
  dropDownItems: []
}

export default CustomDropdownField
