import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

import ContainerTooltipTemplate from './ContainerTooltipTemplate'

const ContainerTooltipCell = props => <GridLinkCell {...props} />

export default TooltipField({
  anchorToGridCellText: true,
  component: ContainerTooltipTemplate,
  indexSearchType: 'container',
  keyToActionMap: {
    m: () => openScreenAction({
      name: 'container',
      title: 'Container',
      image: 'purchasing_container_mgmt_16.png',
    })
  },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'indexSearch'
})(ContainerTooltipCell)
