import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { formatNumber, layoutFlex, getValue, emptyList } from 'utils'
import { AppBar, Typography, Paper, Tabs, Tab } from '@material-ui/core'
import ReactHighcharts from 'react-highcharts'
import highchartsTheme from 'theme/highchartsTheme'
import Grid from 'grid'
import withDimensions from 'hoc/withDimensions'

import { headerStyle } from 'pages/ProductMaster/utils'

import './styles/master-style.scss'

ReactHighcharts.Highcharts.setOptions(highchartsTheme)

export const getRowNodeId = data => {
  const { description, fortyEightMonthDemand } = data
  const compId = `${description}|${fortyEightMonthDemand}`
  return compId
}

export const TabContainer = ({ children }) => (
  <Typography component="div">{children}</Typography>
)

export const getFormattedData = (data, field) => {
  const format = data.description ? '0,0.[000]' : '0,0.[0000]'
  return formatNumber(data[field], format)
}

export const getCellStyle = params => {
  if (params && params.value && params.value < 0) {
    return {
      color: 'red',
      textAlign: 'right'
    }
  }

  return {
    color: 'black',
    textAlign: 'right'
  }
}

const columnDefs = [
  {
    field: '',
    headerName: '',
    children: [
      {
        cellStyle: ({ value }) => (value < 0 ? { color: 'red' } : null),
        field: 'description',
        headerName: 'Month',
        filter: false,
        suppressMenu: true
      }
    ],
    filter: false,
    suppressMenu: true
  },
  {
    field: 'twelveMonth',
    headerClass: 'centered-label',
    headerName: '12 Month',
    children: [
      {
        cellStyle: getCellStyle,
        field: 'twelveMonthSales',
        headerClass: 'numeric-value-label',
        headerName: 'Sales',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) => getFormattedData(data, 'twelveMonthSales')
      },
      {
        cellStyle: getCellStyle,
        field: 'twelveMonthDemand',
        headerClass: 'numeric-value-label',
        headerName: 'Demand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) => getFormattedData(data, 'twelveMonthDemand')
      },
      {
        cellStyle: getCellStyle,
        field: 'twelveMonthOnHand',
        headerClass: 'numeric-value-label',
        headerName: 'On Hand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) => getFormattedData(data, 'twelveMonthOnHand')
      }
    ],
    filter: false,
    suppressMenu: true
  },
  {
    field: 'twentyFourMonth',
    headerClass: 'centered-label',
    headerName: '24 Month',
    children: [
      {
        cellStyle: getCellStyle,
        field: 'twentyFourMonthDemand',
        headerClass: 'numeric-value-label',
        headerName: 'Demand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) =>
          getFormattedData(data, 'twentyFourMonthDemand')
      },
      {
        cellStyle: getCellStyle,
        field: 'twentyFourMonthOnHand',
        headerClass: 'numeric-value-label',
        headerName: 'On Hand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) =>
          getFormattedData(data, 'twentyFourMonthOnHand')
      }
    ],
    filter: false,
    suppressMenu: true
  },
  {
    field: 'thirtySixMonth',
    headerClass: 'centered-label',
    headerName: '36 Month',
    children: [
      {
        cellStyle: getCellStyle,
        field: 'thirtySixMonthDemand',
        headerClass: 'numeric-value-label',
        headerName: 'Demand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) =>
          getFormattedData(data, 'thirtySixMonthDemand')
      }
    ],
    filter: false,
    suppressMenu: true
  },
  {
    field: 'fortyEightMonth',
    headerClass: 'centered-label',
    headerName: '48 Month',
    children: [
      {
        cellStyle: getCellStyle,
        field: 'fortyEightMonthDemand',
        headerClass: 'numeric-value-label',
        headerName: 'Demand',
        filter: false,
        suppressMenu: true,
        valueGetter: ({ data }) =>
          getFormattedData(data, 'fortyEightMonthDemand')
      }
    ],
    filter: false,
    suppressMenu: true
  }
]

export class Demand extends Component {
  static propTypes = {
    allWarehouses: PropTypes.array.isRequired,
    allWarehouses48MonthDemand: PropTypes.array.isRequired,
    currentWarehouse: PropTypes.array.isRequired,
    currentWarehouse48MonthDemand: PropTypes.array.isRequired,
    height: PropTypes.number.isRequired
  }

  constructor(...args) {
    super(...args)
    this.state = {
      tab: 'Current'
    }
  }

  getBottomData = () => {
    const { tab } = this.state
    const { allWarehouses, currentWarehouse } = this.props

    const total = []

    if (tab === 'Current') {
      const currentTotal = currentWarehouse.reduce(
        (acc, next) => {
          acc.twelveMonthSales += next.get('twelveMonthSales')
          acc.twelveMonthDemand += next.get('twelveMonthDemand')
          acc.twentyFourMonthDemand += next.get('twentyFourMonthDemand')
          acc.thirtySixMonthDemand += next.get('thirtySixMonthDemand')
          acc.fortyEightMonthDemand += next.get('fortyEightMonthDemand')
          return acc
        },
        {
          twelveMonthSales: 0,
          twelveMonthDemand: 0,
          twentyFourMonthDemand: 0,
          thirtySixMonthDemand: 0,
          fortyEightMonthDemand: 0
        }
      )
      total.push(currentTotal)
    } else {
      const allTotal = allWarehouses.reduce(
        (acc, next) => {
          acc.twelveMonthSales += next.get('twelveMonthSales')
          acc.twelveMonthDemand += next.get('twelveMonthDemand')
          acc.twentyFourMonthDemand += next.get('twentyFourMonthDemand')
          acc.thirtySixMonthDemand += next.get('thirtySixMonthDemand')
          acc.fortyEightMonthDemand += next.get('fortyEightMonthDemand')
          return acc
        },
        {
          twelveMonthSales: 0,
          twelveMonthDemand: 0,
          twentyFourMonthDemand: 0,
          thirtySixMonthDemand: 0,
          fortyEightMonthDemand: 0
        }
      )
      total.push(allTotal)
    }

    return total
  }

  handleTabChange = (e, value) => {
    this.setState({ tab: value })
  }

  barChartConfig = () => {
    const {
      allWarehouses48MonthDemand,
      currentWarehouse48MonthDemand
    } = this.props
    const { tab } = this.state

    const allChartData = allWarehouses48MonthDemand.reduce((acc, next) => {
      acc = acc.concat({
        name: next.get('months'),
        y: next.get('demand')
      })
      return acc
    }, [])

    const currentChartData = currentWarehouse48MonthDemand.reduce(
      (acc, next) => {
        acc = acc.concat({
          name: next.get('months'),
          y: next.get('demand')
        })
        return acc
      },
      []
    )

    const chartConfig = {
      chart: {
        type: 'column',
        style: {
          height: '100%'
        }
        // style: {
        //   height: '90%',
        //   padding: '10px'
        // },
      },
      title: {
        text: '48 Month Demand'
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        column: {
          pointPadding: 0
        }
      },
      series: [
        {
          colorByPoint: true,
          // colors: ['#8bbc21', '#1aadce', '#ffff07', '#f28f43'],
          data: tab === 'Current' ? currentChartData : allChartData
        }
      ],
      tooltip: { enabled: false },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        min: 0,
        title: {
          style: { 'font-size': 12 },
          text: 'Units'
        }
      }
    }

    return chartConfig
  }

  render() {
    const { tab } = this.state
    const { height, currentWarehouse, allWarehouses } = this.props

    return (
      <div style={layoutFlex('120rem')}>
        <div className="product-analysis-layout-overflow">
          <div className="product-analysis-flex-demand">
            <AppBar position="static">
              <Tabs onChange={this.handleTabChange} value={tab}>
                <Tab value="Current" label="Current Warehouse" />
                <Tab value="All" label="All Warehouses" />
              </Tabs>
            </AppBar>
            <TabContainer>
              <div className="product-analysis-flex-wrapper-1">
                <div style={{ height: '68%' }}>
                  <Grid
                    // propertyName={
                    //   tab === 'Current' ? 'demand.currentWarehouse' : 'demand.allWarehouses'
                    // }
                    addButtonText="Update Demand"
                    showAddButtonAlways
                    addButtonStyleParams={{
                      showIcon: false
                    }}
                    title="Demand"
                    getRowNodeId={getRowNodeId}
                    headerStyle={headerStyle}
                    columnDefs={columnDefs}
                    pinnedBottomRowData={this.getBottomData()}
                    height={height - 78}
                    rowSelection="single"
                    rowData={
                      tab === 'Current'
                        ? currentWarehouse.toJS()
                        : allWarehouses.toJS()
                    }
                    width="100%"
                    enableSorting
                    fullWidth
                  />
                </div>
              </div>
            </TabContainer>
          </div>

          <div className="product-analysis-bar-wrapper">
            <div style={{ flex: '1 1' }}>
              <Paper
                style={{
                  margin: '0 15px',
                  height: height ? height - 5 : 400,
                  padding: 10
                }}
              >
                <ReactHighcharts config={this.barChartConfig()} />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const contextObj = {
  allWarehouses: form => getValue(form, 'demand.allWarehouses', emptyList),
  allWarehouses48MonthDemand: form =>
    getValue(form, 'demand.allWarehouses48MonthDemand', emptyList),
  currentWarehouse: form =>
    getValue(form, 'demand.currentWarehouse', emptyList),
  currentWarehouse48MonthDemand: form =>
    getValue(form, 'demand.currentWarehouse48MonthDemand', emptyList)
}

const demandWithContext = withContext(contextObj)(Demand)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  demandWithContext
)
