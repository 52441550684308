import React, { Component } from 'react'
import {
  IconButton,
  Icon,
  Popover,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'

import PropTypes from 'prop-types'
import { getRgb, formatNumber } from 'utils'

const convertToDollar = ['Cost', 'Last Cost', 'List']

const headingStyle = {
  // color: '#517b9c',
  fontSize: '12px',
  lineHeight: '16px',
  margin: 10,
  textAlign: 'center',
  textTransform: 'uppercase'
}

class CurrentPrice extends Component {
  static propTypes = {
    data: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number
    ])
  }

  static defaultProps = {
    data: null,
    value: null
  }

  constructor(...args) {
    super(...args)
    this.state = {
      anchorEl: null
    }
  }

  handleClick = e => this.setState({ anchorEl: e.currentTarget })

  handleClose = () => this.setState({ anchorEl: null })

  getColorFormatting = (data, value) => {
    if (data && typeof data === 'object' && data.color) {
      return Object.keys(data.color).length ? getRgb(data.color) : '#444'
    }

    if (value && typeof value === 'object' && value.color) {
      return Object.keys(value.color).length ? getRgb(value.color) : '#444'
    }

    return '#444'
  }

  displayCellValue = (valueFormatted, value) => {
    if (valueFormatted) {
      return valueFormatted
    }
    if (value && typeof value !== 'object') {
      return value
    }
    return ''
  }

  render() {
    const { data, value, valueFormatted } = this.props
    const open = !!this.state.anchorEl
    return (
      <>
        <span
          style={{ color: this.getColorFormatting(data, value) }}
          onClick={this.handleClick}
        >
          {this.displayCellValue(valueFormatted, value)}
        </span>
        {this.props?.data?.currentPrice?.tooltipData ? (
          <>
            <IconButton size="small" onClick={this.handleClick}>
              <Icon>more_vert</Icon>
            </IconButton>
            <Popover
              anchorEl={this.state.anchorEl}
              open={open}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <div>
                {this.props?.data?.dataId &&
                this.props?.data?.description?.value ? (
                  <h3 style={headingStyle}>
                    <strong
                      style={{
                        color: '#517b9c',
                        display: 'inline-block',
                        marginRight: 3
                      }}
                    >
                      {this.props.data.dataId}:
                    </strong>
                    <span>{this.props.data.description.value}</span>
                  </h3>
                ) : null}
                <TableContainer>
                  <Table>
                    <TableBody>
                      {this.props?.data?.currentPrice?.tooltipData.map(x => (
                        <TableRow>
                          <TableCell>{x.label}:</TableCell>
                          <TableCell align="right">
                            {x.label && convertToDollar.includes(x.label)
                              ? formatNumber(x.value, '$0,0.0000')
                              : x.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Popover>
          </>
        ) : null}
      </>
    )
  }
}

export default CurrentPrice
