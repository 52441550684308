/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CircularProgress, Icon, IconButton, Tooltip } from '@material-ui/core'
import { getIn } from 'utils'
import { debounce } from 'lodash'
import { cancelCardReaderPayment } from 'pages/SalesOrder/actions'

// import DDICardWrapper from 'components/DDICardWrapper'

// find way to pass dispatch so no need for class component
// for the modal actions
// --LL 1/13/20

const mapStateToProps = (state, ownProps) => {
  const { data: { form } } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const overpaymentResponsePosting = getIn(formState, 'overpaymentResponsePosting') || false
  const cardProcessorInitialized = getIn(formState, 'cardProcessorInitialized') || false

  return {
    overpaymentResponsePosting,
    cardProcessorInitialized
  }
}

export class OverpaymentModal extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired
  }

  componentDidMount() {
    console.log(this.props)
  }

  cancelCardReaderPayment = debounce(e => {
    const { data: { form }, dispatch } = this.props

    dispatch(cancelCardReaderPayment.try(form))

  }, 300)

  render() {
    const { data: { option }, overpaymentResponsePosting, cardProcessorInitialized } = this.props
    const mes = this.props.data && this.props.data.message ? this.props.data.message.split('/r/n') : []

    return (
      <div style={{ width: '100%' }}>
        {(option === 'processorPayment' && overpaymentResponsePosting && cardProcessorInitialized) ? (
          <div style={{ alignItems: 'center', background: 'rgba(26,188,247, 0.05)', borderRadius: 4, border: '1px solid #1abcf7', display: 'flex', justifyContent: 'center', marginBottom: 10, padding: 5 }}>
            <CircularProgress color="secondary" size={15} thickness={7} style={{ marginRight: 5 }} />
            <span style={{ color: '#1abcf7' }}>Please go ahead and swipe your card... this modal will close automatically after payment</span>
            <Tooltip title="Cancel Transaction">
              <IconButton onClick={this.cancelCardReaderPayment}>
                <Icon style={{ color: '#d9534f' }}>cancel</Icon>
              </IconButton>
            </Tooltip>
          </div>
        ) : null}
        <p style={{ textAlign: 'center' }}>
          {(mes && mes[0] && mes[1]) ? (
            <>
              <span>{mes[0]}</span>
              <span style={{ display: 'block', textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>{mes[1]}</span>
            </>
          ) : null}
        </p>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(OverpaymentModal)
