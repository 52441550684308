import { is } from 'utils'

export const getColumnDescription = (hasHeaders, lineItems = [], next) => {
  if (
    hasHeaders &&
    Array.isArray(lineItems) &&
    lineItems?.[0]?.[next] &&
    typeof lineItems?.[0]?.[next] === 'string'
  ) {
    return lineItems[0][next]
  }

  return is.number(Number(next)) ? `Column ${next}` : next
}

export const associatedFieldStyleOverrides = {
  float: 'none',
  clear: 'none',
  width: 'auto',
  marginTop: 0,
  minWidth: 160
}

export const sortData = (arr = [], sortOrder = [], comparator = 'field') =>
  arr.sort((a, b) => {
    if (
      a?.[comparator] &&
      b?.[comparator] &&
      sortOrder?.indexOf(a[comparator]) < sortOrder?.indexOf(b[comparator])
    ) {
      return -1
    }
    if (
      a?.[comparator] &&
      b?.[comparator] &&
      sortOrder?.indexOf(a[comparator]) > sortOrder?.indexOf(b[comparator])
    ) {
      return 1
    }

    return 0
  })

export const mappedColumnDefaults = [
  {
    fieldName: 'Product Number',
    propertyName: 'productNumber'
  },
  {
    fieldName: 'Description',
    propertyName: 'description'
  },
  {
    fieldName: 'Unit of Measure',
    propertyName: 'unitOfMeasure'
  },
  {
    fieldName: 'Product Image URL',
    propertyName: 'productImageUrl'
  },
  {
    fieldName: 'Product Spec Sheet URL',
    propertyName: 'productSpecSheetUrl'
  },
  {
    fieldName: 'Web Description',
    propertyName: 'webDescription'
  },
  {
    fieldName: 'Quantity Ordered',
    propertyName: 'quantityOrdered'
  },
  {
    fieldName: 'Net Cost',
    propertyName: 'netCost'
  },
  {
    fieldName: 'Net Price',
    propertyName: 'netPrice'
  },
  {
    fieldName: 'Item Group',
    propertyName: 'itemGroup'
  },
  {
    fieldName: 'S/O External Comments',
    propertyName: 'sOExternalComments'
  }
]

export const createImportPostData = (lineItems = []) => {
  if (lineItems && Array.isArray(lineItems)) {
    return lineItems.reduce((acc, next) => {
      const { rowId, ...rest } = next
      acc = acc.concat(`${Object.values({ ...rest })}\r\n`)
      return acc
    }, '')
  }

  return ''
}
