/* eslint react/sort-comp: 0, react/no-did-mount-set-state: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Select, MenuItem } from '@material-ui/core'
import shortid from 'shortid'
import memoize from 'memoize-one'
import {
  // getErrorIcon,
  showRequiredIcon,
  isDisabled,
  updateGridCellData
} from 'components/EditableGrid/utils'
import { plainDeepEqual } from 'utils'
import shouldUpdate from 'hoc/shouldUpdate'

let isChanging = false

class SelectGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    values: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.value || '',
      values: props.values || []
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)
    this.showRequiredIcon = showRequiredIcon.bind(this)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef.cellRendererParams.requiredFn = this.props.colDef.cellRendererParams.requiredFn.bind(
        this
      )
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.value &&
      !prevState.value &&
      nextProps.value !== prevState.value &&
      !isChanging
    ) {
      return { value: nextProps.value }
    }

    if (!plainDeepEqual(nextProps.values, prevState.values)) {
      return { values: nextProps.values }
    }

    return null
  }

  componentDidMount() {
    const {
      colDef: {
        cellRendererParams: { initialValue },
        field
      }
    } = this.props

    if (initialValue && this.props.value === '') {
      this.setState({ value: initialValue }, () => this.updateGridCellData())
    }
  }

  handleChange = event => {
    isChanging = true

    this.setState({ value: event.target.value }, () => {
      if (this.props.value !== this.state.value) {
        this.updateGridCellData()
      }
      isChanging = false
    })
  }

  isFieldDisabled = () => {
    const {
      value,
      colDef: {
        cellRendererParams: { disabledFn, formatter }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return this.isDisabled()
  }

  render() {
    const {
      colDef: {
        cellRendererParams: {
          selectValue = 'dataId',
          selectDescription = 'description',
          valuesFilterFn
        }
      },
      data
    } = this.props
    // console.log(this.props)

    const { values } = this.state

    const menuItems =
      valuesFilterFn && typeof valuesFilterFn === 'function'
        ? valuesFilterFn(values, data)
        : values

    return (
      <div style={{ position: 'relative' }}>
        {this.showRequiredIcon()}
        <Select
          displayEmpty
          inputProps={{
            name: shortid.generate(),
            id: shortid.generate()
          }}
          value={this.state.value}
          fullWidth
          onChange={this.handleChange}
          disabled={this.isFieldDisabled()}
          style={{ paddingLeft: 10 }}
        >
          {menuItems.map((item, idx) => (
            // <MenuItem value={item.dataId}>{item.description}</MenuItem>
            <MenuItem value={item[selectValue]}>
              {item[selectDescription]}
            </MenuItem>
          ))}
        </Select>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(SelectGridCell)
