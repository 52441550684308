import { createAsyncRequestTypes } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export const TOOLTIP_FIELD_HOC = '@@TOOLTIP_FIELD_HOC'

/* get purchase order tooltip */
export const GET_PURCHASE_ORDER_TOOLTIP = createAsyncRequestTypes(
  `${TOOLTIP_FIELD_HOC}/GET_PURCHASE_ORDER_TOOLTIP`
)
export const GET_PURCHASE_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/purchaseorderinquiry/summary`

/* get sales order tooltip */
export const GET_SALES_ORDER_TOOLTIP = createAsyncRequestTypes(
  `${TOOLTIP_FIELD_HOC}/GET_SALES_ORDER_TOOLTIP`
)
export const GET_SALES_ORDER_TOOLTIP_URL = `${BASE_INFORM_API_URL}/salesorderinquiry/summary`

/* general tooltip handler */
export const GET_INDEX_SEARCH_TOOLTIP = createAsyncRequestTypes(
  `${TOOLTIP_FIELD_HOC}GET_INDEX_SEARCH_TOOLTIP`
)

/* get sales order tooltip */
export const GET_WARRANTY_CLAIM_TOOLTIP = createAsyncRequestTypes(
  `${TOOLTIP_FIELD_HOC}/GET_WARRANTY_CLAIM_TOOLTIP`
)
export const GET_WARRANTY_CLAIM_TOOLTIP_URL = `${BASE_INFORM_API_URL}/search/claim/summary`
