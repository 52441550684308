import React, { useState } from 'react'
import { call, put, select, take, spawn } from 'redux-saga/effects'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import { warningModal } from 'modals/sagas'
import { ProductSerialNumberInquiryInModal } from 'pages/ProductSerialNumberInquiry'
import { getProductInSerialNumberInquiry } from 'pages/ProductSerialNumberInquiry/api'
import { addScreenInModal } from 'ddiForm/actions'

function* onApiSuccess(response, meta, form) {
  // debugger
  yield put(
    getEntityAsync.success(response, {
      form,
      thunk: meta.thunk
    })
  )
}

function* onApiError(error) {
  if (error?.message) {
    yield call(warningModal, error.message, 'Warning')
  }
}

const OpenProductSerialNumberInquiryInModal = (form, productId) =>
  addScreenInModal(form, {
    screen: ProductSerialNumberInquiryInModal(
      `${form}.productSerialNumberInquiry`
    ),
    formName: `${form}.productSerialNumberInquiry`,
    apiMethod: getProductInSerialNumberInquiry,
    apiArgs: {
      dataId: productId,
      groupNames: ['currentInventory']
    },
    onApiSuccess,
    onApiError,
    modalOptions: {
      title: 'Product Serial Number Inquiry',
      modalOverrideClass: 'serial-number-inquiry-in-modal',
      hideActions: true,
      maxSize: true
    },
    route: 'productSerialNumberInquiry'
  })

export default OpenProductSerialNumberInquiryInModal
