import React from 'react'
import { Icon } from '@material-ui/core'

import { informBlue } from 'theme/colors'

const NoItemsInCart = ({
  isTablet = false,
  headerText = 'Your Cart Is Empty',
  instructionsText = 'Use the Product Search or find an existing order to get started',
  statusIcon = 'shopping_cart',
  wrapperStyleOverride = {}
}) => (
  <div
    style={{
      alignItems: 'center',
      background: '#f5f7f7',
      display: 'flex',
      justifyContent: 'center',
      padding: '100px 20px',
      margin: 20,
      ...wrapperStyleOverride
    }}
  >
    <div
      style={{
        display: 'flex',
        borderRadius: 100,
        background: '#fff',
        padding: 10,
        maxWidth: 300
      }}
    >
      <div
        style={{
          background: informBlue,
          borderRadius: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 15,
          height: 55,
          minWidth: 55
        }}
      >
        <Icon fontSize="large" style={{ color: '#fff' }}>
          {statusIcon}
        </Icon>
      </div>
      <div style={{ paddingRight: 15 }}>
        <h2
          style={{
            margin: '0 0 5px 0',
            color: informBlue,
            fontSize: 16
          }}
        >
          {headerText}
        </h2>
        <p style={{ margin: 0, fontSize: 12, color: '#444' }}>
          {instructionsText}
        </p>
      </div>
    </div>
  </div>
)

export default NoItemsInCart
