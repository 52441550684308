import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Grid from 'grid'
import memoize from 'memoize-one'
// import { withContext } from 'ddiForm'
import {
  emptyList,
  formatDollarFields,
  // getValue,
  makeRowDataUnique,
  plainDeepEqual,
  getIn
} from 'utils'

import VoidPaymentCell from './VoidPaymentCell'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps

  const formState = getIn(state, `ddiForm.${form}`)
  const payments = getIn(formState, 'values.payments') || emptyList
  const isEditing = getIn(formState, 'isEditing') || false

  return {
    isEditing,
    payments
  }
}

const propTypes = {
  form: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

export const getColumnDefs = ({ form, dispatch, isEditing }) => {
  const columnDefs = [
    {
      field: 'paymentTypeDescription',
      headerName: 'Type',
      suppressFilter: true,
      suppressMenu: true
    },
    {
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right',
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: formatDollarFields,
      suppressFilter: true,
      suppressMenu: true
    },
    {
      field: 'referenceResolved',
      headerName: 'Reference',
      suppressFilter: true,
      suppressMenu: true
    },
    // add delete/void payment cell
    {
      headerName: '',
      cellRendererFramework: VoidPaymentCell,
      cellRendererParams: {
        form,
        dispatch,
        isEditing
      },
      hide: !isEditing,
      maxWidth: 50,
      suppressMenu: true,
      suppressFilter: true
    }
  ]

  return columnDefs
}

const getRowData = memoize(
  (rowData = []) => makeRowDataUnique(rowData, 'rowId'),
  plainDeepEqual
)

const PaymentsGrid = ({ form, dispatch, payments, isEditing }) => {
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (payments && payments.toJS) {
      setRowData(payments.toJS())
    } else {
      setRowData([])
    }
  }, [payments])

  const onGridReady = params => {
    if (params && params.api) {
      params.api.sizeColumnsToFit()
    }
  }

  return (
    <>
      <Grid
        getRowNodeId={data => data.rowId}
        columnDefs={getColumnDefs({
          form,
          dispatch,
          isEditing
        })}
        rowData={getRowData(rowData)}
        height={150}
        onGridReady={onGridReady}
        rowSelection="single"
        suppressHorizontalScroll
      />
    </>
  )
}

PaymentsGrid.propTypes = propTypes

/* updated 9/25/2020 SVE */
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(PaymentsGrid)
