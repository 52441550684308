import React from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { getIn, getValue } from 'utils'
import { Button, Link } from '@material-ui/core'
import { informBlue } from 'theme/colors'
import { print } from '../actions'

const marginRight = { marginRight: 5 }

export const LeftFooterButtons = ({
  allowWarrantyPageLink = false,
  form,
  warrantyPage,
  hasRecord = false,
  isEditing = false,
  isNew = false,
  newMode = false,
  canEdit = false
}) => {
  const dispatch = useDispatch()

  const onClickPrintButton = debounce(e => dispatch(print.try(form)), 300)

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', float: 'left' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <DDIButton
          variant="contained"
          label="Print"
          onClick={onClickPrintButton}
          preventAutoDisable
          tabIndex={0}
          disabled={!hasRecord || !isEditing || isNew}
        />
        {hasRecord && allowWarrantyPageLink && warrantyPage ? (
          <Link
            style={{
              color: informBlue,
              fontSize: '1.2rem',
              marginLeft: 15,
              marginRight: 15
            }}
            target="_blank"
            href={warrantyPage}
            rel="noreferrer"
          >
            Warranty Pages
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default withContext({
  allowWarrantyPageLink: form => getValue(form, 'allowWarrantyPageLink', false),
  hasRecord: form => getIn(form, 'hasRecord'),
  isEditing: form => getIn(form, 'isEditing'),
  newMode: form => getIn(form, 'newMode'),
  isNew: form => getValue(form, 'isNew', false),
  canEdit: form => getValue(form, 'canEdit', false),
  warrantyPage: form => getValue(form, 'warrantyPage', '')
})(LeftFooterButtons)
