import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import { useDispatch } from 'react-redux'
import { remove } from 'snackbar/actions'
import nl2br from 'react-newline-to-break'

const SuccessAlert = id => props => {
  const dispatch = useDispatch()
  // console.log('PaymentAlert', props, id)

  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      style={{ width: 360, marginTop: 5 }}
      onClose={() => dispatch(remove({ id }))}
      {...props}
    >
      <>{nl2br(props.children)}</>
    </MuiAlert>
  )
}

export default SuccessAlert

