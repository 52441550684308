import React, { Component } from 'react'
import {
  IconButton,
  Icon,
  Popover,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import { getRgb, formatNumber } from 'utils'

const convertToDollar = ['Cost', 'Last Cost', 'List']

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#444',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

const headingStyle = {
  color: '#444',
  fontSize: '12px',
  lineHeight: '16px',
  margin: '10px 0',
  textAlign: 'center'
}

class CurrentPriceTooltip extends Component {
  static propTypes = {
    data: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number
    ])
  }

  static defaultProps = {
    data: null,
    value: null
  }

  constructor(...args) {
    super(...args)
    this.state = {
      anchorEl: null
    }
  }

  getColorFormatting = (data, value) => {
    if (data && typeof data === 'object' && data.color) {
      return Object.keys(data.color).length ? getRgb(data.color) : '#444'
    }

    if (value && typeof value === 'object' && value.color) {
      return Object.keys(value.color).length ? getRgb(value.color) : '#444'
    }

    return '#444'
  }

  displayCellValue = (valueFormatted, value) => {
    if (valueFormatted) {
      return valueFormatted
    }
    if (value && typeof value !== 'object') {
      return value
    }
    return ''
  }

  render() {
    const { data, value, valueFormatted } = this.props
    const open = !!this.state.anchorEl
    return (
      <>
        {this.props?.data?.currentPrice?.tooltipData ? (
          <HtmlTooltip
            title={
              <div>
                {this.props?.data?.dataId &&
                this.props?.data?.description?.value ? (
                  <h3 style={headingStyle}>
                    <strong
                      style={{
                        color: '#517b9c',
                        display: 'inline-block',
                        marginRight: 3
                      }}
                    >
                      {this.props.data.dataId}:
                    </strong>
                    <span>{this.props.data.description.value}</span>
                  </h3>
                ) : null}
                <TableContainer>
                  <Table>
                    <TableBody>
                      {this.props?.data?.currentPrice?.tooltipData.map(x => (
                        <TableRow>
                          <TableCell>{x.label}:</TableCell>
                          <TableCell align="right">
                            {x.label && convertToDollar.includes(x.label)
                              ? formatNumber(x.value, '$0,0.0000')
                              : x.value}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          >
            <span style={{ color: this.getColorFormatting(data, value) }}>
              {this.displayCellValue(valueFormatted, value)}
            </span>
          </HtmlTooltip>
        ) : (
          <span style={{ color: this.getColorFormatting(data, value) }}>
            {this.displayCellValue(valueFormatted, value)}
          </span>
        )}
      </>
    )
  }
}

export default CurrentPriceTooltip
