import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Grid from 'grid'
import DistributorsTable from 'pages/ProductMaster/tabs/Inventory/components/DistributorsTable'
import { getIn } from 'utils'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  getSidebarRowData,
  usePrevious
} from 'pages/SalesOrder/utils'

// const mapState = (state, ownProps) => {
//   const { form } = ownProps
//   const formState = getIn(state, `ddiForm.${form}`)

//   return {}
// }

const getRowNodeId = ({ dataId, description, number }) => {
  const compId = `${dataId}|${description}|${number}`
  return compId
}

const storesCol = [
  {
    field: 'number',
    headerName: 'Number',
    cellClass: 'align-right',
    headerClass: 'align-right',
    maxWidth: 100
  },
  { field: 'description', headerName: 'Location' },
  {
    field: 'quantityAvailable',
    headerName: 'Quantity',
    cellClass: 'align-right',
    headerClass: 'align-right',
    maxWidth: 150
  },
  {
    field: 'rank',
    headerName: 'Rank',
    cellClass: 'text-center',
    headerClass: 'text-center',
    maxWidth: 80
  },
  {
    field: 'dataId',
    headerName: 'Zone',
    cellClass: 'text-center',
    headerClass: 'text-center'
  }
]

export const headerStyle = {
  background: '#e1e3e4',
  color: '#444',
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '17px',
  margin: 0,
  padding: '5px 0',
  textAlign: 'center',
  width: '100%'
}

export const JohnstoneNational = ({ form, johnstoneInventory = null }) => {
  const [data, setData] = useState({})

  useEffect(() => {
    if (johnstoneInventory) {
      setData(johnstoneInventory)
    }
  }, [johnstoneInventory])

  const gridHeight = data?.stores?.data?.length
    ? data.stores.data.length * 28
    : 400

  return (
    <div>
      <div style={{ marginBotton: 15 }}>
        <DistributorsTable
          data={data?.distributors?.data || []}
          title={data?.distributors?.display || ''}
        />
      </div>

      {data?.stores?.data ? (
        <div style={{ clear: 'both', float: 'left', width: '100%' }}>
          <Grid
            title="Stores"
            getRowNodeId={getRowNodeId}
            rowData={data.stores.data}
            columnDefs={storesCol}
            headerStyle={headerStyle}
            height={gridHeight}
            enableSorting
          />
        </div>
      ) : null}
    </div>
  )
}

export default JohnstoneNational
