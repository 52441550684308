import React, { memo } from 'react'
import PropTypes from 'prop-types'
// import { GridField } from 'ddiForm'
import { withContext } from 'ddiForm'
import Grid from 'grid'
import { formatDollarFields, noop } from 'utils'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'
import { isEqual } from 'lodash'

const getRowNodeId = data => data.level

// const columnDefs = [
//   {
//     checkboxSelection: true,
//     width: 50,
//     suppressMenu: true,
//   },
//   {
//     field: 'level',
//     headerName: 'Level',
//     suppressMenu: true,
//   },
//   {
//     field: 'description',
//     headerName: 'Method',
//     suppressMenu: true,
//   },
//   {
//     cellClass: 'align-right',
//     field: 'factoredPrice',
//     headerClass: 'align-right',
//     headerName: 'Price',
//     valueFormatter: formatDollarFields,
//     suppressMenu: true,
//   },
//   {
//     field: 'formulaDisplay',
//     headerName: 'Formula',
//     suppressMenu: true,
//   },
//   {
//     cellClass: 'align-right',
//     field: 'cost',
//     headerClass: 'align-right',
//     headerName: 'Cost',
//     valueFormatter: formatDollarFields,
//     suppressMenu: true,
//   }
// ]

const CompetitivePricingTab = ({
  dispatch,
  form,
  handleTabChange = noop,
  priceChange = {},
  lineNumber,
  isEditing
}) => {
  function onRowSelected(params) {
    if (isEditing && params && params.data && !!params.data.dataId) {
      dispatch(
        changeProvisionalLineItem.try(form, {
          propertyName: 'setCompetitivePrice',
          lineNumber,
          value: params.data.dataId
        })
      )
      handleTabChange(null, 'Price')
    }
  }
  const columnDefs = [
    {
      checkboxSelection: isEditing,
      width: 50,
      suppressMenu: true
    },
    {
      field: 'level',
      headerName: 'Level',
      suppressMenu: true
    },
    {
      field: 'description',
      headerName: 'Method',
      suppressMenu: true
    },
    {
      cellClass: 'align-right',
      field: 'factoredPrice',
      headerClass: 'align-right',
      headerName: 'Price',
      valueFormatter: formatDollarFields,
      suppressMenu: true
    },
    {
      field: 'formulaDisplay',
      headerName: 'Formula',
      suppressMenu: true
    },
    {
      cellClass: 'align-right',
      field: 'cost',
      headerClass: 'align-right',
      headerName: 'Cost',
      valueFormatter: formatDollarFields,
      suppressMenu: true
    }
  ]
  return (
    <Grid
      getRowNodeId={getRowNodeId}
      columnDefs={columnDefs}
      height={360}
      onRowSelected={onRowSelected}
      rowData={
        priceChange && priceChange.competitivePrices
          ? priceChange.competitivePrices
          : []
      }
    />
  )
}

CompetitivePricingTab.propTyes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  priceChange: PropTypes.object.isRequired
}

// export default CompetitivePricingTab
const contextObj = {
  isEditing: form => form.get('isEditing') || false
}

/* fixed 9/23/2020 SVE */
export default memo(withContext(contextObj)(CompetitivePricingTab), isEqual)
