import React from 'react'
import { withContext } from 'ddiForm'
import masterStyles from 'theme/masterStyles'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'

import VendorTooltipSearchCell from 'pages/ProductMaster/tabs/Setup/components/grids/VendorTooltipSearchCell'


const getRowNodeId = data => {
  return data.dataId
}

const columnDefs = props => {
  const params = {
    disabled: !props.isEditing,
    focusedCell: props.focusedCell,
    form: props.form,
    onChange: props.onCellChange,
    propertyName: props.propertyName
  }

  const textEditor = {
    cellRendererFramework: TextFieldGridCell,
    cellRendererParams: params
  }

  const colDefs = [
    {
      cellRendererFramework: VendorTooltipSearchCell,
      cellRendererParams: {
        ...params,
        indexSearchType: 'Vendor',
        meta: {
          allowInstantSearch: true,
          allowNavigation: false,
          allowSearchAll: false,
          allowSpecialProducts: false,
          fieldName: "ediVendor",
          hasFilters: false,
          hideToggle: true,
          isSecure: false,
          minimumKeywordLength: 2,
          openText: "Open Vendor Master",
          searchType: "Vendor"
        }
      },
      field: 'dataId',
      headerName: 'Vendor',
    },
    { field: 'description', headerName: 'Description'},
    {
      ...textEditor,
      cellRendererParams: {
        ...params,
        isRequired: true
      },
      field: 'shipToId',
      headerName: "Vendor's Ship To ID",
    },
    {
      cellRendererFramework: DeleteGridRowCell,
      cellRendererParams: {
        ...params
      },
      hide: !props.isEditing,
      maxWidth: 50,
      width: 50
    }
  ]

  return colDefs
}

const layoutStyle = {
  display: 'flex',
  flex: '0.1 1 60rem',
  flexDirection: 'row',
  flexWrap: 'wrap',
  maxWidth: '100%',
  margin: '0 auto',
  height: 400
}

const VendorIds = ({ form, isEditing }) => {

  return (
    <div style={layoutStyle}>
      <div
        style={{
          flex: '1 1',
          padding: '0 0.5rem'
        }}
      >
        {/*
          <DDICardWrapper title="EDI" contentStyle={{ padding: '0.5rem' }}>
            <GreyRow style={styles.marginBottom}>
              <div style={styles.searchRow.wrapper}>
                <div className="cm-search-row-label" style={styles.searchRow.label}>
                  EDI Vendor:
                </div>
                <div style={styles.searchRow.input}>
                  <DDIIndexSearch
                    propertyName="ediVendor"
                    metaKey="ediVendor"
                    minWidth="100%"
                    width="100%"
                    style={{ maxWidth: '100%', width: '100%' }}
                  />
                </div>
                <div style={styles.searchRow.description}>
                  <Field component={Label} propertyName="ediVendorName" />
                </div>
              </div>
            </GreyRow>
            <GreyRow style={styles.marginBottom}>
              <div style={styles.searchRow.wrapper}>
                <div className="cm-search-row-label" style={styles.searchRow.label}>
                  EDI Number:
                </div>
                <div style={styles.searchRow.input}>
                  <DDITextField
                    propertyName="ediNumber"
                    minWidth="100%"
                    width="100%"
                    style={{ maxWidth: '100%', width: '100%' }}
                  />
                </div>
              </div>
            </GreyRow>
            <GreyRow style={styles.marginBottom}>
              <div style={styles.searchRow.wrapper}>
                <div className="cm-search-row-label" style={styles.searchRow.label}>
                  EDI Ship To Number:
                </div>
                <div style={styles.searchRow.input}>
                  <DDITextField
                    propertyName="ediShipToNum"
                    minWidth="100%"
                    width="100%"
                    style={{ maxWidth: '100%', width: '100%' }}
                  />
                </div>
              </div>
            </GreyRow>
          </DDICardWrapper>

        */}
        <EditableGrid
          addButtonText="Add Vendor Ship To ID"
          emptyRow={{
            dataId: null,
            description: '',
            shipToId: null

          }}
          form={form}
          propertyName="vendorIds"
          getRowNodeId={getRowNodeId}
          columnDefs={columnDefs}
          enableSorting
          width="100%"
          height={400}
          isEditing={isEditing}
          title="Vendor Ids"
          skipValidationOnUnmount
        />
      </div>
    </div>
  )

}

export default withContext({
  isEditing: form => form.get('isEditing') || false
})(VendorIds)
