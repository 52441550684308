import salesOrderBehaviors, {
  setAdditionalData
} from 'pages/SalesOrder/behaviors'
import { Set } from 'immutable'
import * as DDIMASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { deleteIn, fromJS, getIn, setIn } from 'utils'

import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import activityBehaviors from 'components/MasterScreen/Activities/reducer'
import {
  DELETE_ATTACHMENT,
  UPLOAD_ATTACHMENTS
} from 'components/MasterScreen/Attachments/constants'
import { EXACT_MATCH_SEARCH as AR_INVOICE_EXACT_MATCH_SEARCH } from 'components/Search/ARInvoiceSearch/constants'
import * as CONSTANTS from './constants'
import { handleSalesmanCommissionData } from './utils'

const acceptNullValues = [
  'backOrderCycle',
  'branchId',
  'branchDescription',
  'customerId',
  'customerName',
  'dataId',
  'orderId',
  'orderedById',
  'orderedByName',
  'poNumber',
  'shipToEmail',
  'shipToFax',
  'shipToPhone',
  'shipToId',
  'shipToDescription',
  'shipToAddressMap',
  'shipViaId',
  'shipViaDescription',
  'shipDate',
  'writerId',
  'writerDescription',
  'warehouseId',
  'warehouseDescription',
  'invoiceDate',
  'orderDate',
  'jobId',
  'jobDescription',
  'taxId',
  'taxDescription',
  'salesmanId',
  'salesmanDescription',
  'termsId',
  'termsDescription',
  'orderTypeId',
  'orderTypeDescription',
  'priceContractId',
  'priceContractDescription',
  'procurementTypeDescription'
]

const clearChangeInvoiceEditedFields = result => {
  let editedFields = getIn(result, 'editedFields') || Set()
  editedFields = editedFields?.reduce
    ? editedFields.reduce((acc, next) => {
        if (!next.match(/changeInvoice\./)) {
          acc = acc.add(next)
        }
        return acc
      }, Set())
    : Set()

  result = setIn(result, 'editedFields', editedFields)
  return result
}

export default {
  ...salesOrderBehaviors,
  [DDICONSTANTS.OPEN_SCREEN.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'fields.lineItems.grid', true)
    result = setIn(result, 'fields.lineItems.selectedRowIndex', 0)
    result = setIn(result, 'additionalDataMap', fromJS({}))
    result = setIn(result, 'previousInvoices', Set())
    return result
  },
  [CONSTANTS.CLEAR_ADDITIONAL_DATA]: (state, action) => {
    let result = state
    result = setIn(result, 'additionalDataMap', fromJS({}))
    return result
  },
  [DDIMASTER_CONSTANTS.GET_ENTITY.REQUEST]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.GET_ENTITY.REQUEST](
      result,
      action
    )
    return result
  },
  [DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.GET_ENTITY.SUCCESS](
      result,
      action
    )

    if (action?.payload?.lineItems) {
      action.payload.lineItems.forEach(x => {
        if (x?.inventory) {
          result = setAdditionalData(result, x)
        }
      })
    }

    if (
      action?.payload?.attachmentsSalesOrder?.attachments &&
      Array.isArray(action?.payload?.attachmentsSalesOrder?.attachments)
    ) {
      const salesOrderAttachments = fromJS(
        action.payload.attachmentsSalesOrder.attachments
      )
      result = setIn(
        result,
        'fields.attachmentsSalesOrder.rowData',
        salesOrderAttachments
      )
      result = setIn(
        result,
        'values.attachmentsSalesOrder',
        salesOrderAttachments
      )
    }

    /* 
      Marc -- this is a patch essentially for the logic 
      in ddiForm/MasterScreen/behaviors line 35 -- SVE 2/12/21
    */
    for (let i = 0; i < acceptNullValues.length; i++) {
      const field = acceptNullValues[i]
      if (action?.payload?.[field] === null) {
        // console.log('nullValue', field)
        result = setIn(result, `values.${field}`, action.payload[field])
        if (getIn(result, `fields.${field}`)) {
          result = setIn(result, `fields.${field}.value`, action.payload[field])
        }
      }
    }

    if (action?.payload?.dataId) {
      let previousInvoices = getIn(result, 'previousInvoices') || Set()
      previousInvoices = Set([
        action.payload.dataId,
        ...Array.from(previousInvoices)
      ])
      result = setIn(result, 'previousInvoices', previousInvoices)
    }
    return result
  },
  [DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    result = setIn(result, 'additionalDataMap', fromJS({}))
    return result
  },
  [CONSTANTS.GET_ADDITIONAL_DATA.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.GET_ADDITIONAL_DATA.SUCCESS]: (state, { payload }) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    result = setAdditionalData(result, payload)
    return result
  },
  [CONSTANTS.GET_ADDITIONAL_DATA.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.SET_NEXT_TAB]: (state, { payload: { tab } }) => {
    let result = state
    result = setIn(result, 'masterOptions.nextTab', tab)
    return result
  },
  [UPLOAD_ATTACHMENTS.SUCCESS]: (state, { payload: { attachments } }) => {
    let result = state
    const selectedAttachmentsTab =
      getIn(result, 'ui.attachmentsTab') || 'This Invoice'
    const attachmentsType =
      selectedAttachmentsTab === 'Sales Order'
        ? 'attachmentsSalesOrder'
        : 'attachments'

    if (attachments && Array.isArray(attachments)) {
      const rowData = fromJS(attachments)
      result = setIn(result, `fields.${attachmentsType}.rowData`, rowData)
      result = setIn(result, `values.${attachmentsType}`, rowData)
    }

    return result
  },
  [DELETE_ATTACHMENT.SUCCESS]: (state, { payload: { attachments } }) => {
    let result = state
    const selectedAttachmentsTab =
      getIn(result, 'ui.attachmentsTab') || 'This Invoice'
    const attachmentsType =
      selectedAttachmentsTab === 'Sales Order'
        ? 'attachmentsSalesOrder'
        : 'attachments'

    if (attachments && Array.isArray(attachments)) {
      const rowData = fromJS(attachments)
      result = setIn(result, `fields.${attachmentsType}.rowData`, rowData)
      result = setIn(result, `values.${attachmentsType}`, rowData)
    }

    return result
  },
  [CONSTANTS.GET_CHANGE_INVOICE_META.SUCCESS]: (
    state,
    { payload: { customFields, defaultTemplates = [], searchFields = {} } }
  ) => {
    let result = state

    const searchMeta = searchFields?.reduce
      ? searchFields.reduce((acc, next) => {
          acc[next.fieldName] = next
          return acc
        }, {})
      : {}

    result = setIn(
      result,
      'meta.changeInvoiceFields.searchFields',
      fromJS(searchMeta)
    )

    result = setIn(
      result,
      'meta.changeInvoiceFields.customFields',
      fromJS(customFields)
    )
    result = setIn(
      result,
      'meta.changeInvoiceFields.defaultTemplates',
      fromJS(defaultTemplates)
    )

    return result
  },
  [CONSTANTS.LOCK_INVOICE_RECORD.SUCCESS]: (state, { payload }) => {
    let result = state
    const {
      salesmanDescription,
      orderTypeDescription,
      jobDescription
    } = payload

    const fields = [
      'discountAmount',
      'discountDate',
      'dueDate',
      'freightCost',
      'invoiceDate',
      'invoiceMessage',
      'jobId',
      'orderTypeId',
      'poNumber',
      'salesmanId',
      'shipDate',
      'sjPeriod'
    ]

    for (const prop in payload) {
      result = setIn(
        result,
        `values.changeInvoice.${prop}`,
        fromJS(payload[prop])
      )

      if (fields.includes(prop)) {
        result = setIn(
          result,
          `fields.changeInvoice.${prop}.value`,
          fromJS(payload[prop])
        )
      }
    }

    result = setIn(
      result,
      `fields.changeInvoice.orderTypeId.description`,
      fromJS(orderTypeDescription || '')
    )

    result = setIn(
      result,
      `fields.changeInvoice.salesmanId.description`,
      fromJS(salesmanDescription || '')
    )

    result = setIn(
      result,
      `fields.changeInvoice.jobId.description`,
      fromJS(jobDescription || '')
    )

    return result
  },
  [CONSTANTS.UNLOCK_INVOICE_RECORD.SUCCESS]: (state, action) => {
    let result = state

    result = deleteIn(result, 'values.changeInvoice')
    result = deleteIn(result, 'fields.changeInvoice')
    result = clearChangeInvoiceEditedFields(result)

    return result
  },
  [CONSTANTS.SAVE_INVOICE_RECORD.SUCCESS]: (state, { payload }) => {
    let result = state

    result = deleteIn(result, 'values.changeInvoice')
    result = deleteIn(result, 'fields.changeInvoice')

    for (const prop in payload) {
      if (prop !== 'dataId') {
        if (getIn(result, `fields.${prop}`)) {
          result = setIn(result, `fields.${prop}.value`, fromJS(payload[prop]))
          result = setIn(result, `values.${prop}`, fromJS(payload[prop]))
        } else {
          result = setIn(result, `values.${prop}`, fromJS(payload[prop]))
        }
      }
    }

    result = clearChangeInvoiceEditedFields(result)
    return result
  },
  [INDEX_SEARCH_CONSTANTS.BLUR]: (
    state,
    { payload: { propertyName, value } }
  ) => {
    let result = state
    if (propertyName && propertyName.match(/changeInvoice\./) && !value) {
      let editedFields = getIn(result, 'editedFields') || Set()
      editedFields = editedFields.add(propertyName)
      result = setIn(result, 'editedFields', editedFields)
      result = setIn(result, `fields.${propertyName}.description`, '')
    }

    return result
  },
  [CONSTANTS.GET_SALESMEN_COMMISSIONS.SUCCESS]: handleSalesmanCommissionData,
  [CONSTANTS.CHANGE_COMMISSION_PROPERTY.SUCCESS]: handleSalesmanCommissionData,
  [CONSTANTS.CHANGE_COMMISSION_VALUE.SUCCESS]: (
    state,
    {
      payload: {
        record: { lineItems }
      }
    }
  ) => {
    let result = state
    if (lineItems && Array.isArray(lineItems)) {
      result = setIn(
        result,
        'values.changeInvoice.salesmenCommissions',
        fromJS(lineItems)
      )
    }
    return result
  },
  [AR_INVOICE_EXACT_MATCH_SEARCH.FAILURE]: (state, action) => {
    let result = state

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    result = setIn(result, 'fields.dataId.value', '')
    result = setIn(result, 'values.dataId', '')
    result = setIn(result, 'additionalDataMap', fromJS({}))
    return result
  },
  ...activityBehaviors
}
