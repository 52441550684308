import React from 'react'
import PropTypes from 'prop-types'
import { GridField } from 'ddiForm'
import { formatNumber } from 'utils'
import { debounce } from 'lodash'
// import memoize from 'memoize-one'
// import { isEqual } from 'lodash'
// import { connect } from 'react-redux'
import { setSelectedAvailableProduct } from 'pages/SalesOrder/actions'
import { addMinWidth } from './mobile/utils'

export const getRowNodeId = data => {
  const { dataId, lineNumber } = data

  return `${lineNumber} - ${dataId}`
}

export const columnDefs = [
  {
    field: 'lineNumber',
    headerName: 'Ln',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    filter: 'agNumberColumnFilter',
    filterParams: {
      clearButton: true,
      inRangeInclusive: true,
      suppressAndOrCondition: true
    }
  },
  {
    field: 'dataId',
    headerName: 'Product',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'description',
    headerName: 'Description',
    filter: 'agTextColumnFilter'
  },
  {
    field: 'netPrice',
    headerName: 'New Price',
    headerClass: 'align-right',
    cellStyle: { textAlign: 'right' },
    valueFormatter: params => formatNumber(params.value, '0,0.0000'),
    suppressMenu: true
  }
]

export const AvailableProducts = ({
  dispatch,
  form,
  height,
  isMobile = false
}) => {
  const debounceTime = isMobile ? 1000 : 500
  const onSelectionChanged = debounce(params => {
    if (params?.api) {
      const selectedProduct = params.api.getSelectedRows()
      // multiple select so will be an array
      const selectedLineNumbers = selectedProduct.map(x => x.lineNumber)
      dispatch(setSelectedAvailableProduct(form, { selectedLineNumbers }))
    }
  }, debounceTime)

  const postProcessPopup = params => {
    if (params && params.type !== 'columnMenu') {
      return
    }

    const { ePopup } = params
    ePopup.style.zIndex = 1300
  }

  return (
    <div>
      <GridField
        propertyName="groups.availableLineItems"
        title="Available Products"
        getRowNodeId={getRowNodeId}
        columnDefs={isMobile ? columnDefs.map(addMinWidth) : columnDefs}
        fullWidth
        height={isMobile ? height : 700}
        rowSelection="multiple"
        onSelectionChanged={onSelectionChanged}
        headerStyle={{
          background: '#d1d3d4',
          color: '#444',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17px',
          margin: 0,
          padding: '5px 0',
          textAlign: 'center',
          width: '100%'
        }}
        suppressRowClickSelection
        postProcessPopup={postProcessPopup}
      />
    </div>
  )
}

AvailableProducts.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired
}

export default AvailableProducts
