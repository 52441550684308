/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const STANDARD_SIMPLE_FORM = '@@STANDARD_SIMPLE_FORM@@'

export const TRY_TOGGLE_PRE_NEW_MODE = `${STANDARD_SIMPLE_FORM}TRY_TOGGLE_PRE_NEW_MODE`

export const TOGGLE_PRE_NEW_MODE = `${STANDARD_SIMPLE_FORM}TOGGLE_PRE_NEW_MODE`

export const SAVE_PRE_NEW_MODE_VALUE = `${STANDARD_SIMPLE_FORM}SAVE_PRE_NEW_MODE_VALUE`
