import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import Grid from 'grid'
// import ProductIdCell from 'pages/InvoiceInquiry/components/ProductIdCell'
import OpenProductAnalysisInSecondaryModalCell from 'components/grid/OpenProductAnalysisInSecondaryModalCell'
import { changeCommissionValue } from 'pages/InvoiceInquiry/actions'
import NumericCellEditor from 'pages/CustomerOrderPad/NumericCellEditor'
import { getIn, emptyList, formatNumber } from 'utils'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { DDIToggle } from 'ddiForm/wrapped'
import TooltipField from 'hoc/TooltipField'

export const SalesmenCommissionsModal = props => {
  const form = props?.data?.form
  const showCommissionsByProduct = Boolean(
    props?.data?.response?.showCommissionsByProduct
  )
  const editable = !props?.data?.response?.disableEdit

  const { salesmenCommissionsList } = props
  const salesmenCommissions = salesmenCommissionsList?.toJS
    ? salesmenCommissionsList.toJS()
    : []

  const dispatch = useDispatch()
  const modalId = props?.id
  const parentModalCb = props?.data?.parentModalCb

  const columnDefs = [
    {
      field: 'lineNumber',
      headerName: 'Ln',
      minWidth: 75
    },
    {
      field: 'dataId',
      headerName: 'Product Number',
      cellRendererFramework: OpenProductAnalysisInSecondaryModalCell,
      cellRendererParams: {
        form,
        modalId,
        parentModalCb
      },
      minWidth: 125
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 175
    },
    {
      field: 'quantityOrdered',
      headerName: 'Quant Ordered',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      minWidth: 100
    },
    {
      field: 'quantityShipped',
      headerName: 'Quant Shipped',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      minWidth: 100
    },
    {
      field: 'uomId',
      headerName: 'Quant UM',
      minWidth: 100
    },
    {
      field: 'unitPrice',
      headerName: 'Unit Price',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      valueFormatter: params => formatNumber(params.value, '0,0.0000'),
      minWidth: 100
    },
    {
      field: 'discountPercent',
      headerName: 'Disc Pct',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      valueFormatter: params => formatNumber(params.value, '00.00'),
      minWidth: 100
    },
    {
      field: 'priceExtension',
      headerName: 'Price Extension',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      valueFormatter: params => formatNumber(params.value, '$0,0.00'),
      minWidth: 100
    },
    {
      field: 'unitCost',
      headerName: 'Unit Cost',
      cellStyle: { textAlign: 'right' },
      headerClass: 'align-right right-align',
      valueFormatter: params => formatNumber(params.value, '0,0.0000'),
      minWidth: 100
    },
    {
      field: 'commissionableType',
      headerName: 'Comm?',
      editable,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => {
        if (
          params?.data?.commissionableOptions &&
          Array.isArray(params.data.commissionableOptions)
        ) {
          const values = params.data.commissionableOptions.map(x => x.dataId)
          return {
            values,
            formatValue: value => {
              const row = params.data.commissionableOptions.find(
                x => x.dataId === value
              )

              return row?.description ? row.description : value
            }
          }
        }

        return { values: [] }
      },
      sortable: false,
      valueFormatter: params => {
        if (
          params?.data?.commissionableOptions &&
          Array.isArray(params.data.commissionableOptions)
        ) {
          const row = params.data.commissionableOptions.find(
            x => x.dataId === params.value
          )
          return row?.description ? row.description : params.value
        }

        return params.value
      },
      onCellValueChanged: params => {
        if (params.newValue === params.oldValue) {
          return
        }
        if (form && dispatch) {
          dispatch(
            changeCommissionValue.try(form, {
              lineNumber: params.data.lineNumber,
              field: 'commissionableType',
              value: params.newValue,
              gridApi: params.api
            })
          )
        }
      },
      minWidth: 125
    },
    {
      field: 'commissionMethodType',
      headerName: 'Comm Method',
      editable,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: params => {
        if (
          params?.data?.commissionMethodOptions &&
          Array.isArray(params.data.commissionMethodOptions)
        ) {
          const values = params.data.commissionMethodOptions.map(x => x.dataId)
          return {
            values,
            formatValue: value => {
              const row = params.data.commissionMethodOptions.find(
                x => x.dataId === value
              )

              return row?.description ? row.description : value
            }
          }
        }

        return { values: [] }
      },
      sortable: false,
      valueFormatter: params => {
        if (
          params?.data?.commissionMethodOptions &&
          Array.isArray(params.data.commissionMethodOptions)
        ) {
          const row = params.data.commissionMethodOptions.find(
            x => x.dataId === params.value
          )
          return row?.description ? row.description : params.value
        }

        return params.value
      },
      onCellValueChanged: params => {
        if (params.newValue === params.oldValue) {
          return
        }
        if (form && dispatch) {
          dispatch(
            changeCommissionValue.try(form, {
              lineNumber: params.data.lineNumber,
              field: 'commissionMethodType',
              value: params.newValue,
              gridApi: params.api
            })
          )
        }
      },
      hide: !showCommissionsByProduct,
      minWidth: 125
    },

    {
      field: 'commissionValueType',
      headerName: 'Comm %/$',
      hide: !showCommissionsByProduct,
      minWidth: 125
    },
    {
      colId: 'commissionValue',
      field: 'commissionValue',
      headerClass: 'align-right right-align',
      headerName: 'Comm Value',
      hide: !showCommissionsByProduct,
      editable,
      cellEditor: 'numericCellEditor',
      cellClass: 'align-right right-align',
      onCellValueChanged: params => {
        if (
          params.newValue === params.oldValue ||
          (params.oldValue === null && params.newValue === '')
        ) {
          return
        }

        if (form && dispatch) {
          dispatch(
            changeCommissionValue.try(form, {
              lineNumber: params.data.lineNumber,
              field: 'commissionValue',
              value: params.newValue,
              gridApi: params.api
            })
          )
        }
      },
      valueFormatter: params => formatNumber(params.value, '0,0.00'),
      minWidth: 125
    },
    {
      colId: 'commissionSalesmanCost',
      field: 'commissionSalesmanCost',
      headerClass: 'align-right right-align',
      headerName: 'Comm Slsm Cost',
      editable,
      cellEditor: 'numericCellEditor',
      cellClass: 'align-right right-align',
      onCellValueChanged: params => {
        if (
          params.newValue === params.oldValue ||
          (params.oldValue === null && params.newValue === '')
        ) {
          return
        }

        if (form && dispatch) {
          dispatch(
            changeCommissionValue.try(form, {
              lineNumber: params.data.lineNumber,
              field: 'commissionSalesmanCost',
              value: params.newValue,
              gridApi: params.api
            })
          )
        }
      },
      valueFormatter: params => formatNumber(params.value, '0,0.0000'),
      minWidth: 150
    }
  ]

  const postProcessPopup = params => {
    const ePopupParent = params?.ePopup?.parentNode
    if (
      ((params?.type && params.type === 'ag-list') ||
        (params?.type && params.type === 'popupCellEditor')) &&
      ePopupParent
    ) {
      ePopupParent.style.zIndex = '9999'
      ePopupParent.style.position = 'absolute'
      ePopupParent.style.top = '0'
      ePopupParent.style.left = '0'
      ePopupParent.classList.add('ag-popup-show-dropdown')
    }
  }

  const onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId && editable) {
      setTimeout(() => {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column && params.column.colId
        })
      }, 0)
    }
  }

  const getRowNodeId = data => data.lineNumber

  return (
    <div id="salemen-commissions-modal-wrapper">
      <div style={{ width: '100%', overflow: 'auto' }}>
        <Grid
          id="salemenCommissionsGrid"
          key="salemenCommissionsGrid"
          rowData={salesmenCommissions}
          columnDefs={columnDefs}
          defaultColDef={{
            resizable: true,
            filter: true,
            sortable: true,
            minWidth: 125
          }}
          getRowNodeId={getRowNodeId}
          height={400}
          singleClickEdit
          components={{ numericCellEditor: NumericCellEditor }}
          postProcessPopup={postProcessPopup}
          popupParent={document.querySelector(
            '#salemen-commissions-modal-wrapper'
          )}
          applyColumnDefOrder
          reactUi={false}
        />
      </div>
      <div>
        <AssociatedFieldsWrapper
          style={{ width: 260, float: 'none', clear: 'none' }}
        >
          <DDIToggle
            propertyName="changeInvoice.commissionIgnoreMinimums"
            label="Ignore Order Minimums"
            preventAutoDisable
            disableDoubleClickUnlock
            disabled={!editable}
          />
        </AssociatedFieldsWrapper>
      </div>
    </div>
  )
}

export default withContext({
  salesmenCommissionsList: form =>
    getIn(form, 'values.changeInvoice.salesmenCommissions') || emptyList
})(SalesmenCommissionsModal)
