import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { DDITextField } from 'ddiForm/wrapped'
import { getIn } from 'utils'

export const SerialNotesModal = ({ isLocked, data }) => {
  const { note } = data
  const textFieldRef = useRef()

  useEffect(() => {
    // setValue(data.note)
    if (isLocked) {
      textFieldRef.current.focus()
    }
  }, [isLocked])

  return (
    <DDITextField
      name="serialNote"
      propertyName={`serialNote.${data.serialNumberId}`}
      initialValue={note}
      disabled={!isLocked && !data.newData}
      multiline
      rows={5}
      inputRef={textFieldRef}
      preventAutoDisable
    />
  )
}

SerialNotesModal.propTypes = {
  isLocked: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired
}

export const contextObj = {
  isLocked: (form, { data }) => {
    const { propertyName, rowIndex } = data
    const isLocked = Boolean(
      getIn(form, `fields.${propertyName}.rowData[${rowIndex}].isLocked`)
    )

    return isLocked
  }
}

export default withContext(contextObj)(SerialNotesModal)
