import React, { useState } from 'react'
import { Icon, IconButton } from '@material-ui/core'
import { withContext } from 'ddiForm'

import { setWarehouse } from 'pages/SalesOrder/actions'
import { SET_WAREHOUSE } from 'pages/SalesOrder/constants'

export const WarehouseCheckboxCell = ({
  data,
  dispatch,
  form,
  gridId,
  isMobile,
  isEditing
}) => {
  const [isChecked, setIsChecked] = useState(data.isSelected)

  const isDisabled =
    (data && data.dataId && data.dataId === '*') || !isEditing || false

  const toggleCheckbox = async () => {
    try {
      await dispatch({
        type: SET_WAREHOUSE.TRY,
        meta: { form, thunk: true },
        payload: {
          propertyName: 'warehouse',
          warehouseId: data.dataId,
          isSelected: data.isSelected,
          gridId
        }
      })
    } catch (e) {
      console.log(e)
    } finally {
      console.log('whatever')
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <IconButton onClick={toggleCheckbox} disabled={isDisabled}>
        <Icon style={{ fontSize: isMobile ? 20 : 16 }}>
          {isChecked ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
      </IconButton>
    </div>
  )
}

export default withContext({
  isEditing: form => form.get('isEditing') || false
})(WarehouseCheckboxCell)
