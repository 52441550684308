import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import { AppBar, Paper, Tabs, Toolbar } from '@material-ui/core'
import { generateTabs, getIn, getMeta, getValue } from 'utils'

import { storeUIFeatureState } from 'pages/SalesOrder/actions'

import ARInfo from './ARInfo'
import ShipToDollarLimit from './ShipToDollarLimit'
import PODollarLimit from './PODollarLimit'
import '../../../styles/master-style.scss'

export const comp = ({
  arBalance,
  orderBalance,
  creditLimitBalance,
  currentAging,
  customerIsOverMaxDaysAllowed,
  dispatch,
  form,
  thru30Aging,
  thru60AndOverAging,
  accountingTab,
  poLimit,
  poSales,
  poBalance,
  showShipToDollarLimit,
  currentSJPeriod,
  shipToBalance,
  shipToLimit,
  shipToSales
}) => {
  const [tab, setTab] = useState(accountingTab)
  const [defaultTabs, setDefaultTabs] = useState([])

  useEffect(() => {
    const newTabs = ['Accounts Receivable', 'P/O Dollar Limit']

    if (showShipToDollarLimit || form === 'invoiceInquiry') {
      newTabs.push('Ship To Dollar Limit')
    }

    setDefaultTabs(generateTabs(newTabs))
  }, [showShipToDollarLimit, form])

  function handleTabChange(e, val) {
    setTab(val)
    /* store the tab state in Redux */
    dispatch(
      storeUIFeatureState(form, {
        feature: 'accountingTab',
        featureState: val
      })
    )
  }

  return (
    <div className="bottom-tabs-wrapper">
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            minHeight: 35,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Tabs
            value={tab}
            onChange={handleTabChange}
            // variant="scrollable"
          >
            {defaultTabs}
          </Tabs>
        </Toolbar>
      </AppBar>

      <Paper square style={{ padding: 15 }}>
        {tab === 'Accounts Receivable' && (
          <ARInfo
            arBalance={arBalance}
            orderBalance={orderBalance}
            creditLimitBalance={creditLimitBalance}
            currentAging={currentAging}
            thru30Aging={thru30Aging}
            thru60AndOverAging={thru60AndOverAging}
            customerIsOverMaxDaysAllowed={customerIsOverMaxDaysAllowed}
          />
        )}

        {tab === 'P/O Dollar Limit' && (
          <PODollarLimit
            poBalance={poBalance}
            poSales={poSales}
            poLimit={poLimit}
          />
        )}

        {tab === 'Ship To Dollar Limit' && (
          <ShipToDollarLimit
            currentSJPeriod={currentSJPeriod}
            shipToBalance={shipToBalance}
            shipToLimit={shipToLimit}
            shipToSales={shipToSales}
          />
        )}
      </Paper>
    </div>
  )
}

export const contextObj = {
  arBalance: form => getValue(form, 'arBalance', null),
  orderBalance: form => getValue(form, 'orderBalance', null),
  creditLimitBalance: form => getValue(form, 'creditLimitBalance', null),
  currentAging: form => getValue(form, 'currentAging', null),
  customerIsOverMaxDaysAllowed: form =>
    getValue(form, 'customerIsOverMaxDaysAllowed', false),
  thru30Aging: form => getValue(form, 'thru30Aging', null),
  thru60AndOverAging: form => getValue(form, 'thru60AndOverAging', null),
  poLimit: form => getValue(form, 'poLimit', null),
  poSales: form => getValue(form, 'poSales', null),
  poBalance: form => getValue(form, 'poBalance', null),
  showShipToDollarLimit: form => getMeta(form, 'showShipToDollarLimit', false),
  currentSJPeriod: form => getValue(form, 'currentSJPeriod', null),
  shipToBalance: form => getValue(form, 'shipToBalance', null),
  shipToLimit: form => getValue(form, 'shipToLimit', null),
  shipToSales: form => getValue(form, 'shipToSales', null),
  accountingTab: form =>
    getIn(form, 'ui.accountingTab') || 'Accounts Receivable'
}

export default withContext(contextObj)(comp)
