import { call, fork, put, select, take } from 'redux-saga/effects'
import { getRecord } from 'ddiForm/MasterScreen/sagas'
import { fromJS, getIn, is } from 'utils'
import { getFormSelector } from 'ddiForm/utils'
import { getEntityAsync } from 'ddiForm/MasterScreen/actions'
import { confirmationModal } from 'modals/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { voidInvoice as voidInvoiceAPI } from '../api'
import { getGroupNames, mapGetRecordResponse } from '../utils'

export function* handleVoidInvoiceProcessListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { modalId }
    } = yield take(CONSTANTS.HANDLE_VOID_PROCESS.TRY)

    if (form === formListener) {
      yield fork(handleVoidInvoiceProcess, form, modalId)
    }
  }
}

export function* handleVoidInvoiceProcess(form, modalId = null) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const groupNames = getGroupNames(selectedPrimaryTab)

  yield call(
    confirmationModal,
    `Are you sure you want to void Invoice - ${dataId}?`,
    'Void?'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return
  }

  yield put(getEntityAsync.request(form))

  const { response, error } = yield call(voidInvoiceAPI, {
    dataId,
    groupNames
  })

  if (response) {
    const payloadData = mapGetRecordResponse({
      response,
      tabNames: groupNames,
      formState,
      groupNames
    })

    yield put(getEntityAsync.success({ ...payloadData }, form))
    if (response.orderId && !modalId) {
      yield put(
        openScreenAction({
          image: 'sales_order_entry_16.png',
          title: 'Sales Order',
          activeKey: '',
          route: 'salesorder',
          screenOpenData: {
            initEditMode: true,
            dataId: response.orderId
          }
        })
      )
    }
  } else {
    yield put(actions.handleVoidProcess.failure(error, form))
  }
}

export default function* secondaryHandlers(form) {
  yield fork(handleVoidInvoiceProcessListener, form)
}
