import React, { useState } from 'react'
import { InputAdornment, IconButton, Icon, TextField } from '@material-ui/core'
import { errorRed } from 'theme/colors'
import ActionButtons from './components/ActionButtons'

import '../../../styles/modal-flex-button-styles.scss'

const CustomAssemblySaveAsModal = props => {
  const {
    data: { form, lineNumber, displayName = 'Save Product As', maxLength },
    id
  } = props

  const [newId, setId] = useState('')

  console.log('CustomAssemblySaveAsModal', props)
  const onChange = e => {
    const { value } = e.target
    setId(value)
  }

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div className="sales-order-entry-modal-primary-interface-wrapper">
        <div className="multiline-textarea-wrapper" style={{ width: '100%' }}>
          <TextField
            value={newId}
            label={displayName}
            onChange={onChange}
            rows={4}
            fullWidth
            InputProps={{
              inputProps: {
                maxLength
              }
            }}
          />
          <span
            style={{
              fontSize: '0.875em',
              fontStyle: 'italic',
              display: 'inline-block',
              marginTop: 3
            }}
          >
            (blank for system generated)
          </span>
        </div>
      </div>
      <ActionButtons
        form={form}
        modalId={id}
        lineNumber={lineNumber}
        newId={newId}
      />
    </div>
  )
}

export default CustomAssemblySaveAsModal
