import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import memoize from 'memoize-one'
import { getRowNodeId, setGridHeight } from 'pages/ProductMaster/utils'
import { handlePurchaseHistoryLink } from 'mobile/pages/ProductAnalysis/actions'

import OpenScreenLinkCell from './OpenScreenLinkCell'

const mobileDataIdCell = ({ value, data, form, colDef, ...other }) => {
  console.log('mobileDataIdCell', data, value, form, other)
  const modalId = colDef?.cellRendererParams?.modalId || null
  const dispatch = useDispatch()

  const handlePurchaseHistoryLinkClick = e => {
    if (modalId && value && form) {
      dispatch(
        handlePurchaseHistoryLink(form, {
          dataId: value,
          description: data?.description,
          modalId
        })
      )
    }
  }

  return (
    <button
      type="button"
      onClick={handlePurchaseHistoryLinkClick}
      style={{ color: '#517b9c', padding: 0, margin: 0 }}
    >
      {value}
    </button>
  )
}

export const dynamicColumnDefs = memoize(({ form, isMobile, modalId }) => {
  const colDefs = [
    {
      cellRendererFramework: isMobile ? mobileDataIdCell : OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true,
        modalId
      },
      field: 'dataId',
      headerName: 'Product',
      linkTo: 'productMaster',
      // for now, only use case is in analysis
      startTab: 'analysis',
      groupNames: ['analysis', 'overview'],
      filter: false,
      suppressMenu: true
    },
    {
      field: 'description',
      headerName: 'Description',
      filter: false,
      suppressMenu: true
    }
  ]

  return colDefs
})

export const PurchaseHistoryGrid = memo(({ data, id }) => {
  return (
    <div style={{ flex: '1 1', overflow: 'hidden' }}>
      <div style={{ height: '26%' }}>
        <Grid
          rowData={data.links}
          getRowNodeId={getRowNodeId}
          columnDefs={dynamicColumnDefs({
            form: data.form,
            isMobile: data.isMobile || false,
            modalId: id
          })}
          rowSelection="multiple"
          height={setGridHeight(data.links)}
          suppressRowClickSelection
          enableSorting
        />
      </div>
    </div>
  )
})

PurchaseHistoryGrid.propTypes = {
  data: PropTypes.object.isRequired
}

export default PurchaseHistoryGrid
