import React from 'react'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import TooltipField from 'hoc/TooltipField'
import GridLinkCell from 'pages/CustomerMaster/components/GridLinkCell'

export const SalesOrderTooltipCell = props => <GridLinkCell {...props} />

export default TooltipField({
  anchorToGridCellText: true,
  keyToActionMap: {
    m: () =>
      openScreenAction({
        name: 'purchaseOrder',
        title: 'Purchase Order',
        image: 'purchasing_purchase_order_16.png'
      }),
    1: () =>
      openScreenAction({
        image: 'customer_master_16.png',
        name: 'customerMaster',
        title: 'Customer Master'
      }),
    2: () =>
      openScreenAction({
        name: 'shipTo',
        title: 'Ship To'
      }),
    3: () =>
      openScreenAction({
        image: 'contact_master_16.png',
        name: 'contact',
        title: 'Contact Master'
      })
  },
  position: 'right-end',
  recordNameKey: 'value',
  sensitivity: 100,
  type: 'salesOrder'
})(SalesOrderTooltipCell)
