/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React from 'react'
import { Field, withContext } from 'ddiForm'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import RenderRadioGroup from 'components/RenderRadioGroup'
import IndexSearch from 'components/Search/IndexSearch'
import { makeRadioButtons, styles } from 'pages/CustomerOrderPad/utils'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import { getMeta, getField, emptyList } from 'utils'

const inputWrapperStyle = {
  alignItems: 'center',
  display: 'flex',
  marginBottom: 15
}

const GroupBy = ({ defaultGroupBy, groupBy, groupByOptionsList }) => {
  const groupByOptions =
    groupByOptionsList && groupByOptionsList.toJS
      ? groupByOptionsList.toJS()
      : []

  return (
    <DDIExpansionPanel
      title="Group By"
      expansionPanelContentStyle={{ width: '100%' }}
    >
      <div className="clear">
        <Field
          component={RenderRadioGroup}
          propertyName="groupBy"
          defaultSelected={defaultGroupBy}
          initialValue={defaultGroupBy}
          renderAsRow
        >
          {makeRadioButtons(groupByOptions, 'groupBy')}
        </Field>
      </div>
      <div className="clear">
        {groupBy && groupBy === 'P' ? (
          <div style={inputWrapperStyle}>
            <label
              htmlFor="productLine"
              style={{ ...styles.label, width: 120 }}
            >
              Product Line:
            </label>
            <Field
              propertyName="productLine"
              metaKey="Product Line"
              component={IndexSearch}
              searchType={
                INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.PRODUCT_LINE
              }
              displayDescription
              descriptionKey="description"
            />
          </div>
        ) : null}
        {groupBy && groupBy === 'V' ? (
          <div style={inputWrapperStyle}>
            <label htmlFor="vendor" style={{ ...styles.label, width: 120 }}>
              Primary Vendor:
            </label>
            <Field
              propertyName="vendor"
              metaKey="Primary Vendor"
              component={IndexSearch}
              searchType={INDEX_SEARCH_CONSTANTS.INDEX_SEARCH_TYPES.VENDOR}
              displayDescription
              descriptionKey="name"
            />
          </div>
        ) : null}
      </div>
    </DDIExpansionPanel>
  )
}

// export default withContext({
//   defaultGroupBy: form => form?.meta?.defaultGroupBy || 'V',
//   groupBy: form => form?.fields?.groupBy?.value || '',
//   groupByOptions: form => form?.meta?.groupByOptions || []
// })(GroupBy)

export default withContext({
  defaultGroupBy: form => getMeta(form, 'defaultGroupBy', 'V'),
  groupBy: form => getField(form, 'groupBy', ''),
  groupByOptionsList: form => getMeta(form, 'groupByOptions', emptyList)
})(GroupBy)
