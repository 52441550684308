import React, { useState, useEffect } from 'react'
import { DDIButton } from 'ddiForm/wrapped'
import { confirm } from 'modals/actions'
import { is } from 'utils'
import { Switch as Toggle, FormControlLabel } from '@material-ui/core'

import { deleteComment, setComment } from 'pages/SalesOrder/actions'

export const CommentsModalActions = ({ data, dispatch, form, modal }) => {
  const [store, setStore] = useState(modal.options.data.storePermanently)
  const [uniqueKey, setUniqueKey] = useState(null)
  const [commentName, setCommentName] = useState('')
  const [showStorePermanently, setShowStorePermanently] = useState(false)

  useEffect(() => {
    if (modal?.options?.data) {
      if (modal.options.data.uniqueKey) {
        setUniqueKey(modal.options.data.uniqueKey)
      }

      if (modal.options.data.commentName) {
        setCommentName(modal.options.data.commentName)
      }

      if (is.bool(modal.options.data.showStorePermanently)) {
        setShowStorePermanently(modal.options.data.showStorePermanently)
      }
    }
  }, [modal])

  const marginRight = {
    marginRight: 5
  }

  function handleChange(e) {
    const { checked } = e.target
    setStore(checked)
  }

  function getToggle() {
    return <Toggle checked={store} id={modal.id} onChange={handleChange} />
  }

  function handleExit() {
    dispatch(confirm(form, modal.id))
  }

  function handleSave() {
    dispatch(
      setComment.try(form, {
        uniqueKey,
        commentName,
        storePermanently: store,
        modalId: modal.id
      })
    )
  }

  function handleDelete() {
    dispatch(
      deleteComment.try(form, {
        uniqueKey,
        commentName,
        storePermanently: store,
        comment: null,
        modalId: modal.id
      })
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        width: '100%'
      }}
    >
      {showStorePermanently && (
        <div>
          <FormControlLabel
            control={getToggle()}
            label="Store Permanently"
            labelPlacement="start"
            style={{ marginRight: 'auto' }}
          />
        </div>
      )}
      <div>
        <DDIButton
          onClick={handleSave}
          variant="contained"
          label="Save"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
        <DDIButton
          onClick={handleDelete}
          variant="contained"
          label="Delete"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
        <DDIButton
          onClick={handleExit}
          variant="contained"
          label="Exit"
          tabIndex={0}
          preventAutoDisable
          style={marginRight}
        />
      </div>
    </div>
  )
}

export default CommentsModalActions
