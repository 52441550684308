import React, { useEffect, useState } from 'react'
import memoize from 'memoize-one'
import shortid from 'shortid'
import { withContext } from 'ddiForm'
import { formatDollarFields, getIn, getValue, emptyList } from 'utils'
import usePrevious from 'hooks/usePrevious'
import Grid from 'grid'
import { Paper, Link, Icon } from '@material-ui/core'
import { informBlue } from 'theme/colors'

import TrackingNumberCellRenderer from './TrackingNumberCellRenderer'
import DeleteTrackingCell from './DeleteTrackingCell'

// charge: 75
// count: 1
// dataId: "123545155122515"
// serviceType: "2"
// type: "AIR"
// weight: 10

let gridApi = null
const TrackingInformation = ({
  carrierUrl,
  form,
  dispatch,
  isEditing,
  rowDataList,
  shipmentTrackedTotalPackages,
  totalCharge,
  totalWeight,
  isMobile = false,
  isTablet = false,
  isActiveMobileTab = false
}) => {
  const rowData = rowDataList?.toJS ? rowDataList.toJS() : []
  const previousCarrierUrl = usePrevious(carrierUrl)
  const [gridKey, resetGridKey] = useState(shortid.generate())

  useEffect(() => {
    if (carrierUrl && carrierUrl !== previousCarrierUrl && rowData?.length) {
      resetGridKey(shortid.generate())
    }
  }, [carrierUrl, previousCarrierUrl])

  const pinnedBottomRowData = [
    {
      dataId: '',
      count: shipmentTrackedTotalPackages,
      weight: totalWeight,
      type: '',
      serviceType: '',
      charge: totalCharge
    }
  ]

  const getColumnDefs = memoize((editing, carrier) => [
    {
      field: 'dataId',
      headerName: 'Tracking Information',
      cellRenderer: TrackingNumberCellRenderer,
      cellRendererParams: {
        carrierUrl: carrier
      },
      minWidth: isMobile ? 150 : 100
    },
    {
      cellClass: 'align-right',
      field: 'count',
      headerClass: 'align-right',
      headerName: 'Pckgs'
    },
    {
      cellClass: 'align-right',
      field: 'weight',
      headerClass: 'align-right',
      headerName: 'Weight'
    },
    {
      field: 'type',
      headerName: 'Type'
    },
    {
      field: 'serviceType',
      headerName: 'Service'
    },
    {
      cellClass: 'align-right',
      field: 'charge',
      headerClass: 'align-right',
      headerName: 'Charge',
      valueFormatter: formatDollarFields
    },
    {
      field: 'dataId',
      hide: !editing,
      headerComponentFramework: DeleteTrackingCell,
      headerComponenentParams: {
        form,
        dispatch
      },
      cellRendererFramework: DeleteTrackingCell,
      cellRendererParams: {
        form,
        dispatch
      },
      maxWidth: 50
    }
  ])

  useEffect(() => {
    if (isMobile && gridApi && isActiveMobileTab) {
      gridApi.sizeColumnsToFit()
    }
  }, [isActiveMobileTab])

  // const rowData = [
  //   { dataId: 44412352351234563, packages: 2, weight: 5, type: 2, service: 'OVR', charge: 5.03 }
  // ]

  const gridHeight = rowData && rowData.length ? rowData.length * 29 + 64 : 100

  const onGridReady = params => {
    if (params && params.api) {
      gridApi = params.api
      params.api.sizeColumnsToFit()
    }
  }

  return (
    <div style={{ width: '100%', padding: isMobile ? 0 : '0 5px' }}>
      <div
        style={{
          marginBottom: isMobile ? 0 : 10,
          width: isMobile && !isTablet ? 800 : '100%'
        }}
      >
        <Grid
          form={form}
          getRowNodeId={data => data.dataId}
          columnDefs={getColumnDefs(isEditing, carrierUrl)}
          rowData={rowData}
          height={gridHeight}
          width="100%"
          pinnedBottomRowData={pinnedBottomRowData}
          suppressHorizontalScroll
          key={gridKey}
          onGridReady={onGridReady}
        />
      </div>
      <Paper
        style={{
          display: 'flex',
          margin: isMobile ? 10 : '0 0 10px 0',
          padding: 5,
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%'
        }}
      >
        <p
          style={{
            color: '#444',
            fontSize: '0.9em',
            margin: 0,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <span
            style={{ fontWeight: 400, whiteSpace: 'nowrap', marginRight: 5 }}
          >
            Carrier URL:
          </span>
          {carrierUrl ? (
            <Link
              href={carrierUrl}
              target="_blank"
              rel="noopener"
              style={{
                color: informBlue,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isMobile ? (
                <Icon
                  size="small"
                  style={{ color: informBlue, marginRight: 5 }}
                >
                  open_in_new
                </Icon>
              ) : null}
              {carrierUrl}
            </Link>
          ) : null}
        </p>
      </Paper>
    </div>
  )
}

/* updated 9/25/2020 SVE */
export default withContext({
  carrierUrl: form => getValue(form, 'carrierUrl', ''),
  isEditing: form => form.get('isEditing') || false,
  rowDataList: form =>
    getIn(form, 'values.shipmentTrackedPackages') || emptyList,
  shipmentTrackedTotalPackages: form =>
    getValue(form, 'shipmentTrackedTotalPackages', ''),
  totalCharge: form => getValue(form, 'totalCharge', ''),
  totalWeight: form => getValue(form, 'totalWeight', '')
})(TrackingInformation)
