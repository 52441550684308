import moment from 'moment'
import { getValue, getField } from 'utils'

export const getErrorMessages = errors =>
  errors.reduce((acc, next) => {
    if (next.message) {
      acc = acc.concat(`${next.message} \n`)
    }
    return acc
  }, '')

export const requiredDate = (value, _) => {
  return moment(value).isValid() ? undefined : 'Date is required'
}
export const validateAccountingPeriod = (value, _) => {
  const dateFormat = 'MMYY'
  return moment(value, dateFormat, true).isValid()
    ? undefined
    : 'You must specify an accounting period'
}

export const validateStartDate = (value, formState) => {
  let endDate = getValue(formState, 'endDate') || getField(formState, 'endDate')

  if (endDate !== undefined) {
    endDate = new Date(endDate)
    const newDate = new Date(value)
    return endDate < newDate ? 'Start Date cannot be after End Date' : undefined
  }

  return undefined
}

export const validateStartReceivingDate = (value, formState) => {
  let endDate =
    getValue(formState, 'endReceivingDate') ||
    getField(formState, 'endReceivingDate')

  if (endDate !== undefined) {
    endDate = new Date(endDate)
    const newDate = new Date(value)
    return endDate < newDate ? 'Start Date cannot be after End Date' : undefined
  }

  return undefined
}
