import { createAsyncRequestTypes } from 'utils'

const SELECTION_CRITERIA = '@@SELECTION_CRITERIA@@/'

/* get print labels criteria */
export const GET_LABEL_PRINTING_CRITERIA = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}GET_LABEL_PRINTING_CRITERIA`
)

export const TOGGLE_CRITERIA_SELECTION = `${SELECTION_CRITERIA}TOGGLE_CRITERIA_SELECTION`
export const TOGGLE_EQUAL_TO_CRITERIA = `${SELECTION_CRITERIA}TOGGLE_EQUAL_TO_CRITERIA`
export const RESET_CRITERIA_SELECTION = `${SELECTION_CRITERIA}RESET_CRITERIA_SELECTION`
export const CLEAR_CRITERIA_SELECTION = `${SELECTION_CRITERIA}CLEAR_CRITERIA_SELECTION`

export const RESET_FIELD_SELECTION = `${SELECTION_CRITERIA}RESET_FIELD_SELECTION`

export const LAUNCH_FIELD_SELECTION_MODAL = `${SELECTION_CRITERIA}LAUNCH_FIELD_SELECTION_MODAL`
export const DELETE_PRINT_ORDER_ITEM = `${SELECTION_CRITERIA}DELETE_PRINT_ORDER_ITEM`

export const UPDATE_PRINT_ORDER = `${SELECTION_CRITERIA}UPDATE_PRINT_ORDER`

export const GET_LABEL_PRINTING_DEFAULTS = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}GET_LABEL_PRINTING_DEFAULTS`
)

export const RUN_PRINT_LABELS = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}RUN_PRINT_LABELS`
)

export const EXIT_PRINT_LABELS = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}EXIT_PRINT_LABELS`
)

export const LAUNCH_LABEL_PARAMS_MODAL = `${SELECTION_CRITERIA}LAUNCH_LABEL_PARAMS_MODAL`
export const CLOSE_PARENT_MODAL = `${SELECTION_CRITERIA}CLOSE_PARENT_MODAL`

/* get customer report criteria */
export const GET_REPORT_CRITERIA = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}GET_REPORT_CRITERIA`
)

export const SET_REPORT_CRITERIA = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}SET_REPORT_CRITERIA`
)

export const CANCEL_REPORT = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}CANCEL_REPORT`
)

export const VIEW_REPORT = createAsyncRequestTypes(
  `${SELECTION_CRITERIA}VIEW_REPORT`
)

export const CLEAR_SELECTION_CRITERIA_FIELDS = `${SELECTION_CRITERIA}CLEAR_SELECTION_CRITERIA_FIELDS`
export const DELETE_ONLY_GRID_ROW = `${SELECTION_CRITERIA}DELETE_ONLY_GRID_ROW`
export const SET_INITIAL_ROW_DATA = `${SELECTION_CRITERIA}SET_INITIAL_ROW_DATA`
