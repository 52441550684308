import React, { memo, useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
// import { DDIIndexSearch, DDISelectField } from 'ddiForm/wrapped'
import {
  AppBar,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from '@material-ui/core'
import { generateTabs, getField, getValue, getIn, emptyList } from 'utils'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'

import PaymentMethods from './PaymentMethods'
import GrossProfit from './GrossProfit'
import WeightVolume from './WeightVolume'
import Signature from './Signature'
import PaymentsDeposits from './PaymentsDeposits'
import OrderTotals from './OrderTotals'
import Shipping from './Shipping'
import Allowance from './Allowance'

const CheckoutTabs = ({
  form,
  dispatch,
  height,
  invoiceDetailsTab,
  quote,
  isSuspended,
  paymentsDepositsList,
  dataId
}) => {
  const paymentsDeposits = paymentsDepositsList?.toJS
    ? paymentsDepositsList.toJS()
    : []
  const [tab, setTab] = useState(quote ? 'Order Totals' : invoiceDetailsTab)

  const setActiveTab = tabName => {
    setTab(tabName)
    /* store the tab state in Redux */
    dispatch(
      storeUIFeatureState(form, {
        feature: 'invoiceDetailsTab',
        featureState: tabName
      })
    )
  }

  useEffect(() => {
    if (quote && tab === 'Payment Methods') {
      setActiveTab('Order Totals')
    }
  }, [quote])

  useEffect(() => {
    /* 
      if the user happens to be looking at Payments & Deposits
      when they change the order number
    */
    if (tab === 'Payments/Deposits') {
      if (quote) {
        setActiveTab('Order Totals')
      } else {
        setActiveTab('Payment Methods')
      }
    }
  }, [dataId])

  function handleTabChange(e, val) {
    if (val) {
      setActiveTab(val)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            minHeight: 35,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Tabs value={tab} onChange={handleTabChange} scrollable>
            <Tab value="Order Totals" label="Order Totals" />
            {!quote ? (
              <Tab value="Payment Methods" label="Payment Methods" />
            ) : null}
            <Tab value="Weight/Volume" label="Weight/Volume" />
            {paymentsDeposits && paymentsDeposits.length ? (
              <Tab value="Payments/Deposits" label="Payments/Deposits" />
            ) : null}
            <Tab value="Shipping" label="Shipping" />
            <Tab value="Signature" label="Signature" />
            <Tab value="G/P" label="G/P" />
            <Tab value="Allowance" label="Allowance" />
          </Tabs>
        </Toolbar>
      </AppBar>
      <Paper style={{ background: '#fff' }} square>
        <div style={{ padding: tab === 'Shipping' ? 0 : 10 }}>
          {tab === 'Order Totals' && (
            <OrderTotals dispatch={dispatch} form={form} />
          )}
          {tab === 'Payment Methods' && (
            <PaymentMethods
              dispatch={dispatch}
              form={form}
              height={height}
              isSuspended={isSuspended}
            />
          )}
          {tab === 'Weight/Volume' && <WeightVolume />}
          {tab === 'Payments/Deposits' && (
            <PaymentsDeposits
              dispatch={dispatch}
              form={form}
              paymentsDeposits={paymentsDeposits}
            />
          )}
          {/* {tab === 'Payments' && <p>Payments</p>} */}
          {tab === 'Shipping' && <Shipping form={form} />}
          {tab === 'Signature' && <Signature form={form} />}
          {tab === 'G/P' && <GrossProfit />}
          {tab === 'Allowance' && <Allowance />}
        </div>
      </Paper>
    </div>
  )
}

const contextObj = {
  canAddPayment: form => getValue(form, 'canAddPayment', false),
  invoiceDetailsTab: form =>
    getIn(form, 'ui.invoiceDetailsTab') || 'Order Totals',
  quote: form => getField(form, 'quote', false),
  isSuspended: form => getValue(form, 'isSuspended', false),
  paymentsDepositsList: form => getValue(form, 'paymentsDeposits', emptyList),
  dataId: form => getField(form, 'dataId', '')
}

export default memo(withContext(contextObj)(CheckoutTabs))
