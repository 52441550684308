import React, { useEffect, useState } from 'react'
import { withContext, GridField } from 'ddiForm'
import { Icon, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import shortid from 'shortid'
import memoize from 'memoize-one'
import { useDispatch } from 'react-redux'
import withDimensions from 'hoc/withDimensions'
import { getIn, emptyList, plainDeepEqual, is } from 'utils'
import { headerStyle } from 'pages/VendorMaster/utils'
import usePrevious from 'hooks/usePrevious'
import ErrorBoundary from 'components/ErrorBoundary'
import { informBlue } from 'theme/colors'
import {
  handleRowDataUpdate,
  removeEmptyGridRow
} from 'pages/VendorMaster/actions'
import DeleteGridCell from './components/grids/components/DeleteGridCell'
import PatternsTooltipTable from './components/PatternsTooltipTable'

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}))

const emptyRow = {
  rowId: 'blankrow',
  dataId: null
}

let patternsGridApi = null
let serialNumbersGridApi = null
let _isMounted = false

const handleGridUpdate = (rowData, prevRowData, gridApi, isMounted) => {
  if (
    rowData &&
    prevRowData &&
    Array.isArray(rowData) &&
    Array.isArray(prevRowData) &&
    !plainDeepEqual(rowData, prevRowData) &&
    gridApi &&
    isMounted
  ) {
    gridApi.setRowData(rowData)
    gridApi.refreshCells({
      force: true,
      suppressFlash: true
    })
    gridApi.clearFocusedCell()
    gridApi.stopEditing()

    if (rowData.length > prevRowData.length) {
      gridApi.setFocusedCell(rowData.length - 1, 'dataId')
    }
  }
}

const Patterns = ({
  form,
  height,
  isEditing,
  productPatternsList,
  serialNumberPatternsList,
  isNewProductBasedOnVendor,
  patternKeyMap
}) => {
  const dispatch = useDispatch()
  const productPatterns = productPatternsList?.toJS
    ? productPatternsList.toJS()
    : []
  const prevProductPatterns = usePrevious(productPatterns)
  const serialNumberPatterns = serialNumberPatternsList?.toJS
    ? serialNumberPatternsList.toJS()
    : []
  const prevSerialNumberPatterns = usePrevious(serialNumberPatterns)

  const prevIsEditing = usePrevious(isEditing)
  const [patternsGridKey, resetPatternsGridKey] = useState(shortid.generate())
  const [serialNumbersGridKey, resetSerialNumbersGridKey] = useState(
    shortid.generate()
  )

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (
      is.bool(isEditing) &&
      is.bool(prevIsEditing) &&
      isEditing !== prevIsEditing
    ) {
      resetPatternsGridKey(shortid.generate())
      resetSerialNumbersGridKey(shortid.generate())

      if (_isMounted && patternsGridApi && serialNumbersGridApi && !isEditing) {
        patternsGridApi.clearFocusedCell()
        patternsGridApi.stopEditing()

        serialNumbersGridApi.clearFocusedCell()
        serialNumbersGridApi.stopEditing()
      }
    }
  }, [isEditing, prevIsEditing])

  useEffect(() => {
    _isMounted = true
    return () => {
      dispatch(removeEmptyGridRow(form, { propertyName: 'productPatterns' }))
      dispatch(
        removeEmptyGridRow(form, { propertyName: 'serialNumberPatterns' })
      )
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    handleGridUpdate(
      productPatterns,
      prevProductPatterns,
      patternsGridApi,
      _isMounted
    )
  }, [productPatterns, prevProductPatterns])

  useEffect(() => {
    handleGridUpdate(
      serialNumberPatterns,
      prevSerialNumberPatterns,
      serialNumbersGridApi,
      _isMounted
    )
  }, [serialNumberPatterns, prevSerialNumberPatterns])

  const onCellValueChanged = params => {
    if (params.newValue === params.oldValue || !_isMounted || !isEditing) {
      return
    }

    dispatch(
      handleRowDataUpdate(form, {
        propertyName: params?.colDef?.cellEditorParams?.propertyName,
        rowIndex: params.node.rowIndex,
        rowId: params?.data?.rowId,
        field: params?.colDef?.field,
        value: params.newValue,
        requiredCols: ['dataId'],
        emptyRow,
        addNewRowOnChange: true
      })
    )

    if (params?.api?.tabToNextCell) {
      params.api.tabToNextCell()
    }
  }

  const getColumnDefs = memoize((propertyName, editing) => [
    {
      colId: 'dataId',
      field: 'dataId',
      headerName: 'Pattern',
      cellEditorParams: {
        propertyName
      },
      editable: params => {
        if (!editing) {
          return false
        }

        return true
      },
      onCellValueChanged
    },
    {
      colId: 'delete',
      field: '',
      cellRendererFramework: DeleteGridCell,
      cellRendererParams: {
        propertyName,
        form,
        isEditing
      },
      editable: false,
      suppressNavigable: true,
      maxWidth: 40
    }
  ])

  const onPatternsGridReady = params => {
    patternsGridApi = params.api
  }

  const onSerialNumbersGridReady = params => {
    serialNumbersGridApi = params.api
  }

  const onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column && params.column.colId
      })
    }
  }

  return (
    <>
      <Popover
        id="patterns-tooltip"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PatternsTooltipTable form={form} patternKeyMap={patternKeyMap} />
      </Popover>
      <div
        className={
          isNewProductBasedOnVendor
            ? 'vendor-grid-layout-twocol'
            : 'vendor-grid-layout-twocol includes-hidden-grid'
        }
      >
        {isNewProductBasedOnVendor ? (
          <div>
            <div
              aria-owns={open ? 'patterns-tooltip' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              style={{
                ...headerStyle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Icon size="small" style={{ marginRight: 5, color: informBlue }}>
                info
              </Icon>
              <span>Product Number Patterns</span>
            </div>
            <ErrorBoundary>
              <GridField
                title=""
                form={form}
                height={height - 30}
                propertyName="productPatterns"
                getRowNodeId={data => data.rowId}
                columnDefs={getColumnDefs('productPatterns', isEditing)}
                onGridReady={onPatternsGridReady}
                onCellFocused={onCellFocused}
                emptyRow={emptyRow}
                addButtonText="Add Matching Pattern"
                showAddButtonOnlyIfEditing
                key={patternsGridKey}
              />
            </ErrorBoundary>
          </div>
        ) : null}
        <div>
          <div
            aria-owns={open ? 'patterns-tooltip' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            style={{
              ...headerStyle,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Icon size="small" style={{ marginRight: 5, color: informBlue }}>
              info
            </Icon>
            <span>Serial Number Patterns</span>
          </div>
          <ErrorBoundary>
            <GridField
              title=""
              headerStyle={headerStyle}
              form={form}
              height={height - 30}
              propertyName="serialNumberPatterns"
              getRowNodeId={data => data.rowId}
              columnDefs={getColumnDefs('serialNumberPatterns', isEditing)}
              onGridReady={onSerialNumbersGridReady}
              onCellFocused={onCellFocused}
              emptyRow={emptyRow}
              addButtonText="Add Matching Pattern"
              showAddButtonOnlyIfEditing
              key={serialNumbersGridKey}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
}

const PatternsWithContext = withContext({
  patternKeyMap: form => getIn(form, 'meta.patternKey') || emptyList,
  productPatternsList: form =>
    getIn(form, 'fields.productPatterns.rowData') || emptyList,
  serialNumberPatternsList: form =>
    getIn(form, 'fields.serialNumberPatterns.rowData') || emptyList,
  isEditing: form => form.get('isEditing') || false,
  isNewProductBasedOnVendor: form =>
    getIn(form, 'meta.isNewProductBasedOnVendor') || false
})(Patterns)

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%',
  minHeight: 400
})(PatternsWithContext)
