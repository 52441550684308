import React from 'react'
import PropTypes from 'prop-types'
import { DDIThreeWayToggle, DDIToggle } from 'ddiForm/wrapped'

import DDICardWrapper from 'components/DDICardWrapper'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'

const Settings = ({ pageStyles }) => (
  <DDICardWrapper contentStyle={{ padding: 10 }} title="Settings">
    <AssociatedFieldsWrapper>
      {/* <DDIThreeWayToggle
        propertyName="suppressHeader"
        label="Suppress Company Header"
      /> */}
      <DDIThreeWayToggle
        propertyName="suppressHeader"
        label="Suppress Company Header"
      />
    </AssociatedFieldsWrapper>
    <AssociatedFieldsWrapper>
      <DDIToggle
        propertyName="printAsRemitTo"
        label="Print Ship To as Remit To on Docs"
      />
    </AssociatedFieldsWrapper>
    <AssociatedFieldsWrapper>
      <DDIToggle propertyName="excludeDemand" label="Exclude Demand" />
    </AssociatedFieldsWrapper>
  </DDICardWrapper>
)

Settings.propTypes = {
  pageStyles: PropTypes.object.isRequired
}

export default Settings
