import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'majorBuyLine'

export const updateMajorBuyLine = ({ dataId, properties }) => {
  const options = createApiOptions({
    body: { dataId, properties },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/update`, options)
}

export const deleteMajorBuyLine = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/delete`, options)
}

export const createMajorBuyLine = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/create`, options)
}

export const closeMajorBuyLine = () => {
  const options = createApiOptions({
    body: {},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/close`, options)
}
