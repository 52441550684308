import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'

import { deleteItemGroup, changeItemGroups } from 'pages/SalesOrder/actions'

export class DeleteItemGroupCell extends Component {

  deleteItemGroup = () => {
    const {
      form,
      data: {
        dataId,
        lineNumber
      },
      dispatch,
      type = 'group',
    } = this.props

    if (type === 'product') {
      dispatch(changeItemGroups.try(form, {
        type: 'remove',
        lineNumbers: [lineNumber],
        itemGroup: {}
      }))
    } else if (type === 'itemGroups' && dataId) {
      dispatch(deleteItemGroup.try(form, { lineNumber }))
    }
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon
          color="error"
          style={{
            display: 'inline-block',
            fontSize: 18,
            marginLeft: -5
          }}
          onClick={this.deleteItemGroup}
        >
          cancel
        </Icon>
      </div>
    )
  }
}

export default DeleteItemGroupCell
