/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'utils'
import { DDINumberSpinner, DDITextField } from 'ddiForm/wrapped'

const styles = {
  flexInputs: {
    label: { fontWeight: 400, marginBottom: 0, marginRight: 15, order: 1 },
    input: { order: 2 },
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 15,
      width: '100%'
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { dataId, form } = ownProps
  const fields = `ddiForm.${form}.fields.selectionCriteria.Labels.printLabels`

  return {
    lengthOfEachLabelChar: getIn(fields, 'lengthOfEachLabelChar.value'),
    numberOfLabelsAcross: getIn(fields, 'numberOfLabelsAcross.value'),
    numberOfLabelsToSample: getIn(fields, 'numberOfLabelsToSample.value'),
    numberOfLinesToSkip: getIn(fields, 'numberOfLinesToSkip.value'),
    numberOfSpacesBetweenLabels: getIn(
      fields,
      'numberOfSpacesBetweenLabels.value'
    )
  }
}

class PrintLabelsModal extends Component {
  static propTypes = {
    lengthOfEachLabelChar: PropTypes.number.isRequired,
    numberOfLabelsAcross: PropTypes.number.isRequired,
    numberOfLabelsToSample: PropTypes.number.isRequired,
    numberOfLinesToSkip: PropTypes.number.isRequired,
    numberOfSpacesBetweenLabels: PropTypes.number.isRequired
  }

  render() {
    const {
      lengthOfEachLabelChar,
      numberOfLabelsAcross,
      numberOfLabelsToSample,
      numberOfLinesToSkip,
      numberOfSpacesBetweenLabels
    } = this.props

    return (
      <div>
        <div style={styles.flexInputs.wrapper}>
          <label style={styles.flexInputs.label}>
            Number of labels across:
          </label>
          <div style={styles.flexInputs.input}>
            <DDINumberSpinner
              preventAutoDisable
              propertyName="selectionCriteria.Labels.printLabels.numberOfLabelsAcross"
              type="number"
            />
          </div>
        </div>
        <div style={styles.flexInputs.wrapper}>
          <label style={styles.flexInputs.label}>
            Number of lines to skip:
          </label>
          <div style={styles.flexInputs.input}>
            <DDINumberSpinner
              preventAutoDisable
              propertyName="selectionCriteria.Labels.printLabels.numberOfLinesToSkip"
              type="number"
            />
          </div>
        </div>
        <div style={styles.flexInputs.wrapper}>
          <label style={styles.flexInputs.label}>
            Length of each label (Char):
          </label>
          <div style={styles.flexInputs.input}>
            <DDINumberSpinner
              preventAutoDisable
              propertyName="selectionCriteria.Labels.printLabels.lengthOfEachLabelChar"
              type="number"
            />
          </div>
        </div>
        <div style={styles.flexInputs.wrapper}>
          <label style={styles.flexInputs.label}>
            Number of spaces between labels:
          </label>
          <div style={styles.flexInputs.input}>
            <DDINumberSpinner
              preventAutoDisable
              propertyName="selectionCriteria.Labels.printLabels.numberOfSpacesBetweenLabels"
              type="number"
            />
          </div>
        </div>
        <div style={styles.flexInputs.wrapper}>
          <label style={styles.flexInputs.label}>
            Number of labels to sample:
          </label>
          <div style={styles.flexInputs.input}>
            <DDINumberSpinner
              preventAutoDisable
              propertyName="selectionCriteria.Labels.printLabels.numberOfLabelsToSample"
              type="number"
            />
          </div>
        </div>

        <div style={{ width: '100%' }}>
          <label style={{ color: '#444', marginBottom: 10 }}>
            Selection Criteria
          </label>
          <DDITextField
            disabled
            fullWidth
            multiline
            propertyName="selectionCriteria.Labels.printLabels.headerCriteria"
            rows={4}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(PrintLabelsModal)
