import React, { useState } from 'react'
import Attachments from 'components/MasterScreen/Attachments'

const AttachmentsModal = ({ data }) => {
  const attachments = data?.attachments || []
  const form = data?.form
  const targetType = data?.targetType
  const [rowData, setRowData] = useState(attachments)

  const handleAttachments = (response = []) => setRowData(response)

  return (
    <div style={{ paddingBottom: 30 }}>
      <Attachments
        form={form}
        targetType={targetType}
        isStandaloneComponent
        rowData={rowData}
        additionalAttachmentsData={{
          existingRowData: rowData,
          handleAttachmentsCallback: handleAttachments,
          modalData: {
            ...data
          }
        }}
      />
    </div>
  )
}

export default AttachmentsModal
