import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DDITextField, DDISelectField, DDIToggle } from 'ddiForm/wrapped'
import DDIIndexSearch from 'mobile/ddiForm/wrapped/ddiIndexSearchMobile'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import { Paper, FormControlLabel } from '@material-ui/core'
import { required, maxLength } from 'ddiForm/validations'
import { getIn } from 'utils'
import { states } from 'mobile/pages/Contacts/utils'
import { styles } from '../utils'
import '../styles/css-mod-ignore.scss'

const { subtitleHeadingStyle } = styles

const inputWrapperStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap'
}

const inputInnerWrapperStyle = { margin: '0 10px 15px 10px', width: 175 }

const mapStateToProps = (state, ownProps) => {
  const {
    data: { form }
  } = ownProps

  const canChangeSave =
    getIn(state, `ddiForm.${form}.values.manualShipTo.canChangeSave`) || false
  const showTax =
    getIn(state, `ddiForm.${form}.values.manualShipTo.showTax`) || false
  const save =
    getIn(state, `ddiForm.${form}.fields.manualShipTo.save.value`) || false

  return {
    canChangeSave,
    showTax,
    save
  }
}

export class FastShipToModalMobile extends Component {
  componentDidMount() {
    if (this.nameField) {
      this.nameField.focus()
    }
  }

  render() {
    const { canChangeSave, save, showTax } = this.props

    return (
      <Paper style={{ padding: 10, width: '100%' }}>
        <h3 style={subtitleHeadingStyle}>Ship To Information</h3>
        <div
          className="fast-ship-to-mobile-layout-wrapper"
          style={inputWrapperStyle}
        >
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.description"
              label="Name"
              inputRef={c => (this.nameField = c)}
              validate={[required, maxLength(30)]}
              InputProps={{
                inputProps: {
                  maxLength: 30
                }
              }}
              fullWidth
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.address1"
              label="Address"
              fullWidth
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.address2"
              label="Address Line 2"
              fullWidth
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.address3"
              label="Address Line 3"
              fullWidth
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.city"
              label="City"
              fullWidth
              preventAutoDisable
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDISelectField
              propertyName="manualShipTo.state"
              label="State"
              fullWidth
              values={states}
              preventAutoDisable
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.postalCode"
              label="Zip Code"
              fullWidth
              preventAutoDisable
              // mask="zipCode"
              inputProps={{
                guide: false
              }}
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.country"
              label="Country"
              fullWidth
            />
          </div>
          <div style={inputInnerWrapperStyle}>
            <DDITextField
              propertyName="manualShipTo.attention"
              label="Attention"
              fullWidth
            />
          </div>
          {save && showTax && (
            <div style={inputInnerWrapperStyle}>
              <DDIIndexSearch
                label="Tax Code"
                propertyName="manualShipTo.taxId"
                metaKey="Tax Code"
                preventAutoDisable
                displayDescription
                descriptionKey="jurisdiction"
                blurOnSelection
              />
            </div>
          )}
          {canChangeSave && (
            <div style={inputInnerWrapperStyle}>
              <AssociatedFieldsWrapper>
                <DDIToggle
                  propertyName="manualShipTo.save"
                  label="Save Manual Ship To Info"
                />
              </AssociatedFieldsWrapper>
            </div>
          )}
        </div>
      </Paper>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(FastShipToModalMobile)
