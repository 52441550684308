import React, { useEffect, useState } from 'react'
import memoize from 'memoize-one'
import { InputAdornment, IconButton, Icon, TextField } from '@material-ui/core'
import {
  clearPaymentTransactionData,
  initializePayment
} from 'pages/SalesOrder/actions'
import { debounce } from 'lodash'

import {
  PaymentButton,
  paymentModuleStyle,
  headerFourStyle,
  buttonIsDisabled
} from '../utils'

const CheckPayment = ({
  form,
  dispatch,
  amountTendered,
  clearAmountTendered,
  balanceLeft,
  isEditing,
  isMobile = false
}) => {
  const [checkNumber, setCheckNumber] = useState('')

  useEffect(() => {
    /* 
      the remaining balance has been updated,
      which means that the user has successfully submitted
      a payment. Clear the check number -- SVE 1/15/2020
    */
    setCheckNumber('')
  }, [balanceLeft])

  useEffect(() => {
    /* functional component substitute for componentWillUnmount */
    return () => {
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  const onChange = e => {
    const { value } = e.target
    setCheckNumber(value)
  }

  const clearCheckNumber = () => setCheckNumber('')

  const submitCheckPayment = debounce(e => {
    dispatch(
      initializePayment.try(form, {
        option: 'check',
        checkNumber,
        amountTendered
      })
    )
  }, 500)

  const moduleStyle = isMobile
    ? {
        ...paymentModuleStyle,
        padding: '10px 10px 15px 10px'
      }
    : paymentModuleStyle

  return (
    <div>
      <h4 style={headerFourStyle}>Enter Payment Information</h4>
      <div style={moduleStyle}>
        <div style={{ width: 200 }}>
          <TextField
            value={checkNumber}
            label="Enter Check Number"
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearCheckNumber} style={{ padding: 5 }}>
                    <Icon style={{ color: '#d9534f', fontSize: 13 }}>
                      close
                    </Icon>
                  </IconButton>
                </InputAdornment>
              )
            }}
            fullWidth
            disabled={!isEditing}
          />
        </div>
        <PaymentButton
          variant="contained"
          onClick={submitCheckPayment}
          disabled={buttonIsDisabled(amountTendered, isEditing)}
        >
          OK
        </PaymentButton>
      </div>
    </div>
  )
}

export default CheckPayment
