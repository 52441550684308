import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext, GridField } from 'ddiForm'
import { headerStyle } from 'pages/ProductMaster/utils'
import { Button, Paper } from '@material-ui/core'
import PivotTable from 'components/PivotTable'
import { getIn, getValue, emptyList } from 'utils'
import { getCorporateFieldUpdatesMeta } from 'components/MasterScreen/CorporateFieldUpdates/actions'

import Links from './components/Links'

import '../../styles/master-style.scss'
import './styles/johnstone.scss'

export const getRowNodeId = ({ dataId, cost, quantity }) =>
  `${dataId} - ${cost} - ${quantity}`

export const getOpenCorpNodeId = data => data.dataId

const costsColDefs = [
  {
    cellStyle: { textAlign: 'right' },
    field: 'quantity',
    headerClass: 'numeric-value-label',
    headerName: 'Quantity'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'cost',
    headerClass: 'numeric-value-label',
    headerName: 'Cost'
  }
]

const linksColDefs = [
  {
    cellRendererFramework: Links,
    filter: false,
    suppressMenu: true
  }
]

const detailsColDefs = [
  {
    field: 'dataId',
    headerName: '',
    filter: false,
    suppressMenu: true
  },
  {
    field: 'description',
    headerName: '',
    filter: false,
    suppressMenu: true
  }
]

const openCorporateCols = [
  { field: 'warehouseId', headerName: 'Whse' },
  { field: 'dataId', headerName: 'P/O #' },
  {
    cellStyle: { textAlign: 'right' },
    field: 'quantityOpen',
    headerClass: 'numeric-value-label',
    headerName: 'Qty Open'
  },
  {
    cellStyle: { textAlign: 'right' },
    field: 'quantityShip',
    headerClass: 'numeric-value-label',
    headerName: 'Qty Ship'
  },
  { field: 'uom', headerName: 'UOM' },
  { field: 'cartons', headerName: 'Cartons' },
  { field: 'invoiceId', headerName: 'Invoice #' }
]

const corporateLotMeta = {
  columns: [
    {
      columnHeader: 'Quantity',
      position: 1,
      propertyName: 'quantity'
    },
    {
      columnHeader: 'List Price',
      position: 2,
      propertyName: 'list'
    },
    {
      columnHeader: 'Net Price',
      position: 3,
      propertyName: 'price'
    }
  ]
}

const Johnstone = ({ corporatePriceLots, dataId, form, height }) => {
  const dispatch = useDispatch()

  const openCorporateFieldsUpdateModal = e =>
    dispatch(
      getCorporateFieldUpdatesMeta.try(form, {
        openModalOnComplete: true
      })
    )

  return (
    <div className="johnstone-layout-flex">
      <div
        className="johnstone-two-col-flex-wrapper"
        style={{ maxWidth: '100%', width: '100%' }}
      >
        <div className="johnstone-two-col-flex-left" style={{ width: '60%' }}>
          <Paper
            className="johnstone-flex-wrapper-1"
            style={{ marginBottom: 10 }}
          >
            {corporatePriceLots && corporatePriceLots.size ? (
              <PivotTable
                data={corporatePriceLots?.toJS ? corporatePriceLots.toJS() : []}
                meta={corporateLotMeta}
                columnHeaderProp="dataId"
              />
            ) : null}
          </Paper>
          <div>
            <div>
              <div className="johnstone-item-display">
                <div className="johnstone-flex-hidden">
                  <GridField
                    title="Corporate Costs"
                    headerStyle={headerStyle}
                    propertyName="jsCorporateCosts"
                    getRowNodeId={getRowNodeId}
                    columnDefs={costsColDefs}
                    height={250}
                    fullWidth
                  />
                </div>
                <div
                  style={{
                    flex: '1 1',
                    paddingLeft: 5,
                    overflow: 'hidden',
                    maxWidth: '100%',
                    width: '100%'
                  }}
                >
                  <GridField
                    title="Direct Costs"
                    headerStyle={headerStyle}
                    propertyName="jsDirectCosts"
                    getRowNodeId={getRowNodeId}
                    columnDefs={costsColDefs}
                    height={250}
                    fullWidth
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ flex: '1 1' }}>
            <GridField
              title="Open Corporate P/Os"
              headerStyle={headerStyle}
              propertyName="getJSOpenCorpPOs"
              columnDefs={openCorporateCols}
              getRowNodeId={getOpenCorpNodeId}
              height={250}
              fullWidth
            />
          </div>
        </div>
        <div className="johnstone-two-col-flex-right" style={{ width: '40%' }}>
          <div>
            <div style={{ marginBottom: 10 }}>
              <GridField
                title="Web Links"
                headerStyle={headerStyle}
                propertyName="johnstoneLinks"
                getRowNodeId={getRowNodeId}
                columnDefs={linksColDefs}
                height={250}
                fullWidth
                width="100%"
              />
            </div>
            <GridField
              title="Details"
              headerStyle={headerStyle}
              propertyName="jsDetail"
              getRowNodeId={getRowNodeId}
              columnDefs={detailsColDefs}
              height={250}
              fullWidth
              width="100%"
            />
          </div>
          <div
            style={{ display: 'flex', padding: 20, justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              disabled={!dataId}
              onClick={openCorporateFieldsUpdateModal}
            >
              Corporate Field Updates
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const contextObj = {
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  corporatePriceLots: form => getValue(form, 'corporatePriceLots', emptyList)
}

export default withContext(contextObj)(Johnstone)
