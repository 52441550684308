import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

/* start MERGE MODAL stuff */
export const openMergeModal = form =>
  createAction(CONSTANTS.OPEN_MERGE_MODAL.TRY, {}, form)

export const merge = (form, { cb }) =>
  createAction(CONSTANTS.MERGE.TRY, { cb }, form)

export const deletePendingMerge = (form, { cb }) =>
  createAction(CONSTANTS.DELETE_PENDING_MERGE.TRY, { cb }, form)

export const clearUOMFields = form =>
  createAction(CONSTANTS.CLEAR_UOM_FIELDS, {}, form)
