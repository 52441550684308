/* eslint react/prefer-stateless-function: 0, react/sort-comp: 0, jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { Button } from '@material-ui/core'
import { errorRed } from 'theme/colors'
import { empty, getMeta, getIn, numToDollar } from 'utils'
import { DDIIndexSearch } from 'ddiForm/wrapped'

import { saveSalesOrder } from 'pages/SalesOrder/actions'
import Calculator from './components/Calculator'
import './styles/allowance.scss'

const Allowance = props => {
  const dispatch = useDispatch()
  const {
    allowanceCustomerIdDescription,
    allowanceAmount,
    customerMetaMap,
    isEditing,
    openTotal,
    totalInvoicePaymentAmount,
    netOrderTotal,
    canBillAllowance,
    form
  } = props

  const customerMeta = customerMetaMap?.toJS ? customerMetaMap?.toJS() : {}

  const onClickBillAllowance = e =>
    dispatch(
      saveSalesOrder.try(form, {
        billToAllowance: true
      })
    )

  return (
    <div className="soe-allowance-wrapper">
      <div className="soe-allowance-row">
        <label>Allowance Customer:</label>
        <div className="soe-allowance-input">
          <DDIIndexSearch
            propertyName="allowanceCustomerId"
            disableLastSearchButton
            fullWidth
            meta={{
              ...customerMeta,
              searchType: 'customer'
            }}
            preventAutoDisable
            popoverStyle={{
              width: 750
            }}
            displayDescription
            description={allowanceCustomerIdDescription}
            moreInfo
            forwardRef
            disabled={!isEditing}
          />
        </div>
      </div>
      <div className="soe-allowance-row">
        <label>Order Total:</label>
        <div className="display-only">{numToDollar(openTotal)}</div>
      </div>
      <div className="soe-allowance-row">
        <label>Payments:</label>
        <div className="display-only">
          {numToDollar(totalInvoicePaymentAmount)}
        </div>
      </div>
      <div className="soe-allowance-row">
        <label>Allowance:</label>
        <div className="soe-allowance-caluclator-wrapper">
          <Calculator
            allowanceAmount={allowanceAmount}
            openTotal={openTotal}
            isEditing={isEditing}
            form={form}
            dispatch={dispatch}
          />
        </div>
      </div>
      <div style={{ paddingTop: 15, width: '100%' }}>
        <div className="soe-allowance-row soe-allowance-totals">
          <label>Net Due:</label>
          <div className="display-only" style={{ color: errorRed }}>
            {numToDollar(netOrderTotal)}
          </div>
        </div>
        <div className="bill-allowance-button-wrapper">
          <Button
            variant="contained"
            disabled={!canBillAllowance}
            onClick={onClickBillAllowance}
          >
            Bill Allowance
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withContext({
  customerMetaMap: form => getMeta(form, 'Customer', empty),
  allowanceCustomerIdDescription: form =>
    getIn(form, 'fields.allowanceCustomerId.description') || '',
  allowanceAmount: form => getIn(form, 'values.allowanceAmount') || 0,
  isEditing: form => form.get('isEditing') || false,
  netOrderTotal: form => getIn(form, 'values.netOrderTotal') || 0,
  openTotal: form => getIn(form, 'values.openTotal') || 0,
  totalInvoicePaymentAmount: form =>
    getIn(form, 'values.totalInvoicePaymentAmount') || 0,
  canBillAllowance: form => getIn(form, 'values.canBillAllowance') || false
  // showAllowanceLabel: form => getIn(form, 'values.showAllowanceLabel') || false
})(Allowance)
