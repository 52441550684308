import moment from 'moment'
import DeleteGridCell from 'components/MasterScreen/Activities/components/DeleteGridCell'
import LaunchEventModalCell from 'components/MasterScreen/Activities/components/LaunchEventModalCell'
import ActivityCompletedCell from 'components/MasterScreen/Activities/components/ActivityCompletedCell'

const columnDefs = props => [
  {
    cellClass: 'cell-value-hidden',
    cellRendererFramework: DeleteGridCell,
    form: props.form,
    field: 'dataId',
    headerName: '',
    filter: false,
    type: 'activity',
    width: 50
  },
  {
    cellRendererFramework: LaunchEventModalCell,
    form: props.form,
    field: 'subject',
    headerName: 'Subject'
  },
  { field: 'type', headerName: 'Type', width: 100 },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 100,
    cellClass: 'text-center align-center',
    valueFormatter: params => {
      if (params.data) {
        return params.value
          ? moment(new Date(params.value)).format('M/D/YY h:mm A')
          : params.value
      }
      return null
    },
    headerClass: 'centered-label'
  },
  {
    cellClass: 'text-center',
    cellRendererFramework: ActivityCompletedCell,
    field: 'completed',
    form: props.form,
    headerName: 'Completed',
    width: 100,
    headerClass: 'centered-label'
  },
]

export default columnDefs
