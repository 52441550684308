/* eslint react/sort-comp: 0, react/no-did-update-set-state: 0, react/static-property-placement: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { is, getIn, noop, layoutFlex, deepEqual, plainDeepEqual } from 'utils'
import GridField from 'ddiForm/GridField'
import { addBlankRow } from 'ddiForm/actions'
import ErrorBoundary from '../ErrorBoundary'
import * as actions from './actions'

/* few styles that can't be customized via JS */
import './styles/css-mod-ignore.scss'

const mapStateToProps = (state, ownProps) => {
  const { form, propertyName } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const isPending = getIn(formState, `fields.${propertyName}.isPending`)
  const emptyRow = getIn(formState, `fields.${propertyName}.emptyRow`) || null
  const modals = getIn(state, 'modals')

  let rowData =
    getIn(formState, `fields.${propertyName}.rowData`) ||
    getIn(formState, `values.${propertyName}`) ||
    getIn(formState, `fields.${propertyName}.value`)

  rowData = rowData && rowData.toJS ? rowData.toJS() : []
  rowData = ownProps.rowData ? ownProps.rowData : rowData

  return {
    isPending: isPending || false,
    activeModals: modals && modals.toJS ? modals.toJS() : [],
    hasEmptyRow: Boolean(emptyRow),
    rowData: rowData && Array.isArray(rowData) ? rowData : []
  }
}

const propsToUpdateFor = [
  'addButtonText',
  'rowData',
  'isEditing',
  'colDefs',
  'isPending',
  'height',
  'propertyName',
  'colParams',
  'showAddButtonOnlyIfEditing'
]

class EditableGrid extends Component {
  static propTypes = {
    activeModals: PropTypes.array.isRequired,
    actOnCellChange: PropTypes.bool,
    addBlankRowOnLoad: PropTypes.bool,
    addButtonText: PropTypes.string,
    allowDuplicates: PropTypes.bool,
    allowDuplicatesRequiresModal: PropTypes.string,
    allowInsertRow: PropTypes.bool,
    allowFocusWithEditModeOff: PropTypes.bool,
    columnDefs: PropTypes.func.isRequired,
    emptyRow: PropTypes.object.isRequired,
    entityType: PropTypes.string,
    focusCell: PropTypes.string,
    focusCellOnNewRow: PropTypes.string,
    forceFocusOnUpdate: PropTypes.bool,
    form: PropTypes.string,
    getRowNodeId: PropTypes.func,
    gridWrapperStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    height: PropTypes.number.isRequired,
    indexSearchType: PropTypes.string,
    isEditing: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired,
    maxWidth: PropTypes.string,
    newRowOnTab: PropTypes.bool,
    onGridReady: PropTypes.func,
    onRowClicked: PropTypes.func,
    onRowSelected: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    propertyName: PropTypes.string.isRequired,
    requiresRefreshOnEditMode: PropTypes.bool,
    removeFocusedCell: PropTypes.bool,
    requiredCols: PropTypes.array,
    // rowData: PropTypes.array,
    rowSelection: PropTypes.string,
    showAddButtonOnlyIfEditing: PropTypes.bool,
    singleClickEdit: PropTypes.bool,
    skipFocusAction: PropTypes.array,
    skipValidationOnUnmount: PropTypes.bool,
    suppressClipboardPaste: PropTypes.bool,
    suppressRowClickSelection: PropTypes.bool,
    suppressTabbing: PropTypes.bool,
    title: PropTypes.string
  }

  static defaultProps = {
    actOnCellChange: false,
    addBlankRowOnLoad: false,
    addButtonText: 'Add',
    allowDuplicates: false,
    allowDuplicatesRequiresModal: '',
    allowFocusWithEditModeOff: false,
    allowInsertRow: false,
    entityType: '',
    focusCell: '',
    focusCellOnNewRow: '',
    forceFocusOnUpdate: false,
    form: 'customerMaster',
    getRowNodeId: data => data.rowId,
    gridWrapperStyle: {
      flex: '1 1',
      maxWidth: '100%',
      // overflow: 'hidden',
      width: '100%'
    },
    headerStyle: {
      background: '#d1d3d4',
      color: '#444',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '17px',
      margin: 0,
      padding: '5px 0',
      textAlign: 'center',
      width: '100%'
    },
    indexSearchType: '',
    maxWidth: '80rem',
    newRowOnTab: false,
    onGridReady: noop,
    onRowClicked: noop,
    onRowSelected: noop,
    onSelectionChanged: noop,
    requiresRefreshOnEditMode: false,
    removeFocusedCell: false,
    requiredCols: ['dataId', 'description'],
    // rowData: [],
    rowSelection: 'multiple',
    showAddButtonOnlyIfEditing: true,
    skipFocusAction: [],
    skipValidationOnUnmount: false,
    singleClickEdit: true,
    suppressClipboardPaste: true,
    suppressRowClickSelection: true,
    suppressTabbing: true,
    title: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      cellInFocus: {}
    }

    this.saveSortedDataToStore = debounce(this.saveSortedDataToStore, 500)
    this.setFocusedCellFromState = debounce(this.setFocusedCellFromState, 100)
  }

  componentDidMount() {
    this._isMounted = true
    const {
      emptyRow,
      form,
      requiredCols,
      propertyName,
      rowData = [],
      dispatch,
      isEditing,
      addBlankRowOnLoad
    } = this.props
    if (requiredCols.length) {
      this.props.dispatch(
        actions.initializeEditableGrid(form, {
          requiredCols,
          propertyName,
          emptyRow
        })
      )
    }

    if (
      dispatch &&
      form &&
      isEditing &&
      propertyName &&
      addBlankRowOnLoad &&
      !rowData?.length &&
      emptyRow
    ) {
      setTimeout(() => {
        if (this._isMounted) {
          dispatch(addBlankRow(form, { propertyName }))
        }
      }, 0)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return Object.keys(nextProps).some(
      prop =>
        propsToUpdateFor.includes(prop) &&
        !deepEqual(this.props[prop], nextProps[prop])
    )
  }

  componentDidUpdate(prevProps, prevState) {
    const hasRowIds =
      (this.props.rowData &&
        Array.isArray(this.props.rowData) &&
        this.props.rowData.every(x => x.rowId)) ||
      false

    if (!hasRowIds) {
      const { form, propertyName } = this.props
      this.props.dispatch(actions.ensureRowIdsSet(form, { propertyName }))
    }

    if (this?.props?.rowData?.length > prevProps?.rowData?.length) {
      setTimeout(() => {
        this.setFocusOnNewRow()
      }, 0)
    }

    if (
      !plainDeepEqual(this.props.rowData, prevProps.rowData) &&
      (!this?.props?.hasEmptyRow || this?.props?.forceFocusOnUpdate)
    ) {
      setTimeout(() => {
        this.setFocusedCellFromState()
      }, 0)
    }

    if (
      ((is.bool(this.props.isEditing) &&
        is.bool(prevProps.isEditing) &&
        this.props.isEditing !== prevProps.isEditing) ||
        !plainDeepEqual(this.props.colParams, prevProps.colParams)) &&
      this._isMounted &&
      this.gridApi
    ) {
      /* 
        resize the grid on going in and out of edit mode,
        OR changing variables that effect what columns are hidden
        and shown. We did not have to do this before, but we do
        now -- SVE 10/20/2021
      */
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit()
      }, 1)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    const {
      form,
      isPending,
      propertyName,
      skipValidationOnUnmount
    } = this.props
    if (form && isPending && !skipValidationOnUnmount) {
      this.props.dispatch(actions.validateGridData(form, { propertyName }))
    }
  }

  setFocusOnNewRow = () => {
    if (
      this._isMounted &&
      this.editableGrid &&
      this.columnApi &&
      this.gridApi
    ) {
      const columns = this.columnApi.getAllColumns()

      if (!columns) {
        return
      }

      const column =
        this.props.focusCellOnNewRow &&
        columns.find(x => x.colDef.field === this.props.focusCellOnNewRow)
          ? columns.find(x => x.colDef.field === this.props.focusCellOnNewRow)
          : columns?.[0]

      if (!column) {
        return
      }
      /* focus on either the _first_ OR the specified column */

      const {
        colDef: { field }
      } = column
      const model = this.gridApi.getModel()

      if (!model) {
        return
      }

      const sortedRowData = model.rowsToDisplay.reduce((acc, next) => {
        acc = acc.concat(next.data)
        return acc
      }, [])
      let rowIndex = sortedRowData.findIndex(x => !x[field])
      rowIndex = rowIndex === -1 ? sortedRowData.length : rowIndex

      // debugger
      this.cellFocusHandler(column, rowIndex, null, true)
    }
  }

  onChange = (data = {}, newData) => {
    const {
      allowDuplicates,
      allowDuplicatesRequiresModal,
      entityType,
      propertyName,
      focusCell,
      form
    } = this.props
    // debugger
    this.props.dispatch(
      actions.onPrimaryGridDataPosted(form, {
        rowIndex: data.rowIndex,
        rowId: data.rowId,
        newData,
        propertyName,
        allowDuplicates,
        allowDuplicatesRequiresModal,
        entityType,
        gridApi: this.gridApi
      })
    )
  }

  onGridReady = params => {
    this.gridApi = params.api
    this.columnApi = params.columnApi

    if (this.props.onGridReady) {
      this.props.onGridReady(params)
    }
  }

  onRowSelected = params => {
    if (this.props.onRowSelected) {
      this.props.onRowSelected(params)
    }
  }

  onRowClicked = params => {
    if (this.props.onRowClicked) {
      this.props.onRowClicked(params)
    }
  }

  onSelectionChanged = params => {
    if (this.props.onSelectionChanged) {
      this.props.onSelectionChanged(params)
    }
  }

  suppressKeyboardEvent = params => {
    const primaryDataFields = ['dataId']

    const { isEditing, allowFocusWithEditModeOff } = this.props

    const isComponent =
      (params.colDef &&
        params.colDef.cellRendererFramework &&
        typeof params.colDef.cellRendererFramework === 'function') ||
      false

    const isIndexSearchField =
      (params.colDef &&
        params.colDef.cellRendererParams &&
        params.colDef.cellRendererParams.indexSearchType) ||
      false

    const field =
      params.colDef && params.colDef.field ? params.colDef.field : ''

    const { event } = params
    // const event = params.event
    const key = event.keyCode
    const arrowKeys = [37, 39]

    if (isEditing && arrowKeys.includes(key) && isComponent) {
      return true
    }

    if (key === 9 && isComponent && params.data.dataId) {
      return false
    }

    /* covers editing and tabbing into an index search field */
    /* also covers tabbing in selection critieria fields (which act like index search fields but are not) */
    if (
      (key === 9 &&
        primaryDataFields.includes(field) &&
        !isEditing &&
        allowFocusWithEditModeOff) ||
      (key === 9 &&
        isIndexSearchField &&
        !isEditing &&
        allowFocusWithEditModeOff)
    ) {
      return true
    }

    if (
      (key === 9 &&
        primaryDataFields.includes(field) &&
        isEditing &&
        !params.data[field]) ||
      (key === 9 && isIndexSearchField && isEditing && !params.data[field])
    ) {
      return true
    }

    return false
  }

  getContextMenuItems = params => {
    const {
      showAddButtonOnlyIfEditing,
      addButtonText,
      form,
      isEditing,
      propertyName
    } = this.props
    const { defaultItems = [] } = params
    // debugger

    /* this will cover most cases for adding a row by context menu */
    if (
      isEditing &&
      this.props.dispatch &&
      showAddButtonOnlyIfEditing &&
      form &&
      propertyName
    ) {
      let items = [
        {
          name: 'Insert Row',
          action: () =>
            this.props.dispatch(
              actions.requestInsertGridRow(form, {
                propertyName,
                rowIndex:
                  params.node && params.node.rowIndex ? params.node.rowIndex : 0
              })
            )
        },
        {
          name: 'Add Row',
          action: () => this.props.dispatch(addBlankRow(form, { propertyName }))
        },
        ...defaultItems
      ]

      if (!this.props.allowInsertRow) {
        items = items.splice(1, items.length)
      }

      return items
    }

    return defaultItems
  }

  /* more control with componentDidUpdate */
  // onRowDataChanged = params => {
  //   const { api, columnApi } = params
  //   this.setFocusedCellFromState()
  // }

  setFocusedCellFromState = () => {
    if (
      this.editableGrid &&
      this._isMounted &&
      this.state.cellInFocus &&
      (this.state.cellInFocus.rowIndex ||
        this.state.cellInFocus.rowIndex === 0) &&
      this.state.cellInFocus.rowIndex >= 0 &&
      this.gridApi &&
      this.columnApi
    ) {
      const { form, propertyName } = this.props
      const {
        rowIndex,
        column: {
          colId,
          colDef: { field }
        },
        floating
      } = this.state.cellInFocus

      /*
        ag-grid does some really weird stuff
        with column IDs, alternately appending a _1
        to either the colId and/or column.colDef.field
        that are sent in various callback params
      */
      if (!this.props.activeModals.length) {
        if (this.columnApi.getColumn(colId)) {
          this.gridApi.setFocusedCell(rowIndex, colId)
        } else if (this.columnApi.getColumn(field)) {
          this.gridApi.setFocusedCell(rowIndex, field)
        } else if (this.columnApi.getColumn(`${field}_1`)) {
          this.gridApi.setFocusedCell(rowIndex, `${field}_1`)
        } else if (this.columnApi.getColumn(`${colId}_1`)) {
          this.gridApi.setFocusedCell(rowIndex, `${colId}_1`)
        }
      }
    }
  }

  saveSortedDataToStore = (propertyName, sortedRowData) => {
    const { form, dispatch } = this.props
    dispatch(
      actions.saveSortedRowData(form, {
        propertyName,
        rowData: sortedRowData
      })
    )
  }

  onCellFocused = params => {
    if (!this.editableGrid || !this._isMounted) {
      return
    }

    if (params.column) {
      /* setState to track cellInFocus */
      this.cellFocusHandler(params.column, params.rowIndex, params.floating)
    }
  }

  cellFocusHandler = (column, rowIndex, floating, isNewRow = false) => {
    if (
      !plainDeepEqual(this.state.cellInFocus, {
        column,
        rowIndex,
        floating
      })
    ) {
      this.setState(
        {
          cellInFocus: {
            column,
            rowIndex,
            floating
          }
        },
        () => {
          if (this.props.dispatch) {
            // debugger
            this.props.dispatch(
              actions.setFocusedCell(this.props.form, {
                propertyName: this.props.propertyName,
                rowIndex: this.state.cellInFocus.rowIndex,
                field: this.state.cellInFocus.column.colDef.field
              })
            )

            if (isNewRow) {
              this.setFocusedCellFromState()
            }
          }
        }
      )
    }
  }

  render() {
    // console.log('editable grid rendered')
    const {
      addButtonText,
      columnDefs,
      emptyRow,
      form,
      getRowNodeId,
      gridWrapperStyle,
      height,
      isEditing,
      maxWidth,
      meta,
      title,
      headerStyle,
      propertyName,
      singleClickEdit,
      suppressClipboardPaste,
      suppressRowClickSelection,
      showAddButtonOnlyIfEditing,
      suppressTabbing,
      colParams,
      rowData
    } = this.props
    // debugger

    return (
      <ErrorBoundary>
        <div className="editable-grid-wrapper" style={layoutFlex(maxWidth)}>
          <div style={gridWrapperStyle}>
            <GridField
              getContextMenuItems={this.getContextMenuItems}
              {...this.props}
              immutableData={false}
              suppressMultiRangeSelection
              suppressClipboardPaste={suppressClipboardPaste}
              suppressRowClickSelection={suppressRowClickSelection}
              propertyName={propertyName}
              getRowNodeId={data => data.rowId}
              columnDefs={columnDefs({
                propertyName,
                form,
                onCellChange: this.onChange,
                isEditing,
                meta: this.props.meta,
                ...colParams
              })}
              enableSorting
              width="100%"
              height={height}
              meta={meta}
              addButtonText={addButtonText}
              showAddButtonOnlyIfEditing={showAddButtonOnlyIfEditing}
              emptyRow={emptyRow}
              onGridReady={this.onGridReady}
              onRowClicked={this.onRowClicked}
              onRowSelected={this.onRowSelected}
              onSelectionChanged={this.onSelectionChanged}
              title={title}
              headerStyle={headerStyle}
              ref={el => (this.editableGrid = el)}
              suppressKeyboardEvent={this.suppressKeyboardEvent}
              onCellFocused={this.onCellFocused}
              rowData={rowData}
              suppressPropertyNamesCheck
            />
          </div>
        </div>
      </ErrorBoundary>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(EditableGrid)
