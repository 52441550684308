import React, { Component } from 'react'

export default class GroupRowInnerRenderer extends Component {
  render() {
    return (
      <div
        style={{
          display: 'inline-block',
          fontSize: 14
        }}
      >
        <strong>{this.props.value}</strong>
      </div>
    )
  }
}
