import React, { useState } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import {
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Paper
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getIn, is, formatDate, formatNumber } from 'utils'
import { errorRed, darkGrey } from 'theme/colors'
import NumberField from '../../tabs/Detail/components/Pricing/components/NumberField'
import LineItemSwitch from '../../tabs/Detail/components/Pricing/components/LineItemSwitch'

const useStyles = makeStyles(theme => ({
  root: {
    width: 320
  },
  paper: {
    width: '100%'
  },
  table: {
    width: 320
  },
  textField: { minWidth: 63, width: 63, boxSizing: 'initial' }
}))

const defaultValues = {
  available: 0,
  onHand: 0,
  onPurchaseOrder: false,
  onSalesOrderAndPicked: false,
  onStockTransferIn: false,
  onStockTransferOutAndPicked: false,
  purchaseOrderExpectedDateEstOrConf: false,
  stockTransferInExpectedDate: null
}

const getValues = (value = {}, warehouseId, defaults = defaultValues) => {
  const { data = [] } = value
  if (!warehouseId || !data.length) {
    return defaults
  }

  return data.find(x => x.dataId === warehouseId) || defaults
}

const parseNum = str => (typeof str === 'string' ? str.replace(',', '') : str)

const getNumberCellStyle = memoize(value => {
  if (is.number(value) && value < 0) {
    return { color: errorRed }
  }

  return null
})

const QuantityCell = ({
  quantityChange,
  canChangeQuantity,
  edited,
  inquiryMode,
  warehouseId,
  value,
  handleClose,
  node,
  form,
  lineNumber,
  // rowData,
  dispatch,
  onCancelButtonClicked,
  setEdited,
  inventory,
  isPostingGridUpdate,
  isEditing
}) => {
  const classes = useStyles()

  // const [wasBlurred, setBlurred] = useState(false)

  quantityChange =
    quantityChange && quantityChange.toJS ? quantityChange.toJS() : {}
  const quantity = quantityChange.quantity || 0
  const quantityBO = quantityChange.quantityBO || 0
  const purchase = quantityChange.purchase || 0
  inventory = inventory && inventory.toJS ? inventory.toJS() : { data: [] }
  // debugger

  const values = getValues(inventory, warehouseId)

  // console.log('VALUES', values, inventory, warehouseId)
  const {
    available = null,
    onHand = null,
    onPurchaseOrder = false,
    onSalesOrderAndPicked = false,
    onStockTransferIn = false,
    onStockTransferOutAndPicked = false,
    purchaseOrderExpectedDateEstOrConf = false,
    stockTransferInExpectedDate = null
  } = values

  const boRef = React.createRef()
  const qRef = React.createRef()
  const testRef = React.createRef()
  const onKeyDown = propertyName => async event => {
    event.persist()
    if (
      (propertyName === 'quantity' && !event.shiftKey) ||
      (propertyName === 'quantityBO' && event.shiftKey)
    ) {
      return
    }
    if (event.key === 'Tab') {
      if (boRef.current && boRef.current.blur) {
        await boRef.current.blur()
      }
      handleClose(true, event.shiftKey)
    }
  }

  const onChange = prop => val => {
    if (prop === 'quantityBO' && val !== quantityBO) {
      setEdited()
    }
    if (prop === 'quantity' && val !== quantity) {
      setEdited()
    }
  }

  if (!Object.keys(values).length) {
    return null
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div style={{ flex: 0.2 }}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="right">Quant Open</TableCell>
                <TableCell align="right">Quant To Ship</TableCell>
                <TableCell align="right">Quant B/O</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {form === 'invoiceInquiry' ? (
                    <TextField
                      className={classes.textField}
                      value={node.data.quantityOpen}
                      inputProps={{
                        style: { textAlign: 'right' }
                      }}
                      disabled
                    />
                  ) : (
                    <TextField
                      className={classes.textField}
                      value={node.data.quantityOrdered}
                      inputProps={{
                        style: { textAlign: 'right' }
                      }}
                      disabled
                    />
                  )}
                </TableCell>
                <TableCell>
                  <NumberField
                    value={quantity}
                    className={classes.textField}
                    dispatch={dispatch}
                    propertyName="quantity"
                    form={form}
                    lineNumber={lineNumber}
                    data={node.data}
                    // onBlur={editFirstCell}
                    autoFocus
                    inputRef={qRef}
                    nextInput={boRef}
                    disabled={inquiryMode || !canChangeQuantity}
                    onChange={onChange('quantity')}
                    onKeyDown={onKeyDown('quantity')}
                    noDecimalLimit
                    textAlign="right"
                  />
                </TableCell>
                <TableCell>
                  <NumberField
                    value={quantityBO}
                    className={classes.textField}
                    dispatch={dispatch}
                    propertyName="quantityBO"
                    form={form}
                    lineNumber={lineNumber}
                    data={node.data}
                    // ref={boRef}
                    // onBlur={secondCellBlur}
                    // callOnBlurWithoutChange
                    inputRef={boRef}
                    onKeyDown={onKeyDown('quantityBO')}
                    disabled={inquiryMode || !canChangeQuantity}
                    onChange={onChange('quantityBO')}
                    noDecimalLimit
                    ref={testRef}
                    textAlign="right"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div style={{ flex: 0.7 }}>
          <Typography variant="h6" style={{ paddingLeft: 10 }}>
            Warehouse Stock
          </Typography>
          <Table className={classes.table} size="small">
            <TableBody>
              <TableRow>
                <TableCell>On Hand</TableCell>
                <TableCell align="right" style={getNumberCellStyle(onHand)}>
                  {is.number(onHand) && onHand?.toLocaleString
                    ? onHand.toLocaleString()
                    : onHand}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Avail</TableCell>
                <TableCell align="right" style={getNumberCellStyle(available)}>
                  {is.number(available) && available?.toLocaleString
                    ? available.toLocaleString()
                    : available}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>On P/O</TableCell>
                <TableCell
                  align="right"
                  style={getNumberCellStyle(onPurchaseOrder)}
                >
                  {is.number(onPurchaseOrder) && onPurchaseOrder?.toLocaleString
                    ? onPurchaseOrder.toLocaleString()
                    : onPurchaseOrder}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>On Trans In</TableCell>
                <TableCell
                  align="right"
                  style={getNumberCellStyle(onStockTransferIn)}
                >
                  {is.number(onStockTransferIn) &&
                  onStockTransferIn?.toLocaleString
                    ? onStockTransferIn.toLocaleString()
                    : onStockTransferIn}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>On SO/Pckd</TableCell>
                <TableCell align="right">{onSalesOrderAndPicked}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Trans Out/Pckd</TableCell>
                <TableCell align="right">
                  {onStockTransferOutAndPicked}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>P/O Expd Dt</TableCell>
                <TableCell align="center">
                  {purchaseOrderExpectedDateEstOrConf}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>S/O Expd Dt</TableCell>
                <TableCell align="center">
                  {stockTransferInExpectedDate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div
          style={{
            flex: 0.1,
            flexDirection: 'row',
            display: 'flex',
            padding: '0 10px'
          }}
        >
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <LineItemSwitch
              field="purchase"
              label="Purchase"
              form={form}
              dispatch={dispatch}
              node={node}
              value={node.data.purchase}
              isPostingGridUpdate={isPostingGridUpdate}
              disabled={!isEditing}
            />
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={onCancelButtonClicked}
              style={{ padding: '5px 20px 5px 20px' }}
              inputProps={{ tabIndex: -1 }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { form, rowId } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const isEditing = getIn(formState, 'isEditing') || false
  const isPostingGridUpdate = getIn(formState, 'isPostingGridUpdate') || false

  const quantityChange = getIn(
    formState,
    `additionalDataMap.${rowId}.quantityChange`
  )
  const inventory = getIn(formState, `additionalDataMap.${rowId}.inventory`)

  return {
    isEditing,
    isPostingGridUpdate,
    quantityChange,
    inventory
  }
}
const connectedQuantityCell = connect(mapStateToProps)(QuantityCell)
export default React.memo(connectedQuantityCell)
