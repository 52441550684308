import React, { useState, useEffect, useRef } from 'react'
import Grid from 'grid'
import { withContext } from 'ddiForm'
import { is, formatNumber } from 'utils'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { moveProductToDifferentGroup } from 'pages/SalesOrder/actions'
import { getGridHeight, addMinWidth } from './utils'

import DeleteCell from '../DeleteItemGroupCell'
import { contextObj } from '../ProductsInGroupGrid'

const ITEM_HEIGHT = 48

const getRowNodeId = data => data.lineNumber

const getColumnDefs = ({ form, dispatch }) => {
  const colDefs = [
    {
      field: 'lineNumber',
      headerName: 'Ln',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      filter: 'agNumberColumnFilter'
    },
    {
      field: 'dataId',
      headerName: 'Product',
      filter: 'agTextColumnFilter'
    },
    {
      field: 'description',
      headerName: 'Description',
      filter: 'agTextColumnFilter'
    },
    {
      cellStyle: { 'text-align': 'right' },
      headerClass: 'align-right',
      field: 'netPrice',
      headerName: 'Net Price',
      suppressFilter: true,
      suppressMenu: true,
      valueFormatter: params => formatNumber(params.value, '0,0.0000')
    },
    {
      cellStyle: { 'text-align': 'right' },
      field: 'quantityOrdered',
      headerName: 'Qty',
      suppressMenu: true
    },
    {
      headerName: '',
      cellRendererFramework: DeleteCell,
      cellRendererParams: {
        form,
        dispatch,
        type: 'product'
      },
      maxWidth: 50,
      suppressMenu: true
    }
  ]

  return colDefs.map(addMinWidth)
}

const ProductsInGroupGridMobile = ({
  selectedRowIndex,
  itemGroupsMap,
  form,
  dispatch
}) => {
  const itemGroups = itemGroupsMap?.toJS ? itemGroupsMap.toJS() : []
  const menuOptions =
    itemGroups && is.array(itemGroups) && itemGroups.length
      ? itemGroups.filter(
          (x, i) => x.rowId !== 'blankrow' && i !== selectedRowIndex
        )
      : []

  const gridApi = useRef(null)

  const [rowData, setRowData] = useState([])
  const [title, setTitle] = useState('')
  const [
    selectedProductsLineNumbers,
    setSelectedProductsLineNumbers
  ] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (
      itemGroups.length &&
      itemGroups[selectedRowIndex] &&
      itemGroups[selectedRowIndex].lineItems
    ) {
      const selectedProduct = itemGroups[selectedRowIndex]
      const newTitle = selectedProduct.dataId ? selectedProduct.dataId : ''

      setRowData(selectedProduct.lineItems)
      setTitle(newTitle)
    }
  }, [itemGroupsMap, selectedRowIndex])

  useEffect(() => {
    if (selectedRowIndex === null) {
      setRowData([])
      setTitle('')
    }
  }, [selectedRowIndex])

  const onGridReady = params => {
    if (params && params.api) {
      gridApi.current = params.api
    }
  }

  const handleClick = e => setAnchorEl(e.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleSelectGroup = event => {
    // find better way to get dataId
    const itemGroupId = event.currentTarget.dataset.itemgroupid

    dispatch(
      moveProductToDifferentGroup.try(form, {
        itemGroupId,
        lineNumbers: selectedProductsLineNumbers
      })
    )

    setAnchorEl(null)
  }

  const onSelectionChanged = params => {
    if (params && params.api) {
      const selected = params.api.getSelectedRows().reduce((acc, next) => {
        acc = acc.concat([next.lineNumber])
        return acc
      }, [])

      setSelectedProductsLineNumbers(selected)
    }
  }

  const postProcessPopup = params => {
    if (params && params.type !== 'columnMenu') {
      return
    }

    const { ePopup } = params
    ePopup.style.zIndex = 1300
  }

  return (
    <div>
      <Grid
        title={`Group ${title} Products`}
        getRowNodeId={getRowNodeId}
        columnDefs={getColumnDefs({
          form,
          dispatch
        })}
        fullWidth
        rowData={rowData}
        height={getGridHeight(rowData)}
        rowSelection="multiple"
        headerStyle={{
          background: '#d1d3d4',
          color: '#444',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17px',
          margin: 0,
          padding: '5px 0',
          textAlign: 'center',
          width: '100%'
        }}
        onSelectionChanged={onSelectionChanged}
        suppressRowClickSelection
        postProcessPopup={postProcessPopup}
        onGridReady={onGridReady}
      />
      <div style={{ flex: 1, display: 'flex', margin: '5px 0 20px 0' }}>
        <Button
          onClick={handleClick}
          // disabled when no item groups or there are no selected products
          disabled={!menuOptions.length || !selectedProductsLineNumbers.length}
        >
          Move Product To Group
        </Button>

        {/* ITEM GROUP OPTIONS */}
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          keepMounted
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            }
          }}
        >
          {menuOptions.map(option => (
            <MenuItem
              key={option.dataId}
              onClick={handleSelectGroup}
              data-itemgroupid={option.dataId}
            >
              {option.dataId}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </div>
  )
}

/* fixed SVE 9/24/2020 */
/* contextObj shared with ProductsInGroup */
export default withContext(contextObj)(ProductsInGroupGridMobile)
