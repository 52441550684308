import React, { useState, useEffect, createRef } from 'react'
import { useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { show } from 'snackbar/actions'
import { withContext } from 'ddiForm'
import { removeModal } from 'modals/actions'

import { updateAs } from '../actions'

const paragraphStyle = {
  color: '#517b9c',
  textAlign: 'center',
  fontSize: '1.1em',
  margin: 0
}

const paperStyle = {
  border: '1px solid #517b9c',
  background: '#f2f2f2',
  padding: 10,
  marginBottom: 15
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%'
  },
  card: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardMarginTop: {
    width: '100%',
    marginTop: 20
  }
}))

export function SaveAsModal(props) {
  const [open, setOpen] = useState(true)
  const [trying, setTrying] = useState(false)
  const [status, setStatus] = useState('')
  const classes = useStyles()
  const [customerName, setCustomerName] = useState('')
  const customerRef = createRef()
  const dispatch = useDispatch()
  const onKeyUp = event => {
    if (event.key === 'Enter') {
      // this.resetPasswordRequest()
    }
  }
  console.log(props)

  const handleClose = () => {
    setOpen(false)
    // const f = await resetPasswordRequest
    // console.log(currentEmailField)
    props.dispatch(removeModal(null, props.id))
  }

  const saveAsRequest = async () => {
    if (customerName && !trying) {
      setTrying(true)
      try {
        await dispatch(updateAs.request('customerMaster', { customerName }))
        setOpen(false)
      } catch (e) {
        setTrying(false)
        // setStatus(
      }
    }
  }

  const updateCustomer = e => {
    setCustomerName(e.target.value)
  }
  let { account } = props
  account = account?.toJS ? account.toJS() : {}

  const animationStyles = account.isSuccess
    ? { animationDuration: '2s', animationName: 'fadeIn' }
    : {}
  console.log(customerRef)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">Save As</DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div style={{ margin: '25px auto', minHeight: 280 }}>
              <div style={{ maxWidth: '100%', minWidth: 260, width: '100%' }}>
                <Paper style={paperStyle}>
                  <p style={paragraphStyle}>Save Customer As</p>
                </Paper>

                <form
                  className="form-transition"
                  style={animationStyles}
                  name="updateEmail"
                >
                  <div className="input-wrapper">
                    <TextField
                      label="Customer"
                      id="current-email"
                      inputRef={customerRef}
                      onChange={updateCustomer}
                      value={customerName}
                      fullWidth
                      style={{ fontSize: 14 }}
                    />
                  </div>
                  {account.statusText && (
                    <div className="alert alert-info">{account.statusText}</div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // disabled={isAuthenticating}
            disabled={!customerName?.length || trying}
            // disabled={account.submitting || this.state.requesting}
            color="primary"
            onClick={saveAsRequest}
          >
            Ok
          </Button>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SaveAsModal
