import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, FormControlLabel, Input, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { getIn, is, noop } from 'utils'
import memoize from 'memoize-one'
import NumberFormat from 'react-number-format'
import { NumberFormatter } from 'components/EditableGrid/components/TextFieldGridCell'
import { debounce, isNumber } from 'lodash'
import { a } from 'react-dom-factories'

export function useLongPress(callback = () => {}, ms = 300) {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId
    if (startLongPress) {
      timerId = setTimeout(callback, ms)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, ms, startLongPress])

  return {
    onMouseDown: () => setStartLongPress(true),
    onMouseUp: () => setStartLongPress(false),
    onMouseLeave: () => setStartLongPress(false),
    onTouchStart: () => setStartLongPress(true),
    onTouchEnd: () => setStartLongPress(false)
  }
}

const NumberSpinner = (props, ref) => {
  const {
    disabled,
    max,
    isField,
    hintText,
    onDoubleClick,
    label,
    errorText,
    inputProps,
    labelPosition,
    setField,
    updateValueCallback
  } = props

  const [value, setValue] = useState(
    is.number(props.initialValue) ? props.initialValue : props.value
  )

  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value)
    }
  }, [props.value])
  const fieldSetter = val =>
    isField ? setField(val) : updateValueCallback(val)

  const setInc = newVal => {
    setValue(newVal <= max ? newVal : max)
    fieldSetter(newVal <= max ? newVal : max)
    // next
  }
  const setDec = newVal => {
    setValue(newVal <= max ? newVal : max)
    fieldSetter(newVal <= max ? newVal : max)
    // next
  }

  const decrement = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      if (e.currentTarget.id === 'btn-increment') {
        return
      }
    }
    const {
      step,
      inputProps: { fixedDecimalScale, decimalScale, allowNegative },
      min
    } = props
    // debugger
    if (disabled === false) {
      let newVal = value - step < min ? min : value - step
      if (fixedDecimalScale && decimalScale) {
        newVal = Number(newVal.toFixed(decimalScale))
      }
      if (newVal < 0 && !allowNegative) {
        return
      }
      setDec(newVal)
    }
  }
  const increment = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      if (e.currentTarget.id === 'btn-decrement') {
        return
      }
    }
    const {
      step,
      inputProps: { fixedDecimalScale, decimalScale, allowNegative }
    } = props

    if (disabled === false) {
      let newVal = value === '' ? step : value <= max ? value + step : value
      if (fixedDecimalScale && decimalScale) {
        newVal = Number(newVal.toFixed(decimalScale))
      }
      setInc(newVal)
    }
  }

  const incrementArgs = useLongPress(increment)
  const decrementArgs = useLongPress(decrement)

  const updateValue = values => {
    const {
      inputProps: { allowNegative }
    } = props
    const val =
      values.floatValue || values.floatValue === 0 ? values.floatValue : ''
    // debugger

    if (val > max) {
      return
    }
    if (!allowNegative && isNumber(val) && Math.sign(val) === -1) {
      setValue('')
      // setField('')
      fieldSetter('')
    } else if (val === '' || Number.isNaN(val)) {
      setValue('')
      // setField('')
      fieldSetter('')
    } else if (!Number.isNaN(val)) {
      setValue(val)
      // setField(val)
      fieldSetter(val)
    }
  }

  const getInputWidth = memoize(v => {
    if (inputProps?.noDecimalLimit) {
      return v && typeof v === 'number'
        ? v.toString().replace('.', '').length * 15 +
            (inputProps?.prefix || inputProps?.suffix ? 20 : 0)
        : 45
    }

    return v && typeof v === 'number'
      ? v.toString().split('.')[0].length * 15 +
          (inputProps?.prefix || inputProps?.suffix ? 20 : 0) +
          (inputProps?.decimalScale ? inputProps?.decimalScale * 15 : 0)
      : inputProps?.prefix || inputProps?.suffix
      ? 65
      : 45
  })

  const renderSpinner = ({
    disabled,
    max,
    hintText,
    onDoubleClick,
    label,
    errorText,
    inputProps,
    step
  }) => {
    const iconStylesBase = {
      height: 16,
      position: 'relative',
      width: 16
    }
    // const buttonStylesBase = { float: 'left', height: 26, width: 26 }
    const buttonStylesBase = { height: 26, maxWidth: 26, width: 26 }

    // console.log('insideNumberSpinner', inputProps, value)
    console.log('numberSpinner', value, typeof value)

    return (
      <div
        // className="number-spinner"
        onDoubleClick={onDoubleClick}
        style={
          label
            ? {
                alignItems: 'center',
                marginLeft: labelPosition === 'left' ? 0 : 5,
                marginRight: labelPosition === 'left' ? 5 : 0,
                display: 'flex',
                cursor: disabled ? 'default' : 'pointer'
              }
            : {
                alignItems: 'center',
                display: 'flex',
                cursor: disabled ? 'default' : 'pointer'
              }
        }
      >
        <IconButton
          id="btn-decrement"
          onClick={decrement}
          disabled={value === 0 || disabled}
          // iconStyle={{ ...iconStylesBase, left: -10, top: -10 }}
          style={{
            ...buttonStylesBase,
            flex: 1,
            background: disabled ? '#999' : '#517b9c',
            borderRadius: '4px 0 0 4px',
            opacity: disabled ? 0.5 : undefined,
            padding: 5
          }}
          {...decrementArgs}
        >
          <Icon style={{ color: '#fff', fontSize: 16 }}>remove</Icon>
        </IconButton>
        <Input
          disableUnderline
          fullWidth
          inputRef={ref}
          value={value}
          disabled={disabled}
          className="input-spinner"
          inputComponent={NumberFormatter}
          onChange={updateValue}
          style={{
            color: disabled ? '#555' : 'inherit',
            borderBottom: `1px solid ${disabled ? '#ccc' : '#517b9c'}`, // disabled ? '#999' : '1px solid #517b9c',
            borderLeft: 0,
            borderRight: 0,
            borderTop: `1px solid ${disabled ? '#ccc' : '#517b9c'}`, // disabled ? '#999' : '1px solid #517b9c',
            fontSize: 13,
            height: 26,
            textAlign: 'center',
            minWidth: inputProps?.prefix || inputProps?.suffix ? 65 : 45,
            width: getInputWidth(value)
          }}
          inputProps={{
            decimalScale: inputProps?.decimalScale,
            noDecimalLimit: inputProps?.noDecimalLimit,
            decimalSeparator: inputProps?.decimalSeperator,
            fixedDecimalScale: inputProps?.fixedDecimalScale,
            inputMode: 'numeric',
            textAlign: 'center',
            thousandSeparator: inputProps?.thousandSeparator,
            prefix: inputProps?.prefix,
            suffix: inputProps?.suffix
          }}
        />
        <IconButton
          disabled={value >= max || disabled}
          id="btn-increment"
          onClick={increment}
          // iconStyle={{ ...iconStylesBase, left: -6, top: -7 }}
          style={{
            ...buttonStylesBase,
            background: value >= max || disabled ? '#999' : '#517b9c',
            borderRadius: '0 4px 4px 0',
            flex: 1,
            opacity: value >= max || disabled ? 0.5 : undefined,
            padding: 5
          }}
          {...incrementArgs}
        >
          <Icon style={{ color: '#fff', fontSize: 16 }}>add</Icon>
        </IconButton>
        {errorText && !isField ? (
          <div style={{ marginLeft: 10 }} title={errorText}>
            <Icon
              style={{
                color: '#d9534f',
                fontSize: 16
              }}
            >
              error_outline
            </Icon>
          </div>
        ) : null}
      </div>
    )
  }
  // debugger
  return label ? (
    <FormControlLabel
      control={renderSpinner({
        disabled,
        max,
        hintText,
        onDoubleClick,
        label,
        errorText,
        inputProps
      })}
      label={label}
      labelPlacement={labelPosition}
    />
  ) : (
    renderSpinner({
      disabled,
      max,
      hintText,
      onDoubleClick,
      label,
      errorText,
      inputProps
    })
  )
}

const Spinner = React.forwardRef(NumberSpinner)

Spinner.defaultProps = {
  disabled: false,
  hintText: undefined,
  isField: true,
  labelPosition: 'start',
  inputProps: {
    allowNegative: false,
    decimalScale: 0,
    noDecimalLimit: false,
    decimalSeparator: '.',
    fixedDecimalScale: false,
    textAlign: 'center',
    thousandSeparator: ',',
    prefix: '',
    suffix: ''
  },
  updateValueCallback: value => {
    console.log(value)
  },
  value: 0,
  min: 0,
  max: Infinity,
  step: 1,
  wrapperStyles: { float: 'left', margin: '10px 0' }
}
export default Spinner
