import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { connect } from 'react-redux'
import { getIn, plainDeepEqual } from 'utils'
import IconTooltip from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/hoc/IconTooltip'
import { lockActivity as lockActivityAction } from 'modals/EventModal/actions'

const mapStateToProps = state => ({
  eventModal: getIn(state, 'ddiForm.eventModal') || {}
})

class ActivityCompletedCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    eventModal: PropTypes.object.isRequired
  }

  launchEventModal = () => {
    const {
      colDef: { form },
      data: { dataId },
      eventModal
    } = this.props

    if (dataId && !Object.keys(eventModal).length) {
      this.props.dispatch(lockActivityAction(form, { dataId }))
    }
  }

  render() {
    const {
      data: { completed }
    } = this.props
    const icon = completed ? 'check_box' : 'check_box_outline_blank'
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          height: '100%',
          width: '100%'
        }}
      >
        <Icon
          style={{ color: '#999', display: 'inline-block', fontSize: 16 }}
          onClick={this.launchEventModal}
        >
          {icon}
        </Icon>
      </div>
    )
  }
}

const cellWithTooltip = IconTooltip({ tooltipField: 'endDate' })(
  ActivityCompletedCell
)

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(cellWithTooltip)
