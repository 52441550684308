/* eslint react/prefer-stateless-function: 0, jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react'
import { DDINumberSpinner } from 'ddiForm/wrapped'
import { withContext } from 'ddiForm'
import { getField, getValue } from 'utils'
import { connect } from 'react-redux'

class CutLengthModal extends Component {
  render() {
    return (
      <div>
        <label className="label-style">Pieces:</label>
        <DDINumberSpinner
          propertyName="customCut.pieces"
          step={1.0}
          min={0.0}
          wrapperStyle={{ margin: 0, marginBottom: '10px' }}
          preventAutoDisable
        />
        <label className="label-style">Feet:</label>
        <DDINumberSpinner
          propertyName="customCut.feet"
          step={1.0}
          min={0.0}
          wrapperStyle={{ margin: 0, marginBottom: '10px' }}
          preventAutoDisable
        />
        <label className="label-style">Inches:</label>
        <DDINumberSpinner
          propertyName="customCut.inches"
          step={1.0}
          min={0.0}
          wrapperStyle={{ margin: 0, marginBottom: '10px' }}
          preventAutoDisable
        />
        <label className="label-style">Fraction:</label>
        <DDINumberSpinner
          propertyName="customCut.numerator"
          step={1.0}
          min={0.0}
          wrapperStyle={{ margin: 0 }}
          preventAutoDisable
        />
        ________________
        <DDINumberSpinner
          propertyName="customCut.divisor"
          step={1.0}
          min={0.0}
          wrapperStyle={{ margin: 0, marginBottom: '10px' }}
          preventAutoDisable
        />
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(CutLengthModal)
