import React from 'react'
import DropShipChargesGrid from './components/grids/DropShipChargesGrid'

const DropShipCharges = props => (
  <div
    className="vendor-master-drop-ship-charges-wrapper"
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }}
  >
    <div style={{ width: 400 }}>
      <DropShipChargesGrid {...props} />
    </div>
  </div>
)

export default DropShipCharges
