/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useState } from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import Grid from 'grid'
import {
  clearPaymentTransactionData,
  getVaultCards,
  initializePayment,
  removeVaultCard,
  setDefaultPaymentContact
} from 'pages/SalesOrder/actions'

import { handleCheckoutContact, setGridHeight } from 'pages/SalesOrder/utils'
import { emptyList, getIn, getField, getValue } from 'utils'
import usePrevious from 'hooks/usePrevious'
import { Icon } from '@material-ui/core'
import shortid from 'shortid'
import { debounce, isEqual } from 'lodash'

import { PaymentButton, paymentContactMeta } from '../../../utils'

const RemoveVaultCardCell = ({ dispatch, form, node, isEditing, cb }) => {
  const tryRemoveVaultCard = debounce(e => {
    // console.log(dispatch, form, node, isEditing)
    if (node.data.guid) {
      //
      dispatch(
        removeVaultCard.try(form, {
          paymentGuid: node.data.guid,
          cb
        })
      )
    }
  }, 300)

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Icon
        style={{
          color: isEditing ? '#d9534f' : '#999',
          display: 'inline-block',
          fontSize: 18
        }}
        onClick={tryRemoveVaultCard}
        disabled={!isEditing}
      >
        cancel
      </Icon>
    </div>
  )
}

const getColumnDefs = memoize((form, dispatch, isEditing, cb) => {
  const columnDefs = [
    {
      field: 'cardDescription',
      headerName: 'Type'
    },
    {
      field: 'cardNumber',
      headerName: 'Card Number'
    },
    {
      field: 'expirationDate',
      headerName: 'Exp Date'
    },
    {
      field: 'cardHolder',
      headerName: 'Name'
    },
    {
      field: 'address',
      headerName: 'Address'
    },
    {
      field: 'zipCode',
      headerName: 'ZIP'
    },
    {
      headerName: '',
      cellRendererFramework: RemoveVaultCardCell,
      cellRendererParams: {
        form,
        dispatch,
        isEditing,
        cb
      },
      hide: !isEditing,
      maxWidth: 50,
      suppressMenu: true,
      suppressFilter: true
    }
  ]

  return columnDefs
}, isEqual)

const VaultPayment = ({
  form,
  dispatch,
  ccPaymentOption,
  checkoutContactDescription,
  customerId,
  paymentAttemptSuccess,
  paymentOption,
  isEditing,
  isPosting,
  checkoutContact,
  checkoutContactFieldRegistered,
  amountTendered,
  vaultCardsMap,
  saveCCInfo,
  orderedById,
  orderedByName
}) => {
  const vaultCards = vaultCardsMap?.toJS ? vaultCardsMap.toJS() : []
  const prevPaymentOption = usePrevious(paymentOption)
  const prevCCPaymentOption = usePrevious(ccPaymentOption)

  const [state, setState] = useState({
    contactInputKey: shortid.generate(),
    selectedCard: null
  })

  useEffect(() => {
    if (paymentAttemptSuccess) {
      setState({
        ...state,
        selectedCard: null,
        contactInputKey: shortid.generate()
      })
    }
  }, [paymentAttemptSuccess])

  useEffect(() => {
    /* functional component substitute for componentDidMount && componentWillUnmount */
    if (saveCCInfo) {
      handleCheckoutContact(
        dispatch,
        form,
        checkoutContact,
        checkoutContactDescription,
        orderedById,
        orderedByName
      )
    } else if (orderedById) {
      debugger
      dispatch(
        getVaultCards.try(form, {
          contactId: orderedById
        })
      )
    }

    return () => {
      dispatch(clearPaymentTransactionData(form))
    }
  }, [])

  useEffect(() => {
    if (
      paymentOption === 'cc' &&
      ccPaymentOption === 'vault' &&
      prevCCPaymentOption &&
      prevPaymentOption &&
      (paymentOption !== prevPaymentOption ||
        ccPaymentOption !== prevCCPaymentOption) &&
      checkoutContact &&
      checkoutContactDescription &&
      saveCCInfo &&
      !isPosting
    ) {
      handleCheckoutContact(
        dispatch,
        form,
        checkoutContact,
        checkoutContactDescription,
        orderedById,
        orderedByName
      )
    }
  }, [ccPaymentOption, paymentOption])

  useEffect(() => {
    console.log(
      'useEffect',
      checkoutContact,
      orderedById,
      orderedByName,
      vaultCardsMap
    )
    setState({
      ...state,
      selectedCard: null
    })
  }, [checkoutContact])

  useEffect(() => {
    setState({
      ...state,
      selectedCard: null
    })
  }, [orderedById])

  const onSubmitVaultPayment = e => {
    if (state.selectedCard && state.selectedCard.guid) {
      dispatch(
        initializePayment.try(form, {
          option: 'vaultPayment',
          amountTendered,
          paymentGuid: state.selectedCard.guid
        })
      )
    }
  }

  const onRowSelected = params => {
    if (params.node.selected) {
      // console.log(params)
      //
      setState({
        ...state,
        selectedCard: params.data
      })
    }
  }

  const clearSelectedVaultCard = () => {
    setState({
      ...state,
      selectedCard: null
    })
  }

  const noVaultCardsMessage =
    checkoutContact && saveCCInfo
      ? 'No vault cards available for this contact'
      : ''

  return (
    <div>
      {saveCCInfo ? (
        <>
          <p style={{ fontSize: 12, margin: '10px 0', textAlign: 'center' }}>
            Please select a contact, and then a card from the vault and click
            &lsquo;Submit&rsquo;
          </p>
          <div style={{ alignItems: 'flex-end', display: 'flex' }}>
            <div style={{ maxWidth: 190, margin: 10 }}>
              <label
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontSize: '0.85em',
                  margin: '0 0 5px 0',
                  transform: 'translate(0, 7px) scale(0.75)',
                  transformOrigin: 'top left'
                }}
              >
                Contact
              </label>
              <DDIIndexSearch
                key={state.contactInputKey}
                propertyName="checkoutContact"
                parentId={customerId}
                fullWidth
                parentType="Customer"
                popoverStyle={{
                  width: 750
                }}
                meta={paymentContactMeta}
                descriptionKey="contact"
                displayDescription
                description={checkoutContactDescription}
                inputProps={{
                  showQuickEntityButton: true
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <p style={{ fontSize: 12, margin: '10px 0', textAlign: 'center' }}>
          Choose from any available vault cards, then click &lsquo;Submit&rsquo;
        </p>
      )}

      {vaultCards && Array.isArray(vaultCards) && vaultCards.length ? (
        <div style={{ marginTop: 15, width: '100%' }}>
          <Grid
            getRowNodeId={data => data.guid}
            rowData={vaultCards}
            columnDefs={getColumnDefs(
              form,
              dispatch,
              isEditing,
              clearSelectedVaultCard
            )}
            fullWidth
            height={setGridHeight(vaultCards, 1)}
            width="100%"
            style={{ width: '100%' }}
            rowSelection="single"
            onRowSelected={onRowSelected}
            suppressHorizontalScroll
          />
        </div>
      ) : (
        <div style={{ padding: 10 }}>
          <p
            style={{
              margin: 0,
              fontSize: 11,
              fontStyle: 'italic',
              textAlign: 'center'
            }}
          >
            {noVaultCardsMessage}
          </p>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px 5px 5px 5px'
        }}
      >
        <PaymentButton
          onClick={onSubmitVaultPayment}
          variant="contained"
          disabled={isPosting || !isEditing || !state.selectedCard}
          style={{ margin: 10 }}
        >
          <Icon style={{ marginRight: 5 }}>lock</Icon> Submit
        </PaymentButton>
      </div>
    </div>
  )
}

/* updated SVE 9/25/2020 */
export default withContext({
  checkoutContact: form => getValue(form, 'checkoutContact', ''),
  checkoutContactFieldRegistered: form =>
    getIn(form, 'fields.checkoutContact.meta') || null,
  checkoutContactDescription: form =>
    getField(form, 'checkoutContactDescription', '') ||
    getValue(form, 'checkoutContactDescription', '') ||
    '',
  customerId: form => getField(form, 'customerId', ''),
  paymentOption: form => getIn(form, 'ui.paymentOption') || '',
  ccPaymentOption: form => getIn(form, 'ui.ccPaymentOption') || 'keyed',
  paymentAttemptSuccess: form => form.get('paymentAttemptSuccess') || false,
  isEditing: form => form.get('isEditing') || false,
  isPosting: form => form.get('isPosting') || false,
  vaultCardsMap: form => getValue(form, 'vaultCards', emptyList),
  saveCCInfo: form => getValue(form, 'saveCCInfo', false),
  orderedById: form => getField(form, 'orderedById', ''),
  orderedByName: form => getValue(form, 'orderedByName', '')
})(VaultPayment)
