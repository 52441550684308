import React from 'react'
import { Table, TableCell, TableRow, TableBody } from '@material-ui/core'
import { withContext } from 'ddiForm'
import { formatNumber, getValue } from 'utils'

const cellStyle = { textAlign: 'right' }
const bgWhite = { background: '#fff' }

const ItemizedSubTotalTable = ({
  openSubTotal,
  shippableSubtotal,
  backOrderAmount,
  shippedSubTotal,
  orderSubTotal
}) => (
  <div>
    <h2
      style={{
        color: '#444',
        fontSize: 14,
        fontWeight: 500,
        margin: '10px 0 0 0',
        textAlign: 'center'
      }}
    >
      Subtotal
    </h2>
    <Table>
      <TableBody>
        <TableRow style={bgWhite}>
          <TableCell>Open</TableCell>
          <TableCell style={cellStyle}>{formatNumber(openSubTotal)}</TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Shippable</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippableSubtotal)}
          </TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Back Ordered</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(backOrderAmount)}
          </TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Shipped</TableCell>
          <TableCell style={cellStyle}>
            {formatNumber(shippedSubTotal)}
          </TableCell>
        </TableRow>
        <TableRow style={bgWhite}>
          <TableCell>Total</TableCell>
          <TableCell style={cellStyle}>{formatNumber(orderSubTotal)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
)

/* should be OK as-is SVE 9/25/2020 */
export default withContext({
  openSubTotal: form => getValue(form, 'openSubTotal', 0),
  shippableSubtotal: form => getValue(form, 'shippableSubtotal', 0),
  backOrderAmount: form => getValue(form, 'backOrderAmount', 0),
  shippedSubTotal: form => getValue(form, 'shippedSubTotal', 0),
  orderSubTotal: form => getValue(form, 'orderSubTotal', 0)
})(ItemizedSubTotalTable)
