import {
  actionChannel,
  take,
  call,
  // cancel,
  select,
  put,
  putResolve,
  fork
} from 'redux-saga/effects'
// import soap from 'jquery.soap'
import { getIn, toJS } from 'utils'
import { addModal, removeModal } from 'modals/actions'
import * as EVENT_MODAL_CONSTANTS from 'modals/EventModal/constants'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { handleEntityResponse } from 'ddiForm/MasterScreen/sagas'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { tryChangeFormTab, updateFormTitle, setField } from 'ddiForm/actions'
import {
  CLEAR_SEARCH,
  EXACT_MATCH_SEARCH
} from 'components/Search/IndexSearch/constants'
import eventModalSagas from 'modals/EventModal/sagas'
import { addNewActivity } from 'modals/EventModal/actions'
import { listActivitiesProcess } from 'components/MasterScreen/Activities/sagas'
import attachmentsSagas from 'components/MasterScreen/Attachments/sagas'

import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal, warningModal } from 'modals/sagas'
import {
  listAuditsProcess,
  additionalAuditsDataFlagListener
} from 'components/MasterScreen/Audit/sagas'

import { tryGetModule } from 'pages/Layout/sagas'
import { add, setPageTitle } from 'pages/Layout/actions'
// import { initializeScreen, openScreenProcess } from 'access/accessOverrideMiddleware'
import { openScreen as openScreenAction } from 'pages/Main/actions'

import { api } from 'services'
import { getFormSelector, getMapResponse, mapResponse } from 'ddiForm/utils'
import * as DOC_SEARCH_CONSTANTS from 'components/Search/DocumentSearch/constants'

import * as actions from './actions'
import * as CONSTANTS from './constants'
import AddPaymentProfile from './modals/AddPaymentProfile'
import { getErrorMessages } from './utils'

const getDataId = formState =>
  getIn(formState, 'fields.dataId.value') || getIn(formState, 'values.dataId')

export function* exactMatchProcess(form, payload) {
  const formState = yield select(getFormSelector(form))

  const prevDataId = getIn(formState, 'values.dataId') || ''
  const prevParent = getIn(formState, 'values.parent.parentId') || ''
  const result = payload.exactMatchResults

  const parent = {
    parentId: result.parentId,
    parentType: result.parentType
  }

  const isSameContact =
    prevDataId === result.dataId && prevParent === result.parentId

  if (!isSameContact) {
    const updateTitleMethod = getIn(formState, 'updateTitleMethod')
    const title = yield call(updateTitleMethod, payload.exactMatchResults)

    yield put(setPageTitle(title))

    yield put(actions.setParent(form, parent))
  }
}
export function* exactMatchSearchSuccessListener(formListener) {
  while (true) {
    const action = yield take(EXACT_MATCH_SEARCH.SUCCESS)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener && payload.exactMatchResults) {
      yield fork(exactMatchProcess, form, payload)
    }
  }
}

export function* entitySuccessProcess(form, payload, screenOpen = false) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value') || ''
  const selectedPrimaryTab =
    getIn(formState, 'masterOptions.selectedPrimaryTab') || null

  // initial open of screen or empty screen
  // debugger
  if (screenOpen) {
    yield fork(changeTabProcess, form, payload)
  }

  if (selectedPrimaryTab === 'activities') {
    // refresh activities list

    const activitiesApiParams = yield call(
      getListActivitiesApiParams,
      form,
      false
    )

    yield fork(listActivitiesProcess, activitiesApiParams)
  } else if (selectedPrimaryTab === 'audit' && payload?.audit) {
    const groupNames = ['audit']
    const response = mapResponse({
      response: payload,
      tabIds: groupNames,
      formState,
      groupNames
    })

    yield put(actions.refreshAudits(form, response))
  }
}

export function* entitySuccessListener(formListener) {
  while (true) {
    const action = yield take(MASTER_CONSTANTS.GET_ENTITY.SUCCESS)
    const {
      meta: { form, screenOpen },
      payload
    } = action
    if (form === formListener) {
      yield fork(entitySuccessProcess, form, payload, screenOpen)
    }
  }
}

export function* changeTabProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  debugger
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  const isNotesContact = getIn(formState, 'values.isNotesContact') || false
  const isCustomerContact =
    getIn(formState, 'values.isCustomerContact') || false
  const newMode = getIn(formState, 'newMode') || false
  // const hasCustomerLedgerAccess = getIn(payload, 'hasCustomerLedgerAccess')
  const hasCustomerLedgerAccess =
    getIn(formState, 'values.hasCustomerLedgerAccess') || false
  const parentType = getIn(formState, 'values.parent.parentType')
  const isActivitiesSelected = selectedPrimaryTab === 'activities'

  if (!newMode) {
    // debugger
    // if (payload.parent && payload.parent.parentType) {
    if (parentType) {
      // const parentType = payload.parent.parentType
      const preSelectedPrimaryTab = yield select(state =>
        getIn(state, 'layout.screens.contact.data.selectedPrimaryTab')
      )
      /*
            this routine accomodates opening A/R Notes from Customer Ledger,
            which is different than selecting that same A/R Note from the
            Contact search dropdown
          */
      // if (preSelectedPrimaryTab && isNotesContact) {
      if (preSelectedPrimaryTab) {
        yield put(tryChangeFormTab(form, preSelectedPrimaryTab))
      } else {
        // debugger
        yield put(
          tryChangeFormTab(
            form,
            isCustomerContact && hasCustomerLedgerAccess ? 'analysis' : 'setup'
          )
        )
      }
    } else if (isActivitiesSelected && isNotesContact) {
      yield put(tryChangeFormTab(form, 'activities'))
    }
  }

  return null
}

export function* wrapAuditProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentId = getIn(formState, 'fields.dataId.parent.recordName')
    ? getIn(formState, 'fields.dataId.parent.recordName')
    : getIn(formState, 'values.parent.parentId')

  let parentType = getIn(formState, 'fields.dataId.parent.recordType')
    ? getIn(formState, 'fields.dataId.parent.recordType')
    : getIn(formState, 'values.parent.parentType')

  parentType = parentType ? parentType[0] : ''

  const showAll = getIn(formState, 'fields.showAllAudits.value') || false
  yield fork(listAuditsProcess, form, {
    dataId,
    parentId,
    parentType,
    groupNames: ['setup', 'audit'],
    showAll
  })
}
export function* deleteContactListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.DELETE_CONTACT.TRY)

    if (form === formListener) {
      yield fork(deleteContactProcess, form)
    }
  }
}

export function* deleteContactProcess(form) {
  const formState = yield select(getFormSelector(form))
  const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'values.parent.parentId')
  const dataId = getDataId(formState)
  const firstName = getIn(formState, 'values.firstName')

  yield put(actions.deleteContact.request(form))

  yield call(
    confirmationModal,
    `Are you sure you want to delete Contact - ${dataId} "${firstName}"?`,
    'Delete?'
  )
  const { type } = yield take([CONFIRMED, CANCELED])

  if (type === CONFIRMED) {
    const { response, error } = yield call(api.deleteContact, {
      dataId,
      groupNames: ['setup'],
      parentId,
      parentType
    })
    if (response) {
      const layout = yield select(state => getIn(state, 'layout.openScreens'))

      if (getIn(layout, 'customerMaster')) {
        const R = yield call(api.getCustomer, {
          dataId: parentId,
          groupNames: ['contacts']
        })

        if (R.response) {
          yield put(
            masterActions.getEntityAsync.success(
              {
                ...R.response,
                contacts: R.response?.contacts?.contacts
              },
              'customerMaster'
            )
          )
        } else {
          yield put(
            masterActions.getEntityAsync.failure('customerMaster', error)
          )
        }
      }
      yield put(actions.deleteContact.success(response, form))
      yield put(masterActions.resetMasterFields(form))
      yield put(setPageTitle('Contact Master'))
    } else {
      yield put(actions.deleteContact.failure(error, form))
    }
  }
}

export function* getAnalysisProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  // const parentId = getIn(formState, 'values.parent.parentId')
  // const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'fields.dataId.parent.recordName')
    ? getIn(formState, 'fields.dataId.parent.recordName')
    : getIn(formState, 'values.parent.parentId')

  const parentType = getIn(formState, 'fields.dataId.parent.recordType')
    ? getIn(formState, 'fields.dataId.parent.recordType')
    : getIn(formState, 'values.parent.parentType')

  if (!dataId) {
    return
    // throw new Error('no dataId')
  }
  debugger
  try {
    yield put(actions.getAnalysis.request(form))
    debugger
    const { response, error } = yield call(api.getContact, {
      dataId,
      parentId,
      parentType,
      groupNames: ['analysis'],
      summary: true
    })

    if (response) {
      const screen = yield select(getFormSelector(form))
      // check if screen is still open
      if (!screen) {
        // NOTE: temp fix for when closing contact on edit. -LL
        // should stop api call when exiting screen
        yield put(actions.getAnalysis.failure(form))
        throw new Error('screen not opened')
      }
      yield put(actions.getAnalysis.success(response, form))
    } else {
      yield put(tryChangeFormTab(form, 'setup'))
      yield put(actions.getAnalysis.failure({ error }, form))
    }

    // if (response) {
    //   return true
    // }
  } catch (e) {
    debugger
    throw new Error(e)
  }
}

export function* getPaymentProfilesProcess(form) {
  const formState = yield select(getFormSelector(form))
  const parentId = getIn(formState, 'values.parent.parentId')
  const parentType = getIn(formState, 'values.parent.parentType')
  const dataId = getDataId(formState)

  yield put(actions.getPaymentProfiles.request(form))

  const { response, error } = yield call(api.getContact, {
    dataId,
    parentId,
    parentType,
    groupNames: ['vault']
  })

  yield fork(putPaymentProfiles, { form, response, error })
}

export function* putPaymentProfiles({ meta, response, error, form }) {
  // const formState = yield select(getFormSelector(form))
  if (response) {
    yield put(actions.getPaymentProfiles.success(response, meta || form))
  } else {
    yield put(actions.getPaymentProfiles.failure(error, meta || form))
  }
}
export function* getListActivitiesApiParams(form, isSummary = false) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  // const parentId = getIn(formState, 'values.parent.parentId')
  // const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'fields.dataId.parent.recordName')
    ? getIn(formState, 'fields.dataId.parent.recordName')
    : getIn(formState, 'values.parent.parentId')

  let parentType = getIn(formState, 'fields.dataId.parent.recordType')
    ? getIn(formState, 'fields.dataId.parent.recordType')
    : getIn(formState, 'values.parent.parentType')

  parentType = parentType ? parentType[0] : ''

  return {
    form,
    dataId,
    parentId,
    parentType,
    groupNames: ['setup', 'activities'],
    summary: isSummary
  }
}

export function* refreshActivitiesListener(formListener) {
  const channel = yield actionChannel([
    EVENT_MODAL_CONSTANTS.SAVE_ACTIVITY.SUCCESS,
    EVENT_MODAL_CONSTANTS.CLOSE_ACTIVITY_MODAL,
    EVENT_MODAL_CONSTANTS.DELETE_ACTIVITY.SUCCESS
  ])

  while (true) {
    const action = yield take(channel)
    /* destructuring this action does not work here for some reason */
    // const {
    //   meta: { form }
    // } = action

    if (action?.meta?.form === formListener) {
      const formState = yield select(getFormSelector(action.meta.form))
      const selectedPrimaryTab = getIn(
        formState,
        'masterOptions.selectedPrimaryTab'
      )
      const isSummary = selectedPrimaryTab === 'analysis'

      const activitiesApiParams = yield call(
        getListActivitiesApiParams,
        formListener,
        isSummary
      )

      yield fork(listActivitiesProcess, activitiesApiParams)
    }
  }
}

// export function* refreshPaymentInfosListener(formListener) {
//   while (true) {
//     const {
//       meta: { form }
//     } = yield take(CONSTANTS.REMOVE_PAYMENT_SOURCE.SUCCESS)

//     if (form === formListener) {
//       yield fork(getPaymentProfilesProcess, form)
//     }
//   }
// }

export function* addRowButtonListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName }
    } = yield take(DDICONSTANTS.ADD_BLANK_ROW)
    if (form === formListener && propertyName === 'activities') {
      yield put(addNewActivity(form, { selectedStartDate: new Date() }))
    }
  }
}

// export function* addPaymentSourceListener(formListener) {
//   while (true) {
//     const {
//       meta: { form }
//     } = yield take(CONSTANTS.ADD_PAYMENT_SOURCE.TRY)

//     if (form === formListener) {
//       yield call(addPaymentSourceProcess, form)
//     }
//   }
// }

// export function* addPaymentSourceProcess(form) {
//   const formState = yield select(state => getIn(state, `ddiForm.${form}`))
//   const dataId = getIn(formState, 'fields.dataId.value')

//   // const uri = yield call(api.getPaymentProfilesUri)
// }

export function* removePaymentSourceListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.REMOVE_PAYMENT_SOURCE.TRY)

    if (form === formListener) {
      yield fork(removePaymentSourceProcess, formListener, payload)
    }
  }
}

export function* removePaymentSourceProcess(form, payload) {
  const { token } = payload
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentId = getIn(formState, 'values.parent.parentId')
  const parentType = getIn(formState, 'values.parent.parentType')

  yield call(confirmationModal, 'Remove Card', 'Are you sure?')
  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    const { response, error } = yield call(api.removePaymentSource, {
      dataId,
      parentId,
      parentType,
      token
    })

    if (response) {
      yield put(actions.removePaymentSource.success(response, form))
      yield put(actions.getPaymentProfiles.success(response, form))
    } else {
      yield put(actions.removePaymentSource.failure(error, form))
    }
  }
}

export function* clearSelectedPaymentProcess(form) {
  const formState = yield select(getFormSelector(form))
  const selectedPayment = getIn(formState, 'fields.selectedPaymentInfos.value')
    ? getIn(formState, 'fields.selectedPaymentInfos.value')
    : []

  if (selectedPayment.length) {
    yield put(actions.clearSelectedPayment(form, { paymentInfo: [] }))
  }
}

export function* addPaymentProcess(action) {
  const { form } = action.meta
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentId = getIn(formState, 'values.parent.parentId')
  const parentType = getIn(formState, 'values.parent.parentType')
  const { branchId, vaultToken } = action.payload
  const profiles = getIn(formState, 'values.paymentProfiles')
  const merchantKey = profiles.first().get('merchantKey')

  const { response, error } = yield call(api.addPaymentSource, {
    branchId,
    dataId,
    token: { id: vaultToken, merchantKey },
    parentId,
    parentType
  })
  yield fork(putPaymentProfiles, { meta: action.meta, response, error })
  const selectedPaymentAccount = getIn(
    formState,
    'fields.selectedPaymentAccount.value'
  )
  const data = response.gatewayAccounts.find(
    x => x.account === selectedPaymentAccount
  )

  yield put(actions.setSelectedPayment(data))
}

export function* addPaymentListener() {
  while (true) {
    const action = yield take(CONSTANTS.ADD_PAYMENT_SOURCE.TRY)
    yield fork(addPaymentProcess, action)
  }
}

export function* getPaymentProfilesUriProcess(formListener, action) {
  const { paymentInfo, rowId, ...rest } = action.payload
  const modalOpts = {
    component: AddPaymentProfile,
    props: {
      data: {
        // uri: response.uri,
        form: formListener,
        ...rest
      },

      width: 750,
      height: 700,
      overflow: 'hidden',
      title: 'Process Credit Card'
    }
  }
  // debugger
  const modal = yield call(addModal, formListener, modalOpts)
  yield put(modal)
}

export function* getPaymentProfilesUriListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_PAYMENT_PROFILES_URI.TRY)
    yield fork(getPaymentProfilesUriProcess, formListener, action)
  }
}

export function* lockForEditListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.LOCK_CONTACT_FOR_EDIT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(lockForEditProcess, form)
    }
  }
}

export function* lockForEditProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'values.parent.parentId')
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (selectedPrimaryTab !== 'setup') {
    // yield put(tryChangeFormTab(form, 'setup', 'information'))

    yield put(tryChangeFormTab(form, 'setup'))
  }

  yield put(masterActions.lockForEditAsync.request(form))

  const { response, error } = yield call(api.lockForEdit, {
    recordName: dataId,
    form,
    parentId,
    parentType,
    groupNames: ['setup']
  })

  if (response) {
    // update for mapResponse logic.
    const mapResponse = getMapResponse({ formState })
    const res = mapResponse({ response, tabs: ['setup'], formState })
    yield putResolve(masterActions.lockForEditAsync.success(res, { form }))
  } else {
    yield put(masterActions.lockForEditAsync.failure(error, { form }))
  }
}

export function* cancelEditListener(formListener) {
  while (true) {
    const action = yield take(MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS)
    // const action = yield take(CONSTANTS.CANCEL_CONTACT_EDIT.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(cancelEditProcess, form)
    }
  }
}

export function* cancelEditProcess(form) {
  const formState = yield select(getFormSelector(form))
  const isCustomerContact = getIn(formState, 'values.isCustomerContact')
  const hasCustomerLedgerAccess = getIn(
    formState,
    'values.hasCustomerLedgerAccess'
  )
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )

  if (
    selectedPrimaryTab !== 'setup' &&
    !isCustomerContact &&
    !hasCustomerLedgerAccess
  ) {
    yield put(tryChangeFormTab(form, 'setup'))
  } else if (isCustomerContact && hasCustomerLedgerAccess) {
    yield put(tryChangeFormTab(form, 'analysis'))
  } else if (
    selectedPrimaryTab !== 'setup' &&
    isCustomerContact &&
    !hasCustomerLedgerAccess
  ) {
    yield put(tryChangeFormTab(form, 'setup'))
  }

  // yield put(masterActions.cancelEditAsync.try(form))
}

export function* onSaveContactProcess(formState, payload, form) {
  const dataId = getDataId(formState)
  const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'values.parent.parentId')
  const isCustomerContact = getIn(formState, 'values.isCustomerContact')
  const hasCustomerLedgerAccess = getIn(
    formState,
    'values.hasCustomerLedgerAccess'
  )
  const newMode = getIn(formState, 'newMode') || false
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  // for modals
  const { id } = payload
  // const state = formState.toJS()

  const editedFields = getIn(formState, 'editedFields').toJS()

  let params
  if (editedFields && Array.isArray(editedFields) && editedFields.length) {
    params = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      const value = getIn(field, 'rowData') || getIn(field, 'value')
      acc[next] = toJS(value)
      return acc
    }, {})
  }

  params = {
    dataId,
    groupNames: [selectedPrimaryTab, selectedSecondaryTab],
    parentId,
    parentType,
    properties: { ...params }
  }

  yield put(actions.saveContact.request())

  const { response, error } = yield call(api.saveContact, params)

  if (response) {
    // yield put(actions.saveContact.success(response, 'customerMaster'))

    yield put({
      // meta: { form: newMode ? 'customerMaster.contact' : 'contact' },
      meta: { form },
      payload: { ...response },
      type: DDICONSTANTS.SAVE.SUCCESS
    })

    yield fork(handleEntityResponse, 'contact', response)

    if (newMode && id && isCustomerContact) {
      yield put(removeModal('customerMaster', id))
    } else {
      yield put(
        tryChangeFormTab(
          'contact',
          isCustomerContact && hasCustomerLedgerAccess ? 'analysis' : 'setup'
        )
      )
    }
  } else {
    const { status, type, validationErrors, message, title } = error
    if (status && status === 498) {
      const errorMessages = getErrorMessages(validationErrors)
      yield call(warningModal, errorMessages, 'Attention!')
    }

    if (status && status === 496) {
      yield call(warningModal, message, title)
    }

    yield put(actions.saveContact.failure())
  }
}

export function* onContactSave(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SAVE_CONTACT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      if (!form.includes('.contact')) {
        const formState = yield select(getFormSelector(form))
        yield fork(onSaveContactProcess, formState, payload, form)
      } else {
        yield fork(saveContactFromModal, form, payload)
      }
    }
  }
}

export function* copyListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.HANDLE_COPY_PARENT.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(copyProcess, form, payload)
    }
  }
}

export function* copyProcess(form, payload) {
  const { newMode } = payload
  const parent = payload?.parent?.toJS ? payload.parent.toJS() : null
  const newPayload = { ...payload, parent }

  if (newMode && form.includes('customerMaster')) {
    yield put(actions.handleCopyParent.success(newPayload, 'customerMaster'))
  } else if (newMode && form.includes('salesOrder')) {
    const newForm = form.split('.')[0]
    yield put(actions.handleCopyParent.success(newPayload, newForm))
  } else {
    yield put(actions.handleCopyParent.success(newPayload, form))
  }
}

export function* unlockContactProcess(form, action) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentId = getIn(formState, 'values.parent.parentId')
  const parentType = getIn(formState, 'values.parent.parentType')

  if (!dataId) {
    return
  }

  yield call(api.cancelEdit, {
    dataId,
    form: 'contact',
    parentId,
    parentType
  })
}

export function* unlockContactRecordListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.UNLOCK_CONTACT_RECORD.TRY)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(unlockContactProcess, form, action)
    }
  }
}

export function* setDefaultChartViewListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.SET_DEFAULT_CHART_VIEW.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      yield fork(setDefaultChartViewProcess, form, payload)
    }
  }
}

export function* setDefaultChartViewProcess(form, payload) {
  const { view } = payload

  yield put(actions.setDefaultChartView.request())

  // const { response, error } = yield call(api.setDefaultChartView, { view })

  yield call(api.setDefaultChartView, { view })

  yield put(actions.setDefaultChartView.success({ view }, form))
}

export function* clearSearchListener(formListener) {
  while (true) {
    const action = yield take(CLEAR_SEARCH)
    const {
      meta: { form },
      payload: { propertyName }
    } = action

    if (form === formListener && propertyName === 'dataId') {
      yield put(setPageTitle('Contact Master'))
      yield put(updateFormTitle(form, 'Contact Master'))
    }
  }
}

export function* openScreenListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_SCREEN.TRY)
    const {
      meta: { form }
      // payload
    } = action

    if (form === formListener) {
      yield fork(openScreenProcess, form, action)
    }
  }
}

export function* openScreenProcess(form, action) {
  const { payload } = action
  const formState = yield select(getFormSelector(payload.name))
  const screen = yield call(tryGetModule, payload.title)

  if (formState) {
    // if already open, change layout ta to screen
    // for now...
    yield putResolve(
      add({
        component: screen.default ? screen.default : screen,
        data: { ...payload },
        allowMultiple:
          screen.options && screen.options.allowMultiple
            ? !!screen.options.allowMultiple
            : false,
        ...payload
      })
    )
  } else {
    yield put(openScreenAction(payload))
  }
}

export function* getContactImage(form, fileName) {
  const route = form.includes('.') ? form.split('.').pop() : form

  const { response, error } = yield call(api.documentDownload, {
    fileName,
    form: route,
    documentType: 'image'
  })

  if (response && response.content) {
    yield put(actions.updatePhoto(form, { base64: response.content }))
  }

  return null
}

/* listen for a change to the contact image */
export function* contactImageChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, base64, result },
      type
    } = yield take([
      DOC_SEARCH_CONSTANTS.HANDLE_FILE_UPLOAD.SUCCESS,
      DOC_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS
    ])

    if (
      form === formListener &&
      propertyName === 'imageURL' &&
      base64 &&
      type === DOC_SEARCH_CONSTANTS.HANDLE_FILE_UPLOAD.SUCCESS
    ) {
      yield put(actions.updatePhoto(form, { base64 }))
    }

    if (
      form === formListener &&
      propertyName === 'imageURL' &&
      result &&
      result.dataId &&
      type === DOC_SEARCH_CONSTANTS.EXACT_MATCH_SEARCH.SUCCESS
    ) {
      /*
        if the user has selected a previously existing image,
        we need to fetch that image data
      */
      yield fork(getContactImage, form, result.dataId)
    }
  }
}

/* flip the image back if needed */
export function* cancelEditSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { imageBase64String = null }
    } = yield take(MASTER_CONSTANTS.CANCEL_EDIT.SUCCESS)

    if (form === formListener) {
      yield put(actions.updatePhoto(form, { base64: imageBase64String }))
    }
  }
}

export function* getRecordArgumentsSaga(form) {
  const formState = yield select(getFormSelector(form))
  let dataId = getDataId(formState)
  const parentId = getIn(formState, 'fields.dataId.parent.recordName')
    ? getIn(formState, 'fields.dataId.parent.recordName')
    : getIn(formState, 'values.parent.parentId')

  let parentType = getIn(formState, 'fields.dataId.parent.recordType')
    ? getIn(formState, 'fields.dataId.parent.recordType')
    : getIn(formState, 'values.parent.parentType')

  parentType = parentType ? parentType[0] : ''

  if (dataId.includes('|')) {
    dataId = dataId.split('|')[2]
  }

  return {
    // groupNames: ['setup', 'audit'],
    dataId,
    parentType,
    parentId,
    showAll: getIn(formState, 'fields.showAllAudits.value') || false
  }
}

export function* saveContactFromModal(form, payload) {
  const formState = yield select(getFormSelector(form))
  const dataId = getDataId(formState)
  const parentType = getIn(formState, 'values.parent.parentType')
  const parentId = getIn(formState, 'values.parent.parentId')
  const isCustomerContact = getIn(formState, 'values.isCustomerContact')
  const newMode = getIn(formState, 'newMode') || false

  const { id } = payload

  const editedFields = getIn(formState, 'editedFields').toJS()

  let params
  if (editedFields && Array.isArray(editedFields) && editedFields.length) {
    params = editedFields.reduce((acc, next) => {
      const field = getIn(formState, `fields.${next}`)
      const value = getIn(field, 'rowData') || getIn(field, 'value')
      acc[next] = toJS(value)
      return acc
    }, {})
  }

  params = {
    dataId,
    groupNames: ['setup'],
    parentId,
    parentType,
    properties: { ...params }
  }

  yield put(actions.saveContact.request(form))

  const { response, error } = yield call(api.saveContact, params)

  if (response) {
    const newForm = form.split('.')[0]
    if (newForm.includes('salesOrder')) {
      const parentFormState = yield select(getFormSelector(newForm))
      const contactReferenceField =
        getIn(parentFormState, 'contactReferenceField') || 'orderedById'

      yield put(setField(newForm, contactReferenceField, response.dataId))

      if (contactReferenceField === 'checkoutContact' && response.description) {
        yield put(
          setField(newForm, 'checkoutContactDescription', response.description)
        )
      }
    }

    yield put(actions.saveContact.success(response, form))
    yield put(masterActions.resetMasterFields(form))

    if (newMode && id && isCustomerContact) {
      yield put(removeModal(newForm, id))
    }

    if (newMode && id && newForm === 'vendorMaster') {
      yield put(removeModal(newForm, id))
    }
  } else {
    yield put(actions.saveContact.failure(error, form))
  }
}

export default function* sagas(form) {
  yield fork(exactMatchSearchSuccessListener, form)

  /* for the activities */
  yield fork(eventModalSagas, form)
  yield fork(refreshActivitiesListener, form)

  yield fork(deleteContactListener, form)
  yield fork(entitySuccessListener, form)
  yield fork(addRowButtonListener, form)
  // yield fork(refreshPaymentInfosListener, form)
  yield fork(addPaymentListener, form)
  yield fork(removePaymentSourceListener, form)
  yield fork(getPaymentProfilesUriListener, form)
  // yield fork(getPaymentProfilesUriListener, form)
  yield fork(lockForEditListener, form)
  // yield fork(cancelEditListener, form)
  yield fork(additionalAuditsDataFlagListener, form)
  yield fork(copyListener, form)
  yield fork(onContactSave, form)
  yield fork(unlockContactRecordListener, form)
  yield fork(setDefaultChartViewListener, form)
  yield fork(clearSearchListener, form)
  // yield fork(entityFailureListener, form)

  // yield fork(test, form)

  yield fork(openScreenListener, form)
  yield fork(contactImageChangeListener, form)
  yield fork(cancelEditSuccessListener, form)
  yield fork(attachmentsSagas, form)
}
