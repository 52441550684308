/* eslint import/prefer-default-export: 0 */
import {
  call,
  fork,
  put,
  select,
  take,
  actionChannel
} from 'redux-saga/effects'
import { getIn, is } from 'utils'
import { changeFormTab, exit } from 'ddiForm/actions'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { handleEntityResponse } from 'ddiForm/MasterScreen/sagas'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { confirmationModal } from 'modals/sagas'

import {
  getFormSelector,
  getSelectedTabsFromState,
  getMapResponse
} from 'ddiForm/utils'
import * as api from './api'
import * as CONSTANTS from './constants'

export function* readTemplateProcess(templateKey, form, templateApiParams) {
  yield put({
    type: CONSTANTS.READ_TEMPLATE.REQUEST,
    meta: { form, apiRequest: true }
  })

  if (form !== 'shipVia') {
    if (form === 'salespersonMaster') {
      yield put(changeFormTab(form, 'setup', 'information'))
    } else {
      yield put(changeFormTab(form, 'setup', 'main'))
    }
  }

  yield put(resetMasterFields(form, ['dataId']))

  const formState = yield select(getFormSelector(form))
  const groupNames = getSelectedTabsFromState(formState)
  const additionalApiParams =
    templateApiParams && is.fn(templateApiParams)
      ? yield call(templateApiParams, form)
      : {}

  const { response, error } = yield call(api.readTemplate, {
    form,
    templateKey,
    groupNames,
    ...additionalApiParams
  })

  // debugger

  if (response) {
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })
    const res = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    yield put({
      type: MASTER_CONSTANTS.GET_ENTITY.SUCCESS,
      payload: {
        ...res,
        templateKey
      },
      meta: { form }
    })

    yield fork(handleEntityResponse, form, response)
    /* show only the template fields */
  } else {
    yield put({
      type: MASTER_CONSTANTS.GET_ENTITY.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* readTemplateListener(formListener, templateApiParams = null) {
  const channel = yield actionChannel(CONSTANTS.READ_TEMPLATE.TRY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form },
      payload: { templateKey }
    } = action
    // debugger

    if (formListener === form) {
      debugger
      yield fork(readTemplateProcess, templateKey, form, templateApiParams)
    }
  }
}

export function* lockTemplateProcess(form, templateApiParams = null) {
  const formState = yield select(getFormSelector(form))
  const templateKey = getIn(formState, 'values.templateKey')
  const groupNames = getSelectedTabsFromState(formState)
  const additionalApiParams =
    templateApiParams && is.fn(templateApiParams)
      ? yield call(templateApiParams, form)
      : {}

  if (!templateKey) {
    return
  }

  yield put({
    type: CONSTANTS.LOCK_TEMPLATE.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.lockTemplate, {
    form,
    templateKey,
    groupNames,
    ...additionalApiParams
  })
  // debugger

  if (response) {
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })
    const res = mapResponse({
      response: response.record ? response.record : response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    yield put({
      type: CONSTANTS.LOCK_TEMPLATE.SUCCESS,
      payload: {
        ...res,
        templateKey
      },
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.LOCK_TEMPLATE.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* lockTemplateListener(formListener, templateApiParams = null) {
  const channel = yield actionChannel(CONSTANTS.LOCK_TEMPLATE.TRY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      // debugger
      yield fork(lockTemplateProcess, form, templateApiParams)
    }
  }
}

export function* unlockTemplateProcess(
  form,
  templateApiParams = null,
  isExit = false
) {
  const formState = yield select(getFormSelector(form))
  const templateKey = getIn(formState, 'values.templateKey')
  let groupNames = getSelectedTabsFromState(formState)
  const additionalApiParams =
    templateApiParams && is.fn(templateApiParams)
      ? yield call(templateApiParams, form)
      : {}

  if (!templateKey) {
    return
  }

  yield put({
    type: CONSTANTS.PRE_UNLOCK_TEMPLATE,
    meta: { form }
  })

  yield put({
    type: CONSTANTS.UNLOCK_TEMPLATE.REQUEST,
    meta: { form, apiRequest: true }
  })

  if (form === 'vendorMaster' && groupNames.includes('dropShipCharges')) {
    /* not the same group name as what is listed on the tab name -- SVE 9/28/21 */
    groupNames = [
      ...groupNames.filter(x => x !== 'dropShipCharges'),
      'dropShip'
    ]
  }

  const { response, error } = yield call(api.unlockTemplate, {
    form,
    templateKey,
    groupNames,
    ...additionalApiParams
  })

  if (response) {
    const mapResponse = getMapResponse({ formState, tabIds: groupNames })
    const res = mapResponse({
      response: response.record ? response.record : response,
      tabIds: groupNames,
      formState,
      groupNames
    })
    // debugger

    yield put({
      type: CONSTANTS.UNLOCK_TEMPLATE.SUCCESS,
      payload: {
        ...res,
        templateKey
      },
      meta: { form }
    })

    if (isExit) {
      // debugger
      yield put(exit(form))
    }
  } else {
    yield put({
      type: CONSTANTS.UNLOCK_TEMPLATE.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* unlockTemplateListener(
  formListener,
  templateApiParams = null
) {
  const channel = yield actionChannel(CONSTANTS.UNLOCK_TEMPLATE.TRY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield call(
        confirmationModal,
        'All changes will be lost. Continue?',
        'Cancel?'
      )

      const tplAction = yield take([CONFIRMED, CANCELED])
      if (tplAction.type === CONFIRMED) {
        yield fork(unlockTemplateProcess, form, templateApiParams)
      }
    }
  }
}

export function* deleteTemplateProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const description = getIn(formState, 'fields.description.value')
  const templateKey = getIn(formState, 'values.templateKey')
  const groupNames = getSelectedTabsFromState(formState)

  const prefixMapping = {
    customerMaster: 'Customer',
    productMaster: 'Product'
  }

  const warningMessage = `Are you sure you want to delete "${description}"`

  yield call(confirmationModal, warningMessage, 'Delete?')
  const action = yield take([CONFIRMED, CANCELED])

  if (action.type === CONFIRMED) {
    yield put({
      type: CONSTANTS.DELETE_TEMPLATE.REQUEST,
      meta: { form, apiRequest: true }
    })

    const { response, error } = yield call(api.deleteTemplate, {
      templateKey,
      form
    })
    // debugger

    if (response) {
      const mapResponse = getMapResponse({ formState, tabIds: groupNames })
      const res = mapResponse({
        response,
        tabIds: groupNames,
        formState,
        groupNames
      })

      yield put({
        type: CONSTANTS.DELETE_TEMPLATE.SUCCESS,
        payload: {
          ...res,
          templateKey
        },
        meta: { form }
      })

      yield put({
        type: MASTER_CONSTANTS.RESET_MASTER_FIELDS,
        meta: { form }
      })
    } else {
      yield put({
        type: CONSTANTS.DELETE_TEMPLATE.FAILURE,
        payload: error,
        meta: { form }
      })
    }
  }
}

export function* deleteTemplateListener(formListener) {
  const channel = yield actionChannel(CONSTANTS.DELETE_TEMPLATE.TRY)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(deleteTemplateProcess, form)
    }
  }
}

export function* dispatchExitTemplateProcess(form, templateApiParams) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  // debugger

  if (isEditing) {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Cancel?'
    )

    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      yield fork(unlockTemplateProcess, form, templateApiParams, true)
    }
  } else {
    // debugger
    yield put(exit(form))
  }
}

export function* exitTemplateListener(formListener, templateApiParams) {
  const channel = yield actionChannel(CONSTANTS.EXIT_TEMPLATE)
  while (true) {
    const action = yield take(channel)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      // debugger
      yield fork(dispatchExitTemplateProcess, form, templateApiParams)
    }
  }
}

export default function* templateSagas(form, templateApiParams = null) {
  yield fork(readTemplateListener, form, templateApiParams)
  yield fork(lockTemplateListener, form, templateApiParams)
  yield fork(unlockTemplateListener, form, templateApiParams)
  yield fork(deleteTemplateListener, form)
  yield fork(exitTemplateListener, form, templateApiParams)
}
