import React from 'react'
// import { Button, Icon } from '@material-ui/core'
import DDIExpansionPanel from 'components/DDIExpansionPanel'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import DDIARInvoiceSearch from 'ddiForm/wrapped/DDIARInvoiceSearch'
import { fromJS, getRgb, getIn, getValue, empty, formatDate } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { renderShipToAddress } from 'pages/SalesOrder/utils'
import { getScreenOpeningParams } from 'pages/InvoiceInquiry/utils'
import addShipToInModal from 'components/addShipToInModal'
// import usePrevious from 'hooks/usePrevious'
// import { clearAdditionalData as clearAdditionalDataAction } from 'pages/InvoiceInquiry/actions'
import OrderLink from './OrderLink'
import PopoverInfoChip from './PopoverInfoChip'
import TooltipWrapper from './TooltipWrapper'

const OrderLinkWithTooltip = TooltipWrapper({
  type: 'salesOrder'
})(OrderLink)

const PopoverInfoChipWithTooltip = TooltipWrapper({
  type: 'indexSearch'
})(PopoverInfoChip)

export const SearchArea = ({
  backOrderCycle,
  branchId,
  branchDescription,
  currentStatusDescriptionResolved,
  currentStatusColorMap,
  customerId,
  customerName,
  dataId,
  form,
  orderId,
  orderedById,
  orderedByName,
  poNumber,
  shipToId,
  shipToDescription,
  shipToAddressMap,
  shipToEmail,
  shipToFax,
  shipToPhone,
  shipDate,
  shipViaId,
  shipViaDescription,
  warehouseId,
  warehouseDescription,
  writerId,
  writerDescription
}) => {
  const dispatch = useDispatch()
  // const prevDataId = usePrevious(dataId)

  // useEffect(() => {
  //   if (prevDataId && dataId !== prevDataId) {
  //     dispatch(clearAdditionalDataAction(form))
  //   }
  // }, [dataId, prevDataId])

  const currentStatusColor = currentStatusColorMap?.toJS
    ? getRgb(currentStatusColorMap.toJS(), 0)
    : '#444'

  const shipToAddress = shipToAddressMap?.toJS
    ? shipToAddressMap.toJS()
    : { address1: '', address2: '', cityStateZip: '' }

  const { address1 = '', address2 = '', cityStateZip = '' } = shipToAddress

  const showInCustomerPopover = renderShipToAddress(
    shipToAddress,
    shipToPhone,
    customerId,
    shipToEmail,
    shipToFax
  )

  const openExternalScreen = (id, screen) => {
    if (!id || !screen) {
      return
    }
    const params = getScreenOpeningParams(id, screen)
    dispatch(openScreenAction(params))
  }

  const openShipToMaster = () => {
    if (customerId && shipToId) {
      dispatch(
        addShipToInModal(
          {
            dataId: shipToId,
            name: 'customerShipTo',
            title: 'Customer Ship To',
            parentId: customerId,
            groupNames: ['setup']
          },
          form
        )
      )
    }
  }

  const openContactMaster = () => {
    if (customerId && orderedById) {
      dispatch(
        openScreenAction({
          image: 'default-inform-icon-16.png',
          dataId: orderedById,
          name: 'contact master',
          title: `Contact - ${orderedById} "${orderedByName}"`,
          parentType: 'C',
          parentId: customerId,
          groupNames: ['setup']
        })
      )
    }
  }

  return (
    <DDIExpansionPanel
      title="Invoice Inquiry"
      titleIcon="receipt"
      expansionPanelContentStyle={{
        padding: 0,
        width: '100%'
      }}
      style={{ border: 0 }}
    >
      <div
        className="invoice-inquiry-search-area-wrapper"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          padding: 20,
          width: '100%',
          maxWidth: '100%'
        }}
      >
        <div>
          <div style={{ alignItems: 'center', display: 'flex' }}>
            <span style={{ marginRight: 10 }}>Invoice:</span>
            <div style={{ width: 150 }}>
              <DDIARInvoiceSearch
                propertyName="dataId"
                leader
                preventAutoDisable
                disableDoubleClickUnlock
              />
            </div>
          </div>
        </div>
        <div>
          <PopoverInfoChipWithTooltip
            labelName="Customer"
            id={customerId}
            description={customerName}
            action={e => openExternalScreen(customerId, 'customerLedger')}
            actionText="Open Customer Master"
            additionalPopoverInfo={showInCustomerPopover}
            indexSearchType="Customer"
          />
          <PopoverInfoChipWithTooltip
            labelName="Ship To"
            id={shipToId}
            description={shipToDescription}
            action={openShipToMaster}
            actionText="Open Ship To Master"
            indexSearchType="ShipTo"
            tooltipId={`${customerId}!${shipToId}`}
          />
          <PopoverInfoChipWithTooltip
            labelName="Ordered By"
            id={orderedById}
            description={orderedByName}
            action={openContactMaster}
            actionText="Open Contact Master"
            indexSearchType="Contacts"
            tooltipId={`C|${customerId}|${orderedById}`}
          />
          <PopoverInfoChipWithTooltip
            labelName="Branch"
            id={branchId}
            description={
              branchId && branchDescription
                ? `${branchId} - ${branchDescription}`
                : branchDescription
            }
            wrapperStyle={{ marginBottom: 0 }}
            indexSearchType="Branch"
            action={e => openExternalScreen(branchId, 'branch')}
            actionText="Open Branch Master"
          />
        </div>
        <div>
          <PopoverInfoChipWithTooltip
            labelName="Writer"
            id={writerId}
            description={writerDescription}
            indexSearchType="SystemUsers"
          />
          <PopoverInfoChipWithTooltip
            labelName="Ship Via"
            id={shipViaId}
            description={shipViaDescription}
            indexSearchType="ShipVia"
            action={e => openExternalScreen(shipViaId, 'shipVia')}
            actionText="Open Ship Via"
          />
          <PopoverInfoChip
            labelName="Ship Date"
            description={shipDate ? formatDate(shipDate, 'MM/DD/YYYY') : ''}
            hasAvatar={false}
            hasPopover={false}
          />
          <PopoverInfoChipWithTooltip
            labelName="Warehouse"
            id={warehouseId}
            description={warehouseDescription}
            wrapperStyle={{ marginBottom: 0 }}
            indexSearchType="Warehouse"
          />
        </div>
        <div>
          {currentStatusDescriptionResolved ? (
            <div style={{ marginBottom: 10 }}>
              <div
                style={{
                  background: currentStatusColor,
                  borderRadius: 4,
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: 10,
                  minHeight: 27,
                  fontSize: 12,
                  flexDirection: 'row',
                  padding: 5,
                  width: '100%'
                }}
              >
                <span>{currentStatusDescriptionResolved}</span>
              </div>
            </div>
          ) : null}
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
          >
            <span style={{ marginRight: 4, fontSize: '0.9em' }}>Order:</span>
            {orderId ? (
              <OrderLinkWithTooltip
                openExternalScreen={openExternalScreen}
                tooltipId={orderId}
                orderId={orderId}
                type="salesOrder"
              />
            ) : null}
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}
          >
            <span style={{ marginRight: 10, fontSize: '0.9em' }}>
              Customer P/O:
            </span>
            <span>{poNumber}</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10, fontSize: '0.9em' }}>Cycle:</span>
            <strong>{backOrderCycle}</strong>
          </div>
        </div>
      </div>
    </DDIExpansionPanel>
  )
}

export default withContext({
  backOrderCycle: form => getIn(form, 'values.backOrderCycle') || null,
  branchId: form => getIn(form, 'values.branchId') || '',
  branchDescription: form => getIn(form, 'values.branchDescription') || '',
  currentStatusDescriptionResolved: form =>
    getIn(form, 'values.currentStatusDescriptionResolved') || '',
  currentStatusColorMap: form =>
    getIn(form, 'values.currentStatusColor') || fromJS({ r: 68, g: 68, b: 68 }),
  customerId: form => getIn(form, 'values.customerId') || '',
  customerName: form => getIn(form, 'values.customerName') || '',
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  orderId: form => getIn(form, 'values.orderId') || '',
  orderedById: form => getIn(form, 'values.orderedById') || '',
  orderedByName: form => getIn(form, 'values.orderedByName') || '',
  poNumber: form => getIn(form, 'values.poNumber') || '',
  shipToEmail: form => getValue(form, 'shipToEmail', ''),
  shipToFax: form => getValue(form, 'shipToFax', ''),
  shipToPhone: form => getValue(form, 'shipToPhone', ''),

  shipToId: form => getIn(form, 'values.shipToId') || '',
  shipToDescription: form => getIn(form, 'values.shipToDescription') || '',
  shipToAddressMap: form => getValue(form, 'shipToAddress', empty),

  shipViaId: form => getIn(form, 'values.shipViaId') || '',
  shipViaDescription: form => getIn(form, 'values.shipViaDescription') || '',

  shipDate: form => getIn(form, 'values.shipDate') || '',

  writerId: form => getIn(form, 'values.writerId') || '',
  writerDescription: form => getIn(form, 'values.writerDescription') || '',

  warehouseId: form => getIn(form, 'values.warehouseId') || '',
  warehouseDescription: form => getIn(form, 'values.warehouseDescription') || ''
})(SearchArea)
