/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect } from 'react'
import { Label, Field, withContext } from 'ddiForm'
import { getValue, getField, getIn, getMeta, empty } from 'utils'
import { DDIIndexSearch, DDITextField } from 'ddiForm/wrapped'

import { required } from 'ddiForm/validations'

import {
  Button,
  Icon,
  IconButton,
  Paper,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'

import {
  getFastProductCopyFromMeta,
  fastProductCopyFromCancel,
  fastProductCopyFromExecute
} from 'pages/SalesOrder/actions'

import '../../../styles/master-style.scss'

const metaData = {
  allowInvalidValues: true,
  allowInstantSearch: true,
  allowNavigation: false,
  allowSearchAll: false,
  fieldName: 'dataId',
  hasFilters: false,
  hideToggle: true,
  minimumKeywordLength: 0,
  openText: null,
  searchType: 'customer'
}

const FastProductEntryModalHeader = ({
  canCopyFrom,
  dispatch,
  copyFromProduct,
  copyFromInterfaceOpen,
  copyFromDisplayUOM,
  copyFromPriceUOM,
  form,
  isPostingFastProductData,
  indexSearchMetaMap
}) => {
  const indexSearchMeta = indexSearchMetaMap?.toJS
    ? indexSearchMetaMap.toJS()
    : {}

  const [state, setState] = React.useState({
    importProductData: false
  })

  // const handleChange = name => event => {
  //   setState({ ...state, [name]: event.target.checked })
  //   if (!copyFromInterfaceOpen) {
  //     dispatch(getFastProductCopyFromMeta.try(form))
  //   } else {
  //     dispatch(fastProductCopyFromCancel.try(form))
  //   }
  // }

  const openCopyProductsInterface = event => {
    if (!copyFromInterfaceOpen) {
      dispatch(getFastProductCopyFromMeta.try(form))
    } else {
      dispatch(fastProductCopyFromCancel.try(form))
    }
  }

  const dismissCopyInterface = event => {
    setState({ ...state, importProductData: false })
    dispatch(fastProductCopyFromCancel.try(form))
  }

  const executeCopyProduct = event => {
    dispatch(fastProductCopyFromExecute.try(form))
  }

  return (
    <div
      className="fast-product-entry-modal-header-wrapper"
      style={{ fontWeight: 400 }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <h2
          style={{ fontSize: '1.25rem', fontWeight: 500, margin: '-5px 0 0 0' }}
        >
          Fast Product Entry
        </h2>
        {canCopyFrom ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={copyFromInterfaceOpen}
                onChange={openCopyProductsInterface}
              />
            }
            label="Copy Data from Another Product"
          />
        ) : null}
      </div>
      {copyFromInterfaceOpen ? (
        <Paper
          style={{
            background: '#f4f7f9',
            padding: '10px 10px 25px 10px',
            position: 'relative'
          }}
        >
          <div className="search-row-wrapper">
            <label className="search-row-label">From Product:</label>
            <div className="search-row-input">
              <DDIIndexSearch
                propertyName="fastProduct.fromId"
                meta={indexSearchMeta}
                // meta={{
                //   ...metaData,
                //   searchType: 'product',
                //   allowSearchAll: false
                // }}
                preventAutoDisable
              />
            </div>
            <div className="search-row-description">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Field
                  propertyName="fastProduct.fromDescription"
                  component={Label}
                  wrapperStyle={{ maxWidth: 150, marginRight: 10 }}
                />
                {copyFromProduct ? (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={executeCopyProduct}
                    disabled={isPostingFastProductData}
                  >
                    OK
                  </Button>
                ) : null}
              </div>
            </div>
          </div>

          {copyFromDisplayUOM && copyFromPriceUOM && copyFromProduct ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                fontSize: 12
              }}
            >
              <span>
                <strong style={{ color: '#517b9c', fontWeight: 500 }}>
                  Display UOM:
                </strong>{' '}
                {copyFromDisplayUOM}
              </span>
              <span>
                <strong style={{ color: '#517b9c', fontWeight: 500 }}>
                  Price UOM:
                </strong>{' '}
                {copyFromPriceUOM}
              </span>
            </div>
          ) : null}

          <IconButton
            size="small"
            onClick={dismissCopyInterface}
            style={{ position: 'absolute', bottom: 0, right: 2 }}
          >
            <Icon style={{ fontSize: 14 }}>close</Icon>
          </IconButton>
        </Paper>
      ) : null}
      <Paper style={{ margin: '10px 0', padding: '10px 10px 20px 10px' }}>
        <DDITextField
          propertyName="fastProduct.description"
          label="Product Description"
          preventAutoDisable
          multiline
          validate={[required]}
        />
      </Paper>
    </div>
  )
}

/* FIXED SVE 9/23/2020 */
export default withContext({
  isPostingFastProductData: form =>
    form.get('isPostingFastProductData') || false,
  copyFromDisplayUOM: form =>
    getValue(form, 'fastProduct.copyFromDisplayUOM', ''),
  copyFromPriceUOM: form => getValue(form, 'fastProduct.copyFromPriceUOM', ''),
  canCopyFrom: form => getIn(form, 'meta.fastProduct.canCopyFrom') || false,
  copyFromInterfaceOpen: form =>
    getValue(form, 'fastProduct.copyFromInterfaceOpen', false),
  copyFromProduct: form => getField(form, 'fastProduct.fromId', ''),
  indexSearchMetaMap: form =>
    getMeta(form, 'fastProduct.searchFields.FromId', empty)
})(FastProductEntryModalHeader)
