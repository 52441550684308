import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { Icon } from '@material-ui/core'
import { getRgb } from 'utils'
import { renderStatusIcon } from 'pages/SalesOrder/utils'
import addInvoiceInquiryInModal from '../../common/addInvoiceInquiryInModal'
import addWarrantyTagInModal from '../../common/addWarrantyTagInModal'

/* can test with 2023413 */
export const StatusGridCell = props => {
  const [isOpeningScreen, setIsOpening] = useState(false)
  const [textColor, setTextColor] = useState('#000000')
  const { colDef, data } = props
  const returnInvoiceId = data?.returnInvoiceId
  const warrantyTagId = data?.warrantyTagId
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = colDef?.cellRendererParams?.dispatch || null

  const buttonLinkStyle = {
    alignItems: 'center',
    color: isOpeningScreen ? '#777' : textColor,
    display: 'flex',
    margin: 0,
    padding: 0
  }

  useEffect(() => {
    if (data && data.color) {
      setTextColor(getRgb(data.color))
    }
  }, [data.color])

  const openInvoiceInquiry = debounce(async event => {
    if (isOpeningScreen || !returnInvoiceId) {
      return
    }
    try {
      setIsOpening(true)
      await dispatch(addInvoiceInquiryInModal(form, returnInvoiceId))
    } catch (err) {
      console.log(err)
    } finally {
      setIsOpening(false)
    }
  }, 300)

  const openWarrantyTag = debounce(async event => {
    const { guid, lineNumber } = data
    if (isOpeningScreen || !guid || !lineNumber) {
      return
    }
    try {
      setIsOpening(true)
      await dispatch(
        addWarrantyTagInModal(form, lineNumber, guid, warrantyTagId, false)
      )
    } catch (err) {
      console.log(err)
    } finally {
      setIsOpening(false)
    }
  }, 300)

  const handleClick = (e, type) => {
    if (type === 'returnInvoiceId') {
      openInvoiceInquiry(e)
    }

    if (type === 'warrantyTagId') {
      openWarrantyTag(e)
    }
  }

  if (
    (returnInvoiceId && form && dispatch) ||
    ((warrantyTagId || data?.text === 'Warranty Tag') && form && dispatch)
  ) {
    return (
      <button
        type="button"
        style={buttonLinkStyle}
        onClick={e =>
          handleClick(e, returnInvoiceId ? 'returnInvoiceId' : 'warrantyTagId')
        }
      >
        <Icon
          style={{
            color: isOpeningScreen ? '#777' : textColor,
            marginRight: 3,
            paddingRight: 20
          }}
        >
          {renderStatusIcon(textColor)}
        </Icon>
        <span style={{ color: isOpeningScreen ? '#777' : textColor }}>
          {data.text}
        </span>
      </button>
    )
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <Icon
        style={{
          color: textColor,
          marginRight: 3,
          paddingRight: 20
        }}
      >
        {renderStatusIcon(textColor)}
      </Icon>
      <span style={{ color: textColor }}>{data.text}</span>
    </div>
  )
}

export default StatusGridCell
