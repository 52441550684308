/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React from 'react'
import memoize from 'memoize-one'
import { useDispatch } from 'react-redux'
import { Label, withContext } from 'ddiForm'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import { getIn, getRgb, getMeta, emptyList } from 'utils'
import { readTemplate } from 'components/MasterScreen/Template/actions'

import { templateLabel } from '../utils'
import PreNewTextField from './PreNewTextField'
import '../styles/search-area.scss'

const SearchArea = ({
  autoGenerateId,
  dataId,
  description,
  form,
  isEditing,
  preNewMode,
  statusDescription,
  statusColorMap,
  idMaxLength,
  defaultTemplates,
  templateKey,
  searchFields
}) => {
  const dispatch = useDispatch()
  const searchFieldsArr = searchFields?.toJS ? searchFields.toJS() : []
  const searchMeta = searchFieldsArr?.find(x => x?.fieldName === 'dataId') || {
    allowInstantSearch: true,
    allowNavigation: false,
    allowSearchAll: false,
    allowSpecialProducts: false,
    fieldName: 'dataId',
    hasFilters: false,
    hideToggle: true,
    isSecure: false,
    minimumKeywordLength: 2,
    openText: 'Open Vendor Master',
    searchType: 'Vendor'
  }

  const statusColor = statusColorMap?.toJS
    ? getRgb(statusColorMap.toJS(), 0)
    : '#444'

  const getCustomerTemplate = key => {
    dispatch(readTemplate(form, { templateKey: key }))
  }

  const getCustomContextMenuItems = () => {
    const items = defaultTemplates.reduce((acc, next) => {
      acc = acc.concat({
        text: next.get('description'),
        icon: '',
        enabled: !dataId,
        onClick: () => getCustomerTemplate(next.get('dataId')),
        style: { color: '#444' }
      })
      return acc
    }, [])

    return items
  }

  const getTemplateDescription = memoize(key => {
    const name =
      key && defaultTemplates && defaultTemplates?.find
        ? defaultTemplates
            .find(x => x.get('dataId') === key)
            ?.get('description')
        : ''
    return name
  })

  return (
    <div className="vendor-master-search-area">
      <div className="primary-inputs">
        <label>Vendor:</label>
        {preNewMode && !autoGenerateId ? (
          <PreNewTextField form={form} maxLength={10} />
        ) : (
          <DDIIndexSearch
            propertyName="dataId"
            searchType="Vendor"
            autoFocus
            fullWidth
            // metaKey="dataId"
            meta={searchMeta}
            preventAutoDisable
            leader
            disabled={isEditing}
            wrapperStyle={{ width: 175 }}
            customContextMenuItems={getCustomContextMenuItems}
          />
        )}
        {templateKey ? (
          templateLabel(getTemplateDescription(templateKey))
        ) : (
          <Label
            propertyName="description"
            labelStyle={{ width: 'auto' }}
            value={description}
          />
        )}
      </div>
      <div className="status-col">
        {statusDescription && (
          <div className="overflow-status" title={statusDescription}>
            <div
              style={{
                background: statusColor,
                borderRadius: 4,
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 27,
                fontSize: 12,
                flexDirection: 'row',
                padding: 5,
                width: '100%'
              }}
            >
              <span className="ellipsis-span">{statusDescription}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withContext({
  autoGenerateId: form => getIn(form, 'meta.autoGenerateId') || false,
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  description: form => getIn(form, 'values.description') || '',
  isEditing: form => form.get('isEditing') || false,
  isNew: form => getIn(form, 'values.isNew') || false,
  preNewMode: form => form.get('preNewMode') || false,
  statusDescription: form => getIn(form, 'values.statusDescription') || null,
  statusColorMap: form => getIn(form, 'values.statusColor') || null,
  idMaxLength: form => getIn(form, 'meta.idMaxLength') || 7,
  defaultTemplates: form => getMeta(form, 'defaultTemplates', emptyList),
  searchFields: form => getMeta(form, 'searchFields', emptyList),
  templateKey: form => getIn(form, 'values.templateKey') || ''
})(SearchArea)
