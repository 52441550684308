import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'

const HighAccessCell = ({ data }) => {
  const { highAccessLevelRequired } = data
  // console.log('HighAccessCell', data, this.props)
  const icon = highAccessLevelRequired ? 'check_box' : 'check_box_outline_blank'
  return (
    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
      <Icon style={{ color: '#999', display: 'inline-block', fontSize: 16 }}>
        {icon}
      </Icon>
    </div>
  )
}

HighAccessCell.propTypes = {
  data: PropTypes.object.isRequired
}

export default HighAccessCell

