import React from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { getIn, emptyList } from 'utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

const ExceptionsReport = ({ data: { report = [] } }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell style={{ width: 50 }}>Line #</TableCell>
          <TableCell>Column</TableCell>
          <TableCell>Exception</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {report.map((item, idx) => (
          <TableRow key={item?.lineNumber}>
            <TableCell style={{ width: 50 }}>{item?.lineNumber}</TableCell>
            <TableCell>{item.key}</TableCell>
            <TableCell>{item.message}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ExceptionsReport
