import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'grossprofitindicatorsmaster'


export const updateGrossProfitIndicators = ({ properties }) => {
  const options = createApiOptions({
    body: { properties },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/update`, options)
}

export const closeGrossProfitIndicators = () => {
  const options = createApiOptions({
    body: {},
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/close`, options)
}
