/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clearGridRow } from 'components/EditableGrid/utils'
import shouldUpdate from 'hoc/shouldUpdate'
import CancelIcon from './icons/CancelIcon'

class ClearGridRowCell extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    deleteContactAsync: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    // this.deleteGridRow = deleteGridRow.bind(this)
    this.clearGridRow = clearGridRow.bind(this)
  }

  clearGridRow = () => this.clearGridRow()

  render() {
    return <CancelIcon onClick={this.clearGridRow} />
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(shouldUpdate({ whitelist: ['data', 'rowIndex', 'value'] })(ClearGridRowCell))
