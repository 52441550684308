/* eslint react/prefer-stateless-function: 0, react/jsx-fragments: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { DDIButton } from 'ddiForm/wrapped'
import { removeModal } from 'modals/actions'
import { withContext } from 'ddiForm'
import { getValue } from 'utils'
import { cancelEdit, saveModalId } from '../actions'

const marginRight = { marginRight: 5 }

export class RightFooterButtons extends Component {
  static propTypes = {
    callLocation: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    isEditing: PropTypes.bool,
    values: PropTypes.object
  }

  static defaultProps = {
    isEditing: false,
    values: {}
  }

  componentDidMount() {
    const { id } = this.props
    this.props.dispatch(saveModalId({ modalId: id }))
  }

  exitScreen = () => {
    const { callLocation, id, isEditing } = this.props

    if (isEditing) {
      this.props.dispatch(cancelEdit({ modalId: id }))
    } else {
      this.props.dispatch(removeModal(callLocation, id))
    }
  }

  render() {
    const { isEditing, isNew, preNewMode, isSameShipTo } = this.props

    if (preNewMode) {
      return (
        <Fragment>
          <DDIButton
            variant="contained"
            label="OK"
            actionName="createShipTo"
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
          />
          <DDIButton
            variant="contained"
            label="Cancel"
            // actionName="exit"
            onClick={this.exitScreen}
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
          />
          <DDIButton
            variant="contained"
            label="Exit"
            // actionName="exit"
            onClick={this.exitScreen}
            preventAutoDisable
            tabIndex={0}
          />
        </Fragment>
      )
    }

    return (
      <Fragment>
        {!isEditing ? (
          <DDIButton
            variant="contained"
            label="Edit"
            actionName="lockForEdit"
            preventAutoDisable
            tabIndex={0}
            style={marginRight}
          />
        ) : null}
        {isEditing ? (
          <Fragment>
            <DDIButton
              variant="contained"
              label="Save"
              actionName="saveShipTo"
              preventAutoDisable
              tabIndex={0}
              style={marginRight}
            />
            {!isNew && !isSameShipTo ? (
              <DDIButton
                variant="contained"
                label="Delete"
                actionName="deleteShipTo"
                preventAutoDisable
                tabIndex={0}
                style={marginRight}
              />
            ) : null}
          </Fragment>
        ) : null}
        <DDIButton
          variant="contained"
          label="Exit"
          // actionName={isEditing ? 'cancelShipToEdit' : 'closeModal'}
          onClick={this.exitScreen}
          preventAutoDisable
          tabIndex={0}
        />
      </Fragment>
    )
  }
}

export default withContext({
  callLocation: form => getValue(form, 'callLocation', ''),
  preNewMode: form => getValue(form, 'preNewMode', ''),
  isEditing: form => form.get('isEditing') || false,
  newRecordCreated: form => getValue(form, 'newRecordCreated', ''),
  isNew: form => getValue(form, 'isNew', false),
  isSameShipTo: form => getValue(form, 'isSameShipTo', false)
})(RightFooterButtons)
