/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS, Iterable } from 'immutable'
import createLogger from 'redux-logger'
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router/immutable'
import createSagaMiddleware from 'redux-saga'
import { isMobile, isTablet } from 'react-device-detect'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import mobileMiddleware from 'access/mobileMiddleware'
import createReducer from './reducers'

const config = {
  level: 'log',
  effectTrigger: true,
  effectResolve: true,
  actionDispatch: true
}

// const sagaMiddleware = createSagaMiddleware()
const actionsToNotLog = [
  '@@USER_MAIL@@/SYNC_MAIL_TRY',
  '@@USER_MAIL@@/SYNC_MAIL_SUCCESS',
  '@@main/NEWS_FEED_SUCCESS',
  '@@auth//INCREMENT_LOGOUT_TIMER'
]
export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  let monitor
  // if (process.env.NODE_ENV !== 'production') {
  //   monitor = window['__SAGA_MONITOR_EXTENSION__'] //eslint-disable-line
  // }
  const sagaMiddleware = monitor
    ? createSagaMiddleware({ sagaMonitor: monitor })
    : createSagaMiddleware()
  const middlewares = [
    thunkMiddleware,
    sagaMiddleware,
    routerMiddleware(history)
  ]

  if (isMobile && process.env.NODE_ENV === 'production') {
    middlewares.push(mobileMiddleware)
  }

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(
      createLogger({
        stateTransformer: state => {
          if (Iterable.isIterable(state)) return state.toJS()
          return state
        },
        predicate: (_, action) => !actionsToNotLog.includes(action.type)
      })
    )
    middlewares.unshift(mobileMiddleware)
  }

  const enhancers = [applyMiddleware(...middlewares)]

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25
        })
      : compose

  // const composeEnhancers = compose
  // process.env.NODE_ENV !== 'production' &&
  // typeof window === 'object' &&
  // window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  //   ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  //   : compose
  /* eslint-enable */

  const store = createStore(
    createReducer(history),
    // connectRouter(history)(createReducer()),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  )

  // Extensions
  store.runSaga = sagaMiddleware.run
  store.asyncReducers = {} // Async reducer registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      /* eslint-disable no-undef */
      // System.import('./reducers').then(reducerModule => {
      System.import('./reducers').then(reducerModule => {
        const createReducers = reducerModule.default
        const nextReducers = createReducers(history, store.asyncReducers)

        store.replaceReducer(nextReducers)
      })
    })
  }

  return store
}
