import React from 'react'
import PropTypes from 'prop-types'
// import { GridField } from 'ddiForm'
import Grid from 'grid'
import { formatDateFields, formatDollarFields, noop, formatNumber } from 'utils'
import { changeProvisionalLineItem } from 'pages/SalesOrder/actions'

const getRowNodeId = data => data.dataId

const dynamicColDefs = ({ form, canSeeCost, canSeeRebateCost }) => {
  return [
    {
      checkboxSelection: true,
      minWidth: 50,
      width: 65,
      pinned: 'left',
      // suppressMovable: true,
      suppressMenu: true
    },
    {
      field: 'dataId',
      headerName: 'Contract',
      pinned: 'left',
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 80
    },
    {
      cellClass: 'align-center',
      field: 'effectiveDate',
      headerName: 'Eff Date',
      valueFormatter: formatDateFields,
      suppressMenu: true,
      minWidth: 100
    },
    {
      cellClass: 'align-center',
      field: 'expirationDate',
      headerName: 'Exp Date',
      valueFormatter: formatDateFields,
      suppressMenu: true,
      minWidth: 100
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'price',
      headerClass: 'align-right',
      headerName: 'Price',
      valueFormatter: formatDollarFields,
      suppressMenu: true,
      minWidth: 100
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'priceMethod',
      headerClass: 'align-right',
      headerName: 'Price Method',
      suppressMenu: true,
      minWidth: 100
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'cost',
      headerClass: 'align-right',
      headerName: 'Cost',
      valueFormatter: formatDollarFields,
      hide: !canSeeCost,
      suppressMenu: true,
      minWidth: 100
    },
    {
      field: 'costMethod',
      headerName: 'Cost Method',
      hide: !canSeeCost,
      suppressMenu: true,
      minWidth: 100
    },
    {
      cellStyle: { textAlign: 'right' },
      field: 'rebateCost',
      headerClass: 'align-right',
      headerName: 'Rebate Cost',
      valueFormatter: params => formatNumber(params.value),
      hide: !canSeeRebateCost,
      suppressMenu: true,
      minWidth: 100
    },
    {
      field: 'rebateCostMethod',
      headerName: 'Rebate Cost Method',
      hide: !canSeeRebateCost,
      suppressMenu: true,
      minWidth: 100
    }
  ]
}

const ContractsTab = ({
  dispatch,
  form,
  handleTabChange = noop,
  priceChange = {},
  lineNumber,
  canSeeCost,
  canSeeRebateCost
}) => {
  function onRowSelected(params) {
    if (params && params.data && !!params.data.dataId) {
      dispatch(
        changeProvisionalLineItem.try(form, {
          propertyName: 'setContract',
          lineNumber,
          value: params.data.dataId
        })
      )
      handleTabChange(null, 'Price')
    }
  }

  return (
    <Grid
      getRowNodeId={getRowNodeId}
      columnDefs={dynamicColDefs({
        form,
        canSeeCost,
        canSeeRebateCost
      })}
      rowData={
        priceChange && priceChange.contractPrices
          ? priceChange.contractPrices
          : []
      }
      height={360}
      fullWidth
      width="100%"
      onRowSelected={onRowSelected}
    />
  )
}

ContractsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  priceChange: PropTypes.object.isRequired
}

export default ContractsTab
