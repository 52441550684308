import React from 'react'
import { Grid } from '@material-ui/core'

const LabeledField = ({ label, children, labelWidth = 4, fieldWidth = 8 }) => {
  return (
    <>
      <Grid item xs={labelWidth}>
        <label style={{ float: 'right', marginTop: '3px' }}>{label}:</label>
      </Grid>
      <Grid item xs={fieldWidth}>
        {children}
      </Grid>
    </>
  )
}

export default LabeledField
