import React, { useState } from 'react'
import { call, put, select, take, spawn } from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { addScreenInModal, tryChangeFormTab } from 'ddiForm/actions'
import * as masterActions from 'ddiForm/MasterScreen/actions'
import { warningModal } from 'modals/sagas'
import { productMasterInModal } from 'pages/ProductMaster'
import { getProduct } from 'pages/ProductMaster/api'
import { getChildAndParentForm } from 'pages/ProductMaster/sagas'
import { mapDataResponse } from 'pages/ProductMaster/utils'
import { ADD_MODAL } from 'modals/constants'

function* onApiSuccess(response, meta, form) {
  const { childForm, parentForm } = getChildAndParentForm(form)
  const formState = yield select(getFormSelector(form))
  const tabIds = ['analysis', 'overview']

  const mappedResponse = mapDataResponse({
    response,
    tabIds,
    formState,
    groupNames: tabIds
  })

  yield put(
    masterActions.getEntityAsync.success(mappedResponse, {
      form,
      thunk: meta.thunk
    })
  )

  yield spawn(switchToAnalysisTabInModal, form, parentForm)
}

function* switchToAnalysisTabInModal(form, parentForm) {
  const modalAction = yield take(ADD_MODAL)

  if (
    form &&
    form.match(/productMaster/) &&
    modalAction?.meta?.form &&
    modalAction.meta.form === parentForm
  ) {
    yield put(tryChangeFormTab(form, 'analysis'))
  }
}

function* onApiError(error) {
  if (error?.message) {
    yield call(warningModal, error.message, 'Warning')
  }
}

export const openProductAnalysisScreen = (form, value) =>
  addScreenInModal(form, {
    screen: productMasterInModal(`${form}.productMaster`),
    formName: `${form}.productMaster`,
    activeKey: 'analysis',
    image: 'inventory_product_analysis_16.png',
    route: 'productmaster',
    title: 'Product Analysis',
    apiMethod: getProduct,
    apiArgs: {
      dataId: value,
      activeKey: 'analysis',
      image: 'inventory_product_analysis_16.png',
      route: 'productmaster',
      title: 'Product Analysis',
      groupNames: ['analysis', 'overview']
    },
    onApiSuccess,
    onApiError,
    modalOptions: {
      title: 'Product Master',
      hideActions: true,
      maxSize: true,
      modalOverrideClass: 'product-master-in-modal'
    }
  })

const OpenProductAnalysisCell = ({ colDef, value }) => {
  const [isOpeningScreen, setIsOpening] = useState(false)
  const form = colDef?.cellRendererParams?.form || ''
  const dispatch = useDispatch()
  const buttonLinkStyle = {
    color: isOpeningScreen ? '#777' : '#517b9c',
    fontWeight: 500,
    margin: 0,
    padding: 0,
    textDecoration: 'underline'
  }

  const openProductAnalysisInModal = debounce(async event => {
    if (isOpeningScreen) {
      return
    }
    try {
      setIsOpening(true)
      await dispatch(openProductAnalysisScreen(form, value))
    } catch (e) {
      console.log(e)
    } finally {
      setIsOpening(false)
    }
  }, 300)

  return (
    <button
      type="button"
      onClick={openProductAnalysisInModal}
      style={buttonLinkStyle}
      disabled={isOpeningScreen}
    >
      {value}
    </button>
  )
}
export default OpenProductAnalysisCell
