/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Switch
} from '@material-ui/core'
import DDICardWrapper from 'components/DDICardWrapper'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'

const getReturnAuthData = (props, key, defaultValue) =>
  props.data &&
  props.data.returnAuthorization &&
  props.data.returnAuthorization[key]
    ? props.data.returnAuthorization[key]
    : defaultValue

class ReturnAuthorizationModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      allowReturnToShowroomDisplay: getReturnAuthData(
        props,
        'allowReturnToShowroomDisplay',
        false
      ),
      enableReturnToShowroomDisplayForStock: getReturnAuthData(
        props,
        'enableReturnToShowroomDisplayForStock',
        false
      ),
      applyRestockingCharge: getReturnAuthData(
        props,
        'applyRestockingCharge',
        false
      ),
      returnToShowroomDisplay: getReturnAuthData(
        props,
        'returnToShowroomDisplay',
        false
      ),
      rgaType: getReturnAuthData(props, 'rgaType', 'I'),
      rgaOptions: getReturnAuthData(props, 'rgaOptions', [])
    }
  }

  onRadioButtonChange = e => {
    e.persist()

    this.setState(prevState => {
      if (e.target.value === 'W') {
        return {
          rgaType: e.target.value,
          returnToShowroomDisplay: false,
          applyRestockingCharge: false
        }
      }

      return {
        rgaType: e.target.value,
        returnToShowroomDisplay:
          e.target.value !== 'S' ? false : prevState.returnToShowroomDisplay
      }
    })
  }

  onToggleChange = name => e => this.setState({ [name]: e.target.checked })

  isShowroomToggleDisabled = memoize(
    (rgaType, enableReturnToShowroomDisplayForStock) => {
      if (rgaType !== 'S' || !enableReturnToShowroomDisplayForStock) {
        return true
      }

      return false
    }
  )

  render() {
    const {
      allowReturnToShowroomDisplay,
      enableReturnToShowroomDisplayForStock,
      applyRestockingCharge,
      returnToShowroomDisplay,
      rgaType,
      rgaOptions
    } = this.state

    // console.log('ReturnAuthorizationModal', this.props, this.state)

    return (
      <div>
        <DDICardWrapper
          title="Return To"
          contentStyle={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 20px'
          }}
        >
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="cc-opt-button-set"
              name="cc-opt-button-set"
              value={rgaType}
              onChange={this.onRadioButtonChange}
              // style={{ background: '#f4f7f9', paddingLeft: 10, paddingRight: 10, borderRadius: 20, justifyContent: 'center' }}
              row
            >
              {rgaOptions.map((item, idx) => (
                <FormControlLabel
                  key={item.dataId}
                  value={item.dataId}
                  control={<Radio />}
                  label={item.description}
                  style={{ marginBottom: 0 }}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DDICardWrapper>
        <AssociatedFieldsWrapper>
          <FormControlLabel
            control={
              <Switch
                checked={applyRestockingCharge}
                onChange={this.onToggleChange('applyRestockingCharge')}
                value="applyRestockingCharge"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
            label="Apply Restocking Fee"
          />
        </AssociatedFieldsWrapper>

        {allowReturnToShowroomDisplay ? (
          <AssociatedFieldsWrapper>
            <FormControlLabel
              control={
                <Switch
                  checked={returnToShowroomDisplay}
                  onChange={this.onToggleChange('returnToShowroomDisplay')}
                  value="returnToShowroomDisplay"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={this.isShowroomToggleDisabled(
                    rgaType,
                    enableReturnToShowroomDisplayForStock
                  )}
                />
              }
              label="Return to Showroom"
            />
          </AssociatedFieldsWrapper>
        ) : null}
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ReturnAuthorizationModal)
