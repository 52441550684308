import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext } from 'ddiForm'
import {
  empty,
  emptyList,
  is,
  isForeignCountry,
  isUsaCanadaOrBlank,
  getField,
  getIn,
  getMeta,
  plainDeepEqual,
  getValue
} from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  DDISelectField,
  DDITextField,
  DDIToggle,
  DDIDocumentSearch
} from 'ddiForm/wrapped'
import {
  emailAddress,
  maxLength,
  phoneNumber,
  required
} from 'ddiForm/validations'
import Image from 'pages/ContactMaster/components/Image'
import ParentInfo from 'pages/ContactMaster/components/ParentInfo'
import { BASE_INFORM_URL } from 'services/constants'

import { handleCopyParent } from 'pages/ContactMaster/actions'
import StateSelectField from 'components/StateSelectField'

import {
  CountryField,
  LayoutFlex,
  SelectFieldStyled,
  TwoColFlexWrapper,
  TwoColFlexLeft,
  TwoColFlexRight,
  TwoColInputsFlexWrapper,
  TwoColInputsFlexLeft,
  TwoColInputsFlexRight,
  ThreeColFlexWrapper,
  ThreeColFlexLeft,
  ThreeColFlexCenter,
  ThreeColFlexRight,
  ToggleStyled
} from 'pages/ContactMaster/styles/masterStyle'

import { getImageSource } from 'pages/ContactMaster/utils'

export const validateEmail = email =>
  !email || !is.undefined(emailAddress(email))

export class Information extends Component {
  static propTypes = {
    country: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    imageBase64String: PropTypes.string.isRequired,
    isCanadianCompany: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool,
    newMode: PropTypes.bool.isRequired,
    parent: PropTypes.object.isRequired,
    parentInfo: PropTypes.object.isRequired,
    parentTitle: PropTypes.string.isRequired,
    sendOptions: PropTypes.array
  }

  static defaultProps = {
    isEditing: false,
    sendOptions: []
  }

  componentDidMount() {
    const { newMode } = this.props
    if (newMode && this.firstNameField) this.firstNameField.focus()
  }

  shouldComponentUpdate(nextProps) {
    if (!plainDeepEqual(nextProps, this.props)) {
      return true
    }
    return false
  }

  firstNameField = null

  handleCopy = () => {
    const { dispatch, newMode, parent, form } = this.props
    if (parent) {
      dispatch(handleCopyParent.try(form, { newMode, parent }))
    }
  }

  render() {
    let { parent } = this.props

    const {
      dataId,
      dispatch,
      email,
      form,
      imageBase64String,
      isEditing,
      isCanadianCompany,
      sendOptions,
      country,
      parentTitle,
      newMode
    } = this.props

    parent = parent.toJS()

    const isUsa = !isForeignCountry(country, isCanadianCompany)

    return (
      <LayoutFlex>
        <TwoColFlexWrapper>
          <TwoColFlexLeft>
            <DDICardWrapper title="Contact">
              <TwoColInputsFlexWrapper>
                <TwoColInputsFlexLeft>
                  <DDITextField
                    label="First Name"
                    placeholder="First Name"
                    propertyName="firstName"
                    inputRef={c => (this.firstNameField = c)}
                    validate={[required, maxLength(30)]}
                    InputProps={{
                      inputProps: {
                        maxLength: 30
                      }
                    }}
                  />
                </TwoColInputsFlexLeft>
                <TwoColInputsFlexRight>
                  <DDITextField
                    label="Last Name"
                    placeholder="Last Name"
                    propertyName="lastName"
                  />
                </TwoColInputsFlexRight>
              </TwoColInputsFlexWrapper>
              <TwoColInputsFlexWrapper>
                <TwoColInputsFlexLeft>
                  <DDITextField
                    label="Title"
                    placeholder="Title"
                    propertyName="jobTitle"
                  />
                </TwoColInputsFlexLeft>
              </TwoColInputsFlexWrapper>
              <TwoColInputsFlexWrapper>
                <TwoColInputsFlexLeft>
                  <div style={{ marginTop: 15 }}>
                    <DDIToggle
                      propertyName="highAccessLevelRequired"
                      label="High Access Level Required"
                    />
                  </div>
                </TwoColInputsFlexLeft>
              </TwoColInputsFlexWrapper>
            </DDICardWrapper>
            <DDICardWrapper title="Address">
              <TwoColInputsFlexWrapper>
                <TwoColInputsFlexLeft>
                  <DDITextField
                    label="Address"
                    placeholder="Address"
                    propertyName="addressLine1"
                  />
                  <DDITextField
                    label="Line 2"
                    placeholder="Line 2"
                    propertyName="addressLine2"
                  />
                </TwoColInputsFlexLeft>
              </TwoColInputsFlexWrapper>
              <ThreeColFlexWrapper>
                <ThreeColFlexLeft>
                  <DDITextField
                    label="City"
                    placeholder="City"
                    propertyName="city"
                    validate={maxLength(
                      isUsaCanadaOrBlank(this.props.country) ? 20 : 30
                    )}
                  />
                </ThreeColFlexLeft>
                <ThreeColFlexCenter>
                  <StateSelectField propertyName="state" fullWidth />
                </ThreeColFlexCenter>
                <ThreeColFlexRight>
                  <DDITextField
                    label="Zip Code"
                    placeholder="Zip Code"
                    mask="zipCode"
                    propertyName="zipCode"
                    // inputProps={{
                    //   guide: false
                    // }}
                    disableMask={isCanadianCompany || !isUsa}
                  />
                </ThreeColFlexRight>
              </ThreeColFlexWrapper>
              <TwoColInputsFlexWrapper>
                <CountryField>
                  <DDITextField
                    label="Country"
                    placeholder="Country"
                    propertyName="country"
                  />
                </CountryField>
              </TwoColInputsFlexWrapper>
            </DDICardWrapper>
            <DDICardWrapper
              title="Contact Information"
              contentStyle={{ padding: 10 }}
            >
              <SelectFieldStyled>
                <DDISelectField
                  propertyName="defaultSendTo"
                  label="Send Options"
                  values={sendOptions.toJS()}
                  style={{
                    width: '25%'
                  }}
                />
              </SelectFieldStyled>
              <ThreeColFlexWrapper>
                <ThreeColFlexLeft>
                  {isCanadianCompany || !isUsa ? (
                    <DDITextField
                      placeholder="Phone"
                      label="Phone"
                      margin="dense"
                      propertyName="phone1"
                    />
                  ) : (
                    <DDITextField
                      placeholder="Phone"
                      label="Phone"
                      margin="dense"
                      mask="phoneNumber"
                      propertyName="phone1"
                      validate={[phoneNumber]}
                      inputProps={{
                        validate: true,
                        guide: false
                      }}
                    />
                  )}
                </ThreeColFlexLeft>
                <ThreeColFlexCenter>
                  <DDITextField
                    placeholder="Ext"
                    label="Ext"
                    margin="dense"
                    mask="numeric"
                    inputProps={{
                      maxLength: 8
                    }}
                    propertyName="phoneExtension"
                  />
                </ThreeColFlexCenter>
                <ThreeColFlexRight>
                  {isCanadianCompany || !isUsa ? (
                    <DDITextField
                      placeholder="Mobile"
                      label="Mobile"
                      margin="dense"
                      propertyName="phone2"
                    />
                  ) : (
                    <DDITextField
                      placeholder="Mobile"
                      label="Mobile"
                      margin="dense"
                      mask="phoneNumber"
                      propertyName="phone2"
                      validate={[phoneNumber]}
                      inputProps={{
                        validate: true,
                        guide: false
                      }}
                    />
                  )}
                </ThreeColFlexRight>
              </ThreeColFlexWrapper>
              <TwoColFlexWrapper>
                <TwoColFlexLeft>
                  {isCanadianCompany || !isUsa ? (
                    <DDITextField
                      placeholder="Fax"
                      label="Fax"
                      margin="dense"
                      propertyName="fax"
                      fullWidth
                      linkToSendDocument
                      sendDocumentArgs={{
                        dataId,
                        parentId: parent?.parentId,
                        sendType: 'Fax',
                        parentType: parent?.parentType
                      }}
                    />
                  ) : (
                    <DDITextField
                      placeholder="Fax"
                      label="Fax"
                      margin="dense"
                      mask="phoneNumber"
                      propertyName="fax"
                      fullWidth
                      validate={[phoneNumber]}
                      inputProps={{
                        validate: true,
                        guide: false
                      }}
                      linkToSendDocument
                      sendDocumentArgs={{
                        dataId,
                        parentId: parent?.parentId,
                        sendType: 'Fax',
                        parentType: parent?.parentType
                      }}
                    />
                  )}
                </TwoColFlexLeft>
                <TwoColFlexRight>
                  <ToggleStyled>
                    <DDIToggle propertyName="includeOne" label="Include 1" />
                    <DDIToggle
                      propertyName="includeAreaCode"
                      label="Area Code"
                    />
                  </ToggleStyled>
                </TwoColFlexRight>
              </TwoColFlexWrapper>
              <TwoColFlexWrapper>
                <TwoColFlexLeft>
                  <DDITextField
                    placeholder="Email"
                    label="Email"
                    propertyName="email"
                    validate={emailAddress}
                    linkToSendDocument
                    mask="email"
                    sendDocumentArgs={{
                      dataId,
                      parentId: parent?.parentId,
                      parentType: parent?.parentType,
                      sendType: 'Email'
                    }}
                  />
                </TwoColFlexLeft>
                <TwoColFlexRight>
                  <ToggleStyled>
                    <DDIToggle
                      propertyName="excludeFromMassMail"
                      label="Exclude Mass Mail"
                      disabled={validateEmail(email)}
                    />
                  </ToggleStyled>
                </TwoColFlexRight>
              </TwoColFlexWrapper>
            </DDICardWrapper>
          </TwoColFlexLeft>

          <TwoColFlexRight>
            <DDICardWrapper
              title="Image"
              contentStyle={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '0.5em'
              }}
            >
              <Image src={imageBase64String} isEditing={isEditing} />
              {isEditing ? (
                <div style={{ margin: '10px 0' }}>
                  <DDIDocumentSearch
                    propertyName="imageURL"
                    form={form}
                    apiArgs={{
                      dataId,
                      parentId: parent?.parentId,
                      parentType: parent?.parentType
                    }}
                    documentType="image"
                    width={250}
                    minWidth={250}
                    meta={{
                      allowDownload: true,
                      allowInstantSearch: true,
                      allowSearchAll: true,
                      hasUpload: true,
                      minimumKeywordLength: 2
                    }}
                  />
                </div>
              ) : null}
            </DDICardWrapper>
            <ParentInfo
              canCopy
              dataId={dataId}
              isEditing={isEditing}
              dispatch={dispatch}
              form={form}
              handleCopy={this.handleCopy}
              parent={parent}
              parentTitle={parentTitle}
              newMode={newMode}
            />
            <DDICardWrapper title="Comments" contentStyle={{ padding: 10 }}>
              <DDITextField
                propertyName="comments"
                fullWidth
                multiline
                rows={10}
              />
            </DDICardWrapper>
          </TwoColFlexRight>
        </TwoColFlexWrapper>
      </LayoutFlex>
    )
  }
}

export const contextObj = {
  country: form => getField(form, 'country', ''),
  dataId: form => getValue(form, 'dataId', null),
  email: form => getField(form, 'email', ''),
  imageBase64String: getImageSource,
  isCanadianCompany: form => getMeta(form, 'isCanadianCompany', false),
  isEditing: form => getIn(form, 'isEditing') || false,
  newMode: form => getIn(form, 'newMode') || false,
  parent: form => getValue(form, 'parent', empty),
  // parentInfo: form => getField(form, 'parentInfo', empty),
  parentTitle: form => getValue(form, 'parentTitle', ''),
  sendOptions: form => getMeta(form, 'sendOptions', emptyList)
}

export default withContext(contextObj)(Information)
