import React from 'react'
import { debounce } from 'lodash'
import { withContext } from 'ddiForm'
import { DDIButton } from 'ddiForm/wrapped'
import { useDispatch } from 'react-redux'

import { getIn, getValue } from 'utils'
import { removeModal } from 'modals/actions'
import { LeftFooterButtons } from 'pages/WarrantyTag/components/LeftFooterButtons'

// import { deleteWarrantyTag, saveWarrantyTag } from 'pages/WarrantyTag/actions'
import {
  cancelEditWarrantyTag,
  clearIsEditingFlag,
  deleteWarrantyTag,
  editWarrantyTag,
  saveWarrantyTag
} from '../actions'

const marginRight = { marginRight: 5 }

export const WarrantyTagModalButtons = props => {
  const {
    dataId,
    form,
    hasRecord,
    isEditing,
    isNew,
    newMode,
    canEdit,
    canDelete,
    modal = {}
  } = props

  const lineNumber = modal?.options?.data?.lineNumber
  const warrantyTagId = modal?.options?.data?.warrantyTagId
  const subForm = modal?.options?.data?.subForm
  const isInitialInteraction = modal?.options?.data?.isInitialInteraction
  // const parentForm = modal?.options?.data?.parentForm
  const dispatch = useDispatch()

  const cancelEditSOEWarrantyTag = debounce(e => {
    dispatch(
      cancelEditWarrantyTag.try(form, {
        lineNumber,
        modalId: modal?.id,
        isNew,
        isInitialInteraction
      })
    )
  }, 300)

  const closeModal = debounce(e => {
    if (isEditing) {
      cancelEditSOEWarrantyTag(e)
    } else if (modal?.id) {
      debugger
      dispatch(clearIsEditingFlag(subForm))
      dispatch(removeModal(form, modal.id))
    }
  }, 300)

  const deleteSOEWarrantyTag = debounce(e => {
    dispatch(
      deleteWarrantyTag.try(form, {
        lineNumber,
        modalId: modal?.id,
        warrantyTagId: warrantyTagId || null
      })
    )
  }, 300)

  const editSOEWarrantyTag = debounce(e => {
    dispatch(
      editWarrantyTag.try(form, {
        lineNumber,
        subForm
      })
    )
  }, 300)

  const saveSOEWarrantyTag = debounce(e => {
    dispatch(
      saveWarrantyTag.try(form, {
        lineNumber,
        modalId: modal?.id
      })
    )
  }, 300)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LeftFooterButtons {...props} dispatch={dispatch} form={subForm} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {hasRecord && canEdit && !isEditing && (
          <DDIButton
            variant="contained"
            label="Edit"
            onClick={editSOEWarrantyTag}
            preventAutoDisable
            tabIndex={0}
            autoFocus
            style={marginRight}
          />
        )}
        {isEditing ? (
          <DDIButton
            variant="contained"
            label="Save"
            onClick={saveSOEWarrantyTag}
            preventAutoDisable
            tabIndex={0}
            autoFocus
            style={marginRight}
          />
        ) : null}
        {isEditing && canDelete && !isInitialInteraction ? (
          <DDIButton
            variant="contained"
            label="Delete"
            onClick={deleteSOEWarrantyTag}
            preventAutoDisable
            tabIndex={0}
            autoFocus
            style={marginRight}
          />
        ) : null}
        {isEditing ? (
          <DDIButton
            variant="contained"
            label="Cancel"
            onClick={cancelEditSOEWarrantyTag}
            preventAutoDisable
            tabIndex={0}
            autoFocus
            style={marginRight}
          />
        ) : null}
        <DDIButton
          variant="contained"
          label="Exit"
          onClick={closeModal}
          preventAutoDisable
          tabIndex={0}
          autoFocus
        />
      </div>
    </div>
  )
}

export default withContext({
  allowWarrantyPageLink: form =>
    getIn(form, 'warrantyTag.values.allowWarrantyPageLink') || false,
  dataId: form => getIn(form, 'warrantyTag.fields.dataId.value') || '',
  hasRecord: form => getIn(form, 'warrantyTag.hasRecord') || false,
  isEditing: form => getIn(form, 'warrantyTag.isEditing') || false,
  newMode: form => getIn(form, 'warrantyTag.newMode') || false,
  isNew: form => getIn(form, 'warrantyTag.values.isNew') || false,
  canEdit: form => getIn(form, 'warrantyTag.values.canEdit') || false,
  canDelete: form => getIn(form, 'warrantyTag.values.canDelete') || false,
  warrantyPage: form => getIn(form, 'warrantyTag.values.warrantyPage') || ''
})(WarrantyTagModalButtons)
