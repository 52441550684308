import styled from 'styled-components'

// export const wrapper = {
//   display: 'flex',
//   flexWrap: 'wrap',
//   maxWidth: '100%',
//   padding: '0 5px',
//   width: '100%'
// }

// export const twoCols = {
//   left: { flex: 1, order: 1, padding: '0 5px' },
//   right: { flex: 1, order: 2, padding: '0 5px' }
// }

export const layoutFlex = (
  flexBasis = '120rem',
  flexDirection = 'row',
  flexWrap = 'wrap'
) => ({
  display: 'flex',
  flex: `0.1 1 ${flexBasis}`,
  flexDirection,
  flexWrap,
  maxWidth: '100%',
  margin: '0 auto'
})

export const LayoutFlex = styled.div`
display: flex;
flex: 0.1 1 120rem;
flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'wrap'};
max-width: 100%;
margin: 0 auto;
`


export const twoColFlex = {
  wrapper: { display: 'flex', flexWrap: 'wrap', maxWidth: '100%', padding: '0 5px', width: '100%' },
  left: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 1 },
  right: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 2 }
}

export const TwoColFlexWrapper = styled.div`
display: flex;
flex-wrap: wrap;
max-width: 100%;
padding: 0 5px;
width: 100%;
`

export const TwoColFlexLeft = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 0 5px;
`

export const TwoColFlexRight = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 0 5px;
`

export const twoColInputsFlex = {
  wrapper: { display: 'flex', flexWrap: 'wrap', maxWidth: '100%', padding: 0, width: '100%' },
  left: { flex: 1, maxWidth: '100%', padding: '0 10px', order: 1 },
  right: { flex: 1, maxWidth: '100%', padding: '0 10px', order: 2 }
}

export const TwoColInputsFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0;
  width: 100%;
`

export const TwoColInputsFlexLeft = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 0 10px;
  order: 1;
`

export const TwoColInputsFlexRight = styled.div`
  flex: 1;
  max-width: 100%;
  padding: 0 10px;
  order: 2;
`

export const threeColFlex = {
  wrapper: { display: 'flex', flexWrap: 'wrap', maxWidth: '100%', padding: '0 5px', width: '100%' },
  left: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 1 },
  center: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 2 },
  right: { flex: 1, maxWidth: '100%', padding: '0 5px', order: 3 }
}

export const ThreeColFlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  padding: 0 5px;
  width: 100%;
`

export const ThreeColFlexLeft = styled.div`
  flex: 1;
  max-width: 100%;
  order: 1;
  padding: 0 5px;
  width: 33.3%;
`

export const ThreeColFlexCenter = styled.div`
  flex: 1;
  max-width: 100%;
  order: 2;
  padding: 0 5px;
  width: 33.3%;
`

export const ThreeColFlexRight = styled.div`
  flex: 1;
  max-width: 100%;
  order: 3;
  padding: 0 5px;
  width: 33.3%;
`

export const selectFieldFlex = {
  wrapper: { display: 'flex', justifyContent: 'center' },
  centered: { maxWidth: '100%', width: '50%' },
}

export const SelectFieldFlexWrapper = styled.div`
  display: flex;
  flex: 0.1 1 120rem;
  flex-direction: column;
  flex-wrap: wrap;
  /* margin: 0 auto; */
  /* max-width: 100%; */
  justify-content: center;
  align-items: center;
`

export const SelectFieldFlexCentered = styled.div`
  max-width: 100%;
  width: 35%;
`

export const ToggleStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 15px 0px 15px;
`

export const SelectFieldStyled = styled.div`
  margin: 0px 5px 5px 10px;
`

export const CountryField = styled.div`
  flex: 1;
  margin: 0 10px 0 10px;
`

export const OverflowWrapper = styled.div`
  overflow: hidden;
`
export const ListInfo = styled.ul`
list-style-type: none;
margin: 0;
padding: 0;
width: 100%;
/* max-height: 18%; */
/* overflow: auto; */
`

export const LabelStyled = styled.label`
font-size: 1rem;
font-weight: 500;
margin: 10px 5px 0 0;
min-width: 60;
text-align: right;
white-space: wrap;
`

export const WrapperStyled = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;
align-items: center;
background-color: white;
padding: 5px 0;
`

export const FirstNameStyled = styled.div`
font-weight: bold;
margin: 10px 5px 0 0;
padding: 0 5px;
`

export const SearchWrapper = styled.div`
display: flex;
flex: 2;
flex-direction: row;
flex-wrap: wrap;
`

export const RightColumn = styled.div`
flex: initial;
align-self: flex-end;
margin-bottom: 10px;
margin-left: -110px;
`

export const ColumnWrapper = styled.div`
display: flex;
flex-direction: column;
`

export const FlexDiv = styled.div`
flex: 1
`
