import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GridField, withContext } from 'ddiForm'
import { formatDateFields, getField } from 'utils'
import { headerStyle } from 'pages/ProductSerialNumberInquiry/utils'
import { setSelectedSerialNumber } from 'pages/ProductSerialNumberInquiry/actions'
import NotesCheckbox from 'pages/ProductMaster/components/NotesCheckbox'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import { DynamicGridDataTooltip } from 'hoc'
import memoize from 'memoize-one'

import SerialNumberGridCell from 'pages/ProductSerialNumberInquiry/components/SerialNumberGridCell'

export const getRowNodeId = data => {
  const { dataId, poNumber, poDate, receivingDate } = data

  return `${dataId} - ${poNumber} - ${poDate} - ${receivingDate}`
}

export const dynamicColDefs = memoize(form => {
  const colDefs = [
    {
      cellRendererFramework: SerialNumberGridCell,
      cellRendererParams: {
        form
      },
      field: 'dataId',
      headerName: 'Serial No'
    },
    {
      cellRendererFramework: DynamicGridDataTooltip({
        dataField: 'notes'
      })(NotesCheckbox),
      cellRendererParams: {
        form,
        propertyName: 'poHistory'
      },
      field: 'notes',
      headerClass: 'text-center',
      headerName: 'Notes'
    },
    {
      cellRendererFramework: OpenScreenLinkCell,
      cellRendererParams: {
        form,
        hasLink: true
      },
      field: 'poNumber',
      headerName: 'P/O Number'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'poDate',
      headerClass: 'text-center',
      headerName: 'Date',
      valueFormatter: formatDateFields
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name'
    },
    {
      field: 'buyer',
      headerName: 'Buyer'
    },
    {
      cellStyle: { textAlign: 'center' },
      field: 'receivingDate',
      headerClass: 'text-center',
      headerName: 'Rcvd-Date',
      valueFormatter: formatDateFields
    },
    {
      field: 'receivedBy',
      headerName: 'Rcvd-By'
    }
  ]

  return colDefs
})

export class PurchaseOrderHistory extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    selectedSerialNumber: PropTypes.string.isRequired
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSerialNumber } = this.props
    if (prevProps.selectedSerialNumber !== selectedSerialNumber) {
      if (this.gridApi) {
        this.gridApi.onFilterChanged()
      }
    }
  }

  onGridReady = ({ api }) => {
    this.gridApi = api
  }

  onRowDoubleClicked = params => {
    const { dispatch, form } = this.props
    const value =
      params && params.data && params.data.dataId ? params.data.dataId : ''

    dispatch(setSelectedSerialNumber(form, { value }))
  }

  doesExternalFilterPass = node => {
    const { selectedSerialNumber } = this.props
    const { dataId } = node.data

    return selectedSerialNumber === dataId
  }

  isExternalFilterPresent = () => {
    const { selectedSerialNumber } = this.props

    return selectedSerialNumber !== ''
  }

  render() {
    const { form, height } = this.props

    return (
      <div className="product-serial-number-inquiry-layout-flex">
        <div className="flex-wrapper">
          <GridField
            getRowNodeId={getRowNodeId}
            propertyName="poHistory"
            title="Purchase Order History"
            headerStyle={headerStyle}
            onGridReady={this.onGridReady}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
            columnDefs={dynamicColDefs(form)}
            height={height}
            onRowDoubleClicked={this.onRowDoubleClicked}
          />
        </div>
      </div>
    )
  }
}

export const contextObj = {
  selectedSerialNumber: form => getField(form, 'selectedSerialNumber', '')
}

export default withContext(contextObj)(PurchaseOrderHistory)
