import React from 'react'
import memoize from 'memoize-one'
import EditableGrid from 'components/EditableGrid'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import TooltipField from 'hoc/TooltipField'
import { withContext } from 'ddiForm'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

const getColDefs = memoize(
  ({ isEditing, focusedCell, form, onCellChange, propertyName }) => {
    const params = {
      disabled: !isEditing,
      focusedCell,
      form,
      onChange: onCellChange,
      propertyName
    }

    const columnDefs = [
      {
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'product',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          linkTo: 'productMaster',
          referrer: 'productMaster',
          additionalOpenScreenParams: ['productAnalysis']
        },
        field: 'dataId',
        headerName: 'Product',
        filter: 'agTextColumnFilter'
      },
      {
        cellStyle: { color: isEditing ? '#444' : '#777' },
        field: 'description',
        headerName: 'Description',
        filter: 'agTextColumnFilter'
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params
        },
        hide: !isEditing,
        width: 50,
        minWidth: 50,
        maxWidth: 50
      }
    ]

    return columnDefs
  }
)

export const Substitutes = ({ form, height, isEditing }) => (
  <EditableGrid
    title="Substitutes"
    propertyName="substitutes"
    addButtonText="Add Substitute"
    columnDefs={getColDefs}
    emptyRow={{
      dataId: null,
      description: ''
    }}
    focusCellOnNewRow="dataId"
    focusCell="dataId"
    form={form}
    isEditing={isEditing}
    immutableData
    entityType="Product"
    height={height}
    requiredCols={['dataId']}
    addBlankRowOnLoad
  />
)

const substitutesWithContext = withContext({
  isEditing: form => form.get('isEditing') || false
})(Substitutes)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  substitutesWithContext
)
