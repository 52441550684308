import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DDICardWrapper from 'components/DDICardWrapper'
import { withContext } from 'ddiForm'
import { DDITextField, DDISearchDropdown, DDIToggle } from 'ddiForm/wrapped'
import { getMeta, emptyList, getValue } from 'utils'

import {
  TwoColInputsFlexWrapper,
  TwoColInputsFlexLeft,
  TwoColInputsFlexRight,
  ColumnWrapper,
  FlexDiv
} from 'pages/ContactMaster/styles/masterStyle'

// import * as actions from 'pages/ContactMaster/actions'

const columnDefs = [
  { field: 'dataId', headerName: 'Code' },
  { field: 'description', headerName: 'Description' }
]

export const getRowNodeId = data => data.dataId

const searchWidth = 900

export class WebField extends Component {
  static propTypes = {
    eCommerceBranchOptions: PropTypes.array.isRequired
  }

  // static defaultProps = {
  //   fieldType: 'password'
  // }

  // handlePasswordFocus = () => {
  //   const { canViewPassword, dispatch, form } = this.props

  //   dispatch(actions.setPasswordField.try(form, {
  //     fieldType: canViewPassword ? 'text' : 'password'
  //   }))
  // }

  render() {
    const {
      eCommerceBranchOptions,
      ecommerceBranchIds
      // fieldType,
    } = this.props

    return (
      <DDICardWrapper title="Web">
        <TwoColInputsFlexWrapper>
          <TwoColInputsFlexLeft>
            <DDITextField
              label="User Name"
              placeholder="User Name"
              propertyName="webUserName"
              fullWidth
            />
          </TwoColInputsFlexLeft>

          <TwoColInputsFlexRight>
            <DDITextField
              label="Password"
              placeholder="Password"
              propertyName="webUserPassword"
              type="password"
              inputRef={el => (this.passwordField = el)}
              fullWidth
            />
          </TwoColInputsFlexRight>
        </TwoColInputsFlexWrapper>
        <TwoColInputsFlexWrapper>
          <TwoColInputsFlexLeft>
            <ColumnWrapper>
              <FlexDiv>
                <DDIToggle
                  propertyName="canApprovePendingSalesOrders"
                  label="Approve Pending Sales Order"
                />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle
                  propertyName="canReadOrderPad"
                  label="View Order Pad"
                />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle propertyName="canPayOnline" label="Pay Online" />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle
                  propertyName="allowManualShipto"
                  label="Allow Manual Ship To"
                />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle
                  propertyName="useOrderPadPurchasesOnly"
                  label="Order Pad Purchases Only"
                />
              </FlexDiv>
            </ColumnWrapper>
          </TwoColInputsFlexLeft>
          <TwoColInputsFlexRight>
            <ColumnWrapper>
              <FlexDiv>
                <DDIToggle
                  propertyName="canReadSalesOrder"
                  label="View Sales Order"
                />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle propertyName="canReadReports" label="View Reports" />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle propertyName="canReadInvoice" label="View Invoice" />
              </FlexDiv>
              <FlexDiv>
                <DDIToggle
                  propertyName="hasQuickOrderAccess"
                  label="QuickOrder Access"
                />
              </FlexDiv>
            </ColumnWrapper>
          </TwoColInputsFlexRight>
        </TwoColInputsFlexWrapper>
        <DDISearchDropdown
          floatingLabelText="Branches: "
          fullWidth
          identifier="dataId"
          placeholder="All Branches"
          initialValue={ecommerceBranchIds}
          gridOptions={{
            columnDefs,
            enableSorting: true,
            getRowNodeId,
            onGridReady: this.onGridReady
          }}
          rowData={eCommerceBranchOptions.toJS()}
          propertyName="ecommerceBranchIds"
          width={searchWidth}
          inputLabelProps={{
            shrink: true
          }}
        />
      </DDICardWrapper>
    )
  }
}

export const contextObj = {
  // canViewPassword: form => form.meta.canViewPassword,
  eCommerceBranchOptions: form => getMeta(form, 'eCommerceBranchOptions', emptyList),

  ecommerceBranchIds: form => getValue(form, 'ecommerceBranchIds', '')
  // ecommerceBranchIds: form => form.fields.ecommerceBranchIds && form.fields.ecommerceBranchIds.value ? form.fields.ecommerceBranchIds.value : '',
  // fieldType: form => form.fields && form.fields.fieldType && form.fields.fieldType.value ? form.fields.fieldType.value : 'password',
}

export default withContext(contextObj)(WebField)
