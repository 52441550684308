import callFetch from 'access/callFetch'
import { createApiOptions, encodeString } from 'utils'
import { BASE_INFORM_API_URL } from 'services/constants'

export { lockEntity } from './MasterScreen/api'

const getFormLink = (str = '') => str.split('.').slice(-1)[0]

export const getEntity = ({ dataId, override = '', form }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { dataId, override },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/editlock`, options)
}

export const lockForEdit = ({ recordName, form, ...rest }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { dataId: recordName, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/editlock`, options)
}

export const cancelEdit = ({ recordName, form, ...rest }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { dataId: recordName, ...rest },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/canceledit`, options)
}

export const save = ({ form, ...args }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { ...args },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/update`, options)
}

export const saveTemplate = ({ form, ...args }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { ...args },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/template/update`, options)
}
export const getTabAccess = ({ form, ...args }) => {
  form = getFormLink(form)
  const options = createApiOptions({
    body: { ...args },
    method: 'post'
  })
  return callFetch(`${BASE_INFORM_API_URL}/${form}/access`, options)
}

// export const unlockEntity = ({ dataId, override = '', form }) => {
//   const options = createApiOptions({
//     method: 'get'
//   })
//   return callFetch(
//     `${BASE_INFORM_API_URL}/${form}/canceledit/${encodeString(dataId)}`,
//     options
//   )
//   // return callFetch(`${CANCEL_SHIPPING_CONFIRMATION_URL}/${encodeString(dataId)}`, options)
// }
