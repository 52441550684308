/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react'
import { withContext } from 'ddiForm'
import WarehouseGrid from 'pages/SalesOrder/tabs/Detail/components/WarehouseGrid'
import DynamicWarehouses from 'pages/SalesOrder/tabs/Detail/components/DynamicWarehouses'
import JohnstoneNational from 'pages/SalesOrder/tabs/Detail/components/JohnstoneNational'
import OptionsAndAccessoriesGrid from 'pages/SalesOrder/tabs/Detail/components/OptionsAndAccessoriesGrid'
import ProductDetailsInfo from 'pages/SalesOrder/tabs/Detail/components/ProductDetailsInfo'
import Measure from 'react-measure'
import { AppBar, Tab, Tabs, Toolbar, Typography } from '@material-ui/core'
import Split from 'react-split'
import { storeUIFeatureState } from 'pages/SalesOrder/actions'
import { empty, getIn, getValue, emptyList } from 'utils'

import {
  getAdditionalDataFromContext,
  getSelectedRowIndex,
  getChildIndex
} from 'pages/SalesOrder/utils'
import SubstitutesGrid from './components/SubstitutesGrid'
import AssembliesGrid from './components/AssembliesGrid'
import DetailsTab from './components/DetailsTab'
import PriceBreaksGrid from './components/PriceBreaksGrid'

export class InfoPanel extends Component {
  optionsRef = React.createRef()

  constructor(...args) {
    super(...args)
    this.state = {
      tabState: this.props.lineItemsDetailTab || 'Inventory'
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedRowIndex === prevProps.selectedRowIndex &&
      this.props.optionsAccessoriesList.size !==
        prevProps.optionsAccessoriesList.size &&
      this.props.optionsAccessoriesList.size &&
      this.props.lineItemsList.size === prevProps.lineItemsList.size &&
      this.state.tabState !== 'Options' &&
      this.props.isEditing
    ) {
      /* 
        open to the Options tab if a line item is added and there
        are options to be had
      */
      this.setTabState('Options')
    }

    if (
      this.props.optionsAccessoriesList.size !==
        prevProps.optionsAccessoriesList.size &&
      !this.props.optionsAccessoriesList.size &&
      this.state.tabState === 'Options' &&
      this.props.isEditing
    ) {
      // debugger
      /* 
        user changed line items while looking at the options & accessories tab,
        move them to the Inventory tab if the newly selected line item has no
        options
      */
      this.setTabState('Inventory')
    }

    if (
      prevProps.substitutesList.size !== this.props.substitutesList.size &&
      !this.props.substitutesList.size &&
      this.state.tabState === 'Substitutes' &&
      this.props.isEditing
    ) {
      /* 
        flip to the inventory tab if changing line item from one with 
        subsitutes to one without */
      this.setTabState('Inventory')
    }

    if (
      prevProps.assembliesList.size !== this.props.assembliesList.size &&
      !this.props.assembliesList.size &&
      this.state.tabState === 'Assembly' &&
      this.props.isEditing
    ) {
      /* same deal when changing line items while Assembly tab is in focus */
      this.setTabState('Inventory')
    }

    /*
      if we have updated the tab via props, either from the getProductDetails function
      in detailSagas, with the storeUIFeatureState call. Also can be called from a
      context menu item. See "Substitutes" in getContextMenuItems -- SVE 12/6/2019
    */
    if (
      this.props.lineItemsDetailTab !== prevProps.lineItemsDetailTab &&
      this.state.tabState !== this.props.lineItemsDetailTab
    ) {
      this.setState({ tabState: this.props.lineItemsDetailTab })
    }
  }

  setTabState = tab =>
    this.setState(
      {
        tabState: tab
      },
      () => {
        this.props.dispatch(
          storeUIFeatureState(this.props.form, {
            feature: 'lineItemsDetailTab',
            featureState: this.state.tabState
          })
        )
      }
    )

  handleChange = (e, val) => {
    if (val) {
      this.setState(
        {
          tabState: val
        },
        () => {
          this.props.dispatch(
            storeUIFeatureState(this.props.form, {
              feature: 'lineItemsDetailTab',
              featureState: val
            })
          )
        }
      )
    }
  }

  render() {
    const {
      dispatch,
      form,
      // hasDocumentSpec,
      // hasSDS,
      isJohnstoneCustomer,
      layoutState,
      lineItemsSidebarDimensions,
      onDragEnd,
      onResizeLineItemDetailGrid,
      onToggleInterface,
      optionsAccessoriesList,
      rightColHeight,
      selectedRowIndex,
      substitutesList,
      assembliesList,
      gridApi,
      priceBreaksList,
      johnstoneInventoryMap,
      userId,
      lineItemsList
    } = this.props

    const { tabState } = this.state

    const optionsAccessories = optionsAccessoriesList?.toJS()
      ? optionsAccessoriesList.toJS()
      : []

    const substitutes = substitutesList?.toJS ? substitutesList.toJS() : []

    const assemblies = assembliesList?.toJS ? assembliesList.toJS() : []

    const priceBreaks = priceBreaksList?.toJS ? priceBreaksList.toJS() : []

    const johnstoneInventory = johnstoneInventoryMap?.toJS
      ? johnstoneInventoryMap.toJS()
      : {}

    return (
      <div
        className="pane-content right-info-wrapper"
        style={{ height: rightColHeight, width: '100%' }}
      >
        <Split
          className="split-flex split-flex-vertical"
          direction="vertical"
          sizes={layoutState.sidebar || [50, 50]}
          style={{ height: rightColHeight }}
          minSize={0}
          onDragEnd={sizes => onDragEnd(sizes, 'sidebar')}
        >
          <Measure bounds onResize={onResizeLineItemDetailGrid}>
            {({ measureRef: mesRef }) => (
              <div
                ref={mesRef}
                className="line-item-detail-grid flex-wrapper"
                style={{ height: '100%', overflow: 'hidden' }}
              >
                <AppBar position="static" style={{ boxShadow: 'none' }}>
                  <Toolbar
                    style={{
                      justifyContent: 'space-between',
                      minHeight: 35,
                      paddingLeft: 0,
                      paddingRight: 0
                    }}
                  >
                    <Tabs
                      value={tabState}
                      onChange={this.handleChange}
                      scrollable
                    >
                      <Tab value="Inventory" label="Inventory" />
                      <Tab value="Details" label="Details" />
                      {/* {isJohnstoneCustomer ? (
                        <Tab
                          value="Johnstone National"
                          label="Johnstone National"
                        />
                      ) : null} */}
                      {optionsAccessories && optionsAccessories.length ? (
                        <Tab
                          className={
                            tabState === 'Options' ? '' : 'fade-in-out'
                          }
                          value="Options"
                          label="Options"
                          ref={this.optionsRef}
                        />
                      ) : null}
                      {substitutes && substitutes.length ? (
                        <Tab
                          className={
                            tabState === 'Substitutes' ? '' : 'fade-in-out'
                          }
                          value="Substitutes"
                          label="Substitutes"
                        />
                      ) : null}
                      <Tab
                        className={
                          tabState === 'Assembly'
                            ? ''
                            : assemblies && assemblies.length
                            ? 'fade-in-out'
                            : ''
                        }
                        value="Assembly"
                        label="Assembly"
                      />
                      {priceBreaks && priceBreaks.length ? (
                        <Tab
                          className={
                            tabState === 'Price Breaks' ? '' : 'fade-in-out'
                          }
                          value="Price Breaks"
                          label="Price Breaks"
                        />
                      ) : null}
                    </Tabs>
                  </Toolbar>
                </AppBar>
                <Typography component="div">
                  {tabState === 'Inventory' && (
                    <DynamicWarehouses
                      form={form}
                      height={lineItemsSidebarDimensions.height - 35}
                      userId={userId}
                      lineNumber={
                        lineItemsList
                          ?.get(selectedRowIndex)
                          ?.get('lineNumber') || 1
                      }
                      rowId={
                        lineItemsList?.get(selectedRowIndex)?.get('rowId') ||
                        null
                      }
                      dataId={
                        lineItemsList?.get(selectedRowIndex)?.get('dataId') ||
                        null
                      }
                      isJohnstoneCustomer={isJohnstoneCustomer}
                      johnstoneInventory={johnstoneInventory}
                    />
                  )}
                  {tabState === 'Details' ? (
                    <DetailsTab
                      form={form}
                      dispatch={dispatch}
                      height={lineItemsSidebarDimensions.height - 35}
                    />
                  ) : null}
                  {/* {tabState === 'Johnstone National' && (
                    <JohnstoneNational
                      form={form}
                      johnstoneInventory={johnstoneInventory}
                    />
                  )} */}
                  {tabState === 'Options' && (
                    <OptionsAndAccessoriesGrid
                      form={form}
                      dispatch={dispatch}
                      height={lineItemsSidebarDimensions.height - 35}
                      rowData={optionsAccessories}
                      expandLineItemsInterfaceCb={onToggleInterface}
                      gridApi={gridApi}
                    />
                  )}
                  {tabState === 'Substitutes' && (
                    <SubstitutesGrid
                      form={form}
                      dispatch={dispatch}
                      height={lineItemsSidebarDimensions.height - 35}
                      // rowData={substitutes}
                      gridApi={gridApi}
                      layoutState={layoutState}
                    />
                  )}
                  {tabState === 'Assembly' && (
                    <AssembliesGrid
                      form={form}
                      dispatch={dispatch}
                      height={lineItemsSidebarDimensions.height - 35}
                      rowData={assemblies}
                      gridApi={gridApi}
                      layoutState={layoutState}
                      rowId={
                        lineItemsList?.get(selectedRowIndex)?.get('rowId') ||
                        null
                      }
                      lineItemDataId={
                        lineItemsList?.get(selectedRowIndex)?.get('dataId') ||
                        null
                      }
                      lineNumber={
                        lineItemsList
                          ?.get(selectedRowIndex)
                          ?.get('lineNumber') || 1
                      }
                    />
                  )}
                  {tabState === 'Price Breaks' && (
                    <PriceBreaksGrid
                      form={form}
                      dispatch={dispatch}
                      height={lineItemsSidebarDimensions.height - 35}
                      rowData={priceBreaks}
                      gridApi={gridApi}
                      layoutState={layoutState}
                    />
                  )}
                </Typography>
              </div>
            )}
          </Measure>
          <div
            className="product-details"
            style={{
              border: '1px solid #e5e5e5',
              height: '100%',
              width: '100%'
            }}
          >
            <ProductDetailsInfo dispatch={dispatch} form={form} />
          </div>
        </Split>
      </div>
    )
  }
}

/* fixed SVE 9/24/20 */
const InfoPanelWithContext = withContext({
  selectedRowIndex: getSelectedRowIndex,
  childIndex: getChildIndex,
  optionsAccessoriesList: form =>
    getAdditionalDataFromContext(form, 'options', emptyList),
  substitutesList: form =>
    getAdditionalDataFromContext(form, 'substitutes', emptyList),
  assembliesList: form =>
    getAdditionalDataFromContext(form, 'components', emptyList),
  lineItemsDetailTab: form =>
    getIn(form, 'ui.lineItemsDetailTab') || 'Inventory',
  priceBreaksList: form =>
    getAdditionalDataFromContext(form, 'priceBreaks', emptyList),
  johnstoneInventoryMap: form =>
    getAdditionalDataFromContext(form, 'johnstoneInventory', empty),
  isJohnstoneCustomer: form => getValue(form, 'isJohnstoneCustomer', false),
  lineItemsList: form => getIn(form, 'fields.lineItems.rowData') || emptyList,
  isEditing: form => form.get('isEditing') || false
})(InfoPanel)

export default InfoPanelWithContext
