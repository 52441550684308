/* eslint react/sort-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@material-ui/core'
import { noop } from 'lodash'

const CancelIcon = ({ disabled, onClick, title }) => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      width: '100%'
    }}
    title={title}
  >
    <IconButton disabled={disabled} onClick={onClick} tabIndex={-1}>
      <Icon
        style={{
          color: disabled ? '#999' : '#d9534f',
          display: 'inline-block',
          fontSize: 16
        }}
      >
        cancel
      </Icon>
    </IconButton>
  </div>
)

CancelIcon.propTypes = {
  disabled: PropTypes.bool,
  onClickEvent: PropTypes.func.isRequired,
  title: PropTypes.string
}

CancelIcon.defaultProps = {
  disabled: false,
  onClickEvent: noop,
  title: ''
}

export default CancelIcon
