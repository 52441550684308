/* eslint no-else-return: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Field, GridField, withContext } from 'ddiForm'
import { tryChangeFormTab } from 'ddiForm/actions'
// import shortid from 'shortid'
import {
  dateFilterComparator,
  formatDateFields,
  layoutFlex,
  getValue,
  emptyList,
  getIn,
  getField
} from 'utils'
import withDimensions from 'hoc/withDimensions'

const columnDefs = [
  { field: 'changedById', headerName: 'User', width: 80 },
  {
    cellClass: 'text-center align-center',
    valueFormatter: params => formatDateFields(params, 'M/D/YY'),
    field: 'changedDate',
    headerName: 'Changed Date',
    filter: 'agDateColumnFilter',
    filterParams: {
      clearButton: true,
      comparator: dateFilterComparator,
      inRangeInclusive: true,
      suppressAndOrCondition: true,
      browserDatePicker: true
    }
  },
  {
    cellClass: 'text-center align-center',
    valueFormatter: params => formatDateFields(params, 'hh:mm A'),
    field: 'changedDate',
    headerName: 'Changed Time'
  },
  { field: 'reference', headerName: 'Reference' },
  { field: 'refDesc', headerName: 'Description' },
  { field: 'field', headerName: 'Field' },
  { field: 'changedFrom', headerName: 'From' },
  { field: 'changedTo', headerName: 'To' },
  { field: 'changeType', headerName: 'Type' }
]

const getRowNodeId = data => data.uniqueKey

class Audit extends Component {
  static propTypes = {
    additionalDataAvailable: PropTypes.bool.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { rowData } = nextProps
    rowData = rowData && rowData?.toJS ? rowData.toJS() : []
    if (!nextProps.hasRecord) {
      return { showFlag: false }
    }

    if (nextProps.additionalDataAvailable && !prevState.showFlag) {
      return {
        showFlag: nextProps.additionalDataAvailable
      }
    }

    if (
      !nextProps.additionalDataAvailable &&
      rowData &&
      rowData.length &&
      rowData.length <= 1000 &&
      prevState.showFlag
    ) {
      return {
        showFlag: false
      }
    }

    if (
      rowData &&
      rowData.length &&
      rowData.length >= 1000 &&
      !prevState.showFlag
    ) {
      return {
        showFlag: true
      }
    }

    return null
  }

  constructor(...args) {
    super(...args)

    this.state = {
      showFlag: this.props.additionalDataAvailable
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dataId,
      dispatch,
      isNew,
      isLocked,
      form,
      selectedPrimaryTab
    } = this.props

    if (
      form &&
      form.match(/salesOrder/) &&
      isNew &&
      isLocked &&
      prevProps.isLocked !== isLocked &&
      !dataId &&
      selectedPrimaryTab === 'audit'
    ) {
      dispatch(tryChangeFormTab(form, 'order'))
    }
  }

  render() {
    const { hasRecord, height } = this.props
    const { showFlag } = this.state

    return (
      <div
        className="audits-grid-wrapper"
        style={layoutFlex('120rem', 'column', 'wrap')}
      >
        <div style={{ flex: '1 1', overflow: 'hidden' }}>
          {hasRecord && showFlag ? (
            <Field
              preventAutoDisable
              propertyName="showAllAudits"
              label="Show All Audits"
              component={Checkbox}
              wrapperStyle={{ marginLeft: 15 }}
            />
          ) : null}
          <div
            style={{ flex: '1 1', overflow: 'hidden', height, minHeight: 500 }}
          >
            <GridField
              propertyName="audits"
              getRowNodeId={getRowNodeId}
              columnDefs={columnDefs}
              height={showFlag ? height - 50 : height}
              enableSorting
              width="100%"
              title="Audit"
              headerStyle={{
                background: '#e1e3e4',
                color: '#444',
                fontSize: 13,
                fontWeight: 400,
                lineHeight: '17px',
                margin: 0,
                padding: '5px 0',
                textAlign: 'center',
                width: '100%'
              }}
              ref={el => (this.auditsGrid = el)}
            />
          </div>
        </div>
      </div>
    )
  }
}

const contextObj = {
  additionalDataAvailable: form =>
    getValue(form, 'additionalDataAvailable', false),
  hasRecord: form => getIn(form, 'hasRecord') || false,
  isNew: form => getValue(form, 'isNew', false),
  isLocked: form => getValue(form, 'isLocked', false),
  selectedPrimaryTab: form =>
    getIn(form, 'masterOptions.selectedPrimaryTab') || '',
  dataId: form => getField(form, 'dataId', ''),
  customerId: form => getField(form, 'customerId', ''),
  rowData: form => getField(form, 'audits', emptyList, true)
}

const auditsWithContext = withContext(contextObj)(Audit)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  auditsWithContext
)
