/* eslint react/jsx-no-duplicate-props: 0 */
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Icon,
  InputAdornment,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel
} from '@material-ui/core'
import moment from 'moment'
import { numToDollar, getIn } from 'utils'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as actions from '../actions'

export const mapStateToProps = state => ({
  accessLevel: getIn(state, 'auth.accessLevelsDisplay')
})

export const mapDispatchToProps = dispatch => ({
  callAccessOverrideModal: () =>
    dispatch(actions.callAccessOverrideModal('eventModal'))
})

export class ReportOptionsModal extends Component {
  static propTypes = {
    accessLevel: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    callAccessOverrideModal: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    console.log('ReportOptionsModal', props)

    this.state = {
      defaultCutOffDate: new Date(props.data.responseData.defaultCutOffDate),
      printOption: 'N',
      printShipToDetail: false,
      reportType: 'O',
      saveLineSeqNo: false,
      userAuthenticated: false
    }
  }

  onToggleChange = name => event => {
    this.setState({ [name]: event.target.checked }, () =>
      console.log(this.state)
    )
  }

  onDatepickerChange = date => {
    this.setState({ defaultCutOffDate: new Date(date) })
  }

  /*
  onSelectChange = event => {
    const { accessLevel, callAccessOverrideModal } = this.props
    const { userAuthenticated } = this.state
    if (
      event.target.name === 'reportType' &&
      event.target.value === 'P' &&
      Number(accessLevel) < 99 &&
      userAuthenticated === false
    ) {
      callAccessOverrideModal()
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }
  */
  onSelectChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    const { printOptions, reportTypeOptions } = this.props.data.responseData

    const { defaultCutOffDate, printShipToDetail, saveLineSeqNo } = this.state

    return (
      <div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 15 }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="reportType">Report Type</InputLabel>
              <Select
                value={this.state.reportType}
                onChange={this.onSelectChange}
                inputProps={{
                  name: 'reportType',
                  id: 'reportType'
                }}
                fullWidth
              >
                {reportTypeOptions.map((opt, idx) => (
                  <MenuItem value={opt.dataId}>{opt.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 15 }}>
            <FormControl style={{ width: '100%' }}>
              <InputLabel htmlFor="printOption">Print Options</InputLabel>
              <Select
                value={this.state.printOption}
                onChange={this.onSelectChange}
                inputProps={{
                  name: 'printOption',
                  id: 'printOption'
                }}
                fullWidth
              >
                {printOptions.map((opt, idx) => (
                  <MenuItem value={opt.dataId}>{opt.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ marginBottom: 15 }}>
            <label
              style={{
                color: '#999',
                fontSize: 10,
                margin: 0
              }}
              htmlFor="defaultCutOffDate"
            >
              History Cut-Off Date
            </label>
            <FormControl style={{ width: '100%' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  id="defaultCutOffDate"
                  format="MM/DD/YYYY"
                  onChange={this.onDatepickerChange}
                  value={defaultCutOffDate}
                  fullWidth
                  InputProps={{
                    name: 'defaultCutOffDate',
                    id: 'defaultCutOffDate',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Icon
                            style={{
                              fontSize: 18,
                              position: 'relative',
                              left: 8
                            }}
                          >
                            event
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </Col>
        </Row>
        <Paper style={{ padding: 10 }}>
          <Row>
            <Col lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={printShipToDetail}
                    onChange={this.onToggleChange('printShipToDetail')}
                    value="printShipToDetail"
                  />
                }
                label="Print Ship to Detail"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={saveLineSeqNo}
                    onChange={this.onToggleChange('saveLineSeqNo')}
                    value="saveLineSeqNo"
                  />
                }
                label="Save Line Sequence Numbers"
              />
            </Col>
          </Row>
        </Paper>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(ReportOptionsModal)
