/* eslint jsx-a11y/label-has-for: 0, react/sort-comp: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { GridField } from 'ddiForm'
import { formatDateFields, formatDollarFields, layoutFlex } from 'utils'
import {
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/ProductMaster/utils'
import withDimensions from 'hoc/withDimensions'

export const colDefs = [
  {
    cellClass: 'align-right',
    field: 'c1',
    headerClass: 'align-right',
    headerName: 'C1',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'c4',
    headerClass: 'align-right',
    headerName: 'C4',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'c6',
    headerClass: 'align-right',
    headerName: 'C6',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'c7',
    headerClass: 'align-right',
    headerName: 'C7',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'l1',
    headerClass: 'align-right',
    headerName: 'L1',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'l2',
    headerClass: 'align-right',
    headerName: 'L2',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'l3',
    headerClass: 'align-right',
    headerName: 'L3',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'align-right',
    field: 'l4',
    headerClass: 'align-right',
    headerName: 'L4',
    valueFormatter: params => formatDollarFields(params, 4),
    ...numberFilterGridConfigs
  },
  {
    cellClass: 'text-center',
    field: 'changeDate',
    headerClass: 'text-center',
    headerName: 'Date Changed',
    valueFormatter: params => formatDateFields(params, 'M/D/YY'),
    ...dateFilterGridConfigs
  }
]

export const RollUpHistory = ({ height }) => (
  <div style={layoutFlex()}>
    <div style={{ maxWidth: '100%', width: '100%' }}>
      <GridField
        title="Roll-Up History"
        propertyName="getRollUpHistory"
        columnDefs={colDefs}
        headerStyle={{
          background: '#e1e3e4',
          color: '#444',
          fontSize: 13,
          fontWeight: 400,
          lineHeight: '17px',
          margin: 0,
          padding: '5px 0',
          textAlign: 'center',
          width: '100%'
        }}
        width="100%"
        height={height}
        enableSorting
        immutableData={false}
      />
    </div>
  </div>
)
RollUpHistory.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  RollUpHistory
)
