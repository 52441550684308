import { createAction, createAsyncFormAction } from 'utils'
import * as CONSTANTS from './constants'

export const truckRoutesChange = (rowIndex, newData, form) =>
  createAction(
    CONSTANTS.TRUCK_ROUTES_CHANGE,
    {
      rowIndex,
      newData
    },
    form
  )
export const stopNumberChange = (rowIndex, newValue, form) =>
  createAction(
    CONSTANTS.STOP_NUMBER_CHANGE,
    {
      rowIndex,
      newValue
    },
    form
  )

export const clearTruckRoute = (rowIndex, form) =>
  createAction(CONSTANTS.CLEAR_TRUCK_ROUTE, { rowIndex }, form)
