import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

/* MERGE modal */
export const openMergeModal = ({ dataId, form }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/changenumber/meta`, options)
}

export const merge = ({ dataId, toId, mergeOvernight, form, ...args }) => {
  const options = createApiOptions({
    body: { dataId, toId, mergeOvernight, ...args },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${form}/changenumber/set`, options)
}

export const deletePendingMerge = ({ dataId, form }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(
    `${BASE_INFORM_API_URL}/${form}/changenumber/deletepending`,
    options
  )
}

/* end MERGE API calls */
