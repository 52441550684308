/* eslint react/jsx-fragments: 0 */
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'
import { debounce } from 'lodash'
import { getIn, generateTabs } from 'utils'
import {
  AppBar,
  Icon,
  IconButton,
  Paper,
  Tabs,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core'
import { DDITextField } from 'ddiForm/wrapped'
import {
  launchCustomerShipTo as launchCustomerShipToAction,
  storeUIFeatureState,
  editShipTo
} from 'pages/SalesOrder/actions'

import { getGoogleMapsURL, renderShipToAddress } from 'pages/SalesOrder/utils'

import { openScreen as openScreenAction } from 'pages/Main/actions'

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  const customerId =
    getIn(formState, 'fields.customerId.value') ||
    getIn(formState, 'values.customerId') ||
    ''
  const customerName = getIn(formState, 'values.customerName') || ''
  const shipToAddress = getIn(formState, 'values.shipToAddress')
  const shipToDescription = getIn(formState, 'values.shipToDescription') || ''
  const shipToEmail = getIn(formState, 'values.shipToEmail') || ''
  const shipToFax = getIn(formState, 'values.shipToFax') || ''
  const shipToPhone = getIn(formState, 'values.shipToPhone') || ''
  const soldToAddress = getIn(formState, 'values.soldToAddress')
  const soldToEmail = getIn(formState, 'values.soldToEmail') || ''
  const soldToFax = getIn(formState, 'values.soldToFax') || ''
  const soldToPhone = getIn(formState, 'values.soldToPhone') || ''
  const soldToShipToTab = getIn(formState, 'ui.soldToShipToTab') || 'Sold To'

  const isEditing = getIn(formState, 'isEditing') || false

  return {
    customerId,
    customerName,
    immutableShipToAddress: shipToAddress,
    shipToDescription,
    shipToEmail,
    shipToFax,
    shipToPhone,
    immutableSoldToAddress: soldToAddress,
    soldToEmail,
    soldToFax,
    soldToPhone,
    soldToShipToTab,
    isEditing
  }
}

export const CustomerInfo = ({
  customerId,
  customerName,
  dispatch,
  form,
  immutableShipToAddress,
  shipToDescription,
  shipToEmail,
  shipToFax,
  shipToPhone,
  immutableSoldToAddress,
  soldToEmail,
  soldToFax,
  soldToPhone,
  soldToShipToTab,
  isEditing,
  isMobile = false
}) => {
  const shipToAddress = immutableShipToAddress?.toJS
    ? immutableShipToAddress.toJS()
    : {}
  const soldToAddress = immutableSoldToAddress?.toJS
    ? immutableSoldToAddress.toJS()
    : {}

  const [tab, setTab] = useState(soldToShipToTab)

  const iconButtonStyle = {
    background: 'rgba(240, 241, 241, 0.75)',
    marginRight: 5,
    padding: 3
  }

  const handleTabChange = (event, value) => {
    if (typeof value === 'string') {
      setTab(value)
      /* store the tab state in Redux */
      dispatch(
        storeUIFeatureState(form, {
          feature: 'soldToShipToTab',
          featureState: value
        })
      )
    }
  }

  const launchCustomerLedger = () => {
    dispatch(
      openScreenAction({
        dataId: customerId,
        image: 'accounting_accounts_receivable_customer_ledger_16.png',
        name: 'customerLedger',
        title: 'Customer Ledger',
        activeKey: 'ledger',
        route: 'customermaster',
        groupNames: ['summary', 'invoices']
      })
    )
  }

  const launchCustomerShipTo = () => {
    dispatch(launchCustomerShipToAction(form))
  }

  const renderSoldToAddress = memoize((soldToInfo = {}, id = '') => {
    const {
      address1 = '',
      address2 = '',
      address3 = '',
      cityStateZip = '',
      soldToPhone = '',
      soldToEmail = '',
      soldToFax = ''
    } = soldToInfo

    if (id && address1) {
      return (
        <div style={{ padding: 0 }}>
          <ul
            className="list-plain"
            style={{ listStyleType: 'none', paddingLeft: 0, marginLeft: 0 }}
          >
            <li>
              <a
                href={getGoogleMapsURL(address1, address2, cityStateZip)}
                rel="noopener noreferrer"
                target="_blank"
              >
                {address1}
              </a>
            </li>
            {address2 ? <li>{address2}</li> : null}
            {address3 ? <li>{address3}</li> : null}
            <li>{cityStateZip}</li>
            {soldToPhone ? <li>Phone: {soldToPhone}</li> : null}
            {soldToFax ? <li>Fax: {soldToFax}</li> : null}
            {soldToEmail ? <li>Email: {soldToEmail}</li> : null}
          </ul>
        </div>
      )
    }

    return null
  })

  const openShipToUrl = () => {
    const { address1, address2, cityStateZip } = shipToAddress
    window.open(getGoogleMapsURL(address1, address2, cityStateZip), '_blank')
  }

  const openSoldToUrl = () => {
    const { address1, address2, cityStateZip } = soldToAddress
    window.open(getGoogleMapsURL(address1, address2, cityStateZip), '_blank')
  }

  const openEditShipTo = debounce(() => {
    if (isEditing) {
      dispatch(editShipTo.try(form))
    }
  }, 500)

  return (
    <>
      <AppBar position="static" style={{ boxShadow: 'none' }}>
        <Toolbar
          style={{
            justifyContent: 'space-between',
            minHeight: 35,
            paddingLeft: 0,
            paddingRight: 0
          }}
        >
          <Tabs value={tab} onChange={handleTabChange} scrollable>
            {generateTabs(['Sold To', 'Ship To'])}
          </Tabs>
          {tab === 'Ship To' ? (
            customerId ? (
              <div>
                {isEditing && (
                  <Tooltip title="Edit" aria-label="Edit" placement="top">
                    <IconButton
                      style={iconButtonStyle}
                      onClick={openEditShipTo}
                    >
                      <Icon style={{ color: '#444' }}>edit</Icon>
                    </IconButton>
                  </Tooltip>
                )}
                {shipToAddress && shipToAddress.address1 ? (
                  <Tooltip
                    title="View Map"
                    aria-label="View Map"
                    placement="top"
                  >
                    <IconButton style={iconButtonStyle} onClick={openShipToUrl}>
                      <Icon style={{ color: '#444' }}>location_on</Icon>
                    </IconButton>
                  </Tooltip>
                ) : null}
                <Tooltip
                  title="Edit Ship To"
                  aria-label="Edit Ship To"
                  placement="top"
                >
                  <IconButton
                    style={iconButtonStyle}
                    onClick={launchCustomerShipTo}
                  >
                    <Icon style={{ color: '#444' }}>local_shipping</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            ) : null
          ) : customerId ? (
            <div>
              <Tooltip
                title="View Ledger"
                aria-label="View Ledger"
                placement="top"
              >
                <IconButton
                  style={iconButtonStyle}
                  onClick={launchCustomerLedger}
                >
                  <Icon style={{ color: '#444' }}>list_alt</Icon>
                </IconButton>
              </Tooltip>
              {soldToAddress && soldToAddress.address1 ? (
                <Tooltip title="View Map" aria-label="View Map" placement="top">
                  <IconButton style={iconButtonStyle} onClick={openSoldToUrl}>
                    <Icon style={{ color: '#444' }}>location_on</Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </Toolbar>
      </AppBar>
      <TabContainer>
        <Paper square style={{ padding: 15 }}>
          {tab === 'Sold To' && (
            <Fragment>
              <DDITextField propertyName="soldToAttention" label="Attention" />
              <h2
                style={{
                  color: '#517b9c',
                  fontSize: 14,
                  marginBottom: 3
                }}
              >
                {customerName}
              </h2>
              {renderSoldToAddress(
                { ...soldToAddress, soldToEmail, soldToFax, soldToPhone },
                customerId
              )}
            </Fragment>
          )}
          {tab === 'Ship To' && (
            <Fragment>
              <DDITextField propertyName="shipToAttention" label="Attention" />
              <h2
                style={{
                  color: '#517b9c',
                  fontSize: 14,
                  marginBottom: 3
                }}
              >
                {isMobile ? (
                  <>{shipToAddress?.description ?? ''}</>
                ) : (
                  <button
                    onClick={launchCustomerShipTo}
                    type="button"
                    style={{ cursor: 'pointer', padding: 0 }}
                  >
                    {shipToAddress?.description ?? ''}
                  </button>
                )}
              </h2>
              {renderShipToAddress(
                shipToAddress,
                shipToPhone,
                customerId,
                shipToEmail,
                shipToFax
              )}
            </Fragment>
          )}
        </Paper>
      </TabContainer>
    </>
  )
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(CustomerInfo)
