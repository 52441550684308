import React, { memo, useState } from 'react'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import {
  AppBar,
  Collapse,
  Icon,
  List,
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Paper,
  Tooltip
} from '@material-ui/core'

import { DDISelectField, DDITextField } from 'ddiForm/wrapped'

import { withStyles } from '@material-ui/core/styles'
import { formatNumber, getValue } from 'utils'

import ItemizedSubTotalTable from 'pages/SalesOrder/tabs/Invoicing/components/OrderTotals/components/ItemizedSubTotalTable'
import ItemizedTotalTable from 'pages/SalesOrder/tabs/Invoicing/components/OrderTotals/components/ItemizedTotalTable'
import MiscChargesGrid from './MiscChargesGrid'
import Freight from './Freight'

export const ListItem = withStyles({
  root: {
    borderBottom: '1px solid #c9c9c9',
    paddingBottom: 5,
    paddingTop: 5
  }
})(MuiListItem)

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    border: '1px solid #dadde9'
  }
}))(Tooltip)

export const ListItemText = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 0,
    marginTop: 0
  },
  primary: {
    color: '#444',
    fontSize: '0.9em',
    fontWeight: 500
  },
  secondary: {
    color: '#444',
    fontSize: '0.9em',
    fontWeight: 500
  },
  multiline: {
    marginBottom: 0,
    marginTop: 0
  }
})(MuiListItemText)

const InvoiceSummary = ({
  freight,
  miscellaneousChargeAmount,
  form,
  taxAmount,
  shippableAmount,
  invoiceAmount,
  isMobile = false
}) => {
  const [freightToggleOpen, setOpen] = useState(false)
  const [miscChargeToggleOpen, setMiscChargeToggleOpen] = useState(false)

  function handleFreightToggle() {
    setOpen(!freightToggleOpen)
  }

  function handleMiscChargeToggle() {
    setMiscChargeToggleOpen(!miscChargeToggleOpen)
  }

  const wrapperStyle = isMobile
    ? { flexGrow: 1, paddingTop: 10 }
    : { maxWidth: 700, minWidth: 400, flexGrow: 1, paddingTop: 10 }

  return (
    <Paper style={wrapperStyle} square>
      <h2
        style={{
          color: '#517b9c',
          fontSize: '1.2em',
          margin: '0 10px 10px 10px',
          textAlign: 'center'
        }}
      >
        Invoice Summary
      </h2>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem>
          <ListItemText
            primary="Subtotal"
            secondary={
              <HtmlTooltip title={<ItemizedSubTotalTable />} placement="right">
                <span>{formatNumber(shippableAmount)}</span>
              </HtmlTooltip>
            }
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>
        <ListItem button onClick={handleFreightToggle}>
          {freightToggleOpen ? (
            <Icon>keyboard_arrow_down</Icon>
          ) : (
            <Icon>keyboard_arrow_right</Icon>
          )}
          <ListItemText primary="Freight" secondary={formatNumber(freight)} />
        </ListItem>
        <Collapse
          in={freightToggleOpen}
          timeout="auto"
          unmountOnExit
          style={{ borderBottom: '1px solid #c9c9c9' }}
        >
          <div style={{ margin: 10 }}>
            <Freight />
          </div>
        </Collapse>

        <ListItem button onClick={handleMiscChargeToggle}>
          {miscChargeToggleOpen ? (
            <Icon>keyboard_arrow_down</Icon>
          ) : (
            <Icon>keyboard_arrow_right</Icon>
          )}
          <ListItemText
            primary="Miscellaneous Charge"
            secondary={formatNumber(miscellaneousChargeAmount)}
          />
        </ListItem>
        <Collapse
          in={miscChargeToggleOpen}
          timeout="auto"
          unmountOnExit
          style={{ borderBottom: '1px solid #c9c9c9' }}
        >
          <div style={{ margin: 10 }}>
            <MiscChargesGrid form={form} />
          </div>
        </Collapse>

        <ListItem>
          <ListItemText
            primary="Tax"
            secondary={formatNumber(taxAmount)}
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>
        <ListItem style={{ background: '#e1e3e4' }}>
          <ListItemText
            primary="TOTALS"
            secondary={
              <HtmlTooltip title={<ItemizedTotalTable />} placement="right">
                <span>{formatNumber(invoiceAmount)}</span>
              </HtmlTooltip>
            }
            primaryTypographyProps={{ style: { marginLeft: 18 } }}
          />
        </ListItem>
      </List>
    </Paper>
  )
}

/* fixed SVE 9/25/2020 */
// paid,
// balanceLeft,
// change,
// paymentsList,

const contextObj = {
  freight: form => getValue(form, 'freight', 0),
  miscellaneousChargeAmount: form =>
    getValue(form, 'miscellaneousChargeAmount', 0),
  shippableAmount: form => getValue(form, 'shippableAmount', 0),
  taxAmount: form => getValue(form, 'taxAmount', 0),
  invoiceAmount: form => getValue(form, 'invoiceAmount', 0)
}

export default memo(withContext(contextObj)(InvoiceSummary))
