import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { withContext } from 'ddiForm'
import { AppBar, Paper, Tab, Tabs, Toolbar } from '@material-ui/core'
import { getIn, layoutFlex } from 'utils'
import PrintOptions from 'pages/SalesOrder/tabs/Invoicing/components/PrintOptions'
import GrossProfit from './components/GrossProfit'
import InvoiceSummary from './components/InvoiceSummary'
import GLDistribution from './components/GLDistribution'

export const Invoicing = ({ form, batchNumber, pos }) => {
  const [tab, setTab] = useState('Invoice Totals')
  const dispatch = useDispatch()

  const setActiveTab = tabName => {
    setTab(tabName)
    /* store the tab state in Redux */
    // dispatch(
    //   storeUIFeatureState(form, {
    //     feature: 'invoiceDetailsTab',
    //     featureState: tabName
    //   })
    // )
  }

  function handleTabChange(e, val) {
    if (val) {
      setActiveTab(val)
    }
  }

  return (
    <div
      className="invoice-inquiry-invoicing-screen invoice-inquiry-screen"
      style={layoutFlex('90rem', 'row', 'wrap', 'center')}
    >
      <div style={{ flexGrow: 1 }}>
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Toolbar
            style={{
              justifyContent: 'space-between',
              minHeight: 35,
              paddingLeft: 0,
              paddingRight: 0
            }}
          >
            <Tabs value={tab} onChange={handleTabChange} scrollable>
              <Tab value="Invoice Totals" label="Invoice Totals" />
              <Tab value="Gross Profit" label="Gross Profit" />
              <Tab value="G/L Distribution" label="G/L Distribution" />
            </Tabs>
          </Toolbar>
        </AppBar>
        <Paper style={{ display: 'flex', padding: 10 }}>
          {tab === 'Invoice Totals' && (
            <div style={{ display: 'flex', width: '100%' }}>
              <InvoiceSummary form={form} dispatch={dispatch} />
              <div style={{ padding: 15 }}>
                <p style={{ margin: 0, textAlign: 'center' }}>
                  <strong style={{ marginRight: 5 }}>Batch:</strong>
                  <span>{batchNumber}</span>
                </p>
              </div>
            </div>
          )}
          {tab === 'Gross Profit' && <GrossProfit />}
          {tab === 'G/L Distribution' && <GLDistribution />}
        </Paper>
      </div>
      <PrintOptions form={form} dispatch={dispatch} />
    </div>
  )
}

export default withContext({
  batchNumber: form => getIn(form, 'values.batchNumber') || '',
  pos: form => getIn(form, 'values.pos') || false
})(Invoicing)
