import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import Measure from 'react-measure'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { DDISelectField } from 'ddiForm/wrapped'
import { createFooterTotals, postProcessPopup } from 'hoc/withRowTotals/utils'
import {
  emptyList,
  formatDate,
  formatNumber,
  getIn,
  plainDeepEqual
} from 'utils'

import {
  getCellStyle,
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/VendorMaster/utils'

// import SalesOrderTooltipCell from 'pages/CustomerMaster/components/SalesOrderTooltipCell'
import PurchaseOrderTooltipCell from 'pages/ProductMaster/components/PurchaseOrderTooltipCell'
import CustomTooltip from './grid/CustomTooltip'

import AwaitingBackdrop from './AwaitingBackdrop'

import '../styles/ledger-styles.scss'
import { getAttachments } from '../../../../../components/MasterScreen/Attachments/actions'

export const getDetailColumnField = memoize(field => {
  const mapping = {
    amount: 'balanceDue',
    bankGLId: 'branchId',
    discountTaken: 'discount',
    number: 'dataId',
    postingDate: 'invoiceDate',
    reference: 'pOorReferenceNumber'
  }

  if (mapping[field]) {
    return mapping[field]
  }

  return field
})

export const getPinnedRowData = memoize(grid => {
  const result = grid.reduce(
    (acc, next, idx) => {
      acc.orderValue += next.orderValue
      acc.openValue += next.openValue
      return acc
    },
    { dataId: 'Totals', orderValue: 0.0, openValue: 0.0 }
  )

  return [result]
}, plainDeepEqual)

const TotalsPinnedRowRenderer = ({ value }) => (
  <span style={{ fontWeight: 500 }}>{value}</span>
)

let _isMounted = false

const OrdersMain = ({
  dataId,
  defaultTransactionDate,
  dimensions,
  dispatch,
  form,
  gridMaxHeight,
  height,
  isEditing,
  poStatus,
  poStatusOptionsList,
  ordersList,
  fetchingEntity
}) => {
  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const [inputDimensions, setDimensions] = useState({
    width: -1,
    height: -1
  })

  const getContextMenuItems = params => {
    const {
      node: {
        data: { dataId, uniqueKey, id }
      }
    } = params

    const items = [
      {
        name: 'Attachments',
        action: () => {
          dispatch(
            getAttachments.try(form, {
              uniqueKey,
              targetType: 'ledgerOrder',
              modalTitle: `Attachments for Purchase Order - ${dataId}`,
              poStatus
            })
          )
        }
      },
      'rows',
      'exportTo',
      'filter',
      'layout'
    ]

    return items
  }

  const orders = ordersList?.toJS ? ordersList.toJS() : []
  const poStatusOptions = poStatusOptionsList?.toJS
    ? poStatusOptionsList.toJS()
    : []

  const columnDefs = [
    {
      colId: 'dataId',
      field: 'dataId',
      headerName: 'Order #',
      cellRendererFramework: PurchaseOrderTooltipCell,
      linkTo: 'purchaseOrder',
      pinnedRowCellRenderer: 'totalsPinnedRowRenderer',
      minWidth: 125
    },

    {
      colId: 'orderDate',
      field: 'orderDate',
      headerName: 'Order Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY')
    },
    {
      colId: 'branchId',
      field: 'branchId',
      headerName: 'Branch',
      valueFormatter: params => {
        if (params?.value?.value) {
          return params.value.value
        }

        return ''
      },
      tooltipField: 'branchId.tooltip'
    },
    {
      colId: 'warehouseId',
      field: 'warehouseId',
      headerName: 'Whse',
      valueFormatter: params => {
        if (params?.value?.value) {
          return params.value.value
        }

        return ''
      },
      tooltipField: 'warehouseId.tooltip'
    },
    {
      colid: 'buyerId',
      field: 'buyerId',
      headerName: 'Buyer',
      valueFormatter: params => {
        if (params?.value?.value) {
          return params.value.value
        }

        return ''
      },
      tooltipField: 'buyerId.tooltip'
    },
    {
      colId: 'shipViaId',
      field: 'shipViaId',
      headerName: 'Ship Via',
      valueFormatter: params => {
        if (params?.value?.value) {
          return params.value.value
        }

        return ''
      },
      tooltipField: 'shipViaId.tooltip'
    },
    {
      colId: 'termsId',
      field: 'termsId',
      headerName: 'Terms',
      valueFormatter: params => {
        if (params?.value?.value) {
          return params.value.value
        }

        return ''
      },
      tooltipField: 'termsId.tooltip'
    },
    {
      cellClass: 'align-right right-align',
      colId: 'numberOfReceivings',
      field: 'numberOfReceivings',
      headerClass: 'align-right right-align',
      headerName: 'Rcvgs',
      valueFormatter: params => {
        if (params?.value) {
          return params.value
        }

        return ''
      }
    },
    {
      colId: 'lastReceivingDate',
      field: 'lastReceivingDate',
      headerName: 'Last Rcv Date',
      headerClass: 'text-center',
      cellClass: 'text-center',
      valueFormatter: params => formatDate(params.value, 'M/D/YYYY')
    },
    {
      colId: 'orderValue',
      field: 'orderValue',
      headerName: 'Order Value',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle
    },
    {
      colId: 'openValue',
      field: 'openValue',
      headerName: 'Balance',
      headerClass: 'align-right',
      cellClass: 'align-right',
      valueFormatter: params => formatNumber(params.value),
      minWidth: 150,
      cellStyle: getCellStyle
    },
    {
      cellStyle: { color: '#c140f7' },
      colId: 'statusType',
      field: 'statusType.value',
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      headerName: 'Status',
      tooltipField: 'statusType.tooltip',
      tooltipComponent: CustomTooltip,
      minWidth: 100
    }
  ]

  const getOrdersTitle = memoize((status, statusOptions) => {
    let title = 'Purchase Orders'
    if (
      statusOptions &&
      Array.isArray(statusOptions) &&
      statusOptions?.length
    ) {
      const description =
        statusOptions.find(x => x.dataId === status)?.description || ''
      title = description ? `${description} Purchase Orders` : title
    }

    return title
  }, plainDeepEqual)

  const redrawFooter = ({ api }) =>
    createFooterTotals(api, getPinnedRowData, _isMounted, false)

  return (
    <div style={{ width: '100%' }}>
      <Measure
        bounds
        onResize={contentRect => setDimensions(contentRect.bounds)}
      >
        {({ measureRef }) => (
          <div ref={measureRef} className="vendor-ledger-inputs-wrapper">
            <div className="vendor-ledger-inputs">
              <DDISelectField
                propertyName="orders.poStatus"
                label="Type"
                initialValue="O"
                values={poStatusOptions}
                preventAutoDisable
                disabled={!dataId}
              />
            </div>
          </div>
        )}
      </Measure>
      <div
        className="vendor-ledger-grid-wrapper"
        style={{ position: 'relative' }}
      >
        <AwaitingBackdrop fetchingEntity={fetchingEntity} />
        <Grid
          title={getOrdersTitle(poStatus, poStatusOptions)}
          headerStyle={headerStyle}
          height={gridMaxHeight - inputDimensions?.height}
          columnDefs={columnDefs}
          getContextMenuItems={getContextMenuItems}
          rowData={orders}
          getRowNodeId={data => data.dataId}
          suppressRowClickSelection
          applyColumnDefOrder
          key={`${dataId}-${poStatus}`}
          frameworkComponents={{
            totalsPinnedRowRenderer: TotalsPinnedRowRenderer
          }}
          postProcessPopup={postProcessPopup}
          onFilterChanged={redrawFooter}
          onRowDataChanged={redrawFooter}
          onRowDataUpdated={redrawFooter}
          reactUi={false}
        />
      </div>
    </div>
  )
}

export default withContext({
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  isEditing: form => form.get('isEditing') || false,
  poStatusOptionsList: form =>
    getIn(form, 'meta.ledgerMeta.poStatusOptions') || emptyList,
  ordersList: form => getIn(form, 'values.orders.orders') || emptyList,
  poStatus: form => getIn(form, 'fields.orders.poStatus.value') || 'O',
  fetchingEntity: form => form.get('fetchingEntity') || false
})(OrdersMain)
