import React, { Component } from 'react'

import { BrowserCodeReader } from '@zxing/browser'
import { BarcodeFormat, DecodeHintType } from '@zxing/library'
import noop from 'utils/noop'
import BrowserMultiFormatContinuousReader from './BrowserMultiFormatContinuousReader'
import './styles.scss'

export default class BarcodeScanner extends Component {
  constructor(...args) {
    super(...args)

    this._enabled = true
    this._hints = new Map()
    this.autofocusEnabled = true
    this.autostart = true
    this.formats = this.props.formats

    this.hasNavigator = typeof navigator !== 'undefined'
    this.isMediaDevicesSupported = this.hasNavigator && !!navigator.mediaDevices
  }

  static defaultProps = {
    autofocusEnabled: true,
    autostarting: noop,
    autostarted: noop,
    camerasFound: noop,
    deviceChange: noop,
    permissionResponseError: noop,
    camerasNotFound: noop,
    hasDevices: noop,
    permissionResponse: noop,
    scanComplete: noop,
    scanSuccess: noop,
    scanFailure: noop,
    scanError: noop,
    torchCompatible: noop,
    timeBetweenScans: 500,
    delayBetweenScanSuccess: 500,
    formats: [
      BarcodeFormat.AZTEC,
      BarcodeFormat.CODE_39,
      BarcodeFormat.CODE_128,
      BarcodeFormat.DATA_MATRIX,
      BarcodeFormat.EAN_13,
      BarcodeFormat.EAN_8,
      BarcodeFormat.ITF,
      BarcodeFormat.QR_CODE,
      BarcodeFormat.PDF_417,
      BarcodeFormat.RSS_14
    ],
    style: {
      objectFit: 'contain'
    }
  }

  _hints = null

  _codeReader = null

  _device = null

  _enabled = null

  _isAutoStarting = null

  hasNavigator = null

  isMediaDevicesSupported = null

  hasPermission = null

  _scanSubscription = null

  _ready = false

  _deviceStart = null

  componentDidMount() {
    if (this.props.tryHarder) {
      this.setTryHarder(true)
    }
    this.init()
  }

  componentWillUnmount() {
    this.reset()
  }

  previewElemRef = React.createRef()

  getCodeReader = () => this._codeReader

  getDevice = () => this._device

  getFormats = () => this._hints.get(DecodeHintType.POSSIBLE_FORMATS)

  setFormats = input => {
    if (typeof input === 'string') {
      throw new Error(
        'Invalid formats, make sure the [formats] input is a binding.'
      )
    }

    // formats may be set from html template as BarcodeFormat or string array
    const formats = input.map(f => this.getBarcodeFormatOrFail(f))

    // const { hints } = this
    const hints = this.getHints()

    // updates the hints
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)

    // handles updating the codeReader
    this.setHints(hints)
  }

  getHints = () => this._hints

  setHints = hints => {
    this._hints = hints
    this.getCodeReader()?.setHints(this._hints)
  }

  setVideoConstraints = constraints => {
    const controls = this.getCodeReader()?.getScannerControls()

    if (!controls) {
      // fails silently
      return
    }

    controls?.streamVideoConstraintsApply(constraints)
  }

  setIsAutostarting = state => {
    this._isAutostarting = state
    this.props.autostarting(state)
  }

  getIsAutoStarting = () => this._isAutoStarting

  setTorch = onOff => {
    try {
      const controls = this.getCodeReader().getScannerControls()
      controls.switchTorch(onOff)
    } catch (error) {
      // ignore error
    }
  }

  setEnable = enabled => {
    this._enabled = Boolean(enabled)

    if (!this._enabled) {
      this.reset()
    } else if (this.device) {
      this.scanFromDevice(this.device.deviceId)
    } else {
      this.init()
    }
  }

  getEnabled = () => this._enabled

  getTryHarder = () => {
    return this.hints.get(DecodeHintType.TRY_HARDER)
  }

  setTryHarder = enable => {
    const hints = this.getHints()

    if (enable) {
      hints.set(DecodeHintType.TRY_HARDER, true)
    } else {
      hints.delete(DecodeHintType.TRY_HARDER)
    }

    this.setHints(hints)
  }

  askForPermission = async () => {
    if (!this.hasNavigator) {
      console.error(
        '@zxing/ngx-scanner',
        "Can't ask permission, navigator is not present."
      )
      this.setPermission(null)
      return this.hasPermission
    }

    if (!this.isMediaDevicesSupported) {
      console.error(
        '@zxing/ngx-scanner',
        "Can't get user media, this is not supported."
      )
      this.setPermission(null)
      return this.hasPermission
    }

    let stream
    let permission

    try {
      // Will try to ask for permission
      stream = await this.getAnyVideoDevice()
      permission = !!stream
    } catch (err) {
      return this.handlePermissionException(err)
    } finally {
      this.terminateStream(stream)
    }

    this.setPermission(permission)

    // Returns the permission
    return permission
  }

  getAnyVideoDevice = () => {
    return navigator.mediaDevices.getUserMedia({ video: true })
  }

  terminateStream = stream => {
    if (stream) {
      stream.getTracks().forEach(t => t.stop())
    }

    stream = undefined
  }

  init = async () => {
    debugger
    if (!this.autostart) {
      console.warn(
        "Feature 'autostart' disabled. Permissions and devices recovery has to be run manually."
      )

      // does the necessary configuration without autostarting
      this.initAutostartOff()

      this._ready = true

      return
    }

    // configurates the component and starts the scanner
    await this.initAutostartOn()

    this._ready = true
  }

  initAutostartOff = () => {
    // do not ask for permission when autostart is off
    this.setIsAutostarting(false)

    // just update devices information
    this.updateVideoInputDevices()

    if (this._device && this._devicePreStart) {
      this.setDevice(this._devicePreStart)
    }
  }

  initAutostartOn = async () => {
    this.setIsAutostarting(true)
    let hasPermission
    debugger
    try {
      // Asks for permission before enumerating devices so it can get all the device's info
      hasPermission = await this.askForPermission()
    } catch (e) {
      console.error('Exception occurred while asking for permission:', e)
      return
    }
    debugger
    // from this point, things gonna need permissions
    if (hasPermission) {
      const devices = await this.updateVideoInputDevices()
      await this.autostartScanner([...devices])
    }

    this.isAutostarting = false
    this.props.autostarted()
  }

  isCurrentDevice = device => {
    return device?.deviceId === this._device?.deviceId
  }

  scanStop = () => {
    if (this._scanSubscription) {
      this.codeReader?.getScannerControls().stop()
      this._scanSubscription?.unsubscribe()
      this._scanSubscription = undefined
    }
    this.props.torchCompatible(false)
  }

  scanStart = () => {
    if (this._scanSubscription) {
      throw new Error('There is already a scan proccess running.')
    }

    if (!this._device) {
      throw new Error(
        'No device defined, cannot start scan, please define a device.'
      )
    }

    this.scanFromDevice(this._device.deviceId)
  }

  restart = () => {
    this._codeReader = undefined

    const prevDevice = this._reset()

    if (!prevDevice) {
      return
    }

    this.device = prevDevice
  }

  updateVideoInputDevices = async () => {
    // permissions aren't needed to get devices, but to access them and their info
    const devices = (await BrowserCodeReader.listVideoInputDevices()) || []
    const hasDevices = devices && devices.length > 0

    // stores discovered devices and updates information
    this.props.hasDevices(hasDevices)
    this.props.camerasFound([...devices])

    if (!hasDevices) {
      this.props.camerasNotFound()
    }

    return devices
  }

  autostartScanner = async devices => {
    const matcher = ({ label }) =>
      /back|trás|rear|traseira|environment|ambiente/gi.test(label)
    const device = devices.find(matcher) || devices.pop()
    if (!device) {
      throw new Error('Impossible to autostart, no input devices available.')
    }

    await this.setDevice(device)
    this.props.deviceChange(device)
  }

  handlePermissionException = err => {
    // failed to grant permission to video input
    console.error(
      '@zxing/ngx-scanner',
      'Error when asking for permission.',
      err
    )

    let permission

    switch (err.name) {
      // usually caused by not secure origins
      case 'NotSupportedError':
        console.warn('@zxing/ngx-scanner', err.message)
        // could not claim
        permission = null
        // can't check devices
        this.props.hasDevices(null)
        break

      // user denied permission
      case 'NotAllowedError':
        console.warn('@zxing/ngx-scanner', err.message)
        // claimed and denied permission
        permission = false
        // this means that input devices exists
        this.props.hasDevices(true)
        break

      // the device has no attached input devices
      case 'NotFoundError':
        console.warn('@zxing/ngx-scanner', err.message)
        // no permissions claimed
        permission = null
        // because there was no devices
        this.props.hasDevices(false)
        // tells the listener about the error
        this.props.camerasNotFound(err)
        break

      case 'NotReadableError':
        console.warn(
          '@zxing/ngx-scanner',
          "Couldn't read the device(s)'s stream, it's probably in use by another app."
        )
        // no permissions claimed
        permission = null
        // there are devices, which I couldn't use
        this.props.hasDevices(false)
        // tells the listener about the error
        this.props.camerasNotFound(err)
        break

      default:
        console.warn(
          '@zxing/ngx-scanner',
          'I was not able to define if I have permissions for camera or not.',
          err
        )
        // unknown
        permission = null
        // this.hasDevices.next(undefined;
        break
    }

    this.setPermission(permission)

    // tells the listener about the error
    this.props.permissionResponseError(err)

    return permission
  }

  getBarcodeFormatOrFail = format => {
    return typeof format === 'string'
      ? BarcodeFormat[format.trim().toUpperCase()]
      : format
  }

  getCodeReader = () => {
    if (!this._codeReader) {
      const options = {
        delayBetweenScanAttempts: this.props.timeBetweenScans,
        delayBetweenScanSuccess: this.props.delayBetweenScanSuccess
      }
      debugger
      this._codeReader = new BrowserMultiFormatContinuousReader(
        this.hints,
        options
      )
      debugger
    }

    return this._codeReader
  }

  scanFromDevice = async deviceId => {
    const videoElement = this.previewElemRef.current
    const codeReader = this.getCodeReader()
    const scanStream = await codeReader.scanFromDeviceObservable(
      deviceId,
      videoElement
    )
    if (!scanStream) {
      throw new Error('Undefined decoding stream, aborting.')
    }

    const next = x => this._onDecodeResult(x.result, x.error)
    const error = err => this._onDecodeError(err)
    const complete = () => {}

    this._scanSubscription = scanStream.subscribe(next, error, complete)
    if (this._scanSubscription.closed) {
      return
    }

    const controls = codeReader.getScannerControls()
    const hasTorchControl = typeof controls.switchTorch !== 'undefined'

    this.props.torchCompatible(hasTorchControl)
  }

  _onDecodeError = error => {
    // if (!this.props.scanError.observers.some(x => Boolean(x))) {
    //   console.error(`zxing scanner component: ${error.name}`, error)
    //   console.warn('Use the `(scanError)` property to handle errors like this!')
    // }
    this.props.scanError(error)
  }

  _onDecodeResult = (result, error) => {
    if (result) {
        this.props.scanSuccess(result.text)
    } else {
      this.props.scanFailure(error)
    }

    this.props.scanComplete(result)
  }

  _reset = () => {
    if (!this._codeReader) {
      return
    }
    const device = this._device
    this.device = undefined
    this._codeReader = undefined
    return device
  }

  reset = () => {
    this._reset()
    this.props.deviceChange(null)
  }

  setDevice = async device => {
    this.scanStop()
    this._device = device
    if (!this._device) {
      BrowserCodeReader.cleanVideoSource(this.previewElemRef.current)
    }
    if (this._enabled && device) {
      await this.scanFromDevice(device.deviceId)
    }
  }

  setPermission = hasPermission => {
    this.hasPermission = hasPermission
    this.props.permissionResponse(hasPermission)
  }

  render() {
    return (
      <video id="preview" ref={this.previewElemRef} style={this.props.style}>
        <p>
          Your browser does not support this feature, please try to upgrade it.
        </p>
        <p>
          Seu navegador não suporta este recurso, por favor tente atualizá-lo.
        </p>
      </video>
    )
  }
}
