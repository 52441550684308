import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  delay
} from 'redux-saga/effects'
import { displayValidationErrors } from 'ddiForm/sagas'
import { setFields } from 'ddiForm/actions'
import { getFormSelector } from 'ddiForm/utils'
import { getIn, is } from 'utils'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'

import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, confirm, canceled, removeModal } from 'modals/actions'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'

import EndUserTrackedFieldsModal from '../components/EndUserTrackedFields'
import { createClearFieldsAction } from '../components/EndUserTrackedFields/components/ActionButtons'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import { validateSalesOrderProcess } from '.'
import {
  changeGridItem as changeGridItemAPI,
  readLineItem,
  validateSalesOrder as validateSalesOrderAPI
} from '../api'
import { EXACT_MATCH_SEARCH } from '../../../components/Search/IndexSearch/constants'

export function* openEndUserTrackedFieldsModal(
  form,
  lineNumber,
  rebateInfo = {}
) {
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  yield put(actions.destroyVirtualFields(form, { key: 'rebateInfo' }))

  const modalOpts = {
    component: EndUserTrackedFieldsModal,
    options: {
      actions: false,
      title: 'End User Tracked Fields',
      data: {
        form,
        isMobile,
        lineNumber,
        rebateInfo
      },
      // marginTop: '0px',
      maxHeight: '100%',
      width: 600
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield take(act => {
    return act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
  })

  // return modal.payload.id
}

export function* handleRebateInfoInteractionProcess(form, lineNumber, modalId) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['header', 'detail']
  let rebateInfoFields = getIn(formState, `fields.rebateInfo`)
  rebateInfoFields = rebateInfoFields?.toJS ? rebateInfoFields.toJS() : {}

  const rebateInfo = Object.keys(rebateInfoFields).reduce((acc, next) => {
    const field = next
    if (field) {
      const selectFields = ['constructionType', 'fuelType']
      const value = getIn(formState, `fields.rebateInfo.${field}.value`)
      acc[field] = selectFields.includes(field) && value === 'none' ? '' : value
    }

    return acc
  }, {})

  if (
    !rebateInfo.description ||
    !rebateInfo.address1 ||
    !rebateInfo.city ||
    !rebateInfo.state ||
    !rebateInfo.postalCode ||
    !rebateInfo.installDate ||
    !rebateInfo.phoneNumber
  ) {
    yield call(
      confirmationModal,
      'Would you like to clear all info?',
      'Incomplete Data?'
    )

    const modalAction = yield take([CONFIRMED, CANCELED])
    if (modalAction.type === CONFIRMED) {
      yield put(createClearFieldsAction(form, rebateInfoFields))
      if (modalId) {
        yield put(removeModal(form, modalId))
      }
    }

    return
  }

  yield put(actions.handleRebateInfoInteraction.request(form))

  const { response, error } = yield call(changeGridItemAPI, {
    guid,
    groupNames,
    gridName: 'lineItems',
    lineNumber,
    properties: {
      rebateInfo
    }
  })

  if (response) {
    if (modalId) {
      yield put(removeModal(form, modalId))
    }

    yield put(actions.handleRebateInfoInteraction.success(response, form))
  } else {
    yield put(actions.handleRebateInfoInteraction.failure(error, form))
    yield fork(displayValidationErrors, error)
  }
}

export function* handleRebateInfoInteractionListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { action, lineNumber, modalId, rebateInfo = null }
    } = yield take(CONSTANTS.HANDLE_REBATE_INFO_INTERACTION.TRY)

    if (form === formListener && action) {
      // debugger
      if (action === 'save') {
        // debugger
        yield fork(
          handleRebateInfoInteractionProcess,
          form,
          lineNumber,
          modalId
        )
      } else if (action === 'launch' && rebateInfo) {
        // debugger
        yield fork(openEndUserTrackedFieldsModal, form, lineNumber, rebateInfo)
      }
    }
  }
}

export function* openRebateCostTabListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { lineNumber, setTab }
    } = yield take(CONSTANTS.OPEN_REBATE_COST_TAB.TRY)

    if (form === formListener) {
      yield fork(openRebateCostTabProcess, form, lineNumber, setTab)
    }
  }
}

export function* openRebateCostTabProcess(form, lineNumber, setTab) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'values.dataId')
  // debugger

  yield put(actions.openRebateCostTab.request(form))

  const { response, error } = yield call(readLineItem, {
    dataId,
    lineNumber,
    guid,
    action: 'rebatecost'
  })

  if (response) {
    yield put(
      actions.openRebateCostTab.success({ response, lineNumber }, { form })
    )
    setTab('Rebate Cost')
  } else {
    yield put(actions.openRebateCostTab.failure({ error }, { form }))
  }
}

export function* rebatePropertyChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyChanged: propertyName }
    } = yield take(CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS)

    const rebateProperties = [
      'rebateCost',
      'rebateVendorId',
      'rebateContractId'
    ]

    // debugger

    if (
      form === formListener &&
      propertyName &&
      rebateProperties.includes(propertyName?.split('.')?.[1])
    ) {
      yield fork(rebatePropertyChangeProcess, form, propertyName.split('.')[1])
    }
  }
}

export function* rebatePropertyChangeProcess(form, propertyName) {
  const formState = yield select(getFormSelector(form))
  const lineNumber = getIn(formState, 'selectedLineNumber')
  const value = getIn(
    formState,
    `fields.rebateCostData[${lineNumber - 1}].${propertyName}.value`
  )
  // debugger

  yield put(
    actions.changeProvisionalLineItem.try(form, {
      lineNumber,
      propertyName,
      value
    })
  )

  // yield put(
  //   actions.changeProvisionalLineItem.try(form, {
  //     lineNumber,
  //     propertyName: 'confirmProvisionalChanges'
  //   })
  // )
}

export default function* rebateSagas(form) {
  yield fork(handleRebateInfoInteractionListener, form)
  yield fork(openRebateCostTabListener, form)
  yield fork(rebatePropertyChangeListener, form)
}
