/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
// import { connect } from 'react-redux'
import { withTooltip } from 'hoc'
// import hoistStatics from 'hoist-non-react-statics'
import BaseTooltipTemplate from 'components/BaseTooltipTemplate'
import * as eventModalActions from 'modals/EventModal/actions'
import {
  getIndexSearchTooltip,
  getPurchaseOrderTooltip,
  getSalesOrderTooltip
} from 'hoc/TooltipField/actions'

/* for usage example, see SalesOrderFieldWithTooltip and PurchaseOrderFieldWithTooltip */
/* in app\pages\Dashboard\components\Calendar\components\EventModal\index.js */

const getNetworkRequestPromise = type => {
  const actions = {
    salesOrder: getSalesOrderTooltip,
    purchaseOrder: getPurchaseOrderTooltip,
    indexSearch: getIndexSearchTooltip,
    calendarItem: eventModalActions.getActivityTooltip,
    customerLedgerCell: getIndexSearchTooltip
  }

  return actions[type]
}

export default ({
  anchorToGridCellText = false,
  keyToActionMap = {},
  component = BaseTooltipTemplate,
  indexSearchType = '',
  inputComponent = false,
  networkRequestPromise,
  position = 'bottom',
  sensitivity = 10,
  style = { width: 400 },
  timeout = 1000,
  id,
  type = 'salesOrder',
  recordNameKey = 'dataId'
}) => WrappedComponent => {
  class TooltipWrapper extends Component {
    _isMounted = false

    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const ComponentWithTooltip = withTooltip({
    anchorToGridCellText,
    component,
    inputComponent,
    keyToActionMap,
    networkRequestPromise: getNetworkRequestPromise(type),
    position,
    sensitivity,
    style,
    timeout,
    requestArgs() {
      return {
        indexSearchType: this.props.indexSearchType,
        recordName: this.props.tooltipId || this.props.id
      }
    }
  })(TooltipWrapper)

  return ComponentWithTooltip
}
