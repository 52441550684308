import React, { memo } from 'react'
// import { AllModules, LicenseManager } from '@ag-grid-enterprise/all-modules'
// import { AgGridReact } from '@ag-grid-community/react'
// import { AgGridReact } from 'ag-grid-react'
// import 'ag-grid-community/dist/styles/ag-grid.css'
// import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css'
// import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css'
import { connect } from 'react-redux'
import { isEqual, transform, isObject } from 'lodash'

LicenseManager.setLicenseKey(
  'CompanyName=Ddi System, LLC,LicensedApplication=Inform,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-018918,ExpiryDate=30_October_2022_[v2]_MTY2NzA4NDQwMDAwMA==42710b6b4bb0b5cbe3722f426fa8125a'
)
const difference = (object, base) =>
  transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value
    }
  })

const whitelist = ['rowData', 'columnDefs', 'height', 'masterDetail']

class WrappedGrid extends React.Component {
  static defaultProps = {
    immutableData: true,
    rowSelection: 'single',
    rowData: []
  }

  constructor(props) {
    super(props)
    console.log('wrapped grid constructed')
  }

  // shouldComponentUpdate(nextProps) {
  //   const ret =
  //     this.props.children ||
  //     nextProps.children ||
  //     Object.keys(nextProps).some(
  //       prop =>
  //         whitelist.includes(prop) &&
  //         !isEqual(this.props[prop], nextProps[prop])
  //     )
  //   console.log('wrapped grid should update', ret)
  //   return ret
  // }

  // shouldComponentUpdate(nextProps) {
  //   const ret =
  //     this.props.children ||
  //     nextProps.children ||
  //     Object.keys(nextProps).some(prop => {
  //       const r =
  //         whitelist.includes(prop) &&
  //         !isEqual(this.props[prop], nextProps[prop])
  //       // if (r) {
  //       //   console.log(
  //       //     'wrapped grid should update diff',
  //       //     difference(this.props[prop], nextProps[prop])
  //       //   )
  //       // }
  //       return r
  //     })
  //   // console.log('wrapped grid should update', ret)
  //   return ret
  // }

  componentDidUpdate(prevProps) {
    // console.log('wrapped grid updated', difference(prevProps, this.props))
  }

  render() {
    // console.log('wrapped grid rendered')
    /* eslint-disable no-unused-vars */
    const {
      dispatch,
      emptyRow,
      focusCell,
      allowDuplicates,
      allowFieldChooser,
      skipValidationOnUnmount,
      ...rest
    } = this.props
    return (
      <AgGridReact
        ref={this.props.gridRef}
        reactNext
        {...rest}
        // modules={AllModules}
      />
    )
  }
}
export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(WrappedGrid)
