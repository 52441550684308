import React from 'react'
import PropTypes from 'prop-types'

export const AddressLink = ({ href, address }) => (
  <a href={href} rel="noopener noreferrer" target="_blank">
    {address}
  </a>
)

AddressLink.propTypes = {
  address: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
}


export default AddressLink
