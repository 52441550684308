import { Set } from 'immutable'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as DDIMASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { behaviors as ddiFormBehaviors } from 'ddiForm/reducer'
import masterScreenBehaviors from 'ddiForm/MasterScreen/behaviors'
import { getIn, fromJS, setIn, deleteIn } from 'utils'
import * as CONSTANTS from './constants'

export default {
  [CONSTANTS.TOGGLE_PRE_NEW_MODE]: (state, action) => {
    let result = state

    if (getIn(result, 'preNewMode')) {
      result = setIn(result, 'preNewMode', false)
      result = deleteIn(result, 'values.preNewText')
    } else {
      result = setIn(result, 'preNewMode', true)
    }

    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
      result,
      action
    )

    result = setIn(result, 'fields.dataId.value', '')
    result = setIn(result, 'values.dataId', '')
    result = setIn(result, 'editedFields', Set())

    return result
  },
  [CONSTANTS.SAVE_PRE_NEW_MODE_VALUE]: (state, { payload: { value } }) => {
    let result = state
    result = setIn(result, 'values.preNewText', value)

    return result
  },
  [DDIMASTER_CONSTANTS.CANCEL_EDIT.SUCCESS]: (state, action) => {
    let result = state
    result = masterScreenBehaviors[DDIMASTER_CONSTANTS.CANCEL_EDIT.SUCCESS](
      result,
      action
    )

    if (getIn(result, 'values.isNew')) {
      result = deleteIn(result, 'values.isNew')
      result = setIn(result, 'fields.dataId.value', '')
      result = setIn(result, 'values.dataId', '')
      result = masterScreenBehaviors[DDIMASTER_CONSTANTS.RESET_MASTER_FIELDS](
        result,
        action
      )
    }

    if (!action?.payload?.dataId) {
      result = setIn(result, 'hasRecord', false)
    }

    result = setIn(result, 'isEditing', false)
    result = setIn(result, 'values.isLocked', false)
    result = setIn(result, 'values.validationErrors', fromJS([]))
    return result
  },
  [DDICONSTANTS.SAVE.SUCCESS]: (state, action) => {
    let result = state
    result = ddiFormBehaviors[DDICONSTANTS.SAVE.SUCCESS](result, action)

    if (getIn(result, 'values.isNew')) {
      result = deleteIn(result, 'values.isNew')
    }

    result = setIn(result, 'values.validationErrors', fromJS([]))
    return result
  },
  [DDICONSTANTS.SAVE.FAILURE]: (state, action) => {
    let result = state

    if (action?.payload?.validationErrors) {
      result = setIn(
        result,
        'values.validationErrors',
        fromJS(action.payload.validationErrors)
      )
    }

    return result
  }
}
