import React from 'react'
import { withContext } from 'ddiForm'
import { getIn, is } from 'utils'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce } from 'lodash'
import { removeModal } from 'modals/actions'
import { handleSerialNumberReviewChange } from '../../../actions'

const marginRight = { marginRight: 5 }

const ActionButtons = ({ form, modalId, initialRequestParams }) => {
  const dispatch = useDispatch()

  const cancelSerialReview = debounce(e => {
    dispatch(
      handleSerialNumberReviewChange.try(form, {
        apiParams: {
          cancel: true
        },
        modalId
      })
    )
  }, 300)

  const commitSerialReview = debounce(e => {
    dispatch(
      handleSerialNumberReviewChange.try(form, {
        apiParams: {
          action: 'commit',
          cancel: false
        },
        initialRequestParams,
        modalId
      })
    )
  }, 300)

  return (
    <div
      className="serial-number-editor-interface-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 0 0 0'
      }}
    >
      <Button
        onClick={commitSerialReview}
        variant="contained"
        style={marginRight}
      >
        Save
      </Button>
      <Button onClick={cancelSerialReview} variant="contained">
        Cancel
      </Button>
    </div>
  )
}

export default ActionButtons
