import React, { useEffect } from 'react'
import { withContext } from 'ddiForm'
import Order from 'pages/SalesOrder/tabs/Order'
import { getIn, emptyList } from 'utils'
import usePrevious from 'hooks/usePrevious'

export const OrderWrapper = ({ dataId, orderId, lineItemsList, ...other }) => {
  const orderRef = React.createRef()
  const lineItems = lineItemsList?.toJS ? lineItemsList.toJS() : []
  const prevOrderId = usePrevious(orderId)

  useEffect(() => {
    if (lineItems && lineItems.length && orderId && orderId !== prevOrderId) {
      /* 
        focus on the first lineItem on getting a new order number
        (test against orderId to ensure that the API has returned data
        and the grid has items) -- SVE 2/8/2021
      */
      setTimeout(() => {
        if (orderRef?.current?.gridApi) {
          orderRef.current.gridApi.forEachNode(node => {
            if (node?.data?.lineNumber && node.data.lineNumber === 1) {
              node.setSelected(true)
            } else {
              node.setSelected(false)
            }
          })
        }
      }, 0)
    }
  }, [orderId, prevOrderId])

  return <Order {...other} ref={orderRef} />
}

export default withContext({
  dataId: form => getIn(form, 'values.dataId') || '',
  orderId: form => getIn(form, 'values.orderId') || '',
  lineItemsList: form => getIn(form, 'fields.lineItems.rowData') || emptyList
})(OrderWrapper)
