import { getIn, setIn, toCamelCase } from 'utils'
import { fromJS } from 'immutable'
import editableGridBehaviors from 'components/EditableGrid/reducer'
import printDocumentModalBehaviors from 'components/PrintDocumentModal/reducer'
import * as CONSTANTS from './constants'

export const updateSelectionCriteria = (state, data, type) => {
  for (const prop in data) {
    if (typeof data[prop] === 'object') {
      let value = data[prop]
      if (prop === 'equalToCriterias') {
        value = data[prop].reduce((acc, next) => {
          acc = acc.concat({
            ...next,
            enabledOrig: next.enabled,
            itemsOrig: next.items // clone a copy for the 'reset' feature of this interface
          })
          return acc
        }, [])

        /* sort it */
        value = value.sort((a, b) => {
          if (a.description < b.description) {
            return -1
          }

          if (a.description > b.description) {
            return 1
          }

          return 0
        })

        /* we don't need this anymore now that the initialValues
          are set as part of the registerField action
        */
        for (let i = 0; i < data[prop].length; i++) {
          const item = data[prop][i]
          const descKey = toCamelCase(item.description)
          state = setIn(
            state,
            `fields.selectionCriteria.${type}.inputs.${descKey}.value`,
            item.equalToType
          )
          state = setIn(
            state,
            `fields.selectionCriteria.${type}.inputs.${descKey}.defaultValue`,
            item.equalToType
          )
        }
      }
      state = setIn(state, `values.selectionCriteria.${prop}`, fromJS(value))
    } else if (prop === 'sortByField' || prop === 'sortDirectionType') {
      // eslint-disable-line
      state = setIn(
        state,
        `fields.selectionCriteria.${type}.inputs.${prop}.value`,
        data[prop]
      )
      state = setIn(
        state,
        `fields.selectionCriteria.${type}.inputs.${prop}.defaultValue`,
        data[prop]
      )
    }
  }

  return state
}

const printOrderKey = 'values.selectionCriteria.fieldSelection.printOrder'
const printOrderOrigKey =
  'values.selectionCriteria.fieldSelection.printOrderOrig'

const selectionCriteriaBehaviors = {
  [CONSTANTS.GET_REPORT_CRITERIA.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'values.selectionCriteria', fromJS({}))
    result = setIn(result, 'fields.selectionCriteria', fromJS({}))
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.GET_LABEL_PRINTING_CRITERIA.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'values.selectionCriteria', fromJS({}))
    result = setIn(result, 'fields.selectionCriteria', fromJS({}))
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.GET_LABEL_PRINTING_CRITERIA.SUCCESS]: (
    state,
    { payload, meta }
  ) => {
    let result = state
    result = updateSelectionCriteria(result, payload, 'Labels')
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.GET_REPORT_CRITERIA.SUCCESS]: (
    state,
    { payload: { selectionCriteria, fieldSelection }, meta: { form } }
  ) => {
    let result = state
    result = updateSelectionCriteria(result, selectionCriteria, 'Report')

    for (const prop in fieldSelection) {
      result = setIn(
        result,
        `values.selectionCriteria.fieldSelection.${prop}`,
        fromJS(fieldSelection[prop])
      )

      /* save a backup of the defaults for the 'Reset' feature */
      if (prop === 'printOrder') {
        result = setIn(result, printOrderOrigKey, fromJS(fieldSelection[prop]))
      }
    }

    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.UPDATE_PRINT_ORDER]: (state, { payload: { printOrder } }) => {
    let result = state
    if (printOrder && Array.isArray(printOrder)) {
      result = setIn(result, printOrderKey, fromJS(printOrder))
    }
    return result
  },
  [CONSTANTS.DELETE_PRINT_ORDER_ITEM]: (
    state,
    { payload: { dataId, rowIndex }, meta: { form } }
  ) => {
    let result = state
    let printOrder = getIn(result, printOrderKey)
    printOrder = printOrder.delete(rowIndex)
    result = setIn(result, printOrderKey, printOrder)
    return result
  },
  [CONSTANTS.RESET_FIELD_SELECTION]: (state, action) => {
    let result = state
    // const printOrdersOrig = getIn(result, printOrderOrigKey)
    result = setIn(result, printOrderKey, fromJS([]))
    return result
  },
  [CONSTANTS.TOGGLE_CRITERIA_SELECTION]: (
    state,
    { payload: { dataId }, meta: { form } }
  ) => {
    let result = state
    const key = 'values.selectionCriteria.equalToCriterias'
    let criteria = getIn(state, key)

    const rowIndex = criteria.findIndex(item => item.get('dataId') === dataId)
    criteria = criteria.update(rowIndex, row =>
      row.set('enabled', !row.get('enabled'))
    )

    result = setIn(result, key, criteria)
    return result
  },
  [CONSTANTS.TOGGLE_EQUAL_TO_CRITERIA]: (
    state,
    {
      payload: { criteriaName, gridPath, selectedRows, rowIndex },
      meta: { form }
    }
  ) => {
    let result = state

    /* must update the original equalToCriterias as well for this stuff */
    const key = 'values.selectionCriteria.equalToCriterias'
    let criteria = getIn(result, key)
    const idx = criteria.findIndex(item => item.get('dataId') === criteriaName)
    criteria = criteria.update(idx, row =>
      row.set(
        'items',
        row
          .get('items')
          .update(rowIndex, item =>
            item.set('isSelected', selectedRows.includes(item.get('dataId')))
          )
      )
    )

    result = setIn(result, key, criteria)
    let rowData = getIn(result, gridPath)

    rowData = rowData.update(rowIndex, item =>
      item.set('isSelected', selectedRows.includes(item.get('dataId')))
    )

    result = setIn(result, gridPath, rowData)
    return result
  },
  [CONSTANTS.CLEAR_CRITERIA_SELECTION]: (state, action) => {
    let result = state
    result = setIn(result, 'values.selectionCriteria', fromJS({}))
    return result
  },
  [CONSTANTS.RESET_CRITERIA_SELECTION]: (
    state,
    { payload: { type }, meta: { form } }
  ) => {
    let result = state
    const equalToCriteriasKey = 'values.selectionCriteria.equalToCriterias'
    let equalToCriterias = getIn(result, equalToCriteriasKey)
      ? getIn(result, equalToCriteriasKey).toJS()
      : []

    equalToCriterias = equalToCriterias.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        enabled: next.enabledOrig,
        items: next.itemsOrig ? next.itemsOrig : next.items
      })
      return acc
    }, [])

    const inputFieldsKey = `fields.selectionCriteria.${type}.inputs`
    const gridFieldsKey = `fields.selectionCriteria.${type}.grids`

    for (let i = 0; i < equalToCriterias.length; i++) {
      const descKey = toCamelCase(equalToCriterias[i].description)
      const key = `${inputFieldsKey}.${descKey}`
      const gridKey = `${gridFieldsKey}.${descKey}.rowData`
      const isPendingKey = `${gridFieldsKey}.${descKey}.isPending`
      result = setIn(
        result,
        key,
        fromJS({
          value: equalToCriterias[i].equalToType,
          prevValue: ''
        })
      )

      result = setIn(result, `${gridFieldsKey}.${descKey}.grid`, true)
      result = setIn(result, gridKey, fromJS(equalToCriterias[i].itemsOrig))
      result = setIn(result, isPendingKey, false)
      result = setIn(
        result,
        `${gridFieldsKey}.${descKey}.emptyRow`,
        fromJS({
          dataId: '',
          description: ''
        })
      )
    }

    result = setIn(
      result,
      'values.selectionCriteria.equalToCriterias',
      fromJS(equalToCriterias)
    )

    const additionalCriteriaKey = `fields.selectionCriteria.${type}.grids.additionalCriteria`

    result = setIn(
      result,
      `${additionalCriteriaKey}.rowData`,
      getIn(result, 'values.selectionCriteria.additionalCriteria')
    )
    result = setIn(result, `${additionalCriteriaKey}.isPending`, false)

    /* reset the 'Sort By' field */
    result = setIn(
      result,
      `${inputFieldsKey}.sortByField`,
      fromJS({
        defaultValue: getIn(
          result,
          `${inputFieldsKey}.sortByField.defaultValue`
        ),
        value: getIn(result, `${inputFieldsKey}.sortByField.defaultValue`),
        prevValue: getIn(result, `${inputFieldsKey}.sortByField.value`)
      })
    )

    /* reset the 'Sort Direction' field */
    result = setIn(
      result,
      `${inputFieldsKey}.sortDirectionType`,
      fromJS({
        defaultValue: getIn(
          result,
          `${inputFieldsKey}.sortDirectionType.defaultValue`
        ),
        value: getIn(
          result,
          `${inputFieldsKey}.sortDirectionType.defaultValue`
        ),
        prevValue: getIn(
          result,
          `${inputFieldsKey}.sortDirectionType.defaultValue`
        )
      })
    )

    return result
  },
  [CONSTANTS.GET_LABEL_PRINTING_DEFAULTS.SUCCESS]: (
    state,
    { payload, meta }
  ) => {
    let result = state
    for (const prop in payload) {
      result = setIn(
        result,
        `fields.selectionCriteria.Labels.printLabels.${prop}.value`,
        payload[prop]
      )
    }

    return result
  },
  [CONSTANTS.VIEW_REPORT.REQUEST]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', true)
    return result
  },
  [CONSTANTS.VIEW_REPORT.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.EXIT_PRINT_LABELS.SUCCESS]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.CLEAR_SELECTION_CRITERIA_FIELDS]: (state, action) => {
    let result = state
    result = setIn(result, 'values.selectionCriteria', fromJS({}))
    result = setIn(result, 'fields.selectionCriteria', fromJS({}))
    return result
  },
  [CONSTANTS.DELETE_ONLY_GRID_ROW]: (
    state,
    { payload: { propertyName, rowIndex } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`
    let rowData = getIn(result, rowDataKey)

    if (rowData && rowData.size && rowData.size === 1) {
      rowData = rowData.delete(rowIndex)
      result = setIn(result, rowDataKey, rowData)
      result = setIn(result, `fields.${propertyName}.isPending`, false)
      result = setIn(result, `values.${propertyName}`, rowData)
    }

    return result
  },
  [CONSTANTS.SET_INITIAL_ROW_DATA]: (
    state,
    { payload: { propertyName, rowData } }
  ) => {
    let result = state
    const rowDataKey = `fields.${propertyName}.rowData`

    result = setIn(result, rowDataKey, fromJS(rowData))
    result = setIn(result, `fields.${propertyName}.initialRowDataSet`, true)

    return result
  },
  [CONSTANTS.GET_REPORT_CRITERIA.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  [CONSTANTS.GET_LABEL_PRINTING_CRITERIA.FAILURE]: (state, action) => {
    let result = state
    result = setIn(result, 'isPosting', false)
    return result
  },
  ...editableGridBehaviors,
  ...printDocumentModalBehaviors
}

export default selectionCriteriaBehaviors

// const initialState = fromJS({})

// const selectionCriteriaReducer = createReducer(initialState, behaviors)

// export default function reducer() {
//   const enhancer = duck => (state = initialState, action = {}) => {
//     debugger
//     const form = action && action.meta && action.meta.form
//     // form = form && !form.includes('dashboard')
//     if (form) {
//       // return state
//       return duck(state, action)
//     }
//     // if (action.type === AUTHENTICATION_ERROR) {
//     //   return initialState
//     // }
//     // return duck(state, action)
//     return state
//   }
//   return enhancer(selectionCriteriaReducer)
// }
