import React from 'react'
import TooltipField from 'hoc/TooltipField'

const CellRendererSelector = (
  Component,
  defaultArgs = {},
  logic = () => true,
  options = {}
) => {
  // console.log(rest)
  return props => {
    console.log(props)

    const renderComponent = logic(props)
    if (renderComponent) {
      if (options?.tooltipField) {
        Component = TooltipField(defaultArgs)(Component)
      }
      return <Component {...props} />
    }
    // if (options?.tooltipField) {
    // Component =
    // }

    return <div>{props.data.dataId}</div>
  }
}

export default CellRendererSelector
