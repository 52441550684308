/* eslint react/sort-comp: 0, no-lonely-if: 0 */
import React, { useEffect } from 'react'
import Grid from 'grid'
import { withContext } from 'ddiForm'
import { formatDollarFields, emptyList } from 'utils'
import {
  resizeGrid,
  getAdditionalDataFromContext
} from 'pages/SalesOrder/utils'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import TooltipField from 'hoc/TooltipField'
import SelectSubstituteBtn from './SelectSubstituteBtn'

const getRowNodeId = data => data.dataId
let substitutesGridColumnApi = null
// let substitutesGridApi = null

let _isMounted = false

const SubstitutesGrid = ({
  form,
  height,
  dispatch,
  rowData,
  layoutState,
  gridApi,
  isEditing,
  isMobile = false
}) => {
  const onGridReady = params => {
    substitutesGridColumnApi = params.columnApi
    // substitutesGridApi = params.api
  }

  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  useEffect(() => {
    if (layoutState && substitutesGridColumnApi && _isMounted) {
      resizeGrid(substitutesGridColumnApi)
    }
  }, [layoutState])

  const productColumn = isMobile
    ? {
        field: 'dataId',
        headerName: 'Product',
        minWidth: 100
      }
    : {
        field: 'dataId',
        headerName: 'Product',
        minWidth: 100,
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'Product',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          form,
          linkTo: 'productAnalysis',
          groupNames: ['analysis', 'overview']
        }
      }

  let columnDefs = [
    {
      field: 'dataId',
      headerClass: 'text-center align-center',
      headerName: 'Select',
      cellRendererFramework: SelectSubstituteBtn,
      cellRendererParams: {
        form,
        dispatch,
        gridApi,
        isEditing,
        isMobile
      },
      maxWidth: 70,
      minWidth: 70,
      suppressMenu: true
    },
    productColumn,
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 150
    },
    {
      field: 'uomId',
      headerName: 'UOM'
    },
    {
      field: 'price',
      headerName: 'Price',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align',
      valueFormatter: formatDollarFields
    },
    {
      field: 'available',
      headerName: 'Avail',
      cellClass: 'align-right right-align',
      headerClass: 'align-right right-align'
    }
  ]

  if (isMobile) {
    columnDefs = columnDefs.reduce((acc, next) => {
      acc = acc.concat({
        ...next,
        minWidth: next?.minWidth || 100
      })
      return acc
    }, [])
  }

  return (
    <div style={{ width: '100%' }}>
      <Grid
        form={form}
        id="substitutes"
        height={height}
        getRowNodeId={getRowNodeId}
        columnDefs={columnDefs}
        rowData={rowData?.toJS ? rowData.toJS() : rowData}
        onGridReady={onGridReady}
        width="100%"
      />
    </div>
  )
}

/* updated 9/25/2020 SVE */
export default withContext({
  isEditing: form => form.get('isEditing') || false,
  rowData: form => getAdditionalDataFromContext(form, 'substitutes', emptyList)
})(SubstitutesGrid)
