import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withContext, Label } from 'ddiForm'
import { empty, getRgb, getValue, getIn } from 'utils'
import { DDIIndexSearch } from 'ddiForm/wrapped'
import { openScreen } from 'pages/Main/actions'
import { Icon } from '@material-ui/core'
import {
  // FirstNameStyled,
  LabelStyled,
  SearchWrapper,
  WrapperStyled
} from 'pages/ContactMaster/styles/masterStyle'

export class SearchArea extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    hasRecord: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    newMode: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    statusColor: PropTypes.object.isRequired,
    // values: PropTypes.object.isRequired
  }

  onSetField = (a, v, c, data) => {
    if (data) {
      let name
      let title
      if (data.recordType === 'Contacts') {
        name = 'contact'
        title = 'Contact Master'
      } else {
        name = data.recordType
        title = data.recordType
      }
      this.props.dispatch(
        openScreen({
          ...data,
          // image,
          name,
          title
        })
      )
    }
  }

  // bindRef = c => (this.search = c)

  getStatusOptionIcon = () => {
    const { status } = this.props

    let result

    if (
      status.includes('Cash in Advance') ||
      status.includes('C.O.D.') ||
      status.includes('Point Of Sale')
    ) {
      result = 'monetization_on'
    } else if (status.includes('Active/Good')) {
      result = 'check_circle'
    } else if (status.includes('Hold')) {
      result = 'outlined_flag'
    } else if (status.includes('Inactive')) {
      result = 'offline_bolt'
    } else if (status.includes('Legal')) {
      result = 'gavel'
    } else if (status.includes('Prospect')) {
      result = 'business_center'
    } else if (status.includes('Credit Card')) {
      result = 'credit_card'
    } else if (status.includes('Watch')) {
      result = 'visibility'
    } else {
      result = ''
    }

    return result
  }

  render() {
    const {
      description,
      // values: { dataId = '', firstName = '', lastName = '' },
      hasRecord,
      isEditing,
      newMode,
      recordName,
      status,
    } = this.props

    const statusColor = this.props.statusColor.toJS()

    // const title = is.null(firstName) || is.null(lastName) ? '' : `${firstName} ${lastName}`

    return (
      <WrapperStyled>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '5px 10px',
            flexWrap: 'nowrap',
            width: '50%'
          }}
        >
          <SearchWrapper>
            <LabelStyled>Contact:</LabelStyled>
            <DDIIndexSearch
              propertyName="dataId"
              disableLastSearchButton
              fullWidth
              meta={{
                allowInstantSearch: true,
                hasFilters: true,
                minimumKeywordLength: 2,
                searchType: 'contacts'
              }}
              metaKey="Contact"
              inputRef={c => (this.search = c)}
              includeParent
              leader
              preventAutoDisable
              // onSetField={this.onSetField}
              disabled={newMode || isEditing}
              recordName={recordName}
              moreInfo
            />
            {hasRecord ? (
              <div style={{ marginLeft: 15 }}>
                <Label
                  propertyName="description"
                  labelStyle={{ width: '100%' }}
                  value={description}
                />
              </div>
            ) : null}
          </SearchWrapper>
        </div>
        <div
          className="cm-search-wrapper cm-search-wrapper-rank"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: '5px 10px',
            width: '50%'
          }}
        >
          <div style={{ marginLeft: 15, width: 150 }}>
            {hasRecord && (
              <div
                variant="contained"
                style={{
                  background:
                    Object.keys(statusColor).length > 0
                      ? getRgb(statusColor)
                      : '#d9534f',
                  borderRadius: 4,
                  color: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 27,
                  fontSize: 12,
                  flexDirection: 'row',
                  width: '100%'
                }}
              >
                <Icon
                  style={{
                    color: '#fff',
                    fontSize: 16,
                    marginRight: 5,
                    verticalAlign: 'middle'
                  }}
                >
                  {this.getStatusOptionIcon()}
                </Icon>
                {status}
              </div>
            )}
          </div>
        </div>
      </WrapperStyled>
    )
  }
}

export const contextObj = {
  description: form => getValue(form, 'description', ''),
  hasRecord: form => getIn(form, 'hasRecord') || false,
  isEditing: form => getIn(form, 'isEditing') || false,
  newMode: form => getIn(form, 'newMode') || false,
  status: form => getValue(form, 'parent.status.description', ''),
  statusColor: form => getValue(form, 'parent.status.color', empty),
  recordName: form => getValue(form, 'recordName', '')
}

export default withContext(contextObj)(SearchArea)
