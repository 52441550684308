/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { deleteGridRow, deleteGridRowNew } from 'components/EditableGrid/utils'
import { getIn } from 'utils'
import shouldUpdate from 'hoc/shouldUpdate'
import CancelIcon from './icons/CancelIcon'

const mapStateToProps = (state, ownProps) => {
  const { form, propertyName } = ownProps
  const isPending = getIn(
    state,
    `ddiForm.${form}.fields.${propertyName}.isPending`
  )

  const rowData =
    getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`) ||
    getIn(state, `ddiForm.${form}.fields.${propertyName}.value`)

  return {
    rowData: rowData && rowData.toJS ? rowData.toJS() : []
  }
}

class DeleteGridRowCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    // deleteContactAsync: PropTypes.func.isRequired,
    // rowData: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ]).isRequired
  }

  constructor(props) {
    super(props)

    const {
      colDef: {
        cellRendererParams: { disabledFn, requiredFn, tooltip }
      }
    } = props

    const tooltipDisplay =
      tooltip && props.data[tooltip] ? props.data[tooltip] : ''

    this.state = {
      tooltipDisplay: tooltipDisplay || ''
    }

    this.deleteGridRow = deleteGridRowNew.bind(this)
    // this.clearGridRow = clearGridRow.bind(this)

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef.cellRendererParams.disabledFn = this.props.colDef.cellRendererParams.disabledFn.bind(
        this
      )
    }
  }

  deleteGridRow = () => this.deleteGridRow()

  isDisabled = () => {
    const {
      colDef: {
        cellRendererParams: { disabledFn }
      },
      data
    } = this.props

    if (disabledFn) {
      return disabledFn(data)
    }

    return false
  }

  render() {
    const { tooltipDisplay } = this.state

    return (
      <CancelIcon
        title={tooltipDisplay}
        onClick={this.deleteGridRow}
        disabled={this.isDisabled()}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(
  shouldUpdate({
    whitelist: ['data', 'rowData', 'propertyName', 'rowIndex', 'value']
  })(DeleteGridRowCell)
)

// export default connect(
//   null,
//   null,
//   null,
//   { forwardRef: true }
// )(DeleteGridRowCell)
