/* eslint react/sort-comp: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import { getIn, toCamelCase } from 'utils'
import HoverIntent from 'react-hoverintent'
import { Tooltip } from 'react-tippy'

// import addShipToInModal from 'pages/CustomerShipTo/addShipToInModal'
import addShipToInModal from 'components/addShipToInModal'

import nl2br from 'react-newline-to-break'
import { openContact, showShipToWarningModal } from '../actions'
import { RightFooterButtons } from '../../ProductMaster/components/RightFooterButtons'

const generateScreenParams = obj => {
  const ret = Object.keys(obj).reduce((acc, next) => {
    if (next === 'fullDescription') {
      acc.name = toCamelCase(obj[next])
      acc.title = obj[next]
    } else if (next === 'imageFileName') {
      acc.image = obj[next]
    } else if (next === 'dataId') {
      acc.dataId = obj[next]
    } else if (next === 'parentId') {
      acc.parentId = obj[next]
    } else {
      acc[next] = obj[next]
    }

    return acc
  }, {})

  return ret
}

const mapStateToProps = (state, ownProps) => {
  const {
    colDef: {
      linkTo,
      form,
      additionalOpenScreenParams = [],
      groupNames,
      image,
      title
    },
    value
  } = ownProps
  if (linkTo) {
    let openScreenParams = getIn(state, `auth.screensMap.${linkTo}`)
    openScreenParams = openScreenParams ? openScreenParams.toJS() : {}

    openScreenParams =
      title && image && linkTo
        ? {
            ...openScreenParams,
            groupNames,
            name: linkTo,
            image,
            title,
            dataId: value
          }
        : {
            ...openScreenParams,
            groupNames,
            dataId: value
          }

    /* customerShipTo is not in auth.screensMap */
    if (linkTo === 'customerShipTo') {
      openScreenParams = {
        ...openScreenParams,
        fullDescription: 'Customer Ship To',
        groupNames: ['setup', 'information']
      }
      // debugger
    }

    /* for opening a screen or record with a parentId */
    if (form && additionalOpenScreenParams) {
      if (additionalOpenScreenParams.includes('parentId')) {
        openScreenParams = {
          ...openScreenParams,
          parentId:
            getIn(state, `ddiForm.${form}.fields.dataId.value`) ||
            getIn(state, `ddiForm.${form}.values.dataId`)
        }
      }
    }

    return {
      openScreenParams:
        name && image && linkTo
          ? openScreenParams
          : generateScreenParams(openScreenParams)
    }
  }

  return {}
}

class GridLinkCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    openScreenParams: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object
    ])
  }

  static defaultProps = {
    openScreenParams: {},
    value: ''
  }

  constructor(props) {
    super(props)

    this.state = {
      hoverIntended: false
      // tooltip: null
    }

    this.tooltipMarkup = null
    // this.openScreen = debounce(this.openScreen, 100)
  }

  openScreen = debounce(e => {
    const {
      data,
      colDef: { linkTo, form },
      openScreenParams = {},
      value
    } = this.props
    // debugger
    /* show a warning modal if the user is editing the 'SAME' Ship To */
    if (linkTo === 'customerShipTo') {
      // debugger
      this.props.dispatch(
        addShipToInModal({
          ...openScreenParams,
          dataId: value,
          parentId: openScreenParams.parentId
            ? openScreenParams.parentId
            : data.customerId,
          parentType: 'C',
          name: 'customerShipTo',
          groupNames: ['setup', 'information']
        })
      )
    } else if (linkTo === 'contacts') {
      const {
        dataId = '',
        description = '',
        parentId = '',
        parentType = ''
      } = data

      this.props.dispatch(
        openContact.try(form, { dataId, description, parentId, parentType })
      )
    } else if (linkTo === 'salesOrder') {
      this.props.dispatch(
        openScreenAction({
          image: 'sales_order_entry_32.png',
          imageFileName16: 'sales_order_entry_16.png',
          description: 'Sales Order',
          name: 'salesOrder',
          route: 'salesorder',
          title: 'Sales Order',
          // dataId: 'JS000028',
          dataId: openScreenParams.dataId,
          newInstance: true,
          read: true
        })
      )
    } else if (linkTo === 'productAnalysis') {
      this.props.dispatch(
        openScreenAction({
          activeKey: 'analysis',
          image: 'inventory_product_analysis_16.png',
          route: 'productmaster',
          title: 'Product Analysis',
          groupNames: ['analysis', 'overview'],
          dataId: value
        })
      )
    } else if (linkTo === 'productMaster') {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          linkTo: 'productMaster',
          image: 'product_master_16.png',
          title: 'Product Master',
          groupNames: ['setup', 'main'],
          dataId: value
        })
      )
    } else if (linkTo === 'customerLedger') {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          image: 'accounting_accounts_receivable_customer_ledger_16.png',
          name: 'customerLedger',
          title: 'Customer Ledger',
          activeKey: 'ledger',
          route: 'customermaster',
          groupNames: ['summary', 'invoices']
        })
      )
    } else if (linkTo === 'invoiceInquiry') {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          image: 'sales_invoicing_invoice_inquiry_16.png',
          route: 'invoiceinquiry',
          title: 'Invoice Inquiry',
          groupNames: ['header', 'detail']
        })
      )
    } else if (linkTo === 'vendorMaster') {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          image: 'vendor_master_32.png',
          imageFileName16: 'vendor_master_16.png',
          route: 'vendorMaster',
          title: 'Vendor Master',
          groupNames: ['setup', 'main']
        })
      )
    } else if (linkTo === 'warehouse' || linkTo === 'warehouseMaster') {
      // debugger
      this.props.dispatch(
        openScreenAction({
          activeKey: null,
          image: 'company_warehouse_16.png',
          route: 'warehousemaster',
          title: 'Warehouse Master',
          dataId: value,
          groupNames: ['general']
        })
      )
    } else if (Object.keys(openScreenParams).length) {
      // debugger
      this.props.dispatch(openScreenAction(openScreenParams))
    }
  }, 500)

  generateTooltip = () => {
    const {
      colDef: { cellRendererParams }
    } = this.props

    if (
      this.tooltipMarkup === null &&
      cellRendererParams &&
      cellRendererParams.tooltip
    ) {
      const markup = (
        <div
          className="inform-tooltip"
          style={{ borderRadius: 4, maxWidth: 400 }}
        >
          <div style={{ padding: 10 }}>
            {cellRendererParams &&
            cellRendererParams.tooltip &&
            this.props.data[cellRendererParams.tooltip] ? (
              <p style={{ color: '#fff', fontSize: 11, margin: 0 }}>
                {nl2br(this.props.data[cellRendererParams.tooltip])}
              </p>
            ) : null}
          </div>
        </div>
      )

      this.tooltipMarkup = markup

      return this.tooltipMarkup
    }

    if (
      cellRendererParams &&
      cellRendererParams.tooltip &&
      this.props.data[cellRendererParams.tooltip] &&
      this.tooltipMarkup
    ) {
      return this.tooltipMarkup
    }

    return null
  }

  hoverIntended = () => this.setState({ hoverIntended: true })

  hoverNotIntended = () => this.setState({ hoverIntended: false })

  getLink = () => {
    const {
      data: { color },
      value,
      valueFormatted,
      colDef: { linkTo, screenName, cellRendererParams }
    } = this.props

    if (
      linkTo &&
      cellRendererParams &&
      cellRendererParams.dataVerificationCol &&
      !this.props.data[cellRendererParams.dataVerificationCol]
    ) {
      return <span>{valueFormatted || value}</span>
    }

    if (linkTo) {
      return (
        <a className="grid-link-cell" onClick={this.openScreen}>
          {valueFormatted || value}
        </a>
      )
    }

    return <span>{valueFormatted || value}</span>
  }

  render() {
    const {
      data: { color },
      value,
      valueFormatted,
      colDef: { linkTo, screenName }
    } = this.props
    // console.log('GridLinkCellRender', this.props)
    return (
      <Tooltip
        open={this.state.hoverIntended}
        ref={el => (this.tooltip = el)}
        html={<div>{this.generateTooltip()}</div>}
        unmountHTMLWhenHide
        position="top"
        popperOptions={{
          modifiers: { preventOverflow: { boundariesElement: 'window' } }
        }}
      >
        <div
          style={{
            display: 'block',
            width: '100%',
            height: '100%'
          }}
        >
          <HoverIntent
            onMouseOver={this.hoverIntended}
            onMouseOut={this.hoverNotIntended}
            sensitivity={20}
            interval={500}
          >
            <div
              style={{
                display: 'block',
                width: '100%',
                height: '100%'
              }}
            >
              {this.getLink()}
            </div>
          </HoverIntent>
        </div>
      </Tooltip>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(GridLinkCell)
