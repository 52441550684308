import { all, take, call, select, put, fork } from 'redux-saga/effects'
import { getIn } from 'utils'
import { api } from 'services'
import { SET_FIELD } from 'ddiForm/constants'
import { BLUR } from 'components/Search/IndexSearch/constants'
import { CANCELED, CONFIRMED } from 'modals/constants'
import { addModal, removeModal } from 'modals/actions'
import { confirmationModal } from 'modals/sagas'

import * as actions from 'pages/CustomerMaster/actions'
import * as CONSTANTS from 'pages/CustomerMaster/constants'
import TaxMatrixModal from '.'
import TaxMatrixModalActions from './TaxMatrixModalActions'

let taxMatrixModalId

export function* openTaxMatrixModal(newMatrix = true, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const customerID = getIn(formState, 'values.dataId')

  if (newMatrix) {
    yield put({
      type: CONSTANTS.INIT_NEW_TAX_MATRIX,
      meta: { form },
      payload: {
        additionalTaxPercent: '',
        customerDescription: '',
        customerID,
        dataId: '',
        isLocked: false,
        priceGroupDescription: 'All Price Groups',
        priceGroupID: '',
        productDescription: 'All Products',
        productID: '',
        productLineDescription: 'All Product Lines',
        productLineID: '',
        productTaxPriceGroup: '',
        productTaxPriceGroupDescription: 'All Tax Groups',
        shipToDescription: 'All Ship Tos',
        shipToID: '',
        // taxColumn: 'All',
        taxable: false
      }
    })
  }

  const modalOpts = {
    component: TaxMatrixModal,
    options: {
      // actions: [{ primary: true, title: 'Exit' }]
      data: {
        customerID
      },
      actions: TaxMatrixModalActions,
      maxHeight: '100%',
      title: 'Tax Matrix'
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  taxMatrixModalId = modal.payload.id
  return taxMatrixModalId
}

export function* createTaxMatrixProcess(form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const productLineId = yield getIn(
    formState,
    'fields.taxMatrix.productLineID.value'
  )
  const priceGroupId = yield getIn(
    formState,
    'fields.taxMatrix.priceGroupID.value'
  )
  const shipToId = yield getIn(formState, 'fields.taxMatrix.shipToID.value')
  const customerId = yield getIn(formState, 'fields.taxMatrix.customerID.value')
  const productId = yield getIn(formState, 'fields.taxMatrix.productID.value')
  const productTaxPriceGroup = yield getIn(
    formState,
    'fields.taxMatrix.productTaxPriceGroup.value'
  )
  // const additionalTaxPercent = yield getIn(formState, 'fields.taxMatrix.additionalTaxPercent.value')
  // const taxable = yield getIn(formState, 'fields.taxMatrix.taxable.value')
  // const taxColumn = yield getIn(formState, 'fields.taxMatrix.taxColumn.value')

  yield put(actions.createTaxMatrix.request(form))

  const { response, error } = yield call(api.createTaxMatrix, {
    dataId: `${customerId}|${productId}|${productLineId}|${shipToId}|${priceGroupId}|${productTaxPriceGroup}`
  })

  if (response) {
    yield put(actions.createTaxMatrix.success(response, form))
    if (!response.isLocked && !response.isNew) {
      /* 
        needed to add this condition, 
        which means that the user has attempted to use the 'create' method
        on an existing Tax Matrix, in which case, we are simply going to lock that
        record -- SVE 4/24/20
      */
      yield fork(lockTaxMatrixProcess, response.dataId, form)
    }
  } else {
    yield put(actions.createTaxMatrix.failure(error, form))
  }
}

export function* openTaxMatrixProcess(dataId, form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))
  const modals = getIn(formState, 'modals')
  // console.log('modals', modals)

  if (!dataId && !modals.size) {
    // debugger
    yield fork(openTaxMatrixModal, true, form)
    return
  }

  yield put({
    type: CONSTANTS.OPEN_TAX_MATRIX.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.openTaxMatrix, {
    dataId
    // dataId: '0001036||100-04|00000002|003|' // test only
  })

  if (response) {
    // debugger
    yield put({
      type: CONSTANTS.OPEN_TAX_MATRIX.SUCCESS,
      payload: {
        ...response,
        dataId
      },
      meta: { form }
    })
    // debugger

    // yield fork(openTaxMatrixModal, false, form)
    yield fork(lockTaxMatrixProcess, dataId, form, true)
  } else {
    yield put({
      type: CONSTANTS.OPEN_TAX_MATRIX.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

/* open the tax matrix modal */
export function* openTaxMatrixListener(formListener) {
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.OPEN_TAX_MATRIX.TRY)

    if (form === formListener) {
      yield fork(openTaxMatrixProcess, dataId, form)
    }
  }
}

export function* deleteTaxMatrixProcess(dataId, form, modalId) {
  if (!dataId) {
    return
  }
  // debugger

  yield put({
    type: CONSTANTS.DELETE_TAX_MATRIX.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.deleteTaxMatrix, {
    dataId
    // dataId: '0001036||100-04|00000002|003|' // test only
  })

  if (response) {
    yield put({
      type: CONSTANTS.DELETE_TAX_MATRIX.SUCCESS,
      payload: {
        ...response,
        dataId
      },
      meta: { form }
    })

    // debugger
    if (modalId) {
      yield put(removeModal(form, modalId))
    }
  } else {
    yield put({
      type: CONSTANTS.DELETE_TAX_MATRIX.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* deleteTaxMatrixListener(formListener) {
  while (true) {
    const {
      payload: { dataId, modalId, unlocked },
      meta: { form }
    } = yield take(CONSTANTS.DELETE_TAX_MATRIX.TRY)

    yield call(confirmationModal, 'Are you sure you want to delete Tax Matrix?')
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CONFIRMED) {
      // debugger
      // console.log('entire customer ID record needs to be locked')
      if (unlocked) {
        // debugger
        // debugger
        yield fork(lockTaxMatrixProcess, dataId, form, false, true)
      } else {
        // debugger
        yield fork(deleteTaxMatrixProcess, dataId, form, modalId)
      }
    }
  }
}

// yield fork(lockTaxMatrixProcess, dataId, form, true)
export function* lockTaxMatrixProcess(
  dataId,
  form,
  openLockedTaxMatrix = false,
  deleteTaxMatrix = false
) {
  yield put({
    type: CONSTANTS.LOCK_TAX_MATRIX.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.lockTaxMatrix, {
    dataId
  })

  if (response) {
    // debugger
    yield put({
      type: CONSTANTS.LOCK_TAX_MATRIX.SUCCESS,
      payload: {
        ...response,
        ignoreReducer: deleteTaxMatrix
      },
      meta: { form }
    })

    if (openLockedTaxMatrix) {
      yield fork(openTaxMatrixModal, false, form)
    }

    if (deleteTaxMatrix) {
      yield fork(deleteTaxMatrixProcess, dataId, form)
    }
  } else {
    yield put({
      type: CONSTANTS.LOCK_TAX_MATRIX.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

/* lock the tax matrix! */
export function* lockTaxMatrix(formListener) {
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.LOCK_TAX_MATRIX.TRY)

    if (form === formListener) {
      yield fork(lockTaxMatrixProcess, dataId, form)
    }
  }
}

export function* unlockTaxMatrixProcess(dataId, form) {
  yield put({
    type: CONSTANTS.UNLOCK_TAX_MATRIX.REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.unlockTaxMatrix, {
    dataId
  })

  if (response) {
    yield put({
      type: CONSTANTS.UNLOCK_TAX_MATRIX.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.UNLOCK_TAX_MATRIX.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

/* unlock the tax matrix! */
export function* unlockTaxMatrix(formListener) {
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.UNLOCK_TAX_MATRIX.TRY)

    if (form === formListener) {
      yield fork(unlockTaxMatrixProcess, dataId, form)
    }
  }
}

/* save the tax matrix record */
export function* saveTaxMatrixProcess(dataId = '', form) {
  const formState = yield select(state => getIn(state, `ddiForm.${form}`))

  // dataId, productLineId, priceGroupId, shipToId, customerId, productId, productTaxPriceGroup
  const productLineId = yield getIn(
    formState,
    'fields.taxMatrix.productLineID.value'
  )
  const priceGroupId = yield getIn(
    formState,
    'fields.taxMatrix.priceGroupID.value'
  )
  const shipToId = yield getIn(formState, 'fields.taxMatrix.shipToID.value')
  const customerId = yield getIn(formState, 'fields.taxMatrix.customerID.value')
  const productId = yield getIn(formState, 'fields.taxMatrix.productID.value')
  const productTaxPriceGroup = yield getIn(
    formState,
    'fields.taxMatrix.productTaxPriceGroup.value'
  )
  const additionalTaxPercent = yield getIn(
    formState,
    'fields.taxMatrix.additionalTaxPercent.value'
  )
  const taxable = yield getIn(formState, 'fields.taxMatrix.taxable.value')
  const taxColumn = yield getIn(formState, 'fields.taxMatrix.taxColumn.value')
  // debugger

  yield put({
    type: CONSTANTS.SAVE_TAX_MATRIX.REQUEST,
    meta: { form, apiRequest: true }
  })

  const apiParams = dataId
    ? {
        dataId,
        additionalTaxPercent,
        taxable,
        taxColumn: taxColumn !== 'All' ? taxColumn : ''
      }
    : {
        dataId: `${customerId}|${productId}|${productLineId}|${shipToId}|${priceGroupId}|${productTaxPriceGroup}`,
        productLineId,
        priceGroupId,
        shipToId,
        customerId,
        productId,
        productTaxPriceGroup
      }

  const { response, error } = yield call(api.saveTaxMatrix, apiParams)

  if (response) {
    /* not working for some reason
    yield put({
      type: REMOVE_MODAL,
      payload: { id: taxMatrixModalId }
    })
    */

    yield put({
      type: CONSTANTS.SAVE_TAX_MATRIX.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.SAVE_TAX_MATRIX.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* saveTaxMatrix(formListener) {
  while (true) {
    const {
      payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.SAVE_TAX_MATRIX.TRY)

    if (form === formListener) {
      if (dataId) {
        yield fork(saveTaxMatrixProcess, dataId, form)
      } else {
        yield fork(createTaxMatrixProcess, form)
      }
    }
  }
}

export function* setTaxMatrixFieldDescription(
  form,
  propertyName,
  results,
  value
) {
  const emptyDescriptions = {
    'taxMatrix.shipToID': 'All Ship Tos',
    'taxMatrix.priceGroupID': 'All Price Groups',
    'taxMatrix.productLineID': 'All Product Lines',
    'taxMatrix.productTaxPriceGroup': 'All Tax Groups',
    'taxMatrix.productID': 'All Product Lines'
  }

  if (value && results && results.description) {
    yield put(
      actions.setTaxMatrixPropertyDescription(form, {
        propertyName,
        description: results.description
      })
    )
  } else if (propertyName && emptyDescriptions[propertyName] && !value) {
    yield put(
      actions.setTaxMatrixPropertyDescription(form, {
        propertyName,
        description: emptyDescriptions[propertyName]
      })
    )
  }
}

export function* setFieldListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value, results = {} }
    } = yield take([BLUR, SET_FIELD])

    const matrixFields = [
      'taxMatrix.shipToID',
      'taxMatrix.priceGroupID',
      'taxMatrix.productLineID',
      'taxMatrix.productTaxPriceGroup',
      'taxMatrix.productID'
    ]
    if (form === formListener && matrixFields.includes(propertyName)) {
      yield fork(
        setTaxMatrixFieldDescription,
        form,
        propertyName,
        results,
        value
      )
    }
  }
}

export default function* taxMatrixModalSagas(form) {
  yield all([
    fork(openTaxMatrixListener, form),
    fork(lockTaxMatrix, form),
    fork(unlockTaxMatrix, form),
    fork(saveTaxMatrix, form),
    fork(deleteTaxMatrixListener, form),
    fork(setFieldListener, form)
  ])
}
