import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DDIButton } from 'ddiForm/wrapped'
import DDICardWrapper from 'components/DDICardWrapper'
import {
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core'
import { linkedRefundProcess } from 'pages/SalesOrder/actions'
import { debounce } from 'lodash'
import { getIn, numToDollar, is } from 'utils'

import './styles/linked-refund.scss'

const tableCellStyle = { fontSize: 11, lineHeight: '15px', padding: '5px 10px' }

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)

  const amountDisplay = getIn(formState, 'values.amountDisplay')
  const invoiceIds = getIn(formState, 'values.invoiceIds')
  const refundInvoices = getIn(formState, 'values.refundInvoices') || []
  const approvalStatus = getIn(formState, 'values.approvalStatus') || ''
  const transactionId = getIn(formState, 'values.transactionID') || ''

  return {
    amountDisplay,
    invoiceIds,
    refundInvoices,
    approvalStatus,
    transactionId
  }
}

export const LinkedRefund = ({
  amountDisplay = '',
  amountTendered,
  dispatch,
  form,
  invoiceIds = '',
  refundInvoices = [],
  approvalStatus,
  transactionId
}) => {
  const [invoiceSelection, setInvoiceSelection] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState('')

  const [payments, setPayments] = useState(null)
  const [selectedPayment, setSelectedPayment] = useState('')
  const [paymentDetails, setPaymentDetails] = useState(null)

  useEffect(() => {
    if (refundInvoices) {
      const data = refundInvoices.toJS ? refundInvoices.toJS() : refundInvoices
      setInvoiceSelection(data)
      setSelectedInvoice(data.length ? data[0].dataId : '')
    }
  }, [refundInvoices])

  useEffect(() => {
    if (invoiceSelection && invoiceSelection.length) {
      const initialPayments = invoiceSelection[0].refundInvoicePayments
      setPayments(initialPayments)

      if (initialPayments && initialPayments[0]) {
        setPaymentDetails(initialPayments[0])

        // const initialSelectedPayment = `${initialPayments[0].refundCardType} payment of ${numToDollar(initialPayments[0].refundTotalAmount)}`
        // setSelectedPayment(initialSelectedPayment)
        setSelectedPayment(initialPayments[0].guid)
      }
    }
  })

  function renderInvoiceOptions(values) {
    if (values && values.length) {
      return values.map(x => (
        <MenuItem key={x.dataId} value={x.dataId}>
          {x.dataId}
        </MenuItem>
      ))
    }

    return null
  }

  function handleSelectInvoice(e) {
    const { value } = e.target

    setSelectedInvoice(value)
  }

  function renderPayments() {
    if (invoiceSelection && invoiceSelection.length && selectedInvoice) {
      const data = invoiceSelection.filter(x => x.dataId === selectedInvoice)

      /* Lyndon - there was a pretty mean bug in here -- SVE 2/28/20 */
      if (
        data &&
        data[0] &&
        data[0].refundInvoicePayments &&
        Array.isArray(data[0].refundInvoicePayments)
      ) {
        return data[0].refundInvoicePayments.map(x => (
          <MenuItem key={x.guid} value={x.guid}>
            {x.amountDisplay}
          </MenuItem>
        ))
      }

      return null
    }

    return null
  }

  function handleSelectPayment(e) {
    const { value } = e.target

    if (value && payments) {
      const details = payments.filter(x => x.guid === value)[0]
      setSelectedPayment(value)
      setPaymentDetails(details)
    }
  }

  function submitProcess() {
    const data =
      payments && is.array(payments)
        ? payments.filter(x => x.guid === selectedPayment)[0]
        : []

    dispatch(linkedRefundProcess.try(form, { ...data, amountTendered }))
  }

  return (
    <div className="linked-refund-outer-wrapper outer-wrapper">
      <div className="header-wrapper">
        <div style={{ maxWidth: 150, flex: 1 }}>
          <p className="amount">Amount: {numToDollar(amountDisplay)}</p>
        </div>
        <div style={{ maxWidth: 150, flex: 1 }}>
          <p className="invoice">Invoice: {invoiceIds}</p>
        </div>
      </div>

      <div className="selection-outer">
        <div className="selection-wrapper">
          <FormControl>
            <InputLabel>Refund Invoice</InputLabel>
            <Select
              label="Refund Invoice"
              value={selectedInvoice}
              onChange={handleSelectInvoice}
            >
              {renderInvoiceOptions(invoiceSelection)}
            </Select>
          </FormControl>
        </div>

        <div className="selection-wrapper">
          <FormControl>
            <InputLabel>Payment</InputLabel>
            <Select
              label="Payment"
              value={selectedPayment}
              onChange={handleSelectPayment}
            >
              {renderPayments()}
            </Select>
          </FormControl>
        </div>
      </div>

      <DDICardWrapper title="Payment Details" contentStyle={{ padding: 0 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={tableCellStyle}>Transaction #</TableCell>
              <TableCell style={tableCellStyle}>
                {paymentDetails?.transactionId}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableCellStyle}>Card Number</TableCell>
              <TableCell style={tableCellStyle}>
                {paymentDetails?.refundCardNumber}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableCellStyle}>Card Holder</TableCell>
              <TableCell style={tableCellStyle}>
                {paymentDetails?.refundCardHolder}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableCellStyle}>Card Type</TableCell>
              <TableCell style={tableCellStyle}>
                {paymentDetails?.refundPaymentCardType}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableCellStyle}>Exp. Date</TableCell>
              <TableCell style={tableCellStyle}>
                {paymentDetails?.refundExpDate}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={tableCellStyle}>Total Amount</TableCell>
              <TableCell style={tableCellStyle}>
                {numToDollar(paymentDetails?.refundTotalAmount ?? 0)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DDICardWrapper>

      {approvalStatus ? (
        <div className="response-outer-wrapper">
          <DDICardWrapper title="Response" contentStyle={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={tableCellStyle}>Status:</TableCell>
                  <TableCell style={tableCellStyle}>{approvalStatus}</TableCell>
                </TableRow>
                {transactionId && (
                  <TableRow>
                    <TableCell style={tableCellStyle}>Transaction #:</TableCell>
                    <TableCell style={tableCellStyle}>
                      {transactionId}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </DDICardWrapper>
        </div>
      ) : (
        <div className="button-actions-wrapper">
          <div className="flex-wrapper">
            <DDIButton
              label="Process"
              variant="contained"
              onClick={submitProcess}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(LinkedRefund)
