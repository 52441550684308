import { createAction, createAsyncFormAction } from 'utils'
// import { SAVE } from 'ddiForm/constants'
import * as CONSTANTS from './constants'

export const getContact = createAsyncFormAction(CONSTANTS.GET_CONTACT)

export const deleteContact = createAsyncFormAction(CONSTANTS.DELETE_CONTACT)

export const addContact = createAsyncFormAction(CONSTANTS.ADD_CONTACT)

export const getAnalysis = createAsyncFormAction(CONSTANTS.GET_ANALYSIS)
/* for activities and launching the event modal */
export const listActivities = createAsyncFormAction(CONSTANTS.LIST_ACTIVITIES)

/* end activity actions */

/* get the audits */
export const listAudits = createAsyncFormAction(
  CONSTANTS.LIST_AUDITS,
  CONSTANTS.contact
)

// get payment profiles
export const getPaymentProfiles = createAsyncFormAction(
  CONSTANTS.GET_PAYMENT_PROFILES,
  CONSTANTS.contact
)

export const getPaymentProfilesUri = createAsyncFormAction(
  CONSTANTS.GET_PAYMENT_PROFILES_URI
  // CONSTANTS.contact
)

export const setSelectedPayment = data => {
  const action = createAction(
    CONSTANTS.SET_SELECTED_PAYMENT,
    data,
    CONSTANTS.contact
  )
  return action
}

export const clearSelectedPayment = data => {
  const action = createAction(
    CONSTANTS.CLEAR_SELECTED_PAYMENT,
    data,
    CONSTANTS.contact
  )

  return action
}

// add credit card
// export const addPaymentSource = data => {
//   const action = createAction(
//     CONSTANTS.ADD_PAYMENT_SOURCE.TRY,
//     data,
//     CONSTANTS.contact
//   )
//   return action
// }

export const addPaymentSource = createAsyncFormAction(
  CONSTANTS.ADD_PAYMENT_SOURCE
  // CONSTANTS.contact
)

// remove payment
export const removePaymentSource = createAsyncFormAction(
  CONSTANTS.REMOVE_PAYMENT_SOURCE,
  CONSTANTS.contact
)

export const handleCopyParent = createAsyncFormAction(
  CONSTANTS.HANDLE_COPY_PARENT
)

export const setShipToId = (form, data) => {
  const action = createAction(
    CONSTANTS.SET_SHIPTO_ID,
    { shipToIds: data }
    // CONSTANTS.contact
  )
  return action
}

export const setPasswordField = createAsyncFormAction(
  CONSTANTS.SET_PASSWORD_FIELD
)

export const saveContact = createAsyncFormAction(CONSTANTS.SAVE_CONTACT)

export const saveContactButton = (form, id) => ({
  meta: { form },
  payload: { id },
  type: CONSTANTS.SAVE_CONTACT.TRY
})

export const unlockContactRecord = createAsyncFormAction(
  CONSTANTS.UNLOCK_CONTACT_RECORD
)

export const setDefaultChartView = createAsyncFormAction(
  CONSTANTS.SET_DEFAULT_CHART_VIEW
)

export const openScreen = createAsyncFormAction(CONSTANTS.OPEN_SCREEN)

export const updatePhoto = (form, { base64 }) =>
  createAction(CONSTANTS.UPDATE_PHOTO, { base64 }, form)

export const lockContactForEdit = createAsyncFormAction(CONSTANTS.LOCK_CONTACT_FOR_EDIT)

export const cancelContactEdit = createAsyncFormAction(CONSTANTS.CANCEL_CONTACT_EDIT)

export const setParent = (form, { parentId, parentType }) =>
  createAction(CONSTANTS.SET_PARENT, { parentId, parentType }, form)

export const refreshAudits = (form, data) => {
  const action = createAction(
    CONSTANTS.REFRESH_AUDITS,
    { ...data },
    form
  )

  return action
}