import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { withContext } from 'ddiForm'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { Button } from '@material-ui/core'
import {
  dateFilterComparator,
  formatDateFields,
  getIn,
  getMeta,
  getValue,
  emptyList
} from 'utils'
import OpenScreenLinkCell from 'pages/ProductMaster/components/OpenScreenLinkCell'
import WarehouseTooltipCell from 'pages/ProductMaster/components/WarehouseTooltipCell'
import { headerStyle } from 'pages/ProductMaster/utils'
import Grid from 'grid'
import withDimensions from 'hoc/withDimensions'
import OpenProductSerialNumberInquiryInModal from 'components/OpenProductSerialNumberInquiryInModal'

import './styles/master-style.scss'

export const getRowNodeId = data => {
  const { warehouseId, description, serialNumber } = data
  const compId = `${warehouseId}|${description}|${serialNumber}`
  return compId
}

const dynamicColumnDefs = memoize(
  ({ form, isWarehouseMaintainingBinQuantity, type, dataId }) => {
    const colDef = [
      {
        cellRendererFramework: OpenScreenLinkCell,
        cellRendererParams: {
          form,
          hasLink: true,
          serialProduct: dataId
        },
        field: 'serialNumber',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Serial Number',
        linkTo: type
      },
      {
        cellStyle: { textAlign: 'center' },
        field: 'dateEntered',
        filter: 'agDateColumnFilter',
        filterParams: {
          clearButton: true,
          comparator: dateFilterComparator,
          inRangeInclusive: true,
          suppressAndOrCondition: true,
          browserDatePicker: true
        },
        headerClass: 'centered-label text-center',
        headerName: 'Date',
        valueFormatter: params => formatDateFields(params),
        maxWidth: 150
      },
      {
        cellRendererFramework: WarehouseTooltipCell,
        field: 'warehouseId',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        form,
        headerName: 'Warehouse',
        linkTo: 'warehouse'
      },
      {
        field: 'description',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Description'
      },
      {
        field: 'binLocation',
        filter: 'agTextColumnFilter',
        filterParams: {
          clearButton: true,
          suppressAndOrCondition: true
        },
        headerName: 'Bin Location',
        hide: !isWarehouseMaintainingBinQuantity
      }
    ]
    return colDef
  }
)

export const SerialNumbers = props => {
  const {
    dataId,
    dispatch,
    canUpdateSerialNumbers,
    form,
    height,
    isWarehouseMaintainingBinQuantity
  } = props
  let { serialNumbers } = props
  const [gridApi, setGridApi] = useState(null)

  useEffect(() => {
    if (gridApi) {
      const filter = gridApi.getFilterModel()
      if (Object.keys(filter).length) {
        gridApi.setFilterModel(null)
      }
    }
  }, [serialNumbers])

  function onGridReady({ api }) {
    setGridApi(api)
  }

  serialNumbers = serialNumbers?.toJS ? serialNumbers.toJS() : []

  const openSerialNumbersScreen = () => {
    const formParts = form && form?.split?.('.')
    const isModalForm = formParts?.length > 1

    if (dataId && dispatch) {
      if (isModalForm) {
        dispatch(OpenProductSerialNumberInquiryInModal(formParts?.[0], dataId))
      } else {
        dispatch(
          openScreenAction({
            productId: dataId,
            activeKey: null,
            image: 'prod_serialno_inquiry_16.png',
            route: 'productserialnumberinquiry',
            title: 'Product Serial Number Inquiry',
            groupNames: ['currentInventory']
          })
        )
      }
    }
  }

  return (
    <div className="product-analysis-layout-flex-column">
      <div className="product-analysis-overflow-wrapper">
        <div
          style={{
            height: canUpdateSerialNumbers ? height - 30 : height,
            paddingBottom: 35
          }}
        >
          <Grid
            title="Serial Numbers"
            getRowNodeId={getRowNodeId}
            headerStyle={headerStyle}
            onGridReady={onGridReady}
            columnDefs={dynamicColumnDefs({
              dispatch,
              form,
              isWarehouseMaintainingBinQuantity,
              type: 'serialNumber',
              dataId
            })}
            gridHeight={canUpdateSerialNumbers ? height - 30 : height}
            rowData={serialNumbers}
            rowSelection="single"
            enableSorting
            width="100%"
            fullWidth
          />
          {canUpdateSerialNumbers ? (
            <div
              style={{
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 5
              }}
            >
              <Button onClick={openSerialNumbersScreen} variant="contained">
                Update Serial Numbers
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

SerialNumbers.propTypes = {
  canUpdateSerialNumbers: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  // height: PropTypes.number.isRequired,
  isWarehouseMaintainingBinQuantity: PropTypes.bool.isRequired,
  serialNumbers: PropTypes.array.isRequired
}

export const contextObj = {
  dataId: form => getIn(form, 'fields.dataId.value') || '',
  canUpdateSerialNumbers: form =>
    getValue(form, 'serialNumbers.canUpdateSerialNumbers', false),
  isWarehouseMaintainingBinQuantity: form =>
    getMeta(form, 'isWarehouseMaintainingBinQuantity', false),
  serialNumbers: form => getValue(form, 'serialNumbers.data', emptyList)
}

const serialNumbersWithContext = withContext(contextObj)(SerialNumbers)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  serialNumbersWithContext
)
