/* eslint jsx-a11y/label-has-associated-control: 0, jsx-a11y/label-has-for: 0 */
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import shortid from 'shortid'
import { AppBar, Paper, Tab, Tabs, Toolbar } from '@material-ui/core'
import { getIn, fromJS, plainDeepEqual, toCamelCase } from 'utils'
import { withContext } from 'ddiForm'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import AwaitingBackdrop from 'components/AwaitingBackdrop'
import { DDIToggle } from 'ddiForm/wrapped'

import FileInput from './components/FileInput'
import ConfigurationFields from './components/ConfigurationFields'
import LineItemsGrid from './components/LineItemsGrid'
import MappedColumns from './components/MappedColumns'
import ActionButtons from './components/ActionButtons'
import './styles/product-import.scss'
import {
  getColumnDescription,
  associatedFieldStyleOverrides,
  sortData
} from './utils'

const ProductImport = props => {
  const {
    data: { form, lineItemsGridApi },
    hasHeaders,
    rowData,
    id,
    templateId,
    isImportingProducts
  } = props
  let { fieldMappings, sortOrder, templates } = props
  sortOrder = sortOrder?.toJS ? sortOrder.toJS() : []
  templates = templates?.toJS ? templates.toJS() : []
  fieldMappings = fieldMappings?.toJS ? fieldMappings.toJS() : []

  const lineItems = rowData?.toJS ? rowData.toJS() : []
  const selectValues =
    lineItems?.[0] && Object?.keys(lineItems[0])?.length
      ? sortData(
          Object.keys(lineItems[0]).reduce((acc, next, idx) => {
            acc = acc.concat({
              description: getColumnDescription(hasHeaders, lineItems, next),
              dataId: next
            })
            return acc
          }, []),
          sortOrder,
          'description'
        )
      : []

  const [tab, setTab] = useState('Settings')

  function handleTabChange(e, val) {
    if (val) {
      setTab(val)
    }
  }

  const actionButtonsDisabled = !lineItems?.length || isImportingProducts

  return (
    <>
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.18.5/xlsx.mini.min.js"
          type="text/javascript"
        />
      </Helmet>
      <div style={{ width: '100%', position: 'relative' }}>
        <AwaitingBackdrop
          isAwaiting={isImportingProducts}
          message="Processing Request..."
        />
        <div className="product-import-fullscreen-flex-modal-wrapper">
          <div className="product-import-interface-wrapper">
            <div className="product-import-mapped-cols-wrapper">
              <MappedColumns
                lineItems={lineItems}
                selectValues={selectValues}
                form={form}
                hasHeaders={hasHeaders}
                fieldMappings={fieldMappings}
              />
            </div>
            <div>
              <AppBar position="static" style={{ boxShadow: 'none' }}>
                <Toolbar
                  style={{
                    justifyContent: 'space-between',
                    minHeight: 35,
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                >
                  <Tabs value={tab} onChange={handleTabChange} scrollable>
                    <Tab value="Settings" label="Settings" />
                    <Tab value="Data" label="Data" />
                  </Tabs>
                </Toolbar>
              </AppBar>
              <Paper style={{ background: '#fff' }} square>
                <div
                  style={{
                    padding: 20,
                    display: tab === 'Settings' ? 'block' : 'none'
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <div className="input-row">
                      <FileInput
                        form={form}
                        hasHeaders={hasHeaders}
                        lineItems={lineItems}
                      />
                      <div className="secondary-input-wrapper">
                        <AssociatedFieldsWrapper
                          style={associatedFieldStyleOverrides}
                        >
                          <DDIToggle
                            propertyName="productImport.hasHeaders"
                            label="Has Headers"
                            preventAutoDisable
                            initialValue={true} // eslint-disable-line
                          />
                        </AssociatedFieldsWrapper>
                      </div>
                    </div>
                  </div>
                  <ConfigurationFields
                    form={form}
                    templates={templates}
                    templateId={templateId}
                  />
                </div>
                <div style={{ display: tab === 'Data' ? 'block' : 'none' }}>
                  <LineItemsGrid
                    form={form}
                    lineItems={lineItems}
                    hasHeaders={hasHeaders}
                    sortOrder={sortOrder}
                  />
                </div>
              </Paper>
            </div>
          </div>
          <ActionButtons
            form={form}
            modalId={id}
            lineItemsGridApi={lineItemsGridApi}
            actionButtonsDisabled={actionButtonsDisabled}
          />
        </div>
      </div>
    </>
  )
}

export default withContext({
  hasHeaders: form =>
    getIn(form, 'fields.productImport.hasHeaders.value') || false,
  fieldMappings: form =>
    getIn(form, 'values.productImport.fieldMappings') || fromJS([]),
  isImportingProducts: form =>
    getIn(form, 'values.productImport.isImportingProducts') || false,
  rowData: form => getIn(form, 'values.productImport.rowData') || fromJS([]),
  sortOrder: form =>
    getIn(form, 'values.productImport.sortOrder') || fromJS([]),
  templates: form => getIn(form, 'meta.productImport.templates') || fromJS([]),
  templateId: form => getIn(form, 'fields.productImport.templateId.value') || ''
})(ProductImport)
