import { createAsyncRequestTypes } from 'utils'

export const SALES_ORDER_MOBILE = '@@SALES_ORDER_MOBILE/'

export const GET_META = createAsyncRequestTypes(`${SALES_ORDER_MOBILE}GET_META`)

export const CLOSE_SALES_ORDER = createAsyncRequestTypes(
  `${SALES_ORDER_MOBILE}CLOSE_SALES_ORDER`
)

export const CLEAR_SCREEN = `${SALES_ORDER_MOBILE}CLEAR_SCREEN`

export const VIEW_PRODUCT_DETAILS = `${SALES_ORDER_MOBILE}VIEW_PRODUCT_DETAILS`

export const DESTROY_PRODUCT_DETAIL_FIELDS = `${SALES_ORDER_MOBILE}DESTROY_PRODUCT_DETAIL_FIELDS`

export const REVERT_NUMBER_SPINNER_VALUE = `${SALES_ORDER_MOBILE}REVERT_NUMBER_SPINNER_VALUE`

export const OPEN_CHECKOUT_SCREEN = createAsyncRequestTypes(
  `${SALES_ORDER_MOBILE}OPEN_CHECKOUT_SCREEN`
)

export const SET_SELECTED_ROW_INDEX = `${SALES_ORDER_MOBILE}SET_SELECTED_ROW_INDEX`

export const LAUNCH_FAST_PRODUCT_MODAL = `${SALES_ORDER_MOBILE}LAUNCH_FAST_PRODUCT_MODAL`

export const REMOVE_LINE_ITEM_ATTEMPT = `${SALES_ORDER_MOBILE}REMOVE_LINE_ITEM_ATTEMPT`

export const FETCH_ADDITIONAL_DATA = `${SALES_ORDER_MOBILE}FETCH_ADDITIONAL_DATA`

export const ON_NUMBER_SPINNER_CHANGE = `${SALES_ORDER_MOBILE}ON_NUMBER_SPINNER_CHANGE`

export const ON_LINE_ITEM_INPUT_CHANGE = `${SALES_ORDER_MOBILE}ON_LINE_ITEM_INPUT_CHANGE`

export const CLOSE_PRODUCT_DETAILS_INTERFACE = `${SALES_ORDER_MOBILE}CLOSE_PRODUCT_DETAILS_INTERFACE`

export const DELETE_LINE_ITEM_FROM_PRODUCT_DETAILS_INTERFACE = `${SALES_ORDER_MOBILE}DELETE_LINE_ITEM_FROM_PRODUCT_DETAILS_INTERFACE`

export const GET_LINE_ITEM_IMAGES = createAsyncRequestTypes(
  `${SALES_ORDER_MOBILE}GET_LINE_ITEM_IMAGES`
)

export const TOGGLE_LINE_ITEM_PANELS = `${SALES_ORDER_MOBILE}TOGGLE_LINE_ITEM_PANELS`

export const LAUNCH_FAST_CUSTOMER_MODAL = `${SALES_ORDER_MOBILE}LAUNCH_FAST_CUSTOMER_MODAL`

export const STORE_TABLE_UI_STATE = `${SALES_ORDER_MOBILE}STORE_TABLE_UI_STATE`

export const STORE_TABLE_EXPANDED_GROUPS = `${SALES_ORDER_MOBILE}STORE_TABLE_EXPANDED_GROUPS`

export const TOGGLE_PRINT_OPTIONS_INTERFACE = `${SALES_ORDER_MOBILE}TOGGLE_PRINT_OPTIONS_INTERFACE`

export const LAUNCH_ORDER_PAD_IN_MODAL = `${SALES_ORDER_MOBILE}LAUNCH_ORDER_PAD_IN_MODAL`

export const ADD_LINE_ITEMS_FROM_ORDER_PAD = `${SALES_ORDER_MOBILE}ADD_LINE_ITEMS_FROM_ORDER_PAD`

export const GET_SHIPPING_DATA = createAsyncRequestTypes(
  `${SALES_ORDER_MOBILE}GET_SHIPPING_DATA`
)
