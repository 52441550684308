import React from 'react'
import { emptyList, getIn, fromJS } from 'utils'
import withDimensions from 'hoc/withDimensions'
import { withContext } from 'ddiForm'
import { headerStyle } from 'pages/VendorMaster/utils'
import TooltipField from 'hoc/TooltipField'
import EditableGrid from 'components/EditableGrid'
import IndexSearchCell from 'components/MasterScreen/IndexSearchCell'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'

const Sources = ({
  form,
  height,
  isEditing,
  sourceCodesList,
  vendorSourceMetaMap
}) => {
  const vendorSourceMeta = vendorSourceMetaMap?.toJS
    ? vendorSourceMetaMap.toJS()
    : []

  const getColumnDefs = props => {
    const params = {
      disabled: !props.isEditing,
      focusedCell: props.focusedCell,
      form: props.form,
      indexSearchType: 'VendorSource',
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    const columnDefs = [
      {
        colId: 'dataId',
        field: 'dataId',
        headerName: 'Source Code',
        cellRendererFramework: TooltipField({
          anchorToGridCellText: true,
          indexSearchType: 'VendorSource',
          position: 'right-end',
          recordNameKey: 'value',
          sensitivity: 100,
          type: 'indexSearch'
        })(IndexSearchCell),
        cellRendererParams: {
          ...params,
          meta: vendorSourceMeta,
          isEditing: props.isEditing,
          disabled: !props.isEditing,
          indexSearchType: 'VendorSource',
          linkTo: 'vendorSourceMaster',
          propertyName: 'sourceCodes'
        }
      },
      {
        colId: 'description',
        field: 'description',
        headerName: 'Description'
      },
      {
        colId: 'delete',
        field: '',
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params,
          propertyName: 'sourceCodes',
          form,
          isEditing: props.isEditing,
          disabledFn: data => !props.isEditing
        },
        editable: false,
        suppressNavigable: true,
        maxWidth: 40
      }
    ]

    return columnDefs
  }

  return (
    <div className="vendor-grid-max-flex-layout">
      <EditableGrid
        title="Sources"
        headerStyle={headerStyle}
        form={form}
        height={height}
        propertyName="sourceCodes"
        getRowNodeId={data => data.rowId}
        columnDefs={getColumnDefs}
        emptyRow={{
          dataId: null,
          description: ''
        }}
        addButtonText="Add Vendor Source"
        showAddButtonOnlyIfEditing
        requiredCols={['dataId']}
        isEditing={isEditing}
      />
    </div>
  )
}

const SourcesWithContext = withContext({
  sourceCodesList: form =>
    getIn(form, 'fields.sourceCodes.rowData') || emptyList,
  isEditing: form => form.get('isEditing') || false,
  vendorSourceMetaMap: form =>
    getIn(form, 'meta.vendorSource') ||
    fromJS({
      allowInstantSearch: true,
      allowNavigation: false,
      allowSearchAll: true,
      allowSpecialProducts: false,
      fieldName: 'vendorSource',
      hasFilters: false,
      hideToggle: true,
      isSecure: false,
      minimumKeywordLength: 0,
      openText: null,
      searchType: 'VendorSource'
    })
})(Sources)

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%',
  minHeight: 400
})(SourcesWithContext)
