import React, { Component } from 'react'
import MasterScreenWrapper from 'ddiForm/MasterScreen/'
import Measure from 'react-measure'
import './styles/measured-modal.scss'

const WrappedMasterScreen = WrappedComponent => {
  class WrappedScreen extends Component {
    _isMounted = false

    constructor(props) {
      super(props)
      this.state = {
        dimensions: {
          height: -1,
          width: -1
        }
        // isModal: Boolean(this.props.id)
      }
    }

    componentDidMount() {
      this._isMounted = true
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    onResize = contentRect => this.setState({ dimensions: contentRect.bounds })

    render() {
      // console.log('measure', this.state.dimensions)
      const { dimensions } = this.state
      return (
        <Measure bounds onResize={this.onResize}>
          {({ measureRef }) => (
            <div
              ref={measureRef}
              className="measured-modal-wrapper"
              style={{
                height: dimensions?.height ? dimensions.height : '100%',
                maxHeight: dimensions?.height ? dimensions.height : 600,
                width: '100%'
              }}
            >
              <WrappedComponent {...this.props} />
            </div>
          )}
        </Measure>
      )
    }
  }

  return WrappedScreen
}

const MeasuredModal = WrappedMasterScreen(MasterScreenWrapper)

export default MeasuredModal
