import { call, fork, put, select, take, delay } from 'redux-saga/effects'
import { SET_FIELD, BLUR, OPEN_SCREEN, DESTROY_FIELD } from 'ddiForm/constants'
import { getFormSelector } from 'ddiForm/utils'
import { cancelEditListener } from 'ddiForm/MasterScreen/sagas'
import { CANCEL_EDIT } from 'ddiForm/MasterScreen/constants'
import { Set } from 'immutable'
import { TRY_OPEN_SCREEN } from 'pages/Main/constants'
import { clearLayoutScreenData } from 'pages/Layout/actions'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import {
  PRINT_DOCUMENT_ROUTINE_COMPLETED,
  CLOSE_REPORT_VIEWER
} from 'components/PrintDocumentModal/constants'

import { confirmationModal, warningModal } from 'modals/sagas'
import { addModal, removeModal } from 'modals/actions'
import { CANCELED, CONFIRMED, REMOVE_MODAL } from 'modals/constants'
import ReportViewer from 'components/ReportViewer'
import { getIn, is, toCamelCase } from 'utils'
import { api } from 'services'
import { saveSignatureListener } from 'pages/SalesOrder/sagas/invoicingSagas'
import SafetyDataSheetsGrid from 'pages/ShippingConfirmation/components/modals/SafetyDataSheetsGrid'
import NotesModal from 'pages/ShippingConfirmation/components/modals/NotesModal'
import BackorderSpawnedMessage from 'pages/ShippingConfirmation/components/modals/BackorderSpawnedMessage'
import Signature from 'pages/SalesOrder/tabs/Invoicing/components/Signature'
import { cancel } from 'raf'
import * as actions from './actions'
import * as CONSTANTS from './constants'

const stripNulls = [
  'header',
  'detail',
  'pickedByData',
  'signature',
  'printOptions',
  'tracking',
  'notes'
]
let taskId
let task

export const flattenResponseDataByGroupName = (response, groupNames = []) => {
  const obj = {}

  for (const prop in response) {
    if (
      groupNames.includes(prop) &&
      typeof response[prop] === 'object' &&
      response[prop] !== null
    ) {
      for (const x in response[prop]) {
        obj[x] = response[prop][x]
      }
    } else if (stripNulls.includes(prop)) {
      if (response[prop] !== null) {
        obj[prop] = response[prop]
      }
    } else {
      obj[prop] = response[prop]
    }
  }

  return obj
}

export function* getShippingConfirmationProcess(form, dataId) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing')
  const lockedRecordDataId = getIn(formState, 'lockedRecordDataId')

  if (!dataId) {
    return
  }

  if (isEditing) {
    /* 
      NOTE: this routine is intended to handle cases where
      the user has a locked record and selects a value from the 
      Sales Order Search grid, allowing the value to be reverted
      if the user did not intend to cancel the editing process.
      -- SVE 7/1/2021
    */
    const { cancellationProcessDeclined } = yield call(
      cancelShippingConfirmationProcess,
      form,
      true
    )

    if (cancellationProcessDeclined) {
      yield put(
        actions.revertToLockedDataId(form, { dataId: lockedRecordDataId })
      )
      return
    }
  }

  yield put(actions.getShippingConfirmation.request(form))
  const { response, error } = yield call(api.getShippingConfirmation, {
    dataId,
    groupNames: ['all']
  })

  // debugger
  if (response) {
    const flattenedResponse = flattenResponseDataByGroupName(response, [
      'pickedByData',
      'header',
      'detail',
      'printOptions',
      'tracking'
    ])
    yield put(actions.getShippingConfirmation.success(flattenedResponse, form))
    if (response?.messages) {
      yield call(displayWarningModals, form, response.messages)
    }

    if (response?.notes?.warning) {
      yield call(
        warningModal,
        response?.notes?.warning?.message,
        response?.notes?.warning?.modalTitle
      )
      yield take(REMOVE_MODAL)
    }

    if (response?.notes?.internalNotes) {
      yield fork(
        displayInternalNotesForEditing,
        form,
        response.notes.internalNotes
      )
    }
  } else {
    yield put(actions.getShippingConfirmation.failure(error, form))
  }
}

export function* displayInternalNotesForEditing(form, note) {
  const modalOpts = {
    component: NotesModal,
    options: {
      width: 600,
      maxHeight: 1200,
      title: 'Internal Notes',
      data: {
        form,
        note,
        actions: [
          {
            primary: true,
            title: 'Save',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.updateInternalNotes.try(form, {
                    note: this.state.note,
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            }
          },
          {
            primary: true,
            title: 'Delete',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.updateInternalNotes.try(form, {
                    note: null,
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            }
          },
          {
            primary: true,
            title: 'Exit'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield take(act => {
    return act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
  })

  return modal.payload.id
}

export function* updateInternalNotesProcess(form, note, cb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.updateInternalNotes.request(form))
  const { response, error } = yield call(api.updateShipConfNotes, {
    dataId,
    note
  })

  // debugger
  if (response) {
    yield put(actions.updateInternalNotes.success(response, form))
    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.updateInternalNotes.failure(error, form))
  }
}

export function* updateInternalNotesListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { note, cb }
    } = yield take(CONSTANTS.UPDATE_INTERNAL_NOTES.TRY)

    if (form === formListener) {
      yield fork(updateInternalNotesProcess, form, note, cb)
    }
  }
}

export function* displayWarningModals(form, messages) {
  if (messages && Array.isArray(messages)) {
    for (let i = 0; i < messages.length; i++) {
      const msg = messages[i]
      yield call(warningModal, msg.message, msg.modalTitle)
      yield take(REMOVE_MODAL)
    }
  }
}

export function* onFieldChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value },
      type
    } = yield take([BLUR, SET_FIELD])

    if (form === formListener) {
      if (propertyName === 'dataId' && value && type === SET_FIELD) {
        yield fork(getShippingConfirmationProcess, form, value)
      } else if (propertyName !== 'dataId') {
        yield fork(onPropertyChangeProcess, form, propertyName, value)
      }
    }
  }
}

export function* onPropertyChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  if (propertyName === 'tempSignedBy') return

  yield put(actions.onPropertyChange.request(form))
  const { response, error } = yield call(api.shipConfPropertyChange, {
    action: '',
    dataId,
    properties: {
      [propertyName]: value
    },
    groupNames: ['all']
  })

  // debugger
  if (response) {
    const flattenedResponse = flattenResponseDataByGroupName(response, [
      'pickedByData',
      'header',
      'detail',
      'printOptions',
      'tracking'
    ])

    yield put(actions.onPropertyChange.success(flattenedResponse, form))
    if (response?.messages) {
      yield fork(displayWarningModals, form, response.messages)
    }
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* cancelShippingConfirmationProcess(
  form,
  cancelPrevious = false
) {
  const formState = yield select(getFormSelector(form))
  const dataId = cancelPrevious
    ? getIn(formState, 'lockedRecordDataId')
    : getIn(formState, 'fields.dataId.value')

  yield call(
    confirmationModal,
    'All changes will be lost. Continue?',
    'Cancel?'
  )
  debugger

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return { cancellationProcessDeclined: true }
  }

  yield put(actions.cancelShippingConfirmation.request(form))

  const { response, error } = yield call(api.cancelShippingConfirmation, {
    dataId
  })

  if (response) {
    yield put(actions.cancelShippingConfirmation.success(response, form))
  } else {
    yield put(actions.cancelShippingConfirmation.failure(error, form))
  }

  return { cancellationProcessDeclined: false }
}

export function* cancelShippingConfirmationListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.CANCEL_SHIPPING_CONFIRMATION.TRY)

    if (form === formListener) {
      yield fork(cancelShippingConfirmationProcess, form)
    }
  }
}

export function* onPropertyChangeListener(formListener) {
  while (true) {
    const {
      meta: { form, thunk },
      payload: { key, data = {}, gridApi = null }
    } = yield take(CONSTANTS.ON_PROPERTY_CHANGE.TRY)

    const lineItemShipProcceses = ['shipall', 'boall']
    if (form === formListener) {
      if (lineItemShipProcceses.includes(key)) {
        yield fork(
          gridInteractionPropertyChangeProcess,
          form,
          thunk,
          {
            lineItemProperties: [{ sortIndex: null, key, value: null }]
          },
          ['detail']
        )
      }

      if (key === 'lineItems' && data) {
        const { sortIndex, field, value } = data
        yield fork(
          gridInteractionPropertyChangeProcess,
          form,
          thunk,
          {
            lineItemProperties: [{ sortIndex, key: field, value }]
          },
          ['detail'],
          gridApi
        )
      }

      if (key === 'tracking' && data) {
        // debugger
        yield fork(
          gridInteractionPropertyChangeProcess,
          form,
          thunk,
          { trackingNumberProperties: data },
          ['tracking']
        )
      }
    }
  }
}

export function* gridInteractionPropertyChangeProcess(
  form,
  thunk,
  gridPropertiesObj = null,
  groupNames = ['detail'],
  gridApi = null
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!gridPropertiesObj) {
    return
  }

  yield put(actions.onPropertyChange.request(form))
  const { response, error } = yield call(api.shipConfPropertyChange, {
    action: '',
    properties: {},
    dataId,
    groupNames,
    ...gridPropertiesObj
  })

  if (response) {
    // debugger
    const flattenedResponse = flattenResponseDataByGroupName(
      response,
      groupNames
    )

    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: { form, thunk },
      payload: flattenedResponse
    })
    if (response?.messages) {
      yield fork(displayWarningModals, form, response.messages)
    } else if (gridApi && gridApi?.getFocusedCell) {
      const focusedCell = gridApi.getFocusedCell()
      const rowIndex = focusedCell?.rowIndex
      const colId = focusedCell?.column?.colId || ''

      if (
        is.number(rowIndex) &&
        (colId === 'quantity' || colId === 'quantityBo')
      ) {
        yield delay(100)
        gridApi.startEditingCell({
          rowIndex,
          colKey: colId
        })
      }
    }
  } else {
    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.FAILURE,
      meta: { form, thunk },
      payload: error
    })
  }
}

export function* deleteShippingConfirmationProcess(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const customerName = getIn(formState, 'values.customerName')

  yield call(
    confirmationModal,
    `Are you sure you want to delete Ship Confirmation ${dataId} - "${customerName}"`,
    'Delete?'
  )

  const action = yield take([CONFIRMED, CANCELED])
  if (action.type === CANCELED) {
    return
  }

  yield put(actions.deleteShippingConfirmation.request(form))

  const { response, error } = yield call(api.deleteShippingConfirmation, {
    dataId
  })

  if (response) {
    yield put(actions.deleteShippingConfirmation.success(response, form))
  } else {
    yield put(actions.deleteShippingConfirmation.failure(error, form))
  }
}

export function* deleteShippingConfiramtionListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.DELETE_SHIPPING_CONFIRMATION.TRY)

    if (form === formListener) {
      yield fork(deleteShippingConfirmationProcess, form)
    }
  }
}

export function* openScreenWithDataProcess(form) {
  const dataId = yield select(state =>
    getIn(state, `layout.screens.${form}.data.screenOpenData.dataId`)
  ) || null
  // debugger

  if (dataId) {
    yield fork(getShippingConfirmationProcess, form, dataId)
  }

  yield put(clearLayoutScreenData(form))
}

export function* openScreenListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(OPEN_SCREEN.SUCCESS)

    if (form === formListener) {
      yield fork(openScreenWithDataProcess, form)
    }
  }
}

export function* printShippingConfirmationProcess(form, action) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!dataId || !action) {
    return
  }

  yield put(actions.printShippingConfirmation.request(form))
  const { response, error } = yield call(api.printShippingConfirmation, {
    dataId,
    action
  })

  if (response) {
    yield put(actions.printShippingConfirmation.success(response, form))
    yield fork(showPrintDocumentModalProcess, form, {
      ...response,
      form
    })
  } else {
    yield put(actions.printShippingConfirmation.failure(error, form))
  }
}

export function* printShippingConfirmationListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { action }
    } = yield take(CONSTANTS.PRINT_SHIPPING_CONFIRMATION.TRY)

    if (form === formListener) {
      yield fork(printShippingConfirmationProcess, form, action)
    }
  }
}

export function* handlePrintJobAfterSave(form, printJobs) {
  if (printJobs && Array.isArray(printJobs)) {
    for (let i = 0; i < printJobs.length; i++) {
      if (printJobs[i]) {
        yield fork(showPrintDocumentModalProcess, form, {
          ...printJobs[i],
          form
        })

        yield take([PRINT_DOCUMENT_ROUTINE_COMPLETED, CLOSE_REPORT_VIEWER])
      }
    }
  }
}

export function* displaySafetyDataSheetsGridModal(form, sdSheets) {
  const modalOpts = {
    component: SafetyDataSheetsGrid,
    options: {
      width: 800,
      maxHeight: 1200,
      title: 'Safety Data Sheets',
      data: {
        form,
        sdSheets,
        actions: [
          {
            primary: true,
            title: 'Print',
            async clickEvent(args, fs, cb) {
              try {
                await this.props.dispatch(
                  actions.viewSDSheet.try(form, {
                    pos: this.state.pos,
                    action: 'printsds',
                    cb
                  })
                )
              } catch (e) {
                console.log('error', e)
              }
            }
          },
          {
            primary: true,
            title: 'Exit'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield take(act => {
    return act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
  })

  return modal.payload.id
}

export function* displaySpawnedBoMessage(form, spawnedBoMessage) {
  const modalOpts = {
    component: BackorderSpawnedMessage,
    options: {
      width: 400,
      title: spawnedBoMessage?.modalTitle
        ? spawnedBoMessage.modalTitle
        : 'Backorder Spawned',
      data: {
        form,
        dataId: spawnedBoMessage?.id,
        message: spawnedBoMessage?.message,
        actions: [
          {
            primary: true,
            title: 'OK'
          }
        ]
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)
  yield take(act => {
    return act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
  })

  return modal.payload.id
}

export function* saveShippingConfirmationProcess(
  form,
  baseApiParamOverrides = {}
) {
  // debugger
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const baseApiParams = {
    dataId,
    saveWoShipping: null,
    overrideLimit: null,
    lineItemProperties: null,
    componentLineItemProperties: null,
    trackingNumberProperties: null,
    action: '',
    // requestReply: false,
    taskId: null,
    properties: {},
    credentials: null,
    ...baseApiParamOverrides
  }
  // debugger

  yield put(actions.saveShippingConfirmation.request(form))
  const { response, error } = yield call(
    api.saveShippingConfirmation,
    baseApiParams?.action === 'terminalsignature'
      ? { ...baseApiParamOverrides, dataId }
      : baseApiParams
  )

  // yield fork(saveShippingConfirmationProcess, form, {
  //   // continueWoSignature: false,
  //   taskId,
  //   action: 'canceltransaction',
  //   properties: { cancelsignature: '' }
  // })
  if (baseApiParams?.action === 'terminalsignature') {
    debugger
    yield fork(saveShippingConfirmationProcess, form)
  } else if (response) {
    // debugger

    if (response.printJobs) {
      yield call(handlePrintJobAfterSave, form, response.printJobs)
    }

    if (response.sdSheets) {
      yield call(displaySafetyDataSheetsGridModal, form, response.sdSheets)
    }

    if (response.spawnedBoMessage) {
      yield call(displaySpawnedBoMessage, form, response.spawnedBoMessage)
    }

    yield put(actions.saveShippingConfirmation.success(response, form))
  } else {
    debugger
    yield put(actions.saveShippingConfirmation.failure({}, form))

    if (error?.status === 496 && error?.message) {
      if (error?.modalTitle && error?.propertyToFillOnOk) {
        yield call(confirmationModal, error.message, error.modalTitle)
        const modalAction = yield take([CONFIRMED, CANCELED])
        yield fork(saveShippingConfirmationProcess, form, {
          [toCamelCase(error.propertyToFillOnOk)]:
            modalAction.type === CONFIRMED
        })
      } else {
        yield call(warningModal, error.message, 'Error!')
      }
    }

    // debugger
    if (error?.message === 'Prompt for signature') {
      debugger
      const modalOpts = {
        component: Signature,
        props: { form, modal: true }
      }
      const m = yield call(addModal, form, modalOpts)

      const modalId = m.payload.id
      yield put(m)
      const action = yield take([
        CONSTANTS.SAVE_SHIPPING_CONFIRMATION.SUCCESS,
        DESTROY_FIELD,
        CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE.SUCCESS,
        CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE.FAILURE
      ])

      debugger
      yield put(removeModal(form, modalId))
      if (action.type === DESTROY_FIELD) {
        debugger
        // camcel first
        // yield fork(cancelSignature, action)
        // if (taskId) {
        //   // yield cancel(
        //   const t = taskId
        //   taskId = null
        //   // yield fork(cancelSignature, {
        //   //   payload: { taskId: t },
        //   //   meta: { form }
        //   // })
        // }
        // if (taskId) {
        //   // yield cancel(
        //   const t = taskId
        // }
        if (task) {
          yield cancel(task)
        }

        /* cloning 2022395 in SOE then open Ship Conf triggers the bug */
        debugger
        if (taskId) {
          yield fork(saveShippingConfirmationProcess, form, {
            // continueWoSignature: false,
            action: 'terminalsignature',
            properties: { cancelsignature: taskId }
          })
        }
      }
      // prompt or whatever i guess.. this sucks
      debugger
    }

    debugger
    // if (error?.message === ''

    if (error?.message === 'ContinueWoSignature is required') {
      debugger
      // prompt...
      yield call(confirmationModal, 'No Signature. Continue?', 'No Signature')
      const a = yield take([CONFIRMED, CANCELED])
      debugger
      yield fork(saveShippingConfirmationProcess, form, {
        // continueWoSignature: false,
        continueWoSignature: a.type === CONFIRMED
      })
      // if (a.type === CONFIRMED) {
      //   yield fork(saveShippingConfirmationProcess, form, {
      //     // continueWoSignature: false,
      //    continueWoSignature: true
      //   })
      // } else {

      // }
    }

    if (error?.status === 498) {
      yield fork(displayValidationErrors, error)
    }
  }
}

export function* displayValidationErrors(error) {
  let errorMessageString
  if (error?.validationErrors && Array.isArray(error.validationErrors)) {
    errorMessageString = error.validationErrors.reduce((acc, next) => {
      acc = acc.concat(`${next.message}\n`)
      return acc
    }, '')
  } else if (error?.message) {
    errorMessageString = error.message
  }
  if (errorMessageString) yield call(warningModal, errorMessageString, 'Error!')
}

export function* saveShippingConfirmationListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.SAVE_SHIPPING_CONFIRMATION.TRY)

    if (form === formListener) {
      // debugger
      yield fork(saveShippingConfirmationProcess, form)
    }
  }
}

export function* getPreviousOrderProcess(form) {
  const formState = yield select(getFormSelector(form))
  const previousOrders = getIn(formState, 'previousOrders') || Set()

  /* make sure we are dealing with an Immutable Set here */
  if (!previousOrders?.last) {
    return
  }
  const orderToLoad = previousOrders.last()

  if (orderToLoad) {
    yield put(actions.removePreviousOrder(form, { dataId: orderToLoad }))
    yield fork(getShippingConfirmationProcess, form, orderToLoad)
  }
}

export function* getPreviousOrderListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_PREVIOUS_ORDER)

    if (form === formListener) {
      yield fork(getPreviousOrderProcess, form)
    }
  }
}

export function* viewSDSheetProcess(form, pos, action, cb) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  if (!dataId || !pos || !pos?.length) {
    return
  }

  yield put(actions.viewSDSheet.request(form))

  const { response, error } = yield call(api.printShippingConfirmation, {
    dataId,
    action,
    pos
  })

  if (response) {
    yield put(actions.viewSDSheet.success(response, form))

    if (response?.downloads && Array.isArray(response.downloads)) {
      for (let i = 0; i < response.downloads.length; i++) {
        if (response?.downloads?.[i]?.content) {
          yield call(viewDocumentProcess, form, response.downloads[i].content)
        } else if (response?.downloads?.[i]?.url) {
          window.open(
            response.downloads[i].url,
            '_blank',
            'toolbar=0,location=0,menubar=0'
          )
        }
      }
    } else if (response?.content) {
      yield call(viewDocumentProcess, form, response.content)
    } else if (response?.url) {
      window.open(response.url, '_blank', 'toolbar=0,location=0,menubar=0')
    }

    if (cb && typeof cb === 'function') {
      cb()
    }
  } else {
    yield put(actions.viewSDSheet.failure(error, form))
  }
}

export function* viewDocumentProcess(form, content) {
  if (content) {
    const modalOpts = {
      component: ReportViewer,
      options: {
        data: {
          actions: [{ primary: true, title: 'Exit' }],
          pdfData: `data:application/pdf;base64,${content}`
        },
        fullScreen: true,
        title: '',
        marginTop: '0px',
        maxHeight: '95%',
        maxWidth: '95%',
        width: '95%'
      }
    }

    const modal = yield call(addModal, form, modalOpts)
    yield put(modal)
    yield take(act => {
      return (
        act.type === REMOVE_MODAL && act?.payload?.id === modal?.payload?.id
      )
    })
  }
}

export function* viewSDSheetListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { pos = [], cb = null, action = 'viewsds' }
    } = yield take(CONSTANTS.VIEW_SD_SHEET.TRY)

    if (form === formListener) {
      yield fork(viewSDSheetProcess, form, pos, action, cb)
    }
  }
}

export function* cancelSignature(action) {
  // yield console.log(action)
  const formState = yield select(getFormSelector(action.meta.form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const apiMethod = action.payload.modal
    ? api.saveShippingConfirmation
    : api.shipConfPropertyChange
  taskId = action.payload.taskId
  const { response, error } = yield call(apiMethod, {
    dataId,
    Action: 'terminalsignature',
    Properties: { cancelsignature: action.payload.taskId }
  })
  // debugger
  if (response) {
    yield put(actions.pollCardTerminalSignature.success({}, action.meta))
  } else {
    yield put(actions.pollCardTerminalSignature.failure(error, action.meta))
  }
}

export function* pollCardTerminalSignatureProcess(action) {
  const timer = 1000
  debugger
  try {
    while (true) {
      const formState = yield select(getFormSelector(action.meta.form))
      console.log(formState)
      // debugger
      const hasField = getIn(formState, 'fields.tempSignedBy')
      if (!hasField) {
        // debugger
        if (!action.payload.modal) {
          yield fork(cancelSignature, action)
        }
        debugger
        return
      }
      const dataId = getIn(formState, 'fields.dataId.value')
      const apiMethod = action.payload.modal
        ? api.saveShippingConfirmation
        : api.shipConfPropertyChange
      taskId = action.payload.taskId
      const { response, error } = yield call(apiMethod, {
        dataId,
        action: 'terminalsignature',
        taskId: action.payload.taskId,
        properties: {
          requestsignature: ''
          // requestReply: true
        }
      })
      // console.log(response, error)

      if (response?.signature) {
        yield put(
          actions.pollCardTerminalSignature.success(response, action.meta)
        )
        debugger
        return
      }
      if (error) {
        debugger
        // complete = true
        yield put(
          actions.pollCardTerminalSignature.failure(response, action.meta)
        )
        return
      }

      yield delay(timer)
    }
  } finally {
    debugger
  }
}
export function* pollCardTerminalSignatureListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.POLL_CARD_TERMINAL_SIGNATURE.REQUEST)

    if (action.meta.form === formListener) {
      taskId = action.payload.taskId
      task = yield fork(pollCardTerminalSignatureProcess, action)
    }
  }
}

export function* initiateCardTerminalSignatureProcess(action, formActions) {
  const formState = yield select(getFormSelector(action.meta.form))
  const dataId = getIn(formState, 'fields.dataId.value')
  formActions = formActions || actions

  const apiMethod = action.payload.modal
    ? api.saveShippingConfirmation
    : api.shipConfPropertyChange
  // debugger
  const { response, error } = yield call(apiMethod, {
    dataId,
    Action: 'terminalsignature',
    RequestReply: true,
    Properties: { requestsignature: '' }
  })

  if (response) {
    yield put(
      actions.initiateCardTerminalSignature.success(response, action.meta)
    )
  } else {
    yield put(actions.initiateCardTerminalSignature.failure(error, action.meta))
  }
}

export function* initiateCardTerminalSignatureListener(
  formListener,
  formActions
) {
  while (true) {
    const action = yield take(
      CONSTANTS.INITIATE_CARD_TERMINAL_SIGNATURE.REQUEST
    )
    // debugger
    if (action.meta.form === formListener) {
      yield fork(initiateCardTerminalSignatureProcess, action, formActions)
    }
  }
}

export function* handleMultipleScreenOpenAttempts(form, dataId) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false

  if (isEditing) {
    yield take('REQUEST_CANCELED')
    const cancellationAction = yield take([
      CANCEL_EDIT.SUCCESS,
      CANCEL_EDIT.FAILURE
    ])
    // debugger
    if (cancellationAction.type === CANCEL_EDIT.FAILURE) {
      return
    }

    yield fork(getShippingConfirmationProcess, form, dataId)
    yield put(clearLayoutScreenData(form))
  } else {
    yield fork(getShippingConfirmationProcess, form, dataId)
    yield put(clearLayoutScreenData(form))
  }
}

export function* tryOpenScreenListener(formListener) {
  while (true) {
    const { payload } = yield take(TRY_OPEN_SCREEN)

    if (
      payload?.title &&
      formListener === toCamelCase(payload?.title) &&
      payload?.screenOpenData?.dataId
    ) {
      yield fork(
        handleMultipleScreenOpenAttempts,
        formListener,
        payload?.screenOpenData?.dataId
      )
    }
  }
}

export default function* shippingConfirmationSagas(form) {
  yield fork(openScreenListener, form)
  yield fork(onFieldChangeListener, form)
  yield fork(onPropertyChangeListener, form)
  yield fork(cancelShippingConfirmationListener, form)
  yield fork(deleteShippingConfiramtionListener, form)
  yield fork(printShippingConfirmationListener, form)
  yield fork(saveShippingConfirmationListener, form)
  yield fork(getPreviousOrderListener, form)
  yield fork(viewSDSheetListener, form)
  yield fork(updateInternalNotesListener, form)
  yield fork(saveSignatureListener, form, actions)
  yield fork(pollCardTerminalSignatureListener, form)
  yield fork(initiateCardTerminalSignatureListener, form)
  yield fork(cancelEditListener, form)
  yield fork(tryOpenScreenListener, form)
}
