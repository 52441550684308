import React, { useState, useEffect, createRef } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Icon from '@material-ui/core/Icon'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { show } from 'snackbar/actions'
import { withContext } from 'ddiForm'
import { removeModal } from 'modals/actions'

import {
  validateField as validateFieldAction,
  validatePasswordStrength as validatePasswordStrengthAction,
  validatePasswordConfirm as validatePasswordConfirmAction,
  changePassword,
  resetPassword
} from 'pages/SignUp/actions'

const paragraphStyle = {
  color: '#517b9c',
  textAlign: 'center',
  fontSize: '1.1em',
  margin: 0
}

const paperStyle = {
  border: '1px solid #517b9c',
  background: '#f2f2f2',
  padding: 10,
  marginBottom: 15
}

const mapState = state => ({
  account: state.get('account'),
  hasSystemUser: state.get('auth').get('hasSystemUser'),
  statusText: state.get('auth').get('statusText')
})
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%'
  },
  card: {
    width: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  cardMarginTop: {
    width: '100%',
    marginTop: 20
  }
}))

export function ChangePasswordModal(props) {
  const [open, setOpen] = useState(true)
  const classes = useStyles()
  const [apiActive, setApiActive] = useState(false)
  const [passwordFieldInFocus, setPasswordFieldInFocus] = useState(false)
  const [changed, setChanged] = useState(false)
  const currentPasswordField = createRef()
  const passwordConfirmField = createRef()
  const passwordField = createRef()
  const history = useHistory()

  const { reset, flag, forgotPasswordId } = props
  const onKeyUp = event => {
    if (event.key === 'Enter') {
      // this.resetPasswordRequest()
    }
  }
  useEffect(() => {
    // history.replace({ search: '' })
    if (forgotPasswordId && reset && !changed) {
      history.replace({ search: '' })
      setChanged(true)
    }
  }, [reset, changed])

  const handleClose = () => {
    setOpen(false)
    props.dispatch(removeModal(null, props.id))
  }

  const validate = (field, valid) => {
    props.dispatch(validateFieldAction({ field, valid }))
  }

  const validatePasswordStrength = passwordValidation => {
    props.dispatch(validatePasswordStrengthAction(passwordValidation))
  }

  const validatePasswordConfirm = passwordConfirmSuccess => {
    props.dispatch(validatePasswordConfirmAction(passwordConfirmSuccess))
  }

  const validateField = event => {
    let field
    let valid
    const target = event.target
      ? event.target.getAttribute('id')
      : event.attributes.id.nodeValue
    const inputValue = document.getElementById(target).value
    switch (target) {
      case 'username': {
        field = 'usernameContainsVal'
        valid = !!inputValue
        validate('pristine', false)
        validate(field, valid)
        break
      }
      case 'signup-password': {
        field = 'passwordContainsVal'
        valid = !!(
          inputValue.length >= 8 &&
          inputValue.match(/[A-z]/) &&
          inputValue.match(/[A-Z]/) &&
          inputValue.match(/\d/)
        )

        const passwordValidation = {
          containsLetters: !!inputValue.match(/[A-z]/),
          containsNumbers: !!inputValue.match(/\d/),
          containsUppercaseLetters: !!inputValue.match(/[A-Z]/),
          minLengthMet: inputValue.length >= 8,
          passwordValidationCriteriaMet: !!(
            inputValue.length >= 8 &&
            inputValue.match(/[A-z]/) &&
            inputValue.match(/[A-Z]/) &&
            inputValue.match(/\d/)
          )
        }

        validate('pristine', false)
        validate(field, valid)
        validatePasswordStrength(passwordValidation)
        break
      }
      case 'signup-passwordConfirm': {
        field = 'passwordConfirmContainsVal'
        valid = !!inputValue
        validate('pristine', false)
        validate(field, valid)
        const passwordsMatch =
          passwordField.current.value === passwordConfirmField.current.value
        validatePasswordConfirm(passwordsMatch)
        break
      }
      default:
        field = ''
        valid = false
    }
  }

  const passwordFieldFocused = event => {
    setPasswordFieldInFocus(true)
  }

  const passwordFieldBlurred = event => {
    // const state = update(this.state, {
    //   form: {
    //     passwordFieldInFocus: { $set: false }
    //   }
    // })
    // this.setState(state)
    setPasswordFieldInFocus(false)
  }

  const changePasswordReq = async () => {
    const inputs = document.signupForm.getElementsByTagName('input')
    const emptyInputs = []
    for (let i = 0; i < inputs.length; i++) {
      validateField(inputs[i])
      if (!inputs[i].value) {
        emptyInputs.push(inputs[i].getAttribute('id'))
      }
    }

    if (emptyInputs.length) {
      document.getElementById(emptyInputs[0]).focus()
    } else if (props.account.get('passwordValidationCriteriaMet') === false) {
      // document.getElementById(this.passwordField.id).focus() // ?
      debugger
    } else if (props.account.get('passwordsMatch') === true) {
      setApiActive(true)

      if (!reset) {
        try {
          await props.dispatch(
            changePassword.request({
              oldPassword: currentPasswordField.current.value,
              confirmPassword: passwordConfirmField.current.value,
              password: passwordField.current.value,
              flag
            })
          )
          debugger
          setApiActive(false)

          handleClose()
        } catch (e) {
          debugger
          setApiActive(false)
        }
      } else {
        try {
          await props.dispatch(
            resetPassword.request({
              confirmPassword: passwordConfirmField.current.value,
              password: passwordField.current.value,
              id: forgotPasswordId
            })
          )
          debugger
          setApiActive(false)

          handleClose()
        } catch (e) {
          debugger
          setApiActive(false)
        }
      }
    }
  }

  let { account } = props
  account = account?.toJS ? account.toJS() : {}

  const animationStyles = account.isSuccess
    ? { animationDuration: '2s', animationName: 'fadeIn' }
    : {}
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="alert-dialog-title">
          {reset ? 'Reset Password' : 'Change Password'}
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <div style={{ margin: '25px auto', minHeight: 280 }}>
              <div style={{ maxWidth: '100%', minWidth: 260, width: '100%' }}>
                <Paper style={paperStyle}>
                  {reset ? (
                    <p style={paragraphStyle}>
                      You have requested to reset your password
                    </p>
                  ) : !flag ? (
                    <p style={paragraphStyle}>
                      Your password is expired. Please create a new password!
                    </p>
                  ) : (
                    <p style={paragraphStyle}>Create a new password.</p>
                  )}
                </Paper>
                {!account.isSuccess ? (
                  <form
                    name="signupForm"
                    className="form-transition"
                    style={animationStyles}
                  >
                    {reset ? null : (
                      <div className="input-wrapper">
                        <TextField
                          label="Current Password"
                          type="password"
                          id="signup-current-password"
                          inputRef={currentPasswordField}
                          fullWidth
                          style={{ fontSize: 14 }}
                        />
                      </div>
                    )}
                    <div id="password-field-wrapper" className="input-wrapper">
                      <TextField
                        label="Password"
                        onChange={validateField}
                        onFocus={passwordFieldFocused}
                        onBlur={passwordFieldBlurred}
                        onKeyUp={onKeyUp}
                        type="password"
                        id="signup-password"
                        inputRef={passwordField}
                        fullWidth
                        style={{ fontSize: 14 }}
                        className={
                          account.inputs.passwordContainsVal ? '' : 'error'
                        }
                      />
                      <div
                        id="pswd_info"
                        className={
                          account.passwordValidationCriteriaMet === false &&
                          passwordFieldInFocus === true
                            ? 'show'
                            : ''
                        }
                      >
                        <h4>Password must meet the following requirements:</h4>
                        <ul>
                          <li
                            id="letter"
                            className={
                              account.passwordValidation.containsLetters ===
                              true
                                ? 'valid'
                                : 'invalid'
                            }
                          >
                            <i className="material-icons check">check</i>{' '}
                            <i className="material-icons close">close</i> At
                            least <strong>one letter</strong>
                          </li>
                          <li
                            id="capital"
                            className={
                              account.passwordValidation
                                .containsUppercaseLetters === true
                                ? 'valid'
                                : 'invalid'
                            }
                          >
                            <i className="material-icons check">check</i>{' '}
                            <i className="material-icons close">close</i> At
                            least <strong>one capital letter</strong>
                          </li>
                          <li
                            id="number"
                            className={
                              account.passwordValidation.containsNumbers ===
                              true
                                ? 'valid'
                                : 'invalid'
                            }
                          >
                            <i className="material-icons check">check</i>{' '}
                            <i className="material-icons close">close</i> At
                            least <strong>one number</strong>
                          </li>
                          <li
                            id="length"
                            className={
                              account.passwordValidation.minLengthMet === true
                                ? 'valid'
                                : 'invalid'
                            }
                          >
                            <i className="material-icons check">check</i>{' '}
                            <i className="material-icons close">close</i> Be at
                            least <strong>8 characters</strong>
                          </li>
                        </ul>
                      </div>
                      {account.inputs.passwordContainsVal ? null : (
                        <span className="error-text">
                          Please enter your password
                        </span>
                      )}
                    </div>
                    <div className="input-wrapper" style={{ marginBottom: 20 }}>
                      <TextField
                        label="Re-Enter Password"
                        type="password"
                        inputRef={passwordConfirmField}
                        id="signup-passwordConfirm"
                        onChange={validateField}
                        onKeyUp={onKeyUp}
                        fullWidth
                        style={{ fontSize: 14 }}
                        className={
                          account.passwordsMatch &&
                          account.inputs.passwordContainsVal
                            ? ''
                            : 'error'
                        }
                      />
                      {account.passwordsMatch &&
                      account.inputs.passwordContainsVal ? null : (
                        <span className="error-text">Passwords must match</span>
                      )}
                    </div>
                    {account.statusText && (
                      <div className="alert alert-info">
                        {account.statusText}
                      </div>
                    )}
                  </form>
                ) : (
                  <p
                    className="form-transition"
                    style={{
                      animationDuration: '1s',
                      animationName: 'fadeIn',
                      textAlign: 'center'
                    }}
                  >
                    Form submitted!
                  </p>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {props.flag || props.reset ? (
            <Button variant="contained" onClick={handleClose} color="primary">
              Cancel
            </Button>
          ) : null}
          <Button
            variant="contained"
            // disabled={isAuthenticating}
            // disabled={account.submitting || this.state.requesting}
            color="primary"
            onClick={changePasswordReq}
            disabled={!account.passwordsMatch || apiActive}
            // onClick={handleClose}
          >
            <Icon
              style={{
                width: '20px',
                color: '#fff',
                marginRight: 10
              }}
            >
              lock
            </Icon>
            {flag ? 'Change Password' : 'Reset Password'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default connect(mapState)(ChangePasswordModal)
