/* eslint consistent-return: 0 */
import { CANCELED, CONFIRMED } from 'modals/constants'
import {
  call,
  delay,
  put,
  take,
  fork,
  cancel,
  select
} from 'redux-saga/effects'
import { confirmationModal } from 'modals/sagas'
import { setSelectedRowIndex } from 'ddiForm/actions'
import { getGroupNames } from 'pages/SalesOrder/utils'
import { SET_FIELD } from 'ddiForm/constants'
import { getMapResponse, getFormSelector } from 'ddiForm/utils'
import { getIn } from 'utils'
import { CHANGE_COMMISSION_VALUE } from 'pages/InvoiceInquiry/constants'
import {
  changeCommissionValue,
  changeCommissionProperty
} from 'pages/InvoiceInquiry/actions'
import {
  displaySalesmenCommissionsModal,
  tryBeginEditingFocusedCell
} from 'pages/InvoiceInquiry/sagas/changeInvoiceSagas'

import {
  triggerBoxQuantityPrompts,
  handleNewlyAddedProductNotes
} from './commonSagas'

import * as CONSTANTS from '../constants'
import * as actions from '../actions'
import {
  getSOESalesmenCommissions as getSOESalesmenCommissionsAPI,
  saveLayoutForUserAuthentication as saveLayoutForUserAuthenticationAPI,
  propertyChange as propertyChangeAPI,
  changeGridItem as changeGridItemAPI,
  massChange as massChangeAPI,
  saveGridLayout as saveGridLayoutAPI
} from '../api'

export function* quantityToShipProcess(action) {
  const {
    payload: { value, data, nextInput, field },
    meta,
    type
  } = action

  let ref
  if (nextInput && nextInput.current) {
    ref = nextInput.current
  }

  let bool = false
  let temp
  let tempData = data
  const isTryCellChanged = type === 'TRY_CELL_CHANGED_REQUEST'
  if (!isTryCellChanged) {
    tempData = tempData.quantityChange
  }

  if (field === 'quantity') {
    temp = value
    if (temp > data.quantityOrdered) {
      bool = true
    }
  } else {
    temp = value + data.quantity
    if (temp > data.quantityOrdered) {
      bool = true
    }
  }

  const success = put({
    meta,
    type: isTryCellChanged
      ? 'TRY_CELL_CHANGED_SUCCESS'
      : CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.REQUEST,
    payload: value
  })
  if (bool) {
    const failure = put({
      meta,
      error: true,
      type: isTryCellChanged
        ? 'TRY_CELL_CHANGED_FAILURE'
        : CONSTANTS.CHANGE_PROVISIONAL_LINE_ITEM.FAILURE
    })
    // alert the user..
    const title = 'Over Open Quantity'
    const message =
      'Quantity ship is greater than quantity open!\nAre you sure?'
    yield call(confirmationModal, message, title)
    const act = yield take([CONFIRMED, CANCELED])

    if (act.type === CONFIRMED) {
      if (isTryCellChanged) {
        yield success
      } else {
        return true
      }
    } else if (isTryCellChanged) {
      yield failure
    } else {
      throw failure
    }
    if (ref) {
      ref.select()
      // nextInput.current.select()
    }
  } else if (isTryCellChanged) {
    yield success
  }
}

const setSelectedNodeFromIndex = (api, index) => {
  const node = api.getDisplayedRowAtIndex(index)
  if (node && !node.isSelected()) {
    debugger
    node.setSelected(true)
  }
}

export function* tabProcess(action) {
  yield take([
    CONSTANTS.UPDATE_GRID_ITEM.SUCCESS,
    CONSTANTS.UPDATE_GRID_ITEM.FAILURE,
    'CELL_CHANGED_COMPLETE'
  ])
  let col
  const {
    payload: { api, arrowKey, shiftKey, column, rowIndex, propertyName },
    meta: { form }
  } = action

  const selectRow = idx =>
    put(
      setSelectedRowIndex(form, {
        propertyName: propertyName || 'lineItems',
        rowIndex,
        api
      })
    )
  if (api) {
    if (arrowKey === 'up' || arrowKey === 'down') {
      if (
        (arrowKey === 'up' && !shiftKey) ||
        (arrowKey === 'down' && shiftKey)
      ) {
        // up
        // go to cell up if at the top do nothing...
        if (rowIndex - 1 >= 0) {
          setSelectedNodeFromIndex(api, rowIndex - 1)

          /* is this a bug? because selectRow up there does not use rowIndex - 1 -- SVE 1/5/2021 */
          yield selectRow(rowIndex - 1)
          api.setFocusedCell(rowIndex - 1, column.colId)
        }
      } else {
        const renderedRowCount = api.getModel().getRowCount()
        if (rowIndex + 1 <= renderedRowCount) {
          setSelectedNodeFromIndex(api, rowIndex + 1)
          yield selectRow(rowIndex + 1)
          api.setFocusedCell(rowIndex + 1, column.colId)
        }
        // down
        // go to cell down, if at the end do nothing...
      }
    } else if (arrowKey === 'left' || arrowKey === 'right') {
      const nextDisplayedCol =
        arrowKey === 'left' ? 'getDisplayedColBefore' : 'getDisplayedColAfter'
      try {
        col = column.columnApi[nextDisplayedCol](column)
        if (col) {
          api.setFocusedCell(rowIndex, col.colId)
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      api[shiftKey ? 'tabToPreviousCell' : 'tabToNextCell']()
    }
  }
}

export function* tabListener(formListener) {
  let task
  while (true) {
    const action = yield take('GRID_TAB_ATTEMPT')
    if (action.meta.form === formListener) {
      if (task) {
        yield cancel(task)
      }
      task = yield fork(tabProcess, action)
    }
  }
}

export function* saveLayoutForUserAuthenticationProcess(
  form,
  columnApi = null,
  gridApi = null,
  successCb = null
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  // if (!columnApi) {
  //   return
  // }

  // yield put(actions.saveLayoutForUserAuthentication.request(form))

  // const displayedColumns = columnApi.getAllGridColumns()
  // const aliases = {
  //   delete: 'removeLineItem',
  //   quantityDropDownButton: 'quantityChange',
  //   priceDropDownButton: 'pricing'
  // }

  // const levels = displayedColumns.reduce((acc, next) => {
  //   if (next.colId !== 'rowId') {
  //     acc = acc.concat({
  //       propertyName:
  //         next?.colId && aliases?.[next.colId]
  //           ? aliases[next.colId]
  //           : next?.colId || next?.field,
  //       visible: next?.visible || false
  //     })
  //   }
  //   return acc
  // }, [])

  // const { response, error } = yield call(saveGridLayoutAPI, {
  //   guid,
  //   fieldChooser: {
  //     layoutKey: 'SalesOrderEntryLineItems',
  //     levels
  //   }
  // })
  // const { response, error } = yield call(saveLayoutForUserAuthenticationAPI, {
  //   guid
  // })

  // if (response) {
  //   yield put(actions.saveLayoutForUserAuthentication.success(response, form))
  if (successCb && typeof successCb === 'function') {
    successCb()
  }
  // } else {
  //   yield put(actions.saveLayoutForUserAuthentication.failure(error, form))
  // }
}

export function* saveLayoutForUserAutenticationListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { successCb, columnApi, gridApi }
    } = yield take(CONSTANTS.SAVE_LAYOUT_FOR_USER_AUTHENTICATION.TRY)

    if (form === formListener) {
      yield fork(
        saveLayoutForUserAuthenticationProcess,
        form,
        columnApi,
        gridApi,
        successCb
      )
    }
  }
}

export function* getSalemenCommissionsProcess(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.getSOESalesmenCommissions.request(form))

  const { response, error } = yield call(getSOESalesmenCommissionsAPI, {
    guid
  })

  if (response) {
    yield put(actions.getSOESalesmenCommissions.success(response, form))
    yield fork(displaySalesmenCommissionsModal, form, response)
  } else {
    yield put(actions.getSOESalesmenCommissions.failure(error, form))
  }
}

export function* getSalesmenCommissionsListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.GET_SOE_SALESMEN_COMMISSIONS.TRY)

    if (form === formListener) {
      yield fork(getSalemenCommissionsProcess, form)
    }
  }
}

export function* changeCommissionValueProcess(form, payload) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  const { field, value, lineNumber, gridApi } = payload

  yield put(changeCommissionValue.request(form))

  const { response, error } = yield call(changeGridItemAPI, {
    lineNumber,
    guid,
    gridName: 'commissions',
    properties: {
      [field]: value
    }
  })

  if (response) {
    yield put(changeCommissionValue.success(response, form))
    yield fork(tryBeginEditingFocusedCell, gridApi)
  } else {
    yield put(changeCommissionValue.failure(error, form))
  }
}

export function* changeCommissionValueListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CHANGE_COMMISSION_VALUE.TRY)

    if (form === formListener) {
      yield fork(changeCommissionValueProcess, form, payload)
    }
  }
}

export function* commissionIgnoreMinimumsSwitchListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value }
    } = yield take(SET_FIELD)

    if (
      form === formListener &&
      propertyName === 'changeInvoice.commissionIgnoreMinimums'
    ) {
      yield fork(changeCommissionPropChangeProcess, form, propertyName, value)
    }
  }
}

export function* changeCommissionPropChangeProcess(form, propertyName, value) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(changeCommissionProperty.request(form))

  if (!propertyName) {
    return
  }

  const propName = propertyName.replace('changeInvoice.', '')

  const { response, error } = yield call(propertyChangeAPI, {
    action: 'commissions',
    guid,
    properties: {
      [propName]: value
    }
  })

  if (response) {
    yield put(changeCommissionProperty.success(response, form))
  } else {
    yield put(changeCommissionProperty.failure(error, form))
  }
}

export function* triggerShippingGridBulkChangeProcess(form, action) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const groupNames = ['final', 'header', 'detail']

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(propertyChangeAPI, {
    action,
    groupNames,
    guid,
    properties: {}
  })

  if (response) {
    const mapResponse = yield getMapResponse({ formState, tabIds: groupNames })
    const R = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: {
        form,
        searchFieldInteraction: false
      },
      payload: {
        ...R,
        propertyChanged: action
      }
    })
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* triggerShippingGridBulkChangeListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { action }
    } = yield take(CONSTANTS.TRIGGER_SHIPPING_GRID_BULK_CHANGE)

    if (form === formListener) {
      yield fork(triggerShippingGridBulkChangeProcess, form, action)
    }
  }
}

export function* importAutoQuoteProcess(form, content, fileName, gridApi) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  let lineItems = getIn(formState, 'fields.lineItems.rowData')
  lineItems = lineItems && lineItems.toJS ? lineItems.toJS() : []

  const groupNames = ['header', 'detail']

  yield put(actions.onPropertyChange.request(form))

  const { response, error } = yield call(massChangeAPI, {
    action: 'importautoquotes',
    content,
    fileName,
    groupNames,
    guid,
    properties: {}
  })

  if (response) {
    const mapResponse = yield getMapResponse({ formState, tabIds: groupNames })
    const R = mapResponse({
      response,
      tabIds: groupNames,
      formState,
      groupNames
    })

    yield put({
      type: CONSTANTS.ON_PROPERTY_CHANGE.SUCCESS,
      meta: {
        form,
        searchFieldInteraction: false
      },
      payload: {
        record: {
          ...R
        },
        propertyChanged: 'importautoquotes'
      }
    })

    if (gridApi) {
      yield delay(1000)
      yield fork(triggerBoxQuantityPrompts, form, gridApi, true)

      /* wait for the Box Quanity routine to complete */
      yield take(CONSTANTS.NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED)

      setTimeout(() => {
        gridApi.forEachNode(node => {
          if (node?.data?.lineNumber === 1) {
            node.setSelected(true)
          } else {
            node.setSelected(false)
          }
        })
      }, 0)
    }

    /* show the product notes */
    yield fork(handleNewlyAddedProductNotes, form, lineItems, response)
  } else {
    yield put(actions.onPropertyChange.failure(error, form))
  }
}

export function* importAutoQuoteListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { content, fileName, gridApi }
    } = yield take(CONSTANTS.TRY_IMPORT_AUTO_QUOTE)

    if (form === formListener) {
      yield fork(importAutoQuoteProcess, form, content, fileName, gridApi)
    }
  }
}
