/* eslint react/prefer-stateless-function: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

class LabelParamsModal extends Component {

  render() {
    return (
      <div>
        <p>Do you want to update the default settings?</p>
      </div>
    )
  }
}

export default connect(null, null, null, { forwardRef: true })(LabelParamsModal)
