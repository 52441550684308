var api = require("!../../../../../../.yarn/__virtual__/style-loader-virtual-95b4ca68ce/0/cache/style-loader-npm-1.2.1-d2e298addc-cd30484665.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../../../../../.yarn/__virtual__/css-loader-virtual-2ae40ad6f9/0/cache/css-loader-npm-3.5.3-0f886851e6-e5fa1707d7.zip/node_modules/css-loader/dist/cjs.js!./gridStyle.css");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};