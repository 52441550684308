import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import EditableGrid from 'components/EditableGrid'
import TextFieldGridCell from 'components/EditableGrid/components/TextFieldGridCell'
import SelectGridCell from 'components/EditableGrid/components/SelectGridCell'
import { getIn } from 'utils'
import shortid from 'shortid'
import DeleteGridCell from './DeleteGridCell'
import { setInitialRowData as setInitialRowDataAction } from '../actions'

const mapStateToProps = (state, ownProps) => {
  // console.log('ownProps', ownProps)
  const { form, type } = ownProps
  const formState = getIn(state, `ddiForm.${form}.values.selectionCriteria`)
  const fields = getIn(state, `ddiForm.${form}.fields.selectionCriteria`)
  const rowData = getIn(formState, 'additionalCriteria')
  const fieldOperatorOptions = getIn(formState, 'fieldOperatorOptions')
  const fieldConnectorOptions = getIn(formState, 'fieldConnectorOptions')
  const additionalCriteria = getIn(fields, `grids.${type}.additionalCriteria`)
  const initialRowDataSet =
    getIn(fields, `grids.${type}.additionalCriteria.initialRowDataSet`) || false

  return {
    additionalCriteria:
      additionalCriteria && additionalCriteria.toJS
        ? additionalCriteria.toJS()
        : [],
    fieldConnectorOptions:
      fieldConnectorOptions && fieldConnectorOptions.toJS
        ? fieldConnectorOptions.toJS()
        : [],
    fieldOperatorOptions:
      fieldOperatorOptions && fieldOperatorOptions.toJS
        ? fieldOperatorOptions.toJS()
        : [],
    initialRowData: rowData && rowData.toJS ? rowData.toJS() : [],
    initialRowDataSet
  }
}

class AdditionalCriteriaGrid extends Component {
  static propTypes = {
    additionalCriteria: PropTypes.array.isRequired,
    cols: PropTypes.object,
    dictionaries: PropTypes.array.isRequired,
    fieldConnectorOptions: PropTypes.array.isRequired,
    fieldOperatorOptions: PropTypes.array.isRequired,
    form: PropTypes.string.isRequired,
    initialRowData: PropTypes.array.isRequired,
    initialRowDataSet: PropTypes.bool.isRequired,
    sortDirectionOptions: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired
  }

  componentDidMount() {
    const {
      dispatch,
      form,
      type,
      initialRowData,
      initialRowDataSet
    } = this.props

    // debugger
    if (initialRowData && !initialRowDataSet) {
      dispatch(
        setInitialRowDataAction(form, {
          propertyName: `selectionCriteria.${type}.grids.additionalCriteria`,
          rowData: initialRowData
        })
      )
    }
  }

  getAdditionalCriteriaColDefs = props => {
    const {
      dictionaries,
      fieldConnectorOptions,
      fieldOperatorOptions,
      form,
      type
    } = this.props

    const params = {
      form,
      type,
      onChange: props.onCellChange,
      propertyName: props.propertyName
      // requiredCols: ['field', 'operator']
    }

    const colDefs = [
      {
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          editable: true,
          values: dictionaries
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        },
        field: 'dataId',
        headerName: 'Field',
        generateDataId: true
      },
      {
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          editable: true,
          initialValue: 'EQ',
          values: fieldOperatorOptions
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        },
        field: 'critOperator',
        headerName: 'Operator'
      },
      {
        cellRendererFramework: TextFieldGridCell,
        cellRendererParams: {
          ...params,
          allowEditWithoutDataId: true,
          isRequired: true
        },
        cellStyle: {
          paddingLeft: 10,
          paddingRight: 0
        },
        field: 'critValue',
        headerName: 'Value'
      },
      {
        cellRendererFramework: SelectGridCell,
        cellRendererParams: {
          ...params,
          editable: true,
          values: fieldConnectorOptions
        },
        cellStyle: {
          paddingLeft: 0,
          paddingRight: 0
        },
        field: 'critConnector',
        headerName: 'Connector'
      },
      {
        cellRendererFramework: DeleteGridCell,
        colsRequiredForDeletion: [],
        field: 'dataId',
        form,
        propertyName: `selectionCriteria.${type}.grids.additionalCriteria`,
        headerName: '',
        type: 'deleteGridRow',
        width: 50
      }
    ]

    return colDefs
  }

  render() {
    const { form, type } = this.props
    return (
      <div>
        <EditableGrid
          actOnCellChange
          addButtonText="Add Criteria"
          addButtonStyleParams={{
            variant: 'text'
          }}
          showAddButtonAlways
          form={form}
          type={type}
          height={150}
          propertyName={`selectionCriteria.${type}.grids.additionalCriteria`}
          columnDefs={this.getAdditionalCriteriaColDefs}
          emptyRow={{
            dataId: null,
            critOperator: '',
            critValue: '',
            critConnector: ''
          }}
          gridWrapperStyle={{ flex: '1 1', overflow: 'visible' }}
          requiredCols={['dataId', 'critOperator', 'critValue']}
          skipValidationOnUnmount
        />
      </div>
    )
  }
}

// export default AdditionalCriteriaGrid
export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(AdditionalCriteriaGrid)
