import React from 'react'
import { withContext } from 'ddiForm'
import { setFields } from 'ddiForm/actions'
import { getIn, is } from 'utils'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'
import { debounce, noop } from 'lodash'
import { removeModal } from 'modals/actions'
import { handleRebateInfoInteraction } from '../../../actions'

const marginRight = { marginRight: 5 }

export const createClearFieldsAction = (form, rebateInfoFields = {}) =>
  setFields(
    form,
    Object.keys(rebateInfoFields).reduce((acc, next) => {
      acc = acc.concat({
        propertyName: `rebateInfo.${next}`,
        value: ''
      })
      return acc
    }, [])
  )

const ActionButtons = ({
  description,
  form,
  modalId,
  lineNumber,
  rebateInfoFields = {},
  isEditing
}) => {
  const dispatch = useDispatch()

  const save = debounce(e => {
    dispatch(
      handleRebateInfoInteraction.try(form, {
        action: 'save',
        lineNumber,
        modalId
      })
    )
  }, 300)

  const clear = debounce(
    e => dispatch(createClearFieldsAction(form, rebateInfoFields)),
    300
  )

  const exitModal = e => dispatch(removeModal(form, modalId))

  return (
    <div
      className="sales-order-entry-modal-action-buttons"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '10px 0 0 0'
      }}
    >
      <Button
        onClick={save}
        variant="contained"
        style={marginRight}
        tabIndex={-1}
        disabled={!description || !isEditing}
      >
        OK
      </Button>
      <Button
        onClick={clear}
        variant="contained"
        style={marginRight}
        tabIndex={-1}
        disabled={!isEditing}
      >
        Clear
      </Button>
      <Button onClick={exitModal} variant="contained" tabIndex={-1}>
        Exit
      </Button>
    </div>
  )
}

export default ActionButtons
