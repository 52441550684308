import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import Grid from 'grid'
import useStateWithCallback from 'hooks/useStateWithCallback'
import { formatDateFields } from 'utils'

/* grid components */
import DecimalCellEditor from 'components/grid/DecimalCellEditor'

import SalesOrderTooltipCell from 'pages/SalesOrder/components/RecurringLineItemsScheduleModal/components/SalesOrderTooltipCell'
import ActionButtons from './components/ActionButtons'
import DeleteButton from './components/DeleteButton'
import { handleSwapCommittedQuantitiesInteraction } from '../../actions'

import '../../styles/modal-flex-button-styles.scss'
import './styles/swap-committed-quantities.scss'

const rightAlignParams = {
  headerClass: 'align-right right-align',
  cellClass: 'align-right right-align'
}

const centerAlignParams = {
  cellClass: 'text-center align-center',
  headerClass: 'text-center align-center'
}

let gridApi = null
let columnApi = null
let _isMounted = false

const SwapCommittedQuantitiesModal = props => {
  useEffect(() => {
    _isMounted = true
    return () => {
      _isMounted = false
    }
  }, [])

  const dispatch = useDispatch()

  const {
    data: { form, lineNumber, isEditable, swappableOrders = [], swappableText },
    id
  } = props

  const [state, setState] = useStateWithCallback({
    swappableOrders:
      swappableOrders && Array.isArray(swappableOrders) ? swappableOrders : [],
    swappableText
  })

  const updateStateAfterApiTransaction = result => {
    setState({
      ...state,
      ...result,
      swappableOrders:
        result?.swappableOrders && Array.isArray(result?.swappableOrders)
          ? result.swappableOrders
          : state.swappableOrders || []
    })
  }

  const onCellValueChanged = async params => {
    if (
      ((params.newValue || params.newValue === 0) &&
        Number(params.newValue) === Number(params.oldValue)) ||
      params.newValue === params.oldValue ||
      !_isMounted
    ) {
      return
    }

    const lineNum = params?.data?.lineNumber
    const { oldValue } = params

    let result
    try {
      result = await dispatch(
        handleSwapCommittedQuantitiesInteraction.try(form, {
          apiParams: {
            lineNumber,
            properties: {
              dataId: params?.data?.dataId,
              lineNumber: lineNum,
              [params?.colDef?.colId]: params.newValue
            }
          },
          gridApi: params.api
        })
      )
    } catch (error) {
      console.log(error)
      debugger
    } finally {
      console.log('finally', result)
      if (result?.status === 496) {
        params.api.stopEditing()
        params.api.clearFocusedCell()
        params.node.setDataValue(params?.colDef?.colId, oldValue)
      } else {
        updateStateAfterApiTransaction(result)
      }
    }
  }

  const onClickDeleteButton = async (e, lineItemData) => {
    let result
    try {
      result = await dispatch(
        handleSwapCommittedQuantitiesInteraction.try(form, {
          apiParams: {
            lineNumber,
            properties: {
              dataId: lineItemData.dataId,
              lineNumber: lineItemData.lineNumber,
              delete: null
            }
          }
        })
      )
    } catch (error) {
      console.log(error)
    } finally {
      console.log(result)
      updateStateAfterApiTransaction(result)
    }
  }

  const onCellFocused = params => {
    if (
      !params.column ||
      (params.column && params.column.colDef && !params.column.colDef.editable)
    ) {
      return
    }
    if (params?.column?.colId) {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.column && params.column.colId
      })
    }
  }

  const columnDefs = [
    {
      field: 'dataId',
      colId: 'dataId',
      cellRendererFramework: SalesOrderTooltipCell,
      headerName: 'Order',
      editable: false,
      minWidth: 100
    },
    {
      field: 'lineNumber',
      colId: 'lineNumber',
      headerName: 'Ln',
      maxWidth: 100
    },
    {
      field: 'customerName',
      colId: 'customerName',
      headerName: 'Customer Name'
    },
    {
      field: 'orderDate',
      colId: 'orderDate',
      headerName: 'Order Dt',
      valueFormatter: params => formatDateFields(params, 'M/D/YY'),
      editable: false,
      ...centerAlignParams
    },
    {
      field: 'shipDate',
      colId: 'shipDate',
      headerName: 'Ship Dt',
      valueFormatter: params => formatDateFields(params, 'M/D/YY'),
      editable: false,
      ...centerAlignParams
    },
    {
      field: 'shipViaId.value',
      colId: 'shipViaId.value',
      cellStyle: { color: '#c140f7' },
      headerName: 'Ship Via',
      editable: false,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      tooltipField: 'shipViaId.tooltip'
    },
    {
      field: 'status.value',
      colId: 'status.value',
      cellStyle: { color: '#c140f7' },
      headerName: 'Status',
      editable: false,
      minWidth: 100,
      filter: 'agTextColumnFilter',
      filterParams: {
        clearButton: true,
        suppressAndOrCondition: true
      },
      tooltipField: 'status.tooltip'
    },
    {
      field: 'quantity',
      colId: 'quantity',
      headerName: 'Qty',
      cellEditor: 'decimalCellEditor',
      editable: params => params?.data?.allowSwap,
      onCellValueChanged,
      onCellFocused,
      ...rightAlignParams
    },
    {
      field: 'qtyToShip',
      colId: 'qtyToShip',
      headerName: 'Qty to Ship',
      editable: false,
      ...rightAlignParams
    },
    {
      field: 'qtyToBO',
      colId: 'qtyToBO',
      headerName: 'Qty B/O',
      editable: false,
      ...rightAlignParams
    },
    {
      field: 'lineNumber',
      colId: 'lineNumber_delete',
      headerName: '',
      cellRendererFramework: DeleteButton,
      cellRendererParams: {
        form,
        onClickDeleteButton
      },
      maxWidth: 50
    }
  ]

  const onGridReady = params => {
    gridApi = params.api
    columnApi = params.columnApi
  }

  const getRowNodeById = data => `${data.dataId}-${data.lineNumber}`

  return (
    <div className="sales-order-entry-fullscreen-flex-modal-wrapper">
      <div
        className="sales-order-entry-modal-primary-interface-wrapper"
        style={{ flexDirection: 'column' }}
      >
        <p
          style={{
            textAlign: 'center',
            fontSize: '0.9em'
          }}
        >
          {state.swappableText}
        </p>
        <div style={{ width: '100%' }}>
          <Grid
            headerName="Line Items"
            columnDefs={columnDefs}
            rowData={state.swappableOrders}
            getRowNodeById={getRowNodeById}
            height={350}
            onGridReady={onGridReady}
            components={{
              decimalCellEditor: DecimalCellEditor
            }}
            singleClickEdit
          />
        </div>
      </div>
      <ActionButtons form={form} modalId={id} lineNumber={lineNumber} />
    </div>
  )
}

export default SwapCommittedQuantitiesModal
