import { all, take, call, select, put, fork, delay } from 'redux-saga/effects'
import { fromJS, getIn, getField } from 'utils'
import { intersection } from 'lodash'
import { handleEntityResponse } from 'ddiForm/MasterScreen/sagas'
import { addModal } from 'modals/actions'
import * as DDICONSTANTS from 'ddiForm/constants'
import * as INDEX_SEARCH_CONSTANTS from 'components/Search/IndexSearch/constants'
import * as MASTER_CONSTANTS from 'ddiForm/MasterScreen/constants'
import { getFormSelector } from 'ddiForm/utils'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import { showPrintDocumentModalProcess } from 'components/PrintDocumentModal/sagas'
import { sendDocument } from 'components/PrintDocumentModal/actions'
import SalesHistoryReportModal from 'pages/CustomerMaster/tabs/Ledger/components/LedgerTab/components/modals/SalesHistoryReportModal'
import { displayValidationErrors } from 'ddiForm/sagas'
import moment from 'moment'
import { clearLayoutScreenData } from 'pages/Layout/actions'
import { setField, tryChangeFormTab, exit } from 'ddiForm/actions'
import * as api from './api'
import * as CONSTANTS from './constants'
import * as actions from './actions'

let triggerGetSummaryData = false

export function* getCustomerLedgerFilterApiParams(ledgerName) {
  const formState = yield select(getFormSelector('customerMaster'))
  const filteredInvoices =
    getIn(formState, 'values.customerLedger.filteredRows.invoices') ||
    fromJS([])

  const apiParams = {
    orders: {
      orderType: getIn(formState, 'fields.customerLedger.ordersFilter.value'),
      groupNames: ['summary', 'orders']
    },
    invoices: {
      invoiceType:
        getIn(formState, 'fields.customerLedger.invoicesFilter.value') || 'I',
      invoiceYear: getIn(
        formState,
        'fields.customerLedger.invoicesYearFilter.value'
      ),
      invoices:
        filteredInvoices && filteredInvoices?.toJS
          ? filteredInvoices.toJS()
          : [],
      groupNames: ['summary', 'invoices']
    },
    paymentHistory: {
      paymentHistoryYear: getIn(
        formState,
        'fields.customerLedger.paymentHistoryFilter.value'
      ),
      groupNames: ['summary', 'paymentHistory']
    },
    salesHistory: {
      salesHistoryYear: getIn(
        formState,
        'fields.customerLedger.salesHistoryFilter.value'
      ),
      groupNames: ['summary', 'salesHistory']
    },
    salesAnalysis: {
      groupNames: ['summary', 'salesAnalysis']
    },
    subAccountLedger: {
      groupNames: ['summary', 'subAccountLedger']
    },
    monthlyBreakdown: {
      groupNames: ['summary', 'monthlyBreakdown']
    },
    transactions: {
      groupNames: ['summary', 'transactions'],
      transactionsFromDate: getIn(
        formState,
        'fields.customerLedger.transactionsFilter.value'
      )
    }
  }

  if (apiParams[ledgerName]) {
    const ret = {}
    for (const prop in apiParams[ledgerName]) {
      ret[prop] =
        apiParams[ledgerName][prop] === 'All' ? '' : apiParams[ledgerName][prop]
    }

    return ret
  }

  return {}
}

export function* pushToInvoiceTab(form, clearMetaData = false) {
  yield put({
    type: DDICONSTANTS.CHANGE_SECONDARY_TAB,
    payload: {
      primaryTab: 'ledger',
      value: 'invoices'
    },
    meta: { form, clearMetaData }
  })
}

export function* wrapLedgerDataAccess(form) {
  // try {
  //   yield call(pushToInvoiceTab, form)
  // } catch (e) {
  //   throw new Error(e)
  // }
  try {
    // debugger
    return yield fork(initCustomerLedger, form, true)
  } catch (e) {
    debugger
    throw new Error(e)
  }
}

export function* wrapPaymentHistoryDataAccess(form) {
  const apiParams = yield call(
    getCustomerLedgerFilterApiParams,
    'paymentHistory'
  )
  yield fork(getCustomerLedgerDataByTab, form, 'paymentHistory', apiParams)
}

export function* wrapOrdersDataAccess(form) {
  // debugger
  const apiParams = yield call(getCustomerLedgerFilterApiParams, 'orders')
  yield fork(getCustomerLedgerDataByTab, form, 'orders', apiParams)
}

export function* wrapSalesHistoryDataAccess(form) {
  const apiParams = yield call(getCustomerLedgerFilterApiParams, 'salesHistory')
  yield fork(getCustomerLedgerDataByTab, form, 'salesHistory', apiParams)
}

export function* wrapSalesAnalysisDataAccess(form) {
  yield fork(getCustomerLedgerDataByTab, form, 'salesAnalysis')
}

export function* wrapSubAccountLedgerDataAccess(form) {
  yield fork(getCustomerLedgerDataByTab, form, 'subAccountLedger')
}

export function* wrapMonthlyBreakdownDataAccess(form) {
  yield fork(getCustomerLedgerDataByTab, form, 'monthlyBreakdown')
}

export function* wrapTransactionsDataAccess(form) {
  const apiParams = yield call(getCustomerLedgerFilterApiParams, 'transactions')
  yield fork(getCustomerLedgerDataByTab, form, 'transactions', apiParams)
}

export function* getCustomerLedgerDataByTab(
  form,
  tab,
  additionalApiParams = {}
) {
  const formState = yield select(getFormSelector(form))
  const data = getIn(formState, `values.customerLedger.grids.${tab}`)
  const summary = getIn(formState, 'values.customerLedger.summary')
  const shipToId = getIn(formState, 'fields.shipToId.value')
  const dataId = getIn(formState, 'fields.dataId.value')
  const selectedShipToId = getIn(
    formState,
    `values.customerLedger.shipTosByTab.${tab}.selectedShipToId`
  )

  if (!dataId) {
    return
  }
  // debugger

  if (!summary) {
    yield fork(wrapLedgerDataAccess, form)
    return
  }

  const apiParams = {}
  for (const prop in additionalApiParams) {
    if (prop !== 'groupNames') {
      apiParams[prop] = additionalApiParams[prop]
    }
  }

  if (!data || selectedShipToId !== shipToId) {
    yield fork(
      readCustomerLedgerByTab,
      form,
      {
        groupNames: ['summary', tab],
        ...apiParams
      },
      tab
    )
  }
}

export function* initCustomerLedger(form, isAccessCheck = false) {
  const formState = yield select(getFormSelector(form))
  let metaData = getIn(formState, 'meta.ledgerMeta')
  metaData = metaData && metaData.toJS ? metaData.toJS() : {}
  /* sometimes the 'field' is not registered yet, but the value is, e.g. opening from another tab -- SVE 2/26/20 */
  const dataId = getIn(formState, 'fields.dataId.value')
  // const fetchingEntity = getIn(formState, 'fetchingEntity')

  const shipToId = getIn(formState, 'fields.shipToId.value')
  const selectedShipToId = getIn(
    formState,
    'values.customerLedger.shipTosByTab.invoices.selectedShipToId'
  )
  // const summary = getIn(formState, 'values.customerLedger.summary')
  const invoices = getIn(formState, 'values.customerLedger.grids.invoices')
  const filteredInvoices =
    getIn(formState, 'values.customerLedger.filteredRows.invoices') ||
    fromJS([])

  // debugger

  if (!Object.keys(metaData).length) {
    // debugger
    yield put({
      type: CONSTANTS.GET_CUSTOMER_LEDGER_META.REQUEST,
      meta: { form, apiRequest: true }
    })

    const { response, error } = yield call(api.getCustomerLedgerMeta, {
      dataId
    })
    // debugger

    if (response) {
      yield call(pushToInvoiceTab, form)
      yield put(actions.getCustomerLedgerMeta.success(response.ledgerMeta))

      /* first time we get the invoices, the params are always the same */
      /* readCustomerLedgerByTab will add any dataId or shipToId */
      // debugger
      if (!invoices) {
        yield fork(
          readCustomerLedgerByTab,
          form,
          {
            invoiceType: 'I',
            invoiceYear: '',
            invoices:
              filteredInvoices && filteredInvoices?.toJS
                ? filteredInvoices.toJS()
                : [],
            groupNames: ['summary', 'invoices']
          },
          'invoices'
        )
      }
    } else {
      // debugger
      yield put(actions.getCustomerLedgerMeta.failure(error))
      yield put({
        type: DDICONSTANTS.CHANGE_SECONDARY_TAB,
        payload: {
          primaryTab: 'setup',
          value: 'main'
        },
        meta: { form }
      })
      throw new Error(error)
    }
  } else if (!invoices || shipToId !== selectedShipToId) {
    // debugger
    yield fork(pushToInvoiceTab, form)
    const apiParams = yield call(getCustomerLedgerFilterApiParams, 'invoices')
    yield fork(
      readCustomerLedgerByTab,
      form,
      apiParams,
      'invoices',
      isAccessCheck
    )
  } else {
    // debugger
    yield fork(pushToInvoiceTab, form)
  }
}

export function* readCustomerLedgerByTab(
  form,
  params = {},
  tab,
  isAccessCheck = false
) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const shipToId = getIn(formState, 'fields.shipToId.value')

  // debugger
  if (!dataId) {
    return
  }

  yield put({
    type: CONSTANTS.READ_CUSTOMER_LEDGER_BY_TAB.REQUEST,
    payload: { tab },
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api.readCustomerLedger, {
    ...params,
    shipToId,
    dataId
  })

  if (response) {
    const { ledger, ...rest } = response
    yield put({
      type: CONSTANTS.READ_CUSTOMER_LEDGER_BY_TAB.SUCCESS,
      payload: {
        ...rest,
        ...ledger,
        tab
      },
      meta: { form }
    })
    // debugger
    yield fork(handleEntityResponse, form, response)
  } else {
    const selectedPrimaryTab = getIn(
      formState,
      'masterOptions.selectedPrimaryTab'
    )
    const selectedSecondaryTab = getIn(
      formState,
      'masterOptions.selectedSecondaryTab'
    )

    /* send em back to the tab they were trying to leave */
    /* this is mainly for Sales History */
    yield put(tryChangeFormTab(form, selectedPrimaryTab, selectedSecondaryTab))
    yield put({
      type: CONSTANTS.READ_CUSTOMER_LEDGER_BY_TAB.FAILURE,
      payload: error,
      meta: { form }
    })

    if (isAccessCheck) {
      // debugger
      // yield delay(1000)
      /*
        OK this is a tad hacky, BUT it works and prevents us from having to re-consider
        further framework changes etc. just to deal with Customer Ledger -- SVE 3/16/2020
      */
      yield take(CONSTANTS.CHECK_UPDATE_SUMMARY_DATA)
      yield put(
        tryChangeFormTab(form, selectedPrimaryTab, selectedSecondaryTab)
      )
      // throw new Error(error)
    }
  }
}

export function* customerLedgerFilterListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { propertyName, value },
      payload,
      type
    } = yield take([
      DDICONSTANTS.SET_FIELD,
      INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH,
      DDICONSTANTS.BLUR
    ])

    // debugger
    const formState = yield select(getFormSelector(form))
    const prevDataId = getIn(formState, 'fields.dataId.prevValue')
    const selectedPrimaryTab = getIn(
      formState,
      'masterOptions.selectedPrimaryTab'
    )
    const selectedSecondaryTab = getIn(
      formState,
      'masterOptions.selectedSecondaryTab'
    )
    // debugger

    /*
      if we are sitting on a Ledger screen,
      and the user has cleared out the dataId,
      we need to send 'em home
    */
    if (form === formListener) {
      if (
        (propertyName &&
          propertyName === 'dataId' &&
          type === INDEX_SEARCH_CONSTANTS.CLEAR_SEARCH) ||
        (propertyName &&
          propertyName === 'dataId' &&
          value !== prevDataId &&
          type === DDICONSTANTS.SET_FIELD)
      ) {
        // debugger
        if (selectedPrimaryTab === 'ledger') {
          yield fork(pushToInvoiceTab, form)
        }
      }

      /* no API for Sales Analysis or the Sales Order Print Type switch */
      if (
        propertyName &&
        propertyName.match(/customerLedger/gi) &&
        propertyName !== 'customerLedger.salesAnalysisFilter' &&
        propertyName !== 'customerLedger.orderPrintType' &&
        propertyName !== 'salesReportPrintConfig.productLineId' &&
        propertyName !== 'salesReportPrintConfig.vendorId'
      ) {
        // debugger
        if (type === DDICONSTANTS.BLUR) {
          const prevValue = getIn(formState, `fields.${propertyName}.prevValue`)
          if (propertyName === 'customerLedger.transactionsFilter') {
            if (
              prevValue &&
              new Date(value).getTime() !== new Date(prevValue).getTime()
            ) {
              yield fork(updateViewOnChange, form, selectedSecondaryTab)
            }
          } else if (value !== prevValue) {
            yield fork(updateViewOnChange, form, selectedSecondaryTab)
          }
        } else {
          yield fork(updateViewOnChange, form, selectedSecondaryTab)
        }
      }

      /* if the user changes the Ship To field */
      if (
        propertyName &&
        propertyName === 'shipToId' &&
        selectedPrimaryTab === 'ledger'
      ) {
        yield fork(updateViewOnChange, form, selectedSecondaryTab)
      }
    }
  }
}

export function* updateViewOnChange(form, selectedSecondaryTab) {
  const apiData = yield call(
    getCustomerLedgerFilterApiParams,
    selectedSecondaryTab
  )
  yield fork(readCustomerLedgerByTab, form, apiData, selectedSecondaryTab, true)
}

export function* getEntitySuccessListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload,
      type
    } = yield take(MASTER_CONSTANTS.GET_ENTITY.SUCCESS)
    debugger

    if (form === formListener) {
      const formState = yield select(getFormSelector(form))
      const selectedPrimaryTab = getIn(
        formState,
        'masterOptions.selectedPrimaryTab'
      )
      const selectedSecondaryTab = getIn(
        formState,
        'masterOptions.selectedSecondaryTab'
      )

      const openedFrom = getIn(formState, 'openedFrom')

      /*
        do not clear the ledger if we are just loading Activities
        but otherwise, do clear it out
      */
      debugger
      if (
        selectedPrimaryTab === 'ledger' &&
        !payload.ledger &&
        openedFrom !== 'ledger'
      ) {
        /* this is more like a failsafe at this point */
        yield put(actions.clearCustomerLedger())
        yield fork(wrapLedgerDataAccess, form)
      }
    }
  }
}

// export function* getEntitySuccessListener(formListener) {
//  while (true) {
//    const {
//      meta: { form },
//      payload,
//      type
//    } = yield take(MASTER_CONSTANTS.GET_ENTITY.SUCCESS)
//    // debugger

//    if (form === formListener) {
//      const formState = yield select(getFormSelector(form))
//      const selectedPrimaryTab = getIn(formState, 'masterOptions.selectedPrimaryTab')
//      const selectedSecondaryTab = getIn(formState, 'masterOptions.selectedSecondaryTab')

//      if (selectedPrimaryTab === 'ledger') {
//        yield put(actions.clearCustomerLedger())
//        yield call(wrapLedgerDataAccess, form)
//      } else {
//        yield put(actions.clearCustomerLedger())
//      }

//    }
//  }

// }

export function* launchPrintModalProcess(
  form,
  constantKey,
  apiMethod,
  args,
  isDirectSend = false
) {
  yield put({
    type: CONSTANTS[constantKey].REQUEST,
    meta: { form, apiRequest: true }
  })

  const { response, error } = yield call(api[apiMethod], args)

  if (response) {
    yield put({
      type: CONSTANTS[constantKey].SUCCESS,
      payload: response,
      meta: { form }
    })

    if (isDirectSend) {
      yield put(sendDocument(form, { dataId: response.dataId }))
    } else {
      yield fork(showPrintDocumentModalProcess, form, {
        ...response,
        form
      })
    }
  } else {
    yield put({
      type: CONSTANTS[constantKey].FAILURE,
      payload: error,
      meta: { form }
    })

    yield fork(displayValidationErrors, error)
  }
}

export function* printInvoicesListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { invoices, isDirectSend = false }
    } = yield take(CONSTANTS.PRINT_INVOICES.TRY)
    const formState = yield select(getFormSelector(form))
    const dataId = getIn(formState, 'fields.dataId.value')
    const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
      false

    if (form === formListener) {
      // debugger
      const apiMethod = isMobile ? 'printInvoicesMobile' : 'printInvoices'
      yield fork(
        launchPrintModalProcess,
        form,
        'PRINT_INVOICES',
        apiMethod,
        { dataId, invoices },
        isDirectSend
      )
    }
  }
}

export function* printCreditRefLetterListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(CONSTANTS.PRINT_CREDIT_REF_LETTER.TRY)
    const formState = yield select(getFormSelector(form))
    const dataId = getIn(formState, 'fields.dataId.value')

    yield fork(
      launchPrintModalProcess,
      form,
      'PRINT_CREDIT_REF_LETTER',
      'printCreditRefLetter',
      { dataId }
    )
  }
}

export function* openARNotesListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_AR_NOTES.TRY)
    const {
      meta: { form },
      payload
    } = action

    if (form === formListener) {
      const contactsOpen = yield select(getFormSelector('contact'))
      debugger
      if (contactsOpen) {
        yield put(exit('contact'))
      }
      yield fork(openARNotesProcess, form, payload)
    }
  }
}

export function* openARNotesProcess(form, payload) {
  const { customerId } = payload

  const { response, error } = yield call(api.openARNotes, {
    dataId: customerId
  })
  // debugger

  if (response) {
    const { dataId, parentId, parentType } = response
    // debugger

    yield put(
      openScreenAction({
        dataId,
        parentId,
        parentType,
        displayName: 'Contact',
        name: 'contact',
        recordType: 'Contact',
        selectedPrimaryTab: 'activities',
        // startTab: {
        //   primary: 'activities'
        // },
        // groupNames: ['activities'],
        title: 'Contact Master'
      })
    )
  }
}

export function* getLedgerDocProcess(form, type, dataIds = []) {
  const formState = yield select(getFormSelector('customerMaster'))
  const filteredInvoices =
    getIn(formState, 'values.customerLedger.filteredRows.invoices') ||
    fromJS([])

  if (type === 'invoices') {
    const invoiceYear = getIn(
      formState,
      'fields.customerLedger.invoicesYearFilter.value'
    )
    const apiParams = {
      dataId: getIn(formState, 'fields.dataId.value'),
      invoiceType: getIn(
        formState,
        'fields.customerLedger.invoicesFilter.value'
      ),
      invoices:
        filteredInvoices && filteredInvoices?.toJS
          ? filteredInvoices.toJS()
          : [],
      invoiceYear: invoiceYear === 'All' ? '' : invoiceYear,
      shipToId: getIn(formState, 'fields.shipToId.value')
    }

    yield fork(
      launchPrintModalProcess,
      form,
      'GET_LEDGER_DOC',
      'getInvoiceReport',
      apiParams
    )
  }

  if (type === 'paymentHistory') {
    const paymentHistoryYear = getIn(
      formState,
      'fields.customerLedger.paymentHistoryFilter.value'
    )

    const apiParams = {
      dataId: getIn(formState, 'fields.dataId.value'),
      shipToId: getIn(formState, 'fields.shipToId.value'),
      paymentHistoryYear: paymentHistoryYear === 'All' ? '' : paymentHistoryYear
    }

    yield fork(
      launchPrintModalProcess,
      form,
      'GET_LEDGER_DOC',
      'getPaymentHistoryReport',
      apiParams
    )
  }

  if (type === 'orders' && dataIds && dataIds.length) {
    const apiParams = {
      dataIds,
      status: getIn(formState, 'fields.customerLedger.ordersFilter.value'),
      printType: getIn(formState, 'fields.customerLedger.orderPrintType.value')
    }
    yield fork(
      launchPrintModalProcess,
      form,
      'GET_LEDGER_DOC',
      'printOrders',
      apiParams
    )
  }

  if (type === 'subAccountLedger') {
    const apiParams = {
      dataId: getIn(formState, 'fields.dataId.value'),
      shipToId: getIn(formState, 'fields.shipToId.value')
    }

    yield fork(
      launchPrintModalProcess,
      form,
      'GET_LEDGER_DOC',
      'getSubAccountLedgerReport',
      apiParams
    )
  }
}

export function* getLedgerDocListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.GET_LEDGER_DOC.TRY)
    const {
      meta: { form },
      payload: { type, dataIds }
    } = action

    if (form === formListener) {
      yield fork(getLedgerDocProcess, form, type, dataIds)
    }
  }
}

export function* getSalesHistoryReportProcess(form, apiParams) {
  if (form && apiParams) {
    yield fork(
      launchPrintModalProcess,
      form,
      'GET_SALES_HISTORY_REPORT',
      'getSalesHistoryReport',
      apiParams
    )
  }
}

export function* getSalesHistoryReportListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload
    } = yield take(CONSTANTS.GET_SALES_HISTORY_REPORT.TRY)

    if (form === formListener) {
      yield fork(getSalesHistoryReportProcess, form, payload)
    }
  }
}

export function* openSalesHistoryReportOptionsProcess(form) {
  const sortFieldsRef = [
    'productId',
    'description',
    'productLineId',
    'vendorId'
  ]

  const options = {
    component: SalesHistoryReportModal,
    options: {
      data: {
        actions: [
          {
            primary: true,
            title: 'Run',
            async clickEvent(args, formState, cb) {
              const {
                printMostRecentSalesOnly,
                printPrices,
                printHistoryGrossProfit,
                printHiddenProducts
              } = this.state

              const dataId = getField(formState, 'dataId')
              const shipToId = getField(formState, 'shipToId')
              const salesHistoryYear = getField(
                formState,
                'customerLedger.salesHistoryFilter'
              )

              const productLineId = getField(
                formState,
                'salesReportPrintConfig.productLineId'
              )
              const vendorId = getField(
                formState,
                'salesReportPrintConfig.vendorId'
              )
              const sortField = getField(
                formState,
                'salesReportPrintConfig.sortField'
              )

              const apiParams = {
                dataId,
                salesHistoryYear:
                  salesHistoryYear === 'All' ? '' : salesHistoryYear,
                shipToId,
                printMostRecentSalesOnly,
                printPrices,
                printHistoryGrossProfit,
                printHiddenProducts,
                productLineId,
                vendorId,
                sortField: sortFieldsRef.includes(sortField)
                  ? sortField
                  : 'productId'
              }

              try {
                await this.props.dispatch(
                  actions.getSalesHistoryReport(apiParams)
                )
              } finally {
                console.log('excellent')
              }
            }
          },
          {
            primary: true,
            title: 'Exit',
            async clickEvent(args, formState, cb) {
              try {
                await this.props.dispatch(
                  actions.closeSalesHistoryReportModal()
                )
              } finally {
                cb()
              }
            }
          }
        ]
      },
      title: 'Sales History Report'
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
}

export function* openSalesHistoryReportOptionsListener(formListener) {
  while (true) {
    const action = yield take(CONSTANTS.OPEN_SALES_HISTORY_REPORT_OPTIONS)
    const {
      meta: { form }
    } = action

    if (form === formListener) {
      yield fork(openSalesHistoryReportOptionsProcess, form)
    }
  }
}

export function* saveAgingViewProcess(view, form) {
  const { response, error } = yield call(api.saveAgingView, {
    view
  })
  // debugger

  if (response) {
    yield put({
      type: CONSTANTS.SAVE_AGING_VIEW.SUCCESS,
      payload: response,
      meta: { form }
    })
  } else {
    yield put({
      type: CONSTANTS.SAVE_AGING_VIEW.FAILURE,
      payload: error,
      meta: { form }
    })
  }
}

export function* saveAgingViewListener(formListener) {
  while (true) {
    const {
      meta: { form },
      payload: { agingView }
    } = yield take(CONSTANTS.SAVE_AGING_VIEW.TRY)

    if (form === formListener) {
      yield fork(saveAgingViewProcess, agingView, form)
    }
  }
}

export function* getNextARNote(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')

  yield put(actions.getNextARNote.request(form))

  const { response, error } = yield call(api.getNextARNote, {
    dataId
  })

  if (response) {
    yield put(actions.getNextARNote.success(response, form))
  } else {
    yield put(actions.getNextARNote.failure(error, form))
  }
}

export function* checkUpdateSummaryDataProcess(form, dataId) {
  const formState = yield select(getFormSelector(form))
  let editedFields = getIn(formState, 'editedFields')
  editedFields = editedFields && editedFields.toJS ? editedFields.toJS() : []
  const editableFields = ['arContactName', 'arPhone', 'salesmanID', 'arTermsId']
  const isSharedFieldEdited = intersection(editedFields, editableFields)

  let ledgerSummary = getIn(formState, 'values.customerLedger.summary')
  ledgerSummary =
    ledgerSummary && ledgerSummary.toJS
      ? ledgerSummary.toJS()
      : {
          salesmanName: '',
          contactNameAndPhone: '',
          termsDescription: '',
          taxExemptNo: ''
        }

  const salesmanDescription =
    getIn(formState, 'values.salesmanDescription') || ''
  const arContactName = getIn(formState, 'values.arContactName') || ''
  const arPhone = getIn(formState, 'values.arPhone') || ''
  const arTermsDescription = getIn(formState, 'values.arTermsDescription') || ''
  const taxExemptNumber = getIn(formState, 'values.taxExemptNumber') || ''
  const contactNameAndNumberString = `${arContactName} - ${arPhone}`

  /*
    need some really convoluted logic here to account for asymetrical API return data,
    save after refresh, etc. -- SVE 3/6/2020
  */
  if (
    (isSharedFieldEdited && isSharedFieldEdited.length) ||
    (ledgerSummary &&
      salesmanDescription &&
      ledgerSummary.salesmanName &&
      ledgerSummary.salesmanName !== salesmanDescription) ||
    (ledgerSummary &&
      arTermsDescription &&
      ledgerSummary.termsDescription &&
      ledgerSummary.termsDescription !== arTermsDescription) ||
    (ledgerSummary &&
      contactNameAndNumberString &&
      arContactName &&
      arPhone &&
      ledgerSummary.contactNameAndPhone &&
      ledgerSummary.contactNameAndPhone !== contactNameAndNumberString) ||
    (ledgerSummary &&
      taxExemptNumber &&
      ledgerSummary.taxExemptNo &&
      ledgerSummary.taxExemptNo !== taxExemptNumber) ||
    triggerGetSummaryData
  ) {
    // debugger
    yield fork(getSummaryData, form)
  }
}

export function* getSummaryData(form) {
  const formState = yield select(getFormSelector(form))
  const dataId = getIn(formState, 'fields.dataId.value')
  const shipToId = getIn(formState, 'fields.shipToId.value')

  // debugger
  if (!dataId) {
    return
  }

  yield put(actions.getSummaryData.request(form))

  const { response, error } = yield call(api.readCustomerLedger, {
    groupNames: ['summary'],
    shipToId,
    dataId
  })

  if (response) {
    const { ledger, ...rest } = response
    // debugger
    yield put(
      actions.getSummaryData.success(
        {
          ...ledger
        },
        form
      )
    )
    triggerGetSummaryData = false
  } else {
    yield put(actions.getSummaryData.failure(error, form))
  }
}

export function* checkUpdateSummaryDataListener(formListener) {
  while (true) {
    const {
      // payload: { dataId },
      meta: { form }
    } = yield take(CONSTANTS.CHECK_UPDATE_SUMMARY_DATA)
    // debugger
    if (form === formListener) {
      yield fork(checkUpdateSummaryDataProcess, form)
    }
  }
}

export function* openScreenSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(DDICONSTANTS.OPEN_SCREEN.SUCCESS)

    if (form === formListener) {
      yield put(actions.correctLedgerMetaData(form))
    }
  }
}

export function* saveSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(DDICONSTANTS.SAVE.SUCCESS)

    if (form === formListener) {
      /* set this variable to ensure trigger of a summary data on return to ledger */
      triggerGetSummaryData = true
      // debugger
    }
  }
}

export function* pushToSetupTabProcess(form) {
  const formState = yield select(getFormSelector(form))
  const selectedPrimaryTab = getIn(
    formState,
    'masterOptions.selectedPrimaryTab'
  )
  const selectedSecondaryTab = getIn(
    formState,
    'masterOptions.selectedSecondaryTab'
  )

  if (selectedPrimaryTab === 'ledger') {
    // debugger
    yield put(tryChangeFormTab(form, 'setup', 'main'))
  }
}

export function* lockForEditSuccessListener(formListener) {
  while (true) {
    const {
      meta: { form }
    } = yield take(MASTER_CONSTANTS.LOCK_FOR_EDIT.SUCCESS)

    if (form === formListener) {
      // debugger
      yield fork(pushToSetupTabProcess, form)
    }
  }
}

export default function* customerLedgerSagas(form) {
  yield all([
    fork(customerLedgerFilterListener, form),
    fork(printInvoicesListener, form),
    fork(printCreditRefLetterListener, form),
    // fork(printInvoiceReportListener, form),
    fork(openARNotesListener, form),
    fork(getLedgerDocListener, form),
    fork(openSalesHistoryReportOptionsListener, form),
    fork(getSalesHistoryReportListener, form),
    // fork(getEntitySuccessListener, form),
    fork(saveAgingViewListener, form),
    fork(checkUpdateSummaryDataListener, form),
    fork(openScreenSuccessListener, form),
    fork(saveSuccessListener, form),
    fork(lockForEditSuccessListener, form)
  ])
}
