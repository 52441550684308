import React from 'react'
import PropTypes from 'prop-types'
import { Icon, IconButton } from '@material-ui/core'

import { clearShipmentStatus } from 'pages/SalesOrder/actions'


export const ClearStatusCell = ({
  data,
  dispatch,
  form,
  value
}) => {

  function onClick() {
    dispatch(clearShipmentStatus.try(form, { dataId: data.dataId }))
  }

  return (
    <>
      {value && (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <IconButton onClick={onClick} tabIndex={-1}>
            <Icon
              style={{
                color: '#d9534f',
                display: 'inline-block',
                fontSize: 16,
              }}
            >
              cancel
            </Icon>
          </IconButton>
        </div>
        )
      }
    </>
  )
}

export default ClearStatusCell