import { mapCustomFieldsData } from 'components/MasterScreen/CustomFields/utils'

export const getrRowNodeId = data => data.dataId

export const mapDataResponse = ({ response, tabIds = [], formState, groupNames = [] }) => {

  const nested = [
    // ...tabIds,
    // ...groupNames,
    // 'activities',
    'customFields'
  ]

  let res
  if (response && Object.keys(response).length) {
    const tempResponse = Object.keys(response).reduce((acc, next) => {
      if (nested.includes(next) && response[next] !== null && typeof response[next] === 'object' && Object.keys(response[next]).length) {
        for (const prop in response[next]) {
          if (prop === 'customFieldsData') {
            acc = mapCustomFieldsData(acc, response[next][prop])
          }

          acc[prop] = response[next][prop]
        }
      } else {
        acc[next] = response[next]
      }
      return acc
    }, {})

    res = tempResponse
  }

  // console.log(res)
  // debugger

  return res
}

export const removeEmptyRow = (data = []) => {

  if (data?.length) {
    return data.reduce((acc, next) => {
      if (next.dataId) {
        acc = acc.concat(next)
      }

      return acc
    }, [])
  }

  return data
}