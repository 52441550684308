/* eslint react/no-this-in-sfc: 0 */
import {
  call,
  fork,
  put,
  putResolve,
  select,
  take,
  delay
} from 'redux-saga/effects'
import { getFormSelector } from 'ddiForm/utils'
import { setField, updateFormTitle, tryChangeFormTab } from 'ddiForm/actions'
import { resetMasterFields } from 'ddiForm/MasterScreen/actions'
import { getIn, noop, is, fromJS } from 'utils'
import {
  getDuplicateProductMessage,
  getGroupNames,
  getInventoryNames
} from 'pages/SalesOrder/utils'
import { CANCELED, CONFIRMED } from 'modals/constants'
import * as actions from 'pages/SalesOrder/actions'
import * as CONSTANTS from 'pages/SalesOrder/constants'
import Dialog from 'modals/Dialog'
import { confirmationModal } from 'modals/sagas'
import { addModal, removeModal } from 'modals/actions'
import { api } from 'services'
import { validateGridData } from 'components/EditableGrid/actions'
import PriceOverrideModal from 'modals/PriceOverrideModal'
import DuplicateProductsModalActions from '../components/DuplicateProductsModalActions'
import RepairItemModal from '../components/RepairItemModal'
import { displayProductNotesHandler } from './notesHandlerSagas'

/* need to get all groupNames for mobile */
const mobileGroupNames = ['header', 'detail', 'final']
export function* priceOverrideProcess(
  form,
  error,
  apiMethod,
  apiParams,
  actionName,
  successFn = noop,
  failFn = noop
) {
  if (
    error?.status === 499 &&
    error?.message === 'Price Override Reason Code Required'
  ) {
    // c
    apiParams.action = actionName
    const { response, error } = yield call(apiMethod, apiParams)
    if (response) {
      const modal = yield call(addModal, form, {
        component: PriceOverrideModal,
        props: {
          form,
          apiParams,
          apiMethod,
          successFn,
          failFn,
          ...response
        }
      })
      yield put(modal)
      // debugger
      // let listeners =
    }
  }
}

export function* displayDuplicateProductsModal(form, message, title) {
  const options = {
    component: Dialog,
    options: {
      actions: DuplicateProductsModalActions,
      data: {
        message
      },
      modalOverrideClass: '',
      title: title || 'Cancel?',
      type: 'confirm',
      width: 500
    }
  }

  const modal = yield call(addModal, form, options)
  yield put(modal)
  return modal.payload.id
}

export function* duplicateProductsRoutine(form, lineItems, data) {
  const formState = yield select(getFormSelector(form))
  const isQuote = getIn(formState, 'fields.quote.value') || false
  const suppressDuplicateProductMessageInSalesOrder = getIn(
    formState,
    'meta.suppressDuplicateProductMessageInSalesOrder'
  )

  const processedPostData = []
  let duplicatesProcessed = false
  let applyForAll = false
  let action = {}

  if (
    !suppressDuplicateProductMessageInSalesOrder ||
    (suppressDuplicateProductMessageInSalesOrder === 'O' && isQuote) ||
    (suppressDuplicateProductMessageInSalesOrder === 'Q' && !isQuote)
  ) {
    for (const prop of data) {
      const isDuplicate = lineItems.find(x => x.dataId === prop.dataId)
      if (isDuplicate) {
        const duplicateMessage = getDuplicateProductMessage(isDuplicate)

        if (!applyForAll) {
          yield call(
            displayDuplicateProductsModal,
            form,
            duplicateMessage,
            'Duplicate Product'
          )
          // action = yield take([CONFIRMED, CANCELED])
          action = yield take([
            CONSTANTS.ACCEPT_DUPLICATE_PRODUCT,
            CONSTANTS.REJECT_DUPLICATE_PRODUCT
          ])

          applyForAll = action.payload.applyForAll
          yield put(removeModal(form, action.payload.modalId))

          action = {
            type: action.type
          }
        }

        if (action.type === CONSTANTS.ACCEPT_DUPLICATE_PRODUCT) {
          processedPostData.push(prop)
        }
      } else {
        processedPostData.push(prop)
      }
    }

    duplicatesProcessed = true
  }

  return { processedPostData, duplicatesProcessed }
}

export function* triggerBoxQuantityPrompts(
  form,
  gridApi,
  notifyRoutineCompleted = false
) {
  if (gridApi.getDisplayedRowCount && gridApi.getDisplayedRowAtIndex) {
    const rowCount = gridApi.getDisplayedRowCount()
    let counter = 0
    // anyway to only do this on new rows?
    while (counter < rowCount) {
      const node = gridApi.getDisplayedRowAtIndex(counter)
      // console.log(node)
      let t
      let quantityOrdered

      if (node?.data?.roundToBoxQuantity) {
        if (node && node.data && node.data.quantityOrdered > 0) {
          ;({
            data: { quantityOrdered }
          } = node)

          t = yield putResolve({
            type: 'TRY_CELL_CHANGED_REQUEST',
            meta: { form, thunk: true, reducer: 'Grid' },
            payload: {
              value: quantityOrdered,
              propertyName: 'lineItems',
              field: 'quantityOrdered',
              rowIndex: node.childIndex,
              data: node.data,
              node
            }
          })
        }
        if (t !== quantityOrdered) {
          node.setDataValue('quantityOrdered', t)
        }
      }
      counter += 1
    }
  }

  if (notifyRoutineCompleted) {
    yield put(actions.notifyAutomatedBoxQuantityRoutineCompleted(form))
  }
}

export function* handleNewlyAddedProductNotes(form, lineItems, response) {
  /* handle product notes modal(s) */
  if (response?.detail?.lineItems && response?.detail?.lineItems?.length) {
    const existingItemsCount =
      lineItems && Array.isArray(lineItems)
        ? lineItems.filter(x => x.dataId).length
        : 0

    const newlyAdded = response.detail.lineItems.slice(
      existingItemsCount,
      response.detail.lineItems.length
    )

    if (newlyAdded.length) {
      for (let i = 0; i < newlyAdded.length; i++) {
        const record = newlyAdded[i]
        if (record.productInternalNotes) {
          const notesResponse = {
            dataId: record.dataId,
            description: record.description,
            note: record.productInternalNotes,
            lineNumber: record.lineNumber
          }

          yield call(displayProductNotesHandler, form, notesResponse)
        }
      }
    }
  }

  /* dispatch a notification that the notes routine is done */
  yield put(actions.notifyAutomatedProductNotesRoutineCompleted(form))
}

export function* readLineItemDataProcess(
  form,
  rowIndex,
  gridApi = noop,
  ignoreAdditionalDataFlag = false,
  additionalDataType = '',
  childIndex
) {
  // debugger
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = getIn(formState, 'fields.dataId.value') || null
  const rowData = getIn(formState, 'fields.lineItems.rowData')
  const row = rowData.get(rowIndex)
  let lineNumber = getIn(row, 'lineNumber')
  if (childIndex) {
    lineNumber = rowIndex
  }
  const rowHasAdditionalData = getIn(row, 'hasAdditionalData')
  const selectedWarehousePanel = getIn(formState, 'ui.warehousePanel') || null

  if (rowHasAdditionalData || ignoreAdditionalDataFlag) {
    yield put(actions.readLineItem.request(form))
    /*
      openModalType was added for Return Authorization
      & Invoice History Modals
    */
    const returnDataTypes = [
      'returnauthorization',
      'returninvoiceauthorization',
      'invoicehistory',
      'invoicehistoryall'
    ]

    const apiParams = additionalDataType
      ? returnDataTypes.includes(additionalDataType)
        ? {
            dataId,
            lineNumber,
            guid,
            action: additionalDataType
          }
        : {
            dataId,
            lineNumber,
            guid,
            additionalData: additionalDataType
          }
      : {
          dataId,
          lineNumber,
          guid,
          additionalData: null,
          inventoryNames: guid
            ? getInventoryNames(
                getIn(formState, 'meta.inventoryNames'),
                selectedWarehousePanel
              )
            : getInventoryNames(getIn(formState, 'meta.inventoryNames'))
        }
    if (childIndex) {
      apiParams.childLineNumber = childIndex
      apiParams.additionalData = ['lineitemsubstitutes', 'lineiteminventory']
    }
    const { response, error } = yield call(api.readLineItem, apiParams)

    if (response) {
      if (
        !additionalDataType ||
        (additionalDataType &&
          Array.isArray(additionalDataType) &&
          (additionalDataType?.includes('components') ||
            additionalDataType?.includes('assemblies')))
      ) {
        if (childIndex) {
          response.childLineNumber = childIndex
          response.parentLineNumber = rowIndex
        }
        yield put(actions.readLineItem.success(response, form))

        if (
          additionalDataType &&
          Array.isArray(additionalDataType) &&
          additionalDataType?.includes('components') &&
          gridApi
        ) {
          const lineItems = rowData?.toJS ? rowData.toJS() : []
          const hasBlankRow = lineItems
            ?.find(x => x?.lineNumber === lineNumber)
            ?.components?.some(item => item.rowId === 'blankrow')

          if (hasBlankRow) {
            yield put(
              actions.addComponentsRow(form, {
                parentLineNumber: lineNumber,
                rowId: response.uniqueKey,
                gridApi
              })
            )
          }

          yield delay(100)
          gridApi.resetRowHeights()
        }
        // debugger
      } else {
        /*
          send this action with an empty response in order to
          remove the progress pending bar
        */
        yield put(actions.readLineItem.success({}, form))

        /*
          use the data to launch the modal but do not store this
          info in state, its completely extraneous and causes
          the grid to re-render -- SVE 1/13/20
        */
        if (
          additionalDataType === 'returnauthorization' ||
          additionalDataType === 'returninvoiceauthorization'
        ) {
          const returnData =
            additionalDataType === 'returninvoiceauthorization'
              ? {
                  lineNumber,
                  invoiceHistory: response.invoiceHistory,
                  returnAuthorization: {
                    rgaType: response.rgaType,
                    rgaOptions: response.rgaOptions,
                    applyRestockingCharge: response.applyRestockingCharge,
                    allowApplyRestockingCharge:
                      response.allowApplyRestockingCharge,
                    returnToShowroomDisplay: response.returnToShowroomDisplay,
                    allowReturnToShowroomDisplay:
                      response.allowReturnToShowroomDisplay,
                    enableReturnToShowroomDisplayForStock:
                      response.enableReturnToShowroomDisplayForStock
                  }
                }
              : {
                  lineNumber,
                  returnAuthorization: response
                }

          debugger
          yield put(
            actions.getReturnModalData(form, {
              rowIndex,
              gridApi: null,
              type: additionalDataType,
              isRequest: false,
              launchModal: true,
              data: returnData
            })
          )
        }

        if (
          additionalDataType === 'invoicehistoryall' ||
          additionalDataType === 'invoicehistory'
        ) {
          debugger
          yield put(
            actions.getReturnModalData(form, {
              rowIndex,
              gridApi: null,
              type: additionalDataType,
              isRequest: false,
              launchModal: additionalDataType === 'invoicehistory' || false,
              data: {
                lineNumber,
                invoiceHistory: response
              }
            })
          )
        }
      }

      /* 
        do not page through the line items if we are handling returns
      */
      if (
        gridApi &&
        gridApi.forEachNode &&
        !Number(childIndex) &&
        (!additionalDataType ||
          (additionalDataType && !returnDataTypes.includes(additionalDataType)))
      ) {
        setTimeout(() => {
          gridApi.deselectAll()
          gridApi.forEachNode(node => {
            if (
              node?.data?.lineNumber &&
              node?.data?.lineNumber === Number(lineNumber)
            ) {
              // debugger
              node.setSelected(true, true, true)
            }
          })
        }, 0)
      }
    } else {
      yield put(actions.readLineItem.failure(error, form))
    }
  }
}

export function* triggerReturnProductValidationRoutine(
  form,
  gridApi,
  isCustomerChange = false
) {
  const formState = yield select(getFormSelector(form))
  let lineItems = getIn(formState, 'fields.lineItems.rowData') || fromJS([])
  lineItems = lineItems?.toJS ? lineItems.toJS() : []

  if (lineItems && Array.isArray(lineItems) && lineItems?.length) {
    let counter = 0
    while (counter < lineItems.length) {
      const { quantityOrdered = 0, lineNumber } = lineItems?.[counter]
      const rowIndex = lineItems.findIndex(x => x?.lineNumber === lineNumber)

      if (!is.number(rowIndex)) {
        break
      }

      debugger
      if (quantityOrdered < 0) {
        if (isCustomerChange) {
          yield call(
            readLineItemDataProcess,
            form,
            rowIndex,
            gridApi,
            true,
            'invoicehistory'
          )

          yield take(CONSTANTS.UPDATE_GRID_ITEM.SUCCESS)
        } else {
          debugger
          // yield put(
          //   actions.getReturnModalData(form, {
          //     rowIndex: lineNumber - 1,
          //     gridApi,
          //     isRequest: true,
          //     launchModal: false,
          //     lineNumber,
          //     type: 'returninvoiceauthorization'
          //   })
          // )

          yield call(
            readLineItemDataProcess,
            form,
            rowIndex,
            gridApi,
            true,
            'returninvoiceauthorization'
          )

          yield take(CONSTANTS.RETURN_AUTH_MODAL_CLOSED)
          debugger
        }

        /*
            while this is the accurate workflow above,
            it causes API errors if the second modal isn't shown
          */
      }
      // }
      counter += 1
    }

    yield put(actions.notifyAutomatedReturnProcessCompleted(form))
  }
}

export function* cancelSalesOrderEditProcess(form, hasDataIdBeenSet = false) {
  /* see handleSalesOrderCancellation in searchAreaSagas for ussage of hasDataIdBeenSet -- SVE 6/29/2020 */
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = hasDataIdBeenSet
    ? getIn(formState, 'fields.dataId.prevValue')
    : getIn(formState, 'fields.dataId.value')
  const customerId = getIn(formState, 'fields.customerId.value') || null
  const isEditing = getIn(formState, 'isEditing') || false

  // const groupNames = getGroupNames(formState)
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = isMobile ? mobileGroupNames : getGroupNames(formState)
  const selectedWarehousePanel = getIn(formState, 'ui.warehousePanel') || null
  const hasPaymentsChanged =
    getIn(formState, 'values.hasPaymentsChanged') || false

  // debugger

  if (isEditing && !hasPaymentsChanged) {
    yield call(
      confirmationModal,
      'All changes will be lost. Continue?',
      'Cancel?'
    )
    const action = yield take([CONFIRMED, CANCELED])
    if (action.type === CANCELED) {
      return
    }
  }

  yield put(actions.cancelSalesOrderEdit.request(form))

  // debugger
  const { response, error } = yield call(api.cancelSalesOrderEdit, {
    dataId,
    customerId,
    guid,
    groupNames,
    inventoryNames: guid
      ? getInventoryNames(
          getIn(formState, 'meta.inventoryNames'),
          selectedWarehousePanel
        )
      : getInventoryNames(getIn(formState, 'meta.inventoryNames'))
  })

  if (response) {
    yield put(actions.cancelSalesOrderEdit.success(response, form))
  } else {
    yield put(actions.cancelSalesOrderEdit.failure(error, form))
  }
}

export function* cancelNewSalesOrderEditProcess(
  form,
  hasDataIdBeenSet = false
) {
  /* see handleSalesOrderCancellation in searchAreaSagas for ussage of hasDataIdBeenSet -- SVE 6/29/2020 */
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const dataId = hasDataIdBeenSet
    ? getIn(formState, 'fields.dataId.prevValue')
    : getIn(formState, 'fields.dataId.value') || null
  const customerId = getIn(formState, 'fields.customerId.value') || null
  // const groupNames = getGroupNames(formState)
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false
  const groupNames = isMobile ? mobileGroupNames : getGroupNames(formState)

  const hasPaymentsChanged =
    getIn(formState, 'values.hasPaymentsChanged') || false

  const apiParams = {
    dataId,
    guid,
    customerId,
    groupNames
  }

  if (!hasPaymentsChanged) {
    yield call(
      confirmationModal,
      'All changes will be lost.  Continue?',
      'Cancel?'
    )

    const { type } = yield take([CONFIRMED, CANCELED])

    if (type === CANCELED) return
  }

  yield fork(callCancelSalesOrderApiProcess, form, apiParams)
}

export function* callCancelSalesOrderApiProcess(form, apiParams) {
  yield put(actions.cancelNewSalesOrderEdit.request(form))

  const { response, error } = yield call(api.cancelSalesOrderEdit, apiParams)

  if (response) {
    yield put(resetMasterFields(form))
    yield put(actions.cancelNewSalesOrderEdit.success(response, form))
  } else {
    yield put(actions.cancelNewSalesOrderEdit.failure(error, form))
  }

  return null
}

export function* handleRepairItemModalProcess(form, lineNumber, data = {}) {
  const formState = yield select(getFormSelector(form))
  const isEditing = getIn(formState, 'isEditing') || false
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  // debugger

  const modalActionButtons = [
    {
      primary: true,
      title: 'OK',
      async clickEvent(args, fs, cb) {
        const {
          comments,
          repair,
          printRepair,
          repairSerialNumber,
          repairProduct,
          repairManufacturer,
          repairModel
        } = this.state

        // debugger
        this.props.dispatch(
          actions.saveRepairItemData.try(form, {
            data: {
              comments,
              printRepair,
              repair,
              repairSerialNumber,
              repairProduct,
              repairManufacturer,
              repairModel
            },
            lineNumber,
            cb
          })
        )
      },
      disabled: fs => fs.isPosting || false
    },
    {
      primary: true,
      title: 'Exit',
      disabled: fs => fs.isPosting || false
    }
  ]

  if (!isEditing) {
    modalActionButtons.shift()
  }

  const repairInfo = data.repairInfo ? data.repairInfo : {}

  const modalOpts = {
    component: RepairItemModal,
    options: {
      width: 600,
      maxHeight: 800,
      marginTop: isMobile ? 25 : null,
      title: 'Repair Item',
      data: {
        lineNumber,
        isEditing,
        form,
        productId: data.dataId || '',
        uomId: data.uomId || '',
        isMobile,
        repairInfo: {
          ...repairInfo,
          repair: data.repair || false,
          comments:
            data.comments &&
            Array.isArray(data.comments) &&
            data.comments.length
              ? data.comments.reduce((acc, next) => {
                  if (next.dataId === 'External Comment') {
                    acc = acc.concat(next.text)
                  }
                  return acc
                }, '')
              : ''
        },
        actions: modalActionButtons
      }
    }
  }

  const modal = yield call(addModal, form, modalOpts)
  yield put(modal)

  return modal.payload.id
}

export function* removeGridItemProcess(
  form,
  gridName,
  lineNumber = 0,
  key = 'delete',
  groupNames = ['shipments'],
  isEditableGrid = false
) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')

  yield put(actions.removeGridItem.request(form))

  const { response, error } = yield call(api.changeGridItem, {
    guid,
    lineNumber,
    gridName,
    groupNames,
    properties: {
      [key]: ''
    }
  })

  if (response) {
    const { record, ...other } = response
    yield put(
      actions.removeGridItem.success(
        {
          ...other,
          ...record
        },
        form
      )
    )
    if (isEditableGrid) {
      yield put(validateGridData(form, { propertyName: gridName }))
    }
  } else {
    yield put(actions.removeGridItem.failure(error, form))
  }
}

export function* handleAdditionalProductRoutines(
  form,
  lineItems,
  data,
  gridApi,
  shipToId = '' /* there is not always a shipToId in this routine */
) {
  yield delay(1000)
  /* handle product notes modal(s) */
  yield fork(handleNewlyAddedProductNotes, form, lineItems, data)

  /* listen and wait for completion of the product notes loop */
  yield take(CONSTANTS.NOTIFY_AUTOMATED_PRODUCT_NOTES_ROUTINE_COMPLETED)

  if (gridApi) {
    yield delay(1000)
    yield fork(triggerBoxQuantityPrompts, form, gridApi, true)

    /* wait for the Box Quanity routine to complete */
    yield take(CONSTANTS.NOTIFY_AUTOMATED_BOX_QUANTITY_ROUTINE_COMPLETED)

    /* handle any return products */
    yield fork(triggerReturnProductValidationRoutine, form, gridApi)

    /* wait for the return process to be completed */
    yield take(CONSTANTS.NOTIFY_AUTOMATED_RETURN_PROCESS_COMPLETED)

    /* that should end the modals, set the shipTo if there is one */
    if (shipToId) {
      yield put(setField(form, 'shipToId', shipToId))
    }

    gridApi.forEachNode(node => {
      if (node.data.lineNumber === 1) {
        debugger
        node.setSelected(true)
      }
    })
  } else if (shipToId) {
    yield put(setField(form, 'shipToId', shipToId))
  }
}

export function* cancelEditAfterClear(form) {
  const formState = yield select(getFormSelector(form))
  const guid = getIn(formState, 'guid')
  const isMobile = yield select(state => getIn(state, 'mobile.isMobile')) ||
    false

  yield put(updateFormTitle(form, 'Sales Order'))
  yield put(actions.cancelEditAfterClear.request(form))

  const { response, error } = yield call(api.cancelEditAfterClear, { guid })
  if (response) {
    yield put(actions.cancelEditAfterClear.success(response, form))
    if (!isMobile) {
      yield put(tryChangeFormTab(form, 'order'))
    }
  } else {
    yield put(actions.cancelEditAfterClear.failure(error, form))
  }
}
