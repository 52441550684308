import React from 'react'
import { IconButton, Icon } from '@material-ui/core'
import { removeGridItem } from 'pages/SalesOrder/actions'
import { is } from 'utils'

const DeleteGridRowCell = props => {
  console.log('deleteGridRowCell', props)
  const dispatch = props?.column?.colDef?.cellRendererParams?.dispatch

  const tryDeleteRow = () => {
    const { form, rowIndex } = props

    if (form && dispatch && is.number(rowIndex)) {
      dispatch(
        removeGridItem.try(form, {
          gridName: 'miscellaneousCharges',
          lineNumber: rowIndex + 1,
          key: 'delete',
          groupNames: ['final'],
          isEditableGrid: true
        })
      )
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      <IconButton onClick={tryDeleteRow}>
        <Icon style={{ color: '#d9534f', fontSize: 16 }}>cancel</Icon>
      </IconButton>
    </div>
  )
}

export default DeleteGridRowCell
