/* eslint react/sort-comp: 0 */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Field, GridField, withContext } from 'ddiForm'
import { Grid } from 'grid'
import memoize from 'memoize-one'
import {
  dateFilterComparator,
  formatDateFields,
  generateTabs,
  // plainDeepEqual,
  getValue,
  getField,
  empty,
  emptyList,
  getIn,
  fromJS
} from 'utils'
import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'

import {
  getRowNodeId,
  headerStyle,
  dateFilterGridConfigs,
  numberFilterGridConfigs
} from 'pages/ProductMaster/utils'
import {
  launchAnalysisBinsEditor,
  lockInventoryBinAssignment
} from 'pages/ProductMaster/actions'
import { AppBar, Tabs, Typography } from '@material-ui/core'
import withDimensions from 'hoc/withDimensions'

import '../../styles/master-style.scss'

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>
}

export const getAuditRowNodeId = data => {
  const { changedById, changedDate, refDesc } = data
  return `${changedById}-${changedDate}-${refDesc}`
}

const auditColDefs = [
  {
    field: 'changedBy.value',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'User',
    tooltipField: 'changedBy.tooltip'
  },
  {
    field: 'changedDate',
    filter: 'agDateColumnFilter',
    filterParams: {
      clearButton: true,
      comparator: dateFilterComparator,
      inRangeInclusive: true,
      suppressAndOrCondition: true,
      browserDatePicker: true
    },
    headerName: 'Changed',
    cellClass: 'text-center align-center',
    valueFormatter: params => formatDateFields(params, 'M/D/YY hh:mm A')
  },
  {
    field: 'reference',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'Reference'
  },
  {
    field: 'refDesc',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'Description'
  },
  {
    field: 'field',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'Field'
  },
  {
    field: 'changedFrom',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'From'
  },
  {
    field: 'changedTo',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'To'
  },
  {
    field: 'changeType',
    filter: 'agTextColumnFilter',
    filterParams: {
      clearButton: true,
      suppressAndOrCondition: true
    },
    headerName: 'Type'
  }
]

export class AnalysisBins extends Component {
  static propTypes = {
    canUpdateBins: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isWarehouseMaintainingBinQuantity: PropTypes.bool.isRequired,
    selectedWarehouseId: PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { audit, auditBinShowAll } = nextProps
    // audit = audit && audit?.toJS ? audit.toJS() : []
    if (!nextProps.hasRecord) {
      return { showFlag: false }
    }

    if (nextProps.additionalDataAvailable && !prevState.showFlag) {
      return {
        showFlag: nextProps.additionalDataAvailable
      }
    }

    if (
      nextProps.additionalDataAvailable &&
      audit.size <= 500 &&
      !prevState.showFlag
    ) {
      return {
        showFlag: nextProps.additionalDataAvailable
      }
    }

    if (!nextProps.additionalDataAvailable && audit?.size >= 500) {
      return {
        showFlag: true
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      showFlag: this.props.additionalDataAvailable,
      tab: 'Bin'
    }
  }

  handleTabChange = (event, value) => {
    if (typeof value === 'string') this.setState({ tab: value })
  }

  addBlankRow = () => {
    const { form } = this.props
    if (form) {
      this.props.dispatch(launchAnalysisBinsEditor(form))
    }
  }

  getColumnDefs = memoize(colDefs => {
    let defaultColDefs = [
      {
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          isDisabled: true
        },
        field: 'isPrimary',
        headerName: 'Primary'
      },
      { field: 'dataId', headerName: 'Bin Location' },
      {
        field: 'quantity',
        headerName: 'Quantity',
        cellClass: 'align-right',
        ...numberFilterGridConfigs
      },
      {
        field: 'date',
        headerClass: 'text-center',
        headerName: 'Date',
        cellClass: 'text-center',
        valueFormatter: params => formatDateFields(params),
        ...dateFilterGridConfigs
      }
    ]

    if (colDefs && colDefs.size) {
      defaultColDefs = colDefs.reduce((acc, next) => {
        if (next.get('propertyName') === 'isPrimary') {
          acc = acc.concat({
            ...next.toJS(),
            cellRendererFramework: ToggleCheckboxCell,
            cellRendererParams: {
              isDisabled: true
            },
            field: next.get('propertyName'),
            headerName: next.get('columnHeader')
          })
        } else if (next.propertyName === 'date') {
          acc = acc.concat({
            ...next.toJS(),
            field: next.get('propertyName'),
            headerName: next.get('columnHeader'),
            cellClass: 'text-center',
            valueFormatter: params => formatDateFields(params)
          })
        } else if (next.propertyName === 'quantity') {
          acc = acc.concat({
            ...next.toJS(),
            cellClass: 'align-right',
            field: next.get('propertyName'),
            headerName: next.get('columnHeader')
          })
        } else {
          acc = acc.concat({
            ...next.toJS(),
            field: next.get('propertyName'),
            headerName: next.get('columnHeader')
          })
        }
        return acc
      }, [])
    }

    return defaultColDefs
  })

  getContextMenuItems = params => {
    const form = 'productMaster'
    const { defaultItems = [] } = params
    const { isEditing, isWarehouseMaintainingBinQuantity } = this.props

    if (isWarehouseMaintainingBinQuantity && !isEditing) {
      return [
        {
          name: 'Bin Assignment',
          action: () =>
            this.props.dispatch(lockInventoryBinAssignment.try(form))
        },
        ...defaultItems
      ]
    }

    return [...defaultItems]
  }

  render() {
    const {
      auditBinShowAll,
      colDefs,
      isEditing,
      hasRecord,
      isWarehouseMaintainingBinQuantity,
      audit,
      canUpdateBins,
      height,
      selectedWarehouseId
    } = this.props

    const { showFlag, tab } = this.state
    // debugger
    // console.log('BINS', canUpdateBins, this.props)

    /* show an empty grid until we have columnDefs here */
    const bins =
      colDefs && colDefs.size ? (
        <GridField
          addButtonText="Update Bins"
          addBlankRow={this.addBlankRow}
          showAddButtonAlways={canUpdateBins && !isEditing}
          propertyName={`analysisBins.${selectedWarehouseId}`}
          title="Bins"
          getRowNodeId={getRowNodeId}
          headerStyle={headerStyle}
          columnDefs={this.getColumnDefs(colDefs.toJS())}
          height={height - 50}
          rowSelection="single"
          fullWidth
          getContextMenuItems={this.getContextMenuItems}
          suppressPropertyNamesCheck
        />
      ) : (
        <Grid
          title="Bins"
          headerStyle={headerStyle}
          columnDefs={[]}
          rowData={[]}
          getRowNodeId={getRowNodeId}
          height={height - 50}
          rowSelection="single"
          fullWidth
          suppressPropertyNamesCheck
        />
      )

    const analysisAuditRowData = audit && audit?.toJS() ? audit.toJS() : []

    return (
      <div style={{ padding: '0 20px', width: '100%' }}>
        {audit && audit.size && isWarehouseMaintainingBinQuantity ? (
          <>
            <AppBar position="static" style={{ boxShadow: 'none' }}>
              <Tabs value={tab} onChange={this.handleTabChange} scrollable>
                {generateTabs(['Bin', 'Audit'])}
              </Tabs>
            </AppBar>
            <TabContainer>
              <div className="layout-flex-centered">
                {tab === 'Bin' && <div className="flex-wrapper-50">{bins}</div>}
                {tab === 'Audit' && (
                  <div style={{ width: '100%' }}>
                    {hasRecord && showFlag ? (
                      <Field
                        preventAutoDisable
                        propertyName="auditBinShowAll"
                        label="Show All Audits"
                        component={Checkbox}
                        wrapperStyle={{ marginLeft: 15 }}
                      />
                    ) : null}
                    <div style={{ width: '100%' }}>
                      <Grid
                        propertyName="audit"
                        title="Audit"
                        getRowNodeId={data => data.rowId}
                        rowData={analysisAuditRowData}
                        headerStyle={headerStyle}
                        columnDefs={auditColDefs}
                        // gridHeight={height - 78}
                        height={showFlag ? height - 100 : height - 50}
                        rowSelection="single"
                        fullWidth
                      />
                    </div>
                  </div>
                )}
              </div>
            </TabContainer>
          </>
        ) : (
          <div className="layout-flex-centered">
            <div className="flex-wrapper-50">{bins}</div>
          </div>
        )}
      </div>
    )
  }
}

const getWarehouseId = form => getField(form, 'selectedWarehouseId', '')

const getBinsData = (form, warehouseId) => {
  const f = `fields.analysisBins.${warehouseId}`
  // const ret = getField(form, f, empty)
  const ret = getIn(form, f) || empty
  return ret
}
// getField(form, `analysisBins.${warehouseId}`, empty)

export const contextObj = {
  auditBinShowAll: form => getIn(form, 'fields.auditBinShowAll.value') || false,
  additionalDataAvailable: form =>
    getIn(form, 'values.productBins.additionalDataAvailable') || false,
  isWarehouseMaintainingBinQuantity: form =>
    getValue(form, 'isWarehouseMaintainingBinQuantity', false),

  isEditing: form => form.get('isEditing') || false,
  colDefs: form => {
    const warehouseId = getWarehouseId(form)
    const binsData = getBinsData(form, warehouseId)
    const colDefs = binsData.get('colDefs') || emptyList
    return colDefs
  },
  // audit: form => getField(form, 'audit', emptyList, true),
  audit: form => getIn(form, 'values.audit') || fromJS([]),
  canUpdateBins: form => {
    const warehouseId = getWarehouseId(form)
    const binsData = getBinsData(form, warehouseId)
    const canUpdateBins = !!binsData.get('canUpdateBins')

    return canUpdateBins
  },
  fetchingEntity: form => form.get('fetchingEntity'),

  selectedWarehouseId: form => getWarehouseId(form),
  hasRecord: form => form.get('hasRecord') || false
}

const analysisBinsWithContext = withContext(contextObj)(AnalysisBins)

export default withDimensions({ display: 'flex', flex: 1, maxWidth: '100%' })(
  analysisBinsWithContext
)
