import { setIn } from 'utils'
import { fromJS } from 'immutable'
import { compose } from 'redux'
import { withToggleFetch, id } from 'ddiForm/reducerEnhancers'
import * as CONSTANTS from './constants'

const activityBehaviors = {
  [CONSTANTS.LIST_ACTIVITIES.REQUEST]: state =>
    compose(
      withToggleFetch(true)
    )(state),
  [CONSTANTS.LIST_ACTIVITIES.SUCCESS]: compose(
    withToggleFetch(false),
    (state, { payload: { activities, description } }) => {
      let result = state

      // const { activities, description } = payload

      result = setIn(result, 'fields.activities.rowData', fromJS(activities))
      // result = setIn(result, 'fields.activities.value', fromJS(activities))
      result = setIn(result, 'values.activities', fromJS(activities))
      result = setIn(result, 'fields.activities.grid', true)

      result = setIn(result, 'values.description', description)

      return result
    }
  )
}

export default activityBehaviors
