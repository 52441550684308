import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// import memoize from 'memoize-one'
import { Label, withContext } from 'ddiForm'
import { DDIIndexSearch } from 'ddiForm/wrapped'

import ToggleCheckboxCell from 'components/EditableGrid/components/ToggleCheckboxCell'
import {
  getFieldOrValue,
  headerStyle,
  setGridHeight
} from 'pages/ProductMaster/utils'
// import { addBlankRow } from 'ddiForm/actions'

import EditableGrid from 'components/EditableGrid'
import DeleteGridRowCell from 'components/EditableGrid/components/DeleteGridRowCell'
import BinTooltipSearchCell from 'pages/ProductMaster/tabs/Setup/components/grids/BinTooltipSearchCell'
import { required } from 'ddiForm/validations'
import {
  saveInventoryBinAssignment as saveInventoryBinAssignmentAction,
  unlockInventoryBinAssignment as unlockInventoryBinAssignmentAction
} from 'pages/ProductMaster/actions'
import { getIn } from 'utils'

// export const getRowNodeId = data => data.dataId
// export const getRowNodeId = data => {
//   const { dataId, description, sortIndex } = data
//   return `${dataId}-${description}-${sortIndex}`
// }

const mapStateToProps = (state, ownProps) => {
  const { data: { form } } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const rowData = getIn(formState, 'values.binEditorModal.binAssignments')

  return {
    recordLocked: getIn(formState, 'values.binEditorModal.locked') || false,
    description: getIn(formState, 'fields.description.value') || getIn(formState, 'values.description') || '',
    productDescription: getIn(formState, 'fields.binEditorModal.productDescription.value'),
    productId: getIn(formState, 'fields.dataId.value') || '',
    rowData: rowData && rowData.toJS ? rowData.toJS() : [],
    warehouseId: getIn(formState, 'fields.selectedWarehouseId.value') || '',
    warehouseDescription: getIn(formState, 'fields.binEditorModal.warehouseDescription.value') || '',
    uom: getIn(formState, 'fields.selectedUOMId.value')
  }

}

const mapDispatchToProps = dispatch => ({
  saveInventoryBinAssignment: (form, { cb }) => dispatch(saveInventoryBinAssignmentAction.try(form, { cb })),
  unlockInventoryBinAssignment: (form, { cb, closeModal }) => dispatch(unlockInventoryBinAssignmentAction.try(form, { cb, closeModal }))
})

export class BinEditorModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    // isWarehouseMaintainingBinQuantity: PropTypes.bool.isRequired,
    productId: PropTypes.string.isRequired,
    rowData: PropTypes.object.isRequired,
    uom: PropTypes.string.isRequired,
    warehouseId: PropTypes.string.isRequired
  }

  getColumnDefs = props => {
    // console.log('getColumnDefs', props, this.props)
    const params = {
      disabled: !props.isEditing,
      form: props.form,
      onChange: props.onCellChange,
      propertyName: props.propertyName
    }

    const {
      // isEditing,
      productId,
      uom,
      warehouseId
    } = this.props

    const colDef = [
      {
        field: 'fromBinId',
        headerName: 'Bin',
        cellRendererFramework: BinTooltipSearchCell,
        cellRendererParams: {
          ...params,
          productId,
          warehouseId,
          uom
        }
      },
      {
        field: 'fromBinDescription',
        headerName: 'Description'
      },
      {
        // cellRendererFramework: CheckboxCell,
        cellRendererFramework: ToggleCheckboxCell,
        cellRendererParams: {
          ...params,
          tooltip: 'toolTipIsFromBinPrimary',
          disabledFn: data => !data.canActivateIsFromBinPrimary
        },
        field: 'isFromBinPrimary',
        headerName: 'Is Primary',
        width: 80,
        minWidth: 80
      },
      {
        cellRendererFramework: DeleteGridRowCell,
        cellRendererParams: {
          ...params,
          tooltip: 'toolTipDelete',
          disabledFn: data => !data.canActivateDelete
        },
        headerName: '',
        maxWidth: 50,
        width: 50
      }
    ]

    return colDef
  }

  render() {
    const {
      description,
      productDescription,
      warehouseDescription,
      rowData,
      productId,
      warehouseId,
      isEditing,
      recordLocked
    } = this.props

    console.log('binEditorModal', this.props)
    const form = 'productMaster'

    return (
      <div style={{ width: '100%' }}>
        <div
          style={{ alignItems: 'center', display: 'flex', marginBottom: 15 }}
        >
          <label style={{ color: '#444', margin: '0 10px 0 0', minWidth: 75 }}>
            Warehouse
          </label>
          <div style={{ maxWidth: 200, marginRight: 10, width: 200 }}>
            <DDIIndexSearch
              propertyName="binEditorModal.warehouseId"
              fullWidth
              metaKey="selectedWarehouseId"
              preventAutoDisable
              disabled={recordLocked}
              validate={[required]}
              indexSearchType="warehouse"
              style={{ width: '100%' }}
              meta={{
                allowInstantSearch: true,
                allowNavigation: false,
                allowSearchAll: true,
                hasFilters: false,
                hideToggle: true,
                minimumKeywordLength: 0,
                searchType: 'Warehouse'
              }}
            />
          </div>
          <Label
            propertyName="binEditorModal.warehouseDescription"
            labelStyle={{ width: '100%' }}
            value={warehouseDescription}
          />
        </div>
        <div
          style={{ alignItems: 'center', display: 'flex', marginBottom: 15 }}
        >
          <label style={{ color: '#444', margin: '0 10px 0 0', minWidth: 75 }}>
            Product
          </label>
          <div style={{ maxWidth: 200, marginRight: 10, width: 200 }}>
            <DDIIndexSearch
              propertyName="binEditorModal.productId"
              disableLastSearchButton
              preventAutoDisable
              disabled={recordLocked}
              validate={[required]}
              popoverStyle={{
                width: 500
              }}
              fullWidth
              style={{ width: '100%' }}
              meta={{
                allowInstantSearch: true,
                allowNavigation: true,
                allowSearchAll: false,
                hasFilters: true,
                hideToggle: false,
                minimumKeywordLength: 2,
                searchType: 'Product'
              }}
            />
          </div>
          <Label
            propertyName="binEditorModal.productDescription"
            labelStyle={{ width: '100%' }}
            value={productDescription}
          />
        </div>
        <div style={{ paddingBottom: 35, width: '100%' }}>
          <EditableGrid
            showAddButtonAlways={false}
            form={form}
            propertyName="binEditorModal.binAssignments"
            title="Bins"
            getRowNodeId={data => data.fromBinId}
            headerStyle={headerStyle}
            columnDefs={this.getColumnDefs}
            requiredCols={['fromBinId']}
            isEditing
            height={setGridHeight(rowData, 66)}
            // rowSelection="single"
            width="100%"
            fullWidth
            gridWrapperStyle={{ flex: '1 1', maxWidth: '100%', width: '100%' }}
          />
        </div>
      </div>
    )
  }
}

// export const contextObj = {
//   recordLocked: formState =>
//     formState.values && formState.values.binEditorModal.locked
//       ? formState.values.binEditorModal.locked
//       : false,
//   description: formState => getFieldOrValue(formState, 'description'),
//   productDescription: form =>
//     form.fields &&
//     form.fields.binEditorModal &&
//     form.fields.binEditorModal.productDescription &&
//     form.fields.binEditorModal.productDescription.value
//       ? form.fields.binEditorModal.productDescription.value
//       : '',
//   productId: form => form.fields.dataId.value,
//   rowData: form =>
//     form.values &&
//     form.values.binEditorModal &&
//     form.values.binEditorModal.binAssignments
//       ? form.values.binEditorModal.binAssignments
//       : [],
//   warehouseId: form => form.fields.selectedWarehouseId.value,
//   warehouseDescription: form =>
//     form.fields &&
//     form.fields.binEditorModal &&
//     form.fields.binEditorModal.warehouseDescription &&
//     form.fields.binEditorModal.warehouseDescription.value
//       ? form.fields.binEditorModal.warehouseDescription.value
//       : '',
//   uom: form => form.fields.selectedUOMId.value
// }

// export default withContext(contextObj)(BinEditorModal)
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(BinEditorModal)

