/* eslint react/sort-comp: 0, react/no-find-dom-node: 0, consistent-return: 0 */
import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import { connect } from 'react-redux'
import { getIn, is, noop, plainDeepEqual, toCamelCase } from 'utils'
import IndexSearch from 'components/Search/IndexSearch'
import * as actions from 'components/Search/IndexSearch/actions'
import shortid from 'shortid'
import { getErrorIcon, updateGridCellData } from 'components/EditableGrid/utils'
import {
  resetGridIndexSearch,
  updateGridCellData as updateGridCellDataAction
} from 'components/EditableGrid/actions'
import { openScreen as openScreenAction } from 'pages/Main/actions'
import behaviors from 'components/Search/IndexSearch/behaviors'
import { debounce } from 'lodash'
import { addBlankRow } from 'ddiForm/actions'
import { addBulkLineItems } from '../../../actions'

const KEY_UP = 38
const KEY_DOWN = 40
const KEY_LEFT = 37
const KEY_RIGHT = 39
const ENTER = 13
const HOME = 36
const END = 35

export class IndexSearchCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    exactMatchSearch: PropTypes.func,
    focusedCell: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
    indexSearchType: PropTypes.string,
    indexSearchResultsGrid: PropTypes.object.isRequired,
    indexSearchResultsValue: PropTypes.string.isRequired,
    isSet: PropTypes.bool.isRequired,
    openScreenParams: PropTypes.object,
    meta: PropTypes.object,
    partialMatchSearch: PropTypes.func,
    propertyName: PropTypes.string.isRequired,
    rowData: PropTypes.array.isRequired
  }

  static defaultProps = {
    exactMatchSearch: noop,
    indexSearchType: 'product',
    meta: {
      allowInstantSearch: true,
      minimumKeywordLength: 2,
      allowSearchAll: false
    },
    partialMatchSearch: noop
  }

  constructor(props) {
    super(props)
    // console.log('IndexSearchCell', props)

    this.state = {
      isEditing: false,
      value: this.props.value,
      rowIndex: this.props.rowIndex,
      textFieldVal: this.props.value || ''
    }
    const param = props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { disabledFn, requiredFn, tooltip, getErrorMessageFn }
      }
    } = props

    if (disabledFn && typeof disabledFn === 'function') {
      this.props.colDef[param].disabledFn = this.props.colDef[
        param
      ].disabledFn.bind(this)
    }

    if (requiredFn && typeof requiredFn === 'function') {
      this.props.colDef[param].requiredFn = this.props.colDef[
        param
      ].requiredFn.bind(this)
    }

    if (getErrorMessageFn && typeof getErrorMessageFn === 'function') {
      this.props.colDef[param].getErrorMessageFn = this.props.colDef[
        param
      ].getErrorMessageFn.bind(this)
    }

    // this.updateGridCellData = updateGridCellData.bind(this)
  }

  componentDidUpdate(prevProps) {
    console.log('index search updated', prevProps, this)
  }

  componentWillUnmount() {
    //
    if (this.input) {
      this.input.removeEventListener('keydown', this.onKeyDown)
      this.input.removeEventListener('paste', this.onPasteData)
    }
  }

  getValue = () => {
    return this.state.value
  }

  setField = (val, _, __, result, advancedParsingArguments) => {
    if (this.props.setField) {
      debugger
      this.props.setField(
        val,
        _,
        __,
        result,
        advancedParsingArguments,
        this.props.api,
        this.props.data
      )
      return
    }
    //
    const param = this.props.colDef.cellEditorParams
      ? 'cellEditorParams'
      : 'cellRendererParams'
    const {
      colDef: {
        [param]: { onChange },
        field,
        generateDataId
      }
    } = this.props

    this.setState(
      {
        value: val,
        textFieldVal: result ? result.name || result.recordName : val
      },
      async () => {
        // before i can do this i need to check for duplicates. so we need async
        //
        try {
          await this.props.dispatch({
            type: 'TRY_CELL_CHANGED_REQUEST',
            meta: { form: this.props.form, thunk: true },
            payload: {
              value: val,
              propertyName: this.props.propertyName,
              field: this.props.column.colId,
              advancedParsingArguments
            }
          })
          //
          if (this.props.api) this.props.api.stopEditing()
        } catch (e) {
          if (this.props.api) this.props.api.stopEditing(true)
        }
      }
    )
  }

  exactMatch = args =>
    this.props.dispatch(actions.exactMatchSearch(null, { ...args }))

  partialMatch = args =>
    this.props.dispatch(actions.partialMatchSearch(null, { ...args }))

  inputRef = c => (this.field = c)

  onChange = event => {
    this.setState({ isEditing: true, textFieldVal: event.target.value })
  }

  /* for editing and validating text field input only */
  // onBlur = event =>
  //   this.props.onChange(this.state.rowIndex, {
  //     recordName: this.state.textFieldVal
  //   })

  isInputDisabled = () => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      data,
      colDef: {
        [param]: { disabled, disabledFn }
      }
    } = this.props

    if (disabledFn && typeof disabledFn === 'function') {
      return disabledFn(data)
    }

    /* mainly for text fields like the 'State' field in Selection Critieria modal */
    if (this.props.indexSearchType === 'None' && this.props.value) {
      return true
    }

    if (disabled === false || !this.props.colDef[param].disabled) {
      return false
    }

    return true
  }

  afterGuiAttached() {
    const input = this.field
    if (input) {
      input.addEventListener('keydown', this.onKeyDown)
      input.addEventListener('paste', this.onPasteData)
    }
    setTimeout(() => {
      if (input) {
        input.focus()
        input.select()
      }
    }, 1)
  }

  wrappedKeyDown = event => {
    const { keyCode } = event
    if (keyCode === 13) {
      event.stopPropagation()
    }
  }

  onPasteData = async event => {
    const pastedData = event.clipboardData
      .getData('text')
      .toString()
      .replace(/(\r\n|\n|\r)/gm, '\n')
      .trim()

    const lines = pastedData?.split('\n')
    const delimiter = pastedData?.match(/\t/) ? '\t' : ','

    const sanitizeString = x =>
      x
        ?.replace('$', '')
        ?.replace(/,\s*$/, '')
        ?.split(delimiter)

    const validFormatting =
      lines &&
      Array.isArray(lines) &&
      lines?.length &&
      lines?.every(x => sanitizeString(x)?.length >= 2)

    if (validFormatting) {
      event.preventDefault()
      event.stopPropagation()

      const bulkProperties = lines.reduce((acc, next) => {
        const data = sanitizeString(next)
        if (data && data.length >= 2 && !Number.isNaN(Number(data[0]))) {
          const orderData =
            data?.[2] && !Number.isNaN(Number(data[2]))
              ? {
                  dataId: data[1],
                  quantityOrdered: Number(data[0]),
                  netPrice: Number(data[2])
                }
              : {
                  dataId: data[1],
                  quantityOrdered: Number(data[0])
                }
          acc = acc.concat(orderData)
        }

        return acc
      }, [])

      if (bulkProperties?.length) {
        this.setState({ textFieldVal: 'PROCESSING...' })
        let result
        try {
          if (this?.props?.api?.deselectAll) {
            setTimeout(() => {
              this.props.api.deselectAll()
            }, 0)
          }
          result = await this.props.dispatch(
            addBulkLineItems(this.props.form, {
              bulkProperties,
              gridApi: this.props.api,
              action: 'lineitempastefromsheet'
            })
          )
        } catch (err) {
          console.log(err)
          // debugger
        } finally {
          console.log('done', result)
          if (
            result?.message &&
            (result?.status === 400 || result?.status === 404)
          ) {
            this.setState({ textFieldVal: '' })
          }
        }
      }
    } else {
      await this.onChange(event)
    }
  }

  onKeyDown = event => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { linkTo, form, additionalOpenScreenParams = [] }
      }
    } = this.props
    const { keyCode } = event
    let node
    const self = this
    // if (event.persist) event.persist()
    if (this.isLeftOrRight(event)) {
      event.stopPropagation()
      return false
    }

    if (event.key === 'Tab' && !event.target.value) {
      console.log(this)
      event.preventDefault()
      event.stopPropagation()
      if (event.shiftKey) {
        const { api } = this.props
        if (api) {
          api.tabToPreviousCell()
        }
      }
    }

    const gridAvail = !!(
      this.indexSearchCell &&
      this.indexSearchCell.wrappedComponent &&
      this.indexSearchCell.wrappedComponent.state &&
      this.indexSearchCell.wrappedComponent.state.isOpen &&
      this.indexSearchCell.wrappedComponent.state.dropDownProps
    )
    if (
      event.key === 'Tab' &&
      this.props.indexSearchType &&
      event.target.value &&
      this.props.indexSearchType !== 'None'
    ) {
      /*
        we need to handle tabs on our own now,
        with this new index search
      */
      //
      let { value } = event.target
      let advancedParsingArguments
      event.preventDefault()
      event.stopPropagation()

      this.setState({ isEditing: false }, async () => {
        try {
          if (
            this.indexSearchCell &&
            this.indexSearchCell.wrappedComponent &&
            this.indexSearchCell.wrappedComponent.advancedParsingArguments
          ) {
            advancedParsingArguments = this.indexSearchCell.wrappedComponent
              .advancedParsingArguments
            //
            ;[, value] = advancedParsingArguments
            if (this.indexSearchCell.wrappedComponent.props.node) {
              this.indexSearchCell.wrappedComponent.props.node.advancedParsingArguments = advancedParsingArguments
            }
          }

          const p = await this.props.dispatch(
            actions.exactMatchSearch(form, {
              parentType: 'customer',
              parentId:
                this?.props?.column?.colDef?.cellEditorParams?.customerId ||
                this.props.customerId,
              allowSpecialProducts: true,
              searchType: this.props.indexSearchType,
              indexSearchType: this.props.indexSearchType,
              keyword: value,
              propertyName: `${this.props.propertyName}.editableGridIndexSearchField`,
              allowInvalidValues:
                this.props.meta && this.props.meta.allowInvalidValues
                  ? this.props.meta.allowInvalidValues
                  : false
            })
          )
          // debugger
          if (p.exactMatchResults) {
            //
            const val =
              p.exactMatchResults.name || p.exactMatchResults.recordName
            this.setField(
              val,
              null,
              null,
              p.exactMatchResults,
              advancedParsingArguments
            )
          }
        } catch (e) {
          console.log(e, event.target.value)
        }
      })
    }
    //
    const api =
      this.indexSearchCell &&
      this.indexSearchCell.wrappedComponent &&
      this.indexSearchCell.wrappedComponent.api
    if (keyCode === KEY_DOWN && gridAvail && api) {
      event.preventDefault()
      event.stopPropagation()
      api.ensureIndexVisible(0)
      const rowCount = api.getDisplayedRowCount()
      if (rowCount > 1) {
        const firstCol = api.columnController.getAllDisplayedColumns()[0]

        node = api.getDisplayedRowAtIndex(1)
        if (node) {
          if (!node.isSelected()) node.setSelected(true)
          api.ensureColumnVisible(firstCol)

          // sets focus into the first grid cell
          api.setFocusedCell(1, firstCol)
          api.ensureIndexVisible(0)
        }
      }
      // // might need to change this when we do saveCart feature
    } else if (keyCode === ENTER && gridAvail && api) {
      event.preventDefault()
      event.stopPropagation()
      console.log(self)
      node = api.getDisplayedRowAtIndex(0)
      if (
        self &&
        self.indexSearchCell &&
        self.indexSearchCell.wrappedComponent
      ) {
        self.indexSearchCell.wrappedComponent.onRowClicked({
          node,
          api,
          columnApi: api.columnController,
          data: node.data,
          rowIndex: node.rowIndex
        })
      }
      // this.onRowClicked({
      //   node,
      //   api: this.props.api,
      //   columnApi: this.props.api.columnController,
      //   data: node.data,
      //   rowIndex: node.rowIndex
      // })
    }
  }

  isLeftOrRight = event => [37, 39].indexOf(event.keyCode) > -1

  openScreen = () => {
    const { value } = this.state
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      data,
      colDef: {
        [param]: { linkTo }
      },
      openScreenParams
    } = this.props

    if (Object.keys(openScreenParams).length) {
      this.props.dispatch(
        openScreenAction({
          ...openScreenParams,
          dataId: value
        })
      )
    }
  }

  onSaveCart = () => {
    this.setState({ disabled: true })
  }

  isRequired = memoize(data => {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: { requiredFn, getErrorMessageFn }
      }
    } = this.props

    /*
      sometimes in this crazy sytem,
      you can have an value, but have it be flagged
      (for a variety of reasons)
    */
    if (getErrorMessageFn && requiredFn) {
      return requiredFn(data)
    }

    if (this.state.value || this.state.textFieldVal) {
      return false
    }

    if (requiredFn) {
      return requiredFn(data)
    }

    return false
  })

  render() {
    const param = this.props.colDef.cellRendererParams
      ? 'cellRendererParams'
      : 'cellEditorParams'
    const {
      colDef: {
        [param]: {
          allowMultipleSearches,
          linkTo,
          errorMessage = '',
          getErrorMessageFn
        }
      },
      indexSearchType,
      data,
      meta,
      indexSearchResultsGrid,
      hideToggle
    } = this.props
    // console.log('indexSearchCellMAIN', this.props)

    const searchErrorMessage = getErrorMessageFn
      ? getErrorMessageFn(data)
      : errorMessage

    const disabled =
      this.state.disabled != null ? this.state.disabled : !!this.props.value
    return (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {this.isRequired(data) ? (
          <div style={{ marginRight: 5 }}>
            {getErrorIcon(searchErrorMessage)}
          </div>
        ) : null}
        <div style={{ maxWidth: '100%', paddingLeft: 3, width: '100%' }}>
          <IndexSearch
            {...this.props}
            _ddiForm={{ form: this.props.form || null }}
            // disabled={this.isInputDisabled()}
            getValue={this.getValue}
            disabled={disabled}
            ref={el => (this.indexSearchCell = el)}
            openScreenParams={this.props.openScreenParams}
            onChange={this.onChange}
            // onKeyDown={this.onKeyDown}
            indexSearchType={indexSearchType}
            partialMatchSearch={this.partialMatch}
            exactMatchSearch={this.exactMatch}
            value={this.state.textFieldVal}
            // propertyName={`${
            //   this.props.propertyName
            // }.editableGridIndexSearchField`}
            setField={this.setField}
            onSaveCart={this.onSaveCart}
            meta={{ ...meta, form: this.props.form, hideToggle }}
            tooltip={false}
            allowSpecialProducts
            inputRef={this.inputRef}
            style={{
              maxWidth: '100%',
              minWidth: '100%'
            }}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  null,
  null,
  { forwardRef: true }
)(IndexSearchCell)
