import React from 'react'
import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHead
} from '@material-ui/core'
import memoize from 'memoize-one'

import { fromJS, plainDeepEqual, toCamelCase } from 'utils'
import MappedColumnRow from './MappedColumnRow'
import { mappedColumnDefaults } from '../utils'

const getFormattedMappedColumns = memoize(
  (mappedColumns = [], fieldMappings = []) => {
    return mappedColumns.reduce((acc, next) => {
      const propertyName =
        fieldMappings?.find(x => x?.description === next?.fieldName)
          ?.description || null

      acc = acc.concat({
        ...next,
        propertyName: propertyName
          ? toCamelCase(propertyName)
          : next?.propertyName || ''
      })
      return acc
    }, [])
  },
  plainDeepEqual
)

const MappedColumns = props => {
  const {
    form,
    hasHeaders,
    selectValues,
    mappedColumns = mappedColumnDefaults,
    lineItems = []
  } = props
  let { fieldMappings } = props
  fieldMappings = fieldMappings.reduce((acc, next) => {
    acc = acc.concat({
      dataId: next.dataId,
      description: next.description
    })
    return acc
  }, [])

  const formattedMappedColumns = getFormattedMappedColumns(
    mappedColumns,
    fieldMappings
  )

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 175 }}>Field Name</TableCell>
            <TableCell style={{ width: 150 }}>Mapped Column</TableCell>
            <TableCell>Sample</TableCell>
            <TableCell style={{ width: 35 }}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedMappedColumns.map((item, idx) => (
            <MappedColumnRow
              item={item}
              lineItems={lineItems}
              selectValues={selectValues}
              form={form}
              hasHeaders={hasHeaders}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

export default React.memo(MappedColumns)
