import React from 'react'
import { DDITextField } from 'ddiForm/wrapped'
import { Field, Label } from 'ddiForm'

const CommentsModalContent = ({ data }) => {
  return (
    <>
      <Field
        component={Label}
        initialValue={data.description}
        labelStyle={{ marginBottom: 5, textAlign: 'center', width: '100%' }}
      />
      <DDITextField
        propertyName="comment"
        multiline
        rows={5}
        preventAutoDisable
        variant="outlined"
      />
    </>
  )
}

export default CommentsModalContent
