/* eslint import/prefer-default-export: 0 */
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow
} from '@material-ui/core'

export const TableCell = withStyles({
  root: {
    padding: '5px 10px'
  }
})(MuiTableCell)
