/* eslint import/prefer-default-export: 0 */
import { createAsyncRequestTypes } from 'utils'

export const VENDOR_MASTER = '@@VENDOR_MASTER@@'

export const CREATE_RECORD = createAsyncRequestTypes(
  `${VENDOR_MASTER}CREATE_RECORD`
)

export const DELETE_RECORD = createAsyncRequestTypes(
  `${VENDOR_MASTER}DELETE_RECORD`
)

export const TRY_TOGGLE_PRE_NEW_MODE = `${VENDOR_MASTER}TRY_TOGGLE_PRE_NEW_MODE`

export const TOGGLE_PRE_NEW_MODE = `${VENDOR_MASTER}TOGGLE_PRE_NEW_MODE`

export const SAVE_PRE_NEW_MODE_VALUE = `${VENDOR_MASTER}SAVE_PRE_NEW_MODE_VALUE`

export const GET_VENDOR_INVOICE_REPORT = createAsyncRequestTypes(
  `${VENDOR_MASTER}GET_VENDOR_INVOICE_REPORT`
)

export const LOCK_VENDOR_NOTES = createAsyncRequestTypes(
  `${VENDOR_MASTER}LOCK_VENDOR_NOTES`
)

export const UNLOCK_VENDOR_NOTES = createAsyncRequestTypes(
  `${VENDOR_MASTER}UNLOCK_VENDOR_NOTES`
)

export const SAVE_VENDOR_NOTES = createAsyncRequestTypes(
  `${VENDOR_MASTER}SAVE_VENDOR_NOTES`
)

export const GET_PAYMENT_HISTORY_REPORT = createAsyncRequestTypes(
  `${VENDOR_MASTER}GET_PAYMENT_HISTORY_REPORT`
)

export const SAVE_AGING_VIEW = createAsyncRequestTypes(
  `${VENDOR_MASTER}SAVE_AGING_VIEW`
)

export const SET_SUMMARY_EXPANSION_PANEL_STATE = `${VENDOR_MASTER}SET_SUMMARY_EXPANSION_PANEL_STATE`

export const REMOVE_EMPTY_GRID_ROW = `${VENDOR_MASTER}REMOVE_EMPTY_GRID_ROW`

export const HANDLE_ROW_DATA_UPDATE = `${VENDOR_MASTER}HANDLE_ROW_DATA_UPDATE`

export const TOGGLE_CHECKBOX_CELL = `${VENDOR_MASTER}TOGGLE_CHECKBOX_CELL`

export const PROPERTY_CHANGE = createAsyncRequestTypes(
  `${VENDOR_MASTER}PROPERTY_CHANGE`
)

export const DELETE_VENDOR = createAsyncRequestTypes(
  `${VENDOR_MASTER}DELETE_VENDOR`
)

export const CREATE_VENDOR = createAsyncRequestTypes(
  `${VENDOR_MASTER}CREATE_VENDOR`
)

export const EXIT_SCREEN_FROM_MODAL = `${VENDOR_MASTER}EXIT_SCREEN_FROM_MODAL`
