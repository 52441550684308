/* eslint react/static-property-placement: 0, react/sort-comp: 0 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Icon, ButtonBase, Popover } from '@material-ui/core'
import { getIn } from 'utils'
import { Popover } from '@material-ui/core'
import { once } from 'lodash'
import {
  confirmProvisionalChanges,
  cancelProvisionalChanges
} from 'pages/SalesOrder/actions'

import PricingCell from './PricingCell'

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps
  const formState = getIn(state, `ddiForm.${form}`)
  const showCompetitivePricesInSO =
    getIn(formState, 'meta.showCompetitivePricesInSO') || false
  const showContractPrices =
    getIn(formState, 'meta.showContractPrices') || false
  // const pricingCellOpen = getIn(formState, 'pricingCellOpen') || false
  const isEditing = getIn(formState, 'isEditing') || false
  const rebateCostCleared = getIn(formState, 'rebateCostCleared') || false
  return {
    showCompetitivePricesInSO,
    showContractPrices,
    isEditing,
    rebateCostCleared
  }
}

class PricingCellWrapper extends Component {
  edited = false

  setEdited = once(() => {
    this.edited = true
  })

  static defaultProps = {
    style: {
      height: 440
    }
  }

  // static whyDidYouRender = true

  constructor(props) {
    super(props)

    this.state = {
      style: props.style || {},
      isCancelled: false,
      open: true,
      inquiryMode: props.hasRecord && !props.isEditing
    }
  }

  componentDidMount() {
    if (this.state.inquiryMode) {
      window.addEventListener('keydown', this.onKeyup)
    }
  }

  componentWillUnmount() {
    if (this.state.inquiryMode) {
      window.removeEventListener('keydown', this.onKeyup)
    }
  }

  onKeyup = event => {
    if (event.key === 'Tab') {
      this.tabToNext(this.props.api, true, event.shiftKey)
    }
  }

  getValue() {
    return undefined
  }

  setCancelled = () => {
    const { dispatch, form, gridApi } = this.props
    this.setState(
      {
        open: false,
        isCancelled: true
      },
      () => {
        // close dropdown and stop editing cell
        // gridApi.clearFocusedCell()
      }
    )
  }

  onCancelButtonClicked = () => {
    const { dispatch, form, isComponent, api, rebateCostCleared } = this.props
    if (this.edited || rebateCostCleared) {
      // debugger
      dispatch(
        cancelProvisionalChanges.try(form, {
          isComponent,
          data: this?.props?.data
        })
      )
    }
    // if (this.props.cellStartedEdit) {
    //   debugger
    // }

    api.stopEditing(true)
    this.setCancelled()
  }

  tabToNext = (api, wasBlurred, shiftKey) => {
    const { props } = this
    // console.log(wasBlurred)
    if (typeof wasBlurred !== 'boolean') {
      wasBlurred = false
    }

    this.setState({ open: false }, () => {
      if (wasBlurred) {
        setTimeout(() => {
          if (api) {
            api.stopEditing()
            api[shiftKey ? 'tabToPreviousCell' : 'tabToNextCell']()
          }
        }, 0)
      } else {
        setTimeout(() => {
          if (api) {
            api.stopEditing()
          }
        }, 0)
      } // else?
    })
  }

  handleClose = async (wasBlurred, shiftKey, target) => {
    const { dispatch, form, gridApi, isComponent } = this.props
    const { isCancelled } = this.state

    if (!isCancelled && this.edited) {
      await dispatch(
        confirmProvisionalChanges.try(form, {
          isComponent,
          data: this?.props?.data
        })
      )
    }

    this.tabToNext(gridApi, wasBlurred, shiftKey, target)
  }

  render() {
    const {
      showCompetitivePricesInSO,
      showContractPrices,
      isComponent = false,
      gridApi
    } = this.props
    console.log(this.props)
    // if (rebateCostCleared) {
    //   debugger
    // }
    const {
      allowCostOverride,
      // priceWarningMessage,
      canChange,
      lineNumber,
      canSeeCost,
      canSeeRebateCost
    } = this.props.data
    //
    return (
      <Popover
        open={this.state.open}
        anchorEl={this.props.eGridCell}
        onClose={this.handleClose}
      >
        <div style={this.state.style}>
          <PricingCell
            allowCostOverride={allowCostOverride}
            dataId={this.props.data.dataId}
            dispatch={this.props.dispatch}
            node={this.props.node}
            form={this.props.form}
            showCompetitivePricesInSO={showCompetitivePricesInSO}
            showContractPrices={showContractPrices}
            gridApi={gridApi}
            // lineNumber={lineNumber}
            // setCancelled={this.setCancelled}
            onCancelButtonClicked={this.onCancelButtonClicked}
            // priceWarningMessage={priceWarningMessage}
            // data={this.props.data}
            rowId={this.props.data.rowId}
            // priceChange={priceChange}
            lineNumber={lineNumber}
            canChange={canChange}
            canSeeCost={canSeeCost}
            canSeeRebateCost={canSeeRebateCost}
            handleClose={this.handleClose}
            setEdited={this.setEdited}
            isComponent={isComponent}
          />
        </div>
      </Popover>
      /* </> */
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(PricingCellWrapper)
