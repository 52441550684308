import React from 'react'
import PropTypes from 'prop-types'

export const ImageGridCell = ({ value }) => {
  let src

  if (value) {
    if (value.base64String) {
      src = value.base64String
    } else if (value.url) {
      src = value.url
    } else {
      src = null
    }
  }

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
      }}
    >
      {src ?
        (
          <img
            src={src}
            alt="text"
            style={{ height: 20 }}
          />
        ) : null
      }
    </div>
  )
}

ImageGridCell.propTypes = {
  value: PropTypes.string.isRequired
}

export default ImageGridCell