/* eslint react/sort-comp: 0, react/no-did-mount-set-state: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getIn } from 'utils'
import { Select, MenuItem } from '@material-ui/core'
import shortid from 'shortid'
import { isDisabled, updateGridCellData } from '../utils'

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    data: { dataId, rowId },
    colDef: {
      cellRendererParams: { meta, propertyName }
    }
  } = ownProps

  const rowData = getIn(state, `ddiForm.${form}.fields.${propertyName}.rowData`)
  const rowIndex = rowData && rowData.findIndex ? rowData.findIndex(x => x.get('rowId') === rowId) : 0
  // console.log(rowId, rowIndex, rowData.toJS())

  /* account for inconsistent API naming conventions */
  const uoms = getIn(
    state,
    `ddiForm.${form}.fields.${propertyName}.rowData[${rowIndex}].uoms`
  ) || getIn(
    state,
    `ddiForm.${form}.fields.${propertyName}.rowData[${rowIndex}].uoMs`
  )

  return {
    rowData: rowData && rowData.toJS ? rowData.toJS() : [],
    uoms: uoms && uoms.toJS ? uoms.toJS() : [{ dataId: 'EA', description: 'Each' }]
  }
}

class UOMGridCell extends Component {
  static propTypes = {
    colDef: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      value: props.value || ''
    }

    this.updateGridCellData = updateGridCellData.bind(this)
    this.isDisabled = isDisabled.bind(this)
  }

  componentDidMount() {
    const {
      colDef: { field },
      data: { uom },
      uoms
    } = this.props

    if (field === 'uomid' && this.props.value !== '') {
      this.setState({ value: this.props.value })
    }
  }

  handleChange = event => {
    this.setState({ value: event.target.value }, () => {
      if (this.props.value !== this.state.value) {
        this.updateGridCellData()
      }
    })
  }

  render() {
    const {
      colDef: {
        cellRendererParams: {
          displayValue = 'description'
        }
      },
      disabled,
      data: { uom },
      uoms
    } = this.props

    return (
      <Select
        inputProps={{
          name: shortid.generate(),
          id: shortid.generate()
        }}
        value={this.state.value}
        fullWidth
        disabled={this.isDisabled()}
        onChange={this.handleChange}
        SelectDisplayProps={{
          style: { paddingLeft: 10 }
        }}
      >
        {uoms.map((item, idx) => (
          <MenuItem value={item.dataId} key={shortid.generate()}>
            {item[displayValue]}
          </MenuItem>
        ))}
      </Select>
    )
  }
}

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(UOMGridCell)
