import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { connect } from 'react-redux'
// import deep from 'deep-diff'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { deepEqual, isGrid, getIn, plainDeepEqual } from 'utils'
import DDICardWrapper from 'components/DDICardWrapper'
import * as CONSTANTS from './constants'

const propsToNotUpdateFor = ['setField']
const googleLink = 'https://www.google.com/maps/search/?api=1&query='

class ConnectedAddress extends Component {
  static propTypes = {
    initialValue: PropTypes.oneOfType([
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.any
      }),
      PropTypes.string
    ]), // PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    propertyName: PropTypes.string.isRequired,
    style: PropTypes.object
  }

  static defaultProps = {
    height: 80,
    initialValue: null,
    forwardRef: false,
    value: {},
    style: {}
  }

  // componentDidMount() {
  //   console.log('conn field willmount', this.props)
  //   if (this.props.initialValue) {
  //     console.log(this.props.initialValue)
  //     // this.props.setField(this.props.initialValue)
  //   }
  // }

  shouldComponentUpdate(nextProps) {
    return Object.keys(nextProps).some(
      prop =>
        !~propsToNotUpdateFor.indexOf(prop) &&
        !plainDeepEqual(this.props[prop], nextProps[prop])
    )
  }

  getGoogleLink = () => {
    let href
    if (this.props.field?.value?.address1) {
      let addressStr = `${this.props.field.value.address1} ${this.props.field.value.address2} ${this.props.field.value.cityStateZip}`
      addressStr = addressStr.replace(',', '')
      href = `${googleLink}`
      href = `${href}${encodeURI(addressStr)}`
      href = (
        <a href={href} rel="noopener noreferrer" target="_blank">
          {this.props.field.value.address1}
        </a>
      )
    }

    return href
  }

  render() {
    // console.log(this.props)
    // debugger
    const {
      address2 = '',
      cityStateZip = '',
      description = '',
      phoneNumber = ''
    } = this.props?.field?.value || {}
    return (
      <DDICardWrapper
        title={this.props.title}
        contentClassName="ddi-card-content"
        style={this.props.style}
      >
        <div style={{ height: this.props.height }}>
          {description}
          <br />
          {this.getGoogleLink()}
          <br />
          {address2 && address2}
          {address2 && <br />}
          {cityStateZip}
          {phoneNumber && (
            <span>
              <br />
              {phoneNumber}
            </span>
          )}
        </div>
      </DDICardWrapper>
    )
  }
}

const connector = connect(
  (state, ownProps) => {
    let { initialValue } = ownProps
    const { propertyName, getFormState } = ownProps
    if (initialValue) {
      initialValue =
        initialValue.type === CONSTANTS.DEFAULT_VALUE_TYPES.VALUE
          ? initialValue.value
          : getIn(ownProps, initialValue.value)
    }
    const formState = getFormState(state)
    const field = getIn(formState, `fields.${propertyName}`)
    // field = field ? field.toJS() : field
    return {
      initialValue,
      field
    }
  },
  undefined,
  undefined,
  { forwardRef: true }
)
export default connector(withImmutablePropsToJS(ConnectedAddress))
