import { createApiOptions } from 'utils'
import callFetch from 'access/callFetch'
import { BASE_INFORM_API_URL } from 'services/constants'

const screen = 'customercategory'

export const readCustCategory = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/read`, options)
}

export const createNewCustCategory = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/create`, options)
}

export const updateCustCategory = ({ dataId, properties }) => {
  const options = createApiOptions({
    body: { dataId, properties },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/update`, options)
}

export const deleteCustCategory = ({ dataId }) => {
  const options = createApiOptions({
    body: { dataId },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/delete`, options)
}

export const readCustCategoryTemplate = ({ templateKey }) => {
  const options = createApiOptions({
    body: { templateKey },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/template/read`, options)
}

export const getCustCategoryReport = data => {
  const options = createApiOptions({
    body: { ...data },
    method: 'post'
  })

  return callFetch(`${BASE_INFORM_API_URL}/${screen}/report/get`, options)
}
