import memoize from 'memoize-one'
import { addScreenInModal } from 'ddiForm/actions'
import { warrantyTagInModal } from 'pages/WarrantyTag'
import { onOpenWarrantyTagInModal } from 'pages/WarrantyTag/sagas'
import WarrantyTagModalButtons from './WarrantyTag/components/WarrantyTagModalButtons'
import warrantyTagSOETransaction from './WarrantyTag/warrantyTagSOETransaction'

const addWarrantyTagInModal = memoize(
  (form, lineNumber, guid, warrantyTagId, isInitialInteraction = false) =>
    addScreenInModal(form, {
      screen: warrantyTagInModal(`${form}.warrantyTag`),
      formName: `${form}.warrantyTag`,
      apiMethod: warrantyTagSOETransaction,
      apiArgs: {
        action: 'read',
        guid,
        lineNumber
      },
      onApiSuccess: onOpenWarrantyTagInModal,
      onApiError: error => console.log('fail', error),
      modalOptions: {
        hideActions: false,
        title: 'Warranty Tag',
        maxSize: true,
        modalOverrideClass: 'warranty-tag-in-modal',
        actions: WarrantyTagModalButtons,
        data: {
          lineNumber,
          warrantyTagId,
          subForm: `${form}.warrantyTag`,
          parentForm: form,
          isInitialInteraction
        }
      }
    })
)

export default addWarrantyTagInModal
