import React, { useState } from 'react'
import Measure from 'react-measure'
import withDimensions from 'hoc/withDimensions'
import ErrorBoundary from 'components/ErrorBoundary'
import { layoutFlex } from 'utils'
import Summary from './Summary'

const getGridMaxHeight = (height, dimensions) => {
  const calculatedHeight = height - dimensions?.height
  if (calculatedHeight <= 0) {
    return 100
  }
  return calculatedHeight
}

const InterfaceWrapper = ({ Main = null, height }) => {
  const [dimensions, setDimensions] = useState({
    width: -1,
    height: -1
  })

  return (
    <div style={layoutFlex()}>
      <div
        className="vendor-ledger-wrapper"
        style={{ width: '100%', padding: '0 10px' }}
      >
        <Measure
          bounds
          onResize={contentRect => setDimensions(contentRect.bounds)}
        >
          {({ measureRef }) => (
            <div ref={measureRef}>
              <Summary />
            </div>
          )}
        </Measure>
        {Main ? (
          <ErrorBoundary>
            <Main
              dimensions={dimensions}
              height={height}
              gridMaxHeight={getGridMaxHeight(height, dimensions)}
            />
          </ErrorBoundary>
        ) : null}
      </div>
    </div>
  )
}

export default withDimensions({
  display: 'flex',
  flex: 1,
  maxWidth: '100%'
})(InterfaceWrapper)
