/* eslint jsx-a11y/label-has-for: 0, jsx-a11y/label-has-associated-control: 0 */
import React, { useEffect, useState } from 'react'
import { List } from 'immutable'
import { Label, Field, withContext } from 'ddiForm'
import shortid from 'shortid'
import {
  DDINumberSpinner,
  DDITextField,
  DDIToggle,
  DDIIndexSearch
} from 'ddiForm/wrapped'
import { SearchDropdown } from 'components'
import AssociatedFieldsWrapper from 'components/AssociatedFieldsWrapper'
import DDICardWrapper from 'components/DDICardWrapper'
import { getIn, emptyList, isSecureFieldDisabled } from 'utils'

import GLDistributionGrid from './components/grids/GLDistributionGrid'
import '../../styles/general.scss'

const General = ({ buyersSelected, buyersList, isEditing, securedFields }) => {
  const buyers = buyersList?.toJS ? buyersList.toJS() : []

  return (
    <div className="vendor-master-general-layout-flex-centered">
      <DDICardWrapper title="General" contentStyle={{ padding: 0 }}>
        <div className="inner-wrapper">
          <div className="flex-column-wrapper two-column-wrapper">
            <div className="flex-inner-column-wrapper">
              <div className="two-column-wrapper">
                <div>
                  <div className="form-row-wrapper spinner-form-row-wrapper">
                    <DDINumberSpinner
                      propertyName="payOnDay"
                      type="number"
                      label="Pay On Day:"
                    />
                    <DDINumberSpinner
                      propertyName="cutoffDay"
                      type="number"
                      label="Cutoff Day:"
                    />
                  </div>
                  <div className="form-row-wrapper">
                    <DDITextField propertyName="keyword" label="Keyword" />
                  </div>
                  <div className="form-row-wrapper">
                    <DDITextField
                      propertyName="taxId"
                      label="Tax ID Number"
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'taxId',
                        isEditing,
                        securedFields
                      )}
                    />
                  </div>
                  <div className="form-row-wrapper">
                    <DDITextField
                      propertyName="accountNumberWithVendor"
                      label="Our Number With Vendor"
                    />
                  </div>
                </div>
                <div style={{ paddingTop: 5 }}>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="highAccessLevelRequired"
                      label="High Access Level Required"
                    />
                  </AssociatedFieldsWrapper>
                  <AssociatedFieldsWrapper>
                    <DDIToggle propertyName="issued1099" label="Issued 1099?" />
                  </AssociatedFieldsWrapper>
                  <AssociatedFieldsWrapper>
                    <DDIToggle
                      propertyName="disableMultipleCheckStubs"
                      label="Disable Multiple Check Stubs"
                    />
                  </AssociatedFieldsWrapper>
                </div>
              </div>
            </div>
            <div>
              <div className="form-row-wrapper">
                <div className="search-row">
                  <label>Category:</label>
                  <div className="input-wrapper">
                    <DDIIndexSearch
                      propertyName="categoryId"
                      metaKey="categoryId"
                      searchType="VendorCategory"
                      wrapperStyle={{ margin: 0, width: '100%' }}
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'categoryId',
                        isEditing,
                        securedFields
                      )}
                    />
                  </div>
                  <div className="description-overflow">
                    <Label propertyName="categoryDescription" />
                  </div>
                </div>
                <div className="search-row">
                  <label>Terms:</label>
                  <div className="input-wrapper">
                    <DDIIndexSearch
                      propertyName="apTermsId"
                      metaKey="apTermsId"
                      searchType="APTerms"
                      wrapperStyle={{ margin: 0, width: '100%' }}
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'apTermsId',
                        isEditing,
                        securedFields
                      )}
                    />
                  </div>
                  <div className="description-overflow">
                    <Label propertyName="apTermsDescription" />
                  </div>
                </div>
                <div className="search-row">
                  <label>Currency Code:</label>
                  <div className="input-wrapper">
                    <DDIIndexSearch
                      propertyName="currencyId"
                      metaKey="currencyId"
                      searchType="CurrencyCode"
                      wrapperStyle={{ margin: 0, width: '100%' }}
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'currencyId',
                        isEditing,
                        securedFields
                      )}
                    />
                  </div>
                  <div className="description-overflow">
                    <Label propertyName="currencyDescription" />
                  </div>
                </div>
                <div className="search-row">
                  <label>Remit To Vendor:</label>
                  <div className="input-wrapper">
                    <DDIIndexSearch
                      propertyName="remitToVendorId"
                      metaKey="dataId"
                      searchType="Vendor"
                      wrapperStyle={{ margin: 0, width: '100%' }}
                    />
                  </div>
                  <div className="description-overflow">
                    <Label propertyName="remitToVendorDescription" />
                  </div>
                </div>
                <div className="search-row">
                  <label>Liability G/L:</label>
                  <div className="input-wrapper">
                    <DDIIndexSearch
                      propertyName="liabilityGLId"
                      metaKey="liabilityGLId"
                      searchType="GLAccount"
                      wrapperStyle={{ margin: 0, width: '100%' }}
                      preventAutoDisable
                      disabled={isSecureFieldDisabled(
                        'liabilityGLId',
                        isEditing,
                        securedFields
                      )}
                    />
                  </div>
                  <div className="description-overflow">
                    <Label propertyName="liabilityGLDescription" />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <GLDistributionGrid />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  margin: '15px 0'
                }}
              >
                <label style={{ margin: '0 10px 0 0', fontWeight: 400 }}>
                  Buyers:
                </label>
                <div style={{ flexGrow: 1 }}>
                  <Field
                    label="Buyers"
                    propertyName="buyersSelected"
                    identifier="dataId"
                    initialValue={buyersSelected}
                    component={SearchDropdown}
                    rowData={buyers}
                    fullWidth
                    wrapperMargin={0}
                    gridOptions={{
                      className: 'ag-theme-balham secondary-grid',
                      getRowNodeId: data => data.dataId,
                      columnDefs: [
                        { field: 'dataId', headerName: 'Code' },
                        { field: 'description', headerName: 'Description' }
                      ],
                      enableSorting: true,
                      height: 400
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DDICardWrapper>
    </div>
  )
}

export default withContext({
  buyersList: form => getIn(form, 'values.buyers') || emptyList,
  buyersSelected: form => getIn(form, 'values.buyersSelected') || '',
  isEditing: form => form.get('isEditing') || false,
  securedFields: form => getIn(form, 'meta.securedFields') || List()
})(General)
