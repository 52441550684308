/* eslint react/jsx-no-duplicate-props: 0, react/jsx-props-no-spreading: 0 */
import React, { useEffect, useState } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import {
  Input,
  Icon,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core'
import {
  changeProvisionalLineItem,
  setSalesOrderInitialized
} from 'pages/SalesOrder/actions'

const getEndAdornment = memoize((isMobile, isEditing, val, value) => {
  if (!isMobile) {
    return null
  }

  if (isEditing && isMobile && val !== value) {
    return (
      <InputAdornment position="end">
        <IconButton size="small" style={{ padding: 5 }}>
          <Icon style={{ color: '#389123' }}>done</Icon>
        </IconButton>
      </InputAdornment>
    )
  }

  return null
})

export function NumberFormatter(props) {
  // console.log('numberFormatterProps', props)
  const {
    id,
    inputRef,
    noDecimalLimit = false,
    fixedDecimalScale = false,
    decimalSeparator = '.',
    decimalScale = 0,
    disabled,
    textAlign,
    thousandSeparator = ',',
    onBlur,
    onChange,
    value,
    prefix = '',
    suffix = '',
    fontSize,
    allowNegative = true,
    maxLength = 999,
    paddingRight = 0,
    onFocus,
    isAutoWidth = false,
    ...other
  } = props

  /*
    had to change this component to receive
    decimal props like this
  */

  const decimalFormatting = noDecimalLimit
    ? {}
    : {
        decimalScale,
        fixedDecimalScale
      }

  return (
    <NumberFormat
      {...other}
      {...decimalFormatting}
      disabled={disabled}
      id={id}
      getInputRef={inputRef}
      forwardRef
      value={value}
      onValueChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      prefix={prefix}
      suffix={suffix}
      style={{
        color: value < 0 ? '#d9534f' : disabled ? '#555' : '#000',
        fontSize: fontSize || '1em',
        maxHeight: '100%',
        paddingLeft: 0,
        paddingRight,
        textAlign,
        width: isAutoWidth ? 'auto' : '100%'
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      maxLength={maxLength}
    />
  )
}

const NumberField = ({
  allowNegative,
  callOnBlurWithoutChange = false,
  decimalScale,
  decimalSeperator,
  defaultValue,
  dispatch,
  propertyName,
  fixedDecimalScale,
  fontSize,
  maxLength,
  noDecimalLimit,
  textAlign,
  thousandSeparator,
  prefix,
  suffix,
  form,
  value,
  lineNumber,
  allowSpecialCharacters,
  clearValueOnOpen,
  isPostingProvisionalData,
  disabled,
  autoFocus,
  data,
  onChange,
  onKeyDown: onKeyDownAction,
  onBlur: onBlurAction,
  nextInput,
  inputRef,
  isMobile = false,
  isComponent = false
}) => {
  const textField = inputRef || React.createRef()
  const [val, setValue] = useState(value)
  const [isEditing, setEditMode] = useState(false)
  const [isPosting, setIsPosting] = useState(false)
  const [focusHasBeenSet, isFocusSet] = useState(false)

  useEffect(() => {
    if (
      autoFocus &&
      !focusHasBeenSet &&
      textField &&
      textField.current &&
      textField.current.focus
    ) {
      textField.current.focus()
      textField.current.select()
    }

    if (
      value !== val &&
      !isEditing &&
      /*! isPostingProvisionalData */ !isPosting
    ) {
      if (isPosting) {
        setIsPosting(false)
      } else {
        setValue(value)
      }
    }

    if (clearValueOnOpen) {
      setValue('')
    }
  }, [
    val,
    value,
    focusHasBeenSet,
    isPosting,
    isEditing,
    clearValueOnOpen,
    isPostingProvisionalData
  ])

  const onBlur = async event => {
    event.persist()
    // event.stopPropagation()
    try {
      setEditMode(false)
      // if (callOnBlurWithoutChange && onBlurAction && value === val) {
      //   //
      //   onBlurAction(event)
      // }
      if (value !== val) {
        setIsPosting(true)
        try {
          await dispatch(
            changeProvisionalLineItem.try(form, {
              lineNumber,
              propertyName,
              value: val || event.target.value,
              data,
              nextInput,
              isComponent
            })
          )
          setIsPosting(false)
        } catch (e) {
          setValue(value)
        }
        if (onBlurAction && !callOnBlurWithoutChange) {
          onBlurAction(event)
        }
      }
    } catch (e) {
      setValue(value)
    }
  }

  const onNumberFormatterChange = values => {
    const v = values.floatValue || defaultValue

    setValue(v)
    if (focusHasBeenSet) {
      setEditMode(true)
    }
    if (onChange) {
      onChange(v)
    }
  }

  const onTextFieldChange = event => {
    event.persist()
    setValue(event.target.value)
    setEditMode(true)
    if (onChange) {
      onChange(event.target.value)
    }
  }

  const onFocus = event => {
    event.persist()
    if (textField && textField.current && textField.current.select) {
      textField.current.select()
    }
    if (!focusHasBeenSet) {
      isFocusSet(true)
    }
  }

  const onKeyDown = event => {
    event.persist()
    if (onKeyDownAction) {
      onKeyDownAction(event)
    }
  }

  return (
    <Input
      autoFocus={autoFocus}
      value={val}
      disabled={disabled}
      fullWidth
      inputComponent={allowSpecialCharacters ? 'input' : NumberFormatter}
      onChange={
        allowSpecialCharacters ? onTextFieldChange : onNumberFormatterChange
      }
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onFocus={onFocus}
      // inputRef={el => (textField = el)}
      inputRef={textField}
      inputProps={{
        defaultValue,
        allowNegative,
        decimalScale: decimalScale || 0,
        noDecimalLimit: noDecimalLimit || false,
        decimalSeparator: decimalSeperator || '.',
        fontSize: fontSize || '1em',
        fixedDecimalScale: fixedDecimalScale || false,
        // inputRef: el => (textField = el),
        inputRef: textField,
        onFocus,
        maxLength,
        textAlign: textAlign || 'right',
        thousandSeparator,
        prefix: prefix || '',
        suffix: suffix || ''
      }}
      endAdornment={getEndAdornment(isMobile, isEditing, val, value)}
      // forwardRef
    />
  )
}

NumberField.propTypes = {
  allowSpecialCharacters: PropTypes.bool,
  allowNegative: PropTypes.bool,
  autoFocus: PropTypes.bool,
  clearValueOnOpen: PropTypes.bool,
  decimalScale: PropTypes.number,
  decimalSeperator: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dispatch: PropTypes.func.isRequired,
  propertyName: PropTypes.string.isRequired,
  fixedDecimalScale: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lineNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  maxLength: PropTypes.number,
  noDecimalLimit: PropTypes.bool,
  textAlign: PropTypes.string,
  thousandSeparator: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  form: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPostingProvisionalData: PropTypes.bool
}

NumberField.defaultProps = {
  allowSpecialCharacters: false,
  allowNegative: true,
  autoFocus: false,
  clearValueOnOpen: false,
  decimalScale: 0,
  decimalSeperator: '.',
  defaultValue: 0,
  fixedDecimalScale: false,
  fontSize: '1em',
  maxLength: 999,
  noDecimalLimit: false,
  textAlign: 'left',
  thousandSeparator: ',',
  prefix: '',
  suffix: '',
  value: 0,
  isPostingProvisionalData: false
}

export default NumberField
